import { goTo, urlTo } from 'helpers/router.js'

import { states } from 'constants/client'

export const navigateToIsaTransferFlowByClient = (client): void => {
  const shouldSignIsaDeclaration = client?.should_sign_isa_declaration
  const clientIsNotCompleted = client?.state === states.NOT_COMPLETED
  const clientIsApproved = client?.state === states.APPROVED
  const setupAccountUrl = urlTo('dashboard.setup-account', null, { forwardToTransferIsa: true })
  const transferIsaUrl = urlTo('transfer-isa-altus')
  const dashboardUrl = urlTo('dashboard')

  if (shouldSignIsaDeclaration) {
    const next = clientIsNotCompleted ? setupAccountUrl : clientIsApproved ? transferIsaUrl : dashboardUrl

    goTo(urlTo('dashboard.sign-isa-declaration', null, { next }))

    return
  }

  if (clientIsNotCompleted) {
    goTo(setupAccountUrl)
    return
  }

  if (clientIsApproved) {
    goTo(transferIsaUrl)
    return
  }

  goTo(dashboardUrl)
}
