import React from 'react'
import map from 'lodash/map'
import Inner from 'components/_old/Inner/Inner.jsx'
import StackedBar from 'components/_old/StackedBar/StackedBar.jsx'
import './StackedBarGroup.css'

export default function (props) {
  const { data, style, edgeToEdge, maxHeight, alternativesHelper } = props
  const { groupsOfAssets } = data
  const maxGroupPercent = Math.max.apply(
    null,
    map(groupsOfAssets, 'sumAmount').map((s) => Number(s)),
  )
  const maxGroupHeight = (maxHeight / 100) * maxGroupPercent

  let group = (
    <div className="StackedBarGroup">
      <Inner className="StackedBarGroup-Inner" style={{ ...style, height: maxGroupHeight }}>
        {groupsOfAssets.map((groupOfAssets, i) => {
          return groupOfAssets.assets.length > 0 ? (
            <div className="StackedBarGroup-Stack" key={i}>
              <StackedBar {...groupOfAssets} maxHeight={maxHeight} alternativesHelper={alternativesHelper} />
            </div>
          ) : null
        })}
      </Inner>
    </div>
  )

  if (edgeToEdge) {
    group = <div className="StackedBarGroup-EdgeToEdge">{group}</div>
  }

  return group
}
