import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

import type { QuickStart } from '../types'

const getQuickStarts = async (): Promise<QuickStart[] | ApiError> => {
  try {
    const { data } = await axios.get('/portfolios/quick-start/')

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const getPortfolioQuickStart = async (portfolioId: string): Promise<QuickStart | ApiError> => {
  try {
    const { data } = await axios.get(`/portfolios/${portfolioId}/quick-start/`, { shouldNotSend404: true })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const createQuickStart = async (portfolioId: string, quickStart: QuickStart): Promise<QuickStart | ApiError> => {
  try {
    const { data } = await axios.post(`/portfolios/${portfolioId}/quick-start/`, quickStart)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const updateQuickStart = async (
  portfolioId: number,
  updateData: { status: string },
): Promise<QuickStart | ApiError> => {
  try {
    const { data } = await axios.patch(`/portfolios/${portfolioId}/quick-start/`, updateData)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { getQuickStarts, createQuickStart, getPortfolioQuickStart, updateQuickStart }
