import React, { Fragment } from 'react'

import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

interface DetailsOfIsaTransferProps {
  validation: {
    rules: boolean[]
    errors: string[]
  }
  isForceValidationEnabled: boolean
}

const DetailsOfIsaTransfer = ({
  validation,
  isForceValidationEnabled,
}: DetailsOfIsaTransferProps): React.ReactElement => {
  const brokenRule = validation.rules.findIndex((rule) => !rule)
  const isaTransferDetailError = brokenRule > -1 ? validation.errors[brokenRule] : null

  const shouldShowError = isForceValidationEnabled && isaTransferDetailError

  return (
    <Fragment>
      {shouldShowError && (
        <Paper top={32}>
          <Typography color="error" size={14}>
            <Typo>{isaTransferDetailError}</Typo>
          </Typography>
        </Paper>
      )}
    </Fragment>
  )
}

export { DetailsOfIsaTransfer }
