import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useSelector, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'
import { backendErrorsToObj, combineErrors, bulkValidate } from 'helpers/validation.js'

import { $allowancesStore } from 'app/effector/allowances'
import { fetchBankAccountsFx, $bankAccountsStore } from 'app/effector/bank-accounts'
import type { BankAccount } from 'app/effector/bank-accounts/models/bank'
import { $dictsStore } from 'app/effector/dicts'

import {
  fetchGoal as fetchGoalActionCreator,
  withdrawFunds as withdrawFundsActionCreator,
  resetError as resetGoalsErrorActionCreator,
} from 'app/redux/actions/portfolios'
import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'
import { type Portfolio } from 'app/redux/models/portfolio/types'

import { bankAccountStates } from 'constants/bankAccounts'
import { manageTypes, regulatoryTypes } from 'constants/portfolio'

type Step = 'INITIAL' | 'ISA_LIMIT_WARNING'

const steps: Record<Step, Step> = {
  INITIAL: 'INITIAL',
  ISA_LIMIT_WARNING: 'ISA_LIMIT_WARNING',
}

type UseWithdrawFundsProps = {
  portfolioId: string
  location: {
    query: Record<string, string>
  }
}

type UseWithdrawFundsReturnProps = {
  step: Step
  amount: string
  isDIY: boolean
  isManaged: boolean
  validation: {
    amount: {
      rules: boolean[]
      errors: string[]
    }
  }
  portfolio: Portfolio
  remainingIsaAllowance: number
  withdrawRequestInProgress: boolean
  availableBalance: number
  withdrawableAmount: number
  safeWithdrawable: number
  isCashUnsetlled: boolean
  shouldWithdrawAll: boolean
  shouldForceWithdrawAll: boolean
  initialDepositMin: number
  nominatedBankAccount: BankAccount
  isBankApproved: boolean
  isSubmitButtonDisabled: boolean
  handleDebitChange: (_event: React.ChangeEvent, value: string) => void
  handleWithdrawBtnClick: () => void
  handleWithdraw: () => Promise<void>
  handleBackToForm: () => void
  handleClose: () => void
}

const useWithdrawFunds = ({ portfolioId, location }: UseWithdrawFundsProps): UseWithdrawFundsReturnProps => {
  const [step, setStep] = useState(steps.INITIAL)
  const [amount, setAmount] = useState('')
  const [withdrawRequestInProgress, setWithdrawRequestInProgress] = useState(false)

  const client = useSelector((state) => state.client)
  const goals = useSelector((state) => state.portfolios)
  const goal = useSelector((state) => state.portfolios.items.find((goal) => goal.id === parseInt(portfolioId)))
  const { nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)
  const { initialDepositMin } = useUnit($dictsStore)

  const isDIY = goal.manage_type === manageTypes.DIY
  const isCash = goal.manage_type === manageTypes.CASH
  const isManaged = [manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(goal.manage_type)
  const withdrawableAmount = parseFloat(goal.withdrawable_cash) > 0 ? parseFloat(goal.withdrawable_cash) : 0
  const safeWithdrawable = parseFloat(goal.safe_withdrawable_cash)
  const availableBalance = isManaged ? withdrawableAmount : safeWithdrawable
  const shouldWithdrawAll = !isDIY && !isCash && parseFloat(amount ?? '0') + initialDepositMin > withdrawableAmount
  const shouldForceWithdrawAll = !isDIY && !isCash && goal?.is_fetched && goal.is_full_withdrawal_requested
  const isCashUnsetlled = parseFloat(goal.unsettled_cash ?? '0') > 0
  const isBankApproved = nominatedBankAccount?.state === bankAccountStates.APPROVED

  const { allowances } = useUnit($allowancesStore)
  const remainingIsaAllowance = parseFloat(allowances?.isa?.remaining_allowance ?? '0')

  const [withdrawFunds, fetchGoal, resetError] = useActions([
    withdrawFundsActionCreator,
    fetchGoalActionCreator,
    resetGoalsErrorActionCreator,
  ])

  useEffect(() => {
    if (!nominatedBankAccount?.sort_code) {
      fetchBankAccountsFx()
    }
  }, [nominatedBankAccount])

  const handleDebitChange = useCallback(
    (_event, value = null) => {
      if (goals.error) {
        resetError()
      }

      setAmount(value)
    },
    [goals, resetError],
  )

  const handleClose = useCallback(() => {
    goTo(urlTo(`dashboard.portfolio`, { id: portfolioId }, location.query), { replace: true, scrollToTop: false })
    setTimeout(() => {
      setAmount(null)
      setStep(steps.INITIAL)
    }, 500)
  }, [portfolioId, location])

  const handleBackToForm = useCallback(() => {
    setStep(steps.INITIAL)
  }, [])

  const validation = combineErrors(
    {
      amount: {
        rules: [parseFloat(amount ?? '0') > 0, parseFloat(amount ?? '0') <= availableBalance],
        errors: ['Please enter a value', 'Cannot exceed available balance'],
      },
    },
    backendErrorsToObj(goal.error),
  )

  const isSubmitButtonDisabled =
    !isBankApproved || withdrawRequestInProgress || (shouldForceWithdrawAll ? false : !bulkValidate(validation))

  const handleWithdraw = useCallback(async () => {
    const withdrawAmount = shouldForceWithdrawAll || shouldWithdrawAll ? withdrawableAmount : amount

    setWithdrawRequestInProgress(true)

    try {
      const stateAfterWithdrawal = await withdrawFunds(withdrawAmount, goal.id)

      if (stateAfterWithdrawal.portfolios.error) {
        throw stateAfterWithdrawal.portfolios.error
      }

      await fetchGoal(goal.id)

      trackEvent(
        {
          action: 'withdrawal_requested',
          amount: 99.25,
          client_type: client.type,
          manage_type: goal.manage_type,
        },
        { plugins: { 'google-analytics-v3': false } },
      )

      showSuccessToast('Withdrawal request created')
      handleClose()
    } catch (error) {
      showFailToast('Withdrawal request creation failed')
    }
    setWithdrawRequestInProgress(false)
  }, [
    goal,
    amount,
    withdrawableAmount,
    shouldWithdrawAll,
    shouldForceWithdrawAll,
    handleClose,
    withdrawFunds,
    fetchGoal,
    client?.type,
  ])

  const handleWithdrawBtnClick = useCallback(() => {
    if (goal.regulatory_type === regulatoryTypes.ISA) {
      setStep(steps.ISA_LIMIT_WARNING)
      return
    }

    handleWithdraw()
  }, [goal, handleWithdraw])

  return {
    step,
    amount,
    isDIY,
    isManaged,
    validation,
    portfolio: goal,
    remainingIsaAllowance,
    withdrawRequestInProgress,
    availableBalance,
    withdrawableAmount,
    safeWithdrawable,
    isCashUnsetlled,
    initialDepositMin,
    shouldWithdrawAll,
    nominatedBankAccount,
    shouldForceWithdrawAll,
    isBankApproved,
    isSubmitButtonDisabled,
    handleDebitChange,
    handleWithdrawBtnClick,
    handleWithdraw,
    handleBackToForm,
    handleClose,
  }
}

export { useWithdrawFunds, steps }
