import { useState, useCallback, useEffect, useMemo } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { replace as historyReplace } from 'helpers/history.js'

function normalize(rawValue: string): number[] {
  if (!rawValue) return []

  if (Array.isArray(rawValue)) {
    return rawValue.map((item) => Number(item))
  }

  return rawValue.split(',').map((item) => Number(item))
}

type useAnalyticsFiltersArguments = {
  withFilters?: boolean
  sectors: Array<{ name: string; id: string }>
  regions: Array<{ name: string; id: string }>
  query: Record<string, string>
}

type AnalyticsFilter = {
  value: number[]
  set: (value: number[]) => void
  list: Array<{ name: string; value: string }>
}

type useAnalyticsFiltersData = {
  filters: {
    regions: AnalyticsFilter
    sectors: AnalyticsFilter
  }
}

const useAnalyticsFilters = ({
  withFilters,
  sectors,
  regions,
  query,
}: useAnalyticsFiltersArguments): useAnalyticsFiltersData => {
  const initialFiltersState = useCallback(
    () => ({
      regions: normalize(query?.regions ?? ''),
      sectors: normalize(query?.sectors ?? ''),
    }),
    [query],
  )

  const [filtersState, setFiltersState] = useState(initialFiltersState)

  const setFilter = (name: string, value: number[]): void => {
    setFiltersState((state) => ({ ...state, [name]: value }))
  }

  const filtersList = useMemo(() => {
    return {
      regions: regions.map((region) => ({ name: region.name, value: region.id })),
      sectors: sectors.map((sector) => ({ name: sector.name, value: sector.id })),
    }
  }, [regions, sectors])

  useEffect(() => {
    if (!withFilters) return
    const { regions, sectors, ...restOfQueryObject } = query ?? {}

    const queryString = querystringFromObject({ ...filtersState, ...restOfQueryObject })

    historyReplace(`${window.location.pathname}${queryString}`, { scrollToTop: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState])

  return {
    filters: {
      regions: {
        value: filtersState.regions,
        set: (value) => {
          setFilter('regions', value)
        },
        list: filtersList.regions,
      },
      sectors: {
        value: filtersState.sectors,
        set: (value) => {
          setFilter('sectors', value)
        },
        list: filtersList.sectors,
      },
    },
  }
}

export { useAnalyticsFilters, type AnalyticsFilter }
