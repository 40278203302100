import React from 'react'

import { useActions, useCallback, useEffect, useMediaQueries, useSelector } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'

import { Paper } from 'components/atoms/Paper'

import { SettingsLayout } from '../components/SettingsLayout'

import { PortfolioCard } from './components/PortfolioCard'

import { manageTypes } from 'constants/portfolio'

const ManagedPortfolios = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const portfolios = useSelector((state) => state.portfolios)
  const managedPortfolios = portfolios?.list
    .getVisiblePortfolios()
    .filter((portfolio) => portfolio.manage_type === manageTypes.MANAGED && !portfolio.is_default_preset_used)

  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])

  useEffect(() => {
    if (!portfolios.items.length) {
      fetchPortfolios()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePortfolioClick = useCallback((id: string) => {
    goTo(urlTo('dashboard.user-profile.managed-portfolios.questionnaire-answers', { id }))
  }, [])

  return (
    <SettingsLayout title="Managed portfolios">
      {managedPortfolios.map((portfolio) => (
        <Paper key={portfolio.id} bottom={desktop ? 24 : 16} data-test-id="managedPortfolioCard">
          <PortfolioCard portfolio={portfolio} onClick={handlePortfolioClick} />
        </Paper>
      ))}
    </SettingsLayout>
  )
}

export { ManagedPortfolios }
