import React from 'react'

import { useMediaQueries } from 'hooks'

import { format as formatMoney } from 'helpers/money'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { regulatoryTypes, manageTypes } from 'constants/portfolio'

type PortfolioCardProps = {
  portfolio: Portfolio
  onClick: (id: string) => void
  isBusiness: boolean
}

const PortfolioCard = ({ portfolio, onClick, isBusiness }: PortfolioCardProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const cashAmount = formatMoney(
    portfolio?.manage_type === manageTypes.CASH
      ? portfolio?.available_to_move_cash
      : portfolio?.available_to_invest_cash || 0,
    true,
    true,
  )
  const cashPortfolioTitles = {
    [regulatoryTypes.GIA]: isBusiness ? 'Cash' : 'General cash',
    [regulatoryTypes.ISA]: isBusiness ? 'Cash' : 'ISA cash',
    [regulatoryTypes.SIPP]: isBusiness ? 'Cash' : 'Personal Pension cash',
  }

  const title =
    portfolio?.manage_type === manageTypes.CASH
      ? cashPortfolioTitles[portfolio?.regulatory_type]
      : portfolio?.getTitle()

  const content = (
    <Paper top={desktop ? 24 : 20} bottom={desktop ? 24 : 20} right={desktop ? 24 : 20} left={desktop ? 24 : 20}>
      <ColumnarLayout mods={{ padding: 'small' }}>
        <Column size={1}>
          <Typography lineHeight="small" data-test-id="sourceOfCashTitle">
            <Typo>{title}</Typo>
          </Typography>
        </Column>
        <Column size={0}>
          <Typography lineHeight="small" data-test-id="sourceOfCashAmount">
            <Typo>{cashAmount}</Typo>
          </Typography>
        </Column>
      </ColumnarLayout>
    </Paper>
  )

  return (
    <SelectableCard onClick={onClick} data-test-id="sourceOfCashCard">
      <ItemWithIcon
        iconPosition="right"
        space={4}
        content={content}
        icon={
          <Paper right={desktop ? 32 : 24}>
            <Icon size={16} type="arrow-down-16" />
          </Paper>
        }
        iconVerticalAlign="center"
      />
    </SelectableCard>
  )
}

export { PortfolioCard }
