import React, { Fragment } from 'react'

import { useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import appleStoreIcon from 'components/_old/Icon/svgs/app-store-apple.svg'
import googlePlayIcon from 'components/_old/Icon/svgs/app-store-google.svg'
import Link from 'components/_old/Link/Link.jsx'
import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import iphonePush from '../iphonePush.png'

import storesLink from 'constants/externalLinks.js'

type TwoFactorAuthSetupMobileProps = {
  handleBack: () => void
  handleContinue: () => void
}

const Mobile = ({ handleBack, handleContinue }: TwoFactorAuthSetupMobileProps): React.ReactElement => {
  const isIos = /(iPhone|iPad|iPod)/.test(navigator.userAgent)

  const headerNode = useMemo(
    () => (
      <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
        Step 1 of 3
      </NavigationBar>
    ),
    [handleBack],
  )

  const firstStepNode = useMemo(
    () => (
      <Paper top={24}>
        <Typography size={16} align="center">
          <Typo>1. Get the InvestEngine app</Typo>
        </Typography>
        <Paper top={32}>
          <AllCenter>
            <Link to={storesLink} hard blank>
              <SvgIcon src={isIos ? appleStoreIcon : googlePlayIcon} />
            </Link>
          </AllCenter>
        </Paper>
      </Paper>
    ),
    [isIos],
  )

  const secondStepNode = useMemo(
    () => (
      <Paper top={32}>
        {isIos ? (
          <Fragment>
            <Typography size={16} align="center">
              <Typo>2. Launch app, login and turn on push notifications</Typo>
            </Typography>
            <Paper top={24}>
              <AllCenter>
                <img src={iphonePush} width={240} height={126} />
              </AllCenter>
            </Paper>
          </Fragment>
        ) : (
          <Typography size={16} align="center">
            <Typo>2. Launch app, login</Typo>
          </Typography>
        )}
      </Paper>
    ),
    [isIos],
  )

  const contentNode = (
    <Width size={36} center>
      <Paper top={16}>
        <Typography size={24} lineHeight="small" weight="semibold" align="center">
          <Typo>Ensure app is installed and notifications turned on</Typo>
        </Typography>
      </Paper>
      <Width size={21} center>
        {firstStepNode}
        {secondStepNode}
      </Width>
    </Width>
  )

  const footer = useMemo(
    () => (
      <MobileLayoutFooterButton>
        <Button mods={{ size: 'big block' }} onClick={handleContinue}>
          Continue
        </Button>
      </MobileLayoutFooterButton>
    ),
    [handleContinue],
  )

  return <MobileLayout header={headerNode} content={contentNode} footer={footer} />
}

export { Mobile }
