const PREFIX = 'QUESTIONNAIRE'

export const SET_NOT_VALID = `${PREFIX}_SET_NOT_VALID`
export const SET_VALID = `${PREFIX}_SET_VALID`

export const RECEIVE_ERROR = `${PREFIX}_RECEIVE_ERROR`
export const RESET_ERROR = `${PREFIX}_RESET_ERROR`

export const RECEIVE_QUESTIONS = `${PREFIX}_RECEIVE_QUESTIONS`

export const SET_CLIENT_ANSWERS = `${PREFIX}_SET_CLIENT_ANSWERS`
export const SET_CLIENT_ANSWER = `${PREFIX}_SET_CLIENT_ANSWER`
