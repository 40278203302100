import { useUnit } from 'effector-react'

import { useEffect } from 'hooks'

import {
  $recentlyViewedEtfs,
  $isLoading,
  $areRecentlyViewedEtfsFetched,
  fetchRecentlyViewedEtfsFx,
} from 'app/effector/recently-viewed-etfs'
import { $securitiesStore } from 'app/effector/securities'
import { type Security } from 'app/effector/securities/models/security/Security'

type useRecentlyViewedEtfsInterface = {
  etfsList: Security[]
}

const useRecentlyViewedEtfs = (): useRecentlyViewedEtfsInterface => {
  const recentlyViewedEtfs = useUnit($recentlyViewedEtfs)
  const isLoading = useUnit($isLoading)
  const areRecentlyViewedEtfsFetched = useUnit($areRecentlyViewedEtfsFetched)

  const { securities } = useUnit($securitiesStore)

  const skeletonsData = [
    { isSkeleton: true },
    { isSkeleton: true },
    { isSkeleton: true },
    { isSkeleton: true },
    { isSkeleton: true },
  ]

  const recentlyViewedSecuritiesList = recentlyViewedEtfs.map((id) => securities.get(id)).filter(Boolean)

  useEffect(() => {
    fetchRecentlyViewedEtfsFx()
  }, [])

  return {
    etfsList:
      (isLoading && !areRecentlyViewedEtfsFetched) || !securities.length ? skeletonsData : recentlyViewedSecuritiesList,
  }
}

export { useRecentlyViewedEtfs }
