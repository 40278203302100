import React, { Fragment } from 'react'

import { BonusStates } from 'app/effector/bonuses/models'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo'

import { AnimatedVisibilityToggle } from 'components/atoms/AnimatedVisibilityToggle'
import { MobileLayout, MobileLayoutFooterButton, MobileLayoutFullViewport } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Relative } from 'components/atoms/Relative'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { MobileFooter } from 'app/containers/Footer'

import { Box } from '../components/Box'
import { Title } from '../components/Title'

import type { UseClaimBonusInterface } from '../hooks'

function Mobile({ state, value, handleBack, handleClaim, handleContinue }: UseClaimBonusInterface): React.ReactElement {
  return (
    <MobileLayout
      defaultHeaderHeight={0}
      contentPaperSizes={{ top: 0, left: 0, right: 0 }}
      content={
        <Fragment>
          <MobileLayoutFullViewport>
            <Relative overflow="hidden" mix>
              <Inner flex>
                <ColumnarLayout flexDirection="column" mods={{ padding: 'no' }}>
                  <Column size={0}>
                    <AnimatedVisibilityToggle visible={state === BonusStates.NEW}>
                      <SideSpacesCompensator>
                        <NavigationBar leftPartText="Back" onLeftPartClick={handleBack} />
                      </SideSpacesCompensator>
                    </AnimatedVisibilityToggle>
                    <Paper top={24}>
                      <Title open={state !== BonusStates.NEW} />
                    </Paper>
                  </Column>
                  <Column size={1}>
                    <AllCenter>
                      <Box open={state !== BonusStates.NEW} value={value} handleContinue={handleContinue} />
                      <AnimatedVisibilityToggle visible={state === BonusStates.NEW}>
                        <Paper top={48}>
                          <Typography size={20} align="center">
                            <Typo>Open the box to reveal your Welcome Bonus!</Typo>
                          </Typography>
                        </Paper>
                      </AnimatedVisibilityToggle>
                    </AllCenter>
                  </Column>
                </ColumnarLayout>
              </Inner>
            </Relative>
          </MobileLayoutFullViewport>
          <Inner>
            <MobileFooter noMarginTop />
          </Inner>
        </Fragment>
      }
      footer={
        state === BonusStates.NEW ? (
          <MobileLayoutFooterButton>
            <Button onClick={handleClaim} mods={{ color: 'green' }}>
              Open the box!
            </Button>
          </MobileLayoutFooterButton>
        ) : undefined
      }
    />
  )
}

export { Mobile }
