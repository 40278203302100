import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

/**
 * @class
 * @typedef {Object} SecurityOrder
 */
export class SecurityOrder extends Entity {
  constructor() {
    super()

    /** @type {?number} */
    this.id = null
    /** @type {?number} */
    this.amount = null
    /** @type {?number} */
    this.weight_after = null
    /** @type {?number} */
    this.weight_before = null
    /** @type {?number} */
    this.quantity_change = null
    /** @type {?string} */
    this.trade_type = null
  }

  getRules() {
    return {
      id: [[rules.int]],
      amount: [[rules.float]],
      weight_after: [[rules.float]],
      weight_before: [[rules.float]],
      quantity_change: [[rules.float]],
      trade_type: [[rules.string]],
    }
  }
}
