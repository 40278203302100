import * as React from 'react'

import { useMediaQueries } from 'hooks'

import { formatMinimalPercent } from 'helpers/money'
import { palette } from 'helpers/palette/'

import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { AccentLine } from 'components/atoms/AccentLine'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type HoldingSecurityProps = {
  security: {
    title: string
    color: string
    current_target_weight?: number
    target_weight?: number
  }
  showActual: boolean
}

const emptyHoldingColor = palette['primary-surface-10']

const HoldingSecurity = ({ security, showActual }: HoldingSecurityProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const actualWeightPercent = formatMinimalPercent(security.current_target_weight)
  const targetWeightPercent = formatMinimalPercent(security.target_weight ?? 0, {
    returnDashIfNotNumberPassed: true,
  })

  return (
    <ColumnarLayout className="SecurityLink-Content" mods={{ padding: 'no', 'align-columns': 'center' }}>
      <Column>
        <AccentLine color={security.target_weight ? security.color : emptyHoldingColor} />
      </Column>
      <Column>
        <Paper left={desktop ? 16 : 12} right={40}>
          {showActual ? (
            <Typography size={desktop ? 16 : 18} lineHeight="small" weight="semibold">
              <Nobr>
                <Typography tag="span" size={desktop ? 16 : 18} lineHeight="small" weight="semibold" color="minor">
                  {actualWeightPercent}{' '}
                </Typography>
                → {targetWeightPercent}
              </Nobr>
            </Typography>
          ) : (
            <Typography size={desktop ? 16 : 18} lineHeight="small" weight="semibold">
              {targetWeightPercent}
            </Typography>
          )}
        </Paper>
      </Column>
      <Column mods={{ strict: true }}>
        <Typography size={14} lineHeight="small" align="right" overflow="ellipsis">
          <Nobr>
            <Typo>{security.title}</Typo>
          </Nobr>
        </Typography>
      </Column>
    </ColumnarLayout>
  )
}

export { HoldingSecurity }
