/**
 * Composes single-argument functions from right to left. The rightmost function can take multiple
 * arguments as it provides the signature for the resulting composite function
 *
 * For example, compose(f, g, h) is identical to doing (...args) => f(g(h(...args)))
 */
const compose = (...funcs) => {
  if (funcs.length === 0) {
    return (arg) => arg
  }
  if (funcs.length === 1) {
    return funcs[0]
  }
  return funcs.reduce(
    (prev, next) =>
      (...args) =>
        prev(next(...args)),
  )
}

export default compose

// const compose = <R>(...funcs: ((a: R) => R)[]): (a: R) => R => {
//   if (funcs.length === 0) {
//     return (arg: R) => arg
//   }

//   if (funcs.length === 1) {
//     return funcs[0]
//   }

//   return funcs.reduce((prev, next) => (...args) => prev(next(...args)))
// }

// export default compose
