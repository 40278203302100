import React from 'react'
import PropTypes from 'prop-types'

import { useMemo, useSelector } from 'hooks'

import { palette } from 'helpers/palette/'

import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Icon from 'components/_old/Icon/Icon.jsx'

import { manageTypes, regulatoryTypes } from 'constants/portfolio'
import { types as clientTypes } from 'constants/client'

const PortfolioType = ({ portfolio, inline }) => {
  const clientType = useSelector((state) => state.client.type)
  const isDiy = portfolio.manage_type === manageTypes.DIY

  const regulatoryTypeText = useMemo(
    () =>
      ({
        [regulatoryTypes.GIA]: clientType === clientTypes.BUSINESS ? 'Business' : 'General',
        [regulatoryTypes.ISA]: 'ISA',
        [regulatoryTypes.SIPP]: 'Personal Pension',
      })[portfolio.regulatory_type],
    [clientType, portfolio.regulatory_type],
  )

  const managedTypeText = useMemo(
    () =>
      ({
        [manageTypes.DIY]: 'Self-managed',
        [manageTypes.MANAGED]: 'Managed by InvestEngine',
        [manageTypes.SELF_SELECTED]: 'Picked by you, managed by IE',
      })[portfolio.manage_type],
    [portfolio.manage_type],
  )

  return (
    <ItemWithIcon
      inline={inline}
      space={8}
      icon={
        <Icon
          className="GroupTitle-Icon"
          type={isDiy ? 'person2' : 'logo-small-new'}
          size={24}
          color={isDiy ? palette['secondary-default'] : palette['content-on-background-primary']}
          data-test-id={isDiy ? 'diyIcon' : 'managedIcon'}
        />
      }
      iconVerticalAlign="center"
      contentVerticalAlign="center"
      content={
        <Typography size={14} lineHeight="small" color="additional" data-test-id="portfolioTypeText">
          <Typo>
            {managedTypeText}, {regulatoryTypeText}
          </Typo>
        </Typography>
      }
    />
  )
}

PortfolioType.propTypes = {
  inline: PropTypes.bool,
  portfolio: PropTypes.shape({
    manage_type: PropTypes.oneOf(Object.values(manageTypes)),
    regulatory_type: PropTypes.oneOf(Object.values(regulatoryTypes)),
  }),
}

export { PortfolioType }
