import React from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Width from 'components/_old/Width/Width'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typography } from 'components/atoms/Typography'

import './OrdersProcessing.css'

const OrdersProcessing = ({ space = 8, to, 'data-test-id': dataTestId = 'ordersProcessing' }) => {
  const itemWithIcon = useMemo(
    () => (
      <ItemWithIcon
        className="OrdersProcessing-ItemWithIcon"
        space={space}
        icon={
          <Width className="OrdersProcessing-Icon" size={1.5}>
            <Icon type="time-24" color="inherit" />
          </Width>
        }
        content={
          <Typography size={16} lineHeight="small" color="inherit">
            Orders placed
          </Typography>
        }
        iconVerticalAlign="center"
        contentVerticalAlign="center"
        inline
        data-test-id="ordersPlaced"
      />
    ),
    [space],
  )

  if (to) {
    return (
      <div className="OrdersProcessing" data-test-id={dataTestId}>
        <Link className="OrdersProcessing-Link" to={to} data-test-id={`${dataTestId}Link`}>
          {itemWithIcon}
        </Link>
      </div>
    )
  }

  return (
    <div className="OrdersProcessing" data-test-id={dataTestId}>
      {itemWithIcon}
    </div>
  )
}

OrdersProcessing.propTypes = {
  space: PropTypes.number,
  to: PropTypes.string,
  'data-test-id': PropTypes.string,
}

export { OrdersProcessing }
