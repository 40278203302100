import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette/'
import { getPresetAssetsPercent } from 'helpers/goals.js'

import { presetTypes as goalPresetTypes } from 'constants/goal'

import { useMemo, useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import ModalContent, {
  ModalContentBody,
  ModalContentHeadline,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Arrow } from 'components/atoms/Arrow'
import Segment from 'components/atoms/Segment/Segment.jsx'
import Width from 'components/_old/Width/Width'
import Icon from 'components/_old/Icon/Icon.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'

import '../NewOptimalPortfolioModal.css'

const PortfolioChangedModalContent = ({
  goal,
  currentPortfolio,
  newOptimalPortfolio,
  postHeadlineText,
  onSetToNewOptimal: handleSetToNewOptimal,
  onDoNothing: handleDoNothing,
  onRetake: handleRetake,
}) => {
  const mediaQueries = useMediaQueries()
  const { desktop, tinyPhone } = mediaQueries

  const classes = classNames(
    'NewOptimalPortfolioModal',
    getMediaQuieryClasses('NewOptimalPortfolioModal', mediaQueries),
  )

  const currentRiskData = useMemo(
    () =>
      currentPortfolio
        ? {
            title: currentPortfolio?.title,
            stocks: getPresetAssetsPercent(currentPortfolio?.preset_stocks),
            bonds: getPresetAssetsPercent(currentPortfolio?.preset_bonds),
            alternatives:
              goal.preset_type === goalPresetTypes.GROWTH
                ? getPresetAssetsPercent(currentPortfolio?.preset_alternatives)
                : null,
          }
        : null,
    [currentPortfolio],
  )

  const newOptimalRiskData = useMemo(
    () =>
      newOptimalPortfolio
        ? {
            title: newOptimalPortfolio.title,
            stocks: getPresetAssetsPercent(newOptimalPortfolio.preset_stocks),
            bonds: getPresetAssetsPercent(newOptimalPortfolio.preset_bonds),
            alternatives:
              goal.preset_type === goalPresetTypes.GROWTH
                ? getPresetAssetsPercent(newOptimalPortfolio.preset_alternatives)
                : null,
          }
        : null,
    [newOptimalPortfolio],
  )

  return (
    <div className={classes} data-test-id="portfolioChanged">
      <ModalContent width={60.75}>
        <Width size={36.875} center>
          <Paper top={desktop ? null : 40} left={16} right={16}>
            <ModalContentHeadline
              className="NewOptimalPortfolioModal-Headline"
              level={desktop ? 1 : 2}
              align="center"
              paddingRight={false}
              icon={
                <Icon
                  className="NewOptimalPortfolioModal-Icon"
                  type="exclamation-triangle"
                  size={64}
                  color={palette['status-success']}
                />
              }
            >
              Your optimal portfolio has changed
            </ModalContentHeadline>
            <ModalContentBody size={null}>
              <Fragment>
                <Text block center>
                  {postHeadlineText}
                </Text>
                {currentRiskData && newOptimalRiskData && (
                  <ColumnarLayout
                    className="PortfolioChangedModalContent-Grid"
                    mods={{ direction: tinyPhone && 'column' }}
                  >
                    <Column className="PortfolioChangedModalContent-Cell" data-test-id="portfolioChangedPrevious">
                      <Segment mods={{ margin: 'smaller', noMargin: 'top' }}>
                        <Text small={!desktop}>
                          <Typo>Your current portfolio</Typo>
                        </Text>
                      </Segment>
                      <Segment>
                        <Text small={!desktop} bold data-test-id="portfolioChangedTitle">
                          <Typo>{currentRiskData.title}</Typo>
                        </Text>
                      </Segment>
                      <ColumnarLayout mods={{ padding: !desktop && 'nano' }}>
                        <Column>
                          {currentRiskData.stocks && (
                            <Segment mods={{ noMargin: 'top bottom' }}>
                              <Text small={!desktop}>
                                <Typo>Stocks</Typo>
                              </Text>
                            </Segment>
                          )}
                          {currentRiskData.bonds && (
                            <Segment mods={{ noMargin: 'bottom' }}>
                              <Text small={!desktop}>
                                <Typo>Bonds</Typo>
                              </Text>
                            </Segment>
                          )}
                          {currentRiskData.alternatives && (
                            <Segment mods={{ noMargin: 'bottom' }}>
                              <Text small={!desktop}>
                                <Typo>Alternatives</Typo>
                              </Text>
                            </Segment>
                          )}
                        </Column>
                        <Column>
                          {currentRiskData.stocks && (
                            <Segment mods={{ noMargin: 'top bottom' }}>
                              <Text small={!desktop} color="stocks">
                                <Typo>{currentRiskData.stocks}</Typo>
                              </Text>
                            </Segment>
                          )}
                          {currentRiskData.bonds && (
                            <Segment mods={{ noMargin: 'bottom' }}>
                              <Text small={!desktop} color="bonds">
                                <Typo>{currentRiskData.bonds}</Typo>
                              </Text>
                            </Segment>
                          )}
                          {currentRiskData.alternatives && (
                            <Segment mods={{ noMargin: 'bottom' }}>
                              <Text small={!desktop} color="alternatives">
                                <Typo>{currentRiskData.alternatives}</Typo>
                              </Text>
                            </Segment>
                          )}
                        </Column>
                      </ColumnarLayout>
                    </Column>
                    <Column className="PortfolioChangedModalContent-Arrow">
                      <Arrow />
                    </Column>
                    <Column className="PortfolioChangedModalContent-Cell" data-test-id="portfolioChangedNext">
                      <Segment mods={{ margin: 'smaller', noMargin: 'top' }}>
                        <Text small={!desktop}>
                          <Typo>New optimal portfolio</Typo>
                        </Text>
                      </Segment>
                      <Segment>
                        <Text small={!desktop} bold data-test-id="portfolioChangedTitle">
                          <Typo>{newOptimalRiskData.title}</Typo>
                        </Text>
                      </Segment>
                      <ColumnarLayout mods={{ padding: !desktop && 'nano' }}>
                        <Column>
                          {newOptimalRiskData.stocks && (
                            <Segment mods={{ noMargin: 'top bottom' }}>
                              <Text small={!desktop}>
                                <Typo>Stocks</Typo>
                              </Text>
                            </Segment>
                          )}
                          {newOptimalRiskData.bonds && (
                            <Segment mods={{ noMargin: 'bottom' }}>
                              <Text small={!desktop}>
                                <Typo>Bonds</Typo>
                              </Text>
                            </Segment>
                          )}
                          {newOptimalRiskData.alternatives && (
                            <Segment mods={{ noMargin: 'bottom' }}>
                              <Text small={!desktop}>
                                <Typo>Alternatives</Typo>
                              </Text>
                            </Segment>
                          )}
                        </Column>
                        <Column>
                          {newOptimalRiskData.stocks && (
                            <Segment mods={{ noMargin: 'top bottom' }}>
                              <Text small={!desktop} color="stocks">
                                <Typo>{newOptimalRiskData.stocks}</Typo>
                              </Text>
                            </Segment>
                          )}
                          {newOptimalRiskData.bonds && (
                            <Segment mods={{ noMargin: 'bottom' }}>
                              <Text small={!desktop} color="bonds">
                                <Typo>{newOptimalRiskData.bonds}</Typo>
                              </Text>
                            </Segment>
                          )}
                          {newOptimalRiskData.alternatives && (
                            <Segment mods={{ noMargin: 'bottom' }}>
                              <Text small={!desktop} color="alternatives">
                                <Typo>{newOptimalRiskData.alternatives}</Typo>
                              </Text>
                            </Segment>
                          )}
                        </Column>
                      </ColumnarLayout>
                    </Column>
                  </ColumnarLayout>
                )}
                <Segment mods={{ margin: desktop ? 'smallest' : null, noMargin: 'top' }}>
                  <Text block center>
                    <Typo>You can always have a new risk assessment via Profile</Typo>
                  </Text>
                </Segment>
              </Fragment>
            </ModalContentBody>
            <Width className="PortfolioChangedModalContent-Buttons" size={desktop ? 20.75 : null} center>
              <Button
                className="NewOptimalPortfolioModal-Button"
                mods={{ color: 'green', size: 'big block', text: 'bigger' }}
                onClick={handleSetToNewOptimal}
                data-test-id="portfolioChangedSwitchButton"
              >
                Switch to the new optimal portfolio
              </Button>
              <Button
                className="NewOptimalPortfolioModal-Button"
                mods={{ size: 'big block', text: 'bigger' }}
                onClick={handleDoNothing}
                data-test-id="portfolioChangedNotSwitchButton"
              >
                Do not switch current portfolio
              </Button>
              <Button
                className="NewOptimalPortfolioModal-Button"
                mods={{ theme: 'simple-reverse-blue', size: 'big block', text: 'bigger' }}
                onClick={handleRetake}
                data-test-id="portfolioChangedRetakeButton"
              >
                Retake risk questionnaire
              </Button>
            </Width>
          </Paper>
        </Width>
      </ModalContent>
    </div>
  )
}

const portfolioShape = PropTypes.shape({
  title: PropTypes.string,
  preset_type: PropTypes.string,
  preset_stocks: PropTypes.array,
  preset_bonds: PropTypes.array,
  preset_alternatives: PropTypes.array,
})

PortfolioChangedModalContent.propTypes = {
  goal: portfolioShape.object,
  currentPortfolio: portfolioShape.isRequired,
  newOptimalPortfolio: portfolioShape.isRequired,
  onSetToNewOptimal: PropTypes.func.isRequired,
  onDoNothing: PropTypes.func.isRequired,
  onRetake: PropTypes.func.isRequired,
}

export { PortfolioChangedModalContent }
