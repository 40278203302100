import axios from 'helpers/ajax'
import { features } from 'helpers/features'

import { ApiError } from 'app/redux/models/errors'

import { mockCountries } from './mockCountries'
import { mockCountry } from './mockCountry'

const getAnalytics = async ({ portfolioId, securityId, presetId }): Promise<any> => {
  try {
    const queryArray = []

    if (portfolioId) {
      queryArray.push(['portfolio_id', portfolioId])
    }

    if (securityId) {
      queryArray.push(['security_id', securityId])
    }

    if (presetId) {
      queryArray.push(['preset_id', presetId])
    }

    const query = queryArray.reduce(
      (result, [name, value], index) => `${result}${index === 0 ? '?' : '&'}${name as string}=${value as string}`,
      '',
    )

    const { data } = await axios.get(`analytics/full/${query}`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const getHoldingAnalytics = async ({ type, id, portfolioId }): Promise<any> => {
  try {
    let response = { data: {} }

    if (type === 'country' && features?.get('country-drill-down')) {
      response.data = mockCountry
    } else {
      response = await axios.get(`analytics/by_type/${type.toUpperCase()}/${id}/`, {
        params: { portfolio_id: portfolioId },
      })
    }

    if (type === 'region' && features?.get('country-drill-down')) {
      response.data.countries = mockCountries
    }

    return response.data
  } catch (error) {
    return new ApiError(error)
  }
}

export { getAnalytics, getHoldingAnalytics }
