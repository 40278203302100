import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'

import { MobileFooter } from 'app/containers/Footer'
import { MobileHeader } from 'app/containers/Header'

import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Select } from 'components/molecules/Select'
import { SharePortfolioBanner } from 'components/molecules/SharePortfolioBanner'

import { PortfolioType } from '../components/PortfolioType.jsx'
import { summaryItemPropTypes, equitiesPropTypes } from '../Analytics.jsx'

import { portfolioManageTypes } from 'constants/goal'

const AnalyticsMobileLayout = ({
  scrollableElementRef,
  fromPortfolio,
  portfoliosOptions,
  selected,
  selectedPortfolio,
  selectedPortfolioTitle,
  handlePortfolioSelect,
  handleBackToPortfolio,
  tabs,
  topContent,
  bottomContent,
}) => {
  const { tinyPhone } = useMediaQueries()

  const headerNode = useMemo(() => {
    if (fromPortfolio) {
      return (
        <NavigationBar leftPartText="Back" onLeftPartClick={handleBackToPortfolio}>
          Analytics
        </NavigationBar>
      )
    }

    return <MobileHeader pageName="Analytics" />
  }, [])

  const headlineNode = useMemo(() => {
    if (portfoliosOptions.length === 0) {
      return (
        <Typography size={32} lineHeight="small" weight="semibold">
          <Typo>{selectedPortfolioTitle}</Typo>
        </Typography>
      )
    }

    return <Select selected={selected} options={portfoliosOptions} onSelect={handlePortfolioSelect} />
  }, [selected, selectedPortfolioTitle, portfoliosOptions, handlePortfolioSelect])

  const portfolioTypeNode = useMemo(() => {
    if (!selectedPortfolio) {
      return null
    }

    return (
      <Paper top={8}>
        <PortfolioType portfolio={selectedPortfolio} />
      </Paper>
    )
  }, [selectedPortfolio])

  const shareButton = useMemo(
    () =>
      selectedPortfolio?.manage_type === portfolioManageTypes.DIY ? (
        <Paper top={56}>
          <SharePortfolioBanner portfolioId={selectedPortfolio.id} isSharingEnabled={!!selectedPortfolio.share_url} />
        </Paper>
      ) : null,
    [selectedPortfolio],
  )

  const contentNode = useMemo(
    () => (
      <Fragment>
        {headlineNode}
        {portfolioTypeNode}
        {tabs}
        {topContent}
        {React.cloneElement(bottomContent, { layoutNode: scrollableElementRef })}
        {shareButton}
        <MobileFooter />
      </Fragment>
    ),
    [tinyPhone, headlineNode, portfolioTypeNode, scrollableElementRef, topContent, bottomContent],
  )

  return <MobileLayout ref={scrollableElementRef} header={headerNode} content={contentNode} />
}

AnalyticsMobileLayout.propTypes = {
  scrollableElementRef: PropTypes.object,
  fromPortfolio: PropTypes.bool,
  portfoliosOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selected: PropTypes.string,
  selectedPortfolio: PropTypes.object,
  selectedPortfolioTitle: PropTypes.string,
  showTarget: PropTypes.bool,
  summary: PropTypes.shape({
    total: PropTypes.number,
    actual: PropTypes.number,
    target: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    stocks: PropTypes.shape(summaryItemPropTypes),
    bonds: PropTypes.shape(summaryItemPropTypes),
    alternatives: PropTypes.shape(summaryItemPropTypes),
    cash: PropTypes.shape(summaryItemPropTypes),
  }),
  stocks: PropTypes.shape(equitiesPropTypes),
  bonds: PropTypes.shape(equitiesPropTypes),
  alternatives: PropTypes.shape(equitiesPropTypes),
  cash: PropTypes.shape(equitiesPropTypes),
  handlePortfolioSelect: PropTypes.func,
  handleBackToPortfolio: PropTypes.func,
  tabs: PropTypes.node,
  topContent: PropTypes.node,
  bottomContent: PropTypes.node,
}

export { AnalyticsMobileLayout }
