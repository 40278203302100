import React, { Fragment } from 'react'

import { useMediaQueries, useRef, useState, useEffect } from 'hooks'

import Icon from 'components/_old/Icon/Icon.jsx'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { MarkdownRenderer } from 'components/molecules/MarkdownRenderer'

import { useLockHeaderScroll } from 'app/containers/LockHeaderScrollProvider'

import './SecurityDescription.css'
import useMaxHeight from './hooks/useMaxHeight'

type SecurityDescriptionProps = {
  description: string
}

const MAX_CHARACTER_LENGTH = 350

const SecurityDescription = ({ description }: SecurityDescriptionProps): JSX.Element => {
  const [isFullDescription, setFullDescription] = useState(false)
  const isLongDescription = description.length > MAX_CHARACTER_LENGTH
  const { desktop } = useMediaQueries()
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isScrolling, setIsScrolling] = useState(false)
  const { lockHeaderScroll, setLockHeaderScroll } = useLockHeaderScroll()

  const scrollableElement = desktop
    ? document.querySelector('.DesktopLayout-Element_scrollable')
    : document.querySelector('.MobileLayout-Content')
  const maxHeight = useMaxHeight(isFullDescription, scrollableElement)
  const ref = useRef(null)

  const toggleDescription = (): void => {
    const nextIsFullDescription = !isFullDescription
    setFullDescription(nextIsFullDescription)
    if (scrollableElement) {
      setScrollPosition(scrollableElement.scrollTop)
    }
    setLockHeaderScroll(true)
    setIsScrolling(true)
  }

  useEffect(() => {
    // prevents scroll on regular window resizing
    if (!isScrolling) return

    if (isFullDescription) {
      scrollableElement?.scrollTo({
        top: scrollPosition,
        behavior: 'instant',
      })
    } else {
      scrollableElement?.scrollTo({
        top: scrollPosition - (maxHeight.open - maxHeight.close),
        behavior: 'instant',
      })
    }

    setTimeout(() => {
      setIsScrolling(false)
    }, 0)

    if (lockHeaderScroll) {
      setTimeout(() => {
        setLockHeaderScroll(false)
        // delay is for safari, for some reason locking doesn't work with 0s delay
      }, 100)
    }
  }, [
    isFullDescription,
    scrollPosition,
    maxHeight,
    isScrolling,
    scrollableElement,
    lockHeaderScroll,
    setLockHeaderScroll,
  ])

  return (
    <Fragment>
      <div className={isFullDescription ? '' : 'SecurityDescription_truncated'} ref={ref}>
        <Typography tag="h2" size={24} lineHeight="small" weight="semibold" className="SecurityDescription-Title">
          Details
        </Typography>
        <MarkdownRenderer isInteractive={isFullDescription} desktop={desktop}>
          {description}
        </MarkdownRenderer>
      </div>
      {isLongDescription && (
        <Paper top={16}>
          <button onClick={toggleDescription} className="SecurityDescription-Button">
            <Typography tag="span" size={desktop ? 16 : 14} lineHeight="medium" color="additional">
              {isFullDescription ? 'Hide' : 'Show all'}
            </Typography>
            <Icon
              type={isFullDescription ? 'arrow-up-16' : 'arrow-down-16'}
              color="var(--content-on-background-additional)"
              size={16}
            />
          </button>
        </Paper>
      )}
    </Fragment>
  )
}

export { SecurityDescription }
