import { useUnit } from 'effector-react'
import isEmpty from 'lodash/isEmpty'

import { useActions, useEffect, useLoading, useSelector } from 'hooks'

import { $portfolioSecuritiesStore } from 'app/effector/portfolio-securities'
import { fetchPortfolioSecuritiesFx } from 'app/effector/portfolio-securities/'
import { fetchSecuritiesFx, $securitiesStore } from 'app/effector/securities'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'

/**
 * @param {object} [options={}]
 * @param {number} options.portfolioId
 * @param {boolean} [options.perspective=false]
 * @return {{portfolioSecurities: Security[], securities: SecurityList}}
 */
export const useSecurities = ({ portfolioId, perspective = false } = {}) => {
  const { securities } = useUnit($securitiesStore)
  const { getSecuritiesByPortfolio, selectSecuritiesInPortfolio } = useUnit($portfolioSecuritiesStore)

  const portfolioSecurities = getSecuritiesByPortfolio(portfolioId, perspective)
  const securitiesInPortfolio = selectSecuritiesInPortfolio(portfolioId, perspective)
  const portfolios = useSelector((state) => state.portfolios.list)

  const shouldLoadSecurities = isEmpty(securities)
  const shouldLoadPortfolios = isEmpty(portfolios)
  const shouldLoadPortfolioSecurities = perspective
    ? typeof portfolioSecurities === 'undefined' && portfolioId
    : portfolioId

  const { isLoading: isSecuritiesLoading, wait: waitForSecurities } = useLoading(shouldLoadSecurities)
  const { isLoading: isPortfoliosLoading, wait: waitForPortfolios } = useLoading(shouldLoadPortfolios)
  const { isLoading: isPortfolioSecuritiesLoading, wait: waitForPortfolioSecurities } =
    useLoading(shouldLoadPortfolioSecurities)
  const isLoading = isSecuritiesLoading || isPortfoliosLoading || isPortfolioSecuritiesLoading

  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])

  useEffect(() => {
    if (shouldLoadSecurities) {
      waitForSecurities(fetchSecuritiesFx({ featured_data: 'true' }))
    }
    if (shouldLoadPortfolios) {
      waitForPortfolios(fetchPortfolios())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (shouldLoadPortfolioSecurities) {
      waitForPortfolioSecurities(fetchPortfolioSecuritiesFx({ portfolioId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId])

  return {
    securities,
    portfolioSecurities: securitiesInPortfolio,
    isLoading,
  }
}
