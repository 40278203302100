import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'
import { useIsaDeclaration } from './hooks'

import { Desktop } from 'app/pages/Dashboard/Goals/ISADeclaration/Desktop'
import { Mobile } from 'app/pages/Dashboard/Goals/ISADeclaration/Mobile'
import { FinishRegistrationModal } from 'app/pages/Dashboard/Accounts/components/OpenSippModal/components/FinishRegistrashionModal'

const ISADeclaration = ({ params: { id: idString }, location: { query } }) => {
  const { desktop } = useMediaQueries()
  const props = useIsaDeclaration(idString, query)
  const page = desktop ? <Desktop {...props} /> : <Mobile {...props} />

  return (
    <Fragment>
      {page}
      {props.finishRegistrationModalOpened && (
        <FinishRegistrationModal
          handleClose={props.handleFinishRegistrationModalClose}
          handleContinue={props.handleFinishRegistrationModalContinue}
        />
      )}
    </Fragment>
  )
}

ISADeclaration.propTypes = {
  isTunnel: PropTypes.bool,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  diy: PropTypes.bool,
  location: PropTypes.shape({
    query: PropTypes.object,
  }),
}

export { ISADeclaration }
