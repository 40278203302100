enum State {
  INITIAL = 'INITIAL',
  ACCEPT = 'ACCEPT',
  DENIED = 'DENIED',
  CLOSED = 'CLOSED',
}

enum Event {
  ACCEPT = 'ACCEPT',
  DENY = 'DENY',
  CLOSE = 'CLOSE',
  ERROR = 'ERROR',
  CANCEL = 'CANCEL',
}

const states = {
  [State.INITIAL]: {
    on: {
      [Event.ACCEPT]: State.ACCEPT,
      [Event.DENY]: State.DENIED,
      [Event.CANCEL]: State.CLOSED,
      [Event.ERROR]: State.CLOSED,
    },
  },
  [State.ACCEPT]: {
    entry: ['handleAccept'],
  },
  [State.DENIED]: {
    on: {
      [Event.CLOSE]: State.CLOSED,
    },
  },
  [State.CLOSED]: {
    entry: ['logout'],
  },
}

export { states, State, Event }
