import React from 'react'

import { useMediaQueries } from 'hooks'

import type { Security } from 'app/effector/securities/models'

import { Typo } from 'components/_old/Typo/Typo'

import { CardNew } from 'components/atoms/CardNew'
import { Gateway } from 'components/atoms/Gateway'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import { useSecurityDetails } from './useSecurityDetails'

import './SecurityDetails.css'

type SecurityDetailsProps = {
  security: Security
}

const SecurityDetails = ({ security }: SecurityDetailsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { securityDetailsData, showModal, modalContent, handleOpenModal, handleCloseModal } = useSecurityDetails({
    security,
  })

  return (
    <div>
      <Paper className="SecurityDetails_List">
        {securityDetailsData.map((item, index) => {
          const noPadding = (index + 1) % (desktop ? 3 : 2) === 0

          return (
            <Paper
              key={item.title}
              className={desktop ? 'SecurityDetails_Item' : 'SecurityDetails_Item_mobile'}
              top={8}
              right={noPadding ? 0 : 8}
              onClick={() => {
                handleOpenModal(item.description)
              }}
            >
              <CardNew borderRadius={10} className="SecurityDetails_Card" hoverable>
                <Paper top={12} bottom={12} left={16} right={16}>
                  <Typography size={14} lineHeight="small" color="minor">
                    <Typo>{item.title}</Typo>
                  </Typography>
                  <Paper top={4}>
                    <Typography size={desktop ? 16 : 14} lineHeight="small" color="additional">
                      <Typo>{item.value}</Typo>
                    </Typography>
                  </Paper>
                </Paper>
              </CardNew>
            </Paper>
          )
        })}
      </Paper>
      <Gateway into="modals">
        <Modal open={showModal} onClose={handleCloseModal}>
          <ModalContent width={38}>
            {!desktop && <NavigationBar rightPartText="Close" onRightPartClick={handleCloseModal} />}
            <Paper top={desktop ? 80 : 32} bottom={desktop ? 80 : 0} left={desktop ? 80 : 16} right={desktop ? 80 : 16}>
              <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold">
                <Typo>{modalContent.title}</Typo>
              </Typography>
              <Paper top={16}>
                <Typography>
                  <Typo multiline>{modalContent.text}</Typo>
                </Typography>
              </Paper>
            </Paper>
          </ModalContent>
        </Modal>
      </Gateway>
    </div>
  )
}

export { SecurityDetails }
