import { ApiError } from 'app/redux/models/errors'

type ApiCall<Result, Data> = (data?: Data) => Promise<Result | ApiError>

async function makeApiCall<Result, Data>(apiCall: ApiCall<Result, Data>, data?: Data): Promise<Result> {
  const result = await apiCall(data)

  if (result instanceof ApiError) {
    throw result
  }

  return result
}

export { makeApiCall }
