import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'

import { useSelector } from 'hooks'

import { palette } from 'helpers/palette/'

import { $owner } from 'app/effector/contacts'

import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { UserAvatar } from 'components/molecules/UserAvatar/UserAvatar'

const PresetSubtitle = (): React.ReactElement => {
  const client = useSelector((state) => state.client)
  const owner = useUnit($owner) ?? {}

  const tooltipTextNode = (
    <Fragment>
      <Typography size={24}>
        <Typo>Picked by you, managed by IE</Typo>
      </Typography>
      <Paper top={24}>
        <Typography size={14} data-test-id="tooltipDescription">
          <Typo>
            You choose the LifePlan portfolio that’s appropriate for you, based on your financial goals, timeline and
            comfort with risk. We’ll handle the day-to-day investment management, asset allocation and rebalancing to
            keep your portfolio on track.
          </Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  return (
    <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
      <Column size={0}>
        <Paper right={8}>
          <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
            <Column size={0}>
              <Paper right={4}>
                <UserAvatar
                  size="16"
                  firstName={owner.first_name}
                  lastName={owner.last_name}
                  image={client.avatar}
                  dataTestId="subtitleAvatar"
                />
              </Paper>
            </Column>
            <Column size={0}>
              <Icon type="logo-16" size={16} color={palette['primary-default']} />
            </Column>
          </ColumnarLayout>
        </Paper>
      </Column>
      <Column size={1}>
        <Typography lineHeight="small" color="minor" data-test-id="subtitleText">
          <Typo>Picked by you, managed by IE</Typo>{' '}
          <TooltipToModal description={tooltipTextNode} customIcon offset={-330} data-test-id="subtitleTooltipIcon">
            <Link mods={{ color: 'minorToRed' }} style={{ display: 'inline-flex' }}>
              <Icon size={16} type="information-16" color="inherit" style={{ position: 'relative', top: '3px' }} />
            </Link>
          </TooltipToModal>
        </Typography>
      </Column>
    </ColumnarLayout>
  )
}

export { PresetSubtitle }
