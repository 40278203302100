import { AddressList } from 'app/redux/models/address'
import { Entity } from 'app/redux/models/common/Entity.js'

export class SignificantControl extends Entity {
  constructor() {
    super()

    /** @type {?number} */
    this.id = null
    /** @type {?string} */
    this.state = null
    /** @type {?string} */
    this.name = null
    /** @type {AddressList} */
    this.addresses = new AddressList()
  }

  /** @override */
  getRules() {
    return {
      addresses: [[(value) => AddressList.createFromObject({ list: value })]],
    }
  }
}
