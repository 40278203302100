import React from 'react'

import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { useMemo, useRedirect, useSelector, useLoading, useEffect, useActions, usePrevious } from 'hooks'

import { identify, analyticsCIO } from 'helpers/analytics'
import { format as formatMoney } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router.js'

import {
  fetchPortfolios as fetchPortfoliosActionCreator,
  setValid as setPortfoliosValidActionCreator,
} from 'app/redux/actions/portfolios'

import GlobalPreloader from 'components/_old/GlobalPreloader/GlobalPreloader.jsx'

import type { ManagedPortfolio, RouteProps } from './types'

import { GP_DEFAULT } from 'constants/globalPreloaderStatuses'
import { portfolioManageTypes, presetTypes } from 'constants/goal'

type ManagedPortfolioProps = {
  routes: RouteProps[]
  params: {
    id: string
    securityId: string
  }
  location: {
    query: {
      view: string
    }
  }
}

const getDefaultGoal = (goals: ManagedPortfolio[] = []): ManagedPortfolio => {
  const payedGoal = goals.find((goal) => goal.contributions && parseFloat(goal.contributions.total) > 0)

  return payedGoal ?? goals[0]
}

const ManagedPortfolioRedirect = ({
  params: { id, securityId },
  routes,
  location,
}: ManagedPortfolioProps): React.ReactElement => {
  const goals = useSelector((state) => state.portfolios.items)
  const client = useSelector((state) => state.client)

  const goal: ManagedPortfolio = useMemo(() => {
    const goal = !isNil(id) ? goals.find((goal) => goal.id === parseInt(id, 10)) : null

    return goal ?? getDefaultGoal(goals)
  }, [goals, id])

  const [fetchPortfolios, setValid] = useActions([fetchPortfoliosActionCreator, setPortfoliosValidActionCreator])

  const fetchPortfoliosWithDependencies = async (): Promise<void> => {
    const stateAfterFetch = await fetchPortfolios({ setValidAfter: false })

    const currentBalance = stateAfterFetch.portfolios.items.reduce(
      (acc, goal) => Number(acc) + Number(goal.current_balance),
      0,
    )
    identify(client.id, { clientId: client.id, clientBalance: formatMoney(currentBalance, true) })

    if (client.email) {
      analyticsCIO?.identify({
        id: client.email,
      })
    }

    setValid()
  }

  const prevId = usePrevious(id)

  const { isLoading, wait } = useLoading()

  useRedirect({
    to: () => {
      goTo(urlTo('dashboard.portfolio', { id: goal?.id }, location.query))
    },
    rule: !id,
    isLoading,
  })

  useRedirect({
    to: () => {
      goTo(urlTo('dashboard.portfolio', { id: goal?.id }, location.query))
    },
    rule: goal?.manage_type === portfolioManageTypes.DIY,
    isLoading,
  })

  // If we don’t have any loaded goals at all
  useEffect(() => {
    if (isEmpty(goals) || (id && id !== prevId)) {
      wait(Promise.all([fetchPortfoliosWithDependencies()]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useRedirect({
    to: () => {
      const moduleName = routes
        .map((route) => {
          return route.module === 'portfolio' || route.module === 'portfolio-old' ? `portfolio` : route.module
        })
        .join('.')
      goTo(urlTo(moduleName, { id: goal?.id, securityId }, location.query), { replace: true })
    },
    rule: goal?.preset_type === presetTypes.INCOME || goal?.preset_type === presetTypes.GROWTH,
    isLoading,
  })

  return <GlobalPreloader status={GP_DEFAULT} loading />
}

export { ManagedPortfolioRedirect }
