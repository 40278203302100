import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { goTo, urlTo } from 'helpers/router.js'
import { palette } from 'helpers/palette/'

import { types as clientTypes } from 'constants/client'
import { yearlyReviewTypes } from 'constants/portfolio'

import { useSelector, useMediaQueries, useCallback } from 'hooks'

import { Modal } from 'components/molecules/Modal'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import ModalContent, {
  ModalContentHeadline,
  ModalContentButtons,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'
import Segment from 'components/atoms/Segment/Segment.jsx'

const ReviewModal = ({ portfolioId }) => {
  const { desktop } = useMediaQueries()

  const client = useSelector((state) => state.client)
  const portfolio = useSelector((state) => state.portfolios.list.get(portfolioId))
  const open = portfolio?.isItTimeToYearlyReview?.() === yearlyReviewTypes.MODAL

  const handleReview = useCallback(() => {
    goTo(urlTo('yearly-review', { goalId: portfolio.id }), { replace: true })
  }, [portfolio])

  const content = (
    <Fragment>
      <ModalContentHeadline>
        <Text center block style={{ lineHeight: 1, marginTop: desktop ? '2em' : null, marginBottom: '1em' }}>
          <Icon type="exclamation-triangle" size={desktop ? 56 : 44} inline color={palette['status-warning']} />
        </Text>
        <Headline level={desktop ? 2 : 3} align="center" mods={{ 'no-margin': 'top' }}>
          <Typo>
            Review your {client.type === clientTypes.BUSINESS ? 'business' : 'personal'} information and investment
            requirements to continue
          </Typo>
        </Headline>
      </ModalContentHeadline>
      <Width center size={desktop ? 30 : null}>
        <Text center block>
          <Typo>
            We need to confirm that the information we hold is up-to-date and your portfolio is still suitable for your
            needs.
          </Typo>
        </Text>
      </Width>
      <ModalContentButtons
        text="Review now"
        onClick={handleReview}
        phantomButtonsMarginSize="small"
        isNewModal
        data-test-id="reviewPopupButton"
      />
    </Fragment>
  )

  return (
    <Modal open={open} close={null} gatewayName="review" data-test-id="reviewPopup">
      {desktop && (
        <ModalContent width={56}>
          <Segment mods={{ margin: 'huge' }}>
            <Width size={48} center>
              {content}
            </Width>
          </Segment>
        </ModalContent>
      )}
      {!desktop && (
        <AllCenter>
          <Inner>{content}</Inner>
        </AllCenter>
      )}
    </Modal>
  )
}

ReviewModal.propTypes = {
  portfolioId: PropTypes.number,
}

export { ReviewModal }
