import { useActions, useCallback, useEffect, useRef, useState } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'
import { bulkValidate, monthlyInvestmentRules } from 'helpers/validation.js'

import { fetchBankAccountsFx } from 'app/effector/bank-accounts'

import { changeModalField as changeModalFieldActionCreator } from 'app/redux/actions/ui'

import { usePaymentDetails } from './usePaymentDetails'

import type { UsePaymentDetailsInterface } from './usePaymentDetails'

import { bankAccountStates } from 'constants/bankAccounts'
import { regulatoryTypes as portfolioRegulatoryTypes } from 'constants/portfolio'

type UseDirectDebitFormInterface = UsePaymentDetailsInterface & {
  bankErrorRef: React.RefObject<HTMLDivElement>
  isContinueButtonDisabled: boolean
  isBankErrorVisible: boolean
  validation: object
  handleContinue: () => void
  handleGuarantee: () => void
  handleMonthlyInvestmentChange: (event, value) => void
  handleMonthlyPaymentDayChange: (event, value) => void
  handleConfirmCheckboxChange: () => void
}

const useDirectDebitForm = (
  portfolio: object,
  location: { query: Record<string, string> },
): UseDirectDebitFormInterface => {
  const {
    accountHolder,
    accountNumber,
    nominatedAccount,
    sortCode,
    monthlyPaymentAmount,
    monthlyPaymentDay,
    confirmCheckbox,
    directDebitSubscription,
  } = usePaymentDetails(portfolio.id)

  const bankErrorRef = useRef()

  const [isBankErrorVisible, setIsBankErrorVisible] = useState(false)

  const [changeModalField] = useActions([changeModalFieldActionCreator])

  const changeField = useCallback(
    (field, value): void => {
      changeModalField('directDebit', { [field]: value })
    },
    [changeModalField],
  )

  const handleContinue = useCallback(() => {
    if (nominatedAccount?.state === bankAccountStates.AWAITING_APPROVAL) {
      setIsBankErrorVisible(true)
      return
    }

    goTo(urlTo(`dashboard.portfolio.options.direct-debit-details`, { id: portfolio.id }, location.query))

    changeField('confirmCheckbox', false)
  }, [nominatedAccount?.state, portfolio.id, location.query, changeField])

  const handleGuarantee = useCallback(() => {
    goTo(urlTo(`dashboard.portfolio.options.direct-debit-guarantee`, { id: portfolio.id }, location.query))
  }, [portfolio.id, location])

  const handleMonthlyInvestmentChange = useCallback(
    (_event, value = null) => {
      changeField('monthlyPaymentAmount', value)
    },
    [changeField],
  )

  const handleMonthlyPaymentDayChange = useCallback(
    (_event, value = null) => {
      changeField('monthlyPaymentDay', value)
    },
    [changeField],
  )

  const handleConfirmCheckboxChange = useCallback(() => {
    changeField('confirmCheckbox', !confirmCheckbox)
  }, [changeField, confirmCheckbox])

  const isFirstPayment = !portfolio.first_topup

  const isISAallowedAmount =
    (portfolio.regulatory_type === portfolioRegulatoryTypes.ISA && portfolio.isa) ||
    portfolio.regulatory_type !== portfolioRegulatoryTypes.ISA

  useEffect(() => {
    if (isFirstPayment && !directDebitSubscription && !monthlyPaymentAmount) {
      changeField('monthlyPaymentAmount', portfolio.monthly_deposit)
    }

    if (directDebitSubscription) {
      changeField('monthlyPaymentAmount', directDebitSubscription.amount)
      changeField('monthlyPaymentDay', directDebitSubscription.day_of_month)
    }

    if (!sortCode) {
      fetchBankAccountsFx()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directDebitSubscription?.amount])

  useEffect(() => {
    if (isBankErrorVisible) {
      bankErrorRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [isBankErrorVisible])

  const monthlyPaymentAmountNum = !monthlyPaymentAmount ? null : parseFloat(monthlyPaymentAmount)
  const monthlyPaymentDayNum = !monthlyPaymentDay ? null : parseInt(monthlyPaymentDay, 10)

  const validation = {
    monthlyPaymentAmount: monthlyInvestmentRules(monthlyPaymentAmountNum, {
      optional: false,
      limitMaximumAmount: true,
    }),
    monthlyPaymentDay: {
      rules: [
        (monthlyPaymentAmountNum && monthlyPaymentDayNum && monthlyPaymentDayNum >= 1 && monthlyPaymentDayNum <= 28) ??
          !monthlyPaymentAmountNum,
      ],
      errors: ['Must be 1 to 28'],
    },
  }

  const isContinueButtonDisabled =
    !bulkValidate(validation) ||
    (!isFirstPayment && !monthlyPaymentAmountNum) ||
    (!isFirstPayment &&
      directDebitSubscription &&
      // eslint-disable-next-line
      directDebitSubscription.amount === monthlyPaymentAmountNum.toFixed(2)) ||
    !confirmCheckbox ||
    !isISAallowedAmount

  return {
    bankErrorRef,
    accountHolder,
    accountNumber,
    sortCode,
    monthlyPaymentAmount,
    monthlyPaymentDay,
    confirmCheckbox,
    directDebitSubscription,
    isContinueButtonDisabled,
    isBankErrorVisible,
    validation,
    handleContinue,
    handleGuarantee,
    handleMonthlyInvestmentChange,
    handleMonthlyPaymentDayChange,
    handleConfirmCheckboxChange,
  }
}

export { useDirectDebitForm }
