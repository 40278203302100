import React from 'react'

import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

const BasicModalInside = ({ iconType, imageSrc, iconSize, iconColor, title, description, onClose: handleClose }) => {
  const { desktop } = useMediaQueries()

  const inner = (
    <Inner>
      <AllCenter>
        <Paper top={desktop ? 80 : 48}>
          {iconType === 'image' ? (
            <img src={imageSrc} height={104} data-test-id="successIcon" alt="" />
          ) : (
            <Typography size={16}>
              <Icon type={iconType} color={iconColor} size={iconSize} data-test-id="successIcon" />
            </Typography>
          )}
        </Paper>
      </AllCenter>
      <Paper top={desktop ? 32 : 12}>
        <Width size={desktop ? 32 : null} center>
          <Typography size={desktop ? 32 : 24} lineHeight="small" align="center" data-test-id="modalTitle">
            <Typo>{title}</Typo>
          </Typography>
        </Width>
      </Paper>
      <Paper top={desktop ? 24 : 12}>
        <Width size={desktop ? 28 : null} center>
          <Typography size={16} align="center">
            <Typo>{description}</Typo>
          </Typography>
        </Width>
      </Paper>
      {desktop && (
        <ModalContentButtons width={16} isNewModal phantomButtonsMarginSize="zero">
          <Paper top={24} bottom={48}>
            <Button mods={{ size: 'big block' }} onClick={handleClose} data-test-id="continueButton">
              Continue
            </Button>
          </Paper>
        </ModalContentButtons>
      )}
    </Inner>
  )

  if (desktop) {
    return (
      <Width size={44} center>
        {inner}
      </Width>
    )
  }

  const buttons = (
    <Width size={24} center>
      <Paper top={0} bottom={0}>
        <MobileLayoutFooterButton>
          <Button mods={{ size: 'big block' }} onClick={handleClose} data-test-id="continueButton">
            Continue
          </Button>
        </MobileLayoutFooterButton>
      </Paper>
    </Width>
  )

  return <MobileLayout content={<AllCenter>{inner}</AllCenter>} footer={buttons} />
}

BasicModalInside.propTypes = {
  iconType: PropTypes.string,
  imageSrc: PropTypes.string,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func,
}

export { BasicModalInside }
