import axios from 'axios'

const getBonuses = async () => {
  const isProduction = typeof window !== 'undefined' && /investengine.com/.test(window.location.origin)

  const strapiUrl = isProduction ? 'https://cms.investengine.com' : 'https://cms.rscwealth.com'
  const strapiApiKey = isProduction
    ? 'a03aeacbef2c5b4247b9ee8e83f42e7b94799effbde54565b231c2aa717991e932ac0c2130f0cd0c0022c335f4dda127ba9d55d3254d73316fe7fe88addeae7af58cb54f515f96adc59cd9bfdbacd7ec1d1eceb176095ea8c66ff047fa4a6a9996357a36885e2f71df9b923c9228e1fb1d83e751efb4f3f10f72eb7612a4732f'
    : '31c953b54420278c1c8e31062069d1b405d041335d6d4434eb9fa353f67c54f758e6676a765ed0f6371e63d10be7b25d906e3bbabfa20b1f5bfd49f14271562056c3bcd6d9b62f240e7315e721b09dbe48646a59659022b939323259019abf5e4934563712c312a0b1cfe4773b655e1f8c163358c1bb1682c6f7eabd9c648338'

  const config = {
    headers: {
      Authorization: `Bearer ${strapiApiKey}`,
    },
  }

  const url = `${strapiUrl}/api/common-site-datum/`

  try {
    const {
      data: {
        data: { attributes },
      },
    } = await axios.get(url, config)

    return attributes
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export { getBonuses }
