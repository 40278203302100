/* eslint-disable no-irregular-whitespace */

import Typograf from 'typograf'

const sp = '\u0020' // regular space
const hrsp = '\u200A' // hair space
const nanbsp = '\u202F' // narrow no-break space
const ndash = '\u2013' // en dash (ndash)
const mdash = '\u2014' // em dash (mdash)
const thinsp = '\u2009' // thin space (thinsp)
const hprn = '\u2010' // hypren
const nbhprn = '\u2011' // non-breaking hyphen
const nbsp = '\u00A0' // no-break space (nbsp)
const zwsp = '\u200b' // zero-width space,

const symbols = { sp, hrsp, nanbsp, ndash, mdash, thinsp, hprn, nbhprn, nbsp, zwsp }

Typograf.addRule({
  name: 'common/investEngine/basic',
  index: 2001,
  handler: (text) => {
    const ruleset = [
      // `+ Add a director` → `+${nbsp}Add a director`
      [/\+\s(\w)/g, `+${nbsp}$1`],
      // `20 - 50 - 70` → `<nobr>20${hrsp}${ndash}${hrsp}50${hrsp}${ndash}${hrsp}70</nobr>`
      [
        /(£?[*\d]+[%K]?)\s?[-–—]\s?(£?[*\d]+[%K]?)\s?[-–—]\s?(£?[*\d]+[%K]?)/g,
        `<nobr>$1${hrsp}${ndash}${hrsp}$2${hrsp}${ndash}${hrsp}$3</nobr>`,
      ],
      // `20 - 50` → `<nobr>20${hrsp}${ndash}${hrsp}50</nobr>`
      [/(£?[\d]+[%K]?)\s?[-–—]\s?(£?[\d]+[%K]?)/g, `<nobr>$1${hrsp}${ndash}${hrsp}$2</nobr>`],
      // `15</nobr> index` → `15 index</nobr>`
      [/(\d{1,2})<\/nobr>(\s)([a-zA-Z]*)/g, `$1$2$3</nobr>`],
      // ` / ` → `${thinsp}/${thinsp}` (with )
      [/\s\/\s/g, `${thinsp}/${thinsp}`],
      // `market-weighted` → `market${nbhprn}weighted`
      [/(\w*)-(\w*)/g, `$1${nbhprn}$2`],
      // `and ` → `and${nbsp}`
      [/([aA]nd)\s/g, `$1${nbsp}`],
      // `or ` → `or${nbsp}`
      [/([oO]r)\s/g, `$1${nbsp}`],
      // `that is` → `that${nbsp}is`
      [/([tT]hat)\sis/g, `$1${nbsp}is`],
      // `via ` → `via${nbsp}`
      [/([vV]ia)\s/g, `$1${nbsp}`],
      // `the ` → `the${nbsp}`
      [/([tT]he)\s/g, `$1${nbsp}`],
      // `a|an ` → `a|an${nbsp}
      [/([aA]n?)\s/g, `$1${nbsp}`],
      // `GBP (£)` → `GBP (£)`
      [/([gG][bB][pP])(\s|\u2009|\u00A0)+?\(£\)/g, `$1${nbsp}(£)`],
      // `&amp;` → `&`
      [/&amp;/g, `&`],
      // `S& P 500` → `S&P 500`
      [/(\s|\u2009|\u00A0)?&(\s|\u2009|\u00A0)?/g, `&`],
      // `S&P 500` → `S&P${nbsp}500`
      [/([A-Za-z&]+?)(\s|\u2009|\u00A0)([0-9]{2,3})/g, `$1${nbsp}$3`],
      // `Terms&Conditions` → `Terms${nbsp}&${nbsp}Conditions`
      [/(\s|\u2009|\u00A0)?(\w{2,})&(\w{2,})(\s|\u2009|\u00A0)?/g, `$1$2${nbsp}&${nbsp}$3$4`],
      // `Ts&Cs`|`Ts & Cs` → `<nobr>Ts{thinsp}&{thinsp}Cs</nobr>`
      [/(T[Ss]?)(\s|\u00A0)?&(\s|\u00A0)?(C[Ss]?)/g, `<nobr>$1${hrsp}&${hrsp}$4</nobr>`],
      // `weights — buying` → `weights${nanbsp}—${thinsp}buying`
      [/([A-Za-z&]+?)[\s\u2009\u00A0]+?([\u2013\u2014])[\s\u2009\u00A0]+?(\w+?)/g, `$1${nanbsp}$2${thinsp}$3`],
      // `U.K. Government` → `U.K.${nbsp}Government`
      [/((\w\.){2,3})\s/g, `$1${nbsp}`],
      // `AB 12 34 56 C` → `AB${nanbsp}12${nanbsp}34${nanbsp}56${nanbsp}C`
      [
        /(\w{2})(\s|\u2009|\u00A0)(\d{2})(\s|\u2009|\u00A0)(\d{2})(\s|\u2009|\u00A0)(\d{2})(\s|\u2009|\u00A0)°?(\w)/g,
        `$1${nanbsp}$3${nanbsp}$5${nanbsp}$7${nanbsp}$9`,
      ],
      // `email us` → `email${nbsp}us`
      [/([eE]mail)\sus/g, `$1${nbsp}us`],
      // `  ` → ` `
      [/(\s|\u2009|\u00A0){2,}/g, '$1'],
      // remove nobr inside nobr
      [/<nobr>.*?<nobr>(.*?)<\/nobr>(.*?)<\/nobr>/g, `<nobr>$1$2</nobr>`],
      // `<nobr>` → `<span class="Nobr">`
      [/<nobr>(.*?)<\/nobr>/g, `<span class="Nobr">$1</span>`],
      // `</span>${nbsp}word.` → `${nbsp}word.</span>`
      [/<\/(.+?)>(\u00A0[\w]+)/g, `$2</$1>`],
      // `word${nbsp}<span class="Nobr">` → `<span class="Nobr">word${nbsp}`
      [/(\w*\u00A0)<(.*?)>/g, `<$2>$1`],
      // `Login settings` → `Login${nbsp}settings`
      [/(\blogin)\s(settings\b)/gi, `$1${nbsp}$2`],
    ]

    return ruleset.reduce((text, [rule, to]) => text.replace(rule, to), text)
  },
})

Typograf.addRule({
  name: 'en-GB/dash/main',
  index: '-5',
  handler(text) {
    const dashes = '--?|‒|–|—'
    const nonBreakingSpace = '\u00A0'
    const emDash = '\u2014'
    const spaceBefore = `[ ${nonBreakingSpace}]` // white space or a non-breaking space
    const spaceAfter = `[ ${nonBreakingSpace}\n]` // same as spaceBefore, but includes line break
    const re = new RegExp(`${spaceBefore}(${dashes})(${spaceAfter})`, 'g')

    return text.replace(re, `${nonBreakingSpace}${emDash}$2`)
  },
})

const typograph = new Typograf({
  locale: ['en-GB'],
})

typograph.disableRule('common/symbols/copy')

typograph.disableRule('common/space/trimLeft')
typograph.disableRule('common/space/trimRight')
typograph.disableRule('common/nbsp/beforeShortLastWord')
typograph.enableRule('common/nbsp/afterNumber')

typograph.setSetting('common/nbsp/afterShortWord', 'lengthShortWord', 2)

const cache = {}

const processText = (text: string, forInput?: boolean): string => {
  let result = typograph.execute(text)

  if (forInput) {
    result = result.trim()
    result = result.replace(/(<([^>]+)>)/gi, '')
  }

  return result
}

const typo = (text): string => {
  if (cache[text]) {
    return cache[text]
  }

  const result = processText(text)
  cache[text] = result

  return result
}

const typoForInput = (text: string): string => {
  if (cache[`input:${text}`]) {
    return cache[`input:${text}`]
  }

  const result = processText(text, true)
  cache[`input:${text}`] = result
  return result
}

export { typo, typoForInput, symbols }
