import * as React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useCallback, useMediaQueries } from 'hooks'

import { formatMinimalPercent, format as formatMoney } from 'helpers/money'
import { palette } from 'helpers/palette/'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { AccentLine } from 'components/atoms/AccentLine'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { type HoldingSecurity } from '../../SingleHolding'
import { PortfolioTitle } from '../PortfolioTitle'

import './PortfolioWithHolding.css'

type PortfolioWithHoldingsProps = {
  isLoading?: boolean
  portfolio?: Portfolio
  showTarget: boolean
  portfolioId: string
  holdingSecurities?: HoldingSecurity[]
  handleGoToSecurity: (portfolioId: string, securityId: string) => void
}

const PortfolioWithHolding = ({
  isLoading,
  portfolio,
  showTarget,
  portfolioId,
  holdingSecurities = [],
  handleGoToSecurity,
}: PortfolioWithHoldingsProps): React.ReactElement => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const itemPadding = desktop ? 12 : 8

  const emptyHoldingColor = palette['primary-surface-10']

  const handleSecurityClick = useCallback(
    (securityId) => {
      handleGoToSecurity(portfolioId, securityId)
    },
    [portfolioId, handleGoToSecurity],
  )

  const renderHoldingSecurity = useCallback(
    (holdingSecurity: HoldingSecurity) => {
      const actualWeightPercent = formatMinimalPercent(holdingSecurity.actual_weight)
      const targetWeightPercent = formatMinimalPercent(holdingSecurity.target_weight ?? 0, {
        returnDashIfNotNumberPassed: true,
      })
      const actualWeightAmount = formatMoney(holdingSecurity.actual_value, true, true, false)

      return (
        <Link
          className={classNames(
            'SecurityLink',
            { SecurityLink_loading: isLoading },
            getMediaQuieryClasses('SecurityLink', mediaQueries),
          )}
          mods={{ color: 'black' }}
          onClick={
            isLoading
              ? null
              : () => {
                  handleSecurityClick(holdingSecurity.id)
                }
          }
        >
          <Paper top={itemPadding} bottom={itemPadding}>
            <Skeleton shown={!!isLoading}>
              <ColumnarLayout
                className="SecurityLink-Content"
                mods={{ padding: 'no', 'align-columns': 'center' }}
                data-test-id="securityLink"
              >
                <Column>
                  <Paper left={desktop ? 0 : 16}>
                    <AccentLine color={holdingSecurity.actual_weight ? holdingSecurity.color : emptyHoldingColor} />
                  </Paper>
                </Column>
                <Column>
                  <Paper left={desktop ? 16 : 12} right={40}>
                    <Typography
                      size={desktop ? 16 : 18}
                      lineHeight="small"
                      weight="semibold"
                      data-test-id="securityLinkActualWeight"
                    >
                      <Typo>{actualWeightPercent}</Typo>
                      {showTarget && (
                        <Typography
                          tag="span"
                          size={12}
                          lineHeight="small"
                          color="minor"
                          data-test-id="securityLinkTargetWeight"
                        >
                          &nbsp;/&nbsp;<Typo>{targetWeightPercent}</Typo>
                        </Typography>
                      )}
                    </Typography>
                    <Paper top={1}>
                      <Typography size={12} lineHeight="small" color="minor" data-test-id="securityLinkAmount">
                        <Typo>{actualWeightAmount}</Typo>
                      </Typography>
                    </Paper>
                  </Paper>
                </Column>
                <Column mods={{ strict: true }}>
                  <Paper right={desktop ? 8 : 16}>
                    <ItemWithIcon
                      space={desktop ? 16 : 12}
                      icon={
                        <Icon
                          type="chevron_right"
                          size={16}
                          color={palette['content-on-background-minor']}
                          data-test-id="securityLinkIcon"
                        />
                      }
                      content={
                        <Typography
                          size={14}
                          lineHeight="small"
                          align="right"
                          overflow="ellipsis"
                          data-test-id="securityLinkTitle"
                        >
                          <Nobr>
                            <Typo>{holdingSecurity.title}</Typo>
                          </Nobr>
                        </Typography>
                      }
                      contentVerticalAlign="center"
                      iconPosition="right"
                    />
                  </Paper>
                </Column>
              </ColumnarLayout>
            </Skeleton>
          </Paper>
        </Link>
      )
    },
    [isLoading, desktop, emptyHoldingColor, handleSecurityClick, mediaQueries, showTarget, itemPadding],
  )

  const securitiesNode = holdingSecurities.map(renderHoldingSecurity)

  return (
    <Paper top={desktop ? 56 : 48} data-test-id="portfolioBlock">
      {portfolio ? (
        <Paper bottom={16}>
          <Skeleton shown={!!isLoading} mix inline>
            <PortfolioTitle portfolio={portfolio} />
          </Skeleton>
        </Paper>
      ) : null}
      {desktop || isLoading ? securitiesNode : <SideSpacesCompensator>{securitiesNode}</SideSpacesCompensator>}
    </Paper>
  )
}

export { PortfolioWithHolding }
