import { PortfolioPointOfInterestList } from './PortfolioPointOfInterestList'

import type { PortfolioPointOfInterestDTO } from '../../api'

class PortfolioPointOfInterestCollection {
  constructor(collection?: PortfolioPointOfInterestCollection) {
    if (collection) {
      Object.entries(collection).forEach(([key, value]) => {
        if (value instanceof PortfolioPointOfInterestList) {
          this[key] = value
        }
      })
    }
  }

  set(portfolioId: number, pointsOfInterest: PortfolioPointOfInterestDTO[]): PortfolioPointOfInterestCollection {
    return new PortfolioPointOfInterestCollection({
      ...this,
      [`${portfolioId}`]: new PortfolioPointOfInterestList(...pointsOfInterest),
    })
  }

  get(portfolioId: number): PortfolioPointOfInterestList {
    return this[`${portfolioId}`]
  }
}

export { PortfolioPointOfInterestCollection }
