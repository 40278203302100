import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'
import { useSell } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/hooks'

import { GatewayDest } from 'components/atoms/Gateway'
import { Preloader } from 'components/molecules/Preloader'

import { SellDesktop as Desktop } from './Desktop'
import { SellMobile as Mobile } from './Mobile'
import { usePortfolioBuySell } from './hooks'

const Sell = ({ params: { id } = {} }) => {
  const { desktop } = useMediaQueries()

  const portfolioId = useMemo(() => parseInt(id, 10), [id])

  const {
    isLoading: isPortfolioLoading,
    securities,
    noTradingSecurities,
    isTradingSuspended,
    portfolio,
    handleBuy,
    handleBack,
    handleReview,
  } = usePortfolioBuySell({ portfolioId })

  const {
    isLoading: isSellLoading,
    isValid,
    validation,
    control,
    errors,
    sell: handleSubmit,
    handleSetAllValue,
  } = useSell({
    portfolio,
    onAfterSell: (orderId) => handleReview(orderId, 'sell'),
  })

  const props = {
    portfolio,
    securities,
    noTradingSecurities,
    isTradingSuspended,

    isValid,
    validation,
    errors,
    control,

    handleBuy,
    handleBack,
    handleSetAllValue,
  }

  const isLoading = isSellLoading | isPortfolioLoading

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>{desktop ? <Desktop {...props} /> : <Mobile {...props} />}</form>
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
      <Preloader loading={isLoading} size="big" zIndex />
    </Fragment>
  )
}

Sell.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export { Sell }
