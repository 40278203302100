import React from 'react'

import GlobalPreloader from 'components/_old/GlobalPreloader/GlobalPreloader.jsx'

import { StepProvider } from 'app/containers/PortfolioTunnel'

import { SetupAccountDataStepAdapter } from './Adapter'
import { useSetupAccount, type UseSetupAccountRoute } from './hooks'

import { GP_DEFAULT } from 'constants/globalPreloaderStatuses.js'

type SetupAccountProps = {
  children: React.ReactElement
  routes: UseSetupAccountRoute[]
  route: UseSetupAccountRoute
  location: {
    query: object
  }
}

const SetupAccount = ({ children, routes, route, location }: SetupAccountProps): React.ReactElement => {
  const { isLoading, steps } = useSetupAccount({ routes, route, location })

  if (isLoading) {
    return <GlobalPreloader status={GP_DEFAULT} loading />
  }

  return (
    <StepProvider steps={steps} adapter={SetupAccountDataStepAdapter({ routes, location })}>
      {children}
    </StepProvider>
  )
}

export { SetupAccount, type SetupAccountProps }
