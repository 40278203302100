import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { Typography } from 'components/atoms/Typography'

import './ChartHorizontalTick.css'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

const ChartHorizontalTick = ({ className, textNodeClassName, text }) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  return (
    <div
      className={classNames(
        className,
        'ChartHorizontalTick',
        getMediaQuieryClasses('ChartHorizontalTick', mediaQueries),
      )}
    >
      <Typography
        className={classNames(textNodeClassName, 'ChartHorizontalTick-TextNode')}
        size={desktop ? 12 : 10}
        lineHeight="small"
        color="minor"
        align="center"
      >
        {text}
      </Typography>
    </div>
  )
}

ChartHorizontalTick.propTypes = {
  className: PropTypes.string,
  textNodeClassName: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export { ChartHorizontalTick }
