import React from 'react'

import type { Preset } from 'app/redux/models/portfolio/types'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { SimpleTextModal } from 'components/atoms/SimpleTextModal'

type FeeDescriptionModalProps = {
  isOpen: boolean
  preset: Preset
  onClose: () => void
}

const FeeDescriptionModal = ({ isOpen, preset, onClose }: FeeDescriptionModalProps): React.ReactElement => {
  const textNode = (
    <Typo>
      We charge {preset?.fee}% a year for managing your investments — that’s just 21p a month per £1,000 of investments.
      The ETFs we invest in will also have a cost, which for the {preset?.title} is {preset?.ter}%. For more information
      on our fees, please refer to our{' '}
      <Link to="https://investengine.com/costs/" hard blank>
        fees page
      </Link>
      , and the {preset?.title}{' '}
      <Link to={preset?.kiid} hard blank>
        Key Features Document
      </Link>
      .
    </Typo>
  )

  return <SimpleTextModal title="Fee" text={textNode} isOpen={isOpen} onClose={onClose} />
}

export { FeeDescriptionModal }
