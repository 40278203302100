import { useState, useEffect, useRef, useContext } from 'react'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

const useScrollPosition = (delta = 0, deltaThreshold): number => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const lastScrollPosition = useRef(0)
  const context = useContext(ScrollSaverContext)
  const scrollableElement = context.getScrollableElement()

  useEffect(() => {
    const updatePosition = (): void => {
      if (!scrollableElement) return

      const currentScrollPosition = scrollableElement.scrollTop

      if (deltaThreshold && currentScrollPosition <= deltaThreshold) {
        setScrollPosition(currentScrollPosition)
        lastScrollPosition.current = currentScrollPosition
        return
      }

      if (Math.abs(currentScrollPosition - lastScrollPosition.current) < delta) return

      setScrollPosition(currentScrollPosition)
      lastScrollPosition.current = currentScrollPosition
    }

    scrollableElement?.addEventListener('scroll', updatePosition)
    updatePosition()

    return () => {
      scrollableElement?.removeEventListener('scroll', updatePosition)
    }
  }, [delta, deltaThreshold, scrollableElement])

  return scrollPosition
}

export default useScrollPosition
