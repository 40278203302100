import React from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import moment from 'helpers/date.js'

import { Typo } from 'components/_old/Typo/Typo'

import { Typography } from 'components/atoms/Typography'

type LatestMarketPriceNoteProps = {
  className?: string
  date?: string
}

const LatestMarketPriceNote = ({ className, date }: LatestMarketPriceNoteProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const formattedDate = useMemo(() => {
    if (!date) return 'Previous business day’s close price'

    const startOfDayTimestamp = moment.utc().startOf('day').unix()
    const priceDateTimestamp = moment.utc(date).unix()

    return priceDateTimestamp < startOfDayTimestamp
      ? 'Previous business day’s close price'
      : `Price as at ${moment(date).format('HH:mm, Do MMMM')}`
  }, [date])

  return (
    <Typography className={className}>
      <Typography tag="span" color="minor" lineHeight="small" size={desktop ? 16 : 14}>
        <Typo>{formattedDate}</Typo>
      </Typography>
    </Typography>
  )
}

export { LatestMarketPriceNote }
