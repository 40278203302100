import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { format } from 'helpers/money'
import localstore from 'helpers/localstore.js'

import { useMemo, useSelector, useState, useEffect, useCallback } from 'hooks'

import { palette } from 'helpers/palette'
import { urlTo } from 'helpers/router.js'
import { propTypes } from 'helpers/propTypes'
import { features } from 'helpers/features'

import { DesktopHeader } from 'app/containers/Header'
import { DesktopFooter } from 'app/containers/Footer'

import { AddSecurities } from 'components/molecules/AddSecurities'
import { Card } from 'components/atoms/Card'
import { DesktopLayout } from 'components/atoms/Layouts'
import { DynamicFontSize } from 'components/atoms/DynamicFontSize'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { NewPeriodSelect } from 'components/molecules/NewPeriodSelect'
import { Paper } from 'components/atoms/Paper'
import { PortfolioPointOfInterestCard } from 'components/organisms/charts/parts'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'
import { PriceChart } from 'components/organisms/PriceChart'
import { ReturnInfo } from 'components/organisms/ReturnInfo'
import { SecurityInfo } from 'components/organisms/SecurityInfo'
import { SharePortfolioBanner } from 'components/molecules/SharePortfolioBanner'
import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import { Typography } from 'components/atoms/Typography'
import Button from 'components/_old/Button/Button.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import NotificationGroup from 'components/organisms/Notification/NotificationGroup.tsx'
import Width from 'components/_old/Width/Width'
import { Typo } from 'components/_old/Typo/Typo'
import Toggle from 'components/atoms/Toggle/Toggle.jsx'
import { AltusProgressBar } from 'components/molecules/AltusProgressBar'
import { Skeleton } from 'components/atoms/Skeleton'
import { Document } from 'components/molecules/Document'

import { PortfolioLine } from 'app/pages/Dashboard/Goals/components/PortfolioLine'
import { TransferIsaBlock } from 'app/pages/Dashboard/Goals/components/TransferIsaBlock'
import { OrdersPlacedButton } from 'app/pages/Dashboard/Goals/DIY/Portfolio/components/OrdersPlacedButton'
import { PortfolioLabels } from '../components/PortfolioLabels'
import { ChartSelectTabs } from '../components/ChartSelectTabs'
import { DividendsChart } from '../components/DividendsChart'
import { SavingsPlanLabel } from 'app/pages/Dashboard/Goals/components/SavingsPlanLabel'
import { SavingsPlanSetupBanner } from 'app/pages/Dashboard/Goals/components/SavingsPlanSetupBanner'
import { LumpSumBanner } from 'app/pages/Dashboard/Goals/components/LumpSumBanner'
import { FirstTopupBanner } from 'app/pages/Dashboard/Goals/components/FirstTopupBanner'
import { OnboardingProgressBar } from '../components/OnboardingProgressBar/OnboardingProgressBar'

import { PendingOrderList } from 'app/effector/pending-orders/models'
import { NotificationScopeType } from 'app/effector/notifications/models'
import { selectGoalTitle } from 'app/redux/selectors'

import { manageTypes, states as portfolioStates } from 'constants/portfolio'

const Desktop = ({
  isHistoryLoading,
  portfolio,
  portfolioActions,
  portfolioOrders,
  securities,
  chartData,
  pointsOfInterest,
  periodPriceData,
  contributions,
  activeChart,
  selectedPeriod,
  handlePeriodSelect,
  handleBack,
  handleAddSecurities,
  handleSelectSecurity,
  handleBuySell,
  setChartTab,
  showFinishSavingsPlanBanner,
  quickStart,
  showFirstTopupBanner,
  showLumpSumBanner,
  daysBeforePayment,
  altusProgressData,
  regulatoryTypeText,
  handleDismissAltusPanel,
}) => {
  const [contributionsVisible, setContributionsVisible] = useState(
    ((localstore.get('goals') || {})[portfolio.id] || {}).contributionsVisible,
  )
  const handleChangeContributionsVisible = useCallback(
    (event) => {
      const visible = event.target.checked
      setContributionsVisible(visible)
      const storedGoals = localstore.get('goals') || {}

      if (!storedGoals[portfolio.id]) {
        storedGoals[portfolio.id] = {}
      }
      storedGoals[portfolio.id].contributionsVisible = visible

      localstore.set('goals', storedGoals)
    },
    [portfolio.id],
  )

  useEffect(() => {
    const storedGoals = localstore.get('goals') || {}
    const storeContributionsVisibelValue = Boolean((storedGoals[portfolio.id] || {}).contributionsVisible)
    setContributionsVisible(storeContributionsVisibelValue)
  }, [portfolio.id])

  const goalTitle = useSelector((state) => selectGoalTitle(state, portfolio?.id))

  const backButton = useMemo(
    () => (
      <Paper>
        <Link onClick={handleBack} mods={{ color: 'content-on-background-default' }}>
          <ItemWithIcon
            space={16}
            icon={<Icon type="back-new" size={24} color="inherit" />}
            content={<Typography color="inherit">Back</Typography>}
            iconVerticalAlign="center"
            data-test-id="navigationLeftButton"
          />
        </Link>
      </Paper>
    ),
    [handleBack],
  )

  const notifications = useMemo(
    () => (
      <NotificationGroup scope={NotificationScopeType.PORTFOLIO} portfolioId={portfolio.id}>
        {(nodes) => <Paper top={56}>{nodes}</Paper>}
      </NotificationGroup>
    ),
    [portfolio.id],
  )

  const title = useMemo(
    () => (
      <Paper>
        <Typography size={24} weight="semibold" align="center" lineHeight="small" data-test-id="diyPortfolioTitle">
          {goalTitle}
        </Typography>
        <Paper top={8}>
          <ItemWithIcon
            space={8}
            icon={<Icon type="person2" size={24} color={palette['secondary-default']} />}
            iconVerticalAlign="center"
            contentVerticalAlign="center"
            selfHorizontalAlign="center"
            content={
              <Typography size={16} lineHeight="small" color="minor" data-test-id="portfolioManagedType">
                <span>Self-managed</span>, {regulatoryTypeText}
              </Typography>
            }
          />
        </Paper>
      </Paper>
    ),
    [goalTitle, regulatoryTypeText],
  )

  const balance = useMemo(
    () => (
      <Fragment>
        <Typography weight="semibold" lineHeight="small" data-test-id="portfolioBalance">
          <DynamicFontSize
            min={12}
            max={54}
            lineHeight={1.175}
            length={format(portfolio.capital_balance, true, true).length}
            lengthLimit={9}
          >
            <PoundsWithPence amount={portfolio.capital_balance} showZeroPence />
          </DynamicFontSize>
        </Typography>
        <Paper top={4}>
          <Typography size={16} color="minor" lineHeight="small">
            Portfolio balance{' '}
            <TooltipToModal
              description="The current balance of your portfolio including any pending transfers still being processed"
              customIcon
              offset={-350}
              data-test-id="portfolioBalanceTooltip"
            >
              <Link mods={{ color: 'minorToRed' }} style={{ display: 'inline-flex' }}>
                <Icon size={24} type="information-24" color="inherit" style={{ position: 'relative', top: '6px' }} />
              </Link>
            </TooltipToModal>
          </Typography>
        </Paper>
      </Fragment>
    ),
    [portfolio.capital_balance],
  )

  const buttons = useMemo(
    () => (
      <Paper top={32}>
        <ColumnarLayout mods={{ padding: 'no' }}>
          <Column size={1}>
            <Paper right={12}>
              <Button mods={{ size: 'new-small block' }} onClick={handleBuySell} data-test-id="fundPortfolioButton">
                Invest
              </Button>
            </Paper>
          </Column>
          <Column size={0}>
            <Width size={6.875}>
              <Button
                mods={{ theme: 'simple-reverse-blue', size: 'new-small block' }}
                data-test-id="portfolioOptionsButton"
              >
                <Link to={urlTo('portfolio.options', { id: portfolio.id })}>Options</Link>
              </Button>
            </Width>
          </Column>
        </ColumnarLayout>
      </Paper>
    ),
    [portfolio, handleBuySell],
  )

  const altusProgress = useMemo(
    () =>
      altusProgressData &&
      altusProgressData.length > 0 && (
        <Paper bottom={40}>
          {altusProgressData.map((progressBar, index) => (
            <Paper key={progressBar.id} top={index !== 0 && 16}>
              <AltusProgressBar
                altusProgressBarData={progressBar}
                handleDismissPanel={handleDismissAltusPanel}
                data-test-id="transferProgressBar"
              />
            </Paper>
          ))}
        </Paper>
      ),
    [altusProgressData, handleDismissAltusPanel],
  )

  const ordersPlaced = useMemo(
    () =>
      portfolioOrders.length > 0 && (
        <Paper bottom={40}>
          <OrdersPlacedButton portfolioId={portfolio?.id} />
        </Paper>
      ),
    [portfolio?.id, portfolioOrders],
  )

  const savingsPlanSetupBannerNode = useMemo(
    () =>
      showFinishSavingsPlanBanner && (
        <Paper bottom={56}>
          <SavingsPlanSetupBanner portfolio={portfolio} quickStart={quickStart} />
        </Paper>
      ),
    [showFinishSavingsPlanBanner, quickStart, portfolio],
  )

  const lumpSumBannerNode = useMemo(
    () =>
      showLumpSumBanner && (
        <Paper bottom={56}>
          <LumpSumBanner portfolio={portfolio} quickStart={quickStart} />
        </Paper>
      ),
    [showLumpSumBanner, quickStart, portfolio],
  )

  const firstTopupBannerNode = useMemo(
    () =>
      showFirstTopupBanner && (
        <Paper bottom={56}>
          <FirstTopupBanner portfolio={portfolio} daysBeforePayment={daysBeforePayment} />
        </Paper>
      ),
    [portfolio, showFirstTopupBanner, daysBeforePayment],
  )

  const newPeriodSelect = useMemo(
    () => (
      <Paper bottom={32}>
        <NewPeriodSelect selectedPeriod={selectedPeriod} handlePeriodSelect={handlePeriodSelect} />
      </Paper>
    ),
    [selectedPeriod, handlePeriodSelect],
  )

  const investmentReturnInfo = useMemo(
    () => (
      <ReturnInfo price={periodPriceData?.price ?? 0} percent={periodPriceData?.percent ?? 0} tooltipOffset={-144} />
    ),
    [periodPriceData],
  )

  const returnChart = useMemo(
    () => (
      <Skeleton shown={isHistoryLoading && chartData.performance.length < 1} mix>
        <div>
          <PriceChart
            period={selectedPeriod}
            data={chartData.performance}
            contributionsData={contributionsVisible ? chartData.contributions : null}
            negative={(periodPriceData?.price ?? 0) < 0}
            topContent={investmentReturnInfo}
            portfolioId={portfolio.id}
            topContentOffset={40}
            pointsOfInterest={pointsOfInterest}
            PointOfInterestCard={PortfolioPointOfInterestCard}
          />
          {(isHistoryLoading || chartData.performance.length > 0) && (
            <Paper top={32}>
              <Typography align="left" size={16}>
                <label style={{ display: 'inline-flex', 'align-items': 'center' }}>
                  <Paper right={8} inline style={{ display: 'flex' }}>
                    <Toggle checked={contributionsVisible} onChange={handleChangeContributionsVisible} />
                  </Paper>
                  <Typo>Net contributions</Typo>
                  <Paper left={8} inline data-test-id="goalChartContribution">
                    <PoundsWithPence amount={contributions} />
                  </Paper>
                </label>
              </Typography>
            </Paper>
          )}
        </div>
      </Skeleton>
    ),
    [
      isHistoryLoading,
      chartData,
      selectedPeriod,
      periodPriceData?.price,
      portfolio.id,
      pointsOfInterest,
      contributions,
      contributionsVisible,
      investmentReturnInfo,
      handleChangeContributionsVisible,
    ],
  )

  const dividendsChart = useMemo(
    () => (
      <Skeleton shown={isHistoryLoading && (!chartData.dividends || (chartData.dividends?.length ?? 0) < 0)}>
        <DividendsChart
          data={chartData.dividends}
          period={selectedPeriod}
          securities={securities}
          portfolioId={portfolio.id}
        />
      </Skeleton>
    ),
    [isHistoryLoading, chartData.dividends, selectedPeriod, securities, portfolio.id],
  )

  const chartSelectNode = (
    <Paper bottom={32}>
      <ChartSelectTabs activeChart={activeChart} setActiveChart={setChartTab} />
    </Paper>
  )

  const chartNode = useMemo(
    () => (activeChart === 'return' ? returnChart : dividendsChart),
    [activeChart, returnChart, dividendsChart],
  )

  const securityComponents = useMemo(
    () => (
      <Fragment>
        <AddSecurities onClick={handleAddSecurities} />
        {securities.map((security, index) => (
          <Paper key={security.skeleton ? index : security.id} top={24} data-test-id="portfolioSecurity">
            <Skeleton shown={security.skeleton} mix>
              <SecurityInfo
                {...security}
                stats={security?.stats?.[selectedPeriod]}
                onClick={security?.id ? () => handleSelectSecurity(security.id) : null}
                data-test-id={`portfolioSecurity${index}`}
              />
            </Skeleton>
          </Paper>
        ))}
      </Fragment>
    ),
    [securities, handleAddSecurities, handleSelectSecurity, selectedPeriod],
  )

  const transferIsaBlock = useMemo(() => {
    if ([manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(portfolio?.manage_type)) {
      return false
    }

    if (portfolio?.state === portfolioStates.CLOSING) {
      return false
    }

    if (portfolio?.isa?.transferring_in_process) {
      return (
        <Paper top={40}>
          <TransferIsaBlock mode="sidebar" portfolioId={portfolio.id} reference={portfolio.reference} />
        </Paper>
      )
    }
  }, [
    portfolio?.manage_type,
    portfolio?.state,
    portfolio?.isa?.transferring_in_process,
    portfolio.id,
    portfolio.reference,
  ])

  const onboardingProgressBar = !altusProgress && <OnboardingProgressBar portfolio={portfolio} />

  const shareButton = useMemo(
    () => (
      <Paper top={40}>
        <SharePortfolioBanner portfolioId={portfolio?.id} isSharingEnabled={!!portfolio?.share_url} />
      </Paper>
    ),
    [portfolio?.id, portfolio?.share_url],
  )

  const kiidNode = (
    <Paper bottom={48}>
      <Typography size={24} lineHeight="small" weight="semibold">
        <Typo>Key information</Typo>
      </Typography>
      <Paper top={24}>
        <Document name="Key Features document" src="https://public.investengine.com/pdf/DIY%20Portfolio%20KFD.pdf" />
      </Paper>
    </Paper>
  )

  const content = (
    <Fragment>
      <PortfolioLine type="diy" />
      <Inner twoColumns>
        <Paper top={8}>
          <ColumnarLayout mods={{ padding: 'no', 'column-content': 'center' }}>
            <Column size={0}>{backButton}</Column>
            <Column size={1}>{title}</Column>
          </ColumnarLayout>
        </Paper>
        {notifications}
        <Paper top={56}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1}>
              {firstTopupBannerNode}
              {savingsPlanSetupBannerNode}
              {lumpSumBannerNode}
              {portfolio?.first_topup && (
                <Paper bottom={56}>
                  {newPeriodSelect}
                  {chartSelectNode}
                  {chartNode}
                </Paper>
              )}
              {kiidNode}
              {securityComponents}
            </Column>
            <Column size={0} sticky style={{ zIndex: 1 }}>
              <Paper left={80}>
                <Width size={19}>
                  {features.get('altus-progress-bar') && altusProgress}
                  {onboardingProgressBar}
                  {ordersPlaced}
                  <Card>
                    <Paper top={24} bottom={40} left={24} right={24}>
                      {balance}
                      <PortfolioLabels portfolio={portfolio} />
                      <SavingsPlanLabel portfolio={portfolio} />
                      {buttons}
                    </Paper>
                  </Card>
                  {shareButton}
                  {transferIsaBlock}
                </Width>
              </Paper>
            </Column>
          </ColumnarLayout>
        </Paper>
      </Inner>
    </Fragment>
  )

  return <DesktopLayout header={<DesktopHeader />} content={content} footer={<DesktopFooter />} />
}

Desktop.propTypes = {
  isSecuritiesLoading: PropTypes.bool,
  isHistoryLoading: PropTypes.bool,
  isRebalanceLoading: PropTypes.bool,
  portfolio: PropTypes.object.isRequired,
  portfolioActions: PropTypes.array.isRequired,
  portfolioOrders: propTypes.instanceOf(PendingOrderList),
  securities: PropTypes.array,
  chartData: PropTypes.shape({
    performance: PropTypes.array,
    contributions: PropTypes.array,
    dividends: PropTypes.array,
  }),
  pointsOfInterest: PropTypes.array,
  periodPriceData: PropTypes.shape({
    price: PropTypes.number,
    percent: PropTypes.number,
    name: PropTypes.string,
  }),
  activeChart: PropTypes.string,
  contributions: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  handlePeriodSelect: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleAddSecurities: PropTypes.func.isRequired,
  handleSelectSecurity: PropTypes.func.isRequired,
  handleBuySell: PropTypes.func.isRequired,
  setChartTab: PropTypes.func.isRequired,
  showFinishSavingsPlanBanner: PropTypes.bool,
  showLumpSumBanner: PropTypes.bool,
  quickStart: PropTypes.object,
  showFirstTopupBanner: PropTypes.bool,
  daysBeforePayment: PropTypes.number,
  regulatoryTypeText: PropTypes.string,
  handleDismissAltusPanel: PropTypes.func,
}

export { Desktop }
