import React from 'react'

import { NotApproved } from 'app/pages/Dashboard/Goals/AddFunds/NotApproved/NotApproved.jsx'

export const routes = {
  module: 'not-approved',
  path: 'not-approved/',
  component: function Component(props) {
    return <NotApproved {...props} />
  },
}
