import React from 'react'

import { useMediaQueries, useMemo, useSelector } from 'hooks'
import { usePaymentDetails } from './hooks'

import { urlTo } from 'helpers/router.js'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { BankAccountCard } from 'components/molecules/BankAccountCard'
import { InstantBankTransfer } from 'app/pages/Dashboard/Goals/AddFunds/SelectPaymentMethod/components/InstantBankTransfer'
import { UploadBankStatementModal } from './components'
import { OpenBankingModal } from './components/OpenBankingModal'
import { NewBankModal } from './components/NewBankModal'
import { Preloader } from 'components/molecules/Preloader'
import { BankAccountContextMenu } from './components/BankAccountContextMenu'

import { SubscriptionsGroup } from './components/SubscriptionsGroup'

import { isClientFilledPersonalData, isClientRegistered } from 'app/redux/selectors'

import { regulatoryTypes } from 'constants/portfolio'

const PaymentDetailsContent = () => {
  const { desktop } = useMediaQueries()

  const {
    isLoading,
    bankAccounts,
    bankAccountId,
    groupedSubscriptions,
    regulatotyTypesOrder,
    isNominationAvailable,
    handleSelectBankAccount,
    handleSubscriptionClick,
    handleCloseBankStatementModal,
    handleAddNewBank,
  } = usePaymentDetails()

  const clientFilledPersonalData = useSelector(isClientFilledPersonalData)
  const clientRegistered = useSelector(isClientRegistered)

  const hasApprovedBankAccount = bankAccounts.filter((account) => account.state === 'APPROVED').length > 0
  const hasSubscriptions =
    groupedSubscriptions[regulatoryTypes.GIA]?.length > 0 || groupedSubscriptions[regulatoryTypes.ISA]?.length > 0

  const canLinkBankAccount = useMemo(() => {
    if (!clientFilledPersonalData) {
      return false
    }

    const lessThanALimit = bankAccounts.length < 5

    if (clientRegistered) {
      return lessThanALimit
    }

    if (hasApprovedBankAccount) {
      return false
    }

    return lessThanALimit
  }, [bankAccounts.length, clientFilledPersonalData, clientRegistered, hasApprovedBankAccount])

  const isaNode = useMemo(
    () =>
      groupedSubscriptions[regulatoryTypes.ISA]?.length > 0 ? (
        <SubscriptionsGroup
          title="ISA"
          subscriptions={groupedSubscriptions[regulatoryTypes.ISA]}
          onCardClick={handleSubscriptionClick}
          data-test-id="isaSection"
        />
      ) : null,
    [groupedSubscriptions, handleSubscriptionClick],
  )

  const giaNode = useMemo(
    () =>
      groupedSubscriptions[regulatoryTypes.GIA]?.length > 0 ? (
        <SubscriptionsGroup
          title="General"
          subscriptions={groupedSubscriptions[regulatoryTypes.GIA]}
          onCardClick={handleSubscriptionClick}
          data-test-id="giaSection"
        />
      ) : null,
    [groupedSubscriptions, handleSubscriptionClick],
  )

  const sippNode = useMemo(
    () =>
      groupedSubscriptions[regulatoryTypes.SIPP]?.length > 0 ? (
        <SubscriptionsGroup
          title="Personal Pension"
          subscriptions={groupedSubscriptions[regulatoryTypes.SIPP]}
          onCardClick={handleSubscriptionClick}
          data-test-id="sippSection"
        />
      ) : null,
    [groupedSubscriptions, handleSubscriptionClick],
  )

  const regulatoryTypeNodes = {
    [regulatoryTypes.ISA]: isaNode,
    [regulatoryTypes.GIA]: giaNode,
    [regulatoryTypes.SIPP]: sippNode,
  }

  const subscriptionsTitle = useMemo(
    () =>
      hasSubscriptions ? (
        <Paper top={desktop ? 40 : 56}>
          <Typography size={desktop ? 18 : 24} weight={desktop ? 'regular' : 'semibold'} lineHeight="small">
            <Typo>Savings Plans</Typo>
          </Typography>
        </Paper>
      ) : null,
    [desktop, hasSubscriptions],
  )

  const instantTransferBackUrl = urlTo(
    'dashboard.user-profile.savings-plans.set-up-instant-bank',
    {},
    { back: urlTo('dashboard.user-profile.savings-plans') },
  )

  const instantBankTransfer = canLinkBankAccount ? (
    <Paper top={desktop ? 24 : 16}>
      <InstantBankTransfer
        howItWorksUrl={instantTransferBackUrl}
        hasBankAccounts={!!bankAccounts.length}
        onClick={handleAddNewBank}
      />
    </Paper>
  ) : null

  return (
    <Paper top={desktop ? 0 : 8}>
      <Paper bottom={desktop ? 0 : 24}>
        <Typography size={desktop ? 18 : 24} weight={desktop ? 'regular' : 'semibold'} lineHeight="small">
          Your bank accounts
        </Typography>
      </Paper>
      {bankAccounts.map((account) => (
        <Paper key={account.id} top={desktop ? 24 : 16}>
          <BankAccountContextMenu bankAccount={account}>
            <BankAccountCard
              bankAccount={account}
              selectable={isNominationAvailable && !account.is_nominated}
              onUploadLinkClick={handleSelectBankAccount}
              description={account.is_nominated ? 'Nominated account for withdrawals from InvestEngine' : null}
            />
          </BankAccountContextMenu>
        </Paper>
      ))}
      {instantBankTransfer}
      {subscriptionsTitle}
      {regulatotyTypesOrder.map((regulatoryType) => regulatoryTypeNodes[regulatoryType])}
      <UploadBankStatementModal bankAccountId={bankAccountId} onClose={handleCloseBankStatementModal} />
      <OpenBankingModal />
      <NewBankModal />
      <Preloader loading={isLoading} size="big" zIndex />
    </Paper>
  )
}

export { PaymentDetailsContent }
