import { createEffect, createStore, combine } from 'effector'

import { makeApiCall } from 'app/effector/api'

import type { ApiError } from 'app/redux/models/errors'

import * as api from './api'
import { BonusList, type BonusStates } from './models'

// Effects
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
const fetchBonusesFx = createEffect<BonusStates | undefined | void, api.BonusDTO[], ApiError>(
  async (state?: BonusStates) => await makeApiCall(api.fetchBonuses.bind(this, state)),
)
const claimBonusFx = createEffect(async () => await makeApiCall(api.claimBonus))

// Stores
const $bonuses = createStore<BonusList>(new BonusList())
$bonuses.on(fetchBonusesFx.doneData, (state, items) => new BonusList(...items))
$bonuses.on(claimBonusFx.doneData, (state, item) => state.set(item))

const $isLoading = combine(fetchBonusesFx.pending, claimBonusFx.pending, (...pendingEffects) =>
  pendingEffects.some((pending) => pending),
)

const $bonusesStore = combine({
  bonuses: $bonuses,
  isLoading: $isLoading,
})

export {
  // Effects
  fetchBonusesFx,
  claimBonusFx,
  // Store
  $bonusesStore,
}
