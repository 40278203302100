import { useCallback, useState, useMemo, useLoading } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { isAuthorizationError } from 'helpers/errors'
import { goTo, urlTo } from 'helpers/router.js'

import { showFailToast } from 'app/redux/actions/ui'
import { getAnalyticsByWeights } from 'app/redux/api/analytics.js'

import { type Analytics, type AnalyticsPayloadFromServer } from '../types'

type UseWeightsAnalyticsProps = {
  portfolioId: string
  isPortfolioNew: boolean
  currentValues: Record<string, number>
  mode: string
  searchQuery: Record<string, string | number | boolean>
}

type UseWeightsAnalyticsInterface = {
  analytics: Analytics
  isLoading: boolean
  openModal: () => void
  closeModal: () => void
  fetchAnalyticsByWeights: () => Promise<void>
}

const useWeightsAnalytics = ({
  portfolioId,
  isPortfolioNew,
  currentValues,
  mode,
  searchQuery,
}: UseWeightsAnalyticsProps): UseWeightsAnalyticsInterface => {
  const [data, setData] = useState<AnalyticsPayloadFromServer>({ equities: [], sectors: [], regions: [] })
  const { isLoading, wait } = useLoading(false)

  const analytics = useMemo(() => {
    return {
      sectors: data.sectors.map((item) => ({
        ...item,
        actual: parseFloat(`${item.target_weight}`),
        target: parseFloat(`${item.target_weight}`),
        currentTarget: parseFloat(`${item.current_target_weight}`),
      })),
      regions: data.regions.map((item) => ({
        ...item,
        actual: parseFloat(`${item.target_weight}`),
        target: parseFloat(`${item.target_weight}`),
        currentTarget: parseFloat(`${item.current_target_weight}`),
      })),
      holdings: data.equities.map((item) => ({
        ...item,
        actual: parseFloat(`${item.target_weight}`),
        target: parseFloat(`${item.target_weight}`),
        currentTarget: parseFloat(`${item.current_target_weight}`),
      })),
    }
  }, [data])

  const fetchAnalyticsByWeights = useCallback(async () => {
    const portfolioIdOrNull = isPortfolioNew ? null : portfolioId

    const payload = Object.keys(currentValues).map((securityId) => ({
      security_id: parseInt(securityId, 10),
      weight: currentValues[securityId],
    }))

    try {
      const data: AnalyticsPayloadFromServer = await wait(
        getAnalyticsByWeights({ portfolioId: portfolioIdOrNull, weights: payload }),
      )
      setData(data)
    } catch (error) {
      if (!isAuthorizationError(error)) {
        showFailToast()
      }
    }
  }, [currentValues, portfolioId, isPortfolioNew, wait])

  const openModal = useCallback(() => {
    goTo(
      urlTo(
        'dashboard.portfolio.edit-weights',
        { id: portfolioId },
        querystringFromObject({ ...searchQuery, mode, reviewModalOpened: 'true' }),
      ),
    )
  }, [portfolioId, searchQuery, mode])

  const closeModal = useCallback(() => {
    goTo(
      urlTo('dashboard.portfolio.edit-weights', { id: portfolioId }, querystringFromObject({ ...searchQuery, mode })),
    )
  }, [portfolioId, searchQuery, mode])

  return {
    analytics,
    isLoading,
    openModal,
    closeModal,
    fetchAnalyticsByWeights,
  }
}

export { useWeightsAnalytics }
