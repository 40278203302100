import React from 'react'

export default function AllCenter(props) {
  const { className, style, relative, children, limit, 'data-test-id': dataTestId } = props

  let allStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
  }

  if (relative) {
    allStyles = {
      ...allStyles,
      position: 'relative',
    }
  }

  if (limit) {
    allStyles = {
      ...allStyles,
      maxHeight: `${limit}rem`,
    }
  }

  allStyles = {
    ...allStyles,
    ...style,
  }

  return (
    <div className={className} style={allStyles} data-test-id={dataTestId}>
      {children}
    </div>
  )
}
