const applyMiddlewares = ({ request, response, error }, middlewares = []) => {
  const ctx = {
    request,
    response,
    error,
    errorHandlers: [],
  }
  try {
    const chain = middlewares.reduce((ctx, middleware, index) => {
      middleware(ctx)
      return ctx
    }, ctx)

    if (chain.errorHandlers.length) {
      return chain.errorHandlers.reduce((localCtx, handler) => handler(localCtx) || localCtx, ctx)
    }

    return ctx
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * @param {AxiosInstance} instance
 * @param {Array} middlewares
 */
const axiosWrap = (instance, middlewares = []) => {
  instance.interceptors.request.use(
    async (request) => {
      const ctx = await applyMiddlewares({ request }, middlewares)

      return ctx.request
    },
    async (error) => {
      return await Promise.reject(error)
    },
  )

  instance.interceptors.response.use(
    async (response) => {
      const ctx = await applyMiddlewares({ response }, middlewares)

      return ctx.response
    },
    async (error) => {
      const ctx = await applyMiddlewares({ error }, middlewares)

      return await Promise.reject(ctx.error)
    },
  )

  return instance
}

export { axiosWrap }
