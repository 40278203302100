import React, { forwardRef, Fragment, useMemo } from 'react'

import { format as formatDate } from 'helpers/date.js'
import { palette } from 'helpers/palette/'

import { ChartHorizontalTick, type AbstractPointOfInterest } from 'components/organisms/charts/parts'
import type { SelectPointOfInterestInterface } from 'components/organisms/PriceChart/hooks/usePointsOfInterest'

import { STROKE_WIDTH } from './constants'
import { useLayout, useValues, usePoints } from './hooks'
import { Line, Gradient } from './parts'
import { monotoneCubic, getPath } from './utils'

import './IncomeLine.css'

type IncomeLineDataItem = {
  value: number
  date: Date
}

type IncomeLimeTickDataItem = {
  formattedDate: string
  begin: number
  end: number
}

type IncomeLineProps = {
  className?: string
  data: IncomeLineDataItem[]
  min: number
  max: number
  color?: string
  gradient?: [string, string]
  strokeWidth?: number
  hoverable?: boolean
  hoveredIndex?: number
  onTickEnter?: (event: React.MouseEvent<SVGGElement, MouseEvent>, index: number) => void
  onTickLeave?: (event: React.MouseEvent<SVGGElement, MouseEvent>) => void
  pointsOfInterest?: AbstractPointOfInterest[]
  activePointOfInterest?: AbstractPointOfInterest | null
  selectPointOfInterest?: SelectPointOfInterestInterface
  smoothness?: boolean
  fretless?: boolean
  showLessTicks?: boolean
  setPickCoordinates?: boolean
  'data-test-id'?: string
}

const IncomeLine = forwardRef<SVGGElement, IncomeLineProps>(function IncomeLine(
  {
    className,
    data,
    max,
    min = 0,
    color = palette['secondary-default'],
    gradient,
    strokeWidth = STROKE_WIDTH,
    hoverable = false,
    hoveredIndex,
    onTickEnter: handleTickEnter,
    onTickLeave: handleTickLeave,
    pointsOfInterest = [],
    activePointOfInterest,
    selectPointOfInterest,
    smoothness = false,
    fretless = false,
    showLessTicks = false,
    setPickCoordinates = false,
    'data-test-id': dataTestId,
  }: IncomeLineProps,
  ref,
) {
  const { classes, svgRef, width, height, chartPadding, showTicks } = useLayout({
    data,
    className,
    hoverable,
    showLessTicks,
  })
  const { chartValues, positions } = useValues({ data, min, max, pointsOfInterest, width, chartPadding, height })
  const { hoverablePoints, clickablePoints } = usePoints({
    chartValues,
    color,
    height,
    setPickCoordinates,
    hoverable,
    hoveredIndex,
    handleTickEnter,
    handleTickLeave,
    activePointOfInterest,
    selectPointOfInterest,
  })

  const path = useMemo(() => {
    return smoothness ? monotoneCubic(positions) : getPath(positions)
  }, [smoothness, positions])

  const gradientDeps = [gradient?.length && gradient[0] && gradient[1], positions]

  const gradientDefsNode = useMemo(() => {
    return (
      gradient && (
        <defs>
          <linearGradient id="gradient" x1="0" x2="0" y1="1" y2="0">
            <stop className="stop1" offset="0%" stopColor={gradient[0]} />
            <stop className="stop2" offset="100%" stopColor={gradient[1]} />
          </linearGradient>
        </defs>
      )
    )
    // optimised deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, gradientDeps)

  const gradientNode = useMemo(
    () => gradient && <Gradient path={smoothness ? monotoneCubic(positions, true) : getPath(positions, true)} />,
    // optimised deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    gradientDeps,
  )

  const lineNode = useMemo(() => {
    return (
      <g transform={`translate(${chartPadding}, ${height + 1}) scale(1, -1)`}>
        <Line path={path} color={color} strokeWidth={strokeWidth} />
        {gradientNode}
      </g>
    )
  }, [color, strokeWidth, height, chartPadding, path, gradientNode])

  const pointsNode = useMemo(() => {
    return (
      <g transform={`translate(${chartPadding}, ${height}) scale(1, -1)`} ref={ref}>
        {hoverablePoints}
        {clickablePoints}
      </g>
    )
  }, [ref, height, chartPadding, hoverablePoints, clickablePoints])

  const svgNode = useMemo(() => {
    return (
      <svg className="IncomeLine-Svg" ref={svgRef}>
        {gradientDefsNode}
        {width > 0 && height > 0 && (
          <Fragment>
            {lineNode}
            {pointsNode}
          </Fragment>
        )}
      </svg>
    )
  }, [svgRef, width, height, gradientDefsNode, lineNode, pointsNode])

  const ticksData: IncomeLimeTickDataItem[] | null = useMemo(() => {
    if (fretless) {
      return null
    }

    if (width > 0 && height > 0) {
      return chartValues
        .map(({ data, section }, index) => {
          const formattedDate = formatDate(data.date, 'DD MMM YYYY')
          const showTick = showTicks.includes(index)
          const [begin, end] = section

          if (!showTick) {
            return null
          }

          return {
            formattedDate,
            begin,
            end,
          }
        })
        .filter((item) => Boolean(item)) as IncomeLimeTickDataItem[]
    }

    return null
  }, [fretless, width, height, chartValues, showTicks])

  const ticksNode = useMemo(() => {
    if (!ticksData || ticksData.length < 1) {
      return null
    }

    return ticksData.map(({ formattedDate, begin, end }) => (
      <div key={formattedDate} className="IncomeLine-Tick" style={{ width: end - begin, left: begin + chartPadding }}>
        <ChartHorizontalTick
          className="IncomeLine-TickHolder"
          textNodeClassName="IncomeLine-TextNode"
          text={formattedDate}
        />
      </div>
    ))
  }, [ticksData, chartPadding])

  return (
    <div className={classes} data-test-id={dataTestId}>
      {svgNode}
      {ticksNode}
    </div>
  )
})

export { IncomeLine, type IncomeLineProps, type IncomeLineDataItem }
