import memoizee from 'memoizee'

const JSONnormalizer = (...args): string => JSON.stringify(args) || 'undefined'

const memoizeOne = <Func extends (...args: unknown[]) => unknown>(fn: Func): Func =>
  memoizee(fn, { max: 1, normalizer: JSONnormalizer })
const memoizeForATime = <Func extends (...args: unknown[]) => unknown>(fn: Func, maxAge: number = 60000): Func =>
  memoizee(fn, { maxAge, normalizer: JSONnormalizer })
const memoizeForever = <Func extends (...args: unknown[]) => unknown>(fn: Func): Func =>
  memoizee(fn, { normalizer: JSONnormalizer })

export { memoizeOne, memoizeForATime, memoizeForever }
