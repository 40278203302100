import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

type SimpleTextModalProps = {
  title?: string | React.ReactNode
  text: React.ReactNode
  isOpen: boolean
  onClose: () => void
}

const SimpleTextModal = ({ title, text, isOpen, onClose }: SimpleTextModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const content = (
    <Fragment>
      {title && (
        <Paper bottom={16}>
          {typeof title === 'string' ? (
            <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold">
              <Typo>{title}</Typo>
            </Typography>
          ) : (
            title
          )}
        </Paper>
      )}
      <Paper>{text}</Paper>
    </Fragment>
  )

  if (desktop) {
    return (
      <Modal open={isOpen} onClose={onClose} zIndex={1001}>
        <ModalContent width={36} center>
          <Paper top={80} bottom={80} left={64} right={64}>
            {content}
          </Paper>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Fragment>
      <Modal open={isOpen} onClose={onClose} zIndex={1001}>
        <MobileLayout
          header={<NavigationBar onLeftPartClick={onClose} leftPartText="Back" />}
          content={
            <Paper top={12} bottom={48}>
              {content}
            </Paper>
          }
          footer={
            <MobileLayoutFooterButton>
              <Button onClick={onClose}>OK</Button>
            </MobileLayoutFooterButton>
          }
        />
      </Modal>
    </Fragment>
  )
}

export { SimpleTextModal }
