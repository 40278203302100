import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { Preloader } from 'components/molecules/Preloader'

import { useCreateRequest, type UseCreateRequestInterface, type UseReportsInterface } from '../hooks'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

function CreateReport(reportsProps: UseReportsInterface): React.ReactElement {
  const { desktop } = useMediaQueries()
  const createRequestProps = useCreateRequest()
  const props = { ...reportsProps, ...createRequestProps }

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}

      <Preloader loading={reportsProps.isLoading} size="big" zIndex />
      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

type CreateReportChildProps = UseReportsInterface & UseCreateRequestInterface

export { CreateReport, type CreateReportChildProps }
