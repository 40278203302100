import flattenDeep from 'lodash/flattenDeep'

import { features } from 'helpers/features'

import { questionTypes } from 'constants/questionnaire.js'

const getQuestionnaireSteps = (parentModule, routes = [], questions = [], client, portfolio) => {
  const preparedRoutes = routes
    .map(({ component, path, ...rest }) => {
      if (rest.recursiveSteps) {
        return rest.childRoutes.map(({ component, ...rest }) => rest)
      }

      if (rest.insertableQuestions) {
        return questions
          .filter(({ type }) =>
            features.get('new-questionnaire')
              ? [questionTypes.SINGLE, questionTypes.MULTIPLE, questionTypes.TEXT].includes(type)
              : [questionTypes.SINGLE, questionTypes.MULTIPLE].includes(type),
          )
          .map(({ code, title, description }) => ({
            ...rest,
            isQuestion: true,
            headline: title,
            code,
            questionCode: code,
            description,
          }))
      }

      if (rest.isQuestion) {
        return {
          ...rest,
          questionCode: rest.code,
        }
      }

      return rest
    })
    .filter((step) => !step.filterFromSteps)

  const processStep = (step) => {
    const { module, recursiveSteps, childRoutes, hideWhen, ...rest } = step

    if (typeof hideWhen === 'function') {
      if (hideWhen({ client, portfolio })) {
        return null
      }
    }

    if (recursiveSteps) {
      return childRoutes.map(processStep).filter((step) => Boolean(step))
    } else {
      return {
        ...rest,
        module: `${parentModule}.${module}`,
        initModule: module,
      }
    }
  }

  return flattenDeep(preparedRoutes)
    .map(processStep)
    .filter((step) => Boolean(step))
}

export { getQuestionnaireSteps }
