import React from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

type RecurringPaymentRestrictionModalProps = {
  open: boolean
  onClose: () => void
}

const RecurringPaymentRestrictionModal = ({
  open,
  onClose: handleClose,
}: RecurringPaymentRestrictionModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const content = (
    <Paper>
      <AllCenter>
        <Icon type="exclamation-triangle" color={palette['status-warning']} size={64} />
      </AllCenter>
      <Paper top={32}>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
          <Typo>Recurring payments</Typo>
        </Typography>
      </Paper>
      <Paper top={32}>
        <Typography size={desktop ? 14 : 16} align="center">
          <Typo>
            You can set up or adjust the amount and frequency of any recurring payments via the 'Options' menu on the
            portfolio page when you're ready to make any changes
          </Typo>
        </Typography>
      </Paper>
    </Paper>
  )

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose} zIndex={1001}>
        <ModalContent width={44} center>
          <Paper top={80} bottom={80} left={64} right={64}>
            <Paper left={8} right={8}>
              {content}
            </Paper>
            <Paper top={56}>
              <Width size={16} center>
                <Button onClick={handleClose} mods={{ size: 'big block' }}>
                  Close
                </Button>
              </Width>
            </Paper>
          </Paper>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <MobileLayout
        header={<NavigationBar onLeftPartClick={handleClose} leftPartText="Back" />}
        content={
          <AllCenter>
            <Paper top={48} bottom={48}>
              {content}
            </Paper>
          </AllCenter>
        }
        footer={
          <MobileLayoutFooterButton>
            <Button onClick={handleClose}>Close</Button>
          </MobileLayoutFooterButton>
        }
      />
    </Modal>
  )
}

export { RecurringPaymentRestrictionModal }
