import React from 'react'

import { useMemo } from 'hooks'

import { TwoFactorAwait } from 'components/_old/TwoFactorAwait/TwoFactorAwait'

import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'

type NotificationStepMobileProps = {
  handleBack: () => void
  handleSuccess: () => void
  handleFailure: () => void
}

const Mobile = ({ handleBack, handleSuccess, handleFailure }: NotificationStepMobileProps): React.ReactElement => {
  const headerNode = useMemo(
    () => (
      <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
        Step 3 of 3
      </NavigationBar>
    ),
    [handleBack],
  )

  const contentNode = (
    <TwoFactorAwait showTopBar={false} onAccept={handleSuccess} onDeny={handleFailure} shouldVerifySms={false} />
  )

  return <MobileLayout header={headerNode} content={contentNode} />
}

export { Mobile }
