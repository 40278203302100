import { useMemo, useState, useCallback, useEffect } from 'hooks'

import { format } from 'helpers/date.js'

import type { IncomeLineDataItem } from 'components/organisms/charts/parts'

import { usePeriodData, type UsePeriodDataInterface } from './usePeriodData'
import { useTooltip, type UseTooltipInterface } from './useTooltip'

type UsePricePeakCoordinates = {
  max?: {
    right: number
    bottom: number
  }
  min?: {
    right: number
    bottom: number
  }
  current?: {
    right: number
    bottom: number
  }
}

type UsePriceChartInterface = {
  data: UsePeriodDataInterface & {
    contributionsPeriodLineData?: IncomeLineDataItem[]
    withFloat: boolean
  }
  tooltip: UseTooltipInterface
  firstDate: string | null
  lastDate: string | null
  peakCoordinates: UsePricePeakCoordinates | undefined
}

const usePriceChart = (
  period: string,
  data: IncomeLineDataItem[],
  contributionsData?: IncomeLineDataItem[],
): UsePriceChartInterface => {
  if (!contributionsData) {
    contributionsData = []
  }

  const values = usePeriodData(period, data)
  const contributions = usePeriodData(period, contributionsData)

  const { rootRef, lineRef, hoveredIndex, handleEnter, handleLeave } = useTooltip()

  const { totalMin, totalMax, periodMin, periodMax } = useMemo(
    () => {
      let totalMin = (contributionsData ? Math.min(values.totalMin, contributions.totalMin) : values.totalMin) ?? 0
      let totalMax = (contributionsData ? Math.max(values.totalMax, contributions.totalMax) : values.totalMax) ?? 0
      let periodMin = (contributionsData ? Math.min(values.periodMin, contributions.periodMin) : values.periodMin) ?? 0
      let periodMax = (contributionsData ? Math.max(values.periodMax, contributions.periodMax) : values.periodMax) ?? 0

      // when values is the same, add a lil amount to max/min to ensure that values will be at the middle of the chart
      if (totalMin === totalMax) {
        totalMin = totalMin - 0.001
        totalMax = totalMax + 0.001
      }
      if (periodMin === periodMax) {
        periodMin = periodMin - 0.001
        periodMax = periodMax + 0.001
      }

      return { totalMin, totalMax, periodMin, periodMax }
    },
    // more elaborate caching
    /* eslint-disable */
    [
      contributionsData.length,
      contributionsData.at(0)?.date,
      contributionsData.at(-1)?.date,
      values.totalMin,
      contributions.totalMin,
      values.totalMax,
      contributions.totalMax,
      values.periodMin,
      contributions.periodMin,
      values.periodMax,
      contributions.periodMax,
    ],
    /* eslint-enable */
  )

  const withFloat = true

  const getFormattedDate = useCallback(
    (dataItem: IncomeLineDataItem | undefined) => (dataItem ? format(dataItem.date, 'DD MMM YY') : null),
    [],
  )
  const firstDate = useMemo(
    () => getFormattedDate(values.periodLineData.at(0)),
    [values.periodLineData, getFormattedDate],
  )
  const lastDate = useMemo(
    () => getFormattedDate(values.periodLineData.at(-1)),
    [values.periodLineData, getFormattedDate],
  )

  const [peakCoordinates, setPeakCoordinates] = useState<UsePricePeakCoordinates | undefined>(undefined)

  const handleIncomeLineRendered = useCallback(
    (event: CustomEvent<UsePricePeakCoordinates>) => {
      setPeakCoordinates(event.detail)
    },
    [setPeakCoordinates],
  )

  useEffect(() => {
    window.addEventListener('IncomeLine:Rendered', handleIncomeLineRendered)

    return () => {
      window.removeEventListener('IncomeLine:Rendered', handleIncomeLineRendered)
    }
  }, [handleIncomeLineRendered])

  return {
    data: {
      totalMin,
      totalMax,
      periodLineData: values.periodLineData,
      contributionsPeriodLineData: contributions.periodLineData,
      periodMin,
      periodMax,
      withFloat,
    },
    tooltip: {
      rootRef,
      lineRef,
      hoveredIndex,
      handleEnter,
      handleLeave,
    },
    firstDate,
    lastDate,
    peakCoordinates,
  }
}

export { usePriceChart, type UsePricePeakCoordinates }
