import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { SelectableCard } from 'components/atoms/SelectableCard'

import './PortfolioTypeCard.css'

const PortfolioTypeCard = ({ element = {}, onClick: handleClick } = {}) => {
  const { name, headline, description, disabled } = element

  const { desktop } = useMediaQueries()

  const verticalOffset = desktop ? 40 : 32
  const horizontalOffset = 24

  const titleNode = (
    <Typography size={24} lineHeight="small" align="center">
      {headline}
    </Typography>
  )

  return (
    <SelectableCard
      className={classNames('Button-Proxy PortfolioTypeCard')}
      onClick={handleClick}
      data-typechoose-select={name}
      disabled={disabled}
      flex
    >
      <div
        className={classNames('PortfolioTypeCard-Line', {
          [`PortfolioTypeCard-Line_${name}`]: true,
        })}
      />
      <Paper top={verticalOffset} bottom={verticalOffset} right={horizontalOffset} left={horizontalOffset}>
        {titleNode}
        <Paper top={16}>
          <Typography size={desktop ? 14 : 16} align="center" color="additional">
            {disabled ? (
              <Typo>
                This service is temporarily unavailable while we implement some important updates. Existing portfolio
                holders are unaffected
              </Typo>
            ) : (
              <Typo>{description}</Typo>
            )}
          </Typography>
        </Paper>
      </Paper>
    </SelectableCard>
  )
}

PortfolioTypeCard.propTypes = {
  element: PropTypes.shape({
    name: PropTypes.oneOf(['DIY', 'MANAGED', 'SELF_SELECTED']),
    headline: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    icon: PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
    }),
    disabled: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

export { PortfolioTypeCard }
