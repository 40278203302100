import { QuestionnaireQuestionMultiple } from './Multiple.jsx'
import { QuestionnaireQuestionSingle } from './Single.jsx'
import { QuestionnaireQuestionText } from './Text'

import { questionTypes } from 'constants/questionnaire.js'

const Question = {
  [questionTypes.SINGLE]: QuestionnaireQuestionSingle,
  [questionTypes.MULTIPLE]: QuestionnaireQuestionMultiple,
  [questionTypes.TEXT]: QuestionnaireQuestionText,
}

export { Question }
