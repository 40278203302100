import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

/**
 * @class
 * @typedef {Object} SecurityDocumentItem
 */
export class SecurityDocumentItem extends Entity {
  constructor() {
    super()

    /** @type {?string} */
    this.title = null
    /** @type {?number} */
    this.uri = null
    /** @type {?boolean} */
    this.is_kiid = false
  }

  /** @override */
  getRules() {
    return {
      title: [[rules.string]],
      uri: [[rules.uri]],
      is_kiid: [[rules.bool]],
    }
  }
}
