import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

type SlowSecurityMentionProps = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

const SlowSecurityMention = ({ open, onClose, onSubmit }: SlowSecurityMentionProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const content = (
    <Paper>
      <AllCenter>
        <Icon type="time-48" size={48} color={palette['content-on-background-primary']} />
      </AllCenter>
      <Paper top={desktop ? 40 : 20} bottom={desktop ? 32 : 12}>
        <Typography size={desktop ? 32 : 24} weight="semibold" align="center" lineHeight="small">
          <Typo>Trades in this ETF may take longer to settle</Typo>
        </Typography>
      </Paper>
      <Typography align="center" size={desktop ? 14 : 16}>
        <Typo>
          Buy and sell trades for this ETF may take longer to settle than the usual two business days (‘T+2’).{' '}
          <Link
            to="https://help.investengine.com/hc/en-gb/articles/5021858224029-What-is-Settlement-and-how-long-does-it-take-"
            blank
            hard
          >
            Read more here.
          </Link>
        </Typo>
      </Typography>
    </Paper>
  )

  if (desktop) {
    return (
      <Fragment>
        <Modal open={open} close={null} zIndex={1001}>
          <ModalContent width={36}>
            <Paper top={120} bottom={96} left={64} right={64}>
              {content}
              <Width size={24} center>
                <Paper top={56}>
                  <Button mods={{ theme: 'simple-reverse-blue', size: 'big block' }} onClick={onClose}>
                    Cancel
                  </Button>
                </Paper>
                <Paper top={24}>
                  <Button onClick={onSubmit} mods={{ size: 'big block' }}>
                    Continue
                  </Button>
                </Paper>
              </Width>
            </Paper>
          </ModalContent>
        </Modal>
        <GatewayDest name="supportModal" />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Modal open={open} onClose={onClose} zIndex={1001}>
        <MobileLayout
          content={
            <AllCenter>
              <Paper top={48} bottom={48}>
                {content}
              </Paper>
            </AllCenter>
          }
          footer={
            <Fragment>
              <MobileLayoutFooterButton>
                <Button onClick={onClose} mods={{ theme: 'simple-reverse-blue', size: 'big block' }}>
                  Cancel
                </Button>
              </MobileLayoutFooterButton>
              <MobileLayoutFooterButton>
                <Button onClick={onSubmit}>Continue</Button>
              </MobileLayoutFooterButton>
            </Fragment>
          }
        />
      </Modal>
      <GatewayDest name="supportModal" />
    </Fragment>
  )
}

SlowSecurityMention.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export { SlowSecurityMention }
