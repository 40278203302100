import { useUnit } from 'effector-react'

import { useMemo, useSelector } from 'hooks'

import { $portfolioSecuritiesStore } from 'app/effector/portfolio-securities'

import { PortfolioList } from 'app/redux/models/portfolio/'
import { selectGoalById as selectPortfolioById } from 'app/redux/selectors'

import { useSecurities } from 'app/pages/Securities/hooks'

import { useNavbar } from './useNavbar.js'

import { types as clientTypes } from 'constants/client'
import { manageTypes } from 'constants/portfolio'

const usePortfolioBuySell = ({ portfolioId }) => {
  const { isLoading } = useSecurities({ portfolioId })

  const client = useSelector((state) => state.client)
  const portfolio = useSelector((state) => selectPortfolioById(state, portfolioId))
  const portfolios = useSelector((state) =>
    state.portfolios.list
      .getVisiblePortfolios()
      .filter(({ id, manage_type: manageType }) => id === portfolio?.id || manageType === manageTypes.CASH),
  )
  const cashPortfolios = [...new PortfolioList(...portfolios)]
  const { selectSecuritiesInPortfolioDetailed } = useUnit($portfolioSecuritiesStore)

  const securities = selectSecuritiesInPortfolioDetailed(portfolioId, false)
  const noTradingSecurities = useMemo(
    () => securities.filter(([security]) => !security.is_trading_available),
    [securities],
  )
  const noTradingSecuritiesWithNonZeroWeights = useMemo(
    () => noTradingSecurities.filter(([security, portfolioSecurity]) => portfolioSecurity.target_weight > 0),
    [noTradingSecurities],
  )
  const isTradingSuspended = noTradingSecurities.length === securities.length && !isLoading

  const isBusiness = client.type === clientTypes.BUSINESS

  const { handleBack, handleAddFunds, handleReview, handleSell, handleBuy } = useNavbar({ portfolioId })

  return {
    isLoading,
    portfolio,
    cashPortfolios,
    securities,
    noTradingSecurities,
    isTradingSuspended,
    noTradingSecuritiesWithNonZeroWeights,

    handleBack,
    handleAddFunds,
    handleReview,
    handleSell,
    handleBuy,

    isBusiness,
  }
}

export { usePortfolioBuySell }
