import React from 'react'

import './NonSelectableText.css'

type NonSelectableTextProps = {
  children: React.ReactNode
  tag: keyof JSX.IntrinsicElements
}

const NonSelectableText = ({ tag, children }: NonSelectableTextProps): React.ReactElement => {
  const Tag = tag ?? 'span'

  return <Tag className="NonSelectableText">{children}</Tag>
}

export { NonSelectableText }
