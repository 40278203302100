import { createStore } from 'effector'
import { pending } from 'patronum'

import { fetchContactsFx } from 'app/effector/contacts'
import { fetchNomineesTransferProvidersFx } from 'app/effector/nominees-transfers/providers'

import { type PageUrl, submitFx, setPrevPage, setNextPage, resetPage } from '../../index'

const $prevPage = createStore<PageUrl>(null)
  .on(setPrevPage, (_, prevPage) => prevPage)
  .reset(resetPage)

const $nextPage = createStore<PageUrl>(null)
  .on(setNextPage, (_, nextPage) => nextPage)
  .reset(resetPage)

const $isPageLoading = pending([fetchContactsFx, fetchNomineesTransferProvidersFx, submitFx])

export { $prevPage, $nextPage, $isPageLoading }
