import moment from 'helpers/date.js'

import { type DividendsChartData } from '../../types'

enum Periods {
  PERIOD_ALL_TIME = 'max',
  PERIOD_YEAR = 'year',
  PERIOD_SIX_MONTHS = 'six_months',
  PERIOD_ONE_MONTH = 'month',
  PERIOD_ONE_WEEK = 'week',
}

const filterDividendsDataByPeriod = (
  rawData: DividendsChartData = [],
  period = Periods.PERIOD_ALL_TIME,
): DividendsChartData => {
  if (period === Periods.PERIOD_ALL_TIME) return rawData.filter((item) => !item.last_week_paid_out)

  const lastWeekPaidOutItem = rawData?.find((item) => !!item.last_week_paid_out)

  let beginDate = moment().subtract(1, 'year')

  switch (period) {
    case Periods.PERIOD_SIX_MONTHS:
      beginDate = moment().subtract(6, 'months')
      break
    case Periods.PERIOD_ONE_MONTH:
      beginDate = moment().subtract(1, 'months')
      break
    case Periods.PERIOD_ONE_WEEK:
      if (lastWeekPaidOutItem)
        return [
          {
            date: new Date().toString(),
            paid_out: lastWeekPaidOutItem?.last_week_paid_out ?? '0',
            paid_out_by_security: lastWeekPaidOutItem?.paid_out_by_security ?? [],
          },
        ]
      beginDate = moment().subtract(1, 'weeks')
      break
  }

  const now = moment()

  return rawData.filter((dataItem) => {
    const date = moment(dataItem.date)

    return !dataItem.last_week_paid_out && date >= beginDate && date <= now
  })
}

export { filterDividendsDataByPeriod, Periods }
