import React from 'react'

import classNames from 'classnames/dedupe'

import './Progress.css'

type ProgressProps = {
  className?: string
  fill: number
  gapWidth?: string
}

function Progress({ className, fill, gapWidth }: ProgressProps): React.ReactElement {
  const classes = classNames(className, 'Progress')

  return (
    <div className={classes} data-progress-percent={fill} data-test-id="progressBar">
      {gapWidth ? <div className="Progress-Gap Progress-Gap_begin" style={{ width: gapWidth }} /> : null}
      <div className="Progress-Holder">
        <div className="Progress-Bar" style={{ width: fill ? `${fill}%` : undefined }} />
      </div>
      {gapWidth && fill < 100 ? <div className="Progress-Gap Progress-Gap_end" style={{ width: gapWidth }} /> : null}
    </div>
  )
}

export { Progress }
