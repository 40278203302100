import React from 'react'

import PropTypes from 'prop-types'

import { useCallback, useMediaQueries } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Width from 'components/_old/Width/Width'

import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

import PersonalForm from 'app/pages/Forms/PersonalForm.jsx'

import { types as clientTypes } from 'constants/client'

const PersonalSetup = ({ handleAfterSubmit, changeOwnerField, updateOwner }) => {
  const { desktop } = useMediaQueries()

  const wrapButton = (button) =>
    desktop ? (
      <div style={{ marginTop: '3em' }}>{button}</div>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {button}
      </ModalContentButtons>
    )

  const handleSubmit = useCallback(
    async (keys) => {
      const result = await updateOwner(keys)

      if (!result.result) {
        return processError({ error: result.error })
      }

      trackEvent({
        action: 'personal_information_submitted',
        client_type: clientTypes.INDIVIDUAL,
      })

      handleAfterSubmit()
    },
    [updateOwner, handleAfterSubmit],
  )

  const form = (
    <PersonalForm
      onOwnerFieldChange={changeOwnerField}
      onSubmit={handleSubmit}
      wrapButton={wrapButton}
      submitButton={<Button mods={{ size: 'big block' }}>Continue</Button>}
      inputMods={{ size: 'bigger' }}
    />
  )

  if (desktop) {
    return (
      <Card
        mods={{
          theme: !desktop ? 'edge-to-edge transparent' : null,
          'no-padding': desktop ? null : 'top',
        }}
      >
        <Width size={22}>{form}</Width>
      </Card>
    )
  }

  return form
}

PersonalSetup.propTypes = {
  handleAfterSubmit: PropTypes.func,
  changeOwnerField: PropTypes.func,
  updateOwner: PropTypes.func,
}

export { PersonalSetup }
