import React, { Children } from 'react'

import classNames from 'classnames/dedupe'

import { Paper, type PaperSizes } from 'components/atoms/Paper'

import './Stack.css'

const StackAligns = ['stretch', 'start', 'center', 'end', 'baseline'] as const

type StackBaseProps = {
  children: React.ReactNode | React.ReactNodeArray
  className?: string
  align?: ArrayElement<typeof StackAligns>
  inline?: boolean
}

type StackVerticalProps = StackBaseProps & {
  vertical: ArrayElement<typeof PaperSizes>
}

type StackHorizontalProps = StackBaseProps & {
  horizontal: ArrayElement<typeof PaperSizes>
}

type StackProps = XOR<StackVerticalProps, StackHorizontalProps>

const Stack = ({
  children,
  className,
  vertical,
  horizontal,
  align = 'stretch',
  inline = false,
}: StackProps): React.ReactElement => {
  const classes = classNames(className, 'Stack', {
    Stack_vertical: vertical,
    Stack_horizontal: horizontal,
    [`Stack_align_${align}`]: align,
    Stack_inline: inline,
  })

  return (
    <div className={classes}>
      {Children.toArray(children)
        .filter((child) => Boolean(child))
        .map((child, index) => {
          const isFirst = index === 0

          if (isFirst) {
            return child
          }

          if (vertical) {
            return (
              <Paper key={index} top={vertical}>
                {child}
              </Paper>
            )
          }

          return (
            <Paper key={index} left={horizontal}>
              {child}
            </Paper>
          )
        })}
    </div>
  )
}

export { Stack, StackAligns }
