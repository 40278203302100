import React from 'react'
import { withRouter } from 'react-router'

import { useCallback, useMediaQueries } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { goTo, urlTo } from 'helpers/router.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import List, { ListItem } from 'components/_old/List/List.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

import isaImage from './isa.png'

type OpenIsaModalProps = {
  location: {
    query: {
      openIsa?: string
      transferIsa?: string
      forwardToTransferIsa?: string
    }
  }
}

const OpenIsaModal = withRouter(({ location }: OpenIsaModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { openIsa, transferIsa, forwardToTransferIsa } = location?.query ?? {}
  const isOpen = openIsa === 'true'
  const isTransfer = transferIsa === 'true'

  const handleClose = useCallback(() => {
    goTo(urlTo('dashboard'))
  }, [])

  const handleContinue = useCallback(() => {
    goTo(
      urlTo(
        'dashboard.sign-isa-declaration',
        null,
        querystringFromObject({ forwardToTransferIsa, action: isTransfer ? 'transferIsa' : 'openIsa' }),
      ),
    )
  }, [forwardToTransferIsa, isTransfer])

  const headerNode = (
    <NavigationBar rightPartText="Cancel" onRightPartClick={handleClose} plain linkStyle="onColorWhiteToRed" />
  )

  const buttonNode = (
    <Button
      onClick={handleContinue}
      mods={{ size: 'big block', color: 'green' }}
      data-test-id="openIsaModalContinueButton"
    >
      Continue
    </Button>
  )

  const content = (
    <Paper top={32}>
      <AllCenter>
        <img src={isaImage} width={132} height={132} />
      </AllCenter>
      <Width size={27} center>
        <Paper top={desktop ? 32 : 24}>
          <Typography size={36} lineHeight="small" weight="semibold" align="center" color="white">
            <Typo>Open an ISA account</Typo>
          </Typography>
        </Paper>
        <Paper top={desktop ? 32 : 24}>
          <Typography size={desktop ? 16 : 14} color="white">
            <List mods={{ type: 'bullet', 'no-margin': 'top' }}>
              <ListItem>
                <Typo>
                  Enjoy tax‑free investing and pay no account fees on your ISA. Your only costs relate to the portfolio
                  type you choose.
                </Typo>
              </ListItem>
              <ListItem>
                <Paper top={20}>
                  <Typo>
                    Make the most of your yearly ISA allowance with an InvestEngine Stocks & Shares ISA and give your
                    cash the chance to grow.
                  </Typo>
                </Paper>
              </ListItem>
              <ListItem>
                <Paper top={20}>
                  <Typo>
                    Invest in your choice of hundreds of high quality ETFs, or let us build and manage a portfolio for
                    you.
                  </Typo>
                </Paper>
              </ListItem>
            </List>
          </Typography>
        </Paper>
      </Width>
      {desktop && (
        <Paper top={32} bottom={80}>
          <Width size={21} center>
            {buttonNode}
          </Width>
        </Paper>
      )}
    </Paper>
  )

  if (desktop)
    return (
      <Modal
        background="primary"
        zIndex={1002}
        open={isOpen || isTransfer}
        close={null}
        onClose={handleClose}
        data-test-id="openIsaModal"
      >
        <Width size={36}>
          <Paper top={20} bottom={20} left={24} right={24}>
            {headerNode}
          </Paper>
          {content}
        </Width>
      </Modal>
    )

  return (
    <Modal background="primary" zIndex={1002} open={isOpen || isTransfer} onClose={handleClose}>
      <MobileLayout
        header={headerNode}
        content={content}
        footer={<MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
})

export { OpenIsaModal }
