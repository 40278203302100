enum ScreenState {
  START = 'START',
  TRUSTPILOT_SCREEN = 'TRUSTPILOT_SCREEN',
  ZENDESK_SCREEN = 'ZENDESK_SCREEN',
  CLOSED = 'CLOSED',
}

enum Event {
  SHOW_MODAL = 'SHOW_MODAL',
  BUTTON_CLICK = 'BUTTON_CLICK',
  LINK_CLICK = 'LINK_CLICK',
  BACK = 'BACK',
  CLOSE = 'CLOSE',
}

const states = {
  [ScreenState.START]: {
    on: {
      [Event.BUTTON_CLICK]: ScreenState.TRUSTPILOT_SCREEN,
      [Event.LINK_CLICK]: ScreenState.ZENDESK_SCREEN,
      [Event.CLOSE]: ScreenState.CLOSED,
    },
  },
  [ScreenState.TRUSTPILOT_SCREEN]: {
    on: {
      [Event.BUTTON_CLICK]: {
        target: ScreenState.CLOSED,
        actions: ['openTrustpilotLink'],
      },
      [Event.LINK_CLICK]: ScreenState.CLOSED,
      [Event.BACK]: ScreenState.START,
      [Event.CLOSE]: ScreenState.CLOSED,
    },
  },
  [ScreenState.ZENDESK_SCREEN]: {
    on: {
      [Event.BUTTON_CLICK]: {
        target: ScreenState.CLOSED,
        actions: ['openZendeskLink'],
      },
      [Event.LINK_CLICK]: ScreenState.CLOSED,
      [Event.BACK]: ScreenState.START,
      [Event.CLOSE]: ScreenState.CLOSED,
    },
  },
  [ScreenState.CLOSED]: {
    entry: ['updateLastSeenDate'],
    on: {
      [Event.SHOW_MODAL]: ScreenState.START,
    },
  },
}

export { ScreenState, Event, states }
