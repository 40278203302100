import React from 'react'

import { useCallback } from 'hooks'

import { type ReportFormat } from 'app/effector/reports/models'

import Button from 'components/_old/Button/Button.jsx'
import { Nobr } from 'components/_old/Nobr'

import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { FilterElement } from 'components/organisms/Filter/FilterElement'

import { ReportFormatIcon } from '../../ReportFormatIcon'
import { type CreateReportFormPartProps } from '../CreateReportForm'

function SelectType({
  className,
  contentClassName,
  buttonClassName,
  visible,
  buttonTitle,
  desktop,
  reportTypes,
  selectedType,
  setSelectedType,
  handleSelectType,
}: CreateReportFormPartProps): React.ReactElement {
  const renderFormat = useCallback(
    (format: ReportFormat, index: number): React.ReactElement | undefined => (
      <Column size={0}>
        <Paper left={index > 0 ? 4 : 0}>
          <ReportFormatIcon format={format} />
        </Paper>
      </Column>
    ),
    [],
  )

  return (
    <div className={className} hidden={!visible}>
      <SideSpacesCompensator className={contentClassName}>
        {reportTypes.map((reportType) => {
          const name = (
            <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
              <Column size={0}>
                <Nobr>{reportType.name}</Nobr>
              </Column>
              {reportType.formats.length > 0 && (
                <Column>
                  <Paper left={8}>
                    <ColumnarLayout mods={{ padding: 'no' }}>{reportType.formats.map(renderFormat)}</ColumnarLayout>
                  </Paper>
                </Column>
              )}
            </ColumnarLayout>
          )

          return (
            <FilterElement
              key={reportType.type}
              name={name}
              value={reportType.type}
              type="radio"
              paperSizes={{
                desktop: {
                  left: 16,
                  right: 16,
                },
              }}
              checked={selectedType === reportType.type}
              onChange={() => {
                setSelectedType(reportType.type ?? '')
              }}
              withLine
            />
          )
        })}
      </SideSpacesCompensator>
      <Paper className={buttonClassName} top={desktop ? 24 : 16} bottom={desktop ? 56 : null}>
        <Button onClick={handleSelectType} mods={{ size: desktop ? 'block' : 'big block' }} disabled={!selectedType}>
          {buttonTitle}
        </Button>
      </Paper>
    </div>
  )
}

export { SelectType }
