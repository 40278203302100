/*  eslint-disable @typescript-eslint/naming-convention */
import moment from 'helpers/date.js'

import { getRoundedMinMaxValues } from './getRoundedMinMaxValues'

const transformIncomeHistoryData = (rawData = [], defaultMax = 0.1) => {
  const data = rawData?.map
    ? rawData.map(({ date: rawDate, paid_out: rawPaidOut, collected: rawCollected }) => {
        const date = moment(rawDate).toDate()
        const paid_out = parseFloat(rawPaidOut || '0')
        const collected = (() => {
          let collected = parseFloat(rawCollected || '0')

          if (collected <= 0) {
            collected = null
          }

          return collected
        })()

        return {
          date,
          paid_out,
          collected,
        }
      })
    : []

  const rawMax = Math.max(...data.map(({ paid_out, collected }) => paid_out + collected))
  const { max, withFloat } = getRoundedMinMaxValues(0, rawMax || defaultMax, false)
  const paid_out_sum = data.reduce((sum, { paid_out }) => sum + paid_out, 0)

  return {
    max,
    paid_out_sum,
    data: data.map(({ date, paid_out, collected }) => ({
      date,
      value: collected ? [paid_out, collected] : paid_out,
    })),
    withFloat,
  }
}

export { transformIncomeHistoryData }
