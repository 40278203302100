import React from 'react'

import Transactions from './Transactions.jsx'
import { AllOrders } from './AllOrders'
import BankTransferForm from '../Goals/AddFunds/BankTransfer/BankTransferForm.jsx'

const childRoutes = [
  {
    index: true,
    isNewLayout: true,
    module: 'list',
    name: 'Transactions',
    component: function Component(props) {
      return <Transactions {...props} />
    },
    childRoutes: [
      {
        module: 'add-funds',
        path: 'add-funds/',
        component: function Component(props) {
          return <BankTransferForm {...props} />
        },
      },
    ],
  },
  {
    isNewLayout: true,
    module: 'security-transactions',
    path: 'security/',
    name: 'Transactions',
    component: function Component(props) {
      return <Transactions {...props} isSecurityTransactionsPage />
    },
  },
  {
    isNewLayout: true,
    module: 'all-orders',
    path: 'orders/',
    component: function Component(props) {
      return <AllOrders {...props} />
    },
  },
]

export default {
  module: 'transactions',
  path: 'transactions/',
  childRoutes,
  indexRoute: childRoutes.find((route) => route.index),
}
