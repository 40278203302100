import { goTo, urlTo } from 'helpers/router.js'

import { ActionKey, ActionGroup, ActionStatus, type Action } from './usePortfolioActions'

import { manageTypes as portfolioManageTypes } from 'constants/portfolio'

type useAutoinvestInterface = Action

const useAutoinvestInOptions = ({ portfolio, tunnelQuery }): useAutoinvestInterface => {
  const portfolioId: number | undefined = portfolio?.id
  const isAutoinvestLinkVisible = portfolio?.manage_type === portfolioManageTypes.DIY
  const isAutoinvestEnabled = portfolio?.is_autoinvest_enabled

  const getQueryWithBack = (
    back?: string,
    additional?: Record<string, string | number | boolean>,
  ): Record<string, string> => {
    let query: Record<string, string | number | boolean> = { ...tunnelQuery, ...additional }

    if (back) {
      query = { ...query, back }
    }

    return query
  }

  const handleOpenAutoinvest = (back?: string): void => {
    if (portfolio?.weights_setup_needed) {
      goTo(urlTo(`dashboard.portfolio`, { id: portfolioId }, { holdingsChanged: null }))
      return
    }

    goTo(urlTo(`dashboard.portfolio.autoinvest`, { id: portfolioId }, getQueryWithBack(back)))
  }

  return {
    key: ActionKey.AUTOINVEST,
    title: 'AutoInvest available cash',
    group: ActionGroup.FUNDING,
    status: isAutoinvestEnabled ? ActionStatus.ON : ActionStatus.OFF,
    visible: isAutoinvestLinkVisible,
    enabled: true,
    onClick: handleOpenAutoinvest,
    'data-test-id': 'portfolioAutoInvest',
  }
}

export { useAutoinvestInOptions }
