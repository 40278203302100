// quick fix for https://app.asana.com/0/1202304843784991/1202635259316452/f

const hardCodedCountries = [
  {
    country: 'Afghanistan',
    country_id: 1149361,
  },
  {
    country: 'Aland Islands',
    country_id: 661882,
  },
  {
    country: 'Albania',
    country_id: 783754,
  },
  {
    country: 'Algeria',
    country_id: 2589581,
  },
  {
    country: 'American Samoa',
    country_id: 5880801,
  },
  {
    country: 'Andorra',
    country_id: 3041565,
  },
  {
    country: 'Angola',
    country_id: 3351879,
  },
  {
    country: 'Anguilla',
    country_id: 3573511,
  },
  {
    country: 'Antarctica',
    country_id: 6697173,
  },
  {
    country: 'Antigua and Barbuda',
    country_id: 3576396,
  },
  {
    country: 'Argentina',
    country_id: 3865483,
  },
  {
    country: 'Armenia',
    country_id: 174982,
  },
  {
    country: 'Aruba',
    country_id: 3577279,
  },
  {
    country: 'Australia',
    country_id: 2077456,
  },
  {
    country: 'Austria',
    country_id: 2782113,
  },
  {
    country: 'Azerbaijan',
    country_id: 587116,
  },
  {
    country: 'Bahamas',
    country_id: 3572887,
  },
  {
    country: 'Bahrain',
    country_id: 290291,
  },
  {
    country: 'Bangladesh',
    country_id: 1210997,
  },
  {
    country: 'Barbados',
    country_id: 3374084,
  },
  {
    country: 'Belarus',
    country_id: 630336,
  },
  {
    country: 'Belgium',
    country_id: 2802361,
  },
  {
    country: 'Belize',
    country_id: 3582678,
  },
  {
    country: 'Benin',
    country_id: 2395170,
  },
  {
    country: 'Bermuda',
    country_id: 3573345,
  },
  {
    country: 'Bhutan',
    country_id: 1252634,
  },
  {
    country: 'Bolivia',
    country_id: 3923057,
  },
  {
    country: 'Bonaire, Saint Eustatius and Saba ',
    country_id: 7626844,
  },
  {
    country: 'Bosnia and Herzegovina',
    country_id: 3277605,
  },
  {
    country: 'Botswana',
    country_id: 933860,
  },
  {
    country: 'Bouvet Island',
    country_id: 3371123,
  },
  {
    country: 'Brazil',
    country_id: 3469034,
  },
  {
    country: 'British Indian Ocean Territory',
    country_id: 1282588,
  },
  {
    country: 'British Virgin Islands',
    country_id: 3577718,
  },
  {
    country: 'Brunei',
    country_id: 1820814,
  },
  {
    country: 'Bulgaria',
    country_id: 732800,
  },
  {
    country: 'Burkina Faso',
    country_id: 2361809,
  },
  {
    country: 'Burundi',
    country_id: 433561,
  },
  {
    country: 'Cambodia',
    country_id: 1831722,
  },
  {
    country: 'Cameroon',
    country_id: 2233387,
  },
  {
    country: 'Canada',
    country_id: 6251999,
  },
  {
    country: 'Cape Verde',
    country_id: 3374766,
  },
  {
    country: 'Cayman Islands',
    country_id: 3580718,
  },
  {
    country: 'Central African Republic',
    country_id: 239880,
  },
  {
    country: 'Chad',
    country_id: 2434508,
  },
  {
    country: 'Chile',
    country_id: 3895114,
  },
  {
    country: 'China',
    country_id: 1814991,
  },
  {
    country: 'Christmas Island',
    country_id: 2078138,
  },
  {
    country: 'Cocos Islands',
    country_id: 1547376,
  },
  {
    country: 'Colombia',
    country_id: 3686110,
  },
  {
    country: 'Comoros',
    country_id: 921929,
  },
  {
    country: 'Cook Islands',
    country_id: 1899402,
  },
  {
    country: 'Costa Rica',
    country_id: 3624060,
  },
  {
    country: 'Croatia',
    country_id: 3202326,
  },
  {
    country: 'Cuba',
    country_id: 3562981,
  },
  {
    country: 'Curacao',
    country_id: 7626836,
  },
  {
    country: 'Cyprus',
    country_id: 146669,
  },
  {
    country: 'Czechia',
    country_id: 3077311,
  },
  {
    country: 'Democratic Republic of the Congo',
    country_id: 203312,
  },
  {
    country: 'Denmark',
    country_id: 2623032,
  },
  {
    country: 'Djibouti',
    country_id: 223816,
  },
  {
    country: 'Dominica',
    country_id: 3575830,
  },
  {
    country: 'Dominican Republic',
    country_id: 3508796,
  },
  {
    country: 'East Timor',
    country_id: 1966436,
  },
  {
    country: 'Ecuador',
    country_id: 3658394,
  },
  {
    country: 'Egypt',
    country_id: 357994,
  },
  {
    country: 'El Salvador',
    country_id: 3585968,
  },
  {
    country: 'Equatorial Guinea',
    country_id: 2309096,
  },
  {
    country: 'Eritrea',
    country_id: 338010,
  },
  {
    country: 'Estonia',
    country_id: 453733,
  },
  {
    country: 'Ethiopia',
    country_id: 337996,
  },
  {
    country: 'Falkland Islands',
    country_id: 3474414,
  },
  {
    country: 'Faroe Islands',
    country_id: 2622320,
  },
  {
    country: 'Fiji',
    country_id: 2205218,
  },
  {
    country: 'Finland',
    country_id: 660013,
  },
  {
    country: 'France',
    country_id: 3017382,
  },
  {
    country: 'French Guiana',
    country_id: 3381670,
  },
  {
    country: 'French Polynesia',
    country_id: 4030656,
  },
  {
    country: 'French Southern Territories',
    country_id: 1546748,
  },
  {
    country: 'Gabon',
    country_id: 2400553,
  },
  {
    country: 'Gambia',
    country_id: 2413451,
  },
  {
    country: 'Georgia',
    country_id: 614540,
  },
  {
    country: 'Germany',
    country_id: 2921044,
  },
  {
    country: 'Ghana',
    country_id: 2300660,
  },
  {
    country: 'Gibraltar',
    country_id: 2411586,
  },
  {
    country: 'Greece',
    country_id: 390903,
  },
  {
    country: 'Greenland',
    country_id: 3425505,
  },
  {
    country: 'Grenada',
    country_id: 3580239,
  },
  {
    country: 'Guadeloupe',
    country_id: 3579143,
  },
  {
    country: 'Guam',
    country_id: 4043988,
  },
  {
    country: 'Guatemala',
    country_id: 3595528,
  },
  {
    country: 'Guernsey',
    country_id: 3042362,
  },
  {
    country: 'Guinea',
    country_id: 2420477,
  },
  {
    country: 'Guinea-Bissau',
    country_id: 2372248,
  },
  {
    country: 'Guyana',
    country_id: 3378535,
  },
  {
    country: 'Haiti',
    country_id: 3723988,
  },
  {
    country: 'Heard Island and McDonald Islands',
    country_id: 1547314,
  },
  {
    country: 'Honduras',
    country_id: 3608932,
  },
  {
    country: 'Hong Kong',
    country_id: 1819730,
  },
  {
    country: 'Hungary',
    country_id: 719819,
  },
  {
    country: 'Iceland',
    country_id: 2629691,
  },
  {
    country: 'India',
    country_id: 1269750,
  },
  {
    country: 'Indonesia',
    country_id: 1643084,
  },
  {
    country: 'Iran',
    country_id: 130758,
  },
  {
    country: 'Iraq',
    country_id: 99237,
  },
  {
    country: 'Ireland',
    country_id: 2963597,
  },
  {
    country: 'Isle of Man',
    country_id: 3042225,
  },
  {
    country: 'Israel',
    country_id: 294640,
  },
  {
    country: 'Italy',
    country_id: 3175395,
  },
  {
    country: 'Ivory Coast',
    country_id: 2287781,
  },
  {
    country: 'Jamaica',
    country_id: 3489940,
  },
  {
    country: 'Japan',
    country_id: 1861060,
  },
  {
    country: 'Jersey',
    country_id: 3042142,
  },
  {
    country: 'Jordan',
    country_id: 248816,
  },
  {
    country: 'Kazakhstan',
    country_id: 1522867,
  },
  {
    country: 'Kenya',
    country_id: 192950,
  },
  {
    country: 'Kiribati',
    country_id: 4030945,
  },
  {
    country: 'Kosovo',
    country_id: 831053,
  },
  {
    country: 'Kuwait',
    country_id: 285570,
  },
  {
    country: 'Kyrgyzstan',
    country_id: 1527747,
  },
  {
    country: 'Laos',
    country_id: 1655842,
  },
  {
    country: 'Latvia',
    country_id: 458258,
  },
  {
    country: 'Lebanon',
    country_id: 272103,
  },
  {
    country: 'Lesotho',
    country_id: 932692,
  },
  {
    country: 'Liberia',
    country_id: 2275384,
  },
  {
    country: 'Libya',
    country_id: 2215636,
  },
  {
    country: 'Liechtenstein',
    country_id: 3042058,
  },
  {
    country: 'Lithuania',
    country_id: 597427,
  },
  {
    country: 'Luxembourg',
    country_id: 2960313,
  },
  {
    country: 'Macao',
    country_id: 1821275,
  },
  {
    country: 'Macedonia',
    country_id: 718075,
  },
  {
    country: 'Madagascar',
    country_id: 1062947,
  },
  {
    country: 'Malawi',
    country_id: 927384,
  },
  {
    country: 'Malaysia',
    country_id: 1733045,
  },
  {
    country: 'Maldives',
    country_id: 1282028,
  },
  {
    country: 'Mali',
    country_id: 2453866,
  },
  {
    country: 'Malta',
    country_id: 2562770,
  },
  {
    country: 'Marshall Islands',
    country_id: 2080185,
  },
  {
    country: 'Martinique',
    country_id: 3570311,
  },
  {
    country: 'Mauritania',
    country_id: 2378080,
  },
  {
    country: 'Mauritius',
    country_id: 934292,
  },
  {
    country: 'Mayotte',
    country_id: 1024031,
  },
  {
    country: 'Mexico',
    country_id: 3996063,
  },
  {
    country: 'Micronesia',
    country_id: 2081918,
  },
  {
    country: 'Moldova',
    country_id: 617790,
  },
  {
    country: 'Monaco',
    country_id: 2993457,
  },
  {
    country: 'Mongolia',
    country_id: 2029969,
  },
  {
    country: 'Montenegro',
    country_id: 3194884,
  },
  {
    country: 'Montserrat',
    country_id: 3578097,
  },
  {
    country: 'Morocco',
    country_id: 2542007,
  },
  {
    country: 'Mozambique',
    country_id: 1036973,
  },
  {
    country: 'Myanmar',
    country_id: 1327865,
  },
  {
    country: 'Namibia',
    country_id: 3355338,
  },
  {
    country: 'Nauru',
    country_id: 2110425,
  },
  {
    country: 'Nepal',
    country_id: 1282988,
  },
  {
    country: 'Netherlands',
    country_id: 2750405,
  },
  {
    country: 'New Caledonia',
    country_id: 2139685,
  },
  {
    country: 'New Zealand',
    country_id: 2186224,
  },
  {
    country: 'Nicaragua',
    country_id: 3617476,
  },
  {
    country: 'Niger',
    country_id: 2440476,
  },
  {
    country: 'Nigeria',
    country_id: 2328926,
  },
  {
    country: 'Niue',
    country_id: 4036232,
  },
  {
    country: 'Norfolk Island',
    country_id: 2155115,
  },
  {
    country: 'North Korea',
    country_id: 1873107,
  },
  {
    country: 'Northern Mariana Islands',
    country_id: 4041468,
  },
  {
    country: 'Norway',
    country_id: 3144096,
  },
  {
    country: 'Oman',
    country_id: 286963,
  },
  {
    country: 'Pakistan',
    country_id: 1168579,
  },
  {
    country: 'Palau',
    country_id: 1559582,
  },
  {
    country: 'Palestinian Territory',
    country_id: 6254930,
  },
  {
    country: 'Panama',
    country_id: 3703430,
  },
  {
    country: 'Papua New Guinea',
    country_id: 2088628,
  },
  {
    country: 'Paraguay',
    country_id: 3437598,
  },
  {
    country: 'Peru',
    country_id: 3932488,
  },
  {
    country: 'Philippines',
    country_id: 1694008,
  },
  {
    country: 'Pitcairn',
    country_id: 4030699,
  },
  {
    country: 'Poland',
    country_id: 798544,
  },
  {
    country: 'Portugal',
    country_id: 2264397,
  },
  {
    country: 'Puerto Rico',
    country_id: 4566966,
  },
  {
    country: 'Qatar',
    country_id: 289688,
  },
  {
    country: 'Republic of the Congo',
    country_id: 2260494,
  },
  {
    country: 'Reunion',
    country_id: 935317,
  },
  {
    country: 'Romania',
    country_id: 798549,
  },
  {
    country: 'Russia',
    country_id: 2017370,
  },
  {
    country: 'Rwanda',
    country_id: 49518,
  },
  {
    country: 'Saint Barthelemy',
    country_id: 3578476,
  },
  {
    country: 'Saint Helena',
    country_id: 3370751,
  },
  {
    country: 'Saint Kitts and Nevis',
    country_id: 3575174,
  },
  {
    country: 'Saint Lucia',
    country_id: 3576468,
  },
  {
    country: 'Saint Martin',
    country_id: 3578421,
  },
  {
    country: 'Saint Pierre and Miquelon',
    country_id: 3424932,
  },
  {
    country: 'Saint Vincent and the Grenadines',
    country_id: 3577815,
  },
  {
    country: 'Samoa',
    country_id: 4034894,
  },
  {
    country: 'San Marino',
    country_id: 3168068,
  },
  {
    country: 'Sao Tome and Principe',
    country_id: 2410758,
  },
  {
    country: 'Saudi Arabia',
    country_id: 102358,
  },
  {
    country: 'Senegal',
    country_id: 2245662,
  },
  {
    country: 'Serbia',
    country_id: 6290252,
  },
  {
    country: 'Seychelles',
    country_id: 241170,
  },
  {
    country: 'Sierra Leone',
    country_id: 2403846,
  },
  {
    country: 'Singapore',
    country_id: 1880251,
  },
  {
    country: 'Sint Maarten',
    country_id: 7609695,
  },
  {
    country: 'Slovakia',
    country_id: 3057568,
  },
  {
    country: 'Slovenia',
    country_id: 3190538,
  },
  {
    country: 'Solomon Islands',
    country_id: 2103350,
  },
  {
    country: 'Somalia',
    country_id: 51537,
  },
  {
    country: 'South Africa',
    country_id: 953987,
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    country_id: 3474415,
  },
  {
    country: 'South Korea',
    country_id: 1835841,
  },
  {
    country: 'South Sudan',
    country_id: 7909807,
  },
  {
    country: 'Spain',
    country_id: 2510769,
  },
  {
    country: 'Sri Lanka',
    country_id: 1227603,
  },
  {
    country: 'Sudan',
    country_id: 366755,
  },
  {
    country: 'Suriname',
    country_id: 3382998,
  },
  {
    country: 'Svalbard and Jan Mayen',
    country_id: 607072,
  },
  {
    country: 'Swaziland',
    country_id: 934841,
  },
  {
    country: 'Sweden',
    country_id: 2661886,
  },
  {
    country: 'Switzerland',
    country_id: 2658434,
  },
  {
    country: 'Syria',
    country_id: 163843,
  },
  {
    country: 'Taiwan',
    country_id: 1668284,
  },
  {
    country: 'Tajikistan',
    country_id: 1220409,
  },
  {
    country: 'Tanzania',
    country_id: 149590,
  },
  {
    country: 'Thailand',
    country_id: 1605651,
  },
  {
    country: 'Togo',
    country_id: 2363686,
  },
  {
    country: 'Tokelau',
    country_id: 4031074,
  },
  {
    country: 'Tonga',
    country_id: 4032283,
  },
  {
    country: 'Trinidad and Tobago',
    country_id: 3573591,
  },
  {
    country: 'Tunisia',
    country_id: 2464461,
  },
  {
    country: 'Turkey',
    country_id: 298795,
  },
  {
    country: 'Turkmenistan',
    country_id: 1218197,
  },
  {
    country: 'Turks and Caicos Islands',
    country_id: 3576916,
  },
  {
    country: 'Tuvalu',
    country_id: 2110297,
  },
  {
    country: 'U.S. Virgin Islands',
    country_id: 4796775,
  },
  {
    country: 'Uganda',
    country_id: 226074,
  },
  {
    country: 'Ukraine',
    country_id: 690791,
  },
  {
    country: 'United Arab Emirates',
    country_id: 290557,
  },
  {
    country: 'United Kingdom',
    country_id: 2635167,
  },
  {
    country: 'United States',
    country_id: 6252001,
  },
  {
    country: 'United States Minor Outlying Islands',
    country_id: 5854968,
  },
  {
    country: 'Uruguay',
    country_id: 3439705,
  },
  {
    country: 'Uzbekistan',
    country_id: 1512440,
  },
  {
    country: 'Vanuatu',
    country_id: 2134431,
  },
  {
    country: 'Vatican',
    country_id: 3164670,
  },
  {
    country: 'Venezuela',
    country_id: 3625428,
  },
  {
    country: 'Vietnam',
    country_id: 1562822,
  },
  {
    country: 'Wallis and Futuna',
    country_id: 4034749,
  },
  {
    country: 'Western Sahara',
    country_id: 2461445,
  },
  {
    country: 'Yemen',
    country_id: 69543,
  },
  {
    country: 'Zambia',
    country_id: 895949,
  },
  {
    country: 'Zimbabwe',
    country_id: 878675,
  },
]

export { hardCodedCountries }
