import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { Paper } from 'components/atoms/Paper'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { Typography } from 'components/atoms/Typography'

const SecurityPreview = ({ logo, title }) => {
  const { desktop } = useMediaQueries()

  return desktop ? (
    <Fragment>
      <Paper style={{ display: 'flex', justifyContent: 'center' }}>
        <SecurityLogo logo={logo} size={48} />
      </Paper>
      <Paper top={16}>
        <Typography size={16} lineHeight="small" align="center" color="minor">
          {title}
        </Typography>
      </Paper>
    </Fragment>
  ) : (
    <Paper style={{ display: 'flex', alignItems: 'center' }}>
      <Paper right={16}>
        <SecurityLogo logo={logo} size={36} style={{ display: 'inline' }} />
      </Paper>
      <Typography size={16} weight="semibold" lineHeight="small">
        {title}
      </Typography>
    </Paper>
  )
}

SecurityPreview.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export { SecurityPreview }
