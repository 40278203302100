import React from 'react'

import { Hints } from 'components/organisms/Hints/Hints'

type EditWeightsHintProps = {
  highlightedRef: React.RefObject<Element>
}

const EditWeightsHint = ({ highlightedRef }: EditWeightsHintProps): React.ReactElement | null => {
  const hints = [
    {
      ref: highlightedRef,
      text: 'Your chosen weights will apply to future portfolio investments. You can change these weights at any time.',
    },
  ]

  return <Hints nameKey="edit_weights" hints={hints} />
}

export { EditWeightsHint }
