import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import { useStrapiBonuses } from 'app/pages/Dashboard/PromoBanner/hooks/useStrapiBonuses'

type TsCsModalProps = {
  show: boolean
  isPromoEnabled: boolean
  handleClose: () => void
}

const TsCsModal = ({ isPromoEnabled, show, handleClose }: TsCsModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { bonuses } = useStrapiBonuses()

  return (
    <Modal open={show} onClose={handleClose} close={null}>
      <ModalContent width={36}>
        <Paper right={desktop ? 24 : 0} top={desktop ? 8 : 0} bottom={desktop ? 8 : 0}>
          <NavigationBar disableLeftPart rightPartText="Close" onRightPartClick={handleClose} />
        </Paper>
        <Paper top={desktop ? 80 : 16} bottom={desktop ? 120 : 0} left={desktop ? 48 : 16} right={desktop ? 48 : 16}>
          <Typography size={desktop ? 32 : 24} align="center" weight="semibold" lineHeight="small">
            Share Portfolio Outline {isPromoEnabled ? '&' : ''}
            <br />
            {isPromoEnabled ? `up to ${bonuses?.referIsaOrGeneralBonusMax} Referral Bonus` : ''}
          </Typography>
          <Paper top={desktop ? 32 : 24}>
            <Typography size={desktop ? 14 : 16}>
              <Typo>
                The Share Portfolio Outline feature provides a shareable link to your portfolio's outline which shows
                the ETFs you've chosen and their target weights. The link does not provide access to any personal data
                or £ amounts in your portfolio.
              </Typo>
            </Typography>
            {isPromoEnabled && (
              <Fragment>
                <br />
                <Typography size={desktop ? 14 : 16}>
                  <Typo>
                    When you share your link with friends, if they are not yet an InvestEngine customer but open an
                    account via that link and invest in a portfolio, both of you will earn a between{' '}
                    {bonuses?.referIsaOrGeneralBonusMin}-{bonuses?.referIsaOrGeneralBonusMax} bonus.
                  </Typo>
                </Typography>
                <Paper top={desktop ? 32 : 24}>
                  <Link to="/terms-incentives/#referral_program" blank hard>
                    Referral bonus Ts&Cs apply
                  </Link>
                </Paper>
              </Fragment>
            )}
          </Paper>
        </Paper>
      </ModalContent>
    </Modal>
  )
}

export { TsCsModal }
