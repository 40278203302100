import React from 'react'

import { useState, useMediaQueries, useMemo, useCallback } from 'hooks'

import { palette } from 'helpers/palette/'

import Icon from 'components/_old/Icon/Icon.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent, { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

const labelsData = {
  accumulating: {
    label: 'Acc',
    title: 'Acc',
    icon: 'repeat',
    space: 4,
    color: palette['content-on-background-primary'],
    text: `An accumulating ETF ("Acc") automatically reinvests any income from its underlying holdings back into
            the ETF, rather than paying it out(as with a distributing ETF).`,
  },
  yield: {
    label: 'Yield',
    title: 'Yield',
    icon: 'pound',
    space: 4,
    color: palette['content-on-background-primary'],
    text: `This is a distributing ETF that pays out the income from its underlying holdings, rather than automatically reinvesting it (as with an accumulating ETF).
    Its yield is the last 12 months of income as a % of its current share price.`,
  },
  distributing: {
    label: 'Dist',
    title: 'Dist',
    icon: 'pound',
    space: 4,
    color: palette['content-on-background-primary'],
    text: `A distributing ETF ("Dist") pays out the income from its underlying holdings, rather than automatically
          reinvesting it (as with an accumulating ETF).`,
  },
  hedged: {
    label: '£ Hedged',
    title: 'Currency-hedged',
    icon: 'hedged',
    space: 4,
    color: palette.alternatives,
    text: `Currency-hedged ETFs ("£ Hedged") are designed to protect investors from exchange rate movements when
                holding overseas assets.`,
  },
  esg: {
    label: 'ESG',
    title: 'ESG',
    icon: 'leaf',
    space: 4,
    color: palette['secondary-default'],
    text: `ESG stands for Environmental, Social and Governance, and means the underlying holdings of the ETF have
                been screened for some or all of these criteria.`,
  },
  ter: {
    label: 'TER',
    title: 'Total Expense Ratio',
    icon: 'ter',
    space: 4,
    color: palette['content-on-background-primary'],
    text: `TER stands for Total Expense Ratio and is how much in percentage (%) terms that the ETF charges for investment management and admin costs each year.\n
      The TER is deducted directly from the ETF rather than being charged separately, and the value and performance of your investment is quoted after this deduction.`,
  },
}

type Label = 'accumulating' | 'distributing' | 'hedged' | 'esg' | 'ter' | 'yield'

interface LabelsDescriptionProps {
  labels: Label[]
  ter?: string
  yield?: number
}

const LabelsDescription = ({ labels, ter, yield: yieldValue }: LabelsDescriptionProps): React.ReactElement => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const { desktop } = useMediaQueries()

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])
  const handleOpen = useCallback(() => {
    setShowModal(true)
  }, [setShowModal])

  const labelsPanel = useMemo(
    () => (
      <Typography size={16} color="additional" lineHeight="small">
        {labels.map((lab) => {
          const { label, icon, space, color } = labelsData[lab]
          const isEsg = lab === 'esg'
          const isTer = lab === 'ter'
          const isYield = lab === 'yield'

          return (
            <Width key={label} inline>
              <Paper right={desktop ? 16 : 12} bottom={12}>
                <ItemWithIcon
                  space={space}
                  icon={
                    <Paper top={isEsg || isTer ? 0 : 1}>
                      <Icon
                        type={`${icon}-16`}
                        style={isTer ? { width: '22px' } : {}}
                        size={isTer ? null : 16}
                        color={color}
                      />
                    </Paper>
                  }
                  content={
                    <Text noWrap>
                      <Typo>{isTer ? ter : isYield ? `Yield ${yieldValue as number}%` : label}</Typo>
                    </Text>
                  }
                  iconVerticalAlign="center"
                  contentVerticalAlign="center"
                  iconPosition="right"
                  inline
                />
              </Paper>
            </Width>
          )
        })}
      </Typography>
    ),
    [desktop, labels, ter, yieldValue],
  )

  const descriptions = useMemo(
    () =>
      labels.map((label) => {
        const { title, icon, text, color } = labelsData[label]
        const isTer = label === 'ter'

        return (
          <Paper key={label} bottom={48}>
            <ItemWithIcon
              space={8}
              icon={<Icon type={`${icon}-32`} size={isTer ? 0 : 32} color={color} />}
              content={
                <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold">
                  <Typo>{title}</Typo>
                </Typography>
              }
              iconVerticalAlign="center"
              iconPosition="right"
              inline
            />
            <Paper top={16}>
              <Typography size={16}>
                <Typo multiline>{text}</Typo>
              </Typography>
            </Paper>
          </Paper>
        )
      }),
    [desktop, labels],
  )

  return (
    <div>
      <SelectableCard onClick={handleOpen}>
        <Paper top={16} bottom={4} left={16} right={16}>
          <ItemWithIcon
            space={8}
            icon={
              <Paper bottom={12}>
                <Icon type="chevron_right" size={20} color={palette['content-on-background-muted']} />
              </Paper>
            }
            content={labelsPanel}
            contentVerticalAlign="center"
            iconVerticalAlign="center"
            iconPosition="right"
            data-test-id="labelsDescription"
          />
        </Paper>
      </SelectableCard>

      <Modal open={showModal} onClose={handleClose}>
        <ModalContent width={38}>
          {!desktop && <NavigationBar leftPartText="Back" onLeftPartClick={handleClose} />}
          <Paper top={desktop ? 80 : 32} bottom={desktop ? 32 : 0} left={desktop ? 80 : 16} right={desktop ? 80 : 16}>
            {descriptions}
          </Paper>
          {!desktop && <ModalContentButtons isNewModal text="OK" onClick={handleClose} />}
        </ModalContent>
      </Modal>
    </div>
  )
}

export { LabelsDescription, type LabelsDescriptionProps, type Label }
