import React, { createContext, useContext, useState, type ReactNode } from 'react'

interface LockHeaderScrollContextValue {
  lockHeaderScroll: boolean
  setLockHeaderScroll: React.Dispatch<React.SetStateAction<boolean>>
}

const LockHeaderScrollContext = createContext<LockHeaderScrollContextValue | undefined>(undefined)

interface LockHeaderScrollProviderProps {
  children: ReactNode
}

const LockHeaderScrollProvider: React.FC<LockHeaderScrollProviderProps> = ({ children }) => {
  const [lockHeaderScroll, setLockHeaderScroll] = useState(false)

  return (
    <LockHeaderScrollContext.Provider value={{ lockHeaderScroll, setLockHeaderScroll }}>
      {children}
    </LockHeaderScrollContext.Provider>
  )
}

export const useLockHeaderScroll = (): LockHeaderScrollContextValue => {
  const context = useContext(LockHeaderScrollContext)

  if (!context) {
    throw new Error('useLockHeaderScroll must be used within a LockHeaderScrollProvider')
  }

  return context
}

export { LockHeaderScrollProvider }
