import React from 'react'
import PropTypes from 'prop-types'

import Label, { LabelText, LabelField } from 'components/_old/Label/Label.jsx'
import ErrorMessage from 'components/_old/ErrorMessage/ErrorMessage.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'

export default function LabelInlineStyle({
  children: inputField,
  labelText,
  valueIfDisabled,
  brokenRule,
  errorMessages,
  dataTestId,
  multiline,
  valid = true,
  disabled,
  optional,
  postfield,
  size,
  preventFocusOnLabelClick,
  noNbsp,
  onBlur: handleBlur,
  onFocus: handleFocus,
}) {
  const isErrorVisible = !disabled && !valid
  let labelFlex = 'fieldGrow inlineStyleForm'

  if (disabled) {
    labelFlex = `${labelFlex} inlineStyleFormDisabled`
  }

  if (typeof labelText === 'string') {
    labelText = (
      <Typo noNbsp={noNbsp} data-test-id={dataTestId}>
        {labelText}
      </Typo>
    )
  }

  if (multiline) {
    labelFlex = `${labelFlex} inlineStyleFormMultiline`

    return (
      <Label
        flex={labelFlex}
        valid={valid}
        mods={{ size }}
        preventFocusOnLabelClick={preventFocusOnLabelClick}
        onBlur={handleBlur}
        onFocus={handleFocus}
        data-test-id={dataTestId}
      >
        <LabelText>
          {isErrorVisible && <ErrorMessage>{errorMessages[brokenRule]}</ErrorMessage>}
          {!isErrorVisible && labelText}
        </LabelText>
        <LabelField>
          {!disabled && inputField}
          {postfield && (
            <Text className="Label-Postfield" smaller color="gray">
              {postfield}
            </Text>
          )}
          {!disabled && optional && (
            <Text className="Label-Postfield" smaller color="darkgray">
              Optional
            </Text>
          )}
          {disabled && valueIfDisabled}
        </LabelField>
      </Label>
    )
  }

  return (
    <Label
      flex={labelFlex}
      valid={valid}
      mods={{ size }}
      preventFocusOnLabelClick={preventFocusOnLabelClick}
      onBlur={handleBlur}
      onFocus={handleFocus}
      data-test-id={dataTestId}
    >
      <LabelText>{labelText}</LabelText>
      <LabelField>
        {isErrorVisible && <ErrorMessage>{errorMessages[brokenRule]}</ErrorMessage>}
        {!disabled && inputField}
        {postfield && (
          <Text className="Label-Postfield" smaller color="gray">
            {postfield}
          </Text>
        )}
        {!disabled && optional && (
          <Text className="Label-Postfield" smaller color="darkgray">
            Optional
          </Text>
        )}
        {disabled && <span data-test-id={`${dataTestId}Value`}>{valueIfDisabled}</span>}
      </LabelField>
    </Label>
  )
}

LabelInlineStyle.propTypes = {
  children: PropTypes.node.isRequired,
  labelText: PropTypes.string.isRequired,
  valueIfDisabled: PropTypes.any,
  brokenRule: PropTypes.number,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  dataTestId: PropTypes.string,
  multiline: PropTypes.bool,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  optional: PropTypes.bool,
  postfield: PropTypes.string,
  size: PropTypes.string,
  preventFocusOnLabelClick: PropTypes.bool,
  noNbsp: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}
