import { PortfolioSecurityList } from './PortfolioSecurityList.js'

/**
 * @typedef PortfolioSecurityCollectionLeaf
 * @type {object}
 * @property {PortfolioSecurityList} current
 * @property {PortfolioSecurityList} perspective
 */

/**
 * @class
 * @typedef {Object.<PortfolioSecurityCollectionLeaf>} PortfolioSecurityCollection
 */
export class PortfolioSecurityCollection {
  constructor(data) {
    if (typeof data === 'object') {
      Object.entries(data).forEach(([portfolioId, securities]) => {
        if (securities.current && securities.perspective) {
          this[portfolioId] = { ...securities }

          return
        }

        this[portfolioId] = {
          current: new PortfolioSecurityList(...securities),
          perspective: new PortfolioSecurityList(...securities),
        }
      })
    }
  }

  /**
   * @private
   * @param {PortfolioSecurityList} list
   * @return {PortfolioSecurityList}
   */
  _normalizeList(list) {
    return list && list.length > 0
      ? new PortfolioSecurityList(
          ...[...list].sort(
            (leftPortfolioSecurity, rightPortfolioSecurity) =>
              leftPortfolioSecurity.security_id - rightPortfolioSecurity.security_id,
          ),
        )
      : []
  }

  /**
   * @param {number} portfolioId
   * @return {PortfolioSecurityCollectionLeaf}
   */
  get(portfolioId) {
    return this[`${portfolioId}`]
  }

  /**
   * @param {number} portfolioId
   * @return {?PortfolioSecurityList}
   */
  getCurrent(portfolioId) {
    return this.get(portfolioId)?.current
  }

  /**
   * @param {number} portfolioId
   * @return {?PortfolioSecurityList}
   */
  getPerspective(portfolioId) {
    return this.get(portfolioId)?.perspective
  }

  /**
   * @param {number} portfolioId
   * @return {boolean}
   */
  isModified(portfolioId) {
    const current = JSON.stringify(this._normalizeList(this.getCurrent(portfolioId)))
    const perspective = JSON.stringify(this._normalizeList(this.getPerspective(portfolioId)))

    return current !== perspective
  }

  /**
   * @param {number} portfolioId
   * @param {PortfolioSecurityList} securities
   * @return {PortfolioSecurityCollection}
   */
  set(portfolioId, securities) {
    return new PortfolioSecurityCollection({
      ...this,
      [portfolioId]: new PortfolioSecurityList(...securities),
    })
  }

  /**
   * @param {number} portfolioId
   * @param {PortfolioSecurityList} securities
   * @return {PortfolioSecurityCollection}
   */
  setPerspective(portfolioId, securities) {
    if (this[portfolioId]) {
      return new PortfolioSecurityCollection({
        ...this,
        [portfolioId]: {
          current: this[portfolioId].current,
          perspective: new PortfolioSecurityList(...securities),
        },
      })
    }
  }

  /**
   * @param {number} portfolioId
   * @return {PortfolioSecurityCollection}
   */
  resetPerspective(portfolioId) {
    if (this[portfolioId]) {
      return this.setPerspective(portfolioId, this[portfolioId]?.current)
    }
  }

  getPortfolioIdsWithSecurity(securityId) {
    const entries = Object.entries(this)
    const idPerspectiveListTuples = entries.map(([portfolioId, leaf]) => [portfolioId, leaf.perspective])
    const idPerspectiveListTuplesWithSecurity = idPerspectiveListTuples.filter(([_portfolioId, list]) => {
      return list.filter((portfolioSecurity) => portfolioSecurity.security_id === securityId).length > 0
    })

    return idPerspectiveListTuplesWithSecurity.map(([portfolioId]) => portfolioId).map((string) => parseInt(string, 10))
  }
}
