import React from 'react'

import { format as formatMoney } from 'helpers/money'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Typography } from 'components/atoms/Typography'

import { Chart as ChartComponent, IncomeBars, NumeralAxis } from 'components/organisms/charts/parts'

type ChartProps = {
  rootRef: React.RefObject<Element>
  barsRef: React.RefObject<Element>
  chartColumnClassName?: string
  max: number
  data: Array<{ date: Date; value: number }>
  isEmpty: boolean
  tooltipNode: React.ReactElement | null
  withFloat: boolean
  selectedIndex?: number
  handleColumnEnter: (event: React.MouseEvent, index: number) => void
  handleColumnLeave: (event: React.MouseEvent, index: number) => void
  handleColumnClick: (event: React.MouseEvent, index: number) => void
}

const Chart = ({
  rootRef,
  barsRef,
  max,
  data,
  isEmpty,
  tooltipNode,
  withFloat,
  selectedIndex,
  chartColumnClassName = 'Chart-Column',
  handleColumnEnter,
  handleColumnLeave,
  handleColumnClick,
}: ChartProps): React.ReactElement => {
  return (
    <div className="DividendsChart-Chart" ref={rootRef}>
      <ChartComponent size="large">
        <NumeralAxis min={0} max={max} quantity={isEmpty ? 1 : 5} format={(value) => formatMoney(value, withFloat)} />
        <IncomeBars
          columnClassName={chartColumnClassName}
          ref={barsRef}
          max={max}
          data={data}
          selectedIndex={selectedIndex}
          shouldShowLineOnSelectedTick
          onTickEnter={handleColumnEnter}
          onTickLeave={handleColumnLeave}
          onTickClick={handleColumnClick}
        />
        {isEmpty && (
          <AllCenter className="DividendsChart-Empty">
            <Typography>
              <Typo>Nothing to show just yet</Typo>
            </Typography>
          </AllCenter>
        )}
        {tooltipNode}
      </ChartComponent>
    </div>
  )
}

export { Chart }
