import { useUnit } from 'effector-react'

import { useState, useSelector, useActions, useCallback } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { processError } from 'helpers/errors'
import { goTo, urlTo } from 'helpers/router.js'

import { removeSecurity, $portfolioSecuritiesStore } from 'app/effector/portfolio-securities'

import {
  resetError as resetClientErrorActionCreator,
  setValid as setClientValidActionCreator,
} from 'app/redux/actions/client'
import {
  updateOrCreate as updateOrCreateGoalActionCreator,
  resetError as resetGoalsErrorActionCreator,
  setValid as setValidGoalsActionCreator,
} from 'app/redux/actions/portfolios'

import { flowTypes, useFlowType } from 'app/pages/CreateNewPortfolio/SelectPortfolioType/hooks/flows'

import { manageTypes, states, regulatoryTypes } from 'constants/portfolio'

type UseAddToPortfolioInterface = {
  isIsaOrSippAvailable: boolean
  portfoliosModalOpen: boolean
  handlePortfoliosModalOpen: () => void
  handlePortfoliosModalClose: () => void
  createNewPortfolio: () => Promise<void>
}

const useAddToPortfolio = (handleAddSecurity, query): UseAddToPortfolioInterface => {
  const [portfoliosModalOpen, setPortfoliosModalOpen] = useState(false)
  const portfolios = useSelector((state) =>
    state.portfolios.list.filterByShape({ manage_type: manageTypes.DIY, state: states.NEW }),
  )

  const flowType = useFlowType()
  const isIsaOrSippAvailable = flowType === flowTypes.PERSONAL || flowType === flowTypes.PERSONAL_WITH_SIPP

  const { getSecuritiesByPortfolio } = useUnit($portfolioSecuritiesStore)

  const [updateOrCreateGoal, setClientValid, setGoalsValid, resetGoalsError, resetClientError] = useActions([
    updateOrCreateGoalActionCreator,
    setClientValidActionCreator,
    setValidGoalsActionCreator,
    resetGoalsErrorActionCreator,
    resetClientErrorActionCreator,
  ])

  const handlePortfoliosModalClose = useCallback(() => {
    setPortfoliosModalOpen(false)
  }, [])

  const handlePortfoliosModalOpen = useCallback(() => {
    setPortfoliosModalOpen(true)
  }, [])

  const handleCreateNewPortfolio = useCallback(
    () =>
      updateOrCreateGoal(
        ['manage_type', 'regulatory_type'],
        { manage_type: manageTypes.DIY, regulatory_type: regulatoryTypes.GIA },
        true,
        null,
        false,
      ),
    [updateOrCreateGoal],
  )

  const createNewPortfolio = useCallback(async () => {
    try {
      const alreadyCreatedNewPortfolio = portfolios?.[0] ?? null
      const redirect = (portfolioId): void => {
        if (query?.collection) {
          goTo(
            urlTo(
              'security-collection',
              { slugOrId: query.collection },
              querystringFromObject({
                ...query,
                portfolioId,
                requestRegulatoryType: isIsaOrSippAvailable ? 'true' : false,
                collection: null,
                holdingSearch: null,
                regions: null,
                sectors: null,
              }),
            ),
          )
          return
        }
        goTo(
          urlTo(
            query?.fromDiscovery === 'true' ? 'securities.universe' : 'securities.universe-all',
            null,
            querystringFromObject({
              ...query,
              portfolioId,
              requestRegulatoryType: isIsaOrSippAvailable ? 'true' : false,
            }),
          ),
        )
      }

      if (alreadyCreatedNewPortfolio) {
        const portfolioId = alreadyCreatedNewPortfolio.id

        // clear portfolio securities first
        const list = getSecuritiesByPortfolio(portfolioId, false)
        list?.forEach(({ security_id: securityId }) => removeSecurity({ portfolioId, securityId }))

        handleAddSecurity(null, portfolioId)
        redirect(portfolioId)
        return
      }

      const nextState = await handleCreateNewPortfolio()

      setClientValid()
      setGoalsValid()

      if (nextState.portfolios.error) {
        throw nextState.portfolios.error
      }

      const createdNewPortfolio =
        nextState.portfolios.list.filterByShape({ manage_type: manageTypes.DIY, state: states.NEW })?.[0] ?? null

      if (createdNewPortfolio) {
        const portfolioId = createdNewPortfolio.id
        handleAddSecurity(null, portfolioId)
        redirect(portfolioId)
      }
    } catch (error) {
      processError({
        error,
        forceReset: true,
        resets: [resetGoalsError, resetClientError],
      })
    }
  }, [isIsaOrSippAvailable, handleAddSecurity, portfolios, query]) // eslint-disable-line

  return {
    isIsaOrSippAvailable,
    portfoliosModalOpen,
    handlePortfoliosModalOpen,
    handlePortfoliosModalClose,
    createNewPortfolio,
  }
}

export { useAddToPortfolio }
