import React from 'react'

import PropTypes from 'prop-types'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import { regulatoryTypesText, manageTypes } from 'constants/portfolio'

type PortfolioCardProps = {
  portfolio: Portfolio
  onClick?: () => void
  noType?: boolean
}

const PortfolioCard = ({ portfolio, onClick, noType }: PortfolioCardProps): React.ReactElement | null => {
  if (!portfolio) return null

  const managedType = [manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(portfolio.manage_type)
    ? 'Managed'
    : 'Self-managed'
  const regulatoryType = regulatoryTypesText[portfolio.regulatory_type]

  const content = (
    <Paper top={24} bottom={24} right={20} left={20}>
      <Typography size={noType ? 16 : 18} lineHeight="small" weight="semibold" data-test-id="portfolioCardTitle">
        <Typo>{portfolio.getTitle()}</Typo>
      </Typography>
      {!noType && (
        <Paper top={8}>
          <Typography size={14} lineHeight="small" color="additional" data-test-id="portfolioCardSubtitle">
            <Typo>{`${managedType}, ${regulatoryType}`}</Typo>
          </Typography>
        </Paper>
      )}
    </Paper>
  )

  return onClick ? (
    <SelectableCard onClick={onClick}>{content}</SelectableCard>
  ) : (
    <Card level={0} color="primary-surface-25">
      {content}
    </Card>
  )
}

PortfolioCard.propTypes = {
  portfolio: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  noType: PropTypes.bool,
}

export { PortfolioCard }
