import React from 'react'

import { useSelector, useEffect, useActions, useRedirect } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'

import { Preloader } from 'components/molecules/Preloader'

import { CashPortfolio } from './CashPortfolio/CashPortfolio'
import { Portfolio as DiyPortfolio } from './DIY/Portfolio'
import { ManagedPortfolio } from './ManagedPortfolio/ManagedPortfolio'
import { SelfSelectedPortfolio } from './SelfSelectedPortfolio'

import { types as clientTypes } from 'constants/client'
import { manageTypes, regulatoryTypes } from 'constants/portfolio'

const portfolioComponents = {
  [manageTypes.DIY]: DiyPortfolio,
  [manageTypes.MANAGED]: ManagedPortfolio,
  [manageTypes.SELF_SELECTED]: SelfSelectedPortfolio,
  [manageTypes.CASH]: CashPortfolio,
}

type PortfolioProps = {
  params: {
    id: string
  }
  location: {
    query: object
  }
}

const Portfolio = (props: PortfolioProps): React.ReactElement | null => {
  const fetchPortfolios = useActions(fetchPortfoliosActionCreator)
  const client = useSelector((state) => state.client)
  const portfolios = useSelector((state) => state.portfolios.list)
  const didInvalidate = useSelector((state) => state.portfolios.didInvalidate)
  const portfolioId = props.params.id
  const portfolio = portfolios.find((goal) => goal.id === parseInt(portfolioId, 10))

  useRedirect({
    to: () => {
      goTo(urlTo('dashboard'))
    },
    rule:
      client.type === clientTypes.BUSINESS &&
      [regulatoryTypes.ISA, regulatoryTypes.SIPP].includes(portfolio?.regulatory_type),
    isLoading: client.didInvalidate,
  })

  useEffect(() => {
    if (!portfolios.length) {
      fetchPortfolios()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useRedirect({
    to: () => {
      goTo(urlTo('dashboard'))
    },
    rule: !!portfolios.length && !portfolio,
    isLoading: didInvalidate,
  })

  if (!portfolio) {
    return <Preloader loading={true} size="big" zIndex opaque />
  }

  const PortfolioComponent = portfolioComponents[portfolio.manage_type]

  return <PortfolioComponent {...props} />
}

export { Portfolio }
