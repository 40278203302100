import React, { Fragment } from 'react'

import { DesktopFooter } from 'app/containers/Footer'
import PropTypes from 'prop-types'

import { urlTo } from 'helpers/router.js'

import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'


import { PortfolioUnavailableInterceptor } from 'components/molecules/PortfolioUnavailableInterceptor/PortfolioUnavailableInterceptor'

import { PortfolioTypeCard, RegulatoryTypeCard } from 'app/pages/CreateNewPortfolio/SelectPortfolioType/components'

import { manageTypes } from 'constants/portfolio'

const Desktop = ({ steps, hasIsa, activeStepName, handleBack }) => {
  const header = (
    <Header
      right={
        <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}>
          <Link to={urlTo('dashboard')}>My Dashboard</Link>
        </Button>
      }
      withShadow
      hideDrawer
      logoLink={urlTo('dashboard')}
    />
  )

  const managedTypeStep = steps.find((step) => step.stepName === 'MANAGED_TYPE')
  const managedTypeStepElements = managedTypeStep?.elements ?? []
  const regulatoryTypeStep = steps.find((step) => step.stepName === 'INDIVIDUAL_TYPE')
  const regulatoryTypeStepElements = regulatoryTypeStep?.elements ?? []
  const isMultiStepsFlow = steps.length > 1
  const isTableView = managedTypeStepElements.length >= 4
  const diyElement = managedTypeStepElements.find((element) => (element.name = manageTypes.DIY))

  const managedTypeStepNode = (
    <Fragment>
      <NavigationBar
        leftPartText={isMultiStepsFlow ? 'Add new portfolio' : 'My Dashboard'}
        onLeftPartClick={isMultiStepsFlow ? () => managedTypeStep?.onClose() : handleBack}
      >
        {managedTypeStep?.headline}
      </NavigationBar>
      <Paper top={40}>
        <Width
          center
          size={isTableView ? 41.5 : null}
          style={{
            display: 'flex',
            'justify-content': 'center',
            'flex-wrap': isTableView ? 'wrap' : null,
            'align-items': 'stretch',
          }}
        >
          {managedTypeStepElements.map((element, index) => {
            const isFirstElement = isTableView ? index % 2 === 0 : index === 0
            const isLastElement = isTableView ? index % 2 !== 0 : index === managedTypeStepElements.length - 1

            return element?.hidden ? null : (
              <Paper
                key={element.name}
                bottom={isTableView ? 24 : 0}
                left={isFirstElement ? null : 12}
                right={isLastElement ? null : 12}
                style={{ display: 'flex' }}
              >
                <Width size={20} style={{ display: 'flex' }}>
                  <PortfolioUnavailableInterceptor
                    variant={element.interceptorVariant}
                    hasContinueToDiy
                    onClick={() => managedTypeStep?.onSelect(diyElement)}
                  >
                    <PortfolioTypeCard element={element} onClick={() => managedTypeStep?.onSelect(element)} />
                  </PortfolioUnavailableInterceptor>
                </Width>
              </Paper>
            )
          })}
        </Width>
      </Paper>
    </Fragment>
  )

  const regulatoryTypeStepNode = (
    <Fragment>
      <NavigationBar leftPartText="My Dashboard" onLeftPartClick={handleBack}>
        {regulatoryTypeStep?.headline}
      </NavigationBar>
      <Width size={60} center>
        <Paper top={40}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            {regulatoryTypeStepElements.map((element) => {
              return (
                <Column key={element.name}>
                  <RegulatoryTypeCard
                    data={element}
                    hasIsa={hasIsa}
                    onClick={() => regulatoryTypeStep?.onSelect(element)}
                  />
                </Column>
              )
            })}
          </ColumnarLayout>
        </Paper>
      </Width>
    </Fragment>
  )

  const content = (
    <Inner>
      <Paper top={32}>{activeStepName === 'MANAGED_TYPE' ? managedTypeStepNode : regulatoryTypeStepNode}</Paper>
    </Inner>
  )

  return (
    <Fragment>
      <DesktopLayout header={header} content={content} footer={<DesktopFooter />} />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

Desktop.propTypes = {
  hasIsa: PropTypes.bool,
  steps: PropTypes.array.isRequired,
  activeStepName: PropTypes.string,
  handleBack: PropTypes.func.isRequired,
}

export { Desktop }
