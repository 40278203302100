import React from 'react'
import PropTypes from 'prop-types'
import flow from 'lodash/flow'
import debounce from 'lodash/debounce'

import { palette, isDarkTheme } from 'helpers/palette/'
import { propTypes } from 'helpers/propTypes'

import { useRef, useState, useCallback, useEffect, useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import './DonutChart.css'

const DEFAULT_MINIMAL_SECTOR_VALUE = 1.25 // in %
const MAXIMAL_GALLANT_CAPACITY = 90 // in %

function normalizeFloat(number) {
  return parseFloat((Math.round(number * 100) / 100).toFixed(2))
}

const DonutChart = ({
  children,
  size: initialSize,
  values,
  lineWidth = 8,
  minimalSectorValue = DEFAULT_MINIMAL_SECTOR_VALUE,
}) => {
  const rootNode = useRef(null)
  const [size, setSize] = useState(initialSize || null)

  const getSizeFromNode = useCallback(
    (node) => {
      setSize(node?.parentNode?.getBoundingClientRect()?.width ?? null)
    },
    [setSize],
  )

  const handleResize = useCallback(
    debounce(() => getSizeFromNode(rootNode.current), 250),
    [rootNode.current],
  )

  useEffect(() => {
    if (rootNode.current && !initialSize) {
      getSizeFromNode(rootNode.current)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [rootNode.current, initialSize])

  const gallantValues = useMemo(() => {
    const gallantValues = values.map((value) => {
      if (value[0] <= 0) {
        return [0, value[1]]
      }

      if (value[0] < minimalSectorValue) {
        return [minimalSectorValue, value[1]]
      }

      return value
    })

    const gallantSum = gallantValues.reduce((sum, value) => sum + value[0], 0)

    if (gallantSum > MAXIMAL_GALLANT_CAPACITY) {
      return values
    }

    return gallantValues
  }, [values, minimalSectorValue])

  const svg = useMemo(() => {
    if (size && size > 0) {
      const outerRadius = size / 2
      const innerRadius = outerRadius - lineWidth >= 0 ? outerRadius - lineWidth : 0
      const groupRadius = 100 / (2 * Math.PI)
      const groupDiameter = groupRadius * 2
      const groupTransform = flow(
        (size, diameter) => size / diameter,
        (scale) => `scale3d(${scale}, ${scale}, 1)`,
      )(size, groupDiameter)

      return (
        <svg
          className="DonutChart-Svg"
          width="100%"
          height="100%"
          viewBox={`0 0 ${size} ${size}`}
          data-test-id="donutChart"
        >
          <mask id="mask">
            <circle cx={outerRadius} cy={outerRadius} r={outerRadius} fill="white" />
            <circle cx={outerRadius} cy={outerRadius} r={innerRadius} fill="black" />
          </mask>
          <g mask="url(#mask)">
            <g style={{ transform: groupTransform, transformOrigin: 'center' }}>
              <circle
                cx={size / 2}
                cy={size / 2}
                r={groupRadius}
                fill="transparent"
                stroke={isDarkTheme() ? palette['background-card'] : palette['primary-surface-10']}
                strokeWidth={groupRadius * 2}
              />
              {gallantValues.length > 0
                ? gallantValues.map(([percentage, color], index) => {
                    percentage = normalizeFloat(percentage)

                    if (percentage < 0.1) {
                      color = 'transparent'
                    }

                    const strokeDasharray = `${percentage} ${100 - percentage}`
                    const strokeDashoffset = [[-25], ...gallantValues]
                      .slice(0, index + 1)
                      .reduce((result, [percentage]) => normalizeFloat(result - percentage), 0)

                    return (
                      <circle
                        key={index}
                        cx={size / 2}
                        cy={size / 2}
                        r={groupRadius}
                        fill="transparent"
                        stroke={color}
                        strokeWidth={groupDiameter}
                        strokeDasharray={strokeDasharray}
                        strokeDashoffset={strokeDashoffset}
                      />
                    )
                  })
                : null}
            </g>
          </g>
        </svg>
      )
    }
  }, [gallantValues, lineWidth, size])

  return (
    <div className="DonutChart" ref={rootNode} style={size ? { width: size, height: size } : null}>
      {svg}
      {children && <AllCenter className="DonutChart-Child">{children}</AllCenter>}
    </div>
  )
}

DonutChart.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
  values: propTypes.tuple(PropTypes.number, PropTypes.string),
  lineWidth: PropTypes.number,
  minimalSectorValue: PropTypes.number,
}

export { DonutChart }
