import React from 'react'

import { PersonalSetup } from './PersonalSetup/PersonalSetup.jsx'
import { AddressSetup } from './AddressSetup/AddressSetup.jsx'
import { ProofOfIdentitySetup } from './ProofOfIdentitySetup/ProofOfIdentitySetup'
import { ProvideTaxInfoSetup } from './ProvideTaxInfoSetup/ProvideTaxInfoSetup.jsx'
import { PaymentDetailsSetup } from './PaymentDetailsSetup/PaymentDetailsSetup.jsx'
import { UploadBankStatement } from './UploadBankStatement/UploadBankStatement.jsx'
import { FinancesSetup } from './FinancesSetup/FinancesSetup.jsx'
import { Individual } from './Individual.jsx'
import { Legal } from 'app/pages/Dashboard/SetupAccount/Individual/Legal/Legal.jsx'

const childRoutes = [
  {
    module: 'personal-info',
    headline: 'Personal information',
    component: function Component(props) {
      return <PersonalSetup {...props} />
    },
  },
  {
    module: 'address-info',
    path: 'address-info/',
    headline: 'Residential address',
    component: function Component(props) {
      return <AddressSetup {...props} />
    },
  },
  {
    module: 'provide-tax-info',
    path: 'provide-tax-info/',
    headline: 'Provide tax information',
    component: function Component(props) {
      return <ProvideTaxInfoSetup {...props} />
    },
  },
  {
    module: 'proof-of-identity',
    path: 'proof-of-identity/',
    headline: 'Proof of identity',
    component: function Component(props) {
      return <ProofOfIdentitySetup {...props} clientType="INDIVIDUAL" />
    },
  },
  {
    module: 'setup-funding',
    path: 'setup-funding/',
    headline: 'Nominate bank account',
    component: function Component(props) {
      return <PaymentDetailsSetup {...props} />
    },
  },
  {
    module: 'upload-bank-statement',
    path: 'upload-bank-statement/',
    customHeadline: true,
    component: function Component(props) {
      return <UploadBankStatement {...props} />
    },
  },
  {
    module: 'finances-info',
    path: 'finances-info/',
    headline: 'Financial information',
    component: function Component(props) {
      return <FinancesSetup {...props} />
    },
  },
  {
    module: 'legal',
    path: 'legal/',
    headline: 'Legal and regulatory',
    component: function Component(props) {
      return <Legal {...props} />
    },
  },
]

export default {
  module: 'individual',
  path: 'individual/',
  component: function Component(props) {
    return <Individual {...props} />
  },
  childRoutes: childRoutes,
  indexRoute: childRoutes[0],
}
