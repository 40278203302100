import React, { Fragment } from 'react'

import { useMediaQueries, useState } from 'hooks'

import { isDarkTheme } from 'helpers/palette'
import { urlTo } from 'helpers/router'

import { type Collection } from 'app/effector/discover-etfs/types'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type CollectionDescriptionProps = {
  collection?: Collection
  isFeatured: boolean
  location: {
    query?: Record<string, string>
  }
}

const CollectionDescription = ({
  collection,
  isFeatured,
  location,
}: CollectionDescriptionProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const [isShowAllActive, setShowAllActive] = useState(true)

  const description = collection?.description

  const descriptionLengthLimit = desktop ? 200 : 160

  const shouldHideLongDescription = description && descriptionLengthLimit < description.length

  const trimmedDescription =
    shouldHideLongDescription && isShowAllActive ? `${description?.slice(0, descriptionLengthLimit)}...` : description

  return (
    <Fragment>
      {isFeatured && (
        <Paper bottom={12}>
          <Typography size={32} weight="semibold" lineHeight="small">
            <Typo>{collection?.title}</Typo>
          </Typography>
        </Paper>
      )}
      <Typography size={14} data-test-id="collectionHeaderDescription">
        <Typo>{trimmedDescription}</Typo>{' '}
        {shouldHideLongDescription && isShowAllActive && (
          <Link
            mods={{ color: isDarkTheme() ? 'onColorWhiteToRed' : 'on-color-black' }}
            onClick={() => {
              setShowAllActive(false)
            }}
            data-test-id="collectionHeaderShowAllLink"
          >
            Show all
          </Link>
        )}
      </Typography>
      {isFeatured && (
        <Paper top={12} mix>
          <Typography size={14}>
            <Typo>
              Education content featured on this page including webinars and videos are co‑created as part of a paid
              partnership and should not be taken as investment advice or a personal recommendation. You can also{' '}
              <Link to={urlTo('universe', null, location.query)}>view our full range</Link> of ETFs.
            </Typo>
          </Typography>
        </Paper>
      )}
    </Fragment>
  )
}

export { CollectionDescription }
