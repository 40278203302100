let store

export function cacheStore(passedStore) {
  store = passedStore
  return store
}

export default async function waitForAuth() {
  if (!store) {
    throw new Error('Cannot wait for auth; store is not defined')
  }

  return await new Promise((resolve) => {
    const gotKey = () => {
      const state = store.getState()

      if (state.client.access_token && state.client.id) {
        resolve(state)
        return true
      } else {
        return false
      }
    }

    if (!gotKey()) {
      const unsubscribe = store.subscribe(() => {
        if (gotKey()) {
          unsubscribe()
        }
      })
    }
  })
}
