import * as rules from 'app/redux/models/common/rules.js'

import { PortfolioSimpleStatsItem } from '../../PortfolioSimpleStatsItem/PortfolioSimpleStatsItem.js'

export const GrowthMixin = (superclass) =>
  /**
   * @class
   * @typedef {Object} GrowthMixin
   */
  class extends superclass {
    constructor(...args) {
      super(...args)

      this.preset_type = 'GROWTH'

      /** @type {?number} */
      this.term = null
      /** @type {?number} */
      this.target = null

      this.profit = new PortfolioSimpleStatsItem()
    }

    getRules() {
      const parentRules = typeof super.getRules === 'function' ? super.getRules() : {}

      return {
        ...parentRules,
        term: [[rules.int]],
        target: [[rules.float]],

        profit: [[(item) => PortfolioSimpleStatsItem.createFromObject(item)]],
      }
    }
  }
