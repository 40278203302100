import React from 'react'
import PropTypes from 'prop-types'

import { useSelector, useRedirect } from 'hooks'

import { getClientAnswerValues } from 'app/redux/selectors'

const Redirect = (props) => {
  const { children, ...propsWithoutChildren } = props
  const { redirectToPrevStep, prevStep, params } = propsWithoutChildren
  const portfolioId = parseInt(params?.id, 10)

  const { client, goals, questionnaire, clientAnswerValues, portfolio } = useSelector((state) => {
    const clientAnswerValues = getClientAnswerValues(state)
    const portfolio = state.portfolios.list.get(portfolioId)

    return {
      client: state.client,
      goals: state.portfolios,
      questionnaire: state.questionnaire,
      clientAnswerValues,
      portfolio,
    }
  })

  useRedirect({
    to: redirectToPrevStep,
    rule:
      client.access_token &&
      !clientAnswerValues[prevStep.code] &&
      prevStep.isQuestion &&
      !portfolio?.is_default_preset_used,
    isLoading: client.didInvalidate || goals.didInvalidate || questionnaire.didInvalidate,
  })

  return React.cloneElement(children, { ...propsWithoutChildren })
}

Redirect.propTypes = {
  children: PropTypes.node,
}

export { Redirect }
