import React from 'react'

import type { Preset } from 'app/redux/models/portfolio/types'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'

import { PresetBanner } from '../PresetBanner'

type PresetRightColumnProps = {
  children: React.ReactNode
  preset: Preset
}

const PresetRightColumn = ({ children, preset }: PresetRightColumnProps): React.ReactElement => {
  return (
    <Card borderRadius={10} style={{ overflow: 'hidden' }}>
      <PresetBanner preset={preset} />
      <Paper top={24} bottom={24} right={24} left={24}>
        {children}
      </Paper>
    </Card>
  )
}

export { PresetRightColumn }
