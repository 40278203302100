import React from 'react'
import PropTypes from 'prop-types'

import compose from 'helpers/compose.js'
import { bulkValidate } from 'helpers/validation.js'
import rawMediaQueries from 'helpers/mediaQueries.js'
import { hardCodedCountries } from 'helpers/hardCodedCounties'

import withMediaQueries from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Form from 'components/_old/Form/Form.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { AddressByPeriod } from 'components/organisms/AddressByPeriod'

class ChangeAddressForm extends React.PureComponent {
  handlersListAddressFieldChange = []

  static propTypes = {
    visibleAddressesList: PropTypes.array.isRequired,
    notUkWarningText: PropTypes.node.isRequired,
    withPeriodsSelect: PropTypes.bool,
    allowEmptyMonthsAtAddress: PropTypes.bool,
    clearOnSwitchToSearchMode: PropTypes.bool,
    inputMods: PropTypes.object,
    onAddressFieldChange: PropTypes.func,
    renderButtons: PropTypes.func,
    mediaQueries: PropTypes.object,
  }

  createValidation = (address) => {
    const { withPeriodsSelect, allowEmptyMonthsAtAddress } = this.props
    const { street, postcode, country, city, months_at_address, changed } = address

    const validation = {
      street: { rules: [street], errors: [' Address can’t be empty'] },
      postcode: { rules: [postcode], errors: ['Postcode can’t be empty'] },
      country: { rules: [country], errors: ['Country can’t be empty'] },
      city: { rules: [city], errors: ['City can’t be empty'] },
      isUK: { rules: [address?.isUK()], errors: [''] },
    }

    if (withPeriodsSelect) {
      validation.months_at_address = {
        rules: [allowEmptyMonthsAtAddress || months_at_address > 0, months_at_address < 1200],
        errors: ['Time at this address can’t be empty', 'Max value is 99'],
      }
    }

    if (typeof changed === 'boolean') {
      validation.changed = {
        rules: [changed],
        errors: [''],
      }
    }

    return validation
  }

  render() {
    const {
      visibleAddressesList,
      inputMods,
      withPeriodsSelect,
      allowEmptyMonthsAtAddress,
      clearOnSwitchToSearchMode,
      renderButtons,
      mediaQueries,
      notUkWarningText,
      onAddressFieldChange: handleAddressFieldChange,
    } = this.props

    // quick fix for https://app.asana.com/0/1202304843784991/1202635259316452/f
    const countries = hardCodedCountries // dicts.countries

    visibleAddressesList.list.forEach((_address, index) => {
      if (!this.handlersListAddressFieldChange[index]) {
        this.handlersListAddressFieldChange[index] = (field, value, isInitialCall) =>
          handleAddressFieldChange(index, field, value, isInitialCall)
      }
    })

    const { validationsList, isValid } = (() => {
      let isValid = true

      const validationsList = []
      visibleAddressesList.list.forEach((address) => {
        const validation = this.createValidation(address)
        validationsList.push(validation)
        if (!bulkValidate(validation)) {
          isValid = false
        }
      })

      return {
        validationsList,
        isValid,
      }
    })()

    const buttons = renderButtons(isValid)

    return (
      <SubmitOnEnter>
        <Form>
          <div>
            {visibleAddressesList.list.map((address, index) => {
              const handleChange = this.handlersListAddressFieldChange[index]

              return (
                <AddressByPeriod
                  key={index}
                  address={address}
                  validation={validationsList[index]}
                  inputMods={inputMods}
                  countries={countries}
                  infoVisible={index === 1}
                  withPeriodsSelect={withPeriodsSelect}
                  allowEmptyMonthsAtAddress={allowEmptyMonthsAtAddress}
                  clearOnSwitchToSearchMode={clearOnSwitchToSearchMode}
                  mediaQueries={mediaQueries}
                  notUkWarningText={notUkWarningText}
                  onAddressFieldChange={handleChange}
                />
              )
            })}
          </div>
          {buttons}
        </Form>
      </SubmitOnEnter>
    )
  }
}

export default compose(withMediaQueries(rawMediaQueries))(ChangeAddressForm)
