import { useMemo } from 'hooks'

import type { AbstractPointOfInterest } from 'components/organisms/charts/parts'

import { calculateChartValues, type CalculateChartValuesInterface, type XYCoordinates } from '../utils'

import type { IncomeLineDataItem } from '../IncomeLine'

type UseValuesProps = {
  data: IncomeLineDataItem[]
  pointsOfInterest: AbstractPointOfInterest[]
  min: number
  max: number
  width: number
  chartPadding: number
  height: number
}

type UseValuesInterface = {
  chartValues: CalculateChartValuesInterface
  positions: XYCoordinates[]
}

function useValues({
  data,
  pointsOfInterest,
  min,
  max,
  width,
  chartPadding,
  height,
}: UseValuesProps): UseValuesInterface {
  const chartValues = useMemo(
    () =>
      calculateChartValues({
        data,
        pointsOfInterest,
        min,
        max,
        width: width - chartPadding * 2,
        height,
      }),
    [data, pointsOfInterest, min, max, width, chartPadding, height],
  )
  const positions = chartValues.map(({ position }) => position)

  return {
    chartValues,
    positions,
  }
}

export { useValues }
