/**
 * @param {string} pattern
 * @param {string} substitution
 * @param {function(): boolean} shouldRewrite
 * @return {(function())|*|(function({request?: *}): void)}
 */
const rewriteBaseUrl = (pattern, substitution, shouldRewrite) => {
  if (!pattern || !substitution) {
    return () => {}
  }

  return ({ request }) => {
    const rewrite = typeof shouldRewrite === 'function' ? shouldRewrite() : true

    if (rewrite && request) {
      request.baseURL = request.baseURL.replace(new RegExp(pattern, 'g'), substitution)
    }
  }
}

export { rewriteBaseUrl }
