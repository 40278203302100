import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { formatPercent } from 'helpers/money'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { SecurityItem } from 'components/molecules/SecurityItem'

import { SecurityInfo } from 'components/organisms/SecurityInfo'

import type { AllocationItem } from '../../../ManagedPortfolio/types'

type GroupOfAssetsProps = {
  isPortfolio: boolean
  currentPeriod?: string
  groupOfAssets: AllocationItem
  onSecurityClick: (id: string) => void
}

const GroupOfAssets = ({
  isPortfolio,
  currentPeriod,
  groupOfAssets,
  onSecurityClick,
}: GroupOfAssetsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { type, weight, items = [] } = groupOfAssets

  const title = (
    <Typography size={desktop ? 24 : 18} lineHeight="small" weight="semibold">
      <Typo>
        {type} {formatPercent(weight / 100, 1)}
      </Typo>
    </Typography>
  )

  if (type === 'Cash') {
    return title
  }

  return (
    <Fragment>
      {title}
      {items.map((item) => (
        <Paper key={item.id} top={desktop ? 24 : 12}>
          {isPortfolio ? (
            <SecurityInfo
              {...item}
              logo={item.logo_uri}
              current_weight={item.current_weight_from_capital_balance}
              target_weight={null}
              onClick={() => {
                onSecurityClick(item.id)
              }}
              stats={item?.stats?.[currentPeriod]}
              data-test-id="allocationSecurity"
            />
          ) : (
            <SecurityItem
              {...item}
              onClick={(id) => {
                onSecurityClick(id)
              }}
            />
          )}
        </Paper>
      ))}
    </Fragment>
  )
}

export { GroupOfAssets }
