import React, { Fragment } from 'react'

import Link from 'components/_old/Link/Link.jsx'
import List, { ListItem } from 'components/_old/List/List.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

type TermsChechboxProps = {
  isChecked: boolean
  onChange: () => void
}

const TermsChechbox = ({ isChecked, onChange }: TermsChechboxProps): React.ReactElement => {
  const content = (
    <Fragment>
      <Typography size={14}>
        <Typo>I confirm that:</Typo>
      </Typography>
      <List mods={{ type: 'bullet', 'no-margin': 'top' }}>
        <ListItem>
          <Paper top={8}>
            <Typography size={12}>
              <Typo>
                I am instructing InvestEngine to turn on{' '}
                <Link to="/terms#autoinvest" blank hard>
                  AutoInvest
                </Link>{' '}
                to create a recurring order in the selected ETFs (or to my cash portfolio) for the amount and frequency
                stated above and for the duration of 10 years or until such time that I cancel the order in accordance
                with our{' '}
                <Link to="/terms/" hard blank>
                  Terms and Conditions
                </Link>{' '}
                and{' '}
                <Link to="/terms/#order-execution-policy" hard blank>
                  Order Execution Policy
                </Link>
                ;
              </Typo>
            </Typography>
          </Paper>
        </ListItem>
        <ListItem>
          <Paper top={8}>
            <Typography size={12}>
              <Typo>
                I am permitting TrueLayer to initiate payments from my Nominated bank account to InvestEngine Client
                Money General (38385430 60-40-05);
              </Typo>
            </Typography>
          </Paper>
        </ListItem>
        <ListItem>
          <Paper top={8}>
            <Typography size={12}>
              <Typo>
                I agree to Truelayer{' '}
                <Link to="https://truelayer.com/legal/enduser_tos/" hard blank>
                  End User Terms of Service
                </Link>{' '}
                and{' '}
                <Link to="https://truelayer.com/legal/privacy/" hard blank>
                  Privacy Policy
                </Link>
                ;
              </Typo>
            </Typography>
          </Paper>
        </ListItem>
        <ListItem>
          <Paper top={8}>
            <Typography size={12}>
              <Typo>
                I agree to InvestEngine{' '}
                <Link to="/privacy/" hard blank>
                  Privacy Policy
                </Link>
              </Typo>
            </Typography>
          </Paper>
        </ListItem>
        <ListItem>
          <Paper top={8}>
            <Typography size={12}>
              <Typo>
                Instant bank transfers are subject to payment limits and secure open banking authorisation. These can
                vary between banks.
              </Typo>
            </Typography>
          </Paper>
        </ListItem>
      </List>
    </Fragment>
  )

  return (
    <CheckboxWithLabel
      size="smaller"
      label={content}
      onChange={onChange}
      checked={isChecked}
      data-test-id="recurringPaymentConfirmCheckbox"
    />
  )
}

export { TermsChechbox }
