import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useCallback, useMemo, useState } from 'hooks'

import { DesktopLayout } from 'components/atoms/Layouts'
import { TunnelHeader } from 'app/pages/Securities/components'
import Inner from 'components/_old/Inner/Inner.jsx'
import Width from 'components/_old/Width/Width'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { VirtualList } from 'components/molecules/VirtualList'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'
import { SecurityTitle } from 'components/molecules/SecurityTitle'
import { Paper } from 'components/atoms/Paper'
import { Card } from 'components/atoms/Card'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'
import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { SearchField } from 'components/molecules/SearchField'
import { FilterGroup } from 'components/organisms/Filter/FilterGroup'
import { Filter } from 'components/organisms/Filter'

const Desktop = ({
  security,
  holdings,
  sectors,
  regions,
  filters,
  handleClose,
  itemsAreClickable = true,
  goToHoldingPage,
  type: activeTab,
  goToSecurityAnalytics,
  searchValue,
  handleSearch,
}) => {
  const [layoutNode, setLayoutNode] = useState(null)
  const layoutRef = useCallback((ref) => setLayoutNode(ref), [])
  const analytics = { holdings, sectors, regions }

  const tabs = useMemo(
    () => [
      { title: 'Holdings', id: 'holdings', isActive: activeTab === 'holdings', withSearch: handleSearch },
      { title: 'Regions', id: 'regions', isActive: activeTab === 'regions' },
      { title: 'Sectors', id: 'sectors', isActive: activeTab === 'sectors' },
    ],
    [activeTab, handleSearch],
  )

  const itemTitles = {
    holdings: 'Equities',
    sectors: 'Sectors',
    regions: 'Regions',
  }

  const activeTabObject = useMemo(() => tabs.find((tab) => tab.isActive) ?? {}, [tabs])

  const searchIsActive = useMemo(
    () => activeTabObject?.withSearch && searchValue.length >= 2,
    [activeTabObject, searchValue],
  )

  const isListFiltered = !!filters?.regions.value.length || !!filters?.sectors.value.length || !!searchValue

  const header = useMemo(
    () => (
      <Inner twoColumns>
        <TunnelHeader onBack={handleClose} />
      </Inner>
    ),
    [handleClose],
  )

  const renderEquity = useCallback(
    (listElement, items) => {
      const item = items[listElement.index]

      return itemsAreClickable ? (
        <Paper key={item.name} top={24}>
          <SelectableCard onClick={() => goToHoldingPage(item.id, activeTab.slice(0, -1))}>
            <Paper top={16} bottom={16} left={16} right={16}>
              <SecurityConstituent
                logo={item.logo}
                name={item.name}
                actual={item.actual}
                color={item.color}
                showLogo={activeTab === 'holdings'}
              />
            </Paper>
          </SelectableCard>
        </Paper>
      ) : (
        <Paper key={item.name} top={48}>
          <SecurityConstituent
            logo={item.logo}
            name={item.name}
            actual={item.actual}
            target={null}
            color={item.color}
            showLogo={activeTab === 'holdings'}
          />
        </Paper>
      )
    },
    [activeTab],
  )

  const renderNoEquities = useCallback(() => null, [])

  const content = useMemo(
    () => (
      <Inner twoColumns>
        <SecurityTitle
          logo={security?.logo_uri}
          ticker={security?.ticker}
          title={security?.title}
          size={24}
          lineHeight="small"
          iconPosition="left"
        />
        <Paper top={48}>
          <GreyTabs tabs={tabs} onChange={(tab) => goToSecurityAnalytics(tab.id)} />
        </Paper>
        <Paper top={8}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1}>
              {activeTabObject?.withSearch && (
                <Fragment>
                  <Paper top={24} bottom={8}>
                    <Width size={36}>
                      <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by holding" />
                    </Width>
                  </Paper>
                  <Paper top={16}>
                    <FilterGroup>
                      <Filter
                        name="Region"
                        type="checkbox"
                        values={filters.regions.list}
                        selected={filters.regions.value}
                        onChange={filters.regions.set}
                        data-test-id="holdingsFilterRegion"
                      />
                      <Filter
                        name="Sector"
                        type="checkbox"
                        values={filters.sectors.list}
                        selected={filters.sectors.value}
                        onChange={filters.sectors.set}
                        dropdownMaxHeight={520}
                        data-test-id="holdingsFilterSector"
                      />
                    </FilterGroup>
                  </Paper>
                </Fragment>
              )}
              {isListFiltered && !analytics[activeTab]?.length && (
                <Paper top={24}>
                  <Typography size={14} lineHeight="medium" color="minor" data-test-id="noSearchResults">
                    <Typo>
                      No holdings found.
                      <br />
                      Please try a different search.
                    </Typo>
                  </Typography>
                </Paper>
              )}
              <VirtualList
                scrollableElement={layoutNode}
                cacheKey="security-analytics-list-desktop"
                items={analytics[activeTab]}
                itemMinHeight={itemsAreClickable ? 92 : 76}
                itemMaxHeight={itemsAreClickable ? 120 : 96}
                renderItem={(element) => renderEquity(element, analytics[activeTab])}
                renderNoItems={renderNoEquities}
              />
            </Column>
            <Column size={0}>
              <Paper top={24} left={80}>
                <Width size={19}>
                  {!searchIsActive && (
                    <Card data-test-id="holdingsQuantity">
                      <Paper top={40} bottom={40} left={24} right={24}>
                        <Typography size={48} lineHeight="small" weight="semibold" align="center">
                          <Typo>{analytics[activeTab].length}</Typo>
                        </Typography>
                        <Paper top={4}>
                          <Typography size={16} lineHeight="small" align="center">
                            <Typo>{itemTitles[activeTab]}</Typo>
                          </Typography>
                        </Paper>
                      </Paper>
                    </Card>
                  )}
                </Width>
              </Paper>
            </Column>
          </ColumnarLayout>
        </Paper>
      </Inner>
    ),
    [security, layoutNode, renderEquity, renderNoEquities, tabs, analytics, activeTab, searchIsActive],
  )

  return <DesktopLayout ref={layoutRef} header={header} content={content} footer={<div />} />
}

Desktop.propTypes = {
  open: PropTypes.bool,
  security: PropTypes.shape({
    logo_uri: PropTypes.string,
    ticker: PropTypes.string,
    title: PropTypes.string,
  }),
  holdings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  filters: PropTypes.shape({
    regions: {
      value: PropTypes.arrayOf(PropTypes.number),
      list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.number })),
      set: PropTypes.func,
    },
    sectors: {
      value: PropTypes.arrayOf(PropTypes.number),
      list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.number })),
      set: PropTypes.func,
    },
  }),
  handleClose: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
  itemsAreClickable: PropTypes.bool,
  goToHoldingPage: PropTypes.func,
  type: PropTypes.string,
  goToSecurityAnalytics: PropTypes.func,
}

export { Desktop }
