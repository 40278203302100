import React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import isEmpty from 'lodash/isEmpty'

import { useMediaQueries } from 'hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { AllowNotificationsModal } from 'app/pages/Dashboard/AllowNotificationsModal/AllowNotificationsModal.jsx'
import { AddFundsModal } from 'app/pages/Dashboard/Goals/AddFunds'
import CantSetUpMonthlyPayment from 'app/pages/Dashboard/Goals/CantSetUpMonthlyPayment/CantSetUpMonthlyPayment.jsx'
import ChangeGoalTitle from 'app/pages/Dashboard/Goals/ChangeGoalTitle/ChangeGoalTitle.jsx'
import { SippTransferModal } from 'app/pages/Dashboard/Goals/components/SippTransferModal'
import { SippWithdrawModal } from 'app/pages/Dashboard/Goals/components/SippWithdrawModal'
import { PortfolioOpenedModal } from 'app/pages/Dashboard/Goals/PortfolioOpenedModal'
import { WithdrawFunds } from 'app/pages/Dashboard/Goals/WithdrawFunds/WithdrawFunds'
import { FinishRegistrationModal } from 'app/pages/Dashboard/SetupAccount/FinishRegistration/FinishRegistrationModal.jsx'
import { ReviewModal } from 'app/pages/Dashboard/YearlyReview/ReviewModal.jsx'

import { ChangeGoalTunnelProvider } from '../ChangeGoalTunnel/ChangeGoalTunnel.jsx'
import { SavingsPlanSetup } from '../SavingsPlan/SavingsPlanSetup'

import { EmptyPortfolio } from './components/EmptyPortfolio'
import { Desktop } from './Desktop'
import { useManagedPortfolio } from './hooks/useManagedPortfolio'
import { Mobile } from './Mobile'

import './ManagedPortfolio.css'

type ManagedPortfolioProps = {
  params: {
    id: string
  }
  location: {
    query: {
      view: string
    }
  }
}

const ManagedPortfolio = ({ params: { id }, location }: ManagedPortfolioProps): React.ReactElement => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const {
    isPortfolioLoading,
    goal,
    goals,
    goalTitle,
    showFinishSavingsPlanBanner,
    showLumpSumBanner,
    quickStart,
    regulatoryTypeText,
    preset,
  } = useManagedPortfolio({
    id,
    location,
  })

  const props = {
    location,
    isPortfolioLoading,
    portfolio: goal,
    goalTitle,
    showFinishSavingsPlanBanner,
    showLumpSumBanner,
    quickStart,
    regulatoryTypeText,
    preset,
  }

  const isEmptyPortfolio = isEmpty(goals) || !goal?.id

  return (
    <ChangeGoalTunnelProvider>
      {!isEmptyPortfolio && (
        <div className={classNames('Goal', getMediaQuieryClasses('Goal', mediaQueries))}>
          {desktop ? <Desktop {...props} /> : <Mobile {...props} />}

          <ReviewModal portfolioId={goal.id} />
          <ChangeGoalTitle goalId={goal.id} />
          <CantSetUpMonthlyPayment goalId={goal.id} />
          <WithdrawFunds portfolioId={goal.id} />
          <PortfolioOpenedModal portfolioId={goal.id} type="managed" />
          <AddFundsModal goal={goal} />
          <SavingsPlanSetup portfolio={goal} />
          <FinishRegistrationModal />
          <AllowNotificationsModal module="dashboard.portfolio" />
          <SippWithdrawModal />
          <SippTransferModal />
        </div>
      )}

      {isEmptyPortfolio && <EmptyPortfolio />}

      <GatewayDest name="change-goal-title" />
      <GatewayDest name="cant-set-up-monthly-payment" />
      <GatewayDest name="withdraw-funds" />
      <GatewayDest name="attention" />
      <GatewayDest name="onboarding-modals" />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
      <GatewayDest name="alarms" />
    </ChangeGoalTunnelProvider>
  )
}

export { ManagedPortfolio }
