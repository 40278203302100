import React from 'react'

import { goTo, urlTo } from 'helpers/router'

import { useSelector, useActions, useEffect, useLoading } from 'hooks'

import { GP_DEFAULT } from 'constants/globalPreloaderStatuses'

import GlobalPreloader from 'components/_old/GlobalPreloader/GlobalPreloader.jsx'

import { selectGoalById } from 'app/redux/selectors'
import {
  selectGoal as selectGoalActionCreator,
  fetchPortfolios as fetchPortfoliosActionCreator,
} from 'app/redux/actions/portfolios'
import { fetchData as fetchQuestionnaireDataActionCreator } from 'app/redux/actions/questionnaire'
import { showFailToast } from 'app/redux/actions/ui'

const YearlyReview = ({ children, params: { goalId } }) => {
  const { isLoading: isQuestionnaireLoading, wait: waitForQuestionnaire } = useLoading(true)

  const portfolios = useSelector((state) => state.portfolios)
  const goal = selectGoalById({ portfolios }, parseInt(goalId, 10))
  const client = useSelector((state) => state.client)

  const [fetchQuestionnaireDataAction, fetchPortfolios, selectGoalAction] = useActions([
    fetchQuestionnaireDataActionCreator,
    fetchPortfoliosActionCreator,
    selectGoalActionCreator,
  ])

  useEffect(() => {
    if (portfolios.items.length === 0 && !goal) {
      fetchPortfolios()
    }
  }, [])

  useEffect(() => {
    if (goal?.id) {
      selectGoalAction(goal.id)
    }
  }, [goal])

  useEffect(() => {
    if (goal && client.type) {
      waitForQuestionnaire(
        fetchQuestionnaireDataAction({
          clientType: client.type,
          goalRegulatoryType: goal.regulatory_type,
          goalPresetType: goal.preset_type,
          goalId: goal.id,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal, client.type])

  useEffect(() => {
    if (portfolios.items.length > 0) {
      const goalToFind = portfolios.items.find((goal) => goal.id === parseInt(goalId, 10))

      if (!goalToFind) {
        goTo(urlTo('dashboard'), { replace: true })
        showFailToast()
      }
    }
  }, [goalId, portfolios])

  if (portfolios.items.length < 1 && !goal) {
    return <GlobalPreloader status={GP_DEFAULT} loading />
  }

  return React.cloneElement(children, { goal, client, isQuestionnaireLoading })
}

export { YearlyReview }
