import React, { Fragment } from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMediaQueries } from 'hooks'

import { isDarkTheme } from 'helpers/palette'
import { palette } from 'helpers/palette/'

import { type Collection } from 'app/effector/discover-etfs/types'

import { Typo } from 'components/_old/Typo/Typo'

import { CardNew } from 'components/atoms/CardNew'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Typography } from 'components/atoms/Typography'

import './CollectionHeader.css'

enum Style {
  Background = 'background',
  Primary = 'primary',
  Accent = 'accent',
  Outstanding = 'outstanding',
}

const backgroundColors = {
  [Style.Background]: palette['background-default'],
  [Style.Primary]: palette['primary-default'],
  [Style.Accent]: palette['accent-default'],
  [Style.Outstanding]: palette['outstanding-default'],
}

const hoverColors = {
  [Style.Background]: palette['background-default'],
  [Style.Primary]: palette['primary-action'],
  [Style.Accent]: palette['accent-action'],
}

const textColors = {
  [Style.Background]: 'default',
  [Style.Primary]: 'on_color_white',
  [Style.Accent]: 'on_color_white',
  [Style.Outstanding]: 'on_color_black',
}

interface CollectionHeaderProps {
  collection?: Collection
  isFeatured: boolean
}

const CollectionHeader = ({ collection, isFeatured }: CollectionHeaderProps): React.ReactElement => {
  const mediaQueries = useMediaQueries()
  const { desktop } = useMediaQueries()

  const classes = classNames('CollectionHeader', getMediaQuieryClasses('CollectionHeader', mediaQueries))

  const backgroundColor = collection?.background_color
  const backgroundImage = isFeatured ? collection?.background_logo : collection?.background_full
  const style = collection?.style ?? ''
  const logo = collection?.logo
  const logoDark = collection?.logo_dark_theme
  const textColor = textColors[style]

  const heightDesktop = isFeatured ? '168px' : '122px'
  const heightMobile = isFeatured ? '152px' : 'auto'

  const cardProps = {
    borderRadius: !desktop ? 0 : 16,
    backgroundImage,
    backgroundColor: backgroundColor ?? backgroundColors[style],
    hoverColor: hoverColors[style],
    clickable: false,
  }

  const featuredCardStyles = {
    backgroundSize: 'contain',
  }

  const content = (
    <CardNew
      {...cardProps}
      shadow={0}
      style={
        style === 'background' && desktop
          ? { border: '1px solid var(--background-border)', ...(isFeatured ? featuredCardStyles : {}) }
          : { border: 'none', ...(isFeatured ? featuredCardStyles : {}) }
      }
      data-test-id="collectionHeader"
    >
      <Paper
        left={desktop ? 40 : 16}
        right={desktop ? 40 : 16}
        className={classes}
        top={desktop ? 0 : 24}
        bottom={desktop || style === 'background' ? 0 : 24}
        style={{ minHeight: desktop ? heightDesktop : heightMobile }}
      >
        {!isFeatured && (
          <Fragment>
            <Paper right={32}>
              <Typography
                size={desktop ? 36 : 32}
                lineHeight="small"
                weight="semibold"
                color={textColor}
                className="CollectionHeader_title"
                data-test-id="collectionHeaderTitle"
              >
                <Typo>{collection?.title}</Typo>
              </Typography>
            </Paper>
            <img
              src={isDarkTheme() && logoDark ? logoDark : logo}
              style={{
                width: desktop ? '64px' : '48px',
                height: desktop ? '64px' : '48px',
              }}
              data-test-id="collectionHeaderIcon"
            />
          </Fragment>
        )}
      </Paper>
    </CardNew>
  )

  return desktop ? content : <SideSpacesCompensator>{content}</SideSpacesCompensator>
}

export { CollectionHeader, Style }
