import React from 'react'
import Stories from 'react-insta-stories'

import { trackEvent } from 'helpers/analytics'
import { palette } from 'helpers/palette'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

import { Gateway } from 'components/atoms/Gateway'
import { VisuallyHidden } from 'components/atoms/VisuallyHidden'

import { Modal } from 'components/molecules/Modal'

import { StoryContent } from './StoryContent'

import './Story.css'

type StoryProps = {
  currentStep: number
  isOpen: boolean
  steps: Array<{
    header: string
    text: string
    image: string
    backgroundImage?: string
    bottomImage?: string
    actionButtonUrl?: string
    actionButtonText?: string
  }>
  id: number
  customActions: Record<string, ({ id, index }) => void>
  showNextButton: boolean
  showPreviousButton: boolean
  handleClose: () => void
  handleNext: () => void
  handlePrevious: () => void
}

const Story = ({
  currentStep,
  isOpen,
  id,
  steps,
  customActions,
  showNextButton,
  showPreviousButton,
  handleClose,
  handleNext,
  handlePrevious,
}: StoryProps): React.ReactElement => {
  const step = steps?.[currentStep]

  // need to separate this logic from component somehow...
  const handleActionButtonClick = (actionButtonUrl: string): void => {
    if (customActions?.[actionButtonUrl]) {
      handleClose()
      customActions[actionButtonUrl]({ id, index: currentStep })
      return
    }

    window.open(actionButtonUrl, '_blank')
  }

  const stories = steps.map((step, index) => ({
    content: () => <StoryContent key={index} header={step.header} text={step.text} />,
  }))

  const content = (
    <div className="Story-ContentWrapper">
      <Stories
        key={JSON.stringify(stories)}
        stories={stories}
        defaultInterval={30000}
        width="100%"
        height="100%"
        currentIndex={currentStep}
        onStoryEnd={handleNext}
        onNext={handleNext}
        onPrevious={handlePrevious}
        storyContainerStyles={{ backgroundColor: 'transparent', position: 'unset' }}
        progressContainerStyles={{
          position: 'relative',
          overflow: 'hidden',
          height: '4px',
          width: 'calc(100% - 48px)',
          marginTop: '20px',
          paddingTop: 0,
          padding: 0,
        }}
        progressWrapperStyles={{
          overflow: 'hidden',
          borderRadius: '4px',
          backgroundColor: 'rgba(255, 255, 255, 0.45)',
          transition: 'none',
        }}
        progressStyles={{
          height: '4px',
          maxHeight: '4px',
          padding: 0,
          backgroundColor: 'white',
          perspective: 'none',
        }}
        onStoryStart={() => {
          trackEvent({
            action: 'story_opened',
            story_id: id,
            step_number: currentStep,
          })
        }}
      />
    </div>
  )

  const nextButton = (
    <button
      className="Story-Button Story-Button_Next click-target-helper"
      onClick={handleNext}
      data-test-id="story-button-next"
    >
      <VisuallyHidden tag="span">Next</VisuallyHidden>
      <Icon type="arrow-in-a-circle-right-32" size={32} className="Story-Arrow" />
    </button>
  )

  const previousButton = (
    <button
      className="Story-Button Story-Button_Previous click-target-helper"
      onClick={handlePrevious}
      data-test-id="story-button-previous"
    >
      <VisuallyHidden tag="span">Previous</VisuallyHidden>
      <Icon type="arrow-in-a-circle-left-32" size={32} className="Story-Arrow" />
    </button>
  )

  const backgroundImageSrc = step?.backgroundImage
  const imageSrc = step?.image || step?.bottomImage
  const actionButtonText = step?.actionButtonText ?? 'Action Button'
  const actionButtonUrl = step?.actionButtonUrl

  return (
    <Gateway into="modals">
      <Modal
        open={isOpen}
        close={null}
        onClose={handleClose}
        className="Story-Modal"
        data-test-id="story-modal"
        previousButton={showPreviousButton && previousButton}
        nextButton={showNextButton && nextButton}
      >
        <div className="Story-Wrapper">
          <button
            className="Story-ModalCloser click-target-helper"
            onClick={handleClose}
            data-test-id="story-close-button"
          >
            <VisuallyHidden tag="span">Close</VisuallyHidden>
            <Icon type="cross-16" size={24} color={palette['content-on-color-white']} />
          </button>
          {content}
          {backgroundImageSrc && (
            <div className="Story-BackgroundImageWrapper">
              <img src={backgroundImageSrc} className="Story-BackgroundImage" alt="" data-test-id="story-image" />
            </div>
          )}
          {imageSrc && !backgroundImageSrc && (
            <div className="Story-ImageWrapper">
              <img className="Story-Image" src={imageSrc} data-test-id="story-image" />
            </div>
          )}
        </div>
        {actionButtonUrl && (
          <Button
            mods={{ size: 'big block', color: 'green' }}
            onClick={() => {
              handleActionButtonClick(actionButtonUrl)
            }}
            data-test-id="story-button-action"
            className="Story-Button_Action"
          >
            {actionButtonText}
          </Button>
        )}
      </Modal>
    </Gateway>
  )
}

export { Story }
