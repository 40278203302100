import React from 'react'

import { StandingOrder } from './StandingOrder.jsx'

export const routes = {
  module: 'new-standing-order',
  path: 'new-standing-order/',
  component: function Component(props) {
    return <StandingOrder {...props} />
  },
}
