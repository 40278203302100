import React from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './LabelNative.css'

type LabelNativeProps = {
  children: React.ReactNode | React.ReactNodeArray
  className?: string
  labelText?: string
  'data-test-id'?: string
}

function LabelNative({
  children,
  className,
  labelText,
  'data-test-id': dataTestId,
}: LabelNativeProps): React.ReactElement {
  const { desktop } = useMediaQueries()
  const classes = classNames(className, 'LabelNative')

  return (
    <div className={classes} data-test-id={dataTestId}>
      {labelText && (
        <Paper left={16} right={16} bottom={desktop ? 16 : 8}>
          <Typography size={14} color="minor" uppercase={!desktop}>
            <Typo>{labelText}</Typo>
          </Typography>
        </Paper>
      )}
      <Paper top={labelText ? (desktop ? 16 : 8) : 0} left={16} right={16} bottom={16}>
        {children}
      </Paper>
    </div>
  )
}

export { LabelNative, type LabelNativeProps }
