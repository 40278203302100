import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import InputMask from 'react-input-mask'

import { formatDateFromBackend, formatDateToBackend } from 'helpers/date.js'

export default function DateInput(props) {
  const { value, onChange, subtype, ...rest } = props

  const isMasked = value?.includes('*')

  const [dateString, setDateString] = useState(isMasked ? value : formatDateFromBackend(value))

  const handleChange = (event) => {
    const dateValue = event.target.value
    setDateString(dateValue)

    if (!onChange) {
      return
    }

    const date = isEmpty(dateValue) ? '' : formatDateToBackend(dateValue)

    onChange(event, date)
  }

  useEffect(() => {
    if (!isMasked && !value) {
      setDateString(value)
    }
  }, [value, isMasked])

  return (
    <InputMask
      {...rest}
      type={subtype}
      value={dateString}
      placeholder="DD/MM/YYYY"
      mask={dateString && !isMasked ? '99/99/9999' : null}
      onChange={handleChange}
    />
  )
}

DateInput.propTypes = {
  value: PropTypes.string.isRequired,
  subtype: PropTypes.string,
  onChange: PropTypes.func,
}
