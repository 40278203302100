import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette'

import { PromocodeStatuses } from 'app/effector/promocodes'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Relative } from 'components/atoms/Relative'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import { Preloader } from 'components/molecules/Preloader'

import { usePromocodeModal } from '../hooks/usePromocodeModal'

type PromocodeModalProps = {
  promocodeStatus: PromocodeStatuses
  isLoading: boolean
  location: { pathname: string }
  routes: Array<Record<string, unknown> & { module: string }>
}

const PromocodeModal = withRouter((props: PromocodeModalProps): JSX.Element => {
  const { promocodeStatus, isLoading } = props
  const { desktop } = useMediaQueries()
  const {
    inputRef,
    isOpen,
    promocodeValue,
    appliedText,
    error,
    handleClose,
    handleInput,
    handleFocus,
    handleBlur,
    handleSubmit,
  } = usePromocodeModal(props)

  const headerNode = (
    <NavigationBar rightPartText="Close" onRightPartClick={handleClose}>
      {desktop ? null : 'Enter your promo code'}
    </NavigationBar>
  )

  const contentNode = useMemo(() => {
    if (promocodeStatus === PromocodeStatuses.APPLIED) {
      const content = (
        <Fragment>
          <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
            <Icon type="checkmark_in_a_circle" color={palette['status-success']} size={64} inline />
            <Paper top={16}>
              <Typo>Congratulations!</Typo>
            </Paper>
          </Typography>
          <Paper top={32}>
            <Width size={24} center>
              <Typography size={14} align="center" textWrap="balance">
                <Typo>
                  {appliedText}{' '}
                  <Link to="https://investengine.com/terms-incentives/#variable-partner-welcome-bonus" blank hard>
                    Ts&Cs apply.
                  </Link>
                </Typo>
              </Typography>
            </Width>
          </Paper>
        </Fragment>
      )

      if (!desktop) {
        return (
          <AllCenter>
            <Paper top={24} bottom={24}>
              {content}
            </Paper>
          </AllCenter>
        )
      }

      return (
        <Paper top={24} right={24} bottom={24} left={24}>
          {content}
        </Paper>
      )
    }

    const content = (
      <Fragment>
        <Width size={21.5} center>
          <Label valid={!error} errorMessage={error}>
            <Input
              ref={inputRef}
              name="value"
              type="text"
              mods={{ size: 'big', align: 'center' }}
              onChange={handleInput}
              onFocus={handleFocus}
              onBlur={handleBlur}
              withFloat
            >
              {promocodeValue}
            </Input>
          </Label>
        </Width>
        <Paper top={4}>
          <Typography size={12} color="error" align="center">
            <Typo>{error ?? ' '}</Typo>
          </Typography>
        </Paper>
      </Fragment>
    )

    if (!desktop) {
      return <AllCenter>{content}</AllCenter>
    }

    return (
      <Fragment>
        <Paper bottom={96}>
          <Typography size={32} weight="semibold" lineHeight="small" align="center">
            <Typo>Please enter your promo code</Typo>
          </Typography>
        </Paper>
        {content}
      </Fragment>
    )
  }, [appliedText, desktop, error, handleBlur, handleFocus, handleInput, inputRef, promocodeStatus, promocodeValue])

  const buttonNode = useMemo(() => {
    if (promocodeStatus === PromocodeStatuses.APPLIED) {
      return (
        !desktop && (
          <Button onClick={handleClose} mods={{ size: 'big block' }}>
            OK
          </Button>
        )
      )
    }

    return (
      <Button onClick={handleSubmit} mods={{ size: 'big block' }}>
        Activate code
      </Button>
    )
  }, [desktop, promocodeStatus, handleClose, handleSubmit])

  return (
    <Modal open={isOpen} onClose={handleClose} close={null}>
      <Relative>
        {desktop ? (
          <Width size={36} style={{ minHeight: 516, display: 'flex', flexDirection: 'column' }}>
            <Paper top={8} right={24} left={24}>
              {headerNode}
            </Paper>
            <AllCenter>
              <Paper top={96} bottom={80}>
                {contentNode}
              </Paper>
            </AllCenter>
            {buttonNode && (
              <Paper bottom={80} left={96} right={96}>
                <Width size={21.5} center>
                  {buttonNode}
                </Width>
              </Paper>
            )}
          </Width>
        ) : (
          <MobileLayout
            header={headerNode}
            content={contentNode}
            footer={buttonNode && <MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
            contentFlex
          />
        )}
        <Preloader loading={isLoading} delay={400} size="big" zIndex absolute />
      </Relative>
    </Modal>
  )
})
PromocodeModal.displayName = 'PromocodeModal'

export { PromocodeModal, type PromocodeModalProps }
