import { useMemo, useCallback, useSelector, useRedirect } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { PortfolioList } from 'app/redux/models/portfolio/'
import type { Portfolio } from 'app/redux/models/portfolio/types'

import { types as clientTypes } from 'constants/client'
import { manageTypes, regulatoryTypes } from 'constants/portfolio'

type UseMoveCashInterface = {
  isBusiness: boolean
  groupedPortfolios: Record<string, PortfolioList>
  action: string
  handlePortfolioSelect: (id: number) => void
  handleBack: () => void
}

const useMoveCash = (portfolio: Portfolio, action: string, backLink?: string): UseMoveCashInterface => {
  const currentLocation = useMemo(() => window.location.pathname + window.location.search, [])
  const clientType = useSelector<any, string>((state) => state.client.type)
  const isBusiness = clientType === clientTypes.BUSINESS
  const portfolios = useSelector<{ portfolios: { list: PortfolioList } }, Portfolio[]>((state) =>
    state.portfolios.list.getVisiblePortfolios().filter(({ id }) => id !== portfolio.id),
  )
  const availableForMovePortfolios = useMemo(() => {
    if (portfolio.regulatory_type === regulatoryTypes.ISA && action !== 'in') {
      return portfolios.filter(({ regulatory_type: regulatoryType }) => regulatoryType === regulatoryTypes.ISA)
    }

    if (portfolio.regulatory_type === regulatoryTypes.GIA && action === 'in') {
      return portfolios.filter(({ regulatory_type: regulatoryType }) => regulatoryType === regulatoryTypes.GIA)
    }

    if (portfolio.regulatory_type === regulatoryTypes.GIA && action === 'out') {
      return portfolios
    }

    if (portfolio.regulatory_type === regulatoryTypes.SIPP) {
      if (action === 'in') {
        return portfolios.filter(({ regulatory_type: regulatoryType }) =>
          [regulatoryTypes.SIPP, regulatoryTypes.GIA].includes(regulatoryType),
        )
      }

      return portfolios.filter(({ regulatory_type: regulatoryType }) => regulatoryType === regulatoryTypes.SIPP)
    }

    return portfolios.filter(({ regulatory_type: regulatoryType }) => regulatoryType !== regulatoryTypes.SIPP)
  }, [portfolio, portfolios, action])

  const groupedPortfolios = new PortfolioList(...availableForMovePortfolios).groupByRegulatoryType()

  const moveCashAmountRoute = useCallback((): string => {
    switch (action) {
      case 'in':
        return 'move-cash-in'
      case 'out':
        return 'move-cash-out'
      default:
        return 'move-cash'
    }
  }, [action])

  // if there is only one portfolio to move cash to...
  useRedirect({
    to: () => {
      const moveToId = availableForMovePortfolios?.[0]?.id

      if (moveToId) {
        // ...by default redirect to `move-cash.amount`
        const nextUrl = urlTo(
          `dashboard.portfolio.options.${moveCashAmountRoute()}.amount`,
          {
            id: portfolio.id,
            to: moveToId,
          },
          backLink ? { back: backLink } : {},
        )

        goTo(nextUrl, { replace: true })
      }
    },
    rule: availableForMovePortfolios.length < 2,
    isLoading: false,
  })

  const handleBack = useCallback(() => {
    goTo(
      backLink ??
        urlTo(portfolio.manage_type === manageTypes.CASH ? 'dashboard.portfolio' : 'dashboard.portfolio.options', {
          id: portfolio.id,
        }),
    )
  }, [portfolio, backLink])

  const handlePortfolioSelect = useCallback(
    (id) => {
      const nextUrl = urlTo(
        `dashboard.portfolio.options.${moveCashAmountRoute()}.amount`,
        { id: portfolio.id, to: id },
        { back: currentLocation },
      )

      goTo(nextUrl)
    },
    [portfolio.id, currentLocation, moveCashAmountRoute],
  )

  return {
    isBusiness,
    action,
    groupedPortfolios,
    handlePortfolioSelect,
    handleBack,
  }
}

export { useMoveCash }
