import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { modsToClassnames } from 'helpers/classname.js'

import './ColumnarLayout.css'

const Column = forwardRef(({ className, size = 1, sticky, mods, children, ...rest }, ref) => {
  const classes = classNames(
    className,
    'ColumnarLayout-Column',
    [`ColumnarLayout-Column_size_${size}`],
    modsToClassnames('ColumnarLayout-Column', mods),
    { 'ColumnarLayout-Column_sticky': sticky },
  )

  return (
    <div ref={ref} className={classes} {...rest}>
      {children}
    </div>
  )
})
Column.displayName = 'Column'

const ColumnarLayout = ({
  className,
  mods,
  children,
  inline,
  flexible,
  fluid,
  bordered,
  resetBasis,
  flexDirection,
  style = {},
  forwardedRef,
  ...rest
}) => {
  const classes = classNames(
    className,
    'ColumnarLayout',
    {
      ColumnarLayout_inline: inline,
      ColumnarLayout_flexible: flexible,
      ColumnarLayout_fluid: fluid,
      ColumnarLayout_bordered: bordered,
      ColumnarLayout_resetBasis: resetBasis,
      [`ColumnarLayout_flexDirection_${flexDirection}`]: flexDirection,
    },
    modsToClassnames('ColumnarLayout', mods),
  )

  style = {
    ...style,
    flexDirection,
  }

  return (
    <div className={classes} style={style} {...rest} ref={forwardedRef}>
      {React.Children.map(children, (child) => {
        if (child === null || Column === child.type) {
          return child
        }

        return <Column>{child}</Column>
      })}
    </div>
  )
}

const propTypes = (ColumnarLayout.propTypes = {
  className: PropTypes.string,
  mods: PropTypes.object,
  children: PropTypes.node,
  inline: PropTypes.bool,
  flexible: PropTypes.bool,
  fluid: PropTypes.bool,
  bordered: PropTypes.bool,
  resetBasis: PropTypes.bool,
  direction: PropTypes.oneOf(['column', 'column-reverse']),
  style: PropTypes.object,
})

export { Column, propTypes }
export default ColumnarLayout
