import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { Hr } from 'components/atoms/Hr'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import { manageTypes, regulatoryTypes } from 'constants/portfolio'

type CashBreakDownAmountsProps = {
  portfolio: Portfolio
  showCashFromPendingOrders?: boolean
}

const CashBreakdownAmounts = ({
  portfolio,
  showCashFromPendingOrders = true,
}: CashBreakDownAmountsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const availableBalance = [manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(portfolio.manage_type)
    ? portfolio.withdrawable_cash
    : portfolio.safe_withdrawable_cash

  const description = {
    withdrawal: (
      <div>
        <Typography size={desktop ? 16 : 24} lineHeight="small" weight="semibold">
          <Typo>Available for withdrawal</Typo>
        </Typography>
        <Paper top={16}>
          <Typo>
            Includes investment sales and dividends that have settled, as well as confirmed cash top-ups and deposits
          </Typo>
        </Paper>
      </div>
    ),
    unsettled: (
      <div>
        <Typography size={desktop ? 16 : 24} lineHeight="small" weight="semibold">
          <Typo>Pending</Typo>
        </Typography>
        <Paper top={16}>
          <Typo>
            Includes investment sales and dividends that are still settling, as well as cash top-ups and deposits that
            have yet to be confirmed
          </Typo>
        </Paper>
      </div>
    ),
    pendingOrders: (
      <div>
        <Typography size={desktop ? 16 : 24} lineHeight="small" weight="semibold">
          <Typo>Estimated from pending orders</Typo>
        </Typography>
        <Paper top={16}>
          <Typo>
            Includes cash currently being invested and the proceeds from any pending investment sales. This cash is not
            available for withdrawal but can still be reinvested within the portfolio
          </Typo>
        </Paper>
        <Paper top={16}>
          <Typo>
            We make 95% of the funds from pending orders available to reinvest in the same trading window, along with
            any cash you may have in your portfolio
          </Typo>
        </Paper>
      </div>
    ),
    availableToInvest: (
      <div>
        <Typography size={desktop ? 16 : 24} lineHeight="small" weight="semibold">
          <Typo>Available to invest</Typo>
        </Typography>
        <Paper top={16}>
          <Typo>
            We make 95% of the funds from pending orders available to reinvest in the same trading window, along with
            any cash you may have in your portfolio
          </Typo>
        </Paper>
      </div>
    ),
    hmrcPending: (
      <div>
        <Typography size={desktop ? 16 : 24} lineHeight="small" weight="semibold">
          <Typo>HMRC tax relief</Typo>
        </Typography>
        <Paper top={16}>
          <Typo>
            For 'net' contributions, we will automatically claim back the basic rate tax relief on your behalf. This is
            the 20% income tax you originally paid on the money you used to fund your SIPP. It usually takes 6 to 11
            weeks to receive this rebate from HMRC, and the funds will be added to the same portfolio (or account cash)
            as your original contribution.
            <br />
            <br />
            Please note that any higher rate relief should be claimed through your own annual tax return.
          </Typo>
        </Paper>
      </div>
    ),
  }

  return (
    <Fragment>
      <Card level={0} color="primary-surface-25">
        <Paper top={24} bottom={24} left={20} right={20}>
          {portfolio.regulatory_type !== regulatoryTypes.SIPP && (
            <ColumnarLayout mods={{ padding: 'no' }}>
              <Column>
                <Typography size={16} lineHeight="small">
                  <Typo>Available for withdrawal</Typo>
                  <TooltipToModal
                    className="CashBreakdown-Tooltip"
                    description={description.withdrawal}
                    offset={-174}
                    zIndex={1001}
                    customIcon
                    data-test-id="CashBreakdownWithdrawalTooltip"
                  >
                    <Paper left={4} inline>
                      <Icon
                        type="information-16"
                        color={palette['content-on-background-additional']}
                        size={16}
                        inline
                      />
                    </Paper>
                  </TooltipToModal>
                </Typography>
              </Column>
              <Column size={0}>
                <Paper left={12}>
                  <Typography size={18} lineHeight="small" weight="semibold" align="right">
                    <PoundsWithPence
                      amount={availableBalance}
                      showZeroPence
                      data-test-id="CashBreakdownWithdrawalValue"
                    />
                  </Typography>
                </Paper>
              </Column>
            </ColumnarLayout>
          )}
          {portfolio.regulatory_type === regulatoryTypes.SIPP && (
            <ColumnarLayout>
              <Column>
                <Typography size={16} lineHeight="small">
                  <Typo>Pending from HMRC</Typo>
                  <TooltipToModal
                    className="CashBreakdown-Tooltip"
                    description={description.hmrcPending}
                    offset={-174}
                    zIndex={1001}
                    customIcon
                    data-test-id="CashBreakdownHmrcTooltip"
                  >
                    <Paper left={4} inline>
                      <Icon
                        type="information-16"
                        color={palette['content-on-background-additional']}
                        size={16}
                        inline
                      />
                    </Paper>
                  </TooltipToModal>
                </Typography>
              </Column>
              <Column size={0}>
                <Paper left={12}>
                  <Typography size={18} lineHeight="small" weight="semibold" align="right">
                    <PoundsWithPence
                      amount={portfolio.pending_hmrc_cash}
                      showZeroPence
                      data-test-id="CashBreakdownHmrcValue"
                    />
                  </Typography>
                </Paper>
              </Column>
            </ColumnarLayout>
          )}
          <Paper top={12}>
            <ColumnarLayout>
              <Column>
                <Typography size={16} lineHeight="small">
                  <Typo>Pending</Typo>
                  <TooltipToModal
                    className="CashBreakdown-Tooltip"
                    description={description.unsettled}
                    offset={-63}
                    zIndex={1001}
                    customIcon
                    data-test-id="CashBreakdownUnsettledTooltip"
                  >
                    <Paper left={4} inline>
                      <Icon
                        type="information-16"
                        color={palette['content-on-background-additional']}
                        size={16}
                        inline
                      />
                    </Paper>
                  </TooltipToModal>
                </Typography>
              </Column>
              <Column size={0}>
                <Paper left={12}>
                  <Typography size={18} lineHeight="small" weight="semibold" align="right">
                    <PoundsWithPence
                      amount={portfolio.unsettled_cash}
                      showZeroPence
                      data-test-id="CashBreakdownUnsettledValue"
                    />
                  </Typography>
                </Paper>
              </Column>
            </ColumnarLayout>
          </Paper>
          <Paper top={20} bottom={20}>
            <Hr color="primary-surface-25" />
          </Paper>
          <ColumnarLayout>
            <Column>
              <Typography size={16} lineHeight="small">
                <Typo>Total cash</Typo>
              </Typography>
            </Column>
            <Column size={0}>
              <Paper left={12}>
                <Typography size={18} lineHeight="small" weight="semibold" align="right">
                  <PoundsWithPence
                    amount={portfolio.total_cash}
                    showZeroPence
                    data-test-id="CashBreakdownTotalCashValue"
                  />
                </Typography>
              </Paper>
            </Column>
          </ColumnarLayout>
          {showCashFromPendingOrders && (
            <React.Fragment>
              <Paper top={20}>
                <ColumnarLayout mods={{ padding: 'no' }}>
                  <Column>
                    <Typography size={16} lineHeight="small">
                      <Typo>Estimated from pending</Typo>{' '}
                      <Nobr>
                        orders
                        <TooltipToModal
                          className="CashBreakdown-Tooltip"
                          description={description.pendingOrders}
                          offset={-193}
                          zIndex={1001}
                          customIcon
                          data-test-id="CashBreakdownOrdersTooltip"
                        >
                          <Paper left={4} inline>
                            <Icon
                              type="information-16"
                              color={palette['content-on-background-additional']}
                              size={16}
                              inline
                            />
                          </Paper>
                        </TooltipToModal>
                      </Nobr>
                    </Typography>
                  </Column>
                  <Column size={0}>
                    <Paper left={12}>
                      <Typography size={18} lineHeight="small" weight="semibold" align="right">
                        <PoundsWithPence
                          amount={portfolio.pending_orders_cash}
                          showZeroPence
                          data-test-id="CashBreakdownPendingValue"
                        />
                      </Typography>
                    </Paper>
                  </Column>
                </ColumnarLayout>
              </Paper>
              <Paper top={20} bottom={1}>
                <Hr color="primary-surface-25" />
              </Paper>
              <Paper top={1} bottom={20}>
                <Hr color="primary-surface-25" />
              </Paper>
              <ColumnarLayout>
                <Column>
                  <Typography size={16} lineHeight="small">
                    <Typo>Available to invest</Typo>
                    <TooltipToModal
                      className="CashBreakdown-Tooltip"
                      description={description.availableToInvest}
                      offset={-193}
                      zIndex={1001}
                      customIcon
                      data-test-id="CashBreakdownAvailibleToInvestTooltip"
                    >
                      <Paper left={4} inline>
                        <Icon
                          type="information-16"
                          color={palette['content-on-background-additional']}
                          size={16}
                          inline
                        />
                      </Paper>
                    </TooltipToModal>
                  </Typography>
                </Column>
                <Column size={0}>
                  <Paper left={12}>
                    <Typography size={18} lineHeight="small" weight="semibold" align="right">
                      <PoundsWithPence
                        amount={portfolio.available_to_invest_cash}
                        showZeroPence
                        data-test-id="CashBreakdownInvestValue"
                      />
                    </Typography>
                  </Paper>
                </Column>
              </ColumnarLayout>
            </React.Fragment>
          )}
        </Paper>
      </Card>
    </Fragment>
  )
}

export { CashBreakdownAmounts }
