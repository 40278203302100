import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router.js'

import { useCallback, useMemo } from 'hooks'

import { Gateway } from 'components/atoms/Gateway'
import { Modal } from 'components/molecules/Modal'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import { BasicModalInside } from 'app/pages/Dashboard/Goals/components/BasicModalInside'

const OrdersCancelledModal = withRouter(
  ({ location: { query: { ordersCancelled, ...restQuery } = {} } = {}, portfolioId }) => {
    const open = ordersCancelled === 'true'

    const handleClose = useCallback(
      () => goTo(urlTo(`dashboard.portfolio`, { id: portfolioId }, restQuery), { replace: true, scrollToTop: false }),
      [portfolioId, restQuery],
    )

    const content = useMemo(
      () => ({
        iconType: 'checkmark_in_a_circle',
        iconSize: 54,
        iconColor: palette['status-success'],
        title: 'Orders have been cancelled',
        onClose: handleClose,
      }),
      [],
    )

    return (
      <Gateway into="modals">
        <Modal open={open} onClose={handleClose} data-test-id="ordersCancelledModal">
          <AllCenter>
            <BasicModalInside {...content} />
          </AllCenter>
        </Modal>
      </Gateway>
    )
  },
)

OrdersCancelledModal.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      ordersCancelled: PropTypes.oneOf([undefined, 'true']),
    }),
  }),
  portfolioId: PropTypes.number,
}

export { OrdersCancelledModal }
