import { nullable, type Nullable, int, string, enumValue } from 'app/effector/model'

import { SippTransferRequestPartType, SippTransferRequestAssetType, type SippTransferRequestDTO } from '../api'

class SippTransferRequest {
  readonly portfolio: Nullable<number>
  readonly provider: Nullable<number>
  readonly plan_number: Nullable<string>
  readonly part_type: Nullable<SippTransferRequestPartType>
  readonly asset_transfer_type: Nullable<SippTransferRequestAssetType>
  readonly amount: Nullable<string>

  constructor(dto: Partial<SippTransferRequestDTO> | Partial<SippTransferRequest> = {}) {
    this.portfolio = nullable(int)(dto.portfolio)
    this.provider = nullable(int)(dto.provider)
    this.plan_number = nullable(string)(dto.plan_number)
    this.part_type = nullable(enumValue)(dto.part_type, SippTransferRequestPartType)
    this.asset_transfer_type = nullable(enumValue)(dto.asset_transfer_type, SippTransferRequestAssetType)
    this.amount = nullable(string)(dto.amount)
  }

  setFieldFor<Field extends keyof SippTransferRequest>(field: Field) {
    return (value?: SippTransferRequest[Field]): SippTransferRequest => {
      return new SippTransferRequest({ ...this, [field]: value })
    }
  }

  merge(dto: Partial<SippTransferRequestDTO> | Partial<SippTransferRequest> = {}): SippTransferRequest {
    return new SippTransferRequest({ ...this, ...dto })
  }
}

export { SippTransferRequest }
