import React, { Fragment } from 'react'

import { urlTo } from 'helpers/router.js'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Hr } from 'components/atoms/Hr'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

type WithdrawCashBreakdownProps = {
  portfolioId: string
  isDiy: boolean
  availableBalance: number
  unsettledCash: string
}

const WithdrawCashBreakdown = ({
  portfolioId,
  isDiy,
  availableBalance,
  unsettledCash,
}: WithdrawCashBreakdownProps): React.ReactElement => {
  return (
    <Paper>
      <Paper bottom={16}>
        <Hr />
      </Paper>
      <ColumnarLayout>
        <Column>
          <Typography size={16} lineHeight="small">
            <Typo>Available for withdrawal</Typo>
          </Typography>
        </Column>
        <Column size={0}>
          <Paper left={12}>
            <Typography size={18} lineHeight="small" weight="semibold" align="right">
              <PoundsWithPence amount={availableBalance} showZeroPence data-test-id="withdrawalFundsAvailableBalance" />
            </Typography>
          </Paper>
        </Column>
      </ColumnarLayout>
      <Paper top={16}>
        <ColumnarLayout>
          <Column>
            <Typography size={16} lineHeight="small">
              <Typo>Pending</Typo>
            </Typography>
          </Column>
          <Column size={0}>
            <Paper left={12}>
              <Typography size={18} lineHeight="small" weight="semibold" align="right">
                <PoundsWithPence amount={unsettledCash} showZeroPence data-test-id="withdrawalFundsPending" />
              </Typography>
            </Paper>
          </Column>
        </ColumnarLayout>
      </Paper>
      {isDiy && (
        <Fragment>
          <Paper top={20} bottom={20}>
            <Hr />
          </Paper>
          <Typography align="center">
            <Link
              to={urlTo('dashboard.portfolio.options.cash-breakdown', { id: portfolioId })}
              data-test-id="showCashBreakDownLink"
            >
              Show cash breakdown
            </Link>
          </Typography>
        </Fragment>
      )}
    </Paper>
  )
}

export { WithdrawCashBreakdown }
