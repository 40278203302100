import React from 'react'
import InputMask from 'react-input-mask'

import classNames from 'classnames/dedupe'
import { allCountries, allCountryCodes } from 'country-telephone-data'
import { AsYouType } from 'libphonenumber-js/mobile'
import PropTypes from 'prop-types'

import { useEffect, useCallback, useState, useMemo, useRef } from 'hooks'

import './PhoneInput.css'

const defaultMask = '+99 9999 999999'

const countryData = allCountries.reduce((acc, { iso2, format, dialCode }) => {
  acc[iso2] = {
    mask: format ? format.replace(/\./g, '9') : defaultMask,
    dialCode,
  }
  return acc
}, {})

const parsePhone = (phone: string): string => {
  const numbers = phone.replace(/\D/g, '')

  return numbers ? `+${numbers}` : ''
}

type PhoneInputProps = {
  className?: string
  country?: string
  value?: string
  onChange?: (event: React.ChangeEvent, value: string) => void
  'data-test-id'?: string
}

const PhoneInput = ({
  className = '',
  country = 'gb',
  value = '',
  onChange,
  'data-test-id': dataTestId,
  ...restProps
}: PhoneInputProps): React.ReactElement => {
  const [mask, setMask] = useState(defaultMask)
  const [countryCode, setCountryCode] = useState((country || 'gb').toLowerCase())
  const isInitialRender = useRef(true)
  const isPartiallyHidden = value?.includes('*')
  const classes = classNames('PhoneInput', { PhoneInput_partiallyHidden: isPartiallyHidden })

  const setInputParamsByPhone = useCallback(
    (phone) => {
      const findCountryByCode = (code: string): string => {
        if (!code) return country
        const countries = allCountryCodes[code]

        if (countries) {
          return countries[0]
        }
        return findCountryByCode(code.substr(0, code.length - 1))
      }

      const asYouType = new AsYouType()
      asYouType.input(phone)

      const inputNumberForCountries = phone.substr(1, 5)
      const countryCode = findCountryByCode(inputNumberForCountries)
      const data = countryData[countryCode]

      if (data) {
        setMask(data.mask)
        setCountryCode(countryCode)
      }
    },
    [country],
  )

  const handleChange = useCallback(
    (event) => {
      const phone = parsePhone(event.target.value)
      onChange?.(event, phone)
    },
    [onChange],
  )

  const inputValue = useMemo(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      if (!value) {
        const dialCode: string = countryData[countryCode]?.dialCode

        return dialCode ? `+${dialCode}` : ''
      }

      return value
    }
    return value
  }, [value, countryCode])

  useEffect(() => {
    if (inputValue) {
      const phone = parsePhone(inputValue)
      setInputParamsByPhone(phone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <div data-test-id={dataTestId} className={classes}>
      <InputMask
        className={`PhoneInput-Input ${className}`}
        mask={isPartiallyHidden ? null : mask}
        maskChar=""
        type="tel"
        value={inputValue}
        onChange={handleChange}
        {...restProps}
      />

      {!isPartiallyHidden && (
        <div className="PhoneInput-Flags">
          <div className="PhoneInput-SelectedFlag">
            <div className={`PhoneInput-Flag PhoneInput-Flag_${countryCode}`}></div>
          </div>
        </div>
      )}
    </div>
  )
}

PhoneInput.propTypes = {
  defaultValue: PropTypes.string,
  country: PropTypes.string,
  'data-test-id': PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
}

export { PhoneInput }
