import React, { forwardRef } from 'react'

import classNames from 'classnames/dedupe'

import { useMemo } from 'hooks'

import { type TransferProgress } from 'app/effector/isa-transfer/models/TransferProgress'
import { type TransferProgressStep } from 'app/effector/isa-transfer/models/TransferProgress/TransferProgressStep'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './AltusProgressBarCard.css'

type ProgressBarProps = {
  altusProgressBarData: TransferProgress
  handleModalOpen: () => void
  handleDismissPanel: () => void
  'data-test-id'?: string
}

const AltusProgressBarCard = forwardRef<HTMLDivElement, ProgressBarProps>(
  (
    { altusProgressBarData, handleModalOpen, handleDismissPanel, 'data-test-id': dataTestId }: ProgressBarProps,
    ref,
  ) => {
    const isCompleted = useMemo(
      () => altusProgressBarData.steps.every((step) => step.state === 'DONE'),
      [altusProgressBarData],
    )

    const progressBar = useMemo(
      () => (
        <div className="AltusProgressBar-ProgressBar">
          {altusProgressBarData.steps.map((step: TransferProgressStep, index) => (
            <div
              key={index}
              className={classNames(
                'AltusProgressBar-Step',
                step.state === 'IN_PROGRESS' && 'AltusProgressBar-Step_active',
                step.state === 'DONE' && 'AltusProgressBar-Step_completed',
                step.state === 'ERROR' && 'AltusProgressBar-Step_warning',
              )}
              data-test-id={dataTestId ? `${dataTestId}Step${index}` : undefined}
            >
              <div className="AltusProgressBar-Point">
                {step.state === 'ERROR' && (
                  <Icon className="AltusProgressBar-Icon" type="attention-24" size={16} color="transparent" />
                )}
                {step.state === 'DONE' && (
                  <Icon
                    className="AltusProgressBar-Icon"
                    type="check-16"
                    size={10}
                    color="var(--content-on-color-white)"
                  />
                )}
              </div>
              <div className="AltusProgressBar-PointName">
                {['ERROR', 'IN_PROGRESS'].includes(step.state) && (
                  <Typography size={10} lineHeight="small" color="white" align={index === 0 ? 'left' : 'center'}>
                    <Typo>{step.short_name}</Typo>
                  </Typography>
                )}
              </div>
            </div>
          ))}
        </div>
      ),
      [altusProgressBarData.steps, dataTestId],
    )

    return (
      <Card ref={ref} className="AltusProgressBar-Card" data-test-id={dataTestId}>
        <Paper top={24} right={24} bottom={24} left={24}>
          <Link
            className="AltusProgressBar-Info"
            mods={{ color: 'white' }}
            onClick={handleModalOpen}
            data-test-id={dataTestId ? `${dataTestId}OpenModal` : undefined}
          >
            <Icon type="information-24" size={24} />
          </Link>
          <Typography
            size={18}
            lineHeight="small"
            weight="semibold"
            color="white"
            data-test-id={dataTestId ? `${dataTestId}Title` : undefined}
          >
            <Typo>ISA Transfer{isCompleted && ' completed'}</Typo>
          </Typography>
          <Paper className="AltusProgressBar-Title" top={4}>
            <Typography
              className="AltusProgressBar-Name"
              size={14}
              lineHeight="small"
              color="white"
              data-test-id={dataTestId ? `${dataTestId}ProviderName` : undefined}
            >
              <Typo>{altusProgressBarData.provider_name}</Typo>
            </Typography>
            &nbsp;
            <Typography
              size={14}
              lineHeight="small"
              color="white"
              data-test-id={dataTestId ? `${dataTestId}AccountNumber` : undefined}
            >
              <Typo>{altusProgressBarData.account_number}</Typo>
            </Typography>
          </Paper>
          <Paper top={20}>{progressBar}</Paper>
          {altusProgressBarData.steps.map(
            (step, index) =>
              step.error &&
              step.state === 'ERROR' && (
                <Paper top={12} key={index}>
                  <Card color="background-default">
                    <Paper className="AltusProgressBar-WarningMessage" top={16} right={16} bottom={16} left={16}>
                      <div className="AltusProgressBar-Point AltusProgressBar-Point_warning">
                        <Icon
                          className="AltusProgressBar-Icon"
                          type="attention-24"
                          size={16}
                          color="var(--status-warning)"
                        />
                      </div>
                      <Paper left={8}>
                        <Typography size={14} lineHeight="small">
                          <Typo>{step.error}</Typo>
                        </Typography>
                      </Paper>
                    </Paper>
                  </Card>
                </Paper>
              ),
          )}
          {isCompleted && (
            <Paper top={16} onClick={handleDismissPanel}>
              <Button mods={{ theme: 'simple-reverse-content-on-color', size: 'block' }}>Dismiss</Button>
            </Paper>
          )}
        </Paper>
      </Card>
    )
  },
)
AltusProgressBarCard.displayName = 'AltusProgressBarCard'

export { AltusProgressBarCard }
