import React, { useCallback } from 'react'
import classNames from 'classnames/dedupe'

import Link from 'components/_old/Link/Link.jsx'
import Card from 'components/_old/Card/Card.jsx'

import './SelectableCard.css'

const SelectableCard = ({
  children,
  className,
  cardClassName,
  stretch,
  center,
  selected,
  withLink = true,
  url,
  value,
  onChange,
  tabIndex,
  'data-test-id': dataTestId,
  ...rest
}) => {
  const handleChange = useCallback(
    (event) => {
      if (onChange) {
        onChange(event, value)
      }
    },
    [onChange, value],
  )

  const classes = classNames(className, 'SelectableCard', {
    SelectableCard_stretch: stretch,
    SelectableCard_center: center,
    SelectableCard_selected: selected,
  })

  const cardClasses = classNames(cardClassName, 'SelectableCard-Card')
  const cardTheme = selected ? 'transparent light' : 'transparent light shadowed'

  const inner = (
    <Card className={cardClasses} mods={{ theme: cardTheme }} {...rest}>
      <div className="SelectableCard-Body">{children}</div>
    </Card>
  )

  if (withLink) {
    return (
      <Link
        className={classes}
        to={url}
        onClick={handleChange}
        tabIndex={tabIndex}
        data-test-id={dataTestId}
        data-selected={selected}
      >
        {inner}
      </Link>
    )
  }

  return (
    <div className={classes} data-test-id={dataTestId} data-selected={selected}>
      {inner}
    </div>
  )
}

export default SelectableCard
