import React from 'react'
import PropTypes from 'prop-types'

import { useCallback, useMediaQueries, useMemo, useSelector } from 'hooks'
import { useUnit } from 'effector-react'

import { receiveAccount, $bankAccountsStore } from 'app/effector/bank-accounts'

import { Modal } from 'components/molecules/Modal'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { UploadBankStatementForm } from 'components/organisms/UploadBankStatementForm'

const UploadBankStatementModal = ({ bankAccountId, onClose }) => {
  const { desktop } = useMediaQueries()

  const { getBankAccountById } = useUnit($bankAccountsStore)
  const bankAccount = getBankAccountById(bankAccountId)

  const handleUpload = useCallback(
    () => receiveAccount({ ...bankAccount, bank_statement_filled: true }),
    [receiveAccount, bankAccount],
  )

  const header = useMemo(
    () =>
      !desktop ? (
        <NavigationBar rightPartText="Skip" onRightPartClick={onClose}>
          Set up Open Banking
        </NavigationBar>
      ) : null,
    [desktop, onClose],
  )

  return (
    <Modal open={!!bankAccountId} onClose={onClose}>
      <UploadBankStatementForm
        header={header}
        bankAccount={bankAccount}
        onAfterUpload={handleUpload}
        onContinue={onClose}
      />
    </Modal>
  )
}

UploadBankStatementModal.propTypes = {
  bankAccountId: PropTypes.number,
  onClose: PropTypes.func,
}

export { UploadBankStatementModal }
