import React, { useCallback } from 'react'

import { useMediaQueries } from 'hooks'

import { goTo } from 'helpers/router'

import { Typo } from 'components/_old/Typo/Typo'
import HeadlineWBacklink from 'components/_old/HeadlineWBacklink/HeadlineWBacklink.jsx'
import { NavigationBar } from 'components/atoms/NavigationBar'

export default function StepHeadline(props) {
  const { headline, back, backLinkText, postHeadline, preHeadline, ...rest } = props
  const { desktop } = useMediaQueries()
  const handleLeftPartClick = useCallback(() => (typeof back === 'function' ? back() : goTo(back)), [back])

  return desktop ? (
    <NavigationBar
      leftPartText={back ? backLinkText : undefined}
      onLeftPartClick={back ? handleLeftPartClick : undefined}
    >
      <span data-test-id="headline">
        {preHeadline}
        <Typo>{headline}</Typo>
        {postHeadline}
      </span>
    </NavigationBar>
  ) : (
    <HeadlineWBacklink {...{ back, backLinkText, ...rest }}>
      {preHeadline}
      <Typo>{headline}</Typo>
      {postHeadline}
    </HeadlineWBacklink>
  )
}
