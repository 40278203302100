import { browserHistory } from 'react-router'

import { analytics, analyticsCIO } from 'helpers/analytics'

const history = browserHistory

// todo: Should it sit here?
history.listen(() => {
  const allowedQueryParametersRegexp = /(search)/
  const searchGroups = window.location.search
    .split(/[?&]/)
    .filter((string) => allowedQueryParametersRegexp.test(string))
  const searchString = searchGroups.reduce(
    (string, searchGroup, index) => `${string}${index === 0 ? '?' : ''}${searchGroups}`,
    '',
  )
  const path = window.location.pathname + searchString

  analytics?.page({ path })
  analyticsCIO?.page({ path })
})

const createLocation = (url, state, action) => {
  const location = history.createLocation(url, action)
  location.state = state
  return location
}

export const replace = (url, state) => history.transitionTo(createLocation(url, state, 'REPLACE'))
export const push = (url, state) => history.transitionTo(createLocation(url, state, 'PUSH'))

export default history
