import React, { Fragment } from 'react'
import isFunction from 'lodash/isFunction'

import { processError } from 'helpers/errors'

import { useActions, useCallback, useMediaQueries, useMemo, useSelector } from 'hooks'

import { ControlledInput, useForm, validation } from 'components/atoms/Forms'
import { Modal, ModalHeader } from 'components/molecules/Modal'
import ModalContent, { ModalContentBody, ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import { NavigationBar } from 'components/atoms/NavigationBar'
import Button from 'components/_old/Button/Button.jsx'
import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { Nobr } from 'components/_old/Nobr'
import Headline from 'components/_old/Headline/Headline.jsx'
import { Paper } from 'components/atoms/Paper'

import {
  sendMessage as sendMessageActionCreator,
  resetError as resetErrorActionCreator,
} from 'app/redux/actions/client'
import { changeModalVisibility as changeModalVisibilityActionCreator, showSuccessToast } from 'app/redux/actions/ui'

const SupportModal = ({ onAfterSubmit: handleAfterSubmit }) => {
  const { desktop } = useMediaQueries()

  const modal = useSelector((state) => state.ui.modals.supportModal)
  const { visible, email, name, message } = modal

  const {
    control,
    errors,
    isValid,
    handleReset,
    handleSubmit: handleFormSubmit,
  } = useForm({
    email,
    name,
    message,
  })

  const [changeModalVisibility, sendMessage, resetError] = useActions([
    changeModalVisibilityActionCreator,
    sendMessageActionCreator,
    resetErrorActionCreator,
  ])

  const handleCloseModal = useCallback(() => {
    changeModalVisibility('supportModal', false)

    handleReset()
  }, [changeModalVisibility, handleReset])

  const handleSendMessage = useCallback(
    async ({ email, name, message }) => {
      try {
        const stateAfterSendMessage = await sendMessage({ message, email, name })

        if (stateAfterSendMessage.client.error) {
          throw stateAfterSendMessage.client.error
        }

        showSuccessToast('Message successfully sent')
      } catch (error) {
        processError({
          error,
          forceReset: true,
          resets: [resetError],
        })
      }
    },
    [sendMessage, showSuccessToast],
  )

  const handleSubmit = handleFormSubmit(async (values) => {
    await handleSendMessage(values)

    if (isFunction(handleAfterSubmit)) {
      handleAfterSubmit()
    }

    handleCloseModal()
  })

  const header = useMemo(
    () =>
      !desktop ? (
        <ModalHeader>
          <NavigationBar leftPartText="Cancel" onLeftPartClick={handleCloseModal} />
        </ModalHeader>
      ) : null,
    [desktop, handleCloseModal],
  )

  const buttons = useMemo(() => {
    if (desktop) {
      return (
        <ButtonGroup>
          <Button
            type="reset"
            mods={{ theme: 'simple-reverse-blue' }}
            onClick={handleCloseModal}
            data-test-id="supportModalCancelButton"
          >
            Cancel
          </Button>
          <Button disabled={!isValid} type="submit" data-test-id="supportModalSubmitButton">
            Send message
          </Button>
        </ButtonGroup>
      )
    }

    return (
      <Button
        disabled={!isValid}
        mods={{ size: 'big block' }}
        onClick={handleSubmit}
        data-test-id="supportModalSubmitButton"
      >
        Send message
      </Button>
    )
  }, [desktop, isValid, handleCloseModal])

  return (
    <Modal open={visible} onClose={handleCloseModal} insideGatewayName="supportModalInside" data-test-id="supportModal">
      <ModalContent width={35}>
        {header}
        <form onSubmit={handleSubmit}>
          <Paper top={desktop ? 32 : 24} left={desktop ? 40 : 16} right={desktop ? 40 : 16}>
            <Headline level={desktop ? 1 : 3} mods={{ 'no-margin': 'top', text: 'center' }}>
              <Typo>Need support?</Typo>
            </Headline>
            <ModalContentBody size={null}>
              <Fragment>
                <Card
                  mods={{
                    theme: 'transparent',
                    'no-padding': 'top left right',
                    padding: 'big',
                    text: 'center',
                  }}
                >
                  <Text small muted_very>
                    <Typo>
                      Our support is available <Nobr>Monday-Friday 5:30am to 11:00pm</Nobr>{' '}
                      <Nobr>and Saturday-Sunday 7:00am to 10:00pm</Nobr>
                    </Typo>
                  </Text>
                </Card>
                <Card
                  mods={{
                    theme: 'transparent',
                    'no-padding': 'top left right',
                    padding: 'big',
                  }}
                >
                  <ControlledInput name="email" errors={errors} control={control} rules={validation.email}>
                    {({ value, valid, errorMessage, handleChange, handleFocus, handleBlur }) => (
                      <Label valid={valid} errorMessage={errorMessage} data-test-id="supportModalEmailLabel">
                        Email
                        <Input
                          name="email"
                          type="email"
                          mods={{ size: 'bigger' }}
                          tabIndex="101"
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          data-test-id="supportModalEmailInput"
                        >
                          {value}
                        </Input>
                      </Label>
                    )}
                  </ControlledInput>
                  <ControlledInput
                    name="name"
                    errors={errors}
                    control={control}
                    rules={{ required: { value: true, message: 'Name can’t be empty' } }}
                  >
                    {({ value, valid, errorMessage, handleChange, handleFocus, handleBlur }) => (
                      <Label valid={valid} errorMessage={errorMessage} data-test-id="supportModalNameLabel">
                        Your name
                        <Input
                          name="fullname"
                          type="text"
                          mods={{ size: 'bigger' }}
                          tabIndex="102"
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          data-test-id="supportModalNameInput"
                        >
                          {value}
                        </Input>
                      </Label>
                    )}
                  </ControlledInput>
                  <ControlledInput
                    name="message"
                    errors={errors}
                    control={control}
                    rules={{
                      required: { value: true, message: 'Message can’t be empty' },
                      maxLength: { value: 10000, message: 'Message must not exceed 10000 characters' },
                    }}
                  >
                    {({ value, valid, errorMessage, handleChange, handleFocus, handleBlur }) => (
                      <Label valid={valid} errorMessage={errorMessage} data-test-id="supportModalMessageLabel">
                        Message
                        <Input
                          name="message"
                          type="textarea"
                          rows={6}
                          mods={{ size: 'bigger' }}
                          tabIndex="103"
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          data-test-id="supportModalMessageInput"
                        >
                          {value}
                        </Input>
                      </Label>
                    )}
                  </ControlledInput>
                </Card>
              </Fragment>
            </ModalContentBody>
          </Paper>
          <Paper bottom={desktop ? 32 : 0}>
            <ModalContentButtons
              targetGatewayName="supportModalInside"
              width={desktop ? 30 : null}
              phantomButtonsMarginSize="zero"
              isNewModal
            >
              {buttons}
            </ModalContentButtons>
          </Paper>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default SupportModal
