import { Entity } from 'app/redux/models/common/Entity.js'

import { ClientAnswer } from './ClientAnswer'

/**
 * @class
 * @typedef {Object} ClientAnswerList
 */
export class ClientAnswerList extends Entity {
  constructor() {
    super()

    /** @type {ClientAnswer[]} */
    this.clientAnswers = []
  }

  /**
   * @param {ClientAnswer[]} clientAnswers
   * @return {ClientAnswerList}
   */
  receiveAnswers(clientAnswers) {
    this.clientAnswers = clientAnswers
    return this
  }

  /**
   * @param {number=} questionId
   * @return {ClientAnswer}
   */
  findClientAnswerByQuestionId(questionId) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.clientAnswers.find(({ question_id }) => question_id === questionId)
  }

  /**
   * @param {Object=} answerFields
   * @return {ClientAnswerList}
   */

  setClientAnswer(answerFields) {
    const answerIndex = this.clientAnswers.findIndex(
      (currentAnswer) => currentAnswer.question_id === answerFields.question_id,
    )

    if (answerIndex === -1) {
      const newAnswer = ClientAnswer.createFromObject(answerFields)
      this.clientAnswers = [...this.clientAnswers, newAnswer]
      return this
    }

    this.clientAnswers = this.clientAnswers.map((currentAnswer, idx) => {
      if (idx !== answerIndex) {
        return currentAnswer
      }

      const newAnswer = ClientAnswer.createFromObject(currentAnswer)
      newAnswer.updateFrom(answerFields)
      return newAnswer
    })

    return this
  }
}
