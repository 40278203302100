import React, { Fragment } from 'react'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { DonutChart } from 'components/atoms/DonutChart'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import { type ISummary } from '../../Assets'

type SummaryProps = {
  summary: ISummary
  selectedPortfolio: Record<string, any>
  hideBalance: boolean
}

const Summary = ({ summary, selectedPortfolio, hideBalance = false }: SummaryProps): React.ReactElement => {
  return (
    <AllCenter>
      <DonutChart
        lineWidth={14}
        values={[
          [summary.stocks.actual, palette.stocks],
          [summary.bonds.actual, palette.bonds],
          [summary.alternatives.actual, palette.alternatives],
          [summary.cash.actual, palette.cash],
        ]}
        minimalSectorValue={0.65}
      >
        {!hideBalance && (
          <Fragment>
            <Typography size={32} weight="semibold" lineHeight="small" align="center" data-test-id="donutValue">
              <PoundsWithPence amount={summary.total} />
            </Typography>
            <Paper top={12}>
              <Typography size={14} align="center" data-test-id="donutBalanceHeading">
                <Typo>{selectedPortfolio ? 'Portfolio balance' : 'Total balance'}</Typo>
              </Typography>
            </Paper>
          </Fragment>
        )}
      </DonutChart>
    </AllCenter>
  )
}

export { Summary }
