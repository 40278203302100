import { useCallback, useState } from 'hooks'

enum UseRestrictionTypes {
  EDIT = 'EDIT',
  SECURITY = 'SECURITY',
  PORTFOLIO = 'PORTFOLIO',
  NO_SECURITIES = 'NO_SECURITIES',
}

type UseRestrictionInterface = {
  open: boolean
  type: UseRestrictionTypes | null
  handleClose: () => void
  handleRestriction: (props: {
    isRestricted: boolean
    type: UseRestrictionTypes
    action: (...args: unknown[]) => unknown
    additionalRestrictionAction?: () => void
  }) => void
}

function useRestriction(): UseRestrictionInterface {
  const [open, setOpen] = useState(false)
  const [type, setType] = useState<UseRestrictionTypes | null>(null)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleRestriction = useCallback(({ isRestricted, type, action, additionalRestrictionAction }) => {
    if (isRestricted) {
      setType(type)
      setOpen(true)

      if (typeof additionalRestrictionAction === 'function') {
        additionalRestrictionAction()
      }

      return
    }

    if (typeof action === 'function') {
      action()
    }
  }, [])

  return {
    open,
    type,
    handleClose,
    handleRestriction,
  }
}

export { useRestriction, UseRestrictionTypes, type UseRestrictionInterface }
