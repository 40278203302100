import { sendError } from 'helpers/errorLogging.js'
import { goTo, urlTo } from 'helpers/router.js'

import { useEffect, useSelector } from 'hooks'

import { states as goalStates } from 'constants/goal'

import { showFailToast } from 'app/redux/actions/ui'

import { selectIsSomethingLoading } from 'app/redux/selectors'

const CreateNewPortfolioRootRedirect = (props) => {
  const {
    children,
    routes,
    location,
    params: { id },
  } = props
  const isLoading = useSelector(selectIsSomethingLoading)
  const clientType = useSelector((state) => state.client.type)
  const goal = useSelector((state) => state.portfolios.items.find((goal) => goal.id === Number(id)))
  const typeRoute = routes.find(({ type }) => Boolean(type))

  useEffect(() => {
    if (!id || id === ':id') {
      goTo(urlTo('dashboard'), { replace: true })
      sendError('No goal `id` specified in params at `new-portfolio-tunnel`')
      showFailToast()
    }
  }, [id])

  useEffect(() => {
    if (!isLoading && goal && goal.state !== goalStates.NEW && goal.first_topup) {
      goTo(urlTo('dashboard'))
    }
  }, [goal, isLoading])

  useEffect(() => {
    if (!isLoading && clientType && typeRoute?.type !== clientType) {
      const nextRoute = urlTo(`new-portfolio-tunnel-${clientType.toLowerCase()}`, { id }, location.search)
      goTo(nextRoute, { replace: true })
    }
  }, [isLoading, clientType, typeRoute, id, location.search])

  return children
}

export { CreateNewPortfolioRootRedirect }
