import React from 'react'
import PropTypes from 'prop-types'

import Link from 'components/_old/Link/Link.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

import './Delete.css'

const Delete = ({ children, deletable = true, onDelete: handleDelete, ...rest }) => (
  <div className="Delete">
    {React.cloneElement(children, { ...rest, ...(children.props || {}) })}
    {deletable && (
      <Link onClick={handleDelete} className="Delete-Link" mods={{ color: 'gray' }}>
        <Icon className="Delete-Icon" type="cross-thin" color="inherit" />
      </Link>
    )}
  </div>
)

const propTypes = (Delete.propTypes = {
  children: PropTypes.node,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
})

export { propTypes }
export default Delete
