import React from 'react'
import { withRouter } from 'react-router'

import { PropTypes } from 'prop-types'

import { useActions, useEffect, useMediaQueries, useSelector, useCallback } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { format } from 'helpers/money'
import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router.js'

import { fetchNotificationsFx } from 'app/effector/notifications'
import { fetchOnboardingState } from 'app/effector/onboarding-progress'
import { cancelTransactionFx } from 'app/effector/transactions'

import { fetchRequisites as fetchRequisitesActionCreator, showSuccessToast, showFailToast } from 'app/redux/actions/ui'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import TransferFundsTo from 'components/_old/TransferFundsTo/TransferFundsTo.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'
import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

import { Notation } from 'app/pages/Dashboard/Goals/Notation'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { manageTypes } from 'constants/portfolio'

const BankTransferForm = withRouter(({ goal, handleClose, location, toggleCloseButton }) => {
  const client = useSelector((state) => state.client)
  const requisites = useSelector((state) => state.ui.fields.requisites)
  const transactionId = location.query?.transactionId
  const [fetchRequisites] = useActions([fetchRequisitesActionCreator])
  const { desktop } = useMediaQueries()

  const transactionPortfolioReference = location.query?.transactionPortfolioReference

  const isCashPortfolio = goal.manage_type === manageTypes.CASH

  let amount = null

  const fields = {
    'Payee name': requisites ? requisites.bank_title : null,
    'Sort code': requisites ? requisites.sort_code : null,
    'Bank account number': requisites ? requisites.account_number : null,
    Reference: goal?.reference || transactionPortfolioReference,
  }

  if (location.query?.amount) {
    amount = Number(location.query.amount)
    fields.Amount = format(amount, true)
  }

  const onClose = useCallback(() => {
    fetchOnboardingState({ portfolioId: goal.id, portfolioType: goal.manage_type })
    if (goal.manage_type === manageTypes.DIY && !goal.is_autoinvest_enabled) {
      return goTo(urlTo(`portfolio.autoinvest-suggestion`, { id: goal.id }))
    }

    return handleClose()
  }, [goal, handleClose])

  useEffect(() => {
    if (!requisites?.bank_title) {
      fetchRequisites()
    }
  }, [requisites])

  useEffect(() => {
    toggleCloseButton(false)
  }, [])

  const handleCancelTransaction = useCallback(async () => {
    try {
      await cancelTransactionFx(transactionId)

      handleClose({ replace: true })

      fetchOnboardingState({ portfolioId: goal.id, portfolioType: goal.manage_type })

      showSuccessToast('Transfer cancelled')

      trackEvent(
        {
          action: 'payment_cancelled',
          amount,
          client_type: client.type,
          manage_type: goal.manage_type,
          payment_method: 'WIRE',
        },
        { plugins: { 'google-analytics-v3': false } },
      )

      fetchNotificationsFx()
    } catch (e) {
      showFailToast()
    }
  }, [handleClose, transactionId, amount, client, goal])

  const buttons = [
    <Button
      key="bankTransferPopupOk"
      mods={{ size: desktop ? 'big' : 'big block' }}
      onClick={onClose}
      data-test-id="bankTransferPopupOk"
    >
      OK, I will transfer the funds
    </Button>,
  ]

  if (transactionId) {
    buttons.unshift(
      <Button
        key="bankTransferPopupCancel"
        mods={{ theme: 'simple-reverse-blue' }}
        onClick={handleCancelTransaction}
        data-test-id="bankTransferPopupCancel"
      >
        I won’t be making this transfer
      </Button>,
    )
  }

  return (
    <OptionsLayout
      header={
        <OptionsNavigationBar rightPartText="Close" onRightPartClick={handleClose}>
          <Typo>{isCashPortfolio ? 'Add cash' : 'Manual bank transfer'}</Typo>
        </OptionsNavigationBar>
      }
      content={
        <Paper top={desktop ? 24 : null}>
          <Width size={desktop ? 31 : null} center>
            <Width size={desktop ? 26 : null} center>
              <Headline level={desktop ? 1 : 2} mods={{ text: 'center', 'no-margin': 'top' }}>
                <Typo>InvestEngine bank details for your transfer</Typo>
              </Headline>
              <Notation
                text="Please instruct your bank to transfer your funds  using these details:"
                iconType="attention-24"
                iconColor={palette['status-success']}
                data-test-id="wireTransferDetails"
              />
            </Width>
            <Card
              mods={{
                theme: 'transparent',
                padding: 'big',
                'no-padding': 'right left',
              }}
            >
              <TransferFundsTo fields={fields} smallMuted={!desktop} showCopyButtons />
            </Card>
            <Width size={desktop ? null : 17} center>
              <Paper top={desktop ? 12 : 0}>
                <Typography size={16} lineHeight={desktop ? 'small' : 'medium'} align="center">
                  <Typo>You can also set up standing orders using the same instructions</Typo>
                </Typography>
              </Paper>
            </Width>
          </Width>
        </Paper>
      }
      button={buttons}
      data-test-id="wireTransferDetailsModal"
    />
  )
})

BankTransferForm.propTypes = {
  goal: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  location: PropTypes.object,
  toggleCloseButton: PropTypes.func,
}

export default BankTransferForm
