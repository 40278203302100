import React, { forwardRef } from 'react'

import { goTo, urlTo } from 'helpers/router.js'

import { useImperativeHandle, useMediaQueries, useMemo, useRedirect } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import ContentHolder from 'components/molecules/ContentHolder/ContentHolder.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Paper } from 'components/atoms/Paper'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { AccountTypeCard } from 'components/molecules/AccountTypeCard'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'

import { Questionnaire } from 'app/containers/Questionnaire'

import { useProductType } from './hooks'

const SelectProductType = forwardRef(function QuestionnaireSelectProductType(props, ref) {
  const { desktop } = useMediaQueries()

  const { mode, back, client, clientOnboarded, isSelected, handleSubmit } = useProductType({
    location: props.location,
  })

  useRedirect({
    to: () => goTo(urlTo('dashboard', {}, props.location?.query)),
    rule: !isSelected && clientOnboarded,
    isLoading: client.didInvalidate,
  })

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }))

  const rightLink = useMemo(() => {
    if (!desktop) {
      return null
    }

    if (mode === 'DASHBOARD') {
      return (
        <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}>
          <Link to={urlTo('dashboard')}>My Dashboard</Link>
        </Button>
      )
    }

    return (
      <Button mods={{ color: 'green', size: 'small', text: 'smaller' }}>
        <Link to={urlTo('logout')} classnameless>
          Back to the site
        </Link>
      </Button>
    )
  }, [desktop, mode])

  const leftLink = useMemo(() => {
    if (desktop) {
      return null
    }

    return <Link to={back ?? urlTo('logout')}>Back</Link>
  }, [desktop, back])

  return (
    <Questionnaire
      headerProps={{
        withProgress: false,
        withShadow: true,
        rightLink,
        leftLink,
      }}
      stepHeadlineProps={{
        headline: 'Select investor type',
        forceShow: true,
      }}
    >
      <ContentHolder>
        <Paper top={20} bottom={desktop ? 80 : 64}>
          <Paper bottom={desktop ? 40 : null}>
            <ColumnarLayout flexDirection={!desktop ? 'column' : null} mods={{ padding: 'modest' }}>
              <Column>
                <AccountTypeCard
                  shadowed
                  type="INDIVIDUAL"
                  icon="smartphone-stat-square"
                  title="Individual"
                  description="Invest with an ISA, a Personal Pension or a General Account"
                  buttonTitle="Get started"
                  onClick={handleSubmit}
                  data-test-id="individualTypeCard"
                />
              </Column>
              <Column>
                <AccountTypeCard
                  shadowed
                  type="BUSINESS"
                  icon="laptop-square"
                  title="Business"
                  description="Invest your company's surplus cash"
                  buttonTitle="Get started"
                  onClick={handleSubmit}
                  data-test-id="businessTypeCard"
                />
              </Column>
            </ColumnarLayout>
            {mode !== 'DASHBOARD' && (
              <Paper top={desktop ? 56 : 32}>
                <Link
                  to={urlTo('login', {}, props.location?.query)}
                  analyticsEvent={{
                    action: 'already_signed_up_login',
                    client_type: client.type,
                  }}
                >
                  <Typography lineHeight="small" color="inherit" align="center">
                    <Typo>Already have an account? Log in</Typo>
                  </Typography>
                </Link>
              </Paper>
            )}
          </Paper>
        </Paper>
      </ContentHolder>
    </Questionnaire>
  )
})

export { SelectProductType }
