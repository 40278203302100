import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { trackEvent } from 'helpers/analytics'

import { useMediaQueries, useCallback } from 'hooks'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'

const Title = ({ createPortfolioUrl }) => {
  const { desktop } = useMediaQueries()

  const handleClick = useCallback(() => {
    trackEvent(
      {
        action: 'add_portfolio_clicked',
      },
      { plugins: { 'google-analytics-v3': false } },
    )
  }, [])

  if (desktop) {
    return (
      <ColumnarLayout mods={{ 'align-columns': 'flex-end', flexWrap: 'wrap' }}>
        <Typography size={24} lineHeight="small">
          <Typo>Dashboard</Typo>
        </Typography>
        <Column size={1} mods={{ noShrink: true }}>
          <Paper top={4} bottom={4}>
            <Typography size={16} align="right" lineHeight="small">
              <Link to={createPortfolioUrl} onClick={handleClick} data-test-id="addNewPortfolioButton">
                <Nobr>+ New portfolio</Nobr>
              </Link>
            </Typography>
          </Paper>
        </Column>
      </ColumnarLayout>
    )
  }

  return (
    <Fragment>
      <Typography size={32} weight="semibold" lineHeight="small">
        <Typo>Dashboard</Typo>
      </Typography>
      <Paper top={4}>
        <Typography size={16} align="right" lineHeight="small">
          <Link to={createPortfolioUrl} onClick={handleClick} data-test-id="addNewPortfolioButton">
            <Nobr>+ New portfolio</Nobr>
          </Link>
        </Typography>
      </Paper>
    </Fragment>
  )
}

Title.propTypes = {
  createPortfolioUrl: PropTypes.string,
}

export { Title }
