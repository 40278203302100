import React from 'react'

import './Panel.css'

const Panel = ({ children, 'data-test-id': dataTestId }) => (
  <div className="Panel" data-test-id={dataTestId}>
    {children}
  </div>
)

export default Panel
