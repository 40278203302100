import { useActions, useCallback, useMediaQueries, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import { fetchAllowancesFx } from 'app/effector/allowances'

import { signDeclaration as signDeclarationActionCreator } from 'app/redux/actions/client'
import { showFailToast } from 'app/redux/actions/ui'

import { useCreatePortfolio } from 'app/pages/Dashboard/Goals/hooks'

import { manageTypes, regulatoryTypes } from 'constants/portfolio'

type Step = 'GENERAL' | 'RISK'

const steps: Record<Step, Step> = {
  GENERAL: 'GENERAL',
  RISK: 'RISK',
}

type UseSippDeclarationProps = {
  location: {
    query: Record<string, string>
  }
}

type UseSippDeclarationReturnProps = {
  step: Step
  isGeneralDeclarationChecked: boolean
  isRiskDeclarationChecked: boolean
  handleBack: () => void
  handleClose: () => void
  handleGeneralCheckboxChange: () => void
  handleRiskCheckboxChange: () => void
  handleContinue: () => Promise<void>
}

const useSippDeclaration = ({ location: { query = {} } }: UseSippDeclarationProps): UseSippDeclarationReturnProps => {
  const { desktop } = useMediaQueries()

  const createPortfolio = useCreatePortfolio()

  const [step, setStep] = useState(steps.GENERAL)
  const [isGeneralDeclarationChecked, setGeneralDeclarationChecked] = useState(false)
  const [isRiskDeclarationChecked, setRiskDeclarationChecked] = useState(false)

  const [signDeclaration] = useActions([signDeclarationActionCreator])

  const handleGeneralCheckboxChange = useCallback(() => {
    setGeneralDeclarationChecked(!isGeneralDeclarationChecked)
  }, [isGeneralDeclarationChecked])

  const handleRiskCheckboxChange = useCallback(() => {
    setRiskDeclarationChecked(!isRiskDeclarationChecked)
  }, [isRiskDeclarationChecked])

  const handleContinue = useCallback(async () => {
    if (step === steps.GENERAL) {
      trackEvent({ action: 'sipp_declaration1_continued' })
      setStep(steps.RISK)

      const scrollableElement = desktop
        ? document.querySelectorAll('.DesktopLayout-Element_scrollable')[0]
        : document.querySelectorAll('.MobileLayout-Content')[0]
      scrollableElement?.scrollTo?.(0, 0)
    }

    if (step === steps.RISK) {
      trackEvent({ action: 'sipp_declaration2_continued' })
      const stateAfterDeclarationSign = await signDeclaration({ declarationType: regulatoryTypes.SIPP })

      if (stateAfterDeclarationSign.client.error) {
        showFailToast()
        return
      }

      if (query.regulatoryType && query.manageType) {
        const newPortfolioData = [manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(query.manageType)
          ? {
              manageType: query.manageType,
              regulatoryType: query.regulatoryType,
              presetType: query.presetType,
              isDefault: Boolean(query.isDefault) && `${query.isDefault}` === 'true',
            }
          : {
              manageType: query.manageType,
              regulatoryType: query.regulatoryType,
            }
        await createPortfolio(newPortfolioData)
        fetchAllowancesFx()
        return
      }

      if (query.portfolioId) {
        const { portfolioId, ...restQuery } = query
        goTo(urlTo('dashboard.portfolio.finish', { id: query.portfolioId }, restQuery))
        return
      }

      fetchAllowancesFx()

      const next = query.next ?? urlTo('create-new-portfolio', null, { regulatoryType: regulatoryTypes.SIPP })

      goTo(next)
    }
  }, [step, desktop, createPortfolio, query, signDeclaration])

  const handleBack = useCallback(() => {
    if (step === steps.RISK) {
      setStep(steps.GENERAL)
    }
  }, [step])

  const handleClose = useCallback(() => {
    goTo(query.back ?? urlTo('dashboard'))
  }, [query])

  return {
    step,
    isGeneralDeclarationChecked,
    isRiskDeclarationChecked,
    handleClose,
    handleGeneralCheckboxChange,
    handleRiskCheckboxChange,
    handleBack,
    handleContinue,
  }
}

export { useSippDeclaration, steps, type Step }
