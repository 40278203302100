import { useSelector, useRedirect } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { showFailToast } from 'app/redux/actions/ui'
import { setPermission } from 'app/redux/api/third-party'
import { ApiError } from 'app/redux/models/errors'

type useGrantAccessData = {
  userFirstName?: string
  setAccess: (allow: boolean) => Promise<void>
}

type useGrantAccessProps = {
  query: {
    client_id?: string
    state?: string
    redirect_uri?: string
    auth_id?: string
    name?: string
  }
}

const useGrantAccess = ({ query }: useGrantAccessProps): useGrantAccessData => {
  const client = useSelector((state: { client: { access_token?: string } }) => state.client)

  const { client_id: clientId, state, redirect_uri: redirectUri, auth_id: authId, name } = query

  useRedirect({
    to: () => {
      goTo(urlTo('login', null, { ...query }))
    },
    rule: !client.access_token,
    isLoading: false,
  })

  const setAccess = async (allow: boolean): Promise<void> => {
    const response = await setPermission({ clientId, state, redirectUri, authId, allow })

    if (response instanceof ApiError) {
      showFailToast()
      return
    }

    const url = response?.redirect_uri

    const isAbsoluteUrl = url?.slice(0, 8) === 'https://'

    goTo(url, { hardGoTo: isAbsoluteUrl, unsafe: true, replace: true })
  }

  return {
    userFirstName: name,
    setAccess,
  }
}

export { useGrantAccess }
