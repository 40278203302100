import * as React from 'react'
import { Fragment } from 'react'

import { formatMinimalPercent } from 'helpers/money'

import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { LineChart } from 'components/atoms/LineChart'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type DistributionChartProps = {
  showActual: boolean
  name?: string
  actualWeight?: number
  targetWeight?: number
  distribution: Array<{ value: number; color: string }>
}

const DistributionChart = ({
  showActual,
  name = '',
  actualWeight = 0,
  targetWeight = 0,
  distribution,
}: DistributionChartProps): React.ReactElement => {
  const actualWeightsPercent = formatMinimalPercent(actualWeight)
  const targetWeightsPercent = formatMinimalPercent(targetWeight)
  const restWeightsPercent = formatMinimalPercent(100 - targetWeight)

  return (
    <Fragment>
      <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'flex-end' }}>
        <Column size={2}>
          {showActual ? (
            <Typography size={24} lineHeight="small" weight="semibold">
              <Typography tag="span" size={24} lineHeight="small" weight="semibold" color="minor">
                <Typo>{actualWeightsPercent}</Typo>{' '}
              </Typography>
              <Typo>→ {targetWeightsPercent}</Typo>
            </Typography>
          ) : (
            <Typography size={32} lineHeight="small" weight="semibold">
              <Typo>{targetWeightsPercent}</Typo>
            </Typography>
          )}
          <Paper top={4}>
            <Typography size={14} lineHeight="small">
              <Typo>{name} (Equity)</Typo>
            </Typography>
          </Paper>
        </Column>
        <Column size={1}>
          <Typography size={24} lineHeight="small" weight="semibold" align="right">
            <Typo>{restWeightsPercent}</Typo>
          </Typography>
          <Paper top={4}>
            <Typography size={14} lineHeight="small" align="right">
              <Nobr>
                <Typo>Rest of portfolio</Typo>
              </Nobr>
            </Typography>
          </Paper>
        </Column>
      </ColumnarLayout>
      <Paper top={12}>
        <LineChart values={distribution} />
      </Paper>
    </Fragment>
  )
}

export { DistributionChart }
