import { useUnit } from 'effector-react'

import { useState, useCallback, useSelector } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { type BankAccount } from 'app/effector/bank-accounts/models/bank'
import { $recurringPaymentsStore } from 'app/effector/recurringPayments'
import { type RecurringPaymentSubscription } from 'app/effector/recurringPayments'

import { type Portfolio } from 'app/redux/models/portfolio/types'
import { selectGoalTitle } from 'app/redux/selectors'

import { manageTypes } from 'constants/portfolio'

type UseRecurringPaymentEditProps = {
  portfolio: Portfolio
  location: {
    query?: {
      back?: string
    }
  }
}

type UseRecurringPaymentEditReturnProps = {
  recurringPayment?: RecurringPaymentSubscription
  portfolioTitle: string
  nominatedBankAccount: BankAccount
  showMandateDetails: boolean
  openMandateDetails: () => void
  closeMandateDetails: () => void
  handleBack: () => void
  handleStopPayment: () => void
}

const useRecurringPaymentEdit = ({
  portfolio,
  location,
}: UseRecurringPaymentEditProps): UseRecurringPaymentEditReturnProps => {
  const { getReccuringPaymentByPortfolioId } = useUnit($recurringPaymentsStore)
  const { nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)
  const portfolioTitle = useSelector((state) => selectGoalTitle(state, portfolio?.id))

  const recurringPayment = getReccuringPaymentByPortfolioId(portfolio.id)

  const [showMandateDetails, setShowMandateDetails] = useState(false)

  const openMandateDetails = (): void => {
    setShowMandateDetails(true)
  }
  const closeMandateDetails = (): void => {
    setShowMandateDetails(false)
  }

  const handleBack = useCallback(() => {
    if (portfolio.manage_type === manageTypes.CASH) {
      goTo(location?.query?.back ?? urlTo('dashboard.portfolio', { id: portfolio.id }))
      return
    }

    goTo(location?.query?.back ?? urlTo('dashboard.portfolio.options', { id: portfolio?.id }, location?.query ?? {}))
  }, [portfolio, location])

  const handleStopPayment = useCallback(() => {
    goTo(urlTo('portfolio.options.recurring-payment-stop', { id: portfolio?.id }, location?.query ?? {}))
  }, [portfolio, location])

  return {
    recurringPayment,
    portfolioTitle,
    nominatedBankAccount,
    showMandateDetails,
    handleBack,
    handleStopPayment,
    openMandateDetails,
    closeMandateDetails,
  }
}

export { useRecurringPaymentEdit }
