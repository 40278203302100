import axios from 'helpers/ajax'
import { features } from 'helpers/features'

import { ApiError } from 'app/redux/models/errors'

import { NotificationType } from '../models'

import type { Notification } from '../models'

const getNotifications = async (): Promise<Notification[] | ApiError> => {
  try {
    const { data } = await axios.get('notifications/panels/')

    const isIsaTransferPanelEnabled = features?.get('isa-transfer-panel')

    if (!isIsaTransferPanelEnabled) {
      return data.filter((notification) => notification.type !== NotificationType.ISA_TRANSFER_PANEL)
    }
    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const closeNotification = async (code: string): Promise<ApiError | { success: string }> => {
  try {
    const { data } = await axios.post(`notifications/close-panel/${code}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { getNotifications, closeNotification }
