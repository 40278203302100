import { useActions, useCallback, useEffect, useLoading, useMemo, useSelector, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { sendError } from 'helpers/errorLogging.js'
import { goTo, urlTo } from 'helpers/router.js'

import {
  fetchPortfolios as fetchPortfoliosActionCreator,
  updateOrCreate as updateOrCreateGoalActionCreator,
} from 'app/redux/actions/portfolios'
import { deleteClientAnswers as deleteClientAnswersActionCreator } from 'app/redux/actions/questionnaire'
import { showFailToast } from 'app/redux/actions/ui'

const useSorry = (portfolioIdString) => {
  const { isLoading, wait } = useLoading()
  const portfolioId = useMemo(() => parseInt(portfolioIdString, 10), [portfolioIdString])
  const portfolio = useSelector((state) => state.portfolios.list.get(portfolioId))
  const [allowedToMoveOn, setAllowedToMoveOnState] = useState(false)
  const [fetchPortfolios, deleteClientAnswers, updateOrCreateGoal] = useActions([
    fetchPortfoliosActionCreator,
    deleteClientAnswersActionCreator,
    updateOrCreateGoalActionCreator,
  ])

  const setAllowToMoveOn = useCallback(
    async (value) =>
      await new Promise((resolve) => {
        setAllowedToMoveOnState(value)

        setTimeout(resolve, 1)
      }),
    [setAllowedToMoveOnState],
  )

  useEffect(() => {
    if (!portfolio) {
      wait(fetchPortfolios())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    trackEvent({
      action: 'questionnaire_page_visit',
      step: 10,
      step_description: 'sorry_cannot_invest',
      regulatory_type: portfolio?.regulatory_type,
      version: 'default',
    })
  }, [portfolio])

  useEffect(() => {
    return () => {
      if (!allowedToMoveOn) {
        goTo(urlTo('sorry', null, { portfolioId }), { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedToMoveOn])

  const handleReset = useCallback(async () => {
    try {
      trackEvent({
        action: 'questionnaire_completed',
        text: 'Retake questionnaire',
        step: 10,
        step_description: 'sorry_cannot_invest',
        regulatory_type: portfolio?.regulatory_type,
        version: 'default',
      })

      await Promise.all([
        setAllowToMoveOn(),
        deleteClientAnswers(portfolio.id),
        updateOrCreateGoal(['initial_deposit'], { initial_deposit: null }, false, portfolio.id),
      ])

      goTo(urlTo('new-portfolio-tunnel', { id: portfolio.id }), { replace: true })
    } catch (error) {
      goTo(urlTo('dashboard'), { replace: true })
      sendError(error)
      showFailToast()
    }
  }, [deleteClientAnswers, setAllowToMoveOn, updateOrCreateGoal, portfolio])

  const handleSubmit = useCallback(async () => {
    await setAllowToMoveOn(true)

    trackEvent({
      action: 'questionnaire_completed',
      text: 'My Dashboard',
      step: 10,
      step_description: 'sorry_cannot_invest',
      regulatory_type: portfolio?.regulatory_type,
      version: 'default',
    })

    goTo(urlTo('dashboard'), { replace: true })
  }, [setAllowToMoveOn, portfolio])

  return {
    isLoading,
    portfolio,
    handleReset,
    handleSubmit,
  }
}

export { useSorry }
