import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

const Dialog = ({ open = false, content, buttons, onClose: handleClose }) => {
  const { desktop } = useMediaQueries()

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose}>
        <ModalContent width={28}>
          <Paper top={80} bottom={80} left={64} right={64}>
            <Typography size={16} align="center">
              {content}
            </Typography>
            <Paper top={56}>
              <ColumnarLayout mods={{ padding: 'nano' }}>
                {buttons.map((button, index) => (
                  <Column key={button?.key || index}>
                    {React.cloneElement(button, {
                      ...(button?.props || {}),
                      mods: { ...(button?.props?.mods || {}), size: 'big block' },
                    })}
                  </Column>
                ))}
              </ColumnarLayout>
            </Paper>
          </Paper>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <MobileLayout
        content={
          <AllCenter>
            <Paper top={48} bottom={48}>
              <Typography size={24} weight="semibold" align="center">
                {content}
              </Typography>
            </Paper>
          </AllCenter>
        }
        footer={
          <Fragment>
            {buttons.map((button, index) => (
              <MobileLayoutFooterButton key={button?.key || index}>{button}</MobileLayoutFooterButton>
            ))}
          </Fragment>
        }
      />
    </Modal>
  )
}

Dialog.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.node),
  onClose: PropTypes.func,
}

export { Dialog }
