import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import { NotificationScopeType } from 'app/effector/notifications/models'

import Inner from 'components/_old/Inner/Inner.jsx'

import { CardNew } from 'components/atoms/CardNew'
import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

import { TwoColumns } from 'components/molecules/TwoColumns'

import NotificationGroup from 'components/organisms/Notification/NotificationGroup'

import { DesktopFooter, MobileFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { AllowNotificationsModal } from 'app/pages/Dashboard/AllowNotificationsModal/AllowNotificationsModal.jsx'
import { OnboardingProgressBar } from 'app/pages/Dashboard/Goals//DIY/Portfolio/components/OnboardingProgressBar/OnboardingProgressBar'
import { AddFundsModal } from 'app/pages/Dashboard/Goals/AddFunds'
import ChangeGoalTitle from 'app/pages/Dashboard/Goals/ChangeGoalTitle/ChangeGoalTitle.jsx'
import { SavingsPlanLabel } from 'app/pages/Dashboard/Goals/components/SavingsPlanLabel'
import { SippTransferModal } from 'app/pages/Dashboard/Goals/components/SippTransferModal'
import { SippWithdrawModal } from 'app/pages/Dashboard/Goals/components/SippWithdrawModal'
import { usePortfolioActions } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'
import { OptionsModal } from 'app/pages/Dashboard/Goals/Options/OptionsModal'
import { PortfolioOpenedModal } from 'app/pages/Dashboard/Goals/PortfolioOpenedModal'
import { WithdrawFunds } from 'app/pages/Dashboard/Goals/WithdrawFunds/WithdrawFunds'
import { FinishRegistrationModal } from 'app/pages/Dashboard/SetupAccount/FinishRegistration/FinishRegistrationModal.jsx'
import { PresetBanner } from 'app/pages/Securities/SelfSelectedPreset/components/PresetBanner'

import { ChangeGoalTunnelProvider } from '../ChangeGoalTunnel/ChangeGoalTunnel.jsx'
import { ControlButtons } from '../ManagedPortfolio/components/ControlButtons'
import { HmrcCashLabel } from '../ManagedPortfolio/components/Overview/components/HmrcCashLabel'
import { SavingsPlanSetup } from '../SavingsPlan/SavingsPlanSetup'

import { PortfolioBalance } from './components/PortfolioBalance'
import { PortfolioHeader as PortfolioDesktopHeader } from './components/PortfolioDesktopHeader'
import { PortfolioLabels } from './components/PortfolioLabels'
import { PortfolioHeader as PortfolioMobileHeader } from './components/PortfolioMobileHeader'
import { useSelfSelectedPortfolio } from './hooks/useSelfSelectedPortfolio'
import { SelfSelectedPortfolioContent } from './SelfSelectedPortfolioContent'

import { regulatoryTypes } from 'constants/portfolio'

type SelfSelectedPortfolioProps = {
  params: {
    id: string
  }
  location: {
    query: {
      time: string
    }
  }
}

const SelfSelectedPortfolio = ({ params, location }: SelfSelectedPortfolioProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    title,
    portfolio,
    preset,
    quickStart,
    showFinishSavingsPlanBanner,
    showLumpSumBanner,
    showFirstTopupBanner,
    daysBeforePayment,
    percentageScaleData,
    allocationData,
    isHistoryLoading,
    handleBack,
  } = useSelfSelectedPortfolio({ id: params.id })
  const { actions, actionsMap } = usePortfolioActions(portfolio)

  const notificationsNode = (
    <NotificationGroup scope={NotificationScopeType.PORTFOLIO} portfolioId={portfolio?.id}>
      {(nodes) => <Paper top={desktop ? 48 : 16}>{nodes}</Paper>}
    </NotificationGroup>
  )

  const content = (
    <SelfSelectedPortfolioContent
      title={title}
      portfolio={portfolio}
      preset={preset}
      quickStart={quickStart}
      showFinishSavingsPlanBanner={showFinishSavingsPlanBanner}
      showLumpSumBanner={showLumpSumBanner}
      showFirstTopupBanner={!!showFirstTopupBanner}
      daysBeforePayment={daysBeforePayment}
      percentageScaleData={percentageScaleData}
      allocationData={allocationData}
      location={location}
      isHistoryLoading={isHistoryLoading}
    />
  )

  const controlButtonsNode = <ControlButtons portfolio={portfolio} actionsMap={actionsMap} />

  const portfolioBalanceNode = <PortfolioBalance portfolio={portfolio} />

  const onboardingProgressBarNode = <OnboardingProgressBar portfolio={portfolio} paddingTop={desktop ? 0 : 16} />

  const pendingHmrcCashNode = portfolio?.regulatory_type === regulatoryTypes.SIPP && (
    <Paper top={desktop ? 12 : 8}>
      <HmrcCashLabel amount={portfolio?.pending_hmrc_cash} />
    </Paper>
  )

  return (
    <ChangeGoalTunnelProvider>
      {desktop ? (
        <DesktopLayout
          header={<DesktopHeader />}
          content={
            <Inner twoColumns>
              <PortfolioDesktopHeader title={title} handleBack={handleBack} />
              {notificationsNode}
              <Paper top={40}>
                <TwoColumns
                  content={content}
                  sidebar={
                    <Fragment>
                      {onboardingProgressBarNode}
                      <CardNew
                        clickable={false}
                        style={{ border: 'none' }}
                        backgroundColor={palette['background-card']}
                      >
                        <div style={{ borderRadius: '16px 16px 0 0', overflow: 'hidden' }}>
                          <PresetBanner preset={preset} />
                        </div>
                        <Paper top={24} bottom={24} right={24} left={24}>
                          {portfolioBalanceNode}
                          <Paper top={24}>
                            <PortfolioLabels preset={preset} />
                          </Paper>
                          {pendingHmrcCashNode}
                          <Paper top={12}>
                            <SavingsPlanLabel portfolio={portfolio} />
                          </Paper>
                          <Paper top={24}>{controlButtonsNode}</Paper>
                        </Paper>
                      </CardNew>
                    </Fragment>
                  }
                  stickySidebar
                />
              </Paper>
            </Inner>
          }
          footer={<DesktopFooter />}
        />
      ) : (
        <MobileLayout
          header={<PortfolioMobileHeader title={title} handleBack={handleBack} />}
          content={
            <Fragment>
              {notificationsNode}
              <SideSpacesCompensator>
                <PresetBanner preset={preset} paddingRight={15.77} />
              </SideSpacesCompensator>
              {onboardingProgressBarNode}
              <Paper top={desktop ? 24 : 16}>{portfolioBalanceNode}</Paper>
              <Paper top={desktop ? 24 : 16}>
                <PortfolioLabels preset={preset} />
              </Paper>
              {pendingHmrcCashNode}
              <Paper top={12}>
                <SavingsPlanLabel portfolio={portfolio} />
              </Paper>
              <Paper top={40}>{content}</Paper>
              <MobileFooter />
            </Fragment>
          }
          footer={controlButtonsNode}
        />
      )}

      <AddFundsModal goal={portfolio} />
      <OptionsModal portfolio={portfolio} portfolioActions={actions} />
      <WithdrawFunds portfolioId={portfolio?.id} />
      <ChangeGoalTitle goalId={portfolio?.id} />
      <PortfolioOpenedModal portfolioId={portfolio?.id} />
      <SavingsPlanSetup portfolio={portfolio} />
      <FinishRegistrationModal />
      <AllowNotificationsModal module="dashboard.portfolio" />
      <SippWithdrawModal />
      <SippTransferModal />

      <GatewayDest name="onboarding-modals" />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
      <GatewayDest name="withdraw-funds" />
      <GatewayDest name="change-goal-title" />
    </ChangeGoalTunnelProvider>
  )
}

export { SelfSelectedPortfolio }
