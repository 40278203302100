import axios from 'axios'
import qs from 'qs'

import { ApiError } from 'app/redux/models/errors'

type StepDTO = {
  header: string
  text: string
  image?: string
  backgroundImage?: string
  bottomImage?: string
  actionButtonText?: string
  actionButtonUrl?: string
}

type StoryDTO = {
  id: number
  text: string
  image: string
  wideImage: string
  group: string
  steps: StepDTO[]
}

const isProduction = location.hostname === 'investengine.com'

const strapiUrl = isProduction ? 'https://cms.investengine.com' : 'https://cms.rscwealth.com'
const strapiApiKey = isProduction
  ? 'a03aeacbef2c5b4247b9ee8e83f42e7b94799effbde54565b231c2aa717991e932ac0c2130f0cd0c0022c335f4dda127ba9d55d3254d73316fe7fe88addeae7af58cb54f515f96adc59cd9bfdbacd7ec1d1eceb176095ea8c66ff047fa4a6a9996357a36885e2f71df9b923c9228e1fb1d83e751efb4f3f10f72eb7612a4732f'
  : '31c953b54420278c1c8e31062069d1b405d041335d6d4434eb9fa353f67c54f758e6676a765ed0f6371e63d10be7b25d906e3bbabfa20b1f5bfd49f14271562056c3bcd6d9b62f240e7315e721b09dbe48646a59659022b939323259019abf5e4934563712c312a0b1cfe4773b655e1f8c163358c1bb1682c6f7eabd9c648338'

const config = {
  headers: {
    Authorization: `Bearer ${strapiApiKey}`,
  },
}

const getStories = async ({ groups = [] }: { groups: string[] }): Promise<StoryDTO[] | ApiError> => {
  const query = {
    filters: {
      $or: groups.map((group) => ({
        group: {
          $eq: group,
        },
      })),
    },
    sort: ['order', 'id:desc'],
    populate: ['image', 'wideImage', 'steps', 'steps.image', 'steps.backgroundImage', 'steps.bottomImage'],
  }

  try {
    const url = `${strapiUrl}/api/user-stories/?${qs.stringify(query)}`

    const { data } = await axios.get(url, config)

    const strippedData = data.data.map((item) => ({
      text: item.attributes.text,
      image: item.attributes.image.data.attributes.url,
      wideImage: item.attributes?.wideImage?.data?.attributes?.url,
      id: item.id,
      steps: item.attributes.steps.map((step) => ({
        header: step.header,
        text: step.text,
        image: step.image?.data?.attributes?.url,
        actionButtonText: step.actionButtonText,
        actionButtonUrl: step.actionButtonUrl,
        bottomImage: step.bottomImage?.data?.attributes?.url,
        backgroundImage: step.backgroundImage?.data?.attributes?.url,
      })),
    }))

    return strippedData
  } catch (error) {
    return new ApiError(error)
  }
}

const prepareCollectionStories = (data): StoryDTO[] => {
  return data.map((item) => ({
    text: item.attributes.text,
    image: item.attributes.image.data.attributes.url,
    id: item.id,
    group: item.attributes.group,
    steps: item.attributes.steps.map((step) => ({
      header: step.header,
      text: step.text,
      image: step.image?.data?.attributes?.url,
      actionButtonText: step.actionButtonText,
      actionButtonUrl: step.actionButtonUrl,
      bottomImage: step.bottomImage?.data?.attributes?.url,
      backgroundImage: step.backgroundImage?.data?.attributes?.url,
    })),
  }))
}

const getSecurityCollectionsStories = async (): Promise<StoryDTO[] | ApiError> => {
  const query = {
    sort: ['order', 'id:desc'],
    populate: ['image', 'steps', 'steps.image', 'steps.backgroundImage', 'steps.bottomImage'],
    'pagination[pageSize]': '5000',
  }

  try {
    const url = `${strapiUrl}/api/security-collectionsstories/?${qs.stringify(query)}`

    const { data } = await axios.get(url, config)

    const strippedData = prepareCollectionStories(data.data)

    return strippedData
  } catch (error) {
    return new ApiError(error)
  }
}

const getPresetCollectionsStories = async (): Promise<StoryDTO[] | ApiError> => {
  const query = {
    sort: ['order', 'id:desc'],
    populate: ['image', 'steps', 'steps.image', 'steps.backgroundImage', 'steps.bottomImage'],
  }

  try {
    const url = `${strapiUrl}/api/portfolio-collectionsstories/?${qs.stringify(query)}`

    const { data } = await axios.get(url, config)

    const strippedData = prepareCollectionStories(data.data)

    return strippedData
  } catch (error) {
    return new ApiError(error)
  }
}

export { type StepDTO, type StoryDTO, getStories, getSecurityCollectionsStories, getPresetCollectionsStories }
