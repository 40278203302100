import React from 'react'

import classNames from 'classnames/dedupe'

import Icon from 'components/_old/Icon/Icon.jsx'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './Chip.css'

type ChipProps = {
  children: React.ReactNode | React.ReactNodeArray
  icon?: string
  iconPosition?: 'left' | 'right'
  className?: string
  disabled?: boolean
  onClick?: () => void
  'data-test-id'?: string
}

const Chip = ({
  children,
  icon,
  iconPosition = 'left',
  className,
  disabled,
  onClick,
  'data-test-id': dataTestId,
}: ChipProps): React.ReactElement => {
  const classes = classNames(className, 'Chip', {
    Chip_disabled: disabled,
  })

  const content = icon ? (
    <ItemWithIcon
      partClassNames={{
        icon: 'Chip-Icon',
        content: 'Chip-Content',
      }}
      space={1}
      icon={<Icon className="Chip-Icon" type={icon} size={16} color="inherit" />}
      content={children}
      contentVerticalAlign="center"
      iconVerticalAlign="center"
      iconPosition={iconPosition}
    />
  ) : (
    children
  )

  return (
    <Paper top={4} right={8} bottom={4} left={12} className={classes}>
      <Typography size={14} lineHeight="small" color="inherit" onClick={onClick} data-test-id={dataTestId} inline>
        {content}
      </Typography>
    </Paper>
  )
}

export { Chip }
