import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

export class PortfolioSimpleStatsItem extends Entity {
  constructor() {
    super()

    /** @type {?number} */
    this.total = null
    /** @type {?number} */
    this.year = null
    /** @type {?number} */
    this.six_months = null
    /** @type {?number} */
    this.month = null
    /** @type {?number} */
    this.week = null
  }

  /** @override */
  getRules() {
    return {
      total: [[rules.float]],
      year: [[rules.float]],
      six_months: [[rules.float]],
      month: [[rules.float]],
      week: [[rules.float]],
    }
  }
}
