import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import './FieldLine.css'

const FieldLine = ({ children, className, label, layout = 'horizontal', 'data-test-id': dataTestId }) => {
  const mediaQueries = useMediaQueries()

  const classes = classNames(
    className,
    'FieldLine',
    `FieldLine_${layout}`,
    getMediaQuieryClasses('FieldLine', mediaQueries),
  )

  if (typeof children === 'string') {
    children = <Text color="black">{children}</Text>
  }

  return (
    <div className={classes} data-test-id={dataTestId}>
      <Text className="FieldLine-Label" color="darkgray" data-test-id="question" small>
        <Typo>{label}</Typo>
      </Text>
      <div className="FieldLine-Value" data-test-id="answer">
        {children}
      </div>
    </div>
  )
}

FieldLine.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
  'data-test-id': PropTypes.string,
}

export { FieldLine }
