import React from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries, useCallback, useSelector } from 'hooks'

import { $owner } from 'app/effector/contacts'

import { isClientNewOrNotCompleted as selectIsClientNewOrNotCompleted } from 'app/redux/selectors'

import { Gateway } from 'components/atoms/Gateway'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import { ISA } from 'app/pages/CreateNewPortfolio/SelectPortfolioType/hooks/flows/cards.js'

import { RegulatoryTypeCard } from './RegulatoryTypeCard'

import { regulatoryTypes } from 'constants/portfolio'

type RegulatoryTypeModalProps = {
  open: boolean
  zIndex?: number
  onSubmit: (regulatoryType: string) => void
  onClose: () => void
}

const RegulatoryTypeModal = ({
  open,
  onSubmit,
  onClose,
  zIndex = 1001,
}: RegulatoryTypeModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const allIsaPortfolios = useSelector((state) =>
    state.portfolios.list.filterByShape({ regulatory_type: regulatoryTypes.ISA }),
  )
  const visibleIsaPortfolios = allIsaPortfolios.getVisiblePortfolios()
  const hasIsa = !!visibleIsaPortfolios.length
  const cardIsaDescription = hasIsa ? ISA.descriptionWithActiveIsa : ISA.description

  const isClientNewOrNotCompleted = useSelector(selectIsClientNewOrNotCompleted)
  const owner = useUnit($owner)
  const isSippAge = owner?.age < 75
  const canOpenSipp = isClientNewOrNotCompleted || isSippAge

  const handleSelectISA = useCallback(() => {
    onSubmit(regulatoryTypes.ISA)
  }, [onSubmit])

  const handleSelectGIA = useCallback(() => {
    onSubmit(regulatoryTypes.GIA)
  }, [onSubmit])

  const handleSelectSIPP = useCallback(() => {
    onSubmit(regulatoryTypes.SIPP)
  }, [onSubmit])

  const content = (
    <ModalContent width={36} style={{ minHeight: 600 }} data-test-id="regulatoryTypeModal">
      <Paper top={desktop ? 20 : 0} bottom={desktop ? 20 : 0} left={desktop ? 24 : 0} right={desktop ? 24 : 0}>
        <NavigationBar rightPartText="Cancel" onRightPartClick={onClose} plain>
          <Typography size={desktop ? 20 : 16} lineHeight="small" weight="semibold">
            Select an account
          </Typography>
        </NavigationBar>
      </Paper>
      <Paper top={desktop ? 80 : 40} bottom={desktop ? 80 : 16} left={desktop ? 96 : 16} right={desktop ? 96 : 16}>
        <Paper bottom={12}>
          <RegulatoryTypeCard
            regulatoryType={regulatoryTypes.ISA}
            title="ISA"
            description={cardIsaDescription}
            onClick={handleSelectISA}
            data-test-id="isaCard"
          />
        </Paper>
        {canOpenSipp && (
          <Paper bottom={12}>
            <RegulatoryTypeCard
              regulatoryType={regulatoryTypes.SIPP}
              title="Personal pension"
              description="Open a Self Invested Personal Pension. Grow your retirement savings tax-efficiently with an InvestEngine Personal Pension. Minimums apply."
              onClick={handleSelectSIPP}
              data-test-id="sippCard"
            />
          </Paper>
        )}
        <RegulatoryTypeCard
          regulatoryType={regulatoryTypes.GIA}
          title="General account"
          description="Invest with our flexible General Account"
          onClick={handleSelectGIA}
          data-test-id="giaCard"
        />
      </Paper>
    </ModalContent>
  )

  return (
    <Gateway into="global">
      <Modal open={open} zIndex={zIndex} close={null} onClose={onClose}>
        {content}
      </Modal>
    </Gateway>
  )
}

export { RegulatoryTypeModal }
