import React from 'react'

import classNames from 'classnames/dedupe'

import { isDarkTheme } from 'helpers/palette'

import './Skeleton.css'

type SkeletonProps = {
  children: React.DetailedReactHTMLElement<React.HTMLAttributes<HTMLElement>, HTMLElement>
  className?: string
  tag?: keyof JSX.IntrinsicElements
  shown: boolean
  inline?: boolean
  mix?: boolean
  overflow?: 'hidden' | null
  'data-test-id'?: string
  shownProps?: Record<string, unknown>
  borderRadius?: {
    topRight?: 6 | 0
    bottomRight?: 6 | 0
    topLeft?: 6 | 0
    bottomLeft?: 6 | 0
  }
  [key: string]: unknown
}

function Skeleton({
  children,
  className,
  tag,
  shown,
  inline = false,
  mix = false,
  'data-test-id': dataTestId = 'skeleton',
  shownProps = {},
  borderRadius = {
    topRight: 6,
    bottomRight: 6,
    topLeft: 6,
    bottomLeft: 6,
  },
  ...rest
}: SkeletonProps): React.ReactElement {
  if (!shown) {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        className: classNames(className, children?.props?.className ?? ''),
        'data-test-id': children?.props?.['data-test-id'] ?? dataTestId,
        ...rest,
      })
    }

    return children
  }

  const Tag = tag ?? 'div'
  const classes = classNames(className, 'Skeleton', {
    Skeleton_inline: inline,
    Skeleton_darkTheme: isDarkTheme(),
    [`Skeleton_borderTopRightRadius_${borderRadius?.topRight}`]: borderRadius?.topRight,
    [`Skeleton_borderBottomRightRadius_${borderRadius?.bottomRight}`]: borderRadius?.bottomRight,
    [`Skeleton_borderTopLeftRadius_${borderRadius?.topLeft}`]: borderRadius?.topLeft,
    [`Skeleton_borderBottomLeftRadius_${borderRadius?.bottomLeft}`]: borderRadius?.bottomLeft,
  })

  if (mix) {
    return React.cloneElement(children, {
      className: classNames(classes, children?.props?.className),
      'data-test-id': children?.props?.['data-test-id'] ?? dataTestId,
      ...rest,
      ...shownProps,
    })
  }

  return (
    <Tag className={classes} data-test-id="dataTestId" {...rest} {...shownProps}>
      {children}
    </Tag>
  )
}

export { Skeleton, type SkeletonProps }
