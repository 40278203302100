import React from 'react'

import { useEffect, useSelector, useMemo } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import { selectSelectedGoal } from 'app/redux/selectors'

import { presetTypes } from 'constants/goal'

const ReviewPortfolio = ({
  children,
  location,
  params,
  parentModule,
  prevStep,
  ...otherProps
}): React.ReactElement | null => {
  const { selectedGoal } = useSelector((state: any) => ({
    selectedGoal: selectSelectedGoal(state),
  }))
  const { presets } = useSelector((state: any) => state.portfolios)
  const locationParts = location.pathname.split('/').filter((el) => !!el)
  const presetPartIndex = locationParts.findIndex((part) =>
    Object.values(presetTypes)
      .map((type) => type.toLowerCase())
      .includes(part),
  )
  const lastParts = locationParts.filter((_, index) => presetPartIndex !== -1 && index > presetPartIndex)

  const recommendedPresetNumber = useMemo(() => {
    const presetIndex: number =
      presets[selectedGoal?.preset_type]?.findIndex((preset) => preset.id === selectedGoal.preset_recommended) ?? 0

    return presetIndex + 1
  }, [selectedGoal, presets])

  useEffect(() => {
    // trying to make an auto-redirect if the preset is not specified
    if (presetPartIndex === -1 && selectedGoal?.preset_type) {
      const path = [parentModule, 'review-portfolio', selectedGoal?.preset_type.toLowerCase()]

      if (lastParts.length > 0) {
        path.push(lastParts.join('.'))
      }

      trackEvent({
        action: 'questionnaire_page_visit',
        step: 10,
        step_description: 'you_selected_portfolio_preset',
        regulatory_type: selectedGoal?.regulatory_type,
        preset_recommended: recommendedPresetNumber,
        version: 'default',
      })

      goTo(urlTo(path.join('.'), params), { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoal])

  return children ? React.cloneElement(children, { parentModule, prevStep, ...otherProps }) : null
}

export { ReviewPortfolio }
