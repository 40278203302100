import React from 'react'

import { useMediaQueries } from 'hooks'

import { formatPercent } from 'helpers/money'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { GroupOfAssets } from './GroupOfAssets'

import type { AllocationItem } from '../../../../types'

import './Allocations.css'

type AllocationsProps = {
  onSecurityClick: (securityId: string) => void
  currentPeriod: string
  allocationData: AllocationItem[]
}

const colors = {
  Equities: 'stocks',
  Bonds: 'bonds',
  Alternatives: 'alternatives',
  Cash: 'additional',
}

const Allocations = ({ onSecurityClick, currentPeriod, allocationData }: AllocationsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Paper>
      <ColumnarLayout mods={{ padding: 'no' }}>
        {allocationData.map((item) => (
          <Column size={desktop ? 0 : 1} key={item.type}>
            <Paper right={desktop ? 24 : 0} bottom={desktop ? 0 : 16}>
              <Typography
                size={desktop ? 24 : 18}
                lineHeight="small"
                weight="semibold"
                color={colors[item.type]}
                align="center"
              >
                <Typo>{formatPercent(item.weight / 100, 1)}</Typo>
                <Typography
                  className={desktop ? 'GroupOfAssetsTitle_desktop' : 'GroupOfAssetsTitle_phone'}
                  tag={desktop ? 'span' : 'div'}
                  size={desktop ? 14 : 12}
                  lineHeight="small"
                  align="center"
                >
                  <Typo>{item.type}</Typo>
                </Typography>
              </Typography>
            </Paper>
          </Column>
        ))}
      </ColumnarLayout>
      {allocationData.map((item) => (
        <Paper top={desktop ? 56 : 32} key={item.type}>
          <GroupOfAssets groupOfAssets={item} onSecurityClick={onSecurityClick} currentPeriod={currentPeriod} />
        </Paper>
      ))}
      <Paper top={56}>
        <Typography size={14} color="additional">
          <Typo>
            Numbers might not add up to 100 percent due to rounding. The numbers above include all trades as soon as
            they have been executed. You can check the status of any trade on your account on the Transactions screen.
          </Typo>
        </Typography>
      </Paper>
    </Paper>
  )
}

export { Allocations }
