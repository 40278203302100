import React from 'react'

import { Accounts } from './Accounts.jsx'

export default {
  isNewLayout: true,
  noGlobalPreloader: true,
  module: 'accounts',
  component: function Component(props) {
    return <Accounts {...props} />
  },
  childRoutes: [
    {
      module: 'changes-to-the-agreement',
      path: 'changes-to-the-agreement/',
    },
  ],
}
