import React, { Fragment } from 'react'

import { useMediaQueries, useEffect, useState } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

type SharingTitleEditModalProps = {
  open: boolean
  title: string
  description: string
  onClose: () => void
  onSave: () => Promise<void>
  onChangeTitle: (value: string) => void
  onChangeDescription: (value: string) => void
}

const SharingTitleEditModal = ({
  open,
  onClose: handleClose,
  title,
  onChangeTitle: handleTitleChange,
  onSave: handleSave,
  description,
  onChangeDescription: handleDescriptionChange,
}: SharingTitleEditModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const [errorMessage, setErrorMessage] = useState('')
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('')

  useEffect(() => {
    let message = ''

    if (!title) message = `Name can't be empty`
    if (title?.length >= 30) message = 'Name must be less than 30 characters'
    setErrorMessage(message)
  }, [title])

  useEffect(() => {
    let message = ''

    if (description?.length >= 1500) message = 'Description must be less than 1500 characters'
    setDescriptionErrorMessage(message)
  }, [description])

  const titleInputNode = (
    <Label errorMessage={errorMessage} valid={!errorMessage}>
      Portfolio name
      <Input
        mods={{ size: 'bigger' }}
        onChange={(event, value) => {
          handleTitleChange(value)
        }}
      >
        {title}
      </Input>
    </Label>
  )

  const descriptionInputNode = (
    <Label errorMessage={descriptionErrorMessage} valid={!descriptionErrorMessage}>
      Description
      <Input
        type="textarea"
        mods={{ size: 'bigger' }}
        rows={6}
        onChange={(event, value) => {
          handleDescriptionChange(value)
        }}
      >
        {description}
      </Input>
    </Label>
  )

  if (desktop) {
    return (
      <Modal open={open} close={null}>
        <ModalContent width={36}>
          <Paper top={8} bottom={56} right={24} left={24}>
            <NavigationBar onRightPartClick={handleClose} rightPartText="Cancel">
              <Paper top={12}>
                <Typography size={20} weight="semibold" align="center" lineHeight="small">
                  <Typo>Portfolio description</Typo>
                </Typography>
              </Paper>
            </NavigationBar>
          </Paper>
          <Paper left={96} right={96} bottom={120}>
            {titleInputNode}
            <Paper top={32}>{descriptionInputNode}</Paper>
            <Paper top={32}>
              <Button mods={{ size: 'big block' }} onClick={handleSave} disabled={!!errorMessage}>
                Save
              </Button>
            </Paper>
          </Paper>
        </ModalContent>
      </Modal>
    )
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <MobileLayout
        header={
          <NavigationBar
            leftPartText="Close"
            onLeftPartClick={handleClose}
            onRightPartClick={handleSave}
            rightPartText="Save"
          >
            Portfolio description
          </NavigationBar>
        }
        content={
          <Fragment>
            {titleInputNode}
            {descriptionInputNode}
          </Fragment>
        }
      />
    </Modal>
  )
}

export { SharingTitleEditModal }
