import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { Typography } from 'components/atoms/Typography'
import { Paper } from 'components/atoms/Paper'

import './GreyTabs.css'

const GreyTabs = ({ tabs, fullWidth, large, onChange: handleChange }) => {
  const { desktop } = useMediaQueries()

  return (
    <div className={classNames('GreyTabs', { GreyTabs_mobile: !desktop, GreyTabs_fullWidth: fullWidth })}>
      {tabs.map(function (tab, index) {
        const tabClasses = classNames('GreyTabs-Tab', {
          'GreyTabs-Tab_active': tab.isActive,
          'GreyTabs-Tab_large': large,
        })

        return (
          <Paper
            className={tabClasses}
            top={desktop ? 8 : 4}
            bottom={desktop ? 8 : 4}
            left={24}
            right={24}
            key={tab.id}
            tabIndex="0"
            onKeyDown={(evt) => evt.keyCode === 13 && handleChange(tab)}
            onClick={() => handleChange(tab)}
          >
            <Typography
              className="GreyTabs-Tab-Text"
              color={tab.isActive ? 'default' : 'additional'}
              lineHeight="small"
              size={desktop ? 16 : 14}
              data-test-id={tab['data-test-id'] ? tab['data-test-id'] : `${tab.id}Tab`}
            >
              {tab.title}
            </Typography>
          </Paper>
        )
      })}
    </div>
  )
}

GreyTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      'data-test-id': PropTypes.string,
    }),
  ),
  large: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
}

export { GreyTabs }
