import React from 'react'

import { useMediaQueries } from 'hooks'

import { Mobile } from './Mobile'
import { Desktop } from './Desktop'

const SecurityInfo = React.memo((props) => {
  const { desktop } = useMediaQueries()

  return desktop ? <Desktop {...props} /> : <Mobile {...props} />
})

SecurityInfo.displayName = 'SecurityInfo'

export { SecurityInfo }
