import React, { Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useUnit } from 'effector-react'

import compose from 'helpers/compose.js'
import { isFieldsDisabledByRuleset } from 'helpers/field.js'
import { combineErrors, backendErrorsToObj, bulkValidate, validate } from 'helpers/validation.js'
import { typo } from 'helpers/typograph'
import { hardCodedCountries } from 'helpers/hardCodedCounties'

import { urlTo } from 'helpers/router.js'
import axios from 'helpers/ajax'

import Form, { Fieldset } from 'components/_old/Form/Form.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import DisableIfNeeded from 'components/_old/DisableIfNeeded/DisableIfNeeded.jsx'
import Delete from 'components/molecules/Delete/Delete.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Link from 'components/_old/Link/Link.jsx'
import InlineHelp from 'components/_old/InlineHelp/InlineHelp.jsx'
import ModalContent, {
  ModalContentHeadline,
  ModalContentBody,
  ModalContentButtons,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Label from 'components/_old/Label/Label.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import Combobox from 'components/_old/Combobox/Combobox.jsx'

import { showSupportModal } from 'app/redux/actions/ui'

import {
  changeCompanyAddressField,
  receiveCompanyCurrentAddress,
  partialResetCompany,
  changeCompanyField,
} from 'app/effector/company'
import { $dictsStore } from 'app/effector/dicts'

const CompanyForm = ({ company, desktop, clientApproved, handleSubmit, showSupportModal }) => {
  const { dicts } = useUnit($dictsStore)
  const fieldLockRules = (dicts.company_lock_rules || []).map((rule) => {
    return {
      ...rule,
      fields: rule.fields.map((field) => {
        if (field === 'business_type') return 'business_types'
        return field
      }),
    }
  })

  // quick fix for https://app.asana.com/0/1202304843784991/1202635259316452/f
  const countries = hardCodedCountries // dicts.countries

  const handleFieldChange = useCallback((field, value) => {
    if (field === 'lei_number' && (value || '').length === 0) {
      value = null
    }

    changeCompanyField({ [field]: value })
  }, [])

  const changeCurrentAddress = useCallback(
    (address) => {
      const [currentAddress, currentAddressIndex] = company.addresses.getCurrentAddress()

      if (currentAddress && !currentAddress.id) {
        changeCompanyAddressField({ index: currentAddressIndex, field: address })
      } else {
        receiveCompanyCurrentAddress(address)
      }
    },
    [company.addresses],
  )

  const handleSearchChange = useCallback(
    async ({ value: company = {} } = {}) => {
      if (company.title) {
        handleFieldChange('name', company.title)
      } else {
        handleFieldChange('name', null)
      }

      if (company.number) {
        handleFieldChange('uk_number', company.number)
        const { data } = await axios(`companies_house/companies/${company.number}/`)

        partialResetCompany()

        if (data.business_types?.length) {
          handleFieldChange('business_types', data.business_types)
        }

        changeCurrentAddress(data.address)
      }
    },
    [handleFieldChange, changeCurrentAddress],
  )

  const handleSearchBlur = useCallback(
    (textValue) => {
      handleFieldChange('name', textValue)
    },
    [handleFieldChange],
  )

  const handleBusinessTypeSearchChange = useCallback(
    (index, { value: business_type = {} } = {}) => {
      const { business_types } = company
      const nextBusinessTypes = [...business_types]
      nextBusinessTypes[index] = business_type.code ? business_type : null

      handleFieldChange('business_types', nextBusinessTypes)
    },
    [company, handleFieldChange],
  )

  const handleBusinessTypeDelete = useCallback(
    (indexToDelete) => {
      const { business_types } = company
      const nextBusinessTypes = [...business_types].filter((_value, index) => index !== indexToDelete)

      handleFieldChange('business_types', nextBusinessTypes)
    },
    [company, handleFieldChange],
  )

  const handleAddBusinessType = useCallback(
    (event) => {
      event.preventDefault()
      const { business_types } = company
      handleFieldChange('business_types', [...business_types, null])
    },
    [company, handleFieldChange],
  )

  const business_types = company.business_types.length < 1 ? [{}] : company.business_types
  const businessTypesValidation = business_types.reduce(
    (types, business_type, index) => ({
      ...types,
      [`${index}_business_type`]: {
        rules: [business_type?.code],
        errors: ['Type of business can’t be empty'],
      },
    }),
    {},
  )

  const [currentAddress] = company.addresses.getCurrentAddress()

  const validation = combineErrors(
    {
      name: { rules: [company.name], errors: ['Company name can’t be empty'] },
      uk_number: {
        rules: [company.uk_number, (company.uk_number || '').length >= 6],
        errors: ['Company number can’t be empty', 'Company number should be at least 6 characters long'],
      },
      ...businessTypesValidation,
      lei_number: {
        rules: [(company.lei_number || '').length === 0 || (company.lei_number || '').length === 20],
        errors: [typo('Legal entity identifier number should be 20 characters long')],
      },
      address: {
        rules: [currentAddress?.country && currentAddress.city && currentAddress.street && currentAddress.postcode],
        errors: ['We could not get a full address for your company'],
      },
    },
    backendErrorsToObj(company.error),
  )

  const isFieldsDisabled = isFieldsDisabledByRuleset({
    fields: ['name', 'uk_number', 'business_types', 'lei_number'],
    rules: fieldLockRules,
    model: company,
  })

  const businessTypesString = useCallback(() => {
    return company.business_types.map((type) => (type ? type.title : '')).join(', ')
  }, [company.business_types])

  const addressIsValid = validate(validation.address.rules)
  const getAddressString = useCallback(() => {
    try {
      return currentAddress.getAddressString(countries)
    } catch (error) {
      return null
    }
  }, [currentAddress, countries])

  if (company.addresses.length < 1) {
    return null
  }

  const changeAddressUrl = urlTo('dashboard.user-profile.business-info.change-company-address')

  return (
    <SubmitOnEnter>
      <Form>
        <Fieldset marginNext>
          <LabelInlineStyle
            labelText="Registered office address"
            dataTestId="businessInfoAddressLabel"
            multiline={!desktop}
          >
            <Text style={{ lineHeight: '1.6rem', paddingTop: '0.2rem' }} color={addressIsValid ? 'black' : 'red'} block>
              {addressIsValid ? getAddressString() : 'We could not get a full address for your company'}
            </Text>
            <Link to={changeAddressUrl} data-test-id="openChangeAddressModal">
              <Text small>
                {clientApproved ? 'Moved? Update registered office address here' : 'Update registered office address'}
              </Text>
            </Link>
          </LabelInlineStyle>

          <DisableIfNeeded field="name" model={company} rules={fieldLockRules}>
            <Validate rules={validation.name.rules}>
              <LabelInlineStyle
                labelText="Company name"
                valueIfDisabled={company.name}
                errorMessages={validation.name.errors}
                multiline={!desktop}
                dataTestId="businessInfoNameLabel"
              >
                <Combobox
                  name="search-company-by-name"
                  type="text"
                  remote="companies_house/companies/search/"
                  selectValue={(option) => {
                    option = option || {}
                    return {
                      value: option,
                      key: option.number,
                      name: option.title,
                    }
                  }}
                  onChange={handleSearchChange}
                  onBlur={handleSearchBlur}
                  tabIndex={1}
                  data-test-id="businessInfoNameInput"
                >
                  {typeof company.name === 'string' ? { title: company.name, number: company.uk_number } : company.name}
                </Combobox>
              </LabelInlineStyle>
            </Validate>
          </DisableIfNeeded>

          <DisableIfNeeded field="uk_number" model={company} rules={fieldLockRules}>
            <Validate rules={validation.uk_number.rules}>
              <LabelInlineStyle
                labelText="Company number"
                valueIfDisabled={company.uk_number}
                errorMessages={validation.uk_number.errors}
                multiline={!desktop}
                dataTestId="businessInfoUkNumberLabel"
              >
                <Input
                  name="uk_number"
                  mods={{ size: 'bigger' }}
                  type="text"
                  onChange={(_event, value) => {
                    if (typeof value === 'string') {
                      value = value.toUpperCase()
                    }

                    handleFieldChange('uk_number', value)
                  }}
                  tabIndex={2}
                  data-test-id="businessInfoUkNumberInput"
                >
                  {company.uk_number}
                </Input>
              </LabelInlineStyle>
            </Validate>
          </DisableIfNeeded>

          <DisableIfNeeded field="business_types" rules={fieldLockRules} model={company}>
            <LabelInlineStyle
              labelText="Type of business"
              valueIfDisabled={businessTypesString()}
              multiline={!desktop}
              dataTestId="businessBusinessTypesLabel"
              preventFocusOnLabelClick={!desktop}
            >
              {business_types.map((business_type = {}, index) => {
                // TODO: Use key={business_type.uuid} after models become immutable
                return (
                  <Validate key={business_type.code || index} rules={validation[`${index}_business_type`].rules}>
                    <Label
                      errorMessages={validation[`${index}_business_type`].errors}
                      data-test-id={`aboutBusinessBusinessType${index}Label`}
                    >
                      <Delete onDelete={() => handleBusinessTypeDelete(index)} deletable={index > 0}>
                        <Combobox
                          name="business_type"
                          type="text"
                          remote="business_types/search/"
                          selectValue={(option) => {
                            option = option || {}
                            return {
                              value: option,
                              key: option.code,
                              name: option.title,
                            }
                          }}
                          onChange={(value) => handleBusinessTypeSearchChange(index, value)}
                          tabIndex={3}
                          data-test-id={`businessInfoBusinessType${index}Input`}
                        >
                          {business_type}
                        </Combobox>
                      </Delete>
                    </Label>
                  </Validate>
                )
              })}
              <Text smaller>
                <Link onClick={handleAddBusinessType} data-test-id="businessInfoBusinessTypeAdd">
                  Add type
                </Link>
              </Text>
            </LabelInlineStyle>
          </DisableIfNeeded>

          <DisableIfNeeded field="lei_number" model={company} rules={fieldLockRules}>
            <Validate rules={validation.lei_number.rules}>
              {(isValid, brokenRule) => (
                <LabelInlineStyle
                  labelText={
                    <Fragment>
                      LEI number{' '}
                      <InlineHelp>
                        {(handleClose) => (
                          <ModalContent data-test-id="aboutBusinessLeiNumberInfoContent">
                            <ModalContentHeadline>Legal entity identifiers (LEIs)</ModalContentHeadline>
                            <ModalContentBody>
                              <Typo>
                                The Legal entity identifier (LEI) is a unique global identifier for legal entities
                                participating in financial transactions. All companies which buy and sell securities on
                                financial markets are required to have an LEI.
                                <br />
                                <br />
                                If your company does not have an LEI, InvestEngine will provide one free of charge for
                                the first year of your investment with us.
                              </Typo>
                            </ModalContentBody>
                            <ModalContentButtons
                              text="OK"
                              onClick={handleClose}
                              data-test-id="aboutBusinessLeiNumberInfoClose"
                            />
                          </ModalContent>
                        )}
                      </InlineHelp>
                    </Fragment>
                  }
                  valueIfDisabled={company.lei_number}
                  errorMessages={validation.lei_number.errors}
                  multiline={!desktop}
                  dataTestId="businessInfoLeiNumberLabel"
                >
                  <Input
                    name="lei_number"
                    mods={{ size: 'bigger' }}
                    type="text"
                    onChange={(_event, value) => handleFieldChange('lei_number', value)}
                    tabIndex={4}
                    data-test-id="businessInfoLeiNumberInput"
                  >
                    {company.lei_number}
                  </Input>
                </LabelInlineStyle>
              )}
            </Validate>
          </DisableIfNeeded>
        </Fieldset>

        <LabelInlineStyle labelText="" multiline={!desktop}>
          <Text small style={{ marginTop: '0.8em', display: 'inline-block' }}>
            <Link onClick={showSupportModal} data-test-id="businessInfoEmailUsLink">
              Email us to change this information
            </Link>
          </Text>
        </LabelInlineStyle>

        {!isFieldsDisabled && (
          <LabelInlineStyle labelText="" multiline={!desktop}>
            <Button
              className="t-settings-save-btn"
              mods={{ size: 'big block' }}
              onClick={handleSubmit}
              disabled={!bulkValidate(validation)}
              data-test-id="businessInfoSubmit"
            >
              Save changes
            </Button>
          </LabelInlineStyle>
        )}
      </Form>
    </SubmitOnEnter>
  )
}

CompanyForm.propTypes = {
  company: PropTypes.object.isRequired,
  desktop: PropTypes.bool,
  clientApproved: PropTypes.bool,
  handleSubmit: PropTypes.func,
}

export default compose(
  connect(() => {}, {
    showSupportModal,
  }),
)(CompanyForm)
