import React from 'react'

import { NavigationBar, NavigationBarTitleMultipleChildHolder } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type PortfolioHeaderProps = {
  title: string
  handleBack: () => void
}

const PortfolioHeader = ({ title, handleBack }: PortfolioHeaderProps): React.ReactElement => {
  return (
    <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
      <NavigationBarTitleMultipleChildHolder>
        <Typography color="inherit" align="center" lineHeight="small" weight="semibold">
          {title}
        </Typography>
        <Paper top={1} bottom={1}>
          <Typography
            size={12}
            weight="semibold"
            color="on_color_default"
            align="center"
            lineHeight="small"
            className="ManagedNavigationBar-TypeSubtitle"
            inline
          >
            Picked by you, managed by IE
          </Typography>
        </Paper>
      </NavigationBarTitleMultipleChildHolder>
    </NavigationBar>
  )
}

export { PortfolioHeader }
