import * as React from 'react'
import { type ReactElement, Fragment } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { formatMinimalPercent } from 'helpers/money'
import { symbols } from 'helpers/typograph'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Relative } from 'components/atoms/Relative'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type TypeHeadlineProps = {
  isLoading?: boolean
  title: string
  actual: number
  target?: number
  showTarget?: boolean
  searchInput?: React.ReactElement
}

const TypeHeadline = ({
  isLoading,
  title,
  actual,
  target,
  showTarget = false,
  searchInput,
}: TypeHeadlineProps): ReactElement => {
  const { desktop } = useMediaQueries()

  const actualText = useMemo(() => {
    const actualWeightPercent: string | React.ReactElement = isLoading ? '██' : formatMinimalPercent(actual)

    return (
      <Fragment>
        <Typo>
          {`${title}${symbols.nbsp}`}
          {actualWeightPercent}
        </Typo>
      </Fragment>
    )
  }, [isLoading, actual, title])

  const targetText = useMemo(() => {
    const targetWeightPercent: string = formatMinimalPercent(target, { returnDashIfNotNumberPassed: true })

    if (!showTarget) {
      return null
    }

    if (isLoading) {
      return '███'
    }

    return `/${symbols.nbsp}${targetWeightPercent}`
  }, [isLoading, target, showTarget])

  const headline = (
    <Skeleton shown={!!isLoading} inline mix>
      <span>
        <Typography tag="span" size={24} lineHeight="small" weight="semibold" data-test-id={`${title}ActualWeight`}>
          <Typo>{actualText}</Typo>
        </Typography>
        {showTarget && (
          <Fragment>
            {' '}
            <Typography tag="span" size={12} lineHeight="small" color="minor" data-test-id={`${title}TargetWeight`}>
              <Typo>{targetText}</Typo>
            </Typography>
          </Fragment>
        )}
      </span>
    </Skeleton>
  )

  if (!searchInput) {
    if (desktop) {
      return <Paper top={4}>{headline}</Paper>
    }

    return headline
  }

  if (desktop) {
    return (
      <ColumnarLayout mods={{ padding: 'small' }}>
        <Column size={1}>
          <Paper top={4}>{headline}</Paper>
        </Column>
        <Column size={1}>
          <Relative>{searchInput}</Relative>
        </Column>
      </ColumnarLayout>
    )
  }

  return (
    <Fragment>
      <Paper bottom={24}>{headline}</Paper>
      {searchInput}
    </Fragment>
  )
}

export { TypeHeadline }
