import { useUnit } from 'effector-react'

import { useSelector } from 'hooks'

import { $portfolioSecuritiesStore } from 'app/effector/portfolio-securities'
import { $securitiesStore } from 'app/effector/securities'

import { PortfolioList } from 'app/redux/models/portfolio/'
import { selectGoalById as selectPortfolioById } from 'app/redux/selectors'

import { useSecurities } from 'app/pages/Securities/hooks'

import { useNavbar } from './useNavbar.js'

import { types as clientTypes } from 'constants/client'
import { manageTypes } from 'constants/portfolio'

const useSecurityBuySell = ({ portfolioId, securityId }) => {
  const { isLoading } = useSecurities({ portfolioId })

  const { getSecurityById } = useUnit($securitiesStore)
  const { getSecuritiesByPortfolio } = useUnit($portfolioSecuritiesStore)

  const client = useSelector((state) => state.client)

  const portfolio = useSelector((state) => selectPortfolioById(state, portfolioId))

  const portfolios = useSelector((state) =>
    state.portfolios.list
      .getVisiblePortfolios()
      .filter(({ id, manage_type: manageType }) => id === portfolio.id || manageType === manageTypes.CASH),
  )
  const cashPortfolios = [...new PortfolioList(...portfolios)]

  const security = getSecurityById(securityId)
  const portfolioSecurities = getSecuritiesByPortfolio(portfolioId) || []
  const portfolioSecurity = portfolioSecurities.find((security) => security.security_id === securityId)

  const { available_value: availableValue } = portfolioSecurity ?? {}

  const { handleBack, handleSell, handleBuy, handleAddFunds, handleReview } = useNavbar({ portfolioId, securityId })

  const isBusiness = client.type === clientTypes.BUSINESS

  return {
    isBusiness,
    isLoading,
    portfolio,
    cashPortfolios,
    security: {
      ...security,
      available_value: availableValue,
    },

    handleBack,
    handleSell,
    handleBuy,
    handleAddFunds,
    handleReview,
  }
}

export { useSecurityBuySell }
