import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'

import { goTo, urlTo } from 'helpers/router.js'

import { useActions, useLoading, useMediaQueries, useSelector, useMemo, useEffect } from 'hooks'
import { useAnalytics } from 'app/pages/Dashboard/Analytics/hooks'

import { GatewayDest } from 'components/atoms/Gateway'
import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { Paper } from 'components/atoms/Paper'

import { Empty as DesktopEmpty } from './Desktop'
import { Empty as MobileEmpty } from './Mobile'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'

const AnalyticsWithPortfolios = ({
  location: { query: { fromPortfolio: navigateFromPortfolio, showAll: showAllEquities, ...tunnelQuery } = {} } = {},
  params: { portfolioId } = {},
  portfolios,
  children,
  routes,
  route,
} = {}) => {
  const fromPortfolio = navigateFromPortfolio === 'true'
  const showAll = showAllEquities === 'true'
  const { isLoading, ...props } = useAnalytics({
    portfolioId,
    portfolios,
    fromPortfolio,
    showAll,
    route,
    tunnelQuery: { ...tunnelQuery, ...(navigateFromPortfolio ? { fromPortfolio: navigateFromPortfolio } : {}) },
    withSkeletons: true,
  })
  const { desktop } = useMediaQueries()

  const tabs = useMemo(
    () => [
      { title: 'Regions', id: 'regions', isActive: routes.some((route) => route.module === 'regions') },
      { title: 'Sectors', id: 'sectors', isActive: routes.some((route) => route.module === 'sectors') },
      { title: 'Holdings', id: 'holdings', isActive: routes.some((route) => route.module === 'holdings') },
      { title: 'Asset class', id: 'assets', isActive: routes.some((route) => route.module === 'assets') },
    ],
    [routes],
  )

  const tabsComponent = useMemo(
    () => (
      <Paper top={desktop ? 56 : 32}>
        <GreyTabs
          tabs={tabs}
          onChange={(tab) => {
            const module = portfolioId ? 'analytics.portfolio' : 'analytics'
            const query = {
              ...tunnelQuery,
              ...(fromPortfolio ? { fromPortfolio } : {}),
              ...(showAll ? { showAll } : {}),
            }

            goTo(urlTo(`${module}.${tab.id}`, { portfolioId }, query))
          }}
        />
      </Paper>
    ),
    [tabs, desktop, tunnelQuery, fromPortfolio, showAll, portfolioId],
  )

  return (
    <Fragment>
      {React.cloneElement(children, { fromPortfolio, showAll, isLoading, ...props, tabs: tabsComponent })}
      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

AnalyticsWithPortfolios.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      fromPortfolio: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    }),
  }),
  params: PropTypes.shape({
    portfolioId: PropTypes.string,
  }),
  children: PropTypes.node,
  routes: PropTypes.arrayOf(PropTypes.object),
  route: PropTypes.shape({
    module: PropTypes.string,
    childRoutes: PropTypes.array,
  }),
}

const summaryItemPropTypes = {
  actual: PropTypes.number,
  target: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const equityItemPropTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
  actual: PropTypes.number,
  target: PropTypes.number,
}

const equitiesPropTypes = {
  value: PropTypes.number,
  weight: PropTypes.number,
  target: PropTypes.number,
  count: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape(equityItemPropTypes)),
}

const AnalyticsEmpty = () => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      {desktop ? <DesktopEmpty /> : <MobileEmpty />}
      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

const Analytics = (props) => {
  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])
  const [portfolios, portfoliosFetchCount] = useSelector((state) => [
    state.portfolios.list.getVisiblePortfolios({ includeCashPortfolios: false }),
    state.portfolios.fetchCount,
  ])
  const shouldFetchPortfolios = portfoliosFetchCount < 1 && portfolios.length < 1
  const { isLoading: isPortfoliosLoading, wait: waitForPortfolios } = useLoading(shouldFetchPortfolios)

  useEffect(() => {
    if (shouldFetchPortfolios) {
      waitForPortfolios(fetchPortfolios({ setNotValidBefore: false, setValidAfter: false }))
    }
  }, [])

  if (!isPortfoliosLoading && portfolios.length < 1) {
    return <AnalyticsEmpty />
  }

  return (
    <AnalyticsWithPortfolios portfolios={portfolios} {...props}>
      {props.children}
    </AnalyticsWithPortfolios>
  )
}

Analytics.propTypes = { ...AnalyticsWithPortfolios }

export { Analytics, summaryItemPropTypes, equityItemPropTypes, equitiesPropTypes }
