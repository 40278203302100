import * as React from 'react'
import { Fragment } from 'react'

import { useState, useMemo, useRef, useLayoutEffect } from 'hooks'

import { palette } from 'helpers/palette/'

import { LineChart } from 'components/atoms/LineChart'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

enum SecurityTypes {
  STOCK = 'STOCK',
  BOND = 'BOND',
  ALTERNATIVE = 'ALTERNATIVE',
}

enum SecurityTypeNames {
  STOCK = 'Equities',
  BOND = 'Bonds',
  ALTERNATIVE = 'Alternatives',
}

enum SecurityColors {
  STOCK = 'stocks',
  BOND = 'bonds',
  ALTERNATIVE = 'alternatives',
}

type Security = {
  key: string
  id: number
  title: string
  ticker: string
  logo: string
  value: number
  weight: number
  currentWeight: number
  showDelete: boolean
  isNew: boolean
  type: SecurityTypes
}

type WeightsChartProps = {
  securities: Security[]
  currentValues: Record<string, number>
}

type Title = {
  value: number
  type: SecurityTypes
  title: string
  color: string
  left?: number
  right?: number
}

const WeightsChart = ({ securities = [], currentValues = {} }: WeightsChartProps): React.ReactElement => {
  // primitives from collections to be used in hook deps
  const securitiesPrimitive = securities.map((security) => security.id).join('')
  const currentValuesPrimitive = Object.values(currentValues).join('.')

  // refs
  const containerRef = useRef<HTMLElement>(null)
  const stockRef = useRef<HTMLElement>(null)
  const bondRef = useRef<HTMLElement>(null)
  const alternativesRef = useRef<HTMLElement>(null)
  const titlesRefs = useMemo(() => {
    return {
      [SecurityTypes.STOCK]: stockRef,
      [SecurityTypes.BOND]: bondRef,
      [SecurityTypes.ALTERNATIVE]: alternativesRef,
    }
  }, [])

  // using chart and titles dom elements widths to calculate titles positioning on chart
  const [chartWidth, setChartWidth] = useState(0)
  const [stocksWidth, setStocksWidth] = useState(0)
  const [bondsWidth, setBondsWidth] = useState(0)
  const [alternativesWidth, setAlternativesWidth] = useState(0)

  const titlesWidths = {
    [SecurityTypes.STOCK]: stocksWidth,
    [SecurityTypes.BOND]: bondsWidth,
    [SecurityTypes.ALTERNATIVE]: alternativesWidth,
  }

  // calculate chart values and titles positioning for Stocks, Bonds and Alternatives
  const titles: [Title, Title, Title] = useMemo(() => {
    // accumulate securities percent values by type
    const data = securities.reduce(
      (result, security) => {
        result[security.type] += currentValues[security.id]

        return result
      },
      { [SecurityTypes.STOCK]: 0, [SecurityTypes.BOND]: 0, [SecurityTypes.ALTERNATIVE]: 0 },
    )

    // generating and sorting final objects to display titles
    const results = Object.keys(data)
      .map((typeCode) => {
        const type = SecurityTypes[typeCode]

        return {
          value: data[typeCode],
          type,
          title: SecurityTypeNames[type],
          color: palette[SecurityColors[type]],
        }
      })
      .sort((a, b) => b.value - a.value) as [Title, Title, Title]

    // we need to position middle title correctly and not to overlay with first and last titles
    results[0].left = 0
    results[2].right = 0

    const firstTitleWidth = titlesWidths[results[0].type]
    const middleTitleWidth = titlesWidths[results[1].type]
    const lastTitleWidth = titlesWidths[results[2].type]

    let middleTitleLeft = (results[0].value / 100) * chartWidth

    if (middleTitleLeft >= chartWidth - lastTitleWidth - middleTitleWidth - 8) {
      middleTitleLeft = chartWidth - lastTitleWidth - middleTitleWidth - 8
    }

    if (middleTitleLeft <= firstTitleWidth + 8) middleTitleLeft = firstTitleWidth + 8

    results[1].left = middleTitleLeft

    return [results[0], results[1], results[2]]
    // optimised deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securitiesPrimitive, currentValuesPrimitive, chartWidth, alternativesWidth, bondsWidth, stocksWidth])

  const titlesNode = useMemo(() => {
    return titles?.map((title) => (
      <span
        key={title.type}
        ref={titlesRefs[title.type] as React.RefObject<HTMLDivElement>}
        style={{ left: title.left, right: title.right, position: 'absolute' }}
      >
        <Typography tag="span" color={SecurityColors[title.type]} weight="semibold" size={14}>
          {title.value}%
        </Typography>{' '}
        <Typography tag="span" size={12}>
          {title.title}
        </Typography>
      </span>
    ))
  }, [titles, titlesRefs])

  useLayoutEffect(() => {
    const getNodeWidth = (node: HTMLElement | null): number => {
      if (node) {
        return node.getBoundingClientRect().width
      }

      return 0
    }

    setChartWidth(getNodeWidth(containerRef?.current))
    setStocksWidth(getNodeWidth(titlesRefs[SecurityTypes.STOCK]?.current))
    setBondsWidth(getNodeWidth(titlesRefs[SecurityTypes.BOND]?.current))
    setAlternativesWidth(getNodeWidth(titlesRefs[SecurityTypes.ALTERNATIVE]?.current))
  }, [titles, titlesRefs])

  return (
    <Fragment>
      <Paper bottom={8} ref={containerRef} style={{ position: 'relative', height: '18px' }}>
        {titlesNode}
      </Paper>
      <LineChart values={titles} />
    </Fragment>
  )
}

export { WeightsChart }
