import React, { Fragment } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { formatPercent, format as formatMoney } from 'helpers/money'

import { AssetOnelinerGroup } from 'components/_old/AssetOneliner/AssetOneliner.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelField, LabelText } from 'components/_old/Label/Label.jsx'
import SliderWTicks from 'components/_old/SliderWTicks/SliderWTicks.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

import { Paper } from 'components/atoms/Paper'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { type ManagedPortfolio } from '../../../../types'

import './EditIncomePortfolio.css'

type EditIncomePortfolioControlsProps = {
  goal: ManagedPortfolio
  presets: object[]
  isActiveGoal: boolean
  validation: object
  initialDepositMin: number
  onChange: (field: string, value: number) => void
}

const EditIncomePortfolioControls = ({
  goal,
  presets,
  isActiveGoal = false,
  validation,
  initialDepositMin,
  onChange,
}: EditIncomePortfolioControlsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  let preset = goal?.preset

  const riskStatData = useMemo(() => {
    const getValue = (assets): number => {
      const amount =
        assets?.length > 1
          ? assets.reduce((memo: { amount?: number } | number = 0, item: { amount?: number }) =>
              typeof memo === 'number' ? (memo || 0) + (item?.amount ?? 0) : (memo?.amount ?? 0) + (item?.amount ?? 0),
            )
          : assets?.[0]?.amount || 0

      return amount
    }

    if (presets && presets.length > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      preset = preset || presets[0].id
      const presetItem = presets.find((presetItem) => presetItem.id === preset)

      if (!presetItem) {
        return null
      }

      const stocksValue = getValue(presetItem?.preset_stocks || [])
      let bondsValue = getValue(presetItem?.preset_bonds || [])

      // add cash to bonds
      if (presetItem?.preset_cash) {
        const cashValue = parseFloat(presetItem.preset_cash)
        bondsValue = bondsValue + cashValue
      }

      return {
        title: presetItem?.title,
        stocks: formatPercent(stocksValue / 100, 1),
        bonds: formatPercent(bondsValue / 100, 1),
      }
    }

    return {
      title: '',
      stocks: 0,
      bonds: 0,
    }
  }, [presets, preset])

  const initialInvestmentField = (
    <Validate rules={validation.initial_deposit.rules}>
      <Label errorMessages={validation.initial_deposit.errors}>
        Initial investment
        <Input
          value={goal?.initial_deposit || ''}
          onChange={(_event, value) => {
            onChange('initial_deposit', value)
          }}
          mods={{ size: 'bigger' }}
          placeholder={`Must be at least ${formatMoney(initialDepositMin)}`}
          type="money"
          data-test-id="incomeProjectionsInitialDepositInput"
        />
      </Label>
    </Validate>
  )

  const capitalBalanceField = (
    <Label>
      <LabelText>
        <Text color="lightgray">Capital balance</Text>
      </LabelText>
      <Text block bigger data-test-id="incomeProjectionsCapitalBalance">
        {formatMoney(goal?.capital_balance || 0)}
      </Text>
    </Label>
  )

  const oneOffPaymentField = (
    <Validate rules={validation.one_off_payment?.rules}>
      <Label errorMessages={validation.one_off_payment?.errors}>
        <LabelText replaceWithError>
          <Text color="lightgray">One-off deposit</Text>
        </LabelText>
        <Input
          value={goal?.one_off_payment || ''}
          onChange={(_event, value) => {
            onChange('one_off_payment', value)
          }}
          mods={{ size: 'bigger' }}
          placeholder="£"
          type="money"
          data-test-id="incomeProjectionsOneOffPaymentInput"
        />
      </Label>
    </Validate>
  )

  const riskField = (
    <Label className="EditIncomePortfolio-RiskLabel">
      <LabelText>
        <Text color="lightgray">Risk</Text>
      </LabelText>
      <LabelField>
        <SliderWTicks
          valueKey="id"
          value={goal?.preset}
          recommended={goal?.preset_recommended}
          scale={presets}
          onChange={(value) => {
            onChange('preset', value)
          }}
          tabIndex={3}
          valueAsCaption={
            <div className="EditIncomePortfolio-SliderLabels">
              <AssetOnelinerGroup className="AssetOnelinerGroup_ie-flex">
                <Text smaller lineheight="one-line">
                  Less
                </Text>
                <Text smaller lineheight="one-line">
                  More
                </Text>
              </AssetOnelinerGroup>
            </div>
          }
          theme="risk-zones-to-yellow"
          data-test-id="incomeProjectionsPresetsSlider"
        />
      </LabelField>
    </Label>
  )

  const modelStats = riskStatData ? (
    <Label data-test-id="incomeProjectionsModel">
      <LabelText className="EditIncomePortfolio-ModelLabel">
        <Text color="lightgray">Model</Text>
      </LabelText>
      <LabelField>
        <Text block bigger data-test-id="presetTitle">
          {riskStatData.title}
        </Text>
        <div className="EditIncomePortfolio-ModelFooter">
          <div>
            <Text color="stocks" small data-test-id="equitiesPercent">
              {riskStatData.stocks}
            </Text>
            <Text smaller> Equities</Text>
          </div>
          <div>
            <Text color="bonds" small data-test-id="bondsPercent">
              {riskStatData.bonds}
            </Text>
            <Text smaller> Bonds</Text>
          </div>
        </div>
      </LabelField>
    </Label>
  ) : null

  return (
    <Paper right={desktop ? 40 : 16} left={desktop ? 40 : 16}>
      {desktop ? (
        <ColumnarLayout>
          {isActiveGoal ? <Column className="EditIncomePortfolio-CapitalField">{capitalBalanceField}</Column> : null}
          <Column>{isActiveGoal ? oneOffPaymentField : initialInvestmentField}</Column>
          <Column>{riskField}</Column>
          <Column>{modelStats}</Column>
        </ColumnarLayout>
      ) : (
        <Fragment>
          <ColumnarLayout mods={{ padding: 'small' }} style={{ marginBottom: 12 }}>
            {isActiveGoal ? <Column>{capitalBalanceField}</Column> : null}
          </ColumnarLayout>
          <ColumnarLayout mods={{ padding: 'small' }} style={{ marginBottom: 6 }}>
            <Column>{isActiveGoal ? oneOffPaymentField : initialInvestmentField}</Column>
          </ColumnarLayout>
          <Card mods={{ theme: 'transparent', 'no-padding': 'left right bottom', padding: 'big' }}>
            <ColumnarLayout flexDirection={!desktop && 'column'} mods={{ padding: 'small' }}>
              <Column>{riskField}</Column>
              <Column>{modelStats}</Column>
            </ColumnarLayout>
          </Card>
        </Fragment>
      )}
    </Paper>
  )
}

export { EditIncomePortfolioControls }
