import React, { Fragment } from 'react'

import { useState, useCallback, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import StoresButtons from 'components/_old/StoresButtons/StoresButtons.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { TextCollapse } from 'components/atoms/TextCollapse'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { DesktopFooter } from 'app/containers/Footer'

import { Sticky } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/Sticky'
import { SharePortfolioSecurities } from 'app/pages/Dashboard/Goals/DIY/SharePortfolio/components/SharePortfolioSecurities'
import { SharePortfolioAnalytics } from 'app/pages/Dashboard/Goals/DIY/SharePortfolio/Desktop/components/SharePortfolioAnalytics'

import { BonusBadge } from '../../components/BonusBadge'
import { BuildByBadge } from '../../components/BuildByBadge'
import { type SharedPortfolioComponentData } from '../../types'

const Desktop = ({
  isPromoEnabled,
  isAuthorized,
  title,
  description,
  securities,
  equities,
  regions,
  sectors,
  equityTypes,
  assets,
  handleShare,
  handleGetStarted,
  handleSecurityClick,
  handleOpenTsCsModal,
  isDescriptionExpanded,
  handleDescriptionExpand,
}: SharedPortfolioComponentData): React.ReactElement => {
  const [layoutNode, setLayoutNode] = useState(null)
  const layoutRef = useCallback((ref) => {
    setLayoutNode(ref)
  }, [])

  const analyticsProps = { sectors, regions, equities, equity_types: equityTypes, assets, securities, layoutNode }

  const shareLink = useMemo(
    () => (
      <Link onClick={handleShare}>
        <ItemWithIcon
          space={12}
          icon={<Icon type="share" color="inherit" size={24} />}
          content={
            <Typography color="inherit">
              <Typo>Share this portfolio outline</Typo>
            </Typography>
          }
          iconVerticalAlign="center"
        />
      </Link>
    ),
    [handleShare],
  )

  const headerNode = useMemo(() => <Header logoLink={location.origin} hard withShadow right={shareLink} />, [shareLink])

  const securitiesNode = useMemo(
    () => (
      <Fragment>
        <SharePortfolioSecurities securities={securities} onClick={handleSecurityClick} />
        <Paper top={32} bottom={56}>
          <Typography weight="semibold">
            <Typo>
              The Sharing Portfolio Tool is for illustrative, user idea sharing purposes only. Ideas are not Investment
              Advice and InvestEngine does not endorse or accept liability for any of the ideas shared by users of the
              Sharing Portfolio Tool.
            </Typo>
          </Typography>
        </Paper>
      </Fragment>
    ),
    [securities, handleSecurityClick],
  )

  const stickyButtonNode = useMemo(() => {
    return isAuthorized ? null : (
      <Sticky bottom clickThrough>
        <SideSpacesCompensator>
          <Paper
            top={20}
            bottom={64}
            left={16}
            right={16}
            style={{ background: palette['background-default'] }}
            clickThroughPadding
          >
            <AllCenter>
              <Button
                type="submit"
                mods={{ size: 'big block', color: 'green' }}
                onClick={handleGetStarted}
                data-test-id="createYouOwnButton"
              >
                Create your own
              </Button>
              {isPromoEnabled && <BonusBadge handleOpenTsCsModal={handleOpenTsCsModal} />}
            </AllCenter>
          </Paper>
        </SideSpacesCompensator>
      </Sticky>
    )
  }, [isAuthorized, isPromoEnabled, handleGetStarted, handleOpenTsCsModal])

  const rightContentNode = useMemo(
    () => (
      <Paper left={80}>
        <Width size={19}>
          <AllCenter>
            <Typography>
              <Typo>Download the free InvestEngine app</Typo>
            </Typography>
            <Paper top={16}>
              <StoresButtons />
            </Paper>
          </AllCenter>
        </Width>
      </Paper>
    ),
    [],
  )

  const contentNode = (
    <Inner twoColumns>
      <Paper>
        <ColumnarLayout mods={{ padding: 'no' }}>
          <Column size={1}>
            <Paper bottom={56}>
              <BuildByBadge />
              <Paper top={40}>
                <Typography size={32} lineHeight="small" weight="semibold" data-test-id="sharedTitle">
                  <Typo allowHtml={false}>{title}</Typo>
                </Typography>
              </Paper>
              {description && (
                <Paper top={20}>
                  <Typography>
                    <TextCollapse
                      limit={200}
                      multiline
                      onExpand={handleDescriptionExpand}
                      shouldInitExpanded={isDescriptionExpanded}
                      maxLineBreaks={3}
                      allowHtml={false}
                    >
                      {description}
                    </TextCollapse>
                  </Typography>
                </Paper>
              )}
            </Paper>
          </Column>
          <Column size={0}>
            <Paper left={80} bottom={40}>
              <Width size={19}></Width>
            </Paper>
          </Column>
        </ColumnarLayout>

        <SharePortfolioAnalytics
          {...analyticsProps}
          securitiesNode={securitiesNode}
          stickyButton={stickyButtonNode}
          appLinksNode={rightContentNode}
        />
      </Paper>
    </Inner>
  )

  return <DesktopLayout ref={layoutRef} header={headerNode} content={contentNode} footer={<DesktopFooter />} />
}

export { Desktop }
