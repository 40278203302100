import { useUnit } from 'effector-react'
import isEmpty from 'lodash/isEmpty'

import { useActions, useEffect, useLoading, useSelector, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router'

import { $bankAccountsStore, fetchBankAccountsFx } from 'app/effector/bank-accounts'
import { $owner } from 'app/effector/contacts'
import { fetchSecuritiesFx, $securitiesStore } from 'app/effector/securities'

import {
  fetchPresets as fetchPresetsActionCreator,
  updateOrCreate as updateOrCreateGoalActionCreator,
  selectGoal as selectGoalActionCreator,
} from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'
import { getPresetHistory } from 'app/redux/api/portfolio'
import type { Portfolio, Preset } from 'app/redux/models/portfolio/types'
import { isClientNewOrNotCompleted as selectIsClientNewOrNotCompleted } from 'app/redux/selectors'

import type { DetailedSecurity } from 'app/pages/Dashboard/Goals/ManagedPortfolio/types'
import type { Frequency } from 'app/pages/Dashboard/Goals/RecurringPayment/types'

import { types as clientTypes } from 'constants/client'
import { manageTypes, presetTypes, states as portfolioStates, regulatoryTypes } from 'constants/portfolio'

type UseSelfSelectedPresetProps = {
  presetId: string
  location: {
    query: Record<string, string>
  }
}

type UseSelfSelectedPresetReturnProps = {
  preset: Preset
  allocationData: Array<{ type: string }>
  percentageScaleData: Array<{ text: string; color: string; percentage: number }>
  isLoading: boolean
  needToChooseRegulatoryType: boolean
  chartData: Array<{ date: string; value: number }>
  isHistoryLoading: boolean
  handleBack: () => void
  closeSelfSelectedTerms: () => void
  handleAgreeWithTerms: () => void
  handleInvestNow: () => void
  handleCreatePortfolio: (data: {
    amount: string | null
    frequency?: Frequency | null
    startDay?: string | null
    monthlyPaymentDay?: number | null
    regulatoryType: string
  }) => Promise<void>
  handleGoToSecurity: (securityId: number) => void
}

const useSelfSelectedPreset = ({
  presetId,
  location,
}: UseSelfSelectedPresetProps): UseSelfSelectedPresetReturnProps => {
  const { isLoading, wait } = useLoading(false)
  const [isHistoryLoading, setIsHistoryLoading] = useState(false)
  const [agreedWithSelfSelectedTerms, setAgreedWithSelfSelectedTerms] = useState(false)
  const [chartData, setChartData] = useState([])

  const [fetchPresets, updateOrCreateGoal, selectGoal] = useActions([
    fetchPresetsActionCreator,
    updateOrCreateGoalActionCreator,
    selectGoalActionCreator,
  ])

  const { securities } = useUnit($securitiesStore)
  const { presets } = useSelector((state: any) => state.portfolios)
  const preset = presets[manageTypes.SELF_SELECTED]?.find((preset: any) => preset.id === parseInt(presetId, 10))
  const { bankAccounts } = useUnit($bankAccountsStore)
  const hasBankAccounts = !!bankAccounts.length
  const shouldSignSippDeclaration = useSelector((state) => !state.client.agreed_with_sipp_declaration)
  const client = useSelector((state) => state.client)
  const isClientNewOrNotCompleted = useSelector(selectIsClientNewOrNotCompleted)
  const owner = useUnit($owner)
  const isBusiness = client.type === clientTypes.BUSINESS
  const isUkResidence = owner?.isUkResidence()
  const isSippAge = owner?.age < 75
  const canOpenIsa = !owner?.id ? true : !isBusiness && (isClientNewOrNotCompleted || isUkResidence)
  const canOpenSipp = !owner?.id ? true : !isBusiness && (isClientNewOrNotCompleted || (isUkResidence && isSippAge))
  const needToChooseRegulatoryType = (canOpenIsa ?? canOpenSipp) && !location.query.regulatoryType

  const shouldLoadSecurities = isEmpty(securities)

  useEffect(() => {
    if (!hasBankAccounts) {
      fetchBankAccountsFx()
    }
  }, [hasBankAccounts])

  useEffect(() => {
    if (shouldLoadSecurities) {
      fetchSecuritiesFx()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchPresets(manageTypes.SELF_SELECTED, { setNotValidBefore: false, setValidAfter: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (presetId) {
      setIsHistoryLoading(true)
      getPresetHistory(presetId)
        .then((history) => {
          setChartData(history?.map((item) => ({ date: item.value_date, value: Number(item.balance ?? 0) })))
        })
        .finally(() => {
          setIsHistoryLoading(false)
        })
    }
  }, [presetId])

  const getSecuritiesList = (presetSecurities): DetailedSecurity[] => {
    return presetSecurities.map((presetSecurity) => {
      const securityDetails = securities.find((security) => security.id === presetSecurity.security_id)

      return {
        ...presetSecurity,
        ...securityDetails,
      }
    })
  }

  const getSecuritiesWeight = (securities): number => securities.reduce((sum, { amount }) => sum + amount, 0)

  const allocationData = [
    {
      type: 'Equities',
      items: getSecuritiesList(preset?.preset_stocks ?? []),
      weight: getSecuritiesWeight(preset?.preset_stocks ?? []),
    },
    {
      type: 'Bonds',
      items: getSecuritiesList(preset?.preset_bonds ?? []),
      weight: getSecuritiesWeight(preset?.preset_bonds ?? []),
    },
    {
      type: 'Alternatives',
      items: getSecuritiesList(preset?.preset_alternatives ?? []),
      weight: getSecuritiesWeight(preset?.preset_alternatives ?? []),
    },
    {
      type: 'Cash',
      items: [],
      weight: preset?.preset_cash,
    },
  ].filter(({ weight }) => weight > 0)

  const totalStocksPercentage = preset?.preset_stocks?.reduce((sum, stock) => sum + (stock.amount || 0), 0) || 0

  const percentageScaleData = [
    {
      text: 'Equities',
      color: palette.stocks,
      percentage: totalStocksPercentage,
    },
    {
      text: 'Bonds',
      color: palette['self-selected-default'],
      percentage: 100 - totalStocksPercentage,
    },
  ]

  const handleBack = (): void => {
    goTo(location?.query?.back ?? urlTo('self-selected-presets', {}, { ...location.query }))
  }

  const handleInvestNow = (): void => {
    setAgreedWithSelfSelectedTerms(false)

    trackEvent({
      action: 'lp_invest_now_clicked',
      preset: preset.title,
      manage_type: 'SELF_SELECTED',
    })

    goTo(urlTo('self-selected-presets.preset', { id: presetId }, { ...location.query, termsModalOpened: true }))
  }

  const handleAgreeWithTerms = (): void => {
    const { termsModalOpened, ...restQuery } = location.query
    setAgreedWithSelfSelectedTerms(true)
    trackEvent({ action: 'sp_investnow_sp_or_ls_opened', preset: preset.title, manage_type: 'SELF_SELECTED' })
    goTo(urlTo('self-selected-presets.preset', { id: presetId }, { ...restQuery, quickStartModalOpened: true }))
  }

  const closeSelfSelectedTerms = (): void => {
    const { termsModalOpened, ...restQuery } = location.query

    trackEvent({
      action: 'cancel',
      manage_type: 'SELF_SELECTED',
      url: window.location.toString().replace(window.location.origin, ''),
      client_type: client.type,
    })

    goTo(urlTo('self-selected-presets.preset', { id: presetId }, { ...restQuery }))
  }

  const createNewPortfolio = async (regulatoryType: string): Promise<Portfolio> => {
    await selectGoal(null)
    const nextState = await wait(
      updateOrCreateGoal(
        ['manage_type', 'regulatory_type', 'preset_type', 'preset', 'agreed_with_self_selected_terms'],
        {
          manage_type: manageTypes.SELF_SELECTED,
          regulatory_type: regulatoryType,
          preset_type: presetTypes.SELF_SELECTED,
          preset: presetId,
          agreed_with_self_selected_terms: agreedWithSelfSelectedTerms,
        },
      ),
    )

    if (nextState.portfolios.error) {
      showFailToast()
      return
    }

    const createdNewPortfolio =
      nextState.portfolios.list.filterByShape({
        manage_type: manageTypes.SELF_SELECTED,
        regulatory_type: regulatoryType,
        state: portfolioStates.NEW,
      })?.[0] ?? null

    return createdNewPortfolio
  }

  const handleCreatePortfolio = async (params): Promise<void> => {
    const portfolioRegulatoryType = needToChooseRegulatoryType
      ? params.regulatoryType
      : location.query.regulatoryType ?? regulatoryTypes.GIA

    const portfolio = await createNewPortfolio(portfolioRegulatoryType)

    const backUrl = urlTo('self-selected-presets.preset', { id: presetId })

    if (params.regulatoryType === regulatoryTypes.SIPP && shouldSignSippDeclaration) {
      goTo(
        urlTo(
          'self-selected-presets.preset',
          { id: presetId },
          {
            ...params,
            portfolioId: portfolio?.id,
            openSipp: true,
            quickStart: true,
            back: backUrl,
            registrationBack: urlTo('dashboard', null, { finishRegistration: true }),
          },
        ),
      )
      return
    }

    goTo(urlTo('dashboard.portfolio.finish', { id: portfolio?.id }, { ...params, quickStart: true, back: backUrl }))
  }

  const handleGoToSecurity = (securityId: string): void => {
    const backUrl = urlTo('self-selected-presets.preset', { id: presetId }, location.query)

    goTo(urlTo('securities.security', { id: securityId }, { back: backUrl, isFromPreset: true }))
  }

  return {
    preset,
    allocationData,
    percentageScaleData,
    isLoading,
    needToChooseRegulatoryType,
    chartData,
    isHistoryLoading,
    handleBack,
    handleInvestNow,
    handleCreatePortfolio,
    closeSelfSelectedTerms,
    handleAgreeWithTerms,
    handleGoToSecurity,
  }
}

export { useSelfSelectedPreset }
