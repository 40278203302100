import { combine, createStore, createEffect } from 'effector'

import * as api from './api'

import type { ReferralCodeStore } from './types'

// Effects
const fetchReferralCodeFx = createEffect(async () => {
  try {
    return await api.getReferralCode()
  } catch (error) {}
})

// Stores
const $referralCode = createStore<ReferralCodeStore>({ link: null, referral_code: null })

$referralCode.on(fetchReferralCodeFx.doneData, (state: ReferralCodeStore, referralCode: ReferralCodeStore) => {
  if (!referralCode.link) return state

  return referralCode
})

const $isReferralCodeFetched = createStore<boolean>(false)

$isReferralCodeFetched.on(fetchReferralCodeFx.done, () => true)

const $referralCodeStore = combine({
  referralCode: $referralCode,
  isLoading: fetchReferralCodeFx.pending,
  isReferralCodeFetched: $isReferralCodeFetched,
})

export { $referralCodeStore, fetchReferralCodeFx }
