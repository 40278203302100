import React from 'react'

import { useUnit } from 'effector-react'

import { useCallback, useMediaQueries, useMemo } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import moment from 'helpers/date.js'
import { format as formatMoney } from 'helpers/money'
import { palette } from 'helpers/palette'

import { $quickStartsStore } from 'app/effector/quickStart'
import { $recurringPaymentsStore } from 'app/effector/recurringPayments'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { usePortfolioActions, ActionKey } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'

import { frequencies } from '../../RecurringPayment/constants'

import { quickStartStatuses } from 'constants/quickStart'

import './SavingsPlanLabel.css'

type SavingsPlanLabelProps = {
  portfolio: Portfolio
}

const frequencyTexts = {
  [frequencies.WEEKLY]: 'a week',
  [frequencies.FORTNIGHT]: 'every 2 weeks',
  [frequencies.MONTHLY]: 'a month',
}

const SavingsPlanLabel = ({ portfolio }: SavingsPlanLabelProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { getReccuringPaymentByPortfolioId, getDirectDebitByPortfolioId } = useUnit($recurringPaymentsStore)
  const { getQuickStartByPortfolioId } = useUnit($quickStartsStore)

  const { actionsMap } = usePortfolioActions(portfolio)
  const handleSavingsPlanAction = actionsMap[ActionKey.SAVINGS_PLAN]

  const recurringPayment = getReccuringPaymentByPortfolioId(portfolio?.id)
  const directDebit = getDirectDebitByPortfolioId(portfolio?.id)
  const quickStart = getQuickStartByPortfolioId(portfolio?.id)

  const isQuickStartInProgress = quickStart?.status === quickStartStatuses.ACTIVE && !directDebit && !recurringPayment
  const isQuickStartNotStarted = !quickStart && !directDebit && !recurringPayment

  const handleClick = useCallback(() => {
    if (isQuickStartInProgress) {
      trackEvent({
        action: 'sp_finish_setup_clicked_label',
        manage_type: portfolio?.manage_type,
        ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
      })
    }

    if (isQuickStartNotStarted) {
      trackEvent({
        action: 'sp_setup_in_portfolio_clicked',
        manage_type: portfolio?.manage_type,
        ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
        portfolio_created_date: moment(portfolio?.created).format('YYYY-MM-DD'),
      })
    }

    const currentLocation = window.location.pathname + window.location.search

    handleSavingsPlanAction.onClick(currentLocation, false)
  }, [
    isQuickStartInProgress,
    isQuickStartNotStarted,
    handleSavingsPlanAction,
    portfolio?.manage_type,
    portfolio?.created,
    portfolio?.preset_type,
  ])

  const content = useMemo(() => {
    if (!recurringPayment && !directDebit) {
      return (
        <ItemWithIcon
          space={8}
          icon={<Icon type="steps" color={palette['content-on-background-primary']} size={16} />}
          contentVerticalAlign="center"
          content={
            <ItemWithIcon
              space={8}
              icon={<Icon type="information-16" color={palette['content-on-background-primary']} size={16} />}
              iconPosition="right"
              contentVerticalAlign="center"
              content={
                <Nobr>
                  <Typography size={14} lineHeight="small" color="on_background_primary">
                    <Typo>Set up a Savings Plan</Typo>
                  </Typography>
                </Nobr>
              }
            />
          }
        />
      )
    }

    const savingsPlanAmount = recurringPayment ? recurringPayment?.amount : directDebit?.amount
    const savingsPlanFrequencyText = recurringPayment ? frequencyTexts[recurringPayment?.frequency] : 'a month'

    return (
      <ItemWithIcon
        space={8}
        icon={<Icon type="checkmark_in_a_circle" color={palette['content-on-background-primary']} size={16} />}
        contentVerticalAlign="center"
        content={
          <Nobr>
            <Typography size={14} lineHeight="small" overflow="ellipsis">
              <Typo>
                Savings Plan {formatMoney(savingsPlanAmount, true)} {savingsPlanFrequencyText}
              </Typo>
            </Typography>
          </Nobr>
        }
      />
    )
  }, [recurringPayment, directDebit])

  return (
    <Card className="SavingsPlanLabel_Card" onClick={handleClick} data-test-id="savingsPlanLabel">
      <Paper top={12} bottom={12} right={desktop ? 12 : 16} left={desktop ? 12 : 16}>
        {content}
      </Paper>
    </Card>
  )
}

export { SavingsPlanLabel }
