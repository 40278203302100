import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

const signDeclaration = async (declarationType: 'ISA' | 'SIPP'): Promise<undefined | ApiError> => {
  try {
    const { data } = await axios.post('client/declaration/sign/', { declaration: declarationType })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const uploadAvatar = async (formData: FormData): Promise<{ avatar: string } | ApiError> => {
  try {
    const { data } = await axios({
      url: `client/avatar/`,
      method: 'patch',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { signDeclaration, uploadAvatar }
