import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette'

import CSSTransitionGroup from 'react-addons-css-transition-group'
import Link from 'components/_old/Link/Link.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Gateway } from 'components/atoms/Gateway'

import './Drawer.css'

function Drawer({
  children,
  className,
  gatewayInto = 'modals',
  isVisible,
  onVisibilityChange: handleVisibility,
  ...rest
}) {
  return (
    <div className="Drawer-Holder">
      <Link className="Drawer-Toggle" data-modal-opener="Drawer" onClick={handleVisibility}>
        <Icon className="Drawer-Icon" type="menu" color="inherit" />
      </Link>
      <Gateway into={gatewayInto}>
        <CSSTransitionGroup
          transitionEnterTimeout={palette.animation.speed.number.slow}
          transitionLeaveTimeout={palette.animation.speed.number.slow}
          transitionName={{
            enter: 'Drawer_enter',
            enterActive: 'Drawer_enter_active',
            leave: 'Drawer_leave',
            leaveActive: 'Drawer_leave_active',
          }}
        >
          {isVisible ? (
            <section className={classNames(className, 'Drawer')} {...rest}>
              <span className="Drawer-Backdrop" onClick={handleVisibility} />
              <div className="Drawer-Inner">{children}</div>
            </section>
          ) : null}
        </CSSTransitionGroup>
      </Gateway>
    </div>
  )
}

Drawer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  gatewayInto: PropTypes.string,
  isVisible: PropTypes.bool,
  onVisibilityChange: PropTypes.func,
}

export default Drawer
