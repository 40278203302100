import React from 'react'

import growth from './Growth/routes'
import { ReviewPortfolio } from './ReviewPortfolio'

const childRoutes = [growth]

const getRoutes = ({ Wrapper, ...rest }): object => ({
  ...rest,
  headline: 'This is your selected portfolio',
  module: 'review-portfolio',
  path: 'review-portfolio/',
  component: function Component(props) {
    return Wrapper ? (
      <Wrapper {...props}>
        <ReviewPortfolio {...props} />
      </Wrapper>
    ) : (
      <ReviewPortfolio {...props} />
    )
  },
  childRoutes,
})

export { getRoutes }
