import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { urlTo } from 'helpers/router.js'

import { DesktopLayout } from 'components/atoms/Layouts'
import Header from 'components/_old/Header/Header.jsx'
import { DesktopFooter } from 'app/containers/Footer'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Progress } from 'components/_old/Progress/'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import FeeInfo from 'app/containers/Questionnaire/FeeInfo/FeeInfo.jsx'
import HeadlineHelp from 'app/containers/PortfolioTunnel/Tunnel/HeadlineHelp/HeadlineHelp.jsx'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { SingleChoiceCard } from '../components/SingleChoiceCard.jsx'

const DesktopSingle = ({ progress, question, selectedAnswer, backLinkText, handleBack, handleSubmit }) => {
  const { title, description } = question

  const header = useMemo(
    () => (
      <Header
        right={
          <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}>
            <Link to={urlTo('dashboard')}>My Dashboard</Link>
          </Button>
        }
        after={
          <Fragment>
            <Progress fill={progress} gapWidth="1em" />
            <FeeInfo />
          </Fragment>
        }
        hideDrawer
      />
    ),
    [progress],
  )

  const heading = useMemo(
    () => (
      <Paper className="Question_Title_wrapper" top={64}>
        <NavigationBar leftPartText={backLinkText} onLeftPartClick={handleBack}>
          <span data-test-id="headline">
            <Typo>{title}</Typo>
          </span>
          {description?.text && (
            <Typography className="Question_Headline_help" tag="span" size={16}>
              <HeadlineHelp
                iconType="question"
                background="status-warning"
                title={description?.title}
                body={description?.text}
              />
            </Typography>
          )}
        </NavigationBar>
      </Paper>
    ),
    [title, description, backLinkText, handleBack],
  )

  const answers = useMemo(
    () => (
      <SideSpacesCompensator>
        <AllCenter className="Question_Answers_wrapper">
          <ColumnarLayout className="Question_Answers">
            {question.answers.map((answer, i) => (
              <Column key={answer.id}>
                <SingleChoiceCard
                  tabIndex={i + 1}
                  answer={answer}
                  selected={selectedAnswer === answer.id}
                  onClick={handleSubmit}
                />
              </Column>
            ))}
          </ColumnarLayout>
        </AllCenter>
      </SideSpacesCompensator>
    ),
    [question.answers, selectedAnswer, handleSubmit],
  )

  const content = (
    <Inner>
      {heading}
      {answers}
    </Inner>
  )

  return <DesktopLayout header={header} content={content} footer={<DesktopFooter />} noGaps />
}

DesktopSingle.propTypes = {
  progress: PropTypes.number,
  question: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    }),
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      }),
    ),
  }),
  selectedAnswer: PropTypes.number,
  backLinkText: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleBack: PropTypes.func,
}

export { DesktopSingle }
