import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

import { type Review, type ReviewType } from '../'

const getMarketingReviewStatus = async (): Promise<Review | ApiError> => {
  try {
    const { data } = await axios.get('/marketing/review/')

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const setMarketingReviewLastSeenDate = async ({ date }): Promise<Date | ApiError> => {
  try {
    await axios.patch('/marketing/review/', {
      last_seen: date,
    })
    return date
  } catch (error) {
    return new ApiError(error)
  }
}

const setLeftReview = async ({
  reviewType,
}: {
  reviewType: ReviewType
}): Promise<{ left_review: boolean } | ApiError> => {
  try {
    await axios.patch('/marketing/review/', {
      left_review: true,
      review_type: reviewType,
    })
    return { left_review: true }
  } catch (error) {
    return new ApiError(error)
  }
}

export { getMarketingReviewStatus, setMarketingReviewLastSeenDate, setLeftReview }
