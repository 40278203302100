import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import Headline from 'components/_old/Headline/Headline.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Segment from 'components/atoms/Segment/Segment.jsx'
import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import './LegendList.css'

const LegendList = ({ className, headline, data }) => (
  <div className={classNames(className, 'LegendList')}>
    {headline && (
      <Headline level={4} mods={{ 'no-margin': 'top' }}>
        {headline}
      </Headline>
    )}
    {data.map(({ text, value }, index) => {
      const noMargin = (() => {
        if (index === 0) {
          return 'top'
        }

        if (index === data.length - 1) {
          return 'bottom'
        }

        return null
      })()

      return (
        <Segment key={index} mods={{ margin: 'smallest', noMargin }}>
          <ColumnarLayout>
            <Text small left>
              {text}
            </Text>
            {value && (
              <Text small right>
                {value}
              </Text>
            )}
          </ColumnarLayout>
        </Segment>
      )
    })}
  </div>
)

LegendList.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}

export { LegendList }
