import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

import { SecurityStatsCollection } from './Stats/SecurityStatsCollection.js'

/**
 * @class
 * @typedef {Object} PortfolioSecurity
 */
export class PortfolioSecurity extends Entity {
  constructor() {
    super()

    /** @type {?number} */
    this.security_id = null
    /** @type {?number} */
    this.value = null
    /** @type {?number} */
    this.available_value = null
    /** @type {?number} */
    this.current_weight = null
    /** @type {?number} */
    this.current_weight_from_capital_balance = null
    /** @type {?number} */
    this.target_weight = null
    /** @type {?number} */
    this.average_price = null
    /** @type {?number} */
    this.quantity = null
    /** @type {SecurityStatsCollection} */
    this.stats = SecurityStatsCollection.createFromObject({})
    /** @type {?string} */
    this.added_from = null
  }

  /** @override */
  getRules() {
    return {
      security_id: [[rules.int]],
      value: [[rules.float]],
      available_value: [[rules.float]],
      current_weight: [[rules.float]],
      current_weight_from_capital_balance: [[rules.float]],
      target_weight: [[rules.float]],
      quantity: [[rules.float]],
      average_price: [[rules.float]],
      stats: [[(stats) => SecurityStatsCollection.createFromObject(stats)]],
      added_from: [[rules.string]],
    }
  }
}
