import React from 'react'

import { useUnit } from 'effector-react'

import { useCallback, useEffect, useMediaQueries, useSelector } from 'hooks'

import { processError } from 'helpers/errors'
import { goTo, urlTo } from 'helpers/router.js'

import { $companyStore, fetchCompanyFx, updateOrCreateCompanyFx, saveCompanyAddressFx } from 'app/effector/company'

import { showSuccessToast } from 'app/redux/actions/ui'
import { ApiError } from 'app/redux/models/errors'
import { isClientApproved } from 'app/redux/selectors'

import ChangeCompanyAddress from 'app/pages/Forms/ChangeCompanyAddress.jsx'
import CompanyForm from 'app/pages/Forms/CompanyForm.jsx'

import { SettingsLayout } from '../components/SettingsLayout'

const BusinessInfo = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { company } = useUnit($companyStore)

  const clientApproved = useSelector(isClientApproved)

  useEffect(() => {
    fetchCompanyFx()
  }, [])

  const handleAddressModalClose = useCallback(() => {
    goTo(urlTo('dashboard.user-profile.business-info'), { replace: true, scrollToTop: false })
  }, [])

  const handleSubmit = useCallback(async () => {
    try {
      const saveCompanyAddressResult = await saveCompanyAddressFx(company.addresses)

      if (saveCompanyAddressResult instanceof ApiError) throw saveCompanyAddressResult

      const updateOrCreateCompanyResult = await updateOrCreateCompanyFx({
        company,
        keys: ['name', 'uk_number', 'business_type_codes', 'lei_number'],
      })

      if (updateOrCreateCompanyResult instanceof ApiError) throw updateOrCreateCompanyResult

      showSuccessToast('Company data successfully updated')
    } catch (error) {
      processError({ error })
    }
  }, [company])

  return (
    <SettingsLayout title="Business Info">
      <CompanyForm company={company} desktop={desktop} clientApproved={clientApproved} handleSubmit={handleSubmit} />
      <ChangeCompanyAddress onClose={handleAddressModalClose} />
    </SettingsLayout>
  )
}

export { BusinessInfo }
