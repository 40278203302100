import { useMemo, useActions, useLoading } from 'hooks'

import { unformat } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router'

import { patchPortfolio as patchPortfolioActionCreator } from 'app/redux/actions/portfolios'
import { showSuccessToast, showFailToast } from 'app/redux/actions/ui'

import { useForm } from 'components/atoms/Forms/hooks'

type UseAutoinvestAmountInterface = {
  isLoading: boolean
  isValid: boolean
  validation: any
  control: any
  errors: any
  handleSave: () => Promise<void>
}

const useAutoinvestAmount = (
  portfolioId: number,
  amount: number | null,
  query: object,
): UseAutoinvestAmountInterface => {
  const patchPortfolio = useActions(patchPortfolioActionCreator)
  const { isLoading, wait } = useLoading()

  const { control, errors, isValid, handleSubmit } = useForm({
    amount,
  })

  const validation = useMemo(
    () => ({
      required: {
        value: true,
        message: 'Amount can’t be empty',
      },
    }),
    [],
  )

  const handleSave = useMemo(
    () =>
      handleSubmit(async ({ amount }) => {
        try {
          const response = await wait(
            patchPortfolio(
              portfolioId,
              { min_autoinvest_amount: unformat(amount) },
              { setNotValidBefore: false, setValidAfter: false },
            ),
          )

          if (response.portfolios.error) {
            throw Error()
          }
          goTo(urlTo('dashboard.portfolio.autoinvest', { id: portfolioId }, query))
          showSuccessToast('Cash buffer added')
        } catch (error) {
          showFailToast()
        }
      }),
    [portfolioId, query, handleSubmit, patchPortfolio, wait],
  )

  return {
    isLoading,
    isValid,
    validation,
    control,
    errors,
    handleSave,
  }
}

export { useAutoinvestAmount }
