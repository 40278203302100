import React, { useContext, useState, useCallback, useEffect, useRef } from 'react'
import ReactDOM, { findDOMNode } from 'react-dom'

import './Gateway.css'

type DestinationsShape = Record<string, HTMLElement | React.ReactInstance>

type AddDestinationInterface = (name: string, node: HTMLElement) => void

type GatewayContextShape = {
  destinations: DestinationsShape
  addDestination: AddDestinationInterface
}

let destinationsStore: DestinationsShape = {}

const GatewayContext = React.createContext<GatewayContextShape>({
  destinations: destinationsStore,
  addDestination: () => {},
})

type GatewayProviderProps = {
  children: React.ReactNode | React.ReactNodeArray
}

function GatewayProvider({ children }: GatewayProviderProps): React.ReactElement {
  const [destinations, setDestinations] = useState(destinationsStore)

  const addDestination = useCallback(
    (name, destination) => {
      destinationsStore = {
        ...destinationsStore,
        [name]: destination,
      }

      setDestinations(destinationsStore)
    },
    [setDestinations],
  )

  return <GatewayContext.Provider value={{ destinations, addDestination }}>{children}</GatewayContext.Provider>
}

type GatewayDestProps = {
  className?: string
  name: string
}

function GatewayDest({ className, name }: GatewayDestProps): React.ReactElement {
  const destination = useRef<HTMLDivElement | null>(null)
  const { addDestination } = useContext(GatewayContext)

  useEffect(() => {
    const node = destination?.current

    if (node instanceof HTMLElement) {
      addDestination(name, node)
    }
  }, [name, destination, addDestination])

  return <div ref={destination} className={className} data-gateway-destination={name} />
}

type GatewayProps = {
  children: React.ReactNode | React.ReactNodeArray
  into: string
}

function Gateway({ children, into }: GatewayProps): React.ReactElement | null {
  const { destinations } = useContext(GatewayContext)
  let node = destinations[into]

  if (!(node instanceof HTMLElement)) {
    // eslint-disable-next-line react/no-find-dom-node
    const foundNode = findDOMNode(node)

    if (foundNode instanceof HTMLElement) {
      node = foundNode
    }
  }

  if (node instanceof HTMLElement) {
    return ReactDOM.createPortal(children, node)
  }

  return null
}

export { Gateway, GatewayDest, GatewayProvider }
