import { useUnit } from 'effector-react'
import isEmpty from 'lodash/isEmpty'

import { usePrevious, useCallback, useEffect, useContext, useActions, useMemo, useLoading } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import { $portfolioSecuritiesStore, fetchPortfolioSecuritiesFx } from 'app/effector/portfolio-securities'
import { fetchSecuritiesFx, $securitiesStore } from 'app/effector/securities'

import { fetchHistoryByGoal as fetchHistoryByGoalActionCreator } from 'app/redux/actions/portfolios'
import { createPortfolio } from 'app/redux/models/portfolio/PortfolioList'

import { useTabs } from 'components/molecules/Tabs'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'

import { type ManagedPortfolio, TabNames, type TabData, type AllocationItem, type DetailedSecurity } from '../types'

import { states as portfolioStates } from 'constants/portfolio'

type usePortfolioProps = {
  portfolio: ManagedPortfolio
  location: {
    query: {
      view: string
      editablePortfolioFields?: string
    }
  }
}

type usePortfolioData = {
  handleBack: () => void
  activeTabName: string
  activeTabIndex: number
  visibleTabs: TabData[]
  navigateByTab: (index: number, name: string) => void
  handleAmendPortfolioSelect: () => void
  handleSecurityClick: (securityId: string) => void
  isTransferIsaFormVisible: boolean
  portfolioModel: ManagedPortfolio
  allocationData: AllocationItem[]
  showSecuritiesSkeleton: boolean
}

const useManagedPortfolioContent = ({ portfolio, location }: usePortfolioProps): usePortfolioData => {
  const { setTunnelQuery } = useContext(ChangeGoalTunnelContext)

  const { selectSecuritiesInPortfolioDetailed } = useUnit($portfolioSecuritiesStore)
  const portfolioSecurities = selectSecuritiesInPortfolioDetailed(portfolio.id, false) || []
  const { securities } = useUnit($securitiesStore)

  const shouldLoadSecurities = isEmpty(securities)

  const { isLoading: isSecuritiesLoading, wait: waitForSecurities } = useLoading(shouldLoadSecurities)
  const { isLoading: isPortfolioSecuritiesLoading, wait: waitForPortfolioSecurities } = useLoading(false)

  useEffect(() => {
    if (shouldLoadSecurities) {
      waitForSecurities(fetchSecuritiesFx())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (portfolio.id) {
      waitForPortfolioSecurities(fetchPortfolioSecuritiesFx({ portfolioId: portfolio.id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio.id])

  const getSecuritiesList = (securities, type): DetailedSecurity[] =>
    securities
      .filter(([security]) => security.type === type)
      .map(([security, portfolioSecuritie]) => ({ ...security, ...portfolioSecuritie }))
      .sort((a, b) => b.current_weight_from_capital_balance - a.current_weight_from_capital_balance)

  const getSecuritiesWeight = (securities): number =>
    securities.reduce(
      (sum, { current_weight_from_capital_balance: currentWeightFromCapitalBalance }) =>
        sum + currentWeightFromCapitalBalance,
      0,
    )

  const stocks = getSecuritiesList(portfolioSecurities, 'STOCK')
  const bonds = getSecuritiesList(portfolioSecurities, 'BOND')
  const alternatives = getSecuritiesList(portfolioSecurities, 'ALTERNATIVE')
  const cash = portfolio.goal_cash

  const allocationData: AllocationItem[] = [
    {
      type: 'Equities',
      items: stocks,
      weight: getSecuritiesWeight(stocks),
    },
    {
      type: 'Bonds',
      items: bonds,
      weight: getSecuritiesWeight(bonds),
    },
    {
      type: 'Alternatives',
      items: alternatives,
      weight: getSecuritiesWeight(alternatives),
    },
    {
      type: 'Cash',
      items: cash,
      weight: cash.reduce((sum, { amount }) => sum + amount, 0),
      value: cash.reduce((sum, { total_value: totalValue }) => sum + totalValue, 0),
    },
  ].filter(({ weight }) => weight > 0)

  const prevGoalId = usePrevious(portfolio.id)
  const isDefaultPortfolio = portfolio?.is_default_preset_used

  const isGoalInClosing = portfolio.state === portfolioStates.CLOSING

  const tabItems = [
    {
      name: TabNames.OVERVIEW,
      visible: true,
      'data-test-id': 'goalOverviewTab',
    },
    {
      name: TabNames.PROJECTIONS,
      visible: !isGoalInClosing && !isDefaultPortfolio,
      'data-test-id': 'goalProjectionsTab',
    },
  ]

  const visibleTabs = tabItems.filter((tab) => tab.visible)

  const getTabIndexByName = useCallback(
    (name): number => visibleTabs.findIndex((tab) => tab.name === name),
    [visibleTabs],
  )
  const { view: currentTabView, editablePortfolioFields, ...rest } = location.query
  const initialTabIndex = currentTabView ? getTabIndexByName(currentTabView) : 0
  const { activeTabIndex, setActiveTab } = useTabs(visibleTabs.length, initialTabIndex)

  useEffect(
    () => {
      setActiveTab(initialTabIndex)
      setTunnelQuery(currentTabView ? { view: currentTabView, ...rest } : null)
    },
    // had to run only if location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  )

  const navigateByTab = useCallback(
    (index, name, scrollToTop = false) => {
      const query = { view: name, ...rest }

      goTo(urlTo('dashboard.portfolio', { id: portfolio.id }, query), { scrollToTop })
      setActiveTab(index)
      setTunnelQuery(query)
    },
    [portfolio.id, rest, setActiveTab, setTunnelQuery],
  )

  const [fetchHistoryByGoal] = useActions([fetchHistoryByGoalActionCreator])

  useEffect(() => {
    fetchHistoryByGoal(portfolio.id, portfolio.preset_type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio.id, portfolio.preset_type])

  useEffect(
    () => {
      const isNewGoal = parseFloat(portfolio.current_balance) <= 0 && !portfolio.first_topup && !isGoalInClosing
      const needRedirect = !currentTabView && isNewGoal && !isDefaultPortfolio

      if (needRedirect) {
        navigateByTab(getTabIndexByName(TabNames.PROJECTIONS), TabNames.PROJECTIONS)
      }
    },
    // had to run only if id changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [portfolio?.id],
  )

  const handleAmendPortfolioSelect = useCallback(() => {
    navigateByTab(getTabIndexByName(TabNames.PROJECTIONS), TabNames.PROJECTIONS, true)
    trackEvent({
      category: 'Portfolio panel',
      action: 'Amend your portfolio',
    })
  }, [navigateByTab, getTabIndexByName])

  useEffect(
    () => {
      if (!initialTabIndex && portfolio.id !== prevGoalId) {
        setActiveTab(getTabIndexByName(TabNames.OVERVIEW))
      }
    },
    // had to run only if id changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [portfolio.id, prevGoalId],
  )

  const activeTabName = visibleTabs[activeTabIndex]?.name

  const handleBack = useCallback(() => {
    goTo(urlTo('dashboard'))
  }, [])

  const handleSecurityClick = useCallback(
    (securityId) => {
      goTo(urlTo('dashboard.portfolio.security', { id: portfolio.id, securityId }, location.query))
    },
    [portfolio.id, location.query],
  )

  const isTransferIsaFormVisible = useMemo(
    () => portfolio?.isa?.transferring_in_process && portfolio.state !== portfolioStates.CLOSING,
    [portfolio?.isa?.transferring_in_process, portfolio.state],
  )

  const portfolioModel = createPortfolio(portfolio)

  const showSecuritiesSkeleton = (isSecuritiesLoading || isPortfolioSecuritiesLoading) && portfolioSecurities.length < 1

  return {
    handleBack,
    activeTabName,
    activeTabIndex,
    visibleTabs,
    navigateByTab,
    handleAmendPortfolioSelect,
    handleSecurityClick,
    isTransferIsaFormVisible,
    portfolioModel,
    allocationData,
    showSecuritiesSkeleton,
  }
}

export { useManagedPortfolioContent }
