import React from 'react'

import { type Period } from 'components/molecules/NewPeriodSelect'

import { type ManagedPortfolio } from '../../../../types'

import { GrowthGraph } from './GrowthGraph'
import { IncomeGraphs } from './IncomeGraphs'

import { presetTypes as portfolioPresetTypes } from 'constants/goal'

type GraphsProps = {
  isPortfolioLoading: boolean
  portfolio: ManagedPortfolio
  location: {
    query: {
      time: Period
    }
  }
  currentPeriod: string
  periodSelectNode: React.ReactNode
  handleAddFundsClick: () => void
}

const Graphs = ({
  isPortfolioLoading,
  portfolio,
  location,
  currentPeriod,
  periodSelectNode,
  handleAddFundsClick,
}: GraphsProps): React.ReactElement | null => {
  const isPortfolioIncome = portfolio?.preset_type === portfolioPresetTypes.INCOME
  const hasHistory = Boolean(portfolio?.first_topup)

  return isPortfolioIncome ? (
    <IncomeGraphs
      isPortfolioLoading={isPortfolioLoading}
      portfolio={portfolio}
      location={location}
      currentPeriod={currentPeriod}
      periodSelectNode={periodSelectNode}
      isPlaceholderDisplayed={!hasHistory}
      handleAddFundsClick={handleAddFundsClick}
    />
  ) : (
    <GrowthGraph
      isPortfolioLoading={isPortfolioLoading}
      periodSelectNode={periodSelectNode}
      portfolio={portfolio}
      location={location}
      currentPeriod={currentPeriod}
      isPlaceholderDisplayed={!hasHistory}
      handleAddFundsClick={handleAddFundsClick}
    />
  )
}

export { Graphs }
