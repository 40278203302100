import React from 'react'

import { useUnit } from 'effector-react'

import { useEffect, useMediaQueries } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'

import { $documentsList, $canSubmit, initialize, submit, submitDocumentsFx } from 'app/effector/secure-document-upload'

import { showFailToast } from 'app/redux/actions/ui'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Logo } from 'components/_old/Logo/Logo.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'

import { DocumentUpload } from './components/DocumentUpload'

submitDocumentsFx.done.watch(() => {
  goTo(urlTo('secure-document-upload-success'))
})

submitDocumentsFx.fail.watch(() => {
  showFailToast()
})

const SecureDocumentUpload = ({ params }): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const requestToken = params.requestToken
  const docSetsString = params.docSets

  const [documentsList, canSubmit, submitDocuments, isSubmitInProgress, handleInitialize] = useUnit([
    $documentsList,
    $canSubmit,
    submit,
    submitDocumentsFx.pending,
    initialize,
  ])

  const tooltipText =
    'Your documents are uploaded through encrypted channels and stored in highly restricted storage with limited staff access, ensuring maximum security for your data.'

  useEffect(() => {
    handleInitialize({ requestToken, docSetsString })
    // eslint-disable-next-line
  }, [])

  const headerNode = <Header hideDrawer logoLink={urlTo('dashboard')} hard withShadow />

  const mobileHeader = (
    <NavigationBar>
      <Link to={urlTo('dashboard')} hard>
        <Logo style={{ height: 24 }} />
      </Link>
    </NavigationBar>
  )

  const titleIcon = <Icon type="shield-48" />

  const titleNode = (
    <Typography align="center" size={32} weight="semibold" lineHeight="small">
      <Typo>Secure {desktop ? 'Document' : 'File'} Upload</Typo>
      <Typography weight="regular" size={16} lineHeight="small" inline>
        <TooltipToModal description={tooltipText} customIcon>
          <Paper left={8} inline>
            <Icon type="information-24" style={{ position: 'relative', top: '3px' }} size={24} inline />
          </Paper>
        </TooltipToModal>
      </Typography>
    </Typography>
  )

  const subtitleNode = (
    <Typography align="center" weight="semibold">
      <Typo>Please upload the requested documents below.</Typo>
    </Typography>
  )

  const docsNode = documentsList.map((document) => (
    <Paper bottom={8} key={document.type}>
      <DocumentUpload document={document} />
    </Paper>
  ))

  const submitButton = (
    <Button mods={{ size: 'big block' }} disabled={!canSubmit || isSubmitInProgress} onClick={submitDocuments}>
      Send Documents
    </Button>
  )

  const content = (
    <Width size={26} center>
      <AllCenter>
        <Paper bottom={16} inline>
          {titleIcon}
        </Paper>
        <Paper bottom={16}>{titleNode}</Paper>
        {subtitleNode}
        <Paper top={48} bottom={24} style={{ width: '100%' }}>
          {docsNode}
        </Paper>
        {desktop && submitButton}
        <Paper bottom={120} />
      </AllCenter>
      <GatewayDest name="toasts" />
    </Width>
  )

  const desktopContent = (
    <Inner>
      <Paper top={56}>{content}</Paper>
    </Inner>
  )

  const mobileFooter = <MobileLayoutFooterButton>{submitButton}</MobileLayoutFooterButton>

  if (desktop) return <DesktopLayout header={headerNode} content={desktopContent} footer={<DesktopFooter />} noGaps />

  return <MobileLayout header={mobileHeader} content={<Paper top={8}>{content}</Paper>} footer={mobileFooter} />
}

export { SecureDocumentUpload }
