import React from 'react'

import isFunction from 'lodash/isFunction'

import { useCallback, useMemo } from 'hooks'

type StepProviderStep = {
  [key: string]: unknown
  module: string
  initModule: string
  headline?: string
  notImpactToProgressBar?: boolean
}

type StepProviderAdapter = {
  getActiveStepIndex: (steps: StepProviderStep[]) => number
  getStepByName: (steps: StepProviderStep[], module: string) => StepProviderStep | null
  redirect: (step: StepProviderStep, customParams?: Record<string, string>) => void
}

type StepContextShape = {
  activeStepIndex?: number
  activeStep?: StepProviderStep
  steps?: StepProviderStep[]
  progress?: number
  redirectToNextStep?: (customParams?: Record<string, string>) => void
  redirectToPrevStep?: (customParams?: Record<string, string>) => void
  redirectByStepName?: (stepName: string, customParams?: Record<string, string>) => void
  redirectByStep?: (step: StepProviderStep, customParams?: Record<string, string>) => void
  getPrevStep?: (shift?: number) => StepProviderStep | null
  getNextStep?: (shift?: number) => StepProviderStep | null
  getStepByName?: (stepName: string) => StepProviderStep | null
}

const StepContext = React.createContext<StepContextShape>({})

type StepProviderProps = {
  children: React.ReactNode
  steps: StepProviderStep[]
  adapter: StepProviderAdapter
}

const StepProvider = ({ children, steps = [], adapter }: StepProviderProps): React.ReactElement => {
  const activeStepIndex: number = useMemo(() => {
    if (!isFunction(adapter.getActiveStepIndex)) {
      throw new Error('StepProvider doesnt have the ‘getActiveStepIndex’ method')
    }

    return adapter.getActiveStepIndex(steps)
  }, [adapter, steps])

  const progress = useMemo(() => {
    const progressLength = steps.reduce((length, step) => {
      if (!step.notImpactToProgressBar) {
        length++
      }

      return length
    }, 0)

    let lastImpactToProgressBarIndex = activeStepIndex

    while (steps[lastImpactToProgressBarIndex]?.notImpactToProgressBar && lastImpactToProgressBarIndex > 0) {
      lastImpactToProgressBarIndex--
    }

    return ((lastImpactToProgressBarIndex + 1) / (progressLength + 1)) * 100
  }, [steps, activeStepIndex])

  const getPrevStep = useCallback(
    (shift: number = 1) => (activeStepIndex == null ? null : steps[activeStepIndex - shift]),
    [steps, activeStepIndex],
  )

  const getNextStep = useCallback(
    (shift: number = 1) => (activeStepIndex == null ? null : steps[activeStepIndex + shift]),
    [steps, activeStepIndex],
  )

  const redirectByStep = useCallback(
    (step: StepProviderStep, customParams?: Record<string, string>) => {
      if (!isFunction(adapter.redirect)) {
        throw new Error('StepProvider doesnt have the ‘redirect’ method')
      }

      adapter.redirect(step, customParams)
    },
    [adapter],
  )

  const redirectToNextStep = useCallback(
    (customParams?: Record<string, string>) => {
      const step = getNextStep()

      if (step) {
        redirectByStep(step, customParams)
      }
    },
    [getNextStep, redirectByStep],
  )

  const redirectToPrevStep = useCallback(
    (customParams?: Record<string, string>) => {
      const step = getPrevStep()

      if (step) {
        redirectByStep(step, customParams)
      }
    },
    [getPrevStep, redirectByStep],
  )

  const redirectByStepName = useCallback(
    (stepName: string, customParams?: Record<string, string>) => {
      if (!isFunction(adapter.getStepByName)) {
        throw new Error('StepProvider doesnt have the ‘getStepByName’ method')
      }

      const step = adapter.getStepByName(steps, stepName)

      if (step) {
        redirectByStep(step, customParams)
      }
    },
    [adapter, redirectByStep, steps],
  )

  const getStepByName = useCallback(
    (stepName: string) => {
      if (!isFunction(adapter.getStepByName)) {
        throw new Error('StepProvider doesnt have the ‘getStepByName’ method')
      }

      return adapter.getStepByName(steps, stepName)
    },
    [steps, adapter],
  )

  return (
    <StepContext.Provider
      value={{
        activeStepIndex,
        activeStep: steps[activeStepIndex],
        steps,
        progress,
        redirectToNextStep,
        redirectToPrevStep,
        redirectByStepName,
        redirectByStep,
        getPrevStep,
        getNextStep,
        getStepByName,
      }}
    >
      {children}
    </StepContext.Provider>
  )
}

export {
  type StepProviderAdapter,
  type StepProviderStep,
  StepProvider,
  type StepProviderProps,
  StepContext,
  type StepContextShape,
}
