import { ListOf } from 'app/effector/model'

import { Story, Step } from './Story'

class StoryList extends ListOf(Story) {
  processImpactLinks(client: Record<string, unknown> & { impact: Record<string, string> }): StoryList {
    const updatedStories: Story[] = []

    for (const story of this) {
      const updatedSteps: Step[] = []
      let hasValidSteps = false

      for (const step of story.steps) {
        if (!(step.actionButtonUrl ?? '').includes('personalized-impact-link:')) {
          updatedSteps.push(step)
          continue
        }

        const year = new URL(step.actionButtonUrl ?? '').searchParams.get('year')
        const clientSpecificUrl = client.impact?.[year ?? '']

        if (!clientSpecificUrl) {
          continue
        }

        hasValidSteps = true
        updatedSteps.push(
          new Step({
            ...step,
            actionButtonUrl: clientSpecificUrl,
          }),
        )
      }

      if (hasValidSteps || updatedSteps.length > 0) {
        updatedStories.push(
          new Story({
            ...story,
            steps: updatedSteps,
          }),
        )
      }
    }

    return new StoryList(...updatedStories)
  }
}

export { StoryList }
