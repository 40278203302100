import React from 'react'
import isUndefined from 'lodash/isUndefined'
import classNames from 'classnames/dedupe'
import { modsToClassnames } from 'helpers/classname.js'
import './SegmentedControl.css'

export default function SegmentedControl(props) {
  const { className, fluid, center, right, mods = {}, activeIndex, activeIndexes, children } = props

  const wrapChild = (child, i) => {
    if (typeof child === 'object') {
      const { className } = child.props
      const elem = 'SegmentedControl-Segment'
      const isActive = (() => {
        if (!isUndefined(activeIndex)) {
          return i === activeIndex
        }
        if (!isUndefined(activeIndexes)) {
          return activeIndexes.some((x) => x === i)
        }
      })()
      const classes = classNames(className, elem, { [`${elem}_active`]: isActive })
      child = React.cloneElement(child, { className: classes, key: i, active: isActive })
    } else {
      child = <span className="Button-Inner">{child}</span>
    }
    return child
  }

  if (!mods.theme) {
    mods.theme = 'simple'
  }

  const classes = classNames(
    className,
    'SegmentedControl',
    {
      SegmentedControl_fluid: fluid,
      SegmentedControl_center: center,
      SegmentedControl_right: right,
    },
    modsToClassnames('SegmentedControl', mods),
  )

  return (
    <div className={classes}>
      <div className="SegmentedControl-Inner">{children.map(wrapChild)}</div>
    </div>
  )
}
