import React from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { features } from 'helpers/features'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Badge } from 'components/atoms/Badge'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import { SippTooltip } from 'app/pages/Dashboard/Accounts/components/SippTooltip'

import { regulatoryTypes } from 'constants/portfolio'

import './RegulatoryTypeCard.css'

type RegulatoryTypeCardProps = {
  data: {
    name: string
    headline: string
    description: string
    descriptionNew?: string
    descriptionWithActiveIsa?: string
    badge?: string
  }
  hasIsa: boolean
  onClick: () => void
}

const RegulatoryTypeCard = ({ data, hasIsa, onClick }: RegulatoryTypeCardProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const isZeroFeesEnabled = features?.get('zero-sipp-fees')

  const regulatoryTypeBadge = useMemo(() => {
    if (data.name === regulatoryTypes.ISA) {
      const isaBadgeNode = (
        <Paper top={8}>
          <Typography size={12} weight="semibold" lineHeight="small">
            <Badge theme="rounded-warning" text={data.badge} />
          </Typography>
        </Paper>
      )

      return desktop ? <AllCenter>{isaBadgeNode}</AllCenter> : isaBadgeNode
    }

    return null
  }, [data, desktop])

  let description = data.name === regulatoryTypes.GIA ? data.descriptionNew : data.description

  if (data.name === regulatoryTypes.ISA && hasIsa) {
    description = data.descriptionWithActiveIsa
  }

  return (
    <Paper className="RegulatoryTypeCard_Wrapper" right={12} left={12}>
      <SelectableCard className="RegulatoryTypeCard" onClick={onClick} data-typechoose-select={data.name}>
        <Paper top={desktop ? 40 : 32} right={desktop ? 16 : 24} left={desktop ? 16 : 24} bottom={desktop ? 56 : 40}>
          <Typography
            size={24}
            lineHeight="small"
            align={desktop ? 'center' : 'left'}
            weight={desktop ? 'regular' : 'semibold'}
          >
            <Typo>{data.headline}</Typo>
            {!isZeroFeesEnabled && data.name === regulatoryTypes.SIPP && (
              <Paper inline left={8}>
                <SippTooltip iconSize={24} />
              </Paper>
            )}
          </Typography>
          {regulatoryTypeBadge}
          <Paper top={desktop ? 16 : 12}>
            <Typography
              size={desktop ? 14 : 16}
              align={desktop ? 'center' : 'left'}
              color={desktop ? 'default' : 'additional'}
            >
              <Typo>{description}</Typo>
            </Typography>
          </Paper>
        </Paper>
      </SelectableCard>
    </Paper>
  )
}

export { RegulatoryTypeCard }
