import { roles } from 'constants/contacts.js'

export class ContactList {
  constructor(contacts = []) {
    this.contacts = contacts
  }

  /**
   * @param {number} id
   * @returns {Contact|null}
   */
  getContact(id) {
    const index = this.getContactIndex(id)

    return this.contacts[index]
  }

  /**
   * @param {Contact} contact
   * @returns {ContactList}
   */
  receiveContact(contact) {
    const index = this.getContactIndex(contact.id)

    if (index > -1) {
      this.contacts.splice(index, 1, contact)
      return this
    }

    this.contacts.push(contact)
    return this
  }

  /**
   * @param {number} id
   * @returns {ContactList}
   */
  deleteContact(id) {
    const index = this.getContactIndex(id)

    if (index > -1) {
      this.contacts.splice(index, 1)
    }

    return this
  }

  /**
   * @param {number} id
   * @returns {number}
   */
  getContactIndex(id) {
    return this.contacts.findIndex((contact) => contact.id === id)
  }

  /**
   * @returns {Contact|null}
   */
  getOwner() {
    return this.contacts.find((contact) => contact.role === roles.OWNER)
  }
}
