import React from 'react'

import { sendError } from 'helpers/errorLogging'
import { goTo, urlTo } from 'helpers/router.js'

import { showFailToast } from 'app/redux/actions/ui'

type ErrorBoundaryProps = {
  children: React.ReactNode
  levelName: string
  redirectModule: string
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error, errorInfo): void {
    this.setState(null) // to silence warning for missing getDerivedStateFromError
    goTo(urlTo(this.props.redirectModule))
    showFailToast()
    sendError(error, {
      info: `Potential white screen error, caught by error boundary at <${this.props.levelName}/> level`,
    })
  }

  render(): React.ReactNode {
    return this.props.children
  }
}

export { ErrorBoundary }
