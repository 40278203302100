import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout /* MobileLayoutFooterButton */ } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import { Document } from 'components/molecules/Document'
import { MarkdownRenderer } from 'components/molecules/MarkdownRenderer'
import { PercentageScaleWithLegend } from 'components/molecules/PercentageScaleWithLegend'
import { PortfolioUnavailableInterceptor } from 'components/molecules/PortfolioUnavailableInterceptor/PortfolioUnavailableInterceptor'
import { TwoColumns } from 'components/molecules/TwoColumns'

import { LumpSumSetupModal } from 'components/organisms/LumpSumSetupModal/LumpSumSetupModal'
import { QuickStartModal } from 'components/organisms/QuickStartModal'
import { SavingsPlanSetupModal } from 'components/organisms/SavingsPlanSetupModal'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { OpenSippModal } from 'app/pages/Dashboard/Accounts/components/OpenSippModal'
import { PortfolioLabels } from 'app/pages/Dashboard/Goals/SelfSelectedPortfolio/components/PortfolioLabels'
import { SelfSelectedAnalytics } from 'app/pages/Dashboard/Goals/SelfSelectedPortfolio/components/SelfSelectedAnalytics'
import { TunnelHeader } from 'app/pages/Securities/components'
import { BottomTools } from 'app/pages/Securities/SecurityUniverse/Mobile/components/BottomTools'

import { SelfSelectedStories } from '../components/SelfSelectedStories'

import { PresetBanner } from './components/PresetBanner'
import { PresetChart } from './components/PresetChart'
import { PresetRightColumn } from './components/PresetRightColumn'
import { PresetTitle } from './components/PresetTitle'
import { SelfSelectedTerms } from './components/SelfSelectedTerms'
import { useSelfSelectedPreset } from './hooks/useSelfSelectedPreset'
import { useSelfSelectedPresetQuickStart } from './hooks/useSelfSelectedPresetQuickStart'

type SelfSelectedPresetProps = {
  params: {
    id: string
  }
  location: {
    query: Record<string, string>
  }
}

const SelfSelectedPreset = ({ params, location }: SelfSelectedPresetProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const presetId = params.id
  const isTunnel = location.query.fromDashboard === 'true'

  const {
    preset,
    allocationData,
    percentageScaleData,
    isLoading,
    needToChooseRegulatoryType,
    chartData,
    isHistoryLoading,
    handleBack,
    handleInvestNow,
    handleCreatePortfolio,
    closeSelfSelectedTerms,
    handleAgreeWithTerms,
    handleGoToSecurity,
  } = useSelfSelectedPreset({ presetId, location })
  const { handleStartWithSavingsPlan, handleGoBackToQuickStart, handleStartWithLumpSum, handleCloseModal } =
    useSelfSelectedPresetQuickStart({
      preset,
      location,
    })

  const navbarNode = (
    <NavigationBar leftPartText="Back" onLeftPartClick={desktop && isTunnel ? undefined : handleBack}>
      <PresetTitle title={preset?.title} />
    </NavigationBar>
  )

  const content = (
    <Paper>
      <SelfSelectedStories />
      <Paper top={desktop ? 48 : 40} bottom={16}>
        <Typography size={24} lineHeight="small" weight="semibold" data-test-id="descriptionTitle">
          <Typo>Details</Typo>
        </Typography>
      </Paper>
      <MarkdownRenderer desktop={desktop}>{preset?.description}</MarkdownRenderer>
      <Paper top={48}>
        <Typography size={24} lineHeight="small" weight="semibold" data-test-id="keyInformationTitle">
          <Typo>Key information</Typo>
        </Typography>
      </Paper>
      <Paper top={24}>
        <Document name="Key Features document" src={preset?.kiid} />
      </Paper>
      <Paper top={desktop ? 48 : 40}>
        <Typography size={24} lineHeight="small" weight="semibold" data-test-id="pastPerformanceTitle">
          <Typo>Past performance</Typo>
        </Typography>
      </Paper>
      <Paper top={24}>
        <Skeleton shown={isHistoryLoading}>
          <PresetChart chartData={chartData} performance={preset?.twr} />
        </Skeleton>
      </Paper>
      <Paper top={48}>
        <Typography size={24} lineHeight="small" weight="semibold" data-test-id="portfolioBreakdownTitle">
          <Typo>Portfolio breakdown</Typo>
        </Typography>
      </Paper>
      <Paper top={8}>
        <Typography size={14} color="additional" data-test-id="portfolioBreakdownSubtitle">
          <Typo>Portfolio consists of ETFs</Typo>
        </Typography>
      </Paper>
      <Paper top={32}>
        <PercentageScaleWithLegend items={percentageScaleData} height={4} />
      </Paper>
      <Paper top={48}>
        <SelfSelectedAnalytics
          preset={preset}
          onSecurityClick={handleGoToSecurity}
          allocationData={allocationData}
          tunnelQuery={location.query}
          tabs={[
            { title: 'Holdings', id: 'holdings', withSearch: true },
            { title: 'ETFs', id: 'composition' },
            { title: 'Regions', id: 'regions' },
            { title: 'Sectors', id: 'sectors' },
          ]}
        />
      </Paper>
    </Paper>
  )

  const buttonNode = (
    <Fragment>
      <PortfolioUnavailableInterceptor variant="lifeplans">
        <Button mods={{ size: 'new-big block' }} onClick={handleInvestNow}>
          Invest now
        </Button>
      </PortfolioUnavailableInterceptor>
    </Fragment>
  )

  const desktopContent = (
    <TwoColumns
      content={content}
      sidebar={
        <PresetRightColumn preset={preset}>
          <PortfolioLabels preset={preset} />
          <Paper top={24} data-test-id="investNowButton">
            {buttonNode}
          </Paper>
        </PresetRightColumn>
      }
      stickySidebar
    />
  )

  const mobileContent = (
    <Fragment>
      <Paper bottom={16}>
        <SideSpacesCompensator>
          <PresetBanner preset={preset} paddingRight={15.77} />
        </SideSpacesCompensator>
      </Paper>
      <Paper bottom={24}>
        <PortfolioLabels preset={preset} />
      </Paper>
      {content}
    </Fragment>
  )

  if (location.query.termsModalOpened === 'true')
    return <SelfSelectedTerms preset={preset} onCancel={closeSelfSelectedTerms} onContinue={handleAgreeWithTerms} />

  return (
    <Fragment>
      {desktop ? (
        <DesktopLayout
          header={
            isTunnel ? (
              <Inner twoColumns>
                <TunnelHeader onBack={handleBack} />
              </Inner>
            ) : (
              <DesktopHeader />
            )
          }
          content={
            <Inner twoColumns>
              {navbarNode}
              <Paper top={40} bottom={isTunnel ? 80 : 0}>
                {desktopContent}
              </Paper>
            </Inner>
          }
          footer={!isTunnel && <DesktopFooter />}
        />
      ) : (
        <MobileLayout
          header={navbarNode}
          content={mobileContent}
          footer={
            <BottomTools>{buttonNode}</BottomTools>
            /* <MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton> */
          }
        />
      )}

      <QuickStartModal
        isOpen={location.query.quickStartModalOpened === 'true'}
        onSavingsPlanClick={handleStartWithSavingsPlan}
        onLumpSumClick={handleStartWithLumpSum}
        onClose={handleCloseModal}
      />
      <SavingsPlanSetupModal
        isOpen={location.query.savingsPlanModalOpened === 'true'}
        isLoading={isLoading}
        needToChooseRegulatoryType={needToChooseRegulatoryType}
        onClose={handleCloseModal}
        onBack={handleGoBackToQuickStart}
        onContinue={handleCreatePortfolio}
        analyticsData={{ preset: preset?.title, manage_type: 'SELF_SELECTED' }}
      />
      <LumpSumSetupModal
        isOpen={location.query.lumpSumModalOpened === 'true'}
        needToChooseRegulatoryType={needToChooseRegulatoryType}
        onClose={handleCloseModal}
        onBack={handleGoBackToQuickStart}
        onContinue={handleCreatePortfolio}
        analyticsData={{ preset: preset?.title, manage_type: 'SELF_SELECTED' }}
      />

      <OpenSippModal />
      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

export { SelfSelectedPreset }
