function boolean(unknown: unknown): boolean {
  if (unknown === 'true') {
    return true
  }

  if (unknown === 'false') {
    return false
  }

  return Boolean(unknown)
}

export { boolean }
