import React, { Fragment, useEffect } from 'react'

import { useUnit } from 'effector-react'

import { useActions, useMediaQueries, useSelector } from 'hooks'

import { urlTo, goTo } from 'helpers/router'

import { fetchClient as fetchClientActionCreator } from 'app/redux/actions/client'

import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Preloader } from 'components/molecules/Preloader'

import { DesktopFooter, MobileFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { type ClientIsaTransferPrefillFormDataFields } from 'app/pages/CreateAccount/components/IsaTransferPrefillForm/IsaTransferPrefillFormData'

import { init, submitButtonClicked, $isSubmitting, $isLoading, setPrefilledTransferData } from '../model'

import { Description, TransferIsaForm, Headline, OwnerDetails } from './components'

const TransferIsa = ({
  location,
}: {
  location: { query: { portfolioId?: string; back?: string } }
}): React.ReactElement => {
  const isSubmitting = useUnit($isSubmitting)
  const isLoading = useUnit($isLoading)
  const { desktop } = useMediaQueries()
  const client = useSelector((state) => state.client)
  const isClientFetched = useSelector((state) => state.client.is_fetched)
  const [fetchClient] = useActions([fetchClientActionCreator])
  const backLink = location?.query?.back ? location.query.back : urlTo('dashboard')
  const portfolioId = location?.query?.portfolioId ? parseInt(location?.query?.portfolioId) : undefined
  useEffect(() => {
    init({ portfolioId })

    if (!isClientFetched) {
      fetchClient()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPrefilledTransferData(client?.isa_transfer_prefilled_data as ClientIsaTransferPrefillFormDataFields)
  }, [client?.isa_transfer_prefilled_data])

  const desktopLayout = (
    <DesktopLayout
      header={<DesktopHeader />}
      content={
        <Fragment>
          <Inner>
            <Headline backLink={backLink} />
            <ColumnarLayout>
              <Column size={5} />
              <Column size={22}>
                <Paper left={8}>
                  <Paper top={32}>
                    <Description />
                  </Paper>
                  <Paper top={32}>
                    <OwnerDetails />
                  </Paper>
                  <Paper top={56}>
                    <TransferIsaForm />
                  </Paper>
                  <Width size={45}>
                    <Paper top={56}>
                      <Width size={24} center>
                        <Button
                          mods={{ size: 'big block' }}
                          data-test-id="submitIsaForm"
                          disabled={isSubmitting}
                          onClick={submitButtonClicked}
                        >
                          Submit
                        </Button>
                      </Width>
                    </Paper>
                  </Width>
                </Paper>
              </Column>
            </ColumnarLayout>
          </Inner>
        </Fragment>
      }
      footer={<DesktopFooter />}
    />
  )

  const mobileLayout = (
    <MobileLayout
      header={
        <NavigationBar
          leftPartText="Back"
          onLeftPartClick={() => {
            goTo(backLink)
          }}
        >
          Transfer an ISA
        </NavigationBar>
      }
      content={
        <Fragment>
          <Headline backLink={backLink} />
          <Paper top={32}>
            <Description />
          </Paper>
          <Paper top={32}>
            <OwnerDetails />
          </Paper>
          <Paper top={48}>
            <TransferIsaForm />
          </Paper>
          <MobileFooter />
        </Fragment>
      }
      footer={
        <MobileLayoutFooterButton>
          <Button onClick={submitButtonClicked} disabled={isSubmitting}>
            Submit
          </Button>
        </MobileLayoutFooterButton>
      }
    />
  )

  return (
    <Fragment>
      {desktop ? desktopLayout : mobileLayout}
      <Preloader loading={isLoading} size="big" zIndex />
      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

export { TransferIsa }
