import React from 'react'

import { PreFlight } from './PreFlight.jsx'

export default {
  module: 'pre-flight',
  path: 'pre-flight/',
  component: function Component(props) {
    return <PreFlight {...props} />
  },
}
