import React from 'react'
import PropTypes from 'prop-types'

import { palette } from 'helpers/palette'
import { propTypes } from 'helpers/propTypes'

import { useMemo } from 'hooks'

import { DesktopLayout } from 'components/atoms/Layouts'
import Width from 'components/_old/Width/Width'
import { TunnelHeader } from 'app/pages/Securities/components'
import { Paper } from 'components/atoms/Paper'
import { SecurityOrder } from 'components/organisms/SecurityOrder/SecurityOrder.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Notice } from 'app/pages/Dashboard/Securities/SecurityOrdersPage/components/Notice'
import Button from 'components/_old/Button/Button.jsx'
import { Sticky } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/Sticky'

import { PendingOrderList } from 'app/effector/pending-orders/models'
import { SecurityList } from 'app/effector/securities/models'

const Desktop = ({
  orders = [],
  isRebalance,
  canBeCanceled,
  isTradingWindow,
  securities,
  onBack: handleBack,
  onCancelOrders: handleCancelOrders,
  onContactUs: handleContactUs,
}) => {
  const header = useMemo(
    () => (
      <Width center size={37}>
        <TunnelHeader onBack={handleBack} />
      </Width>
    ),
    [handleBack],
  )

  const ordersNode = useMemo(
    () =>
      orders.map((order) => (
        <Paper key={order.id} top={56}>
          <SecurityOrder order={order} securities={securities} />
        </Paper>
      )),
    [orders, securities],
  )

  const cancelButton = useMemo(
    () =>
      canBeCanceled ? (
        <Paper top={16} bottom={32} style={{ background: palette['background-default'] }}>
          <Button
            mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
            disabled={isTradingWindow}
            onClick={handleCancelOrders}
            data-test-id="cancelOrdersButton"
          >
            {isRebalance ? 'Cancel this rebalance' : 'Cancel all pending orders'}
          </Button>
          {isTradingWindow ? (
            <Paper top={20}>
              <Typography size={14} align="center" data-test-id="tradingWindowRestriction">
                <Typo>Orders cannot be cancelled between 2pm and 7pm daily.</Typo>
              </Typography>
            </Paper>
          ) : null}
        </Paper>
      ) : null,
    [isRebalance, canBeCanceled, isTradingWindow, handleCancelOrders],
  )

  const content = useMemo(() => {
    if (orders.length < 1) {
      return (
        <Width center size={37}>
          <Paper top={20}>
            <Typography align="center">
              <Typo>There are no pending orders</Typo>
            </Typography>
          </Paper>
        </Width>
      )
    }

    return (
      <Width center size={36.75}>
        <Typography size={32} lineHeight="small" weight="semibold" data-test-id="pendingOrdersScreenTitle">
          <Typo>{isRebalance ? 'Rebalance in progress' : 'Orders placed'}</Typo>
        </Typography>
        <Notice onContactUsClick={handleContactUs} isRebalance={isRebalance} />
        <Paper bottom={canBeCanceled ? 48 : 80}>
          <Paper bottom={canBeCanceled ? 40 : 0}>{ordersNode}</Paper>
          <Sticky bottom>{cancelButton}</Sticky>
        </Paper>
      </Width>
    )
  }, [orders, ordersNode, cancelButton])

  return <DesktopLayout header={header} content={content} />
}

Desktop.propTypes = {
  orders: propTypes.instanceOf(PendingOrderList),
  isRebalance: PropTypes.bool,
  canBeCanceled: PropTypes.bool,
  isTradingWindow: PropTypes.bool,
  securities: propTypes.instanceOf(SecurityList),
  onBack: PropTypes.func,
  onCancelOrders: PropTypes.func,
  onContactUs: PropTypes.func,
}

export { Desktop }
