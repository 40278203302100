import { useUnit } from 'effector-react'

import { useState } from 'hooks'

import { uploadFilesFx, clearFiles, $requestToken, $uploadedDocuments } from 'app/effector/secure-document-upload'
import { type DocName } from 'app/effector/secure-document-upload/types'

type useDocumentUploadProps = {
  document: any
}

type useDocumentUploadInterface = {
  isModalVisible: boolean
  isError: boolean
  isLoading: boolean
  uploadedFiles: string[]
  showModal: () => void
  hideModal: () => void
  handleFiles: (files: any) => void
  handleClearFiles: (str: DocName) => void
}

const useDocumentUpload = ({ document }: useDocumentUploadProps): useDocumentUploadInterface => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isError, setIsError] = useState(false)

  const [uploadFiles, isUploading, requestToken, uploadedDocuments, handleClearFilesEvent] = useUnit([
    uploadFilesFx,
    uploadFilesFx.pending,
    $requestToken,
    $uploadedDocuments,
    clearFiles,
  ])

  const uploadedFiles = uploadedDocuments[document.type]?.fileNames || []

  const showModal = (): void => {
    setIsModalVisible(true)
  }

  const hideModal = (): void => {
    setIsModalVisible(false)
  }

  const handleFiles = (files): void => {
    setIsError(false)

    const failUnwatch = uploadFilesFx.fail.watch(() => {
      setIsError(true)
    })

    uploadFiles({ requestToken, files, document })
      .then(() => {
        failUnwatch()
      })
      .catch(() => {})
  }

  const handleClearFiles = (): void => {
    handleClearFilesEvent(document.type)
  }

  return {
    isModalVisible,
    isError,
    isLoading: isUploading,
    uploadedFiles,
    showModal,
    hideModal,
    handleFiles,
    handleClearFiles,
  }
}

export { useDocumentUpload }
