import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { palette } from 'helpers/palette'
import { goTo, urlTo } from 'helpers/router'

import { useSelector, useMediaQueries, useCallback, useMemo } from 'hooks'

import { types as clientTypes } from 'constants/client'

import { Typo } from 'components/_old/Typo'
import Icon from 'components/_old/Icon/Icon.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Width from 'components/_old/Width/Width'
import Inner from 'components/_old/Inner/Inner.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { FieldLine } from 'components/_old/atoms/FieldLine'
import { FieldLineList } from 'components/_old/molecules/FieldLineList'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import Segment from 'components/atoms/Segment/Segment.jsx'
import Sticked from 'components/molecules/Sticked/Sticked.jsx'
import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'
import { LayoutHeadline } from 'app/pages/YearlyReview/LayoutHeadline'

import { getClientAnswersOnQuestions } from 'app/redux/selectors'
import { symbols } from 'helpers/typograph'

const QuestionnaireRoot = ({ backLinkUrl, backLinkText, params, isQuestionnaireLoading }) => {
  const { desktop } = useMediaQueries()
  const clientAnswersOnQuestions = useSelector(getClientAnswersOnQuestions)
  const answers = useMemo(
    () =>
      clientAnswersOnQuestions.reduce(
        (answers, clientAnswersObject) => [...answers, ...(clientAnswersObject?.answers ?? [])],
        [],
      ),
    [clientAnswersOnQuestions],
  )
  const client = useSelector((state) => state.client)
  const isQuestionnaireUnanswered = useMemo(
    () => !isQuestionnaireLoading && answers.length < 1,
    [isQuestionnaireLoading, answers.length],
  )

  const redirectToRetake = useCallback(() => {
    goTo(urlTo('questionnaire-retake', params))
  }, [params])

  const handleCorrectAnswers = useCallback(() => {
    // call modal for choose optimal portfolio
    goTo(urlTo('optimal-portfolio', params))
  }, [params])

  const headline = isQuestionnaireUnanswered ? (
    `${symbols.nbsp}` // has to be some symbol to make balance right with previous page
  ) : (
    <Fragment>
      Below are your previous answers to our risk questionnaire.
      <br />
      Please confirm whether anything has changed.
    </Fragment>
  )

  const content = isQuestionnaireUnanswered ? (
    <Fragment>
      <Typography size={desktop ? 40 : 32} weight="semibold" lineHeight="small" align="center" textWrap="balance">
        <Paper bottom={24}>
          <Icon type="warning-circle-64" color={palette['status-warning']} size={64} inline />
        </Paper>
        <Typo>Our risk questionnaire has been updated</Typo>
      </Typography>
      <Paper top={desktop ? 24 : 16}>
        <Typography align="center" textWrap="balance">
          <Typo>We've improved our risk questionnaire to better match your investment goals</Typo>
        </Typography>
      </Paper>
    </Fragment>
  ) : (
    <Fragment>
      <FieldLineList data-test-id="yearlyReviewAnswers">
        {clientAnswersOnQuestions.map(({ question, answers }, index) => (
          <FieldLine key={index} label={question} layout="vertical">
            {answers.join(', ')}
          </FieldLine>
        ))}
      </FieldLineList>
      {client.type === clientTypes.INDIVIDUAL && (
        <Card mods={{ theme: 'transparent', 'no-padding': 'left right bottom', padding: 'big' }}>
          <Text color="darkgray" small>
            The review is taking your current age into account.
          </Text>
        </Card>
      )}
    </Fragment>
  )

  const buttons = isQuestionnaireUnanswered ? (
    <Button mods={{ color: 'blue', size: 'big block' }} onClick={redirectToRetake} data-test-id="yearlyReviewCorrect">
      Retake questionnaire
    </Button>
  ) : (
    <ButtonGroup
      columnarLayoutMods={{ padding: desktop ? 'small' : 'modest' }}
      flexDirection={desktop ? null : 'column'}
      buttonMods={{ size: 'big block' }}
    >
      <Button mods={{ theme: 'simple-reverse-blue' }} onClick={redirectToRetake} data-test-id="yearlyReviewChanged">
        Something has changed
      </Button>
      <Button mods={{ color: 'blue' }} onClick={handleCorrectAnswers} data-test-id="yearlyReviewCorrect">
        Answers are still correct
      </Button>
    </ButtonGroup>
  )

  const contentAndButtons = isQuestionnaireUnanswered ? (
    <Fragment>
      <Paper top={desktop ? 0 : 32} style={desktop ? { marginTop: -32 } : null}>
        <Width size={desktop ? 42 : null} center>
          {content}
        </Width>
      </Paper>
      <Paper top={desktop ? 32 : 48} bottom={desktop ? 120 : 32}>
        {desktop ? (
          <Width size={18} center>
            {buttons}
          </Width>
        ) : (
          buttons
        )}
      </Paper>
    </Fragment>
  ) : (
    <Fragment>
      <Segment
        mods={{
          margin: desktop ? 'double' : null,
          noMargin: desktop ? 'top' : null,
        }}
      >
        <Width size={desktop ? 42 : null} center>
          {content}
        </Width>
      </Segment>
      {desktop && (
        <Segment
          mods={{
            margin: desktop ? 'double' : 'biggest',
            noMargin: desktop ? null : 'bottom',
          }}
        >
          <Width size={28} center>
            {buttons}
          </Width>
        </Segment>
      )}
    </Fragment>
  )

  return (
    <div>
      {isQuestionnaireUnanswered && !desktop ? null : (
        <LayoutHeadline headline={headline} backLinkText={backLinkText} backLinkUrl={backLinkUrl} />
      )}
      {contentAndButtons}
      {!desktop && !isQuestionnaireUnanswered && (
        <Sticked into="afterLayout" newButton>
          <Inner>{buttons}</Inner>
        </Sticked>
      )}
    </div>
  )
}

QuestionnaireRoot.propTypes = {
  backLinkUrl: PropTypes.string.isRequired,
  backLinkText: PropTypes.string.isRequired,
  params: PropTypes.object,
  isQuestionnaireLoading: PropTypes.bool,
}

export { QuestionnaireRoot }
