import React from 'react'

import { UploadBankStatement } from './UploadBankStatement.jsx'

export const routes = {
  module: 'upload-bank-statement',
  path: ':bankAccountId/upload-bank-statement/',
  component: function Component(props) {
    return <UploadBankStatement {...props} />
  },
}
