import React from 'react'
import PropTypes from 'prop-types'

import { hardCodedCountries } from 'helpers/hardCodedCounties'

import { useMediaQueries, useEffect, useRef } from 'hooks'

import { emptyAddress, MIN_ALLOWED_MONTHS, MAX_ADDRESSES_NUMBER } from 'constants/addresses.js'

import { AddressByPeriod, MODE_SEARCH, MODE_MANUAL_EDIT } from 'components/organisms/AddressByPeriod'
import { Typo } from 'components/_old/Typo/Typo'

const processAddresses = (addresses) => {
  // find address item index after which months sum start exceed MIN_ALLOWED_MONTHS
  const removeAddressesAfterIndex = (() => {
    let monthsSum = 0
    let index = null
    for (let i = 0; i < addresses.length; i++) {
      monthsSum += addresses[i].months_at_address
      if (monthsSum >= MIN_ALLOWED_MONTHS) {
        index = i
        break
      }
    }
    return index
  })()

  // remove addresses items after removeAddressesAfterIndex
  if (removeAddressesAfterIndex !== null && removeAddressesAfterIndex !== addresses.length - 1) {
    return addresses.slice(0, removeAddressesAfterIndex + 1)
  }

  // or add empty address items if months sum less than MIN_ALLOWED_MONTHS and addresses.length < MAX_ADDRESSES_NUMBER
  // also prevent adding new address item if last has unfilled or zero months_at_address
  const lastAddressMonths = addresses[addresses.length - 1].months_at_address

  if (removeAddressesAfterIndex === null && addresses.length < MAX_ADDRESSES_NUMBER && lastAddressMonths !== 0) {
    return [...addresses, emptyAddress]
  }
  return addresses
}

const AddressesForm = ({
  addresses,
  validationsList,
  onAddressFieldChange: handleAddressFieldChange,
  onAddressesChange: handleAddressesChange,
}) => {
  // quick fix for https://app.asana.com/0/1202304843784991/1202635259316452/f
  const countries = hardCodedCountries // dicts.countries

  const { desktop } = useMediaQueries()

  useEffect(() => {
    const newAddresses = processAddresses(addresses.list)

    if (newAddresses !== addresses.list) {
      handleAddressesChange(newAddresses)
    }
  })

  const notUkWarningText = <Typo>You must be a UK resident to open an account with InvestEngine</Typo>

  const inputModsRef = useRef({ size: 'bigger' })

  return addresses.list.map((address, index) => {
    const isLastItem = index === addresses.list.length - 1
    const theme = !isLastItem ? 'transparent border-bottom' : 'transparent'
    const gotNonUK = addresses.list.some((address) => !address.isUK())
    let noPadding = null

    if (!desktop && index === 0) {
      noPadding = `${noPadding} top`.trim()
    }

    if (!desktop && isLastItem) {
      noPadding = `${noPadding} bottom`.trim()
    }

    return (
      <AddressByPeriod
        key={index}
        cardMods={{ theme, 'no-padding': noPadding }}
        inputMods={inputModsRef.current}
        address={address}
        onAddressFieldChange={(fieldName, value) => handleAddressFieldChange(fieldName, value, index)}
        validation={validationsList[index]}
        countries={countries}
        mode={gotNonUK ? MODE_MANUAL_EDIT : MODE_SEARCH}
        notUkWarningText={notUkWarningText}
        infoVisible={index === 1}
        noPaddingBottom
        withPeriodsSelect
      />
    )
  })
}

const addressShape = PropTypes.shape({
  street: PropTypes.string,
  street2: PropTypes.string,
  postcode: PropTypes.string,
  country: PropTypes.number,
  city: PropTypes.string,
  months_at_address: PropTypes.number,
})

AddressesForm.propTypes = {
  addresses: PropTypes.shape({
    list: PropTypes.arrayOf(addressShape),
  }),
  validationsList: PropTypes.array,
  onAddressFieldChange: PropTypes.func,
  onAddressesChange: PropTypes.func,
}

export default AddressesForm
