import React from 'react'

import individual from './Individual/routes.jsx'
import business from './Business/routes.jsx'

import { SetupAccount } from './SetupAccount'

export default {
  isNewLayout: true,
  module: 'setup-account',
  path: 'setup-account/',
  component: function Component(props) {
    return <SetupAccount {...props} />
  },
  childRoutes: [individual, business],
}
