export const kinds = {
  personal: 'personal',
  personalIncome: 'personalIncome',
  isa: 'isa',
  isaIncome: 'isaIncome',
  business: 'business',
  businessIncome: 'businessIncome',
  diy: 'diy',
  isaDiy: 'isaDiy',
  sipp: 'sipp',
}

export const kindTitles = {
  [kinds.personal]: 'Growth portfolio',
  [kinds.personalIncome]: 'Income portfolio',
  [kinds.isa]: 'Growth portfolio',
  [kinds.isaIncome]: 'Income portfolio',
  [kinds.business]: 'Growth portfolio',
  [kinds.businessIncome]: 'Income portfolio',
  [kinds.diy]: 'DIY portfolio',
  [kinds.isaDiy]: 'DIY portfolio',
}

export const regulatoryTypes = {
  GIA: 'GIA',
  ISA: 'ISA',
  SIPP: 'SIPP',
}

export const isaTypes = {
  NEW: 'NEW',
  TRANSFER: 'TRANSFER',
}

export const portfolioManageTypes = {
  DIY: 'DIY',
  MANAGED: 'MANAGED',
  CASH: 'CASH',
  SELF_SELECTED: 'SELF_SELECTED',
}

export const presetTypes = {
  GROWTH: 'GROWTH',
  INCOME: 'INCOME',
  SELF_SELECTED: 'SELF_SELECTED',
}

export const states = {
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
  APPROVED: 'APPROVED',
  CLOSING: 'CLOSING',
  CLOSED: 'CLOSED',
}

export const emptyGoal = {
  id: null,
  regulatory_type: null,
  preset_type: null,
  is_fetched: false,
  title: null,
  initial_deposit: null,
  initial_deposit_recommended: null,
  one_off_payment: null,
  monthly_deposit: null,
  monthly_deposit_recommended: null,
  term: null,
  term_recommended: null,
  target: null,
  target_field_shown: false,
  current_balance: null,
  contributions: {
    total: null,
    year: null,
    month: null,
  },
  profit: {
    total: null,
    year: null,
    month: null,
  },
  twr: {
    total: null,
    year: null,
    month: null,
  },
  state: states.COMPLETED,
  preset: null,
  preset_changed: null,
  preset_modified: null,
  completed: false,
  sigma_up: null,
  sigma_down: null,
  created: null,
  modified: null,
  history_performance: [],
  history_contributions: [],
  history_income: [],
  history_twr: [],
  contributions_shown: false,
  visibleSections: {
    perfomance: false,
    portfolio: false,
  },
  projections: null,
  projections_fetched: false,
  confirm_data: false,
  messages: [],
  data_confirmed: null,
}
