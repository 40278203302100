import React from 'react'
import PropTypes from 'prop-types'

import Link from 'components/_old/Link/Link.jsx'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'

import './AddSecurities.css'

const AddSecurities = ({ onClick: handleClick }) => (
  <Link className="AddSecurities" onClick={handleClick}>
    <SelectableCard data-test-id="addSecuritiesButton">
      <Paper top={12} right={20} bottom={12} left={20}>
        <Typography color="inherit" align="center">
          <Typo>+ Add securities</Typo>
        </Typography>
      </Paper>
    </SelectableCard>
  </Link>
)

AddSecurities.propTypes = {
  onClick: PropTypes.func,
}

export { AddSecurities }
