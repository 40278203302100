import { useCallback, useLoading, useState } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'

import { showFailToast } from 'app/redux/actions/ui'
import { setup2fa } from 'app/redux/api/2fa'
import { ApiError } from 'app/redux/models/errors'

import { useReauth } from 'app/pages/Dashboard/UserProfile/hooks'

import { type PasswordStepProps } from '../PasswordStep'

type UsePasswordStepReturnProps = PasswordStepProps & {
  isLoading: boolean
}

const usePasswordStep = (): UsePasswordStepReturnProps => {
  const [password, setPassword] = useState('')
  const { isLoading, wait } = useLoading(false)

  const { confirmIdentity } = useReauth()

  const handleBack = useCallback(() => {
    goTo(urlTo('user-profile.two-factor-setup.first-step'))
  }, [])

  const handleContinue = useCallback(async () => {
    try {
      const authData = await wait(confirmIdentity(password))

      if (authData instanceof ApiError) {
        throw authData
      }

      const data = await wait(setup2fa({ action: 'enable', type: 'push_and_sms' }))

      if (data instanceof ApiError) {
        throw data
      }

      if (data.state === 'REQUESTED') {
        goTo(urlTo('user-profile.two-factor-setup.notification-step'))
        return
      }

      showFailToast()
    } catch (error) {
      if (error?.response?.data?.error === 'captcha') {
        const captchaToken = error.response?.data?.token

        if (captchaToken) {
          goTo(
            urlTo(
              'user-profile.two-factor-setup.protection',
              {},
              { token: captchaToken, back: 'user-profile.two-factor-setup.password-step', action: 'enable-2fa' },
            ),
          )
          return
        }
      }

      showFailToast(
        error?.response?.data?.error === 'wrong_password'
          ? 'Incorrect password'
          : 'Something went wrong, please try again or contact us at support@investengine.com',
      )
    }
  }, [password, confirmIdentity, wait])

  const handlePasswordChange = useCallback((event, value) => {
    setPassword(value)
  }, [])

  const validation = {
    password: { rules: [password], errors: ['Password can’t be empty'] },
  }

  return {
    isLoading,
    password,
    validation,
    handleBack,
    handlePasswordChange,
    handleContinue,
  }
}

export { usePasswordStep }
