/**
 * Gets key from object or returns object default
 * e.g.:
 *  - getKeyOrDefault({ foo: 'bar', default: 'baz' }, 'foo') → bar
 *  - getKeyOrDefault({ foo: 'bar', default: 'baz' }, 'oof') → baz
 *
 * @param {object} object
 * @param {string} key=default
 * @returns {*}
 */
const getKeyOrDefault = (object, key = 'default') => {
  if (typeof object === 'object' && typeof object[key] !== 'undefined') {
    return object[key]
  }

  if (typeof object === 'object' && typeof object.default !== 'undefined') {
    return object.default
  }

  return undefined
}

export { getKeyOrDefault }
