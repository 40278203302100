import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import List, { ListItem } from 'components/_old/List/List.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

const SippDeclarationText = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <Paper top={desktop ? 56 : 48}>
        <Typography size={24} weight="semibold" lineHeight="small" data-test-id="sippDeclarationSubTitle">
          <Typo>General SIPP Declaration</Typo>
        </Typography>
      </Paper>
      <Paper top={desktop ? 32 : 20}>
        <Typography size={14}>
          <List mods={{ type: 'bullet', 'no-margin': 'top' }}>
            <ListItem>
              <Typo>I apply to become a member of the InvestEngine Pension Plan.</Typo>
            </ListItem>
            <ListItem>
              <Paper top={8}>
                <Typo>
                  I acknowledge that I have been given the opportunity to carefully read the following documents that
                  together form the contract I am agreeing to enter into through making this application:
                </Typo>
              </Paper>
              <Paper top={16}>
                <List mods={{ type: 'bullet', 'no-margin': 'top', 'padding-left': 'big' }}>
                  <ListItem>
                    <Typo>
                      InvestEngine{' '}
                      <Link hard blank to="/key-features-documents-sipp/" data-test-id="sippDeclarationKeyLink">
                        <Typography size={14} color="inherit" inline>
                          SIPP Key Features Document;
                        </Typography>
                      </Link>
                    </Typo>
                  </ListItem>
                  <ListItem>
                    <Paper top={12}>
                      <Typo>
                        InvestEngine{' '}
                        <Link
                          hard
                          blank
                          to="https://public.investengine.com/pdf/Pre-Retirement-Illustration%3Fdate%3D121223.pdf"
                        >
                          <Typography size={14} color="inherit" inline>
                            SIPP Pre-Retirement Illustration;
                          </Typography>
                        </Link>
                      </Typo>
                    </Paper>
                  </ListItem>
                  <ListItem>
                    <Paper top={12}>
                      <Typo>
                        InvestEngine{' '}
                        <Link hard blank to="/terms-sipp/" data-test-id="sippDeclarationTermsLink">
                          <Typography size={14} color="inherit" inline>
                            SIPP Terms & Conditions.
                          </Typography>
                        </Link>
                      </Typo>
                    </Paper>
                  </ListItem>
                </List>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I confirm that I am not a US Person as defined by the US Internal Revenue Service (available on{' '}
                  <Link to="https://www.irs.gov/" hard blank data-test-id="sippDeclarationIrsLink">
                    <Typography size={14} color="inherit" inline>
                      www.irs.gov
                    </Typography>
                  </Link>
                  ).
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I confirm that, to the best of my knowledge and belief, the information I have given in making the
                  application and in any documents I have completed or information I have supplied relating to this
                  application is correct and complete.
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I accept full liability for any claim, loss or expense incurred by Quai Trustees Limited as a result
                  of incorrect information provided by me in this application.
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I agree to inform InvestEngine within 30 days of any changes in the details I have supplied in this
                  application.
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I understand that under the Terms and Conditions I am only entitled to receive money from my pension
                  plan as an ‘Authorised Payment’.
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  In the event of an unauthorised payment, I agree to Quai Investment Services Limited deducting the
                  amount of any scheme sanction, or other charge, levied by HMRC on Quai Investment Services Limited
                  from the funds held for me under the Scheme in order to pay the charge to HMRC. If there are
                  insufficient funds held for the Scheme I agree to pay Quai Investment Services Limited the amount by
                  which the charge exceeds the value of my funds in the Scheme.
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I will not require, nor attempt to acquire, the withdrawal of funds held to provide benefits for me
                  under the Scheme, or the income on those funds, other than in accordance with the Rules of the Scheme.
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I understand that death benefits are payable by Quai Trustees Limited to my beneficiaries as
                  determined at the discretion of Quai Investment Services Limited, who will take into account my
                  Expression of Wishes but cannot be bound by it.
                </Typo>
              </Paper>
            </ListItem>
          </List>
        </Typography>
      </Paper>
      <Paper top={desktop ? 56 : 48}>
        <Typography size={24} weight="semibold" lineHeight="small" data-test-id="sippDeclarationSubTitle">
          <Typo>Information Declaration</Typo>
        </Typography>
      </Paper>
      <Paper top={desktop ? 32 : 20}>
        <Typography size={14}>
          <List mods={{ type: 'bullet', 'no-margin': 'top' }}>
            <ListItem>
              <Typo>
                I acknowledge that the operation of my pension plan relies on the InvestEngine App communications.
              </Typo>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I will maintain the email address I have provided, monitor it regularly and inform InvestEngine
                  immediately if I change my email address.
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I agree to inform InvestEngine of any changes in the details I have supplied in this declaration.
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I confirm I will advise InvestEngine within 90 days if I flexibly access any pension benefits. I
                  understand that I will be subject to the Money Purchase Annual Allowance from the date I first
                  flexibly accessed any benefits.
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>I understand that if I have any Lifetime Allowance</Typo> protection(s){' '}
                <Typo>or enhancements it is my responsibility to provide evidence of the relevant</Typo> protection(s).{' '}
                <Typo>
                  I confirm that I will inform InvestEngine if I should lose or have revoked by Lifetime Allowance
                  protections or enhancements I may have. I give my authority for my protection details I provide to be
                  checked with HMRC.
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I agree to the carrying out of checks to establish proof of my identity and residence. Should these
                  checks prove unsatisfactory, I understand that I will be required to provide proof of identity that is
                  deemed satisfactory before my application can be accepted.
                </Typo>
              </Paper>
            </ListItem>
          </List>
        </Typography>
      </Paper>
    </Fragment>
  )
}

export { SippDeclarationText }
