import React from 'react'
import PropTypes from 'prop-types'

import { useState, useCallback, useEffect, useContext } from 'hooks'

const defaultAppHeight = `${window.innerHeight}px`
const AppHeightContext = React.createContext(defaultAppHeight)

const AppHeightProvider = ({ children }) => {
  const [appHeight, setAppHeight] = useState(defaultAppHeight)

  const handleAppHeightChange = useCallback(() => {
    const nextAppHeight = `${window.innerHeight}px`

    setAppHeight(nextAppHeight)
  }, [])

  const handleAppHeightChangeWithTimeout = useCallback(() => {
    handleAppHeightChange()
    setTimeout(handleAppHeightChange, 50)
  }, [])

  useEffect(() => {
    handleAppHeightChangeWithTimeout()

    window.addEventListener('resize', handleAppHeightChangeWithTimeout)

    return () => {
      window.removeEventListener('resize', handleAppHeightChangeWithTimeout)
    }
  }, [])

  return <AppHeightContext.Provider value={appHeight}>{children}</AppHeightContext.Provider>
}

AppHeightProvider.propTypes = {
  children: PropTypes.node,
}

const useAppHeight = () => {
  return useContext(AppHeightContext)
}

export { AppHeightProvider, AppHeightContext, useAppHeight }
