import * as React from 'react'
import { Fragment } from 'react'

import classNames from 'classnames/dedupe'

import { useCallback, useMediaQueries, useMemo, useState } from 'hooks'

import { urlTo } from 'helpers/router'
import { symbols } from 'helpers/typograph'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Gateway } from 'components/atoms/Gateway'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

import './SuitablePortfolio.css'

type SuitablePortfolioProps = {
  portfolioId?: number
  fixForOldEditGoal?: boolean
}

const SuitablePortfolio = ({ fixForOldEditGoal, portfolioId }: SuitablePortfolioProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const [open, setOpen] = useState(false)

  const questionnaireUrl = 'dashboard.user-profile.managed-portfolios.questionnaire-answers'

  const handleOpen = useCallback(
    (event?: React.MouseEvent<HTMLAnchorElement>) => {
      setOpen(true)

      if (event) {
        event.stopPropagation()
        event.preventDefault()
      }
    },
    [setOpen],
  )
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const headline = useMemo(() => <Typo>Suitable Portfolios</Typo>, [])
  const description = useMemo(
    () => (
      <Fragment>
        <Typo>
          When completing your questionnaire we assess which portfolios are suitable for you, balancing the desired
          reward with taking a level of risk that you are comfortable with. Depending on your answers to the
          questionnaire, some portfolios may not be suitable for you.
          <br />
          <br />
          If you believe this is incorrect, or your risk outlook has changed, you can retake the risk questionnaire
        </Typo>
        {portfolioId && (
          <span>
            {symbols.nbsp}
            <Typo>in the</Typo>
            <Link to={urlTo(questionnaireUrl, { goalId: portfolioId, id: portfolioId })}>
              {symbols.nbsp}
              profile section
            </Link>
          </span>
        )}
        <Typo>, or contact the support team.</Typo>
      </Fragment>
    ),
    [portfolioId, questionnaireUrl],
  )

  const button = useMemo(
    () => (
      <Typography size={16}>
        <Button mods={{ size: 'big block' }} onClick={handleClose}>
          OK
        </Button>
      </Typography>
    ),
    [handleClose],
  )

  const modalContent = useMemo(() => {
    if (desktop) {
      return (
        <Width size={65}>
          <Width size={40} center>
            <Paper top={48}>
              <Typography size={32} weight="semibold" lineHeight="small" align="center">
                {headline}
              </Typography>
            </Paper>
            <Paper top={24}>
              <Typography size={14} lineHeight="medium">
                {description}
              </Typography>
            </Paper>
            <Paper top={32} bottom={56}>
              <Width size={16} center>
                {button}
              </Width>
            </Paper>
          </Width>
        </Width>
      )
    }

    return (
      <MobileLayout
        header={<NavigationBar leftPartText="Back" onLeftPartClick={handleClose} />}
        content={
          <Fragment>
            <Paper top={0}>
              <Typography size={24} weight="semibold" lineHeight="small" align="center">
                {headline}
              </Typography>
            </Paper>
            <Paper top={32}>
              <Typography size={14} lineHeight="medium">
                {description}
              </Typography>
            </Paper>
          </Fragment>
        }
        footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
      />
    )
  }, [desktop, button, description, handleClose, headline])

  return (
    <Fragment>
      <Link
        className={classNames('SuitablePortfolio', { SuitablePortfolio_fixForOldEditGoal: fixForOldEditGoal })}
        mods={{ color: 'mutedToRed' }}
        onClick={handleOpen}
      >
        <Icon type="questionInCircleNoColor" size={18} rounded inline />
      </Link>
      <Gateway into="modals">
        <Modal open={open} onClose={handleClose}>
          {modalContent}
        </Modal>
      </Gateway>
    </Fragment>
  )
}

export { SuitablePortfolio }
