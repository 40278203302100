import { useUnit } from 'effector-react'

import { useSelector, useEffect, useActions, useContext } from 'hooks'

import { urlTo } from 'helpers/router.js'

import { $dictsStore } from 'app/effector/dicts'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'
import { isClientApproved, isClientNewOrNotCompleted } from 'app/redux/selectors'

import { type MenuItems } from 'components/molecules/Menu/MenuSection'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

import { usePromocode } from '../PersonalInfo/hooks/usePromocode'
import { useReferralCode } from '../ReferralCode/hooks/useReferralCode'

import { types as clientTypes } from 'constants/client'
import { manageTypes, regulatoryTypes } from 'constants/portfolio'

type UserProfileInterface = {
  generalMenuItems: MenuItems
  fundingMenuItems: MenuItems
  businessMenuItems: MenuItems
  userSettingsMenuItems: MenuItems
  accountMenuItems: MenuItems
  isPromoBannerDisplayed?: boolean
  isBusiness: boolean
  saveScrollPosition: () => void
}

const useProfile = (): UserProfileInterface => {
  usePromocode() // prefetch promocode early to prevent blinking
  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])

  const { saveScrollPosition, restoreScrollPosition } = useContext(ScrollSaverContext)
  const { isReferralCodeValid } = useReferralCode()

  const { isPromoEnabled } = useUnit($dictsStore)
  const isPromoBannerDisplayed = useSelector(isClientApproved) && isPromoEnabled && isReferralCodeValid
  const clientNotCompleted = useSelector(isClientNewOrNotCompleted)
  const clientType = useSelector<any, string>((state) => state.client.type)
  const isBusiness = clientType === clientTypes.BUSINESS

  const portfolios = useSelector((state) => state.portfolios)
  const hasPortfoliosWithFees =
    portfolios?.list?.filter(
      (portfolio) => portfolio.manage_type !== manageTypes.CASH || portfolio.regularoty_type === regulatoryTypes.SIPP,
    ).length > 0
  const hasHiddenPortfolios = portfolios.list.filterByShape({ display: { is_hidden: true } }).length > 0
  const managedPortfoliosWithAnswers = portfolios?.list
    .getVisiblePortfolios()
    .filter((portfolio) => portfolio.manage_type === manageTypes.MANAGED && !portfolio.is_default_preset_used)

  const generalMenuItems = [
    {
      title: 'Personal info',
      icon: 'user-24',
      link: urlTo('dashboard.user-profile.personal-info'),
      dataTestId: 'personalInfoLink',
      isVisible: true,
    },
    {
      title: 'Help',
      icon: 'help-24',
      link: 'https://help.investengine.com/',
      dataTestId: 'helpLink',
      hard: true,
      blank: true,
      isVisible: true,
    },
  ]

  const accountMenuItems = [
    {
      title: 'Log out',
      icon: 'logout-24',
      link: urlTo('logout'),
      dataTestId: 'logoutLink',
      isVisible: true,
      noArrow: true,
    },
  ]

  const fundingMenuItems = [
    {
      title: 'Linked banks & Savings Plans',
      icon: 'bank-24',
      link: urlTo('dashboard.user-profile.savings-plans'),
      dataTestId: 'fundingLink',
      isVisible: !clientNotCompleted,
    },
    {
      title: 'Fees',
      icon: 'coins-24',
      link: urlTo('dashboard.user-profile.fees'),
      dataTestId: 'feesLink',
      isVisible: hasPortfoliosWithFees,
    },
  ].filter((item) => item.isVisible)

  const businessMenuItems = [
    {
      title: 'Business Info',
      icon: 'information-new-24',
      link: urlTo('dashboard.user-profile.business-info'),
      dataTestId: 'businessInfoLink',
      isVisible: !clientNotCompleted,
    },
    {
      title: 'Directors and Shareholders',
      icon: 'people-24',
      link: urlTo('dashboard.user-profile.directors'),
      dataTestId: 'directorsLink',
      isVisible: !clientNotCompleted,
    },
  ].filter((item) => item.isVisible)

  const userSettingsMenuItems = [
    {
      title: 'Login settings',
      icon: 'user-settings-24',
      link: urlTo('dashboard.user-profile.login-settings'),
      dataTestId: 'loginSettingsLink',
      isVisible: true,
    },
    {
      icon: 'bell-24',
      title: 'Notifications',
      link: urlTo('dashboard.user-profile.notifications'),
      dataTestId: 'notificationsLink',
      isVisible: true,
    },
    {
      icon: 'appearance-24',
      title: 'Appearance',
      link: urlTo('dashboard.user-profile.appearance'),
      dataTestId: 'appearanceLink',
      isVisible: true,
    },
    {
      icon: 'answers-24',
      title: 'Managed portfolios',
      link: urlTo('dashboard.user-profile.managed-portfolios'),
      dataTestId: 'managedPortfoliosLink',
      isVisible: managedPortfoliosWithAnswers.length > 0,
    },
    {
      icon: 'hidden-24',
      title: 'Hidden portfolios',
      link: urlTo('dashboard.user-profile.hidden-portfolios'),
      dataTestId: 'hiddenPortfoliosLink',
      isVisible: hasHiddenPortfolios,
    },
  ].filter((item) => item.isVisible)

  useEffect(() => {
    restoreScrollPosition()

    if (!portfolios.items.length) {
      fetchPortfolios()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    generalMenuItems,
    fundingMenuItems,
    businessMenuItems,
    userSettingsMenuItems,
    accountMenuItems,
    isPromoBannerDisplayed,
    isBusiness,
    saveScrollPosition,
  }
}

export { useProfile }
