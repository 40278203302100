import React from 'react'

import { Amount } from './Amount.jsx'

export const routes = {
  module: 'amount',
  path: ':bankAccountId/amount/',
  component: function Component(props) {
    return <Amount {...props} />
  },
}
