import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'
import { useRebalance } from 'app/pages/Dashboard/Goals/DIY/Rebalance/hooks'

import { GatewayDest } from 'components/atoms/Gateway'
import { Preloader } from 'components/molecules/Preloader'
import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

const Rebalance = ({ params: { id } = {} } = {}) => {
  const { desktop } = useMediaQueries()
  const portfolioId = useMemo(() => parseInt(id, 10), [id])
  const { isLoading, securities, rebalanceOrder, redirectToPortfolio, handleConfirm } = useRebalance(portfolioId)

  return (
    <Fragment>
      {desktop ? (
        <Desktop
          order={rebalanceOrder}
          securities={securities}
          handleConfirm={handleConfirm}
          handleClose={redirectToPortfolio}
        />
      ) : (
        <Mobile
          order={rebalanceOrder}
          securities={securities}
          handleConfirm={handleConfirm}
          handleClose={redirectToPortfolio}
        />
      )}
      <GatewayDest name="toasts" />
      <Preloader loading={!!isLoading} size="big" zIndex />
    </Fragment>
  )
}

Rebalance.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export { Rebalance }
