import React, { Fragment } from 'react'

import { urlTo } from 'helpers/router'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { SecurityTitle } from 'components/molecules/SecurityTitle'

import { SECURITY_UNIVERSE_VERSION } from '../hooks'

type AddSecuritiesCardProps = {
  version: string
  hasAddedSecurities: boolean
  portfolioTitle: string
  addedItemsCount: number
  portfolioSecurities: any[]
  removableSecuritiesIds: string[]
  handleRemoveSecurity: (id: string) => () => void
  location: {
    query: Record<string, string>
  }
}

const AddSecuritiesCard = ({
  version,
  hasAddedSecurities,
  portfolioTitle,
  addedItemsCount,
  portfolioSecurities,
  removableSecuritiesIds,
  handleRemoveSecurity,
  location,
}: AddSecuritiesCardProps): React.ReactElement => {
  const card = (
    <Card>
      <Paper left={24} bottom={24} right={24} top={24}>
        {version === SECURITY_UNIVERSE_VERSION.INSIDE_PORTFOLIO && hasAddedSecurities ? (
          <Fragment>
            <Typography tag="span" size={16} color="additional" weight="semibold" lineHeight="small" inline>
              <Paper right={12}>
                <Typo>{portfolioTitle}</Typo>
              </Paper>
            </Typography>
            <Typography
              tag="span"
              size={16}
              color="minor"
              lineHeight="small"
              data-test-id="addedSecuritiesCounter"
              inline
            >
              <Typo>
                {addedItemsCount} {addedItemsCount > 1 ? 'securities' : 'security'}
              </Typo>
            </Typography>
            <Paper top={24}>
              {portfolioSecurities.map((security, index) => (
                <Paper bottom={24} key={security.id}>
                  <ItemWithIcon
                    space={0}
                    iconPosition="right"
                    icon={
                      removableSecuritiesIds.includes(security.id) ? (
                        <Link
                          mods={{ color: 'minorToRed' }}
                          role="button"
                          onClick={handleRemoveSecurity(security.id)}
                          data-test-id={`removeSecurity${index}Icon`}
                        >
                          <Paper left={16} top={8} bottom={8}>
                            <Icon className="EditableSecurity-DeleteIcon" size={16} type="delete-16" color="inherit" />
                          </Paper>
                        </Link>
                      ) : (
                        <Paper left={16} right={8}></Paper>
                      )
                    }
                    iconVerticalAlign="center"
                    contentVerticalAlign="center"
                    content={
                      <Link
                        to={urlTo(
                          'securities.security',
                          { id: security.id },
                          { ...location.query, back: window.location.pathname + window.location.search },
                        )}
                      >
                        <SecurityTitle
                          title={security.title}
                          logo={security.logo_uri}
                          ticker={security.ticker}
                          size={16}
                          space={16}
                          lineHeight="medium"
                          iconPosition="left"
                          overflow="ellipsis"
                          data-test-id={`addedSecurityTitle${index}`}
                        />
                      </Link>
                    }
                  />
                </Paper>
              ))}
            </Paper>
          </Fragment>
        ) : (
          <Paper top={16} bottom={24}>
            <Typo>
              <Typography
                size={24}
                align="center"
                color="minor"
                lineHeight="small"
                data-test-id="addSecuritiesToContinueText"
              >
                Add securities <br /> to continue
              </Typography>
            </Typo>
          </Paper>
        )}
      </Paper>
    </Card>
  )

  return card
}

export { AddSecuritiesCard }
