import React from 'react'

import { useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { TwoFactorAwait } from 'components/_old/TwoFactorAwait/TwoFactorAwait'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

type NotificationStepDesktopProps = {
  handleBack: () => void
  handleSuccess: () => void
  handleFailure: () => void
}

const Desktop = ({ handleBack, handleSuccess, handleFailure }: NotificationStepDesktopProps): React.ReactElement => {
  const backLink = useMemo(
    () => (
      <Link onClick={handleBack} mods={{ color: 'content-on-background-default' }}>
        <ItemWithIcon
          space={16}
          icon={<Icon type="back-new" size={24} color="inherit" />}
          content={<Typography color="inherit">Back</Typography>}
          iconVerticalAlign="center"
        />
      </Link>
    ),
    [handleBack],
  )

  const stepTitleNode = useMemo(
    () => (
      <Typography size={20} lineHeight="small" weight="semibold" align="center">
        <Typo>Step 3 of 3</Typo>
      </Typography>
    ),
    [],
  )

  const content = (
    <Inner twoColumns>
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column size={1}>{backLink}</Column>
        <Column size={2}>
          {stepTitleNode}
          <AllCenter>
            <Width size={36}>
              <TwoFactorAwait
                showTopBar={false}
                onAccept={handleSuccess}
                onDeny={handleFailure}
                shouldVerifySms={false}
              />
            </Width>
          </AllCenter>
        </Column>
        <Column size={1} />
      </ColumnarLayout>
    </Inner>
  )

  return <DesktopLayout header={<DesktopHeader />} content={content} footer={<DesktopFooter />} />
}

export { Desktop }
