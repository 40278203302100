import last from 'lodash/last.js'

const GUARD_PERCENTAGE = 5
const MINIMAL_SPREAD_WITH_NO_FLOAT = 10
const SMALLEST_ROUND = 0.1
const ROUND_TUPLES: Array<[number, number]> = [
  [1, SMALLEST_ROUND],
  [2, 1],
  [3, 10],
  [4, 100],
  [5, 1000],
  [6, 10000],
  [7, 100000],
  [8, 1000000],
  [9, 10000000],
]

function getRoundTo(number: number): number {
  return (
    ROUND_TUPLES.find(([value]) => Math.floor(number).toString().length <= value)?.[1] ?? last(ROUND_TUPLES)?.[1] ?? 1
  )
}

function getRounded(rawValue: number, roundTo: number, roundToCeil = true): number {
  const floor = rawValue - (rawValue % roundTo) === rawValue ? rawValue : rawValue - (rawValue % roundTo)

  if (roundToCeil) {
    return floor + roundTo
  }

  return floor
}

function getRoundedMin(rawMin: number, roundTo: number): number {
  if (roundTo > 1) {
    roundTo = 1
  }

  return rawMin - (rawMin % roundTo)
}

type GetRoundedMinMaxValuesInterface = {
  min: number
  max: number
  withFloat: boolean
}

function getRoundedMinMaxValues(rawMin: number, rawMax: number, withGuard = true): GetRoundedMinMaxValuesInterface {
  // get the first, raw, values
  let diff = rawMax - rawMin
  let roundTo = getRoundTo(diff)
  let max = getRounded(rawMax, roundTo)
  let min = getRoundedMin(rawMin, roundTo)

  if (withGuard) {
    // add guard to max/min
    const guard = (diff / 100) * GUARD_PERCENTAGE
    min = min - guard
    max = max + guard / 2

    if (min < 0) {
      min = 0
    }

    min = getRounded(min, roundTo, false)
    max = getRounded(max, roundTo)
  }

  // get the second, precise, values
  diff = max - min
  roundTo = getRoundTo(diff)
  max = getRounded(max, roundTo)
  min = getRoundedMin(min, roundTo)

  if (min < 0) {
    min = 0
  }

  const withFloat = diff < MINIMAL_SPREAD_WITH_NO_FLOAT

  return {
    min,
    max,
    withFloat,
  }
}

export { getRoundedMinMaxValues }
