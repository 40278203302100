import * as React from 'react'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { Typography } from 'components/atoms/Typography'

type NoTradingSecurityProps = {
  title: string
  logo: string
}

const NoTradingSecurity = ({ title, logo }: NoTradingSecurityProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Card level={0}>
      <Paper top={16} bottom={16} right={16} left={16}>
        <ItemWithIcon
          icon={<SecurityLogo logo={logo} size={desktop ? 48 : 36} />}
          content={
            <Typography size={desktop ? 16 : 14}>
              <Typo>{title}</Typo>
            </Typography>
          }
          space={16}
          contentVerticalAlign="center"
        />
      </Paper>
    </Card>
  )
}

export { NoTradingSecurity }
