import { createEvent, createStore, createEffect, sample } from 'effector'

import { trackEvent } from 'helpers/analytics'
import { replace as historyReplace } from 'helpers/history.js'
import { urlTo, goTo } from 'helpers/router.js'

import { createStateMachine } from 'app/effector/stateMachine'

import { fetchClient } from 'app/redux/actions/client'
import store from 'app/redux/store/store.js'

import { getAuthFactors } from './api'
import { State, Event, states } from './states'

import type { AuthFactor } from './types'

// Events
const handleAccept = createEvent()
const setQuery = createEvent<any>()
const setIsPhoneVerified = createEvent<boolean>()

// Effects
const handleAcceptFx = createEffect(async ({ query, isPhoneVerified }) => {
  const { token, client_id: clientId, encoded_client_id: encodedClientId, next, ...nextQuery } = query

  if (token && clientId) {
    goTo(urlTo('set-new-password', { uid: encodedClientId, token }, { isTwoFactorAuthEnabled: true, isPhoneVerified }))
  }
  await store.dispatch(fetchClient())

  trackEvent({ action: 'login' })

  if (next) {
    historyReplace(next, nextQuery)
    return
  }

  goTo(urlTo('dashboard', null, nextQuery))
})

const fetchAuthFactorsFx = createEffect(async () => {
  const result = await getAuthFactors()

  return result
})

// Stores
const $query = createStore({})
const $isPhoneVerified = createStore(false)
const $authFactors = createStore<AuthFactor[]>([])

// Store updates
$query.on(setQuery, (_, query) => query)
$isPhoneVerified.on(setIsPhoneVerified, (_, isPhoneVerified) => isPhoneVerified)
$authFactors.on(fetchAuthFactorsFx.doneData, (state, data) => data.second_auth_factors)

sample({
  clock: handleAccept,
  source: [$query, $isPhoneVerified],
  fn: ([query, isPhoneVerified]) => ({ query, isPhoneVerified }),
  target: handleAcceptFx,
})

const { $currentState, sendEvent } = createStateMachine<State, Event>({
  id: '2fa',
  initial: State.INITIAL,
  states,
  actions: {
    logout: () => {
      goTo(urlTo('logout'), { replace: true })
    },
    handleAccept,
  },
})

const $2faState = $currentState.map((state) => state)

export { $2faState, sendEvent, setQuery, setIsPhoneVerified, State, Event, fetchAuthFactorsFx, $authFactors }
