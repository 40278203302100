import localstore from 'helpers/localstore.js'

import { rewriteBaseUrl } from './rewriteBaseUrl.js'

const e2eTests = ({ request }) => {
  if (localstore.get('e2e') && request) {
    if (request.baseURL) {
      rewriteBaseUrl(request.baseURL.match(/https?:\/\/[\w.]*\//)[0], 'http://localhost/')({ request })
    }

    if (request.headers?.Accept) {
      delete request.headers.Accept
    }

    request.withCredentials = false
  }
}

export { e2eTests }
