import axios from 'axios'

import ourAxios from 'helpers/ajax'
import { sendError } from 'helpers/errorLogging.js'

import { showFailToast } from 'app/redux/actions/ui'
import { ApiError } from 'app/redux/models/errors'

const POSTCODES_IO_API_ROOT = 'https://api.postcodes.io/'

function errorReport(error) {
  if (error.config) {
    const errorObj = new Error(`Ajax "${error.config.method}" at "${error.config.url}" failed`)
    sendError(errorObj, error)
  } else {
    sendError(error)
  }
  showFailToast()
}

function uppercaseOnlyFirst(str) {
  return str.charAt(0) + str.slice(1).toLowerCase()
}

function processCountry({ country = '' } = {}, postcode = '') {
  // special case for Channel Islands' crown dependencies
  if (/^(je|gy)/.test(postcode.toLowerCase())) {
    return /^(je)/.test(postcode.toLowerCase()) ? 'Jersey' : 'Guernsey'
  }

  // special case for United Kingdom
  const countriesInUK = ['england', 'scotland', 'wales', 'northern ireland']

  if (countriesInUK.includes(country.toLowerCase())) {
    return 'United Kingdom'
  }

  return country
}

export async function autocompletePostcode(postcode) {
  const url = POSTCODES_IO_API_ROOT + 'postcodes/' + postcode + '/autocomplete'

  if (!postcode) {
    return []
  }

  return await axios
    .get(url)
    .then((response) => {
      return response.data?.result || []
    })
    .catch((error) => {
      errorReport(error)
      return []
    })
}

export function searchByPostcode(postcode) {
  const filter = 'line_1,line_2,line_3,post_town,country'
  const url = `/ideal_postcodes/postcodes/${postcode}/`

  return ourAxios
    .get(url, { filter })
    .then((response) =>
      response.data?.map((address) => {
        let street
        let street2

        if (address.line_3) {
          street = address.line_1 + ' ' + address.line_2
          street2 = address.line_3
        } else {
          street = address.line_1
          street2 = address.line_2
        }
        return {
          street,
          street2,
          city: uppercaseOnlyFirst(address.post_town),
          postcode,
          countryName: processCountry(address, postcode),
        }
      }),
    )
    .catch((error) => {
      if (error instanceof ApiError && error.response.status !== 404) {
        errorReport(error)
      }

      return []
    })
}
