import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { Preloader } from 'components/molecules/Preloader'

import { useReportFilters, type UseReportsInterface, type UseReportFiltersInterface } from '../hooks'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

function ReportList(props: UseReportsInterface): React.ReactElement {
  const { desktop } = useMediaQueries()
  const filterProps = useReportFilters(props.reports)
  const childProps = { ...props, ...filterProps }

  return (
    <Fragment>
      {desktop ? <Desktop {...childProps} /> : <Mobile {...childProps} />}

      <Preloader loading={props.isLoading} size="big" zIndex />
      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

type ReportListChildProps = UseReportsInterface & UseReportFiltersInterface

export { ReportList, type ReportListChildProps }
