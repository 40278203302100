/* eslint-disable @typescript-eslint/naming-convention */
import pick from 'lodash/pick'
import qs from 'qs'

import axios from 'helpers/ajax'
import { identify, analyticsCIO } from 'helpers/analytics'
import { sendError } from 'helpers/errorLogging.js'
import { ValidationError, isIncorrectPasswordError } from 'helpers/errors'
import { features } from 'helpers/features'
import localstore from 'helpers/localstore.js'

import { resetSignificantControls } from 'app/effector/significant-controls'

import * as api from 'app/redux/api/client'

import { resetGoals } from '../portfolios'

import {
  SET_NOT_VALID,
  SET_VALID,
  RECEIVE_FIELD,
  RECEIVE_ERROR,
  RESET_CLIENT,
  RESET_ERROR,
  RECEIVE_CLIENT,
} from './clientActionTypes.js'

import { regulatoryTypes } from 'constants/portfolio'

export function saveFieldToLocalStorage(field) {
  return localstore.set('client', { ...localstore.get('client'), ...field })
}

export function setNotValid() {
  return { type: SET_NOT_VALID }
}

export function setValid() {
  return { type: SET_VALID }
}

export function receiveClient(client) {
  client.is_fetched = true

  return { type: RECEIVE_CLIENT, client }
}

export function resetClient() {
  return { type: RESET_CLIENT }
}

export function receiveError(error, sendErrorToSentry = true) {
  if (!(error instanceof ValidationError) && sendErrorToSentry) {
    sendError(error)
  }
  return { type: RECEIVE_ERROR, error }
}

export function resetError() {
  return { type: RESET_ERROR }
}

export function receiveField(field) {
  return async (dispatch, getState) => {
    return await new Promise((resolve) => {
      dispatch(resetError())
      dispatch({ type: RECEIVE_FIELD, field })
      resolve(getState())
    })
  }
}

export function changeField(field) {
  return async (dispatch, getState) => {
    try {
      if (typeof field.access_token !== 'undefined') {
        saveFieldToLocalStorage(field)
      }
      await dispatch(receiveField(field))
      return getState()
    } catch (error) {
      dispatch(receiveError(error))
      return error
    }
  }
}

export function fetchClient(access_token, setValidAfter = true, setValidBefore = true) {
  const config = {
    url: 'client/',
    method: 'get',
    data: { access_token },
  }

  return (dispatch, getState) => {
    const { client_id, id: prevId } = getState().client
    config.data = { ...config.data, client_id }

    if (setValidBefore) {
      dispatch(setNotValid())
    }

    return axios(config)
      .then((response) => {
        const { password, ...client } = response.data

        saveFieldToLocalStorage({ state: client.state, id: client.id })

        if (client.id !== prevId) {
          identify(client.id, { clientId: client.id, clientType: client.type })

          if (client.email) {
            analyticsCIO?.identify({
              id: client.email,
            })
          }
        }

        dispatch(receiveClient(client))
      })
      .then(() => {
        if (setValidAfter) {
          dispatch(setValid())
        }
      })
      .catch((error) => {
        dispatch(receiveError(error))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function logout(setValidAfter = true) {
  const config = {
    url: 'oauth/revoke-token/',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }

  return async (dispatch, getState) => {
    const { client } = getState()
    const { access_token, client_id } = client
    config.data = qs.stringify({ token: access_token, client_id })

    dispatch(setNotValid())

    try {
      if (access_token) {
        await axios(config)

        features.clear()
        localstore.set('hintsShown', {})
        saveFieldToLocalStorage({ access_token: null, state: null, id: null })

        dispatch(resetClient())
        resetSignificantControls()
        dispatch(resetGoals(false))
      }

      if (setValidAfter) {
        dispatch(setValid())
      }
    } catch (error) {
      dispatch(receiveError(error))
      if (setValidAfter) {
        dispatch(setValid())
      }
    }
    return getState()
  }
}

export function login({ loginIdentifier, loginPassword, client_id }) {
  const config = {
    url: 'oauth/token',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({
      username: loginIdentifier,
      password: loginPassword,
      client_id,
      grant_type: 'password',
    }),
  }

  return (dispatch, getState) => {
    dispatch(setNotValid())
    return axios(config)
      .then((response) => {
        const { access_token, scope } = response?.data ? response.data : {}

        if (!access_token) {
          throw new Error('Got no access_token after login')
        }
        dispatch(changeField({ access_token }))
        dispatch(changeField({ scope }))
        if (scope === '2fa_needed') dispatch(setValid())
        if (scope === 'fully_authorised') dispatch(fetchClient(access_token))
      })
      .catch((error) => {
        const shouldSendToSentry = !isIncorrectPasswordError(error)
        dispatch(receiveError(error, shouldSendToSentry))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function refreshToken({ username, refresh_token, client_id }) {
  const config = {
    url: 'oauth/token',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({
      username,
      refresh_token,
      client_id,
      grant_type: 'refresh_token',
    }),
  }

  return (dispatch, getState) => {
    dispatch(setNotValid())

    return axios(config)
      .then((response) => {
        const { access_token, refresh_token } = response?.data ? response.data : {}

        if (refresh_token) {
          dispatch(changeField({ refresh_token }))
        }

        if (access_token) {
          dispatch(changeField({ access_token }))
        }

        dispatch(setValid())
      })
      .catch((error) => {
        dispatch(receiveError(error))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function resetCaptchaCounter({ loginIdentifier, captcha, client_id }) {
  const config = {
    url: 'oauth/recaptcha/',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({ username: loginIdentifier, token: captcha, client_id }),
  }

  return () => axios(config)
}

export function forgotPassword({ email }) {
  return (dispatch, getState) => {
    dispatch(setNotValid())
    return axios('client/password/reset', { method: 'POST', data: { email } })
      .then(() => {
        dispatch(setValid())
      })
      .catch((error) => {
        dispatch(receiveError(error))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function setNewPassword({ newPassword, newPasswordConfirm, uid, token }) {
  return (dispatch, getState) => {
    dispatch(setNotValid())
    return axios('client/password/reset/confirm/', {
      method: 'POST',
      data: { new_password1: newPassword, new_password2: newPasswordConfirm, uid, token },
    })
      .then(() => {
        dispatch(setValid())
      })
      .catch((error) => {
        dispatch(receiveError(error))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function changePassword({ old_password, new_password1, new_password2 }) {
  return (dispatch, getState) => {
    dispatch(setNotValid())
    return axios(`client/password/change/`, {
      method: 'POST',
      data: { old_password, new_password1, new_password2 },
    })
      .then(() => {
        dispatch(setValid())
      })
      .catch((error) => {
        dispatch(receiveError(error))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function resendEmail({ email, setValidAfter = true } = {}) {
  return (dispatch, getState) => {
    const { client } = getState()
    email = email || client.email

    dispatch(setNotValid())
    return axios(`client/verification_email/`, {
      method: 'PATCH',
      data: { email },
    })
      .then(
        (response) => response,
        (error) => dispatch(receiveError(error)),
      )
      .then(() => {
        if (setValidAfter) {
          dispatch(setValid())
        }

        return getState()
      })
  }
}

export function updateOrCreate(keys, setValidAfter = true, setNotValidBefore = true) {
  return (dispatch, getState) => {
    let { ...client } = getState().client
    const { access_token, id: prevId } = client

    if (keys) {
      client = pick(client, keys)
    }

    const config = {
      url: `client/`,
      method: access_token ? 'patch' : 'post',
      data: client,
    }

    if (setNotValidBefore) {
      dispatch(setNotValid())
    }

    return axios(config)
      .then((response) => {
        const { data: client } = response
        const { access_token, refresh_token } = client

        dispatch(receiveClient(client))

        if (access_token) {
          dispatch(changeField({ access_token }))
        }
        if (refresh_token) {
          dispatch(changeField({ refresh_token }))
        }

        const clientFromLocalStorage = localstore.get('client') || {}

        if (client.state !== clientFromLocalStorage.state) {
          saveFieldToLocalStorage({ state: client.state })
        }

        if (client.id !== prevId) {
          saveFieldToLocalStorage({ id: client.id })

          identify(client.id, { clientId: client.id, clientType: client.type })

          if (client.email) {
            analyticsCIO?.identify({
              id: client.email,
            })
          }
        }
        if (setValidAfter) {
          dispatch(setValid())
        }
      })
      .catch((error) => {
        const extendedError = (() => {
          if (error.response && error.response.status === 400 && error.response.data) {
            return new ValidationError(error.message, error.response.data)
          }
          return error
        })()
        dispatch(receiveError(extendedError))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function sendMessage({ message, email, name }) {
  return (dispatch, getState) => {
    dispatch(resetError())
    dispatch(setNotValid())
    return axios
      .post(`client/message/`, { message, email, name })
      .then((_response) => {
        dispatch(setValid())
      })
      .catch((error) => {
        dispatch(receiveError(error))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function signDeclaration({ declarationType } = {}) {
  return async (dispatch, getState) => {
    const { client } = getState()

    dispatch(setNotValid())

    const result = await api.signDeclaration(declarationType)

    if (result instanceof Error) {
      dispatch(receiveError(result))
    } else {
      const newClient = {
        ...client,
        agreed_with_sipp_declaration:
          declarationType === regulatoryTypes.SIPP ? true : client.agreed_with_sipp_declaration,
        should_sign_isa_declaration:
          declarationType === regulatoryTypes.ISA ? false : client.should_sign_isa_declaration,
      }
      dispatch(receiveClient(newClient))
    }
    dispatch(setValid())

    return getState()
  }
}
