import React from 'react'

import { ThirdPartyAuth } from './ThirdPartyAuth'

export default {
  module: 'third-party-auth',
  path: 'third-party-auth/',
  name: 'Third party auth',
  isNewLayout: true,
  component: function Component(props) {
    return <ThirdPartyAuth {...props} />
  },
}
