import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

import { AnswerValue } from './AnswerValue'

/**
 * @class
 * @typedef {Object} Question
 */
export class Question extends Entity {
  constructor() {
    super()
    /** @type {?number} */
    this.id = null
    /** @type {?number} */
    this.questionnaire_id = null
    /** @type {?string} */
    this.title = null
    /** @type {?Object} */
    this.description = null
    /** @type {?string} */
    this.type = null
    /** @type {?string} */
    this.code = null
    /** @type {?Array} */
    this.answers = null
  }

  /** @override */
  getRules() {
    return {
      id: [[rules.int]],
      questionnaire_id: [[rules.int]],
      code: [[rules.string]],
      title: [[rules.string]],
      type: [[rules.string]],
      answers: [[(value) => value.map((answer) => AnswerValue.createFromObject(answer))]],
    }
  }
}
