import React from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import EventEmitter from 'eventemitter3'
import debounce from 'lodash/debounce'

import getDisplayName from 'helpers/getDisplayName.js'

let EE
let viewport = { width: 1366, height: 768 } // Default size for server-side rendering
const RESIZE_EVENT = 'resize'

const handleWindowResize = debounce(() => {
  if (viewport.width !== window.innerWidth || viewport.height !== window.innerHeight) {
    viewport = { width: window.innerWidth, height: window.innerHeight }
    EE.emit(RESIZE_EVENT, viewport)
  }
}, 250)

export default function withMediaQueries(mediaQueries = {}) {
  return function wrap(WrappedComponent) {
    const wrappedComponentName = getDisplayName(WrappedComponent)

    function getMatchedQueries(context) {
      const matchQueries = {}

      for (const className in mediaQueries) {
        matchQueries[className] = window.matchMedia(mediaQueries[className]).matches
      }

      return matchQueries
    }

    class MediaQueries extends React.Component {
      static contextTypes = {
        static: PropTypes.bool,
      }

      state = {
        mediaQueries: {
          ...getMatchedQueries(this.context),
        },
      }

      handleResize(value) {
        // eslint-disable-next-line
        const componentNode = findDOMNode(this)

        if (document.body.contains(componentNode)) {
          this.setState({
            mediaQueries: {
              ...getMatchedQueries(this.context),
            },
          })
        }
      }

      componentDidMount() {
        if (!EE) {
          EE = new EventEmitter()
          window.addEventListener('resize', handleWindowResize)
          window.addEventListener('orientationchange', handleWindowResize)
        }
        EE.on('resize', this.handleResize, this)
      }

      componentWillUnmount() {
        EE.removeListener(RESIZE_EVENT, this.handleResize, this)
        if (!EE.listeners(RESIZE_EVENT, true)) {
          window.removeEventListener('resize', handleWindowResize)
          window.removeEventListener('orientationchange', handleWindowResize)
          EE = null
        }
      }

      render() {
        return <WrappedComponent mediaQueries={this.state.mediaQueries} {...this.props} />
      }
    }

    MediaQueries.displayName = `MediaQueries(${wrappedComponentName})`

    return MediaQueries
  }
}

export function getMediaQuieryClasses(rootClass, mediaQueries) {
  return Object.keys(mediaQueries).map((className) => {
    return mediaQueries[className] ? `${rootClass}_${className}` : ''
  })
}
