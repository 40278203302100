import last from 'lodash/last'

import { useCallback, useEffect, useMediaQueries, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'

import { regulatoryTypes } from 'constants/portfolio'

const useTypeChoose = ({ flow, initialStep = null, onSubmit, onBeforeClose, query = {} }) => {
  const { desktop } = useMediaQueries()

  const firstStepName = flow.steps[0][0]
  const [activeStepName, setActiveStepNameState] = useState(null)
  const [selectedData, setSelectedData] = useState(flow.preselectedData)
  const [showLeftToRightAnimation, setShowLeftToRightAnimation] = useState(false)
  const [stepsWay, setStepsWay] = useState([])
  const prevStepName = last(stepsWay) ?? null

  useEffect(() => {
    setActiveStepNameState(initialStep ?? firstStepName)
  }, [firstStepName, initialStep])

  useEffect(() => {
    if (activeStepName === firstStepName && selectedData.length > 1) {
      setSelectedData([])
    }
  }, [activeStepName, firstStepName, selectedData])

  // setTimeout used to trigger opening animation on the next tick after `showLeftToRightAnimation` is triggered
  // const setActiveStepName = useCallback(
  //   (name) => {
  //     setTimeout(() => {
  //       setActiveStepNameState(name)
  //     }, 1)
  //   },
  //   [setActiveStepNameState],
  // )
  const setActiveStepName = setActiveStepNameState

  const handleReset = useCallback(() => {
    setShowLeftToRightAnimation(true)
    setActiveStepName(firstStepName)
    setSelectedData([])
    setStepsWay([])
  }, [firstStepName, setActiveStepName, setSelectedData, setShowLeftToRightAnimation, setStepsWay])

  const handleBack = useCallback(
    (prevStepName) => {
      const newSelectedData = selectedData.slice(0, -1)
      const newStepsWay = stepsWay.slice(0, -1)

      setShowLeftToRightAnimation(true)
      setSelectedData(newSelectedData)
      setStepsWay(newStepsWay)
      setActiveStepName(prevStepName)
    },
    [selectedData, setSelectedData, setActiveStepName, setShowLeftToRightAnimation, stepsWay, setStepsWay],
  )

  const handleSelect = useCallback(
    (name, getNextStep, prevStep, analytics) => {
      const nextData = [...(selectedData ?? []), name]
      const nextStep = getNextStep?.(nextData) ?? null

      if (analytics) {
        trackEvent(analytics, { plugins: { 'google-analytics-v3': false } })
      }

      setShowLeftToRightAnimation(false)
      setSelectedData(nextData)

      if (nextStep !== null) {
        setStepsWay([...stepsWay, prevStep])

        setActiveStepName(nextStep)
        return
      }

      if (typeof onSubmit === 'function') {
        let data = nextData

        if (query.regulatoryType) {
          data = [...nextData, query.regulatoryType]
        }

        if (query.manageType) {
          data = [query.manageType, ...nextData]
        }

        onSubmit(data, prevStep)
      }
    },
    [
      onSubmit,
      setActiveStepName,
      selectedData,
      setSelectedData,
      setShowLeftToRightAnimation,
      stepsWay,
      setStepsWay,
      query.regulatoryType,
      query.manageType,
    ],
  )

  const handleClose = useCallback(() => {
    if (typeof onBeforeClose === 'function') {
      onBeforeClose()
    }

    if (!prevStepName) {
      return
    }

    if (desktop) {
      handleReset()
      return
    }

    handleBack(prevStepName)
  }, [onBeforeClose, prevStepName, desktop, handleReset, handleBack])

  const steps = flow.steps.map(([stepName, elements, headline]) => {
    const mobileAnimation = showLeftToRightAnimation ? 'leftToRight' : 'rightToLeft'
    const animation = desktop ? 'bottomToTop' : mobileAnimation

    const showDefaultSippOption =
      selectedData.includes(regulatoryTypes.SIPP) || query.regulatoryType === regulatoryTypes.SIPP

    const filterManagedTypes = (types) => {
      return types.filter((type) => {
        if (!showDefaultSippOption && type.name === 'MANAGED_DEFAULT') {
          return false
        }

        return true
      })
    }

    const stepElements = stepName === 'MANAGED_TYPE' ? filterManagedTypes(elements) : elements

    return {
      stepName,
      elements: stepElements,
      headline,
      animation,
      onClose: handleClose,
      onSelect: (element) => {
        handleSelect(element.name, element.goTo, stepName, element?.analytics)
      },
    }
  })

  return {
    steps,
    activeStepName,
  }
}

export { useTypeChoose }
