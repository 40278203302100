import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

type SettingsLayoutProps = {
  title: string
  back?: string
  children: React.ReactNode
}

const SettingsLayout = ({ title, back, children }: SettingsLayoutProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const headerNode = <DesktopHeader />

  const handleBack = (): void => {
    goTo(back ?? urlTo('dashboard.user-profile'))
  }

  const navbarNode = (
    <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
      <Typo>&nbsp</Typo>
    </NavigationBar>
  )

  const content = (
    <Paper top={desktop ? 40 : 0} bottom={80}>
      <Typography
        size={desktop ? 32 : 20}
        lineHeight="small"
        weight="semibold"
        align={desktop ? 'center' : 'left'}
        data-test-id="pageTitle"
      >
        {title}
      </Typography>
      <Paper top={desktop ? 48 : 40}>{children}</Paper>
    </Paper>
  )

  return (
    <Fragment>
      {desktop ? (
        <DesktopLayout
          header={headerNode}
          footer={<DesktopFooter />}
          content={
            <Inner twoColumns>
              {navbarNode}
              <AllCenter>
                <Width size={36}>{content}</Width>
              </AllCenter>
            </Inner>
          }
        />
      ) : (
        <MobileLayout header={navbarNode} content={content} />
      )}

      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
      <GatewayDest name="change-company-address" />
    </Fragment>
  )
}

export { SettingsLayout }
