import React, { Fragment } from 'react'

import { QuestionWrapper } from 'app/containers/Questionnaire/QuestionWrapper.jsx'

import { isClientNewOrNotCompleted } from 'app/redux/selectors'

import { YearlyReview } from './YearlyReview.jsx'
import { Layout } from './Layout'
import { Information } from './Information'
import { QuestionnaireRoot, QuestionnaireOptimalPortfolio } from './QuestionnaireRoot'
import { Retake, LocallySaveAnswer, LastStep, RetakeOptimalPortfolio } from './Questionnaire'
import { features } from 'helpers/features'
import { Disclaimer } from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/Disclaimer'
import { AllAnswers } from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/AllAnswers'
import { AllAnswersWrapper } from 'app/pages/YearlyReview/Questionnaire/AllAnswersWrapper'

const optimalPortfolioRoute = {
  module: 'optimal-portfolio',
  path: 'optimal-portfolio',
}

const getRetakeRoutes = () => {
  let childRoutes = []

  if (features.get('new-questionnaire')) {
    const questionnaireRoutes = {
      insertableQuestions: true,
      path: 'question/:questionCode/',
      module: 'question',
      component: function Component(props) {
        return (
          <Fragment>
            <QuestionWrapper {...props} analyticsCategory="Yearly review" defaultQuestionWrapper={LocallySaveAnswer} />
          </Fragment>
        )
      },
    }

    childRoutes = [
      {
        index: true,
        module: 'get-started',
        headline: 'Which portfolio is right for you?',
        feeInfo: false,
        firstStep: true,
        isQuestion: false,
        withGlobalInner: false,
        withGlobalHeader: false,
        withGlobalFooter: false,
        component: function Component(props) {
          return <Disclaimer {...props} withDesktopLayout={false} backLinkModule="yearly-review.questionnaire" />
        },
      },
      questionnaireRoutes,
      {
        module: 'all-answers',
        path: 'all-answers/',
        headline: 'Please confirm that all answers are correct',
        feeInfo: false,
        firstStep: true,
        isQuestion: false,
        withGlobalInner: false,
        withGlobalHeader: false,
        withGlobalFooter: false,
        component: function Component(props) {
          return (
            <LastStep {...props}>
              <AllAnswersWrapper {...props}>
                <AllAnswers withDesktopLayout={false} />
              </AllAnswersWrapper>
              <RetakeOptimalPortfolio {...props} />
            </LastStep>
          )
        },
        childRoutes: [optimalPortfolioRoute],
      },
    ]
  } else {
    const questionnaireRoutes = {
      insertableQuestions: true,
      path: ':questionCode/',
      module: 'question',
      component: function Component(props) {
        return (
          <Fragment>
            <RetakeOptimalPortfolio {...props} />
            <QuestionWrapper
              {...props}
              analyticsCategory="Yearly review"
              defaultQuestionWrapper={LocallySaveAnswer}
              lastQuestionWrapper={(props) => (
                <LastStep {...props}>
                  <LocallySaveAnswer {...props} />
                </LastStep>
              )}
            />
          </Fragment>
        )
      },
      childRoutes: [optimalPortfolioRoute],
    }

    childRoutes = [questionnaireRoutes]
  }

  return [
    {
      module: 'questionnaire-retake',
      path: 'questionnaire/retake/',
      name: 'Yearly Review Retake',
      component: function Component(props) {
        return <Retake {...props} />
      },
      childRoutes,
      indexRoute: childRoutes[0],
    },
  ]
}

const retakeRoutes = getRetakeRoutes()

const childRoutes = [
  {
    module: 'information',
    indexRoute: true,
    component: function Component(props) {
      return (
        <Layout {...props} progressFill={0} backLinkModule="dashboard" backLinkText="My Dashboard">
          <Information {...props} />
        </Layout>
      )
    },
    childRoutes: [
      {
        module: 'change-company-address',
        path: 'change-company-address/',
      },
      {
        module: 'change-address',
        path: 'change-address/',
      },
    ],
  },
  {
    module: 'questionnaire',
    path: 'questionnaire/',
    component: function Component(props) {
      return (
        <Fragment>
          <QuestionnaireOptimalPortfolio {...props} />
          <Layout
            {...props}
            progressFill={50}
            backLinkModule={
              isClientNewOrNotCompleted({ client: props.client }) ? 'dashboard' : 'yearly-review.information'
            }
            backLinkText={
              isClientNewOrNotCompleted({ client: props.client }) ? 'My Dashboard' : 'Please review your information'
            }
          >
            <QuestionnaireRoot {...props} />
          </Layout>
        </Fragment>
      )
    },
    childRoutes: [optimalPortfolioRoute],
  },
  ...retakeRoutes,
]

export default {
  index: true,
  module: 'yearly-review',
  path: 'yearly-review/:goalId/',
  name: 'Yearly Review',
  component: function Component(props) {
    return <YearlyReview {...props} />
  },
  childRoutes,
  indexRoute: childRoutes.find(({ indexRoute }) => indexRoute),
}
