import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { goTo, urlTo } from 'helpers/router.js'

import { useActions, useCallback, useMemo } from 'hooks'
import { usePendingOrders } from 'app/pages/Dashboard/Goals/DIY/hooks'
import { useSecurities } from 'app/pages/Securities/hooks'
import { Preloader } from 'components/molecules/Preloader'
import { GatewayDest } from 'components/atoms/Gateway'

import { SecurityOrdersPage } from 'app/pages/Dashboard/Securities/SecurityOrdersPage/SecurityOrdersPage.jsx'

import { showSupportModal as showSupportModalActionCreator } from 'app/redux/actions/ui'

const SecurityOrders = ({ params: { id: portfolioIdString, securityId: securityIdString } = {} }) => {
  const portfolioId = useMemo(() => parseInt(portfolioIdString), [portfolioIdString])
  const securityId = useMemo(() => parseInt(securityIdString), [securityIdString])
  const { securities, isLoading: isSecuritiesLoading } = useSecurities({ portfolioId })
  const { orders, isLoading: isPendingOrdersLoading } = usePendingOrders()
  const securityOrders = useMemo(
    () => orders.getByPortfolioId(portfolioId).getBySecurityId(securityId),
    [portfolioId, securityId, orders],
  )
  const isLoading = isSecuritiesLoading || isPendingOrdersLoading

  const [showSupportModal] = useActions([showSupportModalActionCreator])

  const handleBack = useCallback(
    () => goTo(urlTo('portfolio.security', { id: portfolioId, securityId })),
    [portfolioId, securityId],
  )
  const handleContactUs = useCallback(() => showSupportModal(), [])

  return (
    <SecurityOrdersPage
      orders={securityOrders}
      securities={securities}
      extraNodes={
        <Fragment>
          <GatewayDest name="supportModal" />
          <Preloader loading={isLoading} size="big" zIndex />
        </Fragment>
      }
      onBack={handleBack}
      onContactUs={handleContactUs}
    />
  )
}

SecurityOrders.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
    securityId: PropTypes.string,
  }),
}

export { SecurityOrders }
