import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { bulkValidate } from 'helpers/validation.js'
import { urlTo } from 'helpers/router.js'

import { Typo } from 'components/_old/Typo/Typo'
import { Typography } from 'components/atoms/Typography'
import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout } from 'components/atoms/Layouts'
import Header from 'components/_old/Header/Header.jsx'
import { DesktopFooter } from 'app/containers/Footer'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import Inner from 'components/_old/Inner/Inner.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { Progress } from 'components/_old/Progress/'
import Headline from 'components/_old/Headline/Headline.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Width from 'components/_old/Width/Width'
import Link from 'components/_old/Link/Link.jsx'
import FeeInfo from 'app/containers/Questionnaire/FeeInfo/FeeInfo.jsx'
import YearsOldInput from '../components/YearsOldInput.jsx'
import TextInput from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/components/TextInput.jsx'

const Desktop = ({
  goal,
  owner,
  yearsOld,
  validation,
  handleAgeChange,
  handleInitialDepositChange,
  onSubmit,
  handleBack,
  progress,
  prevStep,
  activeStep,
  skipAge,
  noDescription,
  noInputLabel,
}) => {
  const submitDisabled = !bulkValidate(validation)
  const { initial_deposit } = goal
  const title = activeStep.headline

  const yoInput = skipAge ? null : (
    <YearsOldInput value={yearsOld} validation={validation} handleChange={handleAgeChange} />
  )

  const idInput = (
    <TextInput
      value={initial_deposit}
      validationRule={validation.initial_deposit}
      handleChange={handleInitialDepositChange}
      data-test-id="tunnelStepGetStartedInputInitialDeposit"
    />
  )

  const question = useMemo(
    () => (
      <Fragment>
        {(skipAge ? false : !owner.age) && (
          <Fragment>
            I’m {yoInput} years old
            <br />
          </Fragment>
        )}
        {noInputLabel ? null : 'I would like to invest '}
        {idInput}
      </Fragment>
    ),
    [skipAge, owner.age, noInputLabel, yoInput, idInput],
  )

  const header = useMemo(
    () => (
      <Header
        right={
          <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}>
            <Link to={urlTo('dashboard')}>My Dashboard</Link>
          </Button>
        }
        after={
          <Fragment>
            <Progress fill={progress} gapWidth="1em" />
            <FeeInfo />
          </Fragment>
        }
        hideDrawer
      />
    ),
    [progress],
  )

  const heading = useMemo(
    () => (
      <Paper top={64}>
        <NavigationBar
          leftPartText={handleBack ? prevStep?.headline ?? 'Back' : undefined}
          onLeftPartClick={handleBack || undefined}
        >
          <span data-test-id="headline">
            <Typo>{title}</Typo>
          </span>
        </NavigationBar>
      </Paper>
    ),
    [handleBack, prevStep?.headline, title],
  )

  const description = !noDescription && (
    <Paper top={40}>
      <Typography size={16} lineHeight="small" align="center">
        <Typo>
          Fill in our questionnaire commitment-free and we'll suggest a portfolio built and managed by our investment
          team to suit your profile.
          <br />
          <br />
          You can always change your answers or amount you'd like to invest before continuing.
        </Typo>
      </Typography>
    </Paper>
  )

  const button = useMemo(
    () => (
      <Paper top={48}>
        <Button
          type="submit"
          mods={{ size: 'big block' }}
          onClick={onSubmit}
          disabled={submitDisabled}
          tabIndex={3}
          data-test-id="tunnelStepGetStartedSubmitButton"
        >
          Continue
        </Button>
      </Paper>
    ),
    [onSubmit, submitDisabled],
  )

  const content = useMemo(
    () => (
      <Inner className="_newTextQuestion-Form">
        {heading}
        {description}
        <SubmitOnEnter>
          <Paper top={description ? 32 : 80}>
            <Headline
              level={1}
              mods={{
                size: 'gargantuan',
                text: 'normal center light',
                'no-margin': 'top',
                'line-height': 'bigger',
                overflow: 'visible',
              }}
            >
              {question}
            </Headline>
            <GatewayDest className="_newTextQuestion-Error" name="text-question-error" />
            <Width size={14} center>
              {button}
            </Width>
          </Paper>
        </SubmitOnEnter>
      </Inner>
    ),
    [heading, description, question, button],
  )

  return <DesktopLayout header={header} content={content} footer={<DesktopFooter />} noGaps />
}

Desktop.propTypes = {
  goal: PropTypes.object,
  owner: PropTypes.object,
  yearsOld: PropTypes.number,
  validation: PropTypes.object,
  handleAgeChange: PropTypes.func,
  handleInitialDepositChange: PropTypes.func,
  onSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  progress: PropTypes.number,
  prevStep: PropTypes.object,
  activeStep: PropTypes.object,
  skipAge: PropTypes.bool,
  noDescription: PropTypes.bool,
  noInputLabel: PropTypes.bool,
}

export { Desktop }
