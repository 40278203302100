import { useState, useCallback } from 'react'

type UseStateRefProcess<StateValue> = (node?: Element) => StateValue
type UseStateRefInterface<StateValue, DefaultValue> = [StateValue | DefaultValue, (node?: Element | undefined) => void]

function useStateRef<StateValue, DefaultValue>(
  defaultValue: DefaultValue,
  processNode: UseStateRefProcess<StateValue>,
): UseStateRefInterface<StateValue, DefaultValue> {
  const [value, setValue] = useState<StateValue | DefaultValue>(defaultValue)
  const ref = useCallback(
    (node?: Element) => {
      setValue(processNode(node))
    },
    [processNode],
  )

  return [value, ref]
}

export { useStateRef }
