import React, { Fragment } from 'react'

import { useCallback, useState, useMediaQueries, useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Logo } from 'components/_old/Logo/Logo.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { GatewayDest } from 'components/atoms/Gateway'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import appPush from './appPush.png'
import { TwoFactorAwaitMainInfo } from './components/TwoFactorAwaitMainInfo'
import { TwoFactorConfirmSms } from './components/TwoFactorConfirmSms'
import { TwoFactorNotificationIssue } from './components/TwoFactorNotificationIssue'
import { TwoFactorRecoveryCode } from './components/TwoFactorRecoveryCode'
import { useTwoFactorAwait } from './useTwoFactorAwait'

type TwoFactorAwaitProps = {
  showTopBar?: boolean
  onAccept: (isPhoneVerified?: boolean) => void
  onDeny: () => void
  onCancel?: () => void
  onError?: () => void
  isNotificationIssueInModal?: boolean
  query?: {
    client_id?: string
    token?: string
  }
  shouldVerifySms?: boolean
  setIsPhoneVerified?: (isPhoneVerified: boolean) => void
}

const TwoFactorAwait = ({
  showTopBar = true,
  onAccept,
  onDeny,
  onError,
  onCancel: handleCancel,
  isNotificationIssueInModal = true,
  query = {},
  shouldVerifySms = true,
  setIsPhoneVerified,
}: TwoFactorAwaitProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    step,
    isSendingSms,
    blockedText,
    handleResendPush,
    handleSendSms,
    isResendingPush,
    phone,
    shouldRememberBrowser,
    setShouldRememberBrowser,
    handleBackFromSms,
    handleStopStatusRequest,
  } = useTwoFactorAwait({
    query,
    onAccept,
    onDeny,
    onError,
    setIsPhoneVerified,
  })

  const [isNotificationIssueDisplayed, setIsNotificationIssueDisplayed] = useState(false)
  const [isConfirmSmsDisplayed, setConfirmSmsDisplayed] = useState(false)
  const [isRecoveryCodeDisplayed, setIsRecoveryCodeDisplayed] = useState(false)

  const showNotificationIssue = useCallback(() => {
    setIsNotificationIssueDisplayed(true)
  }, [setIsNotificationIssueDisplayed])

  const hideNotificationIssue = useCallback(() => {
    setIsNotificationIssueDisplayed(false)
  }, [setIsNotificationIssueDisplayed])

  const handleAcceptSms = useCallback((): void => {
    onAccept(!!phone)
  }, [onAccept, phone])

  const showConfirmSms = useCallback((): void => {
    setConfirmSmsDisplayed(true)
  }, [])

  const handleIssueButtonClick = useCallback((): void => {
    shouldVerifySms && phone ? showConfirmSms() : showNotificationIssue()
  }, [shouldVerifySms, phone, showConfirmSms, showNotificationIssue])

  const hideConfirmSmsModal = useCallback((): void => {
    setConfirmSmsDisplayed(false)
  }, [])

  const handleShowRecoveryCodeForm = (): void => {
    setConfirmSmsDisplayed(false)
    setIsRecoveryCodeDisplayed(true)
  }

  const handleHideRecoveryCodeForm = (): void => {
    setConfirmSmsDisplayed(true)
    setIsRecoveryCodeDisplayed(false)
  }

  const isNotificationIssueDisplayedInline = useMemo(
    () => isNotificationIssueDisplayed && !isNotificationIssueInModal,
    [isNotificationIssueDisplayed, isNotificationIssueInModal],
  )

  const startNode = useMemo(
    () => (
      <TwoFactorAwaitMainInfo
        isResendingPush={isResendingPush}
        onResend={handleResendPush}
        onShowNotificationIssue={handleIssueButtonClick}
      />
    ),
    [isResendingPush, handleResendPush, handleIssueButtonClick],
  )

  const notificationIssueNode = useMemo(
    () => <TwoFactorNotificationIssue onContinue={hideNotificationIssue} />,
    [hideNotificationIssue],
  )

  const confirmSmsNode = useMemo(
    () => (
      <TwoFactorConfirmSms
        query={query}
        step={step}
        phone={phone}
        isSendingSms={isSendingSms}
        blockedText={blockedText}
        onAccept={handleAcceptSms}
        onBack={hideConfirmSmsModal}
        shouldRememberBrowser={shouldRememberBrowser}
        setShouldRememberBrowser={setShouldRememberBrowser}
        handleSendSms={handleSendSms}
        handleBackFromSms={handleBackFromSms}
        handleShowRecoveryCodeForm={handleShowRecoveryCodeForm}
      />
    ),
    [
      blockedText,
      handleAcceptSms,
      handleBackFromSms,
      handleSendSms,
      hideConfirmSmsModal,
      isSendingSms,
      phone,
      query,
      shouldRememberBrowser,
      setShouldRememberBrowser,
      step,
    ],
  )

  const content = useMemo(() => {
    if (isRecoveryCodeDisplayed) {
      return (
        <TwoFactorRecoveryCode
          query={query}
          onAccept={onAccept}
          onBack={handleHideRecoveryCodeForm}
          shouldRememberBrowser={shouldRememberBrowser}
          handleStopStatusRequest={handleStopStatusRequest}
        />
      )
    }

    return (
      <Fragment>
        <AllCenter>
          {!isConfirmSmsDisplayed && (
            <Paper bottom={32}>
              <img src={appPush} width={80} height={80} />
            </Paper>
          )}

          {isNotificationIssueDisplayedInline && notificationIssueNode}
          {!isNotificationIssueDisplayedInline && !isConfirmSmsDisplayed && startNode}
        </AllCenter>
        {isConfirmSmsDisplayed && confirmSmsNode}
      </Fragment>
    )
  }, [
    isNotificationIssueDisplayedInline,
    isConfirmSmsDisplayed,
    notificationIssueNode,
    startNode,
    confirmSmsNode,
    isRecoveryCodeDisplayed,
    onAccept,
    shouldRememberBrowser,
    handleStopStatusRequest,
    query,
  ])

  const desktopIssueModal = useMemo(
    () => (
      <Modal
        open={isNotificationIssueInModal && isNotificationIssueDisplayed}
        onClose={hideNotificationIssue}
        close={null}
      >
        <ModalContent width={36}>
          <Paper top={20} right={24} bottom={24} left={24}>
            <NavigationBar rightPartText="Close" onRightPartClick={hideNotificationIssue} plain>
              <Typography size={20} weight="semibold">
                <Typo>&nbsp</Typo>
              </Typography>
            </NavigationBar>
          </Paper>
          <Paper top={56} left={80} right={80} bottom={80}>
            <AllCenter>
              <Paper bottom={32}>
                <img src={appPush} width={80} height={80} />
              </Paper>
              {notificationIssueNode}
            </AllCenter>
          </Paper>
        </ModalContent>
      </Modal>
    ),
    [notificationIssueNode, isNotificationIssueDisplayed, hideNotificationIssue, isNotificationIssueInModal],
  )

  const mobileHeader = useMemo(
    () => (
      <NavigationBar
        rightPartText={isNotificationIssueDisplayedInline ? 'Close' : 'Cancel'}
        onRightPartClick={isNotificationIssueDisplayedInline ? hideNotificationIssue : handleCancel}
      >
        <Logo className="NavigationBar-Logo" style={{ height: 24 }} />
      </NavigationBar>
    ),
    [handleCancel, isNotificationIssueDisplayedInline, hideNotificationIssue],
  )

  const isAlternativeStepDisplayed = isConfirmSmsDisplayed || isRecoveryCodeDisplayed

  if (desktop)
    return (
      <Paper style={{ minHeight: '600px' }}>
        {showTopBar && !isAlternativeStepDisplayed && (
          <Paper top={20} right={24} bottom={24} left={24}>
            <NavigationBar
              rightPartText={isNotificationIssueDisplayedInline ? 'Close' : 'Cancel'}
              onRightPartClick={isNotificationIssueDisplayedInline ? hideNotificationIssue : handleCancel}
              withPaddings={false}
              plain
            >
              <Typography size={20} weight="semibold">
                <Typo>{isNotificationIssueDisplayed ? '&nbsp' : 'Two-factor authentication'}</Typo>
              </Typography>
            </NavigationBar>
          </Paper>
        )}
        <Paper
          top={isAlternativeStepDisplayed ? 0 : 56}
          left={isAlternativeStepDisplayed ? 0 : isNotificationIssueDisplayed && !isNotificationIssueInModal ? 80 : 96}
          right={isAlternativeStepDisplayed ? 0 : isNotificationIssueDisplayed && !isNotificationIssueInModal ? 80 : 96}
          bottom={isAlternativeStepDisplayed ? 0 : 80}
        >
          {content}
        </Paper>

        {desktopIssueModal}
      </Paper>
    )

  return (
    <Fragment>
      {showTopBar ? (
        <MobileLayout
          header={!isAlternativeStepDisplayed && mobileHeader}
          contentPaperSizes={isAlternativeStepDisplayed && { top: 0, left: 0, right: 0, bottom: 0 }}
          content={content}
        />
      ) : (
        content
      )}

      <Modal open={isNotificationIssueDisplayed && isNotificationIssueInModal} onClose={hideNotificationIssue}>
        <MobileLayout
          header={<NavigationBar rightPartText="Close" onRightPartClick={hideNotificationIssue}></NavigationBar>}
          content={
            <AllCenter>
              <Paper bottom={32}>
                <img src={appPush} width={80} height={80} />
              </Paper>
              {notificationIssueNode}
            </AllCenter>
          }
        />
      </Modal>

      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { TwoFactorAwait }
