import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { trackEvent } from 'helpers/analytics'

import { useCallback, useContext, useMediaQueries, useRedirect } from 'hooks'
import { useUnit } from 'effector-react'

import { types as clientTypes } from 'constants/client'

import { StepContext } from 'app/containers/PortfolioTunnel'

import ProofOfIdentityForm from 'app/pages/Forms/ProofOfIdentityForm.jsx'

import Segment from 'components/atoms/Segment/Segment.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Gateway } from 'components/atoms/Gateway'

import { roles } from 'constants/contacts.js'

import { $isLoading } from 'app/effector/contacts'

const ProofOfIdentitySetup = withRouter(({ getContact, changeContactField, handleAfterSubmit, params }) => {
  const { desktop } = useMediaQueries()

  const contactId = params.contactId !== undefined ? parseInt(params.contactId, 10) : null
  const contact = getContact(contactId)

  const { getStepByName } = useContext(StepContext)
  const residentialAddressStep = getStepByName('residential-address')
  const isContactsLoading = useUnit($isLoading)

  useRedirect({
    to: residentialAddressStep.module,
    rule: contact?.role === roles.OWNER && contact?.isAvailable(),
    isLoading: isContactsLoading,
  })

  const handleAfterUpload = useCallback(
    (data) => {
      changeContactField({ field: data, id: contactId })
    },
    [changeContactField, contactId],
  )

  const handleSubmit = useCallback(
    (...args) => {
      trackEvent({
        action: 'documents_submitted',
        client_type: clientTypes.BUSINESS,
      })

      handleAfterSubmit(...args)
    },
    [handleAfterSubmit],
  )

  if (!contact.id) {
    return null
  }

  const submitDisabled =
    !(contact.document_driving_licence_filled || contact.document_passport_filled) || !contact.document_selfie_filled

  const submitButton = (
    <Segment mods={{ margin: 'hefty', noMargin: 'top' }}>
      <ModalContentButtons width={desktop ? 22 : null} phantomButtonsMarginSize="zero" isNewModal>
        <Button
          mods={{ size: 'big block' }}
          onClick={handleSubmit}
          tabIndex={4}
          disabled={submitDisabled}
          data-test-id="proofOfIdentitySubmit"
        >
          Continue
        </Button>
      </ModalContentButtons>
    </Segment>
  )

  return (
    <Fragment>
      <Gateway into="setupAccountHeadline">
        Proof of identity for {contact.first_name} {contact.last_name}
      </Gateway>
      <ProofOfIdentityForm
        model={contact}
        type="BUSINESS"
        url={`contacts/${contact.id}/files/`}
        method="POST"
        submitButton={submitButton}
        onAfterUpload={handleAfterUpload}
      />
    </Fragment>
  )
})

ProofOfIdentitySetup.propTypes = {
  params: PropTypes.shape({
    contactId: PropTypes.string,
  }),
  getContact: PropTypes.func,
  handleAfterSubmit: PropTypes.func,
  changeContactField: PropTypes.func,
}

export { ProofOfIdentitySetup }
