import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'

import { modsToClassnames } from 'helpers/classname.js'

import Link from 'components/_old/Link/Link.jsx'

import './Button.css'

function Button(props, ref) {
  let {
    children,
    tag = 'button',
    className,
    mods,
    disabled = false,
    active,
    type,
    onClick,
    tabIndex,
    block,
    stretch,
    ...rest
  } = props

  const classes = classNames(className, 'Button', modsToClassnames('Button', mods), {
    Button_disabled: disabled,
    Button_active: active,
    Button_stretch: stretch,
  })

  const wrapWithText = (children) => {
    return mods && mods.text ? <span className="Button-Text">{children}</span> : children
  }

  const isLink = children && children.type === Link
  const linkProps = isLink ? children.props : {}

  if (typeof children === 'object' && !isLink && !isArray(children)) {
    children = React.Children.map(children, (child) => {
      return React.cloneElement(
        child,
        {
          className: classNames('Button-Child', 'Button-Inner', child.props.className),
          tabIndex: -1,
        },
        wrapWithText(child.props.children),
      )
    })
  } else {
    children = isLink ? children.props.children : children
    children = <span className="Button-Child Button-Inner">{wrapWithText(children)}</span>
  }

  const onClickWithBlur = (...args) => {
    const [event] = args
    event && event.target.blur()

    if (isFunction(onClick)) {
      onClick(...args)
    }
  }

  const allProps = {
    ...rest,
    ...linkProps,
    className: classes,
    onClick: onClickWithBlur,
    disabled: disabled,
    tabIndex: tabIndex,
    'data-disabled': disabled,
  }

  if (tag === 'button') {
    allProps.type = type
  }

  if (isLink) {
    return (
      <Link ref={ref} {...allProps} classnameless>
        {children}
      </Link>
    )
  }

  return React.createElement(tag, { ...allProps, ref }, children)
}

const ButtonWithForwardedRef = forwardRef(Button)

ButtonWithForwardedRef.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.string,
  className: PropTypes.string,
  mods: PropTypes.object,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  block: PropTypes.bool,
  stretch: PropTypes.bool,
}

export default ButtonWithForwardedRef
