const PREFIX = 'PORTFOLIO'
export const SET_VALID = `${PREFIX}_SET_VALID`
export const SET_NOT_VALID = `${PREFIX}_SET_NOT_VALID`
export const RECEIVE_ERROR = `${PREFIX}_RECEIVE_ERROR`
export const RESET_ERROR = `${PREFIX}_RESET_ERROR`
export const RECEIVE_PORTFOLIO = `${PREFIX}_RECEIVE_PORTFOLIO`
export const RECEIVE_PORTFOLIOS = `${PREFIX}_RECEIVE_PORTFOLIOS`
export const REMOVE_PORTFOLIO = `${PREFIX}_REMOVE_PORTFOLIO`
export const REMOVE_PORTFOLIOS = `${PREFIX}_REMOVE_PORTFOLIOS`
export const CHANGE_FIELD = `${PREFIX}_CHANGE_FIELD`
export const SELECT_GOAL = `${PREFIX}_SELECT_GOAL`
export const REMOVE_GOALS = `${PREFIX}_REMOVE_GOALS`
export const RECEIVE_PRESETS = `${PREFIX}_RECEIVE_PRESETS`
