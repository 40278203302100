import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import './Sticky.css'

const Sticky = ({ children, top, bottom, clickThrough }) => {
  const classes = classNames('Sticky', { Sticky_top: top, Sticky_bottom: bottom, Sticky_clickThrough: clickThrough })

  return <div className={classes}>{children}</div>
}

Sticky.propTypes = {
  children: PropTypes.node,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  clickThrough: PropTypes.bool,
}

export { Sticky }
