import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import { useDropzone } from 'react-dropzone'

import { palette } from 'helpers/palette/'

import { useMemo, useRef } from 'hooks'
import { useFileUpload } from './hooks'

import useMediaQueries from 'hooks/useMediaQueries.js'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { Typo } from 'components/_old/Typo/Typo'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Button from 'components/_old/Button/Button.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { statuses } from './constants.js'

import './FileUpload.css'

const aligns = ['left', 'center']
const dataTypes = ['bank_statement', 'selfie', 'passport']

const FileUpload = ({
  children,
  status: initialStatus,
  align = 'center',
  url,
  icon,
  dataType,
  text,
  title,
  onAfterUpload: handleAfterUpload,
  'data-test-id': dataTestId,
}) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const buttonRef = useRef()

  const onAfterUpload = (data) => {
    handleAfterUpload(data)

    if (buttonRef.current) {
      buttonRef.current.focus()
    }
  }

  const { status, onDrop } = useFileUpload({
    status: initialStatus,
    url,
    dataType,
    onAfterUpload,
  })

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: ['image/*', 'application/pdf'] })

  const heading = useMemo(
    () => (
      <Link>
        <Typography size={20} lineHeight="small" align={align} color={desktop ? 'default' : 'inherit'}>
          <Typo>{title}</Typo>
        </Typography>
      </Link>
    ),
    [align, desktop, title],
  )

  const description = useMemo(
    () => (
      <Paper top={16}>
        <Typography size={14} lineHeight={desktop ? 'small' : 'medium'} align={align}>
          <Typo>{text}</Typo>
        </Typography>
      </Paper>
    ),
    [align, desktop, text],
  )

  const message = useMemo(() => {
    if (status === statuses.LOADING) {
      return (
        <Typography size={14} align="center" color="minor">
          <Typo>Loading...</Typo>
        </Typography>
      )
    }

    if (status === statuses.FAILED) {
      return desktop ? (
        <Typography size={14} align="center" color="error">
          <Typo>Something went wrong please reupload your file</Typo>
        </Typography>
      ) : null
    }

    return desktop ? (
      <Typography size={14} align="center" color="minor">
        <Typo>Drag and drop file here or press ‘Upload’ below</Typo>
      </Typography>
    ) : null
  }, [desktop, status])

  const statusIcon = useMemo(() => {
    if (status === statuses.DONE) {
      return <Icon type="checkmark_small" color={palette['background-default']} fluid data-test-id="successIcon" />
    }

    if (status === statuses.LOADING) {
      return <Icon type="update" color={palette['content-on-background-minor']} fluid animation="spin" />
    }

    if (status === statuses.FAILED) {
      return <Icon type="cross" color={palette['background-default']} fluid />
    }

    return null
  }, [status])

  const getStatus = (mod) => (
    <div
      className={classNames('FileUploader-Status', {
        [`FileUploader-Status_${mod}`]: mod,
      })}
    >
      {statusIcon}
    </div>
  )

  const imageHolder = useMemo(
    () => (
      <Paper top={desktop ? 40 : 16}>
        <div className="FileUploader-ImageHolder">
          <Icon className="FileUploader-Icon" type={icon} />
          {getStatus(status)}
        </div>
      </Paper>
    ),
    [desktop, status, icon],
  )

  const button = useMemo(
    () => (
      <Paper top={16}>
        <Button
          ref={buttonRef}
          className="FileUploader-Button"
          mods={{
            theme: 'simple-reverse-blue',
            text: 'smaller lighter',
          }}
        >
          Upload
        </Button>
      </Paper>
    ),
    [],
  )

  const content =
    align === 'left' && !desktop ? (
      <Fragment>
        {heading}
        <ColumnarLayout>
          <Column size={3}>
            {children}
            {description}
            {button}
          </Column>
          <Column size={1}>{imageHolder}</Column>
        </ColumnarLayout>
      </Fragment>
    ) : (
      <Fragment>
        {heading}
        {children}
        {description}
        <AllCenter>
          {imageHolder}
          {desktop ? <Paper top={16}>{message}</Paper> : null}
          {button}
        </AllCenter>
      </Fragment>
    )

  const classes = classNames(
    'FileUploader',
    {
      FileUploader_Border: desktop,
      FileUploader_disabled: status === statuses.LOADING,
    },
    getMediaQuieryClasses('FileUploader', mediaQueries),
  )

  return (
    <div className={classes} {...getRootProps()} data-test-id={dataTestId}>
      <input {...getInputProps()} />
      <Paper top={desktop ? 32 : 16} bottom={desktop ? 32 : 24} right={desktop ? 24 : 16} left={desktop ? 24 : 16}>
        {content}
      </Paper>
    </div>
  )
}

FileUpload.propTypes = {
  children: PropTypes.node,
  status: PropTypes.oneOf(Object.values(statuses)),
  align: PropTypes.oneOf(aligns),
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  dataType: PropTypes.oneOf(dataTypes),
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onAfterUpload: PropTypes.func,
  'data-test-id': PropTypes.string,
}

export { FileUpload }
