import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { SecuritiesList } from '../components/SecuritiesList'

const AllEtfs = ({
  areCollectionsBeingLoaded,
  getSecurityDataByIndex,
  getSecurityLink,
  handleContactUs,
  handleSecurityClick,
  isDiscoveryDisplayed,
  isDiscoveryVisible,
  isLoading,
  scrollableElementRef,
  securities,
  securitiesWasLoadedOnce,
}): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const securitiesList = (
    <SecuritiesList
      securities={securities}
      getSecurityLink={getSecurityLink}
      isLoading={isDiscoveryDisplayed ? areCollectionsBeingLoaded : isLoading}
      scrollableElementRef={scrollableElementRef}
      securitiesWasLoadedOnce={securitiesWasLoadedOnce}
      getSecurityDataByIndex={getSecurityDataByIndex}
      handleSecurityClick={handleSecurityClick}
    />
  )

  const didntFindLink = (
    <Typography weight="semibold" align="center">
      <Link onClick={handleContactUs}>
        <ItemWithIcon
          icon={<Icon type="plus-in-a-circle-24" size={24} color="inherit" />}
          content={<Typo>Didn't find the ETF you were looking for?</Typo>}
          space={8}
          inline
        />
      </Link>
    </Typography>
  )

  return (
    <Fragment>
      <Paper top={!desktop ? 0 : isDiscoveryVisible ? 0 : 24}>{securitiesList}</Paper>
      <Paper top={80} bottom={80}>
        {didntFindLink}
      </Paper>
    </Fragment>
  )
}

export { AllEtfs }
