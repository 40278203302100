import React from 'react'

import { useUnit } from 'effector-react'

import { useEffect, useMediaQueries } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { fetchOnboardingState, finishOnboardingFx, $onboardingProgressStore } from 'app/effector/onboarding-progress'
import { OnboardingState, type ManageType } from 'app/effector/onboarding-progress/types'

import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'

import { GettingStartedCard } from 'components/molecules/GettingStartedCard/GettingStartedCard'
import { Preloader } from 'components/molecules/Preloader'

import { usePortfolioActions, ActionKey } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'

type OnboardingProgressBarProps = {
  portfolio: {
    first_topup: string | null
    id: number
    manage_type: ManageType
  }
  substitute?: React.ReactElement
  paddingTop?: number
}

const OnboardingProgressBar = ({
  portfolio,
  substitute,
  paddingTop,
}: OnboardingProgressBarProps): React.ReactElement | null => {
  const { id: portfolioId, manage_type: portfolioType, first_topup: firstTopup } = portfolio
  const { desktop } = useMediaQueries()

  const { getOnboardingState, isLoading } = useUnit($onboardingProgressStore)
  const { actionsMap } = usePortfolioActions(portfolio)

  const onboardingState = getOnboardingState({ portfolioId, portfolioType })

  const showSkeleton = isLoading && !onboardingState
  const showLoader = isLoading && !!onboardingState

  useEffect(() => {
    fetchOnboardingState({ portfolioId, portfolioType })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTopup])

  if (!isLoading && !onboardingState) {
    return substitute ?? null
  }

  return (
    <Paper bottom={desktop ? 40 : 0} top={paddingTop ?? (desktop ? 0 : 24)}>
      <div style={{ position: 'relative' }}>
        <Skeleton shown={showSkeleton}>
          <GettingStartedCard
            step={showSkeleton ? OnboardingState.REGISTRATION_NOT_COMPLETED : (onboardingState as OnboardingState)}
            navigateToCompleteRegistration={() => {
              goTo(urlTo('dashboard.setup-account', {}, { portfolioId }))
            }}
            navigateToTopup={() => {
              actionsMap[ActionKey.ADD_FUNDS].onClick()
            }}
            navigateToPlaceOrder={() => {
              goTo(urlTo('portfolio.buy', { id: portfolioId }))
            }}
            hidePanel={() => {
              finishOnboardingFx({ portfolioType })
            }}
          />
        </Skeleton>
        <Preloader loading={showLoader} size="default" absolute />
      </div>
    </Paper>
  )
}

export { OnboardingProgressBar }
