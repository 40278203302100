import { shallowEqual } from 'react-redux'

import { smoothScroll, inView, getRect } from '@reactour/utils'
import { useUnit } from 'effector-react'
import { createSelector } from 'reselect'

import { useActions, useSelector, useLoading, useMemo, useState } from 'hooks'

import { $transferProgressCollection } from 'app/effector/isa-transfer'
import { $recurringPaymentsStore } from 'app/effector/recurringPayments'

import {
  changePortfolioPosition as changePortfolioPositionActionCreator,
  changeRegulatoryTypePosition as changeRegulatoryTypePositionActionCreator,
  changePortfolioHiddenSetting as changePortfolioHiddenSettingActionCreator,
  deletePortfolio as deletePortfolioActionCreator,
} from 'app/redux/actions/portfolios'
import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'
import { ApiError } from 'app/redux/models/errors'
import { type Portfolio } from 'app/redux/models/portfolio/types'
import { selectGoalTitle, getSortedRegulatoryTypes } from 'app/redux/selectors'

import { hightlightElement } from './helpers/highlightElement'

import { regulatoryTypesText, manageTypes } from 'constants/portfolio'

type useDashboardContextMenuProps = {
  portfolio?: Portfolio
  regulatoryTypeGroup?: 'ISA' | 'GIA' | 'SIPP'
  groupRefs: Record<'ISA' | 'GIA' | 'SIPP', React.RefObject<HTMLDivElement>>
}

type useDashboardContextMenuInterface = {
  items: Array<{
    title: string
    icon: string
    onClick: () => void
    active: boolean
  }>
  isLoading: boolean
  isDeletePortfolioModalOpen: boolean
  isCantHideModalOpen: boolean
  closeDeletePortfolioModal: () => void
  closeCantHideModal: () => void
  handleDeletePortfolio: () => Promise<void>
}

const getVisiblePortfoliosGrouped = createSelector([(state) => state.portfolios.list], (portfoliosList) =>
  portfoliosList.getVisiblePortfolios().groupByRegulatoryType(),
)

// used for portfolio items and regulatory type groups
const useDashboardContextMenu = ({
  portfolio,
  regulatoryTypeGroup,
  groupRefs,
}: useDashboardContextMenuProps): useDashboardContextMenuInterface => {
  const groupedPortfolios = useSelector(getVisiblePortfoliosGrouped)
  const regulatoryTypesOrder = useSelector(getSortedRegulatoryTypes, shallowEqual)

  const transferProgressCollection = useUnit($transferProgressCollection)
  const hasIsaTransferInProgress = !!transferProgressCollection?.get(portfolio?.id)?.length

  const { isLoading, wait } = useLoading(false)

  const { hasSavingsPlanByPortfolioId } = useUnit($recurringPaymentsStore)
  const hasSavingsPlan = hasSavingsPlanByPortfolioId(portfolio?.id)

  const title = useSelector((state) => selectGoalTitle(state, portfolio?.id))

  let currentOrder = portfolio
    ? groupedPortfolios[[portfolio?.regulatory_type]]?.map((portfolio) => portfolio.id)
    : regulatoryTypesOrder

  // remove groups with no portfolios to prevent moving empty groups
  if (regulatoryTypeGroup)
    currentOrder = currentOrder.filter((regulatoryType) => groupedPortfolios[regulatoryType]?.length)

  const [isDeletePortfolioModalOpen, setIsDeletePortfolioModalOpen] = useState(false)
  const [portfolioIdToDelete, setPortfolioIdToDelete] = useState(null)
  const [isCantHideModalOpen, setIsCantHideModalOpen] = useState(false)

  const closeCantHideModal = (): void => {
    setIsCantHideModalOpen(false)
  }

  const closeDeletePortfolioModal = (): void => {
    setIsDeletePortfolioModalOpen(false)
    setPortfolioIdToDelete(null)
  }

  const openDeletePortfolioModal = (portfolioId): void => {
    setPortfolioIdToDelete(portfolioId)
    setIsDeletePortfolioModalOpen(true)
  }

  const [changePortfolioPosition, changeRegulatoryTypePosition, changePortfolioHiddenSetting, deletePortfolio] =
    useActions([
      changePortfolioPositionActionCreator,
      changeRegulatoryTypePositionActionCreator,
      changePortfolioHiddenSettingActionCreator,
      deletePortfolioActionCreator,
    ])

  const handleDeletePortfolio = async (): Promise<void> => {
    if (!portfolioIdToDelete) return
    const stateAfterDelete = await wait(deletePortfolio(portfolioIdToDelete))
    stateAfterDelete?.portfolios?.error ? showFailToast() : showSuccessToast(`"${title}" deleted`)
  }

  const items = useMemo(() => {
    const index = currentOrder?.indexOf(portfolio?.id || regulatoryTypeGroup)
    const isFirst = index === 0
    const isLast = index === currentOrder?.length - 1
    const canBeHidden =
      (portfolio?.first_topup || hasSavingsPlan) &&
      portfolio?.current_balance === 0 &&
      portfolio?.manage_type !== manageTypes.CASH &&
      !hasIsaTransferInProgress
    const canBeDeleted =
      portfolio?.is_deletable && portfolio?.manage_type !== manageTypes.CASH && !hasIsaTransferInProgress

    const handlePositionChange = async (direction: 'up' | 'down'): Promise<void> => {
      const result = await wait(
        portfolio
          ? changePortfolioPosition({ portfolioId: portfolio?.id, direction })
          : changeRegulatoryTypePosition({ regulatoryType: regulatoryTypeGroup, direction }),
      )

      if (!(result instanceof ApiError)) {
        showSuccessToast(
          `"${regulatoryTypeGroup ? regulatoryTypesText[regulatoryTypeGroup] : title}" moved ${direction}`,
        )

        setTimeout(() => {
          const element = portfolio
            ? document.querySelector(`[data-portfolio-id='${portfolio?.id}']`)
            : groupRefs[regulatoryTypeGroup].current

          if (!element) return

          const rect = getRect(element)
          const isInView = regulatoryTypeGroup ? inView({ ...rect, height: 50 }) : inView(rect)

          if (!isInView) element.style['scroll-margin-top'] = '50px'

          isInView
            ? hightlightElement({ element, withBackground: !!regulatoryTypeGroup })
            : smoothScroll(element, { block: regulatoryTypeGroup ? 'start' : 'center' }).then(() => {
                element.style['scroll-margin-top'] = null
                hightlightElement({ element, withBackground: !!regulatoryTypeGroup })
              })
        }, 100)
      }
    }

    const handleHidePortfolio = async (portfolioId): Promise<void> => {
      if (hasSavingsPlan) {
        setIsCantHideModalOpen(true)
        return
      }
      const result = await wait(changePortfolioHiddenSetting({ portfolioId, isHidden: true }))
      !(result instanceof ApiError) && showSuccessToast(`"${title}" is hidden`)
    }

    if (regulatoryTypeGroup && !groupedPortfolios[regulatoryTypeGroup]?.length) return []

    return [
      {
        title: regulatoryTypeGroup ? 'Move account up' : 'Move portfolio up',
        icon: 'dashboard-up-24',
        onClick: () => {
          handlePositionChange('up')
        },
        active: !isFirst,
      },
      {
        title: regulatoryTypeGroup ? 'Move account down' : 'Move portfolio down',
        icon: 'dashboard-down-24',
        onClick: () => {
          handlePositionChange('down')
        },
        active: !isLast,
      },
      {
        title: 'Hide',
        icon: 'dashboard-hide-24',
        onClick: () => {
          handleHidePortfolio(portfolio?.id)
        },
        active: canBeHidden,
      },
      {
        title: 'Delete',
        icon: 'dashboard-trash-delete-bin-24',
        onClick: () => {
          openDeletePortfolioModal(portfolio?.id)
        },
        active: canBeDeleted,
      },
    ].filter((item) => item.active)
  }, [
    currentOrder,
    portfolio,
    regulatoryTypeGroup,
    title,
    groupedPortfolios,
    hasSavingsPlan,
    groupRefs,
    hasIsaTransferInProgress,
    changePortfolioPosition,
    changeRegulatoryTypePosition,
    changePortfolioHiddenSetting,
    wait,
  ])

  return {
    items,
    isLoading,
    isDeletePortfolioModalOpen,
    isCantHideModalOpen,
    closeDeletePortfolioModal,
    handleDeletePortfolio,
    closeCantHideModal,
  }
}

export { useDashboardContextMenu }
