import { useState, useCallback } from 'hooks'

import { type Story } from 'app/effector/stories'

const preloadedImages = new Set<string>()
declare global {
  interface Window {
    preloadedImages: Set<string>
  }
}
window.preloadedImages = preloadedImages

type StorySkeleton = ReplaceWithUndefined<Story> & { skeleton: true }
type StoryOrSkeleton = Story | StorySkeleton

type UseStoriesInterface = {
  skeleton: boolean
  currentStepIndex: number
  activeStory?: Story
  previousStory?: Story
  showPreviousButton: boolean
  showNextButton: boolean
  handlePrevious: () => void
  handleNext: () => void
  handleStoryFinish: () => void
  startStory: (story: Story) => void
  closeStory: () => void
}

function isSkeleton(storyOrSkeleton: StoryOrSkeleton): storyOrSkeleton is StorySkeleton {
  return !storyOrSkeleton.id && !storyOrSkeleton.text
}

function useStories({ stories, isLoading }): UseStoriesInterface {
  const [activeStory, setActiveStoryRaw] = useState<Story | undefined>(undefined)
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const setActiveStory = useCallback(
    (nextActiveStory?: Story) => {
      setActiveStoryRaw(nextActiveStory ?? undefined)

      if (nextActiveStory?.steps[currentStepIndex]) {
        const image = nextActiveStory.steps[0]?.backgroundImage ?? nextActiveStory.steps[0]?.image

        if (image) {
          preloadedImages.add(image)
        }
      }
    },
    [setActiveStoryRaw, currentStepIndex],
  )

  const skeleton = isLoading
  const index = stories.indexOf(activeStory as StoryOrSkeleton)
  const previousStory = stories[index - 1]

  const showPreviousButton = Boolean(
    previousStory || (activeStory?.steps && activeStory.steps.length > 1 && currentStepIndex !== 0),
  )
  const showNextButton = Boolean(
    index + 1 < stories.length || (activeStory?.steps && currentStepIndex < activeStory.steps.length - 1),
  )

  const handleShowPreviousStory = useCallback((): void => {
    if (index < 0 && isSkeleton(previousStory)) return
    setActiveStory(previousStory)
  }, [index, previousStory, setActiveStory])

  const handleStoryFinish = useCallback((): void => {
    const index = stories.indexOf(activeStory as StoryOrSkeleton)
    const nextStory = stories[index + 1]

    if (index + 1 < stories.length && !isSkeleton(nextStory)) {
      setActiveStory(nextStory)
      return
    }

    setActiveStory(undefined)
  }, [activeStory, stories, setActiveStory])

  const startStory = useCallback(
    (story: Story): void => {
      setActiveStory(story)
    },
    [setActiveStory],
  )

  const closeStory = useCallback((): void => {
    setActiveStory(undefined)
    setCurrentStepIndex(0)
  }, [setActiveStory])

  const handlePrevious = useCallback((): void => {
    if (!activeStory) return

    if (currentStepIndex === 0 && previousStory) {
      handleShowPreviousStory()
      setCurrentStepIndex(previousStory.steps.length - 1)
      return
    }
    setCurrentStepIndex(currentStepIndex - 1)
  }, [activeStory, currentStepIndex, handleShowPreviousStory, previousStory])

  const handleNext = useCallback((): void => {
    if (!activeStory) return

    if (currentStepIndex + 1 >= activeStory.steps.length) {
      setCurrentStepIndex(0)
      handleStoryFinish()
      return
    }
    setCurrentStepIndex(currentStepIndex + 1)
  }, [activeStory, currentStepIndex, handleStoryFinish])

  return {
    currentStepIndex,
    skeleton,
    activeStory,
    previousStory,
    showNextButton,
    showPreviousButton,
    startStory,
    closeStory,
    handleNext,
    handlePrevious,
    handleStoryFinish,
  }
}

export { useStories, isSkeleton, type StoryOrSkeleton }
