import React from 'react'
import { findDOMNode } from 'react-dom'

import { smoothScroll, inView, getRect, useElemRect } from '@reactour/utils'
import PropTypes from 'prop-types'

import { useCallback, useEffect, useRef, useState, useMemo } from 'hooks'

import { Hint } from 'components/molecules/Hint'

const triggers = {}

const createPosition = (triggerName: string, index?: number): number => {
  if (!triggers[triggerName]) {
    triggers[triggerName] = []
  }
  if (index) {
    triggers[triggerName][index] = null
    return index
  }
  const emptyIndex = triggers[triggerName].length
  triggers[triggerName][emptyIndex] = null
  return emptyIndex
}

type SpotlightTriggerProps = {
  children: React.ReactNode
  name: string
}

const SpotlightTrigger = ({ children, name }: SpotlightTriggerProps): React.ReactElement => {
  const handleClick = useCallback(() => {
    const callbacks = triggers[name]

    if (callbacks && children.props.disabled) {
      const callback = callbacks.find((cb) => typeof cb === 'function')
      callback?.()
    }
  }, [name, children.props.disabled])

  return (
    <div onClick={handleClick} style={{ width: '100%' }}>
      {children}
    </div>
  )
}

SpotlightTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
}

type SpotlightProps = {
  children: React.ReactNode
  trigger: string
  index?: number
  rules: Array<boolean | string>
  type?: 'input'
  hint?: string
}

const Spotlight = ({ children, trigger, index, rules = [], type, hint }: SpotlightProps): React.ReactElement => {
  const highlightedRef = useRef()
  const inputRef = useRef()
  const [isHintOpened, setIsHintOpened] = useState(false)
  const sizes = useElemRect(highlightedRef.current, isHintOpened)

  const sizesAsObject = useMemo(
    () => ({
      top: sizes.top,
      bottom: sizes.bottom,
      left: sizes.left,
      right: sizes.right,
      width: sizes.width,
      height: sizes.height,
    }),
    [sizes],
  )

  const [position] = useState(() => createPosition(trigger, index))

  const isInput = type === 'input'
  const isValid = rules.every(Boolean)

  const closeHint = useCallback(() => {
    setIsHintOpened(false)
  }, [])

  const highlightElement = useCallback(() => {
    if (!isInput) {
      setIsHintOpened(true)
      return
    }
    inputRef.current?.focus()
    inputRef.current?.blur()
  }, [isInput])

  const handleTriggerClick = useCallback(() => {
    // eslint-disable-next-line react/no-find-dom-node
    const element = isInput ? findDOMNode(inputRef.current) : highlightedRef.current
    const rect = getRect(element)
    const { top, bottom, left, right, width, height } = rect
    const isInView = inView({ top, bottom, left, right, width, height })
    isInView ? highlightElement() : smoothScroll(element, { block: 'center' }).then(highlightElement)
  }, [isInput, highlightElement])

  useEffect(() => {
    if (!isValid) {
      triggers[trigger][position] = handleTriggerClick
    }
    return () => {
      triggers[trigger][position] = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  if (isInput) {
    return React.cloneElement(children, { ref: inputRef })
  }

  return (
    <div>
      {React.cloneElement(children, { ref: highlightedRef })}
      {isHintOpened && (
        <Hint sizes={sizesAsObject} closeHint={closeHint}>
          {hint}
        </Hint>
      )}
    </div>
  )
}

Spotlight.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.string.isRequired,
  index: PropTypes.number,
  rules: PropTypes.array,
  type: PropTypes.oneOf(['input']),
  hint: PropTypes.string,
}

export { SpotlightTrigger, Spotlight }
