import React from 'react'

import Input from 'components/_old/Input/Input.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

interface AccountNumberProps {
  value: string | null
  validation: {
    rules: boolean[]
    errors: string[]
  }
  setValue: (value: string) => void
  isForceValidationEnabled: boolean
}

const AccountNumber: React.FC<AccountNumberProps> = ({ value, validation, setValue, isForceValidationEnabled }) => {
  return (
    <Validate rules={validation.rules} errors={validation.errors} skipWaitBlur={isForceValidationEnabled}>
      <LabelInlineStyle
        labelText="ISA account or reference number"
        multiline
        size="small"
        errorMessages={validation.errors}
      >
        <Input
          type="text"
          onChange={(_, value) => {
            setValue(value)
          }}
          data-test-id="isaAccount"
        >
          {value ?? ''}
        </Input>
      </LabelInlineStyle>
    </Validate>
  )
}

export { AccountNumber }
