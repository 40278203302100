import { useUnit } from 'effector-react'

import { useEffect } from 'hooks'

import { $pendingOrders, fetchPendingOrdersFx, $isLoading } from 'app/effector/pending-orders'

/**
 * @param {boolean} [shouldLoadPendingOrdersOnMount=true]
 * @returns {{ isLoading: boolean, orders: PendingOrderList }}
 */
const usePendingOrders = (shouldLoadPendingOrdersOnMount = true) => {
  const { pendingOrders, isLoading, fetchPendingOrders } = useUnit({
    pendingOrders: $pendingOrders,
    fetchPendingOrders: fetchPendingOrdersFx,
    isLoading: $isLoading,
  })

  useEffect(() => {
    if (shouldLoadPendingOrdersOnMount) {
      fetchPendingOrders()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoading,
    orders: pendingOrders,
  }
}

export { usePendingOrders }
