import React from 'react'

import { useMediaQueries } from 'hooks'

import { palette, isDarkTheme } from 'helpers/palette'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './MenuItem.css'

type MenuItemProps = {
  icon: string
  title: string
  link: string
  hard?: boolean
  blank?: boolean
  noArrow?: boolean
  dataTestId?: string
  saveScrollPosition: () => void
}

const MenuItem = ({
  icon,
  title,
  link,
  hard,
  blank = false,
  noArrow = false,
  dataTestId,
  saveScrollPosition,
}: MenuItemProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const isDark = isDarkTheme()
  const iconColor = isDark ? palette['content-on-background-minor'] : palette['content-on-background-additional']

  return (
    <Link
      to={link}
      hard={hard}
      blank={blank}
      onClick={() => {
        saveScrollPosition()
      }}
    >
      <Paper
        top={16}
        bottom={16}
        left={desktop ? 16 : 0}
        right={desktop ? 16 : 0}
        className="Menu_MenuItem"
        data-test-id={dataTestId}
      >
        <ItemWithIcon
          space={16}
          icon={
            noArrow ? null : (
              <Icon type="arrow-right-small-24" color={palette['content-on-background-minor']} size={24} inline />
            )
          }
          iconPosition="right"
          iconVerticalAlign="center"
          contentVerticalAlign="center"
          content={
            <ItemWithIcon
              space={16}
              icon={<Icon type={icon} color={iconColor} size={24} inline />}
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              content={
                <Typography lineHeight="small" className="Menu_MenuItem_text">
                  <Typo>{title}</Typo>
                </Typography>
              }
            />
          }
        />
      </Paper>
    </Link>
  )
}

export { MenuItem }
