import React from 'react'

import { features } from 'helpers/features'

import { types as clientTypes } from 'constants/client'
import { questionCodes } from 'constants/questionnaire.js'

import { PortfolioTunnel } from 'app/containers/PortfolioTunnel'

import { SelectPortfolioType } from './SelectPortfolioType'

import { CreateNewPortfolioRootRedirect as RootRedirect } from './NewPortfolioTunnel/RootRedirect'
import { Disclaimer } from './NewPortfolioTunnel/Disclaimer'
import { AllAnswers } from './NewPortfolioTunnel/AllAnswers'
import { GetStarted as IndividualGetStarted } from './NewPortfolioTunnel/Individual/GetStarted/GetStarted.jsx'
import { GetStarted as BusinessGetStarted } from './NewPortfolioTunnel/Business/GetStarted/GetStarted.jsx'
import { Question } from './NewPortfolioTunnel/Question'
import { Redirect, LastStep } from './NewPortfolioTunnel/QuestionWrappers'
import { getRoutes as getReviewPortfolioRoutes } from './NewPortfolioTunnel/ReviewPortfolio/routes'

const getTunnelRoutes = (type) => {
  const lowerCasedType = type.toLowerCase()

  const root = {
    type,
    isNewLayout: true,
    module: `new-portfolio-tunnel-${lowerCasedType}`,
    path: `${lowerCasedType}/`,
    name: 'Get started',
    component: function Component(props) {
      return <PortfolioTunnel {...props} />
    },
    childRoutes: [
      {
        insertableQuestions: true,
        path: 'question/:questionCode/',
        module: 'question',
        component: function Component(props) {
          return (
            <LastStep {...props}>
              <Question analyticsCategory="Create new portfolio" />
            </LastStep>
          )
        },
      },
      getReviewPortfolioRoutes({ Wrapper: Redirect, feeInfo: true }),
    ],
    indexRoute: null,
  }

  if (features.get('new-questionnaire')) {
    const reviewThisPortfolioRoute = root.childRoutes.at(-1)
    const questionRoutes = root.childRoutes.slice(0, root.childRoutes.length - 1)

    root.childRoutes = [
      {
        index: true,
        module: 'get-started',
        headline: 'Which portfolio is right for you?',
        feeInfo: true,
        firstStep: true,
        isQuestion: false,
        component: function Component(props) {
          return <Disclaimer {...props} />
        },
      },
      {
        module: 'initial-amount',
        path: 'initial-amount/',
        headline: type === clientTypes.BUSINESS ? 'My company would like to invest' : 'I would you like to invest',
        feeInfo: true,
        firstStep: false,
        isQuestion: false,
        component: function Component(props) {
          return type === clientTypes.BUSINESS ? (
            <BusinessGetStarted {...props} noDescription noInputLabel />
          ) : (
            <IndividualGetStarted {...props} skipAge noDescription noInputLabel />
          )
        },
      },
      ...questionRoutes,
      {
        module: 'all-answers',
        path: 'all-answers/',
        headline: 'Please confirm that all answers are correct',
        feeInfo: true,
        firstStep: false,
        isQuestion: false,
        component: function Component(props) {
          return <AllAnswers {...props} />
        },
      },
      reviewThisPortfolioRoute,
    ]
  } else {
    if (type === clientTypes.INDIVIDUAL) {
      root.childRoutes = [
        {
          index: true,
          module: 'get-started',
          headline: 'Find your investment portfolio',
          code: questionCodes.YEARS_OLD,
          feeInfo: true,
          firstStep: true,
          isQuestion: true,
          component: function Component(props) {
            return <IndividualGetStarted {...props} />
          },
        },
        ...root.childRoutes,
      ]
    }

    if (type === clientTypes.BUSINESS) {
      root.childRoutes = [
        {
          index: true,
          module: 'get-started',
          headline: 'Find your investment portfolio',
          feeInfo: true,
          firstStep: true,
          component: function Component(props) {
            return <BusinessGetStarted {...props} />
          },
        },
        ...root.childRoutes,
      ]
    }
  }

  root.indexRoute = root.childRoutes.find(({ index }) => index)

  return root
}

const childRoutes = [
  {
    isNewLayout: true,
    module: 'select-portfolio-type',
    component: function Component(props) {
      return <SelectPortfolioType {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'new-portfolio-tunnel',
    path: ':id/',
    component: function Component(props) {
      return <RootRedirect {...props} />
    },
    childRoutes: [getTunnelRoutes(clientTypes.INDIVIDUAL), getTunnelRoutes(clientTypes.BUSINESS)],
  },
]

export default {
  module: 'create-new-portfolio',
  path: 'create-new-portfolio/',
  childRoutes,
  indexRoute: childRoutes[0],
}
