import React from 'react'

import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

import { DesktopFooter } from './DesktopFooter'

import './MobileFooter.css'

type MobileFooterProps = {
  noMarginTop?: boolean
}

const MobileFooter = ({ noMarginTop = false }: MobileFooterProps): React.ReactElement => {
  return (
    <Paper className="MobileFooter" top={noMarginTop ? 0 : 48}>
      <SideSpacesCompensator>
        <DesktopFooter />
      </SideSpacesCompensator>
    </Paper>
  )
}

export { MobileFooter }
