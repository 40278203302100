import { type PointOfInterestCardPositions } from '../Card'

enum PortfolioPointOfInterestTypes {
  BUY = 'BUY',
  SELL = 'SELL',
  COMBINED = 'COMBINED',
}

type BuySellPortfolioPointOfInterest = {
  type: Omit<PortfolioPointOfInterestTypes, PortfolioPointOfInterestTypes.COMBINED>
  date: Date
  position?: PointOfInterestCardPositions
  center?: number
  data: {
    transactions_quantity: string
    total_value: string
  }
}

type CombinedPortfolioPointOfInterest = {
  type: PortfolioPointOfInterestTypes.COMBINED
  date: Date
  dateFrom: Date
  dateTo: Date
  data: Array<Omit<BuySellPortfolioPointOfInterest, 'date' | 'position' | 'center'>>
}

type PortfolioPointOfInterest = BuySellPortfolioPointOfInterest & CombinedPortfolioPointOfInterest

export { PortfolioPointOfInterestTypes }

export * from './BuySellContent'
export * from './CombinedContent'
export * from './Card'

export type { BuySellPortfolioPointOfInterest, CombinedPortfolioPointOfInterest, PortfolioPointOfInterest }
