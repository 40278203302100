import React from 'react'

import Input from 'components/_old/Input/Input.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

interface IsaProviderCustomNameProps {
  value: string | null
  validation: {
    rules: boolean[]
    errors: string[]
  }
  setValue: (value: string) => void
  isForceValidationEnabled: boolean
}

const IsaProviderCustomName: React.FC<IsaProviderCustomNameProps> = ({
  value,
  validation,
  setValue,
  isForceValidationEnabled,
}) => {
  return (
    <Validate rules={validation.rules} errors={validation.errors} skipWaitBlur={isForceValidationEnabled}>
      <LabelInlineStyle
        labelText="If other, please specify"
        multiline
        size="small"
        dataTestId="isaProviderCustomField"
        errorMessages={validation.errors}
      >
        <Input
          type="text"
          onChange={(_, value: string): void => {
            setValue(value)
          }}
        >
          {value ?? ''}
        </Input>
      </LabelInlineStyle>
    </Validate>
  )
}

export { IsaProviderCustomName }
