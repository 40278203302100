import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import { NavigationBar } from 'components/atoms/NavigationBar'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Width from 'components/_old/Width/Width'
import Inner from 'components/_old/Inner/Inner.jsx'
import Segment from 'components/atoms/Segment/Segment.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import { BankAccountStateContent } from 'app/pages/Dashboard/Goals/components/BankAccount/BankAccountStateContent.jsx'

const Cancel = ({ onContinue: handleContinue }) => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      {!desktop && <NavigationBar>Instruction cancelled</NavigationBar>}
      <AllCenter>
        <Width size={65}>
          <Width size={45} center>
            <Inner>
              <BankAccountStateContent
                title="Instruction has been cancelled"
                description="Your funding instruction has been cancelled. Please input a new instruction or make a manual bank transfer when you are ready to invest in your portfolio."
                icon={{
                  type: 'exclamation-triangle',
                  color: palette['status-warning'],
                  size: 48,
                }}
                data-test-id="instantPaymentCancel"
              >
                <Segment mods={{ margin: 'hefty', noMargin: 'top' }}>
                  <ModalContentButtons width={desktop ? 16 : 24} isNewModal>
                    <Button
                      mods={{ size: 'big block' }}
                      onClick={handleContinue}
                      data-test-id="instantPaymentCancelButton"
                    >
                      Continue
                    </Button>
                  </ModalContentButtons>
                </Segment>
              </BankAccountStateContent>
            </Inner>
          </Width>
        </Width>
      </AllCenter>
    </Fragment>
  )
}

Cancel.propTypes = {
  onContinue: PropTypes.func.isRequired,
}

export { Cancel }
