import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo, useCallback, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'

import { $owner } from 'app/effector/contacts'
import { $statsStore } from 'app/effector/stats'

import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'
import { Profit } from 'components/molecules/Profit'

import { CreateOrTransferDropdown } from 'app/pages/Dashboard/Accounts/components/CreateOrTransferDropdown'

import { types as clientTypes, states as clientStates } from 'constants/client'

import './Performance.css'

type PerformanceProps = {
  createPortfolioUrl: string
  showCreatePortfolioButton: boolean
}

const Performance = ({ createPortfolioUrl, showCreatePortfolioButton }: PerformanceProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { stats, initialFetchCompleted } = useUnit($statsStore)
  const { currentBalance, returnMoney, skeleton, shouldAnimate } = useMemo(() => {
    const { current_balance: currentBalance, periods } = stats
    const returnMoney = periods.max?.return_money
    const skeleton = !initialFetchCompleted
    const shouldAnimate = initialFetchCompleted

    return {
      currentBalance,
      returnMoney,
      skeleton,
      shouldAnimate,
    }
  }, [stats, initialFetchCompleted])

  const owner = useUnit($owner)
  const client = useSelector((state) => state.client)
  const isBusiness = client.type === clientTypes.BUSINESS
  const isUkResidence = owner?.isUkResidence()
  const isSippAge = owner?.age < 75
  const isDropdownVisible =
    (!isBusiness && client.state === clientStates.NOT_COMPLETED) || (!isBusiness && isUkResidence)

  const handleCreatePortfolioClick = useCallback(() => {
    trackEvent(
      {
        action: 'add_portfolio_clicked',
      },
      { plugins: { 'google-analytics-v3': false } },
    )
  }, [])

  const performance = useMemo(() => {
    const amount = (typeof returnMoney === 'number' ? returnMoney : parseFloat(returnMoney)) ?? 0

    if (!skeleton && !amount) return null

    const description = (
      <div>
        <Typography size={desktop ? 16 : 24} lineHeight="small" weight="semibold">
          <Typo>Total Return</Typo>
        </Typography>
        <Paper top={16}>
          <Typo>
            The total capital appreciation and income received across all of your InvestEngine portfolios since you
            began investing
          </Typo>
        </Paper>
      </div>
    )

    if (skeleton) {
      return (
        <Skeleton shown inline mix>
          <div className="AccountPerformance-Tooltip">
            <Typography size={14} lineHeight="small" inline>
              <Profit amount={9876.43} showZeroPence />
            </Typography>
            <TooltipToModal className="AccountPerformance-Value" customIcon>
              <Link mods={{ color: 'mutedToRed' }}>
                <Paper left={4}>
                  <Icon size={18} type="questionInCircle" color="inherit" />
                </Paper>
              </Link>
            </TooltipToModal>
          </div>
        </Skeleton>
      )
    }

    return (
      <div className="AccountPerformance-Tooltip">
        <Typography size={14} lineHeight="small" inline data-test-id="accountPerformance">
          <Profit amount={amount} data-test-id="accountTotal" showZeroPence />
        </Typography>
        <TooltipToModal
          className="AccountPerformance-Value"
          description={description}
          customIcon
          data-test-id="accountPerformanceTooltip"
        >
          <Link mods={{ color: 'mutedToRed' }}>
            <Paper left={4}>
              <Icon size={18} type="questionInCircle" color="inherit" />
            </Paper>
          </Link>
        </TooltipToModal>
      </div>
    )
  }, [desktop, skeleton, returnMoney])

  const totalBalanceNode = useMemo(
    () => (
      <Fragment>
        <Typography size={14} lineHeight="small" color="minor">
          <Typo>Total balance</Typo>
        </Typography>
        <Paper top={4}>
          <Typography size={32} lineHeight="small" weight="semibold" data-test-id="accountBalance">
            <Skeleton shown={skeleton} inline>
              <Fragment>
                <PoundsWithPence
                  amount={skeleton ? 9876.54 : currentBalance}
                  numeric="tabular"
                  showZeroPence={skeleton ? true : currentBalance > 0}
                  animated={shouldAnimate}
                  cacheName={shouldAnimate ? 'Dashboard/Performance' : undefined}
                />
              </Fragment>
            </Skeleton>
          </Typography>
        </Paper>
      </Fragment>
    ),
    [currentBalance, shouldAnimate, skeleton],
  )

  if (!showCreatePortfolioButton) {
    return (
      <Fragment>
        {totalBalanceNode}
        {performance && <Paper top={4}>{performance}</Paper>}
      </Fragment>
    )
  }

  if (desktop) {
    return (
      <ColumnarLayout mods={{ flexWrap: 'wrap' }}>
        <Column>
          {totalBalanceNode}
          {performance && <Paper top={4}>{performance}</Paper>}
        </Column>
        <Column size={1} mods={{ noShrink: true }}>
          {isDropdownVisible ? (
            <CreateOrTransferDropdown
              createPortfolioUrl={createPortfolioUrl}
              handleCreatePortfolioClick={handleCreatePortfolioClick}
              isSippAge={isSippAge}
            />
          ) : (
            <Paper bottom={4}>
              <Typography size={16} align="right" lineHeight="small">
                <Link to={createPortfolioUrl} onClick={handleCreatePortfolioClick} data-test-id="addNewPortfolioButton">
                  <Nobr>Create portfolio</Nobr>
                </Link>
              </Typography>
            </Paper>
          )}
        </Column>
      </ColumnarLayout>
    )
  }

  return (
    <Fragment>
      {isDropdownVisible ? (
        <CreateOrTransferDropdown
          createPortfolioUrl={createPortfolioUrl}
          handleCreatePortfolioClick={handleCreatePortfolioClick}
          isSippAge={isSippAge}
        />
      ) : (
        <Typography size={16} align="right" lineHeight="small">
          <Link to={createPortfolioUrl} onClick={handleCreatePortfolioClick} data-test-id="addNewPortfolioButton">
            <Nobr>Create portfolio</Nobr>
          </Link>
        </Typography>
      )}
      <Paper>
        {totalBalanceNode}
        {performance && <Paper top={4}>{performance}</Paper>}
      </Paper>
    </Fragment>
  )
}

Performance.propTypes = {
  createPortfolioUrl: PropTypes.string,
}

export { Performance }
