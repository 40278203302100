import React from 'react'
import classNames from 'classnames/dedupe'

import rawMediaQueries from 'helpers/mediaQueries.js'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import './Form.css'

@withMediaQueries(rawMediaQueries)
export default class Form extends React.Component {
  render() {
    const { children, style, flex, mediaQueries } = this.props

    return (
      <div
        className={classNames('Form', { Form_flex: flex }, getMediaQuieryClasses('Form', mediaQueries))}
        style={style}
      >
        {children}
      </div>
    )
  }
}

export function Fieldset(props) {
  const { marginNext, grow, children } = props

  return (
    <fieldset
      className={classNames('Form-Fieldset', {
        'Form-Fieldset_grow': grow,
        'Form-Fieldset_margin-next': marginNext,
      })}
    >
      {children}
    </fieldset>
  )
}
