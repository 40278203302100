import React from 'react'
import { withRouter } from 'react-router'

import PropTypes from 'prop-types'

import { useMediaQueries, useRedirect, useMemo } from 'hooks'

import { addOrdinalSuffix } from 'helpers/date.js'
import { format as formatMoney } from 'helpers/money'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Text from 'components/_old/Text/Text.jsx'
import TransferFundsTo from 'components/_old/TransferFundsTo/TransferFundsTo.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import Segment from 'components/atoms/Segment/Segment.jsx'

import { OptionsLayout } from '../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../Options/OptionsNavigationBar'
import { useCheckPaymentDetails } from '../hooks'

type CheckPaymentDetailsProps = {
  portfolio: object
  handleClose: () => void
  location: { query: Record<string, string> }
}

const CheckPaymentDetails = withRouter(
  ({ portfolio, handleClose, location }: CheckPaymentDetailsProps): React.ReactElement => {
    const { desktop } = useMediaQueries()
    const {
      isLoading,
      accountHolder,
      accountNumber,
      sortCode,
      monthlyPaymentAmount,
      monthlyPaymentDay,
      portfolioTitle,
      handleBack,
      handleConfirm,
      isSubscribeRequestInProgress,
    } = useCheckPaymentDetails(portfolio, location, handleClose)

    useRedirect({
      to: handleBack,
      rule: portfolio && (!monthlyPaymentAmount || !monthlyPaymentDay),
      isLoading,
    })

    const content = useMemo(
      () => (
        <Width size={30} center>
          <Paper top={desktop ? 40 : 12}>
            <Headline level={desktop ? 1 : 2} mods={{ 'no-margin': 'top', text: 'center' }}>
              <Typo>Check Your Details</Typo>
            </Headline>
          </Paper>
          <Card
            mods={{
              text: desktop ? 'center' : 'left',
              theme: 'transparent',
              padding: 'small',
              'no-padding': desktop ? 'top left right' : 'left right',
            }}
          >
            <Segment mods={{ margin: 'smallest' }}>
              <Text small={!desktop}>
                <Typo>Please confirm the details of your Direct Debit setup</Typo>
              </Text>
            </Segment>
          </Card>
          <TransferFundsTo
            desktop={desktop}
            fields={{
              'Account holder': accountHolder,
              'Sort code': sortCode,
              'Account Number': accountNumber,
              'Monthly payment': monthlyPaymentAmount && formatMoney(monthlyPaymentAmount, true),
              'On or around': monthlyPaymentDay && `${addOrdinalSuffix(monthlyPaymentDay)} of each month`,
              To: portfolioTitle,
            }}
            smallMuted
          />
        </Width>
      ),
      [desktop, monthlyPaymentAmount, monthlyPaymentDay, portfolioTitle, accountHolder, accountNumber, sortCode],
    )

    return (
      <OptionsLayout
        header={
          <OptionsNavigationBar
            leftPartText="Back"
            onLeftPartClick={handleBack}
            rightPartText="Close"
            onRightPartClick={handleClose}
          >
            <Typo>Savings Plan set up</Typo>
          </OptionsNavigationBar>
        }
        content={content}
        button={
          <Button
            disabled={isSubscribeRequestInProgress}
            onClick={handleConfirm}
            mods={{ size: 'big block' }}
            data-test-id="directDebitConfirmButton"
          >
            Confirm details
          </Button>
        }
        data-test-id="checkPaymentDetailsModal"
      />
    )
  },
)

CheckPaymentDetails.propTypes = {
  portfolio: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export { CheckPaymentDetails }
