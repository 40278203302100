import * as React from 'react'

import capitalize from 'lodash/capitalize'

import { useMemo } from 'hooks'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Badge } from 'components/atoms/Badge'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { TextCollapse } from 'components/atoms/TextCollapse'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { PortfolioTitle } from 'app/pages/Dashboard/Analytics/SingleHolding/components/PortfolioTitle'

import { type HoldingData } from '../../hooks/useHoldingModal'
import { DistributionChart } from '../components/DistributionChart'
import { HoldingSecurity } from '../components/HoldingSecurity'

type HoldingModalDesktopProps = {
  isPortfolioNew: boolean
  selectedPortfolio: Portfolio
  holdingData: HoldingData | null
  holdingDistribution: Array<{ value: number; color: string }>
}

const Desktop = ({
  isPortfolioNew,
  selectedPortfolio,
  holdingData,
  holdingDistribution,
}: HoldingModalDesktopProps): React.ReactElement => {
  const hasRegionOrSectorInfo = useMemo(
    () => Boolean(holdingData?.sector_name) || Boolean(holdingData?.region_name),
    [holdingData],
  )

  const titleNode = useMemo(() => {
    const title = (
      <Typography size={32} lineHeight="small" weight="semibold">
        <Typo>{holdingData?.name} (Equity)</Typo>
      </Typography>
    )

    if (holdingData?.logo) {
      return (
        <ItemWithIcon
          space={24}
          icon={<SecurityLogo logo={holdingData?.logo} size={48} />}
          content={title}
          contentVerticalAlign="center"
        />
      )
    }

    return title
  }, [holdingData?.name, holdingData?.logo])

  const sectorAndRegionNode = useMemo(
    () =>
      hasRegionOrSectorInfo ? (
        <Paper top={24}>
          <Typography size={14}>
            {holdingData?.sector_name && <Badge className="SingleHolding_Badge" text={holdingData?.sector_name} />}
            {holdingData?.region_name && <Badge className="SingleHolding_Badge" text={holdingData?.region_name} />}
          </Typography>
        </Paper>
      ) : null,
    [hasRegionOrSectorInfo, holdingData],
  )

  const descriptionNode = useMemo(
    () =>
      holdingData?.description ? (
        <Paper top={hasRegionOrSectorInfo ? 16 : 24}>
          <Typography size={14} color="additional">
            <TextCollapse limit={280}>{holdingData?.description}</TextCollapse>
          </Typography>
        </Paper>
      ) : null,
    [holdingData?.description, hasRegionOrSectorInfo],
  )

  const websiteNode = useMemo(
    () =>
      holdingData?.website ? (
        <Paper top={24}>
          <Link to={holdingData?.website} blank>
            <Typography size={14} color="inherit">
              <Typo>{holdingData?.website}</Typo>
            </Typography>
          </Link>
        </Paper>
      ) : null,
    [holdingData?.website],
  )

  const distributionTitleNode = useMemo(
    () => (
      <Paper top={56}>
        <Typography size={20} lineHeight="small" weight="semibold">
          <Typo>Weightings of {holdingData?.type} in this portfolio</Typo>
        </Typography>
      </Paper>
    ),
    [holdingData?.type],
  )

  const selectedPortfolioNode = useMemo(
    () =>
      selectedPortfolio ? (
        <Paper top={8}>
          <PortfolioTitle portfolio={selectedPortfolio} />
        </Paper>
      ) : null,
    [selectedPortfolio],
  )

  const distributionNode = useMemo(
    () => (
      <Paper top={32}>
        <Paper bottom={24}>
          {isPortfolioNew ? (
            <Typography size={14} lineHeight="small" color="additional">
              <Typo>Target</Typo>
            </Typography>
          ) : (
            <Typography>
              <Typography tag="span" size={14} lineHeight="small" color="minor">
                <Typo>Current target</Typo>{' '}
              </Typography>
              <Typography tag="span" size={14} lineHeight="small">
                <Typo>→ New target</Typo>
              </Typography>
            </Typography>
          )}
        </Paper>
        <DistributionChart
          showActual={!isPortfolioNew}
          name={holdingData?.name}
          actualWeight={holdingData?.current_target_weight}
          targetWeight={holdingData?.target_weight}
          distribution={holdingDistribution}
        />
      </Paper>
    ),
    [isPortfolioNew, holdingData, holdingDistribution],
  )

  const securitiesNode = useMemo(
    () => (
      <Paper top={56}>
        {holdingData?.securities.map((security, index) => (
          <Paper top={index === 0 ? 0 : 24} key={security.id}>
            <HoldingSecurity showActual={!isPortfolioNew} security={security} />
          </Paper>
        ))}
      </Paper>
    ),
    [isPortfolioNew, holdingData],
  )

  return (
    <Width size={67.5}>
      <Paper top={80} bottom={80} left={56} right={56}>
        <Paper left={16} right={16}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1}>
              {titleNode}
              {distributionTitleNode}
              {selectedPortfolioNode}
              {distributionNode}
              {securitiesNode}
            </Column>
            <Column size={0}>
              <Paper left={80}>
                <Width size={16}>
                  <Typography size={20} lineHeight="small" weight="semibold">
                    <Typo>{capitalize(holdingData?.type)} description</Typo>
                  </Typography>
                  {sectorAndRegionNode}
                  {descriptionNode}
                  {websiteNode}
                </Width>
              </Paper>
            </Column>
          </ColumnarLayout>
        </Paper>
      </Paper>
    </Width>
  )
}

export { Desktop }
