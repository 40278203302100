import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries, useMemo } from 'hooks'

import { Typography, propTypes as TypographyPropTypes } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'
import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Profit } from 'components/molecules/Profit'

import './PriceInformation.css'

const PriceInformation = ({ period = {}, description, align = 'left' }) => {
  const { desktop } = useMediaQueries()

  const periodNode = useMemo(() => {
    const { price = 0, percent = 0 } = period

    return (
      <Typography tag="span" weight="semibold" lineHeight="small" size={desktop ? 32 : 18}>
        <Profit amount={price || 0} percent={percent} smallPences showZeroPence data-test-id="priceInformation" />
      </Typography>
    )
  }, [period, desktop])

  const periodName = useMemo(
    () =>
      period.name ? (
        <Typography tag="span" color="minor" size={14}>
          <Typo>{period.name}</Typo>
        </Typography>
      ) : null,
    [period.name],
  )

  const descriptionNode = useMemo(() => {
    if (!description) {
      return null
    }

    return (
      <TooltipToModal className="PriceInformation-Description" description={description} customIcon>
        <Link
          className={classNames('PriceInformation-DescriptionToggle', {
            'PriceInformation-DescriptionToggle_center': desktop,
          })}
          mods={{ color: 'mutedToRed' }}
        >
          <Icon size={18} type="questionInCircle" color="inherit" inline />
        </Link>
      </TooltipToModal>
    )
  }, [description])

  if (desktop) {
    return (
      <Fragment>
        {periodNode}
        <Paper top={8}>
          {periodName}
          {descriptionNode}
        </Paper>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {periodNode} {periodName}
      {descriptionNode}
    </Fragment>
  )
}

PriceInformation.propTypes = {
  period: PropTypes.shape({
    price: PropTypes.number,
    percent: PropTypes.number,
    name: PropTypes.string,
  }),
  description: PropTypes.node,
  align: TypographyPropTypes.align,
}

export { PriceInformation }
