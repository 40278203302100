import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { TwoColumns } from 'components/molecules/TwoColumns'

import { EditableSecurityList } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/EditableSecurityList'
import { Sticky } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/Sticky'
import { Submit } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/Submit'
import { TunnelHeader } from 'app/pages/Securities/components'

import { WeightsChart } from '../components/WeightsChart'

import './Desktop.css'

const Desktop = ({
  infoText,
  securities,
  total,
  control,
  handleAddSecurities,
  handleCancel,
  handleBack,
  handleDelete,
  currentValues,
  highlightedRef,
}) => {
  const header = useMemo(() => {
    return (
      <Inner twoColumns>
        <TunnelHeader onBack={handleBack} onCancel={handleCancel} />
      </Inner>
    )
  }, [handleBack, handleCancel])

  const stickyFooter = (
    <Sticky bottom>
      <div className="EditWeights_StickedArea">
        <Inner twoColumns>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1}>
              <Paper top={20} bottom={64}>
                <Width size={36}>
                  <Submit securities={securities} total={total} />
                </Width>
              </Paper>
            </Column>
            <Column size={0} />
          </ColumnarLayout>
        </Inner>
      </div>
    </Sticky>
  )

  const content = useMemo(() => {
    return (
      <Fragment>
        <Inner twoColumns>
          <Typography size={24} lineHeight="small">
            <Typo>Set portfolio weights</Typo>
          </Typography>
          <Paper top={40}>
            <ColumnarLayout mods={{ padding: 'no' }}>
              <Column size={1}>
                <ItemWithIcon
                  space={12}
                  icon={<Icon type="information-24" size={24} color="inherit" />}
                  content={
                    <Typography size={14}>
                      <Typo>{infoText}</Typo>
                    </Typography>
                  }
                />
                <Paper top={40}>
                  <Button
                    type="button"
                    mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
                    onClick={handleAddSecurities}
                    data-test-id="addSecuritiesButton"
                  >
                    + Add securities
                  </Button>
                </Paper>
                <Paper top={40}>
                  <WeightsChart securities={securities} currentValues={currentValues} />
                </Paper>
                <Paper top={40} bottom={40} style={{ minHeight: '500px' }}>
                  <EditableSecurityList
                    securities={securities}
                    control={control}
                    handleDelete={handleDelete}
                    highlightedRef={highlightedRef}
                  />
                </Paper>
              </Column>
              <Column size={0}>
                <Paper left={80}>
                  <Width size={19}>
                    <Card>
                      <Paper top={32} bottom={32} left={24} right={24}>
                        <Typography size={20} lineHeight="small" weight="semibold">
                          <Typo>Weights and portfolio look-through</Typo>
                        </Typography>
                        <Paper top={24}>
                          <Typography size={14} color="additional">
                            <Typo>
                              You can see how your chosen weights affect your portfolio’s composition in terms of asset
                              types, geographical regions, business sectors and underlying holdings on the next screen.
                            </Typo>
                          </Typography>
                        </Paper>
                      </Paper>
                    </Card>
                  </Width>
                </Paper>
              </Column>
            </ColumnarLayout>
          </Paper>
        </Inner>
        {stickyFooter}
      </Fragment>
    )
  }, [infoText, handleAddSecurities, securities, currentValues, control, handleDelete, highlightedRef, stickyFooter])

  return <DesktopLayout header={header} content={content} />
}

Desktop.propTypes = {
  infoText: PropTypes.string,
  securities: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  control: PropTypes.object,
  handleAddSecurities: PropTypes.func,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleDelete: PropTypes.func,
  currentValues: PropTypes.object,
  highlightedRef: PropTypes.object,
}

export { Desktop }
