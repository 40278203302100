import React from 'react'

import classNames from 'classnames/dedupe.js'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMediaQueries, useRef } from 'hooks'

import { features } from 'helpers/features'
import { bulkValidate } from 'helpers/validation.js'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import { ScrollToElementIfOutsideViewport } from 'components/_old/molecules/ScrollToElementIfOutsideViewport'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card as NewCard } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { IncomeProjectionsChart } from 'components/organisms/charts'

import { type ManagedPortfolio } from '../../../../types'

import { IncomePortfolioFooterInfo } from './components/FooterInfo'
import { EditIncomePortfolioControls } from './Controls'

import './EditIncomePortfolio.css'

type EditIncomePortfolioProps = {
  isLoading: boolean
  isGoalLocked: boolean
  isGoalChanged: boolean
  projectionsData: object | null
  goal: ManagedPortfolio
  presets: object[]
  isActiveGoal: boolean
  validation: object
  initialDepositMin: number
  shouldShowScrollToKey: string
  onChange: (field: string, value: number) => void
  handleSubmit: () => void
  setShouldShowScrollToKey: (value: string) => void
}

const EditIncomePortfolio = ({
  isLoading,
  isGoalLocked,
  isGoalChanged,
  projectionsData,
  goal,
  presets,
  isActiveGoal = false,
  validation,
  initialDepositMin,
  shouldShowScrollToKey,
  onChange,
  handleSubmit,
  setShouldShowScrollToKey,
}: EditIncomePortfolioProps): React.ReactElement => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const isTwoColumnProjectionsEnabled = features.get('updated-income-projections')

  const chartRef = useRef()

  const locked = (
    <Card
      className="EditIncomePortfolio-Message"
      mods={{ theme: 'gray', padding: 'smaller' }}
      data-test-id="projectionsLockedMessage"
    >
      <Card mods={{ theme: 'transparent', padding: 'nano', 'no-padding': 'top bottom' }}>
        <Typography color="white">
          <Typo>Processing allocation change request</Typo>
        </Typography>
      </Card>
    </Card>
  )

  const submitButton = isGoalChanged ? (
    <Button
      type="submit"
      mods={{
        color: 'green',
        size: desktop,
        text: desktop ? null : 'smaller',
        theme: desktop ? 'simple-green' : 'simple-green round',
      }}
      disabled={!bulkValidate(validation)}
      onClick={handleSubmit}
      data-test-id="editPortfolioSaveButton"
    >
      Apply changes
    </Button>
  ) : null

  const scrollIntoViewport = (
    <ScrollToElementIfOutsideViewport
      elementToScrollTo={chartRef}
      shouldShowScrollToKey={shouldShowScrollToKey}
      setShouldShowScrollToKey={setShouldShowScrollToKey}
      color="blue"
    >
      Projections updated ↓
    </ScrollToElementIfOutsideViewport>
  )

  const chartNode = (
    <IncomeProjectionsChart
      isNewProjections={isTwoColumnProjectionsEnabled}
      data={projectionsData}
      empty={
        <Typography align="center">
          <Typo>{projectionsData ? 'Not enough data for projections' : 'Please, enter your initial investment'}</Typo>
        </Typography>
      }
      loading={isLoading}
    />
  )

  if (isTwoColumnProjectionsEnabled) {
    return desktop ? (
      <Paper top={desktop ? 56 : 16}>
        <ColumnarLayout mods={{ padding: 'no' }}>
          <Column size={1}>
            {chartNode}
            <IncomePortfolioFooterInfo />
          </Column>
          <Column size={0}>
            <Paper left={80}>
              <Width size={19}>
                <NewCard>
                  <Paper top={40} bottom={24} left={24} right={24}>
                    controls here
                  </Paper>
                </NewCard>
              </Width>
            </Paper>
          </Column>
        </ColumnarLayout>
      </Paper>
    ) : (
      <Paper>
        <div>controls here</div>
        {chartNode}
        <IncomePortfolioFooterInfo />
      </Paper>
    )
  }

  const content = (
    <SubmitOnEnter>
      <Paper
        top={desktop ? 56 : 24}
        className={classNames('EditIncomePortfolio', getMediaQuieryClasses('EditIncomePortfolio', mediaQueries))}
      >
        <EditIncomePortfolioControls
          goal={goal}
          presets={presets}
          isActiveGoal={isActiveGoal}
          initialDepositMin={initialDepositMin}
          validation={validation}
          onChange={onChange}
        />

        <Paper top={desktop ? 48 : 16} right={desktop ? 0 : 16} left={desktop ? 0 : 16} ref={chartRef}>
          {chartNode}
          <IncomePortfolioFooterInfo />
        </Paper>
        {!desktop && scrollIntoViewport}
        <Paper top={desktop ? 24 : 32} className="EditIncomePortfolio-Buttons">
          {isGoalLocked ? locked : submitButton}
        </Paper>
      </Paper>
    </SubmitOnEnter>
  )

  if (desktop) {
    return content
  }

  return <SideSpacesCompensator>{content}</SideSpacesCompensator>
}

export { EditIncomePortfolio }
