import React, { Fragment, Children, cloneElement, type ReactElement, type ReactNode } from 'react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper, type PaperSizes } from 'components/atoms/Paper'

type OptionsLayoutProps = {
  header: ReactNode | null
  content: ReactNode | null
  button: ReactElement | ReactElement[] | null
  center?: boolean
  contentPaperSizes?: {
    top: ArrayElement<typeof PaperSizes>
    right: ArrayElement<typeof PaperSizes>
    bottom: ArrayElement<typeof PaperSizes>
    left: ArrayElement<typeof PaperSizes>
  }
  'data-test-id'?: string
}

function OptionsLayout({
  header,
  content,
  button,
  center,
  contentPaperSizes,
  'data-test-id': dataTestId,
}: OptionsLayoutProps): React.ReactElement {
  const { desktop } = useMediaQueries()

  if (desktop) {
    const contentNode = content && (
      <Paper top={header ? 24 : 0} bottom={button ? 0 : 80}>
        {content}
      </Paper>
    )
    const buttonNode = button && (
      <Paper top={56} bottom={80}>
        <Width size={24} center>
          {Children.map(button, (button, index) => {
            const mods = { ...(button?.props?.mods || {}), size: 'big block' }
            const props = { ...(button?.props || {}), mods }

            return <Paper top={index > 0 ? 24 : 0}>{cloneElement(button, props)}</Paper>
          })}
        </Width>
      </Paper>
    )

    return (
      <Width size={36} style={{ minHeight: 600 }} data-test-id={dataTestId}>
        {header && <Paper>{header}</Paper>}
        {center ? (
          <AllCenter style={{ minHeight: 560 }}>
            {contentNode}
            {buttonNode}
          </AllCenter>
        ) : (
          <Fragment>
            {contentNode}
            {buttonNode}
          </Fragment>
        )}
      </Width>
    )
  }

  return (
    <MobileLayout
      header={header}
      content={center ? <AllCenter>{content}</AllCenter> : content}
      footer={Children.map(button, (button) => (
        <MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>
      ))}
      contentPaperSizes={contentPaperSizes ?? { top: 32, right: 16, bottom: button ? 24 : 32, left: 16 }}
      data-test-id={dataTestId}
    />
  )
}

export { OptionsLayout, type OptionsLayoutProps }
