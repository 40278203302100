import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

import { type OnboardingState } from '../types'

const getOnboardingState = async ({ portfolioId }: { portfolioId: number }): Promise<{ state: OnboardingState }> => {
  const { data } = await axios.get(`portfolios/${portfolioId}/onboarding/`, { shouldNotSend404: true })

  return data
}

const finishOnboarding = async ({ portfolioType }): Promise<undefined | ApiError> => {
  try {
    await axios.post(`notifications/close-panel/onboarding-${portfolioType === 'DIY' ? 'diy' : 'managed'}/`)
  } catch (error) {
    return new ApiError(error)
  }
}

export { getOnboardingState, finishOnboarding }
