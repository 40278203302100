import { nullable, type Nullable, int, string, enumValue } from 'app/effector/model'

enum EsgClassification {
  ARTICLE_6 = 'ARTICLE_6',
  ARTICLE_8 = 'ARTICLE_8',
  ARTICLE_9 = 'ARTICLE_9',
}

enum DividendsType {
  ACCUMULATING = 'ACCUMULATING',
  DISTRIBUTING = 'DISTRIBUTING',
}

enum DividendsFrequency {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  ANNUAL = 'ANNUAL',
  IRREGULARLY = 'IRREGULARLY',
}

enum ReplicationMethod {
  PHYSICAL_FULL = 'PHYSICAL_FULL',
  PHYSICAL_OPTIMISED = 'PHYSICAL_OPTIMISED',
  SYNTHETIC = 'SYNTHETIC',
  ACTIVELY_MANAGED = 'ACTIVELY_MANAGED',
  SAMPLING = 'SAMPLING',
}

type SecurityPropertiesDTO = {
  is_esg: boolean
  esg_classification: EsgClassification | null
  is_hedged: boolean
  dividends_type?: DividendsType
  dividends_frequency: DividendsFrequency | null
  ter?: string
  replication_method: ReplicationMethod | null
  fund_size_mm?: number
  share_class_size_mm?: number
}

class SecurityProperties {
  readonly is_esg: boolean
  readonly esg_classification: Nullable<EsgClassification>
  readonly is_hedged: boolean
  readonly dividends_type: Nullable<DividendsType>
  readonly dividends_frequency: Nullable<DividendsFrequency>
  readonly ter: Nullable<string>
  readonly replication_method: Nullable<ReplicationMethod>
  readonly fund_size_mm: Nullable<number>
  readonly share_class_size_mm: Nullable<number>

  constructor(dto?: SecurityPropertiesDTO) {
    this.is_esg = dto?.is_esg ?? false
    this.esg_classification = !dto?.esg_classification
      ? null
      : nullable(enumValue)(dto?.esg_classification, EsgClassification)
    this.is_hedged = dto?.is_hedged ?? false
    this.dividends_type = dto?.dividends_type === null ? null : nullable(enumValue)(dto?.dividends_type, DividendsType)
    this.dividends_frequency = !dto?.dividends_frequency
      ? null
      : nullable(enumValue)(dto?.dividends_frequency, DividendsFrequency)
    this.ter = nullable(string)(dto?.ter)
    this.replication_method = !dto?.replication_method
      ? null
      : nullable(enumValue)(dto?.replication_method, ReplicationMethod)
    this.fund_size_mm = nullable(int)(dto?.fund_size_mm)
    this.share_class_size_mm = nullable(int)(dto?.fund_size_mm)
  }
}

export { SecurityProperties, DividendsType, DividendsFrequency, ReplicationMethod, EsgClassification }
