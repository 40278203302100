import { sendError } from 'helpers/errorLogging.js'

import { Address } from 'app/redux/models/address'
import { Entity } from 'app/redux/models/common/Entity'

export class AddressList extends Entity {
  constructor() {
    super()

    this.list = [new Address({ is_current: true })]
  }

  /** @override */
  getRules() {
    return {
      list: [
        [
          (value) => {
            // TODO Remove after removing getCloneOf (after models become immutable)
            const list = value.list || value
            const initialAddresses = [new Address({ is_current: true })]

            if (!Array.isArray(list)) {
              sendError(`"value" passed to "list" rule does not contain an array of addresses`, { list })
              return initialAddresses
            }

            if (list.length > 0) {
              return list.map((address) => Address.createFromObject(address))
            }

            return initialAddresses
          },
        ],
      ],
    }
  }

  /**
   * @param {Address} address
   *
   * @returns {AddressList}
   */
  addAddress(address) {
    this.list = [address, ...this.list]

    return this
  }

  /**
   * @param {number} index
   *
   * @returns {?Address}
   */
  getAddress(index) {
    return this.list[index]
  }

  /**
   * @param {number} index
   * @param {Address} address
   *
   * @returns {AddressList}
   */
  setAddress(index, address) {
    this.list[index] = address

    return this
  }

  /**
   * @returns {boolean}
   */
  hasAddresses() {
    return Boolean(this.list.length)
  }

  /**
   * @returns {[?Address, ?number]}
   */
  getCurrentAddress() {
    const currentAddressIndex = this.list.findIndex((address) => address.is_current)
    const currentAddress = this.list[currentAddressIndex]

    if (currentAddressIndex < 0) {
      return [null, null]
    }

    return [currentAddress, currentAddressIndex]
  }

  /**
   * @returns {boolean}
   */
  isCurrentAddressFilled() {
    return this.hasAddresses() ? this.getCurrentAddress()[0]?.isFilled() : false
  }
}
