import React from 'react'

import { useMediaQueries } from 'hooks'

import type { DirectDebitSubscription, RecurringPaymentSubscription } from 'app/effector/recurringPayments'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { SubscriptionCard } from './SubscriptionCard'

type SubscriptionRaw = DirectDebitSubscription | RecurringPaymentSubscription

type Subscription = SubscriptionRaw & {
  type: 'DIRECT_DEBIT' | 'OPEN_BANKING'
  manageType: string
  regulatoryType: string
}

type SubscriptionsGroupProps = {
  title: string
  subscriptions: Subscription[]
  onCardClick: (id: number, type: 'DIRECT_DEBIT' | 'OPEN_BANKING') => void
  'data-test-id'?: string
}

const SubscriptionsGroup = ({
  title,
  subscriptions,
  onCardClick: handleSubscriptionClick,
  'data-test-id': dataTestId,
}: SubscriptionsGroupProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Paper top={40} data-test-id={dataTestId}>
      <Typography size={desktop ? 18 : 16} lineHeight="small" weight="semibold">
        <Typo>{title}</Typo>
      </Typography>
      {subscriptions.map((subscription) => (
        <Paper key={subscription.id} top={desktop ? 24 : 16}>
          <SubscriptionCard
            data-test-id={`${subscription.manageType.toLowerCase()}SubscriptionCard`}
            subscription={subscription}
            onClick={handleSubscriptionClick}
          />
        </Paper>
      ))}
    </Paper>
  )
}

export { SubscriptionsGroup }
