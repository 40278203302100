import React, { Fragment } from 'react'
import { useDropzone } from 'react-dropzone'

import classNames from 'classnames/dedupe'

import { useMediaQueries, useRef } from 'hooks'

import { DocName, type Document } from 'app/effector/secure-document-upload/types'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import { Preloader } from 'components/molecules/Preloader'

import { SourceOfWealthSelect } from '../SourceOfWealthSelect'

import './DocumentUploadModal.css'

type DocumentUploadModalProps = {
  isVisible: boolean
  onClose: () => void
  document: Document
  isError?: boolean
  isLoading?: boolean
  uploadedFiles?: string[]
  onFilesSelected: (files: File[]) => void
  onClearFiles: (str: DocName) => void
}

const DocumentUploadModal = ({
  isVisible,
  document,
  isError = false,
  isLoading = false,
  uploadedFiles = [],
  onClose,
  onFilesSelected,
  onClearFiles,
}: DocumentUploadModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const inputFileRef = useRef(null)

  const hasUploadedFiles = !!uploadedFiles.length

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*', 'application/pdf'],
    multiple: true,
    onDrop: onFilesSelected,
  })

  const iconContainerClasses = classNames('DocumentUploadModal_IconContainer', {
    'DocumentUploadModal_IconContainer-success': hasUploadedFiles,
  })

  const uploadBoxClasses = classNames('DocumentUploadModal_UploadBox', {
    'DocumentUploadModal_UploadBox-error': isError,
  })

  const headingNode = (
    <Fragment>
      <Paper bottom={32}>
        <Typography size={desktop ? 32 : 24} weight="semibold" align="center" lineHeight="small">
          <Typo>{document.title}</Typo>
        </Typography>
      </Paper>
      <Typography size={14}>
        <Typo>{document.subtitle}</Typo>
      </Typography>
      {/* additional components for SoW. Refactor -> to abstraction in case changes in this direction happen */}
      {document.type === DocName.SOURCE_OF_WEALTH && <SourceOfWealthSelect />}
    </Fragment>
  )

  const uploadImageNode = (
    <Paper
      className={desktop ? uploadBoxClasses : ''}
      top={desktop ? 24 : 0}
      bottom={desktop ? 24 : 0}
      right={desktop ? 24 : 0}
      left={desktop ? 24 : 0}
    >
      <div {...getRootProps()}>
        <AllCenter>
          <Paper top={16} bottom={16}>
            <AllCenter>
              <Paper bottom={16}>
                <div className={iconContainerClasses}>
                  <Icon type={document.icon} />
                  {hasUploadedFiles && (
                    <Icon className="DocumentUploadModal_IconContainer_SuccessIcon" type="success-48" />
                  )}
                </div>
              </Paper>
              {desktop && (
                <Typography size={14} align="center" lineHeight="small" color="minor">
                  <Typo>
                    Drag and drop file here
                    <br />
                    or press ‘Upload’ below
                  </Typo>
                </Typography>
              )}
            </AllCenter>
          </Paper>

          <Button
            mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}
            onClick={() => inputFileRef.current?.click()}
          >
            Upload
          </Button>
          <input data-test-id="fileInput" ref={inputFileRef} {...getInputProps()} />
        </AllCenter>
      </div>
    </Paper>
  )

  const uploadedFilesNode = hasUploadedFiles && (
    <Paper bottom={48}>
      {uploadedFiles.map((fileName, index) => (
        <Typography color="additional" key={index} overflow="ellipsis" lineHeight="small" data-test-id="uploaded-file">
          <Typo>{fileName}</Typo>
        </Typography>
      ))}
      <Paper top={8}>
        <Link onClick={onClearFiles}>Clear documents</Link>
      </Paper>
    </Paper>
  )

  const errorTextNode = (
    <Typography color="error" align="center">
      <Typo>An error occurred during the upload. Please try again.</Typo>
    </Typography>
  )

  const buttonNode = (
    <Button mods={{ size: 'big block' }} onClick={onClose} disabled={!hasUploadedFiles}>
      Continue
    </Button>
  )

  if (desktop) {
    return (
      <Modal open={isVisible} close={<Typography color="inherit">Close</Typography>} onClose={onClose}>
        <Paper top={80} bottom={80}>
          <Width size={36}>
            <Width size={27} center>
              {headingNode}
              <Paper top={48} bottom={48}>
                {uploadImageNode}
                {isError && <Paper top={16}>{errorTextNode}</Paper>}
              </Paper>
              {uploadedFilesNode}
              {buttonNode}
            </Width>
            <Width size={27} center></Width>
          </Width>
        </Paper>
        <Preloader loading={isLoading} size="default" absolute />
      </Modal>
    )
  }

  return (
    <Modal open={isVisible} close={null} onClose={onClose}>
      <MobileLayout
        header={<NavigationBar rightPartText="Close" onRightPartClick={onClose} />}
        content={
          <Inner>
            <AllCenter>
              {headingNode}
              <Paper top={32} bottom={32}>
                {uploadImageNode}
                {isError && <Paper top={32}>{errorTextNode}</Paper>}
              </Paper>
            </AllCenter>
            {uploadedFilesNode}
          </Inner>
        }
        footer={<MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
      />
      <Preloader loading={isLoading} size="default" absolute zIndex={true} />
    </Modal>
  )
}

export { DocumentUploadModal }
