import React from 'react'
import PropTypes from 'prop-types'

import { goTo } from 'helpers/router.js'
import { useMediaQueries, useRedirect, useSelector } from 'hooks'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'
import { Paper } from 'components/atoms/Paper'

import { Captcha as CaptchaForm } from 'components/organisms/Captcha/Captcha'

const Captcha = ({ location }) => {
  const { query } = location
  const client = useSelector(({ client }) => client)
  const { loginIdentifier, backLink } = query
  const { client_id } = client

  useRedirect({
    to: 'dashboard',
    rule: Number(client?.years_old) > 18 && Boolean(client?.annual_income_id),
    isLoading: client?.didInvalidate,
  })

  const { desktop } = useMediaQueries()
  const { token } = location.query

  const handleBack = () => {
    if (backLink) {
      goTo(backLink)
    }
  }

  const formCard = (
    <CaptchaForm token={token} onSuccess={handleBack} loginIdentifier={loginIdentifier} clientId={client_id} />
  )

  return desktop ? (
    <Desktop title="Security check">
      <Paper bottom={24}>{formCard}</Paper>
    </Desktop>
  ) : (
    <Mobile title="Security check" backUrl="/">
      {formCard}
    </Mobile>
  )
}

Captcha.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      token: PropTypes.string,
      loginIdentifier: PropTypes.string,
      backLink: PropTypes.string,
    }),
  }),
}

export { Captcha }
