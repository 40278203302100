import React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMediaQueries } from 'hooks'

import Tabs, { Tab } from 'components/molecules/Tabs'

import './ChartSelectTabs.css'

type ChartSelectTabsProps = {
  activeChart: 'return' | 'dividends'
  setActiveChart: (chartName: 'return' | 'dividends') => void
}

const ChartSelectTabs = ({ activeChart, setActiveChart }: ChartSelectTabsProps): React.ReactElement => {
  const mediaQueries = useMediaQueries()

  const classes = classNames(
    'PortfolioChartSelect-Tabs',
    getMediaQuieryClasses('PortfolioChartSelect-Tabs', mediaQueries),
  )

  return (
    <div className="PortfolioChartSelect-TabsLine">
      <Tabs className={classes}>
        <Tab
          className="PortfolioChartSelect-Tab"
          active={activeChart === 'return'}
          onClick={() => {
            setActiveChart('return')
          }}
          data-test-id="returnTab"
        >
          Investment return
        </Tab>
        <Tab
          className="PortfolioChartSelect-Tab"
          active={activeChart === 'dividends'}
          onClick={() => {
            setActiveChart('dividends')
          }}
          data-test-id="dividendsTab"
        >
          Dividends
        </Tab>
      </Tabs>
      <div className="PortfolioChartSelect-TabsLineBorder" />
    </div>
  )
}

export { ChartSelectTabs }
