import React from 'react'
import { withRouter } from 'react-router'

import { useUnit } from 'effector-react'

import { useMediaQueries } from 'hooks'

import { $bankAccountsStore } from 'app/effector/bank-accounts'

import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import { SavingsPlanFrequencyBadges } from 'components/organisms/SavingsPlanPromo/SavingsPlanFrequencyBadges'

import lumpSumImage from './lumpSum.png'
import savingsPlanImage from './savingsPlan.png'

import './QuickStartModal.css'

type QuickStartModalProps = {
  isOpen: boolean
  onSavingsPlanClick: () => void
  onLumpSumClick: () => void
  onClose: () => void
}

const QuickStartModal = withRouter(
  ({ isOpen, onSavingsPlanClick, onLumpSumClick, onClose }: QuickStartModalProps): React.ReactElement | null => {
    const { desktop } = useMediaQueries()

    const { bankAccounts, nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)
    const hasBankAccounts = !!bankAccounts.length
    const isFrequencyAvailable = hasBankAccounts ? nominatedBankAccount?.bank?.recurring_payment_support : true

    const headerNode = (
      <Paper top={desktop ? 20 : 0} bottom={desktop ? 20 : 0} left={desktop ? 24 : 0} right={desktop ? 24 : 0}>
        <NavigationBar rightPartText="Cancel" onRightPartClick={onClose} plain>
          &nbsp;
        </NavigationBar>
      </Paper>
    )

    const savingsPlanCardNode = (
      <SelectableCard className="QuickStartModal_SavingsPlanCard" onClick={onSavingsPlanClick}>
        <Paper top={12} bottom={desktop ? 24 : 32} left={32} right={8}>
          <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
            <Column size={1}>
              <Typography
                size={32}
                weight="semibold"
                lineHeight="small"
                color="white"
                data-test-id="startWithSavingsPlanCardTitle"
              >
                <Typo>Start with Savings Plan</Typo>
              </Typography>
            </Column>
            <Column size={0}>
              <img src={savingsPlanImage} width={132} height={132} />
            </Column>
          </ColumnarLayout>
          <Paper top={4}>
            <Typography size={14} color="white" data-test-id="startWithSavingsPlanCardSubTitle">
              <Typo>Recurring contributions into your portfolio</Typo>
            </Typography>
          </Paper>
          {isFrequencyAvailable && (
            <Paper top={20}>
              <SavingsPlanFrequencyBadges fontSize={14} />
            </Paper>
          )}
        </Paper>
      </SelectableCard>
    )

    const lumpSumCardNode = (
      <Card className="QuickStartModal_LumpSumCard" onClick={onLumpSumClick}>
        <Paper top={desktop ? 12 : 24} bottom={desktop ? 24 : 32} left={32} right={8}>
          <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
            <Column size={1}>
              <Typography
                size={24}
                weight="semibold"
                lineHeight="small"
                color="accent"
                data-test-id="addLumpSumCardTitle"
              >
                <Typo>
                  <Nobr>Add a lump sum</Nobr>
                </Typo>
              </Typography>
              <Paper top={16}>
                <Typography size={14} color="accent" data-test-id="addLumpSumCardSubTitle">
                  <Typo>Invest a one-off lump sum instantly from your bank account or make a manual payment</Typo>
                </Typography>
              </Paper>
            </Column>
            <Column size={0}>
              <img src={lumpSumImage} width={132} height={132} />
            </Column>
          </ColumnarLayout>
        </Paper>
      </Card>
    )

    const cardsNode = (
      <Paper top={desktop ? 80 : 64} bottom={desktop ? 120 : 80}>
        {savingsPlanCardNode}
        <Paper top={20} bottom={20}>
          <Typography align="center" color="accent">
            <Typo>OR</Typo>
          </Typography>
        </Paper>
        {lumpSumCardNode}
      </Paper>
    )

    const content = desktop ? (
      <ModalContent width={36} style={{ minHeight: 600 }}>
        {headerNode}
        <Width size={24} center>
          {cardsNode}
        </Width>
      </ModalContent>
    ) : (
      <MobileLayout header={headerNode} content={cardsNode} />
    )

    return (
      <Modal open={isOpen} close={null} onClose={onClose}>
        {content}
      </Modal>
    )
  },
)

export { QuickStartModal }
