import { useCallback, useState } from 'hooks'

import type { FilterValue } from 'components/organisms/Filter'

function normalize(rawValue: string | string[]): Array<string | number> {
  if (!rawValue) {
    return []
  }
  if (Array.isArray(rawValue)) {
    return rawValue
  }
  const value = Number(rawValue)

  return isNaN(value) ? [rawValue] : [value]
}

type FilterProps = {
  name: string
  type: 'checkbox' | 'radio'
  values?: FilterValue[]
  selected: string[] | number[]
  onChange: (nextValue: Array<string | number | null>) => void
}

type Filters = {
  ordering: FilterProps
  asset_class: FilterProps
  provider: FilterProps
  dividends_type: FilterProps
  hedged: FilterProps
  esg: FilterProps
}

type FiltersState = {
  asset_class: string[]
  provider: string[]
  dividends_type: string[]
  hedged: number[]
  esg: number[]
  ordering: string[]
}

type UseFiltersInterface = {
  filters: Filters
  filtersState: FiltersState
  handleFiltersClear: () => void
}

const useFilters = (query): UseFiltersInterface => {
  const initialFiltersState = useCallback(
    () => ({
      asset_class: normalize(query?.asset_class),
      provider: normalize(query?.provider),
      dividends_type: normalize(query?.dividends_type),
      hedged: normalize(query?.hedged),
      esg: normalize(query?.esg),
      ordering: normalize(query?.ordering),
    }),
    [query?.asset_class, query?.dividends_type, query?.esg, query?.hedged, query?.ordering, query?.provider],
  )

  const [filtersState, setFiltersState] = useState<FiltersState>(initialFiltersState)

  const handleFiltersClear = useCallback(() => {
    setFiltersState({
      asset_class: normalize([]),
      provider: normalize([]),
      dividends_type: normalize([]),
      hedged: normalize([]),
      esg: normalize([]),
      ordering: normalize([]),
    })
  }, [setFiltersState])

  const handleFilterChange = useCallback(
    (name) => (value) => {
      setFiltersState((state) => ({ ...state, [name]: value }))
    },
    [],
  )

  const filters: Filters = {
    ordering: {
      name: 'Sort',
      type: 'radio',
      selected: filtersState.ordering,
      onChange: handleFilterChange('ordering'),
    },
    asset_class: {
      name: 'Asset class',
      type: 'checkbox',
      values: [
        {
          name: 'Equities',
          value: 'STOCK',
        },
        {
          name: 'Bonds',
          value: 'BOND',
        },
        {
          name: 'Alternatives',
          value: 'ALTERNATIVE',
        },
      ],
      selected: filtersState.asset_class,
      onChange: handleFilterChange('asset_class'),
    },
    provider: {
      name: 'Provider',
      type: 'radio',
      selected: filtersState.provider,
      onChange: handleFilterChange('provider'),
    },
    dividends_type: {
      name: 'Acc / Dist',
      type: 'radio',
      selected: filtersState.dividends_type,
      onChange: handleFilterChange('dividends_type'),
    },
    hedged: {
      name: '£ Hedged',
      type: 'radio',
      selected: filtersState.hedged,
      onChange: handleFilterChange('hedged'),
    },
    esg: {
      name: 'ESG',
      type: 'radio',
      selected: filtersState.esg,
      onChange: handleFilterChange('esg'),
    },
  }

  return {
    filters,
    filtersState,
    handleFiltersClear,
  }
}

export { useFilters, type Filters, type FiltersState }
