import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Width from 'components/_old/Width/Width'
import Inner from 'components/_old/Inner/Inner.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Segment from 'components/atoms/Segment/Segment.jsx'
import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { NavigationBar } from 'components/atoms/NavigationBar'
import icon from 'components/molecules/Preloader/Preloader.svg'

import { BankAccountStateContent } from 'app/pages/Dashboard/Goals/components/BankAccount/BankAccountStateContent.jsx'

const Loading = () => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      {!desktop && <NavigationBar>Loading</NavigationBar>}
      <AllCenter>
        <Width size={60}>
          <Width size={40} center>
            <Inner>
              <Card mods={{ theme: 'transparent', padding: 'biggest', 'no-padding': 'right left' }}>
                <Segment mods={{ margin: 'hefty', noMargin: desktop ? 'bottom' : 'all' }}>
                  <Width size={desktop ? 6 : 4} center>
                    <SvgIcon className="Icon_animation_spin" src={icon} />
                  </Width>
                </Segment>
                <BankAccountStateContent
                  title="Please wait"
                  description="We're working on setting up your standing order."
                  data-test-id="standingOrderLoading"
                />
              </Card>
            </Inner>
          </Width>
        </Width>
      </AllCenter>
    </Fragment>
  )
}

export { Loading }
