import React, { Fragment } from 'react'
import classNames from 'classnames/dedupe'
import PropTypes from 'prop-types'

import { Gateway } from 'components/atoms/Gateway'

import './Sticked.css'

const Sticked = ({
  children,
  targetGatewayName = 'insideNewModal',
  className,
  into = 'afterLayout',
  woGateway,
  phantomButtonsMarginSize,
  newButton,
}) => {
  const inner = (
    <div
      className={classNames('Sticked', {
        [`Sticked_${into}`]: into,
        Sticked_woGateway: woGateway,
        Sticked_newButton: newButton,
      })}
    >
      <div className={classNames(className, 'Sticked-Holder')}>{children}</div>
    </div>
  )

  const phantomButtonsClasses = classNames('Sticked-PhantomButtons', {
    [`Sticked-PhantomButtons_marginSize_${phantomButtonsMarginSize}`]: phantomButtonsMarginSize,
  })

  if (woGateway) {
    return inner
  }

  if (into === 'insideNewModal') {
    return (
      <Fragment>
        <Gateway into={targetGatewayName}>{inner}</Gateway>
        <div className={phantomButtonsClasses}>{children}</div>
      </Fragment>
    )
  }

  return <Gateway into={into}>{inner}</Gateway>
}

const propTypes = (Sticked.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  targetGatewayName: PropTypes.string,
  into: PropTypes.oneOf(['beforeLayout', 'afterLayout', 'insideModal', 'insideNewModal']),
  phantomButtonsMarginSize: PropTypes.oneOf([null, undefined, 'zero', 'small']),
  woGateway: PropTypes.bool,
})

export { propTypes }
export default Sticked
