import React from 'react'

import { useState, useCallback } from 'hooks'

const RetakeQuestionnaireTunnelContext = React.createContext({})

const RetakeQuestionnaireProvider = ({ children }) => {
  const [answers, setAnswers] = useState({})

  const saveAnswers = useCallback(
    (code, answerIds) => {
      const newAnswers = {
        ...answers,
        [code]: answerIds,
      }
      setAnswers(newAnswers)
    },
    [answers, setAnswers],
  )

  const resetAnswers = useCallback(() => setAnswers({}), [setAnswers])

  return (
    <RetakeQuestionnaireTunnelContext.Provider value={{ answers, saveAnswers, resetAnswers }}>
      {children}
    </RetakeQuestionnaireTunnelContext.Provider>
  )
}

export { RetakeQuestionnaireTunnelContext, RetakeQuestionnaireProvider }
