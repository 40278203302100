import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { bulkValidate } from 'helpers/validation.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

import { RegulatoryTypeModal } from 'app/pages/Dashboard/Goals/DIY/EditWeights/RegulatoryTypeModal'

import { useLumpSumSetup, steps } from './useLumpSumSetupModal'

import './LumpSumSetup.css'

type LumpSumSetupProps = {
  isOpen: boolean
  needToChooseRegulatoryType: boolean
  analyticsData: Record<string, undefined>

  onClose: () => void
  onBack: () => void
  onContinue: (data: { amount: string | null; regulatoryType?: string }) => Promise<void>
}

const LumpSumSetupModal = ({
  isOpen,
  needToChooseRegulatoryType,
  analyticsData,
  onClose: handleClose,
  onBack: handleBack,
  onContinue: handleContinue,
}: LumpSumSetupProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { step, amount, validation, handleInputAmount, handleAmountContinue, handleSubmitRegulatoryType } =
    useLumpSumSetup({
      needToChooseRegulatoryType,
      isOpen,
      analyticsData,
      handleContinue,
    })

  const headerNode = (
    <NavigationBar
      leftPartText="Back"
      onLeftPartClick={handleBack}
      rightPartText="Cancel"
      onRightPartClick={handleClose}
      plain
    >
      <Typography size={desktop ? 20 : 16} lineHeight="small" weight="semibold" align="center">
        <Typo>Add a lump sum</Typo>
      </Typography>
    </NavigationBar>
  )

  const titleNode = (
    <Typography size={36} lineHeight="small" weight="semibold" align="center" data-test-id="lumpSumSetupStepTitle">
      <Typo>Amount</Typo>
    </Typography>
  )

  const amountContinueButton = (
    <Button
      type="submit"
      onClick={handleAmountContinue}
      mods={{ size: 'big block' }}
      disabled={!bulkValidate(validation)}
      data-test-id="continueButton"
    >
      Continue
    </Button>
  )

  const amountInputNode = (
    <Paper top={56} bottom={56}>
      <Width size={15} center>
        <Validate rules={validation.amount.rules}>
          {(isValid, brokenRule) => {
            return (
              <Label
                postfield={
                  !isValid && (
                    <Paper top={4}>
                      <Typography size={12} lineHeight="small" color="error">
                        <Typo>{validation.amount.errors[brokenRule]}</Typo>
                      </Typography>
                    </Paper>
                  )
                }
              >
                <Input
                  className="LumpSumSetup_Input"
                  type="money"
                  mods={{ size: 'big', color: 'blue', text: 'bold' }}
                  onChange={handleInputAmount}
                  withFloat
                  data-test-id="amountInput"
                >
                  {amount}
                </Input>
              </Label>
            )
          }}
        </Validate>
      </Width>
      {desktop && (
        <Paper top={80}>
          <Width size={21} center>
            {amountContinueButton}
          </Width>
        </Paper>
      )}
    </Paper>
  )

  if (step === steps.REGULATORY_TYPE) {
    return <RegulatoryTypeModal open onSubmit={handleSubmitRegulatoryType} onClose={handleClose} zIndex={999} />
  }

  if (desktop) {
    return (
      <Modal open={isOpen} close={null} onClose={handleClose}>
        <Width size={36} style={{ minHeight: 600 }}>
          <Paper top={20} right={24} bottom={20} left={24}>
            {headerNode}
          </Paper>
          <Paper top={48}>
            {titleNode}
            {amountInputNode}
          </Paper>
        </Width>
      </Modal>
    )
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <MobileLayout
        header={headerNode}
        content={
          <Fragment>
            <Paper top={8}>{titleNode}</Paper>
            <AllCenter>{amountInputNode}</AllCenter>
          </Fragment>
        }
        footer={<MobileLayoutFooterButton>{amountContinueButton}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
}

export { LumpSumSetupModal }
