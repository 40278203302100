import React, { Fragment } from 'react'
import Glider from 'react-glider'

import classNames from 'classnames/dedupe'
import isFunction from 'lodash/isFunction'

import { useRef, useMediaQueries, useCallback, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { palette } from 'helpers/palette'

import { type StoryList, type Story as StoryType } from 'app/effector/stories'

import Icon from 'components/_old/Icon/Icon.jsx'

import { Story } from './components/Story'
import { StoryThumbnail } from './components/StoryThumbnail'
import { useStories, isSkeleton } from './hooks/useStories'

import './Stories.css'
import 'glider-js/glider.min.css'

type StoriesProps = {
  stories: StoryList
  isLoading: boolean
  customActions?: Record<string, ({ id, index }) => void>
}

const Stories = ({ stories = [], isLoading, customActions }: StoriesProps): React.ReactElement | null => {
  const { desktop } = useMediaQueries()

  const {
    skeleton,
    activeStory,
    startStory,
    closeStory,
    currentStepIndex,
    handleNext,
    handlePrevious,
    showNextButton,
    showPreviousButton,
  } = useStories({
    stories,
    isLoading,
  })

  const leftArrowEl = useRef(null)
  const rightArrowEl = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const classes = classNames('Stories', { Stories_mobile: !desktop, Stories_loaded: loaded })
  const maxStoriesShown = 5
  const commonGliderProps = {
    draggable: !desktop,
    slidesToShow: desktop ? maxStoriesShown : ('auto' as const),
    slidesToScroll: 'auto' as const,
    itemWidth: window.innerWidth / 3.6,
    exactWidth: !desktop,
    arrows: {
      prev: leftArrowEl.current,
      next: rightArrowEl.current,
    },
    duration: 1,
  }

  const handleLoad = useCallback(() => {
    // after a bit of delay, wait fot glider to initialize
    setTimeout(() => {
      setLoaded(true)
    }, 50)
  }, [setLoaded])

  const handlePreloadStepImage = useCallback(
    (story: StoryType) => () => {
      const image = story.steps[0]?.backgroundImage ?? story.steps[0]?.image

      if (image && isFunction(window.fetch)) {
        const img = new Image()
        img.src = image
      }
    },
    [],
  )

  const arrowsNode =
    desktop && stories.length > maxStoriesShown ? (
      <Fragment>
        <button className="Stories-PrevButton" ref={leftArrowEl} data-test-id="story-arrow-previous">
          <Icon
            type="prev-arrow"
            color={palette['content-on-background-primary']}
            size={11}
            inline
            className="Stories-PrevArrow"
          />
        </button>
        <button className="Stories-NextButton" ref={rightArrowEl} data-test-id="story-arrow-next">
          <Icon
            type="next-arrow"
            color={palette['content-on-background-primary']}
            size={11}
            inline
            className="Stories-NextArrow"
          />
        </button>
      </Fragment>
    ) : null

  if (skeleton) {
    return (
      <div className={classes}>
        <Glider {...commonGliderProps}>
          {new Array(5).fill({ skeleton: true }).map((skeleton, index) => (
            <StoryThumbnail key={index} story={skeleton} data-test-id={`story-preview-${index + 1}`} />
          ))}
        </Glider>
      </div>
    )
  }

  return (
    <div className={classes}>
      {/*
        `key` is needed here since there is strange heisenbug of a Glider when more that 5 stories
        the real stories. So, key forces Glider to rerender, that fixes the bug altogether.
      */}
      <Glider
        key={stories.map((story) => story.id).join(',')}
        {...commonGliderProps}
        hasArrows={!!arrowsNode}
        onLoad={handleLoad}
      >
        {stories.map((story, index) => (
          <StoryThumbnail
            key={index}
            story={story}
            onClick={() => {
              if (!isSkeleton(story)) {
                startStory(story)
                trackEvent({
                  action: 'story_opened',
                  story_id: Number(story?.id),
                })
              }
            }}
            onHover={handlePreloadStepImage(story)}
            data-test-id={`story-preview-${index + 1}`}
          />
        ))}
      </Glider>
      {arrowsNode}
      <Story
        key={activeStory?.id}
        id={activeStory?.id}
        isOpen={!!activeStory}
        steps={activeStory?.steps ?? []}
        currentStep={currentStepIndex}
        customActions={customActions}
        showNextButton={showNextButton}
        showPreviousButton={showPreviousButton}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        handleClose={closeStory}
      />
    </div>
  )
}

export { Stories }
