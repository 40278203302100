import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { Preloader } from 'components/molecules/Preloader'

import { Desktop } from './Desktop'
import { useClaimBonus } from './hooks'
import { Mobile } from './Mobile'

function ClaimBonus(): React.ReactElement {
  const { desktop } = useMediaQueries()
  const claimBonusInterface = useClaimBonus()
  const { isLoading } = claimBonusInterface

  return (
    <Fragment>
      {desktop ? <Desktop {...claimBonusInterface} /> : <Mobile {...claimBonusInterface} />}

      <Preloader loading={isLoading} size="big" zIndex />
      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

export { ClaimBonus }
