import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { propTypes } from 'helpers/propTypes'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

import { PendingOrderList } from 'app/effector/pending-orders/models'
import { SecurityList } from 'app/effector/securities/models'

const SecurityOrdersPage = ({
  orders,
  securities,
  canBeCanceled,
  isTradingWindow,
  extraNodes,
  onBack,
  onCancel,
  onCancelOrders,
  onContactUs,
}) => {
  const { desktop } = useMediaQueries()
  const isRebalance = orders.getByType('REBALANCE').length > 0

  const props = {
    orders,
    isRebalance,
    canBeCanceled,
    isTradingWindow,
    securities,
    onBack,
    onCancel,
    onCancelOrders,
    onContactUs,
  }

  const page = desktop ? <Desktop {...props} /> : <Mobile {...props} />

  return (
    <Fragment>
      {page}
      {extraNodes}
    </Fragment>
  )
}

SecurityOrdersPage.propTypes = {
  orders: propTypes.instanceOf(PendingOrderList),
  securities: propTypes.instanceOf(SecurityList),
  canBeCanceled: PropTypes.bool,
  isTradingWindow: PropTypes.bool,
  extraNodes: PropTypes.node,
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  onCancelOrders: PropTypes.func,
  onContactUs: PropTypes.func,
}

export { SecurityOrdersPage }
