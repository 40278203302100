import { combine, createStore } from 'effector'

import { $sippTransferRequest } from 'app/effector/nominees-transfers/request/sipp'

import { setAgreed, resetForm, $displayedProviders } from '../../index'

// Stores
const $agreed = createStore<boolean>(false)
$agreed.on(setAgreed, (_, payload) => payload)
$agreed.reset(resetForm)

const $form = combine($sippTransferRequest, $agreed, (request, agreed) => ({ ...request, agreed }))

const $validation = combine([$displayedProviders, $form]).map(([providers, form]) => {
  const provider = providers.find((provider) => provider.id === form.provider)

  return {
    provider: {
      rules: [!!form.provider],
      errors: ['Please select a provider'],
    },
    plan_number: {
      rules: [(form.plan_number ?? '').length > 0, provider?.validate(form.plan_number ?? '') ?? true],
      errors: ['Pension reference number cannot be empty', provider?.error_message ?? ''],
    },
    amount: {
      rules: [parseFloat(form.amount ?? '') > 0, parseFloat(form.amount ?? '') < 100000000],
      errors: ['Amount cannot be empty', 'Amount should be less that £100,000,000'],
    },
    asset_transfer_type: {
      rules: [!!form.asset_transfer_type],
      errors: ['Please select a transfer option above'],
    },
    agreed: {
      rules: [form.agreed],
      errors: ['You must agree with terms and conditions'],
    },
  }
})

export { $form, $validation }
