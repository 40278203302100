import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries } from 'hooks'

import { SignatureType } from 'app/effector/isa-transfer/models'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import {
  DesktopLayout,
  MobileLayout,
  MobileLayoutFooterButton,
  MobileLayoutFullViewport,
} from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter, MobileFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { $signatureType, continueAfterSignature } from '../model'

const wetSignatureText =
  'Your selected previous provider requires a physical signed form to initiate the ISA transfer. Please follow the link below to print, sign and scan your form.'
const digitalSignatureText =
  'Your selected previous provider requires an electronic signature to initiate the ISA transfer. Please follow the link below to electronically sign your transfer form.'

const texts = {
  [SignatureType.WET]: wetSignatureText,
  [SignatureType.WET_PARTIAL]: wetSignatureText,
  [SignatureType.DIGITAL]: digitalSignatureText,
  [SignatureType.DIGITAL_PARTIAL]: digitalSignatureText,
}

export const Signature: React.FC = () => {
  const { desktop } = useMediaQueries()
  const signatureType = useUnit($signatureType)
  const handleContinue = useUnit(continueAfterSignature)

  const text = texts[signatureType as SignatureType]

  const signatureContent = (
    <Fragment>
      <Stack vertical={24}>
        <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
          <Typo>Final Step Required</Typo>
        </Typography>
        <Typography size={16} align="center">
          <Typo>{text}</Typo>
        </Typography>
      </Stack>
    </Fragment>
  )

  const desktopLayout = (
    <DesktopLayout
      header={<DesktopHeader />}
      content={
        <Inner flex>
          <AllCenter>
            <Width size={desktop ? 30 : null} center>
              {signatureContent}
            </Width>

            <Paper top={32}>
              <Width size={24} center>
                <Button onClick={handleContinue} mods={{ size: 'big block' }}>
                  Go to final step
                </Button>
              </Width>
            </Paper>
          </AllCenter>
        </Inner>
      }
      footer={<DesktopFooter />}
      noGaps
    />
  )

  const mobileLayout = (
    <MobileLayout
      defaultHeaderHeight={0}
      contentPaperSizes={{ top: 0, left: 0, right: 0 }}
      content={
        <Fragment>
          <MobileLayoutFullViewport>
            <Inner flex>
              <AllCenter>
                <Width size={null} center>
                  {signatureContent}
                </Width>
              </AllCenter>
            </Inner>
          </MobileLayoutFullViewport>
          <Inner>
            <MobileFooter noMarginTop />
          </Inner>
        </Fragment>
      }
      footer={
        <MobileLayoutFooterButton>
          <Button onClick={handleContinue}>Go to final step</Button>
        </MobileLayoutFooterButton>
      }
    />
  )

  return desktop ? desktopLayout : mobileLayout
}
