import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typo } from 'components/_old/Typo/Typo'
import { AddSecurities } from 'components/molecules/AddSecurities'
import { Typography } from 'components/atoms/Typography'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Icon from 'components/_old/Icon/Icon.jsx'

import { EditableSecurityList } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/EditableSecurityList'
import { Submit } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/Submit'
import { WeightsChart } from '../components/WeightsChart'

const Mobile = ({
  infoText,
  securities,
  total,
  control,
  handleAddSecurities,
  handleBack,
  handleCancel,
  handleDelete,
  currentValues,
  highlightedRef,
}) => {
  const header = useMemo(
    () => (
      <NavigationBar
        leftPartText="Back"
        onLeftPartClick={handleBack}
        rightPartText="Cancel"
        onRightPartClick={handleCancel}
      >
        Set portfolio weights
      </NavigationBar>
    ),
    [handleBack, handleCancel],
  )

  const content = useMemo(
    () => (
      <Fragment>
        <ItemWithIcon
          space={12}
          icon={<Icon type="information-24" size={24} color="inherit" />}
          content={
            <Typography size={12}>
              <Typo>{infoText}</Typo>
            </Typography>
          }
        />
        <Paper top={32}>
          <AddSecurities onClick={handleAddSecurities} />
        </Paper>
        <Paper top={32}>
          <WeightsChart securities={securities} currentValues={currentValues} />
        </Paper>
        <Paper top={32}>
          <EditableSecurityList
            securities={securities}
            control={control}
            handleDelete={handleDelete}
            highlightedRef={highlightedRef}
          />
        </Paper>
      </Fragment>
    ),
    [securities, total, control, handleAddSecurities, handleDelete],
  )

  const footer = useMemo(
    () => (
      <MobileLayoutFooterButton>
        <Submit securities={securities} total={total} />
      </MobileLayoutFooterButton>
    ),
    [total, securities?.length],
  )

  return <MobileLayout header={header} content={content} footer={footer} />
}

Mobile.propTypes = {
  infoText: PropTypes.string,
  securities: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  control: PropTypes.object,
  handleAddSecurities: PropTypes.func,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleDelete: PropTypes.func,
  currentValues: PropTypes.object,
  highlightedRef: PropTypes.object,
}

export { Mobile }
