import { useUnit } from 'effector-react'

import { useActions, useCallback, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'

import { $owner } from 'app/effector/contacts'

import {
  changeField as changeClientFieldActionCreator,
  updateOrCreate as updateOrCreateClientActionCreator,
  resetError as resetClientErrorActionCreator,
} from 'app/redux/actions/client'

import { states as clientStates } from 'constants/client'

const useClient = () => {
  const [changeField, updateOrCreateClient, resetClientError] = useActions([
    changeClientFieldActionCreator,
    updateOrCreateClientActionCreator,
    resetClientErrorActionCreator,
  ])

  const client = useSelector((state) => state.client)
  const owner = useUnit($owner)

  const changeClientField = useCallback((field, value) => changeField({ [field]: value }), [changeField])

  const updateClient = useCallback(
    async (keys) => {
      const nextState = await updateOrCreateClient(keys)

      if (nextState.client.error) {
        resetClientError()
        return { result: false, error: nextState.client.error }
      }
      return { result: true }
    },
    [updateOrCreateClient, resetClientError],
  )

  const completeClient = useCallback(async () => {
    changeField({ completed: true })

    const stateAfterClientUpdate = await updateOrCreateClient(['completed'])

    if (stateAfterClientUpdate.client.error) {
      await resetClientError()
      return {
        result: false,
        error: stateAfterClientUpdate.client.error,
      }
    }

    const clientType = stateAfterClientUpdate.client.type

    trackEvent({ action: 'client_completed', client_type: clientType })

    if (stateAfterClientUpdate.client.state === clientStates.APPROVED) {
      trackEvent({ action: 'client_approved', client_type: clientType })
    }

    return {
      result: true,
      client: stateAfterClientUpdate.client,
      owner,
    }
  }, [updateOrCreateClient, resetClientError, changeField, owner])

  return { changeClientField, updateClient, completeClient, client }
}

export { useClient }
