import React from 'react'
import PropTypes from 'prop-types'

import { useCallback, useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Icon from 'components/_old/Icon/Icon.jsx'
import Width from 'components/_old/Width/Width'
import Button from 'components/_old/Button/Button.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { SelectableCard } from 'components/atoms/SelectableCard'

import { types as clientTypes } from 'constants/client'

const AccountTypeCard = ({
  icon,
  type,
  title = '',
  description = '',
  buttonTitle = 'Continue',
  onClick,
  'data-test-id': dataTestId,
}) => {
  const { desktop } = useMediaQueries()

  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick(type)
    }
  }, [type, onClick])

  const cardIcon = useMemo(
    () => (
      <AllCenter>
        <Icon
          type={icon}
          size={80}
          color={palette['content-on-background-primary']}
          data-test-id={`${dataTestId}-icon`}
        />
      </AllCenter>
    ),
    [icon],
  )

  const cardTitle = useMemo(
    () => (
      <Paper top={desktop ? 40 : 16} bottom={16}>
        <Typography size={desktop ? 24 : 20} lineHeight="small" align="center" data-test-id={`${dataTestId}-title`}>
          <Typo>{title}</Typo>
        </Typography>
      </Paper>
    ),
    [desktop, title],
  )

  const cardDescription = useMemo(
    () => (
      <Typography
        size={desktop ? 14 : 16}
        color={desktop ? null : 'additional'}
        lineHeight="medium"
        align="center"
        data-test-id={`${dataTestId}-desc`}
      >
        <Typo>{description}</Typo>
      </Typography>
    ),
    [desktop, description],
  )

  const cardButton = useMemo(
    () => (
      <Paper top={desktop ? 32 : 24}>
        <Button
          stretch
          mods={{ size: desktop ? 'big block' : 'block', color: 'blue' }}
          data-test-id={`${dataTestId}-button`}
        >
          {buttonTitle}
        </Button>
      </Paper>
    ),
    [desktop, buttonTitle, handleClick],
  )

  return (
    <Width size={desktop ? 16.5 : null}>
      <SelectableCard className="Button-Proxy" onClick={handleClick} data-typechoose-select={type}>
        <Paper top={desktop ? 80 : 24} bottom={desktop ? 40 : 24} right={desktop ? 32 : 24} left={desktop ? 32 : 24}>
          {cardIcon}
          {cardTitle}
          {cardDescription}
          {cardButton}
        </Paper>
      </SelectableCard>
    </Width>
  )
}

AccountTypeCard.propTypes = {
  type: PropTypes.oneOf(Object.keys(clientTypes)),
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func,
  'data-test-id': PropTypes.string,
}

export { AccountTypeCard }
