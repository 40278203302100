import axios from 'helpers/ajax'

import { type ReportFormat } from 'app/effector/reports/models'

import { ApiError } from 'app/redux/models/errors'

type ReportDTO = {
  title: string
  type: string
  type_title: string
  is_generated: boolean
  year: string
  modified: string
  date_from: string
  date_to: string
  url?: string | null
  format: string
}

const getFetchReportsURL = (): string => `reports/`
const fetchReports = async (): Promise<ReportDTO[] | ApiError> => {
  try {
    const { data } = await axios.get(getFetchReportsURL())

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
fetchReports.getUrl = getFetchReportsURL

type ReportRequestDTO = {
  type: string
  portfolio_ids?: number[] | null
  format: ReportFormat
  date_from: string
  date_to: string
}

const createReportRequestURL = 'reports/request/'
const createReportRequest = async (request: ReportRequestDTO): Promise<ReportDTO[] | ApiError> => {
  try {
    const { data } = await axios.post(createReportRequestURL, request)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
createReportRequest.url = createReportRequestURL

type ReportTypeDTO = {
  type: string
  name: string
  is_one_portfolio_allowed: boolean
  formats: string[]
}

const fetchReportTypesURL = 'reports/types/'
const fetchReportTypes = async (): Promise<ReportTypeDTO[] | ApiError> => {
  try {
    const { data } = await axios.get(fetchReportTypesURL)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
fetchReportTypes.url = fetchReportTypesURL

export {
  type ReportDTO,
  fetchReports,
  type ReportRequestDTO,
  createReportRequest,
  type ReportTypeDTO,
  fetchReportTypes,
}
