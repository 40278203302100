import { multiply } from 'color-blend'

/** @typedef {{r: number, g: number, b: number,  a: number}} RGBA */

/**
 * @param {string} hex
 * @return {string}
 */
export function hexToRgb(hex) {
  let localHex = `${hex}`

  // #abc → #aabbcc
  if (localHex.length === 4) {
    localHex = localHex
      .split('')
      .map((hex) => {
        if (hex !== '#') {
          return hex + hex
        }

        return hex
      })
      .join('')
  }

  // #aabbccff → #aabbcc
  if (localHex.length === 9) {
    localHex = localHex.split('').splice(0, 7).join('')
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(localHex)

  if (result?.[1] && result?.[2] && result?.[3]) {
    return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`
  }
}

/**
 * @param {string} rgb
 * @param {number} alpha
 * @return {string}
 */
export function rgbToRgba(rgb, alpha) {
  if (rgb.includes('rgba')) {
    return rgb.replace(/\d+\)/g, `${alpha})`)
  }

  return rgb.replace('rgb', 'rgba').replace(/\)$/, `, ${alpha})`)
}

/**
 * @param {string} rgba
 * @return {RGBA}
 */
export function rgbaToObject(rgba) {
  let [r, g, b, a] = rgba
    .split(',')
    .map((string) => string.replace(/[()\s]|rgba?/g, ''))
    .map((string) => parseFloat(string))

  if (!a) {
    a = 1
  }

  return {
    r,
    g,
    b,
    a,
  }
}

/**
 * @param {RGBA} object
 * @return {string}
 */
export function objectToRgba(object) {
  return `rgba(${object.r}, ${object.g}, ${object.b}, ${object.a})`
}

/**
 * @param {string} rgba1
 * @param {string} rgba2
 * @return {string}
 */
export function multiplyRgba(rgba1, rgba2) {
  const rgbaObject1 = rgbaToObject(rgba1)
  const rgbaObject2 = rgbaToObject(rgba2)
  const resultObject = multiply(rgbaObject1, rgbaObject2)

  return objectToRgba(resultObject)
}
