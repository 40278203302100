import * as cards from './cards.js'

const INDIVIDUAL_TYPE = 'INDIVIDUAL_TYPE'

const PERSONAL_WITH_MANAGE_TYPE = {
  preselectedData: [],
  steps: [[INDIVIDUAL_TYPE, [cards.ISA, cards.GIA], 'Select an account']],
}

export { PERSONAL_WITH_MANAGE_TYPE }
