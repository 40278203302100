import React, { Fragment } from 'react'

import Input from 'components/_old/Input/Input.jsx'
import { Typo } from 'components/_old/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { TransferType } from 'constants/transferIsa'

interface TransferTypeSelectProps {
  value: TransferType | null
  validation: {
    rules: boolean[]
    errors: string[]
  }
  isForceValidationEnabled: boolean
  setValue: (value: TransferType) => void
}

const TransferTypeSelect: React.FC<TransferTypeSelectProps> = ({
  value,
  validation,
  isForceValidationEnabled,
  setValue,
}) => {
  const options = [
    { value: TransferType.FULL, name: 'All of my ISA with this provider' },
    { value: TransferType.PARTIAL, name: 'Part of my ISA with this provider' },
  ]

  return (
    <Fragment>
      <Paper bottom={16}>
        <Typography size={14} lineHeight="small" weight="semibold">
          <Typo>How much of your ISA would you like to transfer?</Typo>
        </Typography>
      </Paper>
      <Validate rules={validation.rules} skipWaitBlur={isForceValidationEnabled}>
        <Input
          data-test-id="transferTypeSelect"
          placeholder="Please select an option"
          type="select"
          options={options}
          onChange={(_, value: TransferType): void => {
            setValue(value)
          }}
          forceUnnative
          clearable={false}
          searchable={false}
        >
          {value}
        </Input>
      </Validate>
    </Fragment>
  )
}

export { TransferTypeSelect }
