import React from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Gateway } from 'components/atoms/Gateway'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { ProgressPoint } from 'components/atoms/ProgressPoint/ProgressPoint'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

import './InvestmentStatusModal.css'

type InvestmentStatusModalProps = {
  steps: string[]
  activeStepIndex: number
  isOpen: boolean
  activeStepContent: React.ReactNode
  handleClose: () => void
}

const InvestmentStatusModal = ({
  steps,
  activeStepIndex,
  isOpen,
  activeStepContent,
  handleClose,
}: InvestmentStatusModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const activeStep = steps[activeStepIndex]

  return (
    <Gateway into="onboarding-modals">
      <Modal open={isOpen} close={null} onClose={handleClose} data-test-id="onboardingStatusModal">
        <Width size={desktop && 36} center={desktop}>
          <OptionsNavigationBar
            leftPartText="Back"
            onLeftPartClick={handleClose}
            rightPartText="Close"
            onRightPartClick={handleClose}
          >
            Investment Status
          </OptionsNavigationBar>
          <Paper top={40} right={16} bottom={96} left={16}>
            <Width size={27} center>
              <Paper top={32} bottom={48}>
                <Typography size={32} weight="semibold" lineHeight="small" align="center">
                  <Typo>Getting started</Typo>
                </Typography>
              </Paper>
              {steps.map((step, index) => {
                const isActive = activeStep === step
                const isCompleted = activeStepIndex > index
                // if all steps are completed
                const isDone = activeStepIndex > steps.length - 1 && index === steps.length - 1

                const icon = isCompleted ? (
                  <Icon type="filled-success-24" size={24} style={{ marginTop: '-1px' }} />
                ) : (
                  <div className="InvestmentStatusModal-DefaultStatusIcon"></div>
                )

                // vertical line between steps
                const pathway = index !== steps.length - 1 && (
                  <div
                    className={classNames('InvestmentStatusModal-Pathway', {
                      'InvestmentStatusModal-Pathway_completed': isCompleted,
                      'InvestmentStatusModal-Pathway_active': isActive,
                    })}
                  />
                )

                const stepContent =
                  isActive || isDone ? (
                    <ProgressPoint
                      title={step}
                      icon={isDone ? 'filled-success-24' : 'subtract-24'}
                      description={activeStepContent}
                    />
                  ) : (
                    <ItemWithIcon
                      icon={<div className="InvestmentStatusModal-StepIcon">{icon}</div>}
                      content={
                        <Typography>
                          <Typo>{step}</Typo>
                        </Typography>
                      }
                      space={8}
                      iconVerticalAlign="center"
                      contentVerticalAlign="center"
                    />
                  )

                return (
                  <Paper
                    key={index}
                    bottom={32}
                    className="InvestmentStatusModal-Step"
                    data-test-id="onboardingModalStep"
                  >
                    {stepContent}
                    {pathway}
                  </Paper>
                )
              })}
            </Width>
          </Paper>
        </Width>
      </Modal>
    </Gateway>
  )
}

export { InvestmentStatusModal }
