import { useUnit } from 'effector-react'

import { useCallback, useEffect } from 'hooks'

import { features } from 'helpers/features'

import {
  $transferProgressCollection,
  fetchTransferProgressesFx,
  dismissTransferProgressPanelFx,
} from 'app/effector/isa-transfer'

const useAltusProgressData = (id: number): any => {
  const store = useUnit($transferProgressCollection)
  const portfolioProgressData = store.get(id)

  useEffect(() => {
    if (features.get('altus-progress-bar') && id) {
      fetchTransferProgressesFx({ portfolioId: id })
    }
  }, [id])

  const handleDismissAltusPanel = useCallback(
    (transferId: number) => {
      dismissTransferProgressPanelFx({ portfolioId: id, transferId })
    },
    [id],
  )

  return { altusProgressData: portfolioProgressData, handleDismissAltusPanel }
}

export { useAltusProgressData }
