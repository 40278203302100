import React from 'react'

import { CashBreakdown } from './CashBreakdown'

const routes = [
  {
    module: 'cash-breakdown',
    path: 'cash-breakdown/',
    component: function Component(props) {
      return <CashBreakdown {...props} />
    },
  },
]

export { routes }
