import React, { Fragment } from 'react'

import { useState, useCallback, useMemo, useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DonutChart } from 'components/atoms/DonutChart'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { VirtualList } from 'components/molecules/VirtualList'

import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'

import { type SharingPortfolioData } from '../../types'

const titles = {
  assets: 'ETFs',
  regions: 'Regions',
  sectors: 'Sectors',
  equities: 'Holdings',
}

interface SharePortfolioAnalyticsProps extends SharingPortfolioData {
  layoutNode: React.ReactNode
  securitiesNode: React.ReactNode
}

const SharePortfolioAnalytics = ({
  sectors,
  regions,
  equities,
  assets,
  equity_types, // eslint-disable-line
  layoutNode,
  securities,
  securitiesNode,
}: SharePortfolioAnalyticsProps): React.ReactElement => {
  const { tinyPhone } = useMediaQueries()

  const analytics = useMemo(
    () => ({ sectors, regions, equities, assets, equity_types }),
    [sectors, regions, equities, assets, equity_types],
  )

  const [activeTab, setActiveTab] = useState('assets')

  const [showFullList, setShowFullList] = useState(false)

  const activeList = useMemo(() => {
    return showFullList ? analytics[activeTab] : analytics[activeTab]?.slice(0, 100)
  }, [analytics, showFullList, activeTab])

  const shouldShowShowAllButton = useMemo(() => {
    return !showFullList && analytics[activeTab].length > 100
  }, [analytics, showFullList, activeTab])

  const tabs = useMemo(
    () => [
      { title: 'ETFs', id: 'assets', isActive: activeTab === 'assets' },
      { title: 'Regions', id: 'regions', isActive: activeTab === 'regions' },
      { title: 'Sectors', id: 'sectors', isActive: activeTab === 'sectors' },
      { title: 'Holdings', id: 'equities', isActive: activeTab === 'equities' },
    ],
    [activeTab],
  )

  const renderItems = useCallback((listElement, items) => {
    const item = items[listElement.index]

    return (
      <Paper bottom={16} top={16}>
        <SecurityConstituent
          key={item.name}
          logo={item.logo}
          name={item.name}
          actual={item.target}
          color={item.color}
        />
      </Paper>
    )
  }, [])

  const renderNoItems = useCallback(() => null, [])

  const itemsList = useMemo(
    () => (
      <Fragment>
        <Typography size={24} weight="semibold">
          Equities {analytics.equity_types.STOCK}%
        </Typography>
        <VirtualList
          scrollableElement={layoutNode}
          items={activeList}
          itemMinHeight={60}
          itemMaxHeight={92}
          renderItem={(element) => renderItems(element, activeList)}
          renderNoItems={renderNoItems}
        />
        {shouldShowShowAllButton && (
          <Paper top={16}>
            <Button
              mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
              onClick={() => {
                setShowFullList(true)
              }}
              data-test-id="showAllButton"
            >
              Show all {analytics[activeTab].length}
            </Button>
          </Paper>
        )}
        <Paper top={48} bottom={48}>
          <Typography size={24} weight="semibold">
            Bonds {analytics.equity_types.BOND}%
          </Typography>
        </Paper>
        <Typography size={24} weight="semibold">
          Alternatives {analytics.equity_types.ALTERNATIVE}%
        </Typography>
      </Fragment>
    ),
    [activeList, layoutNode, renderItems, renderNoItems, shouldShowShowAllButton, activeTab, analytics],
  )

  const summary = useMemo(() => {
    return {
      sectors: analytics.sectors.map((item) => [item.target, item.color]),
      regions: analytics.regions.map((item) => [item.target, item.color]),
      equities: [[analytics.equity_types.STOCK, palette.stocks]],
      assets: analytics.assets.map((item) => [item.target, item.color]),
    }
  }, [analytics])

  const pieChart = useMemo(
    () => (
      <DonutChart lineWidth={8} values={summary[activeTab]} minimalSectorValue={1}>
        <Paper>
          <Typography size={tinyPhone ? 24 : 32} weight="semibold" lineHeight="small" align="center">
            <Typo>{activeTab === 'assets' ? securities.length : analytics[activeTab].length}</Typo>
          </Typography>
          <Typography size={14} lineHeight="small" align="center">
            <Typo>{titles[activeTab]}</Typo>
          </Typography>
        </Paper>
      </DonutChart>
    ),
    [summary, securities, tinyPhone, activeTab, analytics],
  )

  return (
    <Fragment>
      <Paper bottom={48}>
        <Paper bottom={40}>
          <Paper bottom={4}>
            <GreyTabs
              tabs={tabs}
              onChange={(tab) => {
                setActiveTab(tab.id)
              }}
            />
          </Paper>
        </Paper>
        {activeTab !== 'assets' && (
          <Paper bottom={48}>
            <AllCenter>
              <Width size={8.5}>{pieChart}</Width>
            </AllCenter>
          </Paper>
        )}
        {activeTab === 'assets' ? (
          <ColumnarLayout>
            <Column size={1}>
              {activeList.map((item) => (
                <Paper top={8} bottom={8} key={item.name}>
                  <SecurityConstituent name={item.name} actual={item.target} color={item.color} lineChartWidth={5.5} />
                </Paper>
              ))}
            </Column>
            <Column size={0} mods={{ justifyContent: 'center' }}>
              <Width size={tinyPhone ? 5 : 8.5}>{pieChart}</Width>
            </Column>
          </ColumnarLayout>
        ) : (
          <Fragment>{itemsList}</Fragment>
        )}
      </Paper>
      {activeTab === 'assets' && securitiesNode}
    </Fragment>
  )
}

export { SharePortfolioAnalytics }
