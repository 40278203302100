import React from 'react'
import isEmpty from 'lodash/isEmpty'

import { goTo, urlTo } from 'helpers/router.js'

import { useContext, useEffect, useSelector, useMemo } from 'hooks'

import { StepProvider, StepContext, Tunnel, TunnelStepAdapter } from 'app/containers/PortfolioTunnel'
import { getQuestionnaireSteps, Loading } from 'app/containers/Questionnaire'

import { RetakeQuestionnaireProvider, RetakeQuestionnaireTunnelContext } from './RetakeQuestionnaireTunnel.jsx'

const RedirectToFirstStep = ({ children, route, params }) => {
  const { answers } = useContext(RetakeQuestionnaireTunnelContext)
  const { steps } = useContext(StepContext)

  useEffect(() => {
    const firstStep = steps[0]
    let needRedirect = false

    if (isEmpty(answers) && params.questionCode !== firstStep?.questionCode) {
      needRedirect = true
    }

    if (route.module === 'questionnaire-retake' && !params.questionCode) {
      needRedirect = true
    }

    if (firstStep.module.includes('get-started')) {
      needRedirect = false
    }

    if (needRedirect && firstStep) {
      goTo(urlTo(`questionnaire-retake.question`, { ...params, questionCode: firstStep?.questionCode }))
    }
  }, [answers, params, route.module, steps])

  return children
}

const Retake = ({ children, route, params, ...rest }) => {
  const questions = useSelector((state) => state.questionnaire.questionList.questions)
  const steps = useMemo(() => getQuestionnaireSteps(route.module, route.childRoutes, questions), [route, questions])
  const activeStep = rest.routes.at(-1)

  if (steps.length < 1) {
    return <Loading />
  }

  const { routes } = rest

  return (
    <RetakeQuestionnaireProvider>
      <StepProvider steps={steps} adapter={TunnelStepAdapter({ routes, params })}>
        <RedirectToFirstStep params={params} route={route}>
          <Tunnel>
            {React.cloneElement(children, {
              key: `${params.goalId}.${params.questionCode ?? ''}`,
              activeStep,
              ...rest,
            })}
          </Tunnel>
        </RedirectToFirstStep>
      </StepProvider>
    </RetakeQuestionnaireProvider>
  )
}

export { Retake }
