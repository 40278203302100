import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMemo, useMediaQueries, useState } from 'hooks'

import { periods as periodTypes, sortIncomeHistoryData } from 'helpers/charts'
import compose from 'helpers/compose.js'
import { urlTo } from 'helpers/router.js'

import Link from 'components/_old/Link/Link.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'

import { type Period } from 'components/molecules/NewPeriodSelect'
import Tabs, { Tab } from 'components/molecules/Tabs'

import { IncomeHistoryChart, IncomeTwrChart } from 'components/organisms/charts'

import './IncomeGraphs.css'

import { type ManagedPortfolio } from '../../../../types'

type IncomeGraphsProps = {
  isPortfolioLoading: boolean
  portfolio: ManagedPortfolio
  currentPeriod: Period
  periodSelectNode: React.ReactNode
  isPlaceholderDisplayed: boolean
  handleAddFundsClick: () => void
}

const IncomeGraphs = ({
  isPortfolioLoading,
  portfolio,
  currentPeriod,
  periodSelectNode,
  isPlaceholderDisplayed,
  handleAddFundsClick,
}: IncomeGraphsProps): React.ReactElement | null => {
  const periods = useMemo(
    () => ({
      [periodTypes.PERIOD_ALL_TIME]: {
        title: 'All time',
        dataKey: 'total',
        'data-test-id': 'portfolioChartAllTimeTab',
      },
      [periodTypes.PERIOD_YEAR]: { title: '12 months', dataKey: 'year', 'data-test-id': 'goalChartYearTab' },
      [periodTypes.PERIOD_YEAR_TO_DATE]: {
        title: 'YTD',
        dataKey: 'year_to_date',
        'data-test-id': 'goalChartYearToDateTab',
      },
      [periodTypes.PERIOD_SIX_MONTHS]: {
        title: '6 months',
        dataKey: 'six_months',
      },
      [periodTypes.PERIOD_ONE_MONTH]: {
        title: '1 month',
        dataKey: 'month',
      },
      [periodTypes.PERIOD_ONE_WEEK]: {
        title: '1 week',
        dataKey: 'week',
      },
    }),
    [],
  )

  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const [activeChart, setActiveChart] = useState('income') // income, return

  const incomeData = useMemo(() => {
    return sortIncomeHistoryData(portfolio.history_income, currentPeriod)
  }, [portfolio, currentPeriod])

  const twrData = useMemo(() => {
    const dataKey = periods[currentPeriod]?.dataKey

    return portfolio.history_twr?.[dataKey]
  }, [portfolio, currentPeriod, periods])

  const investmentReturnData = useMemo(() => portfolio.stats[currentPeriod], [portfolio, currentPeriod])

  const classes = classNames('PortfolioChart', getMediaQuieryClasses('PortfolioChart', mediaQueries))

  const chartSelectNode = useMemo(
    () => (
      <div className="PortfolioChart-TabsLine">
        <Tabs className="PortfolioChart-Tabs">
          <Tab
            className="PortfolioChart-Tab"
            fullWidthBorder={!desktop}
            active={activeChart === 'income'}
            onClick={() => {
              setActiveChart('income')
            }}
            data-test-id="incomePaidTab"
          >
            Income paid
          </Tab>
          <Tab
            className="PortfolioChart-Tab"
            fullWidthBorder={!desktop}
            active={activeChart === 'return'}
            onClick={() => {
              setActiveChart('return')
            }}
            data-test-id="incomeTWRTab"
          >
            Investment return
          </Tab>
        </Tabs>
        <div className="PortfolioChart-TabsLineBorder" />
      </div>
    ),
    [activeChart, desktop],
  )

  const incomeChart = useMemo(
    () => (
      <Skeleton shown={isPortfolioLoading && (incomeData?.length ?? 0) < 1}>
        <IncomeHistoryChart
          data={incomeData}
          empty={
            <Text center>
              <Typo>Nothing to show just yet but you can view</Typo>
              &nbsp;
              <Link to={urlTo('dashboard.portfolio', { id: portfolio.id }, { view: 'Projections' })} replace>
                <Typo>projections here</Typo>
              </Link>
            </Text>
          }
          isPlaceholderDisplayed={isPlaceholderDisplayed}
          handleAddFundsClick={handleAddFundsClick}
        />
      </Skeleton>
    ),
    [isPortfolioLoading, incomeData, isPlaceholderDisplayed, portfolio?.id, handleAddFundsClick],
  )

  const returnChart = useMemo(
    () => (
      <Skeleton shown={isPortfolioLoading && (twrData?.length ?? 0) < 1}>
        <IncomeTwrChart
          data={twrData}
          investmentReturnData={investmentReturnData}
          empty={
            <Text center>
              <Typo>Nothing to show just yet</Typo>
            </Text>
          }
          showLessTicks
          isPlaceholderDisplayed={isPlaceholderDisplayed}
          handleAddFundsClick={handleAddFundsClick}
        />
      </Skeleton>
    ),
    [isPortfolioLoading, twrData, handleAddFundsClick, isPlaceholderDisplayed, investmentReturnData],
  )

  return (
    <Paper className={classes}>
      <Fragment>
        <Paper left={desktop ? 0 : 16} right={desktop ? 0 : 16}>
          {chartSelectNode}
        </Paper>
        <Paper top={40} left={desktop ? 0 : 16} right={desktop ? 0 : 16}>
          {periodSelectNode}
        </Paper>
        <Paper top={desktop ? 32 : 16} left={desktop ? 0 : 16} right={desktop ? 0 : 16}>
          {activeChart === 'income' ? incomeChart : returnChart}
        </Paper>
      </Fragment>
    </Paper>
  )
}

const IncomeGraphsComposed = compose(withRouter)(IncomeGraphs)

export { IncomeGraphsComposed as IncomeGraphs }
