import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { urlTo, goTo } from 'helpers/router.js'
import { combineErrors, backendErrorsToObj, bulkValidate, emailRules } from 'helpers/validation.js'
import { ValidationError } from 'helpers/errors'
import { sendError } from 'helpers/errorLogging.js'

import { useActions, useCallback, useSelector, useEffect, useState } from 'hooks'
import { useUnit } from 'effector-react'

import Modal from 'components/_old/Modal/Modal.jsx'
import ModalContent, {
  ModalContentHeadline,
  ModalContentBody,
  ModalContentButtons,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Form from 'components/_old/Form/Form.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'
import Button from 'components/_old/Button/Button.jsx'

import { updateOrCreateContactFx, $owner } from 'app/effector/contacts'
import {
  changeModalField as changeModalFieldActionCreator,
  showSuccessToast,
  showFailToast,
} from 'app/redux/actions/ui'
import { ApiError } from 'app/redux/models/errors'

const ChangeEmail = ({ routes }) => {
  const owner = useUnit($owner) ?? {}
  const email = useSelector((state) => state.ui.modals.changeEmail.email)
  const [error, setError] = useState(null)

  const isOpen = routes[routes.length - 1]?.module === 'change-email'

  const [changeModalField] = useActions([changeModalFieldActionCreator])

  const handleEmailChange = useCallback(
    (_event, value) => {
      changeModalField('changeEmail', { email: value })
      setError(null)
    },
    [changeModalField],
  )

  const handleClose = useCallback(() => {
    const backUrl = urlTo('dashboard.user-profile.personal-info')
    goTo(backUrl, { replace: true, scrollToTop: false })
    handleEmailChange(null, null)
  }, [handleEmailChange])

  const handleSubmit = useCallback(async () => {
    const { id } = owner
    try {
      const response = await updateOrCreateContactFx({ data: { email }, id })

      if (response instanceof ApiError) {
        throw response
      }

      handleClose()
      showSuccessToast('Email successfully changed')
    } catch (error) {
      setError(error)
      if (!(error instanceof ValidationError)) {
        sendError(error)
      }
      showFailToast()
    }
  }, [owner, email, handleClose])

  useEffect(() => {
    handleEmailChange(null, email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isOpen) {
      handleEmailChange(null, owner.email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const validation = combineErrors(
    {
      email: emailRules(email),
    },
    backendErrorsToObj(error),
  )

  return (
    <Modal open={isOpen} gatewayName="modals" data-test-id="changeEmailModal" onClose={handleClose}>
      <ModalContent
        width={30}
        wrapWith={(innerContent) => (
          <SubmitOnEnter>
            <Form>{innerContent}</Form>
          </SubmitOnEnter>
        )}
      >
        <ModalContentHeadline>Change email</ModalContentHeadline>
        <ModalContentBody size={null}>
          <Fragment>
            <Validate rules={validation.email.rules}>
              <Label errorMessages={validation.email.errors} data-test-id="changeEmailModalEmailLabel">
                Email
                <Input
                  type="email"
                  mods={{ size: 'bigger' }}
                  onChange={handleEmailChange}
                  tabIndex={1}
                  data-test-id="changeEmailModalEmailInput"
                >
                  {email}
                </Input>
              </Label>
            </Validate>
          </Fragment>
        </ModalContentBody>
        <ModalContentButtons width={null}>
          <ButtonGroup>
            <Button
              mods={{ theme: 'simple-reverse-blue' }}
              onClick={handleClose}
              data-test-id="changeEmailCancelButton"
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={!bulkValidate(validation)} data-test-id="changeEmailSubmitButton">
              Change
            </Button>
          </ButtonGroup>
        </ModalContentButtons>
      </ModalContent>
    </Modal>
  )
}
export { ChangeEmail as ChangeEmailModal }
export default withRouter(ChangeEmail)
