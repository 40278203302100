import React from 'react'
import PropTypes from 'prop-types'

const SideSpacesCompensator = ({ className, children, size = 1 }) => (
  <div className={className} style={{ marginLeft: `-${size}rem`, marginRight: `-${size}rem` }}>
    {children}
  </div>
)

SideSpacesCompensator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.number,
}

export default SideSpacesCompensator
