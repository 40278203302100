import { useUnit } from 'effector-react'

import { useLoading, useEffect, useMemo } from 'hooks'

import { features } from 'helpers/features'

import { fetchTransferProgressesFx, $transferProgressCollection } from 'app/effector/isa-transfer'

const useAltusAccountsData = (visiblePortfolios = []) => {
  const transferProgressCollection = useUnit($transferProgressCollection)
  const { isLoading, wait } = useLoading()
  const visiblePortfoliosIdsKey = useMemo(
    () => visiblePortfolios.map((portfolio) => portfolio.id).join(),
    // optimised
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(visiblePortfolios)],
  )

  useEffect(() => {
    if (features.get('altus-progress-bar') && visiblePortfolios.length > 0) {
      wait(
        Promise.all(
          visiblePortfolios
            .filter((portfolio) => !portfolio.skeleton)
            .map(async (portfolio) => await fetchTransferProgressesFx({ portfolioId: portfolio.id })),
        ),
      )
    }
    // optimised
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiblePortfoliosIdsKey])

  return {
    transferProgressCollection,
    isLoading,
  }
}

export { useAltusAccountsData }
