import React from 'react'

import { routes as amount } from './Amount/routes.jsx'
import { routes as finishRegistration } from './FinishRegistration/routes.jsx'
import { routes as notApproved } from './NotApproved/routes.jsx'
import { routes as newBank } from 'app/pages/Dashboard/Goals/NewBank/routes.jsx'
import { routes as instantPayment } from './InstantPayment/routes.jsx'
import { routes as setUpInstantBank } from './SetUpInstantBank/routes.jsx'
import { routes as wireTransfer } from './WireTransfer/routes.jsx'
import { routes as bankTransfer } from './BankTransfer/routes.jsx'
import { routes as uploadBankStatement } from './UploadBankStatement/routes.jsx'
import { routes as netContributionsRoutes } from './NetContributions/routes'

import { SelectPaymentMethod } from './SelectPaymentMethod'

// If route path will change, tell backend to change on their side too, 'cuz it'll affect emails
const routes = [
  {
    module: 'add-funds',
    path: 'add-funds/',
    component: function Component(props) {
      return <SelectPaymentMethod {...props} />
    },
    childRoutes: [
      amount,
      finishRegistration,
      notApproved,
      setUpInstantBank,
      newBank,
      instantPayment,
      wireTransfer,
      bankTransfer,
      uploadBankStatement,
      netContributionsRoutes,
    ],
  },
]

export default routes
