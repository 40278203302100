import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { getKeyOrDefault } from 'helpers/object'

import { useCallback, useState, useEffect, useMemo, useMediaQueries, useSelector, useActions } from 'hooks'

import { Modal } from 'components/molecules/Modal'
import { Paper } from 'components/atoms/Paper'

import { fetchRecommendedPreset as fetchRecommendedPresetActionCreator } from 'app/redux/actions/questionnaire'

import { states as portfolioModalStates } from './states.js'

const NewOptimalPortfolioModal = ({
  className,
  open,
  goal,
  clientAnswers,
  renderChildrenMap,
  onClose: handleClose,
}) => {
  const { desktop } = useMediaQueries()
  const classes = classNames(className, 'NewOptimalPortfolioModal')

  const [rootModalVisibleState, setRootModalVisibleState] = useState(portfolioModalStates.PORTFOLIO_INCORRECT)
  const [presetRecommended, setPresetRecommended] = useState(null)
  const [fetchRecommendedPresetAction] = useActions([fetchRecommendedPresetActionCreator])

  const { type: clientType } = useSelector((state) => state.client)
  const { presets } = useSelector((state) => state.portfolios)

  useEffect(() => {
    if (clientAnswers.length > 0 && goal && open) {
      fetchRecommendedPresetAction({
        clientType,
        goalRegulatoryType: goal.regulatory_type,
        goalPresetType: goal.preset_type,
        answers: clientAnswers,
      }).then(({ presetRecommendedId }) => {
        setPresetRecommended(presetRecommendedId)

        const currentPreset = goal.preset

        if (!presetRecommendedId) {
          setRootModalVisibleState(portfolioModalStates.PORTFOLIO_INCORRECT)
        }
        if (presetRecommendedId && presetRecommendedId === currentPreset) {
          setRootModalVisibleState(portfolioModalStates.PORTFOLIO_NOT_CHANGED)
        }
        if (presetRecommendedId && presetRecommendedId !== currentPreset) {
          setRootModalVisibleState(portfolioModalStates.PORTFOLIO_CHANGED)
        }
      })
    }
  }, [clientAnswers, open])

  const canShowCross = rootModalVisibleState === portfolioModalStates.PORTFOLIO_INCORRECT

  const handleCloseModal = useCallback(() => {
    if (handleClose && canShowCross) {
      handleClose()
    }
  }, [handleClose, canShowCross])

  const renderChildren = renderChildrenMap[rootModalVisibleState]
  const currentPresets = getKeyOrDefault({ ...presets, default: [] }, goal.preset_type)
  const currentPortfolioIndex = currentPresets.findIndex((preset) => preset.id === goal.preset)
  const newOptimalPortfolioIndex = currentPresets.findIndex((preset) => preset.id === presetRecommended)
  const currentPortfolio = { ...currentPresets[currentPortfolioIndex], index: currentPortfolioIndex }
  const newOptimalPortfolio = { ...currentPresets[newOptimalPortfolioIndex], index: newOptimalPortfolioIndex }
  const close = useMemo(() => {
    if (!canShowCross) {
      return { close: null }
    }

    if (!desktop) {
      return { close: 'Close' }
    }

    return {}
  }, [canShowCross, desktop])

  return (
    <Modal
      className={classes}
      open={open}
      {...close}
      noCross={!canShowCross}
      onClose={handleCloseModal}
      data-test-id="newOptimalPortfolioModal"
    >
      <Paper top={desktop ? 48 : null} bottom={desktop ? 48 : null}>
        {renderChildren({ setRootModalVisibleState, currentPortfolio, newOptimalPortfolio, presetRecommended })}
      </Paper>
    </Modal>
  )
}

NewOptimalPortfolioModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  goal: PropTypes.object.isRequired,
  clientAnswers: PropTypes.array.isRequired,
  renderChildrenMap: PropTypes.object.isRequired,
  onClose: PropTypes.func,
}

export { NewOptimalPortfolioModal }
