import { type MutableRefObject } from 'react'

import { useRef, useTouch, useClickOutside, useEffect } from 'hooks'

import { isTouch } from 'helpers/mobile'

type UseTouchablePanelProps = {
  handleEnter: (...args: unknown[]) => void
  handleLeave: (...args: unknown[]) => void
  containers: Element[]
  enterOnTouchStart?: boolean
  enterOnLongTouch?: boolean
  leaveOnTouchEnd?: boolean
}

type UseTouchablePanelInterface<Element extends HTMLElement> = {
  rootRef: MutableRefObject<Element | null>
}

function useTouchablePanelNonTouch<Element extends HTMLElement>(
  _props: UseTouchablePanelProps,
): UseTouchablePanelInterface<Element> {
  const rootRef = useRef<Element>(null)

  return {
    rootRef,
  }
}

function useTouchablePanelTouch<Element extends HTMLElement>({
  handleEnter,
  handleLeave,
  containers = [],
  enterOnTouchStart = true,
  enterOnLongTouch = false,
  leaveOnTouchEnd = false,
}: UseTouchablePanelProps): UseTouchablePanelInterface<Element> {
  const [rootRef, hasClickedOutside] = useClickOutside<Element>()

  useEffect(() => {
    if (hasClickedOutside) {
      handleLeave()
    }
  }, [hasClickedOutside, handleLeave])

  useTouch({
    onEnter: handleEnter,
    onLeave: handleLeave,
    containers,
    enterOnTouchStart,
    enterOnLongTouch,
    leaveOnTouchEnd,
  })

  return {
    rootRef,
  }
}

const useTouchablePanel = <Element extends HTMLElement>(
  props: UseTouchablePanelProps,
): UseTouchablePanelInterface<Element> => {
  const touchResult = useTouchablePanelTouch<Element>(props)
  const nonTouchResult = useTouchablePanelNonTouch<Element>(props)

  return isTouch() ? touchResult : nonTouchResult
}

export { useTouchablePanel }
