import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette/'
import { format as formatMoney, formatPercent, roundDecimals } from 'helpers/money'
import { format as formatDate } from 'helpers/date.js'
import { transformIncomeProjectionsData, getMaxFromDataSeries } from 'helpers/charts'
import { getEventElement } from 'helpers/dom'

import { useMediaQueries, useState, useMemo, useEffect, useCallback, useRef } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import {
  Chart,
  ChartTooltip,
  IncomeBars,
  Legend,
  LegendNumber,
  LegendMark,
  NumeralAxis,
  TodayPointer,
} from 'components/organisms/charts/parts'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Preloader } from 'components/molecules/Preloader'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Profit } from 'components/molecules/Profit'
import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

import { useTouchablePanel } from 'components/organisms/charts/hooks'

import './IncomeProjectionsChart.css'

const IncomeProjectionsChart = ({ isNewProjections, data, empty, loading, defaultMax, hoverable = true } = {}) => {
  const { past: pastData, future: futureData } = data || {}
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries
  const [past, future] = [
    transformIncomeProjectionsData(pastData, false),
    transformIncomeProjectionsData(futureData, true),
  ]
  const { max, withFloat } = getMaxFromDataSeries([past, future], defaultMax)
  const isEmpty = !futureData
  const [hoveredIndexes, setHoveredIndexes] = useState(null)
  const formattedHoveredData = useMemo(() => {
    if (!hoveredIndexes) {
      return null
    }
    const futureData = Array.isArray(future?.data) && future?.data[hoveredIndexes?.future]
    const pastData = Array.isArray(past?.data) && past?.data[hoveredIndexes?.past]
    const data = futureData || pastData

    if (!data) {
      return null
    }

    return (
      <ColumnarLayout mods={{ padding: 'small' }}>
        <Text small noWrap>
          {formatDate(data.date, 'MMMM YYYY')}
        </Text>
        <Text small noWrap>
          {formatMoney(data.value, true)}
        </Text>
      </ColumnarLayout>
    )
  }, [hoveredIndexes, past, future])

  useEffect(() => {
    if (!desktop) {
      setHoveredIndexes(null)
    }
  }, [desktop, setHoveredIndexes])

  const classes = classNames('IncomeProjectionsChart', getMediaQuieryClasses('IncomeProjectionsChart', mediaQueries))

  const pastRef = useRef(null)
  const futureRef = useRef(null)

  const handleEnter = useCallback(
    (event, index) => {
      if (!hoverable) {
        return
      }
      const element = getEventElement(event)

      if (pastRef.current?.contains(element)) {
        setHoveredIndexes({ past: index })
      }
      if (futureRef.current?.contains(element)) {
        setHoveredIndexes({ future: index })
      }
    },
    [setHoveredIndexes, pastRef, futureRef, hoverable],
  )
  const handleLeave = useCallback((_event) => hoverable && setHoveredIndexes(null), [setHoveredIndexes, hoverable])
  const { rootRef } = useTouchablePanel({
    handleEnter,
    handleLeave,
    containers: [pastRef.current, futureRef.current],
  })

  const estimatedYieldText = `
    The income you can expect to receive over the next 12 months.
    The yield is estimated and variable and based on the income paid over the past
    year by the exchange-traded funds (ETFs) in your portfolio.
    Income could be more or less than the indicated amount as a result of factors including:
    a change in interest rates; a change in dividends; the purchase of an ETF after
    its ex-dividend date; significant variation in estimated yields since the data was last updated;
    and the actual price at which ETFs are purchased. Our portfolio yields are updated regularly:
    the last update used closing prices for the ETFs on
    ${formatDate(future.yield_calculated_date, 'DD/MM/YYYY')}.
    Source: InvestEngine.
  `

  return (
    <div className={classes}>
      <Legend flex position="top">
        {(() => {
          if (pastData && futureData) {
            return (
              <Fragment>
                <Text left block>
                  {!desktop && (
                    <LegendMark className="IncomeProjectionsChart-LegendMark" color={palette['primary-default']} />
                  )}
                  <LegendNumber
                    className="IncomeProjectionsChart-LegendNumber"
                    text="Historic yield p.a."
                    textSize="small"
                    description="The income yield of an InvestEngine income portfolio is the weighted average of the estimated yields of the ETFs in the portfolio. The ETFs’ estimated yields are calculated from the last 12 months’ income they have paid. Our quoted portfolio yields are updated monthly, using the closing prices for the ETFs on the last working day of the previous calendar month. Source: InvestEngine."
                    value={[
                      {
                        text: formatPercent(roundDecimals(past.yield_return) / 100, true),
                        color: 'blue',
                      },
                    ]}
                    align="left"
                    data-test-namespace="pastYield"
                  />
                </Text>
                <Text right block>
                  {!desktop && (
                    <LegendMark className="IncomeProjectionsChart-LegendMark" color={palette['status-success']} />
                  )}
                  <LegendNumber
                    className={classNames('IncomeProjectionsChart-Tooltip', 'IncomeProjectionsChart-LegendNumber')}
                    text="Estimated yield p.a."
                    textSize="small"
                    description={estimatedYieldText}
                    value={[
                      {
                        text: formatPercent(roundDecimals(future.yield_return) / 100, true),
                        color: 'green',
                      },
                      {
                        text: formatMoney(future.dividends_amount, true),
                      },
                    ]}
                    align="right"
                    data-test-namespace="estimatedYield"
                  />
                </Text>
              </Fragment>
            )
          }

          if (futureData) {
            return isNewProjections ? (
              <Paper top={desktop ? 0 : 32} bottom={desktop ? 8 : 0}>
                <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold">
                  <Profit
                    showPercentsFirts
                    amount={future.dividends_amount}
                    percent={roundDecimals(future.yield_return)}
                  />
                </Typography>
                <Paper>
                  <Typography size={desktop ? 16 : 14} color="minor" lineHeight="small">
                    Estimated yield p.a.{' '}
                    <TooltipToModal description={<Typo>{estimatedYieldText}</Typo>} customIcon>
                      <Link mods={{ color: 'minorToRed' }} style={{ display: 'inline-flex' }}>
                        <Icon
                          size={desktop ? 24 : 18}
                          type="questionInCircle"
                          color="inherit"
                          style={{ position: 'relative', top: desktop ? '9px' : '5px' }}
                        />
                      </Link>
                    </TooltipToModal>
                  </Typography>
                </Paper>
              </Paper>
            ) : (
              <Text block>
                <LegendNumber
                  text="Estimated yield p.a."
                  className="IncomeProjectionsChart-LegendNumber"
                  textSize="small"
                  description={estimatedYieldText}
                  value={[
                    {
                      text: formatPercent(roundDecimals(future.yield_return) / 100, true),
                      color: 'green',
                    },
                    {
                      text: formatMoney(future.dividends_amount, true),
                    },
                  ]}
                  align="left"
                  oneline
                  data-test-namespace="estimatedYield"
                />
              </Text>
            )
          }

          return (
            <span style={{ visibility: 'hidden' }}>
              <Text block>
                {!desktop && (
                  <LegendMark className="IncomeProjectionsChart-LegendMark" color={palette['status-success']} />
                )}
                <LegendNumber
                  text=" "
                  className="IncomeProjectionsChart-LegendNumber"
                  textSize="small"
                  description=" "
                  value={[
                    {
                      text: formatMoney(future.dividends_amount, true),
                    },
                  ]}
                  align="right"
                  oneline={!(pastData && futureData)}
                  data-test-namespace="estimatedYield"
                />
              </Text>
            </span>
          )
        })()}
      </Legend>
      <div className="IncomeProjectionsChart-Chart" ref={rootRef}>
        <Chart size={isNewProjections ? 'medium' : 'small'} data-test-id="editGoalProjectionsChart">
          <NumeralAxis min={0} max={max} quantity={isEmpty ? 1 : 5} format={(value) => formatMoney(value, withFloat)} />
          {(() => {
            if (pastData && futureData) {
              return (
                <ColumnarLayout mods={{ padding: 'nano' }}>
                  <Column>
                    <IncomeBars
                      ref={pastRef}
                      className="IncomeProjectionsChart-Past"
                      columnClassName="IncomeProjectionsChart-Column"
                      tickClassName="IncomeProjectionsChart-Tick"
                      tickTextClassName="IncomeProjectionsChart-Text"
                      max={max}
                      data={past.data}
                      colors={[palette['primary-default']]}
                      showTicksAt={[0]}
                      hoverable={hoverable}
                      onTickEnter={handleEnter}
                      onTickLeave={handleLeave}
                      selectedIndex={hoveredIndexes?.past}
                    />
                  </Column>
                  <Column size={0}>
                    <TodayPointer />
                  </Column>
                  <Column>
                    <IncomeBars
                      ref={futureRef}
                      className="IncomeProjectionsChart-Future"
                      columnClassName="IncomeProjectionsChart-Column"
                      tickClassName="IncomeProjectionsChart-Tick"
                      tickTextClassName="IncomeProjectionsChart-Text"
                      max={max}
                      data={future.data}
                      colors={[palette['status-success']]}
                      showTicksAt={[0]}
                      hoverable={hoverable}
                      selectedIndex={hoveredIndexes?.future}
                      onTickEnter={handleEnter}
                      onTickLeave={handleLeave}
                    />
                  </Column>
                </ColumnarLayout>
              )
            }

            if (futureData) {
              return (
                <IncomeBars
                  max={max}
                  data={future.data}
                  colors={[palette['status-success']]}
                  hoverable={hoverable}
                  selectedIndex={hoveredIndexes?.future}
                  onTickEnter={handleEnter}
                  onTickLeave={handleLeave}
                  ref={futureRef}
                />
              )
            }

            return null
          })()}
          {!isEmpty && (
            <ChartTooltip visible={Boolean(formattedHoveredData)} pointerTransparent>
              {formattedHoveredData}
            </ChartTooltip>
          )}
        </Chart>
      </div>
      {isEmpty && !loading && (
        <AllCenter className="IncomeProjectionsChart-Empty" data-test-id="incomeProjectionsChartEmpty">
          {empty}
        </AllCenter>
      )}
      <Preloader className="Projections-Preloader" loading={loading} data-test-id="projectionsPreloader" />
    </div>
  )
}

const commonData = {
  max_y: PropTypes.string.isRequired,
  yield_return: PropTypes.number.isRequired,
  dividends_amount: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

const pastDataShape = PropTypes.shape(commonData)

const futureDataShape = PropTypes.shape({
  ...commonData,
  yield_calculated_date: PropTypes.string.isRequired,
})

IncomeProjectionsChart.propTypes = {
  isNewProjections: PropTypes.bool,
  data: PropTypes.shape({
    past: pastDataShape,
    future: futureDataShape,
    date: PropTypes.string,
    value: PropTypes.number,
  }),
  empty: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  defaultMax: PropTypes.number,
  hoverable: PropTypes.bool,
}

export { IncomeProjectionsChart }
