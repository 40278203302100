import React from 'react'

import { urlTo } from 'helpers/router.js'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './OrdersPlacedButton.css'

type OrdersPlacedButtonProps = {
  portfolioId: string
}

const OrdersPlacedButton = ({ portfolioId }: OrdersPlacedButtonProps): React.ReactElement => {
  return (
    <Link to={urlTo('portfolio.portfolio-orders', { id: portfolioId })} data-test-id="ordersProcessingLink">
      <Paper className="OrdersPlacedButton" top={12} bottom={12}>
        <ItemWithIcon
          space={8}
          icon={<Icon size={24} type="time-24" color="inherit" />}
          content={
            <Typography size={16} lineHeight="small" color="inherit">
              <Typo>Orders placed</Typo>
            </Typography>
          }
          iconVerticalAlign="center"
          contentVerticalAlign="center"
          selfHorizontalAlign="center"
          data-test-id="ordersPlaced"
        />
      </Paper>
    </Link>
  )
}

export { OrdersPlacedButton }
