import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

/**
 * @typedef EquityTypeDTO
 * @type {object}
 * @property {string} actual_weight
 * @property {string} target_weight
 * @property {string} value
 */

/**
 * @typedef EquityTypesDTO
 * @type {object}
 * @property {EquityTypeDTO} STOCK
 * @property {EquityTypeDTO} BOND
 * @property {EquityTypeDTO} ALTERNATIVE
 * @property {EquityTypeDTO} CASH
 */

/**
 * @typedef EquityDTO
 * @type {object}
 * @property {string} name
 * @property {string} logo
 * @property {string} actual_weight
 * @property {string} target_weight
 * @property {'STOCK'|'BOND'|'ALTERNATIVE'|'CASH'} equity_type
 */

/**
 * @typedef FullAnalyticsDTO
 * @type {object}
 * @property {string} total_value
 * @property {number} total_quantity
 * @property {EquityTypesDTO} equity_types
 * @property {EquityDTO[]} equities
 */

/**
 * @param {object} params
 * @param {number} params.portfolioId
 * @return {Promise<FullAnalyticsDTO[]|ApiError>}
 */

const getAnalyticsByWeights = async ({ portfolioId, weights }) => {
  try {
    const { data } = await axios.post(`/analytics/by_securities/`, weights, {
      params: { portfolio_id: portfolioId },
    })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const getHoldingAnalyticsByWeights = async ({ type, id, portfolioId, weights }) => {
  try {
    const { data } = await axios.post(`/analytics/by_securities/by_type/${type.toUpperCase()}/${id}/`, weights, {
      params: { portfolio_id: portfolioId },
    })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { getAnalyticsByWeights, getHoldingAnalyticsByWeights }
