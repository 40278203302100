import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useActions, useCallback, useMemo } from 'hooks'
import { usePendingOrders } from 'app/pages/Dashboard/Goals/DIY/hooks'
import { useSecurities } from 'app/pages/Securities/hooks'
import { Preloader } from 'components/molecules/Preloader'
import { GatewayDest } from 'components/atoms/Gateway'

import { SecurityOrdersPage } from 'app/pages/Dashboard/Securities/SecurityOrdersPage/SecurityOrdersPage.jsx'
import { ApproveCancellationModal } from './components/ApproveCancellationModal'
import { TradingWindowWarningModal } from './components/TradingWindowWarningModal'

import { showSupportModal as showSupportModalActionCreator } from 'app/redux/actions/ui'

import { useCancelOrders } from './hooks'

const PortfolioOrders = ({ params: { id: idString } = {} }) => {
  const portfolioId = useMemo(() => parseInt(idString), [idString])
  const { securities, isLoading: isSecuritiesLoading } = useSecurities({ portfolioId })
  const { orders, isLoading: isPendingOrdersLoading } = usePendingOrders()
  const {
    isLoading: isCancelOrdersLoading,
    isTradingWindow,
    isCancellationModalOpened,
    isTradingWindowWarningModalOpened,
    cancellationErrorText,
    openCancellationModal,
    closeCancellationModal,
    closeTradingWindowWarningModal,
    cancelOrders,
  } = useCancelOrders(portfolioId)
  const portfolioOrders = useMemo(() => orders.getByPortfolioId(portfolioId), [portfolioId, orders])
  const isLoading = isSecuritiesLoading || isPendingOrdersLoading || isCancelOrdersLoading

  const [showSupportModal] = useActions([showSupportModalActionCreator])

  const handleBack = useCallback(() => history.back(), [])
  const handleContactUs = useCallback(() => showSupportModal(), [])

  return (
    <SecurityOrdersPage
      canBeCanceled
      isTradingWindow={isTradingWindow}
      orders={portfolioOrders}
      securities={securities}
      extraNodes={
        <Fragment>
          <GatewayDest name="toasts" />
          <GatewayDest name="modals" />
          <GatewayDest name="supportModal" />
          <ApproveCancellationModal
            open={isCancellationModalOpened}
            handleCancelOrders={cancelOrders}
            handleClose={closeCancellationModal}
          />
          <TradingWindowWarningModal
            cancellationErrorText={cancellationErrorText}
            open={isTradingWindowWarningModalOpened}
            handleClose={closeTradingWindowWarningModal}
          />
          <Preloader loading={isLoading} size="big" zIndex />
        </Fragment>
      }
      onBack={handleBack}
      onCancelOrders={openCancellationModal}
      onContactUs={handleContactUs}
    />
  )
}

PortfolioOrders.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export { PortfolioOrders }
