import { shallowEqual } from 'react-redux'

import { useUnit } from 'effector-react'

import { useEffect, useSelector, useMemo } from 'hooks'

import { $allowancesStore, fetchAllowancesFx } from 'app/effector/allowances'
import { $owner } from 'app/effector/contacts'
import { type TransferProgressCollection } from 'app/effector/isa-transfer/models/TransferProgress/TransferProgressCollection'

import { type PortfolioList } from 'app/redux/models/portfolio/'
import {
  isClientNewOrNotCompleted as selectIsClientNewOrNotCompleted,
  getSortedRegulatoryTypes,
} from 'app/redux/selectors'

import { useAltusAccountsData } from '../../hooks/useAltusAccountsData'

import { types as clientTypes } from 'constants/client'
import { regulatoryTypes, manageTypes } from 'constants/portfolio'

type UsePortfoliosInterface = {
  isBusiness: boolean
  groupedPortfolios: Record<string, PortfolioList>
  remainingIsaAllowance: number
  totalSippAllowance: number
  canOpenIsa: boolean
  canOpenSipp: boolean
  isIsaAccountOpened: boolean
  isSippAccountOpened: boolean
  hasPortfolios: boolean
  isLoading: boolean
  hasOpenedIsa: boolean
  hasPersonalPortfolios: boolean
  transferProgressCollection?: TransferProgressCollection
  regulatoryTypesOrder: string[]
}

const usePortfolios = (portfolios: PortfolioList): UsePortfoliosInterface => {
  const groupedPortfolios = useMemo(() => portfolios.groupByRegulatoryType(), [portfolios])
  const regulatoryTypesOrder = useSelector(getSortedRegulatoryTypes, shallowEqual)

  const { allowances, areAllowancesFetched } = useUnit($allowancesStore)

  const remainingIsaAllowance = parseFloat(allowances?.isa?.remaining_allowance ?? '0')
  const totalSippAllowance = parseFloat(allowances?.sipp?.total_allowance ?? '0')

  const client = useSelector((state: { client: { type: string } }) => state.client)
  const isPortfoliosOrClientLoading = useSelector(
    (state) => state.client.didInvalidate || state.portfolios.didInvalidate,
  )

  const owner = useUnit($owner)

  const isClientNewOrNotCompleted = useSelector(selectIsClientNewOrNotCompleted)

  const isBusiness = client.type === clientTypes.BUSINESS
  const isUkResidence = owner?.isUkResidence()
  const isSippAge = owner?.age < 75
  const canOpenIsa = !owner?.id ? true : !isBusiness && (isClientNewOrNotCompleted || isUkResidence)
  const canOpenSipp = !owner?.id ? true : !isBusiness && (isClientNewOrNotCompleted || (isUkResidence && isSippAge))
  const isIsaAccountOpened = !owner?.id ? true : groupedPortfolios[regulatoryTypes.ISA]?.length > 0
  const isSippAccountOpened = groupedPortfolios[regulatoryTypes.SIPP]?.length > 0
  const nonCashPortfolios = [...portfolios].filter(
    (portfolio) => portfolio.manage_type !== manageTypes.CASH && !portfolio.skeleton,
  )
  const hasPortfolios = !!nonCashPortfolios.length
  const hasPersonalPortfolios = !!nonCashPortfolios.filter(
    (portfolio) => portfolio.regulatory_type === regulatoryTypes.GIA && !portfolio.skeleton,
  ).length
  const hasOpenedIsa = !!nonCashPortfolios.filter(
    (portfolio) => portfolio.regulatory_type === regulatoryTypes.ISA && !portfolio.skeleton,
  ).length

  const { isLoading: isAltusProgressDataLoading, transferProgressCollection } = useAltusAccountsData(
    groupedPortfolios[regulatoryTypes.ISA],
  )

  useEffect(() => {
    if ((isIsaAccountOpened || isSippAccountOpened) && !areAllowancesFetched) {
      fetchAllowancesFx()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoading = isPortfoliosOrClientLoading || isAltusProgressDataLoading

  return {
    isBusiness,
    groupedPortfolios,
    remainingIsaAllowance,
    totalSippAllowance,
    canOpenIsa: canOpenIsa && !isPortfoliosOrClientLoading,
    canOpenSipp: canOpenSipp && !isPortfoliosOrClientLoading,
    isIsaAccountOpened,
    isSippAccountOpened,
    hasPortfolios,
    isLoading,
    hasOpenedIsa,
    hasPersonalPortfolios,
    transferProgressCollection,
    regulatoryTypesOrder,
  }
}

export { usePortfolios }
