import React, { Fragment } from 'react'
import Glider from 'react-glider'

import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { trackEvent } from 'helpers/analytics'

import { Typo } from 'components/_old/Typo/Typo'

import { LogoLabel } from 'components/atoms/LogoLabel/LogoLabel'
import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { useRecentlyViewedEtfs } from './hooks/useRecentlyViewedEtfs'

import './RecentlyViewedEtfs.css'
import 'glider-js/glider.min.css'

type RecentlyViewedEtfsProps = {
  getSecurityLink: (security: any) => string
  handleSecurityClick: (id: number) => void
}

const RecentlyViewedEtfs = ({
  getSecurityLink,
  handleSecurityClick,
}: RecentlyViewedEtfsProps): React.ReactElement | null => {
  const { desktop } = useMediaQueries()
  const { etfsList } = useRecentlyViewedEtfs()
  const limitedEtfsList = etfsList.slice(0, 14)

  const etf = (security): React.ReactElement => (
    <Paper top={desktop ? 12 : 0} right={desktop ? 0 : 12} inline>
      <Skeleton shown={security.isSkeleton}>
        <LogoLabel
          text={security.ticker}
          icon={security.logo_uri}
          href={getSecurityLink(security.id)}
          onClick={() => {
            trackEvent({ action: 'etfrange_recently_clicked' })
            handleSecurityClick(security.id)
          }}
        />
      </Skeleton>
    </Paper>
  )

  // For odd ETFs amount to render in ColumnarLayout properly
  const showEmptyColumn = (): React.ReactElement | undefined => {
    if (limitedEtfsList.length % 2) {
      return <Column />
    }
  }

  if (!etfsList.length) return null

  return (
    <Fragment>
      <Typography size={14} lineHeight="small" color="minor">
        <Typo>Recently viewed ETFs</Typo>
      </Typography>
      {desktop ? (
        <ColumnarLayout mods={{ flexWrap: 'wrap', padding: 'micro' }}>
          {limitedEtfsList.map((security) => (
            <Column key={security.id}>{etf(security)}</Column>
          ))}
          {showEmptyColumn()}
        </ColumnarLayout>
      ) : (
        <div
          key={limitedEtfsList.map((etf) => etf.id).join(',')}
          className={classNames('recently-viewed-container', { 'recently-viewed-container-mobile': !desktop })}
        >
          <Glider
            draggable={!desktop}
            hasArrows
            slidesToShow="auto"
            slidesToScroll="auto"
            itemWidth={132}
            exactWidth={true}
            duration={1}
          >
            {limitedEtfsList.map((security) => (
              <div key={security.id}>{etf(security)}</div>
            ))}
          </Glider>
        </div>
      )}
    </Fragment>
  )
}

export { RecentlyViewedEtfs }
