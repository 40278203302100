import React from 'react'
import PropTypes from 'prop-types'

import { formLinkToHost } from 'helpers/formLinkToHost.js'

import { useMediaQueries, useMemo } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'

import './Document.css'

const Document = ({ name, src, noLeftPadding = false }) => {
  const { desktop } = useMediaQueries()

  const linkProps = useMemo(() => {
    const to = formLinkToHost(src)
    const props = {
      mods: { color: 'black' },
    }

    return {
      ...props,
      to,
      blank: true,
    }
  }, [src])

  const itemWithIconNode = useMemo(
    () => (
      <ItemWithIcon
        inline
        space={desktop ? 8 : 16}
        iconPosition="right"
        iconVerticalAlign="center"
        icon={<div className="Document-Icon">PDF</div>}
        content={<Typo>{name}</Typo>}
      />
    ),
    [name, desktop],
  )

  return (
    <Link className="Document" {...linkProps}>
      <Paper top={16} right={desktop ? null : 16} bottom={16} left={noLeftPadding ? 0 : desktop ? null : 16}>
        {itemWithIconNode}
      </Paper>
    </Link>
  )
}

Document.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
}

export { Document }
