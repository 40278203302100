import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router.js'

import { useCallback, useMemo, useSelector } from 'hooks'

import { Gateway } from 'components/atoms/Gateway'
import { Modal } from 'components/molecules/Modal'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import { BasicModalInside } from 'app/pages/Dashboard/Goals/components/BasicModalInside'

const PortfolioOpenedModal = withRouter(
  ({ portfolioId, type, location: { query: { portfolioOpened, ...restQuery } = {} } = {} }) => {
    const open = portfolioOpened === 'true'
    const portfolio = useSelector((state) => state.portfolios.list.get(portfolioId))

    const handleClose = useCallback(() => {
      const query = { ...restQuery }

      if (portfolio?.isa?.transferring_in_process) {
        query.transferIsa = true
      }

      const nextUrl = urlTo(`dashboard.portfolio`, { id: portfolioId }, query)
      const goToOptions = { replace: true, scrollToTop: false }

      return goTo(nextUrl, goToOptions)
    }, [portfolioId, portfolio, restQuery])

    const content = useMemo(() => {
      if (type === 'diy') {
        return {
          iconType: 'piechart-104',
          iconSize: 104,
          iconColor: palette['primary-default'],
          title: "You're ready to start investing!",
          description: `You've chosen your ETFs and set your portfolio weights. Now just add cash and place your first order — or enable AutoInvest to automate your investing.`,
          onClose: handleClose,
        }
      }

      return {
        iconType: 'checkmark_in_a_circle',
        iconSize: 54,
        iconColor: palette['status-success'],
        title: 'Your portfolio has been created',
        description: 'Click continue to confirm and add cash',
        onClose: handleClose,
      }
    }, [type, handleClose])

    return (
      <Gateway into="modals">
        <Modal open={open} onClose={handleClose} data-test-id="portfolioOpenedModal">
          <AllCenter>
            <BasicModalInside {...content} />
          </AllCenter>
        </Modal>
      </Gateway>
    )
  },
)

PortfolioOpenedModal.propTypes = {
  portfolioId: PropTypes.number,
  type: PropTypes.oneOf(['diy', 'managed']),
  location: PropTypes.shape({
    query: PropTypes.shape({
      portfolioOpened: PropTypes.oneOf([undefined, 'true']),
    }),
  }),
}

export { PortfolioOpenedModal }
