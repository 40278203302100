import React from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries, useState, useCallback, useEffect, useMemo } from 'hooks'

import moment from 'helpers/date.js'
import { bulkValidate } from 'helpers/validation.js'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'

import { DateRangeInputs, getDateRangeInputsDefaultValidation } from 'components/molecules/DateRangeInputs'

import { FilterDropdown, type FilterDropdownProps } from '../FilterDropdown'

import './DateRangeDropdown.css'

const DateRangeDropdown = withRouter((props: FilterDropdownProps): React.ReactElement => {
  const { onApply, open, className, location, onClose } = props
  const { desktop } = useMediaQueries()
  const [dateFrom, setDateFrom] = useState<string | null>(location?.query?.date_from ?? null)
  const [dateTo, setDateTo] = useState<string | null>(location?.query?.date_to ?? null)
  const [showDateRange, setShowDateRange] = useState(false)

  const handleDateFrom = useCallback(({ target }) => {
    setDateFrom(target.value)
  }, [])

  const handleDateTo = useCallback(({ target }) => {
    setDateTo(target.value)
  }, [])

  const handleApply = useCallback(
    (event, value) => {
      if (value?.[0] === 'custom') {
        setShowDateRange(true)
        return
      }
      onApply(event, value)
    },
    [onApply],
  )

  const handleSubmitDateRange = useCallback(
    (event) => {
      event.preventDefault()
      onApply(event, [dateFrom, dateTo])
    },
    [onApply, dateFrom, dateTo],
  )

  useEffect(() => {
    if (open && location?.query?.date_from) {
      setDateFrom(location?.query?.date_from ?? null)
      setDateTo(location?.query?.date_to ?? null)
      setShowDateRange(true)
      return
    }
    setDateFrom(null)
    setDateTo(moment().format('YYYY-MM-DD'))
    setShowDateRange(false)
  }, [open, location?.query?.date_from, location?.query?.date_to])

  const validation = useMemo(() => getDateRangeInputsDefaultValidation(dateFrom, dateTo), [dateFrom, dateTo])

  const isSubmitDisabled = !bulkValidate(validation)

  if (showDateRange) {
    const inputs = (
      <Width size={desktop ? 20 : null}>
        <DateRangeInputs
          dateFrom={dateFrom}
          dateTo={dateTo}
          handleDateFrom={handleDateFrom}
          handleDateTo={handleDateTo}
          validation={validation}
        />
      </Width>
    )

    return (
      <form onSubmit={handleSubmitDateRange}>
        {desktop ? (
          <Width className={className} size={23} hidden={!open} aria-hidden={!open}>
            <Paper top={40} bottom={20} left={24} right={24}>
              <Icon
                className="DateRangeDropdown-Close"
                type="cross-24"
                size={24}
                style={{ pointerEvents: 'auto' }}
                onClick={onClose}
              />
              {inputs}
              <Paper top={20}>
                <Button
                  mods={{ size: 'new-big block' }}
                  disabled={isSubmitDisabled}
                  data-test-id="DateRangeApplyButton"
                >
                  Apply
                </Button>
              </Paper>
            </Paper>
          </Width>
        ) : (
          <MobileLayout
            header={
              <NavigationBar rightPartText="Close" onRightPartClick={onClose}>
                <Typo>Select time period</Typo>
              </NavigationBar>
            }
            content={inputs}
            footer={
              <MobileLayoutFooterButton>
                <Button
                  mods={{ size: 'new-big block' }}
                  disabled={isSubmitDisabled}
                  data-test-id="DateRangeApplyButton"
                >
                  Apply
                </Button>
              </MobileLayoutFooterButton>
            }
            contentPaperSizes={{ top: 32, bottom: 16, left: 16, right: 16 }}
          />
        )}
      </form>
    )
  }
  return <FilterDropdown {...props} onApply={handleApply} />
})

export { DateRangeDropdown }
