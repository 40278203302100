import React, { Fragment } from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { format } from 'helpers/date'
import { palette } from 'helpers/palette'

import { type TransferProgress } from 'app/effector/isa-transfer/models/TransferProgress'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { Gateway } from 'components/atoms/Gateway'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

import './AltusProgressBarModal.css'

type AltusProgressBarModalProps = {
  altusProgressBarData: TransferProgress
  isOpen: boolean
  onClose: () => void
  'data-test-id'?: string
}

const AltusProgressBarModal = ({
  altusProgressBarData,
  isOpen,
  onClose: handleClose,
  'data-test-id': dataTestId,
}: AltusProgressBarModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const view = {
    TO_DO: {
      color: 'accent-default',
      icon: <div className="AltusProgressBarModal-DefaultStatusIcon" />,
      card: false,
    },
    IN_PROGRESS: {
      color: 'accent-default',
      icon: <div className="AltusProgressBarModal-DefaultStatusIcon AltusProgressBarModal-DefaultStatusIcon_active" />,
      card: true,
    },
    DONE: {
      color: 'secondary-default',
      icon: 'success-24',
      card: false,
    },
    ERROR: {
      color: 'status-warning',
      icon: 'attention-24',
      card: true,
    },
  }

  return (
    <Gateway into="modals">
      <Modal
        open={isOpen}
        onClose={() => {
          handleClose()
        }}
        close={null}
        data-test-id={dataTestId}
      >
        <Width size={desktop && 36} center={desktop}>
          <OptionsNavigationBar
            leftPartText="Back"
            onLeftPartClick={() => {
              handleClose()
            }}
            rightPartText="Close"
            onRightPartClick={() => {
              handleClose()
            }}
          >
            ISA Transfer progress
          </OptionsNavigationBar>
          <Paper top={40} right={16} bottom={96} left={16}>
            <Width size={27} center>
              <Typography
                size={32}
                lineHeight="small"
                weight="bold"
                align={desktop && 'center'}
                data-test-id={dataTestId ? `${dataTestId}ProviderName` : undefined}
              >
                <Typo>{altusProgressBarData.provider_name}</Typo>
              </Typography>
              <Paper top={8}>
                <Typography
                  align={desktop && 'center'}
                  data-test-id={dataTestId ? `${dataTestId}AccountNumber` : undefined}
                >
                  <Typo>{altusProgressBarData.account_number}</Typo>
                </Typography>
              </Paper>
              <Paper top={48}>
                {altusProgressBarData.steps.map((step, index) => {
                  const viewState = view[step.state]

                  let description: string | null = null

                  if (step.description) {
                    description = step.description
                  }

                  if (step.error && step.state === 'ERROR') {
                    description = step.error
                  }

                  if (step.short_name === 'Completion' && altusProgressBarData.estimated_completion_date) {
                    description = description ? `${description}\n\n` : ''
                    description = `${description}Estimated: ${format(altusProgressBarData.estimated_completion_date)}`
                  }

                  const stepContent = (
                    <Fragment>
                      <ItemWithIcon
                        icon={
                          typeof viewState.icon === 'string' ? (
                            <Icon
                              className="AltusProgressBarModal-StatusIcon"
                              type={viewState.icon}
                              size={24}
                              color={palette[viewState.color]}
                            />
                          ) : (
                            viewState.icon
                          )
                        }
                        content={
                          <Typography weight={viewState.card ? 'bold' : 'regular'}>
                            <Typo>{step.name}</Typo>
                          </Typography>
                        }
                        space={8}
                        iconVerticalAlign="center"
                        contentVerticalAlign="center"
                      />
                      {!altusProgressBarData?.isFullyCompleted() && description && (
                        <Paper
                          className="AltusProgressBarModal-StepDescription"
                          top={8}
                          data-test-id={dataTestId ? `${dataTestId}StepDescription` : undefined}
                        >
                          <Typography size={14}>
                            <Typo multiline>{description}</Typo>
                          </Typography>
                        </Paper>
                      )}
                    </Fragment>
                  )

                  return (
                    <Paper
                      key={index}
                      className={classNames('AltusProgressBarModal-Step', {
                        'AltusProgressBarModal-Step_card': viewState.card,
                        'AltusProgressBarModal-Step_active': step.state === 'IN_PROGRESS',
                        'AltusProgressBarModal-Step_completed': step.state === 'DONE',
                        'AltusProgressBarModal-Step_warning': step.state === 'ERROR',
                      })}
                      bottom={index === altusProgressBarData.steps.length - 1 ? 0 : 32}
                      data-test-id={dataTestId ? `${dataTestId}Step${index}` : undefined}
                    >
                      {viewState.card ? (
                        <Card borderWidth={2} borderRadius={10} color={viewState.color}>
                          <Paper top={16} right={16} bottom={16} left={16}>
                            {stepContent}
                          </Paper>
                        </Card>
                      ) : (
                        <Paper className="AltusProgressBarModal-StepContent" right={16} left={16}>
                          {stepContent}
                        </Paper>
                      )}
                      {index !== altusProgressBarData.steps.length - 1 && (
                        <div
                          className={classNames('AltusProgressBarModal-Pathway', {
                            'AltusProgressBarModal-Pathway_completed': step.state === 'DONE',
                          })}
                        />
                      )}
                    </Paper>
                  )
                })}
              </Paper>
              <Paper top={48}>
                <Card borderWidth={2} borderRadius={10} color="primary-surface-10">
                  <Paper top={20} right={20} bottom={20} left={20}>
                    <ItemWithIcon
                      space={8}
                      icon={<Icon className="AltusProgressBarModal-InformationIcon" type="information-16" size={16} />}
                      content={
                        <Typography size={14}>
                          <Typo>Cash and encashed assets will be transferred to ISA Cash</Typo>
                        </Typography>
                      }
                      data-test-id={dataTestId ? `${dataTestId}CashNotice` : undefined}
                    />
                  </Paper>
                </Card>
              </Paper>
            </Width>
          </Paper>
        </Width>
      </Modal>
    </Gateway>
  )
}

export { AltusProgressBarModal }
