import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { Modal } from 'components/molecules/Modal'
import { NavigationBar } from 'components/atoms/NavigationBar'
import Width from 'components/_old/Width/Width'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Headline from 'components/_old/Headline/Headline.jsx'
import Segment from 'components/atoms/Segment/Segment.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Gateway } from 'components/atoms/Gateway'
import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import './TaxInfoModal.css'

const TaxInfoModal = ({ open, onClose: handleClose, onContinue: handleContinue }) => {
  const { desktop } = useMediaQueries()

  return (
    <Gateway into="modals">
      <Modal open={open} onClose={handleClose} insideGatewayName="taxInfoModal" data-test-id="taxInfoModal">
        {!desktop && <NavigationBar onLeftPartClick={handleClose} leftPartText="Close" />}
        <Width size={65}>
          <Width size={40} center>
            <Segment mods={{ margin: desktop ? 'double' : 'bigger' }}>
              <Inner>
                <Headline level={desktop ? 1 : 2} mods={{ text: 'center' }}>
                  <Typo>Your tax residence</Typo>
                </Headline>
                <Segment mods={{ margin: desktop ? 'bigger' : 'double', noMargin: 'top' }}>
                  <Width size={!desktop ? 20 : null} center>
                    <Text block center>
                      <Typo>
                        You have told us you are not a UK tax resident. If this is the case, you can only open a general
                        investment account. Please go back to correct your tax residence or continue to complete your
                        personal information.
                      </Typo>
                    </Text>
                  </Width>
                </Segment>
                <Segment mods={{ margin: 'hefty', noMargin: 'top' }}>
                  <ModalContentButtons
                    className="TaxInfo-Buttons"
                    width={desktop ? 20 : 24}
                    targetGatewayName="taxInfoModal"
                    isNewModal
                  >
                    <ColumnarLayout flexDirection="column" mods={{ padding: 'nano' }}>
                      <Button mods={{ size: 'big block' }} onClick={handleClose} data-test-id="taxInfoModalGoBack">
                        Go back
                      </Button>
                      <Button
                        mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
                        onClick={handleContinue}
                        data-test-id="taxInfoModalContinue"
                      >
                        Continue
                      </Button>
                    </ColumnarLayout>
                  </ModalContentButtons>
                </Segment>
              </Inner>
            </Segment>
          </Width>
        </Width>
      </Modal>
    </Gateway>
  )
}

TaxInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
}

export { TaxInfoModal }
