import React from 'react'

import { useMemo } from 'hooks'

import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'

type SecurityChartTabsProps = {
  activeChartTab: 'totalReturns' | 'priceReturns'
  setActiveChartTab: (nextActiveChartTab: 'totalReturns' | 'priceReturns') => void
}

const SecurityChartTabs = ({ activeChartTab, setActiveChartTab }: SecurityChartTabsProps): React.ReactElement => {
  const tabs = useMemo(
    () => [
      { title: 'Total return', id: 'totalReturns', isActive: activeChartTab === 'totalReturns' },
      { title: 'Price return', id: 'priceReturns', isActive: activeChartTab === 'priceReturns' },
    ],
    [activeChartTab],
  )

  const handleChange = (tab): void => {
    setActiveChartTab(tab.id)
  }

  return <GreyTabs tabs={tabs} onChange={handleChange} />
}

export { SecurityChartTabs }
