import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

type PortfolioUnavailableInterceptorProps = {
  isOpen: boolean
  handleClose: () => void
  variant: 'managed' | 'lifeplans'
  hasContinueToDiy?: boolean
  onClick?: (...args: any) => void
  'data-test-id'?: string
}

const PortfolioUnavailableModal = ({
  isOpen,
  onClick,
  handleClose,
  variant,
  hasContinueToDiy,
  'data-test-id': dataTestId,
}: PortfolioUnavailableInterceptorProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const closeButtonNode = hasContinueToDiy ? <Typography color="inherit">Close</Typography> : null

  const titleNode = (
    <Typography size={32} lineHeight="small" weight="semibold" align="center" data-test-id={`${dataTestId}Title`}>
      <Typo>New {variant === 'managed' ? 'Managed' : 'LifePlan'} portfolios are temporarily unavailable</Typo>
    </Typography>
  )

  const textNode = (
    <Typography>
      <Typo>
        We are currently making improvements to our{' '}
        {variant === 'managed' ? 'portfolio building questionnaire' : 'LifePlan service'}.
        <br />
        <br />
        Existing portfolio holders and ongoing expert management of our portfolios is unaffected.
        <br />
        <br />
        {hasContinueToDiy && "Click on 'Continue to DIY portfolio' to explore our DIY ETF range."}
      </Typo>
    </Typography>
  )

  const closeButton = hasContinueToDiy ? (
    <Button mods={{ theme: 'simple-blue', size: 'big block' }} onClick={onClick} data-test-id="continueButton">
      Continue to DIY portfolio
    </Button>
  ) : (
    <Button mods={{ theme: 'simple-reverse-blue', size: 'big block' }} onClick={handleClose} data-test-id="closeButton">
      Close
    </Button>
  )

  if (desktop) {
    return (
      <Modal open={isOpen} close={closeButtonNode} onClose={handleClose}>
        <Width size={36}>
          <Paper top={96} bottom={64} right={64} left={64} data-test-id={dataTestId}>
            <AllCenter>
              <Icon type="information-54" />
            </AllCenter>
            <Paper top={16}>{titleNode}</Paper>
            <Paper top={24}>{textNode}</Paper>
            <Paper top={40}>{closeButton}</Paper>
          </Paper>
        </Width>
      </Modal>
    )
  }

  return (
    <Modal open={isOpen} onClose={handleClose} close={closeButtonNode}>
      <MobileLayout
        content={
          <Fragment>
            <Paper top={48}>
              <AllCenter>
                <Icon type="information-54" />
              </AllCenter>
            </Paper>
            <Paper top={8}>{titleNode}</Paper>
            <Paper top={24}>{textNode}</Paper>
          </Fragment>
        }
        footer={<MobileLayoutFooterButton>{closeButton}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
}

export { PortfolioUnavailableModal }
