import DOMPurify from 'dompurify'

function sanitizeString(input: string, options?: DOMPurify.Config): string {
  return DOMPurify.sanitize(input, {
    ...options,
    RETURN_DOM: false,
    RETURN_DOM_FRAGMENT: false,
    FORBID_ATTR: ['style'],
  })
}

export { sanitizeString }
