import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import capitalize from 'lodash/capitalize'

import { palette } from 'helpers/palette/'

import Icon from 'components/_old/Icon/Icon.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Text from 'components/_old/Text/Text.jsx'

import './Term.css'

const Term = ({ title, time_period, goals = [] }) => (
  <div className="Term">
    <div className="Term-Core">
      <div className="Term-Info">
        <Headline
          className="Term-Headline"
          level={2}
          mods={{ 'no-margin': 'top bottom', text: goals.length < 1 ? 'center normal' : 'normal' }}
        >
          <Typo>{title}</Typo>
        </Headline>
        <div className={classNames('Term-TimePeriod', { 'Term-TimePeriod_center': goals.length < 1 })}>
          <Icon className="Term-Icon" type="clock" color={palette['status-success']} size={32} inline />
          <Text center small color="black" className="Term-TimeGap">
            <Typo>{time_period}</Typo>
          </Text>
        </div>
        {goals.length > 0 && (
          <div className="Term-Tags">
            <div className="Term-Label">For example:</div>
            <div className="Term-TagsHolder">
              <Typo>
                {goals.map((goal, i) => (
                  <span className="Term-Tag" key={i}>
                    {i === 0 ? capitalize(goal) : (goal || '').toLowerCase()}
                  </span>
                ))}
              </Typo>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
)

Term.propTypes = {
  title: PropTypes.string,
  time_period: PropTypes.string,
  goals: PropTypes.arrayOf(PropTypes.string),
}

export { Term }
