const modifyApi = ({ request }) => {
  if (!request) {
    return
  }

  if (!/^https?/.test(request.baseURL)) {
    request.baseURL = `${window.location.origin}${request.baseURL}`
  }

  if (request.baseURL) {
    request.withCredentials = true
  }

  if (!/\/$/.test(request.url) && !/\/\?.+$/.test(request.url)) {
    request.url += '/'
  }
}

export { modifyApi }
