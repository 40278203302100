import React from 'react'
import { palette } from 'helpers/palette/'
import Toast, { Stack as ToastStack } from 'components/_old/Toast/Toast.jsx'
import Width from 'components/_old/Width/Width'
import MergeIcons from 'components/_old/MergeIcons/MergeIcons.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

import './OfflineNotification.css'

export default function OfflineNotification({ visible }) {
  return visible ? (
    <div className="OfflineNotification">
      <ToastStack into="offline">
        <Toast style="fail">
          <div className="OfflineNotification-Inner">
            <div className="OfflineNotification-Icon">
              <Width size={1.25}>
                <MergeIcons size={1.25}>
                  <Icon type="triangle" color={palette['background-default']} fluid inline />
                  <Icon
                    style={{ marginTop: '0.0325em' }}
                    type="exclamation"
                    color={palette['status-error']}
                    size={20}
                    inline
                  />
                </MergeIcons>
              </Width>
            </div>
            <Text className="OfflineNotification-Text">
              We can't reach our&nbsp;network right&nbsp;now. Please check your internet connection
              or&nbsp;try&nbsp;again&nbsp;later
            </Text>
          </div>
        </Toast>
      </ToastStack>
    </div>
  ) : null
}
