const colors = [
  '#009900',
  '#0099FF',
  '#FF3366',
  '#FFB23E',
  '#CC3399',
  '#00CCCC',
  '#FF99FF',
  '#6633FF',
  '#FF0000',
  '#009999',
  '#66CC00',
  '#006699',
  '#996633',
  '#66FFCC',
  '#663399',
  '#FFCC00',
  '#663300',
  '#FFCCCC',
  '#99CCFF',
  '#336666',
  '#FF33FF',
  '#003399',
  '#66CC66',
  '#FF6666',
  '#9999FF',
  '#990066',
  '#00FFFF',
  '#00CC33',
  '#9933FF',
  '#FF8F44',
  '#3366FF',
  '#CC3333',
  '#CC9966',
  '#003366',
  '#FFAD84',
  '#999933',
  '#00CCFF',
  '#CC3300',
  '#330066',
  '#CC9999',
  '#CCCC00',
  '#FF6430',
  '#0033CC',
  '#CC99CC',
  '#FFCC66',
  '#9999FF',
  '#990000',
  '#F4F433',
  '#004646',
  '#CC9900',
]

export { colors }
