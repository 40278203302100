import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { showFailToast } from 'app/redux/actions/ui'

import Header from 'components/_old/Header/Header.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { TwoFactorAwait } from 'components/_old/TwoFactorAwait/TwoFactorAwait'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'

import { TwoFactorSetupLayout } from '../components/TwoFactorSetupLayout'

const AwaitingPushStep = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const headerNode = <Header hideDrawer logoLink="/" hard withShadow />

  const handleBack = (): void => {
    goTo(urlTo('two-factor-auth-setup'))
  }

  const handleSuccess = (): void => {
    goTo(urlTo('two-factor-auth-setup.recovery-method'))
  }

  const handleFailure = (): void => {
    showFailToast()
  }

  const navbarNode = (
    <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
      <Typography size={20} lineHeight="small" weight="semibold">
        <Typo>Step 2 of 4</Typo>
      </Typography>
    </NavigationBar>
  )

  const content = (
    <Fragment>
      <TwoFactorAwait showTopBar={false} onAccept={handleSuccess} onDeny={handleFailure} shouldVerifySms={false} />
    </Fragment>
  )

  return (
    <TwoFactorSetupLayout>
      {desktop ? (
        <DesktopLayout
          header={headerNode}
          content={
            <Inner twoColumns>
              {navbarNode}
              <Paper>
                <Width size={45} center>
                  {content}
                </Width>
              </Paper>
            </Inner>
          }
          footer={<DesktopFooter />}
        />
      ) : (
        <MobileLayout
          header={navbarNode}
          content={
            <Paper top={8} bottom={56}>
              {content}
            </Paper>
          }
        />
      )}
    </TwoFactorSetupLayout>
  )
}

export { AwaitingPushStep }
