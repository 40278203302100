import compose from 'helpers/compose.js'

import { BasePortfolio } from './BasePortfolio/BasePortfolio.js'
import { CashMixin } from './ManageType/Cash/CashMixin'
import { DiyMixin } from './ManageType/Diy/DiyMixin.js'
import { ManagedMixin } from './ManageType/Managed/ManagedMixin.js'
import { GrowthMixin } from './PresetType/Growth/GrowthMixin.js'
import { IncomeMixin } from './PresetType/Income/IncomeMixin.js'
import { GiaMixin } from './RegulatoryType/Gia/GiaMixin.js'
import { IsaMixin } from './RegulatoryType/Isa/IsaMixin.js'
import { SippMixin } from './RegulatoryType/Sipp/SippMixin'

const ManagedGrowthGiaPortfolioBehaviors = compose(ManagedMixin, GrowthMixin, GiaMixin)(BasePortfolio)
class ManagedGrowthGiaPortfolio extends ManagedGrowthGiaPortfolioBehaviors {}

const ManagedGrowthIsaPortfolioBehaviors = compose(ManagedMixin, GrowthMixin, IsaMixin)(BasePortfolio)
class ManagedGrowthIsaPortfolio extends ManagedGrowthIsaPortfolioBehaviors {}

const ManagedGrowthSippPortfolioBehaviors = compose(ManagedMixin, GrowthMixin, SippMixin)(BasePortfolio)
class ManagedGrowthSippPortfolio extends ManagedGrowthSippPortfolioBehaviors {}

const ManagedIncomeGiaPortfolioBehaviors = compose(ManagedMixin, IncomeMixin, GiaMixin)(BasePortfolio)
class ManagedIncomeGiaPortfolio extends ManagedIncomeGiaPortfolioBehaviors {}

const ManagedIncomeIsaPortfolioBehaviors = compose(ManagedMixin, IncomeMixin, IsaMixin)(BasePortfolio)
class ManagedIncomeIsaPortfolio extends ManagedIncomeIsaPortfolioBehaviors {}

const ManagedIncomeSippPortfolioBehaviors = compose(ManagedMixin, IncomeMixin, SippMixin)(BasePortfolio)
class ManagedIncomeSippPortfolio extends ManagedIncomeSippPortfolioBehaviors {}

const DiyGiaPortfolioBehaviors = compose(DiyMixin, GiaMixin)(BasePortfolio)
class DiyGiaPortfolio extends DiyGiaPortfolioBehaviors {}

const DiyIsaPortfolioBehaviors = compose(DiyMixin, IsaMixin)(BasePortfolio)
class DiyIsaPortfolio extends DiyIsaPortfolioBehaviors {}

const DiySippPortfolioBehaviors = compose(DiyMixin, SippMixin)(BasePortfolio)
class DiySippPortfolio extends DiySippPortfolioBehaviors {}

const CashGiaPortfolioBehaviors = compose(CashMixin, GiaMixin)(BasePortfolio)
class CashGiaPortfolio extends CashGiaPortfolioBehaviors {}

const CashIsaPortfolioBehaviors = compose(CashMixin, IsaMixin)(BasePortfolio)
class CashIsaPortfolio extends CashIsaPortfolioBehaviors {}

const CashSippPortfolioBehaviors = compose(CashMixin, SippMixin)(BasePortfolio)
class CashSippPortfolio extends CashSippPortfolioBehaviors {}

export {
  ManagedGrowthGiaPortfolio,
  ManagedGrowthIsaPortfolio,
  ManagedGrowthSippPortfolio,
  ManagedIncomeGiaPortfolio,
  ManagedIncomeIsaPortfolio,
  ManagedIncomeSippPortfolio,
  DiyGiaPortfolio,
  DiyIsaPortfolio,
  DiySippPortfolio,
  CashGiaPortfolio,
  CashIsaPortfolio,
  CashSippPortfolio,
}
