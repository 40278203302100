import React from 'react'
import PropTypes from 'prop-types'

import { format as formatPhone } from 'helpers/phone.js'

import { useUnit } from 'effector-react'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $owner } from 'app/effector/contacts'

import { FieldLineList } from 'components/_old/molecules/FieldLineList'
import { FieldLine } from 'components/_old/atoms/FieldLine'
import { Typo } from 'components/_old/Typo/Typo'

const PersonalInformation = ({ getFormattedAddress }) => {
  const owner = useUnit($owner) ?? {}
  const { bankAccounts } = useUnit($bankAccountsStore)
  const nominatedAccount = bankAccounts.find((account) => account.is_nominated) ?? {}
  const [address] = owner?.addresses.getCurrentAddress()

  return (
    <FieldLineList data-test-id="yearlyReviewInformation">
      <FieldLine label="Address">
        <Typo>{getFormattedAddress(address)}</Typo>
      </FieldLine>
      <FieldLine label="Name">
        <Typo>{`${owner.first_name || ''} ${owner.last_name || ''}`.trim()}</Typo>
      </FieldLine>
      <FieldLine label="Phone">
        <Typo>{formatPhone(owner.phone)}</Typo>
      </FieldLine>
      <FieldLine label="Email">
        <Typo>{owner.email}</Typo>
      </FieldLine>
      <FieldLine label="Bank sort code">
        <Typo>{nominatedAccount?.sort_code}</Typo>
      </FieldLine>
      <FieldLine label="Bank account number">
        <Typo>{nominatedAccount?.account_number}</Typo>
      </FieldLine>
    </FieldLineList>
  )
}

PersonalInformation.propTypes = {
  getFormattedAddress: PropTypes.func.isRequired,
}

export { PersonalInformation }
