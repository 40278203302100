import { type MutableRefObject } from 'react'

import { useUnit } from 'effector-react'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'

import { useCallback, useState, useEffect, useMemo, useRef, useDebounce } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import moment from 'helpers/date.js'
import { formatPercent } from 'helpers/money'
import { palette, isDarkTheme } from 'helpers/palette'
import { convertRecurringPaymentAmountToMonthly, convertMonthlyAmountToFrequency } from 'helpers/recurringPayments'
import { validate } from 'helpers/validation.js'

import { $recurringPaymentsStore } from 'app/effector/recurringPayments'

import { type RecurringPayment } from 'app/pages/Dashboard/Goals/RecurringPayment/types'

import type { ManagedPortfolio } from '../../../../types'

type Projections = {
  max_y: number
  min_y: number
  monthly: false
  data: Record<number, Array<{ x: number; y: number }>>
}

type PresetSecurity = {
  amount: number
  change: number
  description: string
  id: number
  isin: string
  modified: Date
  price_date: Date
  security_id: number
  title: string
  value: string
}

type Preset = {
  id: number
  title: string
  type: 'GROWTH' | 'INCOME'
  preset_stocks: PresetSecurity[]
  preset_bonds: PresetSecurity[]
  preset_alternatives: PresetSecurity[]
  preset_cash?: string
}

type useEditGrowthProjectionsArguments = {
  analyticsCategory: string
  portfolio: ManagedPortfolio
  maxYears: number
  minYears: number
  validation: object
  onChange: (field: string, value: string | null, registerChanges?: boolean) => void
  onSubmit: () => void
  presets: Preset[]
  projections: Projections
}

type useEditGrowthProjectionsData = {
  debouncedFields: { preset: number; term: number }
  yearsRange: string
  handleFieldChangeDebounced: (field: string, value: number | null) => void
  handleSubmit: () => void
  handleFieldChange: (field: string, value: string | null, registerChanges?: boolean) => void
  initial_deposit: number
  one_off_payment: number
  monthlyInvestment: number
  preset_recommended: number
  target: string
  targetEditable: boolean
  setTargetEditable: (value: boolean) => void
  isPortfolioActive: boolean
  riskStatData: Array<{ label: string; color: string; value: string }>
  chartRef: MutableRefObject<undefined>
  shouldShowScrollToKey: string
  setShouldShowScrollToKey: (value: string) => void
  endYear: number
  endData?: Dictionary<{ color: string; key: string; value: number }>
  data: Array<{ color: string; key: string; values: Array<{ x: number; y: number }> }>
  recurringPayment?: RecurringPayment
}

const useEditGrowthProjections = ({
  portfolio,
  analyticsCategory,
  maxYears,
  minYears,
  validation,
  onChange,
  onSubmit,
  presets,
  projections,
}: useEditGrowthProjectionsArguments): useEditGrowthProjectionsData => {
  const { getReccuringPaymentByPortfolioId } = useUnit($recurringPaymentsStore)
  const recurringPayment = getReccuringPaymentByPortfolioId(portfolio.id)

  const trackEventDebounced = debounce(trackEvent, 400)

  const sendAnalytics = useCallback(
    (field) => {
      if (analyticsCategory) {
        const actionMap = {
          initial_deposit: 'Project. tool: Initial investment changed',
          one_off_payment: 'Project. tool: One-off payment changed',
          monthly_deposit: 'Project. tool: Monthly investment set',
          preset: 'Project. tool: Model changed',
          term: 'Project. tool: Term changed',
          target: 'Project. tool: Target set',
          submit: 'Project. tool: Save clicked',
        }

        const action = actionMap[field]

        trackEventDebounced({
          category: analyticsCategory,
          action,
        })
      }
    },
    [analyticsCategory, trackEventDebounced],
  )

  const [shouldShowScrollToKey, setShouldShowScrollToKey] = useState('')
  const chartRef = useRef()

  /* eslint-disable @typescript-eslint/naming-convention */
  let {
    target,
    initial_deposit,
    one_off_payment,
    monthly_deposit,
    monthly_deposit_recommended,
    term,
    preset,
    preset_recommended,
  } = portfolio

  /* eslint-enable @typescript-eslint/naming-convention */

  const [targetEditable, setTargetEditable] = useState(parseFloat(target) > 0)

  const isPortfolioActive = portfolio.first_topup || parseInt(portfolio.current_balance, 10) > 0

  // local state for risk field and time period field
  const [debouncedFields, setDebouncedFields] = useState({
    term,
    preset,
  })

  const monthlyInvestment = useMemo(() => {
    if (recurringPayment) {
      return convertMonthlyAmountToFrequency(monthly_deposit, recurringPayment.frequency)
    }

    if (typeof monthly_deposit === 'number' || typeof monthly_deposit === 'string') {
      return monthly_deposit
    }

    if (monthly_deposit_recommended > 0) {
      return monthly_deposit_recommended
    }
    return null
  }, [monthly_deposit, monthly_deposit_recommended, recurringPayment])

  const handleFieldChange = useCallback(
    (field, value = null, registerChanges = true) => {
      if (['initial_deposit', 'one_off_payment', 'monthly_deposit', 'target'].includes(field)) {
        value = value !== '' ? value : null
      }

      if (recurringPayment && field === 'monthly_deposit') {
        if (value) {
          value = convertRecurringPaymentAmountToMonthly(Number(value), recurringPayment.frequency)
        }
      }

      onChange(field, value, registerChanges)

      if (registerChanges) {
        setShouldShowScrollToKey(JSON.stringify({ [field]: value }))
        sendAnalytics(field)
      }
    },
    [onChange, recurringPayment, sendAnalytics],
  )

  const initMonthlyInvestment = useCallback(() => {
    handleFieldChange('monthly_deposit', monthlyInvestment, false)
  }, [monthlyInvestment, handleFieldChange])

  const handleFieldChangeDebounced = useCallback(
    (field, value = null) => {
      setShouldShowScrollToKey(JSON.stringify({ [field]: value }))
      setDebouncedFields({ ...debouncedFields, [field]: value })
    },
    [debouncedFields, setDebouncedFields],
  )

  // mount
  useEffect(() => {
    initMonthlyInvestment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = useCallback(() => {
    sendAnalytics('submit')

    onSubmit?.()
  }, [onSubmit, sendAnalytics])

  useDebounce(
    () => {
      if (portfolio.preset === debouncedFields.preset) return

      const selectedPreset = find(presets, { id: debouncedFields.preset })

      if (selectedPreset) {
        ;['preset_stocks', 'preset_bonds', 'preset_alternatives'].forEach((instrument) => {
          onChange(`goal_${instrument}`, null, selectedPreset[`preset_${instrument}`])
        })
      }
      onChange('preset', debouncedFields.preset)
      sendAnalytics('preset')
    },
    250,
    [debouncedFields.preset, onChange, sendAnalytics],
  )

  useDebounce(
    () => {
      if (portfolio.term === debouncedFields.term) return

      onChange('term', debouncedFields.term)
      sendAnalytics('term')
    },
    250,
    [debouncedFields.term, onChange, sendAnalytics],
  )

  const riskStatData = useMemo(() => {
    const getValue = (assets): number => {
      const amount =
        assets.length > 1
          ? assets.reduce(
              (memo, item) =>
                typeof memo === 'number'
                  ? (memo || 0) + (item ? item.amount : 0)
                  : (memo ? memo.amount : 0) + (item ? item.amount : 0),
              0,
            )
          : assets?.[0]?.amount || 0

      return amount
    }

    if (presets && presets.length > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      preset = debouncedFields.preset || presets[0].id
      const presetObj = presets.find((presetItem) => presetItem.id === preset)

      let statsData = [
        {
          value: getValue(presetObj ? presetObj.preset_stocks || [] : []),
          label: 'Equities',
          color: 'stocks',
          'data-test-id': 'equitiesPercent',
        },
        {
          value: getValue(presetObj ? presetObj.preset_bonds || [] : []),
          label: 'Bonds',
          color: 'bonds',
          'data-test-id': 'bondsPercent',
        },
        {
          value: getValue(presetObj ? presetObj.preset_alternatives || [] : []),
          label: 'Alternatives',
          color: 'alternatives',
          'data-test-id': 'alternativesPercent',
        },
      ]

      // add cash to the largest asset
      if (presetObj?.preset_cash) {
        const cashValue = parseFloat(presetObj.preset_cash)
        const addCashTo =
          presetObj.preset_alternatives?.length > 0
            ? 'Alternatives'
            : presetObj.preset_bonds?.length > 0
              ? 'Bonds'
              : 'Equities'
        const addCashToIndex = statsData.findIndex((item) => item.label === addCashTo)

        statsData = [
          ...statsData.slice(0, addCashToIndex),
          { ...statsData[addCashToIndex], value: statsData[addCashToIndex].value + cashValue },
          ...statsData.slice(addCashToIndex + 1),
        ]
      }

      return statsData.map((item) => ({ ...item, value: formatPercent(item.value / 100, 1) }))
    }

    return []
  }, [presets, debouncedFields.preset])

  const years = Math.abs(term || 1)

  const mixin = {
    5: {
      color: isDarkTheme() ? '#89bae1' : '#8fafdf',
      area: true,
    },
    25: {
      color: isDarkTheme() ? '#cfffc5' : '#95d86d',
      area: true,
    },
    50: {
      color: isDarkTheme() ? '#cfffc5' : '#95d86d',
      area: true,
    },
    75: {
      color: isDarkTheme() ? '#b5b6b5' : '#c0c0c0',
      area: true,
    },
    95: {
      color: isDarkTheme() ? '#b5b6b5' : '#c0c0c0',
      area: true,
    },
    contributions: {
      color: isDarkTheme() ? '#ffffff' : palette['content-on-background-default'],
    },
  }

  let data: Array<{ color: string; key: string; values: Array<{ x: number; y: number }> }> = []

  if (projections?.data) {
    data = Object.keys(projections.data).map((k, i) => {
      const key = k
      let values = projections.data[key]
      const mix = mixin[key]
      const seriesIndex = i

      values = values.map((item) => {
        return {
          x: moment(new Date())
            .add(item.x, projections.monthly ? 'months' : 'years')
            .toDate(),
          y: item.y,
          series: i,
        }
      })

      return { key, values, seriesIndex, ...mix }
    })
  }

  if (validate(validation.target.rules) && target && data?.[5]) {
    const period = data[5].values.length - 1
    data.push({
      color: palette['status-error'],
      key: 'target',
      values: [
        {
          x: new Date(),
          y: target,
        },
        {
          x: moment(new Date())
            .add(projections?.monthly ? period / 12 : period, 'years')
            .toDate(),
          y: target,
        },
      ],
    })
  }

  const yearsRange = Array.apply(null, Array(maxYears - minYears + 1)).map((_, i) => ({ term: i + minYears }))

  const endYear = Number(moment(new Date()).format('YYYY')) + years
  const endData = keyBy(
    data.map((dataObj) => {
      if (dataObj?.values) {
        return {
          color: dataObj.color,
          key: dataObj.key,
          value: dataObj.values[dataObj.values.length - 1].y,
        }
      }

      return null
    }),
    'key',
  )

  return {
    debouncedFields,
    yearsRange,
    handleFieldChangeDebounced,
    handleSubmit,
    handleFieldChange,
    initial_deposit,
    one_off_payment,
    monthlyInvestment,
    preset_recommended,
    target,
    targetEditable,
    setTargetEditable,
    isPortfolioActive,
    riskStatData,
    chartRef,
    shouldShowScrollToKey,
    setShouldShowScrollToKey,
    endYear,
    endData,
    data,
    recurringPayment,
  }
}

export { useEditGrowthProjections, convertRecurringPaymentAmountToMonthly }
