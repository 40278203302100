import { useLoading, useMemo, useState, useSelector, useActions, useCallback } from 'hooks'

import { querystringToObject } from 'helpers/ajax/querystring'
import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router'

import {
  selectGoal as selectGoalActionCreator,
  updateOrCreate as updateOrCreateGoalActionCreator,
} from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'

import { states as clientStates } from 'constants/client'
import { manageTypes, regulatoryTypes, states as portfolioStates } from 'constants/portfolio'

type UseOpenIsaLinkInterface = {
  isLoading: boolean
  isClientNew: boolean
  isNewClientIsaModalOpen: boolean
  handleLinkClick: () => void
  handleOpenNewClientIsaModal: () => void
  handleCloseNewClientIsaModal: () => void
  handleSubmitNewClientIsaModal: () => void
}

function useOpenIsaLink(): UseOpenIsaLinkInterface {
  const { isLoading, wait } = useLoading(false)
  const { openIsaId, ...query } = useMemo(() => querystringToObject(window.location.search), [])
  const [isNewClientIsaModalOpen, setNewClientIsaModalOpen] = useState(!!openIsaId)
  const client = useSelector((state: { client: { state: string } }) => state.client)
  const [selectGoal, updateOrCreateGoal] = useActions([selectGoalActionCreator, updateOrCreateGoalActionCreator])

  const isClientNew = client.state === clientStates.NEW

  const handleOpenNewClientIsaModal = useCallback(() => {
    setNewClientIsaModalOpen(true)
  }, [setNewClientIsaModalOpen])

  const handleCloseNewClientIsaModal = useCallback(() => {
    setNewClientIsaModalOpen(false)

    goTo(urlTo('dashboard', null, query), { replace: true })
  }, [setNewClientIsaModalOpen, query])

  const handleSubmitNewClientIsaModal = useCallback(() => {
    const createAndCompleteIsa = async (): Promise<void> => {
      try {
        await selectGoal(null)

        const stateAfterCreatePortfolio = await updateOrCreateGoal(
          ['manage_type', 'regulatory_type'],
          {
            manage_type: manageTypes.CASH,
            regulatory_type: regulatoryTypes.ISA,
          },
          false,
          undefined,
          false,
        )

        if (stateAfterCreatePortfolio.portfolios.error) {
          throw stateAfterCreatePortfolio.portfolios.error
        }

        const createdGoal = stateAfterCreatePortfolio.portfolios.items
          .filter(
            (goal) =>
              goal.state === portfolioStates.NEW &&
              goal.regulatory_type === regulatoryTypes.ISA &&
              goal.manage_type === manageTypes.CASH,
          )
          .at(-1)

        if (!createdGoal) {
          return
        }

        const stateAfterPortfolioCreated = await updateOrCreateGoal(
          ['completed'],
          { completed: true },
          false,
          createdGoal.id,
        )

        if (stateAfterPortfolioCreated.portfolios.error) {
          throw stateAfterPortfolioCreated.portfolios.error
        }

        if (openIsaId) {
          goTo(urlTo('dashboard.portfolio.finish', { id: openIsaId }, query))
          return
        }

        setNewClientIsaModalOpen(false)
      } catch (e) {
        showFailToast()
      }
    }

    wait(createAndCompleteIsa())
  }, [selectGoal, updateOrCreateGoal, openIsaId, query, wait])

  const handleLinkClick = useCallback(() => {
    trackEvent({ action: 'open_acc_clicked', regulatory_type: regulatoryTypes.ISA })

    if (isClientNew) {
      handleOpenNewClientIsaModal()
      return
    }

    goTo(urlTo('dashboard', null, { openIsa: true }))
  }, [isClientNew, handleOpenNewClientIsaModal])

  return {
    isLoading,
    isClientNew,
    isNewClientIsaModalOpen,
    handleLinkClick,
    handleOpenNewClientIsaModal,
    handleCloseNewClientIsaModal,
    handleSubmitNewClientIsaModal,
  }
}

export { useOpenIsaLink }
