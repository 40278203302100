import { AddressList } from 'app/redux/models/address'
import { Entity } from 'app/redux/models/common/Entity.js'

import { states } from 'constants/contacts.js'
import { ukId } from 'constants/countriesIds.js'

export class Contact extends Entity {
  constructor() {
    super()

    /** @type {number | null} */
    this.id = null
    this.email = null
    this.phone = null
    this.state = null
    this.role = null
    /** @type {AddressList} */
    this.addresses = new AddressList()
    this.nationalities = []

    this.first_name = ''
    this.middle_name = ''
    this.last_name = ''
    this.birthday = ''
    this.age = null
    this.gender = null

    this.document_passport_filled = false
    this.document_driving_licence_filled = false
    this.document_selfie_filled = false

    this.lock_personal_info = false
    this.lock_tax_info = false
    this.email_verified = null
    this.phone_verified = false

    this.country_of_tax_residence = null
    this.national_client_identifier = null
    this.national_insurance_number = null
    this.has_significant_control = false
    this.passport_number = null
  }

  /** @override */
  getRules() {
    return {
      addresses: [[(value) => AddressList.createFromObject({ list: value })]],
    }
  }

  /** @returns {boolean} */
  isProofOfIdentityFilled() {
    return (this.document_driving_licence_filled || this.document_passport_filled) && this.document_selfie_filled
  }

  /** @returns {string} */
  getDisplayName() {
    return this.first_name || 'Investor'
  }

  /** @returns {string} */
  getFullName() {
    return this.first_name ? `${this.first_name ?? ''} ${this.last_name ?? ''}`.trim() : 'Investor'
  }

  /** @returns {boolean} */
  isUkResidence() {
    return this.country_of_tax_residence === ukId
  }

  /** @returns {boolean} */
  isUnchecked() {
    return this.state === states.UNCHECKED
  }

  /** @returns {boolean} */
  isApproved() {
    return this.state === states.APPROVED
  }

  /** @returns {boolean} */
  isRefused() {
    return this.state === states.REFUSED
  }

  /** @returns {boolean} */
  isNeedRecheck() {
    return this.state === states.NEED_RECHECK
  }

  /** @returns {boolean} */
  isAvailable() {
    return this.isApproved() || this.isNeedRecheck()
  }
}
