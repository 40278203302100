import React, { type ReactElement } from 'react'

import { useMediaQueries } from 'hooks/index.js'

import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { LabelNative } from 'components/atoms/LabelNative/LabelNative'

type SettingsRowProps = {
  to: string
  labelText: string
  children: ReactElement
  'data-test-id': string
}

const SettingsRow = ({ to, labelText, children, 'data-test-id': dataTestId }: SettingsRowProps): ReactElement => {
  const { desktop } = useMediaQueries()

  const content = (
    <Link
      mods={
        !desktop
          ? {
              color: 'black',
            }
          : null
      }
      to={to}
      data-test-id={dataTestId}
      scrollToTop={false}
    >
      {children}
    </Link>
  )

  if (desktop) {
    return (
      <LabelInlineStyle noNbsp labelText={labelText} size="smaller">
        {content}
      </LabelInlineStyle>
    )
  }

  return <LabelNative>{content}</LabelNative>
}

export { SettingsRow }
