import React, { useEffect } from 'react'

import { useMediaQueries, useActions } from 'hooks'

import { fetchNotificationSettings as fetchNotificationSettingsActionCreator } from 'app/redux/actions/ui'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { LabelNative } from 'components/atoms/LabelNative/LabelNative'
import { Paper } from 'components/atoms/Paper'
import Switcher from 'components/atoms/Switcher/Switcher'
import { Typography } from 'components/atoms/Typography'

import { useInvestEngineNews } from './hooks'
import { InvestEngineNewsModal } from './Modal'

function InvestEngineNews(): React.ReactElement {
  const { desktop } = useMediaQueries()
  const { promo, isModalVisible, handleChangePromoNotificationSettings, handleCloseModal, handleContinueModal } =
    useInvestEngineNews()
  const [fetchNotificationSettings] = useActions([fetchNotificationSettingsActionCreator])

  useEffect(() => {
    fetchNotificationSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Width size={desktop ? 36 : null}>
      <LabelNative>
        <Link
          mods={{ color: 'black' }}
          onClick={handleChangePromoNotificationSettings}
          data-test-id="notificationChangeSwitcher"
        >
          <Switcher inverse multiline checked={promo} onChange={handleChangePromoNotificationSettings}>
            <Typography size={16} lineHeight="small" color="inherit">
              <Typo>InvestEngine updates</Typo>
            </Typography>
            <Paper top={4}>
              <Typography size={14} lineHeight="small" color="minor">
                <Typo>Get the latest news, offers, and announcements</Typo>
              </Typography>
            </Paper>
          </Switcher>
        </Link>
      </LabelNative>
      <InvestEngineNewsModal open={isModalVisible} onClose={handleCloseModal} onContinue={handleContinueModal} />
    </Width>
  )
}

export { InvestEngineNews }
