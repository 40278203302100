import { useActions, useCallback, useSelector, useState } from 'hooks'

import { resetCaptchaCounter as resetCaptchaCounterActionCreator } from 'app/redux/actions/client'
import { showFailToast } from 'app/redux/actions/ui'

type UseCaptchaProps = {
  handleSuccess: () => void
  loginIdentifier?: string
  clientId?: string
}

type UseCaptchaReturnProps = {
  validation: object
  handleCaptchaChange: (captcha: string) => void
}

const useCaptcha = ({ handleSuccess, clientId, loginIdentifier }: UseCaptchaProps): UseCaptchaReturnProps => {
  const [captcha, setCaptcha] = useState(null)
  const client = useSelector(({ client }) => client)
  const [resetCaptchaCounter] = useActions([resetCaptchaCounterActionCreator])

  const handleCaptchaChange = useCallback(
    (captcha) => {
      setCaptcha(captcha)

      if (captcha) {
        resetCaptchaCounter({
          loginIdentifier: loginIdentifier ?? client.email,
          captcha,
          client_id: clientId ?? client.id,
        })
          .then(() => {
            handleSuccess()
          })
          .catch(() => {
            showFailToast()
          })
      }
    },
    [handleSuccess, client, loginIdentifier, clientId, resetCaptchaCounter],
  )

  const validation = {
    captcha: { rules: [captcha], errors: ['Captcha shall be passed'] },
  }

  return {
    validation,
    handleCaptchaChange,
  }
}

export { useCaptcha }
