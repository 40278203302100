import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { formLinkToHost } from 'helpers/formLinkToHost.js'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { Typography } from 'components/atoms/Typography'

import './PaymentDetail.css'

const PaymentDetail = ({ sortCode, accountNumber }) => {
  const mediaQueries = useMediaQueries()
  const classes = classNames('PaymentDetail', getMediaQuieryClasses('PaymentDetail', mediaQueries))

  return (
    <div className={classes} data-test-id="paymentDetail">
      <div className="PaymentDetail-Table">
        <Typography className="PaymentDetail-TableRow" align="right" data-test-id="paymentDetailSortCode">
          <Typography
            tag="span"
            className="PaymentDetail-TableCell"
            color="additional"
            size={12}
            lineHeight="small"
            align="right"
          >
            Sort code
          </Typography>
          <Typography
            tag="span"
            className="PaymentDetail-TableCell"
            color="additional"
            size={16}
            lineHeight="small"
            align="right"
            numeric="tabular"
          >
            {sortCode}
          </Typography>
        </Typography>
        <Typography tag="span" className="PaymentDetail-TableRow" block right data-test-id="paymentDetailAccountNumber">
          <Typography
            tag="span"
            className="PaymentDetail-TableCell"
            color="additional"
            size={12}
            lineHeight="small"
            align="right"
          >
            Account number
          </Typography>
          <Typography
            tag="span"
            className="PaymentDetail-TableCell"
            color="additional"
            size={16}
            lineHeight="small"
            align="right"
            numeric="tabular"
          >
            {accountNumber}
          </Typography>
        </Typography>
      </div>
    </div>
  )
}

PaymentDetail.propTypes = {
  sortCode: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
}

const PaymentDetailLogo = ({ src, width = '44' }) => {
  const bankLogoClasses = classNames('PaymentDetail-BankLogo', { 'PaymentDetail-BankLogo_none': !src })

  return <div className={bankLogoClasses}>{src && <img src={formLinkToHost(src)} width={width} height="auto" />}</div>
}

PaymentDetailLogo.propTypes = {
  src: PropTypes.string,
  width: PropTypes.string,
}

export { PaymentDetail, PaymentDetailLogo }
