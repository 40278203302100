import { useMemo, useCallback } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { getKeyOrDefault } from 'helpers/object'
import { goTo, urlTo } from 'helpers/router.js'

import { resetSecurities } from 'app/effector/portfolio-securities'

import { states as portfolioStates } from 'constants/portfolio'

const useNavbar = (portfolio, mode, searchQuery = {}) => {
  const portfolioId = portfolio?.id

  const securitiesUrl = useMemo(
    () => urlTo('securities', null, querystringFromObject({ ...searchQuery, portfolioId, back: null })),
    [portfolioId, searchQuery],
  )
  const portfolioUrl = useMemo(() => urlTo('dashboard.portfolio', { id: portfolioId }), [portfolioId])

  const handleAddSecurities = useCallback(() => {
    goTo(securitiesUrl)
  }, [securitiesUrl])
  const handleCancel = useCallback(() => {
    if (searchQuery.fromUniverse === 'true') {
      goTo(urlTo('securities'))
      return
    }
    goTo(portfolio?.state === portfolioStates.NEW ? urlTo('dashboard') : portfolioUrl)

    if (portfolioId) {
      resetSecurities({ portfolioId })
    }
  }, [portfolioUrl, portfolioId, portfolio?.state, searchQuery?.fromUniverse])

  const handleBack = useCallback(() => {
    if (searchQuery?.back) {
      goTo(searchQuery.back)
      return
    }
    const call = getKeyOrDefault(
      {
        universe: handleAddSecurities,
        default: handleCancel,
      },
      mode,
    )

    call()
  }, [mode, handleAddSecurities, handleCancel, searchQuery?.back])

  return {
    handleAddSecurities,
    handleCancel,
    handleBack,
  }
}

export { useNavbar }
