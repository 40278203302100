import isArray from 'lodash/isArray.js'
import isEqual from 'lodash/isEqual.js'
import isObject from 'lodash/isObject.js'

const recurseGetPrimitivesFromProps = (props) => {
  const primitives = []

  const recurse = (item) => {
    if (item) {
      if (item?.props) {
        return recurse(item.props)
      }

      if (isArray(item)) {
        item.forEach(recurse)
        return
      }

      if (isObject(item)) {
        Object.values(item).forEach(recurse)
        return
      }

      if (typeof item === 'string') {
        primitives.push(item)
      }

      if (typeof item === 'number') {
        primitives.push(item)
      }

      if (typeof item === 'boolean') {
        primitives.push(item)
      }
    }
  }

  recurse(props)

  return primitives
}

const primitivePropsAreEqual = (prevProps, nextProps) =>
  isEqual(recurseGetPrimitivesFromProps(prevProps), recurseGetPrimitivesFromProps(nextProps))

export { primitivePropsAreEqual }
