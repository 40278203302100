import React from 'react'

import { useMediaQueries } from 'hooks'

import AttentionModalCheckbox from 'components/_old/AttentionModalCheckbox/AttentionModalCheckbox.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import AttentionModalText from 'components/molecules/AttentionModalText/AttentionModalText.jsx'
import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'

import AttentionModalBase from 'components/organisms/AttentionModal/AttentionModal.jsx'

type AttentionModalProps = {
  desiredIndex: number
  recommendedIndex: number
  portfolioChanged: boolean
  visible: boolean
  onSubmit: () => Promise<unknown>
  onClose: (submitDisabled: boolean) => void
}

const AttentionModal = ({
  desiredIndex,
  recommendedIndex,
  portfolioChanged = false,
  visible = false,
  onSubmit,
  onClose,
}: AttentionModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const increaseText = (
    <Paper bottom={16}>
      <Typography size={14}>
        <Typo>
          You have selected a portfolio that is more risky than the one we suggested based on your answers to the risk
          questionnaire. Although the current portfolio has higher expected returns it also has higher potential losses
          than the optimal portfolio.
        </Typo>
      </Typography>
    </Paper>
  )

  const decreaseText = (
    <Paper bottom={16}>
      <Typography size={14}>
        <Typo>
          You have selected a portfolio that is less risky than the one we suggested based on your answers to the risk
          questionnaire. Although the current portfolio has lower expected losses you might not be able to achieve your
          investment objectives.
        </Typo>
      </Typography>
    </Paper>
  )

  const portfolioChangedText = (
    <Paper bottom={16}>
      <Typography size={14}>
        <Typo>If you continue, the change request will be processed within 2 business days</Typo>
      </Typography>
    </Paper>
  )

  const checkboxText = (
    <Typography size={14}>
      <Typo>I understand the risk associated with this portfolio and am prepared to continue on this basis.</Typo>
    </Typography>
  )

  const renderSubmit = (disabled = false): React.ReactElement => (
    <StickedIfNotDesktop into="insideModal">
      <Button
        onClick={onSubmit}
        mods={{
          size: 'big block',
          theme: desktop ? null : 'not-rounded',
        }}
        disabled={disabled}
        data-test-id="attentionModalSubmit"
      >
        Confirm
      </Button>
    </StickedIfNotDesktop>
  )

  return (
    <AttentionModalCheckbox recommendedIndex={recommendedIndex} desiredIndex={desiredIndex} checkboxText={checkboxText}>
      {({ shouldShowCheckbox = false, checkbox, submitDisabled }) => (
        <AttentionModalBase
          visible={visible}
          closeAttentionModal={() => {
            onClose(submitDisabled)
          }}
          submit={renderSubmit(submitDisabled)}
        >
          <AttentionModalText
            increaseText={increaseText}
            decreaseText={decreaseText}
            portfolioChangedText={portfolioChangedText}
            recommendedIndex={recommendedIndex}
            desiredIndex={desiredIndex}
            portfolioChanged={portfolioChanged}
          />
          {shouldShowCheckbox ? checkbox : null}
        </AttentionModalBase>
      )}
    </AttentionModalCheckbox>
  )
}

export { AttentionModal }
