import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import isNull from 'lodash/isNull'

import { palette } from 'helpers/palette/'

import { useMediaQueries } from 'hooks'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Profit } from 'components/molecules/Profit'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Ticker } from 'components/atoms/Ticker'
import { Typo } from 'components/_old/Typo/Typo'
import { Typography } from 'components/atoms/Typography'
import Icon from 'components/_old/Icon/Icon.jsx'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import './SecurityInfo.css'
import { SecurityWeightInfo } from 'components/molecules/SecurityWeightInfo'

const SecurityInfo = ({
  className,
  skeleton,
  id,
  logo,
  title,
  value,
  ticker,
  processing = false,
  current_weight,
  target_weight,
  stats = {},
  onClick: handleClick,
  'data-test-id': dataTestId,
}) => {
  const { desktop } = useMediaQueries()

  const { return_money, return_percent } = stats
  const showProfit = !isNull(return_money) && !isNull(return_percent)

  const content = (
    <div className="SecurityInfo-Content">
      <div>
        <Typography
          className="SecurityInfo-Title"
          size={desktop ? 18 : 16}
          weight="semibold"
          lineHeight="small"
          data-test-id={skeleton ? null : `${dataTestId}Title`}
        >
          <Typo>{skeleton ? '████ ████ ██████' : title}</Typo>
        </Typography>
        {(ticker || skeleton) && (
          <Paper top={desktop ? 8 : 4}>
            <Ticker text={ticker ?? '████'} data-test-id={skeleton ? null : `${dataTestId}Ticker`} />
          </Paper>
        )}
        <Paper top={8}>
          <SecurityWeightInfo
            currentWeight={current_weight}
            targetWeight={target_weight}
            data-test-id={skeleton ? null : dataTestId}
          />
        </Paper>
      </div>
      <div className="SecurityInfo-RightColumn">
        <Typography
          size={desktop ? 20 : 18}
          align="right"
          lineHeight={desktop ? 'medium' : 'small'}
          weight="semibold"
          data-test-id={skeleton ? null : `${dataTestId}Amount`}
        >
          {processing && (
            <Icon
              className="SecurityInfo-Processing"
              type="time-24"
              color={palette['content-on-background-minor']}
              inline
              data-test-id={skeleton ? null : `${dataTestId}PendingIcon`}
            />
          )}
          <PoundsWithPence amount={skeleton ? 987.65 : value} showZeroPence={value > 0} />
        </Typography>
        {showProfit && (
          <Paper top={4}>
            <Typography
              size={desktop ? 16 : 14}
              align="right"
              lineHeight="small"
              data-test-id={skeleton ? null : `${dataTestId}MoneyReturn`}
            >
              <Profit amount={return_money} percent={return_percent} data-test-id={skeleton ? null : dataTestId} />
            </Typography>
          </Paper>
        )}
      </div>
    </div>
  )

  const horizontalOffset = desktop ? 16 : 12

  return (
    <SelectableCard
      className={classNames(className, 'SecurityInfo')}
      onClick={handleClick}
      data-security-id={id}
      data-test-id={skeleton ? null : dataTestId}
    >
      <Paper top={16} right={horizontalOffset} bottom={16} left={horizontalOffset}>
        <ItemWithIcon content={content} space={12} icon={<SecurityLogo logo={logo} size={desktop ? 48 : 36} />} />
      </Paper>
    </SelectableCard>
  )
}

SecurityInfo.propTypes = {
  className: PropTypes.string,
  skeleton: PropTypes.bool,
  id: PropTypes.number,
  logo: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  ticker: PropTypes.string,
  processing: PropTypes.bool,
  current_weight: PropTypes.number.isRequired,
  target_weight: PropTypes.number,
  stats: PropTypes.shape({
    return_money: PropTypes.number,
    return_percent: PropTypes.number,
  }),
  onClick: PropTypes.func,
  'data-test-id': PropTypes.string,
}

export { SecurityInfo }
