import React from 'react'

import Icon from 'components/_old/Icon/Icon.jsx'

import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './ProgressPoint.css'

type ProgressPointProps = {
  title: string
  icon: 'subtract-24' | 'circle-24' | 'filled-success-24'
  description?: React.ReactNode
  button?: React.ReactNode
}

const ProgressPoint = ({
  title,
  icon = 'subtract-24',
  description,
  button,
}: ProgressPointProps): React.ReactElement => {
  return (
    <Card className="ProgressPoint" borderWidth={2} borderRadius={10} color="background-default">
      <Paper top={12} right={40} bottom={12} left={12}>
        <ItemWithIcon
          space={8}
          icon={<Icon type={icon} size={24} style={{ marginTop: '-1px' }} />}
          content={
            <Typography size={16} weight="semibold" lineHeight="small">
              {title}
            </Typography>
          }
        />
        {description && (
          <Paper left={32} top={8}>
            {description}
          </Paper>
        )}
        {button && (
          <Paper left={32} top={16}>
            {button}
          </Paper>
        )}
      </Paper>
    </Card>
  )
}

export { ProgressPoint }
