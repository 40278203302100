import React from 'react'
import PropTypes from 'prop-types'

import { sendError } from 'helpers/errorLogging.js'
import { goTo, urlTo } from 'helpers/router.js'
import { trackEvent } from 'helpers/analytics'
import { Impact } from 'helpers/impact.js'
import { features } from 'helpers/features'

import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useMemo, useSelector } from 'hooks'
import { useCreatePortfolioQuickStart } from 'app/pages/Dashboard/Goals/SavingsPlan/SavingsPlanSetup/hooks/useCreatePortfolioQuickStart'
import { useSecurityQuickStart } from 'app/pages/Dashboard/Goals/SavingsPlan/SavingsPlanSetup/hooks/useSecurityQuickStart'

import GlobalPreloader from 'components/_old/GlobalPreloader/GlobalPreloader.jsx'

import {
  isClientNewOrNotCompleted as selectIsClientNewOrNotCompleted,
  isClientApproved as selectIsClientApproved,
} from 'app/redux/selectors'

import {
  patchPortfolio as patchPortfolioActionCreator,
  updateOrCreate as updateOrCreateGoalActionCreator,
} from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'

import { states as clientStates } from 'constants/client'
import { bankAccountStates } from 'constants/bankAccounts'
import { GP_DEFAULT } from 'constants/globalPreloaderStatuses.js'
import { manageTypes, regulatoryTypes, states as portfolioStates } from 'constants/portfolio'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $portfolioSecuritiesStore, addSecurity, savePortfolioSecuritiesFx } from 'app/effector/portfolio-securities'

const Finish = ({ params: { id: idString }, location: { query } }) => {
  const id = parseInt(idString, 10)
  const portfolios = useSelector((state) => state.portfolios.list)
  const visibleIsaPortfolios = useMemo(
    () => portfolios.filterByShape({ regulatory_type: regulatoryTypes.ISA }).getVisiblePortfolios(),
    [portfolios],
  )
  const portfolio = useMemo(() => portfolios.get(id), [portfolios, id])
  const shouldSignIsaDeclaration = useSelector((state) => state.client.should_sign_isa_declaration)
  const shouldSignSippDeclaration = useSelector((state) => !state.client.agreed_with_sipp_declaration)
  const isClientNew = useSelector((state) => state.client.state === clientStates.NEW)
  const isClientNewOrNotCompleted = useSelector(selectIsClientNewOrNotCompleted)
  const isClientApproved = useSelector(selectIsClientApproved)
  const { nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)
  const isBankAwaitingApproval = nominatedBankAccount?.state === bankAccountStates.AWAITING_APPROVAL
  const isNominatedBankSupportsRecurringPayment = nominatedBankAccount?.bank?.recurring_payment_support
  const [patchPortfolio, updateOrCreateGoal] = useActions([
    patchPortfolioActionCreator,
    updateOrCreateGoalActionCreator,
  ])
  const isQuickStartInProgress = query.quickStart === 'true'
  const { getSecuritiesByPortfolio } = useUnit($portfolioSecuritiesStore)
  const portfolioSecurities = getSecuritiesByPortfolio(portfolio?.id) || []

  const { createPortfolioQuickStart } = useCreatePortfolioQuickStart()
  const { addSecurityToPortfolio } = useSecurityQuickStart({
    portfolioId: portfolio?.id,
    securityId: query.securityId,
  })

  const handleError = useCallback((error) => {
    sendError(error)
    goTo(urlTo('dashboard'), { replace: true })
    showFailToast()
  }, [])

  const nextUrl = useMemo(() => {
    if (isQuickStartInProgress) {
      if (isClientNewOrNotCompleted)
        return urlTo('portfolio.add-funds.finish-registration', { id }, { quickStart: true })

      const isLumpSum = !query?.frequency

      if (isBankAwaitingApproval || !isClientApproved) return urlTo('dashboard.portfolio', { id })

      if (isLumpSum) {
        return urlTo('dashboard.portfolio.add-funds', { id }, { back: urlTo('dashboard.portfolio', { id }) })
      }

      const quickStartNextUrl = urlTo(
        features.get('recurring-payments-release') && isNominatedBankSupportsRecurringPayment
          ? 'portfolio.options.recurring-payment'
          : 'portfolio.options.direct-debit',
        { id },
        { back: urlTo('dashboard.portfolio', { id }) },
      )

      if (portfolio.regulatory_type === regulatoryTypes.SIPP) {
        return urlTo('portfolio.add-funds.net-contributions', { id }, { next: quickStartNextUrl })
      }

      return quickStartNextUrl
    }

    return urlTo('dashboard.portfolio', { id }, { portfolioOpened: true })
  }, [
    id,
    portfolio,
    isQuickStartInProgress,
    isClientNewOrNotCompleted,
    isClientApproved,
    isBankAwaitingApproval,
    isNominatedBankSupportsRecurringPayment,
    query,
  ])

  const handleFinish = useCallback(async () => {
    if (!idString || idString === ':id') {
      handleError(new Error('No goal `id` specified in params at `new-portfolio-tunnel.finish`'))
      return
    }

    if (portfolio?.regulatory_type === regulatoryTypes.ISA || query.regulatoryType === regulatoryTypes.ISA) {
      if (isClientNew && visibleIsaPortfolios.length < 1) {
        goTo(urlTo('dashboard', null, { ...(query ?? {}), openIsaId: portfolio?.id }))
        return
      }

      if (!isClientNew && shouldSignIsaDeclaration) {
        goTo(urlTo('dashboard.portfolio.isa-declaration', { id }, query), { replace: true })
        return
      }
    }

    if (
      (portfolio?.regulatory_type === regulatoryTypes.SIPP || query.regulatoryType === regulatoryTypes.SIPP) &&
      shouldSignSippDeclaration
    ) {
      goTo(urlTo('dashboard.sipp-declaration', {}, { ...query, portfolioId: id }))
      return
    }

    try {
      if (query.regulatoryType && portfolio?.regulatory_type !== query.regulatoryType) {
        // create new portfolio instead of patch regulatory type
        const nextState = await updateOrCreateGoal(
          ['manage_type', 'regulatory_type', 'preset_type', 'is_default_preset_used', 'title'],
          {
            manage_type: portfolio?.manage_type,
            regulatory_type: query.regulatoryType,
            preset_type: portfolio?.preset_type,
            is_default_preset_used: Boolean(portfolio?.isDefault),
            title: portfolio?.title,
          },
          true,
          null,
          false,
        )

        const newPortfolio = nextState.portfolios.items
          .filter(
            (goal) =>
              goal.state === portfolioStates.NEW &&
              goal.regulatory_type === query.regulatoryType &&
              goal.manage_type === portfolio?.manage_type,
          )
          .at(-1)

        if (!isQuickStartInProgress) {
          portfolioSecurities.forEach((security) => {
            addSecurity({ portfolioId: newPortfolio?.id, securityId: security.security_id })
          })
          const portfolioWeights = portfolioSecurities.map((security) => ({
            security_id: security.security_id,
            target_weight: security.target_weight,
          }))
          await savePortfolioSecuritiesFx({
            portfolioId: newPortfolio?.id,
            portfolioSecurityWeightList: portfolioWeights,
          })
        }

        goTo(urlTo('dashboard.portfolio.finish', { id: newPortfolio?.id }, { ...query }))
        return
      }

      if (isQuickStartInProgress && query.securityId) {
        await addSecurityToPortfolio()
      }
      const stateAfterPortfolioPatch = await patchPortfolio(id, { completed: true })

      if (stateAfterPortfolioPatch.portfolios.error) {
        throw stateAfterPortfolioPatch.portfolios.error
      }

      const client = stateAfterPortfolioPatch.client
      const completedPortfolio = stateAfterPortfolioPatch.portfolios.list.get(id)
      const presets = stateAfterPortfolioPatch.portfolios.presets
      const presetTitle = presets?.[completedPortfolio.preset_type]?.find(
        (preset) => preset.id === completedPortfolio.preset,
      )?.title
      const presetIndex =
        presets?.[completedPortfolio?.preset_type]?.findIndex((preset) => preset.id === completedPortfolio.preset) ?? 0

      if (isQuickStartInProgress) {
        const { amount, frequency, startDay, monthlyPaymentDay } = query
        await createPortfolioQuickStart({
          amount,
          frequency,
          startDay,
          monthlyPaymentDay,
          portfolioId: completedPortfolio?.id,
        })
      }

      trackEvent({
        category: 'Create new portfolio',
        action: 'Save portfolio',
        label: [completedPortfolio.manage_type, completedPortfolio.regulatory_type, completedPortfolio.preset_type]
          .filter((nullableString) => Boolean(nullableString))
          .join(' '),
        portfolioManageType: completedPortfolio.manage_type,
        portfolioPresetType: completedPortfolio.preset_type,
        portfolioRegulatoryType: completedPortfolio.regulatory_type,
      })

      trackEvent({
        action: 'portfolio_created',
        manage_type: completedPortfolio.manage_type,
        regulatory_type: completedPortfolio.regulatory_type,
        preset_selected: presetIndex + 1,
      })

      Impact.trackSavingPortfolio(
        client,
        completedPortfolio,
        [manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(completedPortfolio.manage_type)
          ? presetTitle
          : undefined,
      )

      goTo(nextUrl, { replace: true })
    } catch (error) {
      handleError(error)
    }
  }, [
    handleError,
    id,
    idString,
    patchPortfolio,
    visibleIsaPortfolios,
    portfolio,
    query,
    shouldSignIsaDeclaration,
    shouldSignSippDeclaration,
    nextUrl,
    createPortfolioQuickStart,
    isQuickStartInProgress,
    addSecurityToPortfolio,
    isClientNew,
    updateOrCreateGoal,
    portfolioSecurities,
  ])

  useEffect(
    () => {
      handleFinish()
    },
    // had to run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  )

  return <GlobalPreloader status={GP_DEFAULT} loading />
}

Finish.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  location: PropTypes.shape({
    query: PropTypes.object,
  }),
}

export { Finish }
