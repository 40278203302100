import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useEffect, useMediaQueries, useMemo } from 'hooks'
import { useReview } from './hooks'

import { GatewayDest } from 'components/atoms/Gateway'
import { Preloader } from 'components/molecules/Preloader'
import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

const Review = ({ params: { id, orderId: orderIdString } = {}, location: { query = {} } = {} }) => {
  const { desktop } = useMediaQueries()

  const portfolioId = useMemo(() => parseInt(id, 10), [id])
  const orderId = useMemo(() => parseInt(orderIdString, 10), [orderIdString])

  const { isLoading, orderInfo, securities, handleBack, handleCancel, handleConfirm } = useReview({
    orderId,
    portfolioId,
  })

  const props = {
    orderInfo,
    securities,
    handleBack: () => handleBack(query),
    handleCancel,
    handleConfirm,
  }

  useEffect(() => {
    // after reload page, we can't get the order, because api method on get doesn't exist
    if (!orderInfo) {
      handleBack(query)
    }
  }, [])

  if (!orderInfo) {
    return null
  }

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}
      <GatewayDest name="toasts" />
      <Preloader loading={isLoading} size="big" zIndex />
    </Fragment>
  )
}

Review.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
    orderId: PropTypes.string,
  }),
  location: PropTypes.shape({
    query: PropTypes.shape({
      previous: PropTypes.string.isRequired,
      securityId: PropTypes.string,
    }),
  }),
}

export { Review }
