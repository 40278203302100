import { shallowEqual } from 'react-redux'

import { useUnit } from 'effector-react'

import { useEffect, useSelector, useCallback } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router'

import { $owner } from 'app/effector/contacts'
import { $storiesStore, fetchStoriesFx, processClientStories } from 'app/effector/stories'
import { type StoryList } from 'app/effector/stories'

import { types as clientTypes, states as clientStates } from 'constants/client'
import { regulatoryTypes } from 'constants/portfolio'

enum CustomStoryAction {
  TOPUP_ISA = 'topup-isa',
  TRANSFER_ISA = 'transfer-isa',
}

type useDashboardStoriesProps = {
  shouldFetchStories?: boolean
}

type UseDashboardStoriesData = {
  stories: StoryList
  areStoriesFetched: boolean
  customStoriesActions: Record<string, ({ id, index }) => void>
}

const useDashboardStories = ({ shouldFetchStories = true }: useDashboardStoriesProps = {}): UseDashboardStoriesData => {
  const { stories, areStoriesFetched } = useUnit($storiesStore)

  const client = useSelector((state) => state.client)

  const isClientFetched = client.is_fetched
  const isIndividual = client.type === clientTypes.INDIVIDUAL
  const ownerContact = useUnit($owner)
  const isClientFunded = useSelector((state) => !!state.portfolios?.list?.find((portfolio) => !!portfolio.first_topup))
  const isPortfoliosFetched = useSelector((state) => !!state.portfolios?.list?.length)

  const shouldSignIsaDeclaration = useSelector((state) => state.client.should_sign_isa_declaration)
  const groupedPortfolios = useSelector(
    (state) => state.portfolios.list.getVisiblePortfolios({ includeCashPortfolios: false }).groupByRegulatoryType(),
    shallowEqual,
  )
  const isaPortfolios = groupedPortfolios[regulatoryTypes.ISA] || []

  const handleCTAIsaPromoGroupOne = ({ id, index }): void => {
    trackEvent({
      action: 'CTA_clicked',
      story_id: id,
      step_number: index,
    })

    // if isa not opened -> navigate to open isa
    if (shouldSignIsaDeclaration) {
      goTo(urlTo('dashboard', null, { openIsa: true }))
      return
    }

    // if has isa portfolio -> navigate there / else -> navigate to create isa portfolio
    if (isaPortfolios[0]) {
      goTo(urlTo('dashboard.portfolio', { id: isaPortfolios[0].id }))
    } else {
      goTo(urlTo('create-new-portfolio', null, { regulatoryType: regulatoryTypes.ISA }))
    }
  }

  const handleCTAIsaPromoGroupTwo = ({ id, index }): void => {
    trackEvent({
      action: 'CTA_clicked',
      story_id: id,
      step_number: index,
    })

    goTo(urlTo('transfer-isa-altus'))

    if (client.state === clientStates.NOT_COMPLETED) {
      goTo(urlTo('dashboard.setup-account'))
      return
    }

    if (shouldSignIsaDeclaration) {
      goTo(urlTo('dashboard', null, { openIsa: true, forwardToTransferIsa: true }))
      return
    }

    goTo(urlTo('transfer-isa-altus'))
  }

  const customStoriesActions = {
    [CustomStoryAction.TOPUP_ISA]: handleCTAIsaPromoGroupOne,
    [CustomStoryAction.TRANSFER_ISA]: handleCTAIsaPromoGroupTwo,
  }

  const fetchStories = useCallback(async () => {
    const isUkResident = ownerContact.isUkResidence()

    let groups: string[] = []

    if (
      isIndividual &&
      (client.state === clientStates.NEW ||
        client.state === clientStates.NOT_COMPLETED ||
        (isUkResident && !isClientFunded))
    ) {
      groups = ['isa-promo-season-group-a']
    }

    if (isIndividual && isClientFunded && isUkResident) {
      groups = ['isa-promo-season-group-b']
    }

    await fetchStoriesFx({ groups })
    processClientStories(client)
  }, [client, isClientFunded, isIndividual, ownerContact])

  useEffect(() => {
    if (shouldFetchStories && !areStoriesFetched && isClientFetched && ownerContact && isPortfoliosFetched) {
      fetchStories()
    }
  }, [areStoriesFetched, fetchStories, isClientFetched, isPortfoliosFetched, ownerContact, shouldFetchStories])

  return {
    stories,
    areStoriesFetched,
    customStoriesActions,
  }
}

export { useDashboardStories }
