import isEmpty from 'lodash/isEmpty'

import { useCallback, useMemo } from 'hooks'

import { useRestriction, usePendingOrders } from 'app/pages/Dashboard/Goals/DIY/hooks'

import { scope as pendingOrderScope } from 'constants/pendingOrder.js'

const useSecurityUniverseRestrictions = (portfolioId, handleGoToEditWeightsRaw) => {
  const { orders } = usePendingOrders(!!portfolioId)
  const portfolioOrders = useMemo(() => orders.getByPortfolioId(portfolioId), [portfolioId, orders])
  const portfolioScopeOrders = useMemo(() => portfolioOrders.getByScope(pendingOrderScope.PORTFOLIO), [portfolioOrders])
  const isRestricted = useMemo(() => !isEmpty(portfolioScopeOrders), [portfolioScopeOrders])

  const {
    open: restrictionModalOpen,
    type: restrictionModalType,
    handleClose: handleRestrictionModalClose,
    handleRestriction,
  } = useRestriction()

  const handleGoToEditWeights = useCallback(() => {
    handleRestriction({
      action: handleGoToEditWeightsRaw,
      type: 'EDIT',
      isRestricted,
    })
  }, [handleGoToEditWeightsRaw, handleRestriction, isRestricted])

  return {
    restrictionModalOpen,
    restrictionModalType,
    handleRestrictionModalClose,
    handleGoToEditWeights,
  }
}

export { useSecurityUniverseRestrictions }
