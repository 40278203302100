import React, { Fragment } from 'react'

import { useMemo } from 'hooks'

import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { Captcha } from 'components/organisms/Captcha/Captcha'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

type CaptchaDesktopProps = {
  action: 'enable-2fa' | 'disable-2fa'
  token: string
  handleBack: () => void
}

const Desktop = ({ action, token, handleBack }: CaptchaDesktopProps): React.ReactElement => {
  const backLink = useMemo(
    () => (
      <Link onClick={handleBack} mods={{ color: 'content-on-background-default' }}>
        <ItemWithIcon
          space={16}
          icon={<Icon type="back-new" size={24} color="inherit" />}
          content={<Typography color="inherit">Back</Typography>}
          iconVerticalAlign="center"
        />
      </Link>
    ),
    [handleBack],
  )

  const stepTitleNode = useMemo(
    () => (
      <Typography size={20} lineHeight="small" weight="semibold" align="center">
        <Typo>Step 2 of 3</Typo>
      </Typography>
    ),
    [],
  )

  const titleNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography size={32} lineHeight="small" weight="semibold" align="center">
          <Typo>Security check</Typo>
        </Typography>
      </Paper>
    ),
    [],
  )

  const captchaNode = useMemo(
    () => (
      <Paper top={16}>
        <Captcha token={token} onSuccess={handleBack} />
      </Paper>
    ),
    [token, handleBack],
  )

  const content = (
    <Inner twoColumns>
      {action === 'enable-2fa' ? (
        <ColumnarLayout mods={{ padding: 'no' }}>
          <Column size={1}>{backLink}</Column>
          <Column size={2}>
            {stepTitleNode}
            <Width size={24} center>
              {titleNode}
              {captchaNode}
            </Width>
          </Column>
          <Column size={1} />
        </ColumnarLayout>
      ) : (
        <Fragment>
          {backLink}
          <Width size={27} center>
            {titleNode}
            {captchaNode}
          </Width>
        </Fragment>
      )}
    </Inner>
  )

  return <DesktopLayout header={<DesktopHeader />} content={content} footer={<DesktopFooter />} />
}

export { Desktop }
