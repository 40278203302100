import React from 'react'

import { useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Logo } from 'components/_old/Logo/Logo.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import type { SessionTimeoutProps } from './SessionTimeout'

const Mobile = ({ countdown, handleLogout, handleStay }: SessionTimeoutProps): React.ReactElement => {
  const header = useMemo(
    () => (
      <NavigationBar plain>
        <div className="Header-Logo2">
          <Logo style={{ height: 24 }} />
        </div>
      </NavigationBar>
    ),
    [],
  )

  const content = (
    <AllCenter>
      <Width size={24} center>
        <Paper top={8}>
          <Typography size={16} weight="semibold" align="center">
            {countdown}
          </Typography>
        </Paper>
        <Paper top={56} bottom={12}>
          <Typography size={24} lineHeight="small" weight="semibold" align="center">
            <Typo>Are you still here?</Typo>
          </Typography>
        </Paper>
        <Typography size={16} align="center">
          <Typo>
            You will be automatically logged out of your account in 60 seconds. To stay logged in, click on the button
            below.
          </Typo>
        </Typography>
      </Width>
    </AllCenter>
  )

  const buttons = useMemo(
    () => (
      <Paper>
        <MobileLayoutFooterButton>
          <Button mods={{ theme: 'simple-reverse-blue', size: 'big block' }} onClick={handleLogout}>
            Log out now
          </Button>
        </MobileLayoutFooterButton>
        <MobileLayoutFooterButton>
          <Button onClick={handleStay} mods={{ size: 'big block' }}>
            Stay logged in
          </Button>
        </MobileLayoutFooterButton>
      </Paper>
    ),
    [handleLogout, handleStay],
  )

  return <MobileLayout header={header} content={content} footer={buttons} />
}

export { Mobile }
