import { useUnit } from 'effector-react'

import {
  useCallback,
  useAsyncEffect,
  useEffect,
  useLoading,
  useMediaQueries,
  useSelector,
  useState,
  useContext,
} from 'hooks'

import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router.js'

import { $dictsStore, fetchOptionsFx } from 'app/effector/dicts'

import { getSharedPortfolioData } from 'app/redux/api/portfolio.js'
import { ApiError } from 'app/redux/models/errors'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

import { type SharedPortfolioData } from '../../types'

import { useAnalyticsScripts } from './useAnalyticsScripts'

const CUSTOM_GET_STARTED_MAP = {
  eb0ab2bf37db7459a71b858ea41ef7f5182ba005:
    'https://investengine.com/affiliate-welcome/?irclickid=y650CwzP4xyIWrPQHcWEzT3UUkD2kOwhk1AHyM0&utm_medium=affiliate&utm_source=David%20Stewart&irgwc=1&partner=David%20Stewart&partner_id=2916535',
}

const useSharedPortfolio = (portfolioHash: string, descriptionExpanded: boolean): SharedPortfolioData => {
  const { desktop } = useMediaQueries()
  const [isShareModalOpened, setShareModalOpened] = useState<boolean>(false)
  const [isAvailable, setIsAvailable] = useState<boolean>(true)
  const [sharingData, setSharingData] = useState({
    title: '',
    description: '',
    securities: [],
    equities: [],
    regions: [],
    sectors: [],
    equityTypes: {
      ALTERNATIVE: 0,
      BOND: 0,
      STOCK: 0,
    },
    assets: [],
    referral_url: '',
  })
  const { isLoading, wait } = useLoading(false)

  const { isPromoEnabled } = useUnit($dictsStore)
  const client = useSelector((state) => state.client)
  const isAuthorized = client?.access_token

  // use reddit pixel and twitter analytics scripts
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (process.env.NODE_ENV !== 'development') useAnalyticsScripts()

  const closeShareModal = useCallback(() => {
    setShareModalOpened(false)
  }, [setShareModalOpened])

  const shareUrl = `${window.location.origin}/share/portfolio/${portfolioHash}/`
  const shareTitle = 'Look at this awesome portfolio!'

  const { saveScrollPosition, restoreScrollPosition } = useContext(ScrollSaverContext)

  // TsCs Modal
  const [showTsCsModal, setShowTsCsModal] = useState<boolean>(false)

  const handleCloseTsCsModal = (): void => {
    setShowTsCsModal(false)
  }

  const handleOpenTsCsModal = (): void => {
    setShowTsCsModal(true)
  }

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(descriptionExpanded)

  const handleDescriptionExpand = useCallback(() => {
    setIsDescriptionExpanded(true)
    window.history.replaceState(null, '', `${shareUrl}?descriptionExpanded=true`)
  }, [setIsDescriptionExpanded, shareUrl])

  useAsyncEffect(async () => {
    try {
      const data = await wait(getSharedPortfolioData(portfolioHash))

      if (data instanceof ApiError) {
        throw data
      }

      data.title = data.title ?? 'Portfolio'
      data.equityTypes = {
        STOCK: parseFloat(data.equity_types.STOCK),
        BOND: parseFloat(data.equity_types.BOND),
        ALTERNATIVE: parseFloat(data.equity_types.ALTERNATIVE),
      }
      ;['sectors', 'regions', 'equities'].forEach((type) => {
        data[type] = data[type].map((item) => ({
          ...item,
          target: parseFloat(item.target_weight),
          color: item.color || palette.stocks,
        }))
      })

      data.assets = [
        { name: 'Equities', target: parseFloat(data.equity_types.STOCK ?? 0), color: palette.stocks },
        { name: 'Bonds', target: parseFloat(data.equity_types.BOND ?? 0), color: palette.bonds },
        {
          name: 'Alternatives',
          target: parseFloat(data.equity_types.ALTERNATIVE ?? 0),
          color: palette.alternatives,
        },
      ]

      data.securities = data.securities.map((security) => ({
        ...security,
        target_weight: security.target_weight === null ? 0 : parseFloat(security.target_weight),
      }))

      setSharingData(data)
      restoreScrollPosition()
    } catch {
      setIsAvailable(false)
    }
  }, [portfolioHash])

  useEffect(() => {
    fetchOptionsFx()

    if (window?.history) {
      window.history.replaceState(null, '', `${shareUrl}${descriptionExpanded ? '?descriptionExpanded=true' : ''}`)
    }
  }, [shareUrl, descriptionExpanded])

  const handleShare = useCallback(async () => {
    if (!desktop && navigator.share) {
      await navigator.share({
        title: shareTitle,
        url: shareUrl,
      })
      return
    }
    setShareModalOpened(true)
  }, [desktop, setShareModalOpened, shareTitle, shareUrl])

  const handleGetStarted = useCallback(() => {
    if (isAvailable) {
      const referralUrl = CUSTOM_GET_STARTED_MAP[portfolioHash] ?? sharingData.referral_url

      if (referralUrl) {
        return window.open(referralUrl, '_blank')
      }
    }

    goTo(urlTo('pre-flight'))
  }, [portfolioHash, isAvailable, sharingData])

  const handleSecurityClick = useCallback(
    (securityId: string) => {
      saveScrollPosition()
      window.history.replaceState(
        null,
        '',
        `${window.location.origin}/portfolio/${portfolioHash}/${
          isDescriptionExpanded ? `?descriptionExpanded=true` : ''
        }`,
      )
      goTo(
        urlTo(
          'portfolio-sharing.security',
          { hash: portfolioHash, securityId },
          { ...(isDescriptionExpanded ? { descriptionExpanded: true } : {}) },
        ),
      )
    },
    [portfolioHash, saveScrollPosition, isDescriptionExpanded],
  )

  return {
    isAuthorized,
    isAvailable,
    isLoading,
    isPromoEnabled,
    ...sharingData,
    isShareModalOpened,
    closeShareModal,
    shareUrl,
    shareTitle,
    handleShare,
    handleGetStarted,
    handleSecurityClick,
    showTsCsModal,
    handleOpenTsCsModal,
    handleCloseTsCsModal,
    isDescriptionExpanded,
    handleDescriptionExpand,
  }
}

export { useSharedPortfolio }
