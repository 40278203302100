/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import withMediaQueries from 'decorators/withMediaQueries/withMediaQueries.jsx'
import debounce from 'lodash/debounce'
import isNull from 'lodash/isNull'
import isObject from 'lodash/isObject'
import PropTypes from 'prop-types'

import { trackEvent } from 'helpers/analytics'
import { getDataForHorizontalChart } from 'helpers/assets.js'
import compose from 'helpers/compose.js'
import { features } from 'helpers/features'
import rawMediaQueries from 'helpers/mediaQueries.js'
import { format as formatMoney } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router.js'
import { combineErrors, backendErrorsToObj, bulkValidate, monthlyInvestmentRules } from 'helpers/validation.js'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $dictsStore } from 'app/effector/dicts'

import {
  updateOrCreate as updateOrCreateGoal,
  fetchRecommended,
  fetchProjectionsGrowth as fetchProjections,
  changeField as changeGoalField,
  setNotValid,
  resetError,
} from 'app/redux/actions/portfolios'
import { changeModalVisibility, showFailToast } from 'app/redux/actions/ui'
import { isAllQuestionsAnswered } from 'app/redux/selectors'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Logo } from 'components/_old/Logo/Logo.jsx'
import { Progress } from 'components/_old/Progress/'
import SegmentedControl from 'components/_old/SegmentedControl/SegmentedControl.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'
import FeeInfo from 'app/containers/Questionnaire/FeeInfo/FeeInfo.jsx'

import { KeyFeatureDocumentModal } from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/KeyFeatureDocumentModal'

import AttentionModal from '../components/AttentionModal'
import LearnMore from '../components/LearnMore'

import { types as clientTypes } from 'constants/client'
import { MAX_SINGLE_PAYMENT_AMOUNT } from 'constants/validations.js'

class Growth extends React.Component {
  state = {
    goal: {
      ...this.props.goal,
      term: this.props.goal?.term ?? 10,
    },
    kfdModalOpen: false,
    kfdModalContinued: false,
  }

  openAttentionModal = (): void => {
    const { changeModalVisibility } = this.props
    changeModalVisibility('attentionModal', true)
  }

  updateProjections = debounce((goal_id, preset, initial_deposit, monthly_deposit, term, initialDepositMin) => {
    const { monthly, fetchProjections } = this.props

    if (initial_deposit >= initialDepositMin) {
      return fetchProjections(goal_id, preset, initial_deposit, monthly_deposit, term, monthly)
    }
  }, 250)

  updateRecommended = debounce((term, id) => {
    const { fetchRecommended } = this.props

    if (term && id) {
      fetchRecommended(term, id).then(([_nextState, error]) => {
        if (error) {
          showFailToast('Something went wrong, please try again later')
        }
      })
    }
  }, 250)

  getPresetChangedInfo(): Record<string, unknown> & { title: string; kiid: string } {
    const {
      // @ts-expect-error don't have to type legacy connect
      presets,
      // @ts-expect-error don't have to type legacy connect
      goal: { preset_changed },
    } = this.props

    return presets.find(
      (preset: Record<string, unknown> & { title: string; kiid: string }) => preset.id === preset_changed,
    )
  }

  handleGoalFieldChange = (field, event, value = null): void => {
    const { goal, changeGoalField, initialDepositMin } = this.props
    const { preset_changed, initial_deposit, monthly_deposit, term } = goal

    if (goal.id) {
      changeGoalField({ [field]: value }, Number(goal.id))

      if (['preset_changed', 'initial_deposit', 'monthly_deposit', 'term'].includes(field)) {
        const recommendedFields = { term, ...{ [field]: value } }
        this.updateRecommended(recommendedFields.term, goal.id)
        const projectionsFields = {
          preset_changed,
          initial_deposit,
          monthly_deposit,
          term,
          ...{ [field]: value },
        }
        this.updateProjections(
          goal.id,
          projectionsFields.preset_changed,
          projectionsFields.initial_deposit,
          projectionsFields.monthly_deposit,
          projectionsFields.term,
          initialDepositMin,
        )
      }
    }
  }

  handleSubmit = (): void => {
    const { redirectToNextStep, updateOrCreateGoal, resetError, goal, presets } = this.props
    const isDefaultPortfolio = goal?.is_default_preset_used
    const fields = isDefaultPortfolio
      ? ['initial_deposit', 'monthly_deposit', 'term', 'target']
      : ['initial_deposit', 'monthly_deposit', 'term', 'preset', 'target', 'preset_changed']

    const presetIndex = presets?.findIndex((preset) => preset.id === goal.preset_recommended) ?? 0

    if (features.get('new-questionnaire')) {
      if (!isDefaultPortfolio) {
        if (this.state.kfdModalOpen) {
          this.setState({ kfdModalContinued: true })
        }

        if (!this.state.kfdModalContinued && !this.state.kfdModalOpen) {
          this.setState({ kfdModalOpen: true })

          return
        }
      }
    }

    trackEvent({
      action: 'questionnaire_completed',
      text: 'Save and continue',
      step: 10,
      step_description: 'you_selected_portfolio_preset',
      regulatory_type: goal?.regulatory_type,
      preset_recommended: presetIndex + 1,
      version: 'default',
    })

    updateOrCreateGoal(fields).then((nextState) => {
      if (
        (nextState.client.error && !isObject(nextState.client.error.response)) ||
        (nextState.client.error && !isObject(nextState.client.error.response.data))
      ) {
        showFailToast()
        setTimeout(() => {
          resetError()
        }, 10)
      }

      if (!nextState.portfolios.error) {
        redirectToNextStep()
      }
    })
  }

  handlePortfolioTabClick = (): void => {
    const isPortfolioTabActive = this.props.children.props.route.module === 'portfolio'

    if (!isPortfolioTabActive)
      trackEvent({
        category: 'Review your portfolio',
        action: 'Portfolio tab clicked',
      })
  }

  handleProjectionsTabClick = (): void => {
    const isProjectionsTabActive = this.props.children.props.route.module === 'projections'

    if (!isProjectionsTabActive)
      trackEvent({
        category: 'Review your portfolio',
        action: 'Projections tab clicked',
      })
  }

  handleKfdModalClose(): void {
    this.setState({ kfdModalOpen: false, kfdModalContinued: false })
  }

  componentDidMount(): void {
    const { presets, goal, setNotValid, initialDepositMin } = this.props
    const { id, preset_changed, initial_deposit, monthly_deposit, term } = goal || {}

    if (presets.length < 1) {
      setNotValid()
    }

    if (id) {
      this.updateProjections(id, preset_changed, initial_deposit, monthly_deposit, term, initialDepositMin)
      this.updateRecommended(term, id)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps): void {
    const { goal, questionnaire, allQuestionsAnswered, params } = nextProps

    if (!questionnaire.didInvalidate && allQuestionsAnswered && goal?.is_fetched && isNull(goal.preset_recommended)) {
      goTo(urlTo('sorry', params, { portfolioId: goal.id }), { replace: true })
    } else {
      if (!this.props.goal && nextProps.goal) {
        this.setState({ goal: nextProps.goal })
      }
    }
  }

  render(): React.ReactElement {
    const {
      client,
      goal,
      goalError,
      monthly,
      presets,
      mediaQueries,
      children,
      parentModule,
      params,
      initialDepositMin,
      nominatedBankAccount,
    } = this.props

    const isBusiness = client.type === clientTypes.BUSINESS
    const isDefaultPortfolio = goal?.is_default_preset_used
    const target = goal ? goal.target : null
    const initial_deposit = goal ? goal.initial_deposit : null
    const monthly_deposit = goal ? goal.monthly_deposit : null
    const { phone, desktop } = mediaQueries
    const layoutCardMods = { theme: 'transparent', 'no-padding': 'left right' }
    const currentGoal = this.state.goal

    const selectedPreset = presets.find((p) => p.id === goal.preset_changed) || {}
    const horizontalChartData = getDataForHorizontalChart(
      selectedPreset.preset_stocks,
      selectedPreset.preset_bonds,
      selectedPreset.preset_alternatives,
      null,
      parseFloat(selectedPreset.preset_cash ?? 0),
    )

    const onRecommended = goal ? goal.preset_recommended === goal.preset_changed : false
    const unrecommendedPortfolio = !onRecommended
    const shouldShowAttention =
      goal && currentGoal ? goal.preset_changed !== currentGoal.preset && unrecommendedPortfolio : false

    const activeIndex = children.props.route.module === 'portfolio' ? 0 : 1

    const switchView = (
      <Card
        mods={{
          ...layoutCardMods,
          'no-padding': desktop ? layoutCardMods['no-padding'] : 'all',
        }}
      >
        <SegmentedControl fluid={phone} mods={{ size: 'big', theme: 'slider-alike' }} activeIndex={activeIndex}>
          <Link
            to={urlTo(`${parentModule as string}.review-portfolio.growth`, params)}
            replace
            scrollToTop={false}
            onClick={this.handlePortfolioTabClick}
            data-test-id="tunnelStepReviewGoalProjectionsLink"
          >
            <Icon
              type="graph"
              color="inherit"
              size={30}
              style={{
                margin: '-0.5em 0.25em -0.25em -0.75em',
                verticalAlign: 'middle',
              }}
              inline
            />
            Projections
          </Link>
          <Link
            to={urlTo(`${parentModule as string}.review-portfolio.growth.allocation`, params)}
            replace
            scrollToTop={false}
            onClick={this.handleProjectionsTabClick}
            data-test-id="tunnelStepReviewGoalAllocationLink"
          >
            <Icon
              type="bars"
              color="inherit"
              size={30}
              style={{
                margin: '-0.5em 0.25em -0.25em -0.75em',
                verticalAlign: 'middle',
              }}
              inline
            />
            Allocation
          </Link>
        </SegmentedControl>
      </Card>
    )

    const isRecurringPaymentValidation =
      features.get('recurring-payments-release') && nominatedBankAccount?.bank?.recurring_payment_support

    const validation = combineErrors(
      {
        initial_deposit: {
          rules: [
            parseInt(initial_deposit, 10) >= initialDepositMin,
            parseInt(initial_deposit, 10) <= MAX_SINGLE_PAYMENT_AMOUNT,
          ],
          errors: [
            `Must be at least ${formatMoney(initialDepositMin)}`,
            `Maximum amount is ${formatMoney(MAX_SINGLE_PAYMENT_AMOUNT)}`,
          ],
        },
        monthly_deposit: monthlyInvestmentRules(monthly_deposit, {
          optional: true,
          limitMaximumAmount: false,
          isRecurringPaymentValidation,
        }),
        target: {
          rules: [
            target === null || parseInt(target, 10) === 0 || parseInt(target, 10) >= parseInt(initial_deposit, 10),
          ],
          errors: ['Target must be more than the initial investment'],
        },
      },
      backendErrorsToObj(goalError),
    )

    let submit = (
      <Button
        type="submit"
        mods={{ size: 'big block' }}
        disabled={!isDefaultPortfolio && !bulkValidate(validation)}
        onClick={shouldShowAttention ? this.openAttentionModal : this.handleSubmit}
        tabIndex={6}
        data-test-id="tunnelStepReviewGoalSubmit"
      >
        Save and continue
      </Button>
    )

    submit = desktop ? <Text center>{submit}</Text> : <MobileLayoutFooterButton>{submit}</MobileLayoutFooterButton>

    const child = React.cloneElement(children, {
      desktop,
      goal,
      monthly,
      presets,
      onChange: this.handleGoalFieldChange,
      warning: (
        <Text muted smaller block align="center" data-test-id="acknowledgeDisclaimer">
          <Typo>
            {client.type === clientTypes.BUSINESS
              ? `By continuing you acknowledge that your capital is at risk and you should not invest an amount that may negatively impact your lifestyle. InvestEngine makes no recommendation as to the portfolio you select, only that this portfolio is suitable for you and your business could accept the impact of investment losses.`
              : `By continuing you acknowledge that your capital is at risk and you should not invest an amount that may negatively impact your lifestyle. InvestEngine makes no recommendation as to the portfolio you select, only that this portfolio is suitable for you.`}
          </Typo>
        </Text>
      ),
      submit: desktop ? submit : null,
      validation,
      onRecommended,
      isCreateNewPortfolio: true,
      horizontalChartData,
      isBusiness,
    })

    const bottomCardMods = desktop ? layoutCardMods : { ...layoutCardMods, 'no-padding': 'left right bottom' }

    const { prevStep, activeStep, progress } = this.props

    const backLinkText = isDefaultPortfolio ? 'Create portfolio' : prevStep.headline
    const handleBack = (): void => {
      if (isDefaultPortfolio) {
        goTo(urlTo('create-new-portfolio'))
        return
      }
      goTo(urlTo(prevStep.module, { ...params, questionCode: prevStep.questionCode }))
    }

    const handleCancel = (): void => {
      goTo(urlTo('dashboard'))
    }

    const defaultPortfolioTitle = "You've selected InvestEngine's Glidepath pension portfolio"

    const content = (
      <Paper top={desktop ? 40 : 0} bottom={desktop ? 24 : 0} left={desktop ? 40 : 0} right={desktop ? 40 : 0}>
        <Width size={desktop ? 73 : null} center>
          <Paper bottom={24} top={24}>
            {desktop ? (
              <NavigationBar leftPartText={backLinkText} onLeftPartClick={handleBack}>
                {isDefaultPortfolio ? defaultPortfolioTitle : activeStep.headline}
              </NavigationBar>
            ) : (
              <Typography size={24} align="center">
                {isDefaultPortfolio ? defaultPortfolioTitle : 'This is your selected portfolio'}
              </Typography>
            )}
          </Paper>
          {!isDefaultPortfolio && switchView}
          {activeIndex === 0 && (
            <Card
              mods={{
                theme: 'transparent',
                'no-padding': phone ? 'right bottom left' : 'right left',
                padding: 'small',
              }}
            >
              <Width size={45} center>
                <Text block center={desktop}>
                  <Text small>
                    <Typo>
                      {client.type === clientTypes.BUSINESS
                        ? `The projection tool below allows you to assess the impact of altering the composition and risk level of your company's portfolio, time horizon, initial and monthly investments and to set a target figure if required.`
                        : 'The projection tool below allows you to assess the impact of altering the composition and risk level of your portfolio, time horizon, initial and monthly investments and to set a target figure if required.'}
                    </Typo>
                  </Text>{' '}
                  <LearnMore />
                </Text>
              </Width>
            </Card>
          )}
          {goal ? <Card mods={bottomCardMods}>{child}</Card> : null}
          <AttentionModal presets={presets} onSubmit={this.handleSubmit} />
          <KeyFeatureDocumentModal
            open={this.state.kfdModalOpen}
            preset={this.getPresetChangedInfo()}
            animation={null}
            handleClose={this.handleKfdModalClose.bind(this)} // eslint-disable-line
            handleSubmit={this.handleSubmit}
          />
        </Width>
      </Paper>
    )

    const header = (
      <Header
        right={
          <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}>
            <Link to={urlTo('dashboard')}>My Dashboard</Link>
          </Button>
        }
        after={
          <Fragment>
            <Progress fill={progress} gapWidth="1em" />
            <FeeInfo />
          </Fragment>
        }
        hideDrawer
      />
    )

    return (
      <Fragment>
        {desktop ? (
          <DesktopLayout header={header} content={content} footer={<DesktopFooter />} noGaps />
        ) : (
          <MobileLayout
            header={
              <Fragment>
                <NavigationBar
                  leftPartText="Back"
                  onLeftPartClick={handleBack}
                  rightPartText="Cancel"
                  onRightPartClick={handleCancel}
                >
                  <Logo style={{ height: 24 }} />
                </NavigationBar>
                <Progress fill={progress} gapWidth="1em" />
              </Fragment>
            }
            content={content}
            footer={submit}
          />
        )}
        <GatewayDest name="modals" />
        <GatewayDest name="alarms" />
        <GatewayDest name="attention" />
      </Fragment>
    )
  }
}

Growth.propTypes = {
  client: PropTypes.object,
  goal: PropTypes.object,
  goalError: PropTypes.object,
  monthly: PropTypes.bool,
  presets: PropTypes.array,
  mediaQueries: PropTypes.object,
  children: PropTypes.node,
  parentModule: PropTypes.string,
  params: PropTypes.object,
  initialDepositMin: PropTypes.number,
  questionnaire: PropTypes.object,
  allQuestionsAnswered: PropTypes.array,

  changeModalVisibility: PropTypes.func,
  fetchProjections: PropTypes.func,
  fetchRecommended: PropTypes.func,
  changeGoalField: PropTypes.func,
  redirectToNextStep: PropTypes.func,
  updateOrCreateGoal: PropTypes.func,
  resetError: PropTypes.func,
  setNotValid: PropTypes.func,
  progress: PropTypes.number,
  prevStep: PropTypes.shape({
    headline: PropTypes.string,
    module: PropTypes.string,
    questionCode: PropTypes.number,
  }),
  activeStep: PropTypes.shape({
    headline: PropTypes.string,
  }),
}

export default compose(
  withMediaQueries(rawMediaQueries),
  connect(
    ({ client, portfolios, questionnaire }) => {
      const selectedGoalId = portfolios.selectedGoal
      const selectedGoal = portfolios.items.find((goal) => goal.id === selectedGoalId) || {}

      return {
        presets: portfolios.presets[selectedGoal?.preset_type] || [],
        goal: {
          ...selectedGoal,
          term: selectedGoal?.term ?? 10,
        },
        goalError: portfolios.error,
        client,
        questionnaire,
        allQuestionsAnswered: isAllQuestionsAnswered({ questionnaire }),
        loading: client.didInvalidate || portfolios.didInvalidate,
        initialDepositMin: $dictsStore.getState().initialDepositMin,
        // temporary until it is a functional component
        nominatedBankAccount: $bankAccountsStore.getState().nominatedAccount,
      }
    },
    {
      changeGoalField,
      updateOrCreateGoal,
      setNotValid,
      fetchRecommended,
      fetchProjections,
      resetError,
      changeModalVisibility,
    },
    (stateProps, dispatchProps, ownProps) => {
      const { presets, ...restStateProps } = stateProps
      const { mediaQueries } = ownProps
      const { desktop } = mediaQueries

      return {
        presets,
        ...restStateProps,
        ...dispatchProps,
        ...ownProps,
        monthly: restStateProps.goal && restStateProps.goal.term < 9 && desktop,
      }
    },
  ),
)(Growth)
