import { type TransferProgressDTO } from '../../api'

import { type TransferProgress } from './TransferProgress'
import { TransferProgressList } from './TransferProgressList'

class TransferProgressCollection {
  constructor(
    collection?:
      | TransferProgressCollection
      | Record<string, Array<Partial<TransferProgressDTO> | Partial<TransferProgress>>>,
  ) {
    if (collection) {
      Object.entries(collection).forEach(([key, value]) => {
        if (value instanceof TransferProgressList) {
          this[key] = value
          return
        }

        if (Array.isArray(value)) {
          this[key] = new TransferProgressList(...value)
        }
      })
    }
  }

  set(portfolioId: number, transferProgressList: TransferProgressDTO[]): TransferProgressCollection {
    return new TransferProgressCollection({
      ...this,
      [`${portfolioId}`]: new TransferProgressList(...transferProgressList),
    })
  }

  get(portfolioId: number): TransferProgressList | undefined {
    return this[`${portfolioId}`]
  }

  delete(portfolioId: number, transferId: number): TransferProgressCollection {
    return new TransferProgressCollection({
      ...this,
      [`${portfolioId}`]: new TransferProgressList(
        ...this[`${portfolioId}`].filter((transfer) => transfer.id !== transferId),
      ),
    })
  }
}

export { TransferProgressCollection }
