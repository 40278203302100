import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import rawMediaQueries from 'helpers/mediaQueries.js'
import { modsToClassnames } from 'helpers/classname.js'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import './Inner.css'

@withMediaQueries(rawMediaQueries)
export default class Inner extends React.Component {
  static contextTypes = {
    narrow: PropTypes.bool,
  }

  render() {
    const { narrow } = this.context
    const {
      className,
      mods = {},
      flex,
      style,
      children,
      mediaQueries,
      twoColumns,
      'data-test-id': dataTestId,
      ...rest
    } = this.props

    if (narrow && !mods.size) {
      mods.size = 'small'
    }

    const classes = classNames(
      className,
      'Inner',
      { Inner_flex: flex },
      { Inner_two_columns: twoColumns },
      modsToClassnames('Inner', mods),
      getMediaQuieryClasses('Inner', mediaQueries),
    )

    return (
      <div className={classes} style={style} data-test-id={dataTestId} {...rest}>
        {children}
      </div>
    )
  }
}

Inner.propTypes = {
  className: PropTypes.string,
  mods: PropTypes.object,
  flex: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node,
  mediaQueries: PropTypes.shape({
    desktop: PropTypes.bool,
  }),
  twoColumns: PropTypes.bool,
  'data-test-id': PropTypes.string,
}
