import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries, useMemo, useEffect, useActions } from 'hooks'

import { urlTo } from 'helpers/router.js'

import { $2faState, sendEvent, setQuery, setIsPhoneVerified, State, Event, fetchAuthFactorsFx } from 'app/effector/2fa'

import { changeNotificationSettings as changeNotificationSettingsActionCreator } from 'app/redux/actions/ui'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { TwoFactorAwait } from 'components/_old/TwoFactorAwait/TwoFactorAwait'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'

import { TwoFactorAuthDeniedModal } from './TwoFactorAuthDeniedModal'

import './TwoFactorAuth.css'

type TwoFactorAuthProps = {
  location?: {
    query?: Record<string, string>
  }
}

const TwoFactorAuth = ({ location: { query = {} } = {} }: TwoFactorAuthProps): React.ReactElement => {
  const state = useUnit($2faState)
  const { desktop } = useMediaQueries()
  const { isAgreeWithNotifications } = query
  const [changeNotificationSettings] = useActions([changeNotificationSettingsActionCreator])

  useEffect(() => {
    fetchAuthFactorsFx()
  }, [])

  useEffect(() => {
    setQuery(query)
  }, [query])

  const TwoFactorAwaitNode = useMemo(
    () => (
      <Fragment>
        {state !== State.DENIED && (
          <TwoFactorAwait
            isNotificationIssueInModal={false}
            setIsPhoneVerified={setIsPhoneVerified}
            onAccept={() => {
              sendEvent(Event.ACCEPT)
              if (isAgreeWithNotifications) {
                changeNotificationSettings('promo', true)
              }
            }}
            onDeny={() => {
              sendEvent(Event.DENY)
            }}
            onError={() => {
              sendEvent(Event.ERROR)
            }}
            onCancel={() => {
              sendEvent(Event.CANCEL)
            }}
            query={query}
          />
        )}
        <TwoFactorAuthDeniedModal
          open={state === State.DENIED}
          onClose={() => {
            sendEvent(Event.CLOSE)
          }}
        />
      </Fragment>
    ),
    [state, query, isAgreeWithNotifications, changeNotificationSettings],
  )

  const headerRight = (
    <span>
      <Paper right={20} style={{ display: 'inline' }}>
        <Typography tag="span" color="minor" size={14}>
          Don’t have an account?
        </Typography>
      </Paper>
      <Button mods={{ color: 'green', size: 'small', text: 'smaller' }}>
        <Link to={urlTo('pre-flight')} classnameless data-test-id="getStartedButton">
          Get started
        </Link>
      </Button>
    </span>
  )
  const header = <Header right={headerRight} withShadow logoLink="/" hard />

  const content = (
    <div className="Login-Wrapper">
      <AllCenter style={{ minHeight: 840 }}>
        <Width size={36}>
          <Card className="TwoFactorAuthModal" color="background-default">
            {TwoFactorAwaitNode}
          </Card>
        </Width>
      </AllCenter>
    </div>
  )

  return desktop ? (
    <Fragment>
      <DesktopLayout header={header} content={content} footer={<DesktopFooter />} noGaps />

      <GatewayDest name="toasts" />
    </Fragment>
  ) : (
    TwoFactorAwaitNode
  )
}

export { TwoFactorAuth }
