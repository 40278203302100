import React, { Fragment } from 'react'

import { palette } from 'helpers/palette/'

import { type Document } from 'app/effector/secure-document-upload/types'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { CardNew } from 'components/atoms/CardNew'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DocumentUploadModal } from '../DocumentUploadModal/DocumentUploadModal'

import { useDocumentUpload } from './useDocumentUpload'

type DocumentUploadProps = {
  document: Document
}

const DocumentUpload = ({ document }: DocumentUploadProps): React.ReactElement => {
  const { isModalVisible, isError, isLoading, uploadedFiles, handleFiles, handleClearFiles, showModal, hideModal } =
    useDocumentUpload({
      document,
    })

  const hasUploadedFiles = !!uploadedFiles.length

  const uploadedFilesNode = uploadedFiles.map((fileName) => (
    <Typography color="additional" key={fileName} overflow="ellipsis">
      <Typo>{fileName}</Typo>
    </Typography>
  ))

  const changeFilesLinkNode = <Link onClick={showModal}>Change</Link>

  const documentCardNode = (
    <CardNew
      borderRadius={6}
      backgroundColor={palette['background-card']}
      hoverColor={palette['background-card']}
      clickable={!hasUploadedFiles}
      shadow={3}
    >
      <Paper
        top={20}
        right={20}
        bottom={20}
        left={20}
        onClick={() => {
          if (hasUploadedFiles) return
          showModal()
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography>
            <Typo>{document.title}</Typo>
          </Typography>

          <Icon type={uploadedFiles?.length ? 'done-new-32' : 'add-new-32'} />
        </div>
        {hasUploadedFiles && (
          <Fragment>
            {uploadedFilesNode}
            {changeFilesLinkNode}
          </Fragment>
        )}
      </Paper>
    </CardNew>
  )

  return (
    <Fragment>
      {documentCardNode}

      <DocumentUploadModal
        document={document}
        isVisible={isModalVisible}
        isLoading={isLoading}
        isError={isError}
        uploadedFiles={uploadedFiles}
        onFilesSelected={handleFiles}
        onClearFiles={handleClearFiles}
        onClose={hideModal}
      />
    </Fragment>
  )
}

export { DocumentUpload }
