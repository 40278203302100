import React from 'react'

import classNames from 'classnames/dedupe'

import { useMemo, useState, useEffect, useMediaQueries } from 'hooks'

import { Card } from 'components/atoms/Card'

import './Card.css'

enum PointOfInterestCardPositions {
  LEFT = 'left',
  RIGHT = 'right',
}

const CombinedPointOfInterestType = 'COMBINED' as const

type AbstractPointOfInterest = {
  type: string
  date: Date
  position?: PointOfInterestCardPositions
  hard?: boolean
  [key: string]: unknown
}

type AbstractCombinedPointOfInterest = {
  type: typeof CombinedPointOfInterestType
  date: Date
  position?: PointOfInterestCardPositions
  hard?: boolean
  data: Array<Record<string, unknown>>
  [key: string]: unknown
}

type PointOfInterestCardData = [JSX.Element | null, number, number, PointOfInterestCardPositions]

type PointOfInterestCardProps = {
  className?: string
  pointOfInterest?: AbstractPointOfInterest | AbstractCombinedPointOfInterest | null
  width?: number
  center?: number
  position: PointOfInterestCardPositions
  renderContent: (pointOfInterest?: AbstractPointOfInterest | null) => JSX.Element | null
}

function isPointOfInterestAlike(object: Record<string, unknown>): object is AbstractPointOfInterest {
  return typeof object.type === 'string' && object.date instanceof Date
}

function PointOfInterestCard({
  className,
  pointOfInterest,
  width: widthRaw,
  center: centerRaw,
  position: positionRaw,
  renderContent,
  'data-test-id': dataTestId = 'pointOfInterestCard',
}: PointOfInterestCardProps): React.ReactElement {
  const { desktop } = useMediaQueries()

  const data = useMemo(
    () => [renderContent(pointOfInterest), widthRaw, centerRaw, positionRaw] as PointOfInterestCardData,
    [pointOfInterest, renderContent, widthRaw, centerRaw, positionRaw],
  )

  const [cachedData, setCachedData] = useState<PointOfInterestCardData | null>(data)

  useEffect(() => {
    if (data[0]) {
      setCachedData(data)
    }
  }, [data, setCachedData])

  useEffect(() => {
    return () => {
      setCachedData(null)
    }
  }, [])

  const [content, width, center, position] = useMemo(() => {
    if (data[0]) {
      return data
    }

    return cachedData ?? []
  }, [data, cachedData])

  const visible = Boolean(data[0])

  const classes = useMemo(
    () =>
      classNames(className, 'PointOfInterestCard', {
        [`PointOfInterestCard_position_${position ?? PointOfInterestCardPositions.LEFT}`]: position,
        PointOfInterestCard_visible: visible,
        PointOfInterestCard_hard: pointOfInterest?.hard,
        PointOfInterestCard_desktop: desktop,
      }),
    [className, pointOfInterest?.hard, position, visible, desktop],
  )

  const style = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ({
        '--point-of-interest-width': `${width ?? 158}px`,
        '--point-of-interest-center': `${center ?? 0}px`,
      }) as React.CSSProperties,
    [width, center],
  )

  return (
    <Card className={classes} style={style} data-test-id={dataTestId}>
      {content}
    </Card>
  )
}

export {
  isPointOfInterestAlike,
  PointOfInterestCard,
  PointOfInterestCardPositions,
  CombinedPointOfInterestType,
  type PointOfInterestCardProps,
  type AbstractPointOfInterest,
  type AbstractCombinedPointOfInterest,
}
