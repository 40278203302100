import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'

const Notice = ({ isRebalance }) => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <Paper top={desktop ? 56 : 48}>
        {isRebalance ? (
          <Fragment>
            <ItemWithIcon
              space={12}
              icon={<Icon size={24} type="time-24" color="inherit" />}
              content={
                <Fragment>
                  <Typography size={desktop ? 14 : 12}>
                    <Typo>
                      Orders are executed after 2.30pm daily but your portfolio may not be updated until 7pm. Orders
                      created after 2pm will be processed on the next business day.
                    </Typo>
                  </Typography>
                </Fragment>
              }
            />
            <Paper top={desktop ? 24 : 16}>
              <ItemWithIcon
                space={12}
                icon={<Icon size={24} type="piechart-24" />}
                content={
                  <Fragment>
                    <Typography size={desktop ? 14 : 12}>
                      <Typo>
                        Actual trade amounts will be recalculated at execution and may differ slightly from those shown.
                      </Typo>
                    </Typography>
                  </Fragment>
                }
              />
            </Paper>
          </Fragment>
        ) : (
          <ItemWithIcon
            space={12}
            icon={<Icon size={24} type="time-24" color="inherit" />}
            content={
              <Fragment>
                <Typography size={desktop ? 14 : 12}>
                  <Typo>
                    Orders are executed after 2.30pm daily but your portfolio may not be updated until 7pm. Orders
                    created after 2pm will be processed on the next business day. Actual amounts may differ slightly
                    from those below.
                  </Typo>
                </Typography>
              </Fragment>
            }
          />
        )}
      </Paper>
    </Fragment>
  )
}

Notice.propTypes = {
  isRebalance: PropTypes.bool,
}

export { Notice }
