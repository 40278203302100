import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useActions, useCallback, useMediaQueries } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { showSupportModal as showSupportModalActionCreator } from 'app/redux/actions/ui'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

type SippWithdrawModalProps = {
  params: {
    id: string
  }
  location: {
    query: {
      withdrawSippModalOpened: string
    }
  }
}

const SippWithdrawModal = withRouter(({ params, location }: SippWithdrawModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { withdrawSippModalOpened, ...restQuery } = location?.query ?? {}
  const isOpened = withdrawSippModalOpened === 'true'

  const [showSupportModal] = useActions([showSupportModalActionCreator])

  const handleClose = useCallback(() => {
    goTo(urlTo('dashboard.portfolio', params, restQuery))
  }, [params, restQuery])

  const headerNode = <NavigationBar rightPartText="Close" onRightPartClick={handleClose} />

  const buttonNode = (
    <Button onClick={handleClose} mods={{ size: 'big block' }}>
      Continue
    </Button>
  )

  const content = (
    <Fragment>
      <Paper top={desktop ? 24 : 0}>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
          <Typo>Personal Pension Withdrawals</Typo>
        </Typography>
      </Paper>
      <Paper top={32}>
        <Typography align="center">
          <Typo>
            Withdrawals are not available for our Personal Pension accounts. Please{' '}
            <Link onClick={showSupportModal}>contact our support</Link> if you want more details or information on your
            IE Personal Pension.
          </Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  if (desktop) {
    return (
      <Modal open={isOpened} onClose={handleClose} close={null}>
        <Width size={36} style={{ minHeight: '600px' }}>
          <Paper top={8} bottom={8} right={24} left={24}>
            {headerNode}
          </Paper>
          <Paper top={80}>
            <Width size={27} center>
              {content}
              <Paper top={56}>
                <Width size={21.5} center>
                  {buttonNode}
                </Width>
              </Paper>
            </Width>
          </Paper>
        </Width>
      </Modal>
    )
  }

  return (
    <Modal open={isOpened} onClose={handleClose} close={null}>
      <MobileLayout
        header={headerNode}
        content={<AllCenter>{content}</AllCenter>}
        footer={<MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
})

export { SippWithdrawModal }
