import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { Preloader } from 'components/molecules/Preloader'

import { ShareModal } from 'app/pages/SharedPortfolio/components/ShareModal'

import { SharingTitleEditModal } from './components/SharingTitleEditModal'
import { TsCsModal } from './components/TsCsModal'
import { Desktop } from './Desktop/Desktop'
import { useSharePortfolio } from './hooks/useSharePortfolio'
import { Mobile } from './Mobile/Mobile'

type SharePortfolioProps = {
  params: {
    id: string
  }
  location: { query: { back: string; descriptionExpanded: boolean } }
}

const SharePortfolio = ({
  params: { id: portfolioId },
  location: {
    query: { back: backLink, descriptionExpanded },
  },
}: SharePortfolioProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const propsForSharing = useSharePortfolio(portfolioId, backLink, descriptionExpanded)

  const {
    isLoading,
    isPromoEnabled,
    isShareModalOpened,
    closeShareModal,
    shareUrl,
    shareTitle,
    isTitleEditModalOpen,
    closeTitleEditModal,
    sharingTitle,
    setSharingTitle,
    sharingDescription,
    setSharingDescription,
    saveSharingTitle,
    showTsCsModal,
    handleCloseTsCsModal,
  } = propsForSharing

  return (
    <Fragment>
      {desktop ? <Desktop {...propsForSharing} /> : <Mobile {...propsForSharing} />}
      <ShareModal open={isShareModalOpened} shareUrl={shareUrl} shareTitle={shareTitle} onClose={closeShareModal} />
      <SharingTitleEditModal
        open={isTitleEditModalOpen}
        onClose={closeTitleEditModal}
        title={sharingTitle}
        onChangeTitle={setSharingTitle}
        description={sharingDescription}
        onChangeDescription={setSharingDescription}
        onSave={saveSharingTitle}
      />
      <TsCsModal isPromoEnabled={isPromoEnabled} show={showTsCsModal} handleClose={handleCloseTsCsModal} />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
      <Preloader loading={isLoading} size="big" zIndex />
    </Fragment>
  )
}

export { SharePortfolio }
