import isNull from 'lodash/isNull'

type Nullable<Type> = Type | null

// can't use `guard` name 'cuz of the effector bug → https://github.com/effector/effector/issues/482
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function nullable<Type>(notNullGuard: (...args: [unknown, ...any]) => Type) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: [unknown, ...any]): Nullable<Type> => {
    const unknown = args[0]

    if (isNull(unknown ?? null)) {
      return null
    }

    return notNullGuard(...args) ?? null
  }
}

function isNullableNull<Type>(item: Nullable<Type>): item is null {
  return isNull(item)
}

export { nullable, type Nullable, isNullableNull }
