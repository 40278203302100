import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import { NavigationBar } from 'components/atoms/NavigationBar'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Width from 'components/_old/Width/Width'
import Inner from 'components/_old/Inner/Inner.jsx'
import Segment from 'components/atoms/Segment/Segment.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import { BankAccountStateContent } from 'app/pages/Dashboard/Goals/components/BankAccount/BankAccountStateContent.jsx'

const Success = ({ onAddFundsNow: handleAddFundsNow, onAddFundsLater: handleAddFundsLater, fromPortfolio }) => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      {!desktop && (
        <NavigationBar rightPartText="Skip" onRightPartClick={handleAddFundsLater}>
          Success!
        </NavigationBar>
      )}
      <AllCenter>
        <Width size={65}>
          <Width size={40} center>
            <Inner>
              <BankAccountStateContent
                title="Instant bank transfer is now available"
                description={`Open Banking is set up.${
                  fromPortfolio ? ' Please fund your InvestEngine portfolio.' : ''
                }`}
                icon={{
                  type: 'checkmark_in_a_circle',
                  color: palette['status-success'],
                  size: 48,
                }}
                data-test-id="addNewBankSuccess"
              >
                <Segment mods={{ margin: 'hefty', noMargin: 'top' }}>
                  <ModalContentButtons width={desktop ? 16 : 24} isNewModal>
                    <Button mods={{ size: 'big block' }} onClick={handleAddFundsNow} data-test-id="addFundsNowButton">
                      {fromPortfolio ? 'Add cash now' : 'Continue'}
                    </Button>
                  </ModalContentButtons>
                </Segment>
              </BankAccountStateContent>
            </Inner>
          </Width>
        </Width>
      </AllCenter>
    </Fragment>
  )
}

Success.propTypes = {
  onAddFundsNow: PropTypes.func.isRequired,
  onAddFundsLater: PropTypes.func.isRequired,
  fromPortfolio: PropTypes.bool,
}

export { Success }
