import React, { Fragment } from 'react'

import { useMediaQueries, useMemo, useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { FieldLine } from 'components/_old/atoms/FieldLine'
import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { FieldLineList } from 'components/_old/molecules/FieldLineList'
import { Progress } from 'components/_old/Progress'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { DesktopLayout, MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'

import { DesktopFooter } from 'app/containers/Footer'
import FeeInfo from 'app/containers/Questionnaire/FeeInfo/FeeInfo.jsx'

type AllAnswersProps = {
  progress: number
  activeStep: {
    headline?: string
    feeInfo?: boolean
  }
  prevStep: {
    isQuestion?: boolean
    headline?: string
    module: string
    questionCode?: string
  }
  clientAnswersOnQuestions: Array<{
    answers: string[]
    code: string
    question: string
    type: string
  }>
  location: {
    search?: string
  }
  params: Record<string, unknown>
  withDesktopLayout: boolean
  redirectToNextStep?: (customParams?: Record<string, string>) => void
  retakeQuestionnaire: () => void
}

function AllAnswers({
  progress = 98.5,
  activeStep,
  prevStep,
  clientAnswersOnQuestions,
  location,
  params,
  withDesktopLayout = true,
  redirectToNextStep,
  retakeQuestionnaire,
}: AllAnswersProps): React.ReactElement {
  const backLink = useMemo(() => {
    if (prevStep.isQuestion) {
      return urlTo(prevStep.module, { ...(params ?? {}), questionCode: prevStep.questionCode }, location.search)
    }

    return urlTo(prevStep.module, params, location.search)
  }, [prevStep, params, location.search])

  const handleBack = useCallback(() => {
    goTo(backLink)
  }, [backLink])

  const handleCancel = useCallback(() => {
    goTo(urlTo('dashboard'))
  }, [])

  const { desktop } = useMediaQueries()

  const header = useMemo(() => {
    if (!activeStep.headline) {
      return undefined
    }

    if (desktop) {
      return (
        <Header
          right={
            <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}>
              <Link to={urlTo('dashboard')}>My Dashboard</Link>
            </Button>
          }
          after={
            <Fragment>
              <Progress fill={progress} gapWidth="1em" />
              {activeStep.feeInfo && <FeeInfo />}
            </Fragment>
          }
          hideDrawer
        />
      )
    }

    return (
      <Fragment>
        <NavigationBar
          leftPartText={backLink ? 'Back' : undefined}
          onLeftPartClick={backLink ? handleBack : undefined}
          rightPartText="Cancel"
          onRightPartClick={handleCancel}
        >
          Get started
        </NavigationBar>
        <Progress fill={progress} gapWidth="1em" />
      </Fragment>
    )
  }, [activeStep.headline, activeStep.feeInfo, desktop, backLink, handleBack, handleCancel, progress])

  const heading = useMemo(
    () =>
      activeStep.headline && (
        <Paper top={64}>
          <NavigationBar
            leftPartText={backLink ? prevStep?.headline ?? 'Back' : undefined}
            onLeftPartClick={backLink ? handleBack : undefined}
          >
            <span data-test-id="headline">
              <Typo>{activeStep.headline}</Typo>
            </span>
          </NavigationBar>
        </Paper>
      ),
    [prevStep?.headline, activeStep.headline, backLink, handleBack],
  )

  const retakeButton = useMemo(() => {
    if (retakeQuestionnaire) {
      return (
        <Button
          type="submit"
          mods={{ size: 'big block', theme: 'simple-reverse-blue' }}
          onClick={retakeQuestionnaire}
          data-test-id="questionnaireRetake"
        >
          Retake questionnaire
        </Button>
      )
    }
  }, [retakeQuestionnaire])

  const continueButton = useMemo(() => {
    if (redirectToNextStep) {
      return (
        <Button
          type="submit"
          mods={{ size: 'big block' }}
          onClick={redirectToNextStep}
          data-test-id="questionnaireSubmit"
        >
          Continue
        </Button>
      )
    }

    return null
  }, [redirectToNextStep])

  const content = useMemo(() => {
    const content: JSX.Element[] = []

    if (desktop && heading) {
      content.push(heading)
    }

    if (!desktop) {
      content.push(
        <Paper top={8} bottom={32}>
          <Typography size={20} weight="semibold" align="center">
            <Typo>{activeStep.headline}</Typo>
          </Typography>
        </Paper>,
      )
    }

    clientAnswersOnQuestions &&
      clientAnswersOnQuestions.length > 0 &&
      content.push(
        <Paper top={desktop ? 32 : null}>
          <Width size={desktop ? 36 : null} center>
            <FieldLineList>
              {clientAnswersOnQuestions.map(({ question, answers }, index) => (
                <FieldLine key={index} label={question} layout="vertical">
                  {answers.join(', ')}
                </FieldLine>
              ))}
            </FieldLineList>
          </Width>
        </Paper>,
      )

    if (desktop) {
      content.push(
        <Paper top={40} bottom={120}>
          <Width size={36} center>
            <ButtonGroup>
              {retakeButton}
              {continueButton}
            </ButtonGroup>
          </Width>
        </Paper>,
      )
    }

    if (desktop) {
      return <Inner>{content}</Inner>
    }

    return <Fragment>{content}</Fragment>
  }, [desktop, heading, clientAnswersOnQuestions, activeStep.headline, retakeButton, continueButton])

  if (desktop) {
    if (withDesktopLayout) {
      return <DesktopLayout header={header} content={content} footer={<DesktopFooter />} noGaps />
    }

    return (
      <Fragment>
        {header}
        {content}
        <DesktopFooter />
      </Fragment>
    )
  }

  return (
    <MobileLayout
      header={header}
      content={content}
      footer={
        <Paper top={24}>
          <MobileLayoutFooterButton>{retakeButton}</MobileLayoutFooterButton>
          <MobileLayoutFooterButton>{continueButton}</MobileLayoutFooterButton>
        </Paper>
      }
    />
  )
}

export { AllAnswers, type AllAnswersProps }
