import React from 'react'

import { useCallback, useMediaQueries, useMemo, useState } from 'hooks'

import { getCurrentTheme, palette, setCurrentTheme, ThemeNames, Themes } from 'helpers/palette'
import { goTo, urlTo } from 'helpers/router.js'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Modal } from 'components/molecules/Modal'

import { SettingsLayout } from '../components/SettingsLayout'
import { SettingsRow } from '../components/SettingsRow'

const Appearance = ({ routes }): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const [currentTheme, setSelectedTheme] = useState(getCurrentTheme())
  const currentThemeName = useMemo(() => ThemeNames[getCurrentTheme()], [])
  const isThemeModalOpen = useMemo(() => routes.findIndex((route) => route.module === 'theme') > -1, [routes])

  const handleThemeModalClose = useCallback(() => {
    goTo(urlTo('dashboard.user-profile.appearance'))
  }, [])

  const handleThemeChange = useCallback(
    (theme: Themes) => {
      setCurrentTheme(theme)
      setSelectedTheme(theme)
    },
    [setSelectedTheme],
  )

  const themeLabel = useMemo(() => {
    const rightPart = (
      <ItemWithIcon
        inline
        space={4}
        iconPosition="right"
        icon={<Icon type="arrow-right-small-24" size={24} color={palette['content-on-background-minor']} />}
        content={
          <Typography lineHeight="small" color="minor">
            <Typo>
              <Nobr>{currentThemeName}</Nobr>
            </Typo>
          </Typography>
        }
        iconVerticalAlign="center"
        contentVerticalAlign="center"
      />
    )

    return desktop ? (
      rightPart
    ) : (
      <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
        <Column size={1}>
          <Typography lineHeight="small" color="inherit">
            <Typo>Theme</Typo>
          </Typography>
        </Column>
        <Column size={0}>{rightPart}</Column>
      </ColumnarLayout>
    )
  }, [desktop, currentThemeName])

  return (
    <SettingsLayout title="Appearance">
      <SettingsRow labelText="Theme" to={urlTo('dashboard.user-profile.appearance.theme')}>
        {themeLabel}
      </SettingsRow>
      <Modal open={isThemeModalOpen} onClose={handleThemeModalClose} close={null}>
        <Width size={36}>
          <Paper
            top={desktop ? 8 : null}
            bottom={desktop ? 56 : null}
            right={desktop ? 24 : null}
            left={desktop ? 24 : null}
          >
            <NavigationBar onRightPartClick={handleThemeModalClose} rightPartText="Close">
              {!desktop && <Typo>Theme</Typo>}
            </NavigationBar>
          </Paper>
          {desktop && (
            <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
              <Typo>Theme</Typo>
            </Typography>
          )}
          <Paper bottom={desktop ? 56 : 16} right={desktop ? 56 : 16} left={desktop ? 56 : 16}>
            <Paper top={24}>
              <Stack vertical={12}>
                {Object.entries(Themes).map(([key, value]) => (
                  <SelectableCard
                    key={key}
                    selected={currentTheme === value}
                    onClick={() => {
                      handleThemeChange(value)
                    }}
                  >
                    <Paper top={16} right={24} bottom={16} left={24}>
                      <Typography>
                        <Typo>{ThemeNames[key]}</Typo>
                      </Typography>
                    </Paper>
                  </SelectableCard>
                ))}
              </Stack>
            </Paper>
          </Paper>
        </Width>
      </Modal>
    </SettingsLayout>
  )
}

export { Appearance }
