import { useUnit } from 'effector-react'

import { useActions, useCallback } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'
import { features } from 'helpers/features'
import { getIdentityCheckStatus, IDCheckResult } from 'helpers/id-check'

import {
  $owner,
  $contacts,
  fetchContactsFx,
  deleteContactFx,
  changeContactField,
  updateOrCreateContactFx,
} from 'app/effector/contacts'

import {
  setValid as setClientValidActionCreator,
  setNotValid as setClientNotValidActionCreator,
} from 'app/redux/actions/client'
import { changeField as changeUiFieldActionCreator } from 'app/redux/actions/ui'
import { ApiError } from 'app/redux/models/errors'

import { GP_DEFAULT, GP_VERIFY_IDENTITY_SUCCESS, GP_VERIFYING_IDENTITY } from 'constants/globalPreloaderStatuses.js'

const useContacts = (clientType) => {
  const [changeUiField, setClientValid, setClientNotValid] = useActions([
    changeUiFieldActionCreator,
    setClientValidActionCreator,
    setClientNotValidActionCreator,
  ])

  const contacts = useUnit($contacts)
  const owner = useUnit($owner) ?? {}

  const changeOwnerField = useCallback(
    (field, value) => changeContactField({ field: { [field]: value }, id: owner.id }),
    [owner?.id],
  )

  const updateOwner = async (keys, data) => {
    const result = await updateOrCreateContactFx({ keys, id: owner?.id, data })

    if (result instanceof ApiError) {
      return { result: false, error: result }
    }

    return { result: true }
  }

  const updateContact = async ({ keys, id, data }) => {
    const result = await updateOrCreateContactFx({ id, keys, data })

    if (result instanceof ApiError) {
      return { result: false, error: result }
    }

    return { result: true, contacts: contacts.contacts }
  }

  const handleIdentityCheckResult = useCallback(
    async (isPass, callback) => {
      await fetchContactsFx()

      if (isPass) {
        changeUiField({ globalPreloaderStatus: GP_VERIFY_IDENTITY_SUCCESS })

        trackEvent(
          {
            action: 'identity_verified',
            client_type: clientType,
          },
          {
            plugins: { 'google-analytics-v3': false },
          },
        )

        setTimeout(() => {
          setClientValid()
          changeUiField({ globalPreloaderStatus: GP_DEFAULT })
        }, 2000)
      }

      if (!isPass) {
        // need to re fetch to check is there features.get('onfido') enabled
        await features.fetch()

        // Remove preloader on the next tick to make sure that unmount of the current screen unmounts correctly
        setTimeout(() => {
          setClientValid()
          changeUiField({ globalPreloaderStatus: GP_DEFAULT })
        }, 0)
      }

      if (callback) {
        await callback()
      }

      return isPass
    },
    [changeUiField, clientType, setClientValid],
  )

  const passIdentityCheck = useCallback(
    async (contactId, onSuccessIdentityCheck, onFailIdentityCheck) => {
      try {
        setClientNotValid()
        changeUiField({ globalPreloaderStatus: GP_VERIFYING_IDENTITY })

        const identityCheckStatus = await getIdentityCheckStatus(contactId)
        const isPass = identityCheckStatus === IDCheckResult.PASS

        return await handleIdentityCheckResult(isPass, isPass ? onSuccessIdentityCheck : onFailIdentityCheck)
      } catch (error) {
        processError({ error, forceReset: true })
        await handleIdentityCheckResult(false, onFailIdentityCheck)
      }
    },
    [changeUiField, handleIdentityCheckResult, setClientNotValid],
  )

  return {
    contacts,
    owner,
    changeOwnerField,
    changeContactField,
    updateOwner,
    updateContact,
    passIdentityCheck,
    deleteContact: deleteContactFx,
  }
}

export { useContacts }
