import localstore from 'helpers/localstore.js'
import { goTo, urlTo } from 'helpers/router.js'

let logoutIsProcessing = false

const setLogoutProcessingFinish = () => {
  logoutIsProcessing = false
}

const redirectToLogout = (nextUrl) => {
  goTo(urlTo('logout', null, { next: nextUrl }), { replace: true })
}

const handle401 = ({ error }) => {
  if (!error?.response || error.response.status !== 401) {
    return
  }

  const isOnPreFlight = window ? window.location.toString().includes('/pre-flight/') : false
  const isOnLogin = window ? window.location.toString().includes('/login/') : false
  const inOn2fa = window ? window.location.toString().includes('/login/2fa/') : false
  const isOnForgotPassword = window ? window.location.toString().includes('/forgot-password/') : false

  const nextUrl = window.location.pathname + window.location.search
  const loginUrl = isOnForgotPassword
    ? window.location.pathname
    : urlTo('login', null, isOnLogin ? { next: nextUrl } : { next: nextUrl, sessionExpired: true })

  localstore.remove('client')

  if (isOnPreFlight) {
    logoutIsProcessing = true

    if (!window?.location?.search.includes('do_not_logout=true')) {
      return redirectToLogout(urlTo('pre-flight', null, window.location.search))
    }
  }

  if (!logoutIsProcessing && !inOn2fa) {
    logoutIsProcessing = true
    return redirectToLogout(loginUrl)
  }
}

export { handle401, setLogoutProcessingFinish }
