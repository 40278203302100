import { useCallback, useLoading, useEffect } from 'hooks/'

import { handleExternalLink } from 'helpers/browser/'
import { sendError } from 'helpers/errorLogging'
import { goTo, urlTo } from 'helpers/router'

import { authenticateData } from 'app/effector/bank-accounts/api'

import { showFailToast } from 'app/redux/actions/ui/'
import type { Portfolio } from 'app/redux/models/portfolio/types'

type UseSetUpInstantBankProps = {
  portfolio: Portfolio
  location: { query?: { back?: string } }
  tunnelQuery: Record<string, string>
  nextUrl?: string
  toggleCloseButton: (isVisible: boolean) => void
}

type UseSetUpInstantBankInterface = {
  isLoading: boolean
  handleBack: () => void
  handleSetUpBank: () => void
}

function useSetUpInstantBank({
  portfolio,
  location,
  tunnelQuery,
  nextUrl,
  toggleCloseButton,
}: UseSetUpInstantBankProps): UseSetUpInstantBankInterface {
  const { isLoading, wait } = useLoading()

  const handleBack = useCallback(() => {
    const backUrl = location?.query?.back ?? urlTo(`dashboard.portfolio.add-funds`, { id: portfolio?.id }, tunnelQuery)

    goTo(backUrl, { scrollToTop: false })
  }, [location?.query?.back, portfolio?.id, tunnelQuery])

  const handleSetUpBank = useCallback(() => {
    try {
      const url = nextUrl ?? urlTo(`dashboard.portfolio.add-funds.new-bank`, { id: portfolio?.id }, tunnelQuery)
      wait(handleExternalLink(authenticateData, url), true)
    } catch (error) {
      sendError(error)
      showFailToast()
    }
  }, [portfolio?.id, tunnelQuery, nextUrl, wait])

  // Remove when add cash will be the part of options menu
  useEffect(() => {
    if (typeof toggleCloseButton === 'function') {
      toggleCloseButton(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isLoading, handleBack, handleSetUpBank }
}

export { useSetUpInstantBank }
