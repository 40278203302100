import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

export enum NomineesTransferProviderRegulatoryType {
  'ISA' = 'ISA',
  'SIPP' = 'SIPP',
}

export enum NomineesTransferProviderSignatureType {
  WET = 'WET',
  ELECTRONIC = 'Electronic',
  WET_PARTIAL = 'WET_PARTIAL',
  ELECTRONIC_PARTIAL = 'Electronic_PARTIAL',
}

export type NomineesTransferProviderDTO = {
  id: number
  regulatory_type: NomineesTransferProviderRegulatoryType
  name: string
  logo?: string
  regexp?: string
  error_message?: string
  signature_type: NomineesTransferProviderSignatureType | null
}

export const getFetchNomineesTransferProvidersURL = (
  regulatoryType?: NomineesTransferProviderRegulatoryType,
): string => {
  const baseUrl = 'nominees-transfers/providers/'

  if (regulatoryType) {
    return `${baseUrl}?regulatory_type=${regulatoryType}`
  }

  return baseUrl
}
export const fetchNomineesTransferProviders = async (
  regulatoryType?: NomineesTransferProviderRegulatoryType,
): Promise<NomineesTransferProviderDTO[] | ApiError> => {
  try {
    const { data } = await axios.get(getFetchNomineesTransferProvidersURL(regulatoryType))

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
fetchNomineesTransferProviders.getUrl = getFetchNomineesTransferProvidersURL
