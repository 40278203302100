import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { ControlledInput } from 'components/atoms/Forms'
import { EditableSecurity } from 'components/molecules/EditableSecurity'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

import './EditableSecurityList.css'

const EditableSecurityList = ({ securities, control, handleDelete, highlightedRef }) => {
  const { desktop } = useMediaQueries()
  const classes = classNames('EditableSecurityList', { EditableSecurityList_desktop: desktop })

  const securitiesNode = useMemo(
    () => {
      if (securities.length < 1) {
        return null
      }

      return securities.map((security, index) => {
        const prev = securities[index - 1]
        const next = securities[index + 1]
        const isFirstNew = (security.isNew && !prev) || (security.isNew && !prev.isNew)
        const isLastNew = (security.isNew && !next) || (security.isNew && !next.isNew)
        const classes = classNames('EditableSecurityList-Security', {
          'EditableSecurityList-Security_firstNew': isFirstNew,
          'EditableSecurityList-Security_lastNew': isLastNew,
        })

        return (
          <ControlledInput key={security.id} name={security.id} control={control} rules={{ min: 0, max: 100 }}>
            {({ value, handleChange }) => (
              <EditableSecurity
                className={classes}
                title={security.title}
                ticker={security.ticker}
                logo={security.logo}
                weight={value}
                currentWeight={security.currentWeight}
                targetWeight={security.weight}
                showDelete={security.showDelete}
                isNew={security.isNew}
                onWeightChange={(_event, value) => handleChange(value)}
                onDelete={() => handleDelete(security.id)}
                data-test-id={`editableSecurity${index}`}
                highlightedRef={index === 0 ? highlightedRef : null}
              />
            )}
          </ControlledInput>
        )
      })
    },
    // had to run only on securities changes to optimize the render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(securities)],
  )

  return <SideSpacesCompensator className={classes}>{securitiesNode}</SideSpacesCompensator>
}

EditableSecurityList.propTypes = {
  securities: PropTypes.arrayOf(PropTypes.object),
  control: PropTypes.object,
  handleDelete: PropTypes.func,
  highlightedRef: PropTypes.object,
}

export { EditableSecurityList }
