import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

type PortfolioPointOfInterestDTO = {
  type: string
  date: string
  data: {
    transactions_quantity: string
    total_value: string
  }
}

type FetchPortfolioPointOfInterestsParams = { portfolioId: number }

const getFetchPortfolioPointOfInterestsURL = ({ portfolioId }: FetchPortfolioPointOfInterestsParams): string =>
  `portfolios/${portfolioId}/events/`

const fetchPortfolioPointOfInterests = async ({
  portfolioId,
}: FetchPortfolioPointOfInterestsParams): Promise<PortfolioPointOfInterestDTO[] | ApiError> => {
  try {
    const { data } = await axios.get(getFetchPortfolioPointOfInterestsURL({ portfolioId }))

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
fetchPortfolioPointOfInterests.getUrl = getFetchPortfolioPointOfInterestsURL

type PortfolioSecurityPointOfInterestDTO = {
  type: string
  date: string
  data: {
    price: string
    quantity: string
    value: string
  }
}

type FetchPortfolioSecurityPointOfInterestsParams = { portfolioId: number; securityId: number }

const getFetchPortfolioSecurityPointOfInterestsURL = ({
  portfolioId,
  securityId,
}: FetchPortfolioSecurityPointOfInterestsParams): string => `portfolios/${portfolioId}/securities/${securityId}/events/`
const fetchPortfolioSecurityPointOfInterests = async ({
  portfolioId,
  securityId,
}: FetchPortfolioSecurityPointOfInterestsParams): Promise<PortfolioSecurityPointOfInterestDTO[] | ApiError> => {
  try {
    const { data } = await axios.get(getFetchPortfolioSecurityPointOfInterestsURL({ portfolioId, securityId }))

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
fetchPortfolioSecurityPointOfInterests.getUrl = getFetchPortfolioSecurityPointOfInterestsURL

export {
  fetchPortfolioPointOfInterests,
  type PortfolioPointOfInterestDTO,
  fetchPortfolioSecurityPointOfInterests,
  type PortfolioSecurityPointOfInterestDTO,
}
