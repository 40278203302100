import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries, useSelector, useActions } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { isFieldsDisabledByRuleset } from 'helpers/field.js'
import { bulkValidate } from 'helpers/validation.js'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $dictsStore } from 'app/effector/dicts'

import { showSupportModal as showSupportModalActionCreator } from 'app/redux/actions/ui'

import Form, { Fieldset } from 'components/_old/Form/Form.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelField } from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'

import CleanInputOnFocus from 'components/atoms/CleanInputOnFocus/CleanInputOnFocus.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { states as clientStates } from 'constants/client'

const isPartiallyHidden = (str): boolean => /\*/.test(str)

const PaymentDetailsForm = ({
  onPaymentDataFieldChange,
  onSubmit,
  disabled,
  wrapButton,
  isRegistration,
  inputMods,
  submitButton,
}): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { dicts } = useUnit($dictsStore)
  const fieldLockRules = dicts.client_lock_rules
  const client = useSelector((state) => state.client)
  const { nominatedAccount } = useUnit($bankAccountsStore)
  // eslint-disable-next-line
  const { sort_code, account_number } = nominatedAccount || {}

  const [handleOpenSupportModal] = useActions([showSupportModalActionCreator])

  let submitButtonNode = React.cloneElement(
    submitButton,
    {
      'data-test-id': 'paymentDetailsSubmit',
    },
    submitButton.props.children || 'Continue',
  )

  const validation = {
    sort_code: {
      rules: [(sort_code ?? '').length > 0, (sort_code ?? '').replace(/[_-]/g, '').length === 6],
      errors: ['Sort code can’t be empty', 'Must be 6 characters long'],
    },
    account_number: {
      rules: [(account_number ?? '').length > 0, (account_number ?? '').length === 8],
      errors: ['Account number can’t be empty', 'Must be 8 characters long'],
    },
  }

  const isFieldsDisabled = isFieldsDisabledByRuleset({
    fields: ['sort_code', 'account_number'],
    rules: fieldLockRules,
    model: nominatedAccount,
  })

  const isSomeFieldPartiallyHidden = isPartiallyHidden(sort_code) || isPartiallyHidden(account_number)
  const submitDisabled = !bulkValidate(validation) || (!isRegistration && isSomeFieldPartiallyHidden)

  const handleSubmit = (): void => {
    // eslint-disable-next-line
    const { sort_code, account_number } = nominatedAccount || {}

    const isRegistration = client.state === clientStates.NOT_COMPLETED

    if (!isRegistration) {
      trackEvent({
        category: 'Settings',
        action: 'Payments details changed',
      })
    }

    const shouldUpdateClient = !isPartiallyHidden(sort_code) && !isPartiallyHidden(account_number)
    onSubmit(nominatedAccount, shouldUpdateClient)
  }

  submitButtonNode = React.cloneElement(
    submitButtonNode,
    {
      onClick: handleSubmit,
      disabled: submitDisabled,
      tabIndex: 4,
    },
    submitButtonNode.props.children || 'Continue',
  )

  submitButtonNode = wrapButton(submitButtonNode)

  const handleSortCodeChange = (_event, value = null): void => {
    // eslint-disable-next-line
    const { account_number } = nominatedAccount ?? {}

    if (value) {
      value.replace(/_/g, '')
    }

    onPaymentDataFieldChange('sort_code', value)
    if (isPartiallyHidden(account_number)) {
      onPaymentDataFieldChange('account_number', null)
    }
  }

  const handleAccountNumberChange = (_event, value = null): void => {
    // eslint-disable-next-line
    const { sort_code } = nominatedAccount ?? {}
    onPaymentDataFieldChange('account_number', value)
    if (isPartiallyHidden(sort_code)) {
      onPaymentDataFieldChange('sort_code', null)
    }
  }

  const sortCodeInput = (
    <Validate rules={validation.sort_code.rules}>
      <Label
        disabled={disabled || isFieldsDisabled}
        errorMessages={validation.sort_code.errors}
        data-test-id="paymentDetailsSortCodeLabel"
      >
        Sort code
        <LabelField>
          {!isFieldsDisabled && (
            <CleanInputOnFocus onSetValue={handleSortCodeChange} cleanIfMatch={/\*/}>
              <Input
                name="sort-code"
                autoComplete="off"
                type="masked"
                subtype="tel"
                mask="66-66-66"
                placeholder="__-__-__"
                mods={inputMods}
                tabIndex={2}
                onChange={handleSortCodeChange}
                formatChars={{
                  6: '[0-9*]',
                }}
                disabled={disabled || isFieldsDisabled}
                data-test-id="paymentDetailsSortCodeInput"
              >
                {sort_code}
              </Input>
            </CleanInputOnFocus>
          )}
          {isFieldsDisabled && <Typo>{sort_code}</Typo>}
        </LabelField>
      </Label>
    </Validate>
  )

  const preparedAccountNumber = `${account_number ?? ''}`.substring(0, 19)

  const accountNumberInput = (
    <Validate rules={validation.account_number.rules}>
      <Label
        disabled={disabled || isFieldsDisabled}
        errorMessages={validation.account_number.errors}
        data-test-id="paymentDetailsAccountNumberLabel"
      >
        Account number
        <LabelField>
          {!isFieldsDisabled && (
            <CleanInputOnFocus onSetValue={handleAccountNumberChange} cleanIfMatch={/\*/}>
              <Input
                name="account-number"
                autoComplete="off"
                type="number"
                mods={inputMods}
                tabIndex={3}
                onChange={handleAccountNumberChange}
                disabled={disabled || isFieldsDisabled}
                maxlength={8}
                data-test-id="paymentDetailsAccountNumberInput"
              >
                {preparedAccountNumber}
              </Input>
            </CleanInputOnFocus>
          )}
          {isFieldsDisabled && preparedAccountNumber}
        </LabelField>
      </Label>
    </Validate>
  )

  return (
    <Form>
      <Fieldset marginNext>
        {isFieldsDisabled && (
          <Fragment>
            {sortCodeInput}
            {accountNumberInput}
          </Fragment>
        )}
        {!isFieldsDisabled && (
          <ColumnarLayout mods={{ padding: 'small' }}>
            <Column size={3}>{sortCodeInput}</Column>
            <Column size={4}>{accountNumberInput}</Column>
          </ColumnarLayout>
        )}
        {isRegistration && (
          <Paper top={desktop ? 32 : 40}>
            <Typography size={12} lineHeight="medium">
              <Typo>
                To be accepted, your bank account must be a UK current account registered in your name and address as
                listed on your InvestEngine account. This account will be used for all withdrawals.
                <br />
                <br />
                Please note that only UK current accounts are accepted. We cannot accept Cash ISAs, credit cards, or
                savings accounts as your nominated bank account.
              </Typo>
            </Typography>
          </Paper>
        )}
      </Fieldset>
      {!isFieldsDisabled && submitButtonNode}
      {isFieldsDisabled && (
        <div style={{ marginTop: '2rem' }}>
          <Link onClick={handleOpenSupportModal} data-test-id="paymentDetailsEmailUsLink">
            <Typography tag="span" size={14} color="inherit">
              Email us to change your information
            </Typography>
          </Link>
        </div>
      )}
    </Form>
  )
}

export { PaymentDetailsForm }
