import React from 'react'

import { useSelector } from 'hooks'

import { features } from 'helpers/features'

import { questionTypes } from 'constants/questionnaire.js'

import { Term } from './Term'
import { Question } from './Question'

const QuestionWrapper = (props) => {
  const {
    params: { questionCode = '' } = {},
    defaultQuestionWrapper,
    firstStepQuestionWrapper,
    lastQuestionWrapper,
    analyticsCategory,
  } = props

  const questions = useSelector((state) =>
    state.questionnaire.questionList.questions.filter(({ type }) =>
      features.get('new-questionnaire')
        ? [questionTypes.SINGLE, questionTypes.MULTIPLE, questionTypes.TEXT].includes(type)
        : [questionTypes.SINGLE, questionTypes.MULTIPLE].includes(type),
    ),
  )

  const index = questions.findIndex((question) => question.code === questionCode)
  const question = questions[index]

  if (!question) {
    return null
  }

  const QuestionComponent = Question[question.type]
  const QuestionWrapper = (() => {
    if (index === 0) {
      return firstStepQuestionWrapper || defaultQuestionWrapper
    }

    if (index === questions.length - 1) {
      return lastQuestionWrapper || defaultQuestionWrapper
    }

    return defaultQuestionWrapper
  })()

  const cards = question.answers.map((answer) => {
    const value = answer.id
    const extra_data = answer.extra_data
    let headline = answer.title
    let description = null

    if (extra_data?.time_period) {
      description = <Term {...extra_data} />
      headline = null
    }

    return {
      value,
      headline,
      extra_data,
      description,
    }
  })

  const analytics = analyticsCategory
    ? {
        category: analyticsCategory,
        action: 'Questionnaire step completed',
        label: question.title,
      }
    : {}

  return (
    <QuestionWrapper {...props} analytics={analytics} code={question.code}>
      <QuestionComponent {...props} cards={cards} code={question.code} />
    </QuestionWrapper>
  )
}

export { QuestionWrapper }
