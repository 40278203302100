import React from 'react'

import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'

import { periodOptions } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'
import type { Frequency } from 'app/pages/Dashboard/Goals/RecurringPayment/types'

import { SavingsPlanOptionButton } from '../SavingsPlanOptionButton'

type FrequencyOptions = {
  onSelectOption: (frequency: Frequency) => void
}

const FrequencyStep = ({ onSelectOption }: FrequencyOptions): React.ReactElement => {
  return (
    <Width size={21} center>
      {periodOptions.map((option) => (
        <Paper top={16} key={option.value}>
          <SavingsPlanOptionButton
            title={option.name}
            onClick={() => {
              onSelectOption(option.value as Frequency)
            }}
            data-test-id="savingsPlanSetupFrequency"
          />
        </Paper>
      ))}
    </Width>
  )
}

export { FrequencyStep }
