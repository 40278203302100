import { useStoreMap, useUnit } from 'effector-react'

import { useCallback, useContext, useEffect, useLoading, useMemo, useSelector, useState } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import {
  fetchPortfolioSecurityPointOfInterestsFx,
  $portfolioSecurityPointOfInterests,
} from 'app/effector/pointsOfInterest'
import { type PortfolioSecurityPointOfInterestList } from 'app/effector/pointsOfInterest/models'
import { $portfolioSecuritiesStore } from 'app/effector/portfolio-securities'
import { fetchSecurityFx, $securitiesStore } from 'app/effector/securities'
import { getSecurityDividends } from 'app/effector/securities/api'
import type { Security } from 'app/effector/securities/models'
import type { SecurityStatsCollection } from 'app/effector/securities/models/Stats/SecurityStatsCollection'

import { showFailToast } from 'app/redux/actions/ui'
import { ApiError } from 'app/redux/models/errors'
import type { Portfolio, PortfolioList } from 'app/redux/models/portfolio'

import type { IncomeLineDataItem } from 'components/organisms/charts/parts'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

import { type AnalyticsFilter } from 'app/pages/Dashboard/Analytics/hooks/useAnalyticsFilters'
import { useSecurities } from 'app/pages/Securities/hooks'
import {
  useSecurityAnalytics,
  useSecurityLabels,
  useTotalReturns,
  type TotalReturnsData,
} from 'app/pages/Securities/Security/hooks'

import { useSecuritySliceTabs } from './useSecuritySliceTabs'

const PERIODS = {
  WEEK: 'week',
  MONTH: 'month',
  SIX_MONTHS: 'six_months',
  YEAR: 'year',
  MAX: 'max',
}

const NAME_BY_PERIOD = {
  [PERIODS.WEEK]: 'last week',
  [PERIODS.MONTH]: 'last month',
  [PERIODS.SIX_MONTHS]: 'last six months',
  [PERIODS.YEAR]: 'last year',
  [PERIODS.MAX]: 'max available period',
}

type UseSecuritySliceSecurity = Security & {
  holdingInfo: {
    value?: number
    quantity?: string
    stats?: SecurityStatsCollection
    average_price?: string
  }
}

type UseSecuritySliceProps = {
  portfolioId: number
  securityId: number
  fromAnalytics: boolean
  fromPortfolio: boolean
  location: {
    pathname: string
    search: string
    query: {
      search: string
      chart?: string
      period?: string
    }
  }
  tunnelQuery: {
    id?: string
    tab?: string
    sectors?: string
    regions?: string
    search?: string
    portfolioId?: string
    chart?: string
    period?: string
  }
}

type UseSecuritySliceInterface = {
  isSecurityLoading: boolean
  isAnalyticsLoading: boolean
  security: UseSecuritySliceSecurity | null
  portfolio: Portfolio | null
  chartData: IncomeLineDataItem[]
  totalReturnsData: TotalReturnsData
  pointsOfInterest: PortfolioSecurityPointOfInterestList
  holdings: any
  sectors: any
  regions: any
  securityLabels: string[]
  searchValue: string
  filters: {
    regions: AnalyticsFilter
    sectors: AnalyticsFilter
  }
  handleSearch: (newSearchValue: string) => void
  goToHoldingPage: (id: number, module: string) => void
  handleBack: () => void
  goToSecurityAnalytics: (type: string) => void
  handleCloseAnalytics: () => void
}

const useSecuritySlice = ({
  portfolioId,
  securityId,
  fromAnalytics,
  fromPortfolio,
  location,
  tunnelQuery,
}: UseSecuritySliceProps): UseSecuritySliceInterface => {
  useSecurities({ portfolioId })

  const { getSecurityById } = useUnit($securitiesStore)
  const { getSecuritiesByPortfolio } = useUnit($portfolioSecuritiesStore)

  const { isLoading: isSecurityLoading, wait } = useLoading(true)
  const [dividendsChartData, setDividendsChartData] = useState([])

  const { resetScrollPosition, restoreScrollPosition, saveScrollPosition } = useContext(ScrollSaverContext)

  const portfolio = useSelector((state: { portfolios: { list: PortfolioList } }) =>
    state.portfolios.list.get(portfolioId),
  )
  const security = getSecurityById(securityId)
  const { totalReturnsData } = useTotalReturns({ security })
  const portfolioSecurities = getSecuritiesByPortfolio(portfolioId, false) || []
  const portfolioSecurity = portfolioSecurities.find((security) => security.security_id === securityId)
  const { value, quantity, stats, average_price: averagePrice } = portfolioSecurity ?? {}
  const pointsOfInterest = useStoreMap($portfolioSecurityPointOfInterests, (state) =>
    state.get(portfolioId, securityId),
  )

  const securityDetailed: UseSecuritySliceSecurity | null =
    security && portfolioSecurity
      ? {
          ...security,
          holdingInfo: {
            value,
            quantity,
            stats,
            average_price: averagePrice,
          },
        }
      : null

  const chartData = useMemo(() => {
    return (security?.history?.daily ?? []).map((data) => ({
      date: data.date,
      value: data.share_price,
    })) as unknown as IncomeLineDataItem[]
  }, [security?.history?.daily])

  // analytics
  const {
    isLoading: isAnalyticsLoading,
    holdings,
    sectors,
    regions,
    filters,
    searchValue,
    handleSearch,
  } = useSecurityAnalytics(securityId, tunnelQuery)
  const goToSecurityAnalytics = useCallback(
    (type: string) => {
      saveScrollPosition()

      const url = urlTo(
        `portfolio.security.security-analytics.${type}`,
        { id: portfolioId, securityId },
        { ...location.query },
      )

      goTo(url)
    },
    [portfolioId, securityId, location?.query, saveScrollPosition],
  )
  const handleCloseAnalytics = useCallback(() => {
    goTo(urlTo(`portfolio.security`, { id: portfolioId, securityId }, { ...location.query }))
  }, [portfolioId, securityId, location.query])

  const securityLabels = useSecurityLabels(security)

  const { activeChart, setChartTab, selectedPeriod, setPeriodTab } = useSecuritySliceTabs({
    location,
    portfolioId,
    securityId,
  })

  const periodPriceData = useMemo(
    () => ({
      price: security?.stats?.[selectedPeriod]?.return_money,
      percent: security?.stats?.[selectedPeriod]?.return_percent,
      name: NAME_BY_PERIOD[selectedPeriod],
    }),
    [security, selectedPeriod],
  )

  const shouldShowDividendsChart = !!security?.estimated_yield

  const goToHoldingPage = useCallback(
    (id: number, module: string) => {
      saveScrollPosition()

      const query = { ...tunnelQuery }
      delete query.chart
      delete query.period

      const moduleName = `analytics.${portfolioId ? 'portfolio.' : ''}${module}`

      goTo(urlTo(moduleName, { portfolioId, id }, { ...query, back: location.pathname + location.search }))
    },
    [portfolioId, tunnelQuery, location.pathname, location.search, saveScrollPosition],
  )

  const handleBack = useCallback(() => {
    delete tunnelQuery.sectors
    delete tunnelQuery.regions
    delete tunnelQuery.search

    if (fromAnalytics && tunnelQuery.tab) {
      const { portfolioId: selectedPortfolioId, tab, id, ...restQuery } = tunnelQuery

      resetScrollPosition()

      goTo(
        urlTo(
          selectedPortfolioId ? `analytics.portfolio.${tab}` : `analytics.${tab}`,
          { id, portfolioId: selectedPortfolioId },
          { ...restQuery, fromPortfolio },
        ),
      )
      return
    }

    goTo(urlTo('dashboard.portfolio', { id: portfolioId }, tunnelQuery))
  }, [portfolioId, fromAnalytics, fromPortfolio, tunnelQuery, resetScrollPosition])

  useEffect(
    () => {
      if (portfolio) {
        const promises: Array<Promise<any>> = [
          fetchSecurityFx({ id: securityId }),
          fetchPortfolioSecurityPointOfInterestsFx({ portfolioId, securityId }),
          getSecurityDividends({ portfolioId, securityId }).then((data) => {
            if (data instanceof ApiError) {
              showFailToast()
              return
            }
            setDividendsChartData(data)
          }),
          getSecurityDividends({ portfolioId, securityId }).then((data) => {
            if (data instanceof ApiError) {
              showFailToast()
              return
            }
            setDividendsChartData(data)
          }),
        ]

        wait(Promise.all(promises))
      }
    },
    // had to run only on portfolio or security change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(portfolio), securityId],
  )

  useEffect(
    () => {
      return () => {
        if (fromAnalytics) {
          restoreScrollPosition()
        }
      }
    },
    // had to run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return {
    isSecurityLoading,
    isAnalyticsLoading,
    security: securityDetailed,
    portfolio,
    chartData,
    totalReturnsData,
    pointsOfInterest,
    holdings,
    sectors,
    regions,
    securityLabels,
    searchValue,
    filters,
    handleSearch,
    activeChart,
    periodPriceData,
    selectedPeriod,
    shouldShowDividendsChart,
    dividendsChartData,
    setChartTab,
    setPeriodTab,
    goToHoldingPage,
    handleBack,
    goToSecurityAnalytics,
    handleCloseAnalytics,
  }
}

export { useSecuritySlice }
