import React from 'react'

import Growth from './Growth'
import Portfolio from './Portfolio'
import Projections from './Projections'

export const childRoutes = [
  {
    index: true,
    module: 'portfolio',
    component: function Component(props) {
      return <Projections {...props} />
    },
  },
  {
    module: 'allocation',
    path: 'allocation/',
    component: function Component(props) {
      return <Portfolio {...props} />
    },
  },
]

export default {
  headline: 'Review your portfolio',
  module: 'growth',
  path: 'growth/',
  component: function Components(props) {
    return <Growth {...props} />
  },
  childRoutes,
  indexRoute: childRoutes[0],
}
