import * as cards from './cards.js'

const MANAGED_TYPE = 'MANAGED_TYPE'

const PERSONAL_SIMPLE = {
  preselectedData: [],
  steps: [
    [MANAGED_TYPE, [cards.DIY, cards.SELF_SELECTED, cards.MANAGED, cards.MANAGED_DEFAULT], 'Choose investment service'],
  ],
}

export { PERSONAL_SIMPLE }
