import React, { forwardRef } from 'react'

import classNames from 'classnames/dedupe'

import './Card.css'

type CardLevels = -1 | 0 | 2 | 3

type CardBorderWidths = 0 | 1 | 2

type CardBorderRadii = 0 | 6 | 8 | 10

type CardColors =
  | 'primary-surface-10'
  | 'primary-surface-25'
  | 'background-default'
  | 'primary-default'
  | 'content-on-color-white'
  | 'background-border'

type CardProps = {
  children: React.ReactNode | React.ReactNodeArray
  id?: string
  className?: string
  style?: React.CSSProperties
  level?: CardLevels
  borderWidth?: CardBorderWidths
  borderRadius?: CardBorderRadii
  color?: CardColors
  'data-test-id'?: string
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      id,
      className,
      style,
      level = 3,
      borderWidth = 1,
      borderRadius = 6,
      color,
      'data-test-id': dataTestId,
      ...rest
    }: CardProps,
    ref,
  ): React.ReactElement => {
    const classes = classNames(className, '_new_Card', {
      [`_new_Card_level_${level}`]: true,
      [color ? `_new_Card_color_${color}` : '']: typeof color === 'string',
      [`_new_Card_borderWidth_${borderWidth}`]: typeof borderWidth === 'number',
      [`_new_Card_borderRadius_${borderRadius}`]: typeof borderRadius === 'number',
    })

    return (
      <div ref={ref} id={id} className={classes} style={style} data-test-id={dataTestId} {...rest}>
        {children}
      </div>
    )
  },
)

Card.displayName = 'Card'

export { Card }
