import React from 'react'

import { useMemo } from 'hooks'

import { bulkValidate } from 'helpers/validation.js'

import Button from 'components/_old/Button/Button.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { type PasswordStepProps } from '../PasswordStep'

const Mobile = ({
  password,
  validation,
  handleBack,
  handlePasswordChange,
  handleContinue,
}: PasswordStepProps): React.ReactElement => {
  const headerNode = useMemo(
    () => (
      <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
        Step 2 of 3
      </NavigationBar>
    ),
    [handleBack],
  )

  const inputNode = useMemo(
    () => (
      <Paper top={48}>
        <Validate rules={validation.password.rules}>
          <Label errorMessages={validation.password.errors}>
            Password
            <Input mods={{ size: 'bigger' }} type="password" onChange={handlePasswordChange}>
              {password}
            </Input>
          </Label>
        </Validate>
      </Paper>
    ),
    [password, validation, handlePasswordChange],
  )

  const contentNode = (
    <SubmitOnEnter>
      <Paper top={16}>
        <Typography size={24} lineHeight="small" weight="semibold" align="center">
          <Typo>Confirm your identity</Typo>
        </Typography>
      </Paper>
      <Paper top={24}>
        <Typography align="center">
          <Typo>To activate two-factor authentication enter your InvestEngine password.</Typo>
        </Typography>
      </Paper>
      {inputNode}
    </SubmitOnEnter>
  )

  const footer = useMemo(
    () => (
      <MobileLayoutFooterButton>
        <Button
          type="submit"
          mods={{ size: 'big block' }}
          disabled={!bulkValidate(validation)}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </MobileLayoutFooterButton>
    ),
    [validation, handleContinue],
  )

  return <MobileLayout header={headerNode} content={contentNode} footer={footer} />
}

export { Mobile }
