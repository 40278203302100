import * as React from 'react'

import { trackEvent } from 'helpers/analytics'

export default class AnalyticsTrigger extends React.Component {
  handleClick = () => {
    const { action, category, label } = this.props

    if (action || category || label) {
      trackEvent({ action, category, label })
    }
  }

  render() {
    const { children, style } = this.props

    return (
      <div onClick={this.handleClick} style={style}>
        {children}
      </div>
    )
  }
}
