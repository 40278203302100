import { useCallback, useContext, useMemo, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'
import { features } from 'helpers/features'
import { getDocumentCheckStatusOnce, IDCheckStatus } from 'helpers/id-check'
import { Impact } from 'helpers/impact.js'
import { goTo, urlTo } from 'helpers/router.js'

import { StepContext } from 'app/containers/PortfolioTunnel'

import { useClient } from 'app/pages/Dashboard/SetupAccount/hooks/useClient.js'
import { useContacts } from 'app/pages/Dashboard/SetupAccount/hooks/useContacts.js'
import { usePayment } from 'app/pages/Dashboard/SetupAccount/hooks/usePayment.js'

import { useQuickStartNavigation } from '../hooks/useQuickStartNavigation'

import { states as clientStates } from 'constants/client'
import { regulatoryTypes } from 'constants/portfolio'

const useIndividualRegistration = ({ location }) => {
  const { activeStep, getPrevStep, getNextStep, getStepByName, redirectByStepName, redirectByStep } =
    useContext(StepContext)

  const { client, changeClientField, completeClient, updateClient } = useClient()
  const { contacts, owner, changeOwnerField, updateOwner, updateContact, passIdentityCheck } = useContacts('INDIVIDUAL')
  const isIsaTransferPanelFeatureEnabled = features.get('isa-transfer-panel')

  const {
    bankAccountsList,
    updatePaymentDetails,
    changePaymentField,
    processPaymentDetailsError,
    passPaymentDetailsCheck,
  } = usePayment()
  const portfolios = useSelector(({ portfolios }) => portfolios)
  const isaPortfolios = useMemo(
    () => portfolios?.items?.filter((portfolio) => portfolio.regulatory_type === regulatoryTypes.ISA) ?? [],
    [portfolios],
  )

  const { navigateAfterFinishRegistration } = useQuickStartNavigation({ portfolioId: location?.query?.portfolioId })

  const trackStepCompleted = useCallback(() => {
    trackEvent({
      category: 'Registration',
      action: 'Registration step completed',
      label: activeStep.headline,
    })
  }, [activeStep])

  const trackRegistrationCompleted = useCallback(() => {
    trackEvent({
      category: 'Registration',
      action: 'Registration completed',
      label: client.type,
    })

    Impact.trackRegistration(client, portfolios)
  }, [client, portfolios])

  const completeRegistration = useCallback(async () => {
    trackRegistrationCompleted()

    const stateAfterCompleteClient = await completeClient()

    if (!stateAfterCompleteClient.result) {
      processError({ error: stateAfterCompleteClient.error, forceReset: true })
      return
    }

    const isClientApproved = stateAfterCompleteClient.client?.state === clientStates.APPROVED
    const owner = stateAfterCompleteClient.owner

    if (isaPortfolios.length > 0 && !client.agreed_with_isa_declaration && owner.isUkResidence()) {
      return goTo(urlTo('sign-isa-declaration', null, { next: urlTo('dashboard', null, { finishRegistration: true }) }))
    }

    if (location?.query?.isOpeningSipp) {
      const canOpenSipp = isClientApproved && owner?.age < 75 && owner?.isUkResidence()

      if (canOpenSipp) {
        goTo(location.query.next)
        return
      }

      goTo(location.query.back)
      return
    }

    if (location?.query?.portfolioId) {
      navigateAfterFinishRegistration(stateAfterCompleteClient)
      return
    }

    const query = { finishRegistration: true }

    const shouldForwardToIsaTransfer =
      isIsaTransferPanelFeatureEnabled &&
      location?.query?.forwardToTransferIsa === 'true' &&
      owner?.isUkResidence() &&
      isClientApproved

    if (shouldForwardToIsaTransfer) {
      query.forwardToIsaTransfer = true
    }

    goTo(urlTo('dashboard', null, query))
  }, [
    trackRegistrationCompleted,
    completeClient,
    navigateAfterFinishRegistration,
    location,
    client.agreed_with_isa_declaration,
    isaPortfolios.length,
    isIsaTransferPanelFeatureEnabled,
  ])

  const nextStep = useMemo(() => {
    if (activeStep?.initModule === 'setup-funding') {
      return getStepByName('finances-info')
    }

    return getNextStep()
  }, [activeStep, getNextStep, getStepByName])

  const prevStep = useMemo(() => {
    if (activeStep?.initModule === 'setup-funding' && owner?.isAvailable()) {
      return getStepByName('provide-tax-info')
    }

    if (activeStep?.initModule === 'finances-info') {
      return getStepByName('setup-funding')
    }

    return getPrevStep()
  }, [owner, activeStep, getPrevStep, getStepByName])

  const handleAfterSubmit = useCallback(async () => {
    trackStepCompleted()

    if (nextStep) {
      redirectByStep(nextStep)
      return
    }

    await completeRegistration()
  }, [nextStep, trackStepCompleted, redirectByStep, completeRegistration])

  const updateAddresses = useCallback(async () => {
    trackStepCompleted()

    const result = await updateContact({ id: owner?.id, keys: ['addresses'] })

    if (!result.result) {
      processError({ error: result.error, forceReset: true })
      return result
    }
    redirectByStepName('provide-tax-info')
  }, [owner?.id, updateContact, trackStepCompleted, redirectByStepName])

  const handleTaxInfoUpdate = useCallback(async () => {
    const isOnFidoEnabled = features.get('onfido')
    const onSuccessIdentityCheck = () => {
      redirectByStepName('setup-funding')
    }
    const onFailIdentityCheck = async () => {
      if (isOnFidoEnabled) {
        const documentsCheckData = await getDocumentCheckStatusOnce(owner.id)

        if (documentsCheckData?.status === IDCheckStatus.COMPLETE) {
          redirectByStepName('setup-funding')
          return
        }
      }

      redirectByStepName('proof-of-identity')
    }

    trackStepCompleted()

    await passIdentityCheck(owner?.id, onSuccessIdentityCheck, onFailIdentityCheck)
  }, [owner?.id, passIdentityCheck, redirectByStepName, trackStepCompleted])

  const handlePaymentDetailsUpdate = useCallback(
    // first argument from old api
    async (data, shouldUpdate) => {
      if (shouldUpdate) {
        trackStepCompleted()

        const result = await updatePaymentDetails({ ...data })

        if (!result.result) {
          processPaymentDetailsError(result?.error)
        }
      }

      const onSuccessPaymentDetailsCheck = () => {
        trackEvent({
          action: 'nominated_bank_account_verified',
          client_type: 'INDIVIDUAL',
        })

        redirectByStepName('finances-info')
      }
      const onFailPaymentDetailsCheck = () => {
        redirectByStepName('upload-bank-statement')
      }

      await passPaymentDetailsCheck(
        bankAccountsList.find((account) => account.is_nominated)?.id,
        onSuccessPaymentDetailsCheck,
        onFailPaymentDetailsCheck,
      )
    },
    [
      updatePaymentDetails,
      trackStepCompleted,
      redirectByStepName,
      bankAccountsList,
      passPaymentDetailsCheck,
      processPaymentDetailsError,
    ],
  )

  return {
    nextStep,
    prevStep,
    client,
    contacts,
    owner,
    changeOwnerField,
    changeClientField,
    changePaymentField,
    updateClient,
    updatePaymentDetails,
    updateOwner,
    updateAddresses,
    handleTaxInfoUpdate,
    handlePaymentDetailsUpdate,
    handleAfterSubmit,
  }
}

export { useIndividualRegistration }
