import React, { type Context, Fragment, type ReactNode } from 'react'

import { useContext, useMemo, useMediaQueries, useSelector } from 'hooks'

import { getKeyOrDefault } from 'helpers/object'
import { palette } from 'helpers/palette'
import { urlTo } from 'helpers/router'

import {
  type Notification as NotificationModel,
  type NotificationAction,
  NotificationType,
} from 'app/effector/notifications/models'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Typography } from 'components/atoms/Typography'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'

import { ActionNotification } from './ActionNotification'
import { IsaTransferPanel } from './IsaTransferPanel/IsaTransferPanel'
import { PlainNotification } from './PlainNotication'
import { PromoNotification } from './PromoNotification/PromoNotification'

import { type ClientType } from 'constants/client'

type NotificationIcon = {
  type: string
  color?: string
}

type NotificationTheme = {
  Component: typeof PlainNotification | typeof ActionNotification | typeof PromoNotification
  textColor: string
  textWeight: string
  textSize?: number
  linkColor: string | null
  noNbsp?: boolean
  icon: NotificationIcon
}

type NotificationActionLinkProps =
  | {
      onClick: (event: unknown) => void
      to?: undefined
      hard?: undefined
      blank?: undefined
    }
  | {
      to: string
      hard: boolean
      blank: boolean
      onClick?: undefined
      analyticsEvent?: {
        action: string
        client_type: ClientType
      }
    }
  | {
      to: string | null
      onClick?: undefined
      hard?: undefined
      blank?: undefined
    }
  | null

function getActionLinkProps(
  action: NotificationAction,
  query: Dictionary<string> = {},
  clientType: ClientType,
): NotificationActionLinkProps {
  if (action.type === 'URL') {
    const to = action.link

    return {
      to,
      hard: true,
      blank: true,
    }
  }

  if (action.type === 'CODE') {
    // hack to make old portfolios links work
    if (action?.params?.portfolio_id) {
      action.params.goalId = action.params.portfolio_id
    }

    // hack to make bank-transfer link work
    if (action?.link.includes('bank-transfer') && action.params?.credit) {
      const to = urlTo(action.link, action.params, {
        ...query,
        amount: action.params.credit,
        transactionId: action.params.transaction_id,
      })

      return {
        to,
      }
    }

    const to = urlTo(action.link, action.params)

    if (action.link === 'dashboard.setup-account') {
      return {
        to,
        analyticsEvent: {
          action: 'finish_registration_banner_clicked',
          client_type: clientType,
        },
      }
    }

    if (action.link === 'verify-email') {
      return {
        to,
        analyticsEvent: {
          action: 'verify_email_banner_clicked',
          client_type: clientType,
        },
      }
    }

    return {
      to,
    }
  }

  return null
}

type UseNotificationInterface = {
  theme: NotificationTheme
  actionLinkProps: NotificationActionLinkProps
  title: ReactNode | null
  body: ReactNode | null
  dataTestId: string | null
  image?: string
}

function useNotification(notification: NotificationModel): UseNotificationInterface {
  const { tunnelQuery } = useContext(ChangeGoalTunnelContext as unknown as Context<{ tunnelQuery: Dictionary<string> }>) // TODO: Remove as as when ChangeGoalTunnelContext will be ts
  const { desktop } = useMediaQueries()
  const clientType = useSelector((state) => state.client.type)

  const theme = useMemo(() => {
    const icon: NotificationIcon = getKeyOrDefault(
      {
        [NotificationType.NOTICE]: {
          type: 'attention-24',
          color: palette['status-success'],
        },
        [NotificationType.WARNING]: {
          type: 'attention-24',
          color: palette['status-warning'],
        },
        [NotificationType.AWAITING]: {
          type: 'clock-24',
          color: palette['content-on-background-primary'],
        },
        default: {
          type: 'attention-24',
          color: palette['status-success'],
        },
      },
      notification?.type ?? '',
    )

    let theme: NotificationTheme = {
      Component: PlainNotification,
      textColor: 'default',
      textWeight: 'regular',
      linkColor: null,
      icon,
    }

    if (notification?.action) {
      theme = {
        Component: ActionNotification,
        textColor: 'white',
        textWeight: 'semibold',
        linkColor: 'white',
        icon: {
          type: 'attention-white-24',
          color: palette['content-on-color-white'],
        },
      }
    }

    if (notification?.type === NotificationType.PROMO) {
      theme = {
        Component: PromoNotification,
        textColor: 'white',
        textWeight: 'regular',
        textSize: desktop ? 16 : 14,
        linkColor: 'white',
        icon: {
          type: 'attention-white-24',
          color: palette['content-on-color-default'],
        },
      }
    }

    if (notification?.type === NotificationType.ISA_TRANSFER_PANEL) {
      theme = {
        Component: IsaTransferPanel,
        textColor: 'white',
        textWeight: 'semibold',
        textSize: desktop ? 20 : 16,
        linkColor: 'white',
        noNbsp: true,
        icon: {
          type: 'attention-white-24',
          color: palette['content-on-color-default'],
        },
      }
    }

    return theme
  }, [notification?.action, notification?.type, desktop])

  const actionLinkProps = useMemo(
    () => (notification?.action ? getActionLinkProps(notification?.action, undefined, clientType) : null),
    [notification?.action, clientType],
  )

  const title = useMemo(() => {
    if (notification?.title) {
      return (
        <Typography size={theme.textSize ?? 14} color={theme.textColor} weight={theme.textWeight}>
          <Typo noNbsp={theme.noNbsp}>{notification.title.content}</Typo>
        </Typography>
      )
    }

    return null
  }, [notification?.title, theme])

  const body = useMemo(() => {
    if (notification?.body) {
      if (!notification.body.variables) {
        return (
          <Typography size={14} color={theme.textColor} weight={theme.textWeight}>
            <Typo multiline>{notification.body.content}</Typo>
          </Typography>
        )
      }

      const content = Object.entries(notification.body.variables)
        .map(([key, variable]) => {
          if (variable) {
            return {
              key,
              label: variable.label,
              props: getActionLinkProps(variable, tunnelQuery?.view ? { view: tunnelQuery.view } : undefined),
            }
          }

          return null
        })
        .filter(Boolean)
        .reduce((content: string | any[], link, index, linksArray) => {
          let processedContent =
            typeof content === 'string' ? content.split('${').map((stringItem) => stringItem.replace('}', '')) : content

          if (link) {
            const contentIndex = processedContent.findIndex((contentItem) => contentItem === link.key)

            if (contentIndex > 0) {
              processedContent[contentIndex] = (
                <Link
                  key={index}
                  className="Notification-LinkInner"
                  {...link.props}
                  mods={{ color: theme.linkColor }}
                  data-test-id="notificationLinkInner"
                >
                  <Typo>{link.label}</Typo>
                </Link>
              )
            }
          }

          if (linksArray.length - 1 === index) {
            processedContent = processedContent.map((contentItem, index) => {
              if (typeof contentItem === 'string') {
                const hasToPrependSpace = contentItem[0] === ' '
                const hasToAppendSpace = contentItem[contentItem.length - 1] === ' '

                return (
                  <Fragment key={`contentItem${index}`}>
                    {hasToPrependSpace && ' '}
                    <Typo>{contentItem.trim()}</Typo>
                    {hasToAppendSpace && ' '}
                  </Fragment>
                )
              }

              return contentItem
            })
          }

          return processedContent
        }, notification.body.content ?? '')

      return (
        content && (
          <Typography size={14} color={theme.textColor} weight={theme.textWeight}>
            <Fragment>{content}</Fragment>
          </Typography>
        )
      )
    }

    return null
  }, [notification?.body, theme, tunnelQuery])

  const dataTestId = useMemo(() => {
    if (notification?.code) {
      const camelCaseCode = notification.code
        .split('-')
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
        .join('')

      return `notification${camelCaseCode}`
    }

    return null
  }, [notification?.code])

  return {
    theme,
    actionLinkProps,
    title,
    body,
    image: notification?.body?.image,
    dataTestId,
  }
}

export {
  useNotification,
  type UseNotificationInterface,
  type NotificationActionLinkProps,
  type NotificationTheme,
  type NotificationIcon,
}
