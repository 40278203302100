import React, { Fragment } from 'react'

import { GatewayDest } from 'components/atoms/Gateway'

import { Preloader } from 'components/molecules/Preloader'

import { SecurityAnalytics } from 'app/pages/Dashboard/Analytics/SecurityAnalytics'

import { useSharedSecurity } from '../Security/hooks/useSharedSecurity'

type AnalyticsProps = {
  params: {
    securityId: string
    id?: string
    hash: string
    tab: string
  }
  location: { query: { back: string } }
}

const Analytics = ({
  params: { securityId, id: portfolioId, hash, tab },
  location: { query },
}: AnalyticsProps): React.ReactElement => {
  const { isLoading, equities, sectors, regions, security, goToSecurityAnalytics, handleBackToSecurity } =
    useSharedSecurity(securityId, hash, portfolioId, query)

  const props = {
    security,
    type: tab,
    holdings: equities,
    sectors,
    regions,
    itemsAreClickable: false,
    goToSecurityAnalytics,
    handleClose: handleBackToSecurity,
  }

  return (
    <Fragment>
      <SecurityAnalytics {...props} />

      <GatewayDest name="toasts" />
      <Preloader loading={isLoading} size="big" zIndex />
    </Fragment>
  )
}

export { Analytics }
