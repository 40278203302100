import React, { lazy, Suspense } from 'react'

import { useMediaQueries } from 'hooks'

import { sendErrorOnce } from 'helpers/errorLogging'
import { IDCheckProviders } from 'helpers/id-check'

import type { Contact } from 'app/effector/contacts/models'

import Button from 'components/_old/Button/Button.jsx'

import Segment from 'components/atoms/Segment/Segment.jsx'

import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import { Preloader } from 'components/molecules/Preloader'

import ProofOfIdentityForm from 'app/pages/Forms/ProofOfIdentityForm.jsx'

import { useProofOfIdentitySetup } from './hooks'

const OnfidoForm = lazy(async () => await import(/* webpackChunkName: "OnfidoForm" */ 'app/pages/Forms/OnfidoForm/'))

type ProofOfIdentitySetupProps = {
  owner: Contact
  clientType: 'SME' | 'INDIVIDUAL'
  prevStep: {
    module: string
  }
  changeOwnerField: (key: string, value: unknown) => void
  handleAfterSubmit: (...args: unknown[]) => void
}

const ProofOfIdentitySetup = ({
  owner,
  clientType,
  prevStep,
  changeOwnerField,
  handleAfterSubmit,
}: ProofOfIdentitySetupProps): React.ReactElement | null => {
  const { desktop } = useMediaQueries()
  const { provider, submitEnabled, handleAfterUpload, handleSubmit } = useProofOfIdentitySetup({
    owner,
    clientType,
    prevStep,
    changeOwnerField,
    handleAfterSubmit,
  })

  if (owner.id) {
    if (provider === IDCheckProviders.ONFIDO) {
      return (
        <Suspense fallback={<Preloader loading />}>
          <OnfidoForm model={owner} onComplete={handleSubmit} />
        </Suspense>
      )
    }

    const submitButton = (
      <Segment mods={{ margin: 'hefty', noMargin: 'top' }}>
        <ModalContentButtons width={desktop ? 22 : null} phantomButtonsMarginSize="zero" isNewModal>
          <Button
            mods={{ size: 'big block' }}
            onClick={handleSubmit}
            tabIndex={4}
            disabled={!submitEnabled}
            data-test-id="proofOfIdentitySubmit"
          >
            Continue
          </Button>
        </ModalContentButtons>
      </Segment>
    )

    return (
      <ProofOfIdentityForm
        model={owner}
        provider={provider}
        url={`contacts/${owner.id}/files/`}
        method="POST"
        submitButton={submitButton}
        onAfterUpload={handleAfterUpload}
      />
    )
  }

  sendErrorOnce('`owner` without and `id` is used at the `ProofOfIdentitySetup`')

  return null
}

export { ProofOfIdentitySetup }
