import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import Button from 'components/_old/Button/Button.jsx'

import { WeAreSorry } from '../WeAreSorry'

const Mobile = ({ portfolio, handleReset, handleSubmit }) => {
  const content = useMemo(
    () => (
      <Paper top={80}>
        <WeAreSorry />
      </Paper>
    ),
    [],
  )

  const footer = useMemo(
    () => (
      <Fragment>
        {portfolio && (
          <MobileLayoutFooterButton>
            <Button onClick={handleReset} mods={{ theme: 'simple-reverse-blue' }}>
              Retake questionnaire
            </Button>
          </MobileLayoutFooterButton>
        )}
        <MobileLayoutFooterButton>
          <Button onClick={handleSubmit}>My Dashboard</Button>
        </MobileLayoutFooterButton>
      </Fragment>
    ),
    [portfolio, handleReset, handleSubmit],
  )

  return <MobileLayout header={null} content={content} footer={footer} />
}

Mobile.propTypes = {
  portfolio: PropTypes.object,
  handleReset: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export { Mobile }
