import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

type UseSelfSelectedPresetQuickStartProps = {
  preset: {
    title: string
    id: number
  }
  location: {
    query: Record<string, string>
  }
}

type UseSelfSelectedPresetQuickStartReturnProps = {
  handleCloseModal: () => void
  handleGoBackToQuickStart: () => void
  handleStartWithSavingsPlan: () => void
  handleStartWithLumpSum: () => void
}

const useSelfSelectedPresetQuickStart = ({
  preset,
  location,
}: UseSelfSelectedPresetQuickStartProps): UseSelfSelectedPresetQuickStartReturnProps => {
  const { quickStartModalOpened, savingsPlanModalOpened, lumpSumModalOpened, ...restQuery } = location.query

  const handleCloseModal = (): void => {
    goTo(urlTo('self-selected-presets.preset', { id: preset.id }, { ...restQuery }))
  }

  const handleGoBackToQuickStart = (): void => {
    goTo(urlTo('self-selected-presets.preset', { id: preset.id }, { ...restQuery, quickStartModalOpened: true }))
  }

  const handleStartWithSavingsPlan = (): void => {
    trackEvent({ action: 'sp_investnow_sp_clicked', preset: preset.title, manage_type: 'SELF_SELECTED' })
    goTo(urlTo('self-selected-presets.preset', { id: preset.id }, { ...restQuery, savingsPlanModalOpened: true }))
  }

  const handleStartWithLumpSum = (): void => {
    trackEvent({ action: 'sp_investnow_ls_clicked', preset: preset.title, manage_type: 'SELF_SELECTED' })
    goTo(urlTo('self-selected-presets.preset', { id: preset.id }, { ...restQuery, lumpSumModalOpened: true }))
  }

  return {
    handleCloseModal,
    handleGoBackToQuickStart,
    handleStartWithSavingsPlan,
    handleStartWithLumpSum,
  }
}

export { useSelfSelectedPresetQuickStart }
