import React, { type ReactElement } from 'react'
import { withRouter } from 'react-router'

import { useCallback, useContext } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { Gateway } from 'components/atoms/Gateway'

import { Modal } from 'components/molecules/Modal'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'
import { type Action } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'

type OptionsPortfolio = {
  id: string
  reference: string
}

type OptionsChildProps = {
  portfolio: OptionsPortfolio
  portfolioActions: Action[]
  isModalOpen: boolean
  handleClose: () => void
}

type RouteProps = {
  module: string
  component: (props: OptionsChildProps) => ReactElement
}

type OptionsModalProps = {
  routes: RouteProps[]
  portfolio: OptionsPortfolio
  portfolioActions: Action[]
}

const promoModules = ['recurring-payment', 'direct-debit']

const OptionsModal = withRouter(({ routes, portfolio, portfolioActions }: OptionsModalProps): React.ReactElement => {
  const route = routes[routes.length - 1]
  const isOpen = routes.some((route) => route.module?.includes('options'))
  const isPromoModal = promoModules.includes(route.module)

  // Remove `as`‘es when `ChangePortfolioTunnelContext` will be rewritten to ts
  const { tunnelQuery } = useContext(ChangeGoalTunnelContext) as unknown as { tunnelQuery: Record<string, unknown> }

  const handleClose = useCallback(() => {
    goTo(urlTo(`dashboard.portfolio`, { id: portfolio.id }, tunnelQuery), { scrollToTop: false })
  }, [portfolio.id, tunnelQuery])

  return (
    <Gateway into="modals">
      <Modal open={isOpen} close={null} onClose={handleClose} background={isPromoModal && 'primary'}>
        {isOpen &&
          route.component({
            portfolio,
            portfolioActions,
            isModalOpen: isOpen,
            handleClose,
          })}
      </Modal>
    </Gateway>
  )
})

export { OptionsModal, type OptionsModalProps, type OptionsChildProps }
