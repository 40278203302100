import React, { Fragment, type ReactNode } from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries, useMemo } from 'hooks'

import Icon from 'components/_old/Icon/Icon.jsx'

import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

import { type NotificationIcon } from '../useNotification'

import './PlainNotification.css'

type PlainNotificationProps = {
  icon: NotificationIcon
  title?: ReactNode
  body?: ReactNode
  'data-test-id'?: string | null
}

function PlainNotification({
  icon,
  title,
  body,
  'data-test-id': dataTestId,
}: PlainNotificationProps): React.ReactElement {
  const { desktop } = useMediaQueries()

  const content = useMemo(
    () => (
      <ItemWithIcon
        icon={<Icon size={24} type={icon.type} color={icon.color} data-test-id="notificationIcon" />}
        space={12}
        content={
          <Fragment>
            {title}
            {body}
          </Fragment>
        }
      />
    ),
    [icon, title, body],
  )

  if (desktop) {
    return (
      <Card className="PlainNotification" level={-1} data-test-id={dataTestId}>
        <Paper top={16} right={40} bottom={16} left={40}>
          {content}
        </Paper>
      </Card>
    )
  }

  return (
    <SideSpacesCompensator
      className={classNames('PlainNotification', 'PlainNotification_phone')}
      data-test-id={dataTestId}
    >
      <Paper top={12} right={32} bottom={12} left={32}>
        {content}
      </Paper>
    </SideSpacesCompensator>
  )
}

export { PlainNotification, type PlainNotificationProps }
