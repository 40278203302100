import { ListOf } from 'app/redux/models/common/ListOf'

import { Notification, NotificationScopeType } from './Notification'

class NotificationList extends ListOf(Notification) {
  getActionable(): NotificationList {
    return new NotificationList(...this.filter((notification) => notification.action))
  }

  getNonActionable(): NotificationList {
    return new NotificationList(...this.filter((notification) => !notification.action))
  }

  groupByActionability(): [NotificationList, NotificationList] {
    return [this.getActionable(), this.getNonActionable()]
  }

  filterByScope(scope: NotificationScopeType, portfolioId?: number): NotificationList {
    if (scope === NotificationScopeType.PORTFOLIO && portfolioId) {
      return new NotificationList(
        ...this.filter(
          (notification) =>
            notification.scope?.type === scope && notification.scope?.params?.portfolio_id === portfolioId,
        ),
      )
    }

    if (scope === NotificationScopeType.CLIENT) {
      return new NotificationList(...this.filter((notification) => notification.scope?.type === scope))
    }

    return this
  }
}

export { NotificationList }
