import React from 'react'

import { useCallback, useEffect, useMediaQueries } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

import { manageTypes } from 'constants/portfolio'

type NetContributionsProps = {
  goal: Portfolio
  location: {
    query: {
      back?: string
      next?: string
    }
  }
  handleClose: () => void
  toggleCloseButton: (isCloseVisible: boolean) => void
}

const NetContributions = ({
  goal: portfolio,
  location,
  handleClose,
  toggleCloseButton,
}: NetContributionsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const isPortfolioDIY = portfolio?.manage_type === manageTypes.DIY

  useEffect(() => {
    toggleCloseButton(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleContinue = useCallback(() => {
    if (location.query?.next) {
      goTo(location.query?.next)
      return
    }

    goTo(
      urlTo(
        'portfolio.add-funds',
        { id: portfolio.id },
        {
          ...location.query,
          back: urlTo('portfolio.add-funds.net-contributions', { id: portfolio.id }, location.query),
        },
      ),
    )
  }, [portfolio, location])

  const handleBack = useCallback(() => {
    goTo(location?.query?.back ?? urlTo('dashboard.portfolio.options', { id: portfolio.id }, location.query))
  }, [portfolio, location])

  const headerNode = (
    <OptionsNavigationBar
      leftPartText={isPortfolioDIY ? 'Back' : null}
      onLeftPartClick={isPortfolioDIY ? handleBack : null}
      rightPartText="Close"
      onRightPartClick={handleClose}
    >
      <Typo>Add cash</Typo>
    </OptionsNavigationBar>
  )

  const buttonNode = (
    <Button mods={{ size: 'big block' }} onClick={handleContinue} data-test-id="netContributionContinue">
      Continue
    </Button>
  )

  return (
    <OptionsLayout
      header={headerNode}
      content={
        <Width size={36}>
          <Typography size={24} lineHeight="small" weight="semibold" align="center">
            <Typo>Net contribution</Typo>
          </Typography>
          <Width size={27} center>
            <Paper top={desktop ? 32 : 24}>
              <Typography size={14}>
                <Typo>
                  Make single payment or set up regular contributions.
                  <br />
                  <br />
                  We’ll claim 20% basic rate relief for you from HMRC (any higher rate relief should be claimed via your
                  own annual self‑assessment tax return).
                </Typo>
              </Typography>
              <Paper top={32}>
                <Typography size={14}>
                  <Typo>
                    <b>Important:</b> All contributions to your SIPP, together with contributions to any other pensions
                    you may have count towards your Annual Allowance or, where applicable, the Money Purchase Annual
                    Allowance.
                  </Typo>
                </Typography>
              </Paper>
            </Paper>
          </Width>
        </Width>
      }
      button={buttonNode}
    />
  )
}

export { NetContributions }
