import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

const SecurityAnalytics = ({ ...props }) => {
  const { desktop } = useMediaQueries()

  return desktop ? <Desktop {...props} /> : <Mobile {...props} />
}

const propTypes = {
  security: PropTypes.shape({
    logo_uri: PropTypes.string,
    ticker: PropTypes.string,
    title: PropTypes.string,
  }),
  holdings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  handleClose: PropTypes.func,
  activeTab: PropTypes.string,
  itemsAreClickable: PropTypes.bool,
  goToHoldingPage: PropTypes.func,
  type: PropTypes.string,
}

SecurityAnalytics.propTypes = propTypes

export { SecurityAnalytics, propTypes }
