import React from 'react'

type GradientProps = {
  path: string | null
}

function Gradient({ path }: GradientProps): React.ReactElement | null {
  if (!path) {
    return null
  }

  return <path d={path} fill="url(#gradient)" />
}

export { Gradient }
