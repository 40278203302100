import React from 'react'
import PropTypes from 'prop-types'

import { AnalyticsMobileLayout } from '../../Layouts/AnalyticsMobileLayout.jsx'

import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'
import { Summary } from './components/Summary.jsx'
import { SectorsList } from './components/SectorsList.jsx'

import { equitiesPropTypes } from 'app/pages/Dashboard/Analytics/Analytics.jsx'

const Mobile = ({
  isLoading,
  scrollableElementRef,
  fromPortfolio,
  portfoliosOptions,
  selected,
  selectedPortfolio,
  selectedPortfolioTitle,
  sectors,
  stocks,
  bonds,
  alternatives,
  cash,
  tabs,
  showTarget,
  summary,
  handlePortfolioSelect,
  handleBackToPortfolio,
  handleGoToSingleHolding,
}) => {
  const summaryNode = (
    <Paper top={32}>
      <Skeleton shown={isLoading} mix>
        <Summary sectors={sectors} isLoading={isLoading} summary={summary} isForSinglePortfolio={!!selectedPortfolio} />
      </Skeleton>
    </Paper>
  )

  const sectorsListNode = (
    <SectorsList
      isLoading={isLoading}
      sectors={sectors}
      showTarget={showTarget}
      stocks={stocks}
      bonds={bonds}
      alternatives={alternatives}
      cash={cash}
      handleGoToSingleHolding={handleGoToSingleHolding}
    />
  )

  return (
    <AnalyticsMobileLayout
      isLoading={isLoading}
      scrollableElementRef={scrollableElementRef}
      fromPortfolio={fromPortfolio}
      portfoliosOptions={portfoliosOptions}
      selected={selected}
      selectedPortfolio={selectedPortfolio}
      selectedPortfolioTitle={selectedPortfolioTitle}
      handlePortfolioSelect={handlePortfolioSelect}
      handleBackToPortfolio={handleBackToPortfolio}
      tabs={tabs}
      topContent={summaryNode}
      bottomContent={sectorsListNode}
    />
  )
}

Mobile.propTypes = {
  isLoading: PropTypes.bool,
  scrollableElementRef: PropTypes.object,
  fromPortfolio: PropTypes.bool,
  portfoliosOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selected: PropTypes.string,
  selectedPortfolio: PropTypes.object,
  selectedPortfolioTitle: PropTypes.string,
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      actual: PropTypes.number.isRequired,
      target: PropTypes.number,
    }),
  ),
  stocks: PropTypes.shape(equitiesPropTypes),
  bonds: PropTypes.shape(equitiesPropTypes),
  alternatives: PropTypes.shape(equitiesPropTypes),
  cash: PropTypes.shape(equitiesPropTypes),
  tabs: PropTypes.node,
  showTarget: PropTypes.bool,
  handlePortfolioSelect: PropTypes.func,
  handleBackToPortfolio: PropTypes.func,
  handleGoToSingleHolding: PropTypes.func,
  summary: PropTypes.shape({
    total: PropTypes.number,
  }),
}

export { Mobile }
