import React, { Fragment } from 'react'

import { DesktopHeader } from 'app/containers/Header'
import PropTypes from 'prop-types'

import { useMemo, useState } from 'hooks'

import { format } from 'helpers/money'

import { propTypes } from 'helpers/propTypes'
import { Security } from 'app/effector/securities/models'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Inner from 'components/_old/Inner/Inner.jsx'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { DynamicFontSize } from 'components/atoms/DynamicFontSize'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { PortfolioCard } from 'components/molecules/PortfolioCard/PortfolioCard'
import { PriceInformation } from 'components/molecules/PriceInformation'
import { SearchField } from 'components/molecules/SearchField'
import { SecurityTitle } from 'components/molecules/SecurityTitle'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import { Filter } from 'components/organisms/Filter'
import { FilterGroup } from 'components/organisms/Filter/FilterGroup'
import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { SecurityChart } from 'components/organisms/SecurityChart'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'

import {
  SecurityDescription,
  SecurityDocuments,
  LatestMarketPriceNote,
  SecurityCollections,
} from 'app/pages/Dashboard/Goals/DIY/SecuritySlice/components'
import { TunnelHeader } from 'app/pages/Securities/components'

import { SecurityChartTabs } from '../../components/SecurityChartTabs'
import { SecurityDetails } from '../../components/SecurityDetails'


import { BasePortfolio } from 'app/redux/models/portfolio/Portfolio/BasePortfolio/BasePortfolio'


import { SECURITY_VERSION } from '../../hooks/'

const Desktop = ({
  version,
  isAnalyticsLoading,
  isDetailedSecurityInfoLoading,
  isOtherPortfoliosSecuritiesLoading,
  security,
  isRemovableSecurity,
  portfoliosWithThisSecurity,
  isAddedToCurrentPortfolio,
  periodPriceData,
  chartData,
  totalReturnsData,
  selectedPeriod,
  holdings,
  sectors,
  regions,
  filters,
  searchValue,
  showInvestNowButton,
  isFromPreset,
  handleSearch,
  goToSecurityAnalytics,
  handleRemoveSecurity,
  handlePeriodSelect,
  handleAddSecurity,
  handlePortfoliosModalOpen,
  handleBack,
  handleCancel,
  handleInvestNow,
  sendGaEventOnAddToPortfolioClick,
}) => {
  const isAnalyticsVisible = useMemo(() => security?.type !== 'BOND' && security?.type !== 'ALTERNATIVE', [security])
  const analytics = { holdings, sectors, regions }

  const isDistributing = useMemo(() => security?.dividends_type === 'DISTRIBUTING', [security])

  const [activeTab, setActiveTab] = useState('holdings')
  const [activeChartTab, setActiveChartTab] = useState(isDistributing ? 'totalReturns' : 'priceReturns')

  const tabs = useMemo(
    () => [
      { title: 'Holdings', id: 'holdings', isActive: activeTab === 'holdings', withSearch: handleSearch },
      { title: 'Regions', id: 'regions', isActive: activeTab === 'regions' },
      { title: 'Sectors', id: 'sectors', isActive: activeTab === 'sectors' },
    ],
    [activeTab, handleSearch],
  )
  const activeTabObject = useMemo(() => tabs.find((tab) => tab.isActive) ?? {}, [activeTab])

  const isListFiltered = !!filters.regions.value.length || !!filters.sectors.value.length || !!searchValue

  const price = useMemo(() => {
    const securitySharePrice = security.share_price || 0

    return (
      <Typography weight="semibold" lineHeight="small" data-test-id="portfolioBalance">
        <DynamicFontSize
          min={12}
          max={54}
          lineHeight={1.175}
          length={format(securitySharePrice, true, true).length}
          lengthLimit={9}
        >
          <Skeleton shown={isDetailedSecurityInfoLoading && securitySharePrice === 0} mix inline>
            <PoundsWithPence amount={securitySharePrice} showZeroPence />
          </Skeleton>
        </DynamicFontSize>
      </Typography>
    )
  }, [isDetailedSecurityInfoLoading, security.share_price])

  const addSecurityButton = useMemo(() => {
    const addToPortfolioAction =
      version === SECURITY_VERSION.INSIDE_PORTFOLIO ? handleAddSecurity : handlePortfoliosModalOpen
    const skeleton = isOtherPortfoliosSecuritiesLoading && portfoliosWithThisSecurity.length < 1

    if (isFromPreset) return null

    if (showInvestNowButton) {
      return (
        <Paper top={24}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1}>
              <Paper right={12}>
                <Skeleton shown={skeleton}>
                  <Button
                    mods={{ size: 'new-big block' }}
                    onClick={handleInvestNow}
                    disabled={!security.is_visible_in_universe}
                    data-test-id="securityInvestNowButton"
                  >
                    Invest now
                  </Button>
                </Skeleton>
              </Paper>
            </Column>
            <Column size={0}>
              <Width size={8.875}>
                <Skeleton shown={skeleton}>
                  <Button
                    mods={{ theme: 'simple-reverse-blue', size: 'new-big block' }}
                    onClick={() => {
                      sendGaEventOnAddToPortfolioClick()
                      addToPortfolioAction()
                    }}
                    disabled={!security.is_visible_in_universe}
                    data-test-id="securityAddToPortfolioButton"
                  >
                    Add to portfolio
                  </Button>
                </Skeleton>
              </Width>
            </Column>
          </ColumnarLayout>
        </Paper>
      )
    }

    return (
      <Paper top={24}>
        <Skeleton shown={skeleton} mix>
          <Button
            mods={{ size: 'new-big block' }}
            onClick={addToPortfolioAction}
            disabled={isAddedToCurrentPortfolio || !security.is_visible_in_universe}
            data-test-id="securityAddToPortfolioButton"
          >
            {isAddedToCurrentPortfolio ? 'Already in your portfolio' : 'Add to portfolio'}
          </Button>
        </Skeleton>
      </Paper>
    )
  }, [
    version,
    isOtherPortfoliosSecuritiesLoading,
    portfoliosWithThisSecurity.length,
    isAddedToCurrentPortfolio,
    security.is_visible_in_universe,
    handleAddSecurity,
    handlePortfoliosModalOpen,
    showInvestNowButton,
    handleInvestNow,
    sendGaEventOnAddToPortfolioClick,
    isFromPreset,
  ])

  const removeSecurityButton = useMemo(() => {
    if (!isAddedToCurrentPortfolio || !isRemovableSecurity) return null

    return (
      <Paper top={12}>
        <Typography size={16} align="center" lineHeight="small">
          <Link role="button" onClick={handleRemoveSecurity(security.id)} data-test-id="removeSecurityButton">
            <ItemWithIcon
              space={8}
              inline
              iconPosition="right"
              icon={<Icon size={16} type="delete-16" color="inherit" />}
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              content={<Typo>Remove from portfolio</Typo>}
            />
          </Link>
        </Typography>
      </Paper>
    )
  }, [security.id, isAddedToCurrentPortfolio, isRemovableSecurity, handleRemoveSecurity])

  const currentPriceData = useMemo(() => {
    return activeChartTab === 'totalReturns'
      ? { ...periodPriceData, ...totalReturnsData.stats[selectedPeriod] }
      : periodPriceData
  }, [activeChartTab, periodPriceData, totalReturnsData.stats, selectedPeriod])

  const information = useMemo(
    () =>
      periodPriceData && (
        <PriceInformation
          period={currentPriceData}
          description={
            <Typo>
              Change in share price over time in pounds (£) as well as percentage terms.
              <br />
              <br />
              Note this will {activeChartTab === 'totalReturns' ? '' : 'not'} include any dividend income paid out from
              this security over the period.
            </Typo>
          }
        />
      ),
    [periodPriceData, activeChartTab, currentPriceData],
  )

  const chartTabs = useMemo(
    () =>
      isDistributing && (
        <Paper top={56}>
          <SecurityChartTabs activeChartTab={activeChartTab} setActiveChartTab={setActiveChartTab} />
        </Paper>
      ),
    [activeChartTab, isDistributing],
  )

  const currentChartData = useMemo(() => {
    return activeChartTab === 'totalReturns' ? totalReturnsData.history[selectedPeriod] : chartData
  }, [activeChartTab, chartData, totalReturnsData, selectedPeriod])

  const chart = useMemo(
    () => (
      <Paper top={isDistributing ? 32 : 56}>
        <Skeleton shown={isDetailedSecurityInfoLoading && currentChartData.length < 1}>
          <SecurityChart
            period={selectedPeriod}
            data={currentChartData}
            periodPriceData={currentPriceData}
            handlePeriodSelect={handlePeriodSelect}
            topContent={information}
            topContentOffset={isDistributing ? 24 : 56}
          />
        </Skeleton>
      </Paper>
    ),
    [
      isDetailedSecurityInfoLoading,
      selectedPeriod,
      handlePeriodSelect,
      information,
      currentPriceData,
      currentChartData,
      isDistributing,
    ],
  )

  const portfoliosList = useMemo(() => {
    if (isOtherPortfoliosSecuritiesLoading && portfoliosWithThisSecurity.length < 1) {
      return (
        <Paper top={80}>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Skeleton tag="span" shown inline>
              <Typo>In your portfolios</Typo>
            </Skeleton>
          </Typography>
          <Paper top={24}>
            <Skeleton shown>
              <PortfolioCard
                portfolio={BasePortfolio.createFromObject({ title: '███████' })}
                security={security}
                noType
              />
            </Skeleton>
          </Paper>
        </Paper>
      )
    }

    return (
      portfoliosWithThisSecurity.length > 0 && (
        <Paper top={80}>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Typo>In your portfolios</Typo>
          </Typography>
          {portfoliosWithThisSecurity.map((portfolio) => (
            <Paper top={24} key={portfolio?.id} data-test-id="inYourPortfoliosCard">
              <PortfolioCard portfolio={portfolio} security={security} noType />
            </Paper>
          ))}
        </Paper>
      )
    )
  }, [isOtherPortfoliosSecuritiesLoading, portfoliosWithThisSecurity, security])

  const descriptionNode = useMemo(
    () =>
      security?.description?.length > 0 && (
        <Paper top={80}>
          <SecurityDescription description={security.description} />
        </Paper>
      ),
    [security?.description],
  )

  const detailsNode = useMemo(
    () => (
      <Paper top={24}>
        <SecurityDetails security={security} />
      </Paper>
    ),
    [security],
  )

  const securityCollections = useMemo(
    () => (
      <Paper top={security?.collections?.length ? 48 : 0}>
        <SecurityCollections collections={security?.collections} areLinksDisabled={isFromPreset} />
      </Paper>
    ),
    [security?.collections, isFromPreset],
  )

  const slowSecurityMention = useMemo(
    () => (
      <Paper top={32}>
        <Card level={0} color="primary-surface-25" borderWidth={2}>
          <Paper top={24} bottom={24} left={24} right={24}>
            <Typography size={16} weight="semibold">
              <Paper bottom={12}>
                <Typo>Please note</Typo>
              </Paper>
            </Typography>
            <Typography size={16} color="additional">
              <Typo>
                Buy and sell trades for this ETF may take longer to settle than the usual two business days (‘T+2’).{' '}
                <Link
                  to="https://help.investengine.com/hc/en-gb/articles/5021858224029-What-is-Settlement-and-how-long-does-it-take-"
                  blank
                  hard
                >
                  Read more here.
                </Link>
              </Typo>
            </Typography>
          </Paper>
        </Card>
      </Paper>
    ),
    [],
  )

  const analyticsListNode = useMemo(() => {
    const { id } = activeTabObject
    const items = analytics?.[id]

    if (isAnalyticsLoading && items.length < 1) {
      return (
        <Paper top={32}>
          <Skeleton shown mix>
            <Stack vertical={48}>
              {new Array(5).fill({}).map((_, index) => (
                <SecurityConstituent
                  key={index}
                  name="██████"
                  actual={0}
                  color="transparent"
                  showLogo={id === 'holdings'}
                />
              ))}
              <Skeleton shown>
                <Button mods={{ theme: 'simple-reverse-blue', size: 'big block' }}>Show all</Button>
              </Skeleton>
            </Stack>
          </Skeleton>
        </Paper>
      )
    }

    if (id && items.length > 0) {
      const limitedList = analytics[id].slice(0, 5)

      return (
        <Fragment>
          <Paper top={32}>
            <Stack vertical={48}>
              {limitedList.map((item) => (
                <SecurityConstituent
                  key={item.name}
                  logo={item.logo}
                  name={item.name}
                  actual={item.actual}
                  color={item.color}
                  showLogo={id === 'holdings'}
                />
              ))}
              {analytics[id].length > 5 && (
                <Button
                  mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
                  onClick={() => goToSecurityAnalytics(id)}
                  data-test-id="showAllButton"
                >
                  Show all {analytics[id].length}
                </Button>
              )}
            </Stack>
          </Paper>
        </Fragment>
      )
    }

    return null
  }, [isAnalyticsLoading, goToSecurityAnalytics, activeTabObject, analytics])

  const analyticsNode = useMemo(() => {
    if (!isAnalyticsVisible) return null

    return (
      <Paper top={80}>
        <Fragment>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Typo>ETF Breakdown</Typo>
          </Typography>
          <Paper top={32}>
            <GreyTabs
              tabs={tabs}
              onChange={(tab) => {
                setActiveTab(tab.id)
              }}
            />
          </Paper>
          {activeTabObject?.withSearch && (
            <Fragment>
              <Paper top={24}>
                <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by holding" />
              </Paper>
              <Paper top={16}>
                <Skeleton shown={isAnalyticsLoading} mix>
                  <FilterGroup fitContent>
                    <Filter
                      name="Region"
                      type="checkbox"
                      values={filters.regions.list}
                      selected={filters.regions.value}
                      onChange={filters.regions.set}
                      data-test-id="holdingsFilterRegion"
                    />
                    <Filter
                      name="Sector"
                      type="checkbox"
                      values={filters.sectors.list}
                      selected={filters.sectors.value}
                      onChange={filters.sectors.set}
                      dropdownMaxHeight={520}
                      data-test-id="holdingsFilterSector"
                    />
                  </FilterGroup>
                </Skeleton>
              </Paper>
              {!isAnalyticsLoading && isListFiltered && !analytics[activeTab]?.length && (
                <Paper top={24}>
                  <Typography size={14} lineHeight="medium" color="minor" data-test-id="noSearchResults">
                    <Typo>
                      No holdings found.
                      <br />
                      Please try a different search.
                    </Typo>
                  </Typography>
                </Paper>
              )}
            </Fragment>
          )}
          {analyticsListNode}
        </Fragment>
      </Paper>
    )
  }, [
    isAnalyticsLoading,
    searchValue,
    activeTabObject,
    analyticsListNode,
    activeTab,
    analytics,
    filters,
    tabs,
    isAnalyticsVisible,
    handleSearch,
    isListFiltered,
  ])

  const documents = useMemo(
    () =>
      security?.documents?.length > 0 && (
        <Paper top={80}>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Typo>Key information</Typo>
          </Typography>
          <Paper top={24}>
            <SecurityDocuments documents={security.documents} />
          </Paper>
        </Paper>
      ),
    [security?.documents],
  )

  const desktopHeader = <DesktopHeader />

  const header = useMemo(() => {
    const defaultHeader = (
      <Inner twoColumns>
        <TunnelHeader onBack={handleBack} onCancel={version === SECURITY_VERSION.PRIVATE ? handleCancel : null} />
      </Inner>
    )

    return defaultHeader
  }, [version, handleBack, handleCancel])

  const content = useMemo(() => {
    const defaultContent = (
      <Paper bottom={80}>
        {version === SECURITY_VERSION.PRIVATE && (
          <Paper bottom={32} top={16}>
            <Inner twoColumns>
              <TunnelHeader onBack={handleBack} onCancel={null} />
            </Inner>
          </Paper>
        )}
        <Inner twoColumns>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1} mods={{ strict: true }}>
              <Paper>
                <SecurityTitle
                  logo={security.logo_uri}
                  ticker={security.ticker}
                  title={security.title}
                  description={security.short_description}
                  isFeatured={security.is_featured}
                  size={24}
                  lineHeight="small"
                  iconPosition="left"
                  data-test-id="securityTitle"
                />
              </Paper>
              {chartTabs}
              {chart}
              {portfoliosList}
              {descriptionNode}
              {detailsNode}
              {securityCollections}
              {security.is_slow_to_settle && slowSecurityMention}
              {analyticsNode}
              {documents}
            </Column>
            <Column size={0} sticky>
              <Paper left={80}>
                <Width size={19}>
                  <Card>
                    <Paper top={24} bottom={24} left={24} right={24}>
                      {price}
                      <Paper top={4}>
                        <Skeleton shown={isDetailedSecurityInfoLoading && !security?.price_dt} mix>
                          <LatestMarketPriceNote date={security?.price_dt} />
                        </Skeleton>
                      </Paper>
                      {addSecurityButton}
                      {removeSecurityButton}
                    </Paper>
                  </Card>
                </Width>
              </Paper>
            </Column>
          </ColumnarLayout>
        </Inner>
      </Paper>
    )

    return defaultContent
  }, [
    version,
    security,
    chart,
    portfoliosList,
    descriptionNode,
    slowSecurityMention,
    analyticsNode,
    documents,
    price,
    addSecurityButton,
    removeSecurityButton,
    handleBack,
    isDetailedSecurityInfoLoading,
    securityCollections,
    detailsNode,
    chartTabs,
  ])

  return (
    <DesktopLayout
      noGaps={version === SECURITY_VERSION.PRIVATE}
      header={version === SECURITY_VERSION.INSIDE_PORTFOLIO ? header : desktopHeader}
      content={content}
    />
  )
}

const TotalReturnsHistoryItemPropType = PropTypes.shape({
  date: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
})

const TotalReturnsStatItemPropType = PropTypes.shape({
  price: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
})

Desktop.propTypes = {
  version: PropTypes.string,
  isAnalyticsLoading: PropTypes.bool,
  isDetailedSecurityInfoLoading: PropTypes.bool,
  isOtherPortfoliosSecuritiesLoading: PropTypes.bool,
  security: propTypes.instanceOf(Security),
  isRemovableSecurity: PropTypes.bool,
  portfoliosWithThisSecurity: PropTypes.arrayOf(PropTypes.object),
  isAddedToCurrentPortfolio: PropTypes.bool,
  periodPriceData: PropTypes.shape({
    price: PropTypes.number,
    percent: PropTypes.number,
    name: PropTypes.string,
  }),
  chartData: PropTypes.array,
  totalReturnsData: PropTypes.shape({
    history: PropTypes.shape({
      week: PropTypes.arrayOf(TotalReturnsHistoryItemPropType),
      month: PropTypes.arrayOf(TotalReturnsHistoryItemPropType),
      six_months: PropTypes.arrayOf(TotalReturnsHistoryItemPropType),
      year: PropTypes.arrayOf(TotalReturnsHistoryItemPropType),
      max: PropTypes.arrayOf(TotalReturnsHistoryItemPropType),
    }),
    stats: PropTypes.shape({
      week: TotalReturnsStatItemPropType,
      month: TotalReturnsStatItemPropType,
      six_months: TotalReturnsStatItemPropType,
      year: TotalReturnsStatItemPropType,
      max: TotalReturnsStatItemPropType,
    }),
  }),
  selectedPeriod: PropTypes.string.isRequired,
  holdings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  searchValue: PropTypes.string,
  filters: PropTypes.shape({
    regions: {
      value: PropTypes.arrayOf(PropTypes.number),
      list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.number })),
      set: PropTypes.func,
    },
    sectors: {
      value: PropTypes.arrayOf(PropTypes.number),
      list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.number })),
      set: PropTypes.func,
    },
  }),
  handleSearch: PropTypes.func,
  goToSecurityAnalytics: PropTypes.func.isRequired,
  handleRemoveSecurity: PropTypes.func,
  handlePeriodSelect: PropTypes.func.isRequired,
  handleAddSecurity: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handlePortfoliosModalOpen: PropTypes.func,
  showInvestNowButton: PropTypes.bool,
  handleInvestNow: PropTypes.func,
}

export { Desktop }
