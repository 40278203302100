import React, { Fragment } from 'react'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette/'

import { useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import ModalContent, {
  ModalContentBody,
  ModalContentButtons,
  ModalContentHeadline,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'
import Button from 'components/_old/Button/Button.jsx'
import { Paper } from 'components/atoms/Paper'

import '../NewOptimalPortfolioModal.css'

const PortfolioSwitchedModalContent = ({ onClick: handleClick }) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const classes = classNames(
    'NewOptimalPortfolioModal',
    getMediaQuieryClasses('NewOptimalPortfolioModal', mediaQueries),
  )

  const button = (
    <Button
      className="NewOptimalPortfolioModal-Button"
      mods={{ size: 'big block', text: 'bigger' }}
      onClick={handleClick}
      data-test-id="portfolioSwitchedButton"
    >
      Return to Dashboard
    </Button>
  )

  return (
    <div className={classes} data-test-id="portfolioSwitched">
      <ModalContent width={60.75}>
        <Width size={36.875} center>
          <Paper top={desktop ? null : 40} left={16} right={16}>
            <ModalContentHeadline
              className="NewOptimalPortfolioModal-Headline"
              level={desktop ? 1 : 2}
              align="center"
              paddingRight={false}
              icon={
                <Icon
                  className="NewOptimalPortfolioModal-Icon"
                  type="exclamation-triangle"
                  size={64}
                  color={palette['status-success']}
                />
              }
            >
              Your portfolio switch is underway
            </ModalContentHeadline>
            <ModalContentBody size={null} align="center">
              <Fragment>
                <Typo>
                  We will complete your portfolio switch within two working days. Please note you can always have a new
                  risk assessment via Profile.
                </Typo>
              </Fragment>
            </ModalContentBody>
          </Paper>
          <ModalContentButtons width={20.75} isNewModal>
            {desktop ? button : <div className="NewOptimalPortfolioModal-Buttons">{button}</div>}
          </ModalContentButtons>
        </Width>
      </ModalContent>
    </div>
  )
}

export { PortfolioSwitchedModalContent }
