import React from 'react'

import { useUnit } from 'effector-react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'

import { format as formatMoney } from 'helpers/money'
import { propTypes } from 'helpers/propTypes'

import { $portfolioSecuritiesStore } from 'app/effector/portfolio-securities'

import { BasePortfolio } from 'app/redux/models/portfolio/Portfolio/BasePortfolio/BasePortfolio.js'

import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

const PortfolioCard = ({ portfolio, security }) => {
  const { desktop } = useMediaQueries()

  const { portfolioSecurities } = useUnit($portfolioSecuritiesStore)

  const title = portfolio?.getTitle?.()

  const value = useMemo(() => {
    if (portfolio?.id && security?.id) {
      const portfolioSecuritiesPerspective = portfolioSecurities?.[`${portfolio.id}`]?.perspective
      const portfolioSecurity = portfolioSecuritiesPerspective?.get?.(security.id)

      if (portfolioSecurity) {
        return portfolioSecurity?.value ?? null
      }
    }

    return null
  }, [portfolio?.id, security?.id, portfolioSecurities])

  const content = useMemo(
    () =>
      desktop ? (
        <Paper top={32} bottom={32} left={24} right={24}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Typography size={16} weight="semibold">
              <Typo>{title}</Typo>
            </Typography>
            {value !== null && (
              <Typography size={16} color="minor" weight="semibold" align="right" numeric="tabular">
                <Typo>{formatMoney(value, true)}</Typo>
              </Typography>
            )}
          </ColumnarLayout>
        </Paper>
      ) : (
        <Paper top={16} bottom={16} left={24} right={24}>
          <Typography size={14} weight="semibold" lineHeight="small">
            <Typo>{title}</Typo>
          </Typography>
        </Paper>
      ),
    [desktop, title, value],
  )

  return <Card level={3}>{content}</Card>
}

PortfolioCard.propTypes = {
  portfolio: propTypes.instanceOf(BasePortfolio),
  security: PropTypes.shape({
    id: PropTypes.number,
  }),
}

export { PortfolioCard }
