import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { BottomTools } from 'app/pages/Securities/SecurityUniverse/Mobile/components/BottomTools'

import { steps, type Step } from '../../hooks/useSippDeclaration'
import { RiskDeclarationText } from '../RiskDeclarationText'
import { SippDeclarationText } from '../SippDeclarationText'

type SippDeclarationFormProps = {
  step: Step
  isGeneralDeclarationChecked: boolean
  isRiskDeclarationChecked: boolean
  handleGeneralCheckboxChange: () => void
  handleRiskCheckboxChange: () => void
  handleContinue: () => void
}

const SippDeclarationForm = ({
  step,
  isGeneralDeclarationChecked,
  isRiskDeclarationChecked,
  handleGeneralCheckboxChange,
  handleRiskCheckboxChange,
  handleContinue,
}: SippDeclarationFormProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const generalCheckboxNode = (
    <CheckboxWithLabel
      label={
        <Typography size={14}>
          <Typo>I confirm acceptance of InvestEngine General SIPP & Information Declarations</Typo>
        </Typography>
      }
      checked={isGeneralDeclarationChecked}
      onChange={handleGeneralCheckboxChange}
      value={isGeneralDeclarationChecked}
      data-test-id="sippDeclarationGeneralCheckbox"
    />
  )

  const riskCheckboxNode = (
    <CheckboxWithLabel
      label={
        <Typography size={14}>
          <Typo>I confirm I accept Financial Advice, Risk & SIPP contribution declarations</Typo>
        </Typography>
      }
      checked={isRiskDeclarationChecked}
      onChange={handleRiskCheckboxChange}
      value={isRiskDeclarationChecked}
      data-test-id="sippDeclarationRiskCheckbox"
    />
  )

  const checkboxNode = step === steps.RISK ? riskCheckboxNode : generalCheckboxNode

  const buttonNode = (
    <Button
      disabled={step === steps.RISK ? !isRiskDeclarationChecked : !isGeneralDeclarationChecked}
      mods={{ size: 'big block' }}
      onClick={handleContinue}
      data-test-id="continueButton"
    >
      Continue
    </Button>
  )

  const content = step === steps.RISK ? <RiskDeclarationText /> : <SippDeclarationText />

  return (
    <Fragment>
      <Typography
        size={desktop ? 32 : 24}
        weight="semibold"
        align={desktop ? 'center' : 'left'}
        data-test-id="sippDeclarationTitle"
      >
        <Typo>Pension Plan Declarations</Typo>
      </Typography>
      <Paper top={desktop ? 56 : 24}>
        <Typography align={desktop ? 'center' : 'left'}>
          <Typo>
            We will email you the Personal Pension declaration, please check it over to make sure that all the details
            are correct.
          </Typo>
        </Typography>
      </Paper>
      {content}
      <StickedIfNotDesktop woGateway>
        {desktop ? (
          <Paper top={56}>
            {checkboxNode}
            <Paper top={56}>
              <Width center size={21.5}>
                {buttonNode}
              </Width>
            </Paper>
          </Paper>
        ) : (
          <BottomTools>
            {checkboxNode}
            {buttonNode}
          </BottomTools>
        )}
      </StickedIfNotDesktop>
    </Fragment>
  )
}

export { SippDeclarationForm }
