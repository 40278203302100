import { createEffect, sample } from 'effector'

import { urlTo, goTo } from 'helpers/router'

import { setup2fa } from 'app/effector/2fa/api'

import { showFailToast } from 'app/redux/actions/ui'

// Effects
const startTwoFactorSetupFx = createEffect(async () => {
  await setup2fa({ action: 'enable', type: 'push_and_sms' })
})

const navigateToAwaitingPushFx = createEffect(() => {
  goTo(urlTo('two-factor-auth-setup.awaiting-push'))
})

const showFailToastFx = createEffect(showFailToast)

sample({
  clock: startTwoFactorSetupFx.done,
  target: navigateToAwaitingPushFx,
})

sample({
  clock: startTwoFactorSetupFx.fail,
  target: showFailToastFx,
})

const $isLoading = startTwoFactorSetupFx.pending

export { $isLoading, startTwoFactorSetupFx }
