import React from 'react'

import { useMediaQueries } from 'hooks'

import { Badge } from 'components/atoms/Badge'
import { Typography } from 'components/atoms/Typography'

import './Ticker.css'

const Ticker = ({ text, ref, 'data-test-id': dataTestId }): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Typography ref={ref} size={desktop ? 16 : 14} lineHeight="small" data-test-id={dataTestId}>
      <Badge className="Ticker" text={text} />
    </Typography>
  )
}

export { Ticker }
