export const GiaMixin = (superclass) =>
  /**
   * @class
   * @typedef {Object} GiaMixin
   */
  class extends superclass {
    constructor(...args) {
      super(...args)

      this.regulatory_type = 'GIA'
    }
  }
