import React from 'react'
import classNames from 'classnames/dedupe'
import { modsToClassnames } from 'helpers/classname.js'

import './List.css'

export default function List(props) {
  const { children, className, mods, ...rest } = props
  const classes = classNames('List', className, modsToClassnames('List', mods))

  return (
    <ul className={classes} {...rest}>
      {children}
    </ul>
  )
}

export function ListItem(props) {
  const { children, ...rest } = props

  return (
    <li className="List-Item" {...rest}>
      {children}
    </li>
  )
}
