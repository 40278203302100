import React from 'react'

import './Phantom.css'

type PhantomProps = {
  tag?: keyof JSX.IntrinsicElements
  children: React.ReactElement
}

function Phantom({ tag = 'div', children }: PhantomProps): React.ReactElement {
  return React.createElement(tag, { className: 'Phantom', ariaHidden: true, inert: true }, children)
}

export { Phantom, type PhantomProps }
