import React, { Fragment } from 'react'

import { useMemo } from 'hooks'

import { format as formatDate } from 'helpers/date'
import { format as formatMoney } from 'helpers/money'

import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { ContentWithIconAndLink } from '../ContentWithIconAndLink'

import { SecuritySlicePointOfInterestTypes, type BuySellSecuritySlicePointOfInterest } from './index'

type BuySellSecuritySlicePointOfInterestProps = BuySellSecuritySlicePointOfInterest & {
  linkToTransactions: string | null
}

function SecuritySliceBuySellPointOfInterestContent({
  type,
  date,
  data,
  linkToTransactions,
}: BuySellSecuritySlicePointOfInterestProps): React.ReactElement {
  const content = useMemo(() => {
    const dateString = formatDate(date, 'D MMM YYYY')
    const title = type === SecuritySlicePointOfInterestTypes.SELL ? 'Sold' : 'Bought'
    const price = formatMoney(data.price, true, true)
    const shares = `${data.quantity}`
    const value = formatMoney(data.value, true, true)

    return (
      <Fragment>
        <Typography size={14} lineHeight="small" weight="semibold" align="right" color="inherit">
          <Typo>{dateString}</Typo>
        </Typography>
        <Paper top={12}>
          <Typography size={14} lineHeight="small" weight="semibold" align="right">
            <Typo>{title}</Typo>
          </Typography>
        </Paper>
        <Paper top={8}>
          <Stack vertical={4}>
            <ColumnarLayout mods={{ padding: 'nano' }}>
              <Typography size={14} lineHeight="small" color="minor">
                <Typo>Price</Typo>
              </Typography>
              <Typography size={14} lineHeight="small" align="right">
                <Typo>{price}</Typo>
              </Typography>
            </ColumnarLayout>
            <ColumnarLayout mods={{ padding: 'nano' }}>
              <Typography size={14} lineHeight="small" color="minor">
                <Typo>Shares</Typo>
              </Typography>
              <Typography size={14} lineHeight="small" align="right">
                <Typo>{shares}</Typo>
              </Typography>
            </ColumnarLayout>
            <ColumnarLayout mods={{ padding: 'nano' }}>
              <Typography size={14} lineHeight="small" color="minor">
                <Typo>Value</Typo>
              </Typography>
              <Typography size={14} lineHeight="small" align="right">
                <Typo>{value}</Typo>
              </Typography>
            </ColumnarLayout>
          </Stack>
        </Paper>
      </Fragment>
    )
  }, [type, date, data.price, data.quantity, data.value])

  if (linkToTransactions) {
    return <ContentWithIconAndLink to={linkToTransactions}>{content}</ContentWithIconAndLink>
  }

  return (
    <Paper top={12} right={8} bottom={12} left={12}>
      {content}
    </Paper>
  )
}

export { SecuritySliceBuySellPointOfInterestContent, type BuySellSecuritySlicePointOfInterestProps }
