import { useCallback, useState, useEffect, useLoading, useRef } from 'hooks'

import { isAuthorizationError } from 'helpers/errors'

import { showFailToast } from 'app/redux/actions/ui'
import { request2faStatus, resendPushFor2fa, type TwoFactorStatusResponse, get2faDetails } from 'app/redux/api/2fa'
import { ApiError } from 'app/redux/models/errors'

import { useSmsSend } from 'app/pages/Dashboard/UserProfile/hooks'

type TwoFactorHookParams = {
  onAccept: (isPhoneVerified?: boolean) => void
  onDeny: () => void
  onError?: () => void
  query: {
    client_id?: string
    token?: string
  }
  setIsPhoneVerified?: (flag: boolean) => void
}

type TwoFactorHookData = {
  step: string
  isSendingSms: boolean
  blockedText: string
  handleResendPush: () => Promise<void>
  isResendingPush: boolean
  phone: string
  fetchPhoneNumber: () => Promise<string | undefined>
  shouldRememberBrowser: boolean
  setShouldRememberBrowser: (flag: boolean) => void
  handleSendSms: () => void
  handleBackFromSms: () => void
  handleStopStatusRequest: () => void
}

const useTwoFactorAwait = ({
  onAccept: handleAccept,
  onDeny: handleDeny,
  onError: handleError,
  setIsPhoneVerified,
  query = {},
}: TwoFactorHookParams): TwoFactorHookData => {
  const { isLoading: isResendingPush, wait: waitForResend } = useLoading(false)

  const [phone, setPhone] = useState('')
  const [shouldRememberBrowser, setShouldRememberBrowser] = useState(false)
  const [step, setStep] = useState('initial') // initial | enterSms
  const [isStatusRequestStopped, setIsStatusRequestStopped] = useState(false)

  const { client_id: clientId, token } = query

  const interval = useRef<NodeJS.Timeout | null>(null)

  const handleSuccessSmsSend = useCallback(() => {
    setStep('enterSms')
  }, [])

  const {
    isLoading: isSendingSms,
    blockedText,
    handleSmsSend,
  } = useSmsSend({ action: 'login', clientId, token, onSuccessSmsSend: handleSuccessSmsSend })

  const getPushNotificationStatus = useCallback((): void => {
    request2faStatus({ clientId, token }).then((response) => {
      if (response instanceof ApiError) {
        if (isAuthorizationError(response)) handleDeny()
        else {
          showFailToast('Error while requesting 2FA Status')
          if (typeof handleError === 'function') handleError()
        }
      }
      if ((response as TwoFactorStatusResponse)?.state === 'ALLOWED') {
        if (isStatusRequestStopped) return
        handleAccept(!!phone)
      }
      if ((response as TwoFactorStatusResponse)?.state === 'SMS_REQUIRED') handleAccept(!!phone)
      if ((response as TwoFactorStatusResponse)?.state === 'DENIED') handleDeny()
      if ((response as TwoFactorStatusResponse)?.state !== 'REQUESTED' && interval.current)
        clearInterval(interval.current)
    })
  }, [phone, clientId, token, handleAccept, handleDeny, handleError, isStatusRequestStopped])

  const fetchPhoneNumber = async (): Promise<string | undefined> => {
    const response = await get2faDetails({ clientId, token })

    if (response instanceof ApiError) {
      showFailToast()
      return
    }

    const phoneNumber = (response?.phone_verified && response?.phone) || ''

    setPhone(phoneNumber)
    if (setIsPhoneVerified) setIsPhoneVerified(!!phoneNumber)
  }

  useEffect(() => {
    fetchPhoneNumber()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    interval.current = setInterval(() => {
      getPushNotificationStatus()
    }, 2000)

    return () => {
      if (interval.current) clearInterval(interval.current)
    }
  }, [getPushNotificationStatus])

  const handleResendPush = async (): Promise<void> => {
    const response = await waitForResend(resendPushFor2fa({ clientId, token }))

    if (response instanceof ApiError) showFailToast('Error while requesting push message resend')
  }

  const handleSendSms = useCallback(() => {
    handleSmsSend({ phone })
  }, [phone, handleSmsSend])

  const handleBackFromSms = useCallback(() => {
    setStep('initial')
  }, [])

  const handleStopStatusRequest = (): void => {
    if (interval.current) {
      clearInterval(interval.current)
      setIsStatusRequestStopped(true)
    }
  }

  return {
    step,
    isSendingSms,
    blockedText,
    handleResendPush,
    isResendingPush,
    fetchPhoneNumber,
    phone,
    shouldRememberBrowser,
    setShouldRememberBrowser,
    handleSendSms,
    handleBackFromSms,
    handleStopStatusRequest,
  }
}

export { useTwoFactorAwait }
