import React from 'react'
import PropTypes from 'prop-types'

import { useCallback, useMediaQueries } from 'hooks'

import { processError } from 'helpers/errors'
import { trackEvent } from 'helpers/analytics'

import { types as clientTypes } from 'constants/client'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Card from 'components/_old/Card/Card.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

import AboutBusinessForm from './Form.jsx'

const AboutBusinessSetup = ({ updateCompany, handleAfterSubmit }) => {
  const { desktop } = useMediaQueries()

  const handleSubmit = useCallback(async () => {
    const state = await updateCompany()

    if (!state.result) {
      return processError({ error: state.error })
    }

    trackEvent({ action: 'business_information_submitted', client_type: clientTypes.BUSINESS })

    handleAfterSubmit()
  }, [handleAfterSubmit, updateCompany])

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <Card
        mods={{
          theme: 'transparent',
          'no-padding': 'right bottom left',
        }}
      >
        {submitButton}
      </Card>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  const form = <AboutBusinessForm wrapSubmitButton={wrapSubmitButton} onSubmit={handleSubmit} />

  if (desktop) {
    return (
      <AllCenter>
        <Card
          mods={{
            theme: 'transparent',
            'no-padding': 'left right',
          }}
        >
          {form}
        </Card>
      </AllCenter>
    )
  }

  return form
}

AboutBusinessSetup.propTypes = {
  updateCompany: PropTypes.func,
  handleAfterSubmit: PropTypes.func,
}

export { AboutBusinessSetup }
