import React from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import Width from 'components/_old/Width/Width'

import { Modal } from 'components/molecules/Modal'

import { SmsCodeForm } from 'components/organisms/SmsCodeForm'

import { ClosingAlert } from './components/ClosingAlert'
import { PhoneNumberForm } from './components/PhoneNumberForm'
import { usePhoneConfirmation } from './usePhoneConfirmation'

type PhoneConfirmationModalProps = {
  open: boolean
  isTwoFactorAuthSetup: boolean
  parentModule: string
}

const PhoneConfirmationModal = ({
  open,
  isTwoFactorAuthSetup,
  parentModule,
}: PhoneConfirmationModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    isLoading,
    isClosing,
    blockedText,
    step,
    phone,
    client,
    phoneValidation,
    handlePhoneChange,
    handlePhoneSubmit,
    handleBackToPhone,
    handleSuccessCode,
    handleClose,
    handleContinue2faSetup,
    handleExit,
    handleConfirmIdentityError,
  } = usePhoneConfirmation({ open, parentModule, isTwoFactorAuthSetup })

  const contentNode = useMemo(() => {
    if (isClosing) {
      return <ClosingAlert handleConfirmPhone={handleContinue2faSetup} handleSkip={handleExit} />
    }

    if (step === 'PHONE') {
      return (
        <PhoneNumberForm
          isLoading={isLoading}
          blockedText={blockedText}
          phone={phone}
          client={client}
          validation={phoneValidation}
          handlePhoneChange={handlePhoneChange}
          handleSubmit={handlePhoneSubmit}
          handleClose={handleClose}
        />
      )
    }

    if (step === 'SMS') {
      return (
        <SmsCodeForm
          action={isTwoFactorAuthSetup ? 'setup_2fa' : 'confirm_phone'}
          phone={phone}
          handleSuccess={handleSuccessCode}
          handleBack={handleBackToPhone}
          onConfirmIdentityError={handleConfirmIdentityError}
        />
      )
    }

    return null
  }, [
    isLoading,
    isClosing,
    blockedText,
    step,
    phone,
    client,
    phoneValidation,
    handlePhoneChange,
    handlePhoneSubmit,
    handleBackToPhone,
    handleSuccessCode,
    handleClose,
    handleExit,
    handleContinue2faSetup,
    isTwoFactorAuthSetup,
    handleConfirmIdentityError,
  ])

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose} close={null}>
        <Width size={36} style={{ minHeight: '600px' }}>
          {contentNode}
        </Width>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      {contentNode}
    </Modal>
  )
}

export { PhoneConfirmationModal }
