import { useUnit } from 'effector-react'

import { useCallback, useSelector } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { $bankAccountsStore } from 'app/effector/bank-accounts'

import { bankAccountStates } from 'constants/bankAccounts'
import { states as clientStates } from 'constants/client'
import { manageTypes, regulatoryTypes } from 'constants/portfolio'

type UseQuickStartNavigationProps = {
  portfolioId: string
  isClientCompleted: boolean
}

type UseQuickStartNavigationReturnProps = {
  navigateAfterFinishRegistration: (clientState: string) => void
}

const useQuickStartNavigation = ({
  portfolioId: portfolioIdString,
}: UseQuickStartNavigationProps): UseQuickStartNavigationReturnProps => {
  const portfolioId = portfolioIdString ? parseInt(portfolioIdString, 10) : null
  const { nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)
  const isBankAwaitingApproval = nominatedBankAccount?.state === bankAccountStates.AWAITING_APPROVAL
  const portfolio = useSelector((state) => state.portfolios.list.get(portfolioId))
  const isManagedPortfolio = portfolio?.manage_type === manageTypes.MANAGED

  const navigateAfterFinishRegistration = useCallback(
    (nextState) => {
      const clientState = nextState.client?.state
      const isUkResidence = nextState.owner?.isUkResidence()

      if (portfolio.regulatory_type === regulatoryTypes.ISA && !isUkResidence) {
        goTo(urlTo('dashboard', null, { finishRegistration: true }))
        return
      }

      if (clientState !== clientStates.APPROVED || isBankAwaitingApproval || isManagedPortfolio) {
        goTo(urlTo('dashboard.portfolio', { id: portfolioId }, { finishRegistration: true }))
        return
      }

      goTo(urlTo('dashboard.portfolio', { id: portfolioId }, { finishRegistration: true, quickStart: true }))
    },
    [portfolioId, isManagedPortfolio, isBankAwaitingApproval, portfolio],
  )

  return { navigateAfterFinishRegistration }
}

export { useQuickStartNavigation }
