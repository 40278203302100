import { useUnit } from 'effector-react'

import { useLayoutEffect } from 'hooks'

import { features } from 'helpers/features'

import { $referralCodeStore, fetchReferralCodeFx } from 'app/effector/referralCode'
import type { ReferralCodeStore } from 'app/effector/referralCode/types'

const useReferralCode = (): {
  referralCode: ReferralCodeStore
  isLoading: boolean
  isReferralCodeValid: boolean
  isReferralCodeFetched: boolean
  isNewReferralSystemEnabled: boolean
} => {
  const isNewReferralSystemEnabled = features?.get('new-referral-system')
  const { referralCode, isLoading, isReferralCodeFetched } = useUnit($referralCodeStore)

  const isReferralCodeValid = isNewReferralSystemEnabled ? isReferralCodeFetched && referralCode?.link !== null : true

  useLayoutEffect(() => {
    if (!isReferralCodeFetched) {
      fetchReferralCodeFx()
    }
  }, [isReferralCodeFetched])

  return { referralCode, isLoading, isReferralCodeValid, isReferralCodeFetched, isNewReferralSystemEnabled }
}

export { useReferralCode }
