import { useCallback, useMemo, useSelector } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { getFlatSortedPortfoliosList, selectGoalTitle } from 'app/redux/selectors'

const usePortfolioSelect = ({ portfolioId, fromPortfolio, route, tunnelQuery }) => {
  const portfoliosState = useSelector((state) => state.portfolios)

  const portfolios = useSelector((state) =>
    getFlatSortedPortfoliosList(state).getVisiblePortfolios({ includeCashPortfolios: false }),
  )
  const portfolioIds = portfolios.getArrayOfPortfolioIds()

  const sumOfPortfolioIds = portfolioIds.reduce((sum, portfolioId) => sum + portfolioId, 0)

  const portfoliosOptions = useMemo(() => {
    if (fromPortfolio || portfolios.length <= 1) {
      return []
    }

    return [
      { value: 'all', name: 'All portfolios' },
      ...portfolios.map((portfolio) => ({
        value: `${portfolio.id}`,
        name: selectGoalTitle({ portfolios: portfoliosState }, portfolio.id),
      })),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sumOfPortfolioIds, fromPortfolio, portfolios])

  const selectedPortfolio = useMemo(() => {
    if (portfolios.length <= 1) {
      return portfolios[0]
    }

    return portfolios.find((portfolio) => portfolio.id === portfolioId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sumOfPortfolioIds, portfolioId])

  const selectedPortfolioTitle = useSelector((state) => selectGoalTitle(state, selectedPortfolio?.id))

  const handlePortfolioSelect = useCallback(
    (optionValue) => {
      if (optionValue === 'all') {
        goTo(urlTo(`analytics.${route?.module}`))
      } else {
        goTo(urlTo(`analytics.portfolio.${route?.module}`, { portfolioId: optionValue }))
      }
    },
    [route?.module],
  )

  const handleBackToPortfolio = useCallback(() => {
    goTo(urlTo(`dashboard.portfolio`, { id: portfolioId }, tunnelQuery))
  }, [portfolioId, tunnelQuery])

  return {
    selected: portfolioId ? `${portfolioId}` : 'all',
    selectedPortfolio,
    selectedPortfolioTitle,
    portfoliosOptions,
    handlePortfolioSelect,
    handleBackToPortfolio,
  }
}

export { usePortfolioSelect }
