import { useMemo } from 'hooks'

import { frequencies, weekdays } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'
import type { Frequency } from 'app/pages/Dashboard/Goals/RecurringPayment/types'

import { steps } from '../../useSavingsPlanSetupModal'

import type { SavingsPlanStep } from '../../useSavingsPlanSetupModal'

const frequencyTexts = {
  [frequencies.WEEKLY]: 'Weekly',
  [frequencies.FORTNIGHT]: 'Fortnightly',
  [frequencies.MONTHLY]: 'Monthly',
}

type UseSavingsPlanProgressProps = {
  step: SavingsPlanStep
  frequency: Frequency | null
  startDay: string | null
  monthlyPaymentDay: number | null
}

type UseSavingsPlanProgressReturnProps = {
  firstStepText: string
  secondStepText: string
  isSecondStepActive: boolean
  isThirdStepActive: boolean
}

const useSavingsPlanProgress = ({
  step,
  frequency,
  startDay,
  monthlyPaymentDay,
}: UseSavingsPlanProgressProps): UseSavingsPlanProgressReturnProps => {
  const firstStepText = step !== steps.FREQUENCY && frequency ? frequencyTexts[frequency] : 'Frequency'

  const secondStepText = useMemo(() => {
    if (step === steps.FREQUENCY || step === steps.START_DAY) return 'Start day'
    if (step === steps.DAY_OF_MONTH) return 'Monthly payment day'
    if (monthlyPaymentDay) return `Payment day ${monthlyPaymentDay}`
    if (startDay) return weekdays[Number(startDay) - 1]
    return 'Start day'
  }, [step, startDay, monthlyPaymentDay])

  const isSecondStepActive = step !== steps.FREQUENCY
  const isThirdStepActive = step === steps.AMOUNT

  return {
    firstStepText,
    secondStepText,
    isSecondStepActive,
    isThirdStepActive,
  }
}

export { useSavingsPlanProgress }
