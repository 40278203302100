import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'

import { useEffect, useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette'
import { urlTo, goTo } from 'helpers/router.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'
import { Preloader } from 'components/molecules/Preloader'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import {
  init as initRecoveryCodeForm,
  $recoveryCode,
  $isChecked,
  setIsChecked,
  $isLoading,
  generateRecoveryCodeFx,
  confirmRecoveryCodeFx,
} from './model'
import { RecoveryCode } from './RecoveryCode'

type RecoveryCodeModalProps = {
  open: boolean
}

const RecoveryCodeModal = ({ open }: RecoveryCodeModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const [isChecked, recoveryCode, handleInitRecoveryCodeForm, handleGenerateRecoveryCode, isLoading] = useUnit([
    $isChecked,
    $recoveryCode,
    initRecoveryCodeForm,
    generateRecoveryCodeFx,
    $isLoading,
  ])

  useEffect(() => {
    if (open) {
      handleInitRecoveryCodeForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleClose = (): void => {
    goTo(urlTo('dashboard.user-profile.login-settings'))
  }

  const handleConfirmRecoveryCode = async (): Promise<void> => {
    await confirmRecoveryCodeFx(recoveryCode.id)
    handleClose()
  }

  const titleNode = (
    <Fragment>
      <AllCenter>
        <Icon type="warning-64" color={palette['status-warning']} size={64} />
      </AllCenter>
      <Paper top={16}>
        <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
          <Typo>Write down your recovery code</Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  const content = (
    <Paper top={40}>
      <Typography size={14} align="center">
        <Typo>
          The recovery code is the only way to restore access to your account if you lose your phone. Keep the code in a
          safe place.
        </Typo>
      </Typography>
      <Paper top={40}>
        <RecoveryCode recoveryCode={recoveryCode.raw} onRegenerate={handleGenerateRecoveryCode} />
      </Paper>
    </Paper>
  )

  const button = (
    <Fragment>
      <CheckboxWithLabel
        label={
          <Typography size={14}>
            <Typo>I've written down the recovery code and understand the risk associated with losing the code</Typo>
          </Typography>
        }
        checked={isChecked}
        onChange={setIsChecked}
      />
      <Paper top={16}>
        <Button onClick={handleConfirmRecoveryCode} mods={{ size: 'big block' }} disabled={!isChecked}>
          Continue
        </Button>
      </Paper>
    </Fragment>
  )

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose}>
        <ModalContent width={36}>
          <Paper top={80} bottom={80}>
            {titleNode}
            <Paper right={64} left={64}>
              {content}
              <Paper top={48}>{button}</Paper>
            </Paper>
          </Paper>
        </ModalContent>
        <Preloader loading={isLoading} size="big" zIndex />
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContent width={36}>
        <MobileLayout
          header={<NavigationBar onRightPartClick={handleClose} rightPartText="Close" />}
          content={
            <Fragment>
              {titleNode}
              {content}
            </Fragment>
          }
          footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
        />
      </ModalContent>
      <Preloader loading={isLoading} size="big" zIndex />
    </Modal>
  )
}

export { RecoveryCodeModal }
