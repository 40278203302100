import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'
import { useBuy } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/hooks'

import { Preloader } from 'components/molecules/Preloader'
import { GatewayDest } from 'components/atoms/Gateway'
import { SelectCashModal } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/components/SelectCashModal'

import { BuyDesktop as Desktop } from './Desktop'
import { BuyMobile as Mobile } from './Mobile'
import { useSecurityBuySell } from './hooks'

const BuySecurity = ({ params }) => {
  const { id: portfolioIdString, securityId: securityIdString } = params || {}

  const { desktop } = useMediaQueries()

  const portfolioId = useMemo(() => parseInt(portfolioIdString, 10), [portfolioIdString])
  const securityId = useMemo(() => parseInt(securityIdString, 10), [securityIdString])

  const {
    isLoading: isSecurityLoading,
    security,
    portfolio,
    cashPortfolios,
    handleSell,
    handleBack,
    handleAddFunds,
    handleReview,
  } = useSecurityBuySell({ portfolioId, securityId })

  const {
    currentCashBalance,
    isLoading: isBuyLoading,
    isValid,
    errors,
    control,
    validation,
    buy: handleSubmit,
    isCashSelectModalOpen,
    openCashSelectModal,
    closeCashSelectModal,
    chosenPortfolio,
    setChosenPortfolioId,
    groupedPortfolios,
    isBusiness,
    isIsaLimitWarningDisplayed,
  } = useBuy({
    portfolio,
    portfolioId,
    cashPortfolios,
    security,
    onAfterBuy: (orderId) => handleReview(orderId, 'security-buy'),
  })

  const isLoading = isBuyLoading || isSecurityLoading

  const props = {
    portfolioId,
    currentCashBalance,
    security,

    isValid,
    validation,
    errors,
    control,

    handleSell,
    handleAddFunds,
    handleBack,

    openCashSelectModal,
    chosenPortfolio,
    isIsaLimitWarningDisplayed,

    isBusiness,
  }

  if (!security) {
    return null
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>{desktop ? <Desktop {...props} /> : <Mobile {...props} />}</form>
      <SelectCashModal
        isOpen={isCashSelectModalOpen}
        onClose={closeCashSelectModal}
        onSelect={setChosenPortfolioId}
        groupedPortfolios={groupedPortfolios}
        isBusiness={isBusiness}
      />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
      <Preloader loading={isLoading} size="big" zIndex />
    </Fragment>
  )
}

BuySecurity.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
    securityId: PropTypes.string,
  }),
}

export { BuySecurity }
