const roles = {
  OWNER: 'OWNER',
  DIRECTOR: 'DIRECTOR',
}

const states = {
  UNCHECKED: 'UNCHECKED',
  APPROVED: 'APPROVED',
  REFUSED: 'REFUSED',
  NEED_RECHECK: 'NEED_RECHECK',
}

export { roles, states }
