import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'

import { urlTo } from 'helpers/router.js'
import { format as formatPhone } from 'helpers/phone.js'
import { palette } from 'helpers/palette/'
import { features } from 'helpers/features'

import { useMediaQueries, useMemo, useSelector } from 'hooks'
import { useUnit } from 'effector-react'

import { $owner } from 'app/effector/contacts/'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Width from 'components/_old/Width/Width'
import { Paper } from 'components/atoms/Paper'
import Card from 'components/_old/Card/Card.jsx'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Nobr } from 'components/_old/Nobr'
import ChangePassword from './ChangePassword.jsx'
import { TwoFactorAuthModal } from './TwoFactorAuthModal'
import { TwoFactorEnabledModal } from './TwoFactorEnabledModal'
import { TwoFactorDisabledModal } from './TwoFactorDisabledModal'
import { RequestDeniedModal } from './RequestDeniedModal'
import { PhoneConfirmationModal } from '../../components/PhoneConfirmationModal'
import { PhoneModal } from './PhoneModal'
import { ConfirmIdentityModal } from '../../components/ConfirmIdentityModal'
import { RecoveryCodeModal } from './RecoveryCodeModal'

import { SettingsRow } from '../../components/SettingsRow'

const LoginSettings = ({
  routes,
  location: { query: { twoFactorSetup, twoFactorAuthDisabled, twoFactorAuthEnabled, twoFactorAuthRejected } = {} } = {},
}) => {
  const { desktop } = useMediaQueries()
  const { tfa: twoFA } = useSelector((state) => state.client)
  const owner = useUnit($owner) ?? {}

  const routeModule = routes[routes.length - 1].module

  const arrowIcon = <Icon type="arrow-right-small-24" size={24} color={palette['content-on-background-minor']} />

  const TwoFALabel = useMemo(() => {
    const rightPart = (
      <ItemWithIcon
        inline
        space={4}
        iconPosition="right"
        icon={arrowIcon}
        content={
          <Typography lineHeight="small" color={twoFA === 'ENABLED' ? 'secondary_default' : 'minor'}>
            <Typo>{twoFA === 'ENABLED' ? 'On' : 'Off'}</Typo>
          </Typography>
        }
        iconVerticalAlign="center"
        contentVerticalAlign="center"
      />
    )

    return desktop ? (
      rightPart
    ) : (
      <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
        <Column size={1}>
          <Typography lineHeight="small" color="inherit">
            <Typo>Two-factor authentication</Typo>
          </Typography>
        </Column>
        <Column size={0}>{rightPart}</Column>
      </ColumnarLayout>
    )
  }, [desktop, twoFA])

  const PhoneLabel = useMemo(() => {
    if (!owner.phone) {
      return null
    }

    return (
      <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
        <Column size={desktop ? 0 : 1}>
          <Typography lineHeight="small">
            <Nobr>
              <Typo>{formatPhone(owner.phone)}</Typo>
            </Nobr>
          </Typography>
        </Column>
        <Column size={0}>
          <Paper left={16}>
            <ItemWithIcon
              inline
              space={4}
              iconPosition="right"
              icon={arrowIcon}
              content={
                <Typography lineHeight="small" color={owner.phone_verified ? 'secondary_default' : 'minor'}>
                  <Nobr>
                    <Typo>{owner.phone_verified ? 'Confirmed' : 'Not confirmed'}</Typo>
                  </Nobr>
                </Typography>
              }
              iconVerticalAlign="center"
              contentVerticalAlign="center"
            />
          </Paper>
        </Column>
      </ColumnarLayout>
    )
  }, [desktop, owner])

  const changePasswordUrl = urlTo('dashboard.user-profile.login-settings.change-password')
  const twoFactorUrl = features?.get('2fa-v3')
    ? urlTo('dashboard.user-profile.login-settings.two-factor-auth-setup-start')
    : urlTo('dashboard.user-profile.login-settings.two-factor-auth')
  const phoneNumberUrl = urlTo('dashboard.user-profile.login-settings.phone-number')
  const recoveryCodeUrl = urlTo('dashboard.user-profile.login-settings.recovery-code')

  return (
    <Width size={desktop ? 30 : null}>
      <Card
        mods={{
          theme: desktop ? 'transparent' : 'transparent edge-to-edge',
          'no-padding': desktop ? 'all' : 'top',
        }}
      >
        <SettingsRow labelText="Password" to={changePasswordUrl} data-test-id="profileFormChangePasswordLink">
          <Typography tag="span" size={desktop ? 14 : 16} color="inherit">
            Change password
          </Typography>
        </SettingsRow>
        <SettingsRow
          labelText={
            <Fragment>
              Two-factor
              <br />
              authentication
            </Fragment>
          }
          to={twoFactorUrl}
          data-test-id="profileForm2FALink"
        >
          <Paper top={desktop ? 0 : 16}>{TwoFALabel}</Paper>
        </SettingsRow>
        {(twoFA === 'ENABLED' || owner.phone_verified) && owner.phone ? (
          <SettingsRow labelText="Phone" to={phoneNumberUrl} data-test-id="profileFormConfirmPhoneLink">
            <Paper top={desktop ? 0 : 16}>{PhoneLabel}</Paper>
          </SettingsRow>
        ) : null}
        {twoFA === 'ENABLED' && features?.get('recovery-codes') ? (
          <SettingsRow labelText="Recovery code" to={recoveryCodeUrl}>
            <Paper top={desktop ? 0 : 16}>
              <Typography tag="span" size={desktop ? 14 : 16} color="inherit">
                Generate recovery code
              </Typography>
            </Paper>
          </SettingsRow>
        ) : null}
      </Card>

      <ChangePassword />
      <TwoFactorAuthModal open={routeModule === 'two-factor-auth'} />
      <PhoneConfirmationModal
        open={routeModule === 'confirm-phone-number'}
        parentModule="dashboard.user-profile.login-settings"
        isTwoFactorAuthSetup={twoFactorSetup === 'true'}
      />
      <PhoneModal open={routeModule === 'phone-number'} />
      <ConfirmIdentityModal
        open={routeModule === 'confirm-your-identity'}
        parentModule="dashboard.user-profile.login-settings"
      />
      <TwoFactorDisabledModal open={twoFactorAuthDisabled === 'true'} />
      <TwoFactorEnabledModal open={twoFactorAuthEnabled === 'true'} />
      <RequestDeniedModal open={twoFactorAuthRejected === 'true'} />
      <RecoveryCodeModal open={routeModule === 'recovery-code'} />
    </Width>
  )
}

LoginSettings.propTypes = {
  routes: PropTypes.array,
  location: PropTypes.shape({
    query: PropTypes.shape({
      twoFactorAuthDisabled: PropTypes.string,
      twoFactorAuthEnabled: PropTypes.string,
      twoFactorAuthRejected: PropTypes.string,
    }),
  }),
}

export { LoginSettings }
