import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { goTo, urlTo } from 'helpers/router'
import { SECURITY_UNIVERSE_VERSION } from '../hooks/'
import { useMemo, useCallback } from 'hooks'

import { DesktopHeader } from 'app/containers/Header'
import { DesktopLayout } from 'components/atoms/Layouts'
import { GatewayDest } from 'components/atoms/Gateway'
import { Paper } from 'components/atoms/Paper'
import { RoundedSecuritiesTitle } from '../components/RoundedSecuritiesTitle'
import { Sticky } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/Sticky'
import { TunnelHeader } from 'app/pages/Securities/components'
import { TwoColumns } from 'components/molecules/TwoColumns'
import { Typo } from 'components/_old/Typo/Typo'
import { Typography } from 'components/atoms/Typography'
import Button from 'components/_old/Button/Button.jsx'
import Footer from 'components/_old/Footer/Footer.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

const Desktop = ({
  addSecuritiesCardNode,
  allEtfsNode,
  discoverNode,
  filtersPanelNode,
  handleBack,
  handleCancel,
  handleFiltersClear,
  handleGoToEditWeights,
  handleSearch,
  isDiscoveryDisplayed,
  isDiscoveryVisible,
  location,
  portfolioSecurities,
  recentlyViewedEtfsNode,
  roundedSecuritiesCount,
  scrollableElementRef,
  searchBarNode,
  version,
}) => {
  const addedItemsCount = portfolioSecurities?.length ?? 0
  const hasAddedSecurities = addedItemsCount > 0
  const isPortfolioBeingCreated = Boolean(location.query.portfolioId)
  const isSearching = Boolean(location.query.search)

  const handleBackClick = useCallback(() => {
    const { fromUniverse, search, asset_class, provider, dividends_type, hedged, esg, ordering, ...restQuery } =
      location.query

    handleSearch('')
    handleFiltersClear()
    goTo(urlTo('securities', null, restQuery))
  }, [location.query, handleSearch, handleFiltersClear])

  const title = useMemo(
    () => (
      <Typography size={24} lineHeight="small">
        <RoundedSecuritiesTitle roundedSecuritiesCount={roundedSecuritiesCount} />
      </Typography>
    ),
    [roundedSecuritiesCount],
  )

  const newTitle = (
    <Fragment>
      {!isPortfolioBeingCreated && !isDiscoveryDisplayed && <TunnelHeader onBack={handleBackClick} />}
      <Paper top={32}>
        <Typography size={32} weight="semibold" lineHeight="small">
          <Typo>Browse investments</Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  const dekstopHeader = useMemo(() => {
    if (version === SECURITY_UNIVERSE_VERSION.INSIDE_PORTFOLIO) {
      return (
        <Inner twoColumns>
          <TunnelHeader
            onBack={
              isPortfolioBeingCreated && isSearching
                ? () => {
                    handleSearch('')
                  }
                : location?.query?.back
                  ? handleBack
                  : null
            }
            onCancel={handleCancel}
          />
        </Inner>
      )
    }

    return <DesktopHeader />
  }, [version, isPortfolioBeingCreated, isSearching, handleBack, handleCancel, handleSearch, location?.query?.back])

  const desktopFooter = useMemo(() => {
    if (version === SECURITY_UNIVERSE_VERSION.INSIDE_PORTFOLIO) {
      return <Paper top={32} />
    }

    return (
      <div className="SecurityUniverse-Footer">
        <Footer />
      </div>
    )
  }, [version])

  const continueButton = version === SECURITY_UNIVERSE_VERSION.INSIDE_PORTFOLIO && (
    <Sticky bottom>
      <div className="SecurityUniverse-StuckArea">
        <Inner twoColumns>
          <TwoColumns
            content={
              <SideSpacesCompensator size={2}>
                <Paper top={20} bottom={16} left={32} right={32}>
                  <Paper className="SecurityUniverse-ButtonWrapper">
                    <Button
                      mods={{ size: 'block big' }}
                      disabled={!hasAddedSecurities}
                      onClick={handleGoToEditWeights}
                      data-test-id="securityUniverseContinueButton"
                    >
                      Continue
                    </Button>
                  </Paper>
                </Paper>
              </SideSpacesCompensator>
            }
          />
        </Inner>
      </div>
    </Sticky>
  )

  const content = useMemo(() => {
    return (
      <div className="SecurityUniverse-Wrapper">
        <Inner twoColumns>
          {newTitle}
          <TwoColumns
            content={
              <Fragment>
                <Paper top={48} bottom={16}>
                  {searchBarNode}
                </Paper>
                <Paper bottom={isDiscoveryVisible || isDiscoveryDisplayed ? 48 : 0}>{filtersPanelNode}</Paper>
                {isDiscoveryDisplayed ? discoverNode : allEtfsNode}
              </Fragment>
            }
            sidebar={
              <Paper top={48} bottom={40} data-sidebar-inner={!/portfolioId/.test(location.search)}>
                {addSecuritiesCardNode}
                <Paper top={48}>{recentlyViewedEtfsNode}</Paper>
              </Paper>
            }
            synchronizedScroll
            scrollableElement={scrollableElementRef.current}
            minimalSidebarOffsetTop={48}
            initialSidebarOffsetTop={124}
            sidebarOffsetBottom={version === SECURITY_UNIVERSE_VERSION.INSIDE_PORTFOLIO ? 84 : 0}
          />
          {desktopFooter}
        </Inner>
        {continueButton}
      </div>
    )
  }, [
    addSecuritiesCardNode,
    allEtfsNode,
    discoverNode,
    filtersPanelNode,
    isDiscoveryDisplayed,
    isDiscoveryVisible,
    recentlyViewedEtfsNode,
    searchBarNode,
    version,
    continueButton,
    newTitle,
    title,
  ])

  return (
    <Fragment>
      <DesktopLayout ref={scrollableElementRef} header={dekstopHeader} content={content} />
      <GatewayDest name="supportModal" />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

Desktop.propTypes = {
  addSecuritiesCardNode: PropTypes.node,
  allEtfsNode: PropTypes.node,
  discoverNode: PropTypes.node,
  filtersPanelNode: PropTypes.node,
  handleBack: PropTypes.func,
  handleCancel: PropTypes.func,
  handleGoToEditWeights: PropTypes.func,
  isDiscoveryDisplayed: PropTypes.bool,
  isDiscoveryVisible: PropTypes.bool,
  location: PropTypes.object,
  portfolioSecurities: PropTypes.array,
  recentlyViewedEtfsNode: PropTypes.node,
  roundedSecuritiesCount: PropTypes.number,
  scrollableElementRef: PropTypes.object,
  searchBarNode: PropTypes.node,
  version: PropTypes.string,
}

export { Desktop }
