/* eslint-disable react/prop-types */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { goTo, urlTo } from 'helpers/router.js'
import { palette } from 'helpers/palette/'
import { trackEvent } from 'helpers/analytics'
import compose from 'helpers/compose.js'
import rawMediaQueries from 'helpers/mediaQueries.js'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { Logo } from 'components/_old/Logo/Logo.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { Typography } from 'components/atoms/Typography'
import { UserAvatar } from 'components/molecules/UserAvatar/UserAvatar'
import Card from 'components/_old/Card/Card.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Drawer from 'components/_old/Drawer/Drawer.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import List, { ListItem } from 'components/_old/List/List.jsx'

import './Header.css'

class Header extends React.Component {
  state = {
    isDrawerVisible: false,
  }

  static contextTypes = {
    pageName: PropTypes.string,
  }

  handleHideDrawer() {
    document.dispatchEvent(new CustomEvent('hideDrawer'))
  }

  handleVisibility = () => {
    const { isDrawerVisible } = this.state
    this.setState({ isDrawerVisible: !isDrawerVisible })
  }

  hide = () => {
    // Sometimes animation wont work propely, timeout is a workaroud
    setTimeout(() => {
      this.setState({ isDrawerVisible: false })
    }, 10)
  }

  componentDidMount() {
    document.addEventListener('hideDrawer', this.hide)
  }

  componentWillUnmount() {
    document.removeEventListener('hideDrawer', this.hide)
  }

  handleProfileClick = () => {
    goTo(urlTo('dashboard.user-profile'))
  }

  render() {
    const {
      className,
      left,
      right,
      after,
      menu,
      drawerAction,
      greetings,
      hideDrawer,
      mediaQueries,
      logoLink,
      logoless,
      hard = false,
      location,
      withShadow,
      forceShowRight,
      pageName: pageNameProp,
      client,
      owner,
    } = this.props

    // TODO: need to refactor!!!
    const isDashboard = /\/dashboard\//.test(location.pathname)
    const isCreateNewPortfolio = /(\/create-new-portfolio\/)/.test(location.pathname)
    const isYearlyReview = /(\/yearly-review\/)/.test(location.pathname)
    const isSorry = /(\/sorry\/)/.test(location.pathname)
    const isProfile = /(\/profile\/)/.test(location.pathname)
    const isAuthorizedClientQuestionnaire = isCreateNewPortfolio || isYearlyReview

    const { desktop } = mediaQueries
    const classes = classNames(
      className,
      'Header',
      { Header_withShadow: withShadow },
      getMediaQuieryClasses('Header', mediaQueries),
    )

    const handleLogoClick = () => {
      trackEvent({ category: 'Top menu', action: 'Logo clicked' })
    }

    const greetingsInsideDrawer = (() => {
      const greetingsText = (
        <Headline level={3} className="Header-GreetingsText" mods={{ 'no-margin': 'top bottom' }}>
          {greetings}
        </Headline>
      )

      return (
        <div className="Header-Greetings" data-test-id="headerGreetings">
          {greetingsText}
        </div>
      )
    })()

    const drawer = (
      <Drawer
        className="Header-Drawer"
        isVisible={this.state.isDrawerVisible}
        onVisibilityChange={this.handleVisibility}
      >
        <Card
          className="Drawer-Header"
          mods={{ theme: 'no-border straight-corners', padding: 'small', 'no-padding': 'top bottom left' }}
        >
          <div style={{ display: 'inline-block' }} onClick={this.handleVisibility}>
            <Icon type="cross-thin" color={palette['content-on-background-primary']} size={56} inline />
          </div>
          <Card mods={{ theme: 'no-border straight-corners transparent', padding: 'big', 'no-padding': 'top right' }}>
            <Card mods={{ theme: 'no-border straight-corners transparent', padding: 'biggest', 'no-padding': 'right' }}>
              <ColumnarLayout mods={{ padding: 'nano' }}>
                <Column size={1}>
                  <Icon type="person2" size={21} color={palette['content-on-background-muted']} />
                </Column>
                <Column size={15}>
                  <List>
                    <ListItem>{greetingsInsideDrawer}</ListItem>
                    <ListItem>{drawerAction}</ListItem>
                  </List>
                </Column>
              </ColumnarLayout>
            </Card>
          </Card>
        </Card>
        <Inner className="Header-Inner">
          <div className="Header-Section Header-Section_flex">
            {menu && <div className="Header-Menu">{React.cloneElement(menu, { axis: 'vertical' })}</div>}
          </div>
        </Inner>
      </Drawer>
    )

    /* 😭 */
    const showRight = (() => {
      if (forceShowRight) {
        return true
      }

      if (isDashboard) {
        return false
      }

      return Boolean(right)
    })()

    const userProfileNode = (
      <UserAvatar
        size="40"
        firstName={owner?.first_name}
        lastName={owner?.last_name}
        image={client.avatar}
        isHighlighted={isProfile}
        onClick={this.handleProfileClick}
        dataTestId="headerAvatar"
      />
    )

    const logoNode = (
      <div className="Header-Logo">
        <Link to={logoLink} hard={hard} onClick={handleLogoClick} data-test-id="headerLogo">
          <Logo style={{ height: 32, width: 210.37 }} />
        </Link>
      </div>
    )

    const headerDesktop = (
      <header className={classes}>
        <Inner className="Header-Inner">
          <ColumnarLayout className="Header-Sections">
            <Column size={5} className="Header-Section">
              {logoNode}
            </Column>
            <Column size={22} className="Header-Section">
              <ColumnarLayout className="Header-Sections">
                <Column size={5} className="Header-Section">
                  {menu ? <div className="Header-Menu">{menu}</div> : null}
                </Column>
                <Column size={3} className="Header-Section">
                  {greetings && !isSorry && !isAuthorizedClientQuestionnaire && userProfileNode}
                  {showRight && (
                    <div className="Header-Right" data-test-id="dashboardButton">
                      {right}
                    </div>
                  )}
                </Column>
              </ColumnarLayout>
            </Column>
          </ColumnarLayout>
        </Inner>
        {after}
      </header>
    )

    const newMenuHeaderDesktop = (
      <header className={classes}>
        <Inner className="Header-Inner">
          {logoNode}
          <div className="Header-Menu">{menu}</div>
          <div style={{ minWidth: 40 }}>
            {greetings && !isSorry && !isAuthorizedClientQuestionnaire && userProfileNode}
          </div>
        </Inner>
      </header>
    )

    const headerMobileLogo = (() => {
      if (pageNameProp) {
        return (
          <Typography size={16} lineHeight="small" weight="semibold">
            <Typo>{pageNameProp}</Typo>
          </Typography>
        )
      }

      return (
        <Fragment>
          {logoless ? null : (
            <Link to={logoLink} hard={hard} onClick={handleLogoClick}>
              <Logo style={{ height: 24 }} />
            </Link>
          )}
        </Fragment>
      )
    })()

    const headerMobile = (
      <header className={classes}>
        <Inner className="Header-Inner">
          <div data-test-id="headerLeft">
            {left
              ? React.cloneElement(left, {
                  className: classNames('Header-Drawer'),
                })
              : hideDrawer
                ? null
                : React.cloneElement(drawer, {
                    className: classNames('Header-Drawer Header-Drawer_wider'),
                  })}
          </div>
          <div className="Header-Logo">{headerMobileLogo}</div>
          {userProfileNode}
        </Inner>
        {after}
      </header>
    )

    return desktop ? (menu ? newMenuHeaderDesktop : headerDesktop) : headerMobile
  }
}

export default compose(
  (Component) => withRouter(Component, { withRef: true }),
  withMediaQueries(rawMediaQueries),
  connect((state) => ({
    client: state.client,
  })),
)(Header)
