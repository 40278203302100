import React from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'
import { urlTo } from 'helpers/router.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DesktopLayout, MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Preloader } from 'components/molecules/Preloader'

import { DesktopFooter } from 'app/containers/Footer'

import { useThirdPartyAuth } from './useThirdPartyAuth'

type ThirdPartyAuthProps = {
  location: {
    query?: {
      third_party_connect?: string
      client_id?: string
      state?: string
      redirect_uri?: string
    }
  }
}

const ThirdPartyAuth = ({ location: { query = {} } = {} }: ThirdPartyAuthProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { isLoading, handleContinue } = useThirdPartyAuth({ query })

  const button = (
    <Button mods={{ size: 'big block' }} onClick={handleContinue}>
      Continue
    </Button>
  )

  const content = (
    <AllCenter>
      <Width size={24}>
        <AllCenter>
          <Icon type="checkmark_in_a_circle" color={palette['status-success']} size={64} />
        </AllCenter>
        <Paper top={desktop ? 24 : 32}>
          <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
            <Typo>Your account has been created</Typo>
          </Typography>
        </Paper>
        {desktop && <Paper top={56}>{button}</Paper>}
      </Width>
    </AllCenter>
  )

  const desktopContent = (
    <Inner twoColumns>
      <Paper top={80}>{content}</Paper>
    </Inner>
  )

  const headerNode = <Header hideDrawer logoLink={urlTo('dashboard')} hard withShadow />

  const mobileFooter = <MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>

  if (isLoading) {
    return <Preloader loading size="big" zIndex />
  }

  return desktop ? (
    <DesktopLayout header={headerNode} content={desktopContent} footer={<DesktopFooter />} />
  ) : (
    <MobileLayout header={headerNode} content={content} footer={mobileFooter} />
  )
}

export { ThirdPartyAuth }
