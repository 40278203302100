import isFunction from 'lodash/isFunction'

import { useCallback, useMemo, useMediaQueries, useSelector } from 'hooks'

import { types as clientTypes } from 'constants/client'

const useSteps = ({ params: { id } = {}, steps = [], adapter, location }) => {
  const { desktop } = useMediaQueries()
  const portfolioId = useMemo(() => parseInt(id, 10), [id])

  const client = useSelector((state) => state.client)
  const portfolio = useSelector((state) => state.portfolios.list.get(portfolioId))

  const activeStepIndex = useMemo(() => {
    if (!isFunction(adapter.getActiveStepIndex)) {
      throw new Error("StepProvider hasn't the method getActiveStepIndex")
    }
    return adapter.getActiveStepIndex(steps)
  }, [adapter, steps])

  const activeStep = steps[activeStepIndex]

  const progress = useMemo(() => {
    const progressLength = steps.reduce((length, step) => {
      if (!step.notImpactToProgressBar) {
        length++
      }
      return length
    }, 0)

    let lastImpactToProgressBarIndex = activeStepIndex
    while (steps[lastImpactToProgressBarIndex]?.notImpactToProgressBar && lastImpactToProgressBarIndex > 0) {
      lastImpactToProgressBarIndex--
    }
    return ((lastImpactToProgressBarIndex + 1) / (progressLength + 1)) * 100
  }, [steps, activeStepIndex])

  const getPrevStep = useCallback(
    (shift = 1) => (activeStepIndex == null ? null : steps[activeStepIndex - shift]),
    [steps, activeStepIndex],
  )

  const getNextStep = useCallback(
    (shift = 1) => (activeStepIndex == null ? null : steps[activeStepIndex + shift]),
    [steps, activeStepIndex],
  )

  const redirectByStep = useCallback(
    (step, customParams) => {
      if (!isFunction(adapter.redirect)) {
        throw new Error("StepProvider hasn't the method redirect")
      }

      adapter.redirect(step, customParams, location?.query)
    },
    [adapter, location?.query],
  )

  const redirectToNextStep = useCallback(
    (customParams) => {
      const step = getNextStep()

      if (step) {
        redirectByStep(step, customParams)
      }
    },
    [getNextStep, redirectByStep],
  )

  const redirectToPrevStep = useCallback(
    (customParams) => {
      const step = getPrevStep()

      if (step) {
        redirectByStep(step, customParams)
      }
    },
    [getPrevStep, redirectByStep],
  )

  const redirectByStepName = useCallback(
    (stepName, customParams) => {
      if (!isFunction(adapter.getStepByName)) {
        throw new Error("StepProvider hasn't the method getStepByName")
      }
      const step = adapter.getStepByName(steps, stepName)

      if (step) {
        redirectByStep(step, customParams)
      }
    },
    [redirectByStep, steps, adapter],
  )

  const getStepByName = useCallback(
    (stepName) => {
      if (!isFunction(adapter.getStepByName)) {
        throw new Error("StepProvider hasn't the method getStepByName")
      }
      return adapter.getStepByName(steps, stepName)
    },
    [steps, adapter],
  )

  const isBusiness = client.type === clientTypes.BUSINESS
  const showFeeOnQuestions = (isBusiness && activeStepIndex < 2) || (!isBusiness && activeStepIndex < 1)
  const isNeedShowPanel = activeStep?.feeInfo && portfolio && (desktop || showFeeOnQuestions)

  const prevStep = getPrevStep()
  const nextStep = getNextStep()

  return {
    activeStepIndex,
    activeStep,
    steps,
    progress,
    redirectToNextStep,
    redirectToPrevStep,
    redirectByStepName,
    redirectByStep,
    prevStep,
    nextStep,
    getStepByName,
    isNeedShowPanel,
  }
}

export { useSteps }
