import React from 'react'
import classNames from 'classnames/dedupe'
import ReactSlider from 'react-slider'

import { features } from 'helpers/features'
import rawMediaQueries from 'helpers/mediaQueries.js'
import { modsToClassnames } from 'helpers/classname.js'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import './Slider.css'

// it's hacky way to disallow to drag thumb into disabled area of a slider via monkey patching
// yes it's not an elegant solution, but there is no direct way to do it via `react-slider` component
// it will be removed in a month or two.
// If not, we should rewrite a component without `react-slider` completely.
class CustomReactSlider extends ReactSlider {
  constructor(...args) {
    super(...args)
    const oldMove = this._move

    this._move = (newValue) => {
      if (this.props.scale[newValue - 1]?.disabled) {
        return
      }

      oldMove(newValue)
    }
  }
}

@withMediaQueries(rawMediaQueries)
export default class Slider extends React.Component {
  render() {
    let {
      className,
      scale,
      value,
      valueKey,
      valueAsCaption,
      recommendedIndex,
      onChange,
      colors,
      withBars = false,
      mods = {},
      style,
      orientation = 'horizontal',
      absolute,
      unselected,
      theme,
      mediaQueries,
      limit,
      'data-test-id': dataTestId,
      ...rest
    } = this.props

    if (!mods.size) {
      mods.size = 'normal'
    }

    const gradientOrinetation = orientation === 'horizontal' ? 'to right' : 'to top'
    const gradient = colors
      ? colors.reduce((memo, color, index, colors) => {
          memo = index === 1 ? `linear-gradient(${gradientOrinetation}, ${memo} 0%, ` : memo
          memo += `${color} ${(100 / (colors.length - 1)) * index}%`
          memo += index === colors.length - 1 ? ')' : ', '
          return memo
        })
      : null

    if (valueKey) {
      value =
        scale.findIndex((item) => {
          return value === item[valueKey]
        }) + 1

      const oldOnChange = onChange
      onChange = (number, event) => {
        const index = number - 1
        let item = scale[index]

        if (limit && item.disabled) {
          const firstDisabledIndex = scale.findIndex((item) => item.disabled)

          if (firstDisabledIndex > 0) {
            item = scale[firstDisabledIndex - 1]
          }
        }

        const value = item[valueKey]

        return oldOnChange(value, event)
      }
    }

    const classes = classNames(
      className,
      'Slider',
      `Slider_${orientation}`,
      {
        Slider_wCaption: valueAsCaption,
        Slider_absolute: absolute,
        [`Slider_theme_${theme}`]: theme,
        [`Slider_limitLeft_${recommendedIndex - 1}`]: features.get('new-questionnaire') ? limit : false,
        [`Slider_limitRight_${recommendedIndex + 2}`]: features.get('new-questionnaire') ? limit : null,
        [`Slider_limitRight_${scale.findIndex((item) => item.disabled)}`]: !features.get('new-questionnaire')
          ? limit
          : null,
        'Slider_wo-value': !value,
      },
      modsToClassnames('Slider', mods),
      getMediaQuieryClasses('Slider', mediaQueries),
    )

    return (
      <div className={classes} style={style} data-test-id={dataTestId}>
        {valueAsCaption ? <div className="Slider-ValueAsCaption">{valueAsCaption}</div> : null}
        {unselected ? null : (
          <CustomReactSlider
            scale={scale}
            min={scale ? 1 : null}
            max={scale ? scale.length : null}
            value={value}
            onChange={onChange}
            className="Slider-Holder"
            barClassName="Slider-Bar"
            handleClassName="Slider-Handle"
            withBars={withBars}
            orientation={orientation}
            invert={orientation === 'vertical'}
            autoComplete="off"
            {...rest}
          />
        )}
        {withBars ? null : (
          <div className="Slider-Holder">
            <div
              className="Slider-Bar"
              style={{
                backgroundImage: gradient,
                width: orientation === 'horizontal' ? '100%' : null,
                height: orientation === 'horizontal' ? null : '100%',
              }}
            >
              <div className="Slider-LimitLeft" />
              <div className="Slider-LimitRight" />
            </div>
          </div>
        )}
      </div>
    )
  }
}
