import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import { $bankAccountsStore, fetchBankAccountsFx } from 'app/effector/bank-accounts'
import type { Security } from 'app/effector/securities/models'

import {
  updateOrCreate as updateOrCreateGoalActionCreator,
  selectGoal as selectGoalActionCreator,
  changeField as changeGoalFieldActionCreator,
} from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'
import { isClientNewOrNotCompleted as selectIsClientNewOrNotCompleted } from 'app/redux/selectors'

import { manageTypes, states, regulatoryTypes } from 'constants/portfolio'

type UseSecurityQuickStartProps = {
  security: Security
  location: {
    query?: {
      collection?: string
      portfolioId?: string
      quickStart?: string
    }
  }
}

type UseSecurityQuickStartReturnProps = {
  isModalOpened: boolean
  handleStartWithSavingsPlan: () => Promise<void>
  handleStartWithLumpSum: () => Promise<void>
  handleClose: () => void
}

const useSecurityQuickStart = ({
  security,
  location: { query: { quickStart, ...restQuery } = {} } = {},
}: UseSecurityQuickStartProps): UseSecurityQuickStartReturnProps => {
  const isModalOpened = quickStart === 'true'

  const allNewDIYPortfolios = useSelector((state) =>
    state.portfolios.list.filterByShape({ manage_type: manageTypes.DIY, state: states.NEW }),
  )
  const isClientNewOrNotCompleted = useSelector(selectIsClientNewOrNotCompleted)
  const { bankAccounts } = useUnit($bankAccountsStore)
  const hasBankAccounts = !!bankAccounts.length

  const [updateOrCreateGoal, selectGoal, changeGoalField] = useActions([
    updateOrCreateGoalActionCreator,
    selectGoalActionCreator,
    changeGoalFieldActionCreator,
  ])

  useEffect(() => {
    if (!isClientNewOrNotCompleted && !hasBankAccounts) {
      fetchBankAccountsFx()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClientNewOrNotCompleted, hasBankAccounts, fetchBankAccountsFx])

  const createNewPortfolio = useCallback(async () => {
    const { portfolioId: portfolioIdString } = restQuery ?? {}
    const portfolioId = portfolioIdString ? parseInt(portfolioIdString, 10) : null

    const portfolioTitle = security?.title?.length > 30 ? `${security?.title?.slice(0, 27)}...` : security?.title

    try {
      const alreadyCreatedNewPortfolio = portfolioId
        ? allNewDIYPortfolios?.find((portfolio) => portfolio.id === portfolioId)
        : allNewDIYPortfolios?.[0] ?? null

      if (alreadyCreatedNewPortfolio) {
        changeGoalField({ title: portfolioTitle }, alreadyCreatedNewPortfolio.id)
        await selectGoal(null)
        await updateOrCreateGoal(['title'], {}, false, alreadyCreatedNewPortfolio.id)

        return alreadyCreatedNewPortfolio
      }

      await selectGoal(null)
      const nextState = await updateOrCreateGoal(
        ['manage_type', 'regulatory_type', 'title'],
        {
          manage_type: manageTypes.DIY,
          regulatory_type: regulatoryTypes.GIA,
          title: portfolioTitle,
        },
        false,
        undefined,
      )

      if (nextState.portfolios.error) {
        throw nextState.portfolios.error
      }

      const createdNewPortfolio =
        nextState.portfolios.list.filterByShape({ manage_type: manageTypes.DIY, state: states.NEW })?.[0] ?? null

      return createdNewPortfolio
    } catch (error) {
      showFailToast()
    }
  }, [security, allNewDIYPortfolios, restQuery, selectGoal, changeGoalField, updateOrCreateGoal])

  const handleStartWithSavingsPlan = useCallback(async () => {
    trackEvent({ action: 'sp_investnow_sp_clicked' })
    trackEvent({
      action: 'view_cart',
      currency: 'GBP',
      quantity: 1,
      item_category: restQuery?.collection ?? 'all-etfs',
      item_id: security?.id,
      item_name: security?.title,
      item_brand: security?.provider_filter_name,
      value: null,
      payment_type: null,
      price: null,
    })

    const portfolio = await createNewPortfolio()
    const currentLocation = window.location.pathname + window.location.search

    goTo(
      urlTo(
        'securities.security.savings-plan-setup',
        { id: security?.id },
        { ...restQuery, portfolioId: portfolio?.id, back: currentLocation },
      ),
    )
  }, [createNewPortfolio, security, restQuery])

  const handleStartWithLumpSum = useCallback(async () => {
    trackEvent({ action: 'sp_investnow_ls_clicked' })
    trackEvent({
      action: 'view_cart',
      currency: 'GBP',
      quantity: 1,
      item_category: restQuery?.collection ?? 'all-etfs',
      item_id: security?.id,
      item_name: security?.title,
      item_brand: security?.provider_filter_name,
      value: null,
      payment_type: null,
      price: null,
    })

    const portfolio = await createNewPortfolio()
    const currentLocation = window.location.pathname + window.location.search

    goTo(
      urlTo(
        'securities.security.lump-sum-setup',
        { id: security?.id },
        { ...restQuery, portfolioId: portfolio?.id, back: currentLocation },
      ),
    )
  }, [createNewPortfolio, security, restQuery])

  const handleClose = useCallback(() => {
    trackEvent({ action: 'sp_investnow_cancel_clicked' })

    goTo(urlTo('securities.security', { id: security?.id }, { ...restQuery }))
  }, [security, restQuery])

  return {
    isModalOpened,
    handleStartWithSavingsPlan,
    handleStartWithLumpSum,
    handleClose,
  }
}

export { useSecurityQuickStart }
