import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

const getHintsStatus = async (): Promise<Record<string, boolean> | ApiError> => {
  try {
    const { data } = await axios.get('/hints/')

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const setHintsStatus = async (key: string, isViewed: boolean): Promise<undefined | ApiError> => {
  try {
    const { data } = await axios.patch('/hints/', {
      key,
      is_viewed: isViewed,
    })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { getHintsStatus, setHintsStatus }
