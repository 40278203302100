import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import xor from 'lodash/xor'
import isEqual from 'lodash/isEqual'

import { useMediaQueries, useState, useEffect, useCallback } from 'hooks'

import Segment from 'components/atoms/Segment/Segment.jsx'
import { Paper } from 'components/atoms/Paper'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'
import Width from 'components/_old/Width/Width'
import Card from 'components/_old/Card/Card.jsx'
import Button from 'components/_old/Button/Button.jsx'

import { SelectACard, propTypes as selectACardPropTypes } from 'app/containers/Questionnaire/Question/SelectACard'

const QuestionnaireQuestionMultiple = ({ code, cards, onSubmit: parentHandleSubmit, getAnswers }) => {
  const { desktop } = useMediaQueries()
  const [selectedCards, setSelectedCards] = useState(getAnswers(code) || [])

  useEffect(() => {
    const answers = getAnswers(code) || []

    if (!isEqual(answers, selectedCards)) {
      setSelectedCards(answers)
    }
  }, [getAnswers])

  const handleSelect = useCallback(
    (_event, answerId) => {
      const exclusiveCard = cards.find((card) => card?.extra_data?.exclusive)
      const selectedCard = cards.find(({ value }) => value === answerId)

      if (selectedCard.value === exclusiveCard?.value) {
        if (selectedCards.indexOf(selectedCard.value) > -1) {
          return setSelectedCards([])
        }

        return setSelectedCards([answerId])
      }

      const selectedCardsWithoutExclusive = selectedCards.filter((value) => value !== exclusiveCard?.value)

      return setSelectedCards(xor(selectedCardsWithoutExclusive, [answerId]))
    },
    [cards, selectedCards, setSelectedCards],
  )

  const handleSubmit = useCallback(
    (event) => parentHandleSubmit(event, selectedCards),
    [parentHandleSubmit, selectedCards],
  )

  cards = cards.map((card) => ({
    ...card,
    selected: selectedCards.indexOf(card.value) > -1,
    center: true,
    'data-test-id': 'questionnaireCardMultiple',
  }))

  const cardNodes = <SelectACard cards={cards} multiple onSelect={handleSelect} />

  const tickAllTextNode = (
    <Text center block>
      <Typo>Tick all that apply</Typo>
    </Text>
  )
  const button = (
    <StickedIfNotDesktop into="afterLayout">
      <Width size={desktop ? 14 : null} center>
        <Card
          mods={{
            theme: 'transparent',
            padding: 'biggest',
            'no-padding': desktop ? 'left right bottom' : 'all',
            'no-padding-keyboard-shown': 'top',
          }}
        >
          <Button
            type="submit"
            mods={{ size: 'big block', theme: desktop ? null : 'not-rounded' }}
            onClick={handleSubmit}
            tabIndex={cards.length + 1}
            disabled={selectedCards.length < 1}
            data-test-id="questionnaireSubmit"
          >
            Continue
          </Button>
        </Card>
      </Width>
    </StickedIfNotDesktop>
  )

  if (desktop) {
    return (
      <Fragment>
        <Paper top={48} bottom={64}>
          {tickAllTextNode}
        </Paper>
        {cardNodes}
        <Segment mods={{ noMargin: 'top', margin: 'double' }}>{button}</Segment>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Segment mods={{ margin: 'biggest', noMargin: 'top' }}>{tickAllTextNode}</Segment>
      {cardNodes}
      {button}
    </Fragment>
  )
}

QuestionnaireQuestionMultiple.propTypes = {
  code: PropTypes.string.isRequired,
  cards: selectACardPropTypes.cards,
  analytics: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func,
  getAnswers: PropTypes.func,
}

export { QuestionnaireQuestionMultiple }
