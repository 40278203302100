import React, { Fragment } from 'react'
import { RadioGroup, Radio } from 'react-radio-group'

import isNull from 'lodash/isNull'

import { useMediaQueries, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'

import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { CheckboxWithLabel, RadioWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { TunnelHeader } from 'app/pages/Securities/components'

import { TermsDisagreementModal } from './TermsDisagreementModal'

type SelfSelectedTermsProps = {
  preset: Record<string, any>
  onCancel: () => void
  onContinue: () => void
}

const SelfSelectedTerms = ({
  preset,
  onCancel: handleCancel,
  onContinue: handleContinue,
}: SelfSelectedTermsProps): React.ReactElement | null => {
  const { desktop } = useMediaQueries()

  const [investmentKnowledgeCheckboxValue, setInvestmentKnowledgeCheckboxValue] = useState(false)
  const [financialSituationCheckboxValue, setFinancialSituationCheckboxValue] = useState(false)
  const [investmentObjectivesCheckboxValue, setInvestmentObjectivesCheckboxValue] = useState(false)
  const [readTaC, setReadTaC] = useState(null)
  const [isDisagreedModalOpened, setIsDisagreedModalOpened] = useState(false)

  const allCheckboxesAreChecked =
    [investmentKnowledgeCheckboxValue, financialSituationCheckboxValue, investmentObjectivesCheckboxValue].every(
      (value) => !isNull(value),
    ) && readTaC

  const agreedWithAllTerms = [
    investmentKnowledgeCheckboxValue,
    financialSituationCheckboxValue,
    investmentObjectivesCheckboxValue,
    readTaC,
  ].every((value) => Boolean(value))

  const handleConfirm = (): void => {
    trackEvent({
      action: 'lp_terms_confirm',
      manage_type: 'SELF_SELECTED',
      preset: preset.title,
      answers: `${investmentKnowledgeCheckboxValue ? 'Yes' : 'No'}/${financialSituationCheckboxValue ? 'Yes' : 'No'}/${investmentObjectivesCheckboxValue ? 'Yes' : 'No'}`,
    })

    if (!agreedWithAllTerms) {
      setIsDisagreedModalOpened(true)
      return
    }

    handleContinue()
  }

  const handleCloseDisagreedModal = (): void => {
    setIsDisagreedModalOpened(false)
  }

  const header = (
    <Width center size={52}>
      <TunnelHeader cancelTitle="Cancel" onCancel={handleCancel} />
    </Width>
  )

  const mobileHeader = <NavigationBar rightPartText="Cancel" onRightPartClick={handleCancel} />

  const headline = (
    <Paper>
      <Typography size={32} weight="semibold" lineHeight="small" align="center" data-test-id="lifePlanTermsTitle">
        <Typo>Is this the right portfolio for you?</Typo>
      </Typography>
    </Paper>
  )

  const checkbox = (
    <Typography align={desktop ? 'center' : 'left'}>
      <CheckboxWithLabel
        label={
          <Typography size={14}>
            <Typo>
              I’ve read{' '}
              <Link underline blank to={preset?.kiid} mods={{ color: 'content-on-background-default' }}>
                key investor information
              </Link>
            </Typo>
          </Typography>
        }
        checked={readTaC}
        onChange={setReadTaC}
        value={readTaC}
        data-test-id="lifePlanTermsCheckbox"
      />
    </Typography>
  )

  const disclaimer = (
    <Typography size={14} align={desktop ? 'left' : 'center'}>
      <Typo>
        With investment, your capital is at{' '}
        <Link to="/risk-disclosure/" hard blank underline mods={{ color: 'content-on-background-default' }}>
          risk
        </Link>
      </Typo>
    </Typography>
  )

  const submitButton = (
    <Width size={desktop ? 21 : null} center>
      <Button
        disabled={!allCheckboxesAreChecked}
        mods={{ size: 'big block', theme: null }}
        onClick={handleConfirm}
        data-test-id="lifePlanTermsConfirmButton"
      >
        Confirm
      </Button>
    </Width>
  )

  const content = (
    <Width size={40} center>
      <Paper top={desktop ? 64 : 48}>{headline}</Paper>
      <Paper top={40}>
        <Paper bottom={32}>
          <Typography size={14}>
            <Typo>
              To ensure our LifePlan portfolio is the right fit for you, you need to tell us a little bit about yourself
              with a few important questions. Our portfolios are pre-built to align with a standard risk rating, focused
              solely on growth, they are not designed to generate income. <br /> <br />
              The choice is yours - You'll choose the risk-graded portfolio that best fits your personal goals and give
              us the authority to trade, manage, and rebalance it on your behalf, ensuring it stays aligned with its
              pre-set objectives.
            </Typo>
          </Typography>
        </Paper>
        <Paper bottom={32}>
          <Paper bottom={8}>
            <Typography size={16} weight="semibold">
              <Typo>Investment Knowledge and Experience</Typo>
            </Typography>
          </Paper>
          <Typography size={14}>
            <Typo>
              Do you have prior experience investing in the stock market; and an understanding of the specific
              investments (ETFs) and portfolio you're considering, and understand the risks associated with stock market
              investments?
            </Typo>
          </Typography>
          <Paper top={8}>
            <RadioGroup selectedValue={investmentKnowledgeCheckboxValue} onChange={setInvestmentKnowledgeCheckboxValue}>
              <ColumnarLayout fluid>
                <Column size={0} key={1}>
                  <RadioWithLabel
                    CustomInput={Radio}
                    label="Yes"
                    value={true}
                    size="smaller"
                    data-test-id="knowledgeYesRadiobutton"
                  />
                </Column>
                <Column size={0} key={2}>
                  <RadioWithLabel
                    CustomInput={Radio}
                    label="No"
                    value={false}
                    size="smaller"
                    data-test-id="knowledgeNoRadiobutton"
                  />
                </Column>
              </ColumnarLayout>
            </RadioGroup>
          </Paper>
        </Paper>
        <Paper bottom={32}>
          <Paper bottom={8}>
            <Typography size={16} weight="semibold">
              <Typo>Financial Situation and risk capacity & timeline</Typo>
            </Typography>
          </Paper>
          <Typography size={14}>
            <Typo>
              Are you investing an amount that comfortably fits within your financial stability and income, having
              carefully considered your other financial commitments, including any debts, and you understand the
              potential for financial loss, and have the capacity to bear those losses?
            </Typo>
          </Typography>
          <Paper top={8}>
            <RadioGroup selectedValue={financialSituationCheckboxValue} onChange={setFinancialSituationCheckboxValue}>
              <ColumnarLayout fluid>
                <Column size={0} key={1}>
                  <RadioWithLabel
                    CustomInput={Radio}
                    label="Yes"
                    value={true}
                    size="smaller"
                    data-test-id="riskCapacityYesRadiobutton"
                  />
                </Column>
                <Column size={0} key={2}>
                  <RadioWithLabel
                    CustomInput={Radio}
                    label="No"
                    value={false}
                    size="smaller"
                    data-test-id="riskCapacityNoRadiobutton"
                  />
                </Column>
              </ColumnarLayout>
            </RadioGroup>
          </Paper>
        </Paper>
        <Paper bottom={8}>
          <Typography size={16} weight="semibold">
            <Typo>Investment Objectives and risk tolerance</Typo>
          </Typography>
        </Paper>
        <Typography size={14}>
          <Typo>
            Have you understood the investment objectives and risks of the growth portfolio you're selecting, and are
            you confident it aligns with your goals, risk tolerance, and you have a medium to long-term financial
            timeline and are confident choosing one of our risk-graded portfolios meets your objectives?
          </Typo>
        </Typography>
        <Paper top={8}>
          <RadioGroup selectedValue={investmentObjectivesCheckboxValue} onChange={setInvestmentObjectivesCheckboxValue}>
            <ColumnarLayout fluid>
              <Column size={0} key={1}>
                <RadioWithLabel
                  CustomInput={Radio}
                  label="Yes"
                  value={true}
                  size="smaller"
                  data-test-id="riskToleranceYesRadiobutton"
                />
              </Column>
              <Column size={0} key={2}>
                <RadioWithLabel
                  CustomInput={Radio}
                  label="No"
                  value={false}
                  size="smaller"
                  data-test-id="riskToleranceNoRadiobutton"
                />
              </Column>
            </ColumnarLayout>
          </RadioGroup>
        </Paper>
      </Paper>
      <Paper top={40} bottom={desktop ? 40 : 24} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {checkbox}
        {desktop && disclaimer}
      </Paper>
      <Paper bottom={desktop ? 72 : 48}>
        {submitButton}
        {!desktop && (
          <Paper top={24} bottom={24}>
            {disclaimer}
          </Paper>
        )}
      </Paper>
    </Width>
  )

  return (
    <Fragment>
      {desktop ? (
        <DesktopLayout header={header} content={content} footer={null} noGaps fullSize />
      ) : (
        <MobileLayout header={mobileHeader} content={content} />
      )}

      <TermsDisagreementModal isOpen={isDisagreedModalOpened} onClose={handleCloseDisagreedModal} />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

export { SelfSelectedTerms }
