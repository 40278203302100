import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useCallback, useState, useMemo, useEffect } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { createPendingOrderFx, $isLoading } from 'app/effector/pending-orders'
import { $portfolioSecuritiesStore } from 'app/effector/portfolio-securities'

import { showFailToast } from 'app/redux/actions/ui'

import { type as pendingOrderType } from 'constants/pendingOrder.js'

const rebalanceErrorCodes = ['withdrawal_exists', 'unsettled_trades_exist']

const rebalanceOrderFx = attach({
  effect: createPendingOrderFx,
  // eslint-disable-next-line
  mapParams: ({ type, portfolio_id }) => ({
    type,
    portfolio_id,
  }),
})

const usePortfolioRebalance = (portfolioId) => {
  const [isRebalanceRestricted, setIsRebalanceRestricted] = useState(false)
  const [rebalanceRestrictionType, setRebalanceRestrictionType] = useState(null)

  const isRebalanceLoading = useUnit($isLoading)

  const { selectSecuritiesInPortfolioDetailed } = useUnit($portfolioSecuritiesStore)
  const securities = selectSecuritiesInPortfolioDetailed(portfolioId, false)

  const isTradingSuspended = useMemo(
    () => securities.some(([security]) => !security.is_trading_available),
    [securities],
  )

  const handleRebalanceRestrictionModalClose = useCallback(() => {
    setIsRebalanceRestricted(false)
  }, [setIsRebalanceRestricted])

  useEffect(() => {
    const doneSubscription = rebalanceOrderFx.done.watch(({ params }) => {
      const portfolioId = params.portfolio_id
      const tunnelQuery = params.tunnelQuery

      if (tunnelQuery) {
        goTo(urlTo('dashboard.portfolio.rebalance', { id: portfolioId }, tunnelQuery))
      } else {
        goTo(urlTo('dashboard.portfolio.rebalance', { id: portfolioId }))
      }
    })

    const failSubscription = rebalanceOrderFx.fail.watch(({ error }) => {
      const nonFieldErrors = error.response?.data?.non_field_errors

      if (nonFieldErrors?.[0] && rebalanceErrorCodes.includes(nonFieldErrors[0].code)) {
        setIsRebalanceRestricted(true)
        setRebalanceRestrictionType(nonFieldErrors[0].code)
      } else {
        showFailToast()
      }
    })

    return () => {
      doneSubscription.unsubscribe()
      failSubscription.unsubscribe()
    }
  }, [])

  const createRebalanceOrder = useCallback(
    (portfolioId, tunnelQuery, isSuspended) => {
      if (isSuspended) {
        setIsRebalanceRestricted(true)
        setRebalanceRestrictionType('trading_suspended')
        return
      }

      rebalanceOrderFx({
        type: pendingOrderType.REBALANCE,
        portfolio_id: portfolioId,
        tunnelQuery,
      })
    },
    [setIsRebalanceRestricted, setRebalanceRestrictionType],
  )

  return {
    isRebalanceLoading,
    isRebalanceRestricted,
    rebalanceRestrictionType,
    handleRebalanceRestrictionModalClose,
    createRebalanceOrder,
    isTradingSuspended,
  }
}

export { usePortfolioRebalance }
