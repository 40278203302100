import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

const NoPortfolios = ({ client, createPortfolioUrl }) => {
  const { desktop } = useMediaQueries()

  if (desktop) {
    return <Desktop client={client} createPortfolioUrl={createPortfolioUrl} />
  }

  return <Mobile client={client} createPortfolioUrl={createPortfolioUrl} />
}

NoPortfolios.propTypes = {
  client: PropTypes.shape({ state: PropTypes.string }),
  createPortfolioUrl: PropTypes.string,
}

export { NoPortfolios }
