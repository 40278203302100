import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import compose from 'helpers/compose.js'

import { setNotValid as setGoalsNotValid } from 'app/redux/actions/portfolios'
import { changeModalVisibility } from 'app/redux/actions/ui'

import AttentionModalCheckbox from 'components/_old/AttentionModalCheckbox/AttentionModalCheckbox.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import AttentionModalText from 'components/molecules/AttentionModalText/AttentionModalText.jsx'
import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'

import AttentionModal from 'components/organisms/AttentionModal/AttentionModal.jsx'

const AttentionModalWhole = ({
  changedPreset,
  presets,
  onSubmit: handleSubmit,
  visible,
  goal: { preset_recommended: presetRecomended, preset_changed: presetChanged }, // TODO: should be delete when growth will be refactored for changedPreset
  changeModalVisibility,
}): React.ReactElement => {
  const tempPreset = changedPreset || presetChanged
  const recommendedIndex = presets.findIndex((preset) => preset.id === presetRecomended)
  const desiredIndex = presets.findIndex((presetItem) => presetItem.id === tempPreset)

  const { desktop } = useMediaQueries()

  const handleCloseAttentionModal = useCallback(() => {
    changeModalVisibility('attentionModal', false)
  }, [changeModalVisibility])

  const handleSubmitAttentionModal = useCallback(() => {
    setGoalsNotValid()
    handleCloseAttentionModal()
    handleSubmit()
  }, [handleCloseAttentionModal, handleSubmit])

  const increaseText = (
    <Text>
      <Typo>
        <p>
          You have selected a portfolio that is more risky than the one we suggested based on your answers to the risk
          questionnaire. Although the current portfolio has higher expected returns it also has higher potential losses
          than the optimal portfolio.
        </p>
      </Typo>
    </Text>
  )

  const decreaseText = (
    <Text>
      <Typo>
        <p>
          You have selected a portfolio that is less risky than the one we suggested based on your answers to the risk
          questionnaire. Although the current portfolio has lower expected losses you might not be able to achieve your
          investment objectives.
        </p>
      </Typo>
    </Text>
  )

  const checkboxText = (
    <Text>
      <Typo>I understand the risk associated with this portfolio and am prepared to continue on this basis.</Typo>
    </Text>
  )

  const renderSubmit = (disabled = false): React.ReactElement => (
    <StickedIfNotDesktop into="insideModal">
      <Button
        onClick={handleSubmitAttentionModal}
        mods={{
          size: 'big block',
          theme: desktop ? null : 'not-rounded',
        }}
        disabled={disabled}
        data-test-id="attentionModalSubmit"
      >
        Confirm
      </Button>
    </StickedIfNotDesktop>
  )

  return (
    <AttentionModalCheckbox recommendedIndex={recommendedIndex} desiredIndex={desiredIndex} checkboxText={checkboxText}>
      {({ shouldShowCheckbox = false, checkbox, submitDisabled }) => (
        <AttentionModal
          visible={visible}
          closeAttentionModal={handleCloseAttentionModal}
          submit={renderSubmit(submitDisabled)}
        >
          <AttentionModalText
            increaseText={increaseText}
            decreaseText={decreaseText}
            presets={presets}
            recommendedIndex={recommendedIndex}
            desiredIndex={desiredIndex}
          />
          {shouldShowCheckbox ? checkbox : null}
        </AttentionModal>
      )}
    </AttentionModalCheckbox>
  )
}

AttentionModalWhole.propTypes = {
  changedPreset: PropTypes.number,
  presets: PropTypes.shape({
    findIndex: PropTypes.func,
  }),
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
  goal: PropTypes.shape({
    preset_recommended: PropTypes.number,
    preset_changed: PropTypes.number,
  }),
  changeModalVisibility: PropTypes.func,
}

export default compose(
  connect(
    ({
      portfolios,
      ui: {
        modals: {
          attentionModal: { visible },
        },
      },
    }) => {
      const goalId = portfolios.selectedGoal
      const goal = portfolios.items.find((goal) => goal.id === goalId) || {}

      return {
        goal,
        visible,
      }
    },
    {
      changeModalVisibility,
    },
  ),
  withRouter,
)(AttentionModalWhole)
