import React, { Fragment } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { format as formatPhone } from 'helpers/phone.js'
import { urlTo } from 'helpers/router'
import { bulkValidate } from 'helpers/validation.js'

import { PromocodeStatuses } from 'app/effector/promocodes'

import { showSuccessToast } from 'app/redux/actions/ui'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import DisableIfNeeded from 'components/_old/DisableIfNeeded/DisableIfNeeded.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Input from 'components/_old/Input/Input.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import CleanInputOnFocus from 'components/atoms/CleanInputOnFocus/CleanInputOnFocus.jsx'
import { CopyToClipboard } from 'components/atoms/CopyToClipboard'
import { GatewayDest } from 'components/atoms/Gateway'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { ConfirmIdentityModal } from 'app/pages/Dashboard/UserProfile/components/ConfirmIdentityModal'
import { PhoneConfirmationModal } from 'app/pages/Dashboard/UserProfile/components/PhoneConfirmationModal'
import { PhoneNumberSetting } from 'app/pages/Dashboard/UserProfile/components/PhoneNumberSetting'
import ChangeAddress from 'app/pages/Forms/ChangeAddress.jsx'

import ChangeEmail from './components/ChangeEmail.jsx'
import { FinancesForm } from './components/FinancesForm'
import { PromocodeModal } from './components/PromocodeModal'
import { usePersonalInfo } from './hooks/usePersonalInfo'

import './PersonalInfo.css'

const PersonalInfo = ({ routes }): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const routeModule = routes[routes.length - 1].module

  const {
    client,
    isBusiness,
    owner,
    dicts,
    fullAddress,
    ownerEmail,
    promocodeStatus,
    clientValidation,
    financesValidation,
    clientNewOrNotCompleted,
    isPersonalFieldsDisabled,
    isFinancesFieldsDisabled,
    isEmailFieldDisabled,
    isPromocodeLoading,
    isPromocodeFieldDisabled,
    handleSubmit,
    handleBack,
    handleAddressModalClose,
    handleEmailChange,
    handleFirstNameChange,
    handleMiddleNameChange,
    handleLastNameChange,
    handlePhoneChange,
    showSupportModal,
  } = usePersonalInfo()
  const { contact_lock_rules: contactLockRules } = dicts
  const isSubmitButtonVisible =
    !isPersonalFieldsDisabled ||
    (!isBusiness && !isFinancesFieldsDisabled) ||
    (clientNewOrNotCompleted && !owner.email_verified)

  const headerNode = <DesktopHeader />

  const navbarNode = (
    <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
      <Typo>&nbsp</Typo>
    </NavigationBar>
  )

  const emailEditableField = (
    <Validate rules={clientValidation.email.rules}>
      <LabelInlineStyle
        labelText="Email"
        size="smaller"
        valueIfDisabled={ownerEmail}
        errorMessages={clientValidation.email.errors}
        multiline={!desktop}
        dataTestId="profileFormEmailLabel"
      >
        <Input type="email" onChange={handleEmailChange} tabIndex={5} data-test-id="profileFormEmailInput">
          {ownerEmail}
        </Input>
      </LabelInlineStyle>
    </Validate>
  )

  const emailNonEditableField = (
    <LabelInlineStyle labelText="Email" size="smaller" multiline={!desktop} dataTestId="profileFormEmailLabel">
      <Typography inline={desktop} data-test-id="profileFormEmailLabelValue">
        {ownerEmail}
      </Typography>
      <Link
        to={urlTo('dashboard.user-profile.personal-info.change-email')}
        scrollToTop={false}
        data-test-id="openChangeEmailModal"
      >
        <Typography size={14} color="inherit">
          Edit email
        </Typography>
      </Link>
    </LabelInlineStyle>
  )

  const submitButton = (
    <Button
      className="t-settings-save-btn"
      mods={{ size: 'big block' }}
      onClick={handleSubmit}
      disabled={!bulkValidate(clientValidation) || !bulkValidate(financesValidation)}
      data-test-id="profileFormSubmit"
    >
      Save changes
    </Button>
  )

  const submitButtonField = <LabelInlineStyle multiline={!desktop}>{submitButton}</LabelInlineStyle>

  const addressNode = (
    <Paper top={32} bottom={32}>
      <LabelInlineStyle labelText="Address" size="smaller" multiline={!desktop} dataTestId="profileFormAddressLabel">
        <Typography data-test-id="profileFormAddressLabelValue">{fullAddress}</Typography>
        <Paper top={4}>
          <Link
            to={urlTo('dashboard.user-profile.personal-info.change-address')}
            scrollToTop={false}
            data-test-id="openChangeAddressModal"
          >
            <Typography size={14} color="inherit">
              Edit address
            </Typography>
          </Link>
        </Paper>
      </LabelInlineStyle>
    </Paper>
  )

  const personalInfoNode = (
    <Fragment>
      <DisableIfNeeded field="first_name" rules={contactLockRules} model={owner}>
        <Validate rules={clientValidation.first_name?.rules}>
          <LabelInlineStyle
            labelText="First name"
            size="smaller"
            valueIfDisabled={owner.first_name}
            errorMessages={clientValidation.first_name?.errors}
            multiline={!desktop}
            dataTestId="profileFormFirstNameLabel"
          >
            <Input
              name="fname"
              autoComplete="given-name"
              type="text"
              onChange={handleFirstNameChange}
              tabIndex={1}
              dataTestId="profileFormFirstNameInput"
            >
              {owner.first_name}
            </Input>
          </LabelInlineStyle>
        </Validate>
      </DisableIfNeeded>
      <DisableIfNeeded field="middle_name" rules={contactLockRules} model={owner}>
        {(disabled) => {
          const visible = !disabled || (disabled && owner.middle_name)

          if (!visible) {
            return null
          }

          return (
            <Validate rules={clientValidation.middle_name?.rules} disabled={disabled}>
              <LabelInlineStyle
                labelText="Middle name"
                size="smaller"
                valueIfDisabled={owner.middle_name}
                errorMessages={clientValidation.middle_name?.errors}
                multiline={!desktop}
                disabled={disabled}
                optional
                dataTestId="profileFormMiddleNameLabel"
              >
                <Input
                  name="mname"
                  autoComplete="additional-name"
                  type="text"
                  onChange={handleMiddleNameChange}
                  tabIndex={1}
                  data-test-id="profileFormMiddleNameInput"
                >
                  {owner.middle_name}
                </Input>
              </LabelInlineStyle>
            </Validate>
          )
        }}
      </DisableIfNeeded>
      <DisableIfNeeded field="last_name" rules={contactLockRules} model={owner}>
        <Validate rules={clientValidation.last_name?.rules}>
          <LabelInlineStyle
            labelText="Last name"
            size="smaller"
            valueIfDisabled={owner.last_name}
            errorMessages={clientValidation.last_name?.errors}
            multiline={!desktop}
            dataTestId="profileFormLastNameLabel"
          >
            <Input
              name="lname"
              autoComplete="family-name"
              type="text"
              onChange={handleLastNameChange}
              tabIndex={2}
              data-test-id="profileFormLastNameInput"
            >
              {owner.last_name}
            </Input>
          </LabelInlineStyle>
        </Validate>
      </DisableIfNeeded>
    </Fragment>
  )

  const phoneNode =
    (client.tfa === 'ENABLED' || owner.phone_verified) && owner.phone ? (
      <LabelInlineStyle labelText="Phone" size="smaller" multiline={!desktop} dataTestId="profileFormPhoneLabel">
        <PhoneNumberSetting
          country={client.country_code_by_ip}
          phone={owner.phone}
          isConfirmed={owner.phone_verified}
          parentModule="dashboard.user-profile.personal-info"
        />
      </LabelInlineStyle>
    ) : (
      <DisableIfNeeded field="phone" rules={contactLockRules} model={owner}>
        <Validate rules={clientValidation.phone?.rules}>
          <LabelInlineStyle
            labelText="Phone"
            size="smaller"
            valueIfDisabled={formatPhone(owner.phone)}
            errorMessages={clientValidation.phone?.errors}
            multiline={!desktop}
            dataTestId="profileFormPhoneLabel"
          >
            <CleanInputOnFocus
              onSetValue={(value) => {
                handlePhoneChange(null, value || '')
              }}
              cleanIfMatch={/\*/}
            >
              <Input
                name="phone"
                type="tel"
                size="smaller"
                country={client.country_code_by_ip}
                onChange={handlePhoneChange}
                tabIndex={4}
                data-test-id="profileFormPhoneInput"
              >
                {owner.phone}
              </Input>
            </CleanInputOnFocus>
          </LabelInlineStyle>
        </Validate>
      </DisableIfNeeded>
    )

  const promocodeValue = useMemo(() => {
    if (promocodeStatus === PromocodeStatuses.APPLIED) {
      return <Typo>Applied</Typo>
    }

    if (isPromocodeLoading) {
      return (
        <Typo>
          <Skeleton shown inline>
            Loading...
          </Skeleton>
        </Typo>
      )
    }

    return (
      <Link
        to={urlTo('dashboard.user-profile.personal-info.enter-promocode')}
        scrollToTop={false}
        data-test-id="profilePromocodeOpenModal"
      >
        <Typography size={14} color="inherit">
          Enter a promo code
        </Typography>
      </Link>
    )
  }, [promocodeStatus, isPromocodeLoading])

  const promocodeNode = isPromocodeFieldDisabled ? null : (
    <LabelInlineStyle labelText="Promo code" size="smaller" multiline={!desktop} dataTestId="profilePromocodeLabel">
      <Typography inline={desktop} data-test-id="profilePromocodeLabelValue">
        {promocodeValue}
      </Typography>
    </LabelInlineStyle>
  )

  const content = (
    <Paper top={desktop ? 40 : 0} bottom={80} className="PersonalInfo-Content">
      <Typography
        size={desktop ? 32 : 20}
        lineHeight="small"
        weight="semibold"
        align={desktop ? 'center' : 'left'}
        data-test-id="pageTitle"
      >
        Personal info
      </Typography>
      <Paper top={desktop ? 48 : 40}>
        <LabelInlineStyle
          labelText="Client reference"
          size="smaller"
          multiline={!desktop}
          dataTestId="clientReferenceLabel"
        >
          <ItemWithIcon
            inline
            space={12}
            iconPosition="right"
            iconVerticalAlign="center"
            icon={
              <CopyToClipboard
                value={client.reference}
                absolute
                toast="Client reference copied"
                showToast={showSuccessToast}
              >
                Copy client reference to the clipboard
              </CopyToClipboard>
            }
            content={<Typography data-test-id="clientReferenceValue">{client.reference}</Typography>}
            data-test-id="copyReferenceButton"
          />
        </LabelInlineStyle>
      </Paper>
      {clientNewOrNotCompleted && (
        <Paper top={24} bottom={24}>
          {owner.email_verified ? emailNonEditableField : emailEditableField}
        </Paper>
      )}
      {!clientNewOrNotCompleted && (
        <Fragment>
          {addressNode}
          {personalInfoNode}
          {phoneNode}
          {!isPersonalFieldsDisabled && !isEmailFieldDisabled && emailEditableField}
          {isPersonalFieldsDisabled && isEmailFieldDisabled && (
            <LabelInlineStyle
              labelText="Email"
              size="smaller"
              valueIfDisabled={ownerEmail}
              disabled
              multiline={!desktop}
              dataTestId="profileFormEmailLabel"
            />
          )}
          {isPersonalFieldsDisabled && !isEmailFieldDisabled && emailNonEditableField}
          {!isBusiness && (
            <Paper top={32} bottom={32}>
              <FinancesForm validation={financesValidation} />
            </Paper>
          )}
        </Fragment>
      )}
      {isSubmitButtonVisible && submitButtonField}
      {promocodeNode}
      {isPersonalFieldsDisabled && (
        <LabelInlineStyle
          size="smaller"
          multiline={!desktop}
          disabled
          valueIfDisabled={
            <Link onClick={showSupportModal} scrollToTop={false} data-test-id="profileFormEmailUs">
              <Typography lineHeight="small" color="inherit">
                <Typo>Email us to change your information</Typo>
              </Typography>
            </Link>
          }
        />
      )}
    </Paper>
  )

  return (
    <Fragment>
      {desktop ? (
        <DesktopLayout
          header={headerNode}
          footer={<DesktopFooter />}
          content={
            <Inner twoColumns>
              {navbarNode}
              <AllCenter>
                <Width size={36}>{content}</Width>
              </AllCenter>
            </Inner>
          }
        />
      ) : (
        <MobileLayout header={navbarNode} content={content} />
      )}

      <ConfirmIdentityModal
        open={routeModule === 'confirm-your-identity'}
        parentModule="dashboard.user-profile.personal-info"
      />
      <PhoneConfirmationModal
        open={routeModule === 'confirm-phone-number'}
        parentModule="dashboard.user-profile.personal-info"
      />
      <ChangeAddress inputMods={{ size: 'bigger' }} onClose={handleAddressModalClose} owner={owner} />
      <ChangeEmail />
      <PromocodeModal promocodeStatus={promocodeStatus} isLoading={isPromocodeLoading} />
      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
      <GatewayDest name="change-address" />
    </Fragment>
  )
}

export { PersonalInfo }
