import * as React from 'react'

import { useMemo, useResizeDetector } from 'hooks'

import './LineChart.css'

type LineChartProps = {
  values: Array<{ value: number; color: string }>
}

const MINIMAL_SECTOR_WIDTH = 4 // in px
const MAXIMAL_GALLANT_CAPACITY = 90 // in %

const LineChart = ({ values = [] }: LineChartProps): React.ReactElement => {
  const { ref, width } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'throttle',
    refreshRate: 250,
  })

  const minimalSectorValue = useMemo(() => {
    if (width) {
      return MINIMAL_SECTOR_WIDTH / (width / 100)
    }

    return 0
  }, [width])

  const gallantValues = useMemo(() => {
    const gallantValues = values.map((value) => {
      if (value.value <= 0) {
        return {
          ...value,
          value: 0,
        }
      }

      if (value.value < minimalSectorValue) {
        return {
          ...value,
          value: minimalSectorValue,
        }
      }

      return value
    })

    const gallantSum = gallantValues.reduce((sum, value) => sum + value.value, 0)

    if (gallantSum > MAXIMAL_GALLANT_CAPACITY) {
      return values
    }

    return gallantValues
  }, [values, minimalSectorValue])

  return (
    <div className="LineChart" ref={ref}>
      {gallantValues.map(({ value, color }, index) =>
        value > 0 ? (
          <div
            key={index}
            className="LineChart_Item"
            style={{ width: `${value}%`, background: color }}
            data-test-id="securityColoredLine"
          />
        ) : null,
      )}
    </div>
  )
}

export { LineChart }
