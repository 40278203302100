import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import './Legend.css'

const positions = {
  TOP: 'top',
  BOTTOM: 'bottom',
}

const Legend = ({ children, className, flex, position = positions.TOP }) => {
  const mediaQueries = useMediaQueries()
  const classes = classNames(
    className,
    'Legend',
    { Legend_flex: flex },
    `Legend_${position}`,
    getMediaQuieryClasses('Legend', mediaQueries),
  )

  return <div className={classes}>{children}</div>
}

Legend.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  flex: PropTypes.bool,
  position: PropTypes.oneOf(Object.values(positions)),
}

export { Legend }
