import { useUnit } from 'effector-react'

import { useEffect, useMemo } from 'hooks'

import { features } from 'helpers/features'

import { $promocodesStore, fetchPromocodeStatusFx, PromocodeStatuses } from 'app/effector/promocodes'

type UsePersonalInfoReturnProps = {
  promocodeStatus: PromocodeStatuses
  isPromocodeLoading: boolean
  isPromocodeFieldDisabled: boolean
}

function usePromocode(): UsePersonalInfoReturnProps {
  const { promocodeStatus, isLoading: isPromocodeLoading } = useUnit($promocodesStore)

  const isPromocodeFieldDisabled = useMemo(() => {
    return [PromocodeStatuses.UNKNOWN, PromocodeStatuses.NOT_AVAILABLE].includes(promocodeStatus)
  }, [promocodeStatus])

  useEffect(() => {
    if (features?.get('promocode') && promocodeStatus === PromocodeStatuses.UNKNOWN) {
      fetchPromocodeStatusFx()
    }
  }, [promocodeStatus])

  return {
    promocodeStatus,
    isPromocodeLoading,
    isPromocodeFieldDisabled,
  }
}

export { type UsePersonalInfoReturnProps, usePromocode }
