import React from 'react'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './StoryContent.css'

type StoryStepProps = {
  header: string
  text: string
}

const StoryContent = ({ header, text }: StoryStepProps): React.ReactElement => {
  return (
    <Paper left={16} right={16} className="StoryContent">
      <Paper top={64} data-test-id="story-title">
        <Typography
          size={24}
          weight="semibold"
          align="left"
          lineHeight="medium"
          data-test-id="story-header"
          color="white"
        >
          <Typo>{header}</Typo>
        </Typography>
      </Paper>

      <Paper top={20} data-test-id="story-text">
        <Typography align="left" color="white">
          <Typo multiline>{text}</Typo>
        </Typography>
      </Paper>
    </Paper>
  )
}

export { StoryContent }
