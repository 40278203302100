import throttle from 'lodash/throttle'

const refreshSession = throttle(() => {
  window.refreshSessionLifetime?.()
}, 5000)

const handleSessionTimeout = ({ response }): void => {
  if (response) {
    refreshSession()
  }
}

export { handleSessionTimeout }
