import React from 'react'

import { useMediaQueries } from 'hooks'

import { urlTo } from 'helpers/router.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Logo } from 'components/_old/Logo/Logo.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DesktopLayout, MobileLayout, MobileLayoutFullViewport } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'

const SecureDocumentUploadSuccess = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const headerNode = <Header hideDrawer logoLink={urlTo('dashboard')} hard withShadow />

  const mobileHeader = (
    <NavigationBar>
      <Link to={urlTo('dashboard')} hard>
        <Logo style={{ height: 24 }} />
      </Link>
    </NavigationBar>
  )

  const titleIcon = <Icon type="good-64" />

  const titleNode = (
    <Typography align="center" size={32} weight="semibold" lineHeight="small">
      <Typo>Documents sent successfully</Typo>
    </Typography>
  )

  const subtitleNode = (
    <Typography align="center" weight="semibold">
      <Typo>Your documents are under review. We will notify you of the status of your application shortly.</Typo>
    </Typography>
  )

  const content = (
    <AllCenter>
      <Paper bottom={16} inline>
        {titleIcon}
      </Paper>
      <Paper bottom={16}>{titleNode}</Paper>
      {subtitleNode}
    </AllCenter>
  )

  const desktopContent = (
    <AllCenter>
      <Inner>
        <Width size={27} center>
          {content}
        </Width>
      </Inner>
    </AllCenter>
  )

  const mobileContent = (
    <MobileLayoutFullViewport>
      {mobileHeader}
      <Inner flex>{content}</Inner>
    </MobileLayoutFullViewport>
  )

  if (desktop) return <DesktopLayout header={headerNode} content={desktopContent} footer={<DesktopFooter />} noGaps />

  return <MobileLayout content={mobileContent} contentPaperSizes={{ top: 0, left: 0, right: 0, bottom: 0 }} />
}

export { SecureDocumentUploadSuccess }
