import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Typography } from 'components/atoms/Typography'
import { Paper } from 'components/atoms/Paper'
import { Progress } from 'components/_old/Progress/'
import { Typo } from 'components/_old/Typo/Typo'
import HeadlineHelp from 'app/containers/PortfolioTunnel/Tunnel/HeadlineHelp/HeadlineHelp.jsx'

import { SingleChoiceCard } from '../components/SingleChoiceCard.jsx'

const MobileSingle = ({ progress, question, selectedAnswer, handleBack, handleSubmit, handleCancel }) => {
  const { title, description } = question

  const heading = useMemo(
    () => (
      <Paper top={8} bottom={8}>
        <Typography size={20} weight="semibold" align="center">
          <Typo>
            {title}
            {description?.text && (
              <Typography className="Question_Headline_help" tag="span" size={14}>
                <HeadlineHelp
                  iconType="question"
                  background="status-warning"
                  title={description?.title}
                  body={description?.text}
                />
              </Typography>
            )}
          </Typo>
        </Typography>
      </Paper>
    ),
    [title, description],
  )

  const answers = useMemo(
    () =>
      question.answers.map((answer, i) => (
        <Paper top={24} key={answer.id}>
          <SingleChoiceCard
            tabIndex={i + 1}
            answer={answer}
            selected={selectedAnswer === answer.id}
            onClick={handleSubmit}
          />
        </Paper>
      )),
    [question.answers, selectedAnswer, handleSubmit],
  )

  const content = (
    <Paper bottom={24}>
      {heading}
      {answers}
    </Paper>
  )

  return (
    <MobileLayout
      header={
        <Fragment>
          <NavigationBar
            leftPartText="Back"
            onLeftPartClick={handleBack}
            rightPartText="Cancel"
            onRightPartClick={handleCancel}
          >
            Get started
          </NavigationBar>
          <Progress fill={progress} gapWidth="1em" />
        </Fragment>
      }
      content={content}
    />
  )
}

MobileSingle.propTypes = {
  progress: PropTypes.number,
  question: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    }),
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      }),
    ),
  }),
  selectedAnswer: PropTypes.number,
  handleBack: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
}

export { MobileSingle }
