import React from 'react'

import { QRCodeSVG } from 'qrcode.react'

import { useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import iphonePush from '../iphonePush.png'

import storesLink from 'constants/externalLinks.js'

type TwoFactorAuthSetupDesktopProps = {
  handleBack: () => void
  handleContinue: () => void
}

const Desktop = ({ handleBack, handleContinue }: TwoFactorAuthSetupDesktopProps): React.ReactElement => {
  const backLink = useMemo(
    () => (
      <Link onClick={handleBack} mods={{ color: 'content-on-background-default' }}>
        <ItemWithIcon
          space={16}
          icon={<Icon type="back-new" size={24} color="inherit" />}
          content={<Typography color="inherit">Back</Typography>}
          iconVerticalAlign="center"
        />
      </Link>
    ),
    [handleBack],
  )

  const stepTitleNode = useMemo(
    () => (
      <Typography size={20} lineHeight="small" weight="semibold" align="center">
        <Typo>Step 1 of 3</Typo>
      </Typography>
    ),
    [],
  )

  const titleNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography size={32} lineHeight="small" weight="semibold" align="center">
          <Typo>Ensure app is installed and notifications turned on</Typo>
        </Typography>
      </Paper>
    ),
    [],
  )

  const firstStepNode = useMemo(
    () => (
      <Paper top={32}>
        <ItemWithIcon
          space={16}
          icon={<Icon type="one-32" size={32} />}
          content={
            <Typography size={14}>
              Get the InvestEngine app using QR code below or download from{' '}
              <Link to={storesLink} hard blank>
                App Store
              </Link>{' '}
              or{' '}
              <Link to={storesLink} hard blank>
                Google Play
              </Link>
            </Typography>
          }
        />
        <AllCenter>
          <Paper top={32}>
            <Card>
              <Paper top={12} bottom={12} right={12} left={12}>
                <AllCenter>
                  <QRCodeSVG size={90} value={`${window.location.origin}/download-app/`} />
                </AllCenter>
              </Paper>
            </Card>
          </Paper>
        </AllCenter>
      </Paper>
    ),
    [],
  )

  const secondStepNode = useMemo(
    () => (
      <Paper top={32}>
        <ItemWithIcon
          space={16}
          icon={<Icon type="two-32" size={32} />}
          content={
            <Typography size={14}>
              Log in to InvestEngine app. If you are on iPhone, turn on push notifications.
            </Typography>
          }
        />
        <Paper top={24}>
          <AllCenter>
            <img src={iphonePush} width={240} height={126} />
            <Typography tag="span" size={12} color="minor" align="center">
              <Typo>for iPhone</Typo>
            </Typography>
          </AllCenter>
        </Paper>
      </Paper>
    ),
    [],
  )

  const button = useMemo(
    () => (
      <Paper top={56}>
        <Button mods={{ size: 'big block' }} onClick={handleContinue}>
          Continue
        </Button>
      </Paper>
    ),
    [handleContinue],
  )

  const content = (
    <Inner twoColumns>
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column size={1}>{backLink}</Column>
        <Column size={2}>
          {stepTitleNode}
          <Width size={36} center>
            {titleNode}
            <Width size={24} center>
              {firstStepNode}
              {secondStepNode}
              {button}
            </Width>
          </Width>
        </Column>
        <Column size={1} />
      </ColumnarLayout>
    </Inner>
  )

  return <DesktopLayout header={<DesktopHeader />} content={content} footer={<DesktopFooter />} />
}

export { Desktop }
