import type { IsaProviderDTO } from 'app/effector/isa-transfer/api'
import { ListOf } from 'app/effector/model'

import { IsaProvider } from './IsaProvider'

class IsaProviderList extends ListOf(IsaProvider) {
  constructor(...items: IsaProviderDTO[]) {
    const newItems: Array<Partial<IsaProviderDTO>> = [...items, { id: undefined, name: 'Other' }]

    super(...newItems)
  }

  get(id: number): IsaProvider | undefined {
    return this.find((provider) => provider.id === id)
  }
}

export { IsaProviderList }
