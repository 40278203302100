import * as React from 'react'

import { useMediaQueries } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Typography } from 'components/atoms/Typography'
// import { Nobr } from 'components/_old/Nobr'

const NoTradingSecuritiesWarning = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Typography size={desktop ? 16 : 14} align="center">
      <Typo>
        You'll need to set their target weight to 0% to proceed if funding your portfolio.
        <br />
        <br />
        If you have any questions please contact us by writing to{' '}
        <Link to="mailto:support@investengine.com" hard>
          support@investengine.com
        </Link>
        {/* {' '}
        or calling{' '}
        <Link to="tel:0800 808 5771" mods={{ color: 'content-on-background-default' }} hard>
          <Nobr>0800 808 5771</Nobr>
        </Link>{' '}
        or{' '}
        <Link to="tel:+44 (0) 208 051 9 501" mods={{ color: 'content-on-background-default' }} hard>
          <Nobr>+44 (0) 208 051 9501</Nobr>
        </Link> */}
        .
      </Typo>
    </Typography>
  )
}

export { NoTradingSecuritiesWarning }
