import React from 'react'
import classNames from 'classnames/dedupe'
import isFunction from 'lodash/isFunction'

import compose from 'helpers/compose.js'
import axios from 'helpers/ajax'
import { palette } from 'helpers/palette/'
import rawMediaQueries from 'helpers/mediaQueries.js'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import Text from 'components/_old/Text/Text.jsx'
// TODO: Rewrite to `import {useDropzone} from 'react-dropzone'`
import Dropzone from 'react-dropzone'
import Icon from 'components/_old/Icon/Icon.jsx'
import Button from 'components/_old/Button/Button.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import './FileUpload.css'

class FileUpload extends React.Component {
  state = {
    files: null,
    status: 'default',
    loading: false,
  }

  handleFiles = (files) => {
    const { fieldName, multiple } = this.props
    this.setState({ files: files })
    const file = multiple ? files : files[0]
    const data = new FormData()
    data.append(fieldName, file)
    this.handleUpload(data)
  }

  handleUpload = (data) => {
    const { to, method = 'POST', finalStatus = 'done', afterUpload } = this.props
    this.setState({ loading: true })

    axios(to, { method: method, data: data })
      .then((response) => {
        this.setState({ loading: false, status: finalStatus })
        if (isFunction(afterUpload)) {
          afterUpload(response.data)
        }
      })
      .catch(() => {
        this.setState({ loading: false, status: 'failed' })
      })
  }

  render() {
    const {
      heading,
      text,
      image,
      flex,
      messages = [],
      mediaQueries,
      status: propsStatus,
      finalStatus: _finalStatus,
      afterUpload: _afterUpload,
      to: _to,
      method: _method,
      tabIndex,
      'data-test-id': dataTestId,
      ...rest
    } = this.props

    const { loading } = this.state
    const { desktop } = mediaQueries
    const status = loading ? 'loading' : propsStatus || this.state.status
    const message = messages[status || 'default']

    const getIcon = (status = 'default') => {
      const icons = {
        done: <Icon type="checkmark" color={palette['background-default']} fluid data-test-id="successIcon" />,
        failed: <Icon type="cross" color={palette['background-default']} fluid />,
        loading: <Icon type="update" color={palette['content-on-background-minor']} fluid animation="spin" />,
        default: null,
      }

      return icons[status]
    }

    const getStatus = (mod, icon = getIcon('default')) => {
      return (
        <div
          className={classNames('FileUpload-Status', {
            [`FileUpload-Status_${mod}`]: mod,
          })}
        >
          {icon}
        </div>
      )
    }

    const classes = classNames(
      'Button-Proxy',
      'FileUpload',
      {
        FileUpload_flex: flex,
        FileUpload_disabled: status === 'loading',
      },
      getMediaQuieryClasses('FileUpload', mediaQueries),
    )

    const imageHolder = (
      <div className="FileUpload-ImageHolder">
        <AllCenter className="FileUpload-Image">
          {image}
          {getStatus(status, getIcon(status))}
        </AllCenter>
      </div>
    )

    const button = (
      <div className="FileUpload-ButtonHolder">
        <Button
          className="FileUpload-Button"
          mods={{
            theme: 'simple-reverse-blue',
            text: 'smaller lighter',
          }}
          disabled={status === 'loading'}
          tabIndex={tabIndex}
        >
          Upload
        </Button>
      </div>
    )

    const label = desktop ? (
      <Text className="FileUpload-Label">
        {heading}
        {text}
      </Text>
    ) : (
      <div>
        {heading}
        <div className="FileUpload-LabelHolder">
          <Text className="FileUpload-Label">
            {text}
            {button}
          </Text>
          {imageHolder}
        </div>
      </div>
    )

    return (
      <div className={classes}>
        <div className="FileUpload-DropzoneHolder">
          <Dropzone onDrop={this.handleFiles} multiple={false} {...rest}>
            {({ getRootProps, getInputProps }) => (
              <div className="FileUpload-Dropzone" {...getRootProps()} data-test-id={dataTestId}>
                <input {...getInputProps()} {...{ capture: true }} />
                {label}
                {desktop ? imageHolder : null}
                <div className="FileUpload-Message">{loading && !desktop ? null : message}</div>
                {desktop ? button : null}
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    )
  }
}

export default compose(withMediaQueries(rawMediaQueries))(FileUpload)
