import { PortfolioSecurityPointOfInterestList } from './PortfolioSecurityPointOfInterestList'

import type { PortfolioSecurityPointOfInterestDTO } from '../../api'

class PortfolioSecurityPointOfInterestCollection {
  constructor(collection?: PortfolioSecurityPointOfInterestCollection) {
    if (collection) {
      Object.entries(collection).forEach(([key, value]) => {
        if (value instanceof PortfolioSecurityPointOfInterestList) {
          this[key] = value
        }
      })
    }
  }

  set(
    portfolioId: number,
    securityId: number,
    pointsOfInterest: PortfolioSecurityPointOfInterestDTO[],
  ): PortfolioSecurityPointOfInterestCollection {
    return new PortfolioSecurityPointOfInterestCollection({
      ...this,
      [`${portfolioId}-${securityId}`]: new PortfolioSecurityPointOfInterestList(...pointsOfInterest),
    })
  }

  get(portfolioId: number, securityId: number): PortfolioSecurityPointOfInterestList {
    return this[`${portfolioId}-${securityId}`]
  }
}

export { PortfolioSecurityPointOfInterestCollection }
