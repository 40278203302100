import React, { Fragment } from 'react'

import { useMemo } from 'hooks'

import { urlTo } from 'helpers/router.js'
import { symbols } from 'helpers/typograph'

import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Progress } from 'components/_old/Progress/'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'
import HeadlineHelp from 'app/containers/PortfolioTunnel/Tunnel/HeadlineHelp/HeadlineHelp.jsx'
import FeeInfo from 'app/containers/Questionnaire/FeeInfo/FeeInfo.jsx'

import TextInput from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/components/TextInput.jsx'

import { useTextQuestion } from '../hooks'

const DesktopText = ({
  progress,
  question,
  selectedAnswer,
  backLinkText,
  handleBack,
  handleSubmit,
}: MultipleViewProps): React.ReactElement => {
  const { title, description } = question
  const { value, validation, isValid, handleChange, handleSubmitClick } = useTextQuestion({
    initialValue: selectedAnswer,
    handleSubmit,
  })

  const header = useMemo(
    () => (
      <Header
        right={
          <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}>
            <Link to={urlTo('dashboard')}>My Dashboard</Link>
          </Button>
        }
        after={
          <Fragment>
            <Progress fill={progress} gapWidth="1em" />
            <FeeInfo />
          </Fragment>
        }
        hideDrawer
      />
    ),
    [progress],
  )

  const heading = useMemo(
    () => (
      <Paper top={24} bottom={48}>
        <NavigationBar leftPartText={backLinkText} onLeftPartClick={handleBack}>
          <span data-test-id="headline">
            <Typo>{title}</Typo>
            {description?.text && (
              <Fragment>
                {symbols.nbsp}
                <Typography className="Question_Headline_help" tag="span" size={16}>
                  <HeadlineHelp
                    iconType="question"
                    background="status-warning"
                    title={description?.title}
                    body={description?.text}
                  />
                </Typography>
              </Fragment>
            )}
          </span>
        </NavigationBar>
      </Paper>
    ),
    [title, description, backLinkText, handleBack],
  )

  const button = useMemo(
    () => (
      <Width size={14} center>
        <Button
          type="submit"
          mods={{ size: 'big block', theme: null }}
          onClick={handleSubmitClick}
          disabled={!isValid}
          data-test-id="questionnaireSubmit"
          tabIndex={3}
        >
          Continue
        </Button>
      </Width>
    ),
    [isValid, handleSubmitClick],
  )

  const input = useMemo(
    () => (
      <SubmitOnEnter>
        <Paper top={description ? 32 : 80}>
          <Headline
            level={1}
            mods={{
              size: 'gargantuan',
              text: 'normal center light',
              'no-margin': 'top',
              'line-height': 'bigger',
              overflow: 'visible',
            }}
          >
            <TextInput value={value} validationRule={validation.value} handleChange={handleChange} />
          </Headline>
          <GatewayDest className="_newTextQuestion-Error" name="text-question-error" />
          <Paper top={48}>
            <Width size={14} center>
              {button}
            </Width>
          </Paper>
        </Paper>
      </SubmitOnEnter>
    ),
    [button, description, handleChange, validation, value],
  )

  const content = (
    <Inner>
      {heading}
      {input}
    </Inner>
  )

  return <DesktopLayout header={header} content={content} footer={<DesktopFooter />} />
}

export { DesktopText }
