import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe.js'

import StickyTop from 'components/atoms/StickyTop'

import './DesktopLayout.css'

const DesktopLayout = ({ header, content, footer, noGaps = false, fullSize = false }, ref) => {
  const classes = classNames('DesktopLayout', { DesktopLayout_fullSize: fullSize })
  const contentClasses = classNames('DesktopLayout-Content', { 'DesktopLayout-Content_withGap': !noGaps })
  const footerClasses = classNames('DesktopLayout-Footer', { 'DesktopLayout-Footer_withGap': !noGaps })

  return (
    <div className={classes}>
      <div className="DesktopLayout-Element DesktopLayout-Element_scrollable" ref={ref} data-test-id="desktopLayout">
        {header && <StickyTop>{header}</StickyTop>}
        {content && <div className={contentClasses}>{content}</div>}
        {footer && <div className={footerClasses}>{footer}</div>}
      </div>
    </div>
  )
}

const DesktopLayoutWithForwardedRef = forwardRef(DesktopLayout)

DesktopLayoutWithForwardedRef.propTypes = {
  header: PropTypes.element,
  content: PropTypes.element,
  footer: PropTypes.element,
  noGaps: PropTypes.bool,
  fullSize: PropTypes.bool,
}

export { DesktopLayoutWithForwardedRef as DesktopLayout }
