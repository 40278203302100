import React, { Fragment } from 'react'

import groupBy from 'lodash/groupBy'

import { useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import { showSuccessToast } from 'app/redux/actions/ui'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { CopyToClipboard } from 'components/atoms/CopyToClipboard'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { List, ListItem } from 'components/atoms/List'
import { Paper } from 'components/atoms/Paper'
import { Relative } from 'components/atoms/Relative'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import { type Action, ActionStatus } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'

import { OptionsLayout } from './OptionsLayout'
import { type OptionsChildProps } from './OptionsModal'
import { OptionsNavigationBar } from './OptionsNavigationBar'

function Options({ portfolio, portfolioActions, handleClose }: OptionsChildProps): React.ReactElement {
  const { desktop } = useMediaQueries()
  const back = useMemo(() => window.location.pathname + window.location.search, [])

  const actionsGroup = useMemo(() => {
    const actionGroupsTuples: Array<[string, Action[]]> = Object.entries(groupBy(portfolioActions, 'group'))

    return actionGroupsTuples.map(([groupName, actions]) => {
      return (
        <div key={groupName}>
          <Paper bottom={desktop ? 16 : 4}>
            <Typography size={desktop ? 14 : 12} lineHeight="small" color="minor" uppercase={!desktop}>
              <Typo>{groupName}</Typo>
            </Typography>
          </Paper>
          <SideSpacesCompensator>
            <List>
              {actions.map((action) => {
                const leftSide = action.icon ? (
                  <ItemWithIcon
                    space={8}
                    icon={<Icon type={action.icon} size={24} color={palette['secondary-default']} />}
                    content={action.title}
                    iconVerticalAlign="center"
                    contentVerticalAlign="center"
                  />
                ) : (
                  action.title
                )
                const rightSide = action.status ? (
                  <ItemWithIcon
                    space={4}
                    iconPosition="right"
                    icon={<Icon type="arrow-right-small-24" size={24} color={palette['content-on-background-minor']} />}
                    content={
                      <Typography
                        size={16}
                        lineHeight="small"
                        color={action.status === ActionStatus.ON ? 'secondary_default' : 'minor'}
                        data-test-id="optionItemStatus"
                      >
                        <Typo>{action.status}</Typo>
                      </Typography>
                    }
                    iconVerticalAlign="center"
                    contentVerticalAlign="center"
                  />
                ) : null

                return (
                  <ListItem
                    key={action.title}
                    leftSide={leftSide}
                    rightSide={rightSide}
                    disabled={!action.enabled}
                    onClick={() => {
                      action.onClick(back)
                    }}
                    data-test-id={action['data-test-id'] ?? 'portfolioOptionsAction'}
                  />
                )
              })}
            </List>
          </SideSpacesCompensator>
        </div>
      )
    })
  }, [portfolioActions, desktop, back])

  const copyPortfolioReference = useMemo(() => {
    const node = (
      <Card level={-1}>
        <Paper top={16} right={16} bottom={16} left={16} data-test-id="portfolioOptionsPortfolioReference">
          <ItemWithIcon
            space={12}
            iconPosition="right"
            iconVerticalAlign="center"
            icon={
              <CopyToClipboard
                value={portfolio.reference}
                absolute={!desktop}
                toast="Portfolio reference copied"
                showToast={showSuccessToast}
              >
                Copy portfolio reference to the clipboard
              </CopyToClipboard>
            }
            content={
              <Typography size={16} align="left" color="default" data-test-id="portfolioOptionsPortfolioReferenceText">
                <Typo>Portfolio reference: {portfolio.reference}</Typo>
              </Typography>
            }
          />
        </Paper>
      </Card>
    )

    if (!desktop) {
      return node
    }

    return <Relative>{node}</Relative>
  }, [portfolio, desktop])

  const content = useMemo(() => {
    if (desktop) {
      return (
        <Width size={25} center>
          <Stack vertical={32}>
            {actionsGroup}
            {copyPortfolioReference}
          </Stack>
        </Width>
      )
    }

    return (
      <Fragment>
        <Stack vertical={48}>{actionsGroup}</Stack>
        <Paper top={24}>{copyPortfolioReference}</Paper>
      </Fragment>
    )
  }, [desktop, actionsGroup, copyPortfolioReference])

  return (
    <OptionsLayout
      header={
        <OptionsNavigationBar rightPartText="Close" onRightPartClick={handleClose}>
          Options
        </OptionsNavigationBar>
      }
      content={content}
      button={null}
      data-test-id="portfolioOptionsModal"
    />
  )
}

export { Options }
