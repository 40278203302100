import React, { Fragment } from 'react'

import { useState } from 'hooks'

import { formatPercent } from 'helpers/money'
import { isDarkTheme } from 'helpers/palette/'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { SimpleTextModal } from 'components/atoms/SimpleTextModal'
import { Typography } from 'components/atoms/Typography'

import { PriceChart } from 'components/organisms/PriceChart'

import { PERIODS } from 'app/pages/Securities/hooks'

type PresetChartProps = {
  performance: number | null
  chartData: Array<{ date: string; value: number }>
}

const PresetChart = ({ chartData, performance = 0 }: PresetChartProps): React.ReactElement => {
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false)
  const shouldShowSign = performance !== 0
  const formattedPercent = formatPercent(performance / 100, true, shouldShowSign, false)
  const color = !performance ? 'minor' : performance > 0 ? 'green' : 'red'
  const isDark = isDarkTheme()

  const disclaimerNode = (
    <Typo>
      This chart simulates how our managed portfolios might have performed based on historical ETF data since March
      2004. Monthly returns include each ETF’s current fee (Total Expense Ratio, or TER) and a 0.25% annual InvestEngine
      fee, adjusted for the current mix of ETFs in our portfolios. We’ve assumed these fees have stayed the same since
      2004, trading costs, like spreads, aren’t included. These figures aim to give a realistic idea of fee impact over
      time and avoid overstating performance, though actual past fees may have varied.
    </Typo>
  )

  const performanceNode = (
    <Fragment>
      <Typography size={32} weight="semibold" lineHeight="small" color={color}>
        {formattedPercent}
      </Typography>
      <Paper top={8}>
        <Typography tag="span" color="minor" size={14}>
          <Typo>Annualised return</Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  return (
    <Fragment>
      <PriceChart period={PERIODS.MAX} data={chartData} topContentOffset={40} topContent={performanceNode} />
      <Paper top={24}>
        <Typography size={14} lineHeight="medium" align="left" color={isDark ? 'on_color_white' : 'default'}>
          <Typo>
            Source: Bloomberg, March 2004. This chart shows a hypothetical example of how a £10,000 investment might
            have grown, based on past data of the current ETFs in our Managed Portfolios. It shows returns after fees
            but trading costs like spreads aren’t included. This is for illustration only - results aren’t guaranteed,
            and the asset mix may change. Investments can go up or down, so you might get back less than you invested.
            Past performance doesn't show how it'll perform in the future.
          </Typo>{' '}
          <Link
            onClick={() => {
              setIsDisclaimerModalOpen(true)
            }}
          >
            <Typography size={14} color="inherit" inline lineHeight="small" data-test-id="howCalculatedLink">
              <Typo>How is this calculated?</Typo>
            </Typography>
          </Link>
        </Typography>
      </Paper>
      <SimpleTextModal
        title="How is this calculated?"
        text={disclaimerNode}
        isOpen={isDisclaimerModalOpen}
        onClose={() => {
          setIsDisclaimerModalOpen(false)
        }}
      />
    </Fragment>
  )
}

export { PresetChart }
