import { useCallback, useState, useMemo } from 'hooks'

import { format as formatMoney } from 'helpers/money'
import { bulkValidate } from 'helpers/validation'

const MIN = 1
const MAX = 99999999

type UseTextQuestionProps = {
  initialValue?: string
  handleSubmit: (answerValue: null | number | number[], value?: null | string) => void
}

type UseTextQuestionInterface = {
  value: string
  validation: Record<string, { rules: boolean[]; errors: string[] }>
  isValid: boolean
  handleChange: (_event: unknown, value: string) => void
  handleSubmitClick: () => void
}

function useTextQuestion({ initialValue = '', handleSubmit }: UseTextQuestionProps): UseTextQuestionInterface {
  const [value, setValue] = useState<string>(initialValue ?? '')

  const validation = useMemo(
    () => ({
      value: {
        rules: [parseFloat(value) >= MIN, parseFloat(value) <= MAX],
        errors: [`Must be at least ${formatMoney(MIN)}`, `Maximum amount is ${formatMoney(MAX)}`],
      },
    }),
    [value],
  )

  const isValid = bulkValidate(validation)

  const handleChange = useCallback(
    (_event, value) => {
      setValue(value)
    },
    [setValue],
  )

  const handleSubmitClick = useCallback(() => {
    handleSubmit(null, value)
  }, [handleSubmit, value])

  return {
    value,
    validation,
    isValid,
    handleChange,
    handleSubmitClick,
  }
}

export { useTextQuestion }
