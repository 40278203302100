import React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMediaQueries, useMemo } from 'hooks'

import { isDarkTheme, palettePlainValues } from 'helpers/palette'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Preloader } from 'components/molecules/Preloader'

import box from './box.png'
import star from './star.png'

import './Box.css'

type BoxProps = {
  open?: boolean
  value: string | null
  handleContinue: () => void
}

function Box({ open = false, value, handleContinue }: BoxProps): React.ReactElement {
  const mediaQueries = useMediaQueries()
  const rootClasses = useMemo(
    () =>
      classNames(
        'ClaimBonusBox',
        {
          ForceDarkTheme: !isDarkTheme(),
          ClaimBonusBox_open: open,
        },
        getMediaQuieryClasses('ClaimBonusBox', mediaQueries),
      ),
    [mediaQueries, open],
  )
  const buttonClasses = useMemo(
    () =>
      classNames('ClaimBonusBox-Button', {
        ForceLightTheme: !isDarkTheme(),
      }),
    [],
  )

  return (
    <div className={rootClasses}>
      <div className="ClaimBonusBox-StarsHolder">
        <img className="ClaimBonusBox-Star1" src={star} width={20} height={20} alt="" role="presentation" />
        <img className="ClaimBonusBox-Star2" src={star} width={34} height={34} alt="" role="presentation" />
        <img className="ClaimBonusBox-Star3" src={star} width={47} height={47} alt="" role="presentation" />
      </div>
      <img className="ClaimBonusBox-Box1" src={box} width={192} height={184} alt="" role="presentation" />
      <img className="ClaimBonusBox-Box2" src={box} width={192} height={184} alt="" role="presentation" />
      <Typography className="ClaimBonusBox-Value" font="Poppins" size={54} weight="bold" align="center">
        <Preloader
          className="ClaimBonusBox-Preloader"
          loading={open && !value}
          background="transparent"
          delay={palettePlainValues.animation.speed.number.default}
        />
        <Typo>{value ?? ' '}</Typo>
      </Typography>
      <Width className={buttonClasses} size={20} center>
        <Paper top={40}>
          <Button onClick={handleContinue} mods={{ color: 'green', size: 'big block' }} disabled={!value}>
            Add to your portfolio
          </Button>
        </Paper>
      </Width>
    </div>
  )
}

export { Box }
