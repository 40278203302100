/* eslint-disable no-console */

import customerIOPlugin from '@analytics/customerio'
import googleAnalytics from '@analytics/google-analytics-v3'
import googleTagManager from '@analytics/google-tag-manager'
import { Analytics } from 'analytics'

import localstore from 'helpers/localstore.js'

import type { AnalyticsPlugin } from 'analytics'

const isProduction = location.hostname === 'investengine.com'
const isStaging = location.hostname.includes('rscwealth.com')
const isLocal = !isProduction && !isStaging
const shouldLogToConsole = isLocal || isStaging
const hasPlugins = !isLocal

const plugins: AnalyticsPlugin[] = []
const cioPlugins: AnalyticsPlugin[] = []

if (hasPlugins) {
  const GTAG_PRODUCTION_ID = 'UA-134693607-1'
  const GTAG_STAGING_ID = 'UA-134693607-2'

  const GTM_PRODUCTION_ID = 'GTM-WJVPJMK'
  const GTM_STAGING_ID = 'GTM-K6DZV5T'

  plugins.push(
    ...[
      googleTagManager({
        containerId: isProduction ? GTM_PRODUCTION_ID : isStaging ? GTM_STAGING_ID : null,
      }),
      googleAnalytics({
        trackingId: isProduction ? GTAG_PRODUCTION_ID : isStaging ? GTAG_STAGING_ID : null,
        customDimensions: {
          clientId: 'dimension1',
          clientType: 'dimension2',
          clientBalance: 'dimension3',
          portfolioRegulatoryType: 'dimension4',
          portfolioPresetType: 'dimension5',
          portfolioManageType: 'dimension6',
        },
      }),
    ],
  )

  cioPlugins.push(
    ...[
      customerIOPlugin({
        siteId: isProduction ? '1d5b1288032c50632168' : 'f7ba2332333eee26e05e',
        customScriptSrc: 'https://assets.customer.io/assets/track-eu.js',
      }),
    ],
  )
}

const analytics = Analytics({ plugins })
const analyticsCIO = Analytics({ plugins: cioPlugins })

analytics.on(
  'identify',
  ({ payload: { userId, traits } = {} }: { payload: { userId?: string; traits?: Record<string, unknown> } }) => {
    if (shouldLogToConsole) {
      console.info('@analytics | identify triggered', {
        userId,
        traits,
      })
    }
  },
)

analyticsCIO.on(
  'identify',
  ({ payload: { userId, traits } = {} }: { payload: { userId?: string; traits?: Record<string, unknown> } }) => {
    if (shouldLogToConsole) {
      console.info('@analyticsCIO | identify triggered', {
        userId,
        traits,
      })
    }
  },
)

analytics.on(
  'page',
  ({
    payload: { properties: { path, url, referrer } = {} },
  }: {
    payload: { properties?: { path?: string; url?: string; referrer?: string } }
  }) => {
    if (shouldLogToConsole) {
      console.info('@analytics | virtual page view triggered', {
        path,
        url,
        referrer,
      })
    }
  },
)

analyticsCIO.on(
  'page',
  ({
    payload: { properties: { path, url, referrer } = {} },
  }: {
    payload: { properties?: { path?: string; url?: string; referrer?: string } }
  }) => {
    if (shouldLogToConsole) {
      console.info('@analyticsCIO | virtual page view triggered', {
        path,
        url,
        referrer,
      })
    }
  },
)

analytics.on(
  'track',
  ({ payload: { event, properties } = {} }: { payload: { event?: string; properties?: Record<string, unknown> } }) => {
    if (shouldLogToConsole) {
      console.info('@analytics | event triggered', {
        action: event,
        properties,
      })
    }
  },
)

const handleError = (error: Error | string): void => {
  if (shouldLogToConsole) {
    console.info('@analytics | error')
    console.error(error)
  }
}
const trackEvent = (
  event: { action: string; [key: string]: number | string },
  options?: { plugins: { 'google-analytics-v3'?: boolean; 'google-tag-manager'?: boolean } },
): void => {
  const { action, ...rest } = event

  analytics.track(action, rest, options).catch(handleError)
}

const identify = (
  identifier: string | number | null,
  traits: { clientId?: string | number | null; clientType?: string; clientBalance?: string },
): void => {
  if (!identifier) {
    identifier = localstore.get('__user_id')
  }

  if (!traits?.clientId) {
    traits.clientId = localstore.get('__user_id')
  }

  if (traits?.clientId) {
    traits.clientId = `${traits.clientId}`
  }

  if (identifier) {
    identifier = `${identifier}`

    localstore.set('__user_id', identifier)

    // send custom event to GTM to trigger userIdSet event for GA4
    window?.dataLayer?.push({
      userId: identifier,
      event: 'userIdSet',
    })

    analytics.identify(identifier, traits).catch(handleError)
  }
}

export { analytics, trackEvent, identify, analyticsCIO }
