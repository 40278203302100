import React, { Fragment } from 'react'

import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

export const AdditionalInfo: React.FC = () => {
  const declarationFieldset = (
    <Fragment>
      <Typography size={24} lineHeight="small" weight="semibold">
        <Typo>Confirm your ISA transfer</Typo>
      </Typography>
      <Paper top={32}>
        <Typography size={14}>
          <Typo>Please read through the following and confirm your ISA Transfer Request:</Typo>
          <ul style={{ marginTop: 0, marginBottom: 0 }}>
            <li>
              I apply to transfer my funds subscribed in an existing ISA to InvestEngine, in accordance with the details
              I have provided and to the ISA I have opened with investEngine.
            </li>
            <li>
              I understand that my existing ISA Manager may charge me to transfer to my InvestEngine Stock and Shares
              ISA, and that there may be a period of time when I am not invested.
            </li>
            <li>
              I have been provided, have read, and have agreed to InvestEngine (UK) Limited's Terms and Conditions,
              including the Additional Terms for Stocks & Shares ISAs.
            </li>
          </ul>
        </Typography>
      </Paper>
    </Fragment>
  )

  const authorityFieldset = (
    <Fragment>
      <Typography size={24} lineHeight="small" weight="semibold">
        <Typo>What you’re authorising us to do</Typo>
      </Typography>
      <Paper top={32}>
        <Typography size={14}>
          <ul style={{ marginTop: 0, marginBottom: 0 }}>
            <li>
              I authorise InvestEngine to hold my cash subscription/payment, ISA investments, interest, dividends and
              any other rights or proceeds in respect of those investments and any other cash.
            </li>
            <li>
              I authorise InvestEngine to make on my behalf any claims to relief from tax in respect of ISA investments.
            </li>
            <li>
              I authorise the existing ISA Manager to sell the ISA investments where required and send InvestEngine the
              proceeds of the sale.
            </li>
            <li>
              I authorise InvestEngine to discuss with the existing ISA Manager any details relating to my existing ISA
              in order to ensure the completion of the transfer, thereby meeting the requirements under the Data
              Protection Act 2018.
            </li>
          </ul>
        </Typography>
      </Paper>
    </Fragment>
  )

  return (
    <div>
      {declarationFieldset}
      <Paper top={40}>{authorityFieldset}</Paper>
    </div>
  )
}
