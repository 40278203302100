import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { sendErrorOnce } from 'helpers/errorLogging.js'

import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'

import './Icon.css'

const Icon = (props) => {
  let {
    type,
    color,
    background,
    size,
    width,
    height,
    style,
    animation,
    className,
    inline,
    rounded,
    transitioned,
    upsideDown,
    onClick: handleClick,
    ...rest
  } = props

  try {
    const svg = useMemo(() => {
      return require(`./svgs/${type}.svg`)
    }, [type])

    style = {
      fill: color || null,
      width: width ? `${width}px` : size ? `${size}px` : null,
      height: height ? `${height}px` : size ? `${size}px` : null,
      backgroundColor: background || null,
      pointerEvents: 'none',
      ...style,
    }

    const classes = classNames(className, 'Icon', {
      [`Icon_animation_${animation}`]: animation,
      Icon_inline: inline,
      Icon_transitioned: transitioned,
      Icon_rounded: rounded,
      'Icon_upside-down': upsideDown,
    })

    return svg ? <SvgIcon className={classes} style={style} src={svg} onClick={handleClick} {...rest} /> : null
  } catch (error) {
    sendErrorOnce(error)

    return null
  }
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  background: PropTypes.string,
  size: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  animation: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  rounded: PropTypes.bool,
  transitioned: PropTypes.bool,
  upsideDown: PropTypes.bool,
  fixedSize: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Icon
