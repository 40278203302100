import * as React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMediaQueries } from 'hooks'

import './AccentLine.css'

type AccentLineProps = {
  color: string
}

const AccentLine = ({ color }: AccentLineProps): React.ReactElement => {
  const mediaQueries = useMediaQueries()

  return (
    <div
      className={classNames('AccentLine', getMediaQuieryClasses('AccentLine', mediaQueries))}
      style={{ background: color }}
      data-test-id="accentLineColor"
    />
  )
}

export { AccentLine }
