import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { palette } from 'helpers/palette'

import { useCallback, useMediaQueries } from 'hooks'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { SecurityOrderItem } from 'components/organisms/SecurityOrderItem/SecurityOrderItem.jsx'

const GroupOrder = ({ title, securities, isAutoinvest, index: groupIndex }) => {
  const { desktop } = useMediaQueries()

  const handleOpenFile = useCallback((event, url) => {
    event.preventDefault()
    window.open(url, '_blank')
  }, [])

  return (
    <Fragment>
      <Typography size={desktop ? 24 : 20} weight="semibold" lineHeight="small" data-test-id="securityOrderTitle">
        <Typo>{title}</Typo>
      </Typography>
      {isAutoinvest && (
        <Paper top={desktop ? 40 : 32}>
          <ItemWithIcon
            space={12}
            icon={<Icon size={24} type="questionmark-24" color={palette['content-on-background-default']} />}
            content={
              <Typography size={desktop ? 14 : 12}>
                <Typo>The following are AutoInvest orders</Typo>
              </Typography>
            }
            iconVerticalAlign="center"
            contentVerticalAlign="center"
          />
        </Paper>
      )}
      <Paper top={desktop ? 40 : 32}>
        {securities.map((security, index) => (
          <Paper
            key={security.id}
            top={index !== 0 ? (desktop ? 40 : 32) : 0}
            data-test-id="securityOrderItem"
            data-test-group={groupIndex}
          >
            <SecurityOrderItem
              title={security.title}
              logo={security.logo}
              orderInfo={security}
              onDocumentLinkClick={handleOpenFile}
            />
          </Paper>
        ))}
      </Paper>
    </Fragment>
  )
}

GroupOrder.propTypes = {
  title: PropTypes.string,
  securities: PropTypes.array,
  index: PropTypes.number,
  isAutoinvest: PropTypes.bool,
}

export { GroupOrder }
