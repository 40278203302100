import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useUnit } from 'effector-react'

import { useContext, useEffect, useMemo } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { isDarkTheme } from 'helpers/palette'
import { palette } from 'helpers/palette/'
import { urlTo } from 'helpers/router'

import { $collectionsStore, fetchCollectionGroupsFx } from 'app/effector/discover-etfs'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { CardNew } from 'components/atoms/CardNew'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

type SecurityCollectionsProps = {
  collections: Array<{
    icon_url: string
    icon_dark_theme_url: string
    style: string
    id: number
    slug: string
    title: string
  }>
  location: {
    query: Record<string, unknown>
  }
  areLinksDisabled: boolean
}

enum Theme {
  Background = 'background',
  Primary = 'primary',
  Accent = 'accent',
  Outstanding = 'outstanding',
}

const backgroundColors = {
  [Theme.Background]: palette['background-card'],
  [Theme.Primary]: palette['primary-default'],
  [Theme.Accent]: palette['accent-default'],
  [Theme.Outstanding]: palette['outstanding-default'],
}

const hoverColors = {
  [Theme.Background]: palette['background-card'],
  [Theme.Primary]: palette['primary-action'],
  [Theme.Accent]: palette['accent-action'],
  [Theme.Outstanding]: palette['outstanding-action'],
}

const textColors = {
  [Theme.Background]: 'on_background_primary',
  [Theme.Primary]: 'on_color_white',
  [Theme.Accent]: 'on_color_white',
  [Theme.Outstanding]: 'on_color_black',
}

const SecurityCollections = withRouter(
  ({
    collections = [],
    location: { query },
    areLinksDisabled,
  }: SecurityCollectionsProps): React.ReactElement | null => {
    const { saveScrollPosition } = useContext(ScrollSaverContext)

    const { collectionGroups, isLoading } = useUnit($collectionsStore)

    const partnerCollectionTitles = useMemo(() => {
      const titles: string[] = []

      collectionGroups.forEach((group) => {
        group.collections.forEach((collection) => {
          if (collection.subtitle === 'Partner page') {
            titles.push(collection.title)
          }
        })
      })

      return titles
    }, [collectionGroups])

    const filteredCollections = collections?.filter(
      (collection) => !partnerCollectionTitles.includes(collection.title) && collection.slug !== 'popular-etfs',
    )

    useEffect(() => {
      fetchCollectionGroupsFx()
    }, [])

    if (isLoading || !filteredCollections.length) return null

    const currentUrl = window.location.pathname + window.location.search

    return (
      <Fragment>
        <Paper bottom={12}>
          <Typography color="additional" size={14}>
            <Typo>This ETF is in collections:</Typo>
          </Typography>
        </Paper>
        {filteredCollections.map((collection) => (
          <Paper inline key={collection.id} right={16} bottom={16}>
            <Link
              disabled={areLinksDisabled}
              to={urlTo(
                'security-collection',
                { slugOrId: collection.slug || collection.id },
                querystringFromObject({
                  ...(query ?? {}),
                  back: currentUrl,
                  ...(query?.fromAnalytics ? { portfolioId: null, fromAnalytics: null } : {}),
                }),
              )}
              onClick={() => {
                saveScrollPosition()
              }}
            >
              <CardNew
                style={{ display: 'inline-block' }}
                backgroundColor={collection.background_color ?? backgroundColors[collection.style]}
                hoverColor={hoverColors[collection.style]}
                hoverOverlay={!!collection.background_color}
                data-test-id="collectionLabel"
              >
                <Paper left={8} right={8} top={8} bottom={8}>
                  <ItemWithIcon
                    space={4}
                    icon={
                      <img
                        src={
                          isDarkTheme() && collection.icon_dark_theme_url
                            ? collection.icon_dark_theme_url
                            : collection.icon_url
                        }
                        width={16}
                        height={16}
                      />
                    }
                    content={
                      <Typography inline size={14} lineHeight="small" color={textColors[collection.style]}>
                        <Typo>{collection.title}</Typo>
                      </Typography>
                    }
                    iconVerticalAlign="center"
                  />
                </Paper>
              </CardNew>
            </Link>
          </Paper>
        ))}
      </Fragment>
    )
  },
)
SecurityCollections.displayName = 'SecurityCollections'

export { SecurityCollections }
