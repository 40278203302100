import { useCallback } from 'hooks'

import localstore from 'helpers/localstore.js'

import { reauthorize } from 'app/redux/api/2fa'
import { ApiError } from 'app/redux/models/errors'

type UseReauthReturnProps = {
  checkIsClientReauthorized: () => boolean
  confirmIdentity: (password: string) => Promise<ApiError | undefined>
}

const useReauth = (): UseReauthReturnProps => {
  const checkIsClientReauthorized = useCallback(() => {
    const lastReauthTime = localstore.get('client')?.lastReauthTime
    const now = new Date().getTime()

    return lastReauthTime && now - lastReauthTime < 5 * 60 * 1000
  }, [])

  const confirmIdentity = useCallback(async (password) => {
    const data = await reauthorize({ password })

    if (data instanceof ApiError) {
      return data
    }

    localstore.set('client', {
      ...localstore.get('client'),
      access_token: data.access_token,
      lastReauthTime: new Date().getTime(),
    })
  }, [])

  return { checkIsClientReauthorized, confirmIdentity }
}

export { useReauth }
