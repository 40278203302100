import React from 'react'
import { Controller } from 'react-hook-form'

import PropTypes from 'prop-types'

import { useCallback, useState } from 'hooks'

const ControlledInput = ({ children, name, control, rules, errors = {} }) => {
  const [focused, setFocused] = useState(false)

  const handleFocusState = useCallback(() => {
    setFocused(true)
  }, [setFocused])
  const handleBlurState = useCallback(() => {
    setFocused(false)
  }, [setFocused])

  return (
    <Controller
      name={`${name}`}
      control={control}
      rules={rules}
      render={(
        { onChange: handleChange, onBlur: handleBlurController, value, name, ref },
        { invalid, isTouched, isDirty },
      ) => {
        const errorMessage = errors?.[name]?.message
        const valid = focused ? true : !invalid

        const handleBlur = () => {
          handleBlurState()
          handleBlurController()
        }

        return children({
          name,
          value,
          ref,
          handleChange,
          handleFocus: handleFocusState,
          handleBlur,
          valid,
          errorMessage,
          isTouched,
          isDirty,
        })
      }}
    />
  )
}

ControlledInput.propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  errors: PropTypes.object,
}

export { ControlledInput }
