import { createStore, createEffect, createEvent, sample } from 'effector'
import { pending } from 'patronum/pending'

import * as api from './api/'
import { type CreatePendingOrderParams } from './api/'
import { PendingOrder, PendingOrderList } from './models'
import { isTradingWindowNow } from './utils'

// Events
const checkIfCancellationAllowed = createEvent<null>()

// Effects
const fetchPendingOrdersFx = createEffect(async () => await api.fetchPendingOrders())

const createPendingOrderFx = createEffect(async (params: CreatePendingOrderParams) => {
  return await api.createPendingOrder(params)
})

const submitPendingOrderFx = createEffect(async ({ orderId }: { orderId: number }) => {
  await api.submitPendingOrder(orderId)
})

const cancelPendingOrdersFx = createEffect(async ({ portfolioId }: { portfolioId: number }) => {
  await api.cancelPendingOrders(portfolioId)
})

const checkIsHolidayFx = createEffect(async () => await api.checkIsHoliday())

// Stores
const $pendingOrders = createStore<PendingOrderList>(new PendingOrderList())
const $isCancellationAllowed = createStore<boolean>(true)

const $isLoading = pending([
  fetchPendingOrdersFx,
  createPendingOrderFx,
  submitPendingOrderFx,
  cancelPendingOrdersFx,
  checkIsHolidayFx,
])

// Store updates
$pendingOrders.on(fetchPendingOrdersFx.doneData, (_, result) => new PendingOrderList(...result))

$pendingOrders.on(createPendingOrderFx.doneData, (state, result) => {
  const order = PendingOrder.createFromObject(result)

  return state.replaceOrAdd(order)
})

$pendingOrders.on(submitPendingOrderFx.done, (state, { params }) => {
  const newPendingOrder = state.get(params.orderId)?.changeStateTo('SUBMITTED')

  if (!newPendingOrder) return state

  return state.replaceOrAdd(newPendingOrder)
})

$pendingOrders.on(cancelPendingOrdersFx.done, (state, { params }) => {
  const portfolioOrders = state.getByPortfolioId(params.portfolioId).map((order) => order.changeStateTo('CANCELLED'))

  return new PendingOrderList(...portfolioOrders)
})

sample({
  source: checkIfCancellationAllowed,
  target: checkIsHolidayFx,
})

sample({
  clock: checkIsHolidayFx.doneData,
  fn: (isHoliday) => !isHoliday && isTradingWindowNow(),
  target: $isCancellationAllowed,
})

export {
  // Events
  checkIfCancellationAllowed,

  // Stores
  $pendingOrders,
  $isCancellationAllowed,
  $isLoading,

  // Effects
  fetchPendingOrdersFx,
  createPendingOrderFx,
  submitPendingOrderFx,
  cancelPendingOrdersFx,
  checkIsHolidayFx,
}
