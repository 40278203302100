import { states as clientStates, states } from 'constants/client'

const isClientOnboarded = ({ client }) => {
  const onboardedStates = [states.NOT_COMPLETED, states.COMPLETED, states.APPROVED, states.CLOSING, states.CLOSED]

  return onboardedStates.includes(client.state)
}

const isClientNewOrNotCompleted = ({ client }) => [clientStates.NEW, clientStates.NOT_COMPLETED].includes(client.state)

const isClientNotCompleted = ({ client }) => [states.NOT_COMPLETED].includes(client.state)

const isClientFilledPersonalData = ({ client }) =>
  [states.COMPLETED, states.APPROVED, states.CLOSING, states.CLOSED].includes(client.state)

const isClientCompleted = ({ client }) => [clientStates.COMPLETED].includes(client.state)

const isClientApproved = ({ client }) => [states.APPROVED].includes(client.state)

const isClientRegistered = ({ client }) => [states.APPROVED, states.CLOSING, states.CLOSED].includes(client.state)

const isClientInClosing = ({ client }) => [states.CLOSING, states.CLOSED].includes(client.state)

const isClientBankFilled = ({ client }) => client.bank_filled

export {
  isClientOnboarded,
  isClientNewOrNotCompleted,
  isClientNotCompleted,
  isClientFilledPersonalData,
  isClientCompleted,
  isClientApproved,
  isClientRegistered,
  isClientInClosing,
  isClientBankFilled,
}
