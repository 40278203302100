import { Entity } from 'app/redux/models/common/Entity.js'

import { SecurityHistoryList } from './SecurityHistoryList.js'

/**
 * @class
 * @typedef {Object} SecurityHistoryCollection
 */
export class SecurityHistoryCollection extends Entity {
  constructor() {
    super()

    /** @type {SecurityHistoryList} */
    this.daily = new SecurityHistoryList()
  }

  /** @override */
  getRules() {
    return {
      daily: [[(history) => new SecurityHistoryList(...history)]],
    }
  }
}
