import React, { useMemo } from 'react'

import { useCallback, useContext, useSelector } from 'hooks'

import { features } from 'helpers/features'
import { format } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router'

import { RetakeQuestionnaireTunnelContext } from 'app/pages/YearlyReview/Questionnaire/RetakeQuestionnaireTunnel.jsx'

import { questionCodes, questionTypes } from 'constants/questionnaire'

type QuestionType = {
  id: number
  type: string
  code: string
  title: string
  answers: Array<{ id: number; title: string }>
}

type AllAnswersWrapperProps = {
  children: JSX.Element
  params: Record<string, unknown>
  location: {
    query: Record<string, unknown>
  }
}

function AllAnswersWrapper(props: AllAnswersWrapperProps): JSX.Element {
  const { children, ...rest } = props
  const questions = useSelector(
    (state: {
      questionnaire: {
        questionList: {
          questions: QuestionType[]
        }
      }
    }) => state.questionnaire.questionList.questions,
  )
  const { answers, resetAnswers } = useContext<{
    answers: Record<string, number[] | string>
    resetAnswers: () => void
  }>(
    // @ts-expect-error RetakeQuestionnaireTunnelContext isn't typed
    RetakeQuestionnaireTunnelContext,
  )

  const clientAnswersOnQuestions = useMemo(() => {
    const getAnswerValue = (question: QuestionType): string[] => {
      const answer = answers[question.code]

      if (!answer) {
        return []
      }

      if (question.type === questionTypes.TEXT) {
        const answerString = typeof answer === 'string' ? answer : `${answer[0]}`

        if (features?.get('new-questionnaire')) {
          return [format(answerString)]
        }

        const value = question.code === questionCodes.ANNUAL_TURNOVER ? format(answerString) : answer

        return [value]
      }

      return question.answers
        .filter((questionAnswer) => answer.includes(questionAnswer.id))
        .map((questionAnswer) => questionAnswer.title)
    }

    return questions.map((question) => ({
      type: question.type,
      question: question.title,
      code: question.code,
      answers: getAnswerValue(question),
    }))
  }, [questions, answers])

  const retakeQuestionnaire = useCallback(() => {
    resetAnswers()
    goTo(urlTo('questionnaire-retake', rest.params, rest.location.query))
  }, [resetAnswers, rest.location.query, rest.params])

  const redirectToNextStep = rest.onAfterSave

  return React.cloneElement(children, { ...rest, clientAnswersOnQuestions, retakeQuestionnaire, redirectToNextStep })
}

export { AllAnswersWrapper }
