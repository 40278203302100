import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'

import { goTo, urlTo } from 'helpers/router.js'
import { sendError } from 'helpers/errorLogging'

import { useCallback, useSelector, useContext, useMemo, useActions } from 'hooks'
import { useUnit } from 'effector-react'

import { questionTypes } from 'constants/questionnaire.js'

import { NewOptimalPortfolioModal } from '../NewOptimalPortfolioModal'
import {
  IncorrectPortfolioModalContent,
  NotChangedPortfolioModalContent,
  // PortfolioChangedModalContent,
  NewPortfolioChangedModalContent,
  PortfolioNotSwitchedModalContent,
  PortfolioSwitchedModalContent,
  RiskAttentionModalContent,
} from '../NewOptimalPortfolioModal/parts'
import { RetakeQuestionnaireTunnelContext } from './RetakeQuestionnaireTunnel.jsx'

import { showSupportModal as showSupportModalActionCreator } from 'app/redux/actions/ui'
import { clientAnswersBatch as clientAnswersBatchActionCreator } from 'app/redux/actions/questionnaire'
import {
  updateOrCreate as updateOrCreateGoalActionCreator,
  changeField as changeGoalFieldActionCreator,
} from 'app/redux/actions/portfolios'

import { states as portfolioModalStates } from '../NewOptimalPortfolioModal/states.js'

import { $owner } from 'app/effector/contacts'

const RetakeOptimalPortfolio = ({ routes, goal, params }) => {
  const routesModule = routes[routes.length - 1].module
  const isOpen = routesModule.includes('optimal-portfolio')
  const [showSupportModalAction, clientAnswersBatchAction, updateOrCreateGoal, changeGoalField] = useActions([
    showSupportModalActionCreator,
    clientAnswersBatchActionCreator,
    updateOrCreateGoalActionCreator,
    changeGoalFieldActionCreator,
  ])

  const { answers: localAnswers, resetAnswers } = useContext(RetakeQuestionnaireTunnelContext)

  const questions = useSelector((state) => state.questionnaire.questionList.questions)
  const clientAnswers = useSelector((state) => state.questionnaire.clientAnswerList.clientAnswers)
  const owner = useUnit($owner) ?? {}

  const newClientAnswers = useMemo(() => {
    // This will be only while Income to Growth portfolio migration https://app.asana.com/0/1178832889049625/1205395029227735/f
    if (!clientAnswers || clientAnswers.length === 0 || clientAnswers.length === 1) {
      return questions.map((question) => {
        const values = localAnswers[question.code]

        if (question.code === 'years_old' && owner.age) {
          return { answer_ids: [], goal_id: goal.id, question_id: question.id, value: owner.age }
        }

        if (question.code === 'annual_turnover') {
          return { answer_ids: [], goal_id: goal.id, question_id: question.id, value: clientAnswers[0].value }
        }

        if (question.type === questionTypes.TEXT) {
          return { answer_ids: [], value: values, goal_id: goal.id, question_id: question.id }
        }

        return {
          answer_ids: values,
          goal_id: goal.id,
          question_id: question.id,
        }
      })
    }

    return clientAnswers
      .map((answer) => {
        const question = questions.find((question) => question.id === answer.question_id)

        if (!question) {
          return answer
        }

        const values = localAnswers[question.code]

        if (question.type === questionTypes.TEXT) {
          return { ...answer, answer_ids: [], value: values || answer.value, goal_id: goal.id }
        }

        return { ...answer, answer_ids: values || answer.answer_ids, goal_id: goal.id }
      })
      .filter((answer) => answer)
  }, [localAnswers, questions, goal.id, owner, clientAnswers])

  const redirectToDashboard = useCallback(
    (view = 'Overview') => {
      if (goal?.id) {
        goTo(urlTo('dashboard.portfolio', { id: goal.id }, { view }), { replace: true })
      }
    },
    [goal.id],
  )

  const redirectToRetake = useCallback(() => {
    if (isFunction(resetAnswers)) {
      resetAnswers()
    }

    goTo(urlTo('questionnaire-retake', params))
  }, [resetAnswers, params])

  const switchToNewPortfolio = useCallback(
    async (presetRecommended) => {
      if (newClientAnswers.length > 0 && goal && presetRecommended) {
        try {
          await clientAnswersBatchAction(goal.id, newClientAnswers)
          await changeGoalField({ preset_changed: presetRecommended }, goal.id)
          await updateOrCreateGoal(['preset'], { preset: presetRecommended }, false, goal.id)
        } catch (error) {
          sendError(error)
        }
      }
    },
    [goal, newClientAnswers],
  )

  const saveClientAnswers = useCallback(async () => {
    if (newClientAnswers.length > 0 && goal) {
      try {
        await clientAnswersBatchAction(goal.id, newClientAnswers)
      } catch (error) {
        sendError(error)
      }
    }
  }, [goal, newClientAnswers])

  const renderChildrenMap = {
    [portfolioModalStates.PORTFOLIO_INCORRECT]: () => (
      <IncorrectPortfolioModalContent
        headline="We were not able to match you to a portfolio"
        contentText="Based on you answers to the risk questionnaire, we were not able to match you to a portfolio. Please complete the questionnaire again or contact our Support team."
        buttonText="Retake questionnaire"
        onRetake={redirectToRetake}
        onContactSupport={showSupportModalAction}
      />
    ),
    [portfolioModalStates.PORTFOLIO_CHANGED]: ({
      /* eslint-disable react/prop-types */
      // setRootModalVisibleState,
      currentPortfolio,
      newOptimalPortfolio,
      // presetRecommended,
      /* eslint-enable react/prop-types */
    }) => (
      // <PortfolioChangedModalContent
      //   goal={goal}
      //   currentPortfolio={currentPortfolio}
      //   newOptimalPortfolio={newOptimalPortfolio}
      //   postHeadlineText="We are suggesting a change in your portfolio because you are a year older than when you completed our risk questionnaire."
      //   onSetToNewOptimal={async () => {
      //     await switchToNewPortfolio(presetRecommended)
      //     setRootModalVisibleState(portfolioModalStates.PORTFOLIO_SWITCHED)
      //   }}
      //   onDoNothing={() => setRootModalVisibleState(portfolioModalStates.PORTFOLIO_RISK_ATTENTION)}
      //   onRetake={redirectToRetake}
      // />
      <NewPortfolioChangedModalContent
        currentPortfolio={currentPortfolio}
        newOptimalPortfolio={newOptimalPortfolio}
        onSetToNewOptimal={async () => {
          await saveClientAnswers()
          redirectToDashboard('Projections')
        }}
        onRetake={redirectToRetake}
      />
    ),
    [portfolioModalStates.PORTFOLIO_NOT_CHANGED]: () => (
      <NotChangedPortfolioModalContent
        headline="Your optimal portfolio has not changed"
        contentText={`Thank you for completing your InvestEngine MOT. You can always have a new risk assessment via Profile.`}
        buttonText="Return to Dashboard"
        onClick={async () => {
          await saveClientAnswers()
          redirectToDashboard()
        }}
      />
    ),
    [portfolioModalStates.PORTFOLIO_SWITCHED]: () => <PortfolioSwitchedModalContent onClick={redirectToDashboard} />,
    [portfolioModalStates.PORTFOLIO_NOT_SWITCHED]: () => (
      <PortfolioNotSwitchedModalContent onClick={redirectToDashboard} />
    ),
    [portfolioModalStates.PORTFOLIO_RISK_ATTENTION]: ({
      /* eslint-disable react/prop-types */
      setRootModalVisibleState,
      currentPortfolio,
      newOptimalPortfolio,
      /* eslint-enable react/prop-types */
    }) => (
      <RiskAttentionModalContent
        currentPortfolio={currentPortfolio}
        newOptimalPortfolio={newOptimalPortfolio}
        onCancel={() => setRootModalVisibleState(portfolioModalStates.PORTFOLIO_CHANGED)}
        onConfirm={async () => {
          await saveClientAnswers()
          setRootModalVisibleState(portfolioModalStates.PORTFOLIO_NOT_SWITCHED)
        }}
      />
    ),
  }

  return (
    <Fragment>
      <NewOptimalPortfolioModal
        open={isOpen}
        renderChildrenMap={renderChildrenMap}
        goal={goal}
        clientAnswers={newClientAnswers}
        onClose={redirectToDashboard}
      />
    </Fragment>
  )
}

RetakeOptimalPortfolio.propTypes = {
  routes: PropTypes.object,
  goal: PropTypes.object,
  params: PropTypes.object,
}

export { RetakeOptimalPortfolio }
