import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

// import { Nobr } from 'components/_old/Nobr'
// import Link from 'components/_old/Link/Link.jsx'

type TwoFactorNotificationIssueProps = {
  onContinue: () => void
}

const TwoFactorNotificationIssue = ({
  onContinue: handleContinue,
}: TwoFactorNotificationIssueProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <Paper bottom={32}>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
          Not receiving notifications?
        </Typography>
      </Paper>
      <Paper bottom={32}>
        <Typography size={desktop ? 14 : 16} align="center">
          <ColumnarLayout mods={{ padding: 'no' }}>
            {/* <Column size={0}>
              <Typo>1.&nbsp</Typo>
            </Column> */}
            <Column size={1}>
              <Typo>
                Your InvestEngine app notifications may be disabled. Go to your phone settings and turn on
                notifications.
              </Typo>
            </Column>
          </ColumnarLayout>
        </Typography>
      </Paper>
      <Paper bottom={56}>
        {/* <Typography size={desktop ? 14 : 16} align="left">
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={0}>
              <Typo>2.&nbsp</Typo>
            </Column>
            <Column size={1}>
              <div>
                <Typo>
                  If you have lost your phone or need assistance please contact us on{' '}
                  <Link to="tel:0800 808 5771" hard>
                    <Nobr>0800 808 5771</Nobr>
                  </Link>{' '}
                  or{' '}
                  <Link to="tel:+44 (0) 208 051 9 501" hard>
                    <Nobr>+44 (0) 208 051 9 501</Nobr>
                  </Link>
                </Typo>
              </div>
            </Column>
          </ColumnarLayout>
        </Typography> */}
      </Paper>
      {desktop && (
        <Button tag="div" mods={{ size: 'big block' }} onClick={handleContinue}>
          Continue
        </Button>
      )}
    </Fragment>
  )
}

export { TwoFactorNotificationIssue }
