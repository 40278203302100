import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette'
import { useCallback, useMediaQueries, useRef } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Input from 'components/_old/Input/Input.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { VisuallyHidden } from 'components/atoms/VisuallyHidden'

import './SearchField.css'

const SearchField = ({ value, placeholder, absolute, onChange }) => {
  const filterInput = useRef()
  const cancelButton = useRef()
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const handleChange = useCallback((event) => onChange && onChange(event.target.value), [onChange])

  const handleClear = useCallback(() => {
    onChange && onChange('')
    filterInput.current?.focus()
  }, [onChange, filterInput])

  const handleCancel = useCallback(() => {
    onChange && onChange('')
    filterInput.current?.blur()
    cancelButton.current?.blur()
  }, [onChange, filterInput, cancelButton])

  const classes = classNames('SearchField', getMediaQuieryClasses('SearchField', mediaQueries), {
    [`SearchField_absolute`]: absolute,
  })
  const tabIndex = value?.length > 0 ? 0 : -1

  return (
    <div className={classes} data-test-id="searchField" role="search">
      <Icon className="SearchField-Icon" type="search" size={17} color={palette['content-on-background-minor']} />
      <Input
        ref={filterInput}
        className="SearchField-Input"
        placeholder={placeholder}
        onChange={handleChange}
        tabIndex={1}
        data-test-id="searchFieldInput"
      >
        {value}
      </Input>
      <button
        className="SearchField-Clear"
        type="reset"
        tabIndex={tabIndex}
        onClick={handleClear}
        data-test-id="searchFieldClear"
      >
        <Icon
          type={desktop ? 'clear-cross' : 'clear'}
          size={desktop ? 24 : 20}
          color={palette['content-on-background-default']}
        />
        <VisuallyHidden>Clear search input</VisuallyHidden>
      </button>
      {!desktop && (
        <Link
          ref={cancelButton}
          className="SearchField-Cancel"
          onClick={handleCancel}
          tabIndex={tabIndex}
          data-test-id="searchFieldCancel"
          role="button"
        >
          Cancel <VisuallyHidden>search</VisuallyHidden>
        </Link>
      )}
    </div>
  )
}

SearchField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  absolute: PropTypes.bool,
  onChange: PropTypes.func,
}

export { SearchField }
