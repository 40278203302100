import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { palette } from 'helpers/palette/'

import { useMediaQueries } from 'hooks'

import { NavigationBar } from 'components/atoms/NavigationBar'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Width from 'components/_old/Width/Width'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Paper } from 'components/atoms/Paper'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'

import '../NewOptimalPortfolioModal.css'
import arrowDesktop from './arrowDesktop.svg'
import arrowMobile from './arrowMobile.svg'

const NewPortfolioChangedModalContent = ({
  currentPortfolio,
  newOptimalPortfolio,
  onSetToNewOptimal: handleSetToNewOptimal,
  onRetake: handleRetake,
}) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  return (
    <div data-test-id="portfolioChanged">
      {desktop && (
        <Fragment>
          {/* yes that's a hack, but ’cuz `NewPortfolioChangedModalContent` is temporary i think that's ok */}
          <Link
            className="_new_Modal-Close _new_Modal-CloseCross"
            mods={{ color: 'gray' }}
            onClick={handleSetToNewOptimal}
          >
            <Icon type="cross-thin" color="inherit" />
          </Link>
          <Width size={45}>
            <Width size={32.5} center>
              <Paper top={32}>
                <Typography size={32} lineHeight="small" align="center">
                  <Typo>Your portfolio may no longer be suitable for you</Typo>
                </Typography>
              </Paper>
              <Paper top={56}>
                <Typography align="center">
                  <ColumnarLayout mods={{ padding: 'no' }} inline>
                    <Column data-test-id="portfolioChangedPrevious">
                      <Typography
                        size={32}
                        lineHeight="small"
                        align="center"
                        color="on_background_primary"
                        data-test-id="portfolioChangedTitle"
                      >
                        <Typo>{currentPortfolio.title}</Typo>
                      </Typography>
                      <Paper top={4}>
                        <Typography size={14} lineHeight="small" align="center" color="additional">
                          <Typo>Current</Typo>
                        </Typography>
                      </Paper>
                    </Column>
                    <Column>
                      <Paper left={16} right={16}>
                        <SvgIcon
                          style={{ width: 60, fill: palette['content-on-background-primary'], marginTop: 10 }}
                          src={arrowDesktop}
                        />
                      </Paper>
                    </Column>
                    <Column data-test-id="portfolioChangedNext">
                      <Typography
                        size={32}
                        lineHeight="small"
                        align="center"
                        color="on_background_primary"
                        data-test-id="portfolioChangedTitle"
                      >
                        <Typo>{newOptimalPortfolio.title}</Typo>
                      </Typography>
                      <Paper top={4}>
                        <Typography size={14} lineHeight="small" align="center" color="additional">
                          <Typo>New</Typo>
                        </Typography>
                      </Paper>
                    </Column>
                  </ColumnarLayout>
                </Typography>
              </Paper>
              <Paper top={32}>
                <Typography size={16} align="center">
                  <Typo>You may re-take the questionnaire if you think this is incorrect.</Typo>
                </Typography>
              </Paper>
              <Paper top={56} bottom={32}>
                <ColumnarLayout mods={{ padding: 'no' }}>
                  <Column>
                    <Width size={15.75} center>
                      <Button
                        mods={{ size: 'big block', theme: 'simple-reverse-blue' }}
                        onClick={handleRetake}
                        data-test-id="portfolioChangedRetakeButton"
                      >
                        Retake risk questionnaire
                      </Button>
                    </Width>
                  </Column>
                  <Column>
                    <Paper left={12}>
                      <Width size={15.75} center>
                        <Button
                          mods={{ size: 'big block' }}
                          onClick={handleSetToNewOptimal}
                          data-test-id="portfolioChangedSwitchButton"
                        >
                          See available portfolios
                        </Button>
                      </Width>
                    </Paper>
                  </Column>
                </ColumnarLayout>
              </Paper>
            </Width>
          </Width>
        </Fragment>
      )}
      {!desktop && (
        <MobileLayout
          header={<NavigationBar rightPartText="Close" onRightPartClick={handleSetToNewOptimal} />}
          content={
            <AllCenter>
              <Typography size={24} weight="semibold" lineHeight="small" align="center">
                <Typo>Your portfolio may no longer be suitable for you</Typo>
              </Typography>
              <Paper top={48}>
                <Typography align="center">
                  <ColumnarLayout mods={{ padding: 'no' }} inline>
                    <Column data-test-id="portfolioChangedPrevious">
                      <Typography
                        size={24}
                        lineHeight="small"
                        align="center"
                        color="on_background_primary"
                        data-test-id="portfolioChangedTitle"
                      >
                        <Typo>{currentPortfolio.title}</Typo>
                      </Typography>
                      <Paper top={4}>
                        <Typography size={14} lineHeight="small" align="center" color="additional">
                          <Typo>Current</Typo>
                        </Typography>
                      </Paper>
                    </Column>
                    <Column>
                      <Paper left={16} right={16}>
                        <SvgIcon
                          style={{ width: 36, fill: palette['content-on-background-primary'], marginTop: 4 }}
                          src={arrowMobile}
                        />
                      </Paper>
                    </Column>
                    <Column data-test-id="portfolioChangedNext">
                      <Typography
                        size={24}
                        lineHeight="small"
                        align="center"
                        color="on_background_primary"
                        data-test-id="portfolioChangedTitle"
                      >
                        <Typo>{newOptimalPortfolio.title}</Typo>
                      </Typography>
                      <Paper top={4}>
                        <Typography size={14} lineHeight="small" align="center" color="additional">
                          <Typo>New</Typo>
                        </Typography>
                      </Paper>
                    </Column>
                  </ColumnarLayout>
                </Typography>
              </Paper>
              <Paper top={32}>
                <Typography size={16} align="center">
                  <Typo>You may re-take the questionnaire if you think this is incorrect.</Typo>
                </Typography>
              </Paper>
            </AllCenter>
          }
          footer={
            <Fragment>
              <MobileLayoutFooterButton>
                <Button
                  mods={{ size: 'big block' }}
                  onClick={handleSetToNewOptimal}
                  data-test-id="portfolioChangedSwitchButton"
                >
                  See available portfolios
                </Button>
              </MobileLayoutFooterButton>
              <MobileLayoutFooterButton>
                <Button
                  mods={{ size: 'big block', theme: 'simple-reverse-blue' }}
                  onClick={handleRetake}
                  data-test-id="portfolioChangedRetakeButton"
                >
                  Retake risk questionnaire
                </Button>
              </MobileLayoutFooterButton>
            </Fragment>
          }
        />
      )}
    </div>
  )
}

const portfolioShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  preset_type: PropTypes.string,
  preset_stocks: PropTypes.array,
  preset_bonds: PropTypes.array,
  preset_alternatives: PropTypes.array,
})

NewPortfolioChangedModalContent.propTypes = {
  currentPortfolio: portfolioShape.isRequired,
  newOptimalPortfolio: portfolioShape.isRequired,
  onSetToNewOptimal: PropTypes.func.isRequired,
  onRetake: PropTypes.func.isRequired,
}

export { NewPortfolioChangedModalContent }
