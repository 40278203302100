import { useUnit } from 'effector-react'

import { useCallback, useSelector } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { $recurringPaymentsStore } from 'app/effector/recurringPayments'

import type { Portfolio } from 'app/redux/models/portfolio/types'
import { isAddFundsAllowed } from 'app/redux/selectors'

import { setAnimation } from 'components/molecules/Modal/'

import { manageTypes } from 'constants/portfolio'

type UseDirectDebitInterface = {
  isLoading: boolean
  addFundsAllowed: boolean
  handleBack: () => void
}

const useDirectDebit = (
  portfolio: Portfolio,
  location: { query?: { promo?: boolean; back?: string } },
): UseDirectDebitInterface => {
  const { hasDirectDebitByPortfolioId } = useUnit($recurringPaymentsStore)
  const hasSubscription = hasDirectDebitByPortfolioId(portfolio?.id)

  const portfoliosDidInvalidate = useSelector((state: any) => state.portfolios.didInvalidate)
  const clientDidInvalidate = useSelector((state: any) => state.client.didInvalidate)
  const addFundsAllowed = useSelector((state: any) => isAddFundsAllowed(state, portfolio?.id))
  const isCashPortfolio = portfolio.manage_type === manageTypes.CASH

  const handleBack = useCallback(() => {
    if (!hasSubscription) {
      goTo(urlTo('dashboard.portfolio.options.direct-debit', { id: portfolio.id }, location?.query ?? {}))
      return
    }

    if (isCashPortfolio && !location.query?.back) {
      goTo(urlTo(`dashboard.portfolio`, { id: portfolio.id }))
      return
    }

    // That's a hack
    setAnimation('true')
    setTimeout(() => {
      setAnimation('false')
    }, 50)

    goTo(location.query?.back ?? urlTo(`dashboard.portfolio.options`, { id: portfolio.id }))
  }, [portfolio.id, hasSubscription, isCashPortfolio, location])

  return {
    isLoading: portfoliosDidInvalidate || clientDidInvalidate,
    addFundsAllowed,
    handleBack,
  }
}

export { useDirectDebit }
