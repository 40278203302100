import React from 'react'

import { useMediaQueries, useSelector } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { urlTo } from 'helpers/router.js'

import { getSortedRegulatoryTypes } from 'app/redux/selectors'

import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { TransactionTypeFilterValue } from 'app/pages/Dashboard/Transactions/hooks/useFilters'

import { PortfolioCard } from './PortfolioCard'

import { regulatoryTypes, manageTypes, regulatoryTypesText } from 'constants/portfolio'

const FeesContent = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const groupedPortfolios = useSelector((state) => state.portfolios.list.getVisiblePortfolios().groupByRegulatoryType())
  const regulatoryTypesOrder = useSelector(getSortedRegulatoryTypes)

  const renderPortfoliosGroup = ({ title, portfolios = [] }): React.ReactElement | null => {
    if (!portfolios.length) return null

    return (
      <Paper bottom={desktop ? 56 : 48}>
        <Typography size={18} weight="regular">
          <Typo>{title}</Typo>
        </Typography>
        {portfolios?.map((portfolio) => (
          <Paper key={portfolio.id} top={desktop ? 24 : 16}>
            <PortfolioCard portfolio={portfolio} />
          </Paper>
        ))}
      </Paper>
    )
  }

  return (
    <Width size={desktop ? 36.75 : null}>
      {regulatoryTypesOrder.map((regulatoryType) => {
        const portfolios = groupedPortfolios[regulatoryType]

        return renderPortfoliosGroup({
          title: regulatoryTypesText[regulatoryType],
          portfolios: portfolios?.filter(
            (portfolio) => regulatoryType === regulatoryTypes.SIPP || portfolio.manage_type !== manageTypes.CASH,
          ),
        })
      })}

      <Button
        mods={{
          size: 'big block',
          theme: 'simple-reverse-blue',
          block: true,
        }}
      >
        <Link
          to={urlTo(
            'dashboard.transactions',
            null,
            querystringFromObject({
              types: TransactionTypeFilterValue.FEE,
            }),
          )}
        >
          View fee transactions
        </Link>
      </Button>
      <Paper top={48}>
        <Typography size={14} color="minor" lineHeight="small">
          <Typo>
            Transaction costs — incurred as a result of the acquisition and disposal of investments. Including brokerage
            commissions, stamp duty and spreads.
            <br />
            <br />
            Ongoing charges — the cost of the funds you hold. These costs are taken by the fund manager rather than
            InvestEngine and are represented as the funds Total Expense ratio (TER)
            <br />
            <br />
            These costs & charges are not taken by InvestEngine and depend on activity within your portfolio. We provide
            a summary of these in your cost & charges report annually.
          </Typo>
        </Typography>
      </Paper>
    </Width>
  )
}

export { FeesContent }
