import { combine, createStore, createEvent, createEffect, sample } from 'effector'

import { generateRecoveryCode, confirmRecoveryCode } from 'app/effector/2fa/api'

import { showFailToast } from 'app/redux/actions/ui'

const generateRecoveryCodeFx = createEffect(async () => {
  const result = await generateRecoveryCode()

  return result
})

const confirmRecoveryCodeFx = createEffect(async (id: number) => {
  const result = await confirmRecoveryCode(id)

  return result
})

const showFailToastFx = createEffect(({ error }) => {
  showFailToast(error?.response?.data?.errors?.[0]?.message ?? 'Something went wrong')
})

// Events
const init = createEvent()
const setIsChecked = createEvent<boolean>()

// Stores
const $recoveryCode = createStore<{ raw: string; id: number | null }>({ raw: '', id: null })
const $isChecked = createStore<boolean>(false)

// Store updates
$recoveryCode.reset(init)
$isChecked.reset(init)
$isChecked.on(setIsChecked, (state, isChecked) => isChecked)
$recoveryCode.on(generateRecoveryCodeFx.doneData, (state, data) => data)

sample({
  clock: init,
  target: [generateRecoveryCodeFx],
})

sample({
  clock: [generateRecoveryCodeFx.fail, confirmRecoveryCodeFx.fail],
  target: showFailToastFx,
})

const $isLoading = combine(generateRecoveryCodeFx.pending, confirmRecoveryCodeFx.pending, (...pendingEffects) =>
  pendingEffects.some((pending) => pending),
)

export { init, $recoveryCode, $isChecked, $isLoading, generateRecoveryCodeFx, confirmRecoveryCodeFx, setIsChecked }
