import localstore from 'helpers/localstore.js'

import {
  SET_NOT_VALID,
  SET_VALID,
  RECEIVE_FIELD,
  RECEIVE_ERROR,
  RESET_ERROR,
  RESET_CLIENT,
  RECEIVE_CLIENT,
} from 'app/redux/actions/client/clientActionTypes.js'

import { states } from 'constants/client'

const cache = localstore.get('client')

export const initialState = {
  is_fetched: false,
  didInvalidate: true,
  error: null,
  type: null,
  password: null,
  password_filled: null,
  bank_checked: null,
  tax_checked: null,
  all_checked: null,
  bank_title: null,
  country_code_by_ip: null,
  email: null,
  third_party_notification_email: null,
  third_party_notification_email_enabled_date: null,
  employment_status: null,
  employer: null,
  experience_with_equities: null,
  gocardless_account_id: null,
  gocardless_id: null,
  id: null,
  other_wealth_source: null,
  phone: null,
  politically_exposed_person: null,
  residence_checked: null,
  state: states.NEW,
  completed: false,
  user_apns_devices: null,
  user_gcm_devices: null,
  wealth_source: null,
  should_sign_isa_declaration: false,
  agreed_with_isa_declaration: null,
  agreed_with_terms: 1,
  agreed_with_risk_disclosure: 1,
  data_confirmation_needed: false,
  confirm_data: false,
  captcha: null,
  access_token: cache ? cache.access_token : null,
  refresh_token: null,
  client_id: 'HHF7ADq3KxJnoRprhgjedr3FbVmpNzbSE3VnvGfK',
  isa_transfer_prefilled_data: null,
}

export default function client(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NOT_VALID:
      return Object.assign({}, state, { didInvalidate: true })

    case SET_VALID:
      return Object.assign({}, state, { didInvalidate: false })

    case RECEIVE_FIELD:
      return { ...state, ...action.field }

    case RECEIVE_ERROR:
      return Object.assign({}, state, { error: action.error })

    case RESET_ERROR:
      return Object.assign({}, state, { error: null })

    case RESET_CLIENT:
      return { ...initialState, access_token: null }

    case RECEIVE_CLIENT:
      return {
        ...state,
        ...action.client,
      }

    default:
      return state
  }
}
