import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { useMediaQueries, useCallback, useContext, useRedirect } from 'hooks'
import { useUnit } from 'effector-react'

import { StepContext } from 'app/containers/PortfolioTunnel'

import Card from 'components/_old/Card/Card.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import AddingSignificantControlForm from './AddingSignificantControlForm/AddingSignificantControlForm.jsx'

import { $isLoading } from 'app/effector/contacts'

const AddingSignificantControlSetup = withRouter(({ owner, params, updateSignificantControl }) => {
  const { desktop } = useMediaQueries()
  const { getStepByName } = useContext(StepContext)
  const isContactsLoading = useUnit($isLoading)

  const residentialAddressStep = getStepByName('residential-address')

  useRedirect({
    to: residentialAddressStep.module,
    rule: !owner?.addresses?.hasAddresses(),
    isLoading: isContactsLoading,
  })

  const significantControlId =
    params.significantControlId !== undefined ? parseInt(params.significantControlId, 10) : null

  const handleSubmit = useCallback(
    (significantControl) => {
      updateSignificantControl(significantControl)
    },
    [updateSignificantControl],
  )

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <Card mods={{ theme: 'transparent' }}>{submitButton}</Card>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  const card = (
    <Card
      mods={{
        theme: 'transparent',
        'no-padding': desktop ? 'left right' : 'all',
      }}
      fluid={!desktop}
    >
      <AddingSignificantControlForm
        significantControlId={significantControlId}
        wrapSubmitButton={wrapSubmitButton}
        onSubmit={handleSubmit}
      />
    </Card>
  )

  if (desktop) {
    return <AllCenter>{card}</AllCenter>
  }

  return card
})

AddingSignificantControlSetup.propTypes = {
  params: PropTypes.shape({
    contactId: PropTypes.string,
  }),
  owner: PropTypes.object,
  updateSignificantControl: PropTypes.func,
  handleAfterSubmit: PropTypes.func,
}

export { AddingSignificantControlSetup }
