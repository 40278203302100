import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'helpers/ajax'
import Bloodhound from 'bloodhound-js'
import throttle from 'lodash/throttle'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import isEqual from 'lodash/isEqual'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette/'

import Input from 'components/_old/Input/Input.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

import './Typeahead.css'

export default class Typeahead extends React.Component {
  state = {
    error: false,
    textValue: '',
    firstGuess: null,
  }

  isItUk(input) {
    input = input || ''
    return input.toLowerCase() === 'uk' || input.toLowerCase() === 'u'
  }

  handleInput = (event) => {
    const nextValue = event.target.value
    const { changeOnInput } = this.props
    this.setTyped(nextValue)
    this.getOptions(nextValue)
    if (changeOnInput) {
      this.handleChange(false, event.target.value)
    }
  }

  setTyped = (textValue) => {
    this.setState({ textValue })
  }

  handleChange = async (enterPressed, nextValue) => {
    const { firstGuess } = this.state
    const { onChange, forceSelect, labelKey } = this.props

    if (enterPressed || forceSelect) {
      const val = firstGuess ? firstGuess[labelKey] : null
      this.setTyped(val)
    }
    const sentValue = firstGuess && (enterPressed || forceSelect) ? firstGuess[labelKey] : nextValue

    if (isFunction(onChange)) {
      onChange(null, firstGuess, sentValue)
    }
    return sentValue
  }

  handleArrowClick = async () => {
    const { onAfterFinalize } = this.props
    await this.handleChange(true)
    if (isFunction(onAfterFinalize)) {
      onAfterFinalize()
    }
  }

  handleKeypess = (event) => {
    if (event.key === 'Enter') {
      this.handleChange(true)
    }
  }

  initBloodhound = (options) => {
    this.engine = new Bloodhound({
      local: options,
      queryTokenizer: function (datum) {
        return Bloodhound.tokenizers.whitespace(datum.toLowerCase())
      },
      datumTokenizer: function (datum) {
        return Bloodhound.tokenizers.whitespace(datum.country.toLowerCase())
      },
      sorter: function (a, b) {
        if (a.country === 'United Kingdom') {
          return -1
        }
        return a.country.length - b.country.length
      },
    })

    this.engine.initialize()
  }

  initTextValue = (value, labelKey) => {
    if (isString(value)) {
      this.setState({ textValue: value })
    }
    if (isObject(value) && labelKey) {
      this.setState({ textValue: value[labelKey], firstGuess: value })
    }
  }

  componentDidMount() {
    const { options, value, labelKey } = this.props

    if (options) {
      this.initBloodhound(options)
    }

    this.initTextValue(value, labelKey)
  }

  getOptions = throttle((nextValue) => {
    let textValue = isString(nextValue) ? nextValue : this.state.textValue
    let { remote, options } = this.props

    if (!textValue || textValue.length < 1) {
      this.setState({ firstGuess: null })
      return false
    }
    if (typeof textValue === 'string' && options) {
      if (options && options[0] && options[0].country && this.isItUk(textValue)) {
        textValue = 'United Kingdom'
      }
      this.engine.search(textValue, (data) => {
        const ukItem = data.find((item) => item.country === 'United Kingdom')
        const firstGuess = ukItem || data[0]
        this.setState({ firstGuess })
      })
    }
    if (typeof textValue === 'string' && remote) {
      remote = remote.replace(/:query/g, textValue)
      axios
        .get(remote)
        .then((response) => {
          const { data } = response

          if (data.length > 0) {
            this.setState({ firstGuess: data[0] })
          } else {
            this.setState({ firstGuess: null })
          }
        })
        .then(() => {
          this.setState({ error: false })
        })
        .catch(() => {
          this.setState({ error: true })
        })
    }
  }, 150)

  componentDidUpdate({ options: prevOptions, value: prevValue }) {
    const { options: nextOptions, value: nextValue, labelKey: nextLabelKey } = this.props

    const paddingLeft = `calc(0.75em + ${this.refs.begginning ? this.refs.begginning.offsetWidth : 0}px)`
    const leftWhole = `calc(0.75em + ${this.refs.wholeString ? this.refs.wholeString.offsetWidth : 0}px)`
    this.refs.input.refs.input.style.paddingLeft = paddingLeft
    const fillIcon = ReactDOM.findDOMNode(this).querySelector('.Typeahead-Fill')

    if (fillIcon && this.refs.wholeString) {
      fillIcon.style.left = leftWhole
    }

    if (JSON.stringify(nextOptions) !== JSON.stringify(prevOptions)) {
      this.initBloodhound(nextOptions)
    }

    if (prevValue !== nextValue) {
      this.initTextValue(nextValue, nextLabelKey)
    }
  }

  render() {
    const { textValue, firstGuess } = this.state
    const { onChange, type, remote, labelKey, forceSelect, children, onBlur, className, options, value, ...rest } =
      this.props

    delete rest.changeOnInput
    delete rest.onAfterFinalize

    const wantedLabel =
      options && options[0] && options[0].country && this.isItUk(textValue)
        ? textValue
        : firstGuess
        ? firstGuess[labelKey]
        : null

    const matchedLabel = (() => {
      const label = wantedLabel ? wantedLabel.toLowerCase() : ''
      const part = textValue ? textValue.toLowerCase() : ''
      const split = label.split(part)
      const wholeLength = label ? label.length : null
      const begginingLenth = split[0] ? split[0].length : null
      const endingLenth = split[1] ? split[1].length : null
      const intersectionLength = wholeLength - begginingLenth - endingLenth

      if (intersectionLength) {
        return (
          <span ref="wholeString">
            <span style={{ opacity: '0.3333' }} ref="begginning">
              {wantedLabel.substring(0, begginingLenth)}
            </span>
            <span style={{ opacity: '0' }}>{textValue}</span>
            <span style={{ opacity: '0.3333' }}>{wantedLabel.substring(wholeLength - endingLenth, wholeLength)}</span>
          </span>
        )
      }
      return null
    })()

    const newOnBlur = forceSelect
      ? () => {
          this.handleChange.apply(arguments)
          if (isFunction(onBlur)) {
            onBlur.apply(arguments)
          }
        }
      : onBlur

    const firstGuessEqualValue = (() => {
      if (!firstGuess) {
        return false
      }
      if (isObject(value)) {
        const isCountry = options && options[0] && options[0].country

        if (isCountry) {
          return firstGuess.country_id === value.country_id
        }
        if (!isCountry) {
          return isEqual(firstGuess, value)
        }
      }

      if (!isObject(value)) {
        return value === firstGuess[labelKey]
      }
    })()

    const gotGuess = !firstGuessEqualValue && matchedLabel

    const typeheadFillClass = classNames('Typeahead-Fill', { 'Typeahead-Fill_visible': gotGuess })

    return (
      <div className="Typeahead">
        <Input
          ref="input"
          type="text"
          className={`Typeahead-Input ${className}`}
          {...rest}
          autoCorrect="off"
          autoComplete="off"
          onInput={this.handleInput}
          onKeyPress={this.handleKeypess}
          onBlur={newOnBlur}
        >
          {textValue}
        </Input>
        {matchedLabel ? <div className={`Typeahead-Label ${className}`}>{matchedLabel}</div> : null}
        <button className={typeheadFillClass} onClick={this.handleArrowClick}>
          <Icon
            type="right"
            className="Typeahead-FillIcon"
            color={palette['content-on-background-primary']}
            size={24}
          />
        </button>
      </div>
    )
  }
}
