import { type RefObject } from 'react'

import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useCallback, useMemo, useState, useRef } from 'hooks'

import { format as formatMoney } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router'

import { applyPromocodeFx, $appliedPromocode } from 'app/effector/promocodes'

import { type PromocodeModalProps } from '../components/PromocodeModal'

type UsePromocodeModalProps = {
  inputRef: RefObject<HTMLInputElement>
  isOpen: boolean
  promocodeValue: string
  error: string | null
  appliedText: string
  handleClose: () => void
  handleInput: (event: unknown, value: string) => void
  handleFocus: (event: unknown) => void
  handleBlur: (event: unknown) => void
  handleSubmit: (event: unknown) => Promise<void>
}

function usePromocodeModal({ routes }: PromocodeModalProps): UsePromocodeModalProps {
  const { min, max } = useUnit($appliedPromocode)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isOpen, moduleToGoToOnClose] = useMemo(() => {
    const enterPromocodeRoute = routes.find((route) => route.module === 'enter-promocode')
    const enterPromocodeRouteIndex = routes.findIndex((route) => route === enterPromocodeRoute)
    const isModalOpen = !!enterPromocodeRoute
    const moduleToGoToOnClose =
      enterPromocodeRouteIndex > 0
        ? routes.slice(0, enterPromocodeRouteIndex).reduce((result, route) => {
            if (route.module) {
              if (result.length > 0) {
                return `${result}.${route.module}`
              }

              return route.module
            }

            return result
          }, '')
        : null

    return [isModalOpen, moduleToGoToOnClose]
  }, [routes])

  const [promocodeValue, setPromocodeValue] = useState('')
  const [error, setError] = useState<string | null>(null)

  const appliedText = useMemo(() => {
    if (typeof min === 'string' && typeof max === 'string') {
      if (min === max) {
        return `You'll receive your bonus of ${formatMoney(min)} when opening any account and investing £100`
      }

      return `You'll receive your bonus between ${formatMoney(min)}-${formatMoney(max)} when opening any account and investing £100`
    }

    return `You'll receive your bonus when opening any account and investing £100.`
  }, [min, max])

  const validate = useCallback(() => {
    if (error) {
      return
    }

    if (promocodeValue.length < 1) {
      setError('Promo code can‘t be empty')
    }
  }, [promocodeValue, error])

  const handleInput = useCallback(
    (_event, value: string) => {
      setError(null)
      setPromocodeValue(value)
    },
    [setError, setPromocodeValue],
  )

  const handleFocus = useCallback(() => {
    setError(null)
  }, [setError])

  const handleBlur = useCallback(validate, [validate])

  const handleSubmit = useCallback(async () => {
    if (error) {
      return inputRef.current?.focus()
    }

    const applyFx = attach({
      effect: applyPromocodeFx,
    })

    const unwatchApply = applyFx.fail.watch(({ error }) => {
      // @ts-expect-error TODO: rework ApiError
      const errorMessage = error.response.data?.errors?.[0]?.message ?? 'Promo code is not valid'

      setError(errorMessage)
    })

    await applyFx({ promocode: promocodeValue })
    unwatchApply.unsubscribe()
  }, [inputRef, error, promocodeValue])

  const handleClose = useCallback(() => {
    goTo(urlTo(moduleToGoToOnClose), { scrollToTop: false })
  }, [moduleToGoToOnClose])

  return {
    inputRef,
    isOpen,
    promocodeValue,
    error,
    appliedText,
    handleClose,
    handleInput,
    handleFocus,
    handleBlur,
    handleSubmit,
  }
}

export { usePromocodeModal }
