import { createEffect, createEvent, createStore, sample } from 'effector'

import { urlTo, goTo } from 'helpers/router'
import { phoneRules, bulkValidate } from 'helpers/validation.js'

import { confirmPhoneNumber } from 'app/effector/2fa/api'

const confirmPhoneNumberFx = createEffect(async (phone: string) => {
  await confirmPhoneNumber(phone)
})

const navigateToFinishFx = createEffect(() => {
  goTo(urlTo('two-factor-auth-setup.success'))
})

// Events
const setPhoneNumber = createEvent<string>()
const initPhoneConfirmation = createEvent()
const closeSmsModal = createEvent()

// stores
const $phoneNumber = createStore<string>('')
const $smsModalOpened = createStore<boolean>(false)

// Store updates
$phoneNumber.on(setPhoneNumber, (state, value) => value.replace(/[ \-()]/g, ''))
$smsModalOpened.on(confirmPhoneNumberFx.done, () => true)
$smsModalOpened.on(closeSmsModal, () => false)

sample({
  clock: initPhoneConfirmation,
  source: $phoneNumber,
  fn: (phoneNumber) => phoneNumber,
  target: confirmPhoneNumberFx,
})

const $validation = $phoneNumber.map((phoneNumber) => {
  return {
    phone: phoneRules(phoneNumber),
  }
})
const $isValid = $validation.map((validation) => bulkValidate(validation))

const $isLoading = confirmPhoneNumberFx.pending

export {
  $isLoading,
  $phoneNumber,
  $smsModalOpened,
  setPhoneNumber,
  initPhoneConfirmation,
  navigateToFinishFx,
  closeSmsModal,
  $validation,
  $isValid,
}
