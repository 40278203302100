import React, { Fragment } from 'react'
import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

import { useMediaQueries } from 'hooks'

const Sectors = (props) => {
  const { desktop } = useMediaQueries()

  return <Fragment>{desktop ? <Desktop {...props} /> : <Mobile {...props} />}</Fragment>
}

export { Sectors }
