import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import './Chart.css'

const sizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

const paddingLefts = {
  no: 'no',
  NORMAL: 'normal',
}

const Chart = ({
  children,
  className,
  size = sizes.MEDIUM,
  paddingLeft = paddingLefts.NORMAL,
  'data-test-id': dataTestId,
}) => {
  const mediaQueries = useMediaQueries()
  const classes = classNames(
    className,
    'Chart',
    [`Chart_size_${size}`],
    [`Chart_paddingLeft_${paddingLeft}`],
    getMediaQuieryClasses('Chart', mediaQueries),
  )

  return (
    <div className={classes} data-test-id={dataTestId}>
      {children.map(
        (child, index) =>
          child &&
          React.cloneElement(child, {
            key: index,
            className: classNames(child?.props?.className || '', 'Chart-Element'),
          }),
      )}
    </div>
  )
}

Chart.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(sizes)),
  paddingLeft: PropTypes.oneOf(Object.values(paddingLefts)),
  'data-test-id': PropTypes.string,
}

export { Chart }
