import React from 'react'

import { useMediaQueries } from 'hooks'

import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper/'
import { Typography } from 'components/atoms/Typography'

import './SippTooltip.css'

type SippTooltipProps = {
  iconSize?: number
  gatewayName?: string
}

const SippTooltip = ({ iconSize = 16, gatewayName }: SippTooltipProps): React.ReactElement | null => {
  const { desktop } = useMediaQueries()

  const modalContentNode = (
    <Paper>
      <Typography size={desktop ? 16 : 24} lineHeight="small" weight="semibold" align={desktop ? 'left' : 'center'}>
        <Typo>InvestEngine Personal Pension</Typo>
      </Typography>
      <Paper top={20}>
        <Typography>
          <ol className="SippTooltip_list">
            <li>
              <Typo>Grow your retirement savings with our tax-efficient SIPP</Typo>
            </li>
            <li>
              <Typo>0.15% p.a. charge</Typo>
            </li>
            <li>
              <Typo>Maximum fee of £200 per year (or £16.66 per month)</Typo>
            </li>
          </ol>
        </Typography>
      </Paper>
    </Paper>
  )

  return (
    <TooltipToModal
      className="SippTooltip"
      zIndex={1001}
      offset={-200}
      description={modalContentNode}
      customIcon
      gatewayName={gatewayName}
    >
      <Link mods={{ color: 'black' }}>
        <Icon type={`information-${iconSize}`} color="inherit" size={iconSize} inline />
      </Link>
    </TooltipToModal>
  )
}

export { SippTooltip }
