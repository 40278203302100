import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette/'
import { format as formatMoney } from 'helpers/money'
import { format as formatDate } from 'helpers/date.js'
import { transformIncomeHistoryData } from 'helpers/charts'
import { Paper } from 'components/atoms/Paper'
import { GraphPlaceholder } from 'app/pages/Dashboard/Goals/components/GraphPlaceholder'

import { useMediaQueries, useState, useMemo, useEffect, useCallback, useRef } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { Chart, ChartTooltip, IncomeBars, Legend, LegendMark, NumeralAxis } from 'components/organisms/charts/parts'
import Segment from 'components/atoms/Segment/Segment.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'

import { useTouchablePanel } from 'components/organisms/charts/hooks'

import { ReturnInfo } from 'components/organisms/ReturnInfo'

import './IncomeHistoryChart.css'

const IncomeHistoryChart = ({
  className,
  data: rawData,
  empty,
  defaultMax,
  hoverable = true,
  isPlaceholderDisplayed,
  handleAddFundsClick,
} = {}) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries
  const { max, paid_out_sum, data, withFloat } = transformIncomeHistoryData(rawData, defaultMax)
  const isEmpty = empty && (data.length === 0 || (data.length === 1 && data[0].value === 0))
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const formattedHoveredData = useMemo(() => {
    const hoveredData = data[hoveredIndex]

    if (!hoveredData) {
      return null
    }

    const date = formatDate(hoveredData.date, 'MMMM YYYY')

    if (typeof hoveredData.value === 'number') {
      return (
        <ColumnarLayout mods={{ padding: 'small' }}>
          <Text block small noWrap>
            {date}
          </Text>
          <Text block right small noWrap>
            {formatMoney(hoveredData.value, true)}
          </Text>
        </ColumnarLayout>
      )
    }

    return (
      <Fragment>
        <Segment mods={{ margin: 'smaller', noMargin: 'top' }}>
          <Text block small noWrap>
            {date}
          </Text>
        </Segment>
        <Segment mods={{ margin: 'smaller', noMargin: 'bottom' }}>
          <ColumnarLayout mods={{ padding: 'small' }}>
            <Text block small noWrap>
              Paid
            </Text>
            <Text block right small noWrap>
              {formatMoney(hoveredData.value[0], true)}
            </Text>
          </ColumnarLayout>
          <ColumnarLayout mods={{ padding: 'small' }}>
            <Text block small noWrap>
              To be paid
            </Text>
            <Text block right small noWrap>
              {formatMoney(hoveredData.value[1], true)}
            </Text>
          </ColumnarLayout>
        </Segment>
      </Fragment>
    )
  }, [hoveredIndex, data])

  useEffect(() => {
    if (!desktop) {
      setHoveredIndex(null)
    }
  }, [desktop, setHoveredIndex])

  const barsRef = useRef(null)

  const handleEnter = useCallback((_event, index) => hoverable && setHoveredIndex(index), [setHoveredIndex, hoverable])
  const handleLeave = useCallback((_event) => hoverable && setHoveredIndex(null), [setHoveredIndex, hoverable])
  const { rootRef } = useTouchablePanel({ handleEnter, handleLeave, containers: [barsRef.current] })
  const classes = classNames(className, 'IncomeHistoryChart', getMediaQuieryClasses('IncomeHistoryChart', mediaQueries))

  if (isPlaceholderDisplayed)
    return (
      <div className={classes}>
        <Paper bottom={desktop ? 32 : 24}>
          <ReturnInfo
            title="Income paid"
            price={paid_out_sum}
            tooltipText="The total income paid by this InvestEngine portfolio over the selected time period."
            tooltipOffset={-30}
            data-test-id="incomePaid"
          />
        </Paper>
        <GraphPlaceholder linkText="Add funds" onLinkClick={handleAddFundsClick} />
      </div>
    )

  return (
    <div className={classes} data-test-id="incomeHistoryChart">
      <Paper bottom={desktop ? 32 : 8}>
        <ReturnInfo
          title="Income paid"
          price={paid_out_sum}
          tooltipText="The total income paid by this InvestEngine portfolio over the selected time period."
          tooltipOffset={-30}
          data-test-id="incomePaid"
        />
      </Paper>

      <Legend flex={desktop} position="top" className="IncomeHistoryChart-Legend">
        {(() => {
          const legendMarks = (
            <ColumnarLayout className="ColumnarLayout_ieInlineBlock" inline={desktop}>
              <Column size={desktop ? 0 : 1}>
                <Text noWrap left={!desktop}>
                  <LegendMark color={palette['primary-default']} text="Paid" />
                </Text>
              </Column>
              <Column size={desktop ? 0 : 1}>
                <Text noWrap right={!desktop}>
                  <ColumnarLayout mods={{ padding: 'nano' }} inline>
                    <Column size={1}>
                      <LegendMark color={palette['status-success']} text="To be paid" />
                    </Column>
                    <Column className="ColumnarLayout-Column_ieTooltip" size={1}>
                      <TooltipToModal
                        className={classNames(
                          'IncomeHistoryChart-Tooltip',
                          getMediaQuieryClasses('IncomeHistoryChart-Tooltip', mediaQueries),
                        )}
                        description="Portfolio income received by InvestEngine which we have yet to pay out to your nominated bank account. We generally pay out any income received during a calendar month in the first 5 working days of the following month. If you request a capital withdrawal, that withdrawal will include the latest income earned — so the income may be paid earlier than usual."
                        data-test-id="IncomeHistoryChartTooltip"
                      >
                        <Icon
                          size={18}
                          type="questionInCircle"
                          color={palette['content-on-background-primary']}
                          inline
                        />
                      </TooltipToModal>
                    </Column>
                  </ColumnarLayout>
                </Text>
              </Column>
            </ColumnarLayout>
          )

          if (desktop) {
            return (
              <Fragment>
                <Text block right>
                  {legendMarks}
                </Text>
              </Fragment>
            )
          }

          return (
            <Fragment>
              <Segment mods={{ margin: 'smaller', noMargin: 'bottom' }}>{legendMarks}</Segment>
            </Fragment>
          )
        })()}
      </Legend>

      <div className="IncomeHistoryChart-Chart" ref={rootRef}>
        <Chart size="medium">
          <NumeralAxis min={0} max={max} quantity={isEmpty ? 1 : 5} format={(value) => formatMoney(value, withFloat)} />
          <IncomeBars
            ref={barsRef}
            max={max}
            data={data}
            selectedIndex={hoveredIndex}
            onTickEnter={handleEnter}
            onTickLeave={handleLeave}
          />
          {isEmpty && <AllCenter className="IncomeHistoryChart-Empty">{empty}</AllCenter>}
          {!isEmpty && (
            <ChartTooltip visible={Boolean(formattedHoveredData)} pointerTransparent>
              {formattedHoveredData}
            </ChartTooltip>
          )}
        </Chart>
      </div>
    </div>
  )
}

IncomeHistoryChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      paid_out: PropTypes.string,
      collected: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  empty: PropTypes.node.isRequired,
  defaultMax: PropTypes.number,
  hoverable: PropTypes.bool,
  isPlaceholderDisplayed: PropTypes.bool,
  handleAddFundsClick: PropTypes.func,
}

export { IncomeHistoryChart }
