import React from 'react'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { presetTypes } from 'constants/goal'

import './Notice.css'

type ProjectionsProps = {
  presetType: string
  isFullWidth?: boolean
}

const Notice = ({ presetType, isFullWidth }: ProjectionsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const isIncomePortfolio = presetType === presetTypes.INCOME

  const text = isIncomePortfolio
    ? 'Below is the income return your portfolio could achieve. You can change your projected returns by adding a one‑off deposit or using the slider to adjust the level of risk. Once received in your portfolio, income is paid out to your nominated bank account at the beginning of the following month, unless you instruct us otherwise.'
    : 'The tool below provides illustrations of the performance your portfolio could achieve. You can use it to see how changing the amount you invest, your portfolio’s risk level, and time horizon, could alter your returns. To apply changes to your actual portfolio click on the Apply changes button below.'

  if (isFullWidth) {
    return (
      <Card className="ProjectionsNotice" level={-1}>
        <Paper top={20} bottom={20} right={desktop ? 32 : 16} left={desktop ? 32 : 16}>
          <Typography size={14}>
            <Typo>{text}</Typo>
          </Typography>
        </Paper>
      </Card>
    )
  }

  return (
    <Card className="ProjectionsNotice" level={-1}>
      <Width size={49.5} center>
        <Paper top={16} bottom={16} right={desktop ? 0 : 16} left={desktop ? 0 : 16}>
          <Typography size={14} align={desktop ? 'center' : 'left'}>
            <Typo>{text}</Typo>
          </Typography>
        </Paper>
      </Width>
    </Card>
  )
}

export { Notice }
