import React, { Fragment } from 'react'

import { symbols } from 'helpers/typograph'

import { Skeleton } from 'components/atoms/Skeleton'

type RoundedSecuritiesTitleProps = {
  roundedSecuritiesCount: number
}

function RoundedSecuritiesTitle({ roundedSecuritiesCount }: RoundedSecuritiesTitleProps): React.ReactElement {
  const count = roundedSecuritiesCount === 0 ? '000' : roundedSecuritiesCount
  const skeletonShown = roundedSecuritiesCount === 0

  return (
    <Skeleton tag="span" shown={skeletonShown} shownProps={{ role: 'presentation' }} inline>
      <Fragment>
        Choose{symbols.nbsp}from{symbols.sp}
        <Fragment>{count}+</Fragment>
        {symbols.nbsp}ETFs
      </Fragment>
    </Skeleton>
  )
}

export { RoundedSecuritiesTitle, type RoundedSecuritiesTitleProps }
