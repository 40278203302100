import 'broadcastchannel-polyfill'

import { useEffect, useRef, useState, useCallback, useActions } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { fetchClient as fetchClientActionCreator } from 'app/redux/actions/client' // arbitrary request for triggering token lifetime refresh

const secondsToLogout = 60
const gap = (secondsToLogout + 10) * 1000
const sessionLifetime = 30 * 60 * 1000 - gap

type UseSessionTimeoutInterface = {
  isOpen: boolean
  countdown: string
  handleLogout: () => void
  handleStay: () => void
}

const useSessionTimeout = (hasAuth: boolean, pathname: string): UseSessionTimeoutInterface => {
  const sessionTimerId = useRef<NodeJS.Timeout | null>(null)
  const countdownTimerId = useRef<NodeJS.Timeout | null>(null)
  const broadcast = useRef<BroadcastChannel | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [countdown, setCountdown] = useState(secondsToLogout)
  const [lastRequestTime, setLastRequestTime] = useState<number | null>(null)

  const fetchClient = useActions(fetchClientActionCreator)

  const startSessionTimer = useCallback(
    (sessionLifetime) => {
      if (!hasAuth) {
        return
      }

      if (sessionTimerId.current) {
        clearTimeout(sessionTimerId.current)
      }

      sessionTimerId.current = setTimeout(() => {
        setIsOpen(true)
      }, sessionLifetime)
    },
    [hasAuth],
  )

  const redirectToLogin = useCallback(() => {
    const loginUrl = urlTo('login', null, { sessionExpired: true })
    goTo(urlTo('logout', null, { next: loginUrl }), { replace: true })
  }, [])

  const handleTimeout = useCallback(() => {
    setIsOpen(false)

    if (broadcast.current) {
      broadcast.current.postMessage({ type: 'timeout' })
    }

    redirectToLogin()
  }, [redirectToLogin])

  window.onfocus = useCallback(() => {
    if (!lastRequestTime) return
    const now = new Date().getTime()
    const remainingTime = lastRequestTime + sessionLifetime + gap - now

    if (remainingTime > gap) {
      startSessionTimer(remainingTime - gap)
      return
    }

    if (remainingTime < 0) {
      handleTimeout()
      return
    }

    const remainingSeconds = Math.floor(remainingTime / 1000)
    const counter = remainingSeconds > secondsToLogout ? secondsToLogout : remainingSeconds
    setCountdown(counter)
    setIsOpen(true)
  }, [lastRequestTime, handleTimeout, startSessionTimer])

  useEffect(() => {
    if (!isOpen) return
    countdownTimerId.current = setInterval(() => {
      setCountdown((counter) => counter - 1)
    }, 1000)
    return () => {
      setCountdown(secondsToLogout)

      if (countdownTimerId.current) {
        clearInterval(countdownTimerId.current)
      }
    }
  }, [isOpen])

  useEffect(() => {
    isOpen && handleStay()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    !countdown && handleTimeout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown])

  useEffect(() => {
    !hasAuth && sessionTimerId.current && clearTimeout(sessionTimerId.current)
  }, [hasAuth])

  useEffect(() => {
    broadcast.current = new BroadcastChannel('session')

    if (broadcast.current) {
      broadcast.current.onmessage = ({ data }) => {
        data.type === 'refresh' && refreshSession()
        data.type === 'logout' && handleLogout()
        data.type === 'timeout' && redirectToLogin()
      }
    }

    window.refreshSessionLifetime = () => {
      refreshSession()

      if (broadcast.current) {
        broadcast.current.postMessage({ type: 'refresh' })
      }
    }

    return () => {
      delete window.refreshSessionLifetime

      if (broadcast.current) {
        broadcast.current.close()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshSession = useCallback(() => {
    setIsOpen(false)
    setLastRequestTime(new Date().getTime())
    startSessionTimer(sessionLifetime)
  }, [startSessionTimer])

  const handleLogout = useCallback(() => {
    setIsOpen(false)

    if (broadcast.current) {
      broadcast.current.postMessage({ type: 'logout' })
    }

    goTo(urlTo('logout'), { replace: true })
  }, [])

  const handleStay = useCallback(() => {
    setIsOpen(false)
    fetchClient(null, false, false)
  }, [fetchClient])

  const countdownText = countdown < 10 ? `00:0${countdown}` : `00:${countdown}`

  return {
    isOpen,
    countdown: countdownText,
    handleLogout,
    handleStay,
  }
}

export { useSessionTimeout }
