import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

import { SecurityOrderList } from './SecurityOrder'

/**
 * @class
 * @typedef {Object} PendingOrder
 */
export class PendingOrder extends Entity {
  constructor() {
    super()

    /** @type {?number} */
    this.id = null
    /** @type {?number} */
    this.portfolio_id = null
    /** @type {?'PORTFOLIO'|?'SECURITY'} */
    this.scope = null
    /** @type {?'BUY'|?'SELL'|?'REBALANCE'|?'AUTOINVEST'} */
    this.type = null
    /** @type {?'SUBMITTED'|?'CANCELLED'|?'NOT_SUBMITTED'} */
    this.state = null
    /** @type {?string} */
    this.price_dt = null
    /** @type {?number} */
    this.amount = null
    /** @type {SecurityOrderList} */
    this.securities = new SecurityOrderList()
  }

  /** @override */
  getRules() {
    return {
      id: [[rules.int]],
      portfolio_id: [[rules.int]],
      scope: [[rules.string]],
      type: [[rules.string]],
      state: [[rules.string]],
      price_dt: [[rules.iso8601UTC]],
      amount: [[rules.float]],
      securities: [[(items) => new SecurityOrderList(...(items || []))]],
    }
  }

  /**
   * @param {'SUBMITTED'|'CANCELLED'|'NOT_SUBMITTED'} state
   */
  changeStateTo(state) {
    return PendingOrder.createFromObject({ ...this.toObject(), state })
  }
}
