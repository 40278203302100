import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { SECURITY_UNIVERSE_VERSION } from '../hooks/'
import { useMemo, useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { MobileHeader } from 'app/containers/Header'

import { Typo } from 'components/_old/Typo/Typo'

import { GatewayDest } from 'components/atoms/Gateway'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { TunnelHeader } from 'app/pages/Securities/components'
import { Typography } from 'components/atoms/Typography'

import { RoundedSecuritiesTitle } from '../components/RoundedSecuritiesTitle'

const Mobile = ({
  addSecuritiesMobileFooterNode,
  allEtfsNode,
  discoverNode,
  filtersPanelNode,
  handleBack,
  handleCancel,
  handleFiltersClear,
  handleSearch,
  isDiscoveryDisplayed,
  isDiscoveryVisible,
  location,
  recentlyViewedEtfsNode,
  roundedSecuritiesCount,
  scrollableElementRef,
  searchBarNode,
  tabsNode,
  version,
}) => {
  const navigationBar = useMemo(
    () => (
      <NavigationBar
        leftPartText="Back"
        onLeftPartClick={location?.query?.back ? handleBack : null}
        rightPartText="Cancel"
        onRightPartClick={handleCancel}
      >
        {isDiscoveryVisible ? '' : <RoundedSecuritiesTitle roundedSecuritiesCount={roundedSecuritiesCount} />}
      </NavigationBar>
    ),
    [handleCancel, isDiscoveryVisible, roundedSecuritiesCount],
  )

  const header = useMemo(() => {
    return version === SECURITY_UNIVERSE_VERSION.PRIVATE ? <MobileHeader /> : navigationBar
  }, [navigationBar, version])

  const footer = useMemo(() => {
    if (version === SECURITY_UNIVERSE_VERSION.INSIDE_PORTFOLIO) {
      return <Paper top={16}>{addSecuritiesMobileFooterNode}</Paper>
    }

    return <Paper top={16} />
  }, [addSecuritiesMobileFooterNode, version])

  const handleBackClick = useCallback(() => {
    const { fromUniverse, search, asset_class, provider, dividends_type, hedged, esg, ordering, ...restQuery } =
      location.query

    handleSearch('')
    handleFiltersClear()
    goTo(urlTo('securities', null, restQuery))
  }, [location.query, handleSearch, handleFiltersClear])

  const newTitle = (
    <Fragment>
      {!isDiscoveryDisplayed && <TunnelHeader onBack={handleBackClick} />}
      <Paper top={24}>
        <Typography size={28} weight="semibold" lineHeight="small">
          <Typo>Browse investments</Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  const content = useMemo(
    () => (
      <Fragment>
        {<Paper bottom={32}>{newTitle}</Paper>}
        <Paper bottom={12}>{searchBarNode}</Paper>
        <Paper bottom={32}>{filtersPanelNode}</Paper>
        {isDiscoveryDisplayed ? discoverNode : allEtfsNode}
        {recentlyViewedEtfsNode}
      </Fragment>
    ),
    [
      newTitle,
      searchBarNode,
      filtersPanelNode,
      recentlyViewedEtfsNode,
      isDiscoveryVisible,
      isDiscoveryDisplayed,
      discoverNode,
      allEtfsNode,
    ],
  )

  return (
    <Fragment>
      <MobileLayout ref={scrollableElementRef} header={header} content={content} footer={footer} />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

Mobile.propTypes = {
  addSecuritiesMobileFooterNode: PropTypes.node,
  allEtfsNode: PropTypes.node,
  discoverNode: PropTypes.node,
  filtersPanelNode: PropTypes.node,
  handleBack: PropTypes.func,
  handleCancel: PropTypes.func,
  isDiscoveryDisplayed: PropTypes.bool,
  isDiscoveryVisible: PropTypes.bool,
  location: PropTypes.object,
  navigateByTab: PropTypes.func,
  recentlyViewedEtfsNode: PropTypes.node,
  roundedSecuritiesCount: PropTypes.number,
  scrollableElementRef: PropTypes.object,
  searchBarNode: PropTypes.node,
  version: PropTypes.string,
}

export { Mobile }
