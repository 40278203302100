import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { Preloader } from 'components/molecules/Preloader'

import { Desktop } from './Desktop'
import { useDisableTwofactorAuth } from './hooks/useDisableTwoFactorAuth'
import { Mobile } from './Mobile'

const DisableTwoFactorAuth = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { isLoading, handleBack, handleContinue } = useDisableTwofactorAuth()

  const props = {
    handleBack,
    handleContinue,
  }

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}

      <Preloader loading={isLoading} size="big" zIndex />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { DisableTwoFactorAuth }
