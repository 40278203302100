import { attach } from 'effector'
import { useUnit } from 'effector-react'
import isNil from 'lodash/isNil'

import { useEffect, useCallback } from 'hooks'

import { roundDecimals, unformat, format as formatMoney } from 'helpers/money'

import { createPendingOrderFx, $isLoading } from 'app/effector/pending-orders'

import { showFailToast } from 'app/redux/actions/ui'

import { useForm } from 'components/atoms/Forms/hooks'

import { type as pendingOrderType } from 'constants/pendingOrder.js'

const sellOrderFx = attach({ effect: createPendingOrderFx })

sellOrderFx.fail.watch(() => {
  showFailToast()
})

const useSell = ({ portfolio, onAfterSell, security }) => {
  const isLoading = useUnit($isLoading)

  const {
    control,
    errors,
    isValid,
    handleSubmit: handleFormSubmit,
    setValue,
  } = useForm({
    amount: undefined,
  })

  // Set up the done handler
  useEffect(() => {
    const doneSubscription = sellOrderFx.done.watch(({ result: createdOrder }) => {
      if (createdOrder) {
        onAfterSell(createdOrder.id)
      }
    })

    return () => {
      doneSubscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isSecuritySelling = !isNil(security)
  const maxValue = parseFloat(isSecuritySelling ? security?.available_value : portfolio?.available_securities_value)

  const validation = {
    required: {
      value: true,
      message: 'Amount can’t be empty',
    },
    validate: (value) => {
      const unformatValue = unformat(value, true)
      const roundedMaxValue = roundDecimals(maxValue, 2)

      if (unformatValue > roundedMaxValue) {
        return 'Not enough in your portfolio'
      }

      if (unformatValue !== maxValue && unformatValue < 1) {
        return `Must be at least ${formatMoney(1)}`
      }

      const isFullSell = (minPrice) => unformatValue < roundedMaxValue && roundedMaxValue - unformatValue < minPrice

      if (isFullSell(roundedMaxValue * 0.01)) {
        return 'Partial sales which may result in very small holdings are restricted. Please amend or Sell all.'
      }

      return true
    },
  }

  const handleSetAllValue = useCallback(() => {
    if (!isNil(maxValue)) {
      setValue('amount', formatMoney(maxValue, true), {
        shouldValidate: true,
        shouldDirty: true,
      })
    }
  }, [setValue, maxValue])

  const sell = handleFormSubmit((values) => {
    sellOrderFx({
      type: pendingOrderType.SELL,
      portfolio_id: portfolio?.id,
      security_id: security?.id,
      amount: values.amount ? unformat(values.amount, true) : undefined,
    })
  })

  return {
    isLoading,

    isValid,
    validation,
    control,
    errors,

    sell,
    handleSetAllValue,
  }
}

export { useSell }
