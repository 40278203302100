import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { Preloader } from 'components/molecules/Preloader'

import { Desktop } from './Desktop'
import { useDisablePassword } from './hooks/useDisablePassword'
import { Mobile } from './Mobile'

type PasswordStepProps = {
  password: string
  validation: object
  handleBack: () => void
  handlePasswordChange: (event: React.ChangeEvent, password: string) => void
  handleContinue: () => Promise<void>
}

const DisableTwoFactorAuthPassword = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { isLoading, password, validation, handleBack, handlePasswordChange, handleContinue } = useDisablePassword()

  const props = {
    password,
    validation,
    handleBack,
    handlePasswordChange,
    handleContinue,
  }

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}

      <Preloader loading={isLoading} size="big" zIndex />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { DisableTwoFactorAuthPassword, type PasswordStepProps }
