import React from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'
import { formatMinimalPercent } from 'helpers/money'

import { Paper } from 'components/atoms/Paper'
import Width from 'components/_old/Width/Width'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Typography } from 'components/atoms/Typography'
import { Nobr } from 'components/_old/Nobr'
import { PercentageBar } from 'components/atoms/PercentageBar'

const PercentageWeight = ({
  actual,
  target,
  currentTarget,
  color,
  width,
  spacing = 8,
  fractionalLength = 1,
  showTarget,
  chartValue = actual,
  showCurrentTarget = false,
}) => {
  const actualWeightPercent = formatMinimalPercent(actual, { fractionalLength })
  const targetWeightPercent = formatMinimalPercent(target, { fractionalLength, returnDashIfNotNumberPassed: true })
  const currentTargetweightPercent = formatMinimalPercent(currentTarget, {
    fractionalLength,
    returnDashIfNotNumberPassed: true,
  })

  const actualTargetPercentsNode = useMemo(
    () => (
      <ColumnarLayout mods={{ padding: 'no' }} inline>
        <Column size={0}>
          <Typography
            tag="span"
            size={14}
            lineHeight="small"
            weight="regular"
            numeric="tabular"
            data-test-id="actualWeightPercent"
          >
            <Nobr>{actualWeightPercent}</Nobr>
          </Typography>
        </Column>
        {showTarget && (
          <Column size={0}>
            <Typography
              tag="span"
              size={12}
              lineHeight="small"
              color="minor"
              numeric="tabular"
              data-test-id="targetWeightPercent"
            >
              <Nobr>
                &nbsp;/&nbsp;
                {targetWeightPercent}
              </Nobr>
            </Typography>
          </Column>
        )}
      </ColumnarLayout>
    ),
    [showTarget, actualWeightPercent, targetWeightPercent],
  )

  const newTargetPercentsNode = useMemo(
    () =>
      showCurrentTarget && (
        <div>
          <Typography tag="span" size={14} lineHeight="small" weight="regular" numeric="tabular" color="minor">
            <Nobr>{currentTargetweightPercent} </Nobr>
          </Typography>
          <Typography tag="span" size={14} lineHeight="small" weight="regular" numeric="tabular">
            <Nobr>
              {'→ '}
              {targetWeightPercent}
            </Nobr>
          </Typography>
        </div>
      ),
    [showCurrentTarget, targetWeightPercent, currentTargetweightPercent],
  )

  return (
    <Width size={width}>
      <Paper>
        <Typography size={14} lineHeight="small" align="right">
          {showCurrentTarget ? newTargetPercentsNode : actualTargetPercentsNode}
        </Typography>
      </Paper>
      <Paper top={spacing}>
        <PercentageBar amount={chartValue} color={color} />
      </Paper>
    </Width>
  )
}

PercentageWeight.propTypes = {
  actual: PropTypes.number.isRequired,
  target: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentTarget: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.number,
  spacing: PropTypes.number,
  fractionalLength: PropTypes.number,
  showTarget: PropTypes.bool,
  showCurrentTarget: PropTypes.bool,
  chartValue: PropTypes.number,
}

export { PercentageWeight }
