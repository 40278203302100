import React from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries, useRef, useState, useCallback, useClickOutside, useEffect } from 'hooks'

import { Gateway } from 'components/atoms/Gateway'

import { Modal } from 'components/molecules/Modal'

import { DateRangeDropdown } from './DateRangeDropdown'
import { FilterButton, type FilterButtonHandle } from './FilterButton'
import { FilterDropdown, type FilterDropdownHandle } from './FilterDropdown'

import './Filter.css'

enum Appearance {
  DEFAULT = 'default',
  BLUE = 'blue',
}

const dropdownComponents = {
  default: FilterDropdown,
  dateRange: DateRangeDropdown,
}

type FilterType = 'checkbox' | 'radio' | 'select'

type FilterValue = {
  name: string | React.ReactElement
  value: unknown
  selectedName?: string | React.ReactElement
  description?: React.ReactNode
  showDescriptionInTooltip?: boolean
  isDefault?: boolean
}

type FilterProps = {
  type: FilterType
  title?: string
  name: string
  values: FilterValue[]
  selected: Array<string | number>
  description?: React.ReactNode
  disabled?: boolean
  appearance?: Appearance
  dropdown?: 'default' | 'dateRange'
  dropdownMaxHeight?: number
  align?: 'center' | 'flex-start' | 'flex-end'
  showDescriptionInTooltip?: boolean
  onChange?: (nextValue: Array<string | number | null>) => void
  'data-test-id'?: string
  noOverflowInDropdown?: boolean
}

const Filter = (props: FilterProps): React.ReactElement => {
  const {
    title,
    selected,
    appearance,
    values,
    dropdown = 'default',
    dropdownMaxHeight,
    noOverflowInDropdown = false,
    align = 'flex-start',
    onChange: handleChange,
    'data-test-id': dataTestId,
  } = props
  const { desktop } = useMediaQueries()
  const [open, setOpen] = useState(false)
  const [rootRef, hasClickedOutside, setClickedOutsideState] = useClickOutside<HTMLDivElement>()
  const filterButtonRef = useRef<FilterButtonHandle>(null)
  const filterDropdownRef = useRef<FilterDropdownHandle>(null)
  const [sessionSelected, setSessionSelected] = useState(selected)

  const resetSessionSelected = useCallback(() => {
    setSessionSelected(selected)
  }, [selected, setSessionSelected])

  const handleClose = useCallback(() => {
    setOpen(false)
    filterButtonRef.current?.focusFilterButton?.()
  }, [setOpen, filterButtonRef])

  const handleOpen = useCallback(() => {
    resetSessionSelected()
    setClickedOutsideState(false)
    setOpen(true)
    filterDropdownRef.current?.focusListBoxItem?.()
  }, [resetSessionSelected, setOpen, filterDropdownRef, setClickedOutsideState])

  const handleSoftReset = useCallback(() => {
    setSessionSelected([])
  }, [])

  const handleHardReset = useCallback(() => {
    handleChange?.([])
    handleClose()
  }, [handleChange, handleClose])

  const handleSelect = useCallback(
    (nextSelected: Array<string | number>) => {
      setSessionSelected(nextSelected)
    },
    [setSessionSelected],
  )

  const handleApply = useCallback(
    (event, value) => {
      handleChange?.(value ?? sessionSelected)
      handleClose()
      filterButtonRef.current?.focusFilterButton?.()
    },
    [handleChange, handleClose, filterButtonRef, sessionSelected],
  )

  const handleKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        handleClose()
      }
    },
    [handleClose],
  )

  useEffect(() => {
    if (desktop && hasClickedOutside && open) {
      handleClose()
    }
  }, [hasClickedOutside, handleClose, open, desktop])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboard)

    return () => {
      document.removeEventListener('keydown', handleKeyboard)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const classes = classNames('Filter', {
    Filter_open: open,
    Filter_desktop: desktop,
    Filter_blue: appearance === Appearance.BLUE,
  })

  const DropdownComponent = dropdownComponents[dropdown]

  return (
    <div ref={rootRef} className={classes}>
      <FilterButton
        ref={filterButtonRef}
        {...props}
        disabled={!values.length}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onHardReset={handleHardReset}
        data-test-id={dataTestId ? `${dataTestId}Button` : undefined}
      />
      {desktop && (
        <DropdownComponent
          ref={filterDropdownRef}
          className={classNames('Filter-Dropdown', { 'Filter-Dropdown--no-overflow': noOverflowInDropdown })}
          dropdownMaxHeight={dropdownMaxHeight}
          {...props}
          open={open}
          align={align}
          selected={sessionSelected}
          onClose={handleClose}
          onSelect={handleSelect}
          onApply={handleApply}
          onSoftReset={handleSoftReset}
          data-test-id={dataTestId ? `${dataTestId}Dropdown` : undefined}
        />
      )}
      {!desktop && (
        <Gateway into="modals">
          <Modal className="Filter-DropdownModal" open={open}>
            <DropdownComponent
              title={title}
              ref={filterDropdownRef}
              className="Filter-Dropdown"
              {...props}
              open={open}
              align={align}
              selected={sessionSelected}
              onClose={handleClose}
              onSelect={handleSelect}
              onApply={handleApply}
              onSoftReset={handleSoftReset}
            />
          </Modal>
        </Gateway>
      )}
    </div>
  )
}

export { Filter, type FilterType, type FilterValue, type FilterProps, Appearance }
