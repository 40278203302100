import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Label, { LabelText, LabelField } from 'components/_old/Label/Label.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

import { useCaptcha } from './useCaptcha'

type CaptchaProps = {
  onSuccess: () => void
  token: string
  loginIdentifier?: string
  clientId?: string
}

const Captcha = ({ onSuccess: handleSuccess, token, loginIdentifier, clientId }: CaptchaProps): React.ReactElement => {
  const { validation, handleCaptchaChange } = useCaptcha({ handleSuccess, loginIdentifier, clientId })

  return (
    <Validate rules={validation.captcha.rules}>
      <Label errorMessages={validation.captcha.errors}>
        <LabelText>&nbsp;</LabelText>
        <LabelField>
          <AllCenter>
            <ReCAPTCHA
              sitekey={token}
              onChange={handleCaptchaChange}
              onExpired={() => {
                window.location.reload()
              }}
            />
          </AllCenter>
        </LabelField>
      </Label>
    </Validate>
  )
}

export { Captcha }
