import update from 'react-addons-update'

import filter from 'lodash/filter'
import without from 'lodash/without'

import {
  SET_NOT_VALID,
  SET_VALID,
  RECEIVE_ERROR,
  RESET_ERROR,
  RECEIVE_TOAST,
  CHANGE_TOASTS_VISIBILITY,
  REMOVE_TOAST,
  REMOVE_TOASTS_WITH_STYLE,
  CHANGE_MODAL_VISIBILITY,
  CHANGE_MODAL_FIELD,
  CHANGE_FIELD,
  RECEIVE_FLATPAGE_SECTION,
  UPDATE_NOTIFICATION_SETTINGS,
} from 'app/redux/actions/ui/uiActionTypes.js'

import { GP_DEFAULT } from 'constants/globalPreloaderStatuses.js'

export const initialState = {
  didInvalidate: false,
  error: null,
  toastsVisibility: true,
  toasts: [],
  fields: {
    globalPreloaderStatus: GP_DEFAULT,
    failedPingAttempts: 0,

    agree_with_terms_and_conditions: false,

    selected_asset: null,
    portfolio_details_shown: false,

    email: null,
    new_password1: null,
    new_password2: null,

    pin: null,
    last_access: {},
    pin_access_granted: null,

    requisites: {},

    documents_filter: {
      year: null,
      type: null,
    },

    transactions_filter: {
      year: null,
      goal: null,
    },

    contactUsMessage: '',
  },
  modals: {
    changePassword: {
      old_password: null,
      new_password1: null,
      new_password2: null,
    },
    changeEmail: {
      email: null,
    },
    wireTransfer: {
      amount: null,
    },
    addFunds: {
      amount: null,
    },
    directDebit: {
      monthlyPaymentAmount: null,
      monthlyPaymentDay: null,
      confirmCheckbox: false,
    },
    supportModal: {
      visible: false,
      email: '',
      name: '',
      message: '',
    },
    attentionModal: {
      visible: false,
    },
    learnMore: {
      visible: false,
    },
  },
  flatpage_sections: {
    'inner-support': [],
  },
  settings: {
    notifications: {
      system: null,
      promo: null,
    },
  },
}

function getFieldsSetOperation(field) {
  const op = {}
  Object.keys(field).forEach((k) => {
    op[k] = { $set: field[k] }
  })
  return op
}

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NOT_VALID:
      return Object.assign({}, state, { didInvalidate: true })

    case SET_VALID:
      return Object.assign({}, state, { didInvalidate: false })

    case RECEIVE_ERROR:
      return Object.assign({}, state, { error: action.error })

    case RESET_ERROR:
      return Object.assign({}, state, { error: null })

    case RECEIVE_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, { message: action.message, style: action.style, uid: action.uid }],
      }

    case CHANGE_TOASTS_VISIBILITY:
      return {
        ...state,
        toastsVisibility: action.visible,
      }

    case REMOVE_TOAST:
      return {
        ...state,
        toasts: without(state.toasts, filter(state.toasts, { uid: action.uid })[0]),
      }

    case REMOVE_TOASTS_WITH_STYLE:
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.style !== action.style),
      }

    case CHANGE_MODAL_VISIBILITY:
      return update(state, {
        modals: { [action.modal]: { visible: { $set: action.visible } } },
      })

    case CHANGE_MODAL_FIELD:
      return update(state, {
        modals: { [action.modal]: getFieldsSetOperation(action.field) },
      })

    case CHANGE_FIELD:
      return update(state, { fields: getFieldsSetOperation(action.field) })

    case RECEIVE_FLATPAGE_SECTION:
      return update(state, {
        flatpage_sections: { $merge: { [action.section]: action.data } },
      })

    case UPDATE_NOTIFICATION_SETTINGS: {
      return update(state, {
        settings: {
          $merge: update(state.settings, { notifications: { $merge: { [action.notificationType]: action.enabled } } }),
        },
      })
    }

    default:
      return state
  }
}
