import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { Document } from 'components/molecules/Document'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

const SecurityDocuments = ({ documents }) => {
  const { desktop } = useMediaQueries()

  const documentList = documents.map((document, index) => (
    <Document key={index} name={document.title} src={document.uri} />
  ))

  return desktop ? documentList : <SideSpacesCompensator>{documentList}</SideSpacesCompensator>
}

SecurityDocuments.propTypes = {
  documents: PropTypes.array,
}

export { SecurityDocuments }
