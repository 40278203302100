import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import moment from 'helpers/date.js'
import { features } from 'helpers/features'
import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import { useUnit } from 'effector-react'
import { useCallback, useMediaQueries, useMemo, useSelector } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'
import { Badge } from 'components/atoms/Badge'
import { Skeleton } from 'components/atoms/Skeleton'
import { CardAccent } from 'components/atoms/CardAccent'
import { Typography } from 'components/atoms/Typography'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Profit } from 'components/molecules/Profit'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'
import { PortfolioManagedBy } from 'components/molecules/PortfolioManagedBy/PortfolioManagedBy'
import { SavingPlanBadge } from '../SavingPlanBadge/SavingPlanBadge'

import { selectGoalTitle } from 'app/redux/selectors'

import { $recurringPaymentsStore } from 'app/effector/recurringPayments'
import { $quickStartsStore } from 'app/effector/quickStart'

import { manageTypes, portfolioColors } from 'constants/portfolio'
import { quickStartStatuses } from 'constants/quickStart'

import './PortfolioSelectableCard.css'

const PortfolioSelectableCard = ({
  isBusiness,
  portfolio,
  'data-test-id': dataTestId = '',
  highlightedRef,
  transferProgressData,
}) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const title = useSelector((state) => selectGoalTitle(state, portfolio.id))

  const { getReccuringPaymentByPortfolioId, getDirectDebitByPortfolioId } = useUnit($recurringPaymentsStore)
  const directDebitSubscription = getDirectDebitByPortfolioId(portfolio.id)
  const recurringPayment = getReccuringPaymentByPortfolioId(portfolio.id)
  const { getQuickStartByPortfolioId } = useUnit($quickStartsStore)
  const quickStart = getQuickStartByPortfolioId(portfolio.id)

  const hideSubtitle = portfolio.manage_type === manageTypes.CASH && isBusiness

  const isLumpSum = !quickStart?.frequency
  const shouldDisplaySavingPlanBadge =
    directDebitSubscription ?? recurringPayment ?? (quickStart?.status === quickStartStatuses.ACTIVE && !isLumpSum)

  const classes = classNames(
    'PortfolioSelectableCard',
    getMediaQuieryClasses('PortfolioSelectableCard', mediaQueries),
    {
      PortfolioSelectableCard_additionalPaddingBottom: desktop && !shouldDisplaySavingPlanBadge,
    },
  )

  const shouldDisplaysTransferProgressBadge =
    features.get('altus-progress-bar') && transferProgressData?.length > 0 && portfolio.manage_type === 'DIY'

  const balance = useMemo(
    () => <PoundsWithPence amount={portfolio.current_balance} showZeroPence={portfolio.current_balance > 0} />,
    [portfolio.current_balance],
  )

  const handleClick = useCallback(() => {
    trackEvent({
      action: 'portfolio_opened',
      manage_type: portfolio?.manage_type,
      portfolio_created_date: moment(portfolio?.created).format('YYYY-MM-DD'),
    })

    goTo(urlTo(`dashboard.portfolio`, { id: portfolio.id }))
  }, [portfolio])

  const performance = useMemo(() => {
    if (!portfolio.stats || !portfolio.first_topup || portfolio.manage_type === manageTypes.CASH) return null

    const amount = portfolio.stats.max?.return_money || 0
    const percent = portfolio.stats.max?.return_percent || 0

    return (
      <Typography size={14} align="right" lineHeight="small">
        <Nobr>
          <Profit amount={amount} percent={percent} showLessThanPointZeroOne noWrap data-test-id={dataTestId} />
        </Nobr>
      </Typography>
    )
  }, [portfolio, dataTestId])

  return (
    <Paper ref={highlightedRef}>
      <Skeleton
        shown={portfolio.skeleton}
        shownProps={dataTestId ? { 'data-test-id': `${dataTestId}-skeleton` } : null}
        mix
      >
        <SelectableCard
          className={classes}
          onClick={handleClick}
          data-test-id={dataTestId}
          data-portfolio-id={portfolio.id}
        >
          <CardAccent color={portfolioColors[portfolio.manage_type]} data-test-id={`${dataTestId}Accent`} />
          <Paper ref={highlightedRef} top={24} right={desktop ? 24 : 20} bottom={24} left={desktop ? 32 : 20}>
            <ColumnarLayout mods={{ padding: 'small' }}>
              <Column size={1}>
                <Typography
                  size={desktop ? 18 : 16}
                  lineHeight={desktop ? 'medium' : 'small'}
                  weight="semibold"
                  align="left"
                  data-test-id={`${dataTestId}Title`}
                >
                  <Typo allowHtml={false}>{title}</Typo>
                </Typography>
                {!hideSubtitle && (
                  <Paper top={4}>
                    <PortfolioManagedBy
                      regulatoryType={portfolio.regulatory_type}
                      manageType={portfolio.manage_type}
                      isDefaultPresetUsed={portfolio.is_default_preset_used}
                      data-test-id={`${dataTestId}Type`}
                    />
                  </Paper>
                )}
              </Column>
              <Column size={0}>
                <Typography
                  size={18}
                  lineHeight={desktop ? 'medium' : 'small'}
                  weight="semibold"
                  align="right"
                  data-test-id={`${dataTestId}Balance`}
                >
                  {balance}
                </Typography>
                {performance && <Paper top={4}>{performance}</Paper>}
              </Column>
            </ColumnarLayout>
            {shouldDisplaySavingPlanBadge && (
              <Paper top={24}>
                <SavingPlanBadge subscription={directDebitSubscription ?? recurringPayment} quickStart={quickStart} />
              </Paper>
            )}
            {shouldDisplaysTransferProgressBadge && (
              <Paper top={shouldDisplaySavingPlanBadge ? 12 : 24}>
                <Badge
                  theme="rounded-accent-default"
                  text={
                    transferProgressData.length === 1
                      ? 'ISA transfer in progress'
                      : `${transferProgressData.length} ISA transfers in progress`
                  }
                  data-test-id="transfersBadge"
                />
              </Paper>
            )}
          </Paper>
        </SelectableCard>
      </Skeleton>
    </Paper>
  )
}

PortfolioSelectableCard.propTypes = {
  isBusiness: PropTypes.bool,
  portfolio: PropTypes.object,
  'data-test-id': PropTypes.string,
  highlightedRef: PropTypes.object,
  transferProgressData: PropTypes.array,
}

export { PortfolioSelectableCard }
