import { useUnit } from 'effector-react'
import { type SdkResponse } from 'onfido-sdk-ui'

import { useActions, useCallback, useEffect, useRedirect, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'
import { features } from 'helpers/features'
import {
  startDocumentCheck,
  getDocumentCheckStatus,
  getDocumentCheckStatusOnce,
  IDCheckResult,
  IDCheckStatus,
  IDCheckProviders,
} from 'helpers/id-check'

import { $isLoading, fetchContactsFx } from 'app/effector/contacts'
import type { Contact } from 'app/effector/contacts/models'

import {
  setValid as setClientValidActionCreator,
  setNotValid as setClientNotValidActionCreator,
} from 'app/redux/actions/client'
import { changeField as changeUiFieldActionCreator } from 'app/redux/actions/ui'

import { GP_DEFAULT, GP_VERIFY_IDENTITY_SUCCESS, GP_VERIFYING_IDENTITY } from 'constants/globalPreloaderStatuses.js'

type UseProofOfIdentitySetupProps = {
  owner: Contact
  clientType: 'SME' | 'INDIVIDUAL'
  prevStep: {
    module: string
  }
  changeOwnerField: (key: string, value: unknown) => void
  handleAfterSubmit: (...args: unknown[]) => void
}

type UseProofOfIdentitySetupInterface = {
  provider: IDCheckProviders
  submitEnabled: boolean
  handleAfterUpload: (data: { key: string; value: unknown }) => void
  handleSubmit: (...args: unknown[]) => Promise<void>
}

function useProofOfIdentitySetup({
  owner,
  clientType,
  prevStep,
  changeOwnerField,
  handleAfterSubmit,
}: UseProofOfIdentitySetupProps): UseProofOfIdentitySetupInterface {
  const isOnFidoEnabled = features.get('onfido')
  const provider = isOnFidoEnabled ? IDCheckProviders.ONFIDO : IDCheckProviders.GBG
  const isContactsLoading = useUnit($isLoading)

  const [documentsUploadStatus, setDocumentsUploadStatus] = useState(null)

  const [changeUiField, setClientValid, setClientNotValid] = useActions([
    changeUiFieldActionCreator,
    setClientValidActionCreator,
    setClientNotValidActionCreator,
  ])

  const submitEnabled = isOnFidoEnabled
    ? (owner.document_driving_licence_filled || owner.document_passport_filled) && owner.document_selfie_filled
    : owner.document_passport_filled && owner.document_selfie_filled

  const checkDocumentsStatus = useCallback(async () => {
    const data = await getDocumentCheckStatusOnce(owner.id)

    setDocumentsUploadStatus(data?.status)
  }, [owner.id])

  useEffect(() => {
    if (isOnFidoEnabled) {
      checkDocumentsStatus()
    }
  }, [isOnFidoEnabled, checkDocumentsStatus])

  const handleAfterUpload = useCallback(
    (data: { key: string; value: unknown }) => {
      for (const [key, value] of Object.entries(data)) {
        changeOwnerField(key, value)
      }
    },
    [changeOwnerField],
  )

  const handleDocumentCheckResult = useCallback(
    (isPass: boolean) => {
      if (isPass) {
        changeUiField({ globalPreloaderStatus: GP_VERIFY_IDENTITY_SUCCESS })

        trackEvent(
          {
            action: 'document_verified',
            client_type: clientType,
          },
          {
            plugins: { 'google-analytics-v3': false },
          },
        )

        setTimeout(() => {
          setClientValid()
          changeUiField({ globalPreloaderStatus: GP_DEFAULT })
        }, 2000)
      }

      if (!isPass) {
        // Remove preloader on the next tick to make sure that unmount of the current screen unmounts correctly
        setTimeout(() => {
          changeUiField({ globalPreloaderStatus: GP_DEFAULT })
          setClientValid()
        }, 0)
      }

      return isPass
    },
    [clientType, changeUiField, setClientValid],
  )

  const passDocumentCheck = useCallback(
    async (contactId: number | string, onfidoResponse?: SdkResponse) => {
      try {
        changeUiField({ globalPreloaderStatus: GP_VERIFYING_IDENTITY })

        const documentCheckStatus = await getDocumentCheckStatus(contactId)
        await fetchContactsFx()

        if (onfidoResponse) {
          changeOwnerField(`document_${onfidoResponse.document_front?.type ?? 'driving_licence'}_filled`, true)
          changeOwnerField(`document_selfie_filled`, true)
        }

        return handleDocumentCheckResult(documentCheckStatus === IDCheckResult.PASS)
      } catch (error) {
        processError({ error, forceReset: true })
        await handleDocumentCheckResult(false)
      }
    },
    [changeUiField, handleDocumentCheckResult, changeOwnerField],
  )

  const handleSubmit = useCallback(
    async (...args) => {
      trackEvent(
        {
          action: 'document_uploaded',
          client_type: clientType,
        },
        {
          plugins: { 'google-analytics-v3': false },
        },
      )

      if (!isOnFidoEnabled) {
        handleAfterSubmit(...args)
        return
      }

      setClientNotValid()

      if (owner.id) {
        await startDocumentCheck(owner.id)
        await passDocumentCheck(owner.id, ...args)
      }

      handleAfterSubmit(...args)
    },
    [isOnFidoEnabled, handleAfterSubmit, clientType, passDocumentCheck, owner?.id, setClientNotValid],
  )

  useRedirect({
    to: prevStep?.module,
    rule: !owner.addresses?.isCurrentAddressFilled(),
    isLoading: isContactsLoading,
  })

  useRedirect({
    to: prevStep?.module,
    rule: documentsUploadStatus === IDCheckStatus.COMPLETE,
    isLoading: false,
  })

  return {
    provider,
    submitEnabled,
    handleAfterUpload,
    handleSubmit,
  }
}

export { useProofOfIdentitySetup }
