import { useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

const useNavbar = ({ portfolioId }) => {
  const handleBack = useCallback(() => {
    goTo(urlTo('dashboard.portfolio', { id: portfolioId }))
  }, [portfolioId])

  const handleSell = useCallback(() => {
    goTo(urlTo('portfolio.sell', { id: portfolioId }))
  }, [portfolioId])
  const handleBuy = useCallback(() => {
    goTo(urlTo('portfolio.buy', { id: portfolioId }))
  }, [portfolioId])

  const handleAddFunds = useCallback(() => {
    const currentLocation = location.pathname + location.search

    goTo(urlTo('dashboard.portfolio.add-funds', { id: portfolioId }, { back: currentLocation }))
  }, [portfolioId])

  const handleReview = useCallback(
    (orderId, previous) => {
      goTo(urlTo('portfolio.review', { id: portfolioId, orderId }, { previous }))
    },
    [portfolioId],
  )

  return {
    handleBack,

    handleSell,
    handleBuy,

    handleAddFunds,
    handleReview,
  }
}

export { useNavbar }
