import { useEffect } from 'react'

const standardTitle = document.title

const useMeta = ({ title, description, preload, og }) => {
  useEffect(() => {
    if (title) {
      document.querySelector('title').innerText = title
    }

    if (description) {
      const meta = document.createElement('meta')
      meta.setAttribute('name', 'description')
      meta.setAttribute('content', description)
      document.head.appendChild(meta)
    }

    if (og) {
      Object.entries(og).forEach(([key, value]) => {
        const meta = document.createElement('meta')
        meta.setAttribute('property', `og:${key}`)
        meta.setAttribute('content', value)
        document.head.appendChild(meta)
      })
    }

    if (preload) {
      preload.forEach((url) => {
        const link = document.createElement('link')
        link.setAttribute('rel', 'preload')
        link.setAttribute('href', url)
        document.head.appendChild(link)
      })
    }

    return () => {
      if (title) {
        document.querySelector('title').innerText = standardTitle
      }

      if (description) {
        document.querySelector('meta[name="description"]')?.remove()
      }

      if (og) {
        Object.entries(og).forEach(([key, value]) => {
          document.querySelector(`meta[property="og:${key}"][content="${value}"]`)?.remove()
        })
      }

      if (preload) {
        preload.forEach((url) => {
          document.querySelector(`link[rel="preload"][href="${url}"]`)?.remove()
        })
      }
    }
  }, [title, description, og, preload])
}

export default useMeta
