import axios from 'helpers/ajax'

import { type PortfolioSecurity } from 'app/effector/securities/models'

import { ApiError } from 'app/redux/models/errors'

export const getPortfolioSecurities = async (portfolioId: number): Promise<PortfolioSecurity[] | ApiError> => {
  try {
    const { data } = await axios.get(`portfolios/${portfolioId}/securities/`, { shouldNotSend404: true })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export const savePortfolioSecurities = async ({
  portfolioSecurityWeightList,
  portfolioId,
}: {
  portfolioSecurityWeightList: Array<{ security_id: number; target_weight: string }>
  portfolioId: number
}): Promise<PortfolioSecurity[] | ApiError> => {
  try {
    const { data } = await axios.put(`/portfolios/${portfolioId}/securities/`, portfolioSecurityWeightList)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
