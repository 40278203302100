import React from 'react'

import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type DayOfMonthStepProps = {
  value: number | null
  validation: {
    rules: boolean[]
    errors: string[]
  }
  inputRef: React.RefObject<Element>
  onChange: (event, value: string) => void
}

const DayOfMonthStep = ({ value, validation, inputRef, onChange }: DayOfMonthStepProps): React.ReactElement => {
  return (
    <Width size={15} center>
      <Validate rules={validation.rules} key="monthlyPaymentDayInput">
        {(isValid) => {
          return (
            <Label
              postfield={
                <Paper top={4}>
                  <Typography size={12} lineHeight="small" color={!isValid ? 'on_color_red' : 'white'}>
                    <Typo>Must be 1 to 28</Typo>
                  </Typography>
                </Paper>
              }
            >
              <Input
                ref={inputRef}
                className="SavingsPlanSetup_Input"
                type="number"
                mods={{ size: 'big', color: 'blue', text: 'bold' }}
                onChange={onChange}
                data-test-id="savingsPlanSetupDayInput"
              >
                {value}
              </Input>
            </Label>
          )
        }}
      </Validate>
    </Width>
  )
}

export { DayOfMonthStep }
