import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import isNull from 'lodash/isNull'

import { formatPercent } from 'helpers/money'

import { useMediaQueries } from 'hooks'

import { Typography } from 'components/atoms/Typography'

import './SecurityWeightInfo.css'

const SecurityWeightInfo = ({ className, currentWeight, targetWeight, 'data-test-id': dataTestId }) => {
  const { desktop } = useMediaQueries()
  const classes = classNames(className, 'SecurityWeightInfo')

  return (
    <div className={classes}>
      <Typography
        size={14}
        color="additional"
        lineHeight="small"
        tag="span"
        data-test-id={`${dataTestId}CurrentWeight`}
      >
        {formatPercent(currentWeight / 100, true)}
      </Typography>
      {!isNull(targetWeight) && (
        <Typography
          className="SecurityWeightInfo-TargetWeight"
          size={14}
          color="minor"
          lineHeight="small"
          tag="span"
          data-test-id={`${dataTestId}TargetWeight`}
        >
          / {formatPercent(targetWeight / 100, true)}
        </Typography>
      )}
    </div>
  )
}

SecurityWeightInfo.propTypes = {
  className: PropTypes.string,
  currentWeight: PropTypes.number.isRequired,
  targetWeight: PropTypes.number,
  'data-test-id': PropTypes.string,
}

export { SecurityWeightInfo }
