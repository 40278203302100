import localstore from 'helpers/localstore.js'

const useAccessToken = ({ request }) => {
  if (!request) {
    return
  }

  const ACCESS_TOKEN = localstore.get('client') ? localstore.get('client').access_token : null

  if (ACCESS_TOKEN && request?.headers) {
    request.headers.Authorization = `Bearer ${ACCESS_TOKEN}`
  }
}

export { useAccessToken }
