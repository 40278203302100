import { useEffect } from 'hooks'

import { sendError } from 'helpers/errorLogging.js'

let initialLoadingSettled = false
let wasLoadingLastTime = null
let wasNotLoadingLastTime = null

const useTrackBlinkingToSentry = ({ logToSentry, loading }) => {
  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        const isActuallyLoadingSomething = document.querySelector('.Global-Preloader')

        if (!isActuallyLoadingSomething) {
          initialLoadingSettled = true
        }
      }, 200)
    }
  }, [loading])

  useEffect(() => {
    if (initialLoadingSettled && logToSentry) {
      if (!loading) {
        // setTimeout is necessary due to some false positives
        setTimeout(() => {
          const isActuallyLoadingSomething = document.querySelector('.Global-Preloader')

          if (!isActuallyLoadingSomething) {
            wasNotLoadingLastTime = new Date().getTime()
          }
        }, 1)
      }

      if (loading) {
        // setTimeout is necessary due to some false positives
        setTimeout(() => {
          const isActuallyLoadingSomething = document.querySelector('.Global-Preloader')

          if (isActuallyLoadingSomething) {
            wasLoadingLastTime = new Date().getTime()
          }
        }, 1)
      }

      const diffBetweenLoadings = wasLoadingLastTime - wasNotLoadingLastTime

      if (
        wasNotLoadingLastTime &&
        wasLoadingLastTime &&
        diffBetweenLoadings &&
        diffBetweenLoadings > 0 &&
        diffBetweenLoadings < 500
      ) {
        sendError('GlobalPreloader blinked rapidly for a no good reason', {
          page: window.location.toString(),
          wasNotLoadingLastTime,
          wasLoadingLastTime,
          diffBetweenLoadings,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
}

export { useTrackBlinkingToSentry }
