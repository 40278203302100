import React, { Fragment } from 'react'

import { memoizeOne } from 'helpers/memoize'

import Text from 'components/_old/Text/Text.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Label, { LabelText, LabelField } from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Delete from 'components/molecules/Delete/Delete.jsx'

import './NationalitiesInputList.css'

// TODO: Refactor component and it’s parents store nationalities with text above
export default class NationalitiesInputList extends React.PureComponent {
  state = {
    nationalitiesTexts: [],
  }

  fullfillInitialNationalitiesTexts = () => {
    const { nationalitiesIds, allNationalitiesList } = this.props
    let { nationalitiesTexts } = this.state

    if (allNationalitiesList.length > 0 && nationalitiesIds.length > 0 && nationalitiesTexts.length < 1) {
      nationalitiesTexts = (
        nationalitiesIds.map((id) => allNationalitiesList.find(({ country_id }) => country_id === id)) || []
      )
        .map(({ country } = {}) => country || null)
        .filter(Boolean)

      if (nationalitiesTexts.length > 0) {
        this.setState({ nationalitiesTexts })
      }
    }
  }

  getNationalitiesListByIds = memoizeOne((nationalitiesIds, nationalitiesTexts) => {
    if (nationalitiesIds.length > 0) {
      const nationalities = nationalitiesIds.map((nationalityId, index) => {
        if (nationalityId) {
          return {
            country: nationalitiesTexts[index],
            country_id: nationalityId,
          }
        }
        return null
      })

      return nationalities
    }
    return [null]
  })

  handleAddEmptyNationality = () => {
    const { nationalitiesIds, onChange } = this.props
    onChange([...nationalitiesIds, null])
  }

  inputMods = { size: 'bigger' }

  handleNationalityInputChange = (index, value) => {
    const { country: nationalityText, country_id: nationalityId } = value || {}
    const { nationalitiesTexts } = this.state
    const { nationalitiesIds, onChange } = this.props
    onChange([...nationalitiesIds.slice(0, index), nationalityId || null, ...nationalitiesIds.slice(index + 1)])
    this.setState({
      nationalitiesTexts: [
        ...nationalitiesTexts.slice(0, index),
        nationalityText || null,
        ...nationalitiesTexts.slice(index + 1),
      ],
    })
  }

  handleNationalityInputDelete = (index) => {
    const { nationalitiesTexts } = this.state
    const { nationalitiesIds, onChange } = this.props
    onChange([...nationalitiesIds.slice(0, index), ...nationalitiesIds.slice(index + 1)])
    this.setState({
      nationalitiesTexts: [...nationalitiesTexts.slice(0, index), ...nationalitiesTexts.slice(index + 1)],
    })
  }

  handlersNationalityInputChange = []

  handleOnFocus = () => {
    const { onFocus } = this.props
    onFocus()
  }

  handleOnBlur = () => {
    const { onBlur } = this.props
    onBlur()
  }

  componentDidMount() {
    if (this.props.nationalitiesIds.length < 1) {
      this.handleAddEmptyNationality()
    }

    this.fullfillInitialNationalitiesTexts()
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.nationalitiesTexts.length > 0) {
      return
    }

    this.fullfillInitialNationalitiesTexts()
  }

  render() {
    const {
      nationalitiesIds,
      allNationalitiesList,
      nationalityUSAIndex,
      isValid,
      errorMessage,
      disabled,
      tabIndex = 1,
      'data-test-id': dataTestId,
    } = this.props
    const { nationalitiesTexts } = this.state

    const nationalitiesList = this.getNationalitiesListByIds(nationalitiesIds, nationalitiesTexts)

    nationalitiesIds.forEach((_nationalityId, index) => {
      if (!this.handlersNationalityInputChange[index]) {
        this.handlersNationalityInputChange[index] = (_event, value) => {
          this.handleNationalityInputChange(index, value)
        }
      }
    })

    const nationalityInput = (nationality, index) => {
      const postfield = index === nationalityUSAIndex && (
        <Text block color="red" data-test-id={`${dataTestId}UsaInfo-${index}`}>
          Please contact us through the{' '}
          <Link to="https://help.investengine.com/" hard>
            Help Centre
          </Link>
          , and we'll help you continue creating an account with us.
        </Text>
      )
      const handleChange =
        this.handlersNationalityInputChange[index] || ((_event, value) => this.handleNationalityInputChange(0, value))

      return (
        <Label postfield={postfield} key={index} data-test-id={`${dataTestId}Label`}>
          <LabelText data-test-id={`${dataTestId}LabelText-${index}`}>
            <Text color={isValid || index > 0 ? null : 'red'}>
              {isValid || index > 0 ? 'Your citizenship' : errorMessage}
            </Text>
          </LabelText>
          <LabelField>
            <Delete onDelete={() => this.handleNationalityInputDelete(index)} deletable={index > 0}>
              <Input
                name="nationality"
                mods={this.inputMods}
                disabled={disabled}
                type="typeahead"
                options={allNationalitiesList}
                labelKey="country"
                onChange={handleChange}
                tabIndex={tabIndex}
                forceSelect
                data-test-id={`${dataTestId}Input-${index}`}
                onFocus={this.handleOnFocus}
                onBlur={this.handleOnBlur}
              >
                {nationality}
              </Input>
            </Delete>
          </LabelField>
        </Label>
      )
    }

    if (!nationalitiesList || nationalitiesList.length === 0) {
      return null
    }

    return (
      <Fragment>
        {nationalitiesList.map((nationality, index) => nationalityInput(nationality, index))}
        {nationalitiesList.length < 5 && (
          <div className="NationalitiesInputList-Link">
            <Link onClick={this.handleAddEmptyNationality} data-test-id={`${dataTestId}Add`}>
              <Text smaller>Add another citizenship</Text>
            </Link>
          </div>
        )}
      </Fragment>
    )
  }
}
