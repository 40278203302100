import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useMemo, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'
import { format as formatMoney } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router.js'
import { backendErrorsToObj, combineErrors } from 'helpers/validation.js'

import { $dictsStore } from 'app/effector/dicts'

import {
  changeField as changeClientFieldActionCreator,
  resetError as resetClientErrorActionCreator,
  setValid as setClientValidActionCreator,
  updateOrCreate as updateOrCreateClientActionCreator,
} from 'app/redux/actions/client'
import {
  changeField as changeGoalFieldActionCreator,
  resetError as resetGoalErrorActionCreator,
  setValid as setGoalValidActionCreator,
  updateOrCreate as updateOrCreateGoalActionCreator,
} from 'app/redux/actions/portfolios'

import { types as clientTypes } from 'constants/client'
import { MAX_SINGLE_PAYMENT_AMOUNT } from 'constants/validations'

const useGetStarted = ({ selectedGoalId, redirectToPrevStep, redirectToNextStep, isFirstStep }) => {
  const goals = useSelector((state) => state.portfolios.items)
  const goalsError = useSelector((state) => state.portfolios.error)
  const client = useSelector((state) => state.client)
  const { isRefferalEnabled, initialDepositMin } = useUnit($dictsStore)

  const goal = useMemo(() => {
    if (selectedGoalId) {
      return goals.find((goal) => goal.id === parseInt(selectedGoalId, 10)) || {}
    }

    const firstGoal = goals[0]

    if (firstGoal) {
      return firstGoal
    }

    return {}
  }, [selectedGoalId, goals])

  const [
    changeClientField,
    updateOrCreateClient,
    resetClientError,
    setClientValid,
    changeGoalField,
    updateOrCreateGoal,
    resetGoalError,
    setGoalValid,
  ] = useActions([
    changeClientFieldActionCreator,
    updateOrCreateClientActionCreator,
    resetClientErrorActionCreator,
    setClientValidActionCreator,
    changeGoalFieldActionCreator,
    updateOrCreateGoalActionCreator,
    resetGoalErrorActionCreator,
    setGoalValidActionCreator,
  ])

  useEffect(() => {
    trackEvent({
      action: 'questionnaire_page_visit',
      step: 0,
      step_description: 'initial_question',
      regulatory_type: goal?.regulatory_type,
      version: 'default',
    })
  }, [goal?.regulatory_type])

  const handleInputChange = useCallback(
    (event, value = event.target.value) => {
      if (value === '' || value === 0 || value === '0') {
        value = value ? parseInt(value, 10) : null
      }

      changeGoalField({ initial_deposit: value }, goal.id)
    },
    [goal, changeGoalField],
  )

  const referralSuffix = isRefferalEnabled ? ' (in addition to any incentives)' : ''
  const validation = combineErrors(
    {
      initialDeposit: {
        rules: [goal.initial_deposit >= initialDepositMin, goal.initial_deposit <= MAX_SINGLE_PAYMENT_AMOUNT],
        errors: [
          `Initial investment must be at least ${formatMoney(initialDepositMin)}${referralSuffix}`,
          `Maximum amount is ${formatMoney(MAX_SINGLE_PAYMENT_AMOUNT)}`,
        ],
      },
    },
    backendErrorsToObj(client.error),
    backendErrorsToObj(goalsError, true),
  )

  const handleGoNext = useCallback(async () => {
    try {
      trackEvent({
        category: 'Create new portfolio',
        action: 'Questionnaire step completed',
        label: 'Get started',
        portfolioRegulatoryType: goal.regulatory_type,
        portfolioPresetType: goal.preset_type,
      })

      trackEvent({
        action: 'questionnaire_step_completed',
        text: goal?.initial_deposit,
        step: 0,
        step_description: 'initial_question',
        regulatory_type: goal?.regulatory_type,
        version: 'default',
      })

      await changeClientField({ type: clientTypes.BUSINESS })
      const stateAfterPatchClient = await updateOrCreateClient(['client_id', 'type'], false)
      const stateAfterPatchGoal = await updateOrCreateGoal(['initial_deposit'], undefined, true, goal.id, false)

      if (stateAfterPatchClient.client.error) {
        throw stateAfterPatchClient.client.error
      }

      if (stateAfterPatchGoal.portfolios.error) {
        throw stateAfterPatchGoal.portfolios.error
      }

      setClientValid()
      setGoalValid()
      redirectToNextStep()
    } catch (error) {
      processError({
        error,
        resets: [resetClientError, resetGoalError],
      })
    }
  }, [
    goal,
    changeClientField,
    updateOrCreateClient,
    resetClientError,
    setClientValid,
    updateOrCreateGoal,
    resetGoalError,
    setGoalValid,
    redirectToNextStep,
  ])

  const handleBack = useCallback(() => {
    if (!isFirstStep) {
      return redirectToPrevStep()
    }

    goTo(urlTo('dashboard'))
  }, [isFirstStep, redirectToPrevStep])

  const handleCancel = useCallback(() => {
    goTo(urlTo('dashboard'))
  }, [])

  return {
    goal,
    validation,
    handleInputChange,
    handleGoNext,
    handleBack,
    handleCancel,
  }
}

export { useGetStarted }
