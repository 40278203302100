import { useEffect } from 'hooks'

const useAnalyticsScripts = (): void => {
  useEffect(() => {
    // Reddit Pixel
    /* eslint-disable */
    !(function (w, d) {
      if (!w.rdt) {
        var p = (w.rdt = function () {
          p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments)
        })
        p.callQueue = []
        const t = d.createElement('script')
        ;(t.src = 'https://www.redditstatic.com/ads/pixel.js'), (t.async = !0)
        const s = d.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(t, s)
      }
    })(window, document)
    rdt('init', 't2_do0vbo8a')
    rdt('track', 'PageVisit')
    /* eslint-enable */
    // End Reddit Pixel

    // Twitter universal website tag code
    /* eslint-disable */
    !(function (e, t, n, s, u, a) {
      e.twq ||
        ((s = e.twq =
          function () {
            s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments)
          }),
        (s.version = '1.1'),
        (s.queue = []),
        (u = t.createElement(n)),
        (u.async = !0),
        (u.src = 'https://static.ads-twitter.com/uwt.js'),
        (a = t.getElementsByTagName(n)[0]),
        a.parentNode.insertBefore(u, a))
    })(window, document, 'script')
    twq('init', 'o4pv0')
    twq('track', 'PageView')
  }, [])
  /* eslint-enable */
  // End Twitter universal website tag code
}

export { useAnalyticsScripts }
