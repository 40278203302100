import React, { useState, useEffect, useCallback } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import isFunction from 'lodash/isFunction'
import debounce from 'lodash/debounce'

import { usePrevious, useClickOutside } from 'hooks'
import axios from 'helpers/ajax'

import Input from 'components/_old/Input/Input.jsx'
import Card from 'components/_old/Card/Card.jsx'
import JoinCards from 'components/molecules/JoinCards/JoinCards.jsx'

import './Combobox.css'

const Combobox = ({
  className,
  children: value,
  onChange,
  remote,
  name,
  tabIndex,
  selectValue,
  onFocus,
  onBlur,
  'data-test-id': dataTestId,
  ...rest
}) => {
  const [rootRef, hasClickedOutside] = useClickOutside()
  const prevValue = usePrevious(value)
  const [textValue, setTextValue] = useState('')
  const prevTextValue = usePrevious(textValue)
  const [focused, setFocused] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState()
  const prevSelectedValue = usePrevious(selectedValue)

  useEffect(() => {
    const prevSelectedValue = selectValue(prevValue)
    const selectedValue = selectValue(value)

    if (JSON.stringify(prevSelectedValue) !== JSON.stringify(selectedValue) && selectedValue && selectedValue.name) {
      setTextValue(selectedValue.name)
    }
  }, [value, selectValue, setTextValue, prevValue])

  const handleInput = useCallback(({ target: { value } }) => setTextValue(value), [remote])

  const fetchOptions = useCallback(
    debounce((textValue) => {
      axios.get(`${remote}?q=${textValue}`).then((response) => {
        const options = ((response || {}).data || []).map(selectValue)

        setLoading(false)
        setOptions(options)
      })
    }, 250),
    [selectValue, setLoading, setOptions],
  )

  useEffect(() => {
    if (textValue !== prevTextValue) {
      if (textValue.length < 1) {
        setSelectedValue(undefined)
        setOptions([])
      } else {
        setLoading(true)
        fetchOptions(textValue)
      }
    }
  }, [remote, selectValue, setLoading, setSelectedValue, setOptions, textValue, prevTextValue])

  const handleFocus = useCallback(
    (...args) => {
      if (isFunction(onFocus)) {
        onFocus(...args)
      }
      setFocused(true)
    },
    [setFocused],
  )

  const handleFinish = useCallback(() => {
    if (!focused) {
      return
    }

    // eslint-disable-next-line react/no-find-dom-node
    const rootElement = findDOMNode(rootRef.current)

    // To propely handle focus we should call focus after next rerender
    setTimeout(() => {
      setFocused(false)
      if (rootElement) {
        rootElement.focus()
      }
    }, 1)
  }, [onFocus, textValue, rootRef, focused, setFocused])

  const handleBlur = useCallback(() => {
    if (!focused) {
      return
    }

    handleFinish()

    // It should be called after setFocused → false
    setTimeout(() => {
      if (isFunction(onBlur)) {
        onBlur(textValue)
      }
    }, 2)
  }, [onBlur, textValue, focused, handleFinish])

  useEffect(() => {
    if (hasClickedOutside) {
      handleBlur()
    }
  }, [hasClickedOutside, handleBlur])

  const handleChange = useCallback(
    (value) => {
      setSelectedValue(value)
    },
    [setTextValue, setSelectedValue],
  )

  useEffect(() => {
    const processedValue = selectValue(selectedValue)

    if (isFunction(onChange) && JSON.stringify(prevSelectedValue) !== JSON.stringify(selectedValue)) {
      if (selectedValue && processedValue.name) {
        setTextValue(processedValue.name)
        onChange(processedValue)
      } else {
        onChange(undefined)
      }

      if (processedValue) {
        handleFinish()
      }
    }
  }, [onChange, setTextValue, setOptions, selectedValue, prevSelectedValue, handleFinish])

  useEffect(() => {
    if (
      focused &&
      prevTextValue !== textValue &&
      (textValue || '').length < 1 &&
      prevSelectedValue === undefined &&
      selectedValue === undefined
    ) {
      onChange(undefined)
    }
  }, [prevTextValue, textValue, prevSelectedValue, selectedValue])

  const haveItems = textValue && focused && options.length > 0
  const classes = classNames('Combobox', {
    Combobox_haveItems: haveItems,
    Combobox_loading: loading,
  })
  const inputClasses = classNames('Combobox-Input', className)

  const autoComplete = name || 'combox-data'

  return (
    <div ref={rootRef} className={classes} tabIndex={tabIndex} data-test-id={dataTestId}>
      <Input
        type="text"
        className={inputClasses}
        autoCorrect="off"
        name={name}
        autoComplete={autoComplete}
        onInput={handleInput}
        tabIndex={tabIndex}
        {...rest}
        onFocus={handleFocus}
      >
        {textValue}
      </Input>
      {haveItems && (
        <Card className="Combobox-List" mods={{ theme: 'white shadowed', 'no-padding': 'all' }}>
          <JoinCards>
            {options.map((option, i) => (
              <Card
                key={option.key || i}
                tag="button"
                className="Combobox-ListItem"
                onClick={() => handleChange(option.value)}
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    handleChange(option.value)
                  }
                }}
                mods={{ padding: 'smaller', 'no-padding': 'left right' }}
                data-test-id="dropdownItem"
              >
                {option.name}
              </Card>
            ))}
          </JoinCards>
        </Card>
      )}
    </div>
  )
}

const propTypes = (Combobox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  onChange: PropTypes.func,
  remote: PropTypes.string.isRequired,
  name: PropTypes.string,
  tabIndex: PropTypes.number,
  selectValue: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  'data-test-id': PropTypes.string,
})

export { propTypes }
export default Combobox
