import { useCallback, useLoading, useState } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'

import { showFailToast } from 'app/redux/actions/ui'
import { ApiError } from 'app/redux/models/errors'

import { useReauth } from 'app/pages/Dashboard/UserProfile/hooks'

type useConfirmIdentityModalProps = {
  parentModule: string
}

type useConfirmIdentityModalReturnProps = {
  isLoading: boolean
  password: string
  validation: object
  handleCancel: () => void
  handlePasswordChange: (event: React.ChangeEvent, password: string) => void
  handleContinue: () => Promise<void>
  captchaToken: string
  isCaptchaDisplayed: boolean
  hideCaptcha: () => void
}

const useConfirmIdentityModal = ({
  parentModule,
}: useConfirmIdentityModalProps): useConfirmIdentityModalReturnProps => {
  const [password, setPassword] = useState('')
  const [isCaptchaDisplayed, setIsCaptchaDisplayed] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')
  const { isLoading, wait } = useLoading(false)

  const { confirmIdentity } = useReauth()

  const hideCaptcha = useCallback(() => {
    setIsCaptchaDisplayed(false)
  }, [setIsCaptchaDisplayed])

  const handleContinue = useCallback(async () => {
    try {
      const data = await wait(confirmIdentity(password))

      if (data instanceof ApiError) {
        throw data
      }

      goTo(urlTo(`${parentModule}.confirm-phone-number`), { scrollToTop: false })
      setPassword('')
    } catch (error) {
      if (error?.response?.data?.error === 'captcha') {
        const captchaToken = error.response?.data?.token

        if (captchaToken) {
          setCaptchaToken(captchaToken)
          setIsCaptchaDisplayed(true)
          return
        }
      }
      showFailToast(
        error?.response?.data?.error === 'wrong_password'
          ? 'Incorrect password'
          : 'Something went wrong, please try again or contact us at support@investengine.com',
      )
    }
  }, [password, setCaptchaToken, setIsCaptchaDisplayed, confirmIdentity, parentModule, wait])

  const handlePasswordChange = useCallback((event, value) => {
    setPassword(value)
  }, [])

  const validation = {
    password: { rules: [password], errors: ['Password can’t be empty'] },
  }

  const handleCancel = useCallback(() => {
    goTo(urlTo(parentModule), { scrollToTop: false })
    setPassword('')
    hideCaptcha()
  }, [parentModule, hideCaptcha])

  return {
    isLoading,
    password,
    validation,
    handleCancel,
    handlePasswordChange,
    handleContinue,
    captchaToken,
    isCaptchaDisplayed,
    hideCaptcha,
  }
}

export { useConfirmIdentityModal }
