import React from 'react'

import { features } from 'helpers/features'
import { memoizeForever } from 'helpers/memoize'
import requireContext from 'require-context.macro'

const clearRoutesCache = () => {
  // Create context of all routes.jsx files
  const context = requireContext('../../', true, /.*routes.jsx$/)
  const allRoutes = context.keys()

  // For each file delete it's cache
  allRoutes.forEach((path) => {
    delete require.cache[context.resolve(path)]
  })
}

// To make `getRoutes` more performant it's split into two parts, forever memoized function with one unused argument
const getMemoizedRoutes = memoizeForever((_hash) => {
  // We have to clean webpack cache every time function is called
  clearRoutesCache()

  // Require is necessary cuz we can't import routes globally to prevent caching
  const preFlight = require('app/pages/PreFlight/routes.jsx').default
  const selectProductType = require('app/pages/SelectProductType/routes.jsx').default
  const sharedPortfolio = require('app/pages/SharedPortfolio/routes.tsx').default
  const createAccount = require('app/pages/CreateAccount/routes.jsx').default
  const createNewPortfolio = require('app/pages/CreateNewPortfolio/routes.jsx').default
  const yearlyReview = require('app/pages/YearlyReview/routes.jsx').default
  const sorry = require('app/pages/Sorry/routes.jsx').default
  const login = require('app/pages/Login/routes.jsx').default
  const dashboard = require('app/pages/Dashboard/routes.jsx').default
  const error = require('app/pages/Error/routes.jsx').default
  const App = require('app/containers/App/App.tsx').default
  const secureDocumentUpload = require('app/pages/SecureDocumentUpload/routes.tsx').default
  const twoFactorAuthSetup = require('app/pages/TwoFactorAuthSetup/routes.tsx').default

  const childRoutes = [
    preFlight,
    selectProductType,
    createAccount,
    sharedPortfolio,
    ...login,
    dashboard,
    createNewPortfolio,
    sorry,
    yearlyReview,
    ...secureDocumentUpload,
    ...twoFactorAuthSetup,
  ]

  childRoutes.push(error)

  return {
    module: 'index',
    path: '/',
    // todo: investigate why the Root component refuses to include the App component directly
    component: function Component(props) {
      return <App {...props} />
    },
    childRoutes,
  }
})

// And call that calls this memoized function with features.hash as an argument
const getRoutes = () => getMemoizedRoutes(features.getHash())

export { getRoutes }
