import React, { forwardRef } from 'react'

import classNames from 'classnames/dedupe'

import './Paper.css'

const PaperSizes = [0, 1, 2, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80, 96, 120] as const

type PaperProps = React.HTMLProps<HTMLDivElement> & {
  tag?: keyof JSX.IntrinsicElements
  children: React.ReactNode | React.ReactNodeArray
  className?: string
  style?: React.CSSProperties
  top?: ArrayElement<typeof PaperSizes>
  right?: ArrayElement<typeof PaperSizes>
  bottom?: ArrayElement<typeof PaperSizes>
  left?: ArrayElement<typeof PaperSizes>
  mix?: boolean
  flex?: boolean
  inline?: boolean
  clickThroughPadding?: boolean
  'data-test-id'?: string
}

const Paper = forwardRef<HTMLElement, PaperProps>(function Paper(props: PaperProps, ref): React.ReactElement {
  const {
    tag = 'div',
    children,
    className,
    style,
    top,
    right,
    bottom,
    left,
    mix = false,
    flex = false,
    inline = false,
    clickThroughPadding,
    'data-test-id': dataTestId,
    ...rest
  } = props

  const classes = classNames(className, 'Paper', {
    [top ? `Paper_top_${top}` : '']: top,
    [right ? `Paper_right_${right}` : '']: right,
    [bottom ? `Paper_bottom_${bottom}` : '']: bottom,
    [left ? `Paper_left_${left}` : '']: left,
    Paper_flex: flex,
    Paper_inline: inline,
    Paper_clickThroughPadding: clickThroughPadding,
  })

  const htmlProps = { ref, className: classes, style, 'data-test-id': dataTestId, ...rest }

  if (mix && React.isValidElement(children)) {
    return React.cloneElement(children, htmlProps)
  }

  return React.createElement(tag, htmlProps, children)
})

export { Paper, PaperSizes }
