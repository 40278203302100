import { memoizeForever } from 'helpers/memoize'

type XYCoordinates = { x: number; y: number }
type CurveXYCoordinates = { x1: number; y1: number; x2: number; y2: number; dx: number; dy: number }

const getMovePath = memoizeForever(({ x, y }: XYCoordinates): string => {
  return `M${x ?? 0} ${y ?? 0}`
})

const getLinePath = memoizeForever((positions: XYCoordinates[]): string => {
  return positions.reduce((prev, { x, y }) => `${prev} L ${x} ${y}`, '')
})

const getCurvePath = memoizeForever((positions: CurveXYCoordinates[]): string => {
  return positions.reduce((prev, { x1, y1, x2, y2, dx, dy }) => `${prev} C ${x1} ${y1}, ${x2} ${y2}, ${dx} ${dy}`, '')
})

const combinePaths = memoizeForever((paths: string[]): string => {
  return paths.reduce((prevPath, path) => `${prevPath} ${path}`, '')
})

const getPath = memoizeForever((positions: XYCoordinates[], close?: boolean): string => {
  if (positions.length === 1) {
    positions = [
      { x: 0, y: positions[0].y },
      { x: positions[0].x * 2, y: positions[0].y },
    ]
  }

  if (close) {
    positions = [{ x: 0, y: 1 }, ...positions, { x: positions.at(-1)?.x ?? 0, y: 1 }]
  }

  return combinePaths([getMovePath(positions[0]), getLinePath(positions)])
})

export { getMovePath, getLinePath, getCurvePath, combinePaths, getPath, type XYCoordinates, type CurveXYCoordinates }
