import React from 'react'

import { useUnit } from 'effector-react'

import { useMemo, useSelector } from 'hooks'

import { palette } from 'helpers/palette'

import { $owner } from 'app/effector/contacts'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { UserAvatar } from 'components/molecules/UserAvatar/UserAvatar'

import { manageTypes, regulatoryTypesCashText, type regulatoryTypes } from 'constants/portfolio'

type RegulatoryType = typeof regulatoryTypes
type ManageType = typeof manageTypes

type PortfolioManagedByProps = {
  isDefaultPresetUsed: boolean
  customText?: string
  'data-test-id'?: string
} & (
  | { manageType: ManageType['CASH']; regulatoryType: RegulatoryType[keyof RegulatoryType] }
  | {
      manageType: Exclude<ManageType[keyof ManageType], ManageType['CASH']>
      regulatoryType?: never
    }
)

const PortfolioManagedBy = ({
  regulatoryType,
  manageType,
  isDefaultPresetUsed,
  customText,
  'data-test-id': dataTestId,
}: PortfolioManagedByProps): React.ReactElement => {
  const client = useSelector((state) => state.client)
  const owner = useUnit($owner) ?? {}

  const showLogo = [manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(manageType)
  const showAvatar = [manageTypes.DIY, manageTypes.SELF_SELECTED].includes(manageType)

  const manageTypeText = useMemo(() => {
    if (isDefaultPresetUsed) return 'Retirement portfolio managed by IE'

    if (customText) return customText

    if (manageType === manageTypes.CASH) {
      return `${regulatoryTypesCashText[regulatoryType]} cash`
    }

    return {
      [manageTypes.MANAGED]: 'Managed by IE',
      [manageTypes.SELF_SELECTED]: 'Picked by you, managed by IE',
      [manageTypes.DIY]: 'Self-managed',
    }[manageType]
  }, [isDefaultPresetUsed, manageType, regulatoryType, customText])

  return (
    <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'flex-start' }}>
      <Column size={0}>
        {(showAvatar || showLogo) && (
          <Paper right={8}>
            <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
              <Column size={0}>
                {showAvatar && (
                  <Paper right={showLogo ? 4 : 0}>
                    <UserAvatar
                      size="16"
                      firstName={owner.first_name}
                      lastName={owner.last_name}
                      image={client.avatar}
                    />
                  </Paper>
                )}
              </Column>
              <Column size={0}>
                {showLogo && <Icon type="logo-16" size={16} color={palette['primary-default']} />}
              </Column>
            </ColumnarLayout>
          </Paper>
        )}
      </Column>
      <Column size={1}>
        <Typography size={14} lineHeight="small" color="minor" data-test-id={dataTestId}>
          <Typo>{manageTypeText}</Typo>
        </Typography>
      </Column>
    </ColumnarLayout>
  )
}

export { PortfolioManagedBy }
