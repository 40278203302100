import moment from 'helpers/date.js'

const transformIncomeProjectionsData = (
  // eslint-disable-next-line @typescript-eslint/naming-convention
  { max_y, yield_return, dividends_amount, data, ...rest } = {},
  isFuture = true,
) => ({
  max: max_y ? parseFloat(max_y) : null,
  yield_return: yield_return || null,
  dividends_amount: dividends_amount ? parseFloat(dividends_amount) : null,
  data: data
    ? data.map((value, index) => {
        const initialDate = isFuture ? moment().add(1, 'month') : moment().subtract(1, 'years')
        const date = initialDate.startOf('month').add(index, 'month').toDate()

        return {
          value: parseFloat(value),
          date,
        }
      })
    : null,
  ...rest,
})

export { transformIncomeProjectionsData }
