import React, { useCallback } from 'react'

import classNames from 'classnames/dedupe'

import { useRef, useLayoutEffect, useMemo } from 'hooks'

import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Phantom } from 'components/atoms/Phantom'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import './TwoColumns.css'

type TwoColumnsSharedProps = {
  className?: string
  content: React.ReactNode | React.ReactNodeArray
  sidebar: React.ReactNode | React.ReactNodeArray
}

type TwoColumnsNonScrollableProps = TwoColumnsSharedProps & {
  stickySidebar?: boolean
  synchronizedScroll?: false
  scrollableElement?: null
}

type TwoColumnsScrollableProps = TwoColumnsSharedProps & {
  synchronizedScroll: true
  scrollableElement: HTMLElement
  minimalSidebarOffsetTop?: number
  initialSidebarOffsetTop?: number
  sidebarOffsetBottom?: number
}

type TwoColumnsProps = XOR<TwoColumnsNonScrollableProps, TwoColumnsScrollableProps>

function TwoColumnsNonScrollable({
  className,
  content,
  sidebar,
  stickySidebar,
}: TwoColumnsSharedProps): React.ReactElement {
  return (
    <ColumnarLayout className={className} mods={{ padding: 'no' }}>
      <Column className="TwoColumns-Content" size={1}>
        {content}
      </Column>
      <Column className="TwoColumns-Sidebar" size={0} sticky={stickySidebar}>
        <Paper left={80}>
          <Width className="TwoColumns-SidebarContent" size={19}>
            {sidebar}
          </Width>
        </Paper>
      </Column>
    </ColumnarLayout>
  )
}

function TwoColumnsScrollable({
  className,
  content,
  sidebar,
  synchronizedScroll,
  scrollableElement,
  minimalSidebarOffsetTop = 0,
  initialSidebarOffsetTop = 0,
  sidebarOffsetBottom = 0,
}: TwoColumnsScrollableProps): React.ReactElement {
  const layoutRef = useRef<HTMLDivElement>()
  const sidebarRef = useRef<HTMLDivElement>()
  const sidebarContentRef = useRef<HTMLDivElement>()
  const sidebarContentInner = sidebarContentRef?.current?.querySelector('[data-sidebar-inner="true"]')
  const style = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {
      '--two-columns-offset-top': `${initialSidebarOffsetTop ?? minimalSidebarOffsetTop ?? 0}px`,
      '--two-columns-offset-bottom': `${sidebarOffsetBottom}px`,
    } as React.CSSProperties
  }, [initialSidebarOffsetTop, minimalSidebarOffsetTop, sidebarOffsetBottom])

  const subscribeToSyncScroll = useCallback(() => {
    if (!scrollableElement) {
      return
    }

    if (sidebarRef.current && layoutRef.current) {
      const initialOffsetTop = sidebarRef.current.getBoundingClientRect().top

      layoutRef.current.style.setProperty('--two-columns-offset-top', `${initialOffsetTop}px`)
    }

    let previousScrollTop: number | null = null

    const syncScroll = (): void => {
      if (!layoutRef.current) {
        return
      }

      if (!sidebarContentRef.current) {
        return
      }

      const scrollTop = scrollableElement?.scrollTop

      if (previousScrollTop === null) {
        previousScrollTop = scrollTop
      }

      const sidebarContentHeight = sidebarContentRef.current?.clientHeight ?? 0
      const sidebarContentChildrenHeight = sidebarContentRef.current?.children[0].clientHeight ?? Infinity
      let nextOffsetTop = sidebarRef.current?.getBoundingClientRect().top ?? minimalSidebarOffsetTop

      if (nextOffsetTop <= minimalSidebarOffsetTop) {
        nextOffsetTop = minimalSidebarOffsetTop
      }

      layoutRef.current.style.setProperty('--two-columns-offset-top', `${nextOffsetTop}px`)

      if (sidebarRef.current && sidebarContentInner) {
        if (
          scrollTop + sidebarContentInner.getBoundingClientRect().height >=
          layoutRef.current.getBoundingClientRect().height + initialSidebarOffsetTop
        ) {
          sidebarRef.current.classList.add('TwoColumns-Sidebar_unpinAtBottom')
        } else {
          sidebarRef.current.classList.remove('TwoColumns-Sidebar_unpinAtBottom')
        }
      }

      if (sidebarContentChildrenHeight < sidebarContentHeight) {
        previousScrollTop = scrollTop

        return
      }

      if (nextOffsetTop > minimalSidebarOffsetTop) {
        previousScrollTop = scrollTop

        if (sidebarContentRef.current) {
          sidebarContentRef.current.scrollTop = 0
        }

        return
      }

      const delta = scrollTop - previousScrollTop

      if (sidebarContentRef.current) {
        sidebarContentRef.current.scrollTop = sidebarContentRef.current.scrollTop + delta
      }

      previousScrollTop = scrollTop
    }

    scrollableElement.addEventListener('scroll', syncScroll)

    return () => {
      scrollableElement.removeEventListener('scroll', syncScroll)
    }
  }, [scrollableElement, minimalSidebarOffsetTop, initialSidebarOffsetTop, sidebarContentInner])

  useLayoutEffect(() => {
    if (synchronizedScroll && sidebarRef.current) {
      return subscribeToSyncScroll()
    }
  }, [synchronizedScroll, initialSidebarOffsetTop, minimalSidebarOffsetTop, sidebarOffsetBottom, subscribeToSyncScroll])

  return (
    <ColumnarLayout forwardedRef={layoutRef} className={className} style={style} mods={{ padding: 'no' }}>
      <Column className="TwoColumns-Content" size={1}>
        {content}
      </Column>
      <Column ref={sidebarRef} className="TwoColumns-Sidebar" size={0}>
        <Paper left={80}>
          <Width ref={sidebarContentRef} className="TwoColumns-SidebarContent" size={19}>
            {sidebar}
          </Width>
          <Phantom>
            <Width size={19} />
          </Phantom>
        </Paper>
      </Column>
    </ColumnarLayout>
  )
}

function TwoColumns({ className, ...rest }: TwoColumnsProps): React.ReactElement {
  const classes = useMemo(
    () =>
      classNames(className, 'TwoColumns', {
        TwoColumns_synchronizedScroll: rest.synchronizedScroll,
        TwoColumns_stickySidebar: rest.stickySidebar,
      }),
    [className, rest.synchronizedScroll, rest.stickySidebar],
  )

  if (rest.synchronizedScroll) {
    return <TwoColumnsScrollable className={classes} {...rest} />
  }

  return <TwoColumnsNonScrollable className={classes} {...rest} />
}

export { TwoColumns }
