import React from 'react'
import Toggle from 'react-toggle'

import classNames from 'classnames/dedupe.js'
import { PropTypes } from 'prop-types'

import { useMediaQueries } from 'hooks'

import 'react-toggle/style.css'
import './Switcher.css'

const Switcher = ({
  children,
  onChange,
  checked,
  multiline,
  inverse,
  'data-test-id': dataTestId,
  smallDistance = false,
  ...props
}) => {
  const { desktop } = useMediaQueries()
  const classes = classNames('Switcher', {
    Switcher_phone: !desktop || inverse,
    Switcher_multiline: multiline,
    Switcher_smallDistance: smallDistance,
  })

  return (
    <div className={classes} data-test-id={dataTestId} data-checked={checked}>
      <div className="Switcher-Switch" data-test-id={`${dataTestId}Switch`}>
        <Toggle {...props} checked={checked} icons={false} onChange={onChange} />
      </div>
      {children && <div className="Switcher-Label">{children}</div>}
    </div>
  )
}

Switcher.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  multiline: PropTypes.bool,
  inverse: PropTypes.bool,
  smallDistance: PropTypes.bool,
  'data-test-id': PropTypes.string,
}

export default Switcher
