import { useCallback, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import { useCreatePortfolio } from 'app/pages/Dashboard/Goals/hooks'

import { manageTypes, presetTypes, regulatoryTypes } from 'constants/portfolio'

const useOpenNewPortfolio = () => {
  const createPortfolio = useCreatePortfolio()

  const shouldSignSippDeclaration = useSelector((state) => !state.client.agreed_with_sipp_declaration)

  const handleSubmit = useCallback(
    async (selectedData) => {
      const fields = selectedData.reduce(
        (accum, field) => {
          if (field === manageTypes.DIY) {
            return { ...accum, manageType: manageTypes.DIY }
          }

          if (field === manageTypes.SELF_SELECTED) {
            return { ...accum, manageType: manageTypes.SELF_SELECTED }
          }

          if (field === manageTypes.MANAGED) {
            return { ...accum, manageType: manageTypes.MANAGED, presetType: presetTypes.GROWTH }
          }

          if (field === 'MANAGED_DEFAULT') {
            return { ...accum, manageType: manageTypes.MANAGED, presetType: presetTypes.GROWTH, isDefault: true }
          }

          if (field === regulatoryTypes.ISA) {
            return { ...accum, regulatoryType: regulatoryTypes.ISA, isIsaTransfer: false }
          }

          if (field === regulatoryTypes.GIA) {
            return { ...accum, regulatoryType: regulatoryTypes.GIA }
          }

          if (field === regulatoryTypes.SIPP) {
            return { ...accum, regulatoryType: regulatoryTypes.SIPP }
          }

          return accum
        },
        { regulatoryType: regulatoryTypes.GIA },
      )

      if (fields.manageType === manageTypes.SELF_SELECTED) {
        goTo(urlTo('self-selected-presets', {}, { fromDashboard: true, regulatoryType: fields.regulatoryType }))
        return
      }

      if (fields.regulatoryType === regulatoryTypes.SIPP && shouldSignSippDeclaration) {
        const currentLocation = window.location.pathname + window.location.search
        const newPortfolioData =
          fields.manageType === manageTypes.MANAGED
            ? {
                manageType: fields.manageType,
                regulatoryType: fields.regulatoryType,
                presetType: fields.presetType,
                isDefault: fields.isDefault ?? false,
              }
            : { manageType: fields.manageType, regulatoryType: fields.regulatoryType }
        goTo(
          urlTo('create-new-portfolio', null, {
            openSipp: true,
            back: currentLocation,
            ...newPortfolioData,
          }),
        )
        return
      }

      if (fields.manageType) {
        trackEvent({
          category: 'Create new portfolio',
          action: 'Select manage type',
          label: fields.manageType,
          portfolioManageType: fields.manageType,
        })
      }

      if (fields.regulatoryType) {
        trackEvent({
          category: 'Create new portfolio',
          action: 'Select account type',
          label: fields.regulatoryType,
          portfolioManageType: fields.manageType,
          portfolioRegulatoryType: fields.regulatoryType,
        })
      }

      if (fields.presetType) {
        trackEvent({
          category: 'Create new portfolio',
          action: 'Select portfolio type',
          label: fields.presetType,
          portfolioManageType: fields.manageType,
          portfolioRegulatoryType: fields.regulatoryType,
          portfolioPresetType: fields.presetType,
        })
      }

      await createPortfolio(fields)
    },
    [createPortfolio, shouldSignSippDeclaration],
  )

  return { handleSubmit }
}

export { useOpenNewPortfolio }
