import React from 'react'

import InlineHelp from 'components/_old/InlineHelp/InlineHelp.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import ModalContent, {
  ModalContentHeadline,
  ModalContentBody,
  ModalContentButtons,
} from 'components/molecules/ModalContent/ModalContent.jsx'

const AlternativesHelper = (): React.ReactElement => (
  <InlineHelp helper={<Link className="StackedBar-InlineHelp">Learn more</Link>}>
    {(handleClose) => (
      <ModalContent>
        <ModalContentHeadline>What are "alternative" investments?</ModalContentHeadline>
        <ModalContentBody>
          <Typo>
            <p>
              We define alternative investments as those that fall outside traditional stock and bond investments. We
              use alternative investments in your portfolio mainly because their returns have a low correlation with
              standard asset classes. So when these broader markets fall, alternative investments will generally rise.
            </p>
            <p>
              Using alternatives allows us to balance out your portfolio. It enables us to hedge against any downside
              risks, reduce volatility over time and ultimately enhance performance. Typically, our alternative ETF
              investments range from gold to real estate and commodities.
            </p>
            <p>
              And although alternative investments can be considered risky to the uninitiated investor – and some do
              come with appreciable risk – they are an essential component of a fully diversified portfolio.
            </p>
          </Typo>
        </ModalContentBody>
        <ModalContentButtons text="OK" onClick={handleClose} />
      </ModalContent>
    )}
  </InlineHelp>
)

export default AlternativesHelper
