import React from 'react'

import { Typo } from 'components/_old/Typo/Typo'

import { SimpleTextModal } from 'components/atoms/SimpleTextModal'

type RiskDescriptionModalProps = {
  isOpen: boolean
  onClose: () => void
}

const RiskDescriptionModal = ({ isOpen, onClose }: RiskDescriptionModalProps): React.ReactElement => {
  const textNode = (
    <Typo>
      The Risk Indicator shown is based on InvestEngine’s risk rating system.
      <br />
      <br />
      The Risk Indicator is a number between 1 and 7, with 1 indicating low risk and 7 indicating high risk. The Risk
      Indicator is calculated using a formula based on the volatility of past performance.
      <br />
      <br />
      The calculation methodology is included in the Key Features Document. The purpose of the Risk Indicator is to make
      it easier for investors to understand the risks and potential rewards of a portfolio. For example, a portfolio
      with a Risk Indicator of 1 has low risk but may also have low returns, while a portfolio with an Risk Indicator of
      7 has high risk but may also have high returns.
    </Typo>
  )

  return <SimpleTextModal title="Risk" text={textNode} isOpen={isOpen} onClose={onClose} />
}

export { RiskDescriptionModal }
