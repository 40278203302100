import React from 'react'
import PropTypes from 'prop-types'

import { useCallback } from 'hooks'

import { urlTo } from 'helpers/router.js'
import { format as formatDate } from 'helpers/date.js'
import { format as formatPhone } from 'helpers/phone.js'

import Card from 'components/_old/Card/Card.jsx'
import Text from 'components/_old/Text/Text.jsx'
import DotsMenu from 'components/organisms/DotsMenu/DotsMenu.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Icon from 'components/_old/Icon/Icon.jsx'

const PersonCard = ({
  data,
  onContactDelete: handleContactDelete,
  onSignificantControlDelete: handleSignificantControlDelete,
}) => {
  const handleDeleteData = useCallback(() => {
    if (data.isDirector) {
      handleContactDelete(data.id)
      return
    }
    handleSignificantControlDelete({ id: data.id })
  }, [data])

  return (
    <Card
      className="ListOfDirectorsAndShareholders-Item"
      mods={{ theme: data.isUploadDocumentsVisible ? 'white border-red' : 'white' }}
      data-test-id="listOfDirectorsAndShareholdersItem"
    >
      <div
        className="ListOfDirectorsAndShareholders-ItemTopLine"
        data-test-id="listOfDirectorsAndShareholdersItemTopLine"
      >
        <Text
          className="listOfDirectorsAndShareholders-ItemName"
          block
          data-test-id="listOfDirectorsAndShareholdersItemName"
        >
          {data.name}
        </Text>
        {!data.isMainApplicant && (
          <DotsMenu data-test-id="listOfDirectorsAndShareholdersItemDotsMenu">
            <Text block right>
              <Link
                to={
                  data.isDirector
                    ? urlTo('adding-director', { contactId: data.id })
                    : urlTo('adding-significant-control', { significantControlId: data.id })
                }
                data-test-id="listOfDirectorsAndShareholdersItemEditLink"
              >
                Edit info
              </Link>
            </Text>
            <Text block right>
              <Link onClick={handleDeleteData} data-test-id="listOfDirectorsAndShareholdersItemDeleteLink">
                Delete
              </Link>
            </Text>
          </DotsMenu>
        )}
      </div>
      {data.isMainApplicant && (
        <Text block small>
          <Typo>Main applicant</Typo>
        </Text>
      )}
      {!data.isMainApplicant && data.isDirector && (
        <Text block small>
          <Typo>Director</Typo>
        </Text>
      )}
      {data.isDirector && data.hasSignificantControl && (
        <Text block small>
          <Typo>This person has significant control over the company</Typo>
        </Text>
      )}
      {!data.isDirector && (
        <Text block small>
          <Typo>A person or entity with significant control</Typo>
        </Text>
      )}
      <Text block small data-test-id="listOfDirectorsAndShareholdersItemAddress">
        <Typo>{`Address: ${data.currentAddress}`}</Typo>
      </Text>
      {data.isDirector && (
        <Text block small data-test-id="listOfDirectorsAndShareholdersItemBirthday">
          <Typo>{`Date of birth: ${data?.birthday?.includes('*') ? data.birthday : formatDate(data.birthday)}`}</Typo>
        </Text>
      )}
      {data.isDirector && data.phone && (
        <Text block small data-test-id="listOfDirectorsAndShareholdersItemPhone">
          <Typo>{`Tel: ${formatPhone(data.phone)}`}</Typo>
        </Text>
      )}
      {data.isUploadDocumentsVisible && (
        <div
          className="ListOfDirectorsAndShareholders-ItemUploadDocuments"
          data-test-id="listOfDirectorsAndShareholdersItemUploadDocuments"
        >
          <Icon
            className="ListOfDirectorsAndShareholders-ItemUploadDocumentsIcon"
            type="attention-red"
            size={21}
            inline
          />
          <Link to={urlTo('director-proof-of-identity', { contactId: data.id })}>Upload documents</Link>
        </div>
      )}
    </Card>
  )
}

PersonCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    isDirector: PropTypes.bool,
    hasSignificantControl: PropTypes.bool,
    isMainApplicant: PropTypes.bool,
    isUploadDocumentsVisible: PropTypes.bool,
    state: PropTypes.string,
    name: PropTypes.string,
    currentAddress: PropTypes.string,
    birthday: PropTypes.string,
    phone: PropTypes.string,
  }),
  onContactDelete: PropTypes.func,
  onSignificantControlDelete: PropTypes.func,
}

export { PersonCard }
