import * as React from 'react'

import { useMediaQueries } from 'hooks'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import { Modal } from 'components/molecules/Modal'

import { type HoldingData } from '../hooks/useHoldingModal'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

type HoldingModalProps = {
  open: boolean
  isPortfolioNew: boolean
  selectedPortfolio: Portfolio
  holdingData: HoldingData | null
  holdingDistribution: Array<{ value: number; color: string }>
  closeHoldingModal: () => void
}

const HoldingModal = ({
  open = false,
  isPortfolioNew,
  selectedPortfolio,
  holdingData,
  holdingDistribution,
  closeHoldingModal,
}: HoldingModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const props = {
    isPortfolioNew,
    selectedPortfolio,
    holdingData,
    holdingDistribution,
  }

  return (
    <Modal open={open} onClose={closeHoldingModal}>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} handleBack={closeHoldingModal} />}
    </Modal>
  )
}

export { HoldingModal }
