import { usePrevious, useReactHookForm, useReactHookFormWatch as useFormWatch, useCallback, useEffect } from 'hooks'

const useForm = (defaultValues = {}, mode = 'all', reValidateMode = 'onChange') => {
  const previousDefaultValues = usePrevious(defaultValues)

  const form = useReactHookForm({
    defaultValues,
    mode,
    reValidateMode,
  })

  const { control, handleSubmit, errors, formState, getValues, setValue, watch } = form
  const { isValid, dirtyFields } = formState

  const setValues = useCallback(
    (values) => {
      form.reset(values)
    },
    [form],
  )

  const handleReset = useCallback(() => {
    form.reset(defaultValues)
  }, [defaultValues, form])

  useEffect(() => {
    if (
      !form.formState.isDirty &&
      previousDefaultValues &&
      JSON.stringify(defaultValues) !== JSON.stringify(previousDefaultValues)
    ) {
      handleReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, previousDefaultValues])

  return {
    control,
    isValid,
    formState,
    dirtyFields,
    errors,
    watch,
    getValues,
    setValue,
    setValues,
    handleReset,
    handleSubmit,
  }
}

export { useForm, useFormWatch }
