import React from 'react'
import classNames from 'classnames/dedupe'

import compose from 'helpers/compose.js'
import rawMediaQueries from 'helpers/mediaQueries.js'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { Logo } from 'components/_old/Logo/Logo.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'

import './ErrorPage.css'

const ErrorPage = ({ children, errorCode, headline, mediaQueries }) => {
  const classes = classNames('ErrorPage', 'Layout-Holder', getMediaQuieryClasses('ErrorPage', mediaQueries))

  return (
    <div className={classes}>
      <header className="ErrorPage-Header">
        <Inner mods={{ size: 'small' }}>
          <Link className="Logo" to="/" hard>
            <Logo style={{ height: 48 }} />
          </Link>
        </Inner>
      </header>
      <div className="ErrorPage-Content">
        <AllCenter>
          <Inner mods={{ size: 'small' }}>
            <Headline
              className="ErrorPage-Headline"
              level={1}
              mods={{
                size: 'gargantuan',
                'no-margin': 'top',
              }}
            >
              {errorCode}: {headline}
            </Headline>
            <div className="ErrorPage-Text">
              <Text muted>{children}</Text>
            </div>
            <ColumnarLayout className="ErrorPage-Bottom">
              <Column>
                <div className="ErrorPage-BottomInfo">
                  <Headline className="ErrorPage-Headline" level={3} mods={{ text: 'normal' }}>
                    Contact us:
                  </Headline>
                  <Link className="ErrorPage-BottomItem" to="https://help.investengine.com/" hard blank>
                    <Headline level={4} mods={{ text: 'normal' }} style={{ display: 'inline' }}>
                      Help Centre
                    </Headline>
                  </Link>
                </div>
              </Column>
              <Column>
                <div className="ErrorPage-BottomInfo">
                  <Headline className="ErrorPage-Headline" level={3} mods={{ text: 'normal' }}>
                    Visit pages below:
                  </Headline>
                  <Link hard to="/">
                    <Headline className="ErrorPage-BottomItem" level={4} mods={{ text: 'normal', 'no-margin': 'all' }}>
                      Homepage
                    </Headline>
                  </Link>
                  <Link hard to="/pre-flight/">
                    <Headline className="ErrorPage-BottomItem" level={4} mods={{ text: 'normal', 'no-margin': 'all' }}>
                      Get started
                    </Headline>
                  </Link>
                </div>
              </Column>
            </ColumnarLayout>
          </Inner>
        </AllCenter>
      </div>
    </div>
  )
}

export default compose(withMediaQueries(rawMediaQueries))(ErrorPage)
