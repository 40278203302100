import React, { useCallback } from 'react'

import { useRef } from 'hooks'

import { isFormControlElement } from 'helpers/forms/isFormControlElement'

const InvalidClasses = ['.Input_error', '.Typography_color_error']

function isFormElementValid(element: Element): boolean {
  // Check if element has error classes
  if (element.closest(InvalidClasses.join(','))) {
    return false
  }

  // Check validity state for form controls
  if (isFormControlElement(element)) {
    return element.validity.valid
  }

  return true
}

function isFormValid(form: HTMLFormElement): boolean {
  return Array.from(form.elements).every(isFormElementValid)
}

async function forceValidateForm(form: HTMLFormElement): Promise<void> {
  for (let formInput = 0; formInput < form.length; formInput++) {
    const input = form[formInput]

    if (isFormControlElement(input)) {
      input.focus({
        preventScroll: true,
      })
      input.blur()
    }
  }

  // let react update dom by waiting for the next tick
  await new Promise((resolve) => setTimeout(resolve, 0))
}

function scrollToFirstInvalidElement(form: HTMLFormElement): void {
  const firstInvalidElement = Array.from(form.elements).find((element) => !isFormElementValid(element))

  if (firstInvalidElement && isFormControlElement(firstInvalidElement)) {
    firstInvalidElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }
}

type FormProps = React.HTMLProps<HTMLFormElement> & {
  children: React.ReactNode
  onSubmit?: React.FormEventHandler<HTMLFormElement> | (() => void)
}

function Form({ children, onSubmit, ...rest }: FormProps): JSX.Element {
  const formRef = useRef<HTMLFormElement | null>(null)

  const handleFormValidation = useCallback(
    async (form: HTMLFormElement, event: React.FormEvent<HTMLFormElement>) => {
      await forceValidateForm(form)

      if (isFormValid(form)) {
        onSubmit?.(event)
        return
      }

      scrollToFirstInvalidElement(form)
    },
    [onSubmit],
  )

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      const form = formRef.current

      if (!form) {
        return
      }

      void handleFormValidation(form, event)
    },
    [handleFormValidation],
  )

  return (
    <form ref={formRef} onSubmit={handleSubmit} noValidate {...rest}>
      {children}
    </form>
  )
}

export { Form }
