import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { bulkValidate } from 'helpers/validation.js'

import { Typo } from 'components/_old/Typo/Typo'
import { Typography } from 'components/atoms/Typography'
import { GatewayDest } from 'components/atoms/Gateway'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'

import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'

import { Progress } from 'components/_old/Progress/'
import Headline from 'components/_old/Headline/Headline.jsx'
import Button from 'components/_old/Button/Button.jsx'
import ContentHolder from 'components/molecules/ContentHolder/ContentHolder.jsx'
import YearsOldInput from '../components/YearsOldInput.jsx'
import TextInput from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/components/TextInput.jsx'

const Mobile = ({
  goal,
  owner,
  yearsOld,
  validation,
  handleAgeChange,
  handleInitialDepositChange,
  onSubmit,
  handleBack,
  handleCancel,
  progress,
  activeStep,
  skipAge,
  noDescription,
  noInputLabel,
}) => {
  const submitDisabled = !bulkValidate(validation)
  const { initial_deposit } = goal
  const title = activeStep.headline

  const yoInput = skipAge ? null : (
    <YearsOldInput value={yearsOld} validation={validation} handleChange={handleAgeChange} />
  )

  const idInput = (
    <TextInput
      value={initial_deposit}
      validationRule={validation.initial_deposit}
      handleChange={handleInitialDepositChange}
    />
  )

  const question = useMemo(
    () => (
      <Fragment>
        {(skipAge ? false : !owner.age) && (
          <Fragment>
            I’m {yoInput} years old
            <br />
          </Fragment>
        )}
        {noInputLabel ? null : 'I would like to invest '}
        {idInput}
      </Fragment>
    ),
    [skipAge, owner.age, noInputLabel, yoInput, idInput],
  )

  const description = !noDescription && (
    <Typography size={16} align="center">
      <Typo>
        Fill in our questionnaire commitment-free and we'll suggest a portfolio built and managed by our investment team
        to suit your profile.
        <br />
        <br />
        You can always change your answers or amount you'd like to invest before continuing.
      </Typo>
    </Typography>
  )

  const heading = useMemo(
    () => (
      <Paper top={8} bottom={16}>
        <Typography size={20} weight="semibold" align="center">
          <Typo>{title}</Typo>
        </Typography>
      </Paper>
    ),
    [title],
  )

  const content = useMemo(
    () => (
      <ContentHolder className="_newTextQuestion-Form" forceAllCenter stretch>
        <SubmitOnEnter>
          <Paper top={8} bottom={16}>
            <Typography size={20} weight="semibold" align="center">
              <Typo>{heading}</Typo>
            </Typography>
          </Paper>
          <Headline
            level={2}
            mods={{
              text: 'normal center light',
              'no-margin': 'top',
              'line-height': 'bigger',
              overflow: 'visible',
            }}
          >
            {question}
          </Headline>
          <Paper top={8} bottom={32}>
            <GatewayDest className="_newTextQuestion-Error" name="text-question-error" />
          </Paper>
          {description}
        </SubmitOnEnter>
      </ContentHolder>
    ),
    [question, description],
  )

  const footer = useMemo(
    () => (
      <MobileLayoutFooterButton>
        <Button
          type="submit"
          mods={{ size: 'big block' }}
          onClick={onSubmit}
          disabled={submitDisabled}
          tabIndex={3}
          data-test-id="tunnelStepGetStartedSubmitButton"
        >
          Continue
        </Button>
      </MobileLayoutFooterButton>
    ),
    [onSubmit, submitDisabled],
  )

  return (
    <MobileLayout
      header={
        <Fragment>
          <NavigationBar
            leftPartText="Back"
            onLeftPartClick={handleBack}
            rightPartText={handleCancel ? 'Cancel' : undefined}
            onRightPartClick={handleCancel || undefined}
          >
            Get started
          </NavigationBar>
          <Progress fill={progress} gapWidth="1em" />
        </Fragment>
      }
      contentPaperSizes={{ top: 16, left: 16, right: 16, bottom: 16 }}
      content={content}
      footer={footer}
    />
  )
}

Mobile.propTypes = {
  goal: PropTypes.object,
  owner: PropTypes.object,
  yearsOld: PropTypes.number,
  validation: PropTypes.object,
  handleAgeChange: PropTypes.func,
  handleInitialDepositChange: PropTypes.func,
  onSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  handleCancel: PropTypes.func,
  progress: PropTypes.number,
  activeStep: PropTypes.object,
  skipAge: PropTypes.bool,
  noDescription: PropTypes.bool,
  noInputLabel: PropTypes.bool,
}

export { Mobile }
