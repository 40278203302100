import React from 'react'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette'

import Icon from 'components/_old/Icon/Icon.jsx'

import ReactSelect from 'react-select'

import './Select.css'

const DropdownIndicator = () => <Icon type="chevron" size={16} color={palette['content-on-background-minor']} />

export default class Select extends React.Component {
  constructor(props) {
    super(props)
    this.selectRef = React.createRef()
  }

  render() {
    let {
      forceUnnative,
      async,
      className,
      value,
      options = [],
      placeholder,
      valid,
      callbackRef,
      onChange,
      withArrow = true,
      clearable = true,
      searchable = true,
      ...restProps
    } = this.props

    const isSelectedDisabledOption = options.find((option) => option.value === value)?.disabled
    const classes = classNames(className, 'Select', { Select_disabledValue: isSelectedDisabledOption })
    const shouldRenderArrow = withArrow && (!clearable || (clearable && !value))

    if (async) {
      restProps = {
        ...restProps,
        autoload: false,
        ignoreCase: true,
        cacheAsyncResults: false,
      }
    }

    if (forceUnnative) {
      const parentOnChange = onChange

      onChange = (option) => {
        const { value } = option ?? {}
        parentOnChange?.(undefined, value)
      }
    }

    if (!forceUnnative) {
      const parentOnChange = onChange
      onChange = (event, value) => {
        const option = event.target.querySelector(`[value*="${event.target.value}"]`)

        if (option) {
          value = option.value
        }

        parentOnChange?.(event, value || null)
      }
    }

    const selectProps = {
      className: classes,
      valid,
      placeholder,
      onChange,
      arrowRenderer: shouldRenderArrow ? DropdownIndicator : () => null,
      clearable,
      searchable,
      openOnClick: false,
      openOnFocus: true,
      ref: this.selectRef,
      ...restProps,
      onOpen: () => {
        // hack to properly open dropdown on mobile
        setTimeout(() => {
          this.selectRef.current.focus()
        }, 0)
      },
    }

    return forceUnnative ? (
      async ? (
        <ReactSelect.Async {...selectProps} />
      ) : (
        <ReactSelect
          {...selectProps}
          options={options.map((option) => ({ value: option.value, label: option.name }))}
          value={value || undefined}
        />
      )
    ) : (
      <span className="SelectHolder">
        <select
          ref={callbackRef}
          className={classNames('Select_native', classes)}
          value={value}
          onChange={onChange}
          {...restProps}
        >
          {placeholder && (
            <option value="" disabled selected>
              {placeholder}
            </option>
          )}
          {options.map((o, i) => (
            <option value={o.value} key={i} disabled={o.disabled}>
              {o.name}
            </option>
          ))}
        </select>
        <Icon className="SelectIcon" type="chevron" size={24} inline color={palette['content-on-background-primary']} />
      </span>
    )
  }
}
