import React from 'react'

import { useContext, useMemo } from 'hooks'

import { MobileLayoutContext } from '../../MobileLayout'

import './MobileLayoutFullViewport.css'

type MobileLayoutFullViewportProps = {
  children: React.ReactNode | React.ReactNodeArray
}

function MobileLayoutFullViewport({ children }: MobileLayoutFullViewportProps): React.ReactElement {
  const { offset } = useContext(MobileLayoutContext)
  const style: React.CSSProperties = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ({ '--mobile-layout-offset-top': `${offset.top ?? 0}px` }) as React.CSSProperties,
    [offset.top],
  )

  return (
    <div className="MobileLayoutFullViewport" style={style}>
      {children}
    </div>
  )
}

export { MobileLayoutFullViewport, type MobileLayoutFullViewportProps }
