import { useActions, useCallback, useSelector, useState } from 'hooks'

import { changeNotificationSettings as changeNotificationSettingsActionCreator } from 'app/redux/actions/ui'

type UseInvestEngineNewsInterface = {
  promo: boolean
  isModalVisible: boolean
  handleChangePromoNotificationSettings: () => void
  handleCloseModal: () => void
  handleContinueModal: () => void
}

const useInvestEngineNews = (): UseInvestEngineNewsInterface => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { promo } = useSelector((state) => state.ui.settings.notifications)
  const changeNotificationSettings = useActions(changeNotificationSettingsActionCreator)

  const handleChangePromoNotificationSettings = useCallback(() => {
    if (promo) {
      setIsModalVisible(true)
      return
    }

    changeNotificationSettings('promo', true)
  }, [changeNotificationSettings, promo])

  const handleCloseModal = useCallback(() => {
    setIsModalVisible(false)
  }, [])

  const handleContinueModal = useCallback(() => {
    changeNotificationSettings('promo', false)
    setIsModalVisible(false)
  }, [changeNotificationSettings])

  return {
    promo,
    isModalVisible,
    handleChangePromoNotificationSettings,
    handleCloseModal,
    handleContinueModal,
  }
}

export { useInvestEngineNews, type UseInvestEngineNewsInterface }
