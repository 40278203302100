export const MIN_ALLOWED_MONTHS = 36

export const MAX_ADDRESSES_NUMBER = 4

export const emptyAddress = {
  id: null,
  street: null,
  street2: null,
  postcode: null,
  country: null,
  city: null,
  months_at_address: 0,
  is_current: true,
}
