import { goTo, urlTo } from 'helpers/router.js'

import type { StepProviderAdapter, StepProviderStep } from 'app/containers/PortfolioTunnel'

type SetupAccountDataStepAdapterProps = {
  routes: Array<{
    [key: string]: unknown
    module: string
  }>
  location: {
    query: object
  }
}

const SetupAccountDataStepAdapter = ({ routes, location }: SetupAccountDataStepAdapterProps): StepProviderAdapter => {
  const getActiveStepIndex = (steps: StepProviderStep[]): number =>
    steps.findIndex((step) => [...routes].reverse().find((route) => route.module === step.initModule))

  const getStepByName = (steps: StepProviderStep[], module: string): StepProviderStep | null =>
    steps.find((step) => step.initModule === module) ?? null

  const redirect = (step: StepProviderStep, customParams?: Record<string, string>): void => {
    goTo(urlTo(step.module, customParams, location.query))
  }

  return {
    getActiveStepIndex,
    getStepByName,
    redirect,
  }
}

export { SetupAccountDataStepAdapter, type SetupAccountDataStepAdapterProps }
