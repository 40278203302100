import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Inner from 'components/_old/Inner/Inner.jsx'
import Width from 'components/_old/Width/Width'

import { ErrorBoundary } from 'components/atoms/ErrorBoundary/ErrorBoundary'
import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'

import { MenuSection } from 'components/molecules/Menu/MenuSection'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader, MobileHeader } from 'app/containers/Header'

import { PromoBanner } from 'app/pages/Dashboard/PromoBanner'

import { ProfileHeader } from './ProfileHeader'
import { useProfile } from './useProfile'

const Profile = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    generalMenuItems,
    accountMenuItems,
    fundingMenuItems,
    businessMenuItems,
    userSettingsMenuItems,
    isPromoBannerDisplayed,
    isBusiness,
    saveScrollPosition,
  } = useProfile()

  const promoBannerNode = isPromoBannerDisplayed && (
    <ErrorBoundary fallback={null}>
      <Paper top={48}>
        <PromoBanner />
      </Paper>
    </ErrorBoundary>
  )

  const content = (
    <Width size={30} center>
      <Paper top={16}>
        <ProfileHeader />
        {promoBannerNode}
        <Paper top={48}>
          <MenuSection title="General" items={generalMenuItems} saveScrollPosition={saveScrollPosition} />
        </Paper>
        {fundingMenuItems.length > 0 && (
          <Paper top={48}>
            <MenuSection title="Funding" items={fundingMenuItems} saveScrollPosition={saveScrollPosition} />
          </Paper>
        )}

        {isBusiness && businessMenuItems.length > 0 && (
          <Paper top={48}>
            <MenuSection title="Business settings" items={businessMenuItems} saveScrollPosition={saveScrollPosition} />
          </Paper>
        )}
        {userSettingsMenuItems.length > 0 && (
          <Paper top={48}>
            <MenuSection title="User settings" items={userSettingsMenuItems} saveScrollPosition={saveScrollPosition} />
          </Paper>
        )}

        <Paper top={48} bottom={desktop ? 0 : 48}>
          <MenuSection title="Account" items={accountMenuItems} saveScrollPosition={saveScrollPosition} />
        </Paper>
      </Paper>
    </Width>
  )

  return (
    <Fragment>
      {desktop ? (
        <DesktopLayout header={<DesktopHeader />} footer={<DesktopFooter />} content={<Inner>{content}</Inner>} />
      ) : (
        <MobileLayout header={<MobileHeader />} content={content} />
      )}

      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

export { Profile }
