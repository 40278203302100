import { createSelector } from 'reselect'

import moment from 'helpers/date.js'
import { getKindByGoal, getKindName } from 'helpers/goals.js'

import { isClientRegistered, isClientBankFilled } from './client.js'

import {
  regulatoryTypes as goalRegulatoryTypes,
  states as goalStates,
  portfolioManageTypes,
  kindTitles as goalKindTitles,
} from 'constants/goal'

const selectGoalById = (state, id) => state.portfolios.items.find((goal) => goal.id === id)

const isAddFundsAllowed = (state, id) => {
  const goal = selectGoalById(state, id) || {}

  return isClientRegistered(state) && isClientBankFilled(state) && goal.state === goalStates.APPROVED
}

const selectGoalsList = ({ portfolios }) => portfolios.items
const goalIdFromProps = (_, props) => props.goalId
const goalByIdReselect = createSelector(
  [goalIdFromProps, selectGoalsList],
  (id, goals) => goals.find((goal) => goal.id === id) || {},
)

const checkGoalIsLocked = (goal) => {
  if (!goal) {
    return false
  }
  const unlockDate = moment(goal.preset_modified).add(2, 'day').toDate()

  return goal.state !== goalStates.NEW && goal.first_topup && moment(unlockDate).diff(new Date()) > 0
}

const isLockedGoal = (state, id) => {
  const goal = selectGoalById(state, id)

  return checkGoalIsLocked(goal)
}

const selectSelectedGoal = ({ portfolios }) => {
  return portfolios.items.find((goal) => goal.id === portfolios.selectedGoal)
}

const selectGoalTitle = ({ portfolios }, id) => {
  const defaultPortfolioName = 'Portfolio'
  const goal = portfolios.items.find((goal) => goal.id === id)

  if (!id || !goal) {
    return defaultPortfolioName
  }

  if (goal.title) {
    return goal.title
  }

  if (goal.manage_type === portfolioManageTypes.DIY) {
    return 'DIY'
  }

  if (goal.manage_type === portfolioManageTypes.CASH) {
    return 'Cash'
  }

  const { presets } = portfolios
  const preset = presets[goal.preset_type]?.find(({ id }) => id === goal.preset)

  if (preset) {
    return preset.title
  }

  return defaultPortfolioName
}

const getGoalTitle = (state, goalId, defaultGoalKind = null) => {
  const goalTitle = selectGoalTitle(state, goalId)
  const goal = selectGoalById(state, goalId)
  const goalKind = getKindByGoal(state.client, goal) ?? defaultGoalKind
  const isExist = Boolean(goal) && goal?.state !== goalStates.NEW

  return isExist ? `${getKindName(goalKind)} ${goalTitle}` : `${getKindName(goalKind)} ${goalKindTitles[goalKind]}`
}

const selectIsGoalInClosing = (state, goalId) => {
  const goal = selectGoalById(state, goalId)

  if (goal?.state) {
    return goal.state === goalStates.CLOSING
  }

  return false
}

const selectPortfoliosList = ({ portfolios }) => portfolios.list

const getSortedRegulatoryTypes = ({ portfolios }) => {
  const groupedPortfolios = portfolios.list.getVisiblePortfolios().groupByRegulatoryType()

  const isIsaAccountOpened = groupedPortfolios?.[goalRegulatoryTypes.ISA]?.length > 0
  const isSippAccountOpened = groupedPortfolios[goalRegulatoryTypes.SIPP]?.length > 0

  let order = [goalRegulatoryTypes.ISA, goalRegulatoryTypes.GIA, goalRegulatoryTypes.SIPP]

  if (!isIsaAccountOpened && !isSippAccountOpened) {
    order = [goalRegulatoryTypes.GIA, goalRegulatoryTypes.ISA, goalRegulatoryTypes.SIPP]
  }

  if (isIsaAccountOpened && !isSippAccountOpened) {
    order = [goalRegulatoryTypes.ISA, goalRegulatoryTypes.GIA, goalRegulatoryTypes.SIPP]
  }

  if (isSippAccountOpened && !isIsaAccountOpened) {
    order = [goalRegulatoryTypes.GIA, goalRegulatoryTypes.SIPP, goalRegulatoryTypes.ISA]
  }

  const regulatoryTypesPositions = {
    [goalRegulatoryTypes.GIA]: groupedPortfolios[goalRegulatoryTypes.GIA]?.[0]?.display?.regulatory_type_position,
    [goalRegulatoryTypes.ISA]: groupedPortfolios[goalRegulatoryTypes.ISA]?.[0]?.display?.regulatory_type_position,
    [goalRegulatoryTypes.SIPP]: groupedPortfolios[goalRegulatoryTypes.SIPP]?.[0]?.display?.regulatory_type_position,
  }

  // sort groups by regulaty type position
  return order.sort((a, b) => {
    const regTypeAPosition = regulatoryTypesPositions[a]
    const regTypeBPosition = regulatoryTypesPositions[b]
    const regTypeAHasPosition = typeof regTypeAPosition === 'number'
    const regTypeBHasPosition = typeof regTypeBPosition === 'number'

    if (regTypeAHasPosition && regTypeBHasPosition) {
      return regTypeAPosition - regTypeBPosition
    }

    return regTypeAHasPosition ? -1 : regTypeBHasPosition ? 1 : 0
  })
}

const getFlatSortedPortfoliosList = createSelector(
  [selectPortfoliosList, getSortedRegulatoryTypes],
  (portfoliosList, regulatoryTypesOrder) => {
    return portfoliosList.getVisiblePortfolios().getFlatSortedList(regulatoryTypesOrder)
  },
)

export {
  selectGoalById,
  selectSelectedGoal,
  isAddFundsAllowed,
  isLockedGoal,
  selectGoalTitle,
  getGoalTitle,
  selectGoalsList,
  goalByIdReselect,
  checkGoalIsLocked,
  selectIsGoalInClosing,
  getSortedRegulatoryTypes,
  getFlatSortedPortfoliosList,
}
