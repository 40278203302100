import React from 'react'

import PropTypes from 'prop-types'

import { useCallback, useMemo, useMediaQueries } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { showSuccessToast } from 'app/redux/actions/ui'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

type AutoinvestAfterSellProps = {
  open: boolean
  portfolioId: number
}

const AutoinvestAfterSell = ({ open = false, portfolioId }: AutoinvestAfterSellProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const handleClose = useCallback(() => {
    goTo(urlTo('dashboard.portfolio', { id: portfolioId }), { replace: true })
    showSuccessToast('AutoInvest deactivated')
  }, [portfolioId])

  const content = useMemo(
    () => (
      <Paper>
        <Typography size={desktop ? 32 : 24} weight="semibold" align="center" lineHeight="small">
          <Typo>Reactivating AutoInvest</Typo>
        </Typography>
        <Paper top={desktop ? 32 : 12}>
          <Typography align="center" size={desktop ? 14 : 16}>
            <Typo> A Sell order automatically turns off AutoInvest. To reactivate AutoInvest, go to</Typo>{' '}
            <Typo>Options menu after you have withdrawn the proceeds of your Sell order.</Typo>
          </Typography>
        </Paper>
      </Paper>
    ),
    [desktop],
  )

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose} zIndex={1001}>
        <ModalContent width={38} center data-test-id="autoinvestReactivatingModal">
          <Paper top={80} bottom={80} left={48} right={48}>
            {content}
            <Paper top={56}>
              <Width size={24} center>
                <Button onClick={handleClose} mods={{ size: 'big block' }} data-test-id="reactivatingContinueButton">
                  Continue
                </Button>
              </Width>
            </Paper>
          </Paper>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={handleClose} zIndex={1001}>
      <MobileLayout
        header={<NavigationBar onRightPartClick={handleClose} rightPartText="Close" />}
        content={
          <AllCenter>
            <Paper top={48} bottom={48}>
              {content}
            </Paper>
          </AllCenter>
        }
        footer={
          <MobileLayoutFooterButton>
            <Button onClick={handleClose}>Continue</Button>
          </MobileLayoutFooterButton>
        }
      />
    </Modal>
  )
}

AutoinvestAfterSell.propTypes = {
  portfolioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool.isRequired,
}

export { AutoinvestAfterSell }
