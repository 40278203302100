import accounts from './Accounts/routes.jsx'
import { routes as securities } from './Securities/routes'
import goals from './Goals/routes.jsx'
import verifyEmail from './VerifyEmail/routes'
import transactions from './Transactions/routes.jsx'
import analytics from './Analytics/routes.jsx'
import reports from './Reports/routes.tsx'
import { routes as profile } from './UserProfile/routes'
import setupAccount from './SetupAccount/routes.jsx'
import grantAccess from './GrantAccess/routes.tsx'
import thirdPartyAuth from './ThirdPartyAuth/routes'
import isaDeclaration from './Goals/ISADeclaration/routes'
import sippDeclaration from './Goals/SippDeclaration/routes'
import { routes as claimBonus } from './ClaimBonus/routes'
import transferIsa from './TransferIsa/routes.tsx'

const childRoutes = [
  accounts,
  securities,
  ...goals,
  verifyEmail,
  transactions,
  analytics,
  reports,
  profile,
  setupAccount,
  grantAccess,
  thirdPartyAuth,
  isaDeclaration,
  sippDeclaration,
  claimBonus,
  transferIsa,
]

export default {
  module: 'dashboard',
  path: 'dashboard/',
  name: 'My Dashboard',
  childRoutes,
  indexRoute: childRoutes[0],
}
