import React from 'react'

import { InstantPayment } from './InstantPayment.jsx'

export const routes = {
  module: 'instant-payment',
  path: 'instant-payment/',
  component: function Component(props) {
    return <InstantPayment {...props} />
  },
}
