import React from 'react'
import PropTypes from 'prop-types'

import InlineHelp from 'components/_old/InlineHelp/InlineHelp.jsx'
import ModalContent, {
  ModalContentHeadline,
  ModalContentBody,
  ModalContentButtons,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import { Typo } from 'components/_old/Typo/Typo'

const HeadlineHelp = ({ title, body, iconType, color, background }) => (
  <InlineHelp iconType={iconType} color={color} background={background}>
    {(handleClose) => (
      <ModalContent>
        {title && <ModalContentHeadline data-test-id="headlineHelpModalTitle">{title}</ModalContentHeadline>}
        <ModalContentBody data-test-id="headlineHelpModalBody">
          <Typo>{body}</Typo>
        </ModalContentBody>
        <ModalContentButtons text="OK" onClick={handleClose} data-test-id="closeHeadlineHelpModalButton" />
      </ModalContent>
    )}
  </InlineHelp>
)

HeadlineHelp.propTypes = {
  iconType: PropTypes.string,
  color: PropTypes.string,
  background: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  body: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
}

export default HeadlineHelp
