import React from 'react'

import { useCallback, useMediaQueries } from 'hooks'

import { type PortfolioList } from 'app/redux/models/portfolio/'

import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'

import { Modal } from 'components/molecules/Modal'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

import { PortfoliosGroup } from './PortfoliosGroup'

import { regulatoryTypes } from 'constants/portfolio'

type SelectCashModalProps = {
  isOpen: boolean
  onClose: () => void
  onSelect: (id: string) => void
  isBusiness: boolean
  groupedPortfolios: Record<string, PortfolioList>
}

const SelectCashModal = ({
  isOpen,
  onClose: handleClose,
  groupedPortfolios,
  onSelect: handleSelect,
  isBusiness,
}: SelectCashModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const handlePortfolioSelect = useCallback(
    (portfolioId) => {
      handleSelect(portfolioId)
      handleClose()
    },
    [handleSelect, handleClose],
  )

  const isaNode =
    groupedPortfolios[regulatoryTypes.ISA]?.length > 0 ? (
      <Paper bottom={48}>
        <PortfoliosGroup
          title="ISA"
          portfolios={groupedPortfolios[regulatoryTypes.ISA]}
          isBusiness={isBusiness}
          handlePortfolioSelect={handlePortfolioSelect}
        />
      </Paper>
    ) : null

  const giaNode =
    groupedPortfolios[regulatoryTypes.GIA]?.length > 0 ? (
      <PortfoliosGroup
        title="General"
        portfolios={groupedPortfolios[regulatoryTypes.GIA]}
        isBusiness={isBusiness}
        hideTitle={isBusiness}
        handlePortfolioSelect={handlePortfolioSelect}
      />
    ) : null

  const sippNode =
    groupedPortfolios[regulatoryTypes.SIPP]?.length > 0 ? (
      <PortfoliosGroup
        title="Personal Pension"
        portfolios={groupedPortfolios[regulatoryTypes.SIPP]}
        isBusiness={isBusiness}
        handlePortfolioSelect={handlePortfolioSelect}
      />
    ) : null

  const content = (
    <Width size={24} style={{ minHeight: 520 }} center>
      <Paper top={desktop ? 32 : 0}>
        {isaNode}
        {giaNode}
        {sippNode}
      </Paper>
    </Width>
  )

  return (
    <Modal open={isOpen} onClose={handleClose} close={null}>
      <OptionsLayout
        header={
          <OptionsNavigationBar leftPartText="Back" onLeftPartClick={handleClose} rightPartText="">
            <Typo>
              <span style={{ whiteSpace: 'nowrap' }}>Choose cash account</span>
            </Typo>
          </OptionsNavigationBar>
        }
        content={content}
        button={null}
        data-test-id="selectCashModal"
      />
    </Modal>
  )
}

export { SelectCashModal }
