import React from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type SmsCodeIssueProps = {
  isLogin?: boolean
  handleBack: () => void
}

const SmsCodeIssue = ({ isLogin, handleBack }: SmsCodeIssueProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const headerNode = useMemo(() => <NavigationBar onLeftPartClick={handleBack} leftPartText="Back" />, [handleBack])

  const iconNode = <Icon type="question-in-bubble-80" size={80} color={palette['content-on-background-primary']} />

  const titleNode = useMemo(
    () => (
      <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
        <Typo>Didn’t receive a code?</Typo>
      </Typography>
    ),
    [desktop],
  )

  const descriptionNode = useMemo(
    () => (
      <Typography size={desktop ? 14 : 16} align="center">
        {isLogin ? (
          <Typo>
            If you have lost your phone, send us a message from the{' '}
            <Nobr>
              <Link to="https://help.investengine.com/" hard>
                Help Centre
              </Link>
              .
            </Nobr>
          </Typo>
        ) : (
          <Typo>Check the phone number is correct. It could also be a problem with your network operator.</Typo>
        )}
      </Typography>
    ),
    [desktop, isLogin],
  )

  if (desktop) {
    return (
      <Paper top={8} bottom={120} right={24} left={24}>
        {headerNode}
        <Paper top={96} left={48} right={48} bottom={80}>
          <AllCenter>{iconNode}</AllCenter>
          <Paper top={32}>{titleNode}</Paper>
          <Paper top={32}>{descriptionNode}</Paper>
        </Paper>
      </Paper>
    )
  }

  const contentNode = (
    <AllCenter data-test-id="smsCodeIssue">
      {iconNode}
      <Paper top={32}>{titleNode}</Paper>
      <Paper top={32} bottom={48}>
        {descriptionNode}
      </Paper>
    </AllCenter>
  )

  return <MobileLayout header={headerNode} content={contentNode} />
}

export { SmsCodeIssue }
