import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'
import { useSorry } from './hooks'

import GlobalPreloader from 'components/_old/GlobalPreloader/GlobalPreloader.jsx'

import { Desktop } from './components/Desktop'
import { Mobile } from './components/Mobile'

const Sorry = ({ location: { query: { portfolioId } = {} } = {} } = {}) => {
  const { desktop } = useMediaQueries()
  const { isLoading, ...props } = useSorry(portfolioId)

  if (isLoading) {
    return <GlobalPreloader loading />
  }

  if (desktop) {
    return <Desktop {...props} />
  }

  return <Mobile {...props} />
}

Sorry.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      portfolioId: PropTypes.string,
    }),
  }),
}

export { Sorry }
