import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import './PortfolioLine.css'

const PortfolioLine = ({ type }) => {
  const classes = classNames('PortfolioLine', {
    PortfolioLine_managed: type === 'managed',
    PortfolioLine_diy: type === 'diy',
    PortfolioLine_cash: type === 'cash',
    PortfolioLine_selfSelected: type === 'selfSelected',
  })

  return <div className={classes} data-test-id={`${type}PortfolioLine`} />
}

PortfolioLine.propTypes = {
  type: PropTypes.oneOf(['diy', 'managed', 'cash', 'selfSelected']),
}

export { PortfolioLine }
