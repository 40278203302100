import { sample } from 'effector'

import { $features } from 'app/effector/features'
import {
  fetchProvidersIfNeeded,
  NomineesTransferProviderRegulatoryType,
} from 'app/effector/nominees-transfers/providers'
import {
  type SippTransferRequest,
  type SetFieldPayload,
  resetSippTransferRequest,
  setFieldForSippTransferRequest,
  setBulkFieldsForSippTransferRequest,
  $sippTransferRequest,
} from 'app/effector/nominees-transfers/request/sipp'

import {
  // events
  closePage,
  resetPage,
  initForm,
  setAgreed,
  setField,
  submitForm,
  resetForm,
  // effects
  sendAnalyticsEventFx,
  showFailToastFx,
  showSuccessToastFx,
  submitFx,
  navigateToUrlFx,
  // stores
  $displayedProviders,
  $nextPage,
  $prevPage,
} from '../index'

// On init
// Should attempt to fetch providers if needed
sample({
  clock: initForm,
  fn: () => NomineesTransferProviderRegulatoryType.SIPP,
  target: fetchProvidersIfNeeded,
})

// Should call setBulkFieldsForSippTransferRequest on init
sample({
  clock: initForm,
  target: setBulkFieldsForSippTransferRequest,
})

// Set default provider based on features
sample({
  clock: initForm,
  source: [$features, $displayedProviders] as const,
  filter: ([features]) => !features.get('multiple-sipp-providers-transfer'),
  fn: ([_, filteredProviders]) => {
    const vanguardProvider = filteredProviders.find((provider) => provider?.name?.toLowerCase().includes('vanguard'))

    if (vanguardProvider) {
      return { field: 'provider' as const, value: vanguardProvider.id }
    }

    throw new Error('No vanguard provider provided to be set on default')
  },
  target: setFieldForSippTransferRequest,
})

// On setField
// Handle form field updates
sample({
  clock: setField,
  filter: ({ field }) => field !== 'agreed',
  fn: <Field extends keyof SippTransferRequest>(payload: SetFieldPayload<Field>) => {
    const { field, value } = payload

    return {
      field,
      value,
    }
  },
  target: setFieldForSippTransferRequest,
})

// Handle agreed field updates
sample({
  clock: setField,
  filter: ({ field }) => field === 'agreed',
  fn: ({ value }) => value as boolean,
  target: setAgreed,
})

// On submitForm
// Handle form submission & send analytics event
sample({
  clock: submitForm,
  source: $sippTransferRequest,
  target: [submitFx, sendAnalyticsEventFx],
})

// On submitFx
// Show fail toast on submit fail
sample({
  clock: submitFx.fail,
  target: showFailToastFx,
})

// Resets page, form, shows success toast, navigates to next page
sample({
  clock: submitFx.doneData,
  source: $nextPage,
  target: [resetPage, resetForm, showSuccessToastFx, navigateToUrlFx],
})

// On closePage
// Resets page, form, navigates to previous page
sample({
  clock: closePage,
  source: $prevPage,
  target: [resetPage, resetForm, navigateToUrlFx],
})

// On resetForm
// Resets form
sample({
  clock: resetForm,
  target: resetSippTransferRequest,
})
