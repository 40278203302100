enum AnalyticsEquityType {
  ALTERNATIVE = 'ALTERNATIVE',
  BOND = 'BOND',
  CASH = 'CASH',
  STOCK = 'STOCK',
}

enum AnalyticsItemType {
  REGION = 'region',
  HOLDING = 'holding',
  SECTOR = 'sector',
  COUNTRY = 'country',
}

type AnalyticsEquity = {
  id: number
  name: string
  logo: string | null
  equity_type: AnalyticsEquityType
  actual_weight: string | null
  target_weight: string | null
}

type AnalyticsItemSecurity = {
  id: number
  name: string
  title: string
  color: string
  actual_weight: string
  target_weight: string | null
}

type AnalyticsItemData = {
  name: string
  logo: string | null
  description: string | null
  website: string | null
  actual_weight: number
  target_weight: number | null
  portfolios: Record<string, AnalyticsItemSecurity[]>
  sector_name: string | null
  region_name: string | null
  total_value: string | null
}

type AnalyticsGeneralEquity = {
  value: string
  actual_weight: string
  target_weight: string | null
}

type AnalyticsGeneralData = {
  total_value: string | null
  total_quantity: number | null
  equities: AnalyticsEquity[]
  equity_types: {
    [AnalyticsEquityType.ALTERNATIVE]: AnalyticsGeneralEquity
    [AnalyticsEquityType.BOND]: AnalyticsGeneralEquity
    [AnalyticsEquityType.CASH]: AnalyticsGeneralEquity
    [AnalyticsEquityType.STOCK]: AnalyticsGeneralEquity
  }
  regions: AnalyticsItemSecurity[]
  sectors: AnalyticsItemSecurity[]
}

type Equity = {
  equity_type: string
  id: number
  logo: string | null
  name: string
  actual_weight: string | null
  target_weight: string | null
}

interface ProcessedEquity extends Equity {
  equity_type: string
  id: number
  logo: string | null
  name: string
  actual: number | null
  target: number | null
  actual_amount: number
}

type ProcessedEquities = {
  value: number | null
  weight: number | null
  target: number | null
  items: ProcessedEquity[]
  count: number
  weight_amount: number
}

type ProcessedNonEquities = Array<{
  id: number
  name: string
  color: string
  actual_weight: string
  target_weight: string | null
  actual: number
  actual_amount: number
}>

type ExtractDataReturnType = {
  actual: number | null
  actual_amount: number
  target: number | string | null
}

type Summary = {
  total: number | null
  stocks?: ExtractDataReturnType
  bonds?: ExtractDataReturnType
  alternatives?: ExtractDataReturnType
  cash?: ExtractDataReturnType
}

type ProcessedAnalyticsData = {
  allStocks: ProcessedEquities
  bonds: ProcessedEquities
  alternatives: ProcessedEquities
  cash: ProcessedEquities
  sectors: ProcessedNonEquities
  regions: ProcessedNonEquities
  summary: Summary
}

type AnalyticsLeaf = {
  general: Record<number | 'all', ProcessedAnalyticsData>
  [AnalyticsItemType.REGION]: Record<number, AnalyticsItemData>
  [AnalyticsItemType.HOLDING]: Record<number, AnalyticsItemData>
  [AnalyticsItemType.SECTOR]: Record<number, AnalyticsItemData>
}

type AnalyticsStore = {
  [portfolioId: number]: AnalyticsLeaf
  all?: AnalyticsLeaf
}

export {
  type AnalyticsLeaf,
  type AnalyticsGeneralData,
  type AnalyticsGeneralEquity,
  type AnalyticsItemData,
  type AnalyticsItemSecurity,
  type AnalyticsEquity,
  type AnalyticsStore,
  type Equity,
  type ProcessedEquity,
  type ProcessedEquities,
  type ProcessedNonEquities,
  type ProcessedAnalyticsData,
  type Summary,
  AnalyticsItemType,
  AnalyticsEquityType,
}
