import { useCallback, useState } from 'react'

type UseTransferRestrictionInterface = {
  isTransferRestrictionModalVisible: boolean
  handleTransferRestrictionModalOpen: () => void
  handleTransferRestrictionModalClose: () => void
}

function useTransferRestriction(): UseTransferRestrictionInterface {
  const [isTransferRestrictionModalVisible, setTransferRestrictionModalVisible] = useState(false)

  const handleTransferRestrictionModalOpen = useCallback(() => {
    setTransferRestrictionModalVisible(true)
  }, [setTransferRestrictionModalVisible])

  const handleTransferRestrictionModalClose = useCallback(() => {
    setTransferRestrictionModalVisible(false)
  }, [setTransferRestrictionModalVisible])

  return {
    isTransferRestrictionModalVisible,
    handleTransferRestrictionModalOpen,
    handleTransferRestrictionModalClose,
  }
}

export { useTransferRestriction, type UseTransferRestrictionInterface }
