const getResultImage = async ({ image, completedCrop, scale }): Promise<Blob> => {
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY)
  const ctx = offscreen.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  ctx.save()

  const cropX = completedCrop.x * scaleX
  const cropY = completedCrop.y * scaleY
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  // 4) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 3) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 2) Scale the image
  ctx.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight)

  const blob = await offscreen.convertToBlob({
    type: 'image/jpeg',
    quality: 0.8,
  })

  return blob
}

export { getResultImage }
