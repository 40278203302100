import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import isArray from 'lodash/isArray'

import { useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Text from 'components/_old/Text/Text.jsx'

import './AddressList.css'

const AddressList = ({ items, onSelect }) => {
  const mediaQueries = useMediaQueries()
  const className = classNames('AddressList', getMediaQuieryClasses('AddressList', mediaQueries))

  const handleSelect = useCallback((index) => onSelect(items[index]), [items, onSelect])

  return (
    <div className={className}>
      {isArray(items) && (
        <div className="AddressList-Items">
          {items.map(({ street, street2, city, postcode, countryName }, index) => (
            <div
              className="AddressList-Item"
              key={index}
              onClick={() => handleSelect(index)}
              data-test-id="addressListItem"
            >
              <Text block>{street}</Text>
              <Text block>{street2}</Text>
              <Text block>{`${city} ${postcode}, ${countryName}`}</Text>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

AddressList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      street: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      countryName: PropTypes.string,
      postcode: PropTypes.string,
    }),
  ),
}

export default AddressList
