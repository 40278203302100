import React, { Fragment } from 'react'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import { GatewayDest } from 'components/atoms/Gateway'
import { MobileLayout, MobileLayoutFullViewport } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'

import { MobileFooter } from 'app/containers/Footer'
import { MobileHeader } from 'app/containers/Header'

import { CreateReportForm } from '../../components/CreateReportForm'
import { ReportsHeader, ReportHeaderTabs } from '../../components/ReportsHeader'
import { type CreateReportChildProps } from '../CreateReport'

const Mobile = (props: CreateReportChildProps): React.ReactElement => (
  <MobileLayout
    header={<MobileHeader pageName="Reports" />}
    content={
      <Fragment>
        <MobileLayoutFullViewport>
          <ReportsHeader activeTabName={ReportHeaderTabs.CREATE_REPORT} />
          <Paper top={48} bottom={48} flex>
            <AllCenter>
              <Paper bottom={64}>
                <CreateReportForm {...props} />
              </Paper>
            </AllCenter>
          </Paper>
        </MobileLayoutFullViewport>
        <Paper bottom={64}>
          <MobileFooter noMarginTop />
        </Paper>
      </Fragment>
    }
    footer={<GatewayDest name="create-report-footer" />}
  />
)

export { Mobile }
