import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { addOrdinalSuffix } from 'helpers/date.js'
import { palette } from 'helpers/palette'
import { urlTo } from 'helpers/router.js'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelField, LabelText } from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import List, { ListItem } from 'components/_old/List/List.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Text from 'components/_old/Text/Text.jsx'
import TransferFundsTo from 'components/_old/TransferFundsTo/TransferFundsTo.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { OptionsLayout } from '../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../Options/OptionsNavigationBar'
import { useDirectDebitForm } from '../hooks'

type DirectDebitFormProps = {
  portfolio: object
  location: { query?: { promo?: boolean; back?: string } }
  handleBack: () => void
  handleClose: () => void
}

const DirectDebitForm = ({
  portfolio,
  location,
  handleBack,
  handleClose,
}: DirectDebitFormProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    bankErrorRef,
    accountHolder,
    accountNumber,
    sortCode,
    monthlyPaymentAmount,
    monthlyPaymentDay,
    confirmCheckbox,
    directDebitSubscription,
    isContinueButtonDisabled,
    isBankErrorVisible,
    validation,
    handleContinue,
    handleGuarantee,
    handleMonthlyInvestmentChange,
    handleMonthlyPaymentDayChange,
    handleConfirmCheckboxChange,
  } = useDirectDebitForm(portfolio, location)

  const termsContent = (
    <List mods={{ type: 'bullet', 'no-margin': 'top' }}>
      <ListItem>
        <Paper top={8}>
          <Typography size={12}>
            <Typo>
              I am instructing InvestEngine to turn on{' '}
              <Link to="/terms#autoinvest" blank hard>
                AutoInvest
              </Link>{' '}
              to create a recurring order in the selected ETFs (or to my cash portfolio) until such time that I cancel
              the order in accordance with our{' '}
              <Link to="/terms/" hard blank>
                Terms and Conditions
              </Link>{' '}
              and{' '}
              <Link to="/terms/#order-execution-policy" hard blank>
                Order Execution Policy
              </Link>
              ;
            </Typo>
          </Typography>
        </Paper>
      </ListItem>
      <ListItem>
        <Paper top={8}>
          <Typography size={12}>
            <Typo>I am authorised to make Direct Debits on this account;</Typo>
          </Typography>
        </Paper>
      </ListItem>
      <ListItem>
        <Paper top={8}>
          <Typography size={12}>
            <Typo>
              I agree to{' '}
              <Link to="/terms/" hard blank>
                Terms and Conditions
              </Link>
              ;
            </Typo>
          </Typography>
        </Paper>
      </ListItem>
      <ListItem>
        <Paper top={8}>
          <Typography size={12}>
            <Typo>
              Have read and understood our{' '}
              <Link to="/privacy/" hard blank>
                Privacy Policy
              </Link>
              , which tells you how we use your information to provide our services and how we’ll communicate with you.
            </Typo>
          </Typography>
        </Paper>
      </ListItem>
    </List>
  )

  const bankAccountErrorNode = isBankErrorVisible && (
    <Paper bottom={32} ref={bankErrorRef}>
      <Typography size={14} lineHeignt="small" color="error">
        <Typo>Please try again once your bank account has been verified.</Typo>
      </Typography>
    </Paper>
  )

  const form = (
    <SubmitOnEnter>
      <Width size={28} center>
        <Card
          mods={{
            padding: desktop ? 'big' : 'nano',
            'no-padding': 'top bottom',
            theme: 'transparent',
          }}
          data-test-id="directDebit"
        >
          <Card
            mods={{
              theme: desktop ? null : 'transparent',
              'no-padding': desktop ? null : 'left right',
            }}
          >
            <ColumnarLayout mods={{ padding: 'nano' }} flexDirection={desktop ? null : 'column'}>
              <Validate rules={validation.monthlyPaymentAmount.rules} skipWaitBlur={monthlyPaymentAmount > 0}>
                {(isValid, brokenRule) => {
                  return (
                    <Label>
                      <LabelText>
                        <Text color={isValid ? null : 'red'} data-test-id="directDebitMonthlyPaymentLabelText">
                          <Typo>
                            {isValid ? 'Monthly investment' : validation.monthlyPaymentAmount.errors[brokenRule]}
                          </Typo>
                        </Text>
                      </LabelText>
                      <LabelField>
                        <Input
                          type="money"
                          mods={{ size: 'bigger' }}
                          style={{ height: '2.25rem' }}
                          tabIndex={2}
                          onChange={handleMonthlyInvestmentChange}
                          withFloat
                          data-test-id="directDebitMonthlyInvestmentInput"
                        >
                          {monthlyPaymentAmount}
                        </Input>
                      </LabelField>
                    </Label>
                  )
                }}
              </Validate>
              {/* TODO: enable auto validation on insertable data */}
              <Validate rules={validation.monthlyPaymentDay.rules}>
                {(isValid, brokenRule) => {
                  return (
                    <Label>
                      <LabelText>
                        <Text color={isValid ? null : 'red'} data-test-id="directDebitMonthlyPaymentDayLabelText">
                          <Typo>
                            {isValid ? 'Monthly payment day' : validation.monthlyPaymentDay.errors[brokenRule]}
                          </Typo>
                        </Text>
                      </LabelText>
                      <LabelField>
                        <Input
                          type="number"
                          mods={{
                            size: !directDebitSubscription ? 'bigger' : 'small',
                          }}
                          style={{ height: '2.25rem' }}
                          tabIndex={3}
                          onChange={handleMonthlyPaymentDayChange}
                          disabled={!!directDebitSubscription}
                          data-test-id="directDebitMonthlyDayInput"
                        >
                          {!directDebitSubscription
                            ? monthlyPaymentDay
                            : `${addOrdinalSuffix(monthlyPaymentDay)} of each month`}
                        </Input>
                      </LabelField>
                      {!directDebitSubscription ? (
                        <Text muted light smaller style={{ lineHeight: '2em', paddingLeft: '0.1em' }}>
                          <Typo>Must be 1 to 28</Typo>
                        </Text>
                      ) : (
                        <Text block right smaller style={{ lineHeight: '2em', paddingRight: '0.1em' }}>
                          <Link
                            to={urlTo(
                              `dashboard.portfolio.direct-debit-stop-subscription`,
                              {
                                id: portfolio.id,
                              },
                              location?.search,
                            )}
                            data-test-id="stopMonthlyPaymentLink"
                          >
                            <Typo>Stop monthly payment</Typo>
                          </Link>
                        </Text>
                      )}
                    </Label>
                  )
                }}
              </Validate>
            </ColumnarLayout>
          </Card>
          <Card
            mods={{
              text: desktop ? 'center' : 'left',
              theme: 'transparent',
              padding: 'biggest',
              'no-padding': desktop ? null : 'left right',
            }}
          >
            <Text small={!desktop}>
              <Typo>You are setting up Direct Debit with the following details:</Typo>
            </Text>
          </Card>
          {bankAccountErrorNode}
          <TransferFundsTo
            desktop={desktop}
            fields={{
              'Account Holder': accountHolder,
              'Sort code': sortCode,
              'Account Number': accountNumber,
            }}
            smallMuted
          />
          <Card
            mods={{
              theme: 'transparent',
              padding: 'big',
              'no-padding': 'left right',
            }}
          >
            <ColumnarLayout>
              <Column size={3}>
                <Text smaller>
                  <Typo>Your payments are protected by the</Typo>&nbsp;
                  <Link onClick={handleGuarantee} data-test-id="DirectDebitGuaranteeLink">
                    Direct Debit Guarantee
                  </Link>
                </Text>
              </Column>
              <Column size={1}>
                <Icon type="directDebit" width={84} height={27} color={palette['content-on-background-default']} />
              </Column>
            </ColumnarLayout>
          </Card>

          <CheckboxWithLabel
            size="smaller"
            label={
              <Fragment>
                <Text block style={{ marginBottom: '1em' }}>
                  <Typo>I confirm that:</Typo>
                </Text>
                <Text block>{termsContent}</Text>
              </Fragment>
            }
            onChange={handleConfirmCheckboxChange}
            checked={confirmCheckbox}
            data-test-id="directDebitConfirmCheckbox"
          />
        </Card>
      </Width>
    </SubmitOnEnter>
  )

  return (
    <OptionsLayout
      header={
        <OptionsNavigationBar
          leftPartText="Back"
          onLeftPartClick={handleBack}
          rightPartText="Close"
          onRightPartClick={handleClose}
        >
          <Typo>Savings Plan set up</Typo>
        </OptionsNavigationBar>
      }
      content={form}
      button={
        <Button
          disabled={isContinueButtonDisabled}
          onClick={handleContinue}
          mods={{ size: 'big block' }}
          data-test-id="directDebitContinueButton"
        >
          Continue
        </Button>
      }
      data-test-id="directDebitPopup"
    />
  )
}

DirectDebitForm.propTypes = {
  portfolio: PropTypes.object,
  location: PropTypes.shape({
    search: PropTypes.string,
    query: PropTypes.shape({
      back: PropTypes.string,
    }),
  }),
  handleBack: PropTypes.func,
  handleClose: PropTypes.func,
}

export { DirectDebitForm }
