import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries, useMemo, useCallback, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { format as formatMoney } from 'helpers/money'
import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { List, ListItem } from 'components/atoms/List'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Stack } from 'components/atoms/Stack'
import Switcher from 'components/atoms/Switcher/Switcher'
import { Typography } from 'components/atoms/Typography'

import { Preloader } from 'components/molecules/Preloader'

import { OptionsLayout } from '../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../Options/OptionsNavigationBar'

import { AutoinvestNotification } from './AutoinvestNotification'
import { useAutoinvestToggle } from './hooks'

import './Autoinvest.css'

type AutoinvestProps = {
  location: { query?: { back?: string } }
  portfolio: Portfolio
  handleClose: () => void
}

const Autoinvest = withRouter(
  ({ location: { query = {} } = {}, portfolio, handleClose }: AutoinvestProps): React.ReactElement => {
    const { desktop } = useMediaQueries()
    const [showNotification, setShowNotification] = useState(false)
    const { isLoading, isActive, activate, deactivate } = useAutoinvestToggle(portfolio)
    const hasBackButton = !!query.back

    const handleBack = useCallback(() => {
      goTo(query.back)
    }, [query.back])

    const handleNext = useCallback(() => {
      goTo(urlTo('dashboard.portfolio.autoinvest.amount', { id: portfolio.id }, query))
    }, [portfolio.id, query])

    const handleToggle = useCallback(() => {
      if (isActive) {
        deactivate()
        return
      }
      setShowNotification(true)
    }, [isActive, deactivate])

    const switcher = useMemo(
      () => <Switcher onChange={handleToggle} checked={isActive} data-test-id="autoinvestSwitcher" />,
      [isActive, handleToggle],
    )

    const handleCloseNotification = useCallback(() => {
      setShowNotification(false)
    }, [])

    const handleActivateAutoinvest = useCallback(() => {
      trackEvent({ action: 'autoinvest_activated' }, { plugins: { 'google-analytics-v3': false } })
      handleCloseNotification()
      activate()
    }, [activate, handleCloseNotification])

    const autoinvestOption = useMemo(() => {
      const leftSide = (
        <Paper top={4} bottom={4}>
          <Typography size={16} lineHeight="small" color="default">
            <Typo>AutoInvest</Typo>
            <Paper left={4} inline>
              <Typography color={isActive ? 'secondary_default' : 'default'}>
                <Typo>{isActive ? 'ON' : 'OFF'}</Typo>
              </Typography>
            </Paper>
          </Typography>
          <Paper top={4} right={desktop ? 16 : 0}>
            <Typography size={14} lineHeight="small" color="minor">
              <Typo>Available cash will be automatically invested in your portfolio based on your chosen weights</Typo>
            </Typography>
          </Paper>
        </Paper>
      )
      const rightSide = <Paper right={48} />

      return (
        <ListItem
          leftSide={leftSide}
          rightSide={rightSide}
          onClick={handleToggle}
          data-test-id="ActivateAutoInvestAction"
        />
      )
    }, [isActive, desktop, handleToggle])

    const amountOption = useMemo(() => {
      const leftSide = (
        <Paper top={4} bottom={4}>
          <Typography size={16} lineHeight="small" color="inherit">
            <Typo>Keep a cash buffer</Typo>
          </Typography>
          <Paper top={4} right={desktop ? 16 : 0}>
            <Typography size={14} lineHeight="small" color="minor">
              <Typo>Retain a cash buffer while automatically investing remaining cash</Typo>
            </Typography>
          </Paper>
        </Paper>
      )
      const rightSide = (
        <Paper top={4} bottom={4}>
          <ItemWithIcon
            space={4}
            iconPosition="right"
            icon={<Icon type="arrow-right-small-24" size={24} color={palette['content-on-background-minor']} />}
            content={
              <Typography size={16} lineHeight="small" color="default">
                <Typo>{portfolio.min_autoinvest_amount > 0 && formatMoney(portfolio.min_autoinvest_amount)}</Typo>
              </Typography>
            }
            iconVerticalAlign="center"
            contentVerticalAlign="center"
          />
        </Paper>
      )

      return (
        <ListItem
          leftSide={leftSide}
          rightSide={rightSide}
          onClick={isActive ? handleNext : undefined}
          disabled={!isActive}
          data-test-id="portfolioAmountAction"
        />
      )
    }, [desktop, isActive, handleNext, portfolio.min_autoinvest_amount])

    const content = useMemo(() => {
      const content = (
        <SideSpacesCompensator>
          <List className="Autoinvest-options">
            {autoinvestOption}
            {amountOption}
            {switcher}
          </List>
        </SideSpacesCompensator>
      )

      if (desktop) {
        return (
          <Width size={25} center>
            <Paper top={24}>
              <Stack vertical={32}>{content}</Stack>
            </Paper>
          </Width>
        )
      }

      return <Stack vertical={48}>{content}</Stack>
    }, [desktop, amountOption, autoinvestOption, switcher])

    if (showNotification) {
      return <AutoinvestNotification onActivate={handleActivateAutoinvest} onClose={handleCloseNotification} />
    }

    return (
      <OptionsLayout
        header={
          <Fragment>
            <Preloader loading={isLoading} background="background" delay={400} zIndex absolute />
            <OptionsNavigationBar
              leftPartText="Back"
              onLeftPartClick={hasBackButton ? handleBack : undefined}
              rightPartText="Close"
              onRightPartClick={handleClose}
            >
              <Typo>AutoInvest</Typo>
            </OptionsNavigationBar>
          </Fragment>
        }
        content={content}
        button={null}
        data-test-id="autoinvestModal"
      />
    )
  },
)

export { Autoinvest }
