// import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useRef, useMemo, useState, useEffect, useContext, useLoading, useCallback, useDebouncedEffect } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { isAuthorizationError } from 'helpers/errors'
import { replace as historyReplace } from 'helpers/history.js'
import { goTo, urlTo } from 'helpers/router.js'

import { $analyticsStore, fetchAnalyticsGeneralFx } from 'app/effector/analytics'
import { type Summary, type ProcessedEquities, type ProcessedNonEquities } from 'app/effector/analytics/types'
import { parseAnalyticsGeneralData } from 'app/effector/analytics/utils/'

import { removeToastsWithStyle, showFailToast } from 'app/redux/actions/ui'
import store from 'app/redux/store/store.js'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

import { filterStocks } from './helpers'
import { useAnalyticsFilters } from './useAnalyticsFilters'
import { usePortfolioSelect } from './usePortfolioSelect.js'

// const fetchGeneralAnalyticsInUseAnalytics = attach({ effect: fetchAnalyticsGeneralFx })

type useAnalyticsArguments = {
  portfolioId?: string | number | null
  securityId?: string | number | null
  presetId?: string | number | null
  fromPortfolio?: boolean
  showAll?: boolean
  route: { module: string }
  tunnelQuery: Record<string, string>
  withSearch?: boolean
  withFilters?: boolean
  withSkeletons?: boolean
  searchQueryParam?: string
}

type useAnalyticsReturnType = {
  scrollableElementRef: React.RefObject<HTMLElement>
  isLoading: boolean
  clientType: string
  selected: string
  selectedPortfolio: number
  selectedPortfolioTitle: string
  portfoliosOptions: Array<{ name: string; value: number }>
  showTarget: boolean
  summary: Summary
  stocks: ProcessedEquities
  bonds: ProcessedEquities
  alternatives: ProcessedEquities
  cash: ProcessedEquities
  sectors: ProcessedNonEquities
  regions: ProcessedNonEquities
  searchValue: string
  filters: { regions: { value: number[] }; sectors: { value: number[] } }
  handleSearch: (value: string) => void
  handlePortfolioSelect: (options: string) => void
  handleBackToPortfolio: () => void
  handleGoToSingleHolding: (id: string, module: string) => void
  handleShowFullList: () => void
}

const useAnalytics = ({
  portfolioId,
  presetId,
  securityId,
  portfolios,
  fromPortfolio,
  showAll,
  route,
  tunnelQuery,
  withSearch = true,
  withFilters = true,
  withSkeletons = false,
  searchQueryParam = 'search',
}: useAnalyticsArguments): useAnalyticsReturnType => {
  portfolioId = portfolioId ? parseInt(portfolioId as string, 10) : null
  securityId = securityId ? parseInt(securityId as string, 10) : null
  presetId = presetId ? parseInt(presetId as string, 10) : null

  // Portfolio Select
  const {
    clientType,
    selected,
    selectedPortfolio,
    selectedPortfolioTitle,
    portfoliosOptions,
    handlePortfolioSelect,
    handleBackToPortfolio,
  } = usePortfolioSelect({ portfolioId, fromPortfolio, route, tunnelQuery })

  const handlePortfolioSelectWrapper = useCallback(
    (option) => {
      handlePortfolioSelect(option)
    },
    [handlePortfolioSelect],
  )

  const showTarget = Boolean(selectedPortfolio)

  // Scroll
  const scrollableElementRef = useRef(null)
  const { saveScrollPosition, restoreScrollPosition } = useContext(ScrollSaverContext)

  let { isLoading, wait } = useLoading(false)

  if (portfolios && portfolios.length < 1) {
    isLoading = true
  }

  // Data Processing
  const [analyticsStore, fetchAnalyticsGeneral] = useUnit([$analyticsStore, fetchAnalyticsGeneralFx])
  const data = analyticsStore.getGeneralDataOf(portfolioId ?? 'all', securityId)

  const { allStocks, bonds, alternatives, cash, regions, sectors, summary } = useMemo(() => {
    if (isLoading && withSkeletons) {
      return {
        allStocks: {
          skeleton: true,
          items: new Array(100).fill({ skeleton: true }),
          count: 20,
          weight_amount: 0,
        },
        bonds: {
          skeleton: true,
          items: [],
          weight_amount: 0,
        },
        alternatives: {
          skeleton: true,
          items: [],
          weight_amount: 0,
        },
        cash: {
          skeleton: true,
          items: [],
          weight_amount: 0,
        },
        regions: new Array(7).fill({ skeleton: true }),
        sectors: new Array(9).fill({ skeleton: true }),
        summary: {
          skeleton: true,
          stocks: {
            skeleton: true,
            actual_amount: 0,
          },
          bonds: {
            skeleton: true,
            actual_amount: 0,
          },
          alternatives: {
            skeleton: true,
            actual_amount: 0,
          },
          cash: {
            skeleton: true,
            actual_amount: 0,
          },
        },
      }
    }

    return data || parseAnalyticsGeneralData()
  }, [withSkeletons, isLoading, data])

  // Search and Filtering
  const [searchValue, setSearchValue] = useState(tunnelQuery?.[searchQueryParam] ?? '')
  const handleSearch = useCallback(
    (newSearchValue) => {
      setSearchValue(newSearchValue)
    },
    [setSearchValue],
  )
  const { filters } = useAnalyticsFilters({
    withFilters,
    sectors,
    regions,
    query: tunnelQuery,
  })

  const stocks = useMemo(() => {
    return filterStocks({
      isLoading,
      allStocks,
      searchValue,
      regionsFilterValues: filters.regions.value,
      sectorsFilterValues: filters.sectors.value,
    })
  }, [isLoading, allStocks, searchValue, filters.regions.value, filters.sectors.value])

  // Navigation
  const handleGoToSingleHolding = useCallback(
    (id: string, module: string) => {
      saveScrollPosition()

      const query = {
        ...tunnelQuery,
        ...(fromPortfolio ? { fromPortfolio } : {}),
        ...(showAll ? { showAll } : {}),
      }
      goTo(urlTo(portfolioId ? `analytics.portfolio.${module}` : `analytics.${module}`, { id, portfolioId }, query))
    },
    [portfolioId, fromPortfolio, showAll, tunnelQuery, saveScrollPosition],
  )

  const handleShowFullList = useCallback(() => {
    const module: string = portfolioId ? 'analytics.portfolio' : 'analytics'
    const query = fromPortfolio ? { fromPortfolio, ...tunnelQuery } : tunnelQuery

    goTo(urlTo(`${module}.${route.module}`, { portfolioId }, { showAll: true, ...query }))
  }, [portfolioId, fromPortfolio, tunnelQuery, route])

  // Effects
  useDebouncedEffect(
    () => {
      if (withSearch) {
        const queryObjectWoSearch = tunnelQuery ?? {}
        // eslint-disable-next-line
        delete queryObjectWoSearch[searchQueryParam]
        const nextQueryObject =
          searchValue.length > 0 ? { ...queryObjectWoSearch, [searchQueryParam]: searchValue } : queryObjectWoSearch
        const nextQueryString = querystringFromObject(nextQueryObject)
        const currentUrl = `${window.location.pathname}${window.location.hash}${window.location.search}`
        const nextUrl = `${window.location.pathname}${window.location.hash}${nextQueryString}`

        if (currentUrl !== nextUrl) {
          return historyReplace(nextUrl, { scrollToTop: false })
        }
      }
    },
    250,
    [searchValue],
  )

  useEffect(() => {
    // redirect to single portfolio analytics if there is only one portfolio (Analytics tab)
    if (route && !portfoliosOptions.length && !portfolioId && selectedPortfolio?.id)
      goTo(urlTo('dashboard.analytics.portfolio.regions', { portfolioId: selectedPortfolio?.id }), { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    restoreScrollPosition()

    try {
      data
        ? fetchAnalyticsGeneral({
            portfolioId: portfolioId as number,
            securityId: securityId as number,
            presetId: presetId as number,
          })
        : wait(
            fetchAnalyticsGeneral({
              portfolioId: portfolioId as number,
              securityId: securityId as number,
              presetId: presetId as number,
            }),
          )
    } catch (error) {
      if (!isAuthorizationError(error)) {
        showFailToast()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId, securityId, presetId, restoreScrollPosition])

  // that's a hotfix of https://app.asana.com/0/1199172649859742/1201756361918009/f
  // should be fixed properly at https://app.asana.com/0/1148691590964049/1201891859357768/f
  useEffect(() => {
    store.dispatch(removeToastsWithStyle('fail'))
  }, [route])

  return {
    scrollableElementRef,
    isLoading,
    clientType,
    selected,
    selectedPortfolio,
    selectedPortfolioTitle,
    portfoliosOptions,
    showTarget,
    summary,
    stocks,
    bonds,
    alternatives,
    cash,
    sectors,
    regions,
    searchValue,
    filters,
    handleSearch,
    handlePortfolioSelect: handlePortfolioSelectWrapper,
    handleBackToPortfolio,
    handleGoToSingleHolding,
    handleShowFullList,
  }
}

export { useAnalytics }
