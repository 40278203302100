import { useUnit } from 'effector-react'

import { useCallback, useEffect, useDebouncedEffect, useMemo, useState, useLoading } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { replace as historyReplace } from 'helpers/history.js'
import { getRandomInteger } from 'helpers/number'

import { $securitiesStore, fetchFilteredSecuritiesFx } from 'app/effector/securities'

import { useSecurities } from 'app/pages/Securities/hooks'

const useSecuritiesSearch = ({ portfolioId, collectionId, lastSearch, filters, isCollection }) => {
  const { portfolioSecurities, isLoading: isSecuritiesLoading } = useSecurities({
    portfolioId,
    perspective: true,
  })

  const { filteredSecurities: filteredGlobalSecurities, filteredCollectionsSecurities } = useUnit($securitiesStore)

  const filteredSecurities = useMemo(
    () => (isCollection ? filteredCollectionsSecurities[collectionId] || [] : filteredGlobalSecurities),
    [filteredGlobalSecurities, filteredCollectionsSecurities, collectionId, isCollection],
  )

  const { isLoading: isFilteredSecuritiesLoading, timesLoaded, wait } = useLoading(filteredSecurities.length < 1)
  const securitiesWasLoadedOnce = timesLoaded > 0
  const isLoading = isSecuritiesLoading || isFilteredSecuritiesLoading

  const visibleSecurities = useMemo(() => {
    const securities = filteredSecurities.getVisibleInUniverse?.() || []

    if (!securitiesWasLoadedOnce && securities.length < 1) {
      return new Array(getRandomInteger(24, 32))
    }

    return securities
  }, [filteredSecurities, securitiesWasLoadedOnce])

  const [searchValue, setSearchValue] = useState(lastSearch?.query?.search || '')

  const fetchSecuritiesWithFilters = useCallback(async () => {
    const params = { featured_data: 'true', search: searchValue, ...filters, collection_id: collectionId }

    const fetchSecurities = wait.bind(null, fetchFilteredSecuritiesFx(params))

    await fetchSecurities()
  }, [filters, searchValue, wait, collectionId])

  const handleLocationChange = useCallback(() => {
    const currentLocation = `${window.location.pathname}${window.location.hash}`.replace(/^\/?#/, '')

    if (!currentLocation) {
      return
    }

    const queryRegexp = /\?.*/
    const currentParams = Object.fromEntries(
      (window?.location?.toString().match(queryRegexp)?.[0] ?? '')
        .split(/[?&]/)
        .filter((string) => string.length > 0)
        .map((string) => string.split('=')),
    )

    if (currentParams.back) currentParams.back = decodeURIComponent(currentParams.back)

    const newQueryString = querystringFromObject({ ...currentParams, search: searchValue, ...filters })

    const nextUrl = queryRegexp.test(currentLocation)
      ? currentLocation.replace(queryRegexp, newQueryString)
      : `${currentLocation}${newQueryString}`

    return historyReplace(nextUrl, { scrollToTop: false })
  }, [searchValue, filters])

  useEffect(() => {
    if (isCollection && !collectionId) return

    fetchSecuritiesWithFilters()
    handleLocationChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, collectionId, isCollection])

  useDebouncedEffect(
    () => {
      searchValue.length !== 1 && fetchSecuritiesWithFilters()
      handleLocationChange()
    },
    250,
    [searchValue],
  )

  const getSecurityDataByIndex = useCallback(
    (index) => {
      const security = visibleSecurities[index]
      const isAddedToPortfolio = !!portfolioSecurities.find(
        (portfolioSecurities) => portfolioSecurities?.id === security?.id,
      )

      return { security, isAddedToPortfolio }
    },
    [visibleSecurities, portfolioSecurities],
  )

  return {
    isLoading,
    securitiesWasLoadedOnce,
    securities: visibleSecurities,
    portfolioSecurities,
    getSecurityDataByIndex,
    searchValue,
    handleSearch: setSearchValue,
  }
}

export { useSecuritiesSearch }
