import { useCallback, useLoading } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'

import { showFailToast } from 'app/redux/actions/ui'
import { setup2fa } from 'app/redux/api/2fa'
import { ApiError } from 'app/redux/models/errors'

import { useReauth } from 'app/pages/Dashboard/UserProfile/hooks'

type UseFirstStepReturnProps = {
  isLoading: boolean
  handleContinue: () => Promise<void>
  handleBack: () => void
}

const useFirstStep = (): UseFirstStepReturnProps => {
  const { checkIsClientReauthorized } = useReauth()

  const { isLoading, wait } = useLoading(false)

  const goToPassword = useCallback(() => {
    goTo(urlTo('user-profile.two-factor-setup.password-step'))
  }, [])

  const handleContinue = useCallback(async () => {
    if (checkIsClientReauthorized()) {
      try {
        const data = await wait(setup2fa({ action: 'enable', type: 'push_and_sms' }))

        if (data instanceof ApiError) {
          throw data
        }

        if (data.state === 'REQUESTED') {
          goTo(urlTo('user-profile.two-factor-setup.notification-step'))
          return
        }

        showFailToast()
        return
      } catch (error) {
        goToPassword()
        return
      }
    }
    goToPassword()
  }, [checkIsClientReauthorized, goToPassword, wait])

  const handleBack = useCallback(() => {
    const backUrl = urlTo('dashboard.user-profile.login-settings.two-factor-auth')

    goTo(backUrl)
  }, [])

  return {
    isLoading,
    handleContinue,
    handleBack,
  }
}

export { useFirstStep }
