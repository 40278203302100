import React from 'react'

import PropTypes from 'prop-types'

import { format as formatMoney } from 'helpers/money'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DonutChart } from 'components/atoms/DonutChart'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

const Summary = ({ className, isLoading, sectors, summary, isForSinglePortfolio }) => (
  <div className={className}>
    <Column size={0}>
      <AllCenter>
        <Width size={8.5}>
          <DonutChart
            lineWidth={8}
            values={sectors.map((sector) => [sector.actual, sector.color])}
            minimalSectorValue={1}
          >
            <Typography size={32} weight="semibold" lineHeight="small" align="center">
              {isLoading ? '█' : sectors.length}
            </Typography>
            <Typography size={14} lineHeight="small" align="center">
              <Typo>Sectors</Typo>
            </Typography>
          </DonutChart>
          <Paper top={24}>
            <Typography size={14} lineHeight="small" align="center">
              <Typo>{isForSinglePortfolio ? 'Portfolio' : 'Total'} balance</Typo>
            </Typography>
            <Typography size={14} lineHeight="small" align="center">
              <Typo>{formatMoney(summary.total, true, true)}</Typo>
            </Typography>
          </Paper>
        </Width>
      </AllCenter>
    </Column>
  </div>
)

Summary.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      actual: PropTypes.number.isRequired,
      target: PropTypes.number,
    }),
  ),
  summary: PropTypes.shape({
    total: PropTypes.number,
  }),
  isForSinglePortfolio: PropTypes.bool,
}

export { Summary }
