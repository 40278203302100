import React from 'react'
import PropTypes from 'prop-types'

import { useMemo, useMediaQueries } from 'hooks'
import { symbols } from 'helpers/typograph'

import { formatPercent } from 'helpers/money'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'
import Link from 'components/_old/Link/Link.jsx'

import './SecurityOrderItem.css'

const SecurityOrderItem = ({ logo, title = 'Unknown Security', orderInfo = {}, onDocumentLinkClick }) => {
  const { desktop } = useMediaQueries()

  const { amount, documents, quantity_change, weight_before, weight_after } = orderInfo

  const estimatedShares = useMemo(() => {
    if (quantity_change <= 0) {
      return '0'
    }

    if (quantity_change < 0.01) {
      return `<${symbols.nanbsp}0.01`
    }

    return parseFloat((Math.round(quantity_change * 100) / 100).toFixed(2))
  }, [quantity_change])

  const kiidLink = useMemo(() => {
    const kiid = documents?.getKiid?.()

    if (kiid && kiid.uri.length > 0) {
      const handleLink = (event) => {
        if (typeof onDocumentLinkClick === 'function') {
          onDocumentLinkClick(event, kiid.uri)
        }
      }

      return (
        <Link onClick={handleLink}>
          <Typography size={14} color="inherit" lineHeight={desktop ? 'medium' : 'small'} data-test-id="kiidLink">
            Key investor information
          </Typography>
        </Link>
      )
    }

    return null
  }, [documents, onDocumentLinkClick, desktop])

  const content = (
    <div className="SecurityOrderItem-Content">
      <div>
        <Typography
          className="SecurityOrderItem-Title"
          size={desktop ? 16 : 14}
          weight="semibold"
          data-test-id="orderSecurityTitle"
        >
          <Typo>{title}</Typo>
        </Typography>
        <Paper top={4}>
          {kiidLink}
          <Typography size={14} color="minor" lineHeight={desktop ? 'medium' : 'small'}>
            Estimated shares <span data-test-id="estimatedShares">{estimatedShares}</span>
          </Typography>
          <Paper top={4}>
            <Typography size={14} color="minor" lineHeight={desktop ? 'medium' : 'small'} data-test-id="securityPrice">
              <PoundsWithPence amount={amount} sameSize showZeroPence />
            </Typography>
          </Paper>
        </Paper>
      </div>
      <div>
        <Typography
          size={16}
          align="right"
          lineHeight={desktop ? 'medium' : 'small'}
          weight="semibold"
          data-test-id="weightChange"
        >
          {formatPercent(weight_before / 100, true)} ➝ {formatPercent(weight_after / 100, true)}
        </Typography>
      </div>
    </div>
  )

  return (
    <ItemWithIcon
      className="SecurityOrderItem"
      icon={<SecurityLogo logo={logo} size={desktop ? 48 : 36} data-test-id="securityIcon" />}
      space={desktop ? 16 : 12}
      content={content}
    />
  )
}

SecurityOrderItem.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.string.isRequired,
  orderInfo: PropTypes.shape({
    amount: PropTypes.number,
    weight_before: PropTypes.number,
    weight_after: PropTypes.number,
    quantity_change: PropTypes.number,
  }).isRequired,
  onDocumentLinkClick: PropTypes.func,
}

export { SecurityOrderItem }
