import { type AxiosRequestConfig } from 'axios'

import localstore from 'helpers/localstore.js'

const addDebugInformation = ({ request }: { request: AxiosRequestConfig }): void => {
  if (!request) {
    return
  }

  const clientId: string | null = localstore.get('client')?.id ?? null

  if (clientId && request?.headers) {
    request.headers['X-Debug'] = `ClientID=${clientId}`
  }
}

export { addDebugInformation }
