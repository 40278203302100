import React from 'react'
import classNames from 'classnames/dedupe'

import rawMediaQueries from 'helpers/mediaQueries.js'
import { modsToClassnames } from 'helpers/classname.js'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import './Card.css'

@withMediaQueries(rawMediaQueries)
export default class Card extends React.Component {
  render() {
    const {
      tag = 'div',
      flex,
      grow,
      fluid,
      column,
      mods,
      className,
      stack,
      children,
      mediaQueries,
      'data-test-id': dataTestId,
      style,
      ...rest
    } = this.props

    const classes = classNames(
      className,
      'Card',
      {
        Card_flex: flex,
        Card_grow: grow,
        Card_fluid: fluid,
        Card_direction_column: column,
        Card_stack: stack,
      },
      mods ? modsToClassnames('Card', mods) : null,
      getMediaQuieryClasses('Card', mediaQueries),
    )

    return React.createElement(
      tag,
      {
        className: classes,
        style,
        ...rest,
        'data-test-id': dataTestId,
      },
      children,
    )
  }
}
