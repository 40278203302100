import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PortfolioCard } from 'app/pages/Dashboard/Goals/MoveCash/components/PortfolioCard'

type PortfoliosGroupProps = {
  title: string
  portfolios: Portfolio[]
  hideTitle?: boolean
  isBusiness: boolean
  handlePortfolioSelect: (id: number) => void
}

const PortfoliosGroup = ({
  title,
  portfolios,
  hideTitle,
  isBusiness,
  handlePortfolioSelect,
}: PortfoliosGroupProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      {!hideTitle && (
        <Typography size={desktop ? 18 : 16} lineHeight="small" weight="semibold" data-test-id="moveCashSectionTitle">
          <Typo>{title}</Typo>
        </Typography>
      )}
      <Paper top={8}>
        {portfolios.map((portfolio, index) => (
          <Paper key={portfolio.id} top={16} data-test-id="moveCashPortfolioCard">
            <PortfolioCard
              showAvailableCash
              withColor
              isBusiness={isBusiness}
              portfolio={portfolio}
              onClick={() => {
                handlePortfolioSelect(portfolio.id)
              }}
              data-test-id={`moveCashPortfolioCard${index}`}
            />
          </Paper>
        ))}
      </Paper>
    </Fragment>
  )
}

export { PortfoliosGroup }
