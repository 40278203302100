import { createEvent, createStore } from 'effector'

import { features } from 'helpers/features'

const featuresUpdated = createEvent()

// TODO: migrate features helper to be features store altogether
const $features = createStore(features.getMap())
$features.on(featuresUpdated, () => features.getMap())

if (typeof window !== 'undefined') {
  window.addEventListener('featuresUpdated', () => {
    featuresUpdated()
  })
}

export { featuresUpdated, $features }
