import { useMemo } from 'hooks'

const useSecurityLabels = (security) => {
  const securityLabels = useMemo(() => {
    const status = {
      accumulating: security?.dividends_type === 'ACCUMULATING',
      hedged: security?.is_hedged,
      esg: security?.is_esg,
      yield: security?.estimated_yield,
      ter: security?.ter,
    }

    return Object.keys(status).filter((key) => status[key])
  }, [security])

  return securityLabels
}

export { useSecurityLabels }
