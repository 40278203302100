import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { propTypes } from 'helpers/propTypes'

import { useMediaQueries, useRef } from 'hooks'
import { usePortfolios } from './hooks'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

import { PortfolioList } from 'app/redux/models/portfolio'
import { DashboardHints } from './components/DashboardHints'

const Portfolios = ({ client, portfolios, createPortfolioUrl, isPortfoliosSkeletons, shouldNotShowHints }) => {
  const { desktop } = useMediaQueries()
  const cashPortfolioRef = useRef(null)
  const isaCashPortfolioRef = useRef(null)
  const addPortfolioRef = useRef(null)
  const addIsaRef = useRef(null)
  const addSippRef = useRef(null)

  const {
    groupedPortfolios,
    remainingIsaAllowance,
    totalSippAllowance,
    isBusiness,
    canOpenIsa,
    canOpenSipp,
    isIsaAccountOpened,
    isSippAccountOpened,
    hasPortfolios,
    isLoading,
    hasOpenedIsa,
    hasPersonalPortfolios,
    transferProgressCollection,
    regulatoryTypesOrder,
  } = usePortfolios(portfolios, isPortfoliosSkeletons)

  const shouldShowHints = isPortfoliosSkeletons || shouldNotShowHints ? false : !isLoading
  const props = {
    client,
    groupedPortfolios,
    remainingIsaAllowance,
    totalSippAllowance,
    isPortfoliosSkeletons,
    isBusiness,
    canOpenIsa,
    canOpenSipp,
    createPortfolioUrl,
    isIsaAccountOpened,
    isSippAccountOpened,
    hasPortfolios,
    cashPortfolioRef,
    isaCashPortfolioRef,
    addPortfolioRef,
    addIsaRef,
    addSippRef,
    hasOpenedIsa,
    hasPersonalPortfolios,
    transferProgressCollection,
    regulatoryTypesOrder,
  }

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}
      {shouldShowHints && (
        <DashboardHints
          cashPortfolioRef={cashPortfolioRef}
          isaCashPortfolioRef={isaCashPortfolioRef}
          addPortfolioRef={addPortfolioRef}
          addIsaRef={addIsaRef}
          addSippRef={addSippRef}
          hasPortfolios={hasPortfolios}
          hasPersonalPortfolios={hasPersonalPortfolios}
          isBusiness={isBusiness}
          hasOpenedIsa={hasOpenedIsa}
        />
      )}
    </Fragment>
  )
}

Portfolios.propTypes = {
  client: PropTypes.shape({ state: PropTypes.string }),
  portfolios: propTypes.instanceOf(PortfolioList),
  createPortfolioUrl: PropTypes.string,
  isPortfoliosSkeletons: PropTypes.bool,
  shouldNotShowHints: PropTypes.bool,
}

export { Portfolios }
