import React from 'react'

import { Sorry } from './Sorry.jsx'

export default {
  isNewLayout: true,
  module: 'sorry',
  path: 'sorry/',
  component: function Component(props) {
    return <Sorry {...props} />
  },
}
