import React, { Fragment } from 'react'

import { useMemo } from 'hooks'

import { MobileHeader } from 'app/containers/Header'
import { MobileFooter } from 'app/containers/Footer'

import { MobileLayout, MobileLayoutFullViewport } from 'components/atoms/Layouts'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import Inner from 'components/_old/Inner/Inner.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

const Empty = () => {
  const headerNode = useMemo(() => {
    return <MobileHeader pageName="Analytics" />
  }, [])

  const contentNode = useMemo(
    () => (
      <Fragment>
        <MobileLayoutFullViewport>
          <Inner flex>
            <AllCenter>
              <Typography size={16} lineHeight="small" align="center" color="minor">
                <Typo>Nothing to show just yet</Typo>
              </Typography>
            </AllCenter>
          </Inner>
        </MobileLayoutFullViewport>
        <MobileFooter />
      </Fragment>
    ),
    [],
  )

  return <MobileLayout header={headerNode} content={contentNode} />
}

export { Empty }
