import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette/'

import { useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MaxLines } from 'components/atoms/MaxLines'
import { NumberInput } from 'components/molecules/NumberInput'
import { Paper } from 'components/atoms/Paper'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { Ticker } from 'components/atoms/Ticker'
import { Typo } from 'components/_old/Typo/Typo'
import { Typography } from 'components/atoms/Typography'
import { VisuallyHidden } from 'components/atoms/VisuallyHidden'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import './EditableSecurity.css'
import { SecurityWeightInfo } from 'components/molecules/SecurityWeightInfo'

const EditableSecurity = ({
  className,
  title,
  ticker,
  logo,
  weight,
  currentWeight,
  targetWeight,
  showDelete = 'space',
  isNew,
  onWeightChange: handleWeightChange,
  onDelete: handleDelete,
  'data-test-id': dataTestId,
  highlightedRef,
}) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries
  const classes = classNames(
    className,
    'EditableSecurity',
    {
      EditableSecurity_new: isNew,
      EditableSecurity_delete: showDelete,
      EditableSecurity_delete_space: showDelete === 'space',
    },
    getMediaQuieryClasses('EditableSecurity', mediaQueries),
  )

  return (
    <Paper className={classes} top={20} right={16} bottom={20} left={16} data-test-id="editableSecurity">
      {showDelete !== false && (
        <Link
          className="EditableSecurity-Delete"
          mods={{ color: 'mutedToRed' }}
          role="button"
          tabIndex={showDelete === 'space' ? -1 : 0}
          onClick={handleDelete}
          data-test-id={`${dataTestId}RemoveButton`}
        >
          <Paper right={desktop ? 16 : 8}>
            <Icon className="EditableSecurity-DeleteIcon" type="delete-16" color="inherit" />
            <VisuallyHidden>Delete security</VisuallyHidden>
          </Paper>
        </Link>
      )}
      <ItemWithIcon
        className="EditableSecurity-Security"
        space={desktop ? 16 : 12}
        icon={<SecurityLogo logo={logo} size={desktop ? 48 : 32} />}
        content={
          <Fragment>
            <Typography
              size={desktop ? 16 : 14}
              weight={desktop ? 'semibold' : 'regular'}
              lineHeight="small"
              data-test-id={`${dataTestId}Title`}
            >
              <MaxLines
                cacheName="EditableSecurity"
                lines={2}
                color={isNew ? palette['primary-surface-10'] : palette['background-default']}
              >
                <Typo>{title}</Typo>
              </MaxLines>
            </Typography>
            {ticker && (
              <Paper top={title ? (desktop ? 8 : 4) : 0}>
                <Ticker text={ticker} />
              </Paper>
            )}
            {!isNew && (
              <Paper top={desktop ? 8 : 4}>
                <SecurityWeightInfo
                  currentWeight={currentWeight}
                  targetWeight={targetWeight}
                  data-test-id={dataTestId}
                />
              </Paper>
            )}
          </Fragment>
        }
      />
      <Paper className="EditableSecurity-Weight" left={12}>
        <div ref={highlightedRef}>
          <NumberInput min={0} max={100} onChange={handleWeightChange} after="%" data-test-id={dataTestId}>
            {weight}
          </NumberInput>
        </div>
      </Paper>
    </Paper>
  )
}

EditableSecurity.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  ticker: PropTypes.string,
  logo: PropTypes.string,
  weight: PropTypes.number,
  currentWeight: PropTypes.number,
  targetWeight: PropTypes.number,
  showDelete: PropTypes.oneOf([true, false, 'space']),
  isNew: PropTypes.bool,
  onWeightChange: PropTypes.func,
  onDelete: PropTypes.func,
  'data-test-id': PropTypes.string,
  highlightedRef: PropTypes.object,
}

export { EditableSecurity }
