import React from 'react'
import classNames from 'classnames/dedupe'
import './Text.css'

const Text = ({ className, children, style, 'data-test-id': dataTestId, ...rest }) => {
  let mods = Object.keys(rest).filter((k) => !!rest[k])
  mods = mods.map((m) => {
    if (typeof rest[m] === 'string') {
      return `Text_${m}_${rest[m]}`
    }
    return `Text_${m}`
  })
  const classes = classNames(className, 'Text', mods)

  return (
    <span className={classes} style={style} data-test-id={dataTestId}>
      {children}
    </span>
  )
}

export default Text
