import React from 'react'
import { withRouter } from 'react-router'
import isNil from 'lodash/isNil'

import { useCallback, useMediaQueries, useSelector, useActions, useEffect } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { Modal } from 'components/molecules/Modal'
import { NavigationBar } from 'components/atoms/NavigationBar'
import Width from 'components/_old/Width/Width'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Paper } from 'components/atoms/Paper'
import Icon from 'components/_old/Icon/Icon.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'

import { useFinishRegistration } from './useFinishRegistration.js'
import { useAllowNotificationsUrl } from 'app/pages/Dashboard/AllowNotificationsModal/useAllowNotificationsUrl.js'
import { fetchNotificationSettings as fetchNotificationSettingsActionCreator } from 'app/redux/actions/ui'

const FinishRegistrationModal = withRouter(({ location, params }) => {
  const { finishRegistration, forwardToIsaTransfer, ...restQuery } = location.query
  const { id: goalId } = params

  const { desktop } = useMediaQueries()
  const { icon, title, description, color, 'data-icon-color': dataIconColor } = useFinishRegistration()
  const isPromoNotificationsAccepted = useSelector(({ ui }) => Boolean(ui?.settings?.notifications?.promo))
  const [fetchNotificationSettings] = useActions([fetchNotificationSettingsActionCreator])
  const handleClose = useCallback(async () => {
    const nextUrlComponents = isNil(goalId)
      ? {
          module: 'dashboard',
          query: restQuery,
        }
      : {
          module: 'dashboard.portfolio',
          options: { id: goalId },
          query: restQuery,
        }

    if (forwardToIsaTransfer === 'true') {
      goTo(urlTo('transfer-isa-altus'))
      return
    }

    const { url: realNextUrl, isModified } = await useAllowNotificationsUrl(
      nextUrlComponents,
      isPromoNotificationsAccepted,
    )

    goTo(realNextUrl, { replace: true, scrollToTop: !isModified })
  }, [goalId, restQuery, isPromoNotificationsAccepted, forwardToIsaTransfer])

  useEffect(() => {
    if (finishRegistration) {
      fetchNotificationSettings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishRegistration])

  const continueButton = (
    <Button mods={{ size: 'big block' }} onClick={handleClose} data-test-id="registrationCompleteButton">
      Continue
    </Button>
  )

  const contentNode = (
    <Width size={28} center>
      <Paper top={80} left={desktop ? 0 : 16} right={desktop ? 0 : 16}>
        <AllCenter>
          <Icon type={icon} color={color} size={64} data-test-id="successIcon" data-icon-color={dataIconColor} />
        </AllCenter>
        <Paper top={32}>
          <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
            {title}
          </Typography>
        </Paper>
        <Paper top={24}>
          <Typography align="center">
            <Typo>{description}</Typo>
          </Typography>
        </Paper>
      </Paper>
    </Width>
  )

  return (
    <Modal open={!!finishRegistration} onClose={handleClose} data-test-id="registrationCompleteModal">
      <Width size={36}>
        {!desktop && <NavigationBar>Registration</NavigationBar>}
        {contentNode}
        {desktop && (
          <Paper top={56} bottom={80}>
            <Width size={24} center>
              {continueButton}
            </Width>
          </Paper>
        )}
        {!desktop && <ModalContentButtons isNewModal>{continueButton}</ModalContentButtons>}
      </Width>
    </Modal>
  )
})

export { FinishRegistrationModal }
