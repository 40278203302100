import React, { Fragment } from 'react'

import { useEffect, useMediaQueries, useMemo, useRef } from 'hooks'

import { bulkValidate } from 'helpers/validation.js'

import Button from 'components/_old/Button/Button.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'

import CleanInputOnFocus from 'components/atoms/CleanInputOnFocus/CleanInputOnFocus.jsx'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Preloader } from 'components/molecules/Preloader'

type PhoneNumberFormProps = {
  isLoading: boolean
  blockedText: string
  phone: string
  client: {
    country_code_by_ip: string
  }
  validation: object
  handlePhoneChange: (event: React.ChangeEvent, phone: string) => void
  handleSubmit: () => void
  handleClose: () => void
}

const PhoneNumberForm = ({
  isLoading,
  blockedText,
  phone,
  client,
  validation,
  handlePhoneChange,
  handleSubmit,
  handleClose,
}: PhoneNumberFormProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const inputRef = useRef()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const headerNode = useMemo(
    () => <NavigationBar onRightPartClick={handleClose} rightPartText="Cancel" />,
    [handleClose],
  )

  const titleNode = useMemo(
    () => (
      <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
        <Typo>Confirm your phone number</Typo>
      </Typography>
    ),
    [desktop],
  )

  const descriptionNode = useMemo(
    () => (
      <Typography size={desktop ? 14 : 16} align="center">
        <Typo>In the event of app notification problems we'll send you an SMS message to enable account access.</Typo>
      </Typography>
    ),
    [desktop],
  )

  const inputNode = useMemo(
    () => (
      <Fragment>
        <Validate rules={validation.phone.rules}>
          <Label errorMessages={validation.phone.errors} mods={{ color: 'darkgray' }}>
            Phone
            <CleanInputOnFocus
              onSetValue={(value) => {
                handlePhoneChange(null, value || '')
              }}
              cleanIfMatch={/\*/}
            >
              <Input
                ref={inputRef}
                name="phone"
                type="tel"
                mods={{ size: 'bigger' }}
                country={client.country_code_by_ip}
                onChange={handlePhoneChange}
                data-test-id="phoneInput"
              >
                {phone}
              </Input>
            </CleanInputOnFocus>
          </Label>
        </Validate>
        {blockedText ? (
          <Paper top={32}>
            <Typography align="center" color="error">
              <Typo>{blockedText}</Typo>
            </Typography>
          </Paper>
        ) : null}
      </Fragment>
    ),
    [phone, validation, blockedText, handlePhoneChange, client.country_code_by_ip],
  )

  const button = useMemo(
    () => (
      <Button mods={{ size: 'big block' }} disabled={!bulkValidate(validation) || isLoading} onClick={handleSubmit}>
        Send confirmation code
      </Button>
    ),
    [validation, isLoading, handleSubmit],
  )

  if (desktop) {
    return (
      <Paper top={8} bottom={120} right={24} left={24}>
        {headerNode}
        <Paper top={96} left={48} right={48}>
          {titleNode}
          <Paper top={32}>{descriptionNode}</Paper>
          <Paper top={32} right={24} left={24} style={{ position: 'relative' }}>
            <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
              {inputNode}
              <Paper top={56}>{button}</Paper>
            </div>
            {isLoading ? (
              <Paper style={{ position: 'absolute', top: 48, left: 0, right: 0 }}>
                <Paper top={32} bottom={32} style={{ position: 'relative' }}>
                  <Preloader loading={true} absolute background="background" />
                </Paper>
              </Paper>
            ) : null}
          </Paper>
        </Paper>
      </Paper>
    )
  }

  const contentNode = (
    <Fragment>
      <Paper top={8}>{titleNode}</Paper>
      <Paper top={24}>{descriptionNode}</Paper>
      <Paper top={48} style={{ position: 'relative' }}>
        <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>{inputNode}</div>
        {isLoading ? (
          <Paper style={{ position: 'absolute', top: 48, left: 0, right: 0 }}>
            <Paper top={32} bottom={32} style={{ position: 'relative' }}>
              <Preloader loading={true} absolute background="background" data-test-id="phonePreloader" />
            </Paper>
          </Paper>
        ) : null}
      </Paper>
    </Fragment>
  )

  return (
    <MobileLayout
      header={headerNode}
      content={contentNode}
      footer={isLoading ? null : <MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
    />
  )
}

export { PhoneNumberForm }
