const animation = {
  speed: {
    zero: '0ms',
    fast: '125ms',
    default: '325ms',
    slow: '500ms',
    number: {
      zero: Number.MIN_VALUE, // should be zero, but not quite to allow `CSSTransitionGroup` to work properly → https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MIN_VALUE
      fast: 125,
      default: 325,
      slow: 500,
    },
  },
  easing: {
    default: 'cubic-bezier(0.55, 0, 0.1, 1)',
  },
}

module.exports = animation
