import React from 'react'

import { PromoFirstPayment } from '../MonthlyPayment/PromoFirstPayment'

import { RecurringPaymentEdit } from './RecurringPaymentEdit'
import { RecurringPaymentProcessing } from './RecurringPaymentProcessing'
import { RecurringPaymentSetup } from './RecurringPaymentSetup'
import { RecurringPaymentStart } from './RecurringPaymentStart'
import { RecurringPaymentStop } from './RecurringPaymentStop'

const routes = [
  {
    module: 'recurring-payment',
    path: 'recurring-payment/',
    component: function Component(props) {
      return <RecurringPaymentStart {...props} />
    },
    childRoutes: [
      {
        module: 'recurring-payment-setup',
        path: 'setup/',
        component: function Component(props) {
          return <RecurringPaymentSetup {...props} />
        },
      },
      {
        module: 'recurring-payment-edit',
        path: 'edit/',
        component: function Component(props) {
          return <RecurringPaymentEdit {...props} />
        },
      },
      {
        module: 'recurring-payment-stop',
        path: 'stop/',
        component: function Component(props) {
          return <RecurringPaymentStop {...props} />
        },
      },
      {
        module: 'recurring-payment-processing',
        path: 'processing/',
        component: function Component(props) {
          return <RecurringPaymentProcessing {...props} />
        },
      },
      {
        module: 'fund-your-portfolio',
        path: 'fund-your-portfolio/',
        component: function Component(props) {
          return <PromoFirstPayment {...props} />
        },
      },
    ],
  },
]

export { routes }
