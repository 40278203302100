import React from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries, useCallback } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { type UseReportsInterface, type UseReportFiltersInterface } from '../../hooks'

import { Report } from './components/Report'

import './Reports.css'

function Reports({
  isFetched,
  hasReports,
  filteredReports,
}: UseReportsInterface & UseReportFiltersInterface): React.ReactElement | null {
  const { desktop } = useMediaQueries()
  const classes = classNames('Reports', { Reports_desktop: desktop, Reports_phone: !desktop })

  const renderEmptyReports = useCallback(
    (text: string, testId: string = 'dashboardReportsNoReports') => (
      <AllCenter>
        <Paper top={desktop ? 0 : 48} bottom={desktop ? 0 : 48}>
          <Typography align="center" data-test-id={testId}>
            <Typo>{text}</Typo>
          </Typography>
        </Paper>
      </AllCenter>
    ),
    [desktop],
  )

  if (!isFetched) {
    return null
  }

  if (isFetched && !hasReports) {
    return renderEmptyReports('No reports to show just yet')
  }

  if (isFetched && hasReports && filteredReports.length < 1) {
    const message = 'No reports matching current filter'

    if (desktop) {
      return (
        <Typography align="left" data-test-id="dashboardReportsNoReports">
          <Typo>{message}</Typo>
        </Typography>
      )
    }

    return renderEmptyReports(message)
  }

  return (
    <div className={classes}>
      <div className="Reports-Head">
        <Paper bottom={desktop ? 12 : 8} className="Reports-CreatedAt">
          <Typography size={desktop ? 16 : 14} color={desktop ? null : 'additional'} lineHeight="small">
            <Typo>Created at</Typo>
          </Typography>
        </Paper>
        <Paper left={desktop ? 32 : 0} bottom={desktop ? 12 : 8} className="Reports-DocumentName">
          <Typography size={desktop ? 16 : 14} color={desktop ? null : 'additional'} lineHeight="small">
            <Typo>Document name</Typo>
          </Typography>
        </Paper>
      </div>
      {filteredReports.map((report, index) => (
        <Report
          key={report.getTitle() ?? index}
          partsClassNames={{
            root: 'Reports-Report',
            createdAt: 'Reports-CreatedAt',
            title: 'Reports-DocumentName',
            download: 'Reports-Download',
          }}
          report={report}
        />
      ))}
    </div>
  )
}

export { Reports }
