import React, { Fragment } from 'react'

import { useMemo, useMediaQueries } from 'hooks'

import { isDarkTheme } from 'helpers/palette'

import { NotificationScopeType } from 'app/effector/notifications/models'

import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar, NavigationBarTitleMultipleChildHolder } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Typography } from 'components/atoms/Typography'

import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import NotificationGroup from 'components/organisms/Notification/NotificationGroup'

import { DesktopFooter, MobileFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { AllowNotificationsModal } from 'app/pages/Dashboard/AllowNotificationsModal/AllowNotificationsModal.jsx'
import { AddFundsModal } from 'app/pages/Dashboard/Goals/AddFunds'
import { ChangeGoalTunnelProvider } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'
import { FirstTopupBanner } from 'app/pages/Dashboard/Goals/components/FirstTopupBanner'
import { PortfolioLine } from 'app/pages/Dashboard/Goals/components/PortfolioLine'
import { SavingsPlanSetupBanner } from 'app/pages/Dashboard/Goals/components/SavingsPlanSetupBanner'
import { SippTransferModal } from 'app/pages/Dashboard/Goals/components/SippTransferModal'
import { SippWithdrawModal } from 'app/pages/Dashboard/Goals/components/SippWithdrawModal'
import { CashBreakdownActions } from 'app/pages/Dashboard/Goals/DIY/CashBreakdown/components/CashBreakdownActions'
import { CashBreakdownAmounts } from 'app/pages/Dashboard/Goals/DIY/CashBreakdown/components/CashBreakdownAmounts'
import { OptionsModal } from 'app/pages/Dashboard/Goals/Options/OptionsModal'
import { WithdrawFunds } from 'app/pages/Dashboard/Goals/WithdrawFunds/WithdrawFunds'
import { FinishRegistrationModal } from 'app/pages/Dashboard/SetupAccount/FinishRegistration/FinishRegistrationModal.jsx'

import { SavingsPlanLabel } from '../components/SavingsPlanLabel'
import { SavingsPlanSetup } from '../SavingsPlan/SavingsPlanSetup'

import { CashPortfolioHeader } from './components/CashPortfolioHeader'
import { CashPortfolioWarning } from './components/CashPortfolioWarning'
import { useCashPortfolio } from './hooks/useCashPortfolio'

import { regulatoryTypesCashText } from 'constants/portfolio'

import './CashPortfolio.css'

type CashPortfolioProps = {
  params: { id: string }
}

const CashPortfolio = ({ params: { id: idString } }: CashPortfolioProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const portfolioId = useMemo(() => parseInt(idString), [idString])
  const currentLocation = useMemo(() => window.location.pathname + window.location.search, [])

  const {
    portfolio,
    actions,
    showFinishSavingsPlanBanner,
    quickStart,
    showFirstTopupBanner,
    daysBeforePayment,
    handleAddFunds,
    handleBack,
  } = useCashPortfolio({
    portfolioId,
    currentLocation,
  })

  const subtitle = `${regulatoryTypesCashText[portfolio?.regulatory_type]} cash`

  const totalCashNode = useMemo(
    () => (
      <Fragment>
        <Typography size={desktop ? 48 : 32} lineHeight="small" weight="semibold">
          <PoundsWithPence amount={portfolio?.total_cash} showZeroPence data-test-id="cashAccountTotalValue" />
        </Typography>
        <Paper top={4} bottom={16}>
          <Typography size={16} color="additional">
            <Typo>Total</Typo>
          </Typography>
        </Paper>
      </Fragment>
    ),
    [portfolio?.total_cash, desktop],
  )

  const notifications = useMemo(
    () => (
      <NotificationGroup scope={NotificationScopeType.PORTFOLIO} portfolioId={portfolio?.id}>
        {(nodes) =>
          nodes && (
            <Paper top={desktop ? 56 : 0} bottom={desktop ? 0 : 32}>
              {nodes}
            </Paper>
          )
        }
      </NotificationGroup>
    ),
    [portfolio?.id, desktop],
  )

  const savingsPlanSetupBannerNode = useMemo(
    () =>
      showFinishSavingsPlanBanner && (
        <Paper bottom={desktop ? 48 : 32}>
          <SavingsPlanSetupBanner portfolio={portfolio} quickStart={quickStart} />
        </Paper>
      ),
    [desktop, showFinishSavingsPlanBanner, quickStart, portfolio],
  )

  const firstTopupBannerNode = useMemo(
    () =>
      showFirstTopupBanner && (
        <Paper bottom={desktop ? 48 : 32}>
          <FirstTopupBanner portfolio={portfolio} daysBeforePayment={daysBeforePayment} />
        </Paper>
      ),
    [desktop, portfolio, showFirstTopupBanner, daysBeforePayment],
  )

  const content = useMemo(() => {
    const content = (
      <SideSpacesCompensator>
        {totalCashNode}
        <Paper bottom={desktop ? 48 : 32}>
          <SavingsPlanLabel portfolio={portfolio} />
        </Paper>
        {savingsPlanSetupBannerNode}
        {firstTopupBannerNode}
        {portfolio && <CashBreakdownAmounts portfolio={portfolio} showCashFromPendingOrders={false} />}

        <Paper top={48} bottom={48}>
          <Button onClick={handleAddFunds} mods={{ size: 'big block' }} data-test-id="cashPortfolioAddCashButton">
            Add cash
          </Button>
        </Paper>
        <Card level={3} style={{ overflow: 'hidden' }}>
          <CashBreakdownActions actions={actions} />
        </Card>
        <Paper top={40}>
          <CashPortfolioWarning />
        </Paper>
      </SideSpacesCompensator>
    )

    if (desktop) {
      return (
        <Width size={25} center>
          {content}
        </Width>
      )
    }
    return (
      <Paper left={16} right={16}>
        {content}
      </Paper>
    )
  }, [totalCashNode, portfolio, handleAddFunds, actions, desktop, firstTopupBannerNode, savingsPlanSetupBannerNode])

  const desktopLayout = (
    <DesktopLayout
      header={<DesktopHeader />}
      content={
        <Fragment>
          <PortfolioLine type="cash" />
          <Inner twoColumns>
            <CashPortfolioHeader handleBack={handleBack} subtitle={subtitle} />
            {notifications}
            <Paper top={56}>{content}</Paper>
          </Inner>
        </Fragment>
      }
      footer={<DesktopFooter />}
    />
  )

  const mobileLayout = (
    <MobileLayout
      header={
        <NavigationBar leftPartText="Back" onLeftPartClick={handleBack} data-test-id="navigationLeftButton">
          <NavigationBarTitleMultipleChildHolder>
            <Typography color="inherit" align="center" lineHeight="small" weight="semibold">
              Cash
            </Typography>
            <Paper top={1} bottom={1}>
              <Typography
                size={12}
                weight="semibold"
                color={isDarkTheme() ? 'on_color_default' : 'default'}
                align="center"
                lineHeight="small"
                className="CashPortfolio-TypeSubtitle"
                inline
              >
                {subtitle}
              </Typography>
            </Paper>
          </NavigationBarTitleMultipleChildHolder>
        </NavigationBar>
      }
      content={
        <Fragment>
          {notifications}
          <Paper top={8}>{content}</Paper>
          <MobileFooter />
        </Fragment>
      }
    />
  )

  return (
    <ChangeGoalTunnelProvider>
      {desktop ? desktopLayout : mobileLayout}
      <AddFundsModal goal={portfolio} />
      {portfolio && <WithdrawFunds portfolioId={portfolio.id} />}
      {portfolio && <OptionsModal portfolio={portfolio} portfolioActions={actions} />}
      <FinishRegistrationModal />
      <AllowNotificationsModal module="dashboard.portfolio" />
      <SippWithdrawModal />
      <SippTransferModal />
      <SavingsPlanSetup portfolio={portfolio} />
      <GatewayDest name="withdraw-funds" />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </ChangeGoalTunnelProvider>
  )
}

export { CashPortfolio }
