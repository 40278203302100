import React from 'react'

import { useCallback, useMediaQueries, useMemo, useSelector } from 'hooks'

import { addOrdinalSuffix } from 'helpers/date.js'
import { palette } from 'helpers/palette'

import type { DirectDebitSubscription, RecurringPaymentSubscription } from 'app/effector/recurringPayments'

import { selectGoalTitle } from 'app/redux/selectors'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { CardAccent } from 'components/atoms/CardAccent'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { PortfolioManagedBy } from 'components/molecules/PortfolioManagedBy/PortfolioManagedBy'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import { frequencies, weekdays } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'

import { portfolioColors } from 'constants/portfolio'

type SubscriptionRaw = DirectDebitSubscription | RecurringPaymentSubscription

type Subscription = SubscriptionRaw & {
  type: 'DIRECT_DEBIT' | 'OPEN_BANKING'
  manageType: string
  regulatoryType: string
}

type SubscriptionCardProps = {
  subscription: Subscription
  onClick: (id: number, type: 'DIRECT_DEBIT' | 'OPEN_BANKING') => void
  'data-test-id'?: string
}

const SubscriptionCard = ({
  subscription,
  onClick,
  'data-test-id': dataTestId,
}: SubscriptionCardProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const title = useSelector((state) => selectGoalTitle(state, subscription.portfolio_id))
  const portfolio = useSelector((state) => state.portfolios.list.get(subscription.portfolio_id))

  const { amount, manageType, regulatoryType } = subscription

  const handleClick = useCallback(() => {
    if (typeof onClick === 'function' && subscription.portfolio_id) {
      onClick(subscription.portfolio_id, subscription.type)
    }
  }, [onClick, subscription])

  const frequencyText = useMemo(() => {
    if (subscription?.frequency === frequencies.WEEKLY || subscription?.frequency === frequencies.FORTNIGHT) {
      return `on ${weekdays[subscription?.start_day_week - 1]} every ${
        subscription?.frequency === frequencies.FORTNIGHT ? 'two weeks' : 'week'
      }`
    }

    return `on ${addOrdinalSuffix(subscription?.day_of_month)} of each month`
  }, [subscription])

  return (
    <SelectableCard onClick={handleClick} data-test-id={dataTestId}>
      <Paper top={24} bottom={24} left={desktop ? 24 : 20} right={desktop ? 24 : 20}>
        <ColumnarLayout>
          <Column size={3}>
            <Typography size={16} lineHeight="small" weight="semibold">
              <Typo>{title}</Typo>
            </Typography>
            <Paper top={4}>
              <PortfolioManagedBy
                manageType={manageType}
                regulatoryType={regulatoryType}
                isDefaultPresetUsed={portfolio.is_default_preset_used}
              />
            </Paper>
          </Column>
          <Column size={1}>
            {subscription.type === 'DIRECT_DEBIT' && (
              <Typography align="right">
                <Icon
                  type="directDebit"
                  width={76}
                  height={24}
                  color={palette['content-on-background-default']}
                  inline
                  data-test-id="directDebitLogo"
                />
              </Typography>
            )}
          </Column>
        </ColumnarLayout>
        <Paper top={24}>
          <Typography size={14} lineHeight="small" data-test-id="paymentDetailsText">
            <PoundsWithPence amount={amount} /> {frequencyText}
          </Typography>
        </Paper>
      </Paper>
      <CardAccent color={portfolioColors[manageType]} />
    </SelectableCard>
  )
}

export { SubscriptionCard }
