import { useState, useEffect } from 'react'

import EventEmitter from 'eventemitter3'
import debounce from 'lodash/debounce'

import rawMediaQueries from 'helpers/mediaQueries.js'

let Emitter
let viewport = { width: 1366, height: 768 } // Default size for server-side rendering

const handleWindowResize = debounce(() => {
  if (viewport.width !== window.innerWidth || viewport.height !== window.innerHeight) {
    viewport = { width: window.innerWidth, height: window.innerHeight }
    Emitter.emit('resize', viewport)
  }
}, 250)

const getMatchedQueries = (mediaQueries) => {
  const matchedQueries = {}

  for (const query in mediaQueries) {
    matchedQueries[query] = window.matchMedia(mediaQueries[query]).matches
  }

  return matchedQueries
}

/**
 * @param mediaQueries
 * @return {{ desktop: boolean, phone: boolean, tinyPhone: boolean }}
 */
const useMediaQueries = (mediaQueries = rawMediaQueries) => {
  const [matchedQueries, setMatchedQueries] = useState({})

  useEffect(() => {
    const handleResize = () => {
      setMatchedQueries({
        ...getMatchedQueries(mediaQueries),
      })
    }

    if (window && !Emitter) {
      Emitter = new EventEmitter()
      window.addEventListener('resize', handleWindowResize)
      window.addEventListener('orientationchange', handleWindowResize)
    }

    Emitter.on('resize', handleResize, this)

    return () => {
      Emitter.removeListener('resize', handleResize)

      if (window && Emitter && Emitter.listenerCount('resize') < 1) {
        Emitter = null
        window.removeEventListener('resize', handleWindowResize)
        window.removeEventListener('orientationchange', handleWindowResize)
      }
    }
  }, [mediaQueries])

  if (Object.keys(matchedQueries).length < 1) {
    const initialMatchedQueries = getMatchedQueries(mediaQueries)
    setMatchedQueries(initialMatchedQueries)

    return matchedQueries
  }

  return matchedQueries
}

export default useMediaQueries
