import React, { Fragment } from 'react'

import { useMediaQueries, useCallback } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'

import { GatewayDest } from 'components/atoms/Gateway'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

type CaptchaProps = {
  location: {
    query: {
      token: string
      action: 'enable-2fa' | 'disable-2fa'
      back: 'string'
    }
  }
}

const Captcha = ({
  location: {
    query: { token, action, back },
  },
}: CaptchaProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const handleBack = useCallback(() => {
    goTo(urlTo(back))
  }, [back])

  const props = {
    action,
    token,
    handleBack,
  }

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}

      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { Captcha }
