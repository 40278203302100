import React from 'react'

import { useUnit } from 'effector-react'
import PropTypes from 'prop-types'

import { useMediaQueries, useCallback, useMemo } from 'hooks'

import { format as formatMoney } from 'helpers/money'
import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router.js'

import { $dictsStore } from 'app/effector/dicts'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { ModalContentBody } from 'components/molecules/ModalContent/ModalContent.jsx'

import { Notation } from 'app/pages/Dashboard/Goals/Notation'

import { OptionsLayout } from '../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../Options/OptionsNavigationBar'

type PromoFirstPaymentProps = {
  portfolio: Portfolio
  handleBack: () => void
  handleClose: () => void
}

const PromoFirstPayment = ({ portfolio, handleClose, handleBack }: PromoFirstPaymentProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const handleAddFunds = useCallback(() => {
    goTo(urlTo(`dashboard.portfolio.add-funds`, { id: portfolio.id }))
  }, [portfolio.id])

  const { initialDepositMin } = useUnit($dictsStore)
  const initialDepositMinString = formatMoney(initialDepositMin)

  const content = useMemo(() => {
    if (desktop) {
      return (
        <Width size={30} center>
          <ModalContentBody size={null}>
            <Paper top={96} bottom={24} left={4} right={4}>
              <Notation
                text={`To set up a Savings Plan, you first need to invest a minimum of ${initialDepositMinString} in this portfolio.`}
                iconType="attention-24"
                iconColor={palette['status-success']}
              />
            </Paper>
          </ModalContentBody>
        </Width>
      )
    }
    return (
      <ModalContentBody size={null}>
        <AllCenter>
          <Paper top={32} bottom={32}>
            <Typography size={24} weight="semibold" lineHeight="small" align="center">
              <Typo>Set up your Savings Plan</Typo>
            </Typography>
          </Paper>
          <Icon type="attention-24" color={palette['status-success']} size={24} />
          <Paper top={16}>
            <Typography size={16} align="center">
              <Typo>{`To set up a Savings Plan, you first need to invest a minimum of ${initialDepositMinString} in this portfolio.`}</Typo>
            </Typography>
          </Paper>
        </AllCenter>
      </ModalContentBody>
    )
  }, [desktop, initialDepositMinString])

  return (
    <OptionsLayout
      header={
        <OptionsNavigationBar
          leftPartText="Back"
          onLeftPartClick={handleBack}
          rightPartText="Close"
          onRightPartClick={handleClose}
        >
          {desktop && <Typo>Set up your Savings Plan</Typo>}
        </OptionsNavigationBar>
      }
      content={content}
      button={
        <Button onClick={handleAddFunds} mods={{ size: 'big block' }} data-test-id="firstPaymentPromoPopupSetupButton">
          + Add funds
        </Button>
      }
      data-test-id="firstPaymentPromoPopup"
    />
  )
}

PromoFirstPayment.propTypes = {
  portfolio: PropTypes.object.isRequired,
  handleBack: PropTypes.func,
  handleClose: PropTypes.func,
}

export { PromoFirstPayment }
