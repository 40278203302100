import axios from 'helpers/ajax'
import { querystringFromObject } from 'helpers/ajax/querystring'

import { ApiError } from 'app/redux/models/errors'

type TransactionDTO = {
  created: string
  credit: string
  debit: string
  description: string
  goal: number
  id: number
  is_deletable: boolean
  modified: string
  payment_method: string
  state: string
  title: string
  trans_type: string
}

type SecurityTransactionDTO = {
  created: string
  description: string
  goal: number
  id: number
  value: string
  amount: string
  trans_type: string
  title: string
  state: string
  state_title: string
}

type TransactionsDTO = {
  total: string
  next?: string
  items: TransactionDTO[]
}

type SecurityTransactionsDTO = {
  total: string
  next?: string
  items: SecurityTransactionDTO[]
}

const getTransactions = async (
  params: Record<string, string>,
  cursor?: string,
): Promise<TransactionsDTO | ApiError> => {
  let queryString = params ? querystringFromObject(params) : ''
  // cursor must be passed as a separate parameter, since URL-encoding in `querystringFromObject` can change its value
  queryString = !cursor ? queryString : queryString ? `${queryString}&cursor=${cursor}` : `?cursor=${cursor}`
  try {
    const { data } = await axios.get(`transactions/${queryString}`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const getSecurityTransactions = async (
  params: Record<string, string>,
  cursor?: string,
): Promise<SecurityTransactionsDTO | ApiError> => {
  let queryString = params ? querystringFromObject(params) : ''
  // cursor must be passed as a separate parameter, since URL-encoding in `querystringFromObject` can change its value
  queryString = !cursor ? queryString : queryString ? `${queryString}&cursor=${cursor}` : `?cursor=${cursor}`
  try {
    const { data } = await axios.get(`security-transactions/${queryString}`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const getAwaitingTransactions = async (): Promise<TransactionDTO[] | ApiError> => {
  try {
    const { data } = await axios.get('transactions/awaiting/')

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const cancelTransaction = async (id: number): Promise<ApiError | unknown> => {
  try {
    const { data } = await axios.delete(`transactions/${id}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const moveCashBetweenPortfolios = async (transferData: {
  transfer_from: number
  transfer_to: number
  amount: number
}): Promise<ApiError | unknown> => {
  try {
    const { data } = await axios.post('transactions/move_cash/', transferData)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export {
  type TransactionDTO,
  type TransactionsDTO,
  getTransactions,
  type SecurityTransactionDTO,
  type SecurityTransactionsDTO,
  getSecurityTransactions,
  getAwaitingTransactions,
  cancelTransaction,
  moveCashBetweenPortfolios,
}
