import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import isEmpty from 'lodash/isEmpty'

import { useCallback, useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Typo } from 'components/_old/Typo/Typo'
import Link from 'components/_old/Link/Link.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

const SingleChoiceCard = ({ answer, tabIndex, selected, onClick }) => {
  const { desktop } = useMediaQueries()
  const { id, title, extra_data } = answer

  const hasExamples = !isEmpty(extra_data?.goals)

  const classes = classNames('Question_Selectable_card', { Question_Selectable_card_center: !hasExamples })

  const handleClick = useCallback(() => onClick(id), [id, onClick])

  const goalsExamples = useMemo(
    () =>
      hasExamples ? (
        <Paper top={desktop ? 8 : 16}>
          <Typography size={12} lineHeight="small" color="minor">
            <Typo>For example:</Typo>
          </Typography>
          <Paper top={4}>
            <Typography size={14}>
              <Typo>
                {extra_data.goals.map((goal, i) => (
                  <span key="goal">
                    {i === 0 ? goal : goal.toLowerCase()}
                    {i === extra_data.goals.length - 1 ? '' : ', '}
                  </span>
                ))}
              </Typo>
            </Typography>
          </Paper>
        </Paper>
      ) : null,
    [desktop, hasExamples, extra_data?.goals],
  )

  const timePeriod = useMemo(
    () =>
      extra_data?.time_period ? (
        <Paper top={8}>
          <ItemWithIcon
            space={4}
            icon={<Icon type="time-24" color={palette['status-success']} size={24} inline />}
            iconVerticalAlign="center"
            contentVerticalAlign="center"
            content={
              <Typography size={14} lineHeight="small" align={hasExamples ? 'left' : 'center'}>
                <Typo>{extra_data?.time_period}</Typo>
              </Typography>
            }
          />
        </Paper>
      ) : null,
    [extra_data?.time_period, hasExamples],
  )

  return (
    <SelectableCard
      className={classes}
      tabIndex={tabIndex}
      selected={selected}
      onClick={handleClick}
      data-test-id="questionnaireCardSingle"
    >
      <Link>
        <Paper top={desktop ? 24 : 16} bottom={desktop ? 24 : 16} left={desktop ? 32 : 16} right={desktop ? 32 : 16}>
          <Typography
            size={timePeriod ? 24 : 16}
            lineHeight="small"
            color={selected ? 'default' : 'inherit'}
            align={hasExamples ? 'left' : 'center'}
          >
            <Typo>{extra_data?.title ?? title}</Typo>
          </Typography>
          {timePeriod}
          {goalsExamples}
        </Paper>
      </Link>
    </SelectableCard>
  )
}

SingleChoiceCard.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.title,
    extra_data: PropTypes.shape({
      title: PropTypes.string,
      time_period: PropTypes.string,
      goals: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  tabIndex: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
}

export { SingleChoiceCard }
