import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { FieldLine } from 'components/_old/atoms/FieldLine'

import './DirectorsAndShareholdersFieldLine.css'

const DirectorsAndShareholdersFieldLine = ({ children, className }) => {
  const { desktop } = useMediaQueries()

  const classes = classNames('DirectorsAndShareholdersFieldLine', className)

  return (
    <FieldLine
      className={classes}
      label="Directors and significant shareholders"
      layout={desktop ? 'horizontal' : 'vertical'}
    >
      <div className="DirectorsAndShareholdersFieldLine-Content">
        {React.Children.map(children, (child, index) => (
          <div key={index} className="DirectorsAndShareholdersFieldLine-Item">
            {child}
          </div>
        ))}
      </div>
    </FieldLine>
  )
}

DirectorsAndShareholdersFieldLine.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export { DirectorsAndShareholdersFieldLine }
