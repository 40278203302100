import axios from 'axios'
import axiosRetry from 'axios-retry'

// import { features } from 'helpers/features'
import { isJest } from 'helpers/isJest.js'

import { axiosWrap } from './axiosWrap'
import {
  useAccessToken,
  useCSRFToken,
  addDebugInformation,
  modifyApi,
  e2eTests,
  transformError,
  handle401,
  handle403,
  errorLogging,
  invalidateStore,
  handleSessionTimeout,
  // rewriteBaseUrl,
} from './middlewares'

const middlewares = [
  // features
  // rewriteBaseUrl('<FROM>', '<TO>', () => features.get(<FEATURE>)),
  // request
  handleSessionTimeout,
  useAccessToken,
  useCSRFToken,
  addDebugInformation,
  modifyApi,
  e2eTests,
  // errors
  transformError, // should be on top among error handlers
  handle401,
  handle403,
  errorLogging,
  invalidateStore,
]

const localAxios = axiosWrap(axios.create({ baseURL: CONFIG?.API_ROOT ?? null }), middlewares)

if (!isJest) {
  axiosRetry(localAxios, {
    retries: 2,
    retryCondition: (error) => {
      if (!error.response) {
        return true
      }
      if (error.response?.status) {
        // if error status 5xx
        return `${error.response.status}`[0] === '5'
      }

      return false
    },
    delay: 500,
  })
}

export * from 'axios'
export default localAxios
