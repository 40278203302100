import React from 'react'
import { withRouter } from 'react-router'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'

import { SavingsPlanPromo } from 'components/organisms/SavingsPlanPromo'

import { OptionsLayout } from '../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../Options/OptionsNavigationBar'

import { usePromoMonthlyPayment } from './hooks/usePromoMonthlyPayment'

type PromoMonthlyPaymentProps = {
  portfolio: Portfolio
  location: {
    query?: {
      back?: string
    }
  }
  handleClose: () => void
}

const PromoMonthlyPayment = withRouter(
  ({ portfolio, location, handleClose }: PromoMonthlyPaymentProps): React.ReactElement => {
    const { isQuickStartInProgress, handleSetup, handleBack } = usePromoMonthlyPayment({ portfolio, location })

    const content = (
      <Paper top={8}>
        <SavingsPlanPromo
          title="Set up Savings Plan"
          isQuickStartInProgress={isQuickStartInProgress}
          onContinue={handleSetup}
        />
      </Paper>
    )

    return (
      <OptionsLayout
        header={
          <OptionsNavigationBar
            leftPartText="Back"
            onLeftPartClick={handleBack}
            rightPartText="Close"
            onRightPartClick={handleClose}
            linkStyle="onColorWhiteToRed"
          >
            <Typo>&nbsp;</Typo>
          </OptionsNavigationBar>
        }
        content={content}
        data-test-id="monthlyPaymentPromoPopup"
      />
    )
  },
)

export { PromoMonthlyPayment }
