import React from 'react'

import { CreateReport } from './CreateReport'
import { ReportList } from './ReportList'
import { ReportsRoot } from './ReportsRoot'

const childRoutes = [
  {
    isNewLayout: true,
    index: true,
    module: 'list',
    component: function Component(props) {
      return (
        <ReportsRoot key="reports-root" {...props}>
          {(reportProps) => <ReportList {...reportProps} />}
        </ReportsRoot>
      )
    },
  },
  {
    isNewLayout: true,
    module: 'create-report',
    path: 'create-report/',
    component: function Component(props) {
      return (
        <ReportsRoot key="reports-root" {...props}>
          {(reportProps) => <CreateReport {...reportProps} />}
        </ReportsRoot>
      )
    },
  },
]

export default {
  module: 'reports',
  path: 'reports/',
  childRoutes,
  indexRoute: childRoutes.find((route) => route.index),
}
