import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useMediaQueries } from 'hooks'
import classNames from 'classnames/dedupe'
import { useUnit } from 'effector-react'

import {
  combineErrors,
  backendErrorsToObj,
  bulkValidate,
  bulkValidateList,
  createAddressesValidationList,
  birthdayRules,
  nameRules,
} from 'helpers/validation.js'
import { trackEvent } from 'helpers/analytics'

import { roles as contactsRoles } from 'constants/contacts.js'
import { types as clientTypes } from 'constants/client'

import { Contact } from 'app/effector/contacts/models'

import Width from 'components/_old/Width/Width'
import Card from 'components/_old/Card/Card.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Form from 'components/_old/Form/Form.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Button from 'components/_old/Button/Button.jsx'
import AddressesForm from 'app/pages/Forms/AddressesForm.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { RadioWithLabel, CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'
import { RadioGroup, Radio } from 'react-radio-group'
import CleanInputOnFocus from 'components/atoms/CleanInputOnFocus/CleanInputOnFocus.jsx'

import SignificantControlInlineHelp from 'app/pages/Dashboard/SetupAccount/Business/DirectorsAndShareholdersSetup/SignificantControlInlineHelp.jsx'

import { changeContactField, changeAddressField as changeContactAddressField, $contacts } from 'app/effector/contacts'
import { $dictsStore } from 'app/effector/dicts'

import { Address, AddressList } from 'app/redux/models/address'

import './AddingDirectorForm.css'

const AddingDirectorForm = ({ wrapSubmitButton, contactId, onSubmit: parentHandleSubmit }) => {
  const { desktop } = useMediaQueries()

  const contacts = useUnit($contacts)
  const contactInStore = contacts.getContact(contactId)
  const isNew = contactId === null
  const { dicts } = useUnit($dictsStore)
  const { genders } = dicts

  const [newContact, setNewContact] = useState(
    Contact.createFromObject({
      role: contactsRoles.DIRECTOR,
    }),
  )
  const contact = contactInStore || newContact

  const handleContactsFieldChange = useCallback(
    (field, value) => {
      if (isNew) {
        setNewContact((prevNewContact) => Contact.createFromObject({ ...prevNewContact, [field]: value }))
        return
      }
      changeContactField({ field: { [field]: value }, id: contact.id })
    },
    [setNewContact, isNew, contact],
  )

  const handleAddressFieldChange = useCallback(
    (field, value, index) => {
      if (isNew) {
        setNewContact((prevNewContact) => {
          const newAddresses = AddressList.createFromObject({ ...prevNewContact.addresses })
          const address = newAddresses.getAddress(index)

          newAddresses.setAddress(index, Address.createFromObject({ ...address, [field]: value }))

          return Contact.createFromObject({ ...prevNewContact, addresses: newAddresses })
        })
        return
      }
      changeContactAddressField({
        field: { [field]: value },
        addressIndex: index,
        id: contact.id,
      })
    },
    [setNewContact, isNew, contact],
  )

  const handleSubmit = useCallback(() => {
    trackEvent({ action: 'add_director_submitted', client_type: clientTypes.BUSINESS })

    parentHandleSubmit(contact)
  }, [contact, parentHandleSubmit])

  const inputModsRef = useRef({ size: 'bigger' })

  if ((!isNew && !contactInStore) || !genders) {
    return null
  }

  const validation = combineErrors(
    {
      gender: {
        rules: [contact.gender !== null],
        errors: ['Gender should be specified'],
      },
      first_name: nameRules(contact.first_name, 'First name'),
      middle_name: nameRules(contact.middle_name, 'Middle name', false),
      last_name: nameRules(contact.last_name, 'Last name'),
      birthday: birthdayRules(contact.birthday),
    },
    backendErrorsToObj(contact.error),
  )

  const addressesValidationsList = createAddressesValidationList(contact.addresses)
  const isAddressesValid = bulkValidateList(addressesValidationsList)

  const classes = classNames('AddingDirectorForm', { AddingDirectorForm_phone: !desktop })

  const submitButton = (
    <Button
      type="submit"
      mods={{ size: 'big block' }}
      onClick={handleSubmit}
      disabled={!bulkValidate(validation) || !isAddressesValid}
      tabIndex={3}
      data-test-id="addingDirectorFormSubmitButton"
    >
      Continue
    </Button>
  )

  return (
    <div className={classes}>
      <SubmitOnEnter>
        <Form>
          <Width size={desktop ? 26 : null}>
            <Card
              mods={{
                'no-padding': 'all',
                theme: !desktop ? 'edge-to-edge transparent' : null,
              }}
            >
              <Card
                className="AddingDirectorForm-TopBlock"
                mods={{
                  theme: 'transparent border-bottom',
                  'no-padding': desktop ? null : 'top',
                }}
              >
                <Validate rules={validation.gender.rules}>
                  <Label errorMessages={validation.gender.errors}>
                    Gender
                    <RadioGroup
                      selectedValue={contact.gender}
                      onChange={(value) => handleContactsFieldChange('gender', value)}
                    >
                      <ColumnarLayout fluid>
                        {genders?.map((gender) => {
                          return (
                            <Column size={0} key={gender.name}>
                              <RadioWithLabel
                                CustomInput={Radio}
                                label={gender.name}
                                value={gender.id}
                                data-test-id="addingDirectorFormGenderRadio"
                              />
                            </Column>
                          )
                        })}
                      </ColumnarLayout>
                    </RadioGroup>
                  </Label>
                </Validate>
                <Validate rules={validation.first_name.rules}>
                  <Label errorMessages={validation.first_name.errors} data-test-id="addingDirectorFormFirstNameLabel">
                    First name
                    <Input
                      name="fname"
                      autoComplete="given-name"
                      type="text"
                      onChange={(_event, value) => handleContactsFieldChange('first_name', value)}
                      tabIndex={1}
                      mods={inputModsRef.current}
                      data-test-id="addingDirectorFormFirstNameInput"
                    >
                      {contact.first_name}
                    </Input>
                  </Label>
                </Validate>
                <Validate rules={validation.last_name.rules}>
                  <Label
                    errorMessages={validation.middle_name.errors}
                    postfield={<Text muted>Optional</Text>}
                    data-test-id="addingDirectorFormMiddleNameLabel"
                  >
                    Middle name
                    <Input
                      name="mname"
                      autoComplete="additional-name"
                      type="text"
                      onChange={(_event, value) => handleContactsFieldChange('middle_name', value)}
                      tabIndex={2}
                      mods={inputModsRef.current}
                      data-test-id="addingDirectorFormMiddleNameInput"
                    >
                      {contact.middle_name}
                    </Input>
                  </Label>
                </Validate>
                <Validate rules={validation.last_name.rules}>
                  <Label errorMessages={validation.last_name.errors} data-test-id="addingDirectorFormLastNameLabel">
                    Last name
                    <Input
                      name="lname"
                      autoComplete="family-name"
                      type="text"
                      onChange={(_event, value) => handleContactsFieldChange('last_name', value)}
                      tabIndex={3}
                      mods={inputModsRef.current}
                      data-test-id="addingDirectorFormLastNameInput"
                    >
                      {contact.last_name}
                    </Input>
                  </Label>
                </Validate>
                <Validate rules={validation.birthday.rules}>
                  <Label errorMessages={validation.birthday.errors} data-test-id="addingDirectorFormBirthdayLabel">
                    Date of birth
                    <CleanInputOnFocus onSetValue={(value) => handleContactsFieldChange('birthday', value || '')}>
                      <Input
                        name="birthday"
                        type="date"
                        onChange={(_event, value) => handleContactsFieldChange('birthday', value)}
                        tabIndex={5}
                        mods={inputModsRef.current}
                        data-test-id="addingDirectorFormBirthdayInput"
                      >
                        {contact.birthday}
                      </Input>
                    </CleanInputOnFocus>
                  </Label>
                </Validate>
                <Card
                  mods={{
                    theme: 'transparent',
                    'no-padding': 'right bottom left',
                  }}
                >
                  <CheckboxWithLabel
                    label={<Typo>This person has significant control over the company</Typo>}
                    size="smaller"
                    postfield={<SignificantControlInlineHelp />}
                    checked={contact.has_significant_control}
                    onChange={(value) => handleContactsFieldChange('has_significant_control', value)}
                    data-test-id="addingDirectorFormSignificantControlCheckbox"
                  />
                </Card>
              </Card>
              <Card
                mods={{
                  theme: 'transparent',
                  'no-padding': 'all',
                }}
              >
                <Card
                  mods={{
                    theme: 'transparent',
                    'no-padding': desktop ? 'bottom' : '',
                  }}
                >
                  <Text smaller>Residential address</Text>
                </Card>
                <AddressesForm
                  addresses={contact.addresses}
                  validationsList={addressesValidationsList}
                  onAddressFieldChange={handleAddressFieldChange}
                  onAddressesChange={(addresses) => handleContactsFieldChange('addresses', addresses)}
                />
                {wrapSubmitButton(submitButton)}
              </Card>
            </Card>
          </Width>
        </Form>
      </SubmitOnEnter>
    </div>
  )
}

AddingDirectorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default AddingDirectorForm
