import React from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { DesktopHeader } from 'app/containers/Header'
import { DesktopFooter } from 'app/containers/Footer'
import { DesktopLayout } from 'components/atoms/Layouts'
import Inner from 'components/_old/Inner/Inner.jsx'
import Width from 'components/_old/Width/Width'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Card } from 'components/atoms/Card'
import { Select } from 'components/molecules/Select'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { PortfolioType } from '../components/PortfolioType.jsx'
import { SharePortfolioBanner } from 'components/molecules/SharePortfolioBanner'

import { portfolioManageTypes } from 'constants/goal'
import { Skeleton } from 'components/atoms/Skeleton'

const AnalyticsDesktopLayout = ({
  isLoading,
  scrollableElementRef,
  fromPortfolio,
  portfoliosOptions,
  selected,
  selectedPortfolio,
  selectedPortfolioTitle,
  handlePortfolioSelect,
  handleBackToPortfolio,
  tabs,
  leftContent,
  rightContent,
}) => {
  const headerNode = useMemo(() => {
    return <DesktopHeader activeItem={fromPortfolio ? 'dashboard' : 'analytics'} />
  }, [fromPortfolio])

  const backLinkNode = useMemo(
    () =>
      fromPortfolio ? (
        <Paper bottom={32}>
          <Link onClick={handleBackToPortfolio} mods={{ color: 'content-on-background-default' }}>
            <ItemWithIcon
              space={16}
              icon={<Icon type="back-new" size={20} color="inherit" />}
              content={<Typography color="inherit">Back</Typography>}
              iconVerticalAlign="center"
            />
          </Link>
        </Paper>
      ) : null,
    [fromPortfolio, handleBackToPortfolio],
  )

  const headlineNode = useMemo(() => {
    if (isLoading) {
      return (
        <Skeleton shown mix>
          <Select selected="all" options={[{ value: 'all', name: 'All portfolios' }]} />
        </Skeleton>
      )
    }

    if (portfoliosOptions.length === 0) {
      return (
        <Typography size={32} lineHeight="small" weight="semibold">
          <Typo>{selectedPortfolioTitle}</Typo>
        </Typography>
      )
    }

    return <Select selected={selected} options={portfoliosOptions} onSelect={handlePortfolioSelect} />
  }, [isLoading, selected, selectedPortfolioTitle, portfoliosOptions, handlePortfolioSelect])

  const portfolioTypeNode = useMemo(() => {
    if (!selectedPortfolio) {
      return null
    }

    return (
      <Paper top={12}>
        <PortfolioType portfolio={selectedPortfolio} />
      </Paper>
    )
  }, [selectedPortfolio])

  const shareButton = useMemo(
    () =>
      selectedPortfolio?.manage_type === portfolioManageTypes.DIY ? (
        <Paper top={rightContent ? 40 : 0}>
          <SharePortfolioBanner portfolioId={selectedPortfolio.id} isSharingEnabled={!!selectedPortfolio.share_url} />
        </Paper>
      ) : null,
    [selectedPortfolio, rightContent],
  )

  const contentNode = useMemo(
    () => (
      <Inner twoColumns>
        {backLinkNode}
        {headlineNode}
        {portfolioTypeNode}
        {tabs}
        <Paper top={56} bottom={80}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1}>{React.cloneElement(leftContent, { layoutNode: scrollableElementRef })}</Column>
            <Column size={0}>
              <Paper left={80}>
                <Width size={19}>
                  {rightContent && (
                    <Skeleton shown={isLoading} mix>
                      <Card>
                        <Paper top={24} bottom={24} left={24} right={24}>
                          {rightContent}
                        </Paper>
                      </Card>
                    </Skeleton>
                  )}
                  {shareButton}
                </Width>
              </Paper>
            </Column>
          </ColumnarLayout>
        </Paper>
      </Inner>
    ),
    [scrollableElementRef, headlineNode, portfolioTypeNode, leftContent, rightContent],
  )

  return (
    <DesktopLayout ref={scrollableElementRef} header={headerNode} content={contentNode} footer={<DesktopFooter />} />
  )
}

AnalyticsDesktopLayout.propTypes = {
  isLoading: PropTypes.bool,
  scrollableElementRef: PropTypes.object,
  fromPortfolio: PropTypes.bool,
  portfoliosOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selected: PropTypes.string,
  selectedPortfolio: PropTypes.object,
  selectedPortfolioTitle: PropTypes.string,
  handlePortfolioSelect: PropTypes.func,
  handleBackToPortfolio: PropTypes.func,
  tabs: PropTypes.node,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
}

export { AnalyticsDesktopLayout }
