import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'
import {
  useSecurity,
  useSecurityLabels,
  useAddToPortfolio,
  useRemovableSecurities,
  useSecurityQuickStart,
} from './hooks'

import { GatewayDest } from 'components/atoms/Gateway'
import { Restrictions } from 'app/pages/Dashboard/Goals/DIY/Restrictions'
import { Abandon } from 'app/pages/Dashboard/Goals/DIY/EditWeights/Abandon'
import { SlowSecurityMention } from 'app/pages/Dashboard/Goals/DIY/SlowSecurityMention'
import { Modal } from 'components/molecules/Modal'

import { SecurityAnalytics } from 'app/pages/Dashboard/Analytics/SecurityAnalytics'
import { SavingsPlanSetup } from 'app/pages/Dashboard/Goals/SavingsPlan/SavingsPlanSetup'
import { LumpSumSetup } from 'app/pages/Dashboard/Goals/SavingsPlan/LumpSumSetup'
import { OpenSippModal } from 'app/pages/Dashboard/Accounts/components/OpenSippModal'
import { QuickStartModal } from 'components/organisms/QuickStartModal'

import { PortfoliosModal } from './components'

const Security = ({ children, params: { id: idString } = {}, location = {}, routes }) => {
  const { query = {} } = location
  const id = useMemo(() => (idString ? parseInt(idString, 10) : null), [idString])
  const activeRouteNames = routes.map((route) => route.module)

  const {
    version,
    isAnalyticsLoading,
    isDetailedSecurityInfoLoading,
    isOtherPortfoliosSecuritiesLoading,
    security,
    attemptedPortfolioId,
    portfoliosWithThisSecurity,
    isAddedToCurrentPortfolio,
    allVisibleDiyPortfolios,
    periodPriceData,
    chartData,
    totalReturnsData,
    selectedPeriod,
    holdings,
    sectors,
    regions,
    filters,
    abandonModalOpen,
    restrictionModalOpen,
    restrictionModalType,
    isSlowSecurityMentionOpen,
    currentPortfolio,
    searchValue,
    showInvestNowButton,
    handleSearch,
    isFromPreset,
    handleRemoveSecurity,
    handleSlowSecurityMentionClose,
    handleAbandonModalClose,
    handleRestrictionModalClose,
    handlePeriodSelect,
    handleAddSecurity,
    handleAddSecurityRaw,
    handleBack,
    handleCancel,
    goToSecurityAnalytics,
    handleCloseAnalytics,
    handleInvestNow,
    handleCloseQuickStart,
    sendGaEventOnAddToPortfolioClick,
  } = useSecurity(id, query)

  const removableSecuritiesIds = useRemovableSecurities(query.portfolioId)
  const isRemovableSecurity = removableSecuritiesIds.includes(id)

  const securityLabels = useSecurityLabels(security)

  const {
    portfoliosModalOpen,
    handlePortfoliosModalOpen,
    handlePortfoliosModalClose,
    createNewPortfolio,
    isIsaOrSippAvailable,
  } = useAddToPortfolio(handleAddSecurity, query)

  const {
    isModalOpened: isQuickStartModalOpened,
    handleStartWithSavingsPlan,
    handleStartWithLumpSum,
    handleClose: handleCloseQuickStartModal,
  } = useSecurityQuickStart({
    security,
    location,
  })

  const props = {
    version,
    isAnalyticsLoading,
    isDetailedSecurityInfoLoading,
    isOtherPortfoliosSecuritiesLoading,
    security,
    isRemovableSecurity,
    portfoliosWithThisSecurity,
    isAddedToCurrentPortfolio,
    periodPriceData,
    chartData,
    totalReturnsData,
    selectedPeriod,
    holdings,
    sectors,
    regions,
    filters,
    securityLabels,
    itemsAreClickable: false,
    isFromPreset,
    goToSecurityAnalytics,
    searchValue,
    showInvestNowButton,
    handleSearch,
    handleRemoveSecurity,
    handlePeriodSelect,
    handleAddSecurity,
    handleBack,
    handleCancel,
    handleClose: handleCloseAnalytics,
    handlePortfoliosModalOpen,
    handleInvestNow,
    sendGaEventOnAddToPortfolioClick,
  }

  if (!security) {
    return null
  }

  return (
    <Fragment>
      {React.cloneElement(children, { ...props })}

      <Abandon open={abandonModalOpen} onClose={handleAbandonModalClose} onContinue={handleCancel} />
      {restrictionModalType && (
        <Restrictions
          portfolioId={query.portfolioId ?? attemptedPortfolioId}
          open={restrictionModalOpen}
          onClose={handleRestrictionModalClose}
          type={restrictionModalType}
        />
      )}
      <SlowSecurityMention
        open={isSlowSecurityMentionOpen}
        onClose={handleSlowSecurityMentionClose}
        onSubmit={handleAddSecurityRaw}
      />
      <PortfoliosModal
        open={portfoliosModalOpen}
        isIsaOrSippAvailable={isIsaOrSippAvailable}
        portfolios={allVisibleDiyPortfolios}
        portfoliosWithThisSecurity={portfoliosWithThisSecurity}
        handleAddSecurity={handleAddSecurity}
        createNewPortfolio={createNewPortfolio}
        onClose={handlePortfoliosModalClose}
      />
      <QuickStartModal
        isOpen={isQuickStartModalOpened}
        onSavingsPlanClick={handleStartWithSavingsPlan}
        onLumpSumClick={handleStartWithLumpSum}
        onClose={handleCloseQuickStartModal}
      />
      <SavingsPlanSetup portfolio={currentPortfolio} securityId={security?.id} onClose={handleCloseQuickStart} />
      <LumpSumSetup portfolio={currentPortfolio} securityId={security?.id} onClose={handleCloseQuickStart} />
      <OpenSippModal />

      <Modal open={activeRouteNames.includes('security-analytics')} close={null} fullScreen>
        {activeRouteNames.includes('holdings') && <SecurityAnalytics type="holdings" {...props} />}
        {activeRouteNames.includes('regions') && <SecurityAnalytics type="regions" {...props} />}
        {activeRouteNames.includes('sectors') && <SecurityAnalytics type="sectors" {...props} />}
      </Modal>
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

Security.propTypes = {
  children: PropTypes.node,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  location: PropTypes.shape({
    query: PropTypes.shape({
      portfolioId: PropTypes.string,
      attemptPortfolioId: PropTypes.string,
    }),
  }),
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      module: PropTypes.string,
    }),
  ),
}

export { Security }
