import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

import type { ReferralCodeStore } from '../types.ts'

const getReferralCode = async (): Promise<ReferralCodeStore | ApiError> => {
  try {
    const { data } = await axios.get('/marketing/referrals/share-link-data/', { shouldNotSend404: true })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { getReferralCode }
