import React from 'react'

import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import Segment from 'components/atoms/Segment/Segment.jsx'

const BankAccountStateContent = ({ children, title, description, icon, 'data-test-id': dataTestId }) => {
  const { desktop, phone } = useMediaQueries()

  return (
    <div data-test-id={dataTestId}>
      {icon && (
        <AllCenter>
          <Segment mods={{ margin: 'biggest', noMargin: desktop ? 'bottom' : 'all' }}>
            {icon && <Icon {...icon} data-test-id={`${dataTestId}Icon-${icon.type}`} />}
          </Segment>
        </AllCenter>
      )}
      <Headline level={desktop ? 1 : 2} mods={{ text: 'center' }} data-test-id={`${dataTestId}Title`}>
        <Typo>{title}</Typo>
      </Headline>
      <Segment mods={{ margin: desktop ? 'bigger' : 'double', noMargin: 'top' }}>
        <AllCenter>
          {typeof description === 'string' ? (
            <Width size={phone ? 20 : null}>
              <Text block center>
                <Typo>{description}</Typo>
              </Text>
            </Width>
          ) : (
            description
          )}
        </AllCenter>
      </Segment>
      {children}
    </div>
  )
}

BankAccountStateContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  icon: PropTypes.shape({
    color: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.number,
  }),
  'data-test-id': PropTypes.string,
}

export { BankAccountStateContent }
