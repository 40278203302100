import React from 'react'

import classNames from 'classnames/dedupe'

import { useState } from 'hooks'

import { palette } from 'helpers/palette/'

import './CardNew.css'

type CardNewProps = {
  className?: string
  backgroundColor?: string
  hoverColor?: string
  shadow?: number
  shadowOnHover?: number
  borderColor?: string
  disabledColor?: string
  backgroundImage?: string | null
  borderRadius?: number
  children?: React.ReactNode
  style?: Record<string, string>
  error?: boolean
  selected?: boolean
  disabled?: boolean
  clickable?: boolean
  hoverable?: boolean
  hoverOverlay?: boolean
  'data-test-id'?: string
}

const CardNew = ({
  backgroundColor = palette['background-default'],
  hoverColor = palette['background-default'],
  disabledColor = palette['background-default'],
  shadow = 4,
  shadowOnHover = 5,
  borderColor = 'transparent',
  style = {},
  backgroundImage,
  children,
  borderRadius = 16,
  className = '',
  disabled = false,
  error = false,
  selected = false,
  clickable = true,
  hoverOverlay = false,
  hoverable = false,
  'data-test-id': dataTestId,
}: CardNewProps): React.ReactElement => {
  const [isHovered, setIsHovered] = useState(false)

  const classes = classNames('CardNew', className, {
    CardNew_disabled: disabled,
    CardNew_error: error,
    CardNew_selected: selected,
    CardNew_withImage: !!backgroundImage,
    CardNew_clickable: clickable,
    CardNew_hoverOverlay: hoverOverlay,
    CardNew_hoverable: hoverable,
  })

  const bgColor = disabled ? disabledColor : isHovered && clickable && !hoverOverlay ? hoverColor : backgroundColor

  return (
    <div
      className={classes}
      tabIndex={clickable ? 0 : -1}
      style={{
        // @ts-expect-error: the custom properties are valid properties for the style object
        '--card-new-box-shadow': `var(--shadow-level-${shadow})`,
        '--card-new-box-shadow-on-hover': `var(--shadow-level-${shadowOnHover})`,

        backgroundColor: bgColor,
        borderRadius: `${borderRadius}px`,
        borderColor,
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
        ...style,
      }}
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      data-test-id={dataTestId}
    >
      <div className="CardNew_mask"></div>
      {children}
    </div>
  )
}

export { CardNew }
