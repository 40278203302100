import { ApiError } from 'app/redux/models/errors'

export const handleExternalLink = async (apiRequest, url) => {
  const redirectUri = `${window.location.origin}${url}`
  const data = await apiRequest(redirectUri)

  if (data instanceof ApiError) {
    throw data
  }
  window.location = data.auth_uri
}
