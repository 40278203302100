import React from 'react'

import { useMediaQueries } from 'hooks'

import moment from 'helpers/date.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'
import { Profit } from 'components/molecules/Profit'

type DividendsReceivedProps = {
  paidOutSum: number
  hoveredData?: { date: Date; value: number }
  isSlice?: boolean
}

const DividendsReceived = ({ paidOutSum, hoveredData, isSlice }: DividendsReceivedProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  if (hoveredData)
    return (
      <Paper data-test-id="dividendsReceivedHoveredBlock">
        <Paper top={desktop ? 16 : 12} left={desktop ? 20 : 16} right={desktop ? 20 : 16} bottom={desktop ? 16 : 12}>
          <AllCenter>
            <Paper bottom={desktop ? 8 : 0}>
              <Typography size={desktop ? 32 : 20} lineHeight={desktop ? 'small' : 'large'} weight="semibold">
                <PoundsWithPence amount={hoveredData.value} showZeroPence={!!hoveredData.value} />
              </Typography>
            </Paper>
            <Typography size={desktop ? 16 : 14} color="minor" lineHeight={desktop ? 'small' : 'large'}>
              <Typo>{moment(hoveredData.date).format('MMM YYYY')}</Typo>
            </Typography>
          </AllCenter>
        </Paper>
      </Paper>
    )

  return (
    <Card>
      <Paper top={desktop ? 16 : 12} left={desktop ? 20 : 16} right={desktop ? 20 : 16} bottom={desktop ? 16 : 12}>
        <Paper bottom={desktop ? 8 : 0}>
          <Typography size={desktop ? 32 : 20} lineHeight={desktop ? 'small' : 'large'} weight="semibold">
            <Profit amount={paidOutSum} smallPences data-test-id="dividendsReceived"></Profit>
          </Typography>
        </Paper>
        <Typography size={desktop ? 16 : 14} color="additional" lineHeight={desktop ? 'small' : 'large'}>
          <Typo>{isSlice ? 'Income received' : 'Dividends received'}</Typo>
        </Typography>
      </Paper>
    </Card>
  )
}

export { DividendsReceived }
