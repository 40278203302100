import { formatPercent } from 'helpers/money'

import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

import { SecurityDocumentList } from './Document/SecurityDocumentList.js'
import { SecurityHistoryCollection } from './History/SecurityHistoryCollection.js'
import { SecurityProperties } from './SecurityProperties'
import { SecurityStatsCollection } from './Stats/SecurityStatsCollection.js'
import { SecurityTotalReturnsCollection } from './TotalReturns/SecurityTotalReturnsCollection'

/**
 * @class
 * @typedef {Object} Security
 */
export class Security extends Entity {
  constructor() {
    super()

    /** @type {?number} */
    this.id = null
    /** @type {?string} */
    this.logo_uri = null
    /** @type {?string} */
    this.title = null
    /** @type {?string} */
    this.ticker = null
    /** @type {?string} */
    this.short_description = null
    /** @type {?'STOCK'|?'BOND'|?'ALTERNATIVE'} */
    this.type = null
    /** @type {?number} */
    this.share_price = null
    /** @type {?string} */
    this.description = null
    /** @type {SecurityStatsCollection} */
    this.stats = SecurityStatsCollection.createFromObject({})
    /** @type {SecurityHistoryCollection} */
    this.history = SecurityHistoryCollection.createFromObject({})
    /** @type {SecurityTotalReturnsCollection} */
    this.totalReturns = SecurityTotalReturnsCollection.createFromObject({})
    /** @type {SecurityDocumentList} */
    this.documents = new SecurityDocumentList()
    /** @type {?boolean} */
    this.is_visible_in_universe = true
    /** @type {?boolean} */
    this.is_slow_to_settle = false
    /** @type {?boolean} */
    this.is_trading_available = true
    /** @type {?'ACCUMULATING'|?'DISTRIBUTING'} */
    this.dividends_type = null
    /** @type {?boolean} */
    this.is_esg = false
    /** @type {?boolean} */
    this.is_hedged = false
    /** @type {?string} */
    this.ter = null
    /** @type {?string} */
    this.price_dt = null
    /** @type {?string} */
    this.provider_filter_name = null
    /** @type {?number} */
    this.estimated_yield = null
    /** @type {?boolean} */
    this.is_featured = false
    /** @type {?string} */
    this.dateFrom = null
    /** @type {Array} */
    this.collections = []
    /** @type {SecurityProperties} */
    this.properties = new SecurityProperties()
    /** @type {?string} */
    this.isin = null
  }

  /** @override */
  getRules() {
    return {
      id: [[rules.int]],
      logo_uri: [[rules.uri]],
      title: [[rules.string]],
      ticker: [[rules.string]],
      short_description: [[rules.string]],
      type: [[rules.string]],
      share_price: [[rules.float]],
      description: [[rules.string]],
      stats: [[(stats) => SecurityStatsCollection.createFromObject(stats)]],
      history: [[(history) => SecurityHistoryCollection.createFromObject(history)]],
      totalReturns: [[(totalReturns) => SecurityTotalReturnsCollection.createFromObject(totalReturns)]],
      documents: [[(documents) => new SecurityDocumentList(...(documents || []))]],
      is_visible_in_universe: [[rules.bool]],
      is_slow_to_settle: [[rules.bool]],
      is_trading_available: [[rules.bool]],
      dividends_type: [[rules.string]],
      is_esg: [[rules.bool]],
      is_hedged: [[rules.bool]],
      ter: [[(decimal) => (decimal ? formatPercent(parseFloat(decimal) / 100, 2, false, true) : null)]],
      provider_filter_name: [[rules.string]],
      yield: [[rules.float]],
      dateFrom: [[rules.string]],
      collections: [[(collections) => collections]],
      is_featured: [[rules.bool]],
      properties: [[(properties) => new SecurityProperties(properties)]],
      isin: [[rules.string]],
    }
  }

  /**
   * @return {{short_description: ?string, ticker: ?string, documents: SecurityDocumentList, logo_uri: ?string, id: ?number, title: ?string}}
   */
  getShortDetails() {
    return {
      id: this.id,
      logo_uri: this.logo_uri,
      title: this.title,
      ticker: this.ticker,
      short_description: this.short_description,
      documents: this.documents,
    }
  }

  /**
   * @param {Security} securityToMerge
   * @return {Security}
   */
  merge(securityToMerge) {
    const merged = { ...this }

    Object.entries(securityToMerge).forEach(([key, value]) => {
      if (value === null) {
        return
      }

      if (typeof value === 'object') {
        if (key === 'stats' && value.max.return_money) {
          return (merged[key] = value)
        }

        if (key === 'history' && value.daily.length > 0) {
          return (merged[key] = value)
        }

        if (key === 'documents' && value.length > 0) {
          return (merged[key] = value)
        }

        return
      }

      merged[key] = value
    })

    return Security.createFromObject(merged)
  }
}
