import React from 'react'

import { useSelector } from 'hooks'

import { additionalFieldsToValidation, backendErrorsToObj, bulkValidate, combineErrors } from 'helpers/validation.js'

import Form, { Fieldset } from 'components/_old/Form/Form.jsx'
import Width from 'components/_old/Width/Width'

const LegalForm = ({
  onSubmit: handleSubmit,
  submitButton: submitButtonTemplate,
  additionalFields,
  wrapButton,
  width,
}) => {
  const client = useSelector((state) => state.client)

  let submitButton = React.cloneElement(
    submitButtonTemplate,
    {
      'data-test-id': 'legalFormSubmit',
    },
    submitButtonTemplate.props.children || 'Continue',
  )

  const validation = combineErrors(additionalFieldsToValidation(additionalFields), backendErrorsToObj(client?.error))

  const postFields = (() => {
    if (!additionalFields || !additionalFields.post) {
      return null
    } else {
      return Object.keys(additionalFields.post).map((field) => {
        return additionalFields.post[field].getField(validation)
      })
    }
  })()

  const isSubmitDisabled = !bulkValidate(validation)

  submitButton = React.cloneElement(
    submitButton,
    {
      onClick: handleSubmit,
      disabled: isSubmitDisabled,
      tabIndex: 8,
    },
    submitButton.props.children || 'Continue',
  )

  submitButton = wrapButton(submitButton)

  return (
    <Form>
      {postFields ? <Fieldset>{postFields}</Fieldset> : null}
      <Width size={width}>{submitButton}</Width>
    </Form>
  )
}

export default LegalForm
