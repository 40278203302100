import { useUnit } from 'effector-react'

import { useCallback, useEffect, useLoading, useMemo, useState } from 'hooks'

import { fetchBankAccountsFx, $bankAccountsStore } from 'app/effector/bank-accounts'
import { getInfoDataRequest } from 'app/effector/bank-accounts/api'

import { ApiError } from 'app/redux/models/errors'

import { useRequestDataWithInterval } from 'app/pages/Dashboard/Goals/hooks'

import { requestStates } from '../requestStates.js'

import { bankAccountStates } from 'constants/bankAccounts.js'

const useNewBank = ({ id }) => {
  const { isLoading: isBankAccountsLoading, wait: waitForBankAccounts } = useLoading()

  const [state, setState] = useState(null)

  const { bankAccounts } = useUnit($bankAccountsStore)

  const fallbackToError = useCallback(() => {
    setState(requestStates.ERROR)
  }, [setState])

  const handleRequestStatus = useCallback(async () => {
    if (!id) {
      return
    }
    const infoDataRequest = await getInfoDataRequest(id)

    if (infoDataRequest instanceof ApiError) {
      throw infoDataRequest
    }
    const { state } = infoDataRequest

    return () => {
      setState(state)
    }
  }, [id])

  useEffect(() => {
    if ([requestStates.SUCCESS, requestStates.ERROR].includes(state)) {
      waitForBankAccounts(fetchBankAccountsFx())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useRequestDataWithInterval(
    handleRequestStatus,
    fallbackToError,
    [requestStates.SUCCESS, requestStates.ERROR].includes(state),
    60 * 1000,
  )

  const awaitingAccounts = useMemo(() => {
    if (bankAccounts.length) {
      return bankAccounts.filter(
        (bankAccount) =>
          bankAccount.state === bankAccountStates.AWAITING_APPROVAL && !bankAccount.bank_statement_filled,
      )
    }
    return []
  }, [bankAccounts])

  return { state, awaitingAccounts, isBankAccountsLoading }
}

export { useNewBank }
