import React, { Fragment } from 'react'

import { useMediaQueries, useMemo, useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Progress } from 'components/_old/Progress'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { DesktopLayout, MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'
import FeeInfo from 'app/containers/Questionnaire/FeeInfo/FeeInfo.jsx'

import disclaimer from './disclaimer.png'
import disclaimer2x from './disclaimer@2x.png'

type DisclaimerProps = {
  progress: number
  activeStep: {
    headline?: string
    feeInfo?: boolean
  }
  location: {
    search?: string
  }
  params: Record<string, unknown>
  withDesktopLayout: boolean
  redirectToNextStep?: (customParams?: Record<string, string>) => void
  backLinkModule?: string
}

function Disclaimer({
  progress,
  activeStep,
  location,
  params,
  withDesktopLayout = true,
  redirectToNextStep,
  backLinkModule = 'create-new-portfolio',
}: DisclaimerProps): React.ReactElement {
  const backLink = useMemo(
    () => urlTo(backLinkModule, params, location.search ?? null),
    [backLinkModule, params, location.search],
  )

  const handleBack = useCallback(() => {
    goTo(backLink)
  }, [backLink])

  const handleCancel = useCallback(() => {
    goTo(urlTo('dashboard'))
  }, [])

  const { desktop } = useMediaQueries()

  const header = useMemo(() => {
    if (!activeStep.headline) {
      return undefined
    }

    if (desktop) {
      return (
        <Header
          right={
            <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}>
              <Link to={urlTo('dashboard')}>My Dashboard</Link>
            </Button>
          }
          after={
            <Fragment>
              <Progress fill={progress} gapWidth="1em" />
              {activeStep.feeInfo && <FeeInfo />}
            </Fragment>
          }
          hideDrawer
        />
      )
    }

    return (
      <Fragment>
        <NavigationBar
          leftPartText={backLink ? 'Back' : undefined}
          onLeftPartClick={backLink ? handleBack : undefined}
          rightPartText="Cancel"
          onRightPartClick={handleCancel}
        >
          Get started
        </NavigationBar>
        <Progress fill={progress} gapWidth="1em" />
      </Fragment>
    )
  }, [activeStep.headline, activeStep.feeInfo, desktop, backLink, handleBack, handleCancel, progress])

  const heading = useMemo(
    () =>
      activeStep.headline && (
        <Paper top={64}>
          <NavigationBar
            leftPartText={backLink ? 'Back' : undefined}
            onLeftPartClick={backLink ? handleBack : undefined}
          >
            <span data-test-id="headline">
              <Typo>{activeStep.headline}</Typo>
            </span>
          </NavigationBar>
        </Paper>
      ),
    [activeStep.headline, backLink, handleBack],
  )

  const button = useMemo(() => {
    if (redirectToNextStep) {
      return (
        <Button
          type="submit"
          mods={{ size: 'big block' }}
          onClick={redirectToNextStep}
          data-test-id="questionnaireSubmit"
        >
          Continue
        </Button>
      )
    }

    return null
  }, [redirectToNextStep])

  const content = useMemo(() => {
    const content: JSX.Element[] = []

    if (desktop && heading) {
      content.push(heading)
    }

    if (!desktop) {
      content.push(
        <Paper top={8} bottom={32}>
          <Typography size={20} weight="semibold" align="center">
            <Typo>{activeStep.headline}</Typo>
          </Typography>
        </Paper>,
      )
    }

    content.push(
      <Width size={48} center>
        <Paper top={desktop ? 24 : null}>
          <Typography size={desktop ? 18 : 16} align="center" textWrap="pretty">
            <Typo>
              We'll ask you a few simple questions about your financial situation in order to find you a portfolio that
              best suits your investing style and appetite for risk.
            </Typo>
          </Typography>
        </Paper>
        <Paper top={desktop ? 16 : 8}>
          <Typography size={desktop ? 18 : 16} align="center" textWrap="pretty">
            <Typo>
              Our portfolio questionnaire is free for you to try and all information is safely secured. Please aim to
              provide us with accurate information so we can act in your best interests.
            </Typo>
          </Typography>
        </Paper>
      </Width>,
    )

    content.push(
      <Width size={desktop ? 14 : 11.25} center>
        <Paper top={desktop ? 40 : 32}>
          <img
            src={disclaimer}
            srcSet={`${disclaimer} 1x, ${disclaimer2x} 2x`}
            width={desktop ? 224 : 180}
            height={desktop ? 200 : 161}
            alt=""
          />
        </Paper>
      </Width>,
    )

    if (desktop) {
      content.push(
        <Width size={14} center>
          <Paper top={40}>{button}</Paper>
        </Width>,
      )
    }

    content.push(
      <Width size={48} center>
        <Paper top={desktop ? 40 : 32} bottom={desktop ? 40 : 32}>
          <Typography size={desktop ? 18 : 16} align="center" textWrap="pretty">
            <Typo>
              The product or service offers no guarantee of return or performance and presents a risk of capital loss.
              The value of your portfolio with InvestEngine can go down as well as up and you may get back less than you
              invest.
            </Typo>
          </Typography>
        </Paper>
      </Width>,
    )

    if (desktop) {
      return <Inner>{content}</Inner>
    }

    return <Fragment>{content}</Fragment>
  }, [desktop, heading, activeStep.headline, button])

  if (desktop) {
    if (withDesktopLayout) {
      return <DesktopLayout header={header} content={content} footer={<DesktopFooter />} noGaps />
    }

    return (
      <Fragment>
        {header}
        {content}
        <DesktopFooter />
      </Fragment>
    )
  }

  return (
    <MobileLayout
      header={header}
      content={content}
      footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
    />
  )
}

export { Disclaimer, type DisclaimerProps }
