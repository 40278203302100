import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

import { type CollectionsGroup } from '../types'

const fetchEtfsCollections = async (): Promise<CollectionsGroup[] | ApiError> => {
  try {
    const { data } = await axios.get('securities/collections/')

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { fetchEtfsCollections }
