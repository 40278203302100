import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import Link from 'components/_old/Link/Link.jsx'
import Button from 'components/_old/Button/Button.jsx'

const Confirm = ({ handleConfirm }) => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <Paper bottom={desktop ? 24 : 20}>
        <Typography size={12} lineHeight="small" align="center" data-test-id="confirmOrderLinks">
          <Link to="/terms/" hard blank>
            Terms and conditions
          </Link>{' '}
          and{' '}
          <Link to="/terms/#order-execution-policy" hard blank>
            {' '}
            Order Execution Policy
          </Link>{' '}
          apply.
        </Typography>
      </Paper>
      <Button mods={{ size: 'big block' }} onClick={handleConfirm} data-test-id="confirmOrderButton">
        Confirm
      </Button>
    </Fragment>
  )
}

Confirm.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
}

export { Confirm }
