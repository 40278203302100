/* eslint-disable react/prop-types */

import React from 'react'

import isFunction from 'lodash/isFunction'

import { useState, useCallback, useEffect, useImperativeHandle } from 'hooks'

import { validate, getBrokenRule } from 'helpers/validation.js'

type ValidateChildrenFunc = (
  isValid: boolean,
  brokenRule: number,
  onFocus: (childOnFocus?: () => void) => void,
  onBlur: (childOnBlur?: () => void) => void,
) => React.ReactElement

type ValidateChildrenElement = React.ReactNode

type ValidateUnionProps = {
  children: ValidateChildrenElement | ValidateChildrenFunc
  value?: unknown
  disabled?: boolean
  skipWaitBlur?: boolean
  onBlur?: () => void
}

type ValidateProps = XOR<ValidateUnionProps & { rules: boolean[] }, ValidateUnionProps & { rule: boolean }>

type ValidateHandle = {
  reset: () => void
  getBlurred: () => boolean
  setBlurred: (blurred: boolean) => void
}

const Validate = React.forwardRef<ValidateHandle, ValidateProps>(
  ({ children, value, disabled, rule, rules = [], skipWaitBlur, onBlur }: ValidateProps, ref): React.ReactElement => {
    const [blurred, setBlurred] = useState(false)
    const forceValid = !skipWaitBlur && !blurred
    const valid = validate(rules, forceValid)
    const brokenRule = getBrokenRule(rules)

    if (typeof rule !== 'undefined') {
      rules.push(rule)
    }

    const handleOnFocus = useCallback(() => {
      if (isFunction(children?.props?.onFocus)) {
        children.props.onFocus()
      }
    }, [children?.props])

    const handleOnBlur = useCallback(() => {
      setBlurred(true)

      if (isFunction(onBlur)) {
        onBlur()
      }

      if (isFunction(children?.props?.onBlur)) {
        children.props.onBlur()
      }
    }, [onBlur, children?.props])

    const reset = useCallback(() => {
      setBlurred(false)
    }, [])

    const getBlurred = useCallback(() => blurred, [blurred])

    useEffect(() => {
      const isValid = validate(rules, false)

      if (!isValid && value) {
        setBlurred(true)
      }
    }, [rules, value])

    useImperativeHandle(ref, () => ({
      reset,
      getBlurred,
      setBlurred,
    }))

    if (typeof children === 'function') {
      children = children(valid, brokenRule, handleOnFocus, handleOnBlur)
    }

    return React.cloneElement(children, {
      valid,
      brokenRule,
      onFocus: handleOnFocus,
      onBlur: handleOnBlur,
      disabled,
    })
  },
)
Validate.displayName = 'Validate'

export { Validate, type ValidateProps, type ValidateHandle }
