import { useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

const useNavbar = ({ portfolioId }) => {
  const handleBack = useCallback(
    ({ previous: module, securityId } = {}) => {
      goTo(urlTo(`dashboard.portfolio.${module}`, { id: portfolioId, securityId }))
    },
    [portfolioId],
  )
  const redirectToPortfolio = useCallback(() => {
    goTo(urlTo('dashboard.portfolio', { id: portfolioId }))
  }, [portfolioId])

  const redirectToPortfolioWithAfterSellModal = useCallback(() => {
    goTo(urlTo('dashboard.portfolio', { id: portfolioId }, '?autoinvestAfterSell'))
  }, [portfolioId])

  return {
    handleBack,
    redirectToPortfolio,
    redirectToPortfolioWithAfterSellModal,
  }
}

export { useNavbar }
