import { createEffect, createStore } from 'effector'

import { makeApiCall } from 'app/effector/api'

import * as api from './api'
import { IsaProviderList } from './models'
import { TransferProgressCollection } from './models/TransferProgress/TransferProgressCollection'

// Effects
const fetchIsaProvidersFx = createEffect(async () => await makeApiCall(api.fetchIsaProviders))

const fetchTransferProgressesFx = createEffect(async ({ portfolioId }) => {
  try {
    return await makeApiCall(api.fetchTransferProgresses, { portfolioId })
  } catch (error) {}
})
const dismissTransferProgressPanelFx = createEffect(async ({ portfolioId, transferId }) => {
  try {
    await makeApiCall(api.dismissTransferProgressPanel, transferId)

    return { portfolioId, transferId }
  } catch (error) {}
})

// Stores
const $isaTransferProviderList = createStore<IsaProviderList>(new IsaProviderList())
$isaTransferProviderList.on(fetchIsaProvidersFx.doneData, (_, providers) => new IsaProviderList(...providers))

const $transferProgressCollection = createStore(new TransferProgressCollection())
$transferProgressCollection.on(fetchTransferProgressesFx.done, (state, { params, result }) =>
  params ? state.set(params.portfolioId, result) : state,
)
$transferProgressCollection.on(dismissTransferProgressPanelFx.done, (state, { params }) =>
  state.delete(params.portfolioId, params.transferId),
)

export {
  // Effects
  fetchIsaProvidersFx,
  fetchTransferProgressesFx,
  dismissTransferProgressPanelFx,
  // Store
  $transferProgressCollection,
  $isaTransferProviderList,
}
