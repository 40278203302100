import { useUnit } from 'effector-react'

import { useSelector } from 'hooks'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import type { BankAccount } from 'app/effector/bank-accounts/models/bank'
import { $owner } from 'app/effector/contacts'
import { $recurringPaymentsStore, type DirectDebitSubscription } from 'app/effector/recurringPayments'

type UsePaymentDetailsInterface = {
  accountHolder: string
  accountNumber: string
  nominatedAccount: BankAccount
  sortCode: string
  monthlyPaymentAmount: string
  monthlyPaymentDay: string
  confirmCheckbox: boolean
  directDebitSubscription?: DirectDebitSubscription
}

const usePaymentDetails = (portfolioId: number): UsePaymentDetailsInterface => {
  const owner = useUnit($owner) ?? {}
  const accountHolder = `${owner.first_name as string} ${owner.last_name as string}`

  const { nominatedAccount } = useUnit($bankAccountsStore)
  const accountNumber = nominatedAccount?.account_number
  const sortCode = nominatedAccount?.sort_code

  const { monthlyPaymentAmount, monthlyPaymentDay, confirmCheckbox } = useSelector(
    (state) => state.ui.modals.directDebit,
  )

  const { getDirectDebitByPortfolioId } = useUnit($recurringPaymentsStore)

  const directDebitSubscription = getDirectDebitByPortfolioId(portfolioId)

  return {
    accountHolder,
    accountNumber,
    nominatedAccount,
    sortCode,
    monthlyPaymentAmount,
    monthlyPaymentDay,
    confirmCheckbox,
    directDebitSubscription,
  }
}

export { usePaymentDetails, type UsePaymentDetailsInterface }
