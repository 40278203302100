import { formatPercent } from 'helpers/money'

import { types as clientTypes } from 'constants/client'
import {
  kinds as goalKinds,
  portfolioManageTypes,
  presetTypes as goalPresetTypes,
  regulatoryTypes as goalRegulatoryTypes,
  states as goalStates,
} from 'constants/goal'

export const getKindByGoal = (client, goal) => {
  const isClientIndividual = client.type === clientTypes.INDIVIDUAL
  const isClientBusiness = client.type === clientTypes.BUSINESS

  const isGIA = goal?.regulatory_type === goalRegulatoryTypes.GIA
  const isISA = goal?.regulatory_type === goalRegulatoryTypes.ISA
  const isSIPP = goal?.regulatory_type === goalRegulatoryTypes.SIPP

  const isIncome = goal?.preset_type === goalPresetTypes.INCOME
  const isGrowth = [goalPresetTypes.GROWTH, goalPresetTypes.SELF_SELECTED].includes(goal?.preset_type)

  const isDIY = goal?.manage_type === portfolioManageTypes.DIY
  const isCash = goal?.manage_type === portfolioManageTypes.CASH

  if (isCash) {
    if (isClientBusiness) {
      return null
    }
    if (isISA) {
      return goalKinds.isaDiy
    }
    if (isSIPP) {
      return goalKinds.sipp
    }
    return goalKinds.personal
  }

  if (isClientBusiness && isGIA) {
    if (isGrowth) {
      return goalKinds.business
    }
    if (isIncome) {
      return goalKinds.businessIncome
    }
  }

  if (isClientIndividual && isGIA) {
    if (isGrowth) {
      return goalKinds.personal
    }
    if (isIncome) {
      return goalKinds.personalIncome
    }
  }

  if (isClientIndividual && isISA) {
    if (isGrowth) {
      return goalKinds.isa
    }
    if (isIncome) {
      return goalKinds.isaIncome
    }
  }

  if (isDIY) {
    if (isISA) {
      return goalKinds.isaDiy
    }
    return goalKinds.diy
  }

  if (isSIPP) {
    return goalKinds.sipp
  }

  return null
}

export const getKindName = (kind) => {
  if ([goalKinds.personal, goalKinds.personalIncome].includes(kind)) {
    return 'General'
  }
  if ([goalKinds.business, goalKinds.businessIncome].includes(kind)) {
    return 'Business'
  }
  if ([goalKinds.isa, goalKinds.isaIncome, goalKinds.isaDiy].includes(kind)) {
    return 'ISA'
  }
  if ([goalKinds.sipp].includes(kind)) {
    return 'Personal Pension'
  }

  return ''
}

export const getActiveGoals = (goals = []) => goals.filter((goal) => goal.state !== goalStates.NEW)

export const getPresetAssetsPercent = (assets) => {
  const amount =
    assets?.length > 1
      ? assets.reduce((memo, item) =>
          typeof memo === 'number'
            ? (memo || 0) + (item ? item.amount : 0)
            : (memo ? memo.amount : 0) + (item ? item.amount : 0),
        )
      : assets?.[0]?.amount || 0

  return formatPercent(amount / 100, true)
}
