import React, { useMemo } from 'react'

import { palette, isDarkTheme } from 'helpers/palette/'

import { Typo } from 'components/_old/Typo/Typo'

import { CardNew } from 'components/atoms/CardNew'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './Banner.css'

enum Theme {
  Background = 'background',
  Primary = 'primary',
  Accent = 'accent',
  Outstanding = 'outstanding',
}

type BannerProps = {
  theme?: Theme
  title: string
  shortDescription: string | null
  icon?: React.ReactElement
  topRightContent?: string
  rounding?: number
  backgroundImage?: string
  backgroundColor?: string
}

const backgroundColors = {
  [Theme.Background]: palette['background-card'],
  [Theme.Primary]: palette['primary-default'],
  [Theme.Accent]: palette['accent-default'],
  [Theme.Outstanding]: palette['outstanding-default'],
}

const textColors = {
  [Theme.Background]: 'on_background_primary',
  [Theme.Primary]: 'on_color_white',
  [Theme.Accent]: 'on_color_white',
  [Theme.Outstanding]: 'on_color_black',
}

const Banner = ({
  theme = Theme.Background,
  shortDescription,
  title = '',
  icon,
  topRightContent,
  rounding,
  backgroundImage,
  backgroundColor,
}: BannerProps): React.ReactElement => {
  const isDark = isDarkTheme()

  const hoverColors = useMemo(
    () => ({
      [Theme.Background]: isDark ? palette['background-overlay-opaque'] : palette['background-card'],
      [Theme.Primary]: palette['primary-action'],
      [Theme.Accent]: palette['accent-action'],
      [Theme.Outstanding]: palette['outstanding-action'],
    }),
    [isDark],
  )

  const cardProps = {
    borderRadius: rounding,
    backgroundImage,
    backgroundColor: backgroundColor ?? backgroundColors[theme],
    hoverColor: hoverColors[theme],
    hoverOverlay: !!backgroundColor,
  }

  return (
    <CardNew {...cardProps} className="Banner-Wrapper" data-test-id="collectionCard">
      <div className="Banner">
        <div className="Banner-Header">
          {icon}
          {topRightContent && (
            <Typography size={12} color={textColors[theme]}>
              {topRightContent}
            </Typography>
          )}
        </div>
        <div>
          <Typography
            size={18}
            lineHeight="small"
            weight="semibold"
            className="Banner-Title Banner-Text"
            color={textColors[theme]}
            style={{ '--banner-text-max-lines': 3 }}
          >
            <Typo>{title}</Typo>
          </Typography>
          {shortDescription && (
            <Paper top={4}>
              <Typography
                size={12}
                lineHeight="small"
                className="Banner-Description Banner-Text"
                color={textColors[theme]}
                style={{ '--banner-text-max-lines': 3 }}
              >
                <Typo>{shortDescription}</Typo>
              </Typography>
            </Paper>
          )}
        </div>
      </div>
    </CardNew>
  )
}

export { Banner, Theme }
