import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useMemo, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'
import { format as formatMoney } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router.js'
import { backendErrorsToObj, combineErrors } from 'helpers/validation.js'

import { $owner } from 'app/effector/contacts'
import { $dictsStore } from 'app/effector/dicts'

import {
  changeField as changeGoalFieldActionCreator,
  resetError as resetGoalErrorActionCreator,
  setValid as setValidGoalActionCreator,
  updateOrCreate as updateOrCreateGoalActionCreator,
} from 'app/redux/actions/portfolios'
import {
  resetError as resetQuestionnaireErrorActionCreator,
  saveClientAnswer as saveQuestionnaireClientAnswerActionCreator,
  setClientAnswer as setQuestionnaireClientAnswerActionCreator,
  setValid as setValidQuestionnaireActionCreator,
} from 'app/redux/actions/questionnaire'
import { getClientAnswerValues } from 'app/redux/selectors'

import { questionCodes } from 'constants/questionnaire.js'
import { MAX_SINGLE_PAYMENT_AMOUNT } from 'constants/validations'

const useGetStarted = ({ selectedGoalId, redirectToPrevStep, redirectToNextStep, isFirstStep, skipAge }) => {
  const goals = useSelector((state) => state.portfolios.items)
  const owner = useUnit($owner) ?? {}
  const questionnaire = useSelector((state) => state.questionnaire)
  const clientAnswerValues = useSelector(getClientAnswerValues)
  const { isRefferalEnabled, initialDepositMin } = useUnit($dictsStore)

  const goal = useMemo(() => {
    if (selectedGoalId) {
      return goals.find((goal) => goal.id === parseInt(selectedGoalId, 10)) || {}
    }

    const firstGoal = goals[0]

    if (firstGoal) {
      return firstGoal
    }

    return {}
  }, [selectedGoalId, goals])

  const [
    changeGoalField,
    updateOrCreateGoal,
    resetGoalError,
    setValidGoal,
    setQuestionnaireClientAnswer,
    saveQuestionnaireClientAnswer,
    resetQuestionnaireError,
    setValidQuestionnaire,
  ] = useActions([
    changeGoalFieldActionCreator,
    updateOrCreateGoalActionCreator,
    resetGoalErrorActionCreator,
    setValidGoalActionCreator,
    setQuestionnaireClientAnswerActionCreator,
    saveQuestionnaireClientAnswerActionCreator,
    resetQuestionnaireErrorActionCreator,
    setValidQuestionnaireActionCreator,
  ])

  const yearsOld = clientAnswerValues[questionCodes.YEARS_OLD]
  const { error } = questionnaire
  const { initial_deposit: initialDeposit } = goal
  const referralSuffix = isRefferalEnabled ? ' (in addition to any incentives)' : ''
  const initialDepositValidation = {
    rules: [initialDeposit >= initialDepositMin, initialDeposit <= MAX_SINGLE_PAYMENT_AMOUNT],
    errors: [
      `Initial investment must be at least ${formatMoney(initialDepositMin)}${referralSuffix}`,
      `Maximum amount is ${formatMoney(MAX_SINGLE_PAYMENT_AMOUNT)}`,
    ],
  }

  const validation = (() => {
    if (skipAge || owner.age) {
      return combineErrors(
        {
          initial_deposit: initialDepositValidation,
        },
        backendErrorsToObj(error),
      )
    }

    return combineErrors(
      {
        years_old: {
          rules: [yearsOld >= 18, yearsOld <= 99],
          errors: ['You must be at least 18 years old', 'You must be no more than 99 years old'],
        },
        initial_deposit: initialDepositValidation,
      },
      backendErrorsToObj(error),
    )
  })()

  const handleAgeChange = useCallback(
    (event, value = event.target.value) => {
      setQuestionnaireClientAnswer(questionCodes.YEARS_OLD, { value: value ? parseFloat(value) : 0 })
    },
    [setQuestionnaireClientAnswer],
  )

  const handleInitialDepositChange = useCallback(
    (event, value = event.target.value) => {
      changeGoalField({ initial_deposit: value }, goal.id)
    },
    [changeGoalField, goal.id],
  )

  useEffect(() => {
    trackEvent({
      action: 'questionnaire_page_visit',
      step: 0,
      step_description: 'initial_question',
      regulatory_type: goal?.regulatory_type,
      version: 'default',
    })
  }, [goal?.regulatory_type])

  useEffect(() => {
    if (!skipAge && owner.age && !yearsOld) {
      handleAgeChange(null, owner.age)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipAge, owner, yearsOld])

  const handleGoNext = useCallback(async () => {
    const goalId = goal.id

    trackEvent({
      category: 'Create new portfolio',
      action: 'Questionnaire step completed',
      label: 'Get started',
      portfolioRegulatoryType: goal.regulatory_type,
      portfolioPresetType: goal.preset_type,
    })

    trackEvent({
      action: 'questionnaire_step_completed',
      text: `${yearsOld}; ${goal.initial_deposit}`,
      step: 0,
      step_description: 'initial_question',
      regulatory_type: goal?.regulatory_type,
      version: 'default',
    })

    try {
      if (!skipAge) {
        const stateAfterSaveAnswer = await saveQuestionnaireClientAnswer(
          questionCodes.YEARS_OLD,
          { goal_id: goalId },
          false,
        )

        if (stateAfterSaveAnswer.questionnaire.error) {
          throw stateAfterSaveAnswer.questionnaire.error
        }
      }

      const stateAfterPatchGoal = await updateOrCreateGoal(['initial_deposit'], goal, true, goalId, false)

      if (stateAfterPatchGoal.portfolios.error) {
        throw stateAfterPatchGoal.portfolios.error
      }

      if (!skipAge) {
        setValidQuestionnaire()
      }

      setValidGoal()
      redirectToNextStep({ id: goal.id })
    } catch (error) {
      processError({
        error,
        resets: [resetQuestionnaireError, resetGoalError],
      })

      setValidQuestionnaire()
      setValidGoal()
    }
  }, [
    goal,
    yearsOld,
    skipAge,
    updateOrCreateGoal,
    setValidQuestionnaire,
    setValidGoal,
    redirectToNextStep,
    saveQuestionnaireClientAnswer,
    resetQuestionnaireError,
    resetGoalError,
  ])

  const handleBack = useCallback(() => {
    if (!isFirstStep) {
      return redirectToPrevStep()
    }

    goTo(urlTo('dashboard'))
  }, [isFirstStep, redirectToPrevStep])

  const handleCancel = useCallback(() => {
    goTo(urlTo('dashboard'))
  }, [])

  return {
    goal,
    owner,
    yearsOld,
    validation,
    handleAgeChange,
    handleInitialDepositChange,
    handleGoNext,
    handleBack,
    handleCancel,
  }
}

export { useGetStarted }
