import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import Button from 'components/_old/Button/Button.jsx'
import Width from 'components/_old/Width/Width'
import Icon from 'components/_old/Icon/Icon.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'

const TradingWindowWarningModal = ({ open = false, cancellationErrorText, handleClose }) => {
  const { desktop } = useMediaQueries()

  const icon = useMemo(
    () => (
      <AllCenter>
        <Icon type="exclamation-triangle" color={palette['status-warning']} size={54} />
      </AllCenter>
    ),
    [],
  )

  const title = useMemo(
    () => (
      <Paper top={desktop ? 32 : 20}>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight={desktop ? 'small' : 'medium'} align="center">
          <Typo>Sorry — daily trading has already started</Typo>
        </Typography>
      </Paper>
    ),
    [desktop],
  )

  const button = useMemo(
    () => (
      <Button onClick={handleClose} mods={{ size: 'big block' }} data-test-id="closeButton">
        Close
      </Button>
    ),
    [handleClose],
  )

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose} data-test-id="tradingWindowModal">
        <ModalContent width={60}>
          <Paper top={80} bottom={80} left={72} right={72}>
            {icon}
            {title}
            <Paper top={32}>
              <Typography size={14} align="center">
                <Typo>{cancellationErrorText ?? 'Orders cannot be cancelled between 2pm and 7pm daily.'}</Typo>
              </Typography>
            </Paper>
            <Paper top={56}>
              <Width size={16} center>
                {button}
              </Width>
            </Paper>
          </Paper>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContent width={60}>
        <MobileLayout
          content={
            <AllCenter>
              <Paper>
                {icon}
                {title}
                <Paper top={80}>
                  <Typography size={14} align="center">
                    {cancellationErrorText ?? (
                      <Typo>
                        Orders cannot be cancelled between
                        <br />
                        2pm and 7pm daily.
                      </Typo>
                    )}
                  </Typography>
                </Paper>
              </Paper>
            </AllCenter>
          }
          footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
        />
      </ModalContent>
    </Modal>
  )
}

TradingWindowWarningModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
}

export { TradingWindowWarningModal }
