import { useUnit } from 'effector-react'

import { useState, useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { $portfolioSecuritiesStore, resetSecurities } from 'app/effector/portfolio-securities'

import { states as portfolioStates } from 'constants/portfolio'

const useCancel = ({ portfolio, query }) => {
  const [abandonModalOpen, setAbandonModalOpen] = useState(false)

  const { portfolioSecurities: portfolioSecuritiesCollection } = useUnit($portfolioSecuritiesStore)

  const isPortfolioSecuritiesModified = portfolioSecuritiesCollection?.isModified(portfolio?.id)

  const handleAbandonModalClose = useCallback(() => {
    setAbandonModalOpen(false)
  }, [setAbandonModalOpen])

  const handleRedirect = useCallback(() => {
    if (query.fromUniverse === 'true') {
      setAbandonModalOpen(false)
      goTo(urlTo('securities'))
      return
    }

    const isNewPortfolioState = portfolio?.state === portfolioStates.NEW

    if (isNewPortfolioState || !portfolio) {
      goTo(urlTo('dashboard'))
      return
    }

    goTo(urlTo('dashboard.portfolio', { id: portfolio?.id }))
  }, [portfolio, query?.fromUniverse])

  const handleCancel = useCallback(() => {
    if (abandonModalOpen || !isPortfolioSecuritiesModified) {
      handleRedirect()

      if (portfolio?.id) {
        resetSecurities({ portfolioId: portfolio?.id })
      }

      return
    }

    setAbandonModalOpen(true)
  }, [portfolio?.id, abandonModalOpen, isPortfolioSecuritiesModified, setAbandonModalOpen, handleRedirect])

  return {
    handleCancel,
    abandonModalOpen,
    handleAbandonModalClose,
  }
}

export { useCancel }
