import React from 'react'

import { AboutBusinessSetup } from './AboutBusinessSetup/AboutBusinessSetup.jsx'
import { PersonalProfileSetup } from './PersonalProfileSetup/PersonalProfileSetup.jsx'
import { ResidentialAddressSetup } from './ResidentialAddressSetup/ResidentialAddressSetup.jsx'
import { ProofOfIdentitySetup } from './ProofOfIdentitySetup/ProofOfIdentitySetup.jsx'
import { DirectorsAndShareholdersSetup } from './DirectorsAndShareholdersSetup/DirectorsAndShareholdersSetup.jsx'
import { AddingDirectorSetup } from './DirectorsAndShareholdersSetup/AddingDirectorSetup.jsx'
import { AddingSignificantControlSetup } from './DirectorsAndShareholdersSetup/AddingSignificantControlSetup.jsx'
import { BankAccountSetup } from './BankAccountSetup/BankAccountSetup.jsx'
import { TermsAndConditionsSetup } from './TermsAndConditionsSetup/TermsAndConditionsSetup.jsx'
import { Business } from './Business.jsx'

const childRoutes = [
  {
    module: 'about-business',
    headline: 'About your business',
    component: function Component(props) {
      return <AboutBusinessSetup {...props} />
    },
  },
  {
    module: 'personal-profile',
    path: 'personal-profile/',
    headline: 'Personal profile for the main applicant',
    component: function Component(props) {
      return <PersonalProfileSetup {...props} />
    },
  },
  {
    module: 'residential-address',
    path: 'residential-address/',
    headline: 'Residential address for the main applicant',
    component: function Component(props) {
      return <ResidentialAddressSetup {...props} />
    },
  },
  {
    module: 'main-applicant-proof-of-identity',
    path: 'main-applicant-proof-of-identity/(:contactId)',
    headline: 'Proof of identity',
    customHeadline: true,
    component: function Component(props) {
      return <ProofOfIdentitySetup {...props} clientType="SME" />
    },
  },
  {
    module: 'directors-and-shareholders',
    path: 'directors-and-shareholders/',
    headline: 'List of directors and significant shareholders',
    component: function Component(props) {
      return <DirectorsAndShareholdersSetup {...props} />
    },
  },
  {
    module: 'adding-director',
    headline: 'Adding a director',
    notImpactToProgressBar: true,
    path: 'adding-director/(:contactId)',
    component: function Component(props) {
      return <AddingDirectorSetup {...props} />
    },
  },
  {
    module: 'director-proof-of-identity',
    headline: 'Proof of identity',
    customHeadline: true,
    notImpactToProgressBar: true,
    path: 'director-proof-of-identity/:contactId/',
    component: function Component(props) {
      return <ProofOfIdentitySetup {...props} clientType="SME" />
    },
  },
  {
    module: 'adding-significant-control',
    headline: 'Adding a person or entity with significant control',
    notImpactToProgressBar: true,
    path: 'adding-significant-control/(:significantControlId)',
    component: function Component(props) {
      return <AddingSignificantControlSetup {...props} />
    },
  },
  {
    module: 'bank-account',
    path: 'bank-account/',
    headline: 'Company bank account',
    component: function Component(props) {
      return <BankAccountSetup {...props} />
    },
  },
  {
    module: 'terms-and-conditions',
    path: 'terms-and-conditions/',
    headline: 'Terms and conditions',
    component: function Component(props) {
      return <TermsAndConditionsSetup {...props} />
    },
  },
]

export default {
  module: 'business',
  path: 'business/',
  component: function Component(props) {
    return <Business {...props} />
  },
  childRoutes: childRoutes,
  indexRoute: childRoutes[0],
}
