const regulatoryTypes = {
  GIA: 'GIA',
  ISA: 'ISA',
  SIPP: 'SIPP',
} as const

const regulatoryTypesText = {
  [regulatoryTypes.GIA]: 'General',
  [regulatoryTypes.ISA]: 'ISA',
  [regulatoryTypes.SIPP]: 'Personal Pension',
}

const regulatoryTypesCashText = {
  [regulatoryTypes.GIA]: 'General',
  [regulatoryTypes.ISA]: 'Stocks & Shares ISA',
  [regulatoryTypes.SIPP]: 'Personal Pension',
}

const manageTypes = {
  DIY: 'DIY',
  MANAGED: 'MANAGED',
  CASH: 'CASH',
  SELF_SELECTED: 'SELF_SELECTED',
} as const

const isaTypes = {
  NEW: 'NEW',
  TRANSFER: 'TRANSFER',
}

const presetTypes = {
  GROWTH: 'GROWTH',
  INCOME: 'INCOME',
  SELF_SELECTED: 'SELF_SELECTED',
}

const states = {
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
  APPROVED: 'APPROVED',
  CLOSING: 'CLOSING',
  CLOSED: 'CLOSED',
}

const yearlyReviewTypes = {
  NULL: null,
  NOTIFICATION: 'NOTIFICATION',
  MODAL: 'MODAL',
}

const portfolioColors = {
  DIY: 'secondary',
  MANAGED: 'primary',
  CASH: 'cashcolor',
  SELF_SELECTED: 'selfSelected',
}

export {
  regulatoryTypes,
  regulatoryTypesText,
  regulatoryTypesCashText,
  manageTypes,
  isaTypes,
  presetTypes,
  states,
  yearlyReviewTypes,
  portfolioColors,
}
