import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { NavigationBar } from 'components/atoms/NavigationBar'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Width from 'components/_old/Width/Width'
import Inner from 'components/_old/Inner/Inner.jsx'
import Segment from 'components/atoms/Segment/Segment.jsx'
import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import { BankAccountStateContent } from 'app/pages/Dashboard/Goals/components/BankAccount/BankAccountStateContent.jsx'

import icon from 'components/molecules/Preloader/Preloader.svg'

const Processing = ({ onContinue: handleContinue }) => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      {!desktop && <NavigationBar>Pending</NavigationBar>}
      <AllCenter>
        <Width size={65}>
          <Width size={40} center>
            <Inner>
              <Segment mods={{ margin: 'biggest', noMargin: desktop ? 'bottom' : 'all' }}>
                <Width size={desktop ? 6 : 4} center>
                  <SvgIcon className="Icon_animation_spin" src={icon} />
                </Width>
              </Segment>
              <BankAccountStateContent
                title="We are processing your instruction"
                description="Processing should take no more than 30 seconds. But there's no need for you to wait around — please continue!"
                data-test-id="instantPaymentProcessing"
              >
                <Segment mods={{ margin: 'hefty', noMargin: 'top' }}>
                  <ModalContentButtons width={desktop ? 20 : 24} isNewModal>
                    <Button
                      mods={{ size: 'big block' }}
                      onClick={handleContinue}
                      data-test-id="instantPaymentProcessingButton"
                    >
                      Continue
                    </Button>
                  </ModalContentButtons>
                </Segment>
              </BankAccountStateContent>
            </Inner>
          </Width>
        </Width>
      </AllCenter>
    </Fragment>
  )
}

Processing.propTypes = {
  onContinue: PropTypes.func.isRequired,
}

export { Processing }
