import React from 'react'
import PropTypes from 'prop-types'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'

import { useCallback, useContext, useMediaQueries, useRedirect } from 'hooks'
import { useUnit } from 'effector-react'

import { types as clientTypes } from 'constants/client'

import { StepContext } from 'app/containers/PortfolioTunnel'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

import BankAccountForm from './Form.jsx'

import { showFailToast } from 'app/redux/actions/ui'

import { $isLoading } from 'app/effector/contacts'

const BankAccountSetup = ({ owner, changePaymentField, updatePaymentDetails, handleAfterSubmit }) => {
  const { desktop } = useMediaQueries()
  const { getStepByName } = useContext(StepContext)

  const residentialAddressStep = getStepByName('residential-address')
  const isContactsLoading = useUnit($isLoading)

  useRedirect({
    to: residentialAddressStep.module,
    rule: !owner?.addresses?.hasAddresses(),
    isLoading: isContactsLoading,
  })

  const handleSubmit = useCallback(async ({ nominatedAccount, skipUpdate }) => {
    if (!skipUpdate) {
      const result = await updatePaymentDetails(nominatedAccount)

      if (!result.result) {
        if (result.error === 'Invalid bank account data') {
          return showFailToast('Invalid bank account data')
        }

        return processError({ error: result.error })
      }
    }

    trackEvent({
      action: 'nominated_bank_account_submitted',
      client_type: clientTypes.BUSINESS,
    })

    handleAfterSubmit()
  }, [])

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <Card
        mods={{
          theme: 'transparent',
          'no-padding': 'right bottom left',
        }}
      >
        {submitButton}
      </Card>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  const form = (
    <BankAccountForm
      wrapSubmitButton={wrapSubmitButton}
      onPaymentDataFieldChange={changePaymentField}
      onSubmit={handleSubmit}
    />
  )

  if (desktop) {
    return (
      <AllCenter>
        <Headline className="ListOfDirectorsAndShareholders-SubTitle" level={4}>
          <Typo>Please nominate a bank account to be used for top-ups and withdrawals</Typo>
        </Headline>
        <Card
          mods={{
            theme: 'transparent',
            'no-padding': 'left right',
          }}
        >
          {form}
        </Card>
      </AllCenter>
    )
  }

  return form
}

BankAccountSetup.propTypes = {
  owner: PropTypes.object,
  changePaymentField: PropTypes.func,
  updatePaymentDetails: PropTypes.func,
  handleAfterSubmit: PropTypes.func,
}

export { BankAccountSetup }
