import React from 'react'

import ErrorPage from './ErrorPage.jsx'

const FourOhFour = () => {
  return (
    <ErrorPage errorCode={404} headline="Page not found">
      <div>
        <p>The page you were looking for could not be found</p>
      </div>
    </ErrorPage>
  )
}

export default FourOhFour
