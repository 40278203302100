import React from 'react'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

import { Captcha } from 'components/organisms/Captcha/Captcha'

type CaptchaModalProps = {
  headerNode: React.ReactElement
  handleCancel: () => void
  token: string
  hideCaptcha: () => void
}

const CaptchaModal = ({ handleCancel, headerNode, token, hideCaptcha }: CaptchaModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  if (desktop)
    return (
      <Modal open={open} onClose={handleCancel} close={null}>
        <Width size={36} style={{ minHeight: '600px' }}>
          <Paper top={8} right={24} left={24}>
            {headerNode}
            <Paper top={40} right={48} left={48}>
              <Paper top={64}>
                <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
                  <Typo>Security check</Typo>
                </Typography>
              </Paper>
              <Paper top={32} left={24} right={24} style={{ position: 'relative' }}>
                <Captcha token={token} onSuccess={hideCaptcha} />
              </Paper>
            </Paper>
          </Paper>
        </Width>
      </Modal>
    )
  return (
    <Modal open={open} onClose={handleCancel}>
      <MobileLayout
        header={headerNode}
        content={
          <Paper top={8} right={24} left={24}>
            <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
              <Typo>Security check</Typo>
            </Typography>
            <Paper top={40} right={48} left={48}>
              <Paper top={32} left={24} right={24} style={{ position: 'relative' }}>
                <Captcha token={token} onSuccess={hideCaptcha} />
              </Paper>
            </Paper>
          </Paper>
        }
      />
    </Modal>
  )
}

export { CaptchaModal }
