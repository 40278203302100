import React from 'react'

import classNames from 'classnames/dedupe'
import PropTypes from 'prop-types'

import { modsToClassnames } from 'helpers/classname.js'
import { propTypes } from 'helpers/propTypes'

import Label, { LabelText, LabelField } from 'components/_old/Label/Label.jsx'

import { Checkbox } from 'components/atoms/Checkbox/'

import './WithLabel.css'

const withLabelPropTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
  postfield: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  size: PropTypes.string,
  preventFocusOnLabelClick: PropTypes.bool,
  noMarginBottom: PropTypes.bool,
  'data-test-id': PropTypes.string,
}

const inputPropTypes = {
  CustomInput: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  'data-test-id': PropTypes.string,
}

const WithLabel = ({
  className,
  field,
  label,
  postfield,
  size = 'normal',
  preventFocusOnLabelClick = false,
  noMarginBottom,
  offsetField = 0,
  'data-test-id': dataTestId,
  ...rest
}) => {
  const classes = classNames(
    className,
    'WithLabel',
    modsToClassnames('WithLabel', {
      size,
      offsetField,
    }),
  )

  return (
    <div className={classes} data-test-id={dataTestId}>
      <Label
        flex={`labelGrow${!postfield ? ' inline' : ''}`}
        mods={{ size }}
        postfield={postfield}
        preventFocusOnLabelClick={preventFocusOnLabelClick}
        noMarginBottom={noMarginBottom}
        {...rest}
      >
        <LabelField className="WithLabel-Field">{field}</LabelField>
        <LabelText>{label}</LabelText>
      </Label>
    </div>
  )
}

WithLabel.propTypes = {
  field: propTypes.childrenOfType([Checkbox]),
  ...withLabelPropTypes,
}

const CheckboxWithLabel = ({
  label,
  size,
  checked,
  value,
  onChange,
  outline,
  'data-test-id': dataTestId,
  required,
  ...rest
}) => {
  const inputProps = {
    checked,
    value,
    onChange,
    outline,
    required,
  }

  return (
    <WithLabel
      label={label}
      size={size}
      field={<Checkbox {...inputProps} type="checkbox" />}
      data-test-id={dataTestId}
      {...rest}
    />
  )
}

CheckboxWithLabel.propTypes = {
  ...withLabelPropTypes,
  ...inputPropTypes,
  checked: PropTypes.bool,
}

const RadioWithLabel = ({
  label,
  size,
  value,
  valid,
  required,
  CustomInput,
  onChange,
  testId,
  'data-test-id': dataTestId,
  name,
  ...rest
}) => {
  const inputProps = {
    name,
    value,
    required,
    CustomInput,
    onChange,
    testId,
  }

  return (
    <WithLabel
      label={label}
      size={size}
      field={<Checkbox type="radio" {...inputProps} />}
      valid={valid}
      data-test-id={dataTestId}
      {...rest}
    />
  )
}

RadioWithLabel.propTypes = {
  ...withLabelPropTypes,
  ...inputPropTypes,
}

export { CheckboxWithLabel, RadioWithLabel }
export default WithLabel
