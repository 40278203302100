import React from 'react'
import ReactMarkdown from 'react-markdown'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import List, { ListItem } from 'components/_old/List/List.jsx'
import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './MarkdownRenderer.css'

type MarkdownRendererProps = {
  isInteractive: boolean
  desktop: boolean
  children: React.ReactNode
}

const MarkdownRenderer = ({ isInteractive, desktop, children }: MarkdownRendererProps): JSX.Element => {
  const renderYoutube = (src, title): React.ReactNode | null => {
    const id = src.match(/(\.com|\.be)\/(.*)(\/|$)/)?.[2]

    if (id) {
      const embed = `https://www.youtube.com/embed/${id}`

      return <iframe className="MarkdownText-Video" src={embed} title={title} frameBorder="0" allowFullScreen />
    }

    return null
  }

  return (
    <ReactMarkdown
      components={{
        p({ children }) {
          return (
            <Typography
              tag="p"
              size={desktop ? 16 : 14}
              lineHeight="medium"
              color="additional"
              className="SecurityDescription-Paragraph"
            >
              <Typo>{children}</Typo>
            </Typography>
          )
        },
        h1({ children }) {
          return (
            <Typography tag="h2" size={24} lineHeight="small" weight="semibold">
              <Typo>{children}</Typo>
            </Typography>
          )
        },
        h2({ children }) {
          return (
            <Typography tag="h2" size={24} lineHeight="small" weight="semibold">
              <Typo>{children}</Typo>
            </Typography>
          )
        },
        h3({ children }) {
          return (
            <Paper top={24} className="SecurityDescription-SectionTitle">
              <Typography tag="h3" size={16} lineHeight="medium" weight="semibold">
                <Typo>{children}</Typo>
              </Typography>
            </Paper>
          )
        },
        h4({ children }) {
          return (
            <Typography tag="h4" size={16} lineHeight="small" weight="semibold">
              <Typo>{children}</Typo>
            </Typography>
          )
        },
        h5({ children }) {
          return (
            <Typography tag="h5" size={16} lineHeight="small" weight="semibold">
              <Typo>{children}</Typo>
            </Typography>
          )
        },
        h6({ children }) {
          return (
            <Typography tag="h6" size={16} lineHeight="small" weight="semibold">
              <Typo>{children}</Typo>
            </Typography>
          )
        },
        a({ href, children }) {
          if (/(youtube\.com|youtu\.be)/.test(href)) {
            return renderYoutube(href, children)
          }
          return (
            <Link to={href} hard blank className="SecurityDescription-Link" tabIndex={isInteractive ? 0 : -1}>
              {children}
              {/* for outer links */}
              {!href.startsWith('/') && <Icon type="arrow-angle-16" color="inherit" size={16} />}
            </Link>
          )
        },
        img({ src, alt }) {
          return <img src={src} alt={alt || ''} className="SecurityDescription-Image" />
        },
        ol({ children }) {
          return (
            <Paper top={12}>
              <ol className="SecurityDescription-OrderedList">{children}</ol>
            </Paper>
          )
        },
        ul({ children }) {
          return (
            <Paper top={12}>
              <List mods={{ type: 'bullet', 'no-margin': 'top' }}>{children}</List>
            </Paper>
          )
        },
        li({ children }) {
          return (
            <ListItem className="SecurityDescription-ListItem">
              <Typography
                tag="p"
                size={desktop ? 16 : 14}
                lineHeight="medium"
                color="additional"
                className="SecurityDescription-Paragraph"
              >
                <Typo>{children}</Typo>
              </Typography>
            </ListItem>
          )
        },
      }}
    >
      {children}
    </ReactMarkdown>
  )
}

export { MarkdownRenderer }
