import React, { Fragment } from 'react'

import { useCallback, useMediaQueries, useMemo, useSelector } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

import twoFactorPhoneImage from './twoFactorPhone.png'

type TwoFactorAuthModalProps = {
  open: boolean
}

const TwoFactorAuthModal = ({ open }: TwoFactorAuthModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { tfa: twoFA } = useSelector((state) => state.client)
  const is2FAEnabled = twoFA === 'ENABLED'

  const handleClose = useCallback(() => {
    const backUrl = urlTo('dashboard.user-profile.login-settings')

    goTo(backUrl, { replace: true, scrollToTop: false })
  }, [])

  const handleContinue = useCallback(() => {
    goTo(urlTo('user-profile.two-factor-setup.first-step'))
  }, [])

  const titleNode = useMemo(
    () => (
      <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
        <Typo>How two-factor authentication helps to protect your account</Typo>
      </Typography>
    ),
    [desktop],
  )

  const descriptionNode = useMemo(
    () => (
      <Typography size={desktop ? 14 : 16} align="center">
        <Typo>
          With two-factor authentication, when you log in to your InvestEngine account from another device, you will
          need to authorise that access using your mobile phone app.
        </Typo>
      </Typography>
    ),
    [desktop],
  )

  const phoneImageNode = useMemo(
    () => (
      <AllCenter>
        <img src={twoFactorPhoneImage} width={215} height={290} />
      </AllCenter>
    ),
    [],
  )

  const button = useMemo(
    () =>
      is2FAEnabled ? (
        <Link to={urlTo('user-profile.two-factor-setup.disable-two-factor')}>
          <Typography align="center" color="inherit">
            <Typo noNbsp>Do you want to disable two-factor authentication?</Typo>
          </Typography>
        </Link>
      ) : (
        <Button mods={{ size: 'big block' }} onClick={handleContinue}>
          Activate two-factor authentication
        </Button>
      ),
    [is2FAEnabled, handleContinue],
  )

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose} close={null}>
        <Width size={36}>
          <Paper top={8} bottom={80} right={24} left={24}>
            <NavigationBar onRightPartClick={handleClose} rightPartText="Close" />
            <Paper top={80} left={48} right={48}>
              <Paper top={24}>{titleNode}</Paper>
              <Paper top={32}>{descriptionNode}</Paper>
              <Paper top={48}>{phoneImageNode}</Paper>
              <Paper top={is2FAEnabled ? 48 : 56} right={24} left={24}>
                {button}
              </Paper>
            </Paper>
          </Paper>
        </Width>
      </Modal>
    )
  }

  const contentNode = (
    <Fragment>
      <Paper top={8}>{titleNode}</Paper>
      <Paper top={32}>{descriptionNode}</Paper>
      <Paper top={32} bottom={32}>
        {phoneImageNode}
      </Paper>
      {is2FAEnabled ? <Paper bottom={80}>{button}</Paper> : null}
    </Fragment>
  )

  return (
    <Modal open={open} onClose={handleClose}>
      <MobileLayout
        header={<NavigationBar onRightPartClick={handleClose} rightPartText="Close" />}
        content={contentNode}
        footer={is2FAEnabled ? null : <MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
}

export { TwoFactorAuthModal }
