enum TransferCurrentYear {
  NO = 'NO',
  YES = 'YES',
}

enum TransferPreviousYear {
  NO = 'NO',
  ALL = 'ALL',
  PART = 'PART',
}

enum TransferType {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

export { TransferCurrentYear, TransferPreviousYear, TransferType }
