import { combine, createEvent, createStore, createEffect } from 'effector'

import { makeApiCall } from 'app/effector/api'

import * as api from './api'
import { type AnalyticsStore } from './types'
import { parseAnalyticsItem, parseAnalyticsGeneralData } from './utils'

// Events
const resetAnalytics = createEvent()

// Effects
const fetchAnalyticsGeneralFx = createEffect(async ({ portfolioId, securityId, presetId }) => {
  try {
    const result = await makeApiCall(api.getAnalytics, { portfolioId, securityId, presetId })

    return result
  } catch (error) {}
})

const fetchAnalyticsItemFx = createEffect(async ({ type, id, portfolioId }) => {
  try {
    const result = await makeApiCall(api.getHoldingAnalytics, { type, id, portfolioId })

    return result
  } catch (error) {}
})

// Stores
const $analyticsData = createStore<AnalyticsStore>({})

$analyticsData.on(fetchAnalyticsGeneralFx.done, (state, { params, result }) => {
  if (!result) return state

  const portfolioId = params.portfolioId ?? 'all'
  const securityId = params.securityId ?? 'all'

  return {
    ...state,
    [portfolioId]: {
      ...(state?.[portfolioId] ?? {}),
      general: {
        ...(state?.[portfolioId]?.general ?? {}),
        [securityId]: parseAnalyticsGeneralData(result),
      },
    },
  }
})

$analyticsData.on(fetchAnalyticsItemFx.done, (state, { params, result }) => {
  if (!result) return state

  const portfolioId = params.portfolioId ?? 'all'

  return {
    ...state,
    [portfolioId]: {
      ...(state?.[portfolioId] ?? {}),
      [params.type]: {
        ...(state?.[portfolioId]?.[params.type] ?? {}),
        [params.id]: parseAnalyticsItem(result),
      },
    },
  }
})

$analyticsData.on(resetAnalytics, () => ({}))

const $analyticsStore = combine([$analyticsData], ([analyticsData]) => ({
  isLoading: fetchAnalyticsGeneralFx.pending || fetchAnalyticsItemFx.pending,
  getGeneralDataOf: (portfolioId, securityId) => {
    if (!portfolioId) {
      portfolioId = 'all'
    }

    if (!securityId) {
      securityId = 'all'
    }

    return analyticsData?.[portfolioId]?.general?.[securityId] ?? null
  },
  getItemDataOf: (portfolioId, type, typeId) => {
    if (!portfolioId) {
      portfolioId = 'all'
    }

    return analyticsData?.[portfolioId]?.[type]?.[typeId] ?? null
  },
}))

export { $analyticsData, $analyticsStore, fetchAnalyticsGeneralFx, fetchAnalyticsItemFx, resetAnalytics }
