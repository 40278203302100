import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import Card from 'components/_old/Card/Card.jsx'
import StepHeadline from 'app/containers/Questionnaire/StepHeadline/StepHeadline.jsx'
import Text from 'components/_old/Text/Text.jsx'

const LayoutHeadline = ({ headline, backLinkUrl, backLinkText }) => {
  const { desktop } = useMediaQueries()

  return (
    <Card
      mods={{
        theme: 'transparent',
        padding: 'biggest',
        'no-padding': `bottom left right ${desktop ? '' : 'top'}`,
      }}
      fluid
    >
      <StepHeadline
        headline={desktop ? null : headline}
        preHeadline={
          desktop ? (
            <Text block smaller style={{ verticalAlign: 'middle', marginBottom: '2.25em', marginTop: '0.325em' }}>
              {headline}
            </Text>
          ) : null
        }
        back={backLinkUrl}
        backLinkText={backLinkText}
      />
    </Card>
  )
}

LayoutHeadline.propTypes = {
  headline: PropTypes.string.isRequired,
  backLinkUrl: PropTypes.string.isRequired,
  backLinkText: PropTypes.string.isRequired,
}

export { LayoutHeadline }
