import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useLoading, useMemo, useSelector } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { $owner, fetchContactsFx } from 'app/effector/contacts'

import { fetchClient as fetchClientActionCreator } from 'app/redux/actions/client'
import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'
import { isClientNewOrNotCompleted as selectIsClientNewOrNotCompleted } from 'app/redux/selectors'

import { usePortfolioFlow } from './flows'
import { useOpenNewPortfolio } from './useOpenNewPortfolio.js'
import { useTypeChoose } from './useTypeChoose.js'

import { types as clientTypes } from 'constants/client'
import { manageTypes, regulatoryTypes } from 'constants/portfolio'

const useSelectPortfolioType = (location) => {
  const client = useSelector((state) => state.client)
  const isClientNewOrNotCompleted = useSelector(selectIsClientNewOrNotCompleted)
  const owner = useUnit($owner)
  const diyPortfolio = useSelector((state) => state.portfolios.list.groupByManageType()?.[manageTypes.DIY]?.[0])
  const groupedPortfolios = useSelector((state) => state.portfolios.list.getVisiblePortfolios().groupByRegulatoryType())
  const hasIsa = !!groupedPortfolios?.[regulatoryTypes.ISA]?.length

  const { isLoading: isClientLoading, wait: waitForClient } = useLoading(!client)
  const { isLoading: isContactsLoading, wait: waitForContacts } = useLoading(!owner)
  const { isLoading: isPortfoliosLoading, wait: waitForPortfolio } = useLoading(!diyPortfolio)
  const isLoading = isClientLoading || isContactsLoading || isPortfoliosLoading

  const canHaveOnlyGIA = useMemo(() => {
    if (client.type === clientTypes.BUSINESS) {
      return true
    }

    if (!owner?.isUkResidence() && !isClientNewOrNotCompleted) {
      return true
    }

    return false
  }, [client.type, owner, isClientNewOrNotCompleted])

  const { step, regulatoryType, manageType, presetType } = location?.query || {}

  const [fetchClient, fetchPortfolios] = useActions([fetchClientActionCreator, fetchPortfoliosActionCreator])

  const { handleSubmit } = useOpenNewPortfolio()

  const handleBack = useCallback(() => {
    goTo(urlTo('dashboard'))
  }, [])

  const handleBeforeClose = useCallback(() => {
    goTo(urlTo('create-new-portfolio'))
  }, [])

  const flow = usePortfolioFlow({ regulatoryType, manageType })
  const { activeStepName, steps } = useTypeChoose({
    flow,
    onSubmit: handleSubmit,
    initialStep: step,
    onBeforeClose: handleBeforeClose,
    query: location?.query,
  })

  const title = useMemo(() => {
    if (regulatoryType) {
      const portfoliosGroup = groupedPortfolios[regulatoryType] ?? []

      return portfoliosGroup.length < 2 ? 'Create portfolio' : 'Add new portfolio'
    }

    return 'Add new portfolio'
  }, [regulatoryType, groupedPortfolios])

  useEffect(() => {
    if (!client) {
      waitForClient(fetchClient())
    }
    if (!owner) {
      waitForContacts(fetchContactsFx())
    }
    if (!diyPortfolio) {
      waitForPortfolio(fetchPortfolios())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (manageType === manageTypes.MANAGED && regulatoryType && presetType) {
      if (client.type === clientTypes.BUSINESS && regulatoryType === regulatoryTypes.ISA) {
        goTo(urlTo('dashboard'))
        return
      }

      waitForPortfolio(handleSubmit([manageType, regulatoryType]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isContactsLoading && manageType === manageTypes.MANAGED && canHaveOnlyGIA) {
      waitForPortfolio(handleSubmit([manageType, regulatoryTypes.GIA]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContactsLoading])

  return {
    isLoading,
    title,
    steps,
    activeStepName,
    hasIsa,
    handleBack,
  }
}

export { useSelectPortfolioType }
