import React from 'react'
import PropTypes from 'prop-types'

import { SelectACard, propTypes as selectACardPropTypes } from 'app/containers/Questionnaire/Question/SelectACard'

const QuestionnaireQuestionSingle = ({ code, cards, analytics, getAnswers, onSubmit: handleSubmit }) => {
  const selectedCardValue = getAnswers(code)

  cards = cards.map((card) => ({
    ...card,
    analytics,
    selected: card.value === selectedCardValue,
    'data-test-id': 'questionnaireCardSingle',
  }))

  return <SelectACard cards={cards} onSelect={(event, answerId) => handleSubmit(event, [answerId])} />
}

QuestionnaireQuestionSingle.propTypes = {
  code: PropTypes.string.isRequired,
  cards: selectACardPropTypes.cards,
  analytics: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func,
  getAnswers: PropTypes.func,
}

export { QuestionnaireQuestionSingle }
