import axios from 'helpers/ajax'

import type { Address } from 'app/redux/models/address'
import { ApiError } from 'app/redux/models/errors'

import type { Company } from '../models'

type CompanyDTO = {
  id: number
  name: string
  uk_number: string
  lei_number: string
  business_types: Array<{ code: string | null; title: string | null }>
  bank_statement_filled: boolean
  addresses: Array<{
    id: number
    street: string
    street2: string
    postcode: string
    city: string
    country: number
    is_current: boolean
  }>
  lock_business_info: boolean
}

const updateCompany = async (company: Company): Promise<Company | ApiError> => {
  try {
    const { data } = await axios.patch(`client/company/`, company)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const fetchCompany = async (): Promise<Company | ApiError> => {
  try {
    const { data } = await axios.get(`client/company/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const createAddress = async (address): Promise<Address | ApiError> => {
  try {
    const { data } = await axios.post(`client/company/addresses/`, address)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const updateAddress = async (address): Promise<Address | ApiError> => {
  try {
    const { id } = address
    const { data } = await axios.patch(`client/company/addresses/${id}`, address)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { updateCompany, fetchCompany, createAddress, updateAddress, type CompanyDTO }
