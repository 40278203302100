import React from 'react'

import { Typo } from 'components/_old/Typo/Typo'

import { CardNew } from 'components/atoms/CardNew'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './BannerLogo.css'

type BannerLogoProps = {
  borderRadius?: 10 | 16
  backgroundImage: string
  title: string
}

const BannerLogo = ({ borderRadius = 10, backgroundImage, title }: BannerLogoProps): React.ReactElement => {
  return (
    <div className="BannerLogo" data-test-id="featuredPartnerCard">
      <CardNew borderRadius={borderRadius} backgroundImage={backgroundImage}>
        <div style={{ height: '104px' }} />
      </CardNew>
      <Paper top={12} left={8}>
        <Typography size={14} lineHeight="small" className="BannerLogo_title">
          <Typo>{title}</Typo>
        </Typography>
      </Paper>
    </div>
  )
}

export { BannerLogo }
