import React, { Fragment } from 'react'

import { useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout, MobileLayoutFullViewport } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import Segment from 'components/atoms/Segment/Segment.jsx'
import { Typography } from 'components/atoms/Typography'

import { HorizontallyScrollable } from 'components/molecules/HorizontallyScrollable'
import { Preloader } from 'components/molecules/Preloader'

import { MobileFooter } from 'app/containers/Footer'
import { MobileHeader } from 'app/containers/Header'

import { Transaction } from '../Transaction.jsx'
import { TransactionsHeader, TransactionsHeaderTabs } from '../TransactionsHeader'

const Mobile = ({
  ordersProcessing,
  hasTransactions,
  hasSecurityTransactions,
  total,
  filters,
  pendingOrders,
  transactions,
  portfolioNameById,
  portfolioReferenceById,
  preloaderElementRef,
  isNextLoading,
  noTransactions,
  noResults,
  isLoading,
  isSecurityTransactionsPage,
  resetFilters,
}): React.ReactElement => {
  const headerAndTabs = useMemo(
    () => (
      <TransactionsHeader
        activeTabName={
          isSecurityTransactionsPage
            ? TransactionsHeaderTabs.SECURITY_TRANSACTIONS
            : TransactionsHeaderTabs.TRANSACTIONS
        }
        hasSecurityTransactions={hasSecurityTransactions}
        resetFilters={resetFilters}
      />
    ),
    [isSecurityTransactionsPage, hasSecurityTransactions, resetFilters],
  )

  const filtersPanel = useMemo(() => {
    const sortFilters = (first, second): number => second.props.selected.length - first.props.selected.length

    return (
      <Inner>
        {headerAndTabs}
        <Paper top={hasSecurityTransactions ? 32 : 0} bottom={24}>
          <HorizontallyScrollable scrollBack>
            {filters.sort(sortFilters).map((filter, index) => (
              <Paper key={index} right={8} inline style={{ verticalAlign: 'middle' }}>
                {filter}
              </Paper>
            ))}
          </HorizontallyScrollable>
        </Paper>
      </Inner>
    )
  }, [headerAndTabs, hasSecurityTransactions, filters])

  const transactionsTable = useMemo(
    () =>
      hasTransactions && (
        <Segment mods={{ noMargin: 'bottom' }}>
          <Inner>
            {total && (
              <Paper className="Transactions-Total" bottom={pendingOrders.length ? 0 : 16}>
                <Typography size={14} align="right">
                  Total:
                </Typography>
                <Typography size={16} align="right" weight="semibold">
                  {total}
                </Typography>
              </Paper>
            )}
            {!isSecurityTransactionsPage && pendingOrders.length > 0 && (
              <Typography size={14} color="additional">
                Transactions
              </Typography>
            )}
            <Paper bottom={8}></Paper>
            <div>
              {transactions.map((transaction) => (
                <Transaction
                  transaction={transaction}
                  key={transaction.id}
                  portfolio={portfolioNameById[transaction.goal]}
                  portfolioReference={portfolioReferenceById[transaction.goal]}
                  isSecurityTransaction={isSecurityTransactionsPage}
                />
              ))}
              <div ref={preloaderElementRef}>
                {isNextLoading && (
                  <div className="Transactions-Preloader">
                    <Preloader loading={isNextLoading} absolute />
                  </div>
                )}
              </div>
            </div>
          </Inner>
        </Segment>
      ),
    [
      hasTransactions,
      total,
      transactions,
      pendingOrders.length,
      portfolioNameById,
      portfolioReferenceById,
      preloaderElementRef,
      isNextLoading,
      isSecurityTransactionsPage,
    ],
  )

  const emptyTransactions = useMemo(
    () =>
      noTransactions && (
        <AllCenter>
          <Typography align="center">
            <Typo>No transactions to show just yet</Typo>
          </Typography>
        </AllCenter>
      ),
    [noTransactions],
  )

  const emptyResults = useMemo(
    () =>
      noResults && (
        <AllCenter>
          <Typography align="center">
            <Typo>
              No transactions found.
              <br /> Try a different set of filters.
            </Typo>
          </Typography>
        </AllCenter>
      ),
    [noResults],
  )

  return (
    <MobileLayout
      header={<MobileHeader pageName="Transactions" />}
      content={
        <Fragment>
          <MobileLayoutFullViewport>
            <div className="Transactions-MobileWrapper">
              {filtersPanel}
              {ordersProcessing}
              {transactionsTable}
              {emptyTransactions}
              {emptyResults}
              <Preloader loading={isLoading} size="big" zIndex />
            </div>
          </MobileLayoutFullViewport>
          <MobileFooter noMarginTop />
        </Fragment>
      }
    />
  )
}

export { Mobile }
