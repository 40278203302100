import React from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette'

import { showSuccessToast } from 'app/redux/actions/ui'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { CopyToClipboard } from 'components/atoms/CopyToClipboard'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type RecoveryCodeProps = {
  recoveryCode: string
  onRegenerate: () => Promise<any>
}

const RecoveryCode = ({ recoveryCode, onRegenerate }: RecoveryCodeProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
      <Column size={0}>
        <Link onClick={onRegenerate}>
          <Icon type="refresh-24" size={24} color={palette['content-on-background-default']} />
        </Link>
      </Column>
      <Column size={1}>
        <Paper right={16} left={16}>
          <Typography size={desktop ? 32 : 24} lineHeight="small" align="center" color="additional">
            {recoveryCode}
          </Typography>
        </Paper>
      </Column>
      <Column size={0}>
        <CopyToClipboard value={recoveryCode} toast="Recovery code copied" showToast={showSuccessToast} />
      </Column>
    </ColumnarLayout>
  )
}

export { RecoveryCode }
