import React from 'react'

import { useCallback } from 'hooks'

import { Nobr } from 'components/_old/Nobr'

import { Paper } from 'components/atoms/Paper'
import Switcher from 'components/atoms/Switcher/Switcher'
import { Typography } from 'components/atoms/Typography'

type ShareToggleProps = { isActive: boolean; setIsActive: (value: boolean) => void }

const ShareToggler = ({ isActive, setIsActive }: ShareToggleProps): React.ReactElement => {
  const handleChange = useCallback(() => {
    setIsActive(!isActive)
  }, [isActive, setIsActive])

  return (
    <Switcher onChange={handleChange} checked={isActive} multiline inverse smallDistance data-test-id="shareToggler">
      <Typography lineHeight="small" data-test-id="shareTogglerTitle">
        <Nobr>
          Link to portfolio outline{' '}
          {isActive ? (
            <Typography tag="span" color="green" lineHeight="small" inline>
              ON
            </Typography>
          ) : (
            <Typography tag="span" color="red" lineHeight="small" inline>
              OFF
            </Typography>
          )}
        </Nobr>
      </Typography>
      <Paper top={8}>
        <Typography size={14} color="minor" lineHeight="small">
          Sharing this link lets others see the outline view of your portfolio. No personal data or £ values are
          disclosed.
        </Typography>
      </Paper>
    </Switcher>
  )
}

export { ShareToggler }
