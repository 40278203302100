import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

const WeAreSorry = () => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <AllCenter>
        <Icon size={64} type="stop-64" color={palette['status-error']} data-test-id="stopIcon" />
      </AllCenter>
      <Paper top={desktop ? 16 : 32}>
        <Typography
          size={desktop ? 32 : 24}
          lineHeight="small"
          weight={desktop ? 'regular' : 'semibold'}
          align="center"
          data-test-id="sorryTitle"
        >
          <Typo>We are sorry!</Typo>
        </Typography>
      </Paper>
      <Paper top={desktop ? 12 : 24}>
        <Width size={desktop ? 36 : null} center>
          <Typography size={desktop ? 18 : 16} align="center">
            <Typo>
              Based on the answers provided, you won’t be able to set up a new portfolio with InvestEngine. If, however,
              your circumstances change, you are welcome to re‑apply.
            </Typo>
          </Typography>
        </Width>
      </Paper>
    </Fragment>
  )
}

export { WeAreSorry }
