import React from 'react'

import { palette } from 'helpers/palette/'
import { urlTo } from 'helpers/router'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import icon from 'components/_old/Icon/svgs/chevron_right.svg'
import Link from 'components/_old/Link/Link.jsx'
import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'

import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { PortfolioUnavailableInterceptor } from 'components/molecules/PortfolioUnavailableInterceptor/PortfolioUnavailableInterceptor'

import managedLogo1x from './managed-logo@1x.png'
import managedLogo2x from './managed-logo@2x.png'

import { manageTypes } from 'constants/portfolio'

const ManagedPromoPanel = (): React.ReactElement => {
  const currentLocation = window.location.pathname + window.location.search

  return (
    <PortfolioUnavailableInterceptor variant="managed">
      <Link
        to={urlTo('create-new-portfolio', null, {
          back: currentLocation,
          manageType: manageTypes.MANAGED,
        })}
      >
        <SelectableCard>
          <Paper top={12} right={16} bottom={12} left={16}>
            <ColumnarLayout mods={{ padding: 'micro' }}>
              <Column>
                <AllCenter>
                  <img
                    src={managedLogo1x}
                    srcSet={`${managedLogo1x} 1x, ${managedLogo2x} 2x`}
                    width={48}
                    height={48}
                    alt="Managed logo"
                  />
                </AllCenter>
              </Column>
              <Column size={14}>
                <Typography tag="p" align="left" weight="semibold">
                  Are you looking for Managed?
                </Typography>

                <Typography tag="p" color="additional">
                  Click to get information
                </Typography>
              </Column>
              <Column>
                <AllCenter>
                  <SvgIcon
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '-20px',
                      fill: palette['content-on-background-default'],
                    }}
                    src={icon}
                  />
                </AllCenter>
              </Column>
            </ColumnarLayout>
          </Paper>
        </SelectableCard>
      </Link>
    </PortfolioUnavailableInterceptor>
  )
}

export { ManagedPromoPanel }
