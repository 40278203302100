import React, { Fragment } from 'react'

import { useMediaQueries, useCallback, useMemo } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

type HoldingsChangedProps = {
  open: boolean
  portfolioId?: number
}

function HoldingsChanged({ open, portfolioId }: HoldingsChangedProps): React.ReactElement {
  const { desktop } = useMediaQueries()

  const handleClose = useCallback(() => {
    const nextQueryString = window.location.search.replace(/[?&]holdingsChanged/, '').replace(/^&/, '?')
    const nextUrl = `${window.location.pathname}${window.location.hash}${nextQueryString}`

    goTo(nextUrl, { replace: true, scrollToTop: false })
  }, [])

  const textNode = useMemo(
    () => (
      <Fragment>
        <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
          <Typo>Holdings in your portfolio have changed</Typo>
        </Typography>
        <Paper top={desktop ? 24 : 32}>
          <Typography size={desktop ? 16 : 14} align="center">
            <Typo>
              This may affect the target weights of funds in your portfolio.
              <br />
              <br />
              Please review your target weights to continue to use AutoInvest, Rebalance and orders at portfolio level.
            </Typo>
          </Typography>
        </Paper>
      </Fragment>
    ),
    [desktop],
  )

  const buttonNode = useMemo(
    () => (
      <Button mods={{ size: 'big block' }}>
        <Link to={urlTo('portfolio.edit-weights', { id: portfolioId })}>Adjust portfolio weights</Link>
      </Button>
    ),
    [portfolioId],
  )

  if (desktop) {
    return (
      <Modal open={open} close={<Typography color="inherit">Close</Typography>} onClose={handleClose}>
        <Paper top={120} bottom={120}>
          <AllCenter>
            <Width size={36}>
              <Width size={27} center>
                {textNode}
              </Width>
              <Paper top={56}>
                <Width size={24} center>
                  {buttonNode}
                </Width>
              </Paper>
            </Width>
          </AllCenter>
        </Paper>
      </Modal>
    )
  }

  return (
    <Modal open={open} close={null} onClose={handleClose}>
      <MobileLayout
        header={<NavigationBar rightPartText="Close" onRightPartClick={handleClose} />}
        content={
          <Inner flex>
            <AllCenter>{textNode}</AllCenter>
          </Inner>
        }
        footer={<MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
}

export { HoldingsChanged, type HoldingsChangedProps }
