import React from 'react'
import isFunction from 'lodash/isFunction'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

export default class AttentionModalCheckbox extends React.Component {
  state = {
    checked: false,
  }

  handleCheckedChange = () => {
    this.setState({ checked: !this.state.checked })
  }

  componentWillUpdate(nextProps) {
    const { recommendedIndex, desiredIndex } = this.props

    if (recommendedIndex !== nextProps.recommendedIndex || desiredIndex !== nextProps.desiredIndex) {
      this.setState({ checked: false })
    }
  }

  render() {
    const { children, recommendedIndex, desiredIndex, checkboxText } = this.props
    const { checked } = this.state

    const shouldShowCheckbox = desiredIndex < recommendedIndex || desiredIndex > recommendedIndex

    const checkbox = (
      <CheckboxWithLabel
        label={checkboxText}
        onChange={this.handleCheckedChange}
        checked={checked}
        size="smaller"
        data-test-id="attentionModalCheckbox"
      />
    )

    return isFunction(children)
      ? children({ shouldShowCheckbox, checkbox, submitDisabled: shouldShowCheckbox ? !checked : false })
      : children
  }
}
