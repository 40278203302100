import { attach } from 'effector'
import { useUnit } from 'effector-react'
import uniqBy from 'lodash/uniqBy'

import {
  useState,
  useLoading,
  useMemorizedState,
  useSelector,
  useCallback,
  useEffect,
  useMemo,
  usePrevious,
} from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { sendError } from 'helpers/errorLogging.js'
import { replace as historyReplace } from 'helpers/history.js'
import { format as formatMoney } from 'helpers/money'

import {
  $transactionsStore,
  fetchTransactionsFx,
  fetchNextTransactionsFx,
  cancelTransactionFx,
  fetchSecurityTransactionsFx,
  fetchNextSecurityTransactionsFx,
  fetchSecuritiesFx,
} from 'app/effector/transactions'
import { type Transaction } from 'app/effector/transactions/models'

import { showFailToast } from 'app/redux/actions/ui'
import { getPortfolios } from 'app/redux/api/portfolio'
import { ApiError } from 'app/redux/models/errors'
import { PortfolioList } from 'app/redux/models/portfolio'
import { getSortedRegulatoryTypes } from 'app/redux/selectors'

import { usePendingOrders } from 'app/pages/Dashboard/Goals/DIY/hooks'

import { useFilters } from './useFilters'

import { types as clientTypes } from 'constants/client'
import { states } from 'constants/portfolio'

type UseTransactionsInterface = {
  isLoading: boolean
  isNextLoading: boolean
  isBusiness: boolean
  portfolios: Array<[number, string]>
  pendingOrders: any[]
  filters: any[]
  transactions: Transaction[]
  hasTransactions: boolean
  hasActiveFilter: boolean
  total: string | null
  fetchNextTransactions: () => Promise<void>
}

const useTransactions = (location, isSecurityTransactionsPage): UseTransactionsInterface => {
  const { isLoading: isPortfoliosLoading, wait: waitForPortfolios } = useLoading()
  const [portfolioList, setPortfolioList] = useMemorizedState<PortfolioList | null>(
    'Transactions/portfolioList',
    new PortfolioList(),
    {
      parse: (string) => new PortfolioList(...JSON.parse(string)),
    },
  )
  const { orders: pendingOrders } = usePendingOrders()

  const {
    transactions: normalTransactions,
    securityTransactions,
    securities,
    isLoading: isTransactionsLoading,
    isNextLoading,
    areTransactionsFetched: areNormalTransactionsFetched,
    areSecurityTransactionsFetched,
  } = useUnit($transactionsStore)

  const transactions = isSecurityTransactionsPage ? securityTransactions : normalTransactions
  const areTransactionsFetched = isSecurityTransactionsPage
    ? areSecurityTransactionsFetched
    : areNormalTransactionsFetched
  const fetchNext = isSecurityTransactionsPage ? fetchNextSecurityTransactionsFx : fetchNextTransactionsFx

  const transactionList = transactions.list
  const isLoading = isPortfoliosLoading || isTransactionsLoading
  const hasTransactions = areTransactionsFetched && transactionList.hasTransactions?.()
  const [hasSecurityTransactions, setHasSecurityTransactions] = useState(false)
  const [isEverythingFetched, setIsEverythingFetched] = useState(
    areNormalTransactionsFetched && areSecurityTransactionsFetched,
  )

  const clientType = useSelector((state) => state.client.type)
  const isBusiness = clientType === clientTypes.BUSINESS

  const portfolios: Array<[number, string]> = useMemo(() => {
    const getPortfolioTitleById = (portfolioId: number): string => {
      if (portfolioList && portfolioList.length > 0) {
        const portfolio = portfolioList.get(portfolioId)

        if (portfolio) {
          return portfolio.getTitle()
        }
      }

      return `Portfolio ${portfolioId}`
    }
    const getPortfolioDescriptionById = (portfolioId: number): string | undefined => {
      if (portfolioList && portfolioList.length > 0) {
        const portfolio = portfolioList.get(portfolioId)

        if (portfolio) {
          return portfolio.getDescription(isBusiness)
        }
      }
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const portfoliosOptions = (portfolioList ?? []).map(({ id, manage_type, first_topup, reference }) => ({
      value: id,
      name: getPortfolioTitleById(id),
      description: getPortfolioDescriptionById(id),
      hasTopup: Boolean(first_topup),
      manageType: manage_type,
      reference,
    }))

    return portfoliosOptions
  }, [portfolioList, isBusiness])

  const { filters, filtersState, resetFilters } = useFilters(
    location.query,
    portfolios,
    securities,
    isSecurityTransactionsPage,
  )
  const prevFiltersState = usePrevious(filtersState)
  const hasActiveTransactionsFilter = Boolean(filtersState.types.length)
  const total = hasActiveTransactionsFilter ? formatMoney(transactions.total, true) : null

  const loadPortfolioList = useCallback(async () => {
    try {
      const loadPortfolios = async (): Promise<[any[] | ApiError, any[] | ApiError]> => {
        const gotPortfoliosList = portfolioList && portfolioList.length > 0

        const promise = Promise.all([getPortfolios(), getPortfolios(states.CLOSED)])

        if (gotPortfoliosList) {
          return await promise
        }

        return await waitForPortfolios(promise)
      }

      const [nonClosedPortfoliosDTO, closedPortfoliosDTO] = await loadPortfolios()

      if (nonClosedPortfoliosDTO instanceof ApiError) {
        throw nonClosedPortfoliosDTO
      }

      if (closedPortfoliosDTO instanceof ApiError) {
        throw closedPortfoliosDTO
      }

      const portfoliosDTO = uniqBy([...nonClosedPortfoliosDTO, ...closedPortfoliosDTO], 'id')

      const portfolios = new PortfolioList(...portfoliosDTO)

      const regulatoryTypesOrder = getSortedRegulatoryTypes({ portfolios: { list: portfolios } })

      const sortedPortfolios = portfolios.getFlatSortedList(regulatoryTypesOrder)

      setPortfolioList(new PortfolioList(...sortedPortfolios))
    } catch (error) {
      sendError(error)
      showFailToast(error?.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPortfolioList, waitForPortfolios])

  const fetchNextTransactions = useMemo(() => {
    if (transactions?.nextPage) {
      return attach({
        effect: fetchNext,
        source: { filtersState, next: transactions.nextPage },
      })
    }

    return () => {}
  }, [fetchNext, filtersState, transactions?.nextPage])

  useEffect(() => {
    loadPortfolioList()
    fetchTransactionsFx({ filtersState })
    fetchSecurityTransactionsFx()
    fetchSecuritiesFx()
    cancelTransactionFx.done.watch(fetchTransactionsFx)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const queryString: string = querystringFromObject(filtersState)
    historyReplace(`${window.location.pathname}${queryString}`, { scrollToTop: false })
  }, [filtersState])

  useEffect(() => {
    if (JSON.stringify(filtersState) !== JSON.stringify(prevFiltersState) && prevFiltersState) {
      try {
        fetchTransactionsFx({ filtersState })
        fetchSecurityTransactionsFx({ filtersState })
      } catch (error) {
        showFailToast('Something went wrong')
        sendError(error)
      }
    }
  }, [filtersState, prevFiltersState])

  useEffect(() => {
    if (!hasSecurityTransactions && areSecurityTransactionsFetched && securityTransactions.list.hasTransactions?.()) {
      setHasSecurityTransactions(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasSecurityTransactions,
    setHasSecurityTransactions,
    areSecurityTransactionsFetched,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    securityTransactions.list.hasTransactions?.(),
  ])

  useEffect(() => {
    // had to update it next tick to avoid blinking
    if (!isEverythingFetched && areNormalTransactionsFetched && areSecurityTransactionsFetched) {
      setTimeout(() => {
        setIsEverythingFetched(true)
      }, 0)
    }
  }, [isEverythingFetched, areNormalTransactionsFetched, areSecurityTransactionsFetched])

  return {
    isLoading,
    isNextLoading,
    isBusiness,
    portfolios,
    pendingOrders,
    transactions: transactionList.transactions,
    filters,
    total,
    hasTransactions,
    hasSecurityTransactions,
    isEverythingFetched,
    hasActiveFilter: hasActiveTransactionsFilter,
    fetchNextTransactions,
    resetFilters,
  }
}

export { useTransactions }
