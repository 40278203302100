import axios from 'helpers/ajax'
import { querystringFromObject } from 'helpers/ajax/querystring'
import { memoizeForATime } from 'helpers/memoize'

import { ApiError } from 'app/redux/models/errors'

/**
 * @typedef SecurityCategory
 * @type {'STOCK'|'BOND'|'ALTERNATIVE'}
 */

/**
 * @typedef SecurityHistoryItemDTO
 * @type {object}
 * @property {string} date
 * @property {string} share_price
 */

/**
 * @typedef SecurityStatsItemDTO
 * @type {object}
 * @property {string} return_money
 * @property {string} return_percent
 */

/**
 * @typedef SecurityDocumentItemDTO
 * @type {object}
 * @property {string} title
 * @property {string} uri
 */

/**
 * @typedef SecurityShortDTO
 * @type {object}
 * @property {number} id
 * @property {?string} logo_uri
 * @property {string} title
 * @property {string} ticker
 * @property {string} short_description
 * @property {SecurityCategory} category
 */

/**
 * @typedef SecurityDetailedDTO
 * @type {object}
 * @property {number} id
 * @property {?string} logo_uri
 * @property {string} title
 * @property {string} ticker
 * @property {string} short_description
 * @property {SecurityCategory} category
 * @property {string} share_price
 * @property {string} description
 * @property {object} history
 * @property {SecurityHistoryItemDTO[]} history.daily
 * @property {object} stats
 * @property {SecurityStatsItemDTO} stats.week
 * @property {SecurityStatsItemDTO} stats.month
 * @property {SecurityStatsItemDTO} stats.six_months
 * @property {SecurityStatsItemDTO} stats.year
 * @property {SecurityStatsItemDTO} stats.max
 * @property {?SecurityDocumentItemDTO[]} documents
 * @property {'DISTRIBUTING' | "ACCUMULATING"} dividends_type
 * @property {object} totalReturns
 */

const getSecuritiesRequest = async (params) => {
  const queryString = params ? querystringFromObject(params) : ''

  try {
    const { data } = await axios.get(`securities/${queryString}`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
const getSecuritiesRequestCached = memoizeForATime(getSecuritiesRequest, 60000)

/**
 * @param {object | undefined | null} params
 * @param {object} [options={}]
 * @param {boolean} [options.useCache=false]
 * @return {Promise<SecurityShortDTO[]|ApiError>}
 */
export const getSecurities = async (params, { useCache = false }) => {
  if (useCache) {
    return await getSecuritiesRequestCached(params)
  }

  return await getSecuritiesRequest(params)
}

/**
 * @param {number | string} id
 * @param {Date} dateFrom
 * @return {Promise<SecurityDetailedDTO|ApiError>}
 */
export const getSecurityTotalReturns = async (id) => {
  try {
    const url = `securities/${id}/total-returns/`

    const { data } = await axios.get(url)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {number | string} id
 * @return {Promise<SecurityDetailedDTO|ApiError>}
 */
export const getSecurity = async (id) => {
  try {
    const { data } = await axios.get(`securities/${id}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {number | string} id
 * @param {Date} dateFrom
 * @return {Promise<SecurityDetailedDTO|ApiError>}
 */
export const getSecurityHistory = async (id) => {
  try {
    const url = `securities/${id}/history/`

    const { data } = await axios.get(url)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 *  Portfolio Securities
 */

/**
 * @typedef PortfolioSecurityDto
 * @type {object}
 * @property {number} security_id
 * @property {string} value
 * @property {string} current_weight
 * @property {?string} target_weight
 * @property {string} quantity
 * @property {object} stats
 * @property {SecurityStatsItemDTO} stats.max
 */

/**
 * @typedef PortfolioSecurityWeightItemDto
 * @type {object}
 * @property {number} security_id
 * @property {string} target_weight
 */

/**
 * @param {number} id
 * @return {Promise<Object|ApiError>}
 */
export const getSharedSecurityData = async (id) => {
  try {
    const { data } = await axios.get(`public/securities/${id}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @return {Promise<Object|ApiError>}
 */
export const getSecuritiesByTransactions = async () => {
  try {
    const { data } = await axios.get('securities/by_transactions/')

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export const getSecurityDividends = async ({ portfolioId, securityId }) => {
  try {
    const { data } = await axios.get(`/history/income/${portfolioId}/security/${securityId}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
