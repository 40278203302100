import React, { Fragment, type ReactElement } from 'react'

import { useMediaQueries } from 'hooks'

import type {
  PortfolioPointOfInterestList,
  PortfolioSecurityPointOfInterestList,
} from 'app/effector/pointsOfInterest/models'

import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'

import { PeriodSelect } from 'components/molecules/PeriodSelect'

import type {
  IncomeLineDataItem,
  PortfolioPointOfInterestCardProps,
  SecuritySlicePointOfInterestCardProps,
} from 'components/organisms/charts/parts'
import { PriceChart, type PriceChartDashedLines } from 'components/organisms/PriceChart'

type SecurityChartProps = {
  data: IncomeLineDataItem[]
  period: string
  periodPriceData: { name: string; percent: number; price: number }
  handlePeriodSelect: (value: string) => void
  topContent?: ReactElement
  topContentOffset?: number
  portfolioId?: number
  securityId?: number
  dashedLines?: PriceChartDashedLines[]
  pointsOfInterest?: PortfolioPointOfInterestList | PortfolioSecurityPointOfInterestList
  PointOfInterestCard?: (
    props: SecuritySlicePointOfInterestCardProps | PortfolioPointOfInterestCardProps,
  ) => React.ReactElement | null
}

const SecurityChart = ({
  data,
  period,
  periodPriceData,
  handlePeriodSelect,
  topContent,
  topContentOffset,
  portfolioId,
  securityId,
  dashedLines,
  pointsOfInterest,
  PointOfInterestCard,
}: SecurityChartProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <PriceChart
        period={period}
        data={data}
        negative={(periodPriceData.price ?? 0) < 0}
        topContent={topContent}
        topContentOffset={topContentOffset}
        portfolioId={portfolioId}
        securityId={securityId}
        dashedLines={dashedLines}
        pointsOfInterest={pointsOfInterest}
        PointOfInterestCard={PointOfInterestCard}
      />
      <Paper top={24} left={desktop ? 16 : null}>
        <Width size={desktop ? 24 : null} center>
          <PeriodSelect selectedPeriod={period} handlePeriodSelect={handlePeriodSelect} />
        </Width>
      </Paper>
    </Fragment>
  )
}

export { SecurityChart }
