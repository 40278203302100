import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMediaQueries } from 'hooks'

import './Tabs.css'

const Tab = ({ children, className, active, fullWidthBorder, onClick: handleClick, 'data-test-id': dataTestId }) => {
  const hasElement = React.isValidElement(children)
  const classes = classNames('Tab', className, {
    Tab_active: active,
    Tab_fullWidthBorder: fullWidthBorder,
    Tab_hasElement: hasElement,
  })

  return (
    <div className={classes} onClick={handleClick} data-test-id={dataTestId}>
      <div className="Tab-Inner">
        {hasElement ? React.cloneElement(children, { className: 'Tab-Element', active }) : children}
      </div>
    </div>
  )
}

Tab.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
  fullWidthBorder: PropTypes.bool,
  onClick: PropTypes.func,
  'data-test-id': PropTypes.string,
}

const Tabs = ({
  children,
  className,
  axis = 'horizontal',
  theme,
  noMargin,
  large,
  paddingLeft,
  'data-test-id': dataTestId,
}) => {
  const mediaQueries = useMediaQueries()
  const classes = classNames('Tabs', className, getMediaQuieryClasses('Tabs', mediaQueries), `Tabs_${axis}`, {
    Tabs_noMargin: noMargin,
    Tabs_large: large,
    [`Tabs_padding-left_${paddingLeft}`]: paddingLeft,
    [`Tabs_theme_${theme}`]: theme,
  })

  return (
    <div className={classes} data-test-id={dataTestId}>
      {children}
    </div>
  )
}

const SubTabs = ({ children, 'data-test-id': dataTestId }) => {
  return (
    <Tabs className="Tabs-SubTabs" axis="vertical" data-test-id={dataTestId}>
      {children}
    </Tabs>
  )
}

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(Tab),
    PropTypes.instanceOf(Tab),
    PropTypes.arrayOf(SubTabs),
    PropTypes.instanceOf(SubTabs),
  ]).isRequired,
  className: PropTypes.string,
  axis: PropTypes.oneOf(['vertical', 'horizontal']),
  theme: PropTypes.oneOf([null, 'iOS', 'withBorder']),
  paddingLeft: PropTypes.oneOf([null, 'small']),
  noMargin: PropTypes.bool,
  large: PropTypes.bool,
  'data-test-id': PropTypes.string,
}

SubTabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(Tab), PropTypes.instanceOf(Tab)]),
  'data-test-id': PropTypes.string,
}

export { Tab, SubTabs }
export default Tabs
