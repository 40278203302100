import trim from 'lodash/trim'

import { sendErrorOnce } from 'helpers/errorLogging.js'

export function modsToClassnames(componentName, mods) {
  mods = mods ?? {}

  try {
    return Object.keys(mods).map((group) => {
      if (mods[group] === true) {
        return `${componentName}_${group}`
      }

      return (mods[group] || '').split(' ').map((mod = '') => {
        mod = trim(mod)
        return mod.length > 0 ? `${componentName}_${group}_${mod}` : null
      })
    })
  } catch (error) {
    sendErrorOnce(error)

    return null
  }
}
