import React, { Fragment } from 'react'

import isNull from 'lodash/isNull'

import { useCallback, useMemo } from 'hooks'

import { getDateRangeLabel } from 'helpers/date'
import { format as formatMoney } from 'helpers/money'

import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import { ContentWithIconAndLink } from '../ContentWithIconAndLink'

import { SecuritySlicePointOfInterestTypes, type CombinedSecuritySlicePointOfInterest } from './index'

type SecuritySliceCombinedPointOfInterestContentProps = CombinedSecuritySlicePointOfInterest & {
  linkToTransactions: string | null
}

function SecuritySliceCombinedPointOfInterestContent({
  dateFrom,
  dateTo,
  data,
  linkToTransactions,
}: SecuritySliceCombinedPointOfInterestContentProps): React.ReactElement {
  const getSumOfType = useCallback(
    (type: SecuritySlicePointOfInterestTypes) => {
      const filtered = data.filter((dataItem) => dataItem.type === type)

      if (filtered.length < 1) {
        return null
      }

      return filtered.reduce((sum, dataItem) => +sum + parseFloat(dataItem.data.price), 0)
    },
    // optimised
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data)],
  )
  const bought = useMemo(() => getSumOfType(SecuritySlicePointOfInterestTypes.BUY), [getSumOfType])
  const sold = useMemo(() => getSumOfType(SecuritySlicePointOfInterestTypes.SELL), [getSumOfType])

  const content = useMemo(
    () => (
      <Fragment>
        <Typography size={14} lineHeight="small" weight="semibold" align="right" color="inherit">
          <Typo>{getDateRangeLabel(dateFrom, dateTo)}</Typo>
        </Typography>
        <Paper top={12}>
          <Stack vertical={12}>
            {!isNull(bought) && (
              <Stack vertical={4}>
                <Typography size={14} lineHeight="small" weight="semibold" align="right">
                  <Typo>Bought</Typo>
                </Typography>
                <Typography size={14} lineHeight="small" color="additional" align="right">
                  <Typo>-{formatMoney(bought, true, true)}</Typo>
                </Typography>
              </Stack>
            )}
            {!isNull(sold) && (
              <Stack vertical={4}>
                <Typography size={14} lineHeight="small" weight="semibold" align="right">
                  <Typo>Sold</Typo>
                </Typography>
                <Typography size={14} lineHeight="small" color="additional" align="right">
                  <Typo>+{formatMoney(sold, true, true)}</Typo>
                </Typography>
              </Stack>
            )}
          </Stack>
        </Paper>
      </Fragment>
    ),
    [dateFrom, dateTo, bought, sold],
  )

  if (linkToTransactions) {
    return <ContentWithIconAndLink to={linkToTransactions}>{content}</ContentWithIconAndLink>
  }

  return (
    <Paper top={12} right={12} bottom={12} left={12}>
      {content}
    </Paper>
  )
}

export { SecuritySliceCombinedPointOfInterestContent, type SecuritySliceCombinedPointOfInterestContentProps }
