import moment from 'helpers/date.js'
import { sendError } from 'helpers/errorLogging.js'
import { formLinkToHost } from 'helpers/formLinkToHost.js'

const raiseError = (name, value) => {
  sendError(`"value" passed to "${name}" rule does not appear to be a proper time format`, { value })
}

/** @return {boolean} */
export const bool = (value) => {
  if (value === 'true') {
    return true
  }

  if (value === 'false') {
    return false
  }

  return Boolean(value)
}

/** @return {?number} */
export const int = (value) => {
  if (value === null) {
    return null
  }

  const int = parseInt(value, 10)

  if (isNaN(int)) {
    raiseError('int', value)
  }

  return int
}

/** @return {?number} */
export const float = (value) => {
  if (value === null) {
    return null
  }

  const float = parseFloat(value)

  if (isNaN(float)) {
    raiseError('float', value)
  }

  return float
}

/** @return {?string} */
export const string = (value) => {
  if ((value ?? null) === null) {
    return null
  }

  const string = `${value}`

  if (string.includes('[object')) {
    raiseError('string', value)
  }

  return string
}

/** @return {?object} */
export const object = (value) => {
  if (value === null) {
    return null
  }

  if (Object.prototype.toString.call(value) === '[object Object]') {
    return value
  }

  raiseError('object', value)

  return null
}

/**
 * @return {?string}
 */
export const uri = (value) => {
  if (value === null) {
    return null
  }

  return formLinkToHost(value)
}

/** @return {?number} */
export const year = (value) => {
  if (value === null) {
    return null
  }

  const year = moment(`${value}`).year()

  if (isNaN(year)) {
    raiseError('year', value)
  }

  return year
}

/** @return {?string} */
export const iso8601UTC = (value) => {
  if (value === null) {
    return null
  }

  const utc = moment.utc(`${value}`).format()

  if (utc === 'Invalid date') {
    raiseError('iso8601UTC', value)
  }

  return utc
}
