export function isDisabledByRuleset({ field, rules, model }) {
  if (rules) {
    const ruleSet = rules.find((rs) => rs.fields.find((f) => f === field))

    if (!ruleSet) {
      return false
    }
    const condition = ruleSet.condition

    return [true, ...condition].reduce((memo, field) => {
      return memo && model[field]
    })
  } else {
    return false
  }
}

export function isFieldsDisabledByRuleset({ fields, rules, model }) {
  return [true, ...fields].reduce((memo, field) => {
    return memo && isDisabledByRuleset({ field, rules, model })
  })
}

export function isOneOfFieldsDisabledByRuleset({ fields, rules, model }) {
  return [false, ...fields].reduce((memo, field) => {
    return memo || isDisabledByRuleset({ field, rules, model })
  })
}
