import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useUnit } from 'effector-react'

import { combineErrors, backendErrorsToObj, validate, bulkValidate } from 'helpers/validation.js'
import axios from 'helpers/ajax'
import { typo } from 'helpers/typograph'

import { useMediaQueries } from 'hooks'

import JoinCards from 'components/molecules/JoinCards/JoinCards.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Width from 'components/_old/Width/Width'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Link from 'components/_old/Link/Link.jsx'
import InlineHelp from 'components/_old/InlineHelp/InlineHelp.jsx'
import ModalContent, {
  ModalContentHeadline,
  ModalContentBody,
  ModalContentButtons,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Form from 'components/_old/Form/Form.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label, { LabelText, LabelField } from 'components/_old/Label/Label.jsx'
import ErrorMessage from 'components/_old/ErrorMessage/ErrorMessage.jsx'
import Combobox from 'components/_old/Combobox/Combobox.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Delete from 'components/molecules/Delete/Delete.jsx'
import { AddressByPeriod } from 'components/organisms/AddressByPeriod'
import Button from 'components/_old/Button/Button.jsx'

import { $companyStore, changeCompanyAddressField, partialResetCompany, changeCompanyField } from 'app/effector/company'
import { $dictsStore } from 'app/effector/dicts'

const AboutBusinessForm = ({ wrapSubmitButton, onSubmit: handleSubmit }) => {
  const { company } = useUnit($companyStore)
  const [currentAddress] = company.addresses.getCurrentAddress()
  const { dicts } = useUnit($dictsStore)
  const { countries } = dicts

  const { desktop } = useMediaQueries()
  const addressByPeriodRef = useRef()
  const inputMods = { size: 'bigger' }

  const handleCompanyFieldChange = useCallback((field, value) => {
    changeCompanyField({ [field]: value })
  }, [])

  const handleCompanyAddressFieldChange = useCallback((fieldName, value) => {
    changeCompanyAddressField({ index: 0, field: { [fieldName]: value } })
  }, [])

  const handleCompanySearchChange = useCallback(
    async ({ value: company = {} } = {}) => {
      if (company.title) {
        handleCompanyFieldChange('name', company.title)
      } else {
        handleCompanyFieldChange('name', null)
      }

      if (company.number) {
        partialResetCompany()
        handleCompanyFieldChange('uk_number', company.number)

        const { data } = await axios.get(`companies_house/companies/${company.number}/`)

        if (data.address) {
          Object.keys(data.address).forEach((key) => {
            handleCompanyAddressFieldChange(key, data.address[key])
          })
          handleCompanyAddressFieldChange('is_current', true)

          if (addressByPeriodRef?.current) {
            const addressByPeriod = addressByPeriodRef.current
            addressByPeriod.handleSwitchToManualEditMode()
          }
        }

        if (data.business_types?.length) {
          handleCompanyFieldChange('business_types', data.business_types)
        }
      }
    },
    [handleCompanyFieldChange, handleCompanyAddressFieldChange],
  )

  const handleCompanySearchBlur = useCallback(
    (textValue) => {
      handleCompanyFieldChange('name', textValue)
    },
    [handleCompanyFieldChange],
  )

  const handleBusinessTypeSearchChange = useCallback(
    (index, { value: business_type = {} } = {}) => {
      const { business_types } = company
      const nextBusinessTypes = [...business_types]
      nextBusinessTypes[index] = business_type.code ? business_type : null

      handleCompanyFieldChange('business_types', nextBusinessTypes)
    },
    [company, handleCompanyFieldChange],
  )

  const handleBusinessTypeDelete = useCallback(
    (indexToDelete) => {
      const { business_types } = company
      const nextBusinessTypes = [...business_types].filter((_value, index) => index !== indexToDelete)

      handleCompanyFieldChange('business_types', nextBusinessTypes)
    },
    [company, handleCompanyFieldChange],
  )

  const handleAddBusinessType = useCallback(
    (event) => {
      event.preventDefault()
      const { business_types } = company
      handleCompanyFieldChange('business_types', [...business_types, null])
    },
    [company, handleCompanyFieldChange],
  )

  if (!company.addresses.hasAddresses()) {
    return null
  }

  const business_types = company.business_types.length < 1 ? [{}] : company.business_types
  const businessTypesValidation = business_types.reduce(
    (types, business_type, index) => ({
      ...types,
      [`${index}_business_type`]: {
        rules: [business_type?.code],
        errors: ['Type of business can’t be empty'],
      },
    }),
    {},
  )

  const validation = combineErrors(
    {
      name: { rules: [company.name], errors: ['Company name can’t be empty'] },
      uk_number: {
        rules: [company.uk_number, (company.uk_number ?? '').length >= 6],
        errors: ['Company number can’t be empty', 'Company number should be at least 6 characters long'],
      },
      ...businessTypesValidation,
      lei_number: {
        rules: [(company.lei_number ?? '').length === 0 || (company.lei_number ?? '').length === 20],
        errors: [typo('Legal entity identifier number should be 20 characters long')],
      },
      street: { rules: [currentAddress?.street], errors: ['Address can’t be empty'] },
      postcode: { rules: [currentAddress?.postcode], errors: ['Postcode can’t be empty'] },
      country: { rules: [currentAddress?.country], errors: ['Country can’t be empty'] },
      city: { rules: [currentAddress?.city], errors: ['City can’t be empty'] },
      isUK: { rules: [currentAddress?.isUK()], errors: [''] },
    },
    backendErrorsToObj(company.error),
  )

  const notUkWarningText = <Typo>You must be a UK resident to open an account with InvestEngine</Typo>

  const submitButton = (
    <Button
      type="submit"
      mods={{ size: 'big block' }}
      onClick={handleSubmit}
      disabled={!bulkValidate(validation)}
      tabIndex={6}
      data-test-id="aboutBusinessSubmitButton"
    >
      Continue
    </Button>
  )

  return (
    <SubmitOnEnter>
      <Form>
        <JoinCards>
          <Card
            mods={{
              theme: !desktop ? 'edge-to-edge transparent' : null,
              'no-padding': desktop ? null : 'top',
            }}
          >
            <Width size={desktop ? 22 : null}>
              <Validate rules={validation.name.rules}>
                <Label errorMessages={validation.name.errors} data-test-id="aboutBusinessNameLabel">
                  Company name
                  <LabelField>
                    <Combobox
                      name="search-company-by-name"
                      type="text"
                      remote="companies_house/companies/search/"
                      selectValue={(option) => {
                        option = option || {}

                        return {
                          value: option,
                          key: option.number,
                          name: option.title,
                        }
                      }}
                      mods={inputMods}
                      onChange={handleCompanySearchChange}
                      onBlur={handleCompanySearchBlur}
                      tabIndex={1}
                      data-test-id="aboutBusinessNameInput"
                    >
                      {typeof company.name === 'string'
                        ? { title: company.name, number: company.uk_number }
                        : company.name}
                    </Combobox>
                  </LabelField>
                </Label>
              </Validate>
              <Validate rules={validation.uk_number.rules}>
                <Label errorMessages={validation.uk_number.errors} data-test-id="aboutBusinessUkNumberLabel">
                  Company number
                  <Input
                    name="uk_number"
                    type="text"
                    mods={inputMods}
                    onChange={(_event, value) => {
                      if (typeof value === 'string') {
                        value = value.toUpperCase()
                      }

                      handleCompanyFieldChange('uk_number', value)
                    }}
                    tabIndex={2}
                    data-test-id="aboutBusinessUkNumberInput"
                  >
                    {company.uk_number}
                  </Input>
                </Label>
              </Validate>
              {business_types.map((business_type = {}, index) => {
                // TODO: Use key={business_type.uuid} after models become immutable
                return (
                  <Validate key={business_type.code || index} rules={validation[`${index}_business_type`].rules}>
                    <Label
                      errorMessages={validation[`${index}_business_type`].errors}
                      data-test-id={`aboutBusinessBusinessType${index}Label`}
                    >
                      Type of business
                      <LabelField>
                        <Delete onDelete={() => handleBusinessTypeDelete(index)} deletable={index > 0}>
                          <Combobox
                            name="business_type"
                            type="text"
                            remote="business_types/search/"
                            selectValue={(option) => {
                              option = option || {}

                              return {
                                value: option,
                                key: option.code,
                                name: option.title,
                              }
                            }}
                            mods={inputMods}
                            onChange={(value) => handleBusinessTypeSearchChange(index, value)}
                            tabIndex={3}
                            data-test-id={`aboutBusinessBusinessType${index}Input`}
                          >
                            {business_type}
                          </Combobox>
                        </Delete>
                      </LabelField>
                    </Label>
                  </Validate>
                )
              })}
              <Card mods={{ theme: 'transparent', 'no-padding': 'top left right', padding: 'small' }}>
                <Text smaller>
                  <Link onClick={handleAddBusinessType} data-test-id="aboutBusinessBusinessTypeAdd">
                    Add type
                  </Link>
                </Text>
              </Card>
              <Validate rules={validation.lei_number.rules}>
                {(isValid, brokenRule) => (
                  <Label
                    data-test-id="aboutBusinessLeiNumberLabel"
                    postfield={<Text muted>Can be provided later</Text>}
                  >
                    <LabelText>
                      <InlineHelp style={{ float: 'right' }} size={20}>
                        {(handleClose) => (
                          <ModalContent data-test-id="aboutBusinessLeiNumberInfoContent">
                            <ModalContentHeadline>Legal entity identifiers (LEIs)</ModalContentHeadline>
                            <ModalContentBody>
                              <Typo>
                                The Legal entity identifier (LEI) is a unique global identifier for legal entities
                                participating in financial transactions. All companies which buy and sell securities on
                                financial markets are required to have an LEI.
                                <br />
                                <br />
                                If your company does not have an LEI, InvestEngine will provide one free of charge for
                                the first year of your investment with us.
                              </Typo>
                            </ModalContentBody>
                            <ModalContentButtons
                              text="OK"
                              onClick={handleClose}
                              data-test-id="aboutBusinessLeiNumberInfoClose"
                            />
                          </ModalContent>
                        )}
                      </InlineHelp>
                      {(() => {
                        if (isValid) {
                          return (
                            <Text data-test-id="aboutBusinessLeiNumberLabelText">
                              Legal entity identifier (LEI) number
                            </Text>
                          )
                        }

                        const errorMessage = validation.lei_number.errors[brokenRule]

                        return <ErrorMessage>{errorMessage}</ErrorMessage>
                      })()}
                    </LabelText>
                    <LabelField>
                      <Input
                        name="lei_number"
                        type="masked"
                        subtype="text"
                        mask="********************"
                        maskChar=""
                        placeholder=""
                        mods={inputMods}
                        onChange={(_event, value) =>
                          // value = "" isn't accepted by the server, it should be null
                          handleCompanyFieldChange('lei_number', value ? value.toUpperCase() : null)
                        }
                        tabIndex={4}
                        data-test-id="aboutBusinessLeiNumberInput"
                      >
                        {company.lei_number}
                      </Input>
                    </LabelField>
                  </Label>
                )}
              </Validate>
            </Width>
          </Card>
          <Card
            mods={{
              theme: !desktop ? 'edge-to-edge transparent' : null,
              'no-padding': desktop ? null : 'top',
            }}
          >
            <Card
              mods={{
                theme: 'transparent',
                'no-padding': 'top right left',
              }}
            >
              <Text smaller>Registered office address</Text>
            </Card>
            <Width size={desktop ? 22 : null}>
              <AddressByPeriod
                ref={addressByPeriodRef}
                inputMods={inputMods}
                cardMods={{ theme: 'transparent', 'no-padding': 'all' }}
                address={currentAddress}
                onAddressFieldChange={handleCompanyAddressFieldChange}
                validation={validation}
                countries={countries}
                notUkWarningText={notUkWarningText}
                withPeriodsSelect={false}
                noPaddingBottom
                manualSkipWaitBlur={
                  company.business_types.length > 0 && !validate(validation.country.rules) ? 'country' : false
                }
              />
              {wrapSubmitButton(submitButton)}
            </Width>
          </Card>
        </JoinCards>
      </Form>
    </SubmitOnEnter>
  )
}

AboutBusinessForm.propTypes = {
  wrapSubmitButton: PropTypes.node,
  onSubmit: PropTypes.func,
}

export default AboutBusinessForm
