import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import Width from 'components/_old/Width/Width'
import Headline from 'components/_old/Headline/Headline.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Text from 'components/_old/Text/Text.jsx'
import Card from 'components/_old/Card/Card.jsx'
import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'
import Button from 'components/_old/Button/Button.jsx'

const getTextModByName = (name) => (name ? { [name]: true } : {})

const ModalContentHeadline = ({
  children,
  className,
  level,
  align,
  icon,
  paddingRight,
  'data-test-id': dataTestId,
}) => {
  const { desktop } = useMediaQueries()

  level = level || (desktop ? 1 : 2)
  align = align || (desktop ? 'center' : 'left')
  paddingRight = (() => {
    if (typeof paddingRight !== 'undefined') {
      return paddingRight
    }
    return !desktop
  })()

  if (typeof children === 'string') {
    return (
      <Text {...getTextModByName(align)} block padding-right_2={paddingRight} data-test-id={dataTestId}>
        <Headline className={className} level={level}>
          {icon && <AllCenter>{icon}</AllCenter>}
          <Typo>{children}</Typo>
        </Headline>
      </Text>
    )
  }

  return children
}

ModalContentHeadline.propTypes = {
  children: PropTypes.node,
  level: PropTypes.number,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  icon: PropTypes.element,
  paddingRight: PropTypes.bool,
  'data-test-id': PropTypes.string,
}

const ModalContentBody = ({ children, withCard, size, align, 'data-test-id': dataTestId }) => {
  size = size || size === null ? size : 'small'
  align = align || align === null ? align : 'left'

  if (typeof children === 'string') {
    children = <Typo>{children}</Typo>
  }

  if (withCard) {
    children = <Card mods={{ ...withCard, theme: 'transparent' }}>{children}</Card>
  }

  return (
    <Text {...getTextModByName(size)} {...getTextModByName(align)} block data-test-id={dataTestId}>
      {children}
    </Text>
  )
}

ModalContentBody.propTypes = {
  children: PropTypes.node,
  withCard: PropTypes.object,
  size: PropTypes.oneOf(['biggest', 'big', 'bigger', null, 'small', 'smaller', 'extraSmall', 'superSmall']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  paddingRight: PropTypes.bool,
  'data-test-id': PropTypes.string,
}

const ModalContentButtons = ({
  children,
  targetGatewayName,
  className,
  width,
  text,
  mods,
  phantomButtonsMarginSize,
  isNewModal,
  onClick,
  'data-test-id': dataTestId,
}) => {
  const { desktop } = useMediaQueries()
  let element

  width = width || width === null ? width : 12

  if (children) {
    element = children
  }

  if (text) {
    element = (
      <Button
        className={className}
        mods={{
          ...mods,
          size: 'big block',
          theme: desktop || isNewModal ? null : 'not-rounded',
        }}
        block
        onClick={onClick}
        data-test-id={dataTestId}
      >
        {text}
      </Button>
    )
  }

  if (element) {
    return (
      <Card
        className={className}
        mods={{
          text: 'center',
          theme: 'transparent',
          padding: 'big',
          'no-padding': desktop ? 'left right' : 'all',
        }}
      >
        <Width size={width} center>
          <StickedIfNotDesktop
            into={isNewModal ? 'insideNewModal' : 'insideModal'}
            targetGatewayName={targetGatewayName}
            phantomButtonsMarginSize={phantomButtonsMarginSize}
          >
            {element}
          </StickedIfNotDesktop>
        </Width>
      </Card>
    )
  }
}

ModalContentButtons.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  targetGatewayName: PropTypes.string,
  width: PropTypes.number,
  text: PropTypes.string,
  mods: PropTypes.object,
  phantomButtonsMarginSize: PropTypes.oneOf([null, undefined, 'zero', 'small']),
  onClick: PropTypes.func,
  'data-test-id': PropTypes.string,
}

const ModalContent = ({ children, width, wrapWith, style, 'data-test-id': dataTestId }) => {
  const { desktop } = useMediaQueries()
  width = width || width === null ? width : desktop ? 36 : null

  if (wrapWith) {
    children = wrapWith(children)
  }

  if (desktop) {
    return (
      <Width size={width} style={style} data-test-id={dataTestId}>
        {children}
      </Width>
    )
  }

  return <div data-test-id={dataTestId}>{children}</div>
}

ModalContent.propTypes = {
  children: PropTypes.node,
  width: PropTypes.number,
  wrapWith: PropTypes.func,
  style: PropTypes.object,
  'data-test-id': PropTypes.string,
}

export { ModalContentHeadline, ModalContentBody, ModalContentButtons }
export default ModalContent
