import React from 'react'

import { Typo } from 'components/_old/Typo/Typo'

import { Typography } from 'components/atoms/Typography'

import type { Frequency } from 'app/pages/Dashboard/Goals/RecurringPayment/types'

import { useSavingsPlanProgress } from './useSavingsPlanProgress'

import type { SavingsPlanStep } from '../../hooks/useSavingsPlanSetup'

import './SavingsPlanProgress.css'

type SavingsPlanProgressProps = {
  step: SavingsPlanStep
  frequency: Frequency | null
  startDay: string | null
  monthlyPaymentDay: number | null
}

const SavingsPlanProgress = ({
  step,
  frequency,
  startDay,
  monthlyPaymentDay,
}: SavingsPlanProgressProps): React.ReactElement => {
  const { firstStepText, secondStepText, isSecondStepActive, isThirdStepActive } = useSavingsPlanProgress({
    step,
    frequency,
    startDay,
    monthlyPaymentDay,
  })

  return (
    <Typography size={12} align="center" color="white" data-test-id="savingsPlanModalProgress">
      <Typo>
        <span>{firstStepText}</span>
        <span className={isSecondStepActive ? 'SavingsPlanProgress_Active' : 'SavingsPlanProgress'}>
          {' '}
          — {secondStepText}
        </span>
        <span className={isThirdStepActive ? 'SavingsPlanProgress_Active' : 'SavingsPlanProgress'}> — Amount</span>
      </Typo>
    </Typography>
  )
}

export { SavingsPlanProgress }
