import { useCallback, useMemo } from 'hooks'

import moment from 'helpers/date.js'

import type { IncomeLineDataItem } from 'components/organisms/charts/parts'

import { PERIODS } from 'app/pages/Securities/hooks'

type UsePeriodDataInterface = {
  totalMin: number
  totalMax: number
  periodLineData: IncomeLineDataItem[]
  periodMin: number
  periodMax: number
}

const usePeriodData = (period: string, data?: IncomeLineDataItem[]): UsePeriodDataInterface => {
  if (!data) {
    data = []
  }

  const getDataAfterDate = useCallback(
    (data, limitDate) => data.filter(({ date }) => moment(date).isSameOrAfter(limitDate, 'day')),
    [],
  )

  const dailyLineData = useMemo(
    () =>
      [...(data ?? [])].map((day) => ({
        date: moment(day.date).toDate(),
        value: day.value,
      })),
    // more elaborate caching
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  const totalPrices = useMemo(() => dailyLineData.map(({ value }) => value), [dailyLineData])
  const totalMin = useMemo(() => Math.min(...totalPrices), [totalPrices])
  const totalMax = useMemo(() => Math.max(...totalPrices), [totalPrices])

  const periodLineData = useMemo(() => {
    if (period === PERIODS.MAX) {
      return dailyLineData
    }

    if (period === PERIODS.YEAR) {
      const limit = moment(new Date()).subtract('1', 'year')

      return getDataAfterDate(dailyLineData, limit)
    }

    if (period === PERIODS.SIX_MONTHS) {
      const limit = moment(new Date()).subtract('6', 'month')

      return getDataAfterDate(dailyLineData, limit)
    }

    if (period === PERIODS.MONTH) {
      const limit = moment(new Date()).subtract('1', 'month')

      return getDataAfterDate(dailyLineData, limit)
    }

    if (period === PERIODS.WEEK) {
      const limit = moment(new Date()).subtract('1', 'week')

      return getDataAfterDate(dailyLineData, limit)
    }

    return dailyLineData
  }, [period, dailyLineData, getDataAfterDate])

  const periodPrices = useMemo(() => periodLineData.map(({ value }) => value), [periodLineData])
  const periodMin = useMemo(() => Math.min(...periodPrices), [periodPrices])
  const periodMax = useMemo(() => Math.max(...periodPrices), [periodPrices])

  return {
    totalMin,
    totalMax,
    periodLineData,
    periodMin,
    periodMax,
  }
}

export { usePeriodData, type UsePeriodDataInterface }
