import React, { type ReactNode } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type OptionsNavigationBarProps = {
  children?: ReactNode
  leftPartText?: string
  disableLeftPart?: boolean
  onLeftPartClick?: () => void
  rightPartText?: string
  disableRightPart?: boolean
  onRightPartClick?: () => void
  linkStyle?: string
}

function OptionsNavigationBar({
  children: childrenProp,
  leftPartText,
  disableLeftPart,
  onLeftPartClick,
  rightPartText,
  disableRightPart,
  onRightPartClick,
  linkStyle,
}: OptionsNavigationBarProps): React.ReactElement {
  const { desktop } = useMediaQueries()

  const children = useMemo(() => {
    if (desktop) {
      return (
        <Typography size={20} lineHeight="small" weight="semibold">
          {childrenProp}
        </Typography>
      )
    }

    return childrenProp
  }, [childrenProp, desktop])

  const navigationBar = useMemo(
    () => (
      <NavigationBar
        leftPartText={leftPartText}
        disableLeftPart={disableLeftPart}
        onLeftPartClick={onLeftPartClick}
        rightPartText={rightPartText}
        disableRightPart={disableRightPart}
        onRightPartClick={onRightPartClick}
        linkStyle={linkStyle}
        plain
      >
        {children}
      </NavigationBar>
    ),
    [
      leftPartText,
      disableLeftPart,
      onLeftPartClick,
      rightPartText,
      disableRightPart,
      onRightPartClick,
      children,
      linkStyle,
    ],
  )

  if (desktop) {
    return (
      <Paper top={20} right={24} bottom={20} left={24}>
        {navigationBar}
      </Paper>
    )
  }

  return navigationBar
}

export { OptionsNavigationBar, type OptionsNavigationBarProps }
