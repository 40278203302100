import React from 'react'
import PropTypes from 'prop-types'

import Validate from 'components/_old/Validate/Validate.jsx'
import Input from 'components/_old/Input/Input.jsx'
import { Gateway } from 'components/atoms/Gateway'
import ErrorMessage from '../../../components/ErrorMessage.jsx'

const YearsOldInput = ({ value, handleChange, validation }) => {
  return (
    <Validate rules={validation?.years_old?.rules}>
      {(isValid, brokenRule) => {
        return (
          <span>
            <Input
              type="number"
              min={0}
              max={99}
              size={2}
              inline
              onChange={handleChange}
              mods={{ size: 'unpadded', color: 'blue_to_white' }}
              valid={isValid}
              tabIndex={1}
              data-test-id="tunnelStepGetStartedInputAge"
            >
              {value === 0 ? null : value}
            </Input>
            {isValid ? null : (
              <Gateway into="text-question-error">
                <ErrorMessage error={validation?.years_old?.errors[brokenRule]} onTop={true} />
              </Gateway>
            )}
          </span>
        )
      }}
    </Validate>
  )
}

YearsOldInput.propTypes = {
  value: PropTypes.number,
  validation: PropTypes.object,
  handleChange: PropTypes.func,
}

export default YearsOldInput
