import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks/'
import { useSetUpInstantBank } from './hooks/'

import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Width from 'components/_old/Width/Width'
import Segment from 'components/atoms/Segment/Segment.jsx'
import { Gateway } from 'components/atoms/Gateway'
import { Preloader } from 'components/molecules/Preloader'
import { Typo } from 'components/_old/Typo/Typo'

const SetUpInstantBank = ({ goal, location, tunnelQuery, nextUrl, handleClose, toggleCloseButton }) => {
  const { desktop } = useMediaQueries()
  const { isLoading, handleBack, handleSetUpBank } = useSetUpInstantBank({
    portfolio: goal,
    location,
    tunnelQuery,
    nextUrl,
    toggleCloseButton,
  })

  return (
    <Width size={65}>
      <OptionsNavigationBar
        leftPartText="Back"
        onLeftPartClick={!!goal && handleBack}
        rightPartText="Close"
        onRightPartClick={handleClose}
      >
        &nbsp;
      </OptionsNavigationBar>
      <Card mods={{ theme: 'transparent', 'no-padding': 'right left' }}>
        <Width size={40} center>
          <Inner>
            <Width size={11} center>
              <Text block center>
                <Icon
                  className="SetUpInstantBank-Icon"
                  type="transfer"
                  style={{
                    marginTop: desktop ? -4 : 44,
                    marginBottom: desktop ? -12 : 0,
                  }}
                  inline
                />
              </Text>
            </Width>
            <Headline level={desktop ? 1 : 2} mods={{ text: 'center' }}>
              How Open Banking works
            </Headline>
            <Segment mods={{ margin: 'bigger', noMargin: 'top' }}>
              <Width size={!desktop ? 20 : null} center>
                <Text block center>
                  <Typo>
                    We offer Open Banking, enabling you to conveniently fund your InvestEngine account in seconds. This
                    provides a secure link to your banking app or website for you to authorise instant transfers. We
                    have partnered with TrueLayer, an FCA-authorised payment institution to offer this service. We need
                    your permission to enable this transfer facility.
                  </Typo>
                </Text>
              </Width>
            </Segment>
            <Segment mods={{ margin: 'hefty', noMargin: 'top' }}>
              <ModalContentButtons width={desktop ? 20 : 24} isNewModal>
                <Button mods={{ size: 'big block' }} onClick={handleSetUpBank}>
                  Set up Open Banking
                </Button>
              </ModalContentButtons>
            </Segment>
          </Inner>
        </Width>
      </Card>
      <Gateway into="newModalPreloader">
        <Preloader loading={isLoading} size="big" zIndex />
      </Gateway>
    </Width>
  )
}

SetUpInstantBank.propTypes = {
  goal: PropTypes.object,
  location: PropTypes.shape({
    query: PropTypes.shape({
      back: PropTypes.string,
    }),
  }),
  tunnelQuery: PropTypes.object,
  nextUrl: PropTypes.string,
  handleClose: PropTypes.func,
  toggleCloseButton: PropTypes.func,
}

export { SetUpInstantBank }
