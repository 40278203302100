import { createStore, createEvent, sample } from 'effector'

import { bulkValidate } from 'helpers/validation.js'

import { fetchIsaProvidersFx, $isaTransferProviderList } from 'app/effector/isa-transfer'

import { IsaTransferPrefillFormData, type FormField, type FormValue } from './IsaTransferPrefillFormData'

import { TransferCurrentYear, TransferPreviousYear, TransferType } from 'constants/transferIsa'

// Events
export const init = createEvent()
export const setField = createEvent<{ field: FormField; value: FormValue }>()
export const resetForm = createEvent()
export const enableForceValidation = createEvent()

// Stores
export const $form = createStore(new IsaTransferPrefillFormData({}))
export const $forceValidationEnabled = createStore<boolean>(false)

// Store updates
$form.on(setField, (state, { field, value }) => state.set(field, value))
$form.reset(resetForm)
$forceValidationEnabled.on(enableForceValidation, () => true).reset(resetForm)

// Reset form and fetch providers on form init
sample({
  clock: init,
  target: [resetForm, fetchIsaProvidersFx],
})

// Validation
export const $validation = $form.map((form) => {
  const isTransferForCurrentYearSelected = form.transfer_current_year === TransferCurrentYear.YES
  const isTransferForPreviousYearsSelected = form.transfer_previous_years !== TransferPreviousYear.NO
  const isPreviousYearPartTransfer = form.transfer_previous_years === TransferPreviousYear.PART
  const previousYearsAmount = parseFloat(form.previous_years_amount ?? '0')
  const isFullTransfer = form.transfer_type === TransferType.FULL
  const isPartialTransfer = form.transfer_type === TransferType.PARTIAL

  return {
    isa_provider_id: {
      rules: [form.isa_provider_id !== null],
      errors: ['Please select a provider or enter a custom one'],
    },
    isa_provider_name: {
      rules: [!!form.isa_provider_name || form.isa_provider_id !== 'other'],
      errors: ['Isa provider name cannot be empty'],
    },
    isa_account: {
      rules: [!!form.isa_account],
      errors: ['ISA account cannot be empty'],
    },
    transfer_type: {
      rules: [form.transfer_type !== null],
      errors: ['Please select an option'],
    },
    previous_years_amount: {
      rules: [!isPreviousYearPartTransfer || isFullTransfer || previousYearsAmount > 0],
      errors: ['Please enter a value'],
    },
    isa_transfer_details: {
      rules: [isTransferForCurrentYearSelected || isTransferForPreviousYearsSelected || !isPartialTransfer],
      errors: [`To continue, please select the portion of your ISA you would like to transfer`],
    },
  }
})

// Overall form validity
export const $isValid = $validation.map((validation) => bulkValidate(validation))

// Provider options from the provider list
export const $isaProviderOptions = $isaTransferProviderList.map((providerList) =>
  providerList.map((provider) => ({
    value: provider.id ?? 'other',
    name: provider.name,
  })),
)
