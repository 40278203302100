import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import rawMediaQueries from 'helpers/mediaQueries.js'
import compose from 'helpers/compose.js'
import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'

import withMediaQueries from 'decorators/withMediaQueries/withMediaQueries.jsx'

import ChangeAddressForm from 'app/pages/Forms/ChangeAddressForm.jsx'

import Modal from 'components/_old/Modal/Modal.jsx'
import ModalContent, {
  ModalContentHeadline,
  ModalContentBody,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Button from 'components/_old/Button/Button.jsx'
import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'
import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'

import { states as clientStates } from 'constants/client'

import { showSuccessToast } from 'app/redux/actions/ui'
import { AddressList } from 'app/redux/models/address'
import { Contact } from 'app/effector/contacts/models'

import { updateOrCreateContactFx } from 'app/effector/contacts'
import { ApiError } from 'app/redux/models/errors'

class ChangeAddress extends React.PureComponent {
  state = null

  resetNewAddress = () => {
    this.setState(null)
  }

  syncNewAddress = () => {
    const { owner } = this.props
    const [currentAddress] = owner?.addresses?.getCurrentAddress()
    const { id, ...currentAddressData } = currentAddress
    this.setState({ ...currentAddressData, changed: false })
  }

  handleAddressFieldChange = (_index, field, value) => {
    const { changed } = this.state

    if (!changed) {
      this.setState({
        changed: true,
        months_at_address: 0,
      })
    }

    this.setState({ [field]: value })
  }

  handleSubmit = async () => {
    const { onClose: handleClose, owner } = this.props

    const ownerId = owner.id

    try {
      const result = await updateOrCreateContactFx({
        id: ownerId,
        keys: ['addresses'],
        data: {
          addresses: [this.state],
        },
        saveAddressesAsABulk: false,
      })

      if (result instanceof ApiError) {
        throw result
      }

      showSuccessToast('Address successfully updated')
      handleClose()
    } catch (error) {
      processError({
        error,
        forceReset: true,
      })
    }

    trackEvent({ category: 'Settings', action: 'Address changed' })
  }

  componentDidUpdate({ isOpen: prevIsOpen }) {
    const { isOpen } = this.props

    if (!prevIsOpen && isOpen) {
      this.syncNewAddress()
    }

    if (prevIsOpen && !isOpen) {
      this.resetNewAddress()
    }
  }

  componentDidMount() {
    const { isOpen } = this.props

    if (isOpen) {
      this.syncNewAddress()
    }
  }

  renderButtons = (isFieldsValid) => {
    const { onClose: handleClose, mediaQueries } = this.props
    const { desktop } = mediaQueries

    return (
      <Card
        mods={{
          text: 'center',
          theme: 'transparent',
          'no-padding': desktop ? 'bottom' : 'all',
        }}
      >
        <Width size={null} center>
          <StickedIfNotDesktop into="insideModal">
            <ButtonGroup>
              <Button mods={{ theme: 'simple-reverse-blue' }} onClick={handleClose} data-test-id="addressCancelButton">
                Cancel
              </Button>
              <Button onClick={this.handleSubmit} disabled={!isFieldsValid} data-test-id="addressSaveBtn">
                Change
              </Button>
            </ButtonGroup>
          </StickedIfNotDesktop>
        </Width>
      </Card>
    )
  }

  render() {
    const { allowEmptyMonthsAtAddress, inputMods, onClose: handleClose, routes, mediaQueries } = this.props
    const newAddress = this.state
    const { desktop } = mediaQueries

    const cardMods = {
      theme: !desktop ? 'edge-to-edge transparent' : null,
      'no-padding': desktop ? null : 'top',
    }

    if (!newAddress) {
      return null
    }

    const visibleAddressesList = AddressList.createFromObject({ list: [newAddress] })

    const notUkWarningText = <Typo>You must be a UK resident to open an account with InvestEngine</Typo>

    // TODO: make with ModalContent
    return (
      <Modal
        open={routes[routes.length - 1].module === 'change-address'}
        onClose={handleClose}
        data-test-id="changeAddressModal"
        gatewayName="change-address"
      >
        <ModalContent
          width={30}
          wrapWith={(innerContent) => <div style={{ minHeight: desktop ? '25rem' : null }}>{innerContent}</div>}
        >
          <ModalContentHeadline>Edit address</ModalContentHeadline>
          <ModalContentBody size={null}>
            <Card mods={cardMods}>
              <ChangeAddressForm
                visibleAddressesList={visibleAddressesList}
                inputMods={inputMods}
                renderButtons={this.renderButtons}
                notUkWarningText={notUkWarningText}
                onAddressFieldChange={this.handleAddressFieldChange}
                allowEmptyMonthsAtAddress={allowEmptyMonthsAtAddress}
                withPeriodsSelect
              />
            </Card>
          </ModalContentBody>
        </ModalContent>
      </Modal>
    )
  }
}

ChangeAddress.propTypes = {
  owner: PropTypes.instanceOf(Contact),
  isOpen: PropTypes.bool,
  mediaQueries: PropTypes.object,
  allowEmptyMonthsAtAddress: PropTypes.bool,
  inputMods: PropTypes.object,
  routes: PropTypes.arrayOf(PropTypes.object),

  onClose: PropTypes.func,
}

export default compose(
  withRouter,
  withMediaQueries(rawMediaQueries),
  connect(({ client } = {}, { routes } = {}) => {
    const isOpen = routes[routes.length - 1].module === 'change-address'
    const allowEmptyMonthsAtAddress = client.state !== clientStates.NEW

    return {
      isOpen,
      allowEmptyMonthsAtAddress,
    }
  }),
)(ChangeAddress)
