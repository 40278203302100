import axios from 'helpers/ajax'

type UploadDocumentInterface = {
  file: File
  type: string
  requestToken: string
}

const uploadDocument = async ({ file, type, requestToken }: UploadDocumentInterface): Promise<{ id: number }> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', type)

  const { data } = await axios({
    url: `client_documents/${requestToken}/documents`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })

  return data
}

type SubmitDocumentsInterface = {
  documentIds: number[]
  requestToken: string
}

const submitDocuments = async ({ documentIds, requestToken }: SubmitDocumentsInterface): Promise<undefined> => {
  const { data } = await axios.post(`client_documents/${requestToken}/submissions`, { documents: documentIds })

  return data
}

export { uploadDocument, submitDocuments }
