import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import isFunction from 'lodash/isFunction'

import { useCallback, useRef } from 'hooks'

import { goTo } from 'helpers/router.js'
import { sendError } from 'helpers/errorLogging.js'
import { modsToClassnames } from 'helpers/classname.js'
import { trackEvent } from 'helpers/analytics'

import './Link.css'

const Link = (
  {
    children,
    classnameless,
    className,
    mods,
    active,
    to,
    hard,
    blank,
    underline,
    noUnderline,
    disabled,
    analyticsEvent,
    replace = false,
    scrollToTop = true,
    stopPropagation = false,
    onClick,
    ...rest
  },
  ref,
) => {
  const tag = !to || disabled ? 'span' : 'a'
  const element = useRef(ref)

  const classes = classNames(className, modsToClassnames('Link', mods), {
    Link: !classnameless,
    Link_active: !classnameless && active,
    Link_underline: underline,
    'Link_no-underline': noUnderline || disabled,
    Link_disabled: disabled,
  })

  const handleClick = useCallback(
    (...args) => {
      if (disabled) {
        return null
      }

      if (analyticsEvent) {
        trackEvent(analyticsEvent)
      }

      if (isFunction(onClick)) {
        onClick(...args)
      }

      if (to && !hard) {
        const event = args[0]

        // Fixes links clicked with ctrl or cmd but opened in a same tab
        if (event?.ctrlKey || event?.metaKey) {
          window.open(url, '_blank')

          return
        }

        if (event?.preventDefault) {
          event.preventDefault()
        }

        if (stopPropagation && event?.stopPropagation) {
          event.stopPropagation()
        }

        if (blank) {
          let url = to

          if (!/^https?:/.test(url)) {
            url = `${CONFIG.HOST}/${url}`.replace(/\/\//g, '')
          }

          window.open(url, '_blank')

          return
        }

        goTo(to, { replace, scrollToTop })
      }
    },
    [disabled, analyticsEvent, onClick, to, hard, blank, replace, scrollToTop],
  )

  const handleKeyPress = useCallback(
    (event) => {
      if (!to && !onClick) {
        return null
      }

      try {
        if (event.charCode === 32 || event.charCode === 13) {
          if (element && element.current) {
            element.current?.click?.()
          }
        }
      } catch (error) {
        sendError(error)
      }
    },
    [to, onClick, element],
  )

  const props = {
    ref: element,
    className: classes,
    href: to,
    target: blank ? '_blank' : null,
    onKeyPress: handleKeyPress,
    onClick: handleClick,
    'data-analytics-category': analyticsEvent && analyticsEvent.category,
    'data-analytics-action': analyticsEvent && analyticsEvent.action,
    'data-analytics-label': analyticsEvent && analyticsEvent.label,
    'data-active': !classnameless && active,
    tabIndex: 0,
    'aria-disabled': disabled,
    ...rest,
  }

  return React.createElement(tag, props, children)
}

const LinkWithForwardedRef = forwardRef(Link)

LinkWithForwardedRef.propTypes = {
  children: PropTypes.node,
  classnameless: PropTypes.bool,
  className: PropTypes.string,
  mods: PropTypes.object,
  active: PropTypes.bool,
  to: PropTypes.string,
  hard: PropTypes.bool,
  blank: PropTypes.bool,
  underline: PropTypes.bool,
  noUnderline: PropTypes.bool,
  disabled: PropTypes.bool,
  analyticsEvent: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string,
  }),
  replace: PropTypes.bool,
  scrollToTop: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  onClick: PropTypes.func,
}

export default LinkWithForwardedRef
