import React from 'react'

import classNames from 'classnames/dedupe'

import './Nobr.css'

type NobrProps = {
  children: React.ReactNode | React.ReactNodeArray
  className?: string
}

function Nobr({ children, className }: NobrProps): React.ReactElement {
  const classes = classNames('Nobr', className)

  return <span className={classes}>{children}</span>
}

export { Nobr }
