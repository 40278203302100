import React from 'react'
import { findDOMNode } from 'react-dom'

export default class SubmitOnEnter extends React.Component {
  handleKeypress = (event) => {
    if (event.keyCode === 13) {
      const wrapperNode = findDOMNode(this)
      const eventCallerNode = event.srcElement

      if (wrapperNode.contains(eventCallerNode)) {
        const submitNode = wrapperNode.querySelector('[type="submit"]')

        if (submitNode) {
          submitNode.click()
        }
      }
    }
  }

  componentDidMount() {
    window.addEventListener('keypress', this.handleKeypress)
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.handleKeypress)
  }

  render() {
    return this.props.children
  }
}
