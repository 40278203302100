import React, { forwardRef, useState, useMemo, useCallback, useImperativeHandle } from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMediaQueries } from 'hooks'

import { bulkValidate } from 'helpers/validation.js'

import Card from 'components/_old/Card/Card.jsx'
import { Fieldset } from 'components/_old/Form/Form.jsx'
import { Typo } from 'components/_old/Typo'

import { AddressManualEdit } from 'components/atoms/AddressManualEdit'
import { AddressPeriodSelect } from 'components/atoms/AddressPeriodSelect'
import { AddressSearch } from 'components/atoms/AddressSearch'
import { Typography } from 'components/atoms/Typography'

import './AddressByPeriod.css'

const MODE_SEARCH = 'MODE_SEARCH'
const MODE_MANUAL_EDIT = 'MODE_MANUAL_EDIT'

type ValidationProp = {
  errors: string[]
  rules: string[] | number[] | boolean[]
}

type Country = {
  country: string
  country_id: number
}

type AddressByPeriodProps = {
  address: {
    street: string
    street2: string
    postcode: string
    country: number
    countryFull: Country
    city: string
    months_at_address: number | null
  }
  validation: {
    city: ValidationProp
    country: ValidationProp
    isUK: ValidationProp
    months_at_address: ValidationProp
    postcode: ValidationProp
    street: ValidationProp
  }
  mode: 'MODE_SEARCH' | 'MODE_MANUAL_EDIT'
  infoVisible: boolean
  withPeriodsSelect: boolean
  manualSkipWaitBlur: string[] | string
  allowEmptyMonthsAtAddress: boolean
  noPaddingBottom: boolean
  cardMods: Record<string, string>
  inputMods: Record<string, string>
  countries: Country[]
  notUkWarningText: object
  clearOnSwitchToSearchMode: boolean
  onAddressFieldChange: (field: string, value: string | object | number | null) => void
}

const AddressByPeriod = forwardRef(function AddressByPeriod(
  {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address: { street, months_at_address },
    address,
    validation,
    countries = [],
    mode: modeProp,

    infoVisible,
    withPeriodsSelect,
    manualSkipWaitBlur,
    allowEmptyMonthsAtAddress,
    clearOnSwitchToSearchMode,

    notUkWarningText,

    noPaddingBottom,
    cardMods,
    inputMods,

    onAddressFieldChange: handleAddressFieldChange,
  }: AddressByPeriodProps,
  ref,
) {
  const [mode, setMode] = useState(modeProp ?? (street === null ? MODE_SEARCH : MODE_MANUAL_EDIT))
  const mediaQueries = useMediaQueries()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { months_at_address: months_at_addressValidation, ...partialValidation } = validation
  const isPeriodSelectVisible = withPeriodsSelect && (bulkValidate(partialValidation) || months_at_address !== null)

  const classes = classNames(
    'AddressByPeriod',
    { AddressByPeriod_noPaddingBottom: noPaddingBottom },
    getMediaQuieryClasses('AddressByPeriod', mediaQueries),
  )

  address.countryFull = useMemo(
    () => countries.find((country) => country.country_id === address.country),
    [countries, address.country],
  )

  const handleSwitchToSearchMode = useCallback(() => {
    setMode(MODE_SEARCH)
  }, [])
  const handleSwitchToManualEditMode = useCallback(() => {
    setMode(MODE_MANUAL_EDIT)
  }, [])

  useImperativeHandle(ref, () => ({
    handleSwitchToSearchMode,
    handleSwitchToManualEditMode,
  }))

  return (
    <div className={classes}>
      <Card mods={cardMods || { theme: 'transparent', 'no-padding': 'all' }}>
        <Fieldset>
          {infoVisible && (
            <Typography align="center" size={12} className="AddressByPeriod-InfoText">
              <Typo>Please provide previous addresses to cover the last 3 years:</Typo>
            </Typography>
          )}
          {mode === MODE_SEARCH && (
            <AddressSearch
              address={address}
              countries={countries}
              inputMods={inputMods}
              mediaQueries={mediaQueries}
              onAddressFieldChange={handleAddressFieldChange}
              onSwitchToManualEditMode={handleSwitchToManualEditMode}
            />
          )}
          {mode === MODE_MANUAL_EDIT && (
            <AddressManualEdit
              address={address}
              validation={validation}
              countries={countries}
              inputMods={inputMods}
              notUkWarningText={notUkWarningText}
              clearOnSwitchToSearchMode={clearOnSwitchToSearchMode}
              onAddressFieldChange={handleAddressFieldChange}
              onSwitchToSearchMode={handleSwitchToSearchMode}
              skipWaitBlur={manualSkipWaitBlur}
            />
          )}
          {isPeriodSelectVisible && (
            <AddressPeriodSelect
              value={months_at_address}
              validation={validation}
              inputMods={inputMods}
              mediaQueries={mediaQueries}
              allowEmptyMonthsAtAddress={allowEmptyMonthsAtAddress}
              onAddressFieldChange={handleAddressFieldChange}
            />
          )}
        </Fieldset>
      </Card>
    </div>
  )
})

export { AddressByPeriod, MODE_SEARCH, MODE_MANUAL_EDIT }
