import React from 'react'
import PropTypes from 'prop-types'

import { useUnit } from 'effector-react'
import { useCallback, useContext, useMediaQueries, useMemo } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'

import { receiveAccount, $bankAccountsStore } from 'app/effector/bank-accounts'

import { NavigationBar } from 'components/atoms/NavigationBar'
import Link from 'components/_old/Link/Link.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { UploadBankStatementForm } from 'components/organisms/UploadBankStatementForm'

const UploadBankStatement = ({ goal, parentModule, params: { bankAccountId } = {} }) => {
  const { desktop } = useMediaQueries()

  const { getBankAccountById } = useUnit($bankAccountsStore)

  const currentBank = getBankAccountById(parseInt(bankAccountId, 10))

  const { tunnelQuery } = useContext(ChangeGoalTunnelContext)

  const redirectToParentModal = useCallback(() => {
    const { id, ...queryWithoutId } = tunnelQuery || {}

    goTo(urlTo(`dashboard.portfolio.${parentModule}`, { id: goal.id }, queryWithoutId), { replace: true })
  }, [tunnelQuery, goal.id])

  const handleUpload = useCallback(
    () => receiveAccount({ ...currentBank, bank_statement_filled: true }),
    [currentBank, receiveAccount],
  )

  const header = useMemo(
    () =>
      desktop ? (
        <Paper top={16} right={20} left={20}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1}>
              <Link onClick={redirectToParentModal} mods={{ color: 'content-on-background-default' }}>
                <Paper top={4}>
                  <Typography align="right" lineHeight="small" color="inherit">
                    Skip
                  </Typography>
                </Paper>
              </Link>
            </Column>
          </ColumnarLayout>
        </Paper>
      ) : (
        <NavigationBar rightPartText="Skip" onRightPartClick={redirectToParentModal}>
          Set up Open Banking
        </NavigationBar>
      ),
    [desktop, redirectToParentModal],
  )

  return (
    <UploadBankStatementForm
      header={header}
      bankAccount={currentBank}
      onAfterUpload={handleUpload}
      onContinue={redirectToParentModal}
    />
  )
}

UploadBankStatement.propTypes = {
  goal: PropTypes.object.isRequired,
  params: PropTypes.shape({
    bankAccountId: PropTypes.string,
  }).isRequired,
  parentModule: PropTypes.string.isRequired,
}

export { UploadBankStatement }
