import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'

import useMediaQueries from 'hooks/useMediaQueries.js'

import FileUpload from 'components/_old/FileUpload/FileUpload.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Text from 'components/_old/Text/Text.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

const FileUploadField = ({
  model,
  modelKey,
  url,
  method,
  type,
  heading,
  text,
  tabIndex,
  onAfterUpload,
  'data-test-id': dataTestId,
}) => {
  const { desktop } = useMediaQueries()

  if (!modelKey) {
    modelKey = `document_${type}`
  }

  const modelKeyFilled = `${modelKey}_filled`
  const modelField = model[modelKey]
  const filled = model[modelKeyFilled]
  const image = modelField || <Icon type={type} />
  const status = (() => {
    if (filled) {
      return 'done'
    }
  })()

  const handleAfterUpload = useCallback(() => {
    if (isFunction(onAfterUpload)) {
      onAfterUpload({ [modelKeyFilled]: true })
    }
  }, [modelKeyFilled, onAfterUpload])

  const messages = {
    loading: (
      <Text small block center muted>
        <Typo>Loading...</Typo>
      </Text>
    ),
    done: desktop ? (
      <Text small block center muted>
        <Typo>Drag and drop file here or press "Upload" below</Typo>
      </Text>
    ) : null,
    failed: desktop ? (
      <Text small block center color="red">
        <Typo>Something went wrong please reupload your file</Typo>
      </Text>
    ) : null,
    default: desktop ? (
      <Text small block center muted>
        <Typo>Drag and drop file here or press Upload below</Typo>
      </Text>
    ) : null,
  }

  return (
    <FileUpload
      heading={heading}
      text={text}
      fieldName={modelKey}
      to={url}
      method={method}
      accept={['image/*', 'application/pdf']}
      image={image}
      status={status}
      messages={messages}
      finalStatus="pending"
      flex={desktop}
      tabIndex={tabIndex}
      afterUpload={handleAfterUpload}
      data-test-id={dataTestId}
    />
  )
}

FileUploadField.propTypes = {
  model: PropTypes.object.isRequired,
  modelKey: PropTypes.string,
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  type: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tabIndex: PropTypes.number,
  onAfterUpload: PropTypes.func,
  'data-test-id': PropTypes.string,
}

export default FileUploadField
