import React from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import classNames from 'classnames/dedupe'
import isUndefined from 'lodash/isUndefined'
import isFunction from 'lodash/isFunction'

import { modsToClassnames } from 'helpers/classname.js'
import { isDarkTheme } from 'helpers/palette'

import MoneyInput from 'components/_old/MoneyInput/MoneyInput.jsx'
import Select from 'components/_old/Select/Select.jsx'
import SelectRemote from 'components/_old/SelectRemote/SelectRemote.jsx'
import Typeahead from 'components/_old/Typeahead/Typeahead.jsx'
import { PhoneInput } from 'components/molecules/PhoneInput'
import DateInput from 'components/_old/DateInput/DateInput.jsx'
import InputMask from 'react-input-mask'

import './Input.css'

class Input extends React.PureComponent {
  handleChange = (event, value, ...rest) => {
    const { type, max, min, onChange, onAfterChange } = this.props

    let newValue = (() => {
      let val = isUndefined(value) && event ? event.target.value : value

      if (type === 'masked') {
        val = val.replace(/_/g, '')
      }

      if (type === 'number') {
        if (min !== undefined) {
          val = val < min ? min : val
        }
        if (max !== undefined) {
          val = val > max ? max : val
        }
      }

      return val
    })()

    if (newValue !== null && typeof newValue === 'object' && newValue.length < 1) {
      newValue = null
    }

    if (onChange) {
      onChange(event, newValue, ...rest)
    }
    if (onAfterChange) {
      onAfterChange()
    }
  }

  focus = () => {
    // eslint-disable-next-line react/no-find-dom-node
    let input = findDOMNode(this)

    if (input.nodeName !== 'INPUT') {
      input = input.querySelector('input')
    }
    input.focus()
  }

  blur = () => {
    // eslint-disable-next-line react/no-find-dom-node
    let input = findDOMNode(this)

    if (input.nodeName !== 'INPUT') {
      input = input.querySelector('input')
    }
    input.blur()
  }

  render() {
    const {
      type = 'text',
      className,
      size,
      mods,
      placeholder,
      inline = false,
      valid = true,
      onChange,
      onBlur,
      onFocus,
      disabled,
      subtype,
      withFloat,
      children,
      ...rest
    } = this.props

    const modsWType = { ...mods, type }

    const classes = classNames(className, 'Input', modsToClassnames('Input', modsWType), {
      Input_error: !valid,
      Input_disabled: disabled,
      Input_inline: inline,
      Input_long_placeholder: typeof placeholder === 'string' && placeholder.length > 1,
      'Input_w-size': typeof size === 'number',
      Input_dark_theme: isDarkTheme(),
    })

    const htmlTypesMatch = {
      number: 'tel',
      money: 'tel',
      tel: 'tel',
      date: 'text',
    }

    const htmlInputModeTypeMatch = {
      number: withFloat ? 'decimal' : 'numeric',
      money: withFloat ? 'decimal' : 'numeric',
      tel: 'tel',
    }

    const calculatedSize = Math.round((size / 1.65 + 0.625) * 100) / 100

    let value = children

    if (typeof value === 'number') {
      if (isNaN(value)) {
        value = null
      }
    } else {
      value = value || ''
    }

    const allProps = {
      className: classes,
      placeholder,
      style: { width: size ? `${calculatedSize}em` : null },
      type: htmlTypesMatch[type] || type,
      inputMode: htmlInputModeTypeMatch[type] || type,
      value,
      onChange: this.handleChange,
      onBlur,
      onFocus,
      disabled,
      withFloat,
      ...rest,
    }

    if (type === 'number') {
      const prevOnKeyDown = allProps.onKeyDown
      allProps.onKeyDown = function (event) {
        // TODO: Refactor all of it
        const isUnusualKey = /[!@#$%^&*()_+=`~"№;:?]/.test(event.key)
        const charCode = event.which ? event.which : event.keyCode
        const isAct = charCode <= 46
        const isNum = charCode >= 48 && charCode <= 57
        const isNumNum = charCode >= 96 && charCode <= 105
        const isMod = event.metaKey || event.shiftKey || event.ctrlKey
        const isDot = withFloat && (charCode === 190 || charCode === 110) && event.target.value.indexOf('.') === -1

        const shouldPreventDefault = isUnusualKey || !(isAct || isNum || isNumNum || isDot || isMod)

        if (shouldPreventDefault) event.preventDefault()

        if (isFunction(prevOnKeyDown)) {
          prevOnKeyDown.apply(this, arguments)
        }
      }
    }

    if (type === 'money') {
      // eslint-disable-next-line react/no-string-refs
      return <MoneyInput ref="moneyInput" {...allProps} />
    } else if (type === 'tel') {
      return <PhoneInput {...allProps} />
    } else if (type === 'select') {
      return <Select {...allProps} />
    } else if (type === 'select-remote') {
      return <SelectRemote {...allProps} mods={mods} />
    } else if (type === 'typeahead') {
      // eslint-disable-next-line react/no-string-refs
      return <Typeahead ref="typeahead" {...allProps} />
    } else if (type === 'date') {
      return <DateInput {...allProps} value={children} />
    } else if (type === 'textarea') {
      return <textarea {...allProps} />
    } else if (type === 'nothing') {
      return <div {...allProps}>{children}</div>
    } else if (type === 'masked' && allProps.mask) {
      return <InputMask {...allProps} type={subtype} value={children ?? ''} />
    } else {
      const { withFloat, ...validInputProps } = allProps

      // eslint-disable-next-line react/no-string-refs
      return <input ref="input" {...validInputProps} />
    }
  }
}

Input.propTypes = {
  type: PropTypes.string,
  subtype: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  mods: PropTypes.object,
  placeholder: PropTypes.string,
  inline: PropTypes.bool,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  withFloat: PropTypes.bool,
  children: PropTypes.node,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onAfterChange: PropTypes.func,
}

export default Input
