import React, { Fragment } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Preloader } from 'components/molecules/Preloader'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { SmsCodeForm } from 'components/organisms/SmsCodeForm'

type TwoFactorConfirmSmsProps = {
  step: string
  phone: string
  isSendingSms: boolean
  blockedText: string
  onAccept: () => void
  onBack: () => void
  shouldRememberBrowser: boolean
  setShouldRememberBrowser: (flag: boolean) => void
  handleSendSms: () => void
  handleBackFromSms: () => void
  query?: {
    client_id?: string
    token?: string
  }
  handleShowRecoveryCodeForm: () => void
}

const TwoFactorConfirmSms = ({
  query,
  step,
  phone,
  isSendingSms,
  blockedText,
  onAccept: handleAccept,
  onBack: handleBack,
  shouldRememberBrowser,
  setShouldRememberBrowser,
  handleSendSms,
  handleBackFromSms,
  handleShowRecoveryCodeForm,
}: TwoFactorConfirmSmsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const headerNode = useMemo(() => <NavigationBar onLeftPartClick={handleBack} leftPartText="Back" />, [handleBack])

  const sendSmsButton = (
    <Button tag="div" mods={{ size: 'big block' }} onClick={handleSendSms}>
      Send confirmation code
    </Button>
  )

  const initialStepTitleNode = (
    <Typography size={desktop ? 14 : 16} align="center">
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column size={1}>
          <Typo>We'll send a confirmation code by SMS message to {phone}</Typo>
        </Column>
      </ColumnarLayout>
    </Typography>
  )

  const initialStepContent = (
    <Fragment>
      <Paper top={desktop ? 96 : 0} bottom={desktop ? 32 : 24}>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
          Use SMS code for access
        </Typography>
      </Paper>
      {desktop ? <Nobr>{initialStepTitleNode}</Nobr> : initialStepTitleNode}
      <Paper top={48} style={{ position: 'relative' }}>
        <div style={{ visibility: isSendingSms ? 'hidden' : 'visible' }}>
          <Paper bottom={desktop ? 56 : 0}>
            <AllCenter>
              <CheckboxWithLabel
                label={
                  <Paper top={4}>
                    <Typography size={14} lineHeight="small">
                      Remember this new device for 30 days
                    </Typography>
                  </Paper>
                }
                checked={shouldRememberBrowser}
                onChange={(value) => {
                  setShouldRememberBrowser(value)
                }}
                noMarginBottom
              />
            </AllCenter>
            {blockedText ? (
              <Paper top={32}>
                <Typography align="center" color="error">
                  <Typo>{blockedText}</Typo>
                </Typography>
              </Paper>
            ) : null}
          </Paper>
        </div>
        {isSendingSms ? (
          <Paper style={{ position: 'absolute', top: 48, left: 0, right: 0 }}>
            <Paper top={32} bottom={32} style={{ position: 'relative' }}>
              <Preloader loading={true} absolute background="background" />
            </Paper>
          </Paper>
        ) : null}
      </Paper>
    </Fragment>
  )

  const initialStepNode = desktop ? (
    <Paper left={desktop ? 24 : 0} right={desktop ? 24 : 0} top={desktop ? 8 : 0} bottom={120}>
      {headerNode}
      <Paper left={48} right={48} bottom={80}>
        {initialStepContent}
        {!isSendingSms ? (
          <Paper left={24} right={24}>
            {sendSmsButton}
          </Paper>
        ) : null}
      </Paper>
    </Paper>
  ) : (
    <MobileLayout
      header={headerNode}
      content={<AllCenter>{initialStepContent}</AllCenter>}
      footer={isSendingSms ? null : <MobileLayoutFooterButton>{sendSmsButton}</MobileLayoutFooterButton>}
    />
  )

  const smsStepNode = (
    <SmsCodeForm
      action="login"
      query={query}
      phone={phone}
      handleBack={handleBackFromSms}
      handleSuccess={handleAccept}
      shouldRememberBrowser={shouldRememberBrowser}
      shouldFormatPhone={false}
      handleShowRecoveryCodeForm={handleShowRecoveryCodeForm}
    />
  )

  return step === 'initial' ? initialStepNode : smsStepNode
}

export { TwoFactorConfirmSms }
