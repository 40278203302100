import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useActions, useSelector, useCallback, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import moment from 'helpers/date.js'
import { goTo, urlTo } from 'helpers/router.js'

import { $transferProgressCollection } from 'app/effector/isa-transfer'
import { fetchRecurringPaymentsFx } from 'app/effector/recurringPayments'

import {
  createDirectDebitSubscription as createSubscriptionActionCreator,
  changeField as changeGoalFieldActionCreator,
  fetchPortfolios as fetchPortfoliosActionCreator,
} from 'app/redux/actions/portfolios'
import { showFailToast as showFailToastActionCreator } from 'app/redux/actions/ui'
import type { Portfolio } from 'app/redux/models/portfolio/types'
import { selectGoalTitle as selectPortfolioTitle } from 'app/redux/selectors'

import { usePaymentDetails } from './usePaymentDetails'

import { types as clientTypes } from 'constants/client'
import { manageTypes, regulatoryTypesCashText } from 'constants/portfolio'

type UseCheckPaymentDetailsInterface = {
  isLoading: boolean
  accountHolder: string
  accountNumber: string
  sortCode: string
  monthlyPaymentAmount: number | null
  monthlyPaymentDay: number | null
  portfolioTitle: string | null
  isSubscribeRequestInProgress: boolean
  handleBack: () => void
  handleConfirm: () => void
}

const fetchRecurringPaymentsOnCheckPaymentDetailsFx = attach({ effect: fetchRecurringPaymentsFx })

const useCheckPaymentDetails = (
  portfolio: Portfolio,
  location: { query: Record<string, string> },
  handleClose?: () => void,
): UseCheckPaymentDetailsInterface => {
  const { accountHolder, accountNumber, sortCode, monthlyPaymentAmount, monthlyPaymentDay, directDebitSubscription } =
    usePaymentDetails(portfolio.id)

  const [isSubscribeRequestInProgress, setIsSubscribeRequestInProgress] = useState(false)

  const portfolioTitle = useSelector((state: any) => selectPortfolioTitle(state, portfolio.id))
  const portfoliosDidInvalidate = useSelector((state: any) => state.portfolios.didInvalidate)
  const clientDidInvalidate = useSelector((state: any) => state.client.didInvalidate)
  const clientType = useSelector((state) => state.client.type)

  const store = useUnit($transferProgressCollection)
  const portfolioProgressData = store.get(portfolio.id)
  const hasIsaTransferInProgress = portfolioProgressData?.length > 0

  const portfolioTitleText =
    portfolio.manage_type === manageTypes.CASH && clientType !== clientTypes.BUSINESS
      ? `${portfolioTitle} ${regulatoryTypesCashText[portfolio.regulatory_type]}`
      : portfolioTitle

  const [createDirectDebitSubscription, changeGoalField, showFailToast, fetchPortfolios] = useActions([
    createSubscriptionActionCreator,
    changeGoalFieldActionCreator,
    showFailToastActionCreator,
    fetchPortfoliosActionCreator,
  ])

  const handleBack = useCallback(() => {
    goTo(urlTo(`dashboard.portfolio.options.direct-debit-form`, { id: portfolio.id }, location?.query))
  }, [portfolio.id, location])

  const monthlyPaymentAmountNum = !monthlyPaymentAmount ? null : parseFloat(monthlyPaymentAmount)
  const monthlyPaymentDayNum = !monthlyPaymentDay ? null : parseInt(monthlyPaymentDay, 10)

  const handleConfirm = useCallback(() => {
    setIsSubscribeRequestInProgress(true)
    let directDebitSubscriptionId = null
    let _monthlyPaymentAmount = monthlyPaymentAmount
    let _monthlyPaymentDay = monthlyPaymentDay

    if (directDebitSubscription) {
      if (directDebitSubscription.amount === parseFloat(_monthlyPaymentAmount).toFixed(2)) {
        _monthlyPaymentAmount = null
        _monthlyPaymentDay = null
      } else {
        directDebitSubscriptionId = directDebitSubscription.id
      }
    }

    if (!directDebitSubscription) {
      trackEvent({
        action: 'sp_setup_form_submitted',
        manage_type: portfolio?.manage_type,
        ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
        payment_method: 'DD',
        portfolio_created_date: moment(portfolio?.created).format('YYYY-MM-DD'),
      })
    }

    createDirectDebitSubscription(
      portfolio.id,
      _monthlyPaymentAmount,
      _monthlyPaymentDay,
      directDebitSubscriptionId,
      !hasIsaTransferInProgress,
    ).then((nextState) => {
      setIsSubscribeRequestInProgress(false)
      if (!nextState.portfolios.error) {
        fetchRecurringPaymentsOnCheckPaymentDetailsFx()
        fetchPortfolios()

        if (!directDebitSubscription) {
          trackEvent({
            category: 'Monthly Investing',
            action: 'Set up monthly investing',
            label: 'DirectDebit',
            value: monthlyPaymentAmount,
            portfolioManageType: portfolio.manage_type,
            portfolioPresetType: portfolio.preset_type,
            portfolioRegulatoryType: portfolio.regulatory_type,
          })
        }

        trackEvent({
          action: 'sp_setup_success_popup',
          manage_type: portfolio?.manage_type,
          ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
          payment_method: 'DD',
          portfolio_created_date: moment(portfolio?.created).format('YYYY-MM-DD'),
        })

        changeGoalField({ monthly_deposit: monthlyPaymentAmount }, portfolio.id)
        goTo(urlTo(`dashboard.portfolio.options.direct-debit-success`, { id: portfolio.id }))
      } else {
        handleClose?.(true)
        let msg

        if (
          nextState.portfolios.error.response?.data?.error ===
          'Validation failed (account_number does not match sort code)'
        ) {
          msg = 'Invalid bank account data. Contact us at support@investengine.com'
        } else {
          msg = 'Something went wrong, please try again or contact us at support@investengine.com'
        }
        setTimeout(() => {
          showFailToast(msg)
        }, 4000)
      }
    })
  }, [
    monthlyPaymentAmount,
    monthlyPaymentDay,
    directDebitSubscription,
    portfolio,
    hasIsaTransferInProgress,
    setIsSubscribeRequestInProgress,
    changeGoalField,
    createDirectDebitSubscription,
    handleClose,
    showFailToast,
    fetchPortfolios,
  ])

  return {
    isLoading: portfoliosDidInvalidate || clientDidInvalidate,
    accountHolder,
    accountNumber,
    sortCode,
    monthlyPaymentAmount: monthlyPaymentAmountNum,
    monthlyPaymentDay: monthlyPaymentDayNum,
    isSubscribeRequestInProgress,
    portfolioTitle: portfolioTitleText,
    handleBack,
    handleConfirm,
  }
}

export { useCheckPaymentDetails }
