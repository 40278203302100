import { PropTypes } from 'prop-types'
import React from 'react'
import classNames from 'classnames/dedupe'

import { palettePlainValues } from 'helpers/palette'

import { useActions, useCallback } from 'hooks'

import { Gateway } from 'components/atoms/Gateway'
import Text from 'components/_old/Text/Text.jsx'
import CSSTransitionGroup from 'react-addons-css-transition-group'

import { removeToast as removeToastActionCreator } from 'app/redux/actions/ui'

import './Toast.css'

import rawMediaQueries from 'helpers/mediaQueries.js'
import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

const Toast = ({ children, style, className, uid, inPlace, mediaQueries, ...rest }) => {
  const removeToast = useActions(removeToastActionCreator)

  const handleClick = useCallback(() => {
    if (!inPlace) {
      removeToast(uid)
    }
  }, [uid, inPlace, removeToast])

  const classes = classNames(
    className,
    'Toast',
    {
      [`Toast_style_${style}`]: style,
      Toast_inPlace: inPlace,
    },
    getMediaQuieryClasses('Toast', mediaQueries),
  )

  return (
    <div className={classes} onClick={handleClick} {...rest} data-toast-style={style} data-test-id="toast">
      <div className="Toast-Inner" data-test-id="toastInner">
        <Text small>{children}</Text>
      </div>
    </div>
  )
}

Toast.propTypes = {
  children: PropTypes.node,
  style: PropTypes.string,
  className: PropTypes.string,
  uid: PropTypes.number,
  inPlace: PropTypes.bool,
  mediaQueries: PropTypes.object,
}

const Stack = ({ children, into = 'toasts' }) => {
  return (
    <Gateway into={into}>
      <CSSTransitionGroup
        transitionEnterTimeout={palettePlainValues.animation.speed.number.default}
        transitionLeaveTimeout={palettePlainValues.animation.speed.number.slow}
        transitionName={{
          enter: 'Toast_enter',
          enterActive: 'Toast_enter_active',
          leave: 'Toast_leave',
          leaveActive: 'Toast_leave_active',
        }}
      >
        {children}
      </CSSTransitionGroup>
    </Gateway>
  )
}

Stack.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  into: PropTypes.string,
}

export { Stack }
export default withMediaQueries(rawMediaQueries)(Toast)
