import { type MutableRefObject } from 'react'
import { centerCrop, makeAspectCrop, convertToPixelCrop, type Crop, type PixelCrop } from 'react-image-crop'

import { useRef, useState } from 'hooks'

import { getResultImage } from './getResultImage'

type useCropAvatarModalProps = {
  onBack: () => void
  onSave: (result: string) => Promise<void>
}

type UseCropAvatarModalInterface = {
  imgRef: MutableRefObject<null>
  crop: Crop
  scale: number
  scaleRange: Array<{ value: number }>
  setCrop: (value: Crop) => void
  setScale: (value: number) => void
  setCompletedCrop: (crop: PixelCrop) => void
  onImageLoad: (e: React.SyntheticEvent) => void
  handleSave: () => Promise<void>
}

const useCropAvatarModal = ({ onBack, onSave }: useCropAvatarModalProps): UseCropAvatarModalInterface => {
  const imgRef = useRef(null)

  const [scale, setScale] = useState(1)
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  })
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

  const onImageLoad = (e): void => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget

    // calculate crop area in the center if the image
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        1,
        width,
        height,
      ),
      width,
      height,
    )

    setCrop(crop)
  }

  const scaleRange = Array.apply(null, Array(150)).map(function (_, i) {
    return { value: 1 + i / 150 }
  })

  const handleSave = async (): Promise<void> => {
    const image = imgRef.current

    const result = await getResultImage({
      image,
      completedCrop: completedCrop ?? convertToPixelCrop(crop, image?.width, image?.height),
      scale,
    })

    onSave(result)
  }

  return {
    imgRef,
    crop,
    scale,
    scaleRange,
    setCrop,
    setScale,
    setCompletedCrop,
    onImageLoad,
    handleSave,
  }
}
export { useCropAvatarModal }
