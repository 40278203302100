import axios from 'helpers/ajax'

import { type Address } from 'app/redux/models/address'

import { type SignificantControl } from '../models'

export const fetchSignificantControls = async (): Promise<SignificantControl[]> => {
  const { data } = await axios.get('client/significant_control/')

  return data
}

export const createSignificantControls = async (controlData: SignificantControl): Promise<SignificantControl> => {
  const { data } = await axios.post('client/significant_control/', controlData)

  return data
}

export const updateSignificantControls = async (
  id: number,
  controlData: SignificantControl,
): Promise<SignificantControl> => {
  const { data } = await axios.patch(`client/significant_control/${id}/`, controlData)

  return data
}

export const deleteSignificantControls = async (id: number): Promise<void> => {
  await axios.delete(`client/significant_control/${id}/`)
}

export const createAddress = async ({ id, address }: { id: number; address: Address }): Promise<Address> => {
  const { data } = await axios.post(`client/significant_control/${id}/addresses/`, address)

  return data
}

export const updateAddress = async ({ id, address }: { id: number; address: Address }): Promise<Address[]> => {
  const { id: addressId, ...addressData } = address
  const { data } = await axios.patch(`client/significant_control/${id}/addresses/${addressId}`, addressData)

  return data
}
