import React from 'react'
import PropTypes from 'prop-types'

import { ErrorBlock } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/ErrorBlock'
import { Typo } from 'components/_old/Typo/Typo'
import Button from 'components/_old/Button/Button.jsx'

const Submit = ({ securities, total }) => {
  if ((securities?.length || 0) < 1) {
    return (
      <ErrorBlock data-test-id="editWeightsSaveError">
        <Typo>Portfolio has no securities added</Typo>
      </ErrorBlock>
    )
  }

  if (total !== 100) {
    return (
      <ErrorBlock data-test-id="editWeightsSaveError">
        <Typo>Weights total {total}%. Adjust to 100%!</Typo>
      </ErrorBlock>
    )
  }

  return (
    <Button type="submit" mods={{ size: 'big block' }} data-test-id="editWeightsContinueButton">
      Review and continue
    </Button>
  )
}

Submit.propTypes = {
  securities: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
}

export { Submit }
