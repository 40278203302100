import React from 'react'

import { useMediaQueries, useSelector, useMemo } from 'hooks'

import { selectGoalTitle } from 'app/redux/selectors'

import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { CardAccent } from 'components/atoms/CardAccent'
import { Paper } from 'components/atoms/Paper'
import Switcher from 'components/atoms/Switcher/Switcher'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { manageTypes, regulatoryTypesCashText, portfolioColors } from 'constants/portfolio'

const PortfolioCard = ({ portfolio, isHidden, onToggleHide: toggleHide }): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const title = useSelector((state) => selectGoalTitle(state, portfolio.id))

  const manageType = useMemo(() => {
    if (portfolio.is_default_preset_used) return 'Retirement portfolio managed by IE'

    return {
      [manageTypes.MANAGED]: 'Managed by InvestEngine',
      [manageTypes.SELF_SELECTED]: 'Picked by you, managed by IE',
      [manageTypes.DIY]: 'Self-managed',
      [manageTypes.CASH]: `${regulatoryTypesCashText[portfolio.regulatory_type]} cash`,
    }[portfolio.manage_type]
  }, [portfolio])

  return (
    <Card level={0}>
      <CardAccent color={portfolioColors[portfolio.manage_type]} />
      <Paper top={desktop ? 32 : 24} right={desktop ? 24 : 20} bottom={desktop ? 32 : 24} left={desktop ? 32 : 20}>
        <ColumnarLayout mods={{ padding: 'small' }}>
          <Column size={1}>
            <Typography
              size={desktop ? 18 : 16}
              lineHeight={desktop ? 'medium' : 'small'}
              weight="semibold"
              align="left"
            >
              <Typo allowHtml={false}>{title}</Typo>
            </Typography>
            <Paper top={4}>
              <Typography size={desktop ? 18 : 14} lineHeight="small" color="minor">
                <Typo>{manageType}</Typo>
              </Typography>
            </Paper>
          </Column>
          <Column size={0}>
            <Switcher onChange={toggleHide} checked={isHidden} />
          </Column>
        </ColumnarLayout>
      </Paper>
    </Card>
  )
}

export { PortfolioCard }
