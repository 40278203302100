import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

type StatsPeriodDTO = {
  return_money: string
}

type StatsPeriodsDTO = Record<(typeof STATS_PERIODS)[number], StatsPeriodDTO>

type StatsDTO = {
  current_balance: string
  periods: StatsPeriodsDTO
}

const STATS_PERIODS = ['week', 'month', 'six_months', 'year', 'max'] as const

const getStatsURL = (): string => `client/stats/`
const fetchStats = async (): Promise<StatsDTO | ApiError> => {
  try {
    const { data } = await axios.get(getStatsURL())

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
fetchStats.getUrl = getStatsURL

export { type StatsPeriodDTO, type StatsDTO, type StatsPeriodsDTO, STATS_PERIODS, fetchStats }
