import React, { forwardRef } from 'react'
import classNames from 'classnames/dedupe'
import PropTypes from 'prop-types'

import './Typography.css'

const fonts = ['default', 'Poppins']
const colors = [
  'default',
  'additional',
  'minor',
  'muted',
  'primary_default',
  'secondary_default',
  'on_color_default',
  'on_background_primary',
  'on_color_black',
  'on_color_white',
  'warning',
  'error',
  'inherit',
  'stocks',
  'bonds',
  'alternatives',
  'green',
  'red',
  'white',
  'on_color_red',
  'accent',
]
const sizes = [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 54, 'inherit']
const weights = ['regular', 'semibold', 'bold', 'inherit']
const lineHeights = ['small', 'medium', 'inherit']
const aligns = ['left', 'center', 'right']
const numerics = ['tabular']
const overflows = ['ellipsis', 'break-word']
const textWraps = ['wrap', 'nowrap', 'balance', 'pretty', 'stable']
const wordBreaks = ['break-all']

const Typography = (
  {
    children,
    tag,
    className,
    font = 'default',
    color = 'default',
    size = 16,
    weight = 'regular',
    lineHeight = 'medium',
    align = 'left',
    numeric,
    overflow,
    textWrap,
    wordBreak,
    uppercase = false,
    inline = false,
    mix = false,
    'data-test-id': dataTestId,
    ...rest
  },
  ref,
) => {
  if (!children) {
    return null
  }

  const Tag = tag ?? 'div'
  const props = {
    ref,
    className: classNames(className, 'Typography', {
      [`Typography_font_${font}`]: font,
      [`Typography_color_${color}`]: color,
      [`Typography_size_${size}`]: size,
      [`Typography_weight_${weight}`]: weight,
      [`Typography_lineHeight_${lineHeight}`]: lineHeight,
      [`Typography_align_${align}`]: align,
      [`Typography_numeric_${numeric}`]: numeric,
      [`Typography_overflow_${overflow}`]: overflow,
      [`Typography_textWrap_${textWrap}`]: textWrap,
      [`Typography_wordBreak_${wordBreak}`]: wordBreak,
      Typography_uppercase: uppercase,
      Typography_inline: inline,
    }),
    'data-test-id': dataTestId,
    lang: 'en-GB',
    ...rest,
  }

  if (mix && React.isValidElement(children)) {
    return React.cloneElement(children, { ...props, className: classNames(props.className, children.props.className) })
  }

  return <Tag {...props}>{children}</Tag>
}

const TypographyWithForwardedRef = forwardRef(Typography)

const propTypes = {
  children: PropTypes.node,
  tag: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(colors),
  size: PropTypes.oneOf(sizes),
  weight: PropTypes.oneOf(weights),
  lineHeight: PropTypes.oneOf(lineHeights),
  align: PropTypes.oneOf(aligns),
  numeric: PropTypes.oneOf(numerics),
  overflow: PropTypes.oneOf(overflows),
  textWrap: PropTypes.oneOf(textWraps),
  wordBreak: PropTypes.oneOf(wordBreaks),
  uppercase: PropTypes.bool,
  inline: PropTypes.bool,
  mix: PropTypes.bool,
  'data-test-id': PropTypes.string,
}

TypographyWithForwardedRef.propTypes = propTypes

export { TypographyWithForwardedRef as Typography, sizes, weights, colors, fonts, propTypes }
