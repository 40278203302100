import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { goTo, urlTo } from 'helpers/router.js'
import { bulkValidate } from 'helpers/validation.js'
import { trackEvent } from 'helpers/analytics'
import { handleExternalLink } from 'helpers/browser'

import { useCallback, useLoading, useMediaQueries, useEffect } from 'hooks'
import { useFundsAmount } from './useFundsAmount.js'

import { FundsAmount } from './components/FundsAmount'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'
import { Gateway } from 'components/atoms/Gateway'
import { Preloader } from 'components/molecules/Preloader'
import Width from 'components/_old/Width/Width'
import { Paper } from 'components/atoms/Paper'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { manageTypes } from 'constants/portfolio'

import { createPayment } from 'app/effector/bank-accounts/api'

import { showFailToast } from 'app/redux/actions/ui'

const Amount = ({
  goal: portfolio,
  tunnelQuery,
  params: { bankAccountId } = {},
  location,
  toggleCloseButton,
  handleClose,
}) => {
  const { desktop } = useMediaQueries()
  const { isLoading, wait } = useLoading()
  const { amount, validation, isIsaLimitReached, sippTotalAllowance, isaRemainingAllowance, handleChange } =
    useFundsAmount(portfolio)

  const isCashPortfolio = portfolio.manage_type === manageTypes.CASH

  const redirectSelectPaymentMethod = useCallback(
    (querystring) => {
      goTo(urlTo(`dashboard.portfolio.add-funds`, { id: portfolio.id }, querystring), { scrollToTop: false })
    },
    [portfolio.id],
  )

  const trackAddMoney = useCallback(() => {
    trackEvent({
      category: 'Add funds',
      action: 'Initiate ‘Add money’',
      label: 'Open Banking',
      value: amount,
      portfolioPresetType: portfolio.preset_type,
      portfolioRegulatoryType: portfolio.regulatory_type,
    })
  }, [amount, portfolio])

  const handleContinue = useCallback(() => {
    trackAddMoney()

    const url = urlTo(`dashboard.portfolio.add-funds.instant-payment`, { id: portfolio.id }, tunnelQuery)
    wait(
      handleExternalLink(
        async (redirectUri) =>
          await createPayment({
            goal_id: portfolio.id,
            amount,
            bank_account_id: bankAccountId,
            redirect_uri: redirectUri,
          }),
        url,
      ),
    ).catch((error) => {
      if (error?.response?.data?.error === 'Compliance blocked') {
        return showFailToast()
      }
      redirectSelectPaymentMethod({
        ...tunnelQuery,
        error: JSON.stringify({
          bankAccountId,
          message: error?.response?.data?.error,
        }),
      })
    })
  }, [trackAddMoney, wait, portfolio, tunnelQuery, amount, bankAccountId, redirectSelectPaymentMethod])

  const handleBack = useCallback(() => {
    return goTo(location?.query?.back ?? urlTo(`portfolio.add-funds`, { id: portfolio.id }))
  }, [portfolio?.id, location?.query?.back])

  useEffect(() => {
    toggleCloseButton(false)
  }, [])

  const header = (
    <OptionsNavigationBar
      leftPartText="Back"
      onLeftPartClick={handleBack}
      rightPartText="Close"
      onRightPartClick={handleClose}
    >
      <Typo>{isCashPortfolio ? 'Add cash' : 'Instant bank transfer'}</Typo>
    </OptionsNavigationBar>
  )

  const amountInput = (
    <FundsAmount
      portfolio={portfolio}
      amount={amount}
      validation={validation}
      isIsaLimitReached={isIsaLimitReached}
      sippTotalAllowance={sippTotalAllowance}
      isaRemainingAllowance={isaRemainingAllowance}
      onChange={handleChange}
    />
  )

  const content = (
    <Fragment>
      {desktop ? (
        <Width size={24} center>
          <Paper top={32}>{amountInput}</Paper>
        </Width>
      ) : (
        <Paper top={32}>{amountInput}</Paper>
      )}
      <Gateway into="newModalPreloader">
        <Preloader loading={isLoading} size="big" zIndex />
      </Gateway>
    </Fragment>
  )

  const button = (
    <Button
      mods={{ size: 'big block' }}
      onClick={handleContinue}
      disabled={!bulkValidate(validation)}
      data-test-id="addFundsAmountContinue"
    >
      Continue
    </Button>
  )

  return <OptionsLayout header={header} content={content} button={button} />
}

Amount.propTypes = {
  goal: PropTypes.object.isRequired,
  tunnelQuery: PropTypes.object.isRequired,
  params: PropTypes.shape({
    bankAccountId: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      back: PropTypes.string,
    }),
  }),
  toggleCloseButton: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export { Amount }
