import { useUnit } from 'effector-react'

import { useCallback, useSelector, useContext } from 'hooks'

import { urlTo } from 'helpers/router.js'

import { $owner } from 'app/effector/contacts'
import { Contact } from 'app/effector/contacts/models'

import { getGoalTitle, getFlatSortedPortfoliosList } from 'app/redux/selectors'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

const mainModules = ['accounts', 'portfolio', 'transfer-isa-altus']

export const useHeader = (routes, activeItem) => {
  const owner = useUnit($owner) ?? new Contact()
  const client = useSelector((state) => state.client)
  const portfolios = useSelector((state) => state.portfolios)
  const sortedGoals = useSelector(getFlatSortedPortfoliosList)

  const dashboardRouteIndex = routes.findIndex((route) => route.module === 'dashboard')
  const routeAfterDashboard = routes[dashboardRouteIndex + 1]
  const isMainPage = mainModules.includes(routeAfterDashboard?.module)

  const childItems = sortedGoals.map((goal) => ({
    name: getGoalTitle({ portfolios, client }, goal.id),
    link: urlTo('dashboard.portfolio', {
      id: goal.id,
    }),
  }))

  const { resetAllScrollPositions } = useContext(ScrollSaverContext)

  const handleAnalytics = useCallback(() => {
    resetAllScrollPositions()
  }, [resetAllScrollPositions])

  const checkActive = useCallback(
    (moduleName, isActive) => {
      if (activeItem) {
        return activeItem === moduleName
      }

      return isActive
    },
    [activeItem],
  )

  const menuItems = [
    {
      name: 'My Dashboard',
      active: checkActive('dashboard', isMainPage),
      link: urlTo('dashboard'),
      analyticsEvent: { category: 'Top menu', action: 'My Dashboard' },
      'data-test-id': 'dashboardMenuDashboardLink',
      childItems: isMainPage && childItems.length > 1 ? childItems : [],
    },
    {
      name: 'Investments',
      active: checkActive(
        'securities',
        routes.some((r) => r.module === 'securities'),
      ),
      link: urlTo('dashboard.securities'),
      analyticsEvent: { category: 'Top menu', action: 'universe' },
      'data-test-id': 'dashboardMenuUniverseLink',
    },
    {
      name: 'Analytics',
      active: checkActive(
        'analytics',
        routes.some((r) => r.module === 'analytics'),
      ),
      link: urlTo('dashboard.analytics'),
      action: handleAnalytics,
      analyticsEvent: { category: 'Top menu', action: 'analytics' },
      'data-test-id': 'dashboardMenuAnalyticsLink',
    },
    {
      name: 'Transactions',
      active: checkActive(
        'transactions',
        routes.some((r) => r.module === 'transactions'),
      ),
      link: urlTo('dashboard.transactions'),
      analyticsEvent: { category: 'Top menu', action: 'Transactions' },
      'data-test-id': 'dashboardMenuTransactionsLink',
    },
    {
      name: 'Reports',
      active: checkActive(
        'reports',
        routes.some((r) => r.module === 'reports'),
      ),
      link: urlTo('dashboard.reports'),
      analyticsEvent: { category: 'Top menu', action: 'Reports' },
      'data-test-id': 'dashboardMenuReportsLink',
    },
  ]

  return { menuItems, owner }
}
