import { createStore, sample, createEvent, combine } from 'effector'
import { condition, pending } from 'patronum'

import { $owner, fetchContactsFx } from 'app/effector/contacts'
import { $isaTransferProviderList, fetchIsaProvidersFx } from 'app/effector/isa-transfer'

import { type ClientIsaTransferPrefillFormDataFields } from 'app/pages/CreateAccount/components/IsaTransferPrefillForm/IsaTransferPrefillFormData'

import {
  scrollToErrorFx,
  submitTransferIsaRequestFx,
  navigateToSuccessFx,
  navigateToSignatureStepFx,
  navigateToPortfolioByIdFx,
  openJotFormLinkFx,
  fetchPortfoliosFx,
  fetchClientFx,
  closeIsaPanelFx,
  showFailToastFx,
} from './effects'
import {
  $form,
  $isValid,
  enableForceValidation,
  setPortfolioId,
  setFormFromPrefilledData,
  resetForm,
} from './formModel'

// Effects

export const init = createEvent<{ portfolioId?: number }>()
export const submitButtonClicked = createEvent()
export const continueAfterSignature = createEvent()
export const navigateToPortfolio = createEvent()
export const submitRequest = createEvent()
export const formValidationFailed = createEvent()
export const setPrefilledTransferData = createEvent<ClientIsaTransferPrefillFormDataFields>()
export const reset = createEvent()

// Stores
export const $prefilledTransferData = createStore<ClientIsaTransferPrefillFormDataFields | null>(null).reset(reset)
export const $isSubmitting = submitTransferIsaRequestFx.pending
export const $signatureType = combine($form, $isaTransferProviderList, (form, providers) => {
  if (!form.isa_provider_id || form.isa_provider_id === 'other' || !providers.length) return null

  return providers.get(form.isa_provider_id)?.signature_type
})
export const $isLoading = pending([submitTransferIsaRequestFx, fetchIsaProvidersFx])
export const $jotformLink = createStore<string | null>(null)
  .on(submitTransferIsaRequestFx.doneData, (_, result) => result.jotform_link)
  .reset(reset)

// Logic
//
// reset model and form-model on init
sample({
  clock: init,
  target: [reset, resetForm],
})

// Fetch isa providers if not fetcheds
sample({
  clock: init,
  source: $isaTransferProviderList,
  filter: (providers) => providers?.length <= 1,
  target: fetchIsaProvidersFx,
})

// Fetch contacts if not fetched
sample({
  clock: init,
  source: $owner,
  filter: (owner) => !owner?.id,
  target: fetchContactsFx,
})

// Set portfolio id if it exists in query
sample({
  clock: init,
  filter: ({ portfolioId }) => !!portfolioId,
  fn: ({ portfolioId }) => portfolioId as number,
  target: setPortfolioId,
})

// save prefilled data if it exists and isa transfer has noot been created yet
sample({
  clock: setPrefilledTransferData,
  filter: (prefilledData) => !!prefilledData && !prefilledData.isa_transfer,
  target: [$prefilledTransferData, setFormFromPrefilledData],
})

// Submit
//
// Submit request if form is valid, otherwise handle validation fail
condition({
  source: submitButtonClicked,
  if: $isValid,
  then: submitRequest,
  else: formValidationFailed,
})

// Submit request
sample({
  clock: submitRequest,
  source: $form,
  fn: (form) => form.serialize(),
  target: submitTransferIsaRequestFx,
})

// Handle validation fail on submit
sample({
  clock: formValidationFailed,
  fn: () => {},
  target: [enableForceValidation, scrollToErrorFx],
})

// Navigate to success page after submit if there is no jotform link in response
condition({
  source: submitTransferIsaRequestFx.done,
  if: ({ result }) => !result.jotform_link,
  then: navigateToSuccessFx,
  else: navigateToSignatureStepFx,
})

// set portfolio_id if new portfolio was created and fetch portfolios
sample({
  clock: submitTransferIsaRequestFx.done,
  filter: ({ params }) => !params.portfolio_id,
  fn: ({ result }) => result.portfolio_id as number,
  target: [setPortfolioId, fetchPortfoliosFx],
})

// fetch client if prefilled transfer data was used
sample({
  clock: submitTransferIsaRequestFx.done,
  source: $prefilledTransferData,
  filter: (data) => !!data,
  fn: () => {},
  target: fetchClientFx,
})

// close portfolio's isa panel if transfer created for existing portfolio
sample({
  clock: submitTransferIsaRequestFx.done,
  filter: ({ params }) => !!params.portfolio_id,
  fn: ({ params }) => params.portfolio_id as number,
  target: closeIsaPanelFx,
})

// show fail toast if transfer request submit failed
sample({
  clock: submitTransferIsaRequestFx.fail,
  target: showFailToastFx,
})

// Open jotform link and navigate to portfolio after signature step
sample({
  clock: continueAfterSignature,
  source: $jotformLink,
  fn: (jotformLink) => jotformLink as string,
  target: [openJotFormLinkFx, navigateToPortfolio],
})

// Navigate to portfolio by id
sample({
  clock: navigateToPortfolio,
  source: $form,
  fn: ({ portfolio_id: portfolioId }) => portfolioId as number,
  target: navigateToPortfolioByIdFx,
})
