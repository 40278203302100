import React from 'react'
import PropTypes from 'prop-types'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'
import { filterEmpty } from 'helpers/filter.js'

import { useCallback, useMediaQueries, useRedirect } from 'hooks'

import { types as clientTypes } from 'constants/client'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Card from 'components/_old/Card/Card.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

import PersonalProfileForm from './Form.jsx'

const PersonalProfileSetup = ({ prevStep, company, owner, updateOwner, handleAfterSubmit }) => {
  const { desktop } = useMediaQueries()

  useRedirect({
    to: prevStep.module,
    rule: !company.name,
    isLoading: company.didInvalidate,
  })

  const handleSubmit = useCallback(async () => {
    const newData = { ...owner, nationalities: filterEmpty(owner.nationalities) }

    const fields = [
      'gender',
      'first_name',
      'middle_name',
      'last_name',
      'nationalities',
      'country_of_tax_residence',
      'has_significant_control',
    ]

    if (!owner.phone?.includes('*')) {
      fields.push('phone')
    }

    if (!owner.birthday?.includes('*')) {
      fields.push('birthday')
    }

    const state = await updateOwner(fields, newData)

    if (!state.result) {
      return processError({ error: state.error })
    }

    trackEvent({
      action: 'personal_information_submitted',
      client_type: clientTypes.BUSINESS,
    })

    handleAfterSubmit()
  }, [owner, handleAfterSubmit, updateOwner])

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <Card
        mods={{
          theme: 'transparent',
          'no-padding': 'right bottom left',
        }}
      >
        {submitButton}
      </Card>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  const form = <PersonalProfileForm wrapSubmitButton={wrapSubmitButton} onSubmit={handleSubmit} />

  if (desktop) {
    return (
      <AllCenter>
        <Card
          mods={{
            theme: 'transparent',
            'no-padding': 'left right',
          }}
        >
          {form}
        </Card>
      </AllCenter>
    )
  }

  return form
}

PersonalProfileSetup.propTypes = {
  prevStep: PropTypes.object,
  company: PropTypes.object,
  owner: PropTypes.object,
  updateOwner: PropTypes.func,
  handleAfterSubmit: PropTypes.func,
}

export { PersonalProfileSetup }
