import React from 'react'

import classNames from 'classnames/dedupe.js'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { features } from 'helpers/features'
import { bulkValidate } from 'helpers/validation.js'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import { ScrollToElementIfOutsideViewport } from 'components/_old/molecules/ScrollToElementIfOutsideViewport'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card as NewCard } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { OnboardingProgressBar } from 'app/pages/Dashboard/Goals/DIY/Portfolio/components/OnboardingProgressBar/OnboardingProgressBar'

import { FundYourPortfolio } from '../../components/FundYourPortfolio'
import { Notice } from '../../components/Notice'

import { Chart } from './components/Chart'
import { Controls as ControlsNew } from './components/ControlsNew'
import { FooterInfo } from './components/FooterInfo'
import { useEditGrowthProjections } from './useEditGrowthProjections'

import './EditGrowthPortfolio.css'

const EditGrowthPortfolio = ({
  portfolio,
  presets = [],
  projections,
  projectionsFetched,
  showChart,
  locked = false,
  changed = false,
  minYears = 2,
  maxYears = 30,
  hideName,
  validation = {},
  additionalFields,
  analyticsCategory,
  onChange,
  onSubmit,
  handleAmendPortfolioSelect,
  isRecommendedPresetLoading = false,
}) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const {
    debouncedFields,
    handleFieldChangeDebounced,
    handleSubmit,
    handleFieldChange,
    initial_deposit,
    one_off_payment,
    monthlyInvestment,
    preset_recommended,
    target,
    targetEditable,
    setTargetEditable,
    isPortfolioActive,
    riskStatData,
    chartRef,
    shouldShowScrollToKey,
    setShouldShowScrollToKey,
    endYear,
    endData,
    data,
    recurringPayment,
  } = useEditGrowthProjections({
    analyticsCategory,
    portfolio,
    maxYears,
    minYears,
    validation,
    onChange,
    onSubmit,
    presets,
    projections,
  })

  if (!portfolio.id || presets.length < 1) {
    return null
  }

  const isNewPortfolio = parseFloat(portfolio.current_balance) <= 0 && !portfolio.first_topup

  const rootClassnames = classNames('EditGrowthPortfolio', getMediaQuieryClasses('EditGrowthPortfolio', mediaQueries))

  const { title } = portfolio

  const submitButton = (
    <Button
      type="submit"
      mods={{
        color: 'green',
        size: desktop ? 'big block' : 'block',
        text: desktop ? null : 'smaller',
        theme: desktop ? 'simple-green' : 'simple-green round',
      }}
      disabled={locked || !changed || !bulkValidate(validation) || isRecommendedPresetLoading}
      onClick={handleSubmit}
      tabIndex={7}
      data-test-id="editPortfolioSaveButton"
    >
      Apply changes
    </Button>
  )

  const form = (
    <ControlsNew
      portfolio={portfolio}
      validation={validation}
      handleFieldChange={handleFieldChange}
      initial_deposit={initial_deposit}
      one_off_payment={one_off_payment}
      monthlyInvestment={monthlyInvestment}
      debouncedFields={debouncedFields}
      preset_recommended={preset_recommended}
      presets={presets}
      handleFieldChangeDebounced={handleFieldChangeDebounced}
      target={target}
      targetEditable={targetEditable}
      setTargetEditable={setTargetEditable}
      additionalFields={additionalFields}
      isPortfolioActive={isPortfolioActive}
      riskStatData={riskStatData}
      maxYears={maxYears}
      minYears={minYears}
      recurringPayment={recurringPayment}
    />
  )

  const chart = (
    <Chart
      chartRef={chartRef}
      showChart={showChart}
      projections={projections}
      hideName={hideName}
      title={title}
      target={target}
      projectionsFetched={projectionsFetched}
      data={data}
      endYear={endYear}
      endData={endData}
    />
  )

  const footerInfo = <FooterInfo />

  const processingAllocationNote = (
    <Card
      fluid={desktop}
      className="EditGrowthPortfolio-Message"
      mods={{ theme: 'gray', 'no-padding': 'all' }}
      data-test-id="projectionsLockedMessage"
    >
      <Paper top={8} bottom={8} left={desktop ? 4 : 16} right={desktop ? 4 : 16}>
        <Typography color="on_color_default" align="center" size={desktop ? 14 : 16}>
          <Typo>Processing allocation change request</Typo>
        </Typography>
      </Paper>
    </Card>
  )

  const scrollIntoViewport = (
    <ScrollToElementIfOutsideViewport
      elementToScrollTo={chartRef}
      shouldShowScrollToKey={shouldShowScrollToKey}
      setShouldShowScrollToKey={setShouldShowScrollToKey}
      color="blue"
    >
      Projections updated ↓
    </ScrollToElementIfOutsideViewport>
  )

  const fundYourPortfolio = (
    <Paper bottom={40}>
      <FundYourPortfolio portfolio={portfolio} handleAmendPortfolioSelect={handleAmendPortfolioSelect} />
    </Paper>
  )

  const fundOrProgressBar = isNewPortfolio ? (
    <OnboardingProgressBar portfolio={portfolio} substitute={fundYourPortfolio} />
  ) : null

  return (
    <SubmitOnEnter>
      {desktop ? (
        <Paper className={rootClassnames} top={desktop ? 56 : 16}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1}>
              {isNewPortfolio && (
                <Paper bottom={40}>
                  <Notice presetType={portfolio?.preset_type} isFullWidth />
                </Paper>
              )}
              <Paper bottom={56}>{chart}</Paper>
              {footerInfo}
            </Column>
            <Column size={0}>
              <Paper left={80}>
                <Width size={19}>
                  {fundOrProgressBar}
                  <NewCard>
                    <Paper top={24} bottom={24} left={24} right={24}>
                      <Paper bottom={40}>{form}</Paper>
                      {locked ? processingAllocationNote : submitButton}
                    </Paper>
                  </NewCard>
                </Width>
              </Paper>
            </Column>
          </ColumnarLayout>
        </Paper>
      ) : (
        <Paper>
          <div className={rootClassnames}>
            <Paper top={16}>{form}</Paper>
            <Paper top={16}>{chart}</Paper>
            <Paper top={32} bottom={32}>
              {footerInfo}
            </Paper>
          </div>
          {locked ? (
            <div className="EditGrowthPortfolio-Buttons">{processingAllocationNote}</div>
          ) : (
            <Width size={12.5} center className="EditGrowthPortfolio-Buttons">
              {scrollIntoViewport}
              {changed && submitButton}
            </Width>
          )}
        </Paper>
      )}
    </SubmitOnEnter>
  )
}

EditGrowthPortfolio.propTypes = {
  portfolio: PropTypes.object,
  presets: PropTypes.arrayOf(PropTypes.object),
  projections: PropTypes.object,
  projectionsFetched: PropTypes.bool,
  showChart: PropTypes.bool,
  locked: PropTypes.bool,
  changed: PropTypes.bool,
  hideName: PropTypes.bool,
  warning: PropTypes.string,
  validation: PropTypes.object,
  additionalFields: PropTypes.node,
  analyticsCategory: PropTypes.string,
  minYears: PropTypes.number,
  maxYears: PropTypes.number,

  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  handleAmendPortfolioSelect: PropTypes.func,
  isRecommendedPresetLoading: PropTypes.bool,
}

export default EditGrowthPortfolio
