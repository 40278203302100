import React from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames/dedupe'
import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import throttle from 'lodash/throttle'
import PropTypes from 'prop-types'

import { trackEvent } from 'helpers/analytics'
import compose from 'helpers/compose.js'
import moment from 'helpers/date.js'
import rawMediaQueries from 'helpers/mediaQueries.js'
import { numeral, format as formatMoney } from 'helpers/money'
import { palette, isDarkTheme } from 'helpers/palette'
import { validate } from 'helpers/validation.js'

import { $dictsStore } from 'app/effector/dicts'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import AssetOneliner, { AssetOnelinerGroup } from 'components/_old/AssetOneliner/AssetOneliner.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Form from 'components/_old/Form/Form.jsx'
import Label, { LabelText, LabelField } from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Slider from 'components/_old/Slider/Slider.jsx'
import SliderWTicks from 'components/_old/SliderWTicks/SliderWTicks.jsx'
import Width from 'components/_old/Width/Width'

import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'
import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'

import Projections from 'components/_old/Projections/Projections.jsx'
import ProjectionsTooltip from 'components/_old/ProjectionsTooltip/ProjectionsTooltip.jsx'
import { ScrollToElementIfOutsideViewport } from 'components/_old/molecules/ScrollToElementIfOutsideViewport'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'

import { Paper } from 'components/atoms/Paper'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { SuitablePortfolio } from 'components/organisms/SuitablePortfolio'

import './EditGoal.css'

const trackEventDebounced = debounce(trackEvent, 400)

class EditGoal extends React.Component {
  state = {
    shouldShowScrollToKey: '',
    fetchedProjectionsCount: 0,
  }

  constructor(props) {
    super(props)

    this.chartRef = React.createRef()
  }

  setShouldShowScrollToKey = (shouldShowScrollToKey) => {
    this.setState({ shouldShowScrollToKey })
  }

  handleFieldChange = (field, value = null) => {
    const { analyticsCategory, onChange, presets = [] } = this.props

    if (field === 'preset_changed') {
      const selectedPreset = find(presets, { id: value })

      if (selectedPreset) {
        ;['preset_stocks', 'preset_bonds', 'preset_alternatives'].forEach((instrument) => {
          onChange(`goal_${instrument}`, null, selectedPreset[`preset_${instrument}`])
        })
      }
    }
    if (['initial_deposit', 'monthly_deposit', 'target'].includes(field)) {
      value = value !== '' ? value : null
    }

    this.setShouldShowScrollToKey(JSON.stringify({ [field]: value }))
    onChange(field, null, value)

    if (analyticsCategory) {
      switch (field) {
        case 'initial_deposit':
          trackEventDebounced({
            category: analyticsCategory,
            action: 'Project. tool: Initial investment changed',
          })
          break
        case 'monthly_deposit':
          trackEventDebounced({
            category: analyticsCategory,
            action: 'Project. tool: Monthly investment set',
          })
          break
        case 'preset_changed':
          trackEventDebounced({
            category: analyticsCategory,
            action: 'Project. tool: Model changed',
          })
          break
        case 'term':
          trackEventDebounced({
            category: analyticsCategory,
            action: 'Project. tool: Term changed',
          })
          break
        case 'target':
          trackEventDebounced({
            category: analyticsCategory,
            action: 'Project. tool: Target set',
          })
          break
      }
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps({ goal: { projections_fetched: nextProjectionsFetched = false } = {} }) {
    const { goal: { projections_fetched: oldProjectionsFetched = false } = {} } = this.props

    if (nextProjectionsFetched && !oldProjectionsFetched) {
      this.setState({ fetchedProjectionsCount: this.state.fetchedProjectionsCount + 1 })
    }
  }

  componentWillUnmount() {
    const { goal } = this.props
    const { target } = goal

    if (!target || parseFloat(target) < 1) {
      this.handleFieldChange('target_field_shown', false)
    }
  }

  componentDidMount() {
    const { goal } = this.props
    const { target } = goal

    if (parseFloat(target) > 0) {
      this.handleFieldChange('target_field_shown', true)
    }

    this.initMonthlyInvestment()
  }

  handleFieldChangeThrottle = throttle(this.handleFieldChange, 250)

  initMonthlyInvestment = () => {
    const { goal } = this.props
    const { monthly_deposit, monthly_deposit_recommended } = goal
    const monthly_investment =
      typeof monthly_deposit === 'number' || typeof monthly_deposit === 'string'
        ? monthly_deposit
        : monthly_deposit_recommended > 0
          ? monthly_deposit_recommended
          : null
    this.handleFieldChange('monthly_deposit', monthly_investment)
  }

  render() {
    const {
      goal,
      monthly,
      showProjections,
      presets = [],
      minYears = 2,
      maxYears = 30,
      hideName,
      warning,
      submit,
      cancel,
      validation,
      additionalFields,
      initialDepositLocked,
      mediaQueries,
      initialDepositMin,
    } = this.props

    if (!goal.id || presets.length < 1) {
      return null
    }

    const { desktop } = mediaQueries
    let {
      title,
      initial_deposit,
      monthly_deposit,
      monthly_deposit_recommended,
      term,
      target,
      target_field_shown,
      preset_changed,
      preset_recommended,
      projections = {},
      projections_fetched = false,
    } = goal

    target = target ? parseInt(target, 10) : null
    if (target > 0) {
      target_field_shown = true
    }

    const years = Math.abs(term || 1)

    const calcTotal = (assets) => {
      return assets && assets.length > 1
        ? assets.reduce(
            (memo, item) =>
              typeof memo === 'number'
                ? (memo || 0) + (item ? item.amount : 0)
                : (memo ? memo.amount : 0) + (item ? item.amount : 0),
            0,
          )
        : assets?.[0]?.amount || 0
    }

    const assetsData = { preset_stocks: 0, preset_bonds: 0, preset_alternatives: 0 }

    if (presets && presets.length > 0) {
      preset_changed = preset_changed || presets[0].id
      const presetObj = presets.find((presetItem) => presetItem.id === preset_changed)
      assetsData.preset_stocks = calcTotal(presetObj ? presetObj.preset_stocks || [] : [])
      assetsData.preset_bonds = calcTotal(presetObj ? presetObj.preset_bonds || [] : [])
      assetsData.preset_alternatives = calcTotal(presetObj ? presetObj.preset_alternatives || [] : [])

      // add cash to alternatives, bonds or stocks
      if (presetObj?.preset_cash) {
        const cashValue = parseFloat(presetObj.preset_cash)

        if (assetsData.preset_alternatives > 0) {
          assetsData.preset_alternatives = assetsData.preset_alternatives + cashValue
        } else if (assetsData.preset_bonds > 0) {
          assetsData.preset_bonds = assetsData.preset_bonds + cashValue
        } else {
          assetsData.preset_stocks = assetsData.preset_stocks + cashValue
        }
      }
    }

    const mixin = {
      5: {
        color: isDarkTheme() ? '#89bae1' : '#8fafdf',
        area: true,
      },
      25: {
        color: isDarkTheme() ? '#cfffc5' : '#95d86d',
        area: true,
      },
      50: {
        color: isDarkTheme() ? '#cfffc5' : '#95d86d',
        area: true,
      },
      75: {
        color: isDarkTheme() ? '#b5b6b5' : '#c0c0c0',
        area: true,
      },
      95: {
        color: isDarkTheme() ? '#b5b6b5' : '#c0c0c0',
        area: true,
      },
      contributions: {
        color: isDarkTheme() ? '#ffffff' : palette['content-on-background-default'],
      },
    }

    const data = (() => {
      if (projections?.data) {
        return Object.keys(projections.data).map((k, i) => {
          const key = k
          let values = projections.data[key]
          const mix = mixin[key]
          const seriesIndex = i

          values = values.map((item) => {
            return {
              x: moment(new Date())
                .add(item.x, monthly ? 'months' : 'years')
                .toDate(),
              y: item.y,
              series: i,
            }
          })

          return { key, values, seriesIndex, ...mix }
        })
      } else {
        return []
      }
    })()

    if (validate(validation.target.rules) && target && data?.[5]) {
      const period = data[5].values.length - 1
      data.push({
        color: palette['status-error'],
        key: 'target',
        values: [
          {
            x: new Date(),
            y: target,
          },
          {
            x: moment(new Date())
              .add(monthly ? period / 12 : period, 'years')
              .toDate(),
            y: target,
          },
        ],
      })
    }

    const inputMods = { size: 'bigger' }

    const initialDepositInput = initialDepositLocked ? (
      <Label errorMessages={validation.initial_deposit.errors} fluid>
        Initial investment
        <Input
          type="money"
          mods={{ ...inputMods, theme: 'borderless' }}
          style={{ paddingLeft: 0 }}
          disabled
          withFloat
          tabIndex={1}
          data-test-id="editGoalInitialDepositInput"
        >
          {initial_deposit}
        </Input>
      </Label>
    ) : (
      <Validate rules={validation.initial_deposit.rules}>
        <Label errorMessages={validation.initial_deposit.errors} fluid>
          Initial investment
          <Input
            type="money"
            onChange={(e, val) => this.handleFieldChange('initial_deposit', val)}
            mods={inputMods}
            tabIndex={1}
            withFloat
            placeholder={`Must be at least ${formatMoney(initialDepositMin)}`}
            data-test-id="editGoalInitialDepositInput"
          >
            {initial_deposit}
          </Input>
        </Label>
      </Validate>
    )

    const monthly_investment =
      typeof monthly_deposit === 'number' || typeof monthly_deposit === 'string'
        ? monthly_deposit
        : monthly_deposit_recommended > 0
          ? monthly_deposit_recommended
          : null

    const monthlyDepositInput = (
      <Validate rules={validation.monthly_deposit.rules}>
        <Label postfield={<Text muted>Optional</Text>} errorMessages={validation.monthly_deposit.errors} fluid>
          Monthly investment
          <Input
            type="money"
            onChange={(_e, value) => this.handleFieldChange('monthly_deposit', value)}
            mods={inputMods}
            tabIndex={2}
            withFloat
            data-test-id="editGoalMonthlyDepositInput"
          >
            {monthly_investment}
          </Input>
        </Label>
      </Validate>
    )

    const presetAsCaption = (
      <AssetOnelinerGroup>
        <AssetOneliner
          color={palette.stocks}
          amount={numeral(assetsData.preset_stocks).format('0[.]00')}
          name="Equities"
          data-test-id="equitiesPercent"
        />
        <AssetOneliner
          color={palette.bonds}
          amount={numeral(assetsData.preset_bonds).format('0[.]00')}
          name="Bonds"
          data-test-id="bondsPercent"
        />
        <AssetOneliner
          color={palette.alternatives}
          amount={numeral(assetsData.preset_alternatives).format('0[.]00')}
          name="Alternatives"
          data-test-id="alternativesPercent"
        />
      </AssetOnelinerGroup>
    )

    const presetInput = (
      <Label fluid>
        <div style={{ marginBottom: '0.25em' }}>
          <ColumnarLayout>
            <Column>
              <Text smaller>Low risk</Text>
            </Column>
            <Column>
              <Text smaller right>
                <span>High risk</span>
                <SuitablePortfolio fixForOldEditGoal />
              </Text>
            </Column>
          </ColumnarLayout>
        </div>
        <SliderWTicks
          value={preset_changed}
          valueKey="id"
          recommended={preset_recommended}
          limit
          scale={presets}
          valueAsCaption={presetAsCaption}
          onChange={(value) => {
            this.handleFieldChangeThrottle('preset_changed', value)
          }}
          tabIndex={3}
          theme="risk-zones"
          data-test-id="editGoalPresetsSlider"
        />
      </Label>
    )

    const yearsRange = Array.apply(null, Array(maxYears - minYears + 1)).map(function (_, i) {
      return { term: i + minYears }
    })
    const yearsAsCaption = <Text bold>{`${years} years`}</Text>
    const yearsInput = (
      <Label fluid style={{ marginTop: desktop ? null : '-1em' }}>
        {desktop ? 'Time period' : null}
        <Slider
          value={years}
          valueKey="term"
          scale={yearsRange}
          valueAsCaption={yearsAsCaption}
          onChange={(value) => {
            this.handleFieldChangeThrottle('term', value)
          }}
          withBars
          tabIndex={4}
          data-test-id="editGoalYearsSlider"
        />
      </Label>
    )

    const targetInput = (
      <Validate rules={validation.target.rules}>
        {(isValid, brokenRule) => {
          return (
            <Label postfield={<Text muted>Optional</Text>} fluid>
              {desktop || target_field_shown ? (
                <LabelText style={{ visibility: target_field_shown ? null : 'hidden' }}>
                  <Text color={isValid ? null : 'red'}>
                    {isValid ? 'Target' : validation.target.errors[brokenRule]}
                  </Text>
                </LabelText>
              ) : null}
              <LabelField>
                {target_field_shown ? (
                  <Input
                    type="money"
                    onChange={(_e, val) => this.handleFieldChange('target', val)}
                    mods={inputMods}
                    tabIndex={5}
                    data-test-id="editGoalTargetInput"
                  >
                    {target}
                  </Input>
                ) : desktop ? (
                  <Button
                    onClick={() => {
                      this.handleFieldChange('target_field_shown', true)
                    }}
                    className="EditGoal-TargetButton"
                    mods={{ size: 'block' }}
                    tabIndex={5}
                    data-test-id="editGoalTargetButton"
                  >
                    Set your target
                  </Button>
                ) : (
                  <Text small>
                    <Link
                      onClick={() => {
                        this.handleFieldChange('target_field_shown', true)
                      }}
                      tabIndex={5}
                    >
                      Set your target
                    </Link>
                  </Text>
                )}
              </LabelField>
            </Label>
          )
        }}
      </Validate>
    )

    const form = desktop ? (
      <Card mods={{ theme: 'transparent' }}>
        <Form className="EditGoal-Form">
          {additionalFields}
          <ColumnarLayout>
            <Column>{initialDepositInput}</Column>
            <Column>{monthlyDepositInput}</Column>
            <Column size={2}>{presetInput}</Column>
            <Column>{yearsInput}</Column>
            <Column>{targetInput}</Column>
          </ColumnarLayout>
        </Form>
      </Card>
    ) : (
      <Card mods={{ theme: 'transparent' }}>
        <Form className="EditGoal-Form">
          {additionalFields}
          <ColumnarLayout mods={{ padding: 'small' }}>
            <Column>{initialDepositInput}</Column>
            <Column>{monthlyDepositInput}</Column>
          </ColumnarLayout>
          <Card mods={{ theme: 'transparent', 'no-padding': 'left right' }}>
            {targetInput}
            {presetInput}
          </Card>
        </Form>
      </Card>
    )

    const buttons = (() => {
      const inner = (() => {
        const widthSize = (() => {
          if (!desktop) {
            return null
          }

          if (cancel) {
            return 32
          }

          return 18
        })()

        return (
          <Width size={widthSize} center>
            <StickedIfNotDesktop into="afterLayout">
              <ButtonGroup>{submit}</ButtonGroup>
            </StickedIfNotDesktop>
          </Width>
        )
      })()

      if (desktop) {
        return (
          <Card style={{ position: 'sticky', bottom: 0, zIndex: 1 }} mods={{ theme: 'transparent' }}>
            {inner}
          </Card>
        )
      }

      return inner
    })()

    const endYear = Number(moment(new Date()).format('YYYY')) + years
    const endData = keyBy(
      data
        .filter((dataObj) => dataObj?.values)
        .map((dataObj) => {
          return {
            color: dataObj.color,
            key: dataObj.key,
            value: dataObj.values[dataObj.values.length - 1].y,
          }
        }),
      'key',
    )

    return (
      <SubmitOnEnter>
        <Card
          className={classNames('EditGoal', getMediaQuieryClasses('EditGoal', mediaQueries))}
          mods={{
            theme: desktop ? 'white shadowed' : 'white shadowed edge-to-edge',
            'no-padding': 'all',
          }}
        >
          {form}
          {desktop ? null : <Card mods={{ theme: 'transparent', 'no-padding': 'top' }}>{yearsInput}</Card>}
          {!desktop && (
            <ScrollToElementIfOutsideViewport
              elementToScrollTo={this.chartRef}
              shouldShowScrollToKey={this.state.shouldShowScrollToKey}
              setShouldShowScrollToKey={this.setShouldShowScrollToKey}
              bottom={this.props.bottom}
            >
              Projections updated ↓
            </ScrollToElementIfOutsideViewport>
          )}

          <div ref={this.chartRef}>
            <Paper className="EditGoal-Chart" top={24} bottom={24}>
              {desktop ? null : (
                <Inner>
                  <Headline className="EditGoal-ChartHeadline" level={4} mods={{ text: 'normal', 'no-margin': 'top' }}>
                    Projected returns
                  </Headline>
                </Inner>
              )}
              {showProjections ? (
                <Projections
                  title={hideName ? null : title}
                  target={target}
                  fetched={projections_fetched}
                  data={data}
                  minY={projections ? projections.min_y : null}
                  maxY={projections ? projections.max_y : null}
                  width={desktop ? 768 : 320}
                  height={desktop ? 370 : 140}
                  desktop={desktop}
                  monthly={monthly}
                  data-test-id="editGoalProjectionsChart"
                />
              ) : (
                <Card
                  mods={{ theme: 'transparent' }}
                  style={{ height: desktop ? 349 : 116 }}
                  flex
                  data-test-id="editGoalNoInitialInvestmentText"
                >
                  <AllCenter>
                    <Text muted>Please, enter your initial investment</Text>
                  </AllCenter>
                </Card>
              )}
            </Paper>
          </div>
          {desktop ? null : (
            <div>
              <Card mods={{ theme: 'transparent', 'no-padding': 'top' }}>
                <ColumnarLayout>
                  <Text smaller muted block>
                    {moment(new Date()).format('YYYY')}
                  </Text>
                  <Text smaller muted block right>
                    {endYear}
                  </Text>
                </ColumnarLayout>
              </Card>
              {showProjections ? (
                <Inner>
                  <Card
                    className="EditGoal-Probability"
                    mods={{
                      theme: 'transparent',
                      'no-padding': 'all',
                    }}
                  >
                    <ProjectionsTooltip date={endYear} series={endData} />
                  </Card>
                </Inner>
              ) : null}
            </div>
          )}
          {warning && (
            <Card mods={{ theme: 'transparent', 'no-padding': !desktop ? 'bottom' : 'top bottom' }}>{warning}</Card>
          )}
          {buttons}
          <Card mods={{ theme: 'transparent' }}>
            <Text block smaller muted data-test-id="chartDisclaimer">
              <Typo>
                The chart should only be used as an indication of the range of potential outcomes based on the portfolio
                allocation you selected above, initial investment, monthly investment and time horizon. The information
                provided does not constitute advice or an expected promise over future outcomes. InvestEngine’s optimal
                portfolio is based only on the information you have provided as part of the risk questionnaire. If you
                hold your portfolio within our SIPP account you should consider the effects of tax relief and accounts
                fees which are not included in these projections.
              </Typo>
            </Text>
          </Card>
        </Card>
      </SubmitOnEnter>
    )
  }
}

EditGoal.propTypes = {
  goal: PropTypes.object,
  monthly: PropTypes.bool,
  showProjections: PropTypes.bool,
  presets: PropTypes.array,
  minYears: PropTypes.number,
  maxYears: PropTypes.number,
  warning: PropTypes.node,
  submit: PropTypes.node,
  cancel: PropTypes.bool,
  validation: PropTypes.object,
  additionalFields: PropTypes.node,
  initialDepositLocked: PropTypes.bool,
  mediaQueries: PropTypes.object,
  initialDepositMin: PropTypes.number,
  analyticsCategory: PropTypes.string,
  hideName: PropTypes.bool,
  bottom: PropTypes.number,

  onChange: PropTypes.func,
}

export default compose(
  withMediaQueries(rawMediaQueries),
  connect(() => {
    return { initialDepositMin: $dictsStore.getState().initialDepositMin }
  }),
)(EditGoal)
