import { replace as historyReplace, push as historyPush } from 'helpers/history.js'
import { memoizeForever } from 'helpers/memoize'

import { removeToastsWithStyle } from 'app/redux/actions/ui'
import store from 'app/redux/store/store.js'

import { getRoutes } from 'app/containers/App/routes.jsx'

const getMap = memoizeForever((route) => {
  const flatMap = []
  const getFlat = ({ childRoutes, ...flatRoute }, parent) => {
    if (parent) {
      flatRoute.module = `${parent.module || ''}.${flatRoute.module || ''}`
      flatRoute.path = `${parent.path || ''}${flatRoute.path || ''}`
      flatRoute.path = /\/$/.test(flatRoute.path) ? flatRoute.path : flatRoute.path + '/'
    }
    if (childRoutes) {
      childRoutes.forEach((childRoute) => {
        getFlat(childRoute, flatRoute)
      })
    }

    flatMap.push(flatRoute)
  }

  getFlat(route)
  return flatMap
})

function getFlatMap() {
  return getMap(getRoutes())
}

/**
 * @param {string|null} moduleName - a module to where link should follow, e.g. 'dashboard'
 * @param {Object=} params - params of that url, e.g. { goalId: 123 }
 * @param {Object=} querystring - an object fo query strings, e.g. { tab: 'projections'  }
 * @returns {string|null}
 */
function urlTo(moduleName, params, querystring) {
  if (moduleName === null) {
    return null
  }
  const flatMap = getFlatMap()
  const module = flatMap.find((route) => {
    const modules = route.module.split('.')
    const targetModules = moduleName.split('.')

    if (
      targetModules.every((module) => modules.indexOf(module) > -1) &&
      modules[modules.length - 1] === targetModules[targetModules.length - 1]
    ) {
      return route.module
    }

    return false
  })

  let path = module ? module.path : null

  if (params) {
    Object.keys(params).forEach((param) => {
      if (path) {
        path = path.replace(`(:${param})`, `:${param}`)
        path = path.replace(`:${param}`, params[param])
      }
    })
  }
  if (!params) {
    // if params not passed and path ends with optional param (like adding-director/(:contactId)), remove it
    path = path?.replace(/\(:.*\)\/$/, '')
  }
  if (querystring) {
    let string = querystring

    if (typeof querystring === 'object') {
      string = Object.keys(querystring)
        .map((key, i) => {
          if (querystring[key] === null) {
            return `${i === 0 ? '?' : '&'}${key}`
          }

          return `${i === 0 ? '?' : '&'}${key}=${encodeURIComponent(querystring[key])}`
        })
        .join('')
    }
    path = path + string
  }

  if (path) {
    return path.replace(/\/\//g, '/')
  }

  return path
}

function goTo(url, { replace = false, hardGoTo = false, unsafe = false, scrollToTop = true } = {}) {
  if (hardGoTo) {
    if (!unsafe && /^http/.test(url)) {
      window.location = '/dashboard/'
      return
    }
    window.location = url
    return
  }

  store.dispatch(removeToastsWithStyle('fail'))

  if (replace) {
    historyReplace(url, { scrollToTop })
  } else {
    historyPush(url, { scrollToTop })
  }
}

function convertToSlug(value, separator = '-') {
  if (!value) {
    return value
  }

  try {
    return String(value)
      .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ') // eslint-disable-line no-useless-escape
      .replace(/^\s+|\s+$/gm, '')
      .replace(/\s+/g, separator)
      .replace(/(?=[a-zA-Z])(?=[A-Z])/, separator)
      .toLowerCase()
  } catch (error) {
    return value
  }
}

const preventScrollTopRouteModules = [
  'wire-transfer',
  'direct-debit',
  'direct-debit-stop-subscription',
  'direct-debit-guarantee',
  'direct-debit-monthly-payment',
  'direct-debit-promo-payment',
  'withdraw-funds',
  'bank-transfer',
  'change-portfolio-name',
]

export { urlTo, goTo, convertToSlug, preventScrollTopRouteModules }
