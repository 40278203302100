import React, { Fragment } from 'react'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { PresetSubtitle } from 'app/pages/Securities/SelfSelectedPreset/components/PresetSubtitle'

type PortfolioHeaderProps = {
  title: string
  handleBack: () => void
}

const PortfolioHeader = ({ handleBack, title }: PortfolioHeaderProps): React.ReactElement => {
  return (
    <Fragment>
      <Paper top={8}>
        <ColumnarLayout mods={{ padding: 'no' }}>
          <Column size={0}>
            <Link onClick={handleBack} mods={{ color: 'content-on-background-default' }}>
              <ItemWithIcon
                space={16}
                icon={<Icon type="back-new" size={20} color="inherit" />}
                content={<Typography color="inherit">Back</Typography>}
                iconVerticalAlign="center"
                data-test-id="navigationLeftButton"
              />
            </Link>
          </Column>
          <Column size={1}>
            <Typography size={24} weight="semibold" align="center" lineHeight="small" data-test-id="goalTitle">
              {title}
            </Typography>
            <Paper top={8}>
              <AllCenter>
                <PresetSubtitle />
              </AllCenter>
            </Paper>
          </Column>
        </ColumnarLayout>
      </Paper>
    </Fragment>
  )
}

export { PortfolioHeader }
