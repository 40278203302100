import last from 'lodash/last'

import moment from 'helpers/date.js'

const transformIncomeTwrData = (rawData = [], defaultMax = 0) => {
  const data = rawData.map(({ date: rawDate, twr: rawTwr }) => {
    const date = moment(rawDate).toDate()
    const twr = parseFloat(rawTwr || '0')

    return {
      date,
      twr,
    }
  })
  const max = Math.max(...data.map(({ twr }) => twr)) || defaultMax
  const min = Math.min(...data.map(({ twr }) => twr), 0)
  const twrReturn = last(data)?.twr

  return {
    max,
    min,
    twrReturn,
    data: data.map(({ date, twr }) => ({
      date,
      value: twr,
    })),
  }
}

export { transformIncomeTwrData }
