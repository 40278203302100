import React from 'react'

import PropTypes from 'prop-types'

import { useCallback, useMediaQueries } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Checkbox } from 'components/atoms/Checkbox'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type MultipleChoiceCardProps = {
  answer: Answer
  tabIndex: number
  selected: boolean
  onClick: (id: number) => void
}

const MultipleChoiceCard = ({ answer, tabIndex, selected, onClick }: MultipleChoiceCardProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { id, title } = answer

  const handleClick = useCallback(() => {
    onClick(id)
  }, [id, onClick])

  return (
    <Link className="Question_Selectable_card" tabIndex={-1}>
      <SelectableCard
        className="Checkbox-Proxy Question_Selectable_card"
        tabIndex={tabIndex}
        selected={selected}
        onClick={handleClick}
        data-test-id="questionnaireCardMultiple"
      >
        <Paper top={desktop ? 24 : 16} bottom={desktop ? 24 : 16} left={desktop ? 32 : 16} right={desktop ? 32 : 16}>
          <Typography size={16} lineHeight="small" color={selected ? 'default' : 'inherit'}>
            <ColumnarLayout mods={{ padding: 'no' }}>
              <Column size={0}>
                <Checkbox tabIndex={-1} type="checkbox" checked={selected} readOnly />
              </Column>
              <Column>
                <Paper left={desktop ? 8 : 12}>
                  <Typo>{title}</Typo>
                </Paper>
              </Column>
            </ColumnarLayout>
          </Typography>
        </Paper>
      </SelectableCard>
    </Link>
  )
}

MultipleChoiceCard.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    extra_data: PropTypes.shape({
      exclusive: PropTypes.bool,
    }),
  }),
  tabIndex: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
}

export { MultipleChoiceCard }
