const mapFiles = (context) => {
  const keys = context.keys()
  const values = keys.map(context)

  return keys.reduce(
    (accumulator, key, index) => ({
      ...accumulator,
      [key]: values[index],
    }),
    {},
  )
}

const allFiles = mapFiles(require.context('./', true, /\.js$/))

export function resetAllErrors() {
  return (dispatch) => {
    const allResets = Object.values(allFiles)
      .filter((module) => typeof module.resetError === 'function')
      .map((module) => module.resetError)

    allResets.forEach((resetErrors) => dispatch(resetErrors()))
  }
}
