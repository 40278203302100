import React, { forwardRef, useEffect } from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useState } from 'hooks'

import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './AddressPeriodSelect.css'

type ValidationProp = {
  errors: string[]
  rules: string[] | number[] | boolean[]
}

type AddressPeriodSelectProps = {
  inputMods: Record<string, string>
  mediaQueries: Record<string, boolean>
  validation: {
    city: ValidationProp
    country: ValidationProp
    isUK: ValidationProp
    months_at_address: ValidationProp
    postcode: ValidationProp
    street: ValidationProp
  }
  value: number
  onAddressFieldChange: (field: string, value: string | object | number | null) => void
}

const AddressPeriodSelect = forwardRef<HTMLDivElement, AddressPeriodSelectProps>(function AddressPeriodSelect({
  mediaQueries,
  inputMods,
  validation,
  value,
  onAddressFieldChange,
}: AddressPeriodSelectProps): React.ReactElement {
  const yearValue = Math.floor(value / 12)
  const monthsValue = value % 12

  const [monthInput, setMonthsInput] = useState(monthsValue)
  const [yearInput, setYearInput] = useState(yearValue)

  useEffect(() => {
    if (monthInput < 12) {
      setYearInput(yearValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, monthInput])

  const handleYearChange = (newValue): void => {
    const newYearValue = parseInt(newValue || '0', 10)

    // do not update months_at_address value while months input is more than 12
    if (monthInput < 12) {
      const value = 12 * newYearValue + monthsValue
      onAddressFieldChange('months_at_address', value || 0)
    }
    setYearInput(newYearValue)
  }

  const handleMonthsChange = (newValue): void => {
    const newMonthsValue = parseInt(newValue || '0', 10)

    setMonthsInput(newMonthsValue)

    if (newMonthsValue < 12) {
      const value = 12 * yearInput + newMonthsValue
      onAddressFieldChange('months_at_address', value)
    } else {
      // hack: setting years to invalid value to invalidate form if user entered months > 12.
      // Needed to show separate validation for months > 12 while operating only with months_at_address value
      onAddressFieldChange('months_at_address', 99999 * 12)
    }
  }

  const classes = classNames('AddressPeriodSelect', getMediaQuieryClasses('AddressPeriodSelect', mediaQueries))

  return (
    <Paper bottom={32}>
      <Validate rules={validation.months_at_address.rules} value={value}>
        {(isValid: boolean, brokenRule: string, onFocus: () => void, onBlur: () => void) => {
          return (
            <React.Fragment>
              <Paper top={8} bottom={8} className={classes}>
                <Label className="AddressPeriodSelect-Label" inlineClm>
                  Time at this address:
                </Label>
                <Label className="AddressPeriodSelect-Select" onFocus={onFocus} onBlur={onBlur} inlineClm>
                  Years
                  <Input
                    type="number"
                    mods={inputMods}
                    data-test-id="addressPeriodYears"
                    onChange={(event) => {
                      handleYearChange(event.target.value)
                    }}
                  >
                    {yearInput}
                  </Input>
                  {!isValid && !(yearValue === 99999 && monthInput > 11 && yearInput < 100) && (
                    <Typography
                      className="AddressPeriodSelect-Error"
                      size={12}
                      color="red"
                      data-test-id="addressPeriodSelectError"
                    >
                      {validation.months_at_address.errors[brokenRule]}
                    </Typography>
                  )}
                </Label>
                <Label className="AddressPeriodSelect-Select" onFocus={onFocus} onBlur={onBlur} inlineClm>
                  Months
                  <Input
                    type="number"
                    mods={inputMods}
                    onChange={(event) => {
                      handleMonthsChange(event.target.value)
                    }}
                    data-test-id="addressPeriodMonths"
                  >
                    {monthInput}
                  </Input>
                  {!isValid && monthInput > 11 && (
                    <Typography
                      className="AddressPeriodSelect-Error"
                      size={12}
                      color="red"
                      data-test-id="addressPeriodSelectError"
                    >
                      Max value is 11
                    </Typography>
                  )}
                </Label>
              </Paper>
            </React.Fragment>
          )
        }}
      </Validate>
    </Paper>
  )
})

export { AddressPeriodSelect }
