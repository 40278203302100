import React from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries, useSelector, useRedirect } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $dictsStore } from 'app/effector/dicts'

import Card from 'components/_old/Card/Card.jsx'
import Width from 'components/_old/Width/Width'

import FinancesForm from 'app/pages/Forms/FinancesForm.jsx'

const FinancesSetupForm = ({
  inputMods,
  onSubmit: handleSubmit,
  onClientFieldChange: handleClientFieldChange,
  ...rest
}): React.ReactElement => {
  const client = useSelector((state) => state.client)
  const { nominatedAccount, isLoading } = useUnit($bankAccountsStore)
  const { dicts } = useUnit($dictsStore)

  const { desktop } = useMediaQueries()

  const form = (
    <FinancesForm
      inputMods={inputMods}
      onSubmit={handleSubmit}
      onClientFieldChange={handleClientFieldChange}
      dicts={dicts}
      {...rest}
    />
  )

  useRedirect({
    to: () => {
      goTo(urlTo('setup-account.individual.setup-funding'))
    },
    rule: client.access_token && !nominatedAccount?.sort_code,
    isLoading,
  })

  if (desktop) {
    return (
      <Card>
        <Width size={22}>{form}</Width>
      </Card>
    )
  }

  return <Width>{form}</Width>
}

export { FinancesSetupForm }
