import { attach } from 'effector'
import { useUnit } from 'effector-react'
import groupBy from 'lodash/groupBy'

import { useCallback, useEffect, useLoading, useSelector, useState } from 'hooks'

import { handleExternalLink } from 'helpers/browser/'
import { sendError } from 'helpers/errorLogging'
import { urlTo, goTo } from 'helpers/router.js'

import { $isNominationAvailable, $bankAccounts } from 'app/effector/bank-accounts'
import { authenticateData } from 'app/effector/bank-accounts/api'
import { $recurringPaymentsStore, fetchRecurringPaymentsFx } from 'app/effector/recurringPayments'

import { showFailToast } from 'app/redux/actions/ui/'
import { getFlatSortedPortfoliosList, getSortedRegulatoryTypes } from 'app/redux/selectors'

const fetchRecurringPaymentsOnPaymentDetailsFx = attach({ effect: fetchRecurringPaymentsFx })

const usePaymentDetails = () => {
  const { isLoading, wait } = useLoading()

  const [bankAccountId, setBankAccountId] = useState(null)

  const [bankAccounts, isNominationAvailable] = useUnit([$bankAccounts, $isNominationAvailable])
  const goals = useSelector((state) => state.portfolios.items)
  const portfoliosList = useSelector(getFlatSortedPortfoliosList)
  const regulatotyTypesOrder = useSelector(getSortedRegulatoryTypes)

  const { recurringPayments, areRecurringPaymentsFetched } = useUnit($recurringPaymentsStore)

  useEffect(() => {
    if (!areRecurringPaymentsFetched) {
      fetchRecurringPaymentsOnPaymentDetailsFx()
    }
  }, [areRecurringPaymentsFetched])

  const handleCloseBankStatementModal = useCallback(() => {
    setBankAccountId(null)
  }, [setBankAccountId])

  const handleSelectBankAccount = useCallback(
    (accountId) => {
      setBankAccountId(accountId)
    },
    [setBankAccountId],
  )

  const handleSubscriptionClick = useCallback((goalId, type) => {
    goTo(
      urlTo(
        type === 'OPEN_BANKING'
          ? 'dashboard.portfolio.options.recurring-payment-edit'
          : 'dashboard.portfolio.options.direct-debit-form',
        { id: goalId },
      ),
    )
  }, [])

  const handleAddNewBank = useCallback(() => {
    try {
      const url = urlTo('dashboard.user-profile.savings-plans.new-bank', {})
      wait(handleExternalLink(authenticateData, url), true)
    } catch (error) {
      sendError(error)
      showFailToast()
    }
  }, [wait])

  const recurringSubscriptionsWithGoalData = [
    ...recurringPayments.open_banking?.map((subscription) => {
      const goal = goals.find((goal) => goal.id === subscription.portfolio_id)

      return {
        ...subscription,
        regulatoryType: goal?.regulatory_type,
        manageType: goal?.manage_type,
        type: 'OPEN_BANKING',
      }
    }),
    ...recurringPayments.direct_debit?.map((subscription) => {
      const goal = goals.find((goal) => goal.id === subscription.goal)

      return {
        ...subscription,
        portfolio_id: subscription.goal,
        regulatoryType: goal?.regulatory_type,
        manageType: goal?.manage_type,
        type: 'DIRECT_DEBIT',
      }
    }),
  ].sort((a, b) => {
    return (
      portfoliosList.findIndex((portfolio) => portfolio.id === a.portfolio_id) -
      portfoliosList.findIndex((portfolio) => portfolio.id === b.portfolio_id)
    )
  })

  const groupedSubscriptions = groupBy(
    recurringSubscriptionsWithGoalData,
    (subscription) => subscription.regulatoryType,
  )

  return {
    isLoading,
    bankAccounts,
    bankAccountId,
    groupedSubscriptions,
    regulatotyTypesOrder,
    isNominationAvailable,
    handleSelectBankAccount,
    handleSubscriptionClick,
    handleCloseBankStatementModal,
    handleAddNewBank,
  }
}

export { usePaymentDetails }
