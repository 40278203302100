import uniqBy from 'lodash/uniqBy'

import { ListOf } from 'app/effector/model'

import { type SecurityList } from './SecurityList'
import { SecurityProvider, type SecurityProviderDTO } from './SecurityProvider'

class SecurityProviderList extends ListOf(SecurityProvider) {
  constructor(...providers: SecurityProviderDTO[]) {
    const independentIndex = providers.findIndex((provider) => SecurityProvider.isIndependent(provider))
    const filteredProviders = providers.filter((provider, index) => {
      if (index === independentIndex) {
        return false
      }

      return Boolean(provider?.name)
    })
    const sortedProviders = filteredProviders.sort((a, b) => {
      return (a.name as string).localeCompare(b.name as string)
    })

    if (independentIndex > -1) {
      sortedProviders.push(providers[independentIndex])
    }

    super(...sortedProviders)
  }

  static createFromSecurityList(securityList: SecurityList): SecurityProviderList {
    const allProviders: SecurityProviderDTO[] = [...securityList].map((security) => ({
      name: security.provider_filter_name,
      logo_uri: security.logo_uri,
    }))

    const providerWithNames = allProviders.filter((provider) => (provider.name?.length ?? '') > 0)
    const allProvidersSorted = providerWithNames.sort((a) => (a.logo_uri ? -1 : 1))
    const uniqueProviders = uniqBy(allProvidersSorted, 'name')

    return new SecurityProviderList(...uniqueProviders)
  }
}

export { SecurityProviderList }
