import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { bulkValidate } from 'helpers/validation.js'

import { useMediaQueries, useState, useCallback } from 'hooks'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { FieldLine } from 'components/_old/atoms/FieldLine'
import { Typo } from 'components/_old/Typo/Typo'
import Text from 'components/_old/Text/Text.jsx'
import Link from 'components/_old/Link/Link.jsx'
import List, { ListItem } from 'components/_old/List/List.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Sticked from 'components/molecules/Sticked/Sticked.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'
import Button from 'components/_old/Button/Button.jsx'

import './YearlyReviewRetakeForm.css'

// TODO: should take out in YearlyReview page
const YearlyReviewRetakeForm = ({ className, updateAddressUrl, onSend: handleSend, onCancel: handleCancel }) => {
  const [message, setMessage] = useState('')
  const [sent, changeSent] = useState(false)

  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const validation = {
    message: {
      rules: [message, message.length <= 10000],
      errors: ['Message can’t be empty', 'Message must not exceed 10000 characters'],
    },
  }

  const handleSendSubmit = useCallback(() => {
    if (typeof handleSend === 'function') {
      handleSend(message)
    }
    changeSent(true)
  }, [message, handleSend])

  const classes = classNames(
    'YearlyReviewRetakeForm',
    className,
    getMediaQuieryClasses('YearlyReviewRetakeForm', mediaQueries),
  )

  const buttons = (
    <ButtonGroup className="YearlyReviewRetakeForm-Buttons" columnarLayoutMods={{ padding: 'micro' }}>
      <Button
        type="button"
        mods={{ size: 'big block', theme: 'simple-reverse-blue' }}
        onClick={handleCancel}
        block
        data-test-id="yearlyReviewFormCancel"
      >
        Cancel
      </Button>
      <Button
        type="button"
        mods={{ size: 'big block', color: 'green' }}
        disabled={!bulkValidate(validation)}
        onClick={handleSendSubmit}
        block
        data-test-id="yearlyReviewFormSubmit"
      >
        Send changes
      </Button>
    </ButtonGroup>
  )

  const fieldContainer = (
    <div className="YearlyReviewRetakeForm-FieldContainer">
      <Validate rules={validation.message.rules}>
        <Input
          name="message"
          type="textarea"
          onChange={(_, value) => setMessage(value)}
          rows={6}
          data-test-id="yearlyReviewFormInput"
        >
          {message}
        </Input>
      </Validate>
      <Text className="YearlyReviewRetakeForm-SubInputText" block small>
        <Typo>Our Support team will get back to you shortly</Typo>
      </Text>
      {desktop && buttons}
      {!desktop && (
        <Sticked into="afterLayout" newButton>
          <Inner>{buttons}</Inner>
        </Sticked>
      )}
    </div>
  )

  return (
    <FieldLine
      className={classes}
      label="Changes"
      layout={desktop ? 'horizontal' : 'vertical'}
      data-test-id="yearlyReviewForm"
    >
      <div className="YearlyReviewRetakeForm-Content">
        <Text bold>
          <List className="YearlyReviewRetakeForm-List" mods={{ type: 'bullet' }}>
            {updateAddressUrl && (
              <ListItem>
                <Fragment>
                  <Typo>Moved?</Typo>{' '}
                  <Link to={updateAddressUrl} data-test-id="yearlyReviewFormUpdateAddress">
                    Update address
                  </Link>
                </Fragment>
              </ListItem>
            )}
            <ListItem>
              <Typo>Any changes other than your address? Tell us below:</Typo>
            </ListItem>
          </List>
        </Text>
        {sent ? (
          <Text block color="green">
            <Typo>Your changes have been sent. Our Support team will get back to you shortly.</Typo>
          </Text>
        ) : (
          fieldContainer
        )}
      </div>
    </FieldLine>
  )
}

YearlyReviewRetakeForm.propTypes = {
  className: PropTypes.string,
  updateAddressUrl: PropTypes.string,
  onSend: PropTypes.func,
  onCancel: PropTypes.func,
}

export { YearlyReviewRetakeForm }
