import cookie from 'js-cookie'

const useCSRFToken = ({ request }) => {
  if (!request) {
    return
  }

  const CSRFToken = cookie.get('csrftoken')

  request.headers.Accept = '*/*'

  if (CSRFToken && request?.headers) {
    request.headers['X-CSRFToken'] = CSRFToken
  }
}

export { useCSRFToken }
