import isFunction from 'lodash/isFunction'

import { useCallback, useEffect, useState } from 'hooks'

import axios from 'helpers/ajax'

import { statuses } from '../constants.js'

const useFileUpload = ({ status: initialStatus, url, dataType, onAfterUpload }) => {
  const [status, setStatus] = useState(initialStatus || statuses.DEFAULT)

  useEffect(() => {
    setStatus(initialStatus)
  }, [initialStatus, setStatus])

  const handleUpload = useCallback(
    (data) => {
      setStatus(statuses.LOADING)

      axios(url, { method: 'POST', data })
        .then((response) => {
          setStatus(statuses.DONE)

          if (isFunction(onAfterUpload)) {
            onAfterUpload(response.data)
          }
        })
        .catch(() => {
          setStatus(statuses.FAILED)
        })
    },
    [onAfterUpload, url],
  )

  const onDrop = useCallback(
    (acceptedFiles) => {
      const data = new FormData()
      data.append(dataType, acceptedFiles[0])

      handleUpload(data)
    },
    [handleUpload, dataType],
  )

  return {
    status,
    onDrop,
  }
}

export { useFileUpload }
