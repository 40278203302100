import React, { Fragment } from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import moment from 'helpers/date.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Projections from 'components/_old/Projections/Projections.jsx'
import ProjectionsTooltip from 'components/_old/ProjectionsTooltip/ProjectionsTooltip.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type ChartProps = {
  chartRef: any
  showChart: any
  projections: any
  hideName: any
  title: any
  target: any
  projectionsFetched: any
  data: any
  endYear: any
  endData: any
}

const Chart = ({
  chartRef,
  showChart,
  projections,
  hideName,
  title,
  target,
  projectionsFetched,
  data,
  endYear,
  endData,
}: ChartProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <div ref={chartRef}>
        <Card
          className={classNames('EditGrowthPortfolio-Chart', 'EditGrowthPortfolio-Chart_updated')}
          mods={{
            theme: 'transparent',
            'no-padding': 'left right',
          }}
        >
          {desktop ? null : (
            <Inner>
              <Headline
                className="EditGrowthPortfolio-ChartHeadline"
                level={4}
                mods={{ text: 'normal center', 'no-margin': 'top' }}
              >
                Projected returns
              </Headline>
            </Inner>
          )}
          {showChart ? (
            <Projections
              title={hideName ? null : title}
              target={parseFloat(target)}
              fetched={projectionsFetched}
              data={data}
              minY={projections ? projections.min_y : null}
              maxY={projections ? projections.max_y : null}
              width={desktop ? 577 : 320}
              height={desktop ? 420 : 140}
              desktop={desktop}
              monthly={projections.monthly}
              data-test-id="editGrowthPortfolioProjectionsChart"
              offsetYLabels={false}
            />
          ) : (
            <Card
              mods={{ theme: 'transparent' }}
              style={{ height: desktop ? 294 : 116 }}
              flex
              data-test-id="editGrowthPortfolioNoInitialInvestmentText"
            >
              <AllCenter>
                <Text muted>Please, enter your initial investment</Text>
              </AllCenter>
            </Card>
          )}
        </Card>
      </div>
      <div>
        <ColumnarLayout>
          <Typography color="minor" size={desktop ? 16 : 12}>
            <Typo>{moment(new Date()).format('YYYY')}</Typo>
          </Typography>
          <Typography align="right" size={desktop ? 16 : 12} color="minor">
            <Typo>{endYear}</Typo>
          </Typography>
        </ColumnarLayout>

        {/* tooltip */}
        {!desktop && showChart ? (
          <Paper top={48}>
            <Card
              className="EditGrowthPortfolio-Probability"
              style={{ border: 'none' }}
              mods={{
                theme: 'white straight-corners',
                'no-padding': 'all',
              }}
            >
              <ProjectionsTooltip date={endYear} series={endData} />
            </Card>
          </Paper>
        ) : null}
      </div>
    </Fragment>
  )
}

export { Chart }
