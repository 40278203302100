import React, { lazy, Suspense } from 'react'
import classNames from 'classnames/dedupe'
import { Preloader } from 'components/molecules/Preloader'
import './Projections.css'

const ProjectionsChart = lazy(
  async () =>
    await import(/* webpackChunkName: "ProjectionsChart" */ 'components/_old/ProjectionsChart/ProjectionsChart.jsx'),
)

export default class Projections extends React.Component {
  constructor(props) {
    super(props)
    this.prevTarget = null
  }

  render() {
    const { fetched, data, height, desktop, 'data-test-id': dataTestId } = this.props
    const chartHeight = height + 104
    const passProps = { ...this.props, height: chartHeight }

    const classes = classNames('Projections', {
      Projections_desktop: desktop,
      Projections_phone: !desktop,
    })

    const shouldUpdate = !(data.length < 1 || !fetched)
    const targetChanged = this.props.target !== this.prevTarget
    this.prevTarget = this.props.target

    return (
      <div className={classes} style={{ height }} data-test-id={dataTestId}>
        <Preloader
          className="Projections-Preloader"
          loading={data.length < 1 || !fetched}
          data-test-id="projectionsPreloader"
        />
        {data.length > 0 ? (
          <Suspense fallback={<Preloader className="Projections-Preloader" loading={true} />}>
            <ProjectionsChart
              {...passProps}
              key={Number(desktop)}
              shouldUpdate={shouldUpdate}
              targetChanged={targetChanged}
            />
          </Suspense>
        ) : null}
      </div>
    )
  }
}
