import React from 'react'
import { modsToClassnames } from 'helpers/classname.js'
import classNames from 'classnames/dedupe'
import './Headline.css'

export default function Headline(props) {
  const {
    mods,
    level = 1,
    className,
    inline = false,
    children,
    seoHeadline,
    customSeoHeadline,
    style,
    ...restProps
  } = props

  const tag = seoHeadline ? `h${level}` : customSeoHeadline ? `h${customSeoHeadline}` : 'div'

  const classes = classNames(className, modsToClassnames('Headline', mods), {
    Headline: true,
    Headline_inline: inline,
    [`Headline_level${level}`]: level,
  })

  return React.createElement(tag, { ...restProps, className: classes, style }, children)
}
