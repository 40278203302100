import React from 'react'

import { useCallback, useMediaQueries, useSelector } from 'hooks'

import type { Portfolio } from 'app/redux/models/portfolio/types'
import { selectGoalTitle } from 'app/redux/selectors'

import { Typo } from 'components/_old/Typo/Typo'

import { CardAccent } from 'components/atoms/CardAccent'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import { PortfolioManagedBy } from 'components/molecules/PortfolioManagedBy/PortfolioManagedBy'

import { portfolioColors } from 'constants/portfolio'

type PortfolioCardProps = {
  portfolio: Portfolio
  onClick: (id: string) => void
}

const PortfolioCard = ({ portfolio, onClick }: PortfolioCardProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const title = useSelector((state) => selectGoalTitle(state, portfolio.id))

  const handleClick = useCallback(() => {
    onClick(portfolio?.id)
  }, [onClick, portfolio?.id])

  return (
    <SelectableCard onClick={handleClick}>
      <CardAccent color={portfolioColors[portfolio.manage_type]} />
      <div className="PortfolioCard-Wrapper">
        <Paper top={24} right={desktop ? 24 : 20} bottom={24} left={desktop ? 32 : 20}>
          <Typography size={desktop ? 18 : 16} lineHeight={desktop ? 'medium' : 'small'} weight="semibold" align="left">
            <Typo allowHtml={false}>{title}</Typo>
          </Typography>
          <Paper top={4}>
            <PortfolioManagedBy
              manageType={portfolio.manage_type}
              regulatoryType={portfolio.regulatory_type}
              isDefaultPresetUsed={portfolio.is_default_preset_used}
            />
          </Paper>
        </Paper>
      </div>
    </SelectableCard>
  )
}

export { PortfolioCard }
