import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import compose from 'helpers/compose.js'
import { format, formatPercent } from 'helpers/money'

import rawMediaQueries from 'helpers/mediaQueries.js'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Headline from 'components/_old/Headline/Headline.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Link from 'components/_old/Link/Link.jsx'

import './HorizontalChart.css'

const HorizontalChart = ({
  label = '',
  assets = [],
  selectedAsset,
  className,
  onClick,
  sumAmount,
  multiplier = 1,
  headless,
  unhoverable,
  mediaQueries,
  showOnlyAmount,
  showWeightOnly,
  largeTotalValueClm,
  'data-item-test-id': dataItemTestId,
}) => {
  const classes = classNames(
    className,
    'HorizontalChart',
    {
      HorizontalChart_clickable: !unhoverable && typeof onClick === 'function',
      HorizontalChart_largeTotalValueClm: largeTotalValueClm,
    },
    getMediaQuieryClasses('HorizontalChart', mediaQueries),
  )
  const totalValueClmClass = classNames('HorizontalChart-TotalValue', 'HorizontalChart-Column', {
    'HorizontalChart-TotalValue_large': largeTotalValueClm,
  })

  const handleClick = useCallback(
    (item) => {
      let onClickFunc = onClick

      if (unhoverable || !onClick) {
        onClickFunc = () => {}
      }

      onClickFunc(item)
    },
    [onClick, unhoverable],
  )

  const formattedSumAmount = sumAmount ? formatPercent(sumAmount / 100, true) : null

  return (
    <div className={classes}>
      {headless ? null : (
        <div className="HorizontalChart-Headline">
          <Headline className="HorizontalChart-Label" level={3}>
            <Typo>
              {label} {formattedSumAmount}
            </Typo>
          </Headline>
          <Headline className="HorizontalChart-Column HorizontalChart-ColumnTitle HorizontalChart-Weight">
            Weight
          </Headline>
          {showWeightOnly || (!showOnlyAmount && <Headline className={totalValueClmClass}>Value</Headline>)}
          {showOnlyAmount && <div className="HorizontalChart-Chart" />}
        </div>
      )}
      {assets.map((item, i) => {
        const isSelected = selectedAsset ? item.id === selectedAsset.id : false
        const itemClasses = classNames('HorizontalChart-Item', {
          'HorizontalChart-Item_selected': isSelected,
          'HorizontalChart-Item_margin-top': headless && i === 0,
        })
        const title = !showOnlyAmount && headless ? `${item.title} ${formattedSumAmount}` : item.title

        return (
          <div
            key={i}
            className={itemClasses}
            onClick={() => handleClick(item)}
            data-test-id={dataItemTestId}
            data-selected={isSelected}
          >
            <div className="HorizontalChart-Label">
              {unhoverable ? (
                <span>
                  <Typo>{title}</Typo>
                </span>
              ) : (
                <Link>
                  <Typo>{title}</Typo>
                </Link>
              )}
            </div>
            <div className="HorizontalChart-Column HorizontalChart-ColumnValue HorizontalChart-Weight">
              {formatPercent(item.amount / 100, true)}
            </div>
            {showWeightOnly ||
              (!showOnlyAmount && <div className={totalValueClmClass}>{format(item.total_value, true)}</div>)}
            {showOnlyAmount && (
              <div className="HorizontalChart-Chart">
                <div
                  className="HorizontalChart-Line"
                  style={{
                    width: `${item.amount * multiplier}%`,
                    backgroundColor: item.backgroundColor,
                  }}
                />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

HorizontalChart.propTypes = {
  label: PropTypes.string,
  assets: PropTypes.array,
  selectedAsset: PropTypes.object,
  className: PropTypes.string,
  sumAmount: PropTypes.number,
  multiplier: PropTypes.number,
  headless: PropTypes.bool,
  unhoverable: PropTypes.bool,
  mediaQueries: PropTypes.object,
  showOnlyAmount: PropTypes.bool,
  showWeightOnly: PropTypes.bool,
  largeTotalValueClm: PropTypes.bool,
  'data-item-test-id': PropTypes.string,

  onClick: PropTypes.func,
}

export default compose(withMediaQueries(rawMediaQueries))(HorizontalChart)
