import React, { createElement, type ReactNode, type ReactElement } from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMemo, useMediaQueries } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'

import { Paper } from 'components/atoms/Paper'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import './List.css'

type LinkListItemProps = {
  to: string
  hard?: boolean
  blank?: boolean
}

type FunctionListItemProps = {
  onClick?: () => void
}

type LinkOrFunctionListItemProps = XOR<LinkListItemProps, FunctionListItemProps>

type ListItemProps = {
  className?: string
  tag?: string
  leftSide: ReactNode
  rightSide: ReactNode
  active?: boolean
  disabled?: boolean
  top?: number
  right?: number
  bottom?: number
  left?: number
  'data-test-id'?: string
  isBoardWithPadding?: boolean
} & LinkOrFunctionListItemProps

function ListItem({
  className,
  tag = 'div',
  leftSide,
  rightSide,
  to,
  blank,
  hard,
  active = false,
  disabled = false,
  top = 16,
  right = 16,
  bottom = 16,
  left = 16,
  onClick,
  isBoardWithPadding = false,
  'data-test-id': dataTestId,
}: ListItemProps): ReactElement {
  const mediaQueries = useMediaQueries()
  const classes = classNames(
    className,
    '_new_List-Item',
    {
      '_new_List-Item_disabled': disabled,
      '_new_List-Item_active': active,
      '_new_List-Item_shorterBoard': isBoardWithPadding,
    },
    getMediaQuieryClasses('_new_List-Item', mediaQueries),
  )

  const children = useMemo(() => {
    const inner = (
      <Paper top={top} right={right} bottom={bottom} left={left}>
        <ColumnarLayout className="_new_List-ItemColumnarLayout" mods={{ padding: 'no' }}>
          <Column size={1}>{leftSide}</Column>
          <Column size={0}>{rightSide}</Column>
        </ColumnarLayout>
      </Paper>
    )

    if (to) {
      return (
        <Link className="_new_List-ItemInner" to={to} blank={blank} hard={hard} classnameless data-test-id={dataTestId}>
          {inner}
        </Link>
      )
    }

    return (
      <button className="_new_List-ItemInner" disabled={disabled} onClick={onClick} data-test-id={dataTestId}>
        {inner}
      </button>
    )
  }, [leftSide, rightSide, to, blank, hard, onClick, bottom, dataTestId, disabled, left, right, top])

  return createElement(tag, { className: classes }, children)
}

type ListProps = {
  tag?: string
  className?: string
  hideUnderlineOnLastItem?: boolean
  children: ReactElement<ListItemProps> | Array<ReactElement<ListItemProps>>
}

function List({ tag = 'div', className, hideUnderlineOnLastItem, children }: ListProps): ReactElement {
  const classes = classNames(className, '_new_List', { _new_List_hideUnderlineOnLastItem: hideUnderlineOnLastItem })

  return createElement(tag, { className: classes }, children)
}

export { ListItem, type ListItemProps, List, type ListProps }
