import React from 'react'
import PropTypes from 'prop-types'

import Link from 'components/_old/Link/Link.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typography } from 'components/atoms/Typography'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import './TunnelHeader.css'

const TunnelHeader = ({ title, onBack: handleBack, onCancel: handleCancel, cancelTitle = 'Cancel' }) => {
  return (
    <div className="TunnelHeader">
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column size={1}>
          {handleBack && (
            <Link
              onClick={handleBack}
              mods={{ color: 'content-on-background-default' }}
              data-test-id="tunnelHeaderBackButton"
            >
              <ItemWithIcon
                space={16}
                icon={<Icon type="back-new" size={20} color="inherit" />}
                content={<Typography color="inherit">Back</Typography>}
                iconVerticalAlign="center"
              />
            </Link>
          )}
        </Column>
        <Column size={2}>
          <Typography size={20} align="center" weight="semibold" lineHeight="small">
            {title}
          </Typography>
        </Column>
        <Column size={1}>
          {handleCancel && (
            <Link
              onClick={handleCancel}
              mods={{ color: 'content-on-background-default' }}
              tabIndex="1"
              data-test-id="tunnelHeaderCancelButton"
            >
              <Typography align="right" lineHeight="small" color="inherit">
                {cancelTitle}
              </Typography>
            </Link>
          )}
        </Column>
      </ColumnarLayout>
    </div>
  )
}

TunnelHeader.propTypes = {
  title: PropTypes.string,
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  cancelTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export { TunnelHeader }
