import React from 'react'

import { GrantAccess } from './GrantAccess'

export default {
  module: 'grant-access',
  path: 'oauth/authorise_third_party/',
  name: 'GrantAccess',
  isNewLayout: true,
  component: function Component(props) {
    return <GrantAccess {...props} />
  },
}
