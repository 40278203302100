import _isEmpty from 'lodash/isEmpty'

import { useMemo } from 'hooks'

import { usePendingOrders } from 'app/pages/Dashboard/Goals/DIY/hooks'

import { scope as pendingOrderScope } from 'constants/pendingOrder.js'

type UsePortfolioOrdersInterface = {
  isEmpty: boolean
}

const usePortfolioOrders = (
  portfolioId: string | number,
  shouldLoadPendingOrdersOnMount?: boolean,
): UsePortfolioOrdersInterface => {
  const { orders } = usePendingOrders(shouldLoadPendingOrdersOnMount)
  const portfolioOrders = useMemo(() => orders.getByPortfolioId(portfolioId), [portfolioId, orders])
  const portfolioScopeOrders = useMemo(() => portfolioOrders.getByScope(pendingOrderScope.PORTFOLIO), [portfolioOrders])
  const isEmpty = useMemo(() => _isEmpty(portfolioScopeOrders), [portfolioScopeOrders])

  return { isEmpty }
}

export { usePortfolioOrders }
