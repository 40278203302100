import React from 'react'
import { withRouter } from 'react-router'

import { useCallback, useMemo, useEffect } from 'hooks'

import { palette } from 'helpers/palette'
import { goTo, urlTo } from 'helpers/router.js'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { OptionsLayout } from '../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../Options/OptionsNavigationBar'

type DirectDebitGuaranteeProps = {
  portfolio: Portfolio
  location: { query: Record<string, string> }
}

const DirectDebitGuarantee = withRouter(({ portfolio, location }: DirectDebitGuaranteeProps): React.ReactElement => {
  const handleBack = useCallback(() => {
    goTo(urlTo(`dashboard.portfolio.options.direct-debit-form`, { id: portfolio?.id }, location.query), {
      replace: true,
      scrollToTop: false,
    })
  }, [portfolio?.id, location?.query])

  useEffect(() => {
    const modalInside = document.querySelector('._new_Modal-Inside')

    if (modalInside) {
      modalInside.scrollTop = 0
    }
  }, [])

  const content = useMemo(
    () => (
      <Width size={30} center>
        <Paper top={8}>
          <Typography size={24} align="center" weight="semibold">
            <Typo>Direct Debit Guarantee</Typo>
          </Typography>
        </Paper>
        <Card mods={{ text: 'center', theme: 'transparent' }}>
          <Icon type="directDebit" width={112} height={36} color={palette['content-on-background-default']} inline />
        </Card>
        <Typography size={14}>
          <Paper bottom={16}>
            <Typo>
              The Guarantee is offered by all banks and building societies that accept instructions to pay Direct
              Debits.
            </Typo>
          </Paper>
          <Paper bottom={40}>
            <Typo>
              If there are any changes to the amount, date or frequency of your Direct Debit GC re InvestEngine (UK)
              Limited will notify you (normally 3 working days) in advance of your account being debited or as otherwise
              agreed. If you request GC re InvestEngine (UK) Limited to collect a payment, confirmation of the amount
              and date will be given to you at the time of the request.
            </Typo>
          </Paper>
          <Paper bottom={16}>
            <Typo>
              If an error is made in the payment of your Direct Debit, by GC re InvestEngine (UK) Limited or your bank
              or building society, you are entitled to a full and immediate refund of the amount paid from your bank or
              building society.
            </Typo>
          </Paper>
          <Paper bottom={40}>
            <Typo>
              If you receive a refund you are not entitled to, you must pay it back when GC re InvestEngine (UK) Limited
              asks you to.
            </Typo>
          </Paper>
          <Typo>
            You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written
            confirmation may be required. Please also notify GC re InvestEngine (UK) Limited
          </Typo>
        </Typography>
      </Width>
    ),
    [],
  )

  return (
    <OptionsLayout
      header={
        <OptionsNavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
          &nbsp;
        </OptionsNavigationBar>
      }
      content={content}
      button={
        <Button onClick={handleBack} mods={{ size: 'big block' }} data-test-id="checkPaymentDetailsButton">
          Continue
        </Button>
      }
      data-test-id="checkPaymentDetailsModal"
    />
  )
})

export { DirectDebitGuarantee }
