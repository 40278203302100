import React from 'react'

import { useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type TwoFactorAuthSetupMobileProps = {
  handleBack: () => void
  handleContinue: () => void
}

const Mobile = ({ handleBack, handleContinue }: TwoFactorAuthSetupMobileProps): React.ReactElement => {
  const headerNode = useMemo(() => <NavigationBar leftPartText="Back" onLeftPartClick={handleBack} />, [handleBack])

  const titleNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography size={24} lineHeight="small" weight="semibold" align="center">
          <Typo>Disable 2FA authentication</Typo>
        </Typography>
      </Paper>
    ),
    [],
  )

  const descriptionNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography align="center">
          <Typo>
            If you disable two-factor authentication, your InvestEngine account will only be password-protected.
          </Typo>
        </Typography>
      </Paper>
    ),
    [],
  )

  const contentNode = (
    <AllCenter>
      <Icon type="disable-2fa" size={80} />
      {titleNode}
      {descriptionNode}
    </AllCenter>
  )

  const footer = useMemo(
    () => (
      <MobileLayoutFooterButton>
        <Button mods={{ size: 'big block' }} onClick={handleContinue}>
          Disable
        </Button>
      </MobileLayoutFooterButton>
    ),
    [handleContinue],
  )

  return <MobileLayout header={headerNode} content={contentNode} footer={footer} />
}

export { Mobile }
