import React from 'react'
import isFunction from 'lodash/isFunction'

import { palette } from 'helpers/palette/'

import Icon from 'components/_old/Icon/Icon.jsx'
import Modal from 'components/_old/Modal/Modal.jsx'
import Link from 'components/_old/Link/Link.jsx'

import './InlineHelp.css'

class InlineHelp extends React.Component {
  state = {
    visible: false,
  }

  handleModalOpen = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ visible: true })
  }

  handleModalClose = () => {
    this.setState({ visible: false })
  }

  componentWillUnmount = () => {
    this.handleModalClose()
  }

  render() {
    const {
      className,
      children,
      gatewayName,
      insideGatewayName,
      helper,
      style,
      zIndex,
      iconType = 'question',
      color = 'content-on-color-default',
      background = 'status-warning',
      dataIconTestId = 'inlineHelpIcon',
      dataModalTestId = 'inlineHelpModal',
      size = 24,
    } = this.props
    const { visible } = this.state

    return (
      <span className={className} style={style}>
        {helper ? (
          React.cloneElement(helper, { onClick: this.handleModalOpen })
        ) : (
          <Link className="InlineHelp" onClick={this.handleModalOpen} data-test-id={dataIconTestId}>
            <Icon
              className="InlineHelp-Icon"
              type={iconType}
              color={color && palette[color]}
              background={background && palette[background]}
              size={size}
              rounded
              inline
            />
          </Link>
        )}
        <Modal
          gatewayName={gatewayName}
          insideGatewayName={insideGatewayName}
          open={visible}
          onClose={this.handleModalClose}
          zIndex={zIndex}
          data-test-id={dataModalTestId}
        >
          {isFunction(children) ? children(this.handleModalClose) : children}
        </Modal>
      </span>
    )
  }
}

export default InlineHelp
