import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

/**
 * @class
 * @typedef {Object} SecurityStatsItem
 */
export class SecurityStatsItem extends Entity {
  constructor() {
    super()

    /** @type {?number} */
    this.return_money = null
    /** @type {?number} */
    this.return_percent = null
    /** @type {?number} */
    this.paid_out = null
  }

  /** @override */
  getRules() {
    return {
      return_money: [[rules.float]],
      return_percent: [[rules.float]],
      paid_out: [[rules.float]],
    }
  }
}
