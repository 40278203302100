import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useCallback, useMemo, useState } from 'hooks'

import { propTypes } from 'helpers/propTypes'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { AnimatedNavigationBar } from 'components/molecules/AnimatedNavigationBar'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'
import { SecurityOrder } from 'components/organisms/SecurityOrder/SecurityOrder.jsx'

import { Confirm } from 'app/pages/Dashboard/Goals/DIY/Review/components'
import { Notice } from 'app/pages/Dashboard/Goals/DIY/Rebalance/components/Notice'

import { SecurityList } from 'app/effector/securities/models'
import { PendingOrder } from 'app/effector/pending-orders/models'

const Mobile = ({ order, securities, handleClose, handleConfirm }) => {
  const [titleNode, setTitleNode] = useState(null)
  const titleRef = useCallback((ref) => setTitleNode(ref), [])
  const title = useMemo(() => <Typo>Rebalancing orders</Typo>, [])

  const header = useMemo(
    () => (
      <AnimatedNavigationBar nodeToWatchTo={titleNode} leftPartText="Back" onLeftPartClick={handleClose}>
        {title}
      </AnimatedNavigationBar>
    ),
    [titleNode, title, handleClose],
  )

  const content = useMemo(
    () => (
      <Fragment>
        <Typography ref={titleRef} size={32} lineHeight="small" weight="semibold">
          {title}
        </Typography>
        <Notice />
        <Paper top={48} bottom={40}>
          {order && securities && <SecurityOrder order={order} securities={securities} />}
        </Paper>
      </Fragment>
    ),
    [order],
  )

  const footer = useMemo(
    () => (
      <MobileLayoutFooterButton opaque>
        <Confirm handleConfirm={handleConfirm} />
      </MobileLayoutFooterButton>
    ),
    [handleConfirm],
  )

  return <MobileLayout header={header} content={content} footer={footer} />
}

Mobile.propTypes = {
  order: propTypes.instanceOf(PendingOrder),
  securities: propTypes.instanceOf(SecurityList),
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
}

export { Mobile }
