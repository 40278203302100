import React, { Fragment } from 'react'
import classNames from 'classnames/dedupe'
import isFunction from 'lodash/isFunction'
import PropTypes from 'prop-types'

import { palette } from 'helpers/palette/'

import { useCallback, useMediaQueries, useMemo } from 'hooks'

import { Badge } from 'components/atoms/Badge'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MaxLines } from 'components/atoms/MaxLines'
import { Nobr } from 'components/_old/Nobr'
import { Paper } from 'components/atoms/Paper'
import { PercentageBar } from 'components/atoms/PercentageBar'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Skeleton } from 'components/atoms/Skeleton'
import { Ticker } from 'components/atoms/Ticker'
import { Typo } from 'components/_old/Typo/Typo'
import { Typography } from 'components/atoms/Typography'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import './SecurityItem.css'

const colorsByType = {
  STOCK: palette.stocks,
  BOND: palette.bonds,
  ALTERNATIVE: palette.alternatives,
}

const SecurityItem = ({
  added,
  href,
  id,
  onClick,
  onSettled,
  ter,
  ticker,
  title,
  type,
  dividends_type: dividendsType,
  estimated_yield: yieldValue,
  is_featured: isFeatured,
  logo_uri: logoUri,
  short_description: shortDescription,
  target_weight: targetWeight,
  isLoading = false,
  showDescription = true,
  showLabels = true,
  showTargetWeight = false,
  'data-test-id': dataTestId,
}) => {
  const { desktop } = useMediaQueries()
  const classes = useMemo(
    () =>
      classNames('SecurityItem', {
        SecurityItem_skeleton: isLoading,
        SecurityItem_skeleton_noLabels: !showLabels && isLoading,
        SecurityItem_mobile: !desktop,
      }),
    [isLoading, desktop],
  )

  const handleClick = useCallback(
    (event) => {
      const isOpenInNewTab = event?.ctrlKey || event?.metaKey
      const hasHref = href && href.length > 0

      if (event && hasHref && !isOpenInNewTab) {
        event.preventDefault()
      }

      if (!isOpenInNewTab && isFunction(onClick)) {
        onClick(id)
      }
    },
    [href, onClick, id],
  )

  const logo = <SecurityLogo logo={logoUri} size={desktop ? 48 : 32} />

  const hasDetails = ticker || shortDescription

  const mark = useMemo(
    () => (
      <Paper className="SecurityItem-Mark" left={16} data-test-id={`${dataTestId}AddedMark`}>
        <Typography tag="div" size={12} weight="semibold">
          <Badge theme="rounded-green" text="Added" />
        </Typography>
      </Paper>
    ),
    [dataTestId],
  )

  const hasLabels = useMemo(() => dividendsType || ter, [dividendsType, ter])

  const labelsNode = useMemo(
    () => (
      <Typography lineHeight="small">
        <ColumnarLayout>
          <Column size={1}>
            <ColumnarLayout mods={{ padding: 'no', flexWrap: 'wrap' }}>
              {ter && (
                <Column size={0}>
                  <Paper right={12} bottom={8}>
                    <Nobr>
                      <Typography color="additional" lineHeight="small" size={14} style={{ whiteSpace: 'nowrap' }}>
                        <Typo>TER {ter}</Typo>
                      </Typography>
                    </Nobr>
                  </Paper>
                </Column>
              )}
              {dividendsType === 'ACCUMULATING' && (
                <Column size={0}>
                  <Paper right={12} bottom={8} data-test-id="securityItemLabel">
                    <Typography
                      color="additional"
                      lineHeight="small"
                      size={14}
                      data-test-id="securityItemAccumulatingLabel"
                    >
                      Accumulating
                    </Typography>
                  </Paper>
                </Column>
              )}
              {yieldValue && (
                <Column size={0}>
                  <Paper bottom={8} data-test-id="securityItemLabel">
                    <Nobr>
                      <Typography
                        color="additional"
                        lineHeight="small"
                        size={14}
                        style={{ whiteSpace: 'nowrap' }}
                        data-test-id="securityItemYieldLabel"
                      >
                        <Typo>Yield {yieldValue}%</Typo>
                      </Typography>
                    </Nobr>
                  </Paper>
                </Column>
              )}
            </ColumnarLayout>
          </Column>
        </ColumnarLayout>
      </Typography>
    ),
    [dividendsType, ter, yieldValue],
  )

  const details = useMemo(
    () => (
      <div className="SecurityItem-Details">
        {added && mark}
        <div className="SecurityItem-Title">
          {!showTargetWeight && (
            <Typography weight="semibold" lineHeight={desktop ? 'medium' : 'small'} data-test-id={`${dataTestId}Title`}>
              <Typo>{title}</Typo>
            </Typography>
          )}
          {showTargetWeight && (
            <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'flex-end' }}>
              <Column size={1}>
                <Typography
                  size={desktop ? 16 : 14}
                  weight="semibold"
                  lineHeight={desktop ? 'medium' : 'small'}
                  data-test-id={`${dataTestId}Title`}
                >
                  <Typo>{title}</Typo>
                </Typography>
              </Column>
              <Column size={0}>
                <Paper left={8}>
                  <Typography
                    size={desktop ? 16 : 14}
                    weight="semibold"
                    align="right"
                    lineHeight={desktop ? 'medium' : 'small'}
                    data-test-id={`${dataTestId}TargetWeight`}
                  >
                    {targetWeight}%
                  </Typography>
                </Paper>
              </Column>
            </ColumnarLayout>
          )}
        </div>
        {hasDetails && (
          <Fragment>
            <ColumnarLayout mods={{ padding: 'no' }}>
              <Column size={1}>
                <Typography
                  size={14}
                  lineHeight="medium"
                  color={palette['content-on-background-default']}
                  data-test-id={`${dataTestId}Details`}
                >
                  <MaxLines
                    cacheName="SecurityItem"
                    defaultMaxHeight={desktop ? 44 : 36}
                    color={palette['background-card-opaque']}
                    lines={2}
                    onAfterModify={onSettled}
                  >
                    {ticker && (
                      <Paper className="SecurityItem-Ticker" right={desktop ? 8 : 4} inline>
                        <Ticker text={ticker} data-test-id={`${dataTestId}Ticker`} />
                      </Paper>
                    )}
                    {showDescription && <Typo>{shortDescription}</Typo>}
                  </MaxLines>
                </Typography>
              </Column>
              {showTargetWeight && (
                <Column size={1}>
                  <Paper top={12}>
                    <PercentageBar amount={targetWeight} color={colorsByType[type]} />
                  </Paper>
                </Column>
              )}
            </ColumnarLayout>
            <Paper top={8}>
              {showLabels && (
                <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'flex-end' }}>
                  <Column size={1}>{labelsNode}</Column>
                  <Column size={0}>
                    {isFeatured && (
                      <Paper bottom={hasLabels ? 8 : 0}>
                        <Typography size={14} lineHeight="small" color="additional">
                          Featured
                        </Typography>
                      </Paper>
                    )}
                  </Column>
                </ColumnarLayout>
              )}
            </Paper>
          </Fragment>
        )}
      </div>
    ),
    [
      added,
      mark,
      desktop,
      hasDetails,
      ticker,
      shortDescription,
      targetWeight,
      title,
      type,
      dataTestId,
      onSettled,
      showDescription,
      showTargetWeight,
      labelsNode,
      hasLabels,
      isFeatured,
    ],
  )

  return (
    <Skeleton shown={isLoading} mix shownProps={{ 'data-test-id': `${dataTestId}-skeleton` }}>
      <SelectableCard
        className={classes}
        tag="a"
        href={href}
        selected={added}
        onClick={handleClick}
        data-added={added}
        data-security-id={id}
        data-test-id={dataTestId}
      >
        {!isLoading && (
          <Paper top={16} right={16} bottom={hasLabels ? 8 : 16} left={16}>
            <ItemWithIcon icon={logo} content={details} space={16} />
          </Paper>
        )}
      </SelectableCard>
    </Skeleton>
  )
}

SecurityItem.propTypes = {
  added: PropTypes.bool,
  estimated_yield: PropTypes.number,
  href: PropTypes.string,
  id: PropTypes.number.isRequired,
  is_esg: PropTypes.bool,
  is_featured: PropTypes.bool,
  is_hedged: PropTypes.bool,
  isLoading: PropTypes.bool,
  logo_uri: PropTypes.string,
  onClick: PropTypes.func,
  onSettled: PropTypes.func,
  short_description: PropTypes.string,
  showDescription: PropTypes.bool,
  showLabels: PropTypes.bool,
  showTargetWeight: PropTypes.bool,
  target_weight: PropTypes.number,
  ter: PropTypes.string,
  ticker: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  dividends_type: PropTypes.oneOf(['ACCUMULATING', 'DISTRIBUTING', null]),
  'data-test-id': PropTypes.string,
}

export { SecurityItem }
