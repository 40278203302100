import { useMemo } from 'hooks'

type useDashboardHintsParams = {
  cashPortfolioRef: React.RefObject<Element>
  isaCashPortfolioRef: React.RefObject<Element>
  addPortfolioRef: React.RefObject<Element>
  addIsaRef: React.RefObject<Element>
  addSippRef: React.RefObject<Element>
  hasOpenedIsa: boolean
  hasPersonalPortfolios: boolean
  isBusiness: boolean
  hasPortfolios: boolean
}

type useDashboardHintsData = {
  hints: Array<{ ref: React.RefObject<Element>; text: string }>
}

const useDashboardHints = ({
  cashPortfolioRef,
  isaCashPortfolioRef,
  addPortfolioRef,
  addIsaRef,
  addSippRef,
  hasOpenedIsa,
  hasPersonalPortfolios,
  isBusiness,
  hasPortfolios,
}: useDashboardHintsParams): useDashboardHintsData => {
  const hints = useMemo(() => {
    const newUserHints = [
      {
        ref: addPortfolioRef,
        text: 'You can start by creating a new General Investment Account portfolio',
      },
      {
        ref: cashPortfolioRef,
        text: 'Or by adding cash to your General Investment Account cash pot',
      },
      {
        ref: addIsaRef,
        text: 'You can also open an ISA',
      },
      {
        ref: addSippRef,
        text: 'Or a Personal Pension',
      },
    ]

    const newBusinessUsersHints = [
      {
        ref: addPortfolioRef,
        text: 'You can start by creating a new General Investment Account portfolio',
      },
      {
        ref: cashPortfolioRef,
        text: 'Or by adding cash to your General Investment Account cash pot',
      },
    ]

    const oldUserTypeOneHints = [
      {
        ref: isaCashPortfolioRef,
        text: 'There is now a dedicated ISA cash pot which can be used to keep uninvested cash',
      },
      {
        ref: cashPortfolioRef,
        text: '...and also a General Investment Account cash pot',
      },
    ]

    const oldUserTypeTwoNoIsaHints = [
      {
        ref: cashPortfolioRef,
        text: 'There is now a dedicated cash pot which can be used to keep uninvested cash',
      },
    ]

    const oldUserTypeTwoWithIsaHints = [
      {
        ref: isaCashPortfolioRef,
        text: 'There is now a dedicated cash pot which can be used to keep uninvested cash',
      },
    ]

    if (!hasPortfolios) {
      if (hasOpenedIsa) {
        return oldUserTypeTwoWithIsaHints
      }

      if (isBusiness) {
        return newBusinessUsersHints
      }

      return newUserHints
    }

    if (hasOpenedIsa) {
      if (hasPersonalPortfolios) {
        return oldUserTypeOneHints
      }

      return oldUserTypeTwoWithIsaHints
    }

    return oldUserTypeTwoNoIsaHints
  }, [
    addIsaRef,
    hasPortfolios,
    hasOpenedIsa,
    addPortfolioRef,
    cashPortfolioRef,
    isBusiness,
    hasPersonalPortfolios,
    isaCashPortfolioRef,
    addSippRef,
  ])

  return { hints }
}

export { useDashboardHints }
