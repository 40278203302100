import { ListOf } from 'app/effector/model'

import { Bonus, type BonusStates } from './Bonus'

import type { BonusDTO } from '../api'

class BonusList extends ListOf(Bonus) {
  set(bonusDto: BonusDTO): BonusList {
    const bonusIndex = this.findIndex((bonus) => bonus.id === bonusDto.id)

    if (bonusIndex > -1) {
      const newBonuses = [...this]
      newBonuses[bonusIndex] = bonusDto

      return new BonusList(...newBonuses)
    }

    return new BonusList(...this, bonusDto)
  }

  filterByState(state: BonusStates): BonusList {
    return new BonusList(...this.filter((bonus) => bonus.state === state))
  }
}

export { BonusList }
