import React from 'react'

import { palette } from 'helpers/palette'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo'

import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type AlertProps = {
  text?: string
  iconColor?: string
  children?: React.ReactNode
}

const Alert = ({ text, iconColor = 'primary-default', children }: AlertProps): React.ReactElement => {
  return (
    <Card borderRadius={10} color="content-on-color-white">
      <Paper top={16} right={16} bottom={16} left={16}>
        <ItemWithIcon
          space={8}
          icon={<Icon type="subtract-16" size={16} color={palette[iconColor]} style={{ marginTop: '3px' }} />}
          content={
            children ?? (
              <Typography color="on_color_black" size={14} lineHeight="small">
                <Typo>{text}</Typo>
              </Typography>
            )
          }
        />
      </Paper>
    </Card>
  )
}

export { Alert }
