import React, { forwardRef, Fragment } from 'react'

import classNames from 'classnames/dedupe'

import {
  useMediaQueries,
  useRef,
  useMemo,
  useCallback,
  useImperativeHandle,
  useState,
  usePrevious,
  useEffect,
} from 'hooks'

import { palette } from 'helpers/palette/'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { type FilterProps } from '../Filter'

import './FilterButton.css'

const appearanceColors = {
  default: {
    selectedTitle: 'on_color_default',
    selectedIcon: palette['content-on-color-default'],
    title: 'default',
    icon: palette['content-on-background-default'],
  },
  blue: {
    selectedTitle: 'on_background_primary',
    selectedIcon: palette['content-on-background-primary'],
    title: 'on_background_primary',
    icon: palette['content-on-background-primary'],
  },
}

type FilterButtonHandle = {
  focusFilterButton: () => void
}

type FilterButtonProps = FilterProps & {
  open: boolean
  onClose: () => void
  onOpen: () => void
  onHardReset: () => void
  'data-test-id'?: string
}

const FilterButton = (
  {
    type,
    name,
    values,
    selected,
    open,
    disabled,
    appearance = 'default',
    onOpen: handleOpen,
    onClose: handleClose,
    onHardReset: handleHardReset,
    'data-test-id': dataTestId,
  }: FilterButtonProps,
  ref: React.Ref<unknown>,
): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const rootRef = useRef<HTMLDivElement>(null)

  const selectedTitle = useMemo<string>(() => {
    const selectedValues = values.filter((value) => {
      if (value.value === null) {
        return false
      }

      return selected.includes(value.value)
    })

    if (selectedValues.length < 1) {
      return name
    }

    if (selectedValues.length === 1) {
      return selectedValues[0]?.selectedName ?? selectedValues[0]?.name
    }

    return `${name}: ${selectedValues.length}`
  }, [name, values, selected])

  const focusFilterButton = useCallback(() => {
    // next tick
    setTimeout(() => {
      if (rootRef.current) {
        const button = rootRef.current.querySelector('button')

        if (button) {
          button.focus()
        }
      }
    }, 0)
  }, [rootRef])

  useImperativeHandle(ref, () => ({
    focusFilterButton,
  }))

  // that's a hack to disable animation when button changes type
  const [additionalFilterClassName, setAdditionalFilterClassName] = useState('')
  const wasSelected = usePrevious(selected)
  const disableAnimationForASplitSecond = useCallback(() => {
    setAdditionalFilterClassName('FilterButton_disableAnimation')

    setTimeout(() => {
      setAdditionalFilterClassName('')
    }, 1)
  }, [setAdditionalFilterClassName])

  useEffect(() => {
    if (!wasSelected) {
      disableAnimationForASplitSecond()
    }

    if (wasSelected) {
      if ((wasSelected.length === 0 && selected.length > 0) || (wasSelected.length > 0 && selected.length === 0)) {
        disableAnimationForASplitSecond()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])
  const classes = classNames('FilterButton', additionalFilterClassName)

  const buttons =
    selected.length > 0 ? (
      <Fragment>
        <button
          className="FilterButton-Selected"
          onClick={open ? handleClose : handleOpen}
          aria-label={`${open ? 'Close' : 'Open'} ’${name}’ filter list. Already selected ${selected.length} filters`}
          aria-haspopup="listbox"
          disabled={disabled}
          data-test-id={dataTestId}
        >
          <Paper top={8} right={8} bottom={8} left={12}>
            <Typography size={14} lineHeight="small" color={appearanceColors[appearance].selectedTitle}>
              <Typo>{selectedTitle}</Typo>
            </Typography>
          </Paper>
        </button>
        <button
          className="FilterButton-Selected FilterButton-Reset"
          onClick={handleHardReset}
          aria-label={`Reset and apply ‘${name}’ filter`}
          disabled={disabled}
          data-test-id={dataTestId ? `${dataTestId}Clear` : undefined}
        >
          <Paper top={8} right={8} bottom={8} left={8}>
            <Icon size={16} type="cross-16" color={appearanceColors[appearance].selectedIcon} />
          </Paper>
        </button>
      </Fragment>
    ) : (
      <button
        className="FilterButton-Empty"
        onClick={open ? handleClose : handleOpen}
        aria-label={`${open ? 'Close' : 'Open'} ‘${name}’ filter list`}
        disabled={disabled}
        data-test-id={dataTestId}
      >
        <Paper className="FilterButton-EmptyInner" top={8} right={desktop ? 8 : 12} bottom={8} left={12}>
          <ItemWithIcon
            iconPosition="right"
            space={4}
            icon={
              <Icon size={16} type={open ? 'arrow-up-16' : 'arrow-down-16'} color={appearanceColors[appearance].icon} />
            }
            content={
              <Typography size={14} lineHeight="small" color={appearanceColors[appearance].title}>
                <Typo>{name}</Typo>
              </Typography>
            }
          />
        </Paper>
      </button>
    )

  return (
    <div ref={rootRef} className={classes}>
      {buttons}
    </div>
  )
}

const FilterButtonWithForwardedRef = forwardRef<FilterButtonHandle, FilterButtonProps>(FilterButton)

export { FilterButtonWithForwardedRef as FilterButton, type FilterButtonHandle, type FilterButtonProps }
