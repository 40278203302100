import isEmpty from 'lodash/isEmpty'
import seedrandom from 'seedrandom'

import axios from 'helpers/ajax'
import { sendError } from 'helpers/errorLogging.js'

import { $owner } from 'app/effector/contacts'

import store from 'app/redux/store/store.js'

import {
  SET_NOT_VALID,
  SET_VALID,
  RECEIVE_ERROR,
  RESET_ERROR,
  CHANGE_TOASTS_VISIBILITY,
  RECEIVE_TOAST,
  REMOVE_TOAST,
  REMOVE_TOASTS_WITH_STYLE,
  CHANGE_MODAL_VISIBILITY,
  CHANGE_MODAL_FIELD,
  CHANGE_FIELD,
  RECEIVE_FLATPAGE_SECTION,
  UPDATE_NOTIFICATION_SETTINGS,
} from './uiActionTypes.js'

export function setNotValid() {
  return { type: SET_NOT_VALID }
}

export function setValid() {
  return { type: SET_VALID }
}

export function receiveError(error) {
  sendError(error)
  return { type: RECEIVE_ERROR, error }
}

export function resetError() {
  return { type: RESET_ERROR }
}

export function receiveToast({ message, style, uid }) {
  return { type: RECEIVE_TOAST, message, style, uid }
}

export function changeToastsVisible(visible) {
  return { type: CHANGE_TOASTS_VISIBILITY, visible }
}

export function removeToast(uid) {
  return { type: REMOVE_TOAST, uid }
}

export function removeToastsWithStyle(style) {
  return { type: REMOVE_TOASTS_WITH_STYLE, style }
}

export function addToast(message, style = 'success', timeout = 3000) {
  return async (dispatch, getState) => {
    return await new Promise((resolve) => {
      const { toasts, toastsVisibility } = getState().ui

      if (toastsVisibility) {
        if (toasts.length > 0) {
          toasts.forEach((t) => dispatch(removeToast(t.uid)))
        }
        const uid = seedrandom(new Date().toString() + message + style)()
        dispatch(receiveToast({ message, style, uid }))
        setTimeout(() => {
          dispatch(removeToast(uid))
        }, timeout)
      }
      resolve(getState())
    })
  }
}

// TODO: That's used thought all code as not a real action, just as functions, so dispatch should be taken from store. Fix it
export function showSuccessToast(message) {
  const { dispatch } = store
  dispatch(addToast(message, 'success'))
}

// TODO: That's used thought all code as not a real action, just as functions, so dispatch should be taken from store. Fix it
export function showFailToast(message = 'Something went wrong') {
  const { dispatch, getState } = store

  // TODO: That's should be moved somewhere where it can make some more sense
  const isAuthError = Object.values(getState())
    .map((item) => item?.error)
    .filter((error) => !!error)
    .some((error) => error.isAuthorizationError)

  if (!isAuthError) {
    dispatch(addToast(message, 'fail'))
  }
}

export function changeModalVisibility(modal, visible) {
  return { type: CHANGE_MODAL_VISIBILITY, modal, visible }
}

export function changeModalField(modal, field) {
  return { type: CHANGE_MODAL_FIELD, modal, field }
}

export function changeField(field) {
  return { type: CHANGE_FIELD, field }
}

export function receiveFlatPageSection(section, data) {
  return { type: RECEIVE_FLATPAGE_SECTION, section, data }
}

export function showSupportModal() {
  return (dispatch, getState) => {
    const owner = $owner.getState() ?? {}
    const { first_name: firstName, last_name: lastName, email } = owner

    dispatch(changeModalField('supportModal', { email }))
    dispatch(
      changeModalField('supportModal', {
        name: `${firstName || ''} ${lastName || ''}`.trim(),
      }),
    )
    dispatch(changeModalVisibility('supportModal', true))
  }
}

export function fetchFlatPageSection(section) {
  return (dispatch, getState) => {
    dispatch(resetError())
    dispatch(setNotValid())
    return axios
      .get(`dicts/pages/${section}`)
      .then((response) => {
        const { data } = response
        dispatch(receiveFlatPageSection(section, data))
        dispatch(setValid())
      })
      .catch((error) => {
        dispatch(receiveError(error))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function fetchRequisites() {
  return async (dispatch, getState) => {
    dispatch(resetError())
    dispatch(setNotValid())
    return await new Promise((resolve) => {
      axios
        .get(`topups/bank/`)
        .then((response) => {
          const { data } = response
          dispatch(changeField({ requisites: data }))
          dispatch(setValid())
        })
        .catch((error) => {
          dispatch(receiveError(error))
          resolve(getState())
          dispatch(setValid())
        })
        .then(() => {
          resolve(getState())
        })
    })
  }
}

export function updatePingAttempts(failedPingAttempts) {
  return (dispatch) => {
    dispatch(changeField({ failedPingAttempts }))
  }
}

export function ping() {
  return async (dispatch, getState) => {
    const { failedPingAttempts } = getState().ui.fields

    return await new Promise((resolve) => {
      // TODO: CONFIG.HOST is a stub
      axios
        .get(CONFIG.HOST)
        .then(() => {
          dispatch(updatePingAttempts(0))
        })
        .catch(() => {
          dispatch(updatePingAttempts(failedPingAttempts + 1))
        })
        .then(() => {
          resolve(getState())
        })
    })
  }
}

export function updateNotificationSettings(notificationType, enabled) {
  return { type: UPDATE_NOTIFICATION_SETTINGS, notificationType, enabled }
}

export function fetchNotificationSettings() {
  return async (dispatch, getState) => {
    try {
      dispatch(setNotValid())
      const { data } = await axios.get('notifications/')

      if (!isEmpty(data)) {
        data.forEach(({ type, enabled }) => {
          dispatch(updateNotificationSettings(type.toLowerCase(), enabled))
        })
      }
      dispatch(setValid())
    } catch (error) {
      dispatch(receiveError(error))
      dispatch(setValid())
    }
    return getState()
  }
}

export function changeNotificationSettings(notificationType, enabled) {
  return async (dispatch, getState) => {
    try {
      dispatch(updateNotificationSettings(notificationType, enabled))
      await axios.patch(`notifications/${notificationType.toUpperCase()}/`, { enabled })
    } catch (error) {
      dispatch(receiveError(error))
    }
    return getState()
  }
}
