import { type ProcessedEquities } from 'app/effector/analytics/types'

type FilterStocksArguments = {
  isLoading?: boolean
  allStocks: ProcessedEquities
  searchValue: string
  regionsFilterValues: number[]
  sectorsFilterValues: number[]
}

const filterStocks = ({
  isLoading,
  allStocks,
  searchValue,
  regionsFilterValues,
  sectorsFilterValues,
}: FilterStocksArguments): ProcessedEquities => {
  if (isLoading) {
    return {
      ...allStocks,
    }
  }

  const searchedItems =
    searchValue.length >= 2
      ? allStocks.items.filter((item) => {
          const itemNameLowerCase = item.name?.toLowerCase() ?? ''
          const searchValueLowerCase = searchValue.toLowerCase() ?? ''

          return itemNameLowerCase.includes(searchValueLowerCase)
        })
      : allStocks.items

  const filteredItems =
    regionsFilterValues.length || sectorsFilterValues.length
      ? searchedItems.filter(
          (item) =>
            (!regionsFilterValues.length || regionsFilterValues.includes(item.region_id)) &&
            (!sectorsFilterValues.length || sectorsFilterValues.includes(item.sector_id)),
        )
      : searchedItems

  return {
    ...allStocks,
    items: filteredItems,
  }
}

export { filterStocks }
