async function wait(ms: number): Promise<void> {
  await new Promise((resolve) => setTimeout(resolve, ms))
}

async function retryAsyncOperationUntilResultIsDone<Result>(
  operation: () => Promise<Result>,
  isDone: (Result) => boolean,
  delay: number = 0,
  times: number = 0,
): Promise<Result> {
  const result = await operation()

  if (isDone(result)) {
    return result
  }

  if (times > 0) {
    if (delay > 0) {
      await wait(delay)
    }

    return await retryAsyncOperationUntilResultIsDone(operation, isDone, delay, times - 1)
  }

  return result
}

export { retryAsyncOperationUntilResultIsDone }
