import React from 'react'

import { ISADeclaration } from './ISADeclaration.jsx'

export default {
  isNewLayout: true,
  module: 'sign-isa-declaration',
  path: 'sign-isa-declaration/',
  component: function Component(props) {
    return <ISADeclaration {...props} />
  },
}
