import { createEffect, createStore, createEvent, sample, combine } from 'effector'

import * as api from './api'
import { docSetsContent } from './data/docSetsContent'
import { documentsContent } from './data/documentsContent'
import { type Document, type DocName, DocSet } from './types'

// Events
const initialize = createEvent<{ requestToken: string; docSetsString: string }>()
const submit = createEvent()
const clearFiles = createEvent<DocName>()

// Effects
const uploadFilesFx = createEffect(
  async ({ requestToken, files, document }: { requestToken: string; files: File[]; document: Document }) => {
    const requests = files.map(async (file) => await api.uploadDocument({ file, type: document.type, requestToken }))

    const data = await Promise.all(requests)

    return data
  },
)

const submitDocumentsFx = createEffect(
  async ({ requestToken, documentIds }: { requestToken: string; documentIds: number[] }) => {
    await api.submitDocuments({ requestToken, documentIds })
  },
)

// Stores
const $requestToken = createStore('')
const $docSets = createStore<DocSet[]>([])
const $uploadedDocuments = createStore<Record<DocName, { fileNames: string[]; ids: number[] }>>({})

const $documentsList = $docSets.map((docSets) => {
  const docsNamesToRender = docSets.reduce<DocName[]>((result, docSet) => {
    result = [...result, ...docSetsContent[docSet]]
    return result
  }, [])

  return docsNamesToRender.map((docName) => {
    return documentsContent[docName]
  })
})

const $canSubmit = combine($uploadedDocuments, $documentsList, (uploadedDocuments, documentsList) => {
  return !!documentsList.map((document) => document.type).every((type) => !!uploadedDocuments[type])
})

$requestToken.on(initialize, (_, { requestToken }) => requestToken)
$docSets.on(
  initialize,
  (_, { docSetsString }) =>
    docSetsString.split('+').filter((docSet) => Object.values(DocSet).includes(docSet as DocSet)) as DocSet[],
)

$uploadedDocuments.on(initialize, () => {})

$uploadedDocuments.on(uploadFilesFx.done, (state, { params, result }) => {
  const documentFiles = state[params.document.type] || { ids: [], fileNames: [] }

  documentFiles.ids = [...documentFiles.ids, ...result.map((item) => item?.id)]
  documentFiles.fileNames = [...documentFiles.fileNames, ...params.files.map((file) => file.name)]

  return {
    ...state,
    [params.document.type]: documentFiles,
  }
})

$uploadedDocuments.on(clearFiles, (state, docName) => {
  return { ...state, [docName]: undefined }
})

sample({
  clock: submit,
  source: {
    requestToken: $requestToken,
    uploadedDocuments: $uploadedDocuments,
  },
  fn: ({ requestToken, uploadedDocuments }) => {
    const documentIds = Object.values(uploadedDocuments).reduce((acc, { ids }) => {
      return [...acc, ...ids]
    }, [])

    return { requestToken, documentIds }
  },
  target: submitDocumentsFx,
})

export {
  // Events
  initialize,
  submit,
  clearFiles,
  // Effects,
  submitDocumentsFx,
  uploadFilesFx,
  // Store
  $documentsList,
  $canSubmit,
  $requestToken,
  $uploadedDocuments,
  $docSets,
}
