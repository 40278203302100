import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

/**
 * @class
 * @typedef {Object} SecurityHistoryItem
 */
export class SecurityHistoryItem extends Entity {
  constructor() {
    super()

    /** @type {?string} */
    this.date = null
    /** @type {?number} */
    this.share_price = null
  }

  /** @override */
  getRules() {
    return {
      date: [[rules.iso8601UTC]],
      share_price: [[rules.float]],
    }
  }
}
