import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { format } from 'helpers/money'

import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { DynamicFontSize } from 'components/atoms/DynamicFontSize'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import { type ManagedPortfolio } from '../../../../types'

import { presetTypes as portfolioPresetTypes } from 'constants/goal'

type BalanceProps = {
  portfolio: ManagedPortfolio
}

const Balance = ({ portfolio }: BalanceProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const isIncomePortfolio = portfolio?.preset_type === portfolioPresetTypes.INCOME

  const tooltipText = isIncomePortfolio
    ? 'The current balance of your portfolio including latest income earned (but not income paid out) and any pending transfers still being processed'
    : 'Portfolio balance, including any pending transfers still being processed'

  if (desktop) {
    return (
      <Fragment>
        <Typography weight="semibold" lineHeight="small" data-test-id="portfolioBalance">
          <DynamicFontSize
            min={12}
            max={54}
            lineHeight={1.175}
            length={format(portfolio.current_balance, true, true).length}
            lengthLimit={9}
          >
            <PoundsWithPence amount={portfolio.current_balance} showZeroPence />
          </DynamicFontSize>
        </Typography>
        <Paper top={4}>
          <Typography size={16} color="minor" lineHeight="small">
            Portfolio balance{' '}
            <TooltipToModal description={tooltipText} customIcon offset={-350} data-test-id="portfolioBalanceTooltip">
              <Link mods={{ color: 'minorToRed' }} style={{ display: 'inline-flex' }}>
                <Icon size={24} type="information-24" color="inherit" style={{ position: 'relative', top: '6px' }} />
              </Link>
            </TooltipToModal>
          </Typography>
        </Paper>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Typography size={14} color="minor" lineHeight="small">
        Portfolio balance{' '}
        <TooltipToModal description={tooltipText} customIcon offset={-350}>
          <Link mods={{ color: 'minorToRed' }} style={{ display: 'inline-flex' }}>
            <Icon size={16} type="information-16" color="inherit" style={{ position: 'relative', top: '3px' }} />
          </Link>
        </TooltipToModal>
      </Typography>
      <Paper top={4}>
        <Typography size={36} weight="semibold" lineHeight="small">
          <PoundsWithPence amount={portfolio.current_balance} showZeroPence />
        </Typography>
      </Paper>
    </Fragment>
  )
}

export { Balance }
