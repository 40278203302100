import { type AnalyticsItemData, type AnalyticsItemSecurity } from '../types'

import { colors } from 'constants/analytics'

const parseAnalyticsItem = (item): AnalyticsItemData => {
  // convert strings -> floats
  item.actual_weight = parseFloat(item.actual_weight)
  item.target_weight = item.target_weight ? parseFloat(item.target_weight) : 0
  item.total_value = item.total_value ? parseFloat(item.total_value) : 0
  Object.keys(item.portfolios).forEach((portfolioId) => {
    item.portfolios[portfolioId].forEach((item) => {
      item.actual_weight = item.actual_weight ? parseFloat(item.actual_weight) : 0
      item.target_weight = item.target_weight ? parseFloat(item.target_weight) : 0
    })
  })

  item.countries = item.countries?.map((country) => ({
    ...country,
    actual: parseFloat(country.actual_weight),
    target: parseFloat(country.target_weight),
    actual_amount: parseFloat(country.actual_value),
  }))

  // colourise
  Object.values(item.portfolios)
    .reverse()
    .forEach((securities: AnalyticsItemSecurity[], index: number, array: AnalyticsItemSecurity[][]) => {
      const previousLength: number = array.slice(0, index).reduce((sum, array) => sum + array.length, 0)

      securities.forEach((security: AnalyticsItemSecurity, index: number) => {
        const joinedIndex = index + previousLength
        const colorIndex = joinedIndex % 50

        security.color = colors[colorIndex]
      })
    })

  return item
}

export { parseAnalyticsItem }
