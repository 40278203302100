import React, { Fragment } from 'react'

import { goTo, urlTo } from 'helpers/router.js'

import { useActions, useCallback } from 'hooks'
import { usePendingOrders } from 'app/pages/Dashboard/Goals/DIY/hooks'
import { useSecurities } from 'app/pages/Securities/hooks'
import { Preloader } from 'components/molecules/Preloader'
import { GatewayDest } from 'components/atoms/Gateway'

import { SecurityOrdersPage } from 'app/pages/Dashboard/Securities/SecurityOrdersPage/SecurityOrdersPage.jsx'

import { showSupportModal as showSupportModalActionCreator } from 'app/redux/actions/ui'

const AllOrders = () => {
  const { securities, isLoading: isSecuritiesLoading } = useSecurities()
  const { orders, isLoading: isPendingOrdersLoading } = usePendingOrders()
  const isLoading = isSecuritiesLoading || isPendingOrdersLoading

  const [showSupportModal] = useActions([showSupportModalActionCreator])

  const handleBack = useCallback(() => goTo(urlTo('transactions')), [])
  const handleContactUs = useCallback(() => showSupportModal(), [])

  return (
    <SecurityOrdersPage
      orders={orders}
      securities={securities}
      extraNodes={
        <Fragment>
          <GatewayDest name="supportModal" />
          <Preloader loading={isLoading} size="big" zIndex />
        </Fragment>
      }
      onBack={handleBack}
      onContactUs={handleContactUs}
    />
  )
}

export { AllOrders }
