import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from 'components/atoms/Typography'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import './ErrorBlock.css'

const ErrorBlock = ({ children, 'data-test-id': dataTestId }) => (
  <AllCenter className="ErrorBlock" data-test-id={dataTestId}>
    <Typography className="ErrorBlock-Inner" size={16} lineHeight="small" color="error" align="center">
      {children}
    </Typography>
  </AllCenter>
)

ErrorBlock.propTypes = {
  children: PropTypes.node,
  'data-test-id': PropTypes.string,
}

export { ErrorBlock }
