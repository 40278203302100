import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

/**
 * @class
 * @typedef {Object} AnswerValue
 */
export class AnswerValue extends Entity {
  constructor() {
    super()
    /** @type {?number} */
    this.id = null
    /** @type {?string} */
    this.title = null
    /** @type {?object} */
    this.extra_data = null
  }

  /** @override */
  getRules() {
    return {
      id: [[rules.int]],
      title: [[rules.string]],
    }
  }
}
