import { useUnit } from 'effector-react'
import uniq from 'lodash/uniq'

import { useState, useCallback, useContext, useEffect, useRef, useSelector, useMemo, useActions, useMeta } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import { $collectionsStore } from 'app/effector/discover-etfs'
import { removeSecurity } from 'app/effector/portfolio-securities'
import { $securitiesStore, clearCollectionSecurities } from 'app/effector/securities'

import { showSuccessToast, showSupportModal as showSupportModalActionCreator } from 'app/redux/actions/ui'

import { ScrollSaverContext, getPageURL } from 'app/containers/ScrollSaver'

import { useFilters } from './useFilters'
import { useNavigation } from './useNavigation.js'
import { useSecuritiesSearch } from './useSecuritiesSearch.js'
import { useSecurityUniverseRestrictions } from './useSecurityUniverseRestrictions.js'

const SECURITY_UNIVERSE_VERSION = {
  PRIVATE: 'PRIVATE',
  INSIDE_PORTFOLIO: 'INSIDE_PORTFOLIO',
}

const useSecurityUniverse = (
  portfolioId,
  search,
  location,
  routes,
  collectionId,
  collectionSlug,
  isCollection = false,
) => {
  const selectedPortfolio = useSelector((state) => state.portfolios.list.get(portfolioId))
  const portfolioTitle = selectedPortfolio?.getTitle()
  const { securities: allSecurities, getSecurityById } = useUnit($securitiesStore)

  const [showSupportModal] = useActions([showSupportModalActionCreator])
  const handleContactUs = useCallback(() => showSupportModal(), [showSupportModal])

  const { isLoading: areCollectionsLoading, areCollectionsFetched } = useUnit($collectionsStore)
  const areCollectionsBeingLoaded = areCollectionsLoading && !areCollectionsFetched

  const version = useMemo(() => {
    if (selectedPortfolio) {
      return SECURITY_UNIVERSE_VERSION.INSIDE_PORTFOLIO
    }

    return SECURITY_UNIVERSE_VERSION.PRIVATE
  }, [selectedPortfolio])

  const roundedSecuritiesCount = useMemo(() => {
    const visibleSecurities = allSecurities.filter(
      ({ is_visible_in_universe: isVisibleInUniverse }) => isVisibleInUniverse,
    )

    return Math.floor(visibleSecurities.length / 10) * 10
  }, [allSecurities])

  const needToSaveScrollPosition = useRef(false)
  const scrollableElementRef = useRef(null)
  const { saveScrollPosition, restoreScrollPosition, resetScrollPosition } = useContext(ScrollSaverContext)

  // tabs
  const tabItems = [
    {
      name: 'Discover ETFs',
      module: 'universe',
      slug: 'discovery',
      'data-test-id': 'discoveryTab',
    },
    {
      name: `All ${roundedSecuritiesCount}+ ETFs`,
      module: 'universe-all',
      slug: 'all',
      'data-test-id': 'allEtfsTab',
    },
  ]

  const [activeTabName, setActiveTabName] = useState(
    routes?.some((route) => route.module === 'universe-all') ? 'all' : 'discovery',
  ) // discovery, all

  const activeTabIndex = tabItems.findIndex(({ slug }) => slug === activeTabName)

  const navigateByTab = (tabIndex) => {
    goTo(urlTo(tabItems[tabIndex].module, {}, { ...location.query }))
    setActiveTabName(tabItems[tabIndex].slug)
  }

  useEffect(() => {
    restoreScrollPosition()

    const urlToResetUrl = getPageURL()

    return () => {
      if (resetScrollPosition && !needToSaveScrollPosition.current) {
        resetScrollPosition(urlToResetUrl)
      }

      if (collectionId) {
        clearCollectionSecurities(collectionId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableElementRef.current])

  useEffect(() => {
    if (activeTabName === 'all') {
      trackEvent({ action: 'view_item_list', item_category: 'all-etfs' })
    }
  }, [activeTabName])

  const { filters, filtersState, handleFiltersClear } = useFilters(search?.query)

  const {
    isLoading,
    portfolioSecurities,
    searchValue,
    securities,
    securitiesWasLoadedOnce,
    getSecurityDataByIndex,
    handleSearch,
  } = useSecuritiesSearch({
    portfolioId,
    collectionId,
    lastSearch: search,
    filters: filtersState,
    isCollection,
  })

  const isDiscoveryVisible = useMemo(
    () => !searchValue && Object.keys(filtersState).every((filterName) => !filtersState[filterName].length),
    [searchValue, filtersState],
  )

  const isDiscoveryDisplayed = /\/etfs\/$/.test(location.pathname) && isDiscoveryVisible

  const {
    abandonModalOpen,
    getSecurityLink: getSecurityLinkNavigation,
    handleCancel: handleCancelRaw,
    handleGoToEditWeights: handleGoToEditWeightsNavigation,
    handleAbandonModalClose,
    redirectToSecurity,
  } = useNavigation({
    portfolio: selectedPortfolio,
    query: search.query,
    isCollection: !!collectionId,
  })

  const {
    restrictionModalOpen,
    restrictionModalType,
    handleRestrictionModalClose,
    handleGoToEditWeights: handleGoToEditWeightsRestrictions,
  } = useSecurityUniverseRestrictions(portfolioId, handleGoToEditWeightsNavigation)

  const logosToPreload = useMemo(() => {
    const allLogos = securities.map((security) => security.logo_uri)

    return uniq(allLogos).filter((logo) => Boolean(logo))
  }, [securities])

  useMeta({ preload: logosToPreload })

  const handleSaveScrollPosition = useCallback(() => {
    needToSaveScrollPosition.current = true
    saveScrollPosition()
  }, [saveScrollPosition])

  const getSecurityQuery = useCallback(() => {
    const queryObject = {
      portfolioId,
      ...search.query,
      search: searchValue,
      ...filtersState,
      back: search.pathname + search.search,
    }

    const fromUniverse = search.query?.fromUniverse || !selectedPortfolio

    if (fromUniverse) {
      queryObject.fromUniverse = 'true'
    }

    if (collectionSlug || collectionId) {
      queryObject.collection = collectionSlug || collectionId
    }

    if (activeTabName === 'discovery') {
      queryObject.fromDiscovery = 'true'
    }

    return querystringFromObject(queryObject)
  }, [activeTabName, collectionId, collectionSlug, filtersState, portfolioId, search, searchValue, selectedPortfolio])

  const getSecurityLink = useCallback(
    (id) => {
      const query = getSecurityQuery()

      return getSecurityLinkNavigation({ id, query })
    },
    [getSecurityLinkNavigation, getSecurityQuery],
  )

  const handleSecurityClick = useCallback(
    (id) => {
      handleSaveScrollPosition()

      if (activeTabName !== 'discovery') {
        const security = getSecurityById(id)
        trackEvent({
          action: 'select_item',
          currency: 'GBP',
          quantity: 1,
          value: null,
          payment_type: null,
          price: null,
          item_category: 'all-etfs',
          item_id: security?.id,
          item_name: security?.title,
          item_brand: security?.provider_filter_name,
        })
      }

      const query = getSecurityQuery()

      redirectToSecurity({ id, query })
    },
    [activeTabName, handleSaveScrollPosition, getSecurityQuery, redirectToSecurity, getSecurityById],
  )

  const handleGoToEditWeights = useCallback(() => {
    handleSaveScrollPosition()

    handleGoToEditWeightsRestrictions()
  }, [handleSaveScrollPosition, handleGoToEditWeightsRestrictions])

  const handleBack = useCallback(() => {
    goTo(urlTo('dashboard.portfolio', { id: portfolioId }))
  }, [portfolioId])

  const handleCancel = useCallback(() => {
    handleCancelRaw()
  }, [handleCancelRaw])

  const handleRemoveSecurity = useCallback(
    (securityId) => () => {
      removeSecurity({ securityId, portfolioId })
      showSuccessToast('ETF has been removed')
    },
    [portfolioId],
  )

  return {
    abandonModalOpen,
    activeTabIndex,
    allSecurities,
    areCollectionsBeingLoaded,
    filters,
    filtersState,
    frames,
    isDiscoveryDisplayed,
    isDiscoveryVisible,
    isLoading,
    portfolioSecurities,
    portfolioTitle,
    restrictionModalOpen,
    restrictionModalType,
    roundedSecuritiesCount,
    scrollableElementRef,
    searchValue,
    securities,
    securitiesWasLoadedOnce,
    selectedPortfolio,
    tabItems,
    version,
    getSecurityDataByIndex,
    getSecurityLink,
    handleAbandonModalClose,
    handleBack,
    handleCancel,
    handleContactUs,
    handleFiltersClear,
    handleGoToEditWeights,
    handleRemoveSecurity,
    handleRestrictionModalClose,
    handleSaveScrollPosition,
    handleSearch,
    handleSecurityClick,
    navigateByTab,
  }
}

export { useSecurityUniverse, SECURITY_UNIVERSE_VERSION }
