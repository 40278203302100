import React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import isFunction from 'lodash/isFunction'

import { useCallback, useMediaQueries, useMemo, useState } from 'hooks'

import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography, type sizes, type weights } from 'components/atoms/Typography'

import './NavigationBar.css'

type NavigationBarProps = {
  children?: React.ReactNode | React.ReactNodeArray
  className?: string
  centralPartSize?: {
    desktop?: (typeof sizes)[number]
    mobile?: (typeof sizes)[number]
  }
  centralPartWeight?: {
    desktop?: (typeof weights)[number]
    mobile?: (typeof weights)[number]
  }
  align?: 'left' | 'center' | 'right'
  leftPartText?: string
  disableLeftPart?: boolean
  onLeftPartClick?: () => void
  rightPartText?: string
  disableRightPart?: boolean
  onRightPartClick?: () => void | Promise<void>
  plain?: boolean
  withPaddings?: boolean
  withShadow?: boolean
  linkStyle?: string
}

function NavigationBar({
  children,
  className,
  centralPartSize,
  centralPartWeight,
  align = 'center',
  leftPartText,
  disableLeftPart = false,
  onLeftPartClick: handleLeftPartClick,
  rightPartText,
  disableRightPart = false,
  onRightPartClick: handleRightPartClick,
  plain = false,
  withPaddings = true,
  withShadow = true,
  linkStyle,
}: NavigationBarProps): React.ReactElement {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const [leftPadding, setLeftPadding] = useState(0)
  const [rightPadding, setRightPadding] = useState(0)
  const setPadding = useCallback(
    (node, setSidePadding) => {
      if (withPaddings) {
        setSidePadding(node?.getBoundingClientRect?.()?.width || 0)
      }
    },
    [withPaddings],
  )

  const leftRef = useCallback(
    (node) => {
      setPadding(node, setLeftPadding)
    },
    [setLeftPadding, setPadding],
  )
  const rightRef = useCallback(
    (node) => {
      setPadding(node, setRightPadding)
    },
    [setRightPadding, setPadding],
  )

  const maxPadding = useMemo(() => Math.max(leftPadding, rightPadding), [leftPadding, rightPadding])

  const titleStyle = useMemo(() => {
    return maxPadding > 0 ? { padding: `0 calc(${maxPadding}px + 0.375em * 2)` } : null
  }, [maxPadding])

  const classes = useMemo(
    () =>
      classNames(
        className,
        'NavigationBar',
        {
          NavigationBar_plain: plain,
          NavigationBar_withShadow: withShadow,
        },
        getMediaQuieryClasses('NavigationBar', mediaQueries),
      ),
    [className, plain, withShadow, mediaQueries],
  )

  const linkMods = useMemo(() => {
    if (linkStyle) {
      return { color: linkStyle }
    }

    if (desktop) {
      return { color: 'black' }
    }

    return null
  }, [desktop, linkStyle])

  const leftPartIcon = useMemo(() => {
    if (desktop) {
      return (
        <Icon
          className="NavigationBar-LeftPartIcon"
          type="back-new"
          size={20}
          color="inherit"
          data-test-id="leftPartIcon"
        />
      )
    }

    return null
  }, [desktop])

  const leftPart = useMemo(() => {
    if (isFunction(handleLeftPartClick)) {
      if (desktop) {
        return (
          <Paper ref={leftRef} className="NavigationBar-AsideButton NavigationBar-LeftPart" top={plain ? 0 : 12}>
            <Width size={11.25}>
              <Link
                onClick={handleLeftPartClick}
                data-test-id="navigationLeftButton"
                mods={linkMods}
                noUnderline
                disabled={disableLeftPart}
              >
                <Typography
                  size={16}
                  color="inherit"
                  lineHeight="small"
                  overflow="ellipsis"
                  data-test-id="navigationBarLeftPart"
                >
                  <Nobr>
                    {leftPartIcon}
                    <span data-test-id="leftPartText">
                      <Typo>{leftPartText}</Typo>
                    </span>
                  </Nobr>
                </Typography>
              </Link>
            </Width>
          </Paper>
        )
      }

      return (
        <Paper ref={leftRef} className="NavigationBar-AsideButton NavigationBar-LeftPart">
          <Typography size={16} lineHeight="small" data-test-id="navigationBarLeftPart">
            <Link
              onClick={handleLeftPartClick}
              data-test-id="navigationLeftButton"
              mods={linkMods}
              disabled={disableLeftPart}
              noUnderline
            >
              <Typo>
                {leftPartIcon}
                {leftPartText}
              </Typo>
            </Link>
          </Typography>
        </Paper>
      )
    }
    return null
  }, [handleLeftPartClick, desktop, leftRef, linkMods, disableLeftPart, leftPartIcon, leftPartText, plain])

  const centralPart = useMemo(
    () => (
      <Typography
        className="NavigationBar-Title"
        color="inherit"
        style={titleStyle}
        size={desktop ? centralPartSize?.desktop ?? 32 : centralPartSize?.mobile ?? 16}
        weight={desktop ? centralPartWeight?.desktop ?? 'regular' : centralPartWeight?.mobile ?? 'semibold'}
        lineHeight="small"
        align={align}
        data-test-id="navigationBarCentralPart"
      >
        <span className="NavigationBar-TitleContent">
          {typeof children === 'string' ? <Typo>{children}</Typo> : children}
        </span>
      </Typography>
    ),
    [
      titleStyle,
      desktop,
      align,
      children,
      centralPartSize?.desktop,
      centralPartSize?.mobile,
      centralPartWeight?.desktop,
      centralPartWeight?.mobile,
    ],
  )

  const rightPart = useMemo(
    () =>
      isFunction(handleRightPartClick) && (
        <Paper
          ref={leftRef}
          className="NavigationBar-AsideButton NavigationBar-RightPart"
          top={desktop && !plain ? 12 : 0}
        >
          <Typography ref={rightRef} size={16} lineHeight="small" data-test-id="navigationBarRightPart">
            <Link
              onClick={handleRightPartClick}
              data-test-id="navigationRightButton"
              mods={linkMods}
              disabled={disableRightPart}
              noUnderline
            >
              <Typo>{rightPartText}</Typo>
            </Link>
          </Typography>
        </Paper>
      ),
    [handleRightPartClick, leftRef, desktop, plain, rightRef, linkMods, disableRightPart, rightPartText],
  )

  if (desktop) {
    return (
      <div className={classes}>
        {leftPart}
        {centralPart}
        {rightPart}
      </div>
    )
  }

  return (
    <div className={classes}>
      <Inner>
        {leftPart}
        {centralPart}
        {rightPart}
      </Inner>
    </div>
  )
}

type NavigationBarTitleMultipleChildHolderProps = {
  children?: React.ReactNode | React.ReactNodeArray
}

function NavigationBarTitleMultipleChildHolder({
  children,
}: NavigationBarTitleMultipleChildHolderProps): React.ReactElement {
  return <div className="NavigationBar-TitleMultipleChildHolder">{children}</div>
}

export { NavigationBar, NavigationBarTitleMultipleChildHolder }
