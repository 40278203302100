import { createEffect } from 'effector'

import { urlTo, goTo } from 'helpers/router'

import { type IsaTransferRequestDTO, submitIsaTransferRequest } from 'app/effector/isa-transfer/api'

import { fetchClient } from 'app/redux/actions/client'
import { closeIsaPanel, fetchPortfolios } from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'
import store from 'app/redux/store/store.js'

import { scrollToError } from './utils'

export const scrollToErrorFx = createEffect(() => {
  setTimeout(scrollToError, 1)
})

export const submitTransferIsaRequestFx = createEffect(
  async (request: IsaTransferRequestDTO) => await submitIsaTransferRequest(request),
)

export const navigateToSuccessFx = createEffect(() => {
  goTo(urlTo('dashboard.transfer-isa-altus.success'))
})

export const navigateToSignatureStepFx = createEffect(() => {
  goTo(urlTo('dashboard.transfer-isa-altus.signature'))
})

export const navigateToPortfolioByIdFx = createEffect((portfolioId: number) => {
  goTo(urlTo('dashboard.portfolio', { id: portfolioId }))
})

export const openJotFormLinkFx = createEffect((jotformLink: string) => {
  window.open(jotformLink, '_blank')
})

export const fetchPortfoliosFx = createEffect(async () => {
  await store.dispatch(fetchPortfolios())
})

export const fetchClientFx = createEffect(() => {
  store.dispatch(fetchClient())
})

export const closeIsaPanelFx = createEffect((portfolioId: number) => {
  store.dispatch(closeIsaPanel(portfolioId))
})

export const showFailToastFx = createEffect(() => {
  showFailToast()
})
