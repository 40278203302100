import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { DesktopLayout } from 'components/atoms/Layouts'
import Width from 'components/_old/Width/Width'
import { TunnelHeader } from 'app/pages/Securities/components'
import Button from 'components/_old/Button/Button.jsx'
import { Fieldset } from 'components/_old/Form/Form.jsx'
import Label from 'components/_old/Label/Label.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'

import { ISADeclarationForm } from 'app/pages/Forms/ISADeclarationForm'

const Desktop = ({ agreed, handleAgreedChange, handleCancel, handleSubmit }) => {
  const header = useMemo(
    () => (
      <Width center size={52}>
        <TunnelHeader cancelTitle="Cancel" onCancel={handleCancel} />
      </Width>
    ),
    [handleCancel],
  )

  const headline = useMemo(
    () => (
      <Paper bottom={12}>
        <Typography size={32} weight="semibold" lineHeight="small" align="center">
          <Typo>ISA declaration</Typo>
        </Typography>
      </Paper>
    ),
    [],
  )

  const content = useMemo(() => {
    const submitButton = <Button mods={{ size: 'big block', theme: null }} />

    const wrapButton = (button) => (
      <Fieldset>
        <Label>{button}</Label>
      </Fieldset>
    )

    return (
      <Fragment>
        {headline}
        <Width size={52} center>
          <ISADeclarationForm
            onSubmit={handleSubmit}
            handleAgreedChange={handleAgreedChange}
            submitButton={submitButton}
            wrapButton={wrapButton}
            value={agreed}
          />
        </Width>
      </Fragment>
    )
  }, [agreed, handleAgreedChange, handleSubmit, headline])

  return <DesktopLayout header={header} content={content} />
}

Desktop.propTypes = {
  agreed: PropTypes.bool,
  handleAgreedChange: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export { Desktop }
