import React from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { bulkValidate } from 'helpers/validation.js'

import Button from 'components/_old/Button/Button.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import { Preloader } from 'components/molecules/Preloader'

import { CaptchaModal } from './CaptchaModal'
import { useConfirmIdentityModal } from './useConfirmIdentityModal'

type ConfirmIdentityModalProps = {
  open: boolean
  parentModule: string
}

const ConfirmIdentityModal = ({ open, parentModule }: ConfirmIdentityModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    isLoading,
    password,
    validation,
    handleCancel,
    handlePasswordChange,
    handleContinue,
    captchaToken,
    isCaptchaDisplayed,
    hideCaptcha,
  } = useConfirmIdentityModal({ parentModule })

  const headerNode = useMemo(
    () => <NavigationBar onRightPartClick={handleCancel} rightPartText="Cancel" />,
    [handleCancel],
  )

  const titleNode = useMemo(
    () => (
      <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
        <Typo>Confirm your identity</Typo>
      </Typography>
    ),
    [desktop],
  )

  const descriptionNode = useMemo(
    () => (
      <Typography size={desktop ? 14 : 16} align="center">
        <Typo>To confirm or change your phone number enter your InvestEngine password.</Typo>
      </Typography>
    ),
    [desktop],
  )

  const inputNode = useMemo(
    () => (
      <SubmitOnEnter>
        <Validate rules={validation.password.rules}>
          <Label errorMessages={validation.password.errors}>
            Password
            <Input
              mods={{ size: 'bigger' }}
              type="password"
              onChange={handlePasswordChange}
              data-test-id="passwordInput"
            >
              {password}
            </Input>
          </Label>
        </Validate>
      </SubmitOnEnter>
    ),
    [password, validation, handlePasswordChange],
  )

  const buttonNode = useMemo(
    () => (
      <Button type="submit" mods={{ size: 'big block' }} disabled={!bulkValidate(validation)} onClick={handleContinue}>
        Continue
      </Button>
    ),
    [validation, handleContinue],
  )

  if (isCaptchaDisplayed)
    return (
      <CaptchaModal
        token={captchaToken}
        headerNode={headerNode}
        hideCaptcha={hideCaptcha}
        handleCancel={handleCancel}
      />
    )

  if (desktop) {
    return (
      <Modal open={open} onClose={handleCancel} close={null}>
        <Width size={36} style={{ minHeight: '600px' }}>
          <Paper top={8} right={24} left={24}>
            {headerNode}
            <Paper top={40} right={48} left={48}>
              <Paper top={64}>{titleNode}</Paper>
              <Paper top={32}>{descriptionNode}</Paper>
              <Paper top={32} left={24} right={24} style={{ position: 'relative' }}>
                <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                  {inputNode}
                  <Paper top={56}>{buttonNode}</Paper>
                </div>
                {isLoading ? (
                  <Paper style={{ position: 'absolute', top: 48, left: 0, right: 0 }}>
                    <Paper top={32} bottom={32} style={{ position: 'relative' }}>
                      <Preloader loading={true} absolute background="background" />
                    </Paper>
                  </Paper>
                ) : null}
              </Paper>
            </Paper>
          </Paper>
        </Width>
      </Modal>
    )
  }

  const contentNode = (
    <Paper top={8}>
      {titleNode}
      <Paper top={24}>{descriptionNode}</Paper>
      <Paper top={48} style={{ position: 'relative' }}>
        <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>{inputNode}</div>
        {isLoading ? (
          <Paper style={{ position: 'absolute', top: 48, left: 0, right: 0 }}>
            <Paper top={32} bottom={32} style={{ position: 'relative' }}>
              <Preloader loading={true} absolute background="background" />
            </Paper>
          </Paper>
        ) : null}
      </Paper>
    </Paper>
  )

  return (
    <Modal open={open} onClose={handleCancel}>
      <MobileLayout
        header={headerNode}
        content={contentNode}
        footer={isLoading ? null : <MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
}

export { ConfirmIdentityModal }
