import { getKeyOrDefault } from 'helpers/object'

import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

import { PortfolioDisplayItem } from '../PortfolioDisplayItem/PortfolioDisplayItem.js'
import { PortfolioSimpleStatsItem } from '../PortfolioSimpleStatsItem/PortfolioSimpleStatsItem.js'

import { manageTypes, states } from 'constants/portfolio'

/**
 * @class
 * @typedef {Object} BasePortfolio
 */
export class BasePortfolio extends Entity {
  constructor() {
    super()

    /** @type {boolean} */
    this.skeleton = false

    /** @type {?number} */
    this.id = null
    /** @type {?string} */
    this.reference = null
    /** @type {?string} */
    this.title = null

    /** @type {null | 'NEW' | 'COMPLETED' | 'APPROVED' | 'CLOSING' | 'CLOSED'} */
    this.state = null

    /** @type {?string} */
    this.created = null
    /** @type {?string} */
    this.modified = null
    /** @type {?string} */
    this.data_confirmed = null
    /** @type {?string} */
    this.first_topup = null
    /** @type {?string} */
    this.share_url = null

    /** @type {?number} */
    this.current_balance = null
    /** @type {?number} */
    this.available_balance = null
    /** @type {?number} */
    this.capital_balance = null

    /** @type {?number} */
    this.monthly_deposit = null
    /** @type {?number} */
    this.monthly_deposit_recommended = null

    /** @type {boolean} */
    this.is_full_withdrawal_requested = false
    /** @type {boolean} */
    this.is_deletable = false
    /** @type {boolean} */
    this.is_default_preset_used = false
    /** @type {boolean} */
    this.weights_setup_needed = false

    /** @type {?number} */
    this.available_to_move_cash = null
    /** @type {?number} */
    this.pending_hmrc_cash = null

    /** @type {?object} */
    this.contributions = new PortfolioSimpleStatsItem()

    /** @type {object} */
    this.display = new PortfolioDisplayItem()
  }

  /** @override */
  getRules() {
    return {
      skeleton: [[rules.bool]],

      goal: [[rules.int]],
      reference: [[rules.string]],
      title: [[rules.string]],

      state: [[rules.string]],
      share_url: [[rules.string]],

      created: [[rules.iso8601UTC]],
      modified: [[rules.iso8601UTC]],
      data_confirmed: [[rules.iso8601UTC]],
      first_topup: [[rules.iso8601UTC]],

      current_balance: [[rules.float]],
      available_balance: [[rules.float]],
      capital_balance: [[rules.float]],

      monthly_deposit: [[rules.float]],
      monthly_deposit_recommended: [[rules.float]],

      is_full_withdrawal_requested: [[rules.bool]],
      is_deletable: [[rules.bool]],
      is_default_preset_used: [[rules.bool]],
      weights_setup_needed: [[rules.bool]],

      available_to_move_cash: [[rules.float]],
      pending_hmrc_cash: [[rules.float]],

      contributions: [[(item) => PortfolioSimpleStatsItem.createFromObject(item)]],
      display: [[(item) => PortfolioDisplayItem.createFromObject(item)]],
    }
  }

  /**
   * @returns {boolean}
   */
  isActive() {
    return [states.COMPLETED, states.APPROVED].includes(this.state)
  }

  /**
   * @param {?string} defaultTitle
   * @return {string | null}
   */
  getTitle(defaultTitle) {
    /* @type {string | null} */
    let title = null

    if (this.reference) {
      title = `Portfolio ${this.reference}`
    }

    if (defaultTitle) {
      title = defaultTitle
    }

    if (this.title) {
      title = this.title
    }

    if (title && this.state === states.CLOSED) {
      return `${title} (Closed)`
    }

    return title
  }

  /**
   * @param {?boolean} isBusiness
   * @returns {string | null}
   */
  getDescription(isBusiness) {
    const regulatoryType = getKeyOrDefault(
      {
        ISA: this.manage_type === manageTypes.CASH ? 'Stocks & Shares ISA' : 'ISA',
        GIA: isBusiness ? 'Business' : 'General',
        SIPP: 'Personal Pension',
        default: null,
      },
      this.regulatory_type,
    )

    if (!regulatoryType) {
      return null
    }

    const manageType = getKeyOrDefault(
      {
        MANAGED: 'Managed by IE',
        SELF_SELECTED: 'Picked by you, managed by IE',
        DIY: 'Self-managed',
        CASH: '',
        default: null,
      },
      this.manage_type,
    )

    return `${regulatoryType}${manageType ? `, ${manageType}` : ''}`
  }
}
