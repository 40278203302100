import { formatDateToBackend } from 'helpers/date'
import { memoizeForever } from 'helpers/memoize'

import {
  type AbstractCombinedPointOfInterest,
  CombinedPointOfInterestType,
  type AbstractPointOfInterest,
} from 'components/organisms/charts/parts'

import { STROKE_WIDTH } from '../constants'

import type { IncomeLineDataItem } from '../IncomeLine'
import type { XYCoordinates } from './svg'

type CalculateChartValuesProps = {
  data: IncomeLineDataItem[]
  pointsOfInterest: AbstractPointOfInterest[]
  min?: number
  max?: number
  width?: number
  height?: number
}

type CalculateChartValuesItem = {
  position: XYCoordinates
  section: number[]
  data: IncomeLineDataItem
  pointOfInterest?: AbstractPointOfInterest
  min: boolean
  max: boolean
  current: boolean
}

type CalculateChartValuesInterface = CalculateChartValuesItem[]

function combinePointOfInterest(
  point1: AbstractPointOfInterest | AbstractCombinedPointOfInterest,
  point2: AbstractPointOfInterest | AbstractCombinedPointOfInterest,
): AbstractCombinedPointOfInterest {
  const type = CombinedPointOfInterestType
  const date: Date = point2.date
  const dateFrom: Date = (point1?.dateFrom as Date) ?? point1?.date
  const dateTo: Date = point2.date
  const data: Array<Record<string, unknown>> = (() => {
    if (Array.isArray(point1.data) && Array.isArray(point2.data)) {
      return [...point1.data, ...point2.data]
    }

    if (Array.isArray(point1.data)) {
      return [...point1.data, point2]
    }

    if (Array.isArray(point2.data)) {
      return [point1, ...point2.data]
    }

    return [point1, point2]
  })()

  return { type, date, dateFrom, dateTo, data }
}

const calculateChartValues = memoizeForever(
  ({
    data = [],
    pointsOfInterest = [],
    min = 0,
    max = 0,
    width = 0,
    height = 0,
  }: CalculateChartValuesProps): CalculateChartValuesInterface => {
    const offsetX = Math.abs(width / Math.max(data.length - 1, 1))
    const halfOffsetX = offsetX / 2
    const distanceY = max - min

    const isOnlyOneValue = data.length === 1
    const values = data.map(({ value }) => value)
    const minPoint = Math.min(...values)
    const maxPoint = Math.max(...values)

    const result: CalculateChartValuesItem[] = []

    for (let index = 0; index < data.length; index++) {
      const isCurrent = index === data.length - 1
      const item = data[index]
      const { value, date } = item

      const relativeY = Math.abs(value - min) / distanceY || 0

      const position = {
        x: isOnlyOneValue ? halfOffsetX : index * offsetX,
        y: Math.max(relativeY * height, STROKE_WIDTH),
      }

      const pointOfInterest: AbstractPointOfInterest | AbstractCombinedPointOfInterest | undefined = (() => {
        if (pointsOfInterest.length < 1) {
          return undefined
        }

        const currentDatePointsOfInterest = (pointsOfInterest ?? []).filter(
          ({ date: poiDate }) => formatDateToBackend(date) === formatDateToBackend(poiDate),
        )

        if (currentDatePointsOfInterest.length < 1) {
          return undefined
        }

        if (currentDatePointsOfInterest.length === 1) {
          return currentDatePointsOfInterest[0]
        }

        return currentDatePointsOfInterest.reduce(
          (
            combinedPoint: AbstractPointOfInterest | AbstractCombinedPointOfInterest | undefined,
            nextPoint: AbstractPointOfInterest,
          ) => {
            if (combinedPoint) {
              return combinePointOfInterest(combinedPoint, nextPoint)
            }

            return nextPoint
          },
          undefined,
        )
      })()

      result.push({
        position,
        section: [position.x - halfOffsetX, position.x + halfOffsetX],
        data: { value, date },
        pointOfInterest,
        min: value === minPoint,
        max: value === maxPoint,
        current: isCurrent,
      })
    }

    return result
  },
)

const getTicks = memoizeForever((data: IncomeLineDataItem[], limit: number): Array<number | null> => {
  const ticks = data
    .map((tickEl, index, dataArr) => {
      if ((index === 0 || index === dataArr.length - 1) && dataArr.length > 2) {
        return null
      } else {
        return index
      }
    })
    .filter((element) => {
      return element !== null
    })

  const count = ticks.length
  const ticksCount = count > limit ? limit : count

  if (ticksCount < limit) {
    return ticks
  }

  const each = Math.ceil(count / limit)

  return ticks.filter((tick, i) => (i % each) - Math.floor(each / 2) === 0)
})

export { calculateChartValues, type CalculateChartValuesInterface, getTicks }
