import React from 'react'

import Input from 'components/_old/Input/Input.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

import { Paper } from 'components/atoms/Paper'

interface NomineesProviderProps {
  label?: string
  placeholder?: string
  paperSize?: number
  value: string | number | null
  validation: {
    rules: boolean[]
    errors: string[]
  }
  options: Array<{ value: string | number; name: string | null }>
  setValue: (value: unknown) => unknown
  isForceValidationEnabled: boolean
}

const NomineesProvider: React.FC<NomineesProviderProps> = ({
  label = 'Existing provider',
  placeholder = 'Choose your provider',
  paperSize,
  value,
  validation,
  options,
  setValue,
  isForceValidationEnabled,
}) => {
  return (
    <Validate rules={validation.rules} skipWaitBlur={isForceValidationEnabled}>
      {(isValid) => (
        <LabelInlineStyle labelText={label} multiline size="small" errorMessages={validation.errors}>
          <Paper top={paperSize}>
            <Input
              type="select"
              placeholder={placeholder}
              options={options}
              onChange={(_, value: string): void => {
                setValue(value)
              }}
              forceUnnative
              valid={isValid}
            >
              {value}
            </Input>
          </Paper>
        </LabelInlineStyle>
      )}
    </Validate>
  )
}

export { NomineesProvider }
