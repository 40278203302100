import { useDropzone, type DropzoneRootProps, type DropzoneInputProps } from 'react-dropzone'

import { useUnit } from 'effector-react'

import { useState, useRef, useSelector, useActions, useLoading } from 'hooks'

import { $owner } from 'app/effector/contacts'
import { type Contact } from 'app/effector/contacts/models'

import { changeField as changeFieldActionCreator } from 'app/redux/actions/client'
import { showFailToast } from 'app/redux/actions/ui'
import { uploadAvatar } from 'app/redux/api/client'

import './EditAvatar.css'

type useEditAvatarInterface = {
  client: { avatar: string | null }
  isLoading: boolean
  owner?: Contact
  inputFileRef: React.MutableRefObject<null>
  isCropAvatarModalVisible: boolean
  preloadedImage?: string
  handleClose: () => void
  setIsCropAvatarModalVisible: (visible: boolean) => void
  updateClientAvatar: (avatar: string) => Promise<void>
  getRootProps: () => DropzoneRootProps
  getInputProps: () => DropzoneInputProps
}

type useEditAvatarProps = {
  onClose: () => void
}

const useEditAvatar = ({ onClose }: useEditAvatarProps): useEditAvatarInterface => {
  const { isLoading, wait } = useLoading(false)

  const client = useSelector((state) => state.client)
  const owner = useUnit($owner)

  const [isCropAvatarModalVisible, setIsCropAvatarModalVisible] = useState(false)
  const inputFileRef = useRef(null)

  const [preloadedImage, setPreloadedImage] = useState(client.avatar || null)

  const [updateClientField] = useActions([changeFieldActionCreator])

  const updateClientAvatar = async (image): Promise<void> => {
    const formData = new FormData()
    formData.append('avatar', image, 'avatar.jpeg')
    let result
    try {
      result = await wait(uploadAvatar(formData))
      updateClientField({ avatar: result.avatar })
      setIsCropAvatarModalVisible(false)
      setPreloadedImage(result.avatar)
    } catch (error) {
      showFailToast(error?.response?.data?.errors?.[0]?.message)
      setIsCropAvatarModalVisible(false)
      setPreloadedImage(client.avatar || null)
    }
  }

  const handleClose = (): void => {
    setIsCropAvatarModalVisible(false)
    onClose()
  }

  const loadImage = (files): void => {
    const reader = new FileReader()
    reader.onload = function () {
      setPreloadedImage(reader.result)
      setIsCropAvatarModalVisible(true)
    }
    reader.readAsDataURL(files[0])
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: loadImage,
  })

  return {
    client,
    isLoading,
    owner,
    inputFileRef,
    isCropAvatarModalVisible,
    preloadedImage,
    handleClose,
    setIsCropAvatarModalVisible,
    updateClientAvatar,
    getRootProps,
    getInputProps,
  }
}

export { useEditAvatar }
