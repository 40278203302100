import { combine, createEffect, createEvent, createStore, sample } from 'effector'

import { makeApiCall } from 'app/effector/api'

import * as api from './api'
import { StoryList } from './models'

// Events
const processClientStories = createEvent<Record<string, unknown> & { impact: Record<string, string> }>()
const fetchSecurityCollectionsStories = createEvent()
const fetchPresetCollectionsStories = createEvent()

// Effects
const fetchStoriesFx = createEffect(async ({ groups }: { groups?: string[] }) => {
  try {
    return await makeApiCall(api.getStories, { groups: ['common', ...(groups ?? [])] })
  } catch (error) {}
})

const fetchSecurityCollectionsStoriesFx = createEffect(async () => {
  try {
    return await makeApiCall(api.getSecurityCollectionsStories)
  } catch (error) {}
})

const fetchPresetCollectionsStoriesFx = createEffect(async () => {
  try {
    return await makeApiCall(api.getPresetCollectionsStories)
  } catch (error) {}
})

// Stores
const $stories = createStore<StoryList>(new StoryList())
const $securityCollectionsStories = createStore<StoryList>(new StoryList())
const $presetCollectionsStories = createStore<StoryList>(new StoryList())

$stories.on(fetchStoriesFx.doneData, (_state, stories) => new StoryList(...(stories ?? [])))
$securityCollectionsStories.on(
  fetchSecurityCollectionsStoriesFx.doneData,
  (_state, stories) => new StoryList(...(stories ?? [])),
)
$presetCollectionsStories.on(
  fetchPresetCollectionsStoriesFx.doneData,
  (_state, stories) => new StoryList(...(stories ?? [])),
)

const $areStoriesFetched = createStore<boolean>(false)
const $areSecurityCollectionsStoriesFetched = createStore<boolean>(false)
const $arePresetCollectionsStoriesFetched = createStore<boolean>(false)

$areStoriesFetched.on(fetchStoriesFx.done, () => true)
$areSecurityCollectionsStoriesFetched.on(fetchSecurityCollectionsStoriesFx.done, () => true)
$arePresetCollectionsStoriesFetched.on(fetchPresetCollectionsStoriesFx.done, () => true)

// process client stories with impact links
sample({
  clock: processClientStories,
  source: $stories, // TODO: rework to [$stories, $client] when $client is ready
  fn: (stories, client) => stories.processImpactLinks(client),
  target: $stories,
})

// fetch stories only once
sample({
  clock: fetchSecurityCollectionsStories,
  source: $areSecurityCollectionsStoriesFetched,
  filter: (isFetched) => !isFetched,
  target: fetchSecurityCollectionsStoriesFx,
})

sample({
  clock: fetchPresetCollectionsStories,
  source: $arePresetCollectionsStoriesFetched,
  filter: (isFetched) => !isFetched,
  target: fetchPresetCollectionsStoriesFx,
})

const $storiesStore = combine({
  stories: $stories,
  isLoading: fetchStoriesFx.pending,
  areStoriesFetched: $areStoriesFetched,
})

const $securityCollectionsStoriesStore = combine(
  [$securityCollectionsStories, $areSecurityCollectionsStoriesFetched],
  ([collectionStories, isFetched]) => ({
    stories: collectionStories,
    isLoading: fetchSecurityCollectionsStoriesFx.pending,
    areStoriesFetched: isFetched,
    getStoriesByCollection: ({ slug }) => {
      return collectionStories?.filter((story) => story.group === `collection-${slug}`) || []
    },
  }),
)

const $presetCollectionsStoriesStore = combine(
  [$presetCollectionsStories, $arePresetCollectionsStoriesFetched],
  ([collectionStories, isFetched]) => ({
    stories: collectionStories,
    isLoading: fetchPresetCollectionsStoriesFx.pending,
    areStoriesFetched: isFetched,
    getStoriesByCollection: ({ slug }) => {
      return collectionStories?.filter((story) => story.group === `collection-${slug}`) || []
    },
  }),
)

export * from './models'
export {
  // Events
  processClientStories,
  fetchSecurityCollectionsStories,
  fetchPresetCollectionsStories,
  // Effects
  fetchStoriesFx,
  fetchSecurityCollectionsStoriesFx,
  fetchPresetCollectionsStoriesFx,
  // Stores
  $storiesStore,
  $stories,
  $securityCollectionsStoriesStore,
  $securityCollectionsStories,
  $areSecurityCollectionsStoriesFetched,
  $presetCollectionsStoriesStore,
  $presetCollectionsStories,
  $arePresetCollectionsStoriesFetched,
}
