import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useCallback } from 'hooks'

import { Stack } from 'components/atoms/Stack'
import { Paper } from 'components/atoms/Paper'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'
import { VirtualList } from 'components/molecules/VirtualList'
import { SelectableCard } from 'components/atoms/SelectableCard'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { TypeHeadline } from 'app/pages/Dashboard/Analytics/components/TypeHeadline.tsx'
import { Skeleton } from 'components/atoms/Skeleton'

import { equitiesPropTypes } from 'app/pages/Dashboard/Analytics/Analytics.jsx'

import { format as formatMoney } from 'helpers/money'

const RegionsList = ({
  isLoading,
  regions,
  showTarget,
  stocks,
  bonds,
  alternatives,
  cash,
  layoutNode,
  handleGoToSingleHolding,
}) => {
  const renderRegion = useCallback(
    (listElement, items) => {
      const item = items[listElement.index]

      return (
        <Paper top={12}>
          <Skeleton shown={item.skeleton} mix>
            <SelectableCard onClick={() => handleGoToSingleHolding(item.id, 'region')}>
              <Paper bottom={16} top={16} left={16} right={16}>
                <SecurityConstituent
                  key={item.name}
                  logo={item.logo}
                  name={item.name}
                  actual={item.actual}
                  actualAmount={item.actual_amount}
                  target={item.target}
                  color={item.color}
                  showTarget={showTarget}
                  onSettled={listElement.measure}
                />
              </Paper>
            </SelectableCard>
          </Skeleton>
        </Paper>
      )
    },
    [showTarget, handleGoToSingleHolding],
  )

  const renderNoRegions = useCallback(() => null, [])

  const renderRegionsList = useCallback(
    ([title, equity, regions], showTarget) => (
      <Fragment key={title}>
        <TypeHeadline
          isLoading={isLoading}
          title={title}
          actual={equity.weight}
          target={equity.target}
          showTarget={showTarget}
        />
        <Paper top={4}>
          <Typography size={14} lineHeight="small" color="minor">
            <Skeleton shown={isLoading} inline>
              <Typo>{formatMoney(equity.weight_amount, true, true)}</Typo>
            </Skeleton>
          </Typography>
        </Paper>
        {(regions?.length ?? 0) > 0 && (
          <Paper top={showTarget ? 16 : 20}>
            {showTarget && (
              <Paper bottom={4}>
                <Typography align="right">
                  <Skeleton shown={equity.skeleton} mix inline>
                    <ColumnarLayout mods={{ padding: 'no' }} inline>
                      <Column size={0}>
                        <Typography tag="span" size={14} lineHeight="small" color="additional">
                          <Typo>Actual</Typo>
                        </Typography>
                      </Column>
                      <Column size={0}>
                        <Typography tag="span" size={12} lineHeight="small" color="minor">
                          &nbsp;/&nbsp;<Typo>Target</Typo>
                        </Typography>
                      </Column>
                    </ColumnarLayout>
                  </Skeleton>
                </Typography>
              </Paper>
            )}
            <VirtualList
              scrollableElement={layoutNode?.current}
              cacheKey="analytics-regions-list-mobile"
              items={regions}
              itemMinHeight={72}
              itemMaxHeight={92}
              renderItem={(element) => renderRegion(element, regions)}
              renderNoItems={renderNoRegions}
            />
          </Paper>
        )}
      </Fragment>
    ),
    [isLoading, layoutNode?.current, renderRegion, renderNoRegions],
  )

  return (
    <Paper top={24}>
      <Stack vertical={48}>
        {[
          ['Equities', stocks, regions],
          ['Bonds', bonds],
          ['Alternatives', alternatives],
          ['Cash', cash],
        ].map((data) => renderRegionsList(data, showTarget))}
      </Stack>
    </Paper>
  )
}

RegionsList.propTypes = {
  isLoading: PropTypes.bool,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      actual: PropTypes.number.isRequired,
      target: PropTypes.number,
    }),
  ),
  showTarget: PropTypes.bool,
  stocks: PropTypes.shape(equitiesPropTypes),
  bonds: PropTypes.shape(equitiesPropTypes),
  alternatives: PropTypes.shape(equitiesPropTypes),
  cash: PropTypes.shape(equitiesPropTypes),
  layoutNode: PropTypes.oneOfType([PropTypes.shape({ current: PropTypes.elementType }), PropTypes.object]),
  handleGoToSingleHolding: PropTypes.func,
}

export { RegionsList }
