import React, { cloneElement, Fragment } from 'react'

import { useState } from 'hooks'

import { PortfolioUnavailableModal } from '../PortfolioUnavailableModal/PortfolioUnavailableModal'

type PortfolioUnavailableInterceptorProps = {
  children: React.ReactElement
  variant: 'managed' | 'lifeplans'
  hasContinueToDiy?: boolean
  onClick?: (...args: any) => void
}

const PortfolioUnavailableInterceptor = ({
  children,
  variant,
  hasContinueToDiy,
  onClick,
}: PortfolioUnavailableInterceptorProps): React.ReactElement => {
  const [isVisible, setVisible] = useState(false)

  if (!variant) {
    return children
  }

  const modifiedChild = cloneElement(children, {
    onClick: (event) => {
      event.preventDefault()
      setVisible(true)
    },
    to: null,
  })

  return (
    <Fragment>
      {modifiedChild}
      {isVisible && (
        <PortfolioUnavailableModal
          isOpen={isVisible}
          handleClose={() => {
            setVisible(false)
          }}
          variant={variant}
          hasContinueToDiy={hasContinueToDiy}
          onClick={onClick}
          data-test-id="portfolioUnavailableModal"
        />
      )}
    </Fragment>
  )
}

export { PortfolioUnavailableInterceptor }
