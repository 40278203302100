import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries, useMemo, useCallback } from 'hooks'

import { unformat } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import Button from 'components/_old/Button/Button.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ControlledInput } from 'components/atoms/Forms/ControlledInput'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Preloader } from 'components/molecules/Preloader'

import { OptionsLayout } from '../../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../../Options/OptionsNavigationBar'
import { useAutoinvestAmount } from '../hooks'

type AutoinvestAmountProps = {
  location: object
  portfolio: Portfolio
  handleClose: () => void
}

const AutoinvestAmount = withRouter(
  ({ location: { query = {} } = {}, portfolio, handleClose }: AutoinvestAmountProps): React.ReactElement => {
    const { desktop } = useMediaQueries()
    const { isLoading, control, errors, validation, isValid, handleSave } = useAutoinvestAmount(
      portfolio.id,
      portfolio.min_autoinvest_amount,
      query,
    )

    const handleBack = useCallback(() => {
      goTo(urlTo('dashboard.portfolio.autoinvest', { id: portfolio.id }, query))
    }, [portfolio.id, query])

    const inner = useMemo(
      () => (
        <Fragment>
          <Paper top={56} bottom={16}>
            <Typography
              tag="h1"
              size={desktop ? 32 : 24}
              lineHeight={desktop ? 'small' : 'medium'}
              align="center"
              weight="semibold"
            >
              <Typo>Set your cash buffer</Typo>
            </Typography>
          </Paper>
          <Typography size={desktop ? 16 : 14} lineHeight={desktop ? 'small' : 'medium'} align="center" color="minor">
            <Typo multiline>
              Only invest cash that exceeds this specified amount — eg, if you set a cash buffer of £25 and you have
              total cash of £100, AutoInvest will only invest £75.
            </Typo>
          </Typography>
          <Paper top={40} bottom={24}>
            <ControlledInput name="amount" control={control} errors={errors} rules={validation}>
              {({ value, valid, errorMessage, handleChange, handleFocus, handleBlur }) => {
                return (
                  <Width size={17.5} center>
                    <Label valid={valid} errorMessage={errorMessage} data-test-id="cashBufferField">
                      <Typography size={desktop ? 32 : 24}>
                        <Input
                          name="value"
                          type="money"
                          placeholder="£"
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        >
                          {value ? unformat(value) : value}
                        </Input>
                      </Typography>
                    </Label>
                    {!valid && (
                      <Paper top={16}>
                        <Typography size={16} color="error" align="center">
                          {errorMessage}
                        </Typography>
                      </Paper>
                    )}
                  </Width>
                )
              }}
            </ControlledInput>
          </Paper>
        </Fragment>
      ),
      [desktop, control, errors, validation],
    )

    const content = useMemo(() => {
      if (desktop) {
        return (
          <Width size={25} center>
            {inner}
          </Width>
        )
      }
      return inner
    }, [desktop, inner])

    return (
      <form onSubmit={handleSave as () => void}>
        <Preloader loading={isLoading} background="background" delay={400} zIndex absolute />
        <OptionsLayout
          header={
            <OptionsNavigationBar
              leftPartText="Back"
              onLeftPartClick={handleBack}
              rightPartText="Close"
              onRightPartClick={handleClose}
            >
              <Typo>AutoInvest</Typo>
            </OptionsNavigationBar>
          }
          content={content}
          button={
            <Button type="submit" mods={{ size: 'big block' }} disabled={!isValid} data-test-id="saveCashBufferButton">
              Save
            </Button>
          }
          data-test-id="portfolioOptionsModal"
        />
      </form>
    )
  },
)

export { AutoinvestAmount }
