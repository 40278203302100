import React from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { Logo } from 'components/_old/Logo/Logo.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './BuildByBadge.css'

const BuildByBadge = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const classes = classNames('BuildByBadge', { BuildByBadge_desktop: desktop })

  return (
    <div className={classes}>
      <Paper top={8} bottom={8} left={desktop ? 20 : 12} right={desktop ? 20 : 12}>
        <ItemWithIcon
          inline
          space={8}
          icon={<Logo style={{ height: desktop ? 20 : 18 }} fluid />}
          iconVerticalAlign="center"
          contentVerticalAlign="center"
          iconPosition="right"
          content={
            <Typography size={desktop ? 16 : 14}>
              <Typo>Built by our Users, Powered by</Typo>
            </Typography>
          }
          data-test-id="buildByUsersBadge"
        />
      </Paper>
    </div>
  )
}

export { BuildByBadge }
