import moment from 'helpers/date'

import { frequencies } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'
import type { Frequency } from 'app/pages/Dashboard/Goals/RecurringPayment/types'

const convertRecurringPaymentAmountToMonthly = (amount: number, frequency: string): number => {
  if (frequency === frequencies.WEEKLY) {
    return Math.round(Number(amount) * 4.35)
  }

  if (frequency === frequencies.FORTNIGHT) {
    return Math.round(Number(amount) * 2.17)
  }

  return amount
}

const convertMonthlyAmountToFrequency = (amount: number, frequency: string): number => {
  if (frequency === frequencies.WEEKLY) {
    return Math.round(Number(amount) / 4.35)
  }

  if (frequency === frequencies.FORTNIGHT) {
    return Math.round(Number(amount) / 2.17)
  }

  return amount
}

const countDaysBeforePayment = (frequency: Frequency, startDayWeek: number, dayOfMonht: number): number => {
  const today = moment().startOf('day')

  if (frequency === frequencies.MONTHLY) {
    const nextDay = moment()
      .startOf('month')
      .add(dayOfMonht - 1, 'days')
    const paymentDate = today.isAfter(nextDay) ? nextDay.add(1, 'month') : nextDay

    return paymentDate.diff(today, 'days')
  }

  if (frequency === frequencies.WEEKLY || frequency === frequencies.FORTNIGHT) {
    const nextDay = moment()
      .startOf('week')
      .add(startDayWeek - 1, 'days')
    const paymentDate = today.isAfter(nextDay) ? nextDay.add(1, 'week') : nextDay

    return paymentDate.diff(today, 'days')
  }

  return 0
}

export { convertRecurringPaymentAmountToMonthly, convertMonthlyAmountToFrequency, countDaysBeforePayment }
