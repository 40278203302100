import axios from 'helpers/ajax/index.js'

import { ApiError } from 'app/redux/models/errors/index.js'

async function getOnfidoSDKToken(contactId: number | string): Promise<string | ApiError> {
  try {
    const referrer = `*://${window.location.host}/*`
    const endpoint = `id_check/identity/generate_sdk_token/${contactId}/`

    const { data } = await axios.post<{ token: string }>(endpoint, {
      referrer,
    })

    if (!data.token) {
      throw new Error(`No token was returned from the \`${endpoint}\` call`)
    }

    return data.token
  } catch (error) {
    return new ApiError(error)
  }
}

export { getOnfidoSDKToken }
