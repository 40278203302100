import { createStore, createEffect, createEvent, sample } from 'effector'

import { makeApiCall } from 'app/effector/api'

import { fetchNomineesTransferProviders, type NomineesTransferProviderRegulatoryType } from './api'
import { NomineesTransferProviderList } from './models'

const fetchProvidersIfNeeded = createEvent<NomineesTransferProviderRegulatoryType>()

const fetchNomineesTransferProvidersFx = createEffect(
  async (regulatoryType?: NomineesTransferProviderRegulatoryType) =>
    await makeApiCall(fetchNomineesTransferProviders, regulatoryType),
)

const $nomineesTransferProviderList = createStore(new NomineesTransferProviderList())

$nomineesTransferProviderList.on(fetchNomineesTransferProvidersFx.doneData, (state, providers) =>
  state.merge(new NomineesTransferProviderList(...providers)),
)

// Fetch providers when needed
sample({
  clock: fetchProvidersIfNeeded,
  source: $nomineesTransferProviderList,
  filter: (providers, regulatoryType) => {
    const providersOfRegulatoryType = providers.filter((provider) => provider.regulatory_type === regulatoryType)

    return providersOfRegulatoryType.length < 1
  },
  fn: (_, regulatoryType) => regulatoryType,
  target: fetchNomineesTransferProvidersFx,
})

export * from './api'
export * from './models'

export { fetchProvidersIfNeeded, fetchNomineesTransferProvidersFx, $nomineesTransferProviderList }
