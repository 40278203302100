import React from 'react'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import type { SessionTimeoutProps } from './SessionTimeout'

const Desktop = ({ countdown, handleLogout, handleStay }: SessionTimeoutProps): React.ReactElement => {
  return (
    <Width size={36} style={{ minHeight: 600 }} center>
      <Paper top={64}>
        <Width size={28} center>
          <Paper top={8}>
            <Typography size={16} weight="semibold" align="center">
              {countdown}
            </Typography>
          </Paper>
          <Paper top={48} bottom={32}>
            <Typography size={32} lineHeight="small" weight="semibold" align="center">
              <Typo>Are you still here?</Typo>
            </Typography>
          </Paper>
          <Typography size={14} align="center">
            <Typo>
              You will be automatically logged out of your account in 60 seconds. To stay logged in, click on the button
              below.
            </Typo>
          </Typography>
        </Width>
        <Paper top={56} bottom={80}>
          <Width size={24} center>
            <Button
              mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
              data-test-id="skipAutoinvestActivationButton"
              onClick={handleLogout}
            >
              Log out now
            </Button>
            <Paper top={24}>
              <Button onClick={handleStay} mods={{ size: 'big block' }} data-test-id="activateAutoinvestButton">
                Stay logged in
              </Button>
            </Paper>
          </Width>
        </Paper>
      </Paper>
    </Width>
  )
}

export { Desktop }
