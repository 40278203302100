import { useRef, useEffect, useCallback } from 'react'

const useDebouncedCallback = (callback, wait, deps) => {
  const argsRef = useRef()
  const timeout = useRef()

  const cleanup = () => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  }

  useEffect(() => cleanup, [])

  const run = (...args) => {
    argsRef.current = args

    cleanup()

    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        // eslint-disable-next-line
        callback(...argsRef.current)
      }
    }, wait)
  }

  if (deps) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useCallback(run, deps)
  }

  return run
}

export default useDebouncedCallback
