import React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import snakeCase from 'lodash/snakeCase.js'

import { useMediaQueries, useState, useMemo, useCallback } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'

import { Checkbox } from 'components/atoms/Checkbox'
import { Paper, type PaperSizes } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { VisuallyHidden } from 'components/atoms/VisuallyHidden'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { type FilterType } from '../Filter'

import './FilterElement.css'

type FilterElementProps = {
  type: FilterType
  name: string | React.ReactNode
  radioName?: string
  value: unknown
  description?: React.ReactNode
  showDescriptionInTooltip?: boolean
  paperSizes?: {
    desktop?: {
      top?: ArrayElement<typeof PaperSizes>
      right?: ArrayElement<typeof PaperSizes>
      bottom?: ArrayElement<typeof PaperSizes>
      left?: ArrayElement<typeof PaperSizes>
    }
    mobile?: {
      top?: ArrayElement<typeof PaperSizes>
      right?: ArrayElement<typeof PaperSizes>
      bottom?: ArrayElement<typeof PaperSizes>
      left?: ArrayElement<typeof PaperSizes>
    }
  }
  withLine?: boolean
  checked: boolean
  align?: 'center' | 'flex-start' | 'flex-end'
  onChange: () => void
  'data-test-id'?: string
}

const FilterElement = ({
  type,
  name,
  radioName,
  value,
  description,
  paperSizes,
  showDescriptionInTooltip,
  withLine = false,
  checked,
  align = 'flex-start',
  onChange: handleChange,
  'data-test-id': dataTestId,
}: FilterElementProps): React.ReactElement => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries
  const [lineHeight, setLineHeight] = useState<string>(desktop ? `16px` : `24px`)

  const setLineHeightByTheRootElement: React.RefCallback<HTMLLabelElement> = useCallback((node) => {
    if (node) {
      const lineHeight = getComputedStyle(node)?.lineHeight

      if (lineHeight) {
        setLineHeight(lineHeight)
      }
    }
  }, [])

  const classes = useMemo(
    () =>
      classNames(
        'FilterElement',
        { FilterElement_withLine: withLine },
        getMediaQuieryClasses('FilterElement', mediaQueries),
      ),
    [mediaQueries, withLine],
  )

  const style: React.CSSProperties = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ({ '--filter-element-line-height': lineHeight }) as React.CSSProperties,
    [lineHeight],
  )

  return (
    <Paper
      tag="label"
      ref={setLineHeightByTheRootElement}
      style={style}
      className={classes}
      top={desktop ? paperSizes?.desktop?.top ?? 12 : paperSizes?.mobile?.top ?? 16}
      right={desktop ? paperSizes?.desktop?.right ?? 24 : paperSizes?.mobile?.right ?? 16}
      bottom={desktop ? paperSizes?.desktop?.bottom ?? 12 : paperSizes?.mobile?.bottom ?? 16}
      left={desktop ? paperSizes?.desktop?.left ?? 24 : paperSizes?.mobile?.left ?? 16}
      data-test-id={dataTestId}
    >
      <ColumnarLayout mods={{ padding: 'no', 'align-columns': align }}>
        <Column size={1} className="FilterElement-Name">
          <Typo>
            {name}
            {description && !showDescriptionInTooltip && <VisuallyHidden tag="span">;</VisuallyHidden>}
          </Typo>
          {description && !showDescriptionInTooltip ? (
            <Paper top={8}>
              <Typography size={desktop ? 12 : 14} lineHeight="medium" color="minor">
                {description}
              </Typography>
            </Paper>
          ) : null}
        </Column>
        <Column size={0} className="FilterElement-InputHolder">
          <Paper left={16}>
            <Checkbox
              className="FilterElement-Input"
              realInputClassName="FilterElement-RealInput"
              type={type}
              name={snakeCase(type === 'radio' && radioName ? radioName : name)}
              value={value ? snakeCase(value) : undefined}
              checked={checked ?? false}
              onChange={handleChange}
              onClick={handleChange}
            />
          </Paper>
        </Column>
      </ColumnarLayout>
    </Paper>
  )
}

export { FilterElement, type FilterElementProps }
