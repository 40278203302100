import React from 'react'

import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { format as formatMoney } from 'helpers/money'
import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import Segment from 'components/atoms/Segment/Segment.jsx'

import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

import { BankAccountStateContent } from 'app/pages/Dashboard/Goals/components/BankAccount/BankAccountStateContent.jsx'

import { manageTypes } from 'constants/portfolio'

const successMessages = {
  [manageTypes.DIY]: <Typo>These funds are now available to invest.</Typo>,
  [manageTypes.CASH]: <Typo>These funds are now available to invest.</Typo>,
  [manageTypes.MANAGED]: <Typo>These funds will be invested in your selected portfolio within one working day.</Typo>,
  [manageTypes.SELF_SELECTED]: (
    <Typo>These funds will be invested in your selected portfolio within one working day.</Typo>
  ),
}

const Executing = ({ amount, onContinue: handleContinue, portfolio }) => {
  const { desktop } = useMediaQueries()

  return (
    <AllCenter>
      <Width size={65}>
        <Width size={50} center>
          <Inner>
            <BankAccountStateContent
              title={`${formatMoney(amount, true)} has been added to your account`}
              description={
                <Text block center>
                  {successMessages[portfolio.manage_type]}
                </Text>
              }
              icon={{
                type: 'checkmark_in_a_circle',
                color: palette['status-success'],
                size: 48,
              }}
              data-test-id="instantPaymentExecuting"
            >
              <Segment mods={{ margin: 'hefty', noMargin: 'top' }}>
                <ModalContentButtons width={desktop ? 16 : 24} isNewModal>
                  <Button
                    mods={{ size: 'big block' }}
                    onClick={handleContinue}
                    data-test-id="instantPaymentExecutingButton"
                  >
                    Continue
                  </Button>
                </ModalContentButtons>
              </Segment>
            </BankAccountStateContent>
          </Inner>
        </Width>
      </Width>
    </AllCenter>
  )
}

Executing.propTypes = {
  amount: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
  portfolio: PropTypes.object.isRequired,
}

export { Executing }
