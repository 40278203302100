import React from 'react'

import { useCallback, useMediaQueries } from 'hooks'

import { format as formatMoney } from 'helpers/money'

import type { QuickStart } from 'app/effector/quickStart/types'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { usePortfolioActions, ActionKey } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'

import lumpSumImage from './lumpSum.png'

import './LumpSumBanner.css'

type LumpSumBannerProps = {
  portfolio: Portfolio
  quickStart: QuickStart
}

const LumpSumBanner = ({ portfolio, quickStart }: LumpSumBannerProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { actionsMap } = usePortfolioActions(portfolio)

  const handleClick = useCallback(() => {
    const currentLocation = window.location.pathname + window.location.search

    actionsMap[ActionKey.ADD_FUNDS].onClick(currentLocation)
  }, [actionsMap])

  return (
    <SelectableCard className="LumpSumBanner_Card" onClick={handleClick} data-test-id="lumpSumBanner">
      <Paper top={16} bottom={16} left={desktop ? 32 : 24} right={desktop ? 32 : 0}>
        <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
          <Column size={1}>
            <Typography size={desktop ? 32 : 24} weight="semibold" color="white" data-test-id="lumpSumBannerTitle">
              <Typo noNbsp>
                Finish adding a lump sum <Nobr>of {formatMoney(quickStart?.amount, true)} →</Nobr>
              </Typo>
            </Typography>
          </Column>
          <Column size={0}>
            <img src={lumpSumImage} width={132} height={132} />
          </Column>
        </ColumnarLayout>
      </Paper>
    </SelectableCard>
  )
}

export { LumpSumBanner }
