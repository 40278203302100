import cloneDeep from 'lodash/cloneDeep'

import {
  SET_VALID,
  SET_NOT_VALID,
  RECEIVE_ERROR,
  RESET_ERROR,
  RECEIVE_QUESTIONS,
  SET_CLIENT_ANSWER,
  SET_CLIENT_ANSWERS,
} from 'app/redux/actions/questionnaire/questionnaireActionTypes.js'
import { Question, QuestionList, ClientAnswer, ClientAnswerList } from 'app/redux/models/questionnaire'

export const initialState = {
  didInvalidate: false,
  error: null,

  questionList: new QuestionList(),
  clientAnswerList: new ClientAnswerList(),
}

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case SET_NOT_VALID:
      return { ...state, didInvalidate: true }

    case SET_VALID:
      return { ...state, didInvalidate: false }

    case RECEIVE_ERROR:
      return { ...state, error: action.payload.error }

    case RESET_ERROR:
      return { ...state, error: null }

    case RECEIVE_QUESTIONS: {
      const { questionList } = state
      const questions = action.payload?.questions

      if (!questions?.length) {
        return state
      }

      const nextQuestionList = QuestionList.createFromObject(cloneDeep(questionList))
      nextQuestionList.receiveQuestions(questions.map((question) => Question.createFromObject(question)))

      return {
        ...state,
        questionList: nextQuestionList,
      }
    }
    case SET_CLIENT_ANSWER: {
      const { clientAnswerList, questionList } = state

      const question = questionList.findQuestionByCode(action.payload.questionCode)

      if (!question) {
        throw new Error(`Question with code '${action.payload.questionCode}' not found in state.`)
      }

      const { id, answer_ids: answerIds, value } = action.payload.answer

      const fields = {
        question_id: question.id,
        answer_ids: question.type !== 'TEXT' ? answerIds : [],
        value: question.type === 'TEXT' ? value : null,
      }

      if (id) {
        fields.id = id
      }

      const nextClientAnswerList = ClientAnswerList.createFromObject(clientAnswerList)
      nextClientAnswerList.setClientAnswer(fields)

      return {
        ...state,
        clientAnswerList: nextClientAnswerList,
      }
    }

    case SET_CLIENT_ANSWERS: {
      const { clientAnswerList } = state

      const clientAnswers = action.payload.answers.map((answer) => {
        const question = state.questionList.findQuestionById(answer.question_id)

        // WTF???
        if (!question) {
          return answer
        }

        return ClientAnswer.createFromObject({
          ...answer,
          answer_ids: question.type !== 'TEXT' ? answer.answer_ids : [],
          value: question.type === 'TEXT' ? answer.value : null,
          question_id: question.id,
        })
      })

      const nextClientAnswerList = ClientAnswerList.createFromObject(cloneDeep(clientAnswerList))
      nextClientAnswerList.receiveAnswers(clientAnswers)

      return {
        ...state,
        clientAnswerList: nextClientAnswerList,
      }
    }
    default:
      return state
  }
}
