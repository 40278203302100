import React from 'react'

import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './AdvertisementBanner.css'

type AdvertisementProps = {
  title: string
  description: string
  linkLabel: string
  linkHref: string
  imageSrc: string
  imageRetinaSrc: string
}

const AdvertisementBanner = ({
  title,
  description,
  linkLabel,
  linkHref,
  imageSrc,
  imageRetinaSrc,
}: AdvertisementProps): JSX.Element => {
  return (
    <Paper tag="article" left={64} top={48}>
      <Typography tag="h2" color="white" size={48} lineHeight="small" weight="bold" font="Poppins">
        <Typo>{title}</Typo>
      </Typography>
      <Paper top={16}>
        <Typography tag="p" color="white" size={16} style={{ opacity: 0.8 }}>
          <Typo>{description}</Typo>
        </Typography>
      </Paper>
      <Paper top={24}>
        <Button mods={{ theme: 'simple-reverse-content-on-color' }}>
          <Link to={linkHref} hard>
            {linkLabel}
          </Link>
        </Button>
      </Paper>
      <Paper top={24}>
        <img
          alt=""
          src={imageSrc}
          srcSet={`${imageSrc} 1x, ${imageRetinaSrc} 2x`}
          className="AdvertisementBanner-Image"
        />
      </Paper>
    </Paper>
  )
}

export default AdvertisementBanner
