import {
  type AnalyticsGeneralData,
  AnalyticsEquityType,
  type ProcessedAnalyticsData,
  type ProcessedEquities,
} from '../types'

import { calculateSummary } from './calculateSummary'

const parseAnalyticsGeneralData = (data?: AnalyticsGeneralData): ProcessedAnalyticsData => {
  const processEquities = (type: AnalyticsEquityType): ProcessedEquities => {
    const equities = (data?.equities ?? []).filter((equity) => equity?.equity_type === type)
    const value = data?.equity_types?.[type]?.value ? parseFloat(data?.equity_types?.[type]?.value) : null
    const weight = data?.equity_types?.[type]?.actual_weight
      ? parseFloat(data?.equity_types?.[type]?.actual_weight)
      : null
    const target = data?.equity_types?.[type]?.target_weight
      ? parseFloat(data?.equity_types?.[type]?.target_weight ?? '')
      : null
    const items = equities.map((equity) => ({
      ...equity,
      id: equity?.id,
      logo: equity?.logo,
      name: equity?.name,
      actual: equity?.actual_weight ? parseFloat(equity.actual_weight) : null,
      target: equity?.target_weight ? parseFloat(equity.target_weight) : null,
      actual_amount: (parseFloat(equity.actual_weight ?? '') * totalAmount) / 100,
    }))
    const count = items.length

    return {
      value,
      weight,
      target,
      items,
      count,
      weight_amount: (totalAmount * (weight as number)) / 100,
    }
  }

  const totalAmount = parseFloat(data?.total_value ?? '0')

  const allStocks = processEquities(AnalyticsEquityType.STOCK)

  const bonds = processEquities(AnalyticsEquityType.BOND)
  const alternatives = processEquities(AnalyticsEquityType.ALTERNATIVE)
  const cash = processEquities(AnalyticsEquityType.CASH)
  const regions = (data?.regions ?? []).map((region) => ({
    ...region,
    actual: parseFloat(region.actual_weight),
    actual_amount: (parseFloat(region.actual_weight) * totalAmount) / 100,
    target: region.target_weight ? parseFloat(region.target_weight) : null,
  }))
  const sectors = (data?.sectors ?? []).map((sector) => ({
    ...sector,
    actual: parseFloat(sector.actual_weight),
    actual_amount: (parseFloat(sector.actual_weight) * totalAmount) / 100,
    target: sector.target_weight ? parseFloat(sector.target_weight) : null,
  }))

  const summary = calculateSummary(data)

  return {
    allStocks,
    bonds,
    alternatives,
    cash,
    regions,
    sectors,
    summary,
  }
}

export { parseAnalyticsGeneralData }
