import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { useEffect, useOnScreen, useRef } from 'hooks'

import { AnimatedVisibilityToggle } from 'components/atoms/AnimatedVisibilityToggle'
import { NavigationBar } from 'components/atoms/NavigationBar'

const AnimatedNavigationBar = (
  {
    children,
    className,
    nodeToWatchTo,
    leftPartText,
    rightPartText,
    onLeftPartClick: handleLeftPartClick,
    onRightPartClick: handleRightPartClick,
  },
  ref,
) => {
  const navBarTitleRef = useRef()

  const onScreen = useOnScreen(nodeToWatchTo, {
    rootMargin: '0px 0px -50% 0px',
    threshold: 0.5,
  })

  useEffect(() => {
    if (typeof onScreen === 'boolean') {
      const method = onScreen ? 'hide' : 'show'

      if (navBarTitleRef?.current?.[method]) {
        navBarTitleRef?.current?.[method]()
      }
    }
  }, [navBarTitleRef, onScreen])

  return (
    <NavigationBar
      className={className}
      leftPartText={leftPartText}
      onLeftPartClick={handleLeftPartClick}
      rightPartText={rightPartText}
      onRightPartClick={handleRightPartClick}
    >
      <AnimatedVisibilityToggle ref={navBarTitleRef} visible={false}>
        {children}
      </AnimatedVisibilityToggle>
    </NavigationBar>
  )
}

const AnimatedNavigationBarWithForwardedRef = forwardRef(AnimatedNavigationBar)

AnimatedNavigationBarWithForwardedRef.propTypes = AnimatedNavigationBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  nodeToWatchTo: PropTypes.object,
  leftPartText: PropTypes.string,
  rightPartText: PropTypes.string,
  onLeftPartClick: PropTypes.func,
  onRightPartClick: PropTypes.func,
}

export { AnimatedNavigationBarWithForwardedRef as AnimatedNavigationBar }
