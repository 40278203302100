import moment, { formatDateToBackend } from 'helpers/date'

import { nullable, type Nullable, string, enumValue, int, date, boolean } from 'app/effector/model'

import { type ReportRequestDTO } from '../api'

import { ReportFormat } from './Report'
import { type ReportType } from './ReportType'

enum ReportRequestStep {
  SELECT_TYPE = 'SELECT_TYPE',
  SELECT_TIME_PERIOD = 'SELECT_TIME_PERIOD',
  SELECT_CUSTOM_TIME_PERIOD = 'SELECT_CUSTOM_TIME_PERIOD',
  SELECT_PORTFOLIOS = 'SELECT_PORTFOLIOS',
  SELECT_FILE_FORMAT = 'SELECT_FILE_FORMAT',
}

enum ReportRequestTimePeriod {
  LAST_MONTH = 'LAST_MONTH',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  CURRENT_TAX_YEAR = 'CURRENT_TAX_YEAR',
  PREVIOUS_TAX_YEAR = 'PREVIOUS_TAX_YEAR',
  CUSTOM = 'CUSTOM',
}

const fullReportFlow = [
  ReportRequestStep.SELECT_TYPE,
  ReportRequestStep.SELECT_TIME_PERIOD,
  ReportRequestStep.SELECT_CUSTOM_TIME_PERIOD,
  ReportRequestStep.SELECT_PORTFOLIOS,
  ReportRequestStep.SELECT_FILE_FORMAT,
]

type ReportRequestConstructor = Partial<ReportRequestDTO | ReportRequest> & {
  useCustomTimePeriod?: boolean
  step?: ReportRequestStep | null
}

class ReportRequest {
  readonly #flow: ReportRequestStep[]
  readonly #reportTypes: ReportType[]
  type: Nullable<string>
  portfolio_ids: Nullable<number[]>
  format: Nullable<ReportFormat>
  date_from: Nullable<Date>
  date_to: Nullable<Date>
  useCustomTimePeriod: boolean
  step: ReportRequestStep | null

  static getMatchingFlow(constructor: ReportRequestConstructor, reportTypes: ReportType[]): ReportRequestStep[] {
    const reportType = reportTypes.find((type) => type.type === constructor.type)
    let flow = fullReportFlow

    if (!constructor.useCustomTimePeriod) {
      flow = flow.filter((step) => step !== ReportRequestStep.SELECT_CUSTOM_TIME_PERIOD)
    }

    if (!reportType?.is_one_portfolio_allowed) {
      flow = flow.filter((step) => step !== ReportRequestStep.SELECT_PORTFOLIOS)
    }

    if (reportType?.formats.length === 1) {
      flow = flow.filter((step) => step !== ReportRequestStep.SELECT_FILE_FORMAT)
    }

    return flow
  }

  static selectDefaultTypeFormat(ctor: ReportRequestConstructor, reportTypes: ReportType[]): ReportFormat | undefined {
    const reportType = reportTypes.find((type) => type.type === ctor.type)

    if (reportType?.formats.length === 1) {
      return reportType.formats[0]
    }
  }

  static getDatesFromTimePeriod(timePeriod: ReportRequestTimePeriod): [dateFrom: Date, dateTo: Date] {
    const currentDay = moment().date()
    const currentMonth = moment().month()
    const isTaxYearStartInThisYear = (currentMonth === 3 && currentDay >= 6) || currentMonth > 3

    let dateFrom = new Date()
    let dateTo = new Date()

    if (timePeriod === ReportRequestTimePeriod.LAST_MONTH) {
      dateFrom = moment(new Date()).subtract(1, 'month').toDate()
    }

    if (timePeriod === ReportRequestTimePeriod.LAST_12_MONTHS) {
      dateFrom = moment(new Date()).subtract(12, 'month').toDate()
    }

    if (timePeriod === ReportRequestTimePeriod.CURRENT_TAX_YEAR) {
      dateFrom = isTaxYearStartInThisYear
        ? moment().month(3).date(6).toDate()
        : moment().subtract(1, 'years').month(3).date(6).toDate()
    }

    if (timePeriod === ReportRequestTimePeriod.PREVIOUS_TAX_YEAR) {
      dateFrom = isTaxYearStartInThisYear
        ? moment().subtract(1, 'years').month(3).date(6).toDate()
        : moment().subtract(2, 'years').month(3).date(6).toDate()
      dateTo = isTaxYearStartInThisYear
        ? moment().month(3).date(5).toDate()
        : moment().subtract(1, 'years').month(3).date(5).toDate()
    }

    return [dateFrom, dateTo]
  }

  constructor(ctor: ReportRequestConstructor, reportTypes: ReportType[]) {
    const flow = ReportRequest.getMatchingFlow(ctor, reportTypes)
    const format = ctor.format ?? ReportRequest.selectDefaultTypeFormat(ctor, reportTypes)

    this.#flow = flow
    this.#reportTypes = reportTypes

    this.type = nullable(string)(ctor.type)
    this.format = nullable(enumValue)(format, ReportFormat)
    this.date_from = nullable(date)(ctor.date_from, true)
    this.date_to = nullable(date)(ctor.date_to ?? new Date(), true)
    this.portfolio_ids = ctor.portfolio_ids
      ? (ctor.portfolio_ids ?? []).map((id) => int(id)).filter((id) => !isNaN(id))
      : null
    this.useCustomTimePeriod = boolean(ctor.useCustomTimePeriod ?? true)
    this.step = typeof ctor?.step === 'undefined' ? flow[0] : ctor?.step
  }

  #getCurrentStepIndex(): number {
    return this.#flow.findIndex((step) => step === this.step)
  }

  #getStep(mod: number): ReportRequestStep | null {
    const currentStepIndex = this.#getCurrentStepIndex()

    if (currentStepIndex > -1) {
      return this.#flow[currentStepIndex + mod] ?? null
    }

    return null
  }

  getPrevStep(): ReportRequestStep | null {
    return this.#getStep(-1)
  }

  getNextStep(): ReportRequestStep | null {
    return this.#getStep(1)
  }

  #goStep(mod: -1 | 1): ReportRequest {
    const back = mod === -1
    const step = back ? this.getPrevStep() ?? this.#flow.at(0) : this.getNextStep() ?? this.#flow.at(-1)

    return new ReportRequest(
      {
        ...this,
        step,
      },
      this.#reportTypes,
    )
  }

  goPrevStep(): ReportRequest {
    return this.#goStep(-1)
  }

  goNextStep(): ReportRequest {
    return this.#goStep(1)
  }

  resetCurrentStep(): ReportRequest {
    const dataToUpdate: Partial<ReportRequest> = {}
    const currentStep = this.#flow[this.#getCurrentStepIndex()]

    if (currentStep === ReportRequestStep.SELECT_TYPE) {
      dataToUpdate.type = null
    }

    if (
      currentStep === ReportRequestStep.SELECT_CUSTOM_TIME_PERIOD ||
      currentStep === ReportRequestStep.SELECT_TIME_PERIOD
    ) {
      dataToUpdate.date_to = null
      dataToUpdate.date_from = null
      dataToUpdate.useCustomTimePeriod = true
    }

    if (currentStep === ReportRequestStep.SELECT_PORTFOLIOS) {
      dataToUpdate.portfolio_ids = null
    }

    if (currentStep === ReportRequestStep.SELECT_FILE_FORMAT) {
      dataToUpdate.format = null
    }

    return new ReportRequest({ ...this, ...dataToUpdate }, this.#reportTypes)
  }

  fillType(type: string): ReportRequest {
    return new ReportRequest(
      {
        ...this,
        type,
        step: this.getNextStep(),
      },
      this.#reportTypes,
    )
  }

  fillTime(custom: ReportRequestTimePeriod.CUSTOM): ReportRequest
  fillTime(dates: [dateFrom: Date, dateTo: Date]): ReportRequest
  fillTime(argument: ReportRequestTimePeriod.CUSTOM | [dateFrom: Date, dateTo: Date]): ReportRequest {
    if (argument === ReportRequestTimePeriod.CUSTOM) {
      return new ReportRequest(
        {
          ...this,
          useCustomTimePeriod: true,
          step: this.getNextStep(),
        },
        this.#reportTypes,
      )
    }

    if (typeof argument === typeof []) {
      const [dateFrom, dateTo] = argument
      const reportRequest =
        this.step === ReportRequestStep.SELECT_TIME_PERIOD
          ? new ReportRequest(
              {
                ...this,
                step: this.step,
                useCustomTimePeriod: false,
              },
              this.#reportTypes,
            )
          : this

      return new ReportRequest(
        {
          ...reportRequest,
          date_from: dateFrom,
          date_to: dateTo,
          step: reportRequest.getNextStep(),
        },
        this.#reportTypes,
      )
    }

    throw new Error('Bad argument passed into `ReportRequest.fillTime`')
  }

  fillPortfolioIds(portfolioIds: number[] | null): ReportRequest {
    return new ReportRequest(
      {
        ...this,
        portfolio_ids: portfolioIds,
        step: this.getNextStep(),
      },
      this.#reportTypes,
    )
  }

  fillFormat(format: ReportFormat): ReportRequest {
    return new ReportRequest(
      {
        ...this,
        format,
        step: this.getNextStep(),
      },
      this.#reportTypes,
    )
  }

  serializeRequest(): ReportRequestDTO | undefined {
    const [dateFrom, dateTo] = [this.date_from, this.date_to].map(formatDateToBackend)

    if (this.type && this.format && dateFrom && dateTo) {
      const currentReportType = this.#reportTypes.find((reportType) => reportType.type === this.type)

      const request: ReportRequestDTO = {
        type: this.type,
        format: this.format,
        date_from: dateFrom,
        date_to: dateTo,
      }

      if (this.portfolio_ids && currentReportType?.is_one_portfolio_allowed) {
        request.portfolio_ids = this.portfolio_ids
      }

      return request
    }
  }
}

export { ReportRequest, ReportRequestStep, ReportRequestTimePeriod, fullReportFlow }
