import React, { type ReactElement } from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries, useMemo } from 'hooks'

import { format as formatDate } from 'helpers/date.js'
import { format as formatMoney } from 'helpers/money'
import { palette } from 'helpers/palette'

import {
  type PortfolioPointOfInterestList,
  type PortfolioSecurityPointOfInterestList,
} from 'app/effector/pointsOfInterest/models'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Hr } from 'components/atoms/Hr'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import {
  Chart,
  ChartTooltip,
  IncomeLine,
  type IncomeLineDataItem,
  NumeralAxis,
  CurrentLine,
  type SecuritySlicePointOfInterestCardProps,
  type PortfolioPointOfInterestCardProps,
} from 'components/organisms/charts/parts'

import { Tooltip } from './components/Tooltip'
import { TooltipWithContributions } from './components/TooltipWithContributions'
import { usePriceChart, usePointsOfInterest } from './hooks'

import './PriceChart.css'

const enum PriceChartDashedLines {
  MIN_MAX = 'MIN_MAX',
  CURRENT = 'CURRENT',
}

type PriceChartProps = {
  period: string
  data: IncomeLineDataItem[]
  contributionsData?: IncomeLineDataItem[]
  negative?: boolean
  dashedLines?: PriceChartDashedLines[]
  topContent?: ReactElement
  topContentOffset?: number
  portfolioId?: number
  securityId?: number
  pointsOfInterest?: PortfolioPointOfInterestList | PortfolioSecurityPointOfInterestList
  PointOfInterestCard?: (
    props: SecuritySlicePointOfInterestCardProps | PortfolioPointOfInterestCardProps,
  ) => React.ReactElement | null
  'data-test-id'?: string
}

const PriceChart = ({
  period,
  data,
  contributionsData,
  negative = false,
  dashedLines = [PriceChartDashedLines.MIN_MAX],
  topContent,
  topContentOffset,
  portfolioId,
  securityId,
  pointsOfInterest: pointsOfInterestRaw,
  PointOfInterestCard,
  'data-test-id': dataTestId,
}: PriceChartProps): ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    data: { periodLineData, contributionsPeriodLineData, periodMin, periodMax, withFloat },
    tooltip: { rootRef, lineRef, hoveredIndex, handleEnter, handleLeave },
    firstDate,
    lastDate,
    peakCoordinates,
  } = usePriceChart(period, data, contributionsData)
  const {
    pointsOfInterest,
    activePointOfInterest,
    pointOfInterestCenter,
    pointOfInterestPosition,
    selectPointOfInterest,
  } = usePointsOfInterest(pointsOfInterestRaw, hoveredIndex)

  const formattedHoveredData = useMemo(() => {
    const hoveredValueData: IncomeLineDataItem | undefined = periodLineData[hoveredIndex ?? -1]

    if (!hoveredValueData) {
      return null
    }

    const date = formatDate(hoveredValueData.date, 'DD MMM YYYY')
    const value = formatMoney(hoveredValueData.value, true, true)

    if (contributionsPeriodLineData) {
      let hoveredContributionsData: IncomeLineDataItem | undefined = contributionsPeriodLineData[hoveredIndex ?? -1]

      if (hoveredValueData.date.toString() !== hoveredContributionsData?.date.toString() ?? '') {
        hoveredContributionsData = contributionsPeriodLineData.find(
          ({ date }) => date.toString() === hoveredValueData.date.toString(),
        )
      }

      if (hoveredContributionsData) {
        const contributionsValue = formatMoney(hoveredContributionsData.value, true, true)

        return (
          <TooltipWithContributions
            date={date}
            value={value}
            contributionsValue={contributionsValue}
            valueColor={negative ? palette['status-error'] : palette['secondary-default']}
            newStyle={Boolean(topContent)}
          />
        )
      }
    }

    return <Tooltip date={date} value={value} newStyle={Boolean(topContent)} />
  }, [negative, topContent, periodLineData, contributionsPeriodLineData, hoveredIndex])

  const shouldShowNumeralAxis = periodLineData.length > 1
  const isEmpty = periodLineData.length === 0

  return (
    <div
      ref={rootRef}
      className={classNames('PriceChart', {
        PriceChart_empty: isEmpty,
        PriceChart_tooltipShown: Boolean(formattedHoveredData),
        PriceChart_desktop: desktop,
      })}
      data-tooltip-shown={Boolean(formattedHoveredData)}
      data-negative={negative}
      data-test-id={dataTestId}
    >
      {topContent && (
        <Paper bottom={topContentOffset}>
          <ChartTooltip visible={Boolean(formattedHoveredData)} stub={topContent} pointerTransparent fullSize>
            {formattedHoveredData}
          </ChartTooltip>
        </Paper>
      )}
      <Chart className="PriceChart-Chart" size="large" paddingLeft="no">
        {shouldShowNumeralAxis && dashedLines.includes(PriceChartDashedLines.MIN_MAX) && (
          <NumeralAxis
            className="PriceChart-NumeralAxis"
            min={periodMin}
            max={periodMax}
            quantity={periodMin === periodMax ? 1 : 2}
            format={(value) => formatMoney(value, withFloat)}
            right={{ min: peakCoordinates?.min?.right ?? 0, max: peakCoordinates?.max?.right ?? 0 }}
          />
        )}
        {shouldShowNumeralAxis && dashedLines.includes(PriceChartDashedLines.CURRENT) && (
          <CurrentLine className="PriceChart-CurrentLine" coordinates={peakCoordinates?.current} />
        )}
        {contributionsPeriodLineData && contributionsPeriodLineData.length > 0 && (
          <IncomeLine
            className="PriceChart-IncomeLine"
            min={periodMin}
            max={periodMax}
            data={contributionsPeriodLineData}
            color={palette['content-on-background-primary']}
            gradient={[palette['primary-surface-10'], palette['primary-surface-5']]}
            strokeWidth={1}
            hoveredIndex={hoveredIndex}
            fretless
            smoothness
            data-test-id="contributionsPeriodLine"
          />
        )}
        <IncomeLine
          ref={lineRef}
          className="PriceChart-IncomeLine"
          min={periodMin}
          max={periodMax}
          data={periodLineData}
          color={negative ? palette['status-error'] : palette['secondary-default']}
          hoverable
          hoveredIndex={hoveredIndex}
          onTickEnter={handleEnter}
          onTickLeave={handleLeave}
          pointsOfInterest={pointsOfInterest}
          activePointOfInterest={activePointOfInterest}
          selectPointOfInterest={selectPointOfInterest}
          setPickCoordinates={true}
          fretless
          smoothness
        />
        {PointOfInterestCard && (
          <PointOfInterestCard
            pointOfInterest={activePointOfInterest}
            center={pointOfInterestCenter}
            position={pointOfInterestPosition}
            portfolioId={portfolioId}
            securityId={securityId}
          />
        )}
        {isEmpty && (
          <AllCenter className="PriceChart-Empty" data-test-id="portfolioChartPlaceholder">
            <Paper bottom={desktop ? 12 : 8}>
              <Typography
                align="center"
                size={desktop ? 24 : 18}
                lineHeight="small"
                data-test-id="portfolioChartPlaceholderBalance"
              >
                <Typo>£0</Typo>
              </Typography>
            </Paper>
            <Typography
              align="center"
              size={desktop ? 16 : 14}
              color="minor"
              lineHeight="small"
              data-test-id="portfolioChartPlaceholderText"
            >
              <Typo>Nothing to show just yet</Typo>
            </Typography>
          </AllCenter>
        )}
        {!topContent && (
          <ChartTooltip visible={Boolean(formattedHoveredData)} pointerTransparent>
            {formattedHoveredData}
          </ChartTooltip>
        )}
      </Chart>
      {!isEmpty && firstDate && lastDate && (
        <Paper top={desktop ? 48 : 32}>
          <Hr />
          <Paper top={desktop ? 8 : 4}>
            <ColumnarLayout>
              <Typography
                align="left"
                size={desktop ? 14 : 10}
                color="minor"
                weight={desktop ? 'regular' : 'semibold'}
                lineHeight="small"
                data-test-id="priceChartFirstDate"
              >
                <Typo>{firstDate}</Typo>
              </Typography>
              <Typography
                align="right"
                size={desktop ? 14 : 10}
                color="minor"
                weight={desktop ? 'regular' : 'semibold'}
                lineHeight="small"
                data-test-id="priceChartLastDate"
              >
                <Typo>{lastDate}</Typo>
              </Typography>
            </ColumnarLayout>
          </Paper>
        </Paper>
      )}
    </div>
  )
}

export { PriceChart, type PriceChartProps, PriceChartDashedLines }
