import React from 'react'

import { Typo } from 'components/_old/Typo/Typo'

import { Typography } from 'components/atoms/Typography'

import { useStrapiBonuses } from 'app/pages/Dashboard/PromoBanner/hooks/useStrapiBonuses'

const BonusesText = (): JSX.Element | null => {
  const { bonuses } = useStrapiBonuses()

  if (!bonuses) {
    return (
      <Typography tag="p" align="center">
        <Typo>
          Share your unique link to refer a friend to our Individual or Business account, and we'll give you both a
          randomly selected investment bonus
        </Typo>
      </Typography>
    )
  }

  const {
    referIsaOrGeneralBonusMin,
    referIsaOrGeneralBonusMax,
    referFriendOrBusinessBonusMin,
    referFriendOrBusinessBonusMax,
  } = bonuses

  return (
    <Typography tag="p" align="center">
      <Typo>
        Share your unique link to refer a friend to our Individual or Business account, and we'll give you both a
        randomly selected investment bonus of between {referIsaOrGeneralBonusMin}&nbsp; & &nbsp
        {referIsaOrGeneralBonusMax} or {referFriendOrBusinessBonusMin}&nbsp; & &nbsp; {referFriendOrBusinessBonusMax}{' '}
        respectively
      </Typo>
    </Typography>
  )
}

export { BonusesText }
