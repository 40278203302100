import { useSelector } from 'hooks'

import { palette } from 'helpers/palette/'

import { isClientRegistered } from 'app/redux/selectors'

import { types as clientTypes } from 'constants/client'

const useFinishRegistration = () => {
  const client = useSelector((state) => state.client)
  const clientRegistered = isClientRegistered({ client })

  if (client.type === clientTypes.BUSINESS) {
    return {
      icon: 'checkmark_in_a_circle',
      title: 'Registration complete',
      description:
        'Thank you for applying for an InvestEngine Business Account. Our Support team will be in touch shortly to discuss the next steps.',
      color: palette['status-warning'],
      'data-icon-color': 'yellow',
    }
  }

  if (clientRegistered) {
    return {
      icon: 'checkmark_in_a_circle',
      title: 'Registration complete',
      description: 'All you need to do now is fund your account to start investing!',
      color: palette['status-success'],
      'data-icon-color': 'green',
    }
  }

  return {
    icon: 'time-in-circle-64',
    title: 'We need to check something',
    description:
      'Unfortunately, we weren’t able to automatically activate your account. Our Support team will be in touch with you shortly.',
    'data-icon-color': 'yellow',
  }
}

export { useFinishRegistration }
