import React from 'react'

import { useActions, useEffect, useLoading, useMediaQueries, useSelector, useMemo } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'
import { fetchData as fetchQuestionnaireDataActionCreator } from 'app/redux/actions/questionnaire'
import { getClientAnswersOnQuestions, isLockedGoal, selectGoalTitle } from 'app/redux/selectors'

import { FieldLine } from 'components/_old/atoms/FieldLine'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { FieldLineList } from 'components/_old/molecules/FieldLineList'
import Width from 'components/_old/Width/Width'

import Segment from 'components/atoms/Segment/Segment.jsx'
import { Typography } from 'components/atoms/Typography'

import { SettingsLayout } from '../components/SettingsLayout'

type ManagedPortfolioAnswersProps = {
  params: {
    id: string
  }
}

const ManagedPortfolioAnswers = ({ params }: ManagedPortfolioAnswersProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { isLoading: isPortfoliosLoading, wait: waitForPortfolios } = useLoading(false)
  const { isLoading: isQuestionnaireLoading, wait: waitForQuestionnaire } = useLoading(true)

  const portfolioId = parseInt(params.id, 10)
  const linkToYearlyReview = useMemo(() => urlTo('yearly-review', { goalId: portfolioId }), [portfolioId])

  const portfolios = useSelector((state) => state.portfolios)
  const portfolio = portfolios.list.get(portfolioId)
  const client = useSelector((state) => state.client)
  const clientAnswersOnQuestions = useSelector(getClientAnswersOnQuestions)
  const answers = useMemo(
    () =>
      clientAnswersOnQuestions.reduce(
        (answers, clientAnswersObject) => [...answers, ...(clientAnswersObject?.answers ?? [])],
        [],
      ),
    [clientAnswersOnQuestions],
  )
  const isLocked = useSelector((state) => isLockedGoal(state, portfolioId))
  const title = useSelector((state) => selectGoalTitle(state, portfolioId))

  const [fetchPortfolios, fetchQuestionnaireDataAction] = useActions([
    fetchPortfoliosActionCreator,
    fetchQuestionnaireDataActionCreator,
  ])

  useEffect(() => {
    if (!portfolios.items.length) {
      waitForPortfolios(fetchPortfolios())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (portfolio) {
      waitForQuestionnaire(
        fetchQuestionnaireDataAction({
          clientType: client.type,
          goalRegulatoryType: portfolio.regulatory_type,
          goalPresetType: portfolio.preset_type,
          goalId: portfolio.id,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio])

  useEffect(() => {
    if (!isPortfoliosLoading && !isQuestionnaireLoading && answers.length < 1) {
      goTo(linkToYearlyReview)
    }
  }, [linkToYearlyReview, isQuestionnaireLoading, isPortfoliosLoading, answers.length])

  return (
    <SettingsLayout title={title} back={urlTo('dashboard.user-profile.managed-portfolios')}>
      <Segment mods={{ margin: desktop ? 'double' : 'biggest', noMargin: 'top' }}>
        <FieldLineList>
          {clientAnswersOnQuestions.map(({ question, answers }, index) => (
            <FieldLine key={index} label={question} layout="vertical">
              {answers.join(', ')}
            </FieldLine>
          ))}
        </FieldLineList>
      </Segment>
      <Segment mods={{ margin: desktop ? 'double' : 'biggest', noMargin: desktop ? null : 'bottom' }}>
        <Width size={desktop ? 14 : null}>
          <Button disabled={isLocked} mods={{ size: 'big block' }} data-test-id="retakeButton">
            <Link to={linkToYearlyReview}>Retake</Link>
          </Button>
        </Width>
        {isLocked && (
          <Segment mods={{ noMargin: 'bottom' }}>
            <Typography size={14}>
              You cannot retake the questionnaire while we are still processing your previous portfolio change.
            </Typography>
          </Segment>
        )}
      </Segment>
    </SettingsLayout>
  )
}

export { ManagedPortfolioAnswers }
