import React from 'react'

import { DeletePortfolio } from './DeletePortfolio'

const deletePortfolioRoutes = [
  {
    module: 'delete-portfolio',
    path: 'delete-portfolio/',
    component: function Component(props) {
      return <DeletePortfolio {...props} />
    },
  },
]

export { deletePortfolioRoutes }
