import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { format as formatMoney } from 'helpers/money'

import Headline from 'components/_old/Headline/Headline.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { LatestMarketPriceNote } from 'app/pages/Dashboard/Goals/DIY/SecuritySlice/components'

const AssetSummary = (props) => {
  const { data, item, className, woPrices, showOnlyAmount, ...rest } = props

  return (
    <div className={classNames(className, 'AssetSummary')} {...rest}>
      <Paper right={32} bottom={24}>
        <Headline
          className="AssetSummary-Headline"
          level={3}
          mods={{ text: 'normal', 'no-margin': 'top bottom' }}
          data-test-id="assetSummaryTitle"
        >
          <Typo>{item.title}</Typo>
        </Headline>
        {(item.market || '').length > 0 ? (
          <Typography tag="span" size={14}>
            {item.market}
          </Typography>
        ) : null}
      </Paper>

      {!woPrices && item.value ? (
        <Paper bottom={24}>
          <Typography size={24} weight="semibold">
            <Typo>{formatMoney(item.value, true)}</Typo>
          </Typography>
          <LatestMarketPriceNote date={item.price_dt} />
        </Paper>
      ) : null}
      {item.quantity ? (
        <Paper bottom={24}>
          <Typography size={14}>Quantity: {item.quantity}</Typography>
        </Paper>
      ) : null}
      <Headline level={4} mods={{ 'no-margin': 'top' }}>
        <Typography size={18} weight="semibold">
          Asset summary
        </Typography>
      </Headline>
      <Typography size={14} data-test-id="assetSummaryDescription">
        <Typo multiline>{item.description}</Typo>
      </Typography>
    </div>
  )
}

AssetSummary.propTypes = {
  data: PropTypes.object,
  item: PropTypes.object,
  className: PropTypes.string,
  woPrices: PropTypes.bool,
  showOnlyAmount: PropTypes.bool,
}

export default AssetSummary
