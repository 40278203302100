import React from 'react'

import { useUnit } from 'effector-react'

import { useActions, useMediaQueries, useSelector } from 'hooks'

import { $dictsStore } from 'app/effector/dicts'

import { changeField as changeClientFieldActionCreator } from 'app/redux/actions/client'

import DisableIfNeeded from 'components/_old/DisableIfNeeded/DisableIfNeeded.jsx'
import Form, { Fieldset } from 'components/_old/Form/Form.jsx'
import Input from 'components/_old/Input/Input.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

import { wealthSources as clientWealthSources } from 'constants/client'

type FinancesFormProps = {
  validation: Record<string, { rules: boolean[]; errors: string[] }>
}

const FinancesForm = ({ validation }: FinancesFormProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const client = useSelector((state) => state.client)
  const { dicts } = useUnit($dictsStore)

  const [changeClientField] = useActions([changeClientFieldActionCreator])

  const onClientFieldChange = (field, value): void => {
    changeClientField({ [field]: value })
  }

  const handleWealthSourceChange = (_event, value): void => {
    onClientFieldChange('wealth_source', value)
  }

  const handleOtherWealthSourceChange = (_event, value): void => {
    onClientFieldChange('other_wealth_source', value)
  }

  const handleEmploymentStatusChange = (_event, value): void => {
    onClientFieldChange('employment_status', value)
  }

  const {
    wealth_sources: wealthSources = [],
    employment_statuses: employmentStatuses = [],
    client_lock_rules: fieldLockRules,
  } = dicts

  const {
    wealth_source: wealthSource,
    other_wealth_source: otherWealthSource,
    employment_status: employmentStatus,
  } = client

  const wealthSourceOptions = [
    { value: null, name: 'Choose' },
    ...wealthSources.map((s) => {
      return { value: s.id, name: s.name }
    }),
  ]
  const wealthSourceText = wealthSources.find((ws) => ws.id === wealthSource)?.name

  const employmentStatusOptions = [
    { value: null, name: 'Choose' },
    ...employmentStatuses.map((s) => {
      return { value: s.id, name: s.name }
    }),
  ]
  const employmentStatusText = employmentStatuses.find((es) => es.id === employmentStatus)?.name

  return (
    <Form>
      <Fieldset marginNext>
        <DisableIfNeeded field="wealth_source" rules={fieldLockRules} model={client}>
          {(disabled) => (
            <Validate rules={validation.wealth_source.rules} disabled={disabled}>
              <LabelInlineStyle
                labelText="Source of wealth"
                size="smaller"
                multiline={!desktop}
                errorMessages={validation.wealth_source.errors}
                valueIfDisabled={wealthSourceText}
                disabled={disabled}
                data-test-id="financesWealthSourceLabel"
              >
                <Input
                  type="select"
                  onChange={handleWealthSourceChange}
                  options={wealthSourceOptions}
                  tabIndex={2}
                  data-test-id="financesWealthSourceInput"
                >
                  {wealthSource}
                </Input>
              </LabelInlineStyle>
            </Validate>
          )}
        </DisableIfNeeded>
        {wealthSource === clientWealthSources.OTHER && (
          <DisableIfNeeded field="other_wealth_source" rules={fieldLockRules} model={client}>
            {(disabled) => (
              <Validate
                skipWaitBlur={otherWealthSource?.length > 0}
                rules={validation.other_wealth_source.rules}
                disabled={disabled}
              >
                <LabelInlineStyle
                  labelText="Other wealth source"
                  size="smaller"
                  multiline={!desktop}
                  errorMessages={validation.other_wealth_source.errors}
                  valueIfDisabled={otherWealthSource}
                  disabled={disabled}
                  data-test-id="financesFormOtherWealthSourceInput"
                >
                  <Input
                    type="text"
                    onChange={handleOtherWealthSourceChange}
                    tabIndex={2}
                    data-test-id="financesFormOtherWealthSourceInput"
                  >
                    {otherWealthSource}
                  </Input>
                </LabelInlineStyle>
              </Validate>
            )}
          </DisableIfNeeded>
        )}
        {wealthSource === clientWealthSources.GIFT && (
          <DisableIfNeeded field="other_wealth_source" rules={fieldLockRules} model={client}>
            {(disabled) => (
              <Validate
                skipWaitBlur={otherWealthSource?.length > 0}
                rules={validation.gift_origin.rules}
                disabled={disabled}
              >
                <LabelInlineStyle
                  labelText="Gift origin"
                  size="smaller"
                  multiline={!desktop}
                  errorMessages={validation.gift_origin.errors}
                  valueIfDisabled={otherWealthSource}
                  disabled={disabled}
                  data-test-id="financesFormGiftOriginLabel"
                >
                  <Input
                    type="text"
                    onChange={handleOtherWealthSourceChange}
                    tabIndex={2}
                    data-test-id="financesFormGiftOriginInput"
                  >
                    {otherWealthSource}
                  </Input>
                </LabelInlineStyle>
              </Validate>
            )}
          </DisableIfNeeded>
        )}
        <DisableIfNeeded field="employment_status" rules={fieldLockRules} model={client}>
          {(disabled) => (
            <Validate rules={validation.employment_status.rules} disabled={disabled}>
              <LabelInlineStyle
                labelText="Employment status"
                size="smaller"
                multiline={!desktop}
                errorMessages={validation.employment_status.errors}
                valueIfDisabled={employmentStatusText}
                disabled={disabled}
                data-test-id="financesEmploymentStatusLabel"
              >
                <Input
                  type="select"
                  onChange={handleEmploymentStatusChange}
                  options={employmentStatusOptions}
                  tabIndex={3}
                  data-test-id="financesEmploymentStatusInput"
                >
                  {employmentStatus}
                </Input>
              </LabelInlineStyle>
            </Validate>
          )}
        </DisableIfNeeded>
      </Fieldset>
    </Form>
  )
}

export { FinancesForm }
