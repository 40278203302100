import React from 'react'

import { useMediaQueries } from 'hooks'

import { Hints } from 'components/organisms/Hints/Hints'

import { useDashboardHints } from './useDashboadHints'

type DashboardHintsProps = {
  cashPortfolioRef: React.RefObject<Element>
  addPortfolioRef: React.RefObject<Element>
  addIsaRef: React.RefObject<Element>
  addSippRef: React.RefObject<Element>
  isaCashPortfolioRef: React.RefObject<Element>
  hasOpenedIsa: boolean
  hasPortfolios: boolean
  hasPersonalPortfolios: boolean
  isBusiness: boolean
}

const DashboardHints = ({
  cashPortfolioRef,
  isaCashPortfolioRef,
  addPortfolioRef,
  addIsaRef,
  addSippRef,
  hasOpenedIsa,
  hasPersonalPortfolios,
  isBusiness,
  hasPortfolios,
}: DashboardHintsProps): React.ReactElement | null => {
  const { desktop } = useMediaQueries()

  const { hints } = useDashboardHints({
    cashPortfolioRef,
    isaCashPortfolioRef,
    addPortfolioRef,
    addIsaRef,
    addSippRef,
    hasOpenedIsa,
    hasPersonalPortfolios,
    isBusiness,
    hasPortfolios,
  })

  return <Hints nameKey="dashboard" hints={hints} forceArrowHorizontalAlign={desktop ? undefined : 'left'} />
}

export { DashboardHints }
