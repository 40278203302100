import React, { Fragment } from 'react'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'

import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { CreateReportForm } from '../../components/CreateReportForm'
import { ReportsHeader, ReportHeaderTabs } from '../../components/ReportsHeader'
import { type CreateReportChildProps } from '../CreateReport'

const Desktop = (props: CreateReportChildProps): React.ReactElement => (
  <DesktopLayout
    header={<DesktopHeader />}
    content={
      <Fragment>
        <Inner mods={{ height: 'full' }}>
          <Paper top={40}>
            <ReportsHeader activeTabName={ReportHeaderTabs.CREATE_REPORT} />
          </Paper>
          <Paper top={120} bottom={120} flex>
            <AllCenter>
              <Paper bottom={64}>
                <CreateReportForm {...props} />
              </Paper>
            </AllCenter>
          </Paper>
        </Inner>
      </Fragment>
    }
    footer={<DesktopFooter />}
    noGaps
    fullSize
  />
)

export { Desktop }
