import React from 'react'

import { useMemo } from 'hooks'

import { DesktopHeader } from 'app/containers/Header'
import { DesktopFooter } from 'app/containers/Footer'

import { DesktopLayout } from 'components/atoms/Layouts'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import Inner from 'components/_old/Inner/Inner.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

const Empty = () => {
  const headerNode = useMemo(() => <DesktopHeader />, [])

  const contentNode = useMemo(
    () => (
      <Inner flex>
        <AllCenter>
          <Typography
            size={16}
            lineHeight="small"
            align="center"
            color="minor"
            data-test-id="dashboardAnalyticsNoAnalytics"
          >
            <Typo>Nothing to show just yet</Typo>
          </Typography>
        </AllCenter>
      </Inner>
    ),
    [],
  )

  return <DesktopLayout header={headerNode} content={contentNode} footer={<DesktopFooter />} />
}

export { Empty }
