import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries, useClickOutside } from 'hooks'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import './Tooltip.css'

const aligns = ['left', 'center']

const Tooltip = ({
  children,
  className,
  tipClassName,
  content,
  align = 'left',
  offset,
  'data-test-id': dataTestId,
}) => {
  const mediaQueries = useMediaQueries()
  const [visible, setVisible] = useState(false)
  const [rootRef, hasClickedOutside] = useClickOutside()

  const handleToggle = useCallback(
    (event) => {
      setVisible(!visible)
      event.preventDefault()
      event.stopPropagation()
    },
    [visible],
  )

  const handleClose = useCallback((event) => {
    setVisible(false)
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }, [])

  useEffect(() => {
    if (hasClickedOutside) {
      handleClose()
    }
  }, [hasClickedOutside, handleClose])

  const tipClasses = classNames('Tooltip-Tip', tipClassName, {
    [`Tooltip-Tip_align_${align}`]: align,
  })

  const offsetStyle = offset ? { left: `${offset}px` } : null

  return (
    <span
      className={classNames(className, 'Tooltip', getMediaQuieryClasses('Tooltip', mediaQueries))}
      ref={rootRef}
      data-test-id={dataTestId}
    >
      <span className={classNames('Tooltip-Subject', visible && 'Tooltip-Pin')} onClick={handleToggle}>
        {children}
      </span>
      {visible && (
        <div className={tipClasses} style={offsetStyle}>
          <div className="Tooltip-TipInner">
            <Link className="Tooltip-Close" onClick={handleClose}>
              <Icon type="cross-thin" color="gainsboro" size={48} />
            </Link>
            {content}
          </div>
        </div>
      )}
    </span>
  )
}

Tooltip.propTypes = {
  children: PropTypes.element,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  tipClassName: PropTypes.string,
  align: PropTypes.oneOf(aligns),
  offset: PropTypes.number,
  'data-test-id': PropTypes.string,
}

export default Tooltip
