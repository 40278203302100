import { useEffect } from 'react'

import { goTo, urlTo } from 'helpers/router.js'

const useRedirect = ({ to, rule, isLoading }) => {
  useEffect(() => {
    if (!isLoading && rule) {
      if (typeof to === 'function') {
        to()
      } else {
        goTo(urlTo(to))
      }
    }
  }, [to, rule, isLoading])
}

export default useRedirect
