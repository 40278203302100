import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import CSSTransitionGroup from 'react-addons-css-transition-group'

import { palettePlainValues } from 'helpers/palette'

import { useState, useEffect, useTimeoutFn, useCallback } from 'hooks'

import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'

import icon from './Preloader.svg'
import './Preloader.css'

const Preloader = ({
  className,
  loading,
  delay,
  zIndex,
  background = 'default',
  size = 'default',
  absolute,
  opaque,
  'data-test-id': dataTestId = 'preloader',
}) => {
  // fallback
  if (background === 'gainsboro') {
    background = 'default'
  }
  if (background === 'white') {
    background = 'background'
  }

  const [visible, setVisible] = useState(!delay)
  const [, cancel, reset] = useTimeoutFn(() => setVisible(true), delay)

  const handleDelay = useCallback(() => {
    if (!delay) {
      return
    }

    if (loading) {
      return reset()
    }

    cancel()
    setVisible(false)
  }, [loading, delay])

  useEffect(handleDelay, [loading, delay])

  const getStyle = () => {
    const side = (() => {
      const icons = {
        big: '6rem',
        zero: '0rem',
        default: '4rem',
      }

      return icons[size]
    })()

    return {
      width: side,
      height: side,
      top: `-${side}`,
      right: `-${side}`,
      bottom: `-${side}`,
      left: `-${side}`,
    }
  }

  const classes = classNames(className, 'Preloader', [`Preloader_background_${background}`], {
    Preloader_zIndex: zIndex,
    Preloader_absolute: absolute,
    Preloader_opaque: opaque,
  })

  return (
    <CSSTransitionGroup
      className="Preloader-Holder"
      transitionName={{
        enter: 'Preloader_enter',
        enterActive: 'Preloader_enter_active',
        leave: 'Preloader_leave',
        leaveActive: 'Preloader_leave_active',
      }}
      transitionEnterTimeout={palettePlainValues.animation.speed.number.default}
      transitionLeaveTimeout={palettePlainValues.animation.speed.number.default}
    >
      {visible && loading ? (
        <div className={classes} data-test-id={dataTestId}>
          <SvgIcon className="Preloader-Icon" src={icon} style={getStyle()} fluid />
        </div>
      ) : null}
    </CSSTransitionGroup>
  )
}

Preloader.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  delay: PropTypes.number,
  zIndex: PropTypes.bool,
  background: PropTypes.oneOf(['background', 'default']),
  size: PropTypes.oneOf(['big', 'zero', 'default']),
  absolute: PropTypes.bool,
  opaque: PropTypes.bool,
  'data-test-id': PropTypes.string,
}

export { Preloader }
