import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'

import { isDarkTheme } from 'helpers/palette'
import { format, formatPercent } from 'helpers/money'
import rawMediaQueries from 'helpers/mediaQueries.js'

import withMediaQueries from 'decorators/withMediaQueries/withMediaQueries.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Text from 'components/_old/Text/Text.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import SegmentedControl from 'components/_old/SegmentedControl/SegmentedControl.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { features } from 'helpers/features'

export const TYPE_MONEY = 'MONEY'
export const TYPE_PERCENT = 'PERCENT'

export const types = [TYPE_MONEY, TYPE_PERCENT]
export const DATA_TAG_NAME = 'data-tooltip-tab-id'

@withMediaQueries(rawMediaQueries)
export default class ProjectionsTooltip extends React.Component {
  render() {
    const { series, date, tabId = TYPE_MONEY, mediaQueries } = this.props
    const { desktop } = mediaQueries

    const calculatePercent = (value, contributionValue) => {
      return (value - contributionValue) / contributionValue
    }

    const tabIndex = types.findIndex((type) => type === tabId)

    const getMedian = (data, i) => {
      const { label, color, value, chunk } = data

      return label && color && value ? (
        <div className="ProjectionsChart-Label" key={i}>
          <div
            className={classNames('ProjectionsChart-TooltipColored', {
              'ProjectionsChart-TooltipColored_chunk': chunk,
            })}
            style={{ [chunk ? 'backgroundColor' : 'borderColor']: color }}
          />
          <Text className="ProjectionsChart-TooltipName" muted smaller data-test-id="projectionsTooltipDataLabel">
            {label}
          </Text>
          <Text className="ProjectionsChart-TooltipValue" smaller data-test-id="projectionsTooltipDataValue">
            {value}
          </Text>
        </div>
      ) : null
    }

    const getPoint = (data, i) =>
      data ? (
        <div
          className={classNames('ProjectionsChart-Label', {
            [`ProjectionsChart-Label_${data.key.toLowerCase()}`]: data.key,
            'ProjectionsChart-Label_desktop': desktop,
          })}
          key={i}
        >
          <div
            className="ProjectionsChart-TooltipColored"
            style={{
              [desktop ? 'backgroundColor' : 'borderColor']: data.color,
            }}
          />
          <Text className="ProjectionsChart-TooltipName" muted smaller>
            {capitalize(data.key)}
          </Text>
          <Text className="ProjectionsChart-TooltipValue" smaller>
            {format(data.value)}
          </Text>
        </div>
      ) : null

    const getMedianData = (type) => {
      let data = [
        get(series, '[5].value', null),
        get(series, '[25].value', null),
        get(series, '[5].value', null),
        get(series, '[75].value', null),
        get(series, '[25].value', null),
        get(series, '[95].value', null),
        get(series, '[75].value', null),
        get(series, '[95].value', null),
      ]

      data = data.map((value) => {
        if (value === null) {
          return null
        }
        if (type === TYPE_PERCENT) {
          return formatPercent(calculatePercent(value, series.contributions.value), false, true)
        }
        return format(value)
      })

      const [exceptional, goodFrom, goodTo, exceptedFrom, exceptedTo, poorFrom, poorTo, worstCase] = data

      const separator = 'to'

      return [
        {
          label: 'Exceptional',
          color: isDarkTheme() ? '#4786c3' : '#8fafdf',
          chunk: false,
          value: exceptional ? <span>over {exceptional}</span> : null,
        },
        {
          label: 'Good',
          color: isDarkTheme() ? '#8eb9e4' : '#cfe2fd',
          chunk: true,
          value:
            goodFrom && goodTo ? (
              <span>
                {goodFrom}
                &#x2008;{separator}&#x2008;
                {goodTo}
              </span>
            ) : null,
        },
        {
          label: 'Expected',
          color: isDarkTheme() ? '#cfffc1' : '#d9ffc2',
          chunk: true,
          value:
            exceptedFrom && exceptedTo ? (
              <span>
                {exceptedFrom}
                &#x2008;{separator}&#x2008;
                {exceptedTo}
              </span>
            ) : null,
        },
        {
          label: 'Poor',
          color: isDarkTheme() ? '#b5b6b5' : '#e7e6e6',
          chunk: true,
          value:
            poorFrom && poorTo ? (
              <span>
                {poorFrom}
                &#x2008;{separator}&#x2008;
                {poorTo}
              </span>
            ) : null,
        },
        {
          label: 'Worst case',
          color: isDarkTheme() ? '#8d8d8d' : '#c0c0c0',
          chunk: false,
          value: worstCase ? <span>under {worstCase}</span> : null,
        },
      ]
    }

    return (
      <Card
        className={classNames('ProjectionsChart-Tooltip', {
          'ProjectionsChart-Tooltip_updated': !desktop,
        })}
        mods={{ theme: 'white straight-corners', padding: 'small' }}
      >
        <div className="ProjectionsChart-TooltipGroup">
          <div className="ProjectionsChart-Label">
            <ColumnarLayout mods={{ 'align-columns': 'center' }} fluid>
              <Column size={2}>
                <Text>
                  <Headline level={5} mods={{ 'no-margin': 'top bottom', text: 'normal' }}>
                    {date}
                  </Headline>
                </Text>
              </Column>
              <Column size={1}>
                <SegmentedControl
                  className="ProjectionsChart-Tabs"
                  mods={{ theme: 'tabs-light', size: 'smaller' }}
                  fluid
                  activeIndex={tabIndex}
                >
                  <Link {...{ [DATA_TAG_NAME]: TYPE_MONEY }} data-test-id={`projectionsTooltipTabLink${TYPE_MONEY}`}>
                    £
                  </Link>
                  <Link
                    {...{ [DATA_TAG_NAME]: TYPE_PERCENT }}
                    data-test-id={`projectionsTooltipTabLink${TYPE_PERCENT}`}
                  >
                    %
                  </Link>
                </SegmentedControl>
              </Column>
            </ColumnarLayout>
          </div>
        </div>

        <div
          className="ProjectionsChart-TooltipGroup"
          {...{ [DATA_TAG_NAME]: TYPE_MONEY }}
          style={tabId === TYPE_MONEY ? {} : { overflow: 'hidden', height: 0, margin: 0 }}
          aria-selected={tabId === TYPE_MONEY}
          role="tab"
        >
          {getMedianData(TYPE_MONEY).map(getMedian)}
        </div>
        <div
          className="ProjectionsChart-TooltipGroup"
          {...{ [DATA_TAG_NAME]: TYPE_PERCENT }}
          style={tabId === TYPE_PERCENT ? {} : { overflow: 'hidden', height: 0, margin: 0 }}
          aria-selected={tabId === TYPE_PERCENT}
          role="tab"
        >
          {getMedianData(TYPE_PERCENT).map(getMedian)}
        </div>
        <div className="ProjectionsChart-TooltipGroup">{[series.contributions, series.target].map(getPoint)}</div>
      </Card>
    )
  }
}

ProjectionsTooltip.propTypes = {
  series: PropTypes.object,
  date: PropTypes.string,
  tabId: PropTypes.oneOf([TYPE_MONEY, TYPE_PERCENT]),
  mediaQueries: PropTypes.object,
}
