import { ListOf } from 'app/redux/models/common/ListOf'

import { Security } from './Security.js'

/**
 * @class
 * @typedef {Array.<Security>} SecurityList
 */
export class SecurityList extends ListOf(Security) {
  /**
   * @param {number} id
   * @return {?Security}
   */
  get(id) {
    return this.find((security) => security.id === id) ?? null
  }

  /**
   * @param {Security} security
   * @return {SecurityList}
   */
  add(security) {
    const newSecurities = new SecurityList(...this)
    newSecurities.push(security)

    return newSecurities
  }

  /**
   * @param {Security} security
   * @return {SecurityList}
   */
  replace(security) {
    const newSecurities = new SecurityList(...this)
    const currentSecurityIndex = newSecurities.findIndex((collectionSecurity) => collectionSecurity.id === security.id)

    if (currentSecurityIndex < 0) {
      return newSecurities
    }

    newSecurities[currentSecurityIndex] = security

    return newSecurities
  }

  /**
   * @param {Security} security
   * @return {SecurityList}
   */
  replaceOrAdd(security) {
    if (this.findIndex((collectionSecurity) => collectionSecurity.id === security.id) > -1) {
      return this.replace(security)
    }

    return this.add(security)
  }

  /**
   * @param {SecurityList} securitiesListToMerge
   * @return {SecurityList}
   */
  merge(securitiesListToMerge) {
    if (this.length > 0) {
      let newSecurities = new SecurityList(...securitiesListToMerge)

      const toMergeIds = [...securitiesListToMerge].map((security) => security.id)
      const intersectionIds = [...this]
        .filter((security) => toMergeIds.includes(security.id))
        .map((security) => security.id)

      intersectionIds.forEach((securityId) => {
        newSecurities = newSecurities.replaceOrAdd(this.get(securityId).merge(securitiesListToMerge.get(securityId)))
      })

      return newSecurities
    }

    return new SecurityList(...securitiesListToMerge)
  }

  /**
   * @return {SecurityList}
   */
  getVisibleInUniverse() {
    return this.filter((security) => security.is_visible_in_universe)
  }
}
