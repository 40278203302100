import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

import { type RecurringPaymentStore } from '..'

const getRecurringSubscriptions = async (): Promise<RecurringPaymentStore | ApiError> => {
  try {
    const { data } = await axios.get('/recurring-payments/')

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { getRecurringSubscriptions }
