import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'

const ErrorMessage = ({ error, onTop }) => {
  const { desktop } = useMediaQueries()
  const errorClasses = classnames('_newTextQuestion-ErrorItem', { '_newTextQuestion-ErrorItem_top': onTop })

  return (
    <Typography
      className={errorClasses}
      align="center"
      color="error"
      size={desktop ? 24 : 16}
      lineHeight="small"
      data-test-id="tunnelStepGetStartedError"
    >
      <Typo>{error}</Typo>
    </Typography>
  )
}

ErrorMessage.propTypes = {
  onTop: PropTypes.bool,
  error: PropTypes.string,
}

export default ErrorMessage
