import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { useUnit } from 'effector-react'

import compose from 'helpers/compose.js'
import { goTo, urlTo } from 'helpers/router.js'
import { format as formatMoney } from 'helpers/money'

import { useCallback, useContext } from 'hooks'

import { $dictsStore } from 'app/effector/dicts'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'

import Modal from 'components/_old/Modal/Modal.jsx'
import ModalContent, {
  ModalContentHeadline,
  ModalContentBody,
  ModalContentButtons,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import { Typo } from 'components/_old/Typo/Typo'

const CantSetUpMonthlyPayment = ({ routes, goalId }) => {
  const { tunnelQuery } = useContext(ChangeGoalTunnelContext)
  const { initialDepositMin } = useUnit($dictsStore)
  const initialDepositMinString = formatMoney(initialDepositMin)

  const currentRouteModule = routes[routes.length - 1].module
  const isOpen = currentRouteModule === 'cant-set-up-monthly-payment'

  const handleClose = useCallback(
    () => goTo(urlTo(`dashboard.portfolio`, { id: goalId }, tunnelQuery), { replace: true, scrollToTop: false }),
    [goalId, tunnelQuery],
  )

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      data-test-id="changeGoalTitleModal"
      gatewayName="cant-set-up-monthly-payment"
    >
      <ModalContent>
        <ModalContentHeadline>Monthly payments</ModalContentHeadline>
        <ModalContentBody>
          <Typo>
            To set up monthly funding of your account, you first need to pay in the initial minimum of{' '}
            {initialDepositMinString}.
            <br />
            <br />
            InvestEngine accounts have a minimum initial investment of {initialDepositMinString}, after which you have
            the flexibility to add regular or one-off amounts.
          </Typo>
        </ModalContentBody>
        <ModalContentButtons text="OK" onClick={handleClose} />
      </ModalContent>
    </Modal>
  )
}

CantSetUpMonthlyPayment.propTypes = {
  routes: PropTypes.array,
  goalId: PropTypes.number,
}

export default compose(withRouter)(CantSetUpMonthlyPayment)
