import React from 'react'

import { palette } from 'helpers/palette/'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import './CreateAccount.css'

type CreateAccountRequirementsProps = {
  isBusiness: boolean
}

const REQUIREMENTS = {
  business: [
    'The business must be registered and up to date on UK Companies House',
    'Directors must be at least 18 years old',
    'Directors cannot be US tax residents',
  ],
  personal: ['18+', 'UK resident', 'UK tax resident (to open an ISA account)'],
}

const CreateAccountRequirements = ({ isBusiness = false }: CreateAccountRequirementsProps): React.ReactElement => {
  return (
    <Paper top={20} bottom={20} left={20} right={20} className="CreateAccount-RequirementsContainer">
      <Stack vertical={8}>
        <Typography size={14}>{isBusiness ? 'Business requirements' : 'You must be'}:</Typography>
        {REQUIREMENTS[isBusiness ? 'business' : 'personal'].map((requirement) => (
          <ItemWithIcon
            key={requirement}
            space={8}
            icon={
              <Icon
                type="check-16"
                color={palette['status-success']}
                size={16}
                className="CreateAccount-CheckIcon"
                inline
              />
            }
            iconVerticalAlign="top"
            contentVerticalAlign="top"
            content={
              <Typography size={14} lineHeight="small" align="left">
                <Typo>{requirement}</Typo>
              </Typography>
            }
            data-test-id="requirementPoint"
          />
        ))}
      </Stack>
    </Paper>
  )
}

export { CreateAccountRequirements }
