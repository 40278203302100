import React, { Fragment } from 'react'

import { formatPercent } from 'helpers/money'

import { Paper } from 'components/atoms/Paper'
import { PercentageScale } from 'components/atoms/PercentageScale'
import { Typography } from 'components/atoms/Typography'

type ScaleItem = {
  percentage: number
  color: string
  text: string
}

type PercentageScaleWithLegendProps = {
  items: ScaleItem[]
  height?: number
}

const PercentageScaleWithLegend = ({ items, height }: PercentageScaleWithLegendProps): React.ReactElement => {
  return (
    <Fragment>
      <Paper bottom={20}>
        {items.map((item, index) => (
          <Paper key={item.text} inline right={index === items.length - 1 ? 0 : 24}>
            <Paper inline right={8}>
              <Typography size={24} lineHeight="small" weight="semibold" style={{ color: item.color }}>
                {formatPercent(item.percentage / 100, 1)}
              </Typography>
            </Paper>
            <Typography tag="span" size={14} data-test-id="chartDescription">
              {item.text}
            </Typography>
          </Paper>
        ))}
      </Paper>
      <PercentageScale items={items} height={height} />
    </Fragment>
  )
}

export { PercentageScaleWithLegend }
