import React, { type ReactElement } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import './TooltipWithContributions.css'

type TooltipWithContributionsLegendLineProps = {
  color: string
}

const TooltipWithContributionsLegendLine = ({
  color = palette['content-on-background-primary'],
}: TooltipWithContributionsLegendLineProps): ReactElement => (
  <div className="TooltipWithContributions-Legend" style={{ background: color }} />
)

type TooltipWithContributionsProps = {
  date: string
  value: string
  contributionsValue: string
  valueColor?: string
  newStyle?: boolean
}

const TooltipWithContributions = ({
  date,
  value,
  contributionsValue,
  valueColor = palette['secondary-default'],
  newStyle = false,
}: TooltipWithContributionsProps): ReactElement => {
  const { desktop } = useMediaQueries()

  const valueCollections: Array<[string, number, string, string]> = useMemo(
    () => [
      [value, 0, 'Value', valueColor],
      [contributionsValue, 4, 'Net contributions', palette['content-on-background-primary']],
    ],
    [value, valueColor, contributionsValue],
  )

  if (newStyle) {
    return (
      <div className="TooltipWithContributions TooltipWithContributions_newStyle">
        <Stack className="TooltipWithContributions-Lines" vertical={desktop ? 8 : 4}>
          {valueCollections.map(([collectionValue, _, collectionName, collectionColor]) => (
            <ItemWithIcon
              key={collectionName}
              iconPosition="left"
              space={12}
              icon={<TooltipWithContributionsLegendLine color={collectionColor} />}
              content={
                <Nobr className="TooltipWithContributions-LineContent">
                  <Typography className="TooltipWithContributions-Name" size={desktop ? 16 : 14} lineHeight="small">
                    <Typo>{collectionName} </Typo>
                  </Typography>
                  <Paper left={desktop ? 40 : 20}>
                    <Typography size={desktop ? 16 : 14} weight="semibold">
                      {collectionValue}
                    </Typography>
                  </Paper>
                </Nobr>
              }
              iconVerticalAlign="center"
              contentVerticalAlign="center"
            />
          ))}
        </Stack>
        <Paper top={desktop ? 20 : 12}>
          <Typography size={14} lineHeight="small" color="minor" align="center">
            <Typo>
              <Nobr>{date}</Nobr>
            </Typo>
          </Typography>
        </Paper>
      </div>
    )
  }

  return (
    <Paper className="TooltipWithContributions" left={4} right={4}>
      <Typography size={14} lineHeight="small" color="minor">
        <Typo>
          <Nobr>{date}</Nobr>
        </Typo>
      </Typography>
      {valueCollections.map(([collectionValue, collectionTopSpacing, collectionName, collectionColor]) => (
        <Paper key={collectionName} top={collectionTopSpacing}>
          <ItemWithIcon
            iconPosition="left"
            space={8}
            icon={<TooltipWithContributionsLegendLine color={collectionColor} />}
            content={
              <Nobr>
                <Typography size={16} lineHeight="small">
                  <Typo>
                    {collectionName}{' '}
                    <Typography tag="span" size={16} weight="semibold" inline>
                      {collectionValue}
                    </Typography>
                  </Typo>
                </Typography>
              </Nobr>
            }
            iconVerticalAlign="center"
            contentVerticalAlign="center"
          />
        </Paper>
      ))}
    </Paper>
  )
}

export { TooltipWithContributions }
