import React from 'react'

import { useCallback, useMediaQueries } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import './AutoinvestInfo.css'

type AutoinvestInfoProps = {
  portfolio: Portfolio
  back: string
}

const AutoinvestInfo = ({ portfolio, back }: AutoinvestInfoProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const isAutoinvestActive = portfolio?.is_autoinvest_enabled

  const handleAutoinvestClick = useCallback(() => {
    goTo(urlTo('dashboard.portfolio.autoinvest', { id: portfolio?.id }, { back }))
  }, [portfolio?.id, back])

  return (
    <Width size={24} center>
      <AllCenter>
        <SelectableCard
          className="AutoinvestInfo_Card"
          onClick={handleAutoinvestClick}
          data-test-id="autoinvestInfoCard"
        >
          <Paper top={12} bottom={12} right={12} left={12}>
            <Typography size={14} lineHeight="small" align="center" color="additional">
              <Typo>AutoInvest</Typo>
              <Paper left={4} inline>
                <Typography color={isAutoinvestActive ? 'secondary_default' : 'minor'}>
                  <Typo>{isAutoinvestActive ? 'ON' : 'OFF'}</Typo>
                </Typography>
              </Paper>
            </Typography>
          </Paper>
        </SelectableCard>
      </AllCenter>
      <Paper top={24}>
        <Typography size={desktop ? 16 : 14} align="center">
          {isAutoinvestActive ? (
            <Typo>
              Any funds added to your portfolio will be{' '}
              <Link to="/terms#autoinvest" blank hard>
                automatically invested
              </Link>{' '}
              in the next trading window
            </Typo>
          ) : (
            <Typo>Any funds added to your portfolio will not be automatically invested</Typo>
          )}
        </Typography>
      </Paper>
    </Width>
  )
}

export { AutoinvestInfo }
