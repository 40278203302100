import { useStoreMap, useUnit } from 'effector-react'

import { useActions, useEffect, useLoading, useMemo, useSelector } from 'hooks'

import { getRandomInteger } from 'helpers/number'
import { countDaysBeforePayment } from 'helpers/recurringPayments'

import { $portfolioPointOfInterests } from 'app/effector/pointsOfInterest'
import { $portfolioSecuritiesStore } from 'app/effector/portfolio-securities'

import { fetchDIYHistoryByPortfolio as fetchDIYHistoryByPortfolioActionCreator } from 'app/redux/actions/portfolios'

import { useCommonPortfolio } from 'app/pages/Dashboard/Goals/hooks/useCommonPortfolio'
import { frequencies } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'
import { useSecurities } from 'app/pages/Securities/hooks'

import { quickStartStatuses } from 'constants/quickStart'

const randomSecuritySkeletonNumber = getRandomInteger(4, 7)

/**
 * @param {number} portfolioId
 * @param {PendingOrderList} orders
 * @return {{ isPotfolioLoading: boolean, isSecuritiesLoading: boolean, isHistoryLoading: boolean, portfolio, messages, securities, chartData: {date: *, value: *}[]}}
 */
const usePortfolioData = ({ portfolioId, orders }) => {
  const { portfolio, savingsPlan, quickStart } = useCommonPortfolio({ portfolioId })
  const { isLoading: isSecuritiesLoading } = useSecurities({ portfolioId })
  const { wait: waitForHistory, isLoading: isHistoryLoading } = useLoading(true)

  const [fetchDIYHistoryByPortfolio] = useActions([fetchDIYHistoryByPortfolioActionCreator])

  const historyPerformance = useSelector(
    (state) => state.portfolios.items.find((goal) => goal.id === portfolioId)?.history_performance,
  )
  const historyContributions = useSelector(
    (state) => state.portfolios.items.find((goal) => goal.id === portfolioId)?.history_contributions,
  )

  const historyDividends = useSelector(
    (state) => state.portfolios.items.find((goal) => goal.id === portfolioId)?.dividends,
  )
  const pointsOfInterest = useStoreMap($portfolioPointOfInterests, (state) => state.get(portfolioId))

  const { selectSecuritiesInPortfolioDetailed } = useUnit($portfolioSecuritiesStore)

  const detailedPortfolioSecurities = selectSecuritiesInPortfolioDetailed(portfolioId, false)

  const daysBeforePayment = countDaysBeforePayment(
    savingsPlan?.frequency || frequencies.MONTHLY,
    savingsPlan?.start_day_week,
    savingsPlan?.day_of_month,
  )
  const isLumpSum = !quickStart?.frequency
  const showFinishSavingsPlanBanner = quickStart?.status === quickStartStatuses.ACTIVE && !isLumpSum && !savingsPlan
  const showLumpSumBanner = quickStart?.status === quickStartStatuses.ACTIVE && isLumpSum
  const showFirstTopupBanner =
    quickStart?.status === quickStartStatuses.ACTIVE && !isLumpSum && savingsPlan && daysBeforePayment > 0

  useEffect(() => {
    if (portfolioId) {
      waitForHistory(fetchDIYHistoryByPortfolio(portfolioId))
    } else {
      waitForHistory(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId])

  const securities = useMemo(() => {
    if (isSecuritiesLoading && detailedPortfolioSecurities.length < 1) {
      return new Array(randomSecuritySkeletonNumber).fill({ skeleton: true })
    }

    return detailedPortfolioSecurities
      .map(([security, portfolioSecurity]) => ({
        id: security?.id,
        title: security?.title,
        logo: security?.logo_uri,
        ticker: security?.ticker,
        dividends_type: security?.dividends_type,
        value: portfolioSecurity?.value || 0,
        target_weight: portfolioSecurity.target_weight,
        current_weight: portfolioSecurity?.current_weight || 0,
        stats: portfolioSecurity?.stats,
        processing: orders.getByPortfolioId(portfolioId).getBySecurityId(security?.id).length > 0,
        quantity: portfolioSecurity.quantity,
      }))
      .filter((security) => {
        if (security.target_weight === null) {
          return security.quantity > 0
        }

        return true
      })
  }, [orders, portfolioId, detailedPortfolioSecurities, isSecuritiesLoading])

  const chartData = {
    performance: historyPerformance?.map((data) => ({ date: data.date, value: data.balance })),
    contributions: historyContributions?.map((data) => ({ date: data.date, value: data.contributions })),
    dividends: historyDividends,
  }

  return {
    isSecuritiesLoading,
    isHistoryLoading,
    portfolio,
    securities,
    chartData,
    pointsOfInterest,
    showFinishSavingsPlanBanner,
    quickStart,
    showFirstTopupBanner,
    showLumpSumBanner,
    daysBeforePayment,
  }
}

export { usePortfolioData }
