import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import icon from 'components/molecules/Preloader/Preloader.svg'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

import { MandateDetails } from '../components/MandateDetails'

import { useRecurringPaymentProcessing } from './hooks/useRecurringPaymentProcessing'

type RecurringPaymentProcessingProps = {
  portfolio: Portfolio
  location: {
    query?: {
      status?: string
    }
  }
  handleClose: () => void
}

const RecurringPaymentProcessing = withRouter(
  ({ portfolio, location, handleClose }: RecurringPaymentProcessingProps): React.ReactElement | null => {
    const { desktop } = useMediaQueries()

    const {
      status,
      isDiyPortfolio,
      recurringPayment,
      showMandateDetails,
      handleContinue,
      openMandateDetails,
      closeMandateDetails,
      handleCloseWrapper,
    } = useRecurringPaymentProcessing({
      portfolio,
      location,
      handleClose,
    })

    const showMandateDetailsButton = useMemo(
      () => (
        <Width size={20} center>
          <SelectableCard onClick={openMandateDetails}>
            <Paper top={12} bottom={12}>
              <AllCenter>
                <ItemWithIcon
                  inline
                  space={8}
                  icon={<Icon type="new-copy-24" size={20} color={palette['content-on-background-primary']} />}
                  content={
                    <Typography color="on_background_primary" lineHeight="small" inline>
                      Show mandate details
                    </Typography>
                  }
                  iconVerticalAlign="center"
                  data-test-id="showMandateDetailsButton"
                />
              </AllCenter>
            </Paper>
          </SelectableCard>
        </Width>
      ),
      [openMandateDetails],
    )

    const content = useMemo(() => {
      if (status === 'successful') {
        const isAutoinvestActive = portfolio?.is_autoinvest_enabled

        return (
          <AllCenter>
            <Icon type="checkmark_in_a_circle" color={palette['status-success']} size={64} />
            <Paper top={24}>
              <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
                <Typo>Your Savings Plan is set up</Typo>
              </Typography>
            </Paper>
            <Paper top={48}>
              <Typography size={16} align="center">
                <Typo>
                  You can cancel your Savings Plan at any time
                  {isDiyPortfolio && (
                    <Fragment>
                      <br />
                      <br />
                      <span data-test-id="autoinvestIsOnNotice">
                        {isAutoinvestActive
                          ? 'AutoInvest is ON by default, but you can switch it off if you wish'
                          : 'AutoInvest is turned off because you have pending orders or incoming ISA assets'}
                      </span>
                    </Fragment>
                  )}
                </Typo>
              </Typography>
            </Paper>
            <Paper top={40}>{showMandateDetailsButton}</Paper>
          </AllCenter>
        )
      }

      if (status === 'failed') {
        return (
          <AllCenter>
            <Icon type="exclamation-triangle" color={palette['status-warning']} size={64} />
            <Paper top={24}>
              <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
                <Typo>Error</Typo>
              </Typography>
            </Paper>
            <Paper top={24}>
              <Typography size={16} align="center">
                <Typo>
                  The Savings Plan has not been set up. Please try again or contact the customer service for more
                  advice.
                </Typo>
              </Typography>
            </Paper>
          </AllCenter>
        )
      }

      return (
        <AllCenter>
          <Width size={4} center>
            <SvgIcon className="Icon_animation_spin" src={icon} />
          </Width>
          <Paper top={24}>
            <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
              <Typo>Please wait</Typo>
            </Typography>
          </Paper>
          <Paper top={24}>
            <Typography size={16} align="center">
              <Typo>We're working on setting up your Savings Plan.</Typo>
            </Typography>
          </Paper>
        </AllCenter>
      )
    }, [status, desktop, isDiyPortfolio, portfolio, showMandateDetailsButton])

    if (showMandateDetails)
      return (
        <MandateDetails
          onBack={closeMandateDetails}
          onClose={handleClose}
          recurringPayment={recurringPayment}
          data-test-id="mandateDetailsModal"
        />
      )

    return (
      <OptionsLayout
        header={
          <OptionsNavigationBar rightPartText="Close" onRightPartClick={handleCloseWrapper}>
            &nbsp;
          </OptionsNavigationBar>
        }
        content={
          desktop ? (
            <Paper top={56} left={40} right={40}>
              {content}
            </Paper>
          ) : (
            content
          )
        }
        button={
          status === 'successful' || status === 'failed' ? (
            <Button
              type="submit"
              onClick={handleContinue}
              mods={{ size: 'big block' }}
              data-test-id="recurringPaymentProcessingContinueButton"
            >
              Continue
            </Button>
          ) : null
        }
        data-test-id="recurringPaymentProcessingPopup"
      />
    )
  },
)

export { RecurringPaymentProcessing }
