import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useLoading, useSelector, useMemo, useCallback, useActions, useEffect } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import {
  $portfolioSecuritiesStore,
  fetchPortfolioSecuritiesFx,
  savePortfolioSecuritiesFx,
  removeSecurity,
} from 'app/effector/portfolio-securities'
import { fetchSecuritiesFx } from 'app/effector/securities'

import {
  fetchPortfolio as fetchPortfolioActionCreator,
  receivePortfolio as receivePortfolioActionCreator,
} from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'

import { useForm } from 'components/atoms/Forms'

import { regulatoryTypes, states as portfolioStates } from 'constants/portfolio'

const savePortfolioSecuritiesFxOnForm = attach({ effect: savePortfolioSecuritiesFx })
savePortfolioSecuritiesFxOnForm.fail.watch(() => showFailToast())

const sendAnalyticsEventWithSecurities = ({ securitiesModels, eventName }) => {
  const analyticsData = securitiesModels.reduce(
    (result, [security, portfolioSecurity]) => {
      result.item_id.push(security?.id)
      result.item_name.push(security?.title)
      result.item_category.push(portfolioSecurity?.added_from)
      result.item_brand.push(security?.provider_filter_name)
      result.quantity.push(1)
      result.price.push(1)
      result.value.push(null)
      result.payment_type.push(null)

      return result
    },
    {
      action: eventName,
      currency: 'GBP',
      item_id: [],
      item_name: [],
      item_category: [],
      item_brand: [],
      quantity: [],
      price: [],
      value: [],
      payment_type: [],
    },
  )

  trackEvent(analyticsData)
}

const useSecuritiesForm = (portfolioId, searchQuery) => {
  const { isLoading: isSecuritiesLoading, wait: waitForSecurities } = useLoading()
  const { isLoading: isPortfolioLoading, wait: waitForPortfolio } = useLoading()
  const { isLoading: isSaveLoading, wait: waitForSave } = useLoading()
  const isLoading = isSecuritiesLoading || isPortfolioLoading || isSaveLoading

  const portfolio = useSelector((state) => state.portfolios.list.get(portfolioId))
  const isPortfolioNew = useMemo(() => portfolio?.state === portfolioStates.NEW, [portfolio?.state])
  const weightsSetupNeeded = useMemo(() => portfolio?.weights_setup_needed, [portfolio?.weights_setup_needed])

  const shouldSignSippDeclaration = useSelector((state) => !state.client.agreed_with_sipp_declaration)

  const [fetchPortfolio, receivePortfolio] = useActions([fetchPortfolioActionCreator, receivePortfolioActionCreator])

  const { selectSecuritiesInPortfolioDetailed } = useUnit($portfolioSecuritiesStore)

  const securitiesModels = selectSecuritiesInPortfolioDetailed(portfolioId, true)

  const securities = useMemo(() => {
    let securities = securitiesModels.map(([security, portfolioSecurity]) => ({
      key: security?.id,
      id: security?.id,
      title: security?.title,
      ticker: security?.ticker,
      logo: security?.logo_uri,
      value: portfolioSecurity?.value || 0,
      weight: portfolioSecurity?.target_weight || 0,
      currentWeight: portfolioSecurity?.current_weight,
      showDelete: (portfolioSecurity?.quantity || 0) <= 0,
      isNew: portfolioSecurity?.target_weight === null,
      type: security?.type,
    }))

    if (securities.every((security) => security.isNew)) {
      const targetWeight = Math.floor(100 / securities.length)
      let unassignedPercents = 100 - targetWeight * securities.length

      securities = securities.map((security) => {
        let weight = targetWeight

        if (unassignedPercents > 0) {
          unassignedPercents = unassignedPercents - 1
          weight = weight + 1
        }

        return {
          ...security,
          weight,
        }
      })
    }

    if (securities.find((security) => security.showDelete)) {
      securities = securities.map((security) => {
        if (!security.showDelete) {
          security.showDelete = 'space'
        }

        return security
      })
    }

    return securities
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(securitiesModels)])

  useEffect(() => {
    if ((securitiesModels || []).length < 1) {
      waitForSecurities(Promise.all([fetchSecuritiesFx(), fetchPortfolioSecuritiesFx({ portfolioId })]))
    }
    if (!portfolio) {
      waitForPortfolio(fetchPortfolio(portfolioId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId])

  useEffect(() => {
    if (!(securitiesModels || []).length || !portfolio?.state || portfolio?.state !== 'NEW') return
    sendAnalyticsEventWithSecurities({ securitiesModels, eventName: 'view_cart' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securitiesModels?.length, portfolio?.state])

  const defaultValues = useMemo(() => {
    if (weightsSetupNeeded) {
      return {
        ...Object.fromEntries(securities.map((security) => [security.id, 0])),
      }
    }

    return {
      ...Object.fromEntries(securities.map((security) => [security.id, security.weight])),
    }
  }, [weightsSetupNeeded, securities])

  const { setValue, control, watch, dirtyFields, handleSubmit: handleFormSubmit } = useForm(defaultValues)
  const values = watch()
  const total = Object.values(values).reduce((total, value) => total + value, 0)
  const isFormDirty = useMemo(
    () => securities.map((security) => dirtyFields[security.id] || false).some((boolean) => boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [securities, JSON.stringify(dirtyFields)],
  )

  const handleDelete = useCallback(
    (securityId) => {
      // had to set input value to unreal value and call the field dirty 'cuz of some nasty bug → https://app.asana.com/0/1202304843784991/1204675325916953/f
      setValue(`${securityId}`, -1, { shouldDirty: true })
      removeSecurity({ securityId, portfolioId })
    },
    [portfolioId, setValue],
  )

  const handleSubmit = handleFormSubmit(async (values, regulatoryType) => {
    const data = Object.entries(values)
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .map(([security_id, target_weight]) => ({
        security_id: parseInt(security_id, 10),
        target_weight: `${target_weight}`,
      }))
      .sort((a, b) => {
        // sort it so the order of securities in the request is the same as in the UI
        const aSecurityIndex = securities.findIndex((security) => security.id === a.security_id)
        const bSecurityIndex = securities.findIndex((security) => security.id === b.security_id)

        return aSecurityIndex - bSecurityIndex
      })

    const unwatch = savePortfolioSecuritiesFxOnForm.done.watch(() => {
      if (weightsSetupNeeded) {
        receivePortfolio({ ...portfolio, weights_setup_needed: false })
      }

      if (isPortfolioNew) {
        sendAnalyticsEventWithSecurities({ securitiesModels, eventName: 'purchase' })

        if (regulatoryType === regulatoryTypes.SIPP && shouldSignSippDeclaration) {
          const currentLocation = window.location.pathname + window.location.search
          goTo(
            urlTo(
              'portfolio.edit-weights',
              { id: portfolioId },
              {
                ...searchQuery,
                openSipp: true,
                regulatoryType,
                back: currentLocation,
                registrationBack: urlTo('dashboard.securities'),
              },
            ),
          )
          return
        }

        const nextQuery = regulatoryType ? { ...searchQuery, regulatoryType } : { ...searchQuery }

        goTo(urlTo('dashboard.portfolio.finish', { id: portfolioId }, nextQuery))
        return
      }

      goTo(urlTo('dashboard.portfolio', { id: portfolioId }, { weightsChanged: true }))
    })

    await waitForSave(savePortfolioSecuritiesFxOnForm({ portfolioSecurityWeightList: data, portfolioId }))

    unwatch()
  })

  return {
    isLoading,

    securities,
    portfolio,
    isPortfolioNew,

    total,
    control,
    isFormDirty,

    handleDelete,
    handleSubmit,

    currentValues: values,
  }
}

export { useSecuritiesForm }
