import { useEffect, useTimeout, useInterval, useState } from 'hooks'

const TIMEOUT = 30 * 1000
const MINIMUM_INTERVAL = 3 * 1000

export const useRequestDataWithInterval = (requestAction, onFailure, needStop, timeout = TIMEOUT) => {
  // wait 30 sec, if request is pending, then stop call the request
  const [isDone, cancel, reset] = useTimeout(timeout)
  const [isRunRequest, setIsRunRequest] = useState(false)
  const isTimeDone = isDone()

  const needCall = !(needStop || isTimeDone !== false)

  const callRequest = async () => {
    try {
      if (!isTimeDone && !isRunRequest) {
        setIsRunRequest(true)

        const beginTime = Date.now()
        const successCallback = await requestAction()
        const endTime = Date.now()
        const runningTime = endTime - beginTime

        // we should wait no less 2 sec for correct showing states
        setTimeout(
          () => {
            successCallback()
            setIsRunRequest(false)
          },
          Math.max(runningTime, MINIMUM_INTERVAL),
        )
      }
    } catch (e) {
      cancel()
      onFailure()
    }
  }

  useInterval(
    async () => {
      await callRequest()
    },
    needCall && !isRunRequest ? MINIMUM_INTERVAL : null,
  )

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isTimeDone && !needStop) {
      onFailure()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needStop, isTimeDone])
}
