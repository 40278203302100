import React from 'react'

import { useUnit } from 'effector-react'

import { useEffect } from 'hooks'

import { Preloader } from 'components/molecules/Preloader'

import { $isLoading, $isNewRecoveryCodeShown, loginWithRecoveryCodeFx, confirmRecoveryCodeFx } from './model'
import { NewRecoveryCode } from './NewRecoveryCode'
import { RecoveryCodeForm } from './RecoveryCodeForm'

type TwoFactorRecoveryCodeProps = {
  query: Record<string, string>
  shouldRememberBrowser: boolean
  onAccept: () => void
  onBack: () => void
  handleStopStatusRequest: () => void
}

const TwoFactorRecoveryCode = ({
  query,
  shouldRememberBrowser,
  onAccept: handleAccept,
  onBack: handleBack,
  handleStopStatusRequest,
}: TwoFactorRecoveryCodeProps): React.ReactElement => {
  const [isLoading, isNewRecoveryCodeShown] = useUnit([$isLoading, $isNewRecoveryCodeShown])

  useEffect(() => {
    const loginWithRecoveryCodeUnwatch = loginWithRecoveryCodeFx.done.watch(() => {
      handleStopStatusRequest()
    })
    const confirmRecoveryCodeUnwatch = confirmRecoveryCodeFx.done.watch(() => {
      handleAccept()
    })

    return () => {
      loginWithRecoveryCodeUnwatch()
      confirmRecoveryCodeUnwatch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {isNewRecoveryCodeShown ? (
        <NewRecoveryCode />
      ) : (
        <RecoveryCodeForm query={query} shouldRememberBrowser={shouldRememberBrowser} onBack={handleBack} />
      )}

      <Preloader loading={isLoading} absolute background="background" />
    </div>
  )
}

export { TwoFactorRecoveryCode }
