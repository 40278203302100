import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

const updateOrCreateAddresses = async ({ id, addresses, saveAddressesAsABulk }) => {
  if (saveAddressesAsABulk) {
    return await putAddresses(
      id,
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      addresses.list.map(({ id, is_current, ...address }, index) => ({
        ...address,
        is_current: index === 0,
      })),
    )
  }

  const reversedUpdatedAddresses = await addresses.list
    .reverse()
    .reduce(
      async (promiseChain, address) => [
        ...(await promiseChain),
        address.id ? await updateAddress(id, address) : await createAddress(id, address),
      ],
      Promise.resolve([]),
    )

  return reversedUpdatedAddresses.reverse()
}

const createContact = async (contact) => {
  try {
    const { data } = await axios.post('contacts/', contact)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const updateContact = async (id, contact) => {
  try {
    const { data } = await axios.patch(`contacts/${id}/`, contact)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const fetchContacts = async () => {
  try {
    const { data } = await axios.get(`contacts/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const deleteContact = async (id) => {
  try {
    await axios.delete(`contacts/${id}/`)
  } catch (error) {
    return new ApiError(error)
  }
}

const createAddress = async (id, address) => {
  try {
    const { data } = await axios.post(`contacts/${id}/addresses/`, address)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const updateAddress = async (id, address) => {
  try {
    const { id: addressId, ...addressData } = address
    const { data } = await axios.patch(`contacts/${id}/addresses/${addressId}`, addressData)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const putAddresses = async (id, addresses) => {
  try {
    const { data } = await axios.put(`contacts/${id}/addresses/`, addresses)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const confirmPhone = async ({ phone }) => {
  try {
    const { data } = await axios.post('/contacts/phone_confirmation/', { phone })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export {
  createContact,
  updateContact,
  fetchContacts,
  deleteContact,
  createAddress,
  updateAddress,
  putAddresses,
  confirmPhone,
  updateOrCreateAddresses,
}
