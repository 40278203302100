import React, { Fragment } from 'react'

import { useState, useCallback, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { DonutChart } from 'components/atoms/DonutChart'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { VirtualList } from 'components/molecules/VirtualList'

import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'

import { type SharingPortfolioData } from '../../types'

const titles = {
  assets: 'ETFs',
  regions: 'Regions',
  sectors: 'Sectors',
  equities: 'Holdings',
}

interface SharePortfolioAnalyticsProps extends SharingPortfolioData {
  layoutNode: React.ReactNode
  stickyButton: React.ReactNode
  togglerNode: React.ReactNode
  securitiesNode: React.ReactNode
  titleNode: React.ReactNode
  appLinksNode: React.ReactNode
}

const SharePortfolioAnalytics = ({
  sectors,
  regions,
  equities,
  assets,
  equity_types, // eslint-disable-line
  layoutNode,
  stickyButton,
  togglerNode,
  appLinksNode,
  securities,
  securitiesNode,
  titleNode,
}: SharePortfolioAnalyticsProps): React.ReactElement => {
  const analytics = useMemo(
    () => ({ sectors, regions, equities, assets, equity_types }),
    [sectors, regions, equities, assets, equity_types],
  )

  const [activeTab, setActiveTab] = useState('assets')

  const [showFullList, setShowFullList] = useState(false)

  const activeList = useMemo(() => {
    return showFullList ? analytics[activeTab] : analytics[activeTab]?.slice(0, 100)
  }, [analytics, activeTab, showFullList])

  const shouldShowShowAllButton = useMemo(() => {
    return !showFullList && analytics[activeTab].length > 100
  }, [showFullList, analytics, activeTab])

  const tabs = useMemo(
    () => [
      { title: 'ETFs', id: 'assets', isActive: activeTab === 'assets' },
      { title: 'Regions', id: 'regions', isActive: activeTab === 'regions' },
      { title: 'Sectors', id: 'sectors', isActive: activeTab === 'sectors' },
      { title: 'Holdings', id: 'equities', isActive: activeTab === 'equities' },
    ],
    [activeTab],
  )

  const renderItems = useCallback((listElement, items) => {
    const item = items[listElement.index]

    return (
      <Paper top={24} bottom={24}>
        <SecurityConstituent
          key={item.name}
          logo={item.logo}
          name={item.name}
          actual={item.target}
          color={item.color}
        />
      </Paper>
    )
  }, [])

  const renderEmpty = useCallback(() => null, [])

  const itemsList = useMemo(
    () => (
      <Fragment>
        <Paper top={24} bottom={32}>
          <Typography size={20} weight="semibold">
            Equities {analytics.equity_types.STOCK}%
          </Typography>
        </Paper>
        <VirtualList
          scrollableElement={layoutNode}
          items={activeList}
          itemMinHeight={76}
          itemMaxHeight={96}
          renderItem={(element) => renderItems(element, activeList)}
          renderNoItems={renderEmpty}
        />
        <Paper top={16}>
          {shouldShowShowAllButton && (
            <Button
              mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
              onClick={() => {
                setShowFullList(true)
              }}
              data-test-id="showAllButton"
            >
              Show all {analytics[activeTab].length}
            </Button>
          )}
        </Paper>
        <Paper top={48}>
          <Typography size={20} weight="semibold">
            Bonds {analytics.equity_types.BOND}%
          </Typography>
        </Paper>
        <Paper top={48} bottom={20}>
          <Typography size={20} weight="semibold">
            Alternatives {analytics.equity_types.ALTERNATIVE}%
          </Typography>
        </Paper>
      </Fragment>
    ),
    [activeList, layoutNode, renderEmpty, renderItems, shouldShowShowAllButton, analytics, activeTab],
  )

  const summary = useMemo(() => {
    return {
      sectors: analytics.sectors.map((item) => [item.target, item.color]),
      regions: analytics.regions.map((item) => [item.target, item.color]),
      equities: [[analytics.equity_types.STOCK, palette.stocks]],
      assets: analytics.assets.map((item) => [item.target, item.color]),
    }
  }, [analytics])

  const pieChart = useMemo(
    () => (
      <Card>
        <Paper top={24} bottom={24} left={24} right={24}>
          <AllCenter>
            <DonutChart size={256} lineWidth={14} values={summary[activeTab]} minimalSectorValue={0.65}>
              <Paper>
                <Typography size={48} weight="semibold" lineHeight="small" align="center" data-test-id="donutValue">
                  <Typo>{(activeTab === 'assets' ? securities.length : analytics[activeTab].length) || ''}</Typo>
                </Typography>
                <Typography size={16} lineHeight="small" align="center" data-test-id="donutValueSubtitle">
                  <Typo>{titles[activeTab]}</Typo>
                </Typography>
              </Paper>
            </DonutChart>
          </AllCenter>
        </Paper>
      </Card>
    ),
    [analytics, activeTab, summary, securities],
  )

  return (
    <ColumnarLayout mods={{ padding: 'no' }}>
      <Column size={1}>
        {titleNode && <Paper bottom={56}>{titleNode}</Paper>}
        <GreyTabs
          tabs={tabs}
          onChange={(tab) => {
            setActiveTab(tab.id)
          }}
        />
        <Paper top={32}>
          {activeTab === 'assets' ? (
            <Fragment>
              {activeList.map((item) => (
                <Paper top={24} bottom={24} key={item.name} data-test-id="sharedAnalyticsItem">
                  <SecurityConstituent name={item.name} actual={item.target} color={item.color} />
                </Paper>
              ))}
              <Paper top={56}>{securitiesNode}</Paper>
            </Fragment>
          ) : (
            itemsList
          )}
        </Paper>
        {stickyButton}
      </Column>
      <Column size={0}>
        {togglerNode}
        <Paper left={80} bottom={40}>
          <Width size={19}>{pieChart}</Width>
        </Paper>
        {appLinksNode}
      </Column>
    </ColumnarLayout>
  )
}

export { SharePortfolioAnalytics }
