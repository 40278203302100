import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import Header from 'components/_old/Header/Header.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'

import { TwoFactorSetupLayout } from '../components/TwoFactorSetupLayout'

import { RecoveryMethods } from './RecoveryMethods'

const RecoveryMethodStep = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const headerNode = <Header hideDrawer logoLink="/" hard withShadow />

  const handleBack = (): void => {
    goTo(urlTo('two-factor-auth-setup.awaiting-push'))
  }

  const navbarNode = (
    <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
      <Typography size={20} lineHeight="small" weight="semibold">
        <Typo>Step 3 of 4</Typo>
      </Typography>
    </NavigationBar>
  )

  const content = (
    <Fragment>
      <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
        <Typo>Choose recovery method</Typo>
      </Typography>
      <Paper top={8}>
        <Typography size={14} align="center">
          <Typo>
            If push notifications fail, select another two-factor authentication method to regain access to your
            account.
          </Typo>
        </Typography>
      </Paper>
      <Paper top={56}>
        <Width size={28} center>
          <RecoveryMethods />
        </Width>
      </Paper>
    </Fragment>
  )

  return (
    <TwoFactorSetupLayout>
      {desktop ? (
        <DesktopLayout
          header={headerNode}
          content={
            <Inner twoColumns>
              {navbarNode}
              <Paper top={40}>
                <Width size={45} center>
                  {content}
                </Width>
              </Paper>
            </Inner>
          }
          footer={<DesktopFooter />}
        />
      ) : (
        <MobileLayout
          header={navbarNode}
          content={
            <Paper top={8} bottom={56}>
              {content}
            </Paper>
          }
        />
      )}
    </TwoFactorSetupLayout>
  )
}

export { RecoveryMethodStep }
