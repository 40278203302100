import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Sticky } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/Sticky'

import savingsPlanImage from './savingsPlan.png'
import { SavingsPlanFrequencyBadges } from './SavingsPlanFrequencyBadges'

type SavingsPlanPromoProps = {
  title: string
  isRecurringPayment?: boolean
  isQuickStartInProgress?: boolean
  onContinue: () => void
}

const SavingsPlanPromo = ({
  title,
  isRecurringPayment,
  isQuickStartInProgress,
  onContinue: handleContinue,
}: SavingsPlanPromoProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <AllCenter>
        <img src={savingsPlanImage} width={244} height={132} />
        <Paper top={desktop ? 32 : 56}>
          <Typography
            size={36}
            lineHeight="small"
            weight="semibold"
            align="center"
            color="white"
            data-test-id="savingsPlanPromoTitle"
          >
            <Typo noNbsp>{isQuickStartInProgress ? 'One step to Savings Plan' : title}</Typo>
          </Typography>
        </Paper>
        {isQuickStartInProgress && isRecurringPayment && (
          <Paper top={desktop ? 32 : 24}>
            <Typography
              size={14}
              weight="semibold"
              align="center"
              color="white"
              data-test-id="savingsPlanPromoSubTitle"
            >
              <Typo noNbsp>Confirm amount and frequency</Typo>
            </Typography>
          </Paper>
        )}
        {!isQuickStartInProgress && isRecurringPayment && (
          <Paper top={desktop ? 32 : 24} data-test-id="promoFrequencyLabels">
            <SavingsPlanFrequencyBadges />
          </Paper>
        )}
      </AllCenter>
      <Paper top={desktop ? 32 : 24}>
        <Width size={27} center>
          <Paper right={desktop ? 0 : 24} left={desktop ? 0 : 24}>
            <Typography size={desktop ? 16 : 14} color="white">
              <Typo noNbsp>
                A Savings Plan lets you regularly invest in selected securities or your cash portfolio.
                <br />
                <br />
                By setting up a plan, you’re enabling AutoInvest for this portfolio and instructing InvestEngine to
                invest after each topup according to your chosen ETF weight, in the next available trading window.{' '}
                <Link to="/terms#autoinvest" mods={{ color: 'onColorWhiteToRed' }} underline blank hard>
                  <Typo>More info.</Typo>
                </Link>
              </Typo>
            </Typography>
          </Paper>
        </Width>
      </Paper>
      <Sticky bottom>
        <Paper top={40}>
          <Width size={21} center>
            <Button
              type="submit"
              onClick={handleContinue}
              mods={{ size: 'big block', color: 'green' }}
              data-test-id="recurringPaymentStartButton"
            >
              Continue
            </Button>
          </Width>
        </Paper>
      </Sticky>
    </Fragment>
  )
}

export { SavingsPlanPromo }
