import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

type BonusDTO = {
  id: number
  amount: string | null
  state: string
}

const getFetchBonusesURL = (state?: string): string => (state ? `bonuses/?state=${state}` : `bonuses/`)
const fetchBonuses = async (state?: string): Promise<BonusDTO[] | ApiError> => {
  try {
    const { data } = await axios.get(getFetchBonusesURL(state))

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
fetchBonuses.getUrl = getFetchBonusesURL

const getClaimBonusURL = (): string => `bonuses/claim/`
const claimBonus = async (): Promise<BonusDTO | ApiError> => {
  try {
    const { data } = await axios.post(getClaimBonusURL())

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
claimBonus.getUrl = getClaimBonusURL

export { type BonusDTO, fetchBonuses, claimBonus }
