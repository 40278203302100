import React from 'react'
import ReactCrop from 'react-image-crop'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Slider from 'components/_old/Slider/Slider.jsx'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import { Preloader } from 'components/molecules/Preloader'

import { useCropAvatarModal } from './useCropAvatarModal'

import 'react-image-crop/dist/ReactCrop.css'

type CropAvatarModalProps = {
  image: string
  isLoading: boolean
  onClose: () => void
  onBack: () => void
  onSave: (image: string) => Promise<void>
}

const CropAvatarModal = ({ onClose, onBack, image, onSave, isLoading }: CropAvatarModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { imgRef, crop, scale, scaleRange, setCrop, setScale, setCompletedCrop, onImageLoad, handleSave } =
    useCropAvatarModal({
      onBack,
      onSave,
    })

  const buttonNode = (
    <Button mods={{ size: 'big block' }} onClick={handleSave}>
      Save
    </Button>
  )

  const cropperNode = (
    <ReactCrop
      crop={crop}
      circularCrop
      aspect={1}
      onChange={(crop, percentageCrop) => {
        setCrop(percentageCrop)
      }}
      onComplete={(crop) => {
        setCompletedCrop(crop)
      }}
      minHeight={150}
      minWidth={150}
      keepSelection
    >
      <img ref={imgRef} src={image} onLoad={onImageLoad} style={{ transform: `scale(${scale})` }} />
    </ReactCrop>
  )

  const zoomSlider = <Slider withBars value={scale} valueKey="value" scale={scaleRange} onChange={setScale} />

  if (desktop) {
    return (
      <Modal open={true} close={null}>
        <Paper top={desktop ? 20 : 0} bottom={desktop ? 20 : 0} left={desktop ? 24 : 0} right={desktop ? 24 : 0}>
          <NavigationBar
            leftPartText="Back"
            onLeftPartClick={onBack}
            rightPartText="Close"
            onRightPartClick={onClose}
            plain
          >
            <Typography size={desktop ? 20 : 16} lineHeight="small" weight="semibold">
              Crop photo
            </Typography>
          </NavigationBar>
        </Paper>
        <Paper top={80} bottom={80}>
          <Width size={36}>
            <Width size={27} center style={{ display: 'flex', justifyContent: 'center' }}>
              {cropperNode}
            </Width>
            <Width size={24} center>
              <Paper top={32} bottom={48}>
                {zoomSlider}
              </Paper>
              {buttonNode}
            </Width>
          </Width>
        </Paper>
        <Preloader absolute loading={isLoading} />
      </Modal>
    )
  }

  return (
    <Modal open={true} close={null} onClose={onClose}>
      <MobileLayout
        header={
          <NavigationBar
            leftPartText="Back"
            onLeftPartClick={onBack}
            rightPartText="Close"
            onRightPartClick={onClose}
          />
        }
        content={
          <Inner flex>
            <AllCenter>
              {cropperNode}
              <Paper top={32} style={{ width: '100%' }}>
                {zoomSlider}
              </Paper>
            </AllCenter>
          </Inner>
        }
        footer={<MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
      />
      <Preloader absolute loading={isLoading} />
    </Modal>
  )
}

export { CropAvatarModal }
