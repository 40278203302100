import { useState, useRef, useEffect, useCallback, type MutableRefObject } from 'react'

const useClickOutside = <Element extends HTMLElement>(): [
  MutableRefObject<Element | null>,
  boolean,
  (state: boolean) => void,
] => {
  const ref = useRef<Element>(null)
  const [state, setState] = useState(false)

  const handleEvent = useCallback(
    (event) => {
      const hasClickedOutside = !(ref?.current?.contains(event?.target) ?? false)

      setState(hasClickedOutside)
    },
    [setState],
  )

  const addEventListener = useCallback(() => {
    if (window.PointerEvent) {
      document.addEventListener('pointerdown', handleEvent)
      return
    }

    document.addEventListener('mousedown', handleEvent)
    document.addEventListener('touchstart', handleEvent)
  }, [handleEvent])

  const removeEventListener = useCallback(() => {
    if (window.PointerEvent) {
      document.removeEventListener('pointerdown', handleEvent)
      return
    }

    document.removeEventListener('mousedown', handleEvent)
    document.removeEventListener('touchstart', handleEvent)
  }, [handleEvent])

  useEffect(() => {
    addEventListener()

    return removeEventListener
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [ref, state, setState]
}

export default useClickOutside
