const colorsPalette = {
  stocks: '#B158A7',
  bonds: '#56CDDA',
  alternatives: '#FDC032',
  cash: '#9BB7E3',
}

const cashPalette = {
  'cash-default': 'rgb(253, 192, 50)',
}

const selfSelectedPalette = {
  'self-selected-default': 'rgb(0, 191, 232)',
}

const primaryPalette = {
  'primary-action': 'rgb(25, 43, 194)',
  'primary-default': 'rgb(59, 59, 245)',
  'primary-disabled': 'rgba(39, 97, 232, 0.45)',
  'primary-surface-85': 'rgba(39, 97, 232, 0.85)',
  'primary-surface-65': 'rgba(39, 97, 232, 0.65)',
  'primary-surface-45': 'rgba(39, 97, 232, 0.45)',
  'primary-surface-25': 'rgba(39, 97, 232, 0.25)',
  'primary-surface-10': 'rgba(39, 97, 232, 0.1)',
  'primary-surface-5': 'rgba(39, 97, 232, 0.05)',
  'primary-link-hover': 'rgb(255, 19, 43)',
}

const secondaryPalette = {
  'secondary-action': 'rgb(59, 137, 11)',
  'secondary-default': 'rgb(79, 190, 12)',
  'secondary-disabled': 'rgba(79, 190, 12, 0.45)',
  'secondary-surface-85': 'rgba(79, 190, 12, 0.85)',
  'secondary-surface-65': 'rgba(79, 190, 12, 0.65)',
  'secondary-surface-45': 'rgba(79, 190, 12, 0.45)',
  'secondary-surface-25': 'rgba(79, 190, 12, 0.25)',
  'secondary-surface-10': 'rgba(79, 190, 12, 0.1)',
  'secondary-link-hover': 'rgb(255, 222, 0)',
  'secondary-link-hover-45': 'rgba(255, 222, 0, 0.45)',
}

const backgroundPalette = {
  'background-default': 'rgb(255, 255, 255)',
  'background-default-85': 'rgb(255, 255, 255, 0.85)',
  'background-overlay': 'rgba(244, 244, 244, 0.85)',
  'background-overlay-opaque': 'rgb(246, 246, 246)',
  'background-muted': 'rgba(244, 244, 244, 0.85)',
  'background-minor': 'rgba(0, 0, 0, 0.05)',
  'background-minor-opaque': 'rgba(242, 242, 242, 1)',
  'background-border': 'rgba(0, 0, 0, 0.1)',
  'background-border-opaque': 'rgb(229, 229, 229)',
  'background-transparent': 'rgba(255, 255, 255, 0)',
  'background-thumb': 'rgb(250, 250, 250)',
  'background-card': 'rgb(255, 255, 255, 0)',
  'background-card-opaque': 'rgb(255, 255, 255)',
  'background-primary': 'rgb(59, 59, 245)',
  'background-primary-action': 'rgb(25, 43, 194)',
  'background-purple': 'rgb(151, 71, 255)',
  'background-and-borders-muted': 'rgba(244, 244, 244, 0.85)',
}

const contentOnBackgroundPalette = {
  'content-on-background-solid': 'rgba(0, 0, 0)',
  'content-on-background-default': 'rgba(0, 0, 0, 0.9)',
  'content-on-background-additional': 'rgba(0, 0, 0, 0.65)',
  'content-on-background-minor': 'rgba(0, 0, 0, 0.45)',
  'content-on-background-muted': 'rgba(0, 0, 0, 0.25)',
  'content-on-background-accent': 'rgba(107, 12, 229, 1)',
  'content-on-background-primary': 'rgb(59, 59, 245)',
  'content-on-background-outstanding': 'rgb(88, 130, 1)',
}

const contentOnColorPalette = {
  'content-on-color-default': 'rgba(255, 255, 255, 0.9)',
  'content-on-color-additional': 'rgba(255, 255, 255, 0.65)',
  'content-on-color-minor': 'rgba(255, 255, 255, 0.45)',
  'content-on-color-muted': 'rgba(255, 255, 255, 0.25)',
  'content-on-color-white': 'rgba(255, 255, 255, 1)',
  'content-on-color-red': 'rgba(255, 147, 140, 1)',
  'content-on-color-black': 'rgba(0, 0, 0, 0.9)',
}

const statusPalette = {
  'status-error-action': 'rgb(216, 9, 71)',
  'status-error': 'rgb(255, 65, 54)',
  'status-error-65': 'rgba(255, 65, 54, 0.65)',
  'status-error-45': 'rgba(255, 65, 54, 0.45)',
  'status-error-25': 'rgba(255, 65, 54, 0.25)',
  'status-error-10': 'rgba(255, 65, 54, 0.1)',
  'status-success-action': 'rgb(59, 137, 11)',
  'status-success': 'rgb(79, 190, 12)',
  'status-success-65': 'rgba(79, 190, 12, 0.65)',
  'status-success-45': 'rgba(79, 190, 12, 0.45)',
  'status-success-25': 'rgba(79, 190, 12, 0.25)',
  'status-success-10': 'rgba(79, 190, 12, 0.1)',
  'status-warning': 'rgb(255, 163, 36)',
  'status-warning-65': 'rgba(255, 163, 36, 0.65)',
  'status-warning-45': 'rgba(255, 163, 36, 0.45)',
  'status-warning-25': 'rgba(255, 163, 36, 0.25)',
  'status-warning-10': 'rgba(255, 163, 36, 0.1)',
}

const accentPalette = {
  'accent-default': '#9747FF',
  'accent-action': '#BC87FF',
  'accent-disabled': '#D0ACFF',
  'accent-surface-45': '#D0ACFF',
  'accent-surface-25': '#E5D1FF',
  'accent-surface-10': '#F5EDFF',
}

const outstandingPalette = {
  'outstanding-default': 'rgb(174, 232, 53)',
  'outstanding-action': 'rgb(197, 244, 98)',
  'outstanding-disabled': 'rgb(232, 248, 200)',
  'outstanding-surface-45': 'rgb(209, 245, 131)',
  'outstanding-surface-25': 'rgb(232, 255, 182)',
  'outstanding-surface-10': 'rgb(244, 255, 222)',
}

const legacyLightColors = {
  ...colorsPalette,
  ...cashPalette,
  ...selfSelectedPalette,
  ...primaryPalette,
  ...secondaryPalette,
  ...backgroundPalette,
  ...contentOnBackgroundPalette,
  ...contentOnColorPalette,
  ...statusPalette,
  ...accentPalette,
  ...outstandingPalette,
}

module.exports = legacyLightColors
