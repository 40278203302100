import React, { Fragment } from 'react'

import { useCallback, useMemo } from 'hooks'

import { getDateRangeLabel } from 'helpers/date'
import { format as formatMoney } from 'helpers/money'

import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import { ContentWithIconAndLink } from '../ContentWithIconAndLink'

import { type CombinedPortfolioPointOfInterest, PortfolioPointOfInterestTypes } from './index'

type PortfolioCombinedPointOfInterestContentProps = CombinedPortfolioPointOfInterest & {
  linkToTransactions: string | null
}

function PortfolioCombinedPointOfInterestContent({
  dateFrom,
  dateTo,
  data,
  linkToTransactions,
}: PortfolioCombinedPointOfInterestContentProps): React.ReactElement {
  const getTypeNode = useCallback(
    (type: PortfolioPointOfInterestTypes) => {
      const filtered = data.filter((dataItem) => dataItem.type === type)

      if (filtered.length < 1) {
        return null
      }

      const title = type === PortfolioPointOfInterestTypes.BUY ? 'buys' : 'sells'
      const transactionsQuantity = filtered.reduce(
        (sum, dataItem) => +sum + parseInt(dataItem.data.transactions_quantity, 10),
        0,
      )
      const totalValue = filtered.reduce((sum, dataItem) => +sum + parseFloat(dataItem.data.total_value), 0)

      return (
        <Stack vertical={4}>
          <Typography size={14} lineHeight="small" weight="semibold" align="right">
            <Typo>
              {transactionsQuantity} {title}
            </Typo>
          </Typography>
          <Typography size={14} lineHeight="small" color="additional" align="right">
            <Typo>{formatMoney(totalValue, true, true)}</Typo>
          </Typography>
        </Stack>
      )
    },
    // optimised
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data)],
  )
  const bought = useMemo(() => getTypeNode(PortfolioPointOfInterestTypes.BUY), [getTypeNode])
  const sold = useMemo(() => getTypeNode(PortfolioPointOfInterestTypes.SELL), [getTypeNode])

  const content = useMemo(
    () => (
      <Fragment>
        <Typography size={14} lineHeight="small" weight="semibold" align="right" color="inherit">
          <Typo>{getDateRangeLabel(dateFrom, dateTo)}</Typo>
        </Typography>
        <Paper top={12}>
          <Stack vertical={12}>
            {bought}
            {sold}
          </Stack>
        </Paper>
      </Fragment>
    ),
    [dateFrom, dateTo, bought, sold],
  )

  if (linkToTransactions) {
    return <ContentWithIconAndLink to={linkToTransactions}>{content}</ContentWithIconAndLink>
  }

  return (
    <Paper top={12} right={12} bottom={12} left={12}>
      {content}
    </Paper>
  )
}

export { PortfolioCombinedPointOfInterestContent, type PortfolioCombinedPointOfInterestContentProps }
