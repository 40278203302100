import React, { Fragment, useRef, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'hooks'

import axios from 'helpers/ajax'
import { features } from 'helpers/features'
import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import { GP_DEFAULT } from 'constants/globalPreloaderStatuses.js'

import GlobalPreloader from 'components/_old/GlobalPreloader/GlobalPreloader.jsx'
import { SelectProductType } from 'app/pages/SelectProductType/SelectProductType.jsx'

/*
  Since we fetch feature toggles here and after fetch it causes global update (Root.jsx:87) and we don't need to run
  business logic twice, we need that extrenal flag that will prevent logic to run if it's already in process.
 */
let MOUNTING = false

const PreFlight = ({ route, location: { query = {} } = {} }) => {
  const client = useSelector((state) => state.client)
  const selectProductType = useRef(null)

  const parseTypeData = useCallback((type) => {
    try {
      return type.split(',')
    } catch (error) {
      return undefined
    }
  }, [])

  const handleRedirect = useCallback(() => {
    const defaultNextUrl = urlTo('product-type', {}, query)
    let nextUrl = query.next && query.next.length > 0 ? query.next : defaultNextUrl
    const isIsaTransfer = query.IsaTransfer === 'true'

    if (isIsaTransfer) {
      nextUrl = urlTo('dashboard', null, { IsaTransfer: 'true' })
    }

    if (query?.type && selectProductType?.current) {
      const { handleSubmit } = selectProductType.current
      const type = parseTypeData(query.type)

      if (type) {
        return handleSubmit(type[0])
      }
    }

    if (client.access_token) {
      return goTo(nextUrl, { replace: true })
    }

    goTo(defaultNextUrl, { replace: true })
  }, [query, parseTypeData, client.access_token])

  const defaultMount = useCallback(async () => {
    const doNotLogout = query.do_not_logout && query.do_not_logout === 'true'

    if (client.access_token) {
      // by default logout user and then redirect it back
      if (!doNotLogout) {
        const nextUrl = urlTo('pre-flight', null, query)

        return goTo(nextUrl, { replace: true })
      }

      // if asked not to logout, check their token, if it's old — logout and redirect to login with proper next
      if (doNotLogout) {
        try {
          await axios.get(`/oauth/ping/`)
        } catch (error) {
          const nextUrl = urlTo('login', null, query.next ? { next: query.next } : null)

          return goTo(nextUrl, { hardGoTo: true, replace: true })
        }
      }
    }

    handleRedirect()
  }, [client.access_token, handleRedirect])

  const handleMount = useCallback(async () => {
    if (MOUNTING) {
      return
    }

    MOUNTING = true

    trackEvent({
      category: 'Get started',
      action: 'Click ‘Get started’',
    })

    trackEvent(
      {
        action: 'get_started_clicked',
      },
      { plugins: { 'google-analytics-v3': false } },
    )

    await features.fetch()

    return await defaultMount()
  }, [defaultMount])

  useEffect(
    () => {
      handleMount()

      return () => {
        MOUNTING = false
      }
    },
    [], // `inputs` is empty array for a reason, we shouldn't call this effect if query or something changed
  )

  return (
    <Fragment>
      <SelectProductType ref={selectProductType} route={route} />
      <GlobalPreloader status={GP_DEFAULT} loading />
    </Fragment>
  )
}

PreFlight.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string,
  }),
  location: PropTypes.shape({
    query: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
}

export { PreFlight }
