import React from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries, useMemo, useCallback } from 'hooks'

import { palette } from 'helpers/palette/'
import { urlTo, goTo } from 'helpers/router.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

const StarlingConnectedModal = withRouter(({ location, params }) => {
  const { starlingConnected, ...restQuery } = location.query

  const { desktop } = useMediaQueries()

  const handleClose = useCallback((): void => {
    goTo(urlTo('dashboard', null, restQuery), { replace: true })
  }, [restQuery])

  const iconNode = useMemo(
    () => (
      <AllCenter>
        <Icon type="checkmark_in_a_circle" color={palette['status-success']} size={64} />
      </AllCenter>
    ),
    [],
  )

  const titleNode = useMemo(
    () => (
      <Paper top={24}>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
          <Typo>Starling successfully connected</Typo>
        </Typography>
      </Paper>
    ),
    [desktop],
  )

  const descriptionNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography size={desktop ? 14 : 16} align="center">
          <Typo>You will see InvestEngine and your account balance on the Starling app</Typo>
        </Typography>
      </Paper>
    ),
    [desktop],
  )

  const button = useMemo(
    () => (
      <Button onClick={handleClose} mods={{ size: 'big block' }}>
        Continue
      </Button>
    ),
    [handleClose],
  )

  if (desktop) {
    return (
      <Modal open={!!starlingConnected} onClose={handleClose}>
        <ModalContent width={36}>
          <Paper top={120} bottom={120}>
            <Width size={27} center>
              {iconNode}
              {titleNode}
              {descriptionNode}
            </Width>
            <Paper top={56} bottom={32}>
              <Width size={16} center>
                {button}
              </Width>
            </Paper>
          </Paper>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal open={!!starlingConnected} onClose={handleClose}>
      <ModalContent width={36}>
        <MobileLayout
          header={<NavigationBar onRightPartClick={handleClose} rightPartText="Close" />}
          content={
            <AllCenter>
              <Paper>
                {iconNode}
                {titleNode}
                {descriptionNode}
              </Paper>
            </AllCenter>
          }
          footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
        />
      </ModalContent>
    </Modal>
  )
})

export { StarlingConnectedModal }
