import { useEffect } from 'hooks'

import { goTo } from 'helpers/router.js'

function Root(): null {
  useEffect(() => {
    const url = `${window.location.pathname}${window.location.hash}${window.location.search}`
      // redirect to regions (as a default root)
      .replace(/analytics(\/\d+\/|\/)?/, 'analytics$1regions/')
    goTo(url, { replace: true })
  }, [])

  return null
}

export { Root }
