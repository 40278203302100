import axios, { type AxiosResponse } from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

enum PromocodeStatuses {
  UNKNOWN = 'UNKNOWN',
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  APPLIED = 'APPLIED',
}

const getFetchPromocodeStatusUrl = (): string => '/promocodes/status/'
const fetchPromocodeStatus = async (): Promise<PromocodeStatuses | ApiError> => {
  try {
    const response: AxiosResponse<{ status: PromocodeStatuses }> = await axios.get(getFetchPromocodeStatusUrl())

    if (response.data.status && PromocodeStatuses[response.data.status]) {
      return PromocodeStatuses[response.data.status]
    }

    return PromocodeStatuses.UNKNOWN
  } catch (error) {
    return new ApiError(error)
  }
}
fetchPromocodeStatus.getUrl = getFetchPromocodeStatusUrl

type ApplyPromocodeData = { promocode: string }
type ApplyPromocodeDTO = {
  bonus: {
    min: string
    max: string
  }
}

const getApplyPromocodeUrl = (): string => '/promocodes/apply/'
const applyPromocode = async (data: ApplyPromocodeData): Promise<ApplyPromocodeDTO | ApiError> => {
  try {
    const response: AxiosResponse<ApplyPromocodeDTO> = await axios.post(getApplyPromocodeUrl(), data)

    if (!/^2\d\d/.test(response.status.toString())) {
      throw new ApiError(response)
    }

    return response.data
  } catch (error) {
    return new ApiError(error)
  }
}
applyPromocode.getUrl = getApplyPromocodeUrl

export { PromocodeStatuses, fetchPromocodeStatus, type ApplyPromocodeData, type ApplyPromocodeDTO, applyPromocode }
