import React from 'react'

import { useMemo } from 'hooks'

import { palette } from 'helpers/palette'

type HoverPointProps = {
  position: {
    x: number
    y: number
  }
  setPickCoordinates: boolean
  color: string
  visible?: boolean
  min?: boolean
  max?: boolean
  current?: boolean
}

function HoverPoint({
  position,
  setPickCoordinates,
  visible = true,
  min = false,
  max = false,
  current = false,
}: HoverPointProps): React.ReactElement {
  const positionY = useMemo(() => position.y - 1, [position.y])

  const peakProps = setPickCoordinates
    ? {
        'data-income-line-min': min,
        'data-income-line-max': max,
        'data-income-line-current': current,
      }
    : {}

  return (
    <circle
      cx={position.x ?? 0}
      cy={positionY ?? 0}
      r="4"
      fill={palette['content-on-background-additional']}
      style={{ opacity: visible ? 1 : 0, stroke: palette['background-default'], strokeWidth: 2 }}
      {...peakProps}
    />
  )
}

export { HoverPoint }
