import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette'
import { useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Width from 'components/_old/Width/Width'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Nobr } from 'components/_old/Nobr'

import etfIcon from './etfPurple.png'

import './Empty.css'

const Empty = ({ createPortfolioUrl }) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries
  const classes = classNames('Empty', getMediaQuieryClasses('Empty', mediaQueries))

  return (
    <Paper className={classes} top={desktop ? 80 : null} bottom={desktop ? null : 24} data-test-id="dashboardEmpty">
      <Width size={desktop ? 7.5 : 5} center>
        <img src={etfIcon} width={desktop ? 120 : 80} height={desktop ? 120 : 80} data-test-id="greetingsImage" />
      </Width>
      <Paper top={desktop ? 40 : 24}>
        <Width size={28} center>
          <Typography lineHeight="small" weight="semibold" align="center" data-test-id="greetingsTitle">
            <Typo>How to invest with InvestEngine</Typo>
          </Typography>
          <Paper className="Empty_StepsRow" top={desktop ? 12 : 4} data-test-id="greetingsText">
            <Paper top={12} right={4} left={4}>
              <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
                <Column size={0}>
                  <ItemWithIcon
                    space={8}
                    icon={<Icon type="number_1" size={24} color={palette['content-on-background-default']} />}
                    content={
                      <Typography size={14} data-test-id="greetingsTextStep0">
                        <Nobr>
                          <Typo>Create a portfolio</Typo>
                        </Nobr>
                      </Typography>
                    }
                    contentVerticalAlign="center"
                  />
                </Column>
                <Column size={0}>
                  <Paper left={8}>
                    <Icon type="arrow-right-16" size={16} color={palette['content-on-background-default']} />
                  </Paper>
                </Column>
              </ColumnarLayout>
            </Paper>
            <Paper top={12} right={4} left={4}>
              <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
                <Column size={0}>
                  <ItemWithIcon
                    space={8}
                    icon={<Icon type="number_2" size={24} color={palette['content-on-background-default']} />}
                    content={
                      <Typography size={14} data-test-id="greetingsTextStep1">
                        <Nobr>
                          <Typo>Add cash</Typo>
                        </Nobr>
                      </Typography>
                    }
                    contentVerticalAlign="center"
                  />
                </Column>
                <Column size={0}>
                  <Paper left={8}>
                    <Icon type="arrow-right-16" size={16} color={palette['content-on-background-default']} />
                  </Paper>
                </Column>
              </ColumnarLayout>
            </Paper>
            <Paper top={12} right={4} left={4}>
              <ItemWithIcon
                space={8}
                icon={<Icon type="number_3" size={24} color={palette['content-on-background-default']} />}
                content={
                  <Typography size={14} data-test-id="greetingsTextStep2">
                    <Nobr>
                      <Typo>Start investing</Typo>
                    </Nobr>
                  </Typography>
                }
                contentVerticalAlign="center"
              />
            </Paper>
          </Paper>
        </Width>
      </Paper>
      <Paper top={desktop ? 48 : 32}>
        <Width size={desktop ? 13.75 : null} center>
          <Button mods={{ size: 'big block', color: 'green' }} data-test-id="addFirstPortfolioButton">
            <Link to={createPortfolioUrl}>Continue</Link>
          </Button>
        </Width>
      </Paper>
    </Paper>
  )
}

Empty.propTypes = {
  createPortfolioUrl: PropTypes.string,
}

export { Empty }
