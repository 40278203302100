import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useState, useActions, useCallback } from 'hooks'

import { features } from 'helpers/features'
import { goTo, urlTo } from 'helpers/router.js'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $quickStartsStore, finishQuickStartFx } from 'app/effector/quickStart'
import { fetchRecurringPaymentsFx, $recurringPaymentsStore } from 'app/effector/recurringPayments'

import { deleteDirectDebitSubscription as deleteSubscriptionActionCreator } from 'app/redux/actions/portfolios'
import { changeModalField as changeModalFieldActionCreator } from 'app/redux/actions/ui'

import { quickStartStatuses } from 'constants/quickStart'

const fetchRecurringPaymentsOnStopSubscriptionFx = attach({ effect: fetchRecurringPaymentsFx })

enum Status {
  INITIAL = 'INITIAL',
  SUCCESS = 'SUCCESS',
}

type UseStopSubscriptionInterface = {
  status: Status
  handleStopPayment: () => Promise<void>
  handleBackToForm: () => void
}

const useStopSubscription = (portfolioId: number, search?: string): UseStopSubscriptionInterface => {
  const { getDirectDebitByPortfolioId } = useUnit($recurringPaymentsStore)
  const directDebitSubscription = getDirectDebitByPortfolioId(portfolioId)
  const { getQuickStartByPortfolioId } = useUnit($quickStartsStore)
  const quickStart = getQuickStartByPortfolioId(portfolioId)
  const { nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)
  const isNominatedBankSupportsRecurringPayment = nominatedBankAccount?.bank?.recurring_payment_support

  const [status, setStatus] = useState(!directDebitSubscription ? Status.SUCCESS : Status.INITIAL)

  const [deleteSubscription, changeModalField] = useActions([
    deleteSubscriptionActionCreator,
    changeModalFieldActionCreator,
  ])

  const handleStopPayment = useCallback(async () => {
    if (!directDebitSubscription) {
      return
    }

    const stateAfterDelete: any = await deleteSubscription(directDebitSubscription.id)

    if (!stateAfterDelete.portfolios.error) {
      setStatus(Status.SUCCESS)
      changeModalField('directDebit', { monthlyPaymentAmount: null, monthlyPaymentDay: null })
      fetchRecurringPaymentsOnStopSubscriptionFx()

      if (quickStart?.status === quickStartStatuses.ACTIVE) {
        finishQuickStartFx(portfolioId)
      }
    }
  }, [directDebitSubscription, deleteSubscription, changeModalField, quickStart, portfolioId])

  const handleBackToForm = useCallback(() => {
    if (
      features.get('recurring-payments-release') &&
      isNominatedBankSupportsRecurringPayment &&
      status === Status.SUCCESS
    ) {
      goTo(urlTo('dashboard.portfolio', { id: portfolioId }), {
        replace: true,
      })
      return
    }

    const nextSearch = search ? search.replace(/[?&]promo=true/, '') : null

    goTo(urlTo(`dashboard.portfolio.options.direct-debit-form`, { id: portfolioId }, nextSearch), {
      replace: true,
      scrollToTop: false,
    })
  }, [portfolioId, search, status, isNominatedBankSupportsRecurringPayment])

  return {
    status,
    handleStopPayment,
    handleBackToForm,
  }
}

export { useStopSubscription, Status }
