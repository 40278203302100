import React, { Fragment } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

import { OnboardingProgressBar } from '../../../DIY/Portfolio/components/OnboardingProgressBar/OnboardingProgressBar'
import { type ManagedPortfolio } from '../../types'

import { FundYourPortfolio } from './components/FundYourPortfolio'
import { Notice } from './components/Notice'
import { GrowthProjections } from './GrowthProjections'
import { IncomeProjections } from './IncomeProjections'

import { presetTypes } from 'constants/goal'

type ProjectionsProps = {
  portfolio: ManagedPortfolio
  handleAmendPortfolioSelect: () => void
}

const Projections = ({ portfolio, handleAmendPortfolioSelect }: ProjectionsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const isIncomePortfolio = portfolio?.preset_type === presetTypes.INCOME
  const isGrowthProjectionsTwoColumns = desktop && !isIncomePortfolio
  const isNewPortfolio = parseFloat(portfolio.current_balance) <= 0 && !portfolio.first_topup

  const fundYourPortfolio = (
    <Paper top={20}>
      <FundYourPortfolio portfolio={portfolio} handleAmendPortfolioSelect={handleAmendPortfolioSelect} />
    </Paper>
  )

  const onboardingProgressBar = <OnboardingProgressBar portfolio={portfolio} substitute={fundYourPortfolio} />

  const fundOrProgressBar = !desktop && isNewPortfolio ? onboardingProgressBar : null

  const noticeNode = useMemo(() => {
    if (isNewPortfolio && isGrowthProjectionsTwoColumns) {
      return null
    }

    const notice = (
      <Paper top={desktop ? (isNewPortfolio ? 32 : 48) : 16}>
        <Notice presetType={portfolio?.preset_type} />
      </Paper>
    )

    return desktop ? notice : <SideSpacesCompensator size={1}>{notice}</SideSpacesCompensator>
  }, [desktop, isNewPortfolio, isGrowthProjectionsTwoColumns, portfolio?.preset_type])

  return (
    <Fragment>
      {fundOrProgressBar && (
        <Paper top={8} left={desktop ? 64 : 0} bottom={desktop ? 0 : 24}>
          {fundOrProgressBar}
        </Paper>
      )}
      {noticeNode}
      {isIncomePortfolio ? (
        <IncomeProjections portfolio={portfolio} />
      ) : (
        <GrowthProjections portfolio={portfolio} handleAmendPortfolioSelect={handleAmendPortfolioSelect} />
      )}
    </Fragment>
  )
}

export { Projections }
