import React from 'react'

import { Appearance } from './Appearance'
import { BusinessInfo } from './BusinessInfo'
import { Directors } from './Directors'
import { Fees } from './Fees'
import { HiddenPortfolios } from './HiddenPortfolios'
import { LoginSettings, TwoFactorAuthSetupStart } from './LoginSettings'
import { ManagedPortfolios, ManagedPortfolioAnswers } from './ManagedPortfolios'
import { Notifications } from './Notifications'
import { PersonalInfo } from './PersonalInfo'
import { Profile } from './Profile'
import { ReferralCode } from './ReferralCode'
import { SavingsPlans } from './SavingsPlans'
import twoFactorAuthSetup from './TwoFactorAuthSetup/routes'

const managedPortfoliosRoutes = [
  {
    index: true,
    isNewLayout: true,
    component: function Component(props) {
      return <ManagedPortfolios {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'questionnaire-answers',
    path: ':id/',
    component: function Component(props) {
      return <ManagedPortfolioAnswers {...props} />
    },
  },
]

const loginSettingsRoutes = [
  {
    index: true,
    isNewLayout: true,
    component: function Component(props) {
      return <LoginSettings {...props} />
    },
    childRoutes: [
      {
        module: 'change-password',
        path: 'change-password/',
      },
      {
        module: 'two-factor-auth',
        path: 'two-factor-auth/',
      },
      {
        module: 'phone-number',
        path: 'phone-number/',
      },
      {
        module: 'confirm-your-identity',
        path: 'confirm-your-identity/',
      },
      {
        module: 'confirm-phone-number',
        path: 'confirm-phone-number/',
      },
      {
        module: 'recovery-code',
        path: 'recovery-code/',
      },
    ],
  },
  {
    isNewLayout: true,
    module: 'two-factor-auth-setup-start',
    path: 'two-factor-auth-setup-start/',
    component: function Component(props) {
      return <TwoFactorAuthSetupStart {...props} />
    },
  },
]

const childRoutes = [
  {
    index: true,
    isNewLayout: true,
    component: function Component(props) {
      return <Profile {...props} />
    },
  },
  {
    module: 'login-settings',
    path: 'login-settings/',
    childRoutes: loginSettingsRoutes,
    indexRoute: loginSettingsRoutes.find((route) => route.index),
  },
  {
    module: 'appearance',
    path: 'appearance/',
    headline: 'Appearance',
    isNewLayout: true,
    component: function Component(props) {
      return <Appearance {...props} />
    },
    childRoutes: [
      {
        module: 'theme',
        path: 'theme/',
      },
    ],
  },
  {
    module: 'notifications',
    path: 'notifications/',
    headline: 'Notifications',
    isNewLayout: true,
    component: function Component(props) {
      return <Notifications {...props} />
    },
  },
  {
    module: 'fees',
    path: 'fees/',
    headline: 'Fees',
    isNewLayout: true,
    component: function Component(props) {
      return <Fees {...props} />
    },
  },
  {
    module: 'hidden-portfolios',
    path: 'hidden-portfolios/',
    headline: 'Hidden Portfolios',
    isNewLayout: true,
    component: function Component(props) {
      return <HiddenPortfolios {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'savings-plans',
    path: 'savings-plans/',
    component: function Component(props) {
      return <SavingsPlans {...props} />
    },
    childRoutes: [
      {
        module: 'set-up-instant-bank',
        path: 'set-up-instant-bank/',
      },
      {
        module: 'new-bank',
        path: 'new-bank/',
      },
    ],
  },
  {
    isNewLayout: true,
    module: 'referral',
    path: 'referral-code/',
    component: function Component(props) {
      return <ReferralCode {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'personal-info',
    path: 'personal-info/',
    component: function Component(props) {
      return <PersonalInfo {...props} />
    },
    childRoutes: [
      {
        module: 'change-address',
        path: 'change-address/',
      },
      {
        module: 'change-email',
        path: 'change-email/',
      },
      {
        module: 'confirm-your-identity',
        path: 'confirm-your-identity/',
      },
      {
        module: 'confirm-phone-number',
        path: 'confirm-phone-number/',
      },
      {
        module: 'enter-promocode',
        path: 'enter-promocode/',
      },
    ],
  },
  {
    module: 'managed-portfolios',
    path: 'managed-portfolios/',
    childRoutes: managedPortfoliosRoutes,
    indexRoute: managedPortfoliosRoutes.find((route) => route.index),
  },
  {
    isNewLayout: true,
    module: 'business-info',
    path: 'business-info/',
    component: function Component(props) {
      return <BusinessInfo {...props} />
    },
    childRoutes: [
      {
        module: 'change-company-address',
        path: 'change-company-address/',
      },
    ],
  },
  {
    isNewLayout: true,
    module: 'directors',
    path: 'directors-and-shareholders/',
    component: function Component(props) {
      return <Directors {...props} />
    },
  },

  twoFactorAuthSetup,
]

const routes = {
  module: 'user-profile',
  path: 'profile/',
  childRoutes,
  indexRoute: childRoutes.find((route) => route.index),
}

export { routes }
