import React, { forwardRef } from 'react'

import { useState, useCallback } from 'hooks'

import { autocompletePostcode, searchByPostcode } from 'helpers/postcodes.js'

import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelTextWithAction } from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'

import { Typography } from 'components/atoms/Typography'

import AddressList from 'components/organisms/AddressList/AddressList.jsx'

import './AddressSearch.css'

type Country = {
  country: string
  country_id: number
}

type AddressListItems = {
  city: string
  country: number | undefined
  countryName: string
  postcode: string
  street: string
  street2: string
}

type Address = {
  street: string
  street2: string
  postcode: string
  country: number
  countryFull?: Country
  city: string
}

type AddressSearchProps = {
  address: Address
  countries: Country[]
  inputMods: Record<string, string>
  mediaQueries: Record<string, boolean>
  onAddressFieldChange: (field: string, value: string | object | number | null) => void
  onSwitchToManualEditMode: () => void
}

const AddressSearch = forwardRef<HTMLDivElement, AddressSearchProps>(function AddressSearch({
  address,
  countries,
  inputMods,
  mediaQueries,
  onAddressFieldChange,
  onSwitchToManualEditMode,
}: AddressSearchProps) {
  const [inputPostcode, setInputPostcode] = useState<string | null>(null)
  const [addressListVisible, setAddressListVisible] = useState<boolean>(false)
  const [addressListItems, setAddressListItems] = useState<AddressListItems[]>([])
  const { street, street2, postcode, countryFull, city } = address
  const { desktop } = mediaQueries

  const handlePostcodeChange = useCallback(
    (_event, value: string) => {
      setInputPostcode(value)
      if (postcode !== null && value !== postcode) {
        onAddressFieldChange('street', null)
        onAddressFieldChange('street2', null)
        onAddressFieldChange('postcode', null)
        onAddressFieldChange('country', null)
        onAddressFieldChange('city', null)
        setAddressListItems([])
        setAddressListVisible(false)
      }
    },
    [onAddressFieldChange, setAddressListItems, setAddressListVisible, postcode],
  )

  const handlePostcodeSelect = useCallback(
    (value: string) => {
      onAddressFieldChange('postcode', value)
      setInputPostcode(value)

      searchByPostcode(value).then((addressListItems: AddressListItems[]) => {
        setAddressListItems(
          addressListItems.map((address: AddressListItems) => ({
            ...address,
            country: countries.find((country) => country.country.toLowerCase() === address.countryName.toLowerCase())
              ?.country_id,
          })),
        )
        setAddressListVisible(true)
      })
    },
    [onAddressFieldChange, setInputPostcode, setAddressListItems, setAddressListVisible, countries],
  )

  const handleAddressSelect = useCallback(
    (address: Address) => {
      const { street, street2, city, country } = address

      onAddressFieldChange('street', street)
      onAddressFieldChange('street2', street2)
      onAddressFieldChange('country', country)
      onAddressFieldChange('city', city)
      setAddressListItems([])
      setAddressListVisible(false)
      setInputPostcode(null)
    },
    [onAddressFieldChange, setAddressListItems, setAddressListVisible, setInputPostcode],
  )

  return (
    <div className="AddressSearch" data-test-id="addressSearch">
      <Label preventFocusOnLabelClick>
        <LabelTextWithAction>
          Postcode
          <Link onClick={onSwitchToManualEditMode} data-test-id="addressModeSwitch">
            Enter address manually
          </Link>
        </LabelTextWithAction>
        <Input
          mods={inputMods}
          name="search-by-postal"
          type="select-remote"
          remoteOptions={autocompletePostcode}
          onChange={handlePostcodeChange}
          onSelect={handlePostcodeSelect}
          relativeList={!desktop}
          data-test-id="addressPostcodeInput"
        >
          {inputPostcode}
        </Input>
      </Label>
      {addressListVisible && (
        <Label>
          Choose address
          <AddressList items={addressListItems} onSelect={handleAddressSelect} />
        </Label>
      )}
      {street && (
        <div className="AddressSearch-Selected">
          <Typography>
            <Typo>{street}</Typo>
          </Typography>
          <Typography>
            <Typo>{street2}</Typo>
          </Typography>
          <Typography>
            <Typo>{`${city} ${postcode}, ${countryFull?.country}`}</Typo>
          </Typography>
          <Link className="AddressSearch-EditLink" onClick={onSwitchToManualEditMode}>
            Edit address
          </Link>
        </div>
      )}
    </div>
  )
})

export { AddressSearch }
