import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { Preloader } from 'components/molecules/Preloader'

import { TsCsModal } from 'app/pages/Dashboard/Goals/DIY/SharePortfolio/components/TsCsModal'

import { ShareModal } from '../components/ShareModal'

import { Desktop, DesktopNotAvailable } from './Desktop'
import { useSharedPortfolio } from './hooks/useSharedPortfolio'
import { Mobile, MobileNotAvailable } from './Mobile'

import './SharedPortfolio.css'

type SharedPortfolioProps = {
  params: { hash: string }
  location: { query: { descriptionExpanded: boolean } }
}

const SharedPortfolio = ({
  params: { hash: portfolioHash },
  location: {
    query: { descriptionExpanded },
  },
}: SharedPortfolioProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    isAuthorized,
    isAvailable,
    isLoading,
    isPromoEnabled,
    title,
    description,
    securities,
    equities,
    regions,
    sectors,
    equityTypes,
    assets,
    isShareModalOpened,
    closeShareModal,
    shareUrl,
    shareTitle,
    handleShare,
    handleGetStarted,
    handleSecurityClick,
    showTsCsModal,
    handleOpenTsCsModal,
    handleCloseTsCsModal,
    isDescriptionExpanded,
    handleDescriptionExpand,
  } = useSharedPortfolio(portfolioHash, descriptionExpanded)

  if (!isAvailable) {
    const notAvailableProps = {
      isPromoEnabled,
      isAuthorized,
      handleGetStarted,
      handleOpenTsCsModal,
    }

    return (
      <Fragment>
        {desktop ? <DesktopNotAvailable {...notAvailableProps} /> : <MobileNotAvailable {...notAvailableProps} />}

        <TsCsModal isPromoEnabled={isPromoEnabled} show={showTsCsModal} handleClose={handleCloseTsCsModal} />
        <GatewayDest name="modals" />
        <GatewayDest name="toasts" />
      </Fragment>
    )
  }

  const props = {
    isPromoEnabled,
    isAuthorized,
    title,
    description,
    securities,
    equities,
    regions,
    sectors,
    equityTypes,
    assets,
    handleShare,
    handleGetStarted,
    handleSecurityClick,
    handleOpenTsCsModal,
    isDescriptionExpanded,
    handleDescriptionExpand,
  }

  return (
    <Fragment>
      {isLoading ? null : <Fragment>{desktop ? <Desktop {...props} /> : <Mobile {...props} />}</Fragment>}

      <ShareModal open={isShareModalOpened} shareUrl={shareUrl} shareTitle={shareTitle} onClose={closeShareModal} />
      <TsCsModal isPromoEnabled={isPromoEnabled} show={showTsCsModal} handleClose={handleCloseTsCsModal} />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
      <Preloader loading={isLoading} size="big" zIndex />
    </Fragment>
  )
}

export { SharedPortfolio }
