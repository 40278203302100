import capitalize from 'lodash/capitalize'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import pickBy from 'lodash/pickBy'
import uniq from 'lodash/uniq'
import moment from 'moment'

import { sendErrorOnce } from 'helpers/errorLogging'
import { format as formatMoney } from 'helpers/money'
import { isValid as isPhoneValid } from 'helpers/phone'
import { snakeToCamel } from 'helpers/snakeToCamel'

import { RECURRING_PAYMENT_MOTHLY_MIN_AMOUNT } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'

export const emailRegex =
  // eslint-disable-next-line no-control-regex
  // https://emailregex.com/
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const emailRegexErrorMessage = 'Invalid email address format'

export function emailRules(email, canBeEmpty = false, prefix = 'Email') {
  let ruleSet = {
    rules: [emailRegex.test(email)],
    errors: [emailRegexErrorMessage],
  }

  if (!canBeEmpty) {
    ruleSet = {
      rules: [(email || '').length > 0, ...ruleSet.rules],
      errors: [`${prefix} can’t be empty`, ...ruleSet.errors],
    }
  }

  return ruleSet
}

export function nameRules(name, fieldName, required = true) {
  if (!name) {
    name = ''
  }

  return {
    rules: [
      !(name.length < 1 && required),
      name.length > 0 ? /\p{Script=Latin}+/u.test(name) : true, // https://javascript.info/regexp-unicode#unicode-properties-p
    ],
    errors: [capitalize(`${fieldName ?? ''} can’t be empty`.trim()), 'Invalid character'],
  }
}

export function birthdayRules(birthday) {
  const isMasked = birthday?.includes('*')

  if (isMasked) return { rules: [true], errors: [] }

  return {
    rules: [
      !(birthday === ''),
      moment(birthday, 'YYYY-MM-DD', true).isValid(),
      moment(new Date()).diff(moment(birthday, 'YYYY-MM-DD'), 'years') >= 18,
      moment(new Date()).diff(moment(birthday, 'YYYY-MM-DD'), 'years') < 99,
    ],
    errors: [
      'Date of birth can’t be empty',
      'Invalid date',
      'You must be at least 18 years old',
      'You must be younger than 100 years old',
    ],
  }
}

export function phoneRules(phone, additionalRules = {}) {
  const rules = [phone !== '', isPhoneValid(phone)]
  const errors = ['Phone can’t be empty', 'Phone number must be in valid international format']

  return {
    rules: [...rules, ...(additionalRules.rules || [])],
    errors: [...errors, ...(additionalRules.errors || [])],
  }
}

export function monthlyInvestmentRules(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  monthly_deposit,
  { optional, limitMaximumAmount, isRecurringPaymentValidation },
) {
  if (typeof optional === 'undefined') {
    sendErrorOnce('No ‘optional’ passed to ‘monthlyInvestmentRules’')

    optional = false
  }

  if (typeof limitMaximumAmount === 'undefined') {
    sendErrorOnce('No ‘limitMaximumAmount’ passed to ‘monthlyInvestmentRules’')

    limitMaximumAmount = true
  }

  monthly_deposit = monthly_deposit ?? 0

  const MIN_DIRECT_DEBIT_PAYMENT_AMOUNT = 50
  const MAX_DIRECT_DEBIT_PAYMENT_AMOUNT = 5000
  const minimumAmount = isRecurringPaymentValidation
    ? RECURRING_PAYMENT_MOTHLY_MIN_AMOUNT
    : MIN_DIRECT_DEBIT_PAYMENT_AMOUNT

  let lessThanMinimum = parseFloat(monthly_deposit) < minimumAmount

  if (optional) {
    lessThanMinimum = monthly_deposit === 0 ? false : lessThanMinimum
  }

  if (isRecurringPaymentValidation) {
    return {
      rules: [!lessThanMinimum],
      errors: [`Must be at least ${formatMoney(RECURRING_PAYMENT_MOTHLY_MIN_AMOUNT)}`],
    }
  }

  const moreThanMaximum = limitMaximumAmount ? parseFloat(monthly_deposit) > MAX_DIRECT_DEBIT_PAYMENT_AMOUNT : false

  const monthlyDepositIntString = `${parseInt(monthly_deposit, 0)}`
  const longerThanEightSymbols = monthlyDepositIntString.length > 8

  return {
    rules: [!lessThanMinimum, !moreThanMaximum, !longerThanEightSymbols],
    errors: [
      `Must be at least ${formatMoney(MIN_DIRECT_DEBIT_PAYMENT_AMOUNT)}`,
      `Maximum amount is ${formatMoney(MAX_DIRECT_DEBIT_PAYMENT_AMOUNT)}`,
      'Must be less than 9 digits',
    ],
  }
}

export function passwordRules(password, additionalRules = {}) {
  password = password ?? ''
  const rules = [password, /^\S*$/.test(password), password.length >= 10, /\d/.test(password), /[A-Z]/.test(password)]
  const errors = [
    'Password cannot be empty',
    'Password cannot contain space character',
    'Password must be at least 10 characters long',
    'Password must contain at least 1 digit',
    'Password must contain at least 1 capital latin letter',
  ]

  return {
    rules: [...rules, ...(additionalRules.rules || [])],
    errors: [...errors, ...(additionalRules.errors || [])],
  }
}

export function validate(rules, forceValidation) {
  rules = isArray(rules) ? rules : [rules]

  return forceValidation || rules.every((r) => Boolean(r))
}

export function additionalFieldsToValidation(additionalFields) {
  if (!additionalFields) {
    return null
  } else {
    const obj = {}
    Object.keys(additionalFields).forEach((position) => {
      Object.keys(additionalFields[position]).forEach((f) => {
        obj[f] = {
          rules: additionalFields[position][f].rules,
          errors: additionalFields[position][f].errors,
        }
      })
    })
    return obj
  }
}

export function combineErrors() {
  const errors = Object.values(pickBy(arguments, isObject))

  return errors.length < 1
    ? {}
    : errors.reduce((memoErroObj, nextErrObj) => {
        const joinedErrObj = {}
        const fields = uniq([...Object.keys(memoErroObj), ...Object.keys(nextErrObj)])
        fields.forEach((f) => {
          joinedErrObj[f] = {}
          ;[memoErroObj[f], nextErrObj[f]].forEach((fieldObj) => {
            if (isObject(fieldObj)) {
              joinedErrObj[f].rules = [...(joinedErrObj[f].rules || []), ...fieldObj.rules]
              joinedErrObj[f].errors = [...(joinedErrObj[f].errors || []), ...fieldObj.errors]
            }
          })
        })
        return joinedErrObj
      }, {})
}

export function backendErrorsToObj(errors, convertToCamel = false, convertFunc) {
  if (!errors || (!errors.response && !errors.data)) {
    return null
  }
  if (errors.response) {
    errors = errors.response
  }
  if (errors.data) {
    errors = errors.data
  }
  if (isObject(errors)) {
    let errObj = {}

    Object.keys(errors).forEach((key) => {
      if (errors[key]?.length > 0) {
        const transformError = (message) => {
          // TODO: it breaks scope.setExtras in sendError
          // if (message === 'This email is already in use') {
          //   return (
          //     <span>
          //       This email is already in use <Link to={urlTo('logout', null, { next: urlTo('login') })}>Sign in?</Link>
          //     </span>
          //   )
          // }

          return message
        }
        const isErrorsArray = isArray(errors[key])
        errors[key] = isErrorsArray ? errors[key].map(transformError) : transformError(errors[key])
        const newKeyName = convertToCamel ? snakeToCamel(key) : key
        errObj[newKeyName] = {}
        errObj[newKeyName].errors = isErrorsArray ? errors[key] : [errors[key]]
        errObj[newKeyName].rules = isErrorsArray
          ? Array.apply(null, Array(errors[key].length)).map(() => false)
          : [false]
      }
    })

    if (isFunction(convertFunc)) {
      errObj = convertFunc(errObj)
    }

    return errObj
  }

  return null
}

export function bulkValidate(mapOfRules) {
  return Object.values(mapOfRules)
    .map((field) => validate(field.rules))
    .every((result) => result)
}

export function bulkValidateList(validationsList) {
  return validationsList.every((validateRules) => bulkValidate(validateRules))
}

export function getBrokenRule(rules) {
  return isArray(rules) ? rules.findIndex((r) => !r) : null
}

export function createAddressesValidationList(addresses, allowEmptyMonthsAtAddress) {
  const createValidation = (address, index) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { street, postcode, country, city, months_at_address } = address

    return {
      street: { rules: [street], errors: [' Address can’t be empty'] },
      postcode: { rules: [postcode], errors: ['Postcode can’t be empty'] },
      country: { rules: [country], errors: ['Country can’t be empty'] },
      city: { rules: [city], errors: ['City can’t be empty'] },
      isUK: { rules: [index === 0 ? address?.isUK() : true], errors: [''] },
      months_at_address: {
        rules: [allowEmptyMonthsAtAddress || months_at_address > 0, months_at_address < 1200],
        errors: ['Time at this address can’t be empty', 'Max value is 99'],
      },
    }
  }

  return addresses.list.map(createValidation)
}

export function getAddressesValidationFlatObject(addressesValidationsList = []) {
  return [...addressesValidationsList].reduce((accumulator, address, index) => {
    const newAddress = { ...address }

    for (const [key, value] of Object.entries(newAddress)) {
      newAddress[`${index}_${key}`] = value
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newAddress[key]
    }

    return { ...accumulator, ...newAddress }
  }, {})
}
