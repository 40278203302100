import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { Stack } from 'components/atoms/Stack'
import Width from 'components/_old/Width/Width'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'

const NoBankAccounts = ({ header, instantBankTransfer, manualBankTransfer, savingsPlan }) => {
  const { desktop } = useMediaQueries()

  return (
    <OptionsLayout
      header={header}
      content={
        <Width size={desktop ? 25 : null} center>
          <Typography size={24} lineHeight="small" weight="semibold" align="center">
            <Typo>How do you want to add cash</Typo>
          </Typography>
          <Paper top={desktop ? 24 : 16} bottom={desktop ? 24 : null}>
            <Paper top={desktop ? 24 : 16}>
              <Stack vertical={desktop ? 24 : 16}>
                {instantBankTransfer}
                {manualBankTransfer}
                {savingsPlan}
              </Stack>
            </Paper>
          </Paper>
        </Width>
      }
      button={null}
    />
  )
}

NoBankAccounts.propTypes = {
  header: PropTypes.node.isRequired,
  instantBankTransfer: PropTypes.node.isRequired,
  manualBankTransfer: PropTypes.node.isRequired,
  savingsPlan: PropTypes.node.isRequired,
}

export { NoBankAccounts }
