import { useState, useEffect } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

type useDiyPortfolioTabsArguments = {
  portfolioId: string
  location: {
    query?: Record<string, string>
  }
  routes: Array<{
    [key: string]: unknown
    module: string
  }>
  selectedPeriod: string
  handlePeriodSelect: (value: string) => void
}

type useDividendsPortfolioTabsReturn = {
  activeChart: string
  setChartTab: (value: string) => void
  setPeriodTab: (value: string) => void
}

const useDiyPortfolioTabs = ({
  portfolioId,
  location,
  routes,
  selectedPeriod,
  handlePeriodSelect,
}: useDiyPortfolioTabsArguments): useDividendsPortfolioTabsReturn => {
  const [activeChart, setActiveChart] = useState(location?.query?.chart ?? 'return')

  const addParamsToQuery = (...params): void => {
    const paramsObject = params.reduce((result, current) => {
      return { ...result, ...current }
    }, {})

    goTo(urlTo(`dashboard.portfolio`, { id: portfolioId }, { ...location.query, ...paramsObject }))
  }

  const setPeriodTab = (period: string): void => {
    handlePeriodSelect(period)
    addParamsToQuery({ period })
  }

  const setChartTab = (tabName: string): void => {
    setActiveChart(tabName)
    addParamsToQuery({ chart: tabName })
  }

  useEffect(() => {
    // set active chart query param when it is not present coming to portfolio page
    if (routes.length === 4 && routes[2].module === 'portfolio') {
      const period = location?.query?.period ?? selectedPeriod
      const chart = location?.query?.chart ?? activeChart

      addParamsToQuery({ chart }, { period })
      handlePeriodSelect(period)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes.length])

  return {
    activeChart,
    setChartTab,
    setPeriodTab,
  }
}

export { useDiyPortfolioTabs }
