import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { format } from 'helpers/money'
import { palette } from 'helpers/palette/'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ControlledInput } from 'components/atoms/Forms/ControlledInput'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack/'
import { Typography } from 'components/atoms/Typography'

import { Preloader } from 'components/molecules/Preloader'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { OptionsLayout } from '../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../Options/OptionsNavigationBar'
import { PortfolioCard } from '../components/PortfolioCard'
import { useMoveCashAmount } from '../hooks'

const MoveCashSectionLabel = ({ children }): React.ReactElement => (
  <Paper bottom={8}>
    <Typography size={14} color="additional" lineHeight="small">
      <Typo>{children}</Typo>
    </Typography>
  </Paper>
)

type MoveCashAmountProps = {
  portfolio: Portfolio
  params: {
    id: string
    to: string
  }
  action: string
  location: { query?: { back?: string } }
  handleClose: () => void
}

const MoveCashAmount = withRouter(
  ({ action, params, location: { query = {} } = {}, handleClose }: MoveCashAmountProps): React.ReactElement => {
    const { desktop } = useMediaQueries()

    const portfolioId = parseInt(params.id, 10)
    const portfolioIdTo = parseInt(params.to, 10)

    const moveCashToId = action === 'in' ? portfolioId : portfolioIdTo
    const moveCashFromId = action === 'in' ? portfolioIdTo : portfolioId

    const {
      isBusiness,
      portfolioTo,
      portfolioFrom,
      portfoliosCount,
      isTransferFromManaged,
      isTransferFromGiaToIsa,
      isTransferFromGiaToSipp,
      isTransferFromIsaToGia,
      transferableCash,
      initialDepositMin,
      hasToMoveWholeAvailableBalance,
      isLoading,
      control,
      errors,
      validation,
      isValid,
      handleSave,
      handleBack,
      showSippTransferConfirmation,
      isSippTransferConfirmed,
      handleConfirmSippTransfer,
      handleContinueSippTransfer,
    } = useMoveCashAmount(moveCashFromId, moveCashToId, query?.back, action)

    const shouldShowNotifications =
      hasToMoveWholeAvailableBalance ||
      isTransferFromGiaToIsa ||
      isTransferFromGiaToSipp ||
      isTransferFromIsaToGia ||
      isTransferFromManaged

    const moveCashAmountTitle = (): React.ReactElement => {
      switch (action) {
        case 'in':
          return <Typo>Move cash in</Typo>
        case 'out':
          return <Typo>Move cash out</Typo>
        default:
          return <Typo>Move cash</Typo>
      }
    }

    const moveCashAmountFrom = (): React.ReactElement => {
      if (action === 'in') {
        return (
          <PortfolioCard
            isBusiness={isBusiness}
            onClick={portfoliosCount > 2 ? handleBack : null}
            portfolio={portfolioFrom}
            data-test-id="moveCashFromPortfolio"
          />
        )
      }
      return <PortfolioCard isBusiness={isBusiness} portfolio={portfolioFrom} data-test-id="moveCashFromPortfolio" />
    }

    const moveCashAmountTo = (): React.ReactElement => {
      if (action === 'in') {
        return <PortfolioCard isBusiness={isBusiness} portfolio={portfolioTo} data-test-id="moveCashToPortfolio" />
      }
      return (
        <PortfolioCard
          isBusiness={isBusiness}
          onClick={portfoliosCount > 2 ? handleBack : null}
          portfolio={portfolioTo}
          data-test-id="moveCashToPortfolio"
        />
      )
    }

    const sippTransferConfirmNode = (
      <Fragment>
        <CheckboxWithLabel
          size="smaller"
          label={
            <Typography>
              <Typo>I confirm I have read and understood the above warning</Typo>
            </Typography>
          }
          onChange={handleConfirmSippTransfer}
          checked={isSippTransferConfirmed}
          data-test-id="sippMoveCashWarningCheckbox"
        />
        <Paper top={16}>
          <Button
            type="submit"
            mods={{ size: 'big block' }}
            disabled={!isSippTransferConfirmed}
            data-test-id="sippWarningConfirmButton"
          >
            Confirm
          </Button>
        </Paper>
      </Fragment>
    )

    const content = showSippTransferConfirmation ? (
      <Width size={24} center>
        <Paper top={desktop ? 32 : 0}>
          <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
            <Typo>
              You are moving cash
              <br />
              to Pension portfolio
            </Typo>
          </Typography>
        </Paper>
        <Paper top={32}>
          <ItemWithIcon
            space={12}
            icon={<Icon type="information-24" color="inherit" size={24} inline />}
            iconVerticalAlign="top"
            contentVerticalAlign="top"
            content={
              <Typography size={14}>
                <Typo>
                  This transfer will be considered a Net Contribution and we’ll claim 20% basic rate relief for you from
                  HMRC.
                </Typo>
              </Typography>
            }
          />
          <Paper top={desktop ? 32 : 24}>
            <ItemWithIcon
              space={12}
              icon={<Icon type="information-24" color="inherit" size={24} inline />}
              iconVerticalAlign="top"
              contentVerticalAlign="top"
              content={
                <Typography size={14}>
                  <Typo>
                    All contributions to your SIPP, together with contributions to any other pensions you may have count
                    towards your Annual Limit. You will typically not be able to access these funds until retirement.
                  </Typo>
                </Typography>
              }
            />
          </Paper>
        </Paper>
      </Width>
    ) : (
      <Width size={24} center>
        <Paper bottom={32} top={8}>
          <MoveCashSectionLabel>From</MoveCashSectionLabel>
          {moveCashAmountFrom()}
        </Paper>
        <Paper bottom={56}>
          <MoveCashSectionLabel>To</MoveCashSectionLabel>
          {moveCashAmountTo()}
        </Paper>
        <ControlledInput name="amount" control={control} errors={errors} rules={validation}>
          {({ value, valid, errorMessage, handleChange, handleFocus, handleBlur }) => (
            <Fragment>
              <Label valid={valid} errorMessage={errorMessage} data-test-id="buySumField">
                Amount
                <Typography size={24}>
                  <Input
                    name="value"
                    type="money"
                    placeholder="£"
                    mods={{ color: 'blue_to_white', size: 'small' }}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    withFloat
                  >
                    {value}
                  </Input>
                </Typography>
              </Label>
              <Paper top={4}>
                <Typography size={12} color="minor" lineHeight="small">
                  <Typo>
                    {isTransferFromManaged ? 'Available balance' : 'Available cash'} {format(transferableCash, true)}
                  </Typo>
                </Typography>
              </Paper>
            </Fragment>
          )}
        </ControlledInput>
        {shouldShowNotifications && (
          <Paper top={32}>
            <Stack vertical={16}>
              {isTransferFromGiaToIsa && (
                <ItemWithIcon
                  space={12}
                  icon={<Icon type="information-24" color="inherit" size={24} inline />}
                  iconVerticalAlign="top"
                  contentVerticalAlign="top"
                  content={
                    <Typography size={12} data-test-id="isaLimitNotice">
                      <Typo>
                        Transferring cash from a non-ISA to an ISA is subject to the annual ISA subscription limit.
                      </Typo>
                    </Typography>
                  }
                />
              )}
              {isTransferFromGiaToSipp && (
                <ItemWithIcon
                  space={12}
                  icon={<Icon type="information-24" color="inherit" size={24} inline />}
                  iconVerticalAlign="top"
                  contentVerticalAlign="top"
                  content={
                    <Typography size={12} data-test-id="sippNetContributionNotice">
                      <Typo>
                        Moving cash into a pension portfolio. This transfer will be considered a Net Contribution and
                        you will typically not be able to access these funds until retirement.
                      </Typo>
                    </Typography>
                  }
                />
              )}
              {isTransferFromIsaToGia && (
                <ItemWithIcon
                  space={12}
                  icon={<Icon type="information-24" color={palette['status-error']} size={24} inline />}
                  iconVerticalAlign="top"
                  contentVerticalAlign="top"
                  content={
                    <Typography size={12} data-test-id="isaLimitLossNotice">
                      <Typo>
                        Any cash transferred from an ISA portfolio to a non-ISA portfolio will lose its tax-free status.
                      </Typo>
                    </Typography>
                  }
                />
              )}
              {isTransferFromManaged && (
                <ItemWithIcon
                  space={12}
                  icon={<Icon type="information-24" color="inherit" size={24} inline />}
                  iconVerticalAlign="top"
                  contentVerticalAlign="top"
                  content={
                    <Typography size={12} data-test-id="saleOfStockNotice">
                      <Typo>
                        This transfer will trigger the sale of stock. Due to market fluctuations the transfer value is
                        therefore not guaranteed.
                      </Typo>
                    </Typography>
                  }
                />
              )}
              {hasToMoveWholeAvailableBalance && (
                <ItemWithIcon
                  space={12}
                  icon={<Icon type="information-24" color="inherit" size={24} inline />}
                  iconVerticalAlign="top"
                  contentVerticalAlign="top"
                  content={
                    <Typography size={12} data-test-id="fullSellNotice">
                      <Typo>
                        If your transfer reduces your portfolio balance below the {format(initialDepositMin)} minimum,
                        we will sell all your investments and move the total balance out of this portfolio.
                      </Typo>
                    </Typography>
                  }
                />
              )}
            </Stack>
          </Paper>
        )}
      </Width>
    )

    return (
      <form onSubmit={(showSippTransferConfirmation ? handleContinueSippTransfer : handleSave) as () => void}>
        <Preloader loading={isLoading} background="background" delay={400} zIndex absolute />
        <OptionsLayout
          header={
            <OptionsNavigationBar
              leftPartText="Back"
              onLeftPartClick={handleBack}
              rightPartText="Close"
              onRightPartClick={handleClose}
            >
              {moveCashAmountTitle()}
            </OptionsNavigationBar>
          }
          content={content}
          button={
            showSippTransferConfirmation ? (
              sippTransferConfirmNode
            ) : (
              <Button type="submit" mods={{ size: 'big block' }} disabled={!isValid} data-test-id="confirmButton">
                Confirm
              </Button>
            )
          }
          data-test-id="moveCashModal"
        />
      </form>
    )
  },
)

MoveCashSectionLabel.propTypes = {
  children: PropTypes.string.isRequired,
}

MoveCashAmount.propTypes = {
  handleClose: PropTypes.func.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
}

export { MoveCashAmount }
