import { nullable, type Nullable, int, date, enumValue, float } from 'app/effector/model'

import { type PortfolioPointOfInterestDTO } from '../../api'

enum PortfolioPointOfInterestType {
  BUY = 'BUY',
  SELL = 'SELL',
}

class PortfolioPointOfInterest {
  readonly type: Nullable<PortfolioPointOfInterestType>
  readonly date: Nullable<Date>
  readonly data: {
    transactions_quantity: Nullable<number>
    total_value: Nullable<number>
  }

  constructor(dto: Partial<PortfolioPointOfInterestDTO> | Partial<PortfolioPointOfInterest>) {
    this.type = nullable(enumValue)(dto.type, PortfolioPointOfInterestType)
    this.date = nullable(date)(dto.date, true)
    this.data = {
      transactions_quantity: nullable(int)(dto.data?.transactions_quantity),
      total_value: nullable(float)(dto.data?.total_value),
    }
  }
}

export { PortfolioPointOfInterest, PortfolioPointOfInterestType }
