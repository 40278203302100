import React from 'react'

import { attach } from 'effector'

import { urlTo, goTo } from 'helpers/router.js'

import { nominateBankAccountFx } from 'app/effector/bank-accounts'
import type { BankAccount } from 'app/effector/bank-accounts/models/bank'

import { showFailToast } from 'app/redux/actions/ui'

import { ContextMenu } from 'components/molecules/ContextMenu/ContextMenu'

import { bankAccountStates } from 'constants/bankAccounts.js'

const attachedNominateBankAccountFx = attach({ effect: nominateBankAccountFx })

attachedNominateBankAccountFx.fail.watch(({ error }) => {
  showFailToast(error?.response?.data?.errors?.[0]?.message ?? 'Something went wrong')
})

type BankAccountContextMenuProps = {
  children: React.ReactNode
  bankAccount: BankAccount
}

const BankAccountContextMenu = ({ children, bankAccount }: BankAccountContextMenuProps): React.ReactNode => {
  const handleNominateBamkAccount = (): void => {
    goTo(
      urlTo(
        'dashboard.user-profile.savings-plans',
        {},
        { changeNominatedBankAccount: true, bankAccountId: bankAccount.id },
      ),
    )
  }

  const items = [
    {
      title: 'Nominate for withdrawals',
      onClick: handleNominateBamkAccount,
      active: bankAccount.state === bankAccountStates.APPROVED && !bankAccount.is_nominated,
    },
  ].filter((item) => item.active)

  if (!items.length) return children

  return <ContextMenu items={items}>{children}</ContextMenu>
}

export { BankAccountContextMenu }
