import React from 'react'
import { withRouter } from 'react-router'

import { useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { Modal } from 'components/molecules/Modal'

import { NewBank } from 'app/pages/Dashboard/Goals/NewBank/NewBank.jsx'
import type { RouteProps } from 'app/pages/Dashboard/UserProfile/types'

type NewBankModalProps = {
  routes: RouteProps[]
  location: {
    query?: Record<string, string>
  }
}

const NewBankModal = withRouter(({ routes, location }: NewBankModalProps): React.ReactElement => {
  const currentRouteModule = routes[routes.length - 1].module
  const isOpen = currentRouteModule === 'new-bank'

  const nextUrl = urlTo('dashboard.user-profile.savings-plans')

  const handleClose = useCallback(() => {
    goTo(nextUrl)
  }, [nextUrl])

  return (
    <Modal open={isOpen} onClose={handleClose} close={null}>
      <NewBank location={location} nextUrl={nextUrl} />
    </Modal>
  )
})

export { NewBankModal }
