import * as React from 'react'
import { Fragment } from 'react'

import { formatMinimalPercent, format as formatMoney } from 'helpers/money'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import { Typo } from 'components/_old/Typo/Typo'

import { LineChart } from 'components/atoms/LineChart'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type DistributionChartProps = {
  showTarget: boolean
  name?: string
  actualWeight?: number
  targetWeight?: number
  totalAmount: number
  actualAmount?: number
  distribution: Array<{ value: number; color: string }>
  selectedPortfolio: Portfolio
}

const DistributionChart = ({
  showTarget,
  name = '',
  actualWeight = 0,
  targetWeight = 0,
  totalAmount,
  actualAmount,
  distribution,
  selectedPortfolio,
}: DistributionChartProps): React.ReactElement => {
  const actualWeightsPercent = formatMinimalPercent(actualWeight)
  const targetWeightsPercent = formatMinimalPercent(targetWeight)
  const restWeightsPercent = formatMinimalPercent(100 - actualWeight)
  const restWeightAmount = totalAmount - actualAmount

  return (
    <Fragment>
      <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'flex-end' }}>
        <Column size={1}>
          <Typography size={32} lineHeight="small" weight="semibold" data-test-id="singleHoldingActualWeightsPercent">
            <Typo>{actualWeightsPercent}</Typo>
            {showTarget && (
              <Typography
                tag="span"
                size={12}
                lineHeight="small"
                color="minor"
                data-test-id="singleHoldingTargetWeightsPercent"
              >
                &nbsp;/&nbsp;<Typo>{targetWeightsPercent}</Typo>
              </Typography>
            )}
          </Typography>
          <Paper top={4}>
            <Typography size={12} lineHeight="small" color="minor" data-test-id="singleHoldingActualAmount">
              <Typo>{formatMoney(actualAmount, true, true, false)}</Typo>
            </Typography>
          </Paper>
          <Paper top={4}>
            <Typography size={12} lineHeight="small" data-test-id="singleHoldingSubtitle">
              <Typo>{name} (Equity)</Typo>
            </Typography>
          </Paper>
        </Column>
        <Column size={1}>
          <Typography
            size={24}
            lineHeight="small"
            weight="semibold"
            align="right"
            data-test-id="singleHoldingRestWeightsPercent"
          >
            <Typo>{restWeightsPercent}</Typo>
          </Typography>
          <Paper top={4}>
            <Typography
              size={12}
              lineHeight="small"
              align="right"
              color="minor"
              data-test-id="singleHoldingRestWeightsAmount"
            >
              <Typo>{formatMoney(restWeightAmount, true, true, false)}</Typo>
            </Typography>
          </Paper>
          <Paper top={4}>
            <Typography size={12} lineHeight="small" align="right" data-test-id="singleHoldingRestSubtitle">
              <Typo>
                Rest of
                {selectedPortfolio ? ' portfolio' : ' all portfolios'}
              </Typo>
            </Typography>
          </Paper>
        </Column>
      </ColumnarLayout>
      <Paper top={12}>
        <LineChart values={distribution} />
      </Paper>
    </Fragment>
  )
}

export { DistributionChart }
