import React from 'react'

import { useUnit } from 'effector-react'

import { useActions, useEffect, useSelector } from 'hooks'

import { $bankAccountsStore, fetchBankAccountsFx } from 'app/effector/bank-accounts'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'

import { Preloader } from 'components/molecules/Preloader'

import { SettingsLayout } from '../components/SettingsLayout'

import { ChangeBankModal } from './components/components/ChangeBankModal'
import { NewBankModal } from './components/components/NewBankModal'
import { OpenBankingModal } from './components/components/OpenBankingModal'
import { PaymentDetailsContent } from './components/PaymentDetailsContent.jsx'

const SavingsPlans = (): React.ReactElement => {
  const portfolios = useSelector((state) => state.portfolios)

  const { isLoading } = useUnit($bankAccountsStore)

  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])

  useEffect(() => {
    fetchBankAccountsFx()

    if (!portfolios.items.length) {
      fetchPortfolios()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SettingsLayout title="Linked banks & Savings plans">
      <PaymentDetailsContent />
      <Preloader loading={isLoading} size="big" zIndex />

      <ChangeBankModal />
      <OpenBankingModal />
      <NewBankModal />
    </SettingsLayout>
  )
}

export { SavingsPlans }
