import { ListOf } from 'app/redux/models/common/ListOf'

import { PendingOrder } from './PendingOrder.js'

import { scope, type as pendingOrderType } from 'constants/pendingOrder'

/**
 * @class
 * @typedef {Array.<PendingOrder>} PendingOrderList
 */
export class PendingOrderList extends ListOf(PendingOrder) {
  /**
   * @param {number} id
   * @return {?PendingOrder}
   */
  get(id) {
    return this.find((order) => order.id === id) ?? null
  }

  /**
   * @param {PendingOrder} order
   * @return {PortfolioList}
   */
  replaceOrAdd(order) {
    if (this.findIndex((listPortfolio) => listPortfolio.id === order.id) > -1) {
      return this.replace(order)
    }

    return this.add(order)
  }

  /**
   * @param {PendingOrder} order
   * @return {PendingOrderList}
   */
  add(order) {
    const pendingOrderList = new PendingOrderList(...this)
    pendingOrderList.push(order)

    return pendingOrderList
  }

  /**
   * @param {PendingOrder} order
   * @return {PendingOrderList}
   */
  replace(order) {
    const pendingOrderList = new PendingOrderList(...this)
    const currentItemIndex = pendingOrderList.findIndex((item) => item.id === order.id)

    if (currentItemIndex < 0) {
      return pendingOrderList
    }

    pendingOrderList[currentItemIndex] = order

    return pendingOrderList
  }

  /**
   * @param {number} portfolioId
   * @return {PendingOrderList}
   */
  getByPortfolioId(portfolioId) {
    const filteredArray = [...this].filter((pendingOrder) => pendingOrder.portfolio_id === portfolioId)

    return new PendingOrderList(...filteredArray)
  }

  /**
   * @param {number} securityId
   * @return {PendingOrderList}
   */
  getBySecurityId(securityId) {
    const filteredArray = [...this].filter((pendingOrder) => {
      return pendingOrder.securities.map((security) => parseInt(security.id, 10)).includes(parseInt(securityId, 10))
    })

    return new PendingOrderList(...filteredArray)
  }

  /**
   * @param {number} portfolioId
   * @param {number} securityId
   * @return {PendingOrderList}
   */
  getByPortfolioIdOrSecurityId(portfolioId, securityId) {
    const filteredArray = [...this].filter((pendingOrder) => {
      return (
        (pendingOrder.scope === scope.PORTFOLIO && pendingOrder.portfolio_id === portfolioId) ||
        pendingOrder.securities.map((security) => parseInt(security.id, 10)).includes(parseInt(securityId, 10))
      )
    })

    return new PendingOrderList(...filteredArray)
  }

  /**
   * @param {string} scope
   * @return {PendingOrderList}
   */
  getByScope(scope) {
    const filteredArray = [...this].filter((pendingOrder) => pendingOrder.scope === scope)

    return new PendingOrderList(...filteredArray)
  }

  /**
   * @param {string} type
   * @return {PendingOrderList}
   */
  getByType(type) {
    const filteredArray = [...this].filter((pendingOrder) => pendingOrder.type === type)

    return new PendingOrderList(...filteredArray)
  }

  /**
   * @param {number} portfolioId
   * @return {PendingOrderList}
   */
  getRebalanceOrdersByPortfolioId(portfolioId) {
    return this.getByPortfolioId(portfolioId).getByType(pendingOrderType.REBALANCE)
  }
}
