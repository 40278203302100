import React, { Fragment, type ReactElement } from 'react'

import { useUnit } from 'effector-react'
import debounce from 'lodash/debounce'

import { useMediaQueries, useSelector, useEffect, useMemo, usePrevious } from 'hooks'

import { $notificationsStore, fetchNotificationsFx } from 'app/effector/notifications'
import { NotificationScopeType } from 'app/effector/notifications/models'

import { selectIsSomethingLoading } from 'app/redux/selectors'

import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'

import { Notification } from './Notification'

type NotificationGroupProps = {
  children?: (
    nodes: ReactElement | ReactElement[],
    actionableLength: number,
    nonActionableLength: number,
  ) => ReactElement
  scope?: NotificationScopeType
  portfolioId?: number
}

const NotificationGroup = ({
  children,
  scope = NotificationScopeType.ANY,
  portfolioId,
}: NotificationGroupProps): ReactElement | null => {
  const { desktop } = useMediaQueries()

  const fetchNotificationsDebounced = useMemo(() => debounce(fetchNotificationsFx, 250), [])

  const isSomethingLoading = useSelector(selectIsSomethingLoading)
  const portfolio = useSelector((state) => state.portfolios.list.get(portfolioId))

  const { notifications: notificationsStore, isLoading } = useUnit($notificationsStore)
  const notifications = notificationsStore.filterByScope(scope, portfolioId)

  const [actionableNotifications, nonActionableNotifications] = notifications.groupByActionability()

  const prevBalance = usePrevious(portfolio?.current_balance)

  // Fetch without bounce every time component mounts...
  useEffect(() => {
    fetchNotificationsFx()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ...and then fetch with debounce only when something loading or balance updated and notifications isn't invalidated
  useEffect(() => {
    const isBalanceUpdated = prevBalance && prevBalance !== portfolio?.current_balance

    if ((isSomethingLoading || isBalanceUpdated) && !isLoading) {
      fetchNotificationsDebounced()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSomethingLoading, prevBalance, portfolio?.current_balance])

  const actionableNotificationsNodes = useMemo(
    () =>
      actionableNotifications.map((notification) => (
        <Notification key={notification.code} notification={notification} />
      )),
    [actionableNotifications],
  )

  const nonActionableNotificationsNodes = useMemo(
    () =>
      nonActionableNotifications.map((notification, index) => (
        <Notification key={`${notification?.code ?? ''}+${index}`} notification={notification} />
      )),
    [nonActionableNotifications],
  )

  const notificationNodes = useMemo(() => {
    if (desktop) {
      return (
        <Stack vertical={8}>
          {actionableNotificationsNodes}
          {nonActionableNotificationsNodes}
        </Stack>
      )
    }

    return (
      <Fragment>
        {actionableNotificationsNodes && <Stack vertical={12}>{actionableNotificationsNodes}</Stack>}
        {nonActionableNotificationsNodes && (
          <Paper top={actionableNotifications.length > 0 ? 12 : null}>{nonActionableNotificationsNodes}</Paper>
        )}
      </Fragment>
    )
  }, [desktop, actionableNotificationsNodes, nonActionableNotificationsNodes, actionableNotifications?.length])

  if (actionableNotifications.length < 1 && nonActionableNotifications.length < 1) {
    return null
  }

  if (typeof children === 'function') {
    return children(notificationNodes, actionableNotifications.length, nonActionableNotifications.length)
  }

  return <div>{notificationNodes}</div>
}

export default NotificationGroup
