import React from 'react'

import { SetUpInstantBank } from './SetUpInstantBank.jsx'

export const routes = {
  module: 'set-up-instant-bank',
  path: 'set-up-instant-bank/',
  component: function Component(props) {
    return <SetUpInstantBank {...props} />
  },
}
