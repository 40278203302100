import React from 'react'

import { useCallback } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { regulatoryTypes } from 'constants/portfolio'

const OpenSippLink = (): React.ReactElement => {
  const handleOpenSipp = useCallback(() => {
    trackEvent({ action: 'open_acc_clicked', regulatory_type: regulatoryTypes.SIPP })

    goTo(urlTo('dashboard', null, { openSipp: true }))
  }, [])

  return (
    <Link onClick={handleOpenSipp} style={{ display: 'block' }} data-test-id="openSippButton">
      <ItemWithIcon
        inline
        space={12}
        icon={<Icon type="plus-in-an-outlined-circle-32" size={32} color="inherit" />}
        content={
          <Typography size={14} lineHeight="small" color="inherit">
            <Typo>Open a Self Invested Personal Pension</Typo>
          </Typography>
        }
        contentVerticalAlign="center"
      />
      <Paper left={40}>
        <Paper left={4}>
          <Typography size={14} lineHeight="small" color="minor">
            <Typo>
              Grow your retirement savings tax-efficiently with an InvestEngine Personal Pension. Minimums apply.
            </Typo>
          </Typography>
        </Paper>
      </Paper>
    </Link>
  )
}

export { OpenSippLink }
