/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import compose from 'helpers/compose.js'

import { fetchBankAccountsFx } from 'app/effector/bank-accounts'
import { $dictsStore } from 'app/effector/dicts'

import { fetchProjectionsGrowth, setProjectionsNotValid } from 'app/redux/actions/portfolios'

import EditGoal from 'components/_old/EditGoal/EditGoal.jsx'

import Segment from 'components/atoms/Segment/Segment.jsx'

class Projections extends React.Component {
  componentDidMount(): void {
    const { goal, fetchProjectionsGrowth, setProjectionsNotValid } = this.props

    fetchBankAccountsFx()

    if (!goal.projections) {
      const { id, preset_changed, initial_deposit, monthly_deposit, term } = goal
      setProjectionsNotValid()
      fetchProjectionsGrowth(id, preset_changed, initial_deposit, monthly_deposit, term)
    }
  }

  render(): React.ReactElement {
    const { submit, warning, desktop, goal, isBusiness, initialDepositMin, ...restProps } = this.props

    return (
      <Segment mods={{ margin: 'double', noMargin: desktop ? 'top' : 'top bottom' }}>
        <EditGoal
          warning={warning}
          hideName
          showProjections={goal.initial_deposit >= initialDepositMin}
          goal={goal}
          submit={submit}
          bottom={5}
          analyticsCategory={isBusiness ? 'SME Review your portfolio' : 'Review your portfolio'}
          {...restProps}
        />
      </Segment>
    )
  }
}

Projections.propTypes = {
  goal: PropTypes.object,
  submit: PropTypes.node,
  warning: PropTypes.node,
  desktop: PropTypes.bool,
  isBusiness: PropTypes.bool,
  initialDepositMin: PropTypes.number,
  fetchProjectionsGrowth: PropTypes.func,
  setProjectionsNotValid: PropTypes.func,
}

export default compose(
  connect(
    () => {
      return { initialDepositMin: $dictsStore.getState().initialDepositMin }
    },
    {
      fetchProjectionsGrowth,
      setProjectionsNotValid,
    },
  ),
)(Projections)
