import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { bulkValidate } from 'helpers/validation.js'

import { useMemo } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'
import { Typography } from 'components/atoms/Typography'
import { GatewayDest } from 'components/atoms/Gateway'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import ContentHolder from 'components/molecules/ContentHolder/ContentHolder.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { Progress } from 'components/_old/Progress/'
import Headline from 'components/_old/Headline/Headline.jsx'
import Button from 'components/_old/Button/Button.jsx'
import FeeInfo from 'app/containers/Questionnaire/FeeInfo/FeeInfo.jsx'
import TextInput from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/components/TextInput.jsx'

const Mobile = ({
  validation,
  handleInputChange,
  goal,
  activeStep,
  onSubmit,
  progress,
  handleBack,
  handleCancel,
  noDescription,
  noInputLabel,
}) => {
  const title = activeStep.headline
  const submitDisabled = !bulkValidate(validation)

  const idInput = (
    <TextInput
      validationRule={validation.initialDeposit}
      handleChange={handleInputChange}
      value={goal.initial_deposit}
    />
  )

  const question = useMemo(
    () => (
      <Fragment>
        {!noInputLabel && (
          <Fragment>
            <Typo>
              How much would your company
              <br />
              like to invest?
            </Typo>{' '}
          </Fragment>
        )}
        {idInput}
      </Fragment>
    ),
    [idInput, noInputLabel],
  )

  const description = !noDescription && (
    <Typography size={16} align="center">
      <Typo>
        Fill in our questionnaire commitment-free and we'll suggest a portfolio built and managed by our investment team
        to suit your profile.
        <br />
        <br />
        You can always change your answers or amount you'd like to invest before continuing.
      </Typo>
    </Typography>
  )

  const content = useMemo(
    () => (
      <ContentHolder className="_newTextQuestion-Form" forceAllCenter stretch>
        <SubmitOnEnter>
          <Paper>
            <Headline
              level={2}
              mods={{
                text: 'normal center light',
                'no-margin': 'top',
                'line-height': 'bigger',
                overflow: 'visible',
              }}
            >
              {question}
            </Headline>
            <Paper top={8} bottom={32}>
              <GatewayDest className="_newTextQuestion-Error" name="text-question-error" />
            </Paper>
            {description}
          </Paper>
        </SubmitOnEnter>
      </ContentHolder>
    ),
    [question, description],
  )

  const footer = useMemo(
    () => (
      <MobileLayoutFooterButton>
        <Button
          type="submit"
          mods={{ size: 'big block' }}
          onClick={onSubmit}
          disabled={submitDisabled}
          tabIndex={3}
          data-test-id="tunnelStepGetStartedSubmitButton"
        >
          Continue
        </Button>
      </MobileLayoutFooterButton>
    ),
    [onSubmit, submitDisabled],
  )

  return (
    <MobileLayout
      header={
        <Fragment>
          <NavigationBar
            leftPartText="Back"
            onLeftPartClick={handleBack}
            rightPartText={handleCancel ? 'Cancel' : undefined}
            onRightPartClick={handleCancel || undefined}
          >
            {title}
          </NavigationBar>
          <Progress fill={progress} gapWidth="1em" />
          <FeeInfo />
        </Fragment>
      }
      contentPaperSizes={{ top: 16, left: 16, right: 16, bottom: 16 }}
      content={content}
      footer={footer}
    />
  )
}

Mobile.propTypes = {
  goal: PropTypes.object,
  validation: PropTypes.object,
  handleInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  handleCancel: PropTypes.func,
  progress: PropTypes.number,
  activeStep: PropTypes.object,
  noDescription: PropTypes.bool,
  noInputLabel: PropTypes.bool,
}

export { Mobile }
