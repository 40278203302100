import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useCallback, useSelector } from 'hooks'

import { fetchBankAccountsFx, $bankAccountsStore } from 'app/effector/bank-accounts'
import { addSecurity, savePortfolioSecuritiesFx } from 'app/effector/portfolio-securities'

import { showFailToast } from 'app/redux/actions/ui'
import { isClientApproved as selectIsClientApproved } from 'app/redux/selectors'

const savePortfolioSecuritiesFxOnQuickStart = attach({ effect: savePortfolioSecuritiesFx })
savePortfolioSecuritiesFxOnQuickStart.fail.watch(() => {
  showFailToast()
})

type UseSecurityQuickStartProps = {
  portfolioId: number
  securityId?: string
}

type UseSecurityQuickStartReturnProps = {
  addSecurityToPortfolio: () => Promise<void>
}

const useSecurityQuickStart = ({
  portfolioId,
  securityId: securityIdString,
}: UseSecurityQuickStartProps): UseSecurityQuickStartReturnProps => {
  const securityId = securityIdString ? parseInt(securityIdString, 10) : null
  const { bankAccounts } = useUnit($bankAccountsStore)
  const hasBankAccounts = !!bankAccounts.length
  const isClientApproved = useSelector(selectIsClientApproved)

  const setPortfolioWeights = useCallback(async () => {
    const portfolioWeights = [
      {
        security_id: securityId,
        target_weight: '100',
      },
    ]
    await savePortfolioSecuritiesFxOnQuickStart({
      portfolioSecurityWeightList: portfolioWeights,
      portfolioId,
    })
  }, [portfolioId, securityId])

  const addSecurityToPortfolio = useCallback(async () => {
    try {
      addSecurity({ securityId, portfolioId })
      await setPortfolioWeights()

      if (!hasBankAccounts && isClientApproved) {
        await fetchBankAccountsFx()
      }
    } catch (error) {
      showFailToast()
    }
  }, [portfolioId, securityId, setPortfolioWeights, hasBankAccounts, isClientApproved])

  return {
    addSecurityToPortfolio,
  }
}

export { useSecurityQuickStart }
