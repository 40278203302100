import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { urlTo } from 'helpers/router.js'

import { useMemo, useCallback } from 'hooks'

import { useHeader } from './useHeader.js'
import { useRefresh } from './useRefresh.js'

import Header from 'components/_old/Header/Header.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Tabs, { SubTabs, Tab } from 'components/molecules/Tabs'
import Icon from 'components/_old/Icon/Icon.jsx'

const MobileHeader = withRouter(({ pageName, routes, withRefresh }) => {
  const { menuItems, owner } = useHeader(routes)
  const handleRefresh = useRefresh()

  const right = useMemo(() => {
    if (withRefresh) {
      return (
        <div>
          <Link className="Header-RefreshLink" onClick={handleRefresh} data-test-id="dashboardHeaderRefreshLink">
            <Icon type="update" color="inherit" size={28} />
          </Link>
        </div>
      )
    }
  }, [withRefresh, handleRefresh])

  const handleItemClick = useCallback((action) => {
    document.dispatchEvent(new CustomEvent('hideDrawer'))

    if (action) {
      action()
    }
  }, [])

  const renderMenuTab = useCallback(
    (item) => (
      <Tab active={item.active}>
        <Link
          to={item.link}
          hard={item.hard}
          blank={item.hard}
          onClick={() => handleItemClick(item.action)}
          analyticsEvent={item.analyticsEvent}
          data-test-id={item['data-test-id']}
        >
          {item.name}
        </Link>
      </Tab>
    ),
    [handleItemClick],
  )

  const menu = useMemo(
    () => (
      <Tabs>
        {menuItems.map((item, index) => (
          <Fragment key={index}>
            {renderMenuTab(item)}
            {item.childItems && (
              <SubTabs>
                {item.childItems.map((childItem, key) => React.cloneElement(renderMenuTab(childItem), { key }))}
              </SubTabs>
            )}
          </Fragment>
        ))}
      </Tabs>
    ),
    [menuItems],
  )

  return (
    <Header
      pageName={pageName}
      menu={menu}
      owner={owner}
      greetings={owner.getDisplayName()}
      drawerAction={<Link to={urlTo('logout')}>Logout</Link>}
      right={right}
      withShadow
    />
  )
})

MobileHeader.propTypes = {
  pageName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  withRefresh: PropTypes.bool,
}

export { MobileHeader }
