import React from 'react'
import PropTypes from 'prop-types'

import { roles } from 'constants/contacts.js'

import { useMemo } from 'hooks'
import { useUnit } from 'effector-react'

import { FieldLineList } from 'components/_old/molecules/FieldLineList'
import { FieldLine } from 'components/_old/atoms/FieldLine'
import Segment from 'components/atoms/Segment/Segment.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Text from 'components/_old/Text/Text.jsx'
import { DirectorsAndShareholdersFieldLine } from 'components/organisms/DirectorsAndShareholdersFieldLine'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $contacts, $owner } from 'app/effector/contacts'
import { $companyStore } from 'app/effector/company'
import { $significantControls } from 'app/effector/significant-controls'

const BusinessInformation = ({ getFormattedAddress }) => {
  const { contacts } = useUnit($contacts)
  const { controls: significantControls } = useUnit($significantControls)
  const { company } = useUnit($companyStore)
  const owner = useUnit($owner) ?? {}
  const [currentAddress] = company.addresses.getCurrentAddress()
  const { bankAccounts } = useUnit($bankAccountsStore)
  const nominatedAccount = bankAccounts.find((account) => account.is_nominated) ?? {}

  const contactsAndSignificantControlsList = useMemo(
    () =>
      [...(contacts || []), ...(significantControls || [])].map((item) => {
        const isContact = !item.name
        const title = isContact ? `${item.first_name || ''} ${item.last_name || ''}`.trim() : `${item.name || ''}`
        const lines = []
        const [address] = item.addresses.getCurrentAddress()

        if (!isContact) {
          lines.push('A person or entity with significant control')
        }

        if (item.role === roles.OWNER) {
          lines.push('Main applicant')
        }

        if (item.role === roles.DIRECTOR) {
          lines.push('Director')
        }

        if (item.has_significant_control) {
          lines.push('This person has significant control over the company')
        }

        if (address) {
          lines.push(`Address: ${getFormattedAddress(address)}`)
        }

        return {
          title,
          lines,
        }
      }),
    [contacts, significantControls, getFormattedAddress],
  )

  return (
    <FieldLineList>
      <FieldLine label="Address">
        <Typo>{getFormattedAddress(currentAddress)}</Typo>
      </FieldLine>
      <FieldLine label="Business email">
        <Typo>{owner.email}</Typo>
      </FieldLine>
      <FieldLine label="Bank sort code">
        <Typo>{nominatedAccount?.sort_code}</Typo>
      </FieldLine>
      <FieldLine label="Bank account number">
        <Typo>{nominatedAccount?.account_number}</Typo>
      </FieldLine>
      <DirectorsAndShareholdersFieldLine>
        {contactsAndSignificantControlsList.map(({ title, lines }, index) => (
          <Typo key={index}>
            <Segment mods={{ noMargin: 'top' }}>
              <Text block>{title}</Text>
            </Segment>
            {lines.length > 0 && (
              <Segment mods={{ noMargin: 'bottom' }}>
                <Text small color="darkgray">
                  {lines.map((line, index) => (
                    <Text key={index} block>
                      {line}
                    </Text>
                  ))}
                </Text>
              </Segment>
            )}
          </Typo>
        ))}
      </DirectorsAndShareholdersFieldLine>
    </FieldLineList>
  )
}

BusinessInformation.propTypes = {
  getFormattedAddress: PropTypes.func.isRequired,
}

export { BusinessInformation }
