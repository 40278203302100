import React from 'react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Preloader } from 'components/molecules/Preloader'

type TwoFactorAwaitMainInfoProps = {
  isResendingPush: boolean
  onResend: () => Promise<void>
  onShowNotificationIssue: () => void
}

const TwoFactorAwaitMainInfo = ({
  isResendingPush = false,
  onResend: handleResend,
  onShowNotificationIssue: handleShowNotificationIssue,
}: TwoFactorAwaitMainInfoProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const resendNode = (
    <Paper top={48} style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
      <AllCenter>
        <Paper top={32} bottom={32} style={{ position: 'relative' }}>
          <Preloader loading={true} absolute background="background" />
        </Paper>
        <Paper top={32}>
          <Typography size={14}>
            <Typo>Notification is being sent</Typo>
          </Typography>
        </Paper>
      </AllCenter>
    </Paper>
  )

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ visibility: isResendingPush ? 'hidden' : '' }}>
        <AllCenter>
          <Paper bottom={32}>
            <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
              <Typo>We've just sent a notification to the app on your mobile phone</Typo>
            </Typography>
          </Paper>
          <Paper bottom={desktop ? 56 : 80}>
            <Typography size={desktop ? 14 : 16} align="center">
              <Typo>Please authorise access using your mobile phone.</Typo>
            </Typography>
          </Paper>
          <Button tag="div" mods={{ theme: 'simple-reverse-blue', size: 'big block' }} onClick={handleResend}>
            <div>
              <Typography size={desktop ? 16 : 18} align="center" color="inherit" lineHeight="small">
                <Typo>Resend</Typo>
              </Typography>
            </div>
          </Button>
          <Paper top={4}>
            <Paper top={desktop ? 40 : 32}>
              <Link onClick={handleShowNotificationIssue}>
                <Typography size={desktop ? 16 : 18} color="inherit">
                  <Typo>Didn’t receive the notification?</Typo>
                </Typography>
              </Link>
            </Paper>
          </Paper>
        </AllCenter>
      </div>

      {isResendingPush && resendNode}
    </div>
  )
}

export { TwoFactorAwaitMainInfo }
