import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { format } from 'helpers/money'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { DynamicFontSize } from 'components/atoms/DynamicFontSize'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

type PortfolioBalanceProps = {
  portfolio: Portfolio
}

const PortfolioBalance = ({ portfolio }: PortfolioBalanceProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const balanceTitle = (
    <Typography size={16} color="minor" lineHeight="small">
      Portfolio balance{' '}
      <TooltipToModal
        description="The current balance of your portfolio including any pending transfers still being processed"
        customIcon
        offset={-350}
        data-test-id="portfolioBalanceTooltip"
      >
        <Link mods={{ color: 'minorToRed' }} style={{ display: 'inline-flex' }}>
          <Icon size={24} type="information-24" color="inherit" style={{ position: 'relative', top: '6px' }} />
        </Link>
      </TooltipToModal>
    </Typography>
  )

  const balanceNumber = (
    <Typography weight="semibold" lineHeight="small" data-test-id="portfolioBalance">
      <DynamicFontSize
        min={12}
        max={54}
        lineHeight={1.175}
        length={format(portfolio?.capital_balance, true, true).length}
        lengthLimit={9}
      >
        <PoundsWithPence amount={portfolio?.capital_balance} showZeroPence />
      </DynamicFontSize>
    </Typography>
  )

  return (
    <Fragment>
      {desktop ? balanceNumber : balanceTitle}
      <Paper top={desktop ? 0 : 4}>{desktop ? balanceTitle : balanceNumber}</Paper>
    </Fragment>
  )
}

export { PortfolioBalance }
