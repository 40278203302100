import React from 'react'
import classNames from 'classnames/dedupe'
import './MergeIcons.css'

export default function MergeIcons(props) {
  const { children, className, size, height, 'data-test-id': dataTestId } = props
  const style = props.style || {}

  return (
    <div
      className={classNames(className, 'MergeIcons')}
      style={{ ...style, width: size ? `${size}em` : style.width, height: height ? `${height}em` : style.height }}
      data-test-id={dataTestId}
    >
      {children.map((icon, i) => {
        return (
          <div className="MergeIcons-Icon" key={i} style={{ zIndex: i }}>
            {icon}
          </div>
        )
      })}
    </div>
  )
}
