import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { SelectableCard } from 'components/atoms/SelectableCard'
import { Paper } from 'components/atoms/Paper'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'

const ManualBankTransfer = ({ selected, onClick: handleClick }) => {
  const { desktop } = useMediaQueries()

  return (
    <SelectableCard selected={selected} onClick={handleClick} data-test-id="manualBankTransferSelectItem">
      <Paper top={24} bottom={24} right={desktop ? 24 : 20} left={desktop ? 24 : 20}>
        <ItemWithIcon
          iconPosition="right"
          icon={<Icon type="manualBankTransfer" size={44} />}
          content={
            <div>
              <Paper bottom={8}>
                <Typography size={desktop ? 16 : 18} weight="semibold" lineHeight="small">
                  <Typo>Manual bank transfer</Typo>
                </Typography>
              </Paper>
              <Typography color="minor" size={14}>
                <Typo>
                  Make your own transfer using the account details we provide. You can also set up standing orders. Will
                  take 1 day to process.
                </Typo>
              </Typography>
            </div>
          }
        />
      </Paper>
    </SelectableCard>
  )
}

ManualBankTransfer.propTypes = {
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export { ManualBankTransfer }
