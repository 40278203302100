import React from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type ClosingAlertProps = {
  handleConfirmPhone: () => void
  handleSkip: () => void
}

const ClosingAlert = ({ handleConfirmPhone, handleSkip }: ClosingAlertProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const iconNode = useMemo(() => <Icon type="exclamation-triangle" color={palette['status-warning']} size={80} />, [])

  const titleNode = useMemo(
    () => (
      <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
        <Typo>Phone number is required to activate two-factor authentication</Typo>
      </Typography>
    ),
    [desktop],
  )

  const descriptionNode = useMemo(
    () => (
      <Typography size={desktop ? 14 : 16} align="center">
        <Typo>
          If you haven't received an SMS message, check the phone number and mobile connection. Try sending a code again
          or activating two-factor authentication later in Login settings.
        </Typo>
      </Typography>
    ),
    [desktop],
  )

  const buttonNode = (
    <Button mods={{ size: 'big block' }} onClick={handleConfirmPhone}>
      Confirm phone
    </Button>
  )

  const closeLinkNode = (
    <Link onClick={handleSkip}>
      <Typography align="center" color="inherit">
        <Typo>I will activate two-factor authentication later in Login settings</Typo>
      </Typography>
    </Link>
  )

  if (desktop) {
    return (
      <Paper top={8} bottom={80} right={24} left={24}>
        <Paper top={96} left={48} right={48}>
          <AllCenter>{iconNode}</AllCenter>
          <Paper top={32}>{titleNode}</Paper>
          <Paper top={32}>{descriptionNode}</Paper>
          <Paper top={56} right={24} left={24}>
            {buttonNode}
            <Paper top={32}>{closeLinkNode}</Paper>
          </Paper>
        </Paper>
      </Paper>
    )
  }

  const contentNode = (
    <AllCenter>
      {iconNode}
      <Paper top={32}>{titleNode}</Paper>
      <Paper top={32}>{descriptionNode}</Paper>
    </AllCenter>
  )

  return (
    <MobileLayout
      content={contentNode}
      footer={
        <Paper>
          <MobileLayoutFooterButton>
            <Paper bottom={24}>{closeLinkNode}</Paper>
          </MobileLayoutFooterButton>
          <MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>
        </Paper>
      }
    />
  )
}

export { ClosingAlert }
