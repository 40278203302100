import { sendError } from 'helpers/errorLogging'
import { format, unformat } from 'helpers/money'

function money(unknown: unknown, withFloat: boolean = false, requiredPence: boolean = false): string {
  if (typeof unknown === 'string' && unknown.startsWith('£')) {
    return format(unformat(unknown, withFloat), withFloat, requiredPence)
  }

  if (typeof unknown === 'string' || typeof unknown === 'number') {
    return format(unknown, withFloat, requiredPence)
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  sendError(new Error(`Cannot convert ${unknown} to money`))

  return ''
}

export { money }
