import { createEffect, createStore, createEvent, combine, sample } from 'effector'
import pick from 'lodash/pick'

import { Contact, ContactList } from 'app/effector/contacts/models'

import { AddressList } from 'app/redux/models/address'
import { ApiError } from 'app/redux/models/errors'

import * as api from './api'
import { processOwner } from './utils'

// Events
const changeContactField = createEvent<{ id; field }>()
const changeAddressField = createEvent<{ field; addressIndex; id }>()
const resetError = createEvent()

// Effects
const fetchContactsFx = createEffect(async () => {
  try {
    const data = await api.fetchContacts()

    return data
  } catch (error) {}
})

const deleteContactFx = createEffect(async (id: number) => {
  try {
    await api.deleteContact(id)

    return true
  } catch (error) {}
})

const updateOrCreateContactFx = createEffect(async ({ keys, id, data, saveAddressesAsABulk = true }) => {
  const rawContact = data || $contacts.getState().getContact(id)

  let contactId = id

  const contact = keys ? pick(rawContact, keys) : rawContact

  let addresses
  let onlyAddresses = false
  let addressesAfterUpdate
  let resultContact

  if (contact.addresses) {
    addresses = AddressList.createFromObject({ list: contact.addresses })
    onlyAddresses = Object.keys(contact).length === 1
    delete contact.addresses
  }

  if (!onlyAddresses) {
    const isCreate = id === null || id === undefined
    resultContact = isCreate ? await api.createContact(contact) : await api.updateContact(id, contact)

    if (resultContact instanceof ApiError) {
      return resultContact
    }

    contactId = resultContact.id
  }

  if (addresses) {
    addressesAfterUpdate = await api.updateOrCreateAddresses({
      id: contactId,
      addresses,
      saveAddressesAsABulk,
    })

    if (addressesAfterUpdate instanceof ApiError || addressesAfterUpdate?.[0] instanceof ApiError) {
      return addressesAfterUpdate?.[0] || addressesAfterUpdate
    }
  }

  return { contact: resultContact, addresses: addressesAfterUpdate, contactId }
})

// Stores
const $contacts = createStore<ContactList>(new ContactList())
const $owner = $contacts.map((contacs) => contacs.getOwner() as Contact | undefined, { skipVoid: false })
const $isLoading = combine(
  fetchContactsFx.pending,
  updateOrCreateContactFx.pending,
  deleteContactFx.pending,
  (...pendingEffects) => pendingEffects.some((pending) => pending),
)
const $error = createStore(null).reset(resetError)

$contacts.on(fetchContactsFx.done, (state, { result }) => {
  if (!result) return state

  const contacts = result.map((contactObj) => Contact.createFromObject(contactObj))
  const newState = new ContactList(contacts)

  processOwner(newState)

  return newState
})

$contacts.on(deleteContactFx.done, (state, { params: id, result }) => {
  if (!result) return

  state.deleteContact(id)
  return new ContactList(state.contacts)
})

$contacts.on(updateOrCreateContactFx.done, (state, { result }) => {
  if (result instanceof Error) return state
  const { contact: contactData, addresses, contactId } = result

  if (contactData) {
    const contact = Contact.createFromObject(contactData)
    state.receiveContact(contact)
  }

  if (addresses) {
    const contact = state.getContact(contactId)
    contact.setDataFromObject({ addresses: { list: addresses } })
  }

  return new ContactList(state.contacts)
})

$contacts.on(changeContactField, (state, { id, field }) => {
  const contact = Contact.createFromObject(state.getContact(id)).setDataFromObject(field)
  state.receiveContact(contact)
  return new ContactList(state.contacts)
})

$contacts.on(changeAddressField, (state, { field, addressIndex, id }) => {
  const contact = state.getContact(id)
  const address = contact.addresses.getAddress(addressIndex).setDataFromObject(field)
  contact.addresses.setAddress(addressIndex, address)

  return new ContactList(state.contacts)
})

$error.on(updateOrCreateContactFx.done, (state, { result }) => {
  if (result instanceof Error) {
    return result
  }
  return state
})

sample({
  clock: updateOrCreateContactFx,
  target: resetError,
})

export {
  // Effects
  fetchContactsFx,
  deleteContactFx,
  updateOrCreateContactFx,
  // Events
  changeContactField,
  changeAddressField,
  resetError,
  // Store
  $contacts,
  $owner,
  $isLoading,
  $error,
}
