import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Typo } from 'components/_old/Typo/Typo'
import { Typography } from 'components/atoms/Typography'
import { Paper } from 'components/atoms/Paper'
import { Hr } from 'components/atoms/Hr'
import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

const BankAccountInfo = ({ sortCode, accountNumber }) => (
  <Fragment>
    <ColumnarLayout>
      <Typography size={14} color="minor">
        <Typo>Sort code</Typo>
      </Typography>
      <Typography size={14}>
        <Typo>{sortCode}</Typo>
      </Typography>
    </ColumnarLayout>
    <Paper top={16} bottom={16}>
      <Hr />
    </Paper>
    <ColumnarLayout>
      <Typography size={14} color="minor">
        <Typo>Account number</Typo>
      </Typography>
      <Typography size={14}>
        <Typo>{accountNumber}</Typo>
      </Typography>
    </ColumnarLayout>
  </Fragment>
)

BankAccountInfo.propTypes = {
  sortCode: PropTypes.string,
  accountNumber: PropTypes.string,
}

export { BankAccountInfo }
