import React from 'react'
import { connect } from 'react-redux'

import isUndefined from 'lodash/isUndefined'
import last from 'lodash/last'
import PropTypes from 'prop-types'

import compose from 'helpers/compose.js'
import { sendError } from 'helpers/errorLogging.js'
import { isAuthorizationError } from 'helpers/errors'
import { features } from 'helpers/features'
import { Impact } from 'helpers/impact.js'
import waitForAuth from 'helpers/waitForAuth.js'

import { fetchCompanyFx } from 'app/effector/company'
import { fetchContactsFx } from 'app/effector/contacts'
import { fetchOptionsFx } from 'app/effector/dicts'
import { fetchReferralCodeFx } from 'app/effector/referralCode'
import { fetchSignificantControlsFx } from 'app/effector/significant-controls'

import { fetchClient, setValid as setClientValid } from 'app/redux/actions/client'
import { resetAllErrors } from 'app/redux/actions/global.js'
import { fetchPresets } from 'app/redux/actions/portfolios'
import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'
import { selectIsSomethingLoading } from 'app/redux/selectors'

import GlobalPreloader, { GlobalPreloaderProvider } from 'components/_old/GlobalPreloader/GlobalPreloader.jsx'
import Toast, { Stack as ToastStack } from 'components/_old/Toast/Toast.jsx'

import { GatewayProvider, GatewayDest } from 'components/atoms/Gateway'

import { TrustpilotReviewModal } from 'components/molecules/TrustpilotReviewModal/TrustpilotReviewModal'

import OfflineNotification from 'components/organisms/OfflineNotification/OfflineNotification.jsx'
import { ZendeskInitiator } from 'components/organisms/ZendeskInitiator/ZendeskInitiator'

import { ErrorBoundary } from 'app/containers/ErrorBoundary'

import { SessionTimeout } from 'app/pages/SessionTimeoutModal'
import SupportModal from 'app/pages/SupportModal/SupportModal.jsx'

import Nodes from './Nodes/Nodes.jsx'
import ScrollTopOnRouteUpdate from './ScrollTopOnRouteUpdate/ScrollTopOnRouteUpdate.jsx'

import { types as clientTypes } from 'constants/client'
import { presetTypes } from 'constants/portfolio'

import 'helpers/palette/palette.css'

type RouteProps = {
  module: string
  name?: string
  isNewLayout?: boolean
  noGlobalPreloader?: boolean
  component: (props: Record<string, unknown>) => React.ReactElement
}

type AppProps = {
  isLoading: boolean
  client: {
    access_token: string
    is_fetched: boolean
  }
  location: {
    query: {
      verify_email?: string
    }
  }
  params: {
    section?: string
  }
  routes: RouteProps[]
  support: Array<{ title: string; slug: string }>
  toasts: Array<{ message: string; style: string; uid: number }>
  failedPingAttempts: number
  globalPreloaderStatus: string
  resetAllErrors: () => void
  fetchClient: (access_token: string, setValidAfter?: boolean) => Promise<object>
  setClientValid: () => Promise<void>
  fetchPresets: () => Promise<void>
}

type AppState = {
  pageName: string | null
}

class App extends React.Component<AppProps, AppState> {
  state: AppState = {
    pageName: null,
  }

  static childContextTypes = {
    pageName: PropTypes.string,
    setPageName: PropTypes.func.isRequired,
  }

  reInitialize = (): void => {
    const { resetAllErrors } = this.props

    resetAllErrors()
    this.forceUpdate()
  }

  setPageName = (pageName: string): void => {
    this.setState({ pageName })
  }

  getChildContext = (): { pageName: string; setPageName: (pageName: string) => void } => {
    const { routes, params } = this.props

    const pageName = (() => {
      if (this.state.pageName) {
        return this.state.pageName
      }

      let name
      const getNameOrGoFurther = (index: number): string | null | undefined => {
        if (!name) {
          const route = routes[routes.length - index]
          const routeName = route ? route.name : null

          if (route && route.module === 'questions' && params.section) {
            const support = this.props.support.find((s) => s.slug === params.section)

            if (support?.title) {
              name = support.title
            }
          }
          if (routeName) {
            name = routeName
          } else if (routeName === null) {
            return null
          } else {
            getNameOrGoFurther(index + 1)
          }
        }
      }
      getNameOrGoFurther(1)

      return name
    })()

    return { pageName, setPageName: this.setPageName }
  }

  async componentDidMount(): Promise<boolean | undefined> {
    const { client, location, routes, fetchClient, setClientValid, fetchPresets } = this.props
    const { query } = location
    const isAuthorized = client?.access_token

    if (last(routes)?.module === 'logout') {
      return false
    }

    if (client?.is_fetched) {
      return false
    }

    // FIXME: should it be here?
    if (!isUndefined(query.verify_email)) {
      const param = (() => {
        try {
          return JSON.parse(query.verify_email)
        } catch (e) {
          return query.verify_email
        }
      })()

      if (query.verify_email && param === 'failed') {
        setTimeout(() => {
          showFailToast('Email didn’t verified, please try to resend it')
        }, 100)
      } else {
        setTimeout(() => {
          showSuccessToast('Email verified')
        }, 100)
      }
    }

    if (isAuthorized) {
      await features.fetch()

      const waitClient = new Promise((resolve, reject) => {
        fetchClient(client.access_token, false).then((nextState) => {
          if (nextState.client.error) {
            reject(nextState.client.error)
            return
          }
          resolve(nextState.client)
        })
      })

      const presetRequests = []
      Object.values(presetTypes).forEach((presetType) =>
        presetRequests.push(fetchPresets(presetType, { setNotValidBefore: false, setValidAfter: false })),
      )
      try {
        const [nextClient] = await Promise.all([waitClient, ...presetRequests])
        Impact.identify({
          reference: nextClient.reference,
          email: nextClient.email,
        })
        fetchOptionsFx()
        fetchContactsFx()

        if (nextClient.type === clientTypes.BUSINESS) {
          fetchCompanyFx()
          fetchSignificantControlsFx()
        }

        await setClientValid()

        if (features?.get('new-referral-system')) {
          fetchReferralCodeFx()
        }
      } catch (error) {
        sendError(error)
        if (!isAuthorizationError(error)) {
          showFailToast()
        }
      }
    }

    if (!isAuthorized) {
      Impact.identify({})
      setClientValid()
      waitForAuth()
        .then(async ({ client }) => {
          fetchOptionsFx()
          fetchContactsFx()

          if (client.type === clientTypes.BUSINESS) {
            fetchCompanyFx()
            fetchSignificantControlsFx()
          }

          return await features.fetch()
        })
        .then((features) => {
          const isNewReferralSystemEnabled = features['new-referral-system']

          if (isNewReferralSystemEnabled) {
            fetchReferralCodeFx()
          }
        })
    }

    document.addEventListener('reInitializeApp', this.reInitialize)
  }

  componentWillUnmount(): void {
    document.removeEventListener('reInitializeApp', this.reInitialize)
  }

  render(): React.ReactElement {
    const {
      children,
      isLoading,
      failedPingAttempts,
      toasts,
      globalPreloaderStatus,
      location,
      params,
      routes = [],
    } = this.props

    const routeWithNewLayoutIndex = routes.findIndex((route) => route.isNewLayout)
    const routeWithNewLayout = routes[routeWithNewLayoutIndex]
    const routeWithoutGlobalPreloaderIndex = routes.findIndex((route) => route.noGlobalPreloader)
    const routeWithoutGlobalPreloader = routes[routeWithoutGlobalPreloaderIndex]
    const propsForNewLayoutComponent = {
      location,
      params,
      routes,
      route: routes[routeWithNewLayoutIndex],
      children,
    }

    const hasAuth = Boolean(this.props.client?.access_token)

    return (
      <ErrorBoundary redirectModule="dashboard" levelName="App">
        <ScrollTopOnRouteUpdate>
          <GatewayProvider>
            <GlobalPreloaderProvider loading={isLoading}>
              {routeWithNewLayout ? (
                routeWithNewLayout.component(propsForNewLayoutComponent)
              ) : (
                <Nodes>{children}</Nodes>
              )}
              <TrustpilotReviewModal />
              {!routeWithoutGlobalPreloader && <GlobalPreloader status={globalPreloaderStatus} />}
              <OfflineNotification visible={failedPingAttempts >= 3} />
              <ToastStack>
                {toasts.map((t, i) => (
                  <Toast key={i} style={t.style} uid={t.uid}>
                    {t.message}
                  </Toast>
                ))}
              </ToastStack>
              <ZendeskInitiator />
              <SupportModal />
              <SessionTimeout hasAuth={hasAuth} />
              <GatewayDest name="global" />
            </GlobalPreloaderProvider>
          </GatewayProvider>
        </ScrollTopOnRouteUpdate>
      </ErrorBoundary>
    )
  }
}

export default compose(
  connect(
    (state: object) => ({
      client: state.client,
      toasts: state.ui.toasts,
      isLoading: selectIsSomethingLoading(state),
      failedPingAttempts: state.ui.fields.failedPingAttempts,
      support: state.ui.flatpage_sections['inner-support'],
      globalPreloaderStatus: state.ui.fields.globalPreloaderStatus,
    }),
    {
      fetchClient,
      fetchPresets,
      setClientValid,
      resetAllErrors,
    },
  ),
)(App)
