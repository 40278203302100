import React from 'react'

import { SippDeclaration } from './SippDeclaration'

export default {
  isNewLayout: true,
  module: 'sipp-declaration',
  path: 'sipp-declaration/',
  component: function Component(props) {
    return <SippDeclaration {...props} />
  },
}
