import React from 'react'
import PropTypes from 'prop-types'

import { features } from 'helpers/features'

import { useMediaQueries, useMemo } from 'hooks'
import { usePortfolio } from './hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { WeightsChangedModal } from './components/WeightsChangedModal'
import { OrdersCancelledModal } from './components/OrdersCancelledModal'
import { RebalanceRestrictions } from './components/RebalanceRestrictions'
import { AutoinvestAfterSell } from './components/AutoinvestAfterSell'
import { HoldingsChanged } from './components/HoldingsChanged'
import { SavingsPlanSetup } from '../../SavingsPlan/SavingsPlanSetup'
import { AutoinvestModal } from './../Autoinvest/AutoinvestModal'

import { Restrictions } from '../Restrictions'

import ChangeGoalTitle from '../../ChangeGoalTitle/ChangeGoalTitle.jsx'
import CantSetUpMonthlyPayment from '../../CantSetUpMonthlyPayment/CantSetUpMonthlyPayment.jsx'
import { WithdrawFunds } from '../../WithdrawFunds/WithdrawFunds'
import { PortfolioOpenedModal } from '../../PortfolioOpenedModal'
import { AddFundsModal } from '../../AddFunds'
import { OptionsModal } from '../../Options/OptionsModal'
import { ChangeGoalTunnelProvider } from '../../ChangeGoalTunnel/ChangeGoalTunnel.jsx'
import { FinishRegistrationModal } from 'app/pages/Dashboard/SetupAccount/FinishRegistration/FinishRegistrationModal.jsx'
import { AllowNotificationsModal } from 'app/pages/Dashboard/AllowNotificationsModal/AllowNotificationsModal.jsx'
import { SippWithdrawModal } from 'app/pages/Dashboard/Goals/components/SippWithdrawModal'
import { SippTransferModal } from 'app/pages/Dashboard/Goals/components/SippTransferModal'

import { Mobile } from './Mobile'
import { Desktop } from './Desktop'
import { AltusRestriction } from 'app/pages/Dashboard/Accounts/components/Performance'

const Portfolio = ({ params: { id: idString } = {}, location = {}, routes }) => {
  const { desktop } = useMediaQueries()
  const portfolioId = useMemo(() => parseInt(idString), [idString])
  const {
    isSecuritiesLoading,
    isHistoryLoading,
    isRebalanceLoading,
    portfolio,
    portfolioActions,
    portfolioOrders,
    securities,
    chartData,
    pointsOfInterest,
    selectedPeriod,
    handlePeriodSelect,
    periodPriceData,
    contributions,
    autoinvestAfterSellModalOpen,
    restrictionModalOpen,
    restrictionModalType,
    isRebalanceRestricted,
    rebalanceRestrictionType,
    holdingsChanged,
    activeChart,
    handleRebalanceRestrictionModalClose,
    handleRestrictionModalClose,
    handleBack,
    handleAddSecurities,
    handleSelectSecurity,
    handleBuySell,
    setChartTab,
    showFinishSavingsPlanBanner,
    quickStart,
    showFirstTopupBanner,
    showLumpSumBanner,
    daysBeforePayment,
    altusProgressData,
    isTransferRestrictionModalVisible,
    handleTransferRestrictionModalOpen,
    handleTransferRestrictionModalClose,
    regulatoryTypeText,
    handleDismissAltusPanel,
  } = usePortfolio(portfolioId, location, routes)

  const props = {
    isSecuritiesLoading,
    isHistoryLoading,
    isRebalanceLoading,
    portfolio,
    portfolioOrders,
    portfolioActions,
    securities,
    chartData,
    pointsOfInterest,
    selectedPeriod,
    handlePeriodSelect,
    periodPriceData,
    contributions,
    handleBack,
    handleAddSecurities,
    handleSelectSecurity,
    handleBuySell,
    activeChart,
    setChartTab,
    showFinishSavingsPlanBanner,
    quickStart,
    showFirstTopupBanner,
    showLumpSumBanner,
    daysBeforePayment,
    altusProgressData,
    handleTransferRestrictionModalOpen,
    regulatoryTypeText,
    handleDismissAltusPanel,
  }

  if (!portfolio) {
    return null
  }

  return (
    <ChangeGoalTunnelProvider>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}

      <ChangeGoalTitle goalId={portfolio.id} />
      <CantSetUpMonthlyPayment goalId={portfolio.id} />
      <WithdrawFunds portfolioId={portfolio.id} />
      <AddFundsModal goal={portfolio} />
      <OptionsModal portfolio={portfolio} portfolioActions={portfolioActions} />
      <PortfolioOpenedModal portfolioId={portfolio.id} type="diy" />
      <WeightsChangedModal portfolioId={portfolio.id} />
      <OrdersCancelledModal portfolioId={portfolio.id} />
      <AutoinvestModal portfolio={portfolio} />
      <AutoinvestAfterSell open={autoinvestAfterSellModalOpen} portfolioId={portfolio.id} />
      <HoldingsChanged open={holdingsChanged} portfolioId={portfolio.id} />
      <SavingsPlanSetup portfolio={portfolio} />
      {features.get('altus-progress-bar') && (
        <AltusRestriction
          isModalVisible={isTransferRestrictionModalVisible}
          handleClose={handleTransferRestrictionModalClose}
        />
      )}
      <FinishRegistrationModal />
      <AllowNotificationsModal module="dashboard.portfolio" />
      <Restrictions
        open={restrictionModalOpen}
        onClose={handleRestrictionModalClose}
        type={restrictionModalType}
        portfolioId={portfolio.id}
      />
      {rebalanceRestrictionType && (
        <RebalanceRestrictions
          open={isRebalanceRestricted}
          onClose={handleRebalanceRestrictionModalClose}
          type={rebalanceRestrictionType}
        />
      )}
      <SippWithdrawModal />
      <SippTransferModal />

      <GatewayDest name="change-goal-title" />
      <GatewayDest name="cant-set-up-monthly-payment" />
      <GatewayDest name="withdraw-funds" />
      <GatewayDest name="onboarding-modals" />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </ChangeGoalTunnelProvider>
  )
}

Portfolio.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  location: PropTypes.object,
  routes: PropTypes.arrayOf(PropTypes.object),
}

export { Portfolio }
