import React from 'react'

import { useMediaQueries } from 'hooks'

import { Paper } from 'components/atoms/Paper'

import { CollectionGroup } from './components/CollectionGroup'
import { ManagedPromoPanel } from './components/ManagedPromoPanel/ManagedPromoPanel'
import { SelfSelectedPresetsBlock } from './components/SelfSelectedPresetsBlock'
import { useSecurityUniverseDiscovery } from './hooks/useSecurityUniverseDiscovery'

type SecurityUniverseDiscoveryProps = {
  allSecurities: any[]
  areCollectionsBeingLoaded: boolean
  isDiscoveryDisplayed: boolean
  isLoading: boolean
  portfolioId?: string
  requestRegulatoryType?: string
  scrollableElementRef: React.RefObject<any>
  securitiesWasLoadedOnce: boolean
  portfolioSecurities: any[]
  getSecurityDataByIndex: (index: number) => any
  getSecurityLink: (security: any) => string
  handleSaveScrollPosition: () => void
  handleSecurityClick: (security: any) => void
  navigateByTab: (index: number) => void
}

const SecurityUniverseDiscovery = ({
  allSecurities,
  areCollectionsBeingLoaded,
  isDiscoveryDisplayed,
  isLoading,
  portfolioId,
  portfolioSecurities,
  requestRegulatoryType,
  scrollableElementRef,
  securitiesWasLoadedOnce,
  getSecurityDataByIndex,
  getSecurityLink,
  handleSaveScrollPosition,
  handleSecurityClick,
  navigateByTab,
}: SecurityUniverseDiscoveryProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { collectionGroups, selfSelectedPresets } = useSecurityUniverseDiscovery()

  const featuredPartners = collectionGroups.find((collection) => collection.layout === 'featured')
  const restCollections = collectionGroups.filter((collection) => collection.layout !== 'featured')
  const shouldDisplaySelfSelectedPresetsBlock = !portfolioId

  const collectionGroupNode = (collectionGroup): React.ReactElement => (
    <CollectionGroup
      areCollectionsBeingLoaded={areCollectionsBeingLoaded}
      collections={collectionGroup.collections}
      isDiscoveryDisplayed={isDiscoveryDisplayed}
      isLoading={isLoading}
      isSkeleton={collectionGroup.isSkeleton}
      layout={collectionGroup.layout}
      portfolioId={portfolioId}
      portfolioSecurities={portfolioSecurities}
      requestRegulatoryType={requestRegulatoryType}
      scrollableElementRef={scrollableElementRef}
      securities={allSecurities}
      securitiesWasLoadedOnce={securitiesWasLoadedOnce}
      title={collectionGroup.title}
      getSecurityDataByIndex={getSecurityDataByIndex}
      getSecurityLink={getSecurityLink}
      handleSaveScrollPosition={handleSaveScrollPosition}
      handleSecurityClick={handleSecurityClick}
      navigateByTab={navigateByTab}
    />
  )

  return (
    <Paper bottom={desktop ? 120 : 0}>
      {!!featuredPartners && <Paper bottom={48}>{collectionGroupNode(featuredPartners)}</Paper>}
      {restCollections.map((collectionGroup, index) => {
        if (collectionGroup.layout === 'inline') {
          return (
            <Paper bottom={56} key={index}>
              {collectionGroupNode(collectionGroup)}
            </Paper>
          )
        }

        return null
      })}
      {shouldDisplaySelfSelectedPresetsBlock && (
        <Paper bottom={48}>
          <SelfSelectedPresetsBlock presets={selfSelectedPresets} handleSaveScrollPosition={handleSaveScrollPosition} />
        </Paper>
      )}
      {!portfolioId && (
        <Paper bottom={56}>
          <ManagedPromoPanel />
        </Paper>
      )}
      {restCollections.map((collectionGroup, index) => {
        if (collectionGroup.layout === 'inline') {
          return null
        }

        return (
          <Paper bottom={48} key={index}>
            {collectionGroupNode(collectionGroup)}
          </Paper>
        )
      })}
    </Paper>
  )
}

export { SecurityUniverseDiscovery }
