import flow from 'lodash/flow'
import groupBy from 'lodash/groupBy'
import isObject from 'lodash/isObject'

import { sendError } from 'helpers/errorLogging.js'

import {
  DiyGiaPortfolio,
  DiyIsaPortfolio,
  DiySippPortfolio,
  ManagedGrowthGiaPortfolio,
  ManagedGrowthIsaPortfolio,
  ManagedGrowthSippPortfolio,
  ManagedIncomeGiaPortfolio,
  ManagedIncomeIsaPortfolio,
  ManagedIncomeSippPortfolio,
  CashGiaPortfolio,
  CashIsaPortfolio,
  CashSippPortfolio,
} from './Portfolio/Portfolio.js'

import { manageTypes, presetTypes, regulatoryTypes, states } from 'constants/portfolio'

/** @typedef { ManagedGrowthGiaPortfolio | ManagedGrowthIsaPortfolio | ManagedIncomeGiaPortfolio | ManagedIncomeIsaPortfolio | DiyGiaPortfolio | CashGiaPortfolio | CashIsaPortfolio } Portfolio */

/**
 * @param {Object} item
 * @return {?Portfolio}
 */
const getPortfolioClass = (item) => {
  if (item.manage_type === manageTypes.MANAGED) {
    if (item.preset_type === presetTypes.GROWTH) {
      if (item.regulatory_type === regulatoryTypes.GIA) {
        return ManagedGrowthGiaPortfolio
      }

      if (item.regulatory_type === regulatoryTypes.ISA) {
        return ManagedGrowthIsaPortfolio
      }

      if (item.regulatory_type === regulatoryTypes.SIPP) {
        return ManagedGrowthSippPortfolio
      }
    }

    if (item.preset_type === presetTypes.INCOME) {
      if (item.regulatory_type === regulatoryTypes.GIA) {
        return ManagedIncomeGiaPortfolio
      }

      if (item.regulatory_type === regulatoryTypes.ISA) {
        return ManagedIncomeIsaPortfolio
      }

      if (item.regulatory_type === regulatoryTypes.SIPP) {
        return ManagedIncomeSippPortfolio
      }
    }
  }

  if (item.manage_type === manageTypes.SELF_SELECTED) {
    if (item.preset_type === presetTypes.SELF_SELECTED) {
      if (item.regulatory_type === regulatoryTypes.GIA) {
        return ManagedGrowthGiaPortfolio
      }

      if (item.regulatory_type === regulatoryTypes.ISA) {
        return ManagedGrowthIsaPortfolio
      }

      if (item.regulatory_type === regulatoryTypes.SIPP) {
        return ManagedGrowthSippPortfolio
      }
    }
  }

  if (item.manage_type === manageTypes.DIY) {
    if (item.regulatory_type === regulatoryTypes.GIA) {
      return DiyGiaPortfolio
    }

    if (item.regulatory_type === regulatoryTypes.ISA) {
      return DiyIsaPortfolio
    }

    if (item.regulatory_type === regulatoryTypes.SIPP) {
      return DiySippPortfolio
    }
  }

  if (item.manage_type === manageTypes.CASH) {
    if (item.regulatory_type === regulatoryTypes.GIA) {
      return CashGiaPortfolio
    }

    if (item.regulatory_type === regulatoryTypes.ISA) {
      return CashIsaPortfolio
    }

    if (item.regulatory_type === regulatoryTypes.SIPP) {
      return CashSippPortfolio
    }
  }

  sendError('Item of unknown type passed as a portfolio to `Portfolios` model', { item })
}

export const createPortfolio = (item) => {
  const portfolioData = item

  const Class = getPortfolioClass(portfolioData)

  if (Class) {
    return Class.createFromObject(portfolioData)
  }
}

/**
 * @class
 * @typedef {Array.<Portfolio>} PortfolioList
 */
export class PortfolioList extends Array {
  constructor(...items) {
    /** @type {Array.<Portfolio>} */
    const portfolioItems = items
      .map((item) => {
        if (Object.prototype.toString.call(item) === '[object Object]') {
          return item
        }

        return null
      })
      .filter((item) => Boolean(item))
      .map(createPortfolio)
      .filter((item) => Boolean(item))

    super(...portfolioItems)
  }

  /**
   * @param {'default'|'no-isa'|'business'} [type='default']
   * @returns {PortfolioList}
   */
  getSkeletonsList(type = 'default') {
    if (type === 'business' || type === 'no-isa') {
      return new PortfolioList(
        CashGiaPortfolio.createFromObject({ skeleton: true }),
        DiyGiaPortfolio.createFromObject({ skeleton: true }),
      )
    }

    return new PortfolioList(
      CashIsaPortfolio.createFromObject({ skeleton: true }),
      DiyIsaPortfolio.createFromObject({ skeleton: true }),
      CashGiaPortfolio.createFromObject({ skeleton: true }),
      DiyGiaPortfolio.createFromObject({ skeleton: true }),
    )
  }

  /**
   * @param {number} id
   * @return {?Portfolio}
   */
  get(id) {
    return this.find((portfolio) => portfolio.id === id) ?? null
  }

  /**
   * @param {number} id
   * @return {PortfolioList}
   */
  remove(id) {
    return new PortfolioList(...this.filter((portfolio) => portfolio.id !== parseInt(id, 10)))
  }

  /**
   * @param {Object} shape
   * @return {PortfolioList}
   */
  filterByShape(shape = {}) {
    return this.filter((portfolio) => {
      const shapeEntries = Object.entries(shape)

      if (shapeEntries.length > 0) {
        return shapeEntries.every(([key, value]) => {
          if (isObject(value)) {
            return Object.entries(value).some(([valueKey, valueValue]) => portfolio[key][valueKey] === valueValue)
          }

          return portfolio[key] === value
        })
      }

      return true
    })
  }

  /**
   * @param {Object} rawPortfolio
   * @return {PortfolioList}
   */
  add(rawPortfolio) {
    const portfolio = createPortfolio(rawPortfolio)

    if (!portfolio) {
      sendError('Object not shaped as `Portfolio` was passed as `add` argument at `PortfolioList`', { rawPortfolio })

      return this
    }

    const newPortfolios = new PortfolioList(...this)
    newPortfolios.push(portfolio)

    return newPortfolios
  }

  /**
   * @param {Object} rawPortfolio
   * @return {PortfolioList}
   */
  replace(rawPortfolio) {
    const portfolio = createPortfolio(rawPortfolio)

    if (!portfolio) {
      sendError('Object not shaped as `Portfolio` was passed as `replace` argument at `PortfolioList`', {
        rawPortfolio,
      })

      return this
    }

    const newPortfolios = new PortfolioList(...this)
    const currentPortfolioIndex = newPortfolios.findIndex((listPortfolio) => listPortfolio.id === portfolio.id)

    if (currentPortfolioIndex < 0) {
      return newPortfolios
    }

    newPortfolios[currentPortfolioIndex] = portfolio

    return newPortfolios
  }

  /**
   * @param {Object} rawPortfolio
   * @return {PortfolioList}
   */
  replaceOrAdd(rawPortfolio) {
    if (this.findIndex((listPortfolio) => listPortfolio.id === rawPortfolio.id) > -1) {
      return this.replace(rawPortfolio)
    }

    return this.add(rawPortfolio)
  }

  /**
   * @return {number[]}
   */
  getArrayOfPortfolioIds() {
    return [...this].map((portfolio) => portfolio.id).filter((id) => typeof id === 'number')
  }

  /**
   * @return {PortfolioList}
   */
  getVisiblePortfolios({ includeCashPortfolios = true, includeHiddenPortfolios = false } = {}) {
    const visibleStates = [states.COMPLETED, states.APPROVED, states.CLOSING]
    let visiblePortfolios = [...this].filter((portfolio) => visibleStates.includes(portfolio.state))

    if (!includeHiddenPortfolios) {
      visiblePortfolios = visiblePortfolios.filter((portfolio) => !portfolio?.display.is_hidden)
    }

    if (!includeCashPortfolios)
      visiblePortfolios = visiblePortfolios.filter((portfolio) => portfolio.manage_type !== manageTypes.CASH)

    return new PortfolioList(...visiblePortfolios)
  }

  /**
   * @return {Object.<string, PortfolioList>}
   */
  groupByManageType() {
    const cloneList = (portfolioList) => [...portfolioList]
    const groupByManageType = (portfolios) => groupBy(portfolios, (portfolio) => portfolio.manage_type)
    const convertArraysToPortfolioLists = (groupedArrays) => {
      const arrayEntries = Object.entries(groupedArrays)
      const listEntries = arrayEntries.map(([key, value]) => [key, new PortfolioList(...value)])

      return Object.fromEntries(listEntries)
    }

    return flow(cloneList, groupByManageType, convertArraysToPortfolioLists)(this)
  }

  /**
   * @return {Object.<string, PortfolioList>}
   */
  groupByRegulatoryType() {
    const manageTypeSortOrder = [manageTypes.CASH, manageTypes.MANAGED, manageTypes.SELF_SELECTED, manageTypes.DIY]
    const cloneList = (portfolioList) => [...portfolioList]
    const groupByRegulatoryType = (portfolios) => groupBy(portfolios, (portfolio) => portfolio.regulatory_type)
    const convertArraysToPortfolioLists = (groupedArrays) => {
      const arrayEntries = Object.entries(groupedArrays)
      const listEntries = arrayEntries.map(([key, value]) => [
        key,
        new PortfolioList(...value)
          .sort((a, b) => {
            if (a.manage_type !== b.manage_type) {
              return manageTypeSortOrder.indexOf(a.manage_type) - manageTypeSortOrder.indexOf(b.manage_type)
            }

            return a.id - b.id
          })
          .sortByPosition(),
      ])

      return Object.fromEntries(listEntries)
    }

    return flow(cloneList, groupByRegulatoryType, convertArraysToPortfolioLists)(this)
  }

  sortByPosition() {
    const portfolioList = new PortfolioList(...this)

    return portfolioList.sort((a, b) => {
      const portfolioAPosition = a.display.position
      const portfolioBPosition = b.display.position
      const portfolioAHasPosition = typeof portfolioAPosition === 'number'
      const portfolioBHasPosition = typeof portfolioBPosition === 'number'

      if (portfolioAHasPosition && portfolioBHasPosition) {
        return portfolioAPosition - portfolioBPosition
      }

      return portfolioAHasPosition ? -1 : portfolioBHasPosition ? 1 : 0
    })
  }

  /**
   * @return {PortfolioList}
   */
  getFlatSortedList(regulatoryTypesOrder) {
    const groupedPortfolios = this.groupByRegulatoryType()

    const portfolios = regulatoryTypesOrder.reduce((acc, regulatoryType) => {
      const portfolios = groupedPortfolios[regulatoryType] || []

      return [...acc, ...portfolios]
    }, [])

    return new PortfolioList(...portfolios)
  }
}
