import React from 'react'
import PropTypes from 'prop-types'

import { goTo, urlTo } from 'helpers/router.js'
import { trackEvent } from 'helpers/analytics'
import localstore from 'helpers/localstore.js'

import { useCallback, useState, useContext, useEffect, useActions, useSelector } from 'hooks'

import { ExecutingError } from 'app/pages/Dashboard/Goals/components/BankAccount/ExecutingError.jsx'

import { Cancel, Executing, Failing, Processing, Hung } from './states'

import { getPaymentInfo } from 'app/effector/bank-accounts/api'
import { fetchGoal as fetchGoalActionCreator } from 'app/redux/actions/portfolios'

import { ApiError } from 'app/redux/models/errors'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'
import { useRequestDataWithInterval } from 'app/pages/Dashboard/Goals/hooks'

import { requestStates } from './requestStates.js'
import { bankAccountStates } from 'constants/bankAccounts.js'
import { manageTypes } from 'constants/portfolio'

import { openReviewModal } from 'app/effector/review'

const finalStates = [
  requestStates.EXECUTED,
  requestStates.CANCELLED,
  requestStates.FAILED,
  requestStates.REJECTED,
  requestStates.HUNG,
]

const InstantPayment = ({ goal, location }) => {
  const { id } = location.query

  const { tunnelQuery } = useContext(ChangeGoalTunnelContext)
  const [paymentInfo, setPaymentInfo] = useState({})
  const [fetchGoal] = useActions([fetchGoalActionCreator])
  const client = useSelector((state) => state.client)
  const portfolios = useSelector((state) => state.portfolios.items)
  const { id: goalId, ...queryWithoutId } = tunnelQuery || {}
  const paymentIds = localstore.get('finalisedInstantPaymentIds') || []

  const updateGoalInfo = useCallback(async () => {
    if (goal) {
      await fetchGoal(goal.id, { setNotValidBefore: false, setValidAfter: false })
    }
  }, [goal, fetchGoal])

  const getUrl = useCallback(
    (module = '') => urlTo(`dashboard.portfolio${module}`, { id: goal.id }, queryWithoutId),
    [goal.id, queryWithoutId],
  )

  const redirectToDashboard = useCallback(() => {
    goTo(getUrl(), { scrollToTop: false, replace: true })
    updateGoalInfo()
  }, [getUrl, updateGoalInfo])

  const afterPaymentRedirect = useCallback(() => {
    if (goal.manage_type === manageTypes.DIY && !goal.is_autoinvest_enabled) {
      goTo(getUrl('.autoinvest-suggestion'))
    } else {
      goTo(getUrl())
    }
    if (
      [manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(goal.manage_type) &&
      paymentInfo.state === requestStates.EXECUTED
    ) {
      openReviewModal()
    }
  }, [goal.manage_type, goal.is_autoinvest_enabled, getUrl, paymentInfo.state])

  const handleHungPayment = useCallback(() => {
    setPaymentInfo({ state: requestStates.HUNG })
    updateGoalInfo()
  }, [setPaymentInfo, updateGoalInfo])

  const getIsFirstPayment = useCallback(() => {
    return portfolios?.every((portfolio) => !portfolio.first_topup) ?? false
  }, [portfolios])

  const handleRequestStatus = useCallback(async () => {
    if (!id) {
      return
    }

    const paymentInfo = await getPaymentInfo(id)

    if (paymentIds === undefined || !paymentIds.includes(id)) {
      if (finalStates.includes(paymentInfo.state)) {
        trackEvent({
          category: 'Add funds',
          action: 'Status finalised',
          label: paymentInfo.state,
          value: paymentInfo.amount,
          portfolioRegulatoryType: goal.regulatory_type,
          portfolioPresetType: goal.preset_type,
        })

        const analyticsData = {
          amount: paymentInfo.amount,
          client_type: client.type,
          manage_type: goal.manage_type,
          payment_method: 'OPEN_BANKING',
          first_payment: getIsFirstPayment(),
        }

        if (paymentInfo.state === requestStates.EXECUTED) {
          trackEvent(
            {
              action: 'payment_success',
              ...analyticsData,
            },
            { plugins: { 'google-analytics-v3': false } },
          )
        }

        if ([requestStates.CANCELLED, requestStates.FAILED, requestStates.REJECTED].includes(paymentInfo.state)) {
          trackEvent(
            {
              action: 'payment_cancelled',
              ...analyticsData,
            },
            { plugins: { 'google-analytics-v3': false } },
          )
        }
      }

      paymentIds.push(id)
      localstore.set('finalisedInstantPaymentIds', paymentIds)
    }

    if (paymentInfo instanceof ApiError) {
      throw paymentInfo
    }

    return () => {
      updateGoalInfo()
      return setPaymentInfo(paymentInfo)
    }
  }, [
    id,
    paymentIds,
    goal.regulatory_type,
    goal.preset_type,
    goal.manage_type,
    portfolios,
    client.type,
    updateGoalInfo,
  ])

  useEffect(() => {
    if (paymentIds === undefined || !paymentIds.includes(id)) {
      trackEvent({
        category: 'Add funds',
        action: 'Finalise ‘Add money’',
        label: 'Open Banking',
        portfolioRegulatoryType: goal.regulatory_type,
        portfolioPresetType: goal.preset_type,
      })
    }

    if (!id) {
      redirectToDashboard()
    }
  }, [])

  useRequestDataWithInterval(handleRequestStatus, handleHungPayment, finalStates.includes(paymentInfo.state))

  if (paymentInfo.state === requestStates.EXECUTED) {
    return paymentInfo.bank_account_state === bankAccountStates.APPROVED ? (
      <Executing amount={paymentInfo.amount} onContinue={afterPaymentRedirect} portfolio={goal} />
    ) : (
      <ExecutingError
        title="Your transfer requires some additional checks. Please bear with us."
        description="Our team is going to check the details and get back to you"
        onContinue={redirectToDashboard}
      />
    )
  }
  if (paymentInfo.state === requestStates.CANCELLED) {
    return <Cancel onContinue={redirectToDashboard} />
  }
  if ([requestStates.REJECTED, requestStates.FAILED].includes(paymentInfo.state)) {
    return <Failing onContinue={redirectToDashboard} />
  }
  if (paymentInfo.state === requestStates.HUNG) {
    return <Hung onContinue={afterPaymentRedirect} />
  }

  return <Processing onContinue={afterPaymentRedirect} />
}

InstantPayment.propTypes = {
  goal: PropTypes.object.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
}

export { InstantPayment }
