import { useUnit } from 'effector-react'

import { useCallback } from 'hooks'

import { $companyStore, updateOrCreateCompanyFx, saveCompanyAddressFx } from 'app/effector/company'

import { ApiError } from 'app/redux/models/errors'

const useCompany = () => {
  const { company } = useUnit($companyStore)

  const updateCompany = useCallback(async () => {
    const saveCompanyAddressResult = await saveCompanyAddressFx(company.addresses)

    if (saveCompanyAddressResult instanceof ApiError) {
      return { result: false, error: saveCompanyAddressResult }
    }

    const updateCompanyResult = await updateOrCreateCompanyFx({
      company,
      keys: ['name', 'uk_number', 'business_type_codes', 'lei_number'],
    })

    if (updateCompanyResult instanceof ApiError) {
      return { result: false, error: updateCompanyResult }
    }

    return { result: true }
  }, [company])

  return { company, updateCompany }
}

export { useCompany }
