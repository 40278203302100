import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

/**
 * @class
 * @typedef {Object} IsaDetails
 */
export class IsaDetails extends Entity {
  constructor() {
    super()

    /** @type {boolean} */
    this.transferring_in_process = false
    /** @type {?number} */
    this.current_year_allowance = null
    /** @type {?number} */
    this.remaining_allowance = null
  }

  /** @override */
  getRules() {
    return {
      transferring_in_process: [[rules.bool]],
      current_year_allowance: [[rules.float]],
      remaining_allowance: [[rules.float]],
    }
  }
}
