import axios from 'helpers/ajax/index.js'

import { ApiError } from 'app/redux/models/errors/index.js'

async function startDocumentCheck(contactId: number | string): Promise<undefined | ApiError> {
  try {
    await axios.post(`id_check/documents/start/${contactId}/`)
  } catch (error) {
    return new ApiError(error)
  }
}

export { startDocumentCheck }
