import React, { Fragment } from 'react'

import { useActions, useEffect, useSelector } from 'hooks'

import { fetchClient as fetchClientActionCreator } from 'app/redux/actions/client'

import { GatewayDest } from 'components/atoms/Gateway'

import { Preloader } from 'components/molecules/Preloader'

type TwoFactorSetupLayoutProps = {
  isLoading?: boolean
  children: React.ReactNode
}

const TwoFactorSetupLayout = ({ isLoading, children }: TwoFactorSetupLayoutProps): React.ReactElement => {
  const isClientFetched = useSelector((state) => state.client.is_fetched)

  const [fetchClient] = useActions([fetchClientActionCreator])

  useEffect(() => {
    if (!isClientFetched) {
      fetchClient()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClientFetched])

  return (
    <Fragment>
      {children}

      <Preloader loading={isLoading ?? !isClientFetched} size="big" zIndex />

      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

export { TwoFactorSetupLayout }
