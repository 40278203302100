import React from 'react'

import { useMemo } from 'hooks'

import { format as formatMoney } from 'helpers/money'
import { palette } from 'helpers/palette'

import { type QuickStart } from 'app/effector/quickStart/types'
import { type DirectDebitSubscription, type RecurringPaymentSubscription } from 'app/effector/recurringPayments'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './SavingPlanBadge.css'

type SavingPlanProps = {
  subscription: DirectDebitSubscription | RecurringPaymentSubscription
  quickStart?: QuickStart
}

const frequencyDict = {
  MONTHLY: 'a month',
  WEEKLY: 'a week',
  FORTNIGHT: 'every 2 weeks',
}

const SavingPlanBadge = ({ subscription, quickStart }: SavingPlanProps): React.ReactElement => {
  const content = useMemo(() => {
    if (subscription) {
      const frequency = (subscription as RecurringPaymentSubscription).frequency || 'MONTHLY'

      return (
        <ItemWithIcon
          space={8}
          icon={<Icon type="success-24" size={16} color={palette['content-on-background-primary']} />}
          content={
            <Typography size={14} lineHeight="small">
              <Typo>
                Savings Plan {formatMoney(subscription.amount, true)} {frequencyDict[frequency]}
              </Typo>
            </Typography>
          }
          contentVerticalAlign="center"
        />
      )
    }

    if (quickStart) {
      return (
        <ItemWithIcon
          space={8}
          icon={<Icon type="steps" size={16} color={palette['content-on-background-primary']} />}
          content={
            <Typography size={14} lineHeight="small" color="on_background_primary">
              <Typo>Finish setting up Savings Plan {formatMoney(quickStart.amount, true)}</Typo>
            </Typography>
          }
          contentVerticalAlign="center"
        />
      )
    }

    return null
  }, [subscription, quickStart])

  return (
    <Paper className="SavingPlanBadge" top={8} right={12} bottom={8} left={12} inline data-test-id="savingsPlanBadge">
      {content}
    </Paper>
  )
}

export { SavingPlanBadge }
