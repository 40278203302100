import { useState, useCallback, useActions, useLoading } from 'hooks'

import { patchPortfolio as patchPortfolioActionCreator } from 'app/redux/actions/portfolios'
import { showSuccessToast, showFailToast } from 'app/redux/actions/ui'
import type { Portfolio } from 'app/redux/models/portfolio/types'

type UseAutoinvsetToggleInterface = {
  isLoading: boolean
  isActive: boolean
  activate: () => Promise<void>
  deactivate: () => Promise<void>
}

const useAutoinvestToggle = (portfolio: Portfolio): UseAutoinvsetToggleInterface => {
  const [isActive, setIsActive] = useState(portfolio.is_autoinvest_enabled)
  const { isLoading, wait } = useLoading()
  const patchPortfolio = useActions(patchPortfolioActionCreator)

  const setAutoinvest = useCallback(
    async (status) => {
      const response = await wait(
        patchPortfolio(
          portfolio.id,
          { is_autoinvest_enabled: status },
          { setNotValidBefore: false, setValidAfter: false },
        ),
      )

      if (response.portfolios.error) {
        return {
          error: response.portfolios.error?.response?.data?.is_autoinvest_enabled?.[0] || 'Something went wrong',
        }
      }

      return {}
    },

    [patchPortfolio, portfolio.id, wait],
  )

  const activate = useCallback(async () => {
    setIsActive(true)
    const { error } = await setAutoinvest(true)

    if (error) {
      setIsActive(false)
      showFailToast(error)
    } else {
      showSuccessToast('AutoInvest activated')
    }
  }, [setAutoinvest])

  const deactivate = useCallback(async () => {
    setIsActive(false)
    const { error } = await setAutoinvest(false)

    if (error) {
      setIsActive(true)
      showFailToast(error)
    } else {
      showSuccessToast('AutoInvest deactivated')
    }
  }, [setAutoinvest])

  return {
    isLoading,
    isActive,
    activate,
    deactivate,
  }
}

export { useAutoinvestToggle }
