import React from 'react'

import { useMediaQueries } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './GraphPlaceholder.css'

type GraphPlaceholderProps = {
  linkText?: string
  onLinkClick?: () => void
}

const GraphPlaceholder = ({ linkText, onLinkClick }: GraphPlaceholderProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Paper className="GraphPlaceholder" top={desktop ? 80 : 24} bottom={desktop ? 80 : 24}>
      <Paper top={8} bottom={8}>
        <Typography size={desktop ? 24 : 18} lineHeight="small" align="center">
          <Typo>£0</Typo>
        </Typography>
        <Paper top={desktop ? 12 : 8}>
          <Typography size={desktop ? 16 : 14} lineHeight="small" color="minor" align="center">
            <Typo>Nothing to show just yet</Typo>
          </Typography>
        </Paper>
        {linkText && onLinkClick && (
          <Paper top={8}>
            <Link onClick={onLinkClick}>
              <Typography size={desktop ? 16 : 14} lineHeight="small" color="inherit" align="center">
                <Typo>{linkText}</Typo>
              </Typography>
            </Link>
          </Paper>
        )}
      </Paper>
    </Paper>
  )
}

export { GraphPlaceholder }
