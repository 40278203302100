import React, { Fragment } from 'react'

import { useActions, useCallback, useMediaQueries, useSelector, useState } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'
import { combineErrors, backendErrorsToObj, emailRules, bulkValidate } from 'helpers/validation.js'

import {
  forgotPassword as forgotPasswordActionCreator,
  resetError as resetErrorActionCreator,
} from 'app/redux/actions/client'
import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'

import Button from 'components/_old/Button/Button.jsx'
import Form, { Fieldset } from 'components/_old/Form/Form.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

type ForgotPasswordProps = {
  location: {
    query: {
      email: string
    }
  }
}

const ForgotPassword = ({ location }: ForgotPasswordProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const [email, setEmail] = useState(location?.query?.email || '')
  const error = useSelector(({ client }) => client?.error)
  const [forgotPassword, resetError] = useActions([forgotPasswordActionCreator, resetErrorActionCreator])
  const [isEmailValid, setIsEmailValid] = useState(true)

  const handleEmailChange = useCallback(
    (event, value) => {
      resetError()
      setEmail(value)
    },
    [resetError],
  )

  const validation = combineErrors(
    {
      email: emailRules(email),
    },
    backendErrorsToObj(error),
  )

  const handleSubmit = useCallback(() => {
    if (validation.email.errors.length > 0) {
      setIsEmailValid(false)
    }

    if (!bulkValidate(validation)) return

    forgotPassword({ email }).then((nextState) => {
      if (!nextState.client.error) {
        goTo(urlTo('login'))
        showSuccessToast('Instructions sent to your email')
      } else {
        showFailToast()
      }
    })
  }, [email, forgotPassword, validation])

  const inputMods = { size: 'bigger' }

  const backUrl = urlTo('login')
  const backToLogin = (
    <Typography align={desktop ? 'left' : 'center'} size={desktop ? 12 : 16}>
      <Link to={backUrl} data-test-id="forgotPasswordBackToLoginLink">
        Back to login
      </Link>
    </Typography>
  )

  const submitButton = (
    <Button
      type="submit"
      mods={{ size: 'big block', color: 'green', theme: !desktop ? 'not-rounded' : null }}
      onClick={handleSubmit}
      data-test-id="forgotPasswordSubmitButton"
    >
      Send reset email
    </Button>
  )

  const formCard = (
    <SubmitOnEnter>
      <Form>
        <Fieldset>
          <Paper bottom={desktop ? 24 : 40}>
            <Validate rules={validation.email.rules} skipWaitBlur={!isEmailValid}>
              <Label errorMessages={validation.email.errors}>
                Your email
                <Input
                  mods={inputMods}
                  type="email"
                  onChange={handleEmailChange}
                  onFocus={() => {
                    setIsEmailValid(true)
                  }}
                  tabIndex={1}
                  data-test-id="forgotPasswordEmailInput"
                >
                  {email}
                </Input>
              </Label>
            </Validate>
          </Paper>
          <Paper bottom={desktop ? 24 : 40}>{backToLogin}</Paper>
          {desktop && submitButton}
        </Fieldset>
      </Form>
    </SubmitOnEnter>
  )

  const title = 'Forgot password'

  return (
    <Fragment>
      {desktop ? (
        <Desktop title={title} isResetPasswordPage>
          {formCard}
        </Desktop>
      ) : (
        <Mobile title={title} button={submitButton} backUrl={backUrl ?? '/'}>
          {formCard}
        </Mobile>
      )}
    </Fragment>
  )
}

export { ForgotPassword }
