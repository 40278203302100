import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

type CantHideModalProps = {
  isOpen: boolean
  onClose: () => void
}

const CantHideModal = ({ isOpen, onClose }: CantHideModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const headerNode = <NavigationBar rightPartText="Close" onRightPartClick={onClose} />

  const content = (
    <Fragment>
      <Paper top={80} bottom={32}>
        <Typography tag="h1" size={24} lineHeight="small" align="center" weight="semibold">
          <Typo>
            This portfolio can not be hidden
            <br />
            because there is a Savings Plan set up for it
          </Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  const button = (
    <Button mods={{ size: 'big block' }} onClick={onClose}>
      Ok
    </Button>
  )

  if (desktop)
    return (
      <Modal open={isOpen} close={null} onClose={onClose}>
        <Width size={36}>
          <Paper top={8} bottom={56} right={24} left={24}>
            {headerNode}
          </Paper>
          {content}
          <Paper bottom={80} left={96} right={96}>
            {button}
          </Paper>
        </Width>
      </Modal>
    )

  return (
    <Modal open={isOpen} close={null} onClose={onClose}>
      <MobileLayout
        header={headerNode}
        content={content}
        footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
}

export { CantHideModal }
