import React, { Fragment, type ReactNode } from 'react'

import { useState, useEffect, useMemorizedState, MemorizedStateStorages } from 'hooks'

import { getBonuses } from 'helpers/getBonuses'
import { urlTo } from 'helpers/router.js'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Relative } from 'components/atoms/Relative'
import Rotator from 'components/atoms/Rotator/Rotator'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Preloader } from 'components/molecules/Preloader'

import { DesktopFooter } from 'app/containers/Footer'

import { type LoginProps } from '../Login'

import howWeHelpYouSaveSrc1x from './assets/how-we-help-you-save@1x.png'
import howWeHelpYouSaveSrc2x from './assets/how-we-help-you-save@2x.png'
import referAFriendSrc1x from './assets/refer-a-friend@1x.png'
import referAFriendSrc2x from './assets/refer-a-friend@2x.png'
import AdvertisementBanner from './components/AdvertisementBanner'

type DesktopProps = {
  children: ReactNode
  title: string
  queryParams?: LoginProps['location']['query']
  isLoading?: boolean
}

type Bonuses = {
  referFriendOrBusinessBonusMax: string
  referIsaOrGeneralBonusMin: string
  referIsaOrGeneralBonusMax: string
}

type Banner = {
  title: string
  description: string
  linkLabel: string
  linkHref: string
  imageSrc: string
  imageRetinaSrc: string
}

const getBanners = (bonuses: Bonuses | undefined): Banner[] => {
  const banners = [
    {
      title: 'How we help you save',
      description:
        'Introducing InvestEngine Impact! How much have our clients saved in investment fees? What were our clients’ favourite ETFs last year? Check out the full breakdown below.',
      linkLabel: 'Find out more',
      linkHref: '/impact/',
      imageSrc: howWeHelpYouSaveSrc1x,
      imageRetinaSrc: howWeHelpYouSaveSrc2x,
    },
  ]

  if (bonuses) {
    banners.push({
      title: `Earn up to ${bonuses.referFriendOrBusinessBonusMax} when you refer a friend or business`,
      description: `Kickstart your portfolio with an investment bonus of between ${bonuses.referIsaOrGeneralBonusMin}&nbsp; & &nbsp;${bonuses.referIsaOrGeneralBonusMax} when you refer an ISA or General account, or up to ${bonuses.referFriendOrBusinessBonusMax} for a Business account!`,
      linkLabel: 'Refer a friend',
      linkHref: '/refer-a-friend/',
      imageSrc: referAFriendSrc1x,
      imageRetinaSrc: referAFriendSrc2x,
    })
  }

  return banners
}

const Desktop = ({ children, title, queryParams = {}, isLoading }: DesktopProps): React.ReactElement => {
  const [visibleBanner, setVisibleBanner] = useState<number | undefined>()
  const [bonuses, setBonuses] = useMemorizedState<Bonuses | undefined>('Login/', null, {
    storage: MemorizedStateStorages.SESSION,
  })

  const banners = getBanners(bonuses)

  const headerRight = (
    <span>
      <Paper right={20} style={{ display: 'inline' }}>
        <Typography tag="span" color="minor" size={14}>
          Don’t have an account?
        </Typography>
      </Paper>
      <Button mods={{ color: 'green', size: 'small', text: 'smaller' }}>
        <Link to={urlTo('pre-flight', {}, queryParams)} classnameless data-test-id="getStartedButton">
          Get started
        </Link>
      </Button>
    </span>
  )

  const header = <Header right={headerRight} withShadow logoLink="/" hard />

  const content = (
    <div className="Login-Wrapper">
      <Inner>
        <ColumnarLayout mods={{ 'column-content': 'center' }}>
          <Column size={0}>
            <Width size={28}>
              <Card
                mods={{
                  theme: 'white shadowed',
                  'no-padding': 'all',
                }}
                className="Login-Card"
              >
                <Relative>
                  <Paper top={48} right={72} bottom={48} left={72}>
                    <Paper bottom={32}>
                      <Typography tag="h1" size={32} align="center" weight="semibold" lineHeight="small">
                        {title}
                      </Typography>
                    </Paper>
                    {children}
                    <Preloader loading={isLoading} size="big" zIndex absolute />
                  </Paper>
                </Relative>
              </Card>
            </Width>
          </Column>
          <Column>
            <Rotator showChild={visibleBanner}>
              {banners.map(({ title, description, linkLabel, linkHref, imageSrc, imageRetinaSrc }) => (
                <AdvertisementBanner
                  key={title}
                  title={title}
                  description={description}
                  linkLabel={linkLabel}
                  linkHref={linkHref}
                  imageSrc={imageSrc}
                  imageRetinaSrc={imageRetinaSrc}
                />
              ))}
            </Rotator>
          </Column>
        </ColumnarLayout>
      </Inner>
    </div>
  )

  useEffect(() => {
    if (bonuses === null) {
      getBonuses().then((result) => {
        setBonuses(result)
      })
    }
    setVisibleBanner(Math.floor(Math.random() * banners.length))
  }, [banners.length, bonuses, setBonuses])

  return (
    <Fragment>
      <DesktopLayout header={header} content={content} footer={<DesktopFooter />} noGaps />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { Desktop }
