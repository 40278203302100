import React from 'react'
import PropTypes from 'prop-types'

import { propTypes } from 'helpers/propTypes'

import { useCallback, useEffect, useMediaQueries, useMemo, useState } from 'hooks'

import { NavigationBar } from 'components/atoms/NavigationBar'
import Link from 'components/_old/Link/Link.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { UploadBankStatementForm } from 'components/organisms/UploadBankStatementForm'

import { BankAccount } from 'app/effector/bank-accounts/models/bank'

const fillArrayWithFalse = (length) => Array.from({ length }, (i) => (i = false))

const UploadStatement = ({ bankAccounts, onOpen, onClose }) => {
  const { desktop } = useMediaQueries()

  const [currentIndex, setCurrentIndex] = useState(0)
  const [uploadedStatements, setUploadedStatements] = useState(fillArrayWithFalse(bankAccounts.length))

  useEffect(() => {
    setUploadedStatements(fillArrayWithFalse(bankAccounts.length))
  }, [bankAccounts])

  useEffect(() => {
    if (typeof onOpen === 'function') {
      onOpen()
    }
  }, [])

  const currentBank = bankAccounts[currentIndex]

  const handleNext = useCallback(() => {
    if (currentIndex < bankAccounts.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
    if (currentIndex === bankAccounts.length - 1) {
      onClose(uploadedStatements.some(Boolean))
    }
  }, [currentIndex, bankAccounts, uploadedStatements])

  const handlePrev = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }, [currentIndex])

  const handleUpload = useCallback(() => {
    setUploadedStatements([
      ...uploadedStatements.slice(0, currentIndex),
      true,
      ...uploadedStatements.slice(currentIndex + 1),
    ])
  }, [currentIndex, uploadedStatements, setUploadedStatements])

  const titleCount = bankAccounts.length > 1 ? ` (${currentIndex + 1} of ${bankAccounts.length})` : ''

  const header = useMemo(
    () =>
      desktop ? (
        <Paper top={16} right={20} left={20}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1}>
              {currentIndex > 0 && (
                <Link onClick={handlePrev} mods={{ color: 'content-on-background-default' }}>
                  <ItemWithIcon
                    space={16}
                    icon={<Icon type="back-new" size={20} color="inherit" />}
                    content={<Typography color="inherit">Back</Typography>}
                    iconVerticalAlign="center"
                  />
                </Link>
              )}
            </Column>
            <Column size={1}>
              <Link onClick={handleNext} mods={{ color: 'content-on-background-default' }}>
                <Paper top={4}>
                  <Typography align="right" lineHeight="small" color="inherit">
                    Skip
                  </Typography>
                </Paper>
              </Link>
            </Column>
          </ColumnarLayout>
        </Paper>
      ) : (
        <NavigationBar
          leftPartText={currentIndex > 0 ? 'Back' : null}
          onLeftPartClick={handlePrev}
          rightPartText="Skip"
          onRightPartClick={handleNext}
        >
          Set up Open Banking
        </NavigationBar>
      ),
    [desktop, currentIndex, handlePrev, handleNext],
  )

  return (
    <UploadBankStatementForm
      header={header}
      title={`We need help verifying your bank account details${titleCount}`}
      bankAccount={{ ...currentBank, bank_statement_filled: uploadedStatements[currentIndex] }}
      onAfterUpload={handleUpload}
      onContinue={handleNext}
    />
  )
}

UploadStatement.propTypes = {
  bankAccounts: PropTypes.arrayOf(propTypes.instanceOf(BankAccount)),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
}

export { UploadStatement }
