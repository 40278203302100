import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

/**
 * @class
 * @typedef {Object} PortfolioDisplayItem
 */
export class PortfolioDisplayItem extends Entity {
  constructor() {
    super()

    /** @type {number} */
    this.position = null
    /** @type {boolean} */
    this.is_hidden = false
    /** @type {number} */
    this.regulatory_type_position = null
  }

  /** @override */
  getRules() {
    return {
      position: [[rules.int]],
      regulatory_type_position: [[rules.int]],
      is_hidden: [[rules.bool]],
    }
  }
}
