import React from 'react'

import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'

import { AnalyticsMobileLayout } from '../../Layouts/AnalyticsMobileLayout.jsx'
import { type IAssetsProps } from '../Assets'

import { AssetsList } from './components/AssetsList'
import { Summary } from './components/Summary'

const Mobile = ({
  isLoading,
  scrollableElementRef,
  fromPortfolio,
  portfoliosOptions,
  selected,
  selectedPortfolio,
  selectedPortfolioTitle,
  showTarget,
  summary,
  handlePortfolioSelect,
  handleBackToPortfolio,
  tabs,
}: IAssetsProps): React.ReactElement => {
  const summaryNode = (
    <Paper top={32}>
      <Skeleton shown={isLoading} mix>
        <Summary summary={summary} selectedPortfolio={selectedPortfolio} />
      </Skeleton>
    </Paper>
  )

  const equitiesNode = <AssetsList isLoading={isLoading} summary={summary} showTarget={showTarget} />

  return (
    <AnalyticsMobileLayout
      isLoading={isLoading}
      scrollableElementRef={scrollableElementRef}
      fromPortfolio={fromPortfolio}
      portfoliosOptions={portfoliosOptions}
      selected={selected}
      selectedPortfolio={selectedPortfolio}
      selectedPortfolioTitle={selectedPortfolioTitle}
      handlePortfolioSelect={handlePortfolioSelect}
      handleBackToPortfolio={handleBackToPortfolio}
      tabs={tabs}
      topContent={summaryNode}
      bottomContent={equitiesNode}
    />
  )
}

export { Mobile }
