import React from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './TradingSuspendedWarning.css'

const TradingSuspendedWarning = ({ noTopPadding = false }: { noTopPadding: boolean }): JSX.Element => {
  const { desktop } = useMediaQueries()
  const topPadding = noTopPadding ? 0 : desktop ? 64 : 40

  return (
    <div className="TradingSuspendedWarning">
      <Paper top={topPadding} bottom={desktop ? 32 : 20}>
        <Icon size={64} type="stop-64" color={palette['status-error']} />
      </Paper>
      <Typography size={desktop ? 32 : 24} weight="semibold" align="center" lineHeight="small">
        Temporarily suspended
        <br />
        for trading
      </Typography>
      <Paper top={24}>
        <Typography size={desktop ? 16 : 14} align="center">
          If you have any questions please contact us
          <br />
          by writing to{' '}
          <Link to="mailto:support@investengine.com" hard>
            support@investengine.com
          </Link>
          {/* {' '}
          <br />
          or calling{' '}
          <Link to="tel:0800 808 5771" mods={{ color: 'content-on-background-default' }} hard>
            <Nobr>0800 808 5771</Nobr>
          </Link>{' '}
          or{' '}
          <Link to="tel:+44 (0) 208 051 9 501" mods={{ color: 'content-on-background-default' }} hard>
            <Nobr>+44 (0) 208 051 9 501</Nobr>
          </Link> */}
          .
        </Typography>
      </Paper>
    </div>
  )
}

export { TradingSuspendedWarning }
