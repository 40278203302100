import React from 'react'

import { CheckPaymentDetails } from './CheckPaymentDetails'
import { DirectDebit } from './DirectDebit'
import { DirectDebitGuarantee } from './DirectDebitGuarantee'
import { PromoFirstPayment } from './PromoFirstPayment'
import { PromoMonthlyPayment } from './PromoMonthlyPayment'
import { StopMonthlyPayment } from './StopMonthlyPayment'
import { SuccessPayment } from './SuccessPayment'

const routes = [
  {
    module: 'direct-debit',
    path: 'direct-debit/',
    component: function Component(props) {
      return <PromoMonthlyPayment {...props} />
    },
    childRoutes: [
      {
        module: 'direct-debit-form',
        path: 'direct-debit-form/',
        component: function Component(props) {
          return <DirectDebit {...props} />
        },
      },
      {
        module: 'direct-debit-details',
        path: 'details/',
        component: function Component(props) {
          return <CheckPaymentDetails {...props} />
        },
      },
      {
        module: 'direct-debit-success',
        path: 'success/',
        component: function Component(props) {
          return <SuccessPayment {...props} />
        },
      },
      {
        module: 'direct-debit-stop-subscription',
        path: 'stop-subscription/',
        component: function Component(props) {
          return <StopMonthlyPayment {...props} />
        },
      },
      {
        module: 'direct-debit-guarantee',
        path: 'guarantee/',
        component: function Component(props) {
          return <DirectDebitGuarantee {...props} />
        },
      },
      {
        module: 'fund-your-portfolio',
        path: 'fund-your-portfolio/',
        component: function Component(props) {
          return <PromoFirstPayment {...props} />
        },
      },
    ],
  },
]

export { routes }
