import React from 'react'

import type { RouteProps } from 'app/pages/Dashboard/UserProfile/types'

import { SettingsLayout } from '../components/SettingsLayout'

import { LoginSettings as LoginSettingsComponent } from './components/LoginSettings.jsx'

type LoginSettingsProps = {
  routes: RouteProps[]
  location: Record<string, any>
}

const LoginSettings = ({ routes, location }: LoginSettingsProps): React.ReactElement => {
  return (
    <SettingsLayout title="Login settings">
      <LoginSettingsComponent routes={routes} location={location} />
    </SettingsLayout>
  )
}

export { LoginSettings }
