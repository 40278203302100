import React from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'

import { palette, hexToRgb, rgbToRgba, multiplyRgba } from 'helpers/palette'
import { primitivePropsAreEqual } from 'helpers/react'

import { useCallback, useEffect, useMemo, useMemorizedState, useRef } from 'hooks'

import './MaxLines.css'

const MaxLines = React.memo(
  ({ children, cacheName, defaultMaxHeight, lines = 1, color = palette['background-default'], onAfterModify }) => {
    const ref = useRef()
    const [lineHeight, setLineHeight] = useMemorizedState(`MaxLines${cacheName ? `/${cacheName}` : ''}`, 0)
    const background = useMemo(() => {
      const getMultipliedColor = () => {
        const colorCssValue = color.match(/(--.*)\)/)?.[1]
        const colorValue = getComputedStyle(document.documentElement).getPropertyValue(colorCssValue)

        if (colorValue) {
          const rgbaColorValue = /#/.test(colorValue) ? hexToRgb(colorValue) : colorValue

          if (rgbaColorValue) {
            return multiplyRgba(rgbaColorValue, 'rgba(255, 255, 255)')
          }
        }

        return `rgba(0, 0, 0, 0)`
      }

      const multipliedColor = getMultipliedColor()
      const leftColor = rgbToRgba(multipliedColor, 0)
      const rightColor = rgbToRgba(multipliedColor, 1)

      return `linear-gradient(to right, ${leftColor} 0%, ${rightColor} 100%)`
    }, [color])

    const getLineHeight = useCallback((element) => {
      if (element) {
        return parseFloat(window.getComputedStyle(element)?.lineHeight)
      }

      return null
    }, [])

    const getHeight = useCallback((element) => {
      if (element) {
        return element?.getBoundingClientRect?.()?.height
      }

      return null
    }, [])

    const reset = useCallback((element) => {
      if (element) {
        element.classList.remove('MaxLines_exceeds')
        element.style.maxHeight = ''
      }
    }, [])

    const modify = useCallback((element, nextMaxHeight) => {
      if (element) {
        element.classList.add('MaxLines_exceeds')
        element.style.maxHeight = `${nextMaxHeight}px`
      }
    }, [])

    useEffect(() => {
      const nextLineHeight = getLineHeight(ref.current)

      if (lineHeight !== nextLineHeight) {
        setLineHeight(nextLineHeight)
      }
    }, [setLineHeight, getLineHeight(ref.current)])

    useEffect(() => {
      reset(ref.current)

      const height = getHeight(ref.current)
      const nextMaxHeight = lineHeight * lines

      if (height > nextMaxHeight) {
        modify(ref.current, nextMaxHeight)

        if (isFunction(onAfterModify)) {
          onAfterModify()
        }
      } else {
        if (isFunction(onAfterModify)) {
          onAfterModify()
        }
      }
    }, [children, lines, lineHeight, onAfterModify, ref.current])

    return (
      <div ref={ref} className="MaxLines" style={defaultMaxHeight ? { maxHeight: defaultMaxHeight } : null}>
        {children}
        <span className="MaxLines-Gradient" style={{ height: lineHeight, background }} />
      </div>
    )
  },
  primitivePropsAreEqual,
)

MaxLines.displayName = 'MaxLines'

MaxLines.propTypes = {
  children: PropTypes.node,
  cacheName: PropTypes.string,
  defaultMaxHeight: PropTypes.number,
  lines: PropTypes.number,
  color: PropTypes.string,
  onAfterModify: PropTypes.func,
}

export { MaxLines }
