import { useActions, useCallback, useEffect, useMemo, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router'

import { patchPortfolio as patchPortfolioActionCreator } from 'app/redux/actions/portfolios'
import {
  saveClientAnswer as saveClientAnswerActionCreator,
  setValid as setValidActionCreator,
  resetError as resetErrorActionCreator,
} from 'app/redux/actions/questionnaire'
import { showFailToast } from 'app/redux/actions/ui'
import { getClientAnswerValues } from 'app/redux/selectors'

import { types as clientTypes } from 'constants/client'
import { questionTypes } from 'constants/questionnaire.js'

const useQuestion = ({ params = {}, prevStep, redirectToNextStep, onAfterSave, analyticsCategory, location }) => {
  const { id } = params
  const portfolioId = useMemo(() => parseInt(id, 10), [id])

  const questionnaire = useSelector((state) => state.questionnaire)
  const clientAnswerValues = useSelector(getClientAnswerValues)
  const portfolio = useSelector((state) => state.portfolios.list.get(portfolioId))
  const client = useSelector((state) => state.client)
  const isBusiness = client.type === clientTypes.BUSINESS

  const [saveClientAnswer, setValid, resetError, patchPortfolio] = useActions([
    saveClientAnswerActionCreator,
    setValidActionCreator,
    resetErrorActionCreator,
    patchPortfolioActionCreator,
  ])

  const questions = questionnaire.questionList.questions.filter(({ type }) =>
    [questionTypes.SINGLE, questionTypes.MULTIPLE, questionTypes.TEXT].includes(type),
  )
  const code = params.questionCode
  const question = questions.find((question) => question.code === code)
  const selectedAnswer = clientAnswerValues[code]

  const index = questions.findIndex((question) => question.code === code)
  const isFinalStep = index === questions.length - 1
  const initialListIndex = questionnaire.questionList.questions.findIndex((question) => question.code === code)
  const actualIndex = isBusiness ? initialListIndex + 1 : initialListIndex

  useEffect(() => {
    trackEvent({
      action: 'questionnaire_page_visit',
      step: actualIndex,
      step_description: question?.code,
      regulatory_type: portfolio?.regulatory_type,
      version: 'default',
    })
  }, [question?.code, actualIndex, portfolio?.regulatory_type])

  const handleSubmit = useCallback(
    async (answerId, value) => {
      trackEvent({
        category: analyticsCategory,
        action: 'Questionnaire step completed',
        label: question.title,
        portfolioPresetType: portfolio?.preset_type,
        portfolioRegulatoryType: portfolio?.regulatory_type,
      })

      const answerIds = Array.isArray(answerId) ? answerId : [answerId]
      const selectedAnswers = question.answers.filter(({ id }) => answerIds.includes(id))

      trackEvent({
        action: 'questionnaire_step_completed',
        text:
          value ||
          answerIds.reduce((acc, answerId) => {
            const value = question.answers.find((answer) => answer.id === answerId).title

            if (acc === '') return value

            return `${acc}; ${value}`
          }, ''),
        step: actualIndex,
        step_description: question?.code,
        regulatory_type: portfolio?.regulatory_type,
        version: 'default',
      })

      const dataToPost = value ? { value, goal_id: portfolio?.id } : { answer_ids: answerIds, goal_id: portfolio?.id }
      const term = selectedAnswers.findLast((answer) => !!answer.extra_data?.term)?.extra_data.term ?? null
      const afterSaveState = await saveClientAnswer(code, dataToPost, false)

      if (afterSaveState.questionnaire.error) {
        setValid()
        showFailToast()
        return resetError()
      }

      if (term && term > 0 && portfolio?.id) {
        const afterPatchState = await patchPortfolio(
          portfolio.id,
          { term },
          { setNotValidBefore: false, setValidAfter: false },
        )

        if (afterPatchState.portfolios.error) {
          setValid()
          showFailToast()
          return resetError()
        }
      }

      if (isFinalStep) {
        trackEvent({
          category: analyticsCategory,
          action: 'Complete questionnaire',
          label: `${portfolio.regulatory_type} ${portfolio.preset_type}`,
          portfolioPresetType: portfolio.preset_type,
          portfolioRegulatoryType: portfolio.regulatory_type,
        })
        await onAfterSave?.()
      }

      setValid()
      redirectToNextStep()
    },
    [
      code,
      portfolio,
      analyticsCategory,
      saveClientAnswer,
      patchPortfolio,
      setValid,
      resetError,
      isFinalStep,
      onAfterSave,
      redirectToNextStep,
      question,
      actualIndex,
    ],
  )

  const handleBack = useCallback(() => {
    goTo(urlTo(prevStep.module, { ...params, questionCode: prevStep.questionCode }, location?.query))
  }, [prevStep, params, location?.query])

  return { question, selectedAnswer, handleBack, handleSubmit }
}

export { useQuestion }
