import React, { Fragment, useEffect } from 'react'

import { goTo, urlTo } from 'helpers/router.js'
import { palette } from 'helpers/palette'

import { useMediaQueries, useMemo, useOnScreen, useRef } from 'hooks'
import { useTransactions } from './hooks/useTransactions'

import Card from 'components/_old/Card/Card.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'

import { Paper } from 'components/atoms/Paper'
import { GatewayDest } from 'components/atoms/Gateway'
import { Typography } from 'components/atoms/Typography'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'

import { Preloader } from 'components/molecules/Preloader'

import { AddFundsModal } from 'app/pages/Dashboard/Goals/AddFunds'

import { manageTypes } from 'constants/portfolio'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

const Transactions = ({ location, isSecurityTransactionsPage }) => {
  const { desktop } = useMediaQueries()

  const {
    isLoading,
    isNextLoading,
    isBusiness,
    portfolios,
    pendingOrders,
    transactions,
    hasTransactions,
    hasSecurityTransactions,
    isEverythingFetched,
    hasActiveFilter,
    total,
    filters,
    fetchNextTransactions,
    resetFilters,
  } = useTransactions(location, isSecurityTransactionsPage)
  const preloaderElementRef = useRef()

  const onScreen = useOnScreen(preloaderElementRef.current)

  useEffect(() => {
    if (onScreen) {
      fetchNextTransactions()
    }
  }, [onScreen])

  const portfolioNameById = {}
  portfolios.forEach((item) => {
    portfolioNameById[item.value] =
      item.manageType === manageTypes.CASH && !isBusiness ? `${item.name} ${item.description}` : item.name
  })

  const portfolioReferenceById = {}
  portfolios.forEach((item) => {
    portfolioReferenceById[item.value] = item.reference
  })

  const ordersProcessing = useMemo(() => {
    if (isSecurityTransactionsPage) {
      return null
    }

    if (pendingOrders.length < 1) {
      return null
    }

    const portfoliosWithOrders = new Set(pendingOrders.map((order) => order.portfolio_id))

    const linksToOrders = Array.from(portfoliosWithOrders).map((portfolioId) => (
      <Card
        className="Pending-Orders"
        mods={{ theme: 'transparent' }}
        key={portfolioId}
        onClick={() => {
          goTo(urlTo('dashboard.portfolio.portfolio-orders', { id: portfolioId }))
        }}
      >
        <ItemWithIcon
          space={4}
          icon={<Icon type="time-alt-24" size={24} color={palette['content-on-background-primary']} />}
          content={portfolioNameById[portfolioId]}
          contentVerticalAlign="center"
        />
      </Card>
    ))

    return (
      <Paper bottom={40}>
        <Paper top={16} bottom={16}>
          <Typography size={14} color="additional">
            {desktop ? 'Orders placed' : <Inner>Orders placed</Inner>}
          </Typography>
        </Paper>
        <Typography size={desktop ? 16 : 14}>{linksToOrders}</Typography>
      </Paper>
    )
  }, [isSecurityTransactionsPage, desktop, pendingOrders, portfolioNameById])

  const noTransactions = !hasTransactions && !isLoading && !hasActiveFilter
  const noResults = !hasTransactions && !isLoading && hasActiveFilter

  const props = {
    ordersProcessing,
    hasTransactions,
    hasSecurityTransactions,
    total,
    filters,
    pendingOrders,
    transactions,
    portfolioNameById,
    portfolioReferenceById,
    preloaderElementRef,
    isNextLoading,
    query: location.query,
    noTransactions,
    noResults,
    isLoading,
    isSecurityTransactionsPage,
    resetFilters,
  }

  if (!isEverythingFetched) {
    return <Preloader loading size="big" zIndex />
  }

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}
      <AddFundsModal parentModule="transactions" />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

export default Transactions
