import React from 'react'

import { SettingsLayout } from '../components/SettingsLayout'

import DirectorsAndShareholders from './DirectorsAndShareholders.jsx'

const Directors = (): React.ReactElement => {
  return (
    <SettingsLayout title="Directors and Shareholders">
      <DirectorsAndShareholders />
    </SettingsLayout>
  )
}

export { Directors }
