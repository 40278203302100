import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { urlTo, goTo } from 'helpers/router.js'

import { useMediaQueries, useCallback, useMemo } from 'hooks'
import { useQuestion } from './hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { DesktopSingle, DesktopMultiple, DesktopText } from './Desktop'
import { MobileSingle, MobileMultiple, MobileText } from './Mobile'

import { questionTypes } from 'constants/questionnaire.js'

import './Question.css'

const Question = ({
  params,
  prevStep,
  progress,
  onSubmit,
  redirectToNextStep,
  analyticsCategory,
  onAfterSave,
  location,
}) => {
  const { desktop } = useMediaQueries()
  const { question, selectedAnswer, handleBack, handleSubmit } = useQuestion({
    params,
    prevStep,
    redirectToNextStep,
    onAfterSave,
    analyticsCategory,
    location,
  })

  const props = {
    progress,
    question,
    selectedAnswer,
    backLinkText: prevStep?.headline,
    onSubmit,
    handleBack,
    handleSubmit,
  }

  const handleCancel = useCallback(() => goTo(urlTo('dashboard')), [])

  const desktopPage = useMemo(
    () =>
      ({
        [questionTypes.SINGLE]: <DesktopSingle {...props} />,
        [questionTypes.MULTIPLE]: <DesktopMultiple {...props} />,
        [questionTypes.TEXT]: <DesktopText {...props} />,
      })[question.type],
    [question.type, props],
  )
  const mobilePage = useMemo(
    () =>
      ({
        [questionTypes.SINGLE]: <MobileSingle {...props} handleCancel={handleCancel} />,
        [questionTypes.MULTIPLE]: <MobileMultiple {...props} handleCancel={handleCancel} />,
        [questionTypes.TEXT]: <MobileText {...props} handleCancel={handleCancel} />,
      })[question.type],
    [question.type, props, handleCancel],
  )

  return (
    <Fragment key={question.code}>
      {desktop ? desktopPage : mobilePage}
      <GatewayDest name="modals" />
    </Fragment>
  )
}

Question.propTypes = {
  params: PropTypes.object,
  progress: PropTypes.number,
  prevStep: PropTypes.shape({
    headline: PropTypes.string,
  }),
  question: PropTypes.node.isRequired,
  backLinkText: PropTypes.string,
  onSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  redirectToNextStep: PropTypes.func,
  analyticsCategory: PropTypes.string,
  onAfterSave: PropTypes.func,
  location: PropTypes.shape({
    regulatoryType: PropTypes.string,
  }),
}

export { Question }
