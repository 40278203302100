import React from 'react'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './SavingsPlanOptionButton.css'

type SavingsPlanOptionButtonProps = {
  title: string
  onClick: () => void
  'data-test-id'?: string
}

const SavingsPlanOptionButton = ({
  title,
  onClick,
  'data-test-id': dataTestId,
}: SavingsPlanOptionButtonProps): React.ReactElement => {
  return (
    <Paper className="SavingsPlanOptionButton" top={16} bottom={16} onClick={onClick} data-test-id={dataTestId}>
      <Typography size={16} lineHeight="small" weight="semibold" align="center" color="primary_default">
        <Typo>{title}</Typo>
      </Typography>
    </Paper>
  )
}

export { SavingsPlanOptionButton }
