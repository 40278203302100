import { ListOf } from 'app/redux/models/common/ListOf'

import { SecurityDocumentItem } from './SecurityDocumentItem.js'

/**
 * @class
 * @typedef {Array.<SecurityDocumentItem>} SecurityDocumentList
 */
export class SecurityDocumentList extends ListOf(SecurityDocumentItem) {
  /**
   * @return {?SecurityDocumentItem}
   */
  getKiid() {
    return this.find((doc) => doc.is_kiid) ?? null
  }
}
