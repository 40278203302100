import React from 'react'

import { AwaitingPushStep } from './AwaitingPushStep'
import { InitialStep } from './InitialStep'
import { PhoneNumberStep } from './PhoneNumberStep'
import { RecoveryMethodStep } from './RecoveryMethodStep'
import { TwoFactorSetupSuccess } from './Success'

const childRoutes = [
  {
    index: true,
    isNewLayout: true,
    component: function Component(props) {
      return <InitialStep {...props} />
    },
  },
  {
    module: 'awaiting-push',
    path: 'awaiting-push',
    isNewLayout: true,
    component: function Component(props) {
      return <AwaitingPushStep {...props} />
    },
  },
  {
    module: 'recovery-method',
    path: 'recovery-method',
    isNewLayout: true,
    component: function Component(props) {
      return <RecoveryMethodStep {...props} />
    },
  },
  {
    module: 'phone-number',
    path: 'phone-number',
    isNewLayout: true,
    component: function Component(props) {
      return <PhoneNumberStep {...props} />
    },
  },
  {
    module: 'success',
    path: 'success',
    isNewLayout: true,
    component: function Component(props) {
      return <TwoFactorSetupSuccess {...props} />
    },
  },
]

const routes = [
  {
    module: 'two-factor-auth-setup',
    path: 'two-factor-auth-setup',
    indexRoute: childRoutes.find((route) => route.index),
    childRoutes,
  },
]

export default routes
