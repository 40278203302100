import React from 'react'

import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'

import { dayOptions } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'

import { SavingsPlanOptionButton } from '../SavingsPlanOptionButton'

type StartDayStepProps = {
  onSelectOption: (startDay: string) => void
}

const StartDayStep = ({ onSelectOption }: StartDayStepProps): React.ReactElement => {
  return (
    <Width size={21} center>
      {dayOptions.map((option) => (
        <Paper top={16} key={option.value}>
          <SavingsPlanOptionButton
            title={option.name}
            onClick={() => {
              onSelectOption(option.value)
            }}
            data-test-id="savingsPlanSetupStartDay"
          />
        </Paper>
      ))}
    </Width>
  )
}

export { StartDayStep }
