import React from 'react'

import { CreateAccount } from './CreateAccount.jsx'

export default {
  module: 'create-account',
  path: 'create-account/',
  name: 'Create account',
  component: function Component(props) {
    return <CreateAccount {...props} />
  },
}
