import React from 'react'

import { useMemo, useSelector, useState } from 'hooks'

import { urlTo } from 'helpers/router'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { FinishRegistrationModal } from 'app/pages/Dashboard/Accounts/components/FinishRegistrashionModal'
import { useClient } from 'app/pages/Dashboard/SetupAccount/hooks/useClient.js'

import { states as clientStates } from 'constants/client'
import { regulatoryTypes } from 'constants/portfolio'

const TransferIsaLink = (): React.ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { client } = useClient()
  const portfolios = useSelector(({ portfolios }) => portfolios)
  const shouldFinishRegistration = [clientStates.NEW, clientStates.NOT_COMPLETED].includes(client.state)

  const isaPortfolios = useMemo(
    () => portfolios?.items?.filter((portfolio) => portfolio.regulatory_type === regulatoryTypes.ISA) ?? [],
    [portfolios],
  )

  const transferIsaLink =
    isaPortfolios.length === 0
      ? urlTo('dashboard', null, { transferIsa: true, forwardToTransferIsa: true })
      : urlTo('transfer-isa-altus')

  const showFinishRegistrationModal = (): void => {
    setIsModalVisible(true)
  }

  return (
    <React.Fragment>
      <Link
        to={shouldFinishRegistration ? null : transferIsaLink}
        onClick={shouldFinishRegistration ? showFinishRegistrationModal : null}
        style={{ display: 'block' }}
        data-test-id="transferIsaButton"
      >
        <ItemWithIcon
          inline
          space={12}
          icon={<Icon type="arrow-in-an-outlined-circle-right-32" size={32} color="inherit" />}
          content={
            <Typography size={14} lineHeight="small" color="inherit">
              <Typo>Transfer an ISA</Typo>
            </Typography>
          }
          contentVerticalAlign="center"
        />
        <Paper left={40}>
          <Paper left={4}>
            <Typography size={14} lineHeight="small" color="minor">
              <Typo>Transfer an ISA from your current provider.</Typo>
            </Typography>
          </Paper>
        </Paper>
      </Link>
      {isModalVisible && <FinishRegistrationModal setIsModalVisible={setIsModalVisible} />}
    </React.Fragment>
  )
}

export { TransferIsaLink }
