import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'
import { useBuy } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/hooks'

import { Preloader } from 'components/molecules/Preloader'
import { GatewayDest } from 'components/atoms/Gateway'

import { BuyDesktop as Desktop } from './Desktop'
import { BuyMobile as Mobile } from './Mobile'
import { usePortfolioBuySell } from './hooks'

import { SelectCashModal } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/components/SelectCashModal'

const Buy = ({ params: { id } = {} }) => {
  const { desktop } = useMediaQueries()

  const portfolioId = useMemo(() => parseInt(id, 10), [id])

  const {
    isLoading: isPortfolioLoading,
    securities,
    noTradingSecurities,
    isTradingSuspended,
    noTradingSecuritiesWithNonZeroWeights,
    portfolio,
    handleSell,
    handleBack,
    handleAddFunds,
    handleReview,
    cashPortfolios,
    isBusiness,
  } = usePortfolioBuySell({ portfolioId })

  const {
    currentCashBalance,
    isLoading: isBuyLoading,
    isValid,
    errors,
    control,
    validation,
    buy: handleSubmit,
    isCashSelectModalOpen,
    openCashSelectModal,
    closeCashSelectModal,
    chosenPortfolio,
    setChosenPortfolioId,
    groupedPortfolios,
    isIsaLimitWarningDisplayed,
  } = useBuy({
    portfolio,
    portfolioId,
    cashPortfolios,
    onAfterBuy: (orderId) => handleReview(orderId, 'buy'),
  })

  const isLoading = isBuyLoading || isPortfolioLoading

  const props = {
    portfolioId,
    currentCashBalance,
    securities,
    noTradingSecurities,
    isTradingSuspended,
    noTradingSecuritiesWithNonZeroWeights,
    chosenPortfolio,
    openCashSelectModal,
    isIsaLimitWarningDisplayed,
    isBusiness,

    isValid,
    validation,
    errors,
    control,

    handleSell,
    handleAddFunds,
    handleBack,
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>{desktop ? <Desktop {...props} /> : <Mobile {...props} />}</form>
      <SelectCashModal
        isOpen={isCashSelectModalOpen}
        onClose={closeCashSelectModal}
        onSelect={setChosenPortfolioId}
        groupedPortfolios={groupedPortfolios}
        isBusiness={isBusiness}
      />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
      <Preloader loading={isLoading} size="big" zIndex />
    </Fragment>
  )
}

Buy.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export { Buy }
