import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { useCallback, useContext, useMediaQueries, useRedirect } from 'hooks'

import { StepContext } from 'app/containers/PortfolioTunnel'
import { useUnit } from 'effector-react'

import Card from 'components/_old/Card/Card.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

import AddingDirectorForm from './AddingDirectorForm/AddingDirectorForm.jsx'

import { $isLoading } from 'app/effector/contacts'

const AddingDirectorSetup = withRouter(({ owner, contacts, params, addDirector }) => {
  const { desktop } = useMediaQueries()
  const { getStepByName } = useContext(StepContext)
  const residentialAddressStep = getStepByName('residential-address')

  const isContactsLoading = useUnit($isLoading)

  useRedirect({
    to: residentialAddressStep.module,
    rule: !owner?.addresses?.hasAddresses(),
    isLoading: isContactsLoading,
  })

  const contactId = params.contactId !== undefined ? parseInt(params.contactId, 10) : null

  const handleSubmit = useCallback((contact) => addDirector(contact), [addDirector])

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <Card mods={{ theme: 'transparent' }}>{submitButton}</Card>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  const card = (
    <Card
      mods={{
        theme: 'transparent',
        'no-padding': desktop ? 'left right' : 'all',
      }}
    >
      <AddingDirectorForm contactId={contactId} wrapSubmitButton={wrapSubmitButton} onSubmit={handleSubmit} />
    </Card>
  )

  if (desktop) {
    return <AllCenter>{card}</AllCenter>
  }

  return card
})

AddingDirectorSetup.propTypes = {
  params: PropTypes.shape({
    contactId: PropTypes.string,
  }),
  contacts: PropTypes.object,
  owner: PropTypes.object,
  addDirector: PropTypes.func,
}

export { AddingDirectorSetup }
