import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { goTo, urlTo } from 'helpers/router.js'
import { trackEvent } from 'helpers/analytics'

import { useCallback, useContext, useEffect, usePrevious, useSelector, useState } from 'hooks'

import { Modal } from 'components/molecules/Modal'
import { Gateway } from 'components/atoms/Gateway'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'

import { regulatoryTypes } from 'constants/portfolio'

const AddFundsModal = withRouter(({ goal = {}, routes, ...rest }) => {
  const { tunnelQuery, updateTunnel } = useContext(ChangeGoalTunnelContext)

  const route = routes[routes.length - 1]

  const [isCloseVisible, setIsCloseVisible] = useState(true)
  const close = isCloseVisible ? {} : { close: null }
  const client = useSelector((state) => state.client)

  const isOpen = routes.some((route) => route.module?.includes('add-funds'))
  const wasNewBank = routes.some((route) => route.module?.includes('new-bank'))
  // reset the old value if were on a new bank page
  const wasOpen = usePrevious(isOpen && !wasNewBank)

  const handleClose = useCallback(
    (options = {}) => {
      if (!goal.id) {
        return goTo(urlTo('dashboard.transactions'), options)
      }
      const { amount, ...queryWithoutAmount } = tunnelQuery || {}

      const updated = updateTunnel({ shouldRunNextStep: true })

      if (!updated) {
        const { id, ...clearedQuery } = queryWithoutAmount || {}

        goTo(urlTo(`dashboard.portfolio`, { id: goal.id }, clearedQuery), { scrollToTop: false, ...options })
      }
    },
    [goal.id, tunnelQuery, route],
  )

  useEffect(() => {
    if (isOpen && goal.regulatory_type === regulatoryTypes.SIPP && route.module === 'add-funds') {
      goTo(urlTo('portfolio.add-funds.net-contributions', { id: goal.id }, tunnelQuery), {
        scrollToTop: false,
      })
    }
  }, [isOpen])

  useEffect(() => {
    if (!wasOpen && isOpen) {
      trackEvent({
        action: 'add_cash',
        client_type: client?.type,
        manage_type: goal?.manage_type,
        regulatory_type: goal?.regulatory_type,
      })
    }
  }, [wasOpen, isOpen])

  const toggleCloseButton = useCallback(
    (isVisible) => {
      setIsCloseVisible(isVisible)
    },
    [setIsCloseVisible],
  )

  return (
    <Gateway into="modals">
      <Modal open={isOpen} {...close} onClose={handleClose} data-test-id="addFundsPopup">
        {isOpen &&
          route.component({
            goal,
            tunnelQuery,
            parentModule: 'add-funds',
            isModalOpen: isOpen,
            wasModalOpen: wasOpen,
            toggleCloseButton,
            handleClose,
            ...rest,
          })}
      </Modal>
    </Gateway>
  )
})

AddFundsModal.propTypes = {
  children: PropTypes.node,
  routes: PropTypes.array,
  route: PropTypes.shape({
    headline: PropTypes.string,
    component: PropTypes.func,
    module: PropTypes.string,
  }),
  goal: PropTypes.object,
}

export { AddFundsModal }
