import React from 'react'

import { useActions, useEffect, useSelector } from 'hooks'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'

import { SettingsLayout } from '../components/SettingsLayout'

import { FeesContent } from './components/FeesContent'

const Fees = (): React.ReactElement => {
  const portfolios = useSelector((state) => state.portfolios)

  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])

  useEffect(() => {
    if (!portfolios.items.length) {
      fetchPortfolios()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SettingsLayout title="Fees">
      <FeesContent />
    </SettingsLayout>
  )
}

export { Fees }
