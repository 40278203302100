import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import isFunction from 'lodash/isFunction'

import { useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

import './HeadlineWBacklink.css'

const HeadlineWBacklink = ({ children, subheadline, back, backLinkText, noPaddingTop, forceShow = false, ...rest }) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const classes = classNames('HeadlineWBacklink', getMediaQuieryClasses('HeadlineWBacklink', mediaQueries), {
    'HeadlineWBacklink_w-back': back && desktop,
  })

  return (
    <div className={classes}>
      <Card
        mods={{
          theme: 'transparent',
          'no-padding': noPaddingTop ? 'top left right' : 'left right',
          padding: desktop ? null : 'big',
        }}
      >
        <div className="HeadlineWBacklink-Holder">
          <Headline
            className="HeadlineWBacklink-Headline"
            level={desktop ? 1 : 2}
            mods={{ 'no-margin': 'top bottom' }}
            data-test-id="headline"
          >
            {children}
          </Headline>
          {back && desktop ? (
            <Link
              className="HeadlineWBacklink-Back"
              to={isFunction(back) ? null : back}
              onClick={isFunction(back) ? back : null}
              title={backLinkText}
              data-test-id="backLink"
              mods={{ color: 'content-on-background-default' }}
              {...rest}
            >
              <Icon
                className="HeadlineWBacklink-BackIcon"
                type="back"
                size={42}
                color="unherit"
                data-test-id="backLinkIcon"
              />
              <span className="HeadlineWBacklink-BackText" data-test-id="backLinkText">
                {backLinkText || 'Back'}
              </span>
            </Link>
          ) : null}
        </div>
        {subheadline ? (
          <Headline
            level={desktop ? 3 : 5}
            mods={{ 'no-margin': 'top bottom', text: 'normal' }}
            className="HeadlineWBacklink-Subheadline"
          >
            {subheadline}
          </Headline>
        ) : null}
      </Card>
    </div>
  )
}

HeadlineWBacklink.propTypes = {
  children: PropTypes.node,
  subheadline: PropTypes.string,
  backLinkText: PropTypes.string,
  back: PropTypes.func,
  noPaddingTop: PropTypes.bool,
  forceShow: PropTypes.bool,
}

export default HeadlineWBacklink
