import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import List, { ListItem } from 'components/_old/List/List.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { InlineTextHelper } from '../InlineTextHelper'

const RiskDeclarationText = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const ukResidenceHelper = (
    <InlineTextHelper>
      <Typography lineHeight="small" weight="semibold" data-test-id="tooltipModalTitle">
        <Typo>You are a relevant UK Individual for a given tax year if you:</Typo>
      </Typography>
      <Paper top={20}>
        <List mods={{ type: 'bullet', 'no-margin': 'top' }}>
          <ListItem>
            <Typo>Have relevant UK earnings chargeable to income tax for that tax year; or</Typo>
          </ListItem>
          <ListItem>
            <Paper top={20}>
              <Typo>Are resident in the UK at some time during that tax year; or</Typo>
            </Paper>
          </ListItem>
          <ListItem>
            <Paper top={20}>
              <Typo>
                Were resident in the UK at some time during the five tax years immediately before the tax in question
                and were also resident in the UK when you joined the pension plan; or
              </Typo>
            </Paper>
          </ListItem>
          <ListItem>
            <Paper top={20}>
              <Typo>
                You, your spouse or civil partner have, for that tax year, general earnings from overseas Crown
                employment subject to UK tax (as defined by section 28 of the Income Tax ((Earnings and Pensions)) Act
                2003).
              </Typo>
            </Paper>
          </ListItem>
        </List>
        <Paper top={20}>
          <Typography>
            <Typo>
              If you do not have relevant UK earnings, the maximum member contribution is the basic amount, currently
              £3,600 (including tax relief). It may be changed by legislation in the future.
            </Typo>
          </Typography>
        </Paper>
      </Paper>
    </InlineTextHelper>
  )

  const contributionsHelper = (
    <InlineTextHelper>
      <Typography lineHeight="small" weight="semibold" data-test-id="tooltipModalTitle">
        <Typo>Total Contributions</Typo>
      </Typography>
      <Paper top={20}>
        <Typography>
          <Typo>
            Your total contributions in any one tax year are the total of all contributions made by you, your employer,
            or any other third party to all the registered pension schemes of which you are a member, plus the amount of
            any basic rate tax relief we collect from HMRC and add to your pension plan.
          </Typo>
        </Typography>
      </Paper>
    </InlineTextHelper>
  )

  const basicAmountHelper = (
    <InlineTextHelper>
      <Typography lineHeight="small" weight="semibold">
        <Typo>The basic amount</Typo>
      </Typography>
      <Paper top={20}>
        <Typography>
          <Typo>
            The basic amount is currently £3,600 (including tax relief) and has remained at that amount since 2006. It
            may be changed by legislation in the future.
          </Typo>
        </Typography>
      </Paper>
    </InlineTextHelper>
  )

  const earningsHelper = (
    <InlineTextHelper>
      <Typography lineHeight="small" weight="semibold" data-test-id="tooltipModalTitle">
        <Typo>Relevant UK Earnings</Typo>
      </Typography>
      <Paper top={20}>
        <Typography>
          <Typo>
            As a general rule most income that is earned and assessable for income tax in the UK counts as relevant UK
            earnings. This includes employment income, but generally does not include pension income, dividends, and
            most rental income.
            <br />
            <br />
            Further details regarding earnings that count as ‘relevant UK earnings’ can be found{' '}
            <Link
              to="https://www.gov.uk/hmrc-internal-manuals/pensions-tax-manual/ptm044100#earnings"
              hard
              blank
              data-test-id="tooltipModalLink"
            >
              <Typography size={14} color="inherit" inline>
                on the HMRC website
              </Typography>
            </Link>
            .
            <br />
            <br />
            As you must declare that your contributions in excess of the basic amount will not exceed your relevant UK
            earnings in any given tax year, if you are in any doubt as to whether the earnings on which you are reliant
            to justify the amount of contributions being pad, are relevant UK earnings, then we recommend that you
            should seek professional advice.
          </Typo>
        </Typography>
      </Paper>
    </InlineTextHelper>
  )

  return (
    <Fragment>
      <Paper top={desktop ? 56 : 48}>
        <Typography size={24} weight="semibold" lineHeight="small" data-test-id="sippDeclarationSubTitle">
          <Typo>Financial Advice Declarations</Typo>
        </Typography>
      </Paper>
      <Paper top={desktop ? 32 : 20}>
        <Typography size={14}>
          <List mods={{ type: 'bullet', 'no-margin': 'top' }}>
            <ListItem>
              <Typo>
                I understand that as a financial adviser is not appointed on this pension plan to advise me, I take full
                responsibility for my decisions and instructions I give, which include but are not limited to:
              </Typo>
              <Paper top={16}>
                <List mods={{ type: 'bullet', 'no-margin': 'top', 'padding-left': 'big' }}>
                  <ListItem>
                    <Typo>Applying for this pension plan;</Typo>
                  </ListItem>
                  <ListItem>
                    <Paper top={12}>
                      <Typo>How much and when I contribute to the pension plan;</Typo>
                    </Paper>
                  </ListItem>
                  <ListItem>
                    <Paper top={12}>
                      <Typo>
                        Determining that any transfers paid in are in my best interests, including acceptance of any
                        charges the transferring scheme may make;
                      </Typo>
                    </Paper>
                  </ListItem>
                  <ListItem>
                    <Paper top={12}>
                      <Typo>
                        How my pension fund is invested, within the investment options available via this pension plan;
                      </Typo>
                    </Paper>
                  </ListItem>
                  <ListItem>
                    <Paper top={12}>
                      <Typo>When, how much and in what form I take benefits.</Typo>
                    </Paper>
                  </ListItem>
                </List>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I acknowledge and accept that Quai Investment Services Limited and Quai Trustees Limited do not
                  provide financial, investment tax, legal or any other form of advice and the services provided do not
                  extend to financial advice under the terms of the Financial Services and Markets Act 2000.
                </Typo>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I confirm that, to the best of my knowledge and belief, the information I have given in making the
                  application and in any documents I have completed or information I have supplied relating to this
                  application is correct and complete.
                </Typo>
              </Paper>
            </ListItem>
          </List>
        </Typography>
      </Paper>
      <Paper top={desktop ? 56 : 48}>
        <Typography size={24} weight="semibold" lineHeight="small" data-test-id="sippDeclarationSubTitle">
          <Typo>Risk Declarations</Typo>
        </Typography>
      </Paper>
      <Paper top={desktop ? 32 : 20}>
        <Typography size={14}>
          <List mods={{ type: 'bullet', 'no-margin': 'top' }}>
            <ListItem>
              <Typo>
                I understand that the value of investments can go down as well as up and I may get back less than
                invested. My capital is at risk.
              </Typo>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>I understand that tax rules can change in the future.</Typo>
              </Paper>
            </ListItem>
          </List>
        </Typography>
      </Paper>
      <Paper top={desktop ? 56 : 48}>
        <Typography size={24} weight="semibold" lineHeight="small" data-test-id="sippDeclarationSubTitle">
          <Typo>Contribution Declarations</Typo>
        </Typography>
      </Paper>
      <Paper top={desktop ? 32 : 20}>
        <Typography size={14}>
          <List mods={{ type: 'bullet', 'no-margin': 'top' }}>
            <ListItem>
              <Typo>
                I understand that contributions which are not eligible for UK pension relief at source cannot be paid
                into this pension plan. If contributions are paid into my pension plan on which tax relief is sought, I
                declare that:
              </Typo>
              <Paper top={16}>
                <List mods={{ type: 'bullet', 'no-margin': 'top', 'padding-left': 'big' }}>
                  <ListItem>
                    <Typo>I am a relevant UK individual</Typo>
                    {ukResidenceHelper}
                  </ListItem>
                  <ListItem>
                    <Paper top={12}>
                      <Typo>
                        The total of the contributions{contributionsHelper}paid to this scheme and to other registered
                        pension schemes in respect of which I am entitled to tax relief, under section 188 of the
                        Finance Act 2004, will not exceed, in any tax year, the higher of:
                      </Typo>
                    </Paper>
                    <Paper top={12}>
                      <List mods={{ 'no-margin': 'top', 'padding-left': 'big' }}>
                        <ListItem>
                          <Typo>1. The basic amount in that tax year{basicAmountHelper}, or</Typo>
                        </ListItem>
                        <ListItem>
                          <Paper top={12}>
                            <Typo>2. My relevant UK earnings in that tax year</Typo>
                            {earningsHelper}
                          </Paper>
                        </ListItem>
                      </List>
                    </Paper>
                  </ListItem>
                  <ListItem>
                    <Paper top={12}>
                      <Typo>This declaration is correct, to the best of my knowledge and belief.</Typo>
                    </Paper>
                  </ListItem>
                  <ListItem>
                    <Paper top={12}>
                      <Typo>
                        I confirm that if an event occurs, as a result of which I will no longer be entitled to relief
                        on contributions (for example I cease to be a relevant UK individual), I will give notice to
                        InvestEngine by the later of:
                      </Typo>
                    </Paper>
                    <Paper top={12}>
                      <List mods={{ type: 'bullet', 'no-margin': 'top', 'padding-left': 'big' }}>
                        <ListItem>
                          <Typo>5th April in the year of assessment in which the event occurs; and</Typo>
                        </ListItem>
                        <ListItem>
                          <Paper top={12}>
                            <Typo>The date which is 30 days after the occurrence of that event.</Typo>
                          </Paper>
                        </ListItem>
                      </List>
                    </Paper>
                  </ListItem>
                </List>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper top={20}>
                <Typo>
                  I understand and acknowledge that if I have Enhanced or Fixed Lifetime Allowance protection this will
                  be lost if a contribution is made to this pension plan or any other pension plan and it is my
                  responsibility should this occur.
                </Typo>
              </Paper>
            </ListItem>
          </List>
        </Typography>
      </Paper>
    </Fragment>
  )
}

export { RiskDeclarationText }
