import { decimal, type Nullable, nullable } from 'app/effector/model'

import { STATS_PERIODS, type StatsDTO } from '../api'

import { StatsPeriod } from './StatsPeriod'

class Stats {
  readonly current_balance: Nullable<string>
  readonly periods: Record<(typeof STATS_PERIODS)[number], StatsPeriod>

  constructor(dto: Partial<StatsDTO> | Partial<Stats>) {
    this.current_balance = nullable(decimal)(dto.current_balance)
    this.periods = Object.fromEntries(
      STATS_PERIODS.map((key) => [key, new StatsPeriod(dto?.periods?.[key] ?? {})]),
    ) as Record<(typeof STATS_PERIODS)[number], StatsPeriod>
  }
}

export { Stats }
