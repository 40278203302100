import React from 'react'

import { NetContributions } from 'app/pages/Dashboard/Goals/AddFunds/NetContributions/NetContributions'

export const routes = {
  module: 'net-contributions',
  path: 'net-contributions/',
  component: function Component(props) {
    return <NetContributions {...props} />
  },
}
