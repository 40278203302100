import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries } from 'hooks'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Preloader } from 'components/molecules/Preloader'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

import { useRecurringPaymentStop } from './hooks/useRecurringPaymentStop'

type RecurringPaymentProps = {
  portfolio: Portfolio
  location: {
    query?: {
      back?: string
    }
  }
  handleClose: () => void
}

const RecurringPaymentStop = withRouter(
  ({ portfolio, location, handleClose }: RecurringPaymentProps): React.ReactElement | null => {
    const { desktop } = useMediaQueries()

    const { isLoading, handleBack, handleStopPayment } = useRecurringPaymentStop({ portfolio, location })

    const titleNode = (
      <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
        <Typo>Stop Savings Plan</Typo>
      </Typography>
    )

    const textNode = (
      <Paper top={desktop ? 32 : 12}>
        <Typography size={desktop ? 14 : 16} align="center">
          <Typo>Are you sure you want to stop the Savings Plan?</Typo>
        </Typography>
      </Paper>
    )

    const content = desktop ? (
      <Paper top={56} left={40} right={40}>
        {titleNode}
        {textNode}
      </Paper>
    ) : (
      <AllCenter>
        {titleNode}
        {textNode}
      </AllCenter>
    )

    return (
      <Fragment>
        <Preloader loading={isLoading} background="background" zIndex />
        <OptionsLayout
          header={
            <OptionsNavigationBar
              leftPartText="Back"
              onLeftPartClick={handleBack}
              rightPartText="Close"
              onRightPartClick={handleClose}
            >
              &nbsp;
            </OptionsNavigationBar>
          }
          content={content}
          button={
            <Button
              type="submit"
              onClick={handleStopPayment}
              mods={{ size: 'big block' }}
              disabled={isLoading}
              data-test-id="confirmRecurringPaymentStopButton"
            >
              Stop Savings Plan
            </Button>
          }
          data-test-id="recurringPaymentStartPopup"
        />
      </Fragment>
    )
  },
)

export { RecurringPaymentStop }
