import React from 'react'
import Zendesk from 'react-zendesk'

const zendeskKey = '35241f52-fe27-4c09-b263-ce9ce6587933'

const zendeskSettings = {
  webWidget: {
    offset: {
      mobile: {
        horizontal: '-14px',
        vertical: '58px',
      },
    },
    chat: {
      connectOnPageLoad: false,
      offlineForm: {
        greeting: {
          '*': "Please leave a message and we'll get back to you as soon as possible.",
        },
      },
    },
  },
}

const ZendeskInitiator = (): any => {
  return <Zendesk defer zendeskKey={zendeskKey} {...zendeskSettings} />
}

export { ZendeskInitiator }
