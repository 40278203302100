import React from 'react'
import PropTypes from 'prop-types'

import { useState, useCallback } from 'hooks'

const CleanInputOnFocus = ({ children, cleanIfMatch, onSetValue, onFocus, ...rest }) => {
  const [previousValue, setPreviousValue] = useState(null)
  const [changed, setChanged] = useState(false)

  const handleFocus = useCallback(() => {
    const value = children.props.children
    setPreviousValue(value)
    setChanged(false)

    if (!cleanIfMatch || cleanIfMatch.test(value)) {
      if (onSetValue && !previousValue) {
        onSetValue(null)
      }
    }

    if (onFocus) {
      onFocus()
    }
  }, [previousValue, onSetValue])

  const handleChange = useCallback(
    (event, value) => {
      if (!changed) {
        setChanged(true)
        setPreviousValue(null)
      }

      if (children.props.onChange) {
        children.props.onChange(event, value)
      }
    },
    [changed, children.props],
  )

  return React.cloneElement(children, {
    ...rest,
    onFocus: handleFocus,
    onChange: handleChange,
  })
}

CleanInputOnFocus.propTypes = {
  children: PropTypes.element.isRequired,
  cleanIfMatch: PropTypes.object.isRequired,
  onSetValue: PropTypes.func,
  onFocus: PropTypes.func,
}

export default CleanInputOnFocus
