import { useUnit } from 'effector-react'

import { useState, useEffect, useCallback, useMemo } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import { $collectionsStore, fetchCollectionGroupsFx } from 'app/effector/discover-etfs'
import { type Collection, type CollectionsGroup } from 'app/effector/discover-etfs/types'
import { CollectionsGroupLayout } from 'app/effector/discover-etfs/types'
import type { SecurityList } from 'app/effector/securities/models'
import type { Security } from 'app/effector/securities/models/Security.js'
import { $securityCollectionsStoriesStore, fetchSecurityCollectionsStories } from 'app/effector/stories'
import { type StoryList } from 'app/effector/stories'

import { useRemovableSecurities } from 'app/pages/Securities/Security/hooks'
import { type Filters } from 'app/pages/Securities/SecurityUniverse/hooks/useFilters'
import { useSecurityUniverse } from 'app/pages/Securities/SecurityUniverse/hooks/useSecurityUniverse.js'

const gaCollectionEtfClickEvents = {
  'Featured partners': 'etfrange_featured_collection_etf_clicked',
  Collections: 'etfrange_collection_etf_clicked',
  'Industries & Sectors': 'etfrange_sector_etf_clicked',
}

type useSecurityCollectionProps = {
  slugOrId: string | number
  portfolioId: number | null
  location: { query?: Record<string, string> }
}

type useSecurityCollectionInterface = {
  abandonModalOpen: boolean
  addedItemsCount: number
  areSecuritiesLoading: boolean
  collection?: Collection
  featuredSecurities: SecurityList
  filters: Filters
  group?: CollectionsGroup
  hasAddedSecurities: boolean
  isCollectionBeingLoaded: boolean
  isFeatured: boolean
  isFiltersChange: boolean
  isLoading: boolean
  isPartnerPage: boolean
  isShowAllActive: boolean
  isShowAllButtonVisible: boolean
  isThematics: boolean
  portfolioSecurities: Security[]
  portfolioTitle: string
  removableSecuritiesIds: number[]
  scrollableElementRef: React.RefObject<HTMLElement>
  searchValue: string
  securities: SecurityList
  securitiesWasLoadedOnce: boolean
  version: string
  stories: StoryList
  areStoriesFetched: boolean
  recommendedCollections: Collection[]
  getFeaturedSecurityDataByIndex: (index: number) => any
  getSecurityDataByIndex: (index: number) => any
  getSecurityLink: (security: any) => string
  handleAbandonModalClose: () => void
  handleBack: () => void
  handleCancel: () => void
  handleGoToEditWeights: () => void
  handleRemoveSecurity: (id: number) => void
  handleSearch: (value: string) => void
  handleShowAllEtfs: (value: boolean) => void
  navigateToSecurity: (id: string) => void
  sendEtfClickEventtoGA: (security: Security) => void
  handleSaveScrollPosition: () => void
}

const useSecurityCollection = ({
  slugOrId,
  portfolioId,
  location,
}: useSecurityCollectionProps): useSecurityCollectionInterface => {
  const {
    getCollectionBySlugOrId,
    isLoading: areCollectionsLoading,
    areCollectionsFetched,
  } = useUnit($collectionsStore)

  const { getStoriesByCollection, areStoriesFetched } = useUnit($securityCollectionsStoriesStore)

  const [showAll, setShowAll] = useState(false)
  const { collection, group } = getCollectionBySlugOrId(slugOrId)
  const stories = getStoriesByCollection({ slug: collection?.slug })

  const recommendedCollections =
    collection?.recommended_collection_ids?.map((id) => getCollectionBySlugOrId(id).collection).filter(Boolean) ?? []
  const recommendedCollectionsSecurityIds = recommendedCollections.map((collection) => collection?.security_ids).flat()

  const {
    abandonModalOpen,
    filters,
    isLoading: areSecuritiesLoading,
    portfolioSecurities,
    portfolioTitle,
    scrollableElementRef,
    searchValue,
    securities,
    securitiesWasLoadedOnce,
    version,
    getSecurityDataByIndex,
    getSecurityLink,
    handleAbandonModalClose,
    handleCancel,
    handleGoToEditWeights,
    handleRemoveSecurity,
    handleSearch,
    handleSecurityClick,
    handleSaveScrollPosition,
  } = useSecurityUniverse(portfolioId, location, location, null, collection?.id, collection?.slug, !!slugOrId)

  const isFiltersChanged = useMemo(() => {
    return Object.values(filters).filter((filter) => filter?.selected?.length > 0).length > 0
  }, [filters])

  const addedItemsCount = portfolioSecurities?.length ?? 0
  const hasAddedSecurities = addedItemsCount > 0
  const isFeatured = group?.layout === CollectionsGroupLayout.FEATURED
  const isThematics = collection?.slug === 'thematics'
  const isPartnerPage = collection?.subtitle === 'Partner page'
  const needToFilterFeaturedETFs =
    recommendedCollections?.length > 0 && recommendedCollectionsSecurityIds.length < securities.length
  const isShowAllButtonVisible =
    location?.query?.showAllEtfs !== 'true' && needToFilterFeaturedETFs && !showAll && !isFiltersChanged
  const removableSecuritiesIds = useRemovableSecurities(portfolioId)
  const skeletonCollection = { isSkeleton: true }

  const featuredSecurities = useMemo(() => {
    if (needToFilterFeaturedETFs) {
      return securities.filter((security) => recommendedCollectionsSecurityIds.includes(security.id))
    }

    return []
  }, [securities, recommendedCollectionsSecurityIds, needToFilterFeaturedETFs])

  const getFeaturedSecurityDataByIndex = useCallback(
    (index) => {
      const security = featuredSecurities[index]
      const isAddedToPortfolio = !!portfolioSecurities.find(
        (portfolioSecurities) => portfolioSecurities?.id === security?.id,
      )

      return { security, isAddedToPortfolio }
    },
    [featuredSecurities, portfolioSecurities],
  )

  const handleBack = (): void => {
    const backUrl =
      location?.query?.back ??
      urlTo(
        'universe',
        null,
        querystringFromObject({ portfolioId, requestRegulatoryType: location?.query?.requestRegulatoryType }),
      )

    goTo(backUrl)
  }

  const sendEtfClickEventtoGA = (security: Security): void => {
    trackEvent({ action: gaCollectionEtfClickEvents[group?.title as string] })
    trackEvent({
      action: 'select_item',
      currency: 'GBP',
      quantity: 1,
      value: null,
      payment_type: null,
      price: null,
      item_category: collection?.slug,
      item_id: security.id,
      item_name: security.title,
      item_brand: security.provider_filter_name,
    })
  }

  const handleShowAllEtfs = useCallback((): void => {
    setShowAll(true)

    goTo(
      urlTo(
        'security-collection',
        {
          slugOrId: 'thematics',
        },
        querystringFromObject({
          ...location?.query,
          showAllEtfs: 'true',
        }),
      ),
    )
  }, [location?.query])

  useEffect(() => {
    fetchCollectionGroupsFx()
    fetchSecurityCollectionsStories()
  }, [])

  useEffect(() => {
    if (!collection?.slug) return
    trackEvent({ action: 'view_item_list', item_category: collection.slug })
  }, [collection?.slug])

  return {
    abandonModalOpen,
    addedItemsCount,
    areSecuritiesLoading,
    collection: (areCollectionsLoading && !areCollectionsFetched) || !collection ? skeletonCollection : collection,
    featuredSecurities,
    filters,
    hasAddedSecurities,
    isFeatured,
    isFiltersChanged,
    isShowAllButtonVisible,
    isThematics,
    isPartnerPage,
    portfolioSecurities,
    portfolioTitle,
    removableSecuritiesIds,
    scrollableElementRef,
    searchValue,
    securities,
    stories,
    areStoriesFetched,
    securitiesWasLoadedOnce,
    version,
    recommendedCollections,
    getFeaturedSecurityDataByIndex,
    getSecurityDataByIndex,
    getSecurityLink,
    handleAbandonModalClose,
    handleBack,
    handleCancel,
    handleGoToEditWeights,
    handleRemoveSecurity,
    handleSearch,
    handleShowAllEtfs,
    navigateToSecurity: handleSecurityClick,
    sendEtfClickEventtoGA,
    handleSaveScrollPosition,
  }
}

export { useSecurityCollection }
