import React from 'react'

import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type AmountStepProps = {
  value: string | null
  validation: {
    rules: boolean[]
    errors: string[]
  }
  inputRef: React.RefObject<Element>
  onChange: (event, value: string) => void
}

const AmountStep = ({ value, validation, inputRef, onChange }: AmountStepProps): React.ReactElement => {
  return (
    <Width size={15} center>
      <Validate rules={validation.rules} key="amountInput">
        {(isValid, brokenRule) => {
          return (
            <Label
              postfield={
                !isValid && (
                  <Paper top={4}>
                    <Typography size={12} lineHeight="small" color="on_color_red">
                      <Typo>{validation.errors[brokenRule]}</Typo>
                    </Typography>
                  </Paper>
                )
              }
            >
              <Input
                ref={inputRef}
                className="SavingsPlanSetup_Input"
                type="money"
                mods={{ size: 'big', color: 'blue', text: 'bold' }}
                onChange={onChange}
                withFloat
                data-test-id="amountInput"
              >
                {value}
              </Input>
            </Label>
          )
        }}
      </Validate>
    </Width>
  )
}

export { AmountStep }
