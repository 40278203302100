import { type IsaTransferRequestDTO } from 'app/effector/isa-transfer/api'
import { nullable, type Nullable, string, int, decimal, enumValue } from 'app/effector/model'

import { TransferCurrentYear, TransferPreviousYear, TransferType } from 'constants/transferIsa'

class IsaTransferFormData {
  portfolio_id: Nullable<number> | 'new'
  isa_provider_id: Nullable<number> | 'other'
  isa_provider_name: Nullable<string>
  isa_account: Nullable<string>
  transfer_type: Nullable<TransferType>
  transfer_current_year: TransferCurrentYear
  transfer_previous_years: TransferPreviousYear
  previous_years_amount: Nullable<string>
  agreed: boolean

  constructor(data: Partial<IsaTransferFormData>) {
    this.isa_provider_id = data.isa_provider_id === 'other' ? 'other' : nullable(int)(data.isa_provider_id)
    this.portfolio_id = data.portfolio_id === 'new' ? 'new' : nullable(int)(data.portfolio_id)
    this.isa_provider_name = nullable(string)(data.isa_provider_name)
    this.isa_account = nullable(string)(data.isa_account)
    this.transfer_current_year =
      nullable(enumValue)(data.transfer_current_year, TransferCurrentYear) ?? TransferCurrentYear.NO
    this.transfer_previous_years =
      nullable(enumValue)(data.transfer_previous_years, TransferPreviousYear) ?? TransferPreviousYear.NO
    this.previous_years_amount = nullable(decimal)(data.previous_years_amount)
    this.agreed = data.agreed ?? false
    this.transfer_type = data.transfer_type ?? null
  }

  set<Key extends keyof IsaTransferFormData>(key: Key, value: IsaTransferFormData[Key]): IsaTransferFormData {
    return new IsaTransferFormData({ ...this, [key]: value })
  }

  serialize(): IsaTransferRequestDTO {
    const { agreed, transfer_type: transferType, ...serialized } = this

    if (serialized.isa_provider_id === 'other') {
      serialized.isa_provider_id = null
    } else {
      serialized.isa_provider_name = null
    }

    if (serialized.portfolio_id === 'new') {
      serialized.portfolio_id = null
    }

    if (transferType === TransferType.FULL) {
      serialized.transfer_current_year = TransferCurrentYear.YES
      serialized.transfer_previous_years = TransferPreviousYear.ALL
      serialized.previous_years_amount = null
    }

    if (
      serialized.transfer_previous_years === TransferPreviousYear.ALL ||
      serialized.transfer_previous_years === TransferPreviousYear.NO
    ) {
      serialized.previous_years_amount = null
    }

    return serialized as IsaTransferRequestDTO
  }
}

type FormField = keyof IsaTransferFormData
type FormValue = IsaTransferFormData[FormField]

export { IsaTransferFormData, type FormField, type FormValue }
