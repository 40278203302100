import React from 'react'
import PropTypes from 'prop-types'

import { trackEvent } from 'helpers/analytics'

import { useMediaQueries, useRedirect, useCallback } from 'hooks'
import { useUnit } from 'effector-react'

import { types as clientTypes } from 'constants/client'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Card from 'components/_old/Card/Card.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

import AddressForm from 'app/pages/Forms/AddressForm.jsx'
import { $isLoading } from 'app/effector/contacts'

const ResidentialAddressSetup = ({ prevStep, owner, updateAddresses }) => {
  const { desktop } = useMediaQueries()

  const isContactsLoading = useUnit($isLoading)

  useRedirect({
    to: prevStep.module,
    rule: owner.id && !owner.first_name,
    isLoading: isContactsLoading,
  })

  const handleSubmit = useCallback(
    (...args) => {
      trackEvent({
        action: 'residential_address_submitted',
        client_type: clientTypes.BUSINESS,
      })

      updateAddresses(...args)
    },
    [updateAddresses],
  )

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <Card
        mods={{
          theme: 'transparent',
          'no-padding': 'top',
        }}
      >
        {submitButton}
      </Card>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  const form = <AddressForm wrapSubmitButton={wrapSubmitButton} onSubmit={handleSubmit} />

  if (desktop) {
    return (
      <AllCenter>
        <Card
          mods={{
            theme: 'transparent',
            'no-padding': 'left right',
          }}
        >
          {form}
        </Card>
      </AllCenter>
    )
  }

  return form
}

ResidentialAddressSetup.propTypes = {
  prevStep: PropTypes.object,
  owner: PropTypes.object,
  updateAddresses: PropTypes.func,
}

export { ResidentialAddressSetup }
