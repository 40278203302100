import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import Button from 'components/_old/Button/Button.jsx'
import Width from 'components/_old/Width/Width'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'

const ApproveCancellationModal = ({ open = false, handleCancelOrders, handleClose }) => {
  const { desktop } = useMediaQueries()

  const title = useMemo(
    () => (
      <Fragment>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight={desktop ? 'small' : 'medium'} align="center">
          <Typo>Are you sure you want to cancel your orders?</Typo>
        </Typography>
      </Fragment>
    ),
    [desktop],
  )

  const button = useMemo(
    () => (
      <Button onClick={handleCancelOrders} mods={{ size: 'big block' }} data-test-id="confirmCancelOrdersButton">
        Cancel orders
      </Button>
    ),
    [handleCancelOrders],
  )

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose} data-test-id="confirmCancelOrderModal">
        <ModalContent width={60}>
          <Paper top={80} bottom={80}>
            {title}
            <Paper top={32}>
              <Typography size={14} align="center">
                <Typo>
                  Orders can only be cancelled before 2pm or after 7pm daily.
                  <br />
                  Any funds from cancelled orders will remain as uninvested cash within the portfolio.
                </Typo>
              </Typography>
            </Paper>
            <Paper top={56}>
              <Width size={16} center>
                {button}
              </Width>
            </Paper>
          </Paper>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContent width={60}>
        <MobileLayout
          header={<NavigationBar onLeftPartClick={handleClose} leftPartText="Back" />}
          content={
            <AllCenter>
              <Paper>
                {title}
                <Paper top={80}>
                  <Typography size={14} align="center">
                    <Typo>
                      Orders can only be cancelled before
                      <br />
                      2pm or after 7pm daily. Any funds from cancelled orders will remain as uninvested cash within the
                      portfolio.
                    </Typo>
                  </Typography>
                </Paper>
              </Paper>
            </AllCenter>
          }
          footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
        />
      </ModalContent>
    </Modal>
  )
}

ApproveCancellationModal.propTypes = {
  open: PropTypes.bool,
  handleCancelOrders: PropTypes.func,
  handleClose: PropTypes.func,
}

export { ApproveCancellationModal }
