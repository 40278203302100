import React from 'react'
import classNames from 'classnames/dedupe'

import { sanitizeString } from 'helpers/typograph'

import './SvgIcon.css'

export default function (props) {
  const { className, style, src, fluid, ...restProps } = props

  if (src) {
    const classes = classNames(className, 'SvgIcon', {
      SvgIcon_fluid: fluid,
      SvgIcon_fill: style && style.fill,
    })

    return (
      <span
        className={classes}
        dangerouslySetInnerHTML={{ __html: sanitizeString(src, { USE_PROFILES: { svg: true } }) }}
        style={style}
        {...restProps}
      />
    )
  } else {
    throw new Error(`Svg Icon can’t be empty. Src is ${props.src}`)
  }
}
