import moment from 'helpers/date.js'

const periods = {
  PERIOD_ALL_TIME: 'max',
  PERIOD_YEAR: 'year',
  PERIOD_YEAR_TO_DATE: 'year-to-date',
  PERIOD_SIX_MONTHS: 'six_months',
  PERIOD_ONE_MONTH: 'month',
  PERIOD_ONE_WEEK: 'week',
}

const filterDataByPeriod = (data, begin, end) => {
  return data.filter((dataItem) => {
    const date = moment(dataItem.date)

    return date >= begin && date <= end
  })
}

const sortIncomeHistoryData = (rawData = [], period = periods.PERIOD_ALL_TIME) => {
  if (period === periods.PERIOD_ALL_TIME) {
    return rawData
  }

  if (period === periods.PERIOD_YEAR) {
    const now = moment()

    return filterDataByPeriod(rawData, moment().subtract(1, 'year'), now)
  }

  if (period === periods.PERIOD_YEAR_TO_DATE) {
    const now = moment()

    const startOfYear = moment().startOf('year')

    return filterDataByPeriod(rawData, startOfYear, now)
  }

  if (period === periods.PERIOD_SIX_MONTHS) {
    const now = moment()

    return filterDataByPeriod(rawData, moment().subtract(6, 'months'), now)
  }

  if (period === periods.PERIOD_ONE_MONTH) {
    const now = moment()

    return filterDataByPeriod(rawData, moment().subtract(1, 'months'), now)
  }

  if (period === periods.PERIOD_ONE_WEEK) {
    const now = moment()

    return filterDataByPeriod(rawData, moment().subtract(1, 'weeks'), now)
  }
}

export { periods, sortIncomeHistoryData }
