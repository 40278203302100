import React from 'react'

import { useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

type TwoFactorAuthSetupDesktopProps = {
  handleBack: () => void
  handleContinue: () => void
}

const Desktop = ({ handleBack, handleContinue }: TwoFactorAuthSetupDesktopProps): React.ReactElement => {
  const backLink = useMemo(
    () => (
      <Link onClick={handleBack} mods={{ color: 'content-on-background-default' }}>
        <ItemWithIcon
          space={16}
          icon={<Icon type="back-new" size={24} color="inherit" />}
          content={<Typography color="inherit">Back</Typography>}
          iconVerticalAlign="center"
        />
      </Link>
    ),
    [handleBack],
  )

  const titleNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography size={32} lineHeight="small" weight="semibold" align="center">
          <Typo>Disable 2FA authentication</Typo>
        </Typography>
      </Paper>
    ),
    [],
  )

  const descriptionNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography size={14} align="center">
          <Typo>
            If you disable two-factor authentication, your InvestEngine account will only be password-protected.
          </Typo>
        </Typography>
      </Paper>
    ),
    [],
  )

  const button = useMemo(
    () => (
      <Paper top={56}>
        <Button mods={{ size: 'big block' }} onClick={handleContinue}>
          Disable
        </Button>
      </Paper>
    ),
    [handleContinue],
  )

  const content = (
    <Inner twoColumns>
      {backLink}
      <Width size={27} center>
        <AllCenter>
          <Paper top={32}>
            <Icon type="disable-2fa" size={80} />
          </Paper>
        </AllCenter>
        {titleNode}
        {descriptionNode}
        <Width size={24} center>
          {button}
        </Width>
      </Width>
    </Inner>
  )

  return <DesktopLayout header={<DesktopHeader />} content={content} footer={<DesktopFooter />} />
}

export { Desktop }
