import React, { Fragment } from 'react'

import classNames from 'classnames/dedupe'
import { useUnit } from 'effector-react'
import pieChartImage from 'images/pieChart.png'

import { useCallback, useMediaQueries, useMemo, useState } from 'hooks'

import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router.js'

import { $dictsStore } from 'app/effector/dicts'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { TsCsModal } from 'app/pages/Dashboard/Goals/DIY/SharePortfolio/components/TsCsModal'
import { useStrapiBonuses } from 'app/pages/Dashboard/PromoBanner/hooks/useStrapiBonuses'

import './SharePortfolioBanner.css'

type SharePortfolioBannerProps = {
  portfolioId: string
  isSharingEnabled: boolean
}

const SharePortfolioBanner = ({ portfolioId, isSharingEnabled }: SharePortfolioBannerProps): React.ReactElement => {
  const { bonuses } = useStrapiBonuses()
  const { desktop } = useMediaQueries()
  const { isPromoEnabled } = useUnit($dictsStore)

  const handleClick = useCallback(() => {
    goTo(urlTo('dashboard.portfolio.share', { id: portfolioId }, { back: location.href }))
  }, [portfolioId])

  const [showTsCsModal, setShowTsCsModal] = useState<boolean>(false)

  const handleCloseTsCsModal = useCallback(() => {
    setShowTsCsModal(false)
  }, [])
  const handleOpenTsCsModal = useCallback((event) => {
    event.stopPropagation()
    setShowTsCsModal(true)
  }, [])

  const topBlockNode = useMemo(() => {
    const labelClasses = classNames('SharingEnabledLabel', { SharingEnabledLabel_active: isSharingEnabled })

    return (
      <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
        <Column size={1}>
          <Typography size={14} color="additional">
            <Typo>Share portfolio outline</Typo>
          </Typography>
        </Column>
        <Column size={0}>
          <ItemWithIcon
            space={4}
            inline
            icon={
              <Icon
                type="chevron_right"
                color={isSharingEnabled ? palette['secondary-default'] : palette['content-on-background-minor']}
                size={16}
              />
            }
            content={
              <Typography
                size={desktop ? 14 : 12}
                inline
                className={labelClasses}
                lineHeight={desktop ? 'medium' : 'small'}
                color={isSharingEnabled ? 'on_color_default' : 'minor'}
                data-test-id="shareBannerState"
              >
                <Paper
                  top={desktop ? 0 : 1}
                  bottom={desktop ? 0 : 1}
                  left={desktop ? 8 : 4}
                  right={desktop ? 8 : 4}
                  inline
                >
                  <Typo>{isSharingEnabled ? 'ON' : 'OFF'}</Typo>
                </Paper>
              </Typography>
            }
            iconVerticalAlign="center"
            contentVerticalAlign="center"
            iconPosition="right"
          />
        </Column>
      </ColumnarLayout>
    )
  }, [isSharingEnabled, desktop])

  const bottomBlockNode = useMemo(
    () => (
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column size={1}>
          <Typography size={desktop ? 16 : 18} weight="semibold" lineHeight="small">
            Share your portfolio's outline
          </Typography>
          <Paper top={desktop ? 16 : 12} bottom={desktop ? 4 : 12}>
            <Typography size={14} lineHeight="small" color="additional">
              <Typo>
                {isPromoEnabled
                  ? `Show friends your ETFs and weights. You’ll both earn up to ${bonuses?.referIsaOrGeneralBonusMax} if they open an account!`
                  : 'Show friends your ETFs and weights'}
              </Typo>
            </Typography>
          </Paper>
          <Typography size={14} lineHeight="small">
            <Link onClick={handleOpenTsCsModal}>More info</Link>
          </Typography>
        </Column>
        <Column size={0}>
          <Paper left={desktop ? 1 : 24}>
            <img src={pieChartImage} width={desktop ? 67 : 100} height={desktop ? 72 : 107} />
          </Paper>
        </Column>
      </ColumnarLayout>
    ),
    [desktop, isPromoEnabled, bonuses, handleOpenTsCsModal],
  )

  return (
    <Fragment>
      <div className="SharePortfolioBannerContainer">
        {/* because it only needed for hover state = desktop */}

        {desktop && (
          <Paper
            className={classNames('SharePortfolioBannerTermsLink', { SharePortfolioBannerTermsLink_desktop: desktop })}
          >
            <Typography size={14} lineHeight="small">
              <Link onClick={handleOpenTsCsModal}>More info</Link>
            </Typography>
          </Paper>
        )}

        <SelectableCard className="SharePortfolioBanner" onClick={handleClick} data-test-id="shareBanner">
          <Paper
            top={20}
            bottom={20}
            left={desktop ? 24 : 12}
            right={desktop ? 24 : 12}
            className="SharePortfolioBannerTopBlock"
          >
            {topBlockNode}
          </Paper>
          <Paper top={24} bottom={desktop ? 32 : 24} left={desktop ? 24 : 12} right={desktop ? 24 : 12}>
            {bottomBlockNode}
          </Paper>
        </SelectableCard>
      </div>

      <TsCsModal isPromoEnabled={isPromoEnabled} show={showTsCsModal} handleClose={handleCloseTsCsModal} />
    </Fragment>
  )
}

export { SharePortfolioBanner }
