import { useUnit } from 'effector-react'
import pick from 'lodash/pick'

import { useActions, useCallback, useContext, useDispatch, useMemo, useSelector, useState } from 'hooks'

import { sendError } from 'helpers/errorLogging.js'
import { format as formatMoney } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router.js'
import { combineErrors, backendErrorsToObj } from 'helpers/validation.js'

import { $allowancesStore } from 'app/effector/allowances'
import { $dictsStore } from 'app/effector/dicts'

import {
  updateOrCreate as updateOrCreateGoalActionCreator,
  setValid as setValidActionCreator,
  setNotValid as setNotValidActionCreator,
  addFundsWireTransfer as addFundsWireTransferActionCreator,
  changeField as changeFieldActionCreator,
  changeGoalMonthlyDepositToDirectDebit as changeGoalMonthlyDepositToDirectDebitActionCreator,
} from 'app/redux/actions/portfolios'
import {
  showFailToast,
  showSuccessToast,
  changeModalField as changeModalFieldActionCreator,
} from 'app/redux/actions/ui'
import { isClientRegistered, isLockedGoal } from 'app/redux/selectors'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'
import { useIncomeProjections as useIncomeProjectionsEdit } from 'app/pages/Dashboard/Goals/hooks'

import { type ManagedPortfolio } from '../../../../types'

import { regulatoryTypes } from 'constants/portfolio'
import { MAX_SINGLE_PAYMENT_AMOUNT } from 'constants/validations.js'

type UseIncomeProjectionsProps = {
  portfolio: ManagedPortfolio
  editablePortfolioFieldsString?: string
}

type UseIncomeProjectionsReturnProps = {
  isLoading: boolean
  validation: object
  clientRegistered: boolean
  presets: object[]
  presetIndex: number
  recommendedIndex: number
  editableGoal: ManagedPortfolio
  isGoalActive: boolean
  isGoalLocked: boolean
  isGoalChanged: boolean
  projectionsData: object | null
  shouldShowScrollToKey: string
  isPresetChanged: boolean
  initialDepositMin: number
  visibleAttentionModal: boolean
  setShouldShowScrollToKey: (value: string) => void
  handleGoalChange: (field: string, value: number) => void
  handleSubmit: () => void
  handleCloseAttantionModal: (isSubmitDisabled: boolean) => void
}

const useIncomeProjections = ({
  portfolio,
  editablePortfolioFieldsString,
}: UseIncomeProjectionsProps): UseIncomeProjectionsReturnProps => {
  const dispatch = useDispatch()

  const { initialDepositMin } = useUnit($dictsStore)
  const clientRegistered = useSelector(isClientRegistered)
  const lockedGoal = useSelector((state) => isLockedGoal(state, portfolio.id))

  const [shouldShowScrollToKey, setShouldShowScrollToKey] = useState('')
  const [visibleAttentionModal, toggleAttentionModal] = useState(false)

  const [
    updateOrCreateGoal,
    setValid,
    setNotValid,
    changeField,
    changeModalField,
    changeGoalMonthlyDepositToDirectDebit,
  ] = useActions([
    updateOrCreateGoalActionCreator,
    setValidActionCreator,
    setNotValidActionCreator,
    changeFieldActionCreator,
    changeModalFieldActionCreator,
    changeGoalMonthlyDepositToDirectDebitActionCreator,
  ])

  const { setTunnel, tunnelQuery, takeNextStep, resetTunnel, tunnel } = useContext(ChangeGoalTunnelContext)

  const editablePortfolioFields: { one_off_payment?: number; preset?: number } = useMemo(() => {
    try {
      if (editablePortfolioFieldsString) {
        return JSON.parse(editablePortfolioFieldsString)
      }

      return {}
    } catch (error) {
      return {}
    }
  }, [editablePortfolioFieldsString])

  const {
    presets,
    isLoading,
    isGoalActive,
    editableGoal,
    projections,
    isPresetChanged,
    isGoalChanged,
    handleEditableGoalChange,
    handleEditableGoalReset,
    updateGoalChanges,
  } = useIncomeProjectionsEdit(portfolio, initialDepositMin, editablePortfolioFields)

  const initialDeposit = parseFloat(editableGoal?.initial_deposit)
  const goalError = useSelector((state) => state.portfolios.error)
  const recommendedIndex = presets.findIndex((preset) => preset.id === portfolio.preset_recommended)
  const presetIndex = presets.findIndex((preset) => preset.id === editableGoal?.preset)
  const isChangedToRecomended = recommendedIndex === presetIndex && !clientRegistered
  const projectionsData =
    initialDeposit >= initialDepositMin || parseFloat(portfolio.capital_balance) > 0
      ? pick(projections, 'future')
      : null

  const { allowances } = useUnit($allowancesStore)
  const isaRemainingAllowance = parseFloat(allowances?.isa?.remaining_allowance ?? '0')

  const maxOneOffPaymentAmount = useMemo(() => {
    if (portfolio.regulatory_type === regulatoryTypes.ISA) {
      return isaRemainingAllowance
    }
    return MAX_SINGLE_PAYMENT_AMOUNT
  }, [portfolio, isaRemainingAllowance])

  const validation = combineErrors(
    {
      initial_deposit: {
        rules: [
          parseFloat(editableGoal?.capital_balance) > 0 ||
            parseFloat(editableGoal?.initial_deposit) >= initialDepositMin,
          editableGoal?.initial_deposit <= MAX_SINGLE_PAYMENT_AMOUNT,
        ],
        errors: [
          `Must be at least ${formatMoney(initialDepositMin)}`,
          `Maximum amount is ${formatMoney(MAX_SINGLE_PAYMENT_AMOUNT)}`,
        ],
      },
      one_off_payment: {
        rules: [editableGoal?.one_off_payment <= maxOneOffPaymentAmount],
        errors: [`Maximum amount is ${formatMoney(maxOneOffPaymentAmount)}`],
      },
    },
    backendErrorsToObj(goalError),
  )

  const handleGoalChange = useCallback(
    (field, value) => {
      handleEditableGoalChange(field, value)
      setShouldShowScrollToKey(JSON.stringify({ [field]: value }))
    },
    [handleEditableGoalChange],
  )

  const openMonthlyDisclaimerModal = useCallback(() => {
    resetTunnel()
    goTo(urlTo('dashboard.portfolio.cant-set-up-monthly-payment', { id: portfolio.id }, tunnelQuery), {
      replace: true,
      scrollToTop: false,
    })
  }, [portfolio.id, resetTunnel, tunnelQuery])

  const openDirectDebitModal = useCallback(
    (monthlyDeposit) => {
      goTo(urlTo(`dashboard.portfolio.options.direct-debit`, { id: portfolio.id }, { ...tunnelQuery, promo: true }), {
        replace: true,
        scrollToTop: false,
      })
      changeField({ monthly_deposit: monthlyDeposit }, editableGoal?.id)
      changeModalField('directDebit', { monthlyPaymentAmount: monthlyDeposit })
    },
    [portfolio.id, tunnelQuery, changeField, editableGoal?.id, changeModalField],
  )

  const onAfterDirectDebitSubmit = useCallback(
    (goalId) => {
      changeGoalMonthlyDepositToDirectDebit(goalId, (nextGoal) => {
        if (nextGoal) {
          handleEditableGoalReset()
          goTo(urlTo('dashboard.portfolio', { id: goalId }, tunnelQuery), {
            replace: true,
            scrollToTop: false,
          })
        }
      })
    },
    [changeGoalMonthlyDepositToDirectDebit, handleEditableGoalReset, tunnelQuery],
  )

  const saveGoal = useCallback(async () => {
    const fieldsToUpdate = ['initial_deposit', 'preset']
    const message =
      portfolio.preset === editableGoal?.preset ? 'Portfolio saved' : 'Portfolio change request has been submitted'
    const stateAfterUpdate = await updateOrCreateGoal(fieldsToUpdate, editableGoal, null, editableGoal?.id, false)

    if (stateAfterUpdate.portfolios.error) {
      showFailToast('Portfolio edit failed')
      throw stateAfterUpdate.client.error
    }

    setValid()

    showSuccessToast(message)
  }, [portfolio.preset, editableGoal, updateOrCreateGoal, setValid])

  const handleSubmit = useCallback(() => {
    const runNextStep = (): void => {
      const nextStep = takeNextStep()

      if (nextStep && typeof nextStep === 'function') {
        return nextStep()
      }

      resetTunnel()
    }

    if (tunnel.length > 0) {
      runNextStep()
      return
    }

    const nextTunnel = []

    if (!visibleAttentionModal && isPresetChanged && !isChangedToRecomended) {
      nextTunnel.push(() => {
        toggleAttentionModal(true)
      })
    }

    nextTunnel.push(async () => {
      toggleAttentionModal(false)
      await setNotValid()
      try {
        await saveGoal()
        runNextStep()
        resetTunnel()
      } catch (error) {
        sendError(error)
      }
    })

    if (editableGoal.one_off_payment) {
      nextTunnel.push(async () => {
        try {
          const {
            goals: { error },
          } = await dispatch(addFundsWireTransferActionCreator(editableGoal.one_off_payment, editableGoal.id))
          changeField({ one_off_payment: 0 }, editableGoal?.id)

          if (error) {
            throw new Error(error)
          }

          const bankTransferUrl = 'dashboard.portfolio.add-funds.bank-transfer'
          goTo(urlTo(bankTransferUrl, { id: portfolio.id }, { ...tunnelQuery, amount: editableGoal.one_off_payment }), {
            replace: true,
            scrollToTop: false,
          })
        } catch (error) {
          sendError(error)
          showFailToast('Something went wrong')
        } finally {
          handleEditableGoalReset()
        }
      })
    }

    if (editableGoal?.monthly_deposit !== portfolio.monthly_deposit) {
      nextTunnel.push(() => {
        if (editableGoal.one_off_payment) {
          changeField({ one_off_payment: null }, editableGoal?.id)
        }

        if (editableGoal.contributions && parseFloat(editableGoal.contributions.total) < initialDepositMin) {
          openMonthlyDisclaimerModal()
          return
        }

        openDirectDebitModal(editableGoal.monthly_deposit)
      })
    }

    if (
      portfolio.contributions &&
      parseFloat(portfolio.contributions.total) > initialDepositMin &&
      editableGoal?.monthly_deposit !== portfolio.monthly_deposit
    ) {
      nextTunnel.push(() => {
        onAfterDirectDebitSubmit(portfolio.id)
      })
    }

    const oldLastStep = nextTunnel[nextTunnel.length - 1]
    nextTunnel[nextTunnel.length - 1] = () => {
      updateGoalChanges()
      oldLastStep()
    }

    const [runFirstStep, ...nextTunnelWithoutFirstStep] = nextTunnel

    setTunnel(nextTunnelWithoutFirstStep)
    runFirstStep()
  }, [
    tunnel.length,
    editableGoal.one_off_payment,
    editableGoal.monthly_deposit,
    editableGoal.id,
    editableGoal.contributions,
    visibleAttentionModal,
    isPresetChanged,
    isChangedToRecomended,
    portfolio.monthly_deposit,
    portfolio.contributions,
    portfolio.id,
    initialDepositMin,
    setTunnel,
    takeNextStep,
    resetTunnel,
    setNotValid,
    saveGoal,
    dispatch,
    changeField,
    tunnelQuery,
    handleEditableGoalReset,
    openDirectDebitModal,
    openMonthlyDisclaimerModal,
    onAfterDirectDebitSubmit,
    updateGoalChanges,
  ])

  const handleCloseAttantionModal = useCallback(
    (isSubmitDisabled) => {
      if (isSubmitDisabled) {
        resetTunnel()
      }
      toggleAttentionModal(false)
    },
    [resetTunnel, toggleAttentionModal],
  )

  return {
    isLoading,
    validation,
    clientRegistered,
    presets,
    presetIndex,
    recommendedIndex,
    editableGoal,
    isGoalActive,
    isGoalLocked: lockedGoal,
    isGoalChanged,
    projectionsData,
    shouldShowScrollToKey,
    isPresetChanged,
    initialDepositMin,
    setShouldShowScrollToKey,
    visibleAttentionModal,
    handleGoalChange,
    handleSubmit,
    handleCloseAttantionModal,
  }
}

export { useIncomeProjections }
