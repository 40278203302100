import React from 'react'

import { useState } from 'hooks'

import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelText } from 'components/_old/Label/Label.jsx'
import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

const texts = {
  1: `• Original or certified copy of a payslip (or bonus payment), or<br/>
  • Letter from employer confirming salary`,
  2: `• Certified investment/savings certificates, contract notes or cash-in statements, and<br/>
  • Bank statement clearly showing receipt of funds and investment company name,or<br/>
  • Signed letter detailing funds from a regulated accountant`,
  3: `• Signed letter from solicitor, or<br/>
      • Certified copy sale contract, or<br/>
      • Signed letter from estate agent`,
  4: `• Grant of probate (with a copy of the will), which must include the value of the estate, or<br/>
  • Signed letter from solicitor`,
  5: `• Signed letter from solicitor or regulated accountant, or<br/>
  • Copy of contract of sale, plus bank statement showing proceeds, and<br/>
  • Copies of media coverage (if applicable) as supporting evidence`,
  6: `• Donor’s source of wealth - please change the drop-down selection to an applicable source of wealth of your donor to see the list of required evidence.<br/>
  Please also attach a letter from the donor confirming details of the gift.`,
}

const SourceOfWealthSelect = (): React.ReactElement => {
  const [sowValue, setSowValue] = useState(null)

  const selectOptions = [
    {
      value: 1,
      name: 'Savings from salary (basic and/or bonus)',
    },
    {
      value: 2,
      name: 'Sales of shares or other investments / liquidation of investment portfolio',
    },
    {
      value: 3,
      name: 'Sale of property',
    },
    {
      value: 4,
      name: 'Inheritance',
    },
    {
      value: 5,
      name: 'Company sale',
    },
    { value: 6, name: 'Gift' },
  ]

  return (
    <div>
      <Typography size={16}>
        <Typo>Please select your source of wealth to see the required evidence</Typo>
      </Typography>
      <Paper top={24}>
        <Label>
          <LabelText>
            <Typography size={12} color="additional">
              <Typo>Source of Wealth</Typo>
            </Typography>
          </LabelText>
          <Input
            type="select"
            placeholder="Select"
            mods={{ size: 'bigger' }}
            options={selectOptions}
            tabIndex={1}
            onChange={(event) => {
              setSowValue(event.target.value)
            }}
          >
            {sowValue}
          </Input>
        </Label>
      </Paper>
      {sowValue && (
        <Paper top={24}>
          <Typography>
            <Typo>{texts[sowValue]}</Typo>
          </Typography>
        </Paper>
      )}
    </div>
  )
}

export { SourceOfWealthSelect }
