import type * as React from 'react'

import { useActions, useMemo } from 'hooks'

import canDownload from 'helpers/canDownload'
import { urlTo } from 'helpers/router.js'

import { closeIsaPanel as closeIsaPanelActionCreator } from 'app/redux/actions/portfolios'

type UseTransferIsaProps = {
  portfolioId?: string
}

type UseTransferIsaInterface = {
  onlineFormProps: {
    to: string | null
    target?: string
    hard: boolean
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
    onClose?: (event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => void
  }
  downloadLinkProps:
    | {
        to: string
        download: boolean
        hard: boolean
      }
    | {
        to: string
        target: string
        hard: boolean
      }
    | {
        onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void
      }
}

const useTransferIsaForm = ({ portfolioId }: UseTransferIsaProps): UseTransferIsaInterface => {
  const closeIsaPanel = useActions(closeIsaPanelActionCreator)

  const onlineFormProps = useMemo(() => {
    const onClose = (_: React.MouseEvent<HTMLAnchorElement>): void => {
      if (portfolioId) {
        closeIsaPanel(portfolioId)
      }
    }

    const back = window.location.pathname
    const queryString: Record<string, string> = { back }

    if (portfolioId) {
      queryString.portfolioId = portfolioId
    }

    const to = urlTo('transfer-isa-altus', null, queryString)
    const hard = false

    return {
      to,
      hard,
      onClose: portfolioId ? onClose : undefined,
    }
  }, [portfolioId, closeIsaPanel])

  const downloadLinkProps = useMemo(() => {
    const to = '/docs/InvestEngine-ISA-Transfer-Form.pdf'

    return canDownload() ? { to, download: true, hard: true } : { to, target: '_blank', hard: true }
  }, [])

  return {
    onlineFormProps,
    downloadLinkProps,
  }
}

export { useTransferIsaForm, type UseTransferIsaProps, type UseTransferIsaInterface }
