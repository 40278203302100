import { Entity } from 'app/redux/models/common/Entity.js'

import { SecurityTotalReturnsHistoryCollection } from './SecurityTotalReturnsHistoryCollection'
import { SecurityTotalReturnsStatsCollection } from './SecurityTotalReturnsStatsCollection'

export class SecurityTotalReturnsCollection extends Entity {
  history: SecurityTotalReturnsHistoryCollection
  stats: SecurityTotalReturnsStatsCollection

  constructor() {
    super()

    this.history = new SecurityTotalReturnsHistoryCollection()
    this.stats = new SecurityTotalReturnsStatsCollection()
  }

  getRules(): {
    history: [[(history) => SecurityTotalReturnsHistoryCollection]]
    stats: [[(stats) => SecurityTotalReturnsStatsCollection]]
  } {
    return {
      history: [[(history) => SecurityTotalReturnsHistoryCollection.createFromObject(history)]],
      stats: [[(stats) => SecurityTotalReturnsStatsCollection.createFromObject(stats)]],
    }
  }
}
