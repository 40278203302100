const PREFIX = 'UI'
export const SET_NOT_VALID = `${PREFIX}_SET_NOT_VALID`
export const SET_VALID = `${PREFIX}_SET_VALID`
export const RECEIVE_ERROR = `${PREFIX}_RECEIVE_ERROR`
export const RESET_ERROR = `${PREFIX}_RESET_ERROR`
export const RECEIVE_TOAST = `${PREFIX}_RECEIVE_TOAST`
export const REMOVE_TOAST = `${PREFIX}_REMOVE_TOAST`
export const CHANGE_TOASTS_VISIBILITY = `${PREFIX}_CHANGE_TOASTS_VISIBILITY`
export const REMOVE_TOASTS_WITH_STYLE = `${PREFIX}_REMOVE_TOASTS_WITH_STYLE`
export const CHANGE_MODAL_VISIBILITY = `${PREFIX}_CHANGE_MODAL_VISIBILITY`
export const CHANGE_MODAL_FIELD = `${PREFIX}_CHANGE_MODAL_FIELD`
export const CHANGE_FIELD = `${PREFIX}_CHANGE_FIELD`
export const RECEIVE_FLATPAGE_SECTION = `${PREFIX}RECEIVE_FLATPAGE_SECTION`
export const UPDATE_NOTIFICATION_SETTINGS = `${PREFIX}_UPDATE_NOTIFICATION_SETTINGS`
