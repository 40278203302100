import React from 'react'

import { useMediaQueries } from 'hooks'

import { NotificationScopeType } from 'app/effector/notifications/models'

import { Paper } from 'components/atoms/Paper'

import NotificationGroup from 'components/organisms/Notification/NotificationGroup'

type NotificationsProps = {
  portfolioId: number
}

const Notifications = ({ portfolioId }: NotificationsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <NotificationGroup scope={NotificationScopeType.PORTFOLIO} portfolioId={portfolioId}>
      {(nodes) => <Paper top={desktop ? 48 : 16}>{nodes}</Paper>}
    </NotificationGroup>
  )
}

export { Notifications }
