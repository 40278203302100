import React, { Fragment } from 'react'

import { palette } from 'helpers/palette/'
import { urlTo, goTo } from 'helpers/router'

import { Typo } from 'components/_old/Typo/Typo'

import { BadgeNew } from 'components/atoms/BadgeNew'
import { CardNew } from 'components/atoms/CardNew'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import recoveryCodeImage from './recoveryCode.png'
import textMessageImage from './textMessage.png'

const RecoveryMethods = (): React.ReactElement => {
  const handleChooseTextMessage = (): void => {
    goTo(urlTo('two-factor-auth-setup.phone-number'))
  }

  const recoveryCodeCard = (
    <CardNew>
      <Paper top={24} bottom={24} right={20} left={32}>
        <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
          <Column size={1}>
            <Paper bottom={32}>
              <Paper inline>
                <BadgeNew
                  text="Recommended"
                  color={palette['content-on-color-white']}
                  backgroundColor={palette['primary-default']}
                />
              </Paper>
            </Paper>
            <Typography size={24} lineHeight="small">
              <Typo>Recovery code</Typo>
            </Typography>
            <Paper top={8}>
              <Typography size={14} color="additional">
                <Typo>The most secure option. Use your saved recovery code to regain access.</Typo>
              </Typography>
            </Paper>
          </Column>
          <Column size={0}>
            <img src={recoveryCodeImage} width={136} height={136} />
          </Column>
        </ColumnarLayout>
      </Paper>
    </CardNew>
  )

  const textMessageCard = (
    <CardNew>
      <Paper top={24} bottom={24} right={32} left={32}>
        <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
          <Column size={1}>
            <Typography size={24} lineHeight="small">
              <Typo>Text message (SMS)</Typo>
            </Typography>
            <Paper top={8}>
              <Typography size={14} color="additional">
                <Typo>A less secure option. A code will be sent to your phone via SMS.</Typo>
              </Typography>
            </Paper>
          </Column>
          <Column size={0}>
            <Paper left={16}>
              <img src={textMessageImage} width={116} height={36} />
            </Paper>
          </Column>
        </ColumnarLayout>
      </Paper>
    </CardNew>
  )

  return (
    <Fragment>
      <Paper>{recoveryCodeCard}</Paper>
      <Paper top={24} onClick={handleChooseTextMessage}>
        {textMessageCard}
      </Paper>
    </Fragment>
  )
}

export { RecoveryMethods }
