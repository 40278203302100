import React, { Fragment } from 'react'
import { Radio, RadioGroup } from 'react-radio-group'
import { withRouter } from 'react-router'

import { useUnit } from 'effector-react'
import moment from 'moment-timezone'

import { useCallback, useEffect, useMediaQueries, useRef } from 'hooks'

import { format as formatDate } from 'helpers/date'
import { features } from 'helpers/features'
import { palette } from 'helpers/palette'
import { urlTo } from 'helpers/router.js'

import {
  NomineesTransferProviderRegulatoryType,
  fetchProvidersIfNeeded,
} from 'app/effector/nominees-transfers/providers'
import { SippTransferRequestAssetType, SippTransferRequestPartType } from 'app/effector/nominees-transfers/request/sipp'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Input from 'components/_old/Input/Input.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Form } from 'components/atoms/Form/Form'
import { GatewayDest } from 'components/atoms/Gateway'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Relative } from 'components/atoms/Relative'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'
import { Validate } from 'components/atoms/Validate'

import { AnimatedNavigationBar } from 'components/molecules/AnimatedNavigationBar'
import { Modal } from 'components/molecules/Modal'
import { Preloader } from 'components/molecules/Preloader'
import { CheckboxWithLabel, RadioWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { NomineesProvider } from 'components/organisms/IsaTransferForm'

import { AvailableToTransferFrom } from 'app/pages/Dashboard/Goals/components/AvailableToTransferFrom'
import { OwnerDetails } from 'app/pages/Dashboard/TransferIsa/TransferIsa/components/OwnerDetails'

import {
  setPrevPage,
  setNextPage,
  closePage,
  initForm,
  setField,
  submitForm,
  $isPageLoading,
  $form,
  $validation,
  $shouldShowProvidersBlock,
  $displayedProviders,
  $providersOptions,
  $providersString,
} from './model'

type SippTransferModalProps = {
  params: {
    id: string
  }
  location: {
    query: {
      transferSipp: string
    }
  }
}

const SippTransferModal = withRouter(({ params, location }: SippTransferModalProps): React.ReactElement => {
  const { transferSipp, ...restQuery } = location?.query ?? {}
  const isOpened = transferSipp === 'true'
  const { desktop } = useMediaQueries()
  const titleRef = useRef()

  const isLoading = useUnit($isPageLoading)
  const [form, validation] = useUnit([$form, $validation])
  const [shouldShowProvidersBlock, filteredProviders, providersOptions, providersString] = useUnit([
    $shouldShowProvidersBlock,
    $displayedProviders,
    $providersOptions,
    $providersString,
  ])

  const handleOpen = useCallback(() => {
    const prevModule = params.id ? 'dashboard.portfolio' : 'dashboard'
    const prevUrl = urlTo(prevModule, params, restQuery)

    // Right now prev and next urls are the same, but in the future we will have different pages
    setPrevPage(prevUrl)
    setNextPage(prevUrl)

    initForm({
      part_type: SippTransferRequestPartType.FULL, // set default part_type
    })
  }, [params, restQuery])

  const handleClose = useCallback(() => {
    closePage()
  }, [])

  type Field = keyof typeof form | 'agreed'
  type FieldValue<F extends Field> = F extends 'agreed' ? boolean : (typeof form)[Exclude<F, 'agreed'>]
  const handleFieldChange = useCallback(
    <F extends Field>(field: F) =>
      (valueOrEvent: Event | FieldValue<F>, maybeValue?: FieldValue<F>) => {
        const value = typeof valueOrEvent === 'object' ? maybeValue : valueOrEvent

        setField({
          field,
          value,
        })
      },
    [],
  )

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    submitForm()
  }, [])

  // While page is still modal, trigger init only on handleOpen
  useEffect(() => {
    if (isOpened) {
      handleOpen()
    }
    // had to run only when isOpened changed
    // eslint-disable-next-line
  }, [isOpened])

  // prefetch providers before opening modal to avoid delay
  useEffect(() => {
    fetchProvidersIfNeeded(NomineesTransferProviderRegulatoryType.SIPP)
  }, [])

  const buttonNode = (
    <Button mods={{ size: 'big block' }} type="submit" form="sipp-transfer-form" disabled={isLoading}>
      Submit
    </Button>
  )

  const content = (
    <Form onSubmit={handleSubmit} id="sipp-transfer-form">
      <Paper top={16}>
        <Typography ref={titleRef} size={desktop ? 32 : 24} weight="semibold" lineHeight="small">
          <Typo>Application to transfer existing pension to InvestEngine</Typo>
        </Typography>
      </Paper>
      {shouldShowProvidersBlock && (
        <Paper top={desktop ? 40 : 48}>
          <AvailableToTransferFrom providers={filteredProviders} />
        </Paper>
      )}
      <Paper top={desktop ? 40 : 48}>
        <Typography size={24} weight="semibold" lineHeight="small">
          <Typo>We can only proceed with this transfer if</Typo>
        </Typography>
        <Paper top={32}>
          <Typography size={14}>
            <Typo>
              <ul>
                {shouldShowProvidersBlock && providersString && (
                  <li>{providersString} your current pension provider;</li>
                )}
                <li>Your pension is not in drawdown; and</li>
                <li>
                  Your pension is not subject to any existing or proposed divorce, earmarking or pension sharing order,
                  trustee in bankruptcy order or any other type of court order.
                </li>
              </ul>
            </Typo>
          </Typography>
        </Paper>
      </Paper>
      <Paper top={desktop ? 56 : 48}>
        <Typography size={24} weight="semibold" lineHeight="small">
          <Typo>Pension details</Typo>
        </Typography>
        <Paper top={32}>
          <Typography size={14}>
            <Typo>
              <p>
                Please enter all the details of your existing pension on this form. If there are any bits of information
                you don't have, or if you have any questions about this form, you can contact our Customer Support Team
                who will help you through the process.
              </p>
              <p>
                If you have more than one pension plan you wish to transfer into your InvestEngine Pension Plan then
                please complete a separate form for each.
              </p>
            </Typo>
          </Typography>
        </Paper>
        <Paper top={32}>
          <OwnerDetails size={14} />
        </Paper>
      </Paper>
      <Paper top={desktop ? 56 : 48}>
        <Typography size={desktop ? 24 : 18} weight="semibold" lineHeight="small">
          <Typo>
            {features.get('multiple-sipp-providers-transfer') ? 'Existing pension provider' : 'Transfer from Vanguard'}
          </Typo>
        </Typography>
        <Width size={22.5}>
          <Paper top={24}>
            {features.get('multiple-sipp-providers-transfer') ? (
              <NomineesProvider
                label="Existing pension providers"
                paperSize={4}
                value={form.provider ?? ''}
                options={providersOptions}
                validation={validation.provider}
                setValue={handleFieldChange('provider')}
                isForceValidationEnabled={false}
              />
            ) : (
              <Input type="hidden" name="provider" value={form.provider ?? ''} />
            )}
            <Validate rules={validation.plan_number.rules}>
              {(isValid) => (
                <LabelInlineStyle
                  labelText="Pension reference number"
                  errorMessages={validation.plan_number.errors}
                  size="small"
                  multiline
                >
                  <Paper top={4}>
                    <Input type="text" onChange={handleFieldChange('plan_number')} valid={isValid} required>
                      {form.plan_number ?? ''}
                    </Input>
                  </Paper>
                </LabelInlineStyle>
              )}
            </Validate>
          </Paper>
          <Paper top={16}>
            <Validate rules={validation.amount.rules}>
              {(isValid) => (
                <LabelInlineStyle
                  labelText="Amount to be transferred"
                  errorMessages={validation.amount.errors}
                  size="small"
                  multiline
                >
                  <Paper top={4}>
                    <Input type="money" onChange={handleFieldChange('amount')} withFloat valid={isValid} required>
                      {form.amount ?? ''}
                    </Input>
                  </Paper>
                  <Paper top={4}>
                    <Typography size={12} color="additional">
                      <Typo>Current pension value may change with markets</Typo>
                    </Typography>
                  </Paper>
                </LabelInlineStyle>
              )}
            </Validate>
          </Paper>
          <Paper top={24}>
            <Input type="hidden" name="part_type" value={form.part_type ?? ''} />
            <ItemWithIcon
              icon={<Icon type="information-16" color={palette['content-on-background-default']} size={16} />}
              iconVerticalAlign="center"
              space={16}
              content={
                <Typography size={14}>
                  <Typo>We only accept full pension transfers — partial transfers are not supported.</Typo>
                </Typography>
              }
              contentVerticalAlign="center"
            />
          </Paper>
          <Paper top={24}>
            <Validate rules={validation.asset_transfer_type.rules}>
              {(isValid, brokenRule, onFocus, onBlur) => (
                <Fragment>
                  <Paper bottom={16}>
                    <Typography weight="semibold">
                      <Typo>Transfer options</Typo>
                    </Typography>
                  </Paper>
                  <RadioGroup
                    className={isValid ? undefined : 'Input_error'}
                    selectedValue={form.asset_transfer_type ?? ''}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={(value) => {
                      handleFieldChange('asset_transfer_type')(value)
                      onBlur()
                    }}
                  >
                    <Stack vertical={4}>
                      <RadioWithLabel
                        CustomInput={Radio}
                        label={
                          <Paper top={2} left={4}>
                            <Typography size={14} weight="semibold" lineHeight="small">
                              <Typo>Transfer your existing ETFs (in-specie)</Typo>
                            </Typography>
                            <Paper top={8}>
                              <Typography size={14}>
                                <Typo>
                                  Move your existing ETFs to InvestEngine without selling them (known as an in-specie
                                  transfer). Other investments will be sold and transferred as cash. See our{' '}
                                  <Link to={urlTo('universe-all')} blank hard>
                                    ETF range
                                  </Link>{' '}
                                  to check which ETFs we accept.
                                </Typo>
                              </Typography>
                            </Paper>
                          </Paper>
                        }
                        value={SippTransferRequestAssetType.IN_SPECIE}
                        valid={isValid}
                        size="smaller"
                        onChange={handleFieldChange('asset_transfer_type')}
                        testId="in-specie-transfer"
                        name="asset_transfer_type"
                        data-test-id="in-specie-transfer"
                      />
                      <RadioWithLabel
                        CustomInput={Radio}
                        label={
                          <Paper top={2} left={4}>
                            <Typography size={14} weight="semibold" lineHeight="small">
                              <Typo>Transfer your pension as cash</Typo>
                            </Typography>
                            <Paper top={8}>
                              <Typography size={14}>
                                <Typo>
                                  Your current investments will be sold and transferred to InvestEngine as cash for you
                                  to invest.
                                </Typo>
                              </Typography>
                            </Paper>
                          </Paper>
                        }
                        value={SippTransferRequestAssetType.ENCASH}
                        valid={isValid}
                        size="smaller"
                        onChange={handleFieldChange('asset_transfer_type')}
                        testId="cash-transfer"
                        name="asset_transfer_type"
                        data-test-id="cash-transfer"
                      />
                    </Stack>
                  </RadioGroup>
                  {!isValid && (
                    <Paper top={8} left={32}>
                      <Typography color="error" size={14}>
                        <Typo>{validation.asset_transfer_type.errors[brokenRule]}</Typo>
                      </Typography>
                    </Paper>
                  )}
                </Fragment>
              )}
            </Validate>
          </Paper>
        </Width>
      </Paper>
      <Paper top={desktop ? 56 : 48}>
        <Typography size={24} weight="semibold" lineHeight="small">
          <Typo>Declaration</Typo>
        </Typography>
        <Paper top={32}>
          <Typography size={14}>
            <Typo>
              <p>
                You are aware that Quai Investment Services Limited is being asked to transfer benefits from your
                existing scheme to the InvestEngine Personal Pension.
              </p>
              <p>Please read the disclaimer below carefully.</p>
              <ul>
                <li>
                  I have neither sought nor been given any pension transfer advice or information from Quai Investment
                  Services Limited or any of its employees on the subject of pension transfers.
                </li>
                <li>
                  I have compared the benefits of the InvestEngine Personal Pension with those of the pension that I am
                  transferring.
                </li>
                <li>
                  I can confirm that I am aware that there may be some benefits available within my existing scheme that
                  will not be available via the InvestEngine Personal Pension.
                </li>
                <li>
                  I understand that if I have taken out protection then paying a contribution or making a transfer to
                  the InvestEngine Personal Pension could negate this protection.
                </li>
                <li>
                  I understand that the InvestEngine Personal Pension does not comply with the "stakeholder" criteria
                  which impose certain conditions such as limiting charges to a maximum of 1.5% per annum for the first
                  ten years and 1.0% per annum thereafter.
                </li>
              </ul>
            </Typo>
          </Typography>
        </Paper>
      </Paper>
      <Paper top={desktop ? 56 : 48}>
        <Typography size={24} weight="semibold" lineHeight="small">
          <Typo>Disclaimer</Typo>
        </Typography>
        <Paper top={32}>
          <Typography size={18} weight="semibold" lineHeight="small">
            <Typo>To the transferring scheme</Typo>
          </Typography>
        </Paper>
        <Paper top={24}>
          <Typography size={14}>
            <Typo>
              <ul>
                <li>
                  I authorise and instruct you to transfer funds from the transferring scheme as listed in the
                  "Transferring Scheme Information" section of this form directly to my InvestEngine Personal Pension.
                </li>
                <li>
                  I authorise you to release all necessary information to InvestEngine and Quai Investment Services
                  Limited to enable the transfer of funds to my InvestEngine Personal Pension.
                </li>
                <li>
                  I have read all the information provided or made available to me by the transferring scheme as listed
                  in the "Transferring Scheme Details" section of this application.
                </li>
                <li>
                  I accept that in order to comply with regulator obligations, Quai Investment Services Limited and the
                  transferring scheme named in this application may need to verify my identity and residential address
                  and may use credit reference agency services and ask for my documents to verify my identity and
                  address.
                </li>
                <li>
                  Until the application is accepted, and complete, Quai Investment Services Limited responsibility is
                  limited to the return of the total payment(s) to the transferring scheme.
                </li>
                <li>
                  I accept that I will be responsible for any losses and / or expenses which are the result, and which a
                  reasonable person would consider to be the probable result, of any untrue, misleading, or inaccurate
                  information deliberately or carelessly given by me, or on my behalf, either in this form or with
                  respect to benefits from the transferring scheme(s).
                </li>
                <li>
                  Where the payment(s) made to my InvestEngine Personal Pension represents all of the funds under the
                  transferring scheme(s) listed in the "Transferring Scheme Information" section of this form, then
                  payment made as instructed will mean that I shall no longer be entitled to receive pension or other
                  benefits from the transferring scheme(s) listed.
                </li>
                <li>
                  Where the payments made to my InvestEngine Personal Pension represent part of the fund under the plan
                  (s) listed in the "Transferring Scheme Details" section of this application, then payment made as
                  instructed will mean that I shall no longer be entitled to receive pension or other benefits from that
                  part of the transferring scheme(s) represented by the payment.
                </li>
                <li>
                  I promise to accept responsibility in respect of any claims, losses and expenses that my InvestEngine
                  Personal Pension and the transferring scheme may incur as a result of any incorrect information
                  provided by me in this application or any failure on my part to comply with any aspect of this
                  application.
                </li>
                <li>
                  I confirm that the value of any tax‑free cash I have already taken combined with the benefits I am
                  taking now are below my personal Lump Sum Allowance and Lump Sum and Death Benefit Allowance.
                </li>
                <li>
                  I will be solely responsible for any additional tax charges or any penalties which arise if the
                  information provided in this application is incorrect or if I have failed to comply with any aspect of
                  this application.
                </li>
                <li>
                  In addition, I promise to accept responsibility in respect of any claims, losses and expenses that
                  Quai Investment Services Limited and the transferring scheme may incur as a result of any incorrect
                  information provided by me in this application or of any failure on my part to comply with my aspect
                  of this application.
                </li>
              </ul>
            </Typo>
          </Typography>
        </Paper>
      </Paper>
      <Paper top={desktop ? 56 : 48}>
        <LabelInlineStyle labelText="Date" size="small" multiline>
          <Paper top={4} data-test-id="signatureDate">
            {formatDate(moment(new Date()).tz('Europe/London').startOf('day').toDate(), 'DD/MM/YYYY')}
          </Paper>
        </LabelInlineStyle>
        <Paper top={16}>
          <Typography size={desktop ? 16 : 14}>
            <Typo>This pension transfer form will be submitted electronically.</Typo>
          </Typography>
        </Paper>
        <Paper top={desktop ? 56 : 24}>
          <Validate rules={validation.agreed.rules}>
            <CheckboxWithLabel
              label={
                <Typography size={14}>
                  <Typo>I understand and agree with the terms provided in both the disclaimer and declaration</Typo>
                </Typography>
              }
              size="smaller"
              checked={form.agreed ?? false}
              onChange={handleFieldChange('agreed')}
              required
              value={form.agreed ?? false}
              outline
              data-test-id="agreed-checkbox"
            />
          </Validate>
        </Paper>
      </Paper>
    </Form>
  )

  const desktopLayout = (
    <Width size={48} style={{ minHeight: '600px' }}>
      <Paper left={4}>
        <Paper top={8} bottom={8} right={24} left={24}>
          <NavigationBar leftPartText="Back" onLeftPartClick={handleClose} />
        </Paper>
      </Paper>
      <Paper top={40} bottom={80}>
        <Width size={40} center>
          {content}
          <Paper top={56}>
            <Width size={21.5} center>
              {buttonNode}
            </Width>
          </Paper>
        </Width>
      </Paper>
    </Width>
  )

  const mobileLayout = (
    <MobileLayout
      header={
        <AnimatedNavigationBar nodeToWatchTo={titleRef?.current} leftPartText="Back" onLeftPartClick={handleClose}>
          <Typo>Pension transfer</Typo>
        </AnimatedNavigationBar>
      }
      fixedHeader={true}
      content={content}
      footer={<MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
    />
  )

  return (
    <Fragment>
      <Modal open={isOpened} onClose={handleClose} close={null}>
        <Relative>
          {desktop ? desktopLayout : mobileLayout}
          <Preloader loading={isLoading} size="big" zIndex />
        </Relative>
      </Modal>
      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
    </Fragment>
  )
})
SippTransferModal.displayName = 'SippTransferModal'

export { SippTransferModal }
