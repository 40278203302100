import React from 'react'

import classNames from 'classnames/dedupe'

import { useState, useEffect } from 'hooks'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Relative } from 'components/atoms/Relative'

import './ChartTooltip.css'

type ChartTooltipProps = {
  children?: React.ReactChild | null
  stub?: React.ReactChild | null
  className?: string
  visible: boolean
  pointerTransparent?: boolean
  fullSize?: boolean
}

const ChartTooltip = ({
  children,
  stub,
  className,
  visible,
  pointerTransparent,
  fullSize,
}: ChartTooltipProps): React.ReactElement => {
  const classes = classNames(className, 'ChartTooltip', {
    ChartTooltip_pointerTransparent: pointerTransparent,
    ChartTooltip_fullSize: fullSize,
    ChartTooltip_visible: visible,
  })

  const [cachedChildren, setCachedChildren] = useState(children)

  useEffect(() => {
    if (children) {
      setCachedChildren(children)
    }
  }, [children, setCachedChildren])

  useEffect(() => {
    return () => {
      setCachedChildren(null)
    }
  }, [])

  return (
    <Relative className={classes}>
      {cachedChildren && (
        <div className="ChartTooltip-Card">
          <Card level={0} borderRadius={0} borderWidth={stub ? 0 : 1} color="background-default">
            <Paper top={12} right={16} bottom={12} left={12}>
              {cachedChildren}
            </Paper>
          </Card>
        </div>
      )}
      {stub && <div className="ChartTooltip-Stub">{stub}</div>}
    </Relative>
  )
}

export { ChartTooltip }
