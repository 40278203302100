import React from 'react'

import classNames from 'classnames/dedupe'

import { useMemo } from 'hooks'

import { type sizes } from 'components/atoms/Typography'

import './DynamicFontSize.css'

type DynamicFontSizeProps = {
  children: React.ReactChild | React.ReactChild[] | string | number
  tag?: keyof JSX.IntrinsicElements
  className?: string
  min: (typeof sizes)[number]
  max: (typeof sizes)[number]
  lineHeight?: number
  length: number
  lengthLimit: number
  'data-test-id'?: string
}

const DynamicFontSize = React.memo(
  ({
    children,
    tag,
    className,
    min,
    max,
    lineHeight = 1,
    length: lengthRaw,
    lengthLimit,
    'data-test-id': dataTestId,
  }: DynamicFontSizeProps): React.ReactElement => {
    const Tag = useMemo(() => tag ?? 'span', [tag])
    const classes = useMemo(() => classNames(className, 'DynamicFontSize'), [className])
    const length = useMemo(() => {
      if (lengthRaw) {
        return lengthRaw
      }

      if (typeof children === 'number') {
        return `${children}`.length
      }

      if (typeof children === 'string') {
        return children.length
      }

      return 1
    }, [children, lengthRaw])
    const style = useMemo(
      () =>
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ({
          lineHeight,
          '--dynamic-font-size-min': `${min}px`,
          '--dynamic-font-size-max': `${max}px`,
          '--dynamic-font-size-lengthLimit': lengthLimit,
          '--dynamic-font-size-length': length,
        }) as React.CSSProperties,
      [lineHeight, min, max, lengthLimit, length],
    )

    return (
      <Tag className={classes} style={style} data-test-id={dataTestId}>
        {children}
      </Tag>
    )
  },
)

DynamicFontSize.displayName = 'DynamicFontSize'

export { DynamicFontSize }
