import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

type AltusRestrictionProps = {
  isModalVisible: boolean
  handleClose: () => void
}

const AltusRestriction = ({ isModalVisible, handleClose }: AltusRestrictionProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <Modal open={isModalVisible} onClose={handleClose}>
        <AllCenter>
          <Paper top={80} bottom={80}>
            <Width size={desktop ? 36 : null} center={desktop}>
              <Width size={4} center>
                <Icon type="warning-64" color={palette['status-warning']} size={64} fixedSize />
              </Width>
              <Paper top={24}>
                <Typography size={24} weight="semibold" align="center">
                  <Typo>Could not complete action</Typo>
                </Typography>
              </Paper>
              <Width size={22} center>
                <Paper top={24}>
                  <Typography size={16} align="center">
                    <Typo>You need to select at least one ETF in order to use this function</Typo>
                  </Typography>
                </Paper>
              </Width>
            </Width>
            {desktop && (
              <Paper top={32}>
                <Width size={15} center>
                  <Button mods={{ size: 'big block' }} onClick={handleClose}>
                    Continue
                  </Button>
                </Width>
              </Paper>
            )}
          </Paper>
        </AllCenter>
        {!desktop && (
          <MobileLayoutFooterButton>
            <Button onClick={handleClose}>Continue</Button>
          </MobileLayoutFooterButton>
        )}
      </Modal>
    </Fragment>
  )
}

export { AltusRestriction }
