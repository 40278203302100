import { useUnit } from 'effector-react'

import { useActions, useAsyncEffect, useCallback, useContext, useEffect, useLoading, useMemo, useSelector } from 'hooks'

import { features } from 'helpers/features'
import { getRandomInteger } from 'helpers/number'
import { goTo, urlTo } from 'helpers/router.js'

import { $analyticsStore, fetchAnalyticsItemFx } from 'app/effector/analytics'
import { type AnalyticsItemType } from 'app/effector/analytics/types'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'
import { type Portfolio } from 'app/redux/models/portfolio/types'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

import { type SingleHoldingProps } from '../SingleHolding/SingleHolding'

import { manageTypes, presetTypes } from 'constants/portfolio'

const moduleNames = {
  holding: 'holdings',
  sector: 'sectors',
  region: 'regions',
}

type UseSingleHoldingProps = {
  id?: string
  portfolioId?: string
  query?: {
    back?: string
    search?: string
  }
  type: AnalyticsItemType
  routes: Array<{
    [key: string]: unknown
    module: string
  }>
}

type UseSingleHoldingReturnProps = SingleHoldingProps & {
  isLoading: boolean
}

const useSingleHolding = ({
  id,
  portfolioId: portfolioIdString,
  query,
  type,
  routes,
}: UseSingleHoldingProps): UseSingleHoldingReturnProps => {
  const { isLoading, wait } = useLoading(false)

  const skeletonLengthByPortfolio = useMemo(
    () => ({
      [[manageTypes.DIY].join('')]: getRandomInteger(5, 8),
      [[manageTypes.MANAGED, presetTypes.GROWTH].join('')]: getRandomInteger(6, 10),
      [[manageTypes.SELF_SELECTED, presetTypes.SELF_SELECTED].join('')]: getRandomInteger(6, 10),
      [[manageTypes.MANAGED, presetTypes.INCOME].join('')]: getRandomInteger(0, 2),
    }),
    [],
  )

  const portfolioId = useMemo(() => (portfolioIdString ? parseInt(portfolioIdString, 10) : null), [portfolioIdString])
  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])
  const portfolios = useSelector((state: any) =>
    state.portfolios.list.getVisiblePortfolios({ includeCashPortfolios: false }),
  )

  const analyticsStore = useUnit($analyticsStore)
  const holdingData = useMemo(() => {
    if (isLoading) {
      const portfoliosData = portfolios
        .map((portfolio: Portfolio) => {
          const skeletonsKey = [portfolio.manage_type, portfolio.preset_type].filter(Boolean).join('')
          const skeletonsLengths = skeletonLengthByPortfolio[skeletonsKey] ?? 5

          if (skeletonsLengths > 0) {
            return {
              [portfolio.id]: new Array(skeletonsLengths).fill({ skeleton: true }),
            }
          }

          return null
        })
        .filter(Boolean)
        .reduce((result, portfolio) => ({ ...result, ...portfolio }), {})

      const countries = [
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
      ]

      return { portfolios: portfoliosData, countries }
    }

    return analyticsStore.getItemDataOf(portfolioId ?? 'all', type, parseInt(id ?? '0'))
  }, [type, id, analyticsStore, portfolios, isLoading, skeletonLengthByPortfolio, portfolioId])

  const { saveScrollPosition, restoreScrollPosition, resetScrollPosition } = useContext(ScrollSaverContext)

  const selectedPortfolio = useMemo(() => {
    if (portfolios.length <= 1) {
      return portfolios[0]
    }

    return portfolios.get(portfolioId)
  }, [portfolios, portfolioId])

  const totalAmount = useMemo(() => holdingData?.total_value, [holdingData?.total_value])

  const showTarget = Boolean(selectedPortfolio)

  const isCountriesTabActive =
    features?.get('country-drill-down') && routes.some((route) => route.module === 'countries')

  const regionTabs = [
    { title: 'Regions', id: '', isActive: routes.some((route) => route.module === 'regions') },
    { title: 'Countries', id: '.countries', isActive: isCountriesTabActive },
  ]

  const navigateToRegionTab = (tab): void => {
    const module = portfolioId ? 'analytics.portfolio' : 'analytics'
    goTo(urlTo(`${module}.${type}${tab.id}`, { portfolioId, id }, { ...query }))
  }

  const navigateToCountry = (countryId: string): void => {
    const currentLocation = window.location.pathname + window.location.search
    const module = portfolioId ? 'analytics.portfolio' : 'analytics'
    goTo(urlTo(`${module}.country`, { portfolioId, id: countryId }, { ...query, back: currentLocation }))
    resetScrollPosition()
  }

  useEffect(() => {
    restoreScrollPosition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useAsyncEffect(async () => {
    if (portfolios.length < 1) {
      await wait(fetchPortfolios({ setNotValidBefore: false, setValidAfter: false }))
    }

    try {
      holdingData
        ? fetchAnalyticsItemFx({ type, id, portfolioId })
        : wait(fetchAnalyticsItemFx({ type, id, portfolioId }))
    } catch {
      showFailToast('Error loading analytics')
    }
  }, [type, id, portfolioId])

  const sortedPortfolioIds = useMemo(() => {
    const portfolioIds = holdingData?.portfolios ? Object.keys(holdingData?.portfolios) : []

    return portfolioIds.sort(
      (a, b) =>
        (holdingData?.portfolios[b].reduce((acc, item) => acc + item.actual_weight, 0) ?? 0) -
        (holdingData?.portfolios[a].reduce((acc, item) => acc + item.actual_weight, 0) ?? 0),
    )
  }, [holdingData?.portfolios])

  const holdingDistribution = useMemo(
    () =>
      holdingData?.portfolios
        ? sortedPortfolioIds.reduce(
            (arr, portfolioId) => [
              ...arr,
              ...holdingData?.portfolios[portfolioId]?.map((item) => ({
                value: item.actual_weight,
                color: item.color,
              })),
            ],
            [],
          )
        : [],
    [holdingData?.portfolios, sortedPortfolioIds],
  )

  const handleBack = useCallback(() => {
    resetScrollPosition()

    query?.back
      ? goTo(query.back)
      : goTo(urlTo(`${portfolioId ? 'analytics.portfolio' : 'analytics'}.${moduleNames[type]}`, { portfolioId }, query))
  }, [portfolioId, query, resetScrollPosition, type])

  const handleGoToSecurity = useCallback(
    (portfolioWithSecurityId, securityId) => {
      saveScrollPosition()

      const module = 'portfolio.security'
      const selectedPortfolioId = portfolioId ? { portfolioId } : {}

      const filteredQuery = { ...query }

      if (filteredQuery?.back) {
        filteredQuery.back = filteredQuery.back
          .replaceAll(/(&?regions=)(.[^&]*)/g, '')
          .replaceAll(/(&?sectors=)(.[^&]*)/g, '')
          .replaceAll(/(&?search=)(.[^&]*)/g, '')
      }

      delete filteredQuery.sectors
      delete filteredQuery.regions
      delete filteredQuery.search

      goTo(
        urlTo(
          module,
          { id: portfolioWithSecurityId, securityId },
          { ...filteredQuery, fromAnalytics: true, ...selectedPortfolioId, tab: type, id },
        ),
      )
    },
    [id, portfolioId, type, query, saveScrollPosition],
  )

  return {
    isLoading,
    portfolios,
    selectedPortfolio,
    showTarget,
    holdingData,
    holdingDistribution,
    sortedPortfolioIds,
    totalAmount,
    isCountriesTabActive,
    regionTabs,
    navigateToRegionTab,
    navigateToCountry,
    handleBack,
    handleGoToSecurity,
  }
}

export { useSingleHolding }
