import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries, useMemo, useCallback, useActions } from 'hooks'

import { patchPortfolio as patchPortfolioActionCreator } from 'app/redux/actions/portfolios'
import { showSuccessToast, showFailToast } from 'app/redux/actions/ui'
import type { Portfolio } from 'app/redux/models/portfolio/types'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { OptionsLayout } from '../../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../../Options/OptionsNavigationBar'

type AutoinvestSuggestionProps = {
  portfolio: Portfolio
  handleClose: () => void
}

const AutoinvestSuggestion = withRouter(({ portfolio, handleClose }: AutoinvestSuggestionProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const patchPortfolio = useActions(patchPortfolioActionCreator)

  const handleActivateAutoinvest = useCallback(async () => {
    try {
      const response = await patchPortfolio(
        portfolio.id,
        { is_autoinvest_enabled: true },
        { setNotValidBefore: false, setValidAfter: false },
      )

      if (response.portfolios.error) {
        throw Error()
      }
      showSuccessToast('AutoInvest activated')
    } catch (error) {
      showFailToast()
    }
    handleClose()
  }, [portfolio.id, patchPortfolio, handleClose])

  const inner = useMemo(
    () => (
      <Fragment>
        <Paper top={desktop ? 56 : 0} bottom={desktop ? 32 : 12}>
          <Typography
            tag="h1"
            size={desktop ? 32 : 24}
            lineHeight={desktop ? 'small' : 'medium'}
            align="center"
            weight="semibold"
          >
            <Typo>AutoInvest your cash</Typo>
          </Typography>
        </Paper>
        <Typography size={desktop ? 14 : 16} align="center">
          <Paper bottom={32}>
            <Typo>Automatically invest any topups and other cash based on your chosen portfolio weights.</Typo>
          </Paper>
          <Typography size={14} color="additional" align="center">
            <Typo>
              By activating AutoInvest you are instructing us to invest your portfolio's cash (£1 minimum and subject to
              any selected cash buffer) according to your chosen ETF weights, in the next available trading window.{' '}
              <Link to="/terms#autoinvest" blank hard>
                <Typo>More information on how AutoInvest works.</Typo>
              </Link>
            </Typo>
          </Typography>
        </Typography>
      </Fragment>
    ),
    [desktop],
  )

  const content = useMemo(() => {
    if (desktop) {
      return (
        <Width size={27} center>
          {inner}
        </Width>
      )
    }
    return <AllCenter>{inner}</AllCenter>
  }, [desktop, inner])

  return (
    <OptionsLayout
      header={
        <OptionsNavigationBar rightPartText="Close" onRightPartClick={handleClose}>
          <Typo>AutoInvest</Typo>
        </OptionsNavigationBar>
      }
      content={content}
      button={[
        <Button
          key="skipAutoinvestActivationButton"
          mods={{ theme: 'simple-reverse-blue' }}
          data-test-id="skipAutoinvestActivationButton"
          onClick={handleClose}
        >
          Do not activate
        </Button>,
        <Button
          key="activateAutoinvestButton"
          onClick={handleActivateAutoinvest}
          mods={{ size: 'big block' }}
          data-test-id="activateAutoinvestButton"
        >
          Activate AutoInvest
        </Button>,
      ]}
      data-test-id="autoinvestSuggestionModal"
    />
  )
})

export { AutoinvestSuggestion }
