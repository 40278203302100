import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

type TooltipProps = {
  date: string
  value: string
  newStyle?: boolean
}

const Tooltip = ({ date, value, newStyle = false }: TooltipProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  if (newStyle) {
    const rawValue = value.replace(/[£,]/g, '')

    return (
      <Fragment>
        <Typography
          size={desktop ? 32 : 20}
          lineHeight="small"
          weight="semibold"
          align="center"
          data-test-id="priceChartTooltipValue"
        >
          <PoundsWithPence amount={rawValue} showZeroPence />
        </Typography>
        <Paper top={desktop ? 8 : 0}>
          <Typography
            size={desktop ? 16 : 14}
            lineHeight={desktop ? 'small' : 'medium'}
            color="minor"
            align="center"
            data-test-id="priceChartTooltipDate"
          >
            <Typo>
              <Nobr>{date}</Nobr>
            </Typo>
          </Typography>
        </Paper>
      </Fragment>
    )
  }

  return (
    <Paper left={4} right={4}>
      <Typography size={14} lineHeight="small" color="minor">
        <Typo>
          <Nobr>{date}</Nobr>
        </Typo>
      </Typography>
      <Paper top={4}>
        <Typography size={20} lineHeight="small" weight="semibold">
          <Typo>{value}</Typo>
        </Typography>
      </Paper>
    </Paper>
  )
}

export { Tooltip }
