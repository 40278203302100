import React, { Fragment, memo } from 'react'

import { useMediaQueries, useMemo, useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PortfolioTabs } from 'app/pages/Dashboard/Goals/ManagedPortfolio/components/PortfolioTabs'

enum TransactionsHeaderTabs {
  TRANSACTIONS = 'Transactions',
  SECURITY_TRANSACTIONS = 'ETF transfers',
}

type TransactionsHeaderProps = {
  activeTabName: TransactionsHeaderTabs
  hasSecurityTransactions: boolean
  resetFilters?: () => void
}

const TransactionsHeader = memo(
  ({ activeTabName, hasSecurityTransactions, resetFilters }: TransactionsHeaderProps): React.ReactElement | null => {
    const { desktop } = useMediaQueries()

    const tabItems = useMemo(
      () => [
        {
          name: TransactionsHeaderTabs.TRANSACTIONS,
          visible: true,
          'data-test-id': 'transactionsTransactions',
        },
        {
          name: TransactionsHeaderTabs.SECURITY_TRANSACTIONS,
          visible: true,
          'data-test-id': 'transactionsSecurityTransactions',
        },
      ],
      [],
    )

    const activeTabIndex = useMemo(
      () => tabItems.findIndex((tabItem) => tabItem.name === activeTabName),
      [activeTabName, tabItems],
    )

    const navigateByTab = useCallback(
      (index: number, name: TransactionsHeaderTabs) => {
        const url = {
          [TransactionsHeaderTabs.TRANSACTIONS]: urlTo('transactions.list'),
          [TransactionsHeaderTabs.SECURITY_TRANSACTIONS]: urlTo('transactions.security-transactions'),
        }[name]

        if (resetFilters) {
          resetFilters()
        }

        if (url) {
          goTo(url)
        }
      },
      [resetFilters],
    )

    if (!hasSecurityTransactions) {
      return desktop ? (
        <Typography size={desktop ? 24 : 18} weight="semibold" lineHeight="small">
          <Typo>Transactions</Typo>
        </Typography>
      ) : null
    }

    return (
      <Fragment>
        {desktop && (
          <Typography size={24} weight="semibold" lineHeight="small" align="center">
            <Typo>Transactions</Typo>
          </Typography>
        )}
        <Paper top={desktop ? 32 : 0}>
          <PortfolioTabs tabItems={tabItems} activeTabIndex={activeTabIndex} navigateByTab={navigateByTab} />
        </Paper>
      </Fragment>
    )
  },
)
TransactionsHeader.displayName = 'TransactionsHeader'

export { TransactionsHeader, type TransactionsHeaderProps, TransactionsHeaderTabs }
