import React from 'react'

import { useCallback, useMediaQueries } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { format as formatMoney } from 'helpers/money'

import type { QuickStart } from 'app/effector/quickStart/types'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { usePortfolioActions, ActionKey } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'
import { frequencies } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'

import savingsPlanImage from './savingsPlan.png'

import './SavingsPlanSetupBanner.css'

type SavingsPlanSetupBannerProps = {
  portfolio: Portfolio
  quickStart: QuickStart
}

const frequencyPrepositionDict = {
  MONTHLY: 'each',
  WEEKLY: 'a',
  FORTNIGHT: 'every 2',
}

const frequencyDict = {
  MONTHLY: 'month',
  WEEKLY: 'week',
  FORTNIGHT: 'weeks',
}

const SavingsPlanSetupBanner = ({ portfolio, quickStart }: SavingsPlanSetupBannerProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { isRecurringPaymentAvailableForSetup, actionsMap } = usePortfolioActions(portfolio)
  const handleSavingsPlanAction = actionsMap[ActionKey.SAVINGS_PLAN]
  const areQuickStartDetailsHidden =
    !isRecurringPaymentAvailableForSetup &&
    (quickStart?.frequency === frequencies.WEEKLY || quickStart?.frequency === frequencies.FORTNIGHT)

  const handleClick = useCallback(() => {
    trackEvent({
      action: 'sp_finish_setup_clicked_banner',
      manage_type: portfolio?.manage_type,
      ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
    })

    const currentLocation = window.location.pathname + window.location.search

    handleSavingsPlanAction.onClick(currentLocation, false)
  }, [handleSavingsPlanAction, portfolio?.manage_type, portfolio?.preset_type])

  return (
    <SelectableCard className="SetupSavingsPlanBanner_Card" onClick={handleClick} data-test-id="savingsPlanBanner">
      <Paper top={desktop ? 16 : 24} bottom={desktop ? 16 : 24} left={desktop ? 32 : 24} right={desktop ? 32 : 0}>
        <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
          <Column size={1}>
            <Typography size={desktop ? 32 : 24} weight="semibold" color="white" data-test-id="savingsPlanBannerTitle">
              {areQuickStartDetailsHidden ? (
                <Typo noNbsp>
                  Finish setting up Savings <Nobr>Plan →</Nobr>
                </Typo>
              ) : (
                <Typo noNbsp>
                  Finish setting up Savings Plan of {formatMoney(quickStart?.amount, true)}{' '}
                  {frequencyPrepositionDict[quickStart?.frequency ?? 'MONTHLY']}{' '}
                  <Nobr>{frequencyDict[quickStart?.frequency ?? 'MONTHLY']} →</Nobr>
                </Typo>
              )}
            </Typography>
          </Column>
          <Column size={0}>
            <img src={savingsPlanImage} width={132} height={132} />
          </Column>
        </ColumnarLayout>
      </Paper>
    </SelectableCard>
  )
}

export { SavingsPlanSetupBanner }
