import React from 'react'

import { Badge } from 'components/atoms/Badge'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type SavingsPlanFrequencyBadgesProps = {
  fontSize?: number
}

const SavingsPlanFrequencyBadges = ({ fontSize = 16 }: SavingsPlanFrequencyBadgesProps): React.ReactElement => {
  return (
    <ColumnarLayout mods={{ padding: 'no' }}>
      <Column size={0}>
        <Typography size={fontSize} weight="semibold">
          <Badge theme="rounded-primary-action" text="Weekly" />
        </Typography>
      </Column>
      <Column size={0}>
        <Paper left={8} right={8}>
          <Typography size={fontSize} weight="semibold">
            <Badge theme="rounded-primary-action" text="Fortnightly" />
          </Typography>
        </Paper>
      </Column>
      <Column size={0}>
        <Typography size={fontSize} weight="semibold">
          <Badge theme="rounded-primary-action" text="Monthly" />
        </Typography>
      </Column>
    </ColumnarLayout>
  )
}

export { SavingsPlanFrequencyBadges }
