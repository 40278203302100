import React, { Fragment } from 'react'

import { useEffect, useMediaQueries, useMemo, useState } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelField, LabelText } from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'
import { Validate } from 'components/atoms/Validate'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Modal } from 'components/molecules/Modal'
import { Preloader } from 'components/molecules/Preloader'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { ThirdPartyTradeReportingModalStates, type UseThirdPartyTradeReportingInterface } from '../hooks'

function ThirdPartyTradeReportingModal({
  modalState,
  thirdPartyEmail,
  thirdPartyEmailEnabledDate,
  futureThirdPartyEmail,
  agreedWithPrivacyPolicy,
  validation,
  forceValidate,
  isModalVisible,
  isLoading,
  closeModal,
  handleThirdPartyFutureEmailInput,
  handleAgreedWithPrivacyPolicy,
  addThirdPartyEmail,
  removeThirdPartyEmail,
  cancelRemoveThirdPartyEmail,
}: UseThirdPartyTradeReportingInterface): React.ReactElement {
  const { desktop } = useMediaQueries()
  const [cachedThirdPartyEmail, setCachedThirdPartyEmail] = useState(thirdPartyEmail)

  useEffect(() => {
    if (thirdPartyEmail !== null) {
      setCachedThirdPartyEmail(thirdPartyEmail)
    }
  }, [thirdPartyEmail])

  const header = useMemo(() => {
    if (modalState === ThirdPartyTradeReportingModalStates.REMOVE_CONFIRM) {
      return <NavigationBar key="cancel" onRightPartClick={cancelRemoveThirdPartyEmail} rightPartText="Cancel" />
    }

    return (
      <NavigationBar
        key="default"
        onLeftPartClick={closeModal}
        leftPartText="Back"
        onRightPartClick={closeModal}
        rightPartText="Close"
      >
        {desktop ? (
          <Paper top={8} bottom={8}>
            <Typography size={20} weight="semibold" lineHeight="small">
              <Typo>Third party trade reporting</Typo>
            </Typography>
          </Paper>
        ) : (
          <Typo>Third party trade reporting</Typo>
        )}
      </NavigationBar>
    )
  }, [modalState, closeModal, cancelRemoveThirdPartyEmail, desktop])

  const content = useMemo(() => {
    if (modalState === ThirdPartyTradeReportingModalStates.REMOVE_CONFIRM) {
      return (
        <Paper top={32}>
          <Typography size={32} weight="semibold" lineHeight="small" align="center">
            <Typo>Are you sure you want to disable notifications to this email address?</Typo>
          </Typography>
          <Paper top={32}>
            <Typography size={14} lineHeight="small" align="center">
              <Typo>{cachedThirdPartyEmail}</Typo>
            </Typography>
          </Paper>
        </Paper>
      )
    }

    if (modalState === ThirdPartyTradeReportingModalStates.REMOVE) {
      return (
        <Fragment>
          <Typography size={14}>
            <Typo>Additional third party email to receive trade notifications on your accounts</Typo>
          </Typography>
          <Paper top={32}>
            <Typography size={18} lineHeight="small">
              <Typo>{cachedThirdPartyEmail}</Typo>
            </Typography>
          </Paper>
          <Paper top={8}>
            <Typography size={14} lineHeight="small" color="additional">
              <Typo>Enabled {thirdPartyEmailEnabledDate}</Typo>
            </Typography>
          </Paper>
          <Paper top={32}>
            <Typography size={14} lineHeight="small">
              <Link onClick={removeThirdPartyEmail}>
                <Typo>Remove this third party email</Typo>
              </Link>
            </Typography>
          </Paper>
        </Fragment>
      )
    }

    return (
      <Width size={desktop ? 20 : null} center>
        <Typography size={14}>
          <Typo>Additional third party email to receive trade notifications on your accounts</Typo>
        </Typography>
        <Paper top={32}>
          <Validate rules={validation.futureThirdPartyEmail.rules} skipWaitBlur={forceValidate}>
            <Label errorMessages={validation.futureThirdPartyEmail.errors}>
              <LabelText replaceWithError>
                <Typo>Third party email</Typo>
              </LabelText>
              <LabelField>
                <Input type="email" onChange={handleThirdPartyFutureEmailInput}>
                  {futureThirdPartyEmail}
                </Input>
              </LabelField>
            </Label>
          </Validate>
          <Paper top={24}>
            <Validate rules={validation.agreedWithPrivacyPolicy.rules} skipWaitBlur={forceValidate}>
              {(isValid, brokenRule, handleValidateFocus, handleBlur) => (
                <Fragment>
                  <CheckboxWithLabel
                    label={
                      <Fragment>
                        <Typography size={14}>
                          <Typo>
                            By providing trade notifications to third parties you consent to us sharing your data in
                            line with our privacy policy
                          </Typo>
                        </Typography>
                        {!isValid && (
                          <Paper top={8}>
                            <Typography color="error" size={12} lineHeight="small" align="left">
                              <Typo>{validation?.agreedWithPrivacyPolicy?.errors[brokenRule]}</Typo>
                            </Typography>
                          </Paper>
                        )}
                      </Fragment>
                    }
                    checked={agreedWithPrivacyPolicy}
                    onChange={handleAgreedWithPrivacyPolicy}
                  />
                </Fragment>
              )}
            </Validate>
          </Paper>
        </Paper>
      </Width>
    )
  }, [
    modalState,
    validation.futureThirdPartyEmail.rules,
    validation.futureThirdPartyEmail.errors,
    forceValidate,
    handleThirdPartyFutureEmailInput,
    futureThirdPartyEmail,
    thirdPartyEmailEnabledDate,
    removeThirdPartyEmail,
    desktop,
    cachedThirdPartyEmail,
    validation.agreedWithPrivacyPolicy?.rules,
    validation.agreedWithPrivacyPolicy?.errors,
    handleAgreedWithPrivacyPolicy,
    agreedWithPrivacyPolicy,
  ])

  const footer = useMemo(() => {
    if (modalState === ThirdPartyTradeReportingModalStates.REMOVE_CONFIRM) {
      const cancel = (
        <Button mods={{ theme: 'simple-reverse-blue', size: 'big block' }} onClick={cancelRemoveThirdPartyEmail}>
          Cancel
        </Button>
      )
      const submit = (
        <Button type="submit" mods={{ size: 'big block' }} onClick={removeThirdPartyEmail}>
          Remove this email
        </Button>
      )

      if (desktop) {
        return (
          <Paper top={32}>
            <Width size={24} center>
              <Stack vertical={24}>
                {cancel}
                {submit}
              </Stack>
            </Width>
          </Paper>
        )
      }

      return (
        <Fragment>
          <MobileLayoutFooterButton>{cancel}</MobileLayoutFooterButton>
          <MobileLayoutFooterButton>{submit}</MobileLayoutFooterButton>
        </Fragment>
      )
    }

    if (modalState === ThirdPartyTradeReportingModalStates.SET) {
      const add = (
        <Button type="submit" mods={{ size: 'big block' }} onClick={addThirdPartyEmail}>
          Add third party email
        </Button>
      )

      if (desktop) {
        return (
          <Paper top={32}>
            <Width size={20} center>
              {add}
            </Width>
          </Paper>
        )
      }

      return <MobileLayoutFooterButton>{add}</MobileLayoutFooterButton>
    }

    return null
  }, [modalState, cancelRemoveThirdPartyEmail, removeThirdPartyEmail, addThirdPartyEmail, desktop])

  if (desktop) {
    return (
      <Modal open={isModalVisible} onClose={closeModal} close={null}>
        <Width size={36} style={{ minHeight: 551 }}>
          <Paper top={12} bottom={80} right={24} left={24}>
            {header}
            <Paper top={80} left={48} right={48}>
              <ColumnarLayout flexDirection="column" mods={{ padding: 'no' }} style={{ minHeight: 339 }}>
                <Column size={1}>{content}</Column>
                <Column size={0}>{footer}</Column>
              </ColumnarLayout>
            </Paper>
          </Paper>
        </Width>
        <Preloader loading={isLoading} size="big" zIndex />
      </Modal>
    )
  }

  return (
    <Modal
      open={isModalVisible}
      onClose={closeModal}
      insideGatewayName="thirdPartyNotificationModal"
      data-test-id="thirdPartyNotificationModal"
      close={null}
    >
      <MobileLayout header={header} content={content} footer={footer} />
      <Preloader loading={isLoading} size="big" zIndex />
    </Modal>
  )
}

export { ThirdPartyTradeReportingModal }
