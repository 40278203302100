import { useMemo } from 'hooks'

import { useWeightsAnalytics } from '../ReviewAnalyticsModal/hooks/useWeightsAnalytics'

import { useAbandon } from './useAbandon.js'
import { useDeleteRestriction } from './useDeleteRestriction.js'
import { useHoldingModal } from './useHoldingModal'
import { useNavbar } from './useNavbar.js'
import { useSecuritiesForm } from './useSecuritiesForm.js'

const useEditWeight = (id, { mode, reviewModalOpened, ...searchQuery } = {}) => {
  const portfolioId = useMemo(() => parseInt(id, 10), [id])

  const {
    isLoading,

    securities,
    portfolio,
    isPortfolioNew,

    total,
    control,
    isFormDirty,

    handleDelete: handleDeleteRaw,
    handleSubmit,

    currentValues,
  } = useSecuritiesForm(portfolioId, { mode, ...searchQuery })

  const {
    handleAddSecurities: handleAddSecuritiesRaw,
    handleCancel: handleCancelRaw,
    handleBack: handleBackRaw,
  } = useNavbar(portfolio, mode, searchQuery)

  const {
    open: abandonModalOpen,

    handleClose: handleAbandonModalClose,
    handleContinue: handleContinueOnAbandonModal,
    handleAddSecurities,
    handleCancel,
    handleBack,
  } = useAbandon({
    portfolioId,
    isFormDirty,
    handleAddSecuritiesRaw,
    handleCancelRaw,
    handleBackRaw,
  })

  const { restrictionModalOpen, handleDelete, handleRestrictionModalClose, restrictionModalType } =
    useDeleteRestriction({ portfolioId, handleDeleteRaw })

  const {
    analytics,
    isLoading: isAnalyticsLoading,
    openModal: openReviewAnalyticsModal,
    closeModal: closeReviewAnalyticsModal,
    fetchAnalyticsByWeights,
  } = useWeightsAnalytics({ portfolioId, isPortfolioNew, currentValues, mode, searchQuery })

  const {
    isHoldingModalOpened,
    isLoading: isHoldingModalLoading,
    selectedPortfolio,
    holdingData,
    holdingDistribution,
    openHoldingModal,
    closeHoldingModal,
  } = useHoldingModal({ portfolioId, isPortfolioNew, currentValues })

  const infoText = useMemo(() => {
    if (portfolio?.is_autoinvest_enabled) {
      return 'AutoInvest is enabled so your portfolio cash will be automatically invested according to your chosen weights'
    }

    if (isPortfolioNew) {
      return 'Your chosen weights will apply to future portfolio investments. You can change these weights at any time.'
    }

    return 'Your chosen investment weights will apply to future portfolio orders.'
  }, [isPortfolioNew, portfolio])

  return {
    isLoading: isLoading || isAnalyticsLoading || isHoldingModalLoading,

    isPortfolioNew,
    infoText,
    securities,
    total,
    control,
    currentValues,

    abandonModalOpen,
    handleAbandonModalClose,
    handleContinueOnAbandonModal,

    restrictionModalOpen,
    restrictionModalType,
    handleRestrictionModalClose,

    handleAddSecurities,
    handleCancel,
    handleBack,
    handleDelete,
    handleSubmit,

    analytics,
    openReviewAnalyticsModal,
    closeReviewAnalyticsModal,
    fetchAnalyticsByWeights,

    isHoldingModalOpened,
    selectedPortfolio,
    holdingData,
    holdingDistribution,
    openHoldingModal,
    closeHoldingModal,
  }
}

export { useEditWeight }
