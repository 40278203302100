import React from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries } from 'hooks'

import { features } from 'helpers/features'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import List, { ListItem } from 'components/_old/List/List.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

import { FinishRegistrationModal } from './components/FinishRegistrashionModal'
import { NotApprovedModal } from './components/NotApprovedModal'
import { useOpenSippModal, steps } from './hooks/useOpenSippModal'
import sippImage from './sipp.png'

type OpenSippModalProps = {
  location: {
    query: {
      openSipp?: string
      startTransferSipp?: string
      back?: string
    }
  }
}

const LIST_ITEMS = {
  default: [
    'Grow your retirement savings with InvestEngine’s Self Invested Personal Pension (SIPP)',
    '0.15% p.a. charge with a cap of £200 (or £16.66 per month)',
    'Pension Transfers coming soon',
  ],
  zeroFees: [
    'Grow your retirement savings with InvestEngine’s Self Invested Personal Pension (SIPP)',
    'Worry-less about fees with zero account fees',
    'Invest in your choice of hundreds of quality ETFs, or let us build and manage a portfolio for you for a small fee',
  ],
}

const OpenSippModal = withRouter(({ location }: OpenSippModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const isZeroFeesEnabled = features?.get('zero-sipp-fees')

  const { openSipp, startTransferSipp, ...restQuery } = location?.query ?? {}
  const isOpen = openSipp === 'true'
  const isTransfer = startTransferSipp === 'true'

  const { step, handleClose, handleContinue, handleBack } = useOpenSippModal({ isOpen, isTransfer, query: restQuery })

  if (step === steps.FINISH_REGISTRATION) {
    return <FinishRegistrationModal handleContinue={handleContinue} handleBack={handleBack} handleClose={handleClose} />
  }

  if (step === steps.NOT_APPROVED) {
    return <NotApprovedModal handleBack={handleBack} handleClose={handleClose} />
  }

  const headerNode = (
    <NavigationBar
      leftPartText="Back"
      onLeftPartClick={step !== steps.INITIAL && handleBack}
      rightPartText="Cancel"
      onRightPartClick={handleClose}
      plain
      linkStyle="onColorWhiteToRed"
    />
  )

  const buttonNode = (
    <Button onClick={handleContinue} mods={{ size: 'big block', color: 'green' }} data-test-id="continueButton">
      Continue
    </Button>
  )

  const firstStepNode = (
    <Paper top={desktop ? 48 : 0}>
      <AllCenter>
        <img src={sippImage} width={desktop ? 171 : 156} height={desktop ? 171 : 156} />
      </AllCenter>
      <Width size={27} center>
        <Paper top={desktop ? 32 : 24}>
          <Typography
            size={36}
            lineHeight="small"
            weight="semibold"
            align="center"
            color="white"
            data-test-id="openSippModalTitle"
          >
            <Typo>InvestEngine Personal Pension</Typo>
          </Typography>
        </Paper>
        <Paper top={desktop ? 32 : 24}>
          <Typography size={desktop ? 16 : 14} color="white">
            <List mods={{ type: 'bullet', 'no-margin': 'top' }}>
              {LIST_ITEMS[isZeroFeesEnabled ? 'zeroFees' : 'default'].map((item, index) => {
                const text = <Typo>{item}</Typo>

                return <ListItem key={index}>{index === 0 ? text : <Paper top={20}>{text}</Paper>}</ListItem>
              })}
            </List>
          </Typography>
        </Paper>
      </Width>
      {desktop && (
        <Paper top={32} bottom={80}>
          <Width size={21} center>
            {buttonNode}
          </Width>
        </Paper>
      )}
    </Paper>
  )

  const secondStepNode = (
    <Paper top={desktop ? 48 : 0}>
      <Width size={27} center>
        <Typography
          size={36}
          lineHeight="small"
          weight="semibold"
          align="center"
          color="white"
          data-test-id="openSippModalTitle"
        >
          <Typo>What you need to remember</Typo>
        </Typography>
        <Paper top={desktop ? 32 : 24}>
          <Typography size={desktop ? 16 : 14} color="white">
            <List mods={{ type: 'bullet', 'no-margin': 'top' }}>
              <ListItem>
                <Typo>
                  Self Invested Personal Pensions are designed for people comfortable choosing their own investments.
                </Typo>
              </ListItem>
              <ListItem>
                <Paper top={20}>
                  <Typo>
                    If you’re not sure which investments are right for you, please speak to a financial advisor. There’s
                    no personal advice on our app or website.
                  </Typo>
                </Paper>
              </ListItem>
              <ListItem>
                <Paper top={20}>
                  <Typo>
                    This pension is designed for individuals who are in the accumulation phase of building their
                    retirement fund. If you're seeking to access or draw income from your pension while it's held at
                    InvestEngine, please note that our app and interfaces may not be the most suitable for your needs.
                  </Typo>
                </Paper>
              </ListItem>
              <ListItem>
                <Paper top={20}>
                  <Typo>
                    You usually need to be at least 55 before you can access the money in your pension (rising to 57
                    from 2028). Pension and tax rules can change, and benefits will depend on your circumstances.
                  </Typo>
                </Paper>
              </ListItem>
            </List>
          </Typography>
        </Paper>
      </Width>
      {desktop && (
        <Paper top={56} bottom={80}>
          <Width size={21} center>
            {buttonNode}
          </Width>
        </Paper>
      )}
    </Paper>
  )

  const content = step === steps.INITIAL ? firstStepNode : secondStepNode

  if (desktop)
    return (
      <Modal
        background="primary"
        zIndex={1002}
        open={isOpen || isTransfer}
        close={null}
        onClose={handleClose}
        data-test-id="openSippModal"
      >
        <Width size={36}>
          <Paper top={20} bottom={20} left={24} right={24}>
            {headerNode}
          </Paper>
          {content}
        </Width>
      </Modal>
    )

  return (
    <Modal background="primary" zIndex={1002} open={isOpen || isTransfer} onClose={handleClose}>
      <MobileLayout
        header={headerNode}
        content={content}
        footer={<MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
})

export { OpenSippModal }
