import { createStore, createEffect, combine } from 'effector'

import { makeApiCall } from 'app/effector/api'

import * as api from './api'
import { Stats } from './models'

// Effects
const fetchStatsFx = createEffect(async () => await makeApiCall(api.fetchStats))

// Stores
const $stats = createStore<Stats>(new Stats({}))
$stats.on(fetchStatsFx.doneData, (state, stats) => (stats ? new Stats(stats) : state))

const $initialFetchCompleted = createStore<boolean>(false)
$initialFetchCompleted.on(fetchStatsFx.done, () => true)

const $statsStore = combine({
  stats: $stats,
  initialFetchCompleted: $initialFetchCompleted,
})

export { $statsStore, fetchStatsFx }
