import React from 'react'

import classNames from 'classnames/dedupe.js'
import { useUnit } from 'effector-react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { $combinedContactsAndControls } from 'app/effector/significant-controls'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import SelectableCard from 'components/_old/SelectableCard/SelectableCard.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { PersonCard } from './PersonCard.jsx'
import SignificantControlInlineHelp from './SignificantControlInlineHelp.jsx'

import './ListOfDirectorsAndShareholders.css'

const DirectorsAndShareholdersList = ({
  wrapSubmitButton,
  onSubmit: handleSubmit,
  onAddDirector: handleAddDirector,
  onAddSignificantControl: handleAddSignificantControl,
  onDeleteContact: handleDeleteContact,
  onDeleteSignificantControls: handleDeleteSignificantControls,
}) => {
  const { desktop } = useMediaQueries()

  const contactsAndSignificantControls = useUnit($combinedContactsAndControls)

  const submitDisabled = contactsAndSignificantControls.some((data) => data.isUploadDocumentsVisible)

  const classes = classNames('ListOfDirectorsAndShareholders', { ListOfDirectorsAndShareholders_phone: !desktop })

  const submitButton = (
    <Button
      type="submit"
      mods={{ size: 'big block' }}
      disabled={submitDisabled}
      onClick={handleSubmit}
      tabIndex={9}
      data-test-id="listOfDirectorsAndShareholdersSubmitButton"
    >
      Continue
    </Button>
  )

  return (
    <div className={classes}>
      <AllCenter>
        <Headline className="ListOfDirectorsAndShareholders-SubTitle" level={4}>
          <Typo>Please add your company's directors and persons or entities with significant control below</Typo>
        </Headline>
        <Width size={desktop ? 33 : null}>
          {contactsAndSignificantControls.map((data, index) => (
            <PersonCard
              data={data}
              key={`${data.id}-${index}`}
              onContactDelete={handleDeleteContact}
              onSignificantControlDelete={handleDeleteSignificantControls}
            />
          ))}
          <SelectableCard
            className="ListOfDirectorsAndShareholders-AddButton"
            onClick={handleAddDirector}
            data-test-id="listOfDirectorsAndShareholdersAddDirectorButton"
          >
            <div>
              <Typo>+ Add a director</Typo>
            </div>
          </SelectableCard>
          <SelectableCard
            className="ListOfDirectorsAndShareholders-AddButton"
            onClick={handleAddSignificantControl}
            data-test-id="listOfDirectorsAndShareholdersAddSignificantControlButton"
          >
            <div>
              <Typo>+ Add a person or entity with significant control</Typo>
            </div>
            <SignificantControlInlineHelp />
          </SelectableCard>
          {wrapSubmitButton(submitButton)}
        </Width>
      </AllCenter>
    </div>
  )
}

DirectorsAndShareholdersList.propTypes = {
  wrapSubmitButton: PropTypes.func,
  onSubmit: PropTypes.func,
  onAddDirector: PropTypes.func,
  onAddSignificantControl: PropTypes.func,
  onDeleteContact: PropTypes.func,
  onDeleteSignificantControls: PropTypes.func,
}

export { DirectorsAndShareholdersList }
