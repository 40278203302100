import React from 'react'

import { useMemo } from 'hooks'

import { ReportRequestTimePeriod } from 'app/effector/reports/models'

import Button from 'components/_old/Button/Button.jsx'

import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

import { FilterElement } from 'components/organisms/Filter/FilterElement'

import { type CreateReportFormPartProps, CREATE_REPORT_FORM_BUTTON_TITLES } from '../CreateReportForm'

const TIME_PERIOD_NAMES = {
  [ReportRequestTimePeriod.LAST_MONTH]: 'Last month',
  [ReportRequestTimePeriod.LAST_12_MONTHS]: 'Last 12 months',
  [ReportRequestTimePeriod.CURRENT_TAX_YEAR]: 'Current tax year',
  [ReportRequestTimePeriod.PREVIOUS_TAX_YEAR]: 'Previous tax year',
  [ReportRequestTimePeriod.CUSTOM]: 'Select time period...',
} as const

function SelectTimePeriod({
  className,
  contentClassName,
  buttonClassName,
  visible,
  buttonTitle: buttonTitleProp,
  reportRequest,
  desktop,
  selectedTimePeriod,
  setSelectedTimePeriod,
  handleSelectTimePeriod,
}: CreateReportFormPartProps): React.ReactElement {
  const buttonTitle: string = useMemo(() => {
    const nextStep = reportRequest.getNextStep()
    const nextNextStep = reportRequest.goNextStep().getNextStep()

    if (!selectedTimePeriod) {
      return buttonTitleProp
    }

    if (!nextStep && selectedTimePeriod !== ReportRequestTimePeriod.CUSTOM) {
      return CREATE_REPORT_FORM_BUTTON_TITLES.GENERATE
    }

    if (!nextNextStep && selectedTimePeriod !== ReportRequestTimePeriod.CUSTOM) {
      return CREATE_REPORT_FORM_BUTTON_TITLES.GENERATE
    }

    return buttonTitleProp
  }, [reportRequest, selectedTimePeriod, buttonTitleProp])

  return (
    <div className={className} hidden={!visible}>
      <SideSpacesCompensator className={contentClassName}>
        {Object.values(ReportRequestTimePeriod).map((timePeriod) => (
          <FilterElement
            key={timePeriod}
            name={TIME_PERIOD_NAMES[timePeriod]}
            value={timePeriod}
            type="radio"
            paperSizes={{
              desktop: {
                left: 16,
                right: 16,
              },
            }}
            checked={selectedTimePeriod === timePeriod}
            onChange={() => {
              setSelectedTimePeriod(timePeriod)
            }}
            withLine
          />
        ))}
      </SideSpacesCompensator>
      <Paper className={buttonClassName} top={desktop ? 24 : 16} bottom={desktop ? 56 : null}>
        <Button
          onClick={handleSelectTimePeriod}
          mods={{ size: desktop ? 'block' : 'big block' }}
          disabled={!selectedTimePeriod}
        >
          {buttonTitle}
        </Button>
      </Paper>
    </div>
  )
}

export { SelectTimePeriod }
