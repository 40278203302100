import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { PropTypes } from 'prop-types'

import {
  useMemo,
  useState,
  useEffect,
  useActions,
  useUnmount,
  useCallback,
  useSelector,
  useDebouncedCallback,
} from 'hooks'

import { round } from 'helpers/money'

import {
  fetchProjectionsIncome as fetchProjectionsActionCreator,
  changeField as changeGoalFieldActionCreator,
} from 'app/redux/actions/portfolios'

const editableFields = ['initial_deposit', 'one_off_payment', 'capital_balance', 'preset', 'monthly_deposit']
const isEqualGoals = (goal, nextGoal) =>
  !editableFields
    .map((field) => parseFloat(Number(goal[field]), 10) === parseFloat(Number(nextGoal[field]), 10))
    .filter((a) => !a).length

const useIncomeProjections = (goal, minLimit, editablePortfolioFields) => {
  const [changeField, fetchProjections] = useActions([changeGoalFieldActionCreator, fetchProjectionsActionCreator])

  const goals = useSelector((state) => state.portfolios)

  const [editableGoal, setEditableGoal] = useState({})
  const [isGoalChanged, setIsGoalChanged] = useState(false)
  const [projections, setProjections] = useState({})
  const [isPresetChanged, setIsPresetChanged] = useState(false)
  const isGoalActive = !!goal?.first_topup || parseInt(goal?.current_balance || '', 10) > 0
  const validInitialDeposit =
    (goal?.initial_deposit && parseFloat(goal?.initial_deposit) >= minLimit) || parseFloat(goal?.capital_balance) > 0
  const isLoading = validInitialDeposit && !goal?.projections_fetched
  const presets = useMemo(() => goals.presets[goal?.preset_type] || [], [goal?.preset_type, goals.presets])

  const updateProjections = useDebouncedCallback(
    ({ goalId, preset, initialDeposit, capitalBalance, oneOffPayment }) => {
      capitalBalance = parseFloat(capitalBalance || 0)
      oneOffPayment = parseFloat(oneOffPayment || 0)
      initialDeposit = parseFloat(initialDeposit || 0)
      const balance = (() => {
        const intermediateBalance = capitalBalance > 0 ? capitalBalance + oneOffPayment : initialDeposit

        if (intermediateBalance <= 0) {
          return 0.01
        }

        return intermediateBalance
      })()

      return fetchProjections(goalId, preset, round(balance))
    },
    500,
    [fetchProjections],
  )

  useEffect(() => {
    const editableGoal = { ...goal }

    if (editablePortfolioFields?.one_off_payment) {
      editableGoal.one_off_payment = editablePortfolioFields.one_off_payment
    }

    if (editablePortfolioFields?.preset) {
      editableGoal.preset = editablePortfolioFields.preset
    }

    setEditableGoal(editableGoal)
    // had to run exactly once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (editableGoal) {
      const isGoalUpdated = !isEqualGoals(goal, editableGoal)
      setIsGoalChanged(isGoalUpdated)
    }
  }, [goal, editableGoal])

  useEffect(() => {
    setProjections({ ...(goal?.projections || {}) })
  }, [goal?.projections])

  useEffect(() => {
    if (!isEmpty(editableGoal)) {
      setIsGoalChanged(!isEqualGoals(goal, editableGoal))
      if (editableGoal?.initial_deposit < minLimit) {
        changeField({ projections: null }, editableGoal?.id)
      }

      changeField({ projections_fetched: false }, editableGoal?.id)

      updateProjections({
        goalId: editableGoal?.id,
        preset: editableGoal?.preset,
        initialDeposit: editableGoal?.initial_deposit,
        oneOffPayment: editableGoal?.one_off_payment,
        capitalBalance: editableGoal?.capital_balance,
      })
    }
    // had to run exactly only when editableGoal changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableGoal])

  const handleResetChanges = useCallback(() => {
    setEditableGoal({ ...goal })
  }, [goal])

  const updateGoalChanges = useCallback(() => {
    editableFields.forEach((field) => {
      changeField({ [field]: editableGoal[field] })
    })
  }, [editableGoal, changeField])

  useUnmount(() => {
    handleResetChanges()
    if (goal?.initial_deposit >= minLimit) {
      updateProjections({
        goalId: goal.id,
        preset: goal.preset,
        initialDeposit: goal.initial_deposit,
        oneOffPayment: goal.one_off_payment,
        capitalBalance: goal.capital_balance,
      })
    }
  })

  const handleEditableGoalChange = useCallback(
    (field, value) => {
      const goalId = Number(editableGoal?.id)
      const selectedPreset = find(presets, { id: editableGoal?.preset })

      if (selectedPreset) {
        ;['preset_stocks', 'preset_bonds'].forEach((instrument) => {
          changeField({ [`goal_${instrument}`]: selectedPreset[instrument] }, goalId)
        })
      }
      if (editableFields.includes(field)) {
        if (goal && field === 'preset') {
          setIsPresetChanged(goal.preset !== value)
        }
        setEditableGoal((editableGoal) => ({
          ...editableGoal,
          [field]: value,
        }))
      }
    },
    [changeField, editableGoal?.id, editableGoal?.preset, goal, presets],
  )

  return {
    presets,
    isLoading,
    isGoalActive,
    editableGoal,
    projections,
    isPresetChanged,
    isGoalChanged,
    handleEditableGoalChange,
    handleEditableGoalReset: handleResetChanges,
    updateGoalChanges,
  }
}

useIncomeProjections.propTypes = {
  goal: PropTypes.object,
  minLimit: PropTypes.number,
}

export default useIncomeProjections
