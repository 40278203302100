import React from 'react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Form from 'components/_old/Form/Form.jsx'
import List, { ListItem } from 'components/_old/List/List.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Phantom } from 'components/atoms/Phantom'
import { Typography } from 'components/atoms/Typography'

import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

const taxYear = (() => {
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const pastYear = date.getFullYear() - 1

  const getNextYear = (year: number): string => `${year + 1}`.slice(2)

  // date from 6th of April
  if ((month === 4 && day >= 6) || month > 4) {
    const currentYear = pastYear + 1

    return `${currentYear}/${getNextYear(currentYear)}`
  }
  return `${pastYear}/${getNextYear(pastYear)}`
})()

type ISADeclarationFormProps = {
  value: boolean
  submitButton: React.ReactElement
  wrapButton: (children: React.ReactElement, isDisabled?: boolean) => React.ReactElement
  handleAgreedChange: (value: boolean) => void
  onSubmit: () => void
}

const ISADeclarationForm = ({
  value,
  submitButton,
  wrapButton,
  handleAgreedChange,
  onSubmit: handleSubmit,
}: ISADeclarationFormProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const checkbox = (
    <Typography align={desktop ? 'center' : 'left'}>
      <Paper top={desktop ? 32 : 8} bottom={desktop ? 32 : 16}>
        <CheckboxWithLabel
          label={
            <Typography>
              <Typo>
                I confirm that I have read and agree to the Stocks and Shares ISA declarations and authorisations
                outlined above
              </Typo>
            </Typography>
          }
          checked={value}
          onChange={handleAgreedChange}
          value={value}
          data-test-id="isaDeclarationCheckbox"
        />
      </Paper>
    </Typography>
  )

  submitButton = React.cloneElement(
    submitButton,
    {
      onClick: handleSubmit,
      disabled: !value,
      tabIndex: 4,
      mods: {
        ...submitButton.props.mods,
        size: desktop ? 'big' : 'big block',
      },
      style: desktop ? { marginBottom: '4em' } : null,
      'data-test-id': 'isaDeclarationSubmit',
    },
    'Continue',
  )

  const identStyle = { marginBottom: '2em' }

  return (
    <Form>
      <Paper top={12} bottom={32}>
        <Typography align="center" weight="semibold" lineHeight="small">
          <Typo>Please read through the following ISA Declaration and Authority statement.</Typo>
        </Typography>
      </Paper>
      <Typography size={14}>
        <List mods={{ type: 'bullet' }} style={identStyle}>
          <ListItem style={desktop ? identStyle : null}>
            <Typo>
              I apply to subscribe for a Stocks & Shares ISA for the tax year {taxYear} and each subsequent tax year
              until further notice
            </Typo>
          </ListItem>
          <ListItem style={desktop ? identStyle : null}>
            <Typo>
              All the information I have provided in this application is correct and accurate to the best of my
              knowledge
            </Typo>
          </ListItem>
          <ListItem style={desktop ? identStyle : null}>
            <Typo>I am 18 years of age or over</Typo>
          </ListItem>
          <ListItem style={desktop ? identStyle : null}>
            <Typo>
              I am resident in the United Kingdom (UK) for tax purposes or, if not resident, either perform duties
              which, as a result of Section 28 of Income Tax (Earnings and Pensions) Act 2003 (Crown employees serving
              overseas), are treated as being performed in the UK or I am married to, or in a civil partnership with, a
              person who performs such duties.
            </Typo>
          </ListItem>
          <ListItem style={desktop ? identStyle : null}>
            <Typo>
              I will inform InvestEngine (UK) Limited if I cease to be resident or to perform such duties or be married
              to, or in a civil partnership with, a person who performs such duties.
            </Typo>
          </ListItem>
          <ListItem style={desktop ? identStyle : null}>
            <Typo>
              All subscriptions made, and to be made, belong to me. I have not subscribed, and will not subscribe, to
              more than the overall ISA subscription limit total in the same tax year.
            </Typo>
          </ListItem>
          <ListItem style={desktop ? identStyle : null}>
            <Typo>
              I have been provided, have read, and have agreed to InvestEngine (UK) Limited’s Terms and Conditions,
              including the Terms Applying to ISA accounts.
            </Typo>
          </ListItem>
        </List>
      </Typography>
      <Typography style={identStyle}>
        <Typo>Authority:</Typo>
      </Typography>
      <Typography size={14}>
        <List mods={{ type: 'bullet' }} style={identStyle}>
          <ListItem style={desktop ? identStyle : null}>
            <Typo>
              I authorise InvestEngine (UK) Limited to hold my cash subscription/payment, ISA investments, interest,
              dividends and any other rights or proceeds in respect of those investments and any other cash, and
            </Typo>
          </ListItem>
          <ListItem style={desktop ? identStyle : null}>
            <Typo>
              I authorise InvestEngine (UK) Limited to make on my behalf any claims to relief from tax in respect of ISA
              investments.
            </Typo>
          </ListItem>
          <ListItem style={desktop ? identStyle : null}>
            <Typo>
              I authorise InvestEngine (UK) Limited to continue my InvestEngine Stocks and Shares ISA automatically for
              the following tax year unless I say not to.
            </Typo>
          </ListItem>
        </List>
      </Typography>
      <Paper top={8} bottom={24}>
        <Card level={0} color="background-border" borderWidth={2}>
          <Paper top={24} bottom={24} left={24} right={24}>
            <Typography size={16} weight="semibold">
              <Paper bottom={24}>
                <Typo>Please note</Typo>
              </Paper>
            </Typography>
            <Typography size={14}>
              <List mods={{ type: 'bullet' }}>
                <ListItem style={desktop ? identStyle : null}>
                  <Typo>
                    You are not using any of your tax‑free allowance until you add cash to invest in your ISA. So you
                    can continue without commitment!
                  </Typo>
                </ListItem>
                <ListItem>
                  <Typo>
                    InvestEngine also lets you have multiple ISA portfolios in the same tax year, subject to your
                    overall subscription limit.
                  </Typo>
                </ListItem>
              </List>
            </Typography>
          </Paper>
        </Card>
      </Paper>

      {desktop && checkbox}

      {!desktop && (
        <Phantom>
          {wrapButton(
            <div>
              {checkbox}
              {submitButton}
            </div>,
            false,
          )}
        </Phantom>
      )}

      {desktop ? (
        <AllCenter>{wrapButton(submitButton)}</AllCenter>
      ) : (
        wrapButton(
          <div>
            {checkbox}
            {submitButton}
          </div>,
        )
      )}
    </Form>
  )
}

export { ISADeclarationForm }
