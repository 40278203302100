import { goTo, urlTo } from 'helpers/router.js'

const handle403 = ({ error }): void => {
  const isOnLoginPage = window?.location.toString().includes('/login/')
  const is2faNeeded = error?.response?.status === 403 && error?.response?.data?.code === '2fa_needed'
  const nextUrl = window.location.pathname + window.location.search

  if (is2faNeeded && !isOnLoginPage) goTo(urlTo('2fa', null, { next: nextUrl }))
}

export { handle403 }
