/* eslint-disable no-console */
import { useRect, inView, smoothScroll } from '@reactour/utils'

import { useState, useMemo, useEffect, useCallback, useMediaQueries } from 'hooks'

import { disableScroll, enableScroll } from './helpers'
import { type Hint } from './types'

type useHintsParams = {
  hints: Hint[]
  handleHintsDisplayed: () => Promise<void>
}

type useHintsData = {
  isHintVisible: boolean
  closeHint: () => void
  sizes: { bottom: number; height: number; left: number; right: number; top: number; width: number }
  hintText: string
}

const useHints = ({ hints, handleHintsDisplayed }: useHintsParams): useHintsData => {
  const { desktop } = useMediaQueries()
  const [activeHintIndex, setActiveHintIndex] = useState(0)
  const [isHintVisible, setIsHintVisible] = useState(false)

  const activeHint = useMemo(() => hints[activeHintIndex], [hints, activeHintIndex])

  const sizes = useRect(activeHint.ref, activeHint.text + isHintVisible.toString())

  const sizesAsObject = useMemo(
    () => ({
      top: sizes.top,
      bottom: sizes.bottom,
      left: sizes.left,
      right: sizes.right,
      width: sizes.width,
      height: sizes.height,
    }),
    [sizes],
  )

  const closeHint = useCallback(() => {
    if (activeHintIndex + 1 === hints.length) {
      setIsHintVisible(false)
      enableScroll(desktop)
    } else setActiveHintIndex(activeHintIndex + 1)
  }, [hints, activeHintIndex, setIsHintVisible, setActiveHintIndex, desktop])

  // skip hint if there is no ref
  useEffect(() => {
    if (!hints[activeHintIndex]?.ref?.current) {
      console.error(`@hints | No element found to display hint: ${hints[activeHintIndex]?.text}`)
      closeHint()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHintIndex])

  // scroll highlighted element into view if needed
  useEffect(() => {
    const isHightlightedElementInViewport = inView({ ...sizesAsObject, threshold: { y: 40 } })

    if (isHightlightedElementInViewport || !isHintVisible) return
    setIsHintVisible(false)
    smoothScroll(activeHint.ref?.current ?? null, { block: 'center' }).then(() => {
      setIsHintVisible(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizesAsObject, isHintVisible])

  useEffect(() => {
    setTimeout(() => {
      setIsHintVisible(true)
      handleHintsDisplayed()
      disableScroll(desktop)
    }, 500)

    return () => {
      enableScroll(desktop)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isHintVisible, closeHint, sizes: sizesAsObject, hintText: activeHint.text }
}

export { useHints }
