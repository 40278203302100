import React from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries, useMemo, useCallback } from 'hooks'

import { goTo } from 'helpers/router'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Typography } from 'components/atoms/Typography'

import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import { type Action, ActionKey } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'

import { OptionsLayout } from '../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../Options/OptionsNavigationBar'

import { CashBreakdownActions } from './components/CashBreakdownActions'
import { CashBreakdownAmounts } from './components/CashBreakdownAmounts'

import './CashBreakdown.css'

type CashBreakdownProps = {
  portfolio: Portfolio
  portfolioActions: Action[]
  handleClose: () => void
  location: {
    query?: {
      back?: string
    }
  }
}

const CashBreakdown = withRouter(
  ({
    portfolio,
    portfolioActions,
    handleClose,
    location: { query: { back: backLink } = {} },
  }: CashBreakdownProps): React.ReactElement => {
    const { desktop } = useMediaQueries()
    const availableActionsKeys = [
      ActionKey.AUTOINVEST,
      ActionKey.WITHDRAW,
      ActionKey.SAVINGS_PLAN,
      ActionKey.MOVE_CASH_IN,
      ActionKey.MOVE_CASH_OUT,
    ]
    const availableActions = portfolioActions.filter((action) => availableActionsKeys.includes(action.key))
    const currentLocation = useMemo(() => window.location.pathname + window.location.search, [])

    const handleBack = useCallback(() => {
      if (backLink) goTo(backLink)
    }, [backLink])

    const addFunds = useCallback(
      () => portfolioActions.find((action) => action.key === ActionKey.ADD_FUNDS)?.onClick?.(currentLocation),
      [currentLocation, portfolioActions],
    )

    const content = useMemo(() => {
      const content = (
        <SideSpacesCompensator>
          <Typography size={desktop ? 48 : 32} lineHeight="small" weight="semibold">
            <PoundsWithPence amount={portfolio.total_cash} showZeroPence data-test-id="CashBreakdownTotalValue" />
          </Typography>
          <Paper top={4} bottom={32}>
            <Typography size={16} color="additional">
              <Typo>Total</Typo>
            </Typography>
          </Paper>

          <Paper top={16}>
            <CashBreakdownAmounts portfolio={portfolio} />
          </Paper>
          <Paper top={48} bottom={48}>
            <Button onClick={addFunds} mods={{ size: 'big block' }} data-test-id="CashBreakdownAddCashButton">
              Add cash
            </Button>
          </Paper>
          <Card level={3} style={{ overflow: 'hidden' }}>
            <CashBreakdownActions actions={availableActions} />
          </Card>
        </SideSpacesCompensator>
      )

      if (desktop) {
        return (
          <Width size={25} center>
            {content}
          </Width>
        )
      }
      return (
        <Paper left={16} right={16}>
          {content}
        </Paper>
      )
    }, [desktop, availableActions, portfolio, addFunds])

    return (
      <OptionsLayout
        header={
          <OptionsNavigationBar
            leftPartText={backLink ? 'Back' : undefined}
            onLeftPartClick={backLink ? handleBack : undefined}
            rightPartText="Close"
            onRightPartClick={handleClose}
          >
            <Typo>Portfolio cash</Typo>
          </OptionsNavigationBar>
        }
        content={content}
        button={null}
        data-test-id="cashBreakdownModal"
      />
    )
  },
)

export { CashBreakdown }
