import React from 'react'

import { useMediaQueries } from 'hooks'

import { Stack } from 'components/atoms/Stack'

import { SettingsLayout } from '../components/SettingsLayout'

import { InvestEngineNews } from './InvestEngineNews'
import { ThirdPartyTradeReporting } from './ThirdPartyTradeReporting'

const Notifications = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <SettingsLayout title="Notifications">
      <Stack vertical={desktop ? 48 : 32}>
        <InvestEngineNews />
        <ThirdPartyTradeReporting />
      </Stack>
    </SettingsLayout>
  )
}

export { Notifications }
