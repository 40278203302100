const defaultFont =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif"

const font = {
  family: {
    default: defaultFont,
    poppins: `Poppins, ${defaultFont}`,
  },
}

module.exports = font
