import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useEffect, useState, useMemo, useActions, useRef } from 'hooks'

import { unformat, format as formatMoney } from 'helpers/money'

import { $allowancesStore, fetchAllowancesFx } from 'app/effector/allowances'
import { createPendingOrderFx, $isLoading } from 'app/effector/pending-orders'

import { receivePortfolio as receivePortfolioActionCreator } from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'
import { PortfolioList } from 'app/redux/models/portfolio/'

import { useForm } from 'components/atoms/Forms/hooks'

import { type as pendingOrderType } from 'constants/pendingOrder.js'
import { manageTypes, regulatoryTypes } from 'constants/portfolio'

const buyOrderFx = attach({ effect: createPendingOrderFx })

buyOrderFx.fail.watch(() => {
  showFailToast()
})

const useBuy = ({ portfolioId, portfolio, onAfterBuy, security, cashPortfolios }) => {
  const isLoading = useUnit($isLoading)

  const {
    control,
    errors,
    isValid,
    handleSubmit: handleFormSubmit,
  } = useForm({
    amount: undefined,
  })

  const { allowances, areAllowancesFetched } = useUnit($allowancesStore)

  const [chosenPortfolioId, setChosenPortfolioId] = useState(portfolioId)
  const [receivePortfolio] = useActions([receivePortfolioActionCreator])

  const isIsa = portfolio?.regulatory_type === regulatoryTypes.ISA
  const isSipp = portfolio?.regulatory_type === regulatoryTypes.SIPP

  const chosenPortfolio = useMemo(
    () => cashPortfolios.find((portfolio) => portfolio?.id === chosenPortfolioId),
    [cashPortfolios, chosenPortfolioId],
  )

  const chosenPortfolioRef = useRef(chosenPortfolio)

  // Update ref when chosenPortfolio changes to be able to access in the done subscription
  useEffect(() => {
    chosenPortfolioRef.current = chosenPortfolio
  }, [chosenPortfolio])

  useEffect(() => {
    const doneSubscription = buyOrderFx.done.watch(({ result: createdOrder }) => {
      const currentChosenPortfolio = chosenPortfolioRef.current

      if (createdOrder && currentChosenPortfolio?.id !== portfolio?.id) {
        receivePortfolio({
          ...currentChosenPortfolio,
          current_balance: parseFloat(currentChosenPortfolio.current_balance) - parseFloat(createdOrder.amount),
        })
      }

      if (createdOrder) {
        onAfterBuy(createdOrder.id)
      }
    })

    return () => {
      doneSubscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isIsaLimitWarningDisplayed = isIsa && chosenPortfolio?.regulatory_type === regulatoryTypes.GIA
  const remainingAllowance = parseFloat(allowances?.isa?.remaining_allowance ?? 'Infinity')

  useEffect(() => {
    if (isIsa && !areAllowancesFetched) {
      fetchAllowancesFx()
    }
  }, [isIsa, areAllowancesFetched])

  const currentCashBalance = useMemo(
    () =>
      chosenPortfolioId === portfolioId
        ? chosenPortfolio?.available_to_invest_cash
        : chosenPortfolio?.available_to_move_cash,
    [chosenPortfolio, chosenPortfolioId, portfolioId],
  )

  const [isCashSelectModalOpen, setIsCashSelectModalOpen] = useState(false)
  const openCashSelectModal = () => {
    setIsCashSelectModalOpen(true)
  }
  const closeCashSelectModal = () => {
    setIsCashSelectModalOpen(false)
  }

  const availablePortfoliosToBuyFrom = [...cashPortfolios].filter((cashPortfolio) => {
    if (isSipp) {
      return cashPortfolio.regulatory_type === regulatoryTypes.SIPP
    }
    if (isIsa) {
      return cashPortfolio.regulatory_type !== regulatoryTypes.SIPP
    }
    return cashPortfolio.regulatory_type === regulatoryTypes.GIA
  })

  const groupedPortfolios = new PortfolioList(...availablePortfoliosToBuyFrom).groupByRegulatoryType()

  const validation = {
    required: {
      value: true,
      message: 'Amount can’t be empty',
    },
    validate: (value) => {
      const unformatValue = unformat(value, true)

      if (unformatValue > currentCashBalance) {
        return `Not enough in your ${chosenPortfolio.manage_type === manageTypes.CASH ? 'cash account' : 'portfolio'}`
      }

      if (isIsaLimitWarningDisplayed && unformatValue > remainingAllowance) {
        return `Amount cannot exceed remaining ISA allowance: ${formatMoney(remainingAllowance)}`
      }

      if (unformatValue < 1) {
        return `Must be at least ${formatMoney(1)}`
      }

      return true
    },
  }

  const buy = handleFormSubmit((values) => {
    buyOrderFx({
      type: pendingOrderType.BUY,
      portfolio_id: portfolio?.id,
      security_id: security?.id,
      cash_from: chosenPortfolio.id,
      amount: values.amount ? unformat(values.amount, true) : undefined,
    })
  })

  return {
    currentCashBalance,
    isLoading,

    isValid,
    validation,
    control,
    errors,

    groupedPortfolios,
    isCashSelectModalOpen,
    openCashSelectModal,
    closeCashSelectModal,
    chosenPortfolio,
    setChosenPortfolioId,
    isIsaLimitWarningDisplayed,

    buy,
  }
}

export { useBuy }
