import { nullable, type Nullable, enumValue, string } from 'app/effector/model'

import { type TransferProgressStepDTO } from '../../api'

enum TransferProgressStepStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
  TO_DO = 'TO_DO',
}

class TransferProgressStep {
  readonly state: TransferProgressStepStatus
  readonly error: Nullable<string>
  readonly short_name: Nullable<string>
  readonly name: Nullable<string>
  readonly description: Nullable<string>

  constructor(dto: Partial<TransferProgressStepDTO> | Partial<TransferProgressStep>) {
    this.state = nullable(enumValue)(dto.state, TransferProgressStepStatus)
    this.error = nullable(string)(dto.error)
    this.short_name = nullable(string)(dto.short_name)
    this.name = nullable(string)(dto.name)
    this.description = nullable(string)(dto.description)
  }

  isCompleted(): boolean {
    return this.state === TransferProgressStepStatus.DONE
  }
}

export { TransferProgressStep, TransferProgressStepStatus }
