import React from 'react'
import classNames from 'classnames/dedupe'

import { modsToClassnames } from 'helpers/classname.js'
import { sanitizeString } from 'helpers/typograph'

import './ErrorMessage.css'

export default function ErrorMessage(props) {
  let { children, className, mods, style, ...rest } = props

  if (/<.+>/.test(children)) {
    children = <span dangerouslySetInnerHTML={{ __html: sanitizeString(children) }} />
  }

  const classes = classNames(className, 'ErrorMessage', modsToClassnames('ErrorMessage', mods))

  return (
    <div className={classes} style={style} {...rest}>
      {children}
    </div>
  )
}
