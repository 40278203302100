import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries } from 'hooks'

import { format as formatMoney } from 'helpers/money'
import { palette } from 'helpers/palette/'

import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { Hr } from 'components/atoms/Hr'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Modal } from 'components/molecules/Modal'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import { useSippUnnualLimitModal } from './hooks/useSippAnnualLimitModal'

type SippAnnualLimitModalProps = {
  location: {
    query: {
      sippAnnualLimitModalOpened: string
    }
  }
  isOpened: boolean
}

const SippAnnualLimitModal = withRouter(({ location }: SippAnnualLimitModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { sippAnnualLimitModalOpened } = location.query
  const isOpened = sippAnnualLimitModalOpened === 'true'

  const { remainingAllowance, totalAllowance, topupsContribution, rebatesContribution, handleClose } =
    useSippUnnualLimitModal()

  const headerNode = (
    <NavigationBar rightPartText="Close" onRightPartClick={handleClose}>
      <Typo>&nbsp</Typo>
    </NavigationBar>
  )

  const limitDescriptionNode = (
    <Paper>
      <Typography size={desktop ? 16 : 24} lineHeight="small" weight="semibold">
        <Typo>Personal Pension annual allowance</Typo>
      </Typography>
      <Paper top={16} data-test-id="subModalSippLimit">
        <Typo>
          The pension annual allowance is currently {formatMoney(totalAllowance ?? 0)} or 100 percent of your qualifying
          earnings (whichever is lower).
          <br />
          <br />
          The annual allowance applies to all of your private pensions combined, if you have more than one.
          <br />
          <br />
          You can{' '}
          <Link hard blank to="https://www.gov.uk/tax-on-your-private-pension/annual-allowance">
            read more about your annual allowance at GOV.UK
          </Link>
        </Typo>
      </Paper>
    </Paper>
  )

  const content = (
    <Fragment>
      <Width size={21} center>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
          <Typo>Personal Pension annual allowance</Typo>
        </Typography>
      </Width>
      <Paper top={32}>
        <Card level={0} color="primary-surface-25">
          <Paper top={24} bottom={24} right={16} left={16}>
            <ColumnarLayout mods={{ padding: 'no' }}>
              <Column size={1}>
                <Typography lineHeight="small">
                  <Typo>Personal Pension annual allowance</Typo>
                  <TooltipToModal
                    className="CashBreakdown-Tooltip"
                    description={limitDescriptionNode}
                    offset={-174}
                    zIndex={1001}
                    customIcon
                  >
                    <Paper left={4} inline data-test-id="sippLimitModalTooltipIcon">
                      <Icon
                        type="information-16"
                        color={palette['content-on-background-additional']}
                        size={16}
                        inline
                      />
                    </Paper>
                  </TooltipToModal>
                </Typography>
              </Column>
              <Column size={0}>
                <Paper left={12}>
                  <Typography size={18} lineHeight="small" weight="semibold" align="right">
                    <PoundsWithPence amount={totalAllowance} showZeroPence />
                  </Typography>
                </Paper>
              </Column>
            </ColumnarLayout>
            <Paper top={12}>
              <ColumnarLayout mods={{ padding: 'no' }}>
                <Column size={1}>
                  <Typography lineHeight="small">
                    <Typo>IE Pension contributions</Typo>
                  </Typography>
                </Column>
                <Column size={0}>
                  <Paper left={12}>
                    <Typography size={18} lineHeight="small" weight="semibold" align="right">
                      <PoundsWithPence amount={topupsContribution} showZeroPence />
                    </Typography>
                  </Paper>
                </Column>
              </ColumnarLayout>
            </Paper>
            <Paper top={12}>
              <ColumnarLayout mods={{ padding: 'no' }}>
                <Column size={1}>
                  <Typography lineHeight="small">
                    <Typo>Basic rate tax relief from HMRC</Typo>
                  </Typography>
                </Column>
                <Column size={0}>
                  <Paper left={12}>
                    <Typography size={18} lineHeight="small" weight="semibold" align="right">
                      <PoundsWithPence amount={rebatesContribution} showZeroPence />
                    </Typography>
                  </Paper>
                </Column>
              </ColumnarLayout>
            </Paper>
            <Paper top={16} bottom={16}>
              <Hr color="primary-surface-25" />
            </Paper>
            <ColumnarLayout mods={{ padding: 'no' }}>
              <Column size={1}>
                <Typography lineHeight="small">
                  <Typo>Available to top up this year</Typo>
                </Typography>
              </Column>
              <Column size={0}>
                <Paper left={12}>
                  <Typography size={18} lineHeight="small" weight="semibold" align="right">
                    <PoundsWithPence amount={remainingAllowance} showZeroPence />
                  </Typography>
                </Paper>
              </Column>
            </ColumnarLayout>
          </Paper>
        </Card>
      </Paper>
      <Paper top={32}>
        <Typography>
          <Typo>
            These only consider SIPP balance held within InvestEngine and may vary with your personal circumstances.
            Please consult{' '}
            <Link hard blank to="https://www.gov.uk/tax-on-your-private-pension/" data-test-id="sippLimitModalGovLink">
              www.gov.uk/tax-on-your-private-pension
            </Link>{' '}
            for further guidance and contact a pension advisor where required
          </Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  if (desktop) {
    return (
      <Modal open={isOpened} onClose={handleClose} close={null}>
        <Width size={36} style={{ minHeight: '600px' }}>
          <Paper top={8} bottom={8} right={24} left={24}>
            {headerNode}
          </Paper>
          <Paper top={8} data-test-id="sippAnnualLimitModal">
            <Width size={26} center>
              {content}
            </Width>
          </Paper>
        </Width>
      </Modal>
    )
  }

  return (
    <Modal open={isOpened} onClose={handleClose} close={null}>
      <MobileLayout header={headerNode} content={content} />
    </Modal>
  )
})

export { SippAnnualLimitModal }
