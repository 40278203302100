import React from 'react'
import { withRouter } from 'react-router'

import { useMemo } from 'hooks'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { Paper } from 'components/atoms/Paper'

import { SavingsPlanPromo } from 'components/organisms/SavingsPlanPromo'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

import { useRecurringPaymentStart } from './hooks/useRecurringPaymentStart'

type RecurringPaymentProps = {
  portfolio: Portfolio
  location: {
    query?: {
      back?: string
    }
  }
  handleClose: () => void
}

const RecurringPaymentStart = withRouter(
  ({ portfolio, location, handleClose }: RecurringPaymentProps): React.ReactElement | null => {
    const { isQuickStartInProgress, handleBack, handleContinue } = useRecurringPaymentStart({ portfolio, location })

    const content = useMemo(() => {
      return (
        <Paper top={8}>
          <SavingsPlanPromo
            isRecurringPayment
            title="Set up Savings Plan"
            isQuickStartInProgress={isQuickStartInProgress}
            onContinue={handleContinue}
          />
        </Paper>
      )
    }, [handleContinue, isQuickStartInProgress])

    return (
      <OptionsLayout
        header={
          <OptionsNavigationBar
            leftPartText="Back"
            onLeftPartClick={handleBack}
            rightPartText="Close"
            onRightPartClick={handleClose}
            linkStyle="onColorWhiteToRed"
          >
            &nbsp;
          </OptionsNavigationBar>
        }
        content={content}
        data-test-id="recurringPaymentStartPopup"
      />
    )
  },
)

export { RecurringPaymentStart }
