import React from 'react'

import instantPayment from './AddFunds/routes.jsx'
import options from './Options/routes.tsx'

import { ISADeclaration } from './ISADeclaration/ISADeclaration.jsx'
import { Finish } from './Finish'

import { ManagedPortfolioRedirect } from './ManagedPortfolio/ManagedPortfolioRedirect'

import { Portfolio } from './Portfolio'
import { SecuritySlice } from './DIY/SecuritySlice'
import { SecuritySliceInfo } from './DIY/SecuritySlice/SecuritySliceInfo'
import { SecurityAnalytics } from 'app/pages/Dashboard/Analytics/SecurityAnalytics'
import { EditWeights } from './DIY/EditWeights'
import { Buy, BuySecurity, Sell, SellSecurity } from './DIY/BuyAndSell'
import { Review } from './DIY/Review'
import { Rebalance } from './DIY/Rebalance'
import { PortfolioOrders } from './DIY/PortfolioOrders'
import { SecurityOrders } from './DIY/SecurityOrders'
import { SharePortfolio } from './DIY/SharePortfolio'
import { SharedSecurity } from 'app/pages/SharedPortfolio/Security/SharedSecurity'
import { Analytics as SharedSecurityAnalytics } from 'app/pages/SharedPortfolio/Analytics'

import { autoinvestRoutes } from './DIY/Autoinvest/routes'
const commonRoutes = [
  {
    module: 'change-portfolio-name',
    path: 'change-portfolio-name/',
  },
  {
    module: 'cant-set-up-monthly-payment',
    path: 'cant-set-up-monthly-payment/',
  },
  {
    module: 'withdraw-funds',
    path: 'withdraw-funds/',
  },
  {
    module: 'transfer-isa',
    path: 'transfer-isa/',
  },
  {
    module: 'savings-plan-setup',
    path: 'savings-plan-setup/',
  },
  // If route path will change, tell backend to change on their side too, 'cuz it'll affect emails
  ...autoinvestRoutes,
  ...instantPayment,
  ...options,
]

const securitySliceRoutes = [
  {
    isNewLayout: true,
    noGlobalPreloader: true,
    path: '',
    component: function Component(props) {
      return (
        <SecuritySlice key="slice" {...props}>
          <SecuritySliceInfo />
        </SecuritySlice>
      )
    },
  },
  {
    path: 'analytics/',
    module: 'security-analytics',
    indexRoute: {
      path: 'holdings/',
      module: 'holdings',
      isNewLayout: true,
      component: function Component(props) {
        return (
          <SecuritySlice key="holdings" {...props}>
            <SecurityAnalytics type="holdings" />
          </SecuritySlice>
        )
      },
    },
    childRoutes: [
      {
        path: 'holdings/',
        module: 'holdings',
        isNewLayout: true,
        component: function Component(props) {
          return (
            <SecuritySlice key="holdings" {...props}>
              <SecurityAnalytics type="holdings" />
            </SecuritySlice>
          )
        },
      },
      {
        path: 'sectors/',
        module: 'sectors',
        isNewLayout: true,
        component: function Component(props) {
          return (
            <SecuritySlice key="sectors" {...props}>
              <SecurityAnalytics type="sectors" />
            </SecuritySlice>
          )
        },
      },
      {
        path: 'regions/',
        module: 'regions',
        isNewLayout: true,
        component: function Component(props) {
          return (
            <SecuritySlice key="regions" {...props}>
              <SecurityAnalytics type="regions" />
            </SecuritySlice>
          )
        },
      },
    ],
  },
]

const shareSecurityRoutes = [
  {
    isNewLayout: true,
    path: '',
    component: function Component(props) {
      return <SharedSecurity {...props} />
    },
  },
  {
    path: 'analytics/:tab/',
    module: 'shared-security-analytics',
    isNewLayout: true,
    component: function Component(props) {
      return <SharedSecurityAnalytics {...props} />
    },
  },
]

const sharePortfolioRoutes = [
  {
    index: true,
    isNewLayout: true,
    module: 'portfolio-share',
    component: function Component(props) {
      return <SharePortfolio {...props} />
    },
  },
  {
    path: 'security/:securityId/',
    module: 'shared-security',
    childRoutes: shareSecurityRoutes,
    indexRoute: shareSecurityRoutes[0],
  },
]

const portfolioRoutes = [
  {
    index: true,
    isNewLayout: true,
    noGlobalPreloader: true,
    component: function Component(props) {
      return <Portfolio {...props} />
    },
    childRoutes: commonRoutes,
  },
  {
    isNewLayout: true,
    module: 'isa-declaration',
    path: 'isa-declaration/',
    component: function Component(props) {
      return <ISADeclaration {...props} />
    },
  },
  {
    isNewLayout: true,
    path: 'finish/',
    module: 'finish',
    component: function Component(props) {
      return <Finish {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'edit-weights',
    path: 'edit/',
    component: function Component(props) {
      return <EditWeights {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'portfolio-orders',
    path: 'orders/',
    component: function Component(props) {
      return <PortfolioOrders {...props} />
    },
  },
  {
    module: 'security',
    path: 'security/:securityId/',
    childRoutes: securitySliceRoutes,
    indexRoute: securitySliceRoutes[0],
  },
  {
    isNewLayout: true,
    module: 'security-buy',
    path: 'security/:securityId/buy/',
    component: function Component(props) {
      return <BuySecurity {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'security-sell',
    path: 'security/:securityId/sell/',
    component: function Component(props) {
      return <SellSecurity {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'security-orders',
    path: 'security/:securityId/orders/',
    component: function Component(props) {
      return <SecurityOrders {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'buy',
    path: 'buy/',
    component: function Component(props) {
      return <Buy {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'sell',
    path: 'sell/',
    component: function Component(props) {
      return <Sell {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'review',
    path: 'review/:orderId/',
    component: function Component(props) {
      return <Review {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'rebalance',
    path: 'rebalance/',
    component: function Component(props) {
      return <Rebalance {...props} />
    },
  },
  {
    module: 'share',
    path: 'share/',
    childRoutes: sharePortfolioRoutes,
    indexRoute: sharePortfolioRoutes[0],
  },
]

const routes = [
  {
    module: 'portfolios',
    component: function Component(props) {
      return <ManagedPortfolioRedirect {...props} />
    },
    path: 'portfolio/',
  },
  {
    module: 'portfolio',
    path: 'portfolio/:id/',
    indexRoute: portfolioRoutes.find((route) => route.index),
    childRoutes: portfolioRoutes,
  },
  {
    isNewLayout: true,
    module: 'portfolio-old',
    component: function Component(props) {
      return <ManagedPortfolioRedirect {...props} />
    },
    path: 'portfolio/:type/:id/',
  },
]
export default routes
