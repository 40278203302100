import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { useMemo, useCallback } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import { Progress } from 'components/_old/Progress/'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import HeadlineHelp from 'app/containers/PortfolioTunnel/Tunnel/HeadlineHelp/HeadlineHelp.jsx'

import { MultipleChoiceCard } from '../components/MultipleChoiceCard'
import { useMultipleChoice } from '../hooks'

const MobileMultiple = ({
  progress,
  question,
  selectedAnswer,
  handleBack,
  handleSubmit,
  handleCancel,
}: MultipleViewProps): React.ReactElement => {
  const { title, description, answers } = question
  const { selectedAnswers, handleSelect } = useMultipleChoice(answers, selectedAnswer)

  const handleSubmitClick = useCallback(() => {
    handleSubmit(selectedAnswers)
  }, [selectedAnswers, handleSubmit])

  const heading = useMemo(
    () => (
      <Paper top={8} bottom={16}>
        <Typography size={20} weight="semibold" align="center">
          <Typo>
            {title}
            {description?.text && (
              <Typography className="Question_Headline_help" tag="span" size={14}>
                <HeadlineHelp
                  iconType="question"
                  background="status-warning"
                  title={description?.title}
                  body={description?.text}
                />
              </Typography>
            )}
          </Typo>
        </Typography>
      </Paper>
    ),
    [title, description],
  )

  const options = useMemo(
    () =>
      answers.map((answer, i) => (
        <Paper top={24} key={answer.id}>
          <MultipleChoiceCard
            tabIndex={i + 1}
            answer={answer}
            selected={selectedAnswers.includes(answer.id)}
            onClick={handleSelect}
          />
        </Paper>
      )),
    [answers, selectedAnswers, handleSelect],
  )

  const button = useMemo(
    () => (
      <MobileLayoutFooterButton>
        <Button
          type="submit"
          mods={{ size: 'big block' }}
          onClick={handleSubmitClick}
          disabled={!selectedAnswers.length}
          tabIndex={answers.length + 1}
          data-test-id="questionnaireSubmit"
        >
          Continue
        </Button>
      </MobileLayoutFooterButton>
    ),
    [answers.length, selectedAnswers.length, handleSubmitClick],
  )

  const content = (
    <Paper bottom={24}>
      {heading}
      <Paper bottom={8}>
        <Typography tag="span" size={14} lineHeight="small">
          <Text center block>
            <Typo>Tick all that apply</Typo>
          </Text>
        </Typography>
      </Paper>
      {options}
    </Paper>
  )

  return (
    <MobileLayout
      header={
        <Fragment>
          <NavigationBar
            leftPartText="Back"
            onLeftPartClick={handleBack}
            rightPartText="Cancel"
            onRightPartClick={handleCancel}
          >
            Get started
          </NavigationBar>
          <Progress fill={progress} gapWidth="1em" />
        </Fragment>
      }
      content={content}
      footer={button}
    />
  )
}

MobileMultiple.propTypes = {
  progress: PropTypes.number,
  question: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    }),
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      }),
    ),
  }),
  selectedAnswer: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  handleBack: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export { MobileMultiple }
