import { createEvent } from 'effector'

import { type SippTransferRequest } from 'app/effector/nominees-transfers/request/sipp'

// Types
type PageUrl = null | string

type SetFieldPayload<Field extends keyof SippTransferRequest | 'agreed'> = {
  field: Field
  value?: Field extends 'agreed' ? boolean : SippTransferRequest[Exclude<Field, 'agreed'>]
}

// Events
const setPrevPage = createEvent<PageUrl>()
const setNextPage = createEvent<PageUrl>()
const resetPage = createEvent()
const closePage = createEvent()

const initForm = createEvent<undefined | Partial<SippTransferRequest>>()
const resetForm = createEvent()
const setAgreed = createEvent<boolean>()
const setField = createEvent<SetFieldPayload<keyof SippTransferRequest | 'agreed'>>()
const submitForm = createEvent()

export {
  type PageUrl,
  type SetFieldPayload,
  setPrevPage,
  setNextPage,
  resetPage,
  closePage,
  initForm,
  resetForm,
  setAgreed,
  setField,
  submitForm,
}
