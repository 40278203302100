import * as React from 'react'

import classNames from 'classnames/dedupe'

import { useSelector } from 'hooks'

import { type Portfolio } from 'app/redux/models/portfolio/types'
import { selectGoalTitle } from 'app/redux/selectors'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PortfolioType } from '../../../components/PortfolioType.jsx'

import './PortfolioTitle.css'

type PortfolioTitleProps = {
  className?: string
  portfolio?: Portfolio
}

const PortfolioTitle = ({ className, portfolio }: PortfolioTitleProps): React.ReactElement => {
  const title = useSelector((state) => selectGoalTitle(state, portfolio?.id))

  return (
    <Paper className={classNames(className, 'PortfolioTitle')}>
      <Paper top={4} bottom={4} right={16}>
        <Typography size={14} lineHeight="small" data-test-id="portfolioTitle">
          <Typo>{title}</Typo>
        </Typography>
      </Paper>
      <PortfolioType inline portfolio={portfolio} />
    </Paper>
  )
}

export { PortfolioTitle }
