import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import Text from 'components/_old/Text/Text.jsx'

import './LegendMark.css'

const LegendMark = ({ className, color, text }) => (
  <div className={classNames(className, 'LegendMark')}>
    <div className="LegendMark-Color" style={{ background: color }} />
    {text && (
      <Text small className="LegendMark-Text">
        {text}
      </Text>
    )}
  </div>
)

LegendMark.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  text: PropTypes.node,
}

export { LegendMark }
