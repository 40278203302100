import React from 'react'

import { useSelector, useActions, useCallback, useEffect } from 'hooks'

import { sendError } from 'helpers/errorLogging.js'
import { goTo } from 'helpers/router.js'

import { logout as logoutActionCreator } from 'app/redux/actions/client'

import GlobalPreloader from 'components/_old/GlobalPreloader/GlobalPreloader.jsx'

import { GP_DEFAULT } from 'constants/globalPreloaderStatuses'

type LogoutProps = {
  location: {
    query: {
      next?: string
    }
  }
}

function Logout({ location: { query } }: LogoutProps): React.ReactElement {
  const accessToken = useSelector((state: { client: { access_token?: string } }) => state.client?.access_token)
  const [logout] = useActions([logoutActionCreator])

  const handleLogout = useCallback(async () => {
    try {
      if (accessToken) {
        await logout()
      }

      goTo(query?.next ?? '/', { hardGoTo: true })
    } catch (error) {
      sendError(error)
    }
  }, [accessToken, logout, query?.next])

  useEffect(
    () => {
      handleLogout()
    },
    // had to run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return <GlobalPreloader status={GP_DEFAULT} loading />
}

export { Logout }
