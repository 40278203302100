import React from 'react'
import PropTypes from 'prop-types'

import { trackEvent } from 'helpers/analytics'

import { useUnit } from 'effector-react'
import { useCallback, useMediaQueries, useMemo, useRedirect, useState } from 'hooks'

import Width from 'components/_old/Width/Width'
import Card from 'components/_old/Card/Card.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { PaymentDetailLogo } from 'components/atoms/PaymentDetail'
import { FileUpload } from 'components/organisms/FileUpload'
import { statuses as fileUploadStatuses } from 'components/organisms/FileUpload/constants.js'
import { Gateway } from 'components/atoms/Gateway'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $isLoading } from 'app/effector/contacts'

const UploadBankStatement = ({ prevStep, changePaymentField, handleAfterSubmit }) => {
  const { desktop } = useMediaQueries()

  const { nominatedAccount } = useUnit($bankAccountsStore)
  const isContactsLoading = useUnit($isLoading)

  const [documentIsLoaded, setDocumentIsLoaded] = useState(nominatedAccount?.bank_statement_filled)

  // if a client has got this page without filling the previous form
  useRedirect({
    to: prevStep?.module ?? 'setup-account',
    rule: !nominatedAccount?.sort_code || !nominatedAccount?.account_number,
    isLoading: isContactsLoading,
  })

  const handleAfterUpload = useCallback(
    (data) => {
      if (data.bank_statement_filled) {
        changePaymentField('bank_statement_filled', true)
        setDocumentIsLoaded(true)

        trackEvent(
          {
            action: 'nominated_bank_statement_uploaded',
            client_type: 'INDIVIDUAL',
          },
          { plugins: { 'google-analytics-v3': false } },
        )
      }
    },
    [changePaymentField, setDocumentIsLoaded],
  )

  const bankAccountDataNode = useMemo(
    () =>
      nominatedAccount ? (
        <AllCenter>
          {nominatedAccount?.bank?.logo_uri ? (
            <Paper top={16} data-test-id="bankLogo">
              <PaymentDetailLogo src={nominatedAccount?.bank?.logo_uri} width="36" />
            </Paper>
          ) : null}
          <Paper top={16}>
            <Typography size={14} weight="semibold" align="center" data-test-id="bankTitle">
              <Typo>
                {nominatedAccount?.bank?.title !== 'Other'
                  ? nominatedAccount?.bank?.title
                  : `Sort code: ${nominatedAccount?.sort_code}`}
              </Typo>
            </Typography>
            <Typography size={14} weight="semibold" align="center" data-test-id="accountNumber">
              <Typo>Account number: {nominatedAccount?.account_number}</Typo>
            </Typography>
          </Paper>
        </AllCenter>
      ) : null,
    [nominatedAccount],
  )

  return (
    <Width size={desktop ? 26 : null}>
      <Gateway into="setupAccountHeadline">
        <div data-test-id="verifyBankAccountTitle">
          We need help verifying your
          <br />
          bank account details
        </div>
      </Gateway>
      <Paper top={24} bottom={desktop ? 32 : 64}>
        <Card
          mods={{
            theme: desktop ? 'transparent' : null,
            'no-padding': 'all',
          }}
        >
          <FileUpload
            status={nominatedAccount?.bank_statement_filled ? fileUploadStatuses.DONE : fileUploadStatuses.DEFAULT}
            url={`bank-accounts/${nominatedAccount?.id}/files/`}
            icon="bank_statement"
            dataType="bank_statement"
            title="Upload bank statement"
            text="Bank documents must show your name, current address, account number + sort code and be from within the last 3 months.
            We can accept downloaded PDF copies, screenshots or photographs of paper statements."
            onAfterUpload={handleAfterUpload}
            data-test-id="bankAccountFileUpload"
          >
            {bankAccountDataNode}
          </FileUpload>
        </Card>
      </Paper>
      <Paper bottom={desktop ? 56 : null}>
        <Button
          mods={{ size: 'big block' }}
          disabled={!documentIsLoaded}
          onClick={handleAfterSubmit}
          data-test-id="bankStatementSubmit"
        >
          Continue
        </Button>
      </Paper>
    </Width>
  )
}

UploadBankStatement.propTypes = {
  prevStep: PropTypes.object,
  handleAfterSubmit: PropTypes.func,
  changePaymentField: PropTypes.func,
}

export { UploadBankStatement }
