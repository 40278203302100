import moment from 'helpers/date.js'

import { Entity } from 'app/redux/models/common/Entity.js'

const TRANSACTION_DONE_STATE = 'DONE' as const

/**
 * @class
 * @typedef {Object} Transaction
 */
export class Transaction extends Entity {
  constructor() {
    super()

    /** @type {?number} */
    this.id = null
    /** @type {?number} */
    this.goal = null
    /** @type {?number} */
    this.credit = null
    /** @type {?number} */
    this.debit = null
    /** @type {?string} */
    this.trans_type = null
    /** @type {?string} */
    this.description = null
    /** @type {?string} */
    this.state = null
    /** @type {?string} */
    this.state_title = ''
    /** @type {?string} */
    this.payment_method = null
    /** @type {?string} */
    this.created = null
    /** @type {?string} */
    this.modified = null
    /** @type {?boolean} */
    this.is_deletable = false
    /** @type {?string} */
    this.title = ''
  }

  /** @override */
  getRules(): object {
    return {
      goal: [[(value) => parseInt(value, 10)]],
      debit: [[(value) => parseFloat(value)]],
      credit: [[(value) => parseFloat(value)]],
    }
  }

  getYear(): number {
    return parseInt(moment(this.created).format('YYYY'), 10)
  }

  isDone(): boolean {
    return this.state === TRANSACTION_DONE_STATE
  }

  getFormattedCreatedDate(): string {
    return moment(this.created).format('lll')
  }
}
