import React from 'react'

import type { Preset } from 'app/redux/models/portfolio/types'

import { Typo } from 'components/_old/Typo/Typo'

import { SimpleTextModal } from 'components/atoms/SimpleTextModal'

type ShortDescriptionModalProps = {
  isOpen: boolean
  preset: Preset
  onClose: () => void
}

const ShortDescriptionModal = ({ isOpen, preset, onClose }: ShortDescriptionModalProps): React.ReactElement => {
  const textNode = <Typo>{preset?.short_description}</Typo>

  return <SimpleTextModal title={preset?.title} text={textNode} isOpen={isOpen} onClose={onClose} />
}

export { ShortDescriptionModal }
