import { nullable, type Nullable, isNullableNull, int, string } from 'app/effector/model'

import { type IsaProviderDTO } from '../api'

type IsaProviderRegulatoryTypes = 'ISA' | 'GIA'

enum SignatureType {
  WET = 'WET',
  DIGITAL = 'Electronic',
  WET_PARTIAL = 'WET_PARTIAL',
  DIGITAL_PARTIAL = 'Electronic_PARTIAL',
}

class IsaProvider {
  readonly id: Nullable<number>
  readonly name: Nullable<string>
  readonly isa_regexp: Nullable<string>
  readonly isa_error_message: Nullable<string>
  readonly gia_regexp: Nullable<string>
  readonly gia_error_message: Nullable<string>
  readonly signature_type: Nullable<SignatureType>

  constructor(dto: Partial<IsaProviderDTO> | Partial<IsaProvider>) {
    this.id = nullable(int)(dto.id)
    this.name = nullable(string)(dto.name)
    this.isa_regexp = nullable(string)(dto.isa_regexp)
    this.isa_error_message = nullable(string)(dto.isa_error_message)
    this.gia_regexp = nullable(string)(dto.gia_regexp)
    this.gia_error_message = nullable(string)(dto.gia_error_message)
    this.signature_type = nullable(string)(dto.signature_type) as SignatureType
  }

  validate(entry: string, regulatoryType: IsaProviderRegulatoryTypes): true | string {
    const regulatoryRegexp = regulatoryType === 'ISA' ? this.isa_regexp : this.gia_regexp

    if (isNullableNull(regulatoryRegexp)) {
      return true
    }

    const regexp = new RegExp(regulatoryRegexp)

    if (regexp.test(entry)) {
      return true
    }

    const regulatoryErrorMessage = regulatoryType === 'ISA' ? this.isa_error_message : this.gia_error_message

    return regulatoryErrorMessage ?? `${regulatoryType} account is in the wrong format for this provider`
  }
}

export { IsaProvider, SignatureType }
