import * as React from 'react'

import { useMediaQueries } from 'hooks'

import { type AnalyticsItemType } from 'app/effector/analytics/types'

import { type PortfolioList } from 'app/redux/models/portfolio'
import { type Portfolio } from 'app/redux/models/portfolio/types'

import { useSingleHolding } from 'app/pages/Dashboard/Analytics/hooks'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

import './SingleHolding.css'

type SingleHoldingRootProps = {
  type: AnalyticsItemType
  params?: {
    id?: string
    portfolioId?: string
  }
  location?: {
    query?: {
      fromPortfolio?: string
      back?: string
      search?: string
    }
  }
  routes: Array<{
    [key: string]: unknown
    module: string
  }>
}

type HoldingSecurity = {
  id: number
  title: string
  actual_weight: number
  actual_value: number
  target_weight?: number
  color: string
}

type RegionCountries = Array<{
  name: string
  logo: string
  actual_weight: number
  actual_value: number
  target_weight?: number
}>

type HoldingData = {
  name: string
  logo?: string
  description?: string
  website?: string
  actual_weight: number
  actual_value: number
  target_weight?: number
  portfolios: Record<string, HoldingSecurity[]>
  countries?: RegionCountries
  sector_name?: string
  region_name?: string
}

type SingleHoldingProps = {
  isLoading: boolean
  holdingData: HoldingData | null
  portfolios: PortfolioList
  selectedPortfolio: Portfolio
  showTarget: boolean
  holdingDistribution: Array<{ value: number; color: string }>
  sortedPortfolioIds: string[]
  totalAmount: number
  isCountriesTabActive: boolean
  regionTabs: Array<{ id: string; title: string; isActive: boolean }>
  navigateToRegionTab: () => void
  handleBack: () => void
  handleGoToSecurity: (portfolioId: string, securityId: string) => void
  navigateToCountry: (id: string) => void
}

const SingleHolding = ({
  type,
  params: { id, portfolioId } = {},
  location: { query = {} } = {},
  routes,
}: SingleHoldingRootProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { fromPortfolio } = query

  const {
    isLoading,
    portfolios,
    selectedPortfolio,
    showTarget,
    holdingData,
    holdingDistribution,
    sortedPortfolioIds,
    totalAmount,
    isCountriesTabActive,
    regionTabs,
    navigateToCountry,
    navigateToRegionTab,
    handleBack,
    handleGoToSecurity,
  } = useSingleHolding({
    id,
    portfolioId,
    query,
    type,
    routes,
  })

  const props = {
    isLoading,
    type,
    fromPortfolio: fromPortfolio === 'true',
    portfolios,
    selectedPortfolio,
    showTarget,
    holdingData,
    holdingDistribution,
    sortedPortfolioIds,
    totalAmount,
    isCountriesTabActive,
    regionTabs,
    navigateToRegionTab,
    navigateToCountry,
    handleBack,
    handleGoToSecurity,
  }

  return desktop ? <Desktop {...props} /> : <Mobile {...props} />
}

export { SingleHolding, type HoldingSecurity, type HoldingData, type SingleHoldingProps, type RegionCountries }
