import { combine, createStore, createEffect, createEvent } from 'effector'

import { SecurityList, Security, SecurityProviderList } from 'app/effector/securities/models'

import { ApiError } from 'app/redux/models/errors'

import * as api from './api/'

// Events
const clearCollectionSecurities = createEvent<number>()

// Effects
const fetchSecuritiesFx = createEffect(async (params = {}) => {
  try {
    const data = await api.getSecurities(params, { useCache: true })

    if (data instanceof ApiError) throw data

    return data
  } catch (error) {}
})

const fetchFilteredSecuritiesFx = createEffect(async (filters) => {
  try {
    const data = await api.getSecurities(filters, { useCache: true })

    if (data instanceof ApiError) throw data

    return { securities: data }
  } catch (error) {}
})

const fetchSecurityFx = createEffect(async ({ id }) => {
  const promises = [api.getSecurity(id), api.getSecurityHistory(id)]

  try {
    const [securityData, securityHistoryData] = await Promise.all(promises)
    let totalReturns

    if (securityData instanceof ApiError) {
      throw securityData
    }

    if (securityData.dividends_type === 'DISTRIBUTING') {
      totalReturns = await api.getSecurityTotalReturns(id)
    }

    if (securityHistoryData && securityHistoryData instanceof ApiError) {
      throw securityHistoryData
    }

    return { ...securityData, ...securityHistoryData, totalReturns }
  } catch (error) {}
})

// Stores
const $securities = createStore<SecurityList>(new SecurityList())
const $filteredSecurities = createStore<SecurityList>(new SecurityList())
const $filteredCollectionsSecurities = createStore<Record<number, SecurityList>>({})
const $providers = createStore<SecurityProviderList>(new SecurityProviderList())

$securities.on(fetchSecuritiesFx.doneData, (state, securities) => {
  if (!securities) return state
  return new SecurityList(...securities)
})

$securities.on(fetchSecurityFx.doneData, (state, securityData) => {
  if (!securityData) return state
  const security = Security.createFromObject(securityData)

  return state.replaceOrAdd(security)
})

$filteredSecurities.on(
  fetchFilteredSecuritiesFx.done,
  (state, { params: { collection_id: collectionId }, result: { securities } }) => {
    if (!securities || collectionId) return state
    return new SecurityList(...securities)
  },
)

$filteredCollectionsSecurities.on(
  fetchFilteredSecuritiesFx.done,
  (state, { params: { collection_id: collectionId }, result: { securities } }) => {
    if (!collectionId || !securities) return state
    return { ...state, [collectionId]: new SecurityList(...securities) }
  },
)

$filteredCollectionsSecurities.on(clearCollectionSecurities, (state, collectionId) => {
  // eslint-disable-next-line
  delete state[collectionId]
  return state
})

$providers.on(fetchSecuritiesFx.doneData, (state, securities) => {
  if (!securities) return state
  return SecurityProviderList.createFromSecurityList(new SecurityList(...securities))
})

const $securitiesStore = combine(
  [$securities, $filteredSecurities, $filteredCollectionsSecurities, $providers],
  ([securities, filteredSecurities, filteredCollectionsSecurities, providers]) => ({
    securities,
    filteredSecurities,
    filteredCollectionsSecurities,
    providers,
    getSecurityById: (id) => securities.get(id),
  }),
)

export {
  $securitiesStore,
  $securities,
  $filteredCollectionsSecurities,
  fetchSecuritiesFx,
  fetchFilteredSecuritiesFx,
  fetchSecurityFx,
  clearCollectionSecurities,
}
