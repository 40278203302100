import { useActions, useSelector, useEffect } from 'hooks'

import { processError } from 'helpers/errors'
import { goTo, urlTo } from 'helpers/router.js'

import {
  selectGoal as selectGoalActionCreator,
  fetchPortfolios as fetchPortfoliosActionCreator,
} from 'app/redux/actions/portfolios'
import { fetchData as fetchQuestionnaireDataActionCreator } from 'app/redux/actions/questionnaire'
import { showFailToast as showFailToastActionCreator } from 'app/redux/actions/ui'

import { states as goalStates } from 'constants/goal'

const useFetchPortfolioData = (goalId) => {
  const client = useSelector((state) => state.client)

  const [fetchPortfolios, selectGoal, showFailToast, fetchQuestionnaireData] = useActions([
    fetchPortfoliosActionCreator,
    selectGoalActionCreator,
    showFailToastActionCreator,
    fetchQuestionnaireDataActionCreator,
  ])

  const fetchData = async (clientType) => {
    const stateAfterGoalsFetch = await fetchPortfolios()

    if (stateAfterGoalsFetch.portfolios.error) {
      throw stateAfterGoalsFetch.portfolios.error
    }

    const selectedGoal = goalId && stateAfterGoalsFetch.portfolios.items.find(({ id }) => id === Number(goalId))

    if (selectedGoal?.state !== goalStates.NEW) {
      goTo(urlTo('dashboard.portfolio', { id: goalId }))
      return
    }

    if (!selectedGoal) {
      showFailToast()
      throw new Error('Selected goal on create new portfolio is missing')
    }

    selectGoal(selectedGoal.id)
    fetchQuestionnaireData({
      clientType,
      goalRegulatoryType: selectedGoal.regulatory_type,
      goalPresetType: selectedGoal.preset_type,
      goalId: selectedGoal.id,
    })
  }

  useEffect(() => {
    if (!client.type) return
    try {
      fetchData(client.type)
    } catch (error) {
      processError({ error, forceReset: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.type])
}

export { useFetchPortfolioData }
