import React from 'react'
import PropTypes from 'prop-types'

import './VisuallyHidden.css'

const VisuallyHidden = ({ tag = 'div', children }) =>
  React.createElement(tag, { className: 'VisuallyHidden' }, children)

VisuallyHidden.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node,
}

export { VisuallyHidden }
