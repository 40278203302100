import { createStore, createEffect } from 'effector'

import { makeApiCall } from 'app/effector/api'

import * as api from './api'
import { PortfolioPointOfInterestCollection, PortfolioSecurityPointOfInterestCollection } from './models'

// Effects
const fetchPortfolioPointOfInterestsFx = createEffect(
  async ({ portfolioId }) => await makeApiCall(api.fetchPortfolioPointOfInterests, { portfolioId }),
)
const fetchPortfolioSecurityPointOfInterestsFx = createEffect(
  async ({ portfolioId, securityId }) =>
    await makeApiCall(api.fetchPortfolioSecurityPointOfInterests, { portfolioId, securityId }),
)

// Stores

const $portfolioPointOfInterests = createStore(new PortfolioPointOfInterestCollection())
$portfolioPointOfInterests.on(fetchPortfolioPointOfInterestsFx.done, (state, { params, result }) =>
  params ? state.set(params.portfolioId, result) : state,
)

const $portfolioSecurityPointOfInterests = createStore(new PortfolioSecurityPointOfInterestCollection())
$portfolioSecurityPointOfInterests.on(fetchPortfolioSecurityPointOfInterestsFx.done, (state, { params, result }) =>
  params ? state.set(params.portfolioId, params.securityId, result) : state,
)

export {
  // Effects
  fetchPortfolioPointOfInterestsFx,
  fetchPortfolioSecurityPointOfInterestsFx,
  // Stores,
  $portfolioPointOfInterests,
  $portfolioSecurityPointOfInterests,
}
