import React from 'react'

import { useMediaQueries } from 'hooks'

import { features } from 'helpers/features'
import { format } from 'helpers/money'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'
import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'

type IsaLimitWarningProps = {
  amount: string
  remainingAllowance: number
  onSubmit: () => Promise<void>
  onCancel: () => void
}

const IsaLimitWarning = ({
  amount,
  remainingAllowance,
  onSubmit: handleSubmit,
  onCancel: handleCancel,
}: IsaLimitWarningProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const isFlexibleISAEnabled = features?.get('flexible-isa')

  return (
    <Width size={30} data-test-id="withdrawFundsIsaWarning">
      <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
        <Typo>{isFlexibleISAEnabled ? 'Flexible ISA Withdrawal' : 'ISA subscription limit warning'}</Typo>
      </Typography>
      <Paper top={desktop ? 24 : 16}>
        <Typography>
          {isFlexibleISAEnabled ? (
            <Typo>
              This withdrawal is part of your <strong>Flexible ISA allowance</strong>. If you redeposit this amount
              within the same tax year, it will not count towards your annual ISA subscription limit.
            </Typo>
          ) : (
            <Typo>
              Keep in mind that your current ISA subscription limit is {format(remainingAllowance ?? 0, true)},
              withdrawing {format(amount, true)} will not increase the subscription limit to{' '}
              {format(remainingAllowance + parseFloat(amount), true)}.
            </Typo>
          )}
        </Typography>
        <Paper top={40}>
          <StickedIfNotDesktop into="insideModal">
            <ButtonGroup>
              <Button mods={{ theme: 'simple-reverse-blue' }} onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="submit" onClick={handleSubmit} data-test-id="withdrawSubmitButton">
                Withdraw
              </Button>
            </ButtonGroup>
          </StickedIfNotDesktop>
        </Paper>
      </Paper>
    </Width>
  )
}

export { IsaLimitWarning }
