import React from 'react'
import PropTypes from 'prop-types'

import { useActions, useMediaQueries, useSelector, useState, useCallback, useMemo } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'
import { combineErrors, backendErrorsToObj, bulkValidate, passwordRules } from 'helpers/validation.js'

import Button from 'components/_old/Button/Button.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Form, { Fieldset } from 'components/_old/Form/Form.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'

import {
  setNewPassword as setNewPasswordActionCreator,
  resetError as resetErrorActionCreator,
} from 'app/redux/actions/client'
import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

const SetNewPassword = ({ params, location: { query: { isTwoFactorAuthEnabled, isPhoneVerified } = {} } = {} }) => {
  const { desktop } = useMediaQueries()
  const { error, fields } = useSelector(({ client, ui }) => ({ error: client?.error, fields: ui?.fields }))
  const [newPassword, setNewPassword] = useState(fields?.new_password1 || '')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState(fields?.new_password2 || '')
  const [setNewPasswordAction, resetErrorAction] = useActions([setNewPasswordActionCreator, resetErrorActionCreator])

  const handleNewPasswordChange = useCallback(
    (event, value) => {
      resetErrorAction()
      setNewPassword(value)
    },
    [resetErrorAction, setNewPassword],
  )

  const handleNewPasswordConfirmChange = useCallback(
    (event, value) => {
      resetErrorAction()
      setNewPasswordConfirm(value)
    },
    [resetErrorAction, setNewPasswordConfirm],
  )

  const handleSubmit = useCallback(() => {
    const { uid, token } = params

    setNewPasswordAction({ newPassword, newPasswordConfirm, uid, token }).then((nextState) => {
      if (!nextState.client.error) {
        goTo(urlTo('login'))
        showSuccessToast('Password has been reset with the new password')
      } else {
        const error = nextState.client.error.response?.data?.non_field_errors?.[0] ?? 'Something went wrong'
        showFailToast(error)
      }
    })
  }, [newPassword, newPasswordConfirm, setNewPasswordAction, params])

  const validation = combineErrors(
    {
      newPassword: passwordRules(newPassword),
      newPasswordConfirm: {
        rules: [newPasswordConfirm, newPasswordConfirm === newPassword],
        errors: ['Password can’t be empty', 'Passwords did not match'],
      },
    },
    backendErrorsToObj(error),
  )

  const inputMods = { size: 'bigger' }

  const submitButton = (
    <Button
      type="submit"
      mods={{ size: 'big block', theme: !desktop ? 'not-rounded' : null }}
      disabled={!bulkValidate(validation)}
      onClick={handleSubmit}
    >
      Change password
    </Button>
  )

  const backUrl = urlTo('login')
  const backToLogin = (
    <Typography align={desktop ? 'left' : 'center'} size={desktop ? 12 : 16}>
      <Link to={backUrl}>Back to login</Link>
    </Typography>
  )

  const logoutInfoNode = (
    <Paper top={32}>
      <ItemWithIcon
        space={12}
        icon={<Icon type="information-24" size={24} color="inherit" />}
        content={
          <Typography size={desktop ? 14 : 12}>
            <Typo>Changing your password will log you out of other devices.</Typo>
          </Typography>
        }
        contentVerticalAlign="center"
      />
    </Paper>
  )

  const twoFactorInfoNode = useMemo(
    () =>
      isTwoFactorAuthEnabled === 'true' && isPhoneVerified !== 'true' ? (
        <Paper top={24}>
          <ItemWithIcon
            space={12}
            icon={<Icon type="information-24" size={24} color="inherit" />}
            content={
              <Typography size={desktop ? 14 : 12}>
                <Typo>
                  Two-factor authentication will be disabled. You can re-enable it anytime in your profile under 'Login
                  Settings'.
                </Typo>
              </Typography>
            }
            contentVerticalAlign="center"
          />
        </Paper>
      ) : null,
    [desktop, isTwoFactorAuthEnabled, isPhoneVerified],
  )

  const formCard = (
    <SubmitOnEnter>
      <Form>
        <Fieldset>
          <Paper bottom={24}>
            <Validate rules={validation.newPassword.rules}>
              <Label
                errorMessages={validation.newPassword.errors}
                postfield={
                  <Typography color={desktop ? 'additional' : 'minor'} size={12}>
                    Use at least one digit and one capital letter
                  </Typography>
                }
              >
                New password
                <Input mods={inputMods} type="password" onChange={handleNewPasswordChange} tabIndex={1}>
                  {newPassword}
                </Input>
              </Label>
            </Validate>
          </Paper>
          <Paper bottom={desktop ? 32 : 40}>
            <Validate rules={validation.newPasswordConfirm.rules}>
              <Label errorMessages={validation.newPasswordConfirm.errors}>
                Repeat the&nbsp;new password
                <Input mods={inputMods} type="password" onChange={handleNewPasswordConfirmChange} tabIndex={1}>
                  {newPasswordConfirm}
                </Input>
              </Label>
            </Validate>
            {logoutInfoNode}
            {twoFactorInfoNode}
          </Paper>
          <Paper bottom={desktop ? 48 : 40}>{backToLogin}</Paper>
          {desktop && submitButton}
        </Fieldset>
      </Form>
    </SubmitOnEnter>
  )

  const title = 'Create new password'

  return desktop ? (
    <Desktop title={title} isResetPasswordPage>
      {formCard}
    </Desktop>
  ) : (
    <Mobile title={title} button={submitButton} backUrl={backUrl}>
      {formCard}
    </Mobile>
  )
}

SetNewPassword.propTypes = {
  params: PropTypes.shape({
    uid: PropTypes.string,
    token: PropTypes.string,
  }),
  location: PropTypes.shape({
    query: PropTypes.shape({
      isTwoFactorAuthEnabled: PropTypes.string,
      isPhoneVerified: PropTypes.string,
    }),
  }),
}

export { SetNewPassword }
