import React from 'react'
import PropTypes from 'prop-types'

import { format as formatMoney } from 'helpers/money'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { Stack } from 'components/atoms/Stack'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { DonutChart } from 'components/atoms/DonutChart'

const Summary = ({ isLoading, sectors, summary, isForSinglePortfolio }) => {
  return (
    <Stack vertical={32}>
      <div>
        <AllCenter>
          <DonutChart
            lineWidth={14}
            values={sectors.map((sector) => [sector.actual, sector.color])}
            minimalSectorValue={0.65}
          >
            <Paper>
              <Typography size={48} weight="semibold" lineHeight="small" align="center" data-test-id="donutValue">
                <Typo>{isLoading ? '' : sectors.length}</Typo>
              </Typography>
              <Typography size={16} lineHeight="small" align="center" data-test-id="donutValueSubtitle">
                <Typo>Sectors</Typo>
              </Typography>
            </Paper>
          </DonutChart>
          <Paper top={24}>
            <Typography size={14} lineHeight="small" align="center" data-test-id="donutBalanceHeading">
              <Typo>{isForSinglePortfolio ? 'Portfolio' : 'Total'} balance</Typo>
            </Typography>
            <Typography size={14} lineHeight="small" align="center" data-test-id="donutBalanceValue">
              <Typo>{formatMoney(summary.total, true, true)}</Typo>
            </Typography>
          </Paper>
        </AllCenter>
      </div>
    </Stack>
  )
}

Summary.propTypes = {
  isLoading: PropTypes.bool,
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      actual: PropTypes.number.isRequired,
      target: PropTypes.number,
    }),
  ),
  summary: PropTypes.shape({
    total: PropTypes.number,
  }),
  isForSinglePortfolio: PropTypes.bool,
}

export { Summary }
