import React, { Fragment } from 'react'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { NotificationScopeType } from 'app/effector/notifications/models'

import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'

import NotificationGroup from 'components/organisms/Notification/NotificationGroup.tsx'

import { Empty } from 'app/pages/Dashboard/Accounts/components/Empty'
import { Title } from 'app/pages/Dashboard/Accounts/components/Title'

import { DashboardStories } from '../../Portfolios/components/DashboardStories'

import { states as clientStates } from 'constants/client'

const Desktop = ({ client, createPortfolioUrl }) => {
  const header = useMemo(() => <DesktopHeader />, [])

  const content = useMemo(
    () => (
      <Width size={36.75} center>
        <Paper bottom={32}>
          <DashboardStories />
        </Paper>
        {client.state !== clientStates.NEW && (
          <NotificationGroup scope={NotificationScopeType.CLIENT}>
            {(nodes) => <Paper bottom={40}>{nodes}</Paper>}
          </NotificationGroup>
        )}
        <Title createPortfolioUrl={createPortfolioUrl} />

        <Empty createPortfolioUrl={createPortfolioUrl} />
      </Width>
    ),
    [client.state, createPortfolioUrl],
  )

  const footer = useMemo(() => <DesktopFooter />, [])

  return (
    <Fragment>
      <DesktopLayout header={header} content={content} footer={footer} />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

Desktop.propTypes = {
  client: PropTypes.shape({ state: PropTypes.string }),
  createPortfolioUrl: PropTypes.string,
}

export { Desktop }
