import React, { Fragment } from 'react'

import { useMemo, useState, useCallback, useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette'
import { goTo, urlTo } from 'helpers/router.js'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { Gateway } from 'components/atoms/Gateway'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import { CashAndAutoinvestInfo } from '../CashAndAutoinvestInfo'

import './PortfolioLabels.css'

type LabelProps = {
  children: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

const Label = ({ children, onClick }: LabelProps): React.ReactElement => {
  return (
    <Card className="PortfolioLabel" onClick={onClick}>
      <Typography inline size={14} lineHeight="small">
        <Paper top={12} bottom={12} right={12} left={12}>
          {children}
        </Paper>
      </Typography>
    </Card>
  )
}

type PortfolioLabelsProps = {
  portfolio: Portfolio
  collapsed?: boolean
}

const PortfolioLabels = ({ portfolio, collapsed = false }: PortfolioLabelsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const [showTerModal, setShowTerModal] = useState(false)
  const [showYieldModal, setShowYieldModal] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(collapsed)

  const hasTer = portfolio.ter !== null
  const hasYield = !!portfolio?.estimated_yield

  const handleCloseTerModal = useCallback(() => {
    setShowTerModal(false)
  }, [])

  const handleOpenTerModal = useCallback(() => {
    setShowTerModal(true)
  }, [])

  const toggleCollapsed = useCallback(() => {
    setIsCollapsed((state) => !state)
  }, [])

  const cashAndAutoinvestInfo = useMemo(
    () => (
      <Link
        to={urlTo('dashboard.portfolio.options.cash-breakdown', { id: portfolio.id })}
        data-test-id="autoinvestLabel"
      >
        <Paper top={desktop ? 32 : 16} right={12}>
          <CashAndAutoinvestInfo
            cashBalance={portfolio.available_to_invest_cash}
            isAutoinvestEnabled={portfolio.is_autoinvest_enabled}
          />
        </Paper>
      </Link>
    ),
    [desktop, portfolio.available_to_invest_cash, portfolio.is_autoinvest_enabled, portfolio.id],
  )

  const TerDescriptionModal = useMemo(() => {
    const content = (
      <Fragment>
        <Paper bottom={16}>
          <ItemWithIcon
            space={8}
            icon={<Icon type="ter-32" style={{ width: '44px' }} color={palette['content-on-background-primary']} />}
            content={
              <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold">
                <Typo>Total Expense Ratio</Typo>
              </Typography>
            }
            iconVerticalAlign="center"
            contentVerticalAlign="center"
            iconPosition="right"
            inline
          />
        </Paper>
        <Paper>
          <Typo>
            TER stands for Total Expense Ratio and is how much in percentage (%) terms that the ETF charges for
            investment management and admin costs each year.
            <br />
            <br /> The TER is deducted directly from the ETF rather than being charged separately, and the value and
            performance of your investment is quoted after this deduction.
            <br />
            <br /> Your Portfolio TER is the weighted average of all the underlying ETFs expense ratios held within the
            portfolio.
          </Typo>
        </Paper>
      </Fragment>
    )

    if (desktop) {
      return (
        <Gateway into="modals">
          <Modal open={showTerModal} onClose={handleCloseTerModal} zIndex={1001}>
            <ModalContent width={36} center>
              <Paper top={80} bottom={80} left={64} right={64}>
                {content}
              </Paper>
            </ModalContent>
          </Modal>
        </Gateway>
      )
    }

    return (
      <Fragment>
        <Gateway into="modals">
          <Modal open={showTerModal} onClose={handleCloseTerModal} zIndex={1001}>
            <MobileLayout
              header={<NavigationBar onLeftPartClick={handleCloseTerModal} leftPartText="Back" />}
              content={
                <Paper top={12} bottom={48}>
                  {content}
                </Paper>
              }
              footer={
                <MobileLayoutFooterButton>
                  <Button onClick={handleCloseTerModal}>OK</Button>
                </MobileLayoutFooterButton>
              }
            />
          </Modal>
        </Gateway>
      </Fragment>
    )
  }, [desktop, showTerModal, handleCloseTerModal])

  const YieldDescriptionModal = useMemo(() => {
    const content = (
      <Fragment>
        <Paper bottom={16}>
          <ItemWithIcon
            space={8}
            icon={<Icon type="pound-32" style={{ width: '44px' }} color={palette['content-on-background-primary']} />}
            content={
              <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold">
                <Typo>Yield</Typo>
              </Typography>
            }
            iconVerticalAlign="center"
            contentVerticalAlign="center"
            iconPosition="right"
            inline
          />
        </Paper>
        <Paper>
          <Typo>
            This is the dividend income that has been paid out over the previous 12 months from the ETFs in the
            quantities you currently hold, expressed as a percentage of your current total portfolio balance. Please
            note that if you did not hold a dividend on the portfolio record date, you would not have received this
            income.
          </Typo>
        </Paper>
      </Fragment>
    )

    if (desktop) {
      return (
        <Gateway into="modals">
          <Modal
            open={showYieldModal}
            onClose={() => {
              setShowYieldModal(false)
            }}
            zIndex={1001}
          >
            <ModalContent width={36} center>
              <Paper top={80} bottom={80} left={64} right={64}>
                {content}
              </Paper>
            </ModalContent>
          </Modal>
        </Gateway>
      )
    }

    return (
      <Fragment>
        <Gateway into="modals">
          <Modal
            open={showYieldModal}
            onClose={() => {
              setShowYieldModal(false)
            }}
            zIndex={1001}
          >
            <MobileLayout
              header={
                <NavigationBar
                  onLeftPartClick={() => {
                    setShowYieldModal(false)
                  }}
                  leftPartText="Back"
                />
              }
              content={
                <Paper top={12} bottom={48}>
                  {content}
                </Paper>
              }
              footer={
                <MobileLayoutFooterButton>
                  <Button
                    onClick={() => {
                      setShowYieldModal(false)
                    }}
                  >
                    OK
                  </Button>
                </MobileLayoutFooterButton>
              }
            />
          </Modal>
        </Gateway>
      </Fragment>
    )
  }, [desktop, showYieldModal, setShowYieldModal])

  const handleAnalyticsClick = useCallback(() => {
    goTo(urlTo('dashboard.analytics.portfolio.regions', { portfolioId: portfolio.id }, '?fromPortfolio=true'))
  }, [portfolio.id])

  const labels = (
    <Fragment>
      {hasTer && (
        <Paper right={desktop ? 8 : 12} bottom={12} inline>
          <Label onClick={handleOpenTerModal}>
            {`${parseFloat(portfolio.ter).toFixed(2)}% `}
            <Typography size={14} color="additional" lineHeight="small" inline>
              Portfolio TER
            </Typography>
          </Label>
        </Paper>
      )}
      {hasYield && (
        <Paper right={desktop ? 8 : 12} bottom={12} inline>
          <Label
            onClick={() => {
              setShowYieldModal(true)
            }}
          >
            <ItemWithIcon
              space={4}
              icon={<Icon type="pound-16" color={palette['content-on-background-primary']} size={16} inline />}
              iconVerticalAlign="center"
              content={
                <Nobr>
                  <Typography size={14} tag="span" lineHeight="small">
                    {portfolio?.estimated_yield}%{' '}
                  </Typography>
                  <Typography size={14} tag="span" lineHeight="small" color="additional">
                    yield
                  </Typography>
                </Nobr>
              }
            />
          </Label>
        </Paper>
      )}
      <Paper right={desktop ? 0 : 12} bottom={12} inline>
        <Label onClick={handleAnalyticsClick}>
          <ItemWithIcon
            space={4}
            icon={<Icon type="piechart-16" color={palette['status-success']} size={16} inline />}
            iconVerticalAlign="center"
            content={
              <Typography size={14} color="additional" lineHeight="small">
                Analytics
              </Typography>
            }
          />
        </Label>
      </Paper>
    </Fragment>
  )

  const labelsBlock = desktop ? <div className="PortfolioLabels">{labels}</div> : labels

  return (
    <div>
      <Paper bottom={12} inline>
        {cashAndAutoinvestInfo}
      </Paper>
      {collapsed && isCollapsed && <Label onClick={toggleCollapsed}>More</Label>}
      {!isCollapsed && labelsBlock}
      {collapsed && !isCollapsed && <Label onClick={toggleCollapsed}>Less</Label>}
      {TerDescriptionModal}
      {YieldDescriptionModal}
    </div>
  )
}

export { PortfolioLabels }
