import localstore from 'helpers/localstore.js'

function saveOwnerFieldToLocalStorage(field): void {
  return localstore.set('owner', { ...localstore.get('owner'), ...field })
}

function insertIdentityToZendeskForm(owner): void {
  window.zE?.(function () {
    if (owner.email) {
      window.zE?.identify({
        name: owner.first_name ? `${owner.first_name ?? ''} ${owner.last_name ?? ''}`.trim() : 'Investor',
        email: owner.email,
      })
    }
  })
}

function processOwner(contacts): void {
  const owner = contacts.getOwner() || {}
  insertIdentityToZendeskForm(owner)
  saveOwnerFieldToLocalStorage({ first_name: owner.first_name })
}

export { processOwner }
