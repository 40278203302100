import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries, useMemo } from 'hooks'

import { bulkValidate } from 'helpers/validation.js'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import Button from 'components/_old/Button/Button.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelField, LabelText } from 'components/_old/Label/Label.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { BankAccountCard } from 'components/molecules/BankAccountCard'
import { Preloader } from 'components/molecules/Preloader'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

import { frequencies, periodOptions, dayOptions } from '../constants'

import { TermsChechbox } from './components/TermsCheckbox'
import { useRecurringPaymentSetup } from './hooks/useRecurringPaymentSetup'

type RecurringPaymentSetupProps = {
  portfolio: Portfolio
  params: object
  location: {
    query?: {
      back?: string
    }
  }
  handleClose: () => void
}

const RecurringPaymentSetup = withRouter(
  ({ portfolio, location, handleClose }: RecurringPaymentSetupProps): React.ReactElement => {
    const { desktop } = useMediaQueries()

    const {
      bankErrorRef,
      isLoading,
      isBankErrorVisible,
      amount,
      frequency,
      startDay,
      validation,
      isChecked,
      monthlyPaymentDay,
      nominatedBankAccount,
      handleSelectFrequency,
      handleSelectStartDay,
      handleInputMonthlyPaymentDay,
      handleInputAmount,
      toggleCheckbox,
      handleContinue,
      handleBack,
    } = useRecurringPaymentSetup({
      portfolio,
      location,
    })

    const frequencySelectorNode = (
      <Validate>
        <Label>
          <LabelText>
            <Typography size={12} color="additional">
              <Typo>Select frequency</Typo>
            </Typography>
          </LabelText>
          <Input
            type="select"
            placeholder="Select"
            mods={{ size: 'bigger' }}
            options={periodOptions}
            tabIndex={1}
            onChange={handleSelectFrequency}
            data-test-id="recurringPaymentFrequencyInput"
          >
            {frequency}
          </Input>
        </Label>
      </Validate>
    )

    const daySelectorNode = useMemo(() => {
      if (!frequency) return null

      if (frequency === frequencies.MONTHLY) {
        return (
          <Paper top={32}>
            <Validate rules={validation.monthlyPaymentDay?.rules} key="monthlyPaymentDayInput">
              <Label errorMessages={validation.monthlyPaymentDay?.errors}>
                <LabelText replaceWithError>
                  <Typography size={12} color="additional">
                    <Typo>Monthly payment day</Typo>
                  </Typography>
                </LabelText>
                <Input
                  type="number"
                  mods={{ size: 'bigger' }}
                  tabIndex={2}
                  onChange={handleInputMonthlyPaymentDay}
                  data-test-id="recurringPaymentMonthlyDayInput"
                >
                  {monthlyPaymentDay}
                </Input>
              </Label>
            </Validate>
            <Paper top={4}>
              <Typography size={12} lineHeight="small" color="minor">
                <Typo>Must be 1 to 28</Typo>
              </Typography>
            </Paper>
          </Paper>
        )
      }
      return (
        <Paper top={32}>
          <Validate key="startDayInput">
            <Label>
              <LabelText>
                <Typography size={12} color="additional">
                  <Typo>Start Day</Typo>
                </Typography>
              </LabelText>
              <Input
                type="select"
                placeholder="Choose a day"
                mods={{ size: 'bigger' }}
                options={dayOptions}
                tabIndex={2}
                onChange={handleSelectStartDay}
                data-test-id="recurringPaymentStartDayInput"
              >
                {startDay}
              </Input>
            </Label>
          </Validate>
        </Paper>
      )
    }, [frequency, validation, monthlyPaymentDay, handleInputMonthlyPaymentDay, startDay, handleSelectStartDay])

    const amountInputNode = (
      <Validate rules={validation.amount.rules}>
        <Label errorMessages={validation.amount.errors}>
          <LabelText replaceWithError>
            <Typography size={12} color="additional">
              <Typo>Amount</Typo>
            </Typography>
          </LabelText>
          <LabelField>
            <Input
              type="money"
              mods={{ size: 'bigger' }}
              tabIndex={3}
              onChange={handleInputAmount}
              withFloat
              data-test-id="recurringPaymentAmountInput"
            >
              {amount}
            </Input>
          </LabelField>
        </Label>
      </Validate>
    )

    const bankAccountNode = (
      <Paper top={32}>
        <Typography size={14} lineHeignt="small" color="minor">
          <Typo>From</Typo>
        </Typography>
        <Paper top={12}>
          <BankAccountCard bankAccount={nominatedBankAccount} />
        </Paper>
      </Paper>
    )

    const bankAccountErrorNode = isBankErrorVisible && (
      <Paper top={32} ref={bankErrorRef}>
        <Typography size={14} lineHeignt="small" color="error">
          <Typo>Please try again once your bank account has been verified.</Typo>
        </Typography>
      </Paper>
    )

    const paymentReferenceNode = (
      <Paper top={32}>
        <Typography size={14} color="minor" lineHeignt="small">
          <Typo>Payment Reference</Typo>
        </Typography>
        <Typography lineHeignt="small">
          <Typo>InvestEngine</Typo>
        </Typography>
      </Paper>
    )

    const content = (
      <Width size={20} center>
        <Paper top={desktop ? 32 : 16}>
          {frequencySelectorNode}
          {daySelectorNode}
          <Paper top={32}>{amountInputNode}</Paper>
          {bankAccountErrorNode}
          {bankAccountNode}
          {paymentReferenceNode}
          <Paper top={32}>
            <TermsChechbox isChecked={isChecked} onChange={toggleCheckbox} />
          </Paper>
        </Paper>
      </Width>
    )

    return (
      <Fragment>
        <Preloader loading={isLoading} background="background" zIndex />
        <OptionsLayout
          header={
            <OptionsNavigationBar
              leftPartText="Back"
              onLeftPartClick={handleBack}
              rightPartText="Close"
              onRightPartClick={handleClose}
            >
              Savings Plan set up
            </OptionsNavigationBar>
          }
          content={content}
          button={
            <Width size={20} center>
              <Button
                type="submit"
                onClick={handleContinue}
                mods={{ size: 'big block' }}
                disabled={isLoading || !isChecked || !bulkValidate(validation)}
                data-test-id="recurringPaymentSetupButton"
              >
                Continue
              </Button>
              <Paper top={20} bottom={16}>
                <Typography size={12} lineHeight="small" align="center">
                  <Typo>We will securely transfer you to your bank for authentication</Typo>
                </Typography>
              </Paper>
            </Width>
          }
          data-test-id="recurringPaymentSetupPopup"
        />
      </Fragment>
    )
  },
)

export { RecurringPaymentSetup }
