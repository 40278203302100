import { combine, createStore, createEffect } from 'effector'

import { makeApiCall } from 'app/effector/api'

import { type Frequency } from 'app/pages/Dashboard/Goals/RecurringPayment/types'

import * as api from './api'

type DirectDebitSubscription = {
  id: number
  portfolio_id: number
  amount: string
  day_of_month?: number
}

type RecurringPaymentSubscription = {
  id: number
  portfolio_id: number
  amount: string
  frequency: Frequency
  start_day_week?: number
  day_of_month?: number
  to_bank_account?: string
  from_bank_account?: string
  max_amount_in_period?: string
  max_amount_per_payment?: string
  valid_from?: string
  valid_to?: string
  reference?: string
}

type RecurringPaymentStore = {
  open_banking: RecurringPaymentSubscription[]
  direct_debit: DirectDebitSubscription[]
}

// Effects
const fetchRecurringPaymentsFx = createEffect(async () => {
  try {
    return await makeApiCall(api.getRecurringSubscriptions)
  } catch (error) {}
})

// Stores
const $recurringPayments = createStore<RecurringPaymentStore>({
  open_banking: [],
  direct_debit: [],
})

$recurringPayments.on(fetchRecurringPaymentsFx.doneData, (state, result) => {
  if (!result) return state

  return result
})

const $areRecurringPaymentsFetched = createStore<boolean>(false)

const $isLoading = fetchRecurringPaymentsFx.pending

$areRecurringPaymentsFetched.on(fetchRecurringPaymentsFx.done, () => true)

const $recurringPaymentsStore = combine(
  [$recurringPayments, $areRecurringPaymentsFetched, $isLoading],
  ([recurringPayments, areRecurringPaymentsFetched, isLoading]) => ({
    recurringPayments,
    isLoading,
    areRecurringPaymentsFetched,
    hasReccuringPaymentByPortfolioId: (portfolioId) =>
      recurringPayments.open_banking?.some((payment) => payment.portfolio_id === portfolioId),
    hasDirectDebitByPortfolioId: (portfolioId) =>
      recurringPayments.direct_debit?.some((subscription) => subscription.goal === portfolioId),
    hasSavingsPlanByPortfolioId: (portfolioId) =>
      recurringPayments.open_banking?.some((payment) => payment.portfolio_id === portfolioId) ||
      recurringPayments.direct_debit?.some((subscription) => subscription.goal === portfolioId),
    getReccuringPaymentByPortfolioId: (portfolioId) =>
      recurringPayments.open_banking?.find((payment) => payment.portfolio_id === portfolioId),
    getDirectDebitByPortfolioId: (portfolioId) =>
      recurringPayments.direct_debit?.find((subscription) => subscription.goal === portfolioId),
  }),
)

export {
  $recurringPaymentsStore,
  fetchRecurringPaymentsFx,
  $recurringPayments,
  type RecurringPaymentStore,
  type DirectDebitSubscription,
  type RecurringPaymentSubscription,
}
