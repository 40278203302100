import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { Typo } from 'components/_old/Typo/Typo'

import './Badge.css'

const Badge = ({ className, text, theme, 'data-test-id': dataTestId }) => {
  const badgeClasses = classNames(className, 'Badge', {
    [`Badge_theme_${theme}`]: !!theme,
  })

  return (
    <div className={badgeClasses} data-test-id={dataTestId}>
      <Typo>{text}</Typo>
    </div>
  )
}

Badge.propTypes = {
  className: PropTypes.string,
  text: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(['rounded-green', 'rounded-warning', 'rounded-primary-action', 'rounded-accent-default']),
  'data-test-id': PropTypes.string,
}

export { Badge }
