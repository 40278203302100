import { useActions, useCallback, useMemo, useState, useSelector } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { fetchAllowancesFx } from 'app/effector/allowances'

import { signDeclaration as signDeclarationActionCreator } from 'app/redux/actions/client'
import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'

import { useClient } from 'app/pages/Dashboard/SetupAccount/hooks/useClient.js'

import { states as clientStates } from 'constants/client'
import { manageTypes, regulatoryTypes } from 'constants/portfolio'

const useIsaDeclaration = (idString, query) => {
  const portfolioId = useMemo(() => (idString ? parseInt(idString, 10) : null), [idString])
  const isPortfolioDIY = useSelector((state) => state.portfolios.list.get(portfolioId)?.manage_type === manageTypes.DIY)
  const [agreed, setAgreed] = useState(false)
  const { client, completeClient } = useClient()
  const [fetchPortfolios, signDeclaration] = useActions([fetchPortfoliosActionCreator, signDeclarationActionCreator])
  const isQuickStartInProgress = query.quickStart === 'true'
  const shouldForwardToIsaTransfer = query.forwardToTransferIsa === 'true'
  const [finishRegistrationModalOpened, setFinishRegistrationModalOpened] = useState(false)

  const handleFinishRegistrationModalOpen = useCallback(() => {
    setFinishRegistrationModalOpened(true)
  }, [setFinishRegistrationModalOpened])

  const handleFinishRegistrationModalClose = useCallback(() => {
    setFinishRegistrationModalOpened(false)
  }, [setFinishRegistrationModalOpened])

  const handleFinishRegistrationModalContinue = useCallback(() => {
    goTo(urlTo('dashboard.setup-account', null, { action: query.action }))
  }, [query?.action])

  const handleAgreedChange = useCallback(
    (value) => {
      setAgreed(value)
    },
    [setAgreed],
  )

  const handleSubmit = useCallback(async () => {
    if (agreed) {
      const stateAfterDeclarationSign = await signDeclaration({ declarationType: regulatoryTypes.ISA })

      if (stateAfterDeclarationSign.client.error) {
        showFailToast()
        return
      }

      if ([clientStates.NEW, clientStates.NOT_COMPLETED].includes(client.state)) {
        await completeClient()

        if (query.action === 'transferIsa') {
          handleFinishRegistrationModalOpen()

          return
        }
      }

      const nextUrlModule = portfolioId ? 'dashboard.portfolio.finish' : 'create-new-portfolio'
      fetchAllowancesFx()

      await fetchPortfolios() // we need to refetch portfolios here since there new isa cash portfolio created

      if (shouldForwardToIsaTransfer) {
        if (
          (client.state === clientStates.NEW || client.state === clientStates.NOT_COMPLETED) &&
          query.action === 'transferIsa'
        ) {
          handleFinishRegistrationModalOpen()

          return
        }

        goTo(urlTo('transfer-isa-altus'))

        return
      }

      const nextUrl =
        query?.next ??
        urlTo(
          nextUrlModule,
          portfolioId ? { id: portfolioId } : {},
          isQuickStartInProgress ? query : portfolioId ? query : { regulatoryType: regulatoryTypes.ISA },
        )

      goTo(nextUrl)
    }
  }, [
    agreed,
    fetchPortfolios,
    portfolioId,
    isQuickStartInProgress,
    query,
    client.state,
    shouldForwardToIsaTransfer,
    completeClient,
    signDeclaration,
    handleFinishRegistrationModalOpen,
  ])

  const handleCancel = useCallback(() => {
    if (!portfolioId) {
      goTo(urlTo('dashboard'))
      return
    }
    const nextUrlModule = isPortfolioDIY ? 'portfolio.edit-weights' : 'new-portfolio-tunnel.review-portfolio'
    const nextUrlQuery = isPortfolioDIY ? query ?? { mode: 'universe' } : null
    const nextUrl = query?.back ?? urlTo(nextUrlModule, { id: portfolioId }, nextUrlQuery)

    goTo(nextUrl, { replace: true })
  }, [query, portfolioId, isPortfolioDIY])

  return {
    agreed,
    finishRegistrationModalOpened,
    handleAgreedChange,
    handleSubmit,
    handleCancel,
    handleFinishRegistrationModalOpen,
    handleFinishRegistrationModalClose,
    handleFinishRegistrationModalContinue,
  }
}

export { useIsaDeclaration }
