import React, { Fragment, useCallback } from 'react'

import isFunction from 'lodash/isFunction'

import { useMediaQueries, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'
// import { palette } from 'helpers/palette/'

// import { BadgeNew } from 'components/atoms/BadgeNew'
import type { Preset } from 'app/redux/models/portfolio/types'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import { PresetCard } from 'app/pages/Securities/components/PresetCard'

type SelfSelectedPresetsBlockProps = {
  presets: Preset[]
  handleSaveScrollPosition?: () => void
}

const SelfSelectedPresetsBlock = ({
  presets,
  handleSaveScrollPosition,
}: SelfSelectedPresetsBlockProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)

  const visiblePresets = presets.sort((a, b) => a.featured_order - b.featured_order).slice(0, 3)

  const getSelfSelectedPresetLink = useCallback((presetId: number): any => {
    const currentLocation = location.pathname + location.search

    return urlTo('self-selected-presets.preset', { id: presetId }, { back: currentLocation })
  }, [])

  const handleGoToPreset = (presetId: number, presetTitle: string): void => {
    if (handleSaveScrollPosition) handleSaveScrollPosition()

    trackEvent({
      action: 'lp_portfolio_selected',
      manage_type: 'SELF_SELECTED',
      preset: presetTitle,
    })

    goTo(getSelfSelectedPresetLink(presetId))
  }

  const handleShowAll = (): void => {
    if (handleSaveScrollPosition) handleSaveScrollPosition()

    trackEvent({
      action: 'lp_show_all_portfolios',
      manage_type: 'SELF_SELECTED',
    })

    goTo(urlTo('self-selected-presets'))
  }

  const handleClick = (event, func): void => {
    const isOpenInNewTab = event?.ctrlKey || event?.metaKey

    if (event && !isOpenInNewTab) {
      event.preventDefault()
    }

    if (!isOpenInNewTab && isFunction(func)) {
      func()
    }
  }

  const handleOpenModal = (): void => {
    setIsModalOpened(true)
  }

  const handleCloseModal = (): void => {
    setIsModalOpened(false)
  }

  // const currentLocation = window.location.pathname + window.location.search

  const modalContent = (
    <Fragment>
      <Paper bottom={16}>
        <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold">
          <Typo>LifePlan portfolios</Typo>
        </Typography>
      </Paper>
      <Paper>
        <Typo>
          Built and managed by the InvestEngine investment team, LifePlans are actively managed, ready-made portfolios,
          aiming to provide you with long-term investment returns at your chosen level of risk.
          <br />
          <br />
          Each portfolio is pre-built to align with a standard risk rating, offering 20%, 40%, 60%, 80%, or 100% equity
          exposure. This gives you broad market exposure to global shares and bonds through expertly chosen passive
          index-tracking investments called ETFs (Exchange Traded Funds).
          <br />
          <br />
          Typically, a higher equity exposure gives you the opportunity for higher returns over the long-term, but can
          be riskier over the short term.
          <br />
          <br />
          Choose the portfolio pre-set that’s appropriate for you, based on your financial goals, timeline and comfort
          with risk. We’ll handle the day-to-day investment management, asset allocation and rebalancing to keep your
          portfolio on track.
          {/* <br />
          <br />
          Not sure what to invest in? Take our{' '}
          <Link
            to={urlTo('create-new-portfolio', null, {
              back: currentLocation,
              manageType: manageTypes.MANAGED,
            })}
          >
            Managed questionnaire
          </Link>{' '}
          and we’ll build and manage a portfolio to suit you on your behalf. */}
        </Typo>
      </Paper>
    </Fragment>
  )

  return (
    <Fragment>
      <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
        {/* <Column size={0}> */}
        {/*   <Paper left={8} right={20}> */}
        {/*     <BadgeNew */}
        {/*       text="NEW" */}
        {/*       color={palette['content-on-color-white']} */}
        {/*       backgroundColor={palette['self-selected-default']} */}
        {/*     /> */}
        {/*   </Paper> */}
        {/* </Column> */}
        <Column size={0}>
          <ItemWithIcon
            space={4}
            iconPosition="right"
            icon={
              <Link
                onClick={handleOpenModal}
                mods={{ color: 'black' }}
                style={{ top: desktop ? '4px' : 0, position: 'relative' }}
              >
                <Icon type="information-24" size={24} color="inherit" />
              </Link>
            }
            content={
              <Typography
                size={desktop ? 24 : 20}
                weight="semibold"
                lineHeight="small"
                data-test-id="discoverySectionTitle"
                inline
              >
                <Typo>LifePlan portfolios</Typo>
              </Typography>
            }
            data-test-id="lifePlanTooltip"
          />
        </Column>
      </ColumnarLayout>

      {visiblePresets.map((preset) => (
        <Link
          key={preset.id}
          to={getSelfSelectedPresetLink(preset.id)}
          onClick={(event) => {
            handleClick(event, () => {
              handleGoToPreset(preset.id, preset.title)
            })
          }}
        >
          <Paper top={24}>
            <PresetCard preset={preset} />
          </Paper>
        </Link>
      ))}
      <Paper top={24} data-test-id="showAllPresetsButton">
        <SelectableCard
          tag="a"
          href={urlTo('self-selected-presets')}
          onClick={(event) => {
            handleClick(event, handleShowAll)
          }}
        >
          <Paper top={12} bottom={12}>
            <Typography lineHeight="small" align="center">
              Show all portfolios
            </Typography>
          </Paper>
        </SelectableCard>
      </Paper>

      {desktop ? (
        <Modal open={isModalOpened} onClose={handleCloseModal}>
          <ModalContent width={36} center>
            <Paper top={80} bottom={80} left={64} right={64}>
              {modalContent}
            </Paper>
          </ModalContent>
        </Modal>
      ) : (
        <Modal open={isModalOpened} onClose={handleCloseModal}>
          <MobileLayout
            header={<NavigationBar onLeftPartClick={handleCloseModal} leftPartText="Back" />}
            content={
              <Paper top={12} bottom={48}>
                {modalContent}
              </Paper>
            }
            footer={
              <MobileLayoutFooterButton>
                <Button onClick={handleCloseModal}>OK</Button>
              </MobileLayoutFooterButton>
            }
          />
        </Modal>
      )}
    </Fragment>
  )
}

export { SelfSelectedPresetsBlock }
