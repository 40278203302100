import React, { Fragment } from 'react'

import { useMemo, useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Relative } from 'components/atoms/Relative'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import { Preloader } from 'components/molecules/Preloader'

import { useOpenIsaLink } from './hooks/useOpenIsaLink'

const OpenIsaLink = (): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const {
    isLoading,
    isClientNew,
    isNewClientIsaModalOpen,
    handleLinkClick,
    handleCloseNewClientIsaModal,
    handleSubmitNewClientIsaModal,
  } = useOpenIsaLink()

  const clientNewIsaModal = useMemo(() => {
    if (isClientNew) {
      const headerNode = <NavigationBar rightPartText="Cancel" onRightPartClick={handleCloseNewClientIsaModal} />

      const buttonNode = (
        <Button onClick={handleSubmitNewClientIsaModal} mods={{ size: 'big block' }}>
          Continue
        </Button>
      )

      const content = (
        <AllCenter>
          <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
            <Icon type="isa-declararion-64" size={64} color="inherit" inline />
            <Paper top={24}>
              <Typo>ISA Account Setup</Typo>
            </Paper>
          </Typography>
          <Paper top={desktop ? 32 : 24}>
            <Typography size={14} align="center">
              <Typo>
                Begin setting up your ISA now, and note that it will be officially active only after completing
                registration with InvestEngine.
              </Typo>
            </Typography>
          </Paper>
        </AllCenter>
      )

      return (
        <Modal open={isNewClientIsaModalOpen} close={null} onClose={handleCloseNewClientIsaModal}>
          <Relative>
            {desktop && (
              <Width size={36} style={{ minHeight: '600px' }}>
                <Paper top={8} bottom={8} right={24} left={24}>
                  {headerNode}
                </Paper>
                <Paper top={120}>
                  <Width size={27} center>
                    {content}
                    <Paper top={56}>
                      <Width size={21.5} center>
                        {buttonNode}
                      </Width>
                    </Paper>
                  </Width>
                </Paper>
              </Width>
            )}
            {!desktop && (
              <MobileLayout
                header={headerNode}
                content={content}
                footer={<MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
              />
            )}
            <Preloader loading={isLoading} background="background" absolute />
          </Relative>
        </Modal>
      )
    }

    return null
  }, [
    isClientNew,
    desktop,
    isLoading,
    isNewClientIsaModalOpen,
    handleSubmitNewClientIsaModal,
    handleCloseNewClientIsaModal,
  ])

  return (
    <Fragment>
      <Link onClick={handleLinkClick} style={{ display: 'block' }} data-test-id="openIsaButton">
        <ItemWithIcon
          inline
          space={12}
          icon={<Icon type="plus-in-an-outlined-circle-32" size={32} color="inherit" />}
          content={
            <Typography size={14} lineHeight="small" color="inherit">
              <Typo>Open an ISA</Typo>
            </Typography>
          }
          contentVerticalAlign="center"
        />
        <Paper left={40}>
          <Paper left={4}>
            <Typography size={14} lineHeight="small" color="minor">
              <Typo>You are not using any of your tax-free allowance until you add cash.</Typo>
            </Typography>
          </Paper>
        </Paper>
      </Link>
      {clientNewIsaModal}
    </Fragment>
  )
}

export { OpenIsaLink }
