import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

/**
 * @param {string=} state
 * @return {Promise<Array.<Object>|ApiError>}
 */
export const getPortfolios = async (state) => {
  try {
    const { data } = await axios.get(state ? `portfolios/?state=${state}` : 'portfolios/')

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {number} id
 * @return {Promise<Object|ApiError>}
 */
export const getPortfolio = async (id) => {
  try {
    const { data } = await axios.get(`portfolios/${id}/`, { shouldNotSend404: true })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {number} id
 * @param {Object} dataToPatch
 * @return {Promise<Object|ApiError>}
 */
export const patchPortfolio = async (id, dataToPatch) => {
  try {
    const { data } = await axios.patch(`portfolios/${id}/`, dataToPatch)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {number} id
 * @return {Promise<Object|ApiError>}
 */
export const deletePortfolio = async (id) => {
  try {
    const { data } = await axios.delete(`portfolios/${id}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export const closeIsaPanel = async (portfolioId) => {
  try {
    await axios.post(`portfolios/close-isa-panel/`, { portfolio_id: portfolioId })
  } catch (error) {
    return new ApiError(error)
  }
}

export const getPortfolioSharingData = async (id) => {
  try {
    const { data } = await axios.get(`/portfolio/${id}/sharing_data/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export const sharePortfolio = async ({ id, isEnabled }) => {
  try {
    const { data } = await axios.patch(`/portfolio/${id}/share/`, { is_enabled: isEnabled })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {string} hash
 * @return {Promise<Object|ApiError>}
 */
export const getSharedPortfolioData = async (hash) => {
  try {
    const { data } = await axios.get(`public/shared_portfolio/${hash}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export const changeSharingPortfolioTitle = async ({ portfolioId, title, description }) => {
  try {
    const { data } = await axios.patch(`/portfolio/${portfolioId}/share/`, { title, description })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export const setupRecurringPayment = async (paymentData) => {
  try {
    const { data } = await axios.post('/open-banking/recurring/', paymentData)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export const getRecurringMandate = async (mandateId) => {
  try {
    const { data } = await axios.get(`/open-banking/recurring/mandates/${mandateId}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export const stopRecurringPayment = async (id) => {
  try {
    const { data } = await axios.delete(`/open-banking/recurring/${id}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export const changeDisplaySettings = async ({ regulatoryTypes = {}, portfolios = {} }) => {
  try {
    const { data } = await axios.patch('portfolios/display-settings/', {
      regulatory_types: regulatoryTypes,
      portfolios,
    })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {number} id
 * @return {Promise<Object|ApiError>}
 */
export const getPresetHistory = async (presetId) => {
  try {
    const { data } = await axios.get(`history/preset-performance/${presetId}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export const createSippTransfer = async (transferData) => {
  try {
    const { data } = await axios.post('/portfolios/sipp-transfer-request/', transferData)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
