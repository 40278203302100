import React from 'react'

import { Autoinvest } from './Autoinvest'
import { AutoinvestAmount } from './AutoinvestAmount'
import { AutoinvestSuggestion } from './AutoinvestSuggestion'

const autoinvestRoutes = [
  {
    module: 'autoinvest',
    path: 'autoinvest/',
    component: function Component(props) {
      return <Autoinvest {...props} />
    },
    childRoutes: [
      {
        module: 'amount',
        path: 'amount/',
        component: function Component(props) {
          return <AutoinvestAmount {...props} />
        },
      },
      {
        module: 'autoinvest-suggestion',
        path: 'suggestion/',
        component: function Component(props) {
          return <AutoinvestSuggestion {...props} />
        },
      },
    ],
  },
]

export { autoinvestRoutes }
