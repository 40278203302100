import React from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { SimpleTextModal } from 'components/atoms/SimpleTextModal'
import { Typography } from 'components/atoms/Typography'

type TerDescriptionModalProps = {
  isOpen: boolean
  onClose: () => void
}

const TerDescriptionModal = ({ isOpen, onClose }: TerDescriptionModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const titleNode = (
    <ItemWithIcon
      space={8}
      icon={<Icon type="ter-32" style={{ width: '44px' }} color={palette['content-on-background-primary']} />}
      content={
        <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold">
          <Typo>Total Expense Ratio</Typo>
        </Typography>
      }
      iconVerticalAlign="center"
      contentVerticalAlign="center"
      iconPosition="right"
      inline
    />
  )

  const textNode = (
    <Typo>
      TER stands for Total Expense Ratio and is how much in percentage (%) terms that the ETF charges for investment
      management and admin costs each year.
      <br />
      <br /> The TER is deducted directly from the ETF rather than being charged separately, and the value and
      performance of your investment is quoted after this deduction.
      <br />
      <br /> Your Portfolio TER is the weighted average of all the underlying ETFs expense ratios held within the
      portfolio.
    </Typo>
  )

  return <SimpleTextModal title={titleNode} text={textNode} isOpen={isOpen} onClose={onClose} />
}

export { TerDescriptionModal }
