import React, { Fragment } from 'react'

import { useState, useEffect, useClickOutside, useMediaQueries, useTouch, useRef } from 'hooks'

import { isDarkTheme } from 'helpers/palette'

import Icon from 'components/_old/Icon/Icon.jsx'

import { Paper } from 'components/atoms/Paper'

import { ContextMenuList } from './ContextMenuList'

import './ContextMenu.css'

const ContextMenu = ({ items, children, offsetTop = 0, overlayPadding = 0 }): React.ReactElement => {
  const ref = useRef(null)
  const { desktop } = useMediaQueries()
  const [isOpen, setIsOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [rootRef, hasClickedOutside] = useClickOutside()

  useTouch({
    onEnter: () => {
      setIsVisible(true)
      navigator?.vibrate?.(200)
    },
    containers: [ref.current],
    enterOnTouchStart: false,
    enterOnLongTouch: true,
    enterOnTouchMove: false,
    longTouchTimer: 1000,
  })

  useEffect(() => {
    if (hasClickedOutside && desktop) {
      setIsOpen(false)
      setIsVisible(false)
    }
  }, [hasClickedOutside, rootRef, desktop])

  if (desktop)
    return (
      <div
        className="ContextMenu_Wrapper"
        onMouseEnter={() => {
          setIsVisible(true)
        }}
        onMouseLeave={() => {
          if (isOpen) return
          setIsVisible(false)
        }}
      >
        {children}
        {isVisible && (
          <div ref={rootRef}>
            <div
              className="ContextMenu"
              onClick={(event) => {
                event.stopPropagation()
                setIsOpen(!isOpen)
              }}
              style={{ top: 0 + offsetTop }}
            >
              <Icon type={isDarkTheme() ? 'essential/dots-menu-light' : 'essential/dots-menu'} size={32} />
            </div>
            {isOpen && (
              <div className="ContextMenu_Content" style={{ top: offsetTop }}>
                <ContextMenuList
                  items={items}
                  onItemClick={() => {
                    setIsOpen(false)
                    setIsVisible(false)
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    )

  return (
    <Fragment>
      <div ref={ref}>{children}</div>
      {isVisible && (
        <div
          className="ContextMenu_Overlay"
          onClick={() => {
            setIsVisible(false)
          }}
        >
          <div className="ContextMenu_OverlayContent">
            <Paper bottom={12} left={16} right={16}>
              <div className="ContextMenu_OverlayChildren" style={{ padding: overlayPadding }}>
                {children}
              </div>
            </Paper>
            <div className="ContextMenu_OverlayMenu">
              <ContextMenuList
                items={items}
                onItemClick={() => {
                  setIsVisible(false)
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export { ContextMenu }
