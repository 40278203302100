import React from 'react'

import InlineHelp from 'components/_old/InlineHelp/InlineHelp.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import ModalContent, { ModalContentBody, ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

const LearnMore = (): React.ReactElement => (
  <InlineHelp
    helper={
      <Link className="Text_noWrap">
        <Text small>
          <Typo>Learn more</Typo>
        </Text>
        <div className="Icon_question">?</div>
      </Link>
    }
  >
    {(handleClose) => (
      <ModalContent>
        <ModalContentBody>
          <Typo>
            <p className="Text_small">
              "Expected" (green): there is a 50% chance that the value of your investment will fall within this range
              (the most likely outcome).
            </p>
            <p className="Text_small">
              "Good" (blue): there is a 20% chance that the value of your investment will end up in this range.
            </p>
            <p className="Text_small">
              "Bad" (grey): there is a 20% chance that the value of your investment will end up in this range.
            </p>
            <p className="Text_small">
              "Exceptional": there is only a 5% chance that the value of your investment will end up above the funnel
              shown on the chart.
            </p>
            <p className="Text_small">
              "Worst": there is a 5% chance that the value of your investment will end up below the funnel shown on the
              chart (down to zero).
            </p>
            <p className="Text_small">
              We recommend not to rely on outcomes in the "Good range" and above for your financial planning.
            </p>
            <p className="Text_small">
              Please seek financial advice if you cannot find on this chart potential outcomes that fit your financial
              goals.
            </p>
          </Typo>
        </ModalContentBody>
        <ModalContentButtons text="OK" onClick={handleClose} />
      </ModalContent>
    )}
  </InlineHelp>
)

export default LearnMore
