import React from 'react'

import { useMediaQueries } from 'hooks'

import moment from 'helpers/date.js'
import { format as formatMoney } from 'helpers/money'

import { type RecurringPaymentSubscription } from 'app/effector/recurringPayments'

import TransferFundsTo from 'components/_old/TransferFundsTo/TransferFundsTo.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'
import { frequencies } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'

const frequencyTexts = {
  [frequencies.WEEKLY]: 'weekly',
  [frequencies.FORTNIGHT]: 'fortnightly',
  [frequencies.MONTHLY]: 'monthly',
}

type MandateDetailsProps = {
  onBack: () => void
  onClose?: () => void
  recurringPayment: RecurringPaymentSubscription
}

const MandateDetails = ({
  onBack: handleBack,
  onClose: handleClose,
  recurringPayment,
}: MandateDetailsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const periodLabel = `Max ${frequencyTexts[recurringPayment?.frequency]} amount`
  const fields = {
    To: recurringPayment?.to_bank_account,
    From: recurringPayment?.from_bank_account,
    [periodLabel]: formatMoney(recurringPayment?.max_amount_in_period as string, true),
    'Max amount per payment': formatMoney(recurringPayment?.max_amount_per_payment as string, true),
    Valid: `${moment(recurringPayment?.valid_from).format('DD MMM YYYY')} — ${moment(recurringPayment?.valid_to).format(
      'DD MMM YYYY',
    )}`,
    Reference: recurringPayment?.reference,
  }

  return (
    <OptionsLayout
      header={
        <OptionsNavigationBar
          leftPartText="Back"
          rightPartText={handleClose ? 'Close' : ''}
          onLeftPartClick={handleBack}
          onRightPartClick={handleClose}
        >
          <Typo>
            <span style={{ whiteSpace: 'nowrap' }}>Mandate details</span>
          </Typo>
        </OptionsNavigationBar>
      }
      content={
        <Paper top={desktop ? 80 : 16} bottom={desktop ? 120 : 0} left={desktop ? 48 : 16} right={desktop ? 48 : 16}>
          <TransferFundsTo fields={fields} smallMuted />
        </Paper>
      }
      button={null}
      data-test-id="mandateDetailsModal"
    />
  )
}

export { MandateDetails }
