import React, { Fragment } from 'react'

import { useCallback, useMemo } from 'hooks'

import { formatDateToBackend } from 'helpers/date'
import { isTouch } from 'helpers/mobile'
import { palette } from 'helpers/palette'

import { PointOfInterestCardPositions } from 'components/organisms/charts/parts'
import type { AbstractPointOfInterest } from 'components/organisms/charts/parts'
import type { SelectPointOfInterestInterface } from 'components/organisms/PriceChart/hooks/usePointsOfInterest'

import { HoverPoint, ClickablePoint } from '../parts'

import type { CalculateChartValuesInterface, XYCoordinates } from '../utils'

type UsePointsProps = {
  chartValues: CalculateChartValuesInterface
  color: string
  height: number
  setPickCoordinates: boolean
  hoverable: boolean
  hoveredIndex?: number
  activePointOfInterestDate?: Date
  handleTickEnter?: (event: React.MouseEvent<SVGGElement, MouseEvent>, index: number) => void
  handleTickLeave?: (event: React.MouseEvent<SVGGElement, MouseEvent>) => void
  activePointOfInterest?: AbstractPointOfInterest | null
  selectPointOfInterest?: SelectPointOfInterestInterface
}

type UsePointsInterface = {
  hoverablePoints: JSX.Element[]
  clickablePoints: JSX.Element[]
}

function usePoints({
  chartValues,
  color,
  height,
  setPickCoordinates,
  hoverable,
  hoveredIndex,
  handleTickEnter: handleTickEnterRaw,
  handleTickLeave: handleTickLeaveRaw,
  activePointOfInterest,
  selectPointOfInterest,
}: UsePointsProps): UsePointsInterface {
  // avoid local trigger of mouse events if uses touch
  const [handleTickEnter, handleTickLeave] = useMemo(() => {
    if (isTouch()) {
      return [() => {}, () => {}]
    }

    if (selectPointOfInterest) {
      return [
        (
          event: React.MouseEvent<SVGGElement>,
          index: number,
          pointOfInterest?: AbstractPointOfInterest,
          center?: number,
          position?: PointOfInterestCardPositions,
        ) => {
          if (handleTickEnterRaw) {
            handleTickEnterRaw(event, index)
          }

          if (pointOfInterest) {
            selectPointOfInterest(pointOfInterest, center, position)
          }
        },
        (event: React.MouseEvent<SVGGElement>) => {
          if (handleTickLeaveRaw) {
            handleTickLeaveRaw(event)
          }

          if (!activePointOfInterest?.hard) {
            selectPointOfInterest(null, undefined, undefined)
          }
        },
      ]
    }

    return [handleTickEnterRaw, handleTickLeaveRaw]
  }, [activePointOfInterest?.hard, handleTickEnterRaw, handleTickLeaveRaw, selectPointOfInterest])

  const renderRect = useCallback(
    (begin: number, end: number) => (
      <rect x={begin} y={0} width={end - begin} height={height} fill="white" style={{ opacity: 0 }} />
    ),
    [height],
  )

  const renderLine = useCallback(
    (position: XYCoordinates, height: number) => (
      <line
        x1={position.x}
        x2={position.x}
        y1={0}
        y2={height}
        stroke={palette['content-on-background-additional']}
        style={{ opacity: 1 }}
      />
    ),
    [],
  )

  const renderHoverPoint = useCallback(
    (position: XYCoordinates, min: boolean, max: boolean, current: boolean, visible = true) => (
      <HoverPoint
        position={position}
        color={color}
        setPickCoordinates={setPickCoordinates}
        min={min}
        max={max}
        current={current}
        visible={visible}
      />
    ),
    [color, setPickCoordinates],
  )

  const isPoiActive = useCallback(
    (date: Date): boolean => {
      const dateString = formatDateToBackend(date)
      const activeDateString = formatDateToBackend(activePointOfInterest?.date)

      return dateString === activeDateString
    },
    [activePointOfInterest?.date],
  )

  const renderClickablePoint = useCallback(
    (
      position: XYCoordinates,
      pointOfInterest: AbstractPointOfInterest | undefined,
      date: Date,
      index: number,
      length: number,
    ) => {
      if (!pointOfInterest) {
        return null
      }

      const isActive = isPoiActive(date)
      const poiPosition =
        index >= Math.floor(length / 2) ? PointOfInterestCardPositions.LEFT : PointOfInterestCardPositions.RIGHT

      return (
        <Fragment>
          {isActive && renderLine(position, height)}
          <ClickablePoint
            position={position}
            height={height}
            active={isActive}
            onClick={() => selectPointOfInterest?.(pointOfInterest, position.x, poiPosition, true)}
            onMouseEnter={(event: React.MouseEvent<SVGGElement>) => {
              if (hoverable && handleTickEnter) {
                handleTickEnter(event, index, pointOfInterest, position.x, poiPosition)
              }
            }}
            onMouseLeave={(event: React.MouseEvent<SVGGElement>) => {
              if (hoverable && handleTickLeave) {
                handleTickLeave(event)
              }
            }}
            data-clickable-point-index={index}
            data-clickable-point-data={JSON.stringify({
              pointOfInterest,
              center: position.x,
              position: poiPosition,
            })}
          />
        </Fragment>
      )
    },
    [isPoiActive, renderLine, height, selectPointOfInterest, hoverable, handleTickEnter, handleTickLeave],
  )

  const hoverablePointPlaceholders = useMemo(() => {
    return chartValues.map(({ data, pointOfInterest, position, section, min, max, current }, index) => {
      const [begin, end] = section
      const isPeak = max || min || current

      return (
        <g key={index} onMouseEnter={(event) => handleTickEnter?.(event, index)} onMouseLeave={handleTickLeave}>
          {renderRect(begin, end)}
          {isPeak && renderHoverPoint(position, min, max, current, false)}
        </g>
      )
    })
  }, [chartValues, handleTickEnter, handleTickLeave, renderHoverPoint, renderRect])

  const hoverablePoints = useMemo(() => {
    if (!hoveredIndex || !chartValues[hoveredIndex]) {
      return hoverablePointPlaceholders
    }

    const { position, section, min, max, current } = chartValues[hoveredIndex]
    const [begin, end] = section
    const points = [...hoverablePointPlaceholders]

    points[hoveredIndex] = (
      <g
        key={hoveredIndex}
        onMouseEnter={(event) => hoverable && handleTickEnter?.(event, hoveredIndex)}
        onMouseLeave={handleTickLeave}
      >
        {renderRect(begin, end)}
        {renderLine(position, height)}
        {renderHoverPoint(position, min, max, current)}
      </g>
    )

    return points
  }, [
    height,
    hoverable,
    chartValues,
    hoveredIndex,
    handleTickLeave,
    handleTickEnter,
    renderRect,
    renderLine,
    renderHoverPoint,
    hoverablePointPlaceholders,
  ])

  // clickable points should be rendered separately in the end of svg to increase their `z-index`
  const clickablePoints = useMemo(() => {
    return chartValues
      .map(({ position, pointOfInterest, data }, index) =>
        renderClickablePoint(position, pointOfInterest, data.date, index, chartValues.length),
      )
      .filter((elementOrNull) => Boolean(elementOrNull)) as JSX.Element[]
  }, [chartValues, renderClickablePoint])

  return {
    hoverablePoints,
    clickablePoints,
  }
}

export { usePoints }
