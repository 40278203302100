import React, { Fragment, lazy, Suspense } from 'react'

import { palette } from 'helpers/palette/'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { SecurityConstituent } from 'components/organisms/SecurityConstituent'

import { type RegionCountries } from '../SingleHolding'

const CountryFlag = lazy(async () => await import(/* webpackChunkName: "CountryFlag" */ 'components/atoms/CountryFlag'))

type CountriesListProps = {
  countries?: RegionCountries
  isLoading: boolean
  showTarget: boolean
  navigateToCountry: (id: number) => void
}

const CountriesList = ({
  countries = [],
  isLoading,
  showTarget,
  navigateToCountry,
}: CountriesListProps): React.ReactElement => {
  const subtitle = (
    <ColumnarLayout mods={{ padding: 'no' }}>
      <Column size={1}></Column>
      <Column size={0}>
        <Skeleton shown={isLoading}>
          <ColumnarLayout mods={{ padding: 'no' }} inline>
            <Column size={0}>
              <Typography tag="span" size={14} lineHeight="small" color="additional">
                <Typo>Actual</Typo>
              </Typography>
            </Column>
            {showTarget && (
              <Column size={0}>
                <Typography tag="span" size={12} lineHeight="small" color="minor">
                  &nbsp;/&nbsp;<Typo>Target</Typo>
                </Typography>
              </Column>
            )}
          </ColumnarLayout>
        </Skeleton>
      </Column>
    </ColumnarLayout>
  )

  const list = countries.map((item) => {
    return (
      <Paper key={item.id} bottom={12}>
        <Skeleton shown={item.skeleton} mix>
          <SelectableCard
            onClick={() => {
              navigateToCountry(item.id)
            }}
          >
            <Paper top={16} bottom={16} left={16} right={16}>
              <SecurityConstituent
                isLoading={isLoading}
                skeleton={item.skeleton}
                logo={
                  <Paper right={4}>
                    <Suspense fallback={<div />}>
                      <CountryFlag countryName={item.name} size={42} />
                    </Suspense>
                  </Paper>
                }
                name={item.name}
                actual={item.actual}
                actualAmount={item.actual_amount}
                target={item.target}
                color={palette.stocks}
                showTarget={showTarget}
                showLogo={true}
              />
            </Paper>
          </SelectableCard>
        </Skeleton>
      </Paper>
    )
  })

  return (
    <Fragment>
      <Paper bottom={16}>{subtitle}</Paper>
      {list}
    </Fragment>
  )
}

export { CountriesList }
