import React from 'react'

import { format as formatMoney } from 'helpers/money'

import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type CashAndAutoinvestInfoProps = {
  cashBalance: number
  isAutoinvestEnabled: boolean
}

const CashAndAutoinvestInfo = ({
  cashBalance,
  isAutoinvestEnabled,
}: CashAndAutoinvestInfoProps): React.ReactElement => {
  const cashNode = (
    <Typography inline size={14} lineHeight="small">
      <Paper top={12} bottom={12} right={8} left={12}>
        <Paper right={4} inline data-test-id="portfolioCashBalance">
          {formatMoney(cashBalance, true)}
        </Paper>
        <Typography inline size={14} lineHeight="small" color="additional">
          Cash
        </Typography>
      </Paper>
    </Typography>
  )

  const autoinvestNode = (
    <Typography inline size={14} lineHeight="small" color="additional">
      <Paper top={12} bottom={12} right={12} left={8}>
        <Paper right={4} inline>
          AutoInvest
        </Paper>
        <Typography color={isAutoinvestEnabled ? 'secondary_default' : 'minor'} size={14} inline lineHeight="small">
          <Typo>{isAutoinvestEnabled ? 'ON' : 'OFF'}</Typo>
        </Typography>
      </Paper>
    </Typography>
  )

  const dividerNode = (
    <Typography inline size={10} lineHeight="small" color="muted">
      |
    </Typography>
  )

  return (
    <Card style={{ display: 'inline-flex', width: 'auto', alignItems: 'center' }}>
      {cashNode}
      {dividerNode}
      {autoinvestNode}
    </Card>
  )
}

export { CashAndAutoinvestInfo }
