import React, { Fragment } from 'react'

import { MobileLayout, MobileLayoutFullViewport } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'

import { MobileFooter } from 'app/containers/Footer'
import { MobileHeader } from 'app/containers/Header'

import { ReportFilters } from '../../components/ReportFilters'
import { Reports } from '../../components/Reports'
import { ReportsHeader, ReportHeaderTabs } from '../../components/ReportsHeader'
import { type ReportListChildProps } from '../ReportList'

const Mobile = (props: ReportListChildProps): React.ReactElement => {
  return (
    <MobileLayout
      header={<MobileHeader pageName="Reports" />}
      content={
        <Fragment>
          <MobileLayoutFullViewport>
            <ReportsHeader activeTabName={ReportHeaderTabs.REPORTS} />
            <Paper top={32}>
              <ReportFilters {...props} />
            </Paper>
            <Paper top={props.hasReports ? 32 : 0} bottom={props.hasReports ? 48 : 0} flex>
              <Reports {...props} />
            </Paper>
          </MobileLayoutFullViewport>
          <MobileFooter noMarginTop />
        </Fragment>
      }
    />
  )
}

export { Mobile }
