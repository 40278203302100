import React from 'react'
import PropTypes from 'prop-types'

import { trackEvent } from 'helpers/analytics'

import { useMediaQueries, useRedirect, useCallback } from 'hooks'
import { useUnit } from 'effector-react'

import Width from 'components/_old/Width/Width'
import Card from 'components/_old/Card/Card.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Button from 'components/_old/Button/Button.jsx'

import { PaymentDetailsForm } from 'app/pages/Forms/PaymentDetailsForm'

import { $isLoading } from 'app/effector/contacts'

const PaymentDetailsSetup = ({ prevStep, owner, changePaymentField, handlePaymentDetailsUpdate }) => {
  const { desktop } = useMediaQueries()
  const isContactsLoading = useUnit($isLoading)

  // if a client has got this page without filling the previous form
  useRedirect({
    to: prevStep?.module,
    rule: !owner.nationalities?.length || !owner.country_of_tax_residence,
    isLoading: isContactsLoading,
  })

  const handleSubmit = useCallback(
    (...args) => {
      trackEvent({
        action: 'nominated_bank_account_submitted',
        client_type: 'INDIVIDUAL',
      })

      handlePaymentDetailsUpdate(...args)
    },
    [handlePaymentDetailsUpdate],
  )

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <div style={{ marginTop: '3em' }}>{submitButton}</div>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  return (
    <Width size={desktop ? 26 : null}>
      <Card
        mods={{
          theme: 'transparent',
          'no-padding': 'top right left',
        }}
      >
        <Text block center>
          <Typo>Please nominate a bank account to be used for top-ups and withdrawals</Typo>
        </Text>
      </Card>
      <Card
        mods={{
          theme: desktop ? null : 'transparent',
          'no-padding': desktop ? null : 'left right',
        }}
      >
        <PaymentDetailsForm
          isRegistration
          submitButton={<Button mods={{ size: 'big block' }}>Continue</Button>}
          wrapButton={wrapSubmitButton}
          inputMods={{ size: 'bigger' }}
          onPaymentDataFieldChange={changePaymentField}
          onSubmit={handleSubmit}
        />
      </Card>
    </Width>
  )
}

PaymentDetailsSetup.propTypes = {
  prevStep: PropTypes.object,
  owner: PropTypes.object,
  changePaymentField: PropTypes.func,
  handlePaymentDetailsUpdate: PropTypes.func,
}

export { PaymentDetailsSetup }
