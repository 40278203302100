import React from 'react'

import Panel from 'components/_old/Panel/Panel.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'

const FeeInfo = () => {
  return (
    <Panel data-test-id="feeInfo">
      <Paper top={16} bottom={16}>
        <Typography size={16} lineHeight="small" weight="semibold" color="inherit" align="center">
          <Typo>Our fee is only 0.25% a year</Typo>
        </Typography>
      </Paper>
    </Panel>
  )
}

export default FeeInfo
