import React from 'react'

import { Analytics } from './Analytics'
import { SharedPortfolio } from './Portfolio'
import { SharedSecurity } from './Security'

const securityRoutes = [
  {
    index: true,
    isNewLayout: true,
    path: '',
    component: function Component(props) {
      return <SharedSecurity {...props} />
    },
  },
  {
    path: 'analytics/:tab/',
    module: 'analytics',
    isNewLayout: true,
    component: function Component(props) {
      return <Analytics {...props} />
    },
  },
]

const childRoutes = [
  {
    index: true,
    isNewLayout: true,
    module: 'portfolio',
    component: function Component(props) {
      return <SharedPortfolio {...props} />
    },
  },
  {
    path: 'security/:securityId/',
    module: 'security',
    childRoutes: securityRoutes,
    indexRoute: securityRoutes.find((route) => route.index),
  },
]

export default {
  module: 'portfolio-sharing',
  path: 'portfolio/:hash/',
  indexRoute: childRoutes.find((route) => route.index),
  childRoutes,
}
