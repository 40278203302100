import React, { Fragment } from 'react'

import { useMemo } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import { Progress } from 'components/_old/Progress/'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { GatewayDest } from 'components/atoms/Gateway'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import HeadlineHelp from 'app/containers/PortfolioTunnel/Tunnel/HeadlineHelp/HeadlineHelp.jsx'

import TextInput from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/components/TextInput.jsx'

import { useTextQuestion } from '../hooks'

const MobileText = ({
  progress,
  question,
  selectedAnswer,
  handleBack,
  handleCancel,
  handleSubmit,
}: MultipleViewProps): React.ReactElement => {
  const { title, description } = question
  const { value, validation, isValid, handleChange, handleSubmitClick } = useTextQuestion({
    initialValue: selectedAnswer,
    handleSubmit,
  })

  const heading = useMemo(
    () => (
      <Paper top={8} bottom={32}>
        <Typography size={20} weight="semibold" align="center">
          <Typo>
            {title}
            {description?.text && (
              <Typography className="Question_Headline_help" tag="span" size={14}>
                <HeadlineHelp
                  iconType="question"
                  background="status-warning"
                  title={description?.title}
                  body={description?.text}
                />
              </Typography>
            )}
          </Typo>
        </Typography>
      </Paper>
    ),
    [title, description],
  )

  const button = useMemo(
    () => (
      <MobileLayoutFooterButton>
        <Button
          type="submit"
          mods={{ size: 'big block', theme: null }}
          onClick={handleSubmitClick}
          disabled={!isValid}
          data-test-id="questionnaireSubmit"
          tabIndex={3}
        >
          Continue
        </Button>
      </MobileLayoutFooterButton>
    ),
    [handleSubmitClick, isValid],
  )

  const input = useMemo(
    () => (
      <SubmitOnEnter>
        <Headline
          level={2}
          mods={{
            text: 'normal center light',
            'no-margin': 'top',
            'line-height': 'bigger',
            overflow: 'visible',
          }}
        >
          <TextInput value={value} validationRule={validation.value} handleChange={handleChange} />
        </Headline>
        <Paper top={8} bottom={32}>
          <GatewayDest className="_newTextQuestion-Error" name="text-question-error" />
        </Paper>
      </SubmitOnEnter>
    ),
    [handleChange, validation, value],
  )

  const content = (
    <Paper bottom={24}>
      {heading}
      {input}
    </Paper>
  )

  return (
    <MobileLayout
      header={
        <Fragment>
          <NavigationBar
            leftPartText="Back"
            onLeftPartClick={handleBack}
            rightPartText="Cancel"
            onRightPartClick={handleCancel}
          >
            Get started
          </NavigationBar>
          <Progress fill={progress} gapWidth="1em" />
        </Fragment>
      }
      content={content}
      footer={button}
    />
  )
}

export { MobileText }
