import React, { Fragment } from 'react'

import { goTo, urlTo } from 'helpers/router.js'

import type { QuickStart } from 'app/effector/quickStart/types'

import type { Preset } from 'app/redux/models/portfolio/types'

import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar, NavigationBarTitleMultipleChildHolder } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { MobileFooter } from 'app/containers/Footer'

import { usePortfolioActions } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'
import { OptionsModal } from 'app/pages/Dashboard/Goals/Options/OptionsModal'

import { ControlButtons } from '../components/ControlButtons'
import { Overview } from '../components/Overview'
import { PortfolioTabs } from '../components/PortfolioTabs'
import { Projections } from '../components/Projections'
import { useManagedPortfolioContent } from '../hooks/useManagedPortfolioContent'
import { type ManagedPortfolio, TabNames } from '../types'

type MobileProps = {
  location: {
    query: {
      view: string
    }
  }
  isPortfolioLoading: boolean
  portfolio: ManagedPortfolio
  goalTitle: string
  showFinishSavingsPlanBanner: boolean
  showLumpSumBanner: boolean
  quickStart?: QuickStart
  regulatoryTypeText: string
  preset: Preset
}

const Mobile = ({
  location,
  isPortfolioLoading,
  portfolio,
  goalTitle,
  showFinishSavingsPlanBanner,
  showLumpSumBanner,
  quickStart,
  regulatoryTypeText,
  preset,
}: MobileProps): React.ReactElement => {
  const {
    visibleTabs,
    activeTabName,
    activeTabIndex,
    handleAmendPortfolioSelect,
    handleSecurityClick,
    navigateByTab,
    portfolioModel,
    allocationData,
    showSecuritiesSkeleton,
  } = useManagedPortfolioContent({
    portfolio,
    location,
  })
  const { actions, actionsMap } = usePortfolioActions(portfolio, handleAmendPortfolioSelect, visibleTabs.length > 1)
  const isDefaultPortfolio = portfolio?.is_default_preset_used

  const headerNode = (
    <NavigationBar
      leftPartText="Back"
      onLeftPartClick={() => {
        goTo(urlTo('dashboard'))
      }}
    >
      <NavigationBarTitleMultipleChildHolder>
        <Typography color="inherit" align="center" lineHeight="small" weight="semibold">
          {goalTitle}
        </Typography>
        <Paper top={1} bottom={1}>
          <Typography
            size={12}
            weight="semibold"
            color="on_color_default"
            align="center"
            lineHeight="small"
            className="ManagedNavigationBar-TypeSubtitle"
            inline
          >
            {isDefaultPortfolio ? 'Retirement Portfolio by IE' : `Managed by InvestEngine, ${regulatoryTypeText}`}
          </Typography>
        </Paper>
      </NavigationBarTitleMultipleChildHolder>
    </NavigationBar>
  )

  const contentNode = (
    <div>
      {visibleTabs.length > 1 && (
        <PortfolioTabs tabItems={visibleTabs} activeTabIndex={activeTabIndex} navigateByTab={navigateByTab} />
      )}
      {activeTabName === TabNames.OVERVIEW && (
        <Overview
          isPortfolioLoading={isPortfolioLoading}
          portfolio={portfolio}
          onSecurityClick={handleSecurityClick}
          location={location}
          actionsMap={actionsMap}
          showFinishSavingsPlanBanner={showFinishSavingsPlanBanner}
          showLumpSumBanner={showLumpSumBanner}
          quickStart={quickStart}
          allocationData={allocationData}
          showSecuritiesSkeleton={showSecuritiesSkeleton}
          preset={preset}
        />
      )}
      {activeTabName === TabNames.PROJECTIONS && (
        <Fragment>
          <Projections portfolio={portfolio} handleAmendPortfolioSelect={handleAmendPortfolioSelect} />
        </Fragment>
      )}
      <MobileFooter />
    </div>
  )

  return (
    <Fragment>
      <MobileLayout
        header={headerNode}
        content={contentNode}
        footer={
          activeTabName === TabNames.OVERVIEW ? <ControlButtons portfolio={portfolio} actionsMap={actionsMap} /> : null
        }
      />
      <OptionsModal portfolio={portfolioModel} portfolioActions={actions} />
    </Fragment>
  )
}

export { Mobile }
