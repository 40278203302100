import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

type TermsDisagreementModalProps = {
  isOpen: boolean
  onClose: () => void
}

const TermsDisagreementModal = ({ isOpen, onClose: handleClose }: TermsDisagreementModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const buttons = (
    <Link to="https://investengine.com/managed/" hard blank>
      <Button mods={{ size: 'big block' }}>Take a look at managed portfolios</Button>
    </Link>
  )

  const content = (
    <Fragment>
      <Paper bottom={40}>
        <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
          <Typo>Unfortunately we cannot offer you this portfolio</Typo>
        </Typography>
      </Paper>
      <Paper bottom={32}>
        <Paper bottom={8}>
          <Typography size={16} weight="semibold">
            <Typo>Why can’t I proceed with LifePlan?</Typo>
          </Typography>
        </Paper>
        <Typography size={14}>
          <Typo>
            We recommend seeking additional guidance to ensure any investment aligns with your goals, as you told us
            that one or more of the following applies: You have limited investment knowledge and experience Your
            financial situation, risk capacity, or timeline may not align with this investment You are uncertain about
            your investment objectives and risk tolerance
          </Typo>
        </Typography>
      </Paper>
      <Paper bottom={32}>
        <Paper bottom={8}>
          <Typography size={16} weight="semibold">
            <Typo>Looking for further guidance?</Typo>
          </Typography>
        </Paper>
        <Typography size={14}>
          <Typo>
            Try our Managed Portfolio guided route, designed for those unsure about their options. It offers extra
            guidance and education, with additional questions about your financial situation, risk tolerance, and
            capacity for loss to help you make a decision.
          </Typo>
        </Typography>
      </Paper>
      <Paper>
        <Paper bottom={8}>
          <Typography size={16} weight="semibold">
            <Typo>Need advice?</Typo>
          </Typography>
        </Paper>
        <Typography size={14}>
          <Typo>
            We don’t provide personal advice. If you’re uncertain, consider contacting an Independent Financial Adviser.
            We’re not affiliated with any advisors; you can use this{' '}
            <Link to="https://www.financiable.co.uk/unbiased/" hard blank underline mods={{ color: 'black' }}>
              <Typo>link to Unbiased Financial Advisors</Typo>
            </Link>
          </Typo>{' '}
          to find support.
        </Typography>
      </Paper>
    </Fragment>
  )

  if (desktop) {
    return (
      <Modal open={isOpen} onClose={handleClose}>
        <ModalContent width={40} center>
          <Paper top={80} bottom={80} left={64} right={64}>
            {content}
            <Paper top={56}>{buttons}</Paper>
          </Paper>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Fragment>
      <Modal open={isOpen} onClose={handleClose}>
        <MobileLayout
          header={<NavigationBar onLeftPartClick={handleClose} leftPartText="Back" />}
          content={
            <Paper top={12} bottom={48}>
              {content}
            </Paper>
          }
          footer={<MobileLayoutFooterButton>{buttons}</MobileLayoutFooterButton>}
        />
      </Modal>
    </Fragment>
  )
}

export { TermsDisagreementModal }
