import isObject from 'lodash/isObject'

import legacyDark from './legacyDark.js'
import legacyLight from './legacyLight.js'
import { isDarkTheme } from './theme'

type Palette = typeof legacyLight | typeof legacyDark

function getPaletteWithCssVariablesAsValues(lightPalette: Palette, darkPalette: Palette): Palette {
  const joinedPalette: Palette = {
    ...lightPalette,
    ...darkPalette,
  }

  function recursivelyReplaceValueAsCssVariableName(paletteObject: object, keys: string[] = []): any {
    const entries = Object.entries(paletteObject)
    const processed = Object.fromEntries(
      entries.map(([key, value]) => {
        if (isObject(value)) {
          return recursivelyReplaceValueAsCssVariableName(value, [...keys, key])
        }

        return [key, `var(--${[...keys, key].join('-')})`]
      }),
    )

    if (keys.length > 0) {
      return [keys.at(-1), processed]
    }

    return processed
  }

  return recursivelyReplaceValueAsCssVariableName(joinedPalette)
}

const palette: Palette = getPaletteWithCssVariablesAsValues(legacyLight, legacyDark)

const plainPalette: Palette = {
  ...legacyLight,
  ...legacyDark,
}

const palettePlainValues: Palette = new Proxy(plainPalette, {
  get(target: Palette, prop: string) {
    const isDark = isDarkTheme()

    if (isDark && legacyDark[prop] !== 'undefined') {
      return legacyDark[prop]
    }

    if (typeof legacyLight[prop] !== 'undefined') {
      return legacyLight[prop]
    }
  },
})

export { palette, palettePlainValues, type Palette }
