import React, { Fragment, useCallback } from 'react'

import isFunction from 'lodash/isFunction'

import { useActions, useEffect, useMediaQueries, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router'
// import { palette } from 'helpers/palette/'

// import { BadgeNew } from 'components/atoms/BadgeNew'
import { fetchPresets as fetchPresetsActionCreator } from 'app/redux/actions/portfolios'

import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { TunnelHeader } from 'app/pages/Securities/components'

import { PresetCard } from '../components/PresetCard'
import { SelfSelectedStories } from '../components/SelfSelectedStories'

import { LifePlanBanner } from './components/LifePlanBanner'
import { LifePlanDescription } from './components/LifePlansDescription'

import { manageTypes } from 'constants/portfolio'

type SelfSelectedPresetsProps = {
  location: {
    query: {
      fromDashboard?: string
    }
  }
}

const SelfSelectedPresets = ({ location }: SelfSelectedPresetsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { presets } = useSelector((state: any) => state.portfolios)

  const [fetchPresets] = useActions([fetchPresetsActionCreator])

  const { fromDashboard, ...restQuery } = location.query
  const isTunnel = fromDashboard === 'true'

  useEffect(() => {
    fetchPresets(manageTypes.SELF_SELECTED, { setNotValidBefore: false, setValidAfter: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPresetLink = useCallback(
    (id): string | null => {
      return urlTo('self-selected-presets.preset', { id }, { ...location.query })
    },
    [location.query],
  )

  const handleBack = (): void => {
    if (isTunnel) {
      goTo(urlTo('create-new-portfolio', {}, { ...restQuery }))
      return
    }
    goTo(urlTo('securities'))
  }

  const handleGoToPreset = (id: string, title: string): void => {
    trackEvent({
      action: 'lp_portfolio_selected',
      manage_type: 'SELF_SELECTED',
      preset: title,
    })

    goTo(getPresetLink(id))
  }

  const handleClick = (event, func): void => {
    const isOpenInNewTab = event?.ctrlKey || event?.metaKey

    if (event && !isOpenInNewTab) {
      event.preventDefault()
    }

    if (!isOpenInNewTab && isFunction(func)) {
      func()
    }
  }

  const navbarNode = (
    <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
      <Typo>&nbsp</Typo>
    </NavigationBar>
  )

  const content = (
    <Width size={36} center>
      <Paper top={!desktop ? 16 : 0}>
        <LifePlanBanner />
      </Paper>
      <Paper top={40} bottom={desktop ? 0 : 80}>
        <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
          <Column size={1}>
            <Typography size={48} weight="semibold" lineHeight="small">
              <Typo>LifePlan</Typo>
            </Typography>
          </Column>
          {/* <Column size={0}> */}
          {/*   <BadgeNew */}
          {/*     text="NEW" */}
          {/*     color={palette['content-on-color-white']} */}
          {/*     backgroundColor={palette['self-selected-default']} */}
          {/*   /> */}
          {/* </Column> */}
        </ColumnarLayout>
        <Paper top={24}>
          <LifePlanDescription />
        </Paper>
        <Paper top={32}>
          <SelfSelectedStories />
        </Paper>
        <Paper top={desktop ? 48 : 40}>
          <Typography size={32} weight="semibold" lineHeight="small">
            <Typo>Portfolios</Typo>
          </Typography>
        </Paper>
        {presets[manageTypes.SELF_SELECTED]?.map((preset) => (
          <Link
            key={preset.id}
            to={getPresetLink(preset.id)}
            onClick={(event) => {
              handleClick(event, () => {
                handleGoToPreset(preset.id, preset.title)
              })
            }}
          >
            <Paper top={desktop ? 24 : 16}>
              <PresetCard preset={preset} />
            </Paper>
          </Link>
        ))}
      </Paper>
    </Width>
  )

  return (
    <Fragment>
      {desktop ? (
        <DesktopLayout
          header={
            isTunnel ? (
              <Inner twoColumns>
                <TunnelHeader onBack={handleBack} />
              </Inner>
            ) : (
              <DesktopHeader />
            )
          }
          content={
            <Inner twoColumns>
              {!isTunnel && navbarNode}
              <Paper top={isTunnel ? 0 : 40} bottom={isTunnel ? 80 : 0}>
                {content}
              </Paper>
            </Inner>
          }
          footer={!isTunnel && <DesktopFooter />}
        />
      ) : (
        <MobileLayout header={navbarNode} content={content} contentPaperSizes={{ top: 0 }} />
      )}

      <GatewayDest name="modals" />
    </Fragment>
  )
}

export { SelfSelectedPresets }
