import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import './Hr.css'

const Hr = ({ className, color, relative }) => {
  const classes = classNames(className, 'Hr', {
    [`Hr_${color}`]: color,
    Hr_relative: relative,
  })

  return <hr className={classes} />
}

Hr.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  relative: PropTypes.bool,
}

export { Hr }
