enum DocName {
  ID = 'ID',
  SELFIE = 'SELFIE',
  BANK_STATEMENT = 'BANK_STATEMENT',
  ISA_FORM = 'ISA_FORM',
  OTHER = 'OTHER',
  BILL = 'BILL',
  SOURCE_OF_WEALTH = 'SOURCE_OF_WEALTH',
}

enum DocSet {
  ID = 'ID',
  BANK_STATEMENT = 'BANK_STATEMENT',
  ISA_FORM = 'ISA_FORM',
  OTHER = 'OTHER',
  BILL = 'BILL',
  SOURCE_OF_WEALTH = 'SOURCE_OF_WEALTH',
}

type Document = {
  type: DocName
  title: string
  subtitle: string
  icon: string
}

export { DocName, DocSet, type Document }
