/* eslint-disable react/no-string-refs */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import compose from 'helpers/compose.js'
import { urlTo, goTo } from 'helpers/router.js'
import rawMediaQueries from 'helpers/mediaQueries.js'
import { combineErrors, backendErrorsToObj, bulkValidate, emailRules } from 'helpers/validation.js'
import { sendError } from 'helpers/errorLogging.js'
import { ValidationError } from 'helpers/errors/'
import { trackEvent } from 'helpers/analytics/'

import withMediaQueries from 'decorators/withMediaQueries/withMediaQueries.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import HeadlineWBacklink from 'components/_old/HeadlineWBacklink/HeadlineWBacklink.jsx'
import ContentHolder from 'components/molecules/ContentHolder/ContentHolder.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { Typography } from 'components/atoms/Typography/'
import Text from 'components/_old/Text/Text.jsx'
import { Paper } from 'components/atoms/Paper/'
import Card from 'components/_old/Card/Card.jsx'
import Width from 'components/_old/Width/Width'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import { Gateway } from 'components/atoms/Gateway'

import { changeField, updateOrCreate, resetError, resendEmail, setValid, setNotValid } from 'app/redux/actions/client'
import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'

import { $owner, updateOrCreateContactFx } from 'app/effector/contacts'
import { ApiError } from 'app/redux/models/errors'

class VerifyEmailForm extends React.Component {
  state = {
    change: false,
    email: null,
  }

  enableChange = (email) => {
    this.setState({ change: true, email })
  }

  disableChange = () => {
    this.setState({ change: false, email: null })
  }

  close = () => {
    this.disableChange()

    goTo(urlTo('dashboard'))
  }

  resendEmail = ({ email = null, showSuccessToast: isShowSuccessToast = true, closeAfter = true } = {}) => {
    const { resendEmail } = this.props

    return resendEmail({ email, setValidAfter: false })
      .then(
        () => {
          if (isShowSuccessToast) {
            showSuccessToast('New email was sent to your email address')
          }
        },
        (error) => {
          sendError(error)
          showFailToast()
        },
      )
      .then(() => {
        if (closeAfter) {
          this.close()
        }
      })
  }

  handleInput = (_event, value) => {
    const { resetError } = this.props
    resetError()
    this.setState({ email: value })
  }

  handleResendVerifyEmail = async () => {
    const { client, setValid } = this.props

    trackEvent({ action: 'resend_verify_email', client_type: client.type })
    await this.resendEmail()

    setValid()
  }

  handleSaveEmail = async () => {
    const { changeField, owner } = this.props
    const { id } = owner

    try {
      changeField({ email: this.state.email })

      const result = await updateOrCreateContactFx({ data: { email: this.state.email }, id })

      if (result instanceof ApiError) {
        throw result
      }

      goTo(urlTo('dashboard'))
      showSuccessToast('Your email address was changed')
    } catch (error) {
      if (!(error instanceof ValidationError)) {
        sendError(error)
      }

      showFailToast()
    }
  }

  render() {
    const { client, owner, mediaQueries, route } = this.props
    const { name } = route
    const { error } = client
    const { desktop } = mediaQueries
    const { change, email: stateEmail } = this.state
    const ownerEmail = owner?.email ?? client?.email ?? ''
    const formattedOwnerEmail = ownerEmail
      .split('@')
      .map((part, index) => (
        <span key={index} style={{ display: 'inline-block', wordBreak: 'break-word' }}>{`${
          index === 1 ? '@' : ''
        }${part}`}</span>
      ))

    const validation = combineErrors({ email: emailRules(stateEmail) }, backendErrorsToObj(error))

    const submitButton = (() => {
      const button = (
        <Button
          mods={{
            size: 'big block',
          }}
          disabled={change ? !bulkValidate(validation) : !ownerEmail}
          onClick={change ? this.handleSaveEmail : this.handleResendVerifyEmail}
          data-test-id="verifyEmailSubmitButton"
        >
          {change ? 'Save сhanges' : 'Resend email'}
        </Button>
      )

      if (desktop) {
        return (
          <Card mods={{ theme: 'transparent', 'no-padding': `left right top` }}>
            <Width center size={14}>
              {button}
            </Width>
          </Card>
        )
      }

      return <Gateway into="verify-email-mobile-footer">{button}</Gateway>
    })()

    return (
      <Inner className="Layout-Holder">
        <HeadlineWBacklink back={urlTo('dashboard')} backLinkText="My Dashboard">
          {change ? 'Change email address' : name}
        </HeadlineWBacklink>
        <Paper bottom={change ? 24 : 48}>
          <Width size={desktop ? 28 : null} center>
            <Text block center={change}>
              <Typo>
                {change ? (
                  <span>Type your new email address below</span>
                ) : (
                  <span>
                    We have automatically sent you a verification email request to the address you provided below.
                    Please open the email and follow the instructions to proceed. If you are missing the email, please
                    check your spam and junk folders or have it resent.
                  </span>
                )}
              </Typo>
            </Text>
          </Width>
        </Paper>
        <ContentHolder>
          <SubmitOnEnter>
            <Width size={!desktop || change ? 30 : null} center>
              <Width size={!desktop || change ? 28 : null} center>
                <Paper bottom={48}>
                  <Label>
                    {change ? (
                      <Validate rules={validation.email.rules}>
                        <Label errorMessages={validation.email.errors}>
                          &nbsp;
                          <Input
                            name="email"
                            ref="input"
                            type="email"
                            mods={{ size: 'bigger' }}
                            onChange={this.handleInput}
                            tabIndex={1}
                            data-test-id="emailChangeInput"
                          >
                            {stateEmail}
                          </Input>
                        </Label>
                      </Validate>
                    ) : (
                      <Fragment>
                        <Paper top={desktop ? 4 : 0} bottom={desktop ? 4 : 0}>
                          <Typography size={18} align="center">
                            <Typo>{formattedOwnerEmail}</Typo>
                            <Typography tag={desktop ? 'span' : 'div'} size={14} align="center">
                              <Paper top={desktop ? 0 : 4} left={desktop ? 16 : 0} inline={desktop}>
                                <Link onClick={() => this.enableChange(ownerEmail)} data-test-id="changeEmailLink">
                                  <Typo>Change</Typo>
                                </Link>
                              </Paper>
                            </Typography>
                          </Typography>
                        </Paper>
                      </Fragment>
                    )}
                  </Label>
                </Paper>
                {submitButton}
              </Width>
            </Width>
          </SubmitOnEnter>
        </ContentHolder>
      </Inner>
    )
  }
}

VerifyEmailForm.propTypes = {
  client: PropTypes.object,
  owner: PropTypes.object,
  mediaQueries: PropTypes.object,
  route: PropTypes.object,

  changeField: PropTypes.func,
  resetError: PropTypes.func,
  resendEmail: PropTypes.func,
  setValid: PropTypes.func,
}

export default compose(
  withMediaQueries(rawMediaQueries),
  connect((state) => ({ client: state.client, owner: $owner.getState() }), {
    changeField,
    updateOrCreate,
    resetError,
    resendEmail,
    setValid,
    setNotValid,
  }),
)(VerifyEmailForm)
