const querystringFromObject = (object: object = {}): string => {
  if (typeof object !== 'object') {
    return ''
  }

  const searchParams = new URLSearchParams(
    Object.entries(object).reduce((entries: any[], [key, value]: [key: string, value: any]) => {
      if (!String(value ?? '').length) {
        return entries
      }

      if (typeof value === 'boolean') {
        return value ? [...entries, [key, '']] : entries
      }

      if (Array.isArray(value)) {
        return [...entries, ...value.map((value) => [key, value])]
      }

      return [...entries, [key, value]]
    }, []),
  )

  const querystring = searchParams.toString().replace(/=(&|$)/g, (text) => (text === '=&' ? '&' : ''))

  return querystring && '?' + querystring
}

const querystringToObject = (querystring: string): Record<string, string | number | boolean> => {
  if (!querystring || querystring.length < 1) {
    return {}
  }

  const entries: Array<[string, boolean] | [string, number] | [string, string]> = querystring
    .split(/[?&]/)
    .map((keyValueString) => {
      const [key, value] = keyValueString.split('=')

      if (!key || !value) {
        return null
      }

      if (value === 'false') {
        return [key, false]
      }

      if (value === 'true') {
        return [key, true]
      }

      if (!Number.isNaN(parseFloat(value))) {
        return [key, parseFloat(value)]
      }

      return [key, value]
    })
    .filter((keyValueArray) => !!keyValueArray)

  if (!entries ?? entries.length < 1) {
    return {}
  }

  return Object.fromEntries(entries)
}

export { querystringFromObject, querystringToObject }
