import { createStore, createEffect, combine } from 'effector'

import { ApiError } from 'app/redux/models/errors'

import * as api from './api'

import type { DictsOptions } from './types'

// Effects
const fetchOptionsFx = createEffect(async () => {
  try {
    const data = await api.fetchOptions()

    if (data instanceof ApiError) throw data

    return data
  } catch (error) {}
})

// Stores
const $dicts = createStore<DictsOptions>({})

$dicts.on(fetchOptionsFx.doneData, (state, options) => {
  if (!options) return state
  return {
    ...state,
    ...options,
  }
})

const $isPromoEnabled = $dicts.map((dicts) => dicts?.active_promos?.includes?.('25-friend-promo'), { skipVoid: false })
const $isRefferalEnabled = $dicts.map((dicts) => dicts?.active_promos?.includes?.('50-pound-promo'), {
  skipVoid: false,
})
const $initialDepositMin = $dicts.map((dicts) => parseFloat(dicts.initial_deposit_min ?? '0'))

const $dictsStore = combine({
  dicts: $dicts,
  isLoading: fetchOptionsFx.pending,
  isPromoEnabled: $isPromoEnabled,
  isRefferalEnabled: $isRefferalEnabled,
  initialDepositMin: $initialDepositMin,
})

export { $dictsStore, fetchOptionsFx }
