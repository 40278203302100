import React, { useContext, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import isFunction from 'lodash/isFunction'
import CSSTransitionGroup from 'react-addons-css-transition-group'
import { GlobalPreloaderContext } from 'components/_old/GlobalPreloader/GlobalPreloader.jsx'

import { palette, palettePlainValues } from 'helpers/palette'

import { useClickOutside, useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Card from 'components/_old/Card/Card.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'

import './Dropdown.css'

const LinkWithChevron = ({ text, onClick: handleClick, 'data-test-id': dataTestId }) => (
  <Link className="Dropdown-Link" onClick={handleClick} data-test-id={dataTestId}>
    <Text small>{text}</Text>
    <Icon className="Dropdown-Icon" type="chevron" inline size={16} color={palette['content-on-background-primary']} />
  </Link>
)

const Dropdown = ({
  children,
  className,
  text,
  mode,
  buttonSize,
  withChevron = true,
  preventCloseOnContentClick = false,
  'data-test-id': dataTestId,
}) => {
  const isGlobalPreloaderShown = useContext(GlobalPreloaderContext)
  const [visible, setVisible] = useState(false)
  const [rootRef, hasClickedOutside] = useClickOutside()
  const mediaQueries = useMediaQueries()

  const handleOpen = useCallback(() => {
    setVisible(true)
  }, [setVisible])

  const handleClose = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const handleContentClick = useCallback(() => {
    if (!preventCloseOnContentClick) {
      setVisible(false)
    }
  }, [preventCloseOnContentClick, setVisible])

  useEffect(() => {
    if (hasClickedOutside) {
      handleClose()
    }
  }, [hasClickedOutside, handleClose])

  const classes = classNames(className, 'Dropdown', getMediaQuieryClasses('Dropdown', mediaQueries))

  const child = isFunction(children) ? children(handleClose) : children

  return (
    <span className={classes} ref={rootRef}>
      <CSSTransitionGroup
        transitionName={{
          enter: 'Dropdown_enter',
          enterActive: 'Dropdown_enter_active',
          leave: 'Dropdown_leave',
          leaveActive: 'Dropdown_leave_active',
        }}
        transitionEnterTimeout={palettePlainValues.animation.speed.number.default}
        transitionLeaveTimeout={palettePlainValues.animation.speed.number.default}
        transitionEnter={!isGlobalPreloaderShown}
        transitionLeave={!isGlobalPreloaderShown}
      >
        {mode === 'button' && (
          <Button
            className="Dropdown-Button"
            mods={{ theme: 'simple-reverse-blue', size: buttonSize }}
            onClick={handleOpen}
            data-test-id={dataTestId}
          >
            {text}
            {withChevron && (
              <Icon
                className="Dropdown-Icon"
                type="chevron"
                inline
                size={16}
                color={palette['content-on-background-primary']}
              />
            )}
          </Button>
        )}
        {mode === 'link' && <LinkWithChevron text={text} data-test-id={dataTestId} onClick={handleOpen} />}
        {visible && (
          <div className="Dropdown-Content" onClick={handleContentClick} data-test-id="DropdownContent">
            <Card mods={{ theme: 'white straight-corners no-border', 'no-padding': 'bottom' }}>
              <LinkWithChevron text={text} onClick={handleClose} />
            </Card>
            {child}
          </div>
        )}
      </CSSTransitionGroup>
    </span>
  )
}

Dropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.func]),
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  mode: PropTypes.string,
  buttonSize: PropTypes.string,
  withChevron: PropTypes.bool,
  preventCloseOnContentClick: PropTypes.bool,
  'data-test-id': PropTypes.string,
}

export default Dropdown
