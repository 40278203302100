import { nullable, type Nullable, decimal } from 'app/effector/model'

import { type StatsPeriodDTO } from '../api'

class StatsPeriod {
  readonly return_money: Nullable<string>

  constructor(dto: Partial<StatsPeriodDTO> | Partial<StatsPeriod>) {
    this.return_money = nullable(decimal)(dto.return_money)
  }
}

export { StatsPeriod }
