import React from 'react'

import { useActions, useCallback } from 'hooks'

import { GP_DEFAULT, GP_WORKING_FAKE } from 'constants/globalPreloaderStatuses.js'

import {
  fetchPortfolios as fetchPortfoliosActionCreator,
  setValid as setPortfoliosValidActionCreator,
} from 'app/redux/actions/portfolios'

import {
  changeField as changeUiFieldActionCreator,
  setNotValid as setUiNotValidActionCreator,
  setValid as setUiValidActionCreator,
} from 'app/redux/actions/ui'

import { Redirect } from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/QuestionWrappers/index.js'

const isDev = process.env.NODE_ENV !== 'production'

const LastStep = (props) => {
  const [fetchPortfolios, setPortfoliosValid, changeUiField, setUiNotValid, setUiValid] = useActions([
    fetchPortfoliosActionCreator,
    setPortfoliosValidActionCreator,
    changeUiFieldActionCreator,
    setUiNotValidActionCreator,
    setUiValidActionCreator,
  ])

  const handleOnAfterSave = useCallback(async () => {
    const afterFetchGoals = await fetchPortfolios({ setValidAfter: false })

    if (afterFetchGoals.portfolios.error) {
      throw afterFetchGoals.portfolios.error
    }

    setPortfoliosValid()
    changeUiField({ globalPreloaderStatus: GP_WORKING_FAKE })
    setUiNotValid()

    await new Promise((resolve) => {
      setTimeout(
        () => {
          changeUiField({ globalPreloaderStatus: GP_DEFAULT })
          setUiValid()
          resolve()
        },
        isDev ? 100 : 4000,
      )
    })
  }, [])

  return <Redirect {...props} onAfterSave={handleOnAfterSave} />
}

export { LastStep }
