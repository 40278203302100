import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Text from 'components/_old/Text/Text.jsx'

import './TodayPointer.css'

const TodayPointer = ({ className }) => {
  const mediaQueries = useMediaQueries()
  const classes = classNames(className, 'TodayPointer', getMediaQuieryClasses('TodayPointer', mediaQueries))

  return (
    <div className={classes}>
      <Text className="TodayPointer-Text" color="white" smaller>
        Today
      </Text>
    </div>
  )
}

TodayPointer.propTypes = {
  className: PropTypes.string,
}

export { TodayPointer }
