import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import Icon from 'components/_old/Icon/Icon.jsx'

import './Notation.css'

const Notation = ({ text, iconType, iconColor, 'data-test-id': dataTestId }) => {
  const { desktop } = useMediaQueries()

  return (
    <Typography size={desktop ? 18 : 16} lineHeight={desktop ? 'small' : 'medium'} align="center">
      <Icon
        className="Notation-Icon"
        color={iconColor}
        type={iconType}
        size={24}
        inline
        data-test-id={`${dataTestId}ExclamationIcon`}
      />
      <Typo>{text}</Typo>
    </Typography>
  )
}

Notation.propTypes = {
  text: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  'data-test-id': PropTypes.string,
}

export { Notation }
