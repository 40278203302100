import { attach, createEffect } from 'effector'

import { analyticsCIO } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router'

import { createSippTransferRequestFx, type SippTransferRequest } from 'app/effector/nominees-transfers/request/sipp'

import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'
import store from 'app/redux/store/store'

import { type PageUrl } from '../index'

// Effects
const navigateToUrlFx = createEffect((pageUrl: PageUrl): void => {
  goTo(pageUrl ?? urlTo('dashboard'))
})

const submitFx = attach({ effect: createSippTransferRequestFx })

const showSuccessToastFx = createEffect(() => {
  showSuccessToast('Your transfer request has been submitted successfully')
})

const showFailToastFx = createEffect(() => {
  showFailToast()
})

const sendAnalyticsEventFx = createEffect((request: SippTransferRequest) => {
  const client = store.getState().client

  analyticsCIO.identify(client.email, {
    waiting_for_sipp_transfers: true,
    sipp_transfer_plan_number: request.plan_number,
    sipp_transfer_amount: request.amount,
    sipp_transfer_type: request.part_type,
    sipp_transfer_assets_type: request.asset_transfer_type,
    sipp_transfer_agreed_with_terms: Date.now(),
  })
})

export { navigateToUrlFx, submitFx, showSuccessToastFx, showFailToastFx, sendAnalyticsEventFx }
