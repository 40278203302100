import { sendError } from 'helpers/errorLogging'

function string(unknown: unknown): string {
  if (typeof unknown === 'string') {
    return unknown
  }

  if (typeof unknown === 'number' || typeof unknown === 'bigint') {
    return `${unknown}`
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  sendError(new Error(`Cannot convert ${unknown} to string`))

  return ''
}

export { string }
