import React from 'react'

import './BadgeNew.css'

type BadgeNewProps = {
  text: string
  color: string
  backgroundColor: string
}

const BadgeNew = ({ text, color, backgroundColor }: BadgeNewProps): React.ReactElement => {
  return (
    <div className="badge-new">
      <div className="badge-new-background" style={{ backgroundColor }}></div>
      <span className="badge-new-text" style={{ color }}>
        {text}
      </span>
    </div>
  )
}

export { BadgeNew }
