import type React from 'react'

import { useReports, type UseReportsInterface } from '../hooks'

type ReportsRootProps = {
  children: (props: UseReportsInterface) => React.ReactElement
}

function ReportsRoot({ children }: ReportsRootProps): React.ReactElement {
  const reportsProps = useReports()

  return children(reportsProps)
}

export { ReportsRoot, type ReportsRootProps }
