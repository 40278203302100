import React from 'react'

import { Login } from './Login'
import { TwoFactorAuth } from './TwoFactorAuth'
import { ForgotPassword } from './ForgotPassword'
import { SetNewPassword } from './SetNewPassword.jsx'
import { Captcha } from './Captcha.jsx'
import { Logout } from './Logout'

const routes = [
  {
    module: 'login',
    path: 'login/',
    name: 'Login',
    headline: 'Login',
    isNewLayout: true,
    noGlobalPreloader: true,
    component: function Component(props) {
      return <Login {...props} />
    },
  },
  {
    module: '2fa',
    path: 'login/2fa/',
    name: 'Two Factor Auth',
    isNewLayout: true,
    component: function Component(props) {
      return <TwoFactorAuth {...props} />
    },
  },
  {
    module: 'forgot-password',
    path: 'forgot-password/',
    name: 'Forgot password',
    isNewLayout: true,
    component: function Component(props) {
      return <ForgotPassword {...props} />
    },
  },
  // moved from children to solve problem with button
  {
    module: 'set-new-password',
    path: 'forgot-password/set-new/:uid/:token/',
    name: 'Set new password',
    isNewLayout: true,
    component: function Component(props) {
      return <SetNewPassword {...props} />
    },
  },
  {
    module: 'captcha',
    path: 'protection/',
    name: 'Protection',
    headline: 'Protection',
    isNewLayout: true,
    component: function Component(props) {
      return <Captcha {...props} />
    },
  },
  {
    module: 'logout',
    path: 'logout/',
    component: function Component(props) {
      return <Logout {...props} />
    },
  },
]

export default routes
