import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { bindActionCreators } from 'redux'

/**
 * @template T
 * @param {T} actions
 * @param {any[]=} deps
 * @return {T}
 */
const useActions = (actions, deps = null) => {
  const dispatch = useDispatch()

  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [actions, dispatch, ...deps] : [actions, dispatch],
  )
}

export default useActions
