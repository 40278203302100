import React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import isNumber from 'lodash/isNumber'

import { useMemo, useMediaQueries } from 'hooks'

import { sendError } from 'helpers/errorLogging.js'

import { Typography } from 'components/atoms/Typography'

import './NumeralAxis.css'

type NumeralAxisProps = {
  className?: string
  min?: number
  max: number
  quantity?: number
  format?: (rawValue: number) => string
  right?: boolean | { min?: number; max?: number }
  fretless?: boolean
}

const NumeralAxis = ({
  className,
  min = 0,
  max,
  quantity = 5,
  format,
  right = false,
  fretless = false,
}: NumeralAxisProps): React.ReactElement | null => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const classes = classNames(
    className,
    'NumeralAxis',
    { NumeralAxis_right: right },
    { NumeralAxis_fretless: fretless },
    getMediaQuieryClasses('NumeralAxis', mediaQueries),
  )
  const ticks = useMemo(() => {
    if (quantity > 2) {
      const tickSize = Math.abs(max - min) / (quantity - 1)

      return new Array(quantity).fill(null).map((_element, index) => min + tickSize * index)
    }

    if (quantity === 2) {
      return [min, max]
    }

    return [min]
  }, [min, max, quantity])

  if (typeof max !== 'number') {
    sendError(`No 'max' prop provided to NumeralAxis`)

    return null
  }

  if (quantity < 1) {
    return null
  }

  return (
    <div className={classes}>
      {React.Children.map(ticks, (tick, index) => {
        let style: React.CSSProperties | undefined

        if (index === 0 && typeof right !== 'boolean' && right?.min) {
          style = { width: right.min }
        }

        if (index === ticks.length - 1 && typeof right !== 'boolean' && right?.max) {
          style = { width: right.max }
        }

        return (
          <div className="NumeralAxis-Tick" style={style}>
            <div className="NumeralAxis-TextHolder">
              <Typography
                className="NumeralAxis-Text"
                size={right ? (desktop ? 14 : 10) : desktop ? 12 : 10}
                weight={desktop ? 'regular' : 'semibold'}
                lineHeight="small"
                numeric="tabular"
                color="minor"
                inline
                data-test-id="chartNumeralAxisLabel"
              >
                {format && isNumber(tick) ? format(tick) : tick}
              </Typography>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export { NumeralAxis }
