import { useCallback, useState } from 'react'

const enum MemorizedStateStorages {
  LOCAL = 'localStorage',
  SESSION = 'sessionStorage',
}

const PREFIX = '@@MemorizedState'

const useMemorizedState = <Type>(
  name: string,
  initialValue: Type,
  options: {
    rewriteWithInitial?: boolean
    parse?: (string: string) => Type
    storage?: MemorizedStateStorages
  } = {},
): [Type, (Type) => void] => {
  if (!name) {
    throw Error(`No name passed to 'useMemorizedState': ${name}`)
  }

  const storage: MemorizedStateStorages = options.storage ?? MemorizedStateStorages.SESSION
  const parse: (string: string) => Type = options.parse ?? JSON.parse
  const storageName = `${PREFIX}/${name}`

  if (options.rewriteWithInitial) {
    window[storage].removeItem(storageName)
  }

  const storageValue = window[storage].getItem(storageName)
  const memoizedValue = storageValue ? parse(storageValue) : null

  const [value, setStateValue] = useState<Type>(memoizedValue ?? initialValue)

  if (value) {
    window[storage].setItem(storageName, JSON.stringify(value))
  }

  const setValue = useCallback(
    (value) => {
      window[storage].setItem(storageName, JSON.stringify(value))
      setStateValue(value)
    },
    [storage, storageName],
  )

  return [value, setValue]
}

export { useMemorizedState, MemorizedStateStorages }
