import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import isFunction from 'lodash/isFunction'

import { useMediaQueries, useCallback } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router'

import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import { SecurityItem } from 'components/molecules/SecurityItem'

type PopularEtfsProps = {
  allSecuritiesAmount: number
  areCollectionsBeingLoaded: boolean
  isDiscoveryDisplayed: boolean
  isLoading: boolean
  location: { query: Record<string, string> }
  portfolioSecurities: any[]
  scrollableElementRef: React.RefObject<any>
  securities: any[]
  securitiesWasLoadedOnce: boolean
  getSecurityDataByIndex: (index: number) => any
  getSecurityLink: (security: any) => string
  handleSaveScrollPosition: () => void
  handleSecurityClick: (security: any) => void
  navigateByTab: (index: number) => void
}

const PopularEtfs = withRouter(
  ({
    allVisibleSecuritiesAmount,
    isLoading,
    location,
    portfolioSecurities,
    securities,
    getSecurityLink,
    handleSaveScrollPosition,
    handleSecurityClick,
    navigateByTab,
  }: PopularEtfsProps): React.ReactElement => {
    const { desktop } = useMediaQueries()

    const getShowAllEtfsLink = useCallback((): string | null => {
      const { fromDiscovery, ...restQuery } = location?.query

      return urlTo('universe-all', null, restQuery)
    }, [location])

    const renderSecurity = useCallback(
      (security, index) => {
        const isAddedToPortfolio =
          portfolioSecurities?.filter((portfolioSecurity) => portfolioSecurity.id === security.id).length > 0

        return (
          <Paper key={security.id} bottom={desktop ? 24 : 12} data-test-id="securityItem">
            <SecurityItem
              {...security}
              added={isAddedToPortfolio}
              href={getSecurityLink(security?.id)}
              onClick={(id) => {
                handleSecurityClick(id)
                trackEvent({ action: 'etfrange_etfs_etf_clicked' })
              }}
              isLoading={isLoading}
              showLabels={false}
              data-test-id={`securityItem${index}`}
            />
          </Paper>
        )
      },
      [desktop, isLoading, portfolioSecurities, getSecurityLink, handleSecurityClick],
    )

    const handleShowAll = useCallback((): void => {
      if (handleSaveScrollPosition) {
        handleSaveScrollPosition()
      }

      goTo(getShowAllEtfsLink())
    }, [handleSaveScrollPosition, getShowAllEtfsLink])

    const handleClick = (event, func): void => {
      const isOpenInNewTab = event?.ctrlKey || event?.metaKey

      if (event && !isOpenInNewTab) {
        event.preventDefault()
      }

      if (!isOpenInNewTab && isFunction(func)) {
        func()
      }
    }

    return (
      <Fragment>
        <Typography
          size={desktop ? 24 : 20}
          weight="semibold"
          lineHeight="small"
          data-test-id="popularEtfsTitle"
          inline
        >
          <Typo>Popular ETFs</Typo>
        </Typography>
        <Paper top={desktop ? 24 : 16}>
          {securities.map(renderSecurity)}
          <SelectableCard
            tag="a"
            href={getShowAllEtfsLink()}
            onClick={(event) => {
              handleClick(event, handleShowAll)
            }}
          >
            <Paper top={12} bottom={12}>
              <Typography lineHeight="small" align="center">
                <Typo>
                  Show all{' '}
                  <Skeleton tag="span" shown={isLoading} inline>
                    {allVisibleSecuritiesAmount || 777}
                  </Skeleton>{' '}
                  ETFs
                </Typo>
              </Typography>
            </Paper>
          </SelectableCard>
        </Paper>
      </Fragment>
    )
  },
)

export { PopularEtfs }
