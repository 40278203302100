import React from 'react'

import { useMemo } from 'hooks'

import { bulkValidate } from 'helpers/validation.js'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { type PasswordStepProps } from '../PasswordStep'

const Desktop = ({
  password,
  validation,
  handleBack,
  handlePasswordChange,
  handleContinue,
}: PasswordStepProps): React.ReactElement => {
  const backLink = useMemo(
    () => (
      <Link onClick={handleBack} mods={{ color: 'content-on-background-default' }}>
        <ItemWithIcon
          space={16}
          icon={<Icon type="back-new" size={24} color="inherit" />}
          content={<Typography color="inherit">Back</Typography>}
          iconVerticalAlign="center"
        />
      </Link>
    ),
    [handleBack],
  )

  const stepTitleNode = useMemo(
    () => (
      <Typography size={20} lineHeight="small" weight="semibold" align="center">
        <Typo>Step 2 of 3</Typo>
      </Typography>
    ),
    [],
  )

  const titleNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography size={32} lineHeight="small" weight="semibold" align="center">
          <Typo>Confirm your identity</Typo>
        </Typography>
      </Paper>
    ),
    [],
  )

  const descriptionNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography size={14} align="center">
          <Typo>To activate two-factor authentication enter your InvestEngine password.</Typo>
        </Typography>
      </Paper>
    ),
    [],
  )

  const inputNode = useMemo(
    () => (
      <Paper top={32}>
        <Validate rules={validation.password.rules}>
          <Label errorMessages={validation.password.errors}>
            Password
            <Input mods={{ size: 'bigger' }} type="password" onChange={handlePasswordChange}>
              {password}
            </Input>
          </Label>
        </Validate>
      </Paper>
    ),
    [password, validation, handlePasswordChange],
  )

  const button = useMemo(
    () => (
      <Paper top={56}>
        <Button
          type="submit"
          mods={{ size: 'big block' }}
          disabled={!bulkValidate(validation)}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Paper>
    ),
    [validation, handleContinue],
  )

  const content = (
    <Inner twoColumns>
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column size={1}>{backLink}</Column>
        <Column size={2}>
          {stepTitleNode}
          <SubmitOnEnter>
            <Width size={24} center>
              {titleNode}
              {descriptionNode}
              {inputNode}
              {button}
            </Width>
          </SubmitOnEnter>
        </Column>
        <Column size={1} />
      </ColumnarLayout>
    </Inner>
  )

  return <DesktopLayout header={<DesktopHeader />} content={content} footer={<DesktopFooter />} />
}

export { Desktop }
