import React from 'react'

import { Stack } from 'components/atoms/Stack'

import { Filter } from 'components/organisms/Filter'

import { type UseReportsInterface, type UseReportFiltersInterface } from '../../hooks'

function ReportFilters({
  filters,
  hasReports,
}: UseReportsInterface & UseReportFiltersInterface): React.ReactElement | null {
  if (!hasReports) {
    return null
  }

  return (
    <Stack horizontal={8}>
      {Object.entries(filters).map(([name, filter]) => (
        <Filter key={name} {...filter} />
      ))}
    </Stack>
  )
}

export { ReportFilters }
