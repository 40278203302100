import React from 'react'

import { propTypes } from 'helpers/propTypes'

import { useMemo, useCallback, useMediaQueries } from 'hooks'

import { Paper } from 'components/atoms/Paper'

import { PendingOrder } from 'app/effector/pending-orders/models'
import { SecurityList } from 'app/effector/securities/models'

import { tradeTypes } from 'constants/securityOrder.js'
import { type as orderTypes } from 'constants/pendingOrder.js'
import { GroupOrder } from 'components/organisms/SecurityOrder/GroupOrder'

const SecurityOrder = ({ order, securities }) => {
  const { desktop } = useMediaQueries()

  const processSecurities = useCallback(
    (orderSecurity) => {
      const security = securities.find((security) => security.id === orderSecurity.id)

      return {
        ...orderSecurity,
        logo: security?.logo_uri,
        title: security?.title,
        documents: security?.documents,
      }
    },
    [securities],
  )

  const securitiesGroupsByType = useMemo(
    () =>
      [
        [
          [...order.securities.getByTradeType(tradeTypes.BUY)].map(processSecurities),
          (securities) => `Buy ${securities.length} securit${securities.length === 1 ? 'y' : 'ies'}`,
        ],
        [
          [...order.securities.getByTradeType(tradeTypes.SELL)].map(processSecurities),
          (securities) => `Sell ${securities.length} securit${securities.length === 1 ? 'y' : 'ies'}`,
        ],
        [
          [...order.securities.getByTradeType(tradeTypes.UNCHANGED)].map(processSecurities),
          () => 'Unchanged securities',
        ],
      ].filter(([securities]) => securities.length > 0),
    [order, securities],
  )

  return securitiesGroupsByType.map(([securities, getTitle], index, groups) => {
    const title = getTitle(securities)
    const isLastGroup = index === groups.length - 1
    const isAutoinvest = order.type === orderTypes.AUTOINVEST

    return (
      <Paper key={title} bottom={isLastGroup ? 0 : desktop ? 56 : 48}>
        <GroupOrder index={index} title={title} securities={securities} isAutoinvest={isAutoinvest} />
      </Paper>
    )
  })
}

SecurityOrder.propTypes = {
  order: propTypes.instanceOf(PendingOrder),
  securities: propTypes.instanceOf(SecurityList),
}

export { SecurityOrder }
