import React from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { Paper } from 'components/atoms/Paper'
import { SecurityLogo, type SECURITY_LOGO_SIZES } from 'components/atoms/SecurityLogo'
import { Ticker } from 'components/atoms/Ticker'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import './SecurityFilterLabel.css'

type SecurityFilterLabelProps = {
  icon?: string
  iconSize?: { desktop?: (typeof SECURITY_LOGO_SIZES)[number]; phone?: (typeof SECURITY_LOGO_SIZES)[number] }
  maxWidth?: { desktop?: number; phone?: number }
  title: string
  ticker?: string
  showStub?: boolean
  grayLogo?: boolean
  align?: 'center' | 'flex-start' | 'flex-end'
}

const SecurityFilterLabel = ({
  icon,
  iconSize: iconSizeRaw,
  maxWidth,
  title,
  ticker,
  showStub,
  grayLogo,
  align = 'flex-start',
}: SecurityFilterLabelProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const iconSize = useMemo(() => {
    const iconSize = iconSizeRaw ?? {}

    if (!iconSize.desktop) {
      iconSize.desktop = 16
    }

    if (!iconSize.phone) {
      iconSize.phone = 36
    }

    return iconSize
  }, [iconSizeRaw])
  const style = useMemo(() => {
    const width = desktop ? maxWidth?.desktop : maxWidth?.phone

    if (width) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return { '--security-filter-label-max-width': `${width}px` } as React.CSSProperties
    }

    return undefined
  }, [maxWidth?.desktop, maxWidth?.phone, desktop])

  return (
    <ColumnarLayout className="SecurityFilterLabel" mods={{ padding: 'no', 'align-columns': align }} style={style}>
      <Column size={0}>
        <Paper right={desktop ? 8 : 16} top={desktop ? 0 : 4}>
          <SecurityLogo
            className="SecurityFilterLabel-Icon"
            logo={icon}
            size={desktop ? iconSize.desktop : iconSize.phone}
            showStub={showStub}
            grayLogo={grayLogo}
          />
        </Paper>
      </Column>
      <Column className="SecurityFilterLabel-Title">
        {title}
        {ticker && (
          <Paper className="SecurityFilterLabel-Ticker" top={8}>
            <Ticker text={ticker} />
          </Paper>
        )}
      </Column>
    </ColumnarLayout>
  )
}

export { SecurityFilterLabel }
