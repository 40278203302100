import React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMediaQueries } from 'hooks'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './PortfolioTabs.css'

import { type TabData } from '../../../types'

type PortfolioTabsProps = {
  tabItems: TabData[]
  activeTabIndex: number
  justifyContent?: string
  navigateByTab: (index: number, name: string) => void
}

// TODO: Move to the external component
const PortfolioTabs = ({
  tabItems,
  activeTabIndex,
  navigateByTab,
  justifyContent = 'center',
}: PortfolioTabsProps): React.ReactElement => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries
  const classes = classNames('PortfolioTabs', getMediaQuieryClasses('PortfolioTabs', mediaQueries))

  return (
    <div className="PortfolioTabsContainer" style={{ justifyContent }}>
      <div className={classes}>
        {tabItems.map((tab, index) => (
          <button
            key={index}
            className={classNames('PortfolioTab', { PortfolioTab_active: index === activeTabIndex })}
            onClick={() => {
              navigateByTab(index, tab.name)
            }}
            data-test-id={tab['data-test-id']}
          >
            <Paper top={desktop ? 12 : 8} bottom={desktop ? 12 : 8} right={16} left={16}>
              <Typography size={desktop ? 16 : 14} lineHeight="small" align="center" className="PortfolioTab_content">
                {tab?.name}
              </Typography>
            </Paper>
          </button>
        ))}
      </div>
    </div>
  )
}

export { PortfolioTabs }
