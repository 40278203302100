import React from 'react'

import { useMediaQueries } from 'hooks/'

import { features } from 'helpers/features'
import { format } from 'helpers/money/'
import { urlTo } from 'helpers/router.js'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper/'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography/'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { SippTooltip } from '../SippTooltip'

import './SippTitle.css'

type SippTitleProps = {
  skeleton?: boolean
  isSippAccountOpened: boolean
  remainingAllowance: string
  title?: React.ReactNode
}

function SippTitle({
  skeleton = false,
  isSippAccountOpened,
  remainingAllowance,
  title,
}: SippTitleProps): React.ReactElement {
  const { desktop } = useMediaQueries()
  const isZeroFeesEnabled = features?.get('zero-sipp-fees')

  const rightPartNode = isSippAccountOpened ? (
    <Skeleton shown={skeleton} mix>
      <Link to={urlTo('dashboard', null, { sippAnnualLimitModalOpened: true })}>
        <Paper className="SippTitle_Badge" top={4} right={8} bottom={4} left={8}>
          <ItemWithIcon
            icon={<Icon type="information-16" size={16} />}
            content={
              <Nobr>
                <Typography
                  color="inherit"
                  size={desktop ? 16 : 14}
                  lineHeight="small"
                  data-test-id="sippAnnualLimitTooltip"
                >
                  Allowance: {format(remainingAllowance)}
                </Typography>
              </Nobr>
            }
            space={4}
            iconPosition="right"
            iconVerticalAlign="center"
          />
        </Paper>
      </Link>
    </Skeleton>
  ) : null

  return (
    <Paper>
      <ColumnarLayout mods={{ 'align-columns': 'center', padding: 'no' }}>
        <Column size={1}>
          <Nobr>
            <Skeleton shown={skeleton} shownProps={{ style: { alignSelf: 'flex-start' } }} inline mix>
              <Paper>
                <Typography
                  tag="h2"
                  size={desktop ? 18 : 16}
                  lineHeight="small"
                  weight="semibold"
                  data-test-id="groupTitleText"
                  className="Text_Not_selectable"
                  inline={desktop}
                >
                  {title ?? <Typo>Personal Pension</Typo>}
                  {!isZeroFeesEnabled && !isSippAccountOpened && (
                    <Paper left={8} inline>
                      <SippTooltip />
                    </Paper>
                  )}
                </Typography>
              </Paper>
            </Skeleton>
          </Nobr>
        </Column>
        <Column size={0}>
          <Paper left={8}>{rightPartNode}</Paper>
        </Column>
      </ColumnarLayout>
    </Paper>
  )
}

export { SippTitle }
