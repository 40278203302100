import React, { Fragment } from 'react'

import { useState } from 'hooks'

import { isDarkTheme } from 'helpers/palette'

import { OnboardingState as Step } from 'app/effector/onboarding-progress/types'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'

import { Alert } from 'components/atoms/Alert/Alert'
import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { ProgressBar } from 'components/molecules/ProgressBar/ProgressBar'

import { InvestmentStatusModal } from './components/InvestmentStatusModal/InvestmentStatusModal'

import './GettingStartedCard.css'

const activeStepNumber = {
  [Step.REGISTRATION_NOT_COMPLETED]: 0,
  [Step.REGISTRATION_CHECK]: 0,
  [Step.BANK_ACCOUNT_AWAITING_APPROVAL]: 1,
  [Step.MAKE_TOPUP]: 2,
  [Step.TOPUP_VERIFYING]: 2,
  [Step.PLACE_AN_ORDER]: 3,
  [Step.ORDER_PLACED]: 3,
  [Step.ORDER_PROCESSING]: 3,
  [Step.HAS_SECURITIES]: 4,
}

const activeStepTitle = {
  [Step.REGISTRATION_NOT_COMPLETED]: 'Registration',
  [Step.REGISTRATION_CHECK]: 'Registration',
  [Step.BANK_ACCOUNT_AWAITING_APPROVAL]: 'Bank Account',
  [Step.MAKE_TOPUP]: 'Top Up',
  [Step.TOPUP_VERIFYING]: 'Top Up',
  [Step.PLACE_AN_ORDER]: 'Placing Orders',
  [Step.ORDER_PLACED]: 'Placing Orders',
  [Step.ORDER_PROCESSING]: 'Placing Orders',
  [Step.HAS_SECURITIES]: 'Placing Orders',
}

type GettingStartedProps = {
  step: Step
  navigateToCompleteRegistration: () => void
  navigateToTopup: () => void
  navigateToPlaceOrder: () => void
  hidePanel: () => void
}

const GettingStartedCard = ({
  step,
  navigateToCompleteRegistration,
  navigateToTopup,
  navigateToPlaceOrder,
  hidePanel,
}: GettingStartedProps): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isDark = isDarkTheme()

  const getStepContent = ({ step, textColor = 'on_color_black' }): React.ReactElement => {
    const stepsContent = {
      [Step.REGISTRATION_NOT_COMPLETED]: (
        <Paper top={8}>
          <Button
            mods={{ size: 'block', color: 'green' }}
            onClick={navigateToCompleteRegistration}
            data-test-id="onboardingCardActionButton"
          >
            Complete Registration
          </Button>
        </Paper>
      ),
      [Step.REGISTRATION_CHECK]: (
        <Typography size={14} lineHeight="small" color={textColor}>
          <Typo>We're currently verifying your account. Please look out for an email for further updates.</Typo>
        </Typography>
      ),
      [Step.BANK_ACCOUNT_AWAITING_APPROVAL]: (
        <Typography size={14} lineHeight="small" color={textColor}>
          <Typo>We are completing some final checks. An email will notify you if any further action is required.</Typo>
        </Typography>
      ),
      [Step.MAKE_TOPUP]: (
        <Paper top={8}>
          <Button
            mods={{ size: 'block', color: 'green' }}
            onClick={navigateToTopup}
            data-test-id="onboardingCardActionButton"
          >
            Make your 1st Top Up
          </Button>
        </Paper>
      ),
      [Step.TOPUP_VERIFYING]: (
        <Typography size={14} lineHeight="small" color={textColor}>
          <Typo>Your payment is being verified.</Typo>
          <br />
          <Link
            to="https://help.investengine.com/hc/en-gb/articles/5309857688477-How-do-Manual-Bank-Transfers-work"
            data-test-id="onboardingCardActionLink"
            blank
            hard
          >
            <Typo>Learn more</Typo>
          </Link>
        </Typography>
      ),
      [Step.PLACE_AN_ORDER]: (
        <Paper top={8}>
          <Button
            mods={{ size: 'block', color: 'green' }}
            onClick={navigateToPlaceOrder}
            data-test-id="onboardingCardActionButton"
          >
            Place an order
          </Button>
        </Paper>
      ),
      [Step.ORDER_PLACED]: (
        <Typography size={14} lineHeight="small" color={textColor}>
          <Typo>Your orders are awaiting execution.</Typo>
          <br />
          <Link
            to="https://help.investengine.com/hc/en-gb/articles/5024299452573-When-is-my-order-going-to-be-executed"
            data-test-id="onboardingCardActionLink"
            blank
            hard
          >
            <Typo>Learn more</Typo>
          </Link>
        </Typography>
      ),
      [Step.ORDER_PROCESSING]: (
        <Typography size={14} lineHeight="small" color={textColor}>
          <Typo>An order will be generated automatically during the next order window. </Typo>
          <Link
            to="https://help.investengine.com/hc/en-gb/articles/5023987981725-When-will-my-new-funds-be-invested"
            data-test-id="onboardingCardActionLink"
            blank
            hard
          >
            <Typo>Learn more</Typo>
          </Link>
        </Typography>
      ),
      [Step.HAS_SECURITIES]: (
        <Fragment>
          <Typography size={14} lineHeight="small" color={textColor}>
            <Typo>You have successfully completed all the necessary steps. The orders are now placed. </Typo>
            <Link
              to="https://help.investengine.com/hc/en-gb/articles/5021858224029-What-is-settlement-and-how-long-does-it-take"
              data-test-id="onboardingCardActionLink"
              blank
              hard
            >
              <Typo>What happens next?</Typo>
            </Link>
          </Typography>
          <Paper top={16}>
            <Button
              mods={{ size: 'block', color: 'green' }}
              onClick={() => {
                setIsModalOpen(false)
                hidePanel()
              }}
              data-test-id="onboardingCardActionButton"
            >
              Close
            </Button>
          </Paper>
        </Fragment>
      ),
    }

    return stepsContent[step]
  }

  const stepContent = getStepContent({ step })

  return (
    <Card className="GettingStartedCard" data-test-id="onboardingCard">
      <Paper top={24} right={24} bottom={24} left={24}>
        <Link
          className="GettingStartedCard-Info"
          mods={{ color: 'white' }}
          onClick={() => {
            setIsModalOpen(true)
          }}
          data-test-id="onboardingCardInfoIcon"
        >
          <Icon type="information-24" size={24} color="white" />
        </Link>
        <Paper bottom={20}>
          <Typography size={18} lineHeight="small" color="white" weight="semibold">
            <Typo>Getting started</Typo>
          </Typography>
        </Paper>
        <ProgressBar numberOfSteps={4} activeStep={activeStepNumber[step]} stepTitle={activeStepTitle[step]} />
        <InvestmentStatusModal
          isOpen={isModalOpen}
          steps={['Registration', 'Bank Account', 'Top Up', 'Placing Orders']}
          activeStepIndex={activeStepNumber[step]}
          activeStepContent={isDark ? getStepContent({ step, textColor: 'on_color_white' }) : stepContent}
          handleClose={() => {
            setIsModalOpen(false)
          }}
        />

        <Paper top={12}>
          {step === Step.REGISTRATION_NOT_COMPLETED && stepContent}
          {step === Step.REGISTRATION_CHECK && <Alert>{stepContent}</Alert>}
          {step === Step.BANK_ACCOUNT_AWAITING_APPROVAL && <Alert>{stepContent}</Alert>}
          {step === Step.MAKE_TOPUP && stepContent}
          {step === Step.TOPUP_VERIFYING && <Alert>{stepContent}</Alert>}
          {step === Step.PLACE_AN_ORDER && stepContent}
          {step === Step.ORDER_PLACED && <Alert>{stepContent}</Alert>}
          {step === Step.ORDER_PROCESSING && <Alert>{stepContent}</Alert>}
          {step === Step.HAS_SECURITIES && (
            <Button mods={{ size: 'block', color: 'green' }} onClick={hidePanel}>
              Close
            </Button>
          )}
        </Paper>
      </Paper>
    </Card>
  )
}

export { GettingStartedCard, Step }
