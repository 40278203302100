import { Entity } from 'app/redux/models/common/Entity.js'

import { SecurityTotalReturnsStatsItem } from './SecurityTotalReturnsStatsItem'

const PERIODS = ['week', 'month', 'six_months', 'year', 'max'] as const

type SecurityTotalReturnsStatsCollectionRulesReturn = Record<
  (typeof PERIODS)[number],
  [[(item) => SecurityTotalReturnsStatsItem]]
>

export class SecurityTotalReturnsStatsCollection extends Entity {
  week: SecurityTotalReturnsStatsItem
  month: SecurityTotalReturnsStatsItem
  six_months: SecurityTotalReturnsStatsItem
  year: SecurityTotalReturnsStatsItem
  max: SecurityTotalReturnsStatsItem

  constructor() {
    super()
    this.week = SecurityTotalReturnsStatsItem.createFromObject({})
    this.month = SecurityTotalReturnsStatsItem.createFromObject({})
    this.six_months = SecurityTotalReturnsStatsItem.createFromObject({})
    this.year = SecurityTotalReturnsStatsItem.createFromObject({})
    this.max = SecurityTotalReturnsStatsItem.createFromObject({})
  }

  getRules(): SecurityTotalReturnsStatsCollectionRulesReturn {
    return {
      week: [[(item) => SecurityTotalReturnsStatsItem.createFromObject(item)]],
      month: [[(item) => SecurityTotalReturnsStatsItem.createFromObject(item)]],
      six_months: [[(item) => SecurityTotalReturnsStatsItem.createFromObject(item)]],
      year: [[(item) => SecurityTotalReturnsStatsItem.createFromObject(item)]],
      max: [[(item) => SecurityTotalReturnsStatsItem.createFromObject(item)]],
    }
  }
}
