import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import type { SecurityList } from 'app/effector/securities/models'

import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'

import { SearchField } from 'components/molecules/SearchField'

import { SecurityUniverseFilters } from 'app/pages/Securities/SecurityUniverse/components/SecurityUniverseFilters'
import { type Filters } from 'app/pages/Securities/SecurityUniverse/hooks/useFilters'

import { CollectionSecuritiesList } from './components/CollectionSecuritiesList'

interface CollectionSecuritiesProps {
  filters: Filters
  collectionFilters: string[]
  scrollableElementRef: React.RefObject<HTMLElement>
  securities: SecurityList
  searchValue: string
  securitiesWasLoadedOnce: boolean
  isCollectionBeingLoaded: boolean
  isLoading: boolean
  handleSearch: (value: string) => void
  getSecurityLink: (security: any) => string
  getSecurityDataByIndex: (index: number) => any
  navigateToSecurity: (id: string) => void
  sendEtfClickEventtoGA: () => void
}

const CollectionSecurities = ({
  filters,
  collectionFilters,
  scrollableElementRef,
  securities,
  searchValue,
  securitiesWasLoadedOnce,
  isCollectionBeingLoaded,
  isLoading,
  getSecurityDataByIndex,
  getSecurityLink,
  handleSearch,
  navigateToSecurity,
  sendEtfClickEventtoGA,
}: CollectionSecuritiesProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const searchNode = (
    <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by name, ticker or keyword" />
  )

  const filtersNode = (
    <Skeleton shown={isCollectionBeingLoaded}>
      <SecurityUniverseFilters filters={filters} moreShown={false} activeFilters={collectionFilters} />
    </Skeleton>
  )

  const securitiesList = (
    <CollectionSecuritiesList
      securities={securities}
      isLoading={isLoading}
      securitiesWasLoadedOnce={securitiesWasLoadedOnce}
      scrollableElementRef={scrollableElementRef}
      getSecurityDataByIndex={getSecurityDataByIndex}
      getSecurityLink={getSecurityLink}
      navigateToSecurity={navigateToSecurity}
      sendEtfClickEventtoGA={sendEtfClickEventtoGA}
    />
  )

  return (
    <Fragment>
      <Paper bottom={16}>{searchNode}</Paper>
      <Paper bottom={desktop ? 24 : 8}>{filtersNode}</Paper>
      <Paper bottom={desktop ? 120 : 0}>{securitiesList}</Paper>
    </Fragment>
  )
}

export { CollectionSecurities }
