import { createStore, createEffect, createEvent, combine } from 'effector'
import pick from 'lodash/pick'

import { sendError } from 'helpers/errorLogging'

import { Address } from 'app/redux/models/address'
import { ApiError } from 'app/redux/models/errors'

import * as api from './api'
import { Company } from './models'

// Events
const partialResetCompany = createEvent()
const changeCompanyField = createEvent<Record<string, any>>()
const changeCompanyAddressField = createEvent<{ index: number; field: Record<string, any> }>()
const receiveCompanyCurrentAddress = createEvent<Record<string, any>>()

// Effects
const updateOrCreateCompanyFx = createEffect(async ({ company: stateCompany, keys }) => {
  const company = keys ? pick(stateCompany, keys) : stateCompany

  const data = await api.updateCompany(company)

  return data
})

const fetchCompanyFx = createEffect(async () => {
  try {
    const data = await api.fetchCompany()

    if (data instanceof ApiError) throw data

    return data
  } catch (error) {
    sendError(error)
  }
})

const saveCompanyAddressFx = createEffect(async (addresses) => {
  const [address] = addresses.getCurrentAddress()
  const isCreate = address.id === null || address.id === undefined

  const data = isCreate ? await api.createAddress(address) : await api.updateAddress(address)

  return data
})

// Stores
const $company = createStore<Company>(new Company())

$company.on(fetchCompanyFx.doneData, (state, company) => {
  if (!company) return state
  return new Company(company)
})

$company.on(updateOrCreateCompanyFx.doneData, (state, company) => {
  if (!company || company instanceof ApiError) return state
  return new Company(company)
})

$company.on(saveCompanyAddressFx.doneData, (state, address) => {
  if (!address) return state
  const company = new Company({ ...state, addresses: [address] })

  return company
})

$company.on(partialResetCompany, (state) => {
  const company = new Company({
    ...state,
    number: null,
    business_types: [],
    addresses: state.addresses?.list.map((address) => {
      if (address.is_current && !address.id) {
        return { is_current: true }
      }
      return address
    }),
  })

  return company
})

$company.on(changeCompanyField, (state, field) => {
  const company = new Company({ ...state, ...field })

  return company
})

$company.on(changeCompanyAddressField, (state, { index, field }) => {
  const company = new Company(state)
  company.addresses?.setAddress(index, Address.createFromObject(state.addresses?.list[index]).setDataFromObject(field))

  return company
})

$company.on(receiveCompanyCurrentAddress, (state, address) => {
  const company = new Company(state)
  company.addresses?.addAddress(Address.createFromObject({ ...address, id: null, is_current: true }))

  return company
})

const $companyStore = combine({
  company: $company,
  isLoading: fetchCompanyFx.pending,
})

export {
  $companyStore,
  $company,
  fetchCompanyFx,
  updateOrCreateCompanyFx,
  saveCompanyAddressFx,
  partialResetCompany,
  changeCompanyField,
  changeCompanyAddressField,
  receiveCompanyCurrentAddress,
}
