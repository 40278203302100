import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { format as formatMoney } from 'helpers/money'

import { useMemo } from 'hooks'

import { DesktopLayout } from 'components/atoms/Layouts'
import Width from 'components/_old/Width/Width'
import { Paper } from 'components/atoms/Paper'
import { ControlledInput } from 'components/atoms/Forms/ControlledInput'
import Label from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import { Typography } from 'components/atoms/Typography'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { TradingSuspendedWarning } from 'components/organisms/TradingSuspendedWarning/TradingSuspendedWarning.tsx'

import { SecurityPreview } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/Security/components'
import { TunnelHeader } from 'app/pages/Securities/components'
import { BuySellTabs } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/components'

const SellDesktop = ({
  security,

  isValid,
  validation,
  errors,
  control,

  handleBack,
  handleBuy,
  handleSetAllValue,
}) => {
  const isTradingEnabled = useMemo(() => security.is_trading_available, [security])

  const header = (
    <Width size={36.75} center>
      <TunnelHeader onBack={handleBack} onCancel={handleBack} />
    </Width>
  )

  const tabs = <BuySellTabs activeTab="sell" handleBuy={handleBuy} />

  const textField = (
    <Paper top={64}>
      <ControlledInput name="amount" control={control} errors={errors} rules={validation}>
        {({ value, valid, errorMessage, handleChange, handleFocus, handleBlur }) => (
          <Fragment>
            <Width size={17.5} center>
              <Label valid={valid} errorMessage={errorMessage} data-test-id="sellSumField">
                <Input
                  name="value"
                  type="money"
                  placeholder="£"
                  mods={{ size: 'big' }}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  withFloat
                >
                  {value}
                </Input>
              </Label>
            </Width>
            {!valid && (
              <Paper top={16}>
                <Width size={24} center>
                  <Typography size={16} color="error" align="center">
                    {errorMessage}
                  </Typography>
                </Width>
              </Paper>
            )}
            <Paper top={16} bottom={valid ? 40 : null}>
              <Typography size={16} color="minor" align="center">
                Value of holding {formatMoney(security?.available_value || 0, true, true)}
                <Link onClick={handleSetAllValue} style={{ marginLeft: 12 }}>
                  Sell all
                </Link>
              </Typography>
            </Paper>
          </Fragment>
        )}
      </ControlledInput>
    </Paper>
  )

  const button = (
    <Paper top={40}>
      <Button type="submit" mods={{ size: 'big block' }} disabled={!isValid} data-test-id="reviewOrderButton">
        Review order
      </Button>
    </Paper>
  )

  const tradingEnabledContent = (
    <Fragment>
      <Paper top={64}>
        <Typography size={32} weight="semibold" align="center" lineHeight="small">
          Sell
        </Typography>
        <Paper top={40}>
          <SecurityPreview title={security.title} logo={security.logo_uri} />
        </Paper>
      </Paper>
      {textField}
      {button}
    </Fragment>
  )

  const content = useMemo(
    () => (
      <Width size={36.75} center>
        <Paper top={12}>{tabs}</Paper>
        {isTradingEnabled ? tradingEnabledContent : <TradingSuspendedWarning />}
      </Width>
    ),
    [isTradingEnabled, tabs, tradingEnabledContent],
  )

  return <DesktopLayout header={header} content={content} />
}

SellDesktop.propTypes = {
  security: PropTypes.object,
  isValid: PropTypes.bool,
  validation: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,

  handleBack: PropTypes.func,
  handleSetAllValue: PropTypes.func,
  handleBuy: PropTypes.func,
}

export { SellDesktop }
