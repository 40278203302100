import { sendError } from 'helpers/errorLogging.js'

const errorLogging = ({ error, errorHandlers }) => {
  if (!error) {
    return
  }

  errorHandlers.push((ctx) => {
    sendError(ctx.error)

    return ctx
  })
}

export { errorLogging }
