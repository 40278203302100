import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Text from 'components/_old/Text/Text.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import HeightTrigger from 'components/atoms/HeightTrigger/HeightTrigger.jsx'
import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'

import './LegendNumber.css'

const colors = {
  BLACK: 'black',
  GREEN: 'green',
  BLUE: 'blue',
}

const aligns = {
  LEFT: 'left',
  RIGHT: 'right',
}

const LegendNumber = ({
  className,
  text,
  textSize,
  description,
  value,
  align,
  oneline,
  'data-test-namespace': dataTestNamespace = '',
}) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries
  const classes = classNames(className, 'LegendNumber', getMediaQuieryClasses('LegendNumber', mediaQueries), {
    LegendNumber_oneline: oneline,
    [`LegendNumber_${align}`]: align,
  })
  const textClasses = classNames('LegendNumber-Text', `Text_${textSize}`)

  return (
    <HeightTrigger className={classes} triggerValue={52} triggerClass="LegendNumber_multiline" depends={value}>
      <Text className={textClasses} color="darkgray" noWrap data-test-id={`${dataTestNamespace}LegendNumberText`}>
        {text}
        {description && (
          <TooltipToModal
            className="LegendNumber-Tooltip"
            description={description}
            data-test-id={`${dataTestNamespace}LegendNumberDescription`}
          >
            <Icon className="LegendNumber-TooltipIcon" type="questionInCircle" inline />
          </TooltipToModal>
        )}
      </Text>
      {(() => {
        if (!value?.length) {
          return null
        }

        return (
          <div className="LegendNumber-Values" data-test-id={`${dataTestNamespace}LegendNumberValues`}>
            {value.map(({ text, color }, index) => (
              <Headline
                key={index}
                className="LegendNumber-Value"
                level={desktop ? 1 : 2}
                mods={{ 'no-margin': 'all' }}
                noWrap
              >
                <Text color={color}>{text}</Text>
              </Headline>
            ))}
          </div>
        )
      })()}
    </HeightTrigger>
  )
}

LegendNumber.propTypes = {
  text: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      color: PropTypes.oneOf(Object.values(colors)),
    }),
  ),
  align: PropTypes.oneOf(Object.values(aligns)),
  oneline: PropTypes.bool,
}

export { LegendNumber }
