import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useCallback, useClickOutside, useEffect, useMediaQueries, useMemo, useState } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Card } from 'components/atoms/Card'
import { Typo } from 'components/_old/Typo/Typo'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Width from 'components/_old/Width/Width'
import { Nobr } from 'components/_old/Nobr'

import './Select.css'

const Select = ({ className, selected, options = [], onSelect }) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const [rootRef, hasClickedOutside] = useClickOutside()
  const [opened, setOpened] = useState(false)
  const handleOpen = useCallback(() => setOpened(!opened), [opened, setOpened])
  const handleClose = useCallback(() => setOpened(false), [setOpened])
  const handleChange = useCallback(
    (optionValue) => {
      if (typeof onSelect === 'function') {
        onSelect(optionValue)
      }
      handleClose()
    },
    [onSelect, handleClose],
  )

  const handleMobileChange = useCallback(
    (event) => {
      handleChange(event.target.value)
    },
    [handleChange],
  )

  useEffect(() => {
    if (desktop && hasClickedOutside) {
      handleClose()
    }
  }, [desktop, handleClose, hasClickedOutside])

  const selectedItem = options.find((option) => option.value === selected)

  const classes = useMemo(
    () =>
      classNames(
        className,
        '_new_Select',
        {
          _new_Select_opened: opened,
        },
        getMediaQuieryClasses('_new_Select', mediaQueries),
      ),
    [className, opened, mediaQueries],
  )

  const button = useMemo(
    () => (
      <button className="_new_Select_Button" onClick={handleOpen}>
        <Paper top={8} bottom={8} left={desktop ? 32 : 20} right={desktop ? 48 : 40}>
          <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" overflow="ellipsis">
            <Nobr>
              <Typo>{selectedItem?.name}</Typo>
            </Nobr>
          </Typography>
        </Paper>
        <Icon className="_new_Select_Chevron" type="chevron" color="inherit" inline size={20} />
      </button>
    ),
    [desktop, selectedItem, handleOpen],
  )

  if (desktop) {
    return (
      <div ref={rootRef} className={classes} data-test-id="portfolioSelector">
        {button}
        <div className="_new_Select_OptionsWrapper">
          <div className="_new_Select_Options">
            <Width size={14}>
              <Card color="background-default">
                <Paper top={24} bottom={24} left={24} right={24}>
                  {options.map((option, index) => (
                    <Paper key={option.value} top={index === 0 ? null : 20}>
                      <Link onClick={() => handleChange(option.value)}>
                        <Typography size={14} lineHeight="small" color="inherit" data-test-id="portfolioSelectorItem">
                          {option.name}
                        </Typography>
                      </Link>
                    </Paper>
                  ))}
                </Paper>
              </Card>
            </Width>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes}>
      {button}
      <select className="_new_Select_select" onChange={handleMobileChange} onFocus={handleOpen} onBlur={handleClose}>
        {options.map((option) => (
          <option value={option.value} key={option.value} selected={selectedItem?.value === option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onSelect: PropTypes.func,
}

export { Select }
