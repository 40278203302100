import * as rules from 'app/redux/models/common/rules.js'

import { PortfolioStatsCollection } from './PortfolioStatsCollection.js'

export const DiyMixin = (superclass) =>
  class extends superclass {
    constructor(...args) {
      super(...args)

      this.manage_type = 'DIY'

      /** @type {?number} */
      this.withdrawable_cash = null
      /** @type {?number} */
      this.safe_withdrawable_cash = null
      /** @type {?number} */
      this.securities_value = null
      /** @type {?number} */
      this.available_securities_value = null
      /** @type {?number} */
      this.unsettled_cash = null
      /** @type {?number} */
      this.total_cash = null
      /** @type {?number} */
      this.pending_orders_cash = null
      /** @type {?number} */
      this.available_to_invest_cash = null
      /** @type {boolean} */
      this.is_autoinvest_enabled = false
      /** @type {?number} */
      this.min_autoinvest_amount = null
      /** @type {?number} */
      this.ter = null
      /** @type {?number} */
      this.estimated_yield = null

      /** @type {PortfolioStatsCollection} */
      this.stats = PortfolioStatsCollection.createFromObject({})
    }

    /** @override */
    getRules() {
      const parentRules = typeof super.getRules === 'function' ? super.getRules() : {}

      return {
        ...parentRules,
        withdrawable_cash: [[rules.float]],
        safe_withdrawable_cash: [[rules.float]],
        securities_value: [[rules.float]],
        available_securities_value: [[rules.float]],
        unsettled_cash: [[rules.float]],
        pending_orders_cash: [[rules.float]],
        available_to_invest_cash: [[rules.float]],
        is_autoinvest_enabled: [[rules.bool]],
        min_autoinvest_amount: [[rules.float]],
        ter: [[rules.float]],
        yield: [[rules.float]],
        stats: [[(stats) => PortfolioStatsCollection.createFromObject(stats)]],
      }
    }

    /**
     * @return {string}
     */
    getTitle() {
      const defaultTitle = 'DIY'

      return super.getTitle?.(defaultTitle) ?? defaultTitle
    }
  }
