import React from 'react'

import WireTransferForm from './WireTransferForm.jsx'

export const routes = {
  module: 'wire-transfer',
  path: 'wire-transfer/',
  component: function Component(props) {
    return <WireTransferForm {...props} />
  },
}
