import React from 'react'

import find from 'lodash/find'

import { useMediaQueries } from 'hooks'

import Card from 'components/_old/Card/Card.jsx'
import Form from 'components/_old/Form/Form.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import Stats from 'components/organisms/Stats/Stats.jsx'

import { frequenciesTexts } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'
import { type RecurringPayment } from 'app/pages/Dashboard/Goals/RecurringPayment/types'

import BaseField from './fields/BaseField.jsx'
import RiskField from './fields/RiskField.jsx'
import TargetField from './fields/TargetField.jsx'
import TimePeriodField from './fields/TimePeriodField.jsx'

type ControlsProps = {
  portfolio: any
  validation: any
  handleFieldChange: any
  initial_deposit: any
  one_off_payment: any
  monthlyInvestment: any
  debouncedFields: any
  preset_recommended: any
  presets: any
  handleFieldChangeDebounced: any
  target: any
  targetEditable: any
  setTargetEditable: any
  additionalFields: any
  isPortfolioActive: any
  riskStatData: any
  maxYears: number
  minYears: number
  handleSubmit: any
  bulkValidate: any
  recurringPayment: RecurringPayment | null
}

const Controls = ({
  portfolio,
  validation,
  handleFieldChange,
  initial_deposit: initialDeposit,
  one_off_payment: oneOfPayment,
  monthlyInvestment,
  debouncedFields,
  preset_recommended: presetRecomended,
  presets,
  handleFieldChangeDebounced,
  target,
  targetEditable,
  setTargetEditable,
  additionalFields,
  isPortfolioActive,
  riskStatData,
  maxYears,
  minYears,
  recurringPayment,
}: ControlsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const initialInvestmentField = (
    <BaseField
      label="Initial investment"
      value={initialDeposit}
      validationRules={validation.initial_deposit.rules}
      validationErrors={validation.initial_deposit.errors}
      onChange={(_event, value) => handleFieldChange('initial_deposit', value)}
      tabIndex={1}
      replaceWithError
      maxLength={11}
      data-test-id="editGrowthPortfolioInitialDepositInput"
    />
  )

  const oneOffPaymentField = (
    <BaseField
      label="One-off payment"
      value={oneOfPayment}
      validationRules={validation.one_off_payment.rules}
      validationErrors={validation.one_off_payment.errors}
      onChange={(_event, value) => handleFieldChange('one_off_payment', value)}
      tabIndex={1}
      replaceWithError
      maxLength={11}
      data-test-id="editGrowthPortfolioOneOffPaymentInput"
    />
  )

  const monthlyDepositField = (
    <BaseField
      label={`${recurringPayment ? frequenciesTexts[recurringPayment.frequency] : 'Monthly'} investment`}
      value={monthlyInvestment}
      postfield={<Text muted>Optional</Text>}
      validationRules={validation.monthly_deposit.rules}
      validationErrors={validation.monthly_deposit.errors}
      onChange={(_event, value) => handleFieldChange('monthly_deposit', value)}
      tabIndex={2}
      replaceWithError
      data-test-id="editGrowthPortfolioMonthlyDepositInput"
      withFloat={false}
      maxLength={11}
    />
  )

  const selectedPreset = find(presets, { id: debouncedFields.preset })

  const riskField = (
    <RiskField
      label="Risk"
      value={debouncedFields.preset}
      recommended={presetRecomended}
      scale={presets}
      portfolioId={portfolio.id}
      onChange={(value) => {
        handleFieldChangeDebounced('preset', value)
      }}
      tabIndex={3}
      data-test-id="editGrowthPortfolioPresetsSlider"
    />
  )

  const presetTitle = (
    <Paper>
      <Paper bottom={8}>
        <Typography size={12} lineHeight="small" color="additional">
          <Typo>Model</Typo>
        </Typography>
      </Paper>
      <Typography lineHeight="small" data-test-id="editGrowthPortfolioPresetTitle">
        <Typo>{selectedPreset?.title}</Typo>
      </Typography>
    </Paper>
  )

  const yearsRange = Array.apply(null, Array(maxYears - minYears + 1)).map((_, i: number) => ({ term: i + minYears }))

  const timePeriodField = (
    <TimePeriodField
      label="Time period"
      value={debouncedFields.term}
      scale={yearsRange}
      onChange={(value) => {
        handleFieldChangeDebounced('term', value)
      }}
      tabIndex={4}
      data-test-id="editGrowthPortfolioYearsSlider"
    />
  )

  const targetField = (
    <TargetField
      label="Target"
      value={target}
      editable={targetEditable}
      postfield={<Text muted>Optional</Text>}
      onChange={(_e, val) => handleFieldChange('target', val)}
      onTargetSet={() => setTargetEditable(true)}
      validationRules={validation.target.rules}
      validationErrors={validation.target.errors}
      tabIndex={5}
      data-test-id="editGrowthPortfolioTarget"
    />
  )

  const form = desktop ? (
    <div className="EditGrowthPortfolio-Form">
      {additionalFields}

      <Card mods={{ theme: 'transparent', padding: 'small', 'no-padding': 'top left right' }}>
        <ColumnarLayout mods={{ padding: 'no' }}>
          <Column>
            <Paper right={8}>{isPortfolioActive ? oneOffPaymentField : initialInvestmentField}</Paper>
          </Column>
          <Column>
            <Paper left={8}>{monthlyDepositField}</Paper>
          </Column>
        </ColumnarLayout>
        <Paper top={16}>{targetField}</Paper>
        <Paper top={24}>{riskField}</Paper>
        <Paper top={24}>{presetTitle}</Paper>
        <Paper top={24}>
          <Stats mode="horizontal" data={riskStatData} />
        </Paper>
        {timePeriodField}
      </Card>
    </div>
  ) : (
    <Form className="EditGrowthPortfolio-Form">
      {additionalFields}
      <ColumnarLayout mods={{ padding: 'small' }}>
        <Column>{isPortfolioActive ? oneOffPaymentField : initialInvestmentField}</Column>
        <Column>{monthlyDepositField}</Column>
      </ColumnarLayout>
      <Paper top={8}>{targetField}</Paper>
      <Paper top={32}>{riskField}</Paper>
      <Paper top={24}>{presetTitle}</Paper>
      <Paper top={16}>
        <Stats mode="horizontal" align="right" data={riskStatData} style={{ 'text-align': 'right' }} />
      </Paper>
      <Paper top={40}>{timePeriodField}</Paper>
    </Form>
  )

  return form
}

export { Controls }
