import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useLoading, useState } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import {
  cancelPendingOrdersFx,
  checkIfCancellationAllowed,
  $isCancellationAllowed,
  $isLoading,
} from 'app/effector/pending-orders'

import { fetchPortfolio as fetchPortfolioActionCreator } from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'

const useCancelOrders = (portfolioId) => {
  const { isLoading: isPortfolioLoading, wait: waitForPortfolio } = useLoading()

  const [isCancellationModalOpened, setCancellationModalOpened] = useState(false)
  const [isTradingWindowWarningModalOpened, setTradingWindowWarningModalOpened] = useState(false)
  const [cancellationErrorText, setCancellationErrorText] = useState(null)

  const [isPendingOrdersLoading, isTradingWindow] = useUnit([$isLoading, $isCancellationAllowed])

  const [fetchPortfolio] = useActions([fetchPortfolioActionCreator])

  const isLoading = isPendingOrdersLoading || isPortfolioLoading

  const openCancellationModal = useCallback(() => {
    setCancellationModalOpened(true)
  }, [])

  const closeCancellationModal = useCallback(() => {
    setCancellationModalOpened(false)
  }, [])

  const openTradingWindowWarningModal = useCallback(() => {
    setTradingWindowWarningModalOpened(true)
  }, [])

  const closeTradingWindowWarningModal = useCallback(() => {
    setTradingWindowWarningModalOpened(false)
  }, [setTradingWindowWarningModalOpened])

  useEffect(() => {
    checkIfCancellationAllowed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const doneSubscription = cancelPendingOrdersFx.done.watch(() => {
      waitForPortfolio(fetchPortfolio(portfolioId)).then(() => {
        goTo(urlTo('dashboard.portfolio', { id: portfolioId }, { ordersCancelled: true }))
      })
    })

    const failSubscription = cancelPendingOrdersFx.fail.watch(({ error }) => {
      const timeError = error.response?.data?.time

      if (timeError) {
        closeCancellationModal()
        setCancellationErrorText(timeError)
        openTradingWindowWarningModal()
      } else {
        showFailToast()
      }
    })

    return () => {
      doneSubscription.unsubscribe()
      failSubscription.unsubscribe()
    }
  }, [portfolioId, closeCancellationModal, openTradingWindowWarningModal, fetchPortfolio, waitForPortfolio])

  const cancelOrders = useCallback(() => {
    cancelPendingOrdersFx({ portfolioId })
  }, [portfolioId])

  return {
    isLoading,
    isTradingWindow,
    isCancellationModalOpened,
    isTradingWindowWarningModalOpened,
    cancellationErrorText,
    openCancellationModal,
    closeCancellationModal,
    closeTradingWindowWarningModal,
    cancelOrders,
  }
}

export { useCancelOrders }
