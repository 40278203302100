import React from 'react'
import classNames from 'classnames/dedupe'
import throttle from 'lodash/throttle'
import isArray from 'lodash/isArray'

import Input from 'components/_old/Input/Input.jsx'
import Text from 'components/_old/Text/Text.jsx'

import './SelectRemote.css'

export default class SelectRemote extends React.Component {
  state = {
    listVisible: false,
    listItems: [],
  }

  updateList = throttle((value) => {
    const { remoteOptions } = this.props
    remoteOptions(value).then((listItems) => {
      listItems = listItems || []
      this.setState({
        listItems,
        listVisible: listItems.length > 0,
      })
    })
  }, 150)

  handleInput = (event) => {
    const { onChange } = this.props
    const value = event.target.value
    onChange(event, value)
    this.updateList(value)
  }

  handleSelect = (event) => {
    const { onSelect } = this.props
    const { listItems } = this.state
    const index = event.currentTarget.dataset.index
    onSelect(listItems[index])
    this.setState({
      listVisible: false,
    })
  }

  render() {
    const { listVisible, listItems } = this.state
    const { mods, tabIndex, value, relativeList, name, 'data-test-id': dataTestId } = this.props
    const inputMods = { ...mods, flatbottom: listVisible }

    const listClasses = classNames('SelectRemote-List', { 'SelectRemote-List_relative': relativeList })
    const autoComplete = name || 'select-remote-data'

    return (
      <div className="SelectRemote" data-test-id={dataTestId}>
        <Input
          autoComplete={autoComplete}
          name={name}
          className="SelectRemote-Input"
          mods={inputMods}
          type="text"
          onInput={this.handleInput}
          tabIndex={tabIndex}
        >
          {value}
        </Input>
        {listVisible && (
          <div className={listClasses}>
            {isArray(listItems) &&
              listItems.map((item, index) => (
                <div
                  className="SelectRemote-List-Item"
                  data-index={index}
                  key={index}
                  onClick={this.handleSelect}
                  data-test-id="selectRemoteItem"
                >
                  <Text small block>
                    {item}
                  </Text>
                </div>
              ))}
          </div>
        )}
      </div>
    )
  }
}
