import React from 'react'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette/'

import { useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import ModalContent, {
  ModalContentBody,
  ModalContentButtons,
  ModalContentHeadline,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Paper } from 'components/atoms/Paper'

import '../NewOptimalPortfolioModal.css'

const NotChangedPortfolioModalContent = ({ headline, buttonText, contentText, onClick: handleClick }) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const classes = classNames(
    'NewOptimalPortfolioModal',
    getMediaQuieryClasses('NewOptimalPortfolioModal', mediaQueries),
  )

  const button = (
    <Button
      className="NewOptimalPortfolioModal-Button"
      mods={{ size: 'big block', text: 'bigger' }}
      onClick={handleClick}
      data-test-id="notChangedPortfolioButton"
    >
      {buttonText}
    </Button>
  )

  return (
    <div className={classes} data-test-id="notChangedPortfolio">
      <ModalContent width={60.75}>
        <Paper top={desktop ? null : 40} left={16} right={16}>
          <ModalContentHeadline
            className="NewOptimalPortfolioModal-Headline"
            level={desktop ? 1 : 2}
            align="center"
            paddingRight={false}
            icon={
              <Icon
                className="NewOptimalPortfolioModal-Icon"
                type="exclamation-triangle"
                size={64}
                color={palette['status-success']}
              />
            }
          >
            {headline}
          </ModalContentHeadline>
          <ModalContentBody size={null} align="center">
            {contentText}
          </ModalContentBody>
        </Paper>
        <ModalContentButtons width={14} isNewModal>
          {desktop ? button : <div className="NewOptimalPortfolioModal-Buttons">{button}</div>}
        </ModalContentButtons>
      </ModalContent>
    </div>
  )
}

export { NotChangedPortfolioModalContent }
