import React, { Fragment } from 'react'

import { useMemo, useCallback, useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette'
import { goTo, urlTo } from 'helpers/router'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import { UseRestrictionTypes } from 'app/pages/Dashboard/Goals/DIY/hooks'

const RestrictionTexts = {
  [UseRestrictionTypes.EDIT]: {
    title: "You can't make further changes to your portfolio while existing orders are processing",
    body: (onClick: () => void) => (
      <Fragment>
        <Typo>
          Orders are executed after 2.30pm daily but your portfolio may not be updated until 7pm. Orders created after
          2pm will be processed on the next business day.
          <br />
          <br />
          Should you wish to cancel any orders, please visit your
        </Typo>{' '}
        <Link onClick={onClick}>Orders placed screen.</Link>
      </Fragment>
    ),
  },
  [UseRestrictionTypes.SECURITY]: {
    title: "You can't trade this security while existing order is processing",
    body: (onClick: () => void) => (
      <Fragment>
        <Typo>
          Orders are executed after 2.30pm daily but your portfolio may not be updated until 7pm. Orders created after
          2pm will be processed on the next business day.
          <br />
          <br />
          Should you wish to cancel any orders, please visit your
        </Typo>{' '}
        <Link onClick={onClick}>Orders placed screen.</Link>
      </Fragment>
    ),
  },
  [UseRestrictionTypes.PORTFOLIO]: {
    title: "You can't buy/sell securities while existing orders are processing",
    body: (onClick: () => void) => (
      <Fragment>
        <Typo>
          Orders are executed after 2.30pm daily but your portfolio may not be updated until 7pm. Orders created after
          2pm will be processed on the next business day.
          <br />
          <br />
          Should you wish to cancel any orders, please visit your
        </Typo>{' '}
        <Link onClick={onClick}>Orders placed screen.</Link>
      </Fragment>
    ),
  },
  [UseRestrictionTypes.NO_SECURITIES]: {
    title: 'Could not complete action',
    body: <Typo>You need to select at least one ETF in order to use this function</Typo>,
  },
}

type RestrictionsProps = {
  open: boolean
  type: UseRestrictionTypes
  portfolioId: string | number
  onClose: () => void
}

function Restrictions({
  open = false,
  type,
  portfolioId,
  onClose: handleClose,
}: RestrictionsProps): React.ReactElement {
  portfolioId = `${portfolioId}`
  const { desktop } = useMediaQueries()

  const goToPortfolioOrders = useCallback(() => {
    goTo(urlTo('portfolio.portfolio-orders', { id: portfolioId }))
  }, [portfolioId])

  const titleNode = useMemo(() => {
    if (RestrictionTexts[type]?.title) {
      return (
        <Fragment>
          <Width size={4} center>
            <Icon type="warning-64" color={palette['status-warning']} size={64} fixedSize />
          </Width>
          <Paper top={24}>
            <Typography
              size={desktop ? 32 : 24}
              weight={desktop ? 'regular' : 'semibold'}
              align="center"
              lineHeight="small"
              data-test-id="restrictionTitle"
            >
              <Typo>{RestrictionTexts[type].title}</Typo>
            </Typography>
          </Paper>
        </Fragment>
      )
    }
  }, [type, desktop])

  const bodyNode = useMemo(() => {
    if (typeof RestrictionTexts[type]?.body === 'function') {
      return (
        <Paper top={desktop ? 32 : 24}>
          <Typography align="center" size={16}>
            {RestrictionTexts[type].body(goToPortfolioOrders)}
          </Typography>
        </Paper>
      )
    }

    if (RestrictionTexts[type]?.body) {
      return (
        <Paper top={desktop ? 32 : 24}>
          <Typography align="center" size={16}>
            {RestrictionTexts[type].body}
          </Typography>
        </Paper>
      )
    }

    return null
  }, [type, desktop, goToPortfolioOrders])

  const content = (
    <Paper>
      {titleNode}
      {bodyNode}
    </Paper>
  )

  if (desktop) {
    return (
      <Fragment>
        <Modal open={open} onClose={handleClose} zIndex={1001}>
          <ModalContent width={44} center>
            <Paper top={80} bottom={80} left={64} right={64}>
              <Paper left={8} right={8}>
                {content}
              </Paper>
              <Paper top={56}>
                <Width size={16} center>
                  <Button onClick={handleClose} mods={{ size: 'big block' }}>
                    Close
                  </Button>
                </Width>
              </Paper>
            </Paper>
          </ModalContent>
        </Modal>
        <GatewayDest name="supportModal" />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Modal open={open} onClose={handleClose} zIndex={1001}>
        <MobileLayout
          header={<NavigationBar onLeftPartClick={handleClose} leftPartText="Back" />}
          content={
            <AllCenter>
              <Paper top={48} bottom={48}>
                {content}
              </Paper>
            </AllCenter>
          }
          footer={
            <MobileLayoutFooterButton>
              <Button onClick={handleClose}>Close</Button>
            </MobileLayoutFooterButton>
          }
        />
      </Modal>
      <GatewayDest name="supportModal" />
    </Fragment>
  )
}

export { Restrictions }
