import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { goTo, urlTo } from 'helpers/router.js'
import { querystringFromObject } from 'helpers/ajax/querystring'

import { useMediaQueries, useRedirect, useSelector, useEffect } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'
import Link from 'components/_old/Link/Link.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Width from 'components/_old/Width/Width'
import Button from 'components/_old/Button/Button.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { NavigationBar } from 'components/atoms/NavigationBar'
import ContentHolder from 'components/molecules/ContentHolder/ContentHolder.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

import { isClientNewOrNotCompleted as selectIsClientNewOrNotCompleted } from 'app/redux/selectors'

const FinishRegistration = ({ handleClose, params, toggleCloseButton, location }) => {
  const { desktop } = useMediaQueries()
  const isClientFetched = useSelector((state) => state.client.is_fetched)
  const isClientNewOrNotCompleted = useSelector(selectIsClientNewOrNotCompleted)

  useRedirect({
    to: () => goTo(urlTo('portfolio.add-funds', params)),
    rule: !isClientNewOrNotCompleted,
    isClientFetched,
  })

  useEffect(() => {
    toggleCloseButton(false)
  }, [])

  const nextUrlParams = querystringFromObject({
    action: location?.query?.action ?? '',
    portfolioId: location?.query?.quickStart === 'true' ? params.id : '',
  })

  const nextUrl = urlTo('dashboard.setup-account', {}, nextUrlParams)

  const navigationBar = <NavigationBar onRightPartClick={handleClose} rightPartText="Close" />

  const headline = (
    <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
      <Typo>Finish registration to start investing with InvestEngine</Typo>
    </Typography>
  )

  const button = (
    <Button mods={{ size: 'big block' }} data-test-id="finishRegistrationPopupContinue">
      <Link to={nextUrl}>Continue</Link>
    </Button>
  )

  return desktop ? (
    <Width size={36} data-test-id="finishRegistrationModal">
      <Paper top={8} right={24} bottom={80} left={24}>
        {navigationBar}
        <Paper top={80} right={24} bottom={120} left={24}>
          <Paper top={120}>{headline}</Paper>
          <Paper top={56} right={48} left={48}>
            {button}
          </Paper>
        </Paper>
      </Paper>
    </Width>
  ) : (
    <Fragment>
      {navigationBar}
      <Inner flex>
        <AllCenter>{headline}</AllCenter>
        <ContentHolder>
          <ModalContentButtons width={desktop ? 20 : null} phantomButtonsMarginSize="zero" isNewModal>
            {button}
          </ModalContentButtons>
        </ContentHolder>
      </Inner>
    </Fragment>
  )
}

FinishRegistration.propTypes = {
  handleClose: PropTypes.func,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  toggleCloseButton: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      quickStart: PropTypes.string,
    }),
  }),
}

export { FinishRegistration }
