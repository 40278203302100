import React from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { useStrapiBonuses } from 'app/pages/Dashboard/PromoBanner/hooks/useStrapiBonuses'

import './BonusBadge.css'

type BonusBadgeProps = {
  handleOpenTsCsModal: () => void
}

const BonusBadge = ({ handleOpenTsCsModal }: BonusBadgeProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { bonuses } = useStrapiBonuses()

  const classes = classNames('BonusBadge', { BonusBadge_desktop: desktop })

  return (
    <div
      className={classes}
      data-test-id="bonusBadge"
      onClick={(event) => {
        event.stopPropagation()
        handleOpenTsCsModal()
      }}
    >
      <AllCenter className="BonusBadge_Content">
        <Typography
          size={desktop ? 12 : 10}
          lineHeight="small"
          weight="semibold"
          color={desktop ? 'additional' : 'default'}
        >
          <Typo>Up to</Typo>
        </Typography>
        <Paper top={desktop ? 4 : 0}>
          <Typography size={20} lineHeight="small" weight="semibold">
            <Typo>{bonuses?.referIsaOrGeneralBonusMax}</Typo>
          </Typography>
        </Paper>
        <Paper top={desktop ? 4 : 0}>
          <Typography
            size={desktop ? 12 : 10}
            lineHeight="small"
            weight="semibold"
            color={desktop ? 'additional' : 'default'}
          >
            <Typo>Referral bonus</Typo>
          </Typography>
        </Paper>
        <Paper top={desktop ? 4 : 0}>
          <Typography size={desktop ? 12 : 10} color="additional">
            <Link>More info</Link>
          </Typography>
        </Paper>
      </AllCenter>
    </div>
  )
}

export { BonusBadge }
