import React from 'react'

import { useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'

import desktopIcon from 'app/pages/Dashboard/Accounts/components/Empty/desktopIcon.svg'
import { useStrapiBonuses } from 'app/pages/Dashboard/PromoBanner/hooks/useStrapiBonuses'

import { BonusBadge } from '../../components/BonusBadge'

type DesktopNotAvailableProps = {
  isPromoEnabled: boolean
  isAuthorized: boolean
  handleGetStarted: () => void
  handleOpenTsCsModal: () => void
}

const DesktopNotAvailable = ({
  isPromoEnabled,
  isAuthorized,
  handleGetStarted,
  handleOpenTsCsModal,
}: DesktopNotAvailableProps): React.ReactElement => {
  const { bonuses } = useStrapiBonuses()

  const headerNode = useMemo(() => <Header logoLink={location.origin} hard withShadow />, [])

  const getStartedButton = useMemo(
    () =>
      isAuthorized ? null : (
        <Paper top={48}>
          <AllCenter className="GetStartedButtonContainer">
            <Button mods={{ size: 'big block', color: 'green' }} onClick={handleGetStarted}>
              Get started
            </Button>
            {isPromoEnabled && <BonusBadge handleOpenTsCsModal={handleOpenTsCsModal} />}
          </AllCenter>
        </Paper>
      ),
    [isAuthorized, isPromoEnabled, handleGetStarted, handleOpenTsCsModal],
  )

  const contentNode = (
    <Inner twoColumns>
      <Width size={36} center>
        <Typography size={32} lineHeight="small" weight="semibold" align="center" data-test-id="sharingDisabledText">
          <Typo>Portfolio outline no longer available to view. User has disabled sharing.</Typo>
        </Typography>
        <Paper top={80}>
          <AllCenter>
            <Paper right={20}>
              <Width className="Empty-Icon" size={8.75}>
                <SvgIcon src={desktopIcon} fluid />
              </Width>
            </Paper>
          </AllCenter>
        </Paper>
        <Paper top={40}>
          <Typography size={16} align="center">
            {isPromoEnabled ? (
              <Typo>
                You can still join InvestEngine and
                <br />
                earn up to {bonuses?.referIsaOrGeneralBonusMax}
              </Typo>
            ) : (
              <Typo>You can still join InvestEngine</Typo>
            )}
          </Typography>
        </Paper>
        {getStartedButton}
      </Width>
    </Inner>
  )

  return <DesktopLayout header={headerNode} content={contentNode} footer={<DesktopFooter />} />
}

export { DesktopNotAvailable }
