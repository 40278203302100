import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import Validate from 'components/_old/Validate/Validate.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelText, LabelField } from 'components/_old/Label/Label.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { propTypes as baseFieldPropTypes } from './BaseField.jsx'

const TargetField = ({
  label,
  value,
  postfield,
  disabled = false,
  placeholder = false,
  mods = { size: 'bigger' },
  validationRules = [],
  validationErrors = [],
  'data-test-id': dataTestId,
  tabIndex,
  editable = true,

  onChange,
  onTargetSet,
}) => {
  const { desktop } = useMediaQueries()

  return (
    <Validate rules={validationRules}>
      {(isValid, brokenRule) => {
        return (
          <Label postfield={editable ? postfield : null} fluid mods={{ color: 'darkgray' }}>
            {editable ? (
              <LabelText style={{ visibility: editable ? null : 'hidden' }}>
                <Text color={isValid ? null : 'red'} block>
                  {isValid ? label : validationErrors[brokenRule]}
                </Text>
              </LabelText>
            ) : null}
            <LabelField>
              {(() => {
                if (editable) {
                  return (
                    <Input
                      type="money"
                      onChange={onChange}
                      placeholder={placeholder}
                      disabled={placeholder}
                      mods={mods}
                      tabIndex={tabIndex}
                      data-test-id={`${dataTestId}Input`}
                    >
                      {value}
                    </Input>
                  )
                }

                if (desktop) return <Link onClick={onTargetSet}>Set your target</Link>

                return <Link onClick={onTargetSet}>Set your target</Link>
              })()}
            </LabelField>
          </Label>
        )
      }}
    </Validate>
  )
}

TargetField.propTypes = {
  ...baseFieldPropTypes,
  editable: PropTypes.bool,
  onTargetSet: PropTypes.func,
}

export default TargetField
