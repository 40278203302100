import React from 'react'
import PropTypes from 'prop-types'

import Validate from 'components/_old/Validate/Validate.jsx'
import Input from 'components/_old/Input/Input.jsx'
import { Gateway } from 'components/atoms/Gateway'
import ErrorMessage from './ErrorMessage.jsx'

const TextInput = ({ validationRule, handleChange, value, 'data-test-id': dataTestId = 'tunnelTextQuestionInput' }) => {
  return (
    <Validate rules={validationRule.rules}>
      {(isValid, brokenRule) => {
        return (
          <span>
            <Input
              type="money"
              size={10}
              mods={{ size: 'unpadded', color: 'blue_to_white' }}
              inline
              min={0}
              onChange={handleChange}
              valid={isValid}
              tabIndex={2}
              data-test-id={dataTestId}
            >
              {value === 0 ? null : value}
            </Input>
            {isValid ? null : (
              <Gateway into="text-question-error">
                <ErrorMessage error={validationRule.errors[brokenRule]} onTop={true} />
              </Gateway>
            )}
          </span>
        )
      }}
    </Validate>
  )
}

TextInput.propTypes = {
  value: PropTypes.number,
  validationRule: PropTypes.object,
  handleChange: PropTypes.func,
  'data-test-id': PropTypes.string,
}

export default TextInput
