import React from 'react'

import { SecureDocumentUpload } from './SecureDocumentUpload'
import { SecureDocumentUploadSuccess } from './SecureDocumentUploadSuccess'

const routes = [
  {
    module: 'secure-document-upload',
    path: 'secure-document-upload/:requestToken/:docSets',
    component: function Component(props) {
      return <SecureDocumentUpload {...props} />
    },
    noGlobalPreloader: true,
  },
  {
    module: 'secure-document-upload-success',
    path: 'secure-document-upload/success/',
    component: function Component(props) {
      return <SecureDocumentUploadSuccess {...props} />
    },
    noGlobalPreloader: true,
  },
]

export default routes
