import { nullable, type Nullable, string, int } from 'app/effector/model'

import { type StepDTO, type StoryDTO } from '../api'

class Step {
  readonly header: Nullable<string>
  readonly text: Nullable<string>
  readonly image: Nullable<string>
  readonly backgroundImage: Nullable<string>
  readonly bottomImage: Nullable<string>
  readonly actionButtonText?: Nullable<string>
  readonly actionButtonUrl?: Nullable<string>

  constructor(dto: StepDTO | Partial<Step>) {
    this.header = nullable(string)(dto.header)
    this.text = nullable(string)(dto.text)
    this.image = nullable(string)(dto.image)
    this.backgroundImage = nullable(string)(dto.backgroundImage)
    this.bottomImage = nullable(string)(dto.bottomImage)
    this.actionButtonText = nullable(string)(dto.actionButtonText)
    this.actionButtonUrl = nullable(string)(dto.actionButtonUrl)
  }
}

class Story {
  readonly id: Nullable<number>
  readonly text: Nullable<string>
  readonly image: Nullable<string>
  readonly wideImage: Nullable<string>
  readonly group: Nullable<string>
  readonly steps: Step[]

  constructor(dto: StoryDTO | Partial<Story>) {
    this.id = nullable(int)(dto.id)
    this.text = nullable(string)(dto.text)
    this.image = nullable(string)(dto.image)
    this.wideImage = nullable(string)(dto.wideImage)
    this.group = nullable(string)(dto.group)
    this.steps = (dto.steps ?? []).map((step: Step | StepDTO) => new Step(step))
  }
}

export { Story, Step }
