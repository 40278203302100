import * as React from 'react'
import { type ReactElement, Fragment } from 'react'

import { useCallback, useMemo, useState } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DonutChart } from 'components/atoms/DonutChart'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { VirtualList } from 'components/molecules/VirtualList'

import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'

import { type Analytics, type TabName } from '../types'

const itemTitles = {
  holdings: 'Equities',
  sectors: 'Sectors',
  regions: 'Regions',
}

type ReviewAnalyticsMobileProps = {
  isPortfolioNew: boolean
  analytics: Analytics
  handleBack: () => void
  handleCancel: () => void
  openHoldingModal: (id: string, tab: string) => void
}

const Mobile = ({
  isPortfolioNew,
  analytics,
  handleBack,
  handleCancel,
  openHoldingModal,
}: ReviewAnalyticsMobileProps): ReactElement => {
  const [layoutNode, setLayoutNode] = useState(null)
  const layoutRef = useCallback((ref) => {
    setLayoutNode(ref)
  }, [])
  const [activeTab, setActiveTab] = useState<TabName>('regions')

  const areItemsPresent = useMemo(() => !!analytics[activeTab].length, [analytics, activeTab])

  const header = useMemo(
    () => (
      <NavigationBar
        leftPartText="Back"
        onLeftPartClick={handleBack}
        rightPartText="Cancel"
        onRightPartClick={handleCancel}
      >
        Equity look-through
      </NavigationBar>
    ),
    [handleBack, handleCancel],
  )

  const renderEquity = useCallback(
    (listElement, items) => {
      const item = items[listElement.index]

      return (
        <Paper key={item.name} bottom={12}>
          <SelectableCard
            onClick={() => {
              openHoldingModal(item.id, activeTab.slice(0, -1))
            }}
          >
            <Paper top={16} bottom={16} left={16} right={16}>
              <SecurityConstituent
                logo={item.logo}
                name={item.name}
                actual={item.actual}
                target={item.target}
                currentTarget={item.currentTarget}
                showCurrentTarget={!isPortfolioNew}
                chartValue={isPortfolioNew ? item.actual : item.target}
                onSettled={listElement.measure}
                color={item.color || palette.stocks}
                showLogo={activeTab === 'holdings'}
              />
            </Paper>
          </SelectableCard>
        </Paper>
      )
    },
    [activeTab, openHoldingModal, isPortfolioNew],
  )

  const renderNoEquities = useCallback(() => null, [])

  const tabs = useMemo(
    () => [
      { title: 'Regions', id: 'regions', isActive: activeTab === 'regions' },
      { title: 'Sectors', id: 'sectors', isActive: activeTab === 'sectors' },
      { title: 'Holdings', id: 'holdings', isActive: activeTab === 'holdings' },
    ],
    [activeTab],
  )

  const chart = useMemo(
    () => (
      <AllCenter>
        <Width size={8.5}>
          <DonutChart
            lineWidth={8}
            values={analytics[activeTab].filter((item) => item.target).map((item) => [item.target, item.color])}
          >
            <Typography size={14} lineHeight="small" align="center">
              <Typography size={32} align="center" weight="semibold" lineHeight="small">
                {analytics[activeTab].filter((item) => item.target).length || ''}
              </Typography>
              {itemTitles[activeTab]}
            </Typography>
          </DonutChart>
        </Width>
      </AllCenter>
    ),
    [analytics, activeTab],
  )

  const listNode = useMemo(
    () => (
      <Fragment>
        <Paper bottom={24}>
          {areItemsPresent && (
            <ColumnarLayout>
              {activeTab === 'holdings' && (
                <Column size={1}>
                  <Typography tag="span" size={14} lineHeight="small" color="additional">
                    <Typo>{`${analytics[activeTab].length} ${activeTab}`}</Typo>
                  </Typography>
                </Column>
              )}
              <Column size={2}>
                <Typography lineHeight="small" align="right">
                  <Typography size={14} color="minor" tag="span" lineHeight="small">
                    {isPortfolioNew ? '' : 'Current target'}
                  </Typography>
                  <Typography size={14} tag="span" lineHeight="small">
                    {isPortfolioNew ? 'Target' : ' → New target'}
                  </Typography>
                </Typography>
              </Column>
            </ColumnarLayout>
          )}
        </Paper>
        <VirtualList
          scrollableElement={layoutNode}
          cacheKey="edit-weights-review-analytics-list-mobile"
          items={analytics[activeTab]}
          itemMinHeight={72}
          itemMaxHeight={92}
          renderItem={(element) => renderEquity(element, analytics[activeTab])}
          renderNoItems={renderNoEquities}
        />
      </Fragment>
    ),
    [areItemsPresent, analytics, activeTab, isPortfolioNew, layoutNode, renderEquity, renderNoEquities],
  )

  const content = useMemo(
    () => (
      <Fragment>
        <Paper top={8} bottom={32}>
          <GreyTabs
            tabs={tabs}
            onChange={(tab) => {
              setActiveTab(tab.id)
            }}
          />
        </Paper>

        {activeTab !== 'holdings' && areItemsPresent && <Paper bottom={24}>{chart}</Paper>}

        <Paper top={8} bottom={40}>
          {listNode}

          {!areItemsPresent && (
            <Paper top={80} bottom={80}>
              <AllCenter>
                <Typography color="minor">No equities in your portfolio</Typography>
              </AllCenter>
            </Paper>
          )}
        </Paper>
      </Fragment>
    ),
    [tabs, activeTab, listNode, chart, areItemsPresent],
  )

  const footer = (
    <MobileLayoutFooterButton>
      <Button type="submit" mods={{ size: 'big block' }} data-test-id="editWeightsSaveButton">
        Save and continue
      </Button>
    </MobileLayoutFooterButton>
  )

  return <MobileLayout ref={layoutRef} header={header} content={content} footer={footer} />
}

export { Mobile }
