import { Entity } from 'app/redux/models/common/Entity.js'

export class BankInfo extends Entity {
  constructor() {
    super()

    /** {?string} */
    this.title = null
    /** {?string} */
    this.logo_uri = null
    /** {?string} */
    this.open_banking_provider_id = null
    /** {?boolean} */
    this.recurring_payment_support = null
  }
}
