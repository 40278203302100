import isEmpty from 'lodash/isEmpty'

import { useCallback, useMemo } from 'hooks'

import { useRestriction } from 'app/pages/Dashboard/Goals/DIY/hooks'

const useBuySellRestriction = ({ orders, handleBuySellRaw }) => {
  const hasPendingOrder = useMemo(() => !isEmpty(orders), [orders])

  const {
    open: restrictionModalOpen,
    type: restrictionModalType,
    handleClose: handleRestrictionModalClose,
    handleRestriction,
  } = useRestriction()

  const handleBuySell = useCallback(() => {
    handleRestriction({
      action: handleBuySellRaw,
      type: 'SECURITY',
      isRestricted: hasPendingOrder,
    })
  }, [handleBuySellRaw, handleRestriction, hasPendingOrder])

  return {
    restrictionModalOpen,
    restrictionModalType,
    handleRestrictionModalClose,
    handleBuySell,
  }
}

export { useBuySellRestriction }
