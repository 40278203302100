import React, { Fragment } from 'react'

import { formatPercent } from 'helpers/money'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

type Decimal = `${number}`

type ProfitProps = {
  className?: string
  amount: Decimal | number
  percent?: Decimal | number
  space?: number
  smallPences?: boolean
  showZeroPence?: boolean
  showPercentsFirts?: boolean
  showLessThanPointZeroOne?: boolean
  'data-test-id'?: string
}

const Profit = ({
  className,
  amount,
  percent,
  smallPences = false,
  space = 0,
  showZeroPence = false,
  showPercentsFirts = false,
  showLessThanPointZeroOne = false,
  'data-test-id': dataTestId,
}: ProfitProps): React.ReactElement => {
  const percentNumber = parseFloat(percent)
  let amountNumber = parseFloat(amount)
  amountNumber = isNaN(amountNumber) ? 0 : amountNumber

  const shouldShowSign = percentNumber !== 0
  const formattedPercent = formatPercent(percentNumber / 100, true, shouldShowSign, false, showLessThanPointZeroOne)

  const color = !amount ? 'minor' : amount > 0 ? 'green' : 'red'

  const defaultSpaceStyle = !space ? { whiteSpace: 'pre-wrap' } : undefined

  const poundsNode = (
    <PoundsWithPence
      amount={amountNumber}
      sameSize={!smallPences}
      showZeroPence={showZeroPence}
      showLessThanPointZeroOne={showLessThanPointZeroOne}
      showPlusSign
      data-test-id={`${dataTestId}PerformanceValue`}
    />
  )

  const content = showPercentsFirts ? (
    <Fragment>
      <Paper inline data-test-id={`${dataTestId}PerformancePercent`}>
        {formattedPercent}
      </Paper>
      <Paper left={space} inline style={defaultSpaceStyle}>
        {' ('}
        {poundsNode})
      </Paper>
    </Fragment>
  ) : (
    <Fragment>
      {poundsNode}
      <Paper left={space} inline style={defaultSpaceStyle} data-test-id={`${dataTestId}PerformancePercent`}>
        {formattedPercent.length ? ` (${formattedPercent})` : ''}
      </Paper>
    </Fragment>
  )

  return (
    <Typography className={className} color={color} size="inherit" lineHeight="inherit" weight="inherit" inline>
      <Typo>{content}</Typo>
    </Typography>
  )
}

export { Profit }
