import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { useState, useMemo, useCallback } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

type TextCollapseProps = {
  children: string | React.ReactChild
  limit: number
  multiline?: boolean
  onExpand?: () => void
  shouldInitExpanded?: boolean
  maxLineBreaks?: number
  allowHtml?: boolean
}

const TextCollapse = ({
  children: text,
  limit,
  multiline = false,
  shouldInitExpanded = false,
  maxLineBreaks = 0,
  onExpand,
  allowHtml = true,
}: TextCollapseProps): React.ReactElement => {
  const [isCollapsed, setIsCollapsed] = useState(!shouldInitExpanded)

  if (maxLineBreaks && typeof text === 'string') {
    const lineBreakPositions = text.split('').reduce((indices: number[], letter, index) => {
      letter === `\n` && indices.push(index)
      return indices
    }, [])

    const lastLineBreakPosition = lineBreakPositions[maxLineBreaks - 1]

    if (lastLineBreakPosition && lastLineBreakPosition < limit) limit = lastLineBreakPosition
  }

  const croppedText = useMemo(() => {
    if (typeof text !== 'string') return text

    const slicedText = text.slice(0, limit + 1)
    const cutToTheLastWord = slicedText.replace(/\s+\S*$/, '')

    return `${cutToTheLastWord}...`
  }, [text, limit])

  const handleShowMore = useCallback(() => {
    setIsCollapsed(false)
    if (onExpand) onExpand()
  }, [setIsCollapsed, onExpand])

  if (isCollapsed && typeof text === 'string' && text.length > limit) {
    return (
      <Fragment>
        <Typo multiline={multiline} allowHtml={allowHtml}>
          {croppedText}
        </Typo>
        <Link onClick={handleShowMore}> See&nbsp;more</Link>
      </Fragment>
    )
  }

  return (
    <Typo multiline={multiline} allowHtml={allowHtml}>
      {text}
    </Typo>
  )
}

export { TextCollapse }

TextCollapse.propTypes = {
  children: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
  multiline: PropTypes.bool,
}
