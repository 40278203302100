import React, { Fragment } from 'react'
import Link from 'components/_old/Link/Link.jsx'

import './StoresButtons.css'

import storesLink from 'constants/externalLinks.js'

import appStore from './app-store.png'
import googlePlay from './google-play.png'

const StoresButtons = () => {
  return (
    <Fragment>
      <Link to={storesLink} hard blank data-test-id="appStoreButton">
        <img className="StoreButton" src={appStore} />
      </Link>
      <Link to={storesLink} hard blank data-test-id="googlePlayButton">
        <img className="StoreButton" src={googlePlay} />
      </Link>
    </Fragment>
  )
}

export default StoresButtons
