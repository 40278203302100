import React from 'react'

import { useCallback, useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'
import { urlTo, goTo } from 'helpers/router.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

type TwoFactorDisabledModalProps = {
  open: boolean
}

const TwoFactorDisabledModal = ({ open }: TwoFactorDisabledModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const handleClose = useCallback(() => {
    const backUrl = urlTo('dashboard.user-profile.login-settings')

    goTo(backUrl)
  }, [])

  const iconNode = useMemo(
    () => (
      <AllCenter>
        <Icon type="checkmark_in_a_circle" color={palette['status-success']} size={64} />
      </AllCenter>
    ),
    [],
  )

  const titleNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
          <Typo>Two-factor authentication disabled</Typo>
        </Typography>
      </Paper>
    ),
    [desktop],
  )

  const descriptionNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography size={desktop ? 14 : 16} align="center">
          <Typo>Your account is now accessible by password only.</Typo>
        </Typography>
      </Paper>
    ),
    [desktop],
  )

  const button = useMemo(
    () => (
      <Button onClick={handleClose} mods={{ size: 'big block' }}>
        Continue
      </Button>
    ),
    [handleClose],
  )

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose}>
        <ModalContent width={36}>
          <Paper top={80} bottom={80}>
            {iconNode}
            <Width size={24} center>
              {titleNode}
              {descriptionNode}
              <Paper top={56}>{button}</Paper>
            </Width>
          </Paper>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContent width={36}>
        <MobileLayout
          header={<NavigationBar onRightPartClick={handleClose} rightPartText="Close" />}
          content={
            <AllCenter>
              <Paper>
                {iconNode}
                {titleNode}
                {descriptionNode}
              </Paper>
            </AllCenter>
          }
          footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
        />
      </ModalContent>
    </Modal>
  )
}

export { TwoFactorDisabledModal }
