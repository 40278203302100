import { combine } from 'effector'

import { $features } from 'app/effector/features'
import {
  $nomineesTransferProviderList,
  NomineesTransferProviderRegulatoryType,
} from 'app/effector/nominees-transfers/providers'

// Stores
const $sippProviders = $nomineesTransferProviderList.map((providers) =>
  providers.filter((provider) => provider.regulatory_type === NomineesTransferProviderRegulatoryType.SIPP),
)

const $vanguardProviders = $sippProviders.map((providers) =>
  providers.filter((provider) => (provider.name ?? '').toLowerCase().includes('vanguard')),
)

const $displayedProviders = combine(
  $features,
  $sippProviders,
  $vanguardProviders,
  (features, sippProviders, vanguardProviders) => {
    if (features.get('multiple-sipp-providers-transfer')) {
      return sippProviders
    }

    return vanguardProviders
  },
)

const $shouldShowProvidersBlock = $displayedProviders.map(
  (providers) => !(providers.length > 3 || providers.length < 1),
)

const $providersString = $displayedProviders.map((providers) => {
  const plural = providers.length <= 1 ? 'is' : 'are'

  const string = providers
    .filter((provider) => !!provider.name)
    .reduce((string, provider, index, providersWithNames) => {
      if (index === 0) {
        return provider.name
      }

      if (providersWithNames.length - 1 === index) {
        return `${string} or ${provider.name}`
      }

      return `${string}, ${provider.name}`
    }, '')

  if (!string || string.length < 1) {
    return null
  }

  return `${string} ${plural}`
})

const $providersOptions = $displayedProviders.map((providers) =>
  [...providers].map((provider) => ({
    value: provider.id,
    name: provider.name,
  })),
)

export { $displayedProviders, $shouldShowProvidersBlock, $providersString, $providersOptions }
