import { Entity } from 'app/redux/models/common/Entity.js'

import { BankInfo } from './BankInfo.js'

export class BankAccount extends Entity {
  constructor() {
    super()

    /** @type {?number} */
    this.id = null
    /** @type {?string} */
    this.state = null
    /** @type {?string} */
    this.account_number = null
    /** @type {?string} */
    this.sort_code = null
    /** @type {BankInfo} */
    this.bank = new BankInfo()
    /** @type {boolean} */
    this.is_nominated = false
    /** @type {boolean} */
    this.bank_statement_filled = false
    /** @type {?string} */
    this.manually_nominated_dt = null
  }

  getRules() {
    return {
      bank: [[(value) => BankInfo.createFromObject(value)]],
    }
  }
}
