import { features } from 'helpers/features'

import { useCallback, useEffect, useState } from 'hooks'

const FeatureToggleHandler = ({ children }) => {
  const [hash, setHash] = useState(features.getHash())
  const [newHash, setNewHash] = useState(features.getHash())

  const handleUpdate = useCallback(() => {
    setNewHash(features.getHash())
  }, [setNewHash])

  useEffect(() => {
    window.addEventListener('featuresUpdated', handleUpdate)

    return () => {
      window.removeEventListener('featuresUpdated', handleUpdate)
    }
  }, [handleUpdate])

  useEffect(() => {
    if (hash !== newHash) setHash(newHash)
  }, [hash, newHash])

  if (typeof children === 'function') {
    return children(hash)
  }

  return children
}

export { FeatureToggleHandler }
