import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { propTypes } from 'helpers/propTypes'

import { useMemo, useSelector, useState } from 'hooks'

import { selectGoalTitle } from 'app/redux/selectors'

import { DesktopHeader } from 'app/containers/Header'
import { DesktopFooter } from 'app/containers/Footer'
import { Typo } from 'components/_old/Typo/Typo'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Width from 'components/_old/Width/Width'
import Inner from 'components/_old/Inner/Inner.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Card } from 'components/atoms/Card'
import { Stack } from 'components/atoms/Stack'
import { Paper } from 'components/atoms/Paper'
import { Relative } from 'components/atoms/Relative'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'
import { DesktopLayout } from 'components/atoms/Layouts'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Preloader } from 'components/molecules/Preloader'
import { SearchField } from 'components/molecules/SearchField'
import { PeriodSelect } from 'components/molecules/PeriodSelect'
import { SecurityTitle } from 'components/molecules/SecurityTitle'
import { PriceInformation } from 'components/molecules/PriceInformation'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Filter } from 'components/organisms/Filter'
import { SecurityChart } from 'components/organisms/SecurityChart'
import { FilterGroup } from 'components/organisms/Filter/FilterGroup'
import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { PriceChartDashedLines } from 'components/organisms/PriceChart'
import { OrdersProcessing } from 'components/organisms/OrdersProcessing'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'
import { SecuritySlicePointOfInterestCard } from 'components/organisms/charts/parts'
import { DividendsChart } from '../../../Portfolio/components/DividendsChart'
import { ChartSelectTabs } from '../../../Portfolio/components/ChartSelectTabs'
import {
  HoldingInformation,
  SecurityDescription,
  SecurityDocuments,
  LatestMarketPriceNote,
  SecurityCollections,
} from 'app/pages/Dashboard/Goals/DIY/SecuritySlice/components'
import { SecurityDetails } from 'app/pages/Securities/Security/components/SecurityDetails'

import { PendingOrderList } from 'app/effector/pending-orders/models'

import { manageTypes } from 'constants/portfolio'
import { format } from 'helpers/money'
import { DynamicFontSize } from 'components/atoms/DynamicFontSize'
import { SecurityChartTabs } from 'app/pages/Securities/Security/components/SecurityChartTabs'

const Desktop = ({
  isSecurityLoading,
  isAnalyticsLoading,
  isPendingOrdersLoading,
  fromAnalytics,
  fromPortfolio,
  security,
  portfolio,
  securityOrders,
  linkToSecurityOrders,
  chartData,
  totalReturnsData,
  pointsOfInterest,
  periodPriceData,
  holdings,
  sectors,
  regions,
  filters,
  searchValue,
  activeChart,
  selectedPeriod,
  dividendsChartData,
  setChartTab,
  setPeriodTab,
  shouldShowDividendsChart,
  handleSearch,
  handleBack,
  handleBuySell,
  goToSecurityAnalytics,
  goToHoldingPage,
}) => {
  const isAnalyticsVisible = useMemo(() => security?.type !== 'BOND' && security?.type !== 'ALTERNATIVE', [security])
  const analytics = { holdings, sectors, regions }
  const portfolioTitle = useSelector((state) => selectGoalTitle(state, portfolio?.id))

  const isDistributing = useMemo(() => security?.dividends_type === 'DISTRIBUTING', [security])
  const [activeTab, setActiveTab] = useState('holdings')
  const [activeChartTab, setActiveChartTab] = useState(isDistributing ? 'totalReturns' : 'priceReturns')

  const tabs = useMemo(
    () => [
      { title: 'Holdings', id: 'holdings', isActive: activeTab === 'holdings', withSearch: handleSearch },
      { title: 'Regions', id: 'regions', isActive: activeTab === 'regions' },
      { title: 'Sectors', id: 'sectors', isActive: activeTab === 'sectors' },
    ],
    [activeTab],
  )
  const activeTabObject = useMemo(() => tabs.find((tab) => tab.isActive) ?? {}, [activeTab])

  const isListFiltered = !!filters.regions.value.length || !!filters.sectors.value.length || !!searchValue

  const backLink = useMemo(
    () => (
      <Paper>
        <Link onClick={handleBack} mods={{ color: 'content-on-background-default' }}>
          <ItemWithIcon
            space={16}
            icon={<Icon type="back-new" size={20} color="inherit" />}
            content={<Typography color="inherit">Back</Typography>}
            iconVerticalAlign="center"
            data-test-id="backFromSlice"
          />
        </Link>
      </Paper>
    ),
    [handleBack],
  )

  const price = useMemo(() => {
    const securitySharePrice = security?.share_price || 0

    return (
      <Typography weight="semibold" lineHeight="small" data-test-id="portfolioBalance">
        <DynamicFontSize
          min={12}
          max={54}
          lineHeight={1.175}
          length={format(securitySharePrice, true, true).length}
          lengthLimit={9}
        >
          <Skeleton shown={isSecurityLoading && securitySharePrice === 0} mix inline>
            <PoundsWithPence amount={securitySharePrice} showZeroPence data-test-id="securityPrice" />
          </Skeleton>
        </DynamicFontSize>
      </Typography>
    )
  }, [isSecurityLoading, security?.share_price])

  const ordersProcessing = useMemo(() => {
    if (securityOrders.length < 1) {
      return null
    }

    return (
      <Paper top={24}>
        <Typography align="center">
          <OrdersProcessing to={linkToSecurityOrders} />
        </Typography>
      </Paper>
    )
  }, [securityOrders, linkToSecurityOrders])

  const buySellButton = useMemo(
    () =>
      portfolio?.manage_type === manageTypes.DIY ? (
        <Paper top={24}>
          <Relative>
            <Preloader loading={isPendingOrdersLoading} size={isSecurityLoading ? 'zero' : 'default'} absolute />
            <Button mods={{ size: 'new-big block' }} onClick={handleBuySell} data-test-id="buySellSecurityButton">
              Buy / Sell
            </Button>
          </Relative>
        </Paper>
      ) : null,
    [isPendingOrdersLoading, isSecurityLoading, portfolio?.manage_type, handleBuySell],
  )

  const currentPriceData = useMemo(() => {
    return activeChartTab === 'totalReturns'
      ? { ...periodPriceData, ...totalReturnsData.stats[selectedPeriod] }
      : periodPriceData
  }, [activeChartTab, periodPriceData, totalReturnsData.stats, selectedPeriod])

  const information = useMemo(
    () =>
      currentPriceData && (
        <PriceInformation
          period={currentPriceData}
          description={
            <Typo>
              Change in share price over time in pounds (£) as well as percentage terms.
              <br />
              <br />
              Note this will {activeChartTab === 'totalReturns' ? '' : 'not'} include any dividend income paid out from
              this security over the period.
            </Typo>
          }
        />
      ),
    [currentPriceData, activeChartTab],
  )

  const dividendsChart = portfolio?.id && (
    <Skeleton shown={isSecurityLoading && dividendsChartData.length < 1} mix>
      <div>
        <DividendsChart
          data={dividendsChartData}
          period={selectedPeriod}
          securities={[]}
          portfolioId={portfolio?.id}
          securityId={security?.id}
        />
        <Paper top={24} left={16}>
          <Width size={24} center>
            <PeriodSelect selectedPeriod={selectedPeriod} handlePeriodSelect={setPeriodTab} />
          </Width>
        </Paper>
      </div>
    </Skeleton>
  )

  const chartTabs = useMemo(() => {
    const tabs = <SecurityChartTabs activeChartTab={activeChartTab} setActiveChartTab={setActiveChartTab} />

    const renderedTabs = shouldShowDividendsChart ? tabs : <Paper top={56}>{tabs}</Paper>

    return activeChart === 'return' && isDistributing && renderedTabs
  }, [activeChart, activeChartTab, isDistributing, shouldShowDividendsChart])

  const currentChartData = useMemo(() => {
    return activeChartTab === 'totalReturns' ? totalReturnsData.history[selectedPeriod] : chartData
  }, [activeChartTab, chartData, totalReturnsData, selectedPeriod])

  const priceChart = useMemo(
    () => (
      <Paper top={shouldShowDividendsChart ? 32 : 56}>
        <Skeleton shown={isSecurityLoading && currentChartData.length < 1}>
          <SecurityChart
            period={selectedPeriod}
            data={currentChartData}
            periodPriceData={currentPriceData}
            handlePeriodSelect={setPeriodTab}
            topContent={information}
            topContentOffset={56}
            portfolioId={portfolio?.id}
            securityId={security?.id}
            dashedLines={[PriceChartDashedLines.CURRENT]}
            pointsOfInterest={pointsOfInterest}
            PointOfInterestCard={SecuritySlicePointOfInterestCard}
          />
        </Skeleton>
      </Paper>
    ),
    [
      isSecurityLoading,
      selectedPeriod,
      currentChartData,
      currentPriceData,
      setPeriodTab,
      portfolio?.id,
      security?.id,
      pointsOfInterest,
      information,
      shouldShowDividendsChart,
      activeChartTab,
    ],
  )

  const chartSelectNode = shouldShowDividendsChart && (
    <Paper top={32} bottom={32}>
      <ChartSelectTabs activeChart={activeChart} setActiveChart={setChartTab} />
    </Paper>
  )

  const chartNode = activeChart === 'dividends' && shouldShowDividendsChart ? dividendsChart : priceChart

  const descriptionNode = useMemo(
    () =>
      security?.description?.length > 0 && (
        <Paper top={80}>
          <SecurityDescription description={security?.description} />
        </Paper>
      ),
    [security?.description],
  )

  const detailsNode = useMemo(
    () => (
      <Paper top={24}>
        <SecurityDetails security={security} />
      </Paper>
    ),
    [security],
  )

  const securityCollections = portfolio?.manage_type === manageTypes.DIY && (
    <Paper top={security?.collections?.length ? 48 : 0}>
      <SecurityCollections collections={security?.collections} />
    </Paper>
  )

  const slowSecurityMention = useMemo(
    () => (
      <Paper top={32}>
        <Card level={0} color="primary-surface-25" borderWidth={2}>
          <Paper top={24} bottom={24} left={24} right={24}>
            <Typography size={16} weight="semibold">
              <Paper bottom={12}>
                <Typo>Please note</Typo>
              </Paper>
            </Typography>
            <Typography size={16} color="additional">
              <Typo>
                Buy and sell trades for this ETF may take longer to settle than the usual two business days (‘T+2’).{' '}
                <Link
                  to="https://help.investengine.com/hc/en-gb/articles/5021858224029-What-is-Settlement-and-how-long-does-it-take-"
                  blank
                  hard
                >
                  Read more here.
                </Link>
              </Typo>
            </Typography>
          </Paper>
        </Card>
      </Paper>
    ),
    [],
  )

  const portfolioHoldings = useMemo(() => {
    const holdingInfo = security?.holdingInfo

    return (
      <Paper top={80}>
        <Typography size={24} lineHeight="small" weight="semibold">
          <Typo allowHtml={false}>This ETF in {portfolioTitle}</Typo>
        </Typography>
        <Paper top={24}>
          <HoldingInformation
            description={
              <Typo>
                Your total return (income and capital) from this holding within the portfolio.
                <br />
                <br />
                The investment return we show is the time-weighted return (TWR).
              </Typo>
            }
            securityId={security?.id}
            portfolioId={portfolio?.id}
            value={holdingInfo?.value}
            total_return={holdingInfo?.stats.max}
            quantity={holdingInfo?.quantity}
            averagePrice={holdingInfo?.average_price}
            portfolioManageType={portfolio?.manage_type}
          />
        </Paper>
      </Paper>
    )
  }, [security?.holdingInfo, portfolio?.manage_type])

  const analyticsListNode = useMemo(() => {
    const { id } = activeTabObject
    const items = analytics?.[id]

    if (isAnalyticsLoading && items.length < 1) {
      return (
        <Paper top={32}>
          <Skeleton shown mix>
            <Stack vertical={48}>
              {new Array(5).fill({}).map((_, index) => (
                <SecurityConstituent
                  key={index}
                  name="██████"
                  actual={0}
                  color="transparent"
                  showLogo={id === 'holdings'}
                />
              ))}
              <Skeleton shown>
                <Button mods={{ theme: 'simple-reverse-blue', size: 'big block' }}>Show all</Button>
              </Skeleton>
            </Stack>
          </Skeleton>
        </Paper>
      )
    }

    if (id && items.length > 0) {
      const limitedList = analytics[id].slice(0, 5)

      return (
        <Fragment>
          <Paper top={32}>
            <Stack vertical={24}>
              {limitedList.map((item) => (
                <SelectableCard key={item.name} onClick={() => goToHoldingPage(item.id, id.slice(0, -1))}>
                  <Paper top={16} bottom={16} left={16} right={16}>
                    <SecurityConstituent
                      logo={item.logo}
                      name={item.name}
                      actual={item.actual}
                      color={item.color}
                      showLogo={id === 'holdings'}
                    />
                  </Paper>
                </SelectableCard>
              ))}
              {analytics[id].length > 5 && (
                <Button
                  mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
                  onClick={() => goToSecurityAnalytics(id)}
                  data-test-id="showAllButton"
                >
                  Show all {analytics[id].length}
                </Button>
              )}
            </Stack>
          </Paper>
        </Fragment>
      )
    }

    return null
  }, [isAnalyticsLoading, goToSecurityAnalytics, activeTabObject, analytics, goToHoldingPage])

  const analyticsNode = useMemo(() => {
    if (!isAnalyticsVisible) return null

    return (
      <Paper top={80}>
        <Fragment>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Typo>ETF Breakdown</Typo>
          </Typography>
          <Paper top={32}>
            <GreyTabs
              tabs={tabs}
              onChange={(tab) => {
                setActiveTab(tab.id)
              }}
            />
          </Paper>
          {activeTabObject?.withSearch && (
            <Fragment>
              <Paper top={24}>
                <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by holding" />
              </Paper>
              <Paper top={16}>
                <Skeleton shown={isAnalyticsLoading} mix>
                  <FilterGroup fitContent>
                    <Filter
                      name="Region"
                      type="checkbox"
                      values={filters.regions.list}
                      selected={filters.regions.value}
                      onChange={filters.regions.set}
                      data-test-id="holdingsFilterRegion"
                    />
                    <Filter
                      name="Sector"
                      type="checkbox"
                      values={filters.sectors.list}
                      selected={filters.sectors.value}
                      onChange={filters.sectors.set}
                      dropdownMaxHeight={520}
                      data-test-id="holdingsFilterSector"
                    />
                  </FilterGroup>
                </Skeleton>
              </Paper>
              {!isAnalyticsLoading && isListFiltered && !analytics[activeTab]?.length && (
                <Paper top={24}>
                  <Typography size={14} lineHeight="medium" color="minor" data-test-id="noSearchResults">
                    <Typo>
                      No holdings found.
                      <br />
                      Please try a different search.
                    </Typo>
                  </Typography>
                </Paper>
              )}
            </Fragment>
          )}
          {analyticsListNode}
        </Fragment>
      </Paper>
    )
  }, [
    isAnalyticsLoading,
    searchValue,
    handleSearch.tabs,
    activeTabObject,
    analyticsListNode,
    isListFiltered,
    analytics,
    activeTab,
  ])

  const documents = useMemo(
    () =>
      security?.documents?.length > 0 && (
        <Paper top={80}>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Typo>Key information</Typo>
          </Typography>
          <Paper top={24}>
            <SecurityDocuments documents={security?.documents} />
          </Paper>
        </Paper>
      ),
    [security?.documents],
  )

  const content = (
    <Inner twoColumns>
      {backLink}
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column size={1} mods={{ strict: true }}>
          <Paper top={56}>
            <SecurityTitle
              logo={security?.logo_uri}
              ticker={security?.ticker}
              title={security?.title}
              description={security?.short_description}
              size={24}
              lineHeight="small"
              iconPosition="left"
            />
          </Paper>
          {chartSelectNode}
          {chartTabs}
          {chartNode}
          {descriptionNode}
          {detailsNode}
          {securityCollections}
          {security?.is_slow_to_settle && slowSecurityMention}
          {portfolioHoldings}
          {analyticsNode}
          {documents}
        </Column>
        <Column size={0} sticky>
          <Paper left={80} top={56}>
            <Width size={19}>
              <Card>
                <Paper top={24} bottom={24} left={24} right={24}>
                  {price}
                  <Paper top={4}>
                    <Skeleton shown={isSecurityLoading && !security?.price_dt} mix>
                      <LatestMarketPriceNote date={security?.price_dt} />
                    </Skeleton>
                  </Paper>
                  {ordersProcessing}
                  {buySellButton}
                </Paper>
              </Card>
            </Width>
          </Paper>
        </Column>
      </ColumnarLayout>
    </Inner>
  )

  return (
    <DesktopLayout
      header={<DesktopHeader activeItem={fromAnalytics && !fromPortfolio ? 'analytics' : 'dashboard'} />}
      content={content}
      footer={<DesktopFooter />}
    />
  )
}

const TotalReturnsHistoryItemPropType = PropTypes.shape({
  date: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
})

const TotalReturnsStatItemPropType = PropTypes.shape({
  price: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
})

Desktop.propTypes = {
  isSecurityLoading: PropTypes.bool,
  isAnalyticsLoading: PropTypes.bool,
  isPendingOrdersLoading: PropTypes.bool,
  fromAnalytics: PropTypes.bool,
  fromPortfolio: PropTypes.bool,
  security: PropTypes.object,
  portfolio: PropTypes.object,
  securityOrders: propTypes.instanceOf(PendingOrderList),
  linkToSecurityOrders: PropTypes.string,
  chartData: PropTypes.array,
  totalReturnsData: PropTypes.shape({
    history: PropTypes.shape({
      week: PropTypes.arrayOf(TotalReturnsHistoryItemPropType),
      month: PropTypes.arrayOf(TotalReturnsHistoryItemPropType),
      six_months: PropTypes.arrayOf(TotalReturnsHistoryItemPropType),
      year: PropTypes.arrayOf(TotalReturnsHistoryItemPropType),
      max: PropTypes.arrayOf(TotalReturnsHistoryItemPropType),
    }),
    stats: PropTypes.shape({
      week: TotalReturnsStatItemPropType,
      month: TotalReturnsStatItemPropType,
      six_months: TotalReturnsStatItemPropType,
      year: TotalReturnsStatItemPropType,
      max: TotalReturnsStatItemPropType,
    }),
  }),
  pointsOfInterest: PropTypes.array,
  selectedPeriod: PropTypes.string.isRequired,
  periodPriceData: PropTypes.shape({
    price: PropTypes.number,
    percent: PropTypes.number,
    name: PropTypes.string,
  }),
  holdings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  filters: PropTypes.shape({
    regions: {
      value: PropTypes.arrayOf(PropTypes.number),
      list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.number })),
      set: PropTypes.func,
    },
    sectors: {
      value: PropTypes.arrayOf(PropTypes.number),
      list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.number })),
      set: PropTypes.func,
    },
  }),
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func,
  setPeriodTab: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleBuySell: PropTypes.func.isRequired,
  goToSecurityAnalytics: PropTypes.func.isRequired,
  goToHoldingPage: PropTypes.func,
}

export { Desktop }
