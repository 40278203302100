import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { LegendMark } from 'components/organisms/charts/parts'

const Legend = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <Paper right={8} style={{ display: 'inline-flex' }}>
        <LegendMark color={palette['content-on-background-primary']} />
      </Paper>

      <Typography align="right" size={desktop ? 16 : 14} color="minor">
        <Typo>Paid</Typo>
      </Typography>
    </Fragment>
  )
}

export { Legend }
