import { combine, createEffect, createStore } from 'effector'

import { makeApiCall } from 'app/effector/api'

import { type ApiError } from 'app/redux/models/errors'

import * as api from './api'
import { type ApplyPromocodeData, type ApplyPromocodeDTO, PromocodeStatuses } from './api'

// Effects
const fetchPromocodeStatusFx = createEffect(async () => {
  return await makeApiCall(api.fetchPromocodeStatus)
})

const applyPromocodeFx = createEffect<ApplyPromocodeData, ApplyPromocodeDTO, ApiError>(
  async (data: api.ApplyPromocodeData) => {
    return await makeApiCall(api.applyPromocode, data)
  },
)

// Stores
const $promocodeStatus = createStore<PromocodeStatuses>(PromocodeStatuses.UNKNOWN)
$promocodeStatus.on(fetchPromocodeStatusFx.doneData, (_state, newStatus) => newStatus)
$promocodeStatus.on(applyPromocodeFx.doneData, (_state) => PromocodeStatuses.APPLIED)

type AppliedPromocode = {
  min: string | null
  max: string | null
}
const $appliedPromocode = createStore<AppliedPromocode>({ min: null, max: null })
$appliedPromocode.on(applyPromocodeFx.doneData, (_state, applyData) => ({
  min: applyData.bonus.min,
  max: applyData.bonus.max,
}))

const $isLoading = combine(fetchPromocodeStatusFx.pending, applyPromocodeFx.pending, (...pendingEffects) =>
  pendingEffects.some((pending) => pending),
)

const $promocodesStore = combine({
  promocodeStatus: $promocodeStatus,
  appliedPromocode: $appliedPromocode,
  isLoading: $isLoading,
})

export {
  PromocodeStatuses,
  fetchPromocodeStatusFx,
  applyPromocodeFx,
  $promocodeStatus,
  $appliedPromocode,
  $promocodesStore,
}
