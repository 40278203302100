import React from 'react'

import { useMediaQueries, useSelector } from 'hooks'

import { features } from 'helpers/features'

import { selectGoalTitle } from 'app/redux/selectors'

import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { CardAccent } from 'components/atoms/CardAccent'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { PortfolioManagedBy } from 'components/molecules/PortfolioManagedBy/PortfolioManagedBy'

import { manageTypes, regulatoryTypes, portfolioColors } from 'constants/portfolio'
import './PortfolioCard.css'

const PortfolioCard = ({ portfolio }): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const isZeroFeesEnabled = features?.get('zero-sipp-fees')

  const title = useSelector((state) => selectGoalTitle(state, portfolio.id))

  const fees = {
    [manageTypes.MANAGED]: (
      <Typography size={desktop ? 18 : 16} weight="semibold" lineHeight="small" align="right">
        <Nobr>
          <Typo>0.25% annual fee</Typo>
        </Nobr>
      </Typography>
    ),
    [manageTypes.SELF_SELECTED]: (
      <Typography size={desktop ? 18 : 16} weight="semibold" lineHeight="small" align="right">
        <Nobr>
          <Typo>0.25% annual fee</Typo>
        </Nobr>
      </Typography>
    ),
    [manageTypes.DIY]: (
      <Typography color="green" size={desktop ? 18 : 16} weight="semibold" lineHeight="small" align="right">
        <Typo>Zero</Typo>
      </Typography>
    ),
    [manageTypes.CASH]: (
      <Typography color="green" size={desktop ? 18 : 16} weight="semibold" lineHeight="small" align="right">
        <Typo>Zero</Typo>
      </Typography>
    ),
  }

  return (
    <Card level={0}>
      <CardAccent color={portfolioColors[portfolio.manage_type]} />
      <div className="PortfolioCard-Wrapper">
        <Paper top={24} right={desktop ? 24 : 20} bottom={24} left={desktop ? 32 : 20}>
          <ColumnarLayout mods={{ padding: 'small' }}>
            <Column size={1}>
              <Typography
                size={desktop ? 18 : 16}
                lineHeight={desktop ? 'medium' : 'small'}
                weight="semibold"
                align="left"
              >
                <Typo allowHtml={false}>{title}</Typo>
              </Typography>
            </Column>
            <Column size={0}>
              {fees[portfolio.manage_type]}
              {!isZeroFeesEnabled && portfolio.regulatory_type === regulatoryTypes.SIPP && (
                <Paper top={4}>
                  <Typography
                    size={desktop ? 18 : 16}
                    lineHeight={desktop ? 'medium' : 'small'}
                    weight="semibold"
                    align="right"
                  >
                    <Nobr>
                      <Typo>+0.15% SIPP fee</Typo>
                    </Nobr>
                  </Typography>
                </Paper>
              )}
            </Column>
          </ColumnarLayout>
          <Paper top={4}>
            <PortfolioManagedBy
              manageType={portfolio.manage_type}
              regulatoryType={portfolio.regulatory_type}
              isDefaultPresetUsed={portfolio.is_default_preset_used}
            />
          </Paper>
        </Paper>
      </div>
    </Card>
  )
}

export { PortfolioCard }
