import React, { Fragment, type ReactNode } from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries, useMemo } from 'hooks'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Relative } from 'components/atoms/Relative'

import { type NotificationActionLinkProps, type NotificationIcon } from '../useNotification'

import './ActionNotification.css'

type ActionNotificationProps = {
  icon: NotificationIcon
  title?: ReactNode
  body?: ReactNode
  actionLinkProps?: NotificationActionLinkProps
  disabled?: boolean
  'data-test-id'?: string | null
}

function ActionNotification({
  icon,
  title,
  body,
  actionLinkProps,
  disabled,
  'data-test-id': dataTestId,
}: ActionNotificationProps): React.ReactElement {
  const { desktop } = useMediaQueries()

  const paperSizes = useMemo(() => {
    if (desktop) {
      return {
        top: 16,
        right: 40,
        bottom: 16,
        left: 40,
      }
    }

    return {
      top: 12,
      right: 16,
      bottom: 12,
      left: 16,
    }
  }, [desktop])

  const bodyClasses = useMemo(
    () => classNames('ActionNotification-Body', { 'ActionNotification-Body_disabled': disabled }),
    [disabled],
  )

  return (
    <Relative className="ActionNotification" data-test-id={dataTestId}>
      {actionLinkProps && !disabled && <Link className="ActionNotification-Link" {...actionLinkProps} />}
      <Card className={bodyClasses} level={3}>
        <Paper {...paperSizes}>
          <ItemWithIcon
            icon={<Icon size={24} type={icon.type} color={icon.color} data-test-id="notificationIcon" />}
            space={12}
            content={
              <Fragment>
                {title}
                {body}
              </Fragment>
            }
          />
        </Paper>
      </Card>
    </Relative>
  )
}

export { ActionNotification, type ActionNotificationProps }
