type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLButtonElement | HTMLTextAreaElement

function isInputElement(element?: unknown | HTMLElement): element is HTMLInputElement {
  return element instanceof HTMLInputElement
}

function isSelectElement(element?: unknown | HTMLElement): element is HTMLSelectElement {
  return element instanceof HTMLSelectElement
}

function isButtonElement(element?: unknown | HTMLElement): element is HTMLButtonElement {
  return element instanceof HTMLButtonElement
}

function isTextAreaElement(element?: unknown | HTMLElement): element is HTMLTextAreaElement {
  return element instanceof HTMLTextAreaElement
}

function isFormControlElement<T extends FormControlElement>(element?: unknown): element is T {
  return isInputElement(element) || isSelectElement(element) || isButtonElement(element) || isTextAreaElement(element)
}

export {
  type FormControlElement,
  isInputElement,
  isSelectElement,
  isButtonElement,
  isTextAreaElement,
  isFormControlElement,
}
