import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import ColumnarLayout, {
  propTypes as ColumnarLayoutPropTypes,
} from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

const ButtonGroup = (props) => {
  const { desktop } = useMediaQueries()

  const {
    className,
    children,
    columnarLayoutMods = {
      padding: desktop ? null : 'no',
    },
    buttonMods = {
      size: 'big block',
      theme: desktop ? null : 'not-rounded',
    },
    flexDirection,
  } = props

  const classes = classNames(className, 'ButtonGroup')

  return (
    <ColumnarLayout className={classes} mods={columnarLayoutMods} flexDirection={flexDirection}>
      {(children && children.length > 1 ? children : [children])
        .filter(Boolean)
        .map(({ props: { mods = {}, ...restProps } = {}, ...child }, i) =>
          React.cloneElement(child, {
            key: i,
            className: 'ButtonGroup-Button',
            stretch: !desktop,
            ...restProps,
            mods: {
              ...(buttonMods || {}),
              block: true,
              ...mods,
            },
          }),
        )}
    </ColumnarLayout>
  )
}

ButtonGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  columnarLayoutMods: PropTypes.shape({
    padding: PropTypes.string,
  }),
  buttonMods: PropTypes.shape({
    size: PropTypes.string,
    theme: PropTypes.string,
  }),
  flexDirection: ColumnarLayoutPropTypes.direction,
}

export default ButtonGroup
