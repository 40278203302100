import moment from 'moment-timezone'

function isTradingWindowNow(): boolean {
  const currentDate = new Date()
  const currentTime = moment(currentDate)
  currentTime.tz('Europe/London')

  const tradingWindowStart = moment(currentDate).tz('Europe/London').set({ hour: 14, minute: 0 })
  const tradingWindowEnd = moment(currentDate).tz('Europe/London').set({ hour: 19, minute: 0 })

  return currentTime.isSameOrAfter(tradingWindowStart) && currentTime.isBefore(tradingWindowEnd)
}

export { isTradingWindowNow }
