import { useCallback, useEffect, useMemo, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { format as formatMoney } from 'helpers/money'

const steps: Record<string, LumpSumStep> = {
  AMOUNT: 'AMOUNT',
  REGULATORY_TYPE: 'REGULATORY_TYPE',
}

type LumpSumStep = 'AMOUNT' | 'REGULATORY_TYPE'

type UseLumpSumSetupProps = {
  isOpen: boolean
  needToChooseRegulatoryType: boolean
  analyticsData: Record<string, undefined>
  handleContinue: (data: { amount: string | null; regulatoryType?: string }) => Promise<void>
}

type UseLumpSumSetupReturnProps = {
  step: LumpSumStep
  amount: string | null
  validation: {
    amount: {
      rules: boolean[]
      errors: string[]
    }
  }
  handleInputAmount: (event, value: string) => void
  handleAmountContinue: () => void
  handleSubmitRegulatoryType: (regulatoryType: string) => void
}

const LUMP_SUM_MIN_AMOUNT = 100

const useLumpSumSetup = ({
  isOpen,
  needToChooseRegulatoryType,
  analyticsData,
  handleContinue,
}: UseLumpSumSetupProps): UseLumpSumSetupReturnProps => {
  const [step, setStep] = useState<LumpSumStep>(steps.AMOUNT)
  const [amount, setAmount] = useState<string | null>(null)

  useEffect(() => {
    if (!isOpen) {
      setStep(steps.AMOUNT)
      setAmount(null)
    }
  }, [isOpen])

  const validation = useMemo(
    () => ({
      amount: {
        rules: [parseFloat(amount ?? '0') >= LUMP_SUM_MIN_AMOUNT],
        errors: [`Must be at least ${formatMoney(LUMP_SUM_MIN_AMOUNT)}`],
      },
    }),
    [amount],
  )

  const handleInputAmount = useCallback((event, value: string) => {
    setAmount(value)
  }, [])

  const handleAmountContinue = useCallback(() => {
    trackEvent({
      action: 'sp_ls_amount_submitted',
      amount: amount?.toString() ?? 'Unable to get amount',
      ...(analyticsData || {}),
    })

    if (needToChooseRegulatoryType) {
      setStep(steps.REGULATORY_TYPE)
      return
    }

    handleContinue({ amount })
  }, [needToChooseRegulatoryType, analyticsData, handleContinue, amount])

  const handleSubmitRegulatoryType = useCallback(
    (regulatoryType) => {
      handleContinue({ amount, regulatoryType })
    },
    [handleContinue, amount],
  )

  return {
    step,
    amount,
    validation,
    handleInputAmount,
    handleAmountContinue,
    handleSubmitRegulatoryType,
  }
}

export { useLumpSumSetup, steps }
