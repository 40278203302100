import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { usePrevious, useSelector, useEffect, useMemo, useLoading, useActions } from 'hooks'

import { identify, analyticsCIO } from 'helpers/analytics'
import { format as formatMoney } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router.js'

import type { QuickStart } from 'app/effector/quickStart/types'

import {
  fetchGoal as fetchGoalActionCreator,
  setValid as setGoalValidActionCreator,
} from 'app/redux/actions/portfolios'
import type { Preset } from 'app/redux/models/portfolio/types'
import { selectGoalTitle } from 'app/redux/selectors'

import { useCommonPortfolio } from 'app/pages/Dashboard/Goals/hooks/useCommonPortfolio'

import type { ManagedPortfolio, RouteProps } from '../types'

import { types as clientTypes } from 'constants/client'
import { regulatoryTypes } from 'constants/portfolio'
import { quickStartStatuses } from 'constants/quickStart'

const getDefaultGoal = (goals: ManagedPortfolio[] = []): ManagedPortfolio => {
  const payedGoal = goals.find((goal) => goal.contributions && parseFloat(goal.contributions.total) > 0)

  return payedGoal ?? goals[0]
}

type UseManagedPortfolioProps = {
  id: string
  location: {
    query: {
      view: string
    }
  }
}

type UseManagedPortfolioInterface = {
  isPortfolioLoading: boolean
  goal: ManagedPortfolio
  goals: ManagedPortfolio[]
  goalTitle: string
  lastRoute?: RouteProps
  showFinishSavingsPlanBanner: boolean
  showLumpSumBanner: boolean
  quickStart?: QuickStart
  regulatoryTypeText: string
  preset: Preset
}

const useManagedPortfolio = ({ id, location }: UseManagedPortfolioProps): UseManagedPortfolioInterface => {
  const portfolioId = parseInt(id, 10)

  const { savingsPlan, quickStart } = useCommonPortfolio({ portfolioId })

  const goals = useSelector((state) => state.portfolios.items)
  const goalTitle = useSelector((state) => selectGoalTitle(state, portfolioId))
  const client = useSelector((state) => state.client)
  const { presets } = useSelector((state: any) => state.portfolios)

  const isLumpSum = !quickStart?.frequency
  const showFinishSavingsPlanBanner = quickStart?.status === quickStartStatuses.ACTIVE && !savingsPlan && !isLumpSum
  const showLumpSumBanner = quickStart?.status === quickStartStatuses.ACTIVE && isLumpSum

  const prevId = usePrevious(id)

  const { isLoading: isPortfolioLoading, wait: waitForPortfolio } = useLoading()

  const [fetchGoal, setValid] = useActions([fetchGoalActionCreator, setGoalValidActionCreator])

  const goal: ManagedPortfolio = useMemo(() => {
    const goal = !isNil(id) ? goals.find((goal) => goal.id === parseInt(id, 10)) : null

    if (goal) return goal

    return getDefaultGoal(goals)
  }, [goals, id])

  const preset = presets?.GROWTH?.find((preset: any) => preset.id === goal?.preset)

  const fetchGoalWithDependencies = async (): Promise<void> => {
    const stateAfterFetch = await fetchGoal(portfolioId, { setValidAfter: false })

    const currentBalance = stateAfterFetch.portfolios.items.reduce(
      (acc, goal) => Number(acc) + Number(goal.current_balance),
      0,
    )
    identify(client.id, { clientId: client.id, clientBalance: formatMoney(currentBalance, true) })

    if (client.email) {
      analyticsCIO?.identify({
        id: client.email,
      })
    }

    setValid()
  }

  // If we don’t have any loaded goals at all
  useEffect(() => {
    if (isEmpty(goals) || (id && id !== prevId)) {
      waitForPortfolio(Promise.all([fetchGoalWithDependencies()]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (goal?.id && !isPortfolioLoading && isNil(id)) {
      goTo(urlTo('dashboard.portfolio', { id: goal.id }, location.query))
    }
  }, [goal?.id, isPortfolioLoading, id, location.query])

  const regulatoryTypeText = useMemo(
    () =>
      ({
        [regulatoryTypes.GIA]: client.type === clientTypes.BUSINESS ? 'Business' : 'General',
        [regulatoryTypes.ISA]: 'ISA',
        [regulatoryTypes.SIPP]: 'Personal Pension',
      })[goal.regulatory_type],
    [client.type, goal.regulatory_type],
  )

  return {
    isPortfolioLoading,
    goals,
    goal,
    goalTitle,
    showFinishSavingsPlanBanner,
    showLumpSumBanner,
    quickStart,
    regulatoryTypeText,
    preset,
  }
}

export { useManagedPortfolio }
