const type = {
  BUY: 'BUY',
  SELL: 'SELL',
  REBALANCE: 'REBALANCE',
  AUTOINVEST: 'AUTOINVEST',
}

const scope = {
  PORTFOLIO: 'PORTFOLIO',
  SECURITY: 'SECURITY',
}

export { type, scope }
