import React from 'react'
import PropTypes from 'prop-types'

import { useActions, useCallback, useSelector } from 'hooks'

import {
  saveClientAnswer as saveClientAnswerActionCreator,
  setValid as setValidActionCreator,
  resetError as resetErrorActionCreator,
} from 'app/redux/actions/questionnaire'
import { showFailToast } from 'app/redux/actions/ui'

import { getClientAnswerValues, selectSelectedGoal } from 'app/redux/selectors'
import { trackEvent } from 'helpers/analytics'

const ServerSaveClientAnswer = (props) => {
  const { code, redirectToNextStep, onAfterSave, params, analytics, analyticsCategory, isFinalStep } = props
  const { children, ...propsWithoutChildren } = props
  const goal = useSelector(selectSelectedGoal)
  const clientAnswerValues = useSelector(getClientAnswerValues)
  const [saveClientAnswer, setValid, resetError] = useActions([
    saveClientAnswerActionCreator,
    setValidActionCreator,
    resetErrorActionCreator,
  ])
  const getAnswers = useCallback((code) => clientAnswerValues[code], [clientAnswerValues])

  const handleSubmit = useCallback(
    async (_event, answers) => {
      if (analytics) {
        trackEvent({
          ...analytics,
          portfolioPresetType: goal.preset_type,
          portfolioRegulatoryType: goal.regulatory_type,
        })

        if (isFinalStep) {
          trackEvent({
            category: analyticsCategory,
            action: 'Complete questionnaire',
            label: `${goal.regulatory_type} ${goal.preset_type}`,
            portfolioPresetType: goal.preset_type,
            portfolioRegulatoryType: goal.regulatory_type,
          })
        }
      }

      const afterSaveState = await saveClientAnswer(code, { answer_ids: answers, goal_id: goal?.id }, false)

      if (afterSaveState.questionnaire.error) {
        setValid()
        showFailToast()
        return resetError()
      }

      if (typeof onAfterSave === 'function') {
        await onAfterSave()
      }

      setValid()
      redirectToNextStep()
    },
    [code, redirectToNextStep, saveClientAnswer, resetError, params, goal],
  )

  return React.cloneElement(children, { ...propsWithoutChildren, getAnswers, onSubmit: handleSubmit })
}

ServerSaveClientAnswer.propTypes = {
  code: PropTypes.string,
  redirectToNextStep: PropTypes.func,
  onAfterSave: PropTypes.func,
}

export { ServerSaveClientAnswer }
