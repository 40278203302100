import { useUnit } from 'effector-react'

import { useActions, useEffect, useSelector } from 'hooks'

import { $allowancesStore, fetchAllowancesFx } from 'app/effector/allowances'
import { $quickStartsStore, fetchPortfolioQuickStartFx } from 'app/effector/quickStart'
import type { QuickStart } from 'app/effector/quickStart/types'
import {
  $recurringPaymentsStore,
  fetchRecurringPaymentsFx,
  type DirectDebitSubscription,
  type RecurringPaymentSubscription,
} from 'app/effector/recurringPayments'

import { fetchPortfolio as fetchPortfolioActionCreator } from 'app/redux/actions/portfolios'
import type { Portfolio } from 'app/redux/models/portfolio/types'

import { regulatoryTypes } from 'constants/portfolio'

type UseCommonPortfolioProps = {
  portfolioId: number
}

type UseCommonPortfolioReturnProps = {
  portfolio: Portfolio
  savingsPlan?: DirectDebitSubscription | RecurringPaymentSubscription
  quickStart?: QuickStart
}

const useCommonPortfolio = ({ portfolioId }: UseCommonPortfolioProps): UseCommonPortfolioReturnProps => {
  const portfolio = useSelector((state) => state.portfolios.list.get(portfolioId))

  const [fetchPortfolio] = useActions([fetchPortfolioActionCreator])

  const { areRecurringPaymentsFetched, getReccuringPaymentByPortfolioId, getDirectDebitByPortfolioId } =
    useUnit($recurringPaymentsStore)
  const recurringPayment = getReccuringPaymentByPortfolioId(portfolioId)
  const directDebit = getDirectDebitByPortfolioId(portfolioId)
  const savingsPlan = recurringPayment ?? directDebit
  const { getQuickStartByPortfolioId } = useUnit($quickStartsStore)
  const quickStart = getQuickStartByPortfolioId(portfolioId)

  const { areAllowancesFetched } = useUnit($allowancesStore)

  useEffect(() => {
    if (portfolioId) {
      fetchPortfolio(portfolioId)
      fetchPortfolioQuickStartFx(portfolioId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId])

  useEffect(() => {
    if (!areRecurringPaymentsFetched) {
      fetchRecurringPaymentsFx()
    }
  }, [areRecurringPaymentsFetched])

  useEffect(() => {
    if (!areAllowancesFetched && [regulatoryTypes.ISA, regulatoryTypes.SIPP].includes(portfolio?.regulatory_type)) {
      fetchAllowancesFx()
    }
  }, [areAllowancesFetched, portfolio])

  return {
    portfolio,
    savingsPlan,
    quickStart,
  }
}

export { useCommonPortfolio }
