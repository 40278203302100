import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useClickOutside } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

import './DotsMenu.css'
import Link from 'components/_old/Link/Link.jsx'

const DotsMenu = ({ children, className, 'data-test-id': dataTestId }) => {
  const [visible, setVisible] = useState(false)
  const [rootRef, hasClickedOutside] = useClickOutside()

  const handleToggleOpen = useCallback(() => {
    setVisible((prevVisble) => !prevVisble)
  }, [setVisible])

  const handleClose = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  useEffect(() => {
    if (hasClickedOutside) {
      handleClose()
    }
  }, [hasClickedOutside, handleClose])

  const classes = classNames(className, 'DotsMenu')

  return (
    <div ref={rootRef} className={classes}>
      <Link
        className="DotsMenu-Link"
        data-test-id={dataTestId}
        mods={{ color: 'mutedToRed' }}
        onClick={handleToggleOpen}
      >
        <AllCenter>
          <Icon className="DotsMenu-Icon" type="dots-menu" color="inherit" />
        </AllCenter>
      </Link>
      {visible && (
        <div className="DotsMenu-Box" data-test-id="DotsMenuContent">
          <div className="DotsMenu-BoxInner">{children}</div>
        </div>
      )}
    </div>
  )
}

DotsMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default DotsMenu
