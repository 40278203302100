import { useCallback, useLoading, useState } from 'hooks'

import { confirmPhone } from 'app/effector/contacts/api'

import { showFailToast } from 'app/redux/actions/ui'
import { setup2faConfirmPhone, sendSmsCode } from 'app/redux/api/2fa'
import { ApiError } from 'app/redux/models/errors'

type UseSmsSendProps = {
  action: 'login' | 'setup_2fa' | 'confirm_phone'
  clientId?: string
  token?: string
  onSuccessSmsSend: () => void
  onConfirmIdentityError?: () => void
}

type UseSmsSendReturnProps = {
  isLoading: boolean
  blockedText: string
  isAlreadyInUse: boolean
  handleSmsSend: (data: { phone: string }) => Promise<void>
  clearErrors: () => void
}

const useSmsSend = ({
  action,
  clientId,
  token,
  onSuccessSmsSend,
  onConfirmIdentityError,
}: UseSmsSendProps): UseSmsSendReturnProps => {
  const [blockedText, setBlockedText] = useState('')
  const [isAlreadyInUse, setIsAlreadyInUse] = useState(false)

  const { isLoading, wait } = useLoading(false)

  const handleSmsSend = useCallback(
    async ({ phone }) => {
      try {
        let data

        if (action === 'login') {
          data = await wait(sendSmsCode({ clientId, token }))
        }

        if (action === 'setup_2fa') {
          data = await wait(setup2faConfirmPhone({ phone }))
        }

        if (action === 'confirm_phone') {
          data = await wait(confirmPhone({ phone }))
        }

        if (data instanceof ApiError) {
          throw data
        }

        onSuccessSmsSend()
      } catch (error) {
        if (error?.response?.data?.error === 'already_used') {
          setIsAlreadyInUse(true)
          return
        }

        if (error?.response?.data?.error === 'too_many_requests') {
          setBlockedText('Too many attempts. Blocked for 1 hour')
          return
        }

        if (error?.response?.data?.error === 'resend_timeout') {
          setBlockedText('Try later')
          return
        }

        if (error?.response?.data?.error === 'need_password_confirmation' && onConfirmIdentityError) {
          onConfirmIdentityError()
          return
        }

        showFailToast('Something went wrong, please try again or contact us at support@investengine.com')
      }
    },
    [action, clientId, token, onConfirmIdentityError, onSuccessSmsSend, wait],
  )

  const clearErrors = useCallback(() => {
    setBlockedText('')
    setIsAlreadyInUse(false)
  }, [])

  return { isLoading, blockedText, isAlreadyInUse, handleSmsSend, clearErrors }
}

export { useSmsSend }
