import React from 'react'
import { withRouter } from 'react-router'

import { useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { Modal } from 'components/molecules/Modal'

import { SetUpInstantBank } from 'app/pages/Dashboard/Goals/AddFunds/SetUpInstantBank/SetUpInstantBank.jsx'
import type { RouteProps } from 'app/pages/Dashboard/UserProfile/types'

type OpenBankingModalProps = {
  routes: RouteProps[]
  location: {
    query?: Record<string, string>
  }
}

const OpenBankingModal = withRouter(({ routes, location }: OpenBankingModalProps): React.ReactElement => {
  const currentRouteModule = routes[routes.length - 1].module
  const isOpen = currentRouteModule === 'set-up-instant-bank'

  const handleClose = useCallback(() => {
    goTo(location?.query?.back ?? urlTo('user-profile.savings-plans'))
  }, [location])

  const nextUrl = urlTo('dashboard.user-profile.savings-plans.new-bank')

  return (
    <Modal open={isOpen} onClose={handleClose} close={null}>
      <SetUpInstantBank location={location} tunnelQuery={location.query} handleClose={handleClose} nextUrl={nextUrl} />
    </Modal>
  )
})

export { OpenBankingModal }
