import { goTo, urlTo } from 'helpers/router.js'

const TunnelStepAdapter = ({ routes, params }) => {
  const getActiveStepIndex = (steps) =>
    steps.findIndex((step) => {
      const currentModule = [...routes].reverse().find((route) => route.module === step.initModule)

      return step.insertableQuestions ? step.questionCode === params.questionCode && currentModule : currentModule
    })

  const getStepByName = (steps, module) => steps.find((step) => step.initModule === module)

  const redirect = (step, customParams, customQueryParams) => {
    const stepParams = step.insertableQuestions ? { ...params, questionCode: step.questionCode } : params
    goTo(urlTo(step.module, { ...customParams, ...stepParams }, customQueryParams))
  }

  return {
    getActiveStepIndex,
    getStepByName,
    redirect,
  }
}

export { TunnelStepAdapter }
