import { useState, useEffect } from 'react'

/**
 * @param {Element|React.MutableRefObject<Element>} elementOrRef
 * @param {IntersectionObserverInit} options
 * @return {?boolean}
 */
const useOnScreen = (elementOrRef, options) => {
  /** @type {Element} */
  const node = elementOrRef?.current ? elementOrRef.current : elementOrRef
  const [isIntersecting, setIntersecting] = useState(null)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
    }, options)

    if (node) {
      observer.observe(node)
    }

    return () => {
      if (node) {
        observer.unobserve(node)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])

  return isIntersecting
}

export { useOnScreen }
