import { attach, merge } from 'effector'
import { useUnit } from 'effector-react'

import { useEffect, useMemo } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { $reportsStore, fetchReportsFx, fetchReportTypesFx } from 'app/effector/reports'
import { type ReportList } from 'app/effector/reports/models'

import { showFailToast } from 'app/redux/actions/ui'

type UseReportsInterface = {
  reports: ReportList
  isLoading: boolean
  isFetched: boolean
  hasReports: boolean
}

const useReports = (): UseReportsInterface => {
  const { reports, isLoading, isFetched } = useUnit($reportsStore)
  const hasReports = useMemo(() => !!reports?.length, [reports])

  useEffect(() => {
    const reportsViewFetchReportsFx = attach({ effect: fetchReportsFx })
    const reportsViewFetchReportTypesFx = attach({ effect: fetchReportTypesFx })
    const failWatch = merge([reportsViewFetchReportsFx.fail, reportsViewFetchReportTypesFx.fail])

    const unwatch = failWatch.watch(() => {
      goTo(urlTo('dashboard'), { replace: true })
      showFailToast('Something went wrong')
    })

    reportsViewFetchReportsFx()
    reportsViewFetchReportTypesFx()

    return () => {
      unwatch()
    }
  }, [])

  return {
    reports,
    isLoading,
    isFetched,
    hasReports,
  }
}

export { useReports, type UseReportsInterface }
