/* eslint-disable no-console */
import React from 'react'

import { Hint } from 'components/molecules/Hint'

import { type Hint as HintType } from './types'
import { useHints } from './useHints'
import { useHintsStore } from './useHintsStore'

type HintsProps = {
  nameKey: string
  hints: HintType[]
  forceArrowHorizontalAlign?: 'left' | 'right'
}

type HintsContentProps = {
  hints: HintType[]
  forceArrowHorizontalAlign?: 'left' | 'right'
  handleHintsDisplayed: () => Promise<void>
}

const Hints = ({ nameKey, hints, forceArrowHorizontalAlign }: HintsProps): React.ReactElement | null => {
  const { shouldDisplayHints, handleHintsDisplayed } = useHintsStore({ nameKey })

  const areHintsValid = hints.every((hint) => hint?.ref?.current)

  if (!areHintsValid) {
    console.error('@hints | Invalid refs for hints provided')
    return null
  }

  if (!hints.length) {
    console.error('@hints | No hints provided')
    return null
  }

  return shouldDisplayHints ? (
    <HintsContent
      hints={hints}
      handleHintsDisplayed={handleHintsDisplayed}
      forceArrowHorizontalAlign={forceArrowHorizontalAlign}
    />
  ) : null
}

const HintsContent = ({
  hints,
  handleHintsDisplayed,
  forceArrowHorizontalAlign,
}: HintsContentProps): React.ReactElement | null => {
  const { isHintVisible, closeHint, sizes, hintText } = useHints({ hints, handleHintsDisplayed })

  return isHintVisible ? (
    <Hint sizes={sizes} closeHint={closeHint} forceArrowHorizontalAlign={forceArrowHorizontalAlign} arrowPadding={5}>
      {hintText}
    </Hint>
  ) : null
}

export { Hints }
