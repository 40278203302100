import * as cards from './cards.js'

const MANAGED_TYPE = 'MANAGED_TYPE'
const INDIVIDUAL_TYPE = 'INDIVIDUAL_TYPE'

const PERSONAL_WITH_SIPP = {
  preselectedData: [],
  steps: [
    [
      INDIVIDUAL_TYPE,
      [
        {
          ...cards.ISA,
          goTo: () => MANAGED_TYPE,
        },
        {
          ...cards.SIPP,
          goTo: () => MANAGED_TYPE,
        },
        {
          ...cards.GIA,
          goTo: () => MANAGED_TYPE,
        },
      ],
      'Select an account',
    ],
    [MANAGED_TYPE, [cards.DIY, cards.SELF_SELECTED, cards.MANAGED, cards.MANAGED_DEFAULT], 'Create portfolio'],
  ],
}

export { PERSONAL_WITH_SIPP }
