import React, { Fragment, lazy, Suspense } from 'react'

import capitalize from 'lodash/capitalize'

import { useMemo } from 'hooks'

import { features } from 'helpers/features'

import { AnalyticsItemType } from 'app/effector/analytics/types'

import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Badge } from 'components/atoms/Badge'
import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { Skeleton } from 'components/atoms/Skeleton'
import { TextCollapse } from 'components/atoms/TextCollapse'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { CountriesList } from '../components/CountriesList'
import { DistributionChart } from '../components/DistributionChart'
import { PortfolioTitle } from '../components/PortfolioTitle'
import { PortfolioWithHolding } from '../components/PortfolioWithHolding'
import { type SingleHoldingProps } from '../SingleHolding'

const CountryFlag = lazy(async () => await import(/* webpackChunkName: "CountryFlag" */ 'components/atoms/CountryFlag'))

type SingleHoldingDesktopProps = SingleHoldingProps & {
  fromPortfolio: boolean
  type: AnalyticsItemType
}

const Desktop = ({
  isLoading,
  type,
  fromPortfolio,
  portfolios,
  selectedPortfolio,
  showTarget,
  holdingData,
  holdingDistribution,
  sortedPortfolioIds,
  totalAmount,
  isCountriesTabActive,
  regionTabs,
  navigateToRegionTab,
  handleBack,
  handleGoToSecurity,
  navigateToCountry,
}: SingleHoldingDesktopProps): React.ReactElement => {
  const hasRegionOrSectorInfo = useMemo(
    () => Boolean(holdingData?.sector_name) || Boolean(holdingData?.region_name),
    [holdingData],
  )

  const tabsNode = <GreyTabs tabs={regionTabs} onChange={navigateToRegionTab} />

  const headerNode = useMemo(() => {
    return <DesktopHeader activeItem={fromPortfolio ? 'dashboard' : 'analytics'} />
  }, [fromPortfolio])

  const backLinkNode = useMemo(
    () => (
      <Link onClick={handleBack} mods={{ color: 'content-on-background-default' }}>
        <ItemWithIcon
          space={16}
          icon={<Icon type="back-new" size={20} color="inherit" />}
          content={<Typography color="inherit">Back</Typography>}
          iconVerticalAlign="center"
          data-test-id="navigationLeftButton"
        />
      </Link>
    ),
    [handleBack],
  )

  const titleNode = useMemo(() => {
    const title = (
      <Typography size={32} lineHeight="small" weight="semibold" data-test-id="singleHoldingTitle">
        <Typo>{isLoading ? '█████' : holdingData?.name} (Equity)</Typo>
      </Typography>
    )

    if ((isLoading && type === 'holding') || holdingData?.logo) {
      return (
        <Skeleton shown={isLoading} inline mix>
          <ItemWithIcon
            space={24}
            icon={<SecurityLogo logo={holdingData?.logo} size={48} />}
            content={title}
            contentVerticalAlign="center"
            data-test-id="singleHoldingLogo"
            inline
          />
        </Skeleton>
      )
    }

    return (
      <Skeleton shown={isLoading} inline mix>
        {title}
      </Skeleton>
    )
  }, [isLoading, holdingData?.name, holdingData?.logo, type])

  const sectorAndRegionNode = useMemo(
    () =>
      hasRegionOrSectorInfo ? (
        <Paper top={24}>
          <Typography size={14}>
            {holdingData?.sector_name && (
              <Badge
                className="SingleHolding_Badge"
                text={holdingData?.sector_name}
                data-test-id="descriptionSectorBadge"
              />
            )}
            {holdingData?.region_name && (
              <Badge
                className="SingleHolding_Badge"
                text={holdingData?.region_name}
                data-test-id="descriptionRegionBadge"
              />
            )}
          </Typography>
        </Paper>
      ) : null,
    [hasRegionOrSectorInfo, holdingData],
  )

  const descriptionNode = useMemo(
    () =>
      holdingData?.description ? (
        <Paper top={hasRegionOrSectorInfo ? 16 : 24}>
          <Typography size={14} color="additional" data-test-id="holdingDescriptionInfo">
            <TextCollapse limit={280}>{holdingData?.description}</TextCollapse>
          </Typography>
        </Paper>
      ) : null,
    [holdingData?.description, hasRegionOrSectorInfo],
  )

  const websiteNode = useMemo(
    () =>
      holdingData?.website ? (
        <Paper top={24}>
          <Link to={holdingData?.website} blank>
            <Typography size={14} color="inherit" overflow="ellipsis" data-test-id="holdingDescriptionWebsite">
              <Nobr>
                <Typo>{holdingData?.website}</Typo>
              </Nobr>
            </Typography>
          </Link>
        </Paper>
      ) : null,
    [holdingData?.website],
  )

  const distributionTitleNode = useMemo(
    () => (
      <Paper top={56}>
        <Typography size={20} lineHeight="small" weight="semibold" data-test-id="singleHoldingWeightingsTitle">
          <Typo>
            Weightings of {type}
            {selectedPortfolio ? ' in this portfolio' : ' in all portfolios'}
          </Typo>
        </Typography>
      </Paper>
    ),
    [type, selectedPortfolio],
  )

  const selectedPortfolioNode = useMemo(
    () =>
      selectedPortfolio ? (
        <Paper top={8}>
          <PortfolioTitle portfolio={selectedPortfolio} />
        </Paper>
      ) : null,
    [selectedPortfolio],
  )

  const distributionNode = useMemo(
    () => (
      <Paper top={32}>
        <Skeleton shown={isLoading}>
          <Fragment>
            {showTarget ? (
              <Paper bottom={24}>
                <Typography data-test-id="actualTargetSubtitle">
                  <Typography tag="span" size={14} lineHeight="small" color="additional">
                    <Typo>Actual</Typo>
                  </Typography>
                  <Typography tag="span" size={12} lineHeight="small" color="minor">
                    &nbsp;/&nbsp;<Typo>Target</Typo>
                  </Typography>
                </Typography>
              </Paper>
            ) : null}
            <DistributionChart
              showTarget={showTarget}
              name={holdingData?.name}
              actualWeight={holdingData?.actual_weight}
              targetWeight={holdingData?.target_weight}
              distribution={holdingDistribution}
              selectedPortfolio={selectedPortfolio}
              totalAmount={totalAmount}
              actualAmount={holdingData?.actual_value}
            />
          </Fragment>
        </Skeleton>
      </Paper>
    ),
    [isLoading, holdingData, showTarget, holdingDistribution, selectedPortfolio, totalAmount],
  )

  const portfoliosNode = useMemo(() => {
    if (selectedPortfolio) {
      const holdingSecurities = holdingData?.portfolios?.[selectedPortfolio.id]

      return (
        <PortfolioWithHolding
          isLoading={isLoading}
          showTarget={showTarget}
          portfolioId={selectedPortfolio.id}
          holdingSecurities={holdingSecurities}
          handleGoToSecurity={handleGoToSecurity}
        />
      )
    }

    return sortedPortfolioIds.map((portfolioId) => {
      const portfolio = portfolios.get(parseInt(portfolioId, 10))

      return (
        portfolio && (
          <PortfolioWithHolding
            key={portfolioId}
            isLoading={isLoading}
            portfolio={portfolio}
            showTarget={showTarget}
            portfolioId={portfolioId}
            holdingSecurities={holdingData?.portfolios[portfolioId]}
            handleGoToSecurity={handleGoToSecurity}
          />
        )
      )
    })
  }, [
    isLoading,
    holdingData?.portfolios,
    sortedPortfolioIds,
    showTarget,
    selectedPortfolio,
    handleGoToSecurity,
    portfolios,
  ])

  const countryFlag = useMemo(() => {
    if (type !== AnalyticsItemType.COUNTRY) return null

    return (
      <Paper top={24}>
        <Suspense fallback={<div />}>
          <CountryFlag countryName={holdingData?.name} />
        </Suspense>
      </Paper>
    )
  }, [holdingData?.name, type])

  const holdingDescriptionNode = (
    <Card>
      <Paper top={24} bottom={24} left={24} right={24}>
        <Typography size={20} lineHeight="small" weight="semibold" data-test-id="holdingDescriptionTitle">
          <Typo>{capitalize(type)} description</Typo>
        </Typography>
        {sectorAndRegionNode}
        {countryFlag}
        {descriptionNode}
        {websiteNode}
      </Paper>
    </Card>
  )

  const countriesDescriptionNode = (
    <Card>
      <Paper top={24} bottom={24} left={24} right={24}>
        <Typography size={20} lineHeight="small" weight="semibold">
          <Typo>Countries description</Typo>
        </Typography>
        <Paper top={24}>
          <Typography size={14} color="additional">
            <Typo>
              We show the company country based on its registered headquarters address. Holding companies and different
              company structures may affect information.
            </Typo>
          </Typography>
        </Paper>
      </Paper>
    </Card>
  )

  const holdingContentNode = (
    <Fragment>
      {distributionTitleNode}
      {selectedPortfolioNode}
      {distributionNode}
      {portfoliosNode}
    </Fragment>
  )

  const countriesListNode = (
    <Paper top={56}>
      <CountriesList
        isLoading={isLoading}
        countries={holdingData?.countries}
        showTarget={showTarget}
        navigateToCountry={navigateToCountry}
      />
    </Paper>
  )

  const contentNode = (
    <Inner twoColumns>
      {backLinkNode}
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column size={1}>
          <Paper top={32}>{titleNode}</Paper>
          {features?.get('country-drill-down') && type === AnalyticsItemType.REGION && (
            <Paper top={32}>{tabsNode}</Paper>
          )}
          {isCountriesTabActive ? countriesListNode : holdingContentNode}
        </Column>
        <Column size={0}>
          <Paper left={80} top={32}>
            <Width size={19}>
              <Skeleton shown={isLoading} shownProps={{ style: { minHeight: 314 } }} mix>
                {isCountriesTabActive ? countriesDescriptionNode : holdingDescriptionNode}
              </Skeleton>
            </Width>
          </Paper>
        </Column>
      </ColumnarLayout>
    </Inner>
  )

  return <DesktopLayout header={headerNode} content={contentNode} footer={<DesktopFooter />} />
}

export { Desktop }
