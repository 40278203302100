import React, { Fragment } from 'react'

import { useMemo, useCallback, useState } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { TextCollapse } from 'components/atoms/TextCollapse'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { SpotlightTrigger, Spotlight } from 'components/molecules/Spotlight'

import { BonusBadge } from 'app/pages/SharedPortfolio/components/BonusBadge'

import { SharePortfolioSecurities } from '../components/SharePortfolioSecurities'
import { ShareToggler } from '../components/ShareToggler'
import { type SharingPortfolioHookData } from '../types'

import { SharePortfolioAnalytics } from './components/SharePortfolioAnalytics'

const Mobile = ({
  isPromoEnabled,
  securities,
  title,
  description,
  sectors,
  regions,
  equities,
  equity_types, // eslint-disable-line
  assets,
  closeSharePage,
  openShareModal,
  toggleShareLink,
  isLinkEnabled,
  handleSecurityClick,
  shareUrl: sharingEnabled,
  openTitleEditModal,
  handleOpenTsCsModal,
  isDescriptionExpanded,
  handleDescriptionExpand,
}: SharingPortfolioHookData): React.ReactElement => {
  const [layoutNode, setLayoutNode] = useState(null)
  const layoutRef = useCallback((ref) => {
    setLayoutNode(ref)
  }, [])

  const analyticsProps = useMemo(
    () => ({ sectors, regions, equities, equity_types, assets, layoutNode }),
    [sectors, regions, equities, equity_types, assets, layoutNode],
  )

  const headerNode = (
    <NavigationBar rightPartText="Close" onRightPartClick={closeSharePage}>
      Portfolio outline
    </NavigationBar>
  )

  const toggleNode = useMemo(
    () => (
      <Spotlight trigger="share" rules={[isLinkEnabled]} hint="Move this to ON to enable portfolio sharing">
        <Paper bottom={16} style={{ 'border-bottom': '1px solid var(--background-border)' }}>
          <ShareToggler isActive={isLinkEnabled} setIsActive={toggleShareLink} />
        </Paper>
      </Spotlight>
    ),
    [isLinkEnabled, toggleShareLink],
  )

  const portfolioTitleNode = useMemo(
    () => (
      <Paper top={40} bottom={40}>
        <Paper top={4} bottom={4}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1}>
              <Typography size={24} weight="semibold">
                <Typo allowHtml={false}>{title || 'Portfolio'}</Typo>
              </Typography>
            </Column>
            <Column size={0}>
              <Paper onClick={openTitleEditModal} style={{ cursor: 'pointer' }}>
                <Icon type="pencil-24" size={24} inline color={palette['content-on-background-primary']} />
              </Paper>
            </Column>
          </ColumnarLayout>
          <Paper top={description ? 12 : 24} bottom={4}>
            {description ? (
              <Typography size={14}>
                <TextCollapse
                  limit={200}
                  onExpand={handleDescriptionExpand}
                  shouldInitExpanded={isDescriptionExpanded}
                  multiline
                  maxLineBreaks={3}
                  allowHtml={false}
                >
                  {description}
                </TextCollapse>
              </Typography>
            ) : (
              <Link onClick={openTitleEditModal}>
                <SelectableCard>
                  <Paper top={12} right={20} bottom={12} left={20}>
                    <Typography color="inherit" align="center" lineHeight="small">
                      <ItemWithIcon
                        space={12}
                        contentVerticalAlign="center"
                        iconVerticalAlign="center"
                        icon={<Icon size={16} inline type="plus-16" color="inherit" />}
                        content={
                          <Typography lineHeight="small" color="inherit" inline>
                            Add description
                          </Typography>
                        }
                        inline
                      />
                    </Typography>
                  </Paper>
                </SelectableCard>
              </Link>
            )}
          </Paper>
        </Paper>
      </Paper>
    ),
    [title, description, isDescriptionExpanded, handleDescriptionExpand, openTitleEditModal],
  )

  const securitiesNode = useMemo(
    () => <SharePortfolioSecurities securities={securities} onClick={handleSecurityClick} />,
    [securities, handleSecurityClick],
  )

  const analyticsNode = useMemo(
    () => <SharePortfolioAnalytics {...analyticsProps} securitiesNode={securitiesNode} securities={securities} />,
    [analyticsProps, securitiesNode, securities],
  )

  const contentNode = useMemo(
    () => (
      <Fragment>
        {toggleNode}
        {portfolioTitleNode}
        {analyticsNode}
      </Fragment>
    ),
    [toggleNode, portfolioTitleNode, analyticsNode],
  )

  const footer = useMemo(
    () => (
      <MobileLayoutFooterButton>
        <div onClick={openShareModal as () => void}>
          <AllCenter>
            <SpotlightTrigger name="share">
              <Button type="submit" mods={{ size: 'big block' }} disabled={!isLinkEnabled}>
                <div>
                  <ItemWithIcon
                    space={12}
                    contentVerticalAlign="center"
                    icon={<Icon size={24} inline type="share" color={palette['content-on-color-default']} />}
                    content={
                      <Typography size={18} lineHeight="small" tag="span" color="on_color_default" inline>
                        Share
                      </Typography>
                    }
                    inline
                  />
                </div>
              </Button>
            </SpotlightTrigger>
            {isPromoEnabled && <BonusBadge handleOpenTsCsModal={handleOpenTsCsModal} />}
          </AllCenter>
        </div>
      </MobileLayoutFooterButton>
    ),
    [isPromoEnabled, openShareModal, isLinkEnabled, handleOpenTsCsModal],
  )

  return <MobileLayout ref={layoutRef} header={headerNode} content={contentNode} footer={footer} />
}

export { Mobile }
