import * as cards from './cards.js'

const INDIVIDUAL_TYPE = 'INDIVIDUAL_TYPE'

const PERSONAL_WITH_MANAGE_TYPE_WITH_SIPP = {
  preselectedData: [],
  steps: [[INDIVIDUAL_TYPE, [cards.ISA, cards.SIPP, cards.GIA], 'Select an account']],
}

export { PERSONAL_WITH_MANAGE_TYPE_WITH_SIPP }
