import { useCallback, useMemo, useState } from 'hooks'

/** @typedef  {'week' | 'month' | 'six_months' | 'year' | 'max'} PeriodValue */

/** @enum {PeriodValue} */
const PERIODS = {
  WEEK: 'week',
  MONTH: 'month',
  SIX_MONTHS: 'six_months',
  YEAR: 'year',
  MAX: 'max',
}

const NAME_BY_PERIOD = {
  [PERIODS.WEEK]: 'last week',
  [PERIODS.MONTH]: 'last month',
  [PERIODS.SIX_MONTHS]: 'last six months',
  [PERIODS.YEAR]: 'last year',
  [PERIODS.MAX]: 'max available period',
}

const usePeriod = (periodItem, defaultPeriod = PERIODS.MAX) => {
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod)

  const handlePeriodSelect = useCallback(
    (value) => {
      setSelectedPeriod(value)
    },
    [setSelectedPeriod],
  )

  const periodPriceData = useMemo(
    () => ({
      price: periodItem?.stats?.[selectedPeriod]?.return_money,
      percent: periodItem?.stats?.[selectedPeriod]?.return_percent,
      name: NAME_BY_PERIOD[selectedPeriod],
    }),
    [periodItem, selectedPeriod],
  )

  const contributions = periodItem?.contributions?.[selectedPeriod === PERIODS.MAX ? 'total' : selectedPeriod]

  return {
    selectedPeriod,
    handlePeriodSelect,
    periodPriceData,
    contributions,
  }
}

export { usePeriod, PERIODS }
