import React from 'react'

import classNames from 'classnames/dedupe'

import { useMemo } from 'hooks'

import { sendErrorOnce } from 'helpers/errorLogging'
import { palette } from 'helpers/palette'

import { ReportFormat } from 'app/effector/reports/models'

import Icon from 'components/_old/Icon/Icon.jsx'

import './ReportFormatIcon.css'

type ReportFormatIconProps = {
  className?: string
  format: ReportFormat
  size?: number
  inline?: boolean
}

function ReportFormatIcon({ className, format, size = 32, inline }: ReportFormatIconProps): React.ReactElement | null {
  const style: React.CSSProperties = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ({ '--report-format-icon-size': `${size}px` }) as React.CSSProperties,
    [size],
  )

  const classes = classNames(className, 'ReportFormatIcon')

  if (Object.values(ReportFormat).includes(format)) {
    const lowerCaseFormat = format.toLowerCase()

    return (
      <Icon
        className={classes}
        size={size}
        style={style}
        fixedSize
        type={`${lowerCaseFormat}-32`}
        color={palette['content-on-background-minor']}
        inline={inline}
      />
    )
  }

  sendErrorOnce(`Unknown format passed into 'ReportFormatIcon': ${format}`)

  return null
}

export { ReportFormatIcon, type ReportFormatIconProps }
