import camelCase from 'lodash/camelCase'
import sniffer from 'snifferjs'

const sniffed = sniffer(navigator.userAgent)

if (!window.navigator.userAgent.includes('PPTR')) {
  if (sniffed.os.fullName && sniffed.os.fullName.length > 0) {
    document.documentElement.classList.add(camelCase(sniffed.os.fullName))
  }

  if (sniffed.browser.name && sniffed.browser.majorVersion) {
    document.documentElement.classList.add(camelCase(sniffed.browser.name))
    document.documentElement.classList.add(camelCase(`${sniffed.browser.name}${sniffed.browser.majorVersion}`))
  }
}

export default sniffed
