import { useUnit } from 'effector-react'
import isEmpty from 'lodash/isEmpty'

import {
  useActions,
  useCallback,
  useDebouncedCallback,
  useMediaQueries,
  useMemo,
  useSelector,
  useEffect,
  useState,
} from 'hooks'

import axios from 'helpers/ajax'
import { features } from 'helpers/features'
import { format as formatMoney } from 'helpers/money'
import { backendErrorsToObj, combineErrors, monthlyInvestmentRules } from 'helpers/validation'

import { $allowancesStore } from 'app/effector/allowances'
import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $dictsStore } from 'app/effector/dicts'
import { $recurringPaymentsStore } from 'app/effector/recurringPayments'

import {
  fetchRecommended as fetchRecommendedActionCreator,
  changeField as changeGoalFieldActionCreator,
} from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'

import useEditablePortfolio from './useEditablePortfolio'

import { regulatoryTypes } from 'constants/portfolio'
import { MAX_SINGLE_PAYMENT_AMOUNT } from 'constants/validations'
import querystring from 'querystring'

const useGrowthProjectionsCommon = (portfolio, editablePortfolioFields) => {
  const [fetchRecommended, changeField] = useActions([fetchRecommendedActionCreator, changeGoalFieldActionCreator])
  const {
    presets,
    editablePortfolio,
    editableFields,
    isPortfolioChanged,
    isPresetChanged,
    change,
    updatePortfolioAppState,
    isPortfolioActive,
  } = useEditablePortfolio(portfolio, ['term', 'target'], changeField, editablePortfolioFields)

  const { hasDirectDebitByPortfolioId } = useUnit($recurringPaymentsStore)
  const hasDirectDebitSubscription = hasDirectDebitByPortfolioId(portfolio?.id)

  const { allowances } = useUnit($allowancesStore)
  const isaRemainingAllowance = parseFloat(allowances?.isa?.remaining_allowance ?? 0)

  const { initialDepositMin } = useUnit($dictsStore)
  const { nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)

  const isRecurringPaymentValidation =
    features.get('recurring-payments-release') &&
    !hasDirectDebitSubscription &&
    nominatedBankAccount?.bank?.recurring_payment_support
  const portfolios = useSelector((state) => state.portfolios)

  const maxOneOffPaymentAmount = useMemo(() => {
    if (portfolio?.regulatory_type === regulatoryTypes.ISA) {
      return isaRemainingAllowance
    }
    return MAX_SINGLE_PAYMENT_AMOUNT
  }, [portfolio, isaRemainingAllowance])

  const validation = combineErrors(
    {
      initial_deposit: {
        rules: [
          parseFloat(portfolio.current_balance) > 0 ||
            parseFloat(editablePortfolio.initial_deposit) >= initialDepositMin,
          editablePortfolio.initial_deposit <= MAX_SINGLE_PAYMENT_AMOUNT,
        ],
        errors: [`Must be at least ${formatMoney(initialDepositMin)}`, ` Must be less than 9 digits`],
      },
      monthly_deposit: monthlyInvestmentRules(editablePortfolio.monthly_deposit, {
        optional: true,
        limitMaximumAmount: false,
        isRecurringPaymentValidation,
      }),
      target: {
        rules: [
          editablePortfolio.target === null ||
            parseInt(editablePortfolio.target, 10) === 0 ||
            parseInt(editablePortfolio.target, 10) >= parseInt(editablePortfolio.initial_deposit, 10),
          editablePortfolio.target <= MAX_SINGLE_PAYMENT_AMOUNT,
        ],
        errors: ['Target must be more than the initial investment', `Must be less than 9 digits`],
      },
      one_off_payment: {
        rules: [editablePortfolio?.one_off_payment <= maxOneOffPaymentAmount],
        errors: [`Maximum amount is ${formatMoney(maxOneOffPaymentAmount)}`],
      },
    },
    backendErrorsToObj(portfolios.error),
  )

  const { desktop } = useMediaQueries()
  const monthly = desktop && editablePortfolio.term < 9

  const [projections, setProjections] = useState({})
  const [projectionsFetched, setProjectionsFetched] = useState(false)
  const [isRecommendedPresetLoading, setIsRecommendedPresetLoading] = useState(false)

  const fetchProjections = async (preset, initialDeposit, monthlyDeposit, term, monthly) => {
    try {
      const data = querystring.stringify({
        preset,
        initial_deposit: initialDeposit,
        monthly_deposit: monthlyDeposit,
        term,
        monthly,
      })
      const response = await axios.get(`projections/growth/?${data}`)

      setProjections({ ...response.data, monthly })
      setProjectionsFetched(true)
    } catch (error) {
      showFailToast()
    }
  }

  const updateProjections = useDebouncedCallback(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    async ({ preset, initial_deposit, current_balance, one_off_payment, monthly_deposit, term }) => {
      current_balance = parseFloat(current_balance || '0')
      one_off_payment = parseFloat(one_off_payment || '0')
      initial_deposit = parseFloat(initial_deposit || '0')
      const balance = current_balance > 0 ? current_balance + one_off_payment : initial_deposit

      await fetchProjections(preset, String(balance), monthly_deposit, term, monthly)
    },
    250,
    [monthly, fetchProjections],
  )

  const updateRecommended = useDebouncedCallback(
    (term, id) => {
      if (term && id) {
        setIsRecommendedPresetLoading(true)
        fetchRecommended(term, id).then(([_nextState, error]) => {
          setIsRecommendedPresetLoading(false)
          if (error) {
            showFailToast('Something went wrong, please try again later')
          }
        })
      }
    },
    250,
    [fetchRecommended],
  )

  const handleChange = useCallback(
    (field, value = null) => {
      if (isEmpty(portfolio)) {
        return
      }

      if (field !== 'target') {
        setProjectionsFetched(false)
      }

      change(field, value)
    },
    [change, portfolio],
  )

  // Request recommends

  useEffect(
    () => {
      updateRecommended(editablePortfolio.term, portfolio.id)
    },
    // had to run only when term was changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editablePortfolio.term],
  )

  // Request projections
  useEffect(
    () => {
      updateProjections({
        portfolioId: portfolio.id,
        preset: editablePortfolio.preset,
        initial_deposit: editablePortfolio.initial_deposit,
        current_balance: isPortfolioActive ? editablePortfolio.current_balance : null,
        one_off_payment: editablePortfolio.one_off_payment,
        monthly_deposit: editablePortfolio.monthly_deposit,
        term: editablePortfolio.term,
      })
    },
    // had to run if active state changed or one of editable fields changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isPortfolioActive,
      editablePortfolio.preset,
      editablePortfolio.initial_deposit,
      editablePortfolio.one_off_payment,
      editablePortfolio.monthly_deposit,
      editablePortfolio.term,
    ],
  )

  return {
    presets,
    editablePortfolio,
    editableFields,
    isPortfolioChanged,
    isPresetChanged,
    validation,
    projections,
    projectionsFetched,
    isPortfolioActive,
    isRecommendedPresetLoading,
    handleChange,
    updatePortfolioAppState,
  }
}

export default useGrowthProjectionsCommon
