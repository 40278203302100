import xor from 'lodash/xor'

import { useCallback, useState, useEffect } from 'hooks'

const useMultipleChoice = (
  answers: Answer[],
  defaultSelected: number[] | null,
): { selectedAnswers: number[]; handleSelect: (id: number) => void } => {
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([])

  useEffect(() => {
    if (!defaultSelected) return
    setSelectedAnswers(defaultSelected)
  }, [defaultSelected])

  const handleSelect = useCallback(
    (answerId: number) => {
      const exclusiveAnswer = answers.find((answer) => answer.extra_data?.exclusive)
      const exclusiveId = exclusiveAnswer ? exclusiveAnswer.id : null

      if (answerId === exclusiveId) {
        selectedAnswers.includes(answerId) ? setSelectedAnswers([]) : setSelectedAnswers([answerId])
        return
      }

      const selectedAnswersWithoutExclusive = selectedAnswers.filter((id) => id !== exclusiveId)

      setSelectedAnswers(xor(selectedAnswersWithoutExclusive, [answerId]))
    },
    [answers, selectedAnswers],
  )

  return { selectedAnswers, handleSelect }
}

export { useMultipleChoice }
