import React from 'react'

import { useUnit } from 'effector-react'

import { useCallback, useMediaQueries, useMemo, useSelector } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'

import { $owner } from 'app/effector/contacts'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

import { PhoneNumberSetting } from '../../components/PhoneNumberSetting'

type PhoneModalProps = {
  open: boolean
}

const PhoneModal = ({ open }: PhoneModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const client = useSelector((state) => state.client)
  const owner = useUnit($owner) ?? {}

  const handleClose = useCallback(() => {
    const backUrl = urlTo('dashboard.user-profile.login-settings')

    goTo(backUrl)
  }, [])

  const descriptionNode = useMemo(
    () => (
      <ItemWithIcon
        space={12}
        icon={<Icon type="information-24" size={24} color="inherit" />}
        content={
          <Typography size={desktop ? 14 : 12}>
            <Typo>SMS messaging may be used as an alternative to notifications for two-factor authentication</Typo>
          </Typography>
        }
      />
    ),
    [desktop],
  )

  const phoneNode = (
    <PhoneNumberSetting
      country={client.country_code_by_ip}
      phone={owner.phone}
      isConfirmed={owner.phone_verified}
      parentModule="dashboard.user-profile.login-settings"
    />
  )

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose} close={null}>
        <Width size={36} style={{ minHeight: '600px' }}>
          <Paper top={8} right={24} left={24}>
            <NavigationBar onLeftPartClick={handleClose} leftPartText="Back">
              <Paper top={8} bottom={8}>
                <Typography size={20} weight="semibold" lineHeight="small">
                  <Typo>Phone</Typo>
                </Typography>
              </Paper>
            </NavigationBar>
            <Paper top={48} right={48} left={48}>
              {descriptionNode}
              <Paper top={48}>{phoneNode}</Paper>
            </Paper>
          </Paper>
        </Width>
      </Modal>
    )
  }

  const contentNode = (
    <Paper top={8}>
      {descriptionNode}
      <Paper top={48}>{phoneNode}</Paper>
    </Paper>
  )

  return (
    <Modal open={open} onClose={handleClose}>
      <MobileLayout
        header={
          <NavigationBar onLeftPartClick={handleClose} leftPartText="Back">
            Phone
          </NavigationBar>
        }
        content={contentNode}
      />
    </Modal>
  )
}

export { PhoneModal }
