import React, { createContext, forwardRef } from 'react'

import { useRef, useCallback, useEffect } from 'hooks'

import { Paper, type PaperSizes } from 'components/atoms/Paper'
import StickyTop from 'components/atoms/StickyTop'

import { useAppHeight } from 'app/containers/AppHeight'

import './MobileLayout.css'

const shouldBlurOnTargetTouch = (target): boolean => {
  let shouldBlur = true

  const check = (target): void => {
    if (target) {
      if (['INPUT', 'TEXTAREA', 'BUTTON', 'LABEL'].includes(target.nodeName)) {
        shouldBlur = false
      }

      check(target.parentNode)
    }
  }

  check(target)

  return shouldBlur
}

type MobileLayoutContextShape = {
  offset: {
    top: number
  }
}

const MobileLayoutContext = createContext<MobileLayoutContextShape>({ offset: { top: 0 } })

type MobileLayoutProps = {
  header?: React.ReactElement
  fixedHeader?: boolean
  content?: React.ReactElement
  contentPaperSizes?: {
    top?: ArrayElement<typeof PaperSizes>
    right?: ArrayElement<typeof PaperSizes>
    bottom?: ArrayElement<typeof PaperSizes>
    left?: ArrayElement<typeof PaperSizes>
  }
  contentFlex?: boolean
  footer?: React.ReactElement | boolean
  'data-test-id'?: string
}

const MobileLayout = forwardRef<HTMLElement, MobileLayoutProps>(
  (
    {
      header,
      fixedHeader,
      content,
      contentPaperSizes: { top = 24, right = 16, bottom = undefined, left = 16 } = {},
      contentFlex,
      footer,
      'data-test-id': dataTestId,
    }: MobileLayoutProps,
    ref,
  ): React.ReactElement => {
    const appHeight = useAppHeight()
    const wasTouchMoveRegistered = useRef(false)
    const registerTouchMove = useCallback(() => (wasTouchMoveRegistered.current = true), [])

    const manageActiveElementBlur = useCallback((event) => {
      const { target } = event

      if (!wasTouchMoveRegistered.current && shouldBlurOnTargetTouch(target)) {
        if (document?.activeElement instanceof HTMLElement) {
          document.activeElement.blur()
        }
      }
      wasTouchMoveRegistered.current = false
    }, [])

    useEffect(
      () => {
        document.addEventListener('touchmove', registerTouchMove)
        document.addEventListener('touchend', manageActiveElementBlur)

        return () => {
          document.removeEventListener('touchmove', registerTouchMove)
          document.removeEventListener('touchend', manageActiveElementBlur)
        }
      },
      // had to run only on mount
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    )

    return (
      <MobileLayoutContext.Provider value={{ offset: { top } }}>
        <div className="MobileLayout" style={{ height: appHeight }} data-test-id={dataTestId}>
          {header && fixedHeader && <div className="Mobile-Layout MobileLayout-Header">{header}</div>}
          <div className="MobileLayout-Element MobileLayout-Content">
            {header && !fixedHeader && <StickyTop>{header}</StickyTop>}
            <Paper ref={ref} top={top} right={right} bottom={bottom} left={left} flex={contentFlex}>
              {content}
              {footer ? (
                <div className="MobileLayout-PhantomFooter" aria-hidden>
                  {footer}
                </div>
              ) : null}
            </Paper>
          </div>

          {footer && <div className="MobileLayout-Footer">{footer}</div>}
        </div>
      </MobileLayoutContext.Provider>
    )
  },
)

MobileLayout.displayName = 'MobileLayout'

export { MobileLayout, type MobileLayoutProps, MobileLayoutContext, type MobileLayoutContextShape }
