import React from 'react'

import { useUnit } from 'effector-react'
import PropTypes from 'prop-types'

import { useActions, useMediaQueries } from 'hooks'

import { format as formatPhone } from 'helpers/phone.js'

import { $combinedContactsAndControls } from 'app/effector/significant-controls'

import { showSupportModal as showSupportModalActionCreator } from 'app/redux/actions/ui'

import Card from 'components/_old/Card/Card.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

const PersonCard = ({ data, ...rest }) => (
  <Card data-test-id="listOfDirectorsAndShareholdersItem" {...rest}>
    <Card
      mods={{
        theme: 'no-sides transparent',
        padding: 'small',
        'no-padding': 'top',
      }}
    >
      <Text block data-test-id="listOfDirectorsAndShareholdersItemName">
        <Typo>{data.name}</Typo>
      </Text>
    </Card>

    {data.isMainApplicant && (
      <Text block small>
        <Typo>Main applicant</Typo>
      </Text>
    )}
    {!data.isMainApplicant && data.isDirector && (
      <Text block small>
        <Typo>Director</Typo>
      </Text>
    )}
    {data.isDirector && data.hasSignificantControl && (
      <Text block small>
        <Typo>This person has significant control over the company</Typo>
      </Text>
    )}
    {!data.isDirector && (
      <Text block small>
        <Typo>A person or entity with significant control</Typo>
      </Text>
    )}
    <Text block small data-test-id="listOfDirectorsAndShareholdersItemAddress">
      <Typo>{`Address: ${data.currentAddress}`}</Typo>
    </Text>
    {data.isDirector && data.phone && (
      <Text block small data-test-id="listOfDirectorsAndShareholdersItemPhone">
        <Typo>{`Tel: ${formatPhone(data.phone)}`}</Typo>
      </Text>
    )}
  </Card>
)

PersonCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    isDirector: PropTypes.bool,
    hasSignificantControl: PropTypes.bool,
    isMainApplicant: PropTypes.bool,
    isUploadDocumentsVisible: PropTypes.bool,
    state: PropTypes.number,
    name: PropTypes.string,
    currentAddress: PropTypes.string,
    birthday: PropTypes.string,
    phone: PropTypes.string,
  }),
}

const DirectorsAndShareholders = () => {
  const { desktop } = useMediaQueries()

  const contactsAndSignificantControls = useUnit($combinedContactsAndControls)

  const [showSupportModal] = useActions([showSupportModalActionCreator])

  return (
    <Width size={desktop ? 33 : null}>
      {contactsAndSignificantControls.map((data, index) => (
        <PersonCard
          key={index}
          data={data}
          mods={{
            theme: 'no-sides transparent',
            'no-padding': index === 0 ? 'top' : '',
          }}
        />
      ))}

      <Card
        mods={{
          theme: 'no-sides transparent',
          padding: 'small',
          'no-padding': 'bottom',
        }}
      >
        <Text small>
          <Link onClick={showSupportModal} data-test-id="businessInfoEmailUsLink">
            Email us to change this information
          </Link>
        </Text>
      </Card>
    </Width>
  )
}

export default DirectorsAndShareholders
