import React from 'react'
import PropTypes from 'prop-types'

import { palette } from 'helpers/palette'

import { propTypes } from 'helpers/propTypes'

import { useMemo } from 'hooks'

import { DesktopLayout } from 'components/atoms/Layouts'
import { TunnelHeader } from 'app/pages/Securities/components'
import Width from 'components/_old/Width/Width'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'
import { SecurityOrder } from 'components/organisms/SecurityOrder/SecurityOrder.jsx'
import { Sticky } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/Sticky'
import { Confirm } from 'app/pages/Dashboard/Goals/DIY/Review/components'

import { Notice } from '../components/Notice'

import { PendingOrder } from 'app/effector/pending-orders/models'
import { SecurityList } from 'app/effector/securities/models'

const Desktop = ({ order, securities, handleClose, handleConfirm }) => {
  const header = useMemo(
    () => (
      <Width size={36.75} center>
        <TunnelHeader onBack={handleClose} onCancel={handleClose} />
      </Width>
    ),
    [handleClose],
  )

  const content = useMemo(
    () => (
      <Width size={36.75} center>
        <Typography size={32} lineHeight="small" weight="semibold">
          <Typo>Rebalancing orders</Typo>
        </Typography>
        <Notice />
        <Paper top={56} bottom={40}>
          {order && securities && <SecurityOrder order={order} securities={securities} />}
        </Paper>
        <Sticky bottom>
          <Paper bottom={32} top={16} style={{ background: palette['background-default'] }}>
            <Confirm handleConfirm={handleConfirm} />
          </Paper>
        </Sticky>
      </Width>
    ),
    [order, securities, handleConfirm],
  )

  return <DesktopLayout header={header} content={content} />
}

Desktop.propTypes = {
  order: propTypes.instanceOf(PendingOrder),
  securities: propTypes.instanceOf(SecurityList),
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
}

export { Desktop }
