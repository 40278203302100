import React from 'react'
import { connect } from 'react-redux'

import withMediaQueries from 'decorators/withMediaQueries/withMediaQueries.jsx'
import isNull from 'lodash/isNull'

import { trackEvent } from 'helpers/analytics'
import compose from 'helpers/compose.js'
import { isFieldsDisabledByRuleset } from 'helpers/field.js'
import rawMediaQueries from 'helpers/mediaQueries.js'
import { combineErrors, backendErrorsToObj, bulkValidate, additionalFieldsToValidation } from 'helpers/validation.js'

import { showSupportModal } from 'app/redux/actions/ui'

import DisableIfNeeded from 'components/_old/DisableIfNeeded/DisableIfNeeded.jsx'
import Form, { Fieldset } from 'components/_old/Form/Form.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelText, LabelField } from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { states as clientStates, wealthSources as clientWealthSources } from 'constants/client'

class FinancesForm extends React.Component {
  handleWealthSourceChange = (_event, value) => {
    const { onClientFieldChange } = this.props
    onClientFieldChange('wealth_source', value)
  }

  handleOtherWealthSourceChange = (_event, value) => {
    const { onClientFieldChange } = this.props
    onClientFieldChange('other_wealth_source', value)
  }

  handleEmploymentStatusChange = (_event, value) => {
    const { onClientFieldChange } = this.props
    onClientFieldChange('employment_status', value)
  }

  handleSubmit = () => {
    const { client, onSubmit } = this.props
    const { state } = client

    const isRegistration = state === clientStates.NOT_COMPLETED

    if (!isRegistration) {
      trackEvent({ category: 'Settings', action: 'Finances changed' })
    }

    if (onSubmit) {
      onSubmit(['wealth_source', 'other_wealth_source', 'employment_status'])
    }
  }

  render() {
    const { client, wrapButton, inputMods, additionalFields, width, handleOpenSupportModal, dicts } = this.props
    const {
      wealth_sources: wealthSources = [],
      employment_statuses: employmentStatuses = [],
      client_lock_rules: fieldLockRules,
    } = dicts

    let submitButton = React.cloneElement(
      this.props.submitButton,
      {
        'data-test-id': 'financesFormSubmit',
      },
      this.props.submitButton.props.children || 'Continue',
    )

    const { wealth_source, other_wealth_source, employment_status, error } = client

    const validation = combineErrors(
      {
        wealth_source: {
          rules: [!isNull(wealth_source)],
          errors: ['Source of wealth must be chosen'],
        },
        gift_origin: {
          rules: [
            wealth_source !== clientWealthSources.GIFT || other_wealth_source,
            wealth_source !== clientWealthSources.GIFT || (other_wealth_source || '').length <= 50,
          ],
          errors: ['Gift origin must be chosen', 'Gift origin must be less than 50 characters'],
        },
        other_wealth_source: {
          rules: [
            wealth_source !== clientWealthSources.OTHER || other_wealth_source,
            wealth_source !== clientWealthSources.OTHER || (other_wealth_source || '').length <= 50,
          ],
          errors: ['Source of wealth must be chosen', 'Source of wealth must be less than 50 characters'],
        },
        employment_status: {
          rules: [!isNull(employment_status)],
          errors: ['Employment status must be chosen'],
        },
      },
      additionalFieldsToValidation(additionalFields),
      backendErrorsToObj(error),
    )

    const postFields = (() => {
      if (!additionalFields?.post) {
        return null
      } else {
        return Object.keys(additionalFields.post).map((f) => {
          return additionalFields.post[f].getField(validation)
        })
      }
    })()

    const wealthSourceOptions = [
      { value: null, name: 'Choose' },
      ...wealthSources.map((s) => {
        return { value: s.id, name: s.name }
      }),
    ]
    const wealthSourceText = (() => {
      const wealthSource = wealthSources.find((ws) => ws.id === wealth_source)

      return wealthSource ? wealthSource.name : null
    })()

    const employmentStatusOptions = [
      { value: null, name: 'Choose' },
      ...employmentStatuses.map((s) => {
        return { value: s.id, name: s.name }
      }),
    ]
    const employmentStatusText = (() => {
      const employmentStatus = employmentStatuses.find((es) => es.id === employment_status)

      return employmentStatus ? employmentStatus.name : null
    })()

    const isSubmitVisible = !isFieldsDisabledByRuleset({
      fields: ['wealth_source', 'other_wealth_source', 'employment_status'],
      rules: fieldLockRules,
      model: client,
    })

    const isSubmitDisabled = !bulkValidate(validation)

    submitButton = React.cloneElement(
      submitButton,
      {
        onClick: this.handleSubmit,
        disabled: isSubmitDisabled,
        tabIndex: 8,
      },
      submitButton.props.children || 'Continue',
    )

    submitButton = wrapButton(submitButton)

    return (
      <Form>
        <Fieldset marginNext>
          <Width size={width}>
            <DisableIfNeeded field="wealth_source" rules={fieldLockRules} model={client}>
              {(disabled) => (
                <Validate rules={validation.wealth_source.rules} disabled={disabled}>
                  <Label errorMessages={validation.wealth_source.errors} data-test-id="financesWealthSourceLabel">
                    <LabelText replaceWithError>
                      <Typo>Source of wealth</Typo>
                    </LabelText>
                    <LabelField>
                      {!disabled && (
                        <Input
                          mods={inputMods}
                          type="select"
                          onChange={this.handleWealthSourceChange}
                          options={wealthSourceOptions}
                          tabIndex={2}
                          data-test-id="financesWealthSourceInput"
                        >
                          {wealth_source}
                        </Input>
                      )}
                      {disabled && wealthSourceText}
                    </LabelField>
                  </Label>
                </Validate>
              )}
            </DisableIfNeeded>
            {/* TODO: Refactor after constants */}
            {wealth_source === clientWealthSources.OTHER && (
              <DisableIfNeeded field="other_wealth_source" rules={fieldLockRules} model={client}>
                {(disabled) => (
                  <Validate
                    skipWaitBlur={other_wealth_source?.length > 0}
                    rules={validation.other_wealth_source.rules}
                    disabled={disabled}
                  >
                    <Label
                      errorMessages={validation.other_wealth_source.errors}
                      data-test-id="financesFormOtherWealthSourceLabel"
                    >
                      <LabelText replaceWithError>
                        <Typo>Other wealth source</Typo>
                      </LabelText>
                      <LabelField>
                        {!disabled && (
                          <Input
                            mods={inputMods}
                            type="text"
                            onChange={this.handleOtherWealthSourceChange}
                            tabIndex={2}
                            data-test-id="financesFormOtherWealthSourceInput"
                          >
                            {other_wealth_source}
                          </Input>
                        )}
                        {disabled && other_wealth_source}
                      </LabelField>
                    </Label>
                  </Validate>
                )}
              </DisableIfNeeded>
            )}
            {/* TODO: Refactor after constants */}
            {wealth_source === clientWealthSources.GIFT && (
              <DisableIfNeeded field="other_wealth_source" rules={fieldLockRules} model={client}>
                {(disabled) => (
                  <Validate
                    skipWaitBlur={other_wealth_source?.length > 0}
                    rules={validation.gift_origin.rules}
                    disabled={disabled}
                  >
                    <Label errorMessages={validation.gift_origin.errors} data-test-id="financesFormGiftOriginLabel">
                      <LabelText replaceWithError>
                        <Typo>Gift origin</Typo>
                      </LabelText>
                      <LabelField>
                        {!disabled && (
                          <Input
                            mods={inputMods}
                            type="text"
                            onChange={this.handleOtherWealthSourceChange}
                            tabIndex={2}
                            data-test-id="financesFormGiftOriginInput"
                          >
                            {other_wealth_source}
                          </Input>
                        )}
                        {disabled && other_wealth_source}
                      </LabelField>
                    </Label>
                  </Validate>
                )}
              </DisableIfNeeded>
            )}
            <DisableIfNeeded field="employment_status" rules={fieldLockRules} model={client}>
              {(disabled) => (
                <Validate rules={validation.employment_status.rules} disabled={disabled}>
                  <Label
                    errorMessages={validation.employment_status.errors}
                    data-test-id="financesEmploymentStatusLabel"
                  >
                    <LabelText replaceWithError>
                      <Typo>Employment status</Typo>
                    </LabelText>
                    <LabelField>
                      {!disabled && (
                        <Input
                          mods={inputMods}
                          type="select"
                          onChange={this.handleEmploymentStatusChange}
                          options={employmentStatusOptions}
                          tabIndex={3}
                          data-test-id="financesEmploymentStatusInput"
                        >
                          {employment_status}
                        </Input>
                      )}
                      {disabled && employmentStatusText}
                    </LabelField>
                  </Label>
                </Validate>
              )}
            </DisableIfNeeded>
          </Width>
        </Fieldset>
        {postFields ? <Fieldset>{postFields}</Fieldset> : null}
        <Width size={width}>
          {isSubmitVisible && submitButton}
          {!isSubmitVisible && (
            <div style={{ marginTop: '2rem' }}>
              <Link onClick={handleOpenSupportModal} data-test-id="financesEmailUsLink">
                <Text small>Email us to change your information</Text>
              </Link>
            </div>
          )}
        </Width>
      </Form>
    )
  }
}

export default compose(
  withMediaQueries(rawMediaQueries),
  connect(
    ({ client }) => {
      return { client }
    },
    {
      handleOpenSupportModal: showSupportModal,
    },
  ),
)(FinancesForm)
