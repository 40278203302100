import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { KeyFeatureDocumentModal } from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/KeyFeatureDocumentModal'

import { AttentionModal } from '../components/AttentionModal'
import { RecurringPaymentRestrictionModal } from '../components/RecurringPaymentRestrictionModal'

import EditGrowthPortfolio from './EditGrowthPortfolio/EditGrowthPortfolio.jsx'
import { useGrowthProjections } from './hooks/useGrowthProjections'

import type { ManagedPortfolio } from '../../../types'

type GrowthProjectionsProps = {
  portfolio: ManagedPortfolio
  handleAmendPortfolioSelect: () => void
  location: {
    query?: {
      editablePortfolioFields?: string
    }
  }
}

const GrowthProjections = withRouter(
  ({ portfolio, handleAmendPortfolioSelect, location }: GrowthProjectionsProps): React.ReactElement | null => {
    const {
      editablePortfolio,
      projections,
      projectionsFetched,
      presets,
      isPortfolioChanged,
      initialDepositMin,
      lockedPortfolio,
      validation,
      handleChange,
      handleSubmit,
      visibleAttentionModal,
      presetIndex,
      recommendedIndex,
      isPresetChanged,
      presetChangedInfo,
      clientRegistered,
      resetTunnel,
      toggleAttentionModal,
      isRecurringPamentRestrictionModalOpened,
      closeRecurringPaymentRestrictionModal,
      isRecommendedPresetLoading,
      kfdModalOpen,
      closeKfdModal,
    } = useGrowthProjections({ portfolio, location })

    if (!portfolio) {
      return null
    }

    return (
      <Fragment>
        <EditGrowthPortfolio
          portfolio={editablePortfolio}
          projections={projections}
          projectionsFetched={projectionsFetched}
          presets={presets}
          changed={isPortfolioChanged}
          showChart={
            editablePortfolio.initial_deposit >= initialDepositMin || parseFloat(portfolio.current_balance) > 0
          }
          locked={lockedPortfolio}
          validation={validation}
          analyticsCategory="Portfolio panel"
          onChange={handleChange}
          onSubmit={handleSubmit}
          handleAmendPortfolioSelect={handleAmendPortfolioSelect}
          isRecommendedPresetLoading={isRecommendedPresetLoading}
        />

        <AttentionModal
          visible={visibleAttentionModal}
          desiredIndex={presetIndex}
          recommendedIndex={recommendedIndex}
          portfolioChanged={isPresetChanged && clientRegistered}
          onSubmit={handleSubmit}
          onClose={() => {
            resetTunnel()
            toggleAttentionModal(false)
          }}
        />
        <KeyFeatureDocumentModal
          open={kfdModalOpen}
          preset={presetChangedInfo}
          animation={null}
          handleClose={closeKfdModal}
          handleSubmit={handleSubmit}
        />

        <RecurringPaymentRestrictionModal
          open={isRecurringPamentRestrictionModalOpened}
          onClose={closeRecurringPaymentRestrictionModal}
        />
      </Fragment>
    )
  },
)

export { GrowthProjections }
