import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { manageTypes } from 'constants/portfolio'

import { GatewayDest } from 'components/atoms/Gateway'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { PortfolioUnavailableInterceptor } from 'components/molecules/PortfolioUnavailableInterceptor/PortfolioUnavailableInterceptor'

import { PortfolioTypeCard, RegulatoryTypeCard } from 'app/pages/CreateNewPortfolio/SelectPortfolioType/components'

const Mobile = ({ title, steps, activeStepName, hasIsa, handleBack: handleCancel }) => {
  const isManagedTypeStep = activeStepName === 'MANAGED_TYPE'

  const managedTypeStep = steps.find((step) => step.stepName === 'MANAGED_TYPE')
  const managedTypeStepElements = managedTypeStep?.elements ?? []
  const regulatoryTypeStep = steps.find((step) => step.stepName === 'INDIVIDUAL_TYPE')
  const regulatoryTypeStepElements = regulatoryTypeStep?.elements ?? []
  const isMultiStepsFlow = steps.length > 1
  const diyElement = managedTypeStepElements?.find((element) => (element.name = manageTypes.DIY))

  const headerNode = isManagedTypeStep ? (
    <NavigationBar
      leftPartText="Back"
      onLeftPartClick={isMultiStepsFlow && (() => managedTypeStep?.onClose())}
      rightPartText="Cancel"
      onRightPartClick={handleCancel}
    >
      {managedTypeStep?.headline}
    </NavigationBar>
  ) : (
    <NavigationBar rightPartText="Cancel" onRightPartClick={handleCancel}>
      {regulatoryTypeStep?.headline}
    </NavigationBar>
  )

  const managedTypeStepNode = managedTypeStepElements.map((element, index) => {
    return element?.hidden ? null : (
      <Paper key={element.name} top={index !== 0 ? 16 : null}>
        <PortfolioUnavailableInterceptor
          variant={element.interceptorVariant}
          hasContinueToDiy
          onClick={() => managedTypeStep?.onSelect(diyElement)}
        >
          <PortfolioTypeCard element={element} onClick={() => managedTypeStep?.onSelect(element)} />
        </PortfolioUnavailableInterceptor>
      </Paper>
    )
  })

  const regulatoryTypeStepNode = regulatoryTypeStepElements.map((element, index) => (
    <Paper key={element.name} top={index !== 0 ? 16 : null}>
      <RegulatoryTypeCard data={element} hasIsa={hasIsa} onClick={() => regulatoryTypeStep?.onSelect(element)} />
    </Paper>
  ))

  const content = isManagedTypeStep ? managedTypeStepNode : regulatoryTypeStepNode

  return (
    <Fragment>
      <MobileLayout
        header={headerNode}
        contentPaperSizes={{ top: 16, left: 16, right: 16, bottom: 16 }}
        content={content}
      />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

Mobile.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array.isRequired,
  activeStepName: PropTypes.string,
  hasIsa: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
}

export { Mobile }
