import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames/dedupe'
import debounce from 'lodash/debounce'

const HeightTrigger = ({
  className,
  triggerValue,
  triggerClass,
  depends = [],
  children,
  'data-test-id': dataTestId,
}) => {
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    const handleResize = debounce(() => {
      if (ref.current) {
        setHeight(ref.current.clientHeight)
      }
    }, 100)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [ref])

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight)
    }
  }, [ref, ...depends])

  const classes = classNames(className, { [triggerClass]: height > triggerValue })

  return (
    <div className={classes} ref={ref} data-test-id={dataTestId}>
      {children}
    </div>
  )
}

export default HeightTrigger
