import { useState, useCallback } from 'hooks'

type UseAltusProgressBarModalInterface = {
  isModalOpen: boolean
  handleModalOpen: () => void
  handleModalClose: () => void
}

function useAltusProgressBarModal(): UseAltusProgressBarModalInterface {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true)
  }, [])
  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  return {
    isModalOpen,
    handleModalOpen,
    handleModalClose,
  }
}

export { useAltusProgressBarModal }
