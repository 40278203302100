import React, { Fragment } from 'react'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette/'

import { useState, useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import ModalContent, {
  ModalContentBody,
  ModalContentButtons,
  ModalContentHeadline,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Segment from 'components/atoms/Segment/Segment.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'
import { Paper } from 'components/atoms/Paper'

import '../NewOptimalPortfolioModal.css'

const RiskAttentionModalContent = ({
  currentPortfolio,
  newOptimalPortfolio,
  onCancel: handleCancel,
  onConfirm: handleConfirm,
}) => {
  const [checked, setChecked] = useState(false)
  const mediaQueries = useMediaQueries()
  const { desktop, tinyPhone } = mediaQueries

  const classes = classNames(
    'NewOptimalPortfolioModal',
    getMediaQuieryClasses('NewOptimalPortfolioModal', mediaQueries),
  )

  const checkboxText = (
    <Text>
      <Typo>I understand the risk associated with this portfolio and am prepared to continue on this basis.</Typo>
    </Text>
  )

  const increaseText = (
    <Text>
      <Typo>
        You have selected a portfolio that is more risky than the one we suggested based on your answers to the risk
        questionnaire. Although the current portfolio has higher expected returns it also has higher potential losses
        than the optimal portfolio.
      </Typo>
    </Text>
  )

  const decreaseText = (
    <Text>
      <Typo>
        You have selected a portfolio that is less risky than the one we suggested based on your answers to the risk
        questionnaire. Although the current portfolio has lower expected losses you might not be able to achieve your
        investment objectives.
      </Typo>
    </Text>
  )

  const buttons = (
    <ColumnarLayout mods={{ padding: 'micro', direction: !desktop && 'column-reverse' }}>
      <Column className="NewOptimalPortfolioModal-Button">
        <Button
          type="button"
          mods={{ size: 'big block', text: 'bigger', theme: 'simple-reverse-blue', theme: null }}
          block
          onClick={handleCancel}
          data-test-id="portfolioRiskAttentionCancel"
        >
          Cancel
        </Button>
      </Column>
      <Column className="NewOptimalPortfolioModal-Button">
        <Button
          mods={{ size: 'big block', text: 'bigger' }}
          disabled={!checked}
          onClick={handleConfirm}
          data-test-id="portfolioRiskAttentionConfirm"
        >
          Confirm
        </Button>
      </Column>
    </ColumnarLayout>
  )

  return (
    <div className={classes} data-test-id="portfolioRiskAttention">
      <ModalContent width={60.75}>
        <Width size={36.875} center>
          <Paper top={desktop ? null : 40} left={16} right={16}>
            <ModalContentHeadline
              className="NewOptimalPortfolioModal-Headline"
              level={desktop ? 1 : 2}
              align="center"
              paddingRight={false}
              icon={
                <Icon
                  className="NewOptimalPortfolioModal-Icon"
                  type="exclamation-triangle"
                  size={64}
                  color={palette['status-warning']}
                />
              }
            >
              Attention
            </ModalContentHeadline>
            <ModalContentBody size={null}>
              <Fragment>
                {newOptimalPortfolio?.index > currentPortfolio?.index ? decreaseText : increaseText}
                <Segment mods={{ margin: 'bigger', noMargin: 'bottom' }}>
                  <CheckboxWithLabel
                    label={checkboxText}
                    onChange={() => setChecked(!checked)}
                    checked={checked}
                    data-test-id="portfolioRiskAttentionCheckbox"
                  />
                </Segment>
              </Fragment>
            </ModalContentBody>
          </Paper>
          {!tinyPhone ? (
            <ModalContentButtons width={null} isNewModal>
              {desktop ? buttons : <div className="NewOptimalPortfolioModal-Buttons">{buttons}</div>}
            </ModalContentButtons>
          ) : (
            <div className="RiskAttentionModalContent-Buttons">{buttons}</div>
          )}
        </Width>
      </ModalContent>
    </div>
  )
}

export { RiskAttentionModalContent }
