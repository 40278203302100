/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import withMediaQueries from 'decorators/withMediaQueries/withMediaQueries.jsx'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'

import { trackEvent } from 'helpers/analytics'
import compose from 'helpers/compose.js'
import rawMediaQueries from 'helpers/mediaQueries.js'
import { format as formatMoney } from 'helpers/money'
import { palette } from 'helpers/palette'

import { $dictsStore } from 'app/effector/dicts'

import { changeField } from 'app/redux/actions/ui'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Analysed from 'components/_old/Analysed/Analysed'
import AnalyticsTrigger from 'components/_old/AnalyticsTrigger/AnalyticsTrigger.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Form, { Fieldset } from 'components/_old/Form/Form.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import HorizontalChartGroupWithSummary from 'components/_old/HorizontalChartGroupWithSummary/HorizontalChartGroupWithSummary.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import StackedBarGroup from 'components/_old/StackedBarGroup/StackedBarGroup.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { Hr } from 'components/atoms/Hr'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import Segment from 'components/atoms/Segment/Segment.jsx'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Sticked from 'components/molecules/Sticked/Sticked.jsx'

import AlternativesHelper from '../components/AlternativesHelper'

const trackEventDebounced = debounce(trackEvent, 400)

class Portfolio extends React.Component {
  handleShowDetails = (): void => {
    const { changeField } = this.props
    changeField({ portfolio_details_shown: true })
  }

  handleHideDetails = (): void => {
    const { changeField } = this.props
    changeField({ portfolio_details_shown: false })
  }

  changeActiveAsset = (asset): void => {
    const { changeField } = this.props
    changeField({ selected_asset: asset })
  }

  handleInitialDepositChange = (event, value): void => {
    const { onChange, isBusiness } = this.props
    value = value !== '' ? value : null
    onChange('initial_deposit', event, value)
    trackEventDebounced({
      category: isBusiness ? 'SME Review your portfolio' : 'Review your portfolio',
      action: 'Initial investment changed',
    })
  }

  handleMonthlyDepositChange = (event, value): void => {
    const { onChange, isBusiness } = this.props
    value = value !== '' ? value : null
    onChange('monthly_deposit', event, value)
    trackEventDebounced({
      category: isBusiness ? 'SME Review your portfolio' : 'Review your portfolio',
      action: 'Monthly investment set',
    })
  }

  componentWillUnmount = (): void => {
    this.handleHideDetails()
  }

  render(): React.ReactElement {
    let {
      goal,
      validation,
      warning,
      submit,
      mediaQueries,
      selectedAsset,
      detailsShown,
      horizontalChartData,
      isBusiness,
      initialDepositMin,
    } = this.props

    const { initial_deposit, monthly_deposit, monthly_deposit_recommended } = goal
    const onRecommended = goal.preset === goal.preset_recommended
    const isDefaultPortfolio = goal?.is_default_preset_used

    const { desktop, phone } = mediaQueries

    const layoutCardMods = {
      theme: 'transparent',
      'no-padding': 'left right',
    }

    const monthly_investment =
      typeof monthly_deposit === 'number' || typeof monthly_deposit === 'string'
        ? monthly_deposit
        : monthly_deposit_recommended > 0
          ? monthly_deposit_recommended
          : null

    const stackedBars = (
      <StackedBarGroup
        data={horizontalChartData}
        maxHeight={desktop ? 256 : 176}
        edgeToEdge={phone}
        alternativesHelper={<AlternativesHelper />}
      />
    )

    warning = (
      <Card
        mods={{
          ...layoutCardMods,
          'no-padding': `left right ${desktop ? 'top' : ''}`,
          padding: 'small',
        }}
      >
        {warning}
      </Card>
    )

    const form = isDefaultPortfolio ? (
      <Card mods={{ theme: phone ? 'white shadowed edge-to-edge' : 'white shadowed' }}>
        <Typography>
          <Typo>
            InvestEngine's Glidepath is our default pension portfolio. We'll manage the investments on your behalf,
            giving you a structured, automated path towards retirement.
          </Typo>
        </Typography>
        <Paper top={24} bottom={24}>
          <Link to="/effects-of-inflation/" hard blank>
            <ItemWithIcon
              inline
              space={4}
              content={
                <Typography tag="span" color="inherit">
                  <Typo>Effects of Inflation</Typo>
                </Typography>
              }
              icon={<Icon type="arrow-angle-16" color="inherit" />}
              iconPosition="right"
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              data-test-id="effectsOfInflationLink"
            />
          </Link>
        </Paper>
        <Hr />
        <Link hard blank to="https://public.investengine.com/pdf/Retirement%20Portfolio%20KFD.pdf">
          <Paper top={16} bottom={16}>
            <ItemWithIcon
              inline
              space={4}
              content={
                <Typography tag="span">
                  <Typo>Key features</Typo>
                </Typography>
              }
              icon={<Icon type="document-24" color={palette['content-on-background-default']} />}
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              data-test-id="keyFeaturesLink"
            />
          </Paper>
        </Link>
        <Hr />
        {desktop && <Paper top={40}>{submit}</Paper>}
      </Card>
    ) : (
      <Card mods={{ theme: phone ? 'white shadowed edge-to-edge' : 'white shadowed' }}>
        {(() => {
          const inputMods = { size: desktop ? 'big' : 'bigger' }
          const idInput = (
            <Validate rules={validation.initial_deposit.rules}>
              <Label
                errorMessages={validation.initial_deposit.errors}
                data-test-id="tunnelStepReviewGoalPortfolioInitialLabel"
              >
                Initial investment
                <Input
                  name="initial-deposit"
                  autoComplete="off"
                  type="money"
                  onChange={this.handleInitialDepositChange}
                  mods={inputMods}
                  tabIndex={1}
                  placeholder={`Must be at least ${formatMoney(initialDepositMin)}`}
                  data-test-id="tunnelStepReviewGoalPortfolioInitialInput"
                >
                  {initial_deposit > 0 ? initial_deposit : null}
                </Input>
              </Label>
            </Validate>
          )
          const mdInput = (
            <Validate rules={validation.monthly_deposit.rules}>
              <Label
                postfield={<Text muted>Optional</Text>}
                errorMessages={validation.monthly_deposit.errors}
                data-test-id="tunnelStepReviewGoalPortfolioMonthlyLabel"
              >
                Monthly investment
                <Input
                  name="monthly-investment"
                  autoComplete="off"
                  type="money"
                  onChange={this.handleMonthlyDepositChange}
                  mods={inputMods}
                  tabIndex={2}
                  data-test-id="tunnelStepReviewGoalPortfolioMonthlyInput"
                >
                  {monthly_investment}
                </Input>
              </Label>
            </Validate>
          )

          return desktop ? (
            <SubmitOnEnter>
              <Form>
                <Fieldset marginNext>
                  {idInput}
                  {mdInput}
                </Fieldset>
                <Fieldset>
                  <Width size={20}>{warning}</Width>
                  {submit}
                </Fieldset>
              </Form>
            </SubmitOnEnter>
          ) : (
            <Form flex style={{ width: '100%' }}>
              <ColumnarLayout mods={{ padding: 'small' }}>
                <Column>
                  <Fieldset grow>{idInput}</Fieldset>
                </Column>
                <Column>
                  <Fieldset grow>{mdInput}</Fieldset>
                </Column>
              </ColumnarLayout>
            </Form>
          )
        })()}
      </Card>
    )

    const showOrHideButtonMods = {
      color: 'green',
      text: 'smaller',
      size: desktop ? null : 'block',
    }
    const showOrHideButton = detailsShown ? (
      <Button
        onClick={this.handleHideDetails}
        mods={showOrHideButtonMods}
        active
        data-test-id="tunnelStepReviewGoalPortfolioHideDetails"
      >
        Hide details
      </Button>
    ) : (
      <AnalyticsTrigger
        category={isBusiness ? 'SME Review your portfolio' : 'Review your portfolio'}
        action="Show details"
      >
        <Button
          onClick={this.handleShowDetails}
          mods={showOrHideButtonMods}
          data-test-id="tunnelStepReviewGoalPortfolioShowDetails"
        >
          Show details
        </Button>
      </AnalyticsTrigger>
    )

    const getHeadline = (level, mods, style): React.ReactElement => (
      <Headline level={level} mods={mods} style={{ ...style, visibility: onRecommended ? null : 'hidden' }}>
        <Width size={26}>
          <Analysed isDefaultPortfolio={isDefaultPortfolio} />
        </Width>
      </Headline>
    )

    return desktop ? (
      <Segment mods={{ margin: 'double', noMargin: 'top' }}>
        <ColumnarLayout mods={{ 'column-content': 'flex-start' }}>
          <Column>
            <Card mods={{ theme: 'transparent', 'no-padding': 'all' }} grow>
              <AllCenter>
                {getHeadline(4, { text: 'normal' })}
                {stackedBars}
                {detailsShown ? null : (
                  <Card mods={{ ...layoutCardMods, 'no-padding': `right bottom left` }}>{showOrHideButton}</Card>
                )}
              </AllCenter>
            </Card>
          </Column>
          <Column>
            {desktop ? (
              <Width size={24} center>
                {form}
              </Width>
            ) : (
              form
            )}
          </Column>
        </ColumnarLayout>
        {detailsShown ? (
          <Card mods={{ theme: 'transparent', padding: 'small', 'no-padding': 'bottom' }}>
            <HorizontalChartGroupWithSummary
              data={horizontalChartData}
              woPrices
              selectedAsset={selectedAsset}
              changeActiveAsset={this.changeActiveAsset}
              continueLine={{ right: true }}
              showOrHideButton={showOrHideButton}
              showWeightOnly
              edgeToEdge
              grow
              data-item-test-id="tunnelStepReviewGoalPortfolioChartItem"
            />
          </Card>
        ) : null}
      </Segment>
    ) : (
      <SubmitOnEnter>
        <Fragment>
          {form}
          <Card mods={layoutCardMods}>
            <Width size={24} center>
              {getHeadline(5, { text: 'normal', margin: 'smaller' }, { marginLeft: '-0.275em' })}
            </Width>
          </Card>
          <Card
            mods={{
              ...layoutCardMods,
              'no-padding': `top ${layoutCardMods['no-padding']}`,
            }}
          >
            {stackedBars}
          </Card>
          {detailsShown ? (
            <Card
              mods={{
                ...layoutCardMods,
                'no-padding': layoutCardMods['no-padding'],
              }}
            >
              <HorizontalChartGroupWithSummary
                data={horizontalChartData}
                woPrices
                selectedAsset={selectedAsset}
                changeActiveAsset={this.changeActiveAsset}
                edgeToEdge={!desktop}
                showWeightOnly
              />
            </Card>
          ) : null}
          <Card mods={{ ...layoutCardMods, 'no-padding': 'all' }}>
            <Card mods={layoutCardMods}>{showOrHideButton}</Card>
          </Card>
          <Sticked into="afterLayout">{submit}</Sticked>
          {warning}
        </Fragment>
      </SubmitOnEnter>
    )
  }
}

Portfolio.propTypes = {
  goal: PropTypes.object,
  validation: PropTypes.object,
  warning: PropTypes.node,
  submit: PropTypes.node,
  mediaQueries: PropTypes.object,
  selectedAsset: PropTypes.object,
  detailsShown: PropTypes.bool,
  horizontalChartData: PropTypes.object,
  isBusiness: PropTypes.bool,
  initialDepositMin: PropTypes.number,

  changeField: PropTypes.func,
  onChange: PropTypes.func,
}

export default compose(
  connect(
    ({ ui }) => {
      return {
        selectedAsset: ui.fields.selected_asset,
        detailsShown: ui.fields.portfolio_details_shown,
        initialDepositMin: $dictsStore.getState().initialDepositMin,
      }
    },
    {
      changeField,
    },
  ),
  withMediaQueries(rawMediaQueries),
)(Portfolio)
