import React from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

type TwoFactorDisabledModalProps = {
  open: boolean
  onClose: () => void
}

const TwoFactorAuthDeniedModal = ({ open, onClose: handleClose }: TwoFactorDisabledModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const iconNode = useMemo(
    () => (
      <AllCenter>
        <Icon type="stop-64" color={palette['status-error']} size={64} />
      </AllCenter>
    ),
    [],
  )

  const titleNode = useMemo(
    () => (
      <Paper top={32}>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
          <Typo>Access denied</Typo>
        </Typography>
      </Paper>
    ),
    [desktop],
  )

  const button = useMemo(
    () => (
      <Button onClick={handleClose} mods={{ size: 'big block' }}>
        Close
      </Button>
    ),
    [handleClose],
  )

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose}>
        <ModalContent width={36}>
          <Paper top={120} bottom={80}>
            {iconNode}
            {titleNode}
            <Paper top={56}>
              <Width size={24} center>
                {button}
              </Width>
            </Paper>
          </Paper>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContent width={36}>
        <MobileLayout
          header={<NavigationBar onRightPartClick={handleClose} rightPartText="Close" />}
          content={
            <AllCenter>
              <Paper>
                {iconNode}
                {titleNode}
              </Paper>
            </AllCenter>
          }
          footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
        />
      </ModalContent>
    </Modal>
  )
}

export { TwoFactorAuthDeniedModal }
