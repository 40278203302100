import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames/dedupe'

import compose from 'helpers/compose.js'
import { combineErrors, backendErrorsToObj, bulkValidate, nameRules } from 'helpers/validation.js'
import { hardCodedCountries } from 'helpers/hardCodedCounties'
import { trackEvent } from 'helpers/analytics'

import { useMediaQueries, useState, useCallback, useRef } from 'hooks'

import { types as clientTypes } from 'constants/client'

import Width from 'components/_old/Width/Width'
import Card from 'components/_old/Card/Card.jsx'
import Text from 'components/_old/Text/Text.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Form from 'components/_old/Form/Form.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { AddressByPeriod } from 'components/organisms/AddressByPeriod'
import { Typo } from 'components/_old/Typo/Typo'

import { Address, AddressList } from 'app/redux/models/address'

import './AddingSignificantControlForm.css'

import { useUnit } from 'effector-react'
import {
  $significantControls,
  changeField as changeSignificantControlField,
  changeAddressField as changeSignificantControlAddressField,
} from 'app/effector/significant-controls'

import { SignificantControl } from 'app/effector/significant-controls/models'

const AddingSignificantControlForm = ({
  wrapSubmitButton,
  significantControlId,
  isNew,
  countries,
  onSubmit: parentHandleSubmit,
}) => {
  const { desktop } = useMediaQueries()

  const significantControls = useUnit($significantControls)
  const significantControlInStore = significantControls.getControl(significantControlId) || null
  const [newSignificantControl, setNewSignificantControl] = useState(new SignificantControl())

  const significantControl = significantControlInStore || newSignificantControl

  const handleSignificantControlFieldChange = useCallback(
    (field, value) => {
      if (isNew) {
        setNewSignificantControl((prevNewSignificantControl) =>
          SignificantControl.createFromObject({ ...prevNewSignificantControl, [field]: value }),
        )
        return
      }
      changeSignificantControlField({ id: significantControl.id, key: field, value })
    },
    [setNewSignificantControl, isNew, significantControl],
  )

  const handleSignificantControlAddressFieldChange = useCallback(
    (fieldName, value) => {
      if (isNew) {
        setNewSignificantControl((prevNewSignificantControl) => {
          const newAddresses = AddressList.createFromObject({ ...prevNewSignificantControl.addresses })
          const [address, index] = newAddresses.getCurrentAddress()

          newAddresses.setAddress(index, Address.createFromObject({ ...address, [fieldName]: value }))

          return SignificantControl.createFromObject({ ...prevNewSignificantControl, addresses: newAddresses })
        })
        return
      }
      changeSignificantControlAddressField({
        id: significantControl.id,
        addressIndex: 0,
        key: fieldName,
        value,
      })
    },
    [setNewSignificantControl, isNew, significantControl],
  )

  const handleSubmit = useCallback(() => {
    trackEvent({ action: 'add_person_or_entity_submitted', client_type: clientTypes.BUSINESS })

    parentHandleSubmit(significantControl)
  }, [significantControl, parentHandleSubmit])

  const inputModsRef = useRef({ size: 'bigger' })

  const [currentAddress] = significantControl.addresses.getCurrentAddress()

  if ((!isNew && !significantControlInStore) || !countries) {
    return null
  }

  const validation = combineErrors(
    {
      name: nameRules(significantControl.name, 'Name'),
      street: { rules: [currentAddress?.street], errors: [' Address can’t be empty'] },
      postcode: { rules: [currentAddress?.postcode], errors: ['Postcode can’t be empty'] },
      country: { rules: [currentAddress?.country], errors: ['Country can’t be empty'] },
      city: { rules: [currentAddress?.city], errors: ['City can’t be empty'] },
      isUK: { rules: [currentAddress?.isUK()], errors: [''] },
    },
    backendErrorsToObj(significantControl.error),
  )

  const notUkWarningText = <Typo>You must be a UK resident to open an account with InvestEngine</Typo>

  const submitButton = (
    <Button
      type="submit"
      mods={{ size: 'big block' }}
      onClick={handleSubmit}
      disabled={!bulkValidate(validation)}
      tabIndex={3}
      data-test-id="addingSignificantControlSubmitButton"
    >
      Continue
    </Button>
  )

  const classes = classNames('AddingSignificantControlForm', { AddingSignificantControlForm_phone: !desktop })

  return (
    <div className={classes}>
      <SubmitOnEnter>
        <Form>
          <Width size={desktop ? 26 : null}>
            <Card
              mods={{
                'no-padding': 'all',
                theme: !desktop ? 'edge-to-edge transparent' : null,
              }}
            >
              <Card
                className="AddingSignificantControlForm-TopBlock"
                mods={{
                  theme: 'transparent border-bottom',
                  'no-padding': desktop ? null : 'top',
                }}
              >
                <Validate rules={validation.name.rules}>
                  <Label errorMessages={validation.name.errors} data-test-id="addingSignificantControlFormNameLabel">
                    Name
                    <Input
                      name="name"
                      type="text"
                      onChange={(_event, value) => handleSignificantControlFieldChange('name', value)}
                      tabIndex={1}
                      mods={inputModsRef.current}
                      data-test-id="addingSignificantControlFormNameInput"
                    >
                      {significantControl.name}
                    </Input>
                  </Label>
                </Validate>
              </Card>
              <Card
                mods={{
                  theme: 'transparent',
                  'no-padding': 'all',
                }}
              >
                <Card
                  mods={{
                    theme: 'transparent',
                  }}
                >
                  <Text smaller>Correspondence Address</Text>
                </Card>
                <AddressByPeriod
                  inputMods={{ size: 'bigger' }}
                  cardMods={{ theme: 'transparent', 'no-padding': 'top bottom' }}
                  address={significantControl.addresses.getCurrentAddress()[0] || new Address({ is_current: true })}
                  onAddressFieldChange={handleSignificantControlAddressFieldChange}
                  validation={validation}
                  countries={countries}
                  notUkWarningText={notUkWarningText}
                  withPeriodsSelect={false}
                  noPaddingBottom
                />
                {wrapSubmitButton(submitButton)}
              </Card>
            </Card>
          </Width>
        </Form>
      </SubmitOnEnter>
    </div>
  )
}

AddingSignificantControlForm.propTypes = {
  wrapSubmitButton: PropTypes.func,
  significantControlId: PropTypes.number,
  isNew: PropTypes.bool,
  countries: PropTypes.array,

  onSubmit: PropTypes.func.isRequired,
}

export default compose(
  connect((state, ownProps) => {
    const { significantControlId } = ownProps
    const isNew = significantControlId === null
    const countries = hardCodedCountries // state.dicts.countries

    return {
      significantControlId,
      isNew,
      countries,
    }
  }),
)(AddingSignificantControlForm)
