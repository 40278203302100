import numeral from 'numeral'
import 'numeral/locales/en-gb'

import { memoizeForever } from '../memoize'
import { symbols } from '../typograph'

numeral.locale('en-gb')

/**
 * @param {string | number} rawAmount
 * @param {boolean} showZeroPence
 * @param {boolean} showPlusSign
 * @param {boolean} showLessThanPointZeroOne
 * @return {{ sign: (string|null), pounds: string, pence: (string|null) }}
 */
function getPoundWithPenceObject(
  rawAmount: string | number,
  showZeroPence: boolean,
  showPlusSign: boolean,
  showLessThanPointZeroOne: boolean,
): { sign: string | null; comparisonSign: string | null; pounds: string; pence: string | null } {
  const amount = parseFloat(rawAmount as string)
  const absoluteAmount = Math.abs(amount)
  const amountParts = absoluteAmount.toFixed(2).split('.')
  const pounds = amountParts[0]
  let pence = amountParts[1]

  if (
    (showLessThanPointZeroOne && amount > 0 && amount < 0.01) ||
    (showLessThanPointZeroOne && amount < 0 && amount > -0.01)
  ) {
    pence = '01'
  }

  const showPence = (): boolean => {
    if (showZeroPence) {
      return true
    }

    return parseFloat(pence) > 0
  }

  const getSign = (): string | null => {
    if (amount < 0) {
      return '‑'
    }

    if (amount > 0 && showPlusSign) {
      return '+'
    }

    return null
  }

  const getСomparisonSign = (): string | null => {
    if (amount > 0 && amount < 0.01) {
      return '<'
    }

    if (amount < 0 && amount > -0.01) {
      return '>'
    }

    return null
  }

  return {
    sign: getSign(),
    comparisonSign: getСomparisonSign(),
    pounds,
    pence: showPence() ? pence ?? '00' : null,
  }
}

function format(
  number: number | string,
  withFloat: boolean = false,
  requiredPence: boolean = false,
  shouldDisplayZero: boolean = true,
): string {
  number = Number(number)
  if (isNaN(number) || Math.abs(number) === Infinity) return ''

  if (withFloat && !shouldDisplayZero && number > 0 && number < 0.01) return '<£0.01'

  const defaultTemplate = '$0,0'
  const floatTemplate = requiredPence ? '$0,0.00' : '$0,0[.]00'

  return numeral(withFloat ? number : Math.floor(number)).format(withFloat ? floatTemplate : defaultTemplate)
}

function formatPercent(
  numberRaw: number | string,
  fractionalLengthRaw: boolean | number = 0,
  withMathSymbol: boolean = false,
  alwaysShowFraction: boolean = false,
  showLessThanPointZeroOne: boolean = false,
): string {
  const fractionalLength =
    typeof fractionalLengthRaw === 'boolean' ? (fractionalLengthRaw ? 2 : 0) : fractionalLengthRaw
  const number = typeof numberRaw === 'string' ? parseFloat(numberRaw) : numberRaw

  if (Number.isNaN(number)) {
    return ''
  }

  if (!Number.isFinite(number)) {
    return ''
  }

  if (showLessThanPointZeroOne && number > 0 && number < 0.0001) {
    return '<+0.01%'
  }

  if (showLessThanPointZeroOne && number < 0 && number > -0.0001) {
    return '>-0.01%'
  }

  let template = '0%'

  if (fractionalLength) {
    let fraction = Array.from({ length: fractionalLength })
      .map(() => 0)
      .join('')

    if (!alwaysShowFraction) {
      fraction = `[${fraction}]`
    }

    template = template = `0.${fraction}%`
  }

  template = withMathSymbol ? `+${template}` : template

  return numeral(number).format(template)
}

const formatMinimalPercent = memoizeForever(
  (
    percentage,
    {
      fractionalLength = 1,
      returnDashIfNotNumberPassed = false,
    }: { fractionalLength?: number; returnDashIfNotNumberPassed?: boolean } = {},
  ) => {
    const minimal = (() => {
      if (fractionalLength < 1) {
        return 1
      }

      const nulls = Array.from({ length: fractionalLength })
        .map(() => 0)
        .join('')

      return parseFloat(`0.${nulls}`.replace(/\d$/, '1'))
    })()

    if (!returnDashIfNotNumberPassed && typeof percentage !== 'number') {
      percentage = 0
    }

    if (typeof percentage !== 'number') {
      return '‑'
    }

    if (percentage <= 0) {
      return `0%`
    }

    if (percentage < minimal) {
      return `<${symbols.nanbsp}${minimal}%`
    }

    return formatPercent(percentage / 100, fractionalLength, false, true)
  },
)

function unformat(string: string, withFloat: boolean = false): number {
  const unformattedValue = numeral(string).value()

  if (unformattedValue) {
    return withFloat ? unformattedValue : Math.round(unformattedValue)
  }

  return 0
}

function round(num: number): number {
  return Math.round(num * 100) / 100
}

function roundDecimals(value: number, decimals: number = 1): number {
  const a = Math.round(Number(`${value}e${decimals}`))

  return Number(`${a}e-${decimals}`)
}

function trimTrailingZero(number: number): string | null {
  const string = `${number}`
  const match = string.match(/^-?\d+(?:\.\d{0,2})?/)?.[0]

  if (match) {
    return match.replace(/\.\d*0*$/, (match) => match.replace(/0*$/, '').replace(/\.$/, ''))
  }

  return null
}

export {
  numeral,
  getPoundWithPenceObject,
  format,
  formatPercent,
  formatMinimalPercent,
  unformat,
  round,
  trimTrailingZero,
  roundDecimals,
}
