import React from 'react'
import PropTypes from 'prop-types'

import { urlTo } from 'helpers/router.js'

import { useMediaQueries } from 'hooks'

import Header from 'components/_old/Header/Header.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Footer from 'components/_old/Footer/Footer.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Progress } from 'components/_old/Progress/'
import { Gateway } from 'components/atoms/Gateway'

const Layout = ({ children, params, progressFill, backLinkModule, backLinkText }) => {
  const { desktop } = useMediaQueries()
  const backLinkUrl = urlTo(backLinkModule, params)
  backLinkText = desktop ? backLinkText : 'Back'

  const headerLeft = <Link to={backLinkUrl}>{backLinkText}</Link>

  const headerRight = desktop && (
    <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}>
      <Link to={urlTo('dashboard')} classnameless>
        My Dashboard
      </Link>
    </Button>
  )

  const headerAfter = <Progress fill={progressFill} gapWidth="1em" />

  const header = <Header left={headerLeft} right={headerRight} after={headerAfter} hideDrawer />

  return (
    <section className="Layout-Holder">
      {desktop && header}
      {!desktop && <Gateway into="beforeLayout">{header}</Gateway>}
      <div className="Layout-Holder Layout-Content Layout-YearlyReview">
        <Inner className="Layout-Holder">{React.cloneElement(children, { params, backLinkUrl, backLinkText })}</Inner>
      </div>
      {desktop ? <Footer /> : null}
    </section>
  )
}

Layout.propTypes = {
  children: PropTypes.element,
  params: PropTypes.shape({
    goalId: PropTypes.string,
  }).isRequired,
  progressFill: PropTypes.number.isRequired,
  backLinkModule: PropTypes.string.isRequired,
  backLinkText: PropTypes.string.isRequired,
}

export { Layout }
