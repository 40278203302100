import { useCallback } from 'hooks'

import { useRestriction } from 'app/pages/Dashboard/Goals/DIY/hooks'

import { usePortfolioOrders } from './usePortfolioOrders'

const useSecurityRestrictions = (portfolioId, handleAddSecurityRaw, handleAdditionalRestrictionAction) => {
  const { isEmpty } = usePortfolioOrders(portfolioId, !!portfolioId)

  const {
    open: restrictionModalOpen,
    type: restrictionModalType,
    handleClose: handleRestrictionModalClose,
    handleRestriction,
  } = useRestriction()

  const handleAddSecurity = useCallback(
    (event, portfolioId, hasRestriction) => {
      handleRestriction({
        action: () => handleAddSecurityRaw(event, portfolioId, hasRestriction),
        additionalRestrictionAction: () => handleAdditionalRestrictionAction(portfolioId),
        type: 'EDIT',
        isRestricted: !isEmpty || hasRestriction,
      })
    },
    [handleAddSecurityRaw, handleRestriction, handleAdditionalRestrictionAction, isEmpty],
  )

  return {
    restrictionModalOpen,
    restrictionModalType,
    handleRestrictionModalClose,
    handleAddSecurity,
  }
}

export { useSecurityRestrictions }
