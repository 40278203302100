import { createStore, createEffect, combine } from 'effector'

import { makeApiCall } from 'app/effector/api'

import { ApiError } from 'app/redux/models/errors'

import * as api from './api'

import type { QuickStart } from './types'

import { quickStartStatuses } from 'constants/quickStart'

// Effects
const fetchQuickStartsFx = createEffect(async () => {
  try {
    return await makeApiCall(api.getQuickStarts)
  } catch (error) {}
})

const fetchPortfolioQuickStartFx = createEffect(async (portfolioId: string | number) => {
  try {
    return await makeApiCall(api.getPortfolioQuickStart, portfolioId)
  } catch (error) {}
})

const createQuickStartFx = createEffect(
  async ({ portfolioId, quickStart }: { portfolioId: string; quickStart: QuickStart }) => {
    const response = await api.createQuickStart(portfolioId, quickStart)

    if (response instanceof ApiError) throw response

    return response
  },
)

const finishQuickStartFx = createEffect(async (portfolioId: number) => {
  const response = await api.updateQuickStart(portfolioId, { status: quickStartStatuses.FINISHED })

  if (response instanceof ApiError) throw response

  return response
})

// Stores
const $quickStarts = createStore<QuickStart[]>([])

$quickStarts.on(fetchQuickStartsFx.doneData, (state: QuickStart[], quickStarts: QuickStart[]) => {
  if (!quickStarts) return state

  return quickStarts
})

$quickStarts.on(fetchPortfolioQuickStartFx.doneData, (state: QuickStart[], quickStart: QuickStart) => {
  if (!quickStart) return state

  const index = state.findIndex((item) => item.portfolio_id === quickStart.portfolio_id)

  return index !== -1 ? [...state.slice(0, index), quickStart, ...state.slice(index + 1)] : [...state, quickStart]
})

$quickStarts.on(createQuickStartFx.doneData, (state: QuickStart[], quickStart: QuickStart) => {
  return [...state, quickStart]
})

$quickStarts.on(finishQuickStartFx.doneData, (state: QuickStart[], quickStart: QuickStart) => {
  const index = state.findIndex((item) => item.portfolio_id === quickStart.portfolio_id)

  return [...state.slice(0, index), quickStart, ...state.slice(index + 1)]
})

const $isLoading = combine(fetchQuickStartsFx.pending, fetchPortfolioQuickStartFx.pending, (...pendingEffects) =>
  pendingEffects.some((pending) => pending),
)

const $quickStartsStore = combine([$quickStarts, $isLoading], ([quickStarts, isLoading]) => ({
  quickStarts,
  isLoading,
  getQuickStartByPortfolioId: (portfolioId: number) => {
    return quickStarts.find((quickStart) => quickStart.portfolio_id === portfolioId)
  },
}))

export { $quickStartsStore, fetchQuickStartsFx, createQuickStartFx, finishQuickStartFx, fetchPortfolioQuickStartFx }
