import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'

import { useMediaQueries } from 'hooks'

import Inner from 'components/_old/Inner/Inner.jsx'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Card from 'components/_old/Card/Card.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Text from 'components/_old/Text/Text.jsx'
import { CopyToClipboard } from 'components/atoms/CopyToClipboard'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'

import { showSuccessToast } from 'app/redux/actions/ui'

const TransferFundsTo = ({ fields, smallMuted, showCopyButtons = false }) => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      {Object.keys(fields).map((label) => {
        if (!fields[label]) {
          return null
        }

        const content = (
          <ColumnarLayout>
            <Text small={smallMuted} muted={smallMuted} data-test-id={`${upperFirst(camelCase(label))}RowTitle`}>
              <Typo>{label}</Typo>
            </Text>
            <div style={{ position: 'relative' }}>
              <ItemWithIcon
                space={12}
                iconPosition="right"
                iconVerticalAlign="center"
                icon={
                  label !== 'Amount' &&
                  showCopyButtons && (
                    <CopyToClipboard
                      value={fields[label]}
                      absolute
                      iconSize={16}
                      toast={`${label} copied`}
                      showToast={showSuccessToast}
                    >
                      Copy {label} to the clipboard
                    </CopyToClipboard>
                  )
                }
                content={
                  <Text
                    small={smallMuted}
                    numeric="tabular"
                    data-test-id={`transferFundsTo${upperFirst(camelCase(label))}Value`}
                  >
                    <Typo>{fields[label]}</Typo>
                  </Text>
                }
              />
            </div>
          </ColumnarLayout>
        )

        const cardMods = {
          theme: 'border-bottom transparent',
          'no-padding': 'left right',
          padding: 'small',
        }

        if (desktop) {
          return (
            <Card key={label} mods={cardMods}>
              {content}
            </Card>
          )
        }

        return (
          <SideSpacesCompensator key={label}>
            <Card mods={cardMods}>
              <Inner>{content}</Inner>
            </Card>
          </SideSpacesCompensator>
        )
      })}
    </Fragment>
  )
}

TransferFundsTo.propTypes = {
  fields: PropTypes.object,
  smallMuted: PropTypes.bool,
  showCopyButtons: PropTypes.bool,
}

export default TransferFundsTo
