import React from 'react'

import { format as formatMoney } from 'helpers/money'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DonutChart } from 'components/atoms/DonutChart'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type SummaryProps = {
  className?: string
  isLoading: boolean
  isForSinglePortfolio: boolean
  stocks: {
    count: number
  }
  summary: {
    total: number
    stocks: {
      actual: number
    }
  }
}

const Summary = ({ className, isLoading, stocks, summary, isForSinglePortfolio }: SummaryProps): React.ReactElement => (
  <div className={className}>
    <Column size={0}>
      <AllCenter>
        <Width size={8.5}>
          <DonutChart lineWidth={8} values={[[summary.stocks.actual, 'var(--stocks)']]} minimalSectorValue={1}>
            <Typography size={32} weight="semibold" lineHeight="small" align="center">
              {isLoading ? '' : stocks.count}
            </Typography>
            <Typography size={14} lineHeight="small" align="center">
              <Typo>Holdings</Typo>
            </Typography>
          </DonutChart>
          <Paper top={24}>
            <Typography size={14} lineHeight="small" align="center">
              <Typo>{isForSinglePortfolio ? 'Portfolio' : 'Total'} balance</Typo>
            </Typography>
            <Typography size={14} lineHeight="small" align="center">
              <Typo>{formatMoney(summary.total, true, true)}</Typo>
            </Typography>
          </Paper>
        </Width>
      </AllCenter>
    </Column>
  </div>
)

export { Summary }
