import React, { Fragment } from 'react'

import { useMemo, useSelector, useState } from 'hooks'

import { features } from 'helpers/features'
import { urlTo } from 'helpers/router.js'

import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import Dropdown from 'components/molecules/Dropdown/Dropdown.jsx'

import { FinishRegistrationModal } from 'app/pages/Dashboard/Accounts/components/FinishRegistrashionModal'
import { useClient } from 'app/pages/Dashboard/SetupAccount/hooks/useClient.js'

import { states as clientStates } from 'constants/client'
import { regulatoryTypes } from 'constants/portfolio'

import './CreateOrTransferDropdown.css'

const CreateOrTransferDropdown = ({
  createPortfolioUrl,
  handleCreatePortfolioClick,
  isSippAge,
}): React.ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { client } = useClient()
  const shouldSignSippDeclaration = useSelector((state) => !state.client.agreed_with_sipp_declaration)
  const portfolios = useSelector(({ portfolios }) => portfolios)
  const shouldFinishRegistration = [clientStates.NEW, clientStates.NOT_COMPLETED].includes(client.state)

  const isaPortfolios = useMemo(
    () => portfolios?.items?.filter((portfolio) => portfolio.regulatory_type === regulatoryTypes.ISA) ?? [],
    [portfolios],
  )

  const transferIsaLink =
    isaPortfolios.length === 0
      ? urlTo('dashboard', null, { transferIsa: true, forwardToTransferIsa: true })
      : urlTo('transfer-isa-altus')

  const transferSippLink = urlTo(
    'dashboard',
    null,
    shouldSignSippDeclaration ? { startTransferSipp: true } : { transferSipp: true },
  )

  const showFinishRegistrationModal = (): void => {
    setIsModalVisible(true)
  }

  return (
    <Fragment>
      <Dropdown
        className="CreateOrTransferDropdown"
        mode="link"
        text="Create Portfolio or Transfer"
        data-test-id="сreateOrTransferDropdown"
      >
        <Paper right={20} bottom={20} left={20}>
          <Typography size={16} lineHeight="small">
            <Link to={createPortfolioUrl} onClick={handleCreatePortfolioClick} data-test-id="addNewPortfolioButton">
              <Paper top={16}>
                <Nobr>Create portfolio</Nobr>
              </Paper>
            </Link>
          </Typography>
          <Typography size={16} lineHeight="small">
            <Link
              to={shouldFinishRegistration ? null : transferIsaLink}
              onClick={shouldFinishRegistration ? showFinishRegistrationModal : null}
              data-test-id="transferIsaButton"
            >
              <Paper top={16}>
                <Nobr>Transfer an ISA</Nobr>
              </Paper>
            </Link>
          </Typography>
          {isSippAge && (
            <Typography size={16} lineHeight="small">
              <Link to={transferSippLink} data-test-id="transferSippButton">
                <Paper top={16}>
                  <Nobr>
                    {features.get('multiple-sipp-providers-transfer')
                      ? 'Transfer a pension'
                      : 'Transfer a Vanguard pension'}
                  </Nobr>
                </Paper>
              </Link>
            </Typography>
          )}
        </Paper>
      </Dropdown>
      {isModalVisible && <FinishRegistrationModal setIsModalVisible={setIsModalVisible} />}
    </Fragment>
  )
}

export { CreateOrTransferDropdown }
