import React from 'react'
import { withRouter } from 'react-router'

import { useRedirect } from 'hooks'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { DirectDebitForm } from './DirectDebitForm'
import { useDirectDebit } from './hooks/useDirectDebit'

type DirectDebitProps = {
  portfolio: Portfolio
  params: object
  location: { query?: { promo?: boolean; back?: string } }
  handleClose: () => void
}

const DirectDebit = withRouter(({ portfolio, location, handleClose }: DirectDebitProps): React.ReactElement | null => {
  const { isLoading, addFundsAllowed, handleBack } = useDirectDebit(portfolio, location)

  useRedirect({
    to: 'dashboard',
    rule: portfolio && !addFundsAllowed,
    isLoading,
  })

  return <DirectDebitForm portfolio={portfolio} location={location} handleBack={handleBack} handleClose={handleClose} />
})

export { DirectDebit }
