import isEmpty from 'lodash/isEmpty'

import axios from 'helpers/ajax'

import {
  SET_VALID,
  SET_NOT_VALID,
  RECEIVE_ERROR,
  RESET_ERROR,
  RECEIVE_QUESTIONS,
  SET_CLIENT_ANSWER,
  SET_CLIENT_ANSWERS,
} from './questionnaireActionTypes.js'

export function setNotValid() {
  return { type: SET_NOT_VALID }
}

export function setValid() {
  return { type: SET_VALID }
}

export function receiveError(error) {
  return { type: RECEIVE_ERROR, payload: { error } }
}

export function resetError() {
  return { type: RESET_ERROR }
}

export function receiveQuestions(questions) {
  return { type: RECEIVE_QUESTIONS, payload: { questions } }
}

export function fetchQuestions({ clientType, goalRegulatoryType, goalPresetType }) {
  return async (dispatch, getState) => {
    try {
      if (!clientType) {
        throw new Error(`Incorrect argument clientType in fetchQuestions`)
      }

      if (!goalRegulatoryType) {
        throw new Error(`Incorrect argument goalRegulatoryType in fetchQuestions`)
      }

      if (!goalPresetType) {
        throw new Error(`Incorrect argument goalPresetType in fetchQuestions`)
      }
      const { data } = await axios({
        url: `questionnaire/questions/?client_type=${clientType}&regulatory_type=${goalRegulatoryType}&preset_type=${goalPresetType}`,
        method: 'get',
      })
      dispatch(receiveQuestions(data))
    } catch (error) {
      dispatch(receiveError(error))
    }
    return getState()
  }
}

export function setClientAnswer(questionCode, answer) {
  return { type: SET_CLIENT_ANSWER, payload: { questionCode, answer } }
}

export function saveClientAnswer(questionCode, { goal_id: goalId, ...rest }, setValidAfter = true) {
  return async (dispatch, getState) => {
    if (getState().questionnaire.didInvalidate) {
      return getState()
    }

    dispatch(setNotValid())

    const answer = !isEmpty(rest) ? rest : null

    try {
      if (answer) {
        dispatch(setClientAnswer(questionCode, answer))
      }

      const {
        questionnaire: { questionList, clientAnswerList },
      } = getState()

      const question = questionList.findQuestionByCode(questionCode)

      if (!question) {
        throw new Error(`Question with ${questionCode} not found in state.`)
      }

      const receivedAnswer = clientAnswerList.findClientAnswerByQuestionId(question.id) || {}

      const { data } = await axios({
        url: receivedAnswer.id ? `questionnaire/client_answers/${receivedAnswer.id}` : `questionnaire/client_answers`,
        method: receivedAnswer.id ? 'patch' : 'post',
        data: receivedAnswer.id
          ? { answer_ids: receivedAnswer.answer_ids, value: receivedAnswer.value, goal_id: goalId }
          : { ...receivedAnswer, goal_id: goalId },
      })

      dispatch(setClientAnswer(questionCode, data))
      return getState()
    } catch (error) {
      dispatch(receiveError(error))
      return getState()
    } finally {
      if (setValidAfter) {
        dispatch(setValid())
      }
    }
  }
}

export function setClientAnswers(answers = []) {
  return { type: SET_CLIENT_ANSWERS, payload: { answers } }
}

export function fetchClientAnswers(goalId) {
  return async (dispatch, getState) => {
    try {
      const url = goalId ? `questionnaire/client_answers/?goal_id=${goalId}` : 'questionnaire/client_answers'
      const { data } = await axios({ url, method: 'get' })

      dispatch(setClientAnswers(data))
    } catch (error) {
      dispatch(receiveError(error))
    }
    return getState()
  }
}

export function deleteClientAnswers(goalId, setValidAfter = true) {
  return async (dispatch, getState) => {
    dispatch(setNotValid())

    try {
      await axios({
        url: `questionnaire/client_answers/?goal_id=${goalId}`,
        method: 'delete',
      })
      dispatch(setClientAnswers([]))
      return getState()
    } catch (error) {
      dispatch(receiveError(error))
      return getState()
    } finally {
      if (setValidAfter) {
        dispatch(setValid())
      }
    }
  }
}

export function fetchRecommendedPreset(
  { clientType, goalRegulatoryType, goalPresetType, answers },
  setValidAfter = true,
) {
  if (!clientType) {
    throw new Error(`Incorrect argument clientType in getRecommendedPreset`)
  }

  if (!goalRegulatoryType) {
    throw new Error(`Incorrect argument goalRegulatoryType in getRecommendedPreset`)
  }

  if (!goalPresetType) {
    throw new Error(`Incorrect argument goalPresetType in getRecommendedPreset`)
  }

  return async (dispatch, getState) => {
    dispatch(setNotValid())

    try {
      const { data } = await axios({
        url: `questionnaire/recommended_preset/`,
        method: 'post',
        data: {
          client_type: clientType,
          regulatory_type: goalRegulatoryType,
          preset_type: goalPresetType,
          answers,
        },
      })

      return { presetRecommendedId: data?.preset_recommended_id }
    } catch (error) {
      dispatch(receiveError(error))
      return getState()
    } finally {
      if (setValidAfter) {
        dispatch(setValid())
      }
    }
  }
}

export function clientAnswersBatch(goalId, clientAnswers) {
  if (!goalId) {
    throw new Error(`Incorrect argument goalId in clientAnswersBatch`)
  }

  return async (dispatch, getState) => {
    dispatch(setNotValid())

    try {
      await axios({
        url: `questionnaire/client_answers_batch/`,
        method: 'post',
        data: {
          goal_id: goalId,
          answers: clientAnswers,
        },
      })
      return getState()
    } catch (error) {
      dispatch(receiveError(error))
      return getState()
    } finally {
      dispatch(setValid())
    }
  }
}

export function fetchData({ clientType, goalRegulatoryType, goalPresetType, goalId = null }) {
  return async (dispatch, getState) => {
    dispatch(setNotValid())
    await dispatch(fetchQuestions({ clientType, goalRegulatoryType, goalPresetType }))
    await dispatch(fetchClientAnswers(goalId))

    dispatch(setValid())

    return getState()
  }
}
