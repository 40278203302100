import { useUnit } from 'effector-react'

import { useState, useCallback, useEffect } from 'hooks'

import { $portfolioSecuritiesStore } from 'app/effector/portfolio-securities'

const useAbandon = ({ portfolioId, isFormDirty, handleAddSecuritiesRaw, handleCancelRaw, handleBackRaw }) => {
  const [open, setOpen] = useState(false)

  const { portfolioSecurities: portfolioSecuritiesCollection } = useUnit($portfolioSecuritiesStore)

  const isPortfolioSecuritiesModified = portfolioSecuritiesCollection?.isModified(portfolioId)

  // For some reason react calls function in state when it's set, so I've decided to store it as object with function in it
  const initialCallbackObject = { callback: null }
  const [callbackObject, setCallback] = useState(initialCallbackObject)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleContinue = useCallback(() => {
    if (typeof callbackObject.callback === 'function') {
      callbackObject.callback()
    }

    setOpen(false)
  }, [setOpen, callbackObject])

  const handleRestriction = useCallback(
    (action, isFormDirty, externalCheck) => {
      const shouldOpenModal = isFormDirty || externalCheck

      if (!shouldOpenModal && typeof action === 'function') {
        action()
      }

      if (shouldOpenModal) {
        setOpen(true)
        setCallback({ callback: action })
      }
    },
    [setOpen, setCallback],
  )

  const handleAddSecurities = useCallback(() => {
    handleRestriction(handleAddSecuritiesRaw, isFormDirty)
  }, [handleAddSecuritiesRaw, isFormDirty, handleRestriction])
  const handleCancel = useCallback(() => {
    handleRestriction(handleCancelRaw, isFormDirty, isPortfolioSecuritiesModified)
  }, [handleCancelRaw, isFormDirty, isPortfolioSecuritiesModified, handleRestriction])
  const handleBack = useCallback(() => {
    handleRestriction(handleBackRaw, isFormDirty)
  }, [handleBackRaw, isFormDirty, handleRestriction])

  useEffect(() => {
    if (!open) {
      setCallback(initialCallbackObject)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return {
    open,

    handleClose,
    handleContinue,
    handleAddSecurities,
    handleCancel,
    handleBack,
  }
}

export { useAbandon }
