import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import {
  DesktopLayout,
  MobileLayout,
  MobileLayoutFooterButton,
  MobileLayoutFullViewport,
} from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter, MobileFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { navigateToPortfolio } from '../model'

export const Success: React.FC = () => {
  const { desktop } = useMediaQueries()

  const successContent = (
    <Fragment>
      <Stack vertical={24}>
        <AllCenter>
          <Icon size={64} type="success-64" color={palette['status-success']} fixedSize />
        </AllCenter>
        <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
          <Typo>ISA Transfer Submitted</Typo>
        </Typography>
        <Typography size={16} align="center">
          <Typo>
            Unless we need anything further from you, your ISA transfer should be completed within 30 days but we'll try
            and process this quicker for you with your existing ISA provider.
          </Typo>
        </Typography>
      </Stack>
    </Fragment>
  )

  const desktopLayout = (
    <DesktopLayout
      header={<DesktopHeader />}
      content={
        <Inner flex>
          <AllCenter>
            <Width size={desktop ? 30 : null} center>
              {successContent}
            </Width>

            <Paper top={32}>
              <Width size={24} center>
                <Button onClick={navigateToPortfolio} mods={{ size: 'big block' }} data-test-id="isaSubmittedContinue">
                  Continue
                </Button>
              </Width>
            </Paper>
          </AllCenter>
        </Inner>
      }
      footer={<DesktopFooter />}
      noGaps
    />
  )

  const mobileLayout = (
    <MobileLayout
      defaultHeaderHeight={0}
      contentPaperSizes={{ top: 0, left: 0, right: 0 }}
      content={
        <Fragment>
          <MobileLayoutFullViewport>
            <Inner flex>
              <AllCenter>
                <Width size={null} center>
                  {successContent}
                </Width>
              </AllCenter>
            </Inner>
          </MobileLayoutFullViewport>
          <Inner>
            <MobileFooter noMarginTop />
          </Inner>
        </Fragment>
      }
      footer={
        <MobileLayoutFooterButton>
          <Button onClick={navigateToPortfolio} data-test-id="isaSubmittedContinue">
            Continue
          </Button>
        </MobileLayoutFooterButton>
      }
    />
  )

  return desktop ? desktopLayout : mobileLayout
}
