const questionTypes = {
  TEXT: 'TEXT',
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
}

const questionCodes = {
  YEARS_OLD: 'years_old',
  ANNUAL_TURNOVER: 'annual_turnover',
  TOTAL_INVEST_ASSETS: 'total_invest_assets_id',
  ANNUAL_INCOME: 'annual_income_id',
  TIME_FRAME: 'time_frame_id',
  WHAT_IS_MORE_IMPORTANT: 'what_is_more_important_id',
  WHAT_WOULD_YOU_DO: 'what_would_you_do_id',
  HOW_BIG_LOSS_CAN_YOU_SUSTAIN: 'how_big_loss_can_you_sustain_id',
  GUARANTEED_VS_UNCERTAIN_RETURNS: 'guaranteed_vs_uncertain_returns_id',
  WILL_YOU_NEED_TO_WITHDRAW: 'will_you_need_to_withdraw_id',
  HOW_MUCH_DO_YOU_EXPECT_TO_EARN: 'how_much_do_you_expect_to_earn',
  WHICH_INSTRUMENTS_YOU_INVESTED_BEFORE: 'which_instruments_you_invested_before_id',
}

export { questionTypes, questionCodes }
