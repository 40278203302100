import React from 'react'

import classNames from 'classnames/dedupe'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo'

import { Typography } from 'components/atoms/Typography'

import './ProgressBar.css'

type ProgressBarProps = {
  numberOfSteps: number
  activeStep: number
  stepTitle: string
}

const ProgressBar = ({ numberOfSteps, activeStep, stepTitle }: ProgressBarProps): React.ReactElement => {
  return (
    <div className="ProgressBar">
      {Array.from(Array(numberOfSteps)).map((step, index) => {
        return (
          <div
            className={classNames('ProgressBar-Step', { 'ProgressBar-Step_completed': index < activeStep })}
            key={index}
          >
            {index < activeStep ? (
              <Icon type="completed-16" size={16} />
            ) : (
              <div className={classNames('ProgressBar-Point', { 'ProgressBar-Point_active': index === activeStep })} />
            )}
            {(index === activeStep || (index === numberOfSteps - 1 && activeStep >= numberOfSteps)) && (
              <Typography
                className="ProgressBar-StepName"
                size={10}
                lineHeight="small"
                color="white"
                align={index === 0 ? 'left' : 'center'}
                data-test-id="progressBarCurrentStepName"
              >
                <Typo noNbsp>{stepTitle}</Typo>
              </Typography>
            )}
          </div>
        )
      })}
    </div>
  )
}

export { ProgressBar }
