import { features } from 'helpers/features'

import { regulatoryTypes, manageTypes } from 'constants/portfolio'

const DIY = {
  name: manageTypes.DIY,
  headline: 'Do it yourself',
  description: 'Build your own ETF portfolio, commission-free',
  analytics: {
    action: 'portfolio_manage_type_selected',
    manage_type: 'DIY',
  },
}

const MANAGED = {
  name: manageTypes.MANAGED,
  headline: 'Managed for you',
  description: 'Fill in our questionnaire and we’ll suggest a portfolio to suit you',
  analytics: {
    action: 'portfolio_manage_type_selected',
    manage_type: 'MANAGED',
  },
  interceptorVariant: 'managed',
  disabled: false,
  hidden: false,
}

const MANAGED_DEFAULT = {
  name: 'MANAGED_DEFAULT',
  headline: 'Retirement portfolio',
  description: 'We’ll manage the investments and adjust the portfolio based on your age',
  analytics: {
    action: 'portfolio_manage_type_selected',
    manage_type: 'MANAGED',
  },
}

const SELF_SELECTED = {
  name: manageTypes.SELF_SELECTED,
  headline: 'LifePlan portfolios',
  description: 'Built by InvestEngine’s investment team — choose a portfolio that suits you',
  analytics: {
    action: 'portfolio_manage_type_selected',
    manage_type: 'SELF_SELECTED',
  },
  interceptorVariant: 'lifeplans',
  disabled: false,
  hidden: false,
}

const ISA = {
  name: regulatoryTypes.ISA,
  headline: 'ISA',
  icon: {
    name: 'isa-type-choose',
  },
  description:
    'You are not using any of your tax-free allowance until you add cash. So you can Continue without commitment!',
  descriptionWithActiveIsa: features.get('flexible-isa')
    ? 'InvestEngine lets you use multiple ISA portfolios in the same tax year. With flexible ISAs, you can withdraw and replace funds without affecting your overall allowance, too.'
    : 'InvestEngine lets you have multiple ISA portfolios in the same tax year, subject to your overall subscription limit.',
  badge: 'Tax-free',
  analytics: {
    action: 'portfolio_regulatory_type_selected',
    regulatory_type: regulatoryTypes.ISA,
  },
}

const GIA = {
  name: regulatoryTypes.GIA,
  headline: 'General',
  icon: {
    name: 'personal-type-choose',
  },
  description: 'Invest with our flexible General Account',
  // TODO: change descriptionNew to description when release sipp-v1 feature
  descriptionNew:
    'Investing with our flexible General Account offers you a range of benefits and opportunities to grow your wealth.',
  analytics: {
    action: 'portfolio_regulatory_type_selected',
    regulatory_type: regulatoryTypes.GIA,
  },
}

const SIPP = {
  name: regulatoryTypes.SIPP,
  headline: 'Personal Pension',
  icon: {
    name: '',
  },
  description:
    'Open a Self Invested Personal Pension. Grow your retirement savings tax-efficiently with an InvestEngine Personal Pension. Minimums apply.',
  badge: 'Free now. £9,99/mo later',
  analytics: {
    action: 'portfolio_regulatory_type_selected',
    regulatory_type: regulatoryTypes.SIPP,
  },
}

export { DIY, MANAGED, MANAGED_DEFAULT, SELF_SELECTED, ISA, GIA, SIPP }
