import React from 'react'
import PropTypes from 'prop-types'

import { useCallback, useContext, useRedirect, useMediaQueries } from 'hooks'
import { useUnit } from 'effector-react'

import { types as clientTypes } from 'constants/client'

import { trackEvent } from 'helpers/analytics/'

import { StepContext } from 'app/containers/PortfolioTunnel'

import { DirectorsAndShareholdersList } from './DirectorsAndShareholdersList.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Card from 'components/_old/Card/Card.jsx'

import { $isLoading } from 'app/effector/contacts'

const DirectorsAndShareholdersSetup = ({
  owner,
  deleteContact,
  deleteSignificantControls,
  handleAfterSubmit: handleSubmit,
}) => {
  const { desktop } = useMediaQueries()
  const { redirectByStepName, getStepByName } = useContext(StepContext)
  const isContactsLoading = useUnit($isLoading)

  const residentialAddressStep = getStepByName('residential-address')

  useRedirect({
    to: residentialAddressStep.module,
    rule: !owner?.addresses?.hasAddresses(),
    isLoading: isContactsLoading,
  })

  const handleAddDirector = useCallback(() => {
    trackEvent({ action: 'add_director', client_type: clientTypes.BUSINESS })

    redirectByStepName('adding-director')
  }, [redirectByStepName])

  const handleAddSignificantControl = useCallback(() => {
    trackEvent({ action: 'add_person_or_entity', client_type: clientTypes.BUSINESS })

    redirectByStepName('adding-significant-control')
  }, [redirectByStepName])

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <Card
        mods={{
          theme: 'transparent',
          padding: 'biggest',
        }}
      >
        {submitButton}
      </Card>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  return (
    <DirectorsAndShareholdersList
      wrapSubmitButton={wrapSubmitButton}
      onSubmit={() => {
        trackEvent({ action: 'list_of_directors_submitted', client_type: clientTypes.BUSINESS })
        handleSubmit()
      }}
      onAddDirector={handleAddDirector}
      onAddSignificantControl={handleAddSignificantControl}
      onDeleteContact={deleteContact}
      onDeleteSignificantControls={deleteSignificantControls}
    />
  )
}

DirectorsAndShareholdersSetup.propTypes = {
  owner: PropTypes.object,
  deleteContact: PropTypes.func,
  deleteSignificantControls: PropTypes.func,
  handleAfterSubmit: PropTypes.func,
}

export { DirectorsAndShareholdersSetup }
