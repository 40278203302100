import React from 'react'
import PropTypes from 'prop-types'

import { Layout } from 'app/pages/Dashboard/SetupAccount/Layout'

import { useBusinessRegistration } from './useBusinessRegistration.js'

const Business = ({ children, location }) => {
  const businessRegistration = useBusinessRegistration({ location })

  return <Layout prevStep={businessRegistration.prevStep}>{React.cloneElement(children, businessRegistration)}</Layout>
}

Business.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
}

export { Business }
