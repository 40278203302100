import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import { TradingSuspendedWarning } from 'components/organisms/TradingSuspendedWarning/TradingSuspendedWarning.tsx'

const titles = {
  withdrawal_exists: "You can't rebalance your portfolio while a withdrawal is being processed",
  unsettled_trades_exist: "You can't rebalance your portfolio until previous trades have settled",
}

const texts = {
  withdrawal_exists: 'We aim to process cash withdrawals within one business day of your request.',
  unsettled_trades_exist: 'Trades typically take 2 days to settle after they are executed in the market.',
}

const RebalanceRestrictions = ({ open = false, onClose: handleClose, type }) => {
  const { desktop } = useMediaQueries()

  const isTradingAvailable = useMemo(() => type !== 'trading_suspended', [type])

  const content = (
    <Paper>
      <Typography size={desktop ? 32 : 24} weight={desktop ? 'regular' : 'semibold'} align="center" lineHeight="small">
        <Typo>{titles[type]}</Typo>
      </Typography>
      <Paper top={desktop ? 56 : 80}>
        <Typography align="center" size={desktop ? 16 : 14}>
          <Typo>
            {texts[type]}
            <br />
            If you have any questions, please contact us through the&nbsp;
            <Link to="https://help.investengine.com/" hard>
              Help Centre
            </Link>
          </Typo>
        </Typography>
      </Paper>
    </Paper>
  )

  const modalContent = isTradingAvailable ? (
    <Paper top={80} bottom={80} left={64} right={64}>
      <Paper left={8} right={8}>
        {content}
      </Paper>
      <Paper top={56}>
        <Width size={16} center>
          <Button onClick={handleClose} mods={{ size: 'big block' }}>
            Close
          </Button>
        </Width>
      </Paper>
    </Paper>
  ) : (
    <Paper top={8} bottom={80}>
      <TradingSuspendedWarning />
      <Paper top={56}>
        <Width size={16} center>
          <Button onClick={handleClose} mods={{ size: 'big block' }}>
            OK
          </Button>
        </Width>
      </Paper>
    </Paper>
  )

  if (desktop) {
    return (
      <Fragment>
        <Modal open={open} onClose={handleClose} zIndex={1001}>
          <ModalContent width={44} center>
            {modalContent}
          </ModalContent>
        </Modal>
        <GatewayDest name="supportModal" />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Modal open={open} onClose={handleClose} zIndex={1001}>
        <MobileLayout
          header={<NavigationBar onLeftPartClick={handleClose} leftPartText="Back" />}
          content={
            isTradingAvailable ? (
              <AllCenter>
                <Paper top={48} bottom={48}>
                  {content}
                </Paper>
              </AllCenter>
            ) : (
              <AllCenter>
                <TradingSuspendedWarning noTopPadding />
              </AllCenter>
            )
          }
          footer={
            <MobileLayoutFooterButton>
              <Button onClick={handleClose}>Close</Button>
            </MobileLayoutFooterButton>
          }
        />
      </Modal>
      <GatewayDest name="supportModal" />
    </Fragment>
  )
}

RebalanceRestrictions.propTypes = {
  type: PropTypes.oneOf(Object.keys(titles)).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export { RebalanceRestrictions }
