import React from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { List, ListItem } from 'components/atoms/List'
import { Typography } from 'components/atoms/Typography'

import { ActionStatus, type Action } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'

type CashBreakdownActionsProps = {
  actions: Action[]
}

const CashBreakdownActions = ({ actions }: CashBreakdownActionsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const currentLocation = window.location.pathname + window.location.search

  return (
    <List hideUnderlineOnLastItem>
      {actions.map((action) => {
        const leftSide = action.icon ? (
          <ItemWithIcon
            space={8}
            icon={<Icon type={action.icon} size={24} color={palette['secondary-default']} />}
            content={action.title}
            iconVerticalAlign="center"
            contentVerticalAlign="center"
          />
        ) : (
          action.title
        )
        const rightSide = action.status ? (
          <ItemWithIcon
            space={4}
            iconPosition="right"
            icon={<Icon type="arrow-right-small-24" size={24} color={palette['content-on-background-minor']} />}
            content={
              <Typography
                size={16}
                lineHeight="small"
                color={action.status === ActionStatus.ON ? 'secondary_default' : 'minor'}
                data-test-id="optionItemStatus"
              >
                <Typo>{action.status}</Typo>
              </Typography>
            }
            iconVerticalAlign="center"
            contentVerticalAlign="center"
          />
        ) : null

        return (
          <ListItem
            key={action.title}
            leftSide={leftSide}
            rightSide={rightSide}
            disabled={!action.enabled}
            onClick={() => {
              action.onClick(currentLocation)
            }}
            left={16}
            right={16}
            data-test-id={action['data-test-id'] ?? 'portfolioOptionsAction'}
            isBoardWithPadding={desktop}
          />
        )
      })}
    </List>
  )
}

export { CashBreakdownActions }
