import { createStore, createEffect, createEvent, combine } from 'effector'

import { $securities } from 'app/effector/securities'
import { PortfolioSecurity, PortfolioSecurityCollection } from 'app/effector/securities/models'

import { ApiError } from 'app/redux/models/errors'

import * as api from './api'

// Effects
const fetchPortfolioSecuritiesFx = createEffect(async ({ portfolioId }) => {
  try {
    const data = await api.getPortfolioSecurities(portfolioId)

    if (data instanceof ApiError) throw data

    return data
  } catch (error) {}
})

const savePortfolioSecuritiesFx = createEffect(async ({ portfolioId, portfolioSecurityWeightList }) => {
  const data = await api.savePortfolioSecurities({ portfolioSecurityWeightList, portfolioId })

  if (data instanceof ApiError) throw data

  return data
})

// Events
const addSecurity = createEvent<{ portfolioId: number; securityId: number }>()
const removeSecurity = createEvent<{ portfolioId: number; securityId: number }>()
const resetSecurities = createEvent<{ portfolioId: number }>()

// Stores
const $portfolioSecurities = createStore<PortfolioSecurityCollection>(new PortfolioSecurityCollection())

$portfolioSecurities.on(fetchPortfolioSecuritiesFx.done, (state, { params, result }) => {
  if (!result) return state
  return state.set(params.portfolioId, result)
})

$portfolioSecurities.on(savePortfolioSecuritiesFx.done, (state, { params, result }) => {
  return state.set(params.portfolioId, result)
})

$portfolioSecurities.on(addSecurity, (state, { portfolioId, securityId, addedFrom }) => {
  const security: PortfolioSecurity = PortfolioSecurity.createFromObject({
    security_id: securityId,
    added_from: addedFrom,
  })
  const hasPortfolioInCollection = state.get(portfolioId)

  if (hasPortfolioInCollection) {
    const nextPortfolioSecuritiesList = state.getPerspective(portfolioId)?.add(security)

    return state.setPerspective(portfolioId, nextPortfolioSecuritiesList)
  }

  return state.set(portfolioId, []).setPerspective(portfolioId, [security])
})

$portfolioSecurities.on(removeSecurity, (state, { portfolioId, securityId }) => {
  const hasPortfolioInCollection = state.get(portfolioId)

  if (hasPortfolioInCollection) {
    const nextPortfolioSecuritiesList = state.getPerspective(portfolioId)?.remove(securityId)

    return state.setPerspective(portfolioId, nextPortfolioSecuritiesList)
  }

  return state
})

$portfolioSecurities.on(resetSecurities, (state, { portfolioId }) => {
  return state.resetPerspective(portfolioId)
})

const getSecuritiesByPortfolio = (
  portfolioSecurities,
  portfolioId,
  perspective = 'getCurrent',
): PortfolioSecurity[] => {
  const method = perspective ? 'getPerspective' : 'getCurrent'

  return portfolioSecurities?.[method]?.(portfolioId) || undefined
}

const selectSecuritiesInPortfolio = (
  securities,
  portfolioSecurities,
  portfolioId,
  perspective,
): PortfolioSecurity[] => {
  const portfolioPortfolioSecurities = getSecuritiesByPortfolio(portfolioSecurities, portfolioId, perspective) || []

  return [...portfolioPortfolioSecurities]
    .map((portfolioSecurity) =>
      [...(securities || [])].find((security) => security.id === portfolioSecurity.security_id),
    )
    .filter((security) => Boolean(security))
}

const $portfolioSecuritiesStore = combine([$portfolioSecurities, $securities], ([portfolioSecurities, securities]) => ({
  portfolioSecurities,
  getSecuritiesByPortfolio: (portfolioId, perspective) =>
    getSecuritiesByPortfolio(portfolioSecurities, portfolioId, perspective),
  selectSecuritiesInPortfolio: (portfolioId, perspective) =>
    selectSecuritiesInPortfolio(securities, portfolioSecurities, portfolioId, perspective),
  selectSecuritiesInPortfolioDetailed: (portfolioId, perspective) => {
    const portfolioPortfolioSecurities = getSecuritiesByPortfolio(portfolioSecurities, portfolioId, perspective) || []

    const securitiesInPortfolio = selectSecuritiesInPortfolio(securities, portfolioSecurities, portfolioId, perspective)

    return securitiesInPortfolio.map((security) => [
      security,
      portfolioPortfolioSecurities.find((portfolioSecurity) => portfolioSecurity.security_id === security.id) ?? {},
    ])
  },
}))

export {
  $portfolioSecuritiesStore,
  $portfolioSecurities,
  fetchPortfolioSecuritiesFx,
  savePortfolioSecuritiesFx,
  addSecurity,
  removeSecurity,
  resetSecurities,
}
