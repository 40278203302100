import React from 'react'

import Input from 'components/_old/Input/Input.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

interface PreviousYearsAmountProps {
  value: string | null
  validation: {
    rules: boolean[]
    errors: string[]
  }
  setValue: (value: string) => void
  isForceValidationEnabled: boolean
}

const PreviousYearsAmount: React.FC<PreviousYearsAmountProps> = ({
  value,
  validation,
  setValue,
  isForceValidationEnabled,
}) => {
  return (
    <React.Fragment>
      <Validate rules={validation.rules} errors={validation.errors} skipWaitBlur={isForceValidationEnabled}>
        <LabelInlineStyle
          labelText="Value of previous years ISAs to be transferred"
          multiline
          size="small"
          errorMessages={validation.errors}
        >
          <Input
            type="money"
            onChange={(_, value) => {
              setValue(value)
            }}
            withFloat
            data-test-id="transferPreviousYearAmount"
          >
            {value ?? ''}
          </Input>
        </LabelInlineStyle>
      </Validate>
    </React.Fragment>
  )
}

export { PreviousYearsAmount }
