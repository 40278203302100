import React, { Fragment } from 'react'

import { useState } from 'hooks'

import { palette } from 'helpers/palette/'

import type { Preset } from 'app/redux/models/portfolio/types'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import { SearchField } from 'components/molecules/SearchField'

import { Filter } from 'components/organisms/Filter'
import { FilterGroup } from 'components/organisms/Filter/FilterGroup'
import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'

import { useAnalytics } from 'app/pages/Dashboard/Analytics/hooks'
import { SelfSelectedFullAnalytics } from 'app/pages/Securities/SelfSelectedPreset/components/SelfSelectedFullAnalytics'

import { GroupOfAssets } from './GroupOfAssets'

import type { AllocationItem } from '../../../ManagedPortfolio/types'

const defaultTabs = [
  { title: 'ETFs', id: 'composition' },
  { title: 'Holdings', id: 'holdings', withSearch: true },
  { title: 'Regions', id: 'regions' },
  { title: 'Sectors', id: 'sectors' },
]

type SelfSelectedAnalyticsProps = {
  title?: string
  portfolioId?: number
  preset?: Preset
  allocationData: AllocationItem[]
  tunnelQuery?: Record<string, string>
  tabs?: Array<{ title: string; id: string; withSearch?: boolean }>
  currentPeriod: string
  onSecurityClick: (id: string) => void
}

const SelfSelectedAnalytics = ({
  title,
  portfolioId,
  allocationData,
  preset,
  onSecurityClick,
  tunnelQuery = {},
  tabs = defaultTabs,
  currentPeriod,
}: SelfSelectedAnalyticsProps): React.ReactElement => {
  const [activeTab, setActiveTab] = useState(tabs[0].id)
  const [showFullAnalytics, setShowFullAnalytics] = useState(false)
  const presetId = preset?.id

  const { isLoading, sectors, regions, filters, searchValue, handleSearch, ...restAnalytics } = useAnalytics({
    portfolioId,
    presetId,
    tunnelQuery,
  })

  const holdings = [
    [restAnalytics?.stocks?.items, palette.stocks],
    [restAnalytics?.bonds?.items, palette.bonds],
    [restAnalytics?.alternatives?.items, palette.alternatives],
    [restAnalytics?.cash?.items, palette.cash],
  ].reduce((array, [items, color]) => {
    return [...array, ...items.map(({ name, logo, target, id }) => ({ name, logo, target, color, id }))]
  }, [])

  const analytics = { holdings, sectors, regions }

  const tabsList = tabs.map((tab) => ({ ...tab, isActive: tab.id === activeTab }))
  const activeTabObject = tabsList.find((tab) => tab.isActive)
  const { id: activeTabId } = activeTabObject
  const limitedList = analytics[activeTabId]?.slice(0, 5)
  const isListFiltered = !!filters.regions.value.length || !!filters.sectors.value.length || !!searchValue

  const compositionNode = (
    <Fragment>
      {allocationData.map((item) => (
        <Paper top={32} key={item.type}>
          <GroupOfAssets
            isPortfolio={!!portfolioId}
            currentPeriod={currentPeriod}
            groupOfAssets={item}
            onSecurityClick={onSecurityClick}
          />
        </Paper>
      ))}
      <Paper top={32}>
        <Typography size={14} color="additional">
          <Typo>
            Numbers might not add up to 100 percent due to rounding. The numbers above include all trades as soon as
            they have been executed. You can check the status of any trade on your account on the Transactions screen.
          </Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  const searchNode = (
    <Fragment>
      <Paper top={24}>
        <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by holding" />
      </Paper>
      <Paper top={16}>
        <FilterGroup fitContent>
          <Filter
            name="Region"
            type="checkbox"
            values={filters.regions.list}
            selected={filters.regions.value}
            onChange={filters.regions.set}
            data-test-id="holdingsFilterRegion"
          />
          <Filter
            name="Sector"
            type="checkbox"
            values={filters.sectors.list}
            selected={filters.sectors.value}
            onChange={filters.sectors.set}
            dropdownMaxHeight={520}
            data-test-id="holdingsFilterSector"
          />
        </FilterGroup>
      </Paper>
      {isListFiltered && !analytics[activeTab]?.length && (
        <Paper top={24}>
          <Typography size={14} lineHeight="medium" color="minor" data-test-id="noSearchResults">
            <Typo>
              No holdings found.
              <br />
              Please try a different search.
            </Typo>
          </Typography>
        </Paper>
      )}
    </Fragment>
  )

  const analyticsNode = (
    <Paper top={32}>
      <Stack vertical={48}>
        {isLoading && limitedList?.length < 1 && (
          <Paper top={32}>
            <Skeleton shown mix>
              <Stack vertical={48}>
                {new Array(5).fill({}).map((_, index) => (
                  <SecurityConstituent
                    key={index}
                    name="██████"
                    actual={0}
                    color="transparent"
                    showLogo={activeTabId === 'holdings'}
                  />
                ))}
                <Skeleton shown>
                  <Button mods={{ theme: 'simple-reverse-blue', size: 'big block' }}>Show all</Button>
                </Skeleton>
              </Stack>
            </Skeleton>
          </Paper>
        )}

        {limitedList?.map((item) => (
          <SecurityConstituent
            key={item.name}
            logo={item.logo}
            name={item.name}
            actual={item.target}
            color={item.color}
            showLogo={activeTabId === 'holdings'}
          />
        ))}
        {analytics[activeTabId]?.length > 5 && (
          <Button
            mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
            onClick={() => {
              setShowFullAnalytics(true)
            }}
            data-test-id="showAllButton"
          >
            Show all {analytics[activeTabId]?.length}
          </Button>
        )}
      </Stack>
    </Paper>
  )

  const fullAnalyticsNode = (
    <Modal open={!!showFullAnalytics} close={null} fullScreen>
      <SelfSelectedFullAnalytics
        {...analytics}
        title={title}
        preset={preset}
        filters={filters}
        itemsAreClickable={false}
        searchValue={searchValue}
        handleSearch={handleSearch}
        type={activeTab !== 'composition' ? activeTab : 'holdings'}
        goToPresetAnalytics={(module) => {
          setActiveTab(module)
        }}
        handleClose={() => {
          setShowFullAnalytics(false)
        }}
      />
    </Modal>
  )

  return (
    <Fragment>
      <GreyTabs
        tabs={tabsList}
        onChange={(tab) => {
          setActiveTab(tab.id)
        }}
      />
      {activeTabObject?.withSearch && searchNode}
      {activeTab === 'composition' && compositionNode}
      {activeTab !== 'composition' && analyticsNode}

      {fullAnalyticsNode}
    </Fragment>
  )
}

export { SelfSelectedAnalytics }
