import React from 'react'
import PropTypes from 'prop-types'

import Validate from 'components/_old/Validate/Validate.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelText } from 'components/_old/Label/Label.jsx'
import { Nobr } from 'components/_old/Nobr'

const BaseField = ({
  label,
  value,
  postfield,
  disabled = false,
  placeholder = false,
  mods = { size: 'bigger' },
  validationRules = [],
  validationErrors = [],
  replaceWithError = false,
  withFloat = true,
  'data-test-id': dataTestId,
  maxLength = null,
  onChange,
}) => {
  return (
    <Validate rules={validationRules}>
      <Label postfield={postfield} errorMessages={validationErrors} fluid mods={{ color: 'darkgray' }}>
        <LabelText replaceWithError={replaceWithError} data-test-id={dataTestId ? `${dataTestId}Label` : null}>
          <Nobr>{label}</Nobr>
        </LabelText>
        <Input
          type="money"
          maxlength={maxLength}
          onChange={onChange}
          disabled={disabled}
          mods={mods}
          placeholder={placeholder}
          data-test-id={dataTestId}
          withFloat={withFloat}
        >
          {value}
        </Input>
      </Label>
    </Validate>
  )
}

export const propTypes = {
  label: PropTypes.string,
  postfield: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  replaceWithError: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  mods: PropTypes.object,
  validationRules: PropTypes.array,
  validationErrors: PropTypes.array,
  'data-test-id': PropTypes.string,
  withFloat: PropTypes.bool,
  maxLength: PropTypes.number,
}

BaseField.propTypes = propTypes

export default BaseField
