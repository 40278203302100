import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useMemo, useSelector, useEffect } from 'hooks'

import { $reviewStore, fetchReviewStatusFx, ScreenState, Event, sendEvent } from 'app/effector/review'

import feedbackImage from '../imgs/feedback.png'
import feedbackImage2x from '../imgs/feedback_2x.png'
import thumbsupImage from '../imgs/thumbsup.png'
import thumbsupImage2x from '../imgs/thumbsup_2x.png'
import trustpilotImage from '../imgs/trustpilot.png'
import trustpilotImage2x from '../imgs/trustpilot_2x.png'

const fetchReviewStatus = attach({ effect: fetchReviewStatusFx })

type useTrustpilotReviewModalData = {
  isOpen: boolean
  isStartScreen: boolean
  title?: string
  image: string
  image2x: string
  subtitle?: string
  buttonText?: string
  linkText?: string
  handleBack: () => void
  onButtonClick: () => void
  onLinkClick: () => void
  handleClose: () => void
}

const useTrustpilotReviewModal = (): useTrustpilotReviewModalData => {
  const client = useSelector((state) => state.client)
  const isAuthorized = client?.access_token

  const { screen } = useUnit($reviewStore)

  const isOpen = screen !== ScreenState.CLOSED

  const title = useMemo(() => {
    switch (screen) {
      case ScreenState.START:
        return 'Enjoying InvestEngine?'
      case ScreenState.TRUSTPILOT_SCREEN:
        return 'Share your feedback'
      case ScreenState.ZENDESK_SCREEN:
        return 'Share your feedback'
    }
  }, [screen])

  const image = useMemo(() => {
    switch (screen) {
      case ScreenState.START:
        return thumbsupImage
      case ScreenState.TRUSTPILOT_SCREEN:
        return trustpilotImage
      case ScreenState.ZENDESK_SCREEN:
        return feedbackImage
    }
  }, [screen])

  const image2x = useMemo(() => {
    switch (screen) {
      case ScreenState.START:
        return thumbsupImage2x
      case ScreenState.TRUSTPILOT_SCREEN:
        return trustpilotImage2x
      case ScreenState.ZENDESK_SCREEN:
        return feedbackImage2x
    }
  }, [screen])

  const subtitle = useMemo(() => {
    switch (screen) {
      case ScreenState.START:
        return ''
      case ScreenState.TRUSTPILOT_SCREEN:
        return 'Help InvestEngine keep growing'
      case ScreenState.ZENDESK_SCREEN:
        return 'Help improve the product'
    }
  }, [screen])

  const buttonText = useMemo(() => {
    switch (screen) {
      case ScreenState.START:
        return 'Yes'
      case ScreenState.TRUSTPILOT_SCREEN:
        return 'Write a review'
      case ScreenState.ZENDESK_SCREEN:
        return 'Share feedback'
    }
  }, [screen])

  const linkText = useMemo(() => {
    switch (screen) {
      case ScreenState.START:
        return 'Something needs improvement'
      case ScreenState.TRUSTPILOT_SCREEN:
        return 'Maybe later'
      case ScreenState.ZENDESK_SCREEN:
        return 'Maybe later'
    }
  }, [screen])

  const handleBack = (): void => {
    sendEvent(Event.BACK)
  }

  const handleClose = (): void => {
    sendEvent(Event.CLOSE)
  }

  const onButtonClick = (): void => {
    sendEvent(Event.BUTTON_CLICK)
  }

  const onLinkClick = (): void => {
    sendEvent(Event.LINK_CLICK)
  }

  useEffect(() => {
    if (isAuthorized) {
      fetchReviewStatus()
    }
  }, [isAuthorized])

  return {
    isOpen,
    isStartScreen: screen === ScreenState.START,
    title,
    image,
    image2x,
    subtitle,
    buttonText,
    linkText,
    handleBack,
    onButtonClick,
    onLinkClick,
    handleClose,
  }
}

export { useTrustpilotReviewModal }
