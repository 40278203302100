import React from 'react'

import { format as formatMoney } from 'helpers/money'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { CardAccent } from 'components/atoms/CardAccent'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { PortfolioManagedBy } from 'components/molecules/PortfolioManagedBy/PortfolioManagedBy'

import { manageTypes, portfolioColors } from 'constants/portfolio'

import './PortfolioCard.css'

type PortfolioCardProps = {
  withColor: boolean
  portfolio: Portfolio
  isBusiness: boolean
  showAvailableCash: boolean
  'data-test-id'?: string
  onClick: () => void
}

const PortfolioCard = ({
  withColor = false,
  portfolio,
  isBusiness,
  onClick,
  showAvailableCash,
  'data-test-id': dataTestId,
}: PortfolioCardProps): React.ReactElement | null => {
  if (!portfolio) return null

  const cashAmount = formatMoney(
    portfolio.manage_type === manageTypes.CASH
      ? portfolio.available_to_move_cash
      : portfolio.available_to_invest_cash || 0,
    true,
    true,
  )

  const content = (
    <Paper top={24} bottom={24} right={20} left={20}>
      <Typography size={18} lineHeight="small" weight="semibold" data-test-id="portfolioTitle">
        <Typo>{portfolio.getTitle()}</Typo>
      </Typography>
      <Paper top={8}>
        <ColumnarLayout mods={{ padding: 'small' }}>
          <Column size={1}>
            <PortfolioManagedBy
              manageType={portfolio.manage_type}
              regulatoryType={portfolio.regulatory_type}
              isDefaultPresetUsed={portfolio.is_default_preset_used}
              customText={portfolio.getDescription(isBusiness)}
              data-test-id="portfolioDescription"
            />
          </Column>
          {showAvailableCash && (
            <Column size={0}>
              <Typography size={14} lineHeight="small" color="additional" weight="semibold">
                <Typo>{cashAmount}</Typo>
              </Typography>
            </Column>
          )}
        </ColumnarLayout>
      </Paper>
    </Paper>
  )

  return onClick ? (
    <SelectableCard className="MoveCashPortfolioCard" onClick={onClick} data-test-id={dataTestId}>
      {withColor && <CardAccent color={portfolioColors[portfolio.manage_type]} />}
      <ItemWithIcon
        iconPosition="right"
        space={4}
        content={content}
        icon={
          !withColor && (
            <Paper right={24}>
              <Icon size={16} type="arrow-down-16" />
            </Paper>
          )
        }
        iconVerticalAlign="center"
      />
    </SelectableCard>
  ) : (
    <Card className="MoveCashPortfolioCard" level={0} data-test-id={dataTestId}>
      {content}
    </Card>
  )
}

export { PortfolioCard }
