import parsePhoneNumber from 'libphonenumber-js/mobile'

export const format = (phone) => {
  try {
    const phoneNumber = parsePhoneNumber(phone)

    return phoneNumber.format('INTERNATIONAL')
  } catch (error) {
    return phone
  }
}

export const formatE164 = (phone) => {
  try {
    const phoneNumber = parsePhoneNumber(phone)

    return phoneNumber.format('E.164')
  } catch (error) {
    return ''
  }
}

export const isValid = (phone) => {
  if (phone?.includes('*')) return true

  try {
    return parsePhoneNumber(phone).isPossible()
  } catch (error) {
    return false
  }
}

export const hidden = (phone) => {
  return format(phone).replace(
    /^([^\d]*\d+)\s(.*)(\d{2})$/,
    (match, m1, m2, m3) => m1 + ' ' + m2.replace(/\d/g, '*') + m3,
  )
}
