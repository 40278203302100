import { Entity } from 'app/redux/models/common/Entity.js'

/**
 * @class
 * @typedef {Object} ClientAnswer
 */
export class ClientAnswer extends Entity {
  constructor() {
    super()
    /** @type {?number} */
    this.question_id = null
    /** @type {?Array} */
    this.answer_ids = null
    /** @type {?String} */
    this.value = null
    /** @type {?number} */
    this.id = null
  }

  /**
   * @param {number=} id
   * @param {Array=} answer_ids
   * @param {String=} value
   * @return {ClientAnswer}
   */
  updateFrom({ id, value, answer_ids: answerIds, question_id: questionId }) {
    // Не перезатираем id пустыми значениями
    if (id) {
      this.id = id
    }

    if (questionId) {
      this.question_id = questionId
    }

    this.value = value
    this.answer_ids = answerIds

    return this
  }
}
