import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { Paper } from 'components/atoms/Paper'
import Segment from 'components/atoms/Segment/Segment.jsx'

import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'

import TextInput from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/components/TextInput.jsx'
import { useTextQuestion } from 'app/pages/CreateNewPortfolio/NewPortfolioTunnel/Question/hooks'

const QuestionnaireQuestionText = ({ code, onSubmit: parentHandleSubmit, getAnswers }): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { value, validation, isValid, handleChange, handleSubmitClick } = useTextQuestion({
    initialValue: getAnswers(code),
    handleSubmit: parentHandleSubmit,
  })

  const input = useMemo(
    () =>
      desktop ? (
        <SubmitOnEnter>
          <Paper top={80}>
            <Headline
              level={1}
              mods={{
                size: 'gargantuan',
                text: 'normal center light',
                'no-margin': 'top',
                'line-height': 'bigger',
                overflow: 'visible',
              }}
            >
              <TextInput value={value} validationRule={validation.value} handleChange={handleChange} />
            </Headline>
            <GatewayDest className="_newTextQuestion-Error" name="text-question-error" />
          </Paper>
        </SubmitOnEnter>
      ) : (
        <SubmitOnEnter>
          <Headline
            level={2}
            mods={{
              text: 'normal center light',
              'no-margin': 'top',
              'line-height': 'bigger',
              overflow: 'visible',
            }}
          >
            <TextInput value={value} validationRule={validation.value} handleChange={handleChange} />
          </Headline>
          <Paper top={8} bottom={32}>
            <GatewayDest className="_newTextQuestion-Error" name="text-question-error" />
          </Paper>
        </SubmitOnEnter>
      ),
    [desktop, handleChange, validation.value, value],
  )

  const button = (
    <StickedIfNotDesktop into="afterLayout">
      <Width size={desktop ? 14 : null} center>
        <Card
          mods={{
            theme: 'transparent',
            padding: 'biggest',
            'no-padding': desktop ? 'left right bottom' : 'all',
            'no-padding-keyboard-shown': 'top',
          }}
        >
          <Button
            type="submit"
            mods={{ size: 'big block', theme: desktop ? null : 'not-rounded' }}
            onClick={handleSubmitClick}
            tabIndex={3}
            disabled={!isValid}
            data-test-id="questionnaireSubmit"
          >
            Continue
          </Button>
        </Card>
      </Width>
    </StickedIfNotDesktop>
  )

  if (desktop) {
    return (
      <Fragment>
        {input}
        <Segment mods={{ noMargin: 'top', margin: 'double' }}>{button}</Segment>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {input}
      {button}
    </Fragment>
  )
}

QuestionnaireQuestionText.propTypes = {
  code: PropTypes.string.isRequired,
  analytics: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func,
}

export { QuestionnaireQuestionText }
