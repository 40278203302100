import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { SecurityItem } from 'components/molecules/SecurityItem'

import { type SharedPortfolioSecurity } from '../types'

type SharedPortfolioSecurityProps = {
  securities: SharedPortfolioSecurity[]
  onClick: (id: string) => void
}

const SharePortfolioSecurities = ({ securities, onClick }: SharedPortfolioSecurityProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <Paper bottom={desktop ? 24 : 16}>
        <Typography size={18} weight="semibold">
          Portfolio composition
        </Typography>
      </Paper>
      {securities.map((security) => (
        <Paper key={security.id} bottom={12}>
          <SecurityItem
            {...security}
            is_featured={false}
            onClick={onClick}
            showDescription={false}
            showTargetWeight={true}
            data-test-id="shareSecurity"
          />
        </Paper>
      ))}
    </Fragment>
  )
}

export { SharePortfolioSecurities }
