import { combineReducers } from 'redux'

import client from './client.js'
import portfolios from './portfolios.js'
import questionnaire from './questionnaire.js'
import ui from './ui.js'

const rootReducer = combineReducers({
  client,

  portfolios,

  questionnaire,

  ui,
})

export default rootReducer
