import { useUnit } from 'effector-react'
import isEmpty from 'lodash/isEmpty'

import { $portfolioSecuritiesStore } from 'app/effector/portfolio-securities'

import { usePendingOrders } from 'app/pages/Dashboard/Goals/DIY/hooks'

const useRemovableSecurities = (currentPortfolioId: string): number[] => {
  const { orders } = usePendingOrders(false)
  const { selectSecuritiesInPortfolioDetailed } = useUnit($portfolioSecuritiesStore)

  const portfolioId = parseInt(currentPortfolioId, 10)
  const portfolioOrders = orders.getByPortfolioId(portfolioId)
  const securitiesModels = selectSecuritiesInPortfolioDetailed(portfolioId, true)

  return securitiesModels
    .filter(
      ([security, portfolioSecurity]) =>
        !portfolioSecurity.quantity && isEmpty(portfolioOrders.getBySecurityId(security.id)),
    )
    .map(([security]: any) => security.id)
    .filter((numberOrNull: number) => Boolean(numberOrNull))
}

export { useRemovableSecurities }
