import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries, useRedirect } from 'hooks'

import { format } from 'helpers/money'
import { urlTo } from 'helpers/router.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelField, LabelText } from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Modal from 'components/_old/Modal/Modal.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { BankAccountCard } from 'components/molecules/BankAccountCard'
import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'
import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'

import type { RouteProps } from 'app/pages/Dashboard/UserProfile/types'

import { IsaLimitWarning } from './components/IsaLimitWarning'
import { WarningMessage } from './components/WarningMessage'
import { WithdrawCashBreakdown } from './components/WithdrawCashBreakdown'
import { useWithdrawFunds, steps } from './hooks/useWithdrawFunds'

import { regulatoryTypes } from 'constants/portfolio'

type WithdrawFundsProps = {
  portfolioId: string
  routes: RouteProps[]
  location: {
    query: Record<string, string>
  }
}

const WithdrawFunds = withRouter(({ portfolioId, routes, location }: WithdrawFundsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const currentRouteModule = routes[routes.length - 1].module
  const isOpen = currentRouteModule === 'withdraw-funds'

  const {
    step,
    amount,
    isDIY,
    isManaged,
    validation,
    portfolio,
    remainingIsaAllowance,
    availableBalance,
    withdrawableAmount,
    safeWithdrawable,
    isCashUnsetlled,
    shouldWithdrawAll,
    initialDepositMin,
    nominatedBankAccount,
    shouldForceWithdrawAll,
    isBankApproved,
    isSubmitButtonDisabled,
    handleDebitChange,
    handleWithdrawBtnClick,
    handleWithdraw,
    handleBackToForm,
    handleClose,
  } = useWithdrawFunds({
    portfolioId,
    location,
  })

  useRedirect({
    to: urlTo(`dashboard.portfolio`, { id: portfolioId }, { ...location.query, withdrawSippModalOpened: true }),
    rule: isOpen && portfolio.regulatory_type === regulatoryTypes.SIPP,
    isLoading: false,
  })

  const moneyInput = (
    <Validate rules={validation.amount.rules}>
      {(isValid, brokenRule) => {
        return shouldForceWithdrawAll ? (
          <Label
            mods={{ size: 'normal' }}
            postfield={
              <Typography size={12} align="center">
                <Typo>You have to withdraw the whole balance</Typo>
              </Typography>
            }
          >
            <LabelField>
              <Typography size={24} lineHeight="small" align="center" weight="semibold">
                {format(availableBalance, true)}
              </Typography>
            </LabelField>
          </Label>
        ) : (
          <Label
            mods={{ size: 'normal' }}
            postfield={
              <Fragment>
                {!isCashUnsetlled && (
                  <div>
                    <Typography size={12} lineHeight="small" align="center">
                      <Typo>
                        Balance available:{' '}
                        <Typography
                          tag="span"
                          size={12}
                          lineHeight="small"
                          color="green"
                          data-test-id="withdrawalFundsAvailableBalance"
                        >
                          {format(availableBalance, true)}
                        </Typography>
                      </Typo>
                    </Typography>
                  </div>
                )}
                {shouldWithdrawAll && (
                  <WarningMessage>
                    If your withdrawal reduces your portfolio balance below the {format(initialDepositMin)} minimum, we
                    will sell all your investments and move the total balance out of this portfolio.
                  </WarningMessage>
                )}
                {!isManaged &&
                  availableBalance - parseFloat(amount ?? '0') <= 0 &&
                  withdrawableAmount > safeWithdrawable && (
                    <WarningMessage>
                      Withdrawing more than the available balance will reduce your account value to below the £100 (+
                      any bonuses) minimum value
                      <br />
                      <br />
                      If you wish to proceed and close your account please contact{' '}
                      <Link to="mailto:support@investengine.com">support@investengine.com</Link>
                    </WarningMessage>
                  )}
              </Fragment>
            }
            preventFocusOnLabelClick
          >
            <LabelText>
              <Typography
                size={desktop ? 20 : 14}
                lineHeight="small"
                weight="semibold"
                align="center"
                color={isValid ? null : 'red'}
              >
                <Typo>{isValid ? 'How much do you want to withdraw?' : validation.amount.errors[brokenRule]}</Typo>
              </Typography>
            </LabelText>
            <Paper top={desktop ? 16 : 12}>
              <LabelField>
                <AllCenter>
                  <Input
                    type="money"
                    mods={{ size: 'big' }}
                    tabIndex={1}
                    onChange={handleDebitChange}
                    size={12}
                    withFloat
                    inline
                    disabled={!isBankApproved}
                    data-test-id="withdrawAmountInput"
                  >
                    {amount}
                  </Input>
                </AllCenter>
              </LabelField>
            </Paper>
          </Label>
        )
      }}
    </Validate>
  )

  const buttons = (
    <StickedIfNotDesktop into="insideModal">
      <ButtonGroup>
        <Button
          type="submit"
          disabled={isSubmitButtonDisabled}
          onClick={handleWithdrawBtnClick}
          data-test-id="withdrawSubmitButton"
        >
          Withdraw
        </Button>
      </ButtonGroup>
    </StickedIfNotDesktop>
  )

  const form = (
    <SubmitOnEnter>
      <Width size={30} data-test-id="withdrawFunds">
        <Paper bottom={desktop ? 24 : 16}>
          <Typography size={desktop ? 32 : 20} lineHeight="small" weight="semibold" align="center">
            <Typo>Withdraw funds</Typo>
          </Typography>
        </Paper>
        <Width size={28} center>
          {!isBankApproved && (
            <Paper bottom={24}>
              <Typography lineHeight="small" color="error">
                <Typo>
                  Withdrawals are unavailable while your nominated bank account is awaiting verification. Please contact
                  support@investengine.com for further assistance.
                </Typo>
              </Typography>
            </Paper>
          )}
          <Card mods={{ theme: 'white', 'border-radius': '16' }}>
            {moneyInput}
            {isCashUnsetlled && (
              <Paper top={24}>
                <WithdrawCashBreakdown
                  isDiy={isDIY}
                  portfolioId={portfolioId}
                  availableBalance={availableBalance}
                  unsettledCash={portfolio.unsettled_cash}
                />
              </Paper>
            )}
          </Card>
          <Paper top={24} bottom={24}>
            <Typography size={12}>
              <Typo>Cash will be sent to your nominated bank account</Typo>
            </Typography>
            <Paper top={24}>
              <BankAccountCard bankAccount={nominatedBankAccount} />
            </Paper>
            {isManaged && (
              <Paper top={24}>
                <Typography size={12} color="minor">
                  <Typo>
                    Once you have requested to withdraw funds, fluctuations in the value of your investments may occur
                    before your request is executed. Once the request is executed, the funds will then be transferred to
                    your bank account in two business days.
                  </Typo>
                </Typography>
              </Paper>
            )}
          </Paper>
          <Paper bottom={desktop ? 24 : 0}>{buttons}</Paper>
        </Width>
      </Width>
    </SubmitOnEnter>
  )

  const ISAlimitWarning = (
    <IsaLimitWarning
      amount={amount}
      remainingAllowance={remainingIsaAllowance}
      onSubmit={handleWithdraw}
      onCancel={handleBackToForm}
    />
  )

  return (
    <Modal open={isOpen} onClose={handleClose} gatewayName="withdraw-funds" data-test-id="withdrawModal">
      {portfolio && step === steps.INITIAL && form}
      {portfolio && step === steps.ISA_LIMIT_WARNING && ISAlimitWarning}
    </Modal>
  )
})

export { WithdrawFunds }
