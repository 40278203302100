import React from 'react'
import classNames from 'classnames/dedupe'

import { features } from 'helpers/features'
import { modsToClassnames } from 'helpers/classname.js'

import { useMemo } from 'hooks'

import Slider from 'components/_old/Slider/Slider.jsx'
import './SliderWTicks.css'

const SliderWTicks = ({ recommended, scale, value, valueKey, recommendedText, mods, theme, limit, ...rest }) => {
  const recommendedIndex = useMemo(() => scale.findIndex((item) => item.id === recommended), [scale, recommended])
  const actualIndex = useMemo(() => scale.findIndex((item) => item.id === value), [scale, value])
  scale = useMemo(() => {
    if (!limit) {
      return scale
    }

    return scale.map((item, index) => {
      if (features.get('new-questionnaire')) {
        if (typeof item === 'object' && item !== null) {
          if (index > recommendedIndex + 1 || index < recommendedIndex - 1) {
            item.disabled = true
          }
        }
      } else {
        if (typeof item === 'object' && item !== null && index > recommendedIndex + 3) {
          item.disabled = true
        }
      }

      return item
    })
  }, [scale, limit, recommendedIndex])

  return (
    <div
      className={classNames('SliderWTicks', modsToClassnames('SliderWTicks', mods))}
      data-test-id="presetsSlider"
      data-recommended-preset={scale[actualIndex]?.title}
    >
      <Slider
        scale={scale}
        value={value}
        valueKey={valueKey}
        recommendedIndex={recommendedIndex}
        mods={mods}
        theme={theme}
        limit={limit}
        {...rest}
      />
      <div className="SliderWTicks-Ticks">
        {scale.map((item, index) => {
          const isRecommended = index === recommendedIndex
          const isAtLeft = index <= Math.floor(scale.length / 10)
          const isAtRight = index >= Math.ceil((scale.length / 10) * 8.5)

          return (
            <div
              key={index}
              className={classNames('SliderWTicks-Tick', {
                'SliderWTicks-Tick_recommended': isRecommended,
                'SliderWTicks-Tick_left': isAtLeft,
                'SliderWTicks-Tick_right': isAtRight,
              })}
              data-recommended-text={recommendedText}
            />
          )
        })}
      </div>
    </div>
  )
}

export default SliderWTicks
