import React from 'react'

import classNames from 'classnames/dedupe'

import { useState, useMemo, useCallback, useEffect, useImperativeHandle } from 'hooks'

import './AnimatedVisibilityToggle.css'

type AnimatedVisibilityToggleHandle = {
  show: () => void
  hide: () => void
}

type AnimatedVisibilityToggleProps = {
  children: React.ReactNode | React.ReactNodeArray
  className?: string
  visible?: boolean
}

const AnimatedVisibilityToggle = React.forwardRef<AnimatedVisibilityToggleHandle, AnimatedVisibilityToggleProps>(
  ({ children, className, visible: visibleProp = false }: AnimatedVisibilityToggleProps, ref) => {
    const [visible, setVisible] = useState(visibleProp)

    const classes = useMemo(
      () => classNames(className, 'AnimatedVisibilityToggle', { AnimatedVisibilityToggle_visible: visible }),
      [className, visible],
    )

    const show = useCallback(() => {
      setVisible(true)
    }, [setVisible])
    const hide = useCallback(() => {
      setVisible(false)
    }, [setVisible])

    useEffect(() => {
      setVisible(visibleProp)
    }, [visibleProp, setVisible])

    useImperativeHandle(ref, () => ({
      show,
      hide,
    }))

    return (
      <div className={classes} aria-hidden={!visible}>
        {children}
      </div>
    )
  },
)
AnimatedVisibilityToggle.displayName = 'AnimatedVisibilityToggle'

export { AnimatedVisibilityToggle, type AnimatedVisibilityToggleProps }
