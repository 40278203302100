import { createStore, createEffect, createEvent, sample, combine } from 'effector'
import isNull from 'lodash/isNull'

import moment from 'helpers/date.js'

import { BankAccount } from 'app/effector/bank-accounts/models/bank'

import { ApiError } from 'app/redux/models/errors'

import * as api from './api'

// Events
const updateOrCreateBankAccount = createEvent<{ sort_code: string; account_number: string }>()
const receiveAccount = createEvent<BankAccount>()
const updateNominatedAccount = createEvent<Record<string, any>>()

// Effects
const fetchBankAccountsFx = createEffect(async () => {
  try {
    const accounts = await api.receiveAccounts()

    if (accounts instanceof ApiError) throw accounts

    return accounts
  } catch (error) {}
})

const fetchTrueLayerBankAccountsFx = createEffect(async () => {
  try {
    const accounts = await api.receiveAccounts({ isTrueLayer: true })

    if (accounts instanceof ApiError) throw accounts

    return accounts
  } catch (error) {}
})

const updateOrCreateBankAccountFx = createEffect(async ({ sortCode, accountNumber, nominatedAccount }) => {
  const result = nominatedAccount?.id
    ? await api.updateBankAccount(nominatedAccount?.id, { sort_code: sortCode, account_number: accountNumber })
    : await api.createBankAccount({ sort_code: sortCode, account_number: accountNumber })

  return result
})

const nominateBankAccountFx = createEffect(async (bankAccountId: number) => {
  const result = await api.setNominatedBankAccount(bankAccountId)

  return result
})

// Stores
const $bankAccounts = createStore<BankAccount[]>([])
const $trueLayerBankAccounts = createStore<BankAccount[]>([])
const $nominatedAccount = createStore<BankAccount | null>(null)

$bankAccounts.on(fetchBankAccountsFx.doneData, (state, accounts) => {
  if (!accounts) return state
  return accounts.map((accountObj) => BankAccount.createFromObject(accountObj))
})

$trueLayerBankAccounts.on(fetchTrueLayerBankAccountsFx.doneData, (state, accounts) => {
  if (!accounts) return state
  return accounts.map((accountObj) => BankAccount.createFromObject(accountObj))
})

$nominatedAccount.on(fetchBankAccountsFx.doneData, (state, accounts) => {
  if (!accounts) return state
  const nominatedAccountData = accounts.find((account) => account.is_nominated)

  return nominatedAccountData ? BankAccount.createFromObject(nominatedAccountData) : state
})

$bankAccounts.on(receiveAccount, (state, accountData) => {
  if (accountData instanceof ApiError) return state

  const index = state.findIndex((account) => account.id === accountData.id)
  const account = BankAccount.createFromObject(accountData)

  index > -1 ? state.splice(index, 1, account) : state.push(account)

  return [...state]
})

$nominatedAccount.on(receiveAccount, (state, accountData) => {
  if (accountData instanceof ApiError) return state

  const account = BankAccount.createFromObject(accountData)

  if (account.is_nominated) return account

  return state
})

$nominatedAccount.on(updateNominatedAccount, (state, field) => {
  return BankAccount.createFromObject({
    ...(state ?? {}),
    ...field,
  })
})

sample({
  clock: updateOrCreateBankAccount,
  source: $nominatedAccount,
  fn: (nominatedAccount, { sort_code: sortCode, account_number: accountNumber }) => ({
    sortCode,
    accountNumber,
    nominatedAccount,
  }),
  target: updateOrCreateBankAccountFx,
})

sample({
  clock: updateOrCreateBankAccountFx.doneData,
  filter: (data) => !!data,
  target: receiveAccount,
})

sample({
  clock: nominateBankAccountFx.doneData,
  target: fetchBankAccountsFx,
})

const $daysFromNomination = combine($nominatedAccount, (nominatedAccount) => {
  return nominatedAccount?.manually_nominated_dt
    ? moment().diff(moment(nominatedAccount.manually_nominated_dt), 'days')
    : null
})

const $isNominationAvailable = combine($daysFromNomination, (daysFromNomination) => {
  return isNull(daysFromNomination) ? true : daysFromNomination > 30
})

const $isLoading = combine(
  fetchBankAccountsFx.pending,
  updateOrCreateBankAccountFx.pending,
  nominateBankAccountFx.pending,
  (...pendingEffects) => pendingEffects.some((pending) => pending),
)

const $bankAccountsStore = combine(
  [$bankAccounts, $trueLayerBankAccounts, $nominatedAccount, $isLoading],
  ([bankAccounts, trueLayerBankAccounts, nominatedAccount, isLoading]) => {
    return {
      bankAccounts,
      trueLayerBankAccounts,
      nominatedAccount,
      isLoading,
      getBankAccountById: (id) => bankAccounts.find((account) => account.id === id),
    }
  },
)

export {
  fetchBankAccountsFx,
  fetchTrueLayerBankAccountsFx,
  updateOrCreateBankAccount,
  updateOrCreateBankAccountFx,
  nominateBankAccountFx,
  receiveAccount,
  updateNominatedAccount,
  $bankAccounts,
  $nominatedAccount,
  $bankAccountsStore,
  $daysFromNomination,
  $isNominationAvailable,
}
