import React, { Fragment } from 'react'

import { QRCodeSVG } from 'qrcode.react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import iphonePush from 'app/pages/Dashboard/UserProfile/TwoFactorAuthSetup/FirstStep/iphonePush.png'

import storesLink from 'constants/externalLinks.js'

const PushInstruction = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const firstStepNode = (
    <Fragment>
      <AllCenter>
        <Icon type="one-32" size={32} />
      </AllCenter>
      <Paper top={16}>
        <Typography size={14} align="center">
          Get the InvestEngine app using QR code below or download from{' '}
          <Link to={storesLink} hard blank>
            App Store
          </Link>{' '}
          or{' '}
          <Link to={storesLink} hard blank>
            Google Play
          </Link>
        </Typography>
      </Paper>
      <AllCenter>
        <Paper top={32}>
          <Card>
            <Paper top={12} bottom={12} right={12} left={12}>
              <AllCenter>
                <QRCodeSVG size={90} value={`${window.location.origin}/download-app/`} />
              </AllCenter>
            </Paper>
          </Card>
        </Paper>
      </AllCenter>
    </Fragment>
  )

  const secondStepNode = (
    <Fragment>
      <AllCenter>
        <Icon type="two-32" size={32} />
      </AllCenter>
      <Paper top={16}>
        <Typography size={14} align="center">
          Log in to InvestEngine app. If you are on iPhone, turn on push notifications.
        </Typography>
      </Paper>
      <Paper top={24}>
        <AllCenter>
          <img src={iphonePush} width={240} height={126} />
          <Typography tag="span" size={12} color="minor" align="center">
            <Typo>for iPhone</Typo>
          </Typography>
        </AllCenter>
      </Paper>
    </Fragment>
  )

  if (desktop) {
    return (
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column>
          <Paper right={12}>{firstStepNode}</Paper>
        </Column>
        <Column>
          <Paper left={12}>{secondStepNode}</Paper>
        </Column>
      </ColumnarLayout>
    )
  }

  return (
    <Fragment>
      {firstStepNode}
      <Paper top={56}>{secondStepNode}</Paper>
    </Fragment>
  )
}

export { PushInstruction }
