const wait = async (ms) => await new Promise((resolve) => setTimeout(resolve, ms))

export const retryOperation = async (operation, delay, times) =>
  await new Promise((resolve, reject) => {
    return operation()
      .then(resolve)
      .catch(async (reason) => {
        if (reason instanceof Error || times - 1 === 0) {
          reject(reason)
          return
        }

        await wait(delay)
          .then(retryOperation.bind(null, operation, delay, times - 1))
          .then(resolve)
          .catch(reject)
      })
  })
