import qs from 'qs'

import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

type ConnectThirdPartyRequestParams = {
  client_id?: string
  state?: string
  redirect_uri?: string
}

type ConnectThirdPartyResponse = {
  redirect_uri: string
}

const connectThirdParty = async ({
  client_id: clientId,
  state,
  redirect_uri: redirectUri,
}: ConnectThirdPartyRequestParams): Promise<ConnectThirdPartyResponse | ApiError> => {
  try {
    const { data } = await axios.post('/oauth/connect_third_party/', {
      client_id: clientId,
      state,
      redirect_uri: redirectUri,
    })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

type setPermissionsArguments = {
  clientId?: string
  state?: string
  redirectUri?: string
  authId?: string
  allow: boolean
}

type setPermissionsResponse = {
  redirect_uri: string
}

const setPermission = async ({
  clientId,
  state,
  redirectUri,
  authId,
  allow,
}: setPermissionsArguments): Promise<setPermissionsResponse | ApiError> => {
  try {
    const { data } = await axios({
      url: '/oauth/authorise/',
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: qs.stringify({
        client_id: clientId,
        state,
        redirect_uri: redirectUri,
        auth_id: authId,
        allow,
        response_type: 'code',
        scope: 'portfolio_list:read',
      }),
    })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { connectThirdParty, setPermission }
