import React from 'react'

import { palette } from 'helpers/palette'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './ContextMenuList.css'

type ContextMenuListProps = {
  items: Array<{ title: string; icon: string; onClick: () => void }>
  onItemClick?: () => void
}

const ContextMenuList = ({ items, onItemClick }: ContextMenuListProps): React.ReactElement => {
  const list = items.map((item, index) => (
    <Paper
      top={8}
      bottom={8}
      left={16}
      right={16}
      key={index}
      className="ContextMenuList-Item"
      onClick={() => {
        item.onClick()
        onItemClick?.()
      }}
    >
      <ItemWithIcon
        space={8}
        icon={<Icon type={item.icon} color={palette['content-on-background-additional']} size={24} inline />}
        iconPosition="right"
        iconVerticalAlign="center"
        contentVerticalAlign="center"
        content={
          <Typography size={14} lineHeight="small">
            <Typo>{item.title}</Typo>
          </Typography>
        }
      />
    </Paper>
  ))

  return <div className="ContextMenuList">{list}</div>
}

export { ContextMenuList }
