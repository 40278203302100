const mockCountries = [
  {
    id: 1,
    name: 'Germany',
    logo: 'https://res.cloudinary.com/tumelo/image/upload/w_128,h_128,c_fit/v1580300057/equsi4go10btrgs4un0l.png',
    target_weight: '23.467',
    actual_weight: '12.05',
    actual_value: '1140.05',
  },
  {
    id: 2,
    name: 'France',
    logo: 'https://res.cloudinary.com/tumelo/image/upload/w_128,h_128,c_fit/v1580300057/equsi4go10btrgs4un0l.png',
    target_weight: '23.0535',
    actual_weight: '20.1500',
    actual_value: '234.55',
  },
  {
    id: 3,
    name: 'Scotland',
    logo: 'https://res.cloudinary.com/tumelo/image/upload/w_128,h_128,c_fit/v1580300057/equsi4go10btrgs4un0l.png',
    target_weight: '24.356',
    actual_weight: '30.358',
    actual_value: '456.35',
  },
  {
    id: 4,
    name: 'Russia',
    logo: 'https://res.cloudinary.com/tumelo/image/upload/w_128,h_128,c_fit/v1580300057/equsi4go10btrgs4un0l.png',
    target_weight: '12.35654',
    actual_weight: '76.3453',
    actual_value: '800.95453',
  },
]

export { mockCountries }
