import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DesktopLayout, MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'

const TwoFactorSetupSuccess = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const headerNode = <Header hideDrawer logoLink="/" hard withShadow />

  const handleContinue = (): void => {
    goTo(urlTo('dashboard'))
  }

  const button = (
    <Button mods={{ size: 'big block' }} onClick={handleContinue}>
      Continue
    </Button>
  )

  const content = (
    <Fragment>
      <AllCenter>
        <Icon type="checkmark_in_a_circle" color={palette['status-success']} size={64} />
      </AllCenter>
      <Paper top={16}>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
          <Typo>Two-factor authentication is now active</Typo>
        </Typography>
      </Paper>
      <Paper top={40}>
        <Typography size={14} align="center">
          <Typo>When logging in from a new device, approve access using the InvestEngine app.</Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  return (
    <Fragment>
      {desktop ? (
        <DesktopLayout
          header={headerNode}
          content={
            <Inner twoColumns>
              <Paper top={80}>
                <Width size={28} center>
                  {content}
                  <Paper top={56}>{button}</Paper>
                </Width>
              </Paper>
            </Inner>
          }
          footer={<DesktopFooter />}
        />
      ) : (
        <MobileLayout
          content={
            <Paper top={80} bottom={56}>
              {content}
            </Paper>
          }
          footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
        />
      )}
    </Fragment>
  )
}

export { TwoFactorSetupSuccess }
