import { nullable, type Nullable, int, date, string } from 'app/effector/model'

import { type TransferProgressDTO } from '../../api'

import { TransferProgressStepList } from './TransferProgressStepList'

class TransferProgress {
  readonly id: Nullable<number>
  readonly provider_name: Nullable<string>
  readonly account_number: Nullable<string>
  readonly steps: TransferProgressStepList
  readonly estimated_completion_date: Nullable<Date>

  constructor(dto: Partial<TransferProgressDTO> | Partial<TransferProgress>) {
    this.id = nullable(int)(dto.id)
    this.provider_name = nullable(string)(dto.provider_name)
    this.account_number = nullable(string)(dto.account_number)
    this.steps = new TransferProgressStepList(...(dto.steps ?? []))
    this.estimated_completion_date = nullable(date)(dto.estimated_completion_date)
  }

  isFullyCompleted(): boolean {
    return this.steps.every((step) => step.isCompleted())
  }
}

export { TransferProgress }
