import { useCallback, useSelector } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { flowTypes, useFlowType } from 'app/pages/CreateNewPortfolio/SelectPortfolioType/hooks/flows'
import type { Frequency } from 'app/pages/Dashboard/Goals/RecurringPayment/types'

import { useCreatePortfolioQuickStart } from './useCreatePortfolioQuickStart'

import { states as portfolioStates, regulatoryTypes } from 'constants/portfolio'

type UseSavingsPlanSetupProps = {
  portfolio: Portfolio
  securityId?: number
  location: {
    query?: {
      back?: string
      fromUniverse?: string
    }
  }
  onClose?: () => void
}

type UseSavingsPlanSetupReturnProps = {
  isLoading: boolean
  needToChooseRegulatoryType: boolean
  handleBack: () => void
  handleClose: () => void
  handleContinue: (data: {
    amount: string | null
    frequency: Frequency | null
    startDay: string | null
    monthlyPaymentDay: number | null
    regulatoryType?: string
  }) => void
}

const useSavingsPlanSetup = ({
  portfolio,
  securityId,
  location,
  onClose,
}: UseSavingsPlanSetupProps): UseSavingsPlanSetupReturnProps => {
  const shouldSignSippDeclaration = useSelector((state) => !state.client.agreed_with_sipp_declaration)

  const { query } = location ?? {}
  const isPortfolioNew = portfolio?.state === portfolioStates.NEW
  const flowType = useFlowType()
  const isIsaOrSippAvailable = flowType === flowTypes.PERSONAL || flowType === flowTypes.PERSONAL_WITH_SIPP
  const needToChooseRegulatoryType = isPortfolioNew && isIsaOrSippAvailable && query?.fromUniverse === 'true'

  const { isLoading: isSavingsPlanLoading, createPortfolioQuickStart } = useCreatePortfolioQuickStart()

  const handlePortfolioQuickStart = useCallback(
    async (params) => {
      if (isPortfolioNew) {
        goTo(
          urlTo(
            'dashboard.portfolio.finish',
            { id: portfolio?.id },
            { ...query, ...params, quickStart: true, securityId },
          ),
        )
        return
      }

      await createPortfolioQuickStart({ ...params, portfolioId: portfolio?.id })

      goTo(urlTo(`portfolio.add-funds.finish-registration`, { id: portfolio?.id }, { quickStart: true }))
    },
    [securityId, portfolio, createPortfolioQuickStart, query, isPortfolioNew],
  )

  const handleContinue = useCallback(
    (params) => {
      if (params.regulatoryType === regulatoryTypes.SIPP && shouldSignSippDeclaration) {
        goTo(
          urlTo(
            'securities.security',
            { id: securityId },
            {
              ...query,
              ...params,
              openSipp: true,
              quickStart: true,
              securityId,
            },
          ),
        )
        return
      }
      handlePortfolioQuickStart(params)
    },
    [handlePortfolioQuickStart, shouldSignSippDeclaration, securityId, query],
  )

  const handleBack = useCallback(() => {
    goTo(location.query?.back)
  }, [location])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
      return
    }

    goTo(urlTo(`dashboard.portfolio`, { id: portfolio?.id }))
  }, [portfolio?.id, onClose])

  return {
    isLoading: isSavingsPlanLoading,
    needToChooseRegulatoryType,
    handleBack,
    handleClose,
    handleContinue,
  }
}

export { useSavingsPlanSetup }
