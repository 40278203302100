import React from 'react'

import { format as formatDate } from 'helpers/date.js'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import {
  type AbstractPointOfInterest,
  type PointOfInterestCardPositions,
  PointOfInterestCard,
} from 'components/organisms/charts/parts/PointOfInterest/components/Card/Card'
import { ContentWithIconAndLink } from 'components/organisms/charts/parts/PointOfInterest/components/ContentWithIconAndLink/ContentWithIconAndLink'

type TooltipProps = {
  tooltipData: {
    activePointOfInterest: AbstractPointOfInterest | null
    pointOfInterestCenter: number
    pointOfInterestPosition: PointOfInterestCardPositions | null
  }
  linkToTransactions: string | null
}

const Tooltip = ({ tooltipData, linkToTransactions }: TooltipProps): React.ReactElement => {
  const { activePointOfInterest, pointOfInterestCenter, pointOfInterestPosition } = tooltipData

  const renderContent = (pointOfInterest): React.ReactElement | null => {
    if (!pointOfInterest) return null

    const content = (
      <Paper right={4}>
        <Typography size={14} color="inherit" weight="semibold" lineHeight="small" align="right">
          <Typo>{formatDate(pointOfInterest.date, 'MMM YYYY')}</Typo>
        </Typography>
        {pointOfInterest.paidOutByEtfs?.slice(0, 2).map((paidOutByEtf, index) => (
          <Paper key={index} top={12}>
            <Paper bottom={4}>
              <Typography size={14} weight="semibold" lineHeight="small" align="right">
                <Typo>{paidOutByEtf.ticker}</Typo>
              </Typography>
              <Typography size={14} color="minor" lineHeight="small" align="right">
                <PoundsWithPence amount={paidOutByEtf.value} sameSize showZeroPence showPlusSign />
              </Typography>
            </Paper>
          </Paper>
        ))}
        {pointOfInterest.paidOutByEtfs?.length > 2 && (
          <Paper top={12}>
            <Typography size={14} weight="semibold" lineHeight="small" align="right">
              <Typo>+ {pointOfInterest.paidOutByEtfs?.length - 2} ETF</Typo>
            </Typography>
          </Paper>
        )}
        {!pointOfInterest.paidOutByEtfs?.length && (
          <Paper top={4}>
            <Typography size={14} color="additional" lineHeight="small" align="right">
              <PoundsWithPence amount={pointOfInterest.value} sameSize showZeroPence showPlusSign />
            </Typography>
          </Paper>
        )}
      </Paper>
    )

    return <ContentWithIconAndLink to={linkToTransactions as string}>{content}</ContentWithIconAndLink>
  }

  return (
    <PointOfInterestCard
      className="DividendsChart-PointOfInterestCard"
      pointOfInterest={activePointOfInterest}
      renderContent={renderContent}
      center={pointOfInterestCenter}
      position={pointOfInterestPosition as PointOfInterestCardPositions}
      width={158}
      data-test-id="dividendsChartTooltip"
    />
  )
}

export { Tooltip }
