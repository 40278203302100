import { Children, useMemo, type ReactNode, type ReactElement } from 'react'

type RotatorProps = {
  children: ReactNode
  showChild: number | undefined
}

function Rotator({ children, showChild }: RotatorProps): ReactElement {
  const childrenCount = useMemo(() => Children.count(children), [children])

  const visibleChild = useMemo(() => {
    const roundedMin = 0
    const roundedMax = childrenCount - 1

    return Math.floor(Math.random() * (roundedMax - roundedMin + 1)) + roundedMin
  }, [childrenCount])

  if (showChild === 0 || showChild) return children?.[showChild]

  return childrenCount < 2 ? children : children?.[visibleChild]
}
export default Rotator
