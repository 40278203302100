import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe.js'

import { useMediaQueries } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Tabs, { Tab } from 'components/molecules/Tabs'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

import './BuySellTabs.css'

const tabs = {
  BUY: 'buy',
  SELL: 'sell',
}

const BuySellTabs = ({ activeTab, handleSell, handleBuy }) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const components = (
    <Tabs className={classNames('BuySellTabs', getMediaQuieryClasses('BuySellTabs', mediaQueries))} theme="withBorder">
      <Tab
        active={activeTab === tabs.BUY}
        fullWidthBorder
        onClick={handleBuy}
        className="BuySellTabs-Tab"
        data-test-id="buyTab"
      >
        Buy
      </Tab>
      <Tab
        active={activeTab === tabs.SELL}
        fullWidthBorder
        onClick={handleSell}
        className="BuySellTabs-Tab"
        data-test-id="sellTab"
      >
        Sell
      </Tab>
    </Tabs>
  )

  return desktop ? components : <SideSpacesCompensator>{components}</SideSpacesCompensator>
}

BuySellTabs.propTypes = {
  activeTab: PropTypes.oneOf([tabs.BUY, tabs.SELL]),
  handleSell: PropTypes.func,
  handleBuy: PropTypes.func,
}

export { BuySellTabs }
