import { useUnit } from 'effector-react'

import {
  useAsyncEffect,
  useState,
  useActions,
  useLoading,
  useCallback,
  useMediaQueries,
  useMemo,
  useSelector,
  useEffect,
  useContext,
} from 'hooks'

import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router.js'

import { $dictsStore } from 'app/effector/dicts'

import {
  sharePortfolio as sharePortfolioActionCreator,
  fetchPortfolios as fetchPortfoliosActionCreator,
} from 'app/redux/actions/portfolios'
import { showSuccessToast, showFailToast } from 'app/redux/actions/ui'
import { getPortfolioSharingData, changeSharingPortfolioTitle } from 'app/redux/api/portfolio.js'
import { selectGoalById } from 'app/redux/selectors/'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

import { type SharingPortfolioData, type SharingPortfolioHookData } from '../types'

const useSharePortfolio = (
  portfolioId: string,
  backLink: string,
  descriptionExpanded: boolean = false,
): SharingPortfolioHookData => {
  const { desktop } = useMediaQueries()

  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])

  const { isLoading: isSharingDataLoading, wait: waitSharingData } = useLoading(false)
  const { isLoading: isPortfoliosLoading, wait: waitPortfolios } = useLoading(false)

  const portfolios = useSelector((state) => state.portfolios.list)

  const { saveScrollPosition, restoreScrollPosition } = useContext(ScrollSaverContext)

  useEffect(() => {
    const waitForFetchPortfolios = async (): Promise<void> => {
      await waitPortfolios(fetchPortfolios({ setNotValidBefore: false, setValidAfter: false }))
    }

    if (portfolios.length < 1) waitForFetchPortfolios()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios])

  const portfolio = useSelector((state) => selectGoalById(state, parseInt(portfolioId, 10)))
  const { isPromoEnabled } = useUnit($dictsStore)

  const [sharingData, setSharingData] = useState<SharingPortfolioData>({
    title: '',
    description: '',
    securities: [],
    equities: [],
    regions: [],
    sectors: [],
    equity_types: {
      ALTERNATIVE: 0,
      BOND: 0,
      STOCK: 0,
    },
    assets: [],
  })

  const sharePortfolio = useActions(sharePortfolioActionCreator)

  const [isLinkEnabled, setIsLinkEnabled] = useState(!!portfolio?.share_url)

  useEffect(() => {
    setIsLinkEnabled(!!portfolio?.share_url)
  }, [portfolio])

  const toggleShareLink = useCallback(
    async (isEnabled: boolean): void => {
      setIsLinkEnabled(isEnabled)
      const shareUrl = await sharePortfolio({ portfolioId, isEnabled })
      setIsLinkEnabled(!!shareUrl)
      showSuccessToast(shareUrl ? 'Portfolio outline sharing activated' : 'Portfolio outline sharing deactivated')
    },
    [portfolioId, sharePortfolio],
  )

  const closeSharePage = (): void => {
    goTo(backLink || urlTo('dashboard.portfolio', { id: portfolioId }))
  }

  //  Share Modal
  const [isShareModalOpened, setShareModalOpened] = useState(false)

  const closeShareModal = useCallback(() => {
    setShareModalOpened(false)
  }, [setShareModalOpened])

  // Sharing Title and Description Edit Modal
  const [sharingTitle, setSharingTitle] = useState('')
  const [sharingDescription, setSharingDescription] = useState('')
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(descriptionExpanded)

  const handleDescriptionExpand = useCallback(() => {
    setIsDescriptionExpanded(true)
    goTo(
      urlTo(
        'dashboard.portfolio.share.portfolio-share',
        { id: portfolioId },
        { ...(backLink ? { back: backLink } : {}), descriptionExpanded: true },
      ),
      {
        replace: true,
      },
    )
  }, [setIsDescriptionExpanded, backLink, portfolioId])

  const [isTitleEditModalOpen, setTitleEditModalOpen] = useState(false)

  const closeTitleEditModal = useCallback(() => {
    setTitleEditModalOpen(false)
  }, [setTitleEditModalOpen])

  const openTitleEditModal = useCallback(() => {
    setSharingTitle(sharingData.title)
    setSharingDescription(sharingData.description)
    setTitleEditModalOpen(true)
  }, [setTitleEditModalOpen, sharingData, setSharingTitle])

  const saveSharingTitle = async (): Promise<void> => {
    // remove multiple line breaks from description
    const strippedDescription = sharingDescription.replace(/\n\s*\n\s*\n/g, '\n\n')

    const result = await changeSharingPortfolioTitle({
      portfolioId,
      title: sharingTitle,
      description: strippedDescription,
    })

    if (result instanceof Error) {
      showFailToast()
      return
    }
    setSharingData({ ...sharingData, title: sharingTitle, description: strippedDescription })
    showSuccessToast('Shared portfolio updated')
    closeTitleEditModal()
  }

  const shareTitle = 'Look at this awesome portfolio!'
  const shareUrl = useMemo(() => portfolio?.share_url, [portfolio])

  const openShareModal = useCallback(async () => {
    if (!shareUrl) {
      window.scroll(0, 0)
      document.querySelector('.MobileLayout-Content')?.scrollTo(0, 0)
      return
    }
    if (!desktop && navigator.share) {
      await navigator.share({
        title: shareTitle,
        url: shareUrl,
      })
      return
    }
    setShareModalOpened(true)
  }, [desktop, setShareModalOpened, shareTitle, shareUrl])

  // TsCs Modal
  const [showTsCsModal, setShowTsCsModal] = useState<boolean>(false)

  const handleOpenTsCsModal = useCallback(() => {
    setShowTsCsModal(true)
  }, [setShowTsCsModal])
  const handleCloseTsCsModal = useCallback(() => {
    setShowTsCsModal(false)
  }, [setShowTsCsModal])

  useAsyncEffect(async () => {
    const data = await waitSharingData(getPortfolioSharingData(portfolioId))

    data.equity_types.STOCK = parseFloat(data.equity_types.STOCK)
    data.equity_types.BOND = parseFloat(data.equity_types.BOND)
    data.equity_types.ALTERNATIVE = parseFloat(data.equity_types.ALTERNATIVE)
    ;['sectors', 'regions', 'equities'].forEach((type) => {
      data[type] = data[type].map((item) => ({
        ...item,
        target: parseFloat(item.target_weight),
        color: item.color || palette.stocks,
      }))
    })

    data.assets = [
      { name: 'Equities', target: parseFloat(data.equity_types.STOCK ?? 0), color: palette.stocks },
      { name: 'Bonds', target: parseFloat(data.equity_types.BOND ?? 0), color: palette.bonds },
      {
        name: 'Alternatives',
        target: parseFloat(data.equity_types.ALTERNATIVE ?? 0),
        color: palette.alternatives,
      },
    ]

    data.securities = data.securities.map((security) => ({
      ...security,
      target_weight: security.target_weight === null ? 0 : parseFloat(security.target_weight),
    }))

    setSharingData(data)
    setSharingTitle(data.title)
    setSharingDescription(data.description)
    restoreScrollPosition()
  }, [portfolioId])

  const handleSecurityClick = useCallback(
    (securityId: string) => {
      saveScrollPosition()
      goTo(
        urlTo(
          'dashboard.portfolio.share.shared-security',
          { id: portfolioId, securityId },
          {
            ...(backLink ? { back: backLink } : {}),
            ...(isDescriptionExpanded ? { descriptionExpanded: isDescriptionExpanded } : {}),
          },
        ),
      )
    },
    [portfolioId, backLink, saveScrollPosition, isDescriptionExpanded],
  )

  return {
    ...sharingData,
    isLoading: isSharingDataLoading || isPortfoliosLoading,
    isPromoEnabled,
    toggleShareLink,
    closeSharePage,
    openShareModal,
    closeShareModal,
    isShareModalOpened,
    shareUrl,
    shareTitle,
    isLinkEnabled,
    handleSecurityClick,
    isTitleEditModalOpen,
    openTitleEditModal,
    closeTitleEditModal,
    sharingTitle,
    setSharingTitle,
    sharingDescription,
    setSharingDescription,
    saveSharingTitle,
    showTsCsModal,
    handleOpenTsCsModal,
    handleCloseTsCsModal,
    isDescriptionExpanded,
    handleDescriptionExpand,
  }
}

export { useSharePortfolio }
