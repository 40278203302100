import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import './Toggle.css'

const Toggle = ({ className, checked, onChange: handleChange, 'data-test-id': dataTestId }) => {
  const classes = classNames('Toggle', className, { Toggle_checked: checked })

  return (
    <span className={classes} data-test-id={dataTestId}>
      <input className="Toggle-Checkbox" type="checkbox" checked={checked} onChange={handleChange} />
      <div className="Toggle-Swipe" />
    </span>
  )
}

Toggle.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Toggle
