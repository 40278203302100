import React from 'react'

import { BonusStates } from 'app/effector/bonuses/models'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { AnimatedVisibilityToggle } from 'components/atoms/AnimatedVisibilityToggle'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Relative } from 'components/atoms/Relative'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'

import { TunnelHeader } from 'app/pages/Securities/components'

import { Box } from '../components/Box'
import { Title } from '../components/Title'

import type { UseClaimBonusInterface } from '../hooks'

function Desktop({
  state,
  value,
  handleBack,
  handleClaim,
  handleContinue,
}: UseClaimBonusInterface): React.ReactElement {
  return (
    <DesktopLayout
      content={
        <Relative overflow="hidden">
          <Paper bottom={120}>
            <Inner>
              <AnimatedVisibilityToggle visible={state === BonusStates.NEW}>
                <TunnelHeader onBack={handleBack} />
              </AnimatedVisibilityToggle>
              <AllCenter>
                <Paper top={48}>
                  <Title open={state !== BonusStates.NEW} />
                </Paper>
                <Paper top={40}>
                  <Box open={state !== BonusStates.NEW} value={value} handleContinue={handleContinue} />
                </Paper>
                <AnimatedVisibilityToggle visible={state === BonusStates.NEW}>
                  <Paper top={48}>
                    <Typography size={20} align="center">
                      <Typo>Open the box to reveal your Welcome Bonus!</Typo>
                    </Typography>
                  </Paper>
                  <Paper top={24}>
                    <Width size={20} center>
                      <Button onClick={handleClaim} mods={{ color: 'green', size: 'big block' }}>
                        Open the box!
                      </Button>
                    </Width>
                  </Paper>
                </AnimatedVisibilityToggle>
              </AllCenter>
            </Inner>
          </Paper>
        </Relative>
      }
      footer={<DesktopFooter />}
      noGaps
    />
  )
}

export { Desktop }
