import { useSelector, useEffect, useMemorizedState, MemorizedStateStorages } from 'hooks'

import { getBonuses } from 'helpers/getBonuses'

type Bonuses = {
  accountFeesSaved: string
  numberOfTrades: string
  portfoliosRebalanced: string
  publishedAt: string
  referFriendOrBusinessBonusMax: string
  referFriendOrBusinessBonusMin: string
  referIsaOrGeneralBonusMax: string
  referIsaOrGeneralBonusMin: string
  tradingFeesSaved: string
  variableAffiliateBonusMax: string
  variableAffiliateBonusMin: string
  variableAffiliateBusinessBonusMax: string
  variableAffiliateBusinessBonusMin: string
  welcomeBonusInvestAmountMin: string
  welcomeBonusMax: string
  updatedAt: string
  createdAt: string
} | null

const useStrapiBonuses = (): { bonuses: Bonuses } => {
  const [bonuses, setBonuses] = useMemorizedState<Bonuses>('Banner/', null, {
    storage: MemorizedStateStorages.SESSION,
  })
  const client = useSelector(({ client }) => client)
  const isAuthorized = Boolean(client.access_token)

  useEffect(() => {
    if (bonuses === null && isAuthorized) {
      getBonuses()
        .then((result) => {
          setBonuses(result)
        })
        .catch(() => {
          throw new Error('Something went wrong when we tried to fetch bonuses from Strapi')
        })
    }
  }, [bonuses, setBonuses, isAuthorized])

  return { bonuses }
}

export { useStrapiBonuses }
