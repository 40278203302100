/**
 * @param {Function|Class} Class
 * @return {function(object, string, string): (Error|undefined)}
 */
const instanceOf = (Class) => {
  return (props, propName, componentName) => {
    const prop = props[propName]

    if (!(prop instanceof Class)) {
      return new Error(`'${componentName}' only accepts '${propName}' as instance of '${Class.name}'`)
    }
  }
}

export { instanceOf }
