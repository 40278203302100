import { useUnit } from 'effector-react'

import { useCallback, useMemo, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import moment from 'helpers/date.js'
import { goTo, urlTo } from 'helpers/router'

import { $quickStartsStore } from 'app/effector/quickStart'

import type { Portfolio } from 'app/redux/models/portfolio/types'
import {
  isClientNewOrNotCompleted as selectIsClientNewOrNotCompleted,
  isClientApproved as selectIsClientApproved,
} from 'app/redux/selectors'

import { manageTypes } from 'constants/portfolio'
import { quickStartStatuses } from 'constants/quickStart'

type UseRecurringPaymentStartProps = {
  portfolio: Portfolio
  location: {
    query?: {
      back?: string
    }
  }
}

type UseRecurringPaymentStartReturnProps = {
  isQuickStartInProgress: boolean
  handleBack: () => void
  handleContinue: () => void
}

const useRecurringPaymentStart = ({
  portfolio,
  location,
}: UseRecurringPaymentStartProps): UseRecurringPaymentStartReturnProps => {
  const isClientNewOrNotCompleted = useSelector(selectIsClientNewOrNotCompleted)
  const isClientApproved = useSelector(selectIsClientApproved)
  const currentLocation = useMemo(() => window.location.pathname + window.location.search, [])
  const { getQuickStartByPortfolioId } = useUnit($quickStartsStore)
  const quickStart = getQuickStartByPortfolioId(portfolio.id)
  const isLumpSum = !quickStart?.frequency
  const isQuickStartInProgress = isClientApproved && quickStart?.status === quickStartStatuses.ACTIVE && !isLumpSum

  const handleBack = useCallback(() => {
    if (portfolio.manage_type === manageTypes.CASH) {
      goTo(location?.query?.back ?? urlTo('dashboard.portfolio', { id: portfolio.id }))
      return
    }

    goTo(location?.query?.back ?? urlTo('dashboard.portfolio.options', { id: portfolio.id }))
  }, [portfolio, location])

  const handleContinue = useCallback(() => {
    if (isQuickStartInProgress) {
      trackEvent({
        action: 'sp_promo_finish_continued',
        manage_type: portfolio?.manage_type,
        ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
      })
    } else {
      trackEvent({
        action: 'sp_promo_continued',
        manage_type: portfolio?.manage_type,
        ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
        portfolio_created_date: moment(portfolio?.created).format('YYYY-MM-DD'),
      })
    }

    if (isClientNewOrNotCompleted) {
      goTo(urlTo('portfolio.savings-plan-setup', { id: portfolio.id }, { ...location.query, back: currentLocation }))
      return
    }

    goTo(
      urlTo(
        `dashboard.portfolio.options.recurring-payment.recurring-payment-setup`,
        { id: portfolio.id },
        location.query ?? {},
      ),
    )
  }, [portfolio, location, isClientNewOrNotCompleted, currentLocation, isQuickStartInProgress])

  return {
    isQuickStartInProgress,
    handleBack,
    handleContinue,
  }
}

export { useRecurringPaymentStart }
