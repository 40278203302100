import React, { Fragment } from 'react'

import chunk from 'lodash/chunk'
import PropTypes from 'prop-types'

import { useMemo, useCallback } from 'hooks'

import { urlTo } from 'helpers/router.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Progress } from 'components/_old/Progress/'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DesktopLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { DesktopFooter } from 'app/containers/Footer'
import HeadlineHelp from 'app/containers/PortfolioTunnel/Tunnel/HeadlineHelp/HeadlineHelp.jsx'
import FeeInfo from 'app/containers/Questionnaire/FeeInfo/FeeInfo.jsx'

import { MultipleChoiceCard } from '../components/MultipleChoiceCard'
import { useMultipleChoice } from '../hooks'

const DesktopMultiple = ({
  progress,
  question,
  selectedAnswer,
  backLinkText,
  handleBack,
  handleSubmit,
}: MultipleViewProps): React.ReactElement => {
  const { title, description, answers } = question
  const { selectedAnswers, handleSelect } = useMultipleChoice(answers, selectedAnswer)

  const handleSubmitClick = useCallback(() => {
    handleSubmit(selectedAnswers)
  }, [selectedAnswers, handleSubmit])

  const header = useMemo(
    () => (
      <Header
        right={
          <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}>
            <Link to={urlTo('dashboard')}>My Dashboard</Link>
          </Button>
        }
        after={
          <Fragment>
            <Progress fill={progress} gapWidth="1em" />
            <FeeInfo />
          </Fragment>
        }
        hideDrawer
      />
    ),
    [progress],
  )

  const heading = useMemo(
    () => (
      <Paper top={24} bottom={48}>
        <NavigationBar leftPartText={backLinkText} onLeftPartClick={handleBack}>
          <span data-test-id="headline">
            <Typo>{title}</Typo>
          </span>
          {description?.text && (
            <Typography className="Question_Headline_help" tag="span" size={16}>
              <HeadlineHelp
                iconType="question"
                background="status-warning"
                title={description?.title}
                body={description?.text}
              />
            </Typography>
          )}
        </NavigationBar>
      </Paper>
    ),
    [title, description, backLinkText, handleBack],
  )

  const options = useMemo(() => {
    const answersRows = chunk(answers, 3).map((chunk) => {
      while (chunk.length < 3) {
        chunk.push(null)
      }

      return chunk
    })

    return (
      <Paper top={4} bottom={48}>
        <SideSpacesCompensator>
          <AllCenter>
            {answersRows.map((row, index) => (
              <Paper className="Question_Answers" key={index} top={index ? 32 : 0}>
                <ColumnarLayout>
                  {row.map((answer, i: number) => {
                    const tabIndex = index * 3 + i + 1

                    if (!answer?.id) {
                      return <Column key={i} />
                    }

                    return (
                      <Column key={answer.id}>
                        <MultipleChoiceCard
                          tabIndex={tabIndex}
                          answer={answer}
                          selected={selectedAnswers.includes(answer.id)}
                          onClick={handleSelect}
                        />
                      </Column>
                    )
                  })}
                </ColumnarLayout>
              </Paper>
            ))}
          </AllCenter>
        </SideSpacesCompensator>
      </Paper>
    )
  }, [answers, selectedAnswers, handleSelect])

  const button = useMemo(
    () => (
      <Width size={14} center>
        <Button
          type="submit"
          mods={{ size: 'big block', theme: null }}
          onClick={handleSubmitClick}
          tabIndex={answers.length + 1}
          disabled={!selectedAnswers.length}
          data-test-id="questionnaireSubmit"
        >
          Continue
        </Button>
      </Width>
    ),
    [answers.length, selectedAnswers.length, handleSubmitClick],
  )

  const content = (
    <Inner>
      {heading}
      <Paper bottom={64}>
        <Text center block>
          <Typo>Tick all that apply</Typo>
        </Text>
      </Paper>
      {options}
      {button}
    </Inner>
  )

  return <DesktopLayout header={header} content={content} footer={<DesktopFooter />} />
}

DesktopMultiple.propTypes = {
  progress: PropTypes.number,
  question: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    }),
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      }),
    ),
  }),
  selectedAnswer: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  backLinkText: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleBack: PropTypes.func,
}

export { DesktopMultiple }
