import React from 'react'
import PropTypes from 'prop-types'

import { useCallback, useMediaQueries, useRedirect, useSubmitOnEnter, useMemo, useRef } from 'hooks'

import { palette } from 'helpers/palette/'
import { bulkValidate } from 'helpers/validation.js'
import { urlTo } from 'helpers/router.js'

import Card from 'components/_old/Card/Card.jsx'
import Width from 'components/_old/Width/Width'
import Form, { Fieldset } from 'components/_old/Form/Form.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Button from 'components/_old/Button/Button.jsx'

import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import ContentHolder from 'components/molecules/ContentHolder/ContentHolder.jsx'
import { Questionnaire } from 'app/containers/Questionnaire'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'

import { types as clientTypes } from 'constants/client'

import { isClientOnboarded } from 'app/redux/selectors'

import { useCreateAccount } from './hooks'
import { CreateAccountSubmit } from './CreateAccountSubmit.jsx'
import { CreateAccountRequirements } from './CreateAccountRequirements'
import { IsaTransferPrefillForm } from './components/IsaTransferPrefillForm'

import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

import './CreateAccount.css'

const CreateAccount = ({ location: { query = {} } = {} }) => {
  const { desktop, phone } = useMediaQueries()

  const {
    mode,
    back,
    client,
    validation,
    isProcessing,
    handleSubmit,
    handleChangeClientField,
    handlePrivacyPolicyAgreement,
    handleChangeNotificationAgreement,
    isAgreeWithPrivacyPolicy,
    isAgreeWithNotifications,
    isEmailStep,
    isPasswordStep,
    isIsaTransferStep,
    moveToPasswordStep,
    moveToEmailStep,
    shouldShowIsaTransfer,
  } = useCreateAccount({ query })

  const formRef = useRef(null)
  const clientOnboarded = isClientOnboarded({ client })
  const isBusiness = client.type === clientTypes.BUSINESS

  useSubmitOnEnter(formRef.current)

  useRedirect({
    to: 'product-type',
    rule: !client.access_token && !isProcessing,
    isLoading: client.didInvalidate,
  })

  useRedirect({
    to: 'dashboard',
    rule: client.access_token && clientOnboarded && !isProcessing,
    isLoading: client.didInvalidate,
  })

  const submit = isEmailStep ? (
    <Paper top={desktop ? 0 : 32}>
      <Button
        type="submit"
        mods={{ size: 'big block' }}
        disabled={!bulkValidate({ email: validation.email })}
        onClick={moveToPasswordStep}
        data-test-id="continueButton"
      >
        Continue
      </Button>
      <Paper top={32} bottom={16}>
        <Link
          to={urlTo('logout', null, { next: urlTo('login', null, query) })}
          analyticsEvent={{
            action: 'already_signed_up_login',
            client_type: client.type,
          }}
        >
          <Typography lineHeight="small" color="inherit" align="center">
            <Typo>Already have an account? Log in</Typo>
          </Typography>
        </Link>
      </Paper>
      <Paper top={16} bottom={16}>
        <Typography lineHeight="small" color="inherit" align="center">
          <Typo>
            With investment, your capital is at{' '}
            <Link to="/risk-disclosure/" mods={{ color: 'black' }} underline blank hard>
              <Typography tag="span" lineHeight="small" color="inherit" align="center">
                <Typo>risk</Typo>
              </Typography>
            </Link>
            .
            <br /> ETF fees apply.
          </Typo>
        </Typography>
      </Paper>
    </Paper>
  ) : (
    <CreateAccountSubmit
      disabled={!bulkValidate(validation)}
      rules={validation.isAgreeWithPrivacyPolicy.rules}
      isAgreeWithPrivacyPolicy={isAgreeWithPrivacyPolicy}
      handlePrivacyPolicyAgreement={handlePrivacyPolicyAgreement}
      isAgreeWithNotifications={isAgreeWithNotifications}
      handleChangeNotificationAgreement={handleChangeNotificationAgreement}
      onSubmit={handleSubmit}
      isIsaTransferStep={isIsaTransferStep}
      shouldShowIsaTransfer={shouldShowIsaTransfer}
    />
  )

  const handleChangeEmail = useCallback(
    (_e, val) => {
      handleChangeClientField('email', val)
    },
    [handleChangeClientField],
  )

  const handleChangePassword = useCallback(
    (_e, val) => {
      handleChangeClientField('password', val)
    },
    [handleChangeClientField],
  )

  const rules = useMemo(
    () => (
      <Paper bottom={desktop ? 4 : 0}>
        <CreateAccountRequirements isBusiness={isBusiness} />
      </Paper>
    ),
    [desktop, isBusiness],
  )

  const form = (
    <Form ref={formRef}>
      <Fieldset>
        {isEmailStep && (
          <Validate rules={validation.email.rules} value={client.email}>
            <Label
              mods={{ color: desktop ? null : 'darkgray' }}
              errorMessages={validation.email.errors}
              data-test-id="createAccountEmailField"
            >
              {isBusiness ? 'Business email' : 'Email'}
              <Input
                name="email"
                type="email"
                onChange={handleChangeEmail}
                mods={{ size: 'bigger' }}
                tabIndex={2}
                data-test-id="createAccountEmailInput"
              >
                {client.email}
              </Input>
            </Label>
          </Validate>
        )}
        {isEmailStep && (
          <Paper top={desktop ? 32 : 24} bottom={40}>
            {rules}
          </Paper>
        )}
        {isPasswordStep && (
          <Paper bottom={64}>
            <Validate rules={validation.password.rules}>
              <Label
                mods={{ color: desktop ? null : 'darkgray' }}
                postfield={
                  <span
                    style={{
                      color: palette['content-on-background-minor'],
                    }}
                  >
                    Use at least one digit and one capital letter
                  </span>
                }
                errorMessages={validation.password.errors}
                data-test-id="createAccountPasswordField"
              >
                Choose a password
                <Input
                  autoComplete="off"
                  name="password"
                  type="password"
                  onChange={handleChangePassword}
                  mods={{ size: 'bigger' }}
                  tabIndex={3}
                  data-test-id="createAccountPasswordInput"
                >
                  {client.password}
                </Input>
              </Label>
            </Validate>
          </Paper>
        )}
        {isIsaTransferStep && <IsaTransferPrefillForm />}
        <StickedIfNotDesktop into="afterLayout" newButton>
          {phone ? <Inner>{submit}</Inner> : submit}
        </StickedIfNotDesktop>
      </Fieldset>
    </Form>
  )

  const content = desktop ? (
    <Paper top={56} bottom={80}>
      <Width size={30} center>
        <Paper bottom={40}>
          <Card mods={{ theme: 'light white', 'no-padding': 'top bottom left right' }} fluid>
            <Paper top={48} bottom={48} left={48} right={48}>
              {form}
            </Paper>
          </Card>
        </Paper>
      </Width>
    </Paper>
  ) : (
    <Paper top={8}>{form}</Paper>
  )

  const leftLinkUrl = useMemo(() => {
    if (isIsaTransferStep || isPasswordStep) {
      return urlTo('create-account', null, query)
    }
    return back ?? urlTo('logout', null, { next: urlTo('product-type', null, query) })
  }, [isPasswordStep, isIsaTransferStep, back, query])

  const leftLink = (
    <Link to={leftLinkUrl} onClick={isIsaTransferStep ? moveToPasswordStep : isPasswordStep ? moveToEmailStep : null}>
      Back
    </Link>
  )

  const rightLink = useMemo(() => {
    if (!desktop) {
      return null
    }

    if (mode === 'DASHBOARD') {
      return (
        <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}>
          <Link to={urlTo('dashboard')}>My Dashboard</Link>
        </Button>
      )
    }

    return (
      <Button mods={{ color: 'green', size: 'small', text: 'smaller' }}>
        <Link to={urlTo('logout')} classnameless>
          Back to the site
        </Link>
      </Button>
    )
  }, [desktop, mode])

  return (
    <Questionnaire
      headerProps={{
        withProgress: false,
        withShadow: true,
        leftLink,
        rightLink,
      }}
      stepHeadlineProps={{
        backLinkUrl: isIsaTransferStep ? moveToPasswordStep : isPasswordStep ? moveToEmailStep : leftLinkUrl,
        headline: isIsaTransferStep ? 'ISA Transfer' : isPasswordStep ? 'Choose a password' : 'Create an account',
        forceShow: true,
      }}
    >
      <ContentHolder stretch={!desktop}>{content}</ContentHolder>
    </Questionnaire>
  )
}

CreateAccount.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string,
  }),
  location: PropTypes.shape({
    query: PropTypes.object,
  }),
}

export { CreateAccount }
