import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useLoading, useMemo, useSelector, useState } from 'hooks'

import { $securitiesStore } from 'app/effector/securities'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'
import { getHoldingAnalyticsByWeights } from 'app/redux/api/analytics.js'
import { type Portfolio } from 'app/redux/models/portfolio/types'

import { colors } from 'constants/analytics'

type UseHoldingModalProps = {
  portfolioId?: string
  isPortfolioNew: boolean
  currentValues: Record<string, unknown>
}

type HoldingModalSecurity = {
  id: number
  color: string
  title: string
  current_target_weight?: number
  target_weight: number
}

export type HoldingData = {
  type: 'holding' | 'sector' | 'region'
  name: string
  logo?: string
  color?: string
  description?: string
  website?: string
  current_target_weight: number
  target_weight?: number
  sector_name?: string
  region_name?: string
  securities: HoldingModalSecurity[]
}

type UseHoldingModalReturnProps = {
  isHoldingModalOpened: boolean
  isLoading: boolean
  selectedPortfolio: Portfolio
  holdingData: HoldingData | null
  holdingDistribution: Array<{ value: number; color: string }>
  openHoldingModal: (holdingId: string, holdingType: string) => void
  closeHoldingModal: () => void
}

const useHoldingModal = ({
  portfolioId: portfolioIdString,
  isPortfolioNew,
  currentValues,
}: UseHoldingModalProps): UseHoldingModalReturnProps => {
  const portfolioId = useMemo(() => (portfolioIdString ? parseInt(portfolioIdString, 10) : null), [portfolioIdString])

  const [holdingData, setHoldingData] = useState<HoldingData | null>(null)
  const [isHoldingModalOpened, setHoldingModalOpened] = useState<boolean>(false)

  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])

  const portfolios = useSelector((state) => state.portfolios.list)
  const { securities } = useUnit($securitiesStore)

  const { isLoading, wait } = useLoading(false)

  const selectedPortfolio = useMemo(() => portfolios.get(portfolioId), [portfolios, portfolioId])

  const fetchHoldingAnalytics = useCallback(
    async (holdingId, holdingType) => {
      try {
        setHoldingData(null)

        const weights = Object.keys(currentValues).map((securityId) => ({
          security_id: parseInt(securityId, 10),
          weight: currentValues[securityId],
        }))
        const holdingAnalytics = await wait(
          getHoldingAnalyticsByWeights({
            type: holdingType,
            id: holdingId,
            portfolioId: isPortfolioNew ? null : portfolioId,
            weights,
          }),
        )

        // convert strings -> floats
        holdingAnalytics.current_target_weight = holdingAnalytics.current_target_weight
          ? parseFloat(holdingAnalytics.current_target_weight)
          : 0
        holdingAnalytics.target_weight = holdingAnalytics.target_weight ? parseFloat(holdingAnalytics.target_weight) : 0
        holdingAnalytics.securities.forEach((item) => {
          item.current_target_weight = item.current_target_weight ? parseFloat(item.current_target_weight) : 0
          item.target_weight = item.target_weight ? parseFloat(item.target_weight) : 0
        })
        holdingAnalytics.type = holdingType

        holdingAnalytics.securities.forEach((security: HoldingModalSecurity, index: number) => {
          const colorIndex = index % 50

          security.color = colors[colorIndex]
          security.title = securities.find((item) => item.id === security.id)?.title ?? ''
        })

        setHoldingData(holdingAnalytics)
      } catch {
        showFailToast('Error loading analytics')
      }
    },
    [portfolioId, isPortfolioNew, currentValues, securities, wait],
  )

  const openHoldingModal = useCallback(
    (id, type) => {
      setHoldingModalOpened(true)
      fetchHoldingAnalytics(id, type)
    },
    [setHoldingModalOpened, fetchHoldingAnalytics],
  )
  const closeHoldingModal = useCallback(() => {
    setHoldingModalOpened(false)
  }, [setHoldingModalOpened])

  useEffect(() => {
    if (portfolios.length < 1) {
      wait(fetchPortfolios({ setNotValidBefore: false, setValidAfter: false }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios])

  const holdingDistribution = useMemo(
    () =>
      holdingData?.securities
        ? holdingData.securities.map((security) => ({
            color: security.color,
            value: security.target_weight,
          }))
        : [],
    [holdingData?.securities],
  )

  return {
    isHoldingModalOpened,
    isLoading,
    selectedPortfolio,
    holdingData,
    holdingDistribution,
    openHoldingModal,
    closeHoldingModal,
  }
}

export { useHoldingModal, type HoldingData }
