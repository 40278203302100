import React, { Fragment } from 'react'

import { useMediaQueries, useState } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import '../CashPortfolio.css'

const CashPortfolioWarning = (): React.ReactElement => {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <Fragment>
      <button
        className="CashPortfolioWarning-Button"
        onClick={() => {
          setModalOpen(!isModalOpen)
        }}
      >
        <ItemWithIcon
          space={16}
          iconPosition="right"
          iconVerticalAlign="center"
          className="CashPortfolioWarning-Wrapper"
          icon={<Icon type="information-24" size={24} color="inherit" />}
          content={
            <Typography tag="p" lineHeight="small">
              Please note InvestEngine does not pay interest on the uninvested cash held within this account
            </Typography>
          }
        />
      </button>
      <CashPortfolioWarningModal
        isModalOpened={isModalOpen}
        handleCloseModal={() => {
          setModalOpen(false)
        }}
      />
    </Fragment>
  )
}

type CashPortfolioWarningModalProps = {
  isModalOpened: boolean
  handleCloseModal: (...args: any) => void
}

const CashPortfolioWarningModal = ({
  isModalOpened,
  handleCloseModal,
}: CashPortfolioWarningModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const modalContent = (
    <Fragment>
      {desktop && (
        <AllCenter>
          <Icon type="information-54" size={54} />
        </AllCenter>
      )}

      <Paper top={16}>
        <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
          <Typo>Uninvested Cash Information</Typo>
        </Typography>
      </Paper>
      <Paper top={desktop ? 40 : 24}>
        <Typography tag="p" textWrap="pretty">
          InvestEngine offers a commission and fee-free service by generating interest on uninvested cash held on the
          platform. However, any uninvested cash you hold with us won't earn you anything.
          <br />
          <br />
          To make the most of your money, you can use the Move cash out button to transfer it into an existing
          InvestEngine portfolio and enable AutoInvest to invest it automatically. Alternatively, you can transfer the
          cash to an interest-bearing account outside InvestEngine.
          <br />
          <br />
          You can find{' '}
          <Link to="https://investengine.com/why-we-are-free/" blank>
            a full breakdown
          </Link>{' '}
          of how we provide a free service.
        </Typography>
      </Paper>
    </Fragment>
  )

  return desktop ? (
    <Modal open={isModalOpened} onClose={handleCloseModal} close={<Typography lineHeight="small">Close</Typography>}>
      <ModalContent width={36}>
        <Paper top={96} bottom={64} left={64} right={64}>
          {modalContent}
        </Paper>
      </ModalContent>
    </Modal>
  ) : (
    <Modal open={isModalOpened} onClose={handleCloseModal}>
      <MobileLayout
        header={<NavigationBar onRightPartClick={handleCloseModal} rightPartText="Close" withShadow={false} />}
        content={
          <Paper top={12} bottom={48}>
            {modalContent}
          </Paper>
        }
      />
    </Modal>
  )
}

export { CashPortfolioWarning, CashPortfolioWarningModal }
