import React from 'react'
import Odometer from 'react-odometerjs'

import classNames from 'classnames/dedupe'

import { useMemo, useEffect, useMemorizedState, useDebouncedCallback, MemorizedStateStorages } from 'hooks'

import { getPoundWithPenceObject, format as formatMoney } from 'helpers/money'

import { Typography } from 'components/atoms/Typography'

import 'odometer/themes/odometer-theme-minimal.css'
import './PoundsWithPence.css'

type PoundsWithPenceProps = {
  className?: string
  amount: string | number
  numeric?: 'tabular'
  sameSize?: boolean
  showZeroPence?: boolean
  showPlusSign?: boolean
  showLessThanPointZeroOne?: boolean
  animated?: boolean
  cacheName?: string
  'data-test-id'?: string
}

type PoundsWithPenceAnimatedProps = {
  cacheName?: string
  className?: string
  amount: string | number
  numeric?: 'tabular'
  sameSize?: boolean
  showZeroPence?: boolean
  showPlusSign?: boolean
  'data-test-id'?: string
}

const PoundsWithPenceAnimated = ({
  className,
  cacheName,
  amount: rawAmount,
  numeric,
  sameSize = false,
  showZeroPence = false,
  showPlusSign = false,
  'data-test-id': dataTestId,
}: PoundsWithPenceAnimatedProps): React.ReactElement => {
  const [cachedAmount, setCachedAmount] = useMemorizedState(`PoundsWithPence/${cacheName}`, rawAmount, {
    storage: MemorizedStateStorages.SESSION,
    rewriteWithInitial: true,
  })

  const updateCachedAmount = useDebouncedCallback(
    (nextAmount) => {
      setCachedAmount(nextAmount)
    },
    250,
    [setCachedAmount],
  )

  useEffect(() => {
    updateCachedAmount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawAmount])

  const { sign, pounds, pence } = useMemo(() => {
    const amount = cachedAmount ?? rawAmount

    return getPoundWithPenceObject(amount, showZeroPence, showPlusSign, false)
  }, [cachedAmount, rawAmount, showZeroPence, showPlusSign])

  return (
    <span
      className={classNames(className, 'PoundsWithPence', { PoundsWithPence_sameSize: sameSize })}
      data-test-id={dataTestId}
    >
      {sign}
      <Typography tag="span" size="inherit" color="inherit" weight="inherit" numeric={numeric}>
        <span>£</span>
        <Odometer className="PoundsWithPence-Odometer" format="(,ddd)" duration={10000} value={parseInt(pounds, 10)} />
      </Typography>
      {typeof pence === 'string' ? (
        <Typography
          tag="span"
          size="inherit"
          color="inherit"
          weight="inherit"
          className="PoundsWithPence-Pence"
        >{`.${pence}`}</Typography>
      ) : null}
    </span>
  )
}

const PoundsWithPence = (props: PoundsWithPenceProps): React.ReactElement => {
  const {
    className,
    animated,
    amount: rawAmount,
    numeric,
    sameSize = false,
    showZeroPence = false,
    showPlusSign = false,
    showLessThanPointZeroOne = false,
    'data-test-id': dataTestId,
  } = props

  const { sign, pounds, pence, comparisonSign } = useMemo(
    () => getPoundWithPenceObject(rawAmount, showZeroPence, showPlusSign, showLessThanPointZeroOne),
    [rawAmount, showZeroPence, showPlusSign, showLessThanPointZeroOne],
  )

  if (animated) {
    return <PoundsWithPenceAnimated {...props} />
  }

  return (
    <span
      className={classNames(className, 'PoundsWithPence', { PoundsWithPence_sameSize: sameSize })}
      data-test-id={dataTestId}
    >
      {showLessThanPointZeroOne && comparisonSign}
      {sign}
      <Typography tag="span" size="inherit" color="inherit" weight="inherit" numeric={numeric}>
        {formatMoney(pounds)}
      </Typography>
      {typeof pence === 'string' ? (
        <Typography
          tag="span"
          size="inherit"
          color="inherit"
          weight="inherit"
          className="PoundsWithPence-Pence"
        >{`.${pence}`}</Typography>
      ) : null}
    </span>
  )
}

export { getPoundWithPenceObject, PoundsWithPence, type PoundsWithPenceProps }
