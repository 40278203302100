import React from 'react'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'

import { CardNew } from 'components/atoms/CardNew'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './LifePlanBanner.css'

import lifePlanImage from './lifePlan.jpg'

const LifePlanBanner = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const cardProps = {
    borderRadius: 16,
    clickable: false,
  }

  const content = (
    <CardNew className="LifePlanBanner" {...cardProps} shadow={0} style={{ border: 'none' }}>
      <img className="LifePlanBanner_Image" src={lifePlanImage} />
      <Paper
        className="LifePlanBanner_Content"
        left={24}
        right={24}
        top={24}
        bottom={24}
        style={desktop ? { justifyContent: 'center' } : {}}
      >
        <Typography size={20} lineHeight="small" weight="semibold" color="on_color_white" align="right">
          <Typo>Picked by you</Typo>
        </Typography>
        <Paper top={4}>
          <Typography size={14} lineHeight="small" weight="semibold" color="on_color_white" align="right">
            <Typo>Managed by InvestEngine</Typo>
          </Typography>
        </Paper>
      </Paper>
    </CardNew>
  )

  return content
}

export { LifePlanBanner }
