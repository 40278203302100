import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { palette } from 'helpers/palette/'
import { format as formatMoney } from 'helpers/money'

import { useCallback } from 'hooks'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Stack } from 'components/atoms/Stack'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'
import { VirtualList } from 'components/molecules/VirtualList'
import { TypeHeadline } from 'app/pages/Dashboard/Analytics/components/TypeHeadline.tsx'
import Button from 'components/_old/Button/Button.jsx'
import { SearchField } from 'components/molecules/SearchField'
import { Skeleton } from 'components/atoms/Skeleton'
import { FilterGroup } from 'components/organisms/Filter/FilterGroup'
import { Filter } from 'components/organisms/Filter'

import { equitiesPropTypes } from 'app/pages/Dashboard/Analytics/Analytics.jsx'

const EquitiesList = ({
  isLoading,
  stocks,
  bonds,
  alternatives,
  cash,
  showTarget,
  showAll,
  layoutNode,
  searchValue,
  handleSearch,
  handleGoToSingleHolding,
  handleShowFullList,
  filters,
}) => {
  const renderEquity = useCallback(
    (listElement, items, showTarget) => {
      const item = items[listElement.index]

      return (
        <Paper top={24}>
          <Skeleton shown={item.skeleton} mix>
            <SelectableCard onClick={() => handleGoToSingleHolding(item.id, 'holding')}>
              <Paper top={16} bottom={16} left={16} right={16}>
                <SecurityConstituent
                  key={item.name}
                  skeleton={item.skeleton}
                  logo={item.logo}
                  name={item.name}
                  actual={item.actual}
                  actualAmount={item.actual_amount}
                  target={item.target}
                  color={palette.stocks}
                  showTarget={showTarget}
                  showLogo={true}
                />
              </Paper>
            </SelectableCard>
          </Skeleton>
        </Paper>
      )
    },
    [showTarget, handleGoToSingleHolding],
  )

  const renderNoEquities = useCallback((showSearchInput, isListFiltered) => {
    if (!showSearchInput) {
      return null
    }

    if (isListFiltered) {
      return (
        <Paper top={24}>
          <Typography size={14} lineHeight="medium" color="minor" data-test-id="noSearchResults">
            <Typo>
              No holdings found.
              <br />
              Please try a different search.
            </Typo>
          </Typography>
        </Paper>
      )
    }
  }, [])

  const renderEquitiesList = useCallback(
    ([title, plural, equity, withShowAllButton, showSearchInput], showTarget) => {
      const hideFullList = equity.items.length > 100 && withShowAllButton && !showAll
      const items = hideFullList ? equity.items.slice(0, 100) : equity.items

      const isListFiltered = !!filters.regions.value.length || !!filters.sectors.value.length || !!searchValue

      const searchInput = showSearchInput && (
        <Paper top={32}>
          <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by holding" />
        </Paper>
      )

      const filtersPanel = showSearchInput && (
        <Paper top={16}>
          <Skeleton shown={equity.skeleton} mix>
            <FilterGroup fitContent>
              <Filter
                name="Region"
                type="checkbox"
                values={filters.regions.list}
                selected={filters.regions.value}
                onChange={filters.regions.set}
              />
              <Filter
                name="Sector"
                type="checkbox"
                dropdownMaxHeight={520}
                values={filters.sectors.list}
                selected={filters.sectors.value}
                onChange={filters.sectors.set}
              />
            </FilterGroup>
          </Skeleton>
        </Paper>
      )

      return (
        <Fragment key={title}>
          <TypeHeadline
            isLoading={isLoading}
            title={title}
            actual={equity.weight}
            target={equity.target}
            showTarget={showTarget}
          />
          <Paper top={4}>
            <Typography size={14} lineHeight="small" color="minor" data-test-id={`${title}ActualAmount`}>
              <Skeleton shown={isLoading} inline>
                <Typo>{formatMoney(equity.weight_amount, true, true)}</Typo>
              </Skeleton>
            </Typography>
          </Paper>
          {searchInput}
          {filtersPanel}

          {!!equity.count && (
            <Fragment>
              {!!items.length && (
                <Paper top={32}>
                  <ColumnarLayout>
                    <Column size={1}>
                      <Typography
                        tag="span"
                        size={14}
                        lineHeight="small"
                        color="additional"
                        data-test-id="holdingsQtySubtitle"
                      >
                        <Skeleton shown={equity.skeleton} inline>
                          <Typo>{`${equity.items.length} ${plural}${equity.items.length > 1 ? 's' : ''}`}</Typo>
                        </Skeleton>
                      </Typography>
                    </Column>
                    <Column size={0}>
                      <Skeleton shown={equity.skeleton} mix inline>
                        <ColumnarLayout mods={{ padding: 'no' }} data-test-id="actualTargetSubtitle" inline>
                          <Column size={0}>
                            <Typography tag="span" size={14} lineHeight="small" color="additional">
                              <Typo>Actual</Typo>
                            </Typography>
                          </Column>
                          {showTarget && (
                            <Column size={0}>
                              <Typography tag="span" size={12} lineHeight="small" color="minor">
                                &nbsp;/&nbsp;<Typo>Target</Typo>
                              </Typography>
                            </Column>
                          )}
                        </ColumnarLayout>
                      </Skeleton>
                    </Column>
                  </ColumnarLayout>
                </Paper>
              )}
              <VirtualList
                scrollableElement={layoutNode?.current}
                cacheKey="analytics-equities-list-desktop"
                items={items}
                itemMinHeight={92}
                itemMaxHeight={120}
                renderItem={(element) => renderEquity(element, items, showTarget)}
                renderNoItems={() => renderNoEquities(showSearchInput, isListFiltered)}
              />
              {hideFullList ? (
                <Paper top={24}>
                  <Button
                    mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
                    onClick={handleShowFullList}
                    data-test-id="showAllButton"
                  >
                    Show all {equity.items.length}
                  </Button>
                </Paper>
              ) : null}
            </Fragment>
          )}
        </Fragment>
      )
    },
    [
      isLoading,
      showTarget,
      showAll,
      layoutNode?.current,
      searchValue,
      renderEquity,
      renderNoEquities,
      handleShowFullList,
      handleSearch,
      filters,
    ],
  )

  return (
    <Stack vertical={56}>
      {[
        ['Equities', 'holding', stocks, true, true],
        ['Bonds', 'holding', bonds],
        ['Alternatives', 'holding', alternatives],
        ['Cash', 'holding', cash],
      ].map((data) => renderEquitiesList(data, showTarget))}
    </Stack>
  )
}

EquitiesList.propTypes = {
  isLoading: PropTypes.bool,
  showTarget: PropTypes.bool,
  showAll: PropTypes.bool,
  stocks: PropTypes.shape(equitiesPropTypes),
  bonds: PropTypes.shape(equitiesPropTypes),
  alternatives: PropTypes.shape(equitiesPropTypes),
  cash: PropTypes.shape(equitiesPropTypes),
  layoutNode: PropTypes.oneOfType([PropTypes.shape({ current: PropTypes.elementType }), PropTypes.object]),
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func,
  handleGoToSingleHolding: PropTypes.func,
  handleShowFullList: PropTypes.func,
  filters: PropTypes.shape({
    regions: {
      value: PropTypes.arrayOf(PropTypes.number),
      list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.number })),
      set: PropTypes.func,
    },
    sectors: {
      value: PropTypes.arrayOf(PropTypes.number),
      list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.number })),
      set: PropTypes.func,
    },
  }),
}

export { EquitiesList }
