import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

import { type SippTransferRequest } from '../models'

enum SippTransferRequestPartType {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

enum SippTransferRequestAssetType {
  ENCASH = 'ENCASH',
  IN_SPECIE = 'IN_SPECIE',
}

type SippTransferRequestDTO = {
  portfolio: number | null
  provider: number | null
  plan_number: string | null
  part_type: SippTransferRequestPartType | null
  asset_transfer_type: SippTransferRequestAssetType | null
  amount: string | null
}

type SippTransferRequestResponseDTO = unknown

const getCreateSippTransferRequestURL = (): string => 'portfolios/sipp-transfer-request/'
const createSippTransferRequest = async (
  transferRequest: SippTransferRequest,
): Promise<SippTransferRequestResponseDTO | ApiError> => {
  try {
    const { data } = await axios.post(getCreateSippTransferRequestURL(), transferRequest)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
createSippTransferRequest.getUrl = getCreateSippTransferRequestURL

export {
  SippTransferRequestPartType,
  SippTransferRequestAssetType,
  type SippTransferRequestDTO,
  type SippTransferRequestResponseDTO,
  getCreateSippTransferRequestURL,
  createSippTransferRequest,
}
