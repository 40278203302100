import React from 'react'
import { Radio, RadioGroup } from 'react-radio-group'

import { Stack } from 'components/atoms/Stack'

import { RadioWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { TransferPreviousYear } from 'constants/transferIsa'

interface PreviousYearsTransferProps {
  value: TransferPreviousYear
  setValue: (value: TransferPreviousYear) => void
}

const PreviousYearsTransfer: React.FC<PreviousYearsTransferProps> = ({ value, setValue }) => {
  return (
    <RadioGroup
      selectedValue={value}
      onChange={(value: TransferPreviousYear) => {
        setValue(value)
      }}
    >
      <Stack vertical={16}>
        <RadioWithLabel
          CustomInput={Radio}
          label="No, don’t transfer any previous years"
          value={TransferPreviousYear.NO}
          size="smaller"
          name="transferPreviousYears"
          onChange={() => {}}
          testId="transferPreviousYearsNo"
          data-test-id="transferPreviousYearsNo"
          noMarginBottom
        />
        <RadioWithLabel
          CustomInput={Radio}
          label="Yes, transfer all of my previous tax years’ ISA"
          value={TransferPreviousYear.ALL}
          size="smaller"
          name="transferPreviousYears"
          onChange={() => {}}
          testId="transferPreviousYearsAll"
          data-test-id="transferPreviousYearsAll"
          noMarginBottom
        />
        <RadioWithLabel
          CustomInput={Radio}
          label="Yes, transfer part of my previous tax years’ ISA"
          value={TransferPreviousYear.PART}
          size="smaller"
          name="transferPreviousYears"
          onChange={() => {}}
          testId="transferPreviousYearsPart"
          data-test-id="transferPreviousYearsPart"
          noMarginBottom
        />
      </Stack>
    </RadioGroup>
  )
}

export { PreviousYearsTransfer }
