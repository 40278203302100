import APIVersion from '../../../APIVersion.js'

import { requestIDCheck, type IDCheckResult } from './requestIDCheck'

async function getIdentityCheckStatus(contactId: number | string): Promise<IDCheckResult | undefined> {
  const url =
    parseFloat(APIVersion) > 0.15
      ? `id_check/identity/status/${contactId}/`
      : `id-check/gbg-authentication/status/${contactId}/`

  return await requestIDCheck(url)
}

export { getIdentityCheckStatus }
