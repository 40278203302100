import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { formatPercent } from 'helpers/money'
import { format as formatDate } from 'helpers/date.js'
import { transformIncomeTwrData } from 'helpers/charts'

import { useMediaQueries, useState, useEffect, useMemo, useCallback, useRef } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { Chart, ChartTooltip, IncomeLine, NumeralAxis } from 'components/organisms/charts/parts'
import Text from 'components/_old/Text/Text.jsx'
import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { ReturnInfo } from 'components/organisms/ReturnInfo'
import { Paper } from 'components/atoms/Paper'

import { useTouchablePanel } from 'components/organisms/charts/hooks'

import { GraphPlaceholder } from 'app/pages/Dashboard/Goals/components/GraphPlaceholder'

import './IncomeTwrChart.css'

const IncomeTwrChart = ({
  className,
  data: rawData,
  twr,
  empty,
  hoverable = true,
  showLessTicks,
  isPlaceholderDisplayed,
  handleAddFundsClick,
  investmentReturnData,
} = {}) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries
  const { max, min, data } = transformIncomeTwrData(rawData)

  const isOnlyOneValue = data.length === 1 && data[0].value === 0
  const isEmpty = data.length === 0

  const [hoveredIndex, setHoveredIndex] = useState(null)
  const formattedHoveredData = useMemo(() => {
    const hoveredData = data[hoveredIndex]

    if (!hoveredData) {
      return null
    }
    const date = formatDate(hoveredData.date, 'DD MMMM YYYY')

    return (
      <ColumnarLayout mods={{ padding: 'small' }}>
        <Text block small noWrap>
          {date}
        </Text>
        <Text block right small noWrap>
          {formatPercent(hoveredData.value, true)}
        </Text>
      </ColumnarLayout>
    )
  }, [hoveredIndex, data])

  const classes = classNames(className, 'IncomeTwrChart', getMediaQuieryClasses('IncomeTwrChart', mediaQueries))

  useEffect(() => {
    if (!desktop) {
      setHoveredIndex(null)
    }
  }, [desktop, setHoveredIndex])

  const twrRef = useRef(null)

  const handleEnter = useCallback((_event, index) => hoverable && setHoveredIndex(index), [setHoveredIndex, hoverable])
  const handleLeave = useCallback((_event) => hoverable && setHoveredIndex(null), [setHoveredIndex, hoverable])
  const { rootRef } = useTouchablePanel({ handleEnter, handleLeave, containers: [twrRef.current] })

  if (isPlaceholderDisplayed)
    return (
      <div className={classes}>
        <Paper bottom={32}>
          <ReturnInfo
            price={investmentReturnData.return_money}
            percent={investmentReturnData.return_percent || 0}
            tooltipOffset={-30}
            tooltipText="Your total return (income and capital) from this InvestEngine portfolio over the selected time period. The investment return we show is the time-weighted return (TWR). This gives a better picture of performance for a portfolio where you have received income payments, or where you have made more than one contribution or capital withdrawal. The TWR adjusts for these cash flows."
          />
        </Paper>
        <GraphPlaceholder linkText="Add funds" onLinkClick={handleAddFundsClick} />
      </div>
    )

  return (
    <div className={classes} data-test-id="incomeTwrChart">
      <Paper bottom={32}>
        <ReturnInfo
          price={investmentReturnData.return_money}
          percent={investmentReturnData.return_percent || 0}
          tooltipOffset={-30}
          tooltipText="Your total return (income and capital) from this InvestEngine portfolio over the selected time period. The investment return we show is the time-weighted return (TWR). This gives a better picture of performance for a portfolio where you have received income payments, or where you have made more than one contribution or capital withdrawal. The TWR adjusts for these cash flows."
        />
      </Paper>
      <div className="IncomeTwrChart-Chart" ref={rootRef}>
        <Chart size="medium">
          <NumeralAxis
            min={min}
            max={max}
            quantity={isEmpty || isOnlyOneValue ? 1 : 5}
            format={(value) => formatPercent(value, true)}
          />
          {data.length > 0 && (
            <IncomeLine
              ref={twrRef}
              max={max}
              min={min}
              data={data}
              hoverable={hoverable}
              onTickEnter={handleEnter}
              onTickLeave={handleLeave}
              hoveredIndex={hoveredIndex}
              showLessTicks={showLessTicks}
            />
          )}
          {isEmpty && !isOnlyOneValue && <AllCenter className="IncomeHTwrChart-Empty">{empty}</AllCenter>}
          <ChartTooltip visible={Boolean(formattedHoveredData)} pointerTransparent>
            {formattedHoveredData}
          </ChartTooltip>
        </Chart>
      </div>
    </div>
  )
}

IncomeTwrChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      twr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.number,
    }),
  ),
  twr: PropTypes.number,
  empty: PropTypes.node.isRequired,
  hoverable: PropTypes.bool,
  showLessTicks: PropTypes.bool,
  isPlaceholderDisplayed: PropTypes.bool,
  handleAddFundsClick: PropTypes.func,
  investmentReturnData: PropTypes.object,
}

export { IncomeTwrChart }
