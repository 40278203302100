import React, { Fragment } from 'react'

import { useMediaQueries, useRef } from 'hooks'

import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Profit } from 'components/molecules/Profit'

import './ReturnInfo.css'

type ReturnInfoProps = {
  price: number | string
  percent: number
  tooltipText?: string
  title?: string
  tooltipOffset: number
  'data-test-id'?: string
}

const ReturnInfo = ({
  price,
  percent,
  tooltipText,
  tooltipOffset = -350,
  title = 'Investment return',
  'data-test-id': dataTestId = 'investmentReturn',
}: ReturnInfoProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const tooltipRef = useRef()

  price = parseFloat(price as string) || 0

  const openModal = (event): void => {
    tooltipRef.current?.openModal(event)
  }

  const icon = desktop ? (
    <Icon size={24} type="information-24" color="inherit" style={{ position: 'relative', top: '6px' }} />
  ) : (
    <Icon size={16} type="information-16" color="inherit" style={{ position: 'relative', top: '3px' }} />
  )

  const tooltipTextNode = tooltipText ? (
    <Typo>{tooltipText}</Typo>
  ) : (
    <Typo>
      We show your investment return in pounds (£) as well as percentage terms.
      <br />
      <br />
      The pounds’ return number is your profit in cash terms or — if there’s a minus sign before the number — your loss.
      It is the difference between the total amount of money you invested and the current value of your portfolio, plus
      any withdrawals you have already made.
      <br />
      <br />
      This is the calculation: Investment return = Current value − Net сontributions (Incoming - Outgoings funds)
      <br />
      <br />
      The percentage investment return which we show is the time‑weighted return (TWR). This gives a better picture of
      performance for portfolios where you have made more than a single contribution and/or withdrawals. The TWR adjusts
      for these cash flows.
    </Typo>
  )

  const contentNode = (
    <Fragment>
      <Typography size={desktop ? 32 : 20} lineHeight="small" weight="semibold" data-test-id={`${dataTestId}Value`}>
        <Profit amount={price} percent={percent} smallPences />
      </Typography>
      <Paper top={desktop ? 0 : 4}>
        <Typography size={desktop ? 16 : 14} color="additional" lineHeight="small">
          {title}{' '}
          <TooltipToModal
            ref={tooltipRef}
            description={tooltipTextNode}
            customIcon
            offset={tooltipOffset}
            tipClassName="returnInfoTooltip"
            data-test-id={`${dataTestId}Tooltip`}
          >
            <Link mods={{ color: 'minorToRed' }} style={{ display: 'inline-flex' }}>
              {icon}
            </Link>
          </TooltipToModal>
        </Typography>
      </Paper>
    </Fragment>
  )

  if (desktop) {
    return (
      <Card>
        <Paper top={16} bottom={16} right={20} left={20}>
          {contentNode}
        </Paper>
      </Card>
    )
  }

  return (
    <Card>
      <Link onClick={openModal}>
        <Paper top={12} bottom={12} right={16} left={16}>
          {contentNode}
        </Paper>
      </Link>
    </Card>
  )
}

export { ReturnInfo }
