import React from 'react'

import { Signature } from './Signature'
import { Success } from './Success'
import { TransferIsa } from './TransferIsa'

const childRoutes = [
  {
    path: '',
    index: true,
    isNewLayout: true,
    component: function Component(props) {
      return <TransferIsa {...props} />
    },
  },
  {
    module: 'success',
    path: 'success/',
    isNewLayout: true,
    component: function Component(props) {
      return <Success {...props} />
    },
  },
  {
    module: 'signature',
    path: 'signature/',
    isNewLayout: true,
    component: function Component(props) {
      return <Signature {...props} />
    },
  },
]

export default {
  module: 'transfer-isa-altus',
  path: 'transfer-isa/',
  name: 'transfer-isa',
  childRoutes,
  indexRoute: childRoutes.find((route) => route.index),
}
