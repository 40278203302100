import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useState, useRef, useMemo } from 'hooks'

import { useEditWeight } from 'app/pages/Dashboard/Goals/DIY/EditWeights/hooks'

import { GatewayDest } from 'components/atoms/Gateway'
import { Preloader } from 'components/molecules/Preloader'

import { Desktop } from 'app/pages/Dashboard/Goals/DIY/EditWeights/Desktop'
import { Mobile } from 'app/pages/Dashboard/Goals/DIY/EditWeights/Mobile'
import { Abandon } from 'app/pages/Dashboard/Goals/DIY/EditWeights/Abandon'
import { Restrictions } from 'app/pages/Dashboard/Goals/DIY/Restrictions'
import { ReviewAnalyticsModal } from './ReviewAnalyticsModal/ReviewAnalyticsModal.tsx'
import { RegulatoryTypeModal } from './RegulatoryTypeModal'
import { HoldingModal } from './HoldingModal'
import { EditWeightsHint } from './components/EditWeightsHint'
import { OpenSippModal } from 'app/pages/Dashboard/Accounts/components/OpenSippModal'

const EditWeights = ({
  params: { id: portfolioId } = {},
  location: { query: { reviewModalOpened, requestRegulatoryType } = {}, query = {} } = {},
}) => {
  const { desktop } = useMediaQueries()

  const {
    isLoading,

    isPortfolioNew,
    infoText,
    securities,
    total,
    control,
    currentValues,

    abandonModalOpen,
    handleAbandonModalClose,
    handleContinueOnAbandonModal,

    restrictionModalOpen,
    restrictionModalType,
    handleRestrictionModalClose,

    handleAddSecurities,
    handleCancel,
    handleBack,
    handleDelete,
    handleSubmit,

    analytics,
    openReviewAnalyticsModal,
    closeReviewAnalyticsModal,
    fetchAnalyticsByWeights,

    isHoldingModalOpened,
    selectedPortfolio,
    holdingData,
    holdingDistribution,
    openHoldingModal,
    closeHoldingModal,
  } = useEditWeight(portfolioId, query)

  const highlightedRef = useRef(null)

  const props = {
    infoText,
    securities,
    total,
    control,
    currentValues,
    handleAddSecurities,
    handleCancel,
    handleBack,
    handleDelete,
    analytics,
    isPortfolioNew,
    openHoldingModal,
    highlightedRef,
  }

  const holdingModalProps = {
    isPortfolioNew,
    selectedPortfolio,
    holdingData,
    holdingDistribution,
    closeHoldingModal,
  }
  const [showRegulatoryTypeModal, setShowRegulatoryTypeModal] = useState(false)
  const openRegulatoryTypeModal = () => setShowRegulatoryTypeModal(true)

  const handleReviewClick = requestRegulatoryType ? openRegulatoryTypeModal : handleSubmit

  const content = useMemo(() => {
    if (!selectedPortfolio) {
      return null
    }

    return desktop ? <Desktop {...props} /> : <Mobile {...props} />
  }, [selectedPortfolio, desktop, props])

  return (
    <Fragment>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          reviewModalOpened === 'true' ? handleReviewClick() : openReviewAnalyticsModal()
        }}
      >
        {content}
        <ReviewAnalyticsModal
          {...props}
          open={reviewModalOpened === 'true'}
          handleBack={closeReviewAnalyticsModal}
          handleCancel={handleCancel}
          fetchAnalyticsByWeights={fetchAnalyticsByWeights}
        />
      </form>
      <HoldingModal open={isHoldingModalOpened} {...holdingModalProps} />
      <Abandon open={abandonModalOpen} onClose={handleAbandonModalClose} onContinue={handleContinueOnAbandonModal} />
      {restrictionModalType && (
        <Restrictions
          portfolioId={portfolioId}
          open={restrictionModalOpen}
          onClose={handleRestrictionModalClose}
          type={restrictionModalType}
        />
      )}
      {requestRegulatoryType && (
        <RegulatoryTypeModal
          open={showRegulatoryTypeModal}
          onSubmit={handleSubmit}
          onClose={() => setShowRegulatoryTypeModal(false)}
        />
      )}
      <OpenSippModal />

      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
      {!reviewModalOpened && <EditWeightsHint highlightedRef={highlightedRef} />}
      <Preloader loading={isLoading} size="big" zIndex />
    </Fragment>
  )
}

EditWeights.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      mode: PropTypes.string,
    }),
  }).isRequired,
}

export { EditWeights }
