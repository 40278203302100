import { type PointOfInterestCardPositions } from '../Card'

enum SecuritySlicePointOfInterestTypes {
  BUY = 'BUY',
  SELL = 'SELL',
  COMBINED = 'COMBINED',
}

type BuySellSecuritySlicePointOfInterest = {
  type: Omit<SecuritySlicePointOfInterestTypes, SecuritySlicePointOfInterestTypes.COMBINED>
  date: Date
  position?: PointOfInterestCardPositions
  center?: number
  data: {
    price: string
    quantity: string
    value: string
  }
}

type CombinedSecuritySlicePointOfInterest = {
  type: SecuritySlicePointOfInterestTypes.COMBINED
  date: Date
  dateFrom: Date
  dateTo: Date
  data: Array<Omit<BuySellSecuritySlicePointOfInterest, 'date' | 'position' | 'center'>>
}

type SecuritySlicePointOfInterest = BuySellSecuritySlicePointOfInterest & CombinedSecuritySlicePointOfInterest

export { SecuritySlicePointOfInterestTypes }

export * from './BuySellContent'
export * from './CombinedContent'
export * from './Card'

export type { BuySellSecuritySlicePointOfInterest, CombinedSecuritySlicePointOfInterest, SecuritySlicePointOfInterest }
