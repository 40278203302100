import { type MutableRefObject } from 'react'

import { useMediaQueries, useState, useEffect, useRef, useMemo } from 'hooks'

import moment from 'helpers/date.js'
import { urlTo } from 'helpers/router'

import { useTouchablePanel } from 'components/organisms/charts/hooks'
import {
  type AbstractPointOfInterest,
  PointOfInterestCardPositions,
} from 'components/organisms/charts/parts/PointOfInterest/components/Card/Card'
import { usePointsOfInterest } from 'components/organisms/PriceChart/hooks/usePointsOfInterest'

import { TransactionTypeFilterValue } from 'app/pages/Dashboard/Transactions/hooks/useFilters'

import { type DividendsChartData, type Periods } from '../types'

import { filterDividendsDataByPeriod } from './utils/filterDividendsDataByPeriod'
import { transformDividendsData } from './utils/transformDividendsData'

type useDividendsChartArguments = {
  rawData: DividendsChartData
  period: Periods
  securities: Array<{ dividends_type: 'ACCUMULATING' | 'DISTRIBUTING' | null; [key: string]: any }>
  portfolioId: string
  securityId?: string
}

type useDividendsChartReturnType = {
  isEmpty: boolean
  max: number
  data: Array<{ date: Date; value: number; type: string }>
  withFloat: boolean
  hoveredIndex?: number
  rootRef: MutableRefObject<Element | null>
  barsRef: MutableRefObject<HTMLDivElement | null>
  shouldShowNoYieldPlaceholder: boolean
  hoveredData?: { date: Date; value: number }
  paidOutSum: number
  selectedIndex?: number
  chartColumnClassName: string
  tooltipData: {
    pointOfInterestCenter: number
    activePointOfInterest: AbstractPointOfInterest | null
    pointOfInterestPosition: PointOfInterestCardPositions | null
  }
  linkToTransactions: string | null
  handleColumnEnter: (event: React.MouseEvent, index: number) => void
  handleColumnLeave: (event: React.MouseEvent, index: number) => void
  handleColumnClick: (event: React.MouseEvent, index: number) => void
}

const useDividendsChart = ({
  rawData,
  period,
  securities,
  portfolioId,
  securityId,
}: useDividendsChartArguments): useDividendsChartReturnType => {
  const { desktop } = useMediaQueries()

  const chartColumnClassName = 'DividendsChart-Column'

  const hasDividendsSecurities = useMemo(
    () => securities.some(({ dividends_type: dividendsType }) => dividendsType === 'DISTRIBUTING'),
    [securities],
  )

  const { max, rawMax, data, paidOutSum, withFloat } = transformDividendsData(
    filterDividendsDataByPeriod(rawData, period),
  )

  const isEmpty = data.length === 0 || (data.length === 1 && data[0].value === 0) || rawMax === 0

  const [hoveredIndex, setHoveredIndex] = useState()
  const [pointOfInterestIndex, setPointOfInterestIndex] = useState()
  const barsRef = useRef(null)

  const hoveredData = ((hoveredIndex === 0 || hoveredIndex) && data[hoveredIndex]) ?? undefined

  const shouldShowNoYieldPlaceholder = !hasDividendsSecurities && !paidOutSum && !securityId

  const { activePointOfInterest, pointOfInterestCenter, pointOfInterestPosition, selectPointOfInterest } =
    usePointsOfInterest(data)

  const selectedIndex = activePointOfInterest ? pointOfInterestIndex : hoveredData?.value ? hoveredIndex : undefined

  const setPointOfInterest = (index, hard = false): void => {
    if (!data[index].value) return

    const position = data.length / 2 > index ? PointOfInterestCardPositions.RIGHT : PointOfInterestCardPositions.LEFT
    const targetColumn = barsRef.current?.getElementsByClassName(chartColumnClassName).item(index)
    const center = (targetColumn?.offsetLeft as number) + (targetColumn?.clientWidth as number) / 2
    selectPointOfInterest(data[index], center, position, hard)
    setPointOfInterestIndex(index)
  }

  const handleColumnEnter = (event, index): void => {
    setHoveredIndex(index)
    if (activePointOfInterest?.hard) return
    setPointOfInterest(index)
  }

  const handleColumnLeave = (event, index): void => {
    if (!index && index !== 0) return
    setHoveredIndex(undefined)
    if (!data[index]?.value) return
    if (!activePointOfInterest?.hard) {
      selectPointOfInterest(null, undefined, undefined)
    }
  }
  const handleColumnClick = (event, index): void => {
    setPointOfInterest(index, true)
  }

  const { rootRef } = useTouchablePanel({
    handleEnter: handleColumnClick,
    handleLeave: handleColumnLeave,
    containers: [barsRef.current],
    enterOnTouchStart: false,
    enterOnLongTouch: true,
    leaveOnTouchEnd: true,
  })

  const linkToTransactions = useMemo(() => {
    const queryParams = {
      portfolio_id: portfolioId.toString(),
      types: TransactionTypeFilterValue.INCOME,
      date: 'custom',
      date_from: moment(activePointOfInterest?.date).startOf('month').format('YYYY-MM-DD'),
      date_to: moment(activePointOfInterest?.date).endOf('month').format('YYYY-MM-DD'),
      security_id: securityId,
    }

    if (!securityId) delete queryParams.security_id

    return urlTo('transactions', null, queryParams)
  }, [activePointOfInterest, portfolioId, securityId])

  useEffect(() => {
    if (!desktop) {
      setHoveredIndex(undefined)
    }
  }, [desktop, setHoveredIndex])

  return {
    isEmpty,
    max,
    data,
    withFloat,
    hoveredData,
    shouldShowNoYieldPlaceholder,
    paidOutSum,
    rootRef,
    barsRef,
    selectedIndex,
    chartColumnClassName,
    tooltipData: {
      pointOfInterestCenter,
      activePointOfInterest,
      pointOfInterestPosition,
    },
    linkToTransactions,
    handleColumnEnter,
    handleColumnLeave,
    handleColumnClick,
  }
}

export { useDividendsChart }
