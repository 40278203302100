import React from 'react'

import { useMemo } from 'hooks'

import { features } from 'helpers/features'

import { Typo } from 'components/_old/Typo/Typo'

import { Badge } from 'components/atoms/Badge'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { SippTooltip } from 'app/pages/Dashboard/Accounts/components/SippTooltip'

import { regulatoryTypes } from 'constants/portfolio'

type RegulatoryTypeCardProps = {
  regulatoryType: string
  title: string
  description: string
  'data-test-id'?: string
  onClick: () => Promise<void>
}

const RegulatoryTypeCard = ({
  regulatoryType,
  title,
  description,
  onClick,
  'data-test-id': dataTestId,
}: RegulatoryTypeCardProps): React.ReactElement => {
  const isZeroFeesEnabled = features?.get('zero-sipp-fees')

  const regulatoryTypeBadge = useMemo(() => {
    if (regulatoryType === regulatoryTypes.ISA) {
      return (
        <Typography size={14}>
          <Badge theme="rounded-warning" text="tax-free" />
        </Typography>
      )
    }

    if (regulatoryType === regulatoryTypes.SIPP && !isZeroFeesEnabled) {
      return <SippTooltip gatewayName="insideNewModal" />
    }

    return null
  }, [regulatoryType, isZeroFeesEnabled])

  const titleNode =
    regulatoryType === regulatoryTypes.ISA ? (
      <ColumnarLayout>
        <Column>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Typo>{title}</Typo>
          </Typography>
        </Column>
        <Column size={0}>{regulatoryTypeBadge}</Column>
      </ColumnarLayout>
    ) : (
      <Typography size={24} lineHeight="small" weight="semibold">
        <Typo>{title}</Typo>
        {regulatoryType === regulatoryTypes.SIPP && !isZeroFeesEnabled && (
          <Paper inline left={8}>
            <SippTooltip iconSize={24} gatewayName="insideNewModal" />
          </Paper>
        )}
      </Typography>
    )

  return (
    <SelectableCard onClick={onClick} data-test-id={dataTestId}>
      <Paper top={32} bottom={32} right={32} left={32}>
        {titleNode}
        <Paper top={8}>
          <Typography size={16} color="additional">
            <Typo>{description}</Typo>
          </Typography>
        </Paper>
      </Paper>
    </SelectableCard>
  )
}

export { RegulatoryTypeCard }
