/* eslint-disable no-console, @typescript-eslint/no-dynamic-delete */
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import cloneDeep from 'lodash/cloneDeep'

import {
  ValidationError,
  isAjaxError,
  isAuthorizationError,
  isOutdatedVersionError,
  isNetworkError,
} from 'helpers/errors'
import { isJest } from 'helpers/isJest.js'
import localstore from 'helpers/localstore.js'

let store

const isDev = process?.env?.NODE_ENV === 'development'

export const getPartialState = () => {
  const state = cloneDeep(store.getState())

  try {
    const clientSensetiveFields = [
      'password',
      'account_number',
      'bank_title',
      'email',
      'gocardless_account_id',
      'gocardless_id',
      'sort_code',
      'user_apns_devices',
      'user_gcm_devices',
      'access_token',
      'refresh_token',
      'addresses',
    ]
    const uiFieldsSensetive = ['new_password1', 'new_password2', 'pin']
    const uiChangePasswordSensetiveFields = ['old_password', 'new_password1', 'new_password2']

    clientSensetiveFields.forEach((clientField) => delete state.client[clientField])

    if (state.ui.fields) {
      uiFieldsSensetive.forEach((uiField) => delete state.ui.fields[uiField])
    }

    if (state.ui.modals?.changePassword) {
      uiChangePasswordSensetiveFields.forEach((uiModalsField) => delete state.ui.modals.changePassword[uiModalsField])
    }

    delete state.securities
    delete state.ui
    delete state.analytics?.data

    return state
  } catch (error) {
    return error.message
  }
}

const cleanSensitiveDataInEvent = (event) => {
  const accessToken = localstore.get('client') ? localstore.get('client').access_token : null

  if (accessToken) {
    const process = function (data) {
      try {
        const stringedData = JSON.stringify(data)
        const accessTokenRegExp = new RegExp(accessToken, 'g')
        const cleanStringedData = stringedData.replace(accessTokenRegExp, '[key]')

        return JSON.parse(cleanStringedData)
      } catch (e) {
        return data
      }
    }

    event.breadcrumbs = process(event.breadcrumbs)
    event.extra = process(event.extra)
    event.exception = process(event.exception)
  }
}

const byteSize = (string) => new Blob([string]).size
const ONE_MB = 1000000

export const configSentry = (reduxStore) => {
  const env = window.location.href.includes('investengine.com') ? 'production' : 'staging'
  const ignoreErrors = [
    /Request failed with status code 401/,
    /Unexpected token 'const'/,
    /Unexpected keyword 'const'. Const declarations are not supported in strict mode./,
    /SecurityError: Blocked a frame with origin "https:\/\/investengine.com" from accessing a cross-origin frame. Protocols, domains, and ports must match./,
    /Non-Error promise rejection captured/,
    // Exclude errors from polling when navigating away from a page
    /TypeError: Failed to fetch/,
    /Unable to find node on an unmounted component./,
    /Minified Redux error #3/,
    /Minified Redux error #7/,
  ]
  const allowUrls = [/https?:\/\/(www\.)?investengine\.com/, /https?:\/\/((www|\d)\.)?rscwealth\.com/]
  const beforeSend = (event, hint) => {
    if (!event.alreadyConsoleLog) {
      // eslint-disable-next-line no-console
      console.error(hint.originalException || hint.syntheticException)
    }

    // try to fit event in 1mb
    if (byteSize(JSON.stringify(event)) > ONE_MB) {
      event.breadcrumbs = event.breadcrumbs.slice(-50)
    }

    if (byteSize(JSON.stringify(event)) > ONE_MB) {
      event.breadcrumbs = event.breadcrumbs.slice(-25)
    }

    if (byteSize(JSON.stringify(event)) > ONE_MB) {
      delete event.extra.partialState
    }

    if (byteSize(JSON.stringify(event)) > ONE_MB) {
      event.breadcrumbs = event.breadcrumbs.slice(-10)
    }

    return event
  }

  Sentry.init({
    dsn:
      env === 'production'
        ? 'https://7b92da9d1bbd4a07b6ed0c09f6629f11@sentry.io/195441'
        : 'https://e1bd6e8e4d9d481396123ba7386ea4e8@sentry.io/195441',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: env === 'production' ? 0.1 : 0,
    environment: env,
    ignoreErrors,
    allowUrls,
    normalizeDepth: 8,
    beforeSend,
  })

  store = reduxStore

  if (isDev) {
    console.warn('@errorLogging | sentry would not run at dev environment')
  }

  Sentry.configureScope((scope) => {
    scope.addEventProcessor(
      async (event, hint) =>
        await new Promise((resolve) => {
          const error = hint.originalException || hint.syntheticException
          console.error(error)

          event.alreadyConsoleLog = true

          if (isDev) {
            event.environment = 'localhost'
            // comment it for enable sentry log in localhost
            return null
          }

          const partialState = getPartialState()

          event.extra = {
            ...event.extra,
            partialState,
          }

          cleanSensitiveDataInEvent(event)

          resolve(event)
        }),
    )
  })
}

export const sendAjaxError = (ajaxError) => {
  if (isAuthorizationError(ajaxError)) {
    return
  }

  if (isOutdatedVersionError(ajaxError)) {
    return
  }

  if (isNetworkError(ajaxError)) {
    return
  }

  if (ajaxError.config?.validErros?.includes(ajaxError?.response?.data?.non_field_errors?.[0]?.code)) {
    return
  }

  if (isDev && !isJest) {
    console.warn('@errorLogging | send ajax error', ajaxError)
  }

  Sentry.withScope((scope) => {
    const requestData = {
      config: ajaxError.config,
      request: ajaxError.request,
      response: ajaxError.response,
    }

    scope.setExtras({ requestData })

    Sentry.captureException(ajaxError)
  })
}

export const sendError = (error, additionalData = null) => {
  const shouldNotSendToSentry = error?.config?.shouldNotSend404 && error?.response?.status === 404

  if (shouldNotSendToSentry) return

  if (isAjaxError(error)) {
    sendAjaxError(error)
    return
  }

  if (isDev && !isJest) {
    console.warn('@errorLogging | send error', error, additionalData)
  }

  Sentry.withScope((scope) => {
    if (additionalData) {
      scope.setExtras(additionalData)
    }
    if (error instanceof ValidationError) {
      scope.setExtras({ responseData: error.data })
    }
    Sentry.captureException(error)
  })
}

const errorsThrown = []
export const sendErrorOnce = (error) => {
  if (!errorsThrown.includes(error.message)) {
    errorsThrown.push(error.message)

    sendError(error)
  }
}
