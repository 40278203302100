import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe.js'

import { useCallback, useCopyToClipboard } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { VisuallyHidden } from 'components/atoms/VisuallyHidden'

import './CopyToClipboard.css'

const CopyToClipboard = ({
  children = 'Copy to the clipboard',
  value,
  absolute = false,
  toast,
  showToast,
  iconSize = 24,
}) => {
  const classes = classNames('CopyToClipboard', { CopyToClipboard_absolute: absolute })

  const handleCopy = useCopyToClipboard(value)

  const handleToast = useCallback(() => {
    if (typeof showToast === 'function' && toast) {
      showToast(toast)
    }
  }, [showToast, toast])

  const handleClick = useCallback(() => {
    handleCopy()
    handleToast()
  }, [handleCopy, handleToast])

  return (
    <Link className={classes} role="button" mods={{ color: 'black' }} onClick={handleClick}>
      <Icon type="copy-24" size={iconSize} color="inherit" />
      <VisuallyHidden>{children}</VisuallyHidden>
    </Link>
  )
}

CopyToClipboard.propTypes = {
  children: PropTypes.string,
  value: PropTypes.string,
  absolute: PropTypes.bool,
  toast: PropTypes.string,
  showToast: PropTypes.func,
  iconSize: PropTypes.number,
}

export { CopyToClipboard }
