import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useEffect, useMediaQueries } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'

import { $isNominationAvailable, $daysFromNomination, nominateBankAccountFx } from 'app/effector/bank-accounts'

import { showFailToast } from 'app/redux/actions/ui'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

const attachedNominateBankAccountFx = attach({ effect: nominateBankAccountFx })

attachedNominateBankAccountFx.fail.watch(({ error }) => {
  showFailToast(error?.response?.data?.errors?.[0]?.message ?? 'Something went wrong')
})

type ChangeBankModalProps = {
  location: {
    query: {
      changeNominatedBankAccount: string
      bankAccountId: string
    }
  }
  handleClose: () => void
}

const ChangeBankModal = withRouter(({ location }: ChangeBankModalProps) => {
  const { desktop } = useMediaQueries()

  const isOpen = location.query?.changeNominatedBankAccount === 'true'

  const [isNominationAvailable, daysFromNomination] = useUnit([$isNominationAvailable, $daysFromNomination])

  const handleClose = (): void => {
    goTo(urlTo('dashboard.user-profile.savings-plans', {}, {}))
  }

  useEffect(() => {
    const unwatch = attachedNominateBankAccountFx.done.watch(() => {
      handleClose()
    })

    return () => {
      unwatch()
    }
  }, [])

  const handleChangeBankAccount = (): void => {
    attachedNominateBankAccountFx(location.query?.bankAccountId)
  }

  const content = (
    <Fragment>
      <Typography size={32} lineHeight="small" weight="semibold" align="center">
        <Typo>
          {isNominationAvailable
            ? 'Confirm the change of nominated account'
            : 'Sorry, you can’t change nominated bank account yet'}
        </Typo>
      </Typography>
      <Paper top={40}>
        <Typography size={14} align="center">
          {isNominationAvailable ? (
            <Typo>
              Once the nominated bank account is changed you will not be able to make another change for 30 days.
              <br />
              <br />
              Please confirm that you are nominating the correct bank account.
            </Typo>
          ) : (
            <Typo>
              It's been {daysFromNomination} {daysFromNomination === 1 ? 'day' : 'days'} since the last update to your
              bank account (instead of the usual 30). If you'd like to update it now, please reach out to our customer
              support team and let them know the reason
            </Typo>
          )}
        </Typography>
      </Paper>
    </Fragment>
  )

  const button = isNominationAvailable ? (
    <Button onClick={handleChangeBankAccount} mods={{ size: 'big block' }}>
      Confirm
    </Button>
  ) : (
    <Button onClick={handleClose} mods={{ theme: 'simple-reverse-blue', size: 'big block' }}>
      Close
    </Button>
  )

  if (desktop) {
    return (
      <Modal open={isOpen} close={null} onClose={handleClose}>
        <Width size={36}>
          <Paper top={8} right={24} left={24} bottom={64}>
            <NavigationBar rightPartText="Close" onRightPartClick={isNominationAvailable ? handleClose : null}>
              <Typo>&nbsp</Typo>
            </NavigationBar>
            <Paper top={32} right={48} left={48}>
              {content}
              <Paper top={48}>{button}</Paper>
            </Paper>
          </Paper>
        </Width>
      </Modal>
    )
  }

  return (
    <Modal open={isOpen} close={null}>
      <MobileLayout
        header={<NavigationBar onRightPartClick={handleClose} rightPartText="Close" />}
        content={content}
        footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
})

export { ChangeBankModal }
