import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useRef, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import moment from 'helpers/date.js'
import { format as formatMoney } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import type { BankAccount } from 'app/effector/bank-accounts/models/bank'
import { $transferProgressCollection } from 'app/effector/isa-transfer'
import { $quickStartsStore } from 'app/effector/quickStart'
import { fetchRecurringPaymentsFx } from 'app/effector/recurringPayments'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'
import { setupRecurringPayment } from 'app/redux/api/portfolio'
import { ApiError } from 'app/redux/models/errors'
import type { Portfolio } from 'app/redux/models/portfolio/types'

import { frequencies, RECURRING_PAYMENT_WEEKLY_MIN_AMOUNT, RECURRING_PAYMENT_MOTHLY_MIN_AMOUNT } from '../../constants'

import type { Frequency } from '../../types'

import { bankAccountStates } from 'constants/bankAccounts'
import { quickStartStatuses } from 'constants/quickStart'

const fetchRecurringPaymentsOnRecurringPaymentSetupFx = attach({ effect: fetchRecurringPaymentsFx })

type UseRecurringPaymentProps = {
  portfolio: Portfolio
  location: {
    query?: {
      amount?: string
    }
  }
}

type UseRecurringPaymentReturnProps = {
  bankErrorRef: React.RefObject<HTMLDivElement>
  isLoading: boolean
  isBankErrorVisible: boolean
  amount: string | null
  frequency: Frequency | null
  startDay: string | null
  monthlyPaymentDay: number | null
  isChecked: boolean
  validation: {
    frequency: {
      rules: boolean[]
      errors: string[]
    }
    startDay?: {
      rules: boolean[]
      errors: string[]
    }
    monthlyPaymentDay?: {
      rules: boolean[]
      errors: string[]
    }
    amount: {
      rules: boolean[]
      errors: string[]
    }
  }
  nominatedBankAccount: BankAccount
  handleSelectFrequency: (event, value: Frequency) => void
  handleSelectStartDay: (event, value: string) => void
  handleInputMonthlyPaymentDay: (event, value: string) => void
  handleInputAmount: (event, value: string) => void
  toggleCheckbox: () => void
  handleContinue: () => Promise<void>
  handleBack: () => void
}

const useRecurringPaymentSetup = ({
  portfolio,
  location,
}: UseRecurringPaymentProps): UseRecurringPaymentReturnProps => {
  // We use state here, not useLoading hook, to keep loader till redirect to trulayer
  const [isLoading, setIsLoading] = useState(false)
  const [isBankErrorVisible, setIsBankErrorVisible] = useState(false)

  const { nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)
  const { getQuickStartByPortfolioId } = useUnit($quickStartsStore)
  const quickStart = getQuickStartByPortfolioId(portfolio.id)
  const isQuickStartInProgress = quickStart?.status === quickStartStatuses.ACTIVE

  const initialAmount = isQuickStartInProgress ? quickStart?.amount : location?.query?.amount ?? null
  const initialFrequency = isQuickStartInProgress ? quickStart?.frequency : null
  const initialStartDay = isQuickStartInProgress ? quickStart?.start_day_week : null
  const initialMonthlyPaymentDay = isQuickStartInProgress ? quickStart?.day_of_month : null

  const store = useUnit($transferProgressCollection)
  const portfolioProgressData = store.get(portfolio.id)
  const hasIsaTransferInProgress = portfolioProgressData?.length > 0

  const [amount, setAmount] = useState<string | null>(initialAmount)
  const [frequency, setFrequency] = useState<Frequency | null>(initialFrequency)
  const [startDay, setStartDay] = useState<string | null>(initialStartDay)
  const [monthlyPaymentDay, setMonthlyPaymentDay] = useState<number | null>(initialMonthlyPaymentDay)
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])

  const bankErrorRef = useRef()

  useEffect(() => {
    if (isBankErrorVisible) {
      bankErrorRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [isBankErrorVisible])

  const handleInputAmount = useCallback((event, value: string) => {
    setAmount(value)
  }, [])

  const handleSelectFrequency = useCallback((event, value: Frequency) => {
    setFrequency(value)
  }, [])

  const handleSelectStartDay = useCallback((event, value: string) => {
    setStartDay(value)
  }, [])

  const handleInputMonthlyPaymentDay = useCallback((event, value: string) => {
    setMonthlyPaymentDay(parseFloat(value))
  }, [])

  const toggleCheckbox = useCallback(() => {
    setIsChecked(!isChecked)
  }, [isChecked, setIsChecked])

  const handleContinue = useCallback(async () => {
    trackEvent({
      action: 'sp_setup_form_submitted',
      manage_type: portfolio?.manage_type,
      ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
      payment_method: 'OPEN_BANKING',
      portfolio_created_date: moment(portfolio?.created).format('YYYY-MM-DD'),
    })

    if (nominatedBankAccount?.state === bankAccountStates.AWAITING_APPROVAL) {
      setIsBankErrorVisible(true)
      return
    }

    const returnUri = `${window.location.origin}${
      urlTo('dashboard.portfolio.options.recurring-payment-processing', {
        id: portfolio.id,
      }) as string
    }`

    setIsLoading(true)

    const data = await setupRecurringPayment({
      portfolio_id: portfolio.id,
      amount,
      frequency,
      start_day_week: startDay,
      day_of_month: monthlyPaymentDay,
      return_uri: returnUri,
      enable_autoinvest: !hasIsaTransferInProgress,
    })

    if (data instanceof ApiError || data.status === 'failed') {
      goTo(
        urlTo('dashboard.portfolio.options.recurring-payment-processing', { id: portfolio.id }, { status: 'failed' }),
      )
      return
    }

    if (data.status === 'need_authorise') {
      window.location = data.authorise_url
      return
    }

    if (data.status === 'successful') {
      fetchPortfolios()

      trackEvent({
        action: 'sp_setup_success_popup',
        manage_type: portfolio?.manage_type,
        ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
        payment_method: 'OPEN_BANKING',
        portfolio_created_date: moment(portfolio?.created).format('YYYY-MM-DD'),
      })

      fetchRecurringPaymentsOnRecurringPaymentSetupFx()

      goTo(
        urlTo(
          'dashboard.portfolio.options.recurring-payment-processing',
          { id: portfolio.id },
          { status: 'successful' },
        ),
      )
      return
    }

    setIsLoading(false)
  }, [
    portfolio,
    amount,
    frequency,
    startDay,
    monthlyPaymentDay,
    nominatedBankAccount,
    fetchPortfolios,
    hasIsaTransferInProgress,
  ])

  const handleBack = useCallback(() => {
    goTo(urlTo('dashboard.portfolio.options.recurring-payment', { id: portfolio.id }, location?.query ?? {}))
  }, [portfolio, location])

  const minAmount =
    frequency === frequencies.MONTHLY ? RECURRING_PAYMENT_MOTHLY_MIN_AMOUNT : RECURRING_PAYMENT_WEEKLY_MIN_AMOUNT

  const commonValidation = {
    frequency: {
      rules: [!!frequency],
      errors: ['Frequency must be selected'],
    },
    amount: {
      rules: [parseFloat(amount ?? '0') >= minAmount],
      errors: [`Must be at least ${formatMoney(minAmount)}`],
    },
    terms: { rules: [isChecked], errors: [''] },
  }

  const validation =
    frequency === frequencies.MONTHLY
      ? {
          ...commonValidation,
          monthlyPaymentDay: {
            rules: [!!monthlyPaymentDay && monthlyPaymentDay >= 1 && monthlyPaymentDay <= 28],
            errors: ['Must be 1 to 28'],
          },
        }
      : {
          ...commonValidation,
          startDay: {
            rules: [!!startDay],
            errors: ['Start day must be selected'],
          },
        }

  return {
    bankErrorRef,
    isLoading,
    isBankErrorVisible,
    amount,
    frequency,
    startDay,
    isChecked,
    validation,
    monthlyPaymentDay,
    nominatedBankAccount,
    handleSelectFrequency,
    handleSelectStartDay,
    handleInputMonthlyPaymentDay,
    handleInputAmount,
    toggleCheckbox,
    handleContinue,
    handleBack,
  }
}

export { useRecurringPaymentSetup }
