import { combine, createStore, createEffect } from 'effector'

import * as api from './api'

import type { AllowancesStore } from './types'

// Effects
const fetchAllowancesFx = createEffect(async () => {
  try {
    return await api.getAllowances()
  } catch (error) {}
})

// Stores
const $allowances = createStore<AllowancesStore>({ isa: null, sipp: null })

$allowances.on(fetchAllowancesFx.doneData, (state: AllowancesStore, allowances: AllowancesStore) => {
  if (!allowances) return state

  return allowances
})

const $areAllowancesFetched = createStore<boolean>(false)

$areAllowancesFetched.on(fetchAllowancesFx.done, () => true)

const $allowancesStore = combine({
  allowances: $allowances,
  isLoading: fetchAllowancesFx.pending,
  areAllowancesFetched: $areAllowancesFetched,
})

export { $allowancesStore, fetchAllowancesFx }
