import React from 'react'

import type { Preset } from 'app/redux/models/portfolio/types'

import './PresetBanner.css'

type PresetBannerProps = {
  preset: Preset
  paddingRight?: number
}

const PresetBanner = ({ preset, paddingRight = 23.77 }: PresetBannerProps): React.ReactElement => {
  return (
    <div className="PresetBannerContainer" style={{ paddingRight }}>
      <img src={preset?.banner} className="PresetBannerImage" />
    </div>
  )
}

export { PresetBanner }
