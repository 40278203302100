import React, { Fragment } from 'react'

import {
  useAsyncEffect,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useSelector,
  useActions,
  useMediaQueries,
  useRedirect,
  useLoading,
} from 'hooks'

import { setLogoutProcessingFinish } from 'helpers/ajax/middlewares'
import { trackEvent } from 'helpers/analytics'
import { sendError } from 'helpers/errorLogging.js'
import { features } from 'helpers/features'
import { urlTo, goTo } from 'helpers/router.js'
import {
  combineErrors,
  backendErrorsToObj,
  bulkValidate,
  emailRegex,
  emailRegexErrorMessage,
} from 'helpers/validation.js'

import {
  changeField as changeFieldActionCreator,
  login as loginActionCreator,
  resetError as resetErrorCreator,
  setValid as setValidActionCreator,
  logout as logoutActionCreator,
} from 'app/redux/actions/client'
import {
  changeNotificationSettings as changeNotificationSettingsActionCreator,
  showFailToast,
} from 'app/redux/actions/ui'
import { authorizeWithDiscourse } from 'app/redux/api/discourse'
import { ApiError } from 'app/redux/models/errors'

import Button from 'components/_old/Button/Button.jsx'
import { Fieldset } from 'components/_old/Form/Form.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

import './Login.css'

export const CLIENT_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  SME: 'SME',
}

function getLoginIdentifierRules(loginIdentifier: string): { rules: boolean[]; errors: string[] } {
  const rulesObject = {
    rules: [Boolean(loginIdentifier)],
    errors: ['Email can’t be empty'],
  }

  rulesObject.rules.push(emailRegex.test(loginIdentifier))
  rulesObject.errors.push(emailRegexErrorMessage)

  return rulesObject
}

type LoginClientAlike = {
  id: number
  client_id: string
  access_token?: string
  scope?: string
  didInvalidate: boolean
  error?: Error
}

type LoginValidation = {
  loginIdentifier: { rules: boolean[]; errors: string[] }
  loginPassword: { rules: boolean[]; errors: string[] }
}

type LoginProps = {
  location: {
    query: {
      sessionExpired?: string
      sso?: string
      sig?: string
      next?: string
    }
  }
}

function Login({ location: { query } }: LoginProps): React.ReactElement {
  const { desktop } = useMediaQueries()
  const { sessionExpired, sso, sig } = query
  const isDiscourse = sso && sig

  const client = useSelector((state: { client: LoginClientAlike }) => state.client)
  const { isLoading, wait } = useLoading(false)

  useRedirect({
    to: () => {
      goTo(query.next ? query.next : urlTo('dashboard', {}, query))
    },
    rule: client?.id && !isDiscourse,
    isLoading: client.didInvalidate,
  })

  const [loginType, setLoginType] = useState(CLIENT_TYPE.INDIVIDUAL)
  const [loginIdentifier, setLoginIdentifier] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [isAgreeWithNotifications, setIsAgreeWithNotifications] = useState(false)
  const [shouldForceValidPassword, setShouldForceValidPassword] = useState(false)
  const [changeField, login, resetError, setValid, logout, changeNotificationSettings] = useActions([
    changeFieldActionCreator,
    loginActionCreator,
    resetErrorCreator,
    setValidActionCreator,
    logoutActionCreator,
    changeNotificationSettingsActionCreator,
  ])
  const [isLoginValid, setIsLoginValid] = useState(true)
  const [isPasswordValid, setIsPasswordValid] = useState(true)

  const tabs = [
    { title: 'Individual', id: CLIENT_TYPE.INDIVIDUAL, isActive: loginType === CLIENT_TYPE.INDIVIDUAL },
    { title: 'Business', id: CLIENT_TYPE.SME, 'data-test-id': 'loginTypeSME', isActive: loginType === CLIENT_TYPE.SME },
  ]

  const handleLoginTypeChange = useCallback(
    (loginType) => {
      resetError()
      setLoginType(loginType)
    },
    [resetError, setLoginType],
  )

  const handleLoginNameChange = useCallback(
    (event, value) => {
      resetError()
      setLoginIdentifier(value)
      setShouldForceValidPassword(false)
    },
    [resetError, setLoginIdentifier, setShouldForceValidPassword],
  )

  const handleLoginPasswordChange = useCallback(
    (event, value) => {
      resetError()
      setLoginPassword(value)
      setShouldForceValidPassword(false)
    },
    [resetError, setLoginPassword, setShouldForceValidPassword],
  )

  const handleChangeNotificationAgreement = useCallback(() => {
    setIsAgreeWithNotifications(!isAgreeWithNotifications)
  }, [isAgreeWithNotifications])

  const handleSubmit = async (event): Promise<void> => {
    event.preventDefault()

    if (validation.loginIdentifier.errors.length > 0) setIsLoginValid(false)
    if (validation.loginPassword.errors.length > 0) setIsPasswordValid(false)

    if (!bulkValidate(validation) && !validation.error?.rules?.includes(false)) return

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { access_token, client_id } = client

    let next = urlTo('dashboard', {}, query)

    if (query.next) {
      next = query.next
    }

    try {
      if (access_token) {
        await logout()
      }

      await changeField({ email: loginIdentifier, password: loginPassword })

      const stateAfterLogin: { client: LoginClientAlike } = await wait(
        login({
          loginIdentifier: loginIdentifier.trim(),
          loginPassword,
          client_id,
        }),
      )

      if (stateAfterLogin.client.error) {
        throw stateAfterLogin.client.error
      }

      if (isDiscourse) {
        return
      }

      if (stateAfterLogin.client.scope === '2fa_needed') {
        await features.fetch()
        goTo(urlTo('2fa', null, { ...query, ...(isAgreeWithNotifications && { isAgreeWithNotifications }), next }))
        return
      }

      trackEvent({ action: 'login' })
      if (isAgreeWithNotifications) {
        changeNotificationSettings('promo', true)
      }

      goTo(next)
    } catch (error) {
      if (error.response && error.response.data?.error === 'captcha') {
        const captchaToken: string | null = error?.response?.data ? error.response.data.token : null
        const captchaUrl: string | null = CONFIG?.CAPTCHA_URL ?? null

        if (captchaToken && captchaUrl) {
          const loc = window.location.toString()
          const url = `${captchaUrl}?loginIdentifier=${encodeURIComponent(
            loginIdentifier,
          )}&backLink=${loc}&token=${captchaToken}`

          goTo(url)
        }
      }

      if (error.response && error.response.data?.error !== 'captcha') {
        sendError(error)
      }

      const errorText = error.response?.data?.non_field_errors
        ? error.response.data.non_field_errors[0]
        : 'Something went wrong'

      showFailToast(errorText)
    }
  }

  useEffect(
    () => {
      if (client.access_token) {
        setValid()
      }

      setLogoutProcessingFinish()

      return () => {
        changeField({ password: null })
      }
    },
    // had to run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useAsyncEffect(async () => {
    if (isDiscourse && sso && sig && client.access_token) {
      try {
        const data = await authorizeWithDiscourse({ sso, sig })

        if (data instanceof ApiError) {
          throw data
        }

        goTo(data.redirect_uri, { hardGoTo: true, unsafe: true })
      } catch {
        showFailToast()
      }
    }
  }, [client.access_token])

  const inputMods = { size: 'bigger' }
  const validation = combineErrors(
    {
      loginIdentifier: getLoginIdentifierRules(loginIdentifier),
      loginPassword: { rules: [loginPassword], errors: ['Password can’t be empty'] },
    },
    backendErrorsToObj(client?.error),
  ) as unknown as LoginValidation

  const loginIdentifierLabel = useMemo(() => {
    return loginType === CLIENT_TYPE.INDIVIDUAL ? 'Your email' : 'Business email'
  }, [loginType])

  const agreementNotificationsCheckbox = useMemo(
    () => (
      <Paper bottom={24}>
        <CheckboxWithLabel
          className="Login-Checkbox"
          label={
            <Typography size={14} lineHeight="small">
              <Typo>Receive InvestEngine updates</Typo>
            </Typography>
          }
          onChange={handleChangeNotificationAgreement}
          checked={isAgreeWithNotifications}
          data-test-id="loginNotificationAgreement"
        />
      </Paper>
    ),
    [isAgreeWithNotifications, handleChangeNotificationAgreement],
  )

  const submitButton = (
    <Fragment>
      <Button
        type="submit"
        mods={{ size: 'big block', color: 'green' }}
        // disabled={!bulkValidate(validation)}
        onClick={handleSubmit}
        data-test-id="loginSubmitButton"
      >
        Login
      </Button>

      <Paper top={desktop ? 24 : 32} bottom={desktop ? 0 : 16}>
        <Link to={urlTo('pre-flight', {}, query)}>
          <Typography lineHeight="small" color="inherit" align="center">
            <Typo>Don’t have an account? Get started</Typo>
          </Typography>
        </Link>
      </Paper>
    </Fragment>
  )

  const formCard = (
    /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
    <form onSubmit={handleSubmit}>
      <Fieldset>
        {sessionExpired ? (
          <Paper bottom={24}>
            <Typography color="error" align="center" data-test-id="loginSessionExpiredMessage">
              Your session has expired
            </Typography>
            <Paper top={4}>
              <Typography size={12} align="center">
                <Link to="/" hard>
                  Go to homepage
                </Link>{' '}
                or login below
              </Typography>
            </Paper>
          </Paper>
        ) : null}
        <Paper bottom={32}>
          <GreyTabs
            tabs={tabs}
            onChange={(tab) => {
              handleLoginTypeChange(tab.id)
            }}
            fullWidth
            large
          />
        </Paper>
        <Paper bottom={desktop ? 24 : 32}>
          <Validate rules={validation.loginIdentifier.rules} skipWaitBlur={!isLoginValid}>
            <Label errorMessages={validation.loginIdentifier.errors} data-test-id="loginIdentifierField">
              {loginIdentifierLabel}
              <Input
                className="Login-Input"
                type="email"
                mods={inputMods}
                onChange={handleLoginNameChange}
                onFocus={() => {
                  setIsLoginValid(true)
                }}
                tabIndex={1}
                name="email"
              >
                {loginIdentifier}
              </Input>
            </Label>
          </Validate>
        </Paper>
        <Paper bottom={desktop ? 12 : 16}>
          <Validate
            rules={validation.loginPassword.rules}
            forceValid={shouldForceValidPassword}
            skipWaitBlur={!isPasswordValid}
          >
            <Label errorMessages={validation.loginPassword.errors} data-test-id="loginPasswordField">
              Password
              <Input
                className="Login-Input"
                mods={inputMods}
                type="password"
                onChange={handleLoginPasswordChange}
                onFocus={() => {
                  setIsPasswordValid(true)
                }}
                tabIndex={2}
                name="password"
              >
                {loginPassword}
              </Input>
            </Label>
          </Validate>
        </Paper>
        <Paper bottom={desktop ? 24 : 40}>
          <Typography align={desktop ? 'left' : 'center'} size={desktop ? 12 : 16}>
            <Link
              to={urlTo('forgot-password', null, loginIdentifier ? { email: loginIdentifier } : null)}
              data-test-id="loginGoToForgotPasswordLink"
            >
              Forgot password?
            </Link>
          </Typography>
        </Paper>
        {agreementNotificationsCheckbox}
        {desktop && submitButton}
      </Fieldset>
    </form>
  )

  const title = 'Log in to InvestEngine'

  return (
    <Fragment>
      {desktop ? (
        <Desktop title={title} queryParams={query} isLoading={isLoading}>
          {formCard}
        </Desktop>
      ) : (
        <Mobile title={title} hasTabs button={submitButton} backUrl="/" isLoading={isLoading}>
          {formCard}
        </Mobile>
      )}
    </Fragment>
  )
}

export { Login, type LoginProps }
