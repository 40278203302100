import React from 'react'

import { Stories } from 'components/organisms/Stories/Stories'

import { useDashboardStories } from './useDashboardStories'

const DashboardStories = (): React.ReactElement => {
  const { stories, areStoriesFetched, customStoriesActions } = useDashboardStories()

  return <Stories stories={stories} isLoading={!areStoriesFetched} customActions={customStoriesActions} />
}

export { DashboardStories }
