import React, { Fragment } from 'react'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import { ContextMenu } from 'components/molecules/ContextMenu/ContextMenu'
import { Preloader } from 'components/molecules/Preloader'

import { CantHideModal } from './CantHideModal'
import { DeletePortfolioModal } from './DeletePortfolioModal'
import { useDashboardContextMenu } from './useDashboardContextMenu'

type DashboardContextMenuProps = {
  portfolio?: Portfolio
  regulatoryTypeGroup?: 'ISA' | 'GIA' | 'SIPP'
  offsetTop?: number
  children: React.ReactNode
  isEnabled?: boolean
  groupRefs: Record<'ISA' | 'GIA' | 'SIPP', React.RefObject<HTMLDivElement>>
}

const DashboardContextMenu = ({
  portfolio,
  regulatoryTypeGroup,
  offsetTop = 0,
  children,
  groupRefs,
  isEnabled = false,
}: DashboardContextMenuProps): React.ReactNode => {
  const {
    items,
    isLoading,
    isDeletePortfolioModalOpen,
    isCantHideModalOpen,
    closeCantHideModal,
    handleDeletePortfolio,
    closeDeletePortfolioModal,
  } = useDashboardContextMenu({ portfolio, regulatoryTypeGroup, groupRefs })

  if (!isEnabled || !items.length) return children

  return (
    <Fragment>
      <ContextMenu items={items} offsetTop={offsetTop} overlayPadding={regulatoryTypeGroup ? 16 : 0}>
        {children}
      </ContextMenu>
      <Preloader loading={isLoading} zIndex />
      <DeletePortfolioModal
        isOpen={isDeletePortfolioModalOpen}
        onDeletePortfolio={handleDeletePortfolio}
        onClose={closeDeletePortfolioModal}
      />
      <CantHideModal isOpen={isCantHideModalOpen} onClose={closeCantHideModal} />
    </Fragment>
  )
}

export { DashboardContextMenu }
