import { useCallback, useState } from 'hooks'

import { format as formatMoney } from 'helpers/money'

import type { Security } from 'app/effector/securities/models'
import {
  DividendsType,
  DividendsFrequency,
  ReplicationMethod,
  EsgClassification,
} from 'app/effector/securities/models/SecurityProperties'

type UseSecurityDetailsProps = {
  security: Security
}

type ModalContent = {
  title: string | null
  text: string | null
}

type UseSecurityDetailsReturnProps = {
  securityDetailsData: Array<{
    title: string
    value: string | null
    description: {
      title: string | null
      text: string | null
    }
    isVisible: boolean
  }>
  showModal: boolean
  modalContent: ModalContent
  handleOpenModal: (data: ModalContent) => void
  handleCloseModal: () => void
}

const dividendTypesTexts = {
  [DividendsType.ACCUMULATING]: 'Accumulating',
  [DividendsType.DISTRIBUTING]: 'Distributing',
}

const dividendTypesDescriptions = {
  [DividendsType.ACCUMULATING]:
    'An accumulating ETF automatically reinvests any income from its underlying holdings back into the ETF, rather than paying it out (as with a distributing ETF).',
  [DividendsType.DISTRIBUTING]:
    'A distributing ETF pays out the income from its underlying holdings, rather than automatically reinvesting it (as with an accumulating ETF).',
}

const dividendPaydoutTexts = {
  [DividendsFrequency.ANNUAL]: 'Annual',
  [DividendsFrequency.IRREGULARLY]: 'Irregularly',
  [DividendsFrequency.MONTHLY]: 'Monthly',
  [DividendsFrequency.QUARTERLY]: 'Quarterly',
  [DividendsFrequency.SEMI_ANNUALLY]: 'Semi-annually',
}

const dividendPaydoutDescriptions = {
  [DividendsFrequency.ANNUAL]: 'This ETF pays out dividends annually.',
  [DividendsFrequency.IRREGULARLY]: 'This ETF pays out dividends on a irregular basis.',
  [DividendsFrequency.MONTHLY]: 'This ETF pays out dividends on a monthly basis.',
  [DividendsFrequency.QUARTERLY]: 'This ETF pays out dividends on a quarterly basis.',
  [DividendsFrequency.SEMI_ANNUALLY]: 'This ETF pays out dividends semi-annually.',
}

const replicationMethodTexts = {
  [ReplicationMethod.ACTIVELY_MANAGED]: 'Actively managed',
  [ReplicationMethod.PHYSICAL_FULL]: 'Physical Full',
  [ReplicationMethod.PHYSICAL_OPTIMISED]: 'Physical Optimised',
  [ReplicationMethod.SYNTHETIC]: 'Synthetic',
  [ReplicationMethod.SAMPLING]: 'Sampling',
}

const replicationMethodDescriptions = {
  [ReplicationMethod.ACTIVELY_MANAGED]: 'Indicates if the fund employs some form of active management.',
  [ReplicationMethod.PHYSICAL_FULL]:
    'A replication method which is designed to replicate the performance of an index by buying all the underlying securities.',
  [ReplicationMethod.PHYSICAL_OPTIMISED]:
    'A replication method which is designed to replicate the performance of an index by buying a sample of securities that provide the index performance based on correlations, exposure and risk.',
  [ReplicationMethod.SYNTHETIC]:
    'A replication method which is designed to replicate the performance of an index via swaps and other derivative instruments',
  [ReplicationMethod.SAMPLING]:
    'A replication method which is designed to replicate the performance of an index by buying a sample of the underlying securities of the index.',
}

const esgClassificationTexts = {
  [EsgClassification.ARTICLE_6]: 'Not ESG screened',
  [EsgClassification.ARTICLE_8]: 'ESG screened',
  [EsgClassification.ARTICLE_9]: 'ESG screened',
}

const esgClassificationDescriptions = {
  [EsgClassification.ARTICLE_6]: 'Article 6 — this ETF does not integrate ESG factors into the investment process.',
  [EsgClassification.ARTICLE_8]:
    'Article 8 — this ETF incorporates ESG factors into the investment process, favouring investments or projects with positive environmental or social qualities.',
  [EsgClassification.ARTICLE_9]:
    'Article 9 — this ETF is heavily focused on ESG considerations, and the majority of the portfolio will be in ESG investments.',
}

const useSecurityDetails = ({ security }: UseSecurityDetailsProps): UseSecurityDetailsReturnProps => {
  const { isin, estimated_yield: estimatedYield, properties } = security ?? {}

  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<ModalContent>({
    title: null,
    text: null,
  })

  const handleCloseModal = useCallback(() => {
    setShowModal(false)
  }, [])
  const handleOpenModal = useCallback((modalData: ModalContent) => {
    setModalContent(modalData)
    setShowModal(true)
  }, [])

  const securityDetailsData = [
    {
      title: 'Dividend type',
      value: properties?.dividends_type && dividendTypesTexts[properties?.dividends_type],
      description: {
        title: properties?.dividends_type && dividendTypesTexts[properties?.dividends_type],
        text: properties?.dividends_type && dividendTypesDescriptions[properties?.dividends_type],
      },
      isVisible: !!properties?.dividends_type,
    },
    {
      title: 'Dividend yield',
      value: `${estimatedYield}%`,
      description: {
        title: `Dividend yield ${estimatedYield}%`,
        text: 'This is the dividend income that has been paid out over the previous 12 months from the ETFs in the quantities you currently hold, expressed as a percentage of your current total portfolio balance. Please note that if you did not hold a dividend on the portfolio record date, you would not have received this income.',
      },
      isVisible: !!estimatedYield,
    },
    {
      title: 'Dividends paid out',
      value: properties?.dividends_frequency && dividendPaydoutTexts[properties?.dividends_frequency],
      description: {
        title: properties?.dividends_frequency && dividendPaydoutTexts[properties?.dividends_frequency],
        text: properties?.dividends_frequency && dividendPaydoutDescriptions[properties?.dividends_frequency],
      },
      isVisible: !!properties?.dividends_frequency,
    },
    {
      title: 'TER',
      value: `${properties?.ter}%`,
      description: {
        title: `Total Expense Ratio ${properties?.ter}%`,
        text: 'TER stands for Total Expense Ratio and is how much in percentage (%) terms that the ETF charges for investment management and admin costs each year.\n\nThe TER is deducted directly from the ETF rather than being charged separately, and the value and performance of your investment is quoted after this deduction.',
      },
      isVisible: !!properties?.ter,
    },
    {
      title: 'ESG classification',
      value: properties?.esg_classification === EsgClassification.ARTICLE_6 ? 'Not ESG screened' : 'ESG screened',
      description: {
        title: properties?.esg_classification && esgClassificationTexts[properties?.esg_classification],
        text: properties?.esg_classification && esgClassificationDescriptions[properties?.esg_classification],
      },
      isVisible: !!properties?.esg_classification,
    },
    {
      title: 'Currency hedged',
      value: '£ hedged',
      description: {
        title: 'Currency hedged',
        text: 'Currency hedging is designed to protect investors from exchange rate movements when holding overseas assets.',
      },
      isVisible: !!properties?.is_hedged,
    },
    {
      title: 'Replication method',
      value: properties?.replication_method && replicationMethodTexts[properties?.replication_method],
      description: {
        title: properties?.replication_method && replicationMethodTexts[properties?.replication_method],
        text: properties?.replication_method && replicationMethodDescriptions[properties?.replication_method],
      },
      isVisible: !!properties?.replication_method,
    },
    {
      title: 'ISIN',
      value: isin,
      description: {
        title: `ISIN ${isin}`,
        text: 'An International Securities Identification Number (ISIN) is a code that identifies a security.',
      },
      isVisible: true,
    },
    {
      title: 'Fund size',
      value: `${formatMoney(properties?.fund_size_mm)}m`,
      description: {
        title: `Fund size ${formatMoney(properties?.fund_size_mm)}m`,
        text: 'The total amount of assets held by the fund.',
      },
      isVisible: !!properties?.fund_size_mm,
    },
    {
      title: 'Share class AUM ',
      value: `${formatMoney(properties?.share_class_size_mm)}m`,
      description: {
        title: `Share class AUM ${formatMoney(properties?.share_class_size_mm)}m`,
        text: 'The total amount of assets held by a share class within the fund.',
      },
      isVisible: !!properties?.share_class_size_mm,
    },
  ]

  return {
    securityDetailsData: securityDetailsData.filter((item) => item.isVisible),
    showModal,
    modalContent,
    handleOpenModal,
    handleCloseModal,
  }
}

export { useSecurityDetails }
