import { DocSet, DocName } from '../types'

const docSetsContent: Record<DocSet, DocName[]> = {
  [DocSet.ID]: [DocName.ID, DocName.SELFIE],
  [DocSet.BANK_STATEMENT]: [DocName.BANK_STATEMENT],
  [DocSet.ISA_FORM]: [DocName.ISA_FORM],
  [DocSet.OTHER]: [DocName.OTHER],
  [DocSet.BILL]: [DocName.BILL],
  [DocSet.SOURCE_OF_WEALTH]: [DocName.SOURCE_OF_WEALTH],
}

export { docSetsContent }
