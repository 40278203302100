import { useUnit } from 'effector-react'

import { useActions, useCallback } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import moment from 'helpers/date.js'
import { goTo, urlTo } from 'helpers/router'

import { $quickStartsStore } from 'app/effector/quickStart'

import { changeModalField as changeModalFieldActionCreator } from 'app/redux/actions/ui'
import type { Portfolio } from 'app/redux/models/portfolio/types'

import { setAnimation } from 'components/molecules/Modal/'

import { frequencies } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'

import { manageTypes } from 'constants/portfolio'
import { quickStartStatuses } from 'constants/quickStart'

type UsePromoMonthlyPaymentProps = {
  portfolio: Portfolio
  location: {
    query?: {
      back?: string
      amount?: string
    }
  }
}

type UsePromoMonthlyPaymentReturnProps = {
  isQuickStartInProgress: boolean
  handleSetup: () => void
  handleBack: () => void
}

const usePromoMonthlyPayment = ({
  portfolio,
  location,
}: UsePromoMonthlyPaymentProps): UsePromoMonthlyPaymentReturnProps => {
  const { getQuickStartByPortfolioId } = useUnit($quickStartsStore)
  const quickStart = getQuickStartByPortfolioId(portfolio.id)
  const isLumpSum = !quickStart?.frequency
  const isQuickStartInProgress = quickStart?.status === quickStartStatuses.ACTIVE && !isLumpSum
  const isCashPortfolio = portfolio.manage_type === manageTypes.CASH
  const changeModalField = useActions(changeModalFieldActionCreator)
  const clearForms = useCallback(() => {
    changeModalField('directDebit', {
      monthlyPaymentAmount: null,
      monthlyPaymentDay: null,
      status: 'initial',
      confirmCheckbox: false,
    })
  }, [changeModalField])

  const handleSetup = useCallback(() => {
    clearForms()

    if (isQuickStartInProgress) {
      trackEvent({
        action: 'sp_promo_finish_continued',
        manage_type: portfolio?.manage_type,
        ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
      })
    } else {
      trackEvent({
        action: 'sp_promo_continued',
        manage_type: portfolio?.manage_type,
        ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
        portfolio_created_date: moment(portfolio?.created).format('YYYY-MM-DD'),
      })
    }

    if (location.query?.amount) {
      changeModalField('directDebit', {
        monthlyPaymentAmount: location.query?.amount,
      })
    }

    if (isQuickStartInProgress && quickStart?.frequency === frequencies.MONTHLY) {
      changeModalField('directDebit', {
        monthlyPaymentAmount: quickStart.amount,
        monthlyPaymentDay: quickStart.day_of_month,
      })
    }

    goTo(urlTo(`dashboard.portfolio.options.direct-debit-form`, { id: portfolio.id }, location.query))
  }, [portfolio, location, clearForms, isQuickStartInProgress, quickStart, changeModalField])

  const handleBack = useCallback(() => {
    if (isCashPortfolio && !location.query?.back) {
      goTo(urlTo(`dashboard.portfolio`, { id: portfolio.id }))
      return
    }

    // That's a hack
    setAnimation('true')
    setTimeout(() => {
      setAnimation('false')
    }, 50)

    goTo(location?.query?.back ?? urlTo('dashboard.portfolio.options', { id: portfolio.id }))
  }, [portfolio, isCashPortfolio, location])

  return { isQuickStartInProgress, handleSetup, handleBack }
}

export { usePromoMonthlyPayment }
