import { useUnit } from 'effector-react'
import isEmpty from 'lodash/isEmpty'

import { useActions, useEffect, useSelector, useLoading } from 'hooks'

import { palette } from 'helpers/palette/'
import { countDaysBeforePayment } from 'helpers/recurringPayments'
import { goTo, urlTo } from 'helpers/router.js'

import { $portfolioSecuritiesStore, fetchPortfolioSecuritiesFx } from 'app/effector/portfolio-securities'
import type { QuickStart } from 'app/effector/quickStart/types'
import { fetchSecuritiesFx, $securitiesStore } from 'app/effector/securities'

import { fetchDIYHistoryByPortfolio as fetchHistoryActionCreator } from 'app/redux/actions/portfolios'
import { type Portfolio } from 'app/redux/models/portfolio/types'
import { selectGoalTitle } from 'app/redux/selectors'

import { useCommonPortfolio } from 'app/pages/Dashboard/Goals/hooks/useCommonPortfolio'
import { frequencies } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'

import type { DetailedSecurity, AllocationItem } from '../../ManagedPortfolio/types'

import { manageTypes } from 'constants/portfolio'
import { quickStartStatuses } from 'constants/quickStart'

type UseSelfSelectedPortfolioProps = {
  id: string
}

type UseSelfSelectedPortfolioReturnProps = {
  title: string
  portfolio: Portfolio
  preset: Record<string, any>
  quickStart?: QuickStart
  showLumpSumBanner: boolean
  showFinishSavingsPlanBanner: boolean
  showFirstTopupBanner?: boolean
  daysBeforePayment: number
  percentageScaleData: Array<{ text: string; color: string; percentage: number }>
  allocationData: AllocationItem[]
  isSecuritiesLoading: boolean
  isHistoryLoading: boolean
  handleBack: () => void
}

const useSelfSelectedPortfolio = ({ id }: UseSelfSelectedPortfolioProps): UseSelfSelectedPortfolioReturnProps => {
  const portfolioId = parseInt(id, 10)

  const { savingsPlan, quickStart } = useCommonPortfolio({ portfolioId })
  const portfolio = useSelector((state) => state.portfolios.items.find((portfolio) => portfolio.id === portfolioId))

  const [fetchHistory] = useActions([fetchHistoryActionCreator])

  const title = useSelector((state) => selectGoalTitle(state, portfolioId))
  const { presets } = useSelector((state: any) => state.portfolios)
  const preset = presets[manageTypes.SELF_SELECTED]?.find(
    (preset: any) => preset.id === parseInt(portfolio?.preset, 10),
  )
  const isLumpSum = !quickStart?.frequency
  const showFinishSavingsPlanBanner = quickStart?.status === quickStartStatuses.ACTIVE && !savingsPlan && !isLumpSum
  const showLumpSumBanner = quickStart?.status === quickStartStatuses.ACTIVE && isLumpSum
  const daysBeforePayment = countDaysBeforePayment(
    savingsPlan?.frequency || frequencies.MONTHLY,
    savingsPlan?.start_day_week,
    savingsPlan?.day_of_month,
  )
  const showFirstTopupBanner =
    quickStart?.status === quickStartStatuses.ACTIVE && !isLumpSum && savingsPlan && daysBeforePayment > 0

  const { selectSecuritiesInPortfolioDetailed } = useUnit($portfolioSecuritiesStore)
  const portfolioSecurities = selectSecuritiesInPortfolioDetailed(portfolio.id, false) || []
  const { securities } = useUnit($securitiesStore)

  const shouldLoadSecurities = isEmpty(securities)

  const { isLoading: isSecuritiesLoading, wait: waitForSecurities } = useLoading(shouldLoadSecurities)
  const { isLoading: isPortfolioSecuritiesLoading, wait: waitForPortfolioSecurities } = useLoading(false)
  const { isLoading: isHistoryLoading, wait: waitForHistory } = useLoading(false)

  useEffect(() => {
    if (shouldLoadSecurities) {
      waitForSecurities(fetchSecuritiesFx())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (portfolio.id) {
      waitForPortfolioSecurities(fetchPortfolioSecuritiesFx({ portfolioId: portfolio.id }))
      waitForHistory(fetchHistory(portfolio.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio.id])

  const totalStocksPercentage = preset?.preset_stocks?.reduce((sum, stock) => sum + (stock.amount || 0), 0) || 0

  const percentageScaleData = [
    {
      text: 'Equities',
      color: palette.stocks,
      percentage: totalStocksPercentage,
    },
    {
      text: 'Bonds',
      color: palette['self-selected-default'],
      percentage: 100 - totalStocksPercentage,
    },
  ]

  const getSecuritiesList = (securities, type): DetailedSecurity[] =>
    securities
      .filter(([security]) => security.type === type)
      .map(([security, portfolioSecuritie]) => ({ ...security, ...portfolioSecuritie }))
      .sort((a, b) => b.current_weight_from_capital_balance - a.current_weight_from_capital_balance)

  const getSecuritiesWeight = (securities): number =>
    securities.reduce(
      (sum, { current_weight_from_capital_balance: currentWeightFromCapitalBalance }) =>
        sum + currentWeightFromCapitalBalance,
      0,
    )

  const stocks = getSecuritiesList(portfolioSecurities, 'STOCK')
  const bonds = getSecuritiesList(portfolioSecurities, 'BOND')
  const alternatives = getSecuritiesList(portfolioSecurities, 'ALTERNATIVE')
  const cash = portfolio.goal_cash

  const allocationData: AllocationItem[] = [
    {
      type: 'Equities',
      items: stocks,
      weight: getSecuritiesWeight(stocks),
    },
    {
      type: 'Bonds',
      items: bonds,
      weight: getSecuritiesWeight(bonds),
    },
    {
      type: 'Alternatives',
      items: alternatives,
      weight: getSecuritiesWeight(alternatives),
    },
    {
      type: 'Cash',
      items: cash,
      weight: cash.reduce((sum, { amount }) => sum + amount, 0),
      value: cash.reduce((sum, { total_value: totalValue }) => sum + totalValue, 0),
    },
  ].filter(({ weight }) => weight > 0)

  const handleBack = (): void => {
    goTo(urlTo('dashboard'))
  }

  return {
    title,
    portfolio,
    preset,
    quickStart,
    showLumpSumBanner,
    showFinishSavingsPlanBanner,
    showFirstTopupBanner,
    daysBeforePayment,
    percentageScaleData,
    allocationData,
    isSecuritiesLoading: isSecuritiesLoading || isPortfolioSecuritiesLoading,
    isHistoryLoading,
    handleBack,
  }
}

export { useSelfSelectedPortfolio }
