import React from 'react'
import PropTypes from 'prop-types'

import { palette } from 'helpers/palette/'

import { useMemo } from 'hooks'

import './PercentageBar.css'

const PercentageBar = ({ amount, color = palette['primary-default'] }) => {
  if (amount <= 0) {
    amount = 0
  }

  if (amount >= 100) {
    amount = 100
  }

  const style = useMemo(() => {
    if (amount <= 0) {
      return {}
    }

    return { width: `${amount}%`, minWidth: 4, backgroundColor: color }
  }, [amount, color])

  return (
    <div className="PercentageBar">
      <div className="PercentageBar-Fill" style={style} />
    </div>
  )
}

PercentageBar.propTypes = {
  amount: PropTypes.number.isRequired,
  color: PropTypes.string,
}

export { PercentageBar }
