import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useCallback, useMediaQueries } from 'hooks'

import { VisuallyHidden } from 'components/atoms/VisuallyHidden'
import { Typography } from 'components/atoms/Typography'

import { PERIODS } from 'app/pages/Securities/hooks'

import './PeriodSelect.css'

const SHORT_NAME_BY_PERIOD = {
  [PERIODS.WEEK]: '1W',
  [PERIODS.MONTH]: '1M',
  [PERIODS.SIX_MONTHS]: '6M',
  [PERIODS.YEAR]: '1Y',
  [PERIODS.MAX]: 'MAX',
}

const LONG_NAME_BY_PERIOD = {
  [PERIODS.WEEK]: 'Show information for a week',
  [PERIODS.MONTH]: 'Show information for a month',
  [PERIODS.SIX_MONTHS]: 'Show information for six months',
  [PERIODS.YEAR]: 'Show information for a year',
  [PERIODS.MAX]: 'Show information for maximum available time',
}

const PeriodSelect = ({ selectedPeriod, handlePeriodSelect }) => {
  const { desktop } = useMediaQueries()

  const renderPeriod = useCallback(
    ([key, value]) => {
      const active = value === selectedPeriod
      const classes = classNames('PeriodSelect-Period', { 'PeriodSelect-Period_active': active })

      return (
        <Typography size={desktop ? 16 : 14} key={key} color={active ? 'on_color_default' : 'minor'} align="center">
          <button
            className={classes}
            onClick={() => handlePeriodSelect(value)}
            role="switch"
            aria-checked={active}
            data-test-id="periodSelect"
            data-period-name={SHORT_NAME_BY_PERIOD[value]}
          >
            <span className="PeriodSelect-Name" aria-hidden>
              {SHORT_NAME_BY_PERIOD[value]}
            </span>
            <VisuallyHidden>{LONG_NAME_BY_PERIOD[value]}</VisuallyHidden>
          </button>
        </Typography>
      )
    },
    [selectedPeriod, desktop],
  )

  return <div className="PeriodSelect">{Object.entries(PERIODS).map(renderPeriod)}</div>
}

PeriodSelect.propTypes = {
  selectedPeriod: PropTypes.string,
  handlePeriodSelect: PropTypes.func,
}

export { PeriodSelect }
