import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

type TwoFactorStatusResponse = {
  state: 'ALLOWED' | 'DENIED' | 'REQUESTED' | 'SMS_REQUIRED'
}

const request2faStatus = async ({ clientId, token }): Promise<TwoFactorStatusResponse | ApiError> => {
  let params = {}

  if (clientId && token) params = { ...params, client_id: clientId, reset_token: token, action: 'reset_password' }
  try {
    const { data } = await axios.get('/oauth/2fa/status/', { params })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const resendPushFor2fa = async ({ clientId, token }): Promise<undefined | ApiError> => {
  const params = clientId && token ? { client_id: clientId, reset_token: token, action: 'reset_password' } : {}

  try {
    await axios.post('/oauth/2fa/resend/', { ...params })
  } catch (error) {
    return new ApiError(error)
  }
}

type TwoFactorSetupParams = {
  action: 'enable' | 'disable'
  type?: 'push' | 'push_and_sms' | 'sms'
}

type TwoFactorSetupReturnData =
  | {
      state: 'ENABLED' | 'DISABLED' | 'REQUESTED'
    }
  | ApiError

const setup2fa = async ({ action, type = 'push' }: TwoFactorSetupParams): Promise<TwoFactorSetupReturnData> => {
  try {
    const { data } = await axios.post(`/oauth/2fa/setup/`, { action, type })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

type TwoFADetailsData = {
  phone: string
  phone_verified: boolean
}

const get2faDetails = async ({ clientId, token }): Promise<TwoFADetailsData | ApiError> => {
  const params = clientId && token ? { client_id: clientId, reset_token: token, action: 'reset_password' } : {}

  try {
    const { data } = await axios.get(`/oauth/2fa/details/`, { params })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

type TwoFactorPhoneConfirmationParams = {
  phone: string
}

const setup2faConfirmPhone = async ({ phone }: TwoFactorPhoneConfirmationParams): Promise<undefined | ApiError> => {
  try {
    const { data } = await axios.post(`/oauth/2fa/setup/phone_confirmation/`, { phone })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

const sendSmsCode = async ({ clientId, token }): Promise<undefined | ApiError> => {
  const params = clientId && token ? { client_id: clientId, reset_token: token, action: 'reset_password' } : {}

  try {
    const { data } = await axios.post(`/oauth/2fa/sms/send/`, { ...params })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

type VeriySmsCodeParams = {
  key: string
  shouldRememberBrowser?: boolean
  clientId?: string
  token?: string
}

const verifySmsCode = async ({
  key,
  shouldRememberBrowser,
  clientId,
  token,
}: VeriySmsCodeParams): Promise<undefined | ApiError> => {
  const params = clientId && token ? { client_id: clientId, reset_token: token, action: 'reset_password' } : {}

  try {
    const { data } = await axios.post(`/oauth/2fa/sms/auth/`, { key, remember: shouldRememberBrowser, ...params })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

type ReauthorizeParams = {
  password: string
}

type ReauthorizeData = {
  access_token: string
}

const reauthorize = async ({ password }: ReauthorizeParams): Promise<ReauthorizeData | ApiError> => {
  try {
    const { data } = await axios.post(`/oauth/reauth/`, { password })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export {
  request2faStatus,
  resendPushFor2fa,
  setup2fa,
  setup2faConfirmPhone,
  sendSmsCode,
  verifySmsCode,
  reauthorize,
  get2faDetails,
  type TwoFactorStatusResponse,
}
