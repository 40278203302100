import { createEffect, createStore } from 'effector'

import { makeApiCall } from 'app/effector/api'

import * as api from './api'

// Effects
const fetchRecentlyViewedEtfsFx = createEffect(async () => {
  try {
    return await makeApiCall(api.fetchRecentlyViewedEtfs)
  } catch (error) {}
})

const setSecurityAsViewedFx = createEffect(async ({ securityId }: { securityId: number }) => {
  try {
    return await makeApiCall(api.setSecurityAsViewed, { securityId })
  } catch (error) {}
})

// Stores
const $recentlyViewedEtfs = createStore<number[]>([])

$recentlyViewedEtfs.on(fetchRecentlyViewedEtfsFx.doneData, (state, result) => {
  if (!result) return state
  return result
})

$recentlyViewedEtfs.on(setSecurityAsViewedFx.doneData, (state, result) => {
  if (!result) return state

  return [...new Set([result, ...state])]
})

const $areRecentlyViewedEtfsFetched = createStore<boolean>(false)
$areRecentlyViewedEtfsFetched.on(fetchRecentlyViewedEtfsFx.done, () => true)

const $isLoading = fetchRecentlyViewedEtfsFx.pending

export {
  // Effects
  fetchRecentlyViewedEtfsFx,
  setSecurityAsViewedFx,
  // Stores
  $recentlyViewedEtfs,
  $areRecentlyViewedEtfsFetched,
  $isLoading,
}
