import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { RecoveryCode } from 'app/pages/Dashboard/UserProfile/LoginSettings/components/RecoveryCodeModal/RecoveryCode'

import {
  $newRecoveryCode,
  $isConfirmNewRecoveryCodeChecked,
  setIsConfirmNewRecoveryCodeChecked,
  generateRecoveryCodeFx,
  confirmNewRecoveryCode,
} from './model'

const NewRecoveryCode = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const [isConfirmNewRecoveryCodeChecked, newRecoveryCode, handleGenerateRecoveryCode, handleConfirmNewRecoveryCode] =
    useUnit([$isConfirmNewRecoveryCodeChecked, $newRecoveryCode, generateRecoveryCodeFx, confirmNewRecoveryCode])

  const titleNode = (
    <Fragment>
      <AllCenter>
        <Icon type="warning-64" color={palette['status-warning']} size={64} />
      </AllCenter>
      <Paper top={16}>
        <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
          <Typo>Write down your new recovery code</Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  const content = (
    <Fragment>
      <Typography size={14} align="center">
        <Typo>
          The recovery code is the only way to restore access to your account if you lose your phone. Keep the code in a
          safe place.
        </Typo>
      </Typography>
      <Paper top={40}>
        <RecoveryCode recoveryCode={newRecoveryCode.raw} onRegenerate={handleGenerateRecoveryCode} />
      </Paper>
    </Fragment>
  )

  const button = (
    <Fragment>
      <CheckboxWithLabel
        label={
          <Typography size={14}>
            <Typo>I've written down the recovery code and understand the risk associated with losing the code</Typo>
          </Typography>
        }
        checked={isConfirmNewRecoveryCodeChecked}
        onChange={setIsConfirmNewRecoveryCodeChecked}
      />
      <Paper top={16}>
        <Button
          mods={{ size: 'big block' }}
          onClick={handleConfirmNewRecoveryCode}
          disabled={!isConfirmNewRecoveryCodeChecked}
        >
          Continue
        </Button>
      </Paper>
    </Fragment>
  )

  if (desktop) {
    return (
      <Paper top={8} left={24} right={24} bottom={64} style={{ position: 'relative' }}>
        <Paper top={40}>{titleNode}</Paper>
        <Paper top={40} left={40} right={40}>
          {content}
          <Paper top={40}>{button}</Paper>
        </Paper>
      </Paper>
    )
  }

  return (
    <MobileLayout
      content={
        <Fragment>
          {titleNode}
          <Paper top={40}>{content}</Paper>
        </Fragment>
      }
      footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
    />
  )
}

export { NewRecoveryCode }
