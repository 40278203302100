import { useCallback, useSelector, useActions, useContext } from 'hooks'

import { processError } from 'helpers/errors'
import { goTo, urlTo } from 'helpers/router.js'

import {
  setValid as setClientValidActionCreator,
  resetError as resetClientErrorActionCreator,
} from 'app/redux/actions/client'
// TODO: migrate to `actions/portfolios` when goals reducer became redundant
import {
  setValid as setValidGoalsActionCreator,
  resetError as resetGoalsErrorActionCreator,
  updateOrCreate as updateOrCreateGoalActionCreator,
} from 'app/redux/actions/portfolios'
import { deleteClientAnswers as deleteClientAnswersActionCreator } from 'app/redux/actions/questionnaire'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

import { states as clientStates } from 'constants/client'
import { manageTypes, states } from 'constants/portfolio'

const useCreatePortfolio = () => {
  const client = useSelector((state) => state.client)
  const portfolios = useSelector((state) => state.portfolios.list)

  const [resetClientError, setClientValid, updateOrCreateGoal, resetGoalsError, setGoalsValid, deleteClientAnswers] =
    useActions([
      resetClientErrorActionCreator,
      setClientValidActionCreator,
      updateOrCreateGoalActionCreator,
      resetGoalsErrorActionCreator,
      setValidGoalsActionCreator,
      deleteClientAnswersActionCreator,
    ])

  const { resetAllScrollPositions } = useContext(ScrollSaverContext)

  const handleGoToSecurityUniverse = useCallback(
    (portfolio) => {
      resetAllScrollPositions()
      goTo(urlTo('securities', null, { portfolioId: portfolio.id }), { replace: true, scrollToTop: false })
    },
    [resetAllScrollPositions],
  )

  const handleGoToNewPortfolioTunnel = useCallback((portfolio) => {
    if (portfolio.is_default_preset_used) {
      goTo(urlTo('new-portfolio-tunnel.review-portfolio.allocation', { id: portfolio.id }), { replace: true })
      return
    }

    goTo(urlTo('new-portfolio-tunnel', { id: portfolio.id }, location.search), { replace: true })
  }, [])

  const handleClearManagedPortfolio = useCallback(
    async (portfolio) => {
      await updateOrCreateGoal(['initial_deposit'], { initial_deposit: null }, false, portfolio.id) // clear initial_deposit
      await deleteClientAnswers(portfolio.id) // clear questionnaire
    },
    [updateOrCreateGoal, deleteClientAnswers],
  )

  const handleCreateNewPortfolio = useCallback(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    (manage_type, regulatory_type, preset_type, is_isa_transfer, is_default_preset_used) =>
      updateOrCreateGoal(
        ['manage_type', 'regulatory_type', 'preset_type', 'is_isa_transfer', 'is_default_preset_used'],
        { manage_type, regulatory_type, preset_type, is_isa_transfer, is_default_preset_used },
        true,
        null,
        false,
      ),
    [updateOrCreateGoal],
  )

  return useCallback(
    async ({ manageType, regulatoryType, presetType, isIsaTransfer, isDefault = false }) => {
      try {
        const newPortfolioShape = {
          state: states.NEW,
          manage_type: manageType,
          regulatory_type: regulatoryType,
          preset_type: presetType,
          is_default_preset_used: isDefault,
        }

        if (client.state === clientStates.NEW && newPortfolioShape.manage_type === manageTypes.MANAGED) {
          const nextModule = client.type ? 'create-account' : 'product-type'

          return goTo(
            urlTo(
              nextModule,
              {},
              {
                mode: 'DASHBOARD',
                back: location?.query?.back ?? window.location.pathname + window.location.search,
                next: urlTo('create-new-portfolio', null, { regulatoryType, manageType, presetType }),
              },
            ),
            { replace: false },
          )
        }

        if (typeof isIsaTransfer !== 'undefined') {
          newPortfolioShape.isa = { transferring_in_process: isIsaTransfer }
        }

        const alreadyCreatedNewPortfolio = portfolios.filterByShape(newPortfolioShape)?.[0] ?? null

        if (alreadyCreatedNewPortfolio) {
          if (alreadyCreatedNewPortfolio.manage_type === manageTypes.DIY) {
            handleGoToSecurityUniverse(alreadyCreatedNewPortfolio)
            return
          }

          await handleClearManagedPortfolio(alreadyCreatedNewPortfolio)
          handleGoToNewPortfolioTunnel(alreadyCreatedNewPortfolio)
          return
        }

        const nextState = await handleCreateNewPortfolio(
          manageType,
          regulatoryType,
          presetType,
          isIsaTransfer,
          isDefault,
        )

        setClientValid()
        setGoalsValid()

        if (nextState.portfolios.error) {
          throw nextState.portfolios.error
        }

        const createdNewPortfolio = nextState.portfolios.list.filterByShape(newPortfolioShape)?.[0] ?? null

        if (createdNewPortfolio) {
          if (createdNewPortfolio.manage_type === manageTypes.DIY) {
            handleGoToSecurityUniverse(createdNewPortfolio)
            return
          }

          handleGoToNewPortfolioTunnel(createdNewPortfolio)
          return
        }

        throw new Error('No portfolio created after `useCreatePortfolio` call')
      } catch (error) {
        processError({
          error,
          forceReset: true,
          resets: [resetGoalsError, resetClientError],
        })
      }
    },
    [
      client,
      portfolios,
      handleGoToSecurityUniverse,
      handleClearManagedPortfolio,
      handleGoToNewPortfolioTunnel,
      handleCreateNewPortfolio,
      setClientValid,
      setGoalsValid,
      resetGoalsError,
      resetClientError,
    ],
  )
}

export { useCreatePortfolio }
