import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Gateway } from 'components/atoms/Gateway'
import { NavigationBar } from 'components/atoms/NavigationBar'
import Segment from 'components/atoms/Segment/Segment.jsx'

import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import ContentHolder from 'components/molecules/ContentHolder/ContentHolder.jsx'
import { Modal } from 'components/molecules/Modal'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

type InvestEngineNewsModalProps = {
  open: boolean
  onClose: () => void
  onContinue: () => void
}

function InvestEngineNewsModal({
  open,
  onClose: handleClose,
  onContinue: handleContinue,
}: InvestEngineNewsModalProps): React.ReactElement {
  const { desktop } = useMediaQueries()

  const contentSection = (
    <Segment mods={desktop ? { margin: 'big', noMargin: 'top' } : { margin: 'bigger' }}>
      <Text center>
        <Headline
          level={desktop ? 2 : 4}
          mods={desktop ? { text: 'light', 'no-margin': 'top bottom' } : { 'no-margin': 'top bottom' }}
        >
          <Typo>Are you sure you want to turn off notifications about InvestEngine features and services?</Typo>
        </Headline>
      </Text>
    </Segment>
  )

  const noButton = (
    <Button
      mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
      onClick={handleClose}
      data-test-id="notificationSettingsTurnOffModalNoButton"
    >
      No
    </Button>
  )

  const yesButton = (
    <Button
      mods={{ size: 'big block' }}
      onClick={handleContinue}
      data-test-id="notificationSettingsTurnOffModalYesButton"
    >
      Yes
    </Button>
  )

  const buttonSection = (
    <ModalContentButtons
      width={desktop ? 20 : null}
      targetGatewayName="notificationSettingsModal"
      phantomButtonsMarginSize="zero"
      isNewModal
    >
      {desktop ? (
        <ColumnarLayout flexDirection={desktop ? 'row' : 'column'} mods={{ padding: 'nano' }}>
          {noButton}
          {yesButton}
        </ColumnarLayout>
      ) : (
        <Fragment>
          <Segment mods={{ noMargin: 'top', margin: 'bigger' }}>{noButton}</Segment>
          <Segment mods={{ noMargin: 'bottom', margin: 'bigger' }}>{yesButton}</Segment>
        </Fragment>
      )}
    </ModalContentButtons>
  )

  const layout = desktop ? (
    <Width size={65}>
      <Width size={40} center>
        <Inner flex>
          <Segment mods={{ margin: 'biggest', noMargin: 'bottom' }}>{contentSection}</Segment>
          <Segment mods={{ margin: 'hefty', noMargin: 'top' }}>{buttonSection}</Segment>
        </Inner>
      </Width>
    </Width>
  ) : (
    <Fragment>
      <NavigationBar onLeftPartClick={handleClose} leftPartText="Close" />
      <Inner flex>
        <AllCenter>{contentSection}</AllCenter>
        <ContentHolder>{buttonSection}</ContentHolder>
      </Inner>
    </Fragment>
  )

  return (
    <Fragment>
      <Gateway into="modals">
        <Modal
          open={open}
          onClose={handleClose}
          insideGatewayName="notificationSettingsModal"
          data-test-id="notificationSettingsTurnOffModal"
        >
          {layout}
        </Modal>
      </Gateway>
    </Fragment>
  )
}

export { InvestEngineNewsModal, type InvestEngineNewsModalProps }
