import React, { Fragment } from 'react'

import { useMemo } from 'hooks'

import { format as formatDate } from 'helpers/date'
import { format as formatMoney } from 'helpers/money'

import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { ContentWithIconAndLink } from 'components/organisms/charts/parts/PointOfInterest/components/ContentWithIconAndLink/ContentWithIconAndLink'

import { PortfolioPointOfInterestTypes, type BuySellPortfolioPointOfInterest } from './index'

type BuySellPortfolioPointOfInterestProps = BuySellPortfolioPointOfInterest & {
  linkToTransactions: string | null
}

function PortfolioBuySellPointOfInterestContent({
  type,
  date,
  data,
  linkToTransactions,
}: BuySellPortfolioPointOfInterestProps): React.ReactElement {
  const content = useMemo(() => {
    const dateString = formatDate(date, 'D MMM YYYY')
    let title = type === PortfolioPointOfInterestTypes.BUY ? 'buy' : 'sell'
    const transactionsQuantity = parseFloat(data.transactions_quantity)

    if (transactionsQuantity > 1) title = `${title}s`

    const value = formatMoney(data.total_value, true, true)

    return (
      <Fragment>
        <Typography size={14} lineHeight="small" weight="semibold" align="right" color="inherit">
          <Typo>{dateString}</Typo>
        </Typography>
        <Paper top={12}>
          <Typography size={14} lineHeight="small" weight="semibold" align="right">
            <Typo>
              {transactionsQuantity} {title}
            </Typo>
          </Typography>
        </Paper>
        <Paper top={4}>
          <Typography size={14} lineHeight="small" color="additional" align="right">
            <Typo>{value}</Typo>
          </Typography>
        </Paper>
      </Fragment>
    )
  }, [type, date, data.transactions_quantity, data.total_value])

  if (linkToTransactions) {
    return <ContentWithIconAndLink to={linkToTransactions}>{content}</ContentWithIconAndLink>
  }

  return (
    <Paper top={12} right={8} bottom={12} left={12}>
      {content}
    </Paper>
  )
}

export { PortfolioBuySellPointOfInterestContent, type BuySellPortfolioPointOfInterestProps }
