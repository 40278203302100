import React, { forwardRef } from 'react'

import { useCallback } from 'hooks'

import { type TransferProgress } from 'app/effector/isa-transfer/models/TransferProgress'

import { AltusProgressBarCard } from './AltusProgressBarCard'
import { AltusProgressBarModal } from './AltusProgressBarModal'
import { useAltusProgressBarModal } from './hooks/useAltusProgressBarModal'

type ProgressBarProps = {
  altusProgressBarData: TransferProgress
  handleDismissPanel: (id: number) => void
  'data-test-id': string
}

const AltusProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
  ({ altusProgressBarData, handleDismissPanel, 'data-test-id': dataTestId }: ProgressBarProps, ref) => {
    const { handleModalOpen, handleModalClose, isModalOpen } = useAltusProgressBarModal()

    const onDismissPanel = useCallback(() => {
      if (altusProgressBarData.id) {
        handleDismissPanel(altusProgressBarData.id)
      }
    }, [altusProgressBarData, handleDismissPanel])

    return (
      <div ref={ref} data-test-id={dataTestId}>
        <AltusProgressBarCard
          altusProgressBarData={altusProgressBarData}
          handleModalOpen={handleModalOpen}
          handleDismissPanel={onDismissPanel}
          data-test-id={dataTestId ? `${dataTestId}Card` : undefined}
        />
        <AltusProgressBarModal
          altusProgressBarData={altusProgressBarData}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          data-test-id={dataTestId ? `${dataTestId}Modal` : undefined}
        />
      </div>
    )
  },
)
AltusProgressBar.displayName = 'AltusProgressBar'

export { AltusProgressBar }
