import React, { Fragment } from 'react'

import { useState } from 'hooks'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type ExpandableDescriptionProps = {
  title: string
  children: React.ReactNode
}

const ExpandableDescription = ({ title, children }: ExpandableDescriptionProps): React.ReactElement => {
  const [isOpened, setIsOpened] = useState(false)

  const toggleOpen = (): void => {
    setIsOpened(!isOpened)
  }

  return (
    <Fragment>
      <Paper top={16} bottom={16}>
        <Link mods={{ color: 'black' }} onClick={toggleOpen}>
          <ItemWithIcon
            iconPosition="right"
            space={4}
            icon={<Icon size={16} type={isOpened ? 'arrow-up-16' : 'arrow-down-16'} />}
            content={
              <Typography lineHeight="small" color="inherit">
                <Typo>{title}</Typo>
              </Typography>
            }
          />
        </Link>
      </Paper>
      {isOpened && (
        <Paper top={16} bottom={40}>
          {children}
        </Paper>
      )}
    </Fragment>
  )
}

export { ExpandableDescription }
