import React, { Fragment } from 'react'

import { useMediaQueries, useRedirect, useCopyToClipboard } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { showSuccessToast } from 'app/redux/actions/ui'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { CardNew } from 'components/atoms/CardNew'
import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { BonusesText } from './components/BonusesText'
import { List } from './components/List'
import { useReferralCode } from './hooks/useReferralCode'
import cover1x from './images/cover@1x.png'
import cover2x from './images/cover@2x.png'
import phone1x from './images/phone@1x.png'
import phone2x from './images/phone@2x.png'

import './ReferralCode.css'

const ReferralCode = ({ location }): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { referralCode, isReferralCodeFetched, isLoading, isNewReferralSystemEnabled } = useReferralCode()

  useRedirect({
    to: 'dashboard.user-profile',
    rule: !isNewReferralSystemEnabled || (isReferralCodeFetched && referralCode.link === null),
    isLoading,
  })

  const navbarNode = (
    <NavigationBar
      leftPartText="Back"
      onLeftPartClick={() => {
        goTo(location?.query?.back ?? urlTo('dashboard.user-profile'))
      }}
    />
  )

  const headerNode = <DesktopHeader />

  const value = referralCode.link

  const handleCopy = useCopyToClipboard(value)

  const handleCopyClick = (): void => {
    handleCopy()
    showSuccessToast('Referral link copied')
  }

  const content = (
    <Fragment>
      <div className="ReferralCode-Banner">
        <div className="ReferralCode-BannerWrapper">
          <div>
            <Typography
              tag="h1"
              size={48}
              font="Poppins"
              color="on_color_white"
              lineHeight="small"
              weight="semibold"
              className="ReferralCode-BannerTitle"
            >
              Refer a friend
            </Typography>
            <Paper top={20} className="ReferralCode-BannerText" mix>
              <Typography tag="p" color="on_color_white">
                <Typo>
                  With InvestEngine's referral programme, when you refer a friend, we'll give you both a randomly
                  selected investment bonus
                </Typo>
              </Typography>
            </Paper>
          </div>

          <picture className="ReferralCode-BannerPicture">
            <source srcSet={`${cover1x} 1x, ${cover2x} 2x`} />
            <img src={cover1x} className="ReferralCode-BannerImage" width={360} height={360} />
          </picture>
        </div>
      </div>
      <Paper top={80} left={20} right={20}>
        <Width size={36} center>
          <BonusesText />
        </Width>
      </Paper>

      {!isLoading && (
        <Paper top={40} left={20} right={20}>
          <Width size={25} center>
            <CardNew borderRadius={8} clickable={false}>
              <Paper top={32} right={32} bottom={32} left={32} className="ReferralCode-Card">
                <Icon type="pound-flag" />

                <div>
                  <Typography tag="h2" size={20} font="Poppins" lineHeight="medium" weight="semibold" align="center">
                    <Typo>Referral link</Typo>
                  </Typography>
                  <Paper top={8} mix>
                    <Typography align="center" wordBreak="break-all">
                      {value}
                    </Typography>
                  </Paper>
                </div>

                <Button
                  mods={{ size: 'big block', color: 'green' }}
                  className="ReferralCode-Button"
                  onClick={handleCopyClick}
                >
                  <Icon type="copy-24" size={24} color="var(--content-on-color-default)" />
                  Copy link
                </Button>
              </Paper>
            </CardNew>
          </Width>
        </Paper>
      )}

      <Paper top={80}>
        <AllCenter>
          <picture className="ReferralCode-PictureNext">
            <source srcSet={`${phone1x} 1x, ${phone2x} 2x`} />
            <img src={phone1x} width={250} height={245} />
          </picture>
        </AllCenter>
        <Paper top={40} mix>
          <Typography tag="h2" size={32} font="Poppins" lineHeight="small" weight="semibold" align="center">
            What to do next?
          </Typography>
        </Paper>
        <Paper top={40} left={20} right={20} bottom={120}>
          <Width size={32} center>
            <List />
          </Width>
        </Paper>
      </Paper>
    </Fragment>
  )

  return (
    <Fragment>
      {desktop ? (
        <DesktopLayout header={headerNode} footer={<DesktopFooter />} content={<Fragment>{content}</Fragment>} noGaps />
      ) : (
        <MobileLayout header={navbarNode} content={content} contentPaperSizes={{ top: 0, left: 0, right: 0 }} />
      )}
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { ReferralCode }
