import React from 'react'
import { PropTypes } from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { goTo, urlTo } from 'helpers/router'

import { useSelector, useCallback, useActions } from 'hooks'
import { useSteps, useFetchPortfolioData } from './hooks'

import { getQuestionnaireSteps, Loading } from 'app/containers/Questionnaire'

import { TunnelStepAdapter } from './Tunnel/TunnelStepAdapter.js'

import FourOhFour from 'app/pages/Error/404.jsx'

import {
  fetchPortfolios as fetchPortfoliosActionCreator,
  updateOrCreate as updateOrCreateGoalActionCreator,
} from 'app/redux/actions/portfolios'
import { deleteClientAnswers as deleteClientAnswersActionCreator } from 'app/redux/actions/questionnaire'
import { getClientAnswersOnQuestions } from 'app/redux/selectors'

const PortfolioTunnel = ({ location, params, route, routes, children }) => {
  const { id: goalId } = params

  const client = useSelector((state) => state.client)
  const questionnaire = useSelector((state) => state.questionnaire)
  const clientAnswersOnQuestions = useSelector(getClientAnswersOnQuestions)
  const portfolios = useSelector((state) => state.portfolios.list)
  const portfolio = portfolios.get(parseInt(goalId, 10))
  const steps = [
    ...getQuestionnaireSteps(route.module, route.childRoutes, questionnaire.questionList.questions, client, portfolio),
    {
      module: `dashboard.portfolio.finish`,
    },
  ]
  const [deleteClientAnswers, updateOrCreateGoal] = useActions([
    deleteClientAnswersActionCreator,
    updateOrCreateGoalActionCreator,
  ])

  useFetchPortfolioData(params.id)

  const stepsProps = useSteps({
    params,
    steps,
    adapter: TunnelStepAdapter({ routes, params }),
    location,
  })

  const resetPortfolio = useCallback(async () => {
    await Promise.all([
      deleteClientAnswers(goalId),
      updateOrCreateGoal(['initial_deposit'], { initial_deposit: null }, false, goalId),
    ])
  }, [deleteClientAnswers, goalId, updateOrCreateGoal])

  const retakeQuestionnaire = useCallback(async () => {
    await resetPortfolio()

    const firstStepModule = stepsProps.steps.at(0).module
    const url = urlTo(firstStepModule, { id: goalId }, location.query)

    goTo(url, { replace: true })
  }, [resetPortfolio, stepsProps.steps, goalId, location.query])

  if (isEmpty(questionnaire.questionList.questions)) {
    return <Loading />
  }

  if (!stepsProps.activeStep) {
    return <FourOhFour />
  }

  return React.cloneElement(children, {
    ...stepsProps,
    parentModule: route.module,
    clientAnswersOnQuestions,
    retakeQuestionnaire,
  })
}

PortfolioTunnel.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      regulatoryType: PropTypes.string,
    }),
  }),
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  route: PropTypes.shape({
    module: PropTypes.string,
    childRoutes: PropTypes.array,
  }),
  routes: PropTypes.array,
  children: PropTypes.node,
}

export { PortfolioTunnel }
