import React, { Fragment } from 'react'

import { useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton, MobileLayoutFullViewport } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { MobileFooter } from 'app/containers/Footer'

import mobileIcon from 'app/pages/Dashboard/Accounts/components/Empty/mobileIcon.svg'
import { useStrapiBonuses } from 'app/pages/Dashboard/PromoBanner/hooks/useStrapiBonuses'

import { BonusBadge } from '../../components/BonusBadge'

type MobileNotAvailableProps = {
  isPromoEnabled: boolean
  isAuthorized: boolean
  handleGetStarted: () => void
  handleOpenTsCsModal: () => void
}

const MobileNotAvailable = ({
  isPromoEnabled,
  isAuthorized,
  handleGetStarted,
  handleOpenTsCsModal,
}: MobileNotAvailableProps): React.ReactElement => {
  const { bonuses } = useStrapiBonuses()

  const headerNode = useMemo(() => <Header logoLink={location.origin} hard hideDrawer withShadow />, [])

  const contentNode = (
    <Fragment>
      <MobileLayoutFullViewport>
        <Inner flex>
          <AllCenter>
            <Typography size={24} lineHeight="small" weight="semibold" align="center">
              <Typo>Portfolio outline no longer available to view. User has disabled sharing.</Typo>
            </Typography>
            <Paper top={48}>
              <Width className="Empty-Icon" size={5}>
                <SvgIcon src={mobileIcon} fluid />
              </Width>
            </Paper>
            <Paper top={24} bottom={80}>
              <Width size={18} center>
                <Typography size={14} align="center">
                  {isPromoEnabled ? (
                    <Typo>
                      You can still join InvestEngine and
                      <br />
                      earn up to {bonuses?.referIsaOrGeneralBonusMax}
                    </Typo>
                  ) : (
                    <Typo>You can still join InvestEngine</Typo>
                  )}
                </Typography>
              </Width>
            </Paper>
          </AllCenter>
        </Inner>
      </MobileLayoutFullViewport>
      <MobileFooter />
    </Fragment>
  )

  const footer = useMemo(
    () =>
      isAuthorized ? null : (
        <MobileLayoutFooterButton>
          <AllCenter>
            <Button type="submit" mods={{ size: 'big block', color: 'green' }} onClick={handleGetStarted}>
              Get started
            </Button>
            {isPromoEnabled && <BonusBadge handleOpenTsCsModal={handleOpenTsCsModal} />}
          </AllCenter>
        </MobileLayoutFooterButton>
      ),
    [isAuthorized, isPromoEnabled, handleGetStarted, handleOpenTsCsModal],
  )

  return <MobileLayout header={headerNode} content={contentNode} footer={footer} />
}

export { MobileNotAvailable }
