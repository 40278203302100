import { Entity } from 'app/redux/models/common/Entity.js'

/**
 * @class
 * @typedef {Object} QuestionList
 */

export class QuestionList extends Entity {
  constructor() {
    super()

    /** @type {Questions[]} */
    this.questions = []
  }

  /**
   * @param {Question[]} questions
   * @return {QuestionList}
   */
  receiveQuestions(questions) {
    this.questions = questions
    return this
  }

  /**
   * @param {number=} id
   * @return {Question}
   */
  findQuestionById(id) {
    return this.questions.find((question) => question.id === id)
  }

  /**
   * @param {number=} code
   * @return {Question}
   */
  findQuestionByCode(code) {
    return this.questions.find((question) => question.code === code)
  }
}
