import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import Inner from 'components/_old/Inner/Inner.jsx'

import './MobileLayoutFooterButton.css'

const MobileLayoutFooterButton = ({ children, opaque }) => {
  const mods = { ...(children?.props?.mods || {}), size: 'big block' }
  const props = { ...(children?.props || {}), mods }
  const classes = classNames('MobileLayoutFooterButton', { MobileLayoutFooterButton_opaque: opaque })

  return (
    <div className={classes}>
      <Inner>
        <div className="MobileLayoutFooterButton-Background">{React.cloneElement(children, props)}</div>
      </Inner>
    </div>
  )
}

MobileLayoutFooterButton.propTypes = {
  children: PropTypes.node,
  opaque: PropTypes.bool,
}

export { MobileLayoutFooterButton }
