import React, { Fragment } from 'react'

import { useMediaQueries, useActions, useMemo, useContext, useCallback } from 'hooks/'

import { sendError } from 'helpers/errorLogging.js'
import { goTo, urlTo } from 'helpers/router.js'

import { deletePortfolio as deletePortfolioActionCreator } from 'app/redux/actions/portfolios/'
import { showFailToast } from 'app/redux/actions/ui/'
import { type Portfolio } from 'app/redux/models/portfolio/types'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'
import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

type DeletePortfolioProps = {
  portfolio: Portfolio
}

function DeletePortfolio({ portfolio }: DeletePortfolioProps): React.ReactElement {
  const { desktop } = useMediaQueries()
  const [deletePortfolio] = useActions([deletePortfolioActionCreator])
  const { tunnelQuery } = useContext(ChangeGoalTunnelContext) as unknown as { tunnelQuery: Record<string, unknown> }

  const handleCancel = useCallback(() => {
    if (!portfolio?.id) {
      throw new Error('There is no `portfolio.id` passed to `DeletePortfolio`')
    }

    goTo(urlTo(`dashboard.portfolio.options`, { id: portfolio.id }, tunnelQuery))
  }, [portfolio, tunnelQuery])

  const handleDelete = useCallback(async () => {
    try {
      if (!portfolio?.id) {
        throw new Error('There is no `portfolio.id` passed to `DeletePortfolio`')
      }

      await deletePortfolio(portfolio.id)

      goTo(urlTo('dashboard'))
    } catch (error) {
      showFailToast()

      if (error?.message) {
        sendError(error?.message)
      }
    }
  }, [portfolio, deletePortfolio])

  const content = useMemo(() => {
    const inner = (
      <Fragment>
        <Typography
          tag="h1"
          size={desktop ? 32 : 24}
          lineHeight={desktop ? 'small' : 'medium'}
          align="center"
          weight="semibold"
        >
          <Typo>Delete portfolio</Typo>
        </Typography>
        <Typography size={desktop ? 14 : 16} align="center">
          <Paper top={desktop ? 32 : 12} bottom={24}>
            <Typo>Are you sure you want to delete this empty portfolio?</Typo>
          </Paper>
        </Typography>
      </Fragment>
    )

    if (desktop) {
      return (
        <Width size={27} center>
          {inner}
        </Width>
      )
    }
    return <AllCenter>{inner}</AllCenter>
  }, [desktop])

  return (
    <OptionsLayout
      header={
        <OptionsNavigationBar rightPartText="Cancel" onRightPartClick={handleCancel}>
          &nbsp;
        </OptionsNavigationBar>
      }
      content={content}
      button={
        <Button onClick={handleDelete} mods={{ size: 'big block' }} data-test-id="deletePortfolioButton">
          Delete
        </Button>
      }
      center
      data-test-id="deletePortfolioModal"
    />
  )
}

export { DeletePortfolio, type DeletePortfolioProps }
