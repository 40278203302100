import React, { Fragment } from 'react'

import { useMemo } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Captcha } from 'components/organisms/Captcha/Captcha'

type CaptchaMobileProps = {
  action: 'enable-2fa' | 'disable-2fa'
  token: string
  handleBack: () => void
}

const Mobile = ({ action, token, handleBack }: CaptchaMobileProps): React.ReactElement => {
  const headerNode = useMemo(
    () => (
      <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
        {action === 'enable-2fa' ? 'Step 2 of 3' : 'Disable two-factor'}
      </NavigationBar>
    ),
    [action, handleBack],
  )

  const titleNode = useMemo(
    () => (
      <Paper top={16}>
        <Typography size={24} lineHeight="small" weight="semibold" align="center">
          <Typo>Security check</Typo>
        </Typography>
      </Paper>
    ),
    [],
  )

  const captchaNode = useMemo(
    () => (
      <Paper top={16}>
        <Captcha token={token} onSuccess={handleBack} />
      </Paper>
    ),
    [handleBack, token],
  )

  const contentNode = (
    <Fragment>
      {titleNode}
      {captchaNode}
    </Fragment>
  )

  return <MobileLayout header={headerNode} content={contentNode} />
}

export { Mobile }
