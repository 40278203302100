import React from 'react'

import { useMediaQueries } from 'hooks'

import { Mobile } from './Mobile'
import { Desktop } from './Desktop'

const SecuritySliceInfo = (props) => {
  const { desktop } = useMediaQueries()

  return desktop ? <Desktop {...props} /> : <Mobile {...props} />
}

export { SecuritySliceInfo }
