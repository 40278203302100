/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useCallback } from 'react'

import parseHTML from 'html-react-parser'
import escape from 'lodash/escape'
import PropTypes from 'prop-types'

import { sendErrorOnce } from 'helpers/errorLogging.js'
import { symbols, typo, sanitizeString } from 'helpers/typograph'

const cache = new Map()

type TypoProps = {
  children: React.ReactNode | React.ReactNodeArray
  multiline?: boolean
  noNbsp?: boolean
  allowHtml?: boolean
}

const Typo = ({ children, multiline, noNbsp, allowHtml = true }: TypoProps): React.ReactElement | null => {
  if (!children) {
    return null
  }

  if (typeof children === 'string') {
    const cacheItem = cache.get(children + multiline)

    if (cacheItem) {
      return cacheItem
    }

    const spaceBeforeLastShortWord = /\s+(?=\S{0,4}$)/

    children = children.replace(spaceBeforeLastShortWord, symbols.nbsp)
  }

  // eslint-disable-next-line  react-hooks/rules-of-hooks
  const format = useCallback(
    (string = '') => {
      try {
        if (!allowHtml) {
          string = escape(string)
        }

        string = sanitizeString(string)

        let typedText = string.replace(/\n/gm, multiline ? '<br />' : '')
        typedText = typo(typedText)

        if (noNbsp) {
          const regexp = new RegExp(symbols.nbsp, 'g')
          typedText = typedText.replace(regexp, ' ')
        }

        return parseHTML(typedText)
      } catch (error) {
        sendErrorOnce(new Error(`Error in ‘Typo’ component at ‘format’`))

        return string
      }
    },
    [allowHtml, multiline, noNbsp],
  )

  // eslint-disable-next-line  react-hooks/rules-of-hooks
  const parse = useCallback(
    (children) => {
      return React.Children.map(children, (child) => {
        if (child === null || typeof child === 'number') {
          return child
        }

        if (typeof child === 'string') {
          return format(child)
        }

        const config: { dangerouslySetInnerHTML?: { __html?: string } } = {}

        if (child.props.dangerouslySetInnerHTML) {
          if (!allowHtml && child.props.dangerouslySetInnerHTML?.__html) {
            child.props.dangerouslySetInnerHTML.__html = escape(child.props.dangerouslySetInnerHTML.__html)
          }

          config.dangerouslySetInnerHTML = {
            __html: typo(sanitizeString(child.props.dangerouslySetInnerHTML?.__html)),
          }
        }

        return React.cloneElement(child, config, parse(child.props.children))
      })
    },
    [format, allowHtml],
  )

  // eslint-disable-next-line  react-hooks/rules-of-hooks
  const insertLineBreak = useCallback((children) => {
    return React.Children.toArray(children).reduce(
      (acc: React.ReactChild[], child: React.ReactChild, index: number) => {
        if (index !== 0) {
          acc.push(<br key={index} />)
        }

        acc.push(child)

        return acc
      },
      [],
    )
  }, [])

  const nodes = multiline ? parse(insertLineBreak(children)) : parse(children)

  if (typeof children === 'string') {
    cache.set(children + multiline, nodes)
  }

  return nodes
}

Typo.propTypes = {
  children: PropTypes.node,
  multiline: PropTypes.bool,
  noNbsp: PropTypes.bool,
}

export { Typo }
