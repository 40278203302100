import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { palette } from 'helpers/palette'
import { propTypes } from 'helpers/propTypes'

import { useCallback, useMemo, useState } from 'hooks'

import { MobileLayout } from 'components/atoms/Layouts'
import { AnimatedNavigationBar } from 'components/molecules/AnimatedNavigationBar'
import { Paper } from 'components/atoms/Paper'
import { SecurityOrder } from 'components/organisms/SecurityOrder/SecurityOrder.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Notice } from 'app/pages/Dashboard/Securities/SecurityOrdersPage/components/Notice'
import Button from 'components/_old/Button/Button.jsx'

import { PendingOrderList } from 'app/effector/pending-orders/models'
import { SecurityList } from 'app/effector/securities/models'

const Mobile = ({
  orders = [],
  isRebalance,
  canBeCanceled,
  isTradingWindow,
  securities,
  onBack: handleBack,
  onCancelOrders: handleCancelOrders,
  onContactUs: handleContactUs,
}) => {
  const [titleNode, setTitleNode] = useState(null)
  const titleRef = useCallback((ref) => setTitleNode(ref), [])
  const title = useMemo(() => <Typo>{isRebalance ? 'Rebalance in progress' : 'Orders placed'}</Typo>, [isRebalance])

  const header = useMemo(
    () => (
      <AnimatedNavigationBar nodeToWatchTo={titleNode} leftPartText="Back" onLeftPartClick={handleBack}>
        {title}
      </AnimatedNavigationBar>
    ),
    [titleNode, title, handleBack],
  )

  const ordersNode = useMemo(
    () =>
      orders.map((order, index) => (
        <Paper key={order.id} top={index === 0 ? 48 : 56}>
          <SecurityOrder order={order} securities={securities} />
        </Paper>
      )),
    [orders, securities],
  )

  const cancelButton = useMemo(
    () =>
      canBeCanceled ? (
        <Paper
          top={isTradingWindow ? 12 : 16}
          bottom={isTradingWindow ? 12 : 16}
          left={16}
          right={16}
          style={{ background: palette['background-default'] }}
        >
          <Button
            mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
            disabled={isTradingWindow}
            onClick={handleCancelOrders}
            data-test-id="cancelOrdersButton"
          >
            {isRebalance ? 'Cancel this rebalance' : 'Cancel all pending orders'}
          </Button>
          {isTradingWindow ? (
            <Paper top={12}>
              <Typography size={14} align="center">
                <Typo>
                  Orders cannot be cancelled between
                  <br />
                  2pm and 7pm daily.
                </Typo>
              </Typography>
            </Paper>
          ) : null}
        </Paper>
      ) : null,
    [isRebalance, canBeCanceled, isTradingWindow, handleCancelOrders],
  )

  const content = useMemo(() => {
    if (orders.length < 1) {
      return (
        <AllCenter>
          <Typography align="center">
            <Typo>There are no pending orders</Typo>
          </Typography>
        </AllCenter>
      )
    }

    return (
      <Fragment>
        <Typography ref={titleRef} size={32} lineHeight="small" weight="semibold">
          {title}
        </Typography>
        <Notice onContactUsClick={handleContactUs} isRebalance={isRebalance} />
        <Paper bottom={40}>{ordersNode}</Paper>
      </Fragment>
    )
  }, [orders, ordersNode])

  return <MobileLayout header={header} content={content} footer={cancelButton} />
}

Mobile.propTypes = {
  orders: propTypes.instanceOf(PendingOrderList),
  isRebalance: PropTypes.bool,
  canBeCanceled: PropTypes.bool,
  isTradingWindow: PropTypes.bool,
  securities: propTypes.instanceOf(SecurityList),
  onBack: PropTypes.func,
  onCancelOrders: PropTypes.func,
  onContactUs: PropTypes.func,
}

export { Mobile }
