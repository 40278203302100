import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { AttentionModal } from '../components/AttentionModal'

import { EditIncomePortfolio } from './EditIncomePortfolio/EditIncomePortfolio'
import { useIncomeProjections } from './hooks'

import type { ManagedPortfolio } from '../../../types'

type IncomeProjectionsProps = {
  portfolio: ManagedPortfolio
  location: {
    query?: {
      editablePortfolioFields?: string
    }
  }
}

const IncomeProjections = withRouter(
  ({
    portfolio,
    location: { query: { editablePortfolioFields: editablePortfolioFieldsString } = {} },
  }: IncomeProjectionsProps): React.ReactElement => {
    const {
      isLoading,
      validation,
      clientRegistered,
      presets,
      presetIndex,
      recommendedIndex,
      projectionsData,
      isGoalActive,
      isGoalLocked,
      editableGoal,
      isGoalChanged,
      shouldShowScrollToKey,
      isPresetChanged,
      setShouldShowScrollToKey,
      initialDepositMin,
      visibleAttentionModal,
      handleGoalChange,
      handleSubmit,
      handleCloseAttantionModal,
    } = useIncomeProjections({ editablePortfolioFieldsString, portfolio })

    return (
      <Fragment>
        <EditIncomePortfolio
          isLoading={isLoading}
          isGoalLocked={isGoalLocked}
          isGoalChanged={isGoalChanged}
          projectionsData={projectionsData}
          goal={editableGoal}
          presets={presets}
          isActiveGoal={isGoalActive}
          initialDepositMin={initialDepositMin}
          validation={validation}
          onChange={handleGoalChange}
          handleSubmit={handleSubmit}
          shouldShowScrollToKey={shouldShowScrollToKey}
          setShouldShowScrollToKey={setShouldShowScrollToKey}
        />
        <AttentionModal
          visible={visibleAttentionModal}
          desiredIndex={presetIndex}
          recommendedIndex={recommendedIndex}
          portfolioChanged={isPresetChanged && clientRegistered}
          onSubmit={handleSubmit}
          onClose={handleCloseAttantionModal}
        />
      </Fragment>
    )
  },
)

export { IncomeProjections }
