import React from 'react'
import PropTypes from 'prop-types'

import { useCallback, useSelector } from 'hooks'
import { useUnit } from 'effector-react'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'
import { hardCodedCountries } from 'helpers/hardCodedCounties'

import ProvideTaxInfoSetupForm from './ProvideTaxInfoSetupForm.jsx'

import { regulatoryTypes as goalRegulatoryTypes } from 'constants/goal'

import { $owner, $error, resetError } from 'app/effector/contacts'

const ProvideTaxInfoSetup = ({ changeOwnerField, updateOwner, handleTaxInfoUpdate, location }) => {
  const owner = useUnit($owner) ?? {}
  const error = useUnit($error)

  const goals = useSelector((state) => state.portfolios.items)
  const countries = hardCodedCountries // useSelector((state) => state.dicts.countries)

  const handleSubmit = useCallback(
    async (fields) => {
      const keys = Object.keys(fields)

      keys.forEach((key) => {
        changeOwnerField(key, fields[key])
      })

      const result = await updateOwner(keys, null, false)

      if (!result?.result) {
        return processError({ error: result?.error, forceReset: true })
      }

      trackEvent(
        {
          action: 'tax_info_submitted',
          client_type: 'INDIVIDUAL',
        },
        { plugins: { 'google-analytics-v3': false } },
      )

      handleTaxInfoUpdate()
    },
    [updateOwner, handleTaxInfoUpdate, changeOwnerField],
  )

  return (
    <ProvideTaxInfoSetupForm
      owner={owner}
      error={error}
      isOpeningSipp={location?.query?.isOpeningSipp}
      hasISAPortfolio={goals.some((goal) => goal.regulatory_type === goalRegulatoryTypes.ISA)}
      countries={countries}
      onSubmit={handleSubmit}
      resetError={resetError}
    />
  )
}

ProvideTaxInfoSetup.propTypes = {
  changeOwnerField: PropTypes.func,
  updateOwner: PropTypes.func,
  handleTaxInfoUpdate: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      isOpeningSipp: PropTypes.string,
    }),
  }),
}

export { ProvideTaxInfoSetup }
