import { Entity } from 'app/redux/models/common/Entity.js'

import { SecurityStatsItem } from './SecurityStatsItem.js'

/**
 * @class
 * @typedef {Object} SecurityStatsCollection
 */
export class SecurityStatsCollection extends Entity {
  constructor() {
    super()

    /** @type {SecurityStatsItem} */
    this.week = SecurityStatsItem.createFromObject({})
    /** @type {SecurityStatsItem} */
    this.month = SecurityStatsItem.createFromObject({})
    /** @type {SecurityStatsItem} */
    this.six_months = SecurityStatsItem.createFromObject({})
    /** @type {SecurityStatsItem} */
    this.year = SecurityStatsItem.createFromObject({})
    /** @type {SecurityStatsItem} */
    this.max = SecurityStatsItem.createFromObject({})
  }

  /** @override */
  getRules() {
    return {
      week: [[(item) => SecurityStatsItem.createFromObject(item)]],
      month: [[(item) => SecurityStatsItem.createFromObject(item)]],
      six_months: [[(item) => SecurityStatsItem.createFromObject(item)]],
      year: [[(item) => SecurityStatsItem.createFromObject(item)]],
      max: [[(item) => SecurityStatsItem.createFromObject(item)]],
    }
  }
}
