import { useActions, useCallback, useSelector } from 'hooks'

import { identify, analyticsCIO } from 'helpers/analytics'
import { format as formatMoney } from 'helpers/money'

import { fetchBankAccountsFx } from 'app/effector/bank-accounts'
import { fetchContactsFx } from 'app/effector/contacts'

import { fetchClient as fetchClientActionCreator } from 'app/redux/actions/client'
import {
  fetchHistoryForAllGoals as fetchHistoryForAllGoalsActionCreator,
  setValid as setGoalValidActionCreator,
  fetchPortfolios as fetchPortfoliosActionCreator,
} from 'app/redux/actions/portfolios'

const useRefresh = () => {
  const client = useSelector((state) => state.client)
  const { id: clientId, access_token: accessToken } = client
  const [fetchClient, fetchHistoryForAllGoals, fetchPortfolios, setGoalValid] = useActions([
    fetchClientActionCreator,
    fetchHistoryForAllGoalsActionCreator,
    fetchPortfoliosActionCreator,
    setGoalValidActionCreator,
  ])

  const fetchPortfoliosWithDependencies = useCallback(async () => {
    const stateAfterFetch = await fetchPortfolios({ setValidAfter: false })
    setGoalValid()

    const currentBalance = stateAfterFetch.portfolios.items.reduce((acc, goal) => acc + Number(goal.current_balance), 0)
    identify(clientId, { clientId, clientBalance: formatMoney(currentBalance, true) })

    if (client.email) {
      analyticsCIO?.identify({
        id: client.email,
      })
    }
  }, [clientId, fetchPortfolios, setGoalValid, client.email])

  return useCallback(
    async () =>
      // eslint-disable-next-line
      await new Promise(async (resolve, reject) => {
        if (accessToken) {
          const promises = [
            fetchClient(),
            fetchContactsFx(),
            fetchPortfoliosWithDependencies(),
            fetchHistoryForAllGoals(),
            fetchBankAccountsFx(),
          ]

          await Promise.all(promises).then(resolve, reject)
          return
        }

        resolve()
      }),
    [accessToken, fetchClient, fetchPortfoliosWithDependencies, fetchHistoryForAllGoals],
  )
}

export { useRefresh }
