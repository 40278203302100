import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PresetSubtitle } from './PresetSubtitle'

type PresetTitleProps = {
  title: string
  align: string
}

const PresetTitle = ({ title, align = 'center' }: PresetTitleProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <Typography
        size={desktop ? 24 : 16}
        lineHeight="small"
        weight="semibold"
        align={align}
        data-test-id="lifePlanPresetTitle"
      >
        <Typo>{title}</Typo>
      </Typography>
      {desktop && (
        <Paper top={8}>
          <PresetSubtitle />
        </Paper>
      )}
    </Fragment>
  )
}

export { PresetTitle }
