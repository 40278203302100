import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'

import { SelectableCard } from 'components/atoms/SelectableCard'
import { Paper } from 'components/atoms/Paper'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import Link from 'components/_old/Link/Link.jsx'

import { manageTypes } from 'constants/portfolio'

export * from './InstantBankTransfer.css'

const InstantBankTransfer = ({ selected, howItWorksUrl, portfolio, onClick: handleClick, hasBankAccounts }) => {
  const { desktop } = useMediaQueries()

  const descriptionText = useMemo(() => {
    if (!portfolio) return 'Fund your account'
    if ([manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(portfolio.manage_type)) return 'Fund your portfolio'
    return 'Add cash'
  }, [portfolio])

  return (
    <SelectableCard selected={selected} onClick={handleClick} data-test-id="setUpNewBankTransfer">
      <Paper top={24} bottom={24} right={desktop ? 24 : 20} left={desktop ? 24 : 20}>
        <ItemWithIcon
          iconPosition="right"
          icon={<Icon type="instantBankTransfer" size={44} />}
          content={
            <div>
              <Paper bottom={8}>
                <Typography size={desktop ? 16 : 18} weight="semibold" lineHeight="small">
                  <Typo>{hasBankAccounts ? 'Link another account' : 'Instant bank transfer'}</Typo>
                </Typography>
              </Paper>
              <Typography color="minor" size={14}>
                <Typo>{descriptionText} in seconds using Open Banking. From an account in your own name.</Typo>
              </Typography>
              <Paper top={12}>
                <Typography size={14} lineHeight="small" data-test-id="BankTransferHowItWorks">
                  <Link
                    className="InstantBankTransfer-HowItWorksLink"
                    to={howItWorksUrl}
                    scrollToTop={false}
                    stopPropagation={true}
                  >
                    <Typo>How Open Banking works</Typo>
                    <Icon
                      className="InstantBankTransfer-HowItWorksIcon"
                      type="questionInCircle"
                      color="inherit"
                      size={18}
                      inline
                    />
                  </Link>
                </Typography>
              </Paper>
            </div>
          }
        />
      </Paper>
    </SelectableCard>
  )
}

InstantBankTransfer.propTypes = {
  selected: PropTypes.bool.isRequired,
  howItWorksUrl: PropTypes.string.isRequired,
  portfolio: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  hasBankAccounts: PropTypes.bool.isRequired,
}

export { InstantBankTransfer }
