import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

import type { DictsOptions } from '../types'

const fetchOptions = async (): Promise<DictsOptions | ApiError> => {
  try {
    const { data } = await axios.get('dicts/options')

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { fetchOptions }
