import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { Paper } from 'components/atoms/Paper'

import './BottomTools.css'

const BottomTools = ({ className, children, withSafeArea = true }) => {
  const bottomTollsClasses = classNames(className, 'BottomTools', {
    BottomTools_withSafeArea: withSafeArea,
  })

  return (
    <Paper className={bottomTollsClasses} top={16} right={16} left={16}>
      <Paper bottom={16}>{children}</Paper>
    </Paper>
  )
}

BottomTools.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  withSafeArea: PropTypes.bool,
}

export { BottomTools }
