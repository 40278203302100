import React from 'react'

import { useMediaQueries } from 'hooks/'

import { format } from 'helpers/money/'

import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/'

import { Badge } from 'components/atoms/Badge/'
import { Paper } from 'components/atoms/Paper/'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography/'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type IsaTitleProps = {
  remainingAllowance: number
  isIsaAccountOpened: boolean
  skeleton?: boolean
  title?: React.ReactNode
}

function IsaTitle({
  remainingAllowance,
  isIsaAccountOpened,
  title,
  skeleton = false,
}: IsaTitleProps): React.ReactElement {
  const { desktop } = useMediaQueries()

  return (
    <Paper>
      <ColumnarLayout mods={{ 'align-columns': 'center', padding: 0 }}>
        <Column size={1}>
          <Skeleton shown={skeleton} shownProps={{ style: { alignSelf: 'flex-start' } }} inline mix>
            <Paper>
              <Typography
                tag="h2"
                size={desktop ? 18 : 16}
                lineHeight="small"
                weight="semibold"
                data-test-id="groupTitleText"
                className="Text_Not_selectable"
                inline={desktop}
              >
                {title ?? <Typo>ISA</Typo>}
              </Typography>
            </Paper>
          </Skeleton>
        </Column>
        {isIsaAccountOpened && (
          <Column size={0}>
            <Skeleton shown={skeleton} mix>
              <Badge
                text={
                  <Paper top={1} right={8} bottom={1} left={8}>
                    <Nobr>
                      <Typography color="default" size={desktop ? 16 : 14} data-test-id="isaRemainingAllowance">
                        Remaining allowance: {skeleton ? format(12345) : format(remainingAllowance)}
                      </Typography>
                    </Nobr>
                  </Paper>
                }
              />
            </Skeleton>
          </Column>
        )}
      </ColumnarLayout>
    </Paper>
  )
}

export { IsaTitle, type IsaTitleProps }
