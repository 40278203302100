import React from 'react'

import { useMediaQueries } from 'hooks'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type HeadlineProps = {
  backLink?: string | null
}

function Headline({ backLink }: HeadlineProps): React.ReactElement {
  const { desktop } = useMediaQueries()

  const headline = 'Application to transfer existing ISA to InvestEngine'

  if (desktop) {
    return (
      <ColumnarLayout>
        <Column size={5}>
          <Paper top={8}>
            <Link to={backLink} mods={{ color: 'black' }}>
              <ItemWithIcon
                space={16}
                icon={<Icon type="back-new" size={24} color="inherit" />}
                content={
                  <Typography size={16} lineHeight="small" color="inherit">
                    <Typo>Back</Typo>
                  </Typography>
                }
                iconPosition="center"
                contentVerticalAlign="center"
              />
            </Link>
          </Paper>
        </Column>
        <Column size={22}>
          <Paper left={8}>
            <Width size={45}>
              <Typography size={32} lineHeight="small" weight="semibold">
                <Typo>{headline}</Typo>
              </Typography>
            </Width>
          </Paper>
        </Column>
      </ColumnarLayout>
    )
  }

  return (
    <Typography size={24} weight="semibold">
      <Typo>{headline}</Typo>
    </Typography>
  )
}

export { Headline, type HeadlineProps }
