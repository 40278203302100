import React from 'react'
import PropTypes from 'prop-types'

import { useCallback, useState, useContext, useEffect, useActions } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { bankAccountStates } from 'constants/bankAccounts.js'

import { ExecutingError } from 'app/pages/Dashboard/Goals/components/BankAccount/ExecutingError.jsx'

import { Loading, Failing, Executing } from './states'

import { getStandingOrderInfo } from 'app/effector/bank-accounts/api'
import { changeModalField as changeModalFieldActionCreator } from 'app/redux/actions/ui'

import { ApiError } from 'app/redux/models/errors'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'
import { useRequestDataWithInterval } from 'app/pages/Dashboard/Goals/hooks'

import { requestStates } from './requestStates.js'

const StandingOrder = ({ goal, location }) => {
  const { id } = location.query
  const { tunnelQuery } = useContext(ChangeGoalTunnelContext)
  const [paymentInfo, setPaymentInfo] = useState({})
  const [changeModalField] = useActions([changeModalFieldActionCreator])

  const redirectToDashboard = useCallback(() => {
    goTo(urlTo('dashboard.portfolio', { id: goal.id }, tunnelQuery), { replace: true, scrollToTop: false })
  }, [tunnelQuery, goal.id])

  useEffect(() => {
    if (!id) {
      redirectToDashboard()
    }
  }, [])

  const handleRequestStatus = useCallback(async () => {
    if (!id) {
      return
    }
    const paymentInfo = await getStandingOrderInfo(id)

    if (paymentInfo instanceof ApiError) {
      throw paymentInfo
    }

    return () => {
      setPaymentInfo(paymentInfo)
      changeModalField('directDebit', { monthlyPaymentAmount: paymentInfo.amount })
      changeModalField('directDebit', { monthlyPaymentDay: paymentInfo.day_of_month })
    }
  }, [id])

  useRequestDataWithInterval(
    handleRequestStatus,
    redirectToDashboard,
    [requestStates.EXECUTED, requestStates.FAILED, requestStates.CANCELLED].includes(paymentInfo.state),
  )

  if (paymentInfo.state === requestStates.EXECUTED) {
    return paymentInfo.bank_account_state === bankAccountStates.APPROVED ? (
      <Executing onContinue={redirectToDashboard} />
    ) : (
      <ExecutingError
        title="Your standing order is still being set up. Please bear with us."
        onContinue={redirectToDashboard}
      />
    )
  }
  if ([requestStates.CANCELLED, requestStates.FAILED].includes(paymentInfo.state)) {
    return <Failing onContinue={redirectToDashboard} />
  }

  return <Loading />
}

StandingOrder.propTypes = {
  goal: PropTypes.object.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
}

export { StandingOrder }
