import React, { Fragment } from 'react'

import Inner from 'components/_old/Inner/Inner.jsx'

import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { ReportFilters } from '../../components/ReportFilters'
import { Reports } from '../../components/Reports'
import { ReportsHeader, ReportHeaderTabs } from '../../components/ReportsHeader'
import { type ReportListChildProps } from '../ReportList'

const Desktop = (props: ReportListChildProps): React.ReactElement => {
  return (
    <DesktopLayout
      header={<DesktopHeader />}
      content={
        <Fragment>
          <Inner mods={{ height: 'full' }}>
            <Paper top={40}>
              <ReportsHeader activeTabName={ReportHeaderTabs.REPORTS} />
            </Paper>
            <Paper top={props.hasReports ? 56 : 120} bottom={120} flex>
              <ReportFilters {...props} />
              <Paper top={40}>
                <Reports {...props} />
              </Paper>
            </Paper>
          </Inner>
        </Fragment>
      }
      footer={<DesktopFooter />}
      noGaps
      fullSize
    />
  )
}

export { Desktop }
