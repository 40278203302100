import sha1 from 'sha1'

import { types as clientTypes } from 'constants/client'
import { manageTypes, states } from 'constants/portfolio'

const IMPACT_EVENTS = {
  SIGN_UP: 25211,
  SAVE_PORTFOLIO: 25212,
  COMPLETE_REGISTRATION: 25213,
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class Impact {
  static identify = ({ reference, email }) => {
    if (window?.ire) {
      window.ire('identify', {
        customerId: reference || '',
        customerEmail: email ? sha1(email) : '',
      })
    }
  }

  static track = (event, client, orderId, additional = {}) => {
    if (window?.ire) {
      const clientType = client.type === clientTypes.INDIVIDUAL ? 'Personal' : 'Business'

      window.ire(
        'trackConversion',
        event,
        {
          customerId: client.reference,
          customerEmail: client.email ? sha1(client.email) : '',
          orderId,
          text1: clientType,
          ...additional,
        },
        {
          verifySiteDefinitionMatch: true,
        },
      )
    }
  }

  static trackSignUp = (client) => {
    Impact.track(IMPACT_EVENTS.SIGN_UP, client, client.reference)
  }

  static trackManagedRegistration = (client, portfolio, presets) => {
    Impact.track(IMPACT_EVENTS.COMPLETE_REGISTRATION, client, client.reference, {
      items: [
        {
          subTotal: 0,
          category: portfolio.regulatory_type,
          subCategory: presets.find((preset) => preset.id === portfolio.preset)?.title,
          sku: 'Registration',
          quantity: 1,
        },
      ],
    })
  }

  static trackDIYRegistration = (client, portfolio) => {
    Impact.track(IMPACT_EVENTS.COMPLETE_REGISTRATION, client, client.reference, {
      items: [
        {
          subTotal: 0,
          category: portfolio.regulatory_type,
          subCategory: 'DIY',
          sku: 'Registration',
          quantity: 1,
        },
      ],
    })
  }

  static trackRegistration = (client, portfolios) => {
    const { presets } = portfolios
    const visibleStates = [states.COMPLETED, states.APPROVED, states.CLOSING, states.CLOSED]
    const visiblePortfolios =
      portfolios.items?.filter(
        (portfolio) => portfolio.manage_type !== manageTypes.CASH && visibleStates.includes(portfolio.state),
      ) || []

    if (visiblePortfolios.length > 0) {
      const portfolio = visiblePortfolios[0]

      if (portfolio.manage_type === manageTypes.DIY) {
        Impact.trackDIYRegistration(client, portfolio)
        return
      }

      Impact.trackManagedRegistration(client, portfolio, presets[portfolio.preset_type])
      return
    }

    Impact.track(IMPACT_EVENTS.COMPLETE_REGISTRATION, client, client.reference, {
      items: [
        {
          subTotal: 0,
          sku: 'Registration',
          quantity: 1,
        },
      ],
    })
  }

  static trackSavingPortfolio = (client, portfolio, presetTitle = 'DIY') => {
    const subCategory = presetTitle

    Impact.track(IMPACT_EVENTS.SAVE_PORTFOLIO, client, portfolio.reference, {
      items: [
        {
          subTotal: 0,
          category: portfolio.regulatory_type,
          subCategory,
          sku: 'Portfolio',
          quantity: 1,
        },
      ],
    })
  }
}

export { Impact }
