import React, { Fragment } from 'react'

import { useMemo, useRef, useState } from 'hooks'
import { SECURITY_VERSION } from '../../hooks/'

import { Typo } from 'components/_old/Typo/Typo'
import Link from 'components/_old/Link/Link.jsx'
import Width from 'components/_old/Width/Width'
import Button from 'components/_old/Button/Button.jsx'
import { Card } from 'components/atoms/Card'
import { Stack } from 'components/atoms/Stack'
import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { SearchField } from 'components/molecules/SearchField'
import { SecurityTitle } from 'components/molecules/SecurityTitle'
import { PriceInformation } from 'components/molecules/PriceInformation'
import { PortfolioCard } from 'components/molecules/PortfolioCard/PortfolioCard'
import { AnimatedNavigationBar } from 'components/molecules/AnimatedNavigationBar'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Filter } from 'components/organisms/Filter'
import { SecurityChart } from 'components/organisms/SecurityChart'
import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'
import { FilterGroup } from 'components/organisms/Filter/FilterGroup'
import { SecurityDetails } from '../../components/SecurityDetails'

import {
  SecurityDescription,
  SecurityDocuments,
  LatestMarketPriceNote,
  SecurityCollections,
} from 'app/pages/Dashboard/Goals/DIY/SecuritySlice/components'

import { BottomTools } from '../../../SecurityUniverse/Mobile/components/BottomTools'
import { LabelsDescription } from '../../components'

import { BasePortfolio } from 'app/redux/models/portfolio/Portfolio/BasePortfolio/BasePortfolio'
import { SecurityChartTabs } from '../../components/SecurityChartTabs'

const Mobile = ({
  version,
  isAnalyticsLoading,
  isDetailedSecurityInfoLoading,
  isOtherPortfoliosSecuritiesLoading,
  security,
  isRemovableSecurity,
  portfoliosWithThisSecurity,
  isAddedToCurrentPortfolio,
  periodPriceData,
  chartData,
  totalReturnsData,
  selectedPeriod,
  holdings,
  sectors,
  regions,
  filters,
  searchValue,
  showInvestNowButton,
  isFromPreset,
  handleSearch,
  goToSecurityAnalytics,
  handleRemoveSecurity,
  handlePortfoliosModalOpen,
  handlePeriodSelect,
  handleAddSecurity,
  handleBack,
  handleCancel,
  handleInvestNow,
  sendGaEventOnAddToPortfolioClick,
}) => {
  const securityTitleRef = useRef()
  const isAnalyticsVisible = useMemo(() => security?.type !== 'BOND' && security?.type !== 'ALTERNATIVE', [security])
  const analytics = { holdings, sectors, regions }

  const isDistributing = useMemo(() => security?.dividends_type === 'DISTRIBUTING', [security])

  const [activeTab, setActiveTab] = useState('holdings')
  const tabs = useMemo(
    () => [
      { title: 'Holdings', id: 'holdings', isActive: activeTab === 'holdings', withSearch: handleSearch },
      { title: 'Regions', id: 'regions', isActive: activeTab === 'regions' },
      { title: 'Sectors', id: 'sectors', isActive: activeTab === 'sectors' },
    ],
    [activeTab, handleSearch],
  )
  const activeTabObject = useMemo(() => tabs.find((tab) => tab.isActive) ?? {}, [activeTab])
  const [activeChartTab, setActiveChartTab] = useState(isDistributing ? 'totalReturns' : 'priceReturns')

  const isListFiltered = !!filters.regions.value.length || !!filters.sectors.value.length || !!searchValue

  const navigationBar = useMemo(() => {
    return (
      <AnimatedNavigationBar
        nodeToWatchTo={securityTitleRef?.current?.title}
        leftPartText="Back"
        onLeftPartClick={handleBack}
        rightPartText="Cancel"
        onRightPartClick={handleCancel}
      >
        {security.title}
      </AnimatedNavigationBar>
    )
  }, [securityTitleRef?.current?.title, security.title, handleBack, handleCancel])

  const header = useMemo(() => {
    return navigationBar
  }, [navigationBar])

  const portfoliosList = useMemo(() => {
    if (isOtherPortfoliosSecuritiesLoading && portfoliosWithThisSecurity.length < 1) {
      return (
        <Paper top={40}>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Skeleton tag="span" shown inline>
              <Typo>In your portfolios</Typo>
            </Skeleton>
          </Typography>
          <Paper top={24}>
            <Skeleton shown>
              <PortfolioCard
                portfolio={BasePortfolio.createFromObject({ title: '███████' })}
                security={security}
                noType
              />
            </Skeleton>
          </Paper>
        </Paper>
      )
    }

    return (
      portfoliosWithThisSecurity.length > 0 && (
        <Paper top={40}>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Typo>In your portfolios</Typo>
          </Typography>
          {portfoliosWithThisSecurity.map((portfolio, index) => (
            <Paper top={index === 0 ? 24 : 16} key={portfolio.id}>
              <PortfolioCard portfolio={portfolio} security={security} noType />
            </Paper>
          ))}
        </Paper>
      )
    )
  }, [isOtherPortfoliosSecuritiesLoading, portfoliosWithThisSecurity, security])

  const currentPriceData = useMemo(() => {
    return activeChartTab === 'totalReturns'
      ? { ...periodPriceData, ...totalReturnsData.stats[selectedPeriod] }
      : periodPriceData
  }, [activeChartTab, periodPriceData, totalReturnsData, selectedPeriod])

  const latestPrice = useMemo(() => {
    const securitySharePrice = security.share_price || 0

    return (
      <Paper top={32}>
        <Typography size={32} weight="semibold" lineHeight="small">
          <Skeleton shown={isDetailedSecurityInfoLoading && securitySharePrice === 0} mix inline>
            <PoundsWithPence amount={security?.share_price ?? 0} showZeroPence />
          </Skeleton>
        </Typography>
        <Paper top={4} bottom={32}>
          <Skeleton shown={isDetailedSecurityInfoLoading && !security?.price_dt} inline mix>
            <LatestMarketPriceNote date={security?.price_dt} />
          </Skeleton>
        </Paper>
      </Paper>
    )
  }, [isDetailedSecurityInfoLoading, security?.price_dt, security.share_price])

  const priceInformation = useMemo(() => {
    return (
      <Fragment>
        {currentPriceData && (
          <PriceInformation
            price={security?.share_price ?? 0}
            period={currentPriceData}
            description={
              <Typo>
                Change in share price over time in pounds (£) as well as percentage terms.
                <br />
                <br />
                Note this will {activeChartTab === 'totalReturns' ? '' : 'not'} include any dividend income paid out
                from this security over the period.
              </Typo>
            }
          />
        )}
      </Fragment>
    )
  }, [security?.share_price, activeChartTab, currentPriceData])

  const chartTabs = useMemo(
    () => isDistributing && <SecurityChartTabs activeChartTab={activeChartTab} setActiveChartTab={setActiveChartTab} />,
    [activeChartTab, isDistributing],
  )

  const currentChartData = useMemo(() => {
    return activeChartTab === 'totalReturns' ? totalReturnsData.history[selectedPeriod] : chartData
  }, [activeChartTab, chartData, totalReturnsData, selectedPeriod])

  const chart = useMemo(
    () => (
      <Paper top={32}>
        <Skeleton shown={isDetailedSecurityInfoLoading && currentChartData.length < 1}>
          <SecurityChart
            period={selectedPeriod}
            data={currentChartData}
            periodPriceData={currentPriceData}
            handlePeriodSelect={handlePeriodSelect}
            topContent={priceInformation}
            topContentOffset={isDistributing ? 24 : 40}
          />
        </Skeleton>
      </Paper>
    ),
    [
      isDetailedSecurityInfoLoading,
      selectedPeriod,
      handlePeriodSelect,
      priceInformation,
      currentChartData,
      currentPriceData,
      isDistributing,
    ],
  )

  const analyticsListNode = useMemo(() => {
    const { id } = activeTabObject
    const items = analytics?.[id]

    if (isAnalyticsLoading && items.length < 1) {
      return (
        <Paper top={24}>
          <Skeleton shown mix>
            <Stack vertical={32}>
              {new Array(5).fill({}).map((_, index) => (
                <SecurityConstituent
                  key={index}
                  name="██████"
                  actual={0}
                  color="transparent"
                  showLogo={id === 'holdings'}
                />
              ))}
              <Skeleton shown>
                <Button mods={{ theme: 'simple-reverse-blue', size: 'big block' }}>Show all</Button>
              </Skeleton>
            </Stack>
          </Skeleton>
        </Paper>
      )
    }

    if (id && items.length > 0) {
      const limitedList = analytics[id].slice(0, 5)

      return (
        <Fragment>
          <Paper top={24}>
            <Stack vertical={32}>
              {limitedList.map((item) => (
                <SecurityConstituent
                  key={item.name}
                  logo={item.logo}
                  name={item.name}
                  actual={item.actual}
                  color={item.color}
                  showLogo={id === 'holdings'}
                />
              ))}
              {analytics[id].length > 5 && (
                <Button
                  mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
                  onClick={() => goToSecurityAnalytics(id)}
                  data-test-id="showAllButton"
                >
                  Show all {analytics[id].length}
                </Button>
              )}
            </Stack>
          </Paper>
        </Fragment>
      )
    }

    return null
  }, [isAnalyticsLoading, goToSecurityAnalytics, activeTabObject, analytics])

  const analyticsNode = useMemo(() => {
    if (!isAnalyticsVisible) return null

    return (
      <Paper top={48}>
        <Fragment>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Typo>ETF Breakdown</Typo>
          </Typography>
          <Paper top={24}>
            <GreyTabs tabs={tabs} onChange={(tab) => setActiveTab(tab.id)} />
          </Paper>
          {activeTabObject?.withSearch && (
            <Fragment>
              <Paper top={24}>
                <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by holding" />
              </Paper>
              <Paper top={16}>
                <Skeleton shown={isAnalyticsLoading} mix>
                  <FilterGroup fitContent>
                    <Filter
                      name="Region"
                      type="checkbox"
                      values={filters.regions.list}
                      selected={filters.regions.value}
                      onChange={filters.regions.set}
                    />
                    <Filter
                      name="Sector"
                      type="checkbox"
                      values={filters.sectors.list}
                      selected={filters.sectors.value}
                      onChange={filters.sectors.set}
                    />
                  </FilterGroup>
                </Skeleton>
              </Paper>
              {!isAnalyticsLoading && isListFiltered && !analytics[activeTab]?.length && (
                <Paper top={32}>
                  <Typography size={14} lineHeight="medium" color="minor" data-test-id="noSearchResults">
                    <Typo>
                      No holdings found.
                      <br />
                      Please try a different search.
                    </Typo>
                  </Typography>
                </Paper>
              )}
            </Fragment>
          )}
          {analyticsListNode}
        </Fragment>
      </Paper>
    )
  }, [
    isAnalyticsLoading,
    searchValue,
    handleSearch,
    tabs,
    activeTabObject,
    analyticsListNode,
    activeTab,
    analytics,
    filters,
    isAnalyticsVisible,
    isListFiltered,
  ])

  const detailsNode = useMemo(
    () => (
      <Paper top={16}>
        <SecurityDetails security={security} />
      </Paper>
    ),
    [security],
  )

  const securityCollections = useMemo(
    () => (
      <Paper top={security?.collections?.length ? 48 : 0}>
        <SecurityCollections collections={security?.collections} areLinksDisabled={isFromPreset} />
      </Paper>
    ),
    [security?.collections],
  )

  const slowSecurityMention = useMemo(
    () => (
      <Paper top={32}>
        <Card level={0} color="primary-surface-25" borderWidth={2}>
          <Paper top={24} bottom={24} left={24} right={24}>
            <Typography size={14} weight="semibold">
              <Paper bottom={12}>
                <Typo>Please note</Typo>
              </Paper>
            </Typography>
            <Typography size={14} color="additional">
              <Typo>
                Buy and sell trades for this ETF may take longer to settle than the usual two business days (‘T+2’).{' '}
                <Link
                  to="https://help.investengine.com/hc/en-gb/articles/5021858224029-What-is-Settlement-and-how-long-does-it-take-"
                  blank
                  hard
                >
                  Read more here.
                </Link>
              </Typo>
            </Typography>
          </Paper>
        </Card>
      </Paper>
    ),
    [],
  )

  const removeSecurityButton = useMemo(() => {
    if (!isAddedToCurrentPortfolio || !isRemovableSecurity) return null

    return (
      <MobileLayoutFooterButton>
        <Typography size={16} align="center" lineHeight="small">
          <Link role="button" onClick={handleRemoveSecurity(security.id)}>
            <Typo>Remove from portfolio</Typo>
          </Link>
        </Typography>
      </MobileLayoutFooterButton>
    )
  }, [security.id, isAddedToCurrentPortfolio, isRemovableSecurity, handleRemoveSecurity])

  const content = useMemo(() => {
    const defaultContent = (
      <Fragment>
        <Paper>
          <SecurityTitle
            ref={securityTitleRef}
            logo={security.logo_uri}
            ticker={security.ticker}
            title={security.title}
            description={security.short_description}
            isFeatured={security.is_featured}
            lineHeight="small"
            size={24}
          />
        </Paper>
        {latestPrice}
        {chartTabs}
        {chart}
        {portfoliosList}
        {security.description?.length > 0 && (
          <Paper top={48}>
            <SecurityDescription description={security.description} />
          </Paper>
        )}
        {detailsNode}
        {securityCollections}
        {security.is_slow_to_settle && slowSecurityMention}
        {analyticsNode}
        {security.documents?.length > 0 && (
          <Paper top={48}>
            <Typography size={24} lineHeight="small" weight="semibold">
              <Typo>Key information</Typo>
            </Typography>
            <Paper top={24}>
              <SecurityDocuments documents={security.documents} />
            </Paper>
          </Paper>
        )}
      </Fragment>
    )

    return defaultContent
  }, [security, chart, portfoliosList, slowSecurityMention, analyticsNode, securityCollections, detailsNode, chartTabs])

  const button = useMemo(() => {
    const addToPortfolioAction =
      version === SECURITY_VERSION.INSIDE_PORTFOLIO ? handleAddSecurity : handlePortfoliosModalOpen
    const skeleton = isOtherPortfoliosSecuritiesLoading && portfoliosWithThisSecurity.length < 1

    if (isFromPreset) return null

    if (showInvestNowButton) {
      return (
        <MobileLayoutFooterButton>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={0}>
              <Width size={9.5}>
                <Card>
                  <Skeleton shown={skeleton} mix>
                    <Button
                      mods={{ size: 'big block' }}
                      onClick={handleInvestNow}
                      disabled={!security.is_visible_in_universe}
                    >
                      Invest now
                    </Button>
                  </Skeleton>
                </Card>
              </Width>
            </Column>
            <Column size={1}>
              <Paper left={8}>
                <Card color="background-default">
                  <Skeleton shown={skeleton} mix>
                    <Button
                      mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
                      onClick={() => {
                        sendGaEventOnAddToPortfolioClick()
                        addToPortfolioAction()
                      }}
                      disabled={!security.is_visible_in_universe}
                      data-test-id="securityAddToPortfolioButton"
                    >
                      Add to portfolio
                    </Button>
                  </Skeleton>
                </Card>
              </Paper>
            </Column>
          </ColumnarLayout>
        </MobileLayoutFooterButton>
      )
    }

    return (
      <MobileLayoutFooterButton>
        <Skeleton shown={skeleton} mix>
          <Button
            onClick={addToPortfolioAction}
            disabled={isAddedToCurrentPortfolio || !security.is_visible_in_universe}
            data-test-id="securityAddToPortfolioButton"
          >
            {isAddedToCurrentPortfolio ? 'Already in your portfolio' : 'Add to portfolio'}
          </Button>
        </Skeleton>
      </MobileLayoutFooterButton>
    )
  }, [
    version,
    isOtherPortfoliosSecuritiesLoading,
    portfoliosWithThisSecurity.length,
    isAddedToCurrentPortfolio,
    security.is_visible_in_universe,
    handleAddSecurity,
    handleInvestNow,
    handlePortfoliosModalOpen,
    showInvestNowButton,
    sendGaEventOnAddToPortfolioClick,
    isFromPreset,
  ])

  const footer = removeSecurityButton ? (
    <BottomTools>
      {button}
      {removeSecurityButton}
    </BottomTools>
  ) : (
    button
  )

  return <MobileLayout header={header} content={content} footer={footer} />
}

export { Mobile }
