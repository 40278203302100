import { createEffect, createStore, combine } from 'effector'

import { makeApiCall } from 'app/effector/api'

import * as api from './api/'
import { type CollectionsGroup, type Collection } from './types'

// Effects
const fetchCollectionGroupsFx = createEffect(async () => {
  try {
    const result = await makeApiCall(api.fetchEtfsCollections)

    return result
  } catch (error) {}
})

// Selectors
const findCollectionBySlugOrId = (
  collectionGroups: CollectionsGroup[],
  slugOrId: string | number,
): { collection?: Collection; group?: CollectionsGroup } => {
  let collection
  const group = collectionGroups.find((group) => {
    collection = group.collections.find(
      (collectionItem) => collectionItem.slug === slugOrId || collectionItem.id === parseInt(slugOrId as string, 10),
    )
    return !!collection
  })

  return { collection, group }
}

// Stores
const $collectionGroups = createStore<CollectionsGroup[]>([])
$collectionGroups.on(fetchCollectionGroupsFx.doneData, (state, result) => {
  if (!result) return state
  return result
})

const $areCollectionsFetched = createStore<boolean>(false)
$areCollectionsFetched.on(fetchCollectionGroupsFx.done, () => true)

const $isLoading = fetchCollectionGroupsFx.pending

const $collectionsStore = combine(
  [$collectionGroups, $areCollectionsFetched, $isLoading],
  ([collectionGroups, areCollectionsFetched, isLoading]) => ({
    collectionGroups,
    isLoading,
    areCollectionsFetched,
    getCollectionBySlugOrId: (slugOrId: string | number) => findCollectionBySlugOrId(collectionGroups, slugOrId),
  }),
)

export {
  // Effects
  fetchCollectionGroupsFx,
  // Store
  $collectionsStore,
  $collectionGroups,
}
