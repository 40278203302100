import React from 'react'
import classNames from 'classnames/dedupe'
import HorizontalChart from 'components/_old/HorizontalChart/HorizontalChart.jsx'

import './HorizontalChartGroup.css'
import { format } from 'helpers/money'

export default class HorizontalChartGroup extends React.Component {
  render() {
    const {
      className,
      style,
      data,
      onClickOnItem,
      selectedAsset,
      showOnlyAmount,
      showWeightOnly,
      center,
      columns,
      fluid,
      unhoverable,
      'data-test-id': dataTestId,
      'data-item-test-id': dataItemTestId,
    } = this.props

    const classes = classNames(className, 'HorizontalChartGroup', {
      HorizontalChartGroup_center: center,
      HorizontalChartGroup_columns: columns,
      HorizontalChartGroup_fluid: fluid,
    })

    const multiplier = 100 / data.maxAmount

    const largeTotalValueClm = !showOnlyAmount && format(data.maxTotalValue, true).length > 10

    return (
      <div className={classes} style={style} data-test-id={dataTestId}>
        {data.groupsOfAssets.map((item, i) => {
          return item.sumAmount > 0 && item.assets.length > 0 ? (
            <HorizontalChart
              className="HorizontalChartGroup-Chart"
              key={i}
              selectedAsset={selectedAsset}
              onClick={onClickOnItem}
              multiplier={multiplier}
              unhoverable={unhoverable}
              showOnlyAmount={showOnlyAmount}
              showWeightOnly={showWeightOnly}
              largeTotalValueClm={largeTotalValueClm}
              data-item-test-id={dataItemTestId}
              {...item}
            />
          ) : null
        })}
      </div>
    )
  }
}
