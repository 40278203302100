import { createStore, createEffect, createEvent, combine } from 'effector'
import pick from 'lodash/pick'

import { $contacts } from 'app/effector/contacts'

import { type Address, AddressList } from 'app/redux/models/address'

import * as api from './api'
import { combineContactsAndControls } from './helpers/combineContactsAndControls'
import { SignificantControlList, SignificantControl } from './models'

// Events
const changeField = createEvent<{ id: number; key: string; value: any }>()
const changeAddressField = createEvent<{ id: number; addressIndex: number; key: string; value: any }>()
const resetSignificantControls = createEvent()

// Effects
const fetchSignificantControlsFx = createEffect(async () => {
  const result = await api.fetchSignificantControls()

  return result
})

const deleteSignificantControlsFx = createEffect(async ({ id }: { id: number }) => {
  await api.deleteSignificantControls(id)
})

const updateOrCreateSignificantControlFx = createEffect(async ({ keys, id, data }) => {
  const rawSignificantControl = data

  const significantControl = keys ? pick(rawSignificantControl, keys) : rawSignificantControl

  let addressesAfterUpdate
  let result

  let addresses
  let onlyAddresses = false

  if (significantControl.addresses) {
    addresses = AddressList.createFromObject({ list: significantControl.addresses })
    onlyAddresses = Object.keys(significantControl).length === 1
    delete significantControl.addresses
  }

  if (!onlyAddresses) {
    const isCreate = id === null || id === undefined
    result = isCreate
      ? await api.createSignificantControls(significantControl)
      : await api.updateSignificantControls(id, data)

    id = result.id
  }

  if (addresses) {
    addressesAfterUpdate = await Promise.all(
      addresses.list.map(async (address) =>
        address.id ? await api.updateAddress({ id, address }) : await api.createAddress({ id, address }),
      ),
    )
  }

  return { result: result as SignificantControl, addressesAfterUpdate: addressesAfterUpdate as Address[] }
})

// Stores
const $significantControls = createStore<SignificantControlList>(new SignificantControlList())

const $combinedContactsAndControls = combine([$contacts, $significantControls], ([contacts, significantControls]) =>
  combineContactsAndControls(contacts, significantControls),
)

const $isLoading = combine(
  [fetchSignificantControlsFx.pending, updateOrCreateSignificantControlFx.pending],
  (isLoading) => isLoading.some(Boolean),
)

$significantControls.on(fetchSignificantControlsFx.doneData, (_, items) => new SignificantControlList(items))

$significantControls.on(updateOrCreateSignificantControlFx.doneData, (state, { result, addressesAfterUpdate }) => {
  const newState = new SignificantControlList(state.controls)

  const control = SignificantControl.createFromObject(result)

  if (addressesAfterUpdate) {
    control.setDataFromObject({ addresses: addressesAfterUpdate })
  }

  newState.receiveControl(control)

  return newState
})

$significantControls.on(changeField, (state, { id, key, value }) => {
  const newState = new SignificantControlList(state.controls)

  newState.getControl(id)?.setDataFromObject({ [key]: value })

  return newState
})

$significantControls.on(changeAddressField, (state, { id, addressIndex, key, value }) => {
  const newState = new SignificantControlList(state.controls)

  const control = newState.getControl(id)
  const address = control?.addresses.getAddress(addressIndex)?.setDataFromObject({ [key]: value })

  if (control && address) {
    control.addresses.setAddress(addressIndex, address)
  }

  return newState
})

$significantControls.on(deleteSignificantControlsFx.done, (state, { params: { id } }) => {
  const newState = new SignificantControlList(state.controls)
  newState.deleteControl(id)

  return newState
})

$significantControls.on(resetSignificantControls, () => new SignificantControlList())

export {
  $significantControls,
  $combinedContactsAndControls,
  $isLoading,
  changeField,
  changeAddressField,
  fetchSignificantControlsFx,
  deleteSignificantControlsFx,
  updateOrCreateSignificantControlFx,
  resetSignificantControls,
}
