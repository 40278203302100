import { useUnit } from 'effector-react'

import { useEffect, useMemo, useSelector } from 'hooks'

import { $collectionsStore, fetchCollectionGroupsFx } from 'app/effector/discover-etfs'
import { CollectionsGroupLayout } from 'app/effector/discover-etfs/types'
import type { CollectionsGroup } from 'app/effector/discover-etfs/types'

import type { Preset } from 'app/redux/models/portfolio/types'

import { manageTypes } from 'constants/portfolio'

type useSecurityUniverseDiscoveryReturnType = {
  collectionGroups: CollectionsGroup[]
  isLoading: boolean
  selfSelectedPresets: Preset[]
}

const useSecurityUniverseDiscovery = (): useSecurityUniverseDiscoveryReturnType => {
  const { collectionGroups, isLoading, areCollectionsFetched } = useUnit($collectionsStore)

  const { presets } = useSelector((state: any) => state.portfolios)
  const selfSelectedPresets = useMemo(() => presets[manageTypes.SELF_SELECTED] ?? [], [presets])

  const skeletonCollectionsGroups = [
    {
      isSkeleton: true,
      title: 'Popular ETFs',
      layout: 'inline',
      collections: [{ title: 'Collection' }],
    },
    {
      isSkeleton: true,
      title: 'Collection group',
      layout: 'featured',
      collections: [{ title: 'Collection' }, { title: 'Collection' }, { title: 'Collection' }],
    },
    {
      isSkeleton: true,
      title: 'Collection group',
      collections: [{ title: 'Collection' }, { title: 'Collection' }, { title: 'Collection' }, { title: 'Collection' }],
    },
  ]

  useEffect(() => {
    fetchCollectionGroupsFx()
  }, [])

  return {
    collectionGroups:
      isLoading && !areCollectionsFetched
        ? skeletonCollectionsGroups
        : collectionGroups.filter((group) => group.layout !== CollectionsGroupLayout.HIDDEN),
    isLoading,
    selfSelectedPresets,
  }
}

export { useSecurityUniverseDiscovery }
