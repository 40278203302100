import { useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

const useNavbar = ({ portfolioId, securityId }) => {
  const handleBack = useCallback(() => {
    goTo(urlTo('portfolio.security', { id: portfolioId, securityId }))
  }, [portfolioId, securityId])

  const handleSell = useCallback(() => {
    goTo(urlTo('portfolio.security-sell', { id: portfolioId, securityId }))
  }, [portfolioId, securityId])

  const handleBuy = useCallback(() => {
    goTo(urlTo('portfolio.security-buy', { id: portfolioId, securityId }))
  }, [portfolioId, securityId])

  const handleAddFunds = useCallback(() => {
    const currentLocation = location.pathname + location.search

    goTo(urlTo('dashboard.portfolio.add-funds', { id: portfolioId }, { back: currentLocation }))
  }, [portfolioId])

  const handleReview = useCallback(
    (orderId, previous) => {
      goTo(urlTo('portfolio.review', { id: portfolioId, orderId }, { previous, securityId }))
    },
    [portfolioId, securityId],
  )

  return {
    handleBack,
    handleSell,
    handleBuy,
    handleAddFunds,
    handleReview,
  }
}

export { useNavbar }
