import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries, useSelector } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { DesktopLayout, MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'

import { TwoFactorSetupLayout } from '../components/TwoFactorSetupLayout'

import { $phoneNumber, setPhoneNumber, initPhoneConfirmation, $validation, $isValid } from './model'
import { SmsFormModal } from './SmsFormModal'

const PhoneNumberStep = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const client = useSelector((state) => state.client)

  const [phoneNumber, handleSetPhoneNumber, handleInitPhoneConfirmation, validation, isValid] = useUnit([
    $phoneNumber,
    setPhoneNumber,
    initPhoneConfirmation,
    $validation,
    $isValid,
  ])

  const handleBack = (): void => {
    goTo(urlTo('two-factor-auth-setup.recovery-method'))
  }

  const headerNode = <Header hideDrawer logoLink="/" hard withShadow />

  const navbarNode = (
    <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
      <Typography size={20} lineHeight="small" weight="semibold">
        <Typo>Step 4 of 4</Typo>
      </Typography>
    </NavigationBar>
  )

  const button = (
    <Button mods={{ size: 'big block' }} onClick={handleInitPhoneConfirmation} disabled={!isValid}>
      Send confirmation code
    </Button>
  )

  const content = (
    <Fragment>
      <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
        <Typo>Confirm your phone number</Typo>
      </Typography>
      <Paper top={8}>
        <Typography size={14} align="center">
          <Typo>If app notifications don’t work, we’ll send you a text message to help you access your account.</Typo>
        </Typography>
      </Paper>
      <Paper top={40}>
        <Width size={23.5} center>
          <Validate rules={validation.phone.rules}>
            <Label errorMessages={validation.phone.errors} mods={{ color: 'darkgray' }}>
              Phone
              <Input
                name="phone"
                type="tel"
                mods={{ size: 'bigger' }}
                country={client.country_code_by_ip}
                onChange={(_event, value) => {
                  handleSetPhoneNumber(value)
                }}
              >
                {phoneNumber}
              </Input>
            </Label>
          </Validate>
        </Width>
      </Paper>
    </Fragment>
  )

  return (
    <TwoFactorSetupLayout>
      {desktop ? (
        <DesktopLayout
          header={headerNode}
          content={
            <Inner twoColumns>
              {navbarNode}
              <Paper top={40}>
                <Width size={45} center>
                  {content}
                </Width>
              </Paper>
              <Paper top={56}>
                <Width size={23.5} center>
                  {button}
                </Width>
              </Paper>
            </Inner>
          }
          footer={<DesktopFooter />}
        />
      ) : (
        <MobileLayout
          header={navbarNode}
          content={
            <Paper top={8} bottom={56}>
              {content}
            </Paper>
          }
          footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
        />
      )}

      <SmsFormModal />
    </TwoFactorSetupLayout>
  )
}

export { PhoneNumberStep }
