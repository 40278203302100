import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

/**
 * @param {string} redirect_uri
 * @return {Promise<{ auth_uri: string }|ApiError>}
 */
export const authenticateData = async (redirectUri) => {
  try {
    const { data } = await axios.post('/open-banking/data/', { redirect_uri: redirectUri })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {number} id
 * @return {Promise<{ id: number, state: string }|ApiError>}
 */
export const getInfoDataRequest = async (id) => {
  try {
    const { data } = await axios.get(`/open-banking/data/${id}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @typedef BankAccountInfo
 * @type {object}
 * @property {number} id
 * @property {string} state
 * @property {number} bank_account_id
 * @property {string} bank_account_state
 * @property {string} goal_id
 * @property {string} amount
 */

/**
 * @param {object=} queries
 * @return {Promise<BankAccountInfo[]|ApiError>}
 */
export const receiveAccounts = async (queries = {}) => {
  try {
    const { isTrueLayer } = queries
    const queryString = isTrueLayer ? `?isTrueLayer=1` : ''

    const { data } = await axios.get(`/bank-accounts/${queryString}`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {number | string} bankAccountId
 * @param {'PAYMENTS' | 'STANDING_ORDERS'} providerCapability
 * @return {Promise<any|ApiError>}
 */
export const checkBankAccount = async (bankAccountId, providerCapability) => {
  try {
    const queryString = `?bank_account_id=${bankAccountId}&provider_capability=${providerCapability}`

    const { data } = await axios.get(`/open-banking/bank_account/check/${queryString}`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {object} account
 * @return {Promise<BankAccountInfo|ApiError>}
 */
export const createBankAccount = async (account) => {
  try {
    const { data } = await axios.post('/bank-accounts/', account)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {number} id
 * @param {object} account
 * @return {Promise<BankAccountInfo|ApiError>}
 */
export const updateBankAccount = async (id, account) => {
  try {
    const { data } = await axios.patch(`/bank-accounts/${id}`, account)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {object} params
 * @param {number} params.goal_id
 * @param {string} params.amount
 * @param {number} params.bank_account_id
 * @param {string} params.redirect_uri
 *
 * @return {Promise<{ auth_uri: string }|ApiError>}
 */
export const createPayment = async (params) => {
  try {
    const { data } = await axios.post('/open-banking/payments/', params)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {number} id
 * @return {Promise<BankAccountInfo|ApiError>}
 */
export const getPaymentInfo = async (id) => {
  try {
    const { data } = await axios.get(`/open-banking/payments/${id}`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {object} params
 * @param {number} params.goal_id
 * @param {string} params.amount
 * @param {number} params.day_of_month
 * @param {number} params.bank_account_id
 * @param {string} params.redirect_uri
 * @return {Promise<{ auth_uri: string }|ApiError>}
 */
export const createStandingOrder = async (params) => {
  try {
    const { data } = await axios.post(`/open-banking/standing-orders/`, params)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @typedef StandingOrderInfo
 * @type {object}
 * @property {number} id
 * @property {string} state
 * @property {number} bank_account_id
 * @property {string} bank_account_state
 * @property {string} goal_id
 * @property {string} amount
 * @property {number} day_of_month
 */

/**
 * @param {number} id
 * @return {Promise<StandingOrderInfo|ApiError>}
 */
export const getStandingOrderInfo = async (id) => {
  try {
    const { data } = await axios.get(`/open-banking/standing-orders/${id}`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @return {Promise<StandingOrderInfo[]|ApiError>}
 */
export const receiveStandingOrders = async () => {
  try {
    const { data } = await axios.get(`/open-banking/standing-orders/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

/**
 * @param {number} id
 * @return {Promise<BankAccountInfo>}
 */
export const setNominatedBankAccount = async (id) => {
  const { data } = await axios.post(`/bank-accounts/${id}/nominate/`)

  return data
}
