import React, { Fragment, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { palette } from 'helpers/palette'

import { useMediaQueries } from 'hooks'

import Layout, { LayoutElement } from 'components/_old/Layout/Layout.jsx'
import { GatewayDest } from 'components/atoms/Gateway'

const setFallback = () => {
  window.visualViewport = {
    fallback: true,
    height: window.innerHeight,
    addEventListener: () => {},
    removeEventListener: () => {},
  }
}

// Kind of fallback for iOS < 13
if (!window.visualViewport) {
  setFallback()
}

let prevVisualViewport = {}

const setVhProperty = () => {
  const height = window.visualViewport.height
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = height * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const handleVWResize = () => {
  if (window.visualViewport.height !== prevVisualViewport.height) {
    setVhProperty()
  }

  prevVisualViewport = { height: window.visualViewport.height }
}

const handleKeyboardShownClassName = () => {
  if (window.visualViewport.height < document.documentElement.getBoundingClientRect().height) {
    document.documentElement.classList.add('Keyboard_shown')
  } else {
    document.documentElement.classList.remove('Keyboard_shown')
  }
}

const Nodes = ({ children }) => {
  const mainElement = useRef(null)
  const { desktop } = useMediaQueries()

  useEffect(() => {
    if (!desktop) {
      // Add custom style
      document.head.innerHTML = `
        ${document.head.innerHTML}
        <style id="NodesStyle">
          html, body {
            height: calc(var(--vh, 1vh) * 100) !important;
            overflow: hidden !important;
          }

          .Layout {
            height: 100% !important;
          }

          .Layout {
            height: calc(var(--vh, 1vh) * 100) !important;
          }

          .Modal-Backdrop,
          .Modal-Window,
          ._new_Modal-Backdrop,
          ._new_Modal-Window, {
            height: 100% !important;
          }

          .Modal-Backdrop,
          .Modal-Window,
          ._new_Modal-Backdrop,
          ._new_Modal-Window,
          ._new_Modal-Inside {
            height: calc(var(--vh, 1vh) * 100) !important;
          }

          html.iOs .Sticked_afterLayout .Button.Button_size_big.Button_size_block,
          html.iOs .Sticked_insideModal .Button.Button_size_big.Button_size_block,
          html.iOs .Sticked_afterLayout .Button.Button_size_big.Button_size_block .Button-Inner,
          html.iOs .Sticked_insideModal .Button.Button_size_big.Button_size_block .Button-Inner {
            transition: all ${palette.animation.speed.default} ${palette.animation.easing.default};
          }

          html.iOs.Keyboard_shown .Sticked_afterLayout .Button.Button_size_big.Button_size_block,
          html.iOs.Keyboard_shown .Sticked_insideModal .Button.Button_size_big.Button_size_block {
            padding: 0 1em;
          }

          html.iOs.Keyboard_shown .Sticked_afterLayout .Button.Button_size_big.Button_size_block .Button-Inner,
          html.iOs.Keyboard_shown .Sticked_insideModal .Button.Button_size_big.Button_size_block .Button-Inner {
            border-radius: 0.325rem;
          }
        </style>
      `
      // Calc vh
      prevVisualViewport = { height: window.visualViewport.height }
      setVhProperty()

      // Bind everything
      if (window.visualViewport.fallback) {
        window.addEventListener('orientationchange', setFallback)
        window.addEventListener('resize', setFallback)
      }

      window.addEventListener('orientationchange', handleVWResize)
      window.addEventListener('resize', handleVWResize)
      window.visualViewport.addEventListener('resize', handleKeyboardShownClassName)
    }

    return () => {
      // Remove custom style
      const nodesStyleElement = document.getElementById('NodesStyle')

      if (nodesStyleElement) {
        nodesStyleElement.remove()
      }

      // Uncalc vh
      prevVisualViewport = null
      document.documentElement.style.removeProperty('--vh')

      // Unbind everything
      if (window.visualViewport.fallback) {
        window.addEventListener('orientationchange', setFallback)
        window.addEventListener('resize', setFallback)
      }

      window.removeEventListener('orientationchange', handleVWResize)
      window.removeEventListener('resize', handleVWResize)
      window.visualViewport.removeEventListener('resize', handleKeyboardShownClassName)
    }
  }, [desktop])

  return (
    <Fragment>
      <Layout>
        <LayoutElement>
          <GatewayDest name="beforeLayout" />
        </LayoutElement>
        <LayoutElement ref={mainElement} main>
          {children}
        </LayoutElement>
        <LayoutElement>
          <GatewayDest name="afterLayout" />
        </LayoutElement>
      </Layout>
      <GatewayDest name="attention" />
      <GatewayDest name="modals" />
      <GatewayDest name="supportModal" />
      <GatewayDest name="cant-set-up-monthly-payment" />
      <GatewayDest name="withdraw-funds" />
      <GatewayDest name="changes-to-the-agreement" />
      <GatewayDest name="terms-and-risk" />
      <GatewayDest name="change-address" />
      <GatewayDest name="change-company-address" />
      <GatewayDest name="change-email" />
      <GatewayDest name="change-password" />
      <GatewayDest name="change-goal-title" />
      <GatewayDest name="alarms" />
      <GatewayDest name="toasts" />
      <GatewayDest name="offline" />
      <GatewayDest name="bank-transfer" />
      <GatewayDest name="wire-transfer" />
      <GatewayDest name="mobile-account-menu" />
      <GatewayDest name="review" />
    </Fragment>
  )
}

Nodes.propTypes = {
  children: PropTypes.node,
}

export default Nodes
