const mockCountry = {
  name: 'Brazil',
  description: `Brazil, officially the Federative Republic of Brazil is the largest country in South America and in Latin America. At 8.5 million square kilometers and with over 217 million people, Brazil is the world's fifth-largest country by area and the seventh most populous. Its capital is Brasília, and its most populous city is São Paulo.`,
  color: '#50D5FF',
  logo: null,
  website: null,
  sector_name: null,
  region_name: null,
  target_weight: null,
  actual_weight: '8.246306',
  total_weight: '26.886857',
  total_value: '34097.15',
  actual_value: '2811.76',
  portfolios: {
    '305707': [
      {
        id: 119,
        title: 'iShares S&P 500',
        target_weight: null,
        actual_weight: '2.844627',
        actual_value: '969.94',
      },
      {
        id: 215,
        title: 'HSBC MSCI World',
        target_weight: null,
        actual_weight: '2.058442',
        actual_value: '701.87',
      },
      {
        id: 247,
        title: 'Vanguard FTSE Developed World (acc)',
        target_weight: null,
        actual_weight: '1.935734',
        actual_value: '660.03',
      },
      {
        id: 315,
        title: 'Vanguard FTSE All-World High Dividend Yield',
        target_weight: null,
        actual_weight: '1.354830',
        actual_value: '461.96',
      },
      {
        id: 115,
        title: 'iShares MSCI Emerging Markets IMI (acc)',
        target_weight: null,
        actual_weight: '0.048226',
        actual_value: '16.44',
      },
      {
        id: 113,
        title: 'Vanguard FTSE 250',
        target_weight: null,
        actual_weight: '0.004447',
        actual_value: '1.52',
      },
    ],
    '306395': [
      {
        id: 215,
        title: 'HSBC MSCI World',
        target_weight: null,
        actual_weight: '0.000000',
        actual_value: '0.00',
      },
    ],
  },
}

export { mockCountry }
