import React from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette'

import type { DirectDebitSubscription, RecurringPaymentSubscription } from 'app/effector/recurringPayments'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

type SavingsPlanProps = {
  subscription?: DirectDebitSubscription | RecurringPaymentSubscription
  isRecurringPaymentAvailableForSetup: boolean
  onClick: () => void
}

const SavingsPlan = ({
  onClick,
  subscription,
  isRecurringPaymentAvailableForSetup,
}: SavingsPlanProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const hasDirectDebitSubscription = !!subscription && !subscription.frequency
  const hasRecurringPaymentSubscription = !!subscription?.frequency
  const hasSubscription = hasDirectDebitSubscription || hasRecurringPaymentSubscription
  const showRecurringPaymentIcon =
    hasRecurringPaymentSubscription || (!hasSubscription && isRecurringPaymentAvailableForSetup)

  const iconNode = showRecurringPaymentIcon ? (
    <Icon type="calendar-in-circle" size={44} data-test-id="savingsPlanCardIcon" />
  ) : (
    <Icon type="directDebit" width={64} height={22} color={palette['content-on-background-default']} />
  )

  return (
    <SelectableCard onClick={onClick} data-test-id="savingsPlanCard">
      <Paper top={24} bottom={hasSubscription ? 20 : 24} right={desktop ? 24 : 20} left={desktop ? 24 : 20}>
        <ItemWithIcon
          iconPosition="right"
          icon={iconNode}
          content={
            <div>
              <Typography size={desktop ? 16 : 18} weight="semibold" lineHeight="small">
                <Typo>{hasSubscription ? 'Savings Plan' : 'Set up a Savings Plan'}</Typo>
              </Typography>
              {!hasSubscription && (
                <Paper top={8}>
                  <Typography color="minor" size={14} data-test-id="savingsPlanCardDescription">
                    <Typo>
                      {isRecurringPaymentAvailableForSetup
                        ? 'Make contributions weekly, fortnightly or monthly'
                        : 'It will take 5-7 business days to set up your first payment.'}
                    </Typo>
                  </Typography>
                </Paper>
              )}
            </div>
          }
          contentVerticalAlign="center"
        />
      </Paper>
    </SelectableCard>
  )
}

export { SavingsPlan }
