import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'

import { useSelector, useState } from 'hooks'

import { $owner } from 'app/effector/contacts'

import { showSuccessToast } from 'app/redux/actions/ui'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { CopyToClipboard } from 'components/atoms/CopyToClipboard'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { UserAvatar } from 'components/molecules/UserAvatar/UserAvatar'

import { EditAvatar } from './EditAvatar/EditAvatar'

const ProfileHeader = (): React.ReactElement => {
  const [isEditAvatarModalVisible, setIsAvatarModalVisible] = useState(false)
  const client = useSelector((state) => state.client)
  const owner = useUnit($owner)

  return (
    <Fragment>
      <AllCenter>
        <UserAvatar
          firstName={owner?.first_name}
          lastName={owner?.last_name}
          image={client.avatar}
          editable={true}
          size="100"
          onClick={() => {
            setIsAvatarModalVisible(true)
          }}
        />
        <Paper top={24} bottom={8}>
          <Typography size={20} lineHeight="small" align="center" weight="semibold">
            <Typo>{owner?.getFullName()}</Typo>
          </Typography>
        </Paper>
        <ItemWithIcon
          inline
          space={8}
          iconPosition="right"
          iconVerticalAlign="center"
          icon={
            <CopyToClipboard
              value={client.reference}
              toast="Client reference copied"
              showToast={showSuccessToast}
              data-test-id="copyReferenceButton"
            />
          }
          content={
            <Typography color="additional">
              <Typo>
                Client reference: <span data-test-id="clientReferenceValue">{client.reference}</span>
              </Typo>
            </Typography>
          }
        />
      </AllCenter>
      <EditAvatar
        isVisible={isEditAvatarModalVisible}
        onClose={() => {
          setIsAvatarModalVisible(false)
        }}
      />
    </Fragment>
  )
}

export { ProfileHeader }
