import isEmpty from 'lodash/isEmpty'

import { useCallback } from 'hooks'

import { usePendingOrders, useRestriction } from 'app/pages/Dashboard/Goals/DIY/hooks'

const useDeleteRestriction = ({ portfolioId, handleDeleteRaw }) => {
  const { orders } = usePendingOrders()

  const portfolioOrders = orders.getByPortfolioId(portfolioId)

  const isRestricted = useCallback(
    (securityId) => !isEmpty(portfolioOrders.getBySecurityId(securityId)),
    [portfolioOrders],
  )

  const {
    open: restrictionModalOpen,
    type: restrictionModalType,
    handleClose: handleRestrictionModalClose,
    handleRestriction,
  } = useRestriction()

  const handleDelete = useCallback(
    (securityId) => {
      handleRestriction({
        action: () => handleDeleteRaw(securityId),
        type: 'EDIT',
        isRestricted: isRestricted(securityId),
      })
    },
    [isRestricted, handleDeleteRaw, handleRestriction],
  )

  return {
    restrictionModalOpen,
    restrictionModalType,
    handleRestrictionModalClose,
    handleDelete,
  }
}

export { useDeleteRestriction }
