import React, { Fragment } from 'react'

import { useCallback, useMemo, useState } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import StoresButtons from 'components/_old/StoresButtons/StoresButtons.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { TextCollapse } from 'components/atoms/TextCollapse'
import { Typography } from 'components/atoms/Typography'

import { MobileFooter } from 'app/containers/Footer'

import { SharePortfolioSecurities } from 'app/pages/Dashboard/Goals/DIY/SharePortfolio/components/SharePortfolioSecurities'
import { SharePortfolioAnalytics } from 'app/pages/Dashboard/Goals/DIY/SharePortfolio/Mobile/components/SharePortfolioAnalytics'

import { BonusBadge } from '../../components/BonusBadge'
import { BuildByBadge } from '../../components/BuildByBadge'
import { type SharedPortfolioComponentData } from '../../types'

const Mobile = ({
  isPromoEnabled,
  isAuthorized,
  title,
  description,
  securities,
  equityTypes,
  assets,
  equities,
  sectors,
  regions,
  handleShare,
  handleGetStarted,
  handleSecurityClick,
  handleOpenTsCsModal,
  isDescriptionExpanded,
  handleDescriptionExpand,
}: SharedPortfolioComponentData): React.ReactElement => {
  const [layoutNode, setLayoutNode] = useState(null)
  const layoutRef = useCallback((ref) => {
    setLayoutNode(ref)
  }, [])

  const analyticsProps = useMemo(
    () => ({ sectors, regions, equities, equity_types: equityTypes, assets, securities, layoutNode }),
    [sectors, regions, equities, equityTypes, assets, securities, layoutNode],
  )

  const headerNode = useMemo(() => <Header logoLink={location.origin} hard hideDrawer withShadow />, [])

  const securitiesNode = useMemo(
    () => (
      <Fragment>
        <Paper bottom={4}>
          <SharePortfolioSecurities securities={securities} onClick={handleSecurityClick} />
        </Paper>
        <Paper top={32}>
          <Typography size={14} weight="semibold">
            <Typo>
              The Sharing Portfolio Tool is for illustrative, user idea sharing purposes only. Ideas are not Investment
              Advice and InvestEngine does not endorse or accept liability for any of the ideas shared by users of the
              Sharing Portfolio Tool.
            </Typo>
          </Typography>
        </Paper>
      </Fragment>
    ),
    [securities, handleSecurityClick],
  )

  const shareLink = useMemo(
    () => (
      <Link onClick={handleShare}>
        <ItemWithIcon
          space={12}
          icon={<Icon type="share" color="inherit" size={24} />}
          content={
            <Typography size={14} weight="semibold" color="inherit">
              <Typo>Share this portfolio outline</Typo>
            </Typography>
          }
          iconVerticalAlign="center"
        />
      </Link>
    ),
    [handleShare],
  )

  const contentNode = useMemo(
    () => (
      <Fragment>
        <Paper bottom={64}>
          <Paper bottom={4}>
            <BuildByBadge />
            <Paper top={32} bottom={40}>
              <Typography size={24} lineHeight="small" weight="semibold" align="center">
                <Typo allowHtml={false}>{title}</Typo>
              </Typography>
              {description && (
                <Paper top={20}>
                  <Typography size={14}>
                    <TextCollapse
                      limit={200}
                      multiline
                      onExpand={handleDescriptionExpand}
                      shouldInitExpanded={isDescriptionExpanded}
                      maxLineBreaks={3}
                      allowHtml={false}
                    >
                      {description}
                    </TextCollapse>
                  </Typography>
                </Paper>
              )}
            </Paper>
          </Paper>
          <SharePortfolioAnalytics {...analyticsProps} securitiesNode={securitiesNode} />
          <AllCenter>
            <Paper top={64}>{shareLink}</Paper>
            <Paper top={56}>
              <Typography>
                <Typo>Download the free InvestEngine app</Typo>
              </Typography>
            </Paper>
            <Paper top={16}>
              <StoresButtons />
            </Paper>
          </AllCenter>
        </Paper>
        <MobileFooter />
      </Fragment>
    ),
    [title, description, shareLink, isDescriptionExpanded, handleDescriptionExpand, analyticsProps, securitiesNode],
  )

  const footer = useMemo(
    () =>
      isAuthorized ? null : (
        <MobileLayoutFooterButton>
          <AllCenter>
            <Button type="submit" mods={{ size: 'big block', color: 'green' }} onClick={handleGetStarted}>
              Create your own
            </Button>
            {isPromoEnabled && <BonusBadge handleOpenTsCsModal={handleOpenTsCsModal} />}
          </AllCenter>
        </MobileLayoutFooterButton>
      ),
    [isAuthorized, isPromoEnabled, handleGetStarted, handleOpenTsCsModal],
  )

  return <MobileLayout ref={layoutRef} header={headerNode} content={contentNode} footer={footer} />
}

export { Mobile }
