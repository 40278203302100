import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo, useMediaQueries } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'

import './CreateAccount.css'

const CreateAccountSubmit = ({
  rules,
  isAgreeWithPrivacyPolicy,
  isAgreeWithNotifications,
  disabled,
  onSubmit,
  handlePrivacyPolicyAgreement,
  handleChangeNotificationAgreement,
  isIsaTransferStep,
  shouldShowIsaTransfer,
}) => {
  const { desktop } = useMediaQueries()

  const privacyPolicyCheckbox = useMemo(() => {
    return (
      <Paper top={24} bottom={16}>
        <Validate rules={rules}>
          <CheckboxWithLabel
            className="CreateAccount-Checkbox"
            label={
              <Typography size={14} lineHeight="small">
                I agree to InvestEngine's{' '}
                <Link to="/privacy/" hard blank>
                  privacy policy
                </Link>
              </Typography>
            }
            onChange={handlePrivacyPolicyAgreement}
            checked={isAgreeWithPrivacyPolicy}
            data-test-id="createAccountPrivacyPolicyField"
          />
        </Validate>
      </Paper>
    )
  }, [rules, isAgreeWithPrivacyPolicy, handlePrivacyPolicyAgreement])

  const agreementNotificationsCheckbox = useMemo(
    () => (
      <Paper bottom={desktop ? 32 : 0}>
        <CheckboxWithLabel
          className="CreateAccount-Checkbox"
          label={
            <Typography size={14} lineHeight="small">
              <Typo>Receive InvestEngine updates</Typo>
            </Typography>
          }
          onChange={handleChangeNotificationAgreement}
          checked={isAgreeWithNotifications}
          data-test-id="createAccountNotificationAgreement"
        />
      </Paper>
    ),
    [isAgreeWithNotifications, handleChangeNotificationAgreement, desktop],
  )

  const submitButton = useMemo(
    () => (
      <Paper top={desktop ? 0 : 16} bottom={desktop ? 0 : 16}>
        <Button
          type="submit"
          mods={{ size: 'big block' }}
          disabled={disabled}
          onClick={onSubmit}
          tabIndex={4}
          data-test-id="createAccountSubmitButton"
        >
          {isIsaTransferStep ? 'Save and continue' : shouldShowIsaTransfer ? 'Continue' : 'Save and continue'}
        </Button>
      </Paper>
    ),
    [disabled, onSubmit, desktop, isIsaTransferStep, shouldShowIsaTransfer],
  )

  return (
    <Fragment>
      {!isIsaTransferStep && (
        <React.Fragment>
          {privacyPolicyCheckbox}
          {agreementNotificationsCheckbox}
        </React.Fragment>
      )}
      {submitButton}
    </Fragment>
  )
}

CreateAccountSubmit.propTypes = {
  rules: PropTypes.array,
  disabled: PropTypes.bool,
  isAgreeWithPrivacyPolicy: PropTypes.bool,
  isAgreeWithNotifications: PropTypes.bool,
  onSubmit: PropTypes.func,
  handlePrivacyPolicyAgreement: PropTypes.func,
  handleChangeNotificationAgreement: PropTypes.func,
  isIsaTransferStep: PropTypes.bool,
  shouldShowIsaTransfer: PropTypes.bool,
}

export { CreateAccountSubmit }
