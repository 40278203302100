import React, { useCallback, useMemo } from 'react'
import { PropTypes } from 'prop-types'
import isArray from 'lodash/isArray'
import { useUnit } from 'effector-react'

import {
  combineErrors,
  backendErrorsToObj,
  bulkValidate,
  birthdayRules,
  nameRules,
  phoneRules,
} from 'helpers/validation.js'

import { useMediaQueries, useSelector } from 'hooks'

import Card from 'components/_old/Card/Card.jsx'
import Width from 'components/_old/Width/Width'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Link from 'components/_old/Link/Link.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Form from 'components/_old/Form/Form.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label, { LabelText } from 'components/_old/Label/Label.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { CheckboxWithLabel, RadioWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'
import Input from 'components/_old/Input/Input.jsx'
import { RadioGroup, Radio } from 'react-radio-group'
import NationalitiesInputList from 'components/_old/NationalitiesInputList/NationalitiesInputList.jsx'
import Button from 'components/_old/Button/Button.jsx'
import CleanInputOnFocus from 'components/atoms/CleanInputOnFocus/CleanInputOnFocus.jsx'

import SignificantControlInlineHelp from 'app/pages/Dashboard/SetupAccount/Business/DirectorsAndShareholdersSetup/SignificantControlInlineHelp.jsx'

import { usaId } from 'constants/countriesIds.js'
import { countriesAlias } from 'constants/countriesAlias.ts'
import { $owner, changeContactField, $error, resetError } from 'app/effector/contacts'
import { $dictsStore } from 'app/effector/dicts'

const PersonalProfileForm = ({ wrapSubmitButton, onSubmit: handleSubmit }) => {
  const { desktop } = useMediaQueries()
  const { country_code_by_ip } = useSelector((state) => state.client)
  const { dicts } = useUnit($dictsStore)
  const { genders = [], countries = [] } = dicts

  const owner = useUnit($owner) ?? {}
  const error = useUnit($error)

  const inputMods = { size: 'bigger' }

  const handleOwnerFieldChange = useCallback(
    (field, value) => {
      resetError()
      changeContactField({ field: { [field]: value }, id: owner.id })
    },
    [owner.id],
  )

  const handleChangeNationality = useCallback(
    (ids) => {
      handleOwnerFieldChange('nationalities', ids)
    },
    [handleOwnerFieldChange],
  )

  const nationalities = useMemo(() => [...(countries || []), { country: 'British', country_id: 2635167 }], [countries])

  const countriesWithAlias = useMemo(() => [...countries, ...countriesAlias], [countries])

  const countryOfTaxResidence = useMemo(
    () => countries.find((country) => country.country_id === owner.country_of_tax_residence),
    [owner.country_of_tax_residence, countries],
  )

  const nationalityUSAIndex = useMemo(
    () => (owner.nationalities || []).findIndex((nationality) => nationality === usaId),
    [owner.nationalities],
  )

  if (!isArray(owner.nationalities)) {
    return null
  }

  const validation = combineErrors(
    {
      gender: {
        rules: [owner.gender !== null],
        errors: ['Gender should be specified'],
      },
      first_name: nameRules(owner.first_name, 'First name'),
      middle_name: nameRules(owner.middle_name, 'Middle name', false),
      last_name: nameRules(owner.last_name, 'Last name'),
      birthday: birthdayRules(owner.birthday),
      nationalities: {
        rules: [owner.nationalities.length > 0 && owner.nationalities.some((nationality) => nationality !== null)],
        errors: ['Citizenship can’t be empty'],
      },
      country_of_tax_residence: {
        rules: [owner.country_of_tax_residence, owner.country_of_tax_residence !== usaId],
        errors: ['Country of residence can’t be empty', 'Country of residence for tax purposes'],
      },
      phone: phoneRules(owner.phone),
    },
    backendErrorsToObj(error),
  )

  const submitButton = (
    <Button
      type="submit"
      mods={{ size: 'big block' }}
      onClick={handleSubmit}
      disabled={!bulkValidate(validation)}
      tabIndex={9}
      data-test-id="personalFormSubmit"
    >
      Continue
    </Button>
  )

  const formContent = (
    <Width size={desktop ? 22 : null}>
      <Validate rules={validation.gender.rules}>
        <Label errorMessages={validation.gender.errors}>
          <LabelText>
            <Text>Gender</Text>
          </LabelText>
          <RadioGroup selectedValue={owner.gender} onChange={(value) => handleOwnerFieldChange('gender', value)}>
            <ColumnarLayout fluid>
              {genders?.map((gender) => {
                return (
                  <Column size={0} key={gender.name}>
                    <RadioWithLabel
                      CustomInput={Radio}
                      label={gender.name}
                      value={gender.id}
                      data-test-id="personalFormGenderRadio"
                    />
                  </Column>
                )
              })}
            </ColumnarLayout>
          </RadioGroup>
        </Label>
      </Validate>
      <Validate rules={validation.first_name.rules}>
        <Label errorMessages={validation.first_name.errors} data-test-id="personalFormFirstNameLabel">
          First name
          <Input
            name="fname"
            autoComplete="given-name"
            type="text"
            mods={inputMods}
            onChange={(_event, value) => handleOwnerFieldChange('first_name', value)}
            tabIndex={2}
            data-test-id="personalFormFirstNameInput"
          >
            {owner.first_name}
          </Input>
        </Label>
      </Validate>
      <Validate rules={validation.middle_name.rules}>
        <Label
          errorMessages={validation.middle_name.errors}
          postfield={<Text muted>Optional</Text>}
          data-test-id="personalFormMiddleNameLabel"
        >
          Middle name
          <Input
            name="mname"
            autoComplete="additional-name"
            type="text"
            mods={inputMods}
            onChange={(_event, value) => handleOwnerFieldChange('middle_name', value)}
            tabIndex={3}
            data-test-id="personalFormMiddleNameInput"
          >
            {owner.middle_name}
          </Input>
        </Label>
      </Validate>
      <Validate rules={validation.last_name.rules}>
        <Label errorMessages={validation.last_name.errors} data-test-id="personalFormLastNameLabel">
          Last name
          <Input
            name="lname"
            autoComplete="family-name"
            type="text"
            mods={inputMods}
            onChange={(_event, value) => handleOwnerFieldChange('last_name', value)}
            tabIndex={4}
            data-test-id="personalFormLastNameInput"
          >
            {owner.last_name}
          </Input>
        </Label>
      </Validate>
      <Validate rules={validation.birthday.rules}>
        <Label errorMessages={validation.birthday.errors} data-test-id="personalFormBirthdayLabel">
          Date of birth
          <CleanInputOnFocus onSetValue={(value) => handleOwnerFieldChange('birthday', value || '')}>
            <Input
              name="birthday"
              type="date"
              mods={inputMods}
              onChange={(_event, value) => handleOwnerFieldChange('birthday', value)}
              tabIndex={5}
              data-test-id="personalFormBirthdayInput"
            >
              {owner.birthday}
            </Input>
          </CleanInputOnFocus>
        </Label>
      </Validate>
      <Validate rules={validation.nationalities.rules}>
        {(isValid, brokenRule) => (
          <NationalitiesInputList
            nationalitiesIds={owner.nationalities}
            allNationalitiesList={nationalities}
            nationalityUSAIndex={nationalityUSAIndex}
            errorMessage={validation.nationalities.errors[brokenRule]}
            isValid={isValid}
            onChange={handleChangeNationality}
            tabIndex={6}
            data-test-id="personalFormNationalities"
          />
        )}
      </Validate>
      <Validate rules={validation.country_of_tax_residence.rules}>
        {(isValid, brokenRule) => {
          const postfield = owner.country_of_tax_residence === usaId && (
            <Text block color="red" data-test-id="personalFormCountryUsaInfo">
              <Typo>
                Please contact us through the{' '}
                <Link to="https://help.investengine.com/" hard>
                  Help Centre
                </Link>
                , and we'll help you continue creating an account with us.
              </Typo>
            </Text>
          )

          return (
            <Label postfield={postfield} data-test-id="personalFormCountryLabel">
              <LabelText>
                <Text color={isValid ? null : 'red'}>
                  <Typo>
                    {isValid
                      ? 'Country of residence for tax purposes'
                      : validation.country_of_tax_residence.errors[brokenRule]}
                  </Typo>
                </Text>
              </LabelText>
              <Input
                name="country"
                type="typeahead"
                options={countriesWithAlias}
                labelKey="country"
                mods={inputMods}
                onChange={(_event, value) => {
                  const id = (value || {}).country_id

                  return handleOwnerFieldChange('country_of_tax_residence', id)
                }}
                tabIndex={7}
                forceSelect
                data-test-id="personalFormCountryInput"
              >
                {countryOfTaxResidence}
              </Input>
            </Label>
          )
        }}
      </Validate>
      <Validate rules={validation.phone.rules}>
        <Label errorMessages={validation.phone.errors} data-test-id="personalFormPhoneLabel">
          Phone
          <CleanInputOnFocus onSetValue={(value) => handleOwnerFieldChange('phone', value)} cleanIfMatch={/\*/}>
            <Input
              name="phone"
              type="tel"
              country={country_code_by_ip}
              mods={inputMods}
              onChange={(_event, value) => handleOwnerFieldChange('phone', value)}
              tabIndex={8}
              disabled={owner.phone_verified}
              data-test-id="personalFormPhoneInput"
            >
              {owner.phone}
            </Input>
          </CleanInputOnFocus>
        </Label>
      </Validate>
      <Card
        mods={{
          theme: 'transparent',
          'no-padding': desktop ? 'right bottom left' : 'right left',
          padding: 'big',
        }}
      >
        <CheckboxWithLabel
          label="I have significant control over this company"
          size="smaller"
          postfield={<SignificantControlInlineHelp />}
          checked={owner.has_significant_control}
          onChange={(value) => handleOwnerFieldChange('has_significant_control', value)}
          data-test-id="personalFormSignificantControlCheckbox"
        />
      </Card>
      {wrapSubmitButton(submitButton)}
    </Width>
  )

  return (
    <SubmitOnEnter>
      <Form>
        {desktop ? (
          <Card
            mods={{
              theme: !desktop ? 'edge-to-edge transparent' : null,
              'no-padding': desktop ? null : 'top',
            }}
          >
            {formContent}
          </Card>
        ) : (
          formContent
        )}
      </Form>
    </SubmitOnEnter>
  )
}

PersonalProfileForm.propTypes = {
  wrapSubmitButton: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default PersonalProfileForm
