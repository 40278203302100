import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

const fetchRecentlyViewedEtfs = async (): Promise<number[] | ApiError> => {
  try {
    const { data } = await axios.get('/securities/recently_viewed/', { limit: 50 })

    return data?.securities_ids || []
  } catch (error) {
    return new ApiError(error)
  }
}

const setSecurityAsViewed = async ({ securityId }: { securityId: number }): Promise<number | ApiError> => {
  try {
    await axios.post(`/securities/${securityId}/view/`)

    return securityId
  } catch (error) {
    return new ApiError(error)
  }
}

export { fetchRecentlyViewedEtfs, setSecurityAsViewed }
