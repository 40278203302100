import React from 'react'

import { VerifyEmail } from './VerifyEmail'

export default {
  module: 'verify-email',
  path: 'verify-email/',
  name: 'Verify email address',
  component: function Component(props) {
    return <VerifyEmail {...props} />
  },
}
