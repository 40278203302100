import { useMemo, useState } from 'hooks'

import { features } from 'helpers/features'

import { type ReportList } from 'app/effector/reports/models'

type UseReportFilter<Value> = {
  title: string
  name: string
  type: 'radio'
  values: Array<{
    name: string
    value: Value | null
    isDefault: boolean
  }>
  selected: Value[]
  onChange: (values: Array<Value | null>) => void
}

type UseReportFilters = {
  type: UseReportFilter<string>
  year: UseReportFilter<string | number>
}

type UseReportFiltersInterface = {
  filteredReports: ReportList
  filters: UseReportFilters
  types: Array<string | null>
  selectedType: string | null
  years: Array<string | number | null>
  selectedYear: string | number | null
  setSelectedYear: (year: string) => void
  setSelectedType: (type: string) => void
}

const useReportFilters = (reports: ReportList): UseReportFiltersInterface => {
  const defaultType = useMemo(() => (features.get('reports-v4') ? null : 'Any'), [])
  const defaultYear = useMemo(() => (features.get('reports-v4') ? null : 'All'), [])
  const [selectedType, setSelectedType] = useState<string | null>(defaultType)
  const [selectedYear, setSelectedYear] = useState<string | number | null>(defaultYear)

  const types = useMemo(() => {
    const types = reports.map((report) => report.type_title).filter((maybeType) => Boolean(maybeType)) as string[]

    return [defaultType, ...new Set(types)]
  }, [defaultType, reports])

  const years = useMemo(() => {
    const years = reports.map((report) => report.year).filter((maybeYear) => Boolean(maybeYear)) as number[]

    return [defaultYear, ...new Set(years)]
  }, [defaultYear, reports])

  const filters: UseReportFilters = useMemo(
    () => ({
      type: {
        title: 'Report type',
        name: 'Any report type',
        type: 'radio',
        values: types.map((type) => ({
          name: type ?? 'Any report type',
          value: type === defaultType ? null : type,
          isDefault: type === defaultType,
        })),
        selected: [selectedType].filter(Boolean) as string[],
        onChange: (valueArray: Array<string | null>) => {
          setSelectedType(valueArray[0] ?? null)
        },
      },
      year: {
        title: 'Year',
        name: 'All time',
        type: 'radio',
        values: years.map((year) => ({
          name: `${year ?? 'All time'}`,
          value: year === defaultYear ? null : year,
          isDefault: year === defaultYear,
        })),
        selected: [selectedYear].filter(Boolean) as string[],
        onChange: (valueArray: Array<string | number | null>) => {
          setSelectedYear(valueArray[0] ?? null)
        },
      },
    }),
    [types, selectedType, years, selectedYear, defaultType, defaultYear],
  )

  const filteredReports: ReportList = useMemo(
    () =>
      reports.filter(
        (report) =>
          (report.year === +(selectedYear ?? '0') || selectedYear === defaultYear) &&
          (report.type_title === selectedType || selectedType === defaultType),
      ),
    [reports, selectedType, defaultType, selectedYear, defaultYear],
  )

  return {
    filteredReports,
    filters,
    types,
    selectedType,
    years,
    selectedYear,
    setSelectedYear,
    setSelectedType,
  }
}

export { useReportFilters, type UseReportFiltersInterface, type UseReportFilters, type UseReportFilter }
