import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import './SelectableCard.css'

const SelectableCard = forwardRef(
  (
    {
      children,
      className,
      tag = 'button',
      flex = false,
      selected = false,
      disabled = false,
      error = false,
      onClick: handleClick,
      'data-test-id': dataTestId,
      ...rest
    },
    ref,
  ) => {
    const classes = classNames(className, '_new_SelectableCard', {
      _new_SelectableCard_flex: flex,
      _new_SelectableCard_selected: selected,
      _new_SelectableCard_disabled: disabled,
      _new_SelectableCard_error: error,
    })

    const props = {
      ref,
      className: classes,
      tabIndex: 0,
      onClick: handleClick,
      'data-test-id': dataTestId,
      ...rest,
    }

    if (tag === 'button') {
      props.type = 'button'
    }

    return React.createElement(tag, props, children)
  },
)
SelectableCard.displayName = 'SelectableCard'

SelectableCard.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  flex: PropTypes.bool,
  selected: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  'data-test-id': PropTypes.string,
}

export { SelectableCard }
