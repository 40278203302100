import { sendError } from 'helpers/errorLogging'

function enumValue<Enum>(unknown: unknown, enumme: Enum): Enum | undefined {
  const enumValues: Enum[] = Object.values(enumme)

  if (enumValues.includes(unknown as Enum)) {
    return unknown as Enum
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  sendError(new Error(`Cannot convert ${unknown} to enumValue`))

  return undefined
}

export { enumValue }
