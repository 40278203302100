import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import last from 'lodash/last'
import omit from 'lodash/omit'
import { useUnit } from 'effector-react'

import { goTo, urlTo } from 'helpers/router.js'
import { sendError } from 'helpers/errorLogging.js'
import { features } from 'helpers/features'
import { trackEvent } from 'helpers/analytics'

import { useMediaQueries, useActions, useLoading, useState, useMemo, useCallback, useSelector } from 'hooks'

import { Gateway } from 'components/atoms/Gateway'
import { Modal } from 'components/molecules/Modal'
import { NavigationBar } from 'components/atoms/NavigationBar'
import Inner from 'components/_old/Inner/Inner.jsx'
import Width from 'components/_old/Width/Width'
import Segment from 'components/atoms/Segment/Segment.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import ContentHolder from 'components/molecules/ContentHolder/ContentHolder.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Preloader } from 'components/molecules/Preloader'

import { $quickStartsStore } from 'app/effector/quickStart'
import { $bankAccountsStore } from 'app/effector/bank-accounts'

import { changeNotificationSettings as changeNotificationSettingsActionCreator } from 'app/redux/actions/ui'

import icon from './icon.svg'

const AllowNotificationsModal = ({ module, params, location: { query }, routes }) => {
  const { desktop } = useMediaQueries()
  const changeNotificationSettings = useActions(changeNotificationSettingsActionCreator)
  const { getQuickStartByPortfolioId } = useUnit($quickStartsStore)
  const portfolioId = params.id ? parseInt(params.id, 10) : null
  const quickStart = getQuickStartByPortfolioId(portfolioId)
  const { isLoading, wait } = useLoading()
  const [allowNotifications, setAllowNotifications] = useState(false)
  const client = useSelector((state) => state.client)
  const open = query.allowNotifications === '1'

  const { nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)
  const isNominatedBankSupportsRecurringPayment = nominatedBankAccount?.bank?.recurring_payment_support
  const quickStartModule = useMemo(() => {
    const isLumpSum = !quickStart?.frequency

    if (isLumpSum) return 'portfolio.add-funds'

    return features.get('recurring-payments-release') && isNominatedBankSupportsRecurringPayment
      ? 'portfolio.options.recurring-payment'
      : 'portfolio.options.direct-debit'
  }, [isNominatedBankSupportsRecurringPayment, quickStart])

  const nextUrl = useMemo(() => {
    const lastRoute = last(routes)
    const parentModule = module ?? lastRoute.module
    const nextModule = query.quickStart === 'true' ? quickStartModule : parentModule
    const nextQuery = omit(query, ['allowNotifications', 'quickStart'])
    const nextUrl = urlTo(nextModule, params, {
      ...nextQuery,
      back: query.quickStart === 'true' ? urlTo('dashboard.portfolio', params) : null,
    })

    return nextUrl
  }, [params, query, routes, module, quickStartModule])

  const handleSkip = useCallback(() => {
    trackEvent({
      action: 'marketing_communications_no',
      client_type: client.type,
    })
    goTo(nextUrl, { replace: true })
  }, [nextUrl, client.type])

  const handleContinue = useCallback(async () => {
    try {
      trackEvent({
        action: 'marketing_communications_yes',
        client_type: client.type,
      })
      await wait(changeNotificationSettings('promo', true))

      goTo(nextUrl, { replace: true })
    } catch (error) {
      sendError(error)
    }
  }, [nextUrl, client.type, wait, changeNotificationSettings])

  const contentSection = (
    <Fragment>
      <Segment mods={{ margin: 'bigger', noMargin: 'top' }}>
        <AllCenter>
          <SvgIcon src={icon} />
        </AllCenter>
      </Segment>
      <Segment mods={{ margin: 'bigger', noMargin: 'bottom' }}>
        <Width size={desktop ? 65 : null}>
          <Width size={desktop ? 40 : null} center>
            <Text center>
              <Headline level={4} mods={{ 'no-margin': 'top bottom' }}>
                <Typo>
                  Want to make the most of InvestEngine?
                  <br />
                  Tick the box below and we’ll keep you up to date with the latest marketing communications about new
                  features and services.
                </Typo>
              </Headline>
            </Text>
          </Width>
        </Width>
      </Segment>
    </Fragment>
  )

  const buttonSection = (
    <ModalContentButtons
      width={desktop ? 20 : null}
      targetGatewayName="allowNotificationsModal"
      phantomButtonsMarginSize="zero"
      isNewModal
    >
      <Segment mods={{ margin: 'big', noMargin: 'top' }}>
        <CheckboxWithLabel
          label="Yes, please send me notifications"
          size="smaller"
          checked={allowNotifications}
          onChange={() => setAllowNotifications(!allowNotifications)}
          data-test-id="allowNotificationsModalCheckbox"
        />
      </Segment>
      <Segment mods={{ margin: 'big', noMargin: 'bottom' }}>
        <Button
          onClick={handleContinue}
          mods={{ size: 'big block' }}
          disabled={!allowNotifications}
          data-test-id="allowNotificationsModalButton"
        >
          Continue
        </Button>
      </Segment>
    </ModalContentButtons>
  )

  const layout = desktop ? (
    <Width size={65}>
      <Width size={40} center>
        <Inner flex>
          <Segment mods={{ margin: 'biggest', noMargin: 'bottom' }}>{contentSection}</Segment>
          <Segment mods={{ margin: 'hefty', noMargin: 'top' }}>{buttonSection}</Segment>
        </Inner>
      </Width>
    </Width>
  ) : (
    <Fragment>
      <NavigationBar rightPartText="Skip" onRightPartClick={handleSkip}>
        Notifications
      </NavigationBar>
      <Inner flex>
        <AllCenter>{contentSection}</AllCenter>
        <ContentHolder>{buttonSection}</ContentHolder>
      </Inner>
    </Fragment>
  )

  return (
    <Fragment>
      <Gateway into="modals">
        <Modal
          open={open}
          close={desktop ? 'Skip' : null}
          onClose={handleSkip}
          insideGatewayName="allowNotificationsModal"
          data-test-id="allowNotificationsModal"
        >
          {layout}
        </Modal>
      </Gateway>
      <Gateway into="newModalPreloader">
        <Preloader loading={isLoading} size="big" zIndex />
      </Gateway>
    </Fragment>
  )
}

AllowNotificationsModal.propTypes = {
  params: PropTypes.object,
  location: PropTypes.shape({
    query: PropTypes.shape({
      view: PropTypes.string,
      allowNotifications: PropTypes.string,
      quickStart: PropTypes.string,
    }),
  }),
  routes: PropTypes.arrayOf(PropTypes.object),
  module: PropTypes.string,
}

const wrappedComponent = withRouter(AllowNotificationsModal)

export { wrappedComponent as AllowNotificationsModal }
