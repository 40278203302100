import { createStore, createEffect, combine } from 'effector'

import { makeApiCall } from 'app/effector/api'

import { ApiError } from 'app/redux/models/errors'

import * as api from './api'

type Hints = Record<string, boolean>

// Effects
const fetchHintsFx = createEffect(async () => {
  try {
    const hints = await makeApiCall(api.getHintsStatus)

    return hints
  } catch (error) {}
})

const setHintAsWatchedFx = createEffect(async (key: string) => {
  const response = await api.setHintsStatus(key, true)

  if (response instanceof ApiError) throw response
})

// Stores
const $hints = createStore<Hints | null>(null)

$hints.on(fetchHintsFx.doneData, (state, hints) => hints ?? {})

$hints.on(setHintAsWatchedFx.done, (state, { params: key }) => ({ ...state, [key]: true }))

const $areHintsFetched = createStore<boolean>(false)

$areHintsFetched.on(fetchHintsFx.done, () => true)

const $hintsStore = combine({
  hints: $hints,
  isLoading: fetchHintsFx.pending,
  areHintsFetched: $areHintsFetched,
})

export { $hintsStore, fetchHintsFx, setHintAsWatchedFx, $hints }
