import React from 'react'

import { memoizeForever } from 'helpers/memoize'

import { Security } from './Security'
import { SecurityInfo } from './Security/SecurityInfo'
import { SecurityCollection } from './SecurityCollection/SecurityCollection'
import { SecurityUniverse } from './SecurityUniverse'
import { SelfSelectedPreset } from './SelfSelectedPreset'
import { SelfSelectedPresets } from './SelfSelectedPresets'

const getRoutes = memoizeForever(() => {
  const securityChildRoutes: Array<Record<string, any>> = [
    {
      isNewLayout: true,
      noGlobalPreloader: true,
      path: '',
      component: function Component(props) {
        return (
          <Security {...props}>
            <SecurityInfo />
          </Security>
        )
      },
      childRoutes: [
        {
          module: 'savings-plan-setup',
          path: 'savings-plan-setup/',
        },
        {
          module: 'lump-sum-setup',
          path: 'lump-sum-setup/',
        },
        {
          path: 'analytics/',
          module: 'security-analytics',
          childRoutes: [
            {
              path: 'holdings/',
              module: 'holdings',
            },
            {
              path: 'sectors/',
              module: 'sectors',
            },
            {
              path: 'regions/',
              module: 'regions',
            },
          ],
        },
      ],
    },
  ]

  const selfSelectedPresetsChildRoutes = [
    {
      index: true,
      isNewLayout: true,
      component: function Component(props) {
        return <SelfSelectedPresets {...props} />
      },
    },
    {
      isNewLayout: true,
      module: 'preset',
      path: ':id/',
      component: function Component(props) {
        return <SelfSelectedPreset {...props} />
      },
    },
  ]

  const childRoutes = [
    {
      indexRoute: true,
      module: 'universe',
      component: function Component(props) {
        return <SecurityUniverse {...props} key="universe" />
      },
    },
    {
      module: 'universe-all',
      path: 'all/',
      component: function Component(props) {
        return <SecurityUniverse {...props} key="universe-all" />
      },
    },
    {
      module: 'self-selected-presets',
      path: 'life-plans/',
      childRoutes: selfSelectedPresetsChildRoutes,
      indexRoute: selfSelectedPresetsChildRoutes.find((route) => route.index),
    },
    {
      module: 'security',
      path: ':id/',
      childRoutes: securityChildRoutes,
      indexRoute: securityChildRoutes[0],
    },
    {
      module: 'security-collection',
      path: 'collections/:slugOrId/',
      isNewLayout: true,
      component: function Component(props) {
        const slugOrId = props?.params?.slugOrId ?? 'defaultSlug'

        return <SecurityCollection key={slugOrId} {...props} />
      },
    },
  ]

  return {
    isNewLayout: true,
    module: 'securities',
    path: 'etfs/',
    childRoutes,
    component: function Component({ location, params, routes }) {
      const lastRoute: any = routes?.findLast((route) => route.component)

      return lastRoute?.component({ location, params, routes }) ?? null
    },
    indexRoute: childRoutes.find(({ indexRoute }) => indexRoute),
  }
})

export { getRoutes }
