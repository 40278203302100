import React from 'react'
import { withRouter } from 'react-router'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import { useStoreMap } from 'effector-react'

import { useMediaQueries, useState, useCallback, useMemo, useEffect } from 'hooks'

import compose from 'helpers/compose.js'
import moment from 'helpers/date.js'
import localstore from 'helpers/localstore.js'

import { $portfolioPointOfInterests } from 'app/effector/pointsOfInterest'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'
import Toggle from 'components/atoms/Toggle/Toggle.jsx'
import { Typography } from 'components/atoms/Typography'

import type { Period } from 'components/molecules/NewPeriodSelect'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'
import { useTabs } from 'components/molecules/Tabs'

import { PortfolioPointOfInterestCard } from 'components/organisms/charts/parts'
import { PriceChart } from 'components/organisms/PriceChart'
import { ReturnInfo } from 'components/organisms/ReturnInfo'

import { GraphPlaceholder } from 'app/pages/Dashboard/Goals/components/GraphPlaceholder'
import { PERIODS } from 'app/pages/Securities/hooks'

import type { ManagedPortfolio } from '../../../../types'

import './GrowthGraph.css'

const prepareQueryParam = (value: string = ''): string => {
  return value.toLowerCase().replace(' ', '-')
}

type GrowthGraphProps = {
  isPortfolioLoading: boolean
  portfolio: ManagedPortfolio
  location: {
    query: {
      time: Period
    }
  }
  periodSelectNode: React.ReactNode
  currentPeriod: Period
  isPlaceholderDisplayed: boolean
  handleAddFundsClick: () => void
}

const GrowthGraph = ({
  isPortfolioLoading,
  portfolio,
  location,
  periodSelectNode,
  currentPeriod,
  isPlaceholderDisplayed,
  handleAddFundsClick,
}: GrowthGraphProps): React.ReactElement => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const [contributionsVisible, setContributionsVisible] = useState(
    localstore.get('goals')?.[portfolio.id]?.contributionsVisible,
  )

  const { history_performance: historyPerformance = [], history_contributions: historyContributions = [] } = portfolio
  const pointsOfInterest = useStoreMap($portfolioPointOfInterests, (state) => state.get(portfolio.id))

  const firstHistoryDay = historyPerformance?.[0] && moment(historyPerformance[0].date)

  const lastHistoryDay = useMemo(
    () =>
      historyPerformance?.[historyPerformance.length - 1] &&
      moment(historyPerformance[historyPerformance.length - 1].date),
    [historyPerformance],
  )

  const rightEdgeDate = useMemo(() => (lastHistoryDay ? lastHistoryDay.toDate() : new Date()), [lastHistoryDay])

  const lesserThanMonth = firstHistoryDay && lastHistoryDay && lastHistoryDay.diff(firstHistoryDay, 'months') < 1
  const lesserThanYear = firstHistoryDay && lastHistoryDay && lastHistoryDay.diff(firstHistoryDay, 'years') < 1

  const dataByPeriod = useMemo(
    () => [
      {
        label: 'All time',
        period: PERIODS.MAX,
        profit: parseFloat(portfolio.stats?.max.return_money ?? 0),
        timeWeightedReturn: parseFloat(portfolio.stats?.max.return_percent ?? 0),
        contributions: portfolio.contributions?.total,
        fromDate: null,
        'data-test-id': 'goalChartAllTimeTab',
      },
      {
        label: 'Year',
        period: PERIODS.YEAR,
        profit: parseFloat(portfolio.stats?.year.return_money ?? 0),
        timeWeightedReturn: parseFloat(portfolio.stats?.year.return_percent ?? 0),
        contributions: portfolio.contributions?.year,
        fromDate: lesserThanYear ? null : moment(rightEdgeDate).subtract(1, 'year').toDate(),
        'data-test-id': 'goalChartYearTab',
      },
      {
        label: 'Month',
        period: PERIODS.MONTH,
        profit: parseFloat(portfolio.stats?.month.return_money ?? 0),
        timeWeightedReturn: parseFloat(portfolio.stats?.month.return_percent ?? 0),
        contributions: portfolio.contributions?.month,
        fromDate: lesserThanMonth ? null : moment(rightEdgeDate).subtract(1, 'month').toDate(),
        'data-test-id': 'goalChartMonthTab',
      },
      {
        label: 'Six months',
        period: PERIODS.SIX_MONTHS,
        profit: parseFloat(portfolio.stats?.six_months.return_money ?? 0),
        timeWeightedReturn: parseFloat(portfolio.stats?.six_months.return_percent ?? 0),
        contributions: portfolio.contributions?.six_months,
        fromDate: lesserThanYear ? null : moment(rightEdgeDate).subtract(6, 'months').toDate(),
        'data-test-id': 'goalChartSixMonthsTab',
      },
      {
        label: 'Week',
        period: PERIODS.WEEK,
        profit: parseFloat(portfolio.stats?.week.return_money ?? 0),
        timeWeightedReturn: parseFloat(portfolio.stats?.week.return_percent ?? 0),
        contributions: portfolio.contributions?.week,
        fromDate: lesserThanYear ? null : moment(rightEdgeDate).subtract(1, 'week').toDate(),
        'data-test-id': 'goalChartWeekTab',
      },
    ],
    [
      portfolio.contributions?.month,
      portfolio.contributions?.six_months,
      portfolio.contributions?.total,
      portfolio.contributions?.week,
      portfolio.contributions?.year,
      portfolio.stats?.max.return_money,
      portfolio.stats?.max.return_percent,
      portfolio.stats?.month.return_money,
      portfolio.stats?.month.return_percent,
      portfolio.stats?.six_months.return_money,
      portfolio.stats?.six_months.return_percent,
      portfolio.stats?.week.return_money,
      portfolio.stats?.week.return_percent,
      portfolio.stats?.year.return_money,
      portfolio.stats?.year.return_percent,
      lesserThanMonth,
      lesserThanYear,
      rightEdgeDate,
    ],
  )

  const getPeriodIndexByLabel = (label: string): number =>
    dataByPeriod.findIndex((period) => prepareQueryParam(period.label) === label)
  const { time: currentTabView = 'all-time' } = location.query

  const initialPeriodIndex = currentTabView ? getPeriodIndexByLabel(currentTabView) : 0
  const { setActiveTab } = useTabs(dataByPeriod.length, initialPeriodIndex)

  const activeTabIndex = useMemo(
    () => dataByPeriod.findIndex((item) => item.period === currentPeriod),
    [currentPeriod, dataByPeriod],
  )

  useEffect(
    () => {
      setActiveTab(initialPeriodIndex)
    },
    // had to run only on location change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  )

  const { profit, period, timeWeightedReturn, contributions } = dataByPeriod[activeTabIndex || 0]

  const priceChartData = useMemo(
    () =>
      historyPerformance.map((day) => ({
        date: day.date,
        value: day.balance,
      })),
    [historyPerformance],
  )
  const contributionsChartData = useMemo(
    () =>
      contributionsVisible
        ? historyContributions.map((day) => ({
            date: day.date,
            value: day.contributions,
          }))
        : null,
    [historyContributions, contributionsVisible],
  )

  const classes = classNames('GrowthGraph', getMediaQuieryClasses('GrowthGraph', mediaQueries))

  const handleChangeContributionsVisible = useCallback(
    (event) => {
      const visible = event.target.checked
      setContributionsVisible(visible)
      const storedGoals = localstore.get('goals') || {}

      if (!storedGoals[portfolio.id]) {
        storedGoals[portfolio.id] = {}
      }
      storedGoals[portfolio.id].contributionsVisible = visible

      localstore.set('goals', storedGoals)
    },
    [setContributionsVisible, portfolio.id],
  )

  useEffect(() => {
    const storedGoals = localstore.get('goals') || {}
    const storeContributionsVisibleValue = Boolean(storedGoals[portfolio.id]?.contributionsVisible)
    setContributionsVisible(storeContributionsVisibleValue)
  }, [portfolio.id, setContributionsVisible])

  if (isPlaceholderDisplayed) {
    return (
      <Paper left={desktop ? 0 : 12} right={desktop ? 0 : 12}>
        {periodSelectNode}
        <Paper top={desktop ? 32 : 16} bottom={32}>
          <ReturnInfo price={profit} percent={timeWeightedReturn ?? 0} tooltipOffset={-30} />
        </Paper>
        <GraphPlaceholder linkText="Add funds" onLinkClick={handleAddFundsClick} />
      </Paper>
    )
  }

  return (
    <Paper left={desktop ? 0 : 16} right={desktop ? 0 : 16}>
      <Paper>{periodSelectNode}</Paper>
      <div className={classes}>
        <Paper top={desktop ? 32 : 16}>
          <Skeleton shown={isPortfolioLoading && priceChartData.length < 1} mix>
            <div>
              <PriceChart
                period={period}
                data={priceChartData}
                contributionsData={contributionsChartData}
                topContent={<ReturnInfo price={profit} percent={timeWeightedReturn ?? 0} tooltipOffset={-70} />}
                topContentOffset={desktop ? 48 : 24}
                negative={(profit ?? 0) < 0}
                portfolioId={portfolio.id}
                pointsOfInterest={pointsOfInterest}
                PointOfInterestCard={PortfolioPointOfInterestCard}
                data-test-id="performanceChart"
              />
              <Paper top={desktop ? 32 : 16}>
                <Typography align="left" size={desktop ? 16 : 14}>
                  <label style={{ display: 'inline-flex', 'align-items': 'center' }}>
                    <Paper right={8} inline style={{ display: 'flex' }}>
                      <Toggle checked={contributionsVisible} onChange={handleChangeContributionsVisible} />
                    </Paper>
                    <Typo>Net contributions</Typo>
                    <Paper left={8} inline data-test-id="goalChartContribution">
                      <PoundsWithPence amount={contributions} />
                    </Paper>
                  </label>
                </Typography>
              </Paper>
            </div>
          </Skeleton>
        </Paper>
      </div>
    </Paper>
  )
}

const composedGrowthGraph = compose(withRouter)(GrowthGraph)

export { composedGrowthGraph as GrowthGraph }
