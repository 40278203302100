import React from 'react'

import { Analytics } from './Analytics.jsx'
import { Root } from './Root'
import { Holdings } from './Holdings/Holdings.jsx'
import { Sectors } from './Sectors/Sectors.jsx'
import { Regions } from './Regions/Regions.jsx'
import { SingleHolding } from './SingleHolding'
import { Assets } from './Assets/Assets.tsx'

const regionChildRoutes = [
  {
    isNewLayout: true,
    noGlobalPreloader: true,
    module: 'regions',
    component: function Component(props) {
      return <SingleHolding type="region" {...props} />
    },
  },
  {
    isNewLayout: true,
    noGlobalPreloader: true,
    path: 'countries/',
    module: 'countries',
    component: function Component(props) {
      return <SingleHolding type="region" {...props} />
    },
  },
]

const analyticsChildRoutes = [
  {
    index: true,
    isNewLayout: true,
    noGlobalPreloader: true,
    module: 'analytics-root',
    component: function Component(props) {
      return <Root {...props} />
    },
  },
  {
    isNewLayout: true,
    noGlobalPreloader: true,
    path: 'regions/',
    module: 'regions',
    component: function Component(props) {
      return (
        <Analytics {...props}>
          <Regions />
        </Analytics>
      )
    },
  },
  {
    path: 'regions/:id/',
    module: 'region',
    indexRoute: regionChildRoutes[0],
    childRoutes: regionChildRoutes,
  },
  {
    isNewLayout: true,
    noGlobalPreloader: true,
    path: 'holdings/',
    module: 'holdings',
    component: function Component(props) {
      return (
        <Analytics {...props}>
          <Holdings />
        </Analytics>
      )
    },
  },
  {
    isNewLayout: true,
    noGlobalPreloader: true,
    path: 'holdings/:id/',
    module: 'holding',
    component: function Component(props) {
      return <SingleHolding type="holding" {...props} />
    },
  },
  {
    isNewLayout: true,
    noGlobalPreloader: true,
    path: 'sectors/',
    module: 'sectors',
    component: function Component(props) {
      return (
        <Analytics {...props}>
          <Sectors />
        </Analytics>
      )
    },
  },
  {
    isNewLayout: true,
    noGlobalPreloader: true,
    path: 'sectors/:id/',
    module: 'sector',
    component: function Component(props) {
      return <SingleHolding type="sector" {...props} />
    },
  },
  {
    isNewLayout: true,
    noGlobalPreloader: true,
    path: 'countries/:id/',
    module: 'country',
    component: function Component(props) {
      return <SingleHolding type="country" {...props} />
    },
  },
  {
    isNewLayout: true,
    noGlobalPreloader: true,
    path: 'assets/',
    module: 'assets',
    component: function Component(props) {
      return (
        <Analytics {...props}>
          <Assets />
        </Analytics>
      )
    },
  },
]

const childRoutes = [
  {
    module: 'all-portfolios',
    indexRoute: analyticsChildRoutes.find((route) => route.index),
    childRoutes: analyticsChildRoutes,
  },
  {
    module: 'portfolio',
    path: ':portfolioId/',
    indexRoute: analyticsChildRoutes.find((route) => route.index),
    childRoutes: analyticsChildRoutes,
  },
]

export default {
  module: 'analytics',
  path: 'analytics/',
  childRoutes,
  indexRoute: childRoutes.find(({ index }) => index),
}
