import React, { Fragment } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

type DeletePortfolioModalProps = {
  isOpen: boolean
  onClose: () => void
  onDeletePortfolio: () => Promise<void>
}

const DeletePortfolioModal = ({
  isOpen,
  onClose,
  onDeletePortfolio,
}: DeletePortfolioModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const content = useMemo(() => {
    const inner = (
      <Fragment>
        <Typography
          tag="h1"
          size={desktop ? 32 : 24}
          lineHeight={desktop ? 'small' : 'medium'}
          align="center"
          weight="semibold"
        >
          <Typo>Delete portfolio</Typo>
        </Typography>
        <Typography size={desktop ? 14 : 16} align="center">
          <Paper top={desktop ? 32 : 12} bottom={24}>
            <Typo>Are you sure you want to delete this empty portfolio?</Typo>
          </Paper>
        </Typography>
      </Fragment>
    )

    if (desktop) {
      return (
        <Width size={27} center>
          {inner}
        </Width>
      )
    }
    return <AllCenter>{inner}</AllCenter>
  }, [desktop])

  return (
    <Modal open={isOpen} close={null} onClose={onClose}>
      <OptionsLayout
        header={
          <OptionsNavigationBar rightPartText="Cancel" onRightPartClick={onClose}>
            &nbsp;
          </OptionsNavigationBar>
        }
        content={content}
        button={
          <Button onClick={onDeletePortfolio} mods={{ size: 'big block' }} data-test-id="deletePortfolioButton">
            Delete
          </Button>
        }
        center
        data-test-id="deletePortfolioModal"
      />
    </Modal>
  )
}

export { DeletePortfolioModal }
