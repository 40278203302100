import React from 'react'

import { palette } from 'helpers/palette/'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import './Analysed.css'

type AnalysedProps = {
  isDefaultPortfolio: boolean
}

export default function Analysed({ isDefaultPortfolio }: AnalysedProps): React.ReactElement {
  return (
    <ColumnarLayout className="Analysed" mods={{ padding: 'small', 'align-columns': 'center' }}>
      <Column size={1}>
        <Width size={4}>
          <Icon className="Analysed-Icon" type="flag" color={palette['status-success']} fluid />
        </Width>
      </Column>
      <Column size={6}>
        <div className="Analysed-Text">
          <Typo>
            {isDefaultPortfolio
              ? 'Based on your profile we would put you in the following portfolio'
              : 'Based on your answers this portfolio could work for you'}
          </Typo>
        </div>
      </Column>
    </ColumnarLayout>
  )
}
