import { useUnit } from 'effector-react'

import { useState, useCallback, useContext, useActions, useSelector, useDispatch, useMemo } from 'hooks'

import { sendError } from 'helpers/errorLogging.js'
import { features } from 'helpers/features'
import { convertRecurringPaymentAmountToMonthly } from 'helpers/recurringPayments'
import { goTo, urlTo } from 'helpers/router'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $dictsStore } from 'app/effector/dicts'
import { $recurringPaymentsStore } from 'app/effector/recurringPayments'

import {
  setValid as setValidActionCreator,
  setNotValid as setNotValidActionCreator,
  updateOrCreate as updateOrCreateActionCreator,
  addFundsWireTransfer as addFundsWireTransferActionCreator,
  changeField as changeFieldActionCreator,
} from 'app/redux/actions/portfolios'
import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'
import { isClientRegistered, isLockedGoal as isLockedPortfolio } from 'app/redux/selectors'

import { ChangeGoalTunnelContext as ChangePortfolioTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'

import { useGrowthProjectionsCommon } from '../../../../../hooks'

import type { ManagedPortfolio } from '../../../../types'

import { regulatoryTypes } from 'constants/portfolio'

type PresetSecurity = {
  amount: number
  change: number
  description: string
  id: number
  isin: string
  modified: Date
  price_date: Date
  security_id: number
  title: string
  value: string
}

type Preset = {
  id: number
  title: string
  type: 'GROWTH' | 'INCOME'
  preset_stocks: PresetSecurity[]
  preset_bonds: PresetSecurity[]
  preset_alternatives: PresetSecurity[]
  preset_cash?: string
}

type useGrowthProjectionsArguments = {
  portfolio: ManagedPortfolio
  location: {
    query?: {
      editablePortfolioFields?: string
    }
  }
}

type useGrowthProjectionsData = {
  editablePortfolio: ManagedPortfolio
  projections: object
  projectionsFetched: boolean
  presets: Preset[]
  isPortfolioChanged: boolean
  initialDepositMin: number
  lockedPortfolio: boolean
  validation: object
  handleChange: () => void
  handleSubmit: () => Promise<unknown>
  visibleAttentionModal: boolean
  presetIndex: number
  recommendedIndex: number
  presetChangedInfo: Record<string, unknown> & { title: string; kiid: string }
  isPresetChanged: boolean
  clientRegistered: boolean
  resetTunnel: () => void
  toggleAttentionModal: (value: boolean) => void
  isRecurringPamentRestrictionModalOpened: boolean
  closeRecurringPaymentRestrictionModal: () => void
  isRecommendedPresetLoading: boolean
  kfdModalOpen: boolean
  closeKfdModal: () => void
}

// todo: переделать на хук (промис)
const changePortfolioMonthlyDepositToDirectDebitActionCreator = (portfolioId, callback) => {
  return (dispatch, getState) => {
    const { monthlyPaymentAmount } = getState().ui.modals.directDebit

    if (monthlyPaymentAmount) {
      const portfolio = getState().portfolios.items.find((portfolio) => portfolio.id === portfolioId)
      const nextPortfolio = { ...portfolio, monthly_deposit: monthlyPaymentAmount }

      dispatch(changeFieldActionCreator({ monthly_deposit: monthlyPaymentAmount }, portfolio.id))

      if (callback) {
        callback(nextPortfolio)
      }
    }
  }
}

const useGrowthProjections = ({
  portfolio,
  location: { query: { editablePortfolioFields: editablePortfolioFieldsString } = {} },
}: useGrowthProjectionsArguments): useGrowthProjectionsData => {
  const [changeField, updateOrCreate, setValid, setNotValid, changePortfolioMonthlyDepositToDirectDebit] = useActions([
    changeFieldActionCreator,
    updateOrCreateActionCreator,
    setValidActionCreator,
    setNotValidActionCreator,
    changePortfolioMonthlyDepositToDirectDebitActionCreator,
  ])

  const editablePortfolioFields: {
    one_off_payment?: number
    preset?: number
    monthly_deposit?: string
    term?: number
  } = useMemo(() => {
    try {
      if (editablePortfolioFieldsString) {
        return JSON.parse(editablePortfolioFieldsString)
      }

      return {}
    } catch (error) {
      return {}
    }
  }, [editablePortfolioFieldsString])

  const {
    presets,
    editablePortfolio,
    editableFields,
    isPortfolioChanged,
    isPresetChanged,
    validation,
    projections,
    projectionsFetched,
    isRecommendedPresetLoading,
    isPortfolioActive,
    updatePortfolioAppState,
    handleChange,
  } = useGrowthProjectionsCommon(portfolio, editablePortfolioFields)

  const { nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)
  const isNominatedBankSupportsRecurringPayment = nominatedBankAccount?.bank?.recurring_payment_support
  const { getReccuringPaymentByPortfolioId, hasDirectDebitByPortfolioId } = useUnit($recurringPaymentsStore)
  const hasDirectDebitSubscription = hasDirectDebitByPortfolioId(portfolio.id)
  const recurringPayment = getReccuringPaymentByPortfolioId(portfolio.id)

  const dispatch = useDispatch()
  const clientRegistered = useSelector(isClientRegistered)
  const lockedPortfolio = useSelector((state) => isLockedPortfolio(state, portfolio.id))
  const { initialDepositMin } = useUnit($dictsStore)

  const { tunnel, setTunnel, resetTunnel, takeNextStep, tunnelQuery } = useContext(ChangePortfolioTunnelContext)

  const [isRecurringPamentRestrictionModalOpened, setIsRecurringPaymentRestrictionModalOpened] = useState(false)
  const [visibleAttentionModal, toggleAttentionModal] = useState(false)
  const [kfdModalOpen, setKfdModalOpen] = useState(false)

  const recommendedIndex = presets.findIndex((preset) => preset.id === portfolio.preset_recommended)
  const presetIndex = presets.findIndex((preset) => preset.id === editablePortfolio.preset)
  const presetChangedInfo = presets[presetIndex]

  const openMonthlyDisclaimerModal = useCallback(() => {
    const { amount, ...queryWithoutAmount } = tunnelQuery || {}

    resetTunnel()
    goTo(urlTo('dashboard.portfolio.cant-set-up-monthly-payment', { id: portfolio.id }, queryWithoutAmount), {
      replace: true,
    })
  }, [portfolio.id, resetTunnel, tunnelQuery])

  const openDirectDebitModal = useCallback(
    (monthlyDeposit) => {
      const isRecurringPaymentEnabled =
        features?.get('recurring-payments-release') && isNominatedBankSupportsRecurringPayment

      const { amount, ...queryWithoutAmount } = tunnelQuery || {}
      const monthlyPaymentModule =
        features?.get('recurring-payments-release') && isRecurringPaymentEnabled ? 'recurring-payment' : 'direct-debit'

      const nextUrl = urlTo(
        `dashboard.portfolio.options.${monthlyPaymentModule}`,
        { id: portfolio.id },
        isRecurringPaymentEnabled
          ? { ...tunnelQuery, amount: monthlyDeposit, back: location.href }
          : { ...queryWithoutAmount, amount: monthlyDeposit, promo: true },
      )

      if (portfolio.regulatory_type === regulatoryTypes.SIPP) {
        goTo(urlTo('portfolio.add-funds.net-contributions', { id: portfolio.id }, { ...tunnelQuery, next: nextUrl }))
        return
      }

      goTo(nextUrl, { replace: true })
    },
    [portfolio, tunnelQuery, isNominatedBankSupportsRecurringPayment],
  )

  const onAfterDirectDebitSubmit = useCallback(
    (portfolioId) => {
      changePortfolioMonthlyDepositToDirectDebit(portfolioId, (nextPortfolio) => {
        if (nextPortfolio) {
          const { amount, ...queryWithoutAmount } = tunnelQuery || {}

          goTo(urlTo('dashboard.portfolio', { id: portfolioId }, queryWithoutAmount), { replace: true })
        }
      })
    },
    [changePortfolioMonthlyDepositToDirectDebit, tunnelQuery],
  )

  const openKfdModal = useCallback(() => {
    setKfdModalOpen(true)
  }, [setKfdModalOpen])
  const closeKfdModal = useCallback(() => {
    setKfdModalOpen(false)
  }, [setKfdModalOpen])

  const savePortfolio = useCallback(async () => {
    const stateAfterUpdate = await updateOrCreate(editableFields, editablePortfolio, null, portfolio.id, false)

    if (stateAfterUpdate.portfolios.error) {
      showFailToast('Portfolio edit failed')
      throw stateAfterUpdate.client.error
    }

    const message = (() => {
      if (editablePortfolio.preset === portfolio.preset) {
        return 'Portfolio saved'
      }

      return 'Portfolio change request has been submitted'
    })()

    setValid()
    showSuccessToast(message)
  }, [updateOrCreate, editableFields, editablePortfolio, portfolio.id, portfolio.preset, setValid])

  const handleSubmit = useCallback(async () => {
    const runNextStep = (): string | undefined => {
      const nextStep = takeNextStep()

      if (nextStep && typeof nextStep === 'function') {
        return nextStep()
      }

      resetTunnel()
    }

    if (tunnel.length > 0) {
      return runNextStep()
    }

    const recurringPaymentMonthlyAmount = convertRecurringPaymentAmountToMonthly(
      Number(recurringPayment?.amount),
      recurringPayment?.frequency,
    )

    const isRecurringPaymentChanged =
      recurringPayment && recurringPaymentMonthlyAmount !== editablePortfolio.monthly_deposit

    const nextTunnel = []

    if (
      !visibleAttentionModal &&
      !kfdModalOpen &&
      isPresetChanged &&
      (clientRegistered || presetIndex !== recommendedIndex)
    ) {
      nextTunnel.push(() => {
        toggleAttentionModal(true)
      })
    }

    if (features.get('new-questionnaire') && isPresetChanged && !kfdModalOpen) {
      nextTunnel.push(() => {
        toggleAttentionModal(false)
        openKfdModal()
      })
    }

    nextTunnel.push(() => {
      setNotValid()
      toggleAttentionModal(false)
      closeKfdModal()
      updatePortfolioAppState()

      savePortfolio().then(
        () => runNextStep(),
        () => resetTunnel(),
      )
    })

    if (isRecurringPaymentChanged) {
      handleChange('monthly_deposit', recurringPaymentMonthlyAmount)
      nextTunnel.push(() => {
        setIsRecurringPaymentRestrictionModalOpened(true)
      })
    }

    if (editablePortfolio.one_off_payment) {
      try {
        const {
          portfolios: { error },
        } = await dispatch(addFundsWireTransferActionCreator(editablePortfolio.one_off_payment, portfolio.id))

        if (error) {
          throw new Error(error)
        }

        nextTunnel.push(() => {
          const bankTransferUrl = 'dashboard.portfolio.add-funds.bank-transfer'
          goTo(
            urlTo(bankTransferUrl, { id: portfolio.id }, { ...tunnelQuery, amount: editablePortfolio.one_off_payment }),
            {
              replace: true,
            },
          )
          handleChange('one_off_payment', null)
        })
      } catch (error) {
        sendError(error)
        showFailToast('Something went wrong')
      }
    }

    if (isPortfolioActive && !recurringPayment && editablePortfolio.monthly_deposit !== portfolio.monthly_deposit) {
      const tryToSetToNull = editablePortfolio.monthly_deposit === null

      if (tryToSetToNull ? hasDirectDebitSubscription : true) {
        nextTunnel.push(() => {
          if (editablePortfolio.one_off_payment) {
            changeField({ one_off_payment: null }, portfolio.id)
          }

          if (portfolio.contributions && parseFloat(portfolio.contributions.total) < initialDepositMin) {
            handleChange('monthly_deposit', portfolio.monthly_deposit)
            openMonthlyDisclaimerModal()
            return
          }

          openDirectDebitModal(editablePortfolio.monthly_deposit)
        })
      }
    }

    if (
      portfolio.contributions &&
      parseFloat(portfolio.contributions.total) > initialDepositMin &&
      editablePortfolio.monthly_deposit !== portfolio.monthly_deposit
    ) {
      nextTunnel.push(() => {
        onAfterDirectDebitSubmit(portfolio.id)
      })
    }

    const oldLastStep = nextTunnel[nextTunnel.length - 1]
    nextTunnel[nextTunnel.length - 1] = () => {
      oldLastStep()
    }

    const [runFirstStep, ...nextTunnelWithoutFirstStep] = nextTunnel

    setTunnel(nextTunnelWithoutFirstStep)
    runFirstStep()
  }, [
    tunnel.length,
    recurringPayment,
    editablePortfolio.monthly_deposit,
    editablePortfolio.one_off_payment,
    visibleAttentionModal,
    kfdModalOpen,
    isPresetChanged,
    clientRegistered,
    presetIndex,
    recommendedIndex,
    isPortfolioActive,
    portfolio.monthly_deposit,
    portfolio.contributions,
    portfolio.id,
    initialDepositMin,
    setTunnel,
    takeNextStep,
    resetTunnel,
    openKfdModal,
    setNotValid,
    closeKfdModal,
    updatePortfolioAppState,
    savePortfolio,
    handleChange,
    dispatch,
    tunnelQuery,
    hasDirectDebitSubscription,
    openDirectDebitModal,
    changeField,
    openMonthlyDisclaimerModal,
    onAfterDirectDebitSubmit,
  ])

  const closeRecurringPaymentRestrictionModal = useCallback(() => {
    setIsRecurringPaymentRestrictionModalOpened(false)
  }, [])

  return {
    editablePortfolio,
    projections,
    projectionsFetched,
    presets,
    isPortfolioChanged,
    initialDepositMin,
    lockedPortfolio,
    validation,
    handleChange,
    handleSubmit,
    visibleAttentionModal,
    presetIndex,
    recommendedIndex,
    isPresetChanged,
    presetChangedInfo,
    clientRegistered,
    resetTunnel,
    toggleAttentionModal,
    isRecurringPamentRestrictionModalOpened,
    isRecommendedPresetLoading,
    closeRecurringPaymentRestrictionModal,
    kfdModalOpen,
    closeKfdModal,
  }
}

export { useGrowthProjections }
