import isArray from 'lodash/isArray'

import { sendError } from 'helpers/errorLogging.js'

class ValidationError extends Error {
  data: Record<string, unknown>

  constructor(message: string, data) {
    super(`Validation error: ${message}`)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError)
    }

    this.data = data
  }
}

type ProcessErrorProps = {
  error: Error | string
  forceReset?: boolean
  resets?: Array<() => void>
}

const processError = ({ error, forceReset = false, resets = [] }: ProcessErrorProps): void => {
  // Had to be an local require to prevent cyclic dependencies which caused unit tests to fail
  const { showFailToast } = require('app/redux/actions/ui') // eslint-disable-line @typescript-eslint/no-var-requires

  sendError(error)
  if (forceReset || !(error instanceof ValidationError)) {
    showFailToast()
    if (isArray(resets)) {
      resets.forEach((reset) => {
        reset()
      })
    }
  }
}

const isAjaxError = (error): object => error?.request
const isAuthorizationError = (error): boolean => {
  return (
    (error?.request?.status ?? 0) === 401 ||
    ((error?.response?.status ?? 0) === 403 && error?.response?.data?.code === '2fa_needed')
  )
}

const isOutdatedVersionError = (error): boolean => (error?.request?.status ?? 0) === 410
const isNetworkError = (error): boolean => error?.request?.status === 0
const isIncorrectPasswordError = (error): boolean =>
  error?.response?.status === 400 &&
  error?.response?.data?.non_field_errors?.[0] === `User with this pair of email and password wasn't found`

export {
  ValidationError,
  processError,
  isAjaxError,
  isAuthorizationError,
  isOutdatedVersionError,
  isNetworkError,
  isIncorrectPasswordError,
}
