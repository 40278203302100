import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useSelector, useState } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'
import { combineErrors, phoneRules } from 'helpers/validation.js'

import { fetchContactsFx, $owner } from 'app/effector/contacts'

import { fetchClient as fetchClientActionCreator } from 'app/redux/actions/client'
import { showFailToast } from 'app/redux/actions/ui'

import { useSmsSend } from 'app/pages/Dashboard/UserProfile/hooks'

type UsePhoneConfirmationProps = {
  open: boolean
  parentModule: string
  isTwoFactorAuthSetup: boolean
}

type UsePhoneConfirmationReturnProps = {
  isLoading: boolean
  isClosing: boolean
  blockedText: string
  step: string
  phone: string
  client: {
    country_code_by_ip: string
  }
  phoneValidation: object
  handlePhoneChange: (event: React.ChangeEvent, phone: string) => void
  handlePhoneSubmit: () => void
  handleSuccessCode: () => void
  handleBackToPhone: () => void
  handleClose: () => void
  handleContinue2faSetup: () => void
  handleExit: () => void
  handleConfirmIdentityError: () => void
}

const usePhoneConfirmation = ({
  open,
  parentModule,
  isTwoFactorAuthSetup,
}: UsePhoneConfirmationProps): UsePhoneConfirmationReturnProps => {
  const client = useSelector((state) => state.client)
  const owner = useUnit($owner) ?? {}

  const [phone, setPhone] = useState(owner.phone === '+' ? null : owner.phone)
  const [step, setStep] = useState('PHONE')
  const [isClosing, setIsClosing] = useState(false)
  const [isSuccess, setIsSucess] = useState(false)

  const [fetchClient] = useActions([fetchClientActionCreator])

  const handleConfirmIdentityError = useCallback(() => {
    goTo(
      urlTo(
        isTwoFactorAuthSetup ? 'user-profile.two-factor-setup.password-step' : `${parentModule}.confirm-your-identity`,
      ),
      { scrollToTop: false },
    )

    showFailToast('Confirm your identity')
  }, [isTwoFactorAuthSetup, parentModule])

  const handleSuccessSmsSend = useCallback(() => {
    setStep('SMS')
  }, [])

  const { isLoading, blockedText, isAlreadyInUse, handleSmsSend, clearErrors } = useSmsSend({
    action: isTwoFactorAuthSetup ? 'setup_2fa' : 'confirm_phone',
    onSuccessSmsSend: handleSuccessSmsSend,
    onConfirmIdentityError: handleConfirmIdentityError,
  })

  useEffect(() => {
    if (!open) {
      setStep('PHONE')
      setIsSucess(false)
      setIsClosing(false)
      clearErrors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handlePhoneChange = useCallback(
    (_event, value) => {
      value = value.replace(/[ \-()]/g, '')
      setPhone(value)

      clearErrors()
    },
    [clearErrors],
  )

  const phoneValidation = combineErrors({
    phone: phoneRules(phone, { rules: [!isAlreadyInUse], errors: ['This phone is already used for other account'] }),
  })

  const handlePhoneSubmit = useCallback(() => {
    handleSmsSend({ phone })
  }, [phone, handleSmsSend])

  const handleSuccessCode = useCallback(() => {
    setIsSucess(true)
    fetchClient(null, false, false)
    fetchContactsFx()

    setTimeout(() => {
      goTo(urlTo(`${parentModule}`, {}, isTwoFactorAuthSetup ? { twoFactorAuthEnabled: true } : {}), {
        scrollToTop: false,
      })
    }, 2000)
  }, [parentModule, isTwoFactorAuthSetup, fetchClient])

  const handleBackToPhone = useCallback(() => {
    setStep('PHONE')
  }, [])

  const handleExit = useCallback(() => {
    goTo(urlTo(parentModule), { scrollToTop: false })
  }, [parentModule])

  const handleClose = useCallback(() => {
    if (isTwoFactorAuthSetup && !isClosing && !isSuccess) {
      setIsClosing(true)
      return
    }

    handleExit()
  }, [isClosing, isSuccess, isTwoFactorAuthSetup, handleExit])

  const handleContinue2faSetup = useCallback(() => {
    setIsClosing(false)
  }, [])

  return {
    isLoading,
    isClosing,
    blockedText,
    step,
    phone,
    client,
    phoneValidation,
    handlePhoneChange,
    handlePhoneSubmit,
    handleBackToPhone,
    handleSuccessCode,
    handleClose,
    handleContinue2faSetup,
    handleExit,
    handleConfirmIdentityError,
  }
}

export { usePhoneConfirmation }
