import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { GatewayDest } from 'components/atoms/Gateway'
import {
  DesktopLayout,
  MobileLayout,
  MobileLayoutFooterButton,
  MobileLayoutFullViewport,
} from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'

import { DesktopFooter, MobileFooter } from 'app/containers/Footer'
import { DesktopHeader, MobileHeader } from 'app/containers/Header'

import VerifyEmailForm from './VerifyEmailForm/VerifyEmailForm.jsx'

function VerifyEmail(props): React.ReactElement {
  const { desktop } = useMediaQueries()

  if (desktop) {
    return (
      <DesktopLayout
        header={<DesktopHeader activeItem="dashboard" />}
        content={
          <Paper top={16}>
            <VerifyEmailForm {...props} />
          </Paper>
        }
        footer={<DesktopFooter />}
        noGaps
      />
    )
  }

  return (
    <MobileLayout
      header={<MobileHeader />}
      content={
        <Fragment>
          <MobileLayoutFullViewport>
            <VerifyEmailForm {...props} />
          </MobileLayoutFullViewport>
          <MobileFooter />
        </Fragment>
      }
      footer={
        <MobileLayoutFooterButton>
          <GatewayDest name="verify-email-mobile-footer" />
        </MobileLayoutFooterButton>
      }
      contentPaperSizes={{
        top: 0,
        left: 0,
        right: 0,
      }}
    />
  )
}

export { VerifyEmail }
