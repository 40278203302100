import React from 'react'

import { useMediaQueries } from 'hooks/'

import { Typo } from 'components/_old/Typo/'

import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography/'

type GiaTitleProps = {
  skeleton?: boolean
  title?: React.ReactNode
}

function GiaTitle({ skeleton = false, title }: GiaTitleProps): React.ReactElement {
  const { desktop } = useMediaQueries()

  return (
    <Skeleton shown={skeleton} inline mix>
      <Typography
        tag="h2"
        size={desktop ? 18 : 16}
        lineHeight="small"
        weight="semibold"
        data-test-id="groupTitleText"
        className="Text_Not_selectable"
        inline={desktop}
      >
        {title ?? <Typo>General</Typo>}
      </Typography>
    </Skeleton>
  )
}

export { GiaTitle }
