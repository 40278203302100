import { IsaDetails } from './IsaDetails.js'

export const IsaMixin = (superclass) =>
  /**
   * @class
   * @typedef {Object} IsaMixin
   */
  class extends superclass {
    constructor(...args) {
      super(...args)

      this.regulatory_type = 'ISA'

      this.isa = new IsaDetails()
    }

    getRules() {
      const parentRules = typeof super.getRules === 'function' ? super.getRules() : {}

      return {
        ...parentRules,
        isa: [[(details) => IsaDetails.createFromObject(details)]],
      }
    }
  }
