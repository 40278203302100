import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

import { useGetStarted } from './hooks'

const GetStarted = ({
  params: { id: selectedGoalId },
  activeStep,
  prevStep,
  progress,
  redirectToPrevStep,
  redirectToNextStep,
  noDescription,
  noInputLabel,
}) => {
  const { desktop } = useMediaQueries()

  /** @type {boolean} */
  const isFirstStep = activeStep?.firstStep ?? false
  const { goal, validation, handleInputChange, handleGoNext, handleBack, handleCancel } = useGetStarted({
    selectedGoalId,
    redirectToPrevStep,
    redirectToNextStep,
    isFirstStep,
  })

  const props = {
    goal,
    validation,
    handleInputChange,
    onSubmit: handleGoNext,
    handleBack,
    handleCancel,
    progress,
    activeStep,
    prevStep,
    redirectToNextStep,
    noDescription,
    noInputLabel,
  }

  return <div>{desktop ? <Desktop {...props} /> : <Mobile {...props} />}</div>
}

GetStarted.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  progress: PropTypes.number,
  activeStep: PropTypes.object,
  prevStep: PropTypes.object,
  redirectToPrevStep: PropTypes.func,
  redirectToNextStep: PropTypes.func,
  noDescription: PropTypes.bool,
  noInputLabel: PropTypes.bool,
}

export { GetStarted }
