import { PortfolioSimpleStatsItem } from '../../PortfolioSimpleStatsItem/PortfolioSimpleStatsItem.js'

export const IncomeMixin = (superclass) =>
  /**
   * @class
   * @typedef {Object} IncomeMixin
   */
  class extends superclass {
    constructor(...args) {
      super(...args)

      this.preset_type = 'INCOME'

      this.twr = new PortfolioSimpleStatsItem()
    }

    getRules() {
      const parentRules = typeof super.getRules === 'function' ? super.getRules() : {}

      return {
        ...parentRules,
        twr: [[(item) => PortfolioSimpleStatsItem.createFromObject(item)]],
      }
    }
  }
