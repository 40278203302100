import React from 'react'

import { NewBank } from './NewBank.jsx'

export const routes = {
  module: 'new-bank',
  path: 'new-bank/',
  component: function Component(props) {
    return <NewBank {...props} />
  },
}
