import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'
import { useSecurityUniverse } from './hooks'
import { useRemovableSecurities } from '../Security/hooks'

import { Abandon } from 'app/pages/Dashboard/Goals/DIY/EditWeights/Abandon'
import { Restrictions } from 'app/pages/Dashboard/Goals/DIY/Restrictions'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

import { AddSecuritiesCard } from './components/AddSecuritiesCard'
import { AddSecuritiesMobileFooter } from './components/AddSecuritiesMobileFooter'
import { AllEtfs } from './components/AllEtfs'
import { PortfolioTabs } from 'app/pages/Dashboard/Goals/ManagedPortfolio/components/PortfolioTabs'
import { RecentlyViewedEtfs } from './components/RecentlyViewedEtfs'
import { SearchField } from 'components/molecules/SearchField'
import { SecurityUniverseDiscovery } from './components/SecurityUniverseDiscovery/SecurityUniverseDiscovery'
import { SecurityUniverseFilters } from './components/SecurityUniverseFilters'

import './SecurityUniverse.css'

const SecurityUniverse = ({ location = {}, routes }) => {
  const { desktop } = useMediaQueries()
  const portfolioId = useMemo(
    () => (location?.query?.portfolioId ? parseInt(location.query.portfolioId, 10) : null),
    [location?.query?.portfolioId],
  )

  const {
    abandonModalOpen,
    activeTabIndex,
    allSecurities,
    areCollectionsBeingLoaded,
    filters,
    filtersState,
    isDiscoveryDisplayed,
    isDiscoveryVisible,
    isLoading,
    portfolioSecurities,
    portfolioTitle,
    restrictionModalOpen,
    restrictionModalType,
    roundedSecuritiesCount,
    scrollableElementRef,
    searchValue,
    securities,
    securitiesWasLoadedOnce,
    tabItems,
    version,
    getSecurityDataByIndex,
    getSecurityLink,
    handleAbandonModalClose,
    handleBack,
    handleCancel,
    handleContactUs,
    handleFiltersClear,
    handleGoToEditWeights,
    handleRemoveSecurity,
    handleRestrictionModalClose,
    handleSaveScrollPosition,
    handleSearch,
    handleSecurityClick,
    navigateByTab,
  } = useSecurityUniverse(portfolioId, location, location, routes)

  const removableSecuritiesIds = useRemovableSecurities(portfolioId)
  const addedItemsCount = portfolioSecurities?.length ?? 0
  const hasAddedSecurities = addedItemsCount > 0

  const allEtfsNode = (
    <AllEtfs
      areCollectionsBeingLoaded={areCollectionsBeingLoaded}
      isDiscoveryDisplayed={isDiscoveryDisplayed}
      isDiscoveryVisible={isDiscoveryVisible}
      isLoading={isLoading}
      portfolioId={portfolioId}
      scrollableElementRef={scrollableElementRef}
      securities={securities}
      securitiesWasLoadedOnce={securitiesWasLoadedOnce}
      getSecurityDataByIndex={getSecurityDataByIndex}
      getSecurityLink={getSecurityLink}
      handleContactUs={handleContactUs}
      handleSecurityClick={handleSecurityClick}
    />
  )

  const discoverNode = (
    <SecurityUniverseDiscovery
      allSecurities={allSecurities}
      areCollectionsBeingLoaded={areCollectionsBeingLoaded}
      isDiscoveryDisplayed={isDiscoveryDisplayed}
      isLoading={isLoading}
      portfolioId={portfolioId}
      portfolioSecurities={portfolioSecurities}
      requestRegulatoryType={location?.query?.requestRegulatoryType}
      scrollableElementRef={scrollableElementRef}
      securities={securities}
      securitiesWasLoadedOnce={securitiesWasLoadedOnce}
      getSecurityDataByIndex={getSecurityDataByIndex}
      getSecurityLink={getSecurityLink}
      handleSaveScrollPosition={handleSaveScrollPosition}
      handleSecurityClick={handleSecurityClick}
      navigateByTab={navigateByTab}
    />
  )

  const recentlyViewedEtfsNode = (
    <RecentlyViewedEtfs handleSecurityClick={handleSecurityClick} getSecurityLink={getSecurityLink} />
  )

  const tabsNode = (
    <PortfolioTabs
      justifyContent="start"
      tabItems={tabItems}
      activeTabIndex={activeTabIndex}
      navigateByTab={navigateByTab}
    />
  )

  const addSecuritiesCardNode = (
    <AddSecuritiesCard
      version={version}
      hasAddedSecurities={hasAddedSecurities}
      portfolioTitle={portfolioTitle}
      addedItemsCount={addedItemsCount}
      portfolioSecurities={portfolioSecurities}
      removableSecuritiesIds={removableSecuritiesIds}
      handleRemoveSecurity={handleRemoveSecurity}
      location={location}
    />
  )

  const addSecuritiesMobileFooterNode = (
    <AddSecuritiesMobileFooter
      addedItemsCount={addedItemsCount}
      portfolioTitle={portfolioTitle}
      isButtonEnabled={addedItemsCount > 0}
      handleGoToEditWeights={handleGoToEditWeights}
    />
  )

  const searchBarNode = (
    <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by name, ticker or keyword" />
  )

  const filtersPanelNode = useMemo(() => {
    const { ordering, asset_class, provider, ...moreFilters } = filtersState
    const isMoreSelected = Object.values(moreFilters).flat().length > 0

    return <SecurityUniverseFilters filters={filters} moreShown={desktop ? isMoreSelected : false} />
  }, [filters, filtersState, desktop])

  const props = {
    addSecuritiesCardNode,
    addSecuritiesMobileFooterNode,
    allEtfsNode,
    discoverNode,
    filtersPanelNode,
    isDiscoveryDisplayed,
    isDiscoveryVisible,
    location,
    portfolioSecurities,
    recentlyViewedEtfsNode,
    roundedSecuritiesCount,
    scrollableElementRef,
    searchBarNode,
    tabsNode,
    version,
    handleBack,
    handleCancel,
    handleFiltersClear,
    handleGoToEditWeights,
    handleSearch,
    navigateByTab,
  }

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}
      <Abandon open={abandonModalOpen} onClose={handleAbandonModalClose} onContinue={handleCancel} />
      {restrictionModalType && (
        <Restrictions
          portfolioId={portfolioId}
          open={restrictionModalOpen}
          onClose={handleRestrictionModalClose}
          type={restrictionModalType}
        />
      )}
    </Fragment>
  )
}

SecurityUniverse.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      portfolioId: PropTypes.string,
      search: PropTypes.string,
    }),
  }),
}

export { SecurityUniverse }
