import { isTouch } from 'helpers/mobile'

// get tap based on touch or mouse event
const getTouchPosition = (event) => ({
  x: event.clientX || event.changedTouches[0].clientX || 0,
  y: event.clientY || event.changedTouches[0].clientY || 0,
})

const getEventElement = (event) => {
  if (isTouch()) {
    const position = getTouchPosition(event)

    return document.elementFromPoint(position.x, position.y)
  }

  return event.target
}

export { getEventElement }
