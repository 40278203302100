import { SignificantControl } from './SignificantControl'

export class SignificantControlList {
  constructor(items = []) {
    /** @type {SignificantControl[]} */
    this.controls = items.map((item) => SignificantControl.createFromObject(item))
  }

  /**
   * @param item
   */
  receiveControl(item) {
    const index = this.getControlIndex(item.id)

    if (index > -1) {
      this.controls.splice(index, 1, item)
      return this
    }

    this.controls.push(item)
    return this
  }

  getControl(id) {
    return this.controls.find((item) => item.id === id)
  }

  deleteControl(id) {
    const index = this.getControlIndex(id)

    if (index > -1) {
      this.controls.splice(index, 1)
    }

    return this
  }

  /**
   * @private
   * @param {number} id
   * @returns {number}
   */
  getControlIndex(id) {
    return this.controls.findIndex((item) => item.id === id)
  }
}
