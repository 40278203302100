import { useUnit } from 'effector-react'

import { useCallback, useEffect, useMemo, useSelector, useState } from 'hooks'

import { format as formatMoney } from 'helpers/money'

import { $allowancesStore } from 'app/effector/allowances'
import { $dictsStore } from 'app/effector/dicts'
import { $quickStartsStore } from 'app/effector/quickStart'

import { regulatoryTypes, manageTypes } from 'constants/portfolio'
import { quickStartStatuses } from 'constants/quickStart'
import { MAX_SINGLE_PAYMENT_AMOUNT, MIN_SINGLE_PAYMENT_AMOUNT } from 'constants/validations.js'

const useFundsAmount = (portfolio) => {
  const { one_off_payment: oneOffPayment, first_topup: firstTopup } = portfolio
  const { initialDepositMin } = useUnit($dictsStore)
  const portfolios = useSelector((state) => state.portfolios.list)

  const { getQuickStartByPortfolioId } = useUnit($quickStartsStore)
  const quickStart = getQuickStartByPortfolioId(portfolio.id)
  const isLumpSumSetupInProgress = quickStart?.status === quickStartStatuses.ACTIVE && !quickStart?.frequency
  const initialAmount = isLumpSumSetupInProgress ? quickStart?.amount : oneOffPayment

  const { allowances } = useUnit($allowancesStore)
  const sippTotalAllowance = parseFloat(allowances?.sipp?.total_allowance ?? 0)
  const isaRemainingAllowance = parseFloat(allowances?.isa?.remaining_allowance ?? 0)

  const [amount, changeAmount] = useState(initialAmount ?? null)

  const hasFundedPortfolios = portfolios.some((portfolio) => portfolio.first_topup)
  const isCashPortfolio = portfolio.manage_type === manageTypes.CASH
  const isFirstPayment = isCashPortfolio ? !hasFundedPortfolios : !firstTopup

  const handleChange = useCallback(
    (_event, value) => {
      changeAmount(value)
    },
    [changeAmount],
  )

  useEffect(() => {
    if (!amount && isFirstPayment) {
      changeAmount(portfolio.initial_deposit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio.id])

  const isIsaLimitReached =
    portfolio.regulatory_type === regulatoryTypes.ISA && parseFloat(amount ?? 0) > isaRemainingAllowance

  const maxAmount = useMemo(() => {
    if (portfolio.regulatory_type === regulatoryTypes.ISA) {
      return isaRemainingAllowance
    }
    return MAX_SINGLE_PAYMENT_AMOUNT
  }, [portfolio, isaRemainingAllowance])

  const validation = {
    amount: {
      rules: [
        amount > 0,
        (isFirstPayment && amount >= initialDepositMin) || !isFirstPayment,
        amount <= maxAmount,
        amount >= MIN_SINGLE_PAYMENT_AMOUNT,
      ],
      errors: [
        'Please enter a value',
        `Must be at least ${formatMoney(initialDepositMin)}`,
        `Maximum amount is ${formatMoney(maxAmount)}`,
        `Must be at least ${formatMoney(MIN_SINGLE_PAYMENT_AMOUNT)}`,
      ],
    },
  }

  return {
    amount,
    validation,
    isIsaLimitReached,
    sippTotalAllowance,
    isaRemainingAllowance,
    handleChange,
  }
}

export { useFundsAmount }
