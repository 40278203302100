enum CollectionsGroupLayout {
  BLOCK = 'block',
  SQUARE = 'square',
  CIRCLE = 'circle',
  FEATURED = 'featured',
  INLINE = 'inline',
  HIDDEN = 'hidden',
}

type Collection = {
  id: number
  title: string
  subtitle: string | null
  subtitle_link?: string
  description: string
  short_description: string | null
  style: string
  slug: string
  icon: string
  icon_dark_theme?: string
  logo: string
  logo_dark_theme?: string
  background_color?: string
  filters: string[]
  background_small: string
  background_full: string
  background_logo?: string
  recommended_collection_ids: number[] | null
  security_ids?: []
}

type CollectionsGroup = {
  title: string
  slug: string
  description: string | null
  layout: CollectionsGroupLayout
  collections: Collection[]
  filters: string[]
}

export { type Collection, type CollectionsGroup, CollectionsGroupLayout }
