import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

export class SecurityTotalReturnsHistoryItem extends Entity {
  date: string | null
  total_returns: number | null

  constructor() {
    super()
    this.date = null
    this.total_returns = null
  }

  getRules(): {
    date: [[(date) => string | null]]
    total_returns: [[(total_returns) => number | null]]
  } {
    return {
      date: [[rules.iso8601UTC]],
      total_returns: [[rules.float]],
    }
  }
}
