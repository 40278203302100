import React from 'react'

import { useUnit } from 'effector-react'

import { useCallback, useMemo, useState } from 'hooks'

import { getDateRangeLabel } from 'helpers/date'

import { $dictsStore } from 'app/effector/dicts'

import { Filter } from 'components/organisms/Filter'
import type { FilterValue } from 'components/organisms/Filter'

import { SecurityFilterLabel } from '../components/SecurityFilterLabel'

import { manageTypes } from 'constants/portfolio'

function normalize(rawValue: string | string[]): Array<string | number> {
  if (!rawValue) {
    return []
  }
  if (Array.isArray(rawValue)) {
    return rawValue
  }
  const value = Number(rawValue)

  return isNaN(value) ? [rawValue] : [value]
}

enum TransactionTypeFilterValue {
  TOPUP = 'topup',
  WITHDRAWAL = 'withdrawals',
  BUY = 'purchases',
  SELL = 'sales',
  INCOME = 'income',
  FEE = 'fee',
  TRANSFER = 'transfers',
}

type FilterProps = {
  name: string
  type: 'radio' | 'checkbox'
  values: FilterValue[]
}

type Filters = {
  types: FilterProps
  date: FilterProps
  portfolio_id: FilterProps
  security_id: FilterProps
}

type FiltersState = {
  types: string[]
  date: string[]
  portfolio_id: number[]
  security_id: number[]
}

type UseFiltersInterface = {
  filters: React.ReactElement[]
  filtersState: FiltersState
}

const useFilters = (query, portfolios, securities, isSecurityTransactionsPage): UseFiltersInterface => {
  const { dicts } = useUnit($dictsStore)
  const transactionTypeFilters = (dicts.transaction_type_filters ?? []).filter(
    (type) => type.filter_param !== 'nominees_transfers',
  )

  const initialFiltersState = useMemo(
    () => ({
      types: normalize(query?.types),
      date: normalize(query?.date),
      date_from: normalize(query?.date_from),
      date_to: normalize(query?.date_to),
      portfolio_id: normalize(query?.portfolio_id),
      security_id: normalize(query?.security_id),
    }),
    [query?.types, query?.date, query?.date_from, query?.date_to, query?.portfolio_id, query?.security_id],
  )

  const [filtersState, setFiltersState] = useState<FiltersState>(initialFiltersState)

  const resetFilters = useCallback(() => {
    setFiltersState(initialFiltersState)
  }, [setFiltersState, initialFiltersState])

  const handleFilterChange = useCallback(
    (name) => (value) => {
      if (name === 'date' && value.length > 1) {
        setFiltersState((state) => ({ ...state, date: ['custom'], date_from: [value[0]], date_to: [value[1]] }))
        return
      }
      if (name === 'date' && value.length < 2) {
        setFiltersState((state) => ({ ...state, date: value, date_from: [], date_to: [] }))
        return
      }
      setFiltersState((state) => ({ ...state, [name]: value }))
    },
    [],
  )

  const filters: Filters = useMemo(() => {
    const portfoliosOptions = portfolios
      .filter((portfolio) => portfolio.hasTopup)
      .filter((portfolio) => {
        if (isSecurityTransactionsPage) {
          return portfolio.manageType === manageTypes.DIY
        }

        return portfolio
      })

    const securitiesOptions = securities.map((item) => ({
      name: <SecurityFilterLabel icon={item.logo_uri} title={item.title} ticker={item.ticker} />,
      selectedName: <SecurityFilterLabel icon={item.logo_uri} title={item.title} />,
      value: item.id,
    }))

    const customDateName = getDateRangeLabel(filtersState.date_from[0], filtersState.date_to[0])

    const filters = {
      types: {
        name: 'Any transaction',
        type: 'radio',
        values: transactionTypeFilters.map((filter) => ({ name: filter.title, value: filter.filter_param })),
        'data-test-id': 'transactionTypeFilter',
      },
      date: {
        name: 'All time',
        type: 'radio',
        values: [
          { name: 'Last month', value: 'month' },
          { name: 'Last year', value: 'year' },
          { name: 'Current tax year', value: 'currentTaxYear' },
          { name: 'Previous tax year', value: 'prevTaxYear' },
          { name: customDateName, value: 'custom' },
        ],
        dropdown: 'dateRange',
        'data-test-id': 'dateFilter',
      },
      portfolio_id: {
        name: 'Any portfolio',
        type: 'radio',
        values: portfoliosOptions,
        'data-test-id': 'portfolioFilter',
      },
      security_id: {
        name: 'Any ETF',
        type: 'radio',
        values: securitiesOptions,
        'data-test-id': 'securitiesFilter',
      },
    }

    if (isSecurityTransactionsPage) {
      delete filters.types
    }

    return filters
  }, [
    portfolios,
    securities,
    isSecurityTransactionsPage,
    filtersState.date_from,
    filtersState.date_to,
    transactionTypeFilters,
  ])

  const filtersElements = Object.keys(filters).map((filter) => {
    const props = filters[filter]

    return <Filter key={filter} {...props} selected={filtersState[filter]} onChange={handleFilterChange(filter)} />
  })

  return {
    filters: filtersElements,
    filtersState,
    resetFilters,
  }
}

export { useFilters, TransactionTypeFilterValue }
