import React from 'react'

import { useRef, useCallback, useMemo, useEffect, useImperativeHandle } from 'hooks'

import moment, { formatDateToBackend } from 'helpers/date'
import { isDarkTheme } from 'helpers/palette'
import { bulkValidate, combineErrors } from 'helpers/validation'

import Label, { LabelField, LabelText } from 'components/_old/Label/Label.jsx'
import { Typo } from 'components/_old/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import './DateRangeInputs.css'

type DateRangeDate = Date | string | null

type DateRangeInputValidationPerInput = {
  rules: boolean[]
  errors: string
}

type DateRangeInputValidation = {
  dateFrom: DateRangeInputValidationPerInput
  dateTo: DateRangeInputValidationPerInput
}

function getDateRangeInputsDefaultValidation(dateFrom: DateRangeDate, dateTo: DateRangeDate): DateRangeInputValidation {
  return combineErrors({
    dateFrom: {
      rules: [
        Boolean(dateFrom),
        moment(dateFrom).unix() < moment().unix(),
        dateTo ? moment(dateFrom).unix() <= moment(dateTo).unix() : true,
      ],
      errors: ['Date cannot be empty', 'Date cannot be in future', 'Date cannot be in future'],
    },
    dateTo: {
      rules: [Boolean(dateTo)],
      errors: ['Date cannot be empty'],
    },
  }) as DateRangeInputValidation
}

type DateRangeInputsHandle = {
  getIsValid: () => boolean
}

type DateRangeInputsProps = {
  dateFrom: Date | string | null
  dateTo: Date | string | null
  validation?: DateRangeInputValidation
  handleDateFrom: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleDateTo: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const DateRangeInputs = React.forwardRef<DateRangeInputsHandle, DateRangeInputsProps>(
  (
    {
      dateFrom: dateFromRaw,
      dateTo: dateToRaw,
      handleDateFrom,
      handleDateTo,
      validation: validationRaw,
    }: DateRangeInputsProps,
    ref,
  ): React.ReactElement => {
    const dateFromInput = useRef<HTMLInputElement>()
    const inputClasses = `DateRangeInputs-Input ${isDarkTheme() ? 'DateRangeInputs-Input_dark' : ''}`

    const getDateString = useCallback((dateRaw: Date | string | null): string => {
      if (!dateRaw) {
        return ''
      }

      if (dateRaw instanceof Date) {
        return formatDateToBackend(dateRaw) ?? ''
      }

      return dateRaw
    }, [])
    const dateFrom: string = useMemo(() => getDateString(dateFromRaw), [dateFromRaw, getDateString])
    const dateTo: string = useMemo(() => getDateString(dateToRaw), [dateToRaw, getDateString])

    const validation = validationRaw ?? getDateRangeInputsDefaultValidation(dateFrom, dateTo)

    useEffect(
      () => {
        if (!dateFrom) {
          dateFromInput.current?.focus() // hack for mobile safari that fix initial value in the input field
        }
      },
      // had to run only on mount
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    )

    useImperativeHandle(ref, () => ({
      getIsValid: () => bulkValidate(validation),
    }))

    return (
      <Typography color="additional">
        <ColumnarLayout mods={{ padding: 'no' }}>
          <Column>
            <Paper right={8}>
              <Validate rules={validation.dateFrom.rules}>
                {(isValid, brokenRule, handleValidateFocus, handleBlur) => (
                  <Label errorMessage={validation.dateFrom.errors[brokenRule]} data-test-id="dateFromInput">
                    <LabelText replaceWithError>
                      <Typo>From</Typo>
                    </LabelText>
                    <LabelField>
                      <input
                        ref={dateFromInput}
                        className={inputClasses}
                        type="date"
                        value={dateFrom}
                        onBlur={handleBlur}
                        onChange={handleDateFrom}
                        onFocus={handleValidateFocus}
                      />
                    </LabelField>
                  </Label>
                )}
              </Validate>
            </Paper>
          </Column>
          <Column>
            <Paper left={8}>
              <Validate rules={validation.dateTo.rules}>
                {(isValid, brokenRule, handleValidateFocus, handleBlur) => (
                  <Label errorMessage={validation.dateFrom.errors[brokenRule]} data-test-id="dateToInput">
                    <LabelText replaceWithError>
                      <Typo>To</Typo>
                    </LabelText>
                    <LabelField>
                      <input
                        className={inputClasses}
                        type="date"
                        value={dateTo}
                        onBlur={handleBlur}
                        onChange={handleDateTo}
                        onFocus={handleValidateFocus}
                      />
                    </LabelField>
                  </Label>
                )}
              </Validate>
            </Paper>
          </Column>
        </ColumnarLayout>
      </Typography>
    )
  },
)
DateRangeInputs.displayName = 'DateRangeInputs'

export {
  DateRangeInputs,
  type DateRangeInputsProps,
  type DateRangeInputsHandle,
  getDateRangeInputsDefaultValidation,
  type DateRangeDate,
  type DateRangeInputValidationPerInput,
  type DateRangeInputValidation,
}
