import React from 'react'

import { Captcha } from './Captcha'
import { DisableTwoFactorAuth } from './DisableTwoFactorAuth'
import { DisableTwoFactorAuthPassword } from './DisableTwoFactorAuthPassword'
import { FirstStep } from './FirstStep'
import { NotificationStep } from './NotificationStep'
import { PasswordStep } from './PasswordStep'

const disable2FARoutes = [
  {
    index: true,
    isNewLayout: true,
    module: 'first-step',
    component: function Component(props) {
      return <DisableTwoFactorAuth {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'password',
    path: 'password/',
    component: function Component(props) {
      return <DisableTwoFactorAuthPassword {...props} />
    },
  },
]

const childRoutes = [
  {
    index: true,
    isNewLayout: true,
    module: 'first-step',
    component: function Component(props) {
      return <FirstStep {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'password-step',
    path: 'password/',
    component: function Component(props) {
      return <PasswordStep {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'protection',
    path: 'protection/',
    component: function Component(props) {
      return <Captcha {...props} />
    },
  },
  {
    isNewLayout: true,
    module: 'notification-step',
    path: 'notification/',
    component: function Component(props) {
      return <NotificationStep {...props} />
    },
  },
  {
    module: 'disable-two-factor',
    path: 'disable-two-factor/',
    indexRoute: disable2FARoutes.find((route) => route.index),
    childRoutes: disable2FARoutes,
  },
]

export default {
  module: 'two-factor-setup',
  path: 'two-factor-setup/',
  name: 'twoFactorSetup',
  indexRoute: childRoutes.find((route) => route.index),
  childRoutes,
}
