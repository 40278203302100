import { useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import { useAnalytics } from 'app/pages/Dashboard/Analytics/hooks'

/**
 * @typedef Holding
 * @type {object}
 * @property {string} name
 * @property {string} logo
 * @property {number} actual
 * @property {logo} color
 */

/**
 * @param {number | string} securityId
 * @return {{ isLoading: boolean, holdings: *[], sectors: *[], regions: *[]}}
 */
const useSecurityAnalytics = (securityId, query, searchQueryParam = 'search') => {
  const { isLoading, sectors, regions, filters, searchValue, handleSearch, ...analytics } = useAnalytics({
    securityId,
    tunnelQuery: query,
    searchQueryParam,
  })

  const holdings = useMemo(() => {
    return [
      [analytics?.stocks?.items, palette.stocks],
      [analytics?.bonds?.items, palette.bonds],
      [analytics?.alternatives?.items, palette.alternatives],
      [analytics?.cash?.items, palette.cash],
    ].reduce((array, [items, color]) => {
      return [...array, ...items.map(({ name, logo, actual, id }) => ({ name, logo, actual, color, id }))]
    }, [])
  }, [analytics?.alternatives?.items, analytics?.bonds?.items, analytics?.cash?.items, analytics?.stocks?.items])

  return {
    isLoading,
    holdings,
    sectors,
    regions,
    filters,
    searchValue,
    handleSearch,
  }
}

export { useSecurityAnalytics }
