import { useCallback, useEffect, useSelector, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import {
  isClientNewOrNotCompleted as selectIsClientNewOrNotCompleted,
  isClientCompleted as selectIsClientCompleted,
} from 'app/redux/selectors'

import { regulatoryTypes } from 'constants/portfolio'

type Step = 'INITIAL' | 'TERMS' | 'FINISH_REGISTRATION' | 'NOT_APPROVED'

const steps: Record<Step, Step> = {
  INITIAL: 'INITIAL',
  TERMS: 'TERMS',
  FINISH_REGISTRATION: 'FINISH_REGISTRATION',
  NOT_APPROVED: 'NOT_APPROVED',
}

type UseOpenSippModalProps = {
  isOpen: boolean
  isTransfer: boolean
  query: {
    back?: string
    registrationBack?: string
  }
}

type UseOpenSippModalReturnProps = {
  step: Step
  handleClose: () => void
  handleContinue: () => void
  handleBack: () => void
}

const useOpenSippModal = ({ isOpen, isTransfer, query }: UseOpenSippModalProps): UseOpenSippModalReturnProps => {
  const { back, registrationBack, ...restQuery } = query ?? {}
  const [step, setStep] = useState<Step>(steps.INITIAL)

  const isClientNewOrNotCompleted = useSelector(selectIsClientNewOrNotCompleted)
  const isClientCompleted = useSelector(selectIsClientCompleted)

  useEffect(() => {
    if (!isOpen || !isTransfer) {
      setStep(steps.INITIAL)
    }
  }, [isOpen, isTransfer])

  const handleClose = useCallback(() => {
    goTo(back ?? urlTo('dashboard'))
  }, [back])

  const handleContinue = useCallback(() => {
    if (step === steps.INITIAL) {
      trackEvent({ action: 'sipp_promo1_continued' })
      setStep(steps.TERMS)
    }

    if (step === steps.TERMS) {
      trackEvent({ action: 'sipp_promo2_continued' })

      if (isClientNewOrNotCompleted) {
        setStep(steps.FINISH_REGISTRATION)
        return
      }

      if (isClientCompleted) {
        setStep(steps.NOT_APPROVED)
        return
      }

      let next: string | null = null

      if (isOpen) {
        next = urlTo('create-new-portfolio', null, { regulatoryType: regulatoryTypes.SIPP })
      }

      if (isTransfer) {
        next = urlTo('dashboard', null, { transferSipp: true })
      }

      goTo(urlTo('dashboard.sipp-declaration', null, { ...restQuery, back: back ?? urlTo('dashboard'), next }))

      return
    }

    if (step === steps.FINISH_REGISTRATION) {
      let next: string | null = null

      if (isOpen) {
        next = urlTo('create-new-portfolio', null, { regulatoryType: regulatoryTypes.SIPP })
      }

      if (isTransfer) {
        next = urlTo('dashboard', null, { transferSipp: true })
      }

      const nextUrl = urlTo('dashboard.sipp-declaration', null, {
        ...restQuery,
        back: back ?? urlTo('dashboard'),
        next,
      })
      const backUrl = registrationBack ?? back ?? urlTo('dashboard')

      goTo(urlTo('dashboard.setup-account', null, { isOpeningSipp: true, next: nextUrl, back: backUrl }))
    }
  }, [step, isClientNewOrNotCompleted, isClientCompleted, restQuery, back, registrationBack, isOpen, isTransfer])

  const handleBack = useCallback(() => {
    if (step === steps.TERMS) {
      setStep(steps.INITIAL)
    }

    if (step === steps.FINISH_REGISTRATION || step === steps.NOT_APPROVED) {
      setStep(steps.TERMS)
    }
  }, [step])

  return {
    step,
    handleClose,
    handleContinue,
    handleBack,
  }
}

export { useOpenSippModal, steps }
