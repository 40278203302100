import React, { Fragment } from 'react'

import { useMediaQueries, useSelector } from 'hooks'

import { palette } from 'helpers/palette'

import { isClientFilledPersonalData } from 'app/redux/selectors/client.js'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { LabelNative } from 'components/atoms/LabelNative/LabelNative'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { useThirdPartyTradeReporting } from './hooks'
import { ThirdPartyTradeReportingModal } from './Modal'

function ThirdPartyTradeReporting(): React.ReactElement | null {
  const { desktop } = useMediaQueries()
  const thirdPartyTradeReportingProps = useThirdPartyTradeReporting()
  const { thirdPartyEmail, isThirdPartyEmailSet, openModal } = thirdPartyTradeReportingProps
  const isThirdPartyTradeReportingAvailable = useSelector(isClientFilledPersonalData)

  if (!isThirdPartyTradeReportingAvailable) {
    return null
  }

  return (
    <Fragment>
      <Width size={desktop ? 36 : null}>
        <LabelNative labelText="Third party trade reporting">
          <Link mods={{ color: 'black' }} onClick={openModal}>
            <ItemWithIcon
              space={24}
              iconPosition="right"
              icon={
                <ItemWithIcon
                  space={4}
                  iconPosition="right"
                  content={
                    <Typography size={16} color={isThirdPartyEmailSet ? 'secondary_default' : 'minor'}>
                      <Typo>{isThirdPartyEmailSet ? 'On' : 'Off'}</Typo>
                    </Typography>
                  }
                  iconVerticalAlign="center"
                  contentVerticalAlign="center"
                  icon={<Icon type="arrow-right-small-24" size={24} color={palette['content-on-background-minor']} />}
                />
              }
              content={
                <Fragment>
                  <Typography size={16} lineHeight="small" color="inherit">
                    <Typo>Send notification to third party</Typo>
                  </Typography>
                  <Paper top={4}>
                    <Typography size={14} lineHeight="small" color="minor">
                      <Typo>Additional third party email to receive trade notifications on your accounts</Typo>
                    </Typography>
                  </Paper>
                  {isThirdPartyEmailSet && (
                    <Paper top={16}>
                      <Typography size={14} lineHeight="small" color="minor">
                        <Typo>{thirdPartyEmail}</Typo>
                      </Typography>
                    </Paper>
                  )}
                </Fragment>
              }
              iconVerticalAlign="top"
              contentVerticalAlign="top"
            />
          </Link>
        </LabelNative>
      </Width>
      <ThirdPartyTradeReportingModal {...thirdPartyTradeReportingProps} />
    </Fragment>
  )
}

export { ThirdPartyTradeReporting }
