import React, { Fragment } from 'react'

import { useMemo, useState } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'
import { PriceInformation } from 'components/molecules/PriceInformation'
import { SearchField } from 'components/molecules/SearchField/index.js'
import { SecurityTitle } from 'components/molecules/SecurityTitle'

import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { SecurityChart } from 'components/organisms/SecurityChart'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'

import {
  SecurityDescription,
  LatestMarketPriceNote,
  SecurityDocuments,
} from 'app/pages/Dashboard/Goals/DIY/SecuritySlice/components'
import { LabelsDescription } from 'app/pages/Securities/Security/components'

import { type SharedSecurityComponentData } from '../../types'

const Mobile = ({
  isAnalyticsVisible,
  equities,
  sectors,
  regions,
  security,
  securityLabels,
  goToSecurityAnalytics,
  handleGoBack,
  selectedPeriod,
  chartData,
  periodPriceData,
  searchValue,
  handleSearch,
  handlePeriodSelect,
}: SharedSecurityComponentData): React.ReactElement => {
  const analytics = useMemo(() => ({ holdings: equities, sectors, regions }), [equities, sectors, regions])

  const [activeTab, setActiveTab] = useState('holdings')
  const tabs = useMemo(
    () => [
      { title: 'Holdings', id: 'holdings', isActive: activeTab === 'holdings', withSearch: handleSearch },
      { title: 'Regions', id: 'regions', isActive: activeTab === 'regions' },
      { title: 'Sectors', id: 'sectors', isActive: activeTab === 'sectors' },
    ],
    [activeTab, handleSearch],
  )
  const activeTabObject: { title?: string; id?: string; isActive?: boolean; withSearch?: boolean } = useMemo(
    () => tabs.find((tab) => tab.isActive) ?? {},
    [tabs],
  )

  const headerNode = useMemo(() => <NavigationBar leftPartText="Back" onLeftPartClick={handleGoBack} />, [handleGoBack])

  const priceInformation = useMemo(
    () => (
      <Fragment>
        <Typography size={32} weight="semibold" lineHeight="small">
          <PoundsWithPence amount={security?.share_price ?? 0} showZeroPence />
        </Typography>
        <Paper bottom={16}>
          <LatestMarketPriceNote date={security?.price_dt} />
        </Paper>

        {periodPriceData && (
          <PriceInformation
            price={security?.share_price ?? 0}
            period={periodPriceData}
            description={
              <Typo>
                Change in share price over time in pounds (£) as well as percentage terms.
                <br />
                <br />
                Note this will not include any dividend income paid out from this security over the period.
              </Typo>
            }
          />
        )}
      </Fragment>
    ),
    [periodPriceData, security?.price_dt, security?.share_price],
  )

  const chart = useMemo(
    () => (
      <Paper top={32}>
        <SecurityChart
          period={selectedPeriod}
          data={chartData}
          periodPriceData={periodPriceData}
          handlePeriodSelect={handlePeriodSelect}
          topContent={priceInformation}
          topContentOffset={40}
        />
      </Paper>
    ),
    [selectedPeriod, chartData, periodPriceData, handlePeriodSelect, priceInformation],
  )

  const details = useMemo(
    () =>
      security && security?.description?.length > 0 ? (
        <Paper top={32}>
          <SecurityDescription description={security?.description} />
        </Paper>
      ) : null,
    [security],
  )

  const analyticsListNode = useMemo(() => {
    const { id, withSearch } = activeTabObject

    if (id && analytics[id].length > 0) {
      const limitedList = (
        withSearch && searchValue.length >= 2
          ? analytics[id].filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
          : analytics[id]
      ).slice(0, 5)

      return (
        <Fragment>
          <Paper top={24}>
            <Stack vertical={32}>
              {limitedList.map((item) => (
                <SecurityConstituent
                  key={item.name}
                  logo={item.logo}
                  name={item.name}
                  actual={item.actual}
                  color={item.color}
                  showLogo={id === 'holdings'}
                />
              ))}
              {withSearch && searchValue.length > 0 && limitedList.length < 1 && (
                <Typography size={14} lineHeight="medium" color="additional" data-test-id="noSearchResults">
                  <Typo>
                    No holdings found.
                    <br />
                    Please try a different search.
                  </Typo>
                </Typography>
              )}
              {analytics[id].length > 5 && (
                <Button
                  mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
                  onClick={() => {
                    goToSecurityAnalytics(id)
                  }}
                  data-test-id="showAllButton"
                >
                  Show all {analytics[id].length}
                </Button>
              )}
            </Stack>
          </Paper>
        </Fragment>
      )
    }

    return null
  }, [searchValue, goToSecurityAnalytics, activeTabObject, analytics])

  const analyticsNode = useMemo(() => {
    if (!isAnalyticsVisible) return null

    return (
      <Paper top={48}>
        <Fragment>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Typo>ETF Breakdown</Typo>
          </Typography>
          <Paper top={24}>
            <GreyTabs
              tabs={tabs}
              onChange={(tab) => {
                setActiveTab(tab.id)
              }}
            />
          </Paper>
          {activeTabObject?.withSearch && (
            <Paper top={24}>
              <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by holding" />
            </Paper>
          )}
          {analyticsListNode}
        </Fragment>
      </Paper>
    )
  }, [searchValue, handleSearch, tabs, activeTabObject, analyticsListNode, isAnalyticsVisible])

  const labelsDescription = useMemo(
    () =>
      securityLabels.length > 0 ? (
        <Paper top={48} bottom={16}>
          <LabelsDescription labels={securityLabels} />
        </Paper>
      ) : null,
    [securityLabels],
  )

  const documents = useMemo(
    () =>
      security &&
      security?.documents?.length > 0 && (
        <Paper top={48}>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Typo>Key information</Typo>
          </Typography>
          <Paper top={24}>
            <SecurityDocuments documents={security?.documents} />
          </Paper>
        </Paper>
      ),
    [security],
  )

  const content = useMemo(
    () => (
      <Paper bottom={80}>
        <Paper>
          <SecurityTitle
            logo={security?.logo_uri}
            ticker={security?.ticker}
            title={security?.title}
            lineHeight="small"
            size={24}
          />
        </Paper>
        {chart}
        {labelsDescription}
        {details}
        {analyticsNode}
        {documents}
      </Paper>
    ),
    [
      security?.logo_uri,
      security?.ticker,
      security?.title,
      chart,
      labelsDescription,
      details,
      analyticsNode,
      documents,
    ],
  )

  return <MobileLayout header={headerNode} content={content} />
}

export { Mobile }
