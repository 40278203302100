import React, { Fragment } from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { CardAccent } from 'components/atoms/CardAccent'
import { MaxLines } from 'components/atoms/MaxLines'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { manageTypes, portfolioColors } from 'constants/portfolio'

import './PresetCard.css'

type PresetCardProps = {
  preset: {
    title: string
    short_description: string
    logo: string
    risk: number
    preset_stocks: Array<{ amount: number }>
  }
}

const PresetCard = ({ preset }: PresetCardProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const riskNode = (
    <Paper inline className="PresetCard_Risk" top={4} bottom={4} left={8} right={8}>
      <Typography size={14} lineHeight="small">
        <Typo>
          <Typography tag="span" size={14} lineHeight="small" color="additional">
            Risk
          </Typography>{' '}
          {preset.risk}/7
        </Typo>
      </Typography>
    </Paper>
  )

  const content = desktop ? (
    <Fragment>
      <Typography lineHeight="small" weight="semibold">
        <Typo data-test-id="presetCardTitle">{preset.title}</Typo>
        <Paper inline left={8} data-test-id="presetCardRisk">
          {riskNode}
        </Paper>
      </Typography>
      <Paper top={4}>
        <Typography size={14} color="additional" lineHeight="small" data-test-id="presetCardShortDescription">
          <Typo>{preset.short_description}</Typo>
        </Typography>
      </Paper>
    </Fragment>
  ) : (
    <Fragment>
      <Typography weight="semibold">
        <MaxLines lines={1}>
          <Typo>{preset.title}</Typo>
        </MaxLines>
      </Typography>
      <Paper top={4}>
        <Typography size={14} color="additional" lineHeight="small">
          <MaxLines lines={1}>
            <Typo>{preset.short_description}</Typo>
          </MaxLines>
        </Typography>
      </Paper>
      <Paper top={4}>{riskNode}</Paper>
    </Fragment>
  )

  return (
    <Card className="PresetCard" borderRadius={6} data-test-id="presetCard">
      <CardAccent color={portfolioColors[manageTypes.SELF_SELECTED]} />
      <Paper left={desktop ? 24 : 20}>
        <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
          <Column size={1}>{content}</Column>
          <Column size={0}>
            <Paper
              className={classNames('PresetCard_ImageContainer', {
                PresetCard_ImageContainer_mobile: !desktop,
              })}
            >
              <img className="PresetCard_Image" src={preset.logo} />
            </Paper>
          </Column>
        </ColumnarLayout>
      </Paper>
    </Card>
  )
}

export { PresetCard }
