/* eslint-disable react/no-string-refs */

import React from 'react'
import { numeral } from 'helpers/money'

export default class MoneyInput extends React.Component {
  format = (value, withFloat) => {
    // this format function can return any length of fraction like "123.4" or "123.453"
    // such behavior is necessary for formatting during input
    if (value === '') return ''
    let fraction = ''

    if (withFloat) {
      const indexOfDot = value.indexOf('.')

      if (indexOfDot !== -1) {
        fraction = value.substr(indexOfDot)
        if (fraction === '.00') {
          fraction = ''
        }
        value = value.slice(0, indexOfDot)
      }
    }

    let formatted = numeral(withFloat ? value : Math.floor(value)).format('$0,0')
    formatted += fraction
    return formatted
  }

  unformat = (string) => {
    return string.replace(/[^.0-9]+/g, '')
  }

  handleKeyDown = (event) => {
    const { withFloat } = this.props
    const alreadyHaveDecimal = event.target.value.indexOf('.') > -1
    const charCode = event.which ?? event.keyCode
    const isAction = charCode <= 46
    const isNumber = charCode >= 48 && charCode <= 57
    const isNumpadNumber = charCode >= 96 && charCode <= 105
    const isPeriod = withFloat && ['.'].includes(event.key) && !alreadyHaveDecimal
    const isComma = withFloat && [',', '٫'].includes(event.key) && !alreadyHaveDecimal
    const isModifier = event.metaKey || event.shiftKey || event.ctrlKey

    const shouldPreventDefault = !(isAction || isNumber || isNumpadNumber || isPeriod || isComma || isModifier)

    if (shouldPreventDefault) {
      event.preventDefault()
    }
  }

  handleChange = (event) => {
    const { onChange } = this.props
    const nonEnglishDecimalSeparatorRegexp = /[,٫]$/
    const alreadyHaveDecimal = event.target.value.indexOf('.') > -1

    // find current caret position
    const caretPosition = event?.target?.selectionStart
    let enteredSymbol = event?.target?.value?.[caretPosition - 1]
    let enteredSymbolCount = event?.target?.value
      ?.slice(0, caretPosition)
      ?.split('')
      ?.filter((symbol) => symbol === enteredSymbol)?.length

    if (nonEnglishDecimalSeparatorRegexp.test(enteredSymbol)) {
      enteredSymbol = '.'
      enteredSymbolCount = 1
    }

    // replace entered comma with '.'
    if (event?.nativeEvent?.data === ',' || event?.nativeEvent?.data === '٫') {
      if (alreadyHaveDecimal) {
        // on the next tick return caret to it's current place
        return setTimeout(() => {
          event.target.setSelectionRange(caretPosition - 1, caretPosition - 1)
        }, 0)
      }

      if (event?.target?.value?.length > 0) {
        const currentValue = event.target.value
        const enteredPosition = caretPosition - 1

        if (nonEnglishDecimalSeparatorRegexp.test(currentValue[enteredPosition])) {
          event.target.value = currentValue
            .split('')
            .map((symbol, index) => (index === enteredPosition ? '.' : symbol))
            .join('')
        }
      }
    }

    // clean-up decimal part
    if (event?.target?.value?.includes?.('.')) {
      const [intPart, decimalPart] = event.target.value.split('.')
      const normalizedDecimalPart = decimalPart
        .replace(/[.,٫]/g, '')
        .split('')
        .filter((_symbol, index) => index < 2)
        .join('')

      event.target.value = `${intPart}.${normalizedDecimalPart}`
    }

    // filter out unallowed symbols
    if (event?.target?.value?.length > 0) {
      event.target.value = event.target.value
        .split('')
        .filter((symbol) => ['£', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', ','].includes(symbol))
        .join('')

      if (event.target.value === '£') {
        event.target.value = ''
      }
    }

    // handle onChange
    const unformattedValue = this.unformat(event.target.value)
    const isValueLengthValid = unformattedValue.length < 15

    if (isValueLengthValid) {
      if (onChange) {
        onChange(event, unformattedValue)
      }
    }

    // on next tick return caret to it's position
    setTimeout(() => {
      const enteredSymbolTuple = event?.target?.value
        ?.split('')
        ?.map((symbol, index) => [index, symbol])
        ?.filter(([_, symbol]) => symbol === enteredSymbol)?.[enteredSymbolCount - 1]

      if (enteredSymbolTuple) {
        const [enteredSymbolIndex] = enteredSymbolTuple
        const nextCaretPosition = enteredSymbolIndex + 1

        event.target.setSelectionRange(nextCaretPosition, nextCaretPosition)
      }
    }, 0)
  }

  render() {
    const { value = '', onChange, placeholder = '£', onKeyDown, withFloat = false, ...rest } = this.props
    const formattedValue = this.format(`${value}`, withFloat)

    return (
      <input
        ref="input"
        onChange={this.handleChange}
        value={formattedValue}
        placeholder={placeholder}
        {...rest}
        onKeyDown={this.handleKeyDown}
      />
    )
  }
}
