import React from 'react'

import { useMediaQueries } from 'hooks'

import { format as formatMoney } from 'helpers/money'
import { palette } from 'helpers/palette'

import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './HmrcCashLabel.css'

type HmrcCashLabelProps = {
  amount: string
}

const HmrcCashLabel = ({ amount }: HmrcCashLabelProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const tooltipContent = (
    <div>
      <Typography size={desktop ? 16 : 24} lineHeight="small" weight="semibold">
        <Typo>HMRC tax relief</Typo>
      </Typography>
      <Paper top={16}>
        <Typo>
          For 'net' contributions, we will automatically claim back the basic rate tax relief on your behalf. This is
          the 20% income tax you originally paid on the money you used to fund your SIPP. It usually takes 6 to 11 weeks
          to receive this rebate from HMRC, and the funds will be added to the same portfolio (or account cash) as your
          original contribution.
          <br />
          <br />
          Please note that any higher rate relief should be claimed through your own annual tax return.
        </Typo>
      </Paper>
    </div>
  )

  return (
    <TooltipToModal customIcon offset={-150} description={tooltipContent}>
      <Card className="HmrcCashLabel_Card">
        <Paper top={12} bottom={12} right={desktop ? 12 : 16} left={desktop ? 12 : 16}>
          <ItemWithIcon
            space={8}
            icon={<Icon type="information-16" color={palette['content-on-background-additional']} size={16} />}
            iconPosition="right"
            contentVerticalAlign="center"
            content={
              <Nobr>
                <Typography size={14} lineHeight="small" color="additional">
                  <Typo>
                    <Typography size={14} lineHeight="small" color="default" tag="span">
                      {formatMoney(amount, true, true)}
                    </Typography>{' '}
                    HMRC pending
                  </Typo>
                </Typography>
              </Nobr>
            }
          />
        </Paper>
      </Card>
    </TooltipToModal>
  )
}

export { HmrcCashLabel }
