import React, { Fragment } from 'react'

import { useState, useCallback, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'

import { Card } from 'components/atoms/Card'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { TextCollapse } from 'components/atoms/TextCollapse'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Spotlight, SpotlightTrigger } from 'components/molecules/Spotlight'

import { Sticky } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/Sticky'
import { TunnelHeader } from 'app/pages/Securities/components'
import { BonusBadge } from 'app/pages/SharedPortfolio/components/BonusBadge'

import { SharePortfolioSecurities } from '../components/SharePortfolioSecurities'
import { ShareToggler } from '../components/ShareToggler'
import { type SharingPortfolioHookData } from '../types'

import { SharePortfolioAnalytics } from './components/SharePortfolioAnalytics'

const Desktop = ({
  isPromoEnabled,
  securities,
  title,
  description,
  sectors,
  regions,
  equities,
  equity_types, // eslint-disable-line
  assets,
  toggleShareLink,
  closeSharePage,
  openShareModal,
  isLinkEnabled,
  handleSecurityClick,
  openTitleEditModal,
  handleOpenTsCsModal,
  isDescriptionExpanded,
  handleDescriptionExpand,
}: SharingPortfolioHookData): React.ReactElement => {
  const [layoutNode, setLayoutNode] = useState(null)
  const layoutRef = useCallback((ref) => {
    setLayoutNode(ref)
  }, [])

  const analyticsProps = { sectors, regions, equities, equity_types, assets, layoutNode }

  const header = useMemo(() => {
    return (
      <Inner twoColumns>
        <TunnelHeader title="Portfolio outline" onCancel={closeSharePage} cancelTitle="Close" />
      </Inner>
    )
  }, [closeSharePage])

  const portfolioTitle = useMemo(
    () => (
      <Fragment>
        <ColumnarLayout mods={{ padding: 'no' }}>
          <Column size={1}>
            <Typography size={32} weight="semibold" lineHeight="small" data-test-id="shareTitle">
              <Typo allowHtml={false}>{title || 'Portfolio'}</Typo>
            </Typography>
          </Column>
          <Column size={0}>
            <Paper onClick={openTitleEditModal} style={{ cursor: 'pointer' }}>
              <Icon type="pencil-24" size={24} inline color={palette['content-on-background-primary']} />
            </Paper>
          </Column>
        </ColumnarLayout>
        <Paper top={20}>
          {description ? (
            <Typography>
              <TextCollapse
                limit={200}
                multiline
                onExpand={handleDescriptionExpand}
                shouldInitExpanded={isDescriptionExpanded}
                maxLineBreaks={3}
                allowHtml={false}
              >
                {description}
              </TextCollapse>
            </Typography>
          ) : (
            <Link onClick={openTitleEditModal}>Add description</Link>
          )}
        </Paper>
      </Fragment>
    ),
    [title, description, openTitleEditModal, handleDescriptionExpand, isDescriptionExpanded],
  )

  const securitiesNode = useMemo(
    () => <SharePortfolioSecurities securities={securities} onClick={handleSecurityClick} />,
    [securities, handleSecurityClick],
  )

  const stickyButtonNode = useMemo(() => {
    return (
      <Sticky bottom clickThrough>
        <SideSpacesCompensator>
          <Paper
            top={20}
            bottom={64}
            left={16}
            right={16}
            style={{ background: palette['background-default'] }}
            clickThroughPadding
          >
            <AllCenter>
              <SpotlightTrigger name="share">
                <Button
                  type="submit"
                  mods={{ size: 'big block' }}
                  onClick={openShareModal}
                  disabled={!isLinkEnabled}
                  data-test-id="sharePortfolioButton"
                >
                  <div>
                    <ItemWithIcon
                      space={12}
                      inline
                      contentVerticalAlign="center"
                      icon={<Icon size={24} inline type="share" color={palette['content-on-color-default']} />}
                      content={
                        <Typography size={18} lineHeight="small" tag="span" color="on_color_default" inline>
                          Share
                        </Typography>
                      }
                    />
                  </div>
                </Button>
              </SpotlightTrigger>
              {isPromoEnabled && <BonusBadge handleOpenTsCsModal={handleOpenTsCsModal} />}
            </AllCenter>
          </Paper>
        </SideSpacesCompensator>
      </Sticky>
    )
  }, [isPromoEnabled, isLinkEnabled, openShareModal, handleOpenTsCsModal])

  const togglerNode = useMemo(
    () => (
      <Paper left={80} bottom={40}>
        <Card>
          <Spotlight trigger="share" rules={[isLinkEnabled]} hint="Move this to ON to enable portfolio sharing">
            <Paper top={24} bottom={24} left={16} right={16}>
              <ShareToggler isActive={isLinkEnabled} setIsActive={toggleShareLink} />
            </Paper>
          </Spotlight>
        </Card>
      </Paper>
    ),
    [isLinkEnabled, toggleShareLink],
  )

  const contentNode = (
    <Inner twoColumns>
      <Paper bottom={80}>
        <SharePortfolioAnalytics
          {...analyticsProps}
          titleNode={portfolioTitle}
          stickyButton={stickyButtonNode}
          togglerNode={togglerNode}
          securitiesNode={securitiesNode}
          securities={securities}
        />
      </Paper>
    </Inner>
  )

  return <DesktopLayout ref={layoutRef} header={header} content={contentNode} />
}

export { Desktop }
