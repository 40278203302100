import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { TunnelHeader } from 'app/pages/Securities/components'

import { SippDeclarationForm } from './components/SippDeclarationForm'
import { steps, useSippDeclaration } from './hooks/useSippDeclaration'

type SippDeclarationProps = {
  location: {
    query: Record<string, string>
  }
}

const SippDeclaration = ({ location }: SippDeclarationProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    step,
    isGeneralDeclarationChecked,
    isRiskDeclarationChecked,
    handleClose,
    handleGeneralCheckboxChange,
    handleRiskCheckboxChange,
    handleContinue,
    handleBack,
  } = useSippDeclaration({ location })

  const props = {
    step,
    isGeneralDeclarationChecked,
    isRiskDeclarationChecked,
    handleGeneralCheckboxChange,
    handleRiskCheckboxChange,
    handleContinue,
  }

  return (
    <Fragment>
      {desktop ? (
        <DesktopLayout
          header={
            <Width center size={52}>
              <TunnelHeader
                cancelTitle="Close"
                onCancel={handleClose}
                onBack={step === steps.RISK ? handleBack : undefined}
              />
            </Width>
          }
          content={
            <Inner>
              <Width size={52} center>
                <Paper bottom={80}>
                  <SippDeclarationForm {...props} />
                </Paper>
              </Width>
            </Inner>
          }
        />
      ) : (
        <MobileLayout
          header={
            <NavigationBar
              rightPartText="Close"
              onRightPartClick={handleClose}
              leftPartText="Back"
              onLeftPartClick={step === steps.RISK ? handleBack : undefined}
            >
              <Typography align="center" lineHeight="small" weight="semibold">
                <Typo>Self Invested Personal Pension</Typo>
              </Typography>
            </NavigationBar>
          }
          content={
            <Paper bottom={120}>
              <SippDeclarationForm {...props} />
            </Paper>
          }
          contentPaperSizes={{ top: 24, right: 16, bottom: 64, left: 16 }}
        />
      )}

      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { SippDeclaration }
