import React from 'react'

import { AnalyticsDesktopLayout } from '../../Layouts/AnalyticsDesktopLayout.jsx'
import { type IAssetsProps } from '../Assets'

import { AssetsList } from './components/AssetsList'
import { Summary } from './components/Summary'

const Desktop = ({
  isLoading,
  scrollableElementRef,
  fromPortfolio,
  portfoliosOptions,
  selected,
  selectedPortfolio,
  selectedPortfolioTitle,
  showTarget,
  summary,
  handlePortfolioSelect,
  handleBackToPortfolio,
  tabs,
}: IAssetsProps): React.ReactElement => {
  const summaryNode = <Summary summary={summary} selectedPortfolio={selectedPortfolio} />

  const equitiesNode = <AssetsList isLoading={isLoading} summary={summary} showTarget={showTarget} />

  return (
    <AnalyticsDesktopLayout
      isLoading={isLoading}
      scrollableElementRef={scrollableElementRef}
      fromPortfolio={fromPortfolio}
      portfoliosOptions={portfoliosOptions}
      selected={selected}
      selectedPortfolio={selectedPortfolio}
      selectedPortfolioTitle={selectedPortfolioTitle}
      handlePortfolioSelect={handlePortfolioSelect}
      handleBackToPortfolio={handleBackToPortfolio}
      tabs={tabs}
      leftContent={equitiesNode}
      rightContent={summaryNode}
    />
  )
}

export { Desktop }
