import React from 'react'

import BankTransferForm from './BankTransferForm.jsx'

export const routes = {
  module: 'bank-transfer',
  path: 'bank-transfer/',
  component: function Component(props) {
    return <BankTransferForm {...props} />
  },
}
