import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { attach } from 'effector'
import { createSelector } from 'reselect'

import { goTo, urlTo } from 'helpers/router.js'

import { useActions, useEffect, useMemo, useRedirect, useSelector, useCallback, useLoading } from 'hooks'
import { useUnit } from 'effector-react'

import { fetchRecurringPaymentsFx } from 'app/effector/recurringPayments'
import { fetchQuickStartsFx } from 'app/effector/quickStart'
import { fetchStatsFx } from 'app/effector/stats'
import { $owner, $isLoading as $areContactsLoading } from 'app/effector/contacts'
import { $dictsStore } from 'app/effector/dicts'

import { GatewayDest } from 'components/atoms/Gateway'

import { Portfolios } from 'app/pages/Dashboard/Accounts/Portfolios'
import { NoPortfolios } from 'app/pages/Dashboard/Accounts/NoPortfolios'

import { AllowNotificationsModal } from 'app/pages/Dashboard/AllowNotificationsModal/AllowNotificationsModal.jsx'
import { FinishRegistrationModal } from 'app/pages/Dashboard/SetupAccount/FinishRegistration/FinishRegistrationModal.jsx'
import { OpenIsaModal } from './components/OpenIsaModal'
import { OpenSippModal } from './components/OpenSippModal'
import { SippAnnualLimitModal } from './components/SippAnnualLimitModal'
import { SippTransferModal } from '../Goals/components/SippTransferModal'
import { StarlingConnectedModal } from 'app/pages/Dashboard/GrantAccess/StarlingConnectedModal.tsx'
import ChangesToTheAgreement from './components/ChangesToTheAgreement/ChangesToTheAgreement.jsx'

import { isClientOnboarded } from 'app/redux/selectors'
import { navigateToIsaTransferFlowByClient } from 'helpers/navigateToIsaTransferFlowByClient'
import {
  fetchPortfolios as fetchPortfoliosActionCreator,
  fetchPresets as fetchPresetsActionCreator,
} from 'app/redux/actions/portfolios'
import { Contact } from 'app/effector/contacts/models'

import { presetTypes } from 'constants/portfolio'
import { states as clientStates, types as clientTypes } from 'constants/client'

import { features } from 'helpers/features'

import './Accounts.css'

const fetchRecurringPaymentsOnDashboardFx = attach({ effect: fetchRecurringPaymentsFx })
const fetchQuickStartsOnDashboardFx = attach({ effect: fetchQuickStartsFx })

const getVisiblePortfoliosAndSkeletons = createSelector(
  [(state) => state.portfolios.list, (state) => state.client.type, (state, owner) => owner],
  (portfoliosList, clientType, owner) => {
    const visiblePortfolios = portfoliosList.getVisiblePortfolios()
    let type = 'default'

    if (clientType === clientTypes.BUSINESS) {
      type = 'business'
    }

    if (!owner.isUkResidence() && owner.id !== null) {
      type = 'no-isa'
    }

    if (visiblePortfolios.length < 1 || owner.id === null) {
      return [portfoliosList.getSkeletonsList(type), true]
    }

    return [visiblePortfolios, false]
  },
)

const Accounts = ({ routes, location: { query = {} } = {} }) => {
  const { wait: waitForPortfolioData, isLoading: isPortfolioDataLoading } = useLoading(true)
  const isChangesToTheAgreementVisible = routes.some((route) => route.module === 'changes-to-the-agreement')
  const client = useSelector((state) => state.client)
  const owner = useUnit($owner) ?? new Contact()
  const areContactsLoading = useUnit($areContactsLoading)
  const goals = useSelector((state) => state.portfolios)
  const { dicts } = useUnit($dictsStore)
  const { versions } = dicts
  const isFinishingRegistrationModalOpen = query.finishRegistration === 'true'
  const isAllowNotificationsModalOpen = query.allowNotifications === '1'
  const isIsaTransferPanelFeatureEnabled = features?.get('isa-transfer-panel')

  const [visiblePortfolios, isPortfoliosSkeletons] = useSelector((state) =>
    getVisiblePortfoliosAndSkeletons(state, owner),
  )
  const [fetchPortfolios, fetchPresets] = useActions([fetchPortfoliosActionCreator, fetchPresetsActionCreator])
  const createPortfolioUrl = useMemo(() => urlTo('create-new-portfolio'), [])

  useRedirect({
    to: () => {
      goTo(urlTo('dashboard.third-party-auth', {}, query))
    },
    rule: query.third_party_connect === 'starling',
    isLoading: false,
  })

  useRedirect({
    to: () => {
      navigateToIsaTransferFlowByClient(client)
    },
    rule:
      isIsaTransferPanelFeatureEnabled &&
      query.IsaTransfer === 'true' &&
      client.type !== clientTypes.BUSINESS &&
      (!owner.country_of_tax_residence || owner.isUkResidence()),
    isLoading: Boolean(client.didInvalidate || client.error || !client.is_fetched || areContactsLoading),
  })

  useRedirect({
    to: () => {
      const forwardQuery = {}

      if (query.IsaTransfer === 'true') {
        forwardQuery.IsaTransfer = 'true'
      }

      goTo(urlTo('create-account', {}, forwardQuery))
    },
    rule: !isClientOnboarded({ client }),
    isLoading: Boolean(client.didInvalidate || client.error || goals.didInvalidate || !client.is_fetched),
  })

  const fetchPortfoliosData = useCallback(async () => {
    const promises = [
      fetchPortfolios({ setNotValidBefore: false, setValidAfter: false }),
      fetchStatsFx(),
      fetchRecurringPaymentsOnDashboardFx(),
      fetchQuickStartsOnDashboardFx(),
    ]

    Object.values(presetTypes).forEach((presetType) =>
      promises.push(fetchPresets(presetType, { setNotValidBefore: false, setValidAfter: false })),
    )

    await Promise.all(promises)
  }, [fetchPortfolios, fetchPresets])

  useEffect(() => {
    waitForPortfolioData(fetchPortfoliosData())
  }, [])

  useEffect(() => {
    const clientFilledPersonalData = [clientStates.COMPLETED, clientStates.APPROVED, clientStates.CLOSING].includes(
      client?.state,
    )

    if (!clientFilledPersonalData) {
      return
    }

    if (!versions) {
      return
    }

    const clientTermsVersion = client?.agreed_with_terms
    const globalTermsVersion = parseInt(versions?.terms ?? 0, 10)
    const clientRiskDisclosureVersion = client?.agreed_with_risk_disclosure
    const globalRiskDisclosureVersion = parseInt(versions?.risk_disclosure ?? 0, 10)
    const isClientHadToUpdateTerms = clientTermsVersion < globalTermsVersion
    const isClientHadToUpdateRiskDisclosure = clientRiskDisclosureVersion < globalRiskDisclosureVersion

    if (isClientHadToUpdateTerms || isClientHadToUpdateRiskDisclosure) {
      goTo(urlTo('accounts.changes-to-the-agreement'))
    }
  }, [client?.agreed_with_terms, client?.agreed_with_risk_disclosure, client?.state, versions])

  const ViewComponent = useMemo(() => {
    if (isPortfolioDataLoading) {
      return Portfolios
    }

    if (visiblePortfolios.length > 0) {
      return Portfolios
    }

    return NoPortfolios
  }, [isPortfolioDataLoading, visiblePortfolios.length])

  return (
    <Fragment>
      <ViewComponent
        client={client}
        portfolios={visiblePortfolios}
        createPortfolioUrl={createPortfolioUrl}
        isPortfoliosSkeletons={isPortfoliosSkeletons}
        shouldNotShowHints={isFinishingRegistrationModalOpen || isAllowNotificationsModalOpen}
      />
      <FinishRegistrationModal />
      <AllowNotificationsModal />
      <StarlingConnectedModal />
      <OpenSippModal />
      <OpenIsaModal />
      <SippAnnualLimitModal />
      <SippTransferModal />
      <ChangesToTheAgreement visible={isChangesToTheAgreementVisible} versions={versions} />
      <GatewayDest name="changes-to-the-agreement" />
      <GatewayDest name="toasts" />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

Accounts.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.shape({
    query: PropTypes.shape({
      third_party_connect: PropTypes.string,
    }),
  }),
}

export { Accounts }
