import xor from 'lodash/xor'

import { features } from 'helpers/features'
import { format } from 'helpers/money'

import { questionCodes, questionTypes } from 'constants/questionnaire'

const getQuestion = ({ questionnaire }, code) => {
  return questionnaire.questionList.findQuestionByCode(code)
}

const getQuestionAnswers = ({ questionnaire }, code) => {
  const question = questionnaire.questionList.findQuestionByCode(code)

  return question ? question.answers : []
}

const getClientAnswer = ({ questionnaire }, code) => {
  const question = questionnaire.questionList.findQuestionByCode(code)

  if (!question) {
    return null
  }

  return questionnaire.clientAnswerList.findClientAnswerByQuestionId(question.id)
}

const getClientAnswerValue = (state, code) => {
  const question = getQuestion(state, code)
  const clientAnswer = getClientAnswer(state, code)

  if (!question || !clientAnswer) return null

  switch (question.type) {
    case 'TEXT':
      return clientAnswer.value
    case 'SINGLE':
      return clientAnswer.answer_ids.length ? clientAnswer.answer_ids[0] : null
    case 'MULTIPLE':
      return clientAnswer.answer_ids
    default:
      return null
  }
}

const getClientAnswerValues = (state, codes = []) => {
  if (codes.length === 0) {
    const questions = state.questionnaire.questionList.questions

    return questions.reduce((acc, question) => {
      acc[question.code] = getClientAnswerValue(state, question.code)
      return acc
    }, {})
  }
  const values = codes.reduce((acc, code) => {
    acc[code] = getClientAnswerValue(state, code)
    return acc
  }, {})

  return values
}

const getClientAnswersOnQuestions = ({ questionnaire }) => {
  const { questionList, clientAnswerList } = questionnaire

  const getAnswerValue = (question) => {
    const answer = clientAnswerList.findClientAnswerByQuestionId(question.id)

    if (!answer) {
      return []
    }

    if (question.type === questionTypes.TEXT) {
      if (features.get('new-questionnaire')) {
        return [format(answer.value)]
      }

      const value = question.code === questionCodes.ANNUAL_TURNOVER ? format(answer.value) : answer.value

      return [value]
    }

    return question.answers
      .filter((questionAnswer) => answer.answer_ids.includes(questionAnswer.id))
      .map((questionAnswer) => questionAnswer.title)
  }

  return questionList.questions
    .map((question) => ({
      type: question.type,
      question: question.title,
      code: question.code,
      answers: getAnswerValue(question),
    }))
    .filter(({ code }) => ![questionCodes.YEARS_OLD].includes(code))
}

const isAllQuestionsAnswered = ({ questionnaire }) => {
  const { questionList, clientAnswerList } = questionnaire
  const questionsIds = questionList.questions.map((question) => question.id)
  const answerQuestionIds = clientAnswerList.clientAnswers.map((answer) => answer.question_id)
  const difference = xor(questionsIds, answerQuestionIds)

  return difference.length < 1
}

export {
  getQuestion,
  getQuestionAnswers,
  getClientAnswer,
  getClientAnswerValue,
  getClientAnswerValues,
  getClientAnswersOnQuestions,
  isAllQuestionsAnswered,
}
