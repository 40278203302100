import * as rules from 'app/redux/models/common/rules.js'

export const CashMixin = (superclass): any =>
  class extends superclass {
    readonly withdrawable_cash: number | null
    readonly safe_withdrawable_cash: number | null
    readonly securities_value: number | null
    readonly available_securities_value: number | null
    readonly unsettled_cash: number | null
    readonly total_cash: number | null
    readonly pending_orders_cash: number | null
    readonly available_to_invest_cash: number | null
    readonly is_autoinvest_enabled: boolean
    readonly min_autoinvest_amount: number | null

    constructor(...args) {
      super(...args)

      this.manage_type = 'CASH'

      this.withdrawable_cash = null
      this.safe_withdrawable_cash = null
      this.securities_value = null
      this.available_securities_value = null
      this.unsettled_cash = null
      this.total_cash = null
      this.pending_orders_cash = null
      this.available_to_invest_cash = null
      this.is_autoinvest_enabled = false
      this.min_autoinvest_amount = null
    }

    /** @override */
    getRules(): object {
      const parentRules = typeof super.getRules === 'function' ? super.getRules() : {}

      return {
        ...parentRules,
        withdrawable_cash: [[rules.float]],
        safe_withdrawable_cash: [[rules.float]],
        securities_value: [[rules.float]],
        available_securities_value: [[rules.float]],
        unsettled_cash: [[rules.float]],
        pending_orders_cash: [[rules.float]],
        available_to_invest_cash: [[rules.float]],
        is_autoinvest_enabled: [[rules.bool]],
        min_autoinvest_amount: [[rules.float]],
      }
    }

    getTitle(): string {
      const defaultTitle = 'Cash'

      return super.getTitle?.(defaultTitle) ?? defaultTitle
    }
  }
