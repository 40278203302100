import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries, useMemo } from 'hooks'

import { addOrdinalSuffix } from 'helpers/date.js'
import { palette } from 'helpers/palette/'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { CardAccent } from 'components/atoms/CardAccent'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import { BankAccountCard } from 'components/molecules/BankAccountCard'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

import { MandateDetails } from '../components/MandateDetails'
import { frequencies, weekdays } from '../constants'

import { useRecurringPaymentEdit } from './hooks/useRecurringPaymentEdit'

import { portfolioColors, manageTypes, regulatoryTypesCashText } from 'constants/portfolio'

type RecurringPaymentEditProps = {
  portfolio: Portfolio
  location: {
    query?: object
  }
  handleClose: () => void
}

const RecurringPaymentEdit = withRouter(
  ({ portfolio, location, handleClose }: RecurringPaymentEditProps): React.ReactElement => {
    const { desktop } = useMediaQueries()

    const {
      recurringPayment,
      portfolioTitle,
      nominatedBankAccount,
      showMandateDetails,
      openMandateDetails,
      closeMandateDetails,
      handleBack,
      handleStopPayment,
    } = useRecurringPaymentEdit({
      portfolio,
      location,
    })

    const manageTypeText = useMemo(
      () =>
        ({
          [manageTypes.MANAGED]: 'Managed by InvestEngine',
          [manageTypes.SELF_SELECTED]: 'Picked by you, managed by IE',
          [manageTypes.DIY]: 'Self-managed',
          [manageTypes.CASH]: `${regulatoryTypesCashText[portfolio?.regulatory_type]} cash`,
        })[portfolio?.manage_type],
      [portfolio],
    )

    const frequencyText = useMemo(() => {
      if (recurringPayment?.frequency === frequencies.MONTHLY) {
        return `on ${addOrdinalSuffix(recurringPayment?.day_of_month)} of each month`
      }

      return `on ${weekdays[recurringPayment?.start_day_week - 1]} every ${
        recurringPayment?.frequency === frequencies.FORTNIGHT ? 'two weeks' : 'week'
      }`
    }, [recurringPayment])

    const paymentDetailsNode = (
      <Card level={0}>
        <Paper top={24} bottom={24} left={desktop ? 24 : 20} right={desktop ? 24 : 20}>
          <Typography size={16} lineHeight="small" weight="semibold" data-test-id="portfolioCardTitle">
            <Typo>{portfolioTitle}</Typo>
          </Typography>
          <Paper top={4}>
            <Typography size={14} lineHeight="small" color="minor">
              {manageTypeText}
            </Typography>
          </Paper>
          <Paper top={24}>
            <Typography size={14} lineHeight="small" data-test-id="paymentDetailsText">
              <PoundsWithPence amount={recurringPayment?.amount} /> {frequencyText}
            </Typography>
          </Paper>
        </Paper>
        <CardAccent color={portfolioColors[portfolio?.manage_type]} />
      </Card>
    )

    const bankAccountNode = (
      <Paper top={32}>
        <Typography size={14} lineHeignt="small" color="minor">
          <Typo>From</Typo>
        </Typography>
        <Paper top={12}>
          <BankAccountCard bankAccount={nominatedBankAccount} />
        </Paper>
      </Paper>
    )

    const showMandateDetailsButton = (
      <Width size={24} center>
        <SelectableCard onClick={openMandateDetails} data-test-id="showMandateDetailsButton">
          <Paper top={12} bottom={12}>
            <AllCenter>
              <ItemWithIcon
                inline
                space={8}
                icon={<Icon type="new-copy-24" size={20} color={palette['content-on-background-primary']} />}
                content={
                  <Typography color="on_background_primary" lineHeight="small" inline>
                    Show mandate details
                  </Typography>
                }
                iconVerticalAlign="center"
              />
            </AllCenter>
          </Paper>
        </SelectableCard>
      </Width>
    )

    const content = (
      <Fragment>
        <Paper top={desktop ? 32 : 0} bottom={32}>
          {paymentDetailsNode}
          {bankAccountNode}
        </Paper>
        <Paper bottom={56}>{showMandateDetailsButton}</Paper>
        <Button
          mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
          onClick={handleStopPayment}
          data-test-id="stopRecurringPaymentButton"
        >
          Stop Savings Plan
        </Button>
        <Paper top={8}>
          <Typography size={12} align="center">
            <Typo>
              You can view{' '}
              <Link to="https://truelayer.com/legal/enduser_tos/" hard blank>
                End User Terms of Service
              </Link>{' '}
              and{' '}
              <Link to="https://truelayer.com/legal/privacy/" hard blank>
                Privacy Policy
              </Link>
              .
            </Typo>
          </Typography>
        </Paper>
      </Fragment>
    )

    if (showMandateDetails && recurringPayment)
      return <MandateDetails onBack={closeMandateDetails} recurringPayment={recurringPayment} />

    return (
      <OptionsLayout
        header={
          <OptionsNavigationBar
            leftPartText="Back"
            onLeftPartClick={handleBack}
            rightPartText="Close"
            onRightPartClick={handleClose}
          >
            Savings Plan
          </OptionsNavigationBar>
        }
        content={
          desktop ? (
            <Width size={20} center>
              {content}
            </Width>
          ) : (
            content
          )
        }
        data-test-id="recurringPaymentEditPopup"
      />
    )
  },
)

export { RecurringPaymentEdit }
