import { useUnit } from 'effector-react'

import { useCallback, useAsyncEffect, useEffect, useLoading, useState, useRef } from 'hooks'

import { features } from 'helpers/features'
import { combineErrors } from 'helpers/validation.js'

import { $authFactors } from 'app/effector/2fa'

import { showFailToast } from 'app/redux/actions/ui'
import { verifySmsCode } from 'app/redux/api/2fa'
import { ApiError } from 'app/redux/models/errors'

import { useSmsSend } from 'app/pages/Dashboard/UserProfile/hooks'

type UseSmsCodeFormProps = {
  action: 'login' | 'setup_2fa' | 'confirm_phone'
  query?: {
    client_id?: string
    token?: string
  }
  phone: string
  onSuccess: () => void | Promise<void>
  shouldRememberBrowser?: boolean
  onConfirmIdentityError?: () => void
  handleShowRecoveryCodeForm: () => void
}

type UseSmsCodeFormReturnProps = {
  inputRef: object
  validationRef: object
  isLoading: boolean
  isResending: boolean
  isSuccess: boolean
  blockedText: string
  isResendRequired: boolean
  code: string
  validation: object
  countdown: number
  isCodeIssue: boolean
  handleCodeChange: (event: React.ChangeEvent, code: string) => void
  handleResend: () => Promise<void>
  handleShowCodeIssue: () => void
  handleCloseCodeIssue: () => void
}

const smsCodeErrors = ['invalid_key', 'expired']

const useSmsCodeForm = ({
  action,
  query = {},
  phone,
  onSuccess,
  shouldRememberBrowser,
  onConfirmIdentityError,
  handleShowRecoveryCodeForm,
}: UseSmsCodeFormProps): UseSmsCodeFormReturnProps => {
  const countdownTimerId = useRef<NodeJS.Timeout | null>(null)
  const validationRef = useRef()
  const inputRef = useRef()

  const { isLoading, wait } = useLoading(false)
  const [code, setCode] = useState('')
  const [countdown, setCountdown] = useState(30)
  const [isCodeIssue, setIsCodeIssue] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [codeError, setCodeError] = useState('')
  const [isResendRequired, setIsResendRequired] = useState(false)

  const [authFactors] = useUnit([$authFactors])

  const { client_id: clientId, token } = query

  const handleSuccessSmsSend = useCallback(() => {
    setCountdown(30)
    countdownTimerId.current = setInterval(() => {
      setCountdown((counter) => counter - 1)
    }, 1000)

    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const {
    isLoading: isResending,
    blockedText,
    handleSmsSend,
    clearErrors,
  } = useSmsSend({
    action,
    clientId,
    token,
    onSuccessSmsSend: handleSuccessSmsSend,
    onConfirmIdentityError,
  })

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }

    countdownTimerId.current = setInterval(() => {
      setCountdown((counter) => counter - 1)
    }, 1000)

    return () => {
      setCountdown(30)

      if (countdownTimerId.current) {
        clearInterval(countdownTimerId.current)
      }
    }
  }, [])

  useEffect(() => {
    if (countdown === 0 && countdownTimerId.current) {
      clearInterval(countdownTimerId.current)
    }
  }, [countdown])

  useAsyncEffect(async () => {
    if (code.length === 6) {
      if (inputRef.current) {
        inputRef.current.blur()
      }

      try {
        const data = await wait(verifySmsCode({ key: code, shouldRememberBrowser, clientId, token }))

        if (data instanceof ApiError) {
          throw data
        }

        setIsSuccess(true)
        onSuccess()
      } catch (error) {
        if (smsCodeErrors.includes(error?.response?.data?.error)) {
          setCodeError('Incorrect code')
          return
        }

        if (error?.response?.data?.error === 'too_many_invalid_keys') {
          setCodeError('Incorrect code')
          setIsResendRequired(true)
          return
        }

        showFailToast('Something went wrong, please try again or contact us at support@investengine.com')
      }
    }
  }, [code])

  const handleCodeChange = useCallback(
    (_event, value) => {
      value = value.replace(/[^0-9]/g, '').slice(0, 6)
      setCode(value)

      if (codeError) {
        setCodeError('')
      }
      clearErrors()
    },
    [codeError, clearErrors],
  )

  const validation = combineErrors({
    code: {
      rules: [code, !codeError],
      errors: ['Code can’t be empty', codeError],
    },
  })

  const handleResend = useCallback(async () => {
    if (isResendRequired) {
      setIsResendRequired(false)
    }

    if (validationRef.current) {
      validationRef.current.reset()
    }

    setCode('')

    await handleSmsSend({ phone })

    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [phone, isResendRequired, handleSmsSend])

  const handleShowCodeIssue = useCallback(() => {
    if (features?.get('recovery-codes') && action === 'login' && authFactors.includes('RECOVERY_CODE')) {
      handleShowRecoveryCodeForm()
      return
    }

    setIsCodeIssue(true)
  }, [action, authFactors, handleShowRecoveryCodeForm])

  const handleCloseCodeIssue = useCallback(() => {
    setIsCodeIssue(false)
  }, [])

  return {
    inputRef,
    validationRef,
    isLoading,
    isResending,
    isSuccess,
    blockedText,
    isResendRequired,
    code,
    validation,
    countdown,
    isCodeIssue,
    handleCodeChange,
    handleResend,
    handleShowCodeIssue,
    handleCloseCodeIssue,
  }
}

export { useSmsCodeForm }
