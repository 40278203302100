import React, { Fragment } from 'react'

import globalXBackground from 'images/globalx-background.jpg'
import hanetfBackground from 'images/hanetf-background.jpg'
import wisdomTreeBackground from 'images/wisdomtree-background-with-logo.jpg'

import { useMediaQueries } from 'hooks'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { BannerLogo } from 'components/organisms/BannerLogo'

const SecondLevelFeaturedProviders = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const thematicsFeaturedProviders = [
    {
      title: 'GLOBAL X',
      backgroundImage: globalXBackground,
      url: '/dashboard/etfs/?provider=Global+X',
    },
    {
      title: 'WisdomTree',
      backgroundImage: wisdomTreeBackground,
      url: `/dashboard/etfs/collections/wisdomtree/?back=${window.location.pathname}`,
    },
    {
      title: 'HANetf',
      backgroundImage: hanetfBackground,
      url: '/dashboard/etfs/?provider=HANetf',
    },
  ]

  return (
    <Fragment>
      <Paper>
        <Typography
          size={desktop ? 24 : 20}
          weight="semibold"
          lineHeight="small"
          data-test-id="discoverySectionTitle"
          inline
        >
          <Typo>Featured partners</Typo>
        </Typography>
        <Paper left={8} inline>
          <Link
            to="https://blog.investengine.com/featured-partners/"
            mods={{ color: 'black' }}
            blank
            style={{ top: '5px', position: 'relative' }}
            data-test-id="featuredPartnersTooltip"
          >
            <Icon type="information-24" inline />
          </Link>
        </Paper>
      </Paper>
      <Paper top={32}>
        <ColumnarLayout mods={{ padding: 'small' }}>
          {thematicsFeaturedProviders.map((item, index) => (
            <Link key={index} to={item.url}>
              <BannerLogo title={item.title} backgroundImage={item.backgroundImage} />
            </Link>
          ))}
        </ColumnarLayout>
      </Paper>
    </Fragment>
  )
}

export { SecondLevelFeaturedProviders }
