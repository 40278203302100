import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import { UserAvatar } from 'components/molecules/UserAvatar/UserAvatar'

import { CropAvatarModal } from './components/CropAvatarModal'
import { useEditAvatar } from './useEditAvatar'

import './EditAvatar.css'

type EditAvatarProps = {
  isVisible: boolean
  onClose: () => void
}

const EditAvatar = ({ isVisible, onClose }: EditAvatarProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    client,
    isLoading,
    owner,
    inputFileRef,
    isCropAvatarModalVisible,
    preloadedImage,
    setIsCropAvatarModalVisible,
    handleClose,
    updateClientAvatar,
    getRootProps,
    getInputProps,
  } = useEditAvatar({ onClose })

  const headingNode = (
    <Fragment>
      <Paper bottom={32}>
        <Typography size={desktop ? 32 : 24} weight="semibold" align="center" lineHeight="small">
          <Typo>Change your profile picture</Typo>
        </Typography>
      </Paper>
      <Typography size={desktop ? 16 : 14} align="center">
        <Typo>Upload an image to represent your identity</Typo>
      </Typography>
    </Fragment>
  )

  const uploadImageNode = (
    <Paper top={desktop ? 48 : 32} bottom={desktop ? 48 : 32}>
      <Paper
        className={desktop ? 'EditAvatarModal_UploadBox' : ''}
        top={desktop ? 24 : 0}
        bottom={desktop ? 24 : 0}
        right={desktop ? 24 : 0}
        left={desktop ? 24 : 0}
      >
        <div {...getRootProps()}>
          <AllCenter>
            <UserAvatar size="100" image={client.avatar} firstName={owner?.first_name} lastName={owner?.last_name} />

            <Paper top={16} bottom={16}>
              {desktop && (
                <Typography size={14} align="center" lineHeight="small">
                  <Typo>Drag and drop file here or press ‘Upload’ below</Typo>
                </Typography>
              )}
            </Paper>

            <Button
              mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }}
              onClick={() => inputFileRef.current?.click()}
            >
              Upload
            </Button>
            <input ref={inputFileRef} {...getInputProps()} />
          </AllCenter>
        </div>
      </Paper>
    </Paper>
  )

  const buttonNode = (
    <Button mods={{ size: 'big block' }} onClick={onClose}>
      Continue
    </Button>
  )

  if (isCropAvatarModalVisible)
    return (
      <CropAvatarModal
        image={preloadedImage}
        onSave={updateClientAvatar}
        onClose={handleClose}
        onBack={() => {
          setIsCropAvatarModalVisible(false)
        }}
        isLoading={isLoading}
      />
    )

  if (desktop) {
    return (
      <Modal open={isVisible} close={<Typography color="inherit">Close</Typography>} onClose={onClose}>
        <Paper top={80} bottom={80}>
          <Width size={36}>
            <Width size={27} center>
              {headingNode}
              {uploadImageNode}
            </Width>
            <Width size={24} center>
              {buttonNode}
            </Width>
          </Width>
        </Paper>
      </Modal>
    )
  }

  return (
    <Modal open={isVisible} close={null} onClose={onClose}>
      <MobileLayout
        header={<NavigationBar rightPartText="Close" onRightPartClick={onClose} />}
        content={
          <Inner flex>
            <AllCenter>
              {headingNode}
              {uploadImageNode}
            </AllCenter>
          </Inner>
        }
        footer={<MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
}

export { EditAvatar }
