import React from 'react'

import { ReportFormat } from 'app/effector/reports/models'

import Button from 'components/_old/Button/Button.jsx'

import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

import { FilterElement } from 'components/organisms/Filter/FilterElement'

import { type CreateReportFormPartProps } from '../CreateReportForm'

function SelectFileFormat({
  className,
  contentClassName,
  buttonClassName,
  visible,
  buttonTitle,
  desktop,
  selectedFormat,
  setSelectedFormat,
  handleSelectFormat,
}: CreateReportFormPartProps): React.ReactElement {
  return (
    <div className={className} hidden={!visible}>
      <SideSpacesCompensator className={contentClassName}>
        {Object.values(ReportFormat).map((format) => (
          <FilterElement
            key={format}
            name={format}
            value={format}
            type="radio"
            paperSizes={{
              desktop: {
                left: 16,
                right: 16,
              },
            }}
            checked={selectedFormat === format}
            onChange={() => {
              setSelectedFormat(format)
            }}
            withLine
          />
        ))}
      </SideSpacesCompensator>
      <Paper className={buttonClassName} top={desktop ? 24 : 16} bottom={desktop ? 56 : null}>
        <Button
          onClick={handleSelectFormat}
          mods={{ size: desktop ? 'block' : 'big block' }}
          disabled={!selectedFormat}
        >
          {buttonTitle}
        </Button>
      </Paper>
    </div>
  )
}

export { SelectFileFormat }
