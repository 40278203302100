import { hardCodedCountries as countries } from 'helpers/hardCodedCounties'

import { type ContactList } from 'app/effector/contacts/models'
import { type SignificantControlList } from 'app/effector/significant-controls/models'

import { roles } from 'constants/contacts.js'

type CombineContactsAndControlsInterface = {
  id: number | null
  isDirector: boolean
  hasSignificantControl: boolean
  isMainApplicant: boolean
  state: string | null
  name: string | null
  currentAddress?: string
  birthday?: string
  phone?: string
  isUploadDocumentsVisible?: boolean
}

const combineContactsAndControls = (
  contacts: ContactList,
  significantControls: SignificantControlList,
): CombineContactsAndControlsInterface[] => {
  const contactsData = contacts.contacts.map((contact) => {
    const [currentAddress] = contact.addresses.getCurrentAddress()

    return {
      id: contact.id,
      isDirector: true,
      hasSignificantControl: contact.has_significant_control,
      isMainApplicant: contact.role === roles.OWNER,
      state: contact.state,
      name: `${contact.first_name} ${contact.last_name}`,
      currentAddress: currentAddress?.getAddressString(countries),
      birthday: contact.birthday,
      phone: contact.phone,
      isUploadDocumentsVisible:
        contact.role !== roles.OWNER &&
        !contact.isApproved() &&
        !(
          (contact.document_driving_licence_filled || contact.document_passport_filled) &&
          contact.document_selfie_filled
        ),
    }
  })

  const significantControlsData = significantControls.controls.map((significantControl) => {
    const [currentAddress] = significantControl.addresses.getCurrentAddress()

    return {
      id: significantControl.id,
      isDirector: false,
      hasSignificantControl: true,
      isMainApplicant: false,
      state: significantControl.state,
      name: significantControl.name,
      currentAddress: currentAddress?.getAddressString(countries),
    }
  })

  return [...contactsData, ...significantControlsData]
}

export { combineContactsAndControls }
