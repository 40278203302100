import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DonutChart } from 'components/atoms/DonutChart'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import { type ISummary } from '../../Assets'

type SummaryProps = {
  className?: string
  summary: ISummary
  selectedPortfolio: Record<string, any>
  hideBalance: boolean
}

const Summary = ({ className, summary, selectedPortfolio, hideBalance = false }: SummaryProps): React.ReactElement => {
  const { tinyPhone } = useMediaQueries()

  return (
    <div className={className}>
      <AllCenter>
        <Width size={8.5}>
          <DonutChart
            lineWidth={8}
            values={[
              [summary.stocks.actual, palette.stocks],
              [summary.bonds.actual, palette.bonds],
              [summary.alternatives.actual, palette.alternatives],
              [summary.cash.actual, palette.cash],
            ]}
            minimalSectorValue={1}
          >
            {!hideBalance && (
              <Fragment>
                <Typography size={18} weight="semibold" lineHeight="small" align="center">
                  <PoundsWithPence amount={summary.total} />
                </Typography>
                <Paper top={4}>
                  <Typography size={14} lineHeight="small" align="center">
                    {selectedPortfolio ? (
                      <Typo>
                        <Fragment>
                          Portfolio
                          <br />
                          balance
                        </Fragment>
                      </Typo>
                    ) : (
                      <Typo>
                        {tinyPhone ? (
                          <Fragment>
                            Total
                            <br />
                            balance
                          </Fragment>
                        ) : (
                          <Fragment>Total balance</Fragment>
                        )}
                      </Typo>
                    )}
                  </Typography>
                </Paper>
              </Fragment>
            )}
          </DonutChart>
        </Width>
      </AllCenter>
    </div>
  )
}

export { Summary }
