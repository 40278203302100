import { useUnit } from 'effector-react'

import { useState, useMemo, useActions, useLoading, useCallback, useSelector } from 'hooks'

import { format, unformat } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router'

import { $allowancesStore } from 'app/effector/allowances'
import { $dictsStore } from 'app/effector/dicts'
import { fetchNotificationsFx } from 'app/effector/notifications'
import { moveCashBetweenPortfoliosFx } from 'app/effector/transactions'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios/'
import { showSuccessToast, showFailToast } from 'app/redux/actions/ui/'
import type { Portfolio } from 'app/redux/models/portfolio/types'

import { useForm, useFormWatch } from 'components/atoms/Forms/hooks'

import { types as clientTypes } from 'constants/client'
import { regulatoryTypes, manageTypes } from 'constants/portfolio'

type UseMoveCashAmountInterface = {
  isBusiness: boolean
  portfolioTo: Portfolio
  portfolioFrom: Portfolio
  portfoliosCount: number
  isTransferFromGiaToIsa: boolean
  isTransferFromGiaToSipp: boolean
  isTransferFromIsaToGia: boolean
  isTransferFromManaged: boolean
  transferableCash: number
  initialDepositMin: number
  hasToMoveWholeAvailableBalance: boolean
  isLoading: boolean
  isValid: boolean
  validation: any
  control: any
  errors: any
  showSippTransferConfirmation: boolean
  isSippTransferConfirmed: boolean
  handleConfirmSippTransfer: () => void
  handleContinueSippTransfer: () => Promise<void>
  handleSave: () => Promise<void>
  handleBack: () => void
}

const useMoveCashAmount = (
  portfolioIdFrom: number,
  portfolioIdTo: number,
  backLink?: string,
  action?: string,
): UseMoveCashAmountInterface => {
  const [isMoveCashRequestInProgress, setIsMoveCashRequestInProgress] = useState(false)
  const [showSippTransferConfirmation, setShowSippTransferConfirmation] = useState(false)
  const [isSippTransferConfirmed, setIsSippTransferConfirmed] = useState(false)
  const [savedAmount, setSavedAmount] = useState('')

  const portfolioFrom: Portfolio = useSelector((state: any) => state.portfolios.list.get(Number(portfolioIdFrom)))
  const portfolioTo: Portfolio = useSelector((state: any) => state.portfolios.list.get(Number(portfolioIdTo)))
  const portfolios: Portfolio[] = useSelector((state: any) => state.portfolios.list.getVisiblePortfolios())
  const clientType = useSelector((state) => state.client.type)
  const { allowances } = useUnit($allowancesStore)

  const availableForMovePortfolios = useMemo(() => {
    if (portfolioFrom?.regulatory_type === regulatoryTypes.ISA && action !== 'in') {
      return portfolios.filter(({ regulatory_type: regulatoryType }) => regulatoryType === regulatoryTypes.ISA)
    }

    if (portfolioTo?.regulatory_type === regulatoryTypes.GIA && action === 'in') {
      return portfolios.filter(({ regulatory_type: regulatoryType }) => regulatoryType === regulatoryTypes.GIA)
    }

    if (portfolioTo?.regulatory_type === regulatoryTypes.SIPP) {
      return portfolios.filter(({ regulatory_type: regulatoryType }) =>
        [regulatoryTypes.SIPP, regulatoryTypes.GIA].includes(regulatoryType),
      )
    }

    return portfolios.filter(({ regulatory_type: regulatoryType }) => regulatoryType !== regulatoryTypes.SIPP)
  }, [portfolioFrom, portfolioTo, portfolios, action])

  const portfoliosCount = availableForMovePortfolios.length
  const isBusiness = clientType === clientTypes.BUSINESS
  const isTransferFromGia = portfolioFrom?.regulatory_type === regulatoryTypes.GIA
  const isTransferFromIsa = portfolioFrom?.regulatory_type === regulatoryTypes.ISA
  const isTransferToGia = portfolioTo?.regulatory_type === regulatoryTypes.GIA
  const isTransferToIsa = portfolioTo?.regulatory_type === regulatoryTypes.ISA
  const isTransferToSipp = portfolioTo?.regulatory_type === regulatoryTypes.SIPP
  const isTransferFromGiaToIsa = isTransferFromGia && isTransferToIsa
  const isTransferFromGiaToSipp = isTransferFromGia && isTransferToSipp
  const isTransferFromIsaToGia = isTransferFromIsa && isTransferToGia
  const isTransferFromManaged = [manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(portfolioFrom?.manage_type)
  const isTransferToManaged = [manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(portfolioTo?.manage_type)
  const isTransferToPortfolioWithFirstTopup = portfolioTo?.first_topup
  const remainingAllowance: number = parseFloat(allowances?.isa?.remaining_allowance ?? 'Infinity')
  const transferableCash: number = useMemo(() => portfolioFrom?.available_to_move_cash, [portfolioFrom])
  const { initialDepositMin } = useUnit($dictsStore)

  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])
  const { isLoading, wait } = useLoading()

  const { control, errors, isValid, handleSubmit } = useForm({
    amount: 0,
  })

  const amount: number | undefined = useFormWatch({ control, name: 'amount' })

  const hasToMoveWholeAvailableBalance = useMemo(
    () => isTransferFromManaged && (amount ?? 0) > transferableCash - initialDepositMin,
    [isTransferFromManaged, amount, transferableCash, initialDepositMin],
  )

  const validation = useMemo(
    () => ({
      setValueAs: (value) => unformat(value, true),
      required: {
        value: true,
        message: 'Amount cannot be empty',
      },
      validate: (value: number) => {
        if (isTransferToManaged && !isTransferToPortfolioWithFirstTopup && value < 100) {
          return `Amount must be at least ${format(initialDepositMin)}`
        }
        if (value > transferableCash) {
          return 'Amount cannot be greater than available'
        }
        if (isTransferFromGiaToIsa && value > remainingAllowance) {
          return `Amount cannot exceed remaining ISA allowance: ${format(remainingAllowance)}`
        }
        if (value < 0.01) {
          return 'Amount cannot be empty'
        }

        if (isMoveCashRequestInProgress) return false

        return true
      },
    }),
    [
      transferableCash,
      isTransferFromGiaToIsa,
      remainingAllowance,
      isMoveCashRequestInProgress,
      initialDepositMin,
      isTransferToManaged,
      isTransferToPortfolioWithFirstTopup,
    ],
  )

  const handleMove = useCallback(
    async (amountString: string) => {
      if (isTransferFromGiaToSipp && !isSippTransferConfirmed) {
        setShowSippTransferConfirmation(true)
        setSavedAmount(amountString)
        return
      }

      setIsMoveCashRequestInProgress(true)
      try {
        const amount: number = hasToMoveWholeAvailableBalance ? transferableCash : unformat(amountString, true)

        await moveCashBetweenPortfoliosFx(
          { transfer_from: portfolioIdFrom, transfer_to: portfolioIdTo, amount },
          { setNotValidBefore: false, setValidAfter: false },
        )

        const stateAfterFetchPortfolios = await fetchPortfolios({
          setValidAfter: false,
          setNotValidBefore: false,
        })

        if (stateAfterFetchPortfolios.portfolios.error) {
          throw Error(stateAfterFetchPortfolios.portfolios.error?.[0])
        }

        if (isTransferFromManaged) {
          await fetchNotificationsFx()
        }

        showSuccessToast('Cash has been moved')
        goTo(urlTo(`dashboard.portfolio`, { id: action === 'in' ? portfolioIdTo : portfolioIdFrom }))
      } catch (error) {
        showFailToast()
      }
      setIsMoveCashRequestInProgress(false)
    },
    [
      portfolioIdFrom,
      portfolioIdTo,
      isTransferFromManaged,
      transferableCash,
      hasToMoveWholeAvailableBalance,
      action,
      fetchPortfolios,
      setIsMoveCashRequestInProgress,
      isTransferFromGiaToSipp,
      isSippTransferConfirmed,
    ],
  )

  const handleSave = useMemo(
    () =>
      handleSubmit(async ({ amount }) => {
        await wait(handleMove(amount))
      }),
    [handleMove, handleSubmit, wait],
  )

  const handleBack = useCallback(() => {
    if (showSippTransferConfirmation) {
      setShowSippTransferConfirmation(false)
      return
    }

    const currentPortfolio = action === 'in' ? portfolioTo : portfolioFrom
    goTo(
      backLink ??
        urlTo(
          currentPortfolio?.manage_type === manageTypes.CASH
            ? `dashboard.portfolio${portfoliosCount > 2 ? 'options.move-cash' : ''}`
            : `dashboard.portfolio.options${portfoliosCount > 2 ? '.move-cash' : ''}`,
          {
            id: currentPortfolio?.id,
          },
        ),
    )
  }, [action, backLink, portfoliosCount, portfolioTo, portfolioFrom, showSippTransferConfirmation])

  const handleConfirmSippTransfer = useCallback(() => {
    setIsSippTransferConfirmed(!isSippTransferConfirmed)
  }, [isSippTransferConfirmed])

  const handleContinueSippTransfer = useMemo(
    () =>
      handleSubmit(async () => {
        await wait(handleMove(savedAmount))
      }),
    [handleMove, handleSubmit, wait, savedAmount],
  )

  return {
    isBusiness,
    portfolioTo,
    portfolioFrom,
    portfoliosCount,
    isTransferFromGiaToIsa,
    isTransferFromGiaToSipp,
    isTransferFromIsaToGia,
    isTransferFromManaged,
    transferableCash,
    initialDepositMin,
    hasToMoveWholeAvailableBalance,
    isLoading,
    isValid,
    validation,
    control,
    errors,
    handleSave,
    handleBack,
    showSippTransferConfirmation,
    isSippTransferConfirmed,
    handleConfirmSippTransfer,
    handleContinueSippTransfer,
  }
}

export { useMoveCashAmount }
