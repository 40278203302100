import { nullable, type Nullable, date, enumValue, float } from 'app/effector/model'

import { type PortfolioSecurityPointOfInterestDTO } from '../../api'

enum PortfolioSecurityPointOfInterestType {
  BUY = 'BUY',
  SELL = 'SELL',
}

class PortfolioSecurityPointOfInterest {
  readonly type: Nullable<PortfolioSecurityPointOfInterestType>
  readonly date: Nullable<Date>
  readonly data: {
    price: Nullable<number>
    quantity: Nullable<number>
    value: Nullable<number>
  }

  constructor(dto: Partial<PortfolioSecurityPointOfInterestDTO> | Partial<PortfolioSecurityPointOfInterest>) {
    this.type = nullable(enumValue)(dto.type, PortfolioSecurityPointOfInterestType)
    this.date = nullable(date)(dto.date, true)
    this.data = {
      price: nullable(float)(dto.data?.price),
      quantity: nullable(float)(dto.data?.quantity),
      value: nullable(float)(dto.data?.value),
    }
  }
}

export { PortfolioSecurityPointOfInterest, PortfolioSecurityPointOfInterestType }
