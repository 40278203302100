import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import { NavigationBar } from 'components/atoms/NavigationBar'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Width from 'components/_old/Width/Width'
import Inner from 'components/_old/Inner/Inner.jsx'
import Segment from 'components/atoms/Segment/Segment.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import { BankAccountStateContent } from 'app/pages/Dashboard/Goals/components/BankAccount/BankAccountStateContent.jsx'

const Error = ({ onContinue: handleContinue }) => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      {!desktop && <NavigationBar>Something went wrong</NavigationBar>}
      <AllCenter>
        <Width size={65}>
          <Width size={40} center>
            <Inner>
              <BankAccountStateContent
                title="Something went wrong"
                description={`
                  Open Banking has not been set up.
                  If you did not cancel the process yourself please check your email for further instructions.
                  Alternatively you can make a manual bank transfer to fund your portfolio.
                `}
                icon={{
                  type: 'exclamation-triangle',
                  color: palette['status-warning'],
                  size: 48,
                }}
                data-test-id="addNewBankError"
              >
                <Segment mods={{ margin: 'hefty', noMargin: 'top' }}>
                  <ModalContentButtons width={desktop ? 16 : 24} isNewModal>
                    <Button mods={{ size: 'big block' }} onClick={handleContinue} data-test-id="addNewBankErrorButton">
                      Continue
                    </Button>
                  </ModalContentButtons>
                </Segment>
              </BankAccountStateContent>
            </Inner>
          </Width>
        </Width>
      </AllCenter>
    </Fragment>
  )
}

Error.propTypes = {
  onContinue: PropTypes.func.isRequired,
}

export { Error }
