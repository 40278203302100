import React from 'react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Typography } from 'components/atoms/Typography'

const NoYieldPlaceholder = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <AllCenter className="DividendsChart-NoDividendsPlaceholder">
      <Typography size={desktop ? 16 : 14} color="minor" lineHeight="large" align="center">
        <Typo>
          This portfolio does not hold any
          <br />
          dividend paying securities
        </Typo>
      </Typography>
    </AllCenter>
  )
}

export { NoYieldPlaceholder }
