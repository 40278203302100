import moment from 'helpers/date'
import { sendError } from 'helpers/errorLogging'

function date(unknown: unknown, setToMidnight: boolean = false): Date {
  let _unknown = unknown

  if (typeof _unknown === 'number' || typeof _unknown === 'string') {
    _unknown = new Date(_unknown)
  }

  if (_unknown instanceof Date) {
    if (setToMidnight) {
      return moment(_unknown).startOf('day').toDate()
    }

    return _unknown
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  sendError(new Error(`Cannot convert ${unknown} to date`))

  return new Date()
}

export { date }
