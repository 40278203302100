import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useActions, useCallback, useLoading, useEffect } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { sendError } from 'helpers/errorLogging.js'

import { $pendingOrders, submitPendingOrderFx, $isLoading } from 'app/effector/pending-orders'

import { fetchPortfolio as fetchPortfolioActionCreator } from 'app/redux/actions/portfolios'
import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'

import { useNavbar } from 'app/pages/Dashboard/Goals/DIY/Review/hooks/useNavbar.js'
import { useSecurities } from 'app/pages/Securities/hooks'

const submitRebalancePendingOrderFx = attach({
  effect: submitPendingOrderFx,
})

submitRebalancePendingOrderFx.fail.watch(() => {
  showFailToast()
})

const useRebalance = (portfolioId) => {
  const { isLoading: isPortfolioLoading, wait } = useLoading()
  const { redirectToPortfolio } = useNavbar({ portfolioId })

  const { isLoading: isSecuritiesLoading, securities } = useSecurities({ portfolioId })

  const [fetchPortfolio] = useActions([fetchPortfolioActionCreator])

  const { pendingOrders, isSubmitting, submitOrder } = useUnit({
    pendingOrders: $pendingOrders,
    isSubmitting: $isLoading,
    submitOrder: submitRebalancePendingOrderFx,
  })

  const isLoading = isPortfolioLoading || isSecuritiesLoading || isSubmitting

  // Get rebalance order from Effector store
  const rebalanceOrder = (() => {
    if (pendingOrders.length > 0) {
      const rebalanceOrders = pendingOrders.getRebalanceOrdersByPortfolioId(portfolioId)

      return rebalanceOrders.length > 0 ? rebalanceOrders[0] : null
    }
    return null
  })()

  useEffect(() => {
    const doneSubscription = submitRebalancePendingOrderFx.done.watch(() => {
      trackEvent({ action: 'pending_orders_submitted' })

      wait(fetchPortfolio(portfolioId)).then((stateAfterFetchPortfolio) => {
        if (stateAfterFetchPortfolio.portfolios.error) {
          showFailToast()
          sendError(stateAfterFetchPortfolio.portfolios.error)
          return
        }

        showSuccessToast('Order submitted successfully')
        redirectToPortfolio()
      })
    })

    return () => {
      doneSubscription.unsubscribe()
    }
  }, [portfolioId, fetchPortfolio, wait, redirectToPortfolio])

  const handleConfirm = useCallback(() => {
    if (rebalanceOrder?.id) {
      submitOrder({ orderId: rebalanceOrder.id })
    }
  }, [rebalanceOrder, submitOrder])

  return {
    isLoading,
    securities,
    rebalanceOrder,
    redirectToPortfolio,
    handleConfirm,
  }
}

export { useRebalance }
