import { createStore, createEvent, sample } from 'effector'

import { bulkValidate } from 'helpers/validation.js'

import { $isaTransferProviderList } from 'app/effector/isa-transfer'

import {
  type ClientIsaTransferPrefillFormDataFields,
  type IsaTransferPrefillFormDataFields,
} from 'app/pages/CreateAccount/components/IsaTransferPrefillForm/IsaTransferPrefillFormData'

import { IsaTransferFormData, type FormField, type FormValue } from './IsaTransferFormData'

import { TransferCurrentYear, TransferPreviousYear, TransferType } from 'constants/transferIsa'

// Events
export const setField = createEvent<{ field: FormField; value: FormValue }>()
export const enableForceValidation = createEvent()
export const setFormFromPrefilledData = createEvent<ClientIsaTransferPrefillFormDataFields>()
export const setPortfolioId = createEvent<number>()
export const resetForm = createEvent()

// Stores
export const $form = createStore<IsaTransferFormData>(new IsaTransferFormData({}))
export const $forceValidationEnabled = createStore<boolean>(false)

export const $isaProviderOptions = $isaTransferProviderList.map((providerList) =>
  providerList.map((provider) => ({
    value: provider.id ?? 'other',
    name: provider.name,
  })),
)

// Store updates
$form.on(setField, (state, { field, value }) => state.set(field, value)).reset(resetForm)
$forceValidationEnabled.on(enableForceValidation, () => true).reset(resetForm)

// Set from prefilled data
sample({
  clock: setFormFromPrefilledData,
  source: $form,
  fn: (formData, prefilledData) => {
    // eslint-disable-next-line
    const { current_year_amount, ...clientPrefilledData } = prefilledData
    const newFormData: IsaTransferPrefillFormDataFields = { ...formData, ...clientPrefilledData }

    if (newFormData.isa_provider_id === null) {
      newFormData.isa_provider_id = 'other'
    }

    const isFullTransfer =
      newFormData.transfer_current_year === TransferCurrentYear.YES &&
      newFormData.transfer_previous_years === TransferPreviousYear.ALL

    newFormData.transfer_type = isFullTransfer ? TransferType.FULL : TransferType.PARTIAL

    return new IsaTransferFormData(newFormData)
  },
  target: $form,
})

// set portfolio_id
sample({
  clock: setPortfolioId,
  fn: (portfolioId) => ({ field: 'portfolio_id' as FormField, value: portfolioId }),
  target: setField,
})

export const $validation = $form.map((form) => {
  const isTransferForCurrentYearSelected = form.transfer_current_year === TransferCurrentYear.YES
  const isTransferForPreviousYearsSelected = form.transfer_previous_years !== TransferPreviousYear.NO
  const isTransferForPreviousYearsPartSelected = form.transfer_previous_years === TransferPreviousYear.PART
  const previousYearsAmount = parseFloat(form.previous_years_amount ?? '0')
  const isFullTransfer = form.transfer_type === TransferType.FULL
  const isPartialTransfer = form.transfer_type === TransferType.PARTIAL

  return {
    portfolio_id: {
      rules: [form.portfolio_id !== null],
      errors: ['Please select destination of ISA transfer'],
    },
    isa_provider_id: {
      rules: [form.isa_provider_id !== null],
      errors: ['Please select a provider or enter a custom one'],
    },
    isa_provider_name: {
      rules: [!!form.isa_provider_name || form.isa_provider_id !== 'other'],
      errors: ['ISA provider name cannot be empty'],
    },
    isa_account: {
      rules: [!!form.isa_account],
      errors: ['ISA account cannot be empty'],
    },
    transfer_type: {
      rules: [form.transfer_type !== null],
      errors: ['Please select an option'],
    },
    previous_years_amount: {
      rules: [!isTransferForPreviousYearsPartSelected || isFullTransfer || previousYearsAmount > 0],
      errors: ['Please enter a value'],
    },
    isa_transfer_details: {
      rules: [isTransferForCurrentYearSelected || isTransferForPreviousYearsSelected || !isPartialTransfer],
      errors: [`Please choose whether you’d like to transfer your current or previous tax year ISA.`],
    },
    agreed: {
      rules: [form.agreed],
      errors: ['This field is required'],
    },
  }
})

export const $isValid = $validation.map((validation) => !!bulkValidate(validation))
