import React from 'react'

import compose from 'helpers/compose.js'
import rawMediaQueries from 'helpers/mediaQueries.js'
import { palette } from 'helpers/palette/'

import withMediaQueries from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Card from 'components/_old/Card/Card.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Width from 'components/_old/Width/Width'
import Headline from 'components/_old/Headline/Headline.jsx'
import Modal from 'components/_old/Modal/Modal.jsx'
import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import MergeIcons from 'components/_old/MergeIcons/MergeIcons.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

const AttentionModal = ({
  children,
  visible: open,
  closeAttentionModal: handleClose,
  submit,
  mediaQueries: { desktop } = {},
}) => (
  <Modal open={open} onClose={handleClose} gatewayName="attention" data-test-id="attentionModal">
    <Width size={36}>
      <Text center block>
        <Width size={3} center>
          <MergeIcons size={3}>
            <Icon type="triangle" color={palette['status-warning']} fluid inline />
            <Icon
              style={{ marginTop: '25%' }}
              type="exclamation"
              color={palette['background-default']}
              size={34}
              inline
            />
          </MergeIcons>
        </Width>
        <Headline level={2}>
          <Text color="orange">Attention</Text>
        </Headline>
        <Card
          mods={{
            theme: 'transparent',
            'no-padding': 'top left right',
          }}
        >
          <Text small left>
            {children}
          </Text>
        </Card>
        <Width size={desktop ? 16 : null} center>
          <ColumnarLayout>{submit}</ColumnarLayout>
        </Width>
      </Text>
    </Width>
  </Modal>
)

export default compose(withMediaQueries(rawMediaQueries))(AttentionModal)
