import { ListOf } from 'app/effector/model'

import { type NomineesTransferProviderRegulatoryType, type NomineesTransferProviderDTO } from '../api/index'

import { NomineesTransferProvider } from './NomineesTransferProvider'

export class NomineesTransferProviderList extends ListOf(NomineesTransferProvider) {
  constructor(...providers: Array<NomineesTransferProvider | NomineesTransferProviderDTO>) {
    super(
      ...providers
        .sort((leftProvider, rightProvider) => leftProvider.id - rightProvider.id)
        .filter((provider) => provider.id && provider.id > 0),
    )
  }

  get(id: number): NomineesTransferProvider | undefined {
    return this.find((provider) => provider.id === id)
  }

  filterByRegulatoryType(regulatoryType: NomineesTransferProviderRegulatoryType): NomineesTransferProviderList {
    return new NomineesTransferProviderList(...this.filter((provider) => provider.regulatory_type === regulatoryType))
  }

  merge(newProvidersList: NomineesTransferProviderList | NomineesTransferProviderDTO[]): NomineesTransferProviderList {
    const mergedProviders = [...this, ...newProvidersList].reduce<
      Array<NomineesTransferProvider | NomineesTransferProviderDTO>
    >((resultArray, provider) => {
      const alreadyAddedIndex = resultArray.findIndex((alreadyAddedProvider) => alreadyAddedProvider.id === provider.id)

      if (alreadyAddedIndex > -1) {
        return [...resultArray.slice(0, alreadyAddedIndex), provider, ...resultArray.slice(alreadyAddedIndex + 1)]
      }

      resultArray.push(provider)
      return resultArray
    }, [])

    return new NomineesTransferProviderList(...mergedProviders)
  }
}
