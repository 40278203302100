import { combine, createDomain, createEvent, type Store } from 'effector'

import moment from 'helpers/date.js'

import * as securityApi from 'app/effector/securities/api'

import { ApiError } from 'app/redux/models/errors'

import * as api from './api'
import { Transaction, TransactionList } from './models'

const transactionsDomain = createDomain('transactions')

const { createStore, createEffect } = transactionsDomain

const formatFilters = (params): Record<string, string> => {
  const filters: Record<string, string> = { ...params }

  if (filters.date?.length && filters.date?.[0] !== 'custom') {
    const period = filters.date[0]
    const currentDay = moment().date()
    const currentMonth = moment().month()
    const isTaxYearStartInThisYear = (currentMonth === 3 && currentDay >= 6) || currentMonth > 3

    const datesRangeMap = {
      month: {
        date_from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD'),
      },
      year: {
        date_from: moment().subtract(1, 'years').format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD'),
      },
      currentTaxYear: {
        date_from: isTaxYearStartInThisYear
          ? moment().month(3).date(6).format('YYYY-MM-DD')
          : moment().subtract(1, 'years').month(3).date(6).format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD'),
      },
      prevTaxYear: {
        date_from: isTaxYearStartInThisYear
          ? moment().subtract(1, 'years').month(3).date(6).format('YYYY-MM-DD')
          : moment().subtract(2, 'years').month(3).date(6).format('YYYY-MM-DD'),
        date_to: isTaxYearStartInThisYear
          ? moment().month(3).date(5).format('YYYY-MM-DD')
          : moment().subtract(1, 'years').month(3).date(5).format('YYYY-MM-DD'),
      },
    }

    Object.assign(filters, datesRangeMap[period])
  }
  delete filters.date

  return filters
}

// Events
const setValid = createEvent()
const setNotValid = createEvent()
const receiveError = createEvent<{ error: string }>()
const resetError = createEvent()
const receiveTransactions = createEvent<api.TransactionsDTO>()

// Effects
const fetchTransactionsFx = createEffect(
  async ({
    filtersState = {},
    setNotValidBefore = true,
    setValidAfter = true,
  }: {
    filtersState?: Record<string, string[] | number[]>
    setNotValidBefore?: boolean
    setValidAfter?: boolean
  } = {}) => {
    try {
      if (setNotValidBefore) {
        setNotValid()
      }
      const filters = formatFilters(filtersState)

      const transactions = await api.getTransactions(filters)

      if (transactions instanceof ApiError) {
        throw transactions
      }

      if (setValidAfter) {
        setValid()
      }

      return transactions
    } catch (error) {
      receiveError(error)
      setValid()
    }
  },
)

const fetchSecurityTransactionsFx = createEffect(
  async ({
    filtersState = {},
    setNotValidBefore = true,
    setValidAfter = true,
  }: {
    filtersState?: Record<string, string[] | number[]>
    setNotValidBefore?: boolean
    setValidAfter?: boolean
  } = {}) => {
    try {
      if (setNotValidBefore) {
        setNotValid()
      }
      const filters = formatFilters(filtersState)

      const transactions = await api.getSecurityTransactions(filters)

      if (transactions instanceof ApiError) {
        throw transactions
      }

      if (setValidAfter) {
        setValid()
      }

      return transactions
    } catch (error) {
      receiveError(error)
      setValid()
    }
  },
)

const fetchSecuritiesFx = createEffect(async ({ setNotValidBefore = true, setValidAfter = true } = {}) => {
  try {
    if (setNotValidBefore) {
      setNotValid()
    }

    const securities = await securityApi.getSecuritiesByTransactions()

    if (securities instanceof ApiError) {
      throw securities
    }

    if (setValidAfter) {
      setValid()
    }

    return securities
  } catch (error) {
    receiveError(error)
    setValid()
  }
})

const fetchAwaitingTransactionsFx = createEffect(async ({ setNotValidBefore = true, setValidAfter = true }) => {
  try {
    if (setNotValidBefore) {
      setNotValid()
    }

    const data = await api.getAwaitingTransactions()

    if (data instanceof ApiError) throw data
    if (setValidAfter) {
      setValid()
    }
    return data
  } catch (error) {
    receiveError(error)
    setValid()
  }
})

const cancelTransactionFx = createEffect(async (id, { setNotValidBefore = true, setValidAfter = true } = {}) => {
  try {
    if (setNotValidBefore) {
      setNotValid()
    }

    const data = await api.cancelTransaction(id)

    if (data instanceof ApiError) throw data
    if (setValidAfter) {
      setValid()
    }
  } catch (error) {
    receiveError(error)
    setValid()
  }
})

const moveCashBetweenPortfoliosFx = createEffect(
  async (transferData, { setNotValidBefore = true, setValidAfter = true } = {}) => {
    try {
      resetError()
      if (setNotValidBefore) {
        setNotValid()
      }

      const data = await api.moveCashBetweenPortfolios(transferData)

      if (data instanceof ApiError) throw data
      if (setValidAfter) {
        setValid()
      }
      return data
    } catch (error) {
      receiveError(error)
      setValid()
      throw error
    }
  },
)

const fetchNextTransactionsFx = createEffect(async ({ filtersState, next }) => {
  const filters = formatFilters(filtersState)

  const transactions = await api.getTransactions(filters, next)

  if (transactions instanceof ApiError) {
    throw transactions
  }

  return transactions
})

const fetchNextSecurityTransactionsFx = createEffect(async ({ filtersState, next }) => {
  const filters = formatFilters(filtersState)

  const transactions = await api.getSecurityTransactions(filters, next)

  if (transactions instanceof ApiError) {
    throw transactions
  }

  return transactions
})

// Stores
const $transactions = createStore<{
  list: TransactionList
  total: string | null
  nextPage: string | null
  didInvalidate: boolean
  error: string | null
}>({
  list: new TransactionList(),
  total: null,
  nextPage: null,
  didInvalidate: false,
  error: null,
})
const $securityTransactions = createStore<{
  list: TransactionList
  nextPage: string | null
  didInvalidate: boolean
  error: string | null
}>({
  list: new TransactionList(),
  nextPage: null,
  didInvalidate: false,
  error: null,
})

$transactions.on(setValid, (state) => ({ ...state, didInvalidate: false }))
$transactions.on(setNotValid, (state) => ({ ...state, didInvalidate: true }))
$transactions.on(receiveError, (state, data) => ({ ...state, error: data.error }))
$transactions.on(resetError, (state) => ({ ...state, error: null }))

$transactions.on([fetchTransactionsFx.doneData, receiveTransactions], (state, data) => {
  state.list.receiveTransactions(data.items.map((transactionObj) => Transaction.createFromObject(transactionObj)))
  return { ...state, total: data.total, nextPage: data.next }
})
$transactions.on(fetchNextTransactionsFx.doneData, (state, data) => {
  state.list.receiveTransactions([
    ...state.list.transactions,
    ...data.items.map((transactionObj) => Transaction.createFromObject(transactionObj)),
  ])
  return { ...state, total: data.total, nextPage: data.next }
})
$securityTransactions.on(fetchSecurityTransactionsFx.doneData, (state, data) => {
  state.list.receiveTransactions(data.items.map((transactionObj) => Transaction.createFromObject(transactionObj)))
  return { ...state, nextPage: data.next }
})
$securityTransactions.on(fetchNextSecurityTransactionsFx.doneData, (state, data) => {
  state.list.receiveTransactions([
    ...state.list.transactions,
    ...data.items.map((transactionObj) => Transaction.createFromObject(transactionObj)),
  ])
  return { ...state, nextPage: data.next }
})

const $areTransactionsFetched = createStore<boolean>(false)
$areTransactionsFetched.on(fetchTransactionsFx.done, () => true)

const $areSecurityTransactionsFetched = createStore<boolean>(false)
$areSecurityTransactionsFetched.on(fetchSecurityTransactionsFx.done, () => true)

const $securities = createStore([])
$securities.on(fetchSecuritiesFx.doneData, (state, data) => data)

const $isLoading = combine(
  fetchTransactionsFx.pending,
  fetchSecurityTransactionsFx.pending,
  fetchSecuritiesFx.pending,
  (...pendingEffects) => pendingEffects.some((pending) => pending),
)

const $isNextLoading = combine(
  fetchNextTransactionsFx.pending,
  fetchNextSecurityTransactionsFx.pending,
  (...pendingEffects) => pendingEffects.some((pending) => pending),
)

const $transactionsStore = combine<{
  transactions: typeof $transactions
  securityTransactions: typeof $securityTransactions
  securities: typeof $securities
  isLoading: boolean
  isNextLoading: boolean
  areTransactionsFetched: Store<boolean>
  areSecurityTransactionsFetched: Store<boolean>
}>({
  transactions: $transactions,
  securityTransactions: $securityTransactions,
  securities: $securities,
  isLoading: $isLoading,
  isNextLoading: $isNextLoading,
  areTransactionsFetched: $areTransactionsFetched,
  areSecurityTransactionsFetched: $areSecurityTransactionsFetched,
})

export {
  setValid,
  setNotValid,
  receiveError,
  resetError,
  receiveTransactions,
  moveCashBetweenPortfoliosFx,
  fetchAwaitingTransactionsFx,
  fetchNextSecurityTransactionsFx,
  fetchNextTransactionsFx,
  fetchSecuritiesFx,
  fetchSecurityTransactionsFx,
  fetchTransactionsFx,
  cancelTransactionFx,
  transactionsDomain,
  $transactionsStore,
  $transactions,
}
