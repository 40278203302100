import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { urlTo } from 'helpers/router.js'
import { useMemo } from 'hooks'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import Button from 'components/_old/Button/Button.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { ControlledInput } from 'components/atoms/Forms/ControlledInput'
import Label, { LabelField, LabelText } from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { TradingSuspendedWarning } from 'components/organisms/TradingSuspendedWarning/TradingSuspendedWarning.tsx'
import { PortfolioCard } from '../components/PortfolioCard/PortfolioCard'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Icon from 'components/_old/Icon/Icon.jsx'

import { BuySellTabs } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/components'
import { SecurityPreview } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/Security/components'

const BuyMobile = ({
  portfolioId,
  security,
  isValid,
  validation,
  errors,
  control,

  handleBack,
  handleAddFunds,
  handleSell,
  chosenPortfolio,
  openCashSelectModal,
  isIsaLimitWarningDisplayed,
  isBusiness,
}) => {
  const isTradingEnabled = useMemo(() => security.is_trading_available, [security])

  const tabs = <BuySellTabs activeTab="buy" handleSell={handleSell} />

  const selectedPortfolio = (
    <Fragment>
      <Paper bottom={8}>
        <Typography size={12} color="additional">
          <Typo>Available cash</Typo>
        </Typography>
      </Paper>
      <PortfolioCard portfolio={chosenPortfolio} onClick={openCashSelectModal} isBusiness={isBusiness} />
    </Fragment>
  )

  const addCash = (
    <Typography size={14}>
      <Link onClick={handleAddFunds}>
        <Typo>+ Add cash</Typo>
      </Link>
    </Typography>
  )

  const textField = (
    <Paper top={48}>
      <ControlledInput name="amount" control={control} errors={errors} rules={validation}>
        {({ value, valid, errorMessage, handleChange, handleFocus, handleBlur }) => (
          <Fragment>
            <Label valid={valid} errorMessage={errorMessage}>
              <LabelText>
                <Typography size={12} color="additional">
                  <Typo>Amount</Typo>
                </Typography>
              </LabelText>
              <LabelField>
                <Input
                  name="value"
                  type="money"
                  placeholder="£"
                  mods={{ size: 'big' }}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  withFloat
                >
                  {value}
                </Input>
              </LabelField>
            </Label>
            {!valid && (
              <Paper top={12}>
                <Typography size={14} color="error">
                  <Typo>{errorMessage}</Typo>
                </Typography>
              </Paper>
            )}
            <Paper top={valid ? 48 : 12} bottom={16}>
              {selectedPortfolio}
            </Paper>
            {portfolioId === chosenPortfolio?.id && (
              <Fragment>
                {addCash}
                <Paper top={40} bottom={40}>
                  <Typography align="center">
                    <Link
                      to={urlTo('dashboard.portfolio.options.cash-breakdown', { id: portfolioId })}
                      data-test-id="showCashBreakDownLink"
                    >
                      Show cash breakdown
                    </Link>
                  </Typography>
                </Paper>
              </Fragment>
            )}
          </Fragment>
        )}
      </ControlledInput>
    </Paper>
  )

  const isaLimitWarning = isIsaLimitWarningDisplayed && (
    <Paper top={32}>
      <ItemWithIcon
        space={12}
        icon={<Icon size={24} type="information-24" color="inherit" />}
        content={
          <Typography size={12}>
            <Typo>Transferring cash from a non-ISA to an ISA is subject to the annual ISA subscription limit</Typo>
          </Typography>
        }
      />
    </Paper>
  )

  const tradingEnabledContent = (
    <Fragment>
      <Paper top={32} bottom={32}>
        <Typography size={36} weight="semibold">
          <Typo>Buy</Typo>
        </Typography>
      </Paper>
      <SecurityPreview logo={security.logo_uri} title={security.title} />
      {textField}
      {isaLimitWarning}
    </Fragment>
  )

  const content = useMemo(
    () => (
      <Paper>
        {tabs}
        {isTradingEnabled ? tradingEnabledContent : <TradingSuspendedWarning />}
      </Paper>
    ),
    [tradingEnabledContent],
  )

  const footer = useMemo(
    () => (
      <MobileLayoutFooterButton>
        <Button type="submit" disabled={!isValid}>
          Review order
        </Button>
      </MobileLayoutFooterButton>
    ),
    [isValid],
  )

  return (
    <MobileLayout
      header={<NavigationBar leftPartText="Back" onLeftPartClick={handleBack} />}
      content={content}
      footer={isTradingEnabled ? footer : null}
    />
  )
}

BuyMobile.propTypes = {
  security: PropTypes.object,

  isValid: PropTypes.bool,
  validation: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,

  handleBack: PropTypes.func,
  handleAddFunds: PropTypes.func,
  handleSell: PropTypes.func,
}

export { BuyMobile }
