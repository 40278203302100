import { batch } from 'react-redux'

import { setValid as setTransactionsValid } from 'app/effector/transactions'

import { setValid as setClientValid } from 'app/redux/actions/client'
import { setValid as setGoalsValid } from 'app/redux/actions/portfolios'
import { setValid as setUiValid } from 'app/redux/actions/ui'
import store from 'app/redux/store/store.js'

const invalidateStore = ({ error }) => {
  if (!error) {
    return
  }

  if (`${error.response?.status}`[0] === '5') {
    return
  }

  const { dispatch } = store

  batch(() => {
    dispatch(setClientValid())
    dispatch(setGoalsValid())
    dispatch(setUiValid())
    setTransactionsValid()
  })
}

export { invalidateStore }
