/**
 *
 * @param {string} relativeUrl
 * @return {string}
 */

const formLinkToHost = (relativeUrl) => {
  const isLocal = /\.local/.test(CONFIG?.HOST)

  // Check is absolute, is so return
  if (/^https?/.test(relativeUrl)) {
    return relativeUrl
  }

  // On locally run dev server logic is different
  if (isLocal) {
    const hostUrl = CONFIG?.API_ROOT ? CONFIG.API_ROOT.match(/^https?:.*?\.com\//)?.[0] : ''

    return `${hostUrl ?? ''}${relativeUrl}`
  }

  return `${CONFIG?.HOST ?? ''}${relativeUrl}`
}

export { formLinkToHost }
