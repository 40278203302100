import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

type LoginWithDiscourseParams = {
  sso: string
  sig: string
}

type LoginWithDiscourseReturnData =
  | {
      redirect_uri: string
    }
  | ApiError

const authorizeWithDiscourse = async ({
  sso,
  sig,
}: LoginWithDiscourseParams): Promise<LoginWithDiscourseReturnData> => {
  try {
    const { data } = await axios.get(`/discourse/connect/`, {
      params: { sso, sig },
    })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { authorizeWithDiscourse }
