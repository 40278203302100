import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useCallback, useLoading } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { $quickStartsStore, finishQuickStartFx } from 'app/effector/quickStart'
import { $recurringPaymentsStore, fetchRecurringPaymentsFx } from 'app/effector/recurringPayments'

import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'
import { stopRecurringPayment } from 'app/redux/api/portfolio'
import { ApiError } from 'app/redux/models/errors'
import type { Portfolio } from 'app/redux/models/portfolio/types'

import { quickStartStatuses } from 'constants/quickStart'

const fetchRecurringPaymentsOnRecurringPaymentStopFx = attach({ effect: fetchRecurringPaymentsFx })

type UseRecurringPaymentStopProps = {
  portfolio: Portfolio
  location: {
    query?: object
  }
}

type UseRecurringPaymentStopReturnProps = {
  isLoading: boolean
  handleBack: () => void
  handleStopPayment: () => Promise<void>
}

const useRecurringPaymentStop = ({
  portfolio,
  location,
}: UseRecurringPaymentStopProps): UseRecurringPaymentStopReturnProps => {
  const { recurringPayments } = useUnit($recurringPaymentsStore)
  const recurringPayment = recurringPayments?.open_banking?.find((payment) => payment.portfolio_id === portfolio.id)
  const { getQuickStartByPortfolioId } = useUnit($quickStartsStore)
  const quickStart = getQuickStartByPortfolioId(portfolio.id)

  const { isLoading, wait } = useLoading()

  const handleBack = useCallback(() => {
    goTo(urlTo('dashboard.portfolio.options.recurring-payment-edit', { id: portfolio?.id }, location?.query ?? {}))
  }, [portfolio, location])

  const handleStopPayment = useCallback(async () => {
    const data = await wait(stopRecurringPayment(recurringPayment?.id))

    if (data instanceof ApiError) {
      showFailToast()
      return
    }

    fetchRecurringPaymentsOnRecurringPaymentStopFx()
    goTo(urlTo('dashboard.portfolio', { id: portfolio?.id }))
    showSuccessToast('Savings Plan stopped')

    if (quickStart?.status === quickStartStatuses.ACTIVE) {
      finishQuickStartFx(portfolio.id)
    }
  }, [wait, portfolio, recurringPayment, quickStart])

  return {
    isLoading,
    handleBack,
    handleStopPayment,
  }
}

export { useRecurringPaymentStop }
