import React, { Fragment } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Typography } from 'components/atoms/Typography'

import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'
import { Document } from 'components/molecules/Document'
import { Modal, type ModalAnimation } from 'components/molecules/Modal'

type KeyFeatureDocumentModalProps = {
  open: boolean
  preset: {
    title: string
    kiid: string
  }
  animation?: ModalAnimation
  handleClose: () => void
  handleSubmit: () => unknown
}

function KeyFeatureDocumentModal({
  open,
  preset,
  animation,
  handleClose,
  handleSubmit,
}: KeyFeatureDocumentModalProps): React.ReactElement | null {
  const { desktop } = useMediaQueries()

  const content = useMemo(() => {
    const title = preset?.title ? (
      <Typography weight="semibold">
        <Typo>Please review the Key features document for {preset.title} portfolio</Typo>
      </Typography>
    ) : null

    const document =
      preset?.title && preset?.kiid ? (
        <Document name={`Key Features document (${preset.title})`} src={preset.kiid} />
      ) : null

    const disclaimer = (
      <Typography>
        <Typo>
          By pressing "Continue", I confirm that I have read and understood the Key Features Document for this portfolio
          and that I will inform InvestEngine if I have any questions.
        </Typo>
      </Typography>
    )

    const cancel = (
      <Button mods={{ size: 'big block', theme: 'simple-reverse-blue' }} onClick={handleClose}>
        Cancel
      </Button>
    )

    const submit = (
      <Button mods={{ size: 'big block' }} onClick={handleSubmit}>
        Continue
      </Button>
    )

    return {
      title,
      document,
      disclaimer,
      cancel,
      submit,
    }
  }, [preset?.kiid, preset?.title, handleClose, handleSubmit])

  if (!preset) {
    return null
  }

  if (desktop) {
    return (
      <Modal open={open} close={null} animation={animation} onClose={handleClose}>
        <Width size={36}>
          <Paper top={48} right={48} bottom={48} left={48}>
            {content.title}
            {content.document && <Paper top={16}>{content.document}</Paper>}
            <Paper top={32}>{content.disclaimer}</Paper>
            <Paper top={32}>
              <ButtonGroup>
                {content.cancel}
                {content.submit}
              </ButtonGroup>
            </Paper>
          </Paper>
        </Width>
      </Modal>
    )
  }

  return (
    <Modal open={open} close={null} animation={animation} onClose={handleClose}>
      <MobileLayout
        content={
          <Fragment>
            {content.title}
            {content.document && (
              <SideSpacesCompensator>
                <Paper top={16}>{content.document}</Paper>
              </SideSpacesCompensator>
            )}
            <Paper top={32}>{content.disclaimer}</Paper>
          </Fragment>
        }
        footer={
          <MobileLayoutFooterButton>
            <ButtonGroup columnarLayoutMods={{ padding: 'small' }} buttonMods={{ theme: null }}>
              {content.cancel}
              {content.submit}
            </ButtonGroup>
          </MobileLayoutFooterButton>
        }
      />
    </Modal>
  )
}

export { KeyFeatureDocumentModal }
