import { useUnit } from 'effector-react'

import { useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { $allowancesStore } from 'app/effector/allowances'

type UseSippUnnualLimitModalReturnProps = {
  remainingAllowance?: string
  totalAllowance?: string
  topupsContribution?: string
  rebatesContribution?: string
  handleClose: () => void
}

const useSippUnnualLimitModal = (): UseSippUnnualLimitModalReturnProps => {
  const { allowances } = useUnit($allowancesStore)
  const sippAllowance = allowances.sipp

  const handleClose = useCallback(() => {
    goTo(urlTo('dashboard'))
  }, [])

  return {
    remainingAllowance: sippAllowance?.remaining_allowance,
    totalAllowance: sippAllowance?.total_allowance,
    topupsContribution: sippAllowance?.topups_contribution,
    rebatesContribution: sippAllowance?.rebates_contribution,
    handleClose,
  }
}

export { useSippUnnualLimitModal }
