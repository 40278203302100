import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useEffect, useState } from 'hooks'

import { $hintsStore, fetchHintsFx, setHintAsWatchedFx } from 'app/effector/hints'

import { showFailToast } from 'app/redux/actions/ui'

type useHintsStoreParams = {
  nameKey: string
}

type useHintsStoreData = {
  shouldDisplayHints: boolean
  handleHintsDisplayed: () => Promise<void>
}

const fetchHintsDashboardFx = attach({ effect: fetchHintsFx })
const setDashboardHintAsWatchedFx = attach({ effect: setHintAsWatchedFx })

const useHintsStore = ({ nameKey }: useHintsStoreParams): useHintsStoreData => {
  const { hints, areHintsFetched } = useUnit($hintsStore)

  const [shouldDisplayHints, setShouldDisplayHints] = useState(false)

  const handleHintsDisplayed = async (): Promise<void> => {
    await setDashboardHintAsWatchedFx(nameKey)
  }

  useEffect(() => {
    fetchHintsDashboardFx()

    const unwatchFetchHintsFx = fetchHintsDashboardFx.fail.watch(() => {
      showFailToast()
    })
    const unwatchSetHintWatched = setDashboardHintAsWatchedFx.fail.watch(() => {
      showFailToast()
    })

    return () => {
      unwatchSetHintWatched()
      unwatchFetchHintsFx()
    }
  }, [])

  useEffect(() => {
    if (areHintsFetched && !!hints && !hints?.[nameKey]) {
      setShouldDisplayHints(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areHintsFetched, hints])

  return { shouldDisplayHints, handleHintsDisplayed }
}

export { useHintsStore }
