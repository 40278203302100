import { Entity } from 'app/redux/models/common/Entity.js'

import { SecurityTotalReturnsHistoryList } from './SecurityTotalReturnsHistoryList'

const PERIODS = ['week', 'month', 'six_months', 'year', 'max'] as const

type SecurityTotalReturnsHistoryCollectionRulesReturn = Record<
  (typeof PERIODS)[number],
  [[(item) => SecurityTotalReturnsHistoryList]]
>

export class SecurityTotalReturnsHistoryCollection extends Entity {
  week: SecurityTotalReturnsHistoryList
  month: SecurityTotalReturnsHistoryList
  six_months: SecurityTotalReturnsHistoryList
  year: SecurityTotalReturnsHistoryList
  max: SecurityTotalReturnsHistoryList

  constructor() {
    super()

    this.week = new SecurityTotalReturnsHistoryList()
    this.month = new SecurityTotalReturnsHistoryList()
    this.six_months = new SecurityTotalReturnsHistoryList()
    this.year = new SecurityTotalReturnsHistoryList()
    this.max = new SecurityTotalReturnsHistoryList()
  }

  getRules(): SecurityTotalReturnsHistoryCollectionRulesReturn {
    return {
      week: [[(week) => new SecurityTotalReturnsHistoryList(...week)]],
      month: [[(month) => new SecurityTotalReturnsHistoryList(...month)]],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      six_months: [[(six_months) => new SecurityTotalReturnsHistoryList(...six_months)]],
      year: [[(year) => new SecurityTotalReturnsHistoryList(...year)]],
      max: [[(max) => new SecurityTotalReturnsHistoryList(...max)]],
    }
  }
}
