import React from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries } from 'hooks'

import Width from 'components/_old/Width/Width'

import { Modal } from 'components/molecules/Modal'

import { SmsCodeForm } from 'components/organisms/SmsCodeForm'

import { $smsModalOpened, $phoneNumber, navigateToFinishFx, closeSmsModal } from './model'

const SmsFormModal = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const [smsModalOpened, phoneNumber, handleNavigateToFinish, handleCloseSmsModal] = useUnit([
    $smsModalOpened,
    $phoneNumber,
    navigateToFinishFx,
    closeSmsModal,
  ])

  const content = <SmsCodeForm action="setup_2fa" phone={phoneNumber} handleSuccess={handleNavigateToFinish} />

  if (desktop) {
    return (
      <Modal open={smsModalOpened} onClose={handleCloseSmsModal}>
        <Width size={36} style={{ minHeight: '600px' }}>
          {content}
        </Width>
      </Modal>
    )
  }

  return (
    <Modal open={smsModalOpened} onClose={handleCloseSmsModal}>
      {content}
    </Modal>
  )
}

export { SmsFormModal }
