import { childrenOfType } from './childrenOfType.js'
import { instanceOf } from './instanceOf.js'
import { tuple } from './tuple.js'

const propTypes = {
  instanceOf,
  childrenOfType,
  tuple,
}

export { propTypes }
