import React from 'react'
import { withRouter } from 'react-router'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

type NotApprovedModalProps = {
  handleClose: () => void
  handleBack: () => void
}

const NotApprovedModal = withRouter(({ handleClose, handleBack }: NotApprovedModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const headerNode = (
    <NavigationBar
      rightPartText="Close"
      onRightPartClick={handleClose}
      leftPartText="Back"
      onLeftPartClick={handleBack}
    />
  )

  const button = (
    <Button mods={{ size: 'big block' }} onClick={handleClose} data-test-id="notApprovedModalOk">
      OK
    </Button>
  )

  const content = (
    <Width size={28} center>
      <Paper top={56} left={desktop ? 0 : 16} right={desktop ? 0 : 16}>
        <AllCenter>
          <Icon type="time-in-circle-64" color={palette['status-warning']} size={64} data-test-id="warningIcon" />
        </AllCenter>
        <Paper top={32}>
          <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
            We need to check something
          </Typography>
        </Paper>
        <Paper top={24}>
          <Typography align="center">
            <Typo>
              Unfortunately, we weren’t able to automatically activate your account. Our Support team will be in touch
              with you shortly.
            </Typo>
          </Typography>
        </Paper>
      </Paper>
    </Width>
  )

  if (desktop)
    return (
      <Modal open close={null} zIndex={1002} onClose={handleClose} data-test-id="notApprovedModal">
        <Width size={36} style={{ minHeight: '600px' }}>
          <Paper top={20} bottom={20} left={24} right={24}>
            {headerNode}
          </Paper>
          {content}
          <Paper top={56}>
            <Width size={24} center>
              {button}
            </Width>
          </Paper>
        </Width>
      </Modal>
    )

  return (
    <Modal open zIndex={1002} onClose={handleClose}>
      <MobileLayout header={headerNode} content={content} footer={button} />
    </Modal>
  )
})

export { NotApprovedModal }
