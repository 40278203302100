import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { propTypes } from 'helpers/propTypes'
import { urlTo } from 'helpers/router.js'
import { features } from 'helpers/features'
import localstore from 'helpers/localstore.js'

import { useState, useMemo, useSelector, useEffect, useCallback } from 'hooks'

import { AddSecurities } from 'components/molecules/AddSecurities'
import { Card } from 'components/atoms/Card'
import { MobileFooter } from 'app/containers/Footer'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar, NavigationBarTitleMultipleChildHolder } from 'components/atoms/NavigationBar'
import { NewPeriodSelect } from 'components/molecules/NewPeriodSelect'
import { Paper } from 'components/atoms/Paper'
import { PortfolioPointOfInterestCard } from 'components/organisms/charts/parts'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'
import { PriceChart } from 'components/organisms/PriceChart'
import { ReturnInfo } from 'components/organisms/ReturnInfo'
import { SecurityInfo } from 'components/organisms/SecurityInfo'
import { SharePortfolioBanner } from 'components/molecules/SharePortfolioBanner'
import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import { Typography } from 'components/atoms/Typography'
import Button from 'components/_old/Button/Button.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import NotificationGroup from 'components/organisms/Notification/NotificationGroup.tsx'
import { Typo } from 'components/_old/Typo/Typo'
import Toggle from 'components/atoms/Toggle/Toggle.jsx'
import { AltusProgressBar } from 'components/molecules/AltusProgressBar'
import { Skeleton } from 'components/atoms/Skeleton'
import { Document } from 'components/molecules/Document'

import { PortfolioLabels } from '../components/PortfolioLabels'
import { ChartSelectTabs } from '../components/ChartSelectTabs'
import { DividendsChart } from '../components/DividendsChart'
import { TransferIsaBlock } from 'app/pages/Dashboard/Goals/components/TransferIsaBlock'
import { OrdersPlacedButton } from 'app/pages/Dashboard/Goals/DIY/Portfolio/components/OrdersPlacedButton'
import { SavingsPlanLabel } from 'app/pages/Dashboard/Goals/components/SavingsPlanLabel'
import { SavingsPlanSetupBanner } from 'app/pages/Dashboard/Goals/components/SavingsPlanSetupBanner'
import { LumpSumBanner } from 'app/pages/Dashboard/Goals/components/LumpSumBanner'
import { FirstTopupBanner } from 'app/pages/Dashboard/Goals/components/FirstTopupBanner'
import { OnboardingProgressBar } from '../components/OnboardingProgressBar/OnboardingProgressBar'

import { PendingOrderList } from 'app/effector/pending-orders/models'
import { NotificationScopeType } from 'app/effector/notifications/models'

import { selectGoalTitle } from 'app/redux/selectors'

import { manageTypes, states as portfolioStates } from 'constants/portfolio'

import './Mobile.css'

const Mobile = ({
  isHistoryLoading,
  portfolio,
  portfolioActions,
  portfolioOrders,
  securities,
  chartData,
  contributions,
  pointsOfInterest,
  periodPriceData,
  selectedPeriod,
  activeChart,
  handlePeriodSelect,
  handleBack,
  handleAddSecurities,
  handleSelectSecurity,
  handleBuySell,
  setChartTab,
  showFinishSavingsPlanBanner,
  quickStart,
  showFirstTopupBanner,
  showLumpSumBanner,
  daysBeforePayment,
  altusProgressData,
  regulatoryTypeText,
  handleDismissAltusPanel,
}) => {
  const goalTitle = useSelector((state) => selectGoalTitle(state, portfolio?.id))
  const [contributionsVisible, setContributionsVisible] = useState(
    ((localstore.get('goals') || {})[portfolio.id] || {}).contributionsVisible,
  )

  const handleChangeContributionsVisible = useCallback(
    (event) => {
      const visible = event.target.checked
      setContributionsVisible(visible)
      const storedGoals = localstore.get('goals') || {}

      if (!storedGoals[portfolio.id]) {
        storedGoals[portfolio.id] = {}
      }
      storedGoals[portfolio.id].contributionsVisible = visible

      localstore.set('goals', storedGoals)
    },
    [portfolio.id],
  )

  useEffect(() => {
    const storedGoals = localstore.get('goals') || {}
    const storeContributionsVisibelValue = Boolean((storedGoals[portfolio.id] || {}).contributionsVisible)
    setContributionsVisible(storeContributionsVisibelValue)
  }, [portfolio.id])

  const notifications = useMemo(
    () => (
      <NotificationGroup scope={NotificationScopeType.PORTFOLIO} portfolioId={portfolio.id}>
        {(nodes) => nodes && <Paper top={16}>{nodes}</Paper>}
      </NotificationGroup>
    ),
    [portfolio.id],
  )

  const balance = useMemo(
    () => (
      <Paper top={24}>
        <Typography size={14} color="minor" lineHeight="small">
          Portfolio balance{' '}
          <TooltipToModal
            description="The current balance of your portfolio including any pending transfers still being processed"
            customIcon
            offset={-350}
            data-test-id="portfolioBalanceTooltip"
          >
            <Link mods={{ color: 'minorToRed' }} style={{ display: 'inline-flex' }}>
              <Icon size={16} type="information-16" color="inherit" style={{ position: 'relative', top: '3px' }} />
            </Link>
          </TooltipToModal>
        </Typography>
        <Paper top={4}>
          <Typography size={36} weight="semibold" lineHeight="small">
            <PoundsWithPence amount={portfolio.capital_balance} />
          </Typography>
        </Paper>
      </Paper>
    ),
    [portfolio.capital_balance],
  )

  const savingsPlanSetupBannerNode = useMemo(
    () =>
      showFinishSavingsPlanBanner && (
        <Paper top={32}>
          <SavingsPlanSetupBanner portfolio={portfolio} quickStart={quickStart} />
        </Paper>
      ),
    [showFinishSavingsPlanBanner, quickStart, portfolio],
  )

  const lumpSumBannerNode = useMemo(
    () =>
      showLumpSumBanner && (
        <Paper top={32}>
          <LumpSumBanner portfolio={portfolio} quickStart={quickStart} />
        </Paper>
      ),
    [showLumpSumBanner, quickStart, portfolio],
  )

  const firstTopupBannerNode = useMemo(
    () =>
      showFirstTopupBanner && (
        <Paper top={32}>
          <FirstTopupBanner portfolio={portfolio} daysBeforePayment={daysBeforePayment} />
        </Paper>
      ),
    [portfolio, showFirstTopupBanner, daysBeforePayment],
  )

  const newPeriodSelect = useMemo(
    () => (
      <Paper top={48}>
        <NewPeriodSelect selectedPeriod={selectedPeriod} handlePeriodSelect={handlePeriodSelect} />
      </Paper>
    ),
    [selectedPeriod, handlePeriodSelect],
  )

  const investmentReturnInfo = useMemo(
    () => <ReturnInfo price={periodPriceData?.price ?? 0} percent={periodPriceData?.percent ?? 0} />,
    [periodPriceData],
  )

  const returnChart = useMemo(
    () => (
      <Paper top={16}>
        <Skeleton shown={isHistoryLoading && chartData.performance.length < 1} mix>
          <div>
            <PriceChart
              period={selectedPeriod}
              data={chartData.performance}
              contributionsData={contributionsVisible ? chartData.contributions : null}
              topContentOffset={32}
              topContent={investmentReturnInfo}
              negative={(periodPriceData?.price ?? 0) < 0}
              portfolioId={portfolio.id}
              pointsOfInterest={pointsOfInterest}
              PointOfInterestCard={PortfolioPointOfInterestCard}
            />
            {chartData.performance.length > 0 && (
              <Paper top={16}>
                <Typography align="left" size={14}>
                  <label style={{ display: 'inline-flex', 'align-items': 'center' }}>
                    <Paper right={8} inline style={{ display: 'flex' }}>
                      <Toggle checked={contributionsVisible} onChange={handleChangeContributionsVisible} />
                    </Paper>
                    <Typo>Net contributions</Typo>
                    <Paper left={8} inline data-test-id="goalChartContribution">
                      <PoundsWithPence amount={contributions} />
                    </Paper>
                  </label>
                </Typography>
              </Paper>
            )}
          </div>
        </Skeleton>
      </Paper>
    ),
    [
      isHistoryLoading,
      chartData,
      selectedPeriod,
      periodPriceData?.price,
      portfolio.id,
      pointsOfInterest,
      contributions,
      contributionsVisible,
      investmentReturnInfo,
      handleChangeContributionsVisible,
    ],
  )

  const dividendsChart = useMemo(
    () => (
      <Paper top={16}>
        <Skeleton shown={isHistoryLoading && (!chartData.dividends || (chartData.dividends?.length ?? 0) < 0)}>
          <DividendsChart
            data={chartData.dividends}
            period={selectedPeriod}
            securities={securities}
            portfolioId={portfolio.id}
          />
        </Skeleton>
      </Paper>
    ),
    [isHistoryLoading, chartData.dividends, selectedPeriod, securities, portfolio.id],
  )

  const chartSelectNode = (
    <Paper top={16} bottom={8}>
      <ChartSelectTabs activeChart={activeChart} setActiveChart={setChartTab} />
    </Paper>
  )

  const chartNode = useMemo(
    () => (activeChart === 'return' ? returnChart : dividendsChart),
    [activeChart, returnChart, dividendsChart],
  )

  const altusProgress = useMemo(
    () =>
      altusProgressData &&
      altusProgressData.length > 0 && (
        <Paper top={24}>
          {altusProgressData.map((progressBar, index) => (
            <Paper key={index} top={index !== 0 && 16}>
              <AltusProgressBar
                altusProgressBarData={progressBar}
                handleDismissPanel={handleDismissAltusPanel}
                data-test-id="transferProgressBar"
              />
            </Paper>
          ))}
        </Paper>
      ),
    [altusProgressData, handleDismissAltusPanel],
  )

  const onboardingProgressBar = !altusProgress && <OnboardingProgressBar portfolio={portfolio} />

  const ordersPlaced = useMemo(
    () =>
      portfolioOrders.length > 0 && (
        <Paper top={altusProgressData && features.get('altus-progress-bar') ? 32 : 24}>
          <OrdersPlacedButton portfolioId={portfolio?.id} />
        </Paper>
      ),
    [altusProgressData, portfolioOrders, portfolio?.id],
  )

  const buttons = useMemo(
    () => (
      <Paper bottom={16} right={16} left={16}>
        <ColumnarLayout mods={{ padding: 'no' }}>
          <Column size={1}>
            <Card>
              <Button mods={{ size: 'big block' }} onClick={handleBuySell}>
                Invest
              </Button>
            </Card>
          </Column>
          <Column size={0}>
            <Paper left={8}>
              <Card color="background-default">
                <Button mods={{ theme: 'simple-reverse-blue', size: 'big' }} data-test-id="portfolioOptionsButton">
                  <Link to={urlTo('portfolio.options', { id: portfolio.id })}>Options</Link>
                </Button>
              </Card>
            </Paper>
          </Column>
        </ColumnarLayout>
      </Paper>
    ),
    [portfolio, handleBuySell],
  )

  const securityComponents = useMemo(
    () => (
      <Paper top={32}>
        <AddSecurities onClick={handleAddSecurities} />
        {securities.map((security, index) => (
          <Paper key={security.skeleton ? index : security.id} top={12}>
            <Skeleton shown={security.skeleton} mix>
              <SecurityInfo
                {...security}
                stats={security?.stats?.[selectedPeriod]}
                onClick={security?.id ? () => handleSelectSecurity(security.id) : null}
              />
            </Skeleton>
          </Paper>
        ))}
      </Paper>
    ),
    [securities, handleAddSecurities, handleSelectSecurity, selectedPeriod],
  )

  const transferIsaBlock = useMemo(() => {
    if ([manageTypes.MANAGED, manageTypes.SELF_SELECTED].includes(portfolio?.manage_type)) {
      return false
    }

    if (portfolio?.state === portfolioStates.CLOSING) {
      return false
    }

    if (portfolio?.isa?.transferring_in_process) {
      return (
        <Paper top={56}>
          <TransferIsaBlock mode="wide" portfolioId={portfolio.id} reference={portfolio.reference} />
        </Paper>
      )
    }
  }, [
    portfolio?.manage_type,
    portfolio?.state,
    portfolio?.isa?.transferring_in_process,
    portfolio.id,
    portfolio.reference,
  ])

  const shareButton = useMemo(
    () => (
      <Paper top={12}>
        <SharePortfolioBanner portfolioId={portfolio?.id} isSharingEnabled={!!portfolio?.share_url} />
      </Paper>
    ),
    [portfolio?.id, portfolio?.share_url],
  )

  const kiidNode = (
    <Paper top={48}>
      <Typography size={24} lineHeight="small" weight="semibold">
        <Typo>Key information</Typo>
      </Typography>
      <Paper top={24}>
        <Document name="Key Features document" src="https://public.investengine.com/pdf/DIY%20Portfolio%20KFD.pdf" />
      </Paper>
    </Paper>
  )

  const content = (
    <Fragment>
      {notifications}
      {features.get('altus-progress-bar') && altusProgress}
      {onboardingProgressBar}
      {ordersPlaced}
      {balance}
      <PortfolioLabels portfolio={portfolio} collapsed />
      <Paper>
        <SavingsPlanLabel portfolio={portfolio} />
      </Paper>
      {firstTopupBannerNode}
      {savingsPlanSetupBannerNode}
      {lumpSumBannerNode}
      {portfolio?.first_topup && (
        <Fragment>
          {newPeriodSelect}
          {chartSelectNode}
          {chartNode}
        </Fragment>
      )}
      {kiidNode}
      {securityComponents}
      {shareButton}
      {transferIsaBlock}
      <MobileFooter />
    </Fragment>
  )

  return (
    <MobileLayout
      header={
        <NavigationBar onLeftPartClick={handleBack} leftPartText="Back">
          <NavigationBarTitleMultipleChildHolder>
            <Typography color="inherit" align="center" lineHeight="small" weight="semibold">
              {goalTitle}
            </Typography>
            <Paper top={1} bottom={1}>
              <Typography
                size={12}
                weight="semibold"
                color="on_color_default"
                align="center"
                lineHeight="small"
                className="MobileDIYPortfolio-TypeSubtitle"
                inline
              >
                Self-managed, {regulatoryTypeText}
              </Typography>
            </Paper>
          </NavigationBarTitleMultipleChildHolder>
        </NavigationBar>
      }
      content={content}
      contentPaperSizes={{
        top: 0,
        bottom: 40,
      }}
      footer={buttons}
    />
  )
}

Mobile.propTypes = {
  isSecuritiesLoading: PropTypes.bool,
  isHistoryLoading: PropTypes.bool,
  isRebalanceLoading: PropTypes.bool,
  portfolio: PropTypes.object.isRequired,
  portfolioActions: PropTypes.array.isRequired,
  portfolioOrders: propTypes.instanceOf(PendingOrderList),
  securities: PropTypes.array,
  chartData: PropTypes.shape({
    performance: PropTypes.array,
    contributions: PropTypes.array,
    dividends: PropTypes.array,
  }),
  pointsOfInterest: PropTypes.array,
  periodPriceData: PropTypes.shape({
    price: PropTypes.number,
    percent: PropTypes.number,
    name: PropTypes.string,
  }),
  activeChart: PropTypes.string,
  contributions: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  handlePeriodSelect: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleAddSecurities: PropTypes.func.isRequired,
  handleSelectSecurity: PropTypes.func.isRequired,
  handleBuySell: PropTypes.func.isRequired,
  setActiveChart: PropTypes.func.isRequired,
  showFinishSavingsPlanBanner: PropTypes.bool,
  quickStart: PropTypes.object,
  showFirstTopupBanner: PropTypes.bool,
  setChartTab: PropTypes.func.isRequired,
  showLumpSumBanner: PropTypes.bool,
  daysBeforePayment: PropTypes.number,
  regulatoryTypeText: PropTypes.string,
  handleDismissAltusPanel: PropTypes.func,
}

export { Mobile }
