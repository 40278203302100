import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { useUnit } from 'effector-react'

import { propTypes } from 'helpers/propTypes'

import { useCallback, useMemo, useSelector } from 'hooks'
import { useReferralCode } from 'app/pages/Dashboard/UserProfile/ReferralCode/hooks/useReferralCode'

import { CreateNewPortfolioLink } from 'app/pages/Dashboard/Accounts/components/CreateNewPortfolioLink'
import { DashboardContextMenu } from '../components/DashboardContextMenu'
import { DashboardStories } from '../components/DashboardStories'
import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'
import { DesktopLayout } from 'components/atoms/Layouts'
import { ErrorBoundary } from 'components/atoms/ErrorBoundary/ErrorBoundary'
import { GiaTitle } from 'app/pages/Dashboard/Accounts/components/GiaTitle/'
import { IsaTitle } from 'app/pages/Dashboard/Accounts/components/IsaTitle/'
import { OpenIsaLink } from 'app/pages/Dashboard/Accounts/components/OpenIsaLink'
import { OpenSippLink } from 'app/pages/Dashboard/Accounts/components/OpenSippLink'
import { Paper } from 'components/atoms/Paper'
import { Performance } from 'app/pages/Dashboard/Accounts/components/Performance'
import { PortfolioSelectableCard } from 'app/pages/Dashboard/Accounts/components/PortfolioSelectableCard'
import { PromoBanner } from 'app/pages/Dashboard/PromoBanner'
import { SippTitle } from 'app/pages/Dashboard/Accounts/components/SippTitle/'
import { TransferIsaLink } from 'app/pages/Dashboard/Accounts/components/TransferIsaLink'
import { TransferSippLink } from 'app/pages/Dashboard/Accounts/components/TransferSippLink'
import { Typo } from 'components/_old/Typo/'
import NotificationGroup from 'components/organisms/Notification/NotificationGroup.tsx'
import Width from 'components/_old/Width/Width'

import { $dictsStore } from 'app/effector/dicts'

import { isClientApproved } from 'app/redux/selectors'
import { regulatoryTypes } from 'constants/portfolio'
import { states as clientStates } from 'constants/client'

import { PortfolioList } from 'app/redux/models/portfolio'
import { NotificationScopeType } from 'app/effector/notifications/models'
import { Skeleton } from 'components/atoms/Skeleton'

const Desktop = ({
  client,
  groupedPortfolios,
  remainingIsaAllowance,
  totalSippAllowance,
  isPortfoliosSkeletons,
  createPortfolioUrl,
  isBusiness,
  canOpenIsa,
  canOpenSipp,
  isIsaAccountOpened,
  isSippAccountOpened,
  hasPortfolios,
  cashPortfolioRef,
  isaCashPortfolioRef,
  addPortfolioRef,
  addIsaRef,
  addSippRef,
  transferProgressCollection,
  regulatoryTypesOrder,
}) => {
  const header = useMemo(() => <DesktopHeader />, [])
  const { isPromoEnabled } = useUnit($dictsStore)
  const { isReferralCodeValid } = useReferralCode()

  const isPromoBannerDisplayed = useSelector(isClientApproved) && isPromoEnabled && isReferralCodeValid

  const giaBlockRef = useRef(null)
  const isaBlockRef = useRef(null)
  const sippBlockRef = useRef(null)

  const groupRefs = useMemo(
    () => ({
      [regulatoryTypes.GIA]: giaBlockRef,
      [regulatoryTypes.ISA]: isaBlockRef,
      [regulatoryTypes.SIPP]: sippBlockRef,
    }),
    [giaBlockRef, isaBlockRef, sippBlockRef],
  )

  const transferIsaLink = (
    <Paper top={24}>
      <Paper inline>
        <TransferIsaLink />
      </Paper>
    </Paper>
  )

  const transferSippLink = (
    <Paper top={24}>
      <Paper inline>
        <Skeleton shown={isPortfoliosSkeletons}>
          <TransferSippLink />
        </Skeleton>
      </Paper>
    </Paper>
  )

  const renderListBottom = useCallback(
    (regulatoryType) => {
      if (regulatoryType === regulatoryTypes.ISA && canOpenIsa && !isIsaAccountOpened) {
        return (
          <Fragment>
            <Paper top={24}>
              <Paper ref={addIsaRef} inline>
                <OpenIsaLink />
              </Paper>
            </Paper>
            {transferIsaLink}
          </Fragment>
        )
      }

      if (regulatoryType === regulatoryTypes.SIPP && canOpenSipp && !isSippAccountOpened) {
        return (
          <Fragment>
            <Paper top={24}>
              <Paper ref={addSippRef} inline>
                <Skeleton shown={isPortfoliosSkeletons}>
                  <OpenSippLink />
                </Skeleton>
              </Paper>
            </Paper>
            {transferSippLink}
          </Fragment>
        )
      }

      if (!hasPortfolios && !isPortfoliosSkeletons) {
        return (
          <>
            <Paper top={24}>
              <Paper ref={regulatoryType === regulatoryTypes.ISA ? addIsaRef : addPortfolioRef} inline>
                <CreateNewPortfolioLink regulatoryType={regulatoryType} canOpenIsaOrSipp={canOpenIsa || canOpenSipp} />
              </Paper>
            </Paper>
            {regulatoryType === regulatoryTypes.ISA && canOpenIsa && transferIsaLink}
            {regulatoryType === regulatoryTypes.SIPP && canOpenSipp && transferSippLink}
          </>
        )
      }

      if (regulatoryType === regulatoryTypes.ISA && canOpenIsa) {
        return transferIsaLink
      }

      if (regulatoryType === regulatoryTypes.SIPP && canOpenSipp) {
        return transferSippLink
      }

      return null
    },
    [
      isPortfoliosSkeletons,
      canOpenIsa,
      canOpenSipp,
      isIsaAccountOpened,
      isSippAccountOpened,
      hasPortfolios,
      addIsaRef,
      addPortfolioRef,
      addSippRef,
    ],
  )

  const renderListOf = useCallback(
    (regulatoryType, title) => {
      const list = groupedPortfolios[regulatoryType]

      return (
        <div style={{ position: 'relative' }} ref={groupRefs[regulatoryType]}>
          {title}
          {list?.length > 0 && (
            <Paper top={24}>
              {list.map((portfolio, index) => (
                <Paper
                  key={portfolio.skeleton ? index : portfolio.id}
                  top={index > 0 ? 24 : null}
                  data-test-id="portfolioCard"
                >
                  <DashboardContextMenu isEnabled={!isPortfoliosSkeletons} portfolio={portfolio}>
                    <PortfolioSelectableCard
                      isBusiness={isBusiness}
                      portfolio={portfolio}
                      data-test-id={`portfolioCard${index}`}
                      highlightedRef={
                        portfolio.manage_type === 'CASH'
                          ? regulatoryType === regulatoryTypes.ISA
                            ? isaCashPortfolioRef
                            : cashPortfolioRef
                          : null
                      }
                      transferProgressData={transferProgressCollection.get(portfolio.id)}
                    />
                  </DashboardContextMenu>
                </Paper>
              ))}
            </Paper>
          )}
          {renderListBottom(regulatoryType)}
        </div>
      )
    },
    [
      groupedPortfolios,
      isBusiness,
      transferProgressCollection,
      renderListBottom,
      cashPortfolioRef,
      isaCashPortfolioRef,
      isPortfoliosSkeletons,
      groupRefs,
    ],
  )

  const isa = useMemo(
    () =>
      canOpenIsa
        ? renderListOf(
            regulatoryTypes.ISA,
            <IsaTitle
              remainingAllowance={remainingIsaAllowance}
              isIsaAccountOpened={isIsaAccountOpened}
              skeleton={isPortfoliosSkeletons}
              title={
                <DashboardContextMenu
                  isEnabled={!isPortfoliosSkeletons}
                  regulatoryTypeGroup={regulatoryTypes.ISA}
                  offsetTop={-4}
                  groupRefs={groupRefs}
                >
                  <Typo>ISA</Typo>
                </DashboardContextMenu>
              }
            />,
          )
        : null,
    [renderListOf, remainingIsaAllowance, isIsaAccountOpened, canOpenIsa, isPortfoliosSkeletons, groupRefs],
  )

  const gia = useMemo(
    () =>
      renderListOf(
        regulatoryTypes.GIA,
        isBusiness ? null : (
          <GiaTitle
            skeleton={isPortfoliosSkeletons}
            title={
              <DashboardContextMenu
                isEnabled={!isPortfoliosSkeletons}
                regulatoryTypeGroup={regulatoryTypes.GIA}
                offsetTop={-4}
                groupRefs={groupRefs}
              >
                <Typo>General</Typo>
              </DashboardContextMenu>
            }
          />
        ),
      ),
    [renderListOf, isBusiness, isPortfoliosSkeletons, groupRefs],
  )

  const sipp = useMemo(
    () =>
      canOpenSipp
        ? renderListOf(
            regulatoryTypes.SIPP,
            <SippTitle
              remainingAllowance={totalSippAllowance}
              isSippAccountOpened={isSippAccountOpened}
              skeleton={isPortfoliosSkeletons}
              title={
                <DashboardContextMenu
                  isEnabled={!isPortfoliosSkeletons}
                  regulatoryTypeGroup={regulatoryTypes.SIPP}
                  offsetTop={-4}
                  groupRefs={groupRefs}
                >
                  <Typo>Personal Pension</Typo>
                </DashboardContextMenu>
              }
            />,
          )
        : null,
    [renderListOf, canOpenSipp, isPortfoliosSkeletons, totalSippAllowance, isSippAccountOpened, groupRefs],
  )

  const isaNode = useMemo(
    () =>
      isa && (
        <Paper top={48} data-test-id="isaPortfolios">
          {isa}
        </Paper>
      ),
    [isa],
  )

  const giaNode = useMemo(
    () =>
      gia && (
        <Paper top={48} data-test-id="giaPortfolios">
          {gia}
        </Paper>
      ),
    [gia],
  )

  const sippNode = useMemo(
    () =>
      sipp && (
        <Paper top={48} data-test-id="sippPortfolios">
          {sipp}
        </Paper>
      ),
    [sipp],
  )

  const regulatoryTypesNodes = useMemo(
    () => ({
      [regulatoryTypes.ISA]: isaNode,
      [regulatoryTypes.GIA]: giaNode,
      [regulatoryTypes.SIPP]: sippNode,
    }),
    [giaNode, isaNode, sippNode],
  )

  const portfolios = useMemo(
    () =>
      regulatoryTypesOrder.map((regulatoryType) => (
        <React.Fragment key={regulatoryType}>{regulatoryTypesNodes[regulatoryType]}</React.Fragment>
      )),
    [regulatoryTypesNodes, regulatoryTypesOrder],
  )

  const promoBanner = useMemo(
    () =>
      isPromoBannerDisplayed && (
        <ErrorBoundary fallback={null}>
          <Paper top={48}>
            <PromoBanner />
          </Paper>
        </ErrorBoundary>
      ),
    [isPromoBannerDisplayed],
  )

  const content = useMemo(
    () => (
      <Width size={36.75} center>
        <Paper bottom={48}>
          <DashboardStories />
        </Paper>

        <Performance createPortfolioUrl={createPortfolioUrl} showCreatePortfolioButton={hasPortfolios} />
        {client.state !== clientStates.NEW && (
          <NotificationGroup scope={NotificationScopeType.CLIENT}>
            {(nodes) => <Paper top={32}>{nodes}</Paper>}
          </NotificationGroup>
        )}
        {portfolios}
        {promoBanner}
      </Width>
    ),
    [client.state, createPortfolioUrl, portfolios, promoBanner, hasPortfolios],
  )

  const footer = useMemo(() => <DesktopFooter />, [])

  return <DesktopLayout header={header} content={content} footer={footer} />
}

Desktop.propTypes = {
  client: PropTypes.shape({ state: PropTypes.string }),
  groupedPortfolios: PropTypes.shape({
    [regulatoryTypes.ISA]: propTypes.instanceOf(PortfolioList),
    [regulatoryTypes.GIA]: propTypes.instanceOf(PortfolioList),
    [regulatoryTypes.SIPP]: propTypes.instanceOf(PortfolioList),
  }),
  remainingIsaAllowance: PropTypes.number,
  totalSippAllowance: PropTypes.number,
  canOpenIsa: PropTypes.bool,
  canOpenSipp: PropTypes.bool,
  isIsaAccountOpened: PropTypes.bool,
  isSippAccountOpened: PropTypes.bool,
  hasPortfolios: PropTypes.bool,
  isPortfoliosSkeletons: PropTypes.bool,
  isBusiness: PropTypes.bool,
  createPortfolioUrl: PropTypes.string,
  regulatoryTypesOrder: PropTypes.arrayOf(PropTypes.string),
}

export { Desktop }
