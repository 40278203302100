// extra legacy to deal with
/* eslint-disable react/prop-types */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import keyBy from 'lodash/keyBy'

import compose from 'helpers/compose.js'
import { goTo, urlTo } from 'helpers/router.js'
import rawMediaQueries from 'helpers/mediaQueries.js'
import { sanitizeString } from 'helpers/typograph'

import withMediaQueries from 'decorators/withMediaQueries/withMediaQueries.jsx'
import Modal from 'components/_old/Modal/Modal.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Width from 'components/_old/Width/Width'
import Button from 'components/_old/Button/Button.jsx'
import InlineHelp from 'components/_old/InlineHelp/InlineHelp.jsx'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'
import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'

import { changeField, updateOrCreate } from 'app/redux/actions/client'
import { fetchFlatPageSection, resetError, showFailToast } from 'app/redux/actions/ui'

class ChangesToTheAgreement extends React.Component {
  state = {
    agreed_with_terms: parseInt(this.props.agreed_with_terms, 10),
    agreed_with_risk_disclosure: parseInt(this.props.agreed_with_risk_disclosure, 10),
    showAlert: false,
  }

  getAgreedFinally = () => {
    const {
      versions = {
        terms: null,
        risk_disclosure: null,
      },
      agreed_with_terms,
      agreed_with_risk_disclosure,
    } = this.props

    return (
      agreed_with_terms === parseInt(versions.terms || {}, 10) &&
      agreed_with_risk_disclosure === parseInt(versions.risk_disclosure || {}, 10)
    )
  }

  toggleAlert = (visible = false) => {
    this.setState({ showAlert: visible })
  }

  handleShowAlert = () => {
    this.toggleAlert(!this.getAgreedFinally())
  }

  handleChange = (agreed) => {
    const { changeField } = this.props

    if (agreed) {
      const { versions = {} } = this.props
      const state = {
        agreed_with_terms: parseInt(versions.terms, 10),
        agreed_with_risk_disclosure: parseInt(versions.risk_disclosure, 10),
      }
      changeField(state)
    } else {
      const { agreed_with_terms, agreed_with_risk_disclosure } = this.state
      const state = {
        agreed_with_terms: parseInt(agreed_with_terms, 10),
        agreed_with_risk_disclosure: parseInt(agreed_with_risk_disclosure, 10),
      }
      changeField(state)
    }

    this.toggleAlert(!agreed)
  }

  handleSubmit = () => {
    const { updateOrCreate } = this.props
    updateOrCreate(['agreed_with_terms', 'agreed_with_risk_disclosure']).then((nextState) => {
      if (!nextState.client.error) {
        goTo(urlTo('dashboard'), { replace: true, scrollToTop: false })
      } else {
        showFailToast()
      }
    })
  }

  handleDownloadOnboardingTexts(props) {
    const { agreed_with_terms, agreed_with_risk_disclosure, fetchFlatPageSection } = props

    fetchFlatPageSection('onboarding_texts')

    this.setState({
      agreed_with_terms: agreed_with_terms,
      agreed_with_risk_disclosure: agreed_with_risk_disclosure,
    })
  }

  componentDidMount() {
    if (this.visible) {
      this.handleDownloadOnboardingTexts(this.props)
    }
  }

  UNSAFE_componentWillUpdate(newProps) {
    if (newProps.visible && !this.props.visible) {
      this.handleDownloadOnboardingTexts(newProps)
    }
  }

  render() {
    const { visible, mediaQueries, versions = {}, pages } = this.props

    const stateTerms = this.state.agreed_with_terms
    const stateRisk = this.state.agreed_with_risk_disclosure

    const { desktop } = mediaQueries
    const agreed = this.getAgreedFinally()

    return (
      <Modal open={visible} noCross gatewayName="changes-to-the-agreement">
        <Card
          mods={{
            theme: 'transparent',
            padding: 'big',
            'no-padding': desktop ? null : 'all',
          }}
        >
          <Text center block>
            <Headline level={desktop ? 1 : 2} mods={{ 'no-margin': 'top' }}>
              Please review the following changes
            </Headline>
          </Text>
          <Card mods={{ theme: 'transparent', 'no-padding': desktop ? 'top right left' : 'right left' }}>
            <Text className={desktop ? 'Text_center' : 'Text_left'} block>
              We’ve made some changes to our
              {stateTerms < parseInt(versions.terms, 10) ? (
                <InlineHelp insideGatewayName="inside-terms-and-risk" helper={<Link> terms and&nbsp;conditions </Link>}>
                  {(close) => {
                    return pages.terms ? (
                      <Width size={36}>
                        <Card
                          mods={{
                            theme: 'transparent',
                            'no-padding': 'top right left',
                          }}
                        >
                          <Width size={34} center>
                            <Text className={desktop ? 'Text_center' : 'Text_padding-right_2'} block>
                              <Headline
                                level={desktop ? 1 : 2}
                                mods={desktop ? { 'no-margin': 'top' } : { 'inverse-margin': 'top' }}
                              >
                                {pages.terms.title}
                              </Headline>
                            </Text>
                            <Text small block>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: sanitizeString(pages.terms.content),
                                }}
                              />
                            </Text>
                          </Width>
                        </Card>
                        <StickedIfNotDesktop into="inside-terms-and-risk">
                          {(() => {
                            const button = (
                              <Button
                                onClick={close}
                                mods={{ size: 'big block', theme: desktop ? null : 'not-rounded' }}
                              >
                                OK
                              </Button>
                            )

                            return desktop ? (
                              <Width size={desktop ? 10 : null} center>
                                {button}
                              </Width>
                            ) : (
                              button
                            )
                          })()}
                        </StickedIfNotDesktop>
                      </Width>
                    ) : null
                  }}
                </InlineHelp>
              ) : null}
              {stateTerms < parseInt(versions.terms, 10) && stateRisk < parseInt(versions.risk_disclosure, 10) ? (
                <span>and</span>
              ) : null}
              {stateRisk < parseInt(versions.risk_disclosure, 10) ? (
                <InlineHelp insideGatewayName="inside-terms-and-risk" helper={<Link> risk disclosure </Link>}>
                  {(close) => {
                    return pages['risk-disclosure'] ? (
                      <Width size={36}>
                        <Card
                          mods={{
                            theme: 'transparent',
                            'no-padding': 'top right left',
                          }}
                        >
                          <Width size={34} center>
                            <Text className={desktop ? 'Text_center' : 'Text_padding-right_2'} block>
                              <Headline
                                level={desktop ? 1 : 2}
                                mods={desktop ? { 'no-margin': 'top' } : { 'inverse-margin': 'top' }}
                              >
                                {pages['risk-disclosure'].title}
                              </Headline>
                            </Text>
                            <Text small block>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: sanitizeString(pages['risk-disclosure'].content),
                                }}
                              />
                            </Text>
                          </Width>
                        </Card>
                        <StickedIfNotDesktop into="inside-terms-and-risk">
                          {(() => {
                            const button = (
                              <Button
                                onClick={close}
                                mods={{ size: 'big block', theme: desktop ? null : 'not-rounded' }}
                              >
                                OK
                              </Button>
                            )

                            return desktop ? (
                              <Width size={desktop ? 10 : null} center>
                                {button}
                              </Width>
                            ) : (
                              button
                            )
                          })()}
                        </StickedIfNotDesktop>
                      </Width>
                    ) : null
                  }}
                </InlineHelp>
              ) : null}
              statement.
              <br />
              To review them, please follow the link.
            </Text>
          </Card>
          <Card
            mods={{
              theme: 'transparent',
              padding: desktop ? 'big' : null,
              'no-padding': desktop ? 'top right left' : 'right left',
            }}
          >
            <Text className={desktop ? 'Text_center' : 'Text_left'} block>
              <CheckboxWithLabel
                label={
                  <Fragment>
                    I have read and agree with&nbsp;
                    {stateTerms < parseInt(versions.terms, 10) ? <span>terms and conditions</span> : null}
                    {stateTerms < parseInt(versions.terms, 10) && stateRisk < parseInt(versions.risk_disclosure, 10) ? (
                      <span>&nbsp;and&nbsp;</span>
                    ) : null}
                    {stateRisk < parseInt(versions.risk_disclosure, 10) ? <span>risk disclosure statement</span> : null}
                  </Fragment>
                }
                checked={agreed}
                onChange={this.handleChange}
                data-test-id="changesToTheAgreementInput"
              />
            </Text>
          </Card>

          <Width size={desktop ? 20 : null} center>
            <StickedIfNotDesktop into="insideModal">
              <div onClick={this.handleShowAlert} data-test-id="changesToTheAgreementButtonContinueWrapper">
                {this.state.showAlert && (
                  <Card
                    mods={{
                      theme: 'transparent',
                      'no-padding': desktop ? 'top' : null,
                      padding: 'small',
                    }}
                    data-test-id="changesToTheAgreementAlert"
                    style={{ paddingRight: desktop ? 'inherit' : '3.5em' }}
                  >
                    <Text color="red" block small center>
                      You have to agree with Terms & Conditions before continuing
                    </Text>
                  </Card>
                )}
                <Button
                  mods={{
                    size: 'big block',
                    theme: desktop ? null : 'not-rounded',
                  }}
                  block
                  type="submit"
                  disabled={!agreed}
                  onClick={this.handleSubmit}
                  data-test-id="changesToTheAgreementButtonContinue"
                >
                  Continue
                </Button>
              </div>
            </StickedIfNotDesktop>
          </Width>
        </Card>
      </Modal>
    )
  }
}

export default compose(
  withMediaQueries(rawMediaQueries),
  connect(
    (state) => {
      return {
        agreed_with_terms: state.client.agreed_with_terms,
        agreed_with_risk_disclosure: state.client.agreed_with_risk_disclosure,
        onboarding_texts: state.ui.flatpage_sections.onboarding_texts || [],
      }
    },
    {
      changeField,
      updateOrCreate,
      fetchFlatPageSection,
      resetError,
    },
    (stateProps, dispatchProps, ownProps) => {
      const { onboarding_texts, ...restState } = stateProps
      const finPop = onboarding_texts.find((t) => t.slug === 'onboarding_finances_popups')
      let pages = {}

      if (finPop) {
        pages = keyBy(
          finPop.pages.map((p) => ({ ...p, url: p.url.replace(/\//g, '') })),
          'url',
        )
      }
      return { pages, ...restState, ...dispatchProps, ...ownProps }
    },
  ),
)(ChangesToTheAgreement)
