import { Entity } from 'app/redux/models/common/Entity.js'

import { PortfolioStatsItem } from './PortfolioStatsItem.js'

/**
 * @class
 * @typedef {Object} PortfolioStatsCollection
 */
export class PortfolioStatsCollection extends Entity {
  constructor() {
    super()

    /** @type {PortfolioStatsItem} */
    this.week = PortfolioStatsItem.createFromObject({})
    /** @type {PortfolioStatsItem} */
    this.month = PortfolioStatsItem.createFromObject({})
    /** @type {PortfolioStatsItem} */
    this.six_months = PortfolioStatsItem.createFromObject({})
    /** @type {PortfolioStatsItem} */
    this.year = PortfolioStatsItem.createFromObject({})
    /** @type {PortfolioStatsItem} */
    this.max = PortfolioStatsItem.createFromObject({})
  }

  /** @override */
  getRules() {
    return {
      week: [[(item) => PortfolioStatsItem.createFromObject(item)]],
      month: [[(item) => PortfolioStatsItem.createFromObject(item)]],
      six_months: [[(item) => PortfolioStatsItem.createFromObject(item)]],
      year: [[(item) => PortfolioStatsItem.createFromObject(item)]],
      max: [[(item) => PortfolioStatsItem.createFromObject(item)]],
    }
  }
}
