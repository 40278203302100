import * as React from 'react'
import { type ReactElement } from 'react'

import { useMediaQueries, useEffect } from 'hooks'

import { Modal } from 'components/molecules/Modal'

import { Desktop } from './Desktop/Desktop'
import { Mobile } from './Mobile/Mobile'

type ReviewAnalyticsModalProps = {
  open: boolean
  isPortfolioNew: boolean
  analytics: object
  handleBack: () => void
  handleCancel: () => void
  fetchAnalyticsByWeights: () => Promise<boolean>
}

const ReviewAnalyticsModal = ({
  open = false,
  fetchAnalyticsByWeights,
  ...props
}: ReviewAnalyticsModalProps): ReactElement => {
  const { desktop } = useMediaQueries()

  useEffect(() => {
    if (open) {
      fetchAnalyticsByWeights()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Modal open={open} close={null} animation={null} onClose={props.handleBack}>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}
    </Modal>
  )
}

export { ReviewAnalyticsModal }
