import React from 'react'

import { deletePortfolioRoutes } from '../DeletePortfolio/routes'
import { routes as cashBreakdownRoutes } from '../DIY/CashBreakdown/routes'
import { routes as monthlyPaymentRoutes } from '../MonthlyPayment/routes'
import { routes as moveCashRoutes } from '../MoveCash/routes'
import { routes as recurringPaymentRoutes } from '../RecurringPayment/routes'

import { Options } from './Options'

const routes = [
  {
    module: 'options',
    path: 'options/',
    component: function Component(props) {
      return <Options {...props} />
    },
    childRoutes: [
      ...monthlyPaymentRoutes,
      ...recurringPaymentRoutes,
      ...deletePortfolioRoutes,
      ...cashBreakdownRoutes,
      ...moveCashRoutes,
    ],
  },
]

export default routes
