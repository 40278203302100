import { useUnit } from 'effector-react'

import { useCallback, useEffect, useMemo, useRef, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { format as formatMoney } from 'helpers/money'
import { validate } from 'helpers/validation.js'

import { $bankAccountsStore } from 'app/effector/bank-accounts'

import { frequencies } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'
import type { Frequency } from 'app/pages/Dashboard/Goals/RecurringPayment/types'

const steps: Record<string, SavingsPlanStep> = {
  FREQUENCY: 'FREQUENCY',
  START_DAY: 'START_DAY',
  DAY_OF_MONTH: 'DAY_OF_MONTH',
  AMOUNT: 'AMOUNT',
  REGULATORY_TYPE: 'REGULATORY_TYPE',
}

type SavingsPlanStep = 'FREQUENCY' | 'START_DAY' | 'DAY_OF_MONTH' | 'AMOUNT' | 'REGULATORY_TYPE'

type UseSavingsPlanSetupProps = {
  isOpen: boolean
  needToChooseRegulatoryType: boolean
  analyticsData: Record<string, unknown>
  handleContinue: (data: {
    amount: string | null
    frequency: Frequency | null
    startDay: string | null
    monthlyPaymentDay: number | null
    regulatoryType?: string
  }) => Promise<void>
  handleGoBack: () => void
}

type UseSavingsPlanSetupReturnProps = {
  step: SavingsPlanStep
  frequency: Frequency | null
  startDay: string | null
  monthlyPaymentDay: number | null
  monthlyPaymentDayValidation: {
    rules: boolean[]
    errors: string[]
  }
  amount: string | null
  amountValidation: {
    rules: boolean[]
    errors: string[]
  }
  monthlyPaymentDayInputRef: React.RefObject<Element>
  amountInputRef: React.RefObject<Element>
  handleBack: () => void
  handleClose: () => void
  handleSelectFrequency: (frequency: Frequency) => void
  handleSelectStartDay: (startDay: string) => void
  handleInputMonthlyPaymentDay: (event, value: string) => void
  handleMonthlyPaymentDayContinue: () => void
  handleInputAmount: (event, value: string) => void
  handleAmountContinue: () => void
  handleSubmitRegulatoryType: (regulatoryType: string) => void
}

const WEEKLY_PAYMENT_MIN_AMOUNT = 20
const MONTHLY_PAYMENT_MIN_AMOUNT = 50

const useSavingsPlanSetup = ({
  isOpen,
  needToChooseRegulatoryType,
  analyticsData = {},
  handleContinue,
  handleGoBack,
}: UseSavingsPlanSetupProps): UseSavingsPlanSetupReturnProps => {
  const { nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)
  const isFrequencyAvailable = nominatedBankAccount?.id ? nominatedBankAccount?.bank?.recurring_payment_support : true

  const [step, setStep] = useState<SavingsPlanStep>(isFrequencyAvailable ? steps.FREQUENCY : steps.DAY_OF_MONTH)
  const [frequency, setFrequency] = useState<Frequency | null>(!isFrequencyAvailable ? frequencies.MONTHLY : null)
  const [startDay, setStartDay] = useState<string | null>(null)
  const [monthlyPaymentDay, setMonthlyPaymentDay] = useState<number | null>(null)
  const [amount, setAmount] = useState<string | null>(null)

  const monthlyPaymentDayInputRef = useRef()
  const amountInputRef = useRef()

  useEffect(() => {
    if (!isOpen) {
      setStep(isFrequencyAvailable ? steps.FREQUENCY : steps.DAY_OF_MONTH)
      setFrequency(!isFrequencyAvailable ? frequencies.MONTHLY : null)
      setStartDay(null)
      setMonthlyPaymentDay(null)
      setAmount(null)
    }
  }, [isOpen, isFrequencyAvailable])

  const monthlyPaymentDayValidation = useMemo(
    () => ({
      rules: [!!monthlyPaymentDay && monthlyPaymentDay >= 1 && monthlyPaymentDay <= 28],
      errors: ['Must be 1 to 28'],
    }),
    [monthlyPaymentDay],
  )

  const minAmount = frequency === frequencies.MONTHLY ? MONTHLY_PAYMENT_MIN_AMOUNT : WEEKLY_PAYMENT_MIN_AMOUNT

  const amountValidation = useMemo(
    () => ({
      rules: [parseFloat(amount ?? '0') >= minAmount],
      errors: [`Must be at least ${formatMoney(minAmount)}`],
    }),
    [amount, minAmount],
  )

  const handleSelectFrequency = useCallback(
    (frequency: Frequency) => {
      trackEvent({
        action: 'sp_promoflow_frequency_choosed',
        frequency: frequency?.toString() ?? 'Unable to get frequency',
        ...(analyticsData || {}),
      })
      setFrequency(frequency)
      setStep(frequency === frequencies.MONTHLY ? steps.DAY_OF_MONTH : steps.START_DAY)
    },
    [analyticsData],
  )

  const handleSelectStartDay = useCallback(
    (startDay: string) => {
      trackEvent({
        action: 'sp_promoflow_day_choosed',
        day: startDay?.toString() ?? 'Unable to get day',
        frequency: frequency?.toString() ?? 'Unable to get frequency',
        ...(analyticsData || {}),
      })
      setStartDay(startDay)
      setStep(steps.AMOUNT)
    },
    [analyticsData, frequency],
  )

  const handleInputMonthlyPaymentDay = useCallback((event, value: string) => {
    setMonthlyPaymentDay(parseFloat(value))
  }, [])

  const handleMonthlyPaymentDayContinue = useCallback(() => {
    if (!validate(monthlyPaymentDayValidation.rules)) {
      monthlyPaymentDayInputRef.current?.focus()
      monthlyPaymentDayInputRef.current?.blur()
      return
    }

    trackEvent({
      action: 'sp_promoflow_day_choosed',
      day: monthlyPaymentDay?.toString() ?? 'Unable to get day',
      frequency: frequency?.toString() ?? 'Unable to get frequency',
      ...(analyticsData || {}),
    })

    setStep(steps.AMOUNT)
  }, [analyticsData, monthlyPaymentDayValidation, monthlyPaymentDay, frequency])

  const handleInputAmount = useCallback((event, value: string) => {
    setAmount(value)
  }, [])

  const handleAmountContinue = useCallback(() => {
    if (!validate(amountValidation.rules)) {
      amountInputRef.current?.focus()
      amountInputRef.current?.blur()
      return
    }

    trackEvent({
      action: 'sp_promoflow_amount_submitted',
      amount: amount?.toString() ?? 'Unable to get amount',
      day: (startDay ?? monthlyPaymentDay)?.toString() ?? 'Unable to get day',
      frequency: frequency?.toString() ?? 'Unable to get frequency',
      ...(analyticsData || {}),
    })

    if (needToChooseRegulatoryType) {
      setStep(steps.REGULATORY_TYPE)
      return
    }

    handleContinue({ amount, frequency, startDay, monthlyPaymentDay })
  }, [
    analyticsData,
    amountValidation,
    handleContinue,
    needToChooseRegulatoryType,
    amount,
    frequency,
    startDay,
    monthlyPaymentDay,
  ])

  const handleSubmitRegulatoryType = useCallback(
    (regulatoryType) => {
      handleContinue({ amount, frequency, startDay, monthlyPaymentDay, regulatoryType })
    },
    [handleContinue, amount, frequency, startDay, monthlyPaymentDay],
  )

  const handleBack = useCallback(() => {
    if (step === steps.AMOUNT) {
      setAmount(null)
      setStep(frequency === frequencies.MONTHLY ? steps.DAY_OF_MONTH : steps.START_DAY)
      return
    }

    if (step === steps.DAY_OF_MONTH) {
      if (!isFrequencyAvailable) {
        handleGoBack()
        return
      }

      setMonthlyPaymentDay(null)
      setStep(steps.FREQUENCY)
      return
    }

    if (step === steps.START_DAY) {
      setStep(steps.FREQUENCY)
      return
    }

    handleGoBack()
  }, [step, frequency, handleGoBack, isFrequencyAvailable])

  return {
    step,
    frequency,
    startDay,
    monthlyPaymentDay,
    monthlyPaymentDayValidation,
    monthlyPaymentDayInputRef,
    amount,
    amountValidation,
    amountInputRef,
    handleBack,
    handleSelectFrequency,
    handleSelectStartDay,
    handleInputMonthlyPaymentDay,
    handleMonthlyPaymentDayContinue,
    handleInputAmount,
    handleAmountContinue,
    handleSubmitRegulatoryType,
  }
}

export { useSavingsPlanSetup, steps, type SavingsPlanStep }
