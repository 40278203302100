import { createEffect, createEvent, createStore } from 'effector'

import { makeApiCall } from 'app/effector/api'

import * as api from './api'
import { SippTransferRequest } from './models'

// events
type SetFieldPayload<Field extends keyof SippTransferRequest> = {
  field: Field
  value?: SippTransferRequest[Field]
}
const resetSippTransferRequest = createEvent()
const setFieldForSippTransferRequest = createEvent<SetFieldPayload<keyof SippTransferRequest>>()
const setBulkFieldsForSippTransferRequest = createEvent<Partial<SippTransferRequest>>()

// effects
const createSippTransferRequestFx = createEffect(
  async (request: SippTransferRequest) => await makeApiCall(api.createSippTransferRequest, request),
)

// store
const $sippTransferRequest = createStore<SippTransferRequest>(new SippTransferRequest())
$sippTransferRequest
  // reset the store on resetSippTransferRequest event
  .reset(resetSippTransferRequest)
  // on successful creation of the request we need to reset the store
  .reset(createSippTransferRequestFx.done)
  // handle setField event
  .on(
    setFieldForSippTransferRequest,
    (state, { field, value }) => new SippTransferRequest({ ...state, [field]: value }),
  )
  // handle bulk fields update
  .on(setBulkFieldsForSippTransferRequest, (state, fields) => state.merge(fields))

export * from './api'
export * from './models'
export {
  type SetFieldPayload,
  resetSippTransferRequest,
  setFieldForSippTransferRequest,
  setBulkFieldsForSippTransferRequest,
  createSippTransferRequestFx,
  $sippTransferRequest,
}
