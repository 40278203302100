import React from 'react'

import { useState, useRef, useCallback, useMemo, useEffect } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'

import { Paper } from 'components/atoms/Paper'

import { DateRangeInputs, type DateRangeInputsHandle } from 'components/molecules/DateRangeInputs'

import { type CreateReportFormPartProps } from '../CreateReportForm'

function SelectCustomTimePeriod({
  className,
  contentClassName,
  buttonClassName,
  visible,
  buttonTitle,
  desktop,
  selectedCustomDateFrom,
  setSelectedCustomDateFrom,
  selectedCustomDateTo,
  setSelectedCustomDateTo,
  handleSelectTimePeriod,
}: CreateReportFormPartProps): React.ReactElement {
  const [isValid, setIsValid] = useState(Boolean(selectedCustomDateFrom) && Boolean(selectedCustomDateTo))
  const dateRangeInputsRef = useRef<DateRangeInputsHandle>()

  const handleDate = useCallback(
    (setSelected: (dateFrom: Date) => void) =>
      (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSelected(new Date(event.target.value))
      },
    [],
  )
  const handleDateFrom = useMemo(() => handleDate(setSelectedCustomDateFrom), [handleDate, setSelectedCustomDateFrom])
  const handleDateTo = useMemo(() => handleDate(setSelectedCustomDateTo), [handleDate, setSelectedCustomDateTo])

  useEffect(() => {
    if (dateRangeInputsRef.current?.getIsValid) {
      // next tick after render
      setTimeout(() => {
        const isValid = !!dateRangeInputsRef.current?.getIsValid()

        setIsValid(isValid)
      }, 0)
    } else {
      setIsValid(Boolean(selectedCustomDateFrom) && Boolean(selectedCustomDateTo))
    }
  }, [selectedCustomDateFrom, selectedCustomDateTo])

  return (
    <div className={className} hidden={!visible}>
      <div className={contentClassName}>
        <DateRangeInputs
          key={visible.toString() /* force remount on visible to properly handle focus and validation errors */}
          ref={dateRangeInputsRef}
          dateFrom={selectedCustomDateFrom}
          handleDateFrom={handleDateFrom}
          dateTo={selectedCustomDateTo}
          handleDateTo={handleDateTo}
        />
      </div>
      <Paper className={buttonClassName} top={desktop ? 24 : 16} bottom={desktop ? 56 : null}>
        <Button onClick={handleSelectTimePeriod} mods={{ size: desktop ? 'block' : 'big block' }} disabled={!isValid}>
          {buttonTitle}
        </Button>
      </Paper>
    </div>
  )
}

export { SelectCustomTimePeriod }
