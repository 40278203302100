import { useUnit } from 'effector-react'
import isEmpty from 'lodash/isEmpty'

import { useActions, useEffect, useMemo, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router'

import { $isLoading } from 'app/effector/contacts'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'
import { fetchFlatPageSection as fetchFlatPageSectionActionCreator } from 'app/redux/actions/ui'
import { isClientNotCompleted, selectIsSomethingLoading } from 'app/redux/selectors'

import type { StepProviderStep } from 'app/containers/PortfolioTunnel'

import { getSetupAccountSteps } from 'app/pages/Dashboard/SetupAccount/getSetupAccountSteps'

import { states as clientStates, types as clientTypes } from 'constants/client'

type UseSetupAccountRoute = {
  [key: string]: unknown
  module: string
  childRoutes?: UseSetupAccountRoute[]
}

type UseSetupAccountProps = {
  routes: UseSetupAccountRoute[]
  route: UseSetupAccountRoute
  location: {
    query: Record<string, string>
  }
}

type UseSetupAccountInterface = {
  isLoading: boolean
  steps: StepProviderStep[]
}

const useSetupAccount = ({ routes, route, location }: UseSetupAccountProps): UseSetupAccountInterface => {
  const isLoading = useSelector(selectIsSomethingLoading)
  const isContactsLoading = useUnit($isLoading)
  const client = useSelector((state: { client: { [key: string]: unknown; type: string } }) => state.client)
  const goals = useSelector((state: { portfolios: { [key: string]: unknown; items: unknown[] } }) => state.portfolios)
  const texts = useSelector(
    (state: { ui: { flatpage_sections: { onboarding_texts: unknown[] } } }) =>
      state.ui.flatpage_sections.onboarding_texts || [],
  )

  const registrationRoute = routes.reverse().find((route) => ['individual', 'business'].includes(route.module))

  useEffect(() => {
    if (client.type) {
      if (isClientNotCompleted({ client })) {
        const module = client.type === clientTypes.INDIVIDUAL ? 'individual' : 'business'
        const correctRoute = Boolean(registrationRoute) && registrationRoute?.module === module

        if (!correctRoute) {
          trackEvent({
            category: 'Registration',
            action: 'Registration started',
            label: client.type,
          })

          goTo(urlTo(`dashboard.setup-account.${module}`, {}, location.query), { replace: true })
        }
      } else {
        goTo(urlTo('dashboard'), { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, client.type, registrationRoute])

  const [fetchPortfolios, fetchFlatPageSection] = useActions([
    fetchPortfoliosActionCreator,
    fetchFlatPageSectionActionCreator,
  ])

  const setupAccountRoute = useMemo(
    () => (client.type === clientTypes.INDIVIDUAL ? route.childRoutes?.[0] : route.childRoutes?.[1]),
    [client.type, route],
  )

  const steps = useMemo(
    () => getSetupAccountSteps(setupAccountRoute.module, setupAccountRoute.childRoutes),
    [setupAccountRoute],
  )

  useEffect(() => {
    if (client.state === clientStates.NEW) {
      const nextModule = client.type ? 'create-account' : 'product-type'
      const isOpeningSipp = location?.query?.isOpeningSipp === 'true'
      const back = location?.query?.back ?? urlTo('dashboard')

      goTo(
        urlTo(
          nextModule,
          {},
          {
            mode: 'DASHBOARD',
            back,
            next: urlTo(
              'setup-account',
              null,
              isOpeningSipp
                ? {
                    isOpeningSipp: true,
                    back,
                    next: urlTo('sipp-declaration', null, { back }),
                  }
                : null,
            ),
          },
        ),
        { replace: true },
      )
      return
    }

    if (isEmpty(goals.items)) {
      fetchPortfolios()
    }

    if (isEmpty(texts)) {
      fetchFlatPageSection('onboarding_texts')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoading: isLoading || isContactsLoading,
    steps,
  }
}

export { useSetupAccount, type UseSetupAccountRoute, type UseSetupAccountProps, type UseSetupAccountInterface }
