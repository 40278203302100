import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useMemo, useSelector, useState, useUnmount } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { sendError } from 'helpers/errorLogging.js'
import { ValidationError } from 'helpers/errors'
import { features } from 'helpers/features'
import { Impact } from 'helpers/impact.js'
import { navigateToIsaTransferFlowByClient } from 'helpers/navigateToIsaTransferFlowByClient'
import { goTo, urlTo } from 'helpers/router.js'
import { backendErrorsToObj, combineErrors, emailRules, passwordRules } from 'helpers/validation.js'

import { fetchContactsFx } from 'app/effector/contacts'
import { $dictsStore } from 'app/effector/dicts'

import {
  changeField as changeFieldActionCreator,
  resetError as resetErrorActionCreator,
  updateOrCreate as updateOrCreateActionCreator,
} from 'app/redux/actions/client'
import {
  changeNotificationSettings as changeNotificationSettingsActionCreator,
  showFailToast,
} from 'app/redux/actions/ui'

import {
  $isValid,
  $form as $isaTransferPrefilledData,
  enableForceValidation,
  init as initIsaTransferPrefillForm,
} from 'app/pages/CreateAccount/components/IsaTransferPrefillForm/model'

import { types as clientTypes } from 'constants/client'

const trackSignUpEvents = (client) => {
  trackEvent({
    category: 'Get started',
    action: 'Signed up',
    label: client.type,
  })
  window.ttq.track('SubmitForm')

  trackEvent({
    action: 'signed_up',
    client_type: client.type,
  })
}

export const useCreateAccount = ({ query = {} }) => {
  const { mode, back, next } = query ?? {}

  const [isAgreeWithPrivacyPolicy, setIsAgreeWithPrivacyPolicy] = useState(false)
  const [isAgreeWithNotifications, setIsAgreeWithNotifications] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [initialAgreements, setInitialAgreements] = useState({})

  const [
    isIsaPrefillFormValid,
    forceValidateIsaPrefillForm,
    handleInitIsaTransferPrefillForm,
    isaTransferPrefilledData,
  ] = useUnit([$isValid, enableForceValidation, initIsaTransferPrefillForm, $isaTransferPrefilledData])

  const { dicts } = useUnit($dictsStore)
  const { privacy_policy: privacyPolicy } = dicts?.versions ?? {}
  const client = useSelector((state) => state.client)
  const { email, error, password, agreed_with_privacy_policy: agreedWithPrivacyPolicy, type } = client

  // Create Account form is split into steps: email, password, isa-transfer-form
  const [step, setStep] = useState('email')
  const isEmailStep = useMemo(() => step === 'email', [step])
  const isPasswordStep = useMemo(() => step === 'password', [step])
  const isIsaTransferStep = useMemo(() => step === 'isa-transfer-form', [step])

  const shouldShowIsaTransfer = useMemo(() => {
    return features.get('isa-transfer-panel') && query.IsaTransfer === 'true' && type === clientTypes.INDIVIDUAL
  }, [query.IsaTransfer, type])

  const moveToPasswordStep = useCallback(() => {
    setStep('password')
  }, [setStep])

  const moveToEmailStep = useCallback(() => {
    setStep('email')
  }, [setStep])

  const moveToIsaTransferStep = useCallback(() => {
    setStep('isa-transfer-form')
  }, [setStep])

  useEffect(() => {
    setInitialAgreements({ agreed_with_privacy_policy: agreedWithPrivacyPolicy })

    // Check for last agreement versions
    setIsAgreeWithPrivacyPolicy(parseInt(privacyPolicy, 10) === agreedWithPrivacyPolicy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privacyPolicy])

  useEffect(() => {
    if (shouldShowIsaTransfer) handleInitIsaTransferPrefillForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowIsaTransfer])

  const [resetError, changeField, updateOrCreate, changeNotificationSettings] = useActions([
    resetErrorActionCreator,
    changeFieldActionCreator,
    updateOrCreateActionCreator,
    changeNotificationSettingsActionCreator,
  ])

  useUnmount(() => {
    changeField({ password: null })
  })

  const handleChangeClientField = useCallback(
    (field, value = null) => {
      changeField({ [field]: value })
    },
    [changeField],
  )

  const handlePrivacyPolicyAgreement = useCallback(() => {
    handleChangeClientField(
      'agreed_with_privacy_policy',
      parseInt(isAgreeWithPrivacyPolicy ? initialAgreements.agreed_with_privacy_policy : privacyPolicy),
    )
    setIsAgreeWithPrivacyPolicy(!isAgreeWithPrivacyPolicy)
  }, [privacyPolicy, isAgreeWithPrivacyPolicy, handleChangeClientField, initialAgreements])

  const handleChangeNotificationAgreement = useCallback(() => {
    setIsAgreeWithNotifications(!isAgreeWithNotifications)
  }, [isAgreeWithNotifications])

  const handleSubmit = useCallback(async () => {
    // If ISA transfer is needed and we're on password step, move to ISA transfer form
    if (shouldShowIsaTransfer && isPasswordStep) {
      moveToIsaTransferStep()
      return
    }

    if (isIsaTransferStep && !isIsaPrefillFormValid) {
      forceValidateIsaPrefillForm()
      return
    }

    if (!isProcessing) {
      setIsProcessing(true)

      try {
        trackSignUpEvents(client)

        const fieldsToUpdate = ['email', 'password', 'agreed_with_privacy_policy']

        if (shouldShowIsaTransfer) {
          handleChangeClientField('isa_transfer_prefilled_data', isaTransferPrefilledData.serialize())
          fieldsToUpdate.push('isa_transfer_prefilled_data')
        }

        const stateAfterUpdateContact = await updateOrCreate(fieldsToUpdate)

        if (stateAfterUpdateContact.client.error) {
          throw stateAfterUpdateContact.client.error
        }

        Impact.trackSignUp(stateAfterUpdateContact.client)

        if (isAgreeWithNotifications) {
          changeNotificationSettings('promo', true)
        }

        changeField({ password_filled: true })

        if (shouldShowIsaTransfer) {
          navigateToIsaTransferFlowByClient(stateAfterUpdateContact.client)
          fetchContactsFx()
          return
        }

        const isThirdPartyAuth = query.third_party_connect === 'starling'

        const nextUrl = isThirdPartyAuth
          ? urlTo('dashboard.third-party-auth', null, { ...query, accountCreated: true })
          : next ?? urlTo('dashboard', null)

        await fetchContactsFx()

        goTo(nextUrl)
      } catch (error) {
        if (!(error instanceof ValidationError)) {
          const validEmailErrors = ['This email is already in use']

          if (!validEmailErrors.includes(error?.data?.email)) {
            sendError(error)
          }
          resetError()
        }
        const isEmailRelatedError = error?.data?.email?.length
        const isPasswordRelatedError = error?.data?.password?.length

        showFailToast()
        if (isEmailRelatedError) {
          setStep('email')
          return
        }
        if (isPasswordRelatedError) setStep('password')
      } finally {
        setIsProcessing(false)
      }
    }
  }, [
    isProcessing,
    next,
    isAgreeWithNotifications,
    query,
    resetError,
    changeField,
    changeNotificationSettings,
    updateOrCreate,
    setStep,
    shouldShowIsaTransfer,
    isPasswordStep,
    moveToIsaTransferStep,
    forceValidateIsaPrefillForm,
    isIsaPrefillFormValid,
    isIsaTransferStep,
    isaTransferPrefilledData,
    handleChangeClientField,
    client,
  ])

  const validation = combineErrors(
    {
      email: emailRules(email),
      password: passwordRules(password),
      isAgreeWithPrivacyPolicy: {
        rules: [isAgreeWithPrivacyPolicy],
        errors: ["You don't agree with Terms and Conditions"],
      },
    },

    backendErrorsToObj(error),
  )

  return {
    mode,
    back,
    next,
    client,
    validation,
    isAgreeWithPrivacyPolicy,
    isAgreeWithNotifications,
    isProcessing,
    isEmailStep,
    isPasswordStep,
    isIsaTransferStep,
    moveToPasswordStep,
    moveToEmailStep,
    moveToIsaTransferStep,
    handleSubmit,
    handleChangeClientField,
    handlePrivacyPolicyAgreement,
    handleChangeNotificationAgreement,
  }
}
