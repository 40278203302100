import { urlTo } from 'helpers/router.js'

const useAllowNotificationsUrl = ({ module, options, query }, isPromoNotificationsAccepted) => {
  const targetUrl = urlTo(module, options, query)

  if (!isPromoNotificationsAccepted) {
    return {
      url: urlTo(module, options, {
        ...query,
        allowNotifications: 1,
      }),
      isModified: true,
    }
  }

  return { url: targetUrl, isModified: false }
}

export { useAllowNotificationsUrl }
