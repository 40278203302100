import React, { Fragment } from 'react'

import classNames from 'classnames/dedupe'

import { useMemo } from 'hooks'

import { getKeyOrDefault } from 'helpers/object'

import { Paper, type PaperSizes } from 'components/atoms/Paper'

import './ItemWithIcon.css'

const positions = {
  LEFT: 'left',
  RIGHT: 'right',
} as const

const aligns = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom',
} as const

const selfHorizontalAligns = {
  CENTER: 'center',
} as const

type ItemWithIconProps = {
  className?: string
  partClassNames?: {
    icon?: string
    content?: string
  }
  space?: ValueOf<typeof PaperSizes>
  icon: React.ReactNode
  content: React.ReactNode | React.ReactNodeArray
  iconPosition?: ValueOf<typeof positions>
  iconVerticalAlign?: ValueOf<typeof aligns>
  contentVerticalAlign?: ValueOf<typeof aligns>
  selfHorizontalAlign?: ValueOf<typeof selfHorizontalAligns>
  inline?: boolean
  'data-test-id'?: string
}

const ItemWithIcon = ({
  className,
  partClassNames,
  space = 20,
  icon,
  content,
  iconPosition = positions.LEFT,
  iconVerticalAlign = aligns.TOP,
  contentVerticalAlign = aligns.TOP,
  selfHorizontalAlign,
  inline = false,
  'data-test-id': dataTestId,
}: ItemWithIconProps): React.ReactElement => {
  const classes = useMemo(
    () =>
      classNames(className, 'ItemWithIcon', {
        ItemWithIcon_inline: inline,
        [iconVerticalAlign ? `ItemWithIcon_iconAlign_${iconVerticalAlign}` : '']: Boolean(iconVerticalAlign),
        [contentVerticalAlign ? `ItemWithIcon_contentAlign_${contentVerticalAlign}` : '']:
          Boolean(contentVerticalAlign),
        [selfHorizontalAlign ? `ItemWithIcon_selfAlign_${selfHorizontalAlign}` : '']: Boolean(selfHorizontalAlign),
      }),
    [className, inline, iconVerticalAlign, contentVerticalAlign, selfHorizontalAlign],
  )
  const iconClasses = useMemo(() => classNames(partClassNames?.icon, 'ItemWithIcon-Icon'), [partClassNames?.icon])
  const contentClasses = useMemo(
    () => classNames(partClassNames?.content, 'ItemWithIcon-Content'),
    [partClassNames?.content],
  )

  const inside = useMemo(
    () =>
      getKeyOrDefault(
        {
          default: (
            <Fragment>
              {icon && (
                <Paper className={iconClasses} right={space}>
                  {icon}
                </Paper>
              )}
              <div className={contentClasses}>{content}</div>
            </Fragment>
          ),
          [positions.RIGHT]: (
            <Fragment>
              <div className={contentClasses}>{content}</div>
              {icon && (
                <Paper className={iconClasses} left={space}>
                  {icon}
                </Paper>
              )}
            </Fragment>
          ),
        },
        iconPosition,
      ),
    [content, contentClasses, icon, iconClasses, iconPosition, space],
  )

  return (
    <div className={classes} data-test-id={dataTestId}>
      {inside}
    </div>
  )
}

export { ItemWithIcon }
