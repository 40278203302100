import { querystringFromObject } from 'helpers/ajax/querystring'
import { goTo, urlTo } from 'helpers/router.js'

import { useCancel } from 'app/pages/Securities/hooks'

const useNavigation = ({ portfolio, query = {}, isCollection }) => {
  const { handleCancel, abandonModalOpen, handleAbandonModalClose } = useCancel({ portfolio, query })

  const getSecurityLink = ({ id, query }) => {
    return urlTo('securities.security', { id }, query)
  }

  const redirectToSecurity = ({ id, query }) => {
    goTo(getSecurityLink({ id, query }))
  }

  const handleGoToEditWeights = () => {
    if (isCollection) {
      goTo(
        urlTo(
          'portfolio.edit-weights',
          { id: portfolio?.id },
          querystringFromObject({ ...query, back: window.location.pathname + window.location.search }),
        ),
      )
      return
    }
    goTo(urlTo('portfolio.edit-weights', { id: portfolio?.id }, querystringFromObject({ ...query, mode: 'universe' })))
  }

  return {
    abandonModalOpen,
    getSecurityLink,
    handleAbandonModalClose,
    handleCancel,
    handleGoToEditWeights,
    redirectToSecurity,
  }
}

export { useNavigation }
