import { ListOf } from 'app/redux/models/common/ListOf'

import { SecurityOrder } from './SecurityOrder.js'

/**
 * @param {Array.<SecurityOrder>} securityOrderList
 * @return {Array.<SecurityOrder>}
 */
const sort = (securityOrderList) =>
  securityOrderList
    .sort((leftSecurityOrder, rightSecurityOrder) => rightSecurityOrder.amount - leftSecurityOrder.amount)
    .sort((leftSecurityOrder, rightSecurityOrder) => rightSecurityOrder.weight_after - leftSecurityOrder.weight_after)

/**
 * @class
 * @typedef {Array.<SecurityOrder>} SecurityOrderList
 */
export class SecurityOrderList extends ListOf(SecurityOrder, sort) {
  /**
   * @param {string} trade_type
   * @return {SecurityOrderList}
   */
  getByTradeType(tradeType) {
    const filteredArray = [...this].filter((securityOrder) => securityOrder.trade_type === tradeType)

    return new SecurityOrderList(...filteredArray)
  }
}
