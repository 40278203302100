import React, { Fragment } from 'react'

import { goTo, urlTo } from 'helpers/router.js'

import { useCallback } from 'hooks'

const LastStep = (props) => {
  const { children, ...propsWithoutChildren } = props

  const { params } = props
  const handleOnAfterSave = useCallback(() => {
    goTo(urlTo('questionnaire-retake.optimal-portfolio', params))
  }, [params])

  return (
    <Fragment>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, { ...propsWithoutChildren, onAfterSave: handleOnAfterSave, key: index }),
      )}
    </Fragment>
  )
}

export { LastStep }
