import { useMemo } from 'hooks'

import { type Security } from 'app/effector/securities/models'
import { type SecurityTotalReturnsCollection } from 'app/effector/securities/models/TotalReturns/SecurityTotalReturnsCollection'

type TransformedTotalReturnsHistoryItem = {
  date: string
  value: number
}

type TransformedTotalReturnsStatItem = {
  price: number
  percent: number
}

export type TotalReturnsData = {
  history: Record<string, TransformedTotalReturnsHistoryItem>
  stats: Record<string, TransformedTotalReturnsStatItem>
}

const transformTotalReturns = (totalReturns: SecurityTotalReturnsCollection | undefined): TotalReturnsData => {
  const result = {
    ...totalReturns,
    history: {},
    stats: {},
  }

  if (totalReturns?.history) {
    Object.entries(totalReturns.history).forEach(([key, items]) => {
      result.history[key] = items.map((item) => ({
        date: item.date,
        value: parseFloat(item.total_returns),
      }))
    })
  }

  if (totalReturns?.stats) {
    Object.entries(totalReturns.stats).forEach(([key, item]) => {
      const transformedStats: TransformedTotalReturnsStatItem = {
        price: item.return_money,
        percent: item.return_percent,
      }
      result.stats[key] = transformedStats
    })
  }
  return result
}

const useTotalReturns = ({ security }: { security: Security | null }): { totalReturnsData: TotalReturnsData } => {
  const totalReturnsData = useMemo(() => {
    return transformTotalReturns(security?.totalReturns)
  }, [security?.totalReturns])

  return {
    totalReturnsData,
  }
}

export { useTotalReturns }
