import * as React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMediaQueries } from 'hooks'

import './CardAccent.css'

type PortfolioColors = 'primary' | 'secondary' | 'cashcolor'

type CardAccentProps = {
  className?: string
  color?: PortfolioColors
  'data-test-id': string
}

const CardAccent = ({
  className,
  color = 'primary',
  'data-test-id': dataTestId,
}: CardAccentProps): React.ReactElement => {
  const mediaQueries = useMediaQueries()

  const classes = classNames(
    className,
    'CardAccent',
    {
      [`CardAccent_${color}`]: true,
    },
    getMediaQuieryClasses('CardAccent', mediaQueries),
  )

  return <div className={classes} data-test-id={dataTestId} />
}

export { CardAccent }
