import React from 'react'

import { useCallback, useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'
import { format as formatPhone } from 'helpers/phone.js'
import { goTo, urlTo } from 'helpers/router.js'

import Icon from 'components/_old/Icon/Icon.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { useReauth } from 'app/pages/Dashboard/UserProfile/hooks'

import './PhoneNumberSetting.css'

type PhoneNumberSettingProps = {
  phone: string
  country: string
  parentModule: string
  isConfirmed: boolean
}

const PhoneNumberSetting = ({
  phone,
  country,
  parentModule,
  isConfirmed,
}: PhoneNumberSettingProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { checkIsClientReauthorized } = useReauth()

  const handlePhoneChange = useCallback(() => {
    if (checkIsClientReauthorized()) {
      goTo(urlTo(`${parentModule}.confirm-phone-number`), { scrollToTop: false })
      return
    }

    goTo(urlTo(`${parentModule}.confirm-your-identity`), { scrollToTop: false })
  }, [checkIsClientReauthorized, parentModule])

  return (
    <Paper>
      <ColumnarLayout className="PhoneNumberSetting_PhoneContainer" mods={{ padding: 'no', 'align-columns': 'center' }}>
        <Column size={0}>
          <ItemWithIcon
            space={0}
            icon={<Input className="PhoneNumberSetting_Phone" disabled type="tel" country={country} />}
            iconVerticalAlign="center"
            contentVerticalAlign="center"
            content={
              <Typography lineHeight="small">
                <Nobr>
                  <Typo>{formatPhone(phone)}</Typo>
                </Nobr>
              </Typography>
            }
          />
        </Column>
        <Column>
          <Paper left={8}>
            <ItemWithIcon
              space={4}
              icon={
                <Icon
                  type={isConfirmed ? 'success-24' : 'attention-24'}
                  size={24}
                  color={isConfirmed ? palette['status-success'] : palette['status-warning']}
                />
              }
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              content={
                <Typography lineHeight="small" color={isConfirmed ? 'secondary_default' : 'minor'}>
                  <Typo>{isConfirmed ? 'Confirmed' : 'Not confirmed'}</Typo>
                </Typography>
              }
            />
          </Paper>
        </Column>
      </ColumnarLayout>
      <Paper top={desktop ? 16 : 8}>
        <Link onClick={handlePhoneChange}>
          <Typography lineHeight="small" color="inherit">
            <Typo>{isConfirmed ? 'Change phone number' : 'Confirm or change phone number'}</Typo>
          </Typography>
        </Link>
      </Paper>
    </Paper>
  )
}

export { PhoneNumberSetting }
