import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useUnit } from 'effector-react'

import { useSelector, useEffect, useState, useCallback, useLoading, useRedirect } from 'hooks'

import { features } from 'helpers/features'
import { getKeyOrDefault } from 'helpers/object'
import { trackEvent } from 'helpers/analytics'
import { handleExternalLink } from 'helpers/browser'
import { goTo, urlTo } from 'helpers/router.js'
import { sendError } from 'helpers/errorLogging.js'

import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'

import { Gateway } from 'components/atoms/Gateway'
import { Preloader } from 'components/molecules/Preloader'

import { InstantBankTransfer } from './components/InstantBankTransfer'
import { ManualBankTransfer } from './components/ManualBankTransfer'
import { NoBankAccounts } from './components/NoBankAccounts'
import { WithBankAccounts } from './components/WithBankAccounts'
import { SavingsPlan } from './components/SavingsPlan'
import { BankAccountCard } from 'components/molecules/BankAccountCard'
import { Typo } from 'components/_old/Typo/Typo'
import { setAnimation } from 'components/molecules/Modal/'

import { authenticateData, checkBankAccount } from 'app/effector/bank-accounts/api'

import { fetchBankAccountsFx, fetchTrueLayerBankAccountsFx, $bankAccountsStore } from 'app/effector/bank-accounts'

import { showFailToast } from 'app/redux/actions/ui'

import {
  isClientNewOrNotCompleted as selectIsClientNewOrNotCompleted,
  isClientCompleted as selectIsClientCompleted,
  isClientApproved as selectIsClientApproved,
} from 'app/redux/selectors'

import { $recurringPaymentsStore } from 'app/effector/recurringPayments'

import { manageTypes, regulatoryTypes } from 'constants/portfolio'

const PaymentMethodTypes = {
  INSTANT_BANK_TRANSFER: 'INSTANT_BANK_TRANSFER',
  MANUAL_BANK_TRANSFER: 'MANUAL_BANK_TRANSFER',
}

const SelectPaymentMethod = ({
  goal: portfolio,
  params,
  location,
  tunnelQuery,
  isModalOpen,
  wasModalOpen,
  toggleCloseButton,
  handleClose,
}) => {
  const isModalOpenForTheFirstTime = isModalOpen && !wasModalOpen
  const client = useSelector((state) => state.client)
  const { is_fetched: isClientFetched } = client
  const isClientNewOrNotCompleted = useSelector(selectIsClientNewOrNotCompleted)
  const isClientCompleted = useSelector(selectIsClientCompleted)
  const isClientApproved = useSelector(selectIsClientApproved)
  const isPortfolioDIY = portfolio?.manage_type === manageTypes.DIY
  const isBackLinkVisible = isPortfolioDIY || portfolio?.regulatory_type === regulatoryTypes.SIPP
  const { isLoading: isBankAccountsLoading, wait } = useLoading(true)
  const isLoading = !isClientFetched || isBankAccountsLoading
  const [error, setErrorState] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)

  const { trueLayerBankAccounts: bankAccounts, nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)

  const isNominatedBankSupportsRecurringPayment = nominatedBankAccount?.bank?.recurring_payment_support
  const hasBankAccounts = !!bankAccounts.length
  const isAccountsLimitReached = bankAccounts.length >= 5
  const {
    hasReccuringPaymentByPortfolioId,
    hasDirectDebitByPortfolioId,
    getDirectDebitByPortfolioId,
    getReccuringPaymentByPortfolioId,
  } = useUnit($recurringPaymentsStore)
  const hasRecurringPayment = hasReccuringPaymentByPortfolioId(portfolio.id)
  const hasDirectDebitSubscriptions = hasDirectDebitByPortfolioId(portfolio.id)
  const directDebit = getDirectDebitByPortfolioId(portfolio.id)
  const recurringPayment = getReccuringPaymentByPortfolioId(portfolio.id)
  const subscription = directDebit ?? recurringPayment
  const hasSubscription = hasRecurringPayment || hasDirectDebitSubscriptions
  const isRecurringPaymentAvailableForSetup =
    (features.get('recurring-payments-release') && isNominatedBankSupportsRecurringPayment) || isClientNewOrNotCompleted

  const isManagedPortfolio = portfolio?.manage_type === manageTypes.MANAGED
  const gotFirstTopup = portfolio?.first_topup || parseFloat(portfolio.current_balance) >= 100
  const isTopupBeforeSavingsPlanNeeded = isClientApproved && isManagedPortfolio && !gotFirstTopup

  const nextBack = useMemo(() => window.location.pathname + window.location.search, [])
  const nextQuery = useMemo(() => ({ ...tunnelQuery, back: nextBack }), [tunnelQuery, nextBack])

  const setError = useCallback(
    (errorMessage) => {
      const message = getKeyOrDefault(
        {
          null: null,
          'Selected bank is not available for PAYMENTS':
            'Instant bank transfer not available currently. Please try again later.',
          "Bank account isn't approved": "Bank account isn't approved",
          default: 'Something went wrong',
        },
        errorMessage,
      )

      setErrorState(message)
    },
    [setErrorState],
  )

  useRedirect({
    to: () => goTo(urlTo('portfolio.add-funds.finish-registration', params), { replace: true }),
    rule: isModalOpen && isClientNewOrNotCompleted,
    isLoading,
  })

  useRedirect({
    to: () => goTo(urlTo('portfolio.add-funds.not-approved', params)),
    rule: isModalOpen && isClientCompleted,
    isLoading,
  })

  useEffect(
    () => {
      toggleCloseButton(false)

      wait(Promise.all([fetchBankAccountsFx(), fetchTrueLayerBankAccountsFx()]))
    },
    // had to run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (nextQuery?.error) {
      try {
        const { error: errorJSON, ...queryWithoutError } = nextQuery || {}
        const error = JSON.parse(errorJSON)

        if (error?.message) {
          setError(error.message)
        }

        if (error?.bankAccountId) {
          setSelectedPaymentMethod(error.bankAccountId)
        }

        goTo(urlTo(`dashboard.portfolio.add-funds`, { id: portfolio.id }, queryWithoutError), {
          replace: true,
          scrollToTop: false,
        })
      } catch (error) {
        sendError(error)
      }
    }
  }, [nextQuery, portfolio.id, setError, setSelectedPaymentMethod])

  const trackAddBank = useCallback(() => {
    trackEvent({
      category: 'Add funds',
      action: 'Add bank',
      portfolioPresetType: portfolio.preset_type,
      portfolioRegulatoryType: portfolio.regulatory_type,
    })
  }, [portfolio])

  const handleSetupBank = useCallback(() => {
    trackAddBank()
    setSelectedPaymentMethod(PaymentMethodTypes.INSTANT_BANK_TRANSFER)

    const url = urlTo(`dashboard.portfolio.add-funds.new-bank`, { id: portfolio.id }, nextQuery)

    wait(handleExternalLink(authenticateData, url), true).catch(() => showFailToast())
  }, [trackAddBank, portfolio.id, nextQuery, wait])

  const trackSelectPaymentMethod = useCallback(
    (label) => {
      trackEvent({
        category: 'Add funds',
        action: 'Select payment method',
        label,
        portfolioPresetType: portfolio.preset_type,
        portfolioRegulatoryType: portfolio.regulatory_type,
      })
    },
    [portfolio],
  )

  const handleManualBankTransfer = useCallback(() => {
    setSelectedPaymentMethod(PaymentMethodTypes.MANUAL_BANK_TRANSFER)
    trackSelectPaymentMethod('Wire transfer')

    goTo(urlTo(`dashboard.portfolio.add-funds.wire-transfer`, { id: portfolio.id }, nextQuery), {
      scrollToTop: false,
    })
  }, [trackSelectPaymentMethod, portfolio.id, nextQuery])

  const handleSavingsPlan = useCallback(() => {
    // That's a hack
    setAnimation('true')
    setTimeout(() => {
      setAnimation('false')
    }, 50)

    if (hasSubscription) {
      goTo(
        urlTo(
          `portfolio.options.${hasRecurringPayment ? 'recurring-payment-edit' : 'direct-debit-form'}`,
          { id: portfolio.id },
          { ...nextQuery },
        ),
      )
      return
    }

    if (isTopupBeforeSavingsPlanNeeded) {
      goTo(urlTo('portfolio.options.recurring-payment.fund-your-portfolio', { id: portfolio.id }, { ...nextQuery }))
      return
    }

    goTo(
      urlTo(
        `portfolio.options.${isRecurringPaymentAvailableForSetup ? 'recurring-payment' : 'direct-debit'}`,
        { id: portfolio.id },
        { ...nextQuery },
      ),
    )
  }, [
    hasSubscription,
    portfolio,
    nextQuery,
    hasRecurringPayment,
    isTopupBeforeSavingsPlanNeeded,
    isRecurringPaymentAvailableForSetup,
  ])

  const handleSelectBank = useCallback(
    (bankAccountId) => {
      setError(null)
      setSelectedPaymentMethod(bankAccountId)
      trackSelectPaymentMethod('Open Banking')

      wait(checkBankAccount(bankAccountId, 'PAYMENTS')).then(
        () => {
          const nextUrl = urlTo(`dashboard.portfolio.add-funds.amount`, { id: portfolio.id, bankAccountId }, nextQuery)

          goTo(nextUrl, { scrollToTop: false })
        },
        (error) => {
          setError(error?.response?.data?.error)
        },
      )
    },
    [nextQuery, portfolio.id, setError, trackSelectPaymentMethod, wait],
  )

  const handleUploadBankStatement = useCallback(
    (bankAccountId) => {
      goTo(urlTo(`dashboard.portfolio.add-funds.upload-bank-statement`, { id: portfolio.id, bankAccountId }, nextQuery))
    },
    [portfolio, nextQuery],
  )

  const handleBack = useCallback(() => {
    // That's a hack
    setAnimation('true')
    setTimeout(() => {
      setAnimation('false')
    }, 50)

    if (location?.query?.back) {
      goTo(location?.query?.back)
      return
    }

    goTo(urlTo(`dashboard.portfolio.options`, { id: portfolio.id }))
  }, [location?.query?.back, portfolio.id])

  const header = (
    <OptionsNavigationBar
      leftPartText={isBackLinkVisible ? 'Back' : null}
      onLeftPartClick={isBackLinkVisible ? handleBack : null}
      rightPartText="Close"
      onRightPartClick={handleClose}
    >
      <Typo>Add cash</Typo>
    </OptionsNavigationBar>
  )

  const instantBankTransfer = !isAccountsLimitReached ? (
    <InstantBankTransfer
      selected={selectedPaymentMethod === PaymentMethodTypes.INSTANT_BANK_TRANSFER}
      howItWorksUrl={urlTo(`dashboard.portfolio.add-funds.set-up-instant-bank`, { id: portfolio.id }, nextQuery)}
      portfolio={portfolio}
      hasBankAccounts={hasBankAccounts}
      onClick={handleSetupBank}
    />
  ) : null
  const manualBankTransfer = (
    <ManualBankTransfer
      selected={selectedPaymentMethod === PaymentMethodTypes.MANUAL_BANK_TRANSFER}
      onClick={handleManualBankTransfer}
    />
  )
  const savingsPlan = (
    <SavingsPlan
      onClick={handleSavingsPlan}
      subscription={subscription}
      isRecurringPaymentAvailableForSetup={isRecurringPaymentAvailableForSetup}
    />
  )

  const accounts =
    hasBankAccounts &&
    bankAccounts.map((account) => (
      <BankAccountCard
        selectable
        key={account.id}
        bankAccount={account}
        error={account.id === selectedPaymentMethod ? error : null}
        selected={account.id === selectedPaymentMethod}
        onClick={handleSelectBank}
        onUploadLinkClick={handleUploadBankStatement}
      />
    ))

  const content = hasBankAccounts ? (
    <WithBankAccounts
      header={header}
      accounts={accounts}
      instantBankTransfer={instantBankTransfer}
      manualBankTransfer={manualBankTransfer}
      savingsPlan={savingsPlan}
      isModalOpenForTheFirstTime={isModalOpenForTheFirstTime}
    />
  ) : (
    <NoBankAccounts
      header={header}
      instantBankTransfer={instantBankTransfer}
      manualBankTransfer={manualBankTransfer}
      savingsPlan={savingsPlan}
    />
  )

  return (
    <Fragment>
      {content}
      <Gateway into="newModalPreloader">
        <Preloader loading={isLoading} size="big" zIndex />
      </Gateway>
    </Fragment>
  )
}

SelectPaymentMethod.propTypes = {
  goal: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  location: PropTypes.shape({
    query: PropTypes.shape({
      back: PropTypes.string,
    }),
  }),
  tunnelQuery: PropTypes.object,
  isModalOpen: PropTypes.bool,
  wasModalOpen: PropTypes.bool,
  toggleCloseButton: PropTypes.func,
  handleClose: PropTypes.func,
}

export { SelectPaymentMethod }
