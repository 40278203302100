import React, { Fragment } from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { isDarkTheme } from 'helpers/palette'

import { Typo } from 'components/_old/Typo/Typo'

import { Hr } from 'components/atoms/Hr'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { MenuItem } from './MenuItem'

import './MenuSection.css'

type MenuItems = Array<{
  title: string
  icon: string
  hard?: boolean
  blank?: boolean
  link: string | null
  noArrow?: boolean
  desktop?: boolean
  isVisible: boolean
  dataTestId?: string
}>

type MenuSectionProps = {
  title: string
  items: MenuItems
  saveScrollPosition: () => void
}

const MenuSection = ({ title, items, saveScrollPosition }: MenuSectionProps): React.ReactElement => {
  const visibleItems = items.filter((item) => item.isVisible)
  const { desktop } = useMediaQueries()
  const isDark = isDarkTheme()

  const className = classNames('Menu_MenuSection', {
    Menu_MenuSection_desktop: desktop,
    Menu_MenuSection_mobile: !desktop,
    Menu_MenuSection_dark_theme: isDark,
  })

  return (
    <Fragment>
      <Paper bottom={12}>
        <Typography size={14} lineHeight="small" color="minor">
          <Typo>{title}</Typo>
        </Typography>
      </Paper>
      <Paper className={className}>
        {visibleItems.map((item, index) => (
          <Fragment key={item.title}>
            {index !== 0 && <Hr />}
            <MenuItem
              icon={item.icon}
              title={item.title}
              link={item.link ?? ''}
              hard={item.hard}
              blank={item.blank}
              noArrow={item.noArrow}
              dataTestId={item.dataTestId}
              saveScrollPosition={saveScrollPosition}
            />
          </Fragment>
        ))}
      </Paper>
    </Fragment>
  )
}

export { MenuSection, type MenuItems }
