import React from 'react'

import { useContext, useSelector, useMediaQueries } from 'hooks'

import { urlTo } from 'helpers/router.js'

import { selectIsGoalInClosing as selectIsPortfolioInClosing } from 'app/redux/selectors'

import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'
import { ActionKey } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'

import type { ManagedPortfolio } from '../types'

type ControlButtonsProps = {
  portfolio: ManagedPortfolio
  actionsMap: object
}

const ControlButtons = ({ portfolio, actionsMap }: ControlButtonsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { tunnelQuery } = useContext(ChangeGoalTunnelContext)
  const isPortfolioInClosing = useSelector(selectIsPortfolioInClosing)

  const addFundsButton = (
    <Button
      mods={{ size: desktop ? 'new-small block' : 'new-big' }}
      disabled={!actionsMap[ActionKey.ADD_FUNDS].enabled}
      onClick={() => actionsMap[ActionKey.ADD_FUNDS].onClick()}
      data-test-id="portfolioAddFundsButton"
    >
      Add Funds
    </Button>
  )

  const optionsButton = (
    <Button
      mods={{ theme: 'simple-reverse-blue', size: desktop ? 'new-small block' : 'new-big' }}
      data-test-id="portfolioOptionsButton"
    >
      <Link to={urlTo(`portfolio.options`, { id: portfolio?.id }, tunnelQuery)} scrollToTop={false}>
        Options
      </Link>
    </Button>
  )

  if (desktop) {
    return (
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column size={1}>
          <Paper right={8}>{addFundsButton}</Paper>
        </Column>
        {!isPortfolioInClosing && (
          <Column size={0}>
            <Width size={6.875}>{optionsButton}</Width>
          </Column>
        )}
      </ColumnarLayout>
    )
  }

  return (
    <Paper bottom={16} right={16} left={16}>
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column size={1}>
          <Card>
            <Button
              mods={{ size: 'big block' }}
              disabled={!actionsMap[ActionKey.ADD_FUNDS].enabled}
              onClick={() => actionsMap[ActionKey.ADD_FUNDS].onClick()}
              data-test-id="portfolioAddFundsButton"
            >
              Add Funds
            </Button>
          </Card>
        </Column>
        {!isPortfolioInClosing && (
          <Column size={0}>
            <Paper left={8}>
              <Card color="background-default">
                <Button mods={{ theme: 'simple-reverse-blue', size: 'big' }}>
                  <Link to={urlTo('portfolio.options', { id: portfolio?.id }, tunnelQuery)} scrollToTop={false}>
                    Options
                  </Link>
                </Button>
              </Card>
            </Paper>
          </Column>
        )}
      </ColumnarLayout>
    </Paper>
  )
}

export { ControlButtons }
