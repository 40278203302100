import { useCallback, useState } from 'hooks'

type UseSlowSecurityMentionInterface = {
  isSlowSecurity: boolean
  isSlowSecurityMentionOpen: boolean
  handleSlowSecurityMentionOpen: () => void
  handleSlowSecurityMentionClose: () => void
}

const useSlowSecurityMention = (security): UseSlowSecurityMentionInterface => {
  const [isSlowSecurityMentionOpen, setIsSlowSecurityMentionOpen] = useState(false)

  const handleSlowSecurityMentionOpen = useCallback(() => {
    setIsSlowSecurityMentionOpen(true)
  }, [])
  const handleSlowSecurityMentionClose = useCallback(() => {
    setIsSlowSecurityMentionOpen(false)
  }, [])

  return {
    isSlowSecurity: security?.is_slow_to_settle,
    isSlowSecurityMentionOpen,
    handleSlowSecurityMentionOpen,
    handleSlowSecurityMentionClose,
  }
}

export { useSlowSecurityMention }
