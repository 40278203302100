import type React from 'react'

import { useCallback, useRef, useState } from 'hooks'

import { useTouchablePanel } from 'components/organisms/charts/hooks'

type UseTooltipInterface = {
  rootRef: React.MutableRefObject<HTMLDivElement | null>
  lineRef: React.MutableRefObject<SVGGElement | null>
  hoveredIndex?: number
  handleEnter?: (event: React.MouseEvent<SVGGElement, MouseEvent>, index: number) => void
  handleLeave?: (event: React.MouseEvent<SVGGElement, MouseEvent>) => void
}

const useTooltip = (): UseTooltipInterface => {
  const lineRef = useRef<SVGGElement | null>(null)

  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(undefined)

  const handleEnter = useCallback(
    (_event, index) => {
      setHoveredIndex(index)
    },
    [setHoveredIndex],
  )
  const handleLeave = useCallback(
    (_event) => {
      setHoveredIndex(undefined)
    },
    [setHoveredIndex],
  )

  const { rootRef } = useTouchablePanel<HTMLDivElement>({
    handleEnter,
    handleLeave,
    containers: [lineRef.current].filter(Boolean) as SVGGElement[],
    enterOnTouchStart: false,
    enterOnLongTouch: true,
    leaveOnTouchEnd: true,
  })

  return {
    rootRef,
    lineRef,
    hoveredIndex,
    handleEnter,
    handleLeave,
  }
}

export { useTooltip, type UseTooltipInterface }
