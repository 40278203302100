import { createStore, createEvent, createEffect, sample } from 'effector'
import { pending } from 'patronum/pending'

import {
  generateRecoveryCode,
  confirmRecoveryCode,
  loginWithRecoveryCode,
  type LoginWithRecoveryCodeParams,
} from 'app/effector/2fa/api'

import { showFailToast } from 'app/redux/actions/ui'

const generateRecoveryCodeFx = createEffect(async () => {
  const result = await generateRecoveryCode()

  return result
})

const confirmRecoveryCodeFx = createEffect(async (id: number) => {
  const result = await confirmRecoveryCode(id)

  return result
})

const loginWithRecoveryCodeFx = createEffect(
  async ({ code, shouldRememberBrowser, clientId, token }: LoginWithRecoveryCodeParams) => {
    const result = await loginWithRecoveryCode({ code, shouldRememberBrowser, clientId, token })

    return result
  },
)

const showFailToastFx = createEffect(({ error }) => {
  showFailToast(error?.response?.data?.errors?.[0]?.message ?? 'Something went wrong')
})

// Events
const setIsConfirmNewRecoveryCodeChecked = createEvent<boolean>()
const setRecoveryCode = createEvent<string>()
const confirmNewRecoveryCode = createEvent()

// Stores
const $recoveryCode = createStore<string>('')
const $newRecoveryCode = createStore<{ raw: string; id: number | null }>({ raw: '', id: null })
const $isConfirmNewRecoveryCodeChecked = createStore<boolean>(false)

// Store updates
$newRecoveryCode.on(generateRecoveryCodeFx.doneData, (state, data) => data)
$newRecoveryCode.on(loginWithRecoveryCodeFx.doneData, (state, data) => data)
$recoveryCode.on(setRecoveryCode, (state, value) => value)
$isConfirmNewRecoveryCodeChecked.on(setIsConfirmNewRecoveryCodeChecked, (state, isChecked) => isChecked)

sample({
  clock: [generateRecoveryCodeFx.fail, confirmRecoveryCodeFx.fail, loginWithRecoveryCodeFx.fail],
  target: showFailToastFx,
})

sample({
  clock: confirmNewRecoveryCode,
  source: $newRecoveryCode,
  fn: (newRecoveryCode) => newRecoveryCode.id,
  target: confirmRecoveryCodeFx,
})

const $isLoading = pending([generateRecoveryCodeFx, confirmRecoveryCodeFx, loginWithRecoveryCodeFx])
const $isNewRecoveryCodeShown = $newRecoveryCode.map((newRecoveryCode) => !!newRecoveryCode.raw)

export {
  $recoveryCode,
  $isConfirmNewRecoveryCodeChecked,
  $newRecoveryCode,
  $isLoading,
  $isNewRecoveryCodeShown,
  generateRecoveryCodeFx,
  confirmRecoveryCodeFx,
  loginWithRecoveryCodeFx,
  setRecoveryCode,
  confirmNewRecoveryCode,
  setIsConfirmNewRecoveryCodeChecked,
}
