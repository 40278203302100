import compose from 'helpers/compose.js'
import { filterEmpty, filterClones } from 'helpers/filter.js'

import { nullable, type Nullable, int, string } from 'app/effector/model'

import { AddressList } from 'app/redux/models/address'

import { BusinessType } from './BusinessType'

import type { CompanyDTO } from '../api'

class Company {
  readonly id: Nullable<number>
  readonly name: Nullable<string>
  readonly uk_number: Nullable<string>
  readonly lei_number: Nullable<string>
  readonly addresses: Nullable<AddressList>
  readonly business_types: Nullable<Array<BusinessType | null>>
  readonly lock_business_info: boolean
  readonly bank_statement_filled: boolean
  readonly business_type_codes: Nullable<string[]>

  constructor(dto?: CompanyDTO) {
    this.id = nullable(int)(dto?.id)
    this.name = nullable(string)(dto?.name)
    this.uk_number = nullable(string)(dto?.uk_number)
    this.lei_number = nullable(string)(dto?.lei_number)
    this.addresses = AddressList.createFromObject({ list: dto?.addresses ?? [] })
    this.business_types = (dto?.business_types ?? []).map((type) => new BusinessType(type))
    this.lock_business_info = dto?.lock_business_info ?? false
    this.bank_statement_filled = dto?.bank_statement_filled ?? false

    Object.defineProperty(this, 'business_type_codes', {
      get: () => compose(filterEmpty, filterClones, (types) => types.map((type) => type.code))(this.business_types),
    })
  }
}

export { Company }
