import { useSelector } from 'react-redux'

import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useEffect, useState, useCallback, useActions, useMemo } from 'hooks'

import { sendError } from 'helpers/errorLogging'

import {
  $reportsStore,
  createNewReportRequest,
  fillReportRequestFormat,
  goReportRequestPrevStep,
  fillReportRequestPortfolioIds,
  fillReportRequestTime,
  fillReportRequestType,
  sendReportRequestFx,
  resetReportRequestCurrentStep,
} from 'app/effector/reports'
import { type ReportRequestDTO } from 'app/effector/reports/api'
import {
  type ReportFormat,
  ReportRequest,
  ReportRequestStep,
  ReportRequestTimePeriod,
  type ReportTypeList,
} from 'app/effector/reports/models'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'
import { getPortfolios } from 'app/redux/api/portfolio'
import { ApiError } from 'app/redux/models/errors'
import { PortfolioList } from 'app/redux/models/portfolio'
import { getSortedRegulatoryTypes } from 'app/redux/selectors'

import { regulatoryTypes, states } from 'constants/portfolio'

type UseCreateRequestInterfacePortfolio = {
  name: string
  value: number[] | null
  description?: string
}

type UseCreateRequestInterface = {
  reportRequest: ReportRequest
  reportTypes: ReportTypeList
  hasNoPortfolios: boolean
  portfolios: UseCreateRequestInterfacePortfolio[]
  formShown: boolean
  selectedType: string | null
  setSelectedType: (string: string) => void
  selectedTimePeriod: ReportRequestTimePeriod | null
  setSelectedTimePeriod: (timePeriod: ReportRequestTimePeriod | null) => void
  selectedCustomDateFrom: Date | null
  setSelectedCustomDateFrom: (dateFrom: Date) => void
  selectedCustomDateTo: Date | null
  setSelectedCustomDateTo: (dateFrom: Date) => void
  selectedPortfolioIds: number[] | null
  setSelectedPortfolioIds: (portfolioIds: number[] | null) => void
  selectedFormat: ReportFormat | null
  setSelectedFormat: (format: ReportFormat) => void
  reportRequestCreated: boolean
  reportRequestFailed: boolean
  isPortfoliosFetching: boolean
  handleCreateRequest: () => void
  handleSelectType: () => void
  handleSelectTimePeriod: () => void
  handleSelectPortfolioIds: () => void
  handleSelectFormat: () => void
  handleGoPrevStep: () => void
  handleCancel: () => void
}

const useCreateRequest = (): UseCreateRequestInterface => {
  const { reportRequest, reportTypes } = useUnit($reportsStore)

  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])
  const [isPortfoliosFetching, setPortfoliosFetching] = useState(true)

  const presets: Record<string, unknown[]> = useSelector((state: any) => state.portfolios.presets)
  const visiblePortfolioList: PortfolioList = useSelector((state: any) =>
    state.portfolios.list.getVisiblePortfolios({ includeHiddenPortfolios: true }),
  )
  const regulatoryTypesOrder = useSelector(getSortedRegulatoryTypes)

  const [closedPortfoliosList, setClosedPortfoliosList] = useState<PortfolioList>(new PortfolioList())
  const portfolioList = useMemo(
    () => new PortfolioList(...visiblePortfolioList, ...closedPortfoliosList),
    [closedPortfoliosList, visiblePortfolioList],
  )
  const hasNoPortfolios = useMemo(() => portfolioList.length < 1, [portfolioList])
  const groupedPortfolios = portfolioList.groupByRegulatoryType()

  const portfolios: UseCreateRequestInterfacePortfolio[] = useMemo(
    () => {
      const giaIds: number[] = groupedPortfolios[regulatoryTypes.GIA]?.getArrayOfPortfolioIds() ?? []
      const isaIds: number[] = groupedPortfolios[regulatoryTypes.ISA]?.getArrayOfPortfolioIds() ?? []
      const sippIds: number[] = groupedPortfolios[regulatoryTypes.SIPP]?.getArrayOfPortfolioIds() ?? []

      const sortedPortfolios = portfolioList.getFlatSortedList(regulatoryTypesOrder)

      let result: UseCreateRequestInterfacePortfolio[] = []

      if (giaIds?.length > 0 && (isaIds.length > 0 || sippIds.length > 0)) {
        result.push({
          name: 'All portfolios',
          value: null,
        })
      }

      if (giaIds?.length > 0) {
        result.push({
          name: 'All General portfolios',
          value: giaIds,
        })
      }

      if (isaIds?.length > 0) {
        result.push({
          name: 'All ISA portfolios',
          value: isaIds,
        })
      }

      if (sippIds?.length > 0) {
        result.push({
          name: 'All Personal Pension portfolios',
          value: sippIds,
        })
      }

      // remove duplicates potential fix
      result = result.filter((item, index) => {
        const itemIndex = result.findIndex((resultItem) => resultItem.name === item.name)

        return itemIndex === index
      })

      if (sortedPortfolios?.length > 0) {
        const sortedPortfoliosArray = sortedPortfolios.map((portfolio) => ({
          name: portfolio.getTitle ? (portfolio.getTitle() as string) : '',
          value: portfolio.id ? ([portfolio.id] as number[]) : [],
          description: portfolio.getDescription ? (portfolio.getDescription() as string) : '',
        }))

        result.push(...sortedPortfoliosArray)
      }

      return result
    },
    // since both `groupedPortfolios` and `presets` is non memoized complex structure
    // to make sure that memoization will work fine we have to manually convert it to primitives
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(groupedPortfolios), JSON.stringify(presets), regulatoryTypesOrder],
  )

  const [formShown, setFormShow] = useState(false)
  const [selectedType, setSelectedType] = useState<string | null>(null)
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<ReportRequestTimePeriod | null>(null)
  const [selectedCustomDateFrom, setSelectedCustomDateFrom] = useState<Date | null>(null)
  const [selectedCustomDateTo, setSelectedCustomDateTo] = useState<Date>(new Date())
  const [selectedPortfolioIds, setSelectedPortfolioIds] = useState<number[] | null>(null)
  const [selectedFormat, setSelectedFormat] = useState<ReportFormat | null>(null)
  const [reportRequestCreated, setReportRequestCreated] = useState(false)
  const [reportRequestFailed, setReportRequestFailed] = useState(false)

  const handleCreateRequest = useCallback(() => {
    setFormShow(true)
  }, [setFormShow])

  const handleCancel = useCallback(() => {
    createNewReportRequest()
    setFormShow(false)
    setSelectedType(null)
    setSelectedTimePeriod(null)
    setSelectedCustomDateFrom(null)
    setSelectedCustomDateTo(new Date())
    setSelectedPortfolioIds(null)
    setSelectedFormat(null)
  }, [setFormShow, setSelectedType, setSelectedTimePeriod])

  const createReportRequest = useCallback(
    async (dto: ReportRequestDTO | undefined) => {
      const createReportViewSendReportRequestFx = attach({ effect: sendReportRequestFx })

      try {
        if (!dto) {
          throw new Error('No `dto` serialized at the `createReportRequest`')
        }

        handleCancel()
        setReportRequestCreated(true)

        await createReportViewSendReportRequestFx(dto)
      } catch (error) {
        setReportRequestCreated(false)

        if (
          error?.response?.status === 400 &&
          error?.response?.data?.error === 'Not enough data for generate the report'
        ) {
          setReportRequestFailed(true)
          return
        }

        sendError(error)
        showFailToast()
      }
    },
    [handleCancel],
  )

  const fillStep = useCallback(
    (fillStep: () => void) => {
      if (fillStep) {
        fillStep()

        const updatedReportRequest = $reportsStore.getState().reportRequest

        if (!updatedReportRequest.step) {
          const dto = updatedReportRequest.serializeRequest()

          createReportRequest(dto)
        }
      }
    },
    [createReportRequest],
  )

  const handleSelectType = useCallback(() => {
    fillStep(() => {
      if (selectedType) {
        return fillReportRequestType(selectedType)
      }
    })
  }, [fillStep, selectedType])

  const handleSelectTimePeriod = useCallback(() => {
    fillStep(() => {
      if (selectedTimePeriod === ReportRequestTimePeriod.CUSTOM && selectedCustomDateFrom && selectedCustomDateTo) {
        return fillReportRequestTime([selectedCustomDateFrom, selectedCustomDateTo])
      }

      if (
        selectedTimePeriod === ReportRequestTimePeriod.CUSTOM &&
        reportRequest.step === ReportRequestStep.SELECT_TIME_PERIOD
      ) {
        return fillReportRequestTime(selectedTimePeriod)
      }

      if (selectedTimePeriod) {
        const dates = ReportRequest.getDatesFromTimePeriod(selectedTimePeriod)

        return fillReportRequestTime(dates)
      }
    })
  }, [fillStep, reportRequest.step, selectedCustomDateFrom, selectedCustomDateTo, selectedTimePeriod])

  const handleSelectPortfolioIds = useCallback(() => {
    fillStep(() => fillReportRequestPortfolioIds(selectedPortfolioIds))
  }, [fillStep, selectedPortfolioIds])

  const handleSelectFormat = useCallback(() => {
    fillStep(() => {
      if (selectedFormat) {
        fillReportRequestFormat(selectedFormat)
      }
    })
  }, [fillStep, selectedFormat])

  const handleGoPrevStep = useCallback(() => {
    if (reportRequest.step === ReportRequestStep.SELECT_TYPE) {
      setSelectedType(null)
    }

    if (reportRequest.step === ReportRequestStep.SELECT_TIME_PERIOD) {
      setSelectedTimePeriod(null)
    }

    if (reportRequest.step === ReportRequestStep.SELECT_CUSTOM_TIME_PERIOD) {
      setSelectedCustomDateFrom(null)
      setSelectedCustomDateTo(new Date())
    }

    if (reportRequest.step === ReportRequestStep.SELECT_PORTFOLIOS) {
      setSelectedPortfolioIds(null)
    }

    if (reportRequest.step === ReportRequestStep.SELECT_FILE_FORMAT) {
      setSelectedFormat(null)
    }

    resetReportRequestCurrentStep()
    goReportRequestPrevStep()
  }, [reportRequest.step])

  useEffect(() => {
    if (portfolios.length > 0) {
      setSelectedPortfolioIds(portfolios[0]?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios])

  useEffect(
    () => {
      createNewReportRequest()

      if (portfolioList.length < 1) {
        fetchPortfolios()
      }

      if (closedPortfoliosList.length < 1) {
        getPortfolios(states.CLOSED).then(
          (closedPortfolios) => {
            setPortfoliosFetching(false)

            if (!(closedPortfolios instanceof ApiError) && closedPortfolios.length > 0) {
              setClosedPortfoliosList(new PortfolioList(...closedPortfolios))
            }
          },
          () => {
            setPortfoliosFetching(false)

            sendError(new Error('Failed to fetch CLOSED portfolios at `Create report` page'))
          },
        )
      }
    },
    // had to run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return {
    reportRequest,
    reportTypes,
    hasNoPortfolios,
    portfolios,
    formShown,
    selectedType,
    setSelectedType,
    selectedTimePeriod,
    setSelectedTimePeriod,
    selectedCustomDateFrom,
    setSelectedCustomDateFrom,
    selectedCustomDateTo,
    setSelectedCustomDateTo,
    selectedPortfolioIds,
    setSelectedPortfolioIds,
    selectedFormat,
    setSelectedFormat,
    reportRequestCreated,
    reportRequestFailed,
    isPortfoliosFetching,
    handleSelectType,
    handleSelectTimePeriod,
    handleSelectPortfolioIds,
    handleSelectFormat,
    handleCreateRequest,
    handleGoPrevStep,
    handleCancel,
  }
}

export { useCreateRequest, type UseCreateRequestInterface }
