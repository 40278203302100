import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { useMediaQueries, useMemo, useRedirect, useCallback } from 'hooks'

import { palette } from 'helpers/palette/'

import { openReviewModal } from 'app/effector/review'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import List, { ListItem } from 'components/_old/List/List.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Preloader } from 'components/molecules/Preloader'

import { OptionsLayout } from '../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../Options/OptionsNavigationBar'
import { useCheckPaymentDetails } from '../hooks'

import { manageTypes } from 'constants/portfolio'

type SuccessPaymentProps = {
  portfolio: Portfolio
  handleClose: () => void
}

const SuccessPayment = ({ portfolio = {}, handleClose: closeModal }: SuccessPaymentProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { isLoading, monthlyPaymentAmount, monthlyPaymentDay, handleBack } = useCheckPaymentDetails(portfolio)

  useRedirect({
    to: handleBack,
    rule: portfolio.id && (!monthlyPaymentAmount || !monthlyPaymentDay),
    isLoading,
  })

  const handleClose = useCallback(() => {
    openReviewModal()
    closeModal()
  }, [closeModal])

  const content = useMemo(() => {
    const isAutoinvestActive = portfolio?.is_autoinvest_enabled

    return (
      <Width size={29} center>
        <Paper top={desktop ? 32 : 0} bottom={desktop ? 24 : 32}>
          <AllCenter>
            <Icon type="checkmark_in_a_circle" color={palette['status-success']} size={64} />
          </AllCenter>
        </Paper>
        <Paper bottom={32}>
          <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
            Your Direct Debit is being processed
          </Typography>
        </Paper>
        <Typography size={desktop ? 14 : 16}>
          <List mods={{ type: 'bullet', 'no-margin': 'top', spacing: !desktop ? 'big' : '' }}>
            <ListItem>
              <Typo>Your Direct Debit application has been submitted successfully</Typo>
            </ListItem>
            <ListItem>
              <Typo>The name on your bank statement will appear as InvestEngine (UK) Limited</Typo>
            </ListItem>
            <ListItem>
              <Typo>
                You will receive an email within 3 business days confirming that the Direct Debit has been set up
              </Typo>
            </ListItem>
            {portfolio.manage_type === manageTypes.DIY && (
              <ListItem data-test-id="autoinvestIsOnNotice">
                <Typo>
                  {isAutoinvestActive
                    ? 'AutoInvest is ON by default, but you can switch it off if you wish'
                    : 'AutoInvest is turned off because you have pending orders or incoming ISA assets'}
                </Typo>
              </ListItem>
            )}
          </List>
        </Typography>
      </Width>
    )
  }, [desktop, portfolio])

  return (
    <OptionsLayout
      header={
        <OptionsNavigationBar rightPartText="Close" onRightPartClick={handleClose}>
          <Typo>Savings Plan set up</Typo>
        </OptionsNavigationBar>
      }
      content={
        <Fragment>
          {desktop ? content : <AllCenter>{content}</AllCenter>}
          <Preloader loading={isLoading} background="background" zIndex absolute opaque />
        </Fragment>
      }
      button={
        <Button type="submit" onClick={handleClose} mods={{ size: 'big block' }} data-test-id="directDebitFinishButton">
          Continue
        </Button>
      }
      data-test-id="successPaymentModal"
    />
  )
}

SuccessPayment.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export { SuccessPayment }
