import React from 'react'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type CashPortfolioHeaderProps = {
  handleBack: () => void
  subtitle: string
}

const CashPortfolioHeader = ({ handleBack, subtitle }: CashPortfolioHeaderProps): React.ReactElement => {
  return (
    <ColumnarLayout mods={{ padding: 'no', 'column-content': 'center' }}>
      <Column size={0}>
        <Link onClick={handleBack} mods={{ color: 'content-on-background-default' }}>
          <ItemWithIcon
            space={16}
            icon={<Icon type="back-new" size={20} color="inherit" />}
            content={<Typography color="inherit">Back</Typography>}
            iconVerticalAlign="center"
          />
        </Link>
      </Column>
      <Column size={1}>
        <Typography size={24} weight="semibold" align="center" lineHeight="small" data-test-id="goalTitle">
          Cash
        </Typography>
        <Paper top={8}>
          <Width center size={14}>
            <Typography size={16} lineHeight="small" color="minor" align="center" data-test-id="goalSubtitle">
              {subtitle}
            </Typography>
          </Width>
        </Paper>
      </Column>
    </ColumnarLayout>
  )
}

export { CashPortfolioHeader }
