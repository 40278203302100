import React from 'react'

import { useMediaQueries } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'

type FinishRegistrationModalProps = {
  handleContinue: () => void
  handleClose: () => void
  handleBack: () => void
}

const FinishRegistrationModal = ({
  handleContinue,
  handleClose,
  handleBack,
}: FinishRegistrationModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const headerNode = (
    <NavigationBar
      leftPartText="Back"
      onLeftPartClick={handleBack}
      rightPartText="Close"
      onRightPartClick={handleClose}
    />
  )

  const buttonNode = (
    <Button onClick={handleContinue} mods={{ size: 'big block' }} data-test-id="finishRegistrationPopupContinue">
      Continue
    </Button>
  )

  const content = (
    <Paper top={56}>
      <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
        <Typo>Finish registration to start investing with InvestEngine</Typo>
      </Typography>
    </Paper>
  )

  if (desktop) {
    return (
      <Modal open zIndex={1002} onClose={handleClose} close={null} data-test-id="finishRegistrationModal">
        <Width size={36} style={{ minHeight: '600px' }}>
          <Paper top={8} bottom={8} right={24} left={24}>
            {headerNode}
          </Paper>
          <Paper top={80}>
            <Width size={27} center>
              {content}
              <Paper top={56}>
                <Width size={21.5} center>
                  {buttonNode}
                </Width>
              </Paper>
            </Width>
          </Paper>
        </Width>
      </Modal>
    )
  }

  return (
    <Modal open zIndex={1002} onClose={handleClose} close={null}>
      <MobileLayout
        header={headerNode}
        content={<Paper>{content}</Paper>}
        footer={<MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
}

export { FinishRegistrationModal }
