import React, { Fragment } from 'react'

import { palette } from 'helpers/palette/'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { SecurityConstituent } from 'components/organisms/SecurityConstituent'

import { type ISummary, type ISummaryItem } from '../../Assets'

type AssetsListProps = {
  isLoading: boolean
  summary: ISummary
  showTarget: boolean
}

const AssetsList = ({ isLoading, summary, showTarget }: AssetsListProps): React.ReactElement => (
  <Fragment>
    <Paper top={32} bottom={32}>
      <Typography align="right" lineHeight="small">
        <Skeleton shown={!!summary.skeleton} mix inline>
          <ColumnarLayout mods={{ padding: 'no' }} inline>
            <Column size={0}>
              <Typography tag="span" size={14} lineHeight="small" color="additional">
                <Typo>Actual</Typo>
              </Typography>
            </Column>
            <Column size={0}>
              {showTarget && (
                <Typography tag="span" size={12} lineHeight="small" color="minor">
                  &nbsp;/&nbsp;<Typo>Target</Typo>
                </Typography>
              )}
            </Column>
          </ColumnarLayout>
        </Skeleton>
      </Typography>
    </Paper>
    <Stack vertical={16}>
      {[
        ['Equities', summary.stocks, palette.stocks],
        ['Bonds', summary.bonds, palette.bonds],
        ['Alternatives', summary.alternatives, palette.alternatives],
        ['Cash', summary.cash, palette.cash],
      ].map(([title, summaryItem, color]: [string, ISummaryItem, string]) => (
        <Paper key={title} bottom={16}>
          <Skeleton shown={!!summary.skeleton}>
            <SecurityConstituent
              isLoading={isLoading}
              name={title}
              actual={summaryItem.actual ?? 0}
              actualAmount={summaryItem.actual_amount}
              target={summaryItem.target ?? 0}
              color={color}
              showTarget={showTarget}
            />
          </Skeleton>
        </Paper>
      ))}
    </Stack>
  </Fragment>
)

export { AssetsList }
