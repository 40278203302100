import React from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'

import { validate, getBrokenRule } from 'helpers/validation.js'

export default class Validate extends React.Component {
  state = {
    blured: false,
  }

  handleOnFocus = (childOnFocus) => () => {
    this.setState({ blured: false })

    childOnFocus?.()
  }

  handleOnBlur = (childOnBlur) => () => {
    const { onBlur } = this.props
    this.setState({ blured: true })

    if (isFunction(onBlur)) {
      onBlur()
    }

    childOnBlur?.()
  }

  componentDidMount() {
    const { rules, value } = this.props
    const isValid = validate(rules, false)

    if (!isValid && value) {
      this.setState({ blured: true })
    }
  }

  reset() {
    this.setState({ blured: false })
  }

  render() {
    let { rule, rules = [], skipWaitBlur, disabled = false, children } = this.props
    const { blured } = this.state

    if (typeof rule !== 'undefined') {
      rules.push(rule)
    }

    const forceValid = !skipWaitBlur && !blured
    const isValid = validate(rules, forceValid)
    const brokenRule = getBrokenRule(rules)

    if (typeof children === 'function') {
      children = children(
        isValid,
        brokenRule,
        this.handleOnFocus(children?.props?.onFocus),
        this.handleOnBlur(children?.props?.onBlur),
      )
    }

    if (typeof children === 'object') {
      children = React.cloneElement(children, {
        valid: isValid,
        brokenRule,
        onFocus: this.handleOnFocus(children?.props?.onFocus),
        onBlur: this.handleOnBlur(children?.props?.onBlur),
        disabled,
      })
    }

    return children
  }
}

Validate.propTypes = {
  onBlur: PropTypes.func,
  rules: PropTypes.array,
  rule: PropTypes.object,
  value: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  skipWaitBlur: PropTypes.bool,
  disabled: PropTypes.bool,
}
