import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useRedirect, useCallback } from 'hooks'
import { useUnit } from 'effector-react'

import { types as clientTypes } from 'constants/client'

import { trackEvent } from 'helpers/analytics/'

import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Card from 'components/_old/Card/Card.jsx'

import { TermsAndConditionsForm } from './TermsAndConditionsForm.jsx'
import { $bankAccountsStore } from 'app/effector/bank-accounts'

const TermsAndConditionsSetup = ({ prevStep, handleAfterSubmit }) => {
  const { desktop } = useMediaQueries()
  const { nominatedAccount, isLoading } = useUnit($bankAccountsStore)

  useRedirect({
    to: prevStep.module,
    rule: !nominatedAccount?.sort_code || !nominatedAccount?.account_number,
    isLoading,
  })

  const handleSubmit = useCallback(() => {
    trackEvent({
      action: 'terms_and_conditions_accepted',
      client_type: clientTypes.BUSINESS,
    })
    handleAfterSubmit()
  }, [handleAfterSubmit])

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <Card
        mods={{
          theme: 'transparent',
          'no-padding': 'right bottom left',
        }}
      >
        {submitButton}
      </Card>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  return <TermsAndConditionsForm wrapSubmitButton={wrapSubmitButton} onSubmit={handleSubmit} />
}

TermsAndConditionsSetup.propTypes = {
  prevStep: PropTypes.object,
  handleAfterSubmit: PropTypes.func,
}

export { TermsAndConditionsSetup }
