import React from 'react'
import PropTypes from 'prop-types'

import { Dialog } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/Dialog'
import { Typo } from 'components/_old/Typo/Typo'
import Button from 'components/_old/Button/Button.jsx'

const Abandon = ({ open = false, onContinue: handleContinue, onClose: handleClose }) => (
  <Dialog
    open={open}
    content={<Typo>Your portfolio changes have not been saved</Typo>}
    buttons={[
      <Button key="ok" mods={{ theme: 'simple-reverse-blue' }} onClick={handleContinue}>
        OK
      </Button>,
      <Button key="cancel" onClick={handleClose}>
        Cancel
      </Button>,
    ]}
    onClose={handleClose}
  />
)

Abandon.propTypes = {
  open: PropTypes.bool,
  onContinue: PropTypes.func,
  onClose: PropTypes.func,
}

export { Abandon }
