import React, { Fragment, lazy, Suspense } from 'react'

import { useMemo } from 'hooks'

import { features } from 'helpers/features'

import { AnalyticsItemType } from 'app/effector/analytics/types'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Badge } from 'components/atoms/Badge'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { Skeleton } from 'components/atoms/Skeleton'
import { TextCollapse } from 'components/atoms/TextCollapse'
import { Typography } from 'components/atoms/Typography'

import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'

import { MobileFooter } from 'app/containers/Footer'

import { CountriesList } from '../components/CountriesList'
import { DistributionChart } from '../components/DistributionChart'
import { PortfolioTitle } from '../components/PortfolioTitle'
import { PortfolioWithHolding } from '../components/PortfolioWithHolding'
import { type SingleHoldingProps } from '../SingleHolding'

const CountryFlag = lazy(async () => await import(/* webpackChunkName: "CountryFlag" */ 'components/atoms/CountryFlag'))

const typeTexts = {
  [AnalyticsItemType.HOLDING]: 'holding',
  [AnalyticsItemType.SECTOR]: 'sector',
  [AnalyticsItemType.REGION]: 'region',
}

type SingleHoldingMobileProps = SingleHoldingProps & {
  type: AnalyticsItemType
}

const Mobile = ({
  isLoading,
  type,
  portfolios,
  selectedPortfolio,
  showTarget,
  holdingData,
  holdingDistribution,
  sortedPortfolioIds,
  totalAmount,
  isCountriesTabActive,
  regionTabs,
  navigateToRegionTab,
  handleBack,
  handleGoToSecurity,
  navigateToCountry,
}: SingleHoldingMobileProps): React.ReactElement => {
  const hasRegionOrSectorInfo = useMemo(
    () => Boolean(holdingData?.sector_name) || Boolean(holdingData?.region_name),
    [holdingData],
  )

  const headerNode = useMemo(
    () => (
      <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
        Analytics
      </NavigationBar>
    ),
    [handleBack],
  )

  const tabsNode = <GreyTabs tabs={regionTabs} onChange={navigateToRegionTab} />

  const titleNode = useMemo(() => {
    const title = (
      <Typography size={32} lineHeight="small" weight="semibold">
        <Typo>{isLoading ? '█████' : holdingData?.name} (Equity)</Typo>
      </Typography>
    )

    if ((isLoading && type === 'holding') || holdingData?.logo) {
      return (
        <Skeleton shown={isLoading} mix>
          <ItemWithIcon
            space={24}
            icon={<SecurityLogo logo={holdingData?.logo} size={48} />}
            content={title}
            contentVerticalAlign="center"
            inline
          />
        </Skeleton>
      )
    }

    return (
      <Skeleton shown={isLoading} mix>
        {title}
      </Skeleton>
    )
  }, [isLoading, holdingData?.name, holdingData?.logo, type])

  const sectorAndRegionNode = useMemo(
    () =>
      hasRegionOrSectorInfo ? (
        <Paper top={32}>
          <Typography size={14}>
            {holdingData?.sector_name && <Badge className="SingleHolding_Badge" text={holdingData?.sector_name} />}
            {holdingData?.region_name && <Badge className="SingleHolding_Badge" text={holdingData?.region_name} />}
          </Typography>
        </Paper>
      ) : null,
    [hasRegionOrSectorInfo, holdingData],
  )

  const descriptionNode = useMemo(
    () =>
      holdingData?.description ? (
        <Paper top={hasRegionOrSectorInfo ? 16 : 32}>
          <Typography size={14}>
            <TextCollapse limit={280}>{holdingData?.description}</TextCollapse>
          </Typography>
        </Paper>
      ) : null,
    [holdingData?.description, hasRegionOrSectorInfo],
  )

  const countryFlag = useMemo(() => {
    if (type !== AnalyticsItemType.COUNTRY) return null

    return (
      <Paper top={24}>
        <Suspense fallback={<div />}>
          <CountryFlag countryName={holdingData?.name} />
        </Suspense>
      </Paper>
    )
  }, [holdingData?.name, type])

  const websiteNode = useMemo(
    () =>
      holdingData?.website ? (
        <Paper top={16}>
          <Link to={holdingData?.website} blank>
            <Typography size={14} color="inherit">
              <Typo>{holdingData?.website}</Typo>
            </Typography>
          </Link>
        </Paper>
      ) : null,
    [holdingData?.website],
  )

  const distributionTitleNode = useMemo(
    () => (
      <Paper top={48}>
        <Typography size={18} lineHeight="small" weight="semibold">
          <Typo>
            Weightings of {typeTexts[type]}
            {selectedPortfolio ? ' in this portfolio' : ' in all portfolios'}
          </Typo>
        </Typography>
      </Paper>
    ),
    [type, selectedPortfolio],
  )

  const selectedPortfolioNode = useMemo(
    () =>
      selectedPortfolio ? (
        <Paper top={8}>
          <PortfolioTitle portfolio={selectedPortfolio} />
        </Paper>
      ) : null,
    [selectedPortfolio],
  )

  const distributionNode = useMemo(
    () => (
      <Paper top={32}>
        <Skeleton shown={isLoading}>
          <Fragment>
            {showTarget ? (
              <Paper bottom={24}>
                <Typography>
                  <Typography tag="span" size={14} lineHeight="small" color="additional">
                    <Typo>Actual</Typo>
                  </Typography>
                  <Typography tag="span" size={12} lineHeight="small" color="minor">
                    &nbsp;/&nbsp;<Typo>Target</Typo>
                  </Typography>
                </Typography>
              </Paper>
            ) : null}
            <DistributionChart
              showTarget={showTarget}
              name={holdingData?.name}
              actualWeight={holdingData?.actual_weight}
              targetWeight={holdingData?.target_weight}
              distribution={holdingDistribution}
              selectedPortfolio={selectedPortfolio}
              totalAmount={totalAmount}
              actualAmount={holdingData?.actual_value}
            />
          </Fragment>
        </Skeleton>
      </Paper>
    ),
    [isLoading, holdingData, showTarget, holdingDistribution, selectedPortfolio, totalAmount],
  )

  const portfoliosNode = useMemo(() => {
    if (selectedPortfolio) {
      const holdingSecurities = holdingData?.portfolios?.[selectedPortfolio.id]

      return (
        <PortfolioWithHolding
          isLoading={isLoading}
          showTarget={showTarget}
          portfolioId={selectedPortfolio.id}
          holdingSecurities={holdingSecurities}
          handleGoToSecurity={handleGoToSecurity}
        />
      )
    }

    return sortedPortfolioIds.map((portfolioId) => {
      const portfolio = portfolios.get(parseInt(portfolioId, 10))

      return (
        portfolio && (
          <PortfolioWithHolding
            key={portfolioId}
            isLoading={isLoading}
            portfolio={portfolio}
            showTarget={showTarget}
            portfolioId={portfolioId}
            holdingSecurities={holdingData?.portfolios[portfolioId]}
            handleGoToSecurity={handleGoToSecurity}
          />
        )
      )
    })
  }, [
    isLoading,
    holdingData?.portfolios,
    sortedPortfolioIds,
    showTarget,
    selectedPortfolio,
    handleGoToSecurity,
    portfolios,
  ])

  const mainContentNode = (
    <Fragment>
      {countryFlag}
      {descriptionNode}
      {websiteNode}
      {distributionTitleNode}
      {selectedPortfolioNode}
      {distributionNode}
      {portfoliosNode}
    </Fragment>
  )

  const countriesNode = (
    <Fragment>
      <CountriesList
        countries={holdingData?.countries}
        isLoading={isLoading}
        showTarget={showTarget}
        navigateToCountry={navigateToCountry}
      />
      <Paper top={32}>
        <Typography size={14} color="additional">
          <Typo>
            We show the company country based on its registered headquarters address. Holding companies and different
            company structures may affect information.
          </Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  const contentNode = (
    <React.Fragment>
      {titleNode}
      {sectorAndRegionNode}
      {features?.get('country-drill-down') && type === AnalyticsItemType.REGION && <Paper top={32}>{tabsNode}</Paper>}
      {isCountriesTabActive ? <Paper top={32}>{countriesNode}</Paper> : mainContentNode}
      <MobileFooter />
    </React.Fragment>
  )

  return <MobileLayout header={headerNode} content={contentNode} />
}

export { Mobile }
