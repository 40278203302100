import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import { cacheStore } from 'helpers/waitForAuth.js'

import rootReducer from 'app/redux/reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middleware = [thunk]

function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)))

  // Enable Webpack hot module replacement for reducers
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () => {
      /* eslint-disable-next-line @typescript-eslint/no-var-requires */
      const nextRootReducer = require('../reducers').default
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}

const store = (() => {
  if (process.env.NODE_ENV !== 'production' && module.hot && window.store) {
    return cacheStore(configureStore(window.store.getState()))
  }

  return cacheStore(configureStore())
})()

export default store
