import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import { useTrustpilotReviewModal } from './hooks/useTrustpilotReviewModal'

const TrustpilotReviewModal = (): React.ReactElement => {
  const {
    isOpen,
    title,
    image,
    image2x,
    subtitle,
    buttonText,
    linkText,
    isStartScreen,
    handleBack,
    onButtonClick,
    onLinkClick,
    handleClose,
  } = useTrustpilotReviewModal()

  const { desktop } = useMediaQueries()

  const content = (
    <AllCenter data-test-id="trustPilotModal">
      <img srcSet={`${image} 1x, ${image2x} 2x`} width={132} height={132} />
      <Paper top={32} bottom={desktop ? 32 : 24}>
        <Typography size={36} weight="semibold" lineHeight="small" align="center" data-test-id="trustPilotModalTitle">
          <Typo>{title}</Typo>
        </Typography>
      </Paper>
      <Typography lineHeight="small" align="center" data-test-id="trustPilotModalSubtitle">
        <Typo>{subtitle}</Typo>
      </Typography>
    </AllCenter>
  )

  const button = (
    <Button mods={{ size: 'big block' }} onClick={onButtonClick} data-test-id="trustPilotModalButton">
      {buttonText}
    </Button>
  )

  const link = (
    <Typography lineHeight="small" align="center" data-test-id="trustPilotModalTextLink">
      <Link onClick={onLinkClick}>{linkText}</Link>
    </Typography>
  )

  if (desktop)
    return (
      <Modal open={isOpen} onClose={handleClose} close={null}>
        <ModalContent width={36}>
          <Paper top={8} bottom={8} right={24} left={24}>
            <NavigationBar
              rightPartText="Close"
              onRightPartClick={handleClose}
              onLeftPartClick={isStartScreen ? undefined : handleBack}
            />
          </Paper>
          <Paper top={80} bottom={96}>
            <Width size={27} center>
              {content}
            </Width>
            <Paper top={40} bottom={32}>
              <Width size={21.5} center>
                {button}
              </Width>
            </Paper>
            <Typography lineHeight="small" align="center">
              {link}
            </Typography>
          </Paper>
        </ModalContent>
      </Modal>
    )

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <MobileLayout
        header={
          <NavigationBar
            leftPartText={isStartScreen ? undefined : 'Back'}
            rightPartText="Close"
            onRightPartClick={handleClose}
            onLeftPartClick={isStartScreen ? undefined : handleBack}
          />
        }
        content={content}
        footer={
          <Fragment>
            <MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>
            <MobileLayoutFooterButton>
              <Paper bottom={16}>{link}</Paper>
            </MobileLayoutFooterButton>
          </Fragment>
        }
      />
    </Modal>
  )
}

export { TrustpilotReviewModal }
