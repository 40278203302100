import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useCallback } from 'hooks'

import Segment from 'components/atoms/Segment/Segment.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Button from 'components/_old/Button/Button.jsx'

import LegalInfoForm from './LegalInfoForm.jsx'

const Legal = ({ changeClientField, handleAfterSubmit }) => {
  const { desktop } = useMediaQueries()

  const handleSubmit = useCallback(
    (...args) => {
      handleAfterSubmit(...args)
    },
    [handleAfterSubmit],
  )

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <Segment mods={{ margin: 'huge', noMargin: 'bottom' }}>{submitButton}</Segment>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  return (
    <LegalInfoForm
      inputMods={{ size: 'bigger' }}
      submitButton={<Button mods={{ size: 'big block' }}>Continue</Button>}
      wrapButton={wrapSubmitButton}
      onClientFieldChange={changeClientField}
      onSubmit={handleSubmit}
    />
  )
}

Legal.propTypes = {
  changeClientField: PropTypes.func,
  handleAfterSubmit: PropTypes.func,
}

export { Legal }
