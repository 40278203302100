import { DocName, type Document } from '../types'

const documentsContent: Record<DocName, Document> = {
  [DocName.ID]: {
    title: 'Photographic ID',
    type: DocName.ID,
    subtitle:
      'Copy of a government-issued photographic ID (usually a passport or UK driving licence).<br/>If you are using a driving licence as a form of ID please send us both the front and back.<br/>With a passport, we require a photo of the data page (the plastic page with photo and details), and the paper page above.<br/>Please ensure that all four corners of your ID/passport page are visible in the photo.',
    icon: 'passport-100',
  },
  [DocName.SELFIE]: {
    title: 'Selfie with ID',
    subtitle:
      'Selfie with your government issued photographic ID (usually a passport or driving licence).<br/>Your face and ID document should be clearly visible in the same photo.',
    type: DocName.SELFIE,
    icon: 'selfie-100',
  },
  [DocName.BANK_STATEMENT]: {
    title: 'Bank Statement',
    subtitle:
      'Scanned or pdf copy of your bank statement for your nominated bank account.<br/>The statement should clearly show your name, current address, sort code and account number, and should be dated within the past 3 months.',
    type: DocName.BANK_STATEMENT,
    icon: 'bank-statement-100',
  },
  [DocName.ISA_FORM]: {
    title: 'ISA Form',
    subtitle: 'Please upload your completed, printed, signed and scanned ISA transfer form.',
    type: DocName.ISA_FORM,
    icon: 'profile-photo-widget-100',
  },
  [DocName.OTHER]: {
    title: 'Secure Document Upload',
    subtitle: 'Please upload any other documents required.',
    type: DocName.OTHER,
    icon: 'profile-photo-widget-100',
  },
  [DocName.BILL]: {
    title: 'Proof of Address',
    subtitle:
      'Proof of your residential address dated within the last three (3) months. This can be a council tax statement or any utility bill (but not an internet or mobile phone bill).',
    type: DocName.BILL,
    icon: 'profile-photo-widget-100',
  },
  [DocName.SOURCE_OF_WEALTH]: {
    title: 'Source of Wealth',
    subtitle: '',
    type: DocName.SOURCE_OF_WEALTH,
    icon: 'profile-photo-widget-100',
  },
}

export { documentsContent }
