import React from 'react'

import { FinishRegistration } from './FinishRegistration.jsx'

export const routes = {
  module: 'finish-registration',
  path: 'finish-registration/',
  component: function Component(props) {
    return <FinishRegistration {...props} />
  },
}
