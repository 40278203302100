import React, { Fragment } from 'react'

import { useCallback, useMemo, useState, useMediaQueries } from 'hooks'

import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { SearchField } from 'components/molecules/SearchField'
import { VirtualList } from 'components/molecules/VirtualList'

import { Filter } from 'components/organisms/Filter'
import { FilterGroup } from 'components/organisms/Filter/FilterGroup'
import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'

import { TunnelHeader } from 'app/pages/Securities/components'

import { PresetTitle } from '../PresetTitle'

const SelfSelectedFullAnalytics = ({
  title,
  holdings,
  sectors,
  regions,
  filters,
  preset,
  handleClose,
  itemsAreClickable = true,
  type: activeTab,
  goToPresetAnalytics,
  searchValue,
  handleSearch,
}): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const [layoutNode, setLayoutNode] = useState(null)
  const layoutRef = useCallback((ref) => {
    setLayoutNode(ref)
  }, [])
  const analytics = { holdings, sectors, regions }

  const tabs = useMemo(
    () => [
      { title: 'Holdings', id: 'holdings', isActive: activeTab === 'holdings', withSearch: handleSearch },
      { title: 'Regions', id: 'regions', isActive: activeTab === 'regions' },
      { title: 'Sectors', id: 'sectors', isActive: activeTab === 'sectors' },
    ],
    [activeTab, handleSearch],
  )

  const itemTitles = {
    holdings: 'Equities',
    sectors: 'Sectors',
    regions: 'Regions',
  }

  const activeTabObject = useMemo(() => tabs.find((tab) => tab.isActive) ?? {}, [tabs])

  const searchIsActive = useMemo(
    () => activeTabObject?.withSearch && searchValue.length >= 2,
    [activeTabObject, searchValue],
  )

  const isListFiltered = !!filters?.regions.value.length || !!filters?.sectors.value.length || !!searchValue

  const header = useMemo(
    () => (
      <Inner twoColumns>
        <TunnelHeader onBack={handleClose} />
      </Inner>
    ),
    [handleClose],
  )

  const renderEquity = useCallback(
    (listElement, items) => {
      const item = items[listElement.index]

      return (
        <Paper key={item.name} top={48}>
          <SecurityConstituent
            logo={item.logo}
            name={item.name}
            actual={item.target}
            target={null}
            color={item.color}
            showLogo={activeTab === 'holdings'}
          />
        </Paper>
      )
    },
    [activeTab],
  )

  const renderNoEquities = useCallback(() => null, [])

  const content = (
    <Inner twoColumns>
      <PresetTitle title={title || preset?.title} align="left" />
      <Paper top={48}>
        <GreyTabs tabs={tabs} onChange={(tab) => goToPresetAnalytics(tab.id)} />
      </Paper>
      <Paper top={8}>
        <ColumnarLayout mods={{ padding: 'no' }}>
          <Column size={1}>
            {activeTabObject?.withSearch && (
              <Fragment>
                <Paper top={24} bottom={8}>
                  <Width size={36}>
                    <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by holding" />
                  </Width>
                </Paper>
                <Paper top={16}>
                  <FilterGroup>
                    <Filter
                      name="Region"
                      type="checkbox"
                      values={filters.regions.list}
                      selected={filters.regions.value}
                      onChange={filters.regions.set}
                      data-test-id="holdingsFilterRegion"
                    />
                    <Filter
                      name="Sector"
                      type="checkbox"
                      values={filters.sectors.list}
                      selected={filters.sectors.value}
                      onChange={filters.sectors.set}
                      dropdownMaxHeight={520}
                      data-test-id="holdingsFilterSector"
                    />
                  </FilterGroup>
                </Paper>
              </Fragment>
            )}
            {isListFiltered && !analytics[activeTab]?.length && (
              <Paper top={24}>
                <Typography size={14} lineHeight="medium" color="minor" data-test-id="noSearchResults">
                  <Typo>
                    No holdings found.
                    <br />
                    Please try a different search.
                  </Typo>
                </Typography>
              </Paper>
            )}
            <VirtualList
              scrollableElement={layoutNode}
              cacheKey="security-analytics-list-desktop"
              items={analytics[activeTab]}
              itemMinHeight={itemsAreClickable ? 92 : 76}
              itemMaxHeight={itemsAreClickable ? 120 : 96}
              renderItem={(element) => renderEquity(element, analytics[activeTab])}
              renderNoItems={renderNoEquities}
            />
          </Column>
          <Column size={0}>
            <Paper top={24} left={80}>
              <Width size={19}>
                {!searchIsActive && (
                  <Card data-test-id="holdingsQuantity">
                    <Paper top={40} bottom={40} left={24} right={24}>
                      <Typography size={48} lineHeight="small" weight="semibold" align="center">
                        <Typo>{analytics[activeTab].length}</Typo>
                      </Typography>
                      <Paper top={4}>
                        <Typography size={16} lineHeight="small" align="center">
                          <Typo>{itemTitles[activeTab]}</Typo>
                        </Typography>
                      </Paper>
                    </Paper>
                  </Card>
                )}
              </Width>
            </Paper>
          </Column>
        </ColumnarLayout>
      </Paper>
    </Inner>
  )

  const mobileContent = (
    <Fragment>
      <PresetTitle title={title || preset?.title} align="left" />
      <Paper top={32}>
        <GreyTabs tabs={tabs} onChange={(tab) => goToPresetAnalytics(tab.id)} />
      </Paper>
      {activeTabObject?.withSearch && (
        <Fragment>
          <Paper top={32} bottom={16}>
            <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by holding" />
          </Paper>
          <FilterGroup>
            <Filter
              name="Region"
              type="checkbox"
              values={filters.regions.list}
              selected={filters.regions.value}
              onChange={filters.regions.set}
            />
            <Filter
              name="Sector"
              type="checkbox"
              values={filters.sectors.list}
              selected={filters.sectors.value}
              onChange={filters.sectors.set}
            />
          </FilterGroup>
        </Fragment>
      )}
      {!searchIsActive && (
        <Paper top={32}>
          <Typography size={14} lineHeight="small" color="additional">
            <Typo>
              {analytics[activeTab].length} {itemTitles[activeTab]}
            </Typo>
          </Typography>
        </Paper>
      )}
      {isListFiltered && !analytics[activeTab]?.length && (
        <Paper top={32}>
          <Typography size={14} lineHeight="medium" color="minor" data-test-id="noSearchResults">
            <Typo>
              No holdings found.
              <br />
              Please try a different search.
            </Typo>
          </Typography>
        </Paper>
      )}
      <Paper top={searchIsActive ? 20 : 0} bottom={40}>
        <VirtualList
          scrollableElement={layoutNode}
          cacheKey="security-analytics-list-mobile"
          items={analytics[activeTab]}
          itemMinHeight={72}
          itemMaxHeight={92}
          renderItem={(element) => renderEquity(element, analytics[activeTab])}
          renderNoItems={renderNoEquities}
        />
      </Paper>
    </Fragment>
  )

  return desktop ? (
    <DesktopLayout ref={layoutRef} header={header} content={content} footer={<div />} />
  ) : (
    <MobileLayout ref={layoutRef} header={header} content={mobileContent} />
  )
}

export { SelfSelectedFullAnalytics }
