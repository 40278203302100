import { useCallback, useEffect, useActions, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'
import { urlTo, goTo } from 'helpers/router.js'

import { fetchOptionsFx } from 'app/effector/dicts'

import {
  changeField as changeClientFieldActionCreator,
  updateOrCreate as updateOrCreateClientActionCreator,
  resetError as resetClientErrorActionCreator,
  setValid as setClientValidActionCreator,
  setNotValid as setClientNotValidActionCreator,
} from 'app/redux/actions/client'
import { isClientOnboarded } from 'app/redux/selectors'

import { types as clientTypes } from 'constants/client'

let isSelected = false

const useProductType = ({ location = {} }) => {
  const { mode, back, next, IsaTransfer } = location?.query ?? {}

  const [changeClientField, updateOrCreateClient, resetClientError, setClientValid, setClientNotValid] = useActions([
    changeClientFieldActionCreator,
    updateOrCreateClientActionCreator,
    resetClientErrorActionCreator,
    setClientValidActionCreator,
    setClientNotValidActionCreator,
  ])

  const client = useSelector((state) => state.client)
  const clientOnboarded = useSelector(isClientOnboarded)

  useEffect(() => {
    fetchOptionsFx()

    return () => {
      isSelected = false
    }
  }, [])

  const handleCreateOrUpdate = useCallback(
    async (productType) => {
      try {
        isSelected = true

        setClientNotValid()

        await changeClientField({ type: productType })

        const stateAfterCreateClient = await updateOrCreateClient(['client_id', 'type'], false)

        if (stateAfterCreateClient.client.error) {
          const { error } = stateAfterCreateClient.client
          throw error
        }

        setClientValid()

        goTo(urlTo('create-account', {}, location.query), { replace: true })
      } catch (error) {
        isSelected = false
        processError({
          error,
          forceReset: true,
          resets: [resetClientError],
        })

        setClientValid()
      }
    },
    [updateOrCreateClient, resetClientError, setClientValid, setClientNotValid, changeClientField, location?.query],
  )

  const handleSubmit = useCallback(
    async (selectedType) => {
      const clientType = selectedType === 'BUSINESS' ? clientTypes.BUSINESS : clientTypes.INDIVIDUAL

      trackEvent({
        category: 'Get started',
        action: 'Select client type',
        label: clientType,
      })

      trackEvent({
        action: 'client_type_selected',
        client_type: clientType,
      })

      await handleCreateOrUpdate(clientType)
    },
    [handleCreateOrUpdate],
  )

  useEffect(() => {
    if (!isSelected && IsaTransfer === 'true') {
      handleSubmit('INDIVIDUAL')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { mode, back, next, client, clientOnboarded, isSelected, handleSubmit }
}

export { useProductType }
