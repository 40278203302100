import React from 'react'
import { withRouter } from 'react-router'

import { goTo, urlTo } from 'helpers/router'

import { Gateway } from 'components/atoms/Gateway'

import { Modal } from 'components/molecules/Modal'

const AutoinvestModal = withRouter(({ portfolio, routes, location: { query = {} } = {} }) => {
  const route = routes[routes.length - 1]
  const isOpen = routes.some((route) => route.module?.includes('autoinvest'))

  const handleClose = (): void => {
    goTo(urlTo(`dashboard.portfolio`, { id: portfolio.id }, query), { scrollToTop: false })
  }

  return (
    <Gateway into="modals">
      <Modal open={isOpen} close={null} onClose={handleClose}>
        {isOpen &&
          route.component({
            portfolio,
            handleClose,
          })}
      </Modal>
    </Gateway>
  )
})

export { AutoinvestModal }
