import { createStore, createEvent, sample, type Store } from 'effector'

type ActionItem = string | (() => void) | (() => Promise<void>)

const createStateMachine = <State extends string, Event extends string>({
  id,
  initial,
  states,
  actions,
}: {
  id: string
  initial: State
  states: Record<
    State,
    {
      on?: {
        [key in Event]?: State | { target: State; actions?: ActionItem[] }
      }
      entry?: ActionItem[]
      exit?: ActionItem[]
    }
  >
  actions?: Record<string, ActionItem>
}): { $currentState: Store<State>; sendEvent: (event: Event) => void } => {
  // Events

  // Event for sending events to state machine externally
  const sendEvent = createEvent<Event>()

  const runEventActions = createEvent<{ state: State; event: Event }>()
  const runStateExitActions = createEvent<{ state: State; event: Event }>()
  const setNewState = createEvent<{ state: State; event: Event }>()
  const runStateEntryActions = createEvent<State>()

  // Stores
  const $currentState = createStore<State>(initial)

  // Flow: sendEvent -> runEventActions -> runStateExitActions -> setNewState -> runStateEntryActions

  // when event is sent -> run event actions -> run state exit actions -> set new state
  sample({
    clock: sendEvent,
    source: $currentState,
    fn: (state, event) => {
      return { state, event }
    },
    target: [runEventActions, runStateExitActions, setNewState],
  })

  // when current state changes -> run state entry actions
  sample({
    clock: $currentState,
    source: $currentState,
    target: runStateEntryActions,
  })
  // forward({
  //   from: $currentState,
  //   to: runStateEntryActions,
  // })

  const runActions = (actionsList: ActionItem[] = []): void => {
    actionsList.forEach((action) => {
      if (typeof action === 'function') {
        action()
        return
      }

      if (!actions?.[action] || typeof actions?.[action] !== 'function') {
        // eslint-disable-next-line
        console.warn(`@StateMachine: could not find action "${action}" to run`)
      }

      actions?.[action]()
    })
  }

  // Run event actions
  runEventActions.watch(({ event, state }) => {
    runActions(states[state]?.on?.[event]?.actions)
  })

  // Run state exit actions
  runStateExitActions.watch(({ state }) => {
    runActions(states[state]?.exit)
  })

  // Run state entry actions
  runStateEntryActions.watch((state) => {
    runActions(states[state]?.entry)
  })

  // Set new state based on current state and event
  $currentState.on(setNewState, (state, { state: currentState, event }) => {
    const nextState = states[currentState]?.on?.[event]?.target || states[currentState]?.on?.[event]

    if (!Object.keys(states).includes(nextState)) {
      // eslint-disable-next-line
      console.warn(`@StateMachine: could not find next state for state "${currentState}" AND event ${event}`)
      return currentState
    }

    return nextState
  })

  return {
    $currentState,
    sendEvent,
  }
}

export { createStateMachine }
