import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import Inner from 'components/_old/Inner/Inner.jsx'
import Sticked from 'components/molecules/Sticked/Sticked.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Card from 'components/_old/Card/Card.jsx'
import { Progress } from 'components/_old/Progress/'
import Footer from 'components/_old/Footer/Footer.jsx'
import { Paper } from 'components/atoms/Paper'

import FeeInfo from './FeeInfo/FeeInfo.jsx'
import StepHeadline from './StepHeadline/StepHeadline.jsx'

const Questionnaire = ({
  children,
  headerProps: { leftLink, rightLink, progressFill = 0, withProgress = true, withShadow, greetings } = {},
  stepHeadlineProps: { headline, preHeadline, postHeadline, backLinkUrl, backLinkText, forceShow = false } = {},
  panel,
  showFee = false,
  withHeader = true,
  withInner = true,
  withFooter = true,
}) => {
  const { desktop } = useMediaQueries()

  const header = (
    <Header
      left={leftLink}
      right={rightLink}
      after={withProgress ? <Progress fill={progressFill} gapWidth="1em" /> : null}
      withShadow={withShadow}
      greetings={greetings}
      hideDrawer
    />
  )

  const stepsLine = (
    <Card
      mods={{
        theme: 'transparent',
        'no-padding': `all`,
      }}
      fluid
    >
      <Paper top={desktop ? 64 : 16}>
        <StepHeadline
          headline={headline}
          preHeadline={preHeadline}
          postHeadline={postHeadline}
          forceShow={forceShow}
          back={backLinkUrl}
          backLinkText={backLinkText}
          data-test-id="tunnelBackLink"
        />
      </Paper>
    </Card>
  )

  return (
    <section className="Layout-Holder">
      {withHeader && (
        <Fragment>
          {desktop ? header : <Sticked into="beforeLayout">{header}</Sticked>}
          {panel}
          {showFee && <FeeInfo />}
        </Fragment>
      )}
      <div className="Layout-Holder Layout-Content">
        {withInner ? (
          <Inner className="Layout-Holder">
            {withHeader && stepsLine}
            {children}
          </Inner>
        ) : (
          <Fragment>
            {withHeader && stepsLine}
            {children}
          </Fragment>
        )}
      </div>
      {desktop && withFooter ? <Footer /> : null}
    </section>
  )
}

Questionnaire.propTypes = {
  children: PropTypes.node.isRequired,
  headerProps: PropTypes.shape({
    progressFill: PropTypes.number,
    withProgress: PropTypes.bool,
    withShadow: PropTypes.bool,
    leftLink: PropTypes.node,
    rightLink: PropTypes.node,
    greetings: PropTypes.string,
  }).isRequired,
  stepHeadlineProps: PropTypes.shape({
    headline: PropTypes.string,
    preHeadline: PropTypes.node,
    postHeadline: PropTypes.node,
    backLinkUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    backLinkText: PropTypes.string,
    forceShow: PropTypes.bool,
  }),
  panel: PropTypes.node,
  showFee: PropTypes.bool,
  withInner: PropTypes.bool,
  withFooter: PropTypes.bool,
}

export { Questionnaire }
