import React from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo'

import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import { isSkeleton, type StoryOrSkeleton } from '../hooks/useStories'

import './StoryThumbnail.css'

type StoriesPreviewProps = {
  story: StoryOrSkeleton
  onClick?: () => void
  onHover?: () => void
  'data-test-id'?: string
}

const StoryThumbnail = ({
  story,
  onClick,
  onHover,
  'data-test-id': dataTestId,
}: StoriesPreviewProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const classes = classNames('StoryThumbnail', {
    StoryThumbnail_mobile: !desktop,
    StoryThumbnail_wide: !!story.wideImage,
  })

  return (
    <Skeleton shown={isSkeleton(story)} mix>
      <Typography
        size={desktop ? 14 : 12}
        tag="span"
        weight="bold"
        lineHeight="small"
        color="white"
        align="center"
        mix
        data-test-id={isSkeleton(story) && dataTestId ? `${dataTestId}-skeleton` : dataTestId}
      >
        <button
          className={classes}
          style={
            isSkeleton(story) ? {} : { backgroundImage: `url(${story.wideImage ? story.wideImage : story.image})` }
          }
          onClick={isSkeleton(story) ? undefined : onClick}
          onTouchStart={isSkeleton(story) ? undefined : onHover}
          onMouseEnter={isSkeleton(story) ? undefined : onHover}
        >
          {!isSkeleton(story) && (
            <span className="StoryThumbnail-Text">
              <Typo noNbsp>{story.text}</Typo>
            </span>
          )}
        </button>
      </Typography>
    </Skeleton>
  )
}

export { StoryThumbnail }
