import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { formatPercent } from 'helpers/money'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'

import { SecurityInfo } from 'components/organisms/SecurityInfo'

import type { AllocationItem } from '../../../../types'

type GroupOfAssetsProps = {
  groupOfAssets: AllocationItem
  onSecurityClick: (id: string) => void
  currentPeriod: string
}

const GroupOfAssets = ({ groupOfAssets, onSecurityClick, currentPeriod }: GroupOfAssetsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { type, weight, value, items = [] } = groupOfAssets

  const title = (
    <Typography size={desktop ? 24 : 18} lineHeight="small" weight="semibold">
      <Typo>
        {type} {formatPercent(weight / 100, 1)}
      </Typo>
    </Typography>
  )

  if (type === 'Cash') {
    return (
      <ColumnarLayout mods={{ padding: 'no' }}>
        <Column>{title}</Column>
        <Column size={0}>
          <Typography size={desktop ? 24 : 18} lineHeight="small" weight="semibold">
            <PoundsWithPence amount={value} showZeroPence={value > 0} />
          </Typography>
        </Column>
      </ColumnarLayout>
    )
  }

  return (
    <Fragment>
      {title}
      {items.map((item) => (
        <Paper key={item.id} top={desktop ? 24 : 12}>
          <SecurityInfo
            {...item}
            logo={item.logo_uri}
            current_weight={item.current_weight_from_capital_balance}
            target_weight={null}
            onClick={() => {
              onSecurityClick(item.security_id)
            }}
            stats={item?.stats?.[currentPeriod]}
            data-test-id="allocationSecurity"
          />
        </Paper>
      ))}
    </Fragment>
  )
}

export { GroupOfAssets }
