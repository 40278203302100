import React from 'react'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'

import { Typography } from 'components/atoms/Typography'

const FooterInfo = (): React.ReactElement => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  return (
    <Typography size={desktop ? 14 : 12} color="minor">
      <Typo>
        The chart should only be used as an indication of the range of potential outcomes based on the portfolio
        allocation you selected above, as well as the initial and monthly investments and time horizon. The information
        provided does not constitute advice or a promise of future outcomes. InvestEngine’s optimal portfolio is based
        only on the information you have provided as part of the risk questionnaire. If you hold your portfolio within
        our SIPP account you should consider the effects of tax relief and accounts fees which are not included in these
        projections.
      </Typo>
    </Typography>
  )
}

export { FooterInfo }
