import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { format as formatMoney } from 'helpers/money'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import Button from 'components/_old/Button/Button.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { ControlledInput } from 'components/atoms/Forms/ControlledInput'
import Label, { LabelField, LabelText } from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { BuySellTabs } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/components'
import { TradingSuspendedWarning } from 'components/organisms/TradingSuspendedWarning/TradingSuspendedWarning.tsx'

import './BuySellMobile.css'

const SellMobile = ({
  portfolio,
  noTradingSecurities,

  isValid,
  validation,
  errors,
  control,

  handleBack,
  handleBuy,
  handleSetAllValue,
}) => {
  const hasNoTradingSecurities = noTradingSecurities.length > 0

  const tabs = <BuySellTabs activeTab="sell" handleBuy={handleBuy} />

  const textField = (
    <Paper top={24}>
      <ControlledInput name="amount" control={control} errors={errors} rules={validation}>
        {({ value, valid, errorMessage, handleChange, handleFocus, handleBlur }) => (
          <Fragment>
            <Label valid={valid} errorMessage={errorMessage}>
              <LabelText>
                <Typography size={12} color="additional">
                  <Typo>Amount</Typo>
                </Typography>
              </LabelText>
              <LabelField>
                <Input
                  name="value"
                  type="money"
                  placeholder="£"
                  mods={{ size: 'big' }}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  withFloat
                >
                  {value}
                </Input>
              </LabelField>
            </Label>
            {!valid && (
              <Paper top={12}>
                <Typography size={14} color="error">
                  <Typo>{errorMessage}</Typo>
                </Typography>
              </Paper>
            )}
          </Fragment>
        )}
      </ControlledInput>
    </Paper>
  )

  const innerContent = hasNoTradingSecurities ? (
    <TradingSuspendedWarning />
  ) : (
    <Fragment>
      <Paper top={32}>
        <Typography size={36} weight="semibold">
          <Typo>Sell</Typo>
        </Typography>
      </Paper>
      <Paper top={4}>
        <Typography size={16}>
          <Typo>We'll sell based on your portfolio's target weights, starting with the most overweight holdings</Typo>
        </Typography>
      </Paper>
      {textField}
      <Paper top={12}>
        <Typography size={14} color="minor">
          <Typo>Settled holdings {formatMoney(portfolio?.available_securities_value || 0, true, true)}</Typo>
          <Link onClick={handleSetAllValue} style={{ marginLeft: 8 }}>
            <Typo>Sell all</Typo>
          </Link>
        </Typography>
      </Paper>
    </Fragment>
  )

  const content = (
    <Paper>
      {tabs}
      {innerContent}
    </Paper>
  )

  const footer = useMemo(
    () =>
      !hasNoTradingSecurities ? (
        <MobileLayoutFooterButton>
          <Button type="submit" disabled={!isValid}>
            Review order
          </Button>
        </MobileLayoutFooterButton>
      ) : null,
    [isValid, hasNoTradingSecurities],
  )

  return (
    <MobileLayout
      header={<NavigationBar leftPartText="Back" onLeftPartClick={handleBack} />}
      content={content}
      footer={footer}
    />
  )
}

SellMobile.propTypes = {
  portfolio: PropTypes.object,
  noTradingSecurities: PropTypes.array,

  isValid: PropTypes.bool,
  validation: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,

  handleBack: PropTypes.func,
  handleAddFunds: PropTypes.func,
  handleBuy: PropTypes.func,
  handleSetAllValue: PropTypes.func,
}

export { SellMobile }
