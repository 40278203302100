import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { format as formatMoney } from 'helpers/money'
import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router.js'

import { useCallback, useMediaQueries, useState } from 'hooks'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Paper } from 'components/atoms/Paper'

import './Transaction.css'
import { string } from 'app/effector/model'

const Transaction = ({
  className,
  transaction,
  portfolio,
  isSecurityTransaction,
  'data-test-id': dataTestId,
  portfolioReference,
}) => {
  const { desktop } = useMediaQueries()
  const isDone = transaction.isDone()

  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false)

  const classes = classNames(className, 'Transaction', {
    Transaction_phone: !desktop,
  })

  const handleDescriptionClick = useCallback(() => {
    setIsDescriptionVisible(!isDescriptionVisible)
  }, [transaction.description, isDescriptionVisible])

  const isZero = !transaction.credit && !transaction.debit
  const isPositive = transaction.credit && !transaction.debit
  const value = isZero ? '—' : isPositive ? formatMoney(transaction.credit, true) : formatMoney(transaction.debit, true)

  const cancelTransaction = (e) => {
    e.stopPropagation()
    goTo(
      urlTo(`dashboard.transactions.add-funds`, null, {
        amount: transaction.credit,
        transactionId: transaction.id,
        transactionPortfolioReference: portfolioReference,
      }),
    )
  }

  return (
    <Card
      className={classes}
      mods={{ theme: 'transparent' }}
      onClick={handleDescriptionClick}
      data-test-id="transaction"
      data-test-transaction-deletable={transaction.is_deletable}
    >
      {desktop ? (
        <div data-test-id={dataTestId}>
          <ColumnarLayout>
            <Column size={14}>
              <div className="Transaction-Type">
                <div className="Transaction-Icon">
                  <Icon
                    size={24}
                    type={isPositive ? 'up' : 'down'}
                    color={isPositive ? palette['status-success'] : palette['content-on-background-minor']}
                    fluid
                    inline
                    data-test-id={isPositive ? 'transaction-icon-arrow-up' : 'transaction-icon-arrow-down'}
                  />
                </div>
                <Text small data-test-id={`${dataTestId}transactionType`}>
                  {transaction.title}
                </Text>
              </div>
            </Column>
            <Column size={14}>
              <Text small data-test-id="transactionDate">
                {transaction.getFormattedCreatedDate()}
              </Text>
            </Column>
            <Column size={14}>
              <div className="Transaction-State-Wrapper">
                <div className="Transaction-State">
                  <div className={`Transaction-Icon ${isDone ? '' : 'Transaction-Icon_clock'}`}>
                    <Icon
                      size={isDone ? 20 : 32}
                      type={isDone ? 'checkmark' : 'clock'}
                      color={isDone ? palette['background-default'] : palette['content-on-background-minor']}
                      fluid
                      inline
                      rounded={isDone}
                      background={isDone ? palette['status-success'] : null}
                      data-test-id={isDone ? 'transaction-status-done' : 'transaction-status-processing'}
                    />
                  </div>
                  <Text small data-test-id={`${dataTestId}transactionStatus`}>
                    {transaction.state_title}
                  </Text>
                </div>
                {transaction.is_deletable && (
                  <div className="Transaction-Details" onClick={cancelTransaction} data-test-id="transactionMoreButton">
                    <Icon type="more-24" color={palette['content-on-background-minor']} size={24} inline />
                  </div>
                )}
              </div>
            </Column>
            {!isSecurityTransaction && (
              <Column size={8}>
                <Text small block data-test-id={`${dataTestId}transactionSum`} right>
                  {isPositive || isZero ? (
                    <span className="Transaction-MoneyPrefix">&nbsp;</span>
                  ) : (
                    <span className="Transaction-MoneyPrefix">-</span>
                  )}
                  {value}
                </Text>
              </Column>
            )}
          </ColumnarLayout>
          {isDescriptionVisible && (
            <Text small block className="Transaction-Description" data-test-id="transactionDescription">
              {transaction.description}
              <div>{portfolio}</div>
            </Text>
          )}
        </div>
      ) : (
        <div>
          <ColumnarLayout>
            <Column size={18} className="Transaction-PhoneClm">
              <div className="Transaction-Type">
                <Text small>{transaction.title}</Text>
              </div>
              <div className="Transaction-State">
                <div className={`Transaction-Icon ${isDone ? '' : 'Transaction-Icon_clock'}`}>
                  <Icon
                    size={isDone ? 20 : 32}
                    type={isDone ? 'checkmark' : 'clock'}
                    color={isDone ? palette['background-default'] : palette['content-on-background-minor']}
                    fluid
                    inline
                    rounded={isDone}
                    background={isDone ? palette['status-success'] : null}
                  />
                </div>
                <Text small muted>
                  {transaction.state_title}
                </Text>
                {transaction.is_deletable && (
                  <Paper className="Transaction-Details" onClick={cancelTransaction} left={16}>
                    <Icon type="more-24" color={palette['content-on-background-minor']} size={24} inline />
                  </Paper>
                )}
              </div>
            </Column>
            <Column size={18} className="Transaction-PhoneClm">
              <div className="Transaction-State-Wrapper">
                <div>
                  <div className="Transaction-Money">
                    <Text block bold right>
                      {isSecurityTransaction ? (
                        <Fragment>&nbsp;</Fragment>
                      ) : (
                        <Fragment>
                          {!isPositive && !isZero && '-'}
                          {value}
                        </Fragment>
                      )}
                    </Text>
                  </div>
                  <Text smaller muted block right>
                    {transaction.getFormattedCreatedDate()}
                  </Text>
                </div>
              </div>
            </Column>
          </ColumnarLayout>
          {isDescriptionVisible && (
            <Text small block className="Transaction-Description">
              {transaction.description}
              <div>{portfolio}</div>
            </Text>
          )}
        </div>
      )}
    </Card>
  )
}

Transaction.propTypes = {
  portfolioReference: PropTypes.number,
  className: PropTypes.string,
  transaction: PropTypes.shape({
    id: PropTypes.number,
    isDone: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    description: PropTypes.string,
    debit: PropTypes.number,
    credit: PropTypes.number,
    is_deletable: PropTypes.bool,
    state_title: PropTypes.string,
    getFormattedCreatedDate: PropTypes.func,
    title: PropTypes.string,
  }),
  portfolio: PropTypes.string,
  isSecurityTransaction: PropTypes.bool,
  'data-test-id': string,
}

export { Transaction }
