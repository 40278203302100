import { memoizeForever } from 'helpers/memoize'

import {
  combinePaths,
  getCurvePath,
  getPath,
  getMovePath,
  getLinePath,
  type XYCoordinates,
  type CurveXYCoordinates,
} from './svg'

const monotoneCubic = memoizeForever((positions: XYCoordinates[], close?: boolean): string | null => {
  const pointsCount = positions.length

  if (pointsCount === 0) {
    return null
  }

  // If less than three points we get source positions
  if (pointsCount < 3) {
    return getPath(positions, close)
  }

  const xs = positions.map((position) => position.x)
  const ys = positions.map((position) => position.y)

  // Calculate deltas and derivative
  const dys: number[] = []
  const dxs: number[] = []
  const derivatives: number[] = []

  for (let i = 0; i < pointsCount - 1; ++i) {
    dxs[i] = xs[i + 1] - xs[i]
    dys[i] = ys[i + 1] - ys[i]
    derivatives[i] = dys[i] / dxs[i]
  }

  // Determine desired slope (m) at each point using Fritsch-Carlson method
  // See: http://math.stackexchange.com/questions/45218/implementation-of-monotone-cubic-interpolation
  const slopes: number[] = []

  slopes[0] = derivatives[0]
  slopes[pointsCount - 1] = derivatives[pointsCount - 2]

  for (let i = 1; i < pointsCount - 1; i++) {
    if (derivatives[i] === 0 || derivatives[i - 1] === 0 || derivatives[i - 1] > 0 !== derivatives[i] > 0) {
      slopes[i] = 0
    } else {
      slopes[i] =
        (3 * (dxs[i - 1] + dxs[i])) /
        ((2 * dxs[i] + dxs[i - 1]) / derivatives[i - 1] + (dxs[i] + 2 * dxs[i - 1]) / derivatives[i])

      if (!isFinite(slopes[i]) || isNaN(slopes[i])) {
        slopes[i] = 0
      }
    }
  }

  const smoothPositions: CurveXYCoordinates[] = []
  for (let i = 0; i < pointsCount - 1; i++) {
    smoothPositions.push({
      x1: xs[i] + dxs[i] / 3,
      y1: ys[i] + (slopes[i] * dxs[i]) / 3,
      x2: xs[i + 1] - dxs[i] / 3,
      y2: ys[i + 1] - (slopes[i + 1] * dxs[i]) / 3,
      dx: xs[i + 1],
      dy: ys[i + 1],
    })
  }

  if (close) {
    return combinePaths([
      getMovePath({ x: 0, y: 1 }),
      getLinePath([{ x: xs[0], y: ys[0] }]),
      getCurvePath(smoothPositions),
      getLinePath([{ x: positions.at(-1)?.x ?? 0, y: 1 }]),
    ])
  }

  return combinePaths([getMovePath({ x: xs[0], y: ys[0] }), getCurvePath(smoothPositions)])
})

export { monotoneCubic }
