import React, { Fragment } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette/'
import { format as formatPhone } from 'helpers/phone.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Preloader } from 'components/molecules/Preloader'

import { SmsCodeIssue } from './CodeIssue'
import { useSmsCodeForm } from './useSmsCodeForm'

import './SmsCodeForm.css'

type SmsCodeFormProps = {
  action: 'login' | 'setup_2fa' | 'confirm_phone'
  query?: {
    client_id?: string
    token?: string
  }
  phone: string
  handleSuccess: () => void | Promise<void>
  handleBack: () => void
  shouldRememberBrowser?: boolean
  shouldFormatPhone?: boolean
  onConfirmIdentityError?: () => void
  handleShowRecoveryCodeForm: () => void
}

const SmsCodeForm = ({
  action,
  query,
  phone,
  handleSuccess,
  handleBack,
  shouldRememberBrowser,
  shouldFormatPhone = true,
  onConfirmIdentityError,
  handleShowRecoveryCodeForm,
}: SmsCodeFormProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    inputRef,
    validationRef,
    isLoading,
    isResending,
    isSuccess,
    blockedText,
    isResendRequired,
    code,
    countdown,
    validation,
    isCodeIssue,
    handleCodeChange,
    handleResend,
    handleShowCodeIssue,
    handleCloseCodeIssue,
  } = useSmsCodeForm({
    action,
    query,
    phone,
    onSuccess: handleSuccess,
    shouldRememberBrowser,
    onConfirmIdentityError,
    handleShowRecoveryCodeForm,
  })

  const hideInputAndButtons = isLoading || isResending || isSuccess

  const headerNode = useMemo(
    () => <NavigationBar onLeftPartClick={!isLoading && !isSuccess ? handleBack : null} leftPartText="Back" />,
    [handleBack, isLoading, isSuccess],
  )

  const titleNode = useMemo(
    () => (
      <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
        <Typo>Enter confirmation code</Typo>
      </Typography>
    ),
    [desktop],
  )

  const descriptionNode = useMemo(
    () => (
      <Typography size={desktop ? 14 : 16} align="center">
        <Typo>
          We've sent the code by SMS message to <Nobr>{shouldFormatPhone ? formatPhone(phone) : phone}</Nobr>
        </Typo>
      </Typography>
    ),
    [desktop, phone, shouldFormatPhone],
  )

  const iconNode = useMemo(
    () =>
      isSuccess ? (
        <AllCenter data-test-id="successScreen">
          <Icon type="success-64" color={palette['secondary-default']} size={64} />
        </AllCenter>
      ) : (
        <Fragment>
          <Paper top={32} bottom={32} style={{ position: 'relative' }}>
            <Preloader loading={true} absolute background="background" />
          </Paper>
          {isResending && (
            <Paper top={32}>
              <Typography align="center">
                <Typo>Code is being sent</Typo>
              </Typography>
            </Paper>
          )}
        </Fragment>
      ),
    [isSuccess, isResending],
  )

  const inputNode = useMemo(
    () => (
      <Width size={9} center>
        <Validate ref={validationRef} rules={validation.code.rules}>
          {(isValid, brokenRule) => (
            <Label
              postfield={
                <Typography
                  color={isValid ? 'default' : 'error'}
                  size={12}
                  lineHeight="small"
                  style={{ height: '14px' }}
                  align="center"
                  data-test-id="smsCodeError"
                >
                  <Typo>{isValid ? '' : validation.code.errors[brokenRule]}</Typo>
                </Typography>
              }
            >
              <Input
                ref={inputRef}
                className="SmsCodeForm_Input"
                name="code"
                autoComplete="one-time-code"
                type="number"
                mods={{ size: 'big', text: 'bold' }}
                onChange={handleCodeChange}
                maxlength={6}
              >
                {code}
              </Input>
            </Label>
          )}
        </Validate>
      </Width>
    ),
    [code, validation, handleCodeChange, inputRef, validationRef],
  )

  const resendCodeErrorNode = useMemo(
    () =>
      isResendRequired ? (
        <Paper top={24}>
          <Typography color="error" align="center">
            <Typo>Too many attempts. Resend code</Typo>
          </Typography>
        </Paper>
      ) : null,
    [isResendRequired],
  )

  const button = useMemo(
    () => (
      <Fragment>
        <Button
          mods={{ theme: 'simple-reverse-blue', size: 'big block' }}
          onClick={handleResend}
          disabled={countdown > 0 || isLoading}
          data-test-id="resendButton"
        >
          <Typography size={desktop ? 16 : 18} align="center" color="inherit" lineHeight="small" numeric="tabular">
            Resend {countdown > 0 ? `in ${countdown} seconds` : ``}
          </Typography>
        </Button>
        {blockedText ? (
          <Paper top={32}>
            <Typography align="center" color="error">
              <Typo>{blockedText}</Typo>
            </Typography>
          </Paper>
        ) : null}
        <Paper top={desktop ? 32 : 24} bottom={desktop ? 0 : 16}>
          <Link onClick={handleShowCodeIssue}>
            <Typography color="inherit" align="center">
              Didn’t receive a code?
            </Typography>
          </Link>
        </Paper>
      </Fragment>
    ),
    [desktop, isLoading, blockedText, countdown, handleResend, handleShowCodeIssue],
  )

  if (isCodeIssue) {
    return <SmsCodeIssue isLogin={action === 'login'} handleBack={handleCloseCodeIssue} />
  }

  if (desktop) {
    return (
      <Paper top={8} bottom={120} right={24} left={24}>
        {headerNode}
        <Paper top={96} left={48} right={48}>
          {titleNode}
          <Paper top={32} right={24} left={24}>
            {descriptionNode}
            <Paper top={32} style={{ position: 'relative' }}>
              <div style={{ visibility: hideInputAndButtons ? 'hidden' : 'visible' }}>
                {inputNode}
                {resendCodeErrorNode}
              </div>
              {hideInputAndButtons ? (
                <Paper style={{ position: 'absolute', top: 48, left: 0, right: 0 }}>{iconNode}</Paper>
              ) : (
                <Paper top={40}>{button}</Paper>
              )}
            </Paper>
          </Paper>
        </Paper>
      </Paper>
    )
  }

  const contentNode = (
    <Fragment>
      <Paper top={8}>{titleNode}</Paper>
      <Paper top={24}>{descriptionNode}</Paper>
      <Paper top={48} style={{ position: 'relative' }}>
        <div style={{ visibility: hideInputAndButtons ? 'hidden' : 'visible' }}>
          {inputNode}
          {resendCodeErrorNode}
        </div>
        {hideInputAndButtons ? (
          <Paper style={{ position: 'absolute', top: 48, left: 0, right: 0 }}>{iconNode}</Paper>
        ) : null}
      </Paper>
    </Fragment>
  )

  return (
    <MobileLayout
      header={headerNode}
      content={contentNode}
      footer={hideInputAndButtons ? null : <MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
    />
  )
}

export { SmsCodeForm }
