const disableScroll = (desktop): void => {
  const scrollContainerSelector = desktop ? '.DesktopLayout-Element_scrollable' : '.MobileLayout-Content'
  const scrollContainer = document.querySelector(scrollContainerSelector)

  if (scrollContainer) scrollContainer.style.overflowY = 'hidden'
}

const enableScroll = (desktop): void => {
  const scrollContainerSelector = desktop ? '.DesktopLayout-Element_scrollable' : '.MobileLayout-Content'
  const scrollContainer = document.querySelector(scrollContainerSelector)

  if (scrollContainer) scrollContainer.style.overflowY = 'scroll'
}

export { disableScroll, enableScroll }
