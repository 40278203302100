import { useRef, useEffect, useState } from 'react'

export type Direction = 'up' | 'down'

const useScrollDirection = (
  scrollPosition,
): [Direction | null, React.Dispatch<React.SetStateAction<Direction | null>>] => {
  const [direction, setDirection] = useState<Direction | null>(null)
  const lastScrollRef = useRef(0)

  useEffect(() => {
    if (!lastScrollRef) return

    const previousScrollPosition = lastScrollRef.current

    const isScrollingUp = previousScrollPosition > scrollPosition

    setDirection(isScrollingUp ? 'up' : 'down')

    lastScrollRef.current = scrollPosition
  }, [scrollPosition, lastScrollRef])

  return [direction, setDirection]
}

export default useScrollDirection
