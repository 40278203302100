import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import type { QuickStart } from 'app/effector/quickStart/types'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import { Typo } from 'components/_old/Typo/Typo'

import { Hr } from 'components/atoms/Hr'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import { Document } from 'components/molecules/Document'
import { MarkdownRenderer } from 'components/molecules/MarkdownRenderer'
import { NewPeriodSelect } from 'components/molecules/NewPeriodSelect'
import { PercentageScaleWithLegend } from 'components/molecules/PercentageScaleWithLegend'

import { FirstTopupBanner } from 'app/pages/Dashboard/Goals/components/FirstTopupBanner'
import { LumpSumBanner } from 'app/pages/Dashboard/Goals/components/LumpSumBanner'
import { SavingsPlanSetupBanner } from 'app/pages/Dashboard/Goals/components/SavingsPlanSetupBanner'

import { GrowthGraph } from '../ManagedPortfolio/components/Overview/components/Graphs/GrowthGraph'

import { ExpandableDescription } from './components/ExpandableDescription/ExpandableDescription'
import { SelfSelectedAnalytics } from './components/SelfSelectedAnalytics'

import type { AllocationItem } from '../ManagedPortfolio/types'

type SelfSelectedPortfolioProps = {
  title: string
  portfolio: Portfolio
  preset: Record<string, any>
  quickStart?: QuickStart
  showLumpSumBanner: boolean
  showFinishSavingsPlanBanner: boolean
  showFirstTopupBanner: boolean
  daysBeforePayment: number
  percentageScaleData: Array<{ text: string; color: string; percentage: number }>
  allocationData: AllocationItem[]
  location: {
    query: {
      time: string
    }
  }
  isHistoryLoading: boolean
}

const SelfSelectedPortfolioContent = ({
  title,
  portfolio,
  preset,
  quickStart,
  showFinishSavingsPlanBanner,
  showLumpSumBanner,
  showFirstTopupBanner,
  daysBeforePayment,
  percentageScaleData,
  allocationData,
  location,
  isHistoryLoading,
}: SelfSelectedPortfolioProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { time: currentPeriod = 'max' } = location.query

  const handleTabClick = (period): void => {
    goTo(urlTo(`dashboard.portfolio`, { id: portfolio.id }, { time: period }), {
      replace: true,
      scrollToTop: false,
    })
  }

  const handleSecurityClick = (securityId): void => {
    goTo(urlTo('dashboard.portfolio.security', { id: portfolio.id, securityId }, location.query))
  }

  const periodSelectNode = (
    <NewPeriodSelect
      selectedPeriod={currentPeriod}
      handlePeriodSelect={(period) => {
        handleTabClick(period)
      }}
    />
  )

  const savingsPlanSetupBannerNode = showFinishSavingsPlanBanner && quickStart && (
    <Paper top={0} bottom={48}>
      <SavingsPlanSetupBanner portfolio={portfolio} quickStart={quickStart} />
    </Paper>
  )

  const lumpSumBannerNode = showLumpSumBanner && quickStart && (
    <Paper top={0} bottom={48}>
      <LumpSumBanner portfolio={portfolio} quickStart={quickStart} />
    </Paper>
  )

  const firstTopupBannerNode = showFirstTopupBanner && (
    <Paper bottom={desktop ? 56 : 48}>
      <FirstTopupBanner portfolio={portfolio} daysBeforePayment={daysBeforePayment} />
    </Paper>
  )

  const chart = <GrowthGraph portfolio={portfolio} periodSelectNode={periodSelectNode} currentPeriod={currentPeriod} />

  return (
    <Fragment>
      {savingsPlanSetupBannerNode}
      {lumpSumBannerNode}
      {firstTopupBannerNode}
      {portfolio?.first_topup && (
        <Paper bottom={48}>
          <Skeleton shown={isHistoryLoading}>
            {desktop ? chart : <SideSpacesCompensator>{chart}</SideSpacesCompensator>}
          </Skeleton>
        </Paper>
      )}
      <Typography size={24} lineHeight="small" weight="semibold">
        <Typo>Key information</Typo>
      </Typography>
      <Paper top={24}>
        <Hr />
        <Paper right={16} data-test-id="portfolioDetailsLink">
          <ExpandableDescription title="Portfolio details">
            <MarkdownRenderer desktop={desktop}>{preset?.description}</MarkdownRenderer>
          </ExpandableDescription>
        </Paper>
        <Document noLeftPadding name="Key Features document" src={preset?.kiid} />
      </Paper>
      <Paper top={48}>
        <Typography size={24} lineHeight="small" weight="semibold">
          <Typo>Portfolio breakdown</Typo>
        </Typography>
      </Paper>
      <Paper top={32}>
        <PercentageScaleWithLegend items={percentageScaleData} height={4} />
      </Paper>
      <Paper top={48}>
        <SelfSelectedAnalytics
          title={title}
          portfolioId={portfolio?.id}
          allocationData={allocationData}
          onSecurityClick={handleSecurityClick}
          currentPeriod={currentPeriod}
          tunnelQuery={location.query}
        />
      </Paper>
    </Fragment>
  )
}

export { SelfSelectedPortfolioContent }
