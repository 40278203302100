import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { format as formatMoney } from 'helpers/money'

import { useMediaQueries } from 'hooks'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'
import { PercentageWeight } from 'components/molecules/PercentageWeight'

const SecurityConstituent = ({
  className,
  skeleton,
  isLoading,
  logo,
  name,
  actual,
  actualAmount,
  target,
  currentTarget,
  color,
  showTarget,
  showCurrentTarget = false,
  showLogo = !!logo,
  chartValue = actual,
  lineChartWidth,
}) => {
  const { desktop } = useMediaQueries()

  const shouldShowActualAmount = !!actualAmount || actualAmount === 0 || skeleton

  return (
    <ColumnarLayout
      className={className}
      mods={{ padding: desktop ? 'small' : 'micro' }}
      data-test-id="securityConstituent"
    >
      <Column size={1} mods={{ justifyContent: 'center', strict: true }}>
        {showLogo || skeleton ? (
          <ItemWithIcon
            space={8}
            icon={typeof logo === 'string' ? <SecurityLogo logo={logo} size={36} /> : logo}
            iconVerticalAlign="center"
            content={
              <Fragment>
                <Typography
                  size={14}
                  weight="regular"
                  lineHeight="small"
                  overflow="ellipsis"
                  style={{ whiteSpace: 'nowrap' }}
                  data-test-id="constituentTitle"
                >
                  <Typo noNbsp>{name}</Typo>
                </Typography>
                {shouldShowActualAmount && (
                  <Paper top={4}>
                    <Typography size={14} lineHeight="small" color="minor" data-test-id="constituentActualAmount">
                      <Typo>{formatMoney(actualAmount, !!actualAmount, !!actualAmount, false)}</Typo>
                    </Typography>
                  </Paper>
                )}
              </Fragment>
            }
            contentVerticalAlign="center"
          />
        ) : (
          <Fragment>
            <Typography
              size={14}
              weight="regular"
              lineHeight="small"
              overflow="ellipsis"
              style={{ whiteSpace: 'nowrap' }}
            >
              <Typo noNbsp>{name}</Typo>
            </Typography>
            {shouldShowActualAmount && (
              <Paper top={4}>
                <Typography size={14} lineHeight="small" color="minor" data-test-id="constituentActualAmount">
                  <Typo>{formatMoney(actualAmount, !!actualAmount, !!actualAmount, false)}</Typo>
                </Typography>
              </Paper>
            )}
          </Fragment>
        )}
      </Column>
      {!isLoading ? (
        <Column size={0} mods={{ justifyContent: 'center' }}>
          <PercentageWeight
            actual={actual}
            target={target}
            currentTarget={currentTarget}
            color={color}
            width={lineChartWidth || (desktop ? 20 : 8.75)}
            showTarget={showTarget}
            showCurrentTarget={showCurrentTarget}
            chartValue={chartValue}
          />
        </Column>
      ) : null}
    </ColumnarLayout>
  )
}

SecurityConstituent.propTypes = {
  className: PropTypes.bool,
  skeleton: PropTypes.bool,
  isLoading: PropTypes.bool,
  logo: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  name: PropTypes.string.isRequired,
  actual: PropTypes.number.isRequired,
  actualAmount: PropTypes.number,
  target: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentTarget: PropTypes.number,
  color: PropTypes.string,
  showTarget: PropTypes.bool,
  maxLines: PropTypes.number,
  showLogo: PropTypes.bool,
  chartValue: PropTypes.number,
  showCurrentTarget: PropTypes.bool,
  lineChartWidth: PropTypes.number,
}

export { SecurityConstituent }
