import axios from 'helpers/ajax/'

import { ApiError } from 'app/redux/models/errors/index.js'

import { requestIDCheck, type IDCheckResult, type IDCheckStatusResponse } from './requestIDCheck'

async function getDocumentCheckStatus(contactId: number | string): Promise<IDCheckResult | undefined> {
  return await requestIDCheck(`id_check/documents/status/${contactId}/`)
}

async function getDocumentCheckStatusOnce(contactId: number | string): Promise<IDCheckStatusResponse | ApiError> {
  try {
    const { data } = await axios.get(`id_check/documents/status/${contactId}/`)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

export { getDocumentCheckStatus, getDocumentCheckStatusOnce }
