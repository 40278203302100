import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useCallback, useEffect, useRef, useState } from 'hooks'

import Input from 'components/_old/Input/Input.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { VisuallyHidden } from 'components/atoms/VisuallyHidden'

import './NumberInput.css'

const NumberInput = ({
  className,
  children: value,
  min,
  max,
  step = 1,
  after,
  placeholder,
  align = 'right',
  onChange,
  onBlur,
  'data-test-id': dataTestId,
}) => {
  const afterRef = useRef(null)
  const [paddingRight, setPaddingRight] = useState(null)

  const handleChange = useCallback(
    (_event, value) => {
      value = parseFloat(value)

      if (isNaN(value)) {
        value = null
      }

      onChange && onChange(_event, value)
    },
    [onChange],
  )

  const handleOperatorClick = useCallback(
    (operator) => {
      if (operator === 'plus') {
        let nextValue = value + step

        if (nextValue > max) {
          nextValue = max
        }

        handleChange(null, nextValue)
      }

      if (operator === 'minus') {
        let nextValue = value - step

        if (nextValue < min) {
          nextValue = min
        }

        handleChange(null, nextValue)
      }
    },
    [value, min, max, step, handleChange],
  )

  const setMinValue = useCallback(() => {
    if (typeof min === 'number' && `${value || ''}`.length < 1 && handleChange) {
      handleChange(null, min)
    }
  }, [min, value, handleChange])

  const handleBlur = useCallback(
    (...args) => {
      setMinValue()

      onBlur && onBlur(...args)
    },
    [onBlur, setMinValue],
  )

  useEffect(setMinValue, [])

  useEffect(() => {
    if (afterRef?.current?.getBoundingClientRect) {
      const ems = align === 'right' ? 1.75 : 1.25
      setPaddingRight(`calc(${ems}em + ${afterRef.current.getBoundingClientRect().width}px)`)
    }
  }, [after, align, setPaddingRight])

  const minusDisabled = !value || value <= min
  const plusDisabled = value >= max

  const classes = classNames(className, 'NumberInput', { [`NumberInput_align_${align}`]: align })
  const minusClasses = classNames('NumberInput-Operator', 'NumberInput-Minus', {
    'NumberInput-Operator_disabled': minusDisabled,
  })
  const plusClasses = classNames('NumberInput-Operator', 'NumberInput-Plus', {
    'NumberInput-Operator_disabled': plusDisabled,
  })

  return (
    <fieldset className={classes} data-test-id={dataTestId}>
      <Link
        className={minusClasses}
        mods={{ color: 'mutedToRed' }}
        role="button"
        tabIndex={minusDisabled ? -1 : 0}
        onClick={() => handleOperatorClick('minus')}
        data-test-id={dataTestId && `${dataTestId}Minus`}
      >
        <span aria-hidden>−</span>
        <VisuallyHidden>
          Subtract {step} from value of {value || 0}
        </VisuallyHidden>
      </Link>
      <Input
        className="NumberInput-Input"
        style={paddingRight ? { paddingRight } : null}
        type="number"
        mods={{ size: 'unpadded' }}
        min={min}
        max={max}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        data-test-id={dataTestId && `${dataTestId}Input`}
      >
        {value}
      </Input>
      <span ref={afterRef} className="NumberInput-After">
        {after}
      </span>
      <Link
        className={plusClasses}
        mods={{ color: 'mutedToRed' }}
        role="button"
        tabIndex={plusDisabled ? -1 : 0}
        onClick={() => handleOperatorClick('plus')}
        data-test-id={dataTestId && `${dataTestId}Plus`}
      >
        <span aria-hidden>+</span>
        <VisuallyHidden>
          Add {step} to value of {value || 0}
        </VisuallyHidden>
      </Link>
    </fieldset>
  )
}

NumberInput.propTypes = {
  className: PropTypes.string,
  children: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  after: PropTypes.string,
  placeholder: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right']),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  'data-test-id': PropTypes.string,
}

export { NumberInput }
