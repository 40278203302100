import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect } from 'hooks'

import { processError } from 'helpers/errors'
import { getPaymentDetailsCheckStatus } from 'helpers/paymentDetailsCheck'

import {
  fetchBankAccountsFx,
  updateNominatedAccount,
  updateOrCreateBankAccountFx,
  $bankAccountsStore,
} from 'app/effector/bank-accounts'

import {
  setValid as setClientValidActionCreator,
  setNotValid as setClientNotValidActionCreator,
} from 'app/redux/actions/client'
import { changeField as changeUiFieldActionCreator, showFailToast } from 'app/redux/actions/ui'
import { ApiError } from 'app/redux/models/errors'

import {
  GP_DEFAULT,
  GP_VERIFY_BANK_ACCOUNT_SUCCESS,
  GP_VERIFYING_BANK_ACCOUNT,
} from 'constants/globalPreloaderStatuses.js'

const usePayment = () => {
  const [changeUiField, setClientValid, setClientInvalid] = useActions([
    changeUiFieldActionCreator,
    setClientValidActionCreator,
    setClientNotValidActionCreator,
  ])

  const { bankAccounts, nominatedAccount } = useUnit($bankAccountsStore)

  useEffect(() => {
    if (!nominatedAccount) {
      fetchBankAccountsFx()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updatePaymentDetails = useCallback(
    async (paymentData) => {
      setClientInvalid()
      const result = await updateOrCreateBankAccountFx({
        nominatedAccount,
        accountNumber: paymentData.account_number,
        sortCode: paymentData.sort_code,
      })

      await fetchBankAccountsFx()

      setClientValid()

      if (result instanceof ApiError) {
        return {
          result: false,
          error: result?.response?.data?.error,
        }
      }

      return { result: true }
    },
    [nominatedAccount, setClientValid, setClientInvalid],
  )

  const changePaymentField = (field, value) => updateNominatedAccount({ [field]: value })

  const processPaymentDetailsError = (error) => {
    showFailToast()
    if (error === 'Invalid bank account data') {
      showFailToast('Invalid bank account data')
      return
    }

    showFailToast()
  }

  const passPaymentDetailsCheck = useCallback(
    async (bankAccountId, onSuccessPaymentDetailsCheck, onFailPaymentDetailsCheck) => {
      setClientInvalid()
      try {
        changeUiField({ globalPreloaderStatus: GP_VERIFYING_BANK_ACCOUNT })

        const paymentDetailsCheckStatus = await getPaymentDetailsCheckStatus({ bankAccountId })

        if (paymentDetailsCheckStatus) {
          changeUiField({ globalPreloaderStatus: GP_VERIFY_BANK_ACCOUNT_SUCCESS })

          setTimeout(() => {
            if (onSuccessPaymentDetailsCheck) {
              onSuccessPaymentDetailsCheck()
            }
            changeUiField({ globalPreloaderStatus: GP_DEFAULT })
            setClientValid()
          }, 2000)
        }
        if (!paymentDetailsCheckStatus) {
          if (onFailPaymentDetailsCheck) {
            onFailPaymentDetailsCheck()
          }
          changeUiField({ globalPreloaderStatus: GP_DEFAULT })
          setClientValid()
        }
      } catch (error) {
        processError({ error, forceReset: true })
        setClientValid()
      }
    },
    [changeUiField, setClientValid, setClientInvalid],
  )

  return {
    bankAccountsList: bankAccounts,
    nominatedBankAccount: nominatedAccount,
    updatePaymentDetails,
    changePaymentField,
    processPaymentDetailsError,
    passPaymentDetailsCheck,
  }
}

export { usePayment }
