import React from 'react'

import { useCallback, useMemo, useMediaQueries } from 'hooks'

import { trackEvent } from 'helpers/analytics'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { SecurityItem } from 'components/molecules/SecurityItem'
import { VirtualList } from 'components/molecules/VirtualList'

type SecuritiesListProps = {
  securities: any[]
  securitiesWasLoadedOnce: boolean
  scrollableElementRef: React.RefObject<any>
  isLoading: boolean
  showLabels?: boolean
  getSecurityDataByIndex: (index: number) => any
  getSecurityLink: (security: any) => string
  handleSecurityClick: (security: any) => void
}

const SecuritiesList = ({
  securities,
  isLoading,
  securitiesWasLoadedOnce,
  scrollableElementRef,
  showLabels,
  getSecurityDataByIndex,
  getSecurityLink,
  handleSecurityClick,
}: SecuritiesListProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const renderSecurity = useCallback(
    ({ index, measure, registerChild }) => {
      const { security, isAddedToPortfolio } = getSecurityDataByIndex(index)

      return (
        <Paper ref={registerChild} bottom={desktop ? 24 : 12} data-test-id="securityItem">
          <SecurityItem
            {...security}
            added={isAddedToPortfolio}
            href={getSecurityLink(security?.id)}
            onClick={(id) => {
              handleSecurityClick(id)
              trackEvent({ action: 'etfrange_all_etfs_etf_clicked' })
            }}
            onSettled={measure}
            isLoading={isLoading}
            showLabels={showLabels}
            data-test-id={`securityItem${index}`}
          />
        </Paper>
      )
    },
    [handleSecurityClick, getSecurityDataByIndex, getSecurityLink, isLoading, showLabels, desktop],
  )

  const renderNoSecurities = useCallback(
    () =>
      securitiesWasLoadedOnce ? (
        <Paper top={desktop ? 40 : 56}>
          <Typography size={desktop ? 24 : 14} lineHeight="medium" color="minor" data-test-id="noSearchResults">
            <Typo multiline>{`No ETFs found.\n Please try a different search and/or change ETF filters.`}</Typo>
          </Typography>
        </Paper>
      ) : null,
    [securitiesWasLoadedOnce, desktop],
  )

  const securitiesList = useMemo(
    () => (
      <VirtualList
        scrollableElement={scrollableElementRef.current}
        cacheKey={desktop ? 'security-universe-list-desktop' : 'security-universe-list-mobile'}
        items={securities}
        itemMaxHeight={desktop ? 156 : 144}
        itemMinHeight={desktop ? 104 : 100}
        renderItem={renderSecurity}
        renderNoItems={renderNoSecurities}
      />
    ),
    [scrollableElementRef, securities, renderSecurity, renderNoSecurities, desktop],
  )

  return securitiesList
}

export { SecuritiesList }
