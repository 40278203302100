import React from 'react'

import classNames from 'classnames/dedupe.js'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import { PropTypes } from 'prop-types'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import Segment from 'components/atoms/Segment/Segment.jsx'

const EmptyPortfolio = ({ sidebar }): React.ReactElement => {
  const mediaQueries = useMediaQueries()
  const { phone } = mediaQueries
  const classes = classNames('Goal', getMediaQuieryClasses('Goal', mediaQueries))

  if (phone) {
    return (
      <div className={classes}>
        <Inner>
          <Width size={30}>
            <Card mods={{ theme: 'transparent', padding: 'big', 'no-padding': 'right bottom left' }} fluid>
              {sidebar}
            </Card>
          </Width>
        </Inner>
      </div>
    )
  }

  return (
    <div className={classes}>
      <Segment mods={{ margin: 'hefty', noMargin: 'bottom' }}>
        <Card mods={{ theme: 'transparent shadowed-mild-centered light', text: 'center' }} fluid>
          <AllCenter style={{ height: 306 }}>
            <Text center color="lightgray">
              <Typo>Please add a new portfolio</Typo>
            </Text>
          </AllCenter>
        </Card>
      </Segment>
    </div>
  )
}

EmptyPortfolio.propTypes = {
  sidebar: PropTypes.node,
}

export { EmptyPortfolio }
