import { useActions, useState, useSelector, useMemo, useCallback, useLoading } from 'hooks'

import { format as formatDate } from 'helpers/date'
import { sendError } from 'helpers/errorLogging'
import { emailRules, bulkValidate } from 'helpers/validation'

import {
  changeField as changeClientFieldActionCreator,
  updateOrCreate as updateOrCreateClientActionCreator,
} from 'app/redux/actions/client'
import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'

enum ThirdPartyTradeReportingModalStates {
  SET = 'SET',
  REMOVE = 'REMOVE',
  REMOVE_CONFIRM = 'REMOVE_CONFIRM',
}

type UseThirdPartyTradeReportingInterface = {
  thirdPartyEmail: string | null
  thirdPartyEmailEnabledDate: string | null
  futureThirdPartyEmail: string
  agreedWithPrivacyPolicy: boolean
  validation: {
    futureThirdPartyEmail: { rules: boolean[]; errors: string[] }
    agreedWithPrivacyPolicy: { rules: boolean[]; errors: string[] }
  }
  forceValidate: boolean
  isThirdPartyEmailSet: boolean
  isModalVisible: boolean
  isLoading: boolean
  modalState: ThirdPartyTradeReportingModalStates
  openModal: () => void
  closeModal: () => void
  handleThirdPartyFutureEmailInput: (event: unknown, value: string) => void
  handleAgreedWithPrivacyPolicy: (event: unknown, value: boolean) => void
  addThirdPartyEmail: () => Promise<void>
  removeThirdPartyEmail: () => Promise<void>
  cancelRemoveThirdPartyEmail: () => void
}

const useThirdPartyTradeReporting = (): UseThirdPartyTradeReportingInterface => {
  const [changeClientField, updateOrCreateClient] = useActions([
    changeClientFieldActionCreator,
    updateOrCreateClientActionCreator,
  ])

  const [thirdPartyEmail, thirdPartyEmailEnabledDateRaw] = useSelector<{
    client: {
      third_party_notification_email: string | null
      third_party_notification_email_enabled_date: string | null
    }
  }>((state) => [state.client.third_party_notification_email, state.client.third_party_notification_email_enabled_date])
  const isThirdPartyEmailSet = useMemo(() => (thirdPartyEmail ?? '').length > 0, [thirdPartyEmail])
  const thirdPartyEmailEnabledDate = useMemo(() => {
    if (!thirdPartyEmailEnabledDateRaw) {
      return null
    }

    return formatDate(thirdPartyEmailEnabledDateRaw)
  }, [thirdPartyEmailEnabledDateRaw])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalState, setModalState] = useState<ThirdPartyTradeReportingModalStates>(
    ThirdPartyTradeReportingModalStates.SET,
  )

  const [futureThirdPartyEmail, setFutureThirdPartyEmail] = useState('')
  const [agreedWithPrivacyPolicy, setAgreedWithPrivacyPolicy] = useState(false)
  const [forceValidate, setForceValidate] = useState(false)

  const { isLoading, wait } = useLoading()

  const validation = useMemo(
    () => ({
      futureThirdPartyEmail: emailRules(futureThirdPartyEmail, false, 'Third party email'),
      agreedWithPrivacyPolicy: {
        rules: [agreedWithPrivacyPolicy],
        errors: ['You need to agree to the checkbox to proceed'],
      },
    }),
    [futureThirdPartyEmail, agreedWithPrivacyPolicy],
  )

  const openModal = useCallback(() => {
    setModalState(
      isThirdPartyEmailSet ? ThirdPartyTradeReportingModalStates.REMOVE : ThirdPartyTradeReportingModalStates.SET,
    )
    setIsModalVisible(true)
  }, [isThirdPartyEmailSet])

  const closeModal = useCallback(() => {
    setFutureThirdPartyEmail('')
    setForceValidate(false)
    setIsModalVisible(false)
  }, [])

  const updateThirdPartyEmailAtBackend = useCallback(
    async (thirdPartyEmail: string | null, toastMessage: string) => {
      try {
        changeClientField({ third_party_notification_email: thirdPartyEmail })

        const stateAfterPatchClient = await wait(updateOrCreateClient(['third_party_notification_email'], false, false))

        if (stateAfterPatchClient.client.error) {
          throw stateAfterPatchClient.client.error
        }

        showSuccessToast(toastMessage)
      } catch (error) {
        sendError(error)
        showFailToast()
      }
    },
    [changeClientField, updateOrCreateClient, wait],
  )

  const addThirdPartyEmail = useCallback(async () => {
    if (!bulkValidate(validation)) {
      setForceValidate(true)
      return
    }

    await updateThirdPartyEmailAtBackend(futureThirdPartyEmail, 'Third party email added')
    closeModal()
  }, [closeModal, futureThirdPartyEmail, updateThirdPartyEmailAtBackend, validation])

  const removeThirdPartyEmail = useCallback(async () => {
    if (modalState === ThirdPartyTradeReportingModalStates.REMOVE) {
      setModalState(ThirdPartyTradeReportingModalStates.REMOVE_CONFIRM)
      return
    }

    await updateThirdPartyEmailAtBackend(null, 'Third party email removed')
    closeModal()
    setAgreedWithPrivacyPolicy(false)
  }, [closeModal, modalState, updateThirdPartyEmailAtBackend, setAgreedWithPrivacyPolicy])

  const cancelRemoveThirdPartyEmail = useCallback(() => {
    setModalState(ThirdPartyTradeReportingModalStates.REMOVE)
  }, [])

  const handleThirdPartyFutureEmailInput = useCallback(
    (_event, value: string) => {
      setFutureThirdPartyEmail(value)
    },
    [setFutureThirdPartyEmail],
  )

  const handleAgreedWithPrivacyPolicy = useCallback(
    (value: boolean) => {
      setAgreedWithPrivacyPolicy(value)
    },
    [setAgreedWithPrivacyPolicy],
  )

  return {
    thirdPartyEmail,
    thirdPartyEmailEnabledDate,
    futureThirdPartyEmail,
    agreedWithPrivacyPolicy,
    validation,
    forceValidate,
    isThirdPartyEmailSet,
    isModalVisible,
    isLoading,
    modalState,
    openModal,
    closeModal,
    handleThirdPartyFutureEmailInput,
    handleAgreedWithPrivacyPolicy,
    addThirdPartyEmail,
    removeThirdPartyEmail,
    cancelRemoveThirdPartyEmail,
  }
}

export { useThirdPartyTradeReporting, ThirdPartyTradeReportingModalStates, type UseThirdPartyTradeReportingInterface }
