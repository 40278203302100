import React from 'react'
import PropTypes from 'prop-types'

import Label from 'components/_old/Label/Label.jsx'
import Text from 'components/_old/Text/Text.jsx'
import SliderWTicks from 'components/_old/SliderWTicks/SliderWTicks.jsx'
import { AssetOnelinerGroup } from 'components/_old/AssetOneliner/AssetOneliner.jsx'
import { SuitablePortfolio } from 'components/organisms/SuitablePortfolio'

import './RiskField.css'

const RiskField = ({
  label,
  value,
  recommended,
  scale = [],
  portfolioId,
  'data-test-id': dataTestId,

  onChange,
}) => {
  const presetAsCaption = (
    <AssetOnelinerGroup>
      <Text smaller lineheight="one-line">
        Low
      </Text>

      <Text smaller lineheight="one-line">
        High
        <SuitablePortfolio portfolioId={portfolioId} />
      </Text>
    </AssetOnelinerGroup>
  )

  return (
    <div className="RiskField">
      <Label fluid>
        <div className="Label-Text">
          <Text color="lightgray" data-test-id={dataTestId ? `${dataTestId}Label` : ''}>
            {label}
          </Text>
        </div>
        <SliderWTicks
          value={value}
          valueKey="id"
          recommended={recommended}
          // Commented temporarely. We uncomment it back in about 6 months.
          // recommendedText="Optimal"
          limit
          scale={scale}
          valueAsCaption={presetAsCaption}
          onChange={onChange}
          theme="risk-zones"
          data-test-id={dataTestId}
        />
      </Label>
    </div>
  )
}

RiskField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  recommended: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  scale: PropTypes.array.isRequired,
  portfolioId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  'data-test-id': PropTypes.string,
}

export default RiskField
