import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

type ISummaryItem = {
  actual: number
  actual_amount: number
  target: number
}

type ISummary = {
  skeleton?: boolean
  total: number
  actual: number
  target: number
  stocks: ISummaryItem
  bonds: ISummaryItem
  alternatives: ISummaryItem
  cash: ISummaryItem
}

type IAssetsProps = {
  isLoading: boolean
  scrollableElementRef: React.RefObject<HTMLDivElement>
  fromPortfolio: boolean
  portfoliosOptions: Array<{ id: string; value: string }>
  selected: string
  selectedPortfolio: Record<string, any> // should be Portfolio type in future
  selectedPortfolioTitle: string
  showTarget: boolean
  summary: ISummary
  handlePortfolioSelect: () => void
  handleBackToPortfolio: () => void
  tabs: React.ReactNode
}

const Assets = (props: IAssetsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return <Fragment>{desktop ? <Desktop {...props} /> : <Mobile {...props} />}</Fragment>
}

export { Assets, type IAssetsProps, type ISummary, type ISummaryItem }
