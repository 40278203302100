import React from 'react'
import isFunction from 'lodash/isFunction'

import { isDisabledByRuleset } from 'helpers/field.js'

export default function DisableIfNeeded(props) {
  const { field, rules, model, children, ...rest } = props
  const disabled = isDisabledByRuleset({ field, rules, model })

  if (isFunction(children)) {
    return children(disabled, props)
  }

  return React.cloneElement(children, {
    disabled,
    ...rest,
  })
}
