import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useRef, useImperativeHandle } from 'hooks'

import InlineHelp from 'components/_old/InlineHelp/InlineHelp.jsx'
import Tooltip from 'components/molecules/Tooltip/Tooltip.jsx'
import ModalContent, { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'

const TooltipToModal = (
  {
    className,
    tipClassName,
    style,
    children,
    description,
    forceModal,
    customIcon,
    align,
    offset,
    zIndex,
    gatewayName,
    'data-test-id': dataTestId,
  },
  ref,
) => {
  const { desktop } = useMediaQueries()

  const inlineHelpRef = useRef()

  useImperativeHandle(ref, () => ({
    openModal: (event) => inlineHelpRef.current?.handleModalOpen(event),
  }))

  if (forceModal || !desktop) {
    return (
      <InlineHelp
        className={className}
        helper={customIcon ? children : null}
        iconType="questionInCircle"
        color={null}
        background={null}
        style={style}
        zIndex={zIndex}
        gatewayName={gatewayName}
        ref={inlineHelpRef}
      >
        {(handleClose) => (
          <ModalContent>
            {description}
            <ModalContentButtons text="OK" onClick={handleClose} />
          </ModalContent>
        )}
      </InlineHelp>
    )
  }

  return (
    <Tooltip
      className={className}
      tipClassName={tipClassName}
      content={description}
      align={align}
      offset={offset}
      data-test-id={dataTestId}
    >
      {children}
    </Tooltip>
  )
}

TooltipToModal.propTypes = {
  className: PropTypes.string,
  tipClassName: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.node,
  style: PropTypes.object,
  forceModal: PropTypes.bool,
  customIcon: PropTypes.bool,
  align: PropTypes.string,
  offset: PropTypes.number,
  'data-test-id': PropTypes.string,
}

const TooltipToModalWithForwardedRef = forwardRef(TooltipToModal)

export { TooltipToModalWithForwardedRef as TooltipToModal }
