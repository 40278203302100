import React from 'react'
import PropTypes from 'prop-types'

import { trackEvent } from 'helpers/analytics'

import { useMediaQueries, useSelector, useCallback } from 'hooks'

import { processError } from 'helpers/errors'

import Segment from 'components/atoms/Segment/Segment.jsx'
import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Button from 'components/_old/Button/Button.jsx'

import { FinancesSetupForm } from './FinancesSetupForm'

const FinancesSetup = ({ changeClientField, updateClient, handleAfterSubmit }) => {
  const { desktop } = useMediaQueries()
  const { wealth_source, employment_status } = useSelector((state) => state.client)

  const handleSubmit = useCallback(
    async (keys) => {
      const state = await updateClient(keys)

      trackEvent(
        {
          action: 'financial_info_submitted',
          wealth_source,
          employment_status,
        },
        { plugins: { 'google-analytics-v3': false } },
      )

      if (!state.result) {
        return processError({ error: state.error })
      }

      handleAfterSubmit()
    },
    [wealth_source, employment_status, updateClient, handleAfterSubmit],
  )

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <Segment mods={{ margin: 'huge', noMargin: 'bottom' }}>{submitButton}</Segment>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  return (
    <FinancesSetupForm
      inputMods={{ size: 'bigger' }}
      submitButton={<Button mods={{ size: 'big block' }}>Continue</Button>}
      wrapButton={wrapSubmitButton}
      onClientFieldChange={changeClientField}
      onSubmit={handleSubmit}
    />
  )
}

FinancesSetup.propTypes = {
  changeClientField: PropTypes.func,
  updateClient: PropTypes.func,
  handleAfterSubmit: PropTypes.func,
}

export { FinancesSetup }
