import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import PropTypes from 'prop-types'

import { useMemo, useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { OptionsLayout } from '../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../Options/OptionsNavigationBar'
import { useStopSubscription, Status } from '../hooks'

type StopMonthlyPaymentProps = {
  portfolio: Portfolio
  handleClose: () => void
  location?: {
    search?: string
  }
}

const StopMonthlyPayment = withRouter(
  ({ portfolio, handleClose, location }: StopMonthlyPaymentProps): React.ReactElement => {
    const { desktop } = useMediaQueries()
    const { status, handleStopPayment, handleBackToForm } = useStopSubscription(portfolio?.id, location.search)

    const [content, button] = useMemo(() => {
      let content
      let button

      if (status === Status.INITIAL) {
        content = (
          <Fragment>
            <Typography size={24} align="center" weight="semibold">
              <Typo>Stop monthly payment</Typo>
            </Typography>
            <Paper top={desktop ? 32 : 12}>
              <Typography align="center">
                <Typo>Are you sure you want to stop monthly payment?</Typo>
              </Typography>
            </Paper>
          </Fragment>
        )
        button = (
          <Button onClick={handleStopPayment} mods={{ size: 'big block' }} data-test-id="stopMonthlyPaymentButton">
            Stop payment
          </Button>
        )
      }

      if (status === Status.SUCCESS) {
        content = (
          <Width size={desktop ? 30 : null} center>
            <AllCenter>
              <Card mods={{ text: 'center', theme: 'transparent', padding: 'big' }}>
                <AllCenter>
                  <Icon type="success-64" color={palette['status-success']} />
                </AllCenter>
              </Card>
              <Headline level={desktop ? 1 : 3} mods={{ 'no-margin': 'top', text: 'center' }}>
                Monthly payment has been stopped
              </Headline>
            </AllCenter>
          </Width>
        )
        button = (
          <Button
            onClick={handleBackToForm}
            mods={{ size: 'big block' }}
            data-test-id="continueAfterMonthlyPaymentIsStopped"
          >
            Continue
          </Button>
        )
      }
      return [content, button]
    }, [desktop, status, handleStopPayment, handleBackToForm])

    return (
      <OptionsLayout
        center
        header={
          <OptionsNavigationBar
            leftPartText="Back"
            onLeftPartClick={status === Status.INITIAL ? handleBackToForm : undefined}
            rightPartText="Close"
            onRightPartClick={handleClose}
          >
            &nbsp;
          </OptionsNavigationBar>
        }
        content={content}
        button={button}
        data-test-id="stopMonthlyPayment"
      />
    )
  },
)

StopMonthlyPayment.propTypes = {
  portfolio: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export { StopMonthlyPayment }
