import * as React from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { palette } from 'helpers/palette'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { useTransferIsaForm } from 'app/pages/Dashboard/Goals/hooks'

type TransferIsaBlockProps = {
  mode: 'sidebar' | 'wide'
  portfolioId: string
}

const TransferIsaBlock = ({ mode = 'sidebar', portfolioId }: TransferIsaBlockProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { onlineFormProps } = useTransferIsaForm({ portfolioId })

  const headline = useMemo(
    () => (
      <Typography size={24} weight="semibold" lineHeight="small" align="center">
        <Typo>Transfer an existing ISA to InvestEngine</Typo>
      </Typography>
    ),
    [],
  )

  const button = useMemo(
    () => (
      <Button
        type="button"
        mods={{ size: 'big block' }}
        onClick={onlineFormProps?.onClick ?? null}
        data-test-id="isaTransferForm"
      >
        <Link {...onlineFormProps}>Complete online form</Link>
      </Button>
    ),
    [onlineFormProps],
  )

  const closer = (() => {
    const closeIconProps = {
      tabIndex: 0,
      onClick: onlineFormProps?.onClose,
      'data-test-id': 'transferIsaBlockCloser',
    }

    return (
      <Link className="_new_Modal-Close _new_Modal-CloseCross" mods={{ color: 'gray' }} {...closeIconProps}>
        <Icon type="cross-thin" color="inherit" />
      </Link>
    )
  })()

  if (desktop && mode === 'sidebar') {
    return (
      <Card>
        {closer}
        <Paper top={24} right={24} bottom={24} left={24}>
          <AllCenter>
            <Icon type="isa-64" size={64} color={palette['content-on-background-primary']} />
          </AllCenter>
          <Paper top={16}>{React.cloneElement(headline, { size: 20 })}</Paper>
          <Paper top={24}>
            <Typography align="center">{React.cloneElement(button, { mods: {} })}</Typography>
          </Paper>
        </Paper>
      </Card>
    )
  }

  if (desktop && mode === 'wide') {
    return (
      <Card data-test-id="transferIsaBlockWide">
        {closer}
        <Paper top={48} right={64} bottom={48} left={64}>
          <Width size={40} center>
            <AllCenter>
              <Icon type="isa-64" size={64} color={palette['content-on-background-primary']} />
            </AllCenter>
            <Paper top={16}>{React.cloneElement(headline, { size: 32, weight: 'regular' })}</Paper>
            <Paper top={32}>
              <Width size={15} center>
                {button}
              </Width>
            </Paper>
          </Width>
        </Paper>
      </Card>
    )
  }

  return (
    <Card>
      {closer}
      <Paper top={32} right={24} bottom={32} left={24}>
        <AllCenter>
          <Icon type="isa-64" size={64} color={palette['content-on-background-primary']} />
        </AllCenter>
        <Paper top={16}>{headline}</Paper>
        <Paper top={32}>{button}</Paper>
      </Paper>
    </Card>
  )
}

export { TransferIsaBlock }
