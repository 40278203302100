const types = {
  INDIVIDUAL: 'INDIVIDUAL',
  BUSINESS: 'SME',
} as const

type ClientType = (typeof types)[keyof typeof types]

const states = {
  NEW: 'NEW',
  NOT_COMPLETED: 'NOT_COMPLETED',
  COMPLETED: 'COMPLETED',
  APPROVED: 'APPROVED',
  CLOSING: 'CLOSING',
  CLOSED: 'CLOSED',
}

const wealthSources = {
  SALARY_OR_BONUS: 'SALARY_OR_BONUS',
  SALE_OF_BUSINESS_OR_SHARES: 'SALE_OF_BUSINESS_OR_SHARES',
  SALE_OF_PROPERTY: 'SALE_OF_PROPERTY',
  INHERITANCE: 'INHERITANCE',
  GIFT: 'GIFT',
  SAVING_OR_PENSION: 'SAVING_OR_PENSION',
  OTHER: 'OTHER',
}

const employmentStatuses = {
  EMPLOYED: 'EMPLOYED', // 0
  SELF_EMPLOYED: 'SELF_EMPLOYED', // 1
  NOT_EMPLOYED: 'NOT_EMPLOYED', // 2
  RETIRED: 'RETIRED', // 3
}

export { types, states, wealthSources, employmentStatuses, type ClientType }
