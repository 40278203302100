import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { modsToClassnames } from 'helpers/classname.js'

import './Segment.css'

const Segment = ({ children, mods = {} }) => {
  if (!mods) {
    mods = {}
  }
  if (!mods.margin) {
    mods.margin = 'normal'
  }

  const classes = classNames('Segment', modsToClassnames('Segment', mods))

  return <div className={classes}>{children}</div>
}

Segment.propTypes = {
  children: PropTypes.node,
  mods: PropTypes.shape({
    margin: PropTypes.oneOf([
      'smallest',
      'smaller',
      'small',
      'normal',
      'bigger',
      'big',
      'biggest',
      'biggest-equal',
      'hefty',
      'huge',
      'double',
    ]),
    noMargin: PropTypes.oneOf(['top', 'bottom', 'top bottom', 'all']),
  }),
}

export default Segment
