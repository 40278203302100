import React from 'react'

import './PercentageScale.css'

type ScaleItem = {
  percentage: number
  color: string
}

type PercentageScaleProps = {
  items: ScaleItem[]
  height?: number
}

const PercentageScale = ({ items, height = 4 }: PercentageScaleProps): React.ReactElement => {
  return (
    <div className="PercentageScale" style={{ height: `${height}px` }}>
      {items.map((item, index) => (
        <div
          key={index}
          className="PercentageScale_Item"
          style={{ width: `${item.percentage}%`, backgroundColor: item.color }}
        />
      ))}
    </div>
  )
}

export { PercentageScale }
