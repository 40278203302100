import React from 'react'

import { SelectProductType } from './SelectProductType.jsx'

export default {
  module: 'product-type',
  path: 'product-type/',
  component: function Component(props) {
    return <SelectProductType {...props} />
  },
}
