import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

export class SecurityTotalReturnsStatsItem extends Entity {
  return_money: number | null
  return_percent: number | null

  constructor() {
    super()
    this.return_money = null
    this.return_percent = null
  }

  getRules(): {
    return_money: [[(return_money) => number | null]]
    return_percent: [[(return_percent) => number | null]]
  } {
    return {
      return_money: [[rules.float]],
      return_percent: [[rules.float]],
    }
  }
}
