import React from 'react'

import { useMediaQueries, useSelector } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { navigateToIsaTransferFlowByClient } from 'helpers/navigateToIsaTransferFlowByClient'
import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router'

import { closeNotificationFx } from 'app/effector/notifications'

import { showFailToast } from 'app/redux/actions/ui'

import Icon from 'components/_old/Icon/Icon.jsx'

import { Paper } from 'components/atoms/Paper'

import { states } from 'constants/client'

import './IsaTransferPanel.css'

const IsaTransferPanel = ({ title, image, code }): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const client = useSelector((state) => state.client)

  const navigateToFlow = (): void => {
    trackEvent({ action: 'banner_clicked', code })
    if (client.state === states.COMPLETED) {
      goTo(urlTo('dashboard', null, { finishRegistration: true }))
    } else {
      navigateToIsaTransferFlowByClient(client)
    }
  }

  const closePanel = (): void => {
    try {
      closeNotificationFx(code)
    } catch (error) {
      showFailToast()
    }
  }

  return (
    <div className="IsaTransferPanel" onClick={navigateToFlow}>
      <Paper className="IsaTransferPanel_content" top={8} bottom={8}>
        <Paper top={desktop ? 16 : 12} bottom={desktop ? 16 : 12} left={desktop ? 40 : 24}>
          {title}
        </Paper>
        {image && (
          <Paper left={desktop ? 24 : 0} flex>
            <img width="116px" height="116px" src={image} />
          </Paper>
        )}
        <Paper
          top={8}
          right={8}
          left={desktop ? 8 : 0}
          style={{ alignSelf: 'start' }}
          onClick={(event) => {
            event.stopPropagation()
            closePanel()
          }}
        >
          <Icon type="cross-16" color={palette['content-on-color-white']} size={16} />
        </Paper>
      </Paper>
    </div>
  )
}

export { IsaTransferPanel }
