import { createEffect, createEvent, createStore, combine } from 'effector'

import { makeApiCall } from 'app/effector/api'

import * as api from './api'
import { ReportList, ReportTypeList, ReportRequest, type ReportRequestTimePeriod, type ReportFormat } from './models'

// Events
const createNewReportRequest = createEvent()
const fillReportRequestType = createEvent<string>()
const fillReportRequestTime = createEvent<ReportRequestTimePeriod.CUSTOM | [dateFrom: Date, dateTo: Date]>()
const fillReportRequestPortfolioIds = createEvent<number[] | null>()
const fillReportRequestFormat = createEvent<ReportFormat>()
const goReportRequestPrevStep = createEvent()
const resetReportRequestCurrentStep = createEvent()

// Effects
const fetchReportsFx = createEffect(async () => await makeApiCall(api.fetchReports))
const fetchReportTypesFx = createEffect(async () => await makeApiCall(api.fetchReportTypes))
const sendReportRequestFx = createEffect(
  async (dto: api.ReportRequestDTO) => await makeApiCall(api.createReportRequest, dto),
)

// Stores
const $reports = createStore<ReportList>([])
$reports.on(fetchReportsFx.doneData, (state, items) => new ReportList(...items))
$reports.on(sendReportRequestFx.doneData, (state, items) => new ReportList(...items, ...state))

const $areReportsFetched = createStore<boolean>(false)
$areReportsFetched.on(fetchReportsFx.done, () => true)

const $reportTypes = createStore<ReportTypeList>([])
$reportTypes.on(fetchReportTypesFx.doneData, (state, items) => new ReportTypeList(...items))

const $areReportTypesFetched = createStore<boolean>(false)
$areReportTypesFetched.on(fetchReportTypesFx.done, () => true)

const $reportRequest = createStore<ReportRequest>(new ReportRequest({}, $reportTypes.getState()))
$reportRequest.on(fetchReportTypesFx.doneData, (_, items) => new ReportRequest({}, new ReportTypeList(...items)))
$reportRequest.on(sendReportRequestFx.done, () => new ReportRequest({}, $reportTypes.getState()))
$reportRequest.on(createNewReportRequest, () => new ReportRequest({}, $reportTypes.getState()))
$reportRequest.on(fillReportRequestType, (state, type) => state.fillType(type))
$reportRequest.on(fillReportRequestTime, (state, argument) => state.fillTime(argument))
$reportRequest.on(fillReportRequestPortfolioIds, (state, portfolioIds) => state.fillPortfolioIds(portfolioIds))
$reportRequest.on(fillReportRequestFormat, (state, format) => state.fillFormat(format))
$reportRequest.on(goReportRequestPrevStep, (state) => state.goPrevStep())
$reportRequest.on(resetReportRequestCurrentStep, (state) => state.resetCurrentStep())

const $isLoading = combine(
  fetchReportsFx.pending,
  fetchReportTypesFx.pending,
  sendReportRequestFx.pending,
  (...pendingEffects) => pendingEffects.some((pending) => pending),
)

const $isFetched = combine($areReportsFetched, $areReportTypesFetched, (...fetchedStores) =>
  fetchedStores.every((areFetched) => areFetched),
)

const $reportsStore = combine({
  reports: $reports,
  reportTypes: $reportTypes,
  reportRequest: $reportRequest,
  isLoading: $isLoading,
  isFetched: $isFetched,
})

export {
  // Events
  createNewReportRequest,
  fillReportRequestType,
  fillReportRequestTime,
  fillReportRequestPortfolioIds,
  fillReportRequestFormat,
  goReportRequestPrevStep,
  resetReportRequestCurrentStep,
  // Effects
  fetchReportsFx,
  fetchReportTypesFx,
  sendReportRequestFx,
  // Store
  $reportsStore,
}
