import React from 'react'
import PropTypes from 'prop-types'

import useMediaQueries from 'hooks/useMediaQueries.js'

import Label from 'components/_old/Label/Label.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Slider from 'components/_old/Slider/Slider.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import './TimePeriodField.css'

const TimePeriodField = ({
  label,
  value,
  scale,
  'data-test-id': dataTestId,

  onChange,
}) => {
  const { desktop } = useMediaQueries()

  const caption = (
    <ColumnarLayout fluid>
      {desktop && (
        <Column>
          <Text smaller block lineheight="one-line" color="lightgray">
            {label}
          </Text>
        </Column>
      )}
      <Column>
        <Text smaller block lineheight="one-line" right={desktop}>{`${value} years`}</Text>
      </Column>
    </ColumnarLayout>
  )

  return (
    <div className="TimePeriodField">
      <Label fluid style={{ marginTop: desktop ? null : '-1em' }}>
        {desktop ? <div>&nbsp;</div> : null}
        <Slider
          value={value}
          valueKey="term"
          scale={scale}
          valueAsCaption={caption}
          onChange={onChange}
          withBars
          data-test-id={dataTestId}
        />
      </Label>
    </div>
  )
}

TimePeriodField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  scale: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  'data-test-id': PropTypes.string,
}

export default TimePeriodField
