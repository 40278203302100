import React, { useEffect } from 'react'

let tunnel = []
let tunnelQuery = null

function setTunnel(nextTunnel) {
  tunnel = nextTunnel
}

function setTunnelQuery(query) {
  if (!query) {
    query = {}
  }

  const blacklist = ['allowNotifications', 'finishRegistration']

  tunnelQuery = Object.entries(query)
    .filter(([key]) => !blacklist.find((blackKey) => blackKey === key))
    .reduce((nextQuery, [key, value]) => ({ ...nextQuery, [key]: value }), {})
}

function resetTunnel() {
  setTunnel([])
  setTunnelQuery({})
}

function takeNextStep() {
  const [nextStep, ...nextTunnel] = tunnel
  setTunnel(nextTunnel)
  return nextStep
}

function updateTunnel({ shouldRunNextStep, shouldReset }) {
  if (tunnel.length > 0) {
    if (shouldRunNextStep) {
      const runNextStep = takeNextStep()

      runNextStep()
      return true
    }
    if (shouldReset) {
      resetTunnel()
      return true
    }
  }
  return false
}

const ChangeGoalTunnelContext = React.createContext([])

const ChangeGoalTunnelProvider = ({ children } = {}) => {
  useEffect(() => {
    resetTunnel()

    return () => {
      resetTunnel()
    }
  }, [])

  return (
    <ChangeGoalTunnelContext.Provider
      value={{ tunnel, tunnelQuery, setTunnel, setTunnelQuery, resetTunnel, takeNextStep, updateTunnel }}
    >
      {children}
    </ChangeGoalTunnelContext.Provider>
  )
}
ChangeGoalTunnelProvider.displayName = 'ChangeGoalTunnelProvider'

export { ChangeGoalTunnelContext, ChangeGoalTunnelProvider }
