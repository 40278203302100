import { useCallback, useAsyncEffect, useEffect, useRef, useState } from 'hooks'

import { goTo } from 'helpers/router.js'

import { showFailToast } from 'app/redux/actions/ui'
import { connectThirdParty } from 'app/redux/api/third-party'
import { ApiError } from 'app/redux/models/errors'

type UseThirdPartyAuthProps = {
  query: {
    accountCreated?: string
    client_id?: string
    state?: string
    redirect_uri?: string
  }
}

type UseThirdPartyAuthData = {
  isLoading: boolean
  handleContinue: () => void
}

const useThirdPartyAuth = ({ query = {} }: UseThirdPartyAuthProps): UseThirdPartyAuthData => {
  const [isLoading, setIsLoading] = useState(true)
  const [redirectUri, setRedirectUri] = useState('')

  const timeout = useRef<NodeJS.Timeout | null>(null)

  const cleanup = (): void => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  }

  useEffect(() => cleanup, [])

  useAsyncEffect(async () => {
    const { accountCreated, client_id: starlingClientId, state, redirect_uri: starlingRedirectUri } = query

    try {
      const starlingData = await connectThirdParty({
        client_id: starlingClientId,
        state,
        redirect_uri: starlingRedirectUri,
      })

      if (starlingData instanceof ApiError) {
        throw starlingData
      }

      if (accountCreated === 'true') {
        timeout.current = setTimeout(() => {
          goTo(starlingData?.redirect_uri, { hardGoTo: true, unsafe: true })
        }, 3000)
        setRedirectUri(starlingData?.redirect_uri)
        setIsLoading(false)
        return
      }

      goTo(starlingData?.redirect_uri, { hardGoTo: true, unsafe: true })
    } catch (error) {
      showFailToast()
    }
  }, [])

  const handleContinue = useCallback(() => {
    goTo(redirectUri, { hardGoTo: true, unsafe: true })
  }, [redirectUri])

  return { isLoading, handleContinue }
}

export { useThirdPartyAuth }
