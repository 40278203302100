import React, { Fragment } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import { getRandomInteger } from 'helpers/number'
import { goTo, urlTo } from 'helpers/router.js'

import type { QuickStart } from 'app/effector/quickStart/types'

import type { Preset } from 'app/redux/models/portfolio/types'

import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Document } from 'components/molecules/Document'
import { NewPeriodSelect, type Period } from 'components/molecules/NewPeriodSelect'

import { SecurityInfo } from 'components/organisms/SecurityInfo'

import { LumpSumBanner } from 'app/pages/Dashboard/Goals/components/LumpSumBanner'
import { SavingsPlanLabel } from 'app/pages/Dashboard/Goals/components/SavingsPlanLabel'
import { SavingsPlanSetupBanner } from 'app/pages/Dashboard/Goals/components/SavingsPlanSetupBanner'
import { TransferIsaBlock } from 'app/pages/Dashboard/Goals/components/TransferIsaBlock'
import { ActionKey } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'

import { OnboardingProgressBar } from '../../../DIY/Portfolio/components/OnboardingProgressBar/OnboardingProgressBar'
import { ControlButtons } from '../ControlButtons'

import { Allocations } from './components/Allocations'
import { Balance } from './components/Balance'
import { Graphs } from './components/Graphs/Graphs'
import { HmrcCashLabel } from './components/HmrcCashLabel'
import { Notifications } from './components/Notifications'

import type { ManagedPortfolio, AllocationItem } from '../../types'

import { regulatoryTypes } from 'constants/portfolio'

type OverviewProps = {
  isPortfolioLoading: boolean
  portfolio: ManagedPortfolio
  onSecurityClick: (securityId: string) => void
  location: {
    query: {
      view: string
      time: Period
    }
  }
  actionsMap: object
  showFinishSavingsPlanBanner: boolean
  showLumpSumBanner: boolean
  quickStart: QuickStart
  allocationData: AllocationItem[]
  showSecuritiesSkeleton: boolean
  preset: Preset
}

const skeletonsNumber = getRandomInteger(4, 7)

const Overview = ({
  isPortfolioLoading,
  portfolio,
  onSecurityClick,
  location,
  actionsMap,
  showFinishSavingsPlanBanner,
  showLumpSumBanner,
  quickStart,
  allocationData,
  showSecuritiesSkeleton,
  preset,
}: OverviewProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const isTransferIsaFormVisible = useMemo(
    () => portfolio?.isa?.transferring_in_process,
    [portfolio?.isa?.transferring_in_process],
  )
  const skeletons = new Array(skeletonsNumber).fill({})

  const { time: currentPeriod = 'max' } = location.query
  const handleTabClick = (period): void => {
    goTo(urlTo(`dashboard.portfolio`, { id: portfolio.id }, { time: period }), {
      replace: true,
      scrollToTop: false,
    })
  }

  const transferIsaNode = <TransferIsaBlock mode="sidebar" portfolioId={portfolio.id} reference={portfolio.reference} />

  const periodSelectNode = (
    <NewPeriodSelect
      selectedPeriod={currentPeriod}
      handlePeriodSelect={(period) => {
        handleTabClick(period)
      }}
    />
  )

  const pendingHmrcCashNode = portfolio?.regulatory_type === regulatoryTypes.SIPP && (
    <Paper bottom={12}>
      <HmrcCashLabel amount={portfolio?.pending_hmrc_cash} />
    </Paper>
  )

  const savingsPlanSetupBannerNode = useMemo(
    () =>
      showFinishSavingsPlanBanner && (
        <Paper top={desktop ? 0 : 8} bottom={desktop ? 56 : 32}>
          <SavingsPlanSetupBanner portfolio={portfolio} quickStart={quickStart} />
        </Paper>
      ),
    [desktop, showFinishSavingsPlanBanner, quickStart, portfolio],
  )

  const lumpSumBannerNode = useMemo(
    () =>
      showLumpSumBanner && (
        <Paper top={desktop ? 0 : 8} bottom={desktop ? 56 : 32}>
          <LumpSumBanner portfolio={portfolio} quickStart={quickStart} />
        </Paper>
      ),
    [desktop, showLumpSumBanner, quickStart, portfolio],
  )

  const onboardingProgressBar = <OnboardingProgressBar portfolio={portfolio} />

  const kiidLink = portfolio?.is_default_preset_used
    ? 'https://public.investengine.com/pdf/Retirement%20Portfolio%20KFD.pdf'
    : preset?.kiid

  const kiidNode = kiidLink && (
    <Paper bottom={48}>
      <Typography size={24} lineHeight="small" weight="semibold">
        <Typo>Key information</Typo>
      </Typography>
      <Paper top={24}>
        <Document name="Key Features document" src={kiidLink} />
      </Paper>
    </Paper>
  )

  if (desktop)
    return (
      <Fragment>
        <Notifications portfolioId={portfolio.id} />
        <Paper top={56}>
          <ColumnarLayout mods={{ padding: 'no' }}>
            <Column size={1}>
              {savingsPlanSetupBannerNode}
              {lumpSumBannerNode}
              {portfolio?.first_topup && (
                <Paper bottom={56}>
                  <Graphs
                    isPortfolioLoading={isPortfolioLoading}
                    portfolio={portfolio}
                    location={location}
                    currentPeriod={currentPeriod}
                    periodSelectNode={periodSelectNode}
                    handleAddFundsClick={() => actionsMap[ActionKey.ADD_FUNDS].onClick()}
                  />
                </Paper>
              )}
              {kiidNode}
              {showSecuritiesSkeleton ? (
                skeletons.map((_, index) => (
                  <Paper key={index} top={24}>
                    <Skeleton shown mix>
                      <SecurityInfo skeleton />
                    </Skeleton>
                  </Paper>
                ))
              ) : (
                <Allocations
                  onSecurityClick={onSecurityClick}
                  currentPeriod={currentPeriod}
                  allocationData={allocationData}
                />
              )}
            </Column>
            <Column size={0} sticky style={{ zIndex: 1 }}>
              <Paper left={80}>
                <Width size={19}>
                  {onboardingProgressBar}
                  <Card>
                    <Paper top={24} bottom={24} left={24} right={24}>
                      <Balance portfolio={portfolio} />
                      <Paper top={32}>
                        {pendingHmrcCashNode}
                        <SavingsPlanLabel portfolio={portfolio} />
                      </Paper>
                      <Paper top={32}>
                        <ControlButtons portfolio={portfolio} actionsMap={actionsMap} />
                      </Paper>
                    </Paper>
                  </Card>
                  {isTransferIsaFormVisible && <Paper top={40}>{transferIsaNode}</Paper>}
                </Width>
              </Paper>
            </Column>
          </ColumnarLayout>
        </Paper>
      </Fragment>
    )

  return (
    <Fragment>
      <Notifications portfolioId={portfolio.id} />
      {onboardingProgressBar}
      <Paper top={24} bottom={16}>
        <Balance portfolio={portfolio} />
      </Paper>
      {pendingHmrcCashNode}
      <Paper bottom={32}>
        <SavingsPlanLabel portfolio={portfolio} />
      </Paper>
      {savingsPlanSetupBannerNode}
      {lumpSumBannerNode}
      {portfolio?.first_topup && (
        <SideSpacesCompensator>
          <Paper top={16} bottom={32}>
            <Graphs
              portfolio={portfolio}
              location={location}
              currentPeriod={currentPeriod}
              periodSelectNode={periodSelectNode}
              handleAddFundsClick={() => actionsMap[ActionKey.ADD_FUNDS].onClick()}
            />
          </Paper>
        </SideSpacesCompensator>
      )}
      {kiidNode}
      <Paper>
        {showSecuritiesSkeleton ? (
          skeletons.map((_, index) => (
            <Paper key={index} top={12}>
              <Skeleton shown mix>
                <SecurityInfo />
              </Skeleton>
            </Paper>
          ))
        ) : (
          <Allocations
            onSecurityClick={onSecurityClick}
            currentPeriod={currentPeriod}
            allocationData={allocationData}
          />
        )}
        {isTransferIsaFormVisible && <Paper top={56}>{transferIsaNode}</Paper>}
      </Paper>
    </Fragment>
  )
}

export { Overview }
