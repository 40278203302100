import React from 'react'

import PropTypes from 'prop-types'

import { useCallback, useMediaQueries, useMemo } from 'hooks'

import { propTypes } from 'helpers/propTypes'

import { BankAccount } from 'app/effector/bank-accounts/models/bank'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Card } from 'components/atoms/Card'
import { NonSelectableText } from 'components/atoms/NonSelectableText'
import { Paper } from 'components/atoms/Paper'
import { PaymentDetailLogo } from 'components/atoms/PaymentDetail'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { bankAccountStates } from 'constants/bankAccounts.js'

const BankAccountCard = ({ bankAccount, selectable, selected, error, description, onClick, onUploadLinkClick }) => {
  const { desktop } = useMediaQueries()

  const { id, bank, sort_code, account_number } = bankAccount ?? {}

  const isAwaitingApproval = bankAccount?.state === bankAccountStates.AWAITING_APPROVAL
  const isDocumentUploadNeeded =
    bankAccount?.state === bankAccountStates.AWAITING_APPROVAL && !bankAccount?.bank_statement_filled
  const isDocumentUploaded =
    bankAccount?.state === bankAccountStates.AWAITING_APPROVAL && bankAccount?.bank_statement_filled

  const title = useMemo(
    () => (
      <Typography size={desktop ? 16 : 18} weight="semibold" lineHeight="small" data-test-id="bankAccountTitle">
        <Typo>{bank?.title}</Typo>
      </Typography>
    ),
    [desktop, bank?.title],
  )

  const sortCode = useMemo(
    () => (
      <Paper top={8}>
        <Typography size={14} color="minor" lineHeight="small">
          <Typo>
            Sort code <span data-test-id="sortCodeValue">{sort_code}</span>
          </Typo>
        </Typography>
      </Paper>
    ),
    [sort_code],
  )

  const accountNumber = useMemo(
    () => (
      <Paper top={8}>
        <Typography size={14} color="minor" lineHeight="small">
          <Typo>
            Account number <span data-test-id="accountNumberValue">{account_number}</span>
          </Typo>
        </Typography>
      </Paper>
    ),
    [account_number],
  )

  const awaitingApproval = useMemo(
    () =>
      isAwaitingApproval ? (
        <Paper top={8}>
          <Typography size={14} color="warning" lineHeight="small" data-test-id="bankAccountWarningText">
            <Typo>Awaiting verification</Typo>
          </Typography>
        </Paper>
      ) : null,
    [isAwaitingApproval],
  )

  const documentUploadLink = useMemo(() => {
    if (isDocumentUploadNeeded) {
      const handleUploadLinkClick = (event) => {
        if (typeof onUploadLinkClick === 'function') {
          onUploadLinkClick(id)
        }
        event.stopPropagation()
      }

      return (
        <Paper top={8}>
          <Typography size={14} lineHeight="small">
            <Link onClick={handleUploadLinkClick} stopPropagation={true}>
              Upload bank statement
            </Link>
          </Typography>
        </Paper>
      )
    }

    return null
  }, [id, isDocumentUploadNeeded, onUploadLinkClick])

  const errorText = useMemo(
    () =>
      error ? (
        <Paper top={8}>
          <Typography color="error" size={14} lineHeight="small">
            <Typo>{error}</Typo>
          </Typography>
        </Paper>
      ) : null,
    [error],
  )

  const descriptionText = useMemo(
    () =>
      description ? (
        <Paper top={24}>
          <Typography size={14} lineHeight="small">
            <Typo>{description}</Typo>
          </Typography>
        </Paper>
      ) : null,
    [description],
  )

  const handleClick = useCallback(() => {
    if (isDocumentUploadNeeded && typeof onUploadLinkClick === 'function') {
      onUploadLinkClick(id)
    }
    if (typeof onClick === 'function') {
      onClick(id)
    }
  }, [id, onClick, onUploadLinkClick])

  const content = (
    <Paper top={24} bottom={24} right={desktop ? 24 : 20} left={desktop ? 24 : 20}>
      <ColumnarLayout fluid>
        <Column size={1}>
          {title}
          {sortCode}
          {accountNumber}
          {awaitingApproval}
          {documentUploadLink}
          {errorText}
        </Column>
        <Column size={0}>
          <PaymentDetailLogo src={bank?.logo_uri} width={desktop ? '44' : '36'} />
        </Column>
      </ColumnarLayout>
      {descriptionText}
    </Paper>
  )

  if (selectable && !isDocumentUploaded) {
    return (
      <SelectableCard selected={selected} error={Boolean(error)} onClick={handleClick} data-test-id="bankAccount">
        {desktop ? content : <NonSelectableText>{content}</NonSelectableText>}
      </SelectableCard>
    )
  }

  return (
    <Card level={0} data-test-id="bankAccount">
      {content}
    </Card>
  )
}

BankAccountCard.propTypes = {
  bankAccount: propTypes.instanceOf(BankAccount),
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  error: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  onUploadLinkClick: PropTypes.func,
}

export { BankAccountCard }
