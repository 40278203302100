import * as cards from './cards.js'

const MANAGED_TYPE = 'MANAGED_TYPE'

const BUSINESS = {
  preselectedData: [],
  steps: [[MANAGED_TYPE, [cards.DIY, cards.SELF_SELECTED, cards.MANAGED], 'Choose investment service']],
}

export { BUSINESS }
