import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { usePortfolioActions, ActionKey } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'

import { type ManagedPortfolio } from '../../types'

type FundYourPortfolioProps = {
  portfolio: ManagedPortfolio
  handleAmendPortfolioSelect: () => void
}

const FundYourPortfolio = ({ portfolio, handleAmendPortfolioSelect }: FundYourPortfolioProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { actionsMap } = usePortfolioActions(portfolio, handleAmendPortfolioSelect)

  const addFundsButtonNode = (
    <Button
      mods={{ size: desktop ? 'big block' : 'new-small' }}
      disabled={!actionsMap[ActionKey.ADD_FUNDS].enabled}
      onClick={() => {
        actionsMap[ActionKey.ADD_FUNDS].onClick()
      }}
      data-test-id="portfolioAddFundsButton"
    >
      Add Funds
    </Button>
  )

  const contentNode = (
    <Fragment>
      <Paper bottom={20}>
        <Typography size={18} align="center" weight="semibold">
          <Typo>Fund your portfolio now</Typo>
        </Typography>
      </Paper>
      {addFundsButtonNode}
    </Fragment>
  )

  return desktop ? (
    <AllCenter>
      <Width size={19}>
        <Card>
          <Paper top={24} left={24} right={24} bottom={24}>
            {contentNode}
          </Paper>
        </Card>
      </Width>
    </AllCenter>
  ) : (
    <AllCenter>{contentNode}</AllCenter>
  )
}

export { FundYourPortfolio }
