import PropTypes from 'prop-types'

/**
 * @param {function[]} types
 * @param {boolean=false} single
 * @return {(function(): null)|*}
 */
const childrenOfType = (types, single = false) => {
  const fieldType = PropTypes.shape({
    type: PropTypes.oneOf(types),
  })

  if (single) {
    return fieldType
  }

  return PropTypes.oneOfType([fieldType, PropTypes.arrayOf(fieldType)])
}

export { childrenOfType }
