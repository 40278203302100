import React from 'react'
import PropTypes from 'prop-types'

import { trackEvent } from 'helpers/analytics'

import { useMediaQueries, useRedirect } from 'hooks'
import { useUnit } from 'effector-react'

import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Card from 'components/_old/Card/Card.jsx'

import AddressForm from 'app/pages/Forms/AddressForm.jsx'

import { $isLoading } from 'app/effector/contacts'

const AddressSetup = ({ prevStep, owner, updateAddresses }) => {
  const isContactsLoading = useUnit($isLoading)

  useRedirect({
    to: prevStep?.module ?? 'setup-account',
    rule: !owner.birthday,
    isLoading: isContactsLoading,
  })

  const { desktop } = useMediaQueries()

  const handleSubmit = async (...args) => {
    trackEvent(
      {
        action: 'residential_address_submitted',
        client_type: 'INDIVIDUAL',
      },
      { plugins: { 'google-analytics-v3': false } },
    )
    updateAddresses(...args)
  }

  const wrapSubmitButton = (submitButton) =>
    desktop ? (
      <Card
        mods={{
          theme: 'transparent',
          'no-padding': 'top',
        }}
      >
        {submitButton}
      </Card>
    ) : (
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    )

  return <AddressForm wrapSubmitButton={wrapSubmitButton} onSubmit={handleSubmit} />
}

AddressSetup.propTypes = {
  prevStep: PropTypes.object,
  owner: PropTypes.object,
  updateAddresses: PropTypes.func,
}

export { AddressSetup }
