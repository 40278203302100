import React, { Fragment } from 'react'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

type PortfolioHeaderProps = {
  handleBack: () => void
  goalTitle: string
  regulatoryTypeText: string
  isDefaultPortfolio?: boolean
}

const PortfolioHeader = ({
  handleBack,
  goalTitle,
  regulatoryTypeText,
  isDefaultPortfolio,
}: PortfolioHeaderProps): React.ReactElement => {
  return (
    <Fragment>
      <Paper top={8}>
        <ColumnarLayout mods={{ padding: 'no', 'column-content': 'center' }}>
          <Column size={0}>
            <Link onClick={handleBack} mods={{ color: 'content-on-background-default' }}>
              <ItemWithIcon
                space={16}
                icon={<Icon type="back-new" size={20} color="inherit" />}
                content={<Typography color="inherit">Back</Typography>}
                iconVerticalAlign="center"
                data-test-id="navigationLeftButton"
              />
            </Link>
          </Column>
          <Column size={1}>
            <Typography size={24} weight="semibold" align="center" lineHeight="small" data-test-id="goalTitle">
              {goalTitle}
            </Typography>
            <Paper top={8}>
              <AllCenter>
                <ItemWithIcon
                  space={8}
                  icon={<Icon type="logo-48" size={24} color={palette['primary-default']} />}
                  iconVerticalAlign="center"
                  contentVerticalAlign="center"
                  content={
                    isDefaultPortfolio ? (
                      <Typography size={16} lineHeight="small" color="minor">
                        Retirement Portfolio by IE
                      </Typography>
                    ) : (
                      <Typography size={16} lineHeight="small" color="minor">
                        Managed by InvestEngine, {regulatoryTypeText}
                      </Typography>
                    )
                  }
                  data-test-id="portfolioManagedType"
                  inline
                />
              </AllCenter>
            </Paper>
          </Column>
        </ColumnarLayout>
      </Paper>
    </Fragment>
  )
}

export { PortfolioHeader }
