import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { goTo, urlTo } from 'helpers/router.js'
import { sendError } from 'helpers/errorLogging'

import { useActions, useCallback, useSelector, useMemo } from 'hooks'

import { NewOptimalPortfolioModal } from '../NewOptimalPortfolioModal'
import {
  IncorrectPortfolioModalContent,
  NotChangedPortfolioModalContent,
  // PortfolioChangedModalContent,
  NewPortfolioChangedModalContent,
  PortfolioNotSwitchedModalContent,
  PortfolioSwitchedModalContent,
  RiskAttentionModalContent,
} from '../NewOptimalPortfolioModal/parts'
import Text from 'components/_old/Text/Text.jsx'

import { showSupportModal as showSupportModalActionCreator } from 'app/redux/actions/ui'
import { clientAnswersBatch as clientAnswersBatchActionCreator } from 'app/redux/actions/questionnaire'
import {
  updateOrCreate as updateOrCreateGoalActionCreator,
  changeField as changeGoalFieldActionCreator,
} from 'app/redux/actions/portfolios'

import { states as portfolioModalStates } from '../NewOptimalPortfolioModal/states.js'

const QuestionnaireOptimalPortfolio = ({ routes, goal, params }) => {
  const routesModule = routes[routes.length - 1].module
  const isOpen = routesModule.includes('optimal-portfolio')
  const [showSupportModalAction, clientAnswersBatchAction, updateOrCreateGoal, changeGoalField] = useActions([
    showSupportModalActionCreator,
    clientAnswersBatchActionCreator,
    updateOrCreateGoalActionCreator,
    changeGoalFieldActionCreator,
  ])
  const clientAnswers = useSelector((state) => state.questionnaire.clientAnswerList.clientAnswers)

  const clientAnswersWithGoal = useMemo(
    () => clientAnswers.map((answer) => ({ ...answer, goal_id: goal.id })),
    [clientAnswers, goal.id],
  )

  const redirectToDashboard = useCallback(() => {
    if (goal?.id) {
      goTo(urlTo('dashboard.portfolio', { id: goal.id }), { replace: true })
    }
  }, [goal.id])

  const redirectToRetake = useCallback(() => {
    goTo(urlTo('questionnaire-retake', params))
  }, [params])

  const switchToNewPortfolio = useCallback(
    async (presetRecommended) => {
      if (clientAnswersWithGoal.length > 0 && goal && presetRecommended) {
        try {
          await clientAnswersBatchAction(goal.id, clientAnswersWithGoal)
          await changeGoalField({ preset_changed: presetRecommended }, goal.id)
          await updateOrCreateGoal(['preset'], { preset: presetRecommended }, false, goal.id)
        } catch (error) {
          sendError(error)
        }
      }
    },
    [goal, clientAnswersWithGoal],
  )

  const saveClientAnswers = useCallback(async () => {
    if (clientAnswersWithGoal.length > 0 && goal) {
      try {
        await clientAnswersBatchAction(goal.id, clientAnswersWithGoal)
      } catch (error) {
        sendError(error)
      }
    }
  }, [goal, clientAnswersWithGoal])

  const renderChildrenMap = {
    [portfolioModalStates.PORTFOLIO_INCORRECT]: () => (
      <IncorrectPortfolioModalContent
        headline="We need to match you to a new portfolio"
        contentText="Please complete our risk questionnaire to see your new optimal portfolio, or contact our Support team."
        buttonText="Complete the questionnaire"
        onRetake={redirectToRetake}
        onContactSupport={showSupportModalAction}
      />
    ),
    [portfolioModalStates.PORTFOLIO_CHANGED]: ({
      /* eslint-disable react/prop-types */
      // setRootModalVisibleState,
      currentPortfolio,
      newOptimalPortfolio,
      // presetRecommended,
      /* eslint-enable react/prop-types */
    }) => (
      // <PortfolioChangedModalContent
      //   goal={goal}
      //   currentPortfolio={currentPortfolio}
      //   newOptimalPortfolio={newOptimalPortfolio}
      //   postHeadlineText="We are suggesting a change in your portfolio because you are a year older than when you completed our risk questionnaire."
      //   onSetToNewOptimal={async () => {
      //     await switchToNewPortfolio(presetRecommended)
      //     setRootModalVisibleState(portfolioModalStates.PORTFOLIO_SWITCHED)
      //   }}
      //   onDoNothing={() => setRootModalVisibleState(portfolioModalStates.PORTFOLIO_RISK_ATTENTION)}
      //   onRetake={redirectToRetake}
      // />
      <NewPortfolioChangedModalContent
        currentPortfolio={currentPortfolio}
        newOptimalPortfolio={newOptimalPortfolio}
        onSetToNewOptimal={async () => {
          await saveClientAnswers()
          redirectToDashboard()
        }}
        onRetake={redirectToRetake}
      />
    ),
    [portfolioModalStates.PORTFOLIO_NOT_CHANGED]: () => (
      <NotChangedPortfolioModalContent
        headline="Thank you for completing your InvestEngine MOT"
        contentText={<Text>You can always retake the assesment from the Profile section</Text>}
        buttonText="OK"
        onClick={async () => {
          await saveClientAnswers()
          redirectToDashboard()
        }}
      />
    ),
    [portfolioModalStates.PORTFOLIO_SWITCHED]: () => <PortfolioSwitchedModalContent onClick={redirectToDashboard} />,
    [portfolioModalStates.PORTFOLIO_NOT_SWITCHED]: () => (
      <PortfolioNotSwitchedModalContent onClick={redirectToDashboard} />
    ),
    [portfolioModalStates.PORTFOLIO_RISK_ATTENTION]: ({
      /* eslint-disable react/prop-types */
      setRootModalVisibleState,
      currentPortfolio,
      newOptimalPortfolio,
      /* eslint-enable react/prop-types */
    }) => (
      <RiskAttentionModalContent
        currentPortfolio={currentPortfolio}
        newOptimalPortfolio={newOptimalPortfolio}
        onCancel={() => setRootModalVisibleState(portfolioModalStates.PORTFOLIO_CHANGED)}
        onConfirm={async () => {
          await saveClientAnswers()
          setRootModalVisibleState(portfolioModalStates.PORTFOLIO_NOT_SWITCHED)
        }}
      />
    ),
  }

  return (
    <Fragment>
      <NewOptimalPortfolioModal
        open={isOpen}
        renderChildrenMap={renderChildrenMap}
        goal={goal}
        clientAnswers={clientAnswersWithGoal}
        onClose={redirectToDashboard}
      />
    </Fragment>
  )
}

QuestionnaireOptimalPortfolio.propTypes = {
  routes: PropTypes.array,
  goal: PropTypes.object,
  params: PropTypes.object,
}

export { QuestionnaireOptimalPortfolio }
