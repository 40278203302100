import React, { Fragment } from 'react'

import { useCallback } from 'hooks'

import { goTo } from 'helpers/router.js'

import { Logo } from 'components/_old/Logo/Logo.jsx'

import { GatewayDest } from 'components/atoms/Gateway'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Preloader } from 'components/molecules/Preloader'

type MobileProps = {
  children: React.ReactNode
  title: string
  button: React.ReactElement
  backUrl: string
  hasTabs?: boolean
  isLoading?: boolean
}

const Mobile = ({ children, title, button, backUrl, hasTabs, isLoading }: MobileProps): React.ReactElement => {
  const handleBack = useCallback(() => {
    goTo(backUrl, { hardGoTo: true })
  }, [backUrl])
  const header = (
    <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
      <Logo className="NavigationBar-Logo" style={{ height: 24 }} />
    </NavigationBar>
  )
  const footer = button ? <MobileLayoutFooterButton>{button}</MobileLayoutFooterButton> : undefined

  const content = (
    <Fragment>
      <Paper bottom={hasTabs ? 20 : 40} top={24}>
        <Typography size={24} lineHeight="small" weight="semibold" align="center">
          {title}
        </Typography>
      </Paper>
      {children}
    </Fragment>
  )

  return (
    <Fragment>
      <MobileLayout header={header} content={content} footer={footer} />
      <Preloader loading={isLoading} size="big" zIndex />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { Mobile }
