import type React from 'react'

import findIndex from 'lodash/findIndex'

import { useState, useCallback, useEffect } from 'hooks'

import { getEventElement } from 'helpers/dom'

const findChild = (
  event: React.TouchEvent,
  container: Element,
  predicate: (child: Element) => boolean,
): [number, ChildNode | null] => {
  const index = findIndex(container.childNodes, predicate)

  return index > -1 ? [index, container.childNodes[index]] : [-1, null]
}

type UseTouchProps = {
  onEnter: (event?: React.TouchEvent, childIndex?: number) => void
  onLeave: (event?: React.TouchEvent) => void
  containers: Element[]
  enterOnTouchStart?: boolean
  enterOnLongTouch?: boolean
  leaveOnTouchEnd?: boolean
  enterOnTouchMove?: boolean
  longTouchTimer?: number
}

const useTouch = ({
  onEnter: handleEnter,
  onLeave: handleLeave,
  containers = [],
  enterOnTouchStart = true,
  enterOnLongTouch = false,
  leaveOnTouchEnd = false,
  enterOnTouchMove = true,
  longTouchTimer = 500,
}: UseTouchProps): void => {
  const [currentChild, setChild] = useState<ChildNode | null>(null)

  const onTouchStart = useCallback(
    (event) => {
      containers.forEach((container) => {
        const selectedElement = getEventElement(event)

        if (!container?.contains(selectedElement)) {
          return
        }

        const [childIndex, child] = findChild(event, container, (child) => child.contains(selectedElement))
        setChild(child)

        if (child) {
          handleEnter(event, childIndex)
          return
        }

        handleLeave(event)
      })
    },
    [handleEnter, handleLeave, containers],
  )

  const onLongTouch = useCallback(
    (event) => {
      let timer

      containers.forEach((container) => {
        const selectedElement = getEventElement(event)

        if (!container?.contains(selectedElement)) {
          return
        }

        const [childIndex, child] = findChild(event, container, (child) => child.contains(selectedElement))
        setChild(child)

        if (child) {
          timer = setTimeout(() => {
            timer = null
            handleEnter(event, childIndex)
          }, longTouchTimer)

          function cancel(): void {
            clearTimeout(timer)

            document.removeEventListener('touchend', cancel)
            document.removeEventListener('touchmove', cancel)
          }

          document.addEventListener('touchend', cancel)
          document.addEventListener('touchmove', cancel)
        }
      })
    },
    [containers, longTouchTimer, handleEnter],
  )

  const onTouchMove = useCallback(
    (event) => {
      containers.forEach((container) => {
        const selectedElement = getEventElement(event)

        if (!container?.contains(selectedElement)) {
          return
        }

        if (currentChild?.contains(selectedElement)) {
          return
        }

        const [childIndex, child] = findChild(event, container, (child) => child.contains(selectedElement))
        setChild(child)

        if (child) {
          handleEnter(event, childIndex)
          return
        }

        handleLeave(event)
      })
    },
    [handleEnter, handleLeave, containers, currentChild, setChild],
  )

  const onTouchEnd = useCallback(
    (event) => {
      setChild(null)
      handleLeave(event)
    },
    [handleLeave],
  )

  useEffect(() => {
    if (enterOnTouchStart) {
      document.addEventListener('touchstart', onTouchStart)
    }

    if (enterOnLongTouch) {
      document.addEventListener('touchstart', onLongTouch)
    }

    if (enterOnTouchMove) document.addEventListener('touchmove', onTouchMove)

    if (leaveOnTouchEnd) {
      document.addEventListener('touchend', onTouchEnd)
    }

    return () => {
      document.removeEventListener('touchstart', onTouchStart)
      document.removeEventListener('touchstart', onLongTouch)
      document.removeEventListener('touchmove', onTouchMove)
      document.removeEventListener('touchend', onTouchEnd)
    }
  }, [
    enterOnTouchStart,
    onTouchStart,
    enterOnLongTouch,
    onLongTouch,
    onTouchMove,
    leaveOnTouchEnd,
    onTouchEnd,
    enterOnTouchMove,
  ])
}

export default useTouch
