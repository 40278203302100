import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import './FieldLineList.css'

const FieldLineList = ({ children, className, 'data-test-id': dataTestId }) => (
  <div className={classNames(className, 'FieldLineList')} data-test-id={dataTestId}>
    {React.Children.map(
      children,
      (child, index) =>
        child &&
        React.cloneElement(child, {
          key: index,
          className: classNames(child?.props?.className || '', 'FieldLineList-Item'),
        }),
    )}
  </div>
)

FieldLineList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
  'data-test-id': PropTypes.string,
}

export { FieldLineList }
