/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import PropTypes from 'prop-types'

import { useEffect, useMediaQueries, useCallback, useState, useMemo } from 'hooks'

import { backendErrorsToObj, bulkValidate, combineErrors } from 'helpers/validation.js'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Form, { Fieldset } from 'components/_old/Form/Form.jsx'
import InlineHelp from 'components/_old/InlineHelp/InlineHelp.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelText } from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import NationalitiesInputList from 'components/_old/NationalitiesInputList/NationalitiesInputList.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'
import Width from 'components/_old/Width/Width'

import CleanInputOnFocus from 'components/atoms/CleanInputOnFocus/CleanInputOnFocus.jsx'
import Segment from 'components/atoms/Segment/Segment.jsx'

import ModalContent, {
  ModalContentBody,
  ModalContentButtons,
  ModalContentHeadline,
} from 'components/molecules/ModalContent/ModalContent.jsx'

import { TaxInfoModal } from './TaxInfoModal.jsx'

import { countriesAlias } from 'constants/countriesAlias.ts'
import { ukId, usaId } from 'constants/countriesIds.js'
import { nciTitles } from 'constants/nci.js'

const isPartiallyHidden = (str) => /\*/.test(str)

const isCorrectNIN = (nin) => {
  if (isPartiallyHidden(nin)) return true

  if (!nin) return false

  return nin
    .replace(/\s/g, '')
    .match(/(?=^[A-Z]{2}[0-9]{6}[A-D]$)(?=^[^DFIQUV][^DFIQUVO])(?!^((BG)|(GB)|(KN)|(NK)|(NT)|(TN)|(ZZ)))/i)
}

const PASSPORT_NUMBER_TITLE = 'Passport number'
const NIDTypes = {
  CONCAT: 'CONCAT',
  PASSPORT: 'PASSPORT',
  NCI: 'NCI',
}

const ProvideTaxInfoSetupForm = (props) => {
  const { owner, error, countries = [], resetError } = props

  const getAllNationalities = useCallback(
    () => [...countries, { country: 'British', country_id: 2635167 }],
    [countries],
  )

  const countriesWithAlias = useMemo(() => [...countries, ...countriesAlias], [countries])

  const findFullCountry = useCallback(
    (countryId) => {
      const country = countries.find((country) => country.country_id === countryId)

      return country ? country.country : ''
    },
    [countries],
  )

  const [continueWithPAPopupVisible, setContinueWithPAPopupVisible] = useState(false)
  const [formState, setFormState] = useState({
    nationalities: owner.nationalities || [],
    firstNationality: null,
    nationalityUSAIndex: null,
    isNationalitiesIncludeUK: false,
    countryOfTaxResidenceId: owner.country_of_tax_residence,
    countryOfTaxResidence: findFullCountry(owner.country_of_tax_residence),
    isCountryOfResidenceUSA: false,
    isCountryOfResidenceUK: false,
    NIDType: null,
    NIDTitle: null,
    NID: owner.passport_number || owner.national_client_identifier,
    NIN: owner.national_insurance_number,
  })

  useEffect(() => {
    const { nationalities } = formState
    const nationalityUSAIndex = nationalities.findIndex((nationality) => nationality === usaId)
    const isNationalitiesIncludeUK = nationalities.some((nationality) => nationality === ukId)
    const firstNationality = nationalities.length ? nationalities[0] : null
    const NIDTitle = nciTitles[firstNationality]
    let NIDType = NIDTypes.CONCAT

    if (firstNationality && !isNationalitiesIncludeUK && NIDTitle !== null) {
      NIDType = NIDTitle ? NIDTypes.NCI : NIDTypes.PASSPORT
    }

    const isCountryOfResidenceUSA = formState.countryOfTaxResidenceId === usaId
    const isCountryOfResidenceUK = formState.countryOfTaxResidenceId === ukId

    setFormState((prevState) => {
      const newState = {
        ...prevState,
        nationalityUSAIndex,
        isNationalitiesIncludeUK,
        firstNationality,
        NIDType,
        NIDTitle,
        isCountryOfResidenceUSA,
        isCountryOfResidenceUK,
      }

      if (NIDType === NIDTypes.CONCAT || isNationalitiesIncludeUK) {
        newState.NID = null
      }

      if (!isNationalitiesIncludeUK && !isCountryOfResidenceUK) {
        newState.NIN = null
      }

      return newState
    })
  }, [formState.nationalities, formState.countryOfTaxResidenceId])

  const handleChange = useCallback(
    (field) => (value) => {
      resetError()
      setFormState((prevState) => {
        return {
          ...prevState,
          [field]: value,
        }
      })
    },
    [],
  )

  const handleDocChange = useCallback(
    (field) =>
      (event, value = event ? event.target.value : null) => {
        handleChange(field)(value ? value.toUpperCase() : value)
      },
    [],
  )

  const handleCountryOfTaxChange = useCallback((event, value = event ? event.target.value : null) => {
    handleChange('countryOfTaxResidence')(value ? findFullCountry(value.country_id) : null)
    handleChange('countryOfTaxResidenceId')(value ? value.country_id : null)
  }, [])

  const handleContinueWithPAPopupClose = () => {
    setContinueWithPAPopupVisible(false)
  }

  const handleContinue = () => {
    const { onSubmit } = props
    const hasNID = [NIDTypes.PASSPORT, NIDTypes.NCI].includes(formState.NIDType)
    const passportNumber = formState.NIDType === NIDTypes.PASSPORT ? formState.NID : null
    const nationalClientIdentifier = hasNID ? formState.NID : formState.isNationalitiesIncludeUK ? formState.NIN : null
    const nationalInsuranceNumber =
      formState.isCountryOfResidenceUK || formState.isNationalitiesIncludeUK ? formState.NIN : null

    const fields = {
      nationalities: formState.nationalities,
      country_of_tax_residence: formState.countryOfTaxResidenceId,
    }

    if (!isPartiallyHidden(passportNumber)) {
      fields.passport_number = passportNumber
    }

    if (!isPartiallyHidden(nationalClientIdentifier)) {
      fields.national_client_identifier = nationalClientIdentifier
    }

    if (!isPartiallyHidden(nationalInsuranceNumber)) {
      fields.national_insurance_number = nationalInsuranceNumber
    }

    onSubmit(fields)
  }

  const handleSubmit = () => {
    const { hasISAPortfolio, isOpeningSipp } = props
    const isNeedContinueWithPa = (hasISAPortfolio || isOpeningSipp) && !formState.isCountryOfResidenceUK

    if (isNeedContinueWithPa) {
      setContinueWithPAPopupVisible(true)
    }

    if (!isNeedContinueWithPa) {
      handleContinue()
    }
  }

  const { desktop } = useMediaQueries()

  const showNINInput = formState.isCountryOfResidenceUK || formState.isNationalitiesIncludeUK

  const validation = combineErrors(
    {
      nationalities: {
        rules: [
          formState.nationalities.length > 0 && formState.nationalities.some((nationality) => nationality !== null),
        ],
        errors: ['Citizenship can’t be empty'],
      },
      notUSA: {
        rules: [!formState.isCountryOfResidenceUSA, formState.nationalityUSAIndex === -1],
        errors: [],
      },
      NID: {
        rules: [formState.NIDType === NIDTypes.CONCAT || formState.isNationalitiesIncludeUK || formState.NID],
        errors: [`${formState.NIDTitle || PASSPORT_NUMBER_TITLE} can’t be empty`],
      },
      countryOfTaxResidence: {
        rules: [formState.countryOfTaxResidence],
        errors: ['Country of residence can’t be empty'],
      },
      NIN: {
        rules: [!showNINInput || formState.NIN, !showNINInput || isCorrectNIN(formState.NIN)],
        errors: ['National Insurance number can’t be empty', 'Please enter a valid National Insurance number'],
      },
    },
    backendErrorsToObj(error, false, (errors) => {
      const newErrors = { ...errors }

      if (newErrors.national_client_identifier) {
        newErrors.NID = newErrors.national_client_identifier
        delete newErrors.national_client_identifier
      }

      return newErrors
    }),
  )

  const inputMods = { size: 'bigger' }

  const nationalityInput = (
    <Validate rules={validation.nationalities.rules}>
      {(isValid, brokenRule) => (
        <NationalitiesInputList
          nationalitiesIds={formState.nationalities}
          allNationalitiesList={getAllNationalities()}
          nationalityUSAIndex={formState.nationalityUSAIndex}
          isValid={isValid}
          errorMessage={validation.nationalities.errors[brokenRule]}
          onChange={handleChange('nationalities')}
          data-test-id="taxInfoNationality"
        />
      )}
    </Validate>
  )

  const countryOfTaxPostField = formState.isCountryOfResidenceUSA && (
    <Text block color="red" data-test-id="taxInfoCountryUsaInfo">
      <Typo>
        Please contact us through the{' '}
        <Link to="https://help.investengine.com/" hard>
          Help Centre
        </Link>
        , and we'll help you continue creating an account with us.
      </Typo>
    </Text>
  )

  const countryOfTaxInput = (
    <Validate rules={validation.countryOfTaxResidence.rules}>
      {(isValid, brokenRule) => (
        <Label postfield={countryOfTaxPostField} data-test-id="taxInfoTaxResidenceLabel">
          <LabelText data-test-id="taxInfoTaxResidenceLabelText">
            <Text color={isValid ? null : 'red'}>
              <Typo>
                {isValid
                  ? 'Country of residence for tax purposes'
                  : validation.countryOfTaxResidence.errors[brokenRule]}
              </Typo>
            </Text>
          </LabelText>
          <Input
            name="country"
            mods={inputMods}
            type="typeahead"
            options={countriesWithAlias}
            labelKey="country"
            onChange={handleCountryOfTaxChange}
            tabIndex={2}
            forceSelect
            data-test-id="taxInfoResidenceInput"
          >
            {{ country: formState.countryOfTaxResidence, country_id: formState.countryOfTaxResidenceId }}
          </Input>
        </Label>
      )}
    </Validate>
  )

  const NIDInput = (
    <Validate rules={validation.NID.rules} value={formState.NID}>
      {(isValid, brokenRule) => (
        <Label data-test-id="taxInfoNIDLabel">
          <LabelText data-test-id="taxInfoNIDLabelText">
            <Text color={isValid ? null : 'red'}>
              <Typo>{isValid ? formState.NIDTitle || PASSPORT_NUMBER_TITLE : validation.NID.errors[brokenRule]}</Typo>
            </Text>
          </LabelText>
          <CleanInputOnFocus onSetValue={handleDocChange('NID')} cleanIfMatch={/\*/}>
            <Input
              name="NID"
              autoComplete="off"
              mods={{ ...inputMods, uppercase: true }}
              type="text"
              onChange={handleDocChange('NID')}
              tabIndex={3}
              data-test-id="taxInfoNIDInput"
            >
              {formState.NID}
            </Input>
          </CleanInputOnFocus>
        </Label>
      )}
    </Validate>
  )

  const NINInput = (
    <Validate rules={validation.NIN.rules}>
      {(isValid, brokenRule) => (
        <Label data-test-id="taxInfoNINLabel">
          <LabelText>
            <Text color={isValid ? null : 'red'} data-test-id="taxInfoNINLabelText">
              <Typo>{isValid ? 'UK National Insurance number' : validation.NIN.errors[brokenRule]}</Typo>
            </Text>
            <InlineHelp style={{ float: 'right' }} size={20}>
              {(handleClose) => (
                <ModalContent data-test-id="taxInfoNINHelp">
                  <ModalContentHeadline>What is "National Insurance number"?</ModalContentHeadline>
                  <ModalContentBody>
                    <Typo>
                      National Insurance or TIN number is unique to you and insures the correct National Insurance and
                      tax is recorded against your name. Every National Insurance number is different. It's made up of
                      letters and numbers like this: AB 12 34 56 C. You can find your National Insurance number on your
                      payslip, P60 or letters about tax, pensions and benefits. If you live outside the UK, we will
                      require your tax identification number (TIN). If you are unsure, please contact us through
                      the&nbsp;
                      <Link to="https://help.investengine.com/" hard blank>
                        Help Centre
                      </Link>
                    </Typo>
                  </ModalContentBody>
                  <ModalContentButtons text="OK" onClick={handleClose} data-test-id="taxInfotaxInfoNINHelpOk" />
                </ModalContent>
              )}
            </InlineHelp>
          </LabelText>
          <CleanInputOnFocus onSetValue={handleDocChange('NIN')} cleanIfMatch={/\*/}>
            <Input
              name="NIN"
              autoComplete="off"
              mods={{ ...inputMods, uppercase: true }}
              type="text"
              onChange={handleDocChange('NIN')}
              tabIndex={4}
              data-test-id="taxInfoNINInput"
            >
              {formState.NIN}
            </Input>
          </CleanInputOnFocus>
        </Label>
      )}
    </Validate>
  )

  const continueWithPersonalAccountPopup = (
    <TaxInfoModal
      open={continueWithPAPopupVisible}
      onClose={handleContinueWithPAPopupClose}
      onContinue={handleContinue}
    />
  )

  const submitButton = (
    <Button
      mods={{ size: 'big block' }}
      onClick={handleSubmit}
      tabIndex={5}
      disabled={!bulkValidate(validation)}
      data-test-id="taxInfoSubmit"
    >
      Continue
    </Button>
  )

  const wrapperSubmitButton = desktop ? (
    <div style={{ marginTop: '3em' }}>{submitButton}</div>
  ) : (
    <Segment mods={{ noMargin: 'top bottom' }}>
      <ModalContentButtons phantomButtonsMarginSize="zero" isNewModal>
        {submitButton}
      </ModalContentButtons>
    </Segment>
  )

  if (!owner.id || !countries.length) {
    return null
  }

  return (
    <Card
      mods={{
        theme: 'transparent',
        'no-padding': 'top left right',
        padding: 'big',
      }}
    >
      <Width size={desktop ? 36 : null} center>
        <Text block center>
          <Typo>
            If you are resident in the UK we need your National Insurance number. If you live outside the UK we require
            your tax identification number (TIN) and country of tax residence.
          </Typo>
        </Text>
        <Width size={desktop ? 24 : null} center style={{ marginTop: '2rem' }}>
          {(() => {
            const mobileCardMods = {
              theme: 'transparent',
              'no-padding': 'all',
            }

            return (
              <Card mods={desktop ? null : mobileCardMods}>
                <SubmitOnEnter>
                  <Form>
                    <Fieldset>
                      {nationalityInput}
                      {countryOfTaxInput}
                      {formState.NIDType &&
                        formState.NIDType !== NIDTypes.CONCAT &&
                        !formState.isNationalitiesIncludeUK &&
                        NIDInput}
                      {showNINInput && NINInput}
                    </Fieldset>
                    {wrapperSubmitButton}
                  </Form>
                </SubmitOnEnter>
              </Card>
            )
          })()}
        </Width>
      </Width>
      {continueWithPersonalAccountPopup}
    </Card>
  )
}

ProvideTaxInfoSetupForm.propTypes = {
  owner: PropTypes.object.isRequired,
  hasISAPortfolio: PropTypes.bool,
  isOpeningSipp: PropTypes.bool,
  error: PropTypes.object,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func,
  resetError: PropTypes.func,
}

export default ProvideTaxInfoSetupForm
