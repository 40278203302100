import { nullable, type Nullable, int, money, enumValue } from 'app/effector/model'

import { type BonusDTO } from '../api'

enum BonusStates {
  NEW = 'NEW',
  CLAIMED = 'CLAIMED',
  CANCELLED = 'CANCELLED',
}

class Bonus {
  readonly id: Nullable<number>
  readonly amount: Nullable<string>
  readonly state: Nullable<string>

  constructor(dto: Partial<BonusDTO> | Partial<Bonus>) {
    this.id = nullable(int)(dto.id)
    this.amount = nullable(money)(dto.amount)
    this.state = nullable(enumValue)(dto.state, BonusStates)
  }
}

export { Bonus, BonusStates }
