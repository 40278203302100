import React from 'react'

import Button from 'components/_old/Button/Button.jsx'

import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

import { Preloader } from 'components/molecules/Preloader'

import { FilterElement } from 'components/organisms/Filter/FilterElement'

import { type CreateReportFormPartProps } from '../CreateReportForm'

function SelectPortfolios({
  className,
  contentClassName,
  buttonClassName,
  visible,
  buttonTitle,
  desktop,
  portfolios,
  isPortfoliosFetching,
  selectedPortfolioIds,
  setSelectedPortfolioIds,
  handleSelectPortfolioIds,
}: CreateReportFormPartProps): React.ReactElement {
  return (
    <div className={className} hidden={!visible}>
      <SideSpacesCompensator className={contentClassName}>
        {portfolios.map((portfolio, index) => (
          <FilterElement
            key={JSON.stringify(portfolio.value ?? index)}
            name={portfolio.name}
            description={portfolio.description}
            value={portfolio.value}
            type="radio"
            paperSizes={{
              desktop: {
                left: 16,
                right: 16,
              },
            }}
            checked={JSON.stringify(selectedPortfolioIds) === JSON.stringify(portfolio.value)}
            onChange={() => {
              setSelectedPortfolioIds(portfolio.value)
            }}
            withLine
          />
        ))}
      </SideSpacesCompensator>
      <Paper className={buttonClassName} top={desktop ? 24 : 16} bottom={desktop ? 56 : null}>
        <Button onClick={handleSelectPortfolioIds} mods={{ size: desktop ? 'block' : 'big block' }}>
          {buttonTitle}
        </Button>
      </Paper>
      <Preloader loading={isPortfoliosFetching} background="white" zIndex absolute />
    </div>
  )
}

export { SelectPortfolios }
