import React from 'react'

import InlineHelp from 'components/_old/InlineHelp/InlineHelp.jsx'

import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import './InlineTextHelper.css'

type InlineTextHelperProps = {
  children: React.ReactNode
}

const InlineTextHelper = ({ children }: InlineTextHelperProps): React.ReactElement => {
  return (
    <InlineHelp
      className="InlineTextHelper"
      iconType="information-16"
      color="content-on-background-additional"
      background={null}
      size={16}
    >
      <ModalContent>{children}</ModalContent>
    </InlineHelp>
  )
}

export { InlineTextHelper }
