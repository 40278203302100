import React from 'react'
import CSSTransitionGroup from 'react-addons-css-transition-group'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import isFunction from 'lodash/isFunction'

import { useContext, useCallback, useRef, useMediaQueries, useEffect, useMemo, useImperativeHandle } from 'hooks'

import { palettePlainValues } from 'helpers/palette'

import { GlobalPreloaderContext } from 'components/_old/GlobalPreloader/GlobalPreloader.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { Gateway, GatewayDest } from 'components/atoms/Gateway'

import { useAppHeight } from 'app/containers/AppHeight'

import './Modal.css'

// That's a temporary hack
function setAnimation(value: 'true' | 'false'): void {
  sessionStorage.setItem('modalAnimation', value)
}

type ModalInsideProps = {
  children: React.ReactNode | React.ReactNodeArray
  insideGatewayName?: string
}

const ModalInside = React.forwardRef<HTMLDivElement, ModalInsideProps>(
  ({ children, insideGatewayName }: ModalInsideProps, ref): React.ReactElement => (
    <div ref={ref} className="_new_Modal-Inside">
      {children}
      {insideGatewayName && <GatewayDest className="_new_Modal-Gateway" name={insideGatewayName} />}
    </div>
  ),
)
ModalInside.displayName = 'ModalInside'

type ModalHeaderProps = {
  children: React.ReactNode | React.ReactNodeArray
}

const ModalHeader = ({ children }: ModalHeaderProps): React.ReactElement => (
  <Gateway into="newModalHeader">{children}</Gateway>
)

type ModalHandle = {
  modalWindow: HTMLDivElement | null
  modalInside: HTMLDivElement | null
}

type ModalAnimation = 'bottomToTop' | 'rightToLeft' | 'leftToRight' | null

type ModalProps = {
  children: React.ReactNode | React.ReactNodeArray
  className?: string
  animation?: ModalAnimation
  open: boolean
  persist?: boolean
  fullScreen?: boolean
  previousButton?: React.ReactNode
  nextButton?: React.ReactNode
  data?: Record<string, unknown>
  close?: React.ReactNode | null
  insideGatewayName?: string
  onClose?: (() => void) | null
  zIndex?: number
  background?: string
  'data-test-id'?: string
}

const Modal = React.forwardRef<ModalHandle, ModalProps>(
  (
    {
      children,
      className,
      animation,
      open,
      persist,
      fullScreen,
      data,
      previousButton,
      nextButton,
      close,
      insideGatewayName = 'insideNewModal',
      zIndex,
      background,
      onClose,
      'data-test-id': dataTestId,
    }: ModalProps,
    ref,
  ): React.ReactElement => {
    if (typeof animation === 'undefined') {
      animation = 'bottomToTop'
    }

    const withoutAnimationContext = useContext(GlobalPreloaderContext)
    const withoutAnimation =
      withoutAnimationContext || animation === null || sessionStorage.getItem('modalAnimation') === 'true'
    const modalWindow = useRef<HTMLDivElement | null>(null)
    const modalInside = useRef<HTMLDivElement | null>(null)
    const mediaQueries = useMediaQueries()
    const { desktop } = mediaQueries
    const appHeight = useAppHeight()

    const classes = classNames(
      className,
      '_new_Modal',
      {
        [animation ? `_new_Modal_animation_${animation}` : '']: animation,
        _new_Modal_fullScreen: fullScreen,
      },
      getMediaQuieryClasses('_new_Modal', mediaQueries),
    )

    const modalWindowClasses = classNames('_new_Modal-Window', {
      '_new_Modal-Window_background_primary': background === 'primary',
    })

    const style = useMemo(() => {
      const style: React.CSSProperties = {}

      if (zIndex) {
        style.zIndex = zIndex
      }

      if (!desktop && appHeight) {
        style.minHeight = appHeight
      }

      return style
    }, [zIndex, desktop, appHeight])

    const handleClose = useCallback(
      (event?: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent) => {
        if (event) {
          event.preventDefault()
          event.stopPropagation()
        }

        if (isFunction(onClose)) {
          onClose()
        }
      },
      [onClose],
    )

    const handleKeyPress = useCallback(
      (event) => {
        if (!open) {
          return
        }

        if (event && event.key === 'Escape') {
          handleClose()
        }
      },
      [open, handleClose],
    )

    const handleWindowClick = useCallback((event) => {
      event.stopPropagation()
    }, [])

    useEffect(() => {
      const bindEvent = (): void => {
        if (document) {
          document.addEventListener('keydown', handleKeyPress)
        }
      }

      const unbindEvent = (): void => {
        if (document) {
          document.removeEventListener('keydown', handleKeyPress)
        }
      }

      if (open) {
        bindEvent()

        setTimeout(() => {
          if (modalWindow?.current) {
            modalWindow.current.scrollTop = 0
          }
        }, 50)
      }

      if (!open) {
        unbindEvent()
      }

      return () => {
        unbindEvent()
      }
    }, [open, modalWindow, handleKeyPress])

    useImperativeHandle(ref, () => ({
      modalWindow: modalWindow.current,
      modalInside: modalInside.current,
    }))

    const dataProps = data
      ? Object.keys(data)
          .map((k) => {
            return { [`data-${k}`]: data[k] }
          })
          .reduce((memo, right) => {
            return {
              ...memo,
              ...(() => {
                const key = Object.keys(right)[0]
                const value = Object.keys(right).map((k) => right[k])[0]

                return { [key]: value }
              })(),
            }
          }, {})
      : null

    const closer = (() => {
      const closeIconProps = {
        tabIndex: 0,
        onClick: handleClose,
        'data-test-id': 'modalCloser',
      }

      if (close === null) {
        return null
      }

      if (close) {
        return (
          <Link className="_new_Modal-Close _new_Modal-CloseCustom" {...closeIconProps} mods={{ color: 'black' }}>
            {close}
          </Link>
        )
      }

      if (desktop) {
        return (
          <Link className="_new_Modal-Close _new_Modal-CloseCross" mods={{ color: 'gray' }} {...closeIconProps}>
            <Icon type="cross-thin" color="inherit" />
          </Link>
        )
      }

      return null
    })()

    const modal = (
      <div className={classes} style={style} data-test-id={dataTestId} {...dataProps}>
        <div className="_new_Modal-Backdrop" onClick={handleClose}>
          <div ref={modalWindow} className={modalWindowClasses} tabIndex={0} onClick={handleWindowClick}>
            <GatewayDest name="newModalHeader" />
            <ModalInside ref={modalInside} insideGatewayName={insideGatewayName}>
              {closer}
              {children}
            </ModalInside>
            <GatewayDest className="_new_Modal-Gateway" name="newModalPreloader" />
          </div>
        </div>
        {previousButton}
        {nextButton}
      </div>
    )

    if (persist) {
      return modal
    }

    return (
      <CSSTransitionGroup
        transitionName={{
          enter: '_new_Modal_enter',
          enterActive: '_new_Modal_enter_active',
          leave: '_new_Modal_leave',
          leaveActive: '_new_Modal_leave_active',
        }}
        transitionEnterTimeout={palettePlainValues.animation.speed.number.slow}
        transitionLeaveTimeout={palettePlainValues.animation.speed.number.slow}
        transitionEnter={!withoutAnimation}
        transitionLeave={!withoutAnimation}
      >
        {open && modal}
      </CSSTransitionGroup>
    )
  },
)
Modal.displayName = 'Modal'

export {
  Modal,
  type ModalHandle,
  type ModalProps,
  type ModalAnimation,
  ModalInside,
  type ModalInsideProps,
  ModalHeader,
  type ModalHeaderProps,
  setAnimation,
}
