import { type AnalyticsGeneralData, AnalyticsEquityType, type Summary } from '../types'

type ExtractDataReturnType = {
  actual: number | null
  actual_amount: number
  target: number | string | null
}

const calculateSummary = (data?: AnalyticsGeneralData): Summary => {
  const totalAmount = parseFloat(data?.total_value ?? '0')

  const extractData = (type: AnalyticsEquityType, hideTarget = false): ExtractDataReturnType => {
    // eslint-disable-next-line
    const { actual_weight, target_weight } = data?.equity_types?.[type] ?? {}
    const actual = actual_weight ? parseFloat(actual_weight) : null

    if (hideTarget) {
      return {
        actual,
        actual_amount: ((actual as number) * totalAmount) / 100,
        target: !target_weight || parseFloat(target_weight) <= 0 ? '—' : parseFloat(target_weight),
      }
    }

    return {
      actual,
      actual_amount: ((actual as number) * totalAmount) / 100,
      target: target_weight ? parseFloat(target_weight) : null,
    }
  }

  const total = data?.total_value ? parseFloat(data?.total_value) : null
  const stocks = extractData(AnalyticsEquityType.STOCK)
  const bonds = extractData(AnalyticsEquityType.BOND)
  const alternatives = extractData(AnalyticsEquityType.ALTERNATIVE)
  const cash = extractData(AnalyticsEquityType.CASH, true)

  return {
    total,
    stocks,
    bonds,
    alternatives,
    cash,
  }
}

export { calculateSummary }
