import React from 'react'

import { useMediaQueries } from 'hooks'

import Sticked, { propTypes as StickedPropTypes } from 'components/molecules/Sticked/Sticked.jsx'

const StickedIfNotDesktop = ({ children, ...rest }) => {
  const { desktop } = useMediaQueries()

  if (desktop) {
    return children
  }

  return <Sticked {...rest}>{children}</Sticked>
}

const propTypes = (StickedIfNotDesktop.propTypes = StickedPropTypes)

export { propTypes }
export default StickedIfNotDesktop
