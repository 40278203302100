import React from 'react'
import head from 'lodash/head'

import { useContext, useCallback, useSelector } from 'hooks'

import { RetakeQuestionnaireTunnelContext } from './RetakeQuestionnaireTunnel.jsx'

const LocallySaveAnswer = (props) => {
  const { code, redirectToNextStep, onAfterSave, params } = props
  const { children, ...propsWithoutChildren } = props
  const questions = useSelector((state) => state.questionnaire.questionList.questions)

  const { saveAnswers, answers } = useContext(RetakeQuestionnaireTunnelContext)
  const getAnswers = useCallback(
    (code) => {
      const currentQuestion = questions.find((question) => question.code === code)
      const currentAnswer = answers[code]

      return (currentQuestion?.type === 'MULTIPLE' || currentQuestion?.type === 'TEXT') && currentAnswer
        ? currentAnswer
        : head(currentAnswer)
    },
    [answers, questions],
  )

  const handleSubmit = useCallback(
    async (_event, answers) => {
      saveAnswers(code, answers)

      if (typeof onAfterSave === 'function') {
        await onAfterSave()
      }

      redirectToNextStep()
    },
    [code, redirectToNextStep, saveAnswers, params, onAfterSave],
  )

  return React.cloneElement(children, { ...propsWithoutChildren, getAnswers, onSubmit: handleSubmit })
}

export { LocallySaveAnswer }
