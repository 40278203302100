import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import chunk from 'lodash/chunk'

import { useMediaQueries } from 'hooks'

import Segment from 'components/atoms/Segment/Segment.jsx'
import Card from 'components/_old/Card/Card.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Text from 'components/_old/Text/Text.jsx'
import SelectableCard from 'components/_old/SelectableCard/SelectableCard.jsx'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

const SelectACard = React.memo(({ cards, multiple, onSelect: handleSelect }) => {
  const { desktop } = useMediaQueries()

  let cardNodes = cards.map((card, i) => {
    return (
      <SelectableCard
        key={i}
        tabIndex={i + 1}
        value={card.value}
        onChange={handleSelect}
        selected={card.selected}
        stretch={desktop}
        center={card.center}
        data-test-id={card['data-test-id']}
      >
        <Fragment>
          {(() => {
            if (multiple) {
              return (
                <CheckboxWithLabel
                  label={
                    <Text block>
                      <Typo>{card.headline || card.description}</Typo>
                    </Text>
                  }
                  checked={card.selected}
                  preventFocusOnLabelClick
                  noMarginBottom
                />
              )
            }

            const headline = (() => {
              if (typeof card.headline === 'string') {
                return (
                  <Text center={!card.description} block>
                    <Typo>{card.headline}</Typo>
                  </Text>
                )
              }

              return card.headline
            })()

            const description = (() => {
              if (typeof card.description === 'string') {
                return (
                  <Text smaller block color="black" mods={{ color: 'black' }}>
                    <Typo>{card.description}</Typo>
                  </Text>
                )
              }

              return card.description
            })()

            return [
              headline && <Fragment key="Headline">{headline}</Fragment>,
              description && <Fragment key="Description">{description}</Fragment>,
            ]
          })()}
        </Fragment>
      </SelectableCard>
    )
  })

  cardNodes = (() => {
    if (desktop) {
      return (
        <AllCenter limit={28}>
          <Card mods={{ theme: 'transparent', 'no-padding': 'all' }} fluid>
            {cardNodes.length < 5 && (
              <ColumnarLayout fluid>
                {cardNodes.map((node, i) => (
                  <Column key={i}>{node}</Column>
                ))}
              </ColumnarLayout>
            )}
            {cardNodes.length >= 5 &&
              (() => {
                const cardNodesChunks = chunk(cardNodes, 3).map((chunk) => {
                  while (chunk.length < 3) {
                    chunk.push(null)
                  }

                  return chunk
                })

                return cardNodesChunks.map((cardNodes, i) => (
                  <Segment key={i} mods={{ margin: 'biggest', noMargin: i === 0 ? 'top' : 'bottom' }}>
                    <ColumnarLayout fluid>
                      {cardNodes.map((node, i) => (node ? <Column key={i}>{node}</Column> : <Column key={i} />))}
                    </ColumnarLayout>
                  </Segment>
                ))
              })()}
          </Card>
        </AllCenter>
      )
    }

    return cardNodes.map((node, i) => (
      <Card
        key={i}
        mods={{
          theme: 'transparent',
          padding: 'big',
          'no-padding': i < 1 ? 'all' : 'left right bottom',
        }}
        fluid
      >
        {node}
      </Card>
    ))
  })()

  return <div className="Layout-Holder Layout-Holder_tunnel-steps">{cardNodes}</div>
})

const propTypes = (SelectACard.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      analytics: PropTypes.shape({
        category: PropTypes.string,
        action: PropTypes.string,
      }),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      headlineLevel: PropTypes.number,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      selected: PropTypes.bool,
      center: PropTypes.bool,
      'data-test-id': PropTypes.string,
    }),
  ).isRequired,
  multiple: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
})
SelectACard.displayName = 'SelectACard'

export { propTypes }
export { SelectACard }
