import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useCallback, useMemo, useState } from 'hooks'

import { AnimatedNavigationBar } from 'components/molecules/AnimatedNavigationBar'
import { Gateway, GatewayDest } from 'components/atoms/Gateway'
import { MobileLayout } from 'components/atoms/Layouts'
import { BottomTools } from 'app/pages/Securities/SecurityUniverse/Mobile/components/BottomTools'
import { Typography } from 'components/atoms/Typography'
import { Paper } from 'components/atoms/Paper'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ISADeclarationForm } from 'app/pages/Forms/ISADeclarationForm'

const Mobile = ({ agreed, handleAgreedChange, handleCancel, handleSubmit }) => {
  const [titleNode, setTitleNode] = useState(null)
  const titleRef = useCallback((ref) => setTitleNode(ref), [])
  const title = useMemo(() => <Typo>ISA declaration</Typo>, [])

  const header = useMemo(
    () => (
      <AnimatedNavigationBar nodeToWatchTo={titleNode} onRightPartClick={handleCancel} rightPartText="Cancel">
        {title}
      </AnimatedNavigationBar>
    ),
    [titleNode, title, handleCancel],
  )

  const content = useMemo(() => {
    const submitButton = <Button mods={{ size: 'big block', theme: null }} />

    const wrapButton = (button, useGateway = true) => {
      const wrapped = <BottomTools>{button}</BottomTools>

      if (useGateway) {
        return <Gateway into="isaDeclarationMobileFooter">{wrapped}</Gateway>
      }

      return wrapped
    }

    return (
      <Fragment>
        <Typography ref={titleRef} size={24} weight="semibold" lineHeight="small" align="center">
          {title}
        </Typography>
        <Paper top={20}>
          <ISADeclarationForm
            onSubmit={handleSubmit}
            handleAgreedChange={handleAgreedChange}
            submitButton={submitButton}
            wrapButton={wrapButton}
            value={agreed}
          />
        </Paper>
      </Fragment>
    )
  }, [titleRef, title, handleSubmit, handleAgreedChange, agreed])

  return <MobileLayout header={header} content={content} footer={<GatewayDest name="isaDeclarationMobileFooter" />} />
}

Mobile.propTypes = {
  agreed: PropTypes.bool,
  handleAgreedChange: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export { Mobile }
