import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'

const WithBankAccounts = ({ header, accounts, instantBankTransfer, manualBankTransfer, savingsPlan }) => {
  const { desktop } = useMediaQueries()

  const linkedAccounts = (
    <Fragment>
      <Typography size={desktop ? 16 : 14} lineHeight="small" color="minor">
        <Typo>Instant Transfer</Typo>
      </Typography>
      <Paper top={desktop ? 24 : 16}>
        <Stack vertical={desktop ? 24 : 16}>
          {accounts.map((account, index) => (
            <Fragment key={index}>{account}</Fragment>
          ))}
        </Stack>
      </Paper>
    </Fragment>
  )

  const moreOptions = (
    <Fragment>
      <Typography size={desktop ? 16 : 14} lineHeight="small" color="minor">
        <Typo>More options</Typo>
      </Typography>
      <Paper top={desktop ? 24 : 16}>
        <Stack vertical={desktop ? 24 : 16}>
          {instantBankTransfer}
          {manualBankTransfer}
          {savingsPlan}
        </Stack>
      </Paper>
    </Fragment>
  )

  const accountNodes = (
    <Stack vertical={desktop ? 32 : 40}>
      {linkedAccounts}
      {moreOptions}
    </Stack>
  )

  return (
    <OptionsLayout
      header={header}
      content={
        <Width size={desktop ? 25 : null} center>
          <Typography size={24} lineHeight="small" weight="semibold" align="center">
            <Typo>How do you want to add cash</Typo>
          </Typography>
          <Paper top={32} bottom={desktop ? 24 : null}>
            {accountNodes}
          </Paper>
        </Width>
      }
      button={null}
    />
  )
}

WithBankAccounts.propTypes = {
  header: PropTypes.node.isRequired,
  accounts: PropTypes.node.isRequired,
  instantBankTransfer: PropTypes.node.isRequired,
  manualBankTransfer: PropTypes.node.isRequired,
  savingsPlan: PropTypes.node.isRequired,
}

export { WithBankAccounts }
