import { nullable, type Nullable, isNullableNull, int, string, enumValue } from 'app/effector/model'

import {
  type NomineesTransferProviderDTO,
  NomineesTransferProviderRegulatoryType,
  NomineesTransferProviderSignatureType,
} from '../api'

export class NomineesTransferProvider {
  readonly id: number
  readonly regulatory_type: Nullable<NomineesTransferProviderRegulatoryType>
  readonly name: Nullable<string>
  readonly logo: Nullable<string>
  readonly regexp: Nullable<string>
  readonly error_message: string
  readonly signature_type: Nullable<NomineesTransferProviderSignatureType>

  constructor(dto: Partial<NomineesTransferProviderDTO> | Partial<NomineesTransferProvider>) {
    this.id = int(dto.id ?? -1)
    this.regulatory_type = nullable(enumValue)(dto.regulatory_type, NomineesTransferProviderRegulatoryType)
    this.name = nullable(string)(dto.name)
    this.logo = nullable(string)(dto.logo)
    this.regexp = nullable(string)(dto.regexp)
    this.error_message = string(dto.error_message ?? 'Incorrect account number')
    this.signature_type = nullable(enumValue)(dto.signature_type, NomineesTransferProviderSignatureType)
  }

  validate(entry: string): boolean {
    if (isNullableNull(this.regexp)) {
      return true
    }

    return new RegExp(this.regexp).test(entry)
  }
}
