import React, { Fragment } from 'react'
import classNames from 'classnames/dedupe.js'
import PropTypes from 'prop-types'

import { goTo, urlTo } from 'helpers/router'

import { useMediaQueries, useCallback } from 'hooks'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { Card } from 'components/atoms/Card'
import { Paper } from 'components/atoms/Paper'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { PoundsWithPence } from 'components/molecules/PoundsWithPence/PoundsWithPence'
import Link from 'components/_old/Link/Link.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import { Chip } from 'components/molecules/Chip'
import { Profit } from 'components/molecules/Profit'

import { manageTypes as portfolioManageTypes } from 'constants/portfolio'

import './HoldingInformation.css'

const Tooltip = ({ description }) => {
  const { desktop } = useMediaQueries()

  if (!description) {
    return null
  }

  return (
    <TooltipToModal className="HoldingInformation-Description" description={description} customIcon>
      <Link
        className={classNames('HoldingInformation-DescriptionToggle', {
          'HoldingInformation-DescriptionToggle_center': desktop,
        })}
        mods={{ color: 'mutedToRed' }}
      >
        <Icon size={18} type="questionInCircle" color="inherit" />
      </Link>
    </TooltipToModal>
  )
}

const HoldingInformation = ({
  description,
  securityId,
  portfolioId,
  value = 0,
  averagePrice = 0,
  total_return: totalReturn = {},
  quantity = 0,
  portfolioManageType,
}) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const { return_money: returnMoney = 0, return_percent: returnPercent = 0 } = totalReturn

  const verticalOffset = desktop ? 32 : 24
  const horizontalOffset = desktop ? 24 : 16

  const handleLinkToTransactions = useCallback(
    () => goTo(urlTo('transactions', null, `?portfolio_id=${portfolioId}&security_id=${securityId}`)),
    [portfolioId, securityId],
  )

  const isManaged = portfolioManageType === portfolioManageTypes.MANAGED
  const shouldHideTotalReturn = isManaged && (returnMoney === null || returnPercent === null)

  const hasAveragePrice =
    Boolean(averagePrice) &&
    ![portfolioManageTypes.MANAGED, portfolioManageTypes.SELF_SELECTED].includes(portfolioManageType)

  const averagePriceDescription = (
    <Typo>
      <b>Average purchase price</b> is the average price at which you bought this ETF in this portfolio, adjusted for
      any sales of part of your holding. The average is weighted according to the number of ETF shares.
      <br />
      <br />
      When compared with the latest price of the ETF, the <b>Average purchase price</b> allows you to see whether you
      are making a profit or a loss on your ETF shares.
    </Typo>
  )

  const transactionsLink = (
    <Typography align="right">
      <Chip icon="chevron-right-16" iconPosition="right" onClick={handleLinkToTransactions}>
        View transactions
      </Chip>
    </Typography>
  )

  return (
    <Card
      level={0}
      className={classNames('HoldingInformation', getMediaQuieryClasses('HoldingInformation', mediaQueries))}
    >
      <Paper top={verticalOffset} bottom={verticalOffset} left={horizontalOffset} right={horizontalOffset}>
        <Paper top={4}>
          <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'flex-start' }}>
            <Column>
              <Typography size={desktop ? 24 : 18} weight="semibold" lineHeight="small">
                <PoundsWithPence amount={value} data-test-id="holdingInformationValue" />
              </Typography>
              <Paper top={4}>
                <Typography size={14} color="minor" lineHeight="small">
                  Value of holding
                </Typography>
              </Paper>
            </Column>
            <Column>
              {!shouldHideTotalReturn && (
                <Fragment>
                  <Typography
                    size={desktop ? 24 : 18}
                    weight="semibold"
                    align="right"
                    lineHeight="small"
                    color="inherit"
                  >
                    <Profit
                      amount={returnMoney}
                      smallPences={true}
                      percent={returnPercent}
                      data-test-id="holdingInformation"
                    />
                  </Typography>
                  <Paper className="HoldingInformation-Label" top={4} right={24}>
                    <Typography size={14} color="minor" align="right" lineHeight="small" tag="span">
                      Total return
                    </Typography>
                    <Tooltip description={description} />
                  </Paper>
                </Fragment>
              )}
            </Column>
          </ColumnarLayout>
        </Paper>
        <Paper top={24}>
          <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'flex-end' }}>
            <Column size={0}>
              <Typography
                size={desktop ? 24 : 18}
                weight="semibold"
                lineHeight="small"
                data-test-id="holdingInformationShares"
              >
                {`${quantity}`}
              </Typography>
              <Paper top={4}>
                <Typography size={14} color="minor" lineHeight="small">
                  Shares
                </Typography>
              </Paper>
            </Column>
            {hasAveragePrice ? (
              <Column>
                <Typography size={desktop ? 24 : 18} weight="semibold" lineHeight="small" align="right">
                  <PoundsWithPence amount={averagePrice} data-test-id="holdingAveragePrice" />
                </Typography>
                <Paper top={4} right={24} className="HoldingInformation-Label">
                  <Typography size={14} color="minor" lineHeight="small" align="right">
                    Average purchase price
                  </Typography>
                  <Tooltip description={averagePriceDescription} />
                </Paper>
              </Column>
            ) : (
              <Paper bottom={1}>{transactionsLink}</Paper>
            )}
          </ColumnarLayout>
          {hasAveragePrice && <Paper top={24}>{transactionsLink}</Paper>}
        </Paper>
      </Paper>
    </Card>
  )
}

Tooltip.propTypes = {
  description: PropTypes.node,
}

HoldingInformation.propTypes = {
  securityId: PropTypes.number,
  portfolioId: PropTypes.number,
  description: PropTypes.node,
  value: PropTypes.number,
  averagePrice: PropTypes.number,
  quantity: PropTypes.number,
  total_return: PropTypes.shape({
    return_money: PropTypes.number,
    return_percent: PropTypes.number,
  }),
  portfolioManageType: PropTypes.oneOf(Object.keys(portfolioManageTypes)),
}

export { HoldingInformation }
