/**
 * @class
 * @typedef {Object} Entity
 */
export class Entity {
  /**
   * @param {Object=} object
   * @returns {Entity|*}
   */
  static createFromObject(object = null) {
    const entity = new this.prototype.constructor()

    if (!object) {
      return entity
    }

    return entity.setDataFromObject(object)
  }

  /**
   * This function for transform a input data by custom rules in the given order.
   * The changed value can be checked for data correctness.
   * Format object - { [key]: [[function, function=]] }
   *
   * @protected
   * @example
   * {
   *    id: [[parseFloat, (value) => value == NaN ? 'Can't be NaN' : null], [Math.round]],
   *    some_key: [[String]]
   * }
   */
  getRules() {
    return {}
  }

  /**
   * @param {Object} object
   * @returns {Entity|*}
   *
   * @final
   */
  setDataFromObject(object = {}) {
    for (const key of Object.keys(object)) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.hasOwnProperty(key)) {
        const transformRules = this.getRules()[key]

        this[key] = transformRules ? this.applyRules(transformRules, object[key], key) : object[key]
      }
    }

    return this
  }

  /** @returns {Object} */
  toObject() {
    return { ...this }
  }

  /**
   * @param {Array} rules
   * @param {any} value
   * @param {string} key
   * @returns {any}
   *
   * @private
   */
  applyRules(rules, value, key) {
    return rules.reduce((accumulator, currentValue) => {
      const [ruleFunction, checkFunction] = currentValue

      if (typeof ruleFunction !== 'function') {
        // can change on custom error
        throw new Error(`[${this.constructor.name}]: Rule can't apply for ${key} property`)
      }

      const nextValue = ruleFunction(accumulator)
      const message = checkFunction ? checkFunction(nextValue) : null

      if (message) {
        // can change on custom error
        throw new Error(`[${this.constructor.name}]: ${message}`)
      }

      return nextValue
    }, value)
  }
}
