import React, { Fragment } from 'react'

import { useMediaQueries, useMemo, useCallback } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PortfolioTabs } from 'app/pages/Dashboard/Goals/ManagedPortfolio/components/PortfolioTabs'

enum ReportHeaderTabs {
  REPORTS = 'Reports',
  CREATE_REPORT = 'Create report',
}

type ReportsHeaderProps = {
  activeTabName: ReportHeaderTabs
}

const ReportsHeader = React.memo(function ReportsHeader({
  activeTabName,
}: ReportsHeaderProps): React.ReactElement | null {
  const { desktop } = useMediaQueries()

  const tabItems = useMemo(
    () => [
      {
        name: ReportHeaderTabs.REPORTS,
        visible: true,
        'data-test-id': 'reportsReportListTab',
      },
      {
        name: ReportHeaderTabs.CREATE_REPORT,
        visible: true,
        'data-test-id': 'reportsCreateReportTab',
      },
    ],
    [],
  )

  const activeTabIndex = useMemo(
    () => tabItems.findIndex((tabItem) => tabItem.name === activeTabName),
    [activeTabName, tabItems],
  )

  const navigateByTab = useCallback((index: number, name: ReportHeaderTabs) => {
    const url = {
      [ReportHeaderTabs.REPORTS]: urlTo('reports.list'),
      [ReportHeaderTabs.CREATE_REPORT]: urlTo('reports.create-report'),
    }[name]

    if (url) {
      goTo(url)
    }
  }, [])

  return (
    <Fragment>
      {desktop && (
        <Typography size={24} weight="semibold" lineHeight="small" align="center">
          <Typo>Reports</Typo>
        </Typography>
      )}
      <Paper top={desktop ? 32 : 0}>
        <PortfolioTabs tabItems={tabItems} activeTabIndex={activeTabIndex} navigateByTab={navigateByTab} />
      </Paper>
    </Fragment>
  )
})

export { ReportsHeader, type ReportsHeaderProps, ReportHeaderTabs }
