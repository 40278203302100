import { nullable, type Nullable, string, boolean, enumValue } from 'app/effector/model'

import { type ReportTypeDTO } from '../api'

import { ReportFormat } from './Report'

class ReportType {
  readonly type: Nullable<string>
  readonly name: Nullable<string>
  readonly is_one_portfolio_allowed: boolean
  readonly formats: ReportFormat[]

  constructor(dto: Partial<ReportTypeDTO>) {
    this.type = nullable(string)(dto.type)
    this.name = nullable(string)(dto.name)
    this.is_one_portfolio_allowed = boolean(dto.is_one_portfolio_allowed ?? false)
    this.formats = (dto.formats ?? [])
      .map((format) => enumValue(format, ReportFormat))
      .filter((format) => typeof format !== 'undefined') as unknown as ReportFormat[]
  }
}

export { ReportType }
