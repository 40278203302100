import React from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries } from 'hooks'

import { $owner } from 'app/effector/contacts'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import { Typo } from 'components/_old/Typo/Typo'

import { Gateway } from 'components/atoms/Gateway'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'
import { PortfolioCard } from 'components/molecules/PortfolioCard/PortfolioCard'

import { usePortfolioOrders } from '../../hooks/usePortfolioOrders'

type SectionTitleProps = {
  visible?: boolean
  children: React.ReactChild
}

const SectionTitle = ({ visible = true, children }: SectionTitleProps): React.ReactElement | null => {
  const { desktop } = useMediaQueries()

  if (!visible) return null
  return (
    <Paper top={desktop ? 40 : 24} bottom={desktop ? 24 : 16}>
      <Typography size={desktop ? 16 : 14} lineHeight="medium" color="minor">
        <Typo>{children}</Typo>
      </Typography>
    </Paper>
  )
}

const Wrapper = ({ children, portfolioId, onClick }): React.ReactElement => {
  const { isEmpty } = usePortfolioOrders(portfolioId)

  return React.cloneElement(children, { onClick: () => onClick(null, portfolioId, !isEmpty) })
}

type PortfolioModalProps = {
  open: boolean
  portfolios: Portfolio[]
  portfoliosWithThisSecurity: Portfolio[]
  isIsaOrSippAvailable: boolean
  handleAddSecurity: (event, portfolioId, isRestricted) => void
  createNewPortfolio: () => void
  onClose: () => void
}

const PortfoliosModal = ({
  open,
  portfolios,
  portfoliosWithThisSecurity,
  isIsaOrSippAvailable,
  handleAddSecurity,
  createNewPortfolio,
  onClose,
}: PortfolioModalProps): React.ReactElement | null => {
  const { desktop } = useMediaQueries()

  const portfoliosWithSecurityIds = portfoliosWithThisSecurity.map(({ id }) => id)
  const portfoliosWithoutSecurity = portfolios.filter((portfolio) => !portfoliosWithSecurityIds.includes(portfolio.id))
  const owner = useUnit($owner)
  const canOpenSipp = owner?.age < 75

  if (open && !portfoliosWithThisSecurity.length && !portfoliosWithoutSecurity.length) {
    createNewPortfolio()
    onClose()
    return null
  }

  const content = (
    <Paper>
      <SectionTitle visible={!!portfoliosWithoutSecurity.length}>Add this ETF to existing portfolio</SectionTitle>
      {portfoliosWithoutSecurity.map((portfolio) => (
        <Paper key={portfolio.id} bottom={16} data-test-id="existingPortfolioCard">
          <Wrapper portfolioId={portfolio.id} onClick={handleAddSecurity}>
            <PortfolioCard portfolio={portfolio} />
          </Wrapper>
        </Paper>
      ))}
      <SectionTitle visible={!!portfoliosWithThisSecurity.length}>Portfolios that already hold this ETF</SectionTitle>
      {portfoliosWithThisSecurity.map((portfolio) => (
        <Paper key={portfolio.id} bottom={16} data-test-id="alreadyHoldPortfolioCard">
          <PortfolioCard portfolio={portfolio} />
        </Paper>
      ))}
    </Paper>
  )

  return (
    <Gateway into="modals">
      <Modal open={open} zIndex={1000} close={null} onClose={onClose}>
        <ModalContent width={36}>
          <Paper top={desktop ? 20 : 0} bottom={desktop ? 20 : 0} left={desktop ? 24 : 0} right={desktop ? 24 : 0}>
            <NavigationBar rightPartText="Cancel" onRightPartClick={onClose} plain>
              <Typography size={desktop ? 20 : 16} lineHeight="small" weight="semibold">
                Add to portfolio
              </Typography>
            </NavigationBar>
          </Paper>
          <Paper top={16} bottom={desktop ? 80 : 16} left={desktop ? 96 : 16} right={desktop ? 96 : 16}>
            <SectionTitle>Add this ETF to new portfolio</SectionTitle>
            <Paper bottom={16}>
              <SelectableCard onClick={createNewPortfolio} data-test-id="createNewPortfolioButton">
                <Paper top={24} bottom={24} right={20} left={20}>
                  <Typography size={18} lineHeight="small" weight="semibold">
                    <Typo>Create new portfolio</Typo>
                  </Typography>
                  <Paper top={8}>
                    <Typography size={14} lineHeight="small" color="additional" data-test-id="regulatoryTypeSubtitle">
                      {isIsaOrSippAvailable ? (
                        <Typo>
                          You can choose ISA{canOpenSipp ? ', Personal Pension' : ''} or General Account later
                        </Typo>
                      ) : (
                        <Typo>Self-managed, General</Typo>
                      )}
                    </Typography>
                  </Paper>
                </Paper>
              </SelectableCard>
            </Paper>
            {content}
          </Paper>
        </ModalContent>
      </Modal>
    </Gateway>
  )
}

export { PortfoliosModal }
