import { type AxiosError } from 'axios'
import { createStore, createEffect, createEvent, sample, combine } from 'effector'

import { ApiError } from 'app/redux/models/errors'

import * as api from './api'
import { OnboardingState, type ManageType } from './types'

// Events
const fetchOnboardingState = createEvent<{
  portfolioId: number
  portfolioType: ManageType
}>()

// Effects
const fetchOnboardingStateFx = createEffect(
  async ({ portfolioId, portfolioType }: { portfolioId: number; portfolioType: ManageType }) => {
    const data = await api.getOnboardingState({ portfolioId })

    return data
  },
)

const finishOnboardingFx = createEffect(async ({ portfolioType }: { portfolioType: ManageType }) => {
  try {
    const data = await api.finishOnboarding({ portfolioType })

    if (data instanceof ApiError) throw data

    return true
  } catch (error) {}
})

// Stores
const $onboardingStates = createStore<Record<string, OnboardingState>>({})
const $finishedOnboarding = createStore<Record<ManageType, boolean>>({ DIY: false, MANAGED: false })
const $isLoading = fetchOnboardingStateFx.pending

$onboardingStates.on(fetchOnboardingStateFx.done, (state, { result, params: { portfolioId } }) => {
  return { ...state, [portfolioId]: result?.state }
})

$finishedOnboarding.on(fetchOnboardingStateFx.fail, (state, { error, params: { portfolioType } }) => {
  if ((error as AxiosError)?.response?.status === 404) return { ...state, [portfolioType]: true }
  return state
})

$finishedOnboarding.on(fetchOnboardingStateFx.done, (state, { result, params: { portfolioType } }) => {
  if (result?.state === OnboardingState.COMPLETED) return { ...state, [portfolioType]: true }
  return state
})

$finishedOnboarding.on(finishOnboardingFx.done, (state, { result, params: { portfolioType } }) => {
  if (!result) return state
  return { ...state, [portfolioType]: true }
})

// do not fetch onboarding state if onboarding is finished for that type of portfolio
sample({
  clock: fetchOnboardingState,
  source: $finishedOnboarding,
  filter: (finishedOnboarding, { portfolioType }) => !finishedOnboarding[portfolioType],
  fn: (finishedOnboarding, { portfolioId, portfolioType }) => ({ portfolioId, portfolioType }),
  target: fetchOnboardingStateFx,
})

const $onboardingProgressStore = combine(
  [$onboardingStates, $finishedOnboarding, $isLoading],
  ([onboardingStates, finishedOnboarding, isLoading]) => ({
    getOnboardingState: ({ portfolioId, portfolioType }: { portfolioId: number; portfolioType: ManageType }) =>
      finishedOnboarding[portfolioType] ? null : onboardingStates[portfolioId],
    isLoading,
  }),
)

export { fetchOnboardingState, finishOnboardingFx, $onboardingStates, $finishedOnboarding, $onboardingProgressStore }
