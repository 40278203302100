import 'custom-event-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'

import { attachLogger } from 'effector-logger'

import 'helpers/analytics'
import 'helpers/Sniff.js'
import { configSentry } from 'helpers/errorLogging.js'
import { setThemeColorMetaTagValue } from 'helpers/palette'

import 'app/effector/init'

import store from 'app/redux/store/store.js'

import Root from 'app/containers/Root/Root.jsx'

if (process.env.NODE_ENV !== 'production') {
  window.React = React
  window.ReactDOM = ReactDOM
  window.store = store

  attachLogger()
}

if (!('scrollBehavior' in document.documentElement.style)) {
  require('scroll-behavior-polyfill')
}

configSentry(store)

const initialPreloader = document.getElementById('InitialPreloader')

if (initialPreloader?.parentNode) {
  initialPreloader.parentNode.removeChild(initialPreloader)
}

setThemeColorMetaTagValue()

const MOUNT = (() => {
  // Return prev root
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    const prevMnt = document.querySelector('.Root')

    if (prevMnt) {
      return prevMnt
    }
  }

  const mnt = document.createElement('div')
  mnt.classList.add('Root')
  document.body.appendChild(mnt)

  return mnt
})()

ReactDOM.render(<Root />, MOUNT)

// Enable global hot module replacement for the app
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept()
}
