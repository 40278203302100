import React from 'react'

import classNames from 'classnames/dedupe'

import './CurrentLine.css'

type CurrentLineProps = {
  className?: string
  coordinates?: {
    bottom: number
  }
}

function CurrentLine({ className, coordinates }: CurrentLineProps): React.ReactElement | null {
  if (!coordinates) {
    return null
  }

  const classes = classNames(className, 'CurrentLine')

  const style: React.CSSProperties = {
    bottom: coordinates.bottom,
  }

  return <div className={classes} style={style} />
}

export { CurrentLine, type CurrentLineProps }
