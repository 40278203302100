import React from 'react'

type LineProps = {
  path: string | null
  color: string
  strokeWidth: number
}

function Line({ path, color, strokeWidth }: LineProps): React.ReactElement | null {
  if (!path) {
    return null
  }

  return <path d={path} stroke={color} strokeWidth={strokeWidth} fill="transparent" />
}

export { Line }
