import React from 'react'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router'

import { useActions, useCallback, useMemo, useMediaQueries, useSelector } from 'hooks'
import { useUnit } from 'effector-react'

import { urlTo, goTo } from 'helpers/router.js'
import { combineErrors, backendErrorsToObj, bulkValidate, passwordRules } from 'helpers/validation.js'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Modal } from 'components/molecules/Modal'
import { NavigationBar } from 'components/atoms/NavigationBar'
import Width from 'components/_old/Width/Width'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Form from 'components/_old/Form/Form.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import { Typography } from 'components/atoms/Typography'
import { Paper } from 'components/atoms/Paper'
import { Typo } from 'components/_old/Typo/Typo'
import Icon from 'components/_old/Icon/Icon.jsx'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Button from 'components/_old/Button/Button.jsx'

import {
  fetchClient as fetchClientActionCreator,
  changePassword as changePasswordActionCreator,
  resetError as resetErrorActionCreator,
} from 'app/redux/actions/client'
import {
  changeModalField as changeModalFieldActionCreator,
  showSuccessToast,
  showFailToast,
} from 'app/redux/actions/ui'

import { $owner } from 'app/effector/contacts'

const ChangePassword = ({ routes }) => {
  const { desktop } = useMediaQueries()
  const { old_password, new_password1, new_password2 } = useSelector((state) => state.ui.modals.changePassword)
  const { error, tfa } = useSelector((state) => state.client)
  const open = routes[routes.length - 1].module === 'change-password'
  const owner = useUnit($owner) ?? {}

  const [fetchClient, changeModalField, changePassword, resetError] = useActions([
    fetchClientActionCreator,
    changeModalFieldActionCreator,
    changePasswordActionCreator,
    resetErrorActionCreator,
  ])

  const handleClose = useCallback(() => {
    const backUrl = urlTo('dashboard.user-profile.login-settings')

    goTo(backUrl, { replace: true, scrollToTop: false })

    changeModalField('changePassword', {
      old_password: null,
      new_password1: null,
      new_password2: null,
    })
  }, [changeModalField])

  const handleFieldChange = useCallback(
    (field, _event, value = null) => {
      if (field === 'old_password' && error) {
        resetError()
      }
      changeModalField('changePassword', { [field]: value })
    },
    [error],
  )

  const handleSubmit = useCallback(async () => {
    const stateAfterChangePassword = await changePassword({ old_password, new_password1, new_password2 })

    if (!stateAfterChangePassword.client.error) {
      await fetchClient()
      handleClose()
      showSuccessToast('Password successfully changed')
    } else {
      let errorMessage = 'Password change failed'
      const errObj = backendErrorsToObj(stateAfterChangePassword.client.error)

      if (errObj?.old_password) {
        errorMessage = errObj.old_password.errors[0]
      }
      showFailToast(errorMessage)
    }
  }, [old_password, new_password1, new_password2])

  const validation = combineErrors(
    {
      old_password: {
        rules: [old_password],
        errors: ['Password can’t be empty'],
      },
      new_password1: passwordRules(new_password1, {
        rules: [old_password !== new_password1],
        errors: ['New password must not be same as the old one'],
      }),
      new_password2: {
        rules: [new_password2, new_password1 === new_password2],
        errors: ['Password can’t be empty', 'Passwords did not match'],
      },
    },
    backendErrorsToObj(error),
  )

  const oldPasswordInput = useMemo(
    () => (
      <Validate rules={validation.old_password.rules}>
        <Label mods={{ color: 'darkgray' }} errorMessages={validation.old_password.errors}>
          Old password
          <Input
            type="password"
            mods={{ size: 'bigger' }}
            onChange={(...args) => handleFieldChange('old_password', ...args)}
            tabIndex={1}
            data-test-id="changePasswordOldPasswordInput"
          >
            {old_password}
          </Input>
        </Label>
      </Validate>
    ),
    [validation, old_password, handleFieldChange],
  )

  const newPasswordInput = useMemo(
    () => (
      <Paper top={desktop ? 32 : 24}>
        <Validate rules={validation.new_password1.rules}>
          <Label
            mods={{ color: 'darkgray' }}
            errorMessages={validation.new_password1.errors}
            postfield={
              <Typography size={12} color="minor" lineHeight="small">
                <Typo>Use at least one digit and one capital letter</Typo>
              </Typography>
            }
          >
            New password
            <Input
              type="password"
              mods={{ size: 'bigger' }}
              onChange={(...args) => handleFieldChange('new_password1', ...args)}
              tabIndex={1}
              data-test-id="changePasswordNewPasswordInput"
            >
              {new_password1}
            </Input>
          </Label>
        </Validate>
      </Paper>
    ),
    [desktop, validation, new_password1, handleFieldChange],
  )

  const repeatPasswordInput = useMemo(
    () => (
      <Paper top={desktop ? 32 : 24}>
        <Validate rules={validation.new_password2.rules}>
          <Label mods={{ color: 'darkgray' }} errorMessages={validation.new_password2.errors}>
            Repeat the&nbsp;new password
            <Input
              type="password"
              mods={{ size: 'bigger' }}
              onChange={(...args) => handleFieldChange('new_password2', ...args)}
              tabIndex={1}
              data-test-id="changePasswordNewPassword2Input"
            >
              {new_password2}
            </Input>
          </Label>
        </Validate>
      </Paper>
    ),
    [desktop, validation, new_password2, handleFieldChange],
  )

  const logoutInfoNode = (
    <Paper top={32}>
      <ItemWithIcon
        space={12}
        icon={<Icon type="information-24" size={24} color="inherit" />}
        content={
          <Typography size={desktop ? 14 : 12}>
            <Typo>Changing your password will log you out of other devices.</Typo>
          </Typography>
        }
        contentVerticalAlign="center"
        data-test-id="logoutInfoNotice"
      />
    </Paper>
  )

  const twoFactorInfoNode = useMemo(
    () =>
      tfa === 'ENABLED' &&
      !owner.phone_verified && (
        <Paper top={24}>
          <ItemWithIcon
            space={12}
            icon={<Icon type="information-24" size={24} color="inherit" />}
            content={
              <Typography size={desktop ? 14 : 12}>
                <Typo>
                  Two-factor authentication will be disabled. You can re-enable it anytime in your profile under 'Login
                  Settings'.
                </Typo>
              </Typography>
            }
            contentVerticalAlign="center"
            data-test-id="2faInfoNotice"
          />
        </Paper>
      ),
    [desktop, tfa, owner.phone_verified],
  )

  const button = useMemo(
    () => (
      <Button
        onClick={handleSubmit}
        disabled={!bulkValidate(validation)}
        mods={{ size: 'big block' }}
        data-test-id="changePasswordSubmitButton"
      >
        Change password
      </Button>
    ),
    [handleSubmit, validation],
  )

  if (desktop) {
    return (
      <Modal open={open} onClose={handleClose} close={null} data-test-id="changePasswordModal">
        <Width size={36}>
          <Paper top={8} bottom={80} right={24} left={24}>
            <NavigationBar onRightPartClick={handleClose} rightPartText="Cancel" />
            <Width size={24} center>
              <Paper top={56}>
                <Typography size={32} lineHeight="small" weight="semibold" align="center">
                  <Typo>Change password</Typo>
                </Typography>
              </Paper>
              <Paper top={32}>
                <SubmitOnEnter>
                  <Form>
                    {oldPasswordInput}
                    {newPasswordInput}
                    {repeatPasswordInput}
                    {logoutInfoNode}
                    {twoFactorInfoNode}
                    <Paper top={32}>{button}</Paper>
                  </Form>
                </SubmitOnEnter>
              </Paper>
            </Width>
          </Paper>
        </Width>
      </Modal>
    )
  }

  const content = (
    <Paper top={8}>
      <SubmitOnEnter>
        <Form>
          {oldPasswordInput}
          {newPasswordInput}
          {repeatPasswordInput}
          {logoutInfoNode}
          {twoFactorInfoNode}
        </Form>
      </SubmitOnEnter>
    </Paper>
  )

  return (
    <Modal open={open} onClose={handleClose} close={null}>
      <MobileLayout
        header={
          <NavigationBar onRightPartClick={handleClose} rightPartText="Cancel">
            Change password
          </NavigationBar>
        }
        content={content}
        footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
}

ChangePassword.propTypes = {
  routes: PropTypes.array,
}

export default withRouter(ChangePassword)
