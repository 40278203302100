import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { format as formatMoney } from 'helpers/money'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import Button from 'components/_old/Button/Button.jsx'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { ControlledInput } from 'components/atoms/Forms/ControlledInput'
import Label, { LabelField, LabelText } from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { TradingSuspendedWarning } from 'components/organisms/TradingSuspendedWarning/TradingSuspendedWarning.tsx'

import { BuySellTabs } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/components'
import { SecurityPreview } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/Security/components/SecurityPreview'

const SellMobile = ({
  security,

  isValid,
  validation,
  errors,
  control,

  handleBack,
  handleBuy,
  handleSetAllValue,
}) => {
  const isTradingEnabled = useMemo(() => security.is_trading_available, [security])

  const tabs = <BuySellTabs activeTab="sell" handleBuy={handleBuy} />

  const textField = (
    <Paper top={48}>
      <ControlledInput name="amount" control={control} errors={errors} rules={validation}>
        {({ value, valid, errorMessage, handleChange, handleFocus, handleBlur }) => (
          <Fragment>
            <Label valid={valid} errorMessage={errorMessage}>
              <LabelText>
                <Typography size={12} color="additional">
                  <Typo>Amount</Typo>
                </Typography>
              </LabelText>
              <LabelField>
                <Input
                  name="value"
                  type="money"
                  placeholder="£"
                  mods={{ size: 'big' }}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  withFloat
                >
                  {value}
                </Input>
              </LabelField>
            </Label>
            {!valid && (
              <Paper top={12}>
                <Typography size={14} color="error">
                  {errorMessage}
                </Typography>
              </Paper>
            )}
          </Fragment>
        )}
      </ControlledInput>
    </Paper>
  )

  const tradingEnabledContent = (
    <Fragment>
      <Paper top={32} bottom={32}>
        <Typography size={36} weight="semibold">
          <Typo>Sell</Typo>
        </Typography>
      </Paper>
      <SecurityPreview logo={security.logo_uri} title={security.title} />
      {textField}
      <Paper top={12}>
        <Typography size={14} color="minor">
          Value of holding {formatMoney(security?.available_value || 0, true, true)}
          <Link onClick={handleSetAllValue} style={{ marginLeft: 8 }}>
            Sell all
          </Link>
        </Typography>
      </Paper>
    </Fragment>
  )

  const content = useMemo(
    () => (
      <Paper>
        {tabs}
        {isTradingEnabled ? tradingEnabledContent : <TradingSuspendedWarning />}
      </Paper>
    ),
    [isTradingEnabled],
  )

  const footer = useMemo(
    () => (
      <MobileLayoutFooterButton>
        <Button type="submit" disabled={!isValid}>
          Review order
        </Button>
      </MobileLayoutFooterButton>
    ),
    [isValid],
  )

  return (
    <MobileLayout
      header={<NavigationBar leftPartText="Back" onLeftPartClick={handleBack} />}
      content={content}
      footer={isTradingEnabled ? footer : null}
    />
  )
}

SellMobile.propTypes = {
  security: PropTypes.object,
  isValid: PropTypes.bool,
  validation: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,

  handleBack: PropTypes.func,
  handleSetAllValue: PropTypes.func,
  handleBuy: PropTypes.func,
}

export { SellMobile }
