import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'

import { format } from 'helpers/date.js'

import * as portfolioActionTypes from 'app/redux/actions/portfolios/portfoliosActionTypes.js'
import { PortfolioList } from 'app/redux/models/portfolio'

import { emptyGoal } from 'constants/goal'

const processGoals = (goals) => {
  return sortBy(
    uniq(goals, (goal) => goal.id),
    (goal) => Number(format(goal.created, 'X')),
  )
}

export const initialState = {
  fetchCount: 0,
  didInvalidate: false,
  error: null,
  list: new PortfolioList(),
  items: [],
  selectedGoal: null,
  presets: {},
}

export default function portfolios(state = initialState, action = {}) {
  switch (action.type) {
    case portfolioActionTypes.SET_VALID:
      return { ...state, didInvalidate: false }

    case portfolioActionTypes.SET_NOT_VALID:
      return { ...state, didInvalidate: true }

    case portfolioActionTypes.RECEIVE_ERROR:
      return { ...state, error: action.error }

    case portfolioActionTypes.RESET_ERROR:
      return { ...state, error: null }

    case portfolioActionTypes.RECEIVE_PORTFOLIOS: {
      const portfolios = action.portfolios || action.goals

      const goals = portfolios.map((goal) => {
        const stateGoal = state.items.find((itemGoal) => itemGoal.id === goal.id)

        goal.term_recommended = goal.term
        goal.is_fetched = true

        // HACK: set preset in buffer variable
        if (!goal.preset_changed && goal.preset_recommended) {
          goal.preset_changed = goal.state !== 'NEW' ? stateGoal?.preset_changed || goal.preset : goal.preset
        }

        if (stateGoal) {
          return { ...stateGoal, ...goal }
        }

        return { ...emptyGoal, ...goal }
      })

      return {
        ...state,
        is_fetched: true,
        fetchCount: state.fetchCount + 1,
        items: processGoals(goals),
        list: new PortfolioList(...portfolios),
      }
    }

    case portfolioActionTypes.RECEIVE_PORTFOLIO: {
      const goal = state.items.find((goal) => {
        if (action.goal) {
          return goal.id === action.goal.id
        }

        return goal.id === action.portfolio.id
      })

      const newGoal = goal
        ? {
            ...goal,
            ...(action.goal || action.portfolio),
            term_recommended: action.goal?.term || action.portfolio?.term,
            is_fetched: true,
          }
        : {
            ...emptyGoal,
            ...(action.goal || action.portfolio),
            term_recommended: action.goal?.term || action.portfolio?.term,
            is_fetched: true,
          }

      // HACK: set preset in buffer variable
      if (!newGoal.preset_changed) {
        newGoal.preset_changed = newGoal.preset
      }

      const items = processGoals([
        ...state.items.filter((i) => {
          if (action.goal) {
            return i.id !== action.goal.id
          }

          return i.id !== action.portfolio.id
        }),
        newGoal,
      ])

      const portfolio = action.portfolio || action.goal

      return { ...state, list: state.list.replaceOrAdd(portfolio), items }
    }

    case portfolioActionTypes.REMOVE_PORTFOLIOS: {
      return { ...state, list: new PortfolioList(), items: [] }
    }

    case portfolioActionTypes.REMOVE_PORTFOLIO: {
      const { portfolioId } = action

      return { ...state, list: state.list.remove(portfolioId) }
    }

    case portfolioActionTypes.SELECT_GOAL:
      return Object.assign({}, state, { selectedGoal: action.id })

    case portfolioActionTypes.CHANGE_FIELD: {
      const goalId = action.id || state.selectedGoal
      let goal = state.items.find((goal) => goal.id === goalId)
      const restGoals = state.items.filter((goal) => goal.id !== goalId)
      goal = { ...goal, ...action.field }

      return Object.assign({}, state, {
        items: processGoals([...restGoals, goal]),
      })
    }

    case portfolioActionTypes.RECEIVE_PRESETS: {
      const presets = {
        [action.presetType]: action.presets,
      }

      return Object.assign({}, state, {
        presets: { ...state.presets, ...presets },
      })
    }

    default:
      return state
  }
}
