enum OnboardingState {
  REGISTRATION_NOT_COMPLETED = 'REGISTRATION_NOT_COMPLETED',
  REGISTRATION_CHECK = 'REGISTRATION_CHECK',
  BANK_ACCOUNT_AWAITING_APPROVAL = 'BANK_ACCOUNT_AWAITING_APPROVAL',
  MAKE_TOPUP = 'MAKE_TOPUP',
  TOPUP_VERIFYING = 'TOPUP_VERIFYING',
  PLACE_AN_ORDER = 'PLACE_AN_ORDER',
  ORDER_PLACED = 'ORDER_PLACED',
  ORDER_PROCESSING = 'ORDER_PROCESSING',
  HAS_SECURITIES = 'HAS_SECURITIES',
  COMPLETED = 'COMPLETED',
}

type ManageType = 'DIY' | 'MANAGED'

export { OnboardingState, type ManageType }
