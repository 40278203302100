import { attach } from 'effector'
import { useUnit } from 'effector-react'
import last from 'lodash/last'

import { useContext, useCallback, useMemo, useSelector, useEffect } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'
import { Impact } from 'helpers/impact.js'
import { goTo, urlTo } from 'helpers/router.js'

import { updateOrCreateSignificantControlFx, deleteSignificantControlsFx } from 'app/effector/significant-controls'

import { showFailToast } from 'app/redux/actions/ui'

import { StepContext } from 'app/containers/PortfolioTunnel'

import { useClient } from 'app/pages/Dashboard/SetupAccount/hooks/useClient.js'
import { useCompany } from 'app/pages/Dashboard/SetupAccount/hooks/useCompany.js'
import { useContacts } from 'app/pages/Dashboard/SetupAccount/hooks/useContacts.js'
import { usePayment } from 'app/pages/Dashboard/SetupAccount/hooks/usePayment.js'

import { useQuickStartNavigation } from '../hooks/useQuickStartNavigation'

const attachedUpdateSignificantControlsFx = attach({ effect: updateOrCreateSignificantControlFx })

attachedUpdateSignificantControlsFx.fail.watch(({ error }) => {
  processError({ error })
  showFailToast()
})

const useBusinessRegistration = ({ location }) => {
  const { activeStep, getPrevStep, getNextStep, getStepByName, redirectByStepName, redirectByStep } =
    useContext(StepContext)

  const [updateOrCreateSignificantControl, deleteSignificantControls] = useUnit([
    attachedUpdateSignificantControlsFx,
    deleteSignificantControlsFx,
  ])

  const updateSignificantControl = (significantControl) => {
    updateOrCreateSignificantControl({
      data: significantControl,
      id: significantControl.id,
      keys: ['name', 'addresses'],
    })
  }

  const { client, completeClient } = useClient()
  const {
    contacts,
    owner,
    changeOwnerField,
    changeContactField,
    updateOwner,
    updateContact,
    deleteContact,
    passIdentityCheck,
  } = useContacts('SME')
  const { company, updateCompany } = useCompany()
  const { updatePaymentDetails, changePaymentField } = usePayment()
  const portfolios = useSelector(({ portfolios }) => portfolios)

  const { navigateAfterFinishRegistration } = useQuickStartNavigation({ portfolioId: location?.query?.portfolioId })

  const trackStepCompleted = useCallback(() => {
    trackEvent({
      category: 'Registration',
      action: 'Registration step completed',
      label: activeStep.headline,
    })
  }, [activeStep])

  const trackRegistrationCompleted = useCallback(() => {
    trackEvent({
      category: 'Registration',
      action: 'Registration completed',
      label: client.type,
    })

    Impact.trackRegistration(client, portfolios)
  }, [client, portfolios])

  const completeRegistration = useCallback(async () => {
    trackRegistrationCompleted()

    const stateAfterCompleteClient = await completeClient()

    if (!stateAfterCompleteClient.result) {
      processError({ error: stateAfterCompleteClient.error, forceReset: true })
      return
    }

    if (location?.query?.portfolioId) {
      navigateAfterFinishRegistration(stateAfterCompleteClient)
      return
    }

    goTo(urlTo('dashboard', null, { finishRegistration: true }))
  }, [trackRegistrationCompleted, completeClient, navigateAfterFinishRegistration, location])

  const addDirector = useCallback(
    async (contact) => {
      trackStepCompleted()

      const keys = ['role', 'gender', 'first_name', 'middle_name', 'last_name', 'addresses', 'has_significant_control']

      if (!contact.birthday?.includes('*')) {
        keys.push('birthday')
      }

      const result = await updateContact({
        keys,
        id: contact.id,
        data: contact,
        setValidAfter: false,
      })

      if (!result.result) {
        processError({ error: result.error })
        return
      }

      const contactId = contact.id ? contact.id : last(result.contacts)?.id

      if (!contact.id) {
        redirectByStepName('adding-director', { contactId })
      }

      const onSuccessIdentityCheck = () => {
        redirectByStepName('directors-and-shareholders')
      }
      const onFailIdentityCheck = () => {
        redirectByStepName('director-proof-of-identity', { contactId })
      }

      await passIdentityCheck(contactId, onSuccessIdentityCheck, onFailIdentityCheck)
    },
    [trackStepCompleted, updateContact, passIdentityCheck, redirectByStepName],
  )

  const updateAddresses = useCallback(async () => {
    trackStepCompleted()

    const state = await updateContact({ id: owner?.id, keys: ['addresses'] })

    if (!state.result) {
      processError({ error: state.error, forceReset: true })
      return
    }

    const onSuccessIdentityCheck = () => {
      redirectByStepName('directors-and-shareholders')
    }
    const onFailIdentityCheck = () => {
      redirectByStepName('main-applicant-proof-of-identity', { contactId: owner?.id })
    }

    await passIdentityCheck(owner?.id, onSuccessIdentityCheck, onFailIdentityCheck)
  }, [owner?.id, updateContact, trackStepCompleted, redirectByStepName, passIdentityCheck])

  const getContact = useCallback(
    (contactId) => {
      if (activeStep.initModule === 'main-applicant-proof-of-identity') {
        return owner
      }
      return contacts.getContact(contactId)
    },
    [activeStep, owner, contacts],
  )

  const prevStep = useMemo(() => {
    const currentModule = activeStep.initModule

    if (currentModule === 'directors-and-shareholders' && owner?.isAvailable()) {
      return getStepByName('residential-address')
    }

    if (currentModule === 'adding-significant-control') {
      return getStepByName('directors-and-shareholders')
    }

    if (currentModule === 'bank-account') {
      return getStepByName('directors-and-shareholders')
    }

    return getPrevStep()
  }, [getPrevStep, activeStep, owner, getStepByName])

  const nextStep = useMemo(() => {
    if (activeStep.initModule === 'directors-and-shareholders') {
      return getStepByName('bank-account')
    }

    if (
      ['director-proof-of-identity', 'main-applicant-proof-of-identity', 'adding-significant-control'].includes(
        activeStep.initModule,
      )
    ) {
      return getStepByName('directors-and-shareholders')
    }

    return getNextStep()
  }, [getNextStep, activeStep, getStepByName])

  const handleAfterSubmit = useCallback(async () => {
    trackStepCompleted()
    window.ttq.track('CompleteRegistration')

    if (nextStep) {
      redirectByStep(nextStep)
      return
    }

    await completeRegistration()
  }, [nextStep, trackStepCompleted, completeRegistration, redirectByStep])

  useEffect(() => {
    const unwatch = attachedUpdateSignificantControlsFx.done.watch(() => {
      handleAfterSubmit()
    })

    return () => {
      unwatch()
    }
  }, [handleAfterSubmit])

  return {
    contacts,
    owner,
    company,
    changeOwnerField,
    changeContactField,
    changePaymentField,
    updateOwner,
    updateCompany,
    updateAddresses,
    updatePaymentDetails,
    deleteContact,
    addDirector,
    updateSignificantControl,
    deleteSignificantControls,
    getContact,
    handleAfterSubmit,
    prevStep,
    nextStep,
  }
}

export { useBusinessRegistration }
