import React from 'react'
import PropTypes from 'prop-types'

import { useCallback, useContext, useEffect, useState } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'

import { requestStates } from './requestStates.js'

import { Error, Success, Loading, Verification, UploadStatement } from './states'
import { useNewBank } from './hooks'

const NewBank = ({ goal, location, parentModule, nextUrl, toggleCloseButton }) => {
  const { id } = location.query

  const { state, awaitingAccounts, isBankAccountsLoading } = useNewBank({ id })

  const [showVerificationModal, setShowVerificationModal] = useState(false)

  const { tunnelQuery } = useContext(ChangeGoalTunnelContext)

  const redirectToDashboard = useCallback(() => {
    const { id, ...queryWithoutId } = tunnelQuery || {}

    goTo(nextUrl ?? urlTo('dashboard.portfolio', { id: goal?.id }, queryWithoutId), { replace: true })
  }, [tunnelQuery, nextUrl, goal?.id])

  const redirectToParentModal = useCallback(() => {
    const { id, ...queryWithoutId } = tunnelQuery || {}

    goTo(nextUrl ?? urlTo(`dashboard.portfolio.${parentModule}`, { id: goal?.id }, queryWithoutId), { replace: true })
  }, [tunnelQuery, parentModule, nextUrl, goal?.id])

  useEffect(() => {
    if (!id) {
      redirectToDashboard()
    }
  }, [])

  const onOpenUpload = useCallback(() => {
    if (typeof toggleCloseButton === 'function') {
      toggleCloseButton(false)
    }
  }, [toggleCloseButton])

  const onCloseUpload = useCallback(
    (isSomeStatementsUploaded) => {
      if (isSomeStatementsUploaded) {
        setShowVerificationModal(true)
        if (typeof toggleCloseButton === 'function') {
          toggleCloseButton(true)
        }
      } else {
        redirectToParentModal()
      }
    },
    [toggleCloseButton, redirectToParentModal, setShowVerificationModal],
  )

  if (isBankAccountsLoading) {
    return <Loading />
  }

  if (showVerificationModal) {
    return <Verification onContinue={redirectToParentModal} />
  }

  if (awaitingAccounts.length > 0 && (state === requestStates.SUCCESS || state === requestStates.ERROR)) {
    return <UploadStatement bankAccounts={awaitingAccounts} onOpen={onOpenUpload} onClose={onCloseUpload} />
  }

  if (state === requestStates.SUCCESS) {
    return (
      <Success onAddFundsLater={redirectToDashboard} onAddFundsNow={redirectToParentModal} fromPortfolio={!!goal} />
    )
  }

  if (state === requestStates.ERROR) {
    return <Error onContinue={redirectToDashboard} />
  }

  return <Loading />
}

NewBank.propTypes = {
  goal: PropTypes.object,
  location: PropTypes.shape({
    query: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  parentModule: PropTypes.string,
  nextUrl: PropTypes.string,
  toggleCloseButton: PropTypes.func,
}

export { NewBank }
