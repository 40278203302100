import axios from 'helpers/ajax/'
import { retryAsyncOperationUntilResultIsDone } from 'helpers/retryAsyncOperationUntilResultIsDone'

const enum IDCheckStatus {
  NO_CHECKS = 'no_checks',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  UNKNOWN = 'unknown',
}

const enum IDCheckResult {
  PASS = 'pass',
  REFER = 'refer',
  ALERT = 'alert',
  UNKNOWN = 'unknown',
}

type IDCheckStatusResponse = {
  status: IDCheckStatus
  result?: IDCheckResult
}

async function requestOnce(url: string): Promise<IDCheckStatusResponse> {
  const response = await axios.get<IDCheckStatusResponse>(url)

  if (response?.data?.status === IDCheckStatus.COMPLETE) {
    return response.data
  }

  return {
    status: IDCheckStatus.UNKNOWN,
  }
}

async function requestIDCheck(url: string): Promise<IDCheckResult | undefined> {
  const response = await retryAsyncOperationUntilResultIsDone<IDCheckStatusResponse>(
    requestOnce.bind(this, url),
    (response) => response.status === IDCheckStatus.COMPLETE,
    4000,
    12,
  )

  return response.result
}

export { requestIDCheck, IDCheckStatus, IDCheckResult, type IDCheckStatusResponse }
