import React from 'react'

import { useUnit } from 'effector-react'

import { useEffect } from 'hooks'

import { $presetCollectionsStoriesStore, fetchPresetCollectionsStories } from 'app/effector/stories'

import { Stories } from 'components/organisms/Stories/Stories'

const SelfSelectedStories = (): React.ReactElement => {
  const { getStoriesByCollection, areStoriesFetched } = useUnit($presetCollectionsStoriesStore)
  const stories = getStoriesByCollection({ slug: 'self-selected' })

  useEffect(() => {
    fetchPresetCollectionsStories()
  }, [])

  return <Stories stories={stories} isLoading={!areStoriesFetched} />
}

export { SelfSelectedStories }
