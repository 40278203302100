import React from 'react'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

const Description = (): React.ReactElement => (
  <Width size={45}>
    <Typography size={16}>
      <Typo>
        Transferring an ISA to InvestEngine is easier than you think, simply fill out this form and we’ll handle the
        process with your existing provider on your behalf, all you have to do is fill out this form.
      </Typo>
      <Paper top={16}>
        <Typo>
          Transfers don’t affect your ISA allowance and we won’t charge you for this process. Read our{' '}
          <Link to="https://help.investengine.com/hc/en-gb/sections/5071035786653-ISA-Transfers" blank>
            ISA transfer FAQs
          </Link>
          .
        </Typo>
      </Paper>
    </Typography>
  </Width>
)

export { Description }
