import { nullable, type Nullable, string } from 'app/effector/model'

class BusinessType {
  readonly code: Nullable<string>
  readonly title: Nullable<string>

  constructor(dto?: { code: string | null; title: string | null }) {
    this.code = nullable(string)(dto?.code)
    this.title = nullable(string)(dto?.title)
  }
}

export { BusinessType }
