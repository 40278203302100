import React, { Fragment } from 'react'
import { withRouter } from 'react-router'

import isEmpty from 'lodash/isEmpty'

import { useCallback, useContext, useMediaQueries, useMemo } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Header from 'components/_old/Header/Header.jsx'
import HeadlineWBacklink from 'components/_old/HeadlineWBacklink/HeadlineWBacklink.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Progress } from 'components/_old/Progress'
import { Typo } from 'components/_old/Typo/Typo'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'

import { DesktopFooter } from 'app/containers/Footer'
import { StepContext, type StepContextShape } from 'app/containers/PortfolioTunnel'

type LayoutParams = Record<string, unknown>

type LayoutStep = {
  module: string
  headline: string
  params?: LayoutParams
}

type LayoutProps = {
  children: React.ReactElement
  prevStep?: LayoutStep
  params?: LayoutParams
  location: {
    query: Record<string, string>
  }
  mobileInnerPaperSizes?: {
    top: number
    bottom: number
  }
}

const Layout = withRouter(
  ({ children, prevStep, params, location, mobileInnerPaperSizes }: LayoutProps): React.ReactElement => {
    const { phone } = useMediaQueries()
    const { activeStep, progress } = useContext<StepContextShape>(StepContext)

    const backStep = useMemo(
      () =>
        prevStep ?? {
          headline: 'My Dashboard',
          module: 'dashboard',
        },
      [prevStep],
    )

    const redirectToBack = useCallback(() => {
      const backParams =
        !isEmpty(params) || !isEmpty(backStep?.params) ? { ...params, ...(backStep?.params ?? {}) } : undefined

      if (backStep.module === 'dashboard' && location?.query?.forwardToTransferIsa) {
        delete location.query.forwardToTransferIsa
      }

      goTo(urlTo(backStep.module, backParams, location.query))
    }, [backStep, params, location])

    const headline = (
      <HeadlineWBacklink
        back={redirectToBack}
        backLinkText={backStep.headline}
        noPaddingTop
        data-test-id="setupAccountBackLink"
      >
        {!activeStep?.customHeadline ? (
          <Typo>{activeStep?.headline}</Typo>
        ) : (
          <GatewayDest name="setupAccountHeadline" />
        )}
      </HeadlineWBacklink>
    )

    const gateways = (
      <Fragment>
        <GatewayDest name="modals" />
      </Fragment>
    )

    if (phone) {
      return (
        <Fragment>
          <MobileLayout
            header={
              <Fragment>
                <NavigationBar onLeftPartClick={redirectToBack} leftPartText="Back">
                  Registration
                </NavigationBar>
                <Progress fill={progress ?? 0} gapWidth="1em" />
              </Fragment>
            }
            content={
              <Fragment>
                {headline}
                {children}
              </Fragment>
            }
            contentPaperSizes={{
              top: mobileInnerPaperSizes?.top,
              bottom: mobileInnerPaperSizes?.bottom ?? 48,
            }}
            footer={<GatewayDest name="insideNewModal" />}
          />
          {gateways}
        </Fragment>
      )
    }

    return (
      <Fragment>
        <DesktopLayout
          header={
            <Fragment>
              <Header right={<Link to={urlTo('dashboard')}>Cancel</Link>} forceShowRight />
              <Progress fill={progress ?? 0} gapWidth="1em" />
            </Fragment>
          }
          content={
            <Inner>
              {headline}
              <AllCenter>{children}</AllCenter>
            </Inner>
          }
          footer={<DesktopFooter />}
        />
        {gateways}
      </Fragment>
    )
  },
)

export { Layout }
