import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { goTo, urlTo } from 'helpers/router.js'

import { useCallback, useMemo } from 'hooks'
import { useSecuritySlice, useBuySellRestriction } from './hooks'
import { usePendingOrders } from 'app/pages/Dashboard/Goals/DIY/hooks'

import { GatewayDest } from 'components/atoms/Gateway'
import { Restrictions } from 'app/pages/Dashboard/Goals/DIY/Restrictions'
import { SlowSecurityMention, useSlowSecurityMention } from 'app/pages/Dashboard/Goals/DIY/SlowSecurityMention'

const SecuritySlice = ({ params, location = {}, children } = {}) => {
  const { id: portfolioIdString, securityId: securityIdString } = params
  const {
    query: { fromAnalytics: navigateFromAnalytics, fromPortfolio: navigateFromPortfolio, ...tunnelQuery },
  } = location
  const fromAnalytics = navigateFromAnalytics === 'true'
  const fromPortfolio = navigateFromPortfolio === 'true'

  const portfolioId = useMemo(() => parseInt(portfolioIdString), [portfolioIdString])
  const securityId = useMemo(() => parseInt(securityIdString), [securityIdString])

  const {
    isSecurityLoading,
    isAnalyticsLoading,
    security,
    portfolio,
    chartData,
    totalReturnsData,
    pointsOfInterest,
    holdings,
    sectors,
    regions,
    securityLabels,
    periodPriceData,
    searchValue,
    filters,
    activeChart,
    selectedPeriod,
    setChartTab,
    setPeriodTab,
    shouldShowDividendsChart,
    dividendsChartData,
    handleSearch,
    goToHoldingPage,
    handleBack,
    goToSecurityAnalytics,
    handleCloseAnalytics,
  } = useSecuritySlice({
    portfolioId,
    securityId,
    fromAnalytics,
    fromPortfolio,
    location,
    tunnelQuery: {
      ...tunnelQuery,
      ...(fromPortfolio ? { fromPortfolio: navigateFromPortfolio } : {}),
      ...(fromAnalytics ? { fromAnalytics: navigateFromAnalytics } : {}),
    },
  })

  const { orders, isLoading: isPendingOrdersLoading } = usePendingOrders()
  const securityOrders = orders.getByPortfolioId(portfolioId).getBySecurityId(securityId)
  const portfolioOrSecurityOrders = orders
    .getByPortfolioId(portfolioId)
    .getByPortfolioIdOrSecurityId(portfolioId, securityId)
  const linkToSecurityOrders = useMemo(
    () => urlTo('portfolio.security-orders', { id: portfolioId, securityId }),
    [portfolioId, securityId],
  )

  const redirectToBuySell = useCallback(
    () => goTo(urlTo('portfolio.security-buy', { id: portfolioId, securityId })),
    [portfolioId, securityId],
  )

  const { isSlowSecurity, isSlowSecurityMentionOpen, handleSlowSecurityMentionOpen, handleSlowSecurityMentionClose } =
    useSlowSecurityMention(security)

  const { restrictionModalOpen, restrictionModalType, handleRestrictionModalClose, handleBuySell } =
    useBuySellRestriction({
      orders: portfolioOrSecurityOrders,
      handleBuySellRaw: isSlowSecurity ? handleSlowSecurityMentionOpen : redirectToBuySell,
    })

  const props = {
    isSecurityLoading,
    isAnalyticsLoading,
    isPendingOrdersLoading,
    fromAnalytics,
    fromPortfolio,
    security,
    portfolio,
    securityOrders,
    linkToSecurityOrders,
    chartData,
    totalReturnsData,
    pointsOfInterest,
    periodPriceData,
    holdings,
    sectors,
    regions,
    securityLabels,
    searchValue,
    filters,
    activeChart,
    selectedPeriod,
    setChartTab,
    setPeriodTab,
    shouldShowDividendsChart,
    dividendsChartData,
    handleSearch,
    handleBack,
    handleClose: handleCloseAnalytics,
    handleBuySell,
    goToSecurityAnalytics,
    goToHoldingPage,
  }

  return (
    <Fragment>
      {React.cloneElement(children, { ...props })}

      {restrictionModalType && (
        <Restrictions
          portfolioId={portfolioIdString}
          open={restrictionModalOpen}
          onClose={handleRestrictionModalClose}
          type={restrictionModalType}
        />
      )}
      <SlowSecurityMention
        open={isSlowSecurityMentionOpen}
        onClose={handleSlowSecurityMentionClose}
        onSubmit={redirectToBuySell}
      />

      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

SecuritySlice.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
    securityId: PropTypes.string.isRequired,
  }),
  location: PropTypes.shape({
    query: PropTypes.object,
  }),
  children: PropTypes.node,
}

export { SecuritySlice }
