import React, { createContext } from 'react'

import omit from 'lodash/omit'
import PropTypes from 'prop-types'

import { useState, useCallback, useMediaQueries } from 'hooks'

type ScrollSaverContextInterface = {
  getScrollableElement: () => HTMLElement | undefined
  saveScrollPosition: () => void
  restoreScrollPosition: () => void
  resetScrollPosition: (url?: string) => void
  resetAllScrollPositions: () => void
}

const ScrollSaverContext = createContext<ScrollSaverContextInterface>({
  getScrollableElement: () => undefined,
  saveScrollPosition: () => {},
  restoreScrollPosition: () => {},
  resetScrollPosition: () => {},
  resetAllScrollPositions: () => {},
})

const getPageURL = (): string => {
  const url = `${window.location.pathname}${window.location.hash}`.replace(/^\/?#/, '').replace(/\?.*$/, '')

  const back = (window.location.toString() || '').match(/back=(.*?)(&|$)/)?.[1] ?? null
  const search = (window.location.toString() || '').match(/search=(.*?)(&|$)/)?.[1] ?? null
  // eslint-disable-next-line
  const asset_class = (window.location.toString() || '').match(/asset_class=(.*?)(&|$)/)?.[1] ?? null
  // eslint-disable-next-line
  const dividends_type = (window.location.toString() || '').match(/dividends_type=(.*?)(&|$)/)?.[1] ?? null
  const hedged = (window.location.toString() || '').match(/hedged=(.*?)(&|$)/)?.[1] ?? null
  const esg = (window.location.toString() || '').match(/esg=(.*?)(&|$)/)?.[1] ?? null

  let result = url

  if (back) {
    result = `${result}?back=${back}`
  }

  if (search) {
    result = `${result}?search=${search}`
  }

  if (asset_class) {
    result = `${result}?asset_class=${asset_class}`
  }

  if (dividends_type) {
    result = `${result}?dividends_type=${dividends_type}`
  }

  if (hedged) {
    result = `${result}?hedged=${hedged}`
  }

  if (esg) {
    result = `${result}?esg=${esg}`
  }

  return result
}

const ScrollSaverProvider = ({ children }): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const [positions, setPositions] = useState({})

  const getScrollableElement = useCallback(() => {
    return desktop
      ? document.querySelector('.DesktopLayout-Element_scrollable')
      : document.querySelector('.MobileLayout-Content')
  }, [desktop])

  const saveScrollPosition = useCallback(() => {
    const scrollableElement = getScrollableElement()

    if (!scrollableElement) {
      return
    }

    const url = getPageURL()
    setPositions((positions) => ({ ...positions, [url]: scrollableElement.scrollTop }))
  }, [getScrollableElement])

  const restoreScrollPosition = useCallback(() => {
    const scrollableElement = getScrollableElement()

    if (!scrollableElement) {
      return
    }

    const url = getPageURL()

    if (positions[url] !== undefined) {
      scrollableElement.scrollTop = positions[url]
    }
  }, [getScrollableElement, positions])

  const resetScrollPosition = useCallback(
    (rawUrl?: string) => {
      const scrollableElement = getScrollableElement()

      if (!scrollableElement) {
        return
      }

      const url = rawUrl ?? getPageURL()
      scrollableElement.scrollTop = 0
      setPositions((positions) => omit(positions, [url]))
    },
    [getScrollableElement],
  )

  const resetAllScrollPositions = useCallback(() => {
    const scrollableElement = getScrollableElement()

    if (!scrollableElement) {
      return
    }

    scrollableElement.scrollTop = 0
    setPositions({})
  }, [getScrollableElement])

  return (
    <ScrollSaverContext.Provider
      value={{
        getScrollableElement,
        saveScrollPosition,
        restoreScrollPosition,
        resetScrollPosition,
        resetAllScrollPositions,
      }}
    >
      {children}
    </ScrollSaverContext.Provider>
  )
}

ScrollSaverProvider.propTypes = {
  children: PropTypes.node,
}

export { ScrollSaverProvider, ScrollSaverContext, getPageURL }
