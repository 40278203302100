import React, { type ReactElement } from 'react'

import { type Notification as NotificationModel } from 'app/effector/notifications/models'

import { useNotification } from './useNotification'

type NotificationProps = {
  notification: NotificationModel
}

const Notification = ({ notification }: NotificationProps): ReactElement => {
  const { theme, actionLinkProps, title, body, image, dataTestId } = useNotification(notification)
  const { Component } = theme

  return (
    <Component
      icon={theme.icon}
      title={title}
      body={body}
      image={image}
      actionLinkProps={actionLinkProps}
      disabled={notification?.disabled}
      action={notification?.action}
      code={notification?.code}
      data-test-id={dataTestId}
    />
  )
}

export { Notification, type NotificationProps }
