import React from 'react'
import { Router, Route, Redirect } from 'react-router'
import { Provider } from 'react-redux'

import history from 'helpers/history.js'

import { useMemo } from 'hooks'

import { AppHeightProvider } from 'app/containers/AppHeight'
import { ScrollSaverProvider } from 'app/containers/ScrollSaver'
import { LockHeaderScrollProvider } from 'app/containers/LockHeaderScrollProvider'
import { FeatureToggleHandler } from 'app/containers/FeatureToggleHandler'
import { getRoutes } from 'app/containers/App/routes.jsx'

import store from 'app/redux/store/store.js'

// Monkey-patch react-router v3 to work with html
if (process.env.NODE_ENV !== 'production' && module.hot) {
  Router.prototype.componentWillReceiveProps = function (nextProps) {
    const components = []
    function grabComponents(element) {
      if (element.props?.component) {
        components.push(element.props.component)
      }
      if (element.props?.children) {
        React.Children.forEach(element.props.children, grabComponents)
      }
    }
    grabComponents(nextProps.routes || nextProps.children)
    components.forEach(React.createElement)
  }
}

const AppRoutes = () => {
  const routes = useMemo(() => getRoutes(), [])

  return (
    <Router history={history}>
      {process.env.NODE_ENV !== 'production' ? <Redirect from="/" to="/login/" /> : null}
      <Route {...routes} />
    </Router>
  )
}

const Root = () => {
  const differentApiServer = useMemo(() => {
    if (window?.location?.host && window.location.host.includes('rscwealth') && process.env.NODE_ENV === 'production') {
      const isDifferent = CONFIG?.API_ROOT?.length > 0 && !CONFIG.API_ROOT.includes(window.location.host)

      if (isDifferent) {
        const host = CONFIG?.API_ROOT?.match?.(/https?:\/\/.*?\//g)?.[0]

        if (host) {
          return (
            <div
              style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                padding: '8px 16px',
                background: 'red',
                borderTopRightRadius: 8,
                color: 'white',
              }}
            >
              Caution! Backend is on different staging:{' '}
              <a href={host} target="_blank" style={{ color: 'white' }} rel="noreferrer">
                {host}
              </a>
            </div>
          )
        }
      }
    }

    return null
  }, [])

  return (
    <ScrollSaverProvider>
      <LockHeaderScrollProvider>
        <AppHeightProvider>
          <FeatureToggleHandler>
            {(hash) => (
              <Provider key={hash} store={store}>
                <AppRoutes />
                {differentApiServer}
              </Provider>
            )}
          </FeatureToggleHandler>
        </AppHeightProvider>
      </LockHeaderScrollProvider>
    </ScrollSaverProvider>
  )
}

export default Root
