import React from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import { $collectionsStore } from 'app/effector/discover-etfs'
import { $securitiesStore } from 'app/effector/securities'
import { SecurityProvider } from 'app/effector/securities/models'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { HorizontallyScrollable } from 'components/molecules/HorizontallyScrollable'

import { Filter, type Appearance } from 'components/organisms/Filter'
import { FilterGroup } from 'components/organisms/Filter/FilterGroup'

import { SecurityFilterLabel } from 'app/pages/Dashboard/Transactions/components/SecurityFilterLabel'

import { type Filters } from '../hooks/useFilters'

type SecurityUniverseFiltersProps = {
  filters: Filters
  moreShown: boolean
  activeFilters?: string[]
}

const SecurityUniverseFilters = ({
  filters,
  moreShown,
  activeFilters = ['ordering', 'asset_class', 'provider', 'dividends_type', 'hedged', 'esg'],
}: SecurityUniverseFiltersProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { providers } = useUnit($securitiesStore)

  const { collectionGroups } = useUnit($collectionsStore)
  const isFeaturedPartnersAvailable = !!collectionGroups.find((group) => group.title === 'Featured partners')

  const orderingFilter = (
    <Filter
      key="ordering"
      name={filters.ordering.name}
      type={filters.ordering.type}
      appearance={'blue' as Appearance}
      noOverflowInDropdown
      selected={filters.ordering.selected}
      onChange={filters.ordering.onChange}
      values={[
        {
          name: 'Relevance',
          value: null,
          isDefault: true,
        },
        {
          name: 'Low to high',
          value: 'ter',
          title: 'TER',
          selectedName: (
            <ItemWithIcon
              space={4}
              icon={<Icon type="sort-low-16" size={16} color={palette['content-on-background-primary']} />}
              content="TER"
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              iconPosition="right"
              inline
            />
          ),
          showDescriptionInTooltip: true,
          description: (
            <span>
              <Paper right={4} inline>
                <ItemWithIcon
                  space={4}
                  icon={
                    <Icon type="ter-16" style={{ width: '22px' }} color={palette['content-on-background-primary']} />
                  }
                  content={
                    <Typography size={desktop ? 14 : 16} color="additional">
                      <Typo>TER </Typo>
                    </Typography>
                  }
                  iconVerticalAlign="center"
                  contentVerticalAlign="center"
                  iconPosition="right"
                  inline
                />
              </Paper>
              <Typography size={desktop ? 14 : 16} color="additional" style={{ display: 'inline' }}>
                <Typo>
                  — TER stands for Total Expense Ratio and is how much in percentage (%) terms that the ETF charges for
                  investment management and admin costs each year.
                </Typo>
              </Typography>
            </span>
          ),
        },
        {
          name: 'High to low',
          value: '-ter',
          selectedName: (
            <ItemWithIcon
              space={4}
              icon={<Icon type="sort-high-16" size={16} color={palette['content-on-background-primary']} />}
              content="TER"
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              iconPosition="right"
              inline
            />
          ),
        },
        {
          name: 'Low to high',
          value: 'estimated_yield',
          title: 'Yield',
          selectedName: (
            <ItemWithIcon
              space={4}
              icon={<Icon type="sort-low-16" size={16} color={palette['content-on-background-primary']} />}
              content="Yield"
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              iconPosition="right"
              inline
            />
          ),
          showDescriptionInTooltip: true,
          description: (
            <span>
              <Paper right={4} inline>
                <ItemWithIcon
                  space={4}
                  icon={
                    <Icon type="pound-16" style={{ width: '22px' }} color={palette['content-on-background-primary']} />
                  }
                  content={
                    <Typography size={desktop ? 14 : 16} color="additional">
                      <Typo>Yield </Typo>
                    </Typography>
                  }
                  iconVerticalAlign="center"
                  contentVerticalAlign="center"
                  iconPosition="right"
                  inline
                />
              </Paper>
              <Typography size={desktop ? 14 : 16} color="additional" style={{ display: 'inline' }}>
                <Typo>
                  — Yield represent the dividend paid out from this ETF over last 12 months as a % of its current share
                  price. Note only distributing ETFs payout dividends. Accumulating ETFs will have no yield but may
                  achieve higher total returns.
                </Typo>
              </Typography>
            </span>
          ),
        },
        {
          name: 'High to low',
          value: '-estimated_yield',
          selectedName: (
            <ItemWithIcon
              space={4}
              icon={<Icon type="sort-high-16" size={16} color={palette['content-on-background-primary']} />}
              content="Yield"
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              iconPosition="right"
              inline
            />
          ),
        },
      ]}
      data-test-id="terFilter"
    />
  )

  const assetClassFilter = (
    <Filter
      key="asset-class"
      name={filters.asset_class.name}
      type={filters.asset_class.type}
      values={filters.asset_class.values as FilterValue[]}
      selected={filters.asset_class.selected}
      onChange={filters.asset_class.onChange}
      data-test-id="assetClassFilter"
    />
  )

  const providerFilter = (
    <Filter
      key="provider"
      name={filters.provider.name}
      type={filters.provider.type}
      align="center"
      selected={filters.provider.selected}
      onChange={filters.provider.onChange}
      values={[
        {
          name: (
            <SecurityFilterLabel
              title="Any provider"
              iconSize={{ phone: 32 }}
              maxWidth={{ desktop: 163 }}
              showStub={false}
              align="center"
            />
          ),
          selectedName: 'Any provider',
          value: null,
          isDefault: true,
        },
        ...(isFeaturedPartnersAvailable
          ? [
              {
                name: (
                  <SecurityFilterLabel
                    title="Featured partners"
                    iconSize={{ phone: 32 }}
                    maxWidth={{ desktop: 163 }}
                    showStub={false}
                    align="center"
                    data-test-id="featuredPartnersFilter"
                  />
                ),
                selectedName: 'Featured partners',
                value: 'Featured partners',
              },
            ]
          : []),
        ...providers.map((provider, index) => ({
          name: (
            <SecurityFilterLabel
              key={index}
              title={provider.name ?? ''}
              icon={provider.logo_uri ?? undefined}
              grayLogo={SecurityProvider.isIndependent(provider)}
              iconSize={{ phone: 32 }}
              maxWidth={{ desktop: 163 }}
              align="center"
            />
          ),
          selectedName: provider.logo_uri ? undefined : provider.name,
          value: provider.name,
        })),
      ]}
      data-test-id="providerFilter"
    />
  )

  const dividendsTypeFilter = (
    <Filter
      key="dividends"
      name={filters.dividends_type.name}
      title="Dividends"
      type={filters.dividends_type.type}
      selected={filters.dividends_type.selected}
      onChange={filters.dividends_type.onChange}
      showDescriptionInTooltip
      noOverflowInDropdown
      description={
        <span>
          <Paper right={4} inline>
            <ItemWithIcon
              space={4}
              icon={<Icon type="repeat-16" size={16} color={palette['content-on-background-primary']} />}
              content={
                <Typography size={desktop ? 14 : 16} color="additional">
                  <Typo>Acc </Typo>
                </Typography>
              }
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              iconPosition="right"
              inline
            />
          </Paper>
          <Typography size={desktop ? 14 : 16} color="additional" style={{ display: 'inline' }}>
            <Typo>
              — An accumulating ETF automatically reinvests any income from its underlying holdings back into the ETF,
              rather than paying it out(as with a distributing ETF).
            </Typo>
          </Typography>
          <Paper top={24}>
            <Paper right={4} inline>
              <ItemWithIcon
                space={4}
                icon={<Icon type="pound-16" size={16} color={palette['content-on-background-primary']} />}
                content={
                  <Typography size={desktop ? 14 : 16} color="additional">
                    <Typo>Yield</Typo>
                  </Typography>
                }
                iconVerticalAlign="center"
                contentVerticalAlign="center"
                iconPosition="right"
                inline
              />
            </Paper>
            <Typography size={desktop ? 14 : 16} color="additional" style={{ display: 'inline' }}>
              <Typo>
                — A distributing ETF pays out the income from its underlying holdings, rather than automatically
                reinvesting it(as with an accumulating ETF).
              </Typo>
            </Typography>
          </Paper>
        </span>
      }
      values={[
        {
          name: 'Accumulating of dividends',
          value: 'ACCUMULATING',
          selectedName: 'Acc',
        },
        {
          name: 'Distributing of dividends',
          value: 'DISTRIBUTING',
          selectedName: 'Yield',
        },
      ]}
      data-test-id="dividendsTypeFilter"
    />
  )

  const hendegFilter = (
    <Filter
      key="hedged"
      title="Hedged"
      name={filters.hedged.name}
      type={filters.hedged.type}
      selected={filters.hedged.selected}
      onChange={filters.hedged.onChange}
      noOverflowInDropdown
      values={[
        {
          name: 'Currency-hedged',
          value: 1,
          selectedName: '£ Hedged',
        },
        {
          name: 'Unhedged',
          value: 0,
          selectedName: 'Unhedged',
        },
      ]}
      showDescriptionInTooltip
      description={
        <span>
          <Paper right={4} inline>
            <ItemWithIcon
              space={4}
              icon={<Icon type="hedged-16" size={16} color={palette.alternatives} />}
              content={
                <Typography size={desktop ? 14 : 16} color="additional">
                  <Typo>£ Hedged </Typo>
                </Typography>
              }
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              iconPosition="right"
              inline
            />
          </Paper>
          <Typography size={desktop ? 14 : 16} color="additional" style={{ display: 'inline' }}>
            <Typo>
              — Currency-hedged ETFs are designed to protect investors from exchange rate movements when holding
              overseas assets.
            </Typo>
          </Typography>
        </span>
      }
      data-test-id="hedgedFilter"
    />
  )

  const esgFilter = (
    <Filter
      key="esg"
      title="ESG"
      name={filters.esg.name}
      type={filters.esg.type}
      selected={filters.esg.selected}
      onChange={filters.esg.onChange}
      noOverflowInDropdown
      values={[
        {
          name: 'Yes',
          value: 1,
          selectedName: 'ESG',
        },
        {
          name: 'No',
          value: 0,
          selectedName: 'No ESG',
        },
      ]}
      showDescriptionInTooltip
      description={
        <span>
          <Paper right={4} inline>
            <ItemWithIcon
              space={4}
              icon={<Icon type="leaf-16" size={16} color={palette['secondary-default']} />}
              content={
                <Typography size={desktop ? 14 : 16} color="additional">
                  <Typo>ESG </Typo>
                </Typography>
              }
              iconVerticalAlign="center"
              contentVerticalAlign="center"
              iconPosition="right"
              inline
            />
          </Paper>
          <Typography size={desktop ? 14 : 16} color="additional" style={{ display: 'inline' }}>
            <Typo>
              — ESG stands for Environmental, Social and Governance, and means the underlying holdings of the ETF have
              been screened for some or all of these criteria.
            </Typo>
          </Typography>
          <Paper top={24}>
            <Typography size={desktop ? 14 : 16} color="additional">
              <Typo>If you don't know what ESG is and want to learn more, read about it in our</Typo>{' '}
              <Link to="/esg/" hard blank>
                information page.
              </Link>
            </Typography>
          </Paper>
        </span>
      }
      data-test-id="esgFilter"
    />
  )

  const filtersList = {
    ordering: orderingFilter,
    asset_class: assetClassFilter,
    provider: providerFilter,
    dividends_type: dividendsTypeFilter,
    hedged: hendegFilter,
    esg: esgFilter,
  }

  const content = (
    <FilterGroup more={desktop ? 3 : undefined} space={desktop ? undefined : 12} moreShown={moreShown}>
      {[...new Set(activeFilters)].map((filterName) => filtersList[filterName])}
    </FilterGroup>
  )

  return desktop ? content : <HorizontallyScrollable mix>{content}</HorizontallyScrollable>
}

export { SecurityUniverseFilters }
