import React from 'react'
import PropTypes from 'prop-types'
import isNumber from 'lodash/isNumber'

const AttentionModalText = ({
  increaseText = '',
  decreaseText = '',
  portfolioChangedText = '',
  recommendedIndex = null,
  desiredIndex = null,
  portfolioChanged = false,
}) => {
  const result = []

  if (isNumber(recommendedIndex) && isNumber(desiredIndex)) {
    if (desiredIndex < recommendedIndex) {
      result.push(decreaseText)
    }
    if (desiredIndex > recommendedIndex) {
      result.push(increaseText)
    }
  }

  if (portfolioChanged) {
    result.push(portfolioChangedText)
  }

  return <div>{React.Children.map(result, (text) => text)}</div>
}

AttentionModalText.propTypes = {
  increaseText: PropTypes.node,
  decreaseText: PropTypes.node,
  portfolioChangedText: PropTypes.node,
  recommendedIndex: PropTypes.number,
  desiredIndex: PropTypes.number,
  portfolioChanged: PropTypes.bool,
}

export default AttentionModalText
