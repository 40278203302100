import React from 'react'
import classNames from 'classnames/dedupe'

import rawMediaQueries from 'helpers/mediaQueries.js'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import './Layout.css'

@withMediaQueries(rawMediaQueries)
export default class Layout extends React.Component {
  static shouldBlurOnTargetTouch = (target) => {
    let shouldBlur = true

    const check = (target) => {
      if (target) {
        if (~['INPUT', 'TEXTAREA', 'BUTTON', 'LABEL'].indexOf(target.nodeName)) shouldBlur = false
        check(target.parentNode)
      }
    }

    check(target)
    return shouldBlur
  }

  manageActiveElementBlur = (event) => {
    const { target } = event

    if (!this._wasTouchMoveRegistered && Layout.shouldBlurOnTargetTouch(target)) {
      document.activeElement.blur()
    }
    this._wasTouchMoveRegistered = false
  }

  registerTouchMove = () => {
    this._wasTouchMoveRegistered = true
  }

  componentDidMount() {
    document.addEventListener('touchmove', this.registerTouchMove)
    document.addEventListener('touchend', this.manageActiveElementBlur)
  }

  componentWillUnmount() {
    document.removeEventListener('touchmove', this.registerTouchMove)
    document.removeEventListener('touchend', this.manageActiveElementBlur)
  }

  render() {
    const { className, children, mediaQueries } = this.props

    return (
      <div className={classNames(className, 'Layout', getMediaQuieryClasses('Layout', mediaQueries))}>{children}</div>
    )
  }
}

export function LayoutElement(props) {
  const { main, children, ...rest } = props

  return (
    <div className={classNames('LayoutElement', { LayoutElement_main: main })} {...rest}>
      {children}
    </div>
  )
}
