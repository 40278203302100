import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { MobileLayout } from 'components/atoms/Layouts'
import { GatewayDest } from 'components/atoms/Gateway'
import { MobileHeader } from 'app/containers/Header'
import { Title } from 'app/pages/Dashboard/Accounts/components/Title'
import { Paper } from 'components/atoms/Paper'
import NotificationGroup from 'components/organisms/Notification/NotificationGroup.tsx'
import { Empty } from 'app/pages/Dashboard/Accounts/components/Empty'

import { NotificationScopeType } from 'app/effector/notifications/models'
import { DashboardStories } from '../../Portfolios/components/DashboardStories'

import { states as clientStates } from 'constants/client'

const Mobile = ({ client, createPortfolioUrl }) => {
  const header = useMemo(() => <MobileHeader withRefresh />, [])

  const content = useMemo(
    () => (
      <Fragment>
        <Title createPortfolioUrl={createPortfolioUrl} />
        <Paper top={8}>
          <DashboardStories />
        </Paper>
        {client.state !== clientStates.NEW && (
          <NotificationGroup scope={NotificationScopeType.CLIENT}>
            {(nodes) => <Paper top={24}>{nodes}</Paper>}
          </NotificationGroup>
        )}
        <Empty createPortfolioUrl={createPortfolioUrl} />
      </Fragment>
    ),
    [client.state, createPortfolioUrl],
  )

  return (
    <Fragment>
      <MobileLayout header={header} content={content} />
      <GatewayDest name="modals" />
    </Fragment>
  )
}

Mobile.propTypes = {
  client: PropTypes.shape({ state: PropTypes.string }),
  createPortfolioUrl: PropTypes.string,
}

export { Mobile }
