import { sendError } from 'helpers/errorLogging'

function float(unknown: unknown, fractionDigits: number = 6): number {
  if (typeof unknown === 'number' || typeof unknown === 'string') {
    return parseFloat(parseFloat(`${unknown}`).toFixed(fractionDigits))
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  sendError(new Error(`Cannot convert ${unknown} to float`))

  return NaN
}

export { float }
