import React from 'react'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'

type ContentWithIconAndLinkProps = {
  to: string
  children?: React.ReactNode | React.ReactNodeArray
}

function ContentWithIconAndLink({ to, children }: ContentWithIconAndLinkProps): React.ReactElement {
  return (
    <Link to={to} mods={{ color: 'black' }}>
      <Paper top={12} right={8} bottom={12} left={12}>
        <ItemWithIcon
          space={4}
          content={children}
          iconPosition="right"
          icon={<Icon type="arrow-open-right-16" size={16} fixedSize data-content-link-icon />}
          iconVerticalAlign="center"
          contentVerticalAlign="center"
        />
      </Paper>
    </Link>
  )
}

export { ContentWithIconAndLink, type ContentWithIconAndLinkProps }
