import React, { forwardRef, Fragment } from 'react'

import FocusTrap from 'focus-trap-react'
import xor from 'lodash/xor'

import { useMediaQueries, useRef, useCallback, useImperativeHandle, useMemo, useEffect, useState } from 'hooks'

import { palette } from 'helpers/palette'

import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { VisuallyHidden } from 'components/atoms/VisuallyHidden'

import { type FilterProps, type FilterValue } from '../Filter'
import { FilterElement } from '../FilterElement'

type FilterDropdownHandle = {
  focusListBoxItem: () => void
}

type FilterDropdownProps = FilterProps & {
  className?: string
  open: boolean
  align?: 'center' | 'flex-start' | 'flex-end'
  location?: {
    query?: {
      date_from?: string
      date_to?: string
    }
  }
  dropdownMaxHeight?: number
  onClose: () => void
  onSelect: (nextSelected: Array<string | number>) => void
  onApply: (event: React.MouseEvent<HTMLButtonElement>, value: any) => void
  onSoftReset: () => void
  'data-test-id': string
}

const FilterDropdown = (
  {
    className,
    type,
    title,
    name,
    values,
    dropdownMaxHeight,
    selected,
    description,
    open,
    align = 'flex-start',
    showDescriptionInTooltip,
    onClose: handleClose,
    onSelect: handleSelect,
    onApply: handleApply,
    onSoftReset: handleSoftReset,
    'data-test-id': dataTestId,
  }: FilterDropdownProps,
  ref: React.Ref<unknown>,
): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const listBoxRef = useRef<HTMLDivElement>(null)
  const [internalOpen, setInternalOpen] = useState<boolean>(open)
  const showControls = type === 'checkbox'

  const getChecked = useCallback(
    (value: FilterValue) => {
      if (value.isDefault && selected.length < 1) {
        return true
      }

      if (value.value !== null) {
        return selected.includes(value.value)
      }

      return false
    },
    [selected],
  )

  const getHandleSelectFunction = useCallback(
    (value: string | number | null) => {
      if (type === 'radio') {
        if (value === null) {
          return () => {
            handleApply(null, [])
          }
        }

        return () => {
          handleApply(null, [value])
        }
      }

      return () => {
        handleSelect(xor(selected, [value]))
      }
    },
    [type, selected, handleSelect, handleApply],
  )

  const focusListBoxItem = useCallback(() => {
    // next tick
    setTimeout(() => {
      if (listBoxRef.current) {
        const input = listBoxRef.current.querySelector('input')

        if (input) {
          input.focus()
        }
      }
    }, 0)
  }, [listBoxRef])

  /**
   * Disallow to focus on any internal focusable element when closed, and allow when open
   */
  useEffect(() => {
    const dropdown = dropdownRef.current

    if (dropdown) {
      const targets: NodeListOf<HTMLElement> = dropdown.querySelectorAll('a,button,input,[tabIndex]')

      targets.forEach((target) => {
        target.tabIndex = open ? 0 : -1
      })
    }

    /* Needed here to trigger FocusTrap just after targets tabIndex was modified */
    setInternalOpen(open)
  }, [open, dropdownRef, setInternalOpen])

  useImperativeHandle(ref, () => ({
    focusListBoxItem,
  }))

  const elements = useMemo(
    () => (
      <div role="listbox" ref={listBoxRef}>
        {values.map((value, index) => {
          const checked = getChecked(value)
          const handleChange = getHandleSelectFunction(value.value)

          const content = (
            <Fragment>
              {value.showDescriptionInTooltip && (
                <Paper top={desktop ? 8 : 32} right={desktop ? 24 : 16} bottom={12} left={desktop ? 24 : 16}>
                  <Typography size={14} color="default" lineHeight="small" weight="bold" inline>
                    <Typo>{value.title}</Typo>
                  </Typography>
                  <Paper inline left={4}>
                    <TooltipToModal description={value.description} customIcon offset={-36} className="FilterTooltip">
                      <Paper>
                        <Icon
                          size={16}
                          type="information-16"
                          color={palette['content-on-background-minor']}
                          style={{ position: 'relative', top: '3px', left: '2px' }}
                        />
                      </Paper>
                    </TooltipToModal>
                  </Paper>
                </Paper>
              )}
              <FilterElement
                key={value.value}
                align={align}
                type={type === 'select' ? 'radio' : type}
                radioName={name}
                {...value}
                checked={checked}
                onChange={handleChange}
                data-test-id={`${dataTestId}Item${index}`}
              />
            </Fragment>
          )

          return content
        })}
      </div>
    ),
    [values, align, type, name, dataTestId, getChecked, getHandleSelectFunction, desktop],
  )

  const apply = useMemo(
    () => (
      <Button mods={{ size: 'block' }} onClick={handleApply} data-test-id={`${dataTestId}ApplyButton`}>
        Apply<VisuallyHidden> ‘{name}’ filter</VisuallyHidden>
      </Button>
    ),
    [handleApply, dataTestId, name],
  )

  /*
   `FocusTrap` has to be rendered at root to work correctly,
    his child should be constant, so `<div>` is necessary
  */
  return (
    <FocusTrap
      active={internalOpen}
      focusTrapOptions={{
        allowOutsideClick: true,
        tabbableOptions: {
          displayCheck: process.env.NODE_ENV === 'test' ? 'none' : undefined,
        },
      }}
    >
      <div ref={dropdownRef} data-test-id={dataTestId}>
        {desktop ? (
          <Width
            className={className}
            size={19}
            hidden={!open}
            aria-hidden={!open}
            style={dropdownMaxHeight ? { maxHeight: dropdownMaxHeight } : {}}
          >
            <Typography size={14} lineHeight="small">
              {showControls ? (
                <Link onClick={handleSoftReset} disabled={selected.length < 1}>
                  <Paper top={24} right={24} bottom={12} left={24}>
                    Reset<VisuallyHidden> ‘{name}’ filter</VisuallyHidden>
                  </Paper>
                </Link>
              ) : (
                <Paper top={8}></Paper>
              )}
              {showDescriptionInTooltip && (
                <Paper top={8} right={24} bottom={12} left={24}>
                  <Typography size={14} color="default" lineHeight="small" weight="bold" inline>
                    <Typo>{title} </Typo>
                  </Typography>
                  <Paper inline left={4}>
                    <TooltipToModal description={description} customIcon offset={-36} className="FilterTooltip">
                      <Icon
                        size={16}
                        type="information-16"
                        color={palette['content-on-background-minor']}
                        style={{ position: 'relative', top: '3px', left: '2px' }}
                      />
                    </TooltipToModal>
                  </Paper>
                </Paper>
              )}
              {elements}
              {!showDescriptionInTooltip && description ? (
                <Paper top={16} right={24} bottom={12} left={24}>
                  <Typography size={14} lineHeight="medium" color="minor">
                    {description}
                  </Typography>
                </Paper>
              ) : null}
              {showControls ? (
                <Paper top={12} right={24} left={24} bottom={24}>
                  {apply}
                </Paper>
              ) : (
                <Paper bottom={8}></Paper>
              )}
            </Typography>
          </Width>
        ) : (
          <MobileLayout
            header={
              <NavigationBar
                leftPartText="Reset"
                onLeftPartClick={showControls ? handleSoftReset : null}
                rightPartText="Close"
                onRightPartClick={handleClose}
                disableLeftPart={selected.length < 1}
              >
                <Typo>{title ?? name}</Typo>
              </NavigationBar>
            }
            content={
              <Fragment>
                {showDescriptionInTooltip && (
                  <Paper top={16} right={16} bottom={8} left={16}>
                    <Typography size={18} color="default" lineHeight="small" weight="bold" inline>
                      <Typo>{title} </Typo>
                    </Typography>
                    <Paper inline left={4}>
                      <TooltipToModal description={description} customIcon offset={-36}>
                        <Paper>
                          <Icon
                            size={16}
                            type="information-16"
                            color={palette['content-on-background-minor']}
                            style={{ position: 'relative', top: '2px' }}
                          />
                        </Paper>
                      </TooltipToModal>
                    </Paper>
                  </Paper>
                )}
                {elements}
                {description && !showDescriptionInTooltip && (
                  <Paper top={40} right={16} left={16}>
                    <Typography size={14} lineHeight="medium" color="minor">
                      {description}
                    </Typography>
                  </Paper>
                )}
              </Fragment>
            }
            footer={showControls ? <MobileLayoutFooterButton>{apply}</MobileLayoutFooterButton> : null}
            contentPaperSizes={{ top: 16, bottom: 16, left: 0, right: 0 }}
          />
        )}
      </div>
    </FocusTrap>
  )
}

const FilterDropdownWithForwardedRef = forwardRef<FilterDropdownHandle, FilterDropdownProps>(FilterDropdown)

export { FilterDropdownWithForwardedRef as FilterDropdown, type FilterDropdownHandle, type FilterDropdownProps }
