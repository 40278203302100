import React from 'react'

import { urlTo } from 'helpers/router.js'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Typography } from 'components/atoms/Typography'

import { regulatoryTypesText } from 'constants/portfolio'

type CreateNewPortfolioLinkProps = {
  regulatoryType: string
  canOpenIsaOrSipp: boolean
}

const CreateNewPortfolioLink = ({
  regulatoryType,
  canOpenIsaOrSipp,
}: CreateNewPortfolioLinkProps): React.ReactElement => {
  return (
    <Link
      to={urlTo('create-new-portfolio', {}, canOpenIsaOrSipp ? { regulatoryType } : {})}
      style={{ display: 'flex' }}
      data-test-id={`addNewPortfolioButton${regulatoryType}`}
    >
      <ItemWithIcon
        inline
        space={12}
        icon={<Icon type="plus-in-an-outlined-circle-32" size={32} color="inherit" />}
        content={
          <Typography
            size={14}
            lineHeight="small"
            color="inherit"
            data-test-id={`addNewPortfolioButton${regulatoryType}Text`}
          >
            <Typo>Create {regulatoryTypesText[regulatoryType]} portfolio</Typo>
          </Typography>
        }
        contentVerticalAlign="center"
      />
    </Link>
  )
}

export { CreateNewPortfolioLink }
