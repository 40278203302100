import React from 'react'

import { useMediaQueries, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { palette } from 'helpers/palette/'

import { closeNotificationFx } from 'app/effector/notifications'

import { showFailToast } from 'app/redux/actions/ui'

import Icon from 'components/_old/Icon/Icon.jsx'

import { Paper } from 'components/atoms/Paper'

import { Story } from 'components/organisms/Stories/components/Story'

import './PromoNotification.css'

import { useDashboardStories } from 'app/pages/Dashboard/Accounts/Portfolios/components/useDashboardStories'

const PromoNotification = ({ title, action, image, code }): React.ReactElement => {
  const { stories, customStoriesActions } = useDashboardStories({ shouldFetchStories: false })
  const { desktop } = useMediaQueries()

  const [isStoryOpen, setIsStoryOpen] = useState(false)

  const promoStory = stories.find((story) => story.id === action?.params?.story_id)

  const closePanel = (): void => {
    try {
      closeNotificationFx(code)
    } catch (error) {
      showFailToast()
    }
  }

  const navigateToStorie = (): void => {
    if (action?.link !== 'dashboard.stories' || !promoStory) return
    trackEvent({ action: 'banner_clicked', code })
    setIsStoryOpen(true)
  }

  return (
    <div className="PromoNotification" onClick={navigateToStorie}>
      <Paper
        top={desktop ? 16 : 12}
        bottom={desktop ? 16 : 12}
        left={desktop ? 40 : 16}
        right={desktop ? 16 : 16}
        className="PromoNotification_content"
      >
        {title}
        {image && (
          <Paper left={desktop ? 80 : 16}>
            <img width="64px" height="64px" src={image} />
          </Paper>
        )}
        <Paper
          left={16}
          style={{ alignSelf: 'start' }}
          onClick={(event) => {
            event.stopPropagation()
            closePanel()
          }}
        >
          <Icon type="cross-16" color={palette['content-on-color-white']} size={16} />
        </Paper>
      </Paper>
      <Story
        id={promoStory?.id}
        isOpen={isStoryOpen}
        steps={promoStory?.steps ?? []}
        customActions={customStoriesActions}
        handleClose={() => {
          setIsStoryOpen(false)
        }}
        handleFinish={() => {
          setIsStoryOpen(false)
        }}
      />
    </div>
  )
}

export { PromoNotification }
