import React, { forwardRef } from 'react'

import classNames from 'classnames/dedupe'

import './Relative.css'

type RelativeOverflow = 'hidden'

type RelativeProps = React.HTMLProps<HTMLDivElement> & {
  children: React.ReactNode
  className?: string
  overflow?: RelativeOverflow
  mix?: boolean
}

const Relative = forwardRef<HTMLDivElement, RelativeProps>(
  ({ children, className, overflow, mix = false, ...rest }: RelativeProps, ref): React.ReactElement => {
    const classes = classNames(className, 'Relative', { [overflow ? `Relative_overflow_${overflow}` : '']: overflow })

    if (mix && React.isValidElement(children)) {
      return React.cloneElement(children, { className: classes, ...rest, ref })
    }

    return (
      <div ref={ref} className={classes} {...rest}>
        {children}
      </div>
    )
  },
)

Relative.displayName = 'Relative'

export { Relative, type RelativeProps }
