import './highlightElement.css'

const hightlightElement = ({ element, withBackground }): void => {
  if (!element) return

  element?.classList.add('ElementHighlighter')

  setTimeout(() => {
    element?.classList.add('ElementHighlighter_transition')
  }, 30)

  setTimeout(() => {
    element?.classList.add('ElementHighlighter_outline')
    if (withBackground) element?.classList.add('ElementHighlighter_background')
  }, 60)

  setTimeout(() => {
    element?.classList.remove('ElementHighlighter_outline')
    element?.classList.remove('ElementHighlighter_background')
    setTimeout(() => {
      element?.classList.add('ElementHighlighter_outline')
      if (withBackground) element?.classList.add('ElementHighlighter_background')
      setTimeout(() => {
        element?.classList.remove('ElementHighlighter_outline')
        element?.classList.remove('ElementHighlighter_background')
        setTimeout(() => {
          element?.classList.remove('ElementHighlighter_transition')
          element?.classList.remove('ElementHighlighter')
        }, 300)
      }, 300)
    }, 300)
  }, 600)
}

export { hightlightElement }
