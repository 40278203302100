import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { getKeyOrDefault } from 'helpers/object'
import { goTo, urlTo } from 'helpers/router.js'
import { hardCodedCountries } from 'helpers/hardCodedCounties'

import { useMediaQueries, useState, useSelector, useCallback, useActions, useEffect } from 'hooks'
import { useUnit } from 'effector-react'

import Width from 'components/_old/Width/Width'
import Segment from 'components/atoms/Segment/Segment.jsx'
import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Hr } from 'components/atoms/Hr'
import { YearlyReviewRetakeForm } from 'components/organisms/YearlyReviewRetakeForm'
import Sticked from 'components/molecules/Sticked/Sticked.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'

import ChangeCompanyAddress from 'app/pages/Forms/ChangeCompanyAddress.jsx'
import ChangeAddress from 'app/pages/Forms/ChangeAddress.jsx'

import { LayoutHeadline } from '../LayoutHeadline'
import { PersonalInformation } from './PersonalInformation'
import { BusinessInformation } from './BusinessInformation'

import { showSuccessToast, showFailToast } from 'app/redux/actions/ui'

import { types as clientTypes } from 'constants/client'

import { sendMessage as sendMessageActionCreator } from 'app/redux/actions/client'

import { $bankAccountsStore, fetchBankAccountsFx } from 'app/effector/bank-accounts'
import { $owner } from 'app/effector/contacts'

import { isClientNotCompleted } from 'app/redux/selectors'

const states = {
  DEFAULT: 'DEFAULT',
  CHANGES_ACTIVE: 'CHANGES_ACTIVE',
  CHANGES_FINISHED: 'CHANGES_FINISHED',
}

const Information = ({ backLinkUrl, backLinkText, params }) => {
  const { desktop } = useMediaQueries()

  const [state, setState] = useState(states.DEFAULT)
  const showForm = [states.CHANGES_ACTIVE, states.CHANGES_FINISHED].includes(state)

  const client = useSelector((state) => state.client)
  const clientNotCompleted = useSelector(isClientNotCompleted)
  const owner = useUnit($owner) ?? {}
  // quick fix for https://app.asana.com/0/1202304843784991/1202635259316452/f
  const countries = hardCodedCountries // useSelector((state) => state.dicts.countries)
  const { bankAccounts } = useUnit($bankAccountsStore)
  const hasBankAccounts = !!bankAccounts.length
  const email = client.email
  const name = `${owner?.first_name || ''} ${owner?.last_name || ''}`.trim()

  const [sendMessage] = useActions([sendMessageActionCreator])

  const nextStepUrl = urlTo('yearly-review.questionnaire', params)

  const updateAddressUrl = getKeyOrDefault(
    {
      [clientTypes.INDIVIDUAL]: urlTo('yearly-review.change-address', params),
      [clientTypes.BUSINESS]: urlTo('yearly-review.change-company-address', params),
      default: null,
    },
    client.type,
  )

  const headline = getKeyOrDefault(
    {
      [clientTypes.INDIVIDUAL]: 'Please review your personal information',
      [clientTypes.BUSINESS]: 'Please review your business information',
      default: 'Please review your information',
    },
    client.type,
  )

  useEffect(() => {
    if (clientNotCompleted) {
      goTo(nextStepUrl)
    }
    if (!(hasBankAccounts || clientNotCompleted)) {
      fetchBankAccountsFx()
    }
  }, [])

  const getFormattedAddress = useCallback((address) => address?.getAddressString(countries) || '', [countries])

  const handleAddressModalClose = useCallback(() => {
    goTo(urlTo('yearly-review.information', params), { replace: true, scrollToTop: false })
  }, [params])

  const handleShowForm = useCallback(() => setState(states.CHANGES_ACTIVE), [setState])
  const handleHideForm = useCallback(() => setState(states.DEFAULT), [setState])
  const handleSendForm = useCallback(
    async (message) => {
      const enhancedMessage = `YEARLY REVIEW:\n\n${message}`

      try {
        const nextState = await sendMessage({
          message: enhancedMessage,
          email,
          name,
        })

        if (nextState.client.error) {
          throw nextState.client.error
        }

        showSuccessToast('Message successfully sent')
        setState(states.CHANGES_FINISHED)
      } catch (error) {
        showFailToast()
      }
    },
    [email, name, setState],
  )

  if (!owner?.addresses) {
    return null
  }

  const buttons = (state === states.DEFAULT || state === states.CHANGES_FINISHED) && (
    <Fragment>
      {state === states.DEFAULT && (
        <Width size={desktop ? 28 : null} center>
          <ButtonGroup
            columnarLayoutMods={{ padding: desktop ? 'small' : 'modest' }}
            flexDirection={desktop ? null : 'column'}
            buttonMods={{ size: 'big block' }}
          >
            <Button
              mods={{ theme: 'simple-reverse-blue' }}
              onClick={handleShowForm}
              data-test-id="yearlyReviewChangeInformation"
            >
              Change information
            </Button>
            <Button mods={{ color: 'blue' }} data-test-id="yearlyReviewConfirmInformation">
              <Link to={nextStepUrl}>Confirm information</Link>
            </Button>
          </ButtonGroup>
        </Width>
      )}
      {state === states.CHANGES_FINISHED && (
        <Width size={desktop ? 14 : null} center>
          <Button mods={{ size: 'big block' }}>
            <Link to={nextStepUrl} data-test-id="yearlyReviewInformationContinue">
              Continue
            </Link>
          </Button>
        </Width>
      )}
    </Fragment>
  )

  return (
    <Fragment>
      <div>
        <LayoutHeadline headline={headline} backLinkText={backLinkText} backLinkUrl={backLinkUrl} />
        <Segment
          mods={{
            margin: desktop ? 'double' : null,
            noMargin: desktop ? 'top' : null,
          }}
        >
          <Width size={desktop ? 42 : null} center>
            {client.type === clientTypes.INDIVIDUAL && (
              <PersonalInformation getFormattedAddress={getFormattedAddress} />
            )}
            {client.type === clientTypes.BUSINESS && <BusinessInformation getFormattedAddress={getFormattedAddress} />}
          </Width>
        </Segment>
        {showForm && (
          <Fragment>
            <Segment mods={{ margin: 'hefty' }}>
              <Width size={desktop ? 42 : null} center>
                <Hr />
              </Width>
            </Segment>
            <Segment
              mods={{
                margin: desktop ? 'double' : null,
                noMargin: desktop ? 'top' : 'top bottom',
              }}
            >
              <Width size={desktop ? 42 : null} center>
                <YearlyReviewRetakeForm
                  onCancel={handleHideForm}
                  onSend={handleSendForm}
                  updateAddressUrl={updateAddressUrl}
                />
              </Width>
            </Segment>
          </Fragment>
        )}
        {desktop && <Segment mods={{ margin: 'double' }}>{buttons}</Segment>}
        {!desktop && !showForm && (
          <Sticked into="afterLayout" newButton>
            <Inner>{buttons}</Inner>
          </Sticked>
        )}
      </div>
      <ChangeAddress onClose={handleAddressModalClose} owner={owner} />
      <ChangeCompanyAddress onClose={handleAddressModalClose} />
    </Fragment>
  )
}

Information.propTypes = {
  backLinkUrl: PropTypes.string.isRequired,
  backLinkText: PropTypes.string.isRequired,
  params: PropTypes.object,
}

export { Information }
