import { useUnit } from 'effector-react'
import xor from 'lodash/xor'

import {
  useMemo,
  useEffect,
  useLoading,
  useRedirect,
  useCallback,
  useSelector,
  useMemorizedState,
  MemorizedStateStorages,
} from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { trackEvent } from 'helpers/analytics'
import { goTo, urlTo } from 'helpers/router.js'

import { $collectionsStore } from 'app/effector/discover-etfs'
import {
  addSecurity,
  removeSecurity,
  fetchPortfolioSecuritiesFx,
  $portfolioSecuritiesStore,
} from 'app/effector/portfolio-securities'
import { setSecurityAsViewedFx } from 'app/effector/recently-viewed-etfs'
import { fetchSecurityFx, $securitiesStore } from 'app/effector/securities'

import { showSuccessToast } from 'app/redux/actions/ui'
import { getFlatSortedPortfoliosList } from 'app/redux/selectors'

import { useSlowSecurityMention } from 'app/pages/Dashboard/Goals/DIY/SlowSecurityMention'
import { useCancel, useSecurities } from 'app/pages/Securities/hooks'
import { useSecurityAnalytics } from 'app/pages/Securities/Security/hooks/useSecurityAnalytics.js'

import { useSecurityChart } from './useSecurityChart.js'
import { useSecurityRestrictions } from './useSecurityRestrictions.js'
import { useTotalReturns } from './useTotalReturns'

import { manageTypes } from 'constants/portfolio'

const SECURITY_VERSION = {
  PRIVATE: 'PRIVATE',
  INSIDE_PORTFOLIO: 'INSIDE_PORTFOLIO',
}

const gaButtonClickEventsFromCollection = {
  'Featured partners': {
    from: 'featured_partners',
    collectionIdParameter: 'featured_partner_id',
  },
  Collections: {
    from: 'collection',
    collectionIdParameter: 'collection_id',
  },
  'Industries & Sectors': {
    from: 'sector',
    collectionIdParameter: 'sector_id',
  },
}

const useSecurity = (id, query) => {
  const { getSecurityById } = useUnit($securitiesStore)
  const { portfolioSecurities: portfolioSecuritiesCollection } = useUnit($portfolioSecuritiesStore)

  // all non-closed portfolios
  const allDiyPortfolios = useSelector((state) => state.portfolios.list.filterByShape({ manage_type: manageTypes.DIY }))
  const allDiyPortfoliosIds = [...allDiyPortfolios.map((portfolio) => portfolio.id)]
  const allVisibleDiyPortfolios = useSelector(getFlatSortedPortfoliosList).filterByShape({
    manage_type: manageTypes.DIY,
  })

  // current portfolio
  const currentPortfolioId = parseInt(query?.portfolioId, 10)
  const isFromPreset = query?.isFromPreset === 'true'
  const currentPortfolio = allDiyPortfolios.get(currentPortfolioId)

  // attempted portfolio
  const [attemptedPortfolioId, setAttemptedPortfolioId] = useMemorizedState(`AttemptedPortfolioId`, null, {
    storage: MemorizedStateStorages.SESSION,
    rewriteWithInitial: true,
  })

  const { getCollectionBySlugOrId } = useUnit($collectionsStore)

  const handleAdditionalRestrictionAction = useCallback(
    (portfolioId) => {
      setAttemptedPortfolioId(portfolioId)
    },
    [setAttemptedPortfolioId],
  )

  // security info
  const { isLoading: isDetailedSecurityInfoLoading, wait: waitForDetailedSecurityInfo } = useLoading(true)
  const security = getSecurityById(id)
  const { portfolioSecurities } = useSecurities({ portfolioId: currentPortfolioId, perspective: true })
  const { chartData, selectedPeriod, handlePeriodSelect, periodPriceData } = useSecurityChart({
    security,
  })
  const { totalReturnsData } = useTotalReturns({ security })
  const isAddedToCurrentPortfolio = useMemo(
    () => !!portfolioSecurities.find((item) => item.id === id),
    [id, portfolioSecurities],
  )

  const showInvestNowButton =
    (!currentPortfolio || currentPortfolio.state === 'NEW') && portfolioSecurities.length === 0

  useEffect(() => {
    waitForDetailedSecurityInfo(fetchSecurityFx({ id }))
    setSecurityAsViewedFx({ securityId: id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (!security) return
    trackEvent({
      action: 'view_item',
      currency: 'GBP',
      quantity: 1,
      value: null,
      payment_type: null,
      price: null,
      item_id: security.id,
      item_name: security.title,
      item_brand: security.provider_filter_name,
      item_category: query?.collection ?? 'all-etfs',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [security])

  useRedirect({
    to: 'dashboard.securities',
    rules: !id,
  })

  // security analytics
  const {
    isLoading: isAnalyticsLoading,
    holdings,
    sectors,
    regions,
    filters,
    searchValue,
    handleSearch,
  } = useSecurityAnalytics(id, query, 'holdingSearch')
  const goToSecurityAnalytics = useCallback(
    (type) => {
      goTo(urlTo(`securities.security.security-analytics.${type}`, { id }, { ...query }))
    },
    [id, query],
  )

  const handleCloseAnalytics = useCallback(() => {
    goTo(urlTo('securities.security', { id }, { ...query }))
  }, [id, query])

  // other portfolios
  const { isLoading: isOtherPortfoliosSecuritiesLoading, wait: waitForOtherPortfoliosSecurities } = useLoading(false)
  const portfoliosWithThisSecurityIds = portfolioSecuritiesCollection.getPortfolioIdsWithSecurity(id)
  const portfoliosWithThisSecurity = allVisibleDiyPortfolios.filter((portfolio) =>
    portfoliosWithThisSecurityIds.includes(portfolio.id),
  )
  const isPortfoliosFetched = !!allDiyPortfoliosIds.length
  useEffect(
    () => {
      if (allDiyPortfolios.length > 0) {
        const nonCurrentPortfolioIds = allVisibleDiyPortfolios
          .getArrayOfPortfolioIds()
          .filter((id) => id !== currentPortfolioId)

        if (nonCurrentPortfolioIds.length > 0) {
          const nonCurrentPortfolioIdsWithFetchedSecurities = Object.keys(portfolioSecuritiesCollection)
            .map((string) => parseInt(string))
            .filter((id) => id !== currentPortfolioId)
          const nonCurrentPortfolioIdsWithNotFetchedSecurities = xor(
            nonCurrentPortfolioIds,
            nonCurrentPortfolioIdsWithFetchedSecurities,
          )
          waitForOtherPortfoliosSecurities(
            Promise.all(
              nonCurrentPortfolioIdsWithNotFetchedSecurities.map(
                async (id) => await fetchPortfolioSecuritiesFx({ portfolioId: id }),
              ),
            ),
          )
        }
      }
    },
    // only trigger on after portfolios fetch or on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPortfoliosFetched],
  )

  // handles
  const handleBack = useCallback(() => {
    const { back, collection, fromDiscovery } = query

    if (back) {
      goTo(back)
      return
    }

    if (collection) {
      goTo(
        urlTo(
          'security-collection',
          { slugOrId: collection },
          querystringFromObject({ ...query, collection: null, holdingSearch: null, regions: null, sectors: null }),
        ),
      )
      return
    }

    if (fromDiscovery) {
      goTo(
        urlTo(
          'securities',
          null,
          querystringFromObject({ ...query, holdingSearch: null, regions: null, sectors: null, fromDiscovery: null }),
        ),
        {
          scrollToTop: false,
        },
      )
      return
    }

    goTo(
      urlTo(
        'securities.universe-all',
        null,
        querystringFromObject({ ...query, holdingSearch: null, regions: null, sectors: null }),
      ),
      {
        scrollToTop: false,
      },
    )
  }, [query])
  const { handleCancel, abandonModalOpen, handleAbandonModalClose } = useCancel({ portfolio: currentPortfolio, query })
  const handleAddSecurityRaw = useCallback(
    (e, portfolioId = currentPortfolioId) => {
      addSecurity({ securityId: id, portfolioId, addedFrom: query?.collection || 'all-etfs' })

      if (query?.collection) {
        query.back = query.fromCollection
          ? urlTo('security-collection', { slugOrId: query.fromCollection }, { portfolioId })
          : null

        goTo(
          urlTo(
            'security-collection',
            { slugOrId: query.collection },
            {
              ...query,
              portfolioId,
              collection: null,
              holdingSearch: null,
              regions: null,
              sectors: null,
            },
          ),
        )

        showSuccessToast('Added to portfolio')
        return
      }

      if (query?.fromDiscovery) {
        query.back = null

        goTo(
          urlTo(
            'securities',
            null,
            querystringFromObject({
              ...query,
              portfolioId,
              holdingSearch: null,
              regions: null,
              sectors: null,
              fromDiscovery: null,
            }),
          ),
          {
            scrollToTop: false,
          },
        )

        showSuccessToast('Added to portfolio')
        return
      }

      goTo(
        urlTo(
          'securities.universe-all',
          null,
          querystringFromObject({ ...query, portfolioId, regions: null, sectors: null }),
        ),
        {
          scrollToTop: false,
        },
      )

      showSuccessToast('Added to portfolio')
    },
    [currentPortfolioId, id, query],
  )

  const handleRemoveSecurity = useCallback(
    (securityId) => () => {
      removeSecurity({ securityId, portfolioId: currentPortfolioId })
      showSuccessToast('ETF has been removed')
    },
    [currentPortfolioId],
  )

  const handleInvestNow = useCallback(() => {
    trackEvent({ action: 'sp_investnow_sp_or_ls_opened' })

    if (query?.collection) {
      const { collection, group } = getCollectionBySlugOrId(query?.collection)

      if (collection && group) {
        trackEvent({
          action: 'etfrange_etf_page_invest_now_clicked',
          from: gaButtonClickEventsFromCollection[group.title]?.from,
          [gaButtonClickEventsFromCollection[group.title]?.collectionIdParameter || 'collection_id']: collection.id,
        })
      }
    } else {
      trackEvent({ action: 'etfrange_etf_page_invest_now_clicked', from: 'all_etfs' })
    }

    trackEvent({
      action: 'add_to_cart',
      currency: 'GBP',
      quantity: 1,
      price: null,
      value: null,
      payment_type: null,
      item_category: query?.collection || 'all-etfs',
      item_id: security.id,
      item_name: security.title,
      item_brand: security.provider_filter_name,
    })

    goTo(urlTo(`securities.security`, { id }, { ...query, quickStart: true }))
  }, [id, query, getCollectionBySlugOrId, security])

  const sendGaEventOnAddToPortfolioClick = () => {
    if (query?.collection) {
      const { collection, group } = getCollectionBySlugOrId(query?.collection)

      if (collection && group) {
        trackEvent({
          action: 'etfrange_etf_page_add_to_ptf_clicked',
          from: gaButtonClickEventsFromCollection[group.title]?.from,
          [gaButtonClickEventsFromCollection[group.title]?.collectionIdParameter || 'collection_id']: collection.id,
        })
      }
    } else {
      trackEvent({ action: 'etfrange_etf_page_add_to_ptf_clicked', from: 'all_etfs' })
    }

    trackEvent({
      action: 'add_to_cart',
      currency: 'GBP',
      quantity: 1,
      price: null,
      value: null,
      payment_type: null,
      item_category: query?.collection || 'all-etfs',
      item_id: security.id,
      item_name: security.title,
      item_brand: security.provider_filter_name,
    })
  }

  const handleCloseQuickStart = useCallback(() => {
    const { quickStart, back, ...restQuery } = query
    goTo(urlTo(`securities.security`, { id }, { ...restQuery }))
  }, [id, query])

  const { isSlowSecurity, isSlowSecurityMentionOpen, handleSlowSecurityMentionOpen, handleSlowSecurityMentionClose } =
    useSlowSecurityMention(security)

  // restrictions
  const { restrictionModalOpen, restrictionModalType, handleRestrictionModalClose, handleAddSecurity } =
    useSecurityRestrictions(
      currentPortfolioId,
      isSlowSecurity ? handleSlowSecurityMentionOpen : handleAddSecurityRaw,
      handleAdditionalRestrictionAction,
    )

  // page version
  const version = useMemo(() => {
    if (query?.portfolioId) {
      return SECURITY_VERSION.INSIDE_PORTFOLIO
    }

    return SECURITY_VERSION.PRIVATE
  }, [query?.portfolioId])

  return {
    version,
    isAnalyticsLoading,
    isDetailedSecurityInfoLoading,
    isOtherPortfoliosSecuritiesLoading,
    security,
    attemptedPortfolioId,
    portfoliosWithThisSecurity,
    isAddedToCurrentPortfolio,
    allVisibleDiyPortfolios,
    periodPriceData,
    chartData,
    selectedPeriod,
    totalReturnsData,
    holdings,
    sectors,
    regions,
    filters,
    abandonModalOpen,
    restrictionModalOpen,
    restrictionModalType,
    isSlowSecurityMentionOpen,
    currentPortfolio,
    searchValue,
    showInvestNowButton,
    handleSearch,
    isFromPreset,
    handleRemoveSecurity,
    handleSlowSecurityMentionClose,
    handleAbandonModalClose,
    handleRestrictionModalClose,
    handlePeriodSelect,
    handleAddSecurity,
    handleAddSecurityRaw,
    handleBack,
    handleCancel,
    goToSecurityAnalytics,
    handleCloseAnalytics,
    handleInvestNow,
    handleCloseQuickStart,
    sendGaEventOnAddToPortfolioClick,
  }
}

export { useSecurity, SECURITY_VERSION }
