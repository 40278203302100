import React from 'react'

import { useActions, useCallback, useMediaQueries, useRedirect, useSelector } from 'hooks'

import Validate from 'components/_old/Validate/Validate.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Text from 'components/_old/Text/Text.jsx'
import Width from 'components/_old/Width/Width'
import Link from 'components/_old/Link/Link.jsx'
import Card from 'components/_old/Card/Card.jsx'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { changeField as changeFieldActionCreator } from 'app/redux/actions/ui'
import LegalForm from 'app/pages/Forms/LegalForm.jsx'

const LegalInfoForm = ({ ...rest }) => {
  const changeUiField = useActions(changeFieldActionCreator)
  const { desktop } = useMediaQueries()
  const client = useSelector((state) => state.client)
  const agreed = useSelector((state) => state.ui.fields.agree_with_terms_and_conditions)

  useRedirect({
    to: 'setup-account.individual.finances-info',
    rule: !client?.employment_status && !client?.wealth_source,
    isLoading: client?.didInvalidate,
  })

  const handleAgreedChange = useCallback(
    (value) => {
      changeUiField({ agree_with_terms_and_conditions: value })
      window.ttq.track('CompleteRegistration')
    },
    [changeUiField],
  )

  const renderDocumentsLink = (title, url, dataTestId) => {
    return (
      <Link to={url} hard blank rel="noopener noreferrer" data-test-id={dataTestId}>
        <Text small>
          <Typo>{title}</Typo>
        </Text>
      </Link>
    )
  }

  const getField = (validation) => {
    return (
      <div key="links">
        <Text small>
          <Typo>
            Before deciding to invest you should understand&#x200b; the investment management and custody services we
            provide —&nbsp;and their associated risks —&nbsp;by reading our Terms and Conditions and Risk Disclosure
            Statement.
          </Typo>
        </Text>
        <ul className="List_common">
          <li>{renderDocumentsLink('Terms and Conditions', '/terms/', 'regulatoryLink')}</li>
          <li>{renderDocumentsLink('Risk Disclosure Statement', '/risk-disclosure/', 'regulatoryLink')}</li>
          <li>{renderDocumentsLink('Portfolios - Key features', '/key-features-documents/', 'keyFeatureLink')}</li>
        </ul>
        <Width size={desktop ? 22 : null}>
          <Validate rules={validation.agreed.rules}>
            <CheckboxWithLabel
              label={
                <Typo>
                  I confirm I have accurately represented my personal information, and have read and agree to the above
                  Terms and Conditions and Risk Disclosure Statement
                </Typo>
              }
              onChange={handleAgreedChange}
              checked={agreed}
              tabIndex={8}
              size="smaller"
              data-test-id="legalFormAgreedInput"
            />
          </Validate>
        </Width>
      </div>
    )
  }

  const additionalFields = {
    post: {
      agreed: {
        rules: [agreed],
        errors: ['You must agree with terms and conditions'],
        getField,
      },
    },
  }

  const form = <LegalForm additionalFields={additionalFields} {...rest} />

  if (desktop) {
    return (
      <Card>
        <Width size={22}>{form}</Width>
      </Card>
    )
  }

  return <Width>{form}</Width>
}

export default LegalInfoForm
