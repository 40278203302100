import React, { Fragment } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import { Preloader } from 'components/molecules/Preloader'

import { RegulatoryTypeModal } from 'app/pages/Dashboard/Goals/DIY/EditWeights/RegulatoryTypeModal'

import { AmountStep } from './components/AmountStep'
import { DayOfMonthStep } from './components/DayOfMonthStep'
import { FrequencyStep } from './components/FrequencyStep'
import { SavingsPlanProgress } from './components/SavingsPlanProgress'
import { StartDayStep } from './components/StartDayStep'
import { useSavingsPlanSetup, steps } from './useSavingsPlanSetupModal'

import './SavingsPlanSetup.css'

type SavingsPlanSetupProps = {
  isOpen: boolean
  isLoading?: boolean
  needToChooseRegulatoryType: boolean
  analyticsData: Record<string, undefined>
  onClose: () => void
  onBack: () => void
  onContinue: () => Promise<void>
}

const titles = {
  FREQUENCY: 'Select frequency',
  START_DAY: 'Choose a day',
  DAY_OF_MONTH: 'Choose a day',
  AMOUNT: 'Amount',
}

const SavingsPlanSetupModal = ({
  isOpen,
  isLoading,
  needToChooseRegulatoryType,
  analyticsData,
  onClose: handleClose,
  onBack: handleGoBack,
  onContinue: handleContinue,
}: SavingsPlanSetupProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    step,
    frequency,
    startDay,
    monthlyPaymentDayValidation,
    monthlyPaymentDay,
    monthlyPaymentDayInputRef,
    amount,
    amountValidation,
    amountInputRef,
    handleBack,
    handleSelectFrequency,
    handleSelectStartDay,
    handleInputMonthlyPaymentDay,
    handleMonthlyPaymentDayContinue,
    handleInputAmount,
    handleAmountContinue,
    handleSubmitRegulatoryType,
  } = useSavingsPlanSetup({
    isOpen,
    needToChooseRegulatoryType,
    analyticsData,
    handleContinue,
    handleGoBack,
  })

  const headerNode = (
    <NavigationBar
      leftPartText="Back"
      onLeftPartClick={handleBack}
      rightPartText="Cancel"
      onRightPartClick={handleClose}
      linkStyle="onColorWhiteToRed"
      plain
    >
      <Typography
        size={desktop ? 20 : 16}
        lineHeight="small"
        weight="semibold"
        align="center"
        color="white"
        data-test-id="SavingsPlanModalTitle"
      >
        <Typo>Set up a Savings Plan</Typo>
      </Typography>
    </NavigationBar>
  )

  const titleNode = (
    <Fragment>
      <Typography
        size={36}
        lineHeight="small"
        weight="semibold"
        align="center"
        color="white"
        data-test-id="savingsPlanModalStepTitle"
      >
        <Typo>{titles[step]}</Typo>
      </Typography>
      <Paper top={32}>
        <SavingsPlanProgress
          step={step}
          frequency={frequency}
          startDay={startDay}
          monthlyPaymentDay={monthlyPaymentDay}
        />
      </Paper>
    </Fragment>
  )

  const monthlyPaymentDayContinueButton = useMemo(
    () => (
      <Paper className="SavingsPlanSetup_ButtonBackground">
        <Button
          type="submit"
          onClick={handleMonthlyPaymentDayContinue}
          mods={{ size: 'big block', color: 'green' }}
          data-test-id="continueButton"
        >
          Continue
        </Button>
      </Paper>
    ),
    [handleMonthlyPaymentDayContinue],
  )

  const amountContinueButton = useMemo(
    () => (
      <Paper className="SavingsPlanSetup_ButtonBackground">
        <Button
          type="submit"
          onClick={handleAmountContinue}
          mods={{ size: 'big block', color: 'green' }}
          data-test-id="continueButton"
        >
          Continue
        </Button>
      </Paper>
    ),
    [handleAmountContinue],
  )

  const optionsNode = useMemo(() => {
    if (step === steps.FREQUENCY) {
      return (
        <Paper top={16} bottom={32}>
          <FrequencyStep onSelectOption={handleSelectFrequency} />
        </Paper>
      )
    }

    if (step === steps.START_DAY) {
      return (
        <Paper top={16} bottom={32}>
          <StartDayStep onSelectOption={handleSelectStartDay} />
        </Paper>
      )
    }

    if (step === steps.DAY_OF_MONTH) {
      return (
        <Paper top={56} bottom={56}>
          <DayOfMonthStep
            value={monthlyPaymentDay}
            validation={monthlyPaymentDayValidation}
            onChange={handleInputMonthlyPaymentDay}
            inputRef={monthlyPaymentDayInputRef}
          />
          {desktop && (
            <Paper top={80}>
              <Width size={21} center>
                {monthlyPaymentDayContinueButton}
              </Width>
            </Paper>
          )}
        </Paper>
      )
    }

    if (step === steps.AMOUNT) {
      return (
        <Paper top={56} bottom={56}>
          <AmountStep
            value={amount}
            validation={amountValidation}
            onChange={handleInputAmount}
            inputRef={amountInputRef}
          />
          {desktop && (
            <Paper top={80}>
              <Width size={21} center>
                {amountContinueButton}
              </Width>
            </Paper>
          )}
        </Paper>
      )
    }

    return null
  }, [
    desktop,
    step,
    amount,
    amountValidation,
    monthlyPaymentDay,
    handleSelectFrequency,
    handleSelectStartDay,
    handleInputMonthlyPaymentDay,
    monthlyPaymentDayValidation,
    monthlyPaymentDayContinueButton,
    handleInputAmount,
    amountContinueButton,
    monthlyPaymentDayInputRef,
    amountInputRef,
  ])

  const footer = useMemo(() => {
    if (step === steps.DAY_OF_MONTH) {
      return <MobileLayoutFooterButton>{monthlyPaymentDayContinueButton}</MobileLayoutFooterButton>
    }

    if (step === steps.AMOUNT) {
      return <MobileLayoutFooterButton>{amountContinueButton}</MobileLayoutFooterButton>
    }

    return null
  }, [step, monthlyPaymentDayContinueButton, amountContinueButton])

  if (step === steps.REGULATORY_TYPE) {
    return (
      <Fragment>
        <Preloader loading={isLoading} background="background" zIndex />
        <RegulatoryTypeModal open onSubmit={handleSubmitRegulatoryType} onClose={handleClose} zIndex={999} />
      </Fragment>
    )
  }

  if (desktop) {
    return (
      <Modal open={isOpen} background="primary" close={null} onClose={handleClose}>
        <Preloader loading={isLoading} background="background" zIndex />
        <Width size={36} style={{ minHeight: 660 }}>
          <Paper top={20} right={24} bottom={20} left={24}>
            {headerNode}
          </Paper>
          <Paper top={48}>
            {titleNode}
            {optionsNode}
          </Paper>
        </Width>
      </Modal>
    )
  }

  return (
    <Modal open={isOpen} background="primary" onClose={handleClose}>
      <Preloader loading={isLoading} background="background" zIndex />
      <MobileLayout
        header={headerNode}
        content={
          <Fragment>
            <Paper top={8}>{titleNode}</Paper>
            <AllCenter>{optionsNode}</AllCenter>
          </Fragment>
        }
        footer={footer}
      />
    </Modal>
  )
}

export { SavingsPlanSetupModal }
