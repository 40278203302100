import React from 'react'
import classNames from 'classnames/dedupe'

import { propTypes } from 'helpers/propTypes'

import Card from 'components/_old/Card/Card.jsx'

import './JoinCards.css'

const JoinCards = ({ children }) => (
  <div className="JoinCards">
    {React.Children.map(children, (child) =>
      React.cloneElement(child, {
        ...child.props,
        className: classNames('JoinCards-Card', child.props.className),
      }),
    )}
  </div>
)

const joinCardsPropTypes = {
  children: propTypes.childrenOfType([Card]),
}

JoinCards.propTypes = joinCardsPropTypes

export { joinCardsPropTypes as propTypes }
export default JoinCards
