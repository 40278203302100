import { useEffect } from 'react'

import { useState, useCallback } from 'hooks'

import { isTouch } from 'helpers/mobile'

import {
  type AbstractPointOfInterest,
  isPointOfInterestAlike,
  PointOfInterestCardPositions,
} from 'components/organisms/charts/parts'

type SelectPointOfInterestInterface = (
  poi: AbstractPointOfInterest | null,
  center?: number,
  position?: PointOfInterestCardPositions,
  hard?: boolean,
) => void

type UsePointsOfInterestInterface = {
  pointsOfInterest: AbstractPointOfInterest[]
  activePointOfInterest: AbstractPointOfInterest | null
  pointOfInterestCenter: number
  pointOfInterestPosition: PointOfInterestCardPositions
  selectPointOfInterest: SelectPointOfInterestInterface
}

let hideOnClickOutside: () => void

function usePointsOfInterest(pointsOfInterestRaw: unknown, hoveredIndex?: number): UsePointsOfInterestInterface {
  const pointsOfInterest = Array.isArray(pointsOfInterestRaw) ? pointsOfInterestRaw.filter(isPointOfInterestAlike) : []
  const [activePointOfInterest, setActivePointOfInterest] = useState<AbstractPointOfInterest | null>(null)
  const [pointOfInterestCenter, setPointOfInterestCenter] = useState<number>(0)
  const [pointOfInterestPosition, setPointOfInterestPosition] = useState<PointOfInterestCardPositions>(
    PointOfInterestCardPositions.LEFT,
  )

  const unselectPointOfInterest = useCallback(() => {
    setActivePointOfInterest(null)
    setPointOfInterestCenter(0)
    setPointOfInterestPosition(PointOfInterestCardPositions.LEFT)
  }, [])

  const selectPointOfInterest = useCallback<SelectPointOfInterestInterface>(
    (poi, center, position, hard = false): void => {
      if (activePointOfInterest?.hard && !hard) {
        return
      }

      if (hideOnClickOutside) {
        document.removeEventListener('click', hideOnClickOutside)
      }

      if (poi) {
        setActivePointOfInterest({ ...poi, hard })
        setPointOfInterestCenter(center ?? 0)
        setPointOfInterestPosition(position ?? PointOfInterestCardPositions.LEFT)

        if (hard) {
          hideOnClickOutside = () => {
            selectPointOfInterest(null, undefined, undefined, true)
          }

          // next tick
          setTimeout(() => {
            document.addEventListener('click', hideOnClickOutside)
          }, 0)
        }

        return
      }

      unselectPointOfInterest()
    },
    [unselectPointOfInterest, activePointOfInterest],
  )

  useEffect(() => {
    if (!isTouch()) {
      return
    }

    if (hoveredIndex && hoveredIndex > -1) {
      const clickablePoint = document.querySelector<SVGGElement>(`[data-clickable-point-index="${hoveredIndex}"]`)

      if (clickablePoint) {
        const { pointOfInterest, center, position } = JSON.parse(clickablePoint.dataset.clickablePointData ?? '{}')

        if (pointOfInterest) {
          selectPointOfInterest(pointOfInterest, center ?? 0, position ?? PointOfInterestCardPositions.LEFT)
        }

        return
      }
    }

    unselectPointOfInterest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredIndex])

  return {
    pointsOfInterest,
    activePointOfInterest,
    pointOfInterestCenter,
    pointOfInterestPosition,
    selectPointOfInterest,
  }
}

export { usePointsOfInterest, type UsePointsOfInterestInterface, type SelectPointOfInterestInterface }
