import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { Preloader } from 'components/molecules/Preloader'

import { Desktop } from './Desktop'
import { useFirstStep } from './hooks/useFirstStep'
import { Mobile } from './Mobile'

const FirstStep = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { isLoading, handleContinue, handleBack } = useFirstStep()

  const props = {
    handleBack,
    handleContinue,
  }

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}

      <Preloader loading={isLoading} size="big" zIndex />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { FirstStep }
