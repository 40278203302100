import React from 'react'

import { features } from 'helpers/features'
import { urlTo } from 'helpers/router'
import { useMediaQueries, useSelector } from 'hooks'
import { useStrapiBonuses } from './hooks/useStrapiBonuses'

import classnames from 'classnames/dedupe'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typo } from 'components/_old/Typo/Typo'
import { Typography } from 'components/atoms/Typography'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Link from 'components/_old/Link/Link.jsx'

import icon from './icon.png'

import './PromoBanner.css'

const PromoBanner = () => {
  const { bonuses } = useStrapiBonuses()
  const { desktop } = useMediaQueries()
  const isNewReferralSystemEnabled = features?.get('new-referral-system')
  const clientEmail = useSelector(({ client }) => client.email)

  const iconClasses = classnames('PromoBanner-Icon', { 'PromoBanner-Icon_mobile': !desktop })

  const content = bonuses && (
    <Typography className="PromoBanner-Text" size={14} lineHeight="small" weight="semibold" color="white">
      <Typo>
        Refer a friend — get up to {bonuses.referFriendOrBusinessBonusMin} each or{' '}
        {bonuses.referFriendOrBusinessBonusMax} for a Business! Ts&Cs apply
      </Typo>
    </Typography>
  )

  const dimensions = {
    top: desktop ? 16 : 8,
    bottom: desktop ? 16 : 8,
    left: 12,
    right: 12,
  }

  const currentLocation = window.location.pathname + window.location.search

  return (
    <Link
      className="PromoBanner"
      to={
        isNewReferralSystemEnabled
          ? urlTo('dashboard.user-profile.referral', null, {
              back: currentLocation,
            })
          : `https://share.investengine.com/?grsf_email=${clientEmail.replace('@', '%40')}`
      }
      data-test-id="referralBanner"
      hard={!isNewReferralSystemEnabled}
      blank={!isNewReferralSystemEnabled}
      scrollToTop={isNewReferralSystemEnabled}
    >
      <AllCenter>
        <Paper {...dimensions}>
          <ItemWithIcon
            space={12}
            icon={<img className={iconClasses} src={icon} width="32" height="32" />}
            content={content}
            iconVerticalAlign="center"
            contentVerticalAlign="center"
          />
        </Paper>
      </AllCenter>
    </Link>
  )
}

export { PromoBanner }
