const frequencies = {
  WEEKLY: 'WEEKLY',
  FORTNIGHT: 'FORTNIGHT',
  MONTHLY: 'MONTHLY',
}

const frequenciesTexts = {
  WEEKLY: 'Weekly',
  FORTNIGHT: 'Fortnightly',
  MONTHLY: 'Monthly',
}

const periodOptions = [
  {
    value: frequencies.WEEKLY,
    name: 'Weekly',
  },
  {
    value: frequencies.FORTNIGHT,
    name: 'Fortnightly',
  },
  {
    value: frequencies.MONTHLY,
    name: 'Monthly',
  },
]

const dayOptions = [
  {
    value: '1',
    name: 'Monday',
  },
  {
    value: '2',
    name: 'Tuesday',
  },
  {
    value: '3',
    name: 'Wednesday',
  },
  {
    value: '4',
    name: 'Thursday',
  },
  {
    value: '5',
    name: 'Friday',
  },
]

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

const RECURRING_PAYMENT_WEEKLY_MIN_AMOUNT = 20
const RECURRING_PAYMENT_MOTHLY_MIN_AMOUNT = 50

export {
  frequencies,
  frequenciesTexts,
  weekdays,
  periodOptions,
  dayOptions,
  RECURRING_PAYMENT_WEEKLY_MIN_AMOUNT,
  RECURRING_PAYMENT_MOTHLY_MIN_AMOUNT,
}
