import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import emailImage from './email.png'

const TwoFactorAuthSetupStart = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const handleBack = (): void => {
    goTo(urlTo('dashboard.user-profile.login-settings'))
  }

  const navbarNode = (
    <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
      <Typo>&nbsp</Typo>
    </NavigationBar>
  )

  const content = (
    <Paper bottom={80}>
      <AllCenter>
        <img src={emailImage} width={256} height={256} />
      </AllCenter>
      <Paper top={16}>
        <Typography size={desktop ? 32 : 20} lineHeight="small" weight="semibold" align="center">
          We’ve just sent you a email
        </Typography>
      </Paper>
      <Paper top={16}>
        <Typography size={14} align="center">
          <Typo>
            To restore access to your account set up 2-factor authentication. Check your inbox and follow the
            instructions provided in the email.
          </Typo>
        </Typography>
      </Paper>
      <Paper top={40}>
        <Typography size={14} align="center">
          <Typo>
            If you have no access to your inbox, send us a message from the{' '}
            <Nobr>
              <Link to="https://help.investengine.com/" hard>
                Help Centre
              </Link>
              .
            </Nobr>
          </Typo>
        </Typography>
      </Paper>
    </Paper>
  )

  return (
    <Fragment>
      {desktop ? (
        <DesktopLayout
          header={<DesktopHeader />}
          content={
            <Inner twoColumns>
              {navbarNode}
              <Width size={36} center>
                {content}
              </Width>
            </Inner>
          }
          footer={<DesktopFooter />}
        />
      ) : (
        <MobileLayout header={navbarNode} content={content} />
      )}

      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { TwoFactorAuthSetupStart }
