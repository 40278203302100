import axios from 'helpers/ajax'

import { type PendingOrder } from '../models'

export const fetchPendingOrders = async (): Promise<PendingOrder[]> => {
  const { data } = await axios.get('/pending_orders/')

  return data
}

export type CreatePendingOrderParams = {
  type: string
  portfolio_id: number
  amount?: number
  security_id?: number
  cash_from?: number
}

export const createPendingOrder = async (params: CreatePendingOrderParams): Promise<PendingOrder> => {
  const { data } = await axios.post('/pending_orders/', params, {
    validErros: ['unsettled_trades_exist', 'withdrawal_exists'],
  })

  return data
}

export const submitPendingOrder = async (orderId: number): Promise<void> => {
  const { data } = await axios.post(`/pending_orders/${orderId}/submit/`)

  return data
}

export const cancelPendingOrders = async (portfolioId: number): Promise<void> => {
  await axios.post(`/pending_orders/portfolio/${portfolioId}/cancel/`)
}

interface HolidayCheckResponse {
  is_holiday: boolean
}

export const checkIsHoliday = async (): Promise<boolean> => {
  const { data }: { data: HolidayCheckResponse } = await axios.get(`/check/is_holiday/`)

  return data.is_holiday
}
