import React, { Fragment } from 'react'

import { useCallback, useMediaQueries } from 'hooks'

import { urlTo, goTo } from 'helpers/router.js'

import { GatewayDest } from 'components/atoms/Gateway'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

const NotificationStep = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const handleBack = useCallback(() => {
    goTo(urlTo('user-profile.two-factor-setup.first-step'))
  }, [])

  const handleSuccess = useCallback(() => {
    const nextUrl = urlTo('dashboard.user-profile.login-settings.confirm-phone-number', {}, { twoFactorSetup: true })

    goTo(nextUrl)
  }, [])

  const handleFailure = useCallback(() => {
    const nextUrl = urlTo('dashboard.user-profile.login-settings', {}, { twoFactorAuthRejected: true })

    goTo(nextUrl)
  }, [])

  const props = {
    handleBack,
    handleSuccess,
    handleFailure,
  }

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}

      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { NotificationStep }
