import { combine, createStore, createEffect } from 'effector'

import { ApiError } from 'app/redux/models/errors'

import * as api from './api'
import { NotificationList } from './models'

// Effects
const fetchNotificationsFx = createEffect(async () => {
  try {
    const data = await api.getNotifications()

    if (data instanceof ApiError) throw data

    return data
  } catch (error) {}
})

const closeNotificationFx = createEffect(async (code: string) => {
  const data = await api.closeNotification(code)

  if (data instanceof ApiError) throw data

  return code
})

// Stores
const $notifications = createStore<NotificationList>(new NotificationList())

$notifications.on(fetchNotificationsFx.doneData, (state, notifications) => {
  if (!notifications) return state
  return new NotificationList(...notifications)
})

$notifications.on(closeNotificationFx.doneData, (state, code) => {
  if (!code) return state

  const newNotifications = state.filter((notification) => notification.code !== code)

  return new NotificationList(...newNotifications)
})

const $notificationsStore = combine({
  notifications: $notifications,
  isLoading: fetchNotificationsFx.pending,
})

export { $notificationsStore, fetchNotificationsFx, closeNotificationFx }
