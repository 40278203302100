/* eslint-disable @typescript-eslint/no-var-requires */
const animation = require('./tokens/animation.js')
const legacyLightColors = require('./tokens/color/legacyLight.js')
const font = require('./tokens/font.js')
const shadow = require('./tokens/shadow/light.js')

const palette = {
  font,
  animation,

  ...legacyLightColors,
  shadow,
}

module.exports = palette
