import React from 'react'

import { useMediaQueries } from 'hooks'

import { Paper } from 'components/atoms/Paper'

import { DividendsReceived, Legend, Tooltip, Chart, NoYieldPlaceholder } from './components'
import { useDividendsChart } from './hooks/useDividendsChart'
import { type DividendsChartData, type Periods } from './types'

import './DividendsChart.css'

type DividendsChartProps = {
  portfolioId: string
  securityId?: string
  data: DividendsChartData
  period: Periods
  securities: Array<{ dividends_type: 'ACCUMULATING' | 'DISTRIBUTING' | null; [key: string]: any }>
}

const DividendsChart = ({
  data: rawData,
  securityId,
  portfolioId,
  period,
  securities,
}: DividendsChartProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const {
    isEmpty,
    max,
    data,
    paidOutSum,
    withFloat,
    hoveredData,
    rootRef,
    barsRef,
    shouldShowNoYieldPlaceholder,
    tooltipData,
    selectedIndex,
    chartColumnClassName,
    linkToTransactions,
    handleColumnEnter,
    handleColumnLeave,
    handleColumnClick,
  } = useDividendsChart({ rawData, period, securities, portfolioId, securityId })

  const dividendsReceivedBlock = (
    <DividendsReceived paidOutSum={paidOutSum} hoveredData={hoveredData} isSlice={!!securityId} />
  )

  const legendNode = <Legend />

  const tooltipNode = <Tooltip {...{ tooltipData, linkToTransactions }} />

  const chartNode = (
    <Chart
      {...{
        rootRef,
        barsRef,
        chartColumnClassName,
        max,
        data,
        isEmpty,
        tooltipNode,
        withFloat,
        selectedIndex,
        handleColumnEnter,
        handleColumnLeave,
        handleColumnClick,
      }}
    />
  )

  const noYieldPlaceholder = <NoYieldPlaceholder />

  return (
    <div className="DividendsChart" data-test-id="dividendsChart">
      <Paper bottom={desktop ? 32 : 24}>{dividendsReceivedBlock}</Paper>
      <Paper bottom={desktop ? 32 : 16} className="DividendsChart-Legend">
        {legendNode}
      </Paper>
      {chartNode}
      {shouldShowNoYieldPlaceholder && noYieldPlaceholder}
    </div>
  )
}

export { DividendsChart }
