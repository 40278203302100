import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { DesktopLayout, MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { TwoFactorSetupLayout } from '../components/TwoFactorSetupLayout'

import { $isLoading, startTwoFactorSetupFx } from './model'
import { PushInstruction } from './PushInstruction'

const InitialStep = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const [isLoading, handleStartTwoFactorSetup] = useUnit([$isLoading, startTwoFactorSetupFx])

  const navbarNode = (
    <NavigationBar>
      <Typography size={20} lineHeight="small" weight="semibold">
        <Typo>Step 1 of 4</Typo>
      </Typography>
    </NavigationBar>
  )

  const button = (
    <Fragment>
      <Button mods={{ size: 'big block' }} onClick={handleStartTwoFactorSetup}>
        Send confirmation notification
      </Button>
      <Paper top={24}>
        <AllCenter>
          <Link>I don’t have a smartphone</Link>
        </AllCenter>
      </Paper>
    </Fragment>
  )

  const content = (
    <Fragment>
      <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
        <Typo>Ensure app is installed and notifications turned on</Typo>
      </Typography>
      <Paper top={8}>
        <Typography size={14} align="center">
          <Typo>
            With 2-factor authentication, when you log in to your InvestEngine account you will authorise the access on
            your mobile phone.
          </Typo>
        </Typography>
      </Paper>
      <Paper top={72}>
        <PushInstruction />
      </Paper>
    </Fragment>
  )

  return (
    <TwoFactorSetupLayout isLoading={isLoading}>
      {desktop ? (
        <DesktopLayout
          header={<DesktopHeader />}
          content={
            <Inner>
              {navbarNode}
              <Paper top={40}>
                <Width size={45} center>
                  {content}
                </Width>
              </Paper>
              <Paper top={72}>
                <Width size={23.5} center>
                  {button}
                </Width>
              </Paper>
            </Inner>
          }
          footer={<DesktopFooter />}
        />
      ) : (
        <MobileLayout
          header={navbarNode}
          content={
            <Paper top={8} bottom={56}>
              {content}
            </Paper>
          }
          footer={<MobileLayoutFooterButton opaque>{button}</MobileLayoutFooterButton>}
        />
      )}
    </TwoFactorSetupLayout>
  )
}

export { InitialStep }
