import { useState } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

type useSecuritySliceProps = {
  location: {
    query: {
      chart?: string
      period?: string
    }
  }
  portfolioId: number
  securityId: number
}

type useSecuritySliceReturn = {
  activeChart: string
  selectedPeriod: string
  setChartTab: (tabName: string) => void
  setPeriodTab: (tabName: string) => void
}

const useSecuritySliceTabs = ({ location, portfolioId, securityId }: useSecuritySliceProps): useSecuritySliceReturn => {
  const [activeChart, setActiveChart] = useState(location?.query?.chart ?? 'return')
  const [selectedPeriod, setSelectedPeriod] = useState(location?.query?.period ?? 'max')

  const addParamsToQuery = (...params): void => {
    const paramsObject = params.reduce((result, current) => {
      return { ...result, ...current }
    }, {})

    goTo(urlTo(`portfolio.security`, { id: portfolioId, securityId }, { ...location.query, ...paramsObject }))
  }

  const setPeriodTab = (period: string): void => {
    setSelectedPeriod(period)
    addParamsToQuery({ period })
  }

  const setChartTab = (tabName: string): void => {
    setActiveChart(tabName)
    addParamsToQuery({ chart: tabName })
  }

  return {
    activeChart,
    setChartTab,
    selectedPeriod,
    setPeriodTab,
  }
}

export { useSecuritySliceTabs }
