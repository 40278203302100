/*  eslint-disable @typescript-eslint/naming-convention */
import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import seedrandom from 'seedrandom'
import tinycolor from 'tinycolor2'

import { memoizeOne } from 'helpers/memoize'
import { palettePlainValues } from 'helpers/palette'

export function colorify(data, basicColor) {
  if (!data) {
    return null
  }

  basicColor = basicColor || data.basicColor || palettePlainValues['primary-default']
  const { assets, ...restData } = data

  if (!Array.isArray(assets)) {
    return null
  }

  return {
    ...restData,
    assets: assets.map((entity, index, all) => {
      let step = 100 / (all.length - 1)
      step = step > 33.3333 ? 33.3333 : step
      return {
        ...entity,
        backgroundColor: tinycolor(basicColor)
          .darken((step * index) / all.length)
          .toString(),
      }
    }),
  }
}

export function shuffle(groupOfAssets, key = 'backgroundColor') {
  if (!groupOfAssets) {
    return null
  }

  const { assets, ...restData } = groupOfAssets

  if (!Array.isArray(assets)) {
    return null
  }

  return {
    ...restData,
    assets: assets.sort((a, b) => {
      return Math.round(seedrandom(a[key] + b[key])() * assets.length - Math.floor(assets.length / 2))
    }),
  }
}

function _getDataForHorizontalChart(
  goal_stocks = [],
  goal_bonds = [],
  goal_alternatives = [],
  goal_cash = undefined,
  preset_cash = 0,
) {
  const groupsOfAssets = [
    {
      label: 'Equities',
      assets: goal_stocks,
      basicColor: palettePlainValues.stocks,
    },
    {
      label: 'Bonds',
      assets: goal_bonds,
      basicColor: palettePlainValues.bonds,
    },
    {
      label: 'Alternatives',
      assets: goal_alternatives,
      basicColor: palettePlainValues.alternatives,
    },
  ]

  if (goal_cash) {
    groupsOfAssets.push({
      label: 'Cash',
      assets: goal_cash,
      basicColor: palettePlainValues['status-success'],
      headless: true,
      unhoverable: true,
    })
  }

  const initData = {
    groupsOfAssets: [],
    maxAmount: null,
    maxTotalValue: null,
    lastPriceDate: null,
  }

  const data = groupsOfAssets.reduce((accData, groupOfAssets) => {
    // add colors
    const _groupOfAssets = colorify(groupOfAssets)

    let _maxAmount = null
    let _maxTotalValue = null
    let _sumAmount = null
    let _lastPriceDate = null

    if (Array.isArray(_groupOfAssets.assets)) {
      _groupOfAssets.assets.forEach((asset) => {
        const amount = parseFloat(asset.amount)
        const totalValue = parseFloat(asset.total_value)

        _sumAmount = isNumber(_sumAmount) ? _sumAmount + amount : amount
        if (isNull(_maxAmount) || (isNumber(amount) && _maxAmount < amount)) {
          _maxAmount = amount
        }
        if (isNull(_maxTotalValue) || (isNumber(totalValue) && _maxTotalValue < totalValue)) {
          _maxTotalValue = totalValue
        }
        if (isNull(_lastPriceDate) || (isString(asset.price_date) && _lastPriceDate < asset.price_date)) {
          _lastPriceDate = asset.price_date
        }
      })
    }

    const groupOfAssetsWithSumAmount = {
      ..._groupOfAssets,
      sumAmount: _sumAmount,
    }

    accData.groupsOfAssets.push(groupOfAssetsWithSumAmount)
    if (isNull(accData.maxAmount) || (isNumber(_maxAmount) && accData.maxAmount < _maxAmount)) {
      accData.maxAmount = _maxAmount
    }
    if (isNull(accData.maxTotalValue) || (isNumber(_maxTotalValue) && accData.maxTotalValue < _maxTotalValue)) {
      accData.maxTotalValue = _maxTotalValue
    }
    if (isNull(accData.lastPriceDate) || (isString(_lastPriceDate) && accData.lastPriceDate < _lastPriceDate)) {
      accData.lastPriceDate = _lastPriceDate
    }

    return accData
  }, initData)

  if (preset_cash > 0) {
    const addCashTo = goal_alternatives.length > 0 ? 'Alternatives' : goal_bonds?.length > 0 ? 'Bonds' : 'Equities'
    const addCashToIndex = data.groupsOfAssets.findIndex((item) => item.label === addCashTo)

    data.groupsOfAssets = [
      ...data.groupsOfAssets.slice(0, addCashToIndex),
      {
        ...data.groupsOfAssets[addCashToIndex],
        sumAmount: data.groupsOfAssets[addCashToIndex].sumAmount + preset_cash,
      },
      ...data.groupsOfAssets.slice(addCashToIndex + 1),
    ]
  }

  return data
}

export const getDataForHorizontalChart = memoizeOne(_getDataForHorizontalChart)
