import { attach } from 'effector'

import { useCallback, useLoading } from 'hooks'

import { createQuickStartFx } from 'app/effector/quickStart'

import { showFailToast } from 'app/redux/actions/ui'

import type { Frequency } from 'app/pages/Dashboard/Goals/RecurringPayment/types'

type CreatePortfolioQuickStartProps = {
  amount: string | null
  frequency: Frequency | null
  startDay: string | null
  monthlyPaymentDay: number | null
  portfolioId: string
}

type UseSavingsPlanSetupReturnProps = {
  isLoading: boolean
  createPortfolioQuickStart: (data: CreatePortfolioQuickStartProps) => Promise<void>
}

const useCreatePortfolioQuickStart = (): UseSavingsPlanSetupReturnProps => {
  const { isLoading, wait } = useLoading(false)

  const createPortfolioQuickStart = useCallback(
    async ({ amount, frequency, startDay, monthlyPaymentDay, portfolioId }: CreatePortfolioQuickStartProps) => {
      const quickStart = {
        amount,
        frequency,
        start_day_week: startDay,
        day_of_month: monthlyPaymentDay,
      }

      const createQuickStartOnSavingsPlanSetupFx = attach({
        effect: createQuickStartFx,
        source: { portfolioId, quickStart },
      })

      try {
        await wait(createQuickStartOnSavingsPlanSetupFx())
      } catch (error) {
        showFailToast()
      }
    },
    [wait],
  )

  return {
    isLoading,
    createPortfolioQuickStart,
  }
}

export { useCreatePortfolioQuickStart }
