import React, { Fragment } from 'react'

import Button from 'components/_old/Button/Button.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import { BottomTools } from 'app/pages/Securities/SecurityUniverse/Mobile/components/BottomTools/BottomTools.jsx'

type AddSecuritiesMobileFooterProps = {
  addedItemsCount: number
  portfolioTitle: string
  isButtonEnabled: boolean
  handleGoToEditWeights: () => void
}

const AddSecuritiesMobileFooter = ({
  addedItemsCount,
  portfolioTitle,
  isButtonEnabled,
  handleGoToEditWeights,
}: AddSecuritiesMobileFooterProps): React.ReactElement => {
  return (
    <BottomTools>
      <ColumnarLayout mods={{ padding: 'mini', 'align-columns': 'center' }}>
        <Column mods={{ strict: true }}>
          {addedItemsCount === 0 ? (
            <Typography size={14} lineHeight="small" color="additional" data-test-id="addedSecuritiesCount">
              <Typo>
                Add securities <br /> to continue
              </Typo>
            </Typography>
          ) : (
            <Fragment>
              <Typography size={14} lineHeight="small" color="additional" weight="semibold" overflow="ellipsis">
                <Nobr>
                  <Typo>{portfolioTitle}</Typo>
                </Nobr>
              </Typography>
              <Typography size={14} lineHeight="small" color="additional" data-test-id="addedSecuritiesCount">
                <Typo>
                  {addedItemsCount} {addedItemsCount > 1 ? 'securities' : 'security'}
                </Typo>
              </Typography>
            </Fragment>
          )}
        </Column>
        <Column mods={{ strict: true }}>
          <Button
            mods={{ size: 'block' }}
            disabled={!isButtonEnabled}
            onClick={handleGoToEditWeights}
            data-test-id="securityUniverseContinueButton"
          >
            Continue
          </Button>
        </Column>
      </ColumnarLayout>
    </BottomTools>
  )
}

export { AddSecuritiesMobileFooter }
