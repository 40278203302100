import React from 'react'

import { palette } from 'helpers/palette'

import Icon from 'components/_old/Icon/Icon.jsx'
import MergeIcons from 'components/_old/MergeIcons/MergeIcons.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

type WarningMessageProps = {
  children: React.ReactNode | React.ReactNodeArray
}

const WarningMessage = ({ children }: WarningMessageProps): React.ReactElement => (
  <Paper top={16} style={{ display: 'flex' }}>
    <MergeIcons
      style={{
        width: '1.8em',
        height: '1.8em',
        paddingBottom: '0',
        marginTop: '0.2em',
        marginRight: '1em',
        flex: '0 0 1.8em',
      }}
      data-test-id="fullWithdrawalIcon"
    >
      <Icon type="triangle" color={palette['content-on-background-primary']} fluid inline />
      <Icon style={{ marginTop: '25%' }} type="exclamation" color={palette['background-default']} size={20} inline />
    </MergeIcons>
    <Typography size={12} lineHeight="small" data-test-id="fullWithdrawalText">
      <Typo>{children}</Typo>
    </Typography>
  </Paper>
)

export { WarningMessage }
