import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { Preloader } from 'components/molecules/Preloader'

import { OpenSippModal } from 'app/pages/Dashboard/Accounts/components/OpenSippModal'

import { Desktop } from './Desktop'
import { useSelectPortfolioType } from './hooks'
import { Mobile } from './Mobile'

type SelectPortfolioTypeProps = {
  location: {
    query: {
      step?: string
      regulatoryType?: string
    }
  }
}

const SelectPortfolioType = ({ location = {} }: SelectPortfolioTypeProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const { isLoading, ...props } = useSelectPortfolioType(location)

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}

      <OpenSippModal />
      <Preloader loading={isLoading} size="big" zIndex />
    </Fragment>
  )
}

export { SelectPortfolioType }
