import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { useUnit } from 'effector-react'

import useMediaQueries from 'hooks/useMediaQueries'

import { combineErrors, backendErrorsToObj, bulkValidate } from 'helpers/validation.js'
import { hardCodedCountries } from 'helpers/hardCodedCounties'
import { processError } from 'helpers/errors'

import { AddressByPeriod } from 'components/organisms/AddressByPeriod'
import { Typo } from 'components/_old/Typo/Typo'
import Modal from 'components/_old/Modal/Modal.jsx'
import ModalContent, {
  ModalContentHeadline,
  ModalContentBody,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Button from 'components/_old/Button/Button.jsx'
import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'
import Width from 'components/_old/Width/Width'
import StickedIfNotDesktop from 'components/molecules/StickedIfNotDesktop/StickedIfNotDesktop.jsx'

import { Address } from 'app/redux/models/address'

import { showSuccessToast } from 'app/redux/actions/ui'

import { $companyStore, saveCompanyAddressFx, receiveCompanyCurrentAddress } from 'app/effector/company'
import { ApiError } from 'app/redux/models/errors'

const ChangeCompanyAddress = ({ open = false, onClose: handleClose }) => {
  const { desktop } = useMediaQueries()
  const [currentAddress, setCurrentAddress] = useState(new Address({ is_current: true }))

  const { company } = useUnit($companyStore)

  // quick fix for https://app.asana.com/0/1202304843784991/1202635259316452/f
  const countries = hardCodedCountries // dicts.countries

  useEffect(() => {
    if (company?.addresses?.hasAddresses() && countries?.length) {
      const [currentAddress] = company.addresses.getCurrentAddress()

      if (currentAddress) {
        setCurrentAddress(currentAddress)
      }
    }
  }, [company, countries])

  const handleAddressFieldChange = useCallback(
    (fieldName, value) => {
      setCurrentAddress((address) => Address.createFromObject({ ...address, [fieldName]: value }))
    },
    [setCurrentAddress],
  )

  const handleSubmit = useCallback(async () => {
    try {
      // TODO: rework save company address to do all in one action
      const newAddressList = company.addresses?.addAddress(
        Address.createFromObject({ ...currentAddress, id: null, is_current: true }),
      )
      receiveCompanyCurrentAddress(currentAddress)
      const saveCompanyAddressResult = await saveCompanyAddressFx(newAddressList)

      if (saveCompanyAddressResult instanceof ApiError) throw saveCompanyAddressResult

      showSuccessToast('Address successfully updated')
      handleClose()
    } catch (error) {
      processError({ error })
    }
  }, [currentAddress, company, handleClose])

  const validation = combineErrors(
    {
      street: { rules: [currentAddress?.street], errors: [' Address can’t be empty'] },
      postcode: { rules: [currentAddress?.postcode], errors: ['Postcode can’t be empty'] },
      country: { rules: [currentAddress?.country], errors: ['Country can’t be empty'] },
      city: { rules: [currentAddress?.city], errors: ['City can’t be empty'] },
      isUK: { rules: [currentAddress?.isUK()], errors: [''] },
    },
    backendErrorsToObj(company.error),
  )

  if (!currentAddress || !countries.length) {
    return null
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      data-test-id="changeCompanyAddressModal"
      gatewayName="change-company-address"
    >
      <ModalContent
        width={30}
        wrapWith={(innerContent) => <div style={{ minHeight: desktop ? '25rem' : null }}>{innerContent}</div>}
      >
        <ModalContentHeadline>Edit address</ModalContentHeadline>
        <ModalContentBody size={null}>
          <Card
            mods={{
              theme: !desktop ? 'edge-to-edge transparent' : null,
              'no-padding': desktop ? null : 'top',
            }}
          >
            <AddressByPeriod
              address={currentAddress}
              onAddressFieldChange={handleAddressFieldChange}
              validation={validation}
              countries={countries}
              notUkWarningText={<Typo>You must be a UK resident to open an account with InvestEngine</Typo>}
              noPaddingBottom
            />
            <Card
              mods={{
                text: 'center',
                theme: 'transparent',
                'no-padding': desktop ? 'bottom' : 'all',
              }}
            >
              <Width size={null} center>
                <StickedIfNotDesktop into="insideModal">
                  <ButtonGroup>
                    <Button
                      mods={{ theme: 'simple-reverse-blue' }}
                      onClick={handleClose}
                      data-test-id="addressCancelButton"
                    >
                      Cancel
                    </Button>
                    <Button onClick={handleSubmit} disabled={!bulkValidate(validation)} data-test-id="addressSaveBtn">
                      Change
                    </Button>
                  </ButtonGroup>
                </StickedIfNotDesktop>
              </Width>
            </Card>
          </Card>
        </ModalContentBody>
      </ModalContent>
    </Modal>
  )
}

ChangeCompanyAddress.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect((state, ownProps) => {
    const { routes } = ownProps

    return {
      open: routes[routes.length - 1].module === 'change-company-address',
    }
  }),
)(ChangeCompanyAddress)
