import * as React from 'react'

import { useMemo } from 'hooks'

import { type Portfolio } from 'app/redux/models/portfolio/types'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Badge } from 'components/atoms/Badge'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { TextCollapse } from 'components/atoms/TextCollapse'
import { Typography } from 'components/atoms/Typography'

import { PortfolioTitle } from 'app/pages/Dashboard/Analytics/SingleHolding/components/PortfolioTitle'

import { type HoldingData } from '../../hooks/useHoldingModal'
import { DistributionChart } from '../components/DistributionChart'
import { HoldingSecurity } from '../components/HoldingSecurity'

type HoldingModalMobileProps = {
  isPortfolioNew: boolean
  selectedPortfolio: Portfolio
  holdingData: HoldingData | null
  holdingDistribution: Array<{ value: number; color: string }>
  handleBack: () => void
}

const Mobile = ({
  isPortfolioNew,
  selectedPortfolio,
  holdingData,
  holdingDistribution,
  handleBack,
}: HoldingModalMobileProps): React.ReactElement => {
  const hasRegionOrSectorInfo = useMemo(
    () => Boolean(holdingData?.sector_name) || Boolean(holdingData?.region_name),
    [holdingData],
  )

  const headerNode = useMemo(
    () => (
      <NavigationBar leftPartText="Back" onLeftPartClick={handleBack}>
        Analytics
      </NavigationBar>
    ),
    [handleBack],
  )

  const titleNode = useMemo(() => {
    const title = (
      <Typography size={32} lineHeight="small" weight="semibold">
        <Typo noNbsp>{holdingData?.name} (Equity)</Typo>
      </Typography>
    )

    if (holdingData?.logo) {
      return (
        <ItemWithIcon
          space={24}
          icon={<SecurityLogo logo={holdingData?.logo} size={36} />}
          content={title}
          iconVerticalAlign="center"
          contentVerticalAlign="center"
          iconPosition="right"
        />
      )
    }

    return title
  }, [holdingData?.name, holdingData?.logo])

  const sectorAndRegionNode = useMemo(
    () =>
      hasRegionOrSectorInfo ? (
        <Paper top={32}>
          <Typography size={14}>
            {holdingData?.sector_name && <Badge className="SingleHolding_Badge" text={holdingData?.sector_name} />}
            {holdingData?.region_name && <Badge className="SingleHolding_Badge" text={holdingData?.region_name} />}
          </Typography>
        </Paper>
      ) : null,
    [hasRegionOrSectorInfo, holdingData],
  )

  const descriptionNode = useMemo(
    () =>
      holdingData?.description ? (
        <Paper top={hasRegionOrSectorInfo ? 16 : 32}>
          <Typography size={14} color="additional">
            <TextCollapse limit={280}>{holdingData?.description}</TextCollapse>
          </Typography>
        </Paper>
      ) : null,
    [holdingData?.description, hasRegionOrSectorInfo],
  )

  const websiteNode = useMemo(
    () =>
      holdingData?.website ? (
        <Paper top={16}>
          <Link to={holdingData?.website} blank>
            <Typography size={14} color="inherit">
              <Typo>{holdingData?.website}</Typo>
            </Typography>
          </Link>
        </Paper>
      ) : null,
    [holdingData?.website],
  )

  const distributionTitleNode = useMemo(
    () => (
      <Paper top={48}>
        <Typography size={18} lineHeight="small" weight="semibold">
          <Typo>Weightings of {holdingData?.type} in this portfolio</Typo>
        </Typography>
      </Paper>
    ),
    [holdingData?.type],
  )

  const selectedPortfolioNode = useMemo(
    () =>
      selectedPortfolio ? (
        <Paper top={8}>
          <PortfolioTitle portfolio={selectedPortfolio} />
        </Paper>
      ) : null,
    [selectedPortfolio],
  )

  const distributionNode = useMemo(
    () => (
      <Paper top={32}>
        <Paper bottom={24}>
          {isPortfolioNew ? (
            <Typography size={14} lineHeight="small" color="additional">
              <Typo>Target</Typo>
            </Typography>
          ) : (
            <Typography>
              <Typography tag="span" size={14} lineHeight="small" color="minor">
                <Typo>Current target</Typo>{' '}
              </Typography>
              <Typography tag="span" size={14} lineHeight="small">
                <Typo>→ New target</Typo>
              </Typography>
            </Typography>
          )}
        </Paper>
        <DistributionChart
          showActual={!isPortfolioNew}
          name={holdingData?.name}
          actualWeight={holdingData?.current_target_weight}
          targetWeight={holdingData?.target_weight}
          distribution={holdingDistribution}
        />
      </Paper>
    ),
    [isPortfolioNew, holdingData, holdingDistribution],
  )

  const securitiesNode = useMemo(
    () => (
      <Paper top={48} bottom={48}>
        {holdingData?.securities.map((security, index) => (
          <Paper top={index === 0 ? 0 : 12} key={security.id}>
            <HoldingSecurity showActual={!isPortfolioNew} security={security} />
          </Paper>
        ))}
      </Paper>
    ),
    [isPortfolioNew, holdingData],
  )

  const contentNode = (
    <React.Fragment>
      {titleNode}
      {sectorAndRegionNode}
      {descriptionNode}
      {websiteNode}
      {distributionTitleNode}
      {selectedPortfolioNode}
      {distributionNode}
      {securitiesNode}
    </React.Fragment>
  )

  return <MobileLayout header={headerNode} content={contentNode} />
}

export { Mobile }
