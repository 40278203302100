import React from 'react'

import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette'

import Icon from 'components/_old/Icon/Icon.jsx'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './UserAvatar.css'

type UserAvatarProps = {
  firstName?: string
  lastName?: string
  size?: string
  editable?: boolean
  image?: string | null
  isHighlighted?: boolean
  onClick?: () => void
  dataTestId?: string
}

const FONT_SIZES = {
  '16': 8,
  '32': 14,
  '40': 16,
  '100': 32,
}

const ICON_SIZES = {
  '16': 8,
  '32': 16,
  '40': 18,
  '100': 36,
}

const UserAvatar = ({
  firstName,
  lastName,
  size = '100',
  isHighlighted = false,
  editable,
  image,
  onClick,
  dataTestId,
}: UserAvatarProps): React.ReactElement => {
  const initials = `${(firstName ?? '').slice(0, 1)}${(lastName ?? '').slice(0, 1)}`

  const classes = classNames('UserAvatar_background', {
    [`UserAvatar_size_${size}`]: size,
    [`UserAvatar_hightlighted`]: isHighlighted,
    [`UserAvatar_clickable`]: !!onClick,
  })

  const hasAvatarImage = !!image

  return (
    <Paper
      className={classes}
      onClick={onClick}
      style={{ backgroundImage: image ? `url(${image})` : 'none' }}
      data-test-id={dataTestId}
    >
      {!hasAvatarImage && initials && (
        <Typography size={FONT_SIZES[size]} weight="semibold" align="center" color="additional">
          {initials}
        </Typography>
      )}

      {!hasAvatarImage && !initials && <Icon type="user-avatar-36" size={ICON_SIZES[size]} />}

      {editable && (
        <Paper className="UserAvatar_edit">
          <Icon
            className="UserAvatar_editIcon"
            type="pencil-24"
            size={24}
            inline
            color={palette['content-on-color-default']}
          />
        </Paper>
      )}
    </Paper>
  )
}

export { UserAvatar }
