import React from 'react'

import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { trackEvent } from 'helpers/analytics'

import Card from 'components/_old/Card/Card.jsx'
import Headline from 'components/_old/Headline/Headline.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import FileUploadField from 'components/molecules/FileUploadField/FileUploadField.jsx'

import { types as clientTypes } from 'constants/client'

const ProofOfIdentityForm = ({ model, type, url, method, submitButton, onAfterUpload: handleAfterUpload }) => {
  const { desktop } = useMediaQueries()

  const texts = {
    selfie: {
      headline: '1. Selfie with Passport or Driving Licence (Full&nbsp;or Provisional)',
      text: 'Portrait photograph of you holding your passport or the front of your driving licence.',
    },
    passport: {
      headline: '2. Passport biodata page or front of driving licence (Full&nbsp;or Provisional)',
      text: 'Close-up photograph of the biodata page of your passport or the front of your driving licence.',
    },
  }

  let fileUploadFields = [
    <FileUploadField
      key="selfie"
      model={model}
      url={url}
      method={method}
      type="selfie"
      heading={
        <Headline className={desktop ? null : 'Text_color_blue'} level={3} mods={{ 'no-margin': 'top' }}>
          <Typo>{texts.selfie.headline}</Typo>
        </Headline>
      }
      text={
        <Text small>
          <Typo>{texts.selfie.text}</Typo>
        </Text>
      }
      desktop={desktop}
      onAfterUpload={() => {
        trackEvent({ action: 'selfie_uploaded', client_type: clientTypes[type] })
        handleAfterUpload()
      }}
      data-test-id="selfieFileUpload"
    />,
    <FileUploadField
      key="passport"
      model={model}
      url={url}
      method={method}
      type="passport"
      heading={
        <Headline className={desktop ? null : 'Text_color_blue'} level={3} mods={{ 'no-margin': 'top' }}>
          <Typo>{texts.passport.headline}</Typo>
        </Headline>
      }
      text={
        <Text small>
          <Typo>{texts.passport.text}</Typo>
        </Text>
      }
      desktop={desktop}
      onAfterUpload={() => {
        trackEvent({ action: 'passport_uploaded', client_type: clientTypes[type] })
        handleAfterUpload()
      }}
      data-test-id="passportFileUpload"
    />,
  ]

  fileUploadFields = desktop ? (
    <Width size={50} center>
      <ColumnarLayout>
        {fileUploadFields.map((field, i) => (
          <Column key={i}>{field}</Column>
        ))}
      </ColumnarLayout>
    </Width>
  ) : (
    fileUploadFields.map((field, i) => (
      <Card key={i} mods={{ padding: 'big' }} stack>
        {field}
      </Card>
    ))
  )

  return (
    <div>
      <Card
        mods={{
          theme: 'transparent',
          'no-padding': desktop ? 'top right bottom left' : 'top right left',
          padding: 'big',
        }}
      >
        <Text block center>
          <Typo>
            {type === 'BUSINESS'
              ? 'We were not able to verify the identity automatically, please upload the following documents:'
              : 'We were not able to verify your identity automatically, please upload the following documents:'}
          </Typo>
        </Text>
      </Card>
      <Card
        mods={{
          theme: 'transparent',
          'no-padding': desktop ? 'left right bottom' : 'all',
          padding: 'big',
        }}
        fluid
      >
        {fileUploadFields}
      </Card>
      <Card mods={{ theme: 'transparent', 'no-padding': 'all', text: 'center' }}>{submitButton}</Card>
    </div>
  )
}

ProofOfIdentityForm.propTypes = {
  model: PropTypes.object.isRequired,
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
  method: PropTypes.string,
  submitButton: PropTypes.element,
  onAfterUpload: PropTypes.func,
}

export default ProofOfIdentityForm
