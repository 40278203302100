import React, { Fragment } from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

import { useMediaQueries, useMemo, useCopyToClipboard, useRef } from 'hooks'

import { showSuccessToast } from 'app/redux/actions/ui'

import Icon from 'components/_old/Icon/Icon.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal } from 'components/molecules/Modal'
import ModalContent from 'components/molecules/ModalContent/ModalContent.jsx'

import './ShareModal.css'

type ShareModalProps = {
  open: boolean
  shareUrl: string
  shareTitle: string
  onClose: () => void
}

const ShareModal = ({ open, shareUrl, shareTitle, onClose }: ShareModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const handleCopy = useCopyToClipboard(shareUrl)

  const facebookButton = useRef()
  const twitterButton = useRef()
  const linkedinButton = useRef()

  const shareButtons = useMemo(
    () => (
      <Fragment>
        <Paper
          top={16}
          left={16}
          bottom={16}
          className="ShareModal-Item"
          tabIndex="0"
          onClick={(event) => {
            event?.currentTarget?.blur()
            handleCopy()
            showSuccessToast('Link copied')
          }}
          onKeyDown={(event) => {
            event.key === 'Enter' && handleCopy() && showSuccessToast('Link copied')
          }}
          data-test-id="copyLinkButton"
        >
          <ItemWithIcon
            space={16}
            contentVerticalAlign="center"
            icon={<Icon type="copy-24" size={22} color="inherit" />}
            content={
              <Typography size={16} align="left" lineHeight="small" tag="span" className="ShareModal-LinkTitle">
                <Typo>Copy link</Typo>
              </Typography>
            }
          />
        </Paper>

        <Paper
          top={16}
          left={16}
          bottom={12}
          className="ShareModal-Item"
          tabIndex="0"
          onClick={(event) => {
            facebookButton?.current?.click()
            event?.target?.blur()
          }}
          onKeyDown={(event) => event.key === 'Enter' && facebookButton?.current?.click()}
        >
          <FacebookShareButton url={shareUrl} quote={shareTitle} tabIndex="-1" ref={facebookButton}>
            <ItemWithIcon
              space={16}
              contentVerticalAlign="center"
              icon={<FacebookIcon size={22} round />}
              content={
                <Typography size={16} align="center" lineHeight="small" tag="span" className="ShareModal-LinkTitle">
                  <Typo>Facebook</Typo>
                </Typography>
              }
            />
          </FacebookShareButton>
        </Paper>

        <Paper
          top={16}
          left={16}
          bottom={12}
          className="ShareModal-Item"
          tabIndex="0"
          onClick={(event) => {
            twitterButton?.current?.click()
            event?.target?.blur()
          }}
          onKeyDown={(event) => event.key === 'Enter' && twitterButton?.current?.click()}
        >
          <TwitterShareButton url={shareUrl} title={shareTitle} tabIndex="-1" ref={twitterButton}>
            <ItemWithIcon
              space={16}
              contentVerticalAlign="center"
              icon={<TwitterIcon size={22} round />}
              content={
                <Typography size={16} align="center" lineHeight="small" tag="span" className="ShareModal-LinkTitle">
                  <Typo>Twitter</Typo>
                </Typography>
              }
            />
          </TwitterShareButton>
        </Paper>

        <Paper
          top={16}
          left={16}
          bottom={12}
          className="ShareModal-Item"
          tabIndex="0"
          onClick={(event) => {
            linkedinButton?.current?.click()
            event?.target?.blur()
          }}
          onKeyDown={(event) => event.key === 'Enter' && linkedinButton?.current?.click()}
        >
          <LinkedinShareButton url={shareUrl} quote={shareTitle} tabIndex="-1" ref={linkedinButton}>
            <ItemWithIcon
              space={16}
              contentVerticalAlign="center"
              icon={<LinkedinIcon size={22} round />}
              content={
                <Typography size={16} align="center" lineHeight="small" tag="span" className="ShareModal-LinkTitle">
                  <Typo>Linkedin</Typo>
                </Typography>
              }
            />
          </LinkedinShareButton>
        </Paper>
      </Fragment>
    ),
    [shareUrl, shareTitle, handleCopy],
  )

  if (desktop) {
    return (
      <Modal open={open} onClose={onClose} close={null} data-test-id="shareModal">
        <ModalContent width={36}>
          <Paper top={20} bottom={120} right={24} left={24}>
            <NavigationBar onRightPartClick={onClose} rightPartText="Close" plain>
              <Typography size={20} weight="semibold">
                <Typo>Share</Typo>
              </Typography>
            </NavigationBar>
            <Width size={27} center>
              <Paper top={48}>{shareButtons}</Paper>
            </Width>
          </Paper>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={onClose}>
      <MobileLayout
        header={
          <NavigationBar onRightPartClick={onClose} rightPartText="Close">
            Share
          </NavigationBar>
        }
        content={shareButtons}
      />
    </Modal>
  )
}

export { ShareModal }
