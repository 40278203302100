import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import isFunction from 'lodash/isFunction'

import { modsToClassnames } from 'helpers/classname.js'
import ErrorMessage from 'components/_old/ErrorMessage/ErrorMessage.jsx'
import { Spotlight } from 'components/molecules/Spotlight'

import './Label.css'

export const LabelField = React.forwardRef((props, ref) => {
  const { className, onFocus, onBlur, valid, style, 'data-test-id': dataTestId } = props

  const classes = classNames('Label-Field', className)

  const getChild = (child) => {
    if (!child) {
      return null
    }

    if (typeof child === 'object') {
      const handleFocus = (...args) => {
        if (isFunction(child.props.onFocus)) {
          child.props.onFocus()
        }

        if (isFunction(onFocus)) {
          onFocus(...args)
        }
      }

      const handleBlur = (...args) => {
        if (isFunction(child.props.onBlur)) {
          child.props.onBlur(...args)
        }

        if (isFunction(onBlur)) {
          onBlur(...args)
        }
      }

      return React.cloneElement(child, {
        onFocus: handleFocus,
        onBlur: handleBlur,
        valid: child.props.valid || valid,
        ref: ref || undefined,
      })
    }

    return child
  }

  const children = React.Children.map(props.children, (child) => getChild(child))

  return (
    <div className={classes} style={style} data-test-id={dataTestId}>
      {children}
    </div>
  )
})

LabelField.displayName = 'LabelField'

LabelField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  valid: PropTypes.bool,
  'data-test-id': PropTypes.string,
  style: PropTypes.string,
}

export function LabelText(props) {
  const { className = '', valid, errorMessage, replaceWithError = false, style, 'data-test-id': dataTestId } = props
  let { children } = props
  const classes = classNames('Label-Text', className)

  if (replaceWithError && !valid) {
    children = <ErrorMessage data-test-id="labelErrorMessage">{errorMessage}</ErrorMessage>
  }

  return (
    <div className={classes} style={style} data-test-id={dataTestId}>
      {children}
    </div>
  )
}

LabelText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  valid: PropTypes.bool,
  replaceWithError: PropTypes.bool,
  'data-test-id': PropTypes.string,
  style: PropTypes.object,
}

export function LabelTextWithAction(props) {
  const { valid, errorMessage } = props
  const children = React.Children.map(props.children, (child) => {
    if (!child) {
      return null
    }
    if (typeof child === 'object') {
      return <span className="Label-TextAction">{child}</span>
    }
    if (!valid) {
      return <ErrorMessage mods={{ inline: true }}>{errorMessage}</ErrorMessage>
    }
    return <span>{child}</span>
  })

  return <div className="Label-Text">{children}</div>
}

LabelTextWithAction.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  valid: PropTypes.bool,
}

export default function Label(props) {
  let {
    children,
    postfield,
    valid = true,
    errorMessage,
    errorMessages,
    brokenRule,
    className,
    fluid,
    flex,
    inline,
    inlineClm,
    disabled,
    style,
    mods = {},
    preventFocusOnLabelClick,
    noMarginBottom,
    onFocus,
    onBlur,
    'data-test-id': dataTestId,
  } = props

  if (!mods.size) {
    mods.size = 'small'
  }

  const flexClasses = (() => {
    if (!flex) {
      return null
    }

    if (flex === true) {
      return 'Label_flex'
    }

    const classes = flex.split(' ').map((mod) => `Label_flex_${mod}`)

    return ['Label_flex', ...classes]
  })()

  const classes = classNames(
    'Label',
    className,
    {
      Label_flex: flex,
      Label_inline: inline,
      Label_inlineClm: inlineClm,
      Label_fluid: fluid,
      Label_disabled: disabled,
      Label_noMarginBottom: noMarginBottom,
    },
    flexClasses,
    modsToClassnames('Label', mods),
  )

  if (!errorMessage && typeof brokenRule === 'number' && typeof errorMessages === 'object') {
    errorMessage = errorMessages[brokenRule]
  }

  const fieldProps = { onFocus, onBlur, valid }
  const textProps = { valid, errorMessage }

  children = React.Children.map(children, (child) => {
    if (!child) {
      return null
    }

    if (typeof child === 'object') {
      if (child.type === LabelField) {
        child = React.cloneElement(child, fieldProps)
        return child
      }

      if (child.type === LabelText) {
        child = React.cloneElement(child, textProps)
        return child
      }

      if (child.type === LabelTextWithAction) {
        child = React.cloneElement(child, textProps)
        return child
      }

      if (child.type === Spotlight) {
        return React.cloneElement(child, { children: <LabelField {...fieldProps}>{child.props.children}</LabelField> })
      }

      return <LabelField {...fieldProps}>{child}</LabelField>
    }

    return (
      <LabelText {...textProps} replaceWithError>
        {child}
      </LabelText>
    )
  })

  postfield = postfield ? <div className="Label-Postfield">{postfield}</div> : null

  return !preventFocusOnLabelClick ? (
    <label className={classes} data-test-id={dataTestId} style={style}>
      {children}
      {postfield}
    </label>
  ) : (
    <span className={classes} data-test-id={dataTestId} style={style}>
      {children}
      {postfield}
    </span>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  postfield: PropTypes.node,
  valid: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorMessages: PropTypes.array,
  brokenRule: PropTypes.number,
  className: PropTypes.string,
  fluid: PropTypes.bool,
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inline: PropTypes.bool,
  inlineClm: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  mods: PropTypes.object,
  preventFocusOnLabelClick: PropTypes.bool,
  noMarginBottom: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  dataTestId: PropTypes.string,
}
