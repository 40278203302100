const STROKE_WIDTH = 2 as const

const LIMIT_TICKS_COUNT = 7 as const
const LIMIT_TICKS_COUNT_LESS = 5 as const
const HORDE_DATA_BOUNDARY = 14 as const

const DESKTOP_PADDING = 0 as const
const PHONE_PADDING = 0 as const

export { STROKE_WIDTH, LIMIT_TICKS_COUNT, LIMIT_TICKS_COUNT_LESS, HORDE_DATA_BOUNDARY, DESKTOP_PADDING, PHONE_PADDING }
