import React from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries, useMemo } from 'hooks'

import { isTouch } from 'helpers/mobile'

import './ClickablePoint.css'

type ClickablePointProps = {
  position: {
    x: number
    y: number
  }
  height: number
  active?: boolean
  onClick: (event?: MouseEvent) => void
  onMouseEnter: (...args: unknown[]) => void
  onMouseLeave: (...args: unknown[]) => void
  'data-clickable-point-index'?: number
  'data-clickable-point-data'?: string
}

function ClickablePoint({
  position,
  height,
  active = false,
  onClick: handleClick,
  onMouseEnter: handleMouseEnter,
  onMouseLeave: handleMouseLeave,
  'data-clickable-point-index': dataIndex,
  'data-clickable-point-data': dataData,
}: ClickablePointProps): React.ReactElement {
  const { desktop } = useMediaQueries()
  const clickableWidth = useMemo(() => (desktop ? 12 : 24), [desktop])
  const positionY = useMemo(() => position.y - 1, [position.y])

  const handleClickProps = useMemo(() => {
    if (isTouch()) {
      return {
        // for some reason `onMouseDown` works best
        onMouseDown: handleClick,
      }
    }

    return {
      onClick: handleClick,
    }
  }, [handleClick])

  const classes = useMemo(() => classNames('ClickablePoint', { ClickablePoint_active: active }), [active])

  return (
    <g
      className={classes}
      {...handleClickProps}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-clickable-point-index={dataIndex}
      data-clickable-point-data={dataData}
    >
      {desktop ? (
        <rect
          x={(position.x ?? 0) - clickableWidth / 2}
          y="0"
          width={clickableWidth}
          height={height}
          fill="transparent"
        />
      ) : (
        <circle cx={position.x ?? 0} cy={positionY ?? 0} r={clickableWidth / 2} fill="transparent" />
      )}
      <circle className="ClickablePoint-Circle" cx={position.x ?? 0} cy={positionY ?? 0} r={3} />
    </g>
  )
}

export { ClickablePoint }
