import React, { useEffect } from 'react'

import { useSelector, useMediaQueries, useActions, useState } from 'hooks'

import { changePortfolioHiddenSetting as changePortfolioHiddenSettingActionCreator } from 'app/redux/actions/portfolios'
import { showSuccessToast } from 'app/redux/actions/ui'
import { PortfolioList } from 'app/redux/models/portfolio'
import { getSortedRegulatoryTypes, selectGoalTitle } from 'app/redux/selectors'

import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { PortfolioCard } from './PortfolioCard'

import { regulatoryTypesText } from 'constants/portfolio'

const HiddenPortfoliosContent = (): React.ReactElement => {
  const sortedRegulatoryTypes = useSelector(getSortedRegulatoryTypes)

  const { desktop } = useMediaQueries()

  const portfolios = useSelector((state) => state.portfolios.list)

  const portfoliosState = useSelector((state) => state.portfolios)

  const allHiddenPortfolios = portfolios.filterByShape({ display: { is_hidden: true } })

  const [portfoliosToDisplay, setPortfoliosToDisplay] = useState(new PortfolioList())

  const groupedPortfolios = portfoliosToDisplay.groupByRegulatoryType()

  const [changePortfolioHiddenSetting] = useActions([changePortfolioHiddenSettingActionCreator])

  const handlePortfolioHiddenToggle = async (portfolio): Promise<void> => {
    await changePortfolioHiddenSetting({
      portfolioId: portfolio.id,
      isHidden: !portfolio.display.is_hidden,
    })

    const portfolioTitle = selectGoalTitle({ portfolios: portfoliosState }, portfolio.id)

    showSuccessToast(`${portfolioTitle} is ${portfolio.display.is_hidden ? 'visible' : 'hidden'}`)
  }

  const content = sortedRegulatoryTypes
    .filter((regulatoryType) => groupedPortfolios[regulatoryType]?.length)
    .map((regulatoryType) => (
      <Paper key={regulatoryType} bottom={56}>
        <Typography size={18} lineHeight="small">
          <Typo>{regulatoryTypesText[regulatoryType]}</Typo>
        </Typography>
        {groupedPortfolios[regulatoryType]?.map((hiddenPortfolio) => {
          const portfolio = portfolios.get(hiddenPortfolio.id)

          return (
            <Paper key={portfolio.id} top={desktop ? 24 : 16}>
              <PortfolioCard
                portfolio={portfolio}
                isHidden={!portfolio.display.is_hidden}
                onToggleHide={() => {
                  handlePortfolioHiddenToggle(portfolio)
                }}
                data-test-id="hiddenPortfolio"
              />
            </Paper>
          )
        })}
      </Paper>
    ))

  // set a list of hidden portfolios once on start
  useEffect(() => {
    if (!portfoliosToDisplay.length && allHiddenPortfolios.length) setPortfoliosToDisplay(allHiddenPortfolios)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allHiddenPortfolios])

  if (!portfoliosToDisplay.length)
    return (
      <Typography>
        <Typo>No hidden portfolios</Typo>
      </Typography>
    )

  return <Width size={desktop ? 36.75 : null}>{content}</Width>
}

export { HiddenPortfoliosContent }
