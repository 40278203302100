import { Entity } from 'app/redux/models/common/Entity.js'

import { ukId, isleOfManId, jerseyId, guernseyId } from 'constants/countriesIds.js'

export class Address extends Entity {
  constructor(object) {
    super()

    this.id = null
    this.street = null
    this.street2 = null
    this.postcode = null
    this.city = null
    this.country = null
    this.is_current = false
    this.months_at_address = 0

    if (object) {
      this.setDataFromObject(object)
    }
  }

  /** @returns {boolean} */
  isFilled() {
    return Boolean(this.street)
  }

  /**
   * @param {object[]} countries
   *
   *  @returns {string} */
  getAddressString(countries) {
    const countryData = countries?.find((country) => country.country_id === this.country)

    return [this.street, this.street2, this.city, countryData?.country, this.postcode].filter(Boolean).join(', ')
  }

  /**
   * @returns {boolean}
   */
  isUK() {
    return !this.country || [ukId, isleOfManId, jerseyId, guernseyId].includes(this.country)
  }
}
