export const nciTitles = {
  // Austria
  2782113: null,
  // Belgium
  2802361: 'Belgian National Number (Numéro de registre national – Rijksregisternummer)',
  // Bulgaria
  732800: 'Bulgarian Personal Number',
  // Czech Republic
  3077311: 'National identification number (Rodné číslo)',
  // Germany
  2921044: null,
  // Denmark
  2623032: 'Personal identity code (10 digits alphanumerical DDYYMMXXXX)',
  // Estonia
  453733: 'Estonian Personal Identification Code (Isikukood)',
  // Spain
  2510769: 'Tax identification number (Código de identificación fiscal)',
  // Finland
  660013: 'Personal identity code',
  // France
  3017382: null,
  // UK - special procedure
  2635167: null,
  // Greece
  390903: '10 DSS digit investor share',
  // Croatia
  3202326: 'Personal Identification Number (OIB – Osobni identifikicijski broj)',
  // Hungary
  719819: null,
  // Ireland
  2963597: null,
  // Iceland
  2629691: 'Personal Identity Code (Kennitala)',
  // Italy
  3175395: 'Fiscal code (Codice fiscale)',
  // Lithuania
  597427: 'Personal code (Asmens kodas)',
  // Luxembourg
  2960313: null,
  // Latvia
  458258: 'Personal code (Personas kods)',
  // Malta
  2562770: 'National Identification Number ',
  // Norway
  3144096: '11 digit personal ID (Foedselsnummer)',
  // Poland
  798544: 'National Identification Number (PESEL)',
  // Portugal
  2264397: 'Tax number (Número de Identificação Fiscal)',
  // Romania
  798549: 'National Identification Number (Cod Numeric Personal)',
  // Sweden
  2661886: 'Personal identity number',
  // Slovenia
  3190538: 'Personal Identification Number (EMŠO: Enotna Matična Številka Občana)',
  // Slovakia
  3057568: 'Personal number (Rodné číslo)',
}
