import { useMemo } from 'hooks'

import { usePeriod } from 'app/pages/Securities/hooks'

const useSecurityChart = ({ security }) => {
  const { selectedPeriod, handlePeriodSelect, periodPriceData } = usePeriod(security)

  const chartData = useMemo(() => {
    return (security?.history?.daily || []).map((data) => ({ date: data.date, value: data.share_price }))
  }, [security?.history])

  return {
    chartData,
    selectedPeriod,
    handlePeriodSelect,
    periodPriceData,
  }
}

export { useSecurityChart }
