import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { urlTo } from 'helpers/router.js'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { DesktopFooter } from 'app/containers/Footer'

import { useGrantAccess } from './useGrantAccess'

type GrantAccessProps = {
  location: {
    query: {
      client_id?: string
      state?: string
      redirect_uri?: string
      auth_id?: string
      name?: string
    }
  }
}

const GrantAccess = ({ location: { query } }: GrantAccessProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { userFirstName = '', setAccess } = useGrantAccess({ query })

  const buttons = (
    <Fragment>
      <Button
        mods={{ size: 'big block' }}
        onClick={() => {
          setAccess(true)
        }}
      >
        Allow
      </Button>
      <Paper top={24}></Paper>
      <Button
        mods={{ size: 'big block', theme: 'simple-reverse-blue' }}
        onClick={() => {
          setAccess(false)
        }}
      >
        Deny
      </Button>
    </Fragment>
  )

  const content = (
    <AllCenter>
      <Width size={24}>
        <AllCenter>
          <Typography size={18}>
            <Typo>Hello, {userFirstName}</Typo>
          </Typography>
          <Paper top={32} bottom={32}>
            <Icon type="starling" />
          </Paper>
          <Typography align="center" size={18}>
            <Typo>is requesting access to view your portfolio names and values</Typo>
          </Typography>
          {desktop && (
            <Fragment>
              <Paper top={56}></Paper>
              {buttons}
            </Fragment>
          )}
        </AllCenter>
      </Width>
    </AllCenter>
  )

  const desktopContent = (
    <Inner twoColumns>
      <Paper top={80}>{content}</Paper>
    </Inner>
  )

  const headerNode = <Header logoLink={urlTo('dashboard')} hard withShadow />

  const mobileFooter = (
    <MobileLayoutFooterButton>
      <AllCenter>{buttons}</AllCenter>
    </MobileLayoutFooterButton>
  )

  return (
    <Fragment>
      {desktop ? (
        <DesktopLayout header={headerNode} content={desktopContent} footer={<DesktopFooter />} />
      ) : (
        <MobileLayout header={headerNode} content={content} footer={mobileFooter} />
      )}
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { GrantAccess }
