import React from 'react'

import classNames from 'classnames/dedupe'

import { useCallback, useMediaQueries } from 'hooks'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { VisuallyHidden } from 'components/atoms/VisuallyHidden'

import { PERIODS } from 'app/pages/Securities/hooks'

import './NewPeriodSelect.css'

const SHORT_NAME_BY_PERIOD = {
  [PERIODS.WEEK]: '1W',
  [PERIODS.MONTH]: '1M',
  [PERIODS.SIX_MONTHS]: '6M',
  [PERIODS.YEAR]: '1Y',
  [PERIODS.MAX]: 'MAX',
}

const LONG_NAME_BY_PERIOD = {
  [PERIODS.WEEK]: 'Show information for a week',
  [PERIODS.MONTH]: 'Show information for a month',
  [PERIODS.SIX_MONTHS]: 'Show information for six months',
  [PERIODS.YEAR]: 'Show information for a year',
  [PERIODS.MAX]: 'Show information for maximum available time',
}

type Period = 'max' | 'year' | 'six_months' | 'month' | 'week'

type NewPeriodSelectProps = {
  selectedPeriod: Period
  handlePeriodSelect: (period: Period) => void
}

const NewPeriodSelect = ({ selectedPeriod, handlePeriodSelect }: NewPeriodSelectProps): React.ReactElement => {
  const { desktop, phone } = useMediaQueries()

  const renderPeriod = useCallback(
    ([key, value]) => {
      const isActive = value === selectedPeriod
      const classes = classNames('NewPeriodSelect-Period', {
        'NewPeriodSelect-Period_active': isActive,
        'NewPeriodSelect-Period_phone': phone,
      })

      return (
        <Typography
          size={desktop ? 16 : 14}
          key={key}
          color={isActive ? 'default' : 'additional'}
          align="center"
          className="NewPeriodSelect-PeriodWrapper"
        >
          <button
            className={classes}
            onClick={() => {
              handlePeriodSelect(value)
            }}
            role="switch"
            aria-checked={isActive}
            data-test-id={`periodSelect${SHORT_NAME_BY_PERIOD[value]}`}
            data-period-name={SHORT_NAME_BY_PERIOD[value]}
            tabIndex={0}
          >
            <Paper top={4} bottom={4}>
              <span className="NewPeriodSelect-Name" aria-hidden>
                {SHORT_NAME_BY_PERIOD[value]}
              </span>
            </Paper>
            <VisuallyHidden>{LONG_NAME_BY_PERIOD[value]}</VisuallyHidden>
          </button>
        </Typography>
      )
    },
    [selectedPeriod, desktop, handlePeriodSelect, phone],
  )

  return <div className="NewPeriodSelect">{Object.entries(PERIODS).map(renderPeriod)}</div>
}

export { NewPeriodSelect, type Period }
