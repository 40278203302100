import React from 'react'

import { ClaimBonus } from './ClaimBonus'

const routes = {
  module: 'claim-bonus',
  path: 'claim-bonus/',
  isNewLayout: true,
  component: function Component(props) {
    return <ClaimBonus {...props} />
  },
}

export { routes }
