import Cookies from 'js-cookie'

import legacyDark from './legacyDark.js'
import legacyLight from './legacyLight.js'
import { palettePlainValues } from './palette'

const COOKIE_KEY = 'ie-theme'
// if changed find and replace at whole project
const DISABLE_ANIMATIONS_CLASS_NAME = 'DisableAnimations'

// if changed, also change at src/app/index.html
enum Themes {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  SYSTEM = 'SYSTEM',
}

enum ThemeNames {
  LIGHT = 'Light theme',
  DARK = 'Dark theme',
  SYSTEM = 'Device default',
}

// if changed find and replace at whole project
enum ThemeForceClassName {
  LIGHT = 'ForceLightTheme',
  DARK = 'ForceDarkTheme',
}

function isDarkTheme(): boolean {
  const cookie = Cookies.get(COOKIE_KEY)

  if (cookie) {
    if (cookie === Themes.DARK) {
      return true
    }

    if (cookie === Themes.LIGHT) {
      return false
    }
  }

  if (document.documentElement.classList.contains(ThemeForceClassName[Themes.DARK])) {
    return true
  }

  if (document.documentElement.classList.contains(ThemeForceClassName[Themes.LIGHT])) {
    return false
  }

  return window.matchMedia?.('(prefers-color-scheme: dark)').matches
}

function getCurrentTheme(): Themes {
  const cookieValue = Cookies.get(COOKIE_KEY)

  if (cookieValue === Themes.DARK) {
    return Themes.DARK
  }

  if (cookieValue === Themes.LIGHT) {
    return Themes.LIGHT
  }

  return Themes.SYSTEM
}

function setCurrentTheme(value: Themes): void {
  const cookieValue = Themes[value]

  document.documentElement.classList.add(DISABLE_ANIMATIONS_CLASS_NAME)
  document.documentElement.classList.remove(ThemeForceClassName[Themes.DARK])
  document.documentElement.classList.remove(ThemeForceClassName[Themes.LIGHT])

  // next tick
  setTimeout(() => {
    setThemeColorMetaTagValue()
  }, 0)

  setTimeout(() => {
    document.documentElement.classList.remove(DISABLE_ANIMATIONS_CLASS_NAME)
  }, legacyLight.animation.speed.number.default)

  if (cookieValue === Themes[Themes.SYSTEM]) {
    Cookies.remove(COOKIE_KEY)
    return
  }

  document.documentElement.classList.add(ThemeForceClassName[value])
  Cookies.set(COOKIE_KEY, cookieValue, { expires: 365 * 100 })
}

function setThemeColorMetaTagValue(value = palettePlainValues['background-default']): void {
  const themeColors = document.querySelectorAll('meta[name="theme-color"]')

  if (themeColors && themeColors.length > 0) {
    for (const themeColor of themeColors) {
      themeColor.remove()
    }

    const newThemeColor = document.createElement('meta')
    newThemeColor.name = 'theme-color'
    newThemeColor.content = value

    document.head.appendChild(newThemeColor)
  }
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
  const value = event.matches ? legacyDark['background-default'] : legacyLight['background-default']

  setThemeColorMetaTagValue(value)
})

export {
  isDarkTheme,
  getCurrentTheme,
  setCurrentTheme,
  setThemeColorMetaTagValue,
  Themes,
  ThemeNames,
  ThemeForceClassName,
}
