import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useCallback, useMemo } from 'hooks'

import { WindowScroller, AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized'

import './VirtualList.css'

const caches = {}

const VirtualList = ({
  scrollableElement,
  cacheKey,
  items,
  itemMaxHeight,
  itemMinHeight,
  renderItem,
  renderNoItems,
}) => {
  const [stubShown, setStubShown] = useState(true)
  const hideStub = useCallback(() => setStubShown(false), [setStubShown])

  const cache = useMemo(() => {
    if (cacheKey) {
      const cache =
        caches[cacheKey] ??
        new CellMeasurerCache({
          defaultHeight: itemMaxHeight,
          minHeight: itemMinHeight,
          fixedWidth: true,
        })

      caches[cacheKey] = cache

      return cache
    }

    return new CellMeasurerCache({
      defaultHeight: itemMaxHeight,
      minHeight: itemMinHeight,
      fixedWidth: true,
    })
  }, [itemMaxHeight, itemMinHeight, items])

  const rowRenderer = useCallback(
    ({ key, parent, index, isScrolling, style }) => (
      <CellMeasurer key={key} deferredMeasurementCache cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
        {({ measure, registerChild }) => (
          <div className={classNames('VirtualList-Row', { 'VirtualList-Row_isScrolling': isScrolling })} style={style}>
            {renderItem({ index, measure, registerChild })}
          </div>
        )}
      </CellMeasurer>
    ),
    [renderItem, cache],
  )

  const noRowsRenderer = useCallback(() => renderNoItems(), [renderNoItems])

  const overscanIndicesGetter = useCallback(
    ({ cellCount, overscanCellsCount, startIndex, stopIndex }) => ({
      overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
      overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
    }),
    [],
  )

  return (
    <div className="VirtualList">
      {scrollableElement && (
        <WindowScroller ref={hideStub} scrollElement={scrollableElement}>
          {({ registerChild, height, isScrolling, scrollTop, onChildScroll }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <div ref={registerChild}>
                  <List
                    width={process.env.NODE_ENV === 'test' ? window.innerWidth : width}
                    height={(process.env.NODE_ENV === 'test' ? window.innerHeight : height) || 0}
                    autoHeight={true}
                    rowCount={items.length}
                    rowRenderer={rowRenderer}
                    noRowsRenderer={noRowsRenderer}
                    deferredMeasurementCache={cache}
                    rowHeight={cache.rowHeight}
                    estimatedRowSize={itemMaxHeight}
                    overscanRowCount={2}
                    overscanIndicesGetter={overscanIndicesGetter}
                    isScrolling={isScrolling}
                    scrollTop={scrollTop}
                    onScroll={onChildScroll}
                    tabIndex={null}
                  />
                </div>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
      {/*
        Stub is needed for saving the scroll position between pages.
        Until list is finished rendering it's height is zero and it's breaks scroll position
       */}
      {stubShown && <div className="VirtualList-Stub" style={{ height: items.length * itemMaxHeight }} />}
    </div>
  )
}

VirtualList.propTypes = {
  scrollableElement: PropTypes.oneOfType([PropTypes.instanceOf(Element), PropTypes.instanceOf(Window)]),
  cacheKey: PropTypes.string,
  items: PropTypes.array.isRequired,
  itemMaxHeight: PropTypes.number.isRequired,
  itemMinHeight: PropTypes.number.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderNoItems: PropTypes.func.isRequired,
}

export { VirtualList }
