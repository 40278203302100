import { useMemo, useCallback } from 'hooks'

import { countDaysBeforePayment } from 'helpers/recurringPayments'
import { goTo, urlTo } from 'helpers/router'

import type { QuickStart } from 'app/effector/quickStart/types'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { usePortfolioActions, type Action, ActionKey } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'
import { useCommonPortfolio } from 'app/pages/Dashboard/Goals/hooks/useCommonPortfolio'
import { frequencies } from 'app/pages/Dashboard/Goals/RecurringPayment/constants'

import { regulatoryTypes } from 'constants/portfolio'
import { quickStartStatuses } from 'constants/quickStart'

type useCashPortfolioParams = {
  portfolioId: number
  currentLocation: string
}

type useCashPortfolioData = {
  portfolio: Portfolio
  actions: Action[]
  handleAddFunds: () => void
  handleBack: () => void
  showFinishSavingsPlanBanner: boolean
  quickStart?: QuickStart
  showFirstTopupBanner: boolean
  daysBeforePayment: number
}

const useCashPortfolio = ({ portfolioId, currentLocation }: useCashPortfolioParams): useCashPortfolioData => {
  const { portfolio, savingsPlan, quickStart } = useCommonPortfolio({ portfolioId })

  const daysBeforePayment = countDaysBeforePayment(
    savingsPlan?.frequency || frequencies.MONTHLY,
    savingsPlan?.start_day_week,
    savingsPlan?.day_of_month,
  )

  const showFinishSavingsPlanBanner = quickStart?.status === quickStartStatuses.ACTIVE && !savingsPlan
  const showFirstTopupBanner =
    quickStart?.status === quickStartStatuses.ACTIVE && !!savingsPlan && daysBeforePayment > 0

  const availableActionsKeys = useMemo(() => {
    const actionKeys = [
      ActionKey.WITHDRAW,
      ActionKey.SAVINGS_PLAN,
      ActionKey.TRANSFER_SIPP,
      ActionKey.MOVE_CASH_IN,
      ActionKey.MOVE_CASH_OUT,
      ActionKey.TRANSACTIONS,
    ]

    if (portfolio.regulatory_type === regulatoryTypes.ISA) {
      return [...actionKeys, ActionKey.TRANSFER_ISA]
    }

    return actionKeys
  }, [portfolio])

  const { actions } = usePortfolioActions(portfolio, () => {})

  const handleAddFunds = useCallback(
    () => actions.find((action) => action.key === ActionKey.ADD_FUNDS)?.onClick?.(currentLocation),
    [actions, currentLocation],
  )

  const handleBack = (): void => {
    goTo(urlTo('dashboard'))
  }

  return {
    portfolio,
    actions: actions.filter((action) => availableActionsKeys.includes(action.key)),
    handleAddFunds,
    handleBack,
    showFinishSavingsPlanBanner,
    quickStart,
    showFirstTopupBanner,
    daysBeforePayment,
  }
}

export { useCashPortfolio }
