import React, { forwardRef } from 'react'

import classNames from 'classnames/dedupe'
import './Width.css'

type WidthProps = React.HTMLAttributes<HTMLDivElement> & {
  size?: number | null
  center?: boolean
  right?: boolean
  inline?: boolean
  children: React.ReactNode
}

const Width = forwardRef<HTMLDivElement, WidthProps>((props, ref) => {
  const { className, size, center, right, inline, children, style, ...rest } = props

  return (
    <div
      ref={ref}
      className={classNames(className, 'Width', { Width_center: center, Width_right: right, Width_inline: inline })}
      style={{ width: size ? `${size}em` : undefined, ...style }}
      {...rest}
    >
      {children}
    </div>
  )
})
Width.displayName = 'Width'

export default Width
