import React, { Fragment } from 'react'

import { Mask } from '@reactour/mask'
import { Popover } from '@reactour/popover'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import Button from 'components/_old/Button/Button.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import './Hint.css'

type HintProps = {
  children: string
  sizes: {
    bottom: number
    height: number
    left: number
    right: number
    top: number
    width: number
  }
  closeHint: () => void
  forceArrowHorizontalAlign?: 'left' | 'right'
  arrowPadding?: number
}

const POPOVER_WIDTH = 342
const MASK_PADDING = 8
const BUTTON_HEIGHT = 48

const opositeSide = {
  top: 'bottom',
  bottom: 'top',
  right: 'left',
  left: 'right',
}

const doArrow = (position: string, verticalAlign: string, horizontalAlign: string, arrowPadding: number): object => {
  if (!position || position === 'custom') {
    return {}
  }

  const width = 16
  const height = 16
  const color = palette.stocks
  const isVertical = position === 'top' || position === 'bottom'
  const spaceFromSide = arrowPadding
  const obj = {
    [`--rtp-arrow-${isVertical ? horizontalAlign : verticalAlign}`]: height + spaceFromSide + 'px',
    [`--rtp-arrow-${opositeSide[position] as string}`]: -height + 2 + 'px',
    [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${width / 2}px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${width / 2}px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
  }

  if (isVertical) obj.marginTop = `${position === 'top' ? '-' : ''}8px`

  return obj
}

const Hint = ({
  children,
  sizes,
  closeHint,
  forceArrowHorizontalAlign,
  arrowPadding = 20,
}: HintProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <Mask
        sizes={sizes}
        padding={MASK_PADDING}
        highlightedAreaClassName="Hint-Highlighted-Area"
        styles={{
          maskWrapper: (base) => ({
            ...base,
            opacity: 0.75,
            zIndex: 999999,
          }),
          maskArea: (base) => ({ ...base, rx: 8 }),
        }}
        onClick={closeHint}
      />
      <Popover
        sizes={{
          ...sizes,
          left: desktop ? sizes.left - (POPOVER_WIDTH - sizes.width - MASK_PADDING * 2) : 0,
        }}
        padding={16}
        position={({ height, bottom, windowHeight }) => {
          if (!desktop) return 'top'
          return height + bottom + 50 > windowHeight ? 'top' : 'bottom'
        }}
        refresher={{ sizes }}
        styles={{
          popover: (base, state) => ({
            ...base,
            display: 'block',
            minWidth: desktop ? POPOVER_WIDTH : 'calc(100% - 32px)',
            maxWidth: desktop ? POPOVER_WIDTH : 'calc(100% - 32px)',
            padding: 0,
            background: palette.stocks,
            left: !desktop ? 24 : base.left,
            borderRadius: 8,
            ...doArrow(
              state?.position,
              state?.verticalAlign,
              forceArrowHorizontalAlign ?? state?.horizontalAlign,
              arrowPadding,
            ),
            zIndex: 999999,
          }),
        }}
      >
        <Paper top={16} bottom={desktop ? 24 : 16} left={16} right={16} data-test-id="editWeightsHint">
          <Typography color="white" data-test-id="hintText">
            <Typo>{children}</Typo>
          </Typography>
          {desktop && (
            <Paper top={16}>
              <Button onClick={closeHint} data-test-id="closeHintButton">
                Continue
              </Button>
            </Paper>
          )}
        </Paper>
      </Popover>
      {!desktop && (
        <Popover
          sizes={sizes}
          position={({ windowHeight }) => [16, windowHeight - BUTTON_HEIGHT - 16]}
          styles={{
            popover: (base) => ({
              ...base,
              minWidth: 'calc(100% - 32px)',
              padding: 0,
              background: 'transparent',
              zIndex: 999999,
            }),
          }}
        >
          <Button mods={{ size: 'big block' }} onClick={closeHint}>
            Continue
          </Button>
        </Popover>
      )}
    </Fragment>
  )
}

export { Hint }
