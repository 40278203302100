import { ListOf } from 'app/redux/models/common/ListOf'

import { PortfolioSecurity } from './PortfolioSecurity.js'

/**
 * @param {Array.<PortfolioSecurity> }portfolioSecurityList
 * @return {Array.<PortfolioSecurity>}
 */
const sort = (portfolioSecurityList) =>
  portfolioSecurityList.sort((left, right) => {
    // if both of the securities has `target_weight`
    if (left?.target_weight !== null && right.target_weight !== null) {
      // if securities have the same `target_weight`, sort it by value
      if (left.target_weight === right.target_weight && left?.value !== null && right?.value !== null) {
        return right.value >= left.value ? 1 : -1
      }

      // in other case, sort them by target weight
      return right.target_weight >= left.target_weight ? 1 : -1
    }

    // if there is no `target_weight`, move security to the beginning of the list
    if (left?.target_weight === null && (right?.target_weight ?? null) !== null) {
      return -1
    }

    return 0
  })

/**
 * @class
 * @typedef {Array.<PortfolioSecurity>} PortfolioSecurityList
 */
export class PortfolioSecurityList extends ListOf(PortfolioSecurity, sort) {
  /**
   * @param {number} id
   * @return {?PortfolioSecurity}
   */
  get(id) {
    return this.find((security) => security.security_id === id) ?? null
  }

  /**
   * @param {PortfolioSecurity} security
   * @return {PortfolioSecurityList}
   */
  add(security) {
    return new PortfolioSecurityList(...[...this, security])
  }

  /**
   * @param {PortfolioSecurity} security
   * @return {PortfolioSecurityList}
   */
  replace(security) {
    const newSecurities = [...this]
    const currentSecurityIndex = newSecurities.findIndex(
      (collectionSecurity) => collectionSecurity.security_id === security.security_id,
    )

    if (currentSecurityIndex < 0) {
      return newSecurities
    }

    newSecurities[currentSecurityIndex] = security

    return new PortfolioSecurityList(...newSecurities)
  }

  /**
   * @param {PortfolioSecurity} security
   * @return {PortfolioSecurityList}
   */
  replaceOrAdd(security) {
    if (this.findIndex((collectionSecurity) => collectionSecurity.security_id === security.security_id) > -1) {
      return this.replace(security)
    }

    return this.add(security)
  }

  /**
   * @param {number} security_id
   * @return {PortfolioSecurityList}
   */
  remove(securityId) {
    const newSecurities = new PortfolioSecurityList(...this)

    return newSecurities.filter((security) => security.security_id !== securityId)
  }
}
