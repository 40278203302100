import { getRoundedMinMaxValues } from 'helpers/charts/getRoundedMinMaxValues'
import moment from 'helpers/date.js'

import { type DividendsChartData } from '../../types'

type transformDividendsDataReturnType = {
  max: number
  rawMax: number
  paidOutSum: number
  data: Array<{ date: Date; value: number; type: string }>
  withFloat: boolean
}

const transformDividendsData = (
  rawData: DividendsChartData = [],
  defaultMax = 0.1,
): transformDividendsDataReturnType => {
  const data =
    rawData?.map(({ date: rawDate, paid_out: rawPaidOut, paid_out_by_security: paidOutByEfs = [] }) => ({
      date: moment(rawDate).toDate(),
      paid_out: parseFloat(rawPaidOut || '0'),
      paid_out_by_security: paidOutByEfs.map(({ paid_out: paidOut, ticker }) => ({
        value: parseFloat(paidOut || '0'),
        ticker,
      })),
    })) || []

  const rawMax = Math.max(...data.map(({ paid_out: paidOut }) => paidOut))

  const { max, withFloat } = getRoundedMinMaxValues(0, rawMax || defaultMax, false)

  const paidOutSum = data.reduce((sum, { paid_out: paidOut }) => sum + paidOut, 0)

  return {
    max,
    rawMax,
    paidOutSum,
    data: data.map(({ date, paid_out: paidOut, paid_out_by_security: paidOutByEtfs }) => ({
      date,
      value: paidOut,
      paidOutByEtfs,
      type: 'dividends',
    })),
    withFloat,
  }
}

export { transformDividendsData }
