import React from 'react'

import { useMediaQueries } from 'hooks'

import { features } from 'helpers/features'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

const IncomePortfolioFooterInfo = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  return (
    <Paper top={desktop ? 56 : 32}>
      <Typography size={desktop && !features.get('updated-income-projections') ? 14 : 12} color="minor">
        <Typo>
          The chart should only be used as an indication of the future income of the portfolio you selected. Income
          amounts are based on the current capital balance (excluding undistributed income) plus any one-off deposit.
          The chart shows the projected income due to be received in your portfolio each month. This income is then
          automatically paid out to your nominated bank account in the first 5 working days of the next month, unless
          you instruct us otherwise. The income on your portfolio may be less than indicated in the first two months
          after investing because ex-dividend dates have already passed. InvestEngine’s fees and the ETF charges are not
          paid out of the income the portfolio generates ⁠— they are taken from the capital value of the portfolio and
          the ETFs, respectively. The information provided does not constitute advice or a promise of future outcomes.
          InvestEngine’s optimal portfolio is based only on the information you have provided through your answers to
          the risk questionnaire.
        </Typo>
      </Typography>
    </Paper>
  )
}

export { IncomePortfolioFooterInfo }
