import React from 'react'

import { useCallback, useMemo } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { formatDateToBackend } from 'helpers/date'
import { urlTo } from 'helpers/router'

import { TransactionTypeFilterValue } from 'app/pages/Dashboard/Transactions/hooks/useFilters'

import { PointOfInterestCard, type PointOfInterestCardProps } from '../Card'

import {
  type BuySellPortfolioPointOfInterest,
  type CombinedPortfolioPointOfInterest,
  PortfolioBuySellPointOfInterestContent,
  PortfolioCombinedPointOfInterestContent,
  type PortfolioPointOfInterest,
  PortfolioPointOfInterestTypes,
} from './index'

type PortfolioPointOfInterestCardProps = Omit<PointOfInterestCardProps, 'renderContent' | 'width'> & {
  portfolioId?: number
}

function PortfolioPointOfInterestCard(props: PortfolioPointOfInterestCardProps): React.ReactElement | null {
  const { portfolioId } = props
  const pointOfInterest = useMemo<PortfolioPointOfInterest | Record<string, undefined>>(
    () => props.pointOfInterest ?? {},
    [props.pointOfInterest],
  )
  const { type, date, dateFrom: dateFromRaw, dateTo: dateToRaw } = pointOfInterest

  const linkToTransactions = useMemo(() => {
    if (!type || !date || !portfolioId) {
      return null
    }

    let transactionType
    let dateFrom = dateFromRaw
    let dateTo = dateToRaw

    if (type !== PortfolioPointOfInterestTypes.COMBINED) {
      transactionType =
        type === PortfolioPointOfInterestTypes.BUY ? TransactionTypeFilterValue.BUY : TransactionTypeFilterValue.SELL
      dateFrom = date
      dateTo = date
    }

    return urlTo(
      'dashboard.transactions',
      null,
      querystringFromObject({
        types: transactionType,
        date: 'custom',
        date_from: formatDateToBackend(dateFrom),
        date_to: formatDateToBackend(dateTo),
        portfolio_id: portfolioId,
      }),
    )
  }, [type, date, dateFromRaw, dateToRaw, portfolioId])

  const renderContent = useCallback(() => {
    if (!type) {
      return null
    }

    if (type === PortfolioPointOfInterestTypes.COMBINED) {
      return (
        <PortfolioCombinedPointOfInterestContent
          {...(pointOfInterest as CombinedPortfolioPointOfInterest)}
          linkToTransactions={linkToTransactions}
        />
      )
    }

    return (
      <PortfolioBuySellPointOfInterestContent
        {...(pointOfInterest as BuySellPortfolioPointOfInterest)}
        linkToTransactions={linkToTransactions}
      />
    )
  }, [type, pointOfInterest, linkToTransactions])

  return <PointOfInterestCard {...props} width={158} renderContent={renderContent} />
}

export { PortfolioPointOfInterestCard, type PortfolioPointOfInterestCardProps }
