import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'

import { useEffect, useMediaQueries, useRef } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { $recoveryCode, setRecoveryCode, loginWithRecoveryCodeFx } from './model'

type RecoveryCodeFormProps = {
  query: Record<string, string>
  shouldRememberBrowser: boolean
  onBack: () => void
}

const RecoveryCodeForm = ({
  query,
  shouldRememberBrowser,
  onBack: handleBack,
}: RecoveryCodeFormProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const [recoveryCode, handleSetRecoveryCode, handleLoginWithRecoveryCode] = useUnit([
    $recoveryCode,
    setRecoveryCode,
    loginWithRecoveryCodeFx,
  ])

  const { client_id: clientId, token } = query

  const inputRef = useRef()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleRecoveryCodeChange = (event, value): void => {
    handleSetRecoveryCode(value)
  }

  const handleSubmitRecoveryCode = (): void => {
    handleLoginWithRecoveryCode({ code: recoveryCode, shouldRememberBrowser, clientId, token })
  }

  const headerNode = (
    <NavigationBar onLeftPartClick={handleBack} leftPartText="Back">
      <Typo>&nbsp</Typo>
    </NavigationBar>
  )

  const titleNode = (
    <Fragment>
      <AllCenter>
        <Icon type="question-in-bubble-80" size={80} color={palette['content-on-background-primary']} />
      </AllCenter>
      <Paper top={16}>
        <Typography size={desktop ? 32 : 24} lineHeight="small" weight="semibold" align="center">
          <Typo>Enter your recovery code</Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  const content = (
    <Fragment>
      <Input
        ref={inputRef}
        name="code"
        mods={{ size: 'big' }}
        style={{ borderWidth: '2px' }}
        onChange={handleRecoveryCodeChange}
      >
        {recoveryCode}
      </Input>
      <Paper top={40}>
        <Typography size={desktop ? 14 : 16} align="center">
          <Typo>
            If you have lost your phone, send us a message from the{' '}
            <Nobr>
              <Link to="https://help.investengine.com/" hard>
                Help Centre
              </Link>
              .
            </Nobr>
          </Typo>
        </Typography>
      </Paper>
    </Fragment>
  )

  const button = (
    <Button mods={{ size: 'big block' }} onClick={handleSubmitRecoveryCode} disabled={!recoveryCode}>
      Continue
    </Button>
  )

  if (desktop) {
    return (
      <Paper top={8} left={24} right={24} bottom={64} style={{ position: 'relative' }}>
        {headerNode}
        <Paper top={40}>{titleNode}</Paper>
        <Paper top={40} left={40} right={40}>
          {content}
          <Paper top={40}>{button}</Paper>
        </Paper>
      </Paper>
    )
  }

  return (
    <MobileLayout
      header={headerNode}
      content={
        <Fragment>
          {titleNode}
          <Paper top={40}>{content}</Paper>
        </Fragment>
      }
      footer={<MobileLayoutFooterButton>{button}</MobileLayoutFooterButton>}
    />
  )
}

export { RecoveryCodeForm }
