import { useAsyncEffect, useCallback, useLoading, useMemo, useState, useDebouncedEffect } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { replace as historyReplace } from 'helpers/history'
import { palette } from 'helpers/palette'
import { goTo, urlTo } from 'helpers/router.js'

import { getSharedSecurityData } from 'app/effector/securities/api'

import { showFailToast } from 'app/redux/actions/ui'
import { ApiError } from 'app/redux/models/errors'

import { useSecurityLabels } from 'app/pages/Securities/Security/hooks'

import { type SharedSecurityData, type SecurityStateData } from '../../types'

type Query = {
  back?: string
  search?: string
}

const useSharedSecurity = (
  securityId: number,
  portfolioHash: string,
  portfolioId?: string,
  query?: Query,
): SharedSecurityData => {
  const [securityData, setSecurityData] = useState<SecurityStateData | null>(null)
  const { isLoading, wait } = useLoading(false)
  const securityLabels = useSecurityLabels(securityData)

  const isAnalyticsVisible = useMemo(
    () => securityData && securityData.type !== 'BOND' && securityData.type !== 'ALTERNATIVE',
    [securityData],
  )

  const chartData = useMemo(() => {
    return (securityData?.history?.daily ?? []).map((data) => ({ date: data.date, value: data.share_price }))
  }, [securityData?.history])

  useAsyncEffect(async () => {
    try {
      const data = await wait(getSharedSecurityData(securityId))

      if (data instanceof ApiError) {
        throw data
      }

      data.equities = data.equities.map((item) => ({
        ...item,
        actual: parseFloat(item.target_weight),
        color: palette.stocks,
      }))
      data.regions = data.regions.map((item) => ({
        ...item,
        actual: parseFloat(item.target_weight),
      }))
      data.sectors = data.sectors.map((item) => ({
        ...item,
        actual: parseFloat(item.target_weight),
      }))

      setSecurityData(data)
    } catch (error) {
      showFailToast()
    }
  }, [securityId])

  const handleGoBack = useCallback(() => {
    portfolioId
      ? goTo(urlTo('dashboard.portfolio.share', { id: portfolioId }, query))
      : goTo(urlTo('portfolio-sharing.portfolio', { hash: portfolioHash }, query))
  }, [portfolioHash, portfolioId, query])

  const goToSecurityAnalytics = useCallback(
    (tab: string) => {
      portfolioId
        ? goTo(
            urlTo(
              `dashboard.portfolio.share.shared-security.shared-security-analytics`,
              {
                id: portfolioId,
                securityId,
                tab,
              },
              query,
            ),
          )
        : goTo(urlTo(`portfolio-sharing.security.analytics`, { hash: portfolioHash, securityId, tab }, query))
    },
    [portfolioHash, portfolioId, securityId, query],
  )

  const handleBackToSecurity = useCallback(() => {
    portfolioId
      ? goTo(urlTo('dashboard.portfolio.share.shared-security', { id: portfolioId, securityId }, query))
      : goTo(urlTo('portfolio-sharing.security', { hash: portfolioHash, securityId }, query))
  }, [portfolioHash, portfolioId, securityId, query])

  // search
  const [searchValue, setSearchValue] = useState(query?.search ?? '')

  useDebouncedEffect(
    () => {
      const { search, ...queryObjectWoSearch } = query ?? {}
      const nextQueryObject =
        searchValue.length > 0 ? { ...queryObjectWoSearch, search: searchValue } : queryObjectWoSearch
      const nextQueryString = querystringFromObject(nextQueryObject)
      const currentUrl = `${window.location.pathname}${window.location.hash}${window.location.search}`
      const nextUrl = `${window.location.pathname}${window.location.hash}${nextQueryString}`

      if (currentUrl !== nextUrl) {
        return historyReplace(nextUrl, { scrollToTop: false })
      }
    },
    250,
    [searchValue],
  )

  const handleSearch = useCallback(
    (newSearchValue) => {
      setSearchValue(newSearchValue)
    },
    [setSearchValue],
  )

  return {
    isLoading,
    isAnalyticsVisible,
    equities: securityData?.equities ?? [],
    sectors: securityData?.sectors ?? [],
    regions: securityData?.regions ?? [],
    security: securityData,
    securityLabels,
    chartData,
    searchValue,
    handleSearch,
    handleGoBack,
    handleBackToSecurity,
    goToSecurityAnalytics,
  }
}

export { useSharedSecurity }
