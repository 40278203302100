import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { palette } from 'helpers/palette/'

import { useMediaQueries } from 'hooks'

import { ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import { NavigationBar } from 'components/atoms/NavigationBar'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Width from 'components/_old/Width/Width'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

const NotApproved = ({ handleClose }) => {
  const { desktop } = useMediaQueries()

  const button = (
    <Button mods={{ size: 'big block' }} onClick={handleClose} data-test-id="notApprovedModalOk">
      OK
    </Button>
  )

  const contentNode = (
    <Width size={28} center>
      <Paper top={80} left={desktop ? 0 : 16} right={desktop ? 0 : 16}>
        <AllCenter>
          <Icon type="time-in-circle-64" color={palette['status-warning']} size={64} data-test-id="warningIcon" />
        </AllCenter>
        <Paper top={32}>
          <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
            We need to check something
          </Typography>
        </Paper>
        <Paper top={24}>
          <Typography align="center">
            <Typo>
              Unfortunately, we weren’t able to automatically activate your account. Our Support team will be in touch
              with you shortly.
            </Typo>
          </Typography>
        </Paper>
      </Paper>
    </Width>
  )

  if (!desktop) {
    return (
      <Fragment>
        <NavigationBar leftPartText="Back" onLeftPartClick={handleClose}>
          Add funds
        </NavigationBar>
        {contentNode}
        <ModalContentButtons isNewModal>{button}</ModalContentButtons>
      </Fragment>
    )
  }

  return (
    <Width size={36} style={{ minHeight: 600 }} data-test-id="notApprovedModal">
      {contentNode}
      <Paper top={56}>
        <Width size={24} center>
          {button}
        </Width>
      </Paper>
    </Width>
  )
}

NotApproved.propTypes = {
  handleClose: PropTypes.func,
}

export { NotApproved }
