import { useUnit } from 'effector-react'

import { useMemo, useState, useCallback, useEffect, useSelector } from 'hooks'

import { convertRecurringPaymentAmountToMonthly } from 'helpers/recurringPayments'

import { $recurringPaymentsStore } from 'app/effector/recurringPayments'

const useEditablePortfolio = (portfolio, specificEditableFields, changeField, editablePortfolioFields) => {
  const { getReccuringPaymentByPortfolioId } = useUnit($recurringPaymentsStore)
  const recurringPayment = getReccuringPaymentByPortfolioId(portfolio.id)

  const editableFields = useMemo(
    () => [...specificEditableFields, 'initial_deposit', 'one_off_payment', 'preset', 'monthly_deposit'],
    [specificEditableFields],
  )
  const getInitialEditablePortfolio = useCallback(() => {
    const editablePortfolio = { ...portfolio }

    if (editablePortfolioFields?.one_off_payment) {
      editablePortfolio.one_off_payment = editablePortfolioFields.one_off_payment
    }

    if (editablePortfolioFields?.preset) {
      editablePortfolio.preset = editablePortfolioFields.preset
    }

    if (editablePortfolioFields?.monthly_deposit) {
      editablePortfolio.monthly_deposit = editablePortfolioFields.monthly_deposit
    }

    if (recurringPayment) {
      editablePortfolio.monthly_deposit = convertRecurringPaymentAmountToMonthly(
        Number(recurringPayment.amount),
        recurringPayment.frequency,
      )
    }

    if (editablePortfolioFields?.term) {
      editablePortfolio.term = editablePortfolioFields.term
    }

    return editablePortfolio
  }, [portfolio, editablePortfolioFields, recurringPayment])
  const [editablePortfolio, setEditablePortfolio] = useState(getInitialEditablePortfolio())
  const [isPortfolioChanged, setIsPortfolioChanged] = useState(false)
  const [isPresetChanged, setIsPresetChanged] = useState(false)
  const presets = useSelector((state) => state.portfolios.presets)[portfolio?.preset_type] || []
  const isPortfolioActive = portfolio.first_topup || parseInt(portfolio.current_balance, 10) > 0

  useEffect(
    () => {
      setEditablePortfolio(getInitialEditablePortfolio())
    },
    // had to run only if one of editable fields changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      portfolio.preset,
      portfolio.preset_recommended,
      portfolio.initial_deposit,
      portfolio.one_off_payment,
      portfolio.monthly_deposit,
      portfolio.term,
    ],
  )

  useEffect(() => {
    setIsPortfolioChanged(
      Boolean(
        editableFields.filter((field) => {
          if (portfolio[field] && editablePortfolio[field]) {
            return parseFloat(portfolio[field]) !== parseFloat(editablePortfolio[field])
          }

          return portfolio[field] !== editablePortfolio[field]
        }).length,
      ),
    )
    setIsPresetChanged(portfolio.preset !== editablePortfolio.preset)
  }, [portfolio, editablePortfolio, editableFields])

  const change = useCallback(
    (field, value) => {
      if (editableFields.includes(field)) {
        setEditablePortfolio((editablePortfolio) => ({
          ...editablePortfolio,
          [field]: value,
        }))
      }
    },
    [editableFields, setEditablePortfolio],
  )

  const updatePortfolioAppState = useCallback(() => {
    editableFields.forEach((field) => {
      const fields = isPortfolioActive ? ['one_off_payment', 'monthly_deposit'] : ['one_off_payment']

      if (!fields.includes(field)) {
        changeField({ [field]: editablePortfolio[field] }, portfolio.id)
      }
    })
  }, [editableFields, isPortfolioActive, changeField, editablePortfolio, portfolio.id])

  return {
    presets,
    editablePortfolio,
    editableFields,
    isPortfolioChanged,
    isPresetChanged,
    change,
    updatePortfolioAppState,
    isPortfolioActive,
  }
}

export default useEditablePortfolio
