import React from 'react'
import { withRouter } from 'react-router'

import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'

import { OptionsLayout } from '../Options/OptionsLayout'
import { OptionsNavigationBar } from '../Options/OptionsNavigationBar'

import { PortfoliosGroup } from './components/PortfoliosGroup'
import { useMoveCash } from './hooks'

import { regulatoryTypes } from 'constants/portfolio'

type MoveCashProps = {
  portfolio: Portfolio
  location: { query?: { back?: string } }
  action: string
  handleClose: () => void
}

const MoveCash = withRouter(
  ({ portfolio, action, location: { query = {} } = {}, handleClose }: MoveCashProps): React.ReactElement => {
    const { desktop } = useMediaQueries()
    const { isBusiness, groupedPortfolios, handlePortfolioSelect, handleBack } = useMoveCash(
      portfolio,
      action,
      query?.back,
    )

    const isaNode =
      groupedPortfolios[regulatoryTypes.ISA]?.length > 0 ? (
        <Paper bottom={48}>
          <PortfoliosGroup
            title="ISA"
            portfolios={groupedPortfolios[regulatoryTypes.ISA]}
            isBusiness={isBusiness}
            handlePortfolioSelect={handlePortfolioSelect}
          />
        </Paper>
      ) : null

    const giaNode =
      groupedPortfolios[regulatoryTypes.GIA]?.length > 0 ? (
        <Paper bottom={48}>
          <PortfoliosGroup
            title="General"
            portfolios={groupedPortfolios[regulatoryTypes.GIA]}
            isBusiness={isBusiness}
            hideTitle={isBusiness}
            handlePortfolioSelect={handlePortfolioSelect}
          />
        </Paper>
      ) : null

    const sippNode =
      groupedPortfolios[regulatoryTypes.SIPP]?.length > 0 ? (
        <PortfoliosGroup
          title="Personal Pension"
          portfolios={groupedPortfolios[regulatoryTypes.SIPP]}
          isBusiness={isBusiness}
          handlePortfolioSelect={handlePortfolioSelect}
        />
      ) : null

    const content = (
      <Width size={24} style={{ minHeight: 520 }} center>
        <Paper top={desktop ? 32 : 0}>
          {isaNode}
          {giaNode}
          {sippNode}
        </Paper>
      </Width>
    )

    const moveCashTitle = (): React.ReactElement => {
      switch (action) {
        case 'in':
          return <Typo>Move cash in from</Typo>
        case 'out':
          return <Typo>Move cash out to</Typo>
        default:
          return <Typo>Move cash to</Typo>
      }
    }

    return (
      <OptionsLayout
        header={
          <OptionsNavigationBar
            leftPartText="Back"
            onLeftPartClick={handleBack}
            rightPartText="Close"
            onRightPartClick={handleClose}
          >
            {moveCashTitle()}
          </OptionsNavigationBar>
        }
        content={content}
        button={null}
        data-test-id="moveCashModal"
      />
    )
  },
)

MoveCash.propTypes = {
  portfolio: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export { MoveCash }
