import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useState, useCallback } from 'hooks'

import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'
import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Card from 'components/_old/Card/Card.jsx'

const TermsAndConditionsForm = ({ wrapSubmitButton, onSubmit: handleSubmit }) => {
  const { desktop } = useMediaQueries()

  const [confirmValues, setConfirmValues] = useState({
    agreedWithTerms: false,
    isAuthorisedPerson: false,
  })

  const handleConfirm = useCallback(
    (name, checked) => {
      setConfirmValues({ ...confirmValues, [name]: checked })
    },
    [confirmValues, setConfirmValues],
  )

  const submitButton = (
    <Button
      type="submit"
      mods={{ size: 'big block' }}
      style={{ marginTop: 1.875 }}
      onClick={handleSubmit}
      disabled={!confirmValues.agreedWithTerms || !confirmValues.isAuthorisedPerson}
      tabIndex={3}
      data-test-id="tunnelStepTermsSubmit"
    >
      Continue
    </Button>
  )

  const renderDocumentsLink = (title, url) => {
    return (
      <Link to={url} hard blank rel="noopener noreferrer">
        <Text small>
          <Typo>{title}</Typo>
        </Text>
      </Link>
    )
  }

  return (
    <Card
      mods={{
        theme: !desktop ? 'edge-to-edge transparent' : null,
        'no-padding': desktop ? null : 'top',
      }}
    >
      <Width className="" size={desktop ? 22 : null}>
        <Text small>
          <Typo>
            Before deciding to invest you should understand&#x200b; the investment management and custody services we
            provide —&nbsp;and their associated risks —&nbsp;by reading our Terms and Conditions and Risk Disclosure
            Statement.
          </Typo>
        </Text>
        <ul className="List_common">
          <li>{renderDocumentsLink('Terms and Conditions', '/terms/')}</li>
          <li>{renderDocumentsLink('Risk Disclosure Statement', '/risk-disclosure/')}</li>
          <li>{renderDocumentsLink('Portfolios - Key features', '/key-features-documents/')}</li>
        </ul>
        <CheckboxWithLabel
          label={
            <Text small>
              <Typo>
                I confirm I have accurately represented my company information and I have read and agree to the
                documents provided above
              </Typo>
            </Text>
          }
          tabIndex={1}
          checked={confirmValues.agreedWithTerms}
          onChange={(value) => handleConfirm('agreedWithTerms', value)}
          data-test-id="tunnelStepTermsConfirmPersonInput"
        />
        <Card mods={{ theme: 'transparent', 'no-padding': 'right bottom left', padding: 'small' }}>
          <CheckboxWithLabel
            label={
              <Text small>
                <Typo>I confirm I am authorised to make decisions on behalf of my company</Typo>
              </Text>
            }
            tabIndex={2}
            checked={confirmValues.isAuthorisedPerson}
            onChange={(value) => handleConfirm('isAuthorisedPerson', value)}
            data-test-id="tunnelStepTermsConfirmInfoInput"
          />
        </Card>
        {wrapSubmitButton(submitButton)}
      </Width>
    </Card>
  )
}

TermsAndConditionsForm.propTypes = {
  wrapSubmitButton: PropTypes.func,
  onSubmit: PropTypes.func,
}

export { TermsAndConditionsForm }
