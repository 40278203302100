import React from 'react'
import { withRouter } from 'react-router'

import { preventScrollTopRouteModules } from 'helpers/router.js'

@withRouter
export default class ScrollTopOnRouteUpdate extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const prevRoutePreventScrollTop = preventScrollTopRouteModules.includes(
        prevProps.routes[prevProps.routes.length - 1].module,
      )
      const newRoutePreventScrollTop = preventScrollTopRouteModules.includes(
        this.props.routes[this.props.routes.length - 1].module,
      )
      const scrollToTop = this?.props?.location?.state?.scrollToTop ?? true

      if (!prevRoutePreventScrollTop && !newRoutePreventScrollTop && scrollToTop) {
        // for mobile safari
        window.scroll(0, 0)
        // for other browsers
        const layoutElement = document.querySelector('.LayoutElement_main')

        if (layoutElement) {
          layoutElement.scrollTop = 0
        }
      }
    }
  }

  render() {
    return this.props.children
  }
}
