import React from 'react'

import InlineHelp from 'components/_old/InlineHelp/InlineHelp.jsx'
import ModalContent, {
  ModalContentHeadline,
  ModalContentBody,
  ModalContentButtons,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import { Typo } from 'components/_old/Typo/Typo'

const SignificantControlInlineHelp = () => (
  <InlineHelp size={20}>
    {(handleClose) => (
      <ModalContent>
        <ModalContentHeadline>What's "significant control"?</ModalContentHeadline>
        <ModalContentBody>
          <Typo>
            A person of significant control is someone that holds more than 25% of shares or voting rights in a company,
            has the right to appoint or remove the majority of the board of directors or otherwise exercises significant
            influence or control.
          </Typo>
        </ModalContentBody>
        <ModalContentButtons text="OK" onClick={handleClose} />
      </ModalContent>
    )}
  </InlineHelp>
)

export default SignificantControlInlineHelp
