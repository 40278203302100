import moment from 'helpers/date.js'

import * as rules from 'app/redux/models/common/rules.js'

import { PortfolioStatsCollection } from '../Diy/PortfolioStatsCollection.js'

import { AssetList } from './AssetList.js'

import { yearlyReviewTypes } from 'constants/portfolio'

export const ManagedMixin = (superclass) =>
  /**
   * @class
   * @typedef {Object} ManagedMixin
   */
  class extends superclass {
    constructor(...args) {
      super(...args)

      this.manage_type = 'MANAGED'

      /** @type {number | null} */
      this.preset = null
      /** @type {AssetList} */
      this.goal_cash = new AssetList()

      /** @type {PortfolioStatsCollection} */
      this.stats = PortfolioStatsCollection.createFromObject({})
    }

    /**
     * @private
     * @return {string | null}
     */
    _getTitleFromPresetFromStore() {
      if (this.preset_type) {
        // unit tests fails when the import is global (circular dependencies), so it should be placed here
        /* eslint-disable-next-line @typescript-eslint/no-var-requires */
        const store = require('app/redux/store/store.js')?.default
        const state = store?.getState()
        const { presets } = state?.portfolios
        const preset = presets[this.preset_type]?.find(({ id }) => id === this.preset)

        if (preset) {
          return preset.title
        }
      }

      return null
    }

    getRules() {
      const parentRules = typeof super.getRules === 'function' ? super.getRules() : {}

      return {
        ...parentRules,
        preset: [[rules.int]],
        goal_cash: [[(assetList) => new AssetList(...(assetList || []))]],
        stats: [[(stats) => PortfolioStatsCollection.createFromObject(stats)]],
      }
    }

    /**
     * @return {string}
     */
    getTitle() {
      const defaultTitle = this._getTitleFromPresetFromStore()

      return super.getTitle?.(defaultTitle) ?? defaultTitle
    }

    /**
     * @return {string|null}
     */
    isItTimeToYearlyReview() {
      const dataConfirmed = this.data_confirmed

      if (dataConfirmed) {
        if (moment().diff(dataConfirmed, 'months') >= 18) {
          return yearlyReviewTypes.MODAL
        }

        if (moment().diff(dataConfirmed, 'months') >= 12) {
          return yearlyReviewTypes.NOTIFICATION
        }

        return yearlyReviewTypes.NULL
      }

      return yearlyReviewTypes.NULL
    }
  }
