import axios from 'helpers/ajax'

import type { AuthFactor, RecoveryCode } from '../types'

type TwoFactorSetupParams = {
  action: 'enable'
  type: 'push' | 'push_and_sms' | 'sms'
}

enum TwoFactorSetupReturnData {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  REQUESTED = 'REQUESTED',
}

const setup2fa = async ({ action, type }: TwoFactorSetupParams): Promise<TwoFactorSetupReturnData> => {
  const { data } = await axios.post(`/oauth/2fa/setup/`, { action, type })

  return data
}

type TwoFactorStatusResponse = {
  state: 'ALLOWED' | 'DENIED' | 'REQUESTED' | 'SMS_REQUIRED'
}

const request2faStatus = async ({ clientId, token }): Promise<TwoFactorStatusResponse> => {
  let params = {}

  if (clientId && token) params = { ...params, client_id: clientId, reset_token: token, action: 'reset_password' }

  const { data } = await axios.get('/oauth/2fa/status/', { params })

  return data
}

const confirmPhoneNumber = async (phone: string): Promise<void> => {
  const { data } = await axios.post(`/oauth/2fa/setup/phone_confirmation/`, { phone })

  return data
}

const generateRecoveryCode = async (): Promise<RecoveryCode> => {
  const { data } = await axios.post(`/oauth/2fa/recovery_codes/`)

  return data
}

const confirmRecoveryCode = async (id: number): Promise<RecoveryCode> => {
  const { data } = await axios.post(`/oauth/2fa/recovery_codes/${id}/confirm/`)

  return data
}

type LoginWithRecoveryCodeParams = {
  code: string
  shouldRememberBrowser: boolean
  clientId?: string
  token?: string
}

const loginWithRecoveryCode = async ({
  code,
  shouldRememberBrowser,
  clientId,
  token,
}: LoginWithRecoveryCodeParams): Promise<RecoveryCode> => {
  const params = clientId && token ? { client_id: clientId, reset_token: token, action: 'reset_password' } : {}

  const { data } = await axios.post(`/oauth/2fa/recovery_codes/auth/login/`, {
    code,
    remember: shouldRememberBrowser,
    ...params,
  })

  return data
}

type AuthFactorsResponce = {
  second_auth_factors: AuthFactor[]
}

const getAuthFactors = async (): Promise<AuthFactorsResponce> => {
  const { data } = await axios.get(`/oauth/2fa/auth_factors/`)

  return data
}

export {
  generateRecoveryCode,
  confirmRecoveryCode,
  loginWithRecoveryCode,
  type LoginWithRecoveryCodeParams,
  getAuthFactors,
  setup2fa,
  request2faStatus,
  confirmPhoneNumber,
}
