import React from 'react'
import PropTypes from 'prop-types'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'
import { useMediaQueries } from 'hooks'

import './Stats.css'

const MODE_HORIZONTAL = 'horizontal'
const MODE_VERTICAL = 'vertical'

const SIZE_SMALL = 'small'
const SIZE_BIG = 'big'

const ALIGN_LEFT = 'left'
const ALIGN_CENTER = 'center'
const ALIGN_RIGHT = 'right'

const Stats = ({ mode = MODE_HORIZONTAL, size = SIZE_SMALL, align = ALIGN_LEFT, data = [] }) => {
  const { desktop } = useMediaQueries()

  if (!desktop)
    return Object.keys(data).map((key) => {
      return (
        <Paper right={32} inline key={key}>
          <Paper bottom={8}>
            <Typography size={12} color="additional" lineHeight="small">
              <Typo>{data[key].label}</Typo>
            </Typography>
          </Paper>
          <Paper>
            <Typography lineHeight="small" color={data[key].color}>
              <Typo>{data[key].value}</Typo>
            </Typography>
          </Paper>
        </Paper>
      )
    })

  return (
    <ColumnarLayout
      className={`Stats Stats_mode-${mode} Stats_size-${size}`}
      flexDirection={mode === MODE_VERTICAL ? 'column' : null}
      mods={{
        padding: mode === MODE_HORIZONTAL ? 'small' : 'no',
      }}
      fluid
    >
      {Object.keys(data).map((key) => {
        return (
          <Column key={key} size={mode === MODE_VERTICAL ? 1 : null}>
            <Paper bottom={8}>
              <Typography size={12} color="additional" lineHeight="small">
                <Typo>{data[key].label}</Typo>
              </Typography>
            </Paper>
            <Typography lineHeight="small" color={data[key].color} data-test-id={data[key]['data-test-id']}>
              <Typo>{data[key].value}</Typo>
            </Typography>
          </Column>
        )
      })}
    </ColumnarLayout>
  )
}

Stats.propTypes = {
  size: PropTypes.oneOf([SIZE_SMALL, SIZE_BIG]),
  mode: PropTypes.oneOf([MODE_HORIZONTAL, MODE_VERTICAL]),
  align: PropTypes.oneOf([ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT]),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      color: PropTypes.string,
    }),
  ).isRequired,
}

export default Stats
