import React from 'react'

import { useMemo, useCallback } from 'hooks'

import type { SecurityList, Security } from 'app/effector/securities/models/'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { SecurityItem } from 'components/molecules/SecurityItem'
import { VirtualList } from 'components/molecules/VirtualList'

interface CollectionSecuritiesListProps {
  securities: SecurityList
  securitiesWasLoadedOnce: boolean
  isLoading: boolean
  scrollableElementRef: React.RefObject<HTMLElement>
  getSecurityDataByIndex: (index: number) => any
  getSecurityLink: (security: any) => string
  navigateToSecurity: (id: string) => void
  sendEtfClickEventtoGA: (security: Security) => void
}

const CollectionSecuritiesList = ({
  securities,
  securitiesWasLoadedOnce,
  scrollableElementRef,
  isLoading,
  getSecurityDataByIndex,
  getSecurityLink,
  navigateToSecurity,
  sendEtfClickEventtoGA,
}: CollectionSecuritiesListProps): React.ReactElement => {
  const renderSecurity = useCallback(
    ({ index, measure, registerChild }) => {
      const { security, isAddedToPortfolio } = getSecurityDataByIndex(index)

      return (
        <Paper ref={registerChild} top={24} data-test-id="securityItem">
          <SecurityItem
            {...security}
            added={isAddedToPortfolio}
            href={getSecurityLink(security?.id)}
            onClick={() => {
              sendEtfClickEventtoGA(security)
              navigateToSecurity(security?.id)
            }}
            onSettled={measure}
            isLoading={isLoading}
            data-test-id={`securityItem${index as number}`}
          />
        </Paper>
      )
    },
    [getSecurityDataByIndex, navigateToSecurity, sendEtfClickEventtoGA, getSecurityLink, isLoading],
  )

  const renderNoSecurities = useCallback(
    () =>
      securitiesWasLoadedOnce ? (
        <Paper top={40}>
          <Typography size={24} lineHeight="medium" color="minor" data-test-id="noSearchResults">
            <Typo multiline>{`No ETFs found.\n Please try a different search and/or change ETF filters.`}</Typo>
          </Typography>
        </Paper>
      ) : null,
    [securitiesWasLoadedOnce],
  )

  const securitiesList = useMemo(
    () => (
      <VirtualList
        scrollableElement={scrollableElementRef.current}
        cacheKey="security-universe-list-desktop"
        items={securities}
        itemMaxHeight={156}
        itemMinHeight={104}
        renderItem={renderSecurity}
        renderNoItems={renderNoSecurities}
      />
    ),
    [securities, renderSecurity, renderNoSecurities, scrollableElementRef],
  )

  return securitiesList
}

export { CollectionSecuritiesList }
