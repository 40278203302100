import { combine, createStore, createEffect, createEvent, sample } from 'effector'

import moment from 'helpers/date.js'

import { makeApiCall } from 'app/effector/api'
import { createStateMachine } from 'app/effector/stateMachine'

import * as api from './api'
import { ScreenState, Event, states } from './states'

enum ReviewType {
  TRUSTPILOT = 'TRUSTPILOT',
  ZENDESK = 'ZENDESK',
}

interface Review {
  last_seen: Date | null
  left_review: boolean
  trustpilot_link: string
  zendesk_link: string
  isModalOpen?: boolean
}

// Utils
const isDateMoreThan30DaysAgo = (date: Date | null): boolean => {
  if (!date) return true

  const thirtyDaysAgo = moment(new Date()).subtract(30, 'days').startOf('day')

  return !moment(date).isAfter(thirtyDaysAgo)
}

// Events
const openReviewModal = createEvent()
const openTrustpilotLink = createEvent()
const openZendeskLink = createEvent()

// Effects
const fetchReviewStatusFx = createEffect(async () => {
  try {
    const review = await makeApiCall(api.getMarketingReviewStatus)

    return review
  } catch (error) {}
})

const updateLastSeenDateFx = createEffect(async ({ date }: { date: Date }) => {
  try {
    const review = await makeApiCall(api.setMarketingReviewLastSeenDate, { date })

    return review
  } catch (error) {}
})

const setLeftReviewFx = createEffect(async ({ reviewType }: { reviewType: ReviewType }) => {
  try {
    const review = await makeApiCall(api.setLeftReview, { reviewType })

    return review
  } catch (error) {}
})

// Stores
const $review = createStore<Review>({
  last_seen: null,
  left_review: false,
  trustpilot_link: '',
  zendesk_link: '',
})

$review.on(fetchReviewStatusFx.doneData, (state, review) => {
  return review ?? state
})
$review.on(updateLastSeenDateFx.done, (state, { result, params }) => {
  return result ? { ...state, last_seen: params.date } : state
})
$review.on(setLeftReviewFx.done, (state, { result }) => {
  return result ? { ...state, left_review: true } : state
})
$review.on(openTrustpilotLink, (state) => {
  window.open(state.trustpilot_link, '_blank')
  return state
})

$review.on(openZendeskLink, (state) => {
  window.open(state.zendesk_link, '_blank')
  return state
})

const $isReviewStatusFetched = createStore<boolean>(false)

$isReviewStatusFetched.on(fetchReviewStatusFx.done, () => true)

// State Machine
const { $currentState, sendEvent } = createStateMachine<ScreenState, Event>({
  id: 'review',
  initial: ScreenState.CLOSED,
  states,
  actions: {
    openTrustpilotLink: () => {
      openTrustpilotLink()
    },
    openZendeskLink: () => {
      openZendeskLink()
    },
    updateLastSeenDate: () => {
      updateLastSeenDateFx({ date: new Date() })
    },
  },
})

sample({
  clock: openReviewModal,
  source: $review,
  filter: (reviewState) => !reviewState.left_review && isDateMoreThan30DaysAgo(reviewState.last_seen),
  fn: () => Event.SHOW_MODAL,
  target: sendEvent,
})

const $reviewStore = combine({
  isReviewStatusFetched: $isReviewStatusFetched,
  trustPilotLink: $review.map((review) => review.trustpilot_link),
  zendeskLink: $review.map((review) => review.zendesk_link),
  screen: $currentState.map((state) => state),
})

export {
  $review,
  $reviewStore,
  fetchReviewStatusFx,
  updateLastSeenDateFx,
  setLeftReviewFx,
  openReviewModal,
  sendEvent,
  type Review,
  Event,
  ScreenState,
  ReviewType,
}
