import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useRef, useEffect } from 'hooks'

import { format } from 'helpers/money'

import { regulatoryTypes as portfolioRegulatoryTypes } from 'constants/goal'

import Validate from 'components/_old/Validate/Validate.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Paper } from 'components/atoms/Paper'
import Headline from 'components/_old/Headline/Headline.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import Label, { LabelText } from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import { AutoinvestInfo } from 'app/pages/Dashboard/Goals/AddFunds/components/AutoinvestInfo'

import { manageTypes } from 'constants/portfolio'

const FundsAmount = ({
  portfolio,
  amount,
  validation,
  isIsaLimitReached,
  sippTotalAllowance,
  isaRemainingAllowance,
  onChange: handleChange,
}) => {
  const { desktop } = useMediaQueries()
  const input = useRef()

  const isDiyPortfolio = portfolio.manage_type === manageTypes.DIY
  const currentLocation = window.location.pathname + window.location.search

  useEffect(() => {
    const inputElement = input?.current?.refs?.moneyInput?.refs?.input

    if (inputElement) {
      inputElement.focus()
    }
  }, [input])

  return (
    <Validate rules={validation.amount.rules}>
      {(isValid, brokenRule, handleValidateFocus, handleBlur) => (
        <Label>
          <LabelText valid={isValid}>
            <Typography
              color={isValid ? 'default' : 'error'}
              size={16}
              weight="semibold"
              lineHeight="small"
              align="center"
            >
              <Typo>{isValid ? 'How much cash do you want to add?' : validation.amount.errors[brokenRule]}</Typo>
            </Typography>
          </LabelText>
          <Paper top={20}>
            <Headline mods={{ 'no-margin': 'top bottom' }}>
              <Typography size={32} align="center">
                <AllCenter>
                  <Input
                    ref={input}
                    type="money"
                    size={10}
                    mods={{ size: 'unpadded', color: 'blue_to_white' }}
                    min={0}
                    valid={isValid}
                    withFloat
                    onChange={handleChange}
                    onFocus={handleValidateFocus}
                    onBlur={handleBlur}
                    data-test-id="addFundsAmountInput"
                  >
                    {amount}
                  </Input>
                </AllCenter>
              </Typography>
            </Headline>
          </Paper>
          {portfolio.regulatory_type === portfolioRegulatoryTypes.ISA && (
            <Fragment>
              <Paper top={20} data-test-id="wireTransferAmountLimit">
                <Typography size={14} color={isIsaLimitReached ? 'error' : 'default'} align="center">
                  <Typo>You can add the maximum of {portfolio.isa && format(isaRemainingAllowance, true)}</Typo>
                </Typography>
              </Paper>
              {isIsaLimitReached && (
                <Paper top={12}>
                  <Typography size={14} align="center">
                    <Typo>
                      Adding these funds would oversubscribe your ISA account. Yearly limit is{' '}
                      {portfolio.isa && format(portfolio.isa?.current_year_allowance, true)}
                    </Typo>
                  </Typography>
                </Paper>
              )}
            </Fragment>
          )}
          {portfolio.regulatory_type === portfolioRegulatoryTypes.SIPP && (
            <Paper top={desktop ? 24 : 16}>
              <Typography size={14} align="center">
                <Typo>Personal Pension annual allowance is {format(sippTotalAllowance, true)}</Typo>
              </Typography>
            </Paper>
          )}
          {isDiyPortfolio && (
            <Paper top={24}>
              <AutoinvestInfo portfolio={portfolio} back={currentLocation} />
            </Paper>
          )}
          <Paper top={desktop ? 48 : 24}>
            <Typography size={14} color="minor" align="center">
              <Typo>
                Instant bank transfers are subject to payment limits and secure open banking authorisation. These can
                vary between banks.
              </Typo>
            </Typography>
          </Paper>
        </Label>
      )}
    </Validate>
  )
}

FundsAmount.propTypes = {
  portfolio: PropTypes.object.isRequired,
  amount: PropTypes.string.isRequired,
  validation: PropTypes.object.isRequired,
  isIsaLimitReached: PropTypes.bool.isRequired,
  sippRemainingAllowance: PropTypes.number,
  isaRemainingAllowance: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

export { FundsAmount }
