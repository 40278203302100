import React from 'react'
import PropTypes from 'prop-types'
import CSSTransitionGroup from 'react-addons-css-transition-group'

import { palette, palettePlainValues } from 'helpers/palette'

import { useContext, useState, useEffect, useTimeoutFn } from 'hooks'
import { useTrackBlinkingToSentry } from './hooks/'

import Modal from 'components/_old/Modal/Modal.jsx'
import { Preloader } from 'components/molecules/Preloader'
import Icon from 'components/_old/Icon/Icon.jsx'
import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import * as GLOBAL_PRELOADER_STATUSES from 'constants/globalPreloaderStatuses.js'

import './GlobalPreloader.css'

const {
  GP_DEFAULT,
  GP_WORKING_FAKE,
  GP_VERIFYING_IDENTITY,
  GP_VERIFY_IDENTITY_SUCCESS,
  GP_VERIFYING_BANK_ACCOUNT,
  GP_VERIFY_BANK_ACCOUNT_SUCCESS,
} = GLOBAL_PRELOADER_STATUSES

const GlobalPreloaderContext = React.createContext(false)

const GlobalPreloaderProvider = React.memo(({ children, loading = false } = {}) => (
  <GlobalPreloaderContext.Provider value={loading}>{children}</GlobalPreloaderContext.Provider>
))
GlobalPreloaderProvider.displayName = 'GlobalPreloaderProvider'
GlobalPreloaderProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  loading: PropTypes.bool,
}

const GlobalPreloader = React.memo(({ loading: propLoading, status = GP_DEFAULT, logToSentry } = {}) => {
  const loading = propLoading || useContext(GlobalPreloaderContext)

  const [loaded, setLoaded] = useState(!loading)
  const [isDone, cancel, reset] = useTimeoutFn(() => setLoaded(true), 250)

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    if (loading) {
      cancel()
      setLoaded(false)
    } else {
      !isDone() && reset()
    }
  }, [loading])

  useTrackBlinkingToSentry({ logToSentry, loading: !loaded })

  if (loaded) {
    return null
  }

  const { text, icon, iconColor } = {
    [GP_DEFAULT]: {},
    [GP_WORKING_FAKE]: {
      text: 'The InvestEngine is working',
    },
    [GP_VERIFYING_IDENTITY]: {
      text: 'Verifying identity',
    },
    [GP_VERIFY_IDENTITY_SUCCESS]: {
      text: 'Identity has been verified',
      icon: 'checkmark_big_green',
      iconColor: palette['secondary-default'],
    },
    [GP_VERIFYING_BANK_ACCOUNT]: {
      text: 'Verifying your bank account details',
    },
    [GP_VERIFY_BANK_ACCOUNT_SUCCESS]: {
      text: 'Your bank account details have been verified',
      icon: 'checkmark_big_green',
      iconColor: palette['secondary-default'],
    },
  }[status]

  return (
    <Modal className="Global-Preloader" open={loading} frameless persist woGateway data={{ 'global-preloader': true }}>
      <CSSTransitionGroup
        transitionEnter
        transitionLeave
        transitionEnterTimeout={palettePlainValues.animation.speed.number.slow}
        transitionLeaveTimeout={palettePlainValues.animation.speed.number.zero}
        transitionName={{
          enter: 'GlobalPreloader-Text_enter',
          enterActive: 'GlobalPreloader-Text_enter_active',
          leave: 'GlobalPreloader-Text_leave',
          leaveActive: 'GlobalPreloader-Text_leave_active',
        }}
      >
        {text && (
          <div className="GlobalPreloader-TextHolder" key={text} data-test-id="globalPreloaderText">
            <div className="GlobalPreloader-Text">{text}</div>
          </div>
        )}
        {icon ? (
          <div className="GlobalPreloader-Icon" data-test-id={`globalPreloaderIcon-${icon}`} key={icon}>
            <AllCenter>
              <Icon type={icon} color={iconColor} />
            </AllCenter>
          </div>
        ) : (
          <Preloader size="big" key="loading" loading />
        )}
      </CSSTransitionGroup>
    </Modal>
  )
})

GlobalPreloader.propTypes = {
  loading: PropTypes.bool,
  status: PropTypes.oneOf(Object.values(GLOBAL_PRELOADER_STATUSES)),
  logToSentry: PropTypes.bool,
}

export { GlobalPreloaderContext, GlobalPreloaderProvider }
export default GlobalPreloader
