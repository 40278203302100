import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

/**
 * @class
 * @typedef {Object} AssetItem
 */
export class AssetItem extends Entity {
  constructor() {
    super()

    /** @type {?number} */
    this.id = null
    /** @type {?number} */
    this.amount = null
    /** @type {?string} */
    this.isin = null
    /** @type {?string} */
    this.title = null
    /** @type {?string} */
    this.value = null
    /** @type {?number} */
    this.change = null
    /** @type {?string} */
    this.description = null
    /** @type {?string} */
    this.modified = null
    /** @type {?string} */
    this.price_date = null
    /** @type {?number} */
    this.quantity = null
    /** @type {?number} */
    this.total_value = null
  }

  /** @override */
  getRules() {
    return {
      id: [[rules.int]],
      amount: [[rules.float]],
      isin: [[rules.string]],
      title: [[rules.string]],
      value: [[rules.float]],
      change: [[rules.float]],
      description: [[rules.string]],
      modified: [[rules.iso8601UTC]],
      price_date: [[rules.iso8601UTC]],
      quantity: [[rules.float]],
      total_value: [[rules.float]],
    }
  }
}
