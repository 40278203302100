import React, { Fragment } from 'react'

import classNames from 'classnames/dedupe'
import flatten from 'lodash/flatten'

import { useCallback, useState } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'

import { Paper, type PaperSizes } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'

import './FilterGroup.css'

type FilterGroupBaseProps = {
  children: React.ReactNode | React.ReactNodeArray
  className?: string
  wrap?: boolean
  space?: (typeof PaperSizes)[number]
  fitContent?: boolean
}

type FilterGroupWithMoreProps = {
  className?: string
  space?: (typeof PaperSizes)[number]
  children: React.ReactNodeArray
  more: number
  moreShown: boolean
}

type FilterGroupProps = XOR<FilterGroupBaseProps, FilterGroupWithMoreProps>

function FilterGroupWithMore({
  children,
  className,
  more,
  moreShown: moreShownRaw,
  space = 8,
}: FilterGroupWithMoreProps): React.ReactElement {
  const [moreShown, setMoreShown] = useState(moreShownRaw)
  const toggleMore = useCallback(() => {
    setMoreShown(!moreShown)
  }, [moreShown])
  const flattenChildren = Array.isArray(children[0]) ? flatten(children) : children
  const before = flattenChildren.slice(0, more)
  const after = flattenChildren.slice(more, flattenChildren.length)
  const gotAfter = after.length > 0
  const moreButtonToggle = (
    <Button
      className="FilterGroup-MoreButton"
      mods={{ theme: 'simple-reverse-content-on-background', size: 'small', text: 'smaller' }}
      onClick={toggleMore}
      data-test-id="moreFiltersButton"
    >
      {moreShown ? 'Less' : 'More'}
    </Button>
  )

  return (
    <Fragment>
      <Stack className={className} horizontal={space}>
        {before}
        {gotAfter && !moreShown && moreButtonToggle}
      </Stack>
      {gotAfter && moreShown && (
        <Paper top={space}>
          <Stack className={className} horizontal={space}>
            {after}
            {moreButtonToggle}
          </Stack>
        </Paper>
      )}
    </Fragment>
  )
}

function FilterGroup(props: FilterGroupProps): React.ReactElement {
  const { children, className, fitContent, wrap, more, space = 8 } = props
  const classes = classNames(className, 'FilterGroup', { FilterGroup_wrap: wrap, FilterGroup_fitContent: fitContent })

  if (more && Array.isArray(children)) {
    return <FilterGroupWithMore {...props} className={classes} />
  }

  return (
    <Stack className={classes} horizontal={space}>
      {children}
    </Stack>
  )
}

export { FilterGroup, type FilterGroupProps }
