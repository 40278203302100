import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import compose from 'helpers/compose.js'
import { combineErrors, backendErrorsToObj, bulkValidate } from 'helpers/validation.js'
import { trackEvent } from 'helpers/analytics'

import { useMediaQueries } from 'hooks'
import { useUnit } from 'effector-react'

import { types as clientTypes } from 'constants/client'

import Card from 'components/_old/Card/Card.jsx'
import Width from 'components/_old/Width/Width'
import Headline from 'components/_old/Headline/Headline.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Text from 'components/_old/Text/Text.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Form from 'components/_old/Form/Form.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label, { LabelField } from 'components/_old/Label/Label.jsx'
import CleanInputOnFocus from 'components/atoms/CleanInputOnFocus/CleanInputOnFocus.jsx'
import Input from 'components/_old/Input/Input.jsx'
import FileUploadField from 'components/molecules/FileUploadField/FileUploadField.jsx'
import Button from 'components/_old/Button/Button.jsx'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $companyStore, changeCompanyField } from 'app/effector/company'

const isPartiallyHidden = (str) => /\*/.test(str)

const BankAccountForm = ({
  wrapSubmitButton,
  onPaymentDataFieldChange: handlePaymentDataFieldChange,
  onSubmit: parentHandleSubmit,
}) => {
  const { company } = useUnit($companyStore)
  const { nominatedAccount } = useUnit($bankAccountsStore)
  const { sort_code, account_number } = nominatedAccount ?? {}
  const [alreadyFilled, setAlreadyFilled] = useState(
    sort_code !== null && account_number !== null && isPartiallyHidden(sort_code),
  )
  const { desktop } = useMediaQueries()

  useEffect(() => {
    setAlreadyFilled(sort_code !== null && account_number !== null && isPartiallyHidden(sort_code))
  }, [sort_code, account_number])

  const handleSubmit = useCallback(() => {
    parentHandleSubmit({ skipUpdate: alreadyFilled, nominatedAccount })
  }, [nominatedAccount, parentHandleSubmit, alreadyFilled])

  const handleSortCodeChange = useCallback(
    (_event, value = null) => {
      if (value) {
        value = value.replace(/_/g, '')
      }

      handlePaymentDataFieldChange('sort_code', value)

      if (isPartiallyHidden(account_number)) {
        handlePaymentDataFieldChange('account_number', null)
        setAlreadyFilled(false)
      }
    },
    [account_number, handlePaymentDataFieldChange],
  )

  const handleAccountNumberChange = useCallback(
    (_event, value = null) => {
      handlePaymentDataFieldChange('account_number', value)

      if (isPartiallyHidden(sort_code)) {
        handlePaymentDataFieldChange('sort_code', null)
        setAlreadyFilled(false)
      }
    },
    [sort_code, handlePaymentDataFieldChange],
  )

  const handleCompanyFieldChange = useCallback((field, value) => {
    changeCompanyField({ [field]: value })
  }, [])

  const validation = combineErrors(
    {
      sort_code: {
        rules: [sort_code, (sort_code || '').replace(/[_-]/g, '').length === 6],
        errors: ['Sort code can’t be empty', 'Must be 6 characters long'],
      },
      account_number: {
        rules: [account_number, account_number?.length === 8],
        errors: ['Account number can’t be empty', 'Must be 8 characters long'],
      },
      bank_statement_filled: {
        rules: [company.bank_statement_filled],
        errors: [''],
      },
    },
    backendErrorsToObj(company.error),
  )

  const inputMods = { size: 'bigger' }

  const sortCodeInput = (
    <Validate rules={validation.sort_code.rules}>
      <Label errorMessages={validation.sort_code.errors} data-test-id="bankAccountSortCodeLabel">
        Sort code
        <LabelField>
          <CleanInputOnFocus onSetValue={handleSortCodeChange} cleanIfMatch={/\*/}>
            <Input
              name="sort-code"
              autoComplete="off"
              type="masked"
              subtype="tel"
              mask="66-66-66"
              placeholder="__-__-__"
              tabIndex={2}
              mods={inputMods}
              onChange={handleSortCodeChange}
              formatChars={{
                6: '[0-9*]',
              }}
              data-test-id="bankAccountSortCodeInput"
            >
              {sort_code}
            </Input>
          </CleanInputOnFocus>
        </LabelField>
      </Label>
    </Validate>
  )

  const preparedAccountNumber = `${account_number || ''}`.substring(0, 19)

  const accountNumberInput = (
    <Validate rules={validation.account_number.rules}>
      <Label errorMessages={validation.account_number.errors} data-test-id="bankAccountAccountNumberLabel">
        Account number
        <LabelField>
          <CleanInputOnFocus onSetValue={handleAccountNumberChange} cleanIfMatch={/\*/}>
            <Input
              name="account-number"
              autoComplete="off"
              type="number"
              tabIndex={3}
              mods={inputMods}
              onChange={handleAccountNumberChange}
              data-test-id="bankAccountAccountNumberInput"
              maxlength={8}
            >
              {preparedAccountNumber}
            </Input>
          </CleanInputOnFocus>
        </LabelField>
      </Label>
    </Validate>
  )

  const submitDisabled = !bulkValidate(validation) && !alreadyFilled

  const submitButton = (
    <Button
      type="submit"
      mods={{ size: 'big block' }}
      onClick={handleSubmit}
      disabled={submitDisabled}
      tabIndex={6}
      data-test-id="bankAccountSubmitButton"
    >
      Continue
    </Button>
  )

  const formContent = (
    <Width size={desktop ? 24 : null}>
      <ColumnarLayout mods={{ padding: 'small' }}>
        <Column size={3}>{sortCodeInput}</Column>
        <Column size={4}>{accountNumberInput}</Column>
      </ColumnarLayout>
      <Card
        mods={{
          theme: 'edge-to-edge transparent',
          'no-padding': desktop ? 'right left' : null,
        }}
      >
        <Text smaller block>
          <Typo>
            Please enter a UK bank account in your company name. All withdrawals will be paid to this account.
          </Typo>
        </Text>
      </Card>
      {(() => {
        const field = (
          <FileUploadField
            model={company}
            modelKey="bank_statement"
            url="client/company/files/"
            method="POST"
            type="bill"
            heading={
              <Text center={desktop} block>
                <Headline className={desktop ? null : 'Text_color_blue'} level={3} mods={{ 'no-margin': 'top' }}>
                  <Typo>Your company bank statement</Typo>
                </Headline>
              </Text>
            }
            text={
              <Text center={desktop} block small>
                <Typo>Please upload a bank statement no older than 3 months</Typo>
              </Text>
            }
            tabIndex={4}
            onAfterUpload={() => {
              handleCompanyFieldChange('bank_statement_filled', true)

              trackEvent({
                action: 'nominated_bank_statement_uploaded',
                client_type: clientTypes.BUSINESS,
              })
            }}
            data-test-id="bankAccountFileUpload"
          />
        )

        if (desktop) {
          return field
        }

        return <Card>{field}</Card>
      })()}
      {wrapSubmitButton(submitButton)}
    </Width>
  )

  return (
    <SubmitOnEnter>
      <Form>
        {desktop ? (
          <Card
            mods={{
              theme: !desktop ? 'edge-to-edge transparent' : null,
              'no-padding': desktop ? null : 'top',
            }}
          >
            {formContent}
          </Card>
        ) : (
          formContent
        )}
      </Form>
    </SubmitOnEnter>
  )
}

BankAccountForm.propTypes = {
  wrapSubmitButton: PropTypes.func,

  onPaymentDataFieldChange: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default compose(
  connect((state) => {
    const { client } = state

    return { client }
  }),
)(BankAccountForm)
