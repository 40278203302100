import React from 'react'
import classNames from 'classnames/dedupe'

import { formatPercent } from 'helpers/money'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Text from 'components/_old/Text/Text.jsx'

import './AssetOneliner.css'

export default function AssetOneliner(props) {
  const { color, amount, name, 'data-test-id': dataTestId } = props

  return (
    <div className="AssetOneliner" data-test-id={dataTestId}>
      <Text bold className="AssetOneliner-Amount" style={{ color: color }}>
        {formatPercent(amount / 100, 1)}
      </Text>
      <span className="AssetOneliner-Name">{name}</span>
    </div>
  )
}

export function AssetOnelinerGroup(props) {
  const { className, children, right } = props

  return (
    <ColumnarLayout
      className={classNames('AssetOnelinerGroup', className, { AssetOnelinerGroup_right: right })}
      mods={{ padding: 'nano' }}
      flexible
    >
      {children.map((child, i) => {
        const ta = i === 0 ? 'left' : i === children.length - 1 ? 'right' : 'center'

        return (
          child && (
            <Column key={i} style={{ textAlign: ta }}>
              {child}
            </Column>
          )
        )
      })}
    </ColumnarLayout>
  )
}
