import { findDOMNode } from 'react-dom'

import { useCallback, useEffect } from 'hooks'

const useSubmitOnEnter = (ref) => {
  const createHandleKeypress = useCallback(
    (ref) => (event) => {
      if (event.keyCode === 13) {
        // eslint-disable-next-line react/no-find-dom-node
        const wrapperNode = findDOMNode(ref ?? this)
        const eventCallerNode = event.target

        if (wrapperNode && wrapperNode?.contains(eventCallerNode)) {
          const submitNode = wrapperNode.querySelector('[type="submit"]')

          if (submitNode) {
            submitNode.click()
          }
        }
      }
    },
    [],
  )

  useEffect(() => {
    const handleKeypress = createHandleKeypress(ref)

    window.addEventListener('keypress', handleKeypress)

    return () => {
      window.removeEventListener('keypress', handleKeypress)
    }
  }, [createHandleKeypress, ref])
}

export default useSubmitOnEnter
