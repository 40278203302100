import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { urlTo } from 'helpers/router.js'
import { useMemo } from 'hooks'

import { DesktopLayout } from 'components/atoms/Layouts'
import Width from 'components/_old/Width/Width'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { ControlledInput } from 'components/atoms/Forms/ControlledInput'
import Label from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Icon from 'components/_old/Icon/Icon.jsx'

import { TunnelHeader } from 'app/pages/Securities/components'
import { BuySellTabs } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/components'
import { TradingSuspendedWarning } from 'components/organisms/TradingSuspendedWarning/TradingSuspendedWarning.tsx'
import { NoTradingSecurity } from '../components/NoTradingSecurity'
import { NoTradingSecuritiesWarning } from '../components/NoTradingSecuritiesWarning'
import { PortfolioCard } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/Security/components/PortfolioCard/PortfolioCard'

const BuyDesktop = ({
  portfolioId,
  isTradingSuspended,
  noTradingSecuritiesWithNonZeroWeights,

  isValid,
  validation,
  errors,
  control,

  handleBack,
  handleAddFunds,
  handleSell,

  chosenPortfolio,
  openCashSelectModal,
  isIsaLimitWarningDisplayed,
  isBusiness,
}) => {
  const header = (
    <Width size={36.75} center>
      <TunnelHeader onBack={handleBack} onCancel={handleBack} />
    </Width>
  )

  const tabs = <BuySellTabs activeTab="buy" handleSell={handleSell} />

  const selectedPortfolio = (
    <Fragment>
      <Paper bottom={12}>
        <Typography size={14} color="additional">
          <Typo>Available cash</Typo>
        </Typography>
      </Paper>
      <PortfolioCard portfolio={chosenPortfolio} onClick={openCashSelectModal} isBusiness={isBusiness} />
    </Fragment>
  )

  const addCash = (
    <Link onClick={handleAddFunds}>
      <Typo>+ Add cash</Typo>
    </Link>
  )

  const textField = (
    <Paper top={64}>
      <ControlledInput name="amount" control={control} errors={errors} rules={validation}>
        {({ value, valid, errorMessage, handleChange, handleFocus, handleBlur }) => (
          <Fragment>
            <Width size={17.5} center>
              <Label valid={valid} errorMessage={errorMessage} data-test-id="buySumField">
                <Input
                  name="value"
                  type="money"
                  placeholder="£"
                  mods={{ size: 'big' }}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  withFloat
                >
                  {value}
                </Input>
              </Label>
            </Width>
            {!valid && (
              <Paper top={16}>
                <Typography size={16} color="error" align="center">
                  {errorMessage}
                </Typography>
              </Paper>
            )}
            <Paper top={valid ? 56 : 16} bottom={12}>
              {selectedPortfolio}
            </Paper>
            {portfolioId === chosenPortfolio?.id && (
              <Fragment>
                {addCash}
                <Paper top={40}>
                  <Typography align="center">
                    <Link
                      to={urlTo('dashboard.portfolio.options.cash-breakdown', { id: portfolioId })}
                      data-test-id="showCashBreakDownLink"
                    >
                      Show cash breakdown
                    </Link>
                  </Typography>
                </Paper>
              </Fragment>
            )}
          </Fragment>
        )}
      </ControlledInput>
    </Paper>
  )

  const isaLimitWarning = isIsaLimitWarningDisplayed && (
    <Paper top={32}>
      <ItemWithIcon
        space={12}
        icon={<Icon size={24} type="information-24" color="inherit" />}
        content={
          <Typography size={14}>
            <Typo>Transferring cash from a non-ISA to an ISA is subject to the annual ISA subscription limit</Typo>
          </Typography>
        }
      />
    </Paper>
  )

  const button = (
    <Paper top={40}>
      <Button type="submit" mods={{ size: 'big block' }} disabled={!isValid} data-test-id="reviewOrderButton">
        Review order
      </Button>
    </Paper>
  )

  const innerContent = useMemo(() => {
    if (isTradingSuspended) {
      return <TradingSuspendedWarning />
    }

    if (noTradingSecuritiesWithNonZeroWeights.length > 0) {
      return (
        <Fragment>
          <Paper top={64} bottom={40}>
            <Typography size={32} weight="semibold" align="center" lineHeight="small">
              <Typo>These securities are not currently available to trade</Typo>
            </Typography>
          </Paper>
          {noTradingSecuritiesWithNonZeroWeights.map(([security]) => (
            <Paper key={security.id} top={24}>
              <NoTradingSecurity title={security.title} logo={security.logo_uri} />
            </Paper>
          ))}
          <Paper top={64} bottom={80}>
            <NoTradingSecuritiesWarning />
          </Paper>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <Paper top={64}>
          <Typography size={32} weight="semibold" align="center" lineHeight="small">
            <Typo>Buy</Typo>
          </Typography>
        </Paper>
        <Paper top={16}>
          <Width size={26} center>
            <Typography size={16} align="center" color="additional">
              <Typo>We'll buy based on your portfolio's target weights</Typo>
            </Typography>
          </Width>
        </Paper>
        {textField}
        {isaLimitWarning}
        <Paper bottom={40}>{button}</Paper>
      </Fragment>
    )
  }, [isTradingSuspended, noTradingSecuritiesWithNonZeroWeights, textField, button])

  const content = (
    <Width size={36.75} center>
      <Paper top={12}>{tabs}</Paper>
      {innerContent}
    </Width>
  )

  return <DesktopLayout header={header} content={content} />
}

BuyDesktop.propTypes = {
  isTradingSuspended: PropTypes.bool,
  noTradingSecuritiesWithNonZeroWeights: PropTypes.array,

  isValid: PropTypes.bool,
  validation: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,

  handleBack: PropTypes.func,
  handleAddFunds: PropTypes.func,
  handleSell: PropTypes.func,
}

export { BuyDesktop }
