import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

/**
 * @return {Promise<Array.<Object>|ApiError>}
 */
export const getFeatures = async () => {
  try {
    const { data } = await axios.get('features/')

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
