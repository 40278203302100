/* eslint-disable react/prop-types */

import React, { useContext, useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames/dedupe'
import isFunction from 'lodash/isFunction'
import { Gateway, GatewayDest } from 'components/atoms/Gateway'
import CSSTransitionGroup from 'react-addons-css-transition-group'

import { palettePlainValues } from 'helpers/palette'
import compose from 'helpers/compose.js'
import { trackEvent } from 'helpers/analytics'
import rawMediaQueries from 'helpers/mediaQueries.js'

import { useMediaQueries } from 'hooks'
import { useAppHeight } from 'app/containers/AppHeight'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { GlobalPreloaderContext } from 'components/_old/GlobalPreloader/GlobalPreloader.jsx'

import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'

import './Modal.css'

const Modal = ({
  className,
  children,
  open,
  mediaQueries,
  frameless,
  noCross,
  transparent,
  persist,
  gatewayName = 'modals',
  insideGatewayName = 'insideModal',
  woGateway,
  data,
  preventScrollTopOnUpdate,
  beforeHeader,
  reverseAnimation,
  onClose,
  analyticsEventOnClose,
  zIndex = 1000,
  'data-test-id': dataTestId,
}) => {
  const { desktop } = useMediaQueries()
  const appHeight = useAppHeight()
  const loading = useContext(GlobalPreloaderContext)
  const withoutAnimation = loading
  const modalWindow = useRef(null)

  const handleClose = useCallback(
    (event) => {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      if (analyticsEventOnClose) {
        trackEvent(analyticsEventOnClose)
      }

      if (isFunction(onClose)) {
        onClose()
      }
    },
    [onClose, analyticsEventOnClose],
  )

  const handleWindowClick = useCallback((event) => {
    event.stopPropagation()
  }, [])

  const handleKeyPress = useCallback(
    (event) => {
      if (!open) {
        return
      }

      if (event && event.key === 'Escape') {
        handleClose()
      }
    },
    [open, handleClose],
  )

  useEffect(() => {
    const bindEvent = () => {
      if (document) {
        document.addEventListener('keydown', handleKeyPress)
      }
    }

    const unbindEvent = () => {
      if (document) {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }

    if (open) {
      bindEvent()

      if (!preventScrollTopOnUpdate) {
        setTimeout(() => {
          if (modalWindow && modalWindow.current) {
            modalWindow.current.scrollTop = 0
          }
        }, 50)
      }
    }

    if (!open) {
      unbindEvent()
    }

    return () => {
      unbindEvent()
    }
  }, [open, preventScrollTopOnUpdate, modalWindow])

  const classes = classNames(
    className,
    'Modal',
    {
      Modal_frameless: frameless,
      Modal_transparent: transparent,
      Modal_reverseAnimation: reverseAnimation,
      Modal_beforeHeader: beforeHeader,
    },
    getMediaQuieryClasses('Modal', mediaQueries),
  )

  const dataProps = data
    ? Object.keys(data)
        .map((k) => {
          return { [`data-${k}`]: data[k] }
        })
        .reduce((memo = {}, right) => {
          return {
            ...memo,
            ...(() => {
              const key = Object.keys(right)[0]
              const value = Object.keys(right).map((k) => right[k])[0]

              return { [key]: value }
            })(),
          }
        })
    : null

  const style = desktop
    ? null
    : {
        minHeight: appHeight,
        zIndex,
      }

  let modal = (
    <div className={classes} style={style} data-test-id={dataTestId} {...dataProps}>
      <div className="Modal-Backdrop" onClick={handleClose}>
        <div className="Modal-Window" ref={modalWindow} tabIndex={0} onClick={handleWindowClick}>
          {frameless || noCross ? null : (
            <Link className="Modal-Close" mods={{ color: 'gray' }} onClick={handleClose} data-test-id="modalCloser">
              <Icon type="cross-thin" color="inherit" />
            </Link>
          )}
          <Inner className="Modal-Inner">{children}</Inner>
        </div>
        {!woGateway && <GatewayDest className="Modal-Gateway" name={insideGatewayName} onClick={handleWindowClick} />}
      </div>
    </div>
  )

  modal = persist ? (
    modal
  ) : (
    <CSSTransitionGroup
      transitionName={{
        enter: 'Modal_enter',
        enterActive: 'Modal_enter_active',
        leave: 'Modal_leave',
        leaveActive: 'Modal_leave_active',
      }}
      transitionEnterTimeout={palettePlainValues.animation.speed.number.slow}
      transitionLeaveTimeout={palettePlainValues.animation.speed.number.slow}
      transitionEnter={!withoutAnimation}
      transitionLeave={!withoutAnimation}
    >
      {open && modal}
    </CSSTransitionGroup>
  )

  return woGateway ? modal : <Gateway into={gatewayName}>{modal}</Gateway>
}

export default compose(withMediaQueries(rawMediaQueries))(Modal)
