import React from 'react'
import PropTypes from 'prop-types'
import {
  combineErrors,
  bulkValidate,
  createAddressesValidationList,
  getAddressesValidationFlatObject,
} from 'helpers/validation.js'

import { emptyAddress } from 'constants/addresses.js'

import { useMediaQueries, useEffect } from 'hooks'
import { useUnit } from 'effector-react'

import Card from 'components/_old/Card/Card.jsx'
import Width from 'components/_old/Width/Width'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Form from 'components/_old/Form/Form.jsx'
import AddressesForm from 'app/pages/Forms/AddressesForm.jsx'
import Button from 'components/_old/Button/Button.jsx'

import { changeAddressField as changeContactAddressField, $owner, changeContactField } from 'app/effector/contacts'

const ResidentialAddressForm = ({ wrapSubmitButton, onSubmit: handleSubmit }) => {
  const { desktop } = useMediaQueries()
  const owner = useUnit($owner) ?? {}

  useEffect(() => {
    if ((owner.addresses || []).length < 1 && owner.id) {
      changeContactField({
        field: { addresses: [emptyAddress] },
        id: owner.id,
      })
    }
  }, [owner])

  const handleContactsFieldChange = (field, value) => changeContactField({ field: { [field]: value }, id: owner.id })

  const handleAddressFieldChange = (field, value, index) => {
    return changeContactAddressField({
      field: { [field]: value },
      addressIndex: index,
      id: owner.id,
    })
  }

  if ((owner.addresses || []).length < 1) {
    return null
  }

  const addressesValidationsList = createAddressesValidationList(owner.addresses)
  const addressesValidationFlatObject = getAddressesValidationFlatObject(addressesValidationsList)

  const validation = combineErrors(addressesValidationFlatObject)

  const submitButton = (
    <Button
      type="submit"
      mods={{ size: 'big block' }}
      onClick={handleSubmit}
      disabled={!bulkValidate(validation)}
      tabIndex={9}
      data-test-id="addressFormSubmit"
    >
      Continue
    </Button>
  )

  return (
    <SubmitOnEnter>
      <Form>
        <Card
          mods={{
            theme: !desktop ? 'edge-to-edge transparent' : null,
            'no-padding': 'all',
          }}
        >
          <Width size={desktop ? 26 : null}>
            <AddressesForm
              addresses={owner.addresses}
              validationsList={addressesValidationsList}
              onAddressFieldChange={handleAddressFieldChange}
              onAddressesChange={(addresses) => handleContactsFieldChange('addresses', addresses)}
            />
            {wrapSubmitButton(submitButton)}
          </Width>
        </Card>
      </Form>
    </SubmitOnEnter>
  )
}

ResidentialAddressForm.propTypes = {
  wrapSubmitButton: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ResidentialAddressForm
