import React, { Fragment } from 'react'

import { useState, useMediaQueries } from 'hooks'

import type { Preset } from 'app/redux/models/portfolio/types'

import { Card } from 'components/atoms/Card'
import { Gateway } from 'components/atoms/Gateway'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { FeeDescriptionModal } from './FeeDescriptionModal'
import { RiskDescriptionModal } from './RiskDescriptionModal'
import { ShortDescriptionModal } from './ShortDescriptionModal'
import { TerDescriptionModal } from './TerDescriptionModal'

import './PortfolioLabels.css'

type LabelProps = {
  children: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

const Label = ({ children, onClick }: LabelProps): React.ReactElement => {
  return (
    <Card className="PortfolioLabel" onClick={onClick}>
      <Typography inline size={14} lineHeight="small">
        <Paper top={12} bottom={12} right={12} left={12}>
          {children}
        </Paper>
      </Typography>
    </Card>
  )
}

type PortfolioLabelsProps = {
  preset: Preset
}

const PortfolioLabels = ({ preset }: PortfolioLabelsProps): React.ReactElement => {
  const { desktop } = useMediaQueries()
  const [showShortDescriptionModal, setShowShortDescriptionModal] = useState(false)
  const [showTerModal, setShowTerModal] = useState(false)
  const [showRiskModal, setShowRiskModal] = useState(false)
  const [showFeeModal, setShowFeeModal] = useState(false)

  const handleOpenTerModal = (): void => {
    setShowTerModal(true)
  }
  const handleCloseTerModal = (): void => {
    setShowTerModal(false)
  }

  const handleOpenRiskModal = (): void => {
    setShowRiskModal(true)
  }
  const handleCloseRiskModal = (): void => {
    setShowRiskModal(false)
  }

  const handleOpenFeeModal = (): void => {
    setShowFeeModal(true)
  }
  const handleCloseFeeModal = (): void => {
    setShowFeeModal(false)
  }

  const handleOpenShortDescriptionModal = (): void => {
    setShowShortDescriptionModal(true)
  }

  const handleCloseShortDescriptionModal = (): void => {
    setShowShortDescriptionModal(false)
  }

  const labels = (
    <Fragment>
      <Paper right={desktop ? 8 : 12} bottom={12} inline data-test-id="presetTitleLabel">
        <Label onClick={handleOpenShortDescriptionModal}>{preset?.title}</Label>
      </Paper>
      <Paper right={desktop ? 8 : 12} bottom={12} inline data-test-id="presetRiskLabel">
        <Label onClick={handleOpenRiskModal}>
          <Typography size={14} color="minor" lineHeight="small" inline>
            Risk
          </Typography>{' '}
          {`${preset?.risk}/7`}
        </Label>
      </Paper>
      <Paper right={desktop ? 8 : 12} bottom={12} inline data-test-id="presetFeeLabel">
        <Label onClick={handleOpenFeeModal}>
          {`${preset?.fee}% `}
          <Typography size={14} color="minor" lineHeight="small" inline>
            Fee
          </Typography>
        </Label>
      </Paper>
      <Paper right={desktop ? 8 : 12} inline data-test-id="presetTERLabel">
        <Label onClick={handleOpenTerModal}>
          {`${parseFloat(preset?.ter).toFixed(2)}% `}
          <Typography size={14} color="minor" lineHeight="small" inline>
            Portfolio TER
          </Typography>
        </Label>
      </Paper>
    </Fragment>
  )

  return (
    <Fragment>
      {desktop ? <div className="PortfolioLabels">{labels}</div> : labels}
      <Gateway into="modals">
        <TerDescriptionModal isOpen={showTerModal} onClose={handleCloseTerModal} />
      </Gateway>
      <Gateway into="modals">
        <RiskDescriptionModal isOpen={showRiskModal} onClose={handleCloseRiskModal} />
      </Gateway>
      <Gateway into="modals">
        <FeeDescriptionModal isOpen={showFeeModal} onClose={handleCloseFeeModal} preset={preset} />
      </Gateway>
      <Gateway into="modals">
        <ShortDescriptionModal
          isOpen={showShortDescriptionModal}
          onClose={handleCloseShortDescriptionModal}
          preset={preset}
        />
      </Gateway>
    </Fragment>
  )
}

export { PortfolioLabels }
