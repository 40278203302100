import React from 'react'

import { format as formatMoney } from 'helpers/money'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { DonutChart } from 'components/atoms/DonutChart'
import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

type SummaryProps = {
  isLoading: boolean
  isForSinglePortfolio: boolean
  stocks: {
    count: number
  }
  summary: {
    total: number
    stocks: {
      actual: number
    }
  }
}

const Summary = ({ isLoading, stocks, summary, isForSinglePortfolio }: SummaryProps): React.ReactElement => {
  return (
    <Stack vertical={32}>
      <div>
        <AllCenter>
          <DonutChart lineWidth={14} values={[[summary.stocks.actual, 'var(--stocks)']]} minimalSectorValue={0.65}>
            <Paper>
              <Typography size={48} weight="semibold" lineHeight="small" align="center" data-test-id="donutValue">
                <Typo>{isLoading ? '' : stocks.count}</Typo>
              </Typography>
              <Typography size={16} lineHeight="small" align="center" data-test-id="donutValueSubtitle">
                <Typo>Holdings</Typo>
              </Typography>
            </Paper>
          </DonutChart>
          <Paper top={24}>
            <Typography size={14} lineHeight="small" align="center" data-test-id="donutBalanceHeading">
              <Typo>{isForSinglePortfolio ? 'Portfolio' : 'Total'} balance</Typo>
            </Typography>
            <Typography size={14} lineHeight="small" align="center" data-test-id="donutBalanceValue">
              <Typo>{formatMoney(summary.total, true, true)}</Typo>
            </Typography>
          </Paper>
        </AllCenter>
      </div>
    </Stack>
  )
}

export { Summary }
