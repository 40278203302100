import React, { Fragment } from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries, useMemo } from 'hooks'

import { format } from 'helpers/date.js'
import { palette } from 'helpers/palette'

import { type Report as ReportModel } from 'app/effector/reports/models'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { ReportFormatIcon } from 'app/pages/Dashboard/Reports/components/ReportFormatIcon'

import './Report.css'

type ReportProps = {
  partsClassNames: {
    root: string
    createdAt: string
    title: string
    download: string
  }
  report: ReportModel
}

const Report = ({ partsClassNames, report }: ReportProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const rootClasses = useMemo(
    () =>
      classNames(partsClassNames.root, 'Report', {
        Report_desktop: desktop,
        Report_phone: !desktop,
        Report_generated: report.is_generated,
        'Button-Proxy': report.is_generated,
      }),
    [partsClassNames.root, desktop, report.is_generated],
  )
  const downloadClasses = useMemo(
    () => classNames(partsClassNames.download, 'Report-Download'),
    [partsClassNames.download],
  )

  const icon = useMemo(() => {
    if (!report.is_generated) {
      return (
        <Icon
          className="Report-Icon"
          type="time-32"
          color={palette['content-on-background-default']}
          size={32}
          fixedSize
        />
      )
    }

    return <ReportFormatIcon className="Report-Icon" format={report.format} />
  }, [report.format, report.is_generated])

  const paperProps = useMemo(() => ({ top: desktop ? 24 : 20, bottom: desktop ? 24 : 20 }), [desktop])

  const content = useMemo(
    () => (
      <Fragment>
        <Paper className={partsClassNames.createdAt} {...paperProps}>
          <Typography size={desktop ? 16 : 14} color={desktop ? 'default' : 'minor'} data-test-id="reportDate">
            <Typo>{format(report.modified)}</Typo>
          </Typography>
        </Paper>
        <Paper className={partsClassNames.title} left={desktop ? 32 : 0} {...paperProps}>
          <ItemWithIcon
            space={8}
            icon={<Paper top={desktop ? 0 : 4}>{icon}</Paper>}
            iconPosition="left"
            iconVerticalAlign={desktop ? 'center' : 'top'}
            contentVerticalAlign={desktop ? 'center' : 'top'}
            content={
              <Typography size={desktop ? 16 : 14} weight="semibold" data-test-id="reportTitle" color="inherit">
                <Typo noNbsp={!desktop}>{report.getTitle()}</Typo>
              </Typography>
            }
          />
        </Paper>
        {desktop && report.is_generated && (
          <Paper className={downloadClasses} left={desktop ? 32 : 0} {...paperProps}>
            <Button mods={{ size: 'small thin-side' }} data-test-id="reportDownloadButton">
              Download
            </Button>
          </Paper>
        )}
      </Fragment>
    ),
    [report, partsClassNames.createdAt, partsClassNames.title, desktop, downloadClasses, icon, paperProps],
  )

  if (report.is_generated) {
    return (
      <Link className={rootClasses} to={report.url} hard download mods={{ color: 'black' }} data-test-id="report">
        {content}
      </Link>
    )
  }

  return (
    <div className={rootClasses} data-test-id="report">
      {content}
    </div>
  )
}

export { Report }
