import { Entity } from 'app/redux/models/common/Entity.js'

export class StandingOrder extends Entity {
  constructor() {
    super()

    /** @type {?number} */
    this.id = null
    /** @type {?string} */
    this.state = null
    /** @type {?number} */
    this.bank_account_id = null
    /** @type {?string} */
    this.bank_account_state = null
    /** @type {?number} */
    this.goal_id = null
    /** @type {?number} */
    this.day_of_month = null
    /** @type {?string} */
    this.amount = null
  }
}
