import isEmpty from 'lodash/isEmpty'

import { useCallback, useMemo } from 'hooks'

import { type PendingOrderList } from 'app/effector/pending-orders/models'

import { useRestriction, UseRestrictionTypes } from 'app/pages/Dashboard/Goals/DIY/hooks'

import { type Action, ActionKey } from './usePortfolioActions'

import { scope as pendingOrderScope } from 'constants/pendingOrder.js'

type UsePortfolioRestrictionsProps = {
  portfoliosSecurities: unknown[]
  portfolioActions: Action[]
  portfolioOrders: PendingOrderList
  handleBuySellRaw: () => void
}

type UsePortfolioRestrictionsInterface = {
  restrictionModalOpen: boolean
  restrictionModalType: UseRestrictionTypes | null
  handleRestrictionModalClose: () => void
  handleBuySell: () => void
  portfolioActions: Action[]
}

function usePortfolioRestrictions({
  portfoliosSecurities,
  portfolioActions,
  portfolioOrders,
  handleBuySellRaw,
}: UsePortfolioRestrictionsProps): UsePortfolioRestrictionsInterface {
  const hasNoSecurities = useMemo(() => isEmpty(portfoliosSecurities), [portfoliosSecurities])
  const hasPendingOrders = useMemo(() => !isEmpty(portfolioOrders), [portfolioOrders])
  const hasPortfoliosScopedPendingOrders = useMemo(
    () => !isEmpty(portfolioOrders.getByScope(pendingOrderScope.PORTFOLIO)),
    [portfolioOrders],
  )

  const {
    open: restrictionModalOpen,
    type: restrictionModalType,
    handleClose: handleRestrictionModalClose,
    handleRestriction,
  } = useRestriction()

  const handleBuySell = useCallback(
    (...args) => {
      if (hasNoSecurities) {
        handleRestriction({
          isRestricted: true,
          type: UseRestrictionTypes.NO_SECURITIES,
          action: handleBuySellRaw.bind(this, ...args),
        })
        return
      }

      handleRestriction({
        isRestricted: hasPendingOrders,
        type: UseRestrictionTypes.PORTFOLIO,
        action: handleBuySellRaw.bind(this, ...args),
      })
    },
    [handleBuySellRaw, handleRestriction, hasNoSecurities, hasPendingOrders],
  )

  const restrictedPortfolioActions = useMemo(
    () =>
      portfolioActions.map((action) => {
        if (action.key === ActionKey.EDIT_WEIGHTS) {
          return {
            ...action,
            onClick: (...args) => {
              handleRestriction({
                isRestricted: hasPortfoliosScopedPendingOrders,
                type: UseRestrictionTypes.EDIT,
                action: action.onClick.bind(this, ...args),
              })
            },
          }
        }

        if (action.key === ActionKey.REBALANCE) {
          if (hasNoSecurities) {
            return {
              ...action,
              onClick: (...args) => {
                handleRestriction({
                  isRestricted: true,
                  type: UseRestrictionTypes.NO_SECURITIES,
                  action: action.onClick.bind(this, ...args),
                })
              },
            }
          }

          return {
            ...action,
            onClick: (...args) => {
              handleRestriction({
                isRestricted: hasPendingOrders,
                type: UseRestrictionTypes.PORTFOLIO,
                action: action.onClick.bind(this, ...args),
              })
            },
          }
        }

        if (action.key === ActionKey.SELL_PORTFOLIO) {
          if (hasNoSecurities) {
            return {
              ...action,
              onClick: (...args) => {
                handleRestriction({
                  isRestricted: true,
                  type: UseRestrictionTypes.NO_SECURITIES,
                  action: action.onClick.bind(this, ...args),
                })
              },
            }
          }

          return {
            ...action,
            onClick: (...args) => {
              handleRestriction({
                isRestricted: hasPendingOrders,
                type: UseRestrictionTypes.PORTFOLIO,
                action: action.onClick.bind(this, ...args),
              })
            },
          }
        }

        return action
      }),
    [portfolioActions, handleRestriction, hasNoSecurities, hasPortfoliosScopedPendingOrders, hasPendingOrders],
  )

  return {
    restrictionModalOpen,
    restrictionModalType,
    handleRestrictionModalClose,
    handleBuySell,
    portfolioActions: restrictedPortfolioActions,
  }
}

export { usePortfolioRestrictions }
