import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useUnit } from 'effector-react'

import { MIN_SINGLE_PAYMENT_AMOUNT, MAX_SINGLE_PAYMENT_AMOUNT } from 'constants/validations'

import compose from 'helpers/compose.js'
import { trackEvent } from 'helpers/analytics'
import { format as formatMoney } from 'helpers/money'
import { bulkValidate } from 'helpers/validation.js'
import { goTo, urlTo } from 'helpers/router.js'
import { sendError } from 'helpers/errorLogging.js'

import { useCallback, useEffect, useMediaQueries, useMemo, useSelector } from 'hooks'

import { OptionsLayout } from 'app/pages/Dashboard/Goals/Options/OptionsLayout'
import { OptionsNavigationBar } from 'app/pages/Dashboard/Goals/Options/OptionsNavigationBar'
import { Typography } from 'components/atoms/Typography'
import Width from 'components/_old/Width/Width'
import { Typo } from 'components/_old/Typo/Typo'
import Text from 'components/_old/Text/Text.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Button from 'components/_old/Button/Button.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label, { LabelField, LabelText } from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import { AutoinvestInfo } from 'app/pages/Dashboard/Goals/AddFunds/components/AutoinvestInfo'
import { Paper } from 'components/atoms/Paper'
import { BankAccountCard } from 'components/molecules/BankAccountCard'

import { showFailToast } from 'app/redux/actions/ui'
import { addFundsWireTransfer, changeField as changeGoalField } from 'app/redux/actions/portfolios'

import { $allowancesStore } from 'app/effector/allowances'
import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $dictsStore } from 'app/effector/dicts'

import { regulatoryTypes as goalRegulatoryTypes } from 'constants/goal'
import { manageTypes } from 'constants/portfolio'
import { bankAccountStates } from 'constants/bankAccounts'

const WireTransferForm = ({
  amount,
  goal,
  location,
  changeField,
  toggleCloseButton,
  handleSubmit,
  handleClose,
  tunnelQuery,
}) => {
  const { desktop } = useMediaQueries()

  const { bankAccounts, isLoading } = useUnit($bankAccountsStore)
  const hasApprovedBankAccounts = bankAccounts.some((account) => account.state === bankAccountStates.APPROVED)
  const bankAccountsList = hasApprovedBankAccounts
    ? bankAccounts.filter((account) => account.state === bankAccountStates.APPROVED)
    : bankAccounts.filter((account) => account.state === bankAccountStates.AWAITING_APPROVAL)

  const { one_off_payment, first_topup } = goal
  const { initialDepositMin } = useUnit($dictsStore)
  const portfolios = useSelector((state) => state.portfolios.list)
  const gotOneOffPayment = Boolean(one_off_payment)

  const { allowances } = useUnit($allowancesStore)
  const sippTotalAllowance = parseFloat(allowances?.sipp?.total_allowance ?? 0)
  const isaRemainingAllowance = parseFloat(allowances?.isa?.remaining_allowance ?? 0)

  const hasFundedPortfolios = portfolios.some((portfolio) => portfolio.first_topup)
  const isDiyPortfolio = goal.manage_type === manageTypes.DIY
  const isCashPortfolio = goal.manage_type === manageTypes.CASH
  const isFirstPayment = isCashPortfolio ? !hasFundedPortfolios : !first_topup
  const currentLocation = window.location.pathname + window.location.search

  const nextQuery = useMemo(() => ({ ...tunnelQuery, back: currentLocation }), [tunnelQuery, currentLocation])

  const handleUploadBankStatement = useCallback(
    (bankAccountId) => {
      goTo(urlTo(`dashboard.portfolio.add-funds.upload-bank-statement`, { id: goal.id, bankAccountId }, nextQuery))
    },
    [goal, nextQuery],
  )

  const handleAmountChange = useCallback(
    (_event, value = null) => {
      changeField('amount', value)
    },
    [changeField],
  )

  useEffect(() => {
    if (!gotOneOffPayment && isFirstPayment) {
      changeField('amount', goal.initial_deposit)
    }

    return () => {
      const nextAmount = !gotOneOffPayment && isFirstPayment ? goal.initial_deposit : null

      changeField('amount', nextAmount)
    }
  }, [])

  const maxAmount = useMemo(() => {
    if (goal.regulatory_type === goalRegulatoryTypes.ISA) {
      return isaRemainingAllowance
    }
    return MAX_SINGLE_PAYMENT_AMOUNT
  }, [goal, isaRemainingAllowance])

  const isIsaLimitReached =
    goal.regulatory_type === goalRegulatoryTypes.ISA && parseFloat(amount ?? 0) > isaRemainingAllowance

  const validation = {
    amount: {
      rules: [
        amount > 0,
        (isFirstPayment && amount >= initialDepositMin) || !isFirstPayment,
        amount <= maxAmount,
        amount >= MIN_SINGLE_PAYMENT_AMOUNT,
      ],
      errors: [
        'Please enter a value',
        `Must be at least ${formatMoney(initialDepositMin)}`,
        `Maximum amount is ${formatMoney(maxAmount)}`,
        `Must be at least ${formatMoney(MIN_SINGLE_PAYMENT_AMOUNT)}`,
      ],
    },
    hasApprovedBankAccounts: {
      rules: [hasApprovedBankAccounts],
    },
  }

  const handleBack = useCallback(() => {
    return goTo(location?.query?.back ?? urlTo(`portfolio.add-funds`, { id: goal.id }))
  }, [goal?.id, location?.query?.back])

  useEffect(() => {
    toggleCloseButton(false)
  }, [])

  const header = (
    <OptionsNavigationBar
      leftPartText="Back"
      onLeftPartClick={handleBack}
      rightPartText="Close"
      onRightPartClick={handleClose}
    >
      <Typo>{isCashPortfolio ? 'Add cash' : 'Manual bank transfer'}</Typo>
    </OptionsNavigationBar>
  )

  return (
    <SubmitOnEnter>
      <OptionsLayout
        header={header}
        content={
          <Paper top={desktop ? 24 : null}>
            <Width size={desktop ? 29 : null} center>
              <Card
                mods={{
                  padding: 'big',
                  'no-padding': desktop ? null : 'all',
                  theme: desktop ? null : 'transparent',
                }}
              >
                <Validate rules={validation.amount.rules}>
                  {(isValid, brokenRule) => {
                    const postfield = (() => {
                      if (goal.regulatory_type === goalRegulatoryTypes.ISA) {
                        return (
                          <Paper top={12} data-test-id="wireTransferAmountLimit">
                            <Typography size={12} color={isIsaLimitReached ? 'error' : 'default'} align="center">
                              <Typo>
                                You can add the maximum of {goal.isa && formatMoney(isaRemainingAllowance, true)}
                              </Typo>
                            </Typography>
                            {isIsaLimitReached && (
                              <Paper top={12}>
                                <Typography size={12} align="center">
                                  <Typo>
                                    Adding these funds would oversubscribe your ISA account. Yearly limit is{' '}
                                    {goal.isa && formatMoney(goal.isa.current_year_allowance, true)}
                                  </Typo>
                                </Typography>
                              </Paper>
                            )}
                          </Paper>
                        )
                      }

                      if (goal.regulatory_type === goalRegulatoryTypes.SIPP) {
                        return (
                          <Paper top={12}>
                            <Typography size={12} align="center">
                              <Typo>Personal Pension annual allowance is {formatMoney(sippTotalAllowance, true)}</Typo>
                            </Typography>
                          </Paper>
                        )
                      }

                      return null
                    })()

                    return (
                      <Label postfield={postfield}>
                        <LabelText>
                          <Text color={isValid ? null : 'red'}>
                            {isValid ? 'Amount' : validation.amount.errors[brokenRule]}
                          </Text>
                        </LabelText>
                        <LabelField>
                          <Input
                            type="money"
                            mods={{ size: 'bigger' }}
                            tabIndex={1}
                            onChange={handleAmountChange}
                            valid={isValid}
                            withFloat
                            disabled={!hasApprovedBankAccounts}
                            data-test-id="addFundsAmountInput"
                          >
                            {amount}
                          </Input>
                        </LabelField>
                      </Label>
                    )
                  }}
                </Validate>
              </Card>

              {isDiyPortfolio && (
                <Paper top={desktop ? 32 : 24}>
                  <AutoinvestInfo portfolio={goal} back={currentLocation} />
                </Paper>
              )}

              <Paper top={56} bottom={desktop ? 48 : 20}>
                <Typography
                  size={desktop ? 16 : 14}
                  color={hasApprovedBankAccounts || isLoading ? 'default' : 'error'}
                  align="center"
                >
                  <Typo>
                    {hasApprovedBankAccounts || isLoading
                      ? 'We can only accept funds from your verified bank accounts:'
                      : 'Your bank account is still Awaiting verification. We can only accept funds from your verified bank account.'}
                  </Typo>
                </Typography>
              </Paper>

              {bankAccountsList.map((account, index) => (
                <Paper key={account.id} top={index ? 16 : 0}>
                  <BankAccountCard bankAccount={account} onUploadLinkClick={handleUploadBankStatement} />
                </Paper>
              ))}
            </Width>
          </Paper>
        }
        button={
          hasApprovedBankAccounts ? (
            <Button
              type="submit"
              mods={{ size: 'big block' }}
              disabled={!bulkValidate(validation)}
              onClick={() => handleSubmit(amount)}
              data-test-id="wireTransferContinueButton"
            >
              Continue
            </Button>
          ) : null
        }
        data-test-id="wireTransfer"
      />
    </SubmitOnEnter>
  )
}

WireTransferForm.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  goal: PropTypes.object,
  location: PropTypes.shape({
    query: PropTypes.shape({
      back: PropTypes.string,
    }),
  }),
  toggleCloseButton: PropTypes.func,

  changeField: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
}

export default compose(
  connect(
    (state, ownProps) => {
      return {
        ...state.ui.modals.wireTransfer,
        amount: state.portfolios.items.find((goal) => goal.id === ownProps.goal.id).one_off_payment,
      }
    },
    (dispatch, ownProps) => ({
      changeField: (field, value) => {
        dispatch(changeGoalField({ one_off_payment: value }, ownProps.goal.id))
      },
      handleSubmit: async (amount) => {
        const { goal, handleClose, tunnelQuery } = ownProps

        try {
          trackEvent({
            category: 'Add funds',
            action: 'Initiate ‘Add money’',
            label: 'Wire transfer',
            value: amount,
            portfolioPresetType: goal.preset_type,
            portfolioRegulatoryType: goal.regulatory_type,
          })

          const {
            portfolios: { error },
          } = await dispatch(addFundsWireTransfer(amount, goal.id))

          if (error) {
            throw new Error(error)
          }

          const queryAmount = goal.one_off_payment ?? amount
          const bankTransferUrl = `dashboard.portfolio.add-funds.bank-transfer`

          goTo(urlTo(bankTransferUrl, { id: goal.id }, { ...tunnelQuery, amount: queryAmount }), {
            replace: true,
            scrollToTop: false,
          })
        } catch (error) {
          sendError(error)
          handleClose()
          showFailToast('Something went wrong')
        }
      },
    }),
  ),
)(WireTransferForm)
