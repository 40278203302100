import React from 'react'

import SvgIcon from 'components/_old/SvgIcon/SvgIcon.jsx'
import iconNew from './LogoNew.svg'

import './Logo.css'

const Logo = (props) => (
  <span className="Logo">
    <SvgIcon src={iconNew} {...props} />
  </span>
)

export { Logo }
