import { nullable, type Nullable, string } from 'app/effector/model'

// import { SecurityProviderDTO } from '../api'

type SecurityProviderDTO = {
  name?: string | null
  logo_uri?: string | null
}

class SecurityProvider {
  readonly name: string
  readonly logo_uri: Nullable<string>

  static isIndependent(dto: SecurityProviderDTO): boolean {
    return dto?.name?.toLowerCase?.() === 'independent'
  }

  constructor(dto: SecurityProviderDTO) {
    this.name = string(dto.name)
    this.logo_uri = SecurityProvider.isIndependent(dto) ? null : nullable(string)(dto.logo_uri)
  }
}

export { SecurityProvider, type SecurityProviderDTO }
