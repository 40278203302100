import { useState, useCallback } from 'hooks'

type UseLoadingCallbacoOrPromise<Return> = ((...args: unknown[]) => Promise<Return>) | Promise<Return>

type UseLoadingInterface = {
  isLoading: boolean
  timesLoaded: number
  wait: <Return>(callbackOrPromise: UseLoadingCallbacoOrPromise<Return>, untilFocus?: boolean) => Promise<Return>
}

const useLoading = (initialState = false): UseLoadingInterface => {
  const [isLoading, setIsLoading] = useState(initialState)
  const [timesLoaded, setTimesLoaded] = useState(0)

  const wait = useCallback(
    async <Return>(callbackOrPromise: UseLoadingCallbacoOrPromise<Return>, untilFocus = false): Promise<Return> => {
      try {
        setIsLoading(true)

        const promise = typeof callbackOrPromise === 'function' ? await callbackOrPromise() : callbackOrPromise

        if (promise instanceof Promise) {
          const result = await promise

          if (result instanceof Error) {
            throw result
          }
        }

        if (!untilFocus) {
          setIsLoading(false)
          setTimesLoaded((prevCount) => prevCount + 1)

          return await promise
        }

        const onBlur = (): void => {
          setIsLoading(false)
          window.removeEventListener('focus', onBlur)
        }

        window.addEventListener('focus', onBlur)
        setTimesLoaded((prevCount) => prevCount + 1)

        return await promise
      } catch (error) {
        setIsLoading(false)

        throw error
      }
    },
    [setIsLoading, setTimesLoaded],
  )

  return { isLoading, timesLoaded, wait }
}

export { useLoading }
