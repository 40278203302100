import React, { Fragment } from 'react'

import type { QuickStart } from 'app/effector/quickStart/types'

import type { Preset } from 'app/redux/models/portfolio/types'

import Inner from 'components/_old/Inner/Inner.jsx'

import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { PortfolioLine } from 'app/pages/Dashboard/Goals/components/PortfolioLine'
import { usePortfolioActions } from 'app/pages/Dashboard/Goals/DIY/Portfolio/hooks'
import { OptionsModal } from 'app/pages/Dashboard/Goals/Options/OptionsModal'

import { Overview } from '../components/Overview'
import { PortfolioTabs } from '../components/PortfolioTabs'
import { PortfolioHeader } from '../components/PotrfolioHeader'
import { Projections } from '../components/Projections'
import { useManagedPortfolioContent } from '../hooks/useManagedPortfolioContent'
import { type ManagedPortfolio, TabNames } from '../types'

type DesktopProps = {
  location: {
    query: {
      view: string
    }
  }
  isPortfolioLoading: boolean
  portfolio: ManagedPortfolio
  goalTitle: string
  showFinishSavingsPlanBanner: boolean
  showLumpSumBanner: boolean
  quickStart?: QuickStart
  regulatoryTypeText: string
  preset: Preset
}

const Desktop = ({
  location,
  isPortfolioLoading,
  portfolio,
  goalTitle,
  showFinishSavingsPlanBanner,
  showLumpSumBanner,
  quickStart,
  regulatoryTypeText,
  preset,
}: DesktopProps): React.ReactElement => {
  const {
    handleBack,
    visibleTabs,
    activeTabName,
    activeTabIndex,
    handleAmendPortfolioSelect,
    handleSecurityClick,
    navigateByTab,
    portfolioModel,
    allocationData,
    showSecuritiesSkeleton,
  } = useManagedPortfolioContent({
    portfolio,
    location,
  })
  const { actions, actionsMap } = usePortfolioActions(portfolio, handleAmendPortfolioSelect, visibleTabs.length > 1)

  const contentNode = (
    <Fragment>
      <PortfolioLine type="managed" />
      <Inner twoColumns>
        <PortfolioHeader
          handleBack={handleBack}
          goalTitle={goalTitle}
          regulatoryTypeText={regulatoryTypeText}
          isDefaultPortfolio={portfolio?.is_default_preset_used}
        />
        {visibleTabs.length > 1 && (
          <Paper left={64} top={32}>
            <Paper left={12}>
              <PortfolioTabs tabItems={visibleTabs} activeTabIndex={activeTabIndex} navigateByTab={navigateByTab} />
            </Paper>
          </Paper>
        )}
        {activeTabName === TabNames.OVERVIEW && (
          <Overview
            isPortfolioLoading={isPortfolioLoading}
            portfolio={portfolio}
            onSecurityClick={handleSecurityClick}
            location={location}
            actionsMap={actionsMap}
            showFinishSavingsPlanBanner={showFinishSavingsPlanBanner}
            showLumpSumBanner={showLumpSumBanner}
            quickStart={quickStart}
            allocationData={allocationData}
            showSecuritiesSkeleton={showSecuritiesSkeleton}
            preset={preset}
          />
        )}
        {activeTabName === TabNames.PROJECTIONS && (
          <Fragment>
            <Projections portfolio={portfolio} handleAmendPortfolioSelect={handleAmendPortfolioSelect} />
          </Fragment>
        )}
      </Inner>
    </Fragment>
  )

  return (
    <Fragment>
      <DesktopLayout header={<DesktopHeader />} content={contentNode} footer={<DesktopFooter />} />
      <OptionsModal portfolio={portfolioModel} portfolioActions={actions} />
    </Fragment>
  )
}

export { Desktop }
