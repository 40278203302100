import React from 'react'
import PropTypes from 'prop-types'

import { useMemo } from 'hooks'

import { DesktopLayout } from 'components/atoms/Layouts'
import Header from 'components/_old/Header/Header.jsx'
import { Progress } from 'components/_old/Progress/'
import { Paper } from 'components/atoms/Paper'
import Inner from 'components/_old/Inner/Inner.jsx'
import Button from 'components/_old/Button/Button.jsx'
import { DesktopFooter } from 'app/containers/Footer'
import Width from 'components/_old/Width/Width'

import { WeAreSorry } from '../WeAreSorry'
import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Typography } from 'components/atoms/Typography'

const Desktop = ({ portfolio, handleReset, handleSubmit }) => {
  const header = useMemo(
    () => (
      <Header
        right={
          <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }} onClick={handleSubmit}>
            My Dashboard
          </Button>
        }
        after={<Progress fill={100} gapWidth="1em" />}
        withShadow={false}
        hideDrawer
      />
    ),
    [],
  )

  const content = useMemo(
    () => (
      <Paper top={80}>
        <Inner>
          <WeAreSorry />
          <Paper top={56}>
            <Typography align="center">
              <ColumnarLayout inline mods={{ padding: 'micro' }}>
                {portfolio && (
                  <Width size={13.5} center>
                    <Button
                      mods={{ size: 'big block', theme: 'simple-reverse-blue' }}
                      onClick={handleReset}
                      data-test-id="retakeQuestionnaireButton"
                    >
                      Retake questionnaire
                    </Button>
                  </Width>
                )}
                <Width size={13.5} center>
                  <Button mods={{ size: 'big block' }} onClick={handleSubmit} data-test-id="sorryDashboardButton">
                    My Dashboard
                  </Button>
                </Width>
              </ColumnarLayout>
            </Typography>
          </Paper>
        </Inner>
      </Paper>
    ),
    [],
  )

  return <DesktopLayout header={header} content={content} footer={<DesktopFooter />} />
}

Desktop.propTypes = {
  portfolio: PropTypes.object,
  handleReset: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export { Desktop }
