import React from 'react'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'

import compose from 'helpers/compose.js'
import { urlTo, goTo } from 'helpers/router.js'
import { combineErrors, backendErrorsToObj, bulkValidate } from 'helpers/validation.js'
import { sendError } from 'helpers/errorLogging.js'

import { usePrevious, useActions, useState, useEffect, useCallback, useMemo, useContext } from 'hooks'

import { ChangeGoalTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'

import Modal from 'components/_old/Modal/Modal.jsx'
import ModalContent, {
  ModalContentHeadline,
  ModalContentBody,
  ModalContentButtons,
} from 'components/molecules/ModalContent/ModalContent.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import Form from 'components/_old/Form/Form.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label from 'components/_old/Label/Label.jsx'
import Input from 'components/_old/Input/Input.jsx'
import ButtonGroup from 'components/molecules/ButtonGroup/ButtonGroup.jsx'
import Button from 'components/_old/Button/Button.jsx'

import {
  updateOrCreate as updateOrCreateGoalActionCreator,
  changeField as changeGoalFieldActionCreator,
  resetError as resetGoalErrorActionCreator,
} from 'app/redux/actions/portfolios'
import { showFailToast } from 'app/redux/actions/ui'
import { selectGoalById, selectGoalTitle } from 'app/redux/selectors'

const ChangeGoalTitle = ({ routes, goalId }) => {
  const currentRouteModule = routes[routes.length - 1].module
  const isOpen = currentRouteModule === 'change-portfolio-name'
  const portfolios = useSelector((state) => state.portfolios)
  const goal = useMemo(() => selectGoalById({ portfolios }, goalId) || {}, [portfolios.items, goalId])
  const goalTitle = selectGoalTitle({ portfolios }, goal?.id)
  const { goalError } = portfolios

  const [stateTitle, setStateTitle] = useState(goalTitle || null)
  const prevTitle = usePrevious(goalTitle)
  const prevIsOpen = usePrevious(isOpen)

  const [updateOrCreateGoal, changeGoalField, resetGoalError] = useActions([
    updateOrCreateGoalActionCreator,
    changeGoalFieldActionCreator,
    resetGoalErrorActionCreator,
  ])

  useEffect(() => {
    if ((!stateTitle && !prevTitle && goalTitle) || (isOpen && !prevIsOpen)) {
      setStateTitle(goalTitle)
    }
  }, [goalTitle, stateTitle, prevTitle, isOpen, prevIsOpen])

  const handleTitleChange = useCallback(
    (event, value = event.target.value) => {
      if (value?.length <= 50) {
        setStateTitle(value)
      }
      if (goalError) {
        resetGoalError()
      }
    },
    [goalError, resetGoalError],
  )

  const { tunnelQuery } = useContext(ChangeGoalTunnelContext)

  const handleClose = useCallback(() => {
    goTo(urlTo(`dashboard.portfolio`, { id: goalId }, tunnelQuery), { replace: true, scrollToTop: false })
  }, [goalId, tunnelQuery])

  const handleSave = useCallback(async () => {
    try {
      changeGoalField({ title: stateTitle }, goal.id)
      const stateAfterUpdateGoal = await updateOrCreateGoal(['title'], {}, false, goal.id)

      if (stateAfterUpdateGoal.portfolios.error) {
        throw stateAfterUpdateGoal.portfolios.error
      }
      handleClose()
    } catch (error) {
      // TODO: add processError
      showFailToast()
      sendError(error)
    }
  }, [goal, stateTitle, updateOrCreateGoal, handleClose])

  const validation = combineErrors(
    {
      title: {
        rules: [stateTitle, (stateTitle || '').length <= 30],
        errors: ['Portfolio name can’t be empty', 'Name must be less than 30 characters'],
      },
    },
    backendErrorsToObj(goalError),
  )

  return (
    <Modal open={isOpen} onClose={handleClose} data-test-id="changeGoalTitleModal" gatewayName="change-goal-title">
      <ModalContent
        width={30}
        wrapWith={(innerContent) => (
          <SubmitOnEnter>
            <Form>{innerContent}</Form>
          </SubmitOnEnter>
        )}
      >
        <ModalContentHeadline>Edit portfolio name</ModalContentHeadline>
        <ModalContentBody size={null}>
          <Validate rules={validation.title.rules}>
            <Label errorMessages={validation.title.errors} data-test-id="changeGoalTitleLabel">
              Portfolio name
              <Input
                type="text"
                mods={{ size: 'bigger' }}
                tabIndex={1}
                onChange={handleTitleChange}
                data-test-id="changeGoalTitleInput"
              >
                {stateTitle}
              </Input>
            </Label>
          </Validate>
        </ModalContentBody>
        <ModalContentButtons width={null}>
          <ButtonGroup>
            <Button
              mods={{ theme: 'simple-reverse-blue' }}
              onClick={handleClose}
              data-test-id="changeGoalTitleCancelButton"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleSave}
              disabled={!bulkValidate(validation)}
              data-test-id="changeGoalTitleSubmitButton"
            >
              Change
            </Button>
          </ButtonGroup>
        </ModalContentButtons>
      </ModalContent>
    </Modal>
  )
}

export default compose(withRouter)(ChangeGoalTitle)
