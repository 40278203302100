/**
 * @class
 * @typedef {Object} TransactionList
 */
export class TransactionList {
  /**
   * @param {Transaction[]} transactions
   */
  constructor(transactions = []) {
    /** @type {Transaction[]} */
    this.transactions = transactions
  }

  /**
   * @param {Transaction[]} transactions
   * @return {TransactionList}
   */
  receiveTransactions(transactions): TransactionList {
    this.transactions = transactions
    return this
  }

  isEmpty(): boolean {
    return this.transactions.length === 0
  }

  hasTransactions(): boolean {
    return this.transactions.length > 0
  }

  hasTransactionsByGoal(goalId): number {
    return this.transactions.some((transaction) => transaction.goal === goalId)
  }

  filterByPortfolio(portfolioId): number {
    return new TransactionList(this.transactions.filter((transaction) => transaction.goal === portfolioId))
  }
}
