import React, { type ReactElement } from 'react'

import { useRef, useEffect, useMemo } from 'hooks'

import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'

import './HorizontallyScrollable.css'

type HorizontallyScrollableProps = {
  children: React.ReactNode | React.ReactNodeArray
  margin?: number
  scrollBack?: boolean
  mix?: boolean
}

const HorizontallyScrollable = ({ children, margin, scrollBack, mix }: HorizontallyScrollableProps): ReactElement => {
  const componentRef = useRef<HTMLDivElement>()

  useEffect(
    () => {
      if (scrollBack && componentRef.current) {
        componentRef.current.scrollLeft = 0
      }
    },
    // had to run only on mount or when children changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children],
  )

  const content = useMemo(() => {
    const className = 'HorizontallyScrollable-Content'

    if (mix) {
      return React.cloneElement(children, { className })
    }

    return <div className={className}>{children}</div>
  }, [children, mix])

  return (
    <SideSpacesCompensator size={margin}>
      <div className="HorizontallyScrollable" ref={componentRef}>
        {content}
      </div>
    </SideSpacesCompensator>
  )
}

export { HorizontallyScrollable }
