import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import {
  type NomineesTransferProvider,
  type NomineesTransferProviderList,
} from 'app/effector/nominees-transfers/providers'

import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

type AvailableToTransferFromProviderProps = {
  provider: NomineesTransferProvider
}

function AvailableToTransferFromProvider({ provider }: AvailableToTransferFromProviderProps): React.ReactElement {
  const { desktop } = useMediaQueries()
  const size = desktop ? 72 : 64

  return (
    <Paper>
      <SecurityLogo logo={provider.logo} size={size} grayLogo />
      <Paper top={12}>
        <Width size={size / 16} center>
          <SideSpacesCompensator>
            <Typography size={14} align="center">
              <Typo>{provider.name}</Typo>
            </Typography>
          </SideSpacesCompensator>
        </Width>
      </Paper>
    </Paper>
  )
}

type AvailableToTransferFromProps = {
  providers: NomineesTransferProviderList
}

function AvailableToTransferFrom({ providers }: AvailableToTransferFromProps): React.ReactElement {
  return (
    <Fragment>
      <Typography size={24} weight="semibold" lineHeight="small">
        <Typo>Available to transfer from</Typo>
      </Typography>
      <Paper top={32}>
        <Stack horizontal={24}>
          {providers.map((provider) => (
            <AvailableToTransferFromProvider key={provider.name} provider={provider} />
          ))}
        </Stack>
      </Paper>
    </Fragment>
  )
}

export { AvailableToTransferFrom, type AvailableToTransferFromProps }
