import React from 'react'

import { useCallback, useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'
import { goTo, urlTo } from 'helpers/router.js'

import { finishQuickStartFx } from 'app/effector/quickStart'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'

import lumpSumImage from './lumpSum.png'

import './FirstTopupBanner.css'

type FirstTopupBannerProps = {
  portfolio: Portfolio
  daysBeforePayment: number
}

const FirstTopupBanner = ({ portfolio, daysBeforePayment }: FirstTopupBannerProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const handleClick = useCallback(() => {
    goTo(urlTo(`portfolio.add-funds`, { id: portfolio?.id }), {
      scrollToTop: false,
    })
  }, [portfolio])

  const handleClose = useCallback(
    (event) => {
      finishQuickStartFx(portfolio.id)
      event.stopPropagation()
    },
    [portfolio],
  )

  return (
    <SelectableCard className="FirstTopupBanner_Card" onClick={handleClick}>
      <Link className="FirstTopupBanner_Close" onClick={handleClose}>
        <Icon size={24} type="cross-thin" color={palette['content-on-color-white']} />
      </Link>
      <Paper top={desktop ? 16 : 24} bottom={desktop ? 16 : 24} left={desktop ? 32 : 24} right={desktop ? 32 : 0}>
        <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
          <Column size={1}>
            <Typography size={desktop ? 20 : 16} weight="semibold" color="white">
              <Typo noNbsp>
                It's {daysBeforePayment} {daysBeforePayment === 1 ? 'day' : 'days'} before we take your first Savings
                plan payment. Do you want to invest a lump sum <Nobr>now? →</Nobr>
              </Typo>
            </Typography>
          </Column>
          <Column size={0}>
            <img src={lumpSumImage} width={132} height={132} />
          </Column>
        </ColumnarLayout>
      </Paper>
    </SelectableCard>
  )
}

export { FirstTopupBanner }
