import React from 'react'

import { MoveCash } from './MoveCash'
import { MoveCashAmount } from './MoveCashAmount'

const routes = [
  {
    module: 'move-cash',
    path: 'move-cash/',
    component: function Component(props) {
      return <MoveCash {...props} />
    },
    childRoutes: [
      {
        module: 'amount',
        path: ':to/',
        component: function Component(props) {
          return <MoveCashAmount {...props} />
        },
      },
    ],
  },
  {
    module: 'move-cash-in',
    path: 'move-cash-in/',
    component: function Component(props) {
      return <MoveCash action="in" {...props} />
    },
    childRoutes: [
      {
        module: 'amount',
        path: ':to/',
        component: function Component(props) {
          return <MoveCashAmount action="in" {...props} />
        },
      },
    ],
  },
  {
    module: 'move-cash-out',
    path: 'move-cash-out/',
    component: function Component(props) {
      return <MoveCash action="out" {...props} />
    },
    childRoutes: [
      {
        module: 'amount',
        path: ':to/',
        component: function Component(props) {
          return <MoveCashAmount action="out" {...props} />
        },
      },
    ],
  },
]

export { routes }
