import React, { useEffect, useState, useRef } from 'react'

import classNames from 'classnames/dedupe'
import useScrollDirection from 'hooks/useScrollDirection'
import useScrollPosition from 'hooks/useScrollPosition'

import { useLockHeaderScroll } from 'app/containers/LockHeaderScrollProvider'

import './StickyTop.css'

const StickyTop = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [isSticky, setSticky] = useState<boolean | null>(null)
  const { lockHeaderScroll } = useLockHeaderScroll()

  const stickyWrapperRef = useRef<HTMLDivElement | null>(null)
  const stickyWrapperHeight = stickyWrapperRef?.current?.getBoundingClientRect().height ?? 0
  const scrollDelta = 8
  const stickyThreshold = stickyWrapperHeight + scrollDelta

  const scrollPosition = useScrollPosition(scrollDelta, stickyThreshold)
  const [scrollDirection, setScrollDirection] = useScrollDirection(scrollPosition)

  const classes = classNames('StickyTop', {
    StickyTop_enter: isSticky,
    StickyTop_leave: isSticky === false && scrollPosition > stickyThreshold,
  })

  useEffect(() => {
    if (lockHeaderScroll) {
      setScrollDirection(scrollDirection)
      return
    }

    if (scrollPosition < stickyThreshold && !isSticky) {
      setSticky(null)
      return
    }

    if (scrollDirection === 'up') {
      setSticky(true)
      return
    }

    if (isSticky !== null) {
      setSticky(false)
    }
  }, [scrollDirection, scrollPosition, isSticky, stickyThreshold, lockHeaderScroll, setScrollDirection])

  return (
    <div
      className={classes}
      style={{ '--sticky-top-position': isSticky ? 'sticky' : 'relative' }}
      ref={stickyWrapperRef}
    >
      {children}
    </div>
  )
}

export default StickyTop
