import React from 'react'

import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'

import { propTypes } from 'helpers/propTypes'

import { BankAccount } from 'app/effector/bank-accounts/models/bank'

import Button from 'components/_old/Button/Button.jsx'
import Card from 'components/_old/Card/Card.jsx'
import Width from 'components/_old/Width/Width'

import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { BankAccountInfo } from 'components/molecules/BankAccountInfo'

import { FileUpload } from 'components/organisms/FileUpload'
import { statuses as fileUploadStatuses } from 'components/organisms/FileUpload/constants.js'

const UploadBankStatementForm = ({
  header,
  title = 'We need help verifying your bank account details',
  bankAccount,
  onAfterUpload,
  onContinue,
}) => {
  const { desktop } = useMediaQueries()

  const heading = useMemo(
    () => (
      <Typography size={desktop ? 32 : 24} lineHeight="small" align="center" weight={desktop ? null : 'semibold'}>
        {title}
      </Typography>
    ),
    [desktop, title],
  )

  const bankAccountInfo = useMemo(
    () => (
      <Paper top={48} bottom={48}>
        <BankAccountInfo sortCode={bankAccount?.sort_code} accountNumber={bankAccount?.account_number} />
      </Paper>
    ),
    [bankAccount],
  )

  const fileUpload = useMemo(
    () => (
      <Card
        mods={{
          theme: desktop ? 'transparent' : null,
          'no-padding': 'all',
        }}
      >
        <FileUpload
          status={bankAccount?.bank_statement_filled ? fileUploadStatuses.DONE : fileUploadStatuses.DEFAULT}
          url={`bank-accounts/${bankAccount?.id}/files/`}
          icon="bank_statement"
          dataType="bank_statement"
          title="Upload bank statement"
          text="Bank documents must show your name, current address, account number + sort code and be from within the last 3 months.
          We can accept downloaded PDF copies, screenshots or photographs of paper statements."
          onAfterUpload={onAfterUpload}
        />
      </Card>
    ),
    [desktop, bankAccount, onAfterUpload],
  )

  const continueButton = useMemo(
    () => (
      <Button mods={{ size: 'big block' }} disabled={!bankAccount?.bank_statement_filled} onClick={onContinue}>
        Continue
      </Button>
    ),
    [bankAccount, onContinue],
  )

  if (desktop) {
    return (
      <Width size={35}>
        {header}
        <Paper top={24} bottom={48} left={80} right={80}>
          {heading}
          {bankAccountInfo}
          {fileUpload}
          <Paper top={32}>{continueButton}</Paper>
        </Paper>
      </Width>
    )
  }

  const content = (
    <Paper top={8} bottom={16}>
      {heading}
      {bankAccountInfo}
      {fileUpload}
    </Paper>
  )

  const footer = <MobileLayoutFooterButton>{continueButton}</MobileLayoutFooterButton>

  return <MobileLayout header={header} content={content} footer={footer} />
}

UploadBankStatementForm.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  bankAccount: propTypes.instanceOf(BankAccount),
  onAfterUpload: PropTypes.func,
  onContinue: PropTypes.func,
}

export { UploadBankStatementForm }
