const transformError = ({ error, errorHandlers }) => {
  if (!error) {
    return
  }

  errorHandlers.push((ctx) => {
    // if error from axios
    if (ctx.error.config) {
      const error = new Error(`Ajax "${ctx.error.config.method}" at "${ctx.error.config.url}" failed`)
      error.config = ctx.error.config
      error.request = ctx.error.request
      error.response = ctx.error.response || {}

      ctx.error = error
    }

    return ctx
  })
}

export { transformError }
