import React from 'react'
import PropTypes from 'prop-types'
import CSSTransitionGroup from 'react-addons-css-transition-group'

import { palettePlainValues } from 'helpers/palette'

import { useCallback, useDebouncedCallback, useEffect, useMemo, useState } from 'hooks'

import { Gateway } from 'components/atoms/Gateway'
import Button from 'components/_old/Button/Button.jsx'

import './ScrollToElementIfOutsideViewport.css'

const ScrollToElementIfOutsideViewport = ({
  children,
  elementToScrollTo,
  shouldShowScrollToKey,
  setShouldShowScrollToKey,
  color = 'green',
  bottom = 4,
}) => {
  const currentElement = elementToScrollTo?.current
  const [isVisible, setIsVisible] = useState(false)

  const scrollers = useMemo(() => {
    const layoutMain = document.querySelector('.LayoutElement_main') || document.querySelector('.MobileLayout-Content')

    return [window, layoutMain]
  }, [currentElement])

  const getHeadersOffset = useCallback(() => {
    const headers = [...document.querySelectorAll('.Header'), ...document.querySelectorAll('.MobileLayout-Header')]

    return headers.map((header) => header.getBoundingClientRect?.()?.height).filter((height) => height > 0)?.[0] || 0
  }, [])

  const getAfterLayoutOffset = useCallback(() => {
    const afterLayout =
      document.querySelector('[data-gateway-destination="afterLayout"]') ||
      document.querySelector('.MobileLayout-Footer')

    return afterLayout?.getBoundingClientRect?.()?.height || 0
  }, [])

  const isScrolledIntoView = useCallback(() => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const rect = currentElement?.getBoundingClientRect() || { top: 0, height: 0 }

    return rect.top + getAfterLayoutOffset() + Math.floor(rect.height / 2) <= windowHeight
  }, [scrollers, currentElement, getHeadersOffset, getAfterLayoutOffset])

  const handleShow = useCallback(() => {
    setIsVisible(true)
  }, [setIsVisible])

  const handleHide = useCallback(() => {
    setShouldShowScrollToKey('')
    setIsVisible(false)
  }, [setShouldShowScrollToKey, setIsVisible])

  const handleScroll = useDebouncedCallback(
    () => {
      if (isScrolledIntoView()) {
        handleHide()
      }
    },
    250,
    [scrollers, currentElement],
  )

  const handleClick = useCallback(() => {
    if (currentElement) {
      scrollers.forEach((scroller) => {
        if (scroller?.scroll) {
          const top =
            (scroller.scrollY || scroller.scrollTop) +
            (currentElement?.getBoundingClientRect()?.top || 0) -
            getHeadersOffset()

          scroller.scroll({ top, behavior: 'smooth' })
        }
      })
    }

    handleHide()
  }, [scrollers, currentElement, getHeadersOffset])

  useEffect(() => {
    const isElementToScrollToExists = Boolean(currentElement)

    if (!isElementToScrollToExists) {
      handleHide()
    }

    if (isElementToScrollToExists && shouldShowScrollToKey && !isVisible && !isScrolledIntoView()) {
      handleShow()
    }
  }, [shouldShowScrollToKey, currentElement, isVisible, isScrolledIntoView])

  useEffect(() => {
    scrollers.forEach((scroller) => {
      scroller?.addEventListener('scroll', handleScroll)
    })

    return () => {
      scrollers.forEach((scroller) => {
        scroller?.removeEventListener('scroll', handleScroll)
      })
    }
  }, [scrollers, currentElement])

  return (
    <Gateway into="alarms">
      <CSSTransitionGroup
        transitionEnterTimeout={palettePlainValues.animation.speed.number.default}
        transitionLeaveTimeout={palettePlainValues.animation.speed.number.default}
        transitionName={{
          enter: 'ScrollToElementIfOutsideViewport_enter',
          enterActive: 'ScrollToElementIfOutsideViewport_enter_active',
          leave: 'ScrollToElementIfOutsideViewport_leave',
          leaveActive: 'ScrollToElementIfOutsideViewport_leave_active',
        }}
      >
        {isVisible ? (
          <div className="ScrollToElementIfOutsideViewport" style={{ bottom: `${bottom}rem` }}>
            <Button
              className="ScrollToElementIfOutsideViewport-Button"
              mods={{
                color: color,
                theme: `simple-${color} round`,
                text: 'smaller',
              }}
              onClick={handleClick}
            >
              {children}
            </Button>
          </div>
        ) : null}
      </CSSTransitionGroup>
    </Gateway>
  )
}

ScrollToElementIfOutsideViewport.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  elementToScrollTo: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  shouldShowScrollToKey: PropTypes.string.isRequired,
  setShouldShowScrollToKey: PropTypes.func.isRequired,
  color: PropTypes.oneOf(['blue', 'green']),
  bottom: PropTypes.number,
}

export { ScrollToElementIfOutsideViewport }
