import React from 'react'

import { useCallback, useMemo } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { formatDateToBackend } from 'helpers/date'
import { urlTo } from 'helpers/router'

import { TransactionTypeFilterValue } from 'app/pages/Dashboard/Transactions/hooks/useFilters'

import { PointOfInterestCard, type PointOfInterestCardProps } from '../Card'

import {
  SecuritySlicePointOfInterestTypes,
  SecuritySliceBuySellPointOfInterestContent,
  SecuritySliceCombinedPointOfInterestContent,
  type SecuritySlicePointOfInterest,
  type BuySellSecuritySlicePointOfInterest,
  type CombinedSecuritySlicePointOfInterest,
} from './index'

type SecuritySlicePointOfInterestCardProps = Omit<PointOfInterestCardProps, 'renderContent' | 'width'> & {
  portfolioId?: number
  securityId?: number
}

function SecuritySlicePointOfInterestCard(props: SecuritySlicePointOfInterestCardProps): React.ReactElement | null {
  const { portfolioId, securityId } = props
  const pointOfInterest = useMemo(() => props.pointOfInterest ?? {}, [props.pointOfInterest]) as
    | SecuritySlicePointOfInterest
    | Record<string, undefined>
  const { type, date, dateFrom: dateFromRaw, dateTo: dateToRaw } = pointOfInterest

  const linkToTransactions = useMemo(() => {
    if (!type || !date || !portfolioId || !securityId) {
      return null
    }

    let transactionType
    let dateFrom = dateFromRaw
    let dateTo = dateToRaw

    if (type !== SecuritySlicePointOfInterestTypes.COMBINED) {
      transactionType =
        type === SecuritySlicePointOfInterestTypes.BUY
          ? TransactionTypeFilterValue.BUY
          : TransactionTypeFilterValue.SELL
      dateFrom = date
      dateTo = date
    }

    return urlTo(
      'dashboard.transactions',
      null,
      querystringFromObject({
        types: transactionType,
        date: 'custom',
        date_from: formatDateToBackend(dateFrom),
        date_to: formatDateToBackend(dateTo),
        portfolio_id: portfolioId,
        security_id: securityId,
      }),
    )
  }, [type, date, dateFromRaw, dateToRaw, portfolioId, securityId])

  const width = useMemo(() => {
    if (type === SecuritySlicePointOfInterestTypes.COMBINED) {
      return 158
    }

    return 182
  }, [type])

  const renderContent = useCallback(() => {
    if (!type) {
      return null
    }

    if (type === SecuritySlicePointOfInterestTypes.COMBINED) {
      return (
        <SecuritySliceCombinedPointOfInterestContent
          {...(pointOfInterest as CombinedSecuritySlicePointOfInterest)}
          linkToTransactions={linkToTransactions}
        />
      )
    }

    return (
      <SecuritySliceBuySellPointOfInterestContent
        {...(pointOfInterest as BuySellSecuritySlicePointOfInterest)}
        linkToTransactions={linkToTransactions}
      />
    )
  }, [type, pointOfInterest, linkToTransactions])

  return <PointOfInterestCard {...props} width={width} renderContent={renderContent} />
}

export { SecuritySlicePointOfInterestCard, type SecuritySlicePointOfInterestCardProps }
