import React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMediaQueries, useMemo } from 'hooks'

import { Typo } from 'components/_old/Typo'

import { AnimatedVisibilityToggle } from 'components/atoms/AnimatedVisibilityToggle'
import { Relative } from 'components/atoms/Relative'
import { Typography } from 'components/atoms/Typography'

import './Title.css'

type TitleProps = {
  open: boolean
}

function Title({ open }: TitleProps): React.ReactElement {
  const mediaQueries = useMediaQueries()
  const classes = useMemo(
    () => classNames('ClaimBonusTitle', getMediaQuieryClasses('ClaimBonusTitle', mediaQueries)),
    [mediaQueries],
  )

  const typographyProps = useMemo(
    () => ({
      font: 'Poppins',
      size: 36,
      lineHeight: 'small',
      weight: 'semibold',
      align: 'center',
    }),
    [],
  )

  return (
    <Relative className={classes}>
      <AnimatedVisibilityToggle className="ClaimBonusTitle-Closed" visible={!open}>
        <Typography {...typographyProps}>
          <Typo>Claim your Welcome&nbsp;Bonus</Typo>
        </Typography>
      </AnimatedVisibilityToggle>
      <AnimatedVisibilityToggle
        className={classNames('ClaimBonusTitle-Open', open && 'ClaimBonusTitle-Open_animated')}
        visible={open}
      >
        <Typography {...typographyProps}>
          <Typo>
            Congratulations!
            <br />
            Your bonus is:
          </Typo>
        </Typography>
      </AnimatedVisibilityToggle>
    </Relative>
  )
}

export { Title, type TitleProps }
