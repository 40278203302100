import React from 'react'
import { withRouter } from 'react-router'

import { urlTo } from 'helpers/router.js'

import Link from 'components/_old/Link/Link.jsx'
import Header from 'components/_old/Header/Header.jsx'
import Tabs, { Tab } from 'components/molecules/Tabs'

import { useHeader } from './useHeader.js'

const DesktopHeader = withRouter(({ routes, activeItem }) => {
  const { menuItems, owner } = useHeader(routes, activeItem)

  const menu = (
    <Tabs>
      {menuItems.map((item, index) => (
        <Tab active={item.active} key={index}>
          <Link
            to={item.link}
            hard={item.hard}
            blank={item.hard}
            onClick={item.action}
            mods={{ color: 'lightgrayToBlack' }}
            analyticsEvent={item.analyticsEvent}
            data-test-id={item['data-test-id']}
          >
            {item.name}
          </Link>
        </Tab>
      ))}
    </Tabs>
  )

  return (
    <Header greetings={owner.getDisplayName()} owner={owner} menu={menu} logoLink={urlTo('dashboard')} withShadow />
  )
})

export { DesktopHeader }
