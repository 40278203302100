/* eslint-disable @typescript-eslint/naming-convention */
import filter from 'lodash/filter'
import isUndefined from 'lodash/isUndefined'
import pick from 'lodash/pick'
import uniq from 'lodash/uniq'

import axios from 'helpers/ajax'
import { identify } from 'helpers/analytics'
import { sendError } from 'helpers/errorLogging.js'
import { ValidationError } from 'helpers/errors'
import localstore from 'helpers/localstore.js'
import waitForAuth from 'helpers/waitForAuth.js'

import { fetchPortfolioPointOfInterestsFx } from 'app/effector/pointsOfInterest'

import { showFailToast, receiveToast } from 'app/redux/actions/ui'
import * as api from 'app/redux/api/portfolio.js'
import { ApiError } from 'app/redux/models/errors'
import { selectGoalById as selectPortfolioById, getSortedRegulatoryTypes } from 'app/redux/selectors'
import store from 'app/redux/store/store.js'

import {
  SET_VALID,
  SET_NOT_VALID,
  RECEIVE_ERROR,
  RESET_ERROR,
  RECEIVE_PORTFOLIO,
  RECEIVE_PORTFOLIOS,
  REMOVE_PORTFOLIO,
  REMOVE_PORTFOLIOS,
  SELECT_GOAL,
  CHANGE_FIELD,
  RECEIVE_PRESETS,
  REMOVE_GOALS,
} from './portfoliosActionTypes.js'

import { presetTypes, states as goalStates } from 'constants/goal'
import querystring from 'querystring'

/**
 * @return {{ type: string }}
 */
export function setNotValid() {
  return { type: SET_NOT_VALID }
}

/**
 * @return {{ type: string }}
 */
export function setValid() {
  return { type: SET_VALID }
}

/**
 * @param {Error} error
 * @return {{ type: string, error: Error }}
 */
export function receiveError(error) {
  sendError(error)
  return { type: RECEIVE_ERROR, error }
}

/**
 * @return {{ type: string }}
 */
export function resetError() {
  return { type: RESET_ERROR }
}

export function selectGoal(id) {
  return { type: SELECT_GOAL, id }
}

export function removeGoals() {
  return { type: REMOVE_GOALS }
}

export function resetGoals(setValidAfter = true) {
  return (dispatch, getState) => {
    dispatch(setNotValid())
    dispatch(selectGoal(null))
    dispatch(removeGoals())
    dispatch(setValid())
  }
}

export function receivePresets(presets, presetType) {
  return { type: RECEIVE_PRESETS, presets, presetType }
}

/**
 * @param {string} type
 * @param {object} [options={}]
 * @param {boolean} [options.setNotValidBefore=true]
 * @param {boolean} [options.setValidAfter=true]
 * @return {function(*=, *=): Promise<unknown>}
 */
export function fetchPresets(type, { setNotValidBefore = true, setValidAfter = true } = {}) {
  return async (dispatch, getState) => {
    return await new Promise((resolve) => {
      waitForAuth().then(() => {
        if (setNotValidBefore) {
          dispatch(setNotValid())
        }

        return axios
          .get(`portfolio/set/?type=${type}`)
          .then((response) => {
            dispatch(receivePresets(response.data, type))

            if (setValidAfter) {
              dispatch(setValid())
            }

            resolve(getState())
          })
          .catch((error) => {
            dispatch(receiveError(error))
            dispatch(setValid())

            resolve(getState())
          })
      })
    })
  }
}

/**
 * @param {Object} portfolio
 * @return {{ type: string, portfolio: Object }}
 */
export function receivePortfolio(portfolio) {
  return {
    type: RECEIVE_PORTFOLIO,
    portfolio,
  }
}

/**
 * @param {Array.<Object>} portfolios
 * @return {{ portfolios: Array.<Object>, type: string }}
 */
export function receivePortfolios(portfolios) {
  return {
    type: RECEIVE_PORTFOLIOS,
    portfolios,
  }
}

/**
 * @param {number} portfolioId
 * @return {{ type: string, portfolioId: number }}
 */
export function removePortfolio(portfolioId) {
  return {
    type: REMOVE_PORTFOLIO,
    portfolioId,
  }
}

/**
 * @return {{ type: string }}
 */
export function removePortfolios() {
  return {
    type: REMOVE_PORTFOLIOS,
  }
}

/**
 * @param {object} [options={}]
 * @param {boolean} [options.setNotValidBefore=true]
 * @param {boolean} [options.setValidAfter=true]
 * @return {function(*, *): Promise<*|ApiError>}
 */
export function fetchPortfolios({ setNotValidBefore = true, setValidAfter = true } = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(resetError())

      if (setNotValidBefore) {
        dispatch(setNotValid())
      }

      const data = await api.getPortfolios()

      if (data instanceof ApiError) {
        throw data
      }

      dispatch(receivePortfolios(data))

      const presetTypes = uniq(data.map((goal) => goal.preset_type).filter((value) => value)) // DIY portfolio without preset
      await Promise.all(presetTypes.map((type) => dispatch(fetchPresets(type, { setNotValidBefore, setValidAfter }))))

      if (setValidAfter) {
        dispatch(setValid())
      }

      return getState()
    } catch (error) {
      dispatch(receiveError(error))
      if (`${error.response?.status}`[0] === '5') {
        dispatch(
          receiveToast({
            message: 'Something went wrong',
            style: 'fail',
            uid: 0,
          }),
        )
      }
      if (setValidAfter) {
        dispatch(setValid())
      }

      return getState()
    }
  }
}

/**
 * @param {number} id
 * @param {object} [options={}]
 * @param {boolean} [options.setNotValidBefore=true]
 * @param {boolean} [options.setValidAfter=true]
 * @return {function(*, *): Promise<*|ApiError>}
 */
export function fetchPortfolio(id, { setNotValidBefore = true, setValidAfter = true } = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(resetError())

      if (setNotValidBefore) {
        dispatch(setNotValid())
      }

      const data = await api.getPortfolio(id)
      const clientId = store.getState().client.id
      const first_payment = store.getState().portfolios.items.every((portfolio) => !portfolio.first_topup)

      if (data instanceof ApiError) {
        throw data
      }

      dispatch(receivePortfolio(data))
      identify(clientId, { clientId, first_payment })

      if (setValidAfter) {
        dispatch(setValid())
      }

      return getState()
    } catch (error) {
      dispatch(receiveError(error))

      if (setValidAfter) {
        dispatch(setValid())
      }

      return getState()
    }
  }
}

/**
 * @param {number} id
 * @param {Object} dataToPatch
 * @param {Object} [options={}]
 * @param {boolean} [options.setNotValidBefore=true]
 * @param {boolean} [options.setValidAfter=true]
 * @return {function(*, *): Promise<*|ApiError>}
 */
export function patchPortfolio(id, dataToPatch, { setNotValidBefore = true, setValidAfter = true } = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(resetError())

      if (setNotValidBefore) {
        dispatch(setNotValid())
      }

      const data = await api.patchPortfolio(id, dataToPatch)

      if (data instanceof ApiError) {
        throw data
      }

      dispatch(receivePortfolio(data))

      if (setValidAfter) {
        dispatch(setValid())
      }

      return getState()
    } catch (error) {
      dispatch(receiveError(error))

      if (setValidAfter) {
        dispatch(setValid())
      }

      return getState()
    }
  }
}

/**
 * @param {number} id
 * @param {Object} [options={}]
 * @param {boolean} [options.setNotValidBefore=true]
 * @param {boolean} [options.setValidAfter=true]
 * @return {function(*, *): Promise<*|ApiError>}
 */
export function deletePortfolio(id, { setNotValidBefore = true, setValidAfter = true } = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(resetError())

      if (setNotValidBefore) {
        dispatch(setNotValid())
      }

      const errorOrData = await api.deletePortfolio(id)

      if (errorOrData instanceof ApiError) {
        throw errorOrData
      }

      dispatch(removePortfolio(id))

      if (setValidAfter) {
        dispatch(setValid())
      }

      return getState()
    } catch (error) {
      dispatch(receiveError(error))

      if (setValidAfter) {
        dispatch(setValid())
      }

      return getState()
    }
  }
}

export function sharePortfolio({ portfolioId, isEnabled }) {
  return async (dispatch, getState) => {
    try {
      const result = await api.sharePortfolio({ id: portfolioId, isEnabled })

      if (result instanceof ApiError) {
        throw result
      }

      const portfolio = selectPortfolioById(getState(), parseInt(portfolioId, 10))

      const updatedPortfolio = {
        ...portfolio,
        share_url: result.share_url,
      }

      dispatch(receivePortfolio(updatedPortfolio))

      return result.share_url
    } catch (error) {
      dispatch(receiveError(error))
      showFailToast()
    }
  }
}

export function closeIsaPanel(portfolioId) {
  return async (dispatch, getState) => {
    try {
      const result = await api.closeIsaPanel(portfolioId)

      if (result instanceof ApiError) {
        throw result
      }

      const portfolio = selectPortfolioById(getState(), parseInt(portfolioId, 10))

      const updatedPortfolio = {
        ...portfolio,
        isa: {
          ...portfolio.isa,
          transferring_in_process: false,
        },
      }

      dispatch(receivePortfolio(updatedPortfolio))
    } catch (error) {
      dispatch(receiveError(error))
      showFailToast()
    }
  }
}

export function changePortfolioPosition({ portfolioId, direction }) {
  return async (dispatch, getState) => {
    const portfolio = selectPortfolioById(getState(), parseInt(portfolioId, 10))

    const { portfolios } = getState()
    const groupedPortfolios = portfolios.list.groupByRegulatoryType()
    const groupedVisiblePortfolios = portfolios.list.getVisiblePortfolios().groupByRegulatoryType()

    const currentOrder = groupedVisiblePortfolios[portfolio.regulatory_type].map((portfolio) => portfolio.id)

    // any position change is a swap of two elements
    const newOrder = [...currentOrder]
    const index = currentOrder.indexOf(portfolioId)
    const indexToSwap = direction === 'up' ? index - 1 : index + 1

    newOrder[index] = currentOrder[indexToSwap]
    newOrder[indexToSwap] = portfolioId

    const portfoliosNewPositionData = newOrder.reduce((result, portfolioId, index) => {
      result[portfolioId] = { position: index }
      return result
    }, {})

    try {
      const result = await api.changeDisplaySettings({ portfolios: portfoliosNewPositionData })

      if (result instanceof ApiError) {
        throw result
      }

      // set new positions to all portfolios in the group
      groupedPortfolios[portfolio.regulatory_type] = groupedPortfolios[portfolio.regulatory_type].map((portfolio) => {
        return {
          ...portfolio,
          display: {
            ...portfolio.display,
            position:
              typeof portfoliosNewPositionData[portfolio.id]?.position === 'number'
                ? portfoliosNewPositionData[portfolio.id]?.position
                : null,
          },
        }
      })

      const updatedPortfoliosList = Object.values(groupedPortfolios).flat()

      dispatch(receivePortfolios(updatedPortfoliosList))
    } catch (error) {
      showFailToast()
      return error
    }
  }
}

export function changeRegulatoryTypePosition({ regulatoryType, direction }) {
  return async (dispatch, getState) => {
    const regulatoryTypesOrder = getSortedRegulatoryTypes(getState())
    const { portfolios } = getState()
    const groupedPortfolios = portfolios.list.groupByRegulatoryType()

    const index = regulatoryTypesOrder.indexOf(regulatoryType)
    const indexToSwap = direction === 'up' ? index - 1 : index + 1

    // any position change is a swap of two elements
    const newOrder = [...regulatoryTypesOrder]
    newOrder[index] = regulatoryTypesOrder[indexToSwap]
    newOrder[indexToSwap] = regulatoryType

    const regulatoryTypesNewPositionData = newOrder.reduce((result, regulatoryType, index) => {
      result[regulatoryType] = { position: index }
      return result
    }, {})

    try {
      const result = await api.changeDisplaySettings({ regulatoryTypes: regulatoryTypesNewPositionData })

      if (result instanceof ApiError) {
        throw result
      }

      // apply new positions to every portfolio in each regulatory type group
      const updatedPortfoliosList = newOrder
        .map((regulatoryType, index) => {
          return (groupedPortfolios[regulatoryType] || []).map((portfolio) => ({
            ...portfolio,
            display: {
              ...portfolio.display,
              regulatory_type_position: index,
            },
          }))
        })
        .flat()

      dispatch(receivePortfolios(updatedPortfoliosList))
    } catch (error) {
      showFailToast()
      return error
    }
  }
}

export function changePortfolioHiddenSetting({ portfolioId, isHidden }) {
  return async (dispatch, getState) => {
    const portfolio = selectPortfolioById(getState(), parseInt(portfolioId, 10))

    const updatedDisplaySettings = { [portfolioId]: { is_hidden: isHidden, position: null } }

    try {
      const result = await api.changeDisplaySettings({ portfolios: updatedDisplaySettings })

      if (result instanceof ApiError) {
        throw result
      }

      const updatedPortfolio = {
        ...portfolio,
        display: {
          ...portfolio.display,
          is_hidden: isHidden,
        },
      }

      dispatch(receivePortfolio(updatedPortfolio))
    } catch (error) {
      showFailToast()
      return error
    }
  }
}

export function changeField(field, id = null) {
  return (dispatch, getState) => {
    try {
      dispatch(resetError())
      dispatch({ type: CHANGE_FIELD, field, id })
      return getState()
    } catch (error) {
      dispatch(receiveError(error))
      return error
    }
  }
}

export function fetchGoal(id, { setNotValidBefore = true, setValidAfter = true } = {}) {
  return (dispatch, getState) => {
    const { access_token } = getState().client

    if (setNotValidBefore) {
      dispatch(setNotValid())
    }

    if (!access_token) {
      return new Promise((resolve) => {
        localstore.remove('goal')
        dispatch(changeField({ id: null }, id))
        resolve(getState())
        if (setValidAfter) {
          dispatch(setValid())
        }
      })
    } else {
      return axios
        .get(`portfolios/${id}/`)
        .then((response) => {
          dispatch(receivePortfolio(response.data))
          if (response.data.preset_type) {
            dispatch(fetchPresets(response.data.preset_type, { setNotValidBefore, setValidAfter }))
          }
        })
        .then(() => {
          if (setValidAfter) {
            dispatch(setValid())
          }
        })
        .catch((error) => {
          dispatch(setValid())
          dispatch(receiveError(error))
        })
        .then(() => getState())
    }
  }
}

export function fetchRecommended(term, id) {
  return (dispatch, getState) => {
    let err = null

    return axios
      .get(`portfolios/recommended/${id}/${term}/`)
      .then((response) => {
        const { data } = response
        dispatch(changeField(data, Number(id)))
      })
      .catch((error) => {
        err = error
        dispatch(receiveError(error))
      })
      .then(() => [getState(), err])
  }
}

export function fetchDIYHistoryByPortfolio(id) {
  return async (dispatch, getState) => {
    try {
      const [performance, contributions, dividends] = await Promise.all([
        axios.get(`history/performance/${id}/`),
        axios.get(`history/contributions/${id}/`),
        axios.get(`history/income/${id}/`),
        fetchPortfolioPointOfInterestsFx({ portfolioId: id }),
      ])

      dispatch(changeField({ history_performance: performance.data }, id))
      dispatch(changeField({ history_contributions: contributions.data }, id))
      dispatch(changeField({ dividends: dividends.data }, id))

      return getState()
    } catch (error) {
      dispatch(receiveError(error))
    }
  }
}

export function fetchHistoryByGoal(id, presetType) {
  return async (dispatch, getState) => {
    let fetchingPromises = []

    if (presetType === presetTypes.GROWTH) {
      fetchingPromises = [
        axios
          .get(`history/performance/${id}/`)
          .then((response) => dispatch(changeField({ history_performance: response.data }, id))),
        axios
          .get(`history/contributions/${id}/`)
          .then((response) => dispatch(changeField({ history_contributions: response.data }, id))),
        fetchPortfolioPointOfInterestsFx({ portfolioId: id }),
      ]
    }
    if (presetType === presetTypes.INCOME) {
      fetchingPromises = [
        axios
          .get(`history/income/${id}/`)
          .then((response) => dispatch(changeField({ history_income: response.data }, id))),
        axios.get(`history/twr/${id}/`).then((response) => dispatch(changeField({ history_twr: response.data }, id))),
      ]
    }

    return await Promise.all(fetchingPromises)
      .catch((error) => {
        dispatch(receiveError(error))
      })
      .then(() => getState())
  }
}

export function fetchHistoryForAllGoals(setValidAfter = true) {
  return async (dispatch, getState) => {
    dispatch(setNotValid())

    const goals = getState().portfolios.items.filter((goal) => goal.state !== goalStates.NEW)
    const fetchingPromises = goals.map((goal) => dispatch(fetchHistoryByGoal(goal.id, goal.preset_type)))

    return await Promise.all(fetchingPromises).then(() => {
      if (setValidAfter) {
        dispatch(setValid())
      }

      return getState()
    })
  }
}

export function updateOrCreate(keys = [], data = {}, select = false, goal_id = undefined, setValidAfter = true) {
  return (dispatch, getState) => {
    const { selectedGoal, items } = getState().portfolios

    if (isUndefined(goal_id) && selectedGoal) {
      goal_id = selectedGoal
    }

    data = { ...filter(items, { id: goal_id })[0], ...data }
    data = { id: goal_id ?? null, ...data }

    // TODO: remove it after refactor deleting of preset_changed
    // HACK: preset_changed is buffer variable for using in projections
    // preset_changed not supported api
    if (keys.includes('preset_changed') && data.preset_changed && data.preset !== data.preset_changed) {
      data.preset = data.preset_changed
      keys = keys.filter((key) => key !== 'preset_changed')
    }

    // remove always property preset_changed
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('preset_changed')) {
      delete data.preset_changed
    }

    if (keys) {
      data = pick(data, keys)
    }

    const method = goal_id ? 'PATCH' : 'POST'
    const url = `portfolios/${goal_id ? String(goal_id) + '/' : ''}`

    dispatch(setNotValid())
    return axios(url, { method, data })
      .then((response) => {
        const { data } = response
        dispatch(receivePortfolio(data))
        if (select) {
          dispatch(selectGoal(data.id))
        }
        if (setValidAfter) {
          dispatch(setValid())
        }
      })
      .catch((error) => {
        const extendedError = (() => {
          if (error.response && error.response.status === 400 && error.response.data) {
            return new ValidationError(error.message, error.response.data)
          }
          return error
        })()
        dispatch(receiveError(extendedError))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function closeGoal(id) {
  return (dispatch) => {
    dispatch(changeField({ state: goalStates.CLOSING }, id))
    return dispatch(updateOrCreate(['state'], null, null, id))
  }
}

export function addFundsWireTransfer(credit, goal_id) {
  return (dispatch, getState) => {
    const url = `topups/goal/${goal_id}`

    dispatch(resetError())
    dispatch(setNotValid())
    return axios(url, { method: 'POST', data: { credit } })
      .then(() => {
        dispatch(setValid())
      })
      .catch((error) => {
        dispatch(receiveError(error))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function deleteDirectDebitSubscription(subscriptionId) {
  return (dispatch, getState) => {
    dispatch(resetError())
    dispatch(setNotValid())
    return axios(`direct-debit/subscriptions/${subscriptionId}`, {
      method: 'DELETE',
    })
      .then(() => {
        dispatch(setValid())
      })
      .catch((error) => {
        dispatch(receiveError(error))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function createDirectDebitSubscription(
  goal_id,
  monthlyPaymentAmount,
  monthlyPaymentDay,
  directDebitSubscriptionId,
  enable_autoinvest = true,
) {
  return async (dispatch, getState) => {
    const requests = []

    if (monthlyPaymentAmount) {
      if (!directDebitSubscriptionId) {
        requests.push(
          axios('direct-debit/subscriptions/', {
            method: 'POST',
            data: {
              goal: goal_id,
              amount: monthlyPaymentAmount,
              day_of_month: monthlyPaymentDay,
              enable_autoinvest,
            },
          }),
        )
      } else {
        requests.push(
          axios(`direct-debit/subscriptions/${directDebitSubscriptionId}`, {
            method: 'PATCH',
            data: {
              amount: monthlyPaymentAmount,
              enable_autoinvest,
            },
          }),
        )
      }
    }

    dispatch(resetError())
    dispatch(setNotValid())
    return await Promise.all(requests)
      .then(() => {
        dispatch(setValid())
      })
      .catch((error) => {
        dispatch(receiveError(error))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function changeGoalMonthlyDepositToDirectDebit(goalId, callback) {
  return (dispatch, getState) => {
    const { monthlyPaymentAmount } = getState().ui.modals.directDebit

    if (monthlyPaymentAmount) {
      const goal = getState().portfolios.items.find((goal) => goal.id === goalId)
      const nextGoal = { ...goal, monthly_deposit: monthlyPaymentAmount }

      dispatch(changeField({ monthly_deposit: monthlyPaymentAmount }, goal.id))

      if (callback) {
        callback(nextGoal)
      }
    }
  }
}

export function withdrawFunds(debit, goal_id) {
  return (dispatch, getState) => {
    const url = `withdrawals/goal/${goal_id}`

    dispatch(setNotValid())
    return axios(url, { method: 'POST', data: { debit } })
      .then(() => {
        dispatch(setValid())
      })
      .catch((error) => {
        dispatch(receiveError(error))
        dispatch(setValid())
      })
      .then(() => getState())
  }
}

export function receiveProjections(goal_id, projections) {
  return (dispatch, getState) => {
    if (getState().portfolios.items.find((goal) => goal.id === goal_id)) {
      dispatch(changeField({ projections }, goal_id))
    }
  }
}

export function setProjectionsNotValid(goal_id) {
  return (dispatch, getState) => {
    if (getState().portfolios.items.find((goal) => goal.id === goal_id)) {
      dispatch(changeField({ projections_fetched: false }, goal_id))
    }
  }
}

export function setProjectionsValid(goal_id) {
  return (dispatch, getState) => {
    if (getState().portfolios.items.find((goal) => goal.id === goal_id)) {
      dispatch(changeField({ projections_fetched: true }, goal_id))
    }
  }
}

export function fetchProjectionsGrowth(
  goalId,
  preset,
  initialDeposit = 0,
  monthlyDeposit = 0,
  term = undefined,
  monthly = false,
) {
  return async (dispatch, getState) => {
    return await new Promise((resolve, reject) => {
      waitForAuth().then(() => {
        dispatch(setProjectionsNotValid(goalId))
        const data = querystring.stringify({
          preset,
          initial_deposit: initialDeposit,
          monthly_deposit: monthlyDeposit,
          term,
          monthly,
        })

        return axios
          .get(`projections/growth/?${data}`)
          .then((response) => {
            const { data } = response
            dispatch(receiveProjections(goalId, data))
            dispatch(setProjectionsValid(goalId))
            resolve(getState())
          })
          .catch((error) => {
            dispatch(receiveError(error))
            dispatch(setProjectionsValid(goalId))
            resolve(getState())
          })
      })
    })
  }
}

export function fetchProjectionsIncome(goalId, preset, initialDeposit = 0, qty = 12) {
  return async (dispatch, getState) => {
    return await new Promise((resolve, reject) => {
      waitForAuth().then(async () => {
        dispatch(setProjectionsNotValid(goalId))

        const data = querystring.stringify({
          initial_deposit: initialDeposit,
          preset,
          qty,
        })

        await Promise.all([
          axios.get(`projections/income/past/?${data}`).then(({ data }) => data),
          axios.get(`projections/income/future/?${data}`).then(({ data }) => data),
        ])
          .then((result) => {
            const [past, future] = result
            dispatch(receiveProjections(goalId, { past, future }))
            dispatch(setProjectionsValid(goalId))
            resolve(getState())
          })
          .catch((error) => {
            dispatch(receiveError(error))
            dispatch(setProjectionsValid(goalId))
            resolve(getState())
          })
      })
    })
  }
}
