import React, { Fragment, useMemo } from 'react'

import Link from 'components/_old/Link/Link.jsx'

import ConnectedList from 'components/atoms/ConnectedList/ConnectedList'

import { useStrapiBonuses } from 'app/pages/Dashboard/PromoBanner/hooks/useStrapiBonuses'

const List = (): React.ReactElement => {
  const { bonuses } = useStrapiBonuses()

  const LIST_ITEMS = useMemo(() => {
    const termsLink = '/terms-incentives/'

    if (!bonuses) {
      return [
        "Share your unique referral link with your friend and ask them to create an account by clicking 'Get started'",
        `When your friend successfully invests at least £100, we'll send you a notification to claim your bonus`,
        <Fragment key="bonus-details">
          Bonus amounts will be randomly selected. Please check the{' '}
          <Link to={termsLink} blank hard rel="noopener noreferrer">
            Ts&Cs
          </Link>{' '}
          for full details
        </Fragment>,
        'Remain invested for a minimum of 12 months to keep your bonus',
        'Refer up to 25 of your own friends or businesses for the chance to earn more!',
      ]
    }

    const {
      referIsaOrGeneralBonusMin,
      referIsaOrGeneralBonusMax,
      referFriendOrBusinessBonusMin,
      referFriendOrBusinessBonusMax,
    } = bonuses

    return [
      'Share your unique referral link with your friend and ask them to create an account by clicking "Get started"',
      `When your friend successfully invests at least £100, we'll send you a notification to claim your bonus of between ${referIsaOrGeneralBonusMin} & ${referIsaOrGeneralBonusMax}`,
      `Or if your friend invests via a Business Account, you could earn between ${referFriendOrBusinessBonusMin} & ${referFriendOrBusinessBonusMax}`,
      <Fragment key="bonus-details">
        Bonus amounts will be randomly selected. Please check the{' '}
        <Link to={termsLink} blank hard rel="noopener noreferrer">
          Ts&Cs
        </Link>{' '}
        for full details
      </Fragment>,
      'Remain invested for a minimum of 12 months to keep your bonus',
      'Refer up to 25 of your own friends or businesses for the chance to earn more!',
    ]
  }, [bonuses])

  return <ConnectedList items={LIST_ITEMS} />
}

export { List }
