import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useMediaQueries, useMemo } from 'hooks'
import { useSell } from 'app/pages/Dashboard/Goals/DIY/BuyAndSell/hooks'

import { Preloader } from 'components/molecules/Preloader'
import { GatewayDest } from 'components/atoms/Gateway'

import { SellDesktop as Desktop } from './Desktop'
import { SellMobile as Mobile } from './Mobile'
import { useSecurityBuySell } from './hooks'

const SellSecurity = ({ params }) => {
  const { id: portfolioIdString, securityId: securityIdString } = params || {}

  const { desktop } = useMediaQueries()

  const portfolioId = useMemo(() => parseInt(portfolioIdString, 10), [portfolioIdString])
  const securityId = useMemo(() => parseInt(securityIdString, 10), [securityIdString])

  const {
    isLoading: isSecurityLoading,
    security,
    portfolio,
    handleBuy,
    handleBack,
    handleReview,
  } = useSecurityBuySell({ portfolioId, securityId })

  const {
    isLoading: isBuyLoading,
    isValid,
    errors,
    control,
    validation,
    sell: handleSubmit,
    handleSetAllValue,
  } = useSell({
    portfolio,
    security,
    onAfterSell: (orderId) => handleReview(orderId, 'security-sell'),
  })

  const isLoading = isBuyLoading || isSecurityLoading

  const props = {
    security,
    isValid,
    validation,
    errors,
    control,

    handleBuy,
    handleBack,
    handleSetAllValue,
  }

  if (!security) {
    return null
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>{desktop ? <Desktop {...props} /> : <Mobile {...props} />}</form>
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
      <Preloader loading={isLoading} size="big" zIndex />
    </Fragment>
  )
}

SellSecurity.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
    securityId: PropTypes.string,
  }),
}

export { SellSecurity }
