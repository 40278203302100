import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useActions, useCallback, useEffect, useRef, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import moment from 'helpers/date.js'
import { goTo, urlTo } from 'helpers/router'

import {
  fetchRecurringPaymentsFx,
  $recurringPaymentsStore,
  type RecurringPaymentSubscription,
} from 'app/effector/recurringPayments'
import { openReviewModal } from 'app/effector/review'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'
import { getRecurringMandate } from 'app/redux/api/portfolio'
import type { Portfolio } from 'app/redux/models/portfolio/types'

import { manageTypes } from 'constants/portfolio'

const fetchRecurringPaymentsOnRecurringPaymentProcessingFx = attach({ effect: fetchRecurringPaymentsFx })

type UseRecurringPaymentProcessingProps = {
  portfolio: Portfolio
  location: {
    query?: {
      status?: string
      mandate_id?: string
    }
  }
  handleClose: () => void
}

type UseRecurringPaymentProcessingReturnProps = {
  status?: string
  isDiyPortfolio: boolean
  handleContinue: () => void
  recurringPayment?: RecurringPaymentSubscription
  showMandateDetails: boolean
  openMandateDetails: () => void
  closeMandateDetails: () => void
  handleCloseWrapper: () => void
}

const useRecurringPaymentProcessing = ({
  portfolio,
  location,
  handleClose,
}: UseRecurringPaymentProcessingProps): UseRecurringPaymentProcessingReturnProps => {
  const interval = useRef<NodeJS.Timeout | null>(null)
  const { status } = location?.query ?? {}

  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])

  const isPaymentProcessing = status !== 'successful' && status !== 'failed'
  const isDiyPortfolio = portfolio.manage_type === manageTypes.DIY

  const { getReccuringPaymentByPortfolioId } = useUnit($recurringPaymentsStore)
  const recurringPayment = getReccuringPaymentByPortfolioId(portfolio.id)

  const [showMandateDetails, setShowMandateDetails] = useState(false)

  const openMandateDetails = (): void => {
    setShowMandateDetails(true)
  }
  const closeMandateDetails = (): void => {
    setShowMandateDetails(false)
  }

  const navigateToReccuringPaymentProcessing = useCallback(
    (status) => {
      goTo(urlTo('dashboard.portfolio.options.recurring-payment-processing', { id: portfolio.id }, { status }))
    },
    [portfolio.id],
  )

  useEffect(() => {
    if (isPaymentProcessing && location.query?.mandate_id) {
      interval.current = setInterval(() => {
        getRecurringMandate(location.query?.mandate_id).then((data) => {
          if (data?.status === 'AUTHORIZED') {
            fetchPortfolios()

            trackEvent({
              action: 'sp_setup_success_popup',
              manage_type: portfolio?.manage_type,
              ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
              payment_method: 'OPEN_BANKING',
              portfolio_created_date: moment(portfolio?.created).format('YYYY-MM-DD'),
            })
            navigateToReccuringPaymentProcessing('successful')
            fetchRecurringPaymentsOnRecurringPaymentProcessingFx()
          }

          if (data?.status === 'FAILED' || data?.status === 'REVOKED' || data?.status === 'UNKNOWN') {
            navigateToReccuringPaymentProcessing('failed')
          }
        })
      }, 3000)
    }

    return () => {
      if (interval.current) clearInterval(interval.current)
    }
  }, [isPaymentProcessing, location, portfolio, navigateToReccuringPaymentProcessing, fetchPortfolios])

  const handleContinue = useCallback(() => {
    if (status === 'successful') {
      openReviewModal()
    }

    goTo(urlTo('dashboard.portfolio', { id: portfolio.id }))
  }, [portfolio, status])

  const handleCloseWrapper = (): void => {
    handleClose()
    if (status === 'successful') {
      openReviewModal()
    }
  }

  return {
    status,
    isDiyPortfolio,
    recurringPayment,
    showMandateDetails,
    openMandateDetails,
    closeMandateDetails,
    handleContinue,
    handleCloseWrapper,
  }
}

export { useRecurringPaymentProcessing }
