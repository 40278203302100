import { useCallback, useSelector } from 'hooks'

import { goTo, urlTo } from 'helpers/router'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { flowTypes, useFlowType } from 'app/pages/CreateNewPortfolio/SelectPortfolioType/hooks/flows'

import { regulatoryTypes } from 'constants/portfolio'

type UseLumpSumSetupProps = {
  portfolio: Portfolio
  securityId?: number
  location: {
    query?: {
      back?: string
      fromUniverse?: string
    }
  }
}

type UseLumpSumSetupReturnProps = {
  needToChooseRegulatoryType: boolean
  handleBack: () => void
  handleContinue: (data: { amount: string | null; regulatoryType?: string }) => void
}

const useLumpSumSetup = ({ portfolio, securityId, location }: UseLumpSumSetupProps): UseLumpSumSetupReturnProps => {
  const shouldSignSippDeclaration = useSelector((state) => !state.client.agreed_with_sipp_declaration)

  const { query } = location ?? {}
  const flowType = useFlowType()
  const isIsaOrSippAvailable = flowType === flowTypes.PERSONAL || flowType === flowTypes.PERSONAL_WITH_SIPP
  const needToChooseRegulatoryType = isIsaOrSippAvailable && query?.fromUniverse === 'true'

  const handlePortfolioQuickStart = useCallback(
    (params) => {
      goTo(
        urlTo(
          'dashboard.portfolio.finish',
          { id: portfolio?.id },
          { ...query, ...params, quickStart: true, securityId },
        ),
      )
    },
    [securityId, portfolio, query],
  )

  const handleContinue = useCallback(
    (params) => {
      if (params.regulatoryType === regulatoryTypes.SIPP && shouldSignSippDeclaration) {
        goTo(
          urlTo(
            'securities.security',
            { id: securityId },
            { ...query, ...params, openSipp: true, quickStart: true, securityId },
          ),
        )
        return
      }
      handlePortfolioQuickStart(params)
    },
    [handlePortfolioQuickStart, shouldSignSippDeclaration, securityId, query],
  )

  const handleBack = useCallback(() => {
    goTo(location.query?.back)
  }, [location])

  return {
    needToChooseRegulatoryType,
    handleBack,
    handleContinue,
  }
}

export { useLumpSumSetup }
