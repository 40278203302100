import * as React from 'react'
import { Fragment, type ReactElement } from 'react'

import { useMemo, useCallback, useState } from 'hooks'

import { palette } from 'helpers/palette/'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Card } from 'components/atoms/Card'
import { DonutChart } from 'components/atoms/DonutChart'
import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { TwoColumns } from 'components/molecules/TwoColumns'
import { VirtualList } from 'components/molecules/VirtualList'

import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'

import { Sticky } from 'app/pages/Dashboard/Goals/DIY/EditWeights/components/Sticky'
import { TunnelHeader } from 'app/pages/Securities/components'

import { type Analytics, type TabName } from '../types'

const itemTitles = {
  holdings: 'Equities',
  sectors: 'Sectors',
  regions: 'Regions',
}

type ReviewAnalyticsDesktopProps = {
  isPortfolioNew: boolean
  analytics: Analytics
  handleBack: () => void
  handleCancel: () => void
  openHoldingModal: (id: string, tab: string) => void
}

const Desktop = ({
  isPortfolioNew,
  analytics,
  handleBack,
  handleCancel,
  openHoldingModal,
}: ReviewAnalyticsDesktopProps): ReactElement => {
  const [layoutNode, setLayoutNode] = useState(null)
  const layoutRef = useCallback((ref) => {
    setLayoutNode(ref)
  }, [])
  const [activeTab, setActiveTab] = useState<TabName>('regions')

  const areItemsPresent = useMemo(() => !!analytics[activeTab].length, [analytics, activeTab])
  const renderNoEquities = useCallback(() => null, [])

  const header = useMemo(() => {
    return (
      <Inner twoColumns>
        <TunnelHeader onBack={handleBack} onCancel={handleCancel} />
      </Inner>
    )
  }, [handleBack, handleCancel])

  const renderEquity = useCallback(
    (listElement, items) => {
      const item = items[listElement.index]

      return (
        <Paper key={item.name} bottom={24}>
          <SelectableCard
            onClick={() => {
              openHoldingModal(item.id, activeTab.slice(0, -1))
            }}
          >
            <Paper top={16} bottom={16} left={16} right={16}>
              <SecurityConstituent
                logo={item.logo}
                name={item.name}
                actual={item.actual}
                target={item.target}
                currentTarget={item.currentTarget}
                showCurrentTarget={!isPortfolioNew}
                color={item.color || palette.stocks}
                showLogo={activeTab === 'holdings'}
                chartValue={isPortfolioNew ? item.actual : item.target}
              />
            </Paper>
          </SelectableCard>
        </Paper>
      )
    },
    [activeTab, openHoldingModal, isPortfolioNew],
  )

  const tabs = useMemo(
    () => [
      { title: 'Regions', id: 'regions', isActive: activeTab === 'regions' },
      { title: 'Sectors', id: 'sectors', isActive: activeTab === 'sectors' },
      { title: 'Holdings', id: 'holdings', isActive: activeTab === 'holdings' },
    ],
    [activeTab],
  )

  const listNode = useMemo(
    () => (
      <Fragment>
        {areItemsPresent && (
          <ColumnarLayout>
            {activeTab === 'holdings' && (
              <Column size={1}>
                <Typography tag="span" size={14} lineHeight="small" color="additional">
                  <Typo>{`${analytics[activeTab].length} ${activeTab}`}</Typo>
                </Typography>
              </Column>
            )}
            <Column size={1}>
              <Paper bottom={24}>
                <Typography lineHeight="small" align="right">
                  <Typography size={14} color="minor" tag="span" lineHeight="small">
                    {isPortfolioNew ? '' : 'Current target'}
                  </Typography>
                  <Typography size={14} tag="span" lineHeight="small">
                    {isPortfolioNew ? 'Target' : ' → New target'}
                  </Typography>
                </Typography>
              </Paper>
            </Column>
          </ColumnarLayout>
        )}

        <VirtualList
          scrollableElement={layoutNode}
          cacheKey="edit-weights-review-analytics-list-desktop"
          items={analytics[activeTab]}
          itemMinHeight={100}
          itemMaxHeight={120}
          renderItem={(element) => renderEquity(element, analytics[activeTab])}
          renderNoItems={renderNoEquities}
        />

        {!areItemsPresent && (
          <Paper top={80} bottom={80}>
            <AllCenter>
              <Typography color="minor">No equities in your portfolio</Typography>
            </AllCenter>
          </Paper>
        )}
      </Fragment>
    ),
    [areItemsPresent, activeTab, analytics, isPortfolioNew, layoutNode, renderEquity, renderNoEquities],
  )

  const chartNode = useMemo(
    () => (
      <Width size={19}>
        {activeTab !== 'holdings' && (
          <Card>
            <Paper top={24} bottom={24} left={24} right={24}>
              <AllCenter>
                <DonutChart
                  lineWidth={14}
                  values={analytics[activeTab].filter((item) => item.target).map((item) => [item.target, item.color])}
                >
                  <Paper top={12}>
                    <Typography size={14} align="center">
                      <Typo>{`${analytics[activeTab].filter((item) => item.target).length || ''} ${
                        itemTitles[activeTab]
                      }`}</Typo>
                    </Typography>
                  </Paper>
                </DonutChart>
              </AllCenter>
            </Paper>
          </Card>
        )}
      </Width>
    ),
    [analytics, activeTab],
  )

  const stickyFooter = useMemo(
    () => (
      <Sticky bottom>
        <div className="EditWeights_StickedArea">
          <Inner twoColumns>
            <TwoColumns
              content={
                <Paper top={20} bottom={64}>
                  <Button type="submit" mods={{ size: 'big block' }} data-test-id="editWeightsSaveButton">
                    Save and continue
                  </Button>
                </Paper>
              }
            />
          </Inner>
        </div>
      </Sticky>
    ),
    [],
  )

  const content = useMemo(
    () => (
      <Fragment>
        <Inner twoColumns>
          <Typography size={24} lineHeight="small">
            <Typo>Equity look-through</Typo>
          </Typography>
          <Paper top={40}>
            <GreyTabs
              tabs={tabs}
              onChange={(tab) => {
                setActiveTab(tab.id)
              }}
            />
          </Paper>
          <Paper top={40}>
            <ColumnarLayout mods={{ padding: 'no' }}>
              <Column size={1}>{listNode}</Column>

              <Column size={0}>
                <Paper left={80}>{chartNode}</Paper>
              </Column>
            </ColumnarLayout>
          </Paper>
        </Inner>
        {stickyFooter}
      </Fragment>
    ),
    [tabs, listNode, chartNode, stickyFooter],
  )

  return <DesktopLayout ref={layoutRef} header={header} content={content} fullSize />
}

export { Desktop }
