import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette/'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useRef, useImperativeHandle, useMediaQueries } from 'hooks'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { SecurityLogo } from 'components/atoms/SecurityLogo'
import { Ticker } from 'components/atoms/Ticker'
import { Typo } from 'components/_old/Typo/Typo'
import { Typography, propTypes as TypographyPropTypes } from 'components/atoms/Typography'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'

import './SecurityTitle.css'

const SecurityTitle = (
  {
    title,
    ticker,
    logo,
    description,
    isFeatured,
    iconPosition = 'right',
    size,
    space = 24,
    lineHeight,
    overflow,
    'data-test-id': dataTestId,
  },
  ref,
) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries
  const contentRef = useRef()
  const titleRef = useRef()
  const tickerRef = useRef()
  const logoRef = useRef()

  useImperativeHandle(ref, () => ({
    content: contentRef?.current,
    title: titleRef?.current,
    ticker: tickerRef?.current,
    logo: logoRef?.current,
  }))

  const classes = classNames(
    'SecurityTitle',
    {
      [`SecurityTitle_overflow_${overflow}`]: overflow,
    },
    getMediaQuieryClasses('SecurityTitle', mediaQueries),
  )

  const hasLabels = ticker || isFeatured

  return (
    <ItemWithIcon
      className={classes}
      space={space}
      content={
        <div ref={contentRef}>
          <Typography
            className="SecurityTitle-Title"
            ref={titleRef}
            size={size}
            lineHeight={lineHeight}
            weight="semibold"
            data-test-id={dataTestId ?? 'securityTitle'}
          >
            <Typo>{title}</Typo>
          </Typography>
          {description && (
            <Typography color="additional">
              <Typo>{description}</Typo>
            </Typography>
          )}
          <Paper top={hasLabels ? (desktop ? 8 : 4) : null}>
            <ColumnarLayout mods={{ padding: 'no', 'align-columns': 'center' }}>
              {ticker && (
                <Column size={0}>
                  <Paper right={desktop ? 12 : 8}>
                    <Ticker ref={tickerRef} text={ticker} data-test-id="securityTicker" />
                  </Paper>
                </Column>
              )}
              {isFeatured && (
                <Column size={0}>
                  <ItemWithIcon
                    icon={
                      <Link to="https://blog.investengine.com/featured-partnerships/" hard blank>
                        <Icon type="information-16" size={16} color={palette['content-on-background-minor']} />
                      </Link>
                    }
                    content={
                      <Typography size={14} lineHeight="small" color="additional">
                        Featured
                      </Typography>
                    }
                    space={4}
                    iconPosition="right"
                    iconVerticalAlign="center"
                  />
                </Column>
              )}
            </ColumnarLayout>
          </Paper>
        </div>
      }
      icon={<SecurityLogo ref={logoRef} logo={logo} size={desktop ? 48 : 36} />}
      iconPosition={iconPosition}
    />
  )
}

const SecurityTitleWithForwardedRef = forwardRef(SecurityTitle)

SecurityTitle.propTypes = {
  title: PropTypes.string,
  ticker: PropTypes.string,
  logo: PropTypes.string,
  description: PropTypes.string,
  isFeatured: PropTypes.bool,
  space: PropTypes.number,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  size: TypographyPropTypes.size,
  lineHeight: TypographyPropTypes.lineHeight,
  overflow: PropTypes.oneOf(['ellipsis']),
  'data-test-id': PropTypes.string,
}

export { SecurityTitleWithForwardedRef as SecurityTitle }
