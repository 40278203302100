import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { useMediaQueries, useContext } from 'hooks'
import { useUnit } from 'effector-react'

import rawMediaQueries from 'helpers/mediaQueries.js'
import { urlTo, convertToSlug } from 'helpers/router.js'
import compose from 'helpers/compose.js'
import { getKindByGoal, getActiveGoals } from 'helpers/goals.js'

import { types as clientTypes } from 'constants/client'

import { selectSelectedGoal } from 'app/redux/selectors'

import withMediaQueries from 'decorators/withMediaQueries/withMediaQueries.jsx'

import Text from 'components/_old/Text/Text.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Button from 'components/_old/Button/Button.jsx'

import { Questionnaire } from 'app/containers/Questionnaire'
import { StepContext } from 'app/containers/PortfolioTunnel/StepProvider'

import FourOhFour from 'app/pages/Error/404.jsx'

import HeadlineHelp from './HeadlineHelp/HeadlineHelp.jsx'

import { $owner } from 'app/effector/contacts'

const Tunnel = ({ parentModule, progressFill, client, goal, children, params }) => {
  const { desktop } = useMediaQueries()

  const owner = useUnit($owner) ?? {}

  const { activeStep, activeStepIndex, progress, getPrevStep, redirectToNextStep, redirectToPrevStep } =
    useContext(StepContext)

  if (!activeStep) {
    return <FourOhFour />
  }

  const { backLinkUrl, backLinkText } = (() => {
    const prevStep = getPrevStep()

    const { desktopBackLinkUrl, desktopBackLinkText } = (() => {
      if (activeStepIndex === 0) {
        return {
          desktopBackLinkUrl: null,
          desktopBackLinkText: null,
        }
      }

      if (prevStep.module.includes('redirect-questions')) {
        const getStartedStep = getPrevStep(2)

        return {
          desktopBackLinkUrl: urlTo(getStartedStep.module, params),
          desktopBackLinkText: getStartedStep.headline,
        }
      }

      return {
        desktopBackLinkUrl: urlTo(prevStep.module, { ...params, questionCode: prevStep.questionCode }),
        desktopBackLinkText: activeStep.backLinkText ?? prevStep.headline,
      }
    })()

    if (desktop) {
      return {
        backLinkUrl: desktopBackLinkUrl,
        backLinkText: desktopBackLinkText,
      }
    }

    // other mobile cases
    return {
      backLinkText: 'Back',
      backLinkUrl: activeStepIndex === 0 ? urlTo('dashboard') : desktopBackLinkUrl,
    }
  })()

  const preHeadline = activeStep?.preHeadline ? (
    <Text block smaller style={{ verticalAlign: 'middle', marginBottom: '2.25em', marginTop: '0.325em' }}>
      {activeStep.preHeadline}
    </Text>
  ) : null

  const postHeadline = activeStep?.description?.text ? (
    <Text superSmall inlineBlock style={{ verticalAlign: 'middle', marginTop: '-0.175em' }}>
      &nbsp;
      <HeadlineHelp title={activeStep?.description?.title} body={activeStep?.description?.text} />
    </Text>
  ) : null

  const rightLink = (() =>
    desktop ? (
      <Button mods={{ theme: 'simple-reverse-blue', size: 'small', text: 'smaller' }} data-test-id="dashboardButton">
        <Link to={urlTo('dashboard')} classnameless>
          My Dashboard
        </Link>
      </Button>
    ) : null)()

  const isBusiness = client.type === clientTypes.BUSINESS
  const showFeeOnQuestions = (isBusiness && activeStepIndex < 2) || (!isBusiness && activeStepIndex < 1)
  const isNeedShowPanel = activeStep.feeInfo && goal && (desktop || showFeeOnQuestions)

  const leftLink = backLinkUrl ? (
    <Link to={backLinkUrl} hard={null}>
      {backLinkText}
    </Link>
  ) : null

  return (
    <Questionnaire
      headerProps={{
        progressFill: progressFill || progress,
        greetings: owner ? owner.first_name : null,
        leftLink,
        rightLink,
      }}
      stepHeadlineProps={{
        headline: activeStep.headline,
        preHeadline,
        postHeadline,
        backLinkUrl,
        backLinkText,
        forceShow: !activeStep.module.includes('get-started'),
      }}
      showFee={isNeedShowPanel}
      withInner={activeStep.withGlobalInner ?? true}
      withHeader={activeStep.withGlobalHeader ?? true}
      withFooter={activeStep.withGlobalFooter ?? true}
    >
      {children
        ? React.cloneElement(children, {
            prevStep: getPrevStep(),
            redirectToPrevStep,
            redirectToNextStep,
            backLinkUrl,
            backLinkText,
            parentModule,
          })
        : null}
    </Questionnaire>
  )
}

Tunnel.propTypes = {
  parentModule: PropTypes.string,
  progressFill: PropTypes.number,
  client: PropTypes.object,
  goal: PropTypes.object,
  children: PropTypes.node,
  params: PropTypes.object,
}

const wrappedComponent = compose(
  withRouter,
  withMediaQueries(rawMediaQueries),
  connect((state, ownProps) => {
    const client = state.client

    const gotActiveGoals = getActiveGoals(state.portfolios.items).length > 0
    const goal = selectSelectedGoal(state)
    const goalKind = convertToSlug(getKindByGoal(client, goal))

    return {
      client,
      gotActiveGoals,
      goal,
      goalKind,
    }
  }),
)(Tunnel)

export { wrappedComponent as Tunnel }
