import { useUnit } from 'effector-react'

import { useSelector } from 'hooks'

import { $owner } from 'app/effector/contacts/'

import { BUSINESS } from './business.js'
import { PERSONAL } from './personal.js'
import { PERSONAL_SIMPLE } from './personalSimple.js'
import { PERSONAL_WITH_MANAGE_TYPE } from './personalWithManageType.js'
import { PERSONAL_WITH_MANAGE_TYPE_WITH_SIPP } from './personalWithManageTypeWithSipp.js'
import { PERSONAL_WITH_SIPP } from './personalWithSipp.js'

import { types as clientTypes } from 'constants/client'

const flowTypes = {
  BUSINESS: 'BUSINESS',
  PERSONAL: 'PERSONAL',
  PERSONAL_SIMPLE: 'PERSONAL_SIMPLE',
  PERSONAL_WITH_SIPP: 'PERSONAL_WITH_SIPP',
  PERSONAL_WITH_MANAGE_TYPE: 'PERSONAL_WITH_MANAGE_TYPE',
  PERSONAL_WITH_MANAGE_TYPE_WITH_SIPP: 'PERSONAL_WITH_MANAGE_TYPE_WITH_SIPP',
}

const useFlowType = ({ skipRegulatoryTypeStep, manageType } = {}) => {
  const client = useSelector((state) => state.client)
  const owner = useUnit($owner)
  const clientType = client.type

  // we should show a isa step if client has not competed state or from UK
  const isUkResidence = owner?.isUkResidence()
  const isSippAge = owner?.age < 75
  const isFullFlow = !owner?.country_of_tax_residence || isUkResidence
  const isSippFlow = !owner?.country_of_tax_residence || (isUkResidence && isSippAge)

  if (clientType === clientTypes.BUSINESS) {
    return flowTypes.BUSINESS
  }

  if (skipRegulatoryTypeStep) {
    return flowTypes.PERSONAL_SIMPLE
  }

  if (manageType) {
    if (isSippFlow) {
      return flowTypes.PERSONAL_WITH_MANAGE_TYPE_WITH_SIPP
    }

    return flowTypes.PERSONAL_WITH_MANAGE_TYPE
  }

  if (isSippFlow) {
    return flowTypes.PERSONAL_WITH_SIPP
  }

  return isFullFlow ? flowTypes.PERSONAL : flowTypes.PERSONAL_SIMPLE
}

const usePortfolioFlow = ({ regulatoryType, manageType }) => {
  const portfolioFlows = {
    BUSINESS,
    PERSONAL,
    PERSONAL_SIMPLE,
    PERSONAL_WITH_SIPP,
    PERSONAL_WITH_MANAGE_TYPE,
    PERSONAL_WITH_MANAGE_TYPE_WITH_SIPP,
  }
  const flowType = useFlowType({ skipRegulatoryTypeStep: !!regulatoryType, manageType })

  return portfolioFlows[flowType]
}

export { flowTypes, useFlowType, usePortfolioFlow }
