import React from 'react'

import { useMediaQueries, useState } from 'hooks'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Typography } from 'components/atoms/Typography'

const LifePlanDescription = (): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const [isShowAllActive, setShowAllActive] = useState(true)

  const handleShowAll = (): void => {
    setShowAllActive(false)
  }

  const description =
    'InvestEngine’s LifePlan portfolios are a range of expertly created portfolios designed to give you easy-access to a portfolio that suits you. Each portfolio is pre-built to align with a standard risk rating, focused solely on growth, they are not designed to generate income and each has a different balance of stocks and bonds, offering 20%, 40%, 60%, 80%, or 100% equity exposure. Typically, a higher exposure to stocks gives you the opportunity for higher returns over the long-term, but can be riskier over the short term.\n\nChoose the ready-made portfolio that is appropriate for you, based on your financial goals, timeline and comfort with risk. We’ll handle the day-to-day investment management, asset allocation and rebalancing to keep your portfolio on track. If you’re unsure what you should be investing in, you can sign up for one of our guided Managed Portfolios, and we’ll help you decide.'

  const descriptionLengthLimit = desktop ? 200 : 160

  const trimmedDescription = isShowAllActive ? `${description?.slice(0, descriptionLengthLimit)}...` : description

  return (
    <Typography size={14} data-test-id="collectionHeaderDescription">
      <Typo multiline>{trimmedDescription}</Typo>{' '}
      {isShowAllActive && (
        <Link onClick={handleShowAll} data-test-id="collectionHeaderShowAllLink">
          Show more
        </Link>
      )}
    </Typography>
  )
}

export { LifePlanDescription }
