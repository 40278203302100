import React from 'react'
import PropTypes from 'prop-types'

import { features } from 'helpers/features'

import { Layout } from 'app/pages/Dashboard/SetupAccount/Layout'

import { useIndividualRegistration } from './useIndividualRegistration.js'

const Individual = ({ children, location }) => {
  const individualRegistration = useIndividualRegistration({ location })
  let mobileInnerPaperSizes = null

  if (features.get('onfido') && children?.props.route.module === 'proof-of-identity') {
    mobileInnerPaperSizes = { bottom: 0 }
  }

  return (
    <Layout prevStep={individualRegistration.prevStep} mobileInnerPaperSizes={mobileInnerPaperSizes}>
      {React.cloneElement(children, { ...children.props, ...individualRegistration })}
    </Layout>
  )
}

Individual.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
}

export { Individual }
