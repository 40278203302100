import type { Security, PortfolioSecurity } from 'app/effector/securities/models'

import type {
  ManagedGrowthGiaPortfolio,
  ManagedGrowthIsaPortfolio,
  ManagedIncomeGiaPortfolio,
  ManagedIncomeIsaPortfolio,
} from 'app/redux/models/portfolio/Portfolio/Portfolio.js'

type ManagedPortfolio =
  | ManagedGrowthGiaPortfolio
  | ManagedGrowthIsaPortfolio
  | ManagedIncomeGiaPortfolio
  | ManagedIncomeIsaPortfolio

enum TabNames {
  OVERVIEW = 'Overview',
  PROJECTIONS = 'Projections',
}

type TabData = {
  'data-test-id': string
  name: string
  visible: boolean
}

type RouteProps = {
  module: string
  name?: string
  isNewLayout?: boolean
  component: (props: Record<string, unknown>) => React.ReactElement
}

type DetailedSecurity = Security & PortfolioSecurity

type AllocationItem = {
  type: string
  items: DetailedSecurity[]
  weight: number
  value?: number
}

export { type ManagedPortfolio, TabNames, type TabData, type RouteProps, type AllocationItem, type DetailedSecurity }
