import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import Icon from 'components/_old/Icon/Icon.jsx'
import { Typography } from 'components/atoms/Typography'
import { Typo } from 'components/_old/Typo/Typo'
import { Paper } from 'components/atoms/Paper'

const Notice = () => {
  const { desktop } = useMediaQueries()

  return (
    <Fragment>
      <Paper top={desktop ? 56 : 48} data-test-id="rebalanceNotice">
        <ItemWithIcon
          space={12}
          icon={<Icon size={24} type="time-24" color="inherit" />}
          content={
            <Typography size={desktop ? 14 : 12}>
              <Typo>
                Orders are executed after 2.30pm daily but your portfolio may not be updated until 7pm. Orders created
                after 2pm will be processed on the next business day.
              </Typo>
            </Typography>
          }
        />
      </Paper>
      <Paper top={desktop ? 32 : 16}>
        <ItemWithIcon
          space={12}
          icon={<Icon size={24} type="piechart-24" />}
          content={
            <Typography size={desktop ? 14 : 12}>
              <Typo>
                The trades below have been calculated to rebalance your portfolio to its target weights — adding to
                holdings that have become underweight and reducing overweight holdings. The calculations include your
                portfolio’s cash balance.
              </Typo>
            </Typography>
          }
        />
      </Paper>
      <Paper top={desktop ? 32 : 16}>
        <ItemWithIcon
          space={12}
          icon={<Icon size={24} type="piechart-24" />}
          content={
            <Typography size={desktop ? 14 : 12}>
              <Typo>
                Actual trade amounts will be recalculated at execution and may differ slightly from those shown.
              </Typo>
            </Typography>
          }
        />
      </Paper>
    </Fragment>
  )
}

export { Notice }
