import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { palette } from 'helpers/palette/'
import { format as formatDate } from 'helpers/date.js'

import { useMediaQueries } from 'hooks'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { ChartHorizontalTick } from 'components/organisms/charts/parts'

import './IncomeBars.css'

const IncomeBars = forwardRef(
  (
    {
      className,
      columnClassName,
      tickClassName,
      tickTextClassName,
      max,
      data,
      colors = [palette['primary-default'], palette['status-success']],
      showTicksAt,
      selectedIndex,
      shouldShowLineOnSelectedTick,

      onTickEnter: handleTickEnter,
      onTickLeave: handleTickLeave,
      onTickClick: handleTickClick,
    },
    ref,
  ) => {
    const { desktop } = useMediaQueries()

    const tickCountLimit = desktop ? 7 : 4

    if (!showTicksAt) {
      // If there is more than `tickCountLimit` data points
      // Show tics only at first and last one
      // In any other way show at every single one
      showTicksAt =
        data.length >= tickCountLimit ? [0, data.length - 1] : new Array(data.length).fill(0).map((_, index) => index)
    }

    const classes = classNames(className, 'IncomeBars', {
      IncomeBars_horde: data.length >= tickCountLimit,
    })

    return (
      <ColumnarLayout className={classes} mods={{ padding: 'pico' }} forwardedRef={ref}>
        {data.map(({ value, date }, index) => {
          const valueArray = Array.isArray(value) ? value : [value]
          const formattedDate = formatDate(date, 'MMM YYYY')
          const showTick = showTicksAt.indexOf(index) > -1

          return (
            <Column
              key={formattedDate}
              className={classNames(columnClassName, 'IncomeBars-Column')}
              onMouseEnter={(event) => handleTickEnter(event, index)}
              onClick={(event) => handleTickClick && handleTickClick(event, index)}
              onMouseLeave={(event) => handleTickLeave(event, index)}
              data-test-id="incomeBar"
            >
              <div className={classNames(tickClassName, 'IncomeBars-Tick')}>
                {valueArray.map((value, key) => (
                  <div
                    key={key}
                    className={classNames('IncomeBars-Part', {
                      'IncomeBars-Part_active': selectedIndex === index,
                      'IncomeBars-Part_withLine': shouldShowLineOnSelectedTick && selectedIndex === index,
                    })}
                    style={{
                      height: `${(value / max) * 100}%`,
                      background: colors[key],
                    }}
                    data-test-id="incomeBarPart"
                  />
                ))}
                {showTick && (
                  <ChartHorizontalTick
                    className={tickTextClassName}
                    textNodeClassName="IncomeBars-TextNode"
                    text={formattedDate}
                  />
                )}
              </div>
            </Column>
          )
        })}
      </ColumnarLayout>
    )
  },
)

IncomeBars.propTypes = {
  className: PropTypes.string,
  columnClassName: PropTypes.string,
  tickClassName: PropTypes.string,
  tickTextClassName: PropTypes.string,
  max: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
      date: PropTypes.instanceOf(Date),
    }),
  ).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  showTicksAt: PropTypes.arrayOf(PropTypes.number),
  selectedIndex: PropTypes.number,
  shouldShowLineOnSelectedTick: PropTypes.bool,
  onTickEnter: PropTypes.func,
  onTickLeave: PropTypes.func,
  onTickClick: PropTypes.func,
}

export { IncomeBars }
