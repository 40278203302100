import React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import ModalContent, {
  ModalContentBody,
  ModalContentButtons,
  ModalContentHeadline,
} from 'components/molecules/ModalContent/ModalContent.jsx'

import '../NewOptimalPortfolioModal.css'

const IncorrectPortfolioModalContent = ({
  headline,
  buttonText,
  contentText,
  onRetake: handleRetake,
  onContactSupport: handleContactSupport,
}) => {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const classes = classNames(
    'NewOptimalPortfolioModal',
    getMediaQuieryClasses('NewOptimalPortfolioModal', mediaQueries),
  )

  const buttons = (
    <ColumnarLayout mods={{ padding: desktop ? 'micro' : 'no', direction: !desktop && 'column' }}>
      <Column className="NewOptimalPortfolioModal-Button">
        <Button
          mods={{ size: 'big block', text: 'bigger' }}
          onClick={handleRetake}
          data-test-id="incorrectPortfolioRetake"
        >
          {buttonText}
        </Button>
      </Column>
      <Column className="NewOptimalPortfolioModal-Button">
        <Button
          mods={{ theme: 'simple-reverse-blue', size: 'big block', text: 'bigger' }}
          onClick={handleContactSupport}
          data-test-id="incorrectPortfolioContactSupport"
        >
          Contact support
        </Button>
      </Column>
    </ColumnarLayout>
  )

  return (
    <div className={classes} data-test-id="incorrectPortfolio">
      <ModalContent width={60.75}>
        <Width size={36.875} center>
          <Paper top={desktop ? null : 40} left={16} right={16}>
            <ModalContentHeadline
              className="NewOptimalPortfolioModal-Headline"
              level={desktop ? 1 : 2}
              align="center"
              paddingRight={false}
              icon={
                <Icon
                  className="NewOptimalPortfolioModal-Icon"
                  type="exclamation-triangle"
                  size={64}
                  color={palette['status-warning']}
                />
              }
            >
              {headline}
            </ModalContentHeadline>
            <ModalContentBody size={null} align="center">
              {contentText}
            </ModalContentBody>
          </Paper>
          <ModalContentButtons width={null} isNewModal>
            {desktop ? buttons : <div className="NewOptimalPortfolioModal-Buttons">{buttons}</div>}
          </ModalContentButtons>
        </Width>
      </ModalContent>
    </div>
  )
}

export { IncorrectPortfolioModalContent }
