import React from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { OptionsLayout } from '../../../Options/OptionsLayout'
import { OptionsNavigationBar } from '../../../Options/OptionsNavigationBar'

type AutoinvestNotificationProps = {
  onActivate: () => void
  onClose: () => void
}

const AutoinvestNotification = ({ onActivate, onClose }: AutoinvestNotificationProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const content = useMemo(() => {
    const inner = (
      <Paper top={desktop ? 56 : 0}>
        <Typography
          tag="h1"
          size={desktop ? 32 : 24}
          lineHeight={desktop ? 'small' : 'medium'}
          align="center"
          weight="semibold"
        >
          <Typo>AutoInvest explained</Typo>
        </Typography>
        <Typography size={desktop ? 14 : 16} align="center">
          <Paper top={desktop ? 32 : 12} bottom={24}>
            <Typo>
              By activating AutoInvest you are instructing us to invest your portfolio's cash (£1 minimum and subject to
              any selected cash buffer) according to your chosen ETF weights, in the next available trading window.
            </Typo>
          </Paper>
          <Link to="/terms#autoinvest" blank hard>
            <Typo>More information on how AutoInvest works.</Typo>
          </Link>
        </Typography>
      </Paper>
    )

    if (desktop) {
      return (
        <Width size={27} center>
          {inner}
        </Width>
      )
    }
    return <AllCenter>{inner}</AllCenter>
  }, [desktop])

  return (
    <OptionsLayout
      header={
        <OptionsNavigationBar rightPartText="Cancel" onRightPartClick={onClose}>
          <Typo>AutoInvest</Typo>
        </OptionsNavigationBar>
      }
      content={content}
      button={
        <Button onClick={onActivate} mods={{ size: 'big block' }} data-test-id="ActivateAutoinvestButton">
          Activate AutoInvest
        </Button>
      }
      data-test-id="autoinvestNotification"
    />
  )
}

export { AutoinvestNotification }
