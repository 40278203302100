import React, { Fragment } from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries, useRef, useMemo, useEffect } from 'hooks'

import { palette } from 'helpers/palette'
import { urlTo } from 'helpers/router'

import { ReportRequestStep } from 'app/effector/reports/models'

import Button from 'components/_old/Button/Button.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Nobr } from 'components/_old/Nobr'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { Gateway } from 'components/atoms/Gateway'
import { MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import { Modal, type ModalHandle } from 'components/molecules/Modal'

import { type UseCreateRequestInterface } from '../../hooks'

import { SelectCustomTimePeriod, SelectFileFormat, SelectPortfolios, SelectTimePeriod, SelectType } from './steps'

import './CreateReportForm.css'

const CREATE_REPORT_FORM_STEP_NAMES = {
  [ReportRequestStep.SELECT_TYPE]: 'Select report type',
  [ReportRequestStep.SELECT_TIME_PERIOD]: 'Select time period',
  [ReportRequestStep.SELECT_CUSTOM_TIME_PERIOD]: 'Select time period',
  [ReportRequestStep.SELECT_PORTFOLIOS]: 'Select portfolio',
  [ReportRequestStep.SELECT_FILE_FORMAT]: 'Select file format',
} as const

const enum CREATE_REPORT_FORM_BUTTON_TITLES {
  CONTINUE = 'Continue',
  GENERATE = 'Generate report',
}

function CreateReportForm(props: UseCreateRequestInterface): React.ReactElement {
  const {
    reportRequest,
    formShown,
    hasNoPortfolios,
    reportRequestCreated,
    reportRequestFailed,
    handleCancel,
    handleGoPrevStep,
    handleCreateRequest,
  } = props
  const { desktop } = useMediaQueries()
  const modalRef = useRef<ModalHandle>()
  const prevStep = reportRequest.getPrevStep()
  const nextStep = reportRequest.getNextStep()
  const buttonTitle = nextStep ? CREATE_REPORT_FORM_BUTTON_TITLES.CONTINUE : CREATE_REPORT_FORM_BUTTON_TITLES.GENERATE
  const classes = useMemo(
    () => classNames('CreateReportForm', { CreateReportForm_desktop: desktop, CreateReportForm_phone: !desktop }),
    [desktop],
  )

  useEffect(() => {
    if (modalRef?.current?.modalInside) {
      modalRef.current.modalInside.scrollTop = 0
    }
  }, [reportRequest.step])

  const [detailsContent, detailsButton] = useMemo(() => {
    if (hasNoPortfolios) {
      return [
        <Fragment key="no-portfolios-content">
          <Paper bottom={8}>
            <Width size={4} center>
              <Icon type="file-64" color={palette['content-on-background-default']} size={64} fixedSize />
            </Width>
          </Paper>
          <Typography align="center">
            <Typo>You held no taxable portfolios during this reporting&nbsp;period</Typo>
          </Typography>
        </Fragment>,
        <Button key="no-portfolios-button" mods={{ size: 'big block' }} disabled>
          Create report
        </Button>,
      ]
    }

    if (reportRequestFailed) {
      return [
        <Fragment key="request-failed-content">
          <Width size={4} center>
            <Icon type="warning-64" color={palette['status-warning']} size={64} fixedSize />
          </Width>
          <Paper top={20}>
            <Typography size={20} lineHeight="small" weight="semibold" align="center">
              <Typo>Report can't be generated</Typo>
            </Typography>
          </Paper>
          <Paper top={12}>
            <Typography align="center">
              <Typo>You held no taxable portfolios during this reporting&nbsp;period</Typo>
            </Typography>
          </Paper>
        </Fragment>,
        <Button key="request-failed-button" mods={{ size: 'big block' }}>
          <Link to={urlTo('reports.list')}>Continue</Link>
        </Button>,
      ]
    }

    if (reportRequestCreated) {
      return [
        <Fragment key="request-created-content">
          <Width size={4} center>
            <Icon type="time-64" color={palette['content-on-background-default']} size={64} fixedSize />
          </Width>
          <Paper top={20}>
            <Typography size={20} lineHeight="small" weight="semibold" align="center">
              <Typo>Report is being generated</Typo>
            </Typography>
          </Paper>
          <Paper top={12}>
            <Typography align="center">
              <Typo>
                We will send you an email and a push notification as soon as your report is ready for download
              </Typo>
            </Typography>
          </Paper>
        </Fragment>,
        <Button key="request-created-button" mods={{ size: 'big block' }}>
          <Link to={urlTo('reports.list')}>Continue</Link>
        </Button>,
      ]
    }

    return [
      <Fragment key="default-content">
        <Paper bottom={8}>
          <Width size={4} center>
            <Icon type="file-64" color={palette['content-on-background-default']} size={64} fixedSize />
          </Width>
        </Paper>
        <Typography align="center">
          <Typo>You can create custom reports in PDF or CSV format by clicking the button below</Typo>
        </Typography>
        <Width size={22} center>
          <Paper top={16}>
            <Typography size={12} color="minor" align="center">
              <Typo>
                All reports are subject to the full list of notes & disclaimers found in your Full client valuation
                report
              </Typo>
            </Typography>
          </Paper>
        </Width>
      </Fragment>,
      <Button key="default-button" onClick={handleCreateRequest} mods={{ size: 'big block' }}>
        Create report
      </Button>,
    ]
  }, [hasNoPortfolios, reportRequestFailed, reportRequestCreated, handleCreateRequest])

  const details: React.ReactElement = useMemo(() => {
    if (desktop) {
      return (
        <Fragment>
          {detailsContent}
          <Paper top={24}>{detailsButton}</Paper>
        </Fragment>
      )
    }

    return (
      <Fragment>
        {detailsContent}
        <Gateway into="create-report-footer">
          <MobileLayoutFooterButton>{detailsButton}</MobileLayoutFooterButton>
        </Gateway>
      </Fragment>
    )
  }, [desktop, detailsContent, detailsButton])

  const stepProps = useMemo(
    () => ({
      className: 'CreateReportForm-Step',
      contentClassName: 'CreateReportForm-Content',
      buttonClassName: 'CreateReportForm-Button',
      buttonTitle,
      desktop,
      ...props,
    }),
    [buttonTitle, desktop, props],
  )

  return (
    <div className={classes}>
      <Width size={desktop ? 25.75 : null} center>
        {details}
      </Width>
      <Modal ref={modalRef} open={formShown} close={null} onClose={handleCancel}>
        <Width size={desktop ? 36 : null} center={desktop}>
          <Paper right={desktop ? 24 : null} left={desktop ? 24 : null}>
            {reportRequest.step && (
              <Fragment>
                <Paper
                  className="CreateReportForm-NavigationBar"
                  top={desktop ? 12 : null}
                  bottom={desktop ? 12 : null}
                >
                  <NavigationBar
                    centralPartSize={{ desktop: 20 }}
                    centralPartWeight={{ desktop: 'semibold' }}
                    leftPartText={prevStep ? 'Back' : undefined}
                    onLeftPartClick={prevStep ? handleGoPrevStep : undefined}
                    rightPartText="Cancel"
                    onRightPartClick={handleCancel}
                  >
                    <Typo>
                      <Nobr>{CREATE_REPORT_FORM_STEP_NAMES[reportRequest.step]}</Nobr>
                    </Typo>
                  </NavigationBar>
                </Paper>
                <Width size={desktop ? 22 : null} center={desktop}>
                  <Paper className="CreateReportForm-Steps" top={desktop ? 24 : 16}>
                    <SelectType visible={reportRequest.step === ReportRequestStep.SELECT_TYPE} {...stepProps} />
                    <SelectTimePeriod
                      visible={reportRequest.step === ReportRequestStep.SELECT_TIME_PERIOD}
                      {...stepProps}
                    />
                    <SelectCustomTimePeriod
                      visible={reportRequest.step === ReportRequestStep.SELECT_CUSTOM_TIME_PERIOD}
                      {...stepProps}
                    />
                    <SelectPortfolios
                      visible={reportRequest.step === ReportRequestStep.SELECT_PORTFOLIOS}
                      {...stepProps}
                    />
                    <SelectFileFormat
                      visible={reportRequest.step === ReportRequestStep.SELECT_FILE_FORMAT}
                      {...stepProps}
                    />
                  </Paper>
                </Width>
              </Fragment>
            )}
          </Paper>
        </Width>
      </Modal>
    </div>
  )
}

type CreateReportFormPartProps = {
  className: string
  contentClassName: string
  buttonClassName: string
  visible: boolean
  buttonTitle: string
  desktop: boolean
} & UseCreateRequestInterface

export { CreateReportForm, type CreateReportFormPartProps, CREATE_REPORT_FORM_BUTTON_TITLES }
