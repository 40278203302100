import { useUnit, useStoreMap } from 'effector-react'

import { useState, usePrevious, useCallback, useEffect, useAsyncEffect } from 'hooks'

import { sendError } from 'helpers/errorLogging'
import { format } from 'helpers/money'
import { goTo, urlTo } from 'helpers/router'

import { $bonusesStore, claimBonusFx, fetchBonusesFx } from 'app/effector/bonuses'
import { type BonusList, BonusStates } from 'app/effector/bonuses/models'

import { showFailToast } from 'app/redux/actions/ui'
import { ApiError } from 'app/redux/models/errors'

const USE_CLAIM_BONUS_CLAIMING = 'CLAIMING' as const

type UseClaimBonusState = BonusStates.NEW | typeof USE_CLAIM_BONUS_CLAIMING | BonusStates.CLAIMED

type UseClaimBonusInterface = {
  state: UseClaimBonusState
  value: string | null
  isLoading: boolean
  isClaiming: boolean
  bonusesInNewState: BonusList
  handleBack: () => void
  handleClaim: () => Promise<void>
  handleContinue: () => void
}

const useClaimBonus = (): UseClaimBonusInterface => {
  const [state, setState] = useState<UseClaimBonusState>(BonusStates.NEW)
  const [value, setValue] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const bonusesInNewState = useStoreMap($bonusesStore, (bonusesStore) =>
    bonusesStore.bonuses.filterByState(BonusStates.NEW),
  )

  const isFetching = useUnit(fetchBonusesFx.pending)
  const wasFetching = usePrevious(isFetching)
  const isClaiming = useUnit(claimBonusFx.pending)

  const handleBack = useCallback(() => {
    goTo(urlTo('dashboard'))
  }, [])

  const handleContinue = useCallback(() => {
    goTo(urlTo('dashboard'))
  }, [])

  const handleFailure = useCallback((message: string = 'Something went wrong') => {
    goTo(urlTo('dashboard'), { replace: true })
    showFailToast(message)
  }, [])

  const handleClaim = useCallback(async () => {
    try {
      setState(USE_CLAIM_BONUS_CLAIMING)

      const { amount } = await claimBonusFx()

      const formattedAmount = amount ? format(amount, false, false) : null

      setValue(formattedAmount)
      setState(BonusStates.CLAIMED)
    } catch (error) {
      sendError(error)
      handleFailure()
    }
  }, [handleFailure])

  useEffect(() => {
    if (wasFetching && !isFetching) {
      setIsLoading(false)
    }
  }, [wasFetching, isFetching])

  useAsyncEffect(async () => {
    try {
      const result = await fetchBonusesFx(BonusStates.NEW)

      if (result instanceof ApiError) {
        throw result
      }

      if (result.length < 1) {
        handleFailure(`You've already claimed this bonus`)
      }
    } catch (error) {
      sendError(error)
      handleFailure()
    }
  }, [handleFailure])

  return {
    state,
    value,
    isLoading,
    isClaiming,
    bonusesInNewState,
    handleBack,
    handleClaim,
    handleContinue,
  }
}

export { useClaimBonus, type UseClaimBonusInterface, USE_CLAIM_BONUS_CLAIMING }
