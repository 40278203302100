import { Entity } from 'app/redux/models/common/Entity.js'
import * as rules from 'app/redux/models/common/rules.js'

enum NotificationType {
  NOTICE = 'NOTICE',
  WARNING = 'WARNING',
  AWAITING = 'AWAITING',
  PROMO = 'PROMO',
  ISA_TRANSFER_PANEL = 'ISA_TRANSFER_PANEL',
}

enum NotificationScopeType {
  PORTFOLIO = 'PORTFOLIO',
  CLIENT = 'CLIENT',
  ANY = 'ANY',
}

type NotificationAction = XOR<
  {
    label: string
    type: 'CODE'
    link: string
    params: Dictionary<string | number> | null
  },
  {
    label: string
    type: 'URL'
    link: string
  }
>

class Notification extends Entity {
  readonly code: string | null
  readonly type: NotificationType | null
  readonly disabled: boolean
  readonly scope: { type: NotificationScopeType | null; params: Dictionary | null } | null
  readonly title: { content: string } | null
  readonly body: { content: string; variables: Dictionary<NotificationAction> | null; image: string | null } | null
  readonly action: NotificationAction | null

  constructor() {
    super()

    this.code = null
    this.type = null
    this.disabled = false
    this.scope = null
    this.title = null
    this.body = null
    this.action = null
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getRules() {
    return {
      code: [[rules.string]],
      type: [[rules.string]],
      disabled: [[rules.bool]],
      scope: [
        [
          (scope) =>
            scope
              ? {
                  type: rules.string(scope.type ?? null),
                  params: scope.params ?? null,
                }
              : null,
        ],
      ],
      title: [
        [
          (title) =>
            title
              ? {
                  content: rules.string(title.content ?? null),
                }
              : null,
        ],
      ],
      body: [
        [
          (body) =>
            body
              ? {
                  content: rules.string(body.content ?? null),
                  variables: body.variables ?? null,
                  image: body.image ?? null,
                }
              : null,
        ],
      ],
      action: [[(action) => action ?? null]],
    }
  }
}

export { Notification, NotificationType, NotificationScopeType, type NotificationAction }
