import React, { forwardRef, type ReactNode } from 'react'

import isArray from 'lodash/isArray'
import isString from 'lodash/isString'

import { useCallback } from 'hooks'

import Input from 'components/_old/Input/Input.jsx'
import Label, { LabelTextWithAction } from 'components/_old/Label/Label.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

import { Typography } from 'components/atoms/Typography'

import './AddressManualEdit.css'

type ValidationProp = {
  errors: string[]
  rules: string[] | number[] | boolean[]
}

type Country = {
  country: string
  country_id: number
}

type AddressManualEditProps = {
  address: {
    street: string
    street2: string
    postcode: string
    country: number
    countryFull: Country
    city: string
  }
  validation: {
    city: ValidationProp
    country: ValidationProp
    isUK: ValidationProp
    months_at_address: ValidationProp
    postcode: ValidationProp
    street: ValidationProp
  }
  inputMods: Record<string, string>
  countries: Country[]
  notUkWarningText: ReactNode | string
  skipWaitBlur: string[] | string
  clearOnSwitchToSearchMode: boolean
  onAddressFieldChange: (field: string, value: string | object | number | null) => void
  onSwitchToSearchMode: () => void
}

const AddressManualEdit = forwardRef<HTMLDivElement, AddressManualEditProps>(function AddressManualEdit(
  {
    address,
    validation,
    inputMods,
    countries,
    notUkWarningText,
    skipWaitBlur,
    clearOnSwitchToSearchMode,
    onAddressFieldChange,
    onSwitchToSearchMode,
  }: AddressManualEditProps,
  ref,
): React.ReactElement | null {
  const handleSwitchToSearchMode = useCallback(() => {
    if (clearOnSwitchToSearchMode) {
      onAddressFieldChange('street', null)
      onAddressFieldChange('street2', null)
      onAddressFieldChange('city', null)
      onAddressFieldChange('postcode', null)
      onAddressFieldChange('country', null)
    }
    onSwitchToSearchMode()
  }, [clearOnSwitchToSearchMode, onAddressFieldChange, onSwitchToSearchMode])
  const handleStreetChange = useCallback(
    (_event: object, value: string) => {
      onAddressFieldChange('street', value)
    },
    [onAddressFieldChange],
  )
  const handleStreet2Change = useCallback(
    (_event: object, value: string) => {
      onAddressFieldChange('street2', value)
    },
    [onAddressFieldChange],
  )
  const handleCityChange = useCallback(
    (_event: object, value: string) => {
      onAddressFieldChange('city', value)
    },
    [onAddressFieldChange],
  )
  const handlePostcodeChange = useCallback(
    (_event: object, value: string) => {
      onAddressFieldChange('postcode', value)
    },
    [onAddressFieldChange],
  )
  const handleCountryChange = useCallback(
    (_event: object, value: { country: string; country_id: number }) => {
      onAddressFieldChange('country', value ? value.country_id : null)
    },
    [onAddressFieldChange],
  )
  const handleSkipWaitBlur = useCallback(
    (fieldName: string) => {
      if (isString(skipWaitBlur)) {
        return fieldName === skipWaitBlur
      }

      if (isArray(skipWaitBlur)) {
        return skipWaitBlur.includes(fieldName)
      }

      return false
    },
    [skipWaitBlur],
  )
  const { street, street2, postcode, country, countryFull, city } = address

  const isUK = validation.isUK.rules[0]

  if (countries.length > 0 && country && !countryFull) {
    return null
  }

  return (
    <div ref={ref} className="AddressManualEdit" data-test-id="addressManualEdit">
      <Validate rules={validation.street.rules} skipWaitBlur={handleSkipWaitBlur('street')}>
        <Label errorMessages={validation.street.errors} data-test-id="addressStreetLabel">
          <LabelTextWithAction>
            Address
            <Link onClick={handleSwitchToSearchMode} data-test-id="addressModeSwitch">
              Search by Postcode
            </Link>
          </LabelTextWithAction>
          <Input
            name="street"
            autoComplete="address-line1"
            mods={inputMods}
            type="text"
            onChange={handleStreetChange}
            data-test-id="addressStreetInput"
          >
            {street}
          </Input>
        </Label>
      </Validate>
      <Label
        className="AddressManualEdit-Street2"
        postfield={
          <Typography size={12} color="muted">
            Optional
          </Typography>
        }
      >
        <Input
          name="street2"
          autoComplete="address-line2"
          mods={inputMods}
          type="text"
          onChange={handleStreet2Change}
          data-test-id="addressStreet2Input"
        >
          {street2}
        </Input>
      </Label>
      <Validate rules={validation.city.rules} skipWaitBlur={handleSkipWaitBlur('city')}>
        <Label errorMessages={validation.city.errors} data-test-id="addressCityLabel">
          City
          <Input
            name="city"
            autoComplete="address-level2"
            mods={inputMods}
            type="text"
            onChange={handleCityChange}
            data-test-id="addressCityInput"
          >
            {city}
          </Input>
        </Label>
      </Validate>
      <Validate rules={validation.postcode.rules} skipWaitBlur={handleSkipWaitBlur('postal')}>
        <Label errorMessages={validation.postcode.errors} data-test-id="addressPostcodeLabel">
          Postcode
          <Input
            name="postal"
            autoComplete="postal-code"
            className="Input_uppercase"
            mods={inputMods}
            type="text"
            onChange={handlePostcodeChange}
            data-test-id="addressPostcodeInput"
          >
            {postcode}
          </Input>
        </Label>
      </Validate>
      <Validate rules={validation.country.rules} skipWaitBlur={handleSkipWaitBlur('country')}>
        <Label
          errorMessages={validation.country.errors}
          postfield={!isUK && <Typography block>{notUkWarningText}</Typography>}
          data-test-id="addressCountryLabel"
        >
          Country
          <Input
            autoComplete="off"
            name="country"
            mods={inputMods}
            type="typeahead"
            options={countries}
            labelKey="country"
            onChange={handleCountryChange}
            forceSelect
            data-test-id="addressCountryInput"
          >
            {countryFull}
          </Input>
        </Label>
      </Validate>
    </div>
  )
})

export { AddressManualEdit }
