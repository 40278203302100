import { type Entity } from 'app/redux/models/common/Entity'

function getListOf<AbstractModelInterface extends Entity, AbstractModelProps>(
  AbstractModel: new (AbstractModelProps: AbstractModelProps) => AbstractModelInterface,
  unknowns: AbstractModelProps[],
): AbstractModelInterface[] {
  return unknowns
    .map((unknown: AbstractModelProps) => {
      if (Object.prototype.toString.call(unknown) === '[object Object]') {
        const instance = new AbstractModel(unknown)
        instance.setDataFromObject(unknown)

        return instance
      }

      return null
    })
    .filter((modelOrNull): modelOrNull is AbstractModelInterface => modelOrNull instanceof AbstractModel)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function ListOf<AbstractModelInterface extends Entity, AbstractModelProps>(
  AbstractModel: new (AbstractModelProps: AbstractModelProps) => AbstractModelInterface,
  process?: (AbstractModels: AbstractModelInterface[]) => AbstractModelInterface[],
) {
  return class ListOfModel extends Array<AbstractModelInterface> {
    constructor(...items: AbstractModelProps[]) {
      let AbstractModels = getListOf<AbstractModelInterface, AbstractModelProps>(AbstractModel, items)

      if (process && typeof process === 'function') {
        AbstractModels = process(AbstractModels)
      }

      super(...AbstractModels)
    }

    filter(
      predicate: <AbstractModelInterface>(
        value: AbstractModelInterface,
        index: number,
        array: AbstractModelInterface[],
      ) => value is AbstractModelInterface,
      thisArg?: any,
    ): ListOfModel {
      const filtered = super.filter(predicate) as AbstractModelProps[]

      return new ListOfModel(...filtered)
    }
  }
}

export { ListOf, getListOf }
