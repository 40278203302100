import { nullable, type Nullable, string, int, date, boolean, enumValue } from 'app/effector/model'

import { type ReportDTO } from '../api'

enum ReportFormat {
  PDF = 'PDF',
  CSV = 'CSV',
}

class Report {
  readonly title: Nullable<string>
  readonly type: Nullable<string>
  readonly type_title: Nullable<string>
  readonly is_generated: boolean
  readonly year: Nullable<number>
  readonly modified: Nullable<Date>
  readonly date_from: Nullable<Date>
  readonly date_to: Nullable<Date>
  readonly url: Nullable<string>
  readonly format: ReportFormat

  constructor(dto: Partial<ReportDTO> | Partial<Report>) {
    this.title = nullable(string)(dto.title)
    this.type = nullable(string)(dto.type)
    this.type_title = nullable(string)(dto.type_title)
    this.is_generated = boolean(dto.is_generated ?? true)
    this.year = nullable(int)(dto.year)
    this.modified = nullable(date)(dto.modified)
    this.date_from = nullable(date)(dto.date_from)
    this.date_to = nullable(date)(dto.date_to)
    this.url = nullable(string)(dto.url)
    this.format = nullable(enumValue)(dto.format, ReportFormat) ?? ReportFormat.PDF
  }

  getTitle(stripFormat = true): string {
    const formats = Object.values(ReportFormat).map((formatName) => formatName.toLowerCase())
    const regexp = new RegExp(`\\.(${formats.join('|')})`)
    const title = stripFormat ? (this.title ?? '').replace(regexp, '') : this.title ?? ''

    if (!this.is_generated) {
      return `Generating ${title ?? ''}`.trim()
    }

    return title.trim()
  }
}

export { Report, ReportFormat }
