import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import compose from 'helpers/compose.js'
import rawMediaQueries from 'helpers/mediaQueries.js'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'

import './ContentHolder.css'

const ContentHolder = ({ className, children, style, stretch = false, forceAllCenter = false, mediaQueries }) => {
  const classes = classNames(
    className,
    'ContentHolder',
    {
      ContentHolder_stretch: stretch,
    },
    getMediaQuieryClasses('ContentHolder', mediaQueries),
  )

  if (mediaQueries.desktop || forceAllCenter) {
    return (
      <div className={classes} style={style}>
        <AllCenter style={{ width: '100%' }}>{children}</AllCenter>
      </div>
    )
  }

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  )
}

ContentHolder.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  stretch: PropTypes.bool,
  forceAllCenter: PropTypes.bool,
  mediaQueries: PropTypes.object,
}

export default compose(withMediaQueries(rawMediaQueries))(ContentHolder)
