import { useEffect, useState } from 'hooks'

const useMaxHeight = (
  isFullDescription: boolean,
  scrollableElement: Element | null,
): { open: number; close: number } => {
  const [maxHeight, setMaxHeight] = useState({ open: 0, close: 0 })

  useEffect(() => {
    if (!scrollableElement) return

    const updateMaxHeight = (): void => {
      if (!isFullDescription) {
        setMaxHeight((prevMaxHeight) => ({ ...prevMaxHeight, close: scrollableElement.scrollHeight }))
      }

      if (isFullDescription) {
        setMaxHeight((prevMaxHeight) => ({ ...prevMaxHeight, open: scrollableElement.scrollHeight }))
      }
    }

    const resizeObserver = new ResizeObserver(() => {
      updateMaxHeight()
    })

    resizeObserver.observe(scrollableElement)

    updateMaxHeight()

    return () => {
      resizeObserver.disconnect()
    }
  }, [isFullDescription, scrollableElement])

  return maxHeight
}

export default useMaxHeight
