import axios from 'helpers/ajax'
import { retryOperation } from 'helpers/retryOperation.js'

export const getPaymentDetailsCheckStatus = async ({ bankAccountId }) => {
  const config = {
    url: `id-check/gbg-authentication-bank-account/status/${bankAccountId}/`,
    method: 'get',
  }

  const requestPaymentDetailsStatus = async () =>
    await new Promise((resolve, reject) => {
      axios(config)
        .then((response) => {
          if (response.data && response.data.status === 'complete') {
            resolve(response.data.result)
          } else {
            // reject with false will stop retryOperation
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

  return await retryOperation(requestPaymentDetailsStatus, 4000, 12)
    .then((result) => {
      const status = result === 'pass'

      return status
    })
    .catch((error) => {
      if (error instanceof Error) {
        throw error
      }
      return false
    })
}
