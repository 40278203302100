import React, { Fragment } from 'react'

import { useMediaQueries } from 'hooks'

import { GatewayDest } from 'components/atoms/Gateway'

import { Preloader } from 'components/molecules/Preloader'

import { usePeriod } from 'app/pages/Securities/hooks'

import { Desktop } from './Desktop'
import { useSharedSecurity } from './hooks/useSharedSecurity'
import { Mobile } from './Mobile'

type SharedSecurityProps = {
  params: {
    securityId: string
    id?: string
    hash: string
  }
  location: { query: { back: string } }
}

const SharedSecurity = ({
  params: { securityId: securityIdString, id: portfolioId, hash },
  location: { query },
}: SharedSecurityProps): React.ReactElement => {
  const securityId = parseInt(securityIdString, 10)
  const { desktop } = useMediaQueries()

  const {
    isLoading,
    isAnalyticsVisible,
    equities,
    sectors,
    regions,
    security,
    securityLabels,
    chartData,
    searchValue,
    handleSearch,
    handleGoBack,
    goToSecurityAnalytics,
  } = useSharedSecurity(securityId, hash, portfolioId, query)

  const { selectedPeriod, handlePeriodSelect, periodPriceData } = usePeriod(security)

  const props = {
    isAnalyticsVisible,
    equities,
    sectors,
    regions,
    security,
    securityLabels,
    chartData,
    selectedPeriod,
    periodPriceData,
    searchValue,
    handleSearch,
    handleGoBack,
    handlePeriodSelect,
    goToSecurityAnalytics,
  }

  return (
    <Fragment>
      {desktop ? <Desktop {...props} /> : <Mobile {...props} />}

      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
      <Preloader loading={isLoading} size="big" zIndex />
    </Fragment>
  )
}

export { SharedSecurity }
