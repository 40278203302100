import { attach } from 'effector'
import { useUnit } from 'effector-react'

import { useEffect, useActions, useSelector, useLoading } from 'hooks'

import { trackEvent } from 'helpers/analytics'

import { $pendingOrders, submitPendingOrderFx, $isLoading } from 'app/effector/pending-orders'
import { openReviewModal } from 'app/effector/review'
import { $securitiesStore } from 'app/effector/securities'

import { fetchPortfolio as fetchPortfolioActionCreator } from 'app/redux/actions/portfolios'
import { showFailToast, showSuccessToast } from 'app/redux/actions/ui'

import { useNavbar } from './useNavbar.js'

import { type as orderTypes } from 'constants/pendingOrder.js'

const submitReviewPendingOrderFx = attach({
  effect: submitPendingOrderFx,
})

submitReviewPendingOrderFx.fail.watch(({ error }) => {
  showFailToast(error.message)
})

const useReview = ({ orderId, portfolioId }) => {
  const { isLoading: isPortfolioLoading, wait: waitForPortfolio } = useLoading()
  const { handleBack, redirectToPortfolio, redirectToPortfolioWithAfterSellModal } = useNavbar({ portfolioId })

  const { pendingOrders, isSubmitting } = useUnit({
    pendingOrders: $pendingOrders,
    isSubmitting: $isLoading,
  })

  const [fetchPortfolio] = useActions([fetchPortfolioActionCreator])

  const orderInfo = pendingOrders.get(orderId)
  const { securities } = useUnit($securitiesStore)
  const isAutoinvestEnabled = useSelector((state) => state.portfolios.list.get(portfolioId))?.is_autoinvest_enabled
  const isSellOrder = orderInfo?.type === orderTypes.SELL
  const isLoading = isSubmitting || isPortfolioLoading

  useEffect(() => {
    const doneSubscription = submitReviewPendingOrderFx.done.watch(() => {
      trackEvent({ action: 'pending_orders_submitted' })

      waitForPortfolio(fetchPortfolio(portfolioId)).then(() => {
        showSuccessToast('Order submitted successfully')

        if (isSellOrder && isAutoinvestEnabled) {
          redirectToPortfolioWithAfterSellModal()
        } else {
          redirectToPortfolio()
        }

        openReviewModal()
      })
    })

    return () => {
      doneSubscription.unsubscribe()
    }
  }, [
    portfolioId,
    fetchPortfolio,
    waitForPortfolio,
    isSellOrder,
    isAutoinvestEnabled,
    redirectToPortfolioWithAfterSellModal,
    redirectToPortfolio,
  ])

  const handleConfirm = () => {
    submitReviewPendingOrderFx({ orderId: orderInfo.id })
  }

  return {
    isLoading,
    orderInfo,
    securities,
    handleBack,
    handleCancel: redirectToPortfolio,
    handleConfirm,
  }
}

export { useReview }
