import React from 'react'

import FourOhFour from './404.jsx'

const routes = {
  module: '404',
  path: '*',
  component: function Component(props) {
    return <FourOhFour {...props} />
  },
}

export default routes
