import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useCallback, useMemo, useRef, useState } from 'hooks'

import { MobileLayout } from 'components/atoms/Layouts'
import { AnimatedNavigationBar } from 'components/molecules/AnimatedNavigationBar'
import { VirtualList } from 'components/molecules/VirtualList'
import { SecurityConstituent } from 'components/organisms/SecurityConstituent'
import { SecurityTitle } from 'components/molecules/SecurityTitle'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Typography } from 'components/atoms/Typography'
import { GreyTabs } from 'components/organisms/GreyTabs/GreyTabs.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { SearchField } from 'components/molecules/SearchField'
import { FilterGroup } from 'components/organisms/Filter/FilterGroup'
import { Filter } from 'components/organisms/Filter'

const itemTitles = {
  holdings: 'Equities',
  sectors: 'Sectors',
  regions: 'Regions',
}

const Mobile = ({
  security,
  holdings,
  sectors,
  regions,
  filters,
  handleClose,
  type: activeTab,
  goToSecurityAnalytics,
  itemsAreClickable = true,
  goToHoldingPage,
  searchValue,
  handleSearch,
}) => {
  const securityTitleRef = useRef()
  const [layoutNode, setLayoutNode] = useState(null)
  const layoutRef = useCallback((ref) => setLayoutNode(ref), [])
  const analytics = { holdings, sectors, regions }

  const tabs = useMemo(
    () => [
      { title: 'Holdings', id: 'holdings', isActive: activeTab === 'holdings', withSearch: handleSearch },
      { title: 'Regions', id: 'regions', isActive: activeTab === 'regions' },
      { title: 'Sectors', id: 'sectors', isActive: activeTab === 'sectors' },
    ],
    [activeTab, handleSearch],
  )

  const activeTabObject = useMemo(() => tabs.find((tab) => tab.isActive) ?? {}, [activeTab])

  const searchIsActive = useMemo(
    () => activeTabObject?.withSearch && searchValue.length >= 2,
    [activeTabObject, searchValue],
  )

  const isListFiltered = !!filters?.regions.value.length || !!filters?.sectors.value.length || !!searchValue

  const header = useMemo(
    () => (
      <AnimatedNavigationBar
        nodeToWatchTo={securityTitleRef?.current?.title}
        leftPartText="Back"
        onLeftPartClick={handleClose}
      >
        {security?.title}
      </AnimatedNavigationBar>
    ),
    [security, handleClose, securityTitleRef?.current?.title],
  )

  const renderEquity = useCallback(
    (listElement, items) => {
      const item = items[listElement.index]

      return itemsAreClickable ? (
        <Paper key={item.name} top={12}>
          <SelectableCard onClick={() => goToHoldingPage(item.id, activeTab.slice(0, -1))}>
            <Paper top={16} bottom={16} left={16} right={16}>
              <SecurityConstituent
                logo={item.logo}
                name={item.name}
                actual={item.actual}
                onSettled={listElement.measure}
                color={item.color}
                showLogo={activeTab === 'holdings'}
              />
            </Paper>
          </SelectableCard>
        </Paper>
      ) : (
        <Paper key={item.name} top={32}>
          <SecurityConstituent
            logo={item.logo}
            name={item.name}
            actual={item.actual}
            target={null}
            color={item.color}
          />
        </Paper>
      )
    },
    [activeTab],
  )

  const renderNoEquities = useCallback(() => null, [])

  const content = useMemo(
    () => (
      <Fragment>
        <SecurityTitle
          ref={securityTitleRef}
          logo={security?.logo_uri}
          ticker={security?.ticker}
          title={security?.title}
          size={24}
          lineHeight="small"
          iconPosition="right"
        />
        <Paper top={32}>
          <GreyTabs tabs={tabs} onChange={(tab) => goToSecurityAnalytics(tab.id)} />
        </Paper>
        {activeTabObject?.withSearch && (
          <Fragment>
            <Paper top={32} bottom={16}>
              <SearchField value={searchValue} onChange={handleSearch} placeholder="Search by holding" />
            </Paper>
            <FilterGroup>
              <Filter
                name="Region"
                type="checkbox"
                values={filters.regions.list}
                selected={filters.regions.value}
                onChange={filters.regions.set}
              />
              <Filter
                name="Sector"
                type="checkbox"
                values={filters.sectors.list}
                selected={filters.sectors.value}
                onChange={filters.sectors.set}
              />
            </FilterGroup>
          </Fragment>
        )}
        {!searchIsActive && (
          <Paper top={32}>
            <Typography size={14} lineHeight="small" color="additional">
              <Typo>
                {analytics[activeTab].length} {itemTitles[activeTab]}
              </Typo>
            </Typography>
          </Paper>
        )}
        {isListFiltered && !analytics[activeTab]?.length && (
          <Paper top={32}>
            <Typography size={14} lineHeight="medium" color="minor" data-test-id="noSearchResults">
              <Typo>
                No holdings found.
                <br />
                Please try a different search.
              </Typo>
            </Typography>
          </Paper>
        )}
        <Paper top={searchIsActive ? 20 : 0} bottom={40}>
          <VirtualList
            scrollableElement={layoutNode}
            cacheKey="security-analytics-list-mobile"
            items={analytics[activeTab]}
            itemMinHeight={72}
            itemMaxHeight={92}
            renderItem={(element) => renderEquity(element, analytics[activeTab])}
            renderNoItems={renderNoEquities}
          />
        </Paper>
      </Fragment>
    ),
    [security, holdings, layoutNode, renderEquity, renderNoEquities, analytics, activeTab, isListFiltered],
  )

  return <MobileLayout ref={layoutRef} header={header} content={content} />
}

Mobile.propTypes = {
  open: PropTypes.bool,
  security: PropTypes.shape({
    logo_uri: PropTypes.string,
    ticker: PropTypes.string,
    title: PropTypes.string,
  }),
  holdings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      actual: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  filters: PropTypes.shape({
    regions: {
      value: PropTypes.arrayOf(PropTypes.number),
      list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.number })),
      set: PropTypes.func,
    },
    sectors: {
      value: PropTypes.arrayOf(PropTypes.number),
      list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.number })),
      set: PropTypes.func,
    },
  }),
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  goToSecurityAnalytics: PropTypes.func,
  itemsAreClickable: PropTypes.bool,
  goToHoldingPage: PropTypes.func,
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func,
}

export { Mobile }
