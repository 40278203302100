import React from 'react'
import Headline from 'components/_old/Headline/Headline.jsx'

import { numeral } from 'helpers/money'

import './StackedBar.css'

export default function StackedBar(props) {
  let { label = '', assets = [], sumAmount = '', maxHeight = 100, alternativesHelper } = props
  const styles = assets.map((item, index, all) => {
    return {
      backgroundColor: item.backgroundColor,
      height: (maxHeight / 100) * item.amount,
    }
  })

  if (label === 'Alternatives' && alternativesHelper) {
    label = (
      <div>
        {label}
        {alternativesHelper}
      </div>
    )
  }

  return (
    <div className="StackedBar">
      {label ? <div className="StackedBar-Label">{label}</div> : null}
      {sumAmount ? (
        <Headline className="StackedBar-Total" data-test-id="typeBarPercent">{`${numeral(sumAmount).format(
          '0',
        )}%`}</Headline>
      ) : null}
      <div className="StackedBar-Stack">
        {styles.map((style, i) => {
          return <div key={i} style={style} />
        })}
      </div>
    </div>
  )
}
