import React from 'react'

import { useMemo } from 'hooks'

import Card from 'components/_old/Card/Card.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Text from 'components/_old/Text/Text.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { DesktopLayout } from 'components/atoms/Layouts'
import { Paper } from 'components/atoms/Paper'
import Segment from 'components/atoms/Segment/Segment.jsx'
import { Typography } from 'components/atoms/Typography'

import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import { Preloader } from 'components/molecules/Preloader'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader } from 'app/containers/Header'

import { Transaction } from '../Transaction.jsx'
import { TransactionsHeader, TransactionsHeaderTabs } from '../TransactionsHeader'

const Desktop = ({
  ordersProcessing,
  hasTransactions,
  hasSecurityTransactions,
  total,
  filters,
  pendingOrders,
  transactions,
  portfolioNameById,
  portfolioReferenceById,
  preloaderElementRef,
  isNextLoading,
  noTransactions,
  noResults,
  isLoading,
  isSecurityTransactionsPage,
  resetFilters,
}): React.ReactElement => {
  const filtersPanel = useMemo(() => {
    return (
      <Paper bottom={24}>
        {filters.map((filter, index) => (
          <Paper key={index} right={8} inline style={{ verticalAlign: 'middle' }}>
            {filter}
          </Paper>
        ))}
      </Paper>
    )
  }, [filters])

  const headerAndTabs = useMemo(
    () => (
      <TransactionsHeader
        activeTabName={
          isSecurityTransactionsPage
            ? TransactionsHeaderTabs.SECURITY_TRANSACTIONS
            : TransactionsHeaderTabs.TRANSACTIONS
        }
        hasSecurityTransactions={hasSecurityTransactions}
        resetFilters={resetFilters}
      />
    ),
    [isSecurityTransactionsPage, hasSecurityTransactions, resetFilters],
  )

  const filtersAndOrders = useMemo(
    () => (
      <Segment mods={{ noMargin: 'bottom' }}>
        <Inner>
          {headerAndTabs}
          <Paper top={56}>{filtersPanel}</Paper>
        </Inner>
        <Inner>{ordersProcessing}</Inner>
      </Segment>
    ),
    [ordersProcessing, filtersPanel, headerAndTabs],
  )

  const transactionsTable = useMemo(
    () =>
      hasTransactions && (
        <Segment mods={{ noMargin: 'bottom' }}>
          <Inner>
            {total && (
              <Paper className="Transactions-Total" bottom={pendingOrders.length ? 0 : 16}>
                <Typography size={16} align="right">
                  Total:
                </Typography>
                <Typography size={24} align="right" weight="regular">
                  {total}
                </Typography>
              </Paper>
            )}
            {!isSecurityTransactionsPage && pendingOrders.length > 0 && (
              <Typography size={14} color="additional">
                Transactions
              </Typography>
            )}
            <Card className="Transactions-Headline" mods={{ theme: 'transparent' }}>
              <ColumnarLayout>
                <Column size={14}>
                  <Text small>Type</Text>
                </Column>
                <Column size={14}>
                  <Text small block>
                    Date & time
                  </Text>
                </Column>
                <Column size={14}>
                  <Text small>Status</Text>
                </Column>
                {!isSecurityTransactionsPage && (
                  <Column size={8}>
                    <Text small block right>
                      &nbsp;Sum
                    </Text>
                  </Column>
                )}
              </ColumnarLayout>
            </Card>
            <div>
              {transactions.map((transaction, index) => (
                <Transaction
                  transaction={transaction}
                  key={transaction.id}
                  portfolio={portfolioNameById[transaction.goal]}
                  isSecurityTransaction={isSecurityTransactionsPage}
                  portfolioReference={portfolioReferenceById[transaction.goal]}
                  data-test-id={`transaction${index as string}`}
                />
              ))}
              <div ref={preloaderElementRef}>
                {isNextLoading && (
                  <div className="Transactions-Preloader">
                    <Preloader loading={isNextLoading} absolute />
                  </div>
                )}
              </div>
            </div>
          </Inner>
        </Segment>
      ),
    [
      hasTransactions,
      total,
      transactions,
      pendingOrders.length,
      portfolioNameById,
      preloaderElementRef,
      isNextLoading,
      isSecurityTransactionsPage,
      portfolioReferenceById,
    ],
  )

  const emptyResults = useMemo(
    () =>
      noResults && (
        <Paper top={32}>
          <Inner>
            <Typo>No transactions found. Try a different set of filters.</Typo>
          </Inner>
        </Paper>
      ),
    [noResults],
  )

  const emptyTransactions = useMemo(
    () =>
      noTransactions && (
        <Paper top={32}>
          <Inner data-test-id="dashboardTransactionsNoTransactions">
            <Typo>No transactions to show just yet</Typo>
          </Inner>
        </Paper>
      ),
    [noTransactions],
  )

  return (
    <DesktopLayout
      header={<DesktopHeader />}
      content={
        <Paper top={20} bottom={120}>
          {filtersAndOrders}
          {transactionsTable}
          {emptyTransactions}
          {emptyResults}
          <Preloader loading={isLoading} size="big" zIndex />
        </Paper>
      }
      footer={<DesktopFooter />}
      noGaps
      fullSize
    />
  )
}

export { Desktop }
