import React, { Fragment } from 'react'

import { useMediaQueries, useMemo } from 'hooks'

import Button from 'components/_old/Button/Button.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'

import { GatewayDest } from 'components/atoms/Gateway'
import { DesktopLayout, MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import SideSpacesCompensator from 'components/atoms/SideSpacesCompensator/SideSpacesCompensator.jsx'
import { Skeleton } from 'components/atoms/Skeleton'

import { TwoColumns } from 'components/molecules/TwoColumns'

import { Stories } from 'components/organisms/Stories/Stories'

import { DesktopFooter } from 'app/containers/Footer'
import { DesktopHeader, MobileHeader } from 'app/containers/Header'

import { Abandon } from 'app/pages/Dashboard/Goals/DIY/EditWeights/Abandon'
import { TunnelHeader } from 'app/pages/Securities/components'

import { AddSecuritiesCard } from '../SecurityUniverse/components/AddSecuritiesCard'
import { AddSecuritiesMobileFooter } from '../SecurityUniverse/components/AddSecuritiesMobileFooter'
import { CollectionGroup } from '../SecurityUniverse/components/SecurityUniverseDiscovery/components/CollectionGroup'
import { SECURITY_UNIVERSE_VERSION } from '../SecurityUniverse/hooks'

import {
  CollectionHeader,
  CollectionDescription,
  CollectionSecurities,
  SecondLevelFeaturedProviders,
} from './components'
import { useSecurityCollection } from './hooks/useSecurityCollection'

interface SecurityCollectionProps {
  params: {
    slugOrId: string
    portfolioId: string
  }
  location: {
    query?: Record<string, string>
  }
}

const SecurityCollection = ({ params: { slugOrId }, location }: SecurityCollectionProps): React.ReactElement | null => {
  const { desktop } = useMediaQueries()

  const portfolioId = useMemo(
    () => (location?.query?.portfolioId ? parseInt(location.query.portfolioId, 10) : null),
    [location?.query?.portfolioId],
  )

  const {
    abandonModalOpen,
    addedItemsCount,
    areSecuritiesLoading,
    collection,
    featuredSecurities,
    filters,
    hasAddedSecurities,
    isPartnerPage,
    isFiltersChange,
    isShowAllButtonVisible,
    isThematics,
    portfolioSecurities,
    portfolioTitle,
    removableSecuritiesIds,
    scrollableElementRef,
    searchValue,
    securities,
    securitiesWasLoadedOnce,
    version,
    stories,
    areStoriesFetched,
    recommendedCollections,
    getFeaturedSecurityDataByIndex,
    getSecurityDataByIndex,
    getSecurityLink,
    handleAbandonModalClose,
    handleBack,
    handleCancel,
    handleGoToEditWeights,
    handleRemoveSecurity,
    handleSearch,
    handleShowAllEtfs,
    navigateToSecurity,
    sendEtfClickEventtoGA,
    handleSaveScrollPosition,
  } = useSecurityCollection({
    slugOrId,
    portfolioId,
    location,
  })

  const collectionHeaderNode = (
    <Skeleton shown={collection?.isSkeleton}>
      <CollectionHeader collection={collection} isFeatured={isPartnerPage} />
    </Skeleton>
  )

  const collectionDescriptionNode = (
    <Skeleton shown={collection?.isSkeleton}>
      <CollectionDescription collection={collection} isFeatured={isPartnerPage} location={location} />
    </Skeleton>
  )

  const recommendedCollectionsNode = !!recommendedCollections?.length && (
    <Paper bottom={desktop ? 48 : 32}>
      <CollectionGroup
        collections={recommendedCollections}
        portfolioId={portfolioId}
        requestRegulatoryType={location?.query?.requestRegulatoryType}
        layout="featured"
        title="Featured partners"
        addBackLocationBeforeNavigation
        handleSaveScrollPosition={handleSaveScrollPosition}
      />
    </Paper>
  )

  const storiesNode = (stories?.length || !areStoriesFetched) && (
    <Paper bottom={desktop ? 48 : 32}>
      <Stories stories={stories} isLoading={!areStoriesFetched} />
    </Paper>
  )

  const securitiesNode =
    isShowAllButtonVisible && !isFiltersChange ? (
      <CollectionSecurities
        collectionFilters={collection.filters}
        filters={filters}
        isCollectionBeingLoaded={collection.isSkeleton}
        isLoading={areSecuritiesLoading}
        location={location}
        scrollableElementRef={scrollableElementRef}
        searchValue={searchValue}
        securities={featuredSecurities}
        securitiesWasLoadedOnce={securitiesWasLoadedOnce}
        getSecurityDataByIndex={getFeaturedSecurityDataByIndex}
        getSecurityLink={getSecurityLink}
        handleGoToEditWeights={handleGoToEditWeights}
        handleSearch={handleSearch}
        navigateToSecurity={navigateToSecurity}
        sendEtfClickEventtoGA={sendEtfClickEventtoGA}
      />
    ) : (
      <CollectionSecurities
        collectionFilters={collection.filters}
        filters={filters}
        isCollectionBeingLoaded={collection.isSkeleton}
        isLoading={areSecuritiesLoading}
        location={location}
        scrollableElementRef={scrollableElementRef}
        searchValue={searchValue}
        securities={securities}
        securitiesWasLoadedOnce={securitiesWasLoadedOnce}
        getSecurityDataByIndex={getSecurityDataByIndex}
        getSecurityLink={getSecurityLink}
        handleGoToEditWeights={handleGoToEditWeights}
        handleSearch={handleSearch}
        navigateToSecurity={navigateToSecurity}
        sendEtfClickEventtoGA={sendEtfClickEventtoGA}
      />
    )

  const navigationHeader = (
    <Inner twoColumns>
      <TunnelHeader onBack={handleBack} onCancel={portfolioId ? handleCancel : null} />
    </Inner>
  )

  const addSecuritiesCard = (
    <AddSecuritiesCard
      version={version}
      hasAddedSecurities={hasAddedSecurities}
      portfolioTitle={portfolioTitle}
      addedItemsCount={addedItemsCount}
      portfolioSecurities={portfolioSecurities}
      removableSecuritiesIds={removableSecuritiesIds}
      handleRemoveSecurity={handleRemoveSecurity}
      location={location}
    />
  )

  const continueButton = version === SECURITY_UNIVERSE_VERSION.INSIDE_PORTFOLIO && (
    <div className="SecurityUniverse-StuckArea">
      <Inner twoColumns>
        <TwoColumns
          content={
            <SideSpacesCompensator size={2}>
              <Paper top={20} bottom={64} left={32} right={32}>
                <Paper className="SecurityUniverse-ButtonWrapper">
                  <Button
                    mods={{ size: 'block big' }}
                    disabled={!hasAddedSecurities}
                    onClick={handleGoToEditWeights}
                    data-test-id="securityUniverseContinueButton"
                  >
                    Continue
                  </Button>
                </Paper>
              </Paper>
            </SideSpacesCompensator>
          }
        />
      </Inner>
    </div>
  )

  const secondLevelFeaturedProviders = useMemo(() => {
    if (recommendedCollections?.length) return null

    if (isThematics) {
      return (
        <Paper bottom={desktop ? 48 : 32}>
          <SecondLevelFeaturedProviders />
        </Paper>
      )
    }

    return null
  }, [isThematics, recommendedCollections?.length, desktop])

  const showAllETFsButton = (
    <Paper bottom={48}>
      <Button mods={{ theme: 'simple-reverse-blue', size: 'block big' }} onClick={handleShowAllEtfs}>
        Show all {securities.length} ETFs
      </Button>
    </Paper>
  )

  const desktopContent = (
    <Fragment>
      {!portfolioId && (
        <Paper top={20} bottom={20}>
          {navigationHeader}
        </Paper>
      )}
      <Inner twoColumns>{collectionHeaderNode}</Inner>

      <Inner
        key={collection?.id} // forcefully redraw after skeleton fetch to allow proper offset of the sidebar after rerender
        twoColumns
        mods={{ height: 'full' }}
      >
        <TwoColumns
          content={
            <Fragment>
              <Paper top={40}>{collectionDescriptionNode}</Paper>
              <Paper bottom={isShowAllButtonVisible ? 32 : 48} top={48}>
                {storiesNode}
                {recommendedCollectionsNode}
                {secondLevelFeaturedProviders}
                {securitiesNode}
              </Paper>
              {isShowAllButtonVisible && showAllETFsButton}
            </Fragment>
          }
          sidebar={
            <Paper top={48} bottom={40}>
              {addSecuritiesCard}
            </Paper>
          }
          synchronizedScroll
          scrollableElement={scrollableElementRef.current}
          minimalSidebarOffsetTop={48}
          initialSidebarOffsetTop={secondLevelFeaturedProviders ? 670 : 124}
          sidebarOffsetBottom={version === SECURITY_UNIVERSE_VERSION.INSIDE_PORTFOLIO ? 132 : 0}
        />
      </Inner>
    </Fragment>
  )

  const navigationBar = (
    <NavigationBar
      leftPartText="Back"
      onLeftPartClick={handleBack}
      rightPartText={portfolioId ? 'Cancel' : null}
      onRightPartClick={portfolioId ? handleCancel : null}
    />
  )

  const mobileHeader = portfolioId ? navigationBar : <MobileHeader />

  const mobileContent = (
    <Fragment>
      {!portfolioId && <SideSpacesCompensator>{navigationBar}</SideSpacesCompensator>}
      <Paper bottom={32}>{collectionHeaderNode}</Paper>
      <Paper bottom={32}>{collectionDescriptionNode}</Paper>
      {secondLevelFeaturedProviders}
      {recommendedCollectionsNode}
      {storiesNode}
      <Paper bottom={32}>{securitiesNode}</Paper>
      {isShowAllButtonVisible && showAllETFsButton}
    </Fragment>
  )

  const desktopScreen = (
    <Fragment>
      <DesktopLayout
        ref={scrollableElementRef}
        header={portfolioId ? navigationHeader : <DesktopHeader />}
        content={desktopContent}
        footer={portfolioId ? null : <DesktopFooter />}
        fullSize
        noGaps
      />
      {continueButton}
    </Fragment>
  )

  const isButtonEnabled = addedItemsCount > 0

  const mobileFooter =
    version === SECURITY_UNIVERSE_VERSION.INSIDE_PORTFOLIO ? (
      <Paper top={16}>
        <AddSecuritiesMobileFooter
          addedItemsCount={addedItemsCount}
          portfolioTitle={portfolioTitle}
          isButtonEnabled={isButtonEnabled}
          handleGoToEditWeights={handleGoToEditWeights}
        />
      </Paper>
    ) : (
      <Paper top={16} />
    )

  const mobileScreen = (
    <MobileLayout
      ref={scrollableElementRef}
      header={mobileHeader}
      content={mobileContent}
      footer={mobileFooter}
      contentPaperSizes={{ top: 0 }}
    />
  )

  return (
    <Fragment>
      {desktop ? desktopScreen : mobileScreen}
      <Abandon open={abandonModalOpen} onClose={handleAbandonModalClose} onContinue={handleCancel} />
      <GatewayDest name="supportModal" />
      <GatewayDest name="modals" />
      <GatewayDest name="toasts" />
    </Fragment>
  )
}

export { SecurityCollection }
