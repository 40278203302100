import React from 'react'
import { withRouter } from 'react-router'

import PropTypes from 'prop-types'

import { useMediaQueries } from 'hooks'

import { Gateway } from 'components/atoms/Gateway'

import { Modal } from 'components/molecules/Modal'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'
import { useSessionTimeout } from './useSessionTimeout'

type SessionTimeoutProps = {
  countdown: string
  handleLogout: () => void
  handleStay: () => void
}

const SessionTimeout = withRouter(({ hasAuth, location }): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { isOpen, ...props } = useSessionTimeout(hasAuth, location?.pathname)

  return (
    <Gateway into="global">
      <Modal open={isOpen} close={null}>
        {desktop ? <Desktop {...props} /> : <Mobile {...props} />}
      </Modal>
    </Gateway>
  )
})

SessionTimeout.propTypes = {
  hasAuth: PropTypes.bool.isRequired,
  location: PropTypes.object,
}

export { SessionTimeout, type SessionTimeoutProps }
