import React from 'react'
import classNames from 'classnames/dedupe'
import isNumber from 'lodash/isNumber'
import isEqual from 'lodash/isEqual'

import rawMediaQueries from 'helpers/mediaQueries.js'
import { palette } from 'helpers/palette/'

import withMediaQueries, { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import ColumnarLayout from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import Card from 'components/_old/Card/Card.jsx'
import HorizontalChartGroup from 'components/_old/HorizontalChartGroup/HorizontalChartGroup.jsx'
import AssetSummary from 'components/_old/AssetSummary/AssetSummary.jsx'
import Modal from 'components/_old/Modal/Modal.jsx'
import ModalContent, { ModalContentBody, ModalContentButtons } from 'components/molecules/ModalContent/ModalContent.jsx'
import Text from 'components/_old/Text/Text.jsx'
import ContentHolder from 'components/molecules/ContentHolder/ContentHolder.jsx'

import './HorizontalChartGroupWithSummary.css'

@withMediaQueries(rawMediaQueries)
export default class HorizontalChartGroupWithSummary extends React.Component {
  componentDidMount() {
    this.setActiveAsset()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props

    if (!isEqual(data, prevProps.data)) {
      this.setActiveAsset()
    }
  }

  setActiveAsset = () => {
    const { data, changeActiveAsset, mediaQueries } = this.props
    const { desktop } = mediaQueries

    if (desktop) {
      const assetGroup = data.groupsOfAssets.find((assetGroup) => assetGroup.assets.length > 0)

      if (assetGroup) {
        changeActiveAsset(assetGroup.assets[0])
      }
    }
  }

  render() {
    const {
      className,
      data,
      woPrices,
      continueLine = {},
      selectedAsset,
      changeActiveAsset,
      mediaQueries,
      grow,
      edgeToEdge,
      showOrHideButton,
      isBottomShown = true,
      showWeightOnly = false,
      showAmountOnly,
      'data-test-id': dataTestId,
      'data-item-test-id': dataItemTestId,
    } = this.props
    const { desktop } = mediaQueries
    const layoutCardMods = { theme: 'transparent', 'no-padding': desktop ? 'left right bottom' : 'left right' }
    const horizontalGroupClasses = classNames({
      'HorizontalChartGroup_w-summary': desktop,
      'HorizontalChartGroup_continue-line_left': continueLine.left,
      'HorizontalChartGroup_continue-line_right': continueLine.right,
      HorizontalChartGroup_edgeToEdge: edgeToEdge,
    })

    const showOnlyAmount = showAmountOnly ?? (!isNumber(data.maxTotalValue) || data.maxTotalValue === 0)

    const horizontalGroup = (
      <HorizontalChartGroup
        className={horizontalGroupClasses}
        data={data}
        selectedAsset={selectedAsset}
        onClickOnItem={changeActiveAsset}
        showOnlyAmount={showOnlyAmount}
        showWeightOnly={showWeightOnly}
        center={!desktop}
        fluid={grow}
        data-test-id={dataTestId}
        data-item-test-id={dataItemTestId}
      />
    )

    const classes = classNames(
      'HorizontalChartGroupWithSummary',
      className,
      getMediaQuieryClasses('HorizontalChartGroupWithSummary', mediaQueries),
    )

    const showBottomInfo = isNumber(data.maxTotalValue) && data.maxTotalValue > 0

    const bottomInfo = showBottomInfo ? (
      <div className="HorizontalChartGroupWithSummary-BottomInfo">
        <Text small>Click on ETF name to see the price update date.</Text>
        <br />
        <br />
        <Text small>
          Numbers might not add up to 100 percent due to rounding. The numbers above include all trades as soon as they
          have been executed. You can check the status of any trade on your account on the Transactions screen.
        </Text>
      </div>
    ) : null

    return desktop ? (
      <div className={classes}>
        <ColumnarLayout
          mods={{ 'column-content': 'flex-start', 'column-overflow': 'hidden' }}
          style={{ borderColor: palette['background-border'] }}
          bordered
        >
          <Card className="HorizontalChartGroupWithSummary-Card" mods={layoutCardMods} grow={grow}>
            {horizontalGroup}
            {showOrHideButton ? (
              <Card
                mods={{
                  ...layoutCardMods,
                  'no-padding': 'left right bottom',
                }}
                grow={grow}
              >
                <Text center block>
                  {showOrHideButton}
                </Text>
              </Card>
            ) : null}
          </Card>
          <Card className="HorizontalChartGroupWithSummary-Card" mods={layoutCardMods} grow={grow}>
            {selectedAsset ? (
              <AssetSummary
                data={data}
                className="HorizontalChartGroupWithSummary-AssetSummary"
                item={selectedAsset}
                woPrices={woPrices}
                showOnlyAmount={showOnlyAmount}
              />
            ) : null}
          </Card>
        </ColumnarLayout>
        {isBottomShown && bottomInfo}
      </div>
    ) : (
      <div className={classes}>
        {horizontalGroup}
        {isBottomShown && bottomInfo}
        <Modal
          open={selectedAsset}
          onClose={() => {
            changeActiveAsset(null)
          }}
        >
          <ModalContent>
            <ModalContentBody>
              <ContentHolder>
                <AssetSummary data={data} item={selectedAsset} woPrices={woPrices} />
              </ContentHolder>
            </ModalContentBody>
            <ModalContentButtons
              text="OK"
              onClick={() => {
                changeActiveAsset(null)
              }}
            />
          </ModalContent>
        </Modal>
      </div>
    )
  }
}
