import React from 'react'
import { withRouter } from 'react-router'

import type { Portfolio } from 'app/redux/models/portfolio/types'

import { LumpSumSetupModal } from 'components/organisms/LumpSumSetupModal/LumpSumSetupModal'

import type { RouteProps } from 'app/pages/Dashboard/UserProfile/types'

import { useLumpSumSetup } from './hooks/useLumpSumSetup'

type LumpSumSetupProps = {
  portfolio: Portfolio
  securityId?: number
  routes: RouteProps[]
  location: {
    query?: {
      back?: string
    }
  }
  onClose: () => void
}

const LumpSumSetup = withRouter(
  ({ portfolio, securityId, location, routes, onClose }: LumpSumSetupProps): React.ReactElement => {
    const currentRouteModule = routes[routes.length - 1].module
    const isOpen = currentRouteModule === 'lump-sum-setup'

    const { needToChooseRegulatoryType, handleContinue, handleBack } = useLumpSumSetup({
      portfolio,
      securityId,
      location,
    })

    return (
      <LumpSumSetupModal
        isOpen={isOpen}
        needToChooseRegulatoryType={needToChooseRegulatoryType}
        onClose={onClose}
        onBack={handleBack}
        onContinue={handleContinue}
      />
    )
  },
)

export { LumpSumSetup }
