import React from 'react'

import { useMediaQueries } from 'hooks'

import { format as formatDate } from 'helpers/date'

import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

export const DateField: React.FC = () => {
  const { desktop } = useMediaQueries()
  const currentDate = new Date()

  return (
    <React.Fragment>
      <LabelInlineStyle labelText="Date" size="small" multiline>
        <Paper top={4} data-test-id="signatureDate">
          {formatDate(currentDate, 'DD/MM/YYYY')}
        </Paper>
      </LabelInlineStyle>
      <Paper top={16}>
        <Typography size={desktop ? 16 : 14}>
          <Typo>This ISA transfer form will be submitted electronically.</Typo>
        </Typography>
      </Paper>
    </React.Fragment>
  )
}
